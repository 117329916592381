import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../../_helpers';
import { dashboardActions, surveyActions, userActions } from '../../../_actions';
import Header from '../Header/Header';
import CustomerSurveyList from './Components/CustomerSurveyList';
import AddSurvey from './Modal/AddSurvey/AddSurvey';
import UpdateSurvey from './Modal/AddSurvey/UpdateSurvey';
import DeleteSurveyConfirm from './Modal/DeleteSurveyConfirm';
import ChooseSurvey from './Modal/AddSurvey/ChooseSurvey';
import SurveyLibrary from './Components/SurveyLibrary';
import QuestionLibrary from './Components/QuestionLibrary';
import AddSurveyLibModal from './Modal/AddSurvey/AddSurveyLibModal';
import Sidebar from "../../Overview/Sidebar/Sidebar";
import NoDashboardModal from './Modal/NoDashboardModal';
import VentLinkModal from './Modal/VentLinkModal';
import OverallSurveyCard from './Components/OverallSurveyCard';
import UploadPreviewCustomer from './Modal/UploadPreviewCustomer';

import * as XLSX from 'xlsx';
import { make_cols } from '../../Employees/MakeColumns';
import { SheetJSFT } from '../../Employees/types';
import { TbPlus } from 'react-icons/tb';

class CustomerSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveysize: 10000,
      showSurveyList: true,
      showAddNewSurvey: false,
      showEditSurvey: false,
      confirmDeleteSurvey: false,
      CurrentSurveyId: '',
      CurrentSurveyName: '',
      showChooseSurveyModal: false,
      showSurveyLibrary: false,
      isPackageSelected: false,
      showStagesListModal: false,
      CurrentSurveyData: '',
      showQuestionLibrary: '',
      savePackage: '',
      crrLifecycleStageId: '',

      EmpIndexA: 0,
      EmpIndexB: 6,

      currentSurveyPage: 1,

      packageList: [],
      surveyValidation: {
        "name": true,
        "desc": true
      },


      surveyFormData: {
        "name": '',
        "desc": '',
        "type": "custom",
        "roleTemp": "ADMIN"
      },

      showCreateSurveyPopup: false,


      showSidebar: true,

      CrrRoute: "survey",

      crrSurvey: "",
      updateformSurveyData: {
        id: "",
        name: "",
        roleTemp: "",
        desc: "",
        templateId: "",
        companyId: "",
      },



      CurrentLanguage: 'en',
      CurrentLanguageName: 'English',


      showLanguageModal: false,
      showNODashboardModal: false,
      crrSurveyId: "",
      selectedSurveyType: {
        "id": 0,
        "name": "Select Category"
      },
      showTab: 1,
      showVentLinkModal: false,
      crrCategoryType: 1,

      showRelationModal: false,
      showGrid: true,


    }
  }


  componentDidMount() {



    this.props.dispatch(dashboardActions.getCompanyNameOnly());


    let dataX = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.getAllParameterList(dataX));




    let dataX2 = {
      "keyWord": "",
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 5 // Customer Listening Survey
    }



    this.props.dispatch(surveyActions.getSurveyList(dataX2));


    this.props.dispatch(surveyActions.getDefaultRiskSurvey({ "defaultType": "DEFAULTCUSTOMER", "stageTemplate": 8, "surveyModule": 5 }, dataX2));



    this.props.dispatch(dashboardActions.getClientProfile());


    let data2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(surveyActions.getLanguageLabel(data2));
    this.props.dispatch(surveyActions.getLanguage(data2));

    let data2x2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 1000
    }

    this.props.dispatch(surveyActions.getCustEmpRelationList(data2x2));





  }



  prevEmpPage = () => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (EmpIndexA > 0) {
      this.setState({ EmpIndexA: EmpIndexA - 1, EmpIndexB: EmpIndexB - 1 });
    }
  }
  nextEmpPage = (IndexListIndex) => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (IndexListIndex && IndexListIndex.length && (EmpIndexB < IndexListIndex.length)) {
      this.setState({ EmpIndexA: EmpIndexA + 1, EmpIndexB: EmpIndexB + 1 });
    }
  }

  SelectEmpPage = (val) => {
    this.setState({ currentSurveyPage: val })
  }


  handleSearchSurvey = (e) => {
    let data = {
      "keyWord": e.target.value,
      "pageNo": 1,
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "size": this.state.size,
      "surveyModule": 5 // Customer Listening Survey
    }
    this.props.dispatch(surveyActions.getSurveyList(data));
  }


  onAddNewSurvey = (check) => {
    if (check)
      this.setState({
        showSurveyList: true,
        showAddNewSurvey: false
      })
    else {
      this.setState({
        showSurveyList: false,
        showAddNewSurvey: true
      })
    }
  }

  handleAddSurveyInput = (e) => {
    let { surveyFormData } = this.state;
    surveyFormData[e.target.name] = e.target.value;
    this.setState({ surveyFormData })
  }

  handleUpdateSurveyInput = (e) => {
    let { updateformSurveyData } = this.state;
    updateformSurveyData[e.target.name] = e.target.value;
    this.setState({ updateformSurveyData })
  }

  handleAddSurveySubmit = () => {

    let isCustom = this.state.surveyFormData && this.state.surveyFormData.type === "custom";
    let isRisk = this.state.surveyFormData && this.state.surveyFormData.type === "risk";

    let reqData = {
      "name": this.state.surveyFormData.name,
      "desc": this.state.surveyFormData.desc,
      "type": this.state.surveyFormData.type,
      "roleTemp": this.state.surveyFormData.roleTemp,
      "templateId": isRisk ? 6 : (isCustom ? 3 : 1)
    }

    // let datatempsurvey = {
    //   "keyWord": "",
    //   "pageNo": 1,
    //   "size": this.state.surveysize
    // }


    if (reqData && reqData.name) {
      this.setState({ SurveySubmitData: reqData }, () => {
        if (this.state.surveyFormData && this.state.surveyFormData.type === "custom") {
          // if(this.state.crrLifecycleStageId){
          //   this.handleCustomSurvey();
          // }
          // else{
          //   this.handleCustomSurveyStage();
          // }
          this.handleCustomSurvey();

        }
        else if (this.state.surveyFormData && this.state.surveyFormData.type === "package") {
          this.handleCreateSurveyFromLibrary();
          this.setState({ showCreateSurveyPopup: false });
        }
        else if (this.state.surveyFormData && this.state.surveyFormData.type === "risk") {
          this.handleCustomSurvey();
        }

      });

    }
    else {
      let { surveyValidation } = this.state;

      if (reqData && reqData.name) {
        surveyValidation["name"] = true
      }
      else {
        surveyValidation["name"] = false
      }

      if (reqData && reqData.desc) {
        surveyValidation["desc"] = true
      }
      else {
        surveyValidation["desc"] = false
      }
      this.setState({ surveyValidation })
    }







  }

  handleCustomSurveyStage = () => {


    let dataX = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(surveyActions.getLifecycleStageList(dataX));

    this.setState({ showStagesListModal: true });

  }


  handleCustomSurvey = () => {
    let clientData = this.props.dashboard.ClientProfileData;


    let { SurveySubmitData, crrLifecycleStageId, selectedSurveyType } = this.state;

    let resData = {
      "name": SurveySubmitData.name,
      "desc": SurveySubmitData.desc,
      "templateId": SurveySubmitData && SurveySubmitData.templateId === "risk" ? 6 : 3,
      "roleTemp": clientData && clientData.role && clientData.role === "CROLE" ? "CROLE" : SurveySubmitData.roleTemp,
      "stageId": crrLifecycleStageId ? crrLifecycleStageId : null,
      "surveyModule": 5 // Customer Listening Survey
    }

    // if(selectedSurveyType && selectedSurveyType.id && selectedSurveyType.id === 1){
    //   resData["isRiskSurveyType"] = "CUSTOMDAYS"
    // }
    if (selectedSurveyType && selectedSurveyType.id) {
      resData["surveyCategory"] = selectedSurveyType.id
    }


    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 5 // Customer Listening Survey
    }

    this.props.dispatch(surveyActions.createSurvey(resData, datatempsurvey, true, [], this.props));

    let validate = {
      "name": true,
      "desc": true
    }
    let tempData = {
      "name": '',
      "desc": '',
      "type": "custom",
      "roleTemp": "ADMIN"

    }
    this.setState({ crrLifecycleStageId: '', showStagesListModal: false, SurveySubmitData: "", showCreateSurveyPopup: false, showChooseSurveyModal: false, surveyValidation: validate, surveyFormData: tempData })

  }

  onDeleteSurvey = (survey) => {
    this.setState({ confirmDeleteSurvey: true, CurrentSurveyId: survey.id, CurrentSurveyName: survey.name })
  }

  handleConfirmDeleteSurveyClose = () => {
    this.setState({ confirmDeleteSurvey: false, CurrentSurveyId: '', CurrentSurveyName: '' })
  }


  handleViewPackage = (survey, valid) => {
    if (valid) {
      let { packageList } = this.state;
      this.setState({ showQuestionLibrary: true, savePackage: survey });

      if (packageList.findIndex(prev => prev === survey.id) !== -1) {
        this.setState({ isPackageSelected: true })
      } else {
        this.setState({ isPackageSelected: false })
      }
    }
  }

  handlePackageSelection = (check, survey) => {
    let Id = survey.id;
    let StageId = survey && survey.stageId && survey.stageId.id ? survey.stageId.id : '';
    let { packageList, crrLifecycleStageId } = this.state;

    if (StageId === crrLifecycleStageId) {
      ////////console.log("survey package------------->?",survey)
      this.setState({ showQuestionLibrary: false })

      if (!check) {
        packageList.push(Id);
      }
      else {
        let getIndex = packageList.findIndex(prev => prev === Id)
        packageList.splice(getIndex, 1);
      }
      this.setState({ packageList });


      // if(packageList.findIndex(prev=>prev === survey.id)===-1){
      //   packageList.push(survey.id);
      //   this.setState({isPackageSelected:false})
      // }
      // else{
      //   let getIndex = packageList.findIndex(prev=>prev === survey.id);
      //   packageList.splice(getIndex,1);
      //   this.setState({isPackageSelected:true})
      // }

    }


  }





  handleADDpackage = (survey) => {
    this.setState({ showQuestionLibrary: true })

    let { packageList } = this.state;
    if (packageList.findIndex(prev => prev === survey.id) === -1) {
      packageList.push(survey.id);
      this.setState({ isPackageSelected: false })
    }
    else {
      let getIndex = packageList.findIndex(prev => prev === survey.id);
      packageList.splice(getIndex, 1);
      this.setState({ isPackageSelected: true })
    }
    this.setState({ packageList });
  }


  onSurveyDelete = () => {
    let data = {
      id: this.state.CurrentSurveyId
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 5 // Customer Listening Survey
    }
    this.props.dispatch(surveyActions.deleteSurvey(data, datatempsurvey));
    this.setState({ confirmDeleteSurvey: false, CurrentSurveyId: '', CurrentSurveyName: '' })
  }

  onEditSurvey = (data) => {
    this.props.history.push(`/app/question/${data.id}`)
  }

  onReportSurvey = (data) => {
    this.props.history.push(`/app/customersurveyreport/${data.id}`)
  }

  onPublishSurvey = (data, templateId, otherSurvey) => {
    // if(templateId===6){
    //   let otherSurveyId = otherSurvey && otherSurvey.id?otherSurvey.id:"";
    //   this.props.history.push(`/app/publishrisksurvey/${data.id}/${otherSurveyId}`)
    // }
    // else{
    //   this.props.history.push(`/app/publish/${data.id}`)
    // }
    this.props.history.push(`/app/publishcustomer/${data.id}`)

  }

  handleChooseSurveyPopup = () => {
    this.setState({ showCreateSurveyPopup: false, showChooseSurveyModal: true })
  }

  handleCreateSurveyPopup = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showCreateSurveyPopup: true })
  }

  handleCreateSurveyFromLibrary = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showSurveyLibrary: true });


    this.props.dispatch(surveyActions.getSurveyFromLib({
      "stageTemplate": 2,
      "surveyType": 2
    }));
  }

  handleSurveyLibraryClose = (check) => {
    this.setState({ showCreateSurveyPopup: true, showSurveyLibrary: false, packageList: [] });
  }


  QuestionLibraryClose = () => {
    this.setState({ CurrentSurveyData: false, savePackage: '', showQuestionLibrary: false });
  }



  handleCreateSurveyPopupClose = () => {
    this.handleChooseSurveyPopupClose();

    let validate = {
      "name": true,
      "desc": true
    }
    let tempData = {
      "name": '',
      "desc": '',
      "type": "custom"

    }
    this.setState({ crrLifecycleStageId: '', showStagesListModal: false, showCreateSurveyPopup: false, surveyValidation: validate, surveyFormData: tempData });
  }

  handleChooseSurveyPopupClose = () => {
    this.setState({ showChooseSurveyModal: false })
  }

  onLogout = () => {
    ////console.log('logout')
    this.props.dispatch(userActions.logoutSession());
  }


  goToDashboard = () => {

    this.props.history.push('/app/dashboard');

  }


  gotoEmployeeScreen = () => {
    this.props.history.push('/app/employees/');
  }




  onDashboard = (survey) => {
    // if(survey && survey.templateId===1){
    //   this.props.history.push('/app/dashboard/' + survey.id);
    // }
    // else if(survey && survey.templateId===2){
    //   this.props.history.push('/app/dashboard/' + survey.id);
    // }
    // else if(survey && survey.templateId===3){
    //   this.props.history.push('/app/generaldashboard/' + survey.id);
    // }
    // else if(survey && survey.templateId===4){
    //   this.props.history.push('/app/generaldashboard/' + survey.id);
    // }
    // else if(survey && survey.templateId===5){
    //   this.props.history.push('/app/customerdashboard/' + survey.id);
    // }
    // else if(survey && survey.templateId===6){
    //   this.props.history.push('/app/sensedashboard/' + survey.id);
    // }
    this.props.history.push('/app/customerdashboard/' + survey.id);
  }



  handleSurveyLibQuestions = (survey) => {
    this.setState({ CurrentSurveyData: survey });
  }

  handleUseSurveyPage = () => { }

  handleSuveyPackageSubmit = (crrLifecycleStageId) => {
    let { SurveySubmitData, packageList } = this.state;
    let { survey } = this.props;
    let { getSurveyFromLibData } = survey;

    ////////console.log("packageList-------------------------->",packageList);

    let isNonEdit = false;
    let nonEditCount = 0
    if (getSurveyFromLibData && getSurveyFromLibData.length > 0 && packageList && packageList.length > 0) {
      packageList.forEach((item) => {
        let getIndex = getSurveyFromLibData.findIndex(prev => prev.id === item);
        if (getIndex !== -1) {
          if (getSurveyFromLibData[getIndex]["stageId"]["isNonEdit"]) {
            nonEditCount++
          }
        }
      })

      if (nonEditCount === packageList.length) {
        isNonEdit = true
      }

    }
    ////////console.log("isNonEdit-------------------------->",isNonEdit);



    let resData = {
      "name": SurveySubmitData.name,
      "desc": SurveySubmitData.desc,
      "stageId": crrLifecycleStageId,
      "roleTemp": SurveySubmitData.roleTemp,
      "templateId": 1,
      "isNonEdit": isNonEdit
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 5 // Customer Listening Survey
    }

    ////////console.log("resData-------->",resData)
    // this.props.history.push(`/app/question/${data.id}`)

    this.props.dispatch(surveyActions.createSurvey(resData, datatempsurvey, false, packageList, this.props));

    let validate = {
      "name": true,
      "desc": true
    }
    let tempData = {
      "name": '',
      "desc": '',
      "type": "custom",
      "roleTemp": "ADMIN"

    }
    this.setState({ crrLifecycleStageId: "", showSurveyLibrary: false, showCreateSurveyPopup: false, showChooseSurveyModal: false, surveyValidation: validate, surveyFormData: tempData, packageList: [] })

  }



  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  }
  routeLink = (link) => {
    if (link === "happinessdashbaord") {
      this.props.history.push('/app/happinessdashbaord');
    }
    else {
      this.props.history.push('/app/' + link);
      this.setState({ CrrRoute: link });
    }

  }
  onOptionDot = (data) => {
    if (this.state.crrSurvey === data.id) {
      this.setState({ crrSurvey: "" });
    }
    else {
      this.setState({ crrSurvey: data.id });
    }

    setTimeout(() => {
      this.setState({ crrSurvey: "" });
    }, 7000);
  }


  handleLanguage = (item) => {
    this.setState({ CurrentLanguage: item.shortName, CurrentLanguageName: item.name, showLanguageModal: false });
  }


  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  }


  onPreviewSurvey = (data) => {
    this.props.history.push(`/app/preview/${data.id}`)
  }


  handleSurveyType = (type) => {
    let { surveyFormData } = this.state;
    surveyFormData["type"] = type;
    this.setState({ surveyFormData });
  }

  handleSurveyRole = (role) => {
    let { surveyFormData } = this.state;
    surveyFormData["roleTemp"] = role;
    this.setState({ surveyFormData });
  }

  updatehandleSurveyRole = (role) => {
    let { updateformSurveyData } = this.state;
    updateformSurveyData["roleTemp"] = role.trim();
    this.setState({ updateformSurveyData });
  }

  onUpdateSurvey = (survey) => {
    let SurveyRole = "ADMIN"
    if (survey && survey.roles && survey.roles && survey.roles.length > 0) {
      if (survey.roles.length === 1) {
        if (survey.roles[0] === "ADMIN") {
          SurveyRole = "ADMIN";
        }
        else if (survey.roles[0] === "CROLE") {
          SurveyRole = "CROLE";
        }
      }
      else if ((survey.roles[0] === "ADMIN" && survey.roles[1] === "CROLE") || (survey.roles[0] === "CROLE" && survey.roles[1] === "ADMIN")) {
        SurveyRole = "CROLE";
      }

    }
    ////console.log('SurveyRole',SurveyRole)
    // survey.roles
    // roles
    let data = {
      id: survey.id,
      name: survey.name,
      roleTemp: SurveyRole,
      desc: survey.desc,
      templateId: survey.templateId,
      companyId: survey.companyId,
    }
    this.setState({ showUpdateSurveyModal: true, updateformSurveyData: data });
  }
  closeUpdateSurvey = () => {
    this.setState({ showUpdateSurveyModal: false, updateformSurveyData: "" });
  }

  handleUpdateSurveySubmit = () => {
    let { updateformSurveyData } = this.state;
    let resData = {
      "id": updateformSurveyData.id,
      "name": updateformSurveyData.name,
      "desc": updateformSurveyData.desc,
      "roleTemp": updateformSurveyData.roleTemp,
      "templateId": updateformSurveyData.templateId,
      "companyId": updateformSurveyData.companyId
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 5 // Customer Listening Survey
    }
    this.props.dispatch(surveyActions.updateSurvey(resData, datatempsurvey));
    let tempClear = {
      id: "",
      name: "",
      roleTemp: "",
      desc: "",
      templateId: "",
      companyId: "",
    }
    this.setState({ showUpdateSurveyModal: false, updateformSurveyData: tempClear });
  }


  onNoDashboardModal = (check, survey, categoryType) => {
    if (check) {
      this.setState({ showNODashboardModal: check, crrSurveyId: survey })
    } else {
      this.setState({ showNODashboardModal: check });
    }
    this.setState({ crrCategoryType: categoryType });
  }

  handleConfirmMailView = () => {
    this.setState({ showNODashboardModal: false }, () => {
      if (this.state.crrSurveyId && this.state.crrSurveyId.id) {
        this.props.history.push('/app/livedashboard/' + this.state.crrSurveyId.id);
      }
    })
  }

  selectStage = (item) => {
    let { crrLifecycleStageId } = this.state;
    if (crrLifecycleStageId === item.id) {
      this.setState({ crrLifecycleStageId: "", packageList: [] })
    }
    else {
      this.setState({ crrLifecycleStageId: item.id, packageList: [] })
    }
  }


  selectCreateType = (data) => {
    this.setState({ selectedSurveyType: data })
  }


  handleVentLink = () => {
    this.setState({ showVentLinkModal: true })
    this.props.dispatch(surveyActions.generateCompanyBotLink());
  }


  viewDashbaord = (categoryId) => {
    this.props.history.push('/app/lifecycledashboard/' + categoryId);
  }



  handleRelationshipUpload = () => {
    this.setState({ showRelationModal: true })
  }

  closeRelationshipModal = () => {
    this.setState({ showRelationModal: false })
  }





  handleDataChange = (e) => {
    // this.props.dispatch(surveyActions.empty360Save());

    this.setState({ loadedDataSheet: [] }, () => {

      const files = e.target.files;
      // ////////console.log("files; ",files)

      if (files && files[0]) {
        let fileName = files && files[0] && files[0].name ? files[0].name : "";
        let extName1 = fileName && fileName.substr(fileName.length - 3) ? fileName.substr(fileName.length - 3) : "";
        let extName2 = fileName && fileName.substr(fileName.length - 4) ? fileName.substr(fileName.length - 4) : "";
        if ((extName1 === "csv") || (extName2 === "xlsx")) {

          this.setState({ file: files[0] }, () => {
            ////////console.log("this.state.file", this.state.file)
            this.setState({ fileUploadError: "" });

            //----------------------------------------------------------------------------------------------------------------------------------------------------------
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;


            if (this.state.file) {
              if (rABS) {
                reader.readAsBinaryString(this.state.file);
              } else {
                reader.readAsArrayBuffer(this.state.file);
              };
            }

            //console.log('rABS--------------------------------------------->', rABS)


            reader.onload = (e) => {
              /* Parse data */
              const bstr = e.target.result;
              const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              //console.log('before ws--------------------------------------------->', ws)

              /* Convert array of arrays */
              let data = XLSX.utils.sheet_to_json(ws);
              data = data.map(v => ({ ...v }))



              //console.log('data-------------11111-------------------------------->', data)


              setTimeout(() => {
                this.setState({ loadedDataSheet: data, showUploadPreview: true, showRelationModal: false })
              }, 1000)



            };


            //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------




          });

        }
        else {
          this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
          ////////console.log("not csv or xlsx file")
        }

      }
      else {
        this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
      }

    })

  };




  saveUploadRelation = () => {
    let { loadedDataSheet } = this.state;
    if (loadedDataSheet && loadedDataSheet.length > 0) {
      let Temp1 = {
        "keyWord": '',
        "pageNo": 1,
        "size": 6
      }
      this.props.dispatch(surveyActions.saveCustEmpRelationList(loadedDataSheet, Temp1));
    }


  }




  closeUploadPreview = () => {
    this.setState({ showUploadPreview: false, loadedDataSheet: [] })
  }

  closeRelationshipTable = () => {
    this.setState({ showRelationTable: false })
  }

  handleMyRelationshipTable = () => {
    this.setState({ showRelationTable: true })
  }


  downLoadSampleCSV = () => {



    let binary_univers = [
      { "cust_id": "E002", "emp_id": "E002", "emp_relation": "Manager" },
      { "cust_id": "E001", "emp_id": "E002", "emp_relation": "Manager" },
      { "cust_id": "E004", "emp_id": "E002", "emp_relation": "Manager" },
      { "cust_id": "E002", "emp_id": "E002", "emp_relation": "Manager" },
      { "cust_id": "E002", "emp_id": "E002", "emp_relation": "Manager" },
      { "cust_id": "E002", "emp_id": "E002", "emp_relation": "Manager" }
    ];



    ////console.log('binary_univers: ', binary_univers)

    let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

    // Create a new Workbook
    var wb = XLSX.utils.book_new()

    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values')

    // export your excel
    XLSX.writeFile(wb, 'sample relationship.xlsx');
  }



  handleGridView = (check) => {
    this.setState({ showGrid: check })
  }



  render() {
    let { survey, dashboard } = this.props;
    let { saveCustEmpRelationListData, getCustEmpRelationListData, getLifecycleStageListData, getLanguageLabelData, getLanguageData, getSurveyFromLibData, getSurveyListData, getSurveyListTotal, loading, allLifecycleListenListData, generateCompanyBotLinkData } = survey;
    let { getCompanyNameOnlyData } = dashboard;
    let { showGrid, loadedDataSheet, showRelationModal, showRelationTable, showUploadPreview, crrCategoryType, showVentLinkModal, showTab, crrLifecycleStageId, showNODashboardModal, showUpdateSurveyModal, updateformSurveyData, showLanguageModal, CurrentLanguageName, CurrentLanguage, crrSurvey, CrrRoute, showSidebar, savePackage, isPackageSelected, showQuestionLibrary, packageList, CurrentSurveyData, showSurveyLibrary, showChooseSurveyModal, showSurveyList, CurrentSurveyName, confirmDeleteSurvey, surveyFormData, showCreateSurveyPopup, surveyValidation } = this.state;


    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }

    //console.log('surveyFormData------->: ', surveyFormData)
    //console.log('updateformSurveyData------->: ', updateformSurveyData)

    //ROLE MANAGEMENT ----------------------------------------------

    let SHOW_ELEMENT = false;
    let CLIENT_ID = "";
    let CLIENT_ROLE = "";

    let clientData = this.props.dashboard.ClientProfileData;

    if (clientData && clientData.id) {
      CLIENT_ID = clientData.id;
    }


    if (clientData && clientData.role) {
      CLIENT_ROLE = clientData.role;

      if (CLIENT_ROLE === "ADMIN") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (CLIENT_ROLE === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (CLIENT_ROLE === "CROLE") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (CLIENT_ROLE === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }

    let isCrole = false;
    if (clientData && clientData.role && clientData.role === "CROLE") {
      isCrole = true;
    }

    function accessAllow(item) {
      // ////////console.log("item-----......>>>",item)
      // ////////console.log("isCrole-----......>>>",isCrole)
      // if(item === "employeeactionplan"){
      //   return (isCrole?true:false)
      // }
      // else{
      //   return (moduleAccess.isAdmin?true:(moduleAccess && moduleAccess.access && moduleAccess.access[item]?true:false))
      // }

      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))

    }

    //END -------------------------------------------------------
    let { ClientProfileData } = this.props.dashboard;
    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageractionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }
    ////////console.log("ClientProfileData----------->",ClientProfileData)
    //--------------------------------------------------------------------------------

    let IndexList = []
    let pageCount = 22
    let IndexListIndex = []
    let indexA = 0
    let indexB = 0

    if (getSurveyListData && getSurveyListData.length > 0) {


      if (getSurveyListData && getSurveyListData.length > 0) {
        getSurveyListData.forEach((item, index) => {
          if (index % pageCount === 0) {
            IndexList.push(index)
          }
          if (index === (getSurveyListData.length - 1)) {
            IndexList.push(index)
          }
        })
      }

      ////console.log('IndexList: ',IndexList)

      if (IndexList && IndexList.length > 0) {
        IndexList.forEach((item, index) => {
          if (index !== (IndexList.length - 1)) {
            IndexListIndex.push(index + 1)
          }
        })
      }
      ////console.log('IndexListIndex: ',IndexListIndex)





      if (IndexListIndex && IndexListIndex.length > 0) {
        IndexListIndex.forEach((item, index) => {
          if (item === this.state.currentSurveyPage) {

            if (index < (IndexList.length - 2)) {
              indexA = IndexList[index];
              indexB = IndexList[index] + (pageCount - 1)
            }
            else {
              indexA = IndexList[index];
              indexB = IndexList[IndexList.length - 1]
            }
          }
        })
      }

    }
    //--------------------------------------------------------------------------------

    ////console.log('CLIENT_ROLE: ',CLIENT_ROLE)
    // ////console.log('getLanguageLabelData: ',getLanguageLabelData,getLanguageData,);



    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }


    // createSurvey: false
    // dashboard: true
    // responseAnalytics: true
    // uploadUser: false
    // viewSurvey: true






    ////////console.log("accessModule",accessModule());



    // accessAllow("createSurvey");
    // accessAllow("viewSurvey");
    // accessAllow("publishSurvey");
    let { getAllParameterListData } = this.props.dashboard;
    ////console.log('getAllParameterListData V : ',getAllParameterListData);

    function GetParameterLabel(name) {
      let AllParameterObj = {};
      if (getAllParameterListData && getAllParameterListData.length > 0) {
        getAllParameterListData.forEach((item) => {
          AllParameterObj[item.name] = item && item.label ? item.label : item.name;
        });
      }
      return (AllParameterObj && AllParameterObj[name] ? AllParameterObj[name] : name);
    }




    function getSurveyByType(data) {
      let list = []
      if (getSurveyListData && getSurveyListData.length > 0) {
        getSurveyListData.forEach((item) => {
          list.push(item)
        })
      }
      return list
    }

    return (
      <>
        <div className="h-screen overflow-hidden ">
          <Header
            ClientProfileData={ClientProfileData}
            handleLogout={this.handleLogout}
            props={this.props}
            companyName={
              getCompanyNameOnlyData && getCompanyNameOnlyData.name
                ? getCompanyNameOnlyData.name
                : ""
            }
            sidebarToggle={this.sidebarToggle}
          />

          <div className="flex w-full" style={{ height: "calc(100% - 2rem)" }}>
            <Sidebar
              onOverview={this.onOverview}
              showSidebar={showSidebar}
              handleSidebar={this.handleSidebar}
              accessAllow={accessAllow}
              routeLink={this.routeLink}
              showMobileSidebar={this.state.showMobileSidebar}
              sidebarToggle={this.sidebarToggle}

            />
            <main className="flex-1 w-full overflow-hidden overflow-y-auto h-[calc(100vh-4rem)] bg-[#f9fafb] ">

              {showSurveyLibrary ?
                showQuestionLibrary ?
                  <QuestionLibrary
                    handleSurveyLibraryClose={this.handleSurveyLibraryClose}
                    getSurveyFromLibData={getSurveyFromLibData}
                    handleSurveyLibQuestions={this.handleSurveyLibQuestions}
                    CurrentSurveyData={CurrentSurveyData}
                    handleADDpackage={this.handleADDpackage}
                    packageList={packageList}
                    handleSuveyPackageSubmit={this.handleSuveyPackageSubmit}


                    isPackageSelected={isPackageSelected}
                    handlePackageSelection={this.handlePackageSelection}
                    savePackage={savePackage}
                    QuestionLibraryClose={this.QuestionLibraryClose}

                    GetParameterLabel={GetParameterLabel}

                  />
                  :
                  <SurveyLibrary
                    handleSurveyLibraryClose={this.handleSurveyLibraryClose}
                    getSurveyFromLibData={getSurveyFromLibData}
                    handleSurveyLibQuestions={this.handleSurveyLibQuestions}
                    CurrentSurveyData={CurrentSurveyData}
                    handleADDpackage={this.handleADDpackage}
                    packageList={packageList}
                    handleSuveyPackageSubmit={this.handleSuveyPackageSubmit}
                    crrLifecycleStageId={crrLifecycleStageId}
                    handleViewPackage={this.handleViewPackage}
                    selectStage={this.selectStage}
                  />
                :


                <>
                  {(accessAllow("viewSurvey")) ?
                    <div className="p-4 mx-auto space-y-4 md:p-6 ">
                      <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
                        <div className="flex items-center space-x-4 ">
                          <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">
                            {"Customer Experience"}
                          </h1>
                        </div>

                        <div className="items-center hidden text-sm md:flex">
                          <span className="mx-2 material-symbols-rounded">home</span>
                          <span>/</span>
                          <span className="px-2">My survey</span>
                          <span>/</span>
                          <span className="px-2 font-semibold">{"Customer Experience"}</span>
                        </div>
                      </div>


                      <div className="items-center justify-between space-x-6 md:flex">
                        {/* left side */}
                        <div className="flex items-center w-full space-x-2 overflow-x-auto border-b whitespace-nowrap">
                          <h1
                            onClick={() => this.setState({ showTab: 1 })}
                            className={
                              showTab === 1
                                ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                                : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-slate-600   rounded-t-md border"
                            }
                          >
                            {"Surveys"}
                          </h1>

                          <h1
                            onClick={() => this.setState({ showTab: 2 })}
                            className={
                              showTab === 2
                                ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                                : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-slate-600   rounded-t-md border  "
                            }
                          >
                            {"Relationship List"}
                          </h1>
                        </div>
                      </div>

                      {
                        showTab === 1 ? (
                          <>

                            <div className="flex flex-col justify-center w-full gap-4 my-6 md:items-center md:flex-row md:justify-between">

                              <div className="flex flex-col gap-4 md:flex-row md:items-center ">
                                <div className="flex justify-center md:justify-start">
                                  <div className="flex items-center justify-between w-full overflow-hidden text-sm bg-white border rounded-md md:w-auto ">
                                    <span
                                      className="p-2 rounded-l-full material-symbols-outlined text-slate-600"
                                      style={{ fontSize: "1.4rem" }}
                                    >
                                      search
                                    </span>
                                    <input
                                      type="search"
                                      name="search"
                                      onChange={this.handleSearchSurvey}
                                      className="w-full py-2 md:w-48 focus:outline-none placeholder:text-slate-600"
                                      placeholder="Search"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="flex flex-row items-center gap-2">

                                <div className='hidden gap-2 md:flex'>

                                  {showGrid ?
                                    <button onClick={() => this.handleGridView(!showGrid)} className="flex items-center justify-center p-2 bg-white border rounded-md">
                                      <span className="material-symbols-rounded  text-[1.5rem] text-[#2196f3]">
                                        grid_view
                                      </span>
                                    </button>
                                    :
                                    <button onClick={() => this.handleGridView(!showGrid)} className="flex items-center justify-center p-2 bg-white border rounded-md">
                                      <span className="material-symbols-rounded  text-[1.5rem]">
                                        format_list_bulleted
                                      </span>
                                    </button>
                                  }

                                  {!showGrid ?
                                    <button onClick={() => this.handleGridView(!showGrid)} className="flex items-center justify-center p-2 bg-white border rounded-md">
                                      <span className="material-symbols-rounded  text-[1.5rem] text-[#2196f3]">
                                        grid_view
                                      </span>
                                    </button>
                                    :

                                    <button onClick={() => this.handleGridView(!showGrid)} className="flex items-center justify-center p-2 bg-white border rounded-md">
                                      <span className="material-symbols-rounded  text-[1.5rem] ">
                                        format_list_bulleted
                                      </span>
                                    </button>
                                  }
                                </div>

                                <button
                                  onClick={() =>
                                    this.handleCreateSurveyPopup()
                                  }
                                  className="bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] hover:bg-[#1E88E5] transition-all duration-200 md:w-auto w-full md:py-2 py-3 px-4 rounded-md text-center justify-center cursor-pointer transform flex items-center text-white space-x-1 md:text-sm text-md"
                                >

                                  <TbPlus className='text-[1.5rem]' />
                                  <span className="">Create New</span>
                                </button>

                              </div>
                            </div>




                            <div className={showGrid ? "grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 my-6" : "grid  grid-cols-1 gap-4 my-6"}>

                              {showSurveyList ?
                                <CustomerSurveyList
                                  getSurveyListData={getSurveyListData}
                                  isLifecycle={false}
                                  getSurveyListTotal={getSurveyListTotal}

                                  onDashboard={this.onDashboard}
                                  onEditSurvey={this.onEditSurvey}
                                  onPreviewSurvey={this.onPreviewSurvey}
                                  onReportSurvey={this.onReportSurvey}
                                  onPublishSurvey={this.onPublishSurvey}
                                  onDeleteSurvey={this.onDeleteSurvey}
                                  SHOW_ELEMENT={SHOW_ELEMENT}

                                  indexA={indexA}
                                  indexB={indexB}

                                  onOptionDot={this.onOptionDot}

                                  crrSurvey={crrSurvey}

                                  LanguageObj={LanguageObj}
                                  CurrentLanguage={CurrentLanguage}

                                  onUpdateSurvey={this.onUpdateSurvey}

                                  isCrole={isCrole}

                                  CLIENT_ID={CLIENT_ID}
                                  CLIENT_ROLE={CLIENT_ROLE}
                                  accessAllow={accessAllow}
                                  categoryType={0}
                                  onNoDashboardModal={this.onNoDashboardModal}
                                  showGrid={showGrid}

                                />
                                :
                                null}
                            </div>
                          </>
                        ) : null
                      }


                      {
                        showTab === 2 ? (
                          <>
                            <div className="w-full h-full" >
                              <div className="bg-white rounded-lg shadow-sm ">
                                <div className="space-y-2">

                                  {getCustEmpRelationListData && getCustEmpRelationListData.length > 0 ?
                                    <div className='p-4 space-y-6 bg-white border rounded-lg md:p-6 '>

                                      <div className='flex flex-wrap items-center justify-between gap-4 '>
                                        <h1 className='font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap'>Relationship List</h1>

                                        <div className='flex flex-row flex-wrap gap-2'>

                                          <div className="flex items-center justify-between w-full overflow-hidden text-sm bg-white border rounded-md md:w-auto ">
                                            <span
                                              className="p-2 rounded-l-full material-symbols-outlined text-slate-600"
                                              style={{ fontSize: "1.4rem" }}
                                            >
                                              search
                                            </span>
                                            <input
                                              type="search"
                                              name="search"
                                              onChange={this.handleSearchSurvey}
                                              className="w-full py-2 md:w-48 focus:outline-none placeholder:text-slate-600"
                                              placeholder="Search"
                                            />
                                          </div>

                                          <button
                                            onClick={() =>
                                              this.handleRelationshipUpload()
                                            }
                                            className="bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] transition-all duration-200 md:w-auto w-full md:py-2 py-3  px-4 rounded-md text-center justify-center cursor-pointer transform  flex items-center text-white space-x-1 md:text-sm  text-md whitespace-nowrap"
                                          >
                                            <span className="text-white material-symbols-outlined ">
                                              add
                                            </span>{" "}
                                            <h1 className="">Add Relationship</h1>
                                          </button>

                                        </div>
                                      </div>


                                      <table className='w-full border border-collapse text-sm  text-[#3D405B]' >
                                        <tr className='bg-slate-100'>
                                          <th className='p-2 font-semibold border-r' >S.no.</th>
                                          <th className='p-2 font-semibold border-r' >{"Customer Id"}</th>
                                          <th className='p-2 font-semibold border-r' >{"Employee Id"}</th>
                                          <th className='p-2 font-semibold border-r' >{"Employee Relation"}</th>
                                        </tr>
                                        {getCustEmpRelationListData && getCustEmpRelationListData.length > 0 ?
                                          getCustEmpRelationListData.map((item, index) =>

                                            <tr className='p-2 text-sm bg-white border-t '>
                                              <td className='p-2 text-center text-[#3D405B] border-r' >{(index + 1).toString() + ""}</td>
                                              <td className='p-2 text-center text-sm text-[#3D405B] border-r' >{item && item["cust_id"] ? item["cust_id"] : "-"}</td>
                                              <td className='p-2 text-center text-sm text-[#3D405B] border-r' >{item && item["emp_id"] ? item["emp_id"] : "-"}</td>
                                              <td className='p-2 text-center text-sm text-[#3D405B] border-r' >{item && item["emp_relation"] ? item["emp_relation"] : "-"}</td>
                                            </tr>

                                          ) : null}
                                      </table>

                                    </div>
                                    : null}

                                </div>

                              </div>



                            </div>

                          </>
                        ) : null
                      }

                    </div>
                    :
                    <>
                      {!loading ?
                        <div className="container h-full px-4 py-12 mx-auto overflow-hidden overflow-y-auto" style={{ height: 'calc(100% - 4rem)' }}>
                          <div className="flex items-center justify-center w-full h-full">
                            <div className="text-center">
                              <img alt='empty_survey' src="img/empty_survey.png" className="mb-4" />
                              <h1 className="text-xl font-semibold text-gray-500 cursor-default">You have no access <br /> to this module!</h1>
                            </div>
                          </div>
                        </div>
                        : null}
                    </>

                  }
                </>}

            </main>



            {showCreateSurveyPopup ?
              (accessAllow("createSurvey")) ?
                <AddSurvey
                  handleCreateSurveyPopupClose={this.handleCreateSurveyPopupClose}
                  handleAddSurveyInput={this.handleAddSurveyInput}
                  handleAddSurveySubmit={this.handleAddSurveySubmit}
                  surveyFormData={surveyFormData}
                  surveyValidation={surveyValidation}
                  handleSurveyType={this.handleSurveyType}
                  handleSurveyRole={this.handleSurveyRole}
                  isCrole={isCrole}
                  showStagesListModal={this.state.showStagesListModal}
                  getLifecycleStageListData={getLifecycleStageListData}
                  selectStage={this.selectStage}
                  crrLifecycleStageId={crrLifecycleStageId}

                  isModule={null}
                  selectCreateType={this.selectCreateType}
                  selectedSurveyType={this.state.selectedSurveyType}

                  allLifecycleListenListData={allLifecycleListenListData}
                />
                : null
              : null}


            {showUpdateSurveyModal ?
              (accessAllow("uploadUser")) ?
                <UpdateSurvey
                  handleCreateSurveyPopupClose={this.closeUpdateSurvey}
                  updateformSurveyData={updateformSurveyData}
                  handleUpdateSurveyInput={this.handleUpdateSurveyInput}
                  updatehandleSurveyRole={this.updatehandleSurveyRole}
                  handleUpdateSurveySubmit={this.handleUpdateSurveySubmit}
                  isCrole={isCrole}
                />
                : null
              : null}


            {showChooseSurveyModal ?
              <ChooseSurvey
                handleCreateSurveyPopupClose={this.handleChooseSurveyPopupClose}
                handleCreateEditableSurvey={this.handleCustomSurvey}
                handleCreateSurveyFromLibrary={this.handleCreateSurveyFromLibrary}

              />
              : null}

            {CurrentSurveyData ?
              <AddSurveyLibModal
                CurrentSurveyData={CurrentSurveyData}
                handleUseSurveyPage={this.handleUseSurveyPage}

              />
              : null}


            {confirmDeleteSurvey ?
              <DeleteSurveyConfirm
                CurrentSurveyName={CurrentSurveyName}
                handleSendSingleMail={this.onSurveyDelete}
                handleConfirmMailClose={this.handleConfirmDeleteSurveyClose}
              />
              : null}


            {showNODashboardModal ?
              <NoDashboardModal
                handleConfirmMailClose={this.onNoDashboardModal}
                handleConfirmMailView={this.handleConfirmMailView}
                surveyFormData={this.state.crrSurveyId}
                crrCategoryType={crrCategoryType}
              />
              : null}


            {showVentLinkModal ?
              <VentLinkModal
                handleClose={() => this.setState({ showVentLinkModal: false })}
                generateCompanyBotLinkData={generateCompanyBotLinkData}
              />
              : null}










            {showUploadPreview ?
              <>
                <UploadPreviewCustomer
                  loadedDataSheet={loadedDataSheet}
                  closeUploadPreview={this.closeUploadPreview}
                  saveUploadRelation={this.saveUploadRelation}
                  handleDataChange={this.handleDataChange}
                  SheetJSFT={SheetJSFT}
                  saveCustEmpRelationListData={saveCustEmpRelationListData}
                />
              </>
              : null}



            {/* {showRelationModal ?
              <>
                <div className="top-0 right-0 z-40 flex items-center justify-center w-full h-full bg-black ffixed bg-opacity-40 xl:h-screen" >
                  <div className="bg-white rounded-lg shadow-sm 2xl:w-6/12 xl:w-8/12 w-11/12  2xl:h-[calc(100vh_-_18rem)] xl:h-[calc(100vh_-_10rem)] md:h-[calc(100vh_-_10rem)] h-[calc(100vh_-_6rem)] overflow-y-auto ">
                                       <div className="sticky top-0 flex items-start justify-between p-4 bg-white border-b ">
                      <div className='space-y-2' >
                        <h2 className="text-lg font-semibold md:text-xl test-gray-800 ">Upload  Partcipants Relationship Data</h2>
                        <p className='text-sm text-gray-500 md:text-base' > Please upload  Participants Relationship Data according to sample and below guidelines.</p>
                      </div>

                      <div onClick={() => this.closeRelationshipModal(false)} className="p-2 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200 " >
                        <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path></svg>
                      </div>
                    </div>


                    <div className='' >
                      <div className='p-4 space-y-2 text-sm text-slate-800' >
                        <h1 className='text-lg font-semibold text-slate-800' > Guidelines for uploading the Participants Relationship Data</h1>
                        <ol className='pl-6 space-y-1 list-disc' >


                          <li className='' > <b className=''>cust_id:</b> Cutomer Id</li>
                          <li className='' > <b className=''>cust_id:</b> Employee Id</li>
                          <li className='' > <b className=''>Employee Relation:</b> It defines the employee relationship with Cutomer.</li>



                        </ol>

                        <p>{"1.  Cust Id is mandatory"}</p>
                        <p>{"2.  Emp Id is mandatory"}</p>
                        <p>{"3. Employee Relation is mandatory"}</p>

                      </div>
                    </div>



                    <div className="p-4 space-y-4 border-t">
                      <div className='grid grid-cols-1 gap-4 xl:grid-cols-2 md:grid-cols-2'>
                       
                        <div onClick={() => this.downLoadSampleCSV()} className='flex items-start p-2 px-4 space-x-4 bg-blue-100 rounded shadow-md cursor-pointer 2xl:items-center' >
                          <span className="material-symbols-outlined">
                            cloud_download
                          </span>
                          <div>
                            <h1 className='text-sm font-medium md:text-base' >Download Sample Data</h1>
                            <p className='text-sm text-gray-500' >See the sample data</p>
                          </div>
                        </div>

                        <input type="file"
                          id="file" accept={SheetJSFT} onChange={this.handleDataChange} hidden />
                        <label for="file" className='bg-blue-100 rounded shadow-md cursor-pointer' >
                          <div className='flex items-start p-2 px-4 space-x-4 2xl:items-center ' >

                            <span className="material-symbols-outlined">
                              cloud_upload
                            </span>
                            <div>
                              <h1 className='text-sm font-medium md:text-base' >Master Data Upload</h1>
                              <p className='text-sm text-gray-500' >Upload the master data</p>
                            </div>
                          </div>
                        </label>

                      </div>



                      <div className="hidden" >
                        <button onClick={() => this.downLoadSampleCSV()} className=" border border-green-500 rounded-md px-6 py-1.5 text-xs text-green-500 focus:outline-none" >Sample CSV</button>

                        <button onClick={this.handleDataFile} className="ml-22 border border-blue-500 rounded-md px-6 py-1.5 text-xs text-blue-500 focus:outline-none">Continue</button>
                      </div>

                    </div>





                  </div>



                </div>






              </>
              :
              null} */}




            {showRelationModal ? (
              <div className="fixed top-0 right-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-40 xl:h-screen">
                <div className="w-11/12 mx-auto bg-white rounded-lg shadow-sm xl:w-6/12 lg:w-9/12 ">
                  {/* head */}
                  <div className="flex items-center justify-between p-4 border-b md:px-8">
                    <div className='space-y-2' >
                      <h2 className="text-lg font-semibold md:text-xl text-[#3D405B]">Upload  Partcipants Relationship Data</h2>
                      <p className='text-sm text-[#3D405B] opacity-70 md:text-base' >Please upload  Participants Relationship Data according to sample and below guidelines.</p>
                    </div>
                    {/* <h2 className="text-lg font-semibold md:text-xl test-[#3D405B]">Upload 360 Partcipants Data</h2>
                    <p className='text-sm text-gray-500 md:text-base' >Please upload 360 Participants data according to sample and below guidelines.</p> */}
                    <div onClick={() => this.closeRelationshipModal(false)} className="p-2 rounded-full cursor-pointer bg-slate-100 hover:bg-slate-200">
                      <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
                        <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path>
                      </svg>
                    </div>
                  </div>

                  <div className="m-4 space-y-8 md:m-8 ">
                    {/* data here */}
                    <div className="p-4 space-y-2 text-sm border rounded-md text-[#3D405B]">
                      <h1 className="pb-2 text-lg font-semibold border-b text-[#3D405B]">Guidelines for uploading the Participants Relationship Data</h1>
                      <ol className="list-disc pl-6 space-y-1.5 py-3 text-[#3D405B] ">
                        <li className="capitalize"><b className=''>cust_id:</b> Cutomer Id</li>
                        <li className="capitalize"><b className=''>cust_id:</b> Employee Id</li>
                        <li className="capitalize"><b className=''>Employee Relation:</b> It defines the employee relationship with Cutomer.</li>
                      </ol>
                      <p>{"1.  Cust Id is mandatory"}</p>
                      <p>{"2.  Emp Id is mandatory"}</p>
                      <p>{"3. Employee Relation is mandatory"}</p>
                    </div>

                    <div className="space-y-4">
                      <div className="grid grid-cols-1 gap-4 xl:grid-cols-4 md:grid-cols-2">
                        {/* 1 */}
                        <div
                          onClick={() => this.downLoadSampleCSV()}
                          className="flex items-start p-2 px-4 space-x-4 rounded shadow-sm cursor-pointer 2xl:items-center bg-sky-100 md:col-span-2"
                        >
                          <span class="material-symbols-outlined p-2 bg-white rounded-full text-blue-500 shadow-sm">
                            download
                          </span>
                          <div>
                            <h1 className="text-sm font-medium md:text-base text-[#3D405B] ">Download Sample Data</h1>
                            <p className="text-sm text-[#3D405B]">See the sample data</p>
                          </div>
                        </div>

                        {/* 2 */}
                        <input
                          type="file"
                          id="file"
                          accept={SheetJSFT}
                          onChange={this.handleDataChange}
                          hidden
                        />
                        <label
                          for="file"
                          className="rounded shadow-sm cursor-pointer bg-sky-100 md:col-span-2"
                        >
                          <div className="flex items-start p-2 px-4 space-x-4 2xl:items-center ">
                            <span class="material-symbols-outlined p-2 bg-white rounded-full text-blue-500 shadow-sm">
                              upload
                            </span>
                            <div>
                              <h1 className="text-sm font-medium md:text-base text-[#3D405B] ">
                                Upload Master Data
                              </h1>
                              <p className="text-sm text-[#3D405B]">
                                Upload master data as per the guidelines
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>

                      <div className="hidden">
                        <button
                          onClick={() => this.downLoadSampleCSV()}
                          className=" border border-green-500 rounded-md px-6 py-1.5 text-xs text-green-500 focus:outline-none"
                        >
                          Sample CSV
                        </button>

                        <button
                          onClick={this.handleDataFile}
                          className="ml-22 border border-blue-500 rounded-md px-6 py-1.5 text-xs text-blue-500 focus:outline-none"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}






















































            {showRelationTable ?
              <>
                <div className="fixed top-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-60 xl:h-screen" >
                  <div className="w-11/12 bg-white rounded-lg shadow-sm 2xl:w-6/12 xl:w-8/12 ">
                    {/* head */}

                    <div className="sticky top-0 flex items-start justify-between p-4 border-b ">

                      <h2 className="text-lg font-semibold md:text-xl test-gray-800">Customer Employee Relationship</h2>

                      <div onClick={() => this.closeRelationshipTable(false)} className="p-2 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200 " >
                        <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path></svg>
                      </div>
                    </div>


                    <div className="space-y-2">

                      {getCustEmpRelationListData && getCustEmpRelationListData.length > 0 ?
                        <div className='p-4 space-y-6 bg-white rounded md:p-6 customscroll5' style={{ overflowY: 'auto', 'height': '400px' }}>

                          <h1 className='text-xl font-medium '>Relationship Table</h1>

                          <table className='w-full border border-collapse ' >
                            <tr>
                              <th className='p-2 font-medium border-r' >S.no.</th>
                              <th className='p-2 font-medium border-r' >{"Customer Id"}</th>
                              <th className='p-2 font-medium border-r' >{"Employee Id"}</th>
                              <th className='p-2 font-medium border-r' >{"Employee Relation"}</th>
                            </tr>
                            {getCustEmpRelationListData && getCustEmpRelationListData.length > 0 ?
                              getCustEmpRelationListData.map((item, index) =>

                                <tr className='p-2 text-sm bg-white border-t '>
                                  <td className='p-2 font-medium text-center text-gray-800 border-r' >{(index + 1).toString() + ""}</td>
                                  <td className='p-2 text-center text-blue-600 border-r' >{item && item["cust_id"] ? item["cust_id"] : "-"}</td>
                                  <td className='p-2 text-center text-blue-600 border-r' >{item && item["emp_id"] ? item["emp_id"] : "-"}</td>
                                  <td className='p-2 text-center text-gray-600 border-r' >{item && item["emp_relation"] ? item["emp_relation"] : "-"}</td>
                                </tr>

                              ) : null}
                          </table>

                        </div>
                        : null}

                    </div>





                  </div>



                </div>

              </>
              :
              null}



          </div>

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(CustomerSurvey);
