import React, { Component } from "react";
import { connect } from "react-redux";
// import IndexKPI from './Components/IndexKPI';
// import ReactSpeedometer from "react-d3-speedometer";

// import Happiness from './OverIndex/HappinessOverview/Happiness';
// import Engagement from './OverIndex/EngagementOverview/Engagement';
// import Stress from './OverIndex/StressOverview/Stress';
// import Manager from './OverIndex/Manager';
// import QuestionCard from "./Components/QuestionTypeCards/QuestionCard";
import QuestionCard from "../../Dashboard/Overview/Components/QuestionTypeCards/QuestionCard";

import SurveyReport from "./SurveyReport/SurveyReport";

// import OverviewNPS from './Components/NPSCard/OverviewNPS';
import OverviewNPSZeroToTen from "./Components/NPSCardZeroToTen/OverviewNPSZeroToTen";
import ResponseHeader from "../../CommonComponents/ResponseHeader";

import GaugeScore from "./Charts/GaugeScore";
import GaugeScoreTwo from "./Charts/GaugeScoreTwo";
import GaugeScoreThree from "./Charts/GaugeScoreThree";
import Demographics from "./Charts/Demographics";
import EmployeesConsider from "./Charts/EmployeesConsider";
// import ReactEcharts from "echarts-for-react";
import DemographicFilter from "./Filters/DemographicFilter";
// import WordChart from "./Charts/WordChart";
// import WordDetails from "./Charts/WordDetails";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerDetailsIndexType: 0,
      showManagerList: true,
      CurrentManagerName: "Select Manager",
      optionType: 1,

      showOverview: true,

      FilterList: [],
      showFilter: false,
      showValue: "",

      allIndexList: [
        {
          name: "Happiness",
          template: 1,
          score: "NaN",
          type: "Score",
          color: "#FF4081",
          tailwindcolor: "bg-pink-100 text-pink-500 hover:bg-pink-500",
          image: "/img/overview_assets/with_circle/happiness-index.png",
        },
        {
          name: "Engagement",
          template: 2,
          score: "NaN",
          type: "Percentage",
          color: "#2196F3",
          tailwindcolor: "bg-blue-100 text-blue-500 hover:bg-blue-500",
          image: "/img/overview_assets/with_circle/engagement-index.png",
        },
        {
          name: "Hope",
          template: 3,
          score: "NaN",
          type: "Percentage",
          color: "#009688",
          tailwindcolor: " bg-green-100 text-green-500 hover:bg-green-500",
          image: "/img/overview_assets/with_circle/hope-index.png",
        },
        {
          name: "Stress",
          template: 4,
          score: "NaN",
          type: "Score",
          color: "#EF5350",
          tailwindcolor: " bg-red-100 text-red-500 hover:bg-red-500",
          image: "/img/overview_assets/with_circle/stress-index.png",
        },
        {
          name: "Manager",
          template: 5,
          score: "NaN",
          type: "Percentage",
          color: "#9C27B0",
          tailwindcolor: "bg-purple-100 text-purple-500 hover:bg-purple-500",
          image: "/img/overview_assets/with_circle/manager-index.png",
        },
        {
          name: "Leadership",
          template: 6,
          score: "NaN",
          type: "Percentage",
          color: "#FFB300",
          tailwindcolor: " bg-yellow-100 text-yellow-500 hover:bg-yellow-500",
          image: "/img/overview_assets/with_circle/leadership-index.png",
        },
        {
          name: "Value",
          template: 7,
          score: "NaN",
          type: "Percentage",
          color: "#C0CA33",
          tailwindcolor: " bg-lime-100 text-[#b2b33f] hover:bg-[#b2b33f] ",
          image: "/img/overview_assets/with_circle/value-index.png",
        },

        {
          name: "Safety",
          template: 9,
          score: "NaN",
          type: "Percentage",
          color: "#b2b33f",
          tailwindcolor: " bg-green-100 text-green-500 hover:bg-green-500",
          image: "/img/overview_assets/with_circle/safety-index.png",
        },
        {
          name: "Diversity",
          template: 10,
          score: "NaN",
          type: "Percentage",
          color: "#3F51B5",
          tailwindcolor: " bg-indigo-100 text-indigo-500 hover:bg-indigo-500",
          image: "/img/overview_assets/with_circle/diversity-index.png",
        },
      ],
      paramName: ""
    };
  }

  componentDidMount() { }

  openSurveyResponse = () => {
    this.setState({ showOverview: false });
  };

  closeResponseAnalytics = () => {
    this.setState({ showOverview: true });
  };

  onOptionType = (value) => {
    this.setState({ optionType: value });
  };

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handleDmgExitCall } = this.props;

    this.setState({ FilterList: finalFilter });

    handleDmgExitCall(finalFilter);
  };


  handleSelectChart = (params) => {
    this.setState({
      paramName: params.name
    }, () => {
      let { handleSelectChart } = this.props;
      handleSelectChart(params.name)
    })

  }

  handleGoBack = () => {
    this.setState({ showOverview: true });
  }


  render() {
    let {
      FilterList,
      optionType,
      allIndexList,
      showOverview,
      getIndexByFilterDMGData,
      paramName
    } = this.state;
    let {
      rolkeywordbyemployeeData,
      getEMPFilterLabel,
      loading,
      onCustomScoreCard,
      getQuestionAvgScoreData,
      getIndexData,
      SurveyList,
      CurrentSurveyId,
      onOptionType,

      getHappinessIndexV2Data,
      getEngagementIndexV2Data,
      getStressIndexV2Data,
      getManagerIndexV2Data,

      getRespondantDetailsData,


      EmpFilterData,
      getIndexFilterData,

      getQuestionOthersResultData,
      getExitQuestionOne,
      getExitQuestionAvgData,
      getExitQuestionYesNoData,
      getExitQuestionAllCountData,
      getExitQuestionAllDemographicsData,
      handleSelectChart,
    } = this.props;

    console.log(
      "OverViewPage _____________________1111111111111111_rolkeywordbyemployeeData",
      rolkeywordbyemployeeData
    );

    let surveyId = "";
    // if(SurveyList && SurveyList.length>0){

    //   if(CurrentSurveyId){
    //     surveyId = CurrentSurveyId;
    //   }
    //   else{
    //     surveyId = SurveyList[0].id;
    //   }

    // }
    surveyId = CurrentSurveyId;

    //console.log('x-------->>>>> IndexList:-------->>>>>', getIndexData)

    let overviewScoreList = [];
    let IndexList = [];

    function getIndexName(key) {
      let name = "";
      if (key === 1) {
        name = "Happiness";
      } else if (key === 2) {
        name = "Engagement";
      } else if (key === 3) {
        name = "Hope";
      } else if (key === 4) {
        name = "Stress";
      } else if (key === 5) {
        name = "Manager";
      } else if (key === 6) {
        name = "Leadership";
      } else if (key === 7) {
        name = "Safety";
      } else if (key === 9) {
        name = "Diversity";
      } else {
        name = "NaN";
      }

      return name;
    }

    function checkIndex(key) {
      let check = false;
      if (key === 1) {
        check = true;
      } else if (key === 2) {
        check = true;
      } else if (key === 3) {
        check = true;
      } else if (key === 4) {
        check = true;
      } else if (key === 5) {
        check = true;
      } else if (key === 6) {
        check = true;
      } else if (key === 7) {
        check = true;
      } else if (key === 9) {
        check = true;
      } else {
        check = false;
      }

      return check;
    }
    let IndexListNew = [];
    let newFilterObj = {};
    if (getIndexData) {
      IndexList = Object.keys(getIndexData).map((key) => [
        Number(key),
        getIndexData[key],
      ]);

      if (getIndexFilterData) {
        if (IndexList && IndexList.length > 0) {
          IndexList.forEach((data) => {
            if (checkIndex(data[0])) {
              newFilterObj[data[0]] = getIndexFilterData[data[0]];
            }
          });
        }

        IndexListNew = Object.keys(newFilterObj).map((key) => [
          Number(key),
          newFilterObj[key],
        ]);
      }
      //console.log('IndexListNew: ',IndexListNew)

      if (IndexListNew && IndexListNew.length > 0) {
        IndexListNew.forEach((data) => {
          let GetIndex = allIndexList.findIndex(
            (prev) => prev.template === data[0]
          );
          if (getIndexName(data[0]) !== "NaN" && data && data[0] && data[1]) {
            overviewScoreList.push({
              template: data[0],
              score: data[1].toFixed(2),
              name: getIndexName(data[0]),
              type: GetIndex !== -1 ? allIndexList[GetIndex].type : "score",
              color: GetIndex !== -1 ? allIndexList[GetIndex].color : "#757575",
              image: GetIndex !== -1 ? allIndexList[GetIndex].image : null,
            });
          }
        });
      }
    }

    let OverViewIndexList = overviewScoreList;

    if (allIndexList && allIndexList.length > 0) {
      allIndexList.forEach((item) => {
        let GetIndex = OverViewIndexList.findIndex(
          (prev) => prev.template === item.template
        );
        if (GetIndex === -1) {
          OverViewIndexList.push({
            ...item,
            type: item.type,
            color: item.color,
            image: item.image,
          });
        }
      });
    }

    // console.log('OverViewIndexList: ',OverViewIndexList)

    function FormattFun(inputData, isSummary) {
      let RankObj = 0;

      let LIST = [];
      let prevTemList = [];
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {
          let templateId = ques.total[0]._id.qtemplate;

          let temp = {
            name:
              ques && ques.question && ques.question[0] && ques.question[0].name
                ? ques.question[0].name
                : "",
            answerList: [],
            rank: RankObj && RankObj[templateId] ? RankObj[templateId] : 100,
            templateId: templateId,
          };
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading:
                  item && item._id && item._id.heading ? item._id.heading : "",
                parentId:
                  item && item._id && item._id.parentId
                    ? item._id.parentId
                    : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId,
              };
              temp["answerList"].push(ans);
            });
          }

          if (isSummary) {
            let getIndex = prevTemList.findIndex((prev) => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId);
            }
          } else {
            LIST.push(temp);
          }
        });
      }
      return LIST;
    }

    let OthersQuesList = FormattFun(getQuestionOthersResultData, true).sort(
      (a, b) => a.rank - b.rank
    );

    let { onAllQuestions } = this.props;

    // console.log("getQuestionAvgScoreData---------->", getQuestionAvgScoreData);

    let itemList =
      getQuestionAvgScoreData &&
        getQuestionAvgScoreData[0] &&
        getQuestionAvgScoreData[0]["total"]
        ? getQuestionAvgScoreData[0]["total"]
        : [];

    let parameterList = [];
    if (itemList && itemList.length > 0) {
      itemList.forEach((item) => {
        let getIndex = parameterList.findIndex(
          (prev) => prev.index === item["_id"].parameterBucketName
        );
        if (getIndex === -1) {
          parameterList.push({
            index: item["_id"].parameterBucketName,
            data: [
              {
                count: item.count,
                sum: item.sum,
                ...item["_id"],
              },
            ],
          });
        } else {
          parameterList[getIndex]["data"].push({
            count: item.count,
            sum: item.sum,
            ...item["_id"],
          });
        }
      });
    }

    let parameter2List = [];
    if (parameterList && parameterList.length > 0) {
      parameterList.forEach((item) => {
        let temp = {
          index: item.index,
          DimenionList: [],
        };
        if (item && item.data && item.data.length > 0) {
          item.data.forEach((item2) => {
            let getIndex = temp["DimenionList"].findIndex(
              (prev) => prev.dimension === item2.parameterDimensionName
            );
            if (getIndex === -1) {
              temp["DimenionList"].push({
                dimension: item2.parameterDimensionName,
                inner: [
                  {
                    count: item2.count,
                    sum: item2.sum,
                    answer: item2.answer,
                  },
                ],
              });
            } else {
              temp["DimenionList"][getIndex]["inner"].push({
                count: item2.count,
                sum: item2.sum,
                answer: item2.answer,
              });
            }
          });
        }

        parameter2List.push(temp);
      });
    }

    // console.log("OverViewIndexList---------->", OverViewIndexList);
    // console.log("parameter2List---------->", parameter2List);

    let OptionList = [
      {
        name: "Dashboard",
        template: 1,
      },
      {
        name: "Question Analysis",
        template: 2,
      },
    ];


    let ParticipationObj = {
      total: 0,
      completed: 0,
      mailSent: 0,
      clicked: 0,
      participated: 0,
      performance: 0,
      perfPlusSign: true,
    };

    if (getRespondantDetailsData && getRespondantDetailsData[0]) {
      let apiData = getRespondantDetailsData[0];
      ParticipationObj["total"] = apiData["total"] ? apiData["total"] : 0;
      ParticipationObj["completed"] = apiData["completed"]
        ? apiData["completed"]
        : 0;
      ParticipationObj["mailSent"] = apiData["mailSent"]
        ? apiData["mailSent"]
        : 0;
      ParticipationObj["clicked"] = apiData["clicked"] ? apiData["clicked"] : 0;
      ParticipationObj["participated"] =
        apiData["completed"] && apiData["total"]
          ? ((apiData["completed"] * 100) / apiData["total"]).toFixed(2)
          : 0;
    }

    let responseOption = {
      item1: {
        label: "Sent",
        value: ParticipationObj["total"],
      },
      item2: {
        label: "Responded",
        value: ParticipationObj["completed"],
      },
      item3: {
        label: "Response Rate",
        value: ParticipationObj["participated"] + "%",
      },
    };

    let list2 = [];

    if (getExitQuestionAllCountData && getExitQuestionAllCountData.length > 0) {
      let temp1 = getExitQuestionAllCountData.sort((b, a) => a.count - b.count);
      if (temp1 && temp1[0] && temp1[0]._id) {
        list2.push(temp1[0]._id);
      }
    }

    // console.log("OVERVIEW____getQuestionOthersResultData????", getQuestionOthersResultData);

    function getZeroToTen(lisV5, type) {
      let list = [];

      if (lisV5 && lisV5.length > 0) {
        list = lisV5.filter(
          (prev) =>
            (prev &&
              prev.total &&
              prev.total[0] &&
              prev.total[0]["_id"] &&
              prev.total[0]["_id"]["qtemplate"]
              ? prev.total[0]["_id"]["qtemplate"]
              : "") === type
        );
      }

      return list;
    }

    let xaXis1 = "Demographics";
    if (FilterList && FilterList.length > 0) {
      xaXis1 = FilterList[0]["level"];
    } else {
      xaXis1 =
        EmpFilterData && EmpFilterData.length > 0
          ? EmpFilterData[0]["name"]
          : "Demographics";
    }

    function keyWordList(elementEnner, type) {
      if (type === 0) {
        let list = elementEnner[0];
        let finalList = list.map((ele) => ele[0]);
        return finalList;
      } else if (type === 1) {
        let list = elementEnner[1];
        let finalList = list.map((ele) => ele);
        return finalList;
      }
      else if (type === 3) {
        let list = elementEnner[0];
        let finalList = list.map((ele) => ele);
        return finalList;
      }
    }



    let firstListName = [];

    if (getExitQuestionAllCountData && getExitQuestionAllCountData.length > 0) {
      let temp = getExitQuestionAllCountData.sort((b, a) => a.count - b.count);

      temp.forEach((element, index) => {
        if (element && element._id) {
          if (index === 0) {
            firstListName = element._id
          }
        }
      });
    }




    function getQueTemplateIn(templateId) {
      let check = false;
      if (templateId) {
        if ([1, 2, 11, 6, 5].includes(Number(templateId))) {
          check = true;
        }
      }
      return check;
    }
    function getQueTemplateIn16(list) {
      let count = 0;
      if (list && list.length > 0) {
        list.forEach((item) => {
          if (item && item.answerList && item.answerList[0] && item.answerList[0].template === 16) {
            count++
          }
        })
      }
      return count > 0 ? true : false
    }


    function getMsg(type) {
      if (type === 1) {
        return (<div className="pt-40 text-2xl text-center text-gray-400">Data not available!</div>)
      } else {
        return (<div className="pt-40 text-2xl text-center text-gray-400"></div>)
      }
    }

    let count = 0;
    if (OthersQuesList && OthersQuesList.length > 0) {
      OthersQuesList.forEach((item) => {
        if (getQueTemplateIn(item && item.answerList && item.answerList[0] && item.answerList[0].template) ? item.answerList[0].template : null) {
          count++
        }
        if (getQueTemplateIn16(getQuestionOthersResultData)) {
          count++
        }
      })
    }


    return (
      <>
        <main className="flex-1 w-full p-4 overflow-hidden overflow-y-auto  bg-[#f9fafb] md:p-6 ">
          {showOverview ? (
            <div className="space-y-4">
              <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Overview</h1>
              <div onClick={() => this.openSurveyResponse()}>
                <ResponseHeader responseOption={responseOption} />
                {/* <IndexKPI getRespondantDetailsData={getRespondantDetailsData} /> */}
              </div>

              {/* Tab */}
              <div className="items-center justify-between border-b md:flex">
                <div className="flex space-x-2 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 whitespace-nowrap tab-scroll">

                  {OptionList && OptionList.length > 0
                    ? OptionList.map((item) => (
                      <span onClick={() => this.onOptionType(item.template)}
                        className={optionType === item.template ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}>
                        {item && item.name ? item.name : ""}
                      </span>
                    ))
                    : null}
                </div>
              </div>


              {/* Overview Page Code optionType = 1 */}
              {optionType === 1 ? (
                <>
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
                    <div className="flex flex-col justify-between h-full p-6 bg-white border rounded-md">
                      <div className="w-full">
                        <h1 className="h-20 font-medium xl:text-xl text-lg text-[#3D405B]">
                          Employees that would recommend this organization to a
                          friend
                        </h1>
                        <div className="flex justify-center item-center">
                          <GaugeScore
                            // score={50}
                            getExitQuestionOne={getExitQuestionOne}
                          />
                        </div>
                        <h1 className="flex justify-center pb-4 -mt-6 item-center">
                          % of employees
                        </h1>
                        {/* <h1 className='flex items-center justify-center w-full h-40'>Graph</h1> */}
                        <div className="flex items-center justify-center space-x-8">
                          <div className="flex items-center space-x-2 ">
                            <div className="w-3 h-3 bg-red-500 rounded-full"></div>{" "}
                            <p>Bad</p>
                          </div>
                          <div className="flex items-center space-x-2 ">
                            <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>{" "}
                            <p>Moderate</p>
                          </div>
                          <div className="flex items-center space-x-2 ">
                            <div className="w-3 h-3 bg-green-500 rounded-full"></div>{" "}
                            <p>Good</p>
                          </div>
                        </div>
                      </div>

                      {/* <div className='flex items-center justify-center mt-6'>
                        <button className='flex items-center font-medium text-blue-500 cursor-pointer '> View More <span className="ml-2 material-symbols-outlined">
                          east
                        </span></button>
                      </div> */}
                    </div>

                    <div className="flex flex-col justify-between h-full p-6 bg-white border rounded-md">
                      <div className="w-full">
                        <h1 className="h-20 font-medium xl:text-xl text-lg text-[#3D405B]">
                          Employees that were paid fairly
                        </h1>
                        <div className="flex justify-center item-center">
                          <GaugeScoreTwo
                            getExitQuestionAvgData={getExitQuestionAvgData}
                          />
                        </div>
                        <h1 className="flex justify-center pb-4 -mt-6 item-center">
                          % of employees
                        </h1>
                        <div className="flex items-center justify-center space-x-8">
                          <div className="flex items-center space-x-2 ">
                            <div className="w-3 h-3 bg-red-500 rounded-full"></div>{" "}
                            <p>Bad</p>
                          </div>
                          <div className="flex items-center space-x-2 ">
                            <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>{" "}
                            <p>Moderate</p>
                          </div>
                          <div className="flex items-center space-x-2 ">
                            <div className="w-3 h-3 bg-green-500 rounded-full"></div>{" "}
                            <p>Good</p>
                          </div>
                        </div>
                      </div>

                      {/* <div className='flex items-center justify-center mt-6'>
                        <button className='flex items-center font-medium text-blue-500 cursor-pointer '> View More <span className="ml-2 material-symbols-outlined">
                          east
                        </span></button>
                      </div> */}
                    </div>

                    <div className="flex flex-col justify-between h-full p-6 bg-white border rounded-md">
                      <div className="w-full">
                        <h1 className="h-20 font-medium xl:text-xl text-lg text-[#3D405B]">
                          Employees that would consider returning
                        </h1>
                        <div className="flex justify-center item-center">
                          <GaugeScoreThree
                            getExitQuestionYesNoData={getExitQuestionYesNoData}
                          />
                        </div>
                        <h1 className="flex justify-center pb-4 -mt-6 item-center">
                          % of employees
                        </h1>
                        <div className="flex items-center justify-center space-x-8">
                          <div className="flex items-center space-x-2 ">
                            <div className="w-3 h-3 bg-red-500 rounded-full"></div>{" "}
                            <p>Bad</p>
                          </div>
                          <div className="flex items-center space-x-2 ">
                            <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>{" "}
                            <p>Moderate</p>
                          </div>
                          <div className="flex items-center space-x-2 ">
                            <div className="w-3 h-3 bg-green-500 rounded-full"></div>{" "}
                            <p>Good</p>
                          </div>
                        </div>
                      </div>

                      {/* <div className='flex items-center justify-center mt-6'>
                        <button className='flex items-center font-medium text-blue-500 cursor-pointer '> View More <span className="ml-2 material-symbols-outlined">
                          east
                        </span></button>
                      </div> */}
                    </div>
                  </div>

                  <div className="flex flex-col justify-between h-full p-6 bg-white rounded-md md:col-span-3">
                    <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">
                      Reasons for Leaving
                    </h1>
                    <EmployeesConsider
                      handleSelectChart={this.handleSelectChart}
                      getExitQuestionAllCountData={getExitQuestionAllCountData}
                    />
                    <div className="text-center">
                      <b>X-Axis: </b>
                      {" Count"}
                      {",  "}
                      <b>Y-Axis: </b>
                      {"Reasons for Leaving"}
                    </div>
                    <div className="flex items-center justify-center mt-6">
                      {/* <button className='flex items-center font-medium text-blue-500 cursor-pointer '> View More <span className="ml-2 material-symbols-outlined">
                          east
                        </span></button> */}
                    </div>
                  </div>


                  <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                    <div className="flex flex-col justify-between col-span-1 p-6 space-y-6 bg-white border rounded-md">
                      <div>
                        <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">
                          Reasons For Leaving Insight
                        </h1>
                        <h1 className="py-2 text-lg font-semibold" style={{ color: "#6536d1" }}>
                          {paramName ? paramName : firstListName}
                        </h1>
                      </div>
                      {rolkeywordbyemployeeData &&
                        rolkeywordbyemployeeData.list &&
                        rolkeywordbyemployeeData.list.length > 0 ? (
                        <>
                          <div className="flex flex-wrap items-center justify-center h-[320px] overflow-y-auto customscroll4">
                            {keyWordList(rolkeywordbyemployeeData.list, 0) &&
                              keyWordList(rolkeywordbyemployeeData.list, 0)
                                .length > 0
                              ? keyWordList(
                                rolkeywordbyemployeeData.list,
                                0
                              ).map((element) => (
                                <>
                                  <span className="p-2 px-4 my-2 mr-2 text-base font-medium bg-blue-100 rounded-full text-slate-800">
                                    {element}
                                  </span>
                                </>
                              ))
                              : null}
                          </div>
                        </>
                      ) : (
                        <div className="flex flex-wrap items-center justify-center">
                          {list2 && list2.length > 0
                            ? list2.map((element) => (
                              <>
                                <span className="p-2 px-4 my-2 mr-2 text-base font-medium bg-blue-100 rounded-full text-slate-800">
                                  {element}
                                </span>
                              </>
                            ))
                            : null}
                        </div>
                      )}
                      <div className="flex items-center justify-center"></div>
                    </div>

                    <div className="flex flex-col justify-between col-span-1 p-6 space-y-6 bg-white border rounded-md">
                      <div>
                        <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">
                          Reasons For Leaving Insight
                        </h1>
                        <h1 className="py-2 text-lg font-semibold" style={{ color: "#6536d1" }}>
                          {paramName ? paramName : firstListName}
                        </h1>
                      </div>
                      {rolkeywordbyemployeeData &&
                        rolkeywordbyemployeeData.list &&
                        rolkeywordbyemployeeData.list.length > 0 ? (
                        <>
                          <div className="h-[320px] overflow-y-auto customscroll4">
                            {keyWordList(rolkeywordbyemployeeData.list, 1) &&
                              keyWordList(rolkeywordbyemployeeData.list, 1)
                                .length > 0
                              ? keyWordList(
                                rolkeywordbyemployeeData.list,
                                1
                              ).map((element, index) => (
                                <>
                                  <div className="px-2 pb-3 mb-2 mr-2 space-y-2 text-base text-gray-500 divide-y">
                                    {index + 1}{". "}
                                    {element}
                                  </div>
                                </>
                              ))
                              : null}
                          </div>
                        </>
                      ) : (
                        <div className="flex flex-wrap items-center justify-center">
                          {list2 && list2.length > 0
                            ? list2.map((element) => (
                              <>
                                <span className="p-2 px-4 my-2 mr-2 text-base font-medium bg-blue-100 rounded-full text-slate-800">
                                  {element}
                                </span>
                              </>
                            ))
                            : null}
                        </div>
                      )}
                      <div className="flex items-center justify-center"></div>
                    </div>
                  </div>


                  <div className="flex flex-col justify-between h-full p-6 bg-white border rounded-md md:col-span-3">
                    <div className="flex justify-between ">
                      <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">
                        Demographics wise Exit
                      </h1>

                      <DemographicFilter
                        showFilter={this.state.showFilter}
                        showValue={this.state.showValue}
                        closeFilter={this.closeFilter}
                        EmpFilterData2={EmpFilterData}
                        getIndexFilterData={getIndexByFilterDMGData}
                        handleMultifilterIndexScore={
                          this.handleMultifilterIndexScore2
                        }
                        getEMPFilterLabel={getEMPFilterLabel}
                        xaXis={xaXis1}
                      />
                    </div>

                    <Demographics
                      getExitQuestionAllDemographicsData={
                        getExitQuestionAllDemographicsData
                      }
                    />
                    <div className="mb-6 text-center">
                      <b>X-Axis: </b>
                      {" Count"}
                      {",  "}
                      <b>Y-Axis: </b>
                      {"Exit Demographics"}
                    </div>
                  </div>

                </>
              ) : null}


              {/* Overview Page Code Question Analysis = 2 */}
              {optionType === 2 ? (
                <>
                  <div className="grid grid-cols-1 gap-6 md:grid-cols-3">


                    {OthersQuesList && OthersQuesList.length > 0
                      ? OthersQuesList.map((item) =>
                        (
                          getQueTemplateIn(
                            item &&
                            item.answerList[0] &&
                            item.answerList[0].template
                          )
                            ? item.answerList[0].template
                            : null
                        ) ? (
                          item && item.name ? (
                            <div
                              onClick={() => onAllQuestions(item)}
                              className="w-full p-4 bg-white border rounded-lg"
                            >
                              <QuestionCard item={item} name={""} />
                            </div>
                          ) : null
                        ) : null
                      )
                      : null}

                    {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ? (
                      (getQueTemplateIn16(getQuestionOthersResultData) ?
                        <div
                          onClick={() => onOptionType(surveyId, "nps6", 16)}
                          className="w-full p-4 bg-white border rounded-lg"
                        >
                          <OverviewNPSZeroToTen
                            getQuestionOthersResultData={getZeroToTen(
                              getQuestionOthersResultData,
                              16
                            )}
                            QueTemplate={16}
                          />
                        </div>
                        : null)
                    ) : (
                      <div></div>
                    )}

                    {count > 0 ? null : getMsg(1)}


                  </div>
                </>
              ) : null}
            </div>
          ) : (
            <div className="px-2 bg-gray-100">
              <SurveyReport
                CurrentSurveyId={surveyId}
                closeResponseAnalytics={this.closeResponseAnalytics}
                EmpFilterData={EmpFilterData}
                handleGoBack={this.handleGoBack}
                getIndexFilterData={getIndexFilterData}
                getRespondantDetailsData={getRespondantDetailsData}
                loading={loading}
              />
            </div>
          )}
        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(Overview);
