import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../../../_actions";
import Filter from "../../../../../pages/CommonComponents/Filters/IndexFilter/Filter";
import ReactEcharts from "echarts-for-react";
//libraries
import * as echarts from 'echarts';
class AppraisalCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: "none",
      showLeaderBoard: false,
      isMultifilter: false,
      currentSelectValue: "",
      FilterValues: [],
      showFilter: false,
      showValue: "",
      ListName: "Demographic",
      showEDAModal: false,
      crrDMGValue: "",



      activeTab: "AppraisalStatus",
      activeCard: 'selfAppraisalStatus',
      mainTab: "AppraisalCycle",
      showhide: false


    };
  }
  componentDidMount() { }

  handleTabClick = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { filterEmployeeRatingAndNormalization } = this.props;
    this.setState({ FilterValues: finalFilter, ListName: name });
    filterEmployeeRatingAndNormalization(finalFilter, true, name);
  };

  render() {
    let { pms, dashboard, survey } = this.props;
    let { EmpFilterData, getEMPFilterLabel, getEmployeeRatingAndNormalization } = dashboard;
    let { getIndexByFilterDMGData } = survey;

    let { getEMPASData, getDashboardOverviewStatusData, PMSGetDetailsByAppraisalCycleId } = pms;
    let { activeTab, activeCard, showhide } = this.state;

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    console.log("PMSGetDetailsByAppraisalCycleId---111111111------>", PMSGetDetailsByAppraisalCycleId)


    const completedCountSelfAppraisal = getDashboardOverviewStatusData?.selfAppraisalStatus?.completed || 0;
    const pendingCountSelfAppraisal = getDashboardOverviewStatusData?.selfAppraisalStatus?.pending || 0;
    const totalSelfAppraisalStatus = completedCountSelfAppraisal + pendingCountSelfAppraisal;

    // Extract completed and pending counts from reviewStatus
    const completedCountMultiRater = getDashboardOverviewStatusData?.multiRaterStatus?.completed || 0;
    const pendingCountMultiRater = getDashboardOverviewStatusData?.multiRaterStatus?.pending || 0;
    const totalMultiRaterStatus = completedCountMultiRater + pendingCountMultiRater;

    // Extract completed and pending counts from reviewStatus
    const completedCountReviewStatus = getDashboardOverviewStatusData?.reviewStatus?.completed || 0;
    const pendingCountReviewStatus = getDashboardOverviewStatusData?.reviewStatus?.pending || 0;
    const totalReviewStatus = completedCountReviewStatus + pendingCountReviewStatus;

    function undefinedZero(value, f) {
      return value && f && value[f] ? value[f] : 0
    }


    let data5 = (getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.labels ? getEmployeeRatingAndNormalization.labels : {})

    let data6 = (getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.normlLabelCount ? getEmployeeRatingAndNormalization.normlLabelCount : {})


    let dataNorml = undefinedZero(PMSGetDetailsByAppraisalCycleId, "normlActive") ? data6 : {}

    var labelsListScore = data5 ? Object.keys(data5).map((key) => [key, undefinedZero(data5, key), undefinedZero(dataNorml, key)]) : [];

    console.log("undefinedZero(PMSGetDetailsByAppraisalCycleId)--------->", undefinedZero(PMSGetDetailsByAppraisalCycleId, "normlActive"))
    console.log("labelsListScore--------->", labelsListScore)
    console.log("data5--------->", data5)
    console.log("data6--------->", data6)
    console.log("getEmployeeRatingAndNormalization--------->", getEmployeeRatingAndNormalization)

    return (
      <>

        <div className="items-center justify-between md:flex">
          <div className="flex flex-wrap gap-2">
            {/* <div className="cursor-pointer bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] flex justify-between px-4 items-center relative py-2 rounded-md w-40 "> */}
            <div className="flex items-center space-x-2 ">
              <Filter
                showFilter={this.state.showFilter}
                showValue={this.state.showValue}
                closeFilter={this.closeFilter}
                EmpFilterData2={EmpFilterData2}
                getIndexFilterData={getIndexByFilterDMGData}
                handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
                getEMPFilterLabel={getEMPFilterLabel}
              />
              {/* </div> */}

            </div>
          </div>
          <div className="flex space-x-2 ">
            <button className="flex items-center justify-center p-2 bg-white border rounded-md" onClick={() => this.setState({ showhide: false })}>
              <span className="material-symbols-rounded  text-[1.5rem] text-[#2196f3]">
                grid_view
              </span>
            </button>

            {/* chnage here */}


            <button className="flex items-center justify-center p-2 bg-white border rounded-md" onClick={() => this.setState({ showhide: true })}>
              <span className="material-symbols-rounded  text-[1.5rem] ">
                format_list_bulleted
              </span>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 ">
          <div className="p-6 transition duration-150 bg-white border rounded-lg cursor-pointer hover:shadow-lg">
            <div className="space-y-6">
              <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">
                Appraisal Rating
              </h1>
              {/* <div className="flex justify-center percent">
                            <h1 className="text-xl text-black/60">
                              Graph come here{" "}
                            </h1>
                          </div> */}
              <div className="flex items-center space-x-4 ">
                <p className='-rotate-90 whitespace-nowrap h-10 font-medium text-[#3D405B]'>Number of Employee</p>

                <div className='flex-1'>
                  <ReactEcharts
                    className='!w-full'
                    option={{
                      legend: {},
                      tooltip: {},
                      dataset: {
                        source: labelsListScore
                      },
                      xAxis: {
                        type: 'category',
                        axisLabel: {
                          textStyle: {
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#000000'
                          }
                        },
                      },
                      yAxis: {
                        axisLabel: {
                          textStyle: {
                            fontWeight: 'bold',
                            color: '#000000'
                          }
                        },
                      },
                      // series: [
                      //   {type: 'bar', barWidth: 30 },
                      //   {type: 'bar', barWidth: 30 }
                      // ]
                      series: [
                        {
                          type: 'bar',
                          barWidth: 30,
                          itemStyle: {
                            color: '#4569F7', // Change this to the desired color
                          },
                        },
                        {
                          type: 'bar',
                          barWidth: 30,
                          itemStyle: {
                            color: '#9C00CF', // Change this to the desired color
                          },
                        },
                      ],
                    }
                    }
                  />
                  <div
                    className="flex justify-center w-full space-x-4 "
                  >
                    <div className="flex items-center space-x-2">
                      <span className="w-3 h-3 bg-blue-500 rounded-full"></span>
                      <div className="flex items-center space-x-1">
                        <p className="font-medium"> Actual Rating</p>
                      </div>
                    </div>
                    {undefinedZero(PMSGetDetailsByAppraisalCycleId, "normlActive") ?
                      <>
                        <div className="flex items-center space-x-2">
                          <span className="w-3 h-3 bg-purple-700 rounded-full"></span>
                          <div className="flex items-center space-x-1">
                            <p className="font-medium ">
                              {" "}
                              Normalization Rating
                            </p>
                          </div>
                        </div>
                      </>
                      : null}

                  </div>
                </div>

              </div>


            </div>
          </div>
        </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, pms, survey } = state;
  return {
    loggingIn,
    dashboard,
    users,
    pms,
    survey
  };
}
export default connect(mapStateToProps)(AppraisalCycle);
