import React, { Component } from "react";
import { connect } from "react-redux";
import QuestionScore from "./Charts/QuestionScore";
import Commentary from "../Commentary/Commentary";
import Filter from "../Filters/IndexFilter/Filter";
import { CSVLink } from "react-csv";
import NudgesBehaviourItem from "../Nudges/NudgesBehaviourItem";
import DataNotFound from "../../Dashboard/Components/DataNotFound";
class DimensionPriorities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: "none",
      currFilterName: "none",

      currentSelectValue: "",
      FilterValues: [],
      showFilter: false,
      showValue: "",
    };
  }
  componentDidMount() { }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultiFilterGetMapQuestion } = this.props;
    this.setState({ FilterValues: finalFilter, currFilterName: name });
    handleMultiFilterGetMapQuestion(finalFilter, 2, name, true);
  };

  render() {
    let {
      selectedFilterValue,
      loading,
      ErrorIndexObj,
      indexType,
      optionType,
      getCommentData,
      CountRestrict,
      getEMPFilterLabel,

      getQuestionData,
      getQuestionMapData,
      getQuestionFilterData,
      handleQuestionFilter,
      EmpFilterData,
      getIndexFilterData,
      getIndexByFilterDMGData,
      IndexName
    } = this.props;

    let { currentSelectValue, FilterValues } = this.state;

    let QuestionData = [];

    if (this.state.currFilterValue === "none") {
      QuestionData = getQuestionData;
    } else {
      QuestionData = getQuestionMapData;
    }

    // let QuestionList
    // for(var index234 = 0; index234 < getQuestionData.length; index234++ ){
    //   let DmgQuesList = getQuestionData[index234];

    // }

    let DMGList = [];
    if (getQuestionMapData && getQuestionMapData.length > 0) {
      getQuestionMapData.forEach((DmgQuesObj) => {
        if (
          DmgQuesObj &&
          DmgQuesObj.value &&
          DmgQuesObj.questionList &&
          DmgQuesObj.questionList.length > 0
        ) {
          DMGList.push(DmgQuesObj.value);
        }
      });
    }

    //////console.log("DMGList------------.",DMGList)

    function getFavouribilityScore(question, dmgValue) {
      let score = "";
      if (getQuestionMapData && getQuestionMapData.length > 0) {
        let getIndex41 = getQuestionMapData.findIndex(
          (prev) => prev.value === dmgValue
        );
        if (getIndex41 !== -1) {
          let DmgQuesObj = getQuestionMapData[getIndex41];
          if (
            DmgQuesObj &&
            DmgQuesObj.questionList &&
            DmgQuesObj.questionList.length > 0
          ) {
            let getIndex42 = DmgQuesObj.questionList.findIndex(
              (prev) => prev.name === question.name
            );
            if (getIndex42 !== -1) {
              let ques = DmgQuesObj.questionList[getIndex42];

              let total =
                ques["1"] +
                ques["2"] +
                ques["3"] +
                ques["4"] +
                ques["5"] +
                ques["6"];

              let tempscore = parseFloat(
                (((ques["5"] + ques["6"]) * 100) / total).toFixed(2)
              );
              score = CountRestrict(ques && ques.count ? ques.count : "NA")
                ? "NA"
                : tempscore;
            }
          }
        }
      }
      return score ? score : 0;
    }

    function getOverallScore(ques) {
      let total =
        ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];
      let score = parseFloat(
        (((ques["5"] + ques["6"]) * 100) / total).toFixed(2)
      );
      return score ? score : 0;
    }

    function getColor(overallScore, dmgScore) {
      if (false) {
        return { Color: "#f1f1f1", diffTextColor: "#000" };
      } else {
        let diffNo = parseInt(
          Math.abs(parseFloat(overallScore) - parseFloat(dmgScore))
        );
        //////console.log("diffNo:",diffNo)

        if (parseFloat(overallScore) <= parseFloat(dmgScore)) {
          //greater (blue)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: "linear-gradient(90deg, #33cc33, #85e085)", diffTextColor: "#ffffff" };
          } else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: "linear-gradient(90deg, #70db70, #c1f0c1)", diffTextColor: "#00000" };
          } else {
            return { Color: "linear-gradient(90deg, #adebad, #eafaea)", diffTextColor: "#00000" };
          }
        } else {
          //lesse (red)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: "linear-gradient(90deg, #f5babd, #fce9ea)", diffTextColor: "#000000" };
          } else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: "linear-gradient(90deg, #ea95a1, #f2bfc6)", diffTextColor: "#000000" };
          } else {
            return { Color: "linear-gradient(90deg, #d03e4d, #e1848d)", diffTextColor: "#ffffff" };
          }
        }
      }
    }

    // //console.log('QuestionData--->', QuestionData)
    // //console.log('getQuestionMapData--->', getQuestionMapData)
    // //console.log('getQuestionFilterData--->', getQuestionFilterData)

    let paraList = [];

    let count = 0;
    let rowSpan = 0;
    let NewParaList = [];

    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        if (
          paraList.findIndex((prev) => prev === ques.parameterDimensionName) ===
          -1
        ) {
          paraList.push(ques.parameterDimensionName);
          count += 1;
        } else {
        }
      });

      let obj = {};
      paraList.forEach((para) => {
        obj[para] = 0;
        QuestionData.forEach((ques) => {
          if (para === ques.parameterDimensionName) {
            obj[para] += 1;
          }
        });
      });

      NewParaList = Object.keys(obj).map((key) => [key, obj[key]]);
      //   //console.log('data NewParaList: ',NewParaList)

      rowSpan = QuestionData.length / count;

      // //console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,QuestionData.length)
    }
    // //console.log('ORG ==== NewParaList ===== > : ',NewParaList)
    // //console.log('ORG ==== QuestionData ===== > : ',QuestionData)

    let NewQuesList = [];
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        let total =
          ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];
        NewQuesList.push({
          ...ques,
          score: parseFloat(
            (((ques["5"] + ques["6"]) * 100) / total).toFixed(2)
          ),
        });
      });
    }

    NewQuesList.sort((b, a) => a.score - b.score);

    // //console.log('ORG ==== NewQuesList ===== > : ',NewQuesList)

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    let isDisable = true;
    //  let DemographicDataScore = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {
      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === "All") {
            isDisable = false;
          }
        });
      }

      //  DemographicDataScore=getDemographicIndexMultiFilterData;
      //  DemographicDataDistribution=getIndexDistributionData;
    }
    let { GetParameterLabel, name } = this.props;

    //Export Excel Logic
    let ScoreExcel = [];
    let filter =
      FilterValues && FilterValues.length > 0
        ? " (" +
        FilterValues.map((element) => {
          return element.level + ": " + element.value;
        }).join(",") +
        ")"
        : " (Filter: All)";

    if (NewQuesList && NewQuesList.length > 0) {
      NewQuesList.forEach((question) => {
        let para =
          question && question.parameterDimensionName
            ? GetParameterLabel(question.parameterDimensionName)
            : "";
        let Score = CountRestrict(
          question && question.count ? question.count : "NA"
        )
          ? 0
          : question.score;
        let temp = {};
        temp["Dimension " + filter] = para;
        temp["Question"] = question.name;

        if (FilterValues && FilterValues.length > 0) {
          temp["Overall"] = getOverallScore(question);

          if (DMGList && DMGList.length > 0) {
            DMGList.forEach((dmg) => {
              temp[dmg] = getFavouribilityScore(question, dmg);
            });
          }

        } else {
          temp["Score(%)"] = Score;
        }

        ScoreExcel.push(temp);
      });
    }



    return (
      <>
        <div className="flex flex-col justify-between gap-4 md:items-center md:flex-row">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />

          {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
          <div className="flex flex-wrap gap-2 ">
            {/* <NudgesBehaviourItem
              indexType={indexType}
              IndexNme={IndexName}
              name={IndexName + (IndexName !== "Organisation" ? " Behaviour Item Detail" : " Item Ranking Detail")}
              dataItem={
                FilterValues && FilterValues.length > 0
                  ? {
                    show: true,
                    questionList: NewQuesList,
                    dmgList: DMGList,
                  }
                  : {
                    show: true,
                    questionList: NewQuesList,
                  }
              }
              getColor={getColor}
              getOverallScore={getOverallScore}
              getFavouribilityScore={getFavouribilityScore}
              GetParameterLabel={GetParameterLabel}
              optionType={
                FilterValues && FilterValues.length > 0 ? "itemdetails" : "item"
              }
              CountRestrict={CountRestrict}
            /> */}

            <div>
              {ScoreExcel ? (
                <CSVLink
                  data={ScoreExcel}
                  filename={IndexName !== "Organisation" ? (IndexName + "BehaviorItemDetail.csv") : "ItemRankingDetail.csv"}
                >
                  <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                      <path d="M10 12l4 4m0 -4l-4 4" />
                    </svg>

                    <span>Download Excel</span>
                  </button>
                </CSVLink>
              ) : (
                "..."
              )}
            </div>

            <Commentary
              indexType={indexType}
              optionType={optionType}
              getCommentData={getCommentData}
            />
          </div>
        </div>

        {true ? (
          <>
            {!(FilterValues && FilterValues.length > 0) ? (
              <div onClick={() => this.closeFilter(false)} className="">
                <div className="w-full">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">
                    <div className="p-6 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                      <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">
                        {IndexName !== "Organisation" ? (IndexName + " Behaviour Item Detail") : "Item Ranking Detail"}
                      </h1>

                      <div className="w-full mb-4 overflow-hidden overflow-x-auto whitespace-nowrap">
                        <table className="w-full border ">
                          <thead>
                            <tr className="bg-gray-100 border-b text-sm  text-[#3D405B]">
                              <th className="py-2 px-2 border-r font-bold  md:w-[20%] w-32">
                                <div className="flex items-center justify-left">
                                  Dimension
                                </div>
                              </th>
                              <th className="py-2 px-2 border-r font-bold md:w-[50%] w-60">
                                <div className="flex items-center justify-left">
                                  Question
                                </div>
                              </th>
                              <th className="py-2 px-4 border-r font-bold md:w-[30%] w-40">
                                <div className="flex items-center justify-left">
                                  Favorability Score
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {NewQuesList && NewQuesList.length > 0
                              ? NewQuesList.map((question, index) => (
                                <tr
                                  key={index}
                                  className="bg-white  mb-10 lg:mb-0 text-sm text-[#3D405B]"
                                >
                                  <td className="px-2 border border-b w-[20%] ">
                                    <div>
                                      {question &&
                                        question.parameterDimensionName
                                        ? GetParameterLabel(
                                          question.parameterDimensionName
                                        )
                                        : ""}
                                    </div>
                                  </td>
                                  <td className="tooltip-response-question1 px-2 border border-b ">
                                    <div className="md:w-auto w-60 whitespace-normal">
                                      {question.name}
                                    </div>
                                  </td>

                                  <td className=" border border-b w-[30%] ">
                                  <div className="md:w-auto w-40">
                                      <QuestionScore
                                        question={question}
                                        CountRestrict={CountRestrict}
                                        currFilterValue={
                                          this.state.currFilterValue
                                        }
                                        currFilterName={
                                          this.state.currFilterName
                                        }
                                        selectedFilterValue={
                                          selectedFilterValue
                                        }
                                        getQuestionFilterData={
                                          getQuestionFilterData
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                      <div className="text-sm text-[#3d405B]/70">
                        * We are not showing data where employee count is less
                        than 5
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div onClick={() => this.closeFilter(false)} className="">
                <div className="w-full">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">
                    <div className="p-6 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                      <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">
                        {/* {name} Behaviour Item */}
                        {IndexName !== "Organisation" ? (IndexName + " Behaviour Item Detail") : "Item Ranking Detail"}

                      </h1>

                      {ErrorIndexObj && ErrorIndexObj["getQuestionMapError"] ? (
                        <>
                          <div className="my-24 text-2xl font-medium text-center text-gray-400">
                            {ErrorIndexObj["getQuestionMapError"]}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="w-full mb-4 overflow-hidden overflow-x-auto  customscroll2">
                            <table className="w-full border whitespace-nowrap ">
                              <thead>
                                <tr className="text-xs text-gray-800 bg-gray-100 border-b">
                                  <th className="p-3 font-semibold text-gray-800 border-r">
                                    <div className="flex items-center justify-left">
                                      Dimension
                                    </div>
                                  </th>
                                  <th className="p-3 font-semibold text-gray-800 border-r">
                                    <div className="flex items-center justify-left">
                                      Question
                                    </div>
                                  </th>

                                  <th className="p-3 font-semibold text-gray-800 border-r">
                                    <div className="flex items-center justify-left">
                                      {"Overall"}
                                    </div>
                                  </th>

                                  {DMGList && DMGList.length > 0
                                    ? DMGList.map((dmg) => (
                                      <th className="p-3 font-semibold text-gray-800 border-r">
                                        <div className="flex items-center justify-left">
                                          {dmg}
                                        </div>
                                      </th>
                                    ))
                                    : null}
                                </tr>
                              </thead>
                              <tbody>
                                {NewQuesList && NewQuesList.length > 0
                                  ? NewQuesList.map((question, index) => (
                                    !CountRestrict(question && question.count ? question.count : 0) ?
                                      <tr
                                        key={index}
                                        className="text-xs text-gray-800 border-b"
                                      >
                                        <td className="px-2 py-2 font-normal border-r">
                                          <div style={{ width: "20%" }}>
                                            {question &&
                                              question.parameterDimensionName
                                              ? GetParameterLabel(
                                                question.parameterDimensionName
                                              )
                                              : ""}
                                          </div>
                                        </td>
                                        <td className="px-2 py-2 font-normal border-r">
                                          <div className="whitespace-pre-line w-96">
                                            {question.name}
                                          </div>
                                        </td>

                                        <td className="px-2 py-2 font-normal border-r">
                                          <div className="text-center whitespace-pre-line cursor-pointer">
                                            {getOverallScore(question)}
                                          </div>
                                        </td>

                                        {DMGList && DMGList.length > 0
                                          ? DMGList.map((dmg) => (

                                            <td
                                              className={"px-2 py-2 font-normal border-r"}

                                              style={{
                                                minWidth: "60px",
                                                minHeight: "50px",
                                                color: getColor(
                                                  getOverallScore(question),
                                                  getFavouribilityScore(
                                                    question,
                                                    dmg
                                                  )
                                                ).diffTextColor,
                                                background: getColor(
                                                  getOverallScore(question),
                                                  getFavouribilityScore(
                                                    question,
                                                    dmg
                                                  )
                                                ).Color,
                                              }}
                                            >
                                              <div className="text-center whitespace-pre-line cursor-pointer 0">
                                                {getFavouribilityScore(
                                                  question,
                                                  dmg
                                                )}
                                              </div>
                                            </td>
                                          ))
                                          : null}
                                      </tr>
                                      : null
                                  ))
                                  : null}
                              </tbody>
                            </table>
                          </div>
                        </>
                      )}

                      <div className="py-4 text-" style={{ color: "#3366ff" }}>
                        * We are not showing data where employee count is less
                        than 5
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {!loading ? (
              <div className="bg-white p-6 border rounded-lg hover:shadow-lg">
                <DataNotFound />
              </div>
            ) : null}
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(DimensionPriorities);
