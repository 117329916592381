import React from "react";
export default function UserAccessModal(props) {
  let { addUserModal, handleOpenLevel, handleOpenValue, selectDMGLevel, selectDMGValue, handleScreenAcess, giveAccessSubmit
    , showUserDmgLevel, showUserDmgValue, DMGLevelList, finalDemoList, AccessObj, AccessList, ComponentList, DemographicLevels,
    DemographicValues, searchName, handleSearch, type, removeAccessModal, getSurveyListByRoleData, crrSelectedSurveyList, selectSurveys } = props;

  let [showSurveyDrop, handleSurveyDrop] = React.useState(false);

  console.log('ComponentList: ', ComponentList);

  return (

    <div className="fixed h-screen top-0 left-0 w-full flex justify-center items-center z-40 xl:py-0 py-2 bg-black bg-opacity-20"  >
      <div className="bg-white rounded-md shadow-sm xl:w-6/12 md:w-9/12 w-11/12 ">
        {/* add user */}
        <div className="flex justify-between items-center p-4 md:px-8 border-b">
          <h1 className="text-lg font-semibold">{"Update Access"}</h1>
          <span onClick={() => addUserModal(false)} className="cursor-pointer material-symbols-outlined p-2 bg-gray-100   hover:bg-gray-200 transtion duration-150 rounded-full" >close</span></div>




        <div className="md:h-96 h-80 overflow-y-auto overflow-hidden customscroll4">
          <div className='flex justify-center items-center -space-x-14 p-6'>
            <div className='flex flex-col  items-center space-y-2 z-10'>
              <div className='w-10 h-10 bg-green-500 text-white flex justify-center items-center rounded-full text-lg font-semibold '>
                1
              </div>
              <p className="text-[#77798C]">Select Employee</p>
            </div>

            <div className='w-40 h-0.5 bg-gray-200 -mt-6'></div>
            <div className='flex flex-col  items-center space-y-2 z-10'>
              <div className='w-10 h-10 bg-blue-500 text-white flex justify-center items-center rounded-full text-lg font-semibold '>
                2
              </div>
              <p>Assign Access</p>
            </div>
          </div>

          {/* selected user */}
          {/* <div className="flex items-center p-4 border-b"><p className="text-sm font-medium">User :-</p><p className="text-sm pl-4">{selectedEmployeeData && selectedEmployeeData.name ? selectedEmployeeData.name + " (Emp Id: " + selectedEmployeeData.employeeID + ")" : "Emp Id: " + selectedEmployeeData.employeeID}</p></div> */}
          {/* demograohical access */}


          {/* success */}
          <div className="p-4 md:px-8" onClick={() => {
            handleOpenLevel(false);
            handleOpenValue(false);
          }}>
            <h1 className="font-medium text-gray-800 text-base pb-4">Module Access</h1>
            <div className="grid md:grid-cols-3 grid-cols-2 gap-4   text-gray-500 md:text-sm text-xs">
              {AccessList && AccessList.length > 0 ?
                AccessList.map((item) =>
                  <div className="flex items-center ">
                    <label className={item.value === "viewSurvey" ? "text-red-500  cursor-pointer" : " cursor-pointer"}>
                      <input className="mr-2" checked={AccessObj && AccessObj[item.value] ? true : false}
                        type="checkbox" onChange={() => handleScreenAcess(!(AccessObj && AccessObj[item.value]) ? true : false, item.value)} />  {item.label}</label></div>
                )
                : null}
            </div>
          </div>
          {/* end */}


          {/* success */}
          <div className="p-4 md:px-8" onClick={() => {
            handleOpenLevel(false);
            handleOpenValue(false);
          }}>
            <h1 className="font-medium text-gray-800 text-base pb-4">Component Access</h1>
            <div className="grid md:grid-cols-3 grid-cols-2 gap-4   text-gray-500 md:text-sm text-xs">
              {ComponentList && ComponentList.length > 0 ?
                ComponentList.map((item) =>
                  <div className="flex items-center ">
                    <label className={item.value === "viewSurvey" ? "text-red-500  cursor-pointer" : " cursor-pointer"}>
                      <input className="mr-2" checked={AccessObj && AccessObj[item.value] ? true : false}
                        type="checkbox" onChange={() => handleScreenAcess(!(AccessObj && AccessObj[item.value]) ? true : false, item.value)} />  {item.label}</label></div>
                )
                : null}
            </div>
          </div>
          {/* end */}



          <div className="p-4 md:px-8">
            <h1 className="font-medium text-gray-800 text-base pb-1">Survey Access</h1>
            <div className="grid  md:grid-cols-3 grid-cols-2  md:gap-6 gap-4  py-2 text-xs font-medium text-gray-500">


              <div className="flex items-center justify-between border border-gray-300 rounded-md p-2 relative">
                <div onClick={() => handleSurveyDrop(!showSurveyDrop)} className="w-full cursor-pointer flex space-x-3 items-center">
                  <p className="text-sm">Surveys</p>
                  <span className="text-blue-500  text-sm font-medium w-6 h-6 flex items-center justify-center bg-blue-100 rounded-full">{crrSelectedSurveyList && crrSelectedSurveyList.length > 0 ? crrSelectedSurveyList.length : 0}</span></div>
                <span onClick={() => handleSurveyDrop(!showSurveyDrop)} className="cursor-pointer material-symbols-outlined">arrow_drop_down</span>


                {showSurveyDrop ?
                  <div className="absolute top-10 border z-20 shadow-xl w-full right-0 bg-white py-2 font-normal 
                 text-gray-500 h-44 overflow-hidden overflow-y-auto lang  ">
                    {getSurveyListByRoleData && getSurveyListByRoleData.length > 0 ?
                      getSurveyListByRoleData.map((survey, index) =>
                        <label key={index} className="flex items-center py-2 hover:bg-gray-100 cursor-pointer px-4">
                          <input type="checkbox"
                            className="mr-2"
                            checked={crrSelectedSurveyList.some(prev => prev === survey.id) ? true : false}
                            onClick={() => {
                              selectSurveys(survey);
                              handleSurveyDrop(false)
                            }} onChange={() => { }} />
                          {survey.name}</label>
                      )
                      : null}

                  </div>
                  : null}
              </div>


            </div>
          </div>


          <div className="p-4 md:px-8">
            <h1 className="font-medium text-gray-800 text-base pb-1">Demographic Access</h1>
            <div className="grid  md:grid-cols-3 grid-cols-2  md:gap-6 gap-4  py-2 text-xs font-medium text-gray-500">
              {/* drop 1 */}

              <div className="flex items-center justify-between border rounded-md p-2 relative">
                <div onClick={() => handleOpenLevel(!showUserDmgLevel)} className="w-full cursor-pointer flex space-x-3 items-center">
                  <p className="text-sm">Demographic</p>
                  <span className="text-blue-500  text-sm font-medium w-6 h-6 flex items-center justify-center bg-blue-100 rounded-full">{DMGLevelList && DMGLevelList.length > 0 ? DMGLevelList.length : 0}</span></div>
                <span onClick={() => handleOpenLevel(!showUserDmgLevel)} className="cursor-pointer material-symbols-outlined">arrow_drop_down</span>
                {/* drop down */}

                {showUserDmgLevel ?
                  <div className="absolute top-10 border z-20 shadow-xl w-full right-0 bg-white py-2 font-normal 
          text-gray-500 h-44 overflow-hidden overflow-y-auto lang  ">
                    {DemographicLevels && DemographicLevels.length > 0 ?
                      DemographicLevels.map((value, index) =>
                        <div key={index} >
                          <label className="flex items-center py-2 hover:bg-gray-100 cursor-pointer px-4">
                            <input type="checkbox"
                              className="mr-2"
                              checked={DMGLevelList.some(prev => prev === value) ? true : false}
                              onClick={() => selectDMGLevel(value)} onChange={() => { }} />
                            {value}</label>
                        </div>
                      )
                      : null}

                  </div>
                  : null}
              </div>


              {DMGLevelList && DMGLevelList.length > 0 ?
                DMGLevelList.map((item) =>
                  <div className="flex items-center justify-between border rounded-md p-2 relative">
                    <div onClick={() => handleOpenValue(item)} className="w-full cursor-pointer flex space-x-3 items-center">
                      <p className="text-sm">{item}</p>
                      <span className="text-blue-500  text-sm font-medium w-6 h-6 flex items-center justify-center bg-blue-100 rounded-full">{(finalDemoList && finalDemoList[item] && finalDemoList[item].length > 0) ? finalDemoList[item].length : 0}</span></div>
                    <span onClick={() => handleOpenValue(item)} className="cursor-pointer material-symbols-outlined">arrow_drop_down</span>
                    {/* drop down */}

                    {showUserDmgValue === item ?
                      <div className="absolute top-10 border z-20 shadow-xl w-full right-0 bg-white py-2 font-normal 
                      text-gray-500 h-44 overflow-hidden overflow-y-auto lang  ">
                        <input type="text" name="searchName" value={searchName} onChange={handleSearch} placeholder="Search" className="w-full border p-1.5 rounded-sm focus:outline-none text-gray-500 mb-2 focus:ring-blue-500 focus:ring-1" />

                        {DemographicValues && DemographicValues[item] && DemographicValues[item].length > 0 ?
                          DemographicValues[item].map((value, index) =>
                            value && value.match(new RegExp(`${searchName}`, 'gi')) ?

                              <label key={index} onClick={() => selectDMGValue(value, item)} className="flex items-center py-2 hover:bg-gray-100 cursor-pointer px-4">
                                <input type="checkbox"
                                  className="mr-2"
                                  checked={(finalDemoList && finalDemoList[item] && finalDemoList[item].length > 0) ? finalDemoList[item].some(prev => prev === value) ? true : false : false}
                                  onClick={() => selectDMGValue(value, item)} onChange={() => { }} />
                                {value}</label>

                              : null)
                          : null}

                      </div>
                      : null}
                  </div>
                ) : null}

            </div>
          </div>
          {/* end */}
     

        </div>
        <div className="p-4 md:flex justify-end border-t md:space-y-0 space-y-4 md:space-x-4">

          {/* <button onClick={() => removeAccessModal(true)} className="md:w-auto w-full text-center flex items-center justify-center text-base   relative cursor-pointer  rounded-md py-2 px-6   uppercase   text-blue-500 font-medium  border border-blue-500">Delete</button> */}
          {type === "UPDATE" ?
            <>

              <button onClick={() => removeAccessModal(false)} className="md:w-auto w-full text-center flex items-center justify-center text-base   relative cursor-pointer  rounded-md py-2 px-6   uppercase   text-blue-500 font-medium  border border-blue-500  ">  Clear All </button>
            </>
            : null}

          <button onClick={() => giveAccessSubmit()} className="md:w-auto w-full text-center flex items-center justify-center text-base font-medium  text-white relative cursor-pointer  rounded-md py-2.5 px-6 bg-gradient-to-r from-blue-500 to-pink-500 uppercase">grant access</button>

        </div>
      </div>
    </div>

  );
}
