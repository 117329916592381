import React, { Component } from 'react'
// import Sidebar from "../Sidebar/Sidebar";
// import Profile from "../Profile/Profile";
// import { dashboardActions } from "../../_actions";
import { connect } from "react-redux";

// import moment from "moment"

class GrowMonth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType1: true,
      showMusic: true,
      viewType2: false,
      width: 0, height: 0,
      newid: ''
    };
    // this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }
  componentDidMount() {

    // console.log("fsdfkljsdffffffffffff_______________this.props.match.params_inner", this.props.match.params);

    // let monthId = this.props.match.params.monthId
    // let id = this.props.match.params.id


    // this.props.dispatch(dashboardActions.getGrowDayListbyWeekId({ monthId: monthId, weekId: id }, this.props));


    // console.log("dataMonth__________fsdfsd",dataMonth);

    // let data = {
    //   "keyWord": "",
    //   "pageNo": 1,
    //   "size": this.state.size
    // }
    // this.props.dispatch(dashboardActions.getGrowMonth());
    // this.props.dispatch(dashboardActions.getGrowParameterList(data));

  }


  // leading = (item) => {

  //   console.log("itemitemitemitemitem____",item);

  //       if (item.isStart === 0) {
  //         this.setState({ newid: item.id })
  //         let data = { monthId: item.id }
  //         this.props.dispatch(dashboardActions.startGrowMonth(data, this.props))
  //         let monthname = { item }
  //         this.props.history.push('/app/leadingself/'+item.id)
  //       }
  //       else {
  //         let monthname = { item }
  //         this.props.history.push('/app/leadingself/'+item.id)
  //       }

  //     }

  // leadingInner = (item) => {
  //   if (item.isStart === 0) {
  //     // this.setState({showtrue:true})
  //     this.setState({ newid: item.id })
  //     this.setState({ newids: item.monthId })
  //     let alldata = { item }
  //     this.props.navigation.navigate('LeadingSelfInner/' + item.id)
  //     let data = { weekId: item.id, monthId: item.monthId }
  //     leading_startGrowWeek_getGrowDayListbyWeekId(true,data, alsoCall);

  //     this.props.dispatch(dashboardActions.startGrowWeek(data, this.props))
  //   }
  //   else {
  //     let alldata = { item }
  //     this.props.navigation.navigate('LeadingSelfInner/' + item.id)
  //   }
  // }



  // selectHabit = (item) => {
  //   if (item.isStart === 0) {
  //     // this.setState({showtrue:true})

  //     this.setState({ newid: item })
  //     let data1 = { dayId: item && item.id, monthId: item && item.monthId && item.monthId.id, weekId: item && item.weekId && item.weekId.id }

  //     this.props.dispatch(dashboardActions.startGrowDay(data1, this.props));
  //     let data = { item }
  //     this.props.history.push('Day1', data)
  //   }



  //   else {
  //     let data = { item }
  //     this.props.history.push('Day1', data)
  //   }
  // }

  selectHabit = (item) => {

    console.log("itemitem___________selectHabit:::", item);

    let { selectHabit_startGrowDay_getGrowDayDatabyDayId, setDayActionName } = this.props;
    let alsoCall = "getGrowDayDatabyDayId";

    if (item.isStart === 0) {
      // this.setState({showtrue:true})

      this.setState({ newid: item })
      let data1 = { dayId: item && item.id, monthId: item && item.monthId && item.monthId.id, weekId: item && item.weekId && item.weekId.id }

      // this.props.dispatch(dashboardActions.startGrowDay(data1, this.props));
      let data2 = { id: item.id }
      selectHabit_startGrowDay_getGrowDayDatabyDayId(true, data1, alsoCall, data2)


      // let data = { item }
      // this.props.history.push('/app/dayaction/'+item.id)
    }



    else {
      let data2 = { id: item.id }

      // this.props.history.push('/app/dayaction/'+item.id)
      selectHabit_startGrowDay_getGrowDayDatabyDayId(false, {}, alsoCall, data2)

    }

    setDayActionName(item && item.name?item.name:"")

  }



  render() {
    let { getGrowDayListbyWeekId, goBackToScreen, crrSelfInnerName } = this.props;
    // let { viewType1, viewType2, } = this.state;
    // console.log("getWeekListbyMonthIdgetWeekListbyMonthId________________11111 ", getGrowDayListbyWeekId);

    // let monthId = this.props.match.params.monthId


    return (
      <>
        <div className="space-y-6 ">
          <div className='flex items-center space-x-4'>
            <span onClick={()=>goBackToScreen(2)} class="cursor-pointer material-symbols-outlined bg-blue-100 p-2 rounded-full text-blue-500 ">arrow_back</span>
            <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">{crrSelfInnerName?crrSelfInnerName:"Grow App"}</h1>
          </div>
          {/* <Sidebar name={"Managerscorecard"} /> */}


          {/* <Profile /> */}
          <main className="">



            <div className="grid grid-cols-1 gap-4 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">


              {
                getGrowDayListbyWeekId && getGrowDayListbyWeekId.length > 0 ?
                  getGrowDayListbyWeekId.map((item, index) => (
                    <div className="h-auto overflow-hidden border rounded-lg shadow-lg" style={{ backgroundColor: (item.finalDaysubmit === 0 ? "#fff" : "#fff"), }}>
                      <img className="block object-cover object-top w-full h-52" src={item.dimensionId.imageLinkV2} />
                      <div className="flex flex-col justify-between p-4 leading-normal rounded-b lg:rounded-b-none lg:rounded-r">

                        <div className="flex items-start justify-between">
                          <p className="mb-2 text-lg font-semibold capitalize text-slate-800" style={{ color: (item.finalDaysubmit === 1 ? 'black' : "black") }}>{item.name}</p>
                          {item.finalDaysubmit === 1 ?
                            <span span class="material-symbols-outlined bg-gray-100 rounded-full p-2 text-gray-800">
                              done  </span>
                            :

                            <span span class="material-symbols-outlined bg-gray-100 rounded-full p-2 text-gray-800">
                              lock  </span>

                          }
                        </div>

                        {/* <div className="mb-2 text-lg font-medium leading-tight text-gray-700 capitalize">{item.dimensionId.tagline}</div> */}

                        {item.finalDaysubmit === 1 ?

                          <button className="p-2 mt-4 text-base text-white bg-green-500 rounded-full cursor-pointer hover:bg-green-500 hover:text-white" type='button' onClick={() => this.selectHabit(item)}>Done</button>

                          :

                          <button className="w-full p-2 mt-4 text-base text-blue-500 border border-blue-500 rounded-full cursor-pointer hover:bg-blue-500 hover:text-white" type='button' onClick={() => this.selectHabit(item)}>Start</button>

                        }
                      </div>
                    </div>




                  )) : null
              }

            </div>



            {/* <div className="container px-6 py-6 mx-auto">
                <div className="grid grid-cols-1 gap-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2">

                  <div className="w-full">
                    <div className="h-full p-4 overflow-hidden bg-white border rounded shadow-lg">
                      <div className="flex flex-col justify-between p-4 leading-normal rounded-b lg:rounded-b-none lg:rounded-r">
                        <div className="mb-2 text-xl font-bold leading-tight capitalize text-slate-800">What behaviors did i seek to develop? why?</div>
                        <div className="py-8">
                          <input type="text" id="first_name"class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write a short note" required />
                        </div>
                        <div className="flex items-center justify-center w-full mt-4">
                          <button className="w-56 p-1 ml-2 text-base bg-blue-300 border border-none rounded-full shadow-lg cursor-pointer text-grey-darker hover:bg-blue-500 hover:text-white">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="h-full p-4 overflow-hidden bg-white border rounded shadow-lg">
                      <div className="flex flex-col justify-between p-4 leading-normal rounded-b lg:rounded-b-none lg:rounded-r">
                        <div className="mb-2 text-xl font-bold leading-tight capitalize text-slate-800">What did I do to develop desired behavior? Describe actions.</div>
                        <div className="py-8">
                          <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write a short note" required />
                        </div>
                        <div className="flex items-center justify-center w-full mt-4">
                          <button className="w-56 p-1 ml-2 text-base bg-blue-300 border border-none rounded-full shadow-lg cursor-pointer text-grey-darker hover:bg-blue-500 hover:text-white">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="h-full p-4 overflow-hidden bg-white border rounded shadow-lg">
                      <div className="flex flex-col justify-between p-4 leading-normal rounded-b lg:rounded-b-none lg:rounded-r">
                        <div className="mb-2 text-xl font-bold leading-tight capitalize text-slate-800">What worked? What didn't?</div>
                        <div className="py-8">
                          <input type="text" id="first_name"class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write a short note" required />
                        </div>
                        <div className="flex items-center justify-center w-full mt-4">
                          <button className="w-56 p-1 ml-2 text-base bg-blue-300 border border-none rounded-full shadow-lg cursor-pointer text-grey-darker hover:bg-blue-500 hover:text-white">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div> */}




          </main>


        </div>

      </>
    )
  }
}

function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users, dashboard } = state;
  return {
    loggingIn,
    questions,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(GrowMonth)