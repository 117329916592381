import React from "react";
import moment from "moment";

export default function Item(props) {
let { CurrentManagerData, getSurveyData  } = props;
////console.log("---->getSurveyData--->",getSurveyData)

  return (
    <>
    
    <section className=" space-y-2 gap-5 px-10 pt-12">





    <header className="bg-white ">
    <div className="flex justify-between capitalize w-12/12 mx-auto py-4 pt-3 space-y-1">
      
      
      <h1 className="text-xl text-white font-bold">
      {getSurveyData && getSurveyData.logo?
        <img alt="item" src={getSurveyData && getSurveyData.logo?getSurveyData.logo:""}  style={{height:'90px'}}/>
        :null}
      </h1>

      <h1 className="text-xl text-white font-bold">
        <img alt="item" src={"img/logo/happypluslogo.png"}  style={{height:'60px'}}/>
      </h1>

      
    </div>
  </header>




    <main className="-mt-12 mb-12 w-full">
      <div className="text-left flex justify-between items-center  ">
        
        
        <div className="text-5xl font-bold w-full -mt-12">
          <h1 className="leading-tight"><b className="text-blue-400 font-bold ">Manager</b> <br /> Scorecard</h1>
          <div className="text-gray-600 font-medium text-xl pt-6 space-y-2">
            <p>Manager Name: <b>{CurrentManagerData && CurrentManagerData.name?CurrentManagerData.name:"NaN"}</b></p>
            <p>Date:<b>{moment().format("DD/MM/YYYY")}</b></p>
            <p>Manager Id: <b>{CurrentManagerData && CurrentManagerData.emp_id?CurrentManagerData.emp_id:"NaN"}</b></p>
            {/* <p>Company: <b>Tata consulting engineers limited</b></p> */}
          </div>
        </div>
        <div className="flex justify-center w-full">
          <img src="img/managerreport/manager_scorecard.png" className="w-10/12 z-10" style={{height: ''}} />
        </div>
      </div>
      <div className=" h-24 bg-blue-200 w-full" />
      </main>
    </section>

    </>
  );
}







