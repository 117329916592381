import React from "react";
import ParameterView from '../../AddQuestion/Components/Parameter/ParameterView'

export default function SurveyList(props) {
  let { GetParameterLabel, savePackage, isPackageSelected, handlePackageSelection, QuestionLibraryClose, } = props;

  // console.log('savePackage:', savePackage);


  savePackage.questionLibraryList.sort((a, b) => a.rank - b.rank);


  return (
    <>

      <main className="px-6 bg-gray-100 h-100%">
        <div className="container py-12 mx-auto">
          <div className="items-center justify-between pb-8 xl:flex lg:flex md:flex">



            <div className="flex items-center justify-between pb-4 xl:pb-0 lg:pb-0 xl:flex-none">
              <i onClick={() => QuestionLibraryClose()} className="pt-1 pr-6 fa fa-arrow-left"></i><h1 className="text-xl cursor-pointer">Plus Question Library</h1>
            </div>



            <div className="flex items-center justify-center">
              <div className="relative text-gray-600 ">
                <h1 onClick={() => handlePackageSelection(isPackageSelected, savePackage)} className={isPackageSelected ? "text-red-500 border-2 border-red-500 px-4 rounded-lg py-1 cursor-pointer font-semibold" : "text-blue-500 border-2 border-blue-500   px-4 rounded-lg py-1 cursor-pointer font-semibold "}>{isPackageSelected ? "Remove this PLUS Survey Template" : "Use this PLUS Survey Template"}</h1>

              </div>

            </div>
          </div>


          <div className="items-center justify-center text-gray-600 cursor-default lg:mx-36 xl:mx-60">
            <div className="py-2 mb-4 text-xl font-semibold text-center text-gray-500 rounded-lg">{savePackage && savePackage.name ? savePackage.name : ""}</div>
            {savePackage.questionLibraryList && savePackage.questionLibraryList.length > 0 ?
              savePackage.questionLibraryList.map((ques, index) =>
                <>
                  <div className="p-2 my-2 bg-white rounded-lg shadow-lg">
                    <div className="p-2 text-xl font-semibold">Q{index + 1}) {ques.name}</div>
                    {/* <div className="flex px-4 pt-2 font-semibold" style={{fontSize:"12px"}}>
                      <span className="text-purple-500">{ques && ques.parameterBucketId && ques.parameterBucketId.name?GetParameterLabel(ques.parameterBucketId.name):""}</span>
                      <span className="text-green-500">{ques && ques.parameterDimensionId && ques.parameterDimensionId.name?", "+GetParameterLabel(ques.parameterDimensionId.name):""}</span>
                    </div> */}

                    <ParameterView
                      question={ques}
                      GetParameterLabel={GetParameterLabel}
                    />






                    {ques.templateId === 1 ?
                      <div className="p-2">
                        {ques.optionsList && ques.optionsList.length > 0 ?
                          ques.optionsList.map((opt, index) =>
                            <div className="px-2 py-0.5 font-normal"><input type="radio" className="px-2" /><span className="px-2 text-lg">{opt.name}</span></div>
                          )
                          : null}
                      </div>
                      :
                      null
                    }

                    {ques.templateId === 4 ?
                      <div className="p-2 mx-2">
                        <textarea type="text" placeholder="Write here..." className="w-full p-2 border-2 border-blue-200 rounded-lg" rows={3} />
                      </div>
                      :
                      null
                    }

                    {ques.templateId === 6 ?
                      [...Array(10).keys()].map((item, index) =>
                        <div className="inline-block px-2 cursor-default w-30 h-30">
                          <div><img alt="code" style={{ height: '40px', width: "40px" }} src={"img/nps/" + (index + 1) + ".png"} /></div>
                          <div className="text-center">{item + 1}</div>
                        </div>
                      )
                      :
                      null
                    }

                  </div>
                </>
              )
              : null}




          </div>

        </div></main>
    </>
  );
}
