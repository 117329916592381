import {
  dashboardConstants
} from '../_constants';

export function dashboard(state = {}, action) {

  switch (action.type) {


    case dashboardConstants.GET_CLIENT_PROFILE_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_CLIENT_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        getClientProfileSuccess: true,
        loading: false,
        ClientProfileData: action.dashboard.ClientProfileData,
      };
    case dashboardConstants.GET_CLIENT_PROFILE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_STATIC_BENCHMARK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STATIC_BENCHMARK_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllBenchMarkStaticSuccess: true,
        getAllBenchMarkStaticData: action.dashboard.getAllBenchMarkStaticData,
      };
    case dashboardConstants.GET_STATIC_BENCHMARK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_V2_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        getEngagementIndexByFilterSuccess: true,
        getEngagementIndexByFilterV2Data: action.dashboard.getIndexFilterData
      };

    case dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_V2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_V2_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        getManagerIndexByFilterSuccess: true,
        getManagerIndexByFilterV2Data: action.dashboard.getIndexFilterData
      };

    case dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_V2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_V2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexEngagementSuccess: true,
        getDemographicIndexError: "",
        getDemographicIndexEngagementV2Data: action.dashboard.getDemographicIndexEngagementData
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_V2_FAILURE:
      return {
        ...state,
        loading: false,
        getDemographicIndexError: action.error,
        error: action.error
      };



    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_V2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexManagerSuccess: true,
        getDemographicIndexError: "",
        getDemographicIndexManagerV2Data: action.dashboard.getDemographicIndexManagerData
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_V2_FAILURE:
      return {
        ...state,
        loading: false,
        getDemographicIndexError: action.error,
        error: action.error
      };







    case dashboardConstants.GET_ASSESSMENT_TOP5_DESC_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ASSESSMENT_TOP5_DESC_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentTop5DescByNamesSuccess: true,
        getAssessmentTop5DescByNamesData: action.dashboard.getAssessmentTop5DescByNamesData,
      };
    case dashboardConstants.GET_ASSESSMENT_TOP5_DESC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.TREE_MAP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.TREE_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        TreeMapSuccess: true,
        TreeMapError: "",
        TreeMapData: action.dashboard.TreeMapData.list
      };
    case dashboardConstants.TREE_MAP_FAILURE:
      return {
        ...state,
        loading: false,
        TreeMapError: action.error,
        error: action.error
      };






    case dashboardConstants.TREE_MAP_LEN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.TREE_MAP_LEN_SUCCESS:
      return {
        ...state,
        loading: false,
        textDistTreeMapSuccess: true,
        textDistTreeError: "",
        textDistTreeMapData: action.dashboard.textDistTreeMapData.list
      };
    case dashboardConstants.TREE_MAP_LEN_FAILURE:
      return {
        ...state,
        loading: false,
        textDistTreeError: action.error,
        error: action.error
      };




    case dashboardConstants.EMP_RATING_SURVEY_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.EMP_RATING_SURVEY_SUCCESS:
      return {
        ...state,
        // generateCompanySurveyCodeSuccess: true,
        // loading: false,
        getEmployeeRatingAndNormalization: action.dashboard.getEmployeeRatingAndNormalization
      };
    case dashboardConstants.EMP_RATING_SURVEY_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };



    case dashboardConstants.SAVE_NORMALIZATION_SURVEY_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.SAVE_NORMALIZATION_SURVEY_SUCCESS:
      return {
        ...state,
        // generateCompanySurveyCodeSuccess: true,
        // loading: false,
        saveEmployeeNormalization: action.dashboard.saveEmployeeNormalization
      };
    case dashboardConstants.SAVE_NORMALIZATION_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GENERATE_COMPANY_SURVEY_CODE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GENERATE_COMPANY_SURVEY_CODE_SUCCESS:
      return {
        ...state,
        generateCompanySurveyCodeSuccess: true,
        loading: false,
        generateCompanySurveyCodeData: action.dashboard.generateCompanySurveyCodeData
      };
    case dashboardConstants.GENERATE_COMPANY_SURVEY_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_PMS_ADD_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.GET_PMS_ADD_SUCCESS:
      return {
        ...state,
        // getDemographicOrgReportSuccess: true,
        // loading: false,
        PMSAddAppraisalCycle1: action.dashboard.PMSAddAppraisalCycle1,
      };
    case dashboardConstants.GET_PMS_ADD_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };



    case dashboardConstants.GET_APPRAISAL_CYCLE_REQUEST:
      return {
        ...state,

      };
    case dashboardConstants.GET_APPRAISAL_CYCLE_SUCCESS:
      return {
        ...state,

        PMSGetAppraisalCycleById: action.dashboard.PMSGetAppraisalCycleById,
      };
    case dashboardConstants.GET_APPRAISAL_CYCLE_FAILURE:
      return {
        ...state,

        error: action.error
      };



    case dashboardConstants.GET_PMSMODULE_SETTING_CYCLE_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.GET_PMSMODULE_SETTING_CYCLE_SUCCESS:
      return {
        ...state,
        // getDemographicOrgReportSuccess: true,
        // loading: false,
        PMSAddModuleSetting2: action.dashboard.PMSAddModuleSetting2,
      };
    case dashboardConstants.GET_PMSMODULE_SETTING_CYCLE_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };






    case dashboardConstants.GET_PMS_GET_MODULE_SETTING_CYCLE_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.GET_PMS_GET_MODULE_SETTING_CYCLE_SUCCESS:
      return {
        ...state,
        // getDemographicOrgReportSuccess: true,
        // loading: false,
        PMSGetModuleSetting2: action.dashboard.PMSGetModuleSetting2
      };
    case dashboardConstants.GET_PMS_GET_MODULE_SETTING_CYCLE_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };







    case dashboardConstants.GET_PMSMODULE_RATER_SETTING_CYCLE_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.GET_PMSMODULE_RATER_SETTING_CYCLE_SUCCESS:
      return {
        ...state,
        // getDemographicOrgReportSuccess: true,
        // loading: false,
        PMSAddRaterSetting3: action.dashboard.PMSAddRaterSetting3,
      };
    case dashboardConstants.GET_PMSMODULE_RATER_SETTING_CYCLE_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };


    case dashboardConstants.GET_GET_PMSMODULE_RATER_SETTING_CYCLE_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.GET_GET_PMSMODULE_RATER_SETTING_CYCLE_SUCCESS:
      return {
        ...state,
        // getDemographicOrgReportSuccess: true,
        // loading: false,
        PMSGetRaterSetting3: action.dashboard.PMSGetRaterSetting3,
      };
    case dashboardConstants.GET_GET_PMSMODULE_RATER_SETTING_CYCLE_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };





    case dashboardConstants.GET_PMSMODULE_APPLICABLE_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.GET_PMSMODULE_APPLICABLE_SUCCESS:
      return {
        ...state,
        // getDemographicOrgReportSuccess: true,
        // loading: false,
        PMSApplicableFor3: action.dashboard.PMSApplicableFor3,
      };
    case dashboardConstants.GET_PMSMODULE_APPLICABLE_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };



    case dashboardConstants.GET_PMSMODULE_APPLICABLE4_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.GET_PMSMODULE_APPLICABLE4_SUCCESS:
      return {
        ...state,
        // getDemographicOrgReportSuccess: true,
        // loading: false,
        PMSApplicableFor4: action.dashboard.PMSApplicableFor4,
      };
    case dashboardConstants.GET_PMSMODULE_APPLICABLE4_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };


    case dashboardConstants.GET_PMSMODULE_APPRAISAL_LIST_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.GET_PMSMODULE_APPRAISAL_LIST_SUCCESS:
      return {
        ...state,
        // getDemographicOrgReportSuccess: true,
        // loading: false,
        PMSAppraisalCycleList: action.dashboard.PMSAppraisalCycleList,
      };
    case dashboardConstants.GET_PMSMODULE_APPRAISAL_LIST_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };

    case dashboardConstants.GET_NPS_SURVEY_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.GET_NPS_SURVEY_SUCCESS:
      return {
        ...state,
        // getDemographicOrgReportSuccess: true,
        // loading: false,
        getNpsSurvey: action.dashboard.getNpsSurvey,
      };
    case dashboardConstants.GET_NPS_SURVEY_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };


    case dashboardConstants.CREATE_NPS_SURVEY_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.CREATE_NPS_SURVEY_SUCCESS:
      return {
        ...state,
        // getDemographicOrgReportSuccess: true,
        // loading: false,
        CreateNpsSurvey: action.dashboard.CreateNpsSurvey,
      };
    case dashboardConstants.CREATE_NPS_SURVEY_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };



    case dashboardConstants.GET_PMSMODULE_NAMES_LIST_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.GET_PMSMODULE_NAMES_LIST_SUCCESS:
      return {
        ...state,

        getPMSModuleNames: action.dashboard.getPMSModuleNames
      };
    case dashboardConstants.GET_PMSMODULE_NAMES_LIST_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };


    case dashboardConstants.GET_PMSRATER_NAMES_LIST_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.GET_PMSRATER_NAMES_LIST_SUCCESS:
      return {
        ...state,

        getPMSRaterNames: action.dashboard.getPMSRaterNames
      };
    case dashboardConstants.GET_PMSRATER_NAMES_LIST_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };






    case dashboardConstants.GET_DEMOGRAPHIC_ORG_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_ORG_REPORT_SUCCESS:
      return {
        ...state,
        getDemographicOrgReportSuccess: true,
        loading: false,
        getDemographicOrgReportData: action.dashboard.getDemographicOrgReportData,
      };
    case dashboardConstants.GET_DEMOGRAPHIC_ORG_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_DEMOGRAPHIC_ORG_REPORT_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_ORG_REPORT_2_SUCCESS:
      return {
        ...state,
        getDemographicOrgReport2Success: true,
        loading: false,
        getDemographicOrgReport2Data: action.dashboard.getDemographicOrgReportData,
      };
    case dashboardConstants.GET_DEMOGRAPHIC_ORG_REPORT_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    //----------------------------------------------------------------- 

    case dashboardConstants.GET_SURVEY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_SURVEY_SUCCESS:
      return {
        ...state,
        getSurveySuccess: true,
        loading: false,
        SurveyList: action.dashboard.SurveyData.list,
        SurveyTotal: action.dashboard.SurveyData.total

      };
    case dashboardConstants.GET_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    //----------------------------------------------------------------- 


    case dashboardConstants.GET_HAPPINESS_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPPINESS_INDEX_SUCCESS:
      return {
        ...state,
        getHappynessIndexSuccess: true,
        loading: false,
        HappynessIndexData: action.dashboard.HappynessIndexData

      };
    case dashboardConstants.GET_HAPPINESS_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    //----------------------------------------------------------------- 

    case dashboardConstants.GET_ENGAGEMENT_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_SUCCESS:
      return {
        ...state,
        getEngagementIndexSuccess: true,
        loading: false,
        EngagementIndexData: action.dashboard.EngagementIndexData

      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    //----------------------------------------------------------------- 

    case dashboardConstants.GET_HOPE_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HOPE_INDEX_SUCCESS:
      return {
        ...state,
        getHopeIndexSuccess: true,
        loading: false,
        HopeIndexData: action.dashboard.HopeIndexData

      };
    case dashboardConstants.GET_HOPE_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    //----------------------------------------------------------------- 

    case dashboardConstants.GET_STRESS_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_INDEX_SUCCESS:
      return {
        ...state,
        getStressIndexSuccess: true,
        loading: false,
        StressIndexData: action.dashboard.StressIndexData

      };
    case dashboardConstants.GET_STRESS_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    //----------------------------------------------------------------- 

    case dashboardConstants.GET_MANAGER_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_INDEX_SUCCESS:
      return {
        ...state,
        getManagerIndexSuccess: true,
        loading: false,
        ManagerIndexData: action.dashboard.ManagerIndexData

      };
    case dashboardConstants.GET_MANAGER_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    //----------------------------------------------------------------- 

    case dashboardConstants.GET_LEADER_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_LEADER_INDEX_SUCCESS:
      return {
        ...state,
        getLeaderIndexSuccess: true,
        loading: false,

        LeaderIndexData: action.dashboard.LeaderIndexData

      };
    case dashboardConstants.GET_LEADER_INDEX_FAILURE:
      return {
        ...state,
        loading: false,

        error: action.error
      };

    //----------------------------------------------------------------- 

    case dashboardConstants.GET_HAPPINESS_INDEX_MNGR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPPINESS_INDEX_MNGR_SUCCESS:
      return {
        ...state,
        getHappinessIndexMNGRSuccess: true,
        loading: false,
        HappinessIndexMNGRData: action.dashboard.HappinessIndexMNGRData

      };
    case dashboardConstants.GET_HAPPINESS_INDEX_MNGR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    //----------------------------------------------------------------- 

    case dashboardConstants.GET_ENGAGEMENT_INDEX_MNGR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_MNGR_SUCCESS:
      return {
        ...state,
        getEngagementIndexMNGRSuccess: true,
        loading: false,
        EngagementIndexMNGRData: action.dashboard.EngagementIndexMNGRData

      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_MNGR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    //----------------------------------------------------------------- 

    case dashboardConstants.GET_HOPE_INDEX_MNGR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HOPE_INDEX_MNGR_SUCCESS:
      return {
        ...state,
        getHopeIndexMNGRSuccess: true,
        loading: false,
        HopeIndexMNGRData: action.dashboard.HopeIndexMNGRData

      };
    case dashboardConstants.GET_HOPE_INDEX_MNGR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    //----------------------------------------------------------------- 

    case dashboardConstants.GET_STRESS_INDEX_MNGR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_INDEX_MNGR_SUCCESS:
      return {
        ...state,
        getStressIndexMNGRSuccess: true,
        loading: false,
        StressIndexMNGRData: action.dashboard.StressIndexMNGRData

      };
    case dashboardConstants.GET_STRESS_INDEX_MNGR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    //----------------------------------------------------------------- 

    case dashboardConstants.GET_HAPPINESS_INDEX_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPPINESS_INDEX_FILTER_SUCCESS:
      return {
        ...state,
        getHappinessIndexFilterSuccess: true,
        loading: false,
        HappinessIndexFilterData: action.dashboard.HappinessIndexFilterData

      };
    case dashboardConstants.GET_HAPPINESS_INDEX_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    //----------------------------------------------------------------- 

    case dashboardConstants.GET_ENGAGEMENT_INDEX_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getEngagementIndexFilterSuccess: true,
        EngagementIndexFilterData: action.dashboard.EngagementIndexFilterData

      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    //----------------------------------------------------------------- 

    case dashboardConstants.GET_HOPE_INDEX_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HOPE_INDEX_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getHopeIndexFilterSuccess: true,
        HopeIndexFilterData: action.dashboard.HopeIndexFilterData

      };
    case dashboardConstants.GET_HOPE_INDEX_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    //----------------------------------------------------------------- 

    case dashboardConstants.GET_STRESS_INDEX_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_INDEX_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getStressIndexFilterSuccess: true,
        StressIndexFilterData: action.dashboard.StressIndexFilterData

      };
    case dashboardConstants.GET_STRESS_INDEX_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    //----------------------------------------------------------------- 

    case dashboardConstants.GET_MANAGER_INDEX_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_INDEX_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getManagerIndexFilterSuccess: true,
        ManagerIndexFilterData: action.dashboard.ManagerIndexFilterData

      };
    case dashboardConstants.GET_MANAGER_INDEX_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    //----------------------------------------------------------------- 

    case dashboardConstants.GET_LEADER_INDEX_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_LEADER_INDEX_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getLeaderIndexFilterSuccess: true,
        LeaderIndexFilterData: action.dashboard.LeaderIndexFilterData

      };
    case dashboardConstants.GET_LEADER_INDEX_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    //----------------------------------------------------------------- 

    case dashboardConstants.GET_EMP_FILTER_REQUEST:
      return {
        ...state,
        loading: false
      };
    case dashboardConstants.GET_EMP_FILTER_SUCCESS:
      return {
        ...state,
        getEmpFilterSuccess: true,
        loading: false,

        EmpFilterData: action.dashboard.EmpFilterData

      };
    case dashboardConstants.GET_EMP_FILTER_FAILURE:
      return {
        ...state,
        loading: false,

        error: action.error
      };


    case dashboardConstants.GET_MANAGER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_LIST_SUCCESS:
      return {
        ...state,
        getManagerListSuccess: true,
        loading: false,

        ManagerListData: action.dashboard.ManagerList.list,
      };
    case dashboardConstants.GET_MANAGER_LIST_FAILURE:
      return {
        ...state,
        loading: false,

        error: action.error
      };



    case dashboardConstants.GET_QUESTION_ANALYSIS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_ANALYSIS_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionAnalysisSuccess: true,
        QuestionAnalysisData: action.dashboard.QuestionAnalysisData.questionDetails,
      };
    case dashboardConstants.GET_QUESTION_ANALYSIS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_QUESTION_ANALYSIS_NEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_ANALYSIS_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionAnalysisOverallSuccess: true,
        QuestionAnalysisOverallData: action.dashboard.QuestionAnalysisOverallData.questionDetails,
      };
    case dashboardConstants.GET_QUESTION_ANALYSIS_NEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_QUESTION_ANALYSIS_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_ANALYSIS_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionAnalysisFilterSuccess: true,
        QuestionAnalysisFilterData: action.dashboard.QuestionAnalysisFilterData,
      };
    case dashboardConstants.GET_QUESTION_ANALYSIS_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_COMPANY_EMP_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_COMPANY_EMP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getCompanyEmployeeListSuccess: true,
        CompanyEmployeeList: action.dashboard.CompanyEmployeeList
      };
    case dashboardConstants.GET_COMPANY_EMP_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_ASSIGN_ROLE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ASSIGN_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssignRoleSuccess: true
      };
    case dashboardConstants.GET_ASSIGN_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_HAPPINESS_INDEX_NEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPPINESS_INDEX_NEW_SUCCESS:
      return {
        ...state,
        getHappinessIndexNewSuccess: true,
        loading: false,
        HappinessIndexNewData: action.dashboard.HappinessIndexNewData
      };
    case dashboardConstants.GET_HAPPINESS_INDEX_NEW_FAILURE:
      return {
        ...state,
        loading: false,

        error: action.error
      };

    case dashboardConstants.GET_ENGAGEMENT_INDEX_NEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_NEW_SUCCESS:
      return {
        ...state,
        getEngagementIndexNewSuccess: true,
        loading: false,
        EngagementIndexNewData: action.dashboard.EngagementIndexNewData
      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_NEW_FAILURE:
      return {
        ...state,
        loading: false,

        error: action.error
      };

    case dashboardConstants.GET_HOPE_INDEX_NEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HOPE_INDEX_NEW_SUCCESS:
      return {
        ...state,
        getHopeIndexNewSuccess: true,
        loading: false,
        HopeIndexNewData: action.dashboard.HopeIndexNewData
      };
    case dashboardConstants.GET_HOPE_INDEX_NEW_FAILURE:
      return {
        ...state,
        loading: false,

        error: action.error
      };

    case dashboardConstants.GET_STRESS_INDEX_NEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_INDEX_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        getStressIndexNewSuccess: true,
        StressIndexNewData: action.dashboard.StressIndexNewData
      };
    case dashboardConstants.GET_STRESS_INDEX_NEW_FAILURE:
      return {
        ...state,
        loading: false,

        error: action.error
      };



    case dashboardConstants.GET_MANAGER_INDEX_NEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_INDEX_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        getManagerIndexNewSuccess: true,
        ManagerIndexNewData: action.dashboard.ManagerIndexNewData
      };
    case dashboardConstants.GET_MANAGER_INDEX_NEW_FAILURE:
      return {
        ...state,
        loading: false,

        error: action.error
      };




    case dashboardConstants.GET_ORG_CORE_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ORG_CORE_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        getOrgCoreIndexSuccess: true,
        OrgCoreIndexData: action.dashboard.OrgCoreIndexData
      };
    case dashboardConstants.GET_ORG_CORE_INDEX_FAILURE:
      return {
        ...state,
        loading: false,

        error: action.error
      };



    case dashboardConstants.GET_STACK_LIST_SUCCESS:
      return {
        ...state,
        getStackListSuccess: true,
        loading: false,
        // StackListData:action.dashboard.StackListData
      };





    // new api s------------------------------------------>


    case dashboardConstants.GET_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndexSuccess: true,
        getIndexData: action.dashboard.getIndexData
      };
    case dashboardConstants.GET_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_INDEX_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_INDEX_2_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndex2Success: true,
        getIndex2Data: action.dashboard.getIndexData
      };
    case dashboardConstants.GET_INDEX_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case dashboardConstants.GET_INDEX_VS_NPS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_INDEX_VS_NPS_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndexVsNpsSuccess: true,
        getIndexVsNpsData: action.dashboard.getIndexVsNpsData
      };
    case dashboardConstants.GET_INDEX_VS_NPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };







    case dashboardConstants.GET_OVERALL_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_OVERALL_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        getOverallIndexSuccess: true,
        getOverallIndexData: action.dashboard.getOverallIndexData
      };
    case dashboardConstants.GET_OVERALL_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_OVERALL_INDEX_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_OVERALL_INDEX_2_SUCCESS:
      return {
        ...state,
        loading: false,
        getOverallIndex2Success: true,
        getOverallIndex2Data: action.dashboard.getOverallIndex2Data
      };
    case dashboardConstants.GET_OVERALL_INDEX_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };








    case dashboardConstants.GET_INDEX_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_INDEX_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndexFilterSuccess: true,
        getIndexFilterData: action.dashboard.getIndexFilterData
      };
    case dashboardConstants.GET_INDEX_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_INDEX_2_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_INDEX_2_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndex2FilterSuccess: true,
        getIndex2FilterData: action.dashboard.getIndexFilterData
      };
    case dashboardConstants.GET_INDEX_2_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexSuccess: true,
        getDemographicIndexError: "",
        getDemographicIndexData: action.dashboard.getDemographicIndexData
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        getDemographicIndexError: action.error,
        error: action.error
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_CLEAR:
      return {
        ...state,
        loading: false,
        getDemographicIndexData: {},
        getDemographicIndexError: "",
        error: action.error
      };



    case dashboardConstants.GET_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getQuestionSuccess: true,
        getQuestionError: "",
        getQuestionData: action.dashboard.getQuestionData
      };
    case dashboardConstants.GET_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        getQuestionError: action.error,
        error: action.error
      };




    case dashboardConstants.GET_QUESTION_ONLY_STRESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_ONLY_STRESS_SUCCESS:

      return {
        ...state,
        loading: false,
        getQuestionDistributionSuccess: true,
        getQuestionDistributionError: "",
        getQuestionDistributionData: action.dashboard.getQuestionDistributionData
      };
    case dashboardConstants.GET_QUESTION_ONLY_STRESS_FAILURE:
      return {
        ...state,
        loading: false,
        getQuestionDistributionError: action.error,
        error: action.error
      };






    case dashboardConstants.GET_QUESTION_INDEX_ORG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_INDEX_ORG_SUCCESS:

      return {
        ...state,
        loading: false,
        getQuesDistributionIndexOrgSuccess: true,
        getQuesDistributionIndexOrgError: "",
        getQuesDistributionIndexOrgData: action.dashboard.getQuesDistributionIndexOrgData
      };
    case dashboardConstants.GET_QUESTION_INDEX_ORG_FAILURE:
      return {
        ...state,
        loading: false,
        getQuesDistributionIndexOrgError: action.error,
        error: action.error
      };




    case dashboardConstants.GET_HAPINESS_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPINESS_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getHappinessQuestionSuccess: true,
        getHappinessQuestionData: action.dashboard.getHappinessQuestionData
      };
    case dashboardConstants.GET_HAPINESS_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_HAPINESS_QUESTION_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPINESS_QUESTION_2_SUCCESS:

      return {
        ...state,
        loading: false,
        getHappinessQuestion2Success: true,
        getHappinessQuestion2Data: action.dashboard.getHappinessQuestionData
      };
    case dashboardConstants.GET_HAPINESS_QUESTION_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };







    case dashboardConstants.GET_HAPINESS_MANAGER_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPINESS_MANAGER_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getHappinessManagerQuestionSuccess: true,
        getHappinessManagerQuestionData: action.dashboard.getHappinessQuestionData
      };
    case dashboardConstants.GET_HAPINESS_MANAGER_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_HAPINESS_DMG_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPINESS_DMG_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getHappinessDMGQuestionSuccess: true,
        getHappinessDMGQuestionData: action.dashboard.getHappinessQuestionData
      };
    case dashboardConstants.GET_HAPINESS_DMG_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case dashboardConstants.GET_ENGAGEMENT_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getEngagementQuestionSuccess: true,
        getEngagementQuestionData: action.dashboard.getEngagementQuestionData
      };
    case dashboardConstants.GET_ENGAGEMENT_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_ENGAGEMENT_QUESTION_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_QUESTION_2_SUCCESS:

      return {
        ...state,
        loading: false,
        getEngagementQuestion2Success: true,
        getEngagementQuestion2Data: action.dashboard.getEngagementQuestionData
      };
    case dashboardConstants.GET_ENGAGEMENT_QUESTION_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_ENGAGEMENT_MANAGER_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_MANAGER_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getEngagementManagerQuestionSuccess: true,
        getEngagementManagerQuestionData: action.dashboard.getEngagementQuestionData
      };
    case dashboardConstants.GET_ENGAGEMENT_MANAGER_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_ENGAGEMENT_DMG_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_DMG_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getEngagementDMGQuestionSuccess: true,
        getEngagementDMGQuestionData: action.dashboard.getEngagementQuestionData
      };
    case dashboardConstants.GET_ENGAGEMENT_DMG_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };







    case dashboardConstants.GET_HOPE_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HOPE_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getHopeQuestionSuccess: true,
        getHopeQuestionData: action.dashboard.getHopeQuestionData
      };
    case dashboardConstants.GET_HOPE_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_STRESS_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getStressQuestionSuccess: true,
        getStressQuestionData: action.dashboard.getStressQuestionData
      };
    case dashboardConstants.GET_STRESS_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_STRESS_QUESTION_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_QUESTION_2_SUCCESS:

      return {
        ...state,
        loading: false,
        getStressQuestion2Success: true,
        getStressQuestion2Data: action.dashboard.getStressQuestionData
      };
    case dashboardConstants.GET_STRESS_QUESTION_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_STRESS_MANAGER_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_MANAGER_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getStressManagerQuestionSuccess: true,
        getStressManagerQuestionData: action.dashboard.getStressQuestionData
      };
    case dashboardConstants.GET_STRESS_MANAGER_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_STRESS_DMG_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_DMG_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getStressDMGQuestionSuccess: true,
        getStressDMGQuestionData: action.dashboard.getStressQuestionData
      };
    case dashboardConstants.GET_STRESS_DMG_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_MANAGER_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getManagerQuestionSuccess: true,
        getManagerQuestionData: action.dashboard.getManagerQuestionData
      };
    case dashboardConstants.GET_MANAGER_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_MANAGER_QUESTION_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_QUESTION_2_SUCCESS:

      return {
        ...state,
        loading: false,
        getManagerQuestion2Success: true,
        getManagerQuestion2Data: action.dashboard.getManagerQuestionData
      };
    case dashboardConstants.GET_MANAGER_QUESTION_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_MANAGER_MANAGER_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_MANAGER_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getManagerManagerQuestionSuccess: true,
        getManagerManagerQuestionData: action.dashboard.getManagerQuestionData
      };
    case dashboardConstants.GET_MANAGER_MANAGER_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_MANAGER_DMG_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_DMG_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getManagerDMGQuestionSuccess: true,
        getManagerDMGQuestionData: action.dashboard.getManagerQuestionData
      };
    case dashboardConstants.GET_MANAGER_DMG_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_LEADER_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_LEADER_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getLeaderQuestionSuccess: true,
        getLeaderQuestionData: action.dashboard.getLeaderQuestionData
      };
    case dashboardConstants.GET_LEADER_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_LEADER_QUESTION_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_LEADER_QUESTION_2_SUCCESS:

      return {
        ...state,
        loading: false,
        getLeaderQuestion2Success: true,
        getLeaderQuestion2Data: action.dashboard.getLeaderQuestionData
      };
    case dashboardConstants.GET_LEADER_QUESTION_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_LEADER_MANAGER_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_LEADER_MANAGER_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getLeaderManagerQuestionSuccess: true,
        getLeaderManagerQuestionData: action.dashboard.getLeaderQuestionData
      };
    case dashboardConstants.GET_LEADER_MANAGER_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_LEADER_DMG_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_LEADER_DMG_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getLeaderDMGQuestionSuccess: true,
        getLeaderDMGQuestionData: action.dashboard.getLeaderQuestionData
      };
    case dashboardConstants.GET_LEADER_DMG_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_QUESTION_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_REPORT_SUCCESS:

      return {
        ...state,
        loading: false,
        getQuestionReportSuccess: true,
        getQuestionReportData: action.dashboard.getQuestionReportData
      };
    case dashboardConstants.GET_QUESTION_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_QUESTION_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionFilterSuccess: true,
        getQuestionError: "",
        getQuestionFilterData: action.dashboard.getQuestionFilterData
      };
    case dashboardConstants.GET_QUESTION_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        getQuestionError: action.error,
        error: action.error
      };









    case dashboardConstants.GET_HAPPINESS_INDEX_V2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPPINESS_INDEX_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        getHappinessIndexV2Success: true,
        getHappinessIndexV2Data: action.dashboard.getHappinessIndexV2Data
      };
    case dashboardConstants.GET_HAPPINESS_INDEX_V2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_HAPPINESS_INDEX_V2_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPPINESS_INDEX_V2_2_SUCCESS:
      return {
        ...state,
        loading: false,
        getHappinessIndexV22Success: true,
        getHappinessIndexV22Data: action.dashboard.getHappinessIndexV2Data
      };
    case dashboardConstants.GET_HAPPINESS_INDEX_V2_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        getEngagementIndexV2Success: true,
        getEngagementIndexV2Data: action.dashboard.getEngagementIndexV2Data
      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_2_SUCCESS:
      return {
        ...state,
        loading: false,
        getEngagementIndexV22Success: true,
        getEngagementIndexV22Data: action.dashboard.getEngagementIndexV2Data
      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };







    case dashboardConstants.GET_HOPE_INDEX_V2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HOPE_INDEX_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        getHopeIndexV2Success: true,
        getHopeIndexV2Data: action.dashboard.getHopeIndexV2Data
      };
    case dashboardConstants.GET_HOPE_INDEX_V2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_STRESS_INDEX_V2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_INDEX_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        getStressIndexV2Success: true,
        getStressIndexV2Data: action.dashboard.getStressIndexV2Data
      };
    case dashboardConstants.GET_STRESS_INDEX_V2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_STRESS_INDEX_V2_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_INDEX_V2_2_SUCCESS:
      return {
        ...state,
        loading: false,
        getStressIndexV22Success: true,
        getStressIndexV22Data: action.dashboard.getStressIndexV2Data
      };
    case dashboardConstants.GET_STRESS_INDEX_V2_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_MANAGER_INDEX_V2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_INDEX_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        getHappinessIndexV2Success: true,
        getHappinessIndexV2Data: action.dashboard.getHappinessIndexV2Data
      };
    case dashboardConstants.GET_MANAGER_INDEX_V2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case dashboardConstants.GET_LEADER_INDEX_V2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_LEADER_INDEX_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        getHappinessIndexV2Success: true,
        getHappinessIndexV2Data: action.dashboard.getHappinessIndexV2Data
      };
    case dashboardConstants.GET_LEADER_INDEX_V2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_SENIORLEADERSHIP_INDEX_V2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_SENIORLEADERSHIP_INDEX_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        getHappinessIndexV2Success: true,
        getHappinessIndexV2Data: action.dashboard.getHappinessIndexV2Data
      };
    case dashboardConstants.GET_SENIORLEADERSHIP_INDEX_V2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_ORG_INDEX_V2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ORG_INDEX_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        getOrgIndexV2Success: true,
        getOrgIndexV2Error: "",
        getOrgIndexV2Data: action.dashboard.getOrgIndexV2Data
      };
    case dashboardConstants.GET_ORG_INDEX_V2_FAILURE:
      return {
        ...state,
        loading: false,
        getOrgIndexV2Error: action.error,
        error: action.error
      };










    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_HAPPINESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_HAPPINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexHappinessSuccess: true,
        getDemographicIndexError: "",
        getDemographicIndexHappinessData: action.dashboard.getDemographicIndexHappinessData
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_HAPPINESS_FAILURE:
      return {
        ...state,
        loading: false,
        getDemographicIndexError: action.error,
        error: action.error
      };



    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexEngagementSuccess: true,
        getDemographicIndexError: "",
        getDemographicIndexEngagementData: action.dashboard.getDemographicIndexEngagementData
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        getDemographicIndexError: action.error,
        error: action.error
      };


    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_STRESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_STRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexStressSuccess: true,
        getDemographicIndexError: "",
        getDemographicIndexStressData: action.dashboard.getDemographicIndexStressData
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_STRESS_FAILURE:
      return {
        ...state,
        loading: false,
        getDemographicIndexError: action.error,
        error: action.error
      };


    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_EXPERIENCE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexExperienceSuccess: true,
        getDemographicIndexError: "",
        getDemographicIndexExperienceData: action.dashboard.getDemographicIndexExperienceData
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_EXPERIENCE_FAILURE:
      return {
        ...state,
        loading: false,
        getDemographicIndexError: action.error,
        error: action.error
      };




    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexManagerSuccess: true,
        getDemographicIndexError: "",
        getDemographicIndexManagerData: action.dashboard.getDemographicIndexManagerData
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_FAILURE:
      return {
        ...state,
        loading: false,
        getDemographicIndexError: action.error,
        error: action.error
      };




    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_LEADER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_LEADER_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexLeaderSuccess: true,
        getDemographicIndexError: "",
        getDemographicIndexLeaderData: action.dashboard.getDemographicIndexLeaderData
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_LEADER_FAILURE:
      return {
        ...state,
        loading: false,
        getDemographicIndexError: action.error,
        error: action.error
      };










    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ORG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ORG_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexOrgSuccess: true,
        getDemographicIndexOrgError: "",
        getDemographicIndexOrgData: action.dashboard.getDemographicIndexOrgData
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ORG_FAILURE:
      return {
        ...state,
        loading: false,
        getDemographicIndexOrgError: action.error,
        error: action.error
      };



    case dashboardConstants.GET_DEMOGRAPHIC_MULTI_AXIS_INDEX_ORG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_MULTI_AXIS_INDEX_ORG_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexMultiAxisOrgSuccess: true,
        getDemographicIndexMultiAxisOrgError: "",
        getDemographicIndexMultiAxisOrgData: action.dashboard.getDemographicIndexMultiAxisOrgData
      };
    case dashboardConstants.GET_DEMOGRAPHIC_MULTI_AXIS_INDEX_ORG_FAILURE:
      return {
        ...state,
        loading: false,
        getDemographicIndexMultiAxisOrgError: action.error,
        error: action.error
      };





    case dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        getHappinessIndexDriverSuccess: true,
        getIndexDriverError: "",
        getHappinessIndexDriverData: action.dashboard.getHappinessIndexDriverData
      };
    case dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_FAILURE:
      return {
        ...state,
        loading: false,
        getIndexDriverError: action.error,
        error: action.error
      };


    case dashboardConstants.GET_ENGAGEMENT_INDEX_DRIVER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        getEngagementIndexDriverSuccess: true,
        getIndexDriverError: "",
        getEngagementIndexDriverData: action.dashboard.getEngagementIndexDriverData
      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_DRIVER_FAILURE:
      return {
        ...state,
        loading: false,
        getIndexDriverError: action.error,
        error: action.error
      };


    case dashboardConstants.GET_STRESS_INDEX_DRIVER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_INDEX_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        getStressIndexDriverSuccess: true,
        getIndexDriverError: "",
        getStressIndexDriverData: action.dashboard.getStressIndexDriverData
      };
    case dashboardConstants.GET_STRESS_INDEX_DRIVER_FAILURE:
      return {
        ...state,
        loading: false,
        getIndexDriverError: action.error,
        error: action.error
      };


    case dashboardConstants.GET_MANAGER_INDEX_DRIVER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_INDEX_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        getManagerIndexDriverSuccess: true,
        getIndexDriverError: "",
        getManagerIndexDriverData: action.dashboard.getManagerIndexDriverData
      };
    case dashboardConstants.GET_MANAGER_INDEX_DRIVER_FAILURE:
      return {
        ...state,
        loading: false,
        getIndexDriverError: action.error,
        error: action.error
      };



    case dashboardConstants.GET_EXPERIENCE_INDEX_DRIVER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_EXPERIENCE_INDEX_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        getExperienceIndexDriverSuccess: true,
        getIndexDriverError: "",
        getExperienceIndexDriverData: action.dashboard.getExperienceIndexDriverData
      };
    case dashboardConstants.GET_EXPERIENCE_INDEX_DRIVER_FAILURE:
      return {
        ...state,
        loading: false,
        getIndexDriverError: action.error,
        error: action.error
      };



    case dashboardConstants.GET_LEADER_INDEX_DRIVER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_LEADER_INDEX_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        getLeaderIndexDriverSuccess: true,
        getIndexDriverError: "",
        getLeaderIndexDriverData: action.dashboard.getLeaderIndexDriverData,
      };
    case dashboardConstants.GET_LEADER_INDEX_DRIVER_FAILURE:
      return {
        ...state,
        loading: false,
        getIndexDriverError: action.error,
        error: action.error
      };



    case dashboardConstants.GET_ORG_INDEX_DRIVER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ORG_INDEX_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        getOrgIndexDriverSuccess: true,
        getOrgIndexDriverData: action.dashboard.getOrgIndexDriverData
      };
    case dashboardConstants.GET_ORG_INDEX_DRIVER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case dashboardConstants.GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_SUCCESS:
      return {
        ...state,
        loading: false,
        getHappinessIndexV2DemographicSuccess: true,
        getIndexV2DemographicError: "",
        getHappinessIndexV2DemographicData: action.dashboard.getHappinessIndexV2DemographicData
      };
    case dashboardConstants.GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_FAILURE:
      return {
        ...state,
        loading: false,
        getIndexV2DemographicError: action.error,
        error: action.error
      };


    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_SUCCESS:
      return {
        ...state,
        loading: false,
        getEngagementIndexV2DemographicSuccess: true,
        getEngagementIndexV2DemographicData: action.dashboard.getEngagementIndexV2DemographicData
      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_STRESS_INDEX_V2_DEMOGRAPHIC_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_INDEX_V2_DEMOGRAPHIC_SUCCESS:
      return {
        ...state,
        loading: false,
        getStressIndexV2DemographicSuccess: true,
        getStressIndexV2DemographicData: action.dashboard.getStressIndexV2DemographicData
      };
    case dashboardConstants.GET_STRESS_INDEX_V2_DEMOGRAPHIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexForManagerSuccess: true,
        getDemographicIndexForManagerData: action.dashboard.getDemographicIndexForManagerData
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        getManagerListDashboardManagerIndexSuccess: true,
        getManagerListDashboardManagerIndexData: action.dashboard.getManagerListDashboardManagerIndexData
      };
    case dashboardConstants.GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_MULTIMANAGER_QUESTIONLIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MULTIMANAGER_QUESTIONLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getMultiManagerQuestionListSuccess: true,
        getMultiManagerQuestionListData: action.dashboard.getMultiManagerQuestionListData
      };
    case dashboardConstants.GET_MULTIMANAGER_QUESTIONLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_ALL_BENCHMARK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ALL_BENCHMARK_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllBenchMarkSuccess: true,
        getAllBenchMarkData: action.dashboard.getAllBenchMarkData
      };
    case dashboardConstants.GET_ALL_BENCHMARK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_RESPONDANT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_RESPONDANT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        getRespondantDetailsSuccess: true,
        getRespondantDetailsData: action.dashboard.getRespondantDetailsData
      };
    case dashboardConstants.GET_RESPONDANT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_RESPONDANT_DETAILS_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_RESPONDANT_DETAILS_2_SUCCESS:
      return {
        ...state,
        loading: false,
        getRespondantDetails2Success: true,
        getRespondantDetails2Data: action.dashboard.getRespondantDetails2Data
      };
    case dashboardConstants.GET_RESPONDANT_DETAILS_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_META_360_RESPONDANT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_META_360_RESPONDANT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        getMetaRespondantDetailsSuccess: true,
        getMetaRespondantDetailsData: action.dashboard.getMetaRespondantDetailsData
      };
    case dashboardConstants.GET_META_360_RESPONDANT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_META_RESPONDANT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_META_RESPONDANT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        getMetaRespondantDetailsSuccess: true,
        getMetaRespondantDetailsData: action.dashboard.getMetaRespondantDetailsData
      };
    case dashboardConstants.GET_META_RESPONDANT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_META_RESPONDANT_DETAILS_2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_META_RESPONDANT_DETAILS_2_SUCCESS:
      return {
        ...state,
        loading: false,
        getMetaRespondantDetails2Success: true,
        getMetaRespondantDetails2Data: action.dashboard.getMetaRespondantDetails2Data
      };
    case dashboardConstants.GET_META_RESPONDANT_DETAILS_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case dashboardConstants.GET_CUSTOMER_RESPONDANT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_CUSTOMER_RESPONDANT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        getRespondantDetailsSuccess: true,
        getRespondantDetailsData: action.dashboard.getRespondantDetailsData
      };
    case dashboardConstants.GET_CUSTOMER_RESPONDANT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_MANAGER_LIST_DOMAIN_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_LIST_DOMAIN_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getManagerListDomainFilterSuccess: true,
        getManagerListDomainFilterData: action.dashboard.getManagerListDomainFilterData
      };
    case dashboardConstants.GET_MANAGER_LIST_DOMAIN_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_QUESTION_MANAGER_OF_HAPPINESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_MANAGER_OF_HAPPINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionManagerOfHappinessSuccess: true,
        getQuestionManagerOfHappinessData: action.dashboard.getQuestionManagerOfHappinessData
      };
    case dashboardConstants.GET_QUESTION_MANAGER_OF_HAPPINESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_QUESTION_MANAGER_OF_ENGAGEMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_MANAGER_OF_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionManagerOfEngagementSuccess: true,
        getQuestionManagerOfEngagementData: action.dashboard.getQuestionManagerOfEngagementData
      };
    case dashboardConstants.GET_QUESTION_MANAGER_OF_ENGAGEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_QUESTION_MANAGER_OF_STRESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_MANAGER_OF_STRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionManagerOfStressSuccess: true,
        getQuestionManagerOfStressData: action.dashboard.getQuestionManagerOfStressData
      };
    case dashboardConstants.GET_QUESTION_MANAGER_OF_STRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_QUESTION_MANAGER_OF_MANAGER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_MANAGER_OF_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionManagerOfManagerSuccess: true,
        getQuestionManagerOfManagerData: action.dashboard.getQuestionManagerOfManagerData
      };
    case dashboardConstants.GET_QUESTION_MANAGER_OF_MANAGER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_INDEX_QUANTILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_INDEX_QUANTILE_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndexQuantileSuccess: true,
        getIndexQuantileError: "",
        getIndexQuantileData: action.dashboard.getIndexQuantileData.list,
        getIndexQuantileCount: action.dashboard.getIndexQuantileData.count
      };
    case dashboardConstants.GET_INDEX_QUANTILE_FAILURE:
      return {
        ...state,
        loading: false,
        getIndexQuantileError: action.error,
        error: action.error
      };



    case dashboardConstants.GET_HAPPINESS_CORRELATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPPINESS_CORRELATION_SUCCESS:
      return {
        ...state,
        loading: false,
        getHappinessCorrelationSuccess: true,
        getHappinessCorrelationData: action.dashboard.getHappinessCorrelationData
      };
    case dashboardConstants.GET_HAPPINESS_CORRELATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_ENGAGEMENT_CORRELATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_CORRELATION_SUCCESS:
      return {
        ...state,
        loading: false,
        getEngagementCorrelationSuccess: true,
        getEngagementCorrelationData: action.dashboard.getEngagementCorrelationData
      };
    case dashboardConstants.GET_ENGAGEMENT_CORRELATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_STRESS_CORRELATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_CORRELATION_SUCCESS:
      return {
        ...state,
        loading: false,
        getStressCorrelationSuccess: true,
        getStressCorrelationData: action.dashboard.getStressCorrelationData
      };
    case dashboardConstants.GET_STRESS_CORRELATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_MANAGER_CORRELATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_CORRELATION_SUCCESS:
      return {
        ...state,
        loading: false,
        getManagerCorrelationSuccess: true,
        getManagerCorrelationData: action.dashboard.getManagerCorrelationData
      };
    case dashboardConstants.GET_MANAGER_CORRELATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_LEADER_CORRELATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_LEADER_CORRELATION_SUCCESS:
      return {
        ...state,
        loading: false,
        getLeaderCorrelationSuccess: true,
        getLeaderCorrelationData: action.dashboard.getLeaderCorrelationData
      };
    case dashboardConstants.GET_LEADER_CORRELATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_SCATTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_SCATTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexScatterSuccess: true,
        getDemographicIndexScatterData: action.dashboard.getDemographicIndexScatterData
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_SCATTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_RWA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_RWA_SUCCESS:
      return {
        ...state,
        loading: false,
        getRWAnalysisSuccess: true,
        getRWAnalysisData: action.dashboard.getRWAnalysisData
      };
    case dashboardConstants.GET_RWA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_TEXT_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_TEXT_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        getTextQuestionSuccess: true,
        getTextQuestionData: action.dashboard.getTextQuestionData
      };
    case dashboardConstants.GET_TEXT_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_NAME_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_NAME_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        getNameQuestionSuccess: true,
        getNameQuestionData: action.dashboard.getNameQuestionData
      };
    case dashboardConstants.GET_NAME_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_FEEDBACK_TEXT_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_FEEDBACK_TEXT_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        getFeedbackTextQuestionSuccess: true,
        getFeedbackTextQuestionData: action.dashboard.getFeedbackTextQuestionData
      };
    case dashboardConstants.GET_FEEDBACK_TEXT_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.TEXT_DIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.TEXT_DIST_SUCCESS:
      return {
        ...state,
        loading: false,
        textDistSuccess: true,
        textDistData: action.dashboard.textDistData
      };
    case dashboardConstants.TEXT_DIST_FAILURE:
      return {
        ...state,
        loading: false,
        textDistError: action.error,
        error: action.error
      };





    case dashboardConstants.WORD_CLOUD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.WORD_CLOUD_SUCCESS:
      return {
        ...state,
        loading: false,
        wordCloudSuccess: true,
        wordCloudData: action.dashboard.wordCloudData
      };
    case dashboardConstants.WORD_CLOUD_FAILURE:
      return {
        ...state,
        loading: false,
        wordCloudError: action.error,
        error: action.error
      };


    case dashboardConstants.GET_ALL_FEEDBACK_WORDS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ALL_FEEDBACK_WORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllFeedbackWordsSuccess: true,
        getAllFeedbackWordsData: action.dashboard.getAllFeedbackWordsData
      };
    case dashboardConstants.GET_ALL_FEEDBACK_WORDS_FAILURE:
      return {
        ...state,
        loading: false,
        getAllFeedbackWordsError: action.error,
        error: action.error
      };




    case dashboardConstants.TOPIC_CLOUD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.TOPIC_CLOUD_SUCCESS:
      return {
        ...state,
        loading: false,
        topicCloudSuccess: true,
        topicCloudData: action.dashboard.topicCloudData
      };
    case dashboardConstants.TOPIC_CLOUD_FAILURE:
      return {
        ...state,
        loading: false,
        topicCloudError: action.error,
        error: action.error
      };





    case dashboardConstants.TEXT_CLUSTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.TEXT_CLUSTER_SUCCESS:
      return {
        ...state,
        loading: false,
        wordCloudSuccess: true,
        wordCloudData: action.dashboard.wordCloudData
      };
    case dashboardConstants.TEXT_CLUSTER_FAILURE:
      return {
        ...state,
        loading: false,
        wordCloudError: action.error,
        error: action.error
      };






    case dashboardConstants.ADVANCE_WORD_CLOUD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.ADVANCE_WORD_CLOUD_SUCCESS:
      return {
        ...state,
        loading: false,
        AdvanceWordCloudSuccess: true,
        AdvanceWordCloudData: action.dashboard.AdvanceWordCloudData
      };
    case dashboardConstants.ADVANCE_WORD_CLOUD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.WORD_CLOUD_TEXT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.WORD_CLOUD_TEXT_SUCCESS:
      return {
        ...state,
        loading: false,
        wordCloudTextSuccess: true,
        wordCloudTextData: action.dashboard.wordCloudTextData,
        wordTextKeyWord: action.dashboard.wordTextKeyWord

      };
    case dashboardConstants.WORD_CLOUD_TEXT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.WORD_CLOUD_MULTI_TEXT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.WORD_CLOUD_MULTI_TEXT_SUCCESS:
      return {
        ...state,
        loading: false,
        wordCloudTextSuccess: true,
        wordCloudMultiTextData: action.dashboard.wordCloudMultiTextData,
        wordMultiTextKeyWord: action.dashboard.wordMultiTextKeyWord

      };
    case dashboardConstants.WORD_CLOUD_MULTI_TEXT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.COOCCUR_MATRIX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.COOCCUR_MATRIX_SUCCESS:
      return {
        ...state,
        loading: false,
        cooccurmatrixSuccess: true,
        cooccurmatrixData: action.dashboard.cooccurmatrixData
      };
    case dashboardConstants.COOCCUR_MATRIX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.NEIGHBOR_GRAPH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.NEIGHBOR_GRAPH_SUCCESS:
      return {
        ...state,
        loading: false,
        neighborGraphSuccess: true,
        neighborGraphData: action.dashboard.neighborGraphData
      };
    case dashboardConstants.NEIGHBOR_GRAPH_FAILURE:
      return {
        ...state,
        loading: false,
        neighborGraphError: action.error,
        error: action.error
      };



    case dashboardConstants.GET_HAPINESS_RWA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPINESS_RWA_SUCCESS:
      return {
        ...state,
        loading: false,
        getHappinessRWASuccess: true,
        getHappinessRWAData: action.dashboard.getEngagementRWAData
      };
    case dashboardConstants.GET_HAPINESS_RWA_FAILURE:
      return {
        ...state,
        loading: false,
        getHappinessRWAError: action.error,
        error: action.error
      };



    case dashboardConstants.GET_ENGAGEMENT_RWA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_RWA_SUCCESS:
      return {
        ...state,
        loading: false,
        getEngagementRWASuccess: true,
        getEngagementRWAData: action.dashboard.getEngagementRWAData
      };
    case dashboardConstants.GET_ENGAGEMENT_RWA_FAILURE:
      return {
        ...state,
        loading: false,
        getEngagementRWAError: action.error,
        error: action.error
      };



    case dashboardConstants.GET_STRESS_RWA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_RWA_SUCCESS:
      return {
        ...state,
        loading: false,
        getStressRWASuccess: true,
        getStressRWAData: action.dashboard.getEngagementRWAData
      };
    case dashboardConstants.GET_STRESS_RWA_FAILURE:
      return {
        ...state,
        loading: false,
        getStressRWAError: action.error,
        error: action.error
      };


    case dashboardConstants.GET_MANAGER_RWA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_RWA_SUCCESS:
      return {
        ...state,
        loading: false,
        getManagerRWASuccess: true,
        getManagerRWAData: action.dashboard.getEngagementRWAData
      };
    case dashboardConstants.GET_MANAGER_RWA_FAILURE:
      return {
        ...state,
        loading: false,
        getManagerRWAError: action.error,
        error: action.error
      };


    case dashboardConstants.GET_LEADER_RWA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_LEADER_RWA_SUCCESS:
      return {
        ...state,
        loading: false,
        getLeaderRWASuccess: true,
        getLeaderRWAData: action.dashboard.getEngagementRWAData
      };
    case dashboardConstants.GET_LEADER_RWA_FAILURE:
      return {
        ...state,
        loading: false,
        getLeaderRWAError: action.error,
        error: action.error
      };




    case dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        cronbatchAlphaVarianceSuccess: true,
        cronbatchAlphaVarianceData: action.dashboard.cronbatchAlphaVarianceData
      };
    case dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_SUCCESS_TWO:
      return {
        ...state,
        loading: false,
        cronbatchAlphaVarianceSuccess: true,
        cronbatchAlphaVarianceData: action.dashboard.cronbatchAlphaVarianceData
      };
    case dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_QUESTION_FAVOURABILITY_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_QUESTION_FAVOURABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionFavorabilitySuccess: true,
        getQuestionFavorabilityData: action.dashboard.getQuestionFavorabilityData
      };

    case dashboardConstants.GET_QUESTION_FAVOURABILITY_FAILURE:
      return {
        ...state,
        loading: false,
        getQuestionFavorabilityError: action.error,
        error: action.error
      };





    case dashboardConstants.GET_QUESTION_FAVOURABILITY_2_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_QUESTION_FAVOURABILITY_2_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionFavorability2Success: true,
        getQuestionFavorability2Data: action.dashboard.getQuestionFavorability2Data
      };

    case dashboardConstants.GET_QUESTION_FAVOURABILITY_2_FAILURE:
      return {
        ...state,
        loading: false,
        getQuestionFavorability2Error: action.error,
        error: action.error
      };


    case dashboardConstants.GET_QUESTION_FAVOURABILITY_API_2_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_QUESTION_FAVOURABILITY_API_2_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionFavorabilityAPI2Success: true,
        getQuestionFavorabilityAPI2Data: action.dashboard.getQuestionFavorabilityData
      };

    case dashboardConstants.GET_QUESTION_FAVOURABILITY_API_2_FAILURE:
      return {
        ...state,
        loading: false,
        getQuestionFavorabilityAPI2Error: action.error,
        error: action.error
      };



    case dashboardConstants.GET_QUESTION_FAVOURABILITY_API_4_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_QUESTION_FAVOURABILITY_API_4_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionFavorabilityAPI4Success: true,
        getQuestionFavorabilityAPI4Data: action.dashboard.getQuestionFavorabilityData
      };

    case dashboardConstants.GET_QUESTION_FAVOURABILITY_API_4_FAILURE:
      return {
        ...state,
        loading: false,
        getQuestionFavorabilityAPI4Error: action.error,
        error: action.error
      };





    case dashboardConstants.GET_HISTORY_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_HISTORY_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        getHistoryIndexSuccess: true,
        getHistoryIndexData: action.dashboard.getHistoryIndexData
      };

    case dashboardConstants.GET_HISTORY_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_RESPONSE_ANALYTIYCS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_RESPONSE_ANALYTIYCS_SUCCESS:
      return {
        ...state,
        loading: false,
        getResponseAnalyticsSuccess: true,
        getResponseAnalyticsData: action.dashboard.getResponseAnalyticsData
      };

    case dashboardConstants.GET_RESPONSE_ANALYTIYCS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_RESPONSE_META_ANALYTIYCS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_RESPONSE_META_ANALYTIYCS_SUCCESS:
      return {
        ...state,
        loading: false,
        getMetaResponseAnalyticsFilterSuccess: true,
        getMetaResponseAnalyticsFilterData: action.dashboard.getMetaResponseAnalyticsFilterData
      };

    case dashboardConstants.GET_RESPONSE_META_ANALYTIYCS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_RESPONSE_META_360_ANALYTIYCS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_RESPONSE_META_360_ANALYTIYCS_SUCCESS:
      return {
        ...state,
        loading: false,
        getMetaResponseAnalyticsFilterSuccess: true,
        getMetaResponseAnalyticsFilterData: action.dashboard.getMetaResponseAnalyticsFilterData
      };

    case dashboardConstants.GET_RESPONSE_META_360_ANALYTIYCS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_RESPONSE_CUST_V2_ANALYTIYCS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_RESPONSE_CUST_V2_ANALYTIYCS_SUCCESS:
      return {
        ...state,
        loading: false,
        getResponseAnalyticsSuccess: true,
        getResponseAnalyticsData: action.dashboard.getResponseAnalyticsData
      };

    case dashboardConstants.GET_RESPONSE_CUST_V2_ANALYTIYCS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_COMMENT_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        getCommentSuccess: true,
        getCommentData: action.dashboard.getCommentData.list
      };

    case dashboardConstants.GET_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_DEMOGRAPHIC_MULTI_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_DEMOGRAPHIC_MULTI_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexMultiFilterSuccess: true,
        getDemographicIndexMultiFilterError: "",
        getDemographicIndexMultiFilterData: action.dashboard.getDemographicIndexMultiFilterData
      };

    case dashboardConstants.GET_DEMOGRAPHIC_MULTI_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        getDemographicIndexMultiFilterError: action.error,
        error: action.error
      };




    case dashboardConstants.GET_DEMOGRAPHIC_REPORT_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_DEMOGRAPHIC_REPORT_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexFilterReportSuccess: true,
        getDemographicIndexFilterReportData: action.dashboard.getDemographicIndexFilterReportData
      };

    case dashboardConstants.GET_DEMOGRAPHIC_REPORT_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_ORG_DEMOGRAPHIC_INDEX_MANY_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ORG_DEMOGRAPHIC_INDEX_MANY_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexOrgManyFilterSuccess: true,
        getDemographicIndexOrgManyFilterData: action.dashboard.getDemographicIndexOrgManyFilterData
      };

    case dashboardConstants.GET_ORG_DEMOGRAPHIC_INDEX_MANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANY_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANY_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexMultiManyFilterSuccess: true,
        getDemographicIndexMultiManyFilterData: action.dashboard.getDemographicIndexMultiManyFilterData
      };

    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_ORG_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ORG_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getOrgQuestionSuccess: true,
        getOrgQuestionData: action.dashboard.getOrgQuestionData
      };
    case dashboardConstants.GET_ORG_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_ORG_MANAGER_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ORG_MANAGER_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getOrgManagerQuestionSuccess: true,
        getOrgManagerQuestionData: action.dashboard.getOrgQuestionData
      };
    case dashboardConstants.GET_ORG_MANAGER_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_ORG_DMG_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ORG_DMG_QUESTION_SUCCESS:

      return {
        ...state,
        loading: false,
        getOrgDMGQuestionSuccess: true,
        getOrgDMGQuestionData: action.dashboard.getOrgQuestionData
      };
    case dashboardConstants.GET_ORG_DMG_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.ASSIGN_MANAGER_ACTION_PLAN_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.ASSIGN_MANAGER_ACTION_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        AssignManagerActionPlanSuccess: true,
      };

    case dashboardConstants.ASSIGN_MANAGER_ACTION_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_MANAGER_ACTION_PLAN_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_MANAGER_ACTION_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        getManagerActionPlanSuccess: true,
        getManagerActionPlanData: action.dashboard.getManagerActionPlanData
      };

    case dashboardConstants.GET_MANAGER_ACTION_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_EMPLOYEE_ACTION_PLAN_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_EMPLOYEE_ACTION_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        getEmployeeActionPlanSuccess: true,
        getEmployeeActionPlanData: action.dashboard.getEmployeeActionPlanData
      };

    case dashboardConstants.GET_EMPLOYEE_ACTION_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.UPDATE_EMPLOYEE_TASK_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.UPDATE_EMPLOYEE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        updateEmployeeTaskSuccess: true,
        updateEmployeeTaskData: action.dashboard.updateEmployeeTaskData
      };

    case dashboardConstants.UPDATE_EMPLOYEE_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.UPDATE_EMPLOYEE_RESOURCE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.UPDATE_EMPLOYEE_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateEmployeeResourceSuccess: true,
        updateEmployeeResourceData: action.dashboard.updateEmployeeResourceData
      };

    case dashboardConstants.UPDATE_EMPLOYEE_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_ALL_MANAGER_ACTION_PLAN_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ALL_MANAGER_ACTION_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllManagerActionPlanSuccess: true,
        getAllManagerActionPlanData: action.dashboard.getAllManagerActionPlanData
      };

    case dashboardConstants.GET_ALL_MANAGER_ACTION_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.CROSS_TAB_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.CROSS_TAB_SUCCESS:
      return {
        ...state,
        loading: false,
        crossTabSuccess: true,
        crossTabData: action.dashboard.crossTabData
      };

    case dashboardConstants.CROSS_TAB_FAILURE:
      return {
        ...state,
        loading: false,
        crossTabError: action.error,
        error: action.error
      };



    case dashboardConstants.CROSS_TAB_DMG_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.CROSS_TAB_DMG_SUCCESS:
      return {
        ...state,
        loading: false,
        crossTabDMGSuccess: true,
        crossTabDMGData: action.dashboard.crossTabDMGData
      };

    case dashboardConstants.CROSS_TAB_DMG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_QUESTION_OVERALL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_QUESTION_OVERALL_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionOverallSuccess: true,
        getQuestionOverallData: action.dashboard.getQuestionOverallData
      };

    case dashboardConstants.GET_QUESTION_OVERALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.CROSS_TAB_ANALYSIS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.CROSS_TAB_ANALYSIS_SUCCESS:
      return {
        ...state,
        loading: false,
        crossTabAnalysisSuccess: true,
        crossTabAnalysisData: action.dashboard.crossTabAnalysisData
      };

    case dashboardConstants.CROSS_TAB_ANALYSIS_FAILURE:
      return {
        ...state,
        loading: false,
        crossTabAnalysisError: action.error,
        error: action.error
      };





    case dashboardConstants.CORRELATION_TYPE2_ANALYSIS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.CORRELATION_TYPE2_ANALYSIS_SUCCESS:
      return {
        ...state,
        loading: false,
        getCorrelationQuestionType2Success: true,
        getCorrelationQuestionType2Data: action.dashboard.getCorrelationQuestionType2Data
      };

    case dashboardConstants.CORRELATION_TYPE2_ANALYSIS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_ALL_PARAMETER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ALL_PARAMETER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllParameterListSuccess: true,
        getAllParameterListData: action.dashboard.getAllParameterListData.list
      };

    case dashboardConstants.GET_ALL_PARAMETER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_CORRELATION_OVERALL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_CORRELATION_OVERALL_SUCCESS:
      return {
        ...state,
        loading: false,
        getCorrelationDimenionVsDimensionSuccess: true,
        getCorrelationDimenionVsDimensionError: "",
        getCorrelationDimenionVsDimensionData: action.dashboard.getCorrelationDimenionVsDimensionData
      };

    case dashboardConstants.GET_CORRELATION_OVERALL_FAILURE:
      return {
        ...state,
        loading: false,
        getCorrelationDimenionVsDimensionError: action.error,
        error: action.error
      };



    case dashboardConstants.GET_CORRELATION_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_CORRELATION_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getCorrelationDimensionVsDimensionFilterSuccess: true,
        getCorrelationDimensionVsDimensionFilterData: action.dashboard.getCorrelationDimensionVsDimensionFilterData
      };

    case dashboardConstants.GET_CORRELATION_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };







    case dashboardConstants.GET_MANAGER_PROFILE_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        getManagerProfileSuccess: true,
        loading: false,
        ManagerProfileData: action.dashboard.ManagerProfileData,
      };
    case dashboardConstants.GET_MANAGER_PROFILE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_MANAGER_SURVEY_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_SURVEY_LIST_SUCCESS:
      return {
        ...state,
        getMNGRSurveyListSuccess: true,
        loading: false,
        getMNGRSurveyListData: action.dashboard.getMNGRSurveyListData.list
      };
    case dashboardConstants.GET_MANAGER_SURVEY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_HAPPINESS_INDEX_BY_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_HAPPINESS_INDEX_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getHappinessIndexByFilterSuccess: true,
        getHappinessIndexByFilterData: action.dashboard.getIndexFilterData
      };

    case dashboardConstants.GET_HAPPINESS_INDEX_BY_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getEngagementIndexByFilterSuccess: true,
        getEngagementIndexByFilterData: action.dashboard.getIndexFilterData
      };

    case dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_STRESS_INDEX_BY_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_STRESS_INDEX_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getStressIndexByFilterSuccess: true,
        getStressIndexByFilterData: action.dashboard.getIndexFilterData
      };

    case dashboardConstants.GET_STRESS_INDEX_BY_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getManagerIndexByFilterSuccess: true,
        getManagerIndexByFilterData: action.dashboard.getIndexFilterData
      };

    case dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_LEADER_INDEX_BY_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_LEADER_INDEX_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getLeaderIndexByFilterSuccess: true,
        getLeaderIndexByFilterData: action.dashboard.getIndexFilterData
      };

    case dashboardConstants.GET_LEADER_INDEX_BY_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_ENGAGEMENT_COMPARISON_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ENGAGEMENT_COMPARISON_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexMultiFilterEngCompSuccess: true,
        getDemographicIndexMultiFilterEngCompData: action.dashboard.getDemographicIndexMultiFilterEngCompData
      };

    case dashboardConstants.GET_ENGAGEMENT_COMPARISON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_ENGAGEMENT_COMPARISON_2_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ENGAGEMENT_COMPARISON_2_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexMultiFilterEngComp2Success: true,
        getDemographicIndexMultiFilterEngComp2Data: action.dashboard.getDemographicIndexMultiFilterEngComp2Data
      };

    case dashboardConstants.GET_ENGAGEMENT_COMPARISON_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };







    case dashboardConstants.GET_ENGAGEMENT_COMPARISON_OVERALL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ENGAGEMENT_COMPARISON_OVERALL_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexEngCompOverallSuccess: true,
        getDemographicIndexEngCompOverallData: action.dashboard.getDemographicIndexEngCompOverallData
      };

    case dashboardConstants.GET_ENGAGEMENT_COMPARISON_OVERALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };












    case dashboardConstants.GET_QUESTION_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionCommentsDataSuccess: true,
        getQuestionCommentsData: action.dashboard.getQuestionCommentsData
      };









    case dashboardConstants.GET_BRANCODE_INDEX_SCORE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_BRANCODE_INDEX_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicBranchCodeIndexMultiFilterSuccess: true,
        getDemographicBranchCodeIndexMultiFilterData: action.dashboard.getDemographicBranchCodeIndexMultiFilterData
      };

    case dashboardConstants.GET_BRANCODE_INDEX_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };







    case dashboardConstants.GET_PARAMETER_TYPE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_PARAMETER_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getParameterTypeListSuccess: true,
        getParameterTypeListData: action.dashboard.getParameterTypeListData.list
      };

    case dashboardConstants.GET_PARAMETER_TYPE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.CREATE_PARAMETER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.CREATE_PARAMETER_SUCCESS:
      return {
        ...state,
        loading: false,
        createParameterSuccess: true
      };

    case dashboardConstants.CREATE_PARAMETER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_ORG_INDEX_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ORG_INDEX_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getOrgIndexV2FilterSuccess: true,
        getOrgIndexV2FilterData: action.dashboard.getOrgIndexV2FilterData
      };

    case dashboardConstants.GET_ORG_INDEX_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_ORG_INDEX_FILTER_2_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ORG_INDEX_FILTER_2_SUCCESS:
      return {
        ...state,
        loading: false,
        getOrgIndexV22FilterSuccess: true,
        getOrgIndexV22FilterData: action.dashboard.getOrgIndexV2FilterData
      };

    case dashboardConstants.GET_ORG_INDEX_FILTER_2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case dashboardConstants.GET_HAPINESS_INDEX_V2_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_HAPINESS_INDEX_V2_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getHappinessIndexV2FilterSuccess: true,
        getIndexV2Error: "",
        getHappinessIndexV2FilterData: action.dashboard.getHappinessIndexV2FilterData
      };

    case dashboardConstants.GET_HAPINESS_INDEX_V2_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        getIndexV2Error: action.error,
        error: action.error
      };

    case dashboardConstants.GET_HAPINESS_INDEX_V2_2_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_HAPINESS_INDEX_V2_2_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getHappinessIndexV22FilterSuccess: true,
        getIndexV2Error: "",
        getHappinessIndexV22FilterData: action.dashboard.getHappinessIndexV2FilterData
      };

    case dashboardConstants.GET_HAPINESS_INDEX_V2_2_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        getIndexV2Error: action.error,
        error: action.error
      };





    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getEngagementIndexV2FilterSuccess: true,
        getEngagementIndexV2FilterData: action.dashboard.getEngagementIndexV2FilterData
      };

    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_2_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_2_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getEngagementIndexV22FilterSuccess: true,
        getEngagementIndexV22FilterData: action.dashboard.getEngagementIndexV2FilterData
      };

    case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_2_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_STRESS_INDEX_V2_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_STRESS_INDEX_V2_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getStressIndexV2FilterSuccess: true,
        getStressIndexV2FilterData: action.dashboard.getStressIndexV2FilterData
      };

    case dashboardConstants.GET_STRESS_INDEX_V2_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_STRESS_INDEX_V2_2_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_STRESS_INDEX_V2_2_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getStressIndexV22FilterSuccess: true,
        getStressIndexV22FilterData: action.dashboard.getStressIndexV2FilterData
      };

    case dashboardConstants.GET_STRESS_INDEX_V2_2_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getHappinessIndexDriverFilterSuccess: true,
        getHappinessIndexDriverFilterData: action.dashboard.getHappinessIndexDriverFilterData
      };

    case dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_2_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_2_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getHappinessIndexDriver2FilterSuccess: true,
        getHappinessIndexDriver2FilterData: action.dashboard.getHappinessIndexDriverFilterData
      };

    case dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_2_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getEngagementIndexDriverFilterSuccess: true,
        getEngagementIndexDriverFilterData: action.dashboard.getEngagementIndexDriverFilterData
      };

    case dashboardConstants.GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_ENAGAGEMENT_INDEX_DRIVER_2_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ENAGAGEMENT_INDEX_DRIVER_2_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getEngagementIndexDriver2FilterSuccess: true,
        getEngagementIndexDriver2FilterData: action.dashboard.getEngagementIndexDriverFilterData
      };

    case dashboardConstants.GET_ENAGAGEMENT_INDEX_DRIVER_2_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case dashboardConstants.GET_STRESS_INDEX_DRIVER_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_STRESS_INDEX_DRIVER_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getStressIndexDriverFilterSuccess: true,
        getStressIndexDriverFilterData: action.dashboard.getStressIndexDriverFilterData
      };

    case dashboardConstants.GET_STRESS_INDEX_DRIVER_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_PARAMETER_LIBRARY_ALL_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_PARAMETER_LIBRARY_ALL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getParameterLibraryListSuccess: true,
        getParameterLibraryListData: action.dashboard.getParameterLibraryListData.list
      };

    case dashboardConstants.GET_PARAMETER_LIBRARY_ALL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.CLUSTER_ANALYSIS_REQUEST:
      return {
        ...state,
        loading: true,
        clusterAnalysisMSG: false
      };

    case dashboardConstants.CLUSTER_ANALYSIS_SUCCESS:
      return {
        ...state,
        loading: false,
        clusterAnalysisMSG: false,
        clusterAnalysisData: action.dashboard.clusterAnalysisData.list,
        clusterAnalysisError: "",
        clusterAnalysisCount: action.dashboard.clusterAnalysisData.count
      };

    case dashboardConstants.CLUSTER_ANALYSIS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        clusterAnalysisError: action.error,
        clusterAnalysisMSG: true

      };


    case dashboardConstants.GET_CLUSTER_EMP_INFO_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_CLUSTER_EMP_INFO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getClusterEmpInfoListSuccess: true,
        getClusterEmpInfoError: "",
        getClusterEmpInfoListData: action.dashboard.getClusterEmpInfoListData
      };

    case dashboardConstants.GET_CLUSTER_EMP_INFO_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        getClusterEmpInfoError: action.error,
        error: action.error
      };



    case dashboardConstants.TOPIC_MODELLING_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.TOPIC_MODELLING_SUCCESS:
      return {
        ...state,
        loading: false,
        topicModellingSuccess: true,
        topicModellingError: "",
        topicModellingData: action.dashboard.topicModellingData
      };

    case dashboardConstants.TOPIC_MODELLING_FAILURE:
      return {
        ...state,
        loading: false,
        topicModellingError: action.error,
        error: action.error
      };







    case dashboardConstants.CREATE_TOPIC_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.CREATE_TOPIC_SUCCESS:
      return {
        ...state,
        loading: false,
        createTopicSuccess: true
      };

    case dashboardConstants.CREATE_TOPIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.UPDATE_TOPIC_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.UPDATE_TOPIC_SUCCESS:
      return {
        ...state,
        loading: false,
        updateTopicSuccess: true
      };

    case dashboardConstants.UPDATE_TOPIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.DELETE_TOPIC_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.DELETE_TOPIC_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteTopicSuccess: true
      };

    case dashboardConstants.DELETE_TOPIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_TOPIC_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_TOPIC_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getTopicListSuccess: true,
        getTopicListData: action.dashboard.getTopicListData.list,
        getTopicListTotal: action.dashboard.getTopicListData.total

      };

    case dashboardConstants.GET_TOPIC_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_ALL_KEYWORDS_TEXT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ALL_KEYWORDS_TEXT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllWordKeywordsTextSuccess: true,
        wordCloudTextError: "",
        wordCloudTextData: action.dashboard.getAllWordKeywordsTextData,
        wordTextKeyWord: action.dashboard.getAllWordKeywordsTopic


      };

    case dashboardConstants.GET_ALL_KEYWORDS_TEXT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        wordCloudTextError: action.error,
        error: action.error
      };






    case dashboardConstants.GET_OVERALL_SCORE_BY_SURVEY_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_OVERALL_SCORE_BY_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        getOverallScoreBySurveyIdSuccess: true,
        getOverallScoreBySurveyIdData: action.dashboard.getOverallScoreBySurveyIdData.data


      };

    case dashboardConstants.GET_OVERALL_SCORE_BY_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_ALL_MANAGER_SCORES_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_ALL_MANAGER_SCORES_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllManagerScoresSuccess: true,
        getAllManagerScoresData: action.dashboard.getAllManagerScoresData

      };

    case dashboardConstants.GET_ALL_MANAGER_SCORES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.CREATE_GOAL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.CREATE_GOAL_SUCCESS:
      return {
        ...state,
        loading: false,
        createGoalSuccess: true,
        createGoalData: action.dashboard.createGoalData

      };

    case dashboardConstants.CREATE_GOAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_GOAL_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_GOAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getGoalListSuccess: true,
        getGoalListData: action.dashboard.getGoalListData.list,
        getGoalListTotal: action.dashboard.getGoalListData.total


      };

    case dashboardConstants.GET_GOAL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.UPDATE_GOAL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.UPDATE_GOAL_SUCCESS:
      return {
        ...state,
        loading: false,
        updateGoalSuccess: true,
        updateGoalData: action.dashboard.updateGoalData

      };

    case dashboardConstants.UPDATE_GOAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.DELETE_GOAL_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.DELETE_GOAL_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteGoalSuccess: true,
        deleteGoalData: action.dashboard.deleteGoalData

      };

    case dashboardConstants.DELETE_GOAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.CREATE_PLAN_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.CREATE_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        createPlanSuccess: true,
        createPlanData: action.dashboard.createPlanData

      };

    case dashboardConstants.CREATE_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.UPDATE_PLAN_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        updatePlanSuccess: true,
        updatePlanData: action.dashboard.updatePlanData

      };

    case dashboardConstants.UPDATE_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        errorUpdateMsg: action.error

      };


    case dashboardConstants.DELETE_PLAN_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.DELETE_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        deletePlanSuccess: true,
        deletePlanData: action.dashboard.deletePlanData

      };

    case dashboardConstants.DELETE_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.START_PLAN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.START_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        closePlanSuccess: true,
        closePlanData: action.dashboard.closePlanData

      };

    case dashboardConstants.START_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.CLOSE_PLAN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.CLOSE_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        closePlanSuccess: true,
        closePlanData: action.dashboard.closePlanData

      };

    case dashboardConstants.CLOSE_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_ALL_MANAGER_GOAL_PLAN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ALL_MANAGER_GOAL_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllManagerGoalPlanSuccess: true,
        getAllManagerGoalPlanData: action.dashboard.getAllManagerGoalPlanData.list,
        getAllManagerGoalPlanTotal: action.dashboard.getAllManagerGoalPlanTotal

      };

    case dashboardConstants.GET_ALL_MANAGER_GOAL_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_IDP_LAST_DATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_IDP_LAST_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        getIDPLastDateSuccess: true,
        getIDPLastDateData: action.dashboard.getIDPLastDateData

      };
    case dashboardConstants.GET_IDP_LAST_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.EDIT_IDP_LAST_DATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.EDIT_IDP_LAST_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        editIDPLastDateSuccess: true,
        editIDPLastDateData: action.dashboard.editIDPLastDateData

      };
    case dashboardConstants.EDIT_IDP_LAST_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_COMPANY_NAME_ONLY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_COMPANY_NAME_ONLY_SUCCESS:
      return {
        ...state,
        loading: false,
        getCompanyNameOnlySuccess: true,
        getCompanyNameOnlyData: action.dashboard.getCompanyNameOnlyData

      };
    case dashboardConstants.GET_COMPANY_NAME_ONLY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_MANAGER_DOWNLOAD_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_DOWNLOAD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        managerDownloadStatusSuccess: true,

      };
    case dashboardConstants.GET_MANAGER_DOWNLOAD_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_ALL_MANAGER_DOWNLOAD_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ALL_MANAGER_DOWNLOAD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        getMngrReportDownloadStatusSuccess: true,
        getMngrReportDownloadStatusData: action.dashboard.getMngrReportDownloadStatusData.list,
        getMngrReportDownloadStatusTotal: action.dashboard.getMngrReportDownloadStatusData.total

      };
    case dashboardConstants.GET_ALL_MANAGER_DOWNLOAD_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_INDEX_BY_FILTER_ALL_STAGES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_INDEX_BY_FILTER_ALL_STAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndexByFilterAllStagesSuccess: true,
        getIndexByFilterAllStagesData: action.dashboard.getIndexByFilterAllStagesData

      };
    case dashboardConstants.GET_INDEX_BY_FILTER_ALL_STAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_QUESTION_MAP_REQUEST:
      return {
        ...state,
        loading: true
      };

    case dashboardConstants.GET_QUESTION_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionMapSuccess: true,
        getQuestionMapError: "",
        getQuestionMapData: action.dashboard.getQuestionMapData
      };

    case dashboardConstants.GET_QUESTION_MAP_FAILURE:
      return {
        ...state,
        loading: false,
        getQuestionMapError: action.error,
        error: action.error
      };



    case dashboardConstants.GET_QUESTION_CUSTOM_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_CUSTOM_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionCustomTemplateSuccess: true,
        getQuestionCustomTemplateData: action.dashboard.getQuestionCustomTemplateData

      };
    case dashboardConstants.GET_QUESTION_CUSTOM_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_CAT_OVERALL_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_CAT_OVERALL_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        getCategoryOverallIndexSuccess: true,
        getCategoryOverallIndexData: action.dashboard.getCategoryOverallIndexData

      };
    case dashboardConstants.GET_CAT_OVERALL_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_LIFECYCLE_LISTEN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_LIFECYCLE_LISTEN_SUCCESS:
      return {
        ...state,
        loading: false,
        getLifecycleListenByIdSuccess: true,
        getLifecycleListenByIdData: action.dashboard.getLifecycleListenByIdData

      };
    case dashboardConstants.GET_LIFECYCLE_LISTEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_360_DIMEN_RELATION_SCORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_360_DIMEN_RELATION_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        get360DimensionRelationScoreSuccess: true,
        get360DimensionRelationScoreData: action.dashboard.get360DimensionRelationScoreData

      };
    case dashboardConstants.GET_360_DIMEN_RELATION_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_360_DIMEN_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_360_DIMEN_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        get360DimensionQuestionsSuccess: true,
        get360DimensionQuestionsData: action.dashboard.get360DimensionQuestionsData

      };
    case dashboardConstants.GET_360_DIMEN_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_ANSWERED_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ANSWERED_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        getAnsweredEmployeesSuccess: true,
        getAnsweredEmployeesData: action.dashboard.getAnsweredEmployeesData

      };
    case dashboardConstants.GET_ANSWERED_EMPLOYEES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_QUES_MNGR_MANAGERPID_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUES_MNGR_MANAGERPID_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionManagerPIDWiseSuccess: true,
        getQuestionManagerPIDWiseData: action.dashboard.getQuestionManagerPIDWiseData

      };
    case dashboardConstants.GET_QUES_MNGR_MANAGERPID_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_INDEX_MNGR_MANAGERPID_SCORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_INDEX_MNGR_MANAGERPID_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        getAnsweredEmployeesSuccess: true,
        getIndexScoreManagerPIDWiseData: action.dashboard.getIndexScoreManagerPIDWiseData

      };
    case dashboardConstants.GET_INDEX_MNGR_MANAGERPID_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_HAPPINESS_INDEX_MNGR_WISE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_HAPPINESS_INDEX_MNGR_WISE_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndexDriverManagerPIDWiseSuccess: true,
        getIndexDriverManagerPIDWiseData: action.dashboard.getHappinessIndexDriverManagerPIDWiseData

      };
    case dashboardConstants.GET_HAPPINESS_INDEX_MNGR_WISE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_ENGAGEMENT_INDEX_MNGR_WISE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_MNGR_WISE_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndexDriverManagerPIDWiseSuccess: true,
        getIndexDriverManagerPIDWiseData: action.dashboard.getEngagementIndexDriverManagerPIDWiseData

      };
    case dashboardConstants.GET_ENGAGEMENT_INDEX_MNGR_WISE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_STRESS_INDEX_MNGR_WISE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_STRESS_INDEX_MNGR_WISE_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndexDriverManagerPIDWiseSuccess: true,
        getIndexDriverManagerPIDWiseData: action.dashboard.getStressIndexDriverManagerPIDWiseData

      };
    case dashboardConstants.GET_STRESS_INDEX_MNGR_WISE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_MANAGER_INDEX_MNGR_WISE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_INDEX_MNGR_WISE_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndexDriverManagerPIDWiseSuccess: true,
        getIndexDriverManagerPIDWiseData: action.dashboard.getManagerIndexDriverManagerPIDWiseData

      };
    case dashboardConstants.GET_MANAGER_INDEX_MNGR_WISE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_ORG_CORE_DRIVER_SENTIMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ORG_CORE_DRIVER_SENTIMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        getOrgCoreDriverSentimentSuccess: true,
        getOrgCoreDriverSentimentData: action.dashboard.getOrgCoreDriverSentimentData

      };
    case dashboardConstants.GET_ORG_CORE_DRIVER_SENTIMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.ANSWER_FEEDBACK_TEXT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.ANSWER_FEEDBACK_TEXT_SUCCESS:
      return {
        ...state,
        loading: false,
        AnswerFeedbackTextSuccess: true,
        AnswerFeedbackTextData: action.dashboard.AnswerFeedbackTextData,
        AnswerFeedbackTextKeyWord: action.dashboard.AnswerFeedbackTextKeyWord

      };
    case dashboardConstants.ANSWER_FEEDBACK_TEXT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_DEMOGRAPHIC_MULTI_AXIS_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_MULTI_AXIS_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicIndexMultiAxisFilterSuccess: true,
        getDemographicIndexMultiAxisFilterData: action.dashboard.getDemographicIndexMultiAxisFilterData

      };
    case dashboardConstants.GET_DEMOGRAPHIC_MULTI_AXIS_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_360_RELATION_RESPONSE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_360_RELATION_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        get360RelationResponseSuccess: true,
        get360RelationResponseData: action.dashboard.get360RelationResponseData

      };
    case dashboardConstants.GET_360_RELATION_RESPONSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_RESPONDANT_ALL_LIFECYCLE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_RESPONDANT_ALL_LIFECYCLE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        getRespondantAllLifecycleDetailsSuccess: true,
        getRespondantAllLifecycleDetailsData: action.dashboard.getRespondantAllLifecycleDetailsData

      };
    case dashboardConstants.GET_RESPONDANT_ALL_LIFECYCLE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_USER_ACCESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_USER_ACCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        getUserAccessSuccess: true,
        getUserAccessData: action.dashboard.getUserAccessData

      };
    case dashboardConstants.GET_USER_ACCESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_FEEDBACK_AND_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_FEEDBACK_AND_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        getFeedbackAndQuestionNameSuccess: true,
        getFeedbackAndQuestionNameData: action.dashboard.getFeedbackAndQuestionNameData

      };
    case dashboardConstants.GET_FEEDBACK_AND_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_TEXT_AND_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_TEXT_AND_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        getTextAndQuestionNameSuccess: true,
        getTextAndQuestionNameData: action.dashboard.getTextAndQuestionNameData

      };
    case dashboardConstants.GET_TEXT_AND_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_PARAMETER_LIBARARY_LABEL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_PARAMETER_LIBARARY_LABEL_SUCCESS:
      return {
        ...state,
        loading: false,
        getParameterLibraryLabelByParentNameSuccess: true,
        getParameterLibraryLabelByParentNameData: action.dashboard.getParameterLibraryLabelByParentNameData

      };
    case dashboardConstants.GET_PARAMETER_LIBARARY_LABEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_INDEX_TREND_CHART_BY_MONTH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_INDEX_TREND_CHART_BY_MONTH_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndexTrendChartByMonthSuccess: true,
        getIndexTrendChartByMonthData: action.dashboard.getIndexTrendChartByMonthData

      };
    case dashboardConstants.GET_INDEX_TREND_CHART_BY_MONTH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_QUESTION_TREND_CHART_BY_MONTH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_TREND_CHART_BY_MONTH_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionTrendChartByMonthSuccess: true,
        getQuestionTrendChartByMonthData: action.dashboard.getQuestionTrendChartByMonthData

      };
    case dashboardConstants.GET_QUESTION_TREND_CHART_BY_MONTH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        getQuestionTrendChartByMonthError: action.error
      };




    case dashboardConstants.GET_BENCHMARK_QUANTILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_BENCHMARK_QUANTILE_SUCCESS:
      return {
        ...state,
        loading: false,
        getBenchmarkQuantileSuccess: true,
        getBenchmarkQuantileData: action.dashboard.getBenchmarkQuantileData

      };
    case dashboardConstants.GET_BENCHMARK_QUANTILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        getBenchmarkQuantileError: action.error
      };




    case dashboardConstants.GET_OVERALL_LIFECYCLE_PTM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_OVERALL_LIFECYCLE_PTM_SUCCESS:
      return {
        ...state,
        loading: false,
        getLifecycleOverallPTMSuccess: true,
        getLifecycleOverallPTMData: action.dashboard.getLifecycleOverallPTMData

      };
    case dashboardConstants.GET_OVERALL_LIFECYCLE_PTM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        getLifecycleOverallPTMError: action.error
      };



    case dashboardConstants.GET_TEXT_CLUSTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_TEXT_CLUSTER_SUCCESS:
      return {
        ...state,
        loading: false,
        getTextClusterSuccess: true,
        getTextClusterData: action.dashboard.getTextClusterData

      };
    case dashboardConstants.GET_TEXT_CLUSTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        getTextClusterError: action.error
      };




    case dashboardConstants.GET_TEXT_CLUSTER_SENTIMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_TEXT_CLUSTER_SENTIMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        getTextClusterSentimentCountSuccess: true,
        getTextClusterSentimentCountData: action.dashboard.getTextClusterSentimentCountData

      };
    case dashboardConstants.GET_TEXT_CLUSTER_SENTIMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        getTextClusterSentimentCountError: action.error
      };




    case dashboardConstants.GET_TEXT_CLUSTER_KEYWORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_TEXT_CLUSTER_KEYWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        getClusterKeywordsSuccess: true,
        getClusterKeywordsData: action.dashboard.getClusterKeywordsData

      };
    case dashboardConstants.GET_TEXT_CLUSTER_KEYWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        getClusterKeywordsError: action.error
      };



    case dashboardConstants.GET_CLUSTER_SENTENCES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_CLUSTER_SENTENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        getClusterSentencesSuccess: true,
        getClusterSentencesData: action.dashboard.getClusterSentencesData,
        getClusterSaveKeyWord: action.dashboard.getClusterSaveKeyWord

      };
    case dashboardConstants.GET_CLUSTER_SENTENCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        getClusterSentencesError: action.error
      };




    case dashboardConstants.DRIVER_VS_NPS_RWA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.DRIVER_VS_NPS_RWA_SUCCESS:
      return {
        ...state,
        loading: false,
        driverVsNpsRWASuccess: true,
        driverVsNpsRWAData: action.dashboard.driverVsNpsRWAData,
        getClusterSaveKeyWord: action.dashboard.getClusterSaveKeyWord

      };
    case dashboardConstants.DRIVER_VS_NPS_RWA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        driverVsNpsRWAError: action.error
      };


    case dashboardConstants.GET_ASSESSMENT_REPORT_EMP_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ASSESSMENT_REPORT_EMP_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentReportByEmpIDSuccess: true,
        getAssessmentReportByEmpIDData: action.dashboard.getAssessmentReportByEmpIDData

      };
    case dashboardConstants.GET_ASSESSMENT_REPORT_EMP_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        driverVsNpsRWAError: action.error
      };


    case dashboardConstants.GET_TYPE2_ASSESSMENT_REPORT_EMP_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_TYPE2_ASSESSMENT_REPORT_EMP_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getType2AssessmentReportByEmpIDSuccess: true,
        getType2AssessmentReportByEmpIDData: action.dashboard.getType2AssessmentReportByEmpIDData

      };
    case dashboardConstants.GET_TYPE2_ASSESSMENT_REPORT_EMP_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        driverVsNpsRWAError: action.error
      };





    case dashboardConstants.GET_ASSESSMENT_REPORT_EMP_ONLY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ASSESSMENT_REPORT_EMP_ONLY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentReportByEmpIDSuccess: true,
        getAssessmentReportByEmpIDOnlyData: action.dashboard.getAssessmentReportByEmpIDOnlyData

      };
    case dashboardConstants.GET_ASSESSMENT_REPORT_EMP_ONLY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        driverVsNpsRWAError: action.error
      };



    case dashboardConstants.GET_TEMP_360_EMP_STRENGTH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_TEMP_360_EMP_STRENGTH_SUCCESS:
      return {
        ...state,
        loading: false,
        getTemp360EmpStrengthSuccess: true,
        getTemp360EmpStrengthData: action.dashboard.getTemp360EmpStrengthData

      };
    case dashboardConstants.GET_TEMP_360_EMP_STRENGTH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        driverVsNpsRWAError: action.error
      };




    case dashboardConstants.GET_ASSESSMENT_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ASSESSMENT_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentDashboardSuccess: true,
        getAssessmentDashboardData: action.dashboard.getAssessmentDashboardData

      };
    case dashboardConstants.GET_ASSESSMENT_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        driverVsNpsRWAError: action.error
      };



    case dashboardConstants.GET_ASSESSMENT_THEME_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ASSESSMENT_THEME_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentByThemeSuccess: true,
        getAssessmentByThemeData: action.dashboard.getAssessmentByThemeData

      };
    case dashboardConstants.GET_ASSESSMENT_THEME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        driverVsNpsRWAError: action.error
      };




    case dashboardConstants.GET_EXIT_QUESTION_ALL_COUNT_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_EXIT_QUESTION_ALL_COUNT_DATA_SUCCESS:
      return {
        ...state,
        getClientProfileSuccess: true,
        loading: false,
        getExitQuestionAllCountData: action.dashboard.getExitQuestionAllCountData,
      };
    case dashboardConstants.GET_EXIT_QUESTION_ALL_COUNT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_EXIT_QUESTION_AVERAGE_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_EXIT_QUESTION_AVERAGE_DATA_SUCCESS:
      return {
        ...state,
        getClientProfileSuccess: true,
        loading: false,
        getExitQuestionAvgData: action.dashboard.getExitQuestionAvgData,
      };
    case dashboardConstants.GET_EXIT_QUESTION_AVERAGE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_EXIT_QUESTION_YES_NO_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_EXIT_QUESTION_YES_NO_DATA_SUCCESS:
      return {
        ...state,
        getClientProfileSuccess: true,
        loading: false,
        getExitQuestionYesNoData: action.dashboard.getExitQuestionYesNoData,
      };
    case dashboardConstants.GET_EXIT_QUESTION_YES_NO_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_EXIT_QUESTION_ONE_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_EXIT_QUESTION_ONE_DATA_SUCCESS:
      return {
        ...state,
        getClientProfileSuccess: true,
        loading: false,
        getExitQuestionOne: action.dashboard.getExitQuestionOne,
      };
    case dashboardConstants.GET_EXIT_QUESTION_ONE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_EXIT_QUES_ALL_DMG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_EXIT_QUES_ALL_DMG_SUCCESS:
      return {
        ...state,
        loading: false,
        getExitQuestionAllDemographicsSuccess: true,
        getExitQuestionAllDemographicsData: action.dashboard.getExitQuestionAllDemographicsData,
      };
    case dashboardConstants.GET_EXIT_QUES_ALL_DMG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case dashboardConstants.GET_ROL_KEYWORD_EMP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ROL_KEYWORD_EMP_SUCCESS:
      return {
        ...state,
        loading: false,
        rolkeywordbyemployeeSuccess: true,
        rolkeywordbyemployeeData: action.dashboard.rolkeywordbyemployeeData,
      };
    case dashboardConstants.GET_ROL_KEYWORD_EMP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_ASSESSMENT_DOWNLOAD_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ASSESSMENT_DOWNLOAD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentDownloadStatusSuccess: true,
        getAssessmentDownloadStatusData: action.dashboard.getAssessmentDownloadStatusData,
      };
    case dashboardConstants.GET_ASSESSMENT_DOWNLOAD_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_ASSESSMENT_DIMENSION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ASSESSMENT_DIMENSION_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentDimensionsSuccess: true,
        getAssessmentDimensionsData: action.dashboard.getAssessmentDimensionsData,
      };
    case dashboardConstants.GET_ASSESSMENT_DIMENSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_ASSESSMENT_DIMENSION_V2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ASSESSMENT_DIMENSION_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentDimensionsV2Success: true,
        getAssessmentDimensionsV2Data: action.dashboard.getAssessmentDimensionsData,
      };
    case dashboardConstants.GET_ASSESSMENT_DIMENSION_V2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case dashboardConstants.GET_ASSESSMENT_MANAGER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ASSESSMENT_MANAGER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentManagerListSuccess: true,
        getAssessmentManagerListData: action.dashboard.getAssessmentManagerListData,
      };
    case dashboardConstants.GET_ASSESSMENT_MANAGER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_ASSESSMENT_MANAGER_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ASSESSMENT_MANAGER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentManagerReportSuccess: true,
        getAssessmentManagerReportData: action.dashboard.getAssessmentManagerReportData,
      };
    case dashboardConstants.GET_ASSESSMENT_MANAGER_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_ASSESSMENT_ONLY_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ASSESSMENT_ONLY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentOnlyDetailsSuccess: true,
        getAssessmentOnlyDetailsData: action.dashboard.getAssessmentOnlyDetailsData,
      };
    case dashboardConstants.GET_ASSESSMENT_ONLY_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_ASSESSMENT_EMP_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_ASSESSMENT_EMP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAssessmentEmpListByMngrSuccess: true,
        getAssessmentEmpListByMngrData: action.dashboard.getAssessmentEmpListByMngrData,
      };
    case dashboardConstants.GET_ASSESSMENT_EMP_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_REPORT_TYPE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_REPORT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        getReportTypeSuccess: true,
        getReportTypeData: action.dashboard.getReportTypeData
      };
    case dashboardConstants.GET_REPORT_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_REPORT_INDEX_YEAR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_REPORT_INDEX_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        getReportIndexYearCheckSuccess: true,
        getReportIndexYearCheckData: action.dashboard.getReportIndexYearCheckData
      };
    case dashboardConstants.GET_REPORT_INDEX_YEAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_DMG_WISE_RANK_SCORE_INDEX_TOP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DMG_WISE_RANK_SCORE_INDEX_TOP_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicWiseRankedScoreIndexTopSuccess: true,
        getDemographicWiseRankedScoreIndexTopData: action.dashboard.getDemographicWiseRankedScoreData
      };
    case dashboardConstants.GET_DMG_WISE_RANK_SCORE_INDEX_TOP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_DMG_WISE_RANK_SCORE_INDEX_BOTTOM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DMG_WISE_RANK_SCORE_INDEX_BOTTOM_SUCCESS:
      return {
        ...state,
        loading: false,
        getDemographicWiseRankedScoreIndexBottomSuccess: true,
        getDemographicWiseRankedScoreIndexBottomData: action.dashboard.getDemographicWiseRankedScoreData
      };
    case dashboardConstants.GET_DMG_WISE_RANK_SCORE_INDEX_BOTTOM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_INDEX_OVERALL_SCORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_INDEX_OVERALL_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndexOverallScoreSuccess: true,
        getIndexOverallScoreData: action.dashboard.getIndexOverallDistributionData
      };
    case dashboardConstants.GET_INDEX_OVERALL_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case dashboardConstants.GET_INDEX_OVERALL_DISTRIBUTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_INDEX_OVERALL_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndexOverallDistributionSuccess: true,
        getIndexOverallDistributionData: action.dashboard.getIndexOverallDistributionData
      };
    case dashboardConstants.GET_INDEX_OVERALL_DISTRIBUTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case dashboardConstants.GET_SURVEY_TAKEN_DATE_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_SURVEY_TAKEN_DATE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        getSurveyTakenDateAdminSuccess: true,
        getSurveyTakenDateAdminData: action.dashboard.getSurveyTakenDateAdminData
      };
    case dashboardConstants.GET_SURVEY_TAKEN_DATE_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.PMS_DMG_EMPLOYEE_LIST_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.PMS_DMG_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        // loading: false
        PMSDMGEmployeeList: action.dashboard.PMSDMGEmployeeList.list,
        PMSDMGEmployeeTotal: action.dashboard.PMSDMGEmployeeList.total
      };
    case dashboardConstants.PMS_DMG_EMPLOYEE_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };







    case dashboardConstants.PMS_GET_PROCESS_LIST_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.PMS_GET_PROCESS_LIST_SUCCESS:
      return {
        ...state,
        // loading: false
        PMSGetProcessDataList: action.dashboard.PMSGetProcessDataList
      };
    case dashboardConstants.PMS_GET_PROCESS_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };



    case dashboardConstants.PMS_ADD_PROCESS_LIST_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.PMS_ADD_PROCESS_LIST_SUCCESS:
      return {
        ...state,
        // loading: false
        PMSAddProcessList: action.dashboard.PMSAddProcessList
      };
    case dashboardConstants.PMS_ADD_PROCESS_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };







    case dashboardConstants.ADD_TEXT_KEYWORD_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.ADD_TEXT_KEYWORD_SUCCESS:
      return {
        ...state,
        // loading: false
        addTextKeywordsData: action.dashboard.addTextKeywordsData,
      };
    case dashboardConstants.ADD_TEXT_KEYWORD_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case dashboardConstants.GET_TEXT_KEYWORD_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case dashboardConstants.GET_TEXT_KEYWORD_SUCCESS:
      return {
        ...state,
        // loading: false
        getTextKeywordsData: action.dashboard.getTextKeywordsData,
      };
    case dashboardConstants.GET_TEXT_KEYWORD_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };



    case dashboardConstants.GET_TEXT_DIST_HIGHLIGHTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_TEXT_DIST_HIGHLIGHTS_SUCCESS:
      return {
        ...state,
        loading: false,
        getTextDistHighlightData: action.dashboard.getTextDistHighlightData,
      };
    case dashboardConstants.GET_TEXT_DIST_HIGHLIGHTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case dashboardConstants.GET_TEXT_DIST_HIGHLIGHTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_TEXT_DIST_HIGHLIGHTS_SUCCESS:
      return {
        ...state,
        loading: false,
        getTextDistHighlightData: action.dashboard.getTextDistHighlightData,
      };
    case dashboardConstants.GET_TEXT_DIST_HIGHLIGHTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };




    case dashboardConstants.All_ASSESSMENT_EMP_DATA_REQUEST:
      return {
        ...state,
        loadingAllAssessment: true
      };
    case dashboardConstants.All_ASSESSMENT_EMP_DATA_SUCCESS:
      return {
        ...state,
        loadingAllAssessment: false,
        getAllAnsweredAssessmentEmpDataList: action.dashboard.getAllAnsweredAssessmentEmpDataList,
      };
    case dashboardConstants.All_ASSESSMENT_EMP_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingAllAssessment: false
      };



    case dashboardConstants.GET_NUDGE_LIB_LIST_REQUEST:
      return {
        ...state,
        loadingAllAssessment: true
      };
    case dashboardConstants.GET_NUDGE_LIB_LIST_SUCCESS:
      return {
        ...state,
        loadingAllAssessment: false,
        getNudgeLibListData: action.dashboard.getNudgeLibListData,
      };
    case dashboardConstants.GET_NUDGE_LIB_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingAllAssessment: false
      };


    case dashboardConstants.MAP_NUDGE_TO_QUESTION_REQUEST:
      return {
        ...state,
        loadingAllAssessment: true
      };
    case dashboardConstants.MAP_NUDGE_TO_QUESTION_SUCCESS:
      return {
        ...state,
        loadingAllAssessment: false,
        mapNudgeToQuestionData: action.dashboard.mapNudgeToQuestionData,
      };
    case dashboardConstants.MAP_NUDGE_TO_QUESTION_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingAllAssessment: false
      };



    case dashboardConstants.GET_NUDGE_TEXT_QUES_LIST_REQUEST:
      return {
        ...state,
        loadingAllAssessment: true
      };
    case dashboardConstants.GET_NUDGE_TEXT_QUES_LIST_SUCCESS:
      return {
        ...state,
        loadingAllAssessment: false,
        getNudgeTextQuesList: action.dashboard.getNudgeTextQuesList,
      };
    case dashboardConstants.GET_NUDGE_TEXT_QUES_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingAllAssessment: false
      };



    case dashboardConstants.GET_NUDGE_TEXT_SURVEYID_REQUEST:
      return {
        ...state,
        loadingAllAssessment: true
      };
    case dashboardConstants.GET_NUDGE_TEXT_SURVEYID_SUCCESS:
      return {
        ...state,
        loadingAllAssessment: false,
        getNudgeTextBySurveyIdData: action.dashboard.getNudgeTextBySurveyIdData
      };
    case dashboardConstants.GET_NUDGE_TEXT_SURVEYID_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingAllAssessment: false
      };




    case dashboardConstants.GET_QUESTION_WISE_EXCEL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_WISE_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false,
        getQuestionWiseExcelSuccess: true,
        getQuestionWiseExcelData: action.dashboard.getQuestionWiseExcelData,
      };
    case dashboardConstants.GET_QUESTION_WISE_EXCEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case dashboardConstants.GET_REPORT_QUES_WISE_WORLD_CLOUD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_REPORT_QUES_WISE_WORLD_CLOUD_SUCCESS:
      return {
        ...state,
        loading: false,
        getReportQuesWiseWorldCloudSuccess: true,
        getReportQuesWiseWorldCloudData: action.dashboard.getReportQuesWiseWorldCloudData,
      };
    case dashboardConstants.GET_REPORT_QUES_WISE_WORLD_CLOUD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    case dashboardConstants.GET_MANAGER_TEAM_SIZE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_MANAGER_TEAM_SIZE_SUCCESS:
      return {
        ...state,
        loading: false,
        getMngrTeamSizeSuccess: true,
        getMngrTeamSizeData: action.dashboard.getMngrTeamSizeData,
      };
    case dashboardConstants.GET_MANAGER_TEAM_SIZE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };






    default:
      return state
  }
}