import React, { Component } from 'react';
import { connect } from 'react-redux';
// import ReactEcharts from "echarts-for-react";
// import { createRoot } from "react-dom/client";
import Highlighter from "react-highlight-words";
import LoadingOverlay from 'react-loading-overlay';

class WordChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedText: '',
      currentItem: 0,
      showDropdown: false
    }
  }



  componentDidMount() {
  }


  handleKeyword = (data) => {
    let { handleKeywordCall } = this.props;
    handleKeywordCall(data.text)
    this.setState({ SelectedText: data.text + ' (' + data.value + ')' });

  }

  handleKeywordTooltip = (data) => {
    this.setState({ SelectedText: data.text + ' (' + data.value + ')' });
  }


  handleSenetences = (item) => {
    this.setState({ currentItem: item });
  }


  handleOpenFilter = (check) => {
    this.setState({ showFilter: check });
  }

  render() {

    let { countList, crrSentenceList, dmgStateValue, loading, xaXis, getTextDistHighlightData } = this.props;
    // let { SelectedText , currentItem, showFilter}= this.state;

    console.log('MODAL_countList::', countList);
    console.log('MODAL_crrSentenceList::', crrSentenceList);

    let DriverName = [];
    let DriverValues = [];
    let DriverSentences = [];

    if (countList && countList.length > 0) {
      countList.forEach((item) => {
        DriverName.push(item["value"]);
        DriverValues.push(item["count"]);
        DriverSentences.push(item["sentences"]);
      });
    }

    // let XAxis = DriverName;
    // let List = DriverValues;
    let list = []
    if (crrSentenceList && crrSentenceList.length > 0) {
      crrSentenceList.forEach((item, index) => {
        if (index < 15) {
          list.push(item)
        }
      })
    }





    return (
      <>



        {getTextDistHighlightData && getTextDistHighlightData.length > 0 ?

          <div className="w-full bg-white border rounded-lg hover:shadow-lg">

            <div className="flex items-center  md:p-6 p-4  space-x-2 border-b ">
              <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] ">Top 15 texts by length</h1>
              <h1 className="font-medium xl:text-base text-sm text-[#3D405B]  ">
                {dmgStateValue && xaXis ?
                  <>{' ('}<b className='text-[#2196F3] font-medium'>{xaXis}</b>{': ' + dmgStateValue + ')'}
                  </> : null}
              </h1>
            </div>

            {/* <LoadingOverlay
              active={loading}
              spinner
              text='Loading Data...'
              className="loader"
            > */}
            <div className="px-4 pb-4 overflow-y-auto h-[510px]" id="chatbot"  >
              {getTextDistHighlightData && getTextDistHighlightData.length > 0 && getTextDistHighlightData.length < 5 ?
                <>
                  <div className='my-24 text-2xl font-medium text-center text-gray-400'>{"We are not showing data where employee count is less than 5"}</div>
                </>
                :
                <>
                  {getTextDistHighlightData.map((item, index) =>
                    (item && item[0] && item[0].trim()) ?
                      <div className="flex items-start space-x-4 capitalize">
                        <div className="space-y-2">
                          <p className="py-3 text-sm leading-8 ">{(index + 1).toString() + ". "}
                            <Highlighter
                              highlightClassName={"bg-gradient-to-r from-cyan-500 to-blue-500 p-2 py-1 text-white rounded-md "}
                              activeClassName="bg-gradient-to-r from-cyan-500 to-blue-500"
                              activeIndex={1}
                              searchWords={item[2]}
                              autoEscape={true}
                              textToHighlight={item[0]}
                            />
                          </p>


                          <div className='flex pb-2 gap-2 flex-wrap'>
                            {item && item[1] && item[1].length > 0 ?
                              item[1].map((ele) =>
                                <button className="w-fit border 
                                border-cyan-500 text-cyan-500
                                flex-shrink-0 uppercase cursor-pointer text-sm py-1.5 px-2 font-medium rounded-md">
                                  {ele}
                                </button>
                              ) : null}
                          </div>
                        </div>
                      </div>
                      : null)}
                </>
              }
            </div>
            {/* </LoadingOverlay> */}
          </div>

          :
          <div className="flex items-center justify-center w-full p-4 bg-white border rounded-lg hover:shadow-lg">
            <p className='font-medium xl:text-2xl text-xl text-[#3D405B] whitespace-nowrap'>Click On Left Bar in Chart</p>
          </div>
        }






      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const {users, dashboard} = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(WordChart);
