import React from "react";

export default function Dashboard(props) {
  let { 
    getQuestionOverallData,
    getQuestionManagerData,
    getDMGQuestionData,
    ManagerDetails,
    SectionName,
    IndexDimensionList,
    GetParameterLabel,
    getDemographicIndexData,
    CurrentManagerData,
    getSurveyData
    // QuestionList,SectionName,EmpFilterData, avoidList 
  
  } = props;


  ////console.log("------>getQuestionOverallData---------->",getQuestionOverallData)
  ////console.log("------>getQuestionManagerData---------->",getQuestionManagerData)
  ////console.log("------>IndexDimensionList---------->",IndexDimensionList)



  function CalculateScore(ques){
    let total = ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];
    let sum = ques["5"] + ques["6"];
    let score = (sum*100)/total;
    return score && score.toFixed(2)?score.toFixed(2):0
  }

  let NewQuestionList = [];
  let OverallQuestionObj = {};
  let ManagerQuestionObj = {};
  let DMGQuestionObj = {};

  if(IndexDimensionList && IndexDimensionList.length>0){
    IndexDimensionList.forEach((para)=>{
      if(getQuestionOverallData && getQuestionOverallData.length>0){
        getQuestionOverallData.forEach((ques)=>{
          if(para === ques.parameterDimensionName){

            let getIndex = NewQuestionList.findIndex(prev=>prev.para===ques.parameterDimensionName);
            if(getIndex===-1){
              NewQuestionList.push({
                "name":ques.name,
                "para":ques.parameterDimensionName,
                "showPara":true,
                "countPara":1
              });
            }
            else{
              NewQuestionList.push({
                "name":ques.name,
                "para":ques.parameterDimensionName,
                "showPara":false,
                "countPara":0
              });
              NewQuestionList[getIndex]["countPara"] += 1;
            }


            OverallQuestionObj[ques.name] = CalculateScore(ques);

          }
      });
    }
    });
  }  


  // if(getQuestionOverallData && getQuestionOverallData.length>0){
  //   getQuestionOverallData.forEach((ques)=>{
  //     OverallQuestionObj[ques.name] = CalculateScore(ques);
  //   });
  // }  
  if(getQuestionManagerData && getQuestionManagerData.length>0){
    getQuestionManagerData.forEach((ques)=>{
      ManagerQuestionObj[ques.name] = CalculateScore(ques);
    });
  }  
  if(getDMGQuestionData && getDMGQuestionData.length>0){
    getDMGQuestionData.forEach((ques)=>{
      DMGQuestionObj[ques.name] = CalculateScore(ques);
    });
  }  





  let ValueObj = {}


if(IndexDimensionList && IndexDimensionList.length>0){
  IndexDimensionList.forEach((item)=>{
    ValueObj[item+"Avg"] = 0
  });
}


let managerId = CurrentManagerData && CurrentManagerData.emp_id?CurrentManagerData.emp_id:"";

//---------------------------
let ManagerIDValue = managerId.toString();
//---------------------------

let index = getDemographicIndexData?getDemographicIndexData.findIndex(prev=>prev.name.toString() === ManagerIDValue):-1;

let DataItem = {}

if(index!==-1){
  DataItem = getDemographicIndexData[index];
}

////console.log("-------------> DataItem--->",DataItem)

if(IndexDimensionList && IndexDimensionList.length>0){
  IndexDimensionList.forEach((item)=>{

    ValueObj[item+"Avg"] = DataItem && DataItem[item+"Avg"] && DataItem[item+"Avg"].toFixed(2)? DataItem[item+"Avg"].toFixed(2):0;


  });
}






  function getScore(ques,type){
    let score = 0;
    if(type==="overall"){
      score = OverallQuestionObj && OverallQuestionObj[ques.name]?OverallQuestionObj[ques.name]:"0"
    }
    else if(type==="manager"){
      score = ManagerQuestionObj && ManagerQuestionObj[ques.name]?ManagerQuestionObj[ques.name]:"0"
    }
    else if(type==="dmg"){
      score = DMGQuestionObj && DMGQuestionObj[ques.name]?DMGQuestionObj[ques.name]:"0"
    }
    else if(type==="N_Manager"){
      let quesIndex = getQuestionManagerData && getQuestionManagerData.length>0? getQuestionManagerData.findIndex(prev=>prev.name === ques.name) :-1;
      ////console.log("quesIndex====>",quesIndex)
      if(quesIndex!==-1){
        let quesData = getQuestionManagerData[quesIndex];
        ////console.log("quesData====>",quesData)
        score = quesData && quesData["count"] && quesData["count"]>4?quesData["count"]:'-';
      }
    }
    else if(type==="para_Manager"){


      ////console.log("ques.para--->",ques.para)
      ////console.log("ValueObj--->",ValueObj)

      score = ValueObj[ques.para+"Avg"]




      // let sum = 0
      // let filterManagerQues = getQuestionManagerData && getQuestionManagerData.length>0? getQuestionManagerData.filter(x=>x.parameterDimensionName === ques.para):[];
      // let filterManagerLength = getQuestionManagerData && getQuestionManagerData.length>0? getQuestionManagerData.filter(x=>x.parameterDimensionName === ques.para).length:0;
      // if(filterManagerQues && filterManagerQues.length>0){
      //   filterManagerQues.forEach((ques)=>{
      //     sum+=parseFloat(CalculateScore(ques));
      //   });
      // }
      // let scoreValue = sum/filterManagerLength;
      // score = scoreValue && scoreValue.toFixed(2)?scoreValue.toFixed(2):0
      


    }
    return score
  }


  // let ManagerName = ManagerDetails && ManagerDetails.name?ManagerDetails.name:"";








  






  return (
    <>
      <section className=" space-y-2 gap-5 px-10 pt-12">
      <header className="bg-white ">
          <div className="flex justify-between capitalize w-12/12 mx-auto py-6 pt-3 space-y-1">
            
            
            <h1 className="text-xl text-white font-bold">
            {getSurveyData && getSurveyData.logo?
              <img alt="item" src={getSurveyData && getSurveyData.logo?getSurveyData.logo:""}  style={{height:'90px'}}/>
              :null}
            </h1>

            <h1 className="text-xl text-white font-bold">
              <img alt="item" src={"img/logo/happypluslogo.png"}  style={{height:'60px'}}/>
            </h1>

            
          </div>
        </header>
        
        <div className="">
          {/* <h4 className="text-left text-2xl font-semibold text-indigo-700">{"( Manager: "+ManagerName+" ) – Your team "+SectionName+" Behavior"}</h4> */}
          <h4 className="text-left text-3xl text-gray-700 font-normal pb-2 ">{"Your Team Score - "+SectionName+" Behavior "}</h4>
          <h2 className="text-left text-sm font-semibold text-blue-500 pb-3">{"*Based on Favorability Score"}</h2>
          {/* <h4 className="text-left text-3xl font-bold text-indigo-700">{SectionName+" Question"} </h4> */}
        </div>
        
        <table className="text-left border-collapse w-full ">
          <tbody>
            <tr className="bg-pink-50">
              <td className="text-center p-1 px-2 text-sm font-bold border border-gray-300 " style={{width:'120px'}}>Dimension</td>
              <td className="p-1 px-2 text-sm font-bold border border-gray-300 ">Question</td>
              <td className="p-1 px-1 text-sm font-bold border border-gray-300 text-center" style={{width:'40px'}}>N</td>
              <td className="p-1 px-1 text-sm font-bold border border-gray-300 text-center" style={{width:'50px'}}>Overall (%)</td>
              <td className="p-1 px-1 text-sm font-bold border border-gray-300 text-center" style={{width:'70px'}}>Team (%)</td>
              
              {getDMGQuestionData && getDMGQuestionData.length>0?
              <td className="p-1 px-1 text-sm font-bold border border-gray-300 text-center">Department (%)</td>
              :null}

{/* 
              {EmpFilterDataNew && EmpFilterDataNew.length>0?
                  EmpFilterDataNew.map((emp)=>
                  emp.name==="ManagerPID"?
                  <td className="text-center p-3 text-sm font-bold border border-gray-300 w-4/12">{emp.name==="ManagerPID"?"Manager":emp.label}</td>
                  :null
                  ):null}

              {EmpFilterDataNew && EmpFilterDataNew.length>0?
                  EmpFilterDataNew.map((emp)=>
                  emp.name!=="ManagerPID"?
                  <td className="text-center p-3 text-sm font-bold border border-gray-300 w-4/12">{emp.name==="ManagerPID"?"Manager":emp.label}</td>
                  :null
                  ):null} */}


            </tr>





            {NewQuestionList && NewQuestionList.length>0?
              NewQuestionList.map((item, index) => (
                <tr className="bg-white  text-sm">
                  {/* {tempdata.showPara?
                  <td className="border-b border p-3 text-center uppercase font-semibold bg-blue-50" rowSpan={tempdata.countPara}>{tempdata.parameter}</td>
                  :null} */}

                  {item.showPara?
                  <td className="p-1 px-2 font-bold  text-gray-800 border border-b border-white bg-blue-100 text-center" rowSpan={item.countPara} style={{width:'120px'}}>
                    <div>{GetParameterLabel(item.para)}</div>
                    <div>{" ("+getScore(item,"para_Manager")+"%) "}</div>
                  </td>
                  :null}

                  <td className="p-1 px-2 font-medium  text-gray-800 border border-b divide-y">{item.name}</td>
                  <td className="p-1 px-1 font-medium  text-gray-800 border border-b divide-y text-center" style={{width:'40px'}}>{getScore(item,"N_Manager")}</td>
                  <td className="p-1 px-1 font-medium  text-gray-800 border border-b divide-y text-center" style={{width:'50px'}}>{getScore(item,"overall")}</td>
                  <td className="p-1 px-1 font-medium  text-gray-800 border border-b divide-y text-center" style={{width:'70px'}}>{getScore(item,"manager")}</td>
                  {getDMGQuestionData && getDMGQuestionData.length>0?
                  <td className="p-1 px-1 font-medium  text-gray-800 border border-b divide-y text-center" style={{width:'90px'}}>{getScore(item,"dmg")}</td>
                  :null}

{/* 
                  {EmpFilterDataNew && EmpFilterDataNew.length>0?
                  EmpFilterDataNew.map((emp)=>
                  emp.name==="ManagerPID"?
                  <td className="font-medium  text-gray-800 text-center border border-b divide-y">{tempdata.demographic[emp.name]}</td>
                  :null
                  ):null}

                  {EmpFilterDataNew && EmpFilterDataNew.length>0?
                  EmpFilterDataNew.map((emp)=>
                  emp.name!=="ManagerPID"?
                  <td className="font-medium  text-gray-800 text-center border border-b divide-y">{tempdata.demographic[emp.name]}</td>
                  :null
                  ):null} */}

                </tr>
              )):null
            }
          </tbody>
        </table>
      </section>
    </>
  );
}


