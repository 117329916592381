import React from "react";
import ReactEcharts from "echarts-for-react";

export default function ConfirmMail(props) {
  let { crrPulseScoreList } = props;

  let valueList = [];
  let labelList = [];

  //////console.log("crrPulseScoreList--------->",crrPulseScoreList)
  let indexScoreColor = [];
  if (crrPulseScoreList && crrPulseScoreList.length > 0) {
    crrPulseScoreList.forEach((item) => {
      labelList.push(
        item && item.surveyId && item.surveyId.name
          ? item.surveyId.name
          : item.surveyId
      );
      valueList.push(item.score);
    });
  }

  return (
    <>
      <div className=""> 
         <h1 className="text-lg font-semibold"> 
            Pluse Score history
          </h1> 
     <div className="mx-4">
     <ReactEcharts
            style={{ height: "350px", width: "100%" }}
            option={{
              title: {
                text: "",
              },
              tooltip: {
                trigger: "axis",
              },
              grid: {
                left: "0%",
                right: "1%",
                bottom: "0%",
                containLabel: true,
              },
              //   toolbox: {
              //     feature: {
              //       saveAsImage: {}
              //     }
              //   },
              xAxis: {
                type: "category",
                boundaryGap: false,
                data: labelList,
                // color:indexScoreColor
              },
              yAxis: {
                type: "value",
              },
              series: [
                {
                  name: "Pulse Score",
                  type: "line",
                  stack: "Total",
                  data: valueList,
                },
              ],
            }}
          />
     </div>
      </div>
    </>
  );
}
