import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScoreGauge from './Components/ScoreGauge';
import QuestionCard from './Components/QuestionTypeCards/QuestionCard';
import ResponseHeader from '../../CommonComponents/ResponseHeader';
import SurveyReport from '../../Dashboard/Overview/SurveyReport/SurveyReport';
import QuestionMean from './Components/QuestionMean/QuestionMean';
import OverallDistribution from './Components/OverallDistribution/OverallDistribution';
import ReactSpeedometer from "react-d3-speedometer";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerDetailsIndexType: 0,
      showManagerList: true,
      CurrentManagerName: 'Select Manager',

      showOverview: true,
      showSurveyReport: false,
      showAllQuestions: false,
      CurrentTemplateId: '',

      allIndexList: [
        {
          "name": "Happiness",
          "template": 1,
          "score": "NaN",
          "type": "Score",
          "color": "#FF4081",
          "image": "/img/Dashboard/Index/happiness_index.png"
        },
        {
          "name": "Engagement",
          "template": 2,
          "score": "NaN",
          "type": "Percentage",
          "color": "#2196F3",
          "image": "/img/Dashboard/Index/engagement_index.png"

        },
        {
          "name": "Hope",
          "template": 3,
          "score": "NaN",
          "type": "Percentage",
          "color": "#009688",
          "image": "/img/Dashboard/Index/hope_index.png"

        },
        {
          "name": "Stress",
          "template": 4,
          "score": "NaN",
          "type": "Percentage",
          "color": "#EF5350",
          "image": "/img/Dashboard/Index/stress_index.png"

        },
        {
          "name": "Manager",
          "template": 5,
          "score": "NaN",
          "type": "Percentage",
          "color": "#9C27B0",
          "image": "/img/Dashboard/Index/manager_index.png"

        },
        {
          "name": "Leadership",
          "template": 6,
          "score": "NaN",
          "type": "Percentage",
          "color": "#FFB300",
          "image": "/img/Dashboard/Index/leadership_index.png"

        },
        {
          "name": "Value",
          "template": 7,
          "score": "NaN",
          "type": "Percentage",
          "color": "#C0CA33",
          "image": "/img/Dashboard/Index/value_index.png"

        },
        // {
        //   "name":"Organization Core",
        //   "template":8,
        //   "score":"NaN"
        // },
        {
          "name": "Safety",
          "template": 9,
          "score": "NaN",
          "type": "Percentage",
          "color": "#00CD7E",
          "image": "/img/Dashboard/Index/safety_index.png"

        },
        {
          "name": "Diversity",
          "template": 10,
          "score": "NaN",
          "type": "Percentage",
          "color": "#3F51B5",
          "image": "/img/Dashboard/Index/diversity_index.png"

        }
      ]


    }
  }

  componentDidMount() {

  }

  openSurveyResponse = () => {
    this.setState({
      showSurveyReport: true,
      showOverview: false,
      showAllQuestions: false
    });

  }

  closeResponseAnalytics = () => {
    this.setState({
      showSurveyReport: false,
      showOverview: true,
      showAllQuestions: false
    })

    let { storeInnerRoute } = this.props;
    storeInnerRoute(false)
  }

  // onAllQuestions=(item)=>{
  //   this.setState({CurrentTemplateId:item.templateId})
  //   this.setState({ 
  //     showSurveyReport:false,
  //     showOverview: false ,
  //     showAllQuestions:true
  //   })

  //   let { storeInnerRoute } = this.props;
  //   storeInnerRoute(true);
  // }

  // onAllMatrixQuestions=()=>{
  //   this.setState({CurrentTemplateId:13})
  //   this.setState({ 
  //     showSurveyReport:false,
  //     showOverview: false ,
  //     showAllQuestions:true
  //   })

  //   let { storeInnerRoute } = this.props;
  //   storeInnerRoute(true)
  // }

  goToOverview = (item) => {
    let { overviewAPI } = this.props;
    overviewAPI();
    this.setState({ CurrentTemplateId: "" })
    this.setState({
      showSurveyReport: false,
      showOverview: true,
      showAllQuestions: false
    })
  }

  handleGoBack = () => {
    this.setState({ showOverview: true })
  }


  render() {
    let { allIndexList, showOverview, showSurveyReport, showAllQuestions, CurrentTemplateId } = this.state;
    let {

      EmpFilterData,
      CurrentSurveyId,
      OverviewList,
      OverallMean,
      onCardClick,
      getRespondantDetailsData,
      getIndexFilterData,
      getQuestionDistOverallData,
      getMeanScoreQwiseData,
      getQuestionNPSResultData,
      getQuestionOthersResultData,

      getSurveyData,






      respondants,
      getHappyPlusOrgHappinessData
    } = this.props;


    let OverallMeanData = {
      "name": "Overall",
      "mean": (typeof OverallMean !== 'string' || OverallMean instanceof Number) && OverallMean ? OverallMean.toFixed(2) : 'NaN',
      "color": "#4080bf"
    }

    //  console.log('OverviewList: ',OverviewList)
    //  console.log('sdsa getQuestionNPSResultData:',getQuestionNPSResultData)
    //  console.log('getQuestionNPSResultData:',getQuestionNPSResultData)



    function FormattFun(inputData, isSummary) {
      let RankObj = getSurveyData && getSurveyData.designObj && getSurveyData.designObj.componentRank ? getSurveyData.designObj.componentRank : {}

      let LIST = []
      let prevTemList = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {

          let templateId = ques.total[0]._id.qtemplate;

          let temp = {
            name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
            answerList: [],
            rank: RankObj && RankObj[templateId] ? RankObj[templateId] : 100,
            templateId: templateId
          }
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading: item && item._id && item._id.heading ? item._id.heading : "",
                parentId: item && item._id && item._id.parentId ? item._id.parentId : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId
              }
              temp["answerList"].push(ans);
            });
          }


          if (isSummary) {
            let getIndex = prevTemList.findIndex(prev => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId)
            }
          }
          else {
            LIST.push(temp);
          }


        });
      }
      return LIST
    }

    //  let NPSQuesList = FormattFun(getQuestionNPSResultData);
    let OthersQuesList = FormattFun(getQuestionOthersResultData, true).sort((a, b) => a.rank - b.rank);

    let AllQuesList = FormattFun(getQuestionOthersResultData, false).filter(x => x.templateId === CurrentTemplateId);



    ////console.log("----->OthersQuesList<-------",OthersQuesList)


    let { onOthersCardClick, getQuestionMatrixResultData, questionsList, handleMatrixDetails, onAllQuestions, onAllMatrixQuestions } = this.props;



    let { onDetailPage } = this.props;




    let ParticipationObj = {
      total: 0,
      completed: 0,
      mailSent: 0,
      clicked: 0,
      participated: 0,
      performance: 0,
      perfPlusSign: true
    }

    if (respondants && respondants[0]) {
      let apiData = respondants[0]
      ParticipationObj['total'] = apiData['total'] ? apiData['total'] : 0;
      ParticipationObj['active'] = apiData['active'] ? apiData['active'] : 0;
      ParticipationObj['inactive'] = apiData['inactive'] ? apiData['inactive'] : 0;



    }



    let responseOption = {
      "item1": {
        "label": "Total",
        "value": ParticipationObj['total']
      },
      "item2": {
        "label": "Active",
        "value": ParticipationObj['active']
      },
      "item3": {
        "label": "Inactive",
        "value": ParticipationObj['inactive'] + '%'
      }
    }



    return (
      <>
        <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto pb-20 p-6" style={{ height: 'calc(100% - 4rem)' }}>


          {showOverview ?
            <>
              <div className="">
                <h1 className='xl:text-2xl text-xl font-medium text-[#3D405B] '>Overview</h1>
                <div className="" >

                  <div 
                  // onClick={() => this.openSurveyResponse()}
                  >
                    <ResponseHeader responseOption={responseOption} />
                  </div>


                  {getHappyPlusOrgHappinessData && getHappyPlusOrgHappinessData.overallScore ?
                    <>
                      <h1 className="py-2 font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Summary</h1>

                      <div className="grid gap-4 lg:grid-cols-1 md:grid-cols-1 lg:gap-6">


                        <div  className="p-4 bg-white border rounded-lg cursor-pointer pb-12">
                          <div className="">
                            <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap truncate">
                              Overall Happiness Score
                            </h1>
                          </div>

                          <div className="flex justify-center w-full">
                            <div className="h-48 px-2 mt-2 mb-4 md:mt-10"
                            >
                              {getHappyPlusOrgHappinessData && getHappyPlusOrgHappinessData.overallScore ?
                                <ReactSpeedometer className="meter"
                                  value={getHappyPlusOrgHappinessData.overallScore.toFixed(2)}
                                  minValue={0}
                                  maxValue={72}
                                  needleColor="#4db8ff"
                                  segments={4}
                                  segmentColors={[
                                    "#e53935",
                                    "#ffc107",
                                    "#00cd7e",
                                    "#ff4081",
                                  ]}
                                  ringWidth={35}
                                  customSegmentStops={[0, 48, 60, 66, 72]}
                                  // fluidWidth={true}
                                  needleTransitionDuration={3333}
                                  needleTransition="easeElastic"
                                />
                                : null}

                            </div>

                          </div>

                          <div className="flex items-center justify-center pb-4 text-white md:px-2 ">
                            <span className="px-2 py-2 text-sm bg-red-500 border-r border-white rounded-l-lg md:px-4 lg:text-md text-12">
                              {"Unhappy"}
                            </span>
                            <span className="px-2 py-2 text-sm bg-yellow-400 border-r border-white lg:px-4 lg:text-md text-12">
                              {"Moderate"}
                            </span>
                            <span className="px-2 py-2 text-sm bg-green-400 border-r border-white lg:px-4 lg:text-md text-12">
                              {"Happier"}
                            </span>
                            <span className="px-2 py-2 text-sm bg-pink-500 rounded-r-lg lg:px-4 lg:text-md text-12 ">
                              {"Happiest"}
                            </span>
                          </div>

                          {/* <div className="flex items-center justify-center py-2 cursor-pointer whitespace-nowrap">
                            <button  onClick={() => onDetailPage("overall")} className=" text-[#2196f3]  cursor-pointer transition duration-150 flex items-center uppercase font-medium xl:text-base text-sm ">View More<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right ml-2" width="34" height="34" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2196f3" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="5" y1="12" x2="19" y2="12"></line><line x1="15" y1="16" x2="19" y2="12"></line><line x1="15" y1="8" x2="19" y2="12"></line></svg></button>

                          </div> */}
                        </div>


                      </div>
                    </>
                    :
                    <>
                      <div className='pt-24 text-3xl font-medium text-center text-gray-400'>Data not available!</div>
                    </>}


                </div>
              </div>

            </>
            :
            null}

          {showSurveyReport ?
            <div>
              <SurveyReport
                CurrentSurveyId={CurrentSurveyId}
                closeResponseAnalytics={this.closeResponseAnalytics}
                EmpFilterData={EmpFilterData}
                getIndexFilterData={getIndexFilterData}
                getRespondantDetailsData={getRespondantDetailsData}
                handleGoBack={this.handleGoBack}
              />
            </div>
            : null}



          {/* {showAllQuestions?

          :null} */}


        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
