import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class WordChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedText:''
    }
  }



  componentDidMount() {
  }


    handleKeyword=(data)=>{
      let { handleKeywordCall } = this.props;
      handleKeywordCall(data.text)
      this.setState({SelectedText:data.text+' ('+data.value+')'});

    }

    handleKeywordTooltip=(data)=>{
      this.setState({SelectedText:data.text+' ('+data.value+')'});
    }


    render(){

      let { CountRestrict, valueList, loading, FilterList, dmgValue , secondCardActive, EmpFilterData}= this.props;
      // let { SelectedText }= this.state;


      let DmgLevel = FilterList && FilterList.length>0?FilterList[0].level:""




      let countList = [];

      let DriverName = [];
      let DriverValues = [];

      if(valueList && valueList.length>0){
        valueList.forEach((item)=>{
          countList.push({
            "value":(item[0][0]).toString()+", "+(item[0][1]).toString(),
            "count":item[1],
          })
        });
      }


      if(countList && countList.length>0){
        countList.sort((b,a)=>a.count - b.count);

        countList.forEach((item)=>{

          if(CountRestrict(item["count"])){
            // DriverName.push(item["value"]);
            // DriverValues.push("NA");          
          }else{
            DriverName.push(item["value"]);
            DriverValues.push(item["count"]);
          }

        });
      }

      let XAxis = DriverName;
      let List = DriverValues;



      console.log("-------------------------countList------------------------->",countList)
      console.log("-------------------------XAxis------------------------->",XAxis)
      console.log("-------------------------List------------------------->",List)


      const onChartClick = (params) => {

        
        let { handleKeywordCall } = this.props;

        let tempTextList = params && params.name? params.name.split(',') : []
        console.log('Chart params', params);

        let xaXis = DmgLevel? DmgLevel:(EmpFilterData && EmpFilterData.length>0?EmpFilterData[0]["name"]:"Demographics")

        console.log('Chart tempTextList, true, filters------------>', tempTextList, true, xaXis,dmgValue);

        if(xaXis && xaXis!=="Demographic"){
          // let filters = [{
          //   "level":xaXis,
          //   "value":dmgValue
          // }]

          handleKeywordCall(tempTextList, true)
        }







      };


      const onEvents = {
        click: onChartClick,
      };


      // let xaXis = "Demographics"


        return(
            <>
                {/* <div className="bg-white w-full shadow-lg p-4 m-4 mr-2 justify-center" style={{overflowX:'auto'}} > */}
                <div className="bg-white w-full   ">

                <div className="flex items-center justify-between  text-gray-500 p-4">

                    </div>
                    {/* <div className="cursor-pointer" onClick={()=>this.handleKeyword('policy')}>Policy</div>
                    <div className="h-60" /> */}
                    <div className='w-full' style={false?{height:'350px', overflowX:'auto',overflowY:'hidden'}:
                  {height:'350px',  overflowX:'auto',overflowY:'hidden'}}>
                      {XAxis && XAxis.length>0?
                      <>
                      <div style={{height:'350px', }}>
                        <ReactEcharts
                        onEvents={onEvents}
                        option={{
                          tooltip:{
                            trigger: 'item',
                            formatter:function (a) {
                              return '<b>'+'Co-occurrence words: '+'</b> '+XAxis[a.dataIndex]+'</br>'+'<b>Count: </b> '+ ((a.value)?a.value:0).toString()+'</br>'
                            }
                          },
                          xAxis: {
                              type: 'category',
                              data: XAxis,
                              axisLabel:{
                                show:true,
                                rotate:40,
                                interval:0,
                                formatter:function(a){
                                  // //console.log(a)
                                  return a?a.length>12?a.slice(0,12)+'..':a:a
                                }
                              }
                          },
                          yAxis: {
                              type: 'value',
                          },
                          series: [{
                              data: List,
                              type: 'bar',
                              showBackground: true,
                              barWidth:'20px',
                              label: {
                                show: true,
                                position: 'top',
                                formatter: function(value) {
                                    return value.data>0?value.data+'':"0";
                                }
                            },
                              color:'#6536d1'
                          }]
                      }}
                                        
                      />
                       </div>
                      <div className="text-center mb-12 font-medium"><b>X-Axis:</b>{'Co-occurance words'}{',  '}<b>Y-Axis:</b>{ ' count  '}</div>
                      </>
                      :
                      <>
                      {!loading?
                      <div className="text-center text-3xl text-gray-400 pt-40 ">We have not calculated this section!</div>
                      
                      
                      
                      :null}
                      </>
                      }
                    </div>
                    <h1 class="text-sm text-[#3d405B]/70 p-6 border-t text-center">
                      * We are not showing data where word count is less than 5
                    </h1>

                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(WordChart);
