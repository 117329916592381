import React from 'react';
import NPSChart from './Components/NPSChart';

export default function Happiness(props) {

  let {

    getQuestionOthersResultData,
    QueTemplate

  } = props;

  // let item = ""
  // let score = 'NaN';
  // let type = '';

  // if(item['score']!=='NaN'){
  //     score = parseFloat(item['score']);
  //     type = item['type'];
  // }



  function FormattFun(inputData, isSummary) {

    let LIST = []
    let prevTemList = []
    if (inputData && inputData.length > 0) {
      inputData.forEach((ques) => {

        // let templateId = ques.total[0]._id.qtemplate;
        let templateId = ques.total[0]._id.qtemplate;

        if (QueTemplate === templateId) {

          let temp = {
            name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
            answerList: [],
            templateId: templateId
          }
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading: item && item._id && item._id.heading ? item._id.heading : "",
                parentId: item && item._id && item._id.parentId ? item._id.parentId : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId
              }
              temp["answerList"].push(ans);
            });
          }


          if (isSummary) {
            let getIndex = prevTemList.findIndex(prev => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId)
            }
          }
          else {
            LIST.push(temp);
          }

        }


      });
    }
    return LIST
  }

  let OthersQuesList = FormattFun(getQuestionOthersResultData, true);







  let item = OthersQuesList && OthersQuesList.length > 0 ? OthersQuesList[0] : {}

  let AnswerObj = {};
  if (item && item.answerList && item.answerList.length > 0) {
    ////console.log('item.answerList (STAR)',item.answerList)

    item.answerList.forEach((ans) => {
      if (ans && ans.answerText) {
        if (AnswerObj && AnswerObj[ans.answerText]) {
          AnswerObj[ans.answerText] += (ans.count);
        }
        else {
          AnswerObj[ans.answerText] = (ans.count)
        }
      }
    });
  }


  //////console.log("item----",item)
  //console.log('---------- OthersQuesList 16----------: ',OthersQuesList);


  let name = "NPS";

  return (
    <>
      {item && item.name && (item && item.answerList && item.answerList[0] && item.answerList[0].template && (item.answerList[0].template === 16)) ?
        <>
          <NPSChart AnswerObj={AnswerObj} name={name} templateId={16} questionName={item.name} stage={1} index={1} />
        </>
        : null}
    </>
  );
}
