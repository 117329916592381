import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';
class DriverRadar extends Component {
  constructor(props) {
    super(props);
    this.state = {


    }
  }

  componentDidMount() {

  }


  render() {
    let { GetParameterLabel, getQuestionsData } = this.props;


    function hexToRgbA(hex, opacity) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
      throw hex;
    }



    function getQuesByDimen(dimen) {
      if (getQuestionsData && getQuestionsData.length > 0) {
        let quesList = getQuestionsData.filter(prev => prev.parameterDimensionName === dimen);
        return quesList
      } else {
        return []
      }
    }




    let ParameterNameList = []
    let DimensionV2List = []
    let RelationV2List = [];
    let SelfScoreListOrdered = [];
    let OtherScoreListOrdered = [];

    function getScoreValueX(questionXList, relation) {
      let scoreCalc = 0
      let oSum = 0;
      let oCount = 0;
      if (questionXList && questionXList.length > 0) {
        questionXList.forEach((item) => {
          let questionX = item;
          let scoreMean = 0;
          if (relation !== "Self") {
            let otherSum = 0;
            let otherCount = 0;

            if (questionX && questionX.relationList && questionX.relationList.length > 0) {
              questionX.relationList.forEach((relation) => {
                if (relation !== "Self") {
                  otherCount += Number(questionX[relation + "_count"])


                  otherSum += Number(questionX[relation + "_sum"]);
                }
              })
            }
            scoreMean = Number((otherSum / otherCount))
          }
          else {
            scoreMean = Number(questionX["Self_sum"] / questionX["Self_count"]);

          }
          // return [Number((scoreMean))]
          // scoreList.push(Number((scoreMean)))
          oSum += Number((scoreMean))
          oCount += 1
        })
      }
      scoreCalc = oSum / oCount
      return scoreCalc ? scoreCalc.toFixed(2) : 0
    }


    if (getQuestionsData && getQuestionsData.length > 0) {
      getQuestionsData.forEach((element) => {
        let getIndex6 = ParameterNameList.findIndex(prev => prev.name === element.parameterDimensionName);
        if (getIndex6 === -1) {
          DimensionV2List.push(GetParameterLabel(element.parameterDimensionName))

          let SelfScore = getScoreValueX(getQuesByDimen(element.parameterDimensionName), 'Self');
          let OtherScore = getScoreValueX(getQuesByDimen(element.parameterDimensionName), 'Others');

          SelfScoreListOrdered.push(SelfScore)
          OtherScoreListOrdered.push(OtherScore)


          ParameterNameList.push({
            "name": element.parameterDimensionName,
            "self": SelfScore,
            "others": OtherScore
          })


        }

      })
    }



    RelationV2List.push({
      name: "Self",
      type: 'bar',
      barWidth: '25px',
      data: SelfScoreListOrdered,
      label: {
        show: true,
        position: 'right',
        color: "#000",
        fontStyle: 'bold'
      },
    });

    RelationV2List.push({
      name: "Others",
      type: 'bar',
      barWidth: '25px',
      data: OtherScoreListOrdered,
      label: {
        show: true,
        position: 'right',
        color: "#000",
        fontStyle: 'bold'
      },
    });



    //-------------------------------------------------------------------------------------------------------


    // let typeList = ["Self", "Others"]

    // if (typeList && typeList.length > 0) {
    //   typeList.forEach((item2) => {
    //     RelationList.push({
    //       name: item2,
    //       type: 'bar',
    //       barWidth: '25px',
    //       // data: getScoreValueX(item2),
    //       data: getScoreDimensionWise(getQuestionsData,item2),
    //       label: {
    //         show: true,
    //         position: 'right'
    //     },
    //     })
    //   })
    // }



    console.log('-------->>>>DimensionV2List; ', DimensionV2List)
    console.log('-------->>>>RelationV2List; ', RelationV2List)


    return (
      <>
        <div className="p-4 bg-white border rounded-md cursor-pointer">
          <div className='space-y-6' >
            <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Dimension Summary</h1>

            {RelationV2List && RelationV2List.length > 0 && DimensionV2List && DimensionV2List.length > 0 ?
              <div className="flex items-center justify-center w-full mb-4 overflow-hidden overflow-x-auto whitespace-nowrap">


                <ReactEcharts className='w-full mx-auto'
                  style={{ height: '600px' }}
                  option={{
                    // title: {
                    //   text: 'World Population'
                    // },
                    color: [
                      new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: hexToRgbA('#F6736A','0.5')
                        },
                        {
                          offset: 1,
                          color: '#F6736A'
                        }]),
                        new graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: hexToRgbA('#f9d350','0.5')
                          },
                          {
                            offset: 1,
                            color: '#f9d350'
                          }])
                        ],
                    tooltip: {
                      trigger: 'axis',
                      axisPointer: {
                        type: 'shadow'
                      }
                    },
                    legend: {},
                    grid: {
                      left: '3%',
                      right: '4%',
                      bottom: '3%',
                      containLabel: true
                    },

                    xAxis: {
                      type: 'value',
                      boundaryGap: [0, 0.01],
                      splitLine: {
                        lineStyle: {
                          color: '#fff'
                        }
                      }, axisLabel: {
                        show: false
                      }
                    },
                    yAxis: {
                      type: 'category',
                      data: DimensionV2List,
                      axisLine: {
                        lineStyle: {
                          color:'#e6e6e6'
                        }
                      },
                      axisLabel: {
                        color: "#000",
                        textStyle: {
                          fontSize: 14,
                          fontWeight: 'bold'
                        },
                      },
                    },
                    series: RelationV2List
                  }}
                />



              </div>
              :
              <>
                <div className="pt-40 text-3xl text-center text-gray-400">Data Not Available!</div>
              </>}

          </div>
        </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DriverRadar);
