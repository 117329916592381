import { customerConstants } from '../_constants';
import { customerService } from '../_services';

export const customerActions = {
    getSurvey,
    getDashboardCard,
    getQuestionDist,
    getQuestionDistOverall,
    getMeanScore,
    getMeanScoreQwise,
    getQuestionOthersResult,
    getQuestionOthersResultFilter,
    getQuestion2OthersResultFilter,
    getQuestionMatrixResult,


    getCustomerColumnName,
    getCustomerFilterListByCompanyId,
    createCustomerFilter,
    updateCustomerFilterStatus,
    deleteCustomerFilter,
    updateCustomerFilterRank,
    updateCustomerShowReportStatus,
    updateCustomerFilterLabel,

    getCustomerList,
    getCustFilter,
    getIndexByFilterDMG_Customer,
    getRespondantDetails_Customers,
    createCustomerRecord,
    getCampaignCustomerssByID

};

function getSurvey(data) {
    return dispatch => {
        dispatch(request());
        customerService.getSurvey(data)
            .then(
                customer =>{ 
                    dispatch(success(customer));
                    if(customer && customer.SurveyData && customer.SurveyData.list && customer.SurveyData.list.length>0){
                        let SurveyList = []
                        customer.SurveyData.list.forEach((survey)=>{
                            if(survey && survey.templateId && survey.templateId === 3){
                                SurveyList.push(survey);
                            }

                        });
                        if(SurveyList && SurveyList.length>0){
                            let data2 = {
                                "surveyId": false? SurveyList[0].id:""
                            }
                            //console.log('surveyId', data2.surveyId)

                            if(data2 && data2.surveyId){
                                dispatch(this.getDashboardCard(data2));
                            }
                        }

                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_SURVEY_LIST_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_SURVEY_LIST_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_SURVEY_LIST_FAILURE, error } }
}


function getDashboardCard(data) {
    return dispatch => {
        dispatch(request());
        customerService.getDashboardCard(data)
            .then(
                customer => dispatch(success(customer)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_DASHBOARD_CARD_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_DASHBOARD_CARD_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_DASHBOARD_CARD_FAILURE, error } }
}



function getQuestionDist(data) {
    return dispatch => {
        dispatch(request());
        customerService.getQuestionDist(data)
            .then(
                customer => dispatch(success(customer)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_QUESTION_DIST_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_QUESTION_DIST_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_QUESTION_DIST_FAILURE, error } }
}


function getQuestionDistOverall(data) {
    return dispatch => {
        dispatch(request());
        customerService.getQuestionDistOverall(data)
            .then(
                customer => dispatch(success(customer)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_QUESTION_DIST_OVERALL_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_QUESTION_DIST_OVERALL_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_QUESTION_DIST_OVERALL_FAILURE, error } }
}

function getMeanScore(data) {
    return dispatch => {
        dispatch(request());
        customerService.getMeanScore(data)
            .then(
                customer => dispatch(success(customer)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_MEAN_SCORE_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_MEAN_SCORE_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_MEAN_SCORE_FAILURE, error } }
}



function getMeanScoreQwise(data) {
    return dispatch => {
        dispatch(request());
        customerService.getMeanScoreQwise(data)
            .then(
                customer => dispatch(success(customer)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_MEAN_SCORE_QWISE_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_MEAN_SCORE_QWISE_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_MEAN_SCORE_QWISE_FAILURE, error } }
}


function getQuestionOthersResult(data) {
    return dispatch => {
        dispatch(request());
        customerService.getQuestionOthersResult(data)
            .then(
                customer => dispatch(success(customer)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_QUES_OTHERS_RESULT_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_QUES_OTHERS_RESULT_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_QUES_OTHERS_RESULT_FAILURE, error } }
}


function getQuestionOthersResultFilter(data) {
    let obj = {
        getQuestionOthersResultFilterData :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        customerService.getQuestionOthersResultFilter(data)
            .then(
                customer => dispatch(success(customer)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_QUES_FILTER_OTHERS_RESULT_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_QUES_FILTER_OTHERS_RESULT_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_QUES_FILTER_OTHERS_RESULT_FAILURE, error } }
}


function getQuestion2OthersResultFilter(data) {
    let obj = {
        getQuestion2OthersResultFilterData :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        customerService.getQuestion2OthersResultFilter(data)
            .then(
                customer => dispatch(success(customer)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_QUES_2_FILTER_OTHERS_RESULT_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_QUES_2_FILTER_OTHERS_RESULT_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_QUES_2_FILTER_OTHERS_RESULT_FAILURE, error } }
}



function getQuestionMatrixResult(data) {
    return dispatch => {
        dispatch(request());
        customerService.getQuestionMatrixResult(data)
            .then(
                customer => dispatch(success(customer)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_QUES_NPS_RESULT_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_QUES_NPS_RESULT_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_QUES_NPS_RESULT_FAILURE, error } }
}







function getCustomerColumnName(data) {
    return dispatch => {
        dispatch(request());
        customerService.getCustomerColumnName(data)
            .then(
                customer => {
                    //console.log(customer)
                    dispatch(success(customer));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_COLUMN_NAME_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_COLUMN_NAME_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_COLUMN_NAME_FAILURE, error } }
}


function getCustomerFilterListByCompanyId(data) {
    return dispatch => {
        dispatch(request());
        customerService.getCustomerFilterListByCompanyId(data)
            .then(
                customer => {
                    dispatch(success(customer));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_FILTER_LIST_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_FILTER_LIST_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_FILTER_LIST_FAILURE, error } }
}


function createCustomerFilter(data) {
    return dispatch => {
        dispatch(request());
        customerService.createCustomerFilter(data)
            .then(
                customer => {
                    //console.log(customer)
                    dispatch(success(customer));
                    dispatch(this.getCustomerFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.CREATE_CUSTOMER_FILTER_REQUEST } }
    function success(customer) { return { type: customerConstants.CREATE_CUSTOMER_FILTER_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.CREATE_CUSTOMER_FILTER_FAILURE, error } }
}

function updateCustomerFilterRank(data) {
    return dispatch => {
        dispatch(request());
        customerService.updateCustomerFilterRank(data)
            .then(
                customer => {
                    //console.log(customer)
                    dispatch(success(customer));
                    dispatch(this.getCustomerFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.UPDATE_CUSTOMER_FILTER_RANK_REQUEST } }
    function success(customer) { return { type: customerConstants.UPDATE_CUSTOMER_FILTER_RANK_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.UPDATE_CUSTOMER_FILTER_RANK_FAILURE, error } }
}

function updateCustomerFilterStatus(data) {
    return dispatch => {
        dispatch(request());
        customerService.updateCustomerFilterStatus(data)
            .then(
                customer => {
                    //console.log(customer)
                    dispatch(success(customer));
                    dispatch(this.getCustomerFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.UPDATE_CUSTOMER_FILTER_STATUS_REQUEST } }
    function success(customer) { return { type: customerConstants.UPDATE_CUSTOMER_FILTER_STATUS_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.UPDATE_CUSTOMER_FILTER_STATUS_FAILURE, error } }
}
function updateCustomerShowReportStatus(data) {
    return dispatch => {
        dispatch(request());
        customerService.updateCustomerShowReportStatus(data)
            .then(
                customer => {
                    //console.log(customer)
                    dispatch(success(customer));
                    dispatch(this.getCustomerFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.UPDATE_CUSTOMER_SHOW_REPORT_STATUS_REQUEST } }
    function success(customer) { return { type: customerConstants.UPDATE_CUSTOMER_SHOW_REPORT_STATUS_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.UPDATE_CUSTOMER_SHOW_REPORT_STATUS_FAILURE, error } }
}

function deleteCustomerFilter (data) {
    return dispatch => {
        dispatch(request());
        customerService.deleteCustomerFilter(data)
            .then(
                customer => {
                    //console.log(customer)
                    dispatch(success(customer));
                    dispatch(this.getCustomerFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.DELETE_CUSTOMER_FILTER_REQUEST } }
    function success(customer) { return { type: customerConstants.DELETE_CUSTOMER_FILTER_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.DELETE_CUSTOMER_FILTER_FAILURE, error } }
}
function updateCustomerFilterLabel(data) {
    return dispatch => {
        dispatch(request());
        customerService.updateCustomerFilterLabel(data)
            .then(
                customer => {
                    //console.log(customer)
                    dispatch(success(customer));
                    dispatch(this.getCustomerFilterListByCompanyId())
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.UPDATE_CUSTOMER_FILTER_LABEL_REQUEST } }
    function success(customer) { return { type: customerConstants.UPDATE_CUSTOMER_FILTER_LABEL_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.UPDATE_CUSTOMER_FILTER_LABEL_FAILURE, error } }
}

function getCustomerList(data) {
    return dispatch => {
        dispatch(request());
        customerService.getCustomerList(data)
            .then(
                customer => {
                    dispatch(success(customer));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_LIST_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_LIST_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_LIST_FAILURE, error } }
}


function getCustFilter() {
    return dispatch => {
        dispatch(request());
        customerService.getCustFilter()
            .then(
                customer => {
                    //////console.log("=========customer======",customer)
                    dispatch(success(customer));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_LEVEL_FILTER_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_LEVEL_FILTER_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_LEVEL_FILTER_FAILURE, error } }
}



function getIndexByFilterDMG_Customer(data) {
    return dispatch => {
        dispatch(request());
        customerService.getIndexByFilterDMG_Customer(data)
            .then(
                customer => {
                    //console.log(customer)
                    dispatch(success(customer));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_DMG_FILTER_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_DMG_FILTER_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_DMG_FILTER_FAILURE, error } }
}




function getRespondantDetails_Customers(data) {
    return dispatch => {
        dispatch(request());
        customerService.getRespondantDetails_Customers(data)
            .then(
                customer => {
                    //console.log(customer)
                    dispatch(success(customer));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_RESPONDANTS_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_RESPONDANTS_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_RESPONDANTS_FAILURE, error } }
}




function createCustomerRecord(data) {
    return dispatch => {
        dispatch(request());
        customerService.createCustomerRecord(data)
            .then(
                customer => {
                    //console.log(customer)
                    dispatch(success(customer));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.GET_CUSTOMER_RECORD_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CUSTOMER_RECORD_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CUSTOMER_RECORD_FAILURE, error } }
}


function getCampaignCustomerssByID(data) {
    return dispatch => {
        dispatch(request());
        customerService.getCampaignCustomerssByID(data)
            .then(
                customer => {
                    //console.log(customer)
                    dispatch(success(customer));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: customerConstants.GET_CAMPAIGN_CUSTOMER_BY_ID_REQUEST } }
    function success(customer) { return { type: customerConstants.GET_CAMPAIGN_CUSTOMER_BY_ID_SUCCESS, customer } }
    function failure(error) { return { type: customerConstants.GET_CAMPAIGN_CUSTOMER_BY_ID_FAILURE, error } }
}