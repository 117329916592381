import React from "react";
import ReactEcharts from "echarts-for-react";


export default function ConfirmMail(props) {
    let { showModal, closeResolveModal, crrPulseScoreList } = props;

    let valueList = [];
    let labelList = [];

    //////console.log("crrPulseScoreList--------->",crrPulseScoreList)
    let indexScoreColor = []
    if(crrPulseScoreList && crrPulseScoreList.length>0){
        crrPulseScoreList.forEach((item)=>{
            labelList.push((item && item.surveyId && item.surveyId.name)?item.surveyId.name:item.surveyId);
            valueList.push(item.score);
        })
    }


    return (



        <>
            {showModal ?
                <div className="top-0 xl:h-screen h-full  w-full flex justify-center items-center z-40 bg-black fixed bg-opacity-40"  >
                    <div className="bg-white rounded-lg  shadow-sm  2xl:w-4/12 xl:w-4/12   lg:w-5/12 md:w-6/12 w-11/12">
                        <div className="flex justify-between items-center px-6 p-4 border-b"> <h2 className="text-base font-semibold text-center test-gray-800 capitalize">Pluse Score history</h2>
                            <span onClick={closeResolveModal} className="cursor-pointer material-symbols-outlined   font-medium text-gray-500 p-1 bg-gray-100 rounded-full hover:bg-sky-100 transtion duration-150">close</span></div>
                        <div className="p-6  space-y-4">
 

                          <ReactEcharts
                            style={{height:'300px',
                          }}
                            option={{
                              title: {
                                text:""
                              },
                              tooltip: {
                                trigger: 'axis'
                              },
                              grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                              },
                            //   toolbox: {
                            //     feature: {
                            //       saveAsImage: {}
                            //     }
                            //   },
                              xAxis: {
                                type: 'category',
                                boundaryGap: false,
                                data: labelList,
                                // color:indexScoreColor
                              },
                              yAxis: {
                                type: 'value'
                              },
                              series: [
                                {
                                  name: "Pulse Score",
                                  type: 'line',
                                  stack: 'Total',
                                  data: valueList
                                }
  
                              ]
                            }}
                          />


                        </div>

                     

                    </div>
                </div>
                : null}
        </>

    );
}
