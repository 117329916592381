import React, { Component } from 'react';
import { connect } from 'react-redux';

class ReportWaiseData extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
  }


  render() {

    let { item, index } = this.props;
    let { total } = item ? item : {};
    // console.log('Email____Type____111111111111111111111111', item);
    // console.log('Total____Items____111111111111111111111111', total);

    return (

      <div className='p-6 space-y-4 bg-white'>
        <div className='flex flex-row items-center justify-between w-full gap-4'>
          <div className='flex items-center text-[#212121] space-x-2'>
            <p className='p-3 bg-[#ededed] font-semibold'>Question {index + 1}</p>
          </div>
        </div>
        <div className=''>
          <h2 className='text-xl font-medium text-[#212121]'>{item && item.question.name ? item.question.name : 'null'}</h2>
          <p className='text-base text-[#212121]/80 flex space-x-2 items-center pt-3'>
            <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>Email</p>
            {/* <b className='font-medium text-[#212121] pr-1'>10</b> out of<b className='font-medium text-[#212121] pr-1'>10</b> answered */}
          </p>
        </div>

        <div className='bg-[#f9f9f9] rounded overflow-hidden overflow-y-auto h-[400px]'>
          <table className='w-full'>
            <thead className='sticky top-0 z-10 bg-[#EEEEEE]'>
              <tr className='border-b'>
                <th className='px-4 py-3 text-lg font-medium text-left'>S.No.</th>
                <th className='px-4 py-3 text-lg font-medium text-left'>Answer Choices</th>
              </tr>
            </thead>

            <tbody className='px-4 py-3 divide-y'>
              {total && total.length > 0 ?
                total.map((item, index) =>
                  <tr className='text-[#212121]'>
                    <td className='px-4 py-3 text-left'>{index + 1}</td>
                    <td className='px-4 py-3 text-left'>{item && item._id && item._id && item._id.answerText ? item._id.answerText : ''}</td>
                  </tr>
                ) :
                <tr>
                  <td colSpan={3} className='pt-2 text-center'>Data not found</td>
                </tr>
              }
            </tbody>

          </table>
        </div>

        {/* {stage !== 2 ?
          <>
            <button className='flex items-center space-x-2 font-medium text-[#2196F3] p-2 w-fit mx-auto'
            // onClick={() => onOthersCardClick(item)}
            >
              <span>{stage == 2 ? "View More" : (stage == 3 ? "" : " View More")} </span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg>
            </button>
          </>
          : null} */}
      </div>


    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ReportWaiseData);
