import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import BarSet from './BarSet';
import BarChart from './BarChart';


class NPSChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList: [],
      EmpIndexA: 0,
      EmpIndexB: 4
    }
  }
  componentDidMount() {

  }

  render() {
    let { AnswerObj, size, name, questionName } = this.props;

    // function ColorFn(key){
    //   if(templateId===11){
    //     return {color:key==="Yes"?'#00cc66':'#ff5050'}
    //   }
    //   else{
    //     return {}
    //   }
    // }

    let DataList = [];

    let totalCount = 0;

    let Promoters = 0;
    let Passsives = 0;
    let Distractors = 0;


    let LifeScale = {}

    if (AnswerObj) {
      Object.keys(AnswerObj).forEach(key => {
        let score = parseInt(AnswerObj[key]);

        LifeScale[LifeScale] = score;

        totalCount += score;

        if (parseInt(key) >= 9 && parseInt(key) <= 10) {
          Promoters += score;
        }
        if (parseInt(key) >= 7 && parseInt(key) <= 8) {
          Passsives += score;
        }
        if (parseInt(key) >= 1 && parseInt(key) <= 6) {
          Distractors += score;
        }

      });
    }


    let PromotersPer = (Promoters * 100 / totalCount);
    let PasssivesPer = (Passsives * 100 / totalCount);
    let DistractorsPer = (Distractors * 100 / totalCount);

    let npsScore = ((PromotersPer) - (DistractorsPer)).toFixed(2);

    let PromotersColorRange = PromotersPer / 100;
    let PasssivesColorRange = (PasssivesPer / 100) + PromotersColorRange;
    let DistractorsColorRange = (DistractorsPer / 100) + PasssivesColorRange;


    let NPSObj = {
      "PromotersPer": PromotersPer.toFixed(2),
      "PasssivesPer": PasssivesPer.toFixed(2),
      "DistractorsPer": DistractorsPer.toFixed(2)
    }


    // DataList.push({
    //   name: key,
    //   value: score
    // });

    ////console.log('NPSObj: ',NPSObj);

    // ////console.log('npsScore: ',npsScore);

    //console.log('PromotersColorRange: ',PromotersColorRange);
    //console.log('PasssivesColorRange: ',PasssivesColorRange);
    //console.log('DistractorsColorRange: ',DistractorsColorRange);



    let { stage, index } = this.props;




    //console.log('AnswerObj:------> ',AnswerObj);
    //console.log('LifeScale:------> ',LifeScale);

    let DriverName = [];
    let DriverValues = [];

    let TotalOverAllCount = 0
    if (AnswerObj) {
      Object.keys(AnswerObj).forEach(key => {
        let score = parseInt(AnswerObj[key]);
        DriverName.push(key);
        TotalOverAllCount += score;
        DriverValues.push(score);
      });
    }


    return (
      <>




        <div className="flex justify-between pb-2">
          <div className="relative inline-block dropdown">
            <h1 className="text-lg font-medium truncate cursor-pointer">
              {/* {stage==2?
                                <b>{"Q"+(index+1)+"."}</b>
                                :
                                <b>Q1.</b>
                                } */}
              {questionName && questionName.length > 30 ? questionName.slice(0, 30) + "..." : questionName}</h1>
            <div className="absolute z-30 hidden p-4 pt-1 text-lg font-semibold bg-white rounded-md shadow-md cursor-pointer dropdown-menu -left-4 -top-12">
              {questionName}
            </div>
          </div>
          <div className="ml-4 cursor-pointer whitespace-nowrap">
            <span className="px-4 py-2 text-xs text-center text-white bg-blue-500 rounded-md hover:bg-blue-600 ">
              {stage == 2 ? "View Details" : (stage == 3 ? "" : "View More")}</span></div>
        </div>








        <BarChart
          // AnswerObj={AnswerObj}
          DriverName={DriverName}
          DriverValues={DriverValues}
          TotalOverAllCount={TotalOverAllCount}
        />





        {/* <ReactEcharts
            style={{height:size===2?"260px":"230px"}}
            option={{
              series: [{
                  type: 'gauge',
                  min:-100,
                  max:100,
                  axisLine: {
                      lineStyle: {
                          width: 15,
                          color: [
                              [0.6, '#ff4d4d'],
                              [0.8, '#ff9933'],
                              [1, '#00cc99']
                          ]
                      }
                  },
                  pointer: {
                      itemStyle: {
                          color: 'auto'
                      }
                  },
                  axisTick: {
                      distance: -30,
                      length: 8,
                      lineStyle: {
                          color: '#fff',
                          width: 2
                      }
                  },
                  splitLine: {
                      distance: -30,
                      length: 30,
                      lineStyle: {
                          color: '#fff',
                          width: 4
                      }
                  },
                  axisLabel: {
                      color: 'auto',
                      distance: 25,
                      fontSize: 10
                  },
                  detail: {
                      valueAnimation: false,
                      formatter: '{value}',
                      color: '#757575',
                      fontSize: 0

                  },
                  data: [{
                      value: npsScore
                  }]
              }]
          }} 
                            
          />*/}
        {/* <div className="text-xl font-bold text-center -mt-14">{npsScore}</div> */}


        {/* {size===2?
                    <div>
                      <BarSet NPSObj={NPSObj}/>
                    </div>
              :null} */}

        {/* {size!==2?
          
          
          <div className="flex items-center justify-between w-5/6 mx-auto mt-4">
          
            <div className="flex flex-col items-center justify-center text-xs">
              <span className="mb-2 text-green-500 material-symbols-outlined" style={{fontSize: '1.6rem'}}>thumb_up</span>
              <p className="text-sm font-medium text-gray-600">Promoters </p>
              <h1 className="text-sm ">({NPSObj["PromotersPer"]}%)</h1></div>
            <span className="text-3xl font-semibold text-gray-500">- </span>

            <div className="flex flex-col items-center justify-center text-xs"><span className="mb-2 text-red-500 material-symbols-outlined" style={{fontSize: '1.6rem'}}>thumb_down_off_alt</span> <p className="text-sm font-medium text-gray-600">Detractors </p>
              <h1 className="text-sm">({NPSObj["DistractorsPer"]}%)</h1></div>
            <span className="text-xl font-semibold text-gray-500">= </span>
            <div> <span className="inline-flex items-center justify-center p-2 font-bold text-gray-800 bg-green-400 rounded-full w-14 h-14 ">{npsScore}</span></div>
          </div>



        :null} */}





      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(NPSChart);
