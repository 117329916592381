import React from "react";
import moment from "moment";

export default function AssessmentScore(props) {
  let {
    getAssessmentReportByEmpIDData,
    crrSelectedEmp,
    EditDetailsFn,
    EditButtonFn,
    createdAt,
    hindiPDFstatic
  } = props;

  const cirleColor = (element) => {
    return (
      <span
        style={{
          background:
            element && element.colorCode ? element.colorCode : "#DDDDDD",
        }}
        className={"w-5 h-5 rounded-full "}
      />
    );
  };

  const barWidthColor = (element) => {
    return (
      <span
        style={{
          background:
            element && element.colorCode ? element.colorCode : "#DDDDDD",
        }}
        className={"w-5 h-5 rounded-full flex-shrink-0"}
      />
    );
  };

  const shortSummary = (element, index) => {
    //console.log("element--->",element)

    return (
      <div className="flex items-start space-x-4 px-2">
        {barWidthColor(element)}

        <div className="flex">
          <p className="text-base font-normal text-[#3D405B] leading-5 ">
            <b className="pr-2 text-[#3D405B]">
              {(index + 1).toString() + ". "}
              {EditDetailsFn(element, "dimension", element.dimension)}:
            </b>
            {/* {element &&
            element.details &&
            element.details.desc
              ? element.details.desc
              : ""} */}
            {EditDetailsFn(element, "desc", element.details.desc)}
          </p>
        </div>
      </div>
    );
  };

  let textList1 = [
    "Read every word of the report. Reflect on it and understand your uniqueness. Think about a time when your talent was in use and how it affected your performance. Keep making notes on the same.",
    "Focus on the Dominant themes as it has a huge influence on your behaviour. It not only allows you to achieve more but also creates your personal brand. People see your behaviour but you can now see from where this behaviour comes and thus help you create an authentic perception of yourself.",
    "You should also give attention to your non-dominant talent themes as it allows you to understand where you will need to partner with someone and what are some areas you might not be naturally gifted.",
    "Once you understand your inherent talent, try planning to use it proactively for your next task and see how it helps you be better at it.",
    "In Case you want more insights and clarity, feel free to write to us at contact@happyplus.in so that we can set up a coaching call with you to explain/clarify questions you have.",
  ];

  let textList2 = [
    "Keep your Dominant talents in front of you & before you undertake any task, think of the ways you are going to use it.",
    "Share the report with your friends and close ones and ask them to reflect it with you.",
    "Share it with your manager to help them understand what makes you tick and help you leverage it much more.",
    "Share it with your colleagues so that they can leverage unique talents which you bring on table.",
  ];



  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }



  return (
    <>
      <main ref={hindiPDFstatic[1]} className="bg-white py-10 space-y-10">
        <section className=" flex flex-col items-center justify-between   space-y-10">
          <div className="space-y-8 text-center flex flex-col items-center">
            <div className="flex justify-center pt-2">
              <img src="/img/logo/happypluslogo.png" className="  w-auto" />
            </div>
            <div className="space-y-4 text-center py-2">
              <h1 className="text-4xl text-[#3D405B] font-normal ">
                {EditButtonFn("page_1_title_1_1", "TEEN TALENTPLUSSTRENGTHS")}
                <br/>
                {EditButtonFn("page_1_title_1_2", "ASSESSMENT REPORT")}
              </h1>
              <h1 className="text-xl text-sky-500 font-medium capitalize ">
                {crrSelectedEmp && crrSelectedEmp.name
                  ? crrSelectedEmp.name
                  : ""}{" "}
                | {createdAt}
              </h1>
            </div>
            <div className="flex justify-center pt-6">
              <img
                src="/img/teen_assessment_report.png"
                className="w-full  mx-auto"
              />
            </div>
            <div className="text-center">
              <p className="text-base text-[#3D405B] font-medium">
                {EditButtonFn(
                  "page_1_footer_text_1",
                  "HappyPlus Online Assessments © 2024"
                )}
              </p>
              <p className="text-sm text-[#3D405B]/70">
                {EditButtonFn(
                  "page_1_confidential_text_1",
                  "This report is confidential and should not be distributed without permission"
                )}
              </p>
            </div>
          </div>
        </section>
      </main>

      <main ref={hindiPDFstatic[2]} className="bg-white p-10 px-6 space-y-6 w-full" style={{ width: '100%' }}>
        <div className="space-y-6 page-break w-full " style={{ width: '100%' }}>
          <div className="flex items-center w-full justify-between  border-b py-4 ">
            <h1 className="text-3xl font-medium text-[#3D405B]">
              {EditButtonFn(
                "TalentAssessmentReport",
                "Teen TalentPlusStrengths Assessment Report"
              )}
              {/* Talent Assessment Report */}
            </h1>
            <p className="text-lg font-semibold text-[#3D405B]">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}
            </p>
          </div>

          <div className="space-y-4 w-full" style={{ width: '100%' }}>
            <h1 className="text-3xl font-medium text-[#3D405B]">
              {/* Your Teen TalentPlusStrengths Assessment Report  */}
              {EditButtonFn(
                "page_2_title_2",
                "Your Teen TalentPlusStrengths Assessment Report"
              )}
            </h1>

            <p className="w-full" style={{ width: '100%' }}>
              {EditButtonFn(
                "page_2_desc_1",
                "Talents are our natural ways of thinking, feeling, and behaving. They define how we do what we do. Talents are core to our being. Successful people know their talents and are able to utilise it beautifully for the work they do and success they achieve. Knowing your talents allows you to experience flow at work and produces superior performance. Your talents make you unique and allow you to stand out amongst others. It also makes you authentic and allows you to lead a fulfilling and happy life by being who you are and not trying to be someone else. "
              )}

              <br />
              <br />
              {EditButtonFn(
                "page_2_desc_2",
                "Based on your response to the Teen TalentPlusStrengths assessment, this report has been created for you. Idea is that you go through the result, reflect on the same and be aware of your uniqueness. Once aware, you can start utilising it proactively to add appropriate skills & knowledge to do anything you want to do in life. It also will help you understand how you think, relate, act and lead in your life. All these four buckets are the basic structure of making you successful."
              )}
            </p>

            <div className="w-full space-y-1 page-break">
              <h1 className="text-3xl font-light text-[#3D405B]">
                {/* <b>{"How to go through this report:"}</b> */}
                <b>
                  {EditButtonFn(
                    "page_2_title_3",
                    "How to go through this report:"
                  )}
                </b>
              </h1>
            </div>

            <div className="text-base space-y-4">
              {textList1 && textList1.length > 0
                ? textList1.map((text, index6) => (
                  <>
                    <div className="flex">
                      <img
                        src={"/img/icon/dot.png"}
                        className="w-2 h-2 rounded-full bg-gray-900 mt-2"
                      />
                      <p className="mx-2">
                        {/* {text} */}
                        {EditButtonFn(
                          "textList1" + (index6 + 1).toString(),
                          text
                        )}
                      </p>
                    </div>
                  </>
                ))
                : null}
            </div>

            <div className="w-full space-y-1 page-break">
              <h1 className="text-3xl font-light text-[#3D405B]">
                {/* <b>{"How to use this report:"}</b> */}
                <b>
                  {EditButtonFn("page_2_title_4", "How to use this report:")}
                </b>
              </h1>
            </div>

            <div className="text-base space-y-4">
              {textList2 && textList2.length > 0
                ? textList2.map((text, index6) => (
                  <>
                    <div className="flex">
                      <img
                        src={"/img/icon/dot.png"}
                        className="w-2 h-2 rounded-full bg-gray-900 mt-2"
                      />
                      <p className="mx-2">
                        {/* {text} */}
                        {EditButtonFn(
                          "textList2" + (index6 + 1).toString(),
                          text
                        )}
                      </p>
                    </div>
                  </>
                ))
                : null}
            </div>
          </div>
        </div>
      </main>

      <main ref={hindiPDFstatic[3]} className="bg-white p-6 px-6 space-y-6 page-break">
        <div className="space-y-6 page-break ">
          <div className="flex items-center w-full justify-between  border-b py-4 ">
            <h1 className="text-3xl font-medium text-[#3D405B]">
              {EditButtonFn(
                "TalentAssessmentReport",
                "Teen TalentPlusStrengths Assessment Report"
              )}
            </h1>
            <p className="text-lg font-semibold text-[#3D405B]">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}
            </p>
          </div>

          <div className="w-full space-y-1 page-break">
            <h1 className="text-3xl font-light text-[#3D405B]">
              {/* <b>{"4 Domains of Teen TalentPlusStrengths themes: "}</b> */}
              <b>
                {EditButtonFn(
                  "domain_4_text",
                  "Domains of Teen TalentPlusStrengths themes: "
                )}
              </b>
            </h1>
          </div>

          <div className="w-full bg-[#F5F5F5] p-4 space-y-4">

            <div className="flex items-start space-x-2 space-y-2 w-full">
              <div className="text-md">
                <div class="text-base font-bold mr-2 flex items-center">
                  <p class="w-4 h-4  mr-2 mb-1 text-lg bg-[#673AB7] text-[#3D405B]"></p>
                  {EditButtonFn("LEARNINGTALENTS", "LEARNING TALENTS")}
                </div>

                <p className="">
                  {EditButtonFn(
                    "LEARNINGDESC",
                    "Learning talents encompass intellectual abilities, emphasizing deep thinking, exploring diverse themes, and strategic planning for the future. Individuals possessing these talents demonstrate a keen focus on intellectual pursuits, navigating complexities, and analyzing information to make informed decisions, using their ability to deliberate on ideas as a strategic pathway for continuous learning and growth."
                  )}
                </p>
              </div>
            </div>
            <br />

            <div className="flex items-start space-x-2 space-y-2 w-full">
              <div className="text-md">
                <div class="text-base font-bold mr-2 flex items-center">
                  <p class="w-4 h-4  mr-2 mb-1 text-lg bg-[#ffc107] text-[#3D405B]"></p>
                  {EditButtonFn("THINKINGTALENTS", "THINKING TALENTS")}
                </div>

                <p className="">
                  {EditButtonFn(
                    "THINKINGDESC",
                    "These are the talents which define your way of thinking and making a sense of the world. Using these talents, you deliberate on ideas, possibilities, and next course of action. This theme deals with intellectual abilities which is the hallmark of human progress. Thinking about themes allows us to explore our past and gaze into the future so that we can plan things in the present. Thinking talents are the strategic pathway to our future."
                  )}
                </p>
              </div>
            </div>
            <br />
            <div className="flex items-start space-x-2 space-y-2 w-full">
              <div className="text-md">
                <div class="text-base font-bold mr-2 flex items-center">
                  <p class="w-4 h-4 mr-2 mb-1 text-lg bg-[#4caf50] text-[#3D405B]"></p>
                  {EditButtonFn("RELATINGTALENTS", "RELATING TALENTS")}
                </div>

                <p className="">
                  {EditButtonFn(
                    "RELATINGDESC",
                    "Human beings are social animals. We thrive with others. We need others in life to share our successes, miseries, and our condition. The ability to connect with others is what defines the possibilities of our success in life. It is the bond between one person and another, which takes us higher and ensures we come together to achieve the goals we set for ourselves, challenges we want to overcome and write history together."
                  )}
                </p>
              </div>
            </div>
            <br />
            <div className="flex items-start space-x-2 space-y-2 w-full">
              <div className="text-md">
                <div class="text-base font-bold mr-2 flex items-center">
                  <p class="w-4 h-4 mr-2 mb-1 text-lg bg-[#2196f3] text-[#3D405B]"></p>
                  {EditButtonFn("ACTINGTALENTS", "ACTING TALENTS")}
                </div>

                <p className="">
                  {EditButtonFn(
                    "ACTINGDESC",
                    "These are action talents, which allow you to make things happen. Ideas are good but the real progress takes place when we put these ideas to action. The acting talent allows us to put our thoughts into action and see the magic happen. It is these themes which make things happen and lets everyone see the progress happening. Our acting talents ensure that we make progress and finish tasks and achieve things consistently."
                  )}
                </p>
              </div>
            </div>
            <br />
            {/* <div className="flex items-start space-x-2 space-y-2  w-full">
              <p className="text-md">
                <div class="text-base font-bold mr-2 flex items-center">
                  <p class="w-4 h-4 mr-2 mb-1 text-lg bg-[#673ab7] text-[#3D405B]"></p>
                  {EditButtonFn("LEADINGTALENTS", "LEADING TALENTS")}
                </div>

                <span className="">
                  {EditButtonFn(
                    "LEADINGDESC",
                    "These are the talents, which defines our leadership. Leadership is not a position but it is a mindset, through which people share their vision with others, get them aligned and motivated and ensure work takes place towards that desired future. Leaders are the people responsible for better a tomorrow, so they not only have to keep looking at the things to come but also make that dream a reality with the help of others."
                  )}
                </span>
              </p>
            </div>

            <br /> */}



            <div className="flex items-start space-x-2 space-y-2  w-full">
              <p className="text-md">
                <div class="text-base font-bold mr-2 flex items-center">
                  <p class="w-4 h-4 mr-2 mb-1 text-lg bg-[#FF9800] text-[#3D405B]"></p>
                  {EditButtonFn("EMOTIONSTALENTS", "EMOTIONS TALENTS")}
                </div>

                <span className="">
                  {EditButtonFn(
                    "EMOTIONSDESC",
                    "Emotion talents, encapsulating the Excitable, Circumspect, and Non-Reactive themes, provide individuals with versatile approaches to engaging and expressing emotions. Recognizing and utilizing these emotional themes can improve interpersonal relationships, facilitating effective communication and collaboration. Striking a balance among these talents enables individuals to navigate a broad spectrum of emotional expressions, adapting to diverse situations with emotional intelligence and resilience."
                  )}
                </span>
              </p>
            </div>

            <br />
            <div className="flex items-start space-x-2 space-y-2  w-full">
              <p className="text-md">
                <div class="text-base font-bold mr-2 flex items-center">
                  <p class="w-4 h-4 mr-2 mb-1 text-lg bg-[#00BCD4] text-[#3D405B]"></p>
                  {EditButtonFn("COMMUNICATINGTALENTS", "COMMUNICATING TALENTS")}
                </div>

                <span className="">
                  {EditButtonFn(
                    "COMMUNICATINGDESC",
                    "Communicating talent involves adeptly conveying thoughts and ideas, utilizing various communication styles to connect with others effectively. This talent extends beyond linguistic skills, encompassing active listening, understanding diverse perspectives, and adapting communication strategies for successful interactions in both personal and professional settings."
                  )}
                </span>
              </p>
            </div>




          </div>
        </div>
      </main>

      <main ref={hindiPDFstatic[4]} className="bg-white p-6 px-6 space-y-6 page-break">
        <div className="space-y-6 page-break ">
          <div className="flex items-center w-full justify-between  border-b py-4">
            <h1 className="text-3xl font-medium text-[#3D405B]">
              {EditButtonFn(
                "TalentAssessmentReport",
                "Teen TalentPlusStrengths Assessment Report"
              )}
            </h1>
            <p className="text-lg font-semibold text-[#3D405B]">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}
            </p>
          </div>

          <div className="flex justify-between items-center">
            <div className="flex space-x-2  border  p-2 rounded-full">
              {getAssessmentReportByEmpIDData &&
                getAssessmentReportByEmpIDData.length > 0
                ? getAssessmentReportByEmpIDData.map((element, index) =>
                  index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1 ? cirleColor(element) : null
                )
                : null}
            </div>

            <div className="flex space-x-2 border  p-2 rounded-full ">
              {getAssessmentReportByEmpIDData &&
                getAssessmentReportByEmpIDData.length > 0
                ? getAssessmentReportByEmpIDData.map((element, index) =>
                  getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1 <= index + 1 && index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2
                    ? cirleColor(element)
                    : null
                )
                : null}
            </div>

            <div className="flex space-x-2 border  p-2 rounded-full ">
              {getAssessmentReportByEmpIDData &&
                getAssessmentReportByEmpIDData.length > 0
                ? getAssessmentReportByEmpIDData.map((element, index) =>
                  getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 <= index + 1 ? cirleColor(element) : null
                )
                : null}
            </div>
          </div>

          <div className="flex space-x-6">
            <div className="w-full divide-y divide-dashed  ">
              <div className="text-base font-normal py-2">
                <h1 className="text-md text-[#3D405B] font-medium">
                  {EditButtonFn(
                    "YourTop5Teen TalentPlusStrengthstheme",
                    "Your Dominant Teen TalentPlusStrengths themes (Broadly they define who you are, what makes you successful, they need to be understood, nurtured and used regularly to experience a thriving life)"
                  )}
                </h1>
              </div>
              <div className="flex w-full py-4">
                {/* <img src={"/img/assessment/top5.png"} className="w-10" /> */}
                <div className="space-y-2 w-full">
                  {getAssessmentReportByEmpIDData &&
                    getAssessmentReportByEmpIDData.length > 0
                    ? getAssessmentReportByEmpIDData.map((element, index) =>
                      index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1 ? (
                        <>{shortSummary(element, index)}</>
                      ) : null
                    )
                    : null}
                </div>
              </div>

              <div className="text-base font-normal py-2">
                <h1 className="text-md text-[#3D405B] font-medium">
                  {EditButtonFn(
                    "YourNext11Teen TalentPlusStrengthstheme",
                    "Your Moderate Teen TalentPlusStrengths Themes (This defines talents which are there and can be drawn when required. Once you have mastered the moderate talents, these talents too can be developed further)"
                  )}
                </h1>
              </div>
              <div className="flex w-full py-4">
                {/* <div className=" flex items-center justify-center bg-slate-100 w-10 flex-col  ">
                <p className="text-base" style={{ writingMode: "vertical-lr" }}>
                  Mid
                </p>
              </div> */}
                {/* <img src={"/img/assessment/middle.png"} className="w-10" /> */}
                <div className="space-y-2 w-full">
                  {getAssessmentReportByEmpIDData &&
                    getAssessmentReportByEmpIDData.length > 0
                    ? getAssessmentReportByEmpIDData.map((element, index) =>
                      getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1 <= index + 1 && index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2 ? (
                        <>{shortSummary(element, index)}</>
                      ) : null
                    )
                    : null}
                </div>
              </div>

              <div className="text-base font-normal py-2">
                <h1 className="text-md text-[#3D405B] font-medium">
                  {EditButtonFn(
                    "YourLast5Teen TalentPlusStrengthstheme",
                    "Your Non-Dominant Teen TalentPlusStrengths Themes (These are the talents, which are there but not much & you may need to learn how to manage it to achieve your goals in life)"
                  )}
                </h1>
              </div>
              <div className="flex w-full py-4">
                {/* <div className=" flex items-center justify-center bg-slate-100 w-10 flex-col  ">
                <p className="text-base" style={{ writingMode: "vertical-lr" }}>
                  Bottom 5
                </p>
              </div> */}
                {/* <img src={"/img/assessment/bottom5.png"} className="w-10" /> */}
                <div className="space-y-2 w-full">
                  {getAssessmentReportByEmpIDData &&
                    getAssessmentReportByEmpIDData.length > 0
                    ? getAssessmentReportByEmpIDData.map((element, index) =>
                      getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 <= index + 1 ? (
                        <>{shortSummary(element, index)}</>
                      ) : null
                    )
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <p>Copyright © 2024, HappyPlus, Inc. All rights reserved</p> */}
      </main>
    </>
  );
}
