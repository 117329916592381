import React, { Component } from 'react';
import { connect } from 'react-redux';
import DetailedView from './Components/Overview/DetailedView';


class ManagerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerDetailsIndexType: 0,
      showManagerList: true,

      mgrIndexA: 0,
      mgrIndexB: 8,
      CurrentManagerName: '',
      CurrentManagerId: '',
      dynamicList: [],

      optionTypeM: 101,

      CurrentManagerData: '',

      showCircle: true,

      showAssign: false,

      crrManagersList: [],


      crrManagerName: 'none',
      crrMangerId: '',
      showMultiple: false,

      AllSelected: false,

      managerSearchName: '',
      managerCollectionList: [],

      DimensionList: [
        {
          index: 1,
          name: 'Happiness',
          cutOff: 59
        },
        {
          index: 2,
          name: 'Engagement',
          cutOff: 78

        },
        {
          index: 4,
          name: 'Stress',
          cutOff: 28

        },
        {
          index: 5,
          name: 'Manager',
          cutOff: 50
        },
        // {
        //   index:6,
        //   name:'Leader',
        //   cutOff:50
        // },
        // {
        //   index:8,
        //   name:'OrgCore',
        //   cutOff:59
        // },
      ],
      showViewBy: false

    }
  }

  componentDidMount() {

  }




  handleViewType = (data) => {
    this.setState({ showCircle: data });

  }

  goPrev = () => {
    let { getDemographicIndexForManagerData } = this.props;
    let { mgrIndexA, mgrIndexB } = this.state;
    if (getDemographicIndexForManagerData && getDemographicIndexForManagerData.length > 0) {
      if (mgrIndexA > 0) {
        this.setState({ mgrIndexA: mgrIndexA - 1, mgrIndexB: mgrIndexB - 1 })
      }

    }

  }
  goNext = () => {
    let { getDemographicIndexForManagerData } = this.props;
    let { mgrIndexA, mgrIndexB } = this.state;
    if (getDemographicIndexForManagerData && getDemographicIndexForManagerData.length > 0) {
      if (mgrIndexB < getDemographicIndexForManagerData.length - 1) {
        this.setState({ mgrIndexA: mgrIndexA + 1, mgrIndexB: mgrIndexB + 1 })

      }
    }
  }


  SelectManager = (data, ManagerName1) => {
    let { handleManagerHeatmap, EmpFilterData } = this.props;
    let { optionTypeM } = this.state;

    // this.setState({CurrentManagerName:data.name, CurrentManagerId:data.ManagerPID, CurrentManagerData:data})
    this.setState({ dynamicList: [] })
    // DynaObj=[]


    if (EmpFilterData && EmpFilterData.length > 0) {
      // handleManagerHeatmap({
      //   level:'ManagerPID',
      //   value:data['ID']
      // });
      EmpFilterData.forEach((filter) => {

        if (data && data[filter.name]) {
          let filterdata = {
            level: filter.name,
            value: data[filter && filter.name === "ManagerPID" ? "id" : filter.name]
          }
          // //console.log('filterdata: ',filterdata);
          handleManagerHeatmap(filterdata);
        }

      })
    }


  }

  handleOption = (optionTypeM, managerPID, managerData, ManagerName1) => {
    let { crrManagersList } = this.state;
    if (optionTypeM === 101 || optionTypeM === 102) {
      this.setState({ showAssign: false });
    }


    if (crrManagersList && crrManagersList.length > 0) {
      this.setState({ showViewBy: false });
    }
    else {

      if (this.state.optionTypeM === 101 || this.state.optionTypeM === 102) {
        this.setState({ showViewBy: true });
      }
      else {
        this.setState({ showViewBy: true });
      }

    }


    this.setState({ optionTypeM: optionTypeM });

    this.setState({ managerCollectionList: [] });

    // if(true){
    //   if(crrManagersList.findIndex(prev=>prev===managerData)===-1){
    //     crrManagersList.push(managerData);
    //     this.setState({crrManagersList});
    //   }
    // }


    this.setState({ CurrentManagerId: managerPID, CurrentManagerData: managerData, CurrentManagerName: ManagerName1 })


    let { handleManagerQuestion, handleManagerDashboardCall, handleMultiManagerWiseDriver, getDemographicIndexForManagerData, handleManagerWiseDriver, EmpFilterData, handleManagerListIndexQuestionCall, QuesDynaObj, ManagerListQuestionDataObj } = this.props;



    if (optionTypeM !== 101 && optionTypeM !== 102) {
      let { crrManagersList } = this.state;
      this.setState({ showMultiple: true });

      for (let i = 0; i < 2; i++) {

        if (crrManagersList && crrManagersList.length > 0) {
          crrManagersList.forEach((mngr) => {
            handleMultiManagerWiseDriver(optionTypeM, mngr);

          });
        }
      }
    }



  }


  handleManagerQuestion = (optionType) => {
    let { CurrentManagerId, CurrentManagerData } = this.state;
    this.handleOption(optionType, CurrentManagerId, CurrentManagerData)
  }

  handleOptionThird = (check, validateList, managerPID, managerData, ManagerName1) => {
    if (check) {
      if (validateList && validateList.length > 0) {
        let template = validateList[0].template;
        this.handleOption(template, managerPID, managerData, ManagerName1)

      }

    }
    this.setState({ showAssign: check })
  }


  handleManagerDriver = (Mindextype) => {
    let { handleManagerWiseDriver, EmpFilterData } = this.props;

    let { CurrentManagerId } = this.state;

    if (EmpFilterData && EmpFilterData.length > 0) {
      handleManagerWiseDriver(Mindextype, CurrentManagerId, "ManagerPID");
      EmpFilterData.forEach((emp) => {
        handleManagerWiseDriver(Mindextype, CurrentManagerId, emp.name);
      })
    }


  }

  openDropdown = (check) => {
    this.setState({ showViewBy: !check });
  }



  handleManagerALLSelection = () => {
    let { AllSelected, crrManagersList } = this.state;
    if (AllSelected) {
      this.setState({ crrManagersList: [] });
      this.setState({ AllSelected: false });

    }
    else {
      let { dashboard } = this.props;
      let { getDemographicIndexForManagerData } = dashboard;
      if (getDemographicIndexForManagerData && getDemographicIndexForManagerData.length > 0) {
        getDemographicIndexForManagerData.forEach((mngr) => {
          crrManagersList.push(mngr);
        });
      }
      this.setState({ crrManagersList, AllSelected: true });
    }


    let defaultMngr = [(this.prop.dashboard && this.prop.dashboard.sgetDemographicIndexForManagerData && this.prop.dashboard.getDemographicIndexForManagerData.length > 0) ? this.prop.dashboard.getDemographicIndexForManagerData[0] : {}]

  }




  handleManagerSelection = (mngr) => {
    //////console.log("-------------------->mngr ",mngr)
    let { crrManagersList } = this.state;
    this.setState({ AllSelected: false });

    let getIndex = crrManagersList.findIndex(prev => prev.emp_id === mngr.emp_id);
    if (getIndex === -1) {
      crrManagersList.push(mngr);

    }
    else {
      crrManagersList.splice(getIndex, 1);
    }
    this.setState({ crrManagersList });



  }

  handleManagersCall = (optionTypeM) => {
    this.setState({ managerCollectionList: [] });

    let { crrManagersList } = this.state;
    if (crrManagersList && crrManagersList.length > 1) {
      this.setState({ showMultiple: true });
    }
    else {
      this.setState({ showMultiple: false });
    }
    let { handleManagerDashboardCall } = this.props;
    let { handleManagerQuestion, handleMultiManagerWiseDriver, handleManagerWiseDriver, EmpFilterData, handleManagerListIndexQuestionCall, QuesDynaObj } = this.props;





    if (optionTypeM !== 101 && optionTypeM !== 102) {
      if (crrManagersList && crrManagersList.length > 0) {
        if (crrManagersList.length === 1 && false) {

          // if(EmpFilterData && EmpFilterData.length>0){
          //   EmpFilterData.forEach((emp)=>{

          //     let data = {
          //       template:optionTypeM,
          //       filters:[
          //         {
          //           "level":emp.name,
          //           "value":crrManagersList[0][emp&&emp.name==="ManagerPID"?"id":emp.name]
          //         }
          //       ]
          //     }
          //     handleManagerQuestion(data);
          //   })
          // }

          for (let i = 0; i < 2; i++) {
            if (EmpFilterData && EmpFilterData.length > 0) {
              handleManagerWiseDriver(optionTypeM, crrManagersList[0].id, "ManagerPID");
              EmpFilterData.forEach((emp) => {
                handleManagerWiseDriver(optionTypeM, crrManagersList[0].id, emp.name);
              })
            }
          }

          this.setState({ showMultiple: false, CurrentManagerData: crrManagersList[0] });

        }
        else {
          this.setState({ showMultiple: true });
          for (let i = 0; i < 2; i++) {

            if (crrManagersList && crrManagersList.length > 0) {
              crrManagersList.forEach((mngr) => {

                // handleManagerListIndexQuestionCall(optionTypeM,mngr);

                handleMultiManagerWiseDriver(optionTypeM, mngr);

              });
            }
          }


        }

      }

    }
    else {
      // if(crrManagersList && crrManagersList.length>0){

      //   if(crrManagersList.length===1&& false){
      //   }
      //   else{
      //     crrManagersList.forEach((mngr)=>{
      //       handleManagerDashboardCall(mngr);
      //     });
      //   }

      // }
    }















    this.setState({ showViewBy: false });

  }




  getReportMngrQuestion = (mngr, template) => {
    let { EmpFilterData, handleManagerQuestion } = this.props;
    if (EmpFilterData && EmpFilterData.length > 0) {
      // handleManagerQuestion({
      //   template:template,
      //   filters:[
      //     {
      //       "level":"ManagerPID",
      //       "value":mngr["ManagerPID"]
      //     }
      //   ]
      // });
      EmpFilterData.forEach((emp) => {

        let data = {
          template: template,
          filters: [
            {
              "level": emp.name,
              "value": mngr[emp && emp.name === "ManagerPID" ? "id" : emp.name]
            }
          ]
        }
        handleManagerQuestion(data);
      });
    }
  }

  handleSearchManager = (e) => {
    this.setState({ managerSearchName: e.target.value });
  }


  CollectDataManagerForActionPlan = (data) => {
    let { managerCollectionList } = this.state;
    let getIndex = managerCollectionList.findIndex(prev => prev.code === data.code);
    if (getIndex === -1) {
      managerCollectionList.push(data)
    }
    else {
      managerCollectionList.splice(getIndex, 1);
    }
    this.setState({ managerCollectionList });

  }


  render() {
    let { getIDPLastDateData, getAllManagerActionPlanData, getDemographicIndexHappinessData, getDemographicIndexEngagementData, getDemographicIndexManagerData, indexType, getCommentData, getIndexData, getDemographicIndexForManagerData, getManagerListDomainFilterData, ManagerIndexDriverData,
      ManagerDynaObj, EmpFilterData, getIndexFilterData, QuesDynaObj, ManagerIndexQuestionData,

      ManagerListDasboardDataObj,
      ManagerListQuestionDataObj,

      QuesMngrHappinessReportObject,
      QuesMngrEngagementReportObject,
      QuesMngrStressReportObject,
      QuesMngrManagerReportObject,
      getIndexByFilterDMGData
    } = this.props;

    let { managerSearchName, crrManagerName, crrMangerId, crrManagersList, showViewBy,
      managerCollectionList, showAssign

    } = this.state;

    let {

      mgrIndexA,
      mgrIndexB,

      CurrentManagerName,
      CurrentManagerId,

      dynamicList,


      showManagerScore,
      showEngagement,
      showHappiness,
      optionTypeM,

      CurrentManagerData,

      showCircle

    } = this.state;


    // dynamicList.push(getManagerListDomainFilterData)

    // //console.log('crrManagersList: ',crrManagersList)
    // //console.log('----------crrManagersList---------------------------------->: ',crrManagersList)

    // //console.log('dynamicList: ',dynamicList)

    let DefaultManagerId = '';
    let DefaultManagerName = '';

    let IndexForManagerData = {}
    let IndexData = {}


    function getIndexName(key) {
      let name = ''
      if (key === 1) { name = 'Happiness' }
      else if (key === 2) { name = 'Engagement' }
      else if (key === 3) { name = 'Hope' }
      else if (key === 4) { name = 'Stress' }
      else if (key === 5) { name = 'Manager' }
      else if (key === 8) { name = 'Organization' }

      return name;
    }

    let IndexList = []
    let IndexLabels = []
    if (getIndexData) {
      IndexList = Object.keys(getIndexData).map((key) => [Number(key), getIndexData[key]]);
    }
    if (IndexList && IndexList.length > 0) {
      IndexList.forEach((item) => {

        if (item[1]) {
          IndexData[item[0]] = (item[1]) ? (item[1]).toFixed(2) : 'NaN';
        }

        if (getIndexName(item[0])) {
          IndexLabels.push({
            indexType: item[0],
            indexName: getIndexName(item[0])
          });
        }
      })
    }
    this.state.FilterList = IndexLabels



    if (getDemographicIndexForManagerData && getDemographicIndexForManagerData.length > 0) {
      if (!CurrentManagerId) {
        DefaultManagerId = getDemographicIndexForManagerData[0].ManagerPID;
        DefaultManagerName = getDemographicIndexForManagerData[0].name;

        if (IndexList && IndexList.length > 0) {
          IndexList.forEach((item) => {
            IndexForManagerData[item[0]] = getDemographicIndexForManagerData[0][item[0]];
          })
        }
      }
      else {
        getDemographicIndexForManagerData.forEach((mgr) => {

          if (mgr.ManagerPID === CurrentManagerId) {
            if (IndexList && IndexList.length > 0) {
              IndexList.forEach((item) => {
                IndexForManagerData[item[0]] = mgr[item[0]];
              })
            }
          }

        })
      }
    }


    let managerPID = '';
    let managerData = '';
    let managerNewName = '';

    if (getDemographicIndexForManagerData && getDemographicIndexForManagerData.length > 0) {
      if (!CurrentManagerId) {
        managerPID = getDemographicIndexForManagerData[0].id;
        managerData = getDemographicIndexForManagerData[0];
        managerNewName = getDemographicIndexForManagerData[0].name;

      }
      else {
        managerPID = CurrentManagerId;
        managerData = CurrentManagerData;
        managerNewName = CurrentManagerName
      }
    }



    // //console.log('managerPID: ',managerPID);
    // //console.log('managerData: ',managerData);
    // //console.log('managerNewName: ',managerNewName);
    // //console.log('getDemographicIndexForManagerData: ',getDemographicIndexForManagerData);

    // //console.log('IndexLabels: ',IndexLabels);



    let { GetParameterLabel, AssignManagerActionPlan, DynaObj, getQuestionData, getQuestionReportData, tempList, loading, getQuestionOverallData } = this.props;


    let ManagerName1 = ''
    if (CurrentManagerName) {
      ManagerName1 = CurrentManagerName
    }
    else {
      ManagerName1 = DefaultManagerName
    }




    let selectedManagerName = '';
    let selectedManagerId = '';

    // if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
    //   if(crrManagerName && crrMangerId){
    //     selectedManagerName = crrManagerName;
    //     selectedManagerId = crrMangerId;
    //   }
    //   else{
    //     selectedManagerName = getDemographicIndexForManagerData[0].name;
    //     selectedManagerId = getDemographicIndexForManagerData[0].id;
    //   }
    //   //------
    // }
    let { showMultiple } = this.state;


    let getDemographicDriverData = []
    if (optionTypeM === 1) {
      getDemographicDriverData = ManagerIndexDriverData;
    }
    else if (optionTypeM === 2) {
      getDemographicDriverData = ManagerIndexDriverData;
    }
    else if (optionTypeM === 4) {
      getDemographicDriverData = ManagerIndexDriverData;
    }
    else if (optionTypeM === 5) {
      getDemographicDriverData = ManagerIndexDriverData;
    }
    // //console.log('zzzzzzzzzzz - getDemographicDriverData: ',getDemographicDriverData)

    let DemographicDriverManagerData = [];
    let temShowMultiple = false;
    if (crrManagersList && crrManagersList.length > 0) {
      if (ManagerIndexDriverData && ManagerIndexDriverData.length > 0) {
        ManagerIndexDriverData.forEach((item) => {
          crrManagersList.forEach((mngr) => {
            if ((item.name).toString() === (mngr.id).toString()) {
              let tempObj = {
                ...item,
                id: (mngr.id).toString()
              }
              tempObj["name"] = mngr.name;
              DemographicDriverManagerData.push(tempObj);
            }
          })
        });
      }
      temShowMultiple = true;
    }
    else {
      temShowMultiple = true;
    }

    // //console.log('zzzzzzzzzzzzz = crrManagersList: ',crrManagersList)
    // //console.log('zzzzzzzzzzzzz = ManagerIndexDriverData: ',ManagerIndexDriverData)
    // //console.log('zzzzzzzzzzzzz = DemographicDriverManagerData: ',DemographicDriverManagerData)




    // //console.log('DemographicDriverManagerData: ',DemographicDriverManagerData);

    //console.log('//////////////////crrManagersList: ',crrManagersList)
    //console.log('//////////////////ManagerIndexDriverData: ',ManagerIndexDriverData)
    //console.log('//////////////////DemographicDriverManagerData: ',DemographicDriverManagerData)



    // //console.log('-------------------------------------------->: ');

    // //console.log('ManagerListDasboardDataObj: ',ManagerListDasboardDataObj);
    // //console.log('ManagerListQuestionDataObj: ',ManagerListQuestionDataObj);

    // //console.log('-------------------------------------------->: ');


    let NEW_ManagerListDasboardDataObj = [];
    let NEW_ManagerListQuestionDataObj = [];

    if (ManagerListDasboardDataObj && ManagerListDasboardDataObj.length > 0) {
      ManagerListDasboardDataObj.forEach((item1) => {
        let getIndex = crrManagersList.findIndex(prev => prev.id === item1.ManagerPID);
        if (getIndex !== -1) {
          NEW_ManagerListDasboardDataObj.push(item1);
        }
      });
    }

    if (ManagerListQuestionDataObj && ManagerListQuestionDataObj.length > 0) {
      ManagerListQuestionDataObj.forEach((item1) => {
        let getIndex = crrManagersList.findIndex(prev => prev.id === item1.ManagerPID);
        if (getIndex !== -1) {
          NEW_ManagerListQuestionDataObj.push(item1);
        }
      });
    }


    // //console.log('-------------------------------------------->: ');

    // //console.log('NEW_ManagerListDasboardDataObj: ',NEW_ManagerListDasboardDataObj);

    // //console.log('-------------------------------------------->: ');
    let DemographicIndexForManagerData = []
    if (getDemographicIndexForManagerData && getDemographicIndexForManagerData.length > 0) {
      getDemographicIndexForManagerData.forEach((item) => {
        DemographicIndexForManagerData.push(item);
      });
    }

    function SortList(list) {
      if (list && list.length > 0) {
        // return list.sort((b,a)=>(a.name-b.name))
        return list.sort((a, b) => a.name.localeCompare(b.name))

      }
      else {
        return [];
      }
    }

    // //console.log('getDemographicIndexForManagerData=>',getDemographicIndexForManagerData)
    // //console.log('DemographicIndexForManagerData=>',DemographicIndexForManagerData)
    let { validateIndex } = this.props;
    let { DimensionList } = this.state;


    let validateList = [];
    let validObj = []
    if (validateIndex) {
      validObj = Object.keys(validateIndex).map((key) => [key, validateIndex[key]])
    }
    //console.log('validObj: ',validObj);
    if (validObj && validObj.length > 0) {
      validObj.forEach((item) => {
        if (item[1]) {
          let getIndex = DimensionList.findIndex(prev => prev.index === parseInt(item[0]));
          if (getIndex !== -1 ? DimensionList[getIndex].name : "") {
            validateList.push({
              "name": getIndex !== -1 ? DimensionList[getIndex].name : "",
              "template": parseInt(item[0])
            });
          }

        }
      })
    }


    let { IndexDimension } = this.props;

    //console.log('crrManagersList: ',crrManagersList)




    let ResponseAction = {
      "assigned": 0,
      "pending": 0,
      "completed": 0
    }



    return (

      <DetailedView indexType={indexType} getCommentData={getCommentData} getIDPLastDateData={getIDPLastDateData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} GetParameterLabel={GetParameterLabel} getAllManagerActionPlanData={getAllManagerActionPlanData} getDemographicIndexForManagerData={getDemographicIndexForManagerData} />

    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ManagerList);
