import React, { Component } from 'react';
import { connect } from 'react-redux';

class ResponseMetaTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { getResponseOverall, getResponseValue, getMetaResponseAnalyticsFilterData,getMetaRespondantDetailsData, litsXAxis } = this.props;


    return (
      <>
   <div className="overflow-x-auto whitespace-nowrap w-full overflow-hidden  mb-4 bg-white p-4">
                            <table className="w-full border ">
                          <thead>
                          <tr className="bg-gray-100 border-b text-sm   text-gray-800 bg-blue-50">
                            <th className="py-2 px-2 border-r font-bold text-left">{litsXAxis}</th>
                            <th className="py-2 px-2 border-r font-bold">Sent</th>
                            <th className="py-2 px-2 border-r font-bold">Clicked</th>
                            <th className="py-2 px-2 border-r font-bold">Not Clicked</th>
                            <th className="py-2 px-2 border-r font-bold">Completed</th>
                            <th className="py-2 px-2 border-r font-bold">Not Completed</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="border-b text-sm   text-gray-800">
                              <td className="py-2 px-2 border-r font-medium w-4/12"><div className="whitespace-pre-line text-left" >{"Overall"}</div></td>
                              <td className="py-2 px-2 border-r font-medium w-1/12"><div className="whitespace-pre-line text-center" >{getResponseOverall(getMetaRespondantDetailsData&&getMetaRespondantDetailsData[0]?getMetaRespondantDetailsData[0]:{},"mailSent")}</div></td>
                              <td className="py-2 px-2 border-r font-medium w-1/12"><div className="whitespace-pre-line text-center" >{getResponseOverall(getMetaRespondantDetailsData&&getMetaRespondantDetailsData[0]?getMetaRespondantDetailsData[0]:{},"clicked")}</div></td>
                              <td className="py-2 px-2 border-r font-medium w-1/12"><div className="whitespace-pre-line text-center" >{getResponseOverall(getMetaRespondantDetailsData&&getMetaRespondantDetailsData[0]?getMetaRespondantDetailsData[0]:{},"notclicked")}</div></td>
                              <td className="py-2 px-2 border-r font-medium w-1/12"><div className="whitespace-pre-line text-center" >{getResponseOverall(getMetaRespondantDetailsData&&getMetaRespondantDetailsData[0]?getMetaRespondantDetailsData[0]:{},"completed")}</div></td>
                              <td className="py-2 px-2 border-r font-medium w-1/12"><div className="whitespace-pre-line text-center" >{getResponseOverall(getMetaRespondantDetailsData&&getMetaRespondantDetailsData[0]?getMetaRespondantDetailsData[0]:{},"notcompleted")}</div></td>
                            </tr>
                            {getMetaResponseAnalyticsFilterData && getMetaResponseAnalyticsFilterData.length>0?
                            getMetaResponseAnalyticsFilterData.map((element,index)=>
                              <tr key={index} className="border-b text-sm   text-gray-800">
                                <td className="py-2 px-2 border-r font-medium w-4/12"><div className="whitespace-pre-line text-left" >{element && element.name?element.name:"-"}</div></td>
                                <td className="py-2 px-2 border-r font-medium w-1/12"><div className="whitespace-pre-line text-center" >{getResponseValue(element.data,"mailSent")}</div></td>
                                <td className="py-2 px-2 border-r font-medium w-1/12"><div className="whitespace-pre-line text-center" >{getResponseValue(element.data,"clicked")}</div></td>
                                <td className="py-2 px-2 border-r font-medium w-1/12"><div className="whitespace-pre-line text-center" >{getResponseValue(element.data,"notclicked")}</div></td>
                                <td className="py-2 px-2 border-r font-medium w-1/12"><div className="whitespace-pre-line text-center" >{getResponseValue(element.data,"completed")}</div></td>
                                <td className="py-2 px-2 border-r font-medium w-1/12"><div className="whitespace-pre-line text-center" >{getResponseValue(element.data,"notcompleted")}</div></td>
                              </tr>
                            ):null}
                            
                          </tbody>
                        </table>
                      </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ResponseMetaTable);
