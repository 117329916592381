import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../_helpers';

//api call
import { dashboardActions, generalActions, questionsActions, surveyActions } from '../../_actions';

//libraries
import LoadingOverlay from 'react-loading-overlay';

//Layout

import Header from '../CommonComponents/DashboardHeader/Header';
import Sidebar from './Sidebar/Sidebar';
import Overview from './Overview//Overview';
import QuestionDetails from '../Dashboard/QuestionDetails/QuestionDetails';

import QuestionCard from '../Dashboard/Overview/Components/QuestionTypeCards/QuestionCard';


class GeneralDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IndexNo: 0,
      CurrentSurveyName: '',
      CurrentSurveyId: this.props.match.params.id,
      // "5fa04d316ede0f0336066cce"
      showOverview: true,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      ItemSaveData: '',
      cardType: '',
      currentQuestionId: '',
      currentQuestionTemplate: '',

      itemSurveyReport: false,
      itemOverview: false,
      itemAllQuestions: false,

      showAllQues: false

    }
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getCompanyNameOnly());
    this.props.dispatch(dashboardActions.getClientProfile());

    // let data1 = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }


    // let data2 = {
    //   "surveyId": this.state.CurrentSurveyId
    // }





    // this.props.dispatch(questionsActions.getQuestionListBySurveyID({"surveyId": this.state.CurrentSurveyId}));

    // this.props.dispatch(dashboardActions.getEmpFilter("getMeanScoreQwise",this.state.CurrentSurveyId));

    // this.props.dispatch(dashboardActions.getClientProfile(data1));

    // this.props.dispatch(generalActions.getSurvey(data1));


    let data2 = {
      "surveyId": this.state.CurrentSurveyId
    }
    // this.props.dispatch(surveyActions.getSurveyById(data2));

    // this.props.dispatch(generalActions.getDashboardCard(data2));

    this.props.dispatch(dashboardActions.getRespondantDetails(data2));


    this.props.dispatch(surveyActions.getSurveyById(data2));



    let data3 = {
      // "surveyId": this.state.CurrentSurveyId,
      "filters": []
    }

    // this.props.dispatch(generalActions.getIndexByFilterDMG(data3));

    // this.props.dispatch(generalActions.getQuestionDistOverall(data3)); 

    // this.props.dispatch(generalActions.getQuestionMatrixResult({surveyId:this.state.CurrentSurveyId,qtemplateList:[13]}));





    // this.props.dispatch(generalActions.getQuestionLiveResponse({surveyId:this.state.CurrentSurveyId,qtemplateList:[1,5,6,11]}));

    // this.props.dispatch(generalActions.getQuestionOthersResult({surveyId:this.state.CurrentSurveyId,qtemplateList:[13]}));

    // this.props.dispatch(generalActions.getQuestionOthersResult({
    //   surveyId:this.state.CurrentSurveyId,
    //   qtemplateList:[5,11],
    //   filters:[{
    //     level:"Gender",
    //     value:"Male"
    //   }]
    // }));



    let dataX = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.getAllParameterList(dataX));
    this.props.dispatch(generalActions.getQuestionLiveResponse({ surveyId: this.state.CurrentSurveyId }));
    let data = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(questionsActions.getQuestionListBySurveyID(data));


  }




  ChooseSurvey = (val) => {

    // console.log('CurrentSurveyId: -------->',val)

    this.setState({ CurrentSurveyId: val })


  }

  onOverview = () => {
    this.setState({
      showOverview: true,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showQuesCard: false,
      CurrentSurveyId: this.state.CurrentSurveyId
    });
    this.props.dispatch(generalActions.getQuestionOthersResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [1, 5, 6, 11] }));

  }


  onCardClick = (cardType, item, index, check) => {

    if (check) {
      let { dashboard } = this.props;
      let { EmpFilterData } = dashboard;
      if (EmpFilterData && EmpFilterData.length > 0) {
        this.handleDemographicFilter(EmpFilterData[0].name);
      }
      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showIndex: true,
        showTextAnalysis: false,
        showAdvanceInsight: false,
        showQuesCard: false,
        IndexNo: index,
        cardType: cardType,
        ItemSaveData: item
      });
    }

  }

  handleSidebarClick = (isIndex, data, index, check) => {
    if (isIndex) {
      this.onCardClick('index', data, index, check);
    }
    else {
      this.onOverview()
      // this.setState({
      //   showOverview:true,
      //   showAllQuestions:false,
      //   showIndex:false,
      //   showTextAnalysis:false,
      //   showAdvanceInsight:false,
      //   showQuesCard:false,
      //   IndexNo:0,
      //   ItemSaveData:'',
      //   CurrentSurveyId:this.state.CurrentSurveyId
      // });
    }
  }



  handleSidebarExtra = (type) => {
    if (type === 1) {
      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showIndex: false,
        showTextAnalysis: true,
        showAdvanceInsight: false,
        showQuesCard: false,
        IndexNo: 0
      });

      let data2 = {
        "surveyId": this.state.CurrentSurveyId
      }
      this.props.dispatch(dashboardActions.getTextQuestion(data2, 1));
    }
    else if (type === 2) {
      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showIndex: false,
        showTextAnalysis: false,
        showAdvanceInsight: true,
        showQuesCard: false,
        IndexNo: 0
      });

      // let data2 ={
      //   "surveyId": this.state.CurrentSurveyId
      // }



    }

  }


  //------------------------------------------------------------------------------------------------------------------------
  handleCrossTabApi = (QuesID1, QuesID2, FilterValues) => {

    let data = {
      // "xAxis": "Department",
      "surveyId": this.state.CurrentSurveyId,
      "questionId1": QuesID1.id,
      "questionId2": QuesID2.id,
      "filters": FilterValues
    }
    // this.props.dispatch(dashboardActions.crossTab(data));

    this.props.dispatch(dashboardActions.crossTabAnalysis(data));


  }



  handleDemographicFilter = (value) => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": value
    }
    // this.props.dispatch(generalActions.getMeanScore(data2));
    this.props.dispatch(generalActions.getMeanScoreQwise(data2));

  }
  handleQuestionMeanScore = (value) => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": value
    }
    this.props.dispatch(generalActions.getMeanScoreQwise(data2));
  }

  handleDemographicOverallQFilter = (value) => {
    // //console.log('value handleDemographicOverallQFilter',value)
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": value
    }
    this.props.dispatch(generalActions.getMeanScoreQwise(data2));

  }


  //------------------------------------------------------------------------------------------------------------------------

  handleQuestion = () => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": []
    }
    this.props.dispatch(generalActions.getQuestionDist(data2));
    this.props.dispatch(generalActions.getQuestionDistOverall(data2));

  }

  handleResponseDistributionFilter = (name, value) => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": [{
        "level": name,
        "value": value
      }]
    }
    this.props.dispatch(generalActions.getQuestionDist(data2));
  }
  //------------------------------------------------------------------------------------------------------------------------
  handleWordCloudDemographicFilter = (QuesId, FilterValues, blank, type) => {
    let { CurrentSurveyId } = this.state;


    // let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        // XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        // XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters

    }

    ////console.log("--------------------------------------------------------->type",QuesId, FilterValues, blank, type)

    if (type === "advance") {
      this.props.dispatch(dashboardActions.AdvanceWordCloud(data2));
    }
    else if (type === "wordCloud") {
      this.props.dispatch(dashboardActions.wordCloud(data2));
    }

    else {
      let data3 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "xAxis": FilterValues && FilterValues.length > 0 ? FilterValues[0].level : "Department"
      }
      this.props.dispatch(dashboardActions.TreeMap(data3));
    }


  }



  callWordCloudText = (QuesId, keyWord, FilterList, FilterValue, isMultiKeyword) => {
    let { CurrentSurveyId } = this.state;


    if (isMultiKeyword) {
      let data2 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "keyWord1": keyWord && keyWord[0] ? keyWord[0] : "",
        "keyWord2": keyWord && keyWord[1] ? keyWord[1] : "",
        "filters": FilterList && FilterList.length > 0 ? FilterList : []
      }
      this.props.dispatch(dashboardActions.wordCloudMultiText(data2));
    }
    else {
      let data2 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "keyWord": keyWord,
        "filters": FilterList && FilterList.length > 0 ? FilterList : []
      }
      this.props.dispatch(dashboardActions.wordCloudText(data2));
    }
  }




  callQuestionWordCloud = (QuesId) => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": []
    }
    this.props.dispatch(dashboardActions.wordCloud(data2));
  }


  onWorldCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 1));

  }




  onNetworkGraph = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 2));

  }

  handleNetworkDemographicFilter = (Qid, FilterValues, graphType) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": Qid,
      "filters": Filters
    }

    if (graphType === 1) {
      this.props.dispatch(dashboardActions.cooccurmatrix(data2));
    }
    else if (graphType === 2) {
      this.props.dispatch(dashboardActions.neighborGraph(data2));
    }

  }

  handleNeighborGraph = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 3));

  }

  handleOverview = () => {
    // this.overviewAPI();
    if (this.state.showOverview) {
      if (this.state.showAllQuestions) {
        this.setState({
          showOverview: true,
          showAllQuestions: false,
          showIndex: false,
          showTextAnalysis: false,
          showAdvanceInsight: false,
          showQuesCard: false,
          currentQuestionTemplate: 0
        })
      }
      else {
        this.props.history.goBack()
      }
    }
    else {

      if (this.state.showQuesCard) {
        this.setState({
          showOverview: false,
          showAllQuestions: true,
          showIndex: false,
          showTextAnalysis: false,
          showAdvanceInsight: false,
          showQuesCard: false
        })
      }
      else {

        this.onOverview();

        this.handleSidebarClick(false)
      }


    }
  }



  onOverviewFromHeader = () => {
    this.props.history.goBack();

  };


  // onNPSCardClick=(item)=>{

  //   console.log('item:',item);
  //   console.log('item Qid:',item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"");


  //   this.setState({
  //     showOverview:false,
  //     showIndex:false,
  //     showTextAnalysis:false,
  //     showQuesCard:true,
  //     currentQuestionId:item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"",
  //     currentQuestionTemplate:item.answerList&&item.answerList[0]&&item.answerList[0].template?item.answerList[0].template:""

  //   });
  // }


  onOthersCardClick = (item) => {

    // console.log('item:',item);
    // console.log('item Qid:',item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"");


    this.setState({
      showOverview: false,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showQuesCard: true,
      currentQuestionId: item.answerList && item.answerList[0] && item.answerList[0].QuesId ? item.answerList[0].QuesId : "",
      currentQuestionTemplate: item.answerList && item.answerList[0] && item.answerList[0].template ? item.answerList[0].template : ""

    }, () => {
      let { currentQuestionTemplate } = this.state;

      let { dashboard } = this.props;
      let { EmpFilterData } = dashboard;

      let AxisInit = "Department";
      if (EmpFilterData && EmpFilterData.length > 0) {
        EmpFilterData.forEach((item, index) => {
          if (item.level && index === 0) {
            AxisInit = item.level;
          }
        });
      }

      if (currentQuestionTemplate !== 13) {
        let data7 = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplateList": [currentQuestionTemplate],
          "xAxis": AxisInit,
          "filters": []
        }
        this.props.dispatch(generalActions.getQuestionOthersResult(data7));
      }

    });



  }




  handleOverViewRoute = (item1, item2, item3) => {

    this.setState({
      itemSurveyReport: item1,
      itemOverview: item2,
      itemAllQuestions: item3
    });


  }




  // handleNPSFilterCall=(FilterValues,isValue)=>{
  //   let XAxis = "";
  //   let Filters = [];
  //   if(FilterValues && FilterValues.length>0){
  //     let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
  //     if(getIndex !== -1){
  //       XAxis = FilterValues[getIndex].level;
  //       FilterValues.forEach((item)=>{
  //         if(item.value !== "All"){
  //           Filters.push(item);
  //         }
  //       });
  //     }
  //     else{
  //       XAxis = FilterValues[FilterValues.length-1].level;
  //       Filters = FilterValues
  //     }

  //   }


  //   let data7 = {
  //     "surveyId": this.state.CurrentSurveyId,
  //     "qtemplateList":[6],
  //     "xAxis":isValue,
  //     "filters":Filters
  //   }

  //   this.props.dispatch(generalActions.getQuestionMatrixResult(data7));

  // }




  handleOthersFilterCall = (FilterValues, isValue, qTemplate, q2Template) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }


    ////console.log("--------------qTemplate-------.",qTemplate)

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplateList": [qTemplate],
      "xAxis": isValue,
      "filters": Filters
    }

    if (qTemplate === 13) {
      this.props.dispatch(generalActions.getQuestionMatrixResult(data7));
    }
    else {
      this.props.dispatch(generalActions.getQuestionOthersResultFilter(data7));
    }



    if (q2Template) {



      let data72 = {
        "surveyId": this.state.CurrentSurveyId,
        "qtemplateList": [q2Template],
        "xAxis": isValue,
        "filters": Filters
      }

      this.props.dispatch(generalActions.getQuestion2OthersResultFilter(data72));



    }

  }




  overviewAPI = () => {
    this.props.dispatch(generalActions.getQuestionOthersResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [1, 5, 6, 11] }));
  }




  onCrossTab = () => {

    let data = {
      "surveyId": this.state.CurrentSurveyId
    }

    this.props.dispatch(questionsActions.getQuestionListBySurveyID(data));


  }
  handleCrossTabApi = (QuesID1, QuesID2, FilterValues) => {

    let data = {
      // "xAxis": "Department",
      "surveyId": this.state.CurrentSurveyId,
      "questionId1": QuesID1.id,
      "questionId2": QuesID2.id,
      "filters": FilterValues
    }
    // this.props.dispatch(dashboardActions.crossTab(data));

    this.props.dispatch(dashboardActions.crossTabAnalysis(data));

  }


  handleQueCorrelation = (QuesID1, QuesList, FilterValues) => {
    ////console.log("asdasdasd")
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "questionId1": QuesID1.id,
      "questionList": QuesList,
      "filters": FilterValues
    }

    ////console.log("===========handleQueCorrelation============")

    this.props.dispatch(dashboardActions.getCorrelationQuestionType2(data2));

  }




  storeInnerRoute = (value) => {
    this.setState({ showAllQues: value });
  }



  onAllQuestions = (item) => {
    // this.setState({CurrentTemplateId:item.templateId})
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(questionsActions.getQuestionListBySurveyID(data2));

    this.setState({
      showOverview: false,
      showAllQuestions: true,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showQuesCard: false,
      currentQuestionTemplate: item.templateId
    })

  }


  onAdvanceWorldCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 4));

  }


  onTreeMap = () => {
    this.props.dispatch(dashboardActions.EmptyTreeMap());
  }



  onAllMatrixQuestions = (item) => {
    // this.setState({CurrentTemplateId:item.templateId})
    this.setState({
      showOverview: false,
      showAllQuestions: true,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showQuesCard: false,
      currentQuestionTemplate: 13
    })

  }


  // oMatrixQuestionDetail=()=>{
  //   this.setState({
  //     showOverview:false,
  //     showAllQuestions:false,
  //     showIndex:false,
  //     showTextAnalysis:false,
  //     showAdvanceInsight:false,
  //     showQuesCard:true,
  //     currentQuestionId:ques && ques.id?ques.id:"",
  //     currentQuestionTemplate:13

  //   });
  //   this.props.dispatch(generalActions.getQuestionMatrixResult({surveyId:this.state.CurrentSurveyId,qtemplateList:[13]}));

  // }
  handleMatrixDetails = (ques) => {
    this.setState({
      showOverview: false,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showQuesCard: true,
      currentQuestionId: ques && ques.id ? ques.id : "",
      currentQuestionTemplate: 13

    });
    this.props.dispatch(generalActions.getQuestionMatrixResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [13] }));

  }


  callQuestionNetworkCloud = (QuesId, graphType, FilterValues) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters && Filters.length > 0 ? Filters : []
    }

    if (graphType === 1) {
      this.props.dispatch(dashboardActions.cooccurmatrix(data2));

    }
    else if (graphType === 2) {
      this.props.dispatch(dashboardActions.neighborGraph(data2));
    }


  }

  handleGoBack = () => {
    let { showOtherTypes, showAllQuestions } = this.state;
    if (showOtherTypes && !showAllQuestions) {
      this.setState({
        showOverview: false,
        showAllQuestions: true,
        showOtherTypes: true
      })
    } else if (showOtherTypes && showAllQuestions) {
      this.setState({
        showOverview: true,
        showAllQuestions: false,
        showOtherTypes: false
      })
    }
  }


  render() {

    let { general, dashboard, survey, questions } = this.props;

    let { getSurveyData } = survey;

    let { questionsList } = questions;


    let { getIndexByFilterDMGData, getQuestionLiveResponseData } = general;

    let getIndexFilterData = getIndexByFilterDMGData;


    let { neighborGraphData, cooccurmatrixData, wordCloudData, wordTextKeyWord, wordMultiTextKeyWord,
      getAllParameterListData,


      wordCloudTextData, wordCloudMultiTextData, getTextQuestionData, ClientProfileData, EmpFilterData,
      getRespondantDetailsData,
      AdvanceWordCloudData,
      crossTabAnalysisData,
      crossTabData,
      TreeMapData,

      getCorrelationQuestionType2Data,
      getCompanyNameOnlyData

    } = dashboard;
    let { getQuestion2OthersResultFilterData, getQuestionOthersResultFilterData, getQuestionMatrixResultData, SurveyList, getMeanScoreQwiseData, getDashboardCardData, getQuestionDistData, getQuestionDistOverallData, getMeanScoreData } = general;

    let { CurrentSurveyId, ItemSaveData, currentQuestionId, currentQuestionTemplate,

      showOverview, showAllQuestions,
      showTextAnalysis, showAdvanceInsight, showQuesCard, showIndex, IndexNo, cardType

    } = this.state;

    let loading = dashboard.loading || general.loading;




    ////console.log("questionsList--------------->",questionsList)
    ////console.log("getQuestionLiveResponseData--------------->",getQuestionLiveResponseData)
    ////console.log("getAllParameterListData--------------->",getAllParameterListData)


    function getParameterName(parameterId) {

      let value = ""
      if (getAllParameterListData && getAllParameterListData.length > 0) {
        let getIndex = getAllParameterListData.findIndex(prev => prev.id === parameterId);
        if (getIndex !== -1) {
          value = getAllParameterListData[getIndex].name;
        }
      }
      return value
    }


    function getQuestionName(Qid, field) {

      let value = ""
      if (questionsList && questionsList.length > 0) {
        let getIndex = questionsList.findIndex(prev => prev.id === Qid);
        if (getIndex !== -1) {
          if (field === "name") {
            value = questionsList[getIndex].name;
          } else if (field === "templateId") {
            value = questionsList[getIndex].templateId;
          }
          else if (field === "rank") {
            value = questionsList[getIndex].rank;
          }
        }
      }
      return value
    }

    let templateList = [1, 2, 5, 6, 11, 13]

    let getQuestionOthersResultData = []
    if (getAllParameterListData && getAllParameterListData.length > 0 &&
      questionsList && questionsList.length > 0 &&
      getQuestionLiveResponseData && getQuestionLiveResponseData.length > 0) {
      getQuestionLiveResponseData.forEach((res) => {
        ////console.log("res--------------->",res)

        let temp = {
          "total": [],
          "question": {
            "name": getQuestionName(res._id, "name")
          },
          "rank": getQuestionName(res._id, "rank"),
          "templateId": getQuestionName(res._id, "templateId")
        }

        if (res && res.total && res.total.length > 0) {
          res.total.forEach((inner) => {
            inner["_id"]["answerText"] = inner["_id"]["answer"];
            inner["_id"]["qtemplate"] = getQuestionName(res._id, "templateId");
            inner["_id"]["parameterBucketName"] = getParameterName(inner.parameterBucketId);
            inner["_id"]["parameterDimensionName"] = getParameterName(inner.parameterDimensionId);
            temp["total"].push(inner)
          });
        }



        if (getQuestionName(res._id, "name") && templateList.includes(temp["templateId"])) {
          getQuestionOthersResultData.push(temp)
        }

      })
    }


    ////console.log("getQuestionOthersResultData--------------->",getQuestionOthersResultData)



    // let loading = false;




    //console.log('/////////////////////////////////////////////////=================================|||=========================>:');
    //console.log( 'getCorrelationQuestionType2Data ',getCorrelationQuestionType2Data)








    // //console.log('getDashboardCardData: ',getDashboardCardData)
    // //console.log('getQuestionDistData: ',getQuestionDistData)

    // //console.log('showIndex: ',showIndex)
    // //console.log('showManagerList: ',showManagerList)
    // //console.log('showAdvanceComparision: ',showAdvanceComparision)
    // //console.log('showTextAnalysis: ',showTextAnalysis)
    let OverviewList = [];
    let SumMean = 0;
    let OverallMean = 0;
    let total = 0;

    if (getDashboardCardData && getDashboardCardData.length > 0) {
      getDashboardCardData.forEach((item, index) => {
        if (item && item._id) {
          SumMean += parseFloat(item && item.mean ? item.mean : 0.0);
          total += 1;
          OverviewList.push({
            "id": item._id,
            "name": item.parameter && item.parameter.length > 0 && item.parameter[0] && item.parameter[0].name ? item.parameter[0].name : "NaN",
            "mean": (typeof item !== 'string' || item instanceof Number) && item && item.mean ? item.mean.toFixed(2) : 'NaN',
            "color": '#757575'
          });
        }
      });
      OverallMean = (SumMean / total);

    }
    //////console.log("OverallMean",OverallMean)
    //////console.log("SumMean",SumMean)
    //////console.log("total",total)
    //////console.log("getDashboardCardData",getDashboardCardData)


    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageactionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }

    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    //console.log('moduleAccess: ',moduleAccess)



    let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    //END -------------------------------------------------------







    function FormattFun(inputData, isSummary, questionsList) {
      let RankObj = getSurveyData && getSurveyData.designObj && getSurveyData.designObj.componentRank ? getSurveyData.designObj.componentRank : {}
      let LIST = []
      let prevTemList = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {

          let templateId = ques.total[0]._id.qtemplate;
          let questionId = ques.total[0]._id.questionId;



          // console.log("item------>questionsList------------>", ques);
          // console.log("item------>questionsList------------>", questionsList);

          let rank = 0
          if (questionsList && questionsList.length > 0) {
            let getIndex = questionsList.findIndex(prev => prev.id === questionId);
            if (getIndex !== -1) {
              rank = questionsList[getIndex]["rank"]
            }
          }

          // (RankObj && RankObj[templateId] ? RankObj[templateId] : 100)
          let temp = {
            name: ques && ques.question && ques.question.name ? ques.question.name : "",
            answerList: [],
            rank: rank,
            templateId: templateId
          }
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading: item && item._id && item._id.heading ? item._id.heading : "",
                parentId: item && item._id && item._id.parentId ? item._id.parentId : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId
              }
              temp["answerList"].push(ans);
            });
          }


          if (isSummary) {
            let getIndex = prevTemList.findIndex(prev => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId)
            }
          }
          else {
            LIST.push(temp);
          }


        });
      }
      return LIST
    }

    //  let NPSQuesList = FormattFun(getQuestionNPSResultData);
    let AllQuesList = FormattFun(getQuestionOthersResultData, false, questionsList).filter(x => x.templateId === currentQuestionTemplate);
    if (AllQuesList && AllQuesList.length > 0) {
      AllQuesList.sort((a, b) => a.rank - b.rank)
    }
    console.log("item------>AllQuesList------------>", AllQuesList)



    function getSurveyDetails(name) {
      return getSurveyData && getSurveyData[name] ? getSurveyData[name] : "NaN";
    }


    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text='Loading Data...'
          className="loader"
        >

          <div>

            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              SurveyList={SurveyList}
              ChooseSurvey={this.ChooseSurvey}
              CurrentSurveyId={CurrentSurveyId}
              props={this.props}
              handleOverview={this.handleOverview}
              onOverviewFromHeader={this.onOverviewFromHeader}
              companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}
            />


            <div className="px-4  md:flex  justify-between items-center bg-white
              border-b py-2.5">
              <div className="flex justify-between w-full space-x-2 text-gray-700">

                <div className='flex space-x-2'>
                  <span className="material-symbols-outlined -mt-0.5">description</span>
                  <h2 className="text-sm font-medium truncate"> {getSurveyDetails("name")} </h2>
                </div>

              </div>


            </div>



            <div className="fixed flex w-full h-screen bg-gray-200" >
              {accessAllow("dashboard") ?
                <>

                  <Sidebar
                    SurveyList={SurveyList}
                    OverviewList={OverviewList}
                    handleSidebarClick={this.handleSidebarClick}
                    IndexNo={IndexNo}
                    showIndex={showIndex}
                    showOverview={showOverview}
                    showTextAnalysis={showTextAnalysis}
                    showAdvanceInsight={showAdvanceInsight}
                    handleSidebarExtra={this.handleSidebarExtra}
                  />


                  {showOverview ?
                    <Overview
                      CurrentSurveyId={CurrentSurveyId}
                      SurveyList={SurveyList}
                      EmpFilterData={EmpFilterData}
                      OverviewList={OverviewList}
                      OverallMean={OverallMean}
                      onCardClick={this.onCardClick}
                      getMeanScoreQwiseData={getMeanScoreQwiseData}
                      getIndexFilterData={getIndexFilterData}
                      getRespondantDetailsData={getRespondantDetailsData}
                      getQuestionDistOverallData={getQuestionDistOverallData}

                      getQuestionMatrixResultData={getQuestionMatrixResultData}
                      questionsList={questionsList}

                      getQuestionOthersResultData={getQuestionOthersResultData}


                      onOthersCardClick={this.onOthersCardClick}

                      getSurveyData={getSurveyData}
                      overviewAPI={this.overviewAPI}
                      handleMatrixDetails={this.handleMatrixDetails}
                      handleOverViewRoute={this.handleOverViewRoute}

                      storeInnerRoute={this.storeInnerRoute}

                      onAllQuestions={this.onAllQuestions}
                      onAllMatrixQuestions={this.onAllMatrixQuestions}

                      getQuestionLiveResponseData={getQuestionLiveResponseData}
                    />
                    : null}

                  {showAllQuestions ?
                    <>
                      <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto md:p-6 p-4 " style={{ height: 'calc(100% - 4rem)' }}>

                        {currentQuestionTemplate !== 13 ?
                          <>
                            <main className="flex-1 w-full h-full pb-20 overflow-hidden overflow-y-auto bg-gray-50">

                              <div className="px-2 py-2 bg-gray-50">
                                <div className="h-full p-4 mx-auto overflow-hidden overflow-y-auto ">
                                  <div className='flex items-center pb-5 space-x-4'>
                                    <span onClick={() => this.handleGoBack()} class="cursor-pointer material-symbols-outlined bg-blue-100 p-2 rounded-full text-blue-500 ">arrow_back</span>
                                    <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Questions Analysis</h1>
                                  </div>
                                  <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-2 lg:gap-6">
                                    {AllQuesList && AllQuesList.length > 0
                                      ? AllQuesList.map((item, index) => (
                                        <div onClick={() => this.onOthersCardClick(item)} className="p-4 bg-white border">
                                          <QuestionCard
                                            item={item}
                                            name={""}
                                            stage={2}
                                            index={index}
                                          />
                                        </div>
                                      ))
                                      : null}
                                  </div>
                                </div>
                              </div>
                            </main>
                          </>
                          :
                          <>
                            {getQuestionMatrixResultData && getQuestionMatrixResultData.length > 0 ?

                              <div className="p-4 border rounded-lg" >
                                <QuestionCard
                                  questionType={"matrix"}
                                  getQuestionMatrixResultData={getQuestionMatrixResultData}
                                  questionsList={questionsList}
                                  stage={2}
                                  handleMatrixDetails={this.handleMatrixDetails}
                                />
                              </div>

                              : null}
                          </>}

                      </main>
                    </>
                    : null}



                  {showQuesCard ?

                    <QuestionDetails
                      handleGoBack={this.handleGoBack}
                      ItemSaveData={ItemSaveData}
                      cardType={cardType}
                      handleQuestion={this.handleQuestion}
                      getQuestionDistData={getQuestionDistData}
                      getQuestionDistOverallData={getQuestionDistOverallData}
                      getMeanScoreQwiseData={getMeanScoreQwiseData}
                      handleResponseDistributionFilter={this.handleResponseDistributionFilter}
                      handleQuestionMeanScore={this.handleQuestionMeanScore}
                      EmpFilterData={EmpFilterData}
                      getIndexFilterData={getIndexFilterData}
                      handleDemographicFilter={this.handleDemographicFilter}
                      getMeanScoreData={getMeanScoreData}
                      handleDemographicOverallQFilter={this.handleDemographicOverallQFilter}
                      OverviewList={OverviewList}
                      getQuestionOthersResultData={getQuestionOthersResultData}
                      currentQuestionId={currentQuestionId}
                      currentQuestionTemplate={currentQuestionTemplate}

                      handleOthersFilterCall={this.handleOthersFilterCall}

                      getQuestionMatrixResultData={getQuestionMatrixResultData}
                      getQuestionOthersResultFilterData={getQuestionOthersResultFilterData}


                      questionsList={questionsList}

                      getQuestion2OthersResultFilterData={getQuestion2OthersResultFilterData}

                    />

                    : null}



                </>
                : null}
            </div>

            <div id="sidebar-btn" className="fixed z-30 flex items-center justify-center w-10 h-10 text-gray-900 bg-white rounded-full shadow-md hover:bg-blue-500 hover:text-white bottom-8 right-8 lg:hidden">
              <span className="material-symbols-outlined">view_quilt</span>
            </div>
          </div>





        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, general, dashboard, survey, questions } = state;
  return {
    loggingIn,
    users,
    general,
    dashboard,
    survey,
    questions
  };
}
export default connect(mapStateToProps)(GeneralDashboard);
