import React from 'react';
// import { connect } from 'react-redux';



export default function Filter(props) {

  let { parameterList, handleSelectDimension, GetParameterLabel } = props;

  let [showFilter, handleOpenFilter] = React.useState(false);



  let { crrSelectedDimension } = props;





  return (
    <>


      <div className="cursor-pointer bg-white md:w-auto w-full text-gray-500    border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">


        <div>
          <h1 className='text-xs font-medium text-gray-800' >Dimensions: </h1>
        </div>
        <p onClick={() => handleOpenFilter(!showFilter)} className="px-2 text-xs ">{GetParameterLabel(crrSelectedDimension ? crrSelectedDimension : (parameterList && parameterList.length > 0 ?
          parameterList[0] : ""))}</p>


        <span onClick={() => handleOpenFilter(!showFilter)} className="material-icons ">arrow_drop_down</span>

        {showFilter && parameterList && parameterList.length > 0 ?
          <div className="absolute right-0 z-10 w-full p-3 overflow-x-auto text-xs font-normal bg-white rounded-md shadow-lg top-10 h-60 customscroll2">

            {parameterList && parameterList.length > 0 ?
              parameterList.map((item, index) =>
                <div key={index} className="flex items-center py-2 m-1 cursor-pointer"
                  onClick={() => { handleSelectDimension(item); handleOpenFilter(false) }}
                >
                  <input type="checkbox" checked={crrSelectedDimension === item} onChange={() => { }} />
                  <label className="ml-2 font-medium">{GetParameterLabel(item)}</label>

                </div>
              ) : null}
          </div>
          : null}
      </div>








      {/* </div> */}

    </>
  );
}
