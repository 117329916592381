import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import Header from "../Header/Header";
// import Sidebar from "./Components/Sidebar/Sidebar";
import { CiFilter } from "react-icons/ci";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { GoGoal } from "react-icons/go";
import { VscFeedback } from "react-icons/vsc";
import { LiaUserCogSolid } from "react-icons/lia";
import { AiOutlineClose } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { TiDocumentText } from "react-icons/ti";
import { TbEdit } from "react-icons/tb";
import { Button } from '@material-ui/core';


export default function Table(props, history) {

  // console.log('locationlocationlocationlocationlocation', props);

  let { item, name, stage } = props;
  const [isAddGoal, setIsAddGoal] = useState(false);
  const [isAddTask, setIsAddTask] = useState(false);
  // State to track the active tab
  const [activeTab, setActiveTab] = useState('goals');
  const [activeTabOne, setActiveTabOne] = useState('Review');

  const handleTabClick = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTab(tab);
  };

  const handleTabClickTop = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTabOne(tab);
  };


  const handleAddGoal = () => {
    setIsAddGoal(!isAddGoal);
  };
  const handleCloseAddGoal = () => {
    setIsAddGoal(false);
  };

  const handleAddTask = () => {
    setIsAddTask(!isAddTask);
  };
  const handleCloseAddTask = () => {
    setIsAddTask(false);
  };


  return (
    <>
      <div className="h-screen overflow-hidden bg-gray-50">
        <Header />



        <div className="flex w-full" style={{ height: "calc(100% - 2rem)" }}>
          {/* <Sidebar /> */}


          <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden">


            <div className="bg-white ">
              <div className="px-6 py-4 h-[64px] border flex justify-between">
                <div className="flex items-center space-x-4 text-[#212121]">
                  <button className="p-2 duration-200 rounded-md cursor-pointer hover:bg-slate-100">
                    <MdOutlineArrowBackIos className="w-5 h-5" /> </button>
                  <div className="text-2xl font-semibold">
                    <b className="font-medium">Appraisal 2023</b>
                  </div>
                  <div>
                    <p>Submission End Date: 15-Jul-2023</p>
                  </div>
                </div>
              </div>
            </div>



            <div className="md:p-6 p-4 mx-auto overflow-hidden overflow-y-auto space-y-4 h-[calc(100vh-12rem)] ">
              <div className="flex items-center justify-between">
                <h1 className="text-xl text-[#3D405B] font-medium">Tasks</h1>
                <div className="flex items-center space-x-3 ">
                  <div className="flex items-center justify-between px-2 space-x-2 overflow-hidden text-sm bg-white border rounded-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search text-gray-600" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>  <path d="M21 21l-6 -6"></path> </svg>
                    <input type="search" name="search" className="w-full py-2 md:w-48 focus:outline-none placeholder:text-slate-600" placeholder="Search" />
                  </div>

                  <div className="flex justify-center md:justify-start">
                    <button className="w-full px-4 py-2 text-center text-white transition-all duration-200 transform bg-white border rounded-sm cursor-pointer md:w-auto text-md"  >
                      <CiFilter className="w-5 h-5 text-gray-600" />
                    </button>
                  </div>

                  <div className="flex justify-center md:justify-start">
                    <button onClick={handleAddGoal} className="bg-[#18afb8] hover:bg-[#18afb8] transition-all duration-200 md:w-auto w-full md:py-2 py-3  px-4 rounded-sm text-center justify-center cursor-pointer transform  flex items-center text-white space-x-1 md:text-sm text-md">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path><path d="M9 12l6 0"></path><path d="M12 9l0 6"></path></svg>
                      <h1 className>Add Goal</h1>
                    </button>
                  </div>
                </div>
              </div>




              <div className='grid gap-6 xl:grid-cols-4 lg:grid-cols-2'>
                <div class="p-6 bg-white rounded-md">
                  <h4 class="text-xl font-medium pb-2">Add Appraisal Cycle details</h4>
                  <p class="my-1 text-justify">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                </div>
                <div class="p-6 bg-white rounded-md">
                  <h4 class="text-xl font-medium pb-2">Add Appraisal Cycle details</h4>
                  <p class="my-1 text-justify">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                </div>
                <div class="p-6 bg-white rounded-md">
                  <h4 class="text-xl font-medium pb-2">Add Appraisal Cycle details</h4>
                  <p class="my-1 text-justify">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                </div>
                <div class="p-6 bg-white rounded-md">
                  <h4 class="text-xl font-medium pb-2">Add Appraisal Cycle details</h4>
                  <p class="my-1 text-justify">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                </div>
              </div>


            </div >


          </main >
        </div >
      </div >

      {isAddGoal && (
        <div className="fixed inset-0 z-40 flex items-center justify-center w-full h-full mt-0 overflow-hidden overflow-y-auto bg-black/40">
          <div className="bg-[#fff] model mx-auto rounded-lg shadow-lg lg:w-2/6 w-full overflow-y-auto">

            <div className="flex items-center justify-between p-6 py-3 border-b bg-dark">
              <p className="font-medium text-black">Add Goal</p>
              <div className="rounded-lg cursor-pointer modal-close bg-[#F1F1F1] p-1" onClick={handleCloseAddGoal}>
                <AiOutlineClose className='w-6 h-6 text-gray-600' />
              </div>
            </div>

            <div className="p-4 space-y-4">
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Goal Name</lable>
                <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Start Date</lable>
                <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="DD-MM-YYYY" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">End Date</lable>
                <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="DD-MM-YYYY" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Priority</lable>
                <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Description</lable>
                <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Progress % (A goal is complete when progress is 100%)</lable>
                <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Weightage %</lable>
                <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='pb-4'>
                <div className="text-center text-white w-full px-3 py-2 text-sm uppercase transition-all duration-150 ease-linear bg-[#009EA7] border focus:outline-none focus:ring">Submit</div>
              </div>

            </div>

          </div>
        </div>
      )
      }

      {
        isAddTask && (
          <div className="fixed inset-0 z-40 flex items-center justify-center w-full h-full mt-0 overflow-hidden overflow-y-auto bg-black/40">
            <div className="bg-[#fff] model mx-auto rounded-lg shadow-lg lg:w-2/6 w-full overflow-y-auto">

              <div className="flex items-center justify-between p-6 py-3 border-b bg-dark">
                <p className="font-medium text-black">Add Goal</p>
                <div className="rounded-lg cursor-pointer modal-close bg-[#F1F1F1] p-1" onClick={handleCloseAddTask}>
                  <AiOutlineClose className='w-6 h-6 text-gray-600' />
                </div>
              </div>

              <div className="p-4 space-y-4">
                <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">Task Name</lable>
                  <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="Enter" />
                </div>
                <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">Start Date</lable>
                  <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="DD-MM-YYYY" />
                </div>
                <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">End Date</lable>
                  <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="DD-MM-YYYY" />
                </div>
                <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">Description</lable>
                  <textarea row={5} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="Write here" />
                </div>
                <div className='pb-4'>
                  <div className="text-center text-white w-full px-3 py-2 text-sm uppercase transition-all duration-150 ease-linear bg-[#009EA7] border focus:outline-none focus:ring">Submit</div>
                </div>
              </div>

            </div>
          </div>
        )
      }
    </>
  );
}
