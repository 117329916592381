import React, { Component } from "react";
import { connect } from "react-redux";
import StackChart from "./Charts/StackChart";
import StackChartDistribution from "./Charts/StackChartDistribution";
import Commentary from "../../../Components/Commentary/Commentary";
import SummaryScore from "./Cards/SummaryScore/SummaryScore";
import SummaryDistribution from "./Cards/SummaryDistribution/SummaryDistribution";
import IndexKPI from "../../../../../CommonComponents/IndexKPI";
import Filter from "../../../../../CommonComponents/Filters/IndexFilter/Filter";
import { CSVLink } from "react-csv";
import EDAModal from "../../../Components/EDA/EDAModal";
import NudgesSummary from "../../../../../CommonComponents/Nudges/NudgesSummary";
import { spliceDecimal } from "../../../../../FunctionLibrary/FuntionList";

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: "none",
      showLeaderBoard: false,
      isMultifilter: false,
      currentSelectValue: "",
      FilterValues: [],
      showFilter: false,
      showValue: "",
      ListName: "Demographic",
      showEDAModal: false,
      crrDMGValue: "",
    };
  }
  componentDidMount() { }

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultifilterIndexScore } = this.props;
    this.setState({ FilterValues: finalFilter, ListName: name });
    handleMultifilterIndexScore(finalFilter, true, name);
  };

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  handleSelectEDA = (data) => {
    ////////console.log("data--------->",data);
    let { finalFilter, ListName } = this.state;
    let dmgValue = data && data.name ? data.name : "";
    let { getEDAdata } = this.props;
    if (dmgValue) {
      getEDAdata(dmgValue, 2, finalFilter, ListName);
      this.setState({ showEDAModal: true, crrDMGValue: dmgValue });

      // setTimeout(()=>{
      //   this.setState({ showEDAModal: true, crrDMGValue: dmgValue});
      // },500)
    }
  };

  closeEDAModal = () => {
    this.setState({ showEDAModal: false, crrDMGValue: "" });
  };

  render() {
    let {
      getDemographicIndexMultiAxisFilterData,
      indexType,
      optionType,
      getCommentData,
      getOverallIndexData,
      isCrole,
      loading,
      name,
      CountRestrict,
      getEMPFilterLabel,
      getIndexByFilterDMGData,

      EmpFilterData,
      getIndexFilterData,
      getDMGIndexManyFilterData,
      ErrorIndexObj,
      getAllBenchMarkData,
      getRespondantDetailsData,
      getIndexData,
      getIndexScore,
      getIndexDistributionData,
      cronbatchAlphaVarianceData,
      getDemographicIndexMultiFilterData,
    } = this.props;
    let { currFilterName, FilterValues, ListName } = this.state;

    let cronBachAlpha = {
      score: 0,
    };
    // if (
    //   cronbatchAlphaVarianceData &&
    //   cronbatchAlphaVarianceData.score &&
    //   cronbatchAlphaVarianceData.score.length > 0
    // ) {
    //   cronBachAlpha["score"] =
    //     (typeof cronbatchAlphaVarianceData.score[0] !== "string" ||
    //       cronbatchAlphaVarianceData.score[0] instanceof Number) &&
    //     cronbatchAlphaVarianceData &&
    //     cronbatchAlphaVarianceData.score &&
    //     cronbatchAlphaVarianceData.score[0]
    //       ? spliceDecimal(cronbatchAlphaVarianceData.score[0],2)
    //       : "";
    // }
    if (
      cronbatchAlphaVarianceData &&
      cronbatchAlphaVarianceData.score &&
      cronbatchAlphaVarianceData.score > 0
    ) {
      cronBachAlpha["score"] = spliceDecimal(cronbatchAlphaVarianceData.score, 2);
    }


    let indexOverallValue =
      getOverallIndexData &&
        getOverallIndexData[indexType] &&
        parseFloat(spliceDecimal(getOverallIndexData[indexType], 2))
        ? parseFloat(spliceDecimal(getOverallIndexData[indexType], 2))
        : 0;

    ////console.log('getIndexFilterData: ', getIndexFilterData)

    let Item = {
      score: 0,
    };
    if (getIndexFilterData) {
      Item = {
        score:
          (typeof getIndexFilterData[indexType] !== "string" ||
            getIndexFilterData[indexType] instanceof Number) &&
            getIndexFilterData &&
            getIndexFilterData[indexType]
            ? spliceDecimal(getIndexFilterData[indexType], 2)
            : "",
      };
    }

    let Benchmark = {
      score: 0,
    };
    if (getAllBenchMarkData) {
      Benchmark = {
        score:
          (typeof getAllBenchMarkData[indexType] !== "string" ||
            getAllBenchMarkData[indexType] instanceof Number) &&
            getAllBenchMarkData &&
            getAllBenchMarkData[indexType]
            ? spliceDecimal(getAllBenchMarkData[indexType], 2)
            : "",
      };
    }

    ////console.log('FilterValues: ', FilterValues)

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    let isDisable = true;
    let DemographicDataScore = [];
    let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {
      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === "All") {
            isDisable = false;
          }
        });
      }

      DemographicDataScore = getDemographicIndexMultiFilterData;
      DemographicDataDistribution = getIndexDistributionData;
    }

    const ref = React.createRef();

    // Download Excel

    let ScoreExcel = [];
    let filter =
      FilterValues && FilterValues.length > 0
        ? " (" +
        FilterValues.map((element) => {
          return element.value;
        }).join(",") +
        ")"
        : " (Filter)";

    let parList = ["Unhappy", "ModeratelyHappy", "Happy", "Happiest"];

    if (getIndexScore) {
      let Overall = {};
      Overall[ListName + filter] = "Overall";
      Overall["Scores"] =
        getIndexScore && getIndexScore[indexType]
          ? spliceDecimal(getIndexScore[indexType], 2)
          : "NaN";

      if (getIndexData) {
        if (parList && parList.length > 0) {
          parList.forEach((para) => {
            Overall[para] =
              getIndexData && getIndexData[para]
                ? spliceDecimal(getIndexData[para], 2)
                : 0;
          });
        }
      }

      ScoreExcel.push(Overall);
    }

    if (DemographicDataScore && DemographicDataScore.length > 0) {
      DemographicDataScore.forEach((data) => {
        let dataScore = CountRestrict(data.count)
          ? "NaN"
          : spliceDecimal(data[indexType], 2);

        let tempData = {};
        if (data && data.name) {
          tempData[ListName + filter] = data.name;
          tempData["Scores"] = dataScore ? dataScore.toString() : "NaN";

          let getIndex = getIndexDistributionData
            ? getIndexDistributionData.findIndex(
              (prev) => prev.name === data.name
            )
            : -1;

          if (getIndex !== -1) {
            let Dist = CountRestrict(data.count)
              ? ""
              : getIndexDistributionData[getIndex];
            if (parList && parList.length > 0) {
              parList.forEach((para) => {
                tempData[para] =
                  Dist && Dist[para] ? spliceDecimal(Dist[para], 2) : "NaN";
              });
            }
          }
        }

        ScoreExcel.push(tempData);
      });
    }

    ////////console.log("------IndexSummaryScoreExcel: ScoreExcel-----------",ScoreExcel)

    let distList = [
      {
        label: "Happiest",
        value: "Happiest",
        color1: '#00cc66',
        color2: '#b3ffd9',
        text: `The smallest group consists of individuals who are classified as the "Happiest." This group reports the highest level of happiness, indicating an exceptionally positive and fulfilling experience in various aspects of life.`
      },
      {
        label: "Happier",
        value: "Happy",
        color1: '#a2e37d',
        color2: '#d2f2c0',
        text: `A considerable percentage of respondents fall into the "Happier" category, indicating a higher level of contentment and well-being. These individuals report a positive and satisfying overall experience.`
      },
      {
        label: "Moderately Happy",
        value: "ModeratelyHappy",
        color1: '#ffc107',
        color2: '#fbe2b6',
        text: `The majority of respondents are classified as "Moderately Happy." This suggests a balanced level of contentment and satisfaction among a significant portion of the surveyed population. While not the highest level of happiness, it indicates a generally positive outlook.`
      },
      {
        label: "Unhappy",
        value: "Unhappy",
        color1: '#e53935',
        color2: '#f1c0c0',
        text: `A significant  percentage of respondents falls into the "Unhappy" category, indicating dissatisfaction or a low level of happiness. Possible reasons for their unhappiness may include work-related issues, personal challenges, or other factors affecting their well-being.`
      }


    ];




    //---------------------------------------------------------------------------------------
    let tempSortData = []
    let tempSortDistribution = []
    if (DemographicDataScore && DemographicDataScore.length > 0) {
      DemographicDataScore.forEach((data) => {
        tempSortData.push(data)
      })
    }
    let tempSortDistributionX = []
    if (DemographicDataDistribution && DemographicDataDistribution.length > 0) {
      DemographicDataDistribution.forEach((data) => {
        tempSortDistributionX.push(data)
      })
    }
    tempSortData.sort((b, a) => a[indexType] - b[indexType]);
    let nameXList = tempSortData.map((ele) => (ele.name));

    if (tempSortDistributionX && tempSortDistributionX.length > 0) {
      tempSortDistribution = tempSortDistributionX.sort((a, b) => nameXList.indexOf(a.name) - nameXList.indexOf(b.name));
    }
    //---------------------------------------------------------------------------------------





    return (
      <>
        {/* end */}
        {/*sub nav bar 2 */}
        <div className="flex flex-col justify-between gap-4 md:items-center md:flex-row">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />

          <div className="flex flex-wrap gap-2 ">
            <NudgesSummary
              indexType={indexType}
              name={"Happiness Index Summary"}
              dataItem={
                FilterValues && FilterValues.length > 0
                  ? {
                    show: true,
                    overallDistDetail: getIndexDistributionData,
                    distListSumLow: [
                      distList[1],
                      distList[0],
                    ],
                    distListSumHigh: [
                      distList[2],
                      distList[3],
                    ],
                    crrDmg: ListName + filter,
                  }
                  : {
                    show: true,
                    overallDist: getIndexData,
                    overallScore: Item.score,
                    overallText: `The Overall Happiness Index score of ${Item && Item.score ? Item.score : ""} is a composite measure that reflects the level of happiness among respondents. Here's an explanation of each category:`,
                    distList: distList,
                  }
              }
              optionType={
                FilterValues && FilterValues.length > 0
                  ? "summarydetails"
                  : "summary"
              }
              CountRestrict={CountRestrict}
            />

            {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
            <Commentary
              indexType={indexType}
              optionType={optionType}
              getCommentData={getCommentData}
            />

            {FilterValues && FilterValues.length > 0 ? (
              <>
                {ScoreExcel ? (
                  <CSVLink
                    data={ScoreExcel}
                    filename={"HappinessIndexScoreAndDistribution.csv"}
                  >
                    <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                        <path d="M10 12l4 4m0 -4l-4 4" />
                      </svg>

                      <span>Download Excel</span>
                    </button>
                  </CSVLink>
                ) : (
                  "..."
                )}
              </>
            ) : null}
          </div>
        </div>

        {/* {FilterValues && FilterValues.length > 0 ?
          <div className="items-center justify-between h-10 px-4 py-3 bg-white border-b xl:flex lg:flex md:flex">
            <>
            {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
              <p className="flex mx-2 my-4 text-sm"><p className="mr-1 text-blue-500">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}


              {FilterValues && FilterValues.length > 0 ?
                <>
                {ScoreExcel ?
                <CSVLink data={ScoreExcel} filename={"IndexScore.csv"}>
                <p className="text-sm font-normal text-purple-700 cursor-pointer hover:text-purple-500">
                  {"Download Excel"}
                </p>                
                </CSVLink>
                : '...'}
                </>
                :null}


              
            </>
            </div>
          : null} */}

        <div className="space-y-4 ">
          {FilterValues && FilterValues.length > 0 ? null : (
            <IndexKPI
              indexType={indexType}
              getIndexData={getIndexData}
              getIndexScore={getIndexScore}
              getAllBenchMarkData={getAllBenchMarkData}
              getRespondantDetailsData={getRespondantDetailsData}
            />
          )}

          <div className="grid grid-cols-1 gap-4 xl:grid-cols-2 ">
            <div className="md:p-6 p-4 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
              <div className="flex items-center justify-between">
                <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">
                  {name} Score
                </h1>
                <div className="flex items-center p-1 px-4 space-x-2  rounded-md bg-gray-100 w-fit text-sm">
                 <p className="   rounded-full text-[#3D405B]  flex justify-center items-center  "> Cron Batch Alpha: </p>
                 <p className="font-semibold  text-[#3D405B]  "> {cronBachAlpha.score}</p>
                </div>
              </div>

              {ErrorIndexObj && ErrorIndexObj["getIndexV2Error"] ? (
                <>
                  <div className="my-24 text-2xl font-medium text-center text-gray-400">
                    {ErrorIndexObj["getIndexV2Error"]}
                  </div>
                </>
              ) : (
                <>
                  {FilterValues && FilterValues.length > 0 ? (
                    <>
                      {ErrorIndexObj &&
                        ErrorIndexObj["getDemographicIndexMultiFilterError"] ? (
                        <>
                          <div className="my-24 text-2xl font-medium text-center text-gray-400">
                            {
                              ErrorIndexObj[
                              "getDemographicIndexMultiFilterError"
                              ]
                            }
                          </div>
                        </>
                      ) : (

                        <StackChart
                          getIndexScore={getIndexScore}
                          tempSortData={tempSortData}
                          currFilterName={currFilterName}
                          indexType={indexType}
                          CountRestrict={CountRestrict}
                          handleSelectEDA={this.handleSelectEDA}
                          ErrorIndexObj={ErrorIndexObj}
                        />


                      )}
                    </>
                  ) : (
                
                      <SummaryScore
                        name={name}
                        isCrole={isCrole}
                        indexOverallValue={indexOverallValue}
                        cronBachAlpha={cronBachAlpha}
                        item={Item}
                        DistributionData={getIndexData}
                        Benchmark={Benchmark}
                      />
            
                  )}{" "}
                </>
              )}
              <p
                className="text-sm text-[#3d405B]/70"

              >
                * We are not showing data where employee count is
                less than 5
              </p>

            </div>

            <div className="md:p-6 p-4 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
              <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">
                {name} Distribution
              </h1>

              {ErrorIndexObj && ErrorIndexObj["getIndexV2Error"] ? (
                <>
                  <div className="my-24 text-2xl font-medium text-center text-gray-400">
                    {ErrorIndexObj["getIndexV2Error"]}
                  </div>
                </>
              ) : (
                <>
                  {FilterValues && FilterValues.length > 0 ? (
                    <>
                      {ErrorIndexObj &&
                        ErrorIndexObj["getIndexV2DemographicError"] ? (
                        <>
                          <div className="my-24 text-2xl font-medium text-center text-gray-400">
                            {ErrorIndexObj["getIndexV2DemographicError"]}
                          </div>
                        </>
                      ) : (

                        <StackChartDistribution
                          getIndexData={getIndexData}
                          tempSortDistribution={tempSortDistribution}
                          currFilterName={currFilterName}
                          indexType={indexType}
                          CountRestrict={CountRestrict}
                          ErrorIndexObj={ErrorIndexObj}
                        />


                      )}
                    </>
                  ) : (

                    <SummaryDistribution
                      cronBachAlpha={cronBachAlpha}
                      item={Item}
                      DistributionData={getIndexData}
                      Benchmark={Benchmark}
                    />

                  )}
                </>
              )}

              <p
                className="text-sm text-[#3d405B]/70"
              >
                * We are not showing data where employee count is
                less than 5
              </p>
            </div>
          </div>
        </div>

        <EDAModal
          showModal={this.state.showEDAModal}
          closeModal={this.closeEDAModal}
          getDMGIndexManyFilterData={getDemographicIndexMultiAxisFilterData}
          indexType={indexType}
          dmgLevel={this.state.ListName}
          dmgValue={this.state.crrDMGValue}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(SummaryData);
