import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dashboardActions, surveyActions } from '../../_actions';
import { userActions } from '../../_actions';
import Header from '../SurveyMgmt/Header/Header';
import Sidebar from '../Overview/Sidebar/SidebarOverview';
import { accessModule } from '../../_helpers';
import LoadingOverlay from 'react-loading-overlay';
import * as XLSX from 'xlsx';
import { make_cols } from './MakeColumns';
import AddUserEmployeeList from './Components/AddUserEmployeeList';
import UserAccessModal from './Components/UserAccessModal';
import AllUserRoles from './Components/AllUserRoles';
import DeleteClearAccessConfirm from './Components/Modal/DeleteClearAccessConfirm';
import UserAccessUpdateModal from './Components/UserAccessUpdateModal';


class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveysize: 10000,
      showSurveyList: true,
      showAddNewSurvey: false,
      showEditSurvey: false,
      confirmDeleteSurvey: false,
      CurrentSurveyId: '',
      CurrentSurveyName: '',
      showChooseSurveyModal: false,
      showSurveyLibrary: false,
      isPackageSelected: false,
      CurrentSurveyData: '',
      showQuestionLibrary: '',

      showUserModal: false,
      showUserModalUPDATE: false,

      checkDMGValueAll: {},
      searchEmployee: "",

      EmpIndexA: 0,
      EmpIndexB: 6,


      offset: 0,
      page: 0,
      size: 10,

      currentSurveyPage: 1,
      currentSurveyPageV1: 1,

      employeeData: '',
      savePackage: '',
      packageList: [],
      surveyValidation: {
        "name": true,
        "desc": true
      },
      surveyFormData: {
        "name": '',
        "desc": ''
      },
      showSidebar: true,
      employeesize2: 6,
      usersize2: 6,
      showCreateSurveyPopup: false,
      CurrentLanguage: 'en',
      CurrentLanguageName: 'English',
      showLanguageModal: false,
      openEmpFilterModal: false,
      formDataFilter: {
        "name": "",
        "label": "",
        "isDisable": false
      },

      showFilterNames: false,
      showEmployeeDetails: "",

      showALLUserRoles: true,
      showUserDmgLevel: false,
      showUserDmgValue: false,
      DMGLevelList: [],
      DMGValueList: {},
      finalDemoList: {},
      selectedEmployeeData: {
        "name": "",
        "emp_id": "",
        "employeeID": "",
        "userId": ""
      },
      AccessObj: {
      },
      AccessList: [

        {
          label: "Employee Experience",
          value: "employeeExperience"
        },
        {
          label: "Performance Management",
          value: "performance"
        },
        {
          label: "Sense",
          value: "sense"
        },
        {
          label: "Happiness Insight",
          value: "happinessInsight"
        },
        {
          label: "360Feedback",
          value: "360Feedback"
        },
        {
          label: "Assessment",
          value: "assessment"
        },
        {
          label: "Venting Platform",
          value: "ventingPlatform"
        },

      ],
      ComponentList: [
        {
          label: "View Survey*",
          value: "viewSurvey"
        },
        {
          label: "Create Survey",
          value: "createSurvey"
        },
        {
          label: "Publish Survey",
          value: "publishSurvey"
        },
        {
          label: "Dashboard",
          value: "dashboard"
        },
        {
          label: "Response Analytics",
          value: "responseAnalytics"
        },
        {
          label: "Download Response",
          value: "downloadResponse"
        },
        {
          label: "Add Users",
          value: "uploadUser"
        },
        {
          label: "Upload Employees",
          value: "uploadEmp"
        },
        {
          label: "Index & Dimension",
          value: "parameterSetting"
        },
        {
          label: "Risk Mngmt",
          value: "riskSurvey"
        },
      ],

      searchName: "",
      crrSelectedSurveyList: [],
      showClearAccessModal: false,
      crrboolData: false,
      showUserEmpList: false,
      showMobileSidebar: false
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "pageNo": 1,
      "size": this.state.surveysize
    }
    this.props.dispatch(dashboardActions.getCompanyNameOnly());

    this.props.dispatch(surveyActions.getSurveyList(data));
    this.props.dispatch(dashboardActions.getClientProfile());

    this.props.dispatch(surveyActions.getEmployeeFilterListByCompanyId());




    let data13 = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.usersize2
    }
    this.props.dispatch(surveyActions.getAssignedRoles(data13));




    let data2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(surveyActions.getLanguageLabel(data2));
    this.props.dispatch(surveyActions.getLanguage(data2));

    let data3 = {
      "filters": []
    }
    this.props.dispatch(dashboardActions.getEmpFilter());
    this.props.dispatch(surveyActions.getAllFilterValues(data3));


    let data132 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 20000
    }
    this.props.dispatch(surveyActions.getSurveyListByRole(data132));


  }

  handleChange = (e) => {
    const files = e.target.files;
    // ////console.log("files; ",files)
    if (files && files[0]) this.setState({ file: files[0] });
  };
  handleFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws);
      // ////console.log("datadatadatadata ", data);
      data = data.map(v => ({ ...v, "companyId": this.props.match.params.id }))

      let flag = 0;
      let locList = [];
      if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let value = validateEmail(data[i].email);
          ////console.log("emails:x:", data[i].email);

          if (value) {
            //all valid
          }
          else {
            flag = flag + 1;
            locList.push(i + 1);
          }
        }
      }
      function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      if (flag) {
        this.setState({ isNotMail: true, isNotMailPos: locList })
      }
      else {
        /* Update state */
        let datatemp0 = {
          "keyWord": this.state.keyWord,
          "pageNo": 1,
          "companyId": this.props.match.params.id,
          "size": this.state.employeesize
        }
        this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
          this.props.dispatch(surveyActions.saveEmployeeList(this.state.data, datatemp0));
          this.setState({ isNotMail: false, isNotMailPos: [] })

        });
      }



    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    };
  }

  onDisableEmployee = (data) => {
    ////console.log("data Employee: ", data);
    let tempdata = {
      "id": data.id.toString(),
      "keyWord": this.state.keyWord,
      "pageNo": this.state.employeepage,
      "size": this.state.size

    }

    ////console.log("asdf :: ", tempdata);
    this.props.dispatch(surveyActions.updateEmployeeStatus(tempdata));
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    ////console.log("search value:", value)
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.setState({ employeeData: '' });

    this.props.dispatch(surveyActions.getEmployeeFilterListByCompanyId(data));
  }

  handleSearchEmployee = (event) => {
    event.preventDefault();
    let { value } = event.target;
    ////console.log("search value:",value)
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "companyId": this.props.match.params.id,
      "size": this.state.size
    }
    this.setState({ employeeData: '' });

    this.props.dispatch(surveyActions.getEmployeeList(data));
  }

  handleSearchEmployee = (event) => {
    event.preventDefault();
    let { value } = event.target;

    this.setState({ searchEmployee: value }, () => {
      let data = {
        "keyWord": "",
        "pageNo": 1,
        "size": this.state.usersize2
      }
      this.props.dispatch(surveyActions.getAssignedRoles(data));
    })

  }

  handleSearchUser = (event) => {
    event.preventDefault();
    let { value } = event.target;

    this.setState({ searchEmployee: value }, () => {
      let data = {
        "keyWord": value,
        "pageNo": 1,
        "size": this.state.usersize2
      }
      this.props.dispatch(surveyActions.getAssignedRoles(data));
    })

  }

  // handleSearchUserAPI = (event) => {
  //   event.preventDefault();
  //   let { searchEmployee } = this.state;


  //   this.setState({ keyWord: searchEmployee, offset: 0 });


  //   let data = {
  //     "keyWord": "",
  //     "pageNo": 1,
  //     "size": this.state.usersize2
  //   }
  //   if (searchEmployee) {
  //     data = {
  //       "keyWord": searchEmployee,
  //       "pageNo": 1,
  //       "size": this.state.usersize2
  //     }
  //   }


  //   this.setState({ employeeData: '' });

  //   this.props.dispatch(surveyActions.getAssignedRoles(data));

  //   // this.props.dispatch(surveyActions.getEmployeeList(data));
  // }

  handleSearchEmployeeNameAndEmail = (event) => {
    event.preventDefault();
    // let { value } = event.target;
    let { searchEmployee } = this.state;


    this.setState({ keyWord: searchEmployee, offset: 0 });


    let data = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": this.props.match.params.id,
      "size": this.state.size
    }
    if (searchEmployee) {
      data = {
        "keyWord": searchEmployee,
        "pageNo": 1,
        "companyId": this.props.match.params.id,
        "size": this.state.size
      }
    }


    this.setState({ employeeData: '' });

    // this.props.dispatch(surveyActions.getAssignedRoles(data));

    this.props.dispatch(surveyActions.getEmployeeList(data));
  }

  handleADDpackage = (survey) => {
    this.setState({ showQuestionLibrary: true })

    let { packageList } = this.state;
    if (packageList.findIndex(prev => prev === survey.id) === -1) {
      packageList.push(survey.id);
      this.setState({ isPackageSelected: false })
    }
    else {
      let getIndex = packageList.findIndex(prev => prev === survey.id);
      packageList.splice(getIndex, 1);
      this.setState({ isPackageSelected: true })
    }
    this.setState({ packageList });
  }


  onSurveyDelete = () => {
    let data = {
      id: this.state.CurrentSurveyId
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }
    this.props.dispatch(surveyActions.deleteSurvey(data, datatempsurvey));
    this.setState({ confirmDeleteSurvey: false, CurrentSurveyId: '', CurrentSurveyName: '' })
  }

  onEditSurvey = (data) => {
    this.props.history.push(`/app/question/${data.id}`)
  }

  onPreviewSurvey = () => {

  }

  onReportSurvey = (data) => {
    this.props.history.push(`/app/surveyreport/${data.id}`)
  }

  onPublishSurvey = (data) => {
    this.props.history.push(`/app/publish/${data.id}`)
  }

  handleChooseSurveyPopup = () => {
    this.setState({ showCreateSurveyPopup: false, showChooseSurveyModal: true })
  }

  handleCreateSurveyPopup = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showCreateSurveyPopup: true })
  }

  handleCreateSurveyFromLibrary = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showSurveyLibrary: true });
    this.props.dispatch(surveyActions.getSurveyFromLib());
  }

  handleSurveyLibraryClose = (check) => {
    if (check) {
      this.setState({ CurrentSurveyData: false });
    }
    else {
      this.setState({ showSurveyLibrary: false });
    }
  }

  QuestionLibraryClose = () => {
    this.setState({ CurrentSurveyData: false, savePackage: '', showQuestionLibrary: false });
  }

  handleCreateSurveyPopupClose = () => {
    this.handleChooseSurveyPopupClose();

    let validate = {
      "name": true,
      "desc": true
    }
    let tempData = {
      "name": '',
      "desc": ''
    }
    this.setState({ showCreateSurveyPopup: false, surveyValidation: validate, surveyFormData: tempData })
  }

  handleChooseSurveyPopupClose = () => {
    this.setState({ showChooseSurveyModal: false })
  }

  onLogout = () => {
    console.log('logout')
    this.props.dispatch(userActions.logout());
  }

  goToDashboard = () => {

    this.props.history.push('/app/dashboard');

  }

  goToGeneralDashboard = () => {
    let SurveyId = '5fa04d316ede0f0336066cce';

    this.props.history.push('/app/generaldashboard/' + SurveyId);

  }

  onDashboard = (survey) => {
    console.log('survey: ', survey);

    // let SurveyId = '5fa04d316ede0f0336066cce';

    if (survey && survey.templateId === 1) {
      this.props.history.push('/app/dashboard/' + survey.id);
    }
    else if (survey && survey.templateId === 2) {
      this.props.history.push('/app/dashboard/' + survey.id);
    }
    else if (survey && survey.templateId === 3) {
      this.props.history.push('/app/generaldashboard/' + survey.id);
    }

  }

  handleSurveyLibQuestions = (survey) => {
    this.setState({ CurrentSurveyData: survey });
  }

  onLogout = () => {
    console.log('logout')
    this.props.dispatch(userActions.logout());
  }
  handleSuveyPackageSubmit = () => {
    let { SurveySubmitData, packageList } = this.state;


    let resData = {
      "name": SurveySubmitData.name,
      "desc": SurveySubmitData.desc,
      "templateId": 2
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }

    this.props.dispatch(surveyActions.createSurvey(resData, datatempsurvey, false, packageList));

    let validate = {
      "name": true,
      "desc": true
    }
    let tempData = {
      "name": '',
      "desc": ''
    }
    this.setState({ showSurveyLibrary: false, showCreateSurveyPopup: false, showChooseSurveyModal: false, surveyValidation: validate, surveyFormData: tempData })

  }

  addUserModal = (value) => {
    if (value) {
      this.setState({ showUserModal: value, showUserModalUPDATE: value });
    }
    else {
      this.setState({ showUserModalUPDATE: false, showUserModal: value, selectedEmployeeData: { "name": "", "emp_id": "", "employeeID": "", "userId": "" }, finalDemoList: {}, AccessObj: {}, DMGLevelList: [], crrSelectedSurveyList: [], DMGValueList: {}, checkDMGValueAll: {} });
    }
  }

  handleUserContinue = () => {
    this.addUserModal(true)
    this.setState({ showUserEmpList: false })
  }

  prevEmpPage = () => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (EmpIndexA > 0) {
      this.setState({ EmpIndexA: EmpIndexA - 1, EmpIndexB: EmpIndexB - 1 });
    }
  }
  nextEmpPage = (IndexListIndex) => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (IndexListIndex && IndexListIndex.length && (EmpIndexB < IndexListIndex.length)) {
      this.setState({ EmpIndexA: EmpIndexA + 1, EmpIndexB: EmpIndexB + 1 });
    }
  }


  SelectEmpPage = (val, IndexListIndex) => {

    if (val > 0 && val <= (IndexListIndex && IndexListIndex.length > 0 ? IndexListIndex.length : 0)) {

      this.setState({ currentSurveyPage: val });
      let datatempemployee = {
        "keyWord": this.state.keyWord ? this.state.keyWord : "",
        // "keyWord":  "",
        "pageNo": val,
        "companyId": this.props.match.params.id,
        "size": this.state.size
      }

      this.props.dispatch(surveyActions.getEmployeeList(datatempemployee));

      let data1 = {
        "name": "",
        "emp_id": "",
        "employeeID": "",
        "userId": ""
      }
      this.setState({ selectedEmployeeData: data1, showEmployeeDrop: false });

    }


  }


  SelectEmpPagev1 = (val, IndexListIndex) => {

    console.log("--vval, IndexListIndex--->", val, IndexListIndex)

    if (val > 0 && val <= (IndexListIndex && IndexListIndex.length > 0 ? IndexListIndex.length : 0)) {

      this.setState({ currentSurveyPageV1: val });
      let datatempemployee = {
        "keyWord": "",
        "pageNo": val,
        "companyId": this.props.match.params.id,
        "size": this.state.usersize2
      }

      this.props.dispatch(surveyActions.getAssignedRoles(datatempemployee));
    }


  }


  DownloadEmployees = (employeesItem, isFilter) => {
    let list = [];
    let keyList = []
    let keyNewList = []


    if (employeesItem && employeesItem.length > 0 && employeesItem[0]) {
      keyList = Object.keys(employeesItem[0])
    }

    if (keyList && keyList.length > 0) {
      keyList.forEach((key, index) => {
        if (key !== 'id'
          // && key !== 'role' 
          && key !== 'companyId' && key !== 'currentStage' && key !== 'isDisable' && key !== 'createdAt') {
          keyNewList.push(key);
        }
      })
    }

    if (employeesItem && employeesItem.length > 0) {

      employeesItem.forEach((emp, index) => {

        let data = {}

        if (keyNewList && keyNewList.length > 0) {
          keyNewList.forEach((keY, indey) => {
            data[keY] = emp[keY]
          })
        }

        list.push(data)

      })


      let finalList = [];
      if (isFilter) {
        // let listFiltered = []
        let { getEmployeeFilterListByCompanyIdData } = this.props.survey;

        console.log('asdadlist: ', list)

        if (list && list.length > 0) {
          list.forEach((emp) => {
            let tempObj = {}
            tempObj["email"] = emp["email"];
            tempObj["emp_id"] = emp["emp_id"];
            if (getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length > 0) {
              getEmployeeFilterListByCompanyIdData.forEach((filter) => {
                if (!filter.isDisable) {
                  tempObj[filter.name] = emp[filter.name]
                }
              });
            }
            finalList.push(tempObj);

          });
        }
      }

      else {
        finalList = list;
      }




      let binary_univers = finalList;
      console.log('binary_univers: ', binary_univers)

      let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

      // Create a new Workbook
      var wb = XLSX.utils.book_new()

      // Name your sheet
      XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values')

      // export your excel
      XLSX.writeFile(wb, 'employees.xlsx');


    }

  }


  handleViewDetails = (emp) => {
    this.setState({ employeeData: emp })
  }


  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });


    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },4000);

  }

  routeLink = (link) => {
    this.props.history.push('/app/' + link);
    this.setState({ CrrRoute: link });
  }

  handleLanguage = (item) => {
    this.setState({ CurrentLanguage: item.shortName, CurrentLanguageName: item.name, showLanguageModal: false });
  }

  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  }

  onEmployeeFilter = (getEmployeeListData) => {
    this.props.dispatch(surveyActions.getEmployeeColumnName());
    this.setState({ openEmpFilterModal: true });

  }

  closeEmployeeFilter = (check) => {
    this.setState({ openEmpFilterModal: check });
  }


  handleFilterInput = (e) => {
    let { formDataFilter } = this.state;
    formDataFilter["label"] = e.target.value;
    this.setState({ formDataFilter });
  }


  onEmployeeFilterDropdown = (check) => {
    this.setState({ showFilterNames: check });
  }


  handleEmployeeColumnSelect = (name) => {
    let { formDataFilter } = this.state;
    formDataFilter["name"] = name;
    formDataFilter["label"] = name;
    this.setState({ formDataFilter, showFilterNames: false });
  }

  handleFilterStatus = (value) => {
    ////console.log("handleFilterStatus::", value);
    this.props.dispatch(surveyActions.updateEmployeeFilterStatus({ id: value }));
  }


  handleFilterCheck = () => {
    let { formDataFilter } = this.state;
    if (formDataFilter.isDisable) {
      formDataFilter['isDisable'] = false;
    }
    else {
      formDataFilter['isDisable'] = true;
    }
    this.setState({ formDataFilter });
  }

  addEmployeeFilter = () => {
    let { formDataFilter } = this.state;

    if (formDataFilter.name !== "Choose Name") {
      ////console.log("formDataFilter final", formDataFilter)
      let tempData = {
        "name": formDataFilter.name,
        "label": formDataFilter.label,
        "isDisable": false
      }
      this.props.dispatch(surveyActions.createEmployeeFilter(tempData));
    }

    let tempClear = {
      "name": "",
      "label": "",
      "isDisable": false
    }
    this.setState({ formDataFilter: tempClear });
  }

  downLoadSampleCSV = () => {

    let binary_univers = [{
      "email": "sample@mail.com",
      "emp_id": "001",
    }];
    console.log('binary_univers: ', binary_univers)

    let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

    // Create a new Workbook
    var wb = XLSX.utils.book_new()

    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values')

    // export your excel
    XLSX.writeFile(wb, 'sample employee.xlsx');
  }

  onDeleteFilter = (empFilter) => {
    let tempData = {
      id: empFilter.id
    }
    this.props.dispatch(surveyActions.deleteEmployeeFilter(tempData));
  }



  handleEmployeeDetails = (emp) => {
    this.setState({ showEmployeeDetails: emp });
  }

  onEditDetails = (check) => {
    this.setState({ showALLUserRoles: check });


    this.addUserModal(false)
  }


  handleOpenLevel = (check) => {
    this.setState({ showUserDmgLevel: check });
  }

  handleOpenValue = (check) => {
    this.setState({ searchName: "" });
    if (this.state.showUserDmgValue === check) {
      this.setState({ showUserDmgValue: "", showUserDmgLevel: false });
    }
    else {
      this.setState({ showUserDmgValue: check, showUserDmgLevel: false });
    }
  }


  handleSearch = (e) => {
    this.setState({ searchName: e.target.value });
  }

  selectDMGLevel = (value) => {
    let { DMGLevelList } = this.state;
    let getIndex = DMGLevelList.findIndex(prev => prev === value);
    if (getIndex === -1) {
      DMGLevelList.push(value);
    } else {
      DMGLevelList.splice(getIndex, 1);
    }
    this.setState({ DMGLevelList });
  }


  selectDMGValue = (value, level) => {
    let { finalDemoList, checkDMGValueAll } = this.state;

    if (finalDemoList && finalDemoList[level] && finalDemoList[level].length > 0) {
      let getIndex = finalDemoList[level].findIndex(prev => prev === value);
      if (getIndex === -1) {
        finalDemoList[level].push(value);
      }
      else {
        finalDemoList[level].splice(getIndex, 1);
      }
    }
    else {
      finalDemoList[level] = [];
      finalDemoList[level].push(value);
    }
    this.setState({ finalDemoList }, () => {
      if (!(finalDemoList && finalDemoList[level] && finalDemoList[level].length > 0)) {
        delete finalDemoList[level];
        this.setState({ finalDemoList });
      }
    });
    checkDMGValueAll[level] = false
    this.setState({ checkDMGValueAll });

  }

  selectDMGValueAll = (DemographicValues, item) => {
    let { finalDemoList, checkDMGValueAll } = this.state;

    if (checkDMGValueAll && checkDMGValueAll[item]) {
      finalDemoList[item] = [];
      checkDMGValueAll[item] = false;
      this.setState({ finalDemoList, checkDMGValueAll }, () => {
        if (!(finalDemoList && finalDemoList[item] && finalDemoList[item].length > 0)) {
          delete finalDemoList[item];
          this.setState({ finalDemoList });
        }
      });
      console.log('checkDMGValueAll: ', checkDMGValueAll[item])

    }
    else {
      this.setState({ finalDemoList: {} }, () => {

        if (DemographicValues && DemographicValues[item] && DemographicValues[item].length > 0) {
          finalDemoList[item] = [];
          DemographicValues[item].forEach((value) => {
            finalDemoList[item].push(value);
          });
        }
        checkDMGValueAll[item] = true;
        console.log('checkDMGValueAll: ', checkDMGValueAll[item])
        this.setState({ finalDemoList, checkDMGValueAll }, () => {
          if (!(finalDemoList && finalDemoList[item] && finalDemoList[item].length > 0)) {
            delete finalDemoList[item];
            this.setState({ finalDemoList });
          }
        });


      });
    }



  }

  handleOpenEmployeeDrop = (check) => {
    this.setState({ showEmployeeDrop: check });
  }

  handleOpenEmployeeRoleUpdate = (check) => {
    this.setState({ showUserModalUPDATE: check });

  }

  giveAccessSubmit = () => {
    let { finalDemoList, selectedEmployeeData, AccessObj, crrSelectedSurveyList } = this.state;

    if (selectedEmployeeData && selectedEmployeeData.emp_id) {
      let data = {
        "empId": selectedEmployeeData.emp_id,
        "access": finalDemoList,
        "moduleAccess": AccessObj,
        "surveyAccess": crrSelectedSurveyList
      }
      console.log('data:', data)


      let tempData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 20000
      }



      this.props.dispatch(surveyActions.assignRole(data, tempData));
      this.setState({ showUserModalUPDATE: false, showUserModal: false, selectedEmployeeData: { "name": "", "emp_id": "", "employeeID": "", "userId": "" }, finalDemoList: {}, AccessObj: {}, checkDMGValueAll: {} });



      let data13 = {
        "keyWord": "",
        "pageNo": 1,
        "size": 20000
      }
      this.props.dispatch(surveyActions.getAssignedRoles(data13));



      this.addUserModal(false);


    }
  }

  removeAccessModal = (boolData) => {
    this.setState({ showClearAccessModal: true, crrboolData: boolData });
  }

  closeClearDeleteAccess = (value) => {
    this.setState({ showClearAccessModal: value });
  }

  removeAccessSubmit = () => {
    let { selectedEmployeeData, crrboolData } = this.state;

    if (selectedEmployeeData && selectedEmployeeData.emp_id) {
      let data = {
        "empId": selectedEmployeeData.emp_id,
        "userId": selectedEmployeeData.userId,
        "isDelete": crrboolData ? true : false
      }
      console.log('data:', data)


      let tempData = {
        "keyWord": "",
        "pageNo": 1,
        "size": 20000
      }



      this.props.dispatch(surveyActions.removeRole(data, tempData));
      this.setState({ showClearAccessModal: false, showUserModalUPDATE: false, showUserModal: false, selectedEmployeeData: { "name": "", "emp_id": "", "employeeID": "", "userId": "" }, finalDemoList: {}, AccessObj: {}, checkDMGValueAll: {} });



      let data13 = {
        "keyWord": "",
        "pageNo": 1,
        "size": 20000
      }
      this.props.dispatch(surveyActions.getAssignedRoles(data13));



      this.addUserModal(false);


    }
  }


  openAccessTab = (emp) => {
    this.selectEmployee(emp);
    // this.addUserModal(true);

  }

  selectEmployee = (empId) => {

    let { selectedEmployeeData } = this.state;

    if (selectedEmployeeData.emp_id === empId.id) {
      let data1 = {
        "name": "",
        "emp_id": "",
        "employeeID": "",
        "userId": ""
      }
      this.setState({ selectedEmployeeData: data1, showEmployeeDrop: false });

    } else {
      console.log('empId||||||------------>', empId);
      let data = {
        "name": empId.name,
        "emp_id": empId.id,
        "employeeID": empId.emp_id
      }
      console.log('data||||||------------>', data);

      this.setState({ selectedEmployeeData: data, showEmployeeDrop: false });
    }







  }

  //update------------
  openAccessUpdateTab = (emp) => {

    let { getAllFilterValuesData } = this.props.survey;

    let { finalDemoList, DMGLevelList, checkDMGValueAll } = this.state;

    let empData = {
      "name": emp && emp.employeeId && emp.employeeId.name ? emp.employeeId.name : "",
      "emp_id": emp && emp.employeeId && emp.employeeId.id ? emp.employeeId.id : "",
      "employeeID": emp && emp.employeeId && emp.employeeId.emp_id ? emp.employeeId.emp_id : "",

      "userId": emp && emp.id ? emp.id : "",

    }

    let moduleAObj = emp && emp.moduleAccess ? emp.moduleAccess : {};
    let accessAObj = emp && emp.access ? emp.access : {}
    let surveyAccessList = emp && emp.surveyAccess && emp.surveyAccess.length > 0 ? emp.surveyAccess : []

    finalDemoList = accessAObj;
    let ListKeys = Object.keys(accessAObj);


    let DemographicValues = {};

    if (ListKeys && ListKeys.length > 0) {
      ListKeys.forEach((item) => {

        DemographicValues[item] = getAllFilterValuesData[item];

        if (finalDemoList && finalDemoList[item] && DemographicValues && DemographicValues[item] && (finalDemoList[item].length === DemographicValues[item].length)) {
          checkDMGValueAll[item] = true;
        }


        DMGLevelList.push(item);
      });
    }

    console.log('emp----------->', emp)

    this.setState({ checkDMGValueAll, showUserModalUPDATE: true, finalDemoList, crrSelectedSurveyList: surveyAccessList, DMGLevelList, AccessObj: moduleAObj, selectedEmployeeData: empData });

    // this.selectEmployee(emp);
    // this.addUserModal(true);
  }



  handleScreenAcess = (value, name) => {
    let { AccessObj } = this.state;
    AccessObj[name] = value;
    this.setState({ AccessObj });
  }


  handleChangeTab = (check) => {
    // this.setState({ showALLUserRoles: check });
    this.setState({ showUserEmpList: check ? true : false });
    if (!check) {
      this.setState({ selectedEmployeeData: {} });
    }

    let data13 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 20000
    }
    this.props.dispatch(surveyActions.getAssignedRoles(data13));


    let datatempemployee = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": this.props.match.params.id,
      "size": this.state.size
    }

    this.props.dispatch(surveyActions.getEmployeeList(datatempemployee));


  }

  selectSurveys = (survey) => {
    let { crrSelectedSurveyList } = this.state;
    let getIndex = crrSelectedSurveyList.findIndex(prev => prev === survey.id);
    if (getIndex > -1) { crrSelectedSurveyList.splice(getIndex, 1) } else { crrSelectedSurveyList.push(survey.id) }
    this.setState({ crrSelectedSurveyList })
  }

  handleSidebar = (check) => {
    this.setState({ showSidebar: check })
  }
  handleMobileSidebar = (check) => {
    this.setState({ showMobileSidebar: check })
    this.setState({ showSidebar: check })
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });

    let datatempemployee = {
      keyWord: "",
      pageNo: data.selected + 1,
      companyId: this.props.match.params.id,
      size: this.state.size,
    }
    this.props.dispatch(surveyActions.getAssignedRoles(datatempemployee));
  }

  handleSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    this.setState({ size: newSize, page: 0, offset: 0 }, () => {
      let datatempemployee = {
        keyWord: "",
        pageNo: 1,
        companyId: this.props.match.params.id,
        size: newSize,
      };
      this.props.dispatch(surveyActions.getAssignedRoles(datatempemployee));
    });
  }

  render() {
    let { survey } = this.props;

    let { getSurveyListByRoleData, getAssignedRolesData, getAssignedRolesTotal, getAllFilterValuesData, loading, getEmployeeColumnNameData, getEmployeeFilterListByCompanyIdData, getLanguageLabelData, getLanguageData, getEmployeeListData, getEmployeeListTotal, getSurveyListData, } = survey;

    // console.log('getAssignedRolesTotalgetAssignedRolesTotalgetAssignedRolesTotalgetAssignedRolesTotal', getAssignedRolesTotal);

    let { showMobileSidebar, crrboolData, showClearAccessModal, searchName, checkDMGValueAll, showUserModalUPDATE, ComponentList, AccessList, AccessObj, showEmployeeDrop, selectedEmployeeData, finalDemoList, DMGLevelList, DMGValueList, showUserDmgLevel, showUserDmgValue, showALLUserRoles, showFilterNames, formDataFilter, openEmpFilterModal, showUserModal,
      showUserEmpList, showLanguageModal, CurrentLanguageName, CurrentLanguage, showSidebar, CrrRoute, crrSelectedSurveyList } = this.state;


    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;
    let { getCompanyNameOnlyData } = dashboard;

    let EmployeeFilterCompanyList = []
    if (getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length > 0) {
      getEmployeeFilterListByCompanyIdData.forEach((filter) => {
        if (!filter.isDisable) {
          EmployeeFilterCompanyList.push(filter);
        }
      });
    }
    //--------------------------------------------------------------------------------

    let IndexList = []
    let IndexListIndex = []

    if (getEmployeeListTotal) {


      if (getEmployeeListTotal) {
        [...Array(getEmployeeListTotal).keys()].forEach((item, index) => {
          if (index % this.state.size === 0) {
            IndexList.push(index)
          }
          if (index === (getEmployeeListTotal.length - 1)) {
            IndexList.push(index)
          }
        })
      }

      // console.log('IndexList: ', IndexList)

      if (IndexList && IndexList.length > 0) {
        IndexList.forEach((item, index) => {
          if (index !== (IndexList.length - 1)) {
            IndexListIndex.push(index + 1)
          }
        })
      }
      // console.log('IndexListIndex: ', IndexListIndex)


    }
    //--------------------------------------------------------------------------------
    let IndexListX1 = []
    let IndexListIndexx1 = []

    if (getAssignedRolesTotal) {

      if (getAssignedRolesTotal) {
        [...Array(getAssignedRolesTotal).keys()].forEach((item, index) => {
          console.log('this.state.usersize2: ', this.state.usersize2)
          console.log('index1: ', index)

          if (index % this.state.usersize2 === 0) {
            IndexListX1.push(index)
          }
          console.log('IndexListX2: ', IndexListX1)

          if (index === (getAssignedRolesTotal - 1)) {
            IndexListX1.push(index)
            console.log('IndexListX3: ', IndexListX1)
          }

        })
      }

      console.log('IndexListX1: ', IndexListX1)

      if (IndexListX1 && IndexListX1.length > 0) {
        IndexListX1.forEach((item, index) => {
          if (index !== (IndexListX1.length - 1)) {
            IndexListIndexx1.push(index + 1)
          }
        })
      }
      console.log('IndexListIndexx1: ', IndexListIndexx1)


    }
    //ROLE MANAGEMENT ----------------------------------------------

    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    // console.log('moduleAccess: ', moduleAccess)

    //ROLE MANAGEMENT ----------------------------------------------

    // let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        // SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        // SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        // SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        // SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    //END -------------------------------------------------------

    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }


    let { ClientProfileData } = this.props.dashboard;
    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageractionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }


    let EmployeeListData = [];
    // if(getEmployeeListData && getEmployeeListData.length>0){
    //   EmployeeListData = Object.key(getEmployeeListData)
    // }
    // console.log('getEmployeeListData: ',getEmployeeListData)
    // console.log('EmployeeListData: ',EmployeeListData)

    let EmployeeFieldList = []
    if (getEmployeeListData && getEmployeeListData.length > 0) {
      EmployeeFieldList = Object.keys(getEmployeeListData[0]);
    }
    // console.log('EmployeeFieldList: ', EmployeeFieldList)

    let NewEmployeeFieldList = []
    if (EmployeeFieldList && EmployeeFieldList.length > 0) {
      EmployeeFieldList.forEach((item) => {
        if (item !== "isDisable" && item !== "isTest" && item !== "createdAt" && item !== "id" && item !== "actionPlanStage" &&
          item !== "companyId" && item !== "email" && item !== "emp_id") {
          NewEmployeeFieldList.push(item);
        }
      });
    }

    if (EmployeeFieldList) {

    }

    let DemographicLevels = [];
    let DemographicValues = {};
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        DemographicLevels.push(emp.name);
      });
    }
    if (DMGLevelList && DMGLevelList.length > 0) {
      DMGLevelList.forEach((level) => {
        DemographicValues[level] = getAllFilterValuesData[level];
      });
    }

    const { page, size, offset } = this.state;
    const startItem = page * size + 1;
    const endItem = Math.min((page + 1) * size, getAssignedRolesTotal);



    console.log('startItemstartItemstartItemstartItem', startItem);
    console.log('endItemendItemendItemendItemendItemendItemendItem', endItem);
    console.log('getEmployeeListTotalgetEmployeeListTotal', getAssignedRolesTotal);
    console.log('getAssignedRolesDatagetAssignedRolesDatagetAssignedRolesData', getAssignedRolesData);

    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}
          spinner
          text='Loading Data...'
          className="loader"
        >


          <div className='h-screen overflow-hidden bg-gray-50'>

            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              props={this.props}
              companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}
              sidebarToggle={this.sidebarToggle}
            />

            <div className="flex w-full" style={{ height: 'calc(100% - 2rem)' }}>

              <Sidebar
                onOverview={this.onOverview}
                showSidebar={showSidebar}
                handleSidebar={this.handleSidebar}
                accessAllow={accessAllow}
                routeLink={this.routeLink}
                showMobileSidebar={this.state.showMobileSidebar}
                sidebarToggle={this.sidebarToggle}
              />
              <main className="flex-1 w-full overflow-hidden overflow-y-auto h-[calc(100vh-4rem)] bg-[#f9fafb]">



                {accessAllow("uploadUser") ?
                  <>
                    {/* header end */}
                    {/* main work*/}
                    {showALLUserRoles ?
                      <>

                        <div className="flex-1 md:p-6 p-4 w-full bg-[#f9fafb]  overflow-hidden overflow-y-auto h-[calc(100vh-4rem)]">
                          <div className="flex flex-col p-4 space-y-4 overflow-hidden bg-white border rounded-lg md:p-6">
                            <div className="items-stretch justify-between space-y-4 md:flex md:space-x-6 md:space-y-0 ">
                              <div className="flex space-x-3 items-center">
                                <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]">Users List</h1>
                                <div className="flex space-x-2 capitalize items-center text-sm text-[#3D405B] font-medium">
                                  <span>Show</span>
                                  <form class="w-16">
                                    <select id="entries" value={size} onChange={this.handleSizeChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full px-2 py-1.5 focus:outline-none">
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  </form>
                                  <span>entries</span>
                                </div>
                              </div>
                              <div className="flex space-x-4">
                                <div className="lg:w-auto w-full flex justify-between border px-4 text-[#3D405B]/70 bg-white rounded-md text-sm items-center  shadow-sm overflow-hidden">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /> <circle cx="10" cy="10" r="7" /> <line x1="21" y1="21" x2="15" y2="15" />
                                  </svg>
                                  <input type="search" name="search" onChange={this.handleSearchUser} placeholder={LanguageObj && LanguageObj["search_by_email_text_" + CurrentLanguage] ? LanguageObj["search_by_email_text_" + CurrentLanguage] : "Search by email..."} className="lg:w-60 w-full focus:outline-none px-2 py-2 text-[#3D405B] placeholder:font-normal font-medium " />
                                </div>

                                <button onClick={() => this.handleChangeTab(true)} className="cursor-pointer bg-[#2196F3] text-white space-x-1 text-sm  border-[#2196F3] flex justify-between px-4 items-center  py-2 rounded-md">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="9" />  <line x1="9" y1="12" x2="15" y2="12" />  <line x1="12" y1="9" x2="12" y2="15" /></svg><span>Assigned Access</span>
                                </button>
                              </div>
                            </div>

                            <div className="w-full overflow-x-auto scroll-smooth whitespace-nowrap">
                              <AllUserRoles
                                getAssignedRolesData={getAssignedRolesData}
                                LanguageObj={LanguageObj}
                                CurrentLanguage={CurrentLanguage}
                                openAccessUpdateTab={this.openAccessUpdateTab}
                                AccessList={AccessList}
                                ComponentList={ComponentList}
                                SelectEmpPagev1={this.SelectEmpPagev1}
                                // currentSurveyPageV1={this.state.currentSurveyPageV1}
                                // IndexListIndexx1={IndexListIndexx1}
                                startItem={startItem}
                                endItem={endItem}
                                offset={offset}
                                getAssignedRolesTotal={getAssignedRolesTotal}
                                size={size}
                              />
                            </div>

                          </div>
                        </div>

                      </>
                      : null}


                    {showUserEmpList ?

                      <div className="fixed top-0 left-0 z-40 flex items-center w-full h-screen p-4 py-8 overflow-hidden overflow-y-auto bg-black bg-opacity-40 md:p-0"  >
                        <div className="flex flex-col mx-auto mt-4 overflow-hidden bg-white border rounded-md 2xl:w-3/6 xl:w-4/6">
                          <div className="sticky top-0 flex items-start justify-between p-4 bg-white border-b "><div className="space-y-2"><h2 className="text-lg font-semibold md:text-xl test-gray-800 ">Create New Access</h2></div>
                            <div onClick={() => this.handleChangeTab(false)} className="p-2 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200 "><svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path></svg></div></div>
                          <div className="p-6">
                            <div className="flex flex-col justify-center space-y-6">

                              <div className='flex items-center justify-center -space-x-14'>
                                <div className='z-10 flex flex-col items-center space-y-2'>
                                  <div className='flex items-center justify-center w-10 h-10 text-lg font-semibold text-white bg-blue-500 rounded-full '>
                                    1
                                  </div>
                                  <p className='text-[#3D405B]'>Select Employee</p>
                                </div>
                                <div className='w-40 h-0.5 bg-[#C5C6CE] -mt-6'></div>
                                <div className='z-10 flex flex-col items-center space-y-2'>
                                  <div className='w-10 h-10 bg-[#C5C6CE] text-white flex justify-center items-center rounded-full text-lg font-semibold '>
                                    2
                                  </div>
                                  <p className='text-[#77798C]'>Assign Access</p>
                                </div>
                              </div>
                              <div className="flex items-center justify-between w-full mx-auto overflow-hidden text-sm bg-white border rounded-md md:w-4/6">
                                <input type="search" name="search" onChange={this.handleSearchEmployee} placeholder={LanguageObj && LanguageObj["search_by_email_text_" + CurrentLanguage] ? LanguageObj["search_by_email_text_" + CurrentLanguage] : "Search by Employee Name, Id or email..."} className="w-full px-4 py-2 focus:outline-none" />
                                <span onClick={this.handleSearchEmployeeNameAndEmail} className="p-2 text-blue-500 rounded-l-full cursor-pointer material-symbols-outlined"
                                  style={{ fontSize: '1.4rem' }}>search</span>
                              </div>
                            </div>
                          </div>


                          <div className="w-full overflow-x-auto whitespace-nowrap">
                            <AddUserEmployeeList
                              getEmployeeListData={getEmployeeListData}
                              LanguageObj={LanguageObj}
                              CurrentLanguage={CurrentLanguage}
                              openAccessTab={this.openAccessTab}
                              selectedEmployeeData={selectedEmployeeData}
                            />
                          </div>

                          <div className="flex items-center justify-center w-full h-16 bg-white ">
                            <div className="flex items-center">
                              <span onClick={() => this.SelectEmpPage(this.state.currentSurveyPage - 1, IndexListIndex)} className="p-2 text-gray-500 bg-gray-100 rounded-full cursor-pointer material-symbols-outlined hover:bg-blue-500 hover:text-white">chevron_left</span>
                              <div className="flex items-center mx-4 text-sm font-medium text-gray-500">
                                <p>{this.state.currentSurveyPage}</p>
                                <p className="px-1">of</p>
                                <p>{IndexListIndex && IndexListIndex.length > 0 ? IndexListIndex.length : 0}</p>
                              </div>
                              <span onClick={() => this.SelectEmpPage(this.state.currentSurveyPage + 1, IndexListIndex)} className="p-2 text-gray-500 bg-gray-100 rounded-full cursor-pointer material-symbols-outlined hover:bg-blue-500 hover:text-white">chevron_right</span>
                            </div>
                          </div>
                          <div className='flex justify-end p-6 border-t'>
                            <button onClick={() => this.handleUserContinue()} className="relative flex items-center justify-center w-full px-8 py-2 text-lg font-medium text-center text-white uppercase rounded-md cursor-pointer md:w-auto bg-gradient-to-r from-blue-500 to-pink-500">
                              Continue
                            </button>
                          </div>
                        </div>

                      </div>
                      : null}


                    {showUserModal ?
                      <UserAccessModal
                        handleOpenEmployeeDrop={this.handleOpenEmployeeDrop}
                        addUserModal={this.addUserModal}
                        handleOpenLevel={this.handleOpenLevel}
                        handleOpenValue={this.handleOpenValue}
                        selectDMGLevel={this.selectDMGLevel}
                        selectDMGValue={this.selectDMGValue}
                        selectDMGValueAll={this.selectDMGValueAll}
                        checkDMGValueAll={checkDMGValueAll}
                        handleSearch={this.handleSearch}
                        handleScreenAcess={this.handleScreenAcess}
                        giveAccessSubmit={this.giveAccessSubmit}
                        searchName={searchName}
                        type={showALLUserRoles ? "UPDATE" : "EDIT"}

                        showEmployeeDrop={showEmployeeDrop} selectedEmployeeData={selectedEmployeeData}
                        showUserDmgLevel={showUserDmgLevel} showUserDmgValue={showUserDmgValue}
                        DMGLevelList={DMGLevelList} finalDemoList={finalDemoList} AccessObj={AccessObj}
                        AccessList={AccessList} ComponentList={ComponentList}
                        DemographicLevels={DemographicLevels} DemographicValues={DemographicValues}

                        getSurveyListByRoleData={getSurveyListByRoleData}
                        selectSurveys={this.selectSurveys}
                        crrSelectedSurveyList={crrSelectedSurveyList}
                        isUpdate={!showALLUserRoles}
                      />
                      :
                      null}



                    {showUserModalUPDATE ?
                      <UserAccessUpdateModal
                        handleOpenEmployeeDrop={this.handleOpenEmployeeRoleUpdate}
                        addUserModal={this.addUserModal}
                        handleOpenLevel={this.handleOpenLevel}
                        handleOpenValue={this.handleOpenValue}
                        selectDMGLevel={this.selectDMGLevel}
                        selectDMGValue={this.selectDMGValue}
                        selectDMGValueAll={this.selectDMGValueAll}
                        checkDMGValueAll={checkDMGValueAll}
                        handleSearch={this.handleSearch}
                        handleScreenAcess={this.handleScreenAcess}
                        giveAccessSubmit={this.giveAccessSubmit}
                        removeAccessModal={this.removeAccessModal}
                        type={showALLUserRoles ? "UPDATE" : "EDIT"}

                        searchName={searchName}

                        showEmployeeDrop={showUserModalUPDATE} selectedEmployeeData={selectedEmployeeData}
                        showUserDmgLevel={showUserDmgLevel} showUserDmgValue={showUserDmgValue}
                        DMGLevelList={DMGLevelList} finalDemoList={finalDemoList} AccessObj={AccessObj}
                        AccessList={AccessList} ComponentList={ComponentList}
                        DemographicLevels={DemographicLevels} DemographicValues={DemographicValues}


                        getSurveyListByRoleData={getSurveyListByRoleData}
                        selectSurveys={this.selectSurveys}
                        crrSelectedSurveyList={crrSelectedSurveyList}
                        isUpdate={showALLUserRoles}
                      />
                      :
                      null}




                    {/* EMPLOYEE FILTER */}
                    {openEmpFilterModal ?
                      <div className="fixed top-0 z-40 flex items-center justify-center w-full h-full xl:h-screen" style={{ background: '#0000005c' }}>
                        <div className="w-11/12 bg-white rounded-lg shadow-sm xl:w-7/12 lg:w-6/12 md:w-7/12 ">
                          <div className="flex justify-between p-4 border-b"> <h2 className="text-base font-semibold text-center test-gray-800 ">Employee Filter</h2> <span onClick={() => this.closeEmployeeFilter(false)} className="text-sm font-medium text-red-500 cursor-pointer">Close</span></div>

                          <div className="flex p-1">
                            <div className="px-2 pt-2 font-bold cursor-default">ADD</div>
                            <div className="flex items-center justify-center lg:float-left ">
                              <div className="w-full">
                                <div className="py-1 ml-1 bg-white mr-1.5 lg:w-60 sm:w-full md:w-full rounded-lg border text-slate-800 flex justify-between px-4 items-center relative ">
                                  <span className="w-full pr-4 text-gray-500 cursor-pointer" onClick={() => this.onEmployeeFilterDropdown(!showFilterNames)}>{formDataFilter.name ? formDataFilter.name : "Choose Name"}</span>
                                  <span className="text-gray-500 cursor-pointer material-symbols-outlined " onClick={() => this.onEmployeeFilterDropdown(!showFilterNames)}>expand_more</span>
                                  {showFilterNames ?
                                    <>
                                      <div className="absolute left-0 z-10 w-full overflow-y-auto text-gray-500 bg-white rounded-md shadow-md top-10" style={{ height: '300px' }}>
                                        {getEmployeeColumnNameData && getEmployeeColumnNameData.length > 0 ?
                                          getEmployeeColumnNameData.map((item, index) =>
                                            (EmployeeFilterCompanyList.findIndex(prev => prev.name === item.name) === -1) ?
                                              <span key={index} onClick={() => this.handleEmployeeColumnSelect(item.name)} className="inline-flex items-center w-full px-4 py-3 text-xs border-b cursor-pointer hover:bg-gray-100">
                                                {item.name}</span>
                                              : null
                                          )
                                          : null}
                                      </div>
                                    </>
                                    : null}

                                </div>
                              </div>
                            </div>

                            <div className="flex p-1 pl-2">
                              <div className="pt-1 cursor-default ">Disable</div>
                              <div className="px-2 pt-1"><input
                                id="mandatory-check"
                                value={formDataFilter.isDisable}
                                checked={formDataFilter.isDisable}
                                type="checkbox"
                                onChange={() => { }}
                                className="pt-2 cursor-pointer"
                                onClick={() => this.handleFilterCheck(formDataFilter.isDisable)}
                              /></div>
                            </div>


                            <div className="pt-1.5 pl-1">
                              <button onClick={() => this.addEmployeeFilter()} className="cursor-pointer w-full border border-blue-500 bg-blue-500 text-white rounded py-1.5  text-xs  px-4">
                                {"Save Filter"}
                              </button>
                            </div>

                          </div>


                          <div className="flex flex-col pb-4 mx-2 overflow-y-auto" style={{ height: '300px' }}>
                            <div className="w-full py-2 overflow-x-auto whitespace-nowrap ">
                              <div className="inline-block min-w-full overflow-hidden align-middle border border-gray-200 sm:rounded-lg">
                                <table className="min-w-full">
                                  <thead>
                                    <tr>
                                      <th className="px-6 py-3 text-sm font-medium text-left capitalize border-b border-gray-200 bg-gray-50 text-slate-800">
                                        {LanguageObj && LanguageObj["s_no_label_" + CurrentLanguage] ? LanguageObj["s_no_label_" + CurrentLanguage] : "S No."}</th>
                                      <th className="px-6 py-3 text-sm font-medium text-left capitalize border-b border-gray-200 bg-gray-50 text-slate-800">
                                        {LanguageObj && LanguageObj["name_label_" + CurrentLanguage] ? LanguageObj["name_label_" + CurrentLanguage] : "Name"}</th>

                                      <th className="px-6 py-3 text-sm font-medium text-left capitalize border-b border-gray-200 bg-gray-50 text-slate-800">
                                        {LanguageObj && LanguageObj["enable_label_" + CurrentLanguage] ? LanguageObj["enable_label_" + CurrentLanguage] : "Enable"}</th>
                                      <th className="px-6 py-3 text-sm font-medium text-left capitalize border-b border-gray-200 bg-gray-50 text-slate-800">
                                        {LanguageObj && LanguageObj["delete_label_" + CurrentLanguage] ? LanguageObj["delete_label_" + CurrentLanguage] : "Delete"}</th>
                                    </tr>
                                  </thead>
                                  <tbody className="bg-white">
                                    {getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length > 0 ?
                                      getEmployeeFilterListByCompanyIdData.map((empFilter, index) =>
                                        <tr className={empFilter.isDisable ? "bg-gray-100" : "bg-white"}>
                                          <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                                            <div className="flex items-center">
                                              <div className="ml-4">
                                                <div className={!empFilter.isDisable ? "text-xs leading-5 text-gray-600" : "text-xs leading-5 text-gray-400"}>{index + 1}</div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className={!empFilter.isDisable ? "text-xs leading-5 text-gray-600" : "text-xs leading-5 text-gray-400"}>
                                            {empFilter && empFilter.label ? empFilter.label : ""}
                                          </td>
                                          <td className="px-6 py-4 text-xs font-semibold text-gray-800 whitespace-no-wrap border-b border-gray-200">
                                            <div className="">
                                              <input
                                                id="mandatory1"
                                                value={!empFilter.isDisable}
                                                checked={!empFilter.isDisable}
                                                type="checkbox"
                                                onChange={() => { }}
                                                className="ml-4 cursor-pointer"
                                                onClick={() => this.handleFilterStatus(empFilter.id)}
                                              />
                                            </div>
                                          </td>
                                          <td className={!empFilter.isDisable ? "text-xs leading-5 text-gray-800 font-semibold " : "text-xs leading-5 text-gray-400"}>
                                            <span onClick={() => this.onDeleteFilter(empFilter)} className="cursor-pointer material-symbols-outlined pl-7">delete_outline</span>
                                          </td>

                                        </tr>
                                      ) : null
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>


                          </div>

                        </div>


                      </div>
                      :
                      null}

                  </>
                  :
                  <>
                    {!loading ?
                      <div className="container h-full px-4 py-12 mx-auto overflow-hidden overflow-y-auto" style={{ height: 'calc(100% - 4rem)' }}>
                        <div className="flex items-center justify-center w-full h-full">
                          {/*  text start*/}
                          <div className="text-center">
                            <img alt='empty_survey' src="img/empty_survey.png" className="mb-4" />
                            <h1 className="text-xl font-semibold text-gray-500 cursor-default">You have no access <br /> to this module!</h1>
                          </div>
                        </div>
                      </div>
                      : null}
                  </>
                }


                {showClearAccessModal ?
                  <DeleteClearAccessConfirm
                    handleClose={this.closeClearDeleteAccess}
                    handleSubmit={this.removeAccessSubmit}
                    name={crrboolData ? "delete" : "clear"}
                  />
                  : null}



              </main>
            </div>
          </div>

        </LoadingOverlay >
      </>
    );
  }
}
function mapStateToProps(state) {
  ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard, user } = state;
  return {
    loggingIn,
    survey,
    dashboard,
    user
  };
}
export default connect(mapStateToProps)(Employees);
