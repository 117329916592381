import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionCard from './Components/QuestionTypeCards/QuestionCard';
import SingleChoiceChart from './Components/ReportWaiseData/SingleChoiceChart';
import DMGMatrix from './Components/ReportWaiseData/DMGMatrix';
import MultipleChoiceChart from './Components/ReportWaiseData/MultipleChoiceChart';
import NPSChart from './Components/ReportWaiseData/NPSChart';
import DMGNPS from './Components/ReportWaiseData/DMGNPS';
import NPSChartScale0to10 from './Components/ReportWaiseData/NPSChartScale0to10';
import YesNoChart from './Components/ReportWaiseData/YesNoChart';
import StarRatingChart from './Components/ReportWaiseData/StarRatingChart';
// import GroupQuestionsChart from './Components/ReportWaiseData/GroupQuestionsChart';
// import MatrixChart from './Components/ReportWaiseData/MatrixChart';
// import StatementChart from './Components/ReportWaiseData/StatementChart';
// import DateChart from './Components/ReportWaiseData/DateChart';
import DropdownChart from './Components/ReportWaiseData/DropdownChart';
import NumberChart from './Components/ReportWaiseData/NumberChart';
// import EmailChart from './Components/ReportWaiseData/EmailChart';
// import TextBoxChart from './Components/ReportWaiseData/TextBoxChart';
// import MultilineTextChart from './Components/ReportWaiseData/MultilineTextChart';
import PageTemplate from './Components/ReportWaiseData/PageTemplate';
import ResponseHeader from '../../CommonComponents/ResponseHeader';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import SingleChoiceChartV2 from './Components/PreviewReportPdf/SingleChoiceChart';
// import DMGMatrix from './Components/PreviewReportPdf/DMGMatrix';

import MultipleChoiceChartV2 from './Components/PreviewReportPdf/MultipleChoiceChart';
import NPSChartV2 from './Components/PreviewReportPdf/NPSChart';
import NPSChartScale0to10V2 from './Components/PreviewReportPdf/NPSChartScale0to10';
import YesNoChartV2 from './Components/PreviewReportPdf/YesNoChart';
import StarRatingChartV2 from './Components/PreviewReportPdf/StarRatingChart';
import DropdownChartV2 from './Components/PreviewReportPdf/DropdownChart';
import NumberChartV2 from './Components/PreviewReportPdf/NumberChart';
// import EmailChartV2 from './Components/PreviewReportPdf/EmailChart';
// import TextBoxChartV2 from './Components/PreviewReportPdf/TextBoxChart';
// import MultilineTextChartV2 from './Components/PreviewReportPdf/MultilineTextChart';

import PageTemplateV2 from './Components/PreviewReportPdf/PageTemplate';
import ReportHeader from "./Components/PreviewReportPdf/ReportHeader"
import ReportIntro from './Components/PreviewReportPdf/ReportIntro';
import moment from "moment";

import SurveyReport from './SurveyReport/SurveyReport';
import ResponseRate from './Components/PreviewReportPdf/ResponseRate';

class Overview extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    this.state = {
      managerDetailsIndexType: 0,
      showManagerList: true,
      CurrentManagerName: 'Select Manager',
      showOverview: true,
      showSurveyReport: false,
      showAllQuestions: false,

      dropdownOpen: false,
      selectedOption: 'Bar chart', // Default selected option

      CurrentTemplateId: '',
      allIndexList: [
        {
          "name": "Happiness",
          "template": 1,
          "score": "NaN",
          "type": "Score",
          "color": "#FF4081",
          "image": "/img/Dashboard/Index/happiness_index.png"
        },
        {
          "name": "Engagement",
          "template": 2,
          "score": "NaN",
          "type": "Percentage",
          "color": "#2196F3",
          "image": "/img/Dashboard/Index/engagement_index.png"
        },
        {
          "name": "Hope",
          "template": 3,
          "score": "NaN",
          "type": "Percentage",
          "color": "#009688",
          "image": "/img/Dashboard/Index/hope_index.png"
        },
        {
          "name": "Stress",
          "template": 4,
          "score": "NaN",
          "type": "Percentage",
          "color": "#EF5350",
          "image": "/img/Dashboard/Index/stress_index.png"
        },
        {
          "name": "Manager",
          "template": 5,
          "score": "NaN",
          "type": "Percentage",
          "color": "#9C27B0",
          "image": "/img/Dashboard/Index/manager_index.png"
        },
        {
          "name": "Leadership",
          "template": 6,
          "score": "NaN",
          "type": "Percentage",
          "color": "#FFB300",
          "image": "/img/Dashboard/Index/leadership_index.png"
        },
        {
          "name": "Value",
          "template": 7,
          "score": "NaN",
          "type": "Percentage",
          "color": "#C0CA33",
          "image": "/img/Dashboard/Index/value_index.png"
        },
        {
          "name": "Safety",
          "template": 9,
          "score": "NaN",
          "type": "Percentage",
          "color": "#00CD7E",
          "image": "/img/Dashboard/Index/safety_index.png"
        },
        {
          "name": "Diversity",
          "template": 10,
          "score": "NaN",
          "type": "Percentage",
          "color": "#3F51B5",
          "image": "/img/Dashboard/Index/diversity_index.png"
        }
      ],

      isPreviewModelOpen: false,
      showPDFButton: false

    }
  }

  componentDidMount() {

    setTimeout(() => {
      this.setState({ showPDFButton: true })
    }, 2000)

  }

  openSurveyResponse = () => {
    this.setState({
      showSurveyReport: true,
      showOverview: false,
      showAllQuestions: false
    });

  }

  closeResponseAnalytics = () => {
    this.setState({
      showSurveyReport: false,
      showOverview: true,
      showAllQuestions: false
    })
    let { storeInnerRoute } = this.props;
    storeInnerRoute(false)
  }

  goToOverview = (item) => {
    let { overviewAPI } = this.props;
    overviewAPI();
    this.setState({ CurrentTemplateId: "" })
    this.setState({
      showSurveyReport: false,
      showOverview: true,
      showAllQuestions: false
    })
  }

  handleGoBack = () => {
    this.setState({
      showOverview: true,
      showSurveyReport: false,
    });
  }

  handleDropdownToggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  handleOptionClick = (option) => {
    this.setState({
      selectedOption: option,
      dropdownOpen: false // Close dropdown after selection
    });
  };

  exportPDFWithComponent = () => {
    if (this.pdfExportComponent.current) {
      this.pdfExportComponent.current.save();
    }
  };

  reportPreviewModel = () => {
    this.setState(prevState => ({
      isPreviewModelOpen: !prevState.isPreviewModelOpen
    }));
  };



  render() {

    let { showPDFButton, showOverview, showSurveyReport, CurrentTemplateId, dropdownOpen, selectedOption } = this.state;
    let { getQuestionDMGResultData, surveyName, EmpFilterData, CurrentSurveyId, getRespondantDetailsData, getIndexFilterData, getQuestionOthersResultData, getSurveyData, onOthersCardClick } = this.props;
    console.log("getQuestionDMGResultData------------------------------->", getQuestionDMGResultData)
    function FormattFun(inputData, isSummary) {
      let RankObj = getSurveyData && getSurveyData.designObj && getSurveyData.designObj.componentRank ? getSurveyData.designObj.componentRank : {}
      let LIST = []
      let prevTemList = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {
          let templateId = ques.total[0]._id.qtemplate;
          let temp = {
            name: ques && ques.question && ques.question.name ? ques.question.name : "",
            answerList: [],
            rank: RankObj && RankObj[templateId] ? RankObj[templateId] : 100,
            templateId: templateId
          }
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading: item && item._id && item._id.heading ? item._id.heading : "",
                parentId: item && item._id && item._id.parentId ? item._id.parentId : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId
              }
              temp["answerList"].push(ans);
            });
          }
          if (isSummary) {
            let getIndex = prevTemList.findIndex(prev => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId)
            }
          }
          else {
            LIST.push(temp);
          }
        });
      }
      return LIST
    }

    let OthersQuesList = FormattFun(getQuestionOthersResultData, true).sort((a, b) => a.rank - b.rank);
    let { templateList, getQuestionMatrixResultData, questionsList, onAllQuestions, onAllMatrixQuestions, surveyLogo } = this.props;

    // console.log('getQuestionOthersResultData1111111111111111111111111111111111111111', getQuestionOthersResultData);

    let ParticipationObj = { total: 0, completed: 0, mailSent: 0, clicked: 0, participated: 0, performance: 0, perfPlusSign: true }

    if (getRespondantDetailsData && getRespondantDetailsData[0]) {
      let apiData = getRespondantDetailsData[0]
      ParticipationObj['total'] = apiData['total'] ? apiData['total'] : 0;
      ParticipationObj['completed'] = apiData['completed'] ? apiData['completed'] : 0;
      ParticipationObj['mailSent'] = apiData['mailSent'] ? apiData['mailSent'] : 0;
      ParticipationObj['clicked'] = apiData['clicked'] ? apiData['clicked'] : 0;
      ParticipationObj['participated'] = apiData['completed'] && apiData['total'] ? (apiData['completed'] * 100 / apiData['total']).toFixed(2) : 0;
    }

    let responseOption = {
      "item1": {
        "label": "Sent",
        "value": ParticipationObj['total']
      },
      "item2": {
        "label": "Responded",
        "value": ParticipationObj['completed']
      },
      "item3": {
        "label": "Response Rate",
        "value": ParticipationObj['participated'] + '%'
      }
    }




    function getQuestionName(resQues, field) {
      return (resQues && resQues.question && resQues.question && resQues.question[0] && resQues.question[0][field] ? resQues.question[0][field] : "")
    }


    let QuestionDataList = []
    if (getQuestionOthersResultData && getQuestionOthersResultData.length > 0) {
      getQuestionOthersResultData.forEach((res) => {

        let temp = {
          "total": [],
          "question": {
            "name": getQuestionName(res, "name"),
            "id": getQuestionName(res, "_id")
          },
          "rank": getQuestionName(res, "rank"),
          "templateId": getQuestionName(res, "templateId")
        }

        if (res && res.total && res.total.length > 0) {
          res.total.forEach((inner) => {
            inner["_id"]["answerText"] = inner["_id"]["answer"];
            inner["_id"]["qtemplate"] = getQuestionName(res, "templateId");
            temp["total"].push(inner)
          });
        }


        if (getQuestionName(res, "name") && templateList.includes(temp["templateId"]) && validAnswers(temp)) {
          QuestionDataList.push(temp)
        }

      });


      QuestionDataList.sort((a, b) => a.rank - b.rank);

    }


    console.log("QuestionDataList--X1X------------->", QuestionDataList)

    let createdAt = moment(Date.now()).format("DD MMMM, YYYY");


    function validAnswers(ele) {
      let count = 0;
      let total = ele && ele.total ? ele.total : [];
      total.forEach((item) => {
        let answer = item._id && item._id.answerText ? item._id.answerText : '';
        if (answer && answer.trim()) {
          count++
        }
      });

      return count > 0 ? true : false
    }

    return (
      <>
        <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto md:p-6 p-4" style={{ height: 'calc(100% - 7rem)' }}>

          {showOverview ?
            <>

              <div className="space-y-4">
                <div className='flex items-start justify-between space-x-4 md:items-center'>
                  <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]">Dashboard</h1>
                  <button onClick={this.reportPreviewModel} class=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"  >    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>    <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>    <path d="M10 12l4 4m0 -4l-4 4"></path>
                    </svg>
                    <span>Download Report</span>
                  </button>
                </div>

                {this.state.isPreviewModelOpen && (
                  <>
                    <div className='report-section'>
                      <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-40">
                        <div class="mx-auto overflow-hidden overflow-y-auto bg-white rounded-md 2xl:w-3/6 xl:w-4/6 lg:w-5/6 customscroll5" style={{ height: "calc(-9rem + 100vh)" }}>

                          <div className="sticky top-0 z-20 flex flex-col items-center justify-between p-4 px-6 bg-white border-b md:flex-row"  >
                            <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B] line-clamp-1">
                              Report
                            </h1>

                            <div className='flex items-center space-x-4'>

                              {showPDFButton ?
                                <button onClick={() => this.exportPDFWithComponent()} class=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"><svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M14 3v4a1 1 0 0 0 1 1h4"></path><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path><path d="M10 12l4 4m0 -4l-4 4"></path></svg><span> Download Report</span></button>
                                :
                                <button class=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"><svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M14 3v4a1 1 0 0 0 1 1h4"></path><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path><path d="M10 12l4 4m0 -4l-4 4"></path></svg><span> Loading... </span></button>
                              }

                              <div onClick={() => this.reportPreviewModel(false)} className="inline-flex items-center justify-center w-10 h-10 text-gray-500 bg-gray-100 rounded-full cursor-pointer hover:text-gray-800" >
                                <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                  <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <PDFExport
                            ref={this.pdfExportComponent}
                            pageTemplate={(pageObj) => (
                              <PageTemplate
                                pageNum={pageObj.pageNum}
                                surveyLogo={surveyLogo}
                              />
                            )}
                            scale={0.7}
                            forcePageBreak=".page-break"
                            paperSize="A4"
                            margin={0}
                            fileName={`${surveyName} Report - ${createdAt}`}

                            author="Happyplus Team"
                            landscape={false}
                          >
                            <ReportIntro
                              surveyLogo={surveyLogo}
                              surveyName={surveyName}
                            />

                            <div className='page-break'>
                              <ReportHeader
                                surveyName={surveyName}
                                createdAt={createdAt}
                              />
                              <ResponseRate
                                responseOption={responseOption}
                              />
                            </div>

                            <div className='relative w-full space-y-4'>
                              {QuestionDataList && QuestionDataList.length > 0 ?
                                QuestionDataList.map((item, index) =>
                                  (templateList.includes(parseInt(item.templateId))) ?
                                    <>

                                      <div key={index} className='page-break'>

                                        {item.templateId === 1 ?
                                          <div>
                                            <ReportHeader
                                              surveyName={surveyName}
                                              createdAt={createdAt}
                                            />
                                            <SingleChoiceChartV2 item={item} index={index} onOthersCardClick={onOthersCardClick} />
                                          </div>
                                          : null}

                                        {item.templateId === 2 ?
                                          <div>
                                            <ReportHeader
                                              surveyName={surveyName}
                                              createdAt={createdAt}
                                            />
                                            <MultipleChoiceChartV2 item={item} index={index} onOthersCardClick={onOthersCardClick} />
                                          </div>
                                          : null}

                                        {/* {item.templateId === 3 ?
                                          <div>
                                            <ReportHeader
                                              surveyName={surveyName}
                                              createdAt={createdAt}
                                            />
                                            <TextBoxChartV2 item={item} index={index} onOthersCardClick={onOthersCardClick} />
                                          </div>
                                          : null} */}

                                        {/* {item.templateId === 4 ?
                                          <div>
                                            <ReportHeader
                                              surveyName={surveyName}
                                              createdAt={createdAt}
                                            />
                                            <MultilineTextChartV2 item={item} index={index} onOthersCardClick={onOthersCardClick} />
                                          </div>
                                          : null} */}

                                        {item.templateId === 5 ?
                                          <div>
                                            <ReportHeader
                                              surveyName={surveyName}
                                              createdAt={createdAt}
                                            />
                                            <StarRatingChartV2 item={item} index={index} onOthersCardClick={onOthersCardClick} />
                                          </div>
                                          : null}

                                        {item.templateId === 6 ?
                                          <div>
                                            <ReportHeader
                                              surveyName={surveyName}
                                              createdAt={createdAt}
                                            />
                                            <NPSChartV2 item={item} index={index} onOthersCardClick={onOthersCardClick} />
                                          </div>
                                          : null}

                                        {/* {item.templateId === 7 ?
                                          <div>
                                            <ReportHeader
                                              surveyName={surveyName}
                                              createdAt={createdAt}
                                            />
                                            <EmailChartV2 item={item} index={index} onOthersCardClick={onOthersCardClick} />
                                          </div>
                                          : null} */}

                                        {item.templateId === 8 ?
                                          <div>
                                            <ReportHeader
                                              surveyName={surveyName}
                                              createdAt={createdAt}
                                            />
                                            <NumberChartV2 item={item} index={index} onOthersCardClick={onOthersCardClick} />
                                          </div>
                                          : null}

                                        {item.templateId === 9 ?
                                          <div>
                                            <ReportHeader
                                              surveyName={surveyName}
                                              createdAt={createdAt}
                                            />
                                            <DropdownChartV2 item={item} index={index} onOthersCardClick={onOthersCardClick} />
                                          </div>
                                          : null}

                                        {item.templateId === 11 ?
                                          <div>
                                            <ReportHeader
                                              surveyName={surveyName}
                                              createdAt={createdAt}
                                            />
                                            <YesNoChartV2 item={item} index={index} onOthersCardClick={onOthersCardClick} />
                                          </div>
                                          : null}

                                        {item.templateId === 16 ?
                                          <div>
                                            <ReportHeader
                                              surveyName={surveyName}
                                              createdAt={createdAt}
                                            />
                                            <NPSChartScale0to10V2 item={item} index={index} onOthersCardClick={onOthersCardClick} />
                                          </div>
                                          : null}

                                      </div>

                                    </>
                                    : null
                                ) : null}



                            </div>


                            <div>

                            </div>
                          </PDFExport>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div onClick={() => this.openSurveyResponse()}>
                  <ResponseHeader responseOption={responseOption} />
                </div>


                <div className='relative w-full space-y-4'>


                  {getQuestionDMGResultData && getQuestionDMGResultData.length > 0 ?
                    getQuestionDMGResultData.map((item, index) =>
                      <div key={index} className='page-break'>
                        {item.templateId === 21 ?
                          <div>
                            <ReportHeader
                              surveyName={surveyName}
                              createdAt={createdAt}
                            />
                            <DMGMatrix item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                          : null}

                        {item.templateId === 22 ?
                          <div>
                            <ReportHeader
                              surveyName={surveyName}
                              createdAt={createdAt}
                            />
                            <DMGNPS item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                          : null}

                      </div>
                    ) : null}



                  {QuestionDataList && QuestionDataList.length > 0 ?
                    QuestionDataList.map((item, index) =>
                      (templateList.includes(parseInt(item.templateId))) ?
                        <>

                          <div key={index} className="">

                            {item.templateId === 1 ?
                              <SingleChoiceChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                              : null}

                            {item.templateId === 2 ?
                              <MultipleChoiceChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                              : null}

                            {/* {item.templateId === 3 ?
                              <TextBoxChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                              : null} */}

                            {/* {item.templateId === 4 ?
                              <MultilineTextChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                              : null} */}

                            {item.templateId === 5 ?
                              <StarRatingChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                              : null}

                            {item.templateId === 6 ?
                              <NPSChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                              : null}

                            {/* {item.templateId === 7 ?
                              <EmailChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                              : null} */}

                            {item.templateId === 8 ?
                              <NumberChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                              : null}

                            {item.templateId === 9 ?
                              <DropdownChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                              : null}

                            {item.templateId === 11 ?
                              <YesNoChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                              : null}

                            {item.templateId === 16 ?
                              <NPSChartScale0to10 item={item} index={index} onOthersCardClick={onOthersCardClick} />
                              : null}

                            {/* {item.templateId === 13 ?
                              <MatrixChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                              : null} */}

                            {/* {item.templateId === 14 ?
                              <GroupQuestionsChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                              : null} */}

                          </div>

                        </>
                        : null
                    ) : null}


                  {/* {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                      getQuestionOthersResultData
                        .filter(item => item.templateId === 1)
                        .map((item, index) =>
                          <div key={index}>
                            <SingleChoiceChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                        )
                      : null}

                    {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                      getQuestionOthersResultData
                        .filter(item => item.templateId === 2)
                        .map((item, index) =>
                          <div key={index}>
                            <MultipleChoiceChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                        )
                      : null}

                    {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                      getQuestionOthersResultData
                        .filter(item => item.templateId === 6)
                        .map((item, index) =>
                          <div key={index}>
                            <NPSChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                        )
                      : null}

                    {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                      getQuestionOthersResultData
                        .filter(item => item.templateId === 11)
                        .map((item, index) =>
                          <div key={index}>
                            <YesNoChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                        )
                      : null}

                    {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                      getQuestionOthersResultData
                        .filter(item => item.templateId === 5)
                        .map((item, index) =>
                          <div key={index}>
                            <StarRatingChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                        )
                      : null}

                    {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                      getQuestionOthersResultData
                        .filter(item => item.templateId === 3)
                        .map((item, index) =>
                          <div key={index}>
                            <GroupQuestionsChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                        )
                      : null}

                    {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                      getQuestionOthersResultData
                        .filter(item => item.templateId === 4)
                        .map((item, index) =>
                          <div key={index}>
                            <MatrixChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                        )
                      : null}

                    {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                      getQuestionOthersResultData
                        .filter(item => item.templateId === 10)
                        .map((item, index) =>
                          <div key={index}>
                            <StatementChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                        )
                      : null} */}

                  {/* {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                      getQuestionOthersResultData
                        .filter(item => item.templateId === 6)
                        .map((item, index) =>
                          <div key={index}>
                            <DateChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                        ) : null} */}


                  {/* {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                      getQuestionOthersResultData
                        .filter(item => item.templateId === 8)
                        .map((item, index) =>
                          <div key={index}>
                            <DropdownChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                        ) : null}

                    {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                      getQuestionOthersResultData
                        .filter(item => item.templateId === 9)
                        .map((item, index) =>
                          <div key={index}>
                            <NumberChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                        ) : null}

                    {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                      getQuestionOthersResultData
                        .filter(item => item.templateId === 10)
                        .map((item, index) =>
                          <div key={index}>
                            <EmailChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                        ) : null}

                    {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                      getQuestionOthersResultData
                        .filter(item => item.templateId === 12)
                        .map((item, index) =>
                          <div key={index}>
                            <TextBoxChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                        ) : null}

                    {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                      getQuestionOthersResultData
                        .filter(item => item.templateId === 13)
                        .map((item, index) =>
                          <div key={index}>
                            <MultilineTextChart item={item} index={index} onOthersCardClick={onOthersCardClick} />
                          </div>
                        ) : null} */}

                </div>


                <div className="grid grid-cols-1 gap-6 ">

                  {/* {OthersQuesList && OthersQuesList.length > 0 ? OthersQuesList.map((item) =>
                    <div
                      onClick={() => onAllQuestions(item)}
                      className="p-4 bg-white">
                      <QuestionCard item={item} name={""} isOverview={true} />
                    </div>
                  ) : null} */}

                  {getQuestionMatrixResultData && getQuestionMatrixResultData.length > 0 ?
                    <div onClick={() => onAllMatrixQuestions()}>
                      <QuestionCard
                        questionType={"matrix"}
                        getQuestionMatrixResultData={getQuestionMatrixResultData}
                        questionsList={questionsList}
                      />
                    </div>
                    : null}

                </div>

              </div>
            </>
            :
            null
          }

          {
            showSurveyReport ?
              <div className="px-2">
                <SurveyReport
                  CurrentSurveyId={CurrentSurveyId}
                  closeResponseAnalytics={this.closeResponseAnalytics}
                  EmpFilterData={EmpFilterData}
                  handleGoBack={this.handleGoBack}
                  getIndexFilterData={getIndexFilterData}
                  getRespondantDetailsData={getRespondantDetailsData}
                />
              </div>
              : null
          }

        </main >
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
