import {
  questionsConstants
} from '../_constants';

export function questions(state = {}, action) {

  switch (action.type) {


      case questionsConstants.QUESTIONS_GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case questionsConstants.QUESTIONS_GETALL_SUCCESS:
        return {
          ...state,
          loading: false,
          questionsList: action.questions.questionsList,
          answersList: action.questions.answersList,
          surveyScreenData: action.questions.surveyScreenData
        };
      case questionsConstants.QUESTIONS_GETALL_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };


        case questionsConstants.COPY_QUESTIONS_REQUEST:
          return {
            ...state,
            loading: true
          };
        case questionsConstants.COPY_QUESTIONS_SUCCESS:
          return {
            ...state,
            loading: false,
          };
        case questionsConstants.COPY_QUESTIONS_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  

    case questionsConstants.ADD_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true

      };
    case questionsConstants.ADD_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        addQuestionsSuccess: true,
        createQuestion:action.questions.createQuestion
      };
    case questionsConstants.ADD_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



      case questionsConstants.DELETE_MANY_QUESTIONS_REQUEST:
        return {
          ...state,
          loading: true
  
        };
      case questionsConstants.DELETE_MANY_QUESTIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          addQuestionsSuccess: true
        };
      case questionsConstants.DELETE_MANY_QUESTIONS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };






      case questionsConstants.DELETE_QUESTIONS_REQUEST:
        return {
          ...state,
          loading: true

        };
      case questionsConstants.DELETE_QUESTIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          deleteQuestionsSuccess: true
        };
      case questionsConstants.DELETE_QUESTIONS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };

    case questionsConstants.UPDATE_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true

      };
    case questionsConstants.UPDATE_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateQuestionsSuccess: true
        // questionsList: action.questions.questionsList,
        // answersList: action.questions.answersList
      };
    case questionsConstants.UPDATE_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      case questionsConstants.DELETE_QUESTIONOPTION_REQUEST:
        return {
          ...state,
          loading: true
        };
      case questionsConstants.DELETE_QUESTIONOPTION_SUCCESS:
        return {
          ...state,
          loading: false,
          deleteQuestionOption: true
        };
      case questionsConstants.DELETE_QUESTIONOPTION_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };

      case questionsConstants.ANSWERS_SUBMIT_REQUEST:
        return {
          ...state,
          loading: true
        };
      case questionsConstants.ANSWERS_SUBMIT_SUCCESS:
        return {
          ...state,
          loading: false,
          answerSubmitSuccess: true
        };
      case questionsConstants.ANSWERS_SUBMIT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };



        case questionsConstants.UPDATE_QUESTIONS_RANK_REQUEST:
          return {
            ...state,
            loading: true
          };
        case questionsConstants.UPDATE_QUESTIONS_RANK_SUCCESS:
          return {
            ...state,
            loading: false,
            updateQuestionRankSuccess: true
          };
        case questionsConstants.UPDATE_QUESTIONS_RANK_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
        


          case questionsConstants.COPY_QUES_LIBRARY_REQUEST:
            return {
              ...state,
              loading: true
            };
          case questionsConstants.COPY_QUES_LIBRARY_SUCCESS:
            return {
              ...state,
              loading: false,
              copyFromQuestionLibrarySuccess: true,
              copyFromQuestionLibraryData: action.questions.copyFromQuestionLibraryData,
            };
          case questionsConstants.COPY_QUES_LIBRARY_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };



            case questionsConstants.TRANSLATE_QUESTION_REQUEST:
              return {
                ...state,
                loading: true
              };
            case questionsConstants.TRANSLATE_QUESTION_SUCCESS:
              return {
                ...state,
                loading: false,
                translateQuestionSuccess: true,
                translateQuestionData: action.questions.translateQuestionData,
              };
            case questionsConstants.TRANSLATE_QUESTION_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };
  

            case questionsConstants.TRANSLATE_MANY_QUESTION_REQUEST:
              return {
                ...state,
                loading: true
              };
            case questionsConstants.TRANSLATE_MANY_QUESTION_SUCCESS:
              return {
                ...state,
                loading: false,
                translateManyQuestionSuccess: true,
              };
            case questionsConstants.TRANSLATE_MANY_QUESTION_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };



            case questionsConstants.TRANSLATE_SURVEY_REQUEST:
              return {
                ...state,
                loading: true
              };
            case questionsConstants.TRANSLATE_SURVEY_SUCCESS:
              return {
                ...state,
                loading: false,
                translateSurveySuccess: true,
                translateSurveyData: action.questions.translateSurveyData,
              };
            case questionsConstants.TRANSLATE_SURVEY_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };
    


              case questionsConstants.GET_ALL_QUESTION_LIST_TYPE1_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case questionsConstants.GET_ALL_QUESTION_LIST_TYPE1_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  getType1AllQuestionListSuccess: true,
                  getType1AllQuestionListData: action.questions.getType1AllQuestionListData,
                };
              case questionsConstants.GET_ALL_QUESTION_LIST_TYPE1_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };
      


          // case questionsConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST:
          //   return {
          //     ...state,
          //     loading: true
          //   };
          // case questionsConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS:
          //   return {
          //     ...state,  
          //     addParameterSuccess: false,      
          //     updateParameterSuccess: false,
     
          //     getParameterListbyParentId: action.questions.getParameterListbyParentId
          //   };
          // case questionsConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_FAILURE:
          //   return {
          //     ...state,
          //     error: action.error
          //   };


          
          case questionsConstants.UPDATE_SURVEY_PARAMETER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case questionsConstants.UPDATE_SURVEY_PARAMETER_SUCCESS:
            return {
              ...state,
              updateSurveyParameterSuccess:true,
              updateSurveyParameterData: action.questions.updateSurveyParameterData
    
            };
          case questionsConstants.UPDATE_SURVEY_PARAMETER_FAILURE:
            return {
              ...state,
              error: action.error
            };






          case questionsConstants.SURVEY_QUESTIONS_GETALL_REQUEST:
            return {
              ...state,
              loading: true
            };
          case questionsConstants.SURVEY_QUESTIONS_GETALL_SUCCESS:
            return {
              ...state,
              questionsList: action.questions.questionsList,
              answersList: action.questions.answersList,
              surveyScreenData: action.questions.surveyScreenData,
              surveyStatus: action.questions.surveyStatus,
              langList: action.questions.langList,
              GroupQuestionOnlyList: action.questions.GroupQuestionOnlyList
    
    
    
            };
          case questionsConstants.SURVEY_QUESTIONS_GETALL_FAILURE:
            return {
              ...state,
              error: action.error
            };



            case questionsConstants.GET_SURVEY_TEMPLATE_CODE_REQUEST:
              return {
                ...state,
                loading: true
              };
            case questionsConstants.GET_SURVEY_TEMPLATE_CODE_SUCCESS:
              return {
                ...state,
                getSurveyTemplateByCodeDataSuccess:true,
                getSurveyTemplateByCodeData: action.questions.getSurveyTemplateByCodeData
      
              };
            case questionsConstants.GET_SURVEY_TEMPLATE_CODE_FAILURE:
              return {
                ...state,
                error: action.error
              };
  


              case questionsConstants.GET_LANGUAGE_LABEL_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case questionsConstants.GET_LANGUAGE_LABEL_SUCCESS:
                return {
                  ...state,
                  getLanguageLabelSuccess:true,
                  getLanguageLabelData: action.questions.getLanguageLabelData
        
                };
              case questionsConstants.GET_LANGUAGE_LABEL_FAILURE:
                return {
                  ...state,
                  error: action.error
                };




                case questionsConstants.GET_AUTO_CHANGE_DASH_TYPE_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case questionsConstants.GET_AUTO_CHANGE_DASH_TYPE_SUCCESS:
                  return {
                    ...state,
                    autoChangeDashboardTypeSuccess:true,
                    autoChangeDashboardTypeData: action.questions.autoChangeDashboardTypeData
          
                  };
                case questionsConstants.GET_AUTO_CHANGE_DASH_TYPE_FAILURE:
                  return {
                    ...state,
                    error: action.error
                  };
  
  

                  case questionsConstants.ON_SURVEY_ALL_QUE_SECONDS_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case questionsConstants.ON_SURVEY_ALL_QUE_SECONDS_SUCCESS:
                    return {
                      ...state,
                      onSurveyAllQueSecondsSuccess:true,
                      onSurveyAllQueSecondsData: action.questions.onSurveyAllQueSecondsData
            
                    };
                  case questionsConstants.ON_SURVEY_ALL_QUE_SECONDS_FAILURE:
                    return {
                      ...state,
                      error: action.error
                    };
    

                    case questionsConstants.UPDATE_QUES_ALL_MANDATORY_REQUEST:
                      return {
                        ...state,
                        loading: true
                      };
                    case questionsConstants.UPDATE_QUES_ALL_MANDATORY_SUCCESS:
                      return {
                        ...state,
                        updateQuesAllMandatorySuccess:true,
                        updateQuesAllMandatoryData: action.questions.updateQuesAllMandatoryData
              
                      };
                    case questionsConstants.UPDATE_QUES_ALL_MANDATORY_FAILURE:
                      return {
                        ...state,
                        error: action.error
                      };
    


                      case questionsConstants.UPDATE_QUES_DMG_REQUEST:
                        return {
                          ...state,
                          loading: true
                        };
                      case questionsConstants.UPDATE_QUES_DMG_SUCCESS:
                        return {
                          ...state,
                          updateQuestionDMGSuccess:true,
                          updateQuestionDMGData: action.questions.updateQuestionDMGData
                
                        };
                      case questionsConstants.UPDATE_QUES_DMG_FAILURE:
                        return {
                          ...state,
                          error: action.error
                        };



                      

                      case questionsConstants.ADD_NUDGE_TO_QUES_REQUEST:
                        return {
                          ...state,
                          loading: true
                        };
                      case questionsConstants.ADD_NUDGE_TO_QUES_SUCCESS:
                        return {
                          ...state,
                          addNudgeToQuestionSuccess:true,
                          addNudgeToQuestionData: action.questions.addNudgeToQuestionData,
                          loading: false
                
                        };
                      case questionsConstants.ADD_NUDGE_TO_QUES_FAILURE:
                        return {
                          ...state,
                          loading: false,
                          error: action.error
                        };
      




                  case questionsConstants.SAVE_PREVIEW_SUCCESS:
                    return {
                      ...state,
                      answerSubmitSuccess: true
                    };

                    
                  case questionsConstants.SAVE_PREVIEW_CLEAR_SUCCESS:
                    return {
                      ...state,
                      answerSubmitSuccess: false
                    };
                    

    default:
      return state
  }
}