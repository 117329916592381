import React, { Component } from 'react';
import { connect } from 'react-redux';

import ClusterAnalysis from './Components/ClusterAnalysis/ClusterAnalysis';
import NudgeMapping from './Components/NudgeMapping/NudgeMapping';


class AdvanceComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 1,
      DimensionList: [
        {
          index: 1,
          name: 'Happiness'
        },
        {
          index: 2,
          name: 'Engagement'
        },
        {
          index: 4,
          name: 'Stress'
        },
        {
          index: 5,
          name: 'Manager'
        },
        {
          index: 6,
          name: 'Leader'
        },
        {
          index: 8,
          name: 'Organization Core'
        },
      ],
    }
  }
  componentDidMount() {

  }

  onNudgeOption = (templateNo) => {
    this.setState({ optionType: templateNo }, () => {
      let { onNudgeOptionType, handleNudgeMapping } = this.props;
      onNudgeOptionType(templateNo);

      if (templateNo === 201) {
        handleNudgeMapping()
      }


    })
  }

  onSwitchOptionScreen = () => {
    let {
      getIndexOverallScoreData,
      getIndexOverallDistributionData,
      getDemographicWiseRankedScoreIndexTopData,
      getDemographicWiseRankedScoreIndexBottomData,
      getNudgeLibListData,
      questionsList,
      GetParameterLabel,
      handleMapNudgeToQuestion,
      getNudgeTextQuesList
    } = this.props;

    let { optionType, DimensionList } = this.state;



    switch (optionType) {
      case 201: return (
        <NudgeMapping
          getNudgeLibListData={getNudgeLibListData}
          questionsList={questionsList}
          GetParameterLabel={GetParameterLabel}
          handleMapNudgeToQuestion={handleMapNudgeToQuestion}
          getNudgeTextQuesList={getNudgeTextQuesList}
        />
      )
      default: return (
        <ClusterAnalysis
          getIndexOverallScoreData={getIndexOverallScoreData}
          getIndexOverallDistributionData={getIndexOverallDistributionData}
          getDemographicWiseRankedScoreIndexTopData={getDemographicWiseRankedScoreIndexTopData}
          getDemographicWiseRankedScoreIndexBottomData={getDemographicWiseRankedScoreIndexBottomData}
          optionType={optionType}
          DimensionList={DimensionList}
        />)
    }

  }

  render() {
    let { optionType, DimensionList } = this.state;

    let { validateIndex } = this.props;

    let validateList = [];
    let validObj = Object.keys(validateIndex).map((key) => [key, validateIndex[key]])
    console.log('validObj: ', validObj);
    if (validObj && validObj.length > 0) {
      validObj.forEach((item) => {
        if (item[1]) {
          let getIndex = DimensionList.findIndex(prev => prev.index === parseInt(item[0]));
          if (getIndex !== -1 ? DimensionList[getIndex].name : "") {
            validateList.push({
              "name": getIndex !== -1 ? DimensionList[getIndex].name : "",
              "template": parseInt(item[0])
            });
          }

        }
      })
    }
    console.log('org validateList: ', validateList);

    validateList.push({
      "name": "Organization Core",
      "template": 8
    })



    return (
      <>

        <div className="md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto bg-[#f9fafb]">
          <div className="flex items-center justify-between text-gray-800">
            <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]">Snapshot</h1>
            {/* <span className="flex cursor-pointer material-symbols-outlined lg:hidden">menu_open</span> */}
          </div>
          {/*sub nav bar 1 */}
          <div className="items-center justify-between border-b md:flex">


            <div className="flex space-x-2 overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 whitespace-nowrap tab-scroll scollar-xs">
              {validateList && validateList.length > 0 ?
                validateList.map((item) =>
                  <span onClick={() => this.onNudgeOption(item.template)}
                    className={optionType === item.template ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                      "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}>
                    {item.name}
                  </span>
                ) : null}

              {/* <span onClick={() => this.onNudgeOption(201)}
                className={optionType === 201 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                  "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}>
                {"Nudge Mapping"}
              </span> */}



            </div>
          </div>

          {this.onSwitchOptionScreen()}

        </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AdvanceComparision);
