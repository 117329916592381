import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
import Filter from '../../../CommonComponents/Filters/IndexFilter/Filter';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: ''
    }
  }
  componentDidMount() {

  }



  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { clearDownloadExcel } = this.props;
    clearDownloadExcel()
    this.setState({ FilterValues: finalFilter });
  }


  render() {
    let { EmpFilterData, getIndexByFilterDMGData, getEMPFilterLabel,
      getQuestionWiseExcelData, handleExcelDownload, getQuesDistributionIndexOrgData,
      companyName } = this.props;

    let { FilterValues } = this.state;

    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }


    function getQuestionDetails(ques, field) {
      let name = "";
      if (ques && ques.demographics && ques.demographics[0] && ques.demographics[0][field]) {
        name = ques.demographics[0][field];
      }
      return name
    }

    let ScoreExcel = []
    if (getQuestionWiseExcelData && getQuestionWiseExcelData.length > 0) {
      for (var i = 0; i < getQuestionWiseExcelData.length; i++) {
        let questionData = JSON.parse(JSON.stringify(getQuestionWiseExcelData[i]));
        console.log("questionData=============>", questionData);

        let temp = {
          "Question": getQuestionDetails(questionData, "name"),
          "Index": getQuestionDetails(questionData, "parameterBucketName"),
          "Dimension": getQuestionDetails(questionData, "parameterDimensionName")
        }

        let dmgList = questionData.demographics;

        if (dmgList && dmgList.length > 0) {

          dmgList.sort((a, b) => a.dmgLevel - b.dmgLevel);

          dmgList.forEach((ele) => {
            temp[ele.dmgLevel + ":" + ele.dmgValue] = ele.favourability;
          });


        }

        ScoreExcel.push(temp)
      }
    }

    console.log("ScoreExcel=============>", ScoreExcel);




    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split(".")[0];
        let second = str.split(".")[1];
        let second2 = second ? second.substring(0, 2) : "";
        let final = first + "." + second2;
        if (second2) {
          return parseFloat(final).toFixed(2);
        } else {
          return no;
        }
      } else {
        return 0;
      }
    }


    let ScoreExcelV1 = [];

    const groupByCategory =
      getQuesDistributionIndexOrgData &&
        getQuesDistributionIndexOrgData.length > 0
        ? getQuesDistributionIndexOrgData.reduce((group, product) => {
          const { parameterBucketName } = product;
          group[parameterBucketName] = group[parameterBucketName] ?? [];
          group[parameterBucketName].push(product);
          return group;
        }, {})
        : {};

    console.log("getQuesDistributionIndexOrgData--->", groupByCategory);

    if (
      groupByCategory &&
      Object.keys(groupByCategory) &&
      Object.keys(groupByCategory).length > 0
    ) {
      Object.keys(groupByCategory).forEach((IndexName) => {
        let questionList = groupByCategory[IndexName];
        if (questionList && questionList.length > 0) {
          questionList.forEach((question) => {
            let tempDataV2 = {};
            tempDataV2["Index"] = IndexName;
            tempDataV2["Dimension"] =
              question && question.parameterDimensionName
                ? question.parameterDimensionName
                : "";
            tempDataV2["Question"] =
              question && question.name ? question.name : "";
            let total =
              question["Strongly Agree"] +
              question["Agree"] +
              question["Slightly Agree"] +
              question["Slightly Disagree"] +
              question["Disagree"] +
              question["Strongly Disagree"];

            tempDataV2["Top 2"] = spliceDecimal(
              ((question["Strongly Agree"] + question["Agree"]) * 100) / total
            );

            tempDataV2["Strongly Disagree"] = spliceDecimal(
              (question["Strongly Disagree"] * 100) / total
            );
            tempDataV2["Disagree"] = spliceDecimal(
              (question["Disagree"] * 100) / total
            );
            tempDataV2["Strongly Disagree"] = spliceDecimal(
              (question["Strongly Disagree"] * 100) / total
            );
            tempDataV2["Slightly Agree"] = spliceDecimal(
              (question["Slightly Agree"] * 100) / total
            );
            tempDataV2["Agree"] = spliceDecimal((question["Agree"] * 100) / total);
            tempDataV2["Strongly Agree"] = spliceDecimal(
              (question["Strongly Agree"] * 100) / total
            );

            tempDataV2["Bottom 2"] = spliceDecimal(
              ((question["Strongly Disagree"] + question["Disagree"]) * 100) /
              total
            );
            ScoreExcelV1.push(tempDataV2);
          });
        }
      });
    }


    return (
      <>

        <div className="flex items-center justify-between gap-4 md:flex-row">
          <div className="flex items-center justify-between text-gray-800">
            <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Excel Download</h1>
          </div>
        </div>

        <div className="items-center justify-between md:flex">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />
          {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} /> */}
        </div>


        <div className='bg-white p-4 flex'>
          <div className='p-2 px-4'>
            <h1 className='font-semibold'>Question Wise Demographic Score</h1>
          </div>
          <div>
            {ScoreExcel && ScoreExcel.length > 0 ?
              <>
                <CSVLink
                  data={ScoreExcel}
                  filename={companyName + " - DemographicScore" + ".csv"}
                >
                  <button className=" bg-green-500 p-2 px-4 rounded-md border text-white group   transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="group-hover:text-white transition-all duration-150  text-white icon icon-tabler icon-tabler-file-x"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                      <path d="M10 12l4 4m0 -4l-4 4" />
                    </svg>
                    <span>Save Excel</span>
                  </button>
                </CSVLink>
              </>
              :
              <>
                <button onClick={() => handleExcelDownload("QuestionWiseDMGExcel", FilterValues)} className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                    <path d="M10 12l4 4m0 -4l-4 4" />
                  </svg>
                  <span>Download Excel</span>
                </button>
              </>}
          </div>
        </div>


        <div className='bg-white p-4 flex'>
          <div className='p-2 px-4'>
            <h1 className='font-semibold'>Question Wise Top Bottom Score</h1>
          </div>
          <div>
            {ScoreExcelV1 && ScoreExcelV1.length > 0 ?
              <>
                <CSVLink
                  data={ScoreExcelV1}
                  filename={companyName + " - TopBottomScore" + ".csv"}
                >
                  <button className=" bg-green-500 p-2 px-4 rounded-md border text-white group   transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="group-hover:text-white transition-all duration-150  text-white icon icon-tabler icon-tabler-file-x"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                      <path d="M10 12l4 4m0 -4l-4 4" />
                    </svg>
                    <span>Save Excel</span>
                  </button>
                </CSVLink>
              </>
              :
              <>
                <button onClick={() => handleExcelDownload("QuestionWiseTopBottomExcel", FilterValues)} className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                    <path d="M10 12l4 4m0 -4l-4 4" />
                  </svg>
                  <span>Download Excel</span>
                </button>
              </>}
          </div>
        </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
