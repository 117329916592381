import React from "react";
import { TbArrowDownRight, TbArrowUpRight } from 'react-icons/tb';
export default function SurveyList(props) {
  let { isLifecycle, CategoryData, viewDashbaord, showGrid } = props;


  return (
    <>

      {CategoryData && CategoryData.id ?
        <>

          <div onClick={() => viewDashbaord(CategoryData.id)} style={{ background: (isLifecycle ? "#E9F5FE" : "#D0F1F8") }}
            className={(showGrid ? "" : "h-36  ") + " p-4 rounded-2xl  border capitalize cursor-pointer transform  transition duration-500 ease-in-out bg-blue-100 space-y-6 group flex flex-col justify-between "} >


            <div className={showGrid ? "" : " flex justify-between items-center"} >

              <div className="space-y-1 " >

                <h1 className="text-lg font-semibold text-[#212121] line-clamp-2">{CategoryData.label}
                </h1>
                <p className="text-sm font-medium text-[#212121]/80 ">{"Overall Insight"}
                </p>

              </div>


              {!showGrid ?
                <div className="flex justify-center ">
                  <button
                    className="flex cursor-pointer  px-6 py-2.5 rounded-full bg-white text-blue-500 font-medium group-hover:bg-blue-500 group-hover:text-white ">
                    <span > View Dashboard</span>
                    <TbArrowUpRight />
                  </button>
                </div>
                : null}


              {!showGrid ? <img alt="code" src={isLifecycle ? "/img/lifecycle-overall-insight.svg" : "/img/lifecycle-overall-insight.svg"} className={"h-24 px-4"} /> : null}

            </div>



            {showGrid ?
              <img alt="code" src={isLifecycle ? "/img/lifecycle-overall-insight.svg" : "/img/lifecycle-overall-insight.svg"} className={"mx-auto w-7/12 "} />
              : null}


            {showGrid ?
              <div className="flex justify-center ">
                <button
                  className="flex items-center justify-center cursor-pointer w-full px-6 py-2.5 rounded-full bg-white text-[#2196F3] font-medium group-hover:bg-[#2196F3] group-hover:text-white transition-all duration-150  ">
                  <span > Overall Dashboard</span>
                  <TbArrowUpRight className="text-[1.5rem]" />
                </button>
              </div>
              : null} 

          </div>



        </>
        : null}


    </>
  );
}
