import React from 'react';
// import { connect } from 'react-redux';



export default function Filter(props) {

  let { parameterList, handleSelectDimension } = props;

  let [showFilter, handleOpenFilter] = React.useState(false);



  let { crrSelectedDimension } = props;





  return (
    <>






      <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">


        <div>
          <h1 className='text-xs font-medium text-gray-800' >Dimensions: </h1>
        </div>
        <p onClick={() => handleOpenFilter(!showFilter)} className="text-xs px-2 ">{crrSelectedDimension ? crrSelectedDimension : (parameterList && parameterList.length > 0 ?
          parameterList[0] : "")}</p>


        <span onClick={() => handleOpenFilter(!showFilter)} className="material-icons ">arrow_drop_down</span>

        {showFilter && parameterList && parameterList.length > 0 ?
          <div className="z-10 absolute right-0 top-10 bg-white shadow-lg p-3 font-normal text-xs w-full h-60 overflow-x-auto customscroll2 rounded-md">

            {parameterList && parameterList.length > 0 ?
              parameterList.map((item, index) =>
                <div key={index} className="py-2 m-1 flex items-center cursor-pointer"
                  onClick={() => { handleSelectDimension(item); handleOpenFilter(false) }}
                >
                  <input type="checkbox" checked={crrSelectedDimension === item} onChange={() => { }} />
                  <label className="ml-2 font-medium">{item}</label>

                </div>
              ) : null}
          </div>
          : null}
      </div>








      {/* </div> */}

    </>
  );
}
