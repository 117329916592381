import { riskdashtype2Constants } from '../_constants';
import { riskdashtype2Service } from '../_services';
import { dashboardActions } from './dashboard.actions';

export const riskdashtype2Actions = {
    getSurvey,
    getDashboardCard,
    getQuestionDist,
    getQuestionDistOverall,
    getMeanScore,
    getMeanScoreQwise,
    getQuestionOthersResult,
    getQuestionOthersResultFilter,
    getQuestionOthersResultFilterEmpty,
    getQuestion2OthersResultFilter,
    getQuestionMatrixResult,

    getQuestionByQuestionIdResult,
    getQuestionWithBranchCodeByQuestionId,
    getQuestionWithBranchCodeByQuestionId2,

    getIndexByFilterDMG,
    getQuestionOthersMultiResultFilter,


    RiskRespondantEmployees,
    RiskgetEmpQuestionResponses,
    RiskAllEmpQuesResponses,
    RiskgetRiskCategory,
    RiskgetEmpQuestionAllStagesResponses,
    RiskFilterEmpQuesResponses,
    emptyRiskgetEmpQuestionResponses,
    getAllRiskActionPlan,
    getAllRiskEmpConversation,
    onScheduleSubmit,
    submitPeoptoMeetResolved,

    OnboardingEmpQuesResponses,
    LifecyclePTMEmpQuesResponses,
    LifecyclePTMEmpQuesOpenEnded,
    LifecycleEngQuesResponses,
    LifecycleEngamentIndexV2
};

function getSurvey(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.getSurvey(data)
            .then(
                riskdashtype2 =>{ 
                    dispatch(success(riskdashtype2));
                    if(riskdashtype2 && riskdashtype2.SurveyData && riskdashtype2.SurveyData.list && riskdashtype2.SurveyData.list.length>0){
                        let SurveyList = []
                        riskdashtype2.SurveyData.list.forEach((survey)=>{
                            if(survey && survey.templateId && survey.templateId === 3){
                                SurveyList.push(survey);
                            }

                        });
                        if(SurveyList && SurveyList.length>0){
                            let data2 = {
                                "surveyId": false? SurveyList[0].id:"5fa04d316ede0f0336066cce"
                            }
                            ////console.log('surveyId', data2.surveyId)

                            if(data2 && data2.surveyId){
                                dispatch(this.getDashboardCard(data2));
                            }
                        }

                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_SURVEY_LIST_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_SURVEY_LIST_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_SURVEY_LIST_FAILURE, error } }
}


function getDashboardCard(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.getDashboardCard(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_DASHBOARD_CARD_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_DASHBOARD_CARD_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_DASHBOARD_CARD_FAILURE, error } }
}



function getQuestionDist(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.getQuestionDist(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_QUESTION_DIST_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_QUESTION_DIST_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_QUESTION_DIST_FAILURE, error } }
}


function getQuestionDistOverall(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.getQuestionDistOverall(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_QUESTION_DIST_OVERALL_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_QUESTION_DIST_OVERALL_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_QUESTION_DIST_OVERALL_FAILURE, error } }
}

function getMeanScore(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.getMeanScore(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_MEAN_SCORE_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_MEAN_SCORE_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_MEAN_SCORE_FAILURE, error } }
}



function getMeanScoreQwise(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.getMeanScoreQwise(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_MEAN_SCORE_QWISE_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_MEAN_SCORE_QWISE_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_MEAN_SCORE_QWISE_FAILURE, error } }
}


function getQuestionOthersResult(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.getQuestionOthersResult(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_OTHERS_RESULT_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_OTHERS_RESULT_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_OTHERS_RESULT_FAILURE, error } }
}


function getQuestionOthersResultFilterEmpty(data) {
    let obj = {
        getQuestionOthersResultFilterData :{}
    }
    return dispatch => {
        dispatch(success(obj));
    };
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_FILTER_OTHERS_RESULT_SUCCESS, riskdashtype2 } }
}


function getQuestionOthersResultFilter(data) {
    let obj = {
        getQuestionOthersResultFilterData :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        riskdashtype2Service.getQuestionOthersResultFilter(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_FILTER_OTHERS_RESULT_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_FILTER_OTHERS_RESULT_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_FILTER_OTHERS_RESULT_FAILURE, error } }
}


function getQuestion2OthersResultFilter(data) {
    let obj = {
        getQuestion2OthersResultFilterData :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        riskdashtype2Service.getQuestion2OthersResultFilter(data)
            .then(
                riskdashtype2 => {
                    dispatch(success(riskdashtype2));
                    ////////console.log("---------riskdashtype2--------",riskdashtype2)
                },
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_2_FILTER_OTHERS_RESULT_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_2_FILTER_OTHERS_RESULT_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_2_FILTER_OTHERS_RESULT_FAILURE, error } }
}



function getQuestionMatrixResult(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.getQuestionMatrixResult(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_NPS_RESULT_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_NPS_RESULT_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_NPS_RESULT_FAILURE, error } }
}



function getQuestionByQuestionIdResult(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.getQuestionByQuestionIdResult(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_TYPE1_RESULT_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_TYPE1_RESULT_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_TYPE1_RESULT_FAILURE, error } }
}


function getQuestionWithBranchCodeByQuestionId(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.getQuestionWithBranchCodeByQuestionId(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_FAILURE, error } }
}


function getQuestionWithBranchCodeByQuestionId2(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.getQuestionWithBranchCodeByQuestionId2(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_2_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_2_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_2_FAILURE, error } }
}


function getIndexByFilterDMG(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.getIndexByFilterDMG(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_INDEX_BY_FILTER_DMG_X_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_INDEX_BY_FILTER_DMG_X_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_INDEX_BY_FILTER_DMG_X_FAILURE, error } }
}



function getQuestionOthersMultiResultFilter(data) {

    return dispatch => {
        dispatch(request());

        let FilteredObj = {
            getQuestionOthersMultiResultFilterData:[]
        };
        if(data && data.length>0){
            data.forEach((item)=>{

                riskdashtype2Service.getQuestionOthersMultiResultFilter(item)
                .then(
                    riskdashtype2 => {
                        if(riskdashtype2 && riskdashtype2.getQuestionOthersMultiResultFilterData){
                            FilteredObj['getQuestionOthersMultiResultFilterData'].push({
                                "xAxis":item.xAxis,
                                "Data":riskdashtype2.getQuestionOthersMultiResultFilterData
                            });
                        }
                    },
                    error => {
                        // dispatch(failure(error))
                    }
                );


            });
        }
        ////////console.log("FilteredObj-------->",FilteredObj)
        dispatch(success(FilteredObj))
    };

    function request() { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_MULTI_RESULT_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_MULTI_RESULT_SUCCESS, riskdashtype2 } }
    // function failure(error) { return { type: riskdashtype2Constants.RISKDASH_GET_QUES_MULTI_RESULT_FAILURE, error } }
}






function RiskRespondantEmployees(data,type, emp_id) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.RiskRespondantEmployees(data)
            .then(
                riskdashtype2 => {
                    dispatch(success(riskdashtype2));
                    if(riskdashtype2 && riskdashtype2.RiskRespondantEmployeesData && riskdashtype2.RiskRespondantEmployeesData[0] && riskdashtype2.RiskRespondantEmployeesData[0].emp_id){
                        let reqData = {
                            "emp_id":type==="custom"?emp_id : riskdashtype2.RiskRespondantEmployeesData[0].emp_id,
                            "surveyId":data && data.surveyId?data.surveyId:""
                        }
                        dispatch(this.RiskgetEmpQuestionResponses(reqData));
                        let reqDat2 = {
                            "emp_id":type==="custom"?emp_id : riskdashtype2.RiskRespondantEmployeesData[0].emp_id
                        }
                        dispatch(dashboardActions.getAssessmentReportByEmpIDOnly(reqDat2));

                    }
                    dispatch(success(riskdashtype2));
                },
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISK_RESPONDANT_EMPLOYEES_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISK_RESPONDANT_EMPLOYEES_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISK_RESPONDANT_EMPLOYEES_FAILURE, error } }
}


function emptyRiskgetEmpQuestionResponses(data) {
    let obj = {
        RiskgetEmpQuestionResponsesData:[]
    }
    return dispatch => {
        dispatch(success(obj));
    };
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISK_EMP_RESPONSES_SUCCESS, riskdashtype2 } }
}




function RiskgetEmpQuestionResponses(data,isEmpty) {
    let obj = {
        RiskgetEmpQuestionResponsesData:[]
    }
    if(isEmpty){
        return dispatch => {
            dispatch(success(obj));
        };
    }else{
        return dispatch => {
            dispatch(success(obj));
            dispatch(request());
            riskdashtype2Service.RiskgetEmpQuestionResponses(data)
                .then(
                    riskdashtype2 => dispatch(success(riskdashtype2)),
                    error => {
                        dispatch(failure(error)) 
                    }
                );
        };
    }

    function request() { return { type: riskdashtype2Constants.RISK_EMP_RESPONSES_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISK_EMP_RESPONSES_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISK_EMP_RESPONSES_FAILURE, error } }
}



function RiskgetRiskCategory(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.RiskgetRiskCategory(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISK_CATEGORY_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISK_CATEGORY_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISK_CATEGORY_FAILURE, error } }
}



function RiskgetEmpQuestionAllStagesResponses(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.RiskgetEmpQuestionAllStagesResponses(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISK_EMP_QUES_ALL_STAGES_RESPONSES_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISK_EMP_QUES_ALL_STAGES_RESPONSES_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISK_EMP_QUES_ALL_STAGES_RESPONSES_FAILURE, error } }
}

function RiskAllEmpQuesResponses(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.RiskAllEmpQuesResponses(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISK_ALL_EMP_QUES_RESPONSES_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISK_ALL_EMP_QUES_RESPONSES_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISK_ALL_EMP_QUES_RESPONSES_FAILURE, error } }
}


function RiskFilterEmpQuesResponses(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.RiskFilterEmpQuesResponses(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISK_FILTER_EMP_QUES_RESPONSES_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISK_FILTER_EMP_QUES_RESPONSES_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISK_FILTER_EMP_QUES_RESPONSES_FAILURE, error } }
}


function getAllRiskActionPlan(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.getAllRiskActionPlan(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.RISK_ACTION_PLAN_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.RISK_ACTION_PLAN_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.RISK_ACTION_PLAN_FAILURE, error } }
}




function getAllRiskEmpConversation(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.getAllRiskEmpConversation(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.GET_ALL_RISK_EMP_CONVERATION_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.GET_ALL_RISK_EMP_CONVERATION_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.GET_ALL_RISK_EMP_CONVERATION_FAILURE, error } }
}



function onScheduleSubmit(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.onScheduleSubmit(data)
            .then(
                riskdashtype2 => {
                    dispatch(success(riskdashtype2));
                    if(data && data.ptm_type && data.ptm_type === 2){
                        dispatch(dashboardActions.getLifecycleOverallPTM());
                    }else{
                        dispatch(this.getAllRiskEmpConversation({"surveyId":data.surveyId,"ptm_type":data.ptm_type}));
                    }

                },
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.ON_SCHEDULE_SUBMIT_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.ON_SCHEDULE_SUBMIT_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.ON_SCHEDULE_SUBMIT_FAILURE, error } }
}




function submitPeoptoMeetResolved(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.submitPeoptoMeetResolved(data)
            .then(
                riskdashtype2 => {
                    dispatch(success(riskdashtype2));
                    if(data && data.ptm_type && data.ptm_type === 2){
                        dispatch(dashboardActions.getLifecycleOverallPTM());
                    }else{
                        dispatch(this.getAllRiskEmpConversation({"surveyId":data.surveyId,"ptm_type":data.ptm_type}));
                    }
                },
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.SUBMIT_PEOPLE_TOMEET_RESOLVED_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.SUBMIT_PEOPLE_TOMEET_RESOLVED_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.SUBMIT_PEOPLE_TOMEET_RESOLVED_FAILURE, error } }
}




function OnboardingEmpQuesResponses(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.OnboardingEmpQuesResponses(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.ONBOARDING_EMP_QUES_RESPONSES_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.ONBOARDING_EMP_QUES_RESPONSES_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.ONBOARDING_EMP_QUES_RESPONSES_FAILURE, error } }
}


function LifecyclePTMEmpQuesResponses(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.LifecyclePTMEmpQuesResponses(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.LIFECYCLE_PTM_EMP_QUES_RESPONSES_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.LIFECYCLE_PTM_EMP_QUES_RESPONSES_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.LIFECYCLE_PTM_EMP_QUES_RESPONSES_FAILURE, error } }
}


function LifecycleEngQuesResponses(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.LifecycleEngQuesResponses(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.LIFECYCLE_PTM_ENG_QUES_RESPONSES_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.LIFECYCLE_PTM_ENG_QUES_RESPONSES_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.LIFECYCLE_PTM_ENG_QUES_RESPONSES_FAILURE, error } }
}


function LifecyclePTMEmpQuesOpenEnded(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.LifecyclePTMEmpQuesOpenEnded(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.LIFECYCLE_PTM_EMP_QUES_OPEN_ENDED_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.LIFECYCLE_PTM_EMP_QUES_OPEN_ENDED_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.LIFECYCLE_PTM_EMP_QUES_OPEN_ENDED_FAILURE, error } }
}





function LifecycleEngamentIndexV2(data) {
    return dispatch => {
        dispatch(request());
        riskdashtype2Service.LifecycleEngamentIndexV2(data)
            .then(
                riskdashtype2 => dispatch(success(riskdashtype2)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: riskdashtype2Constants.LIFECYCLE_ENG_INDEX_DISTRIBUTION_REQUEST } }
    function success(riskdashtype2) { return { type: riskdashtype2Constants.LIFECYCLE_ENG_INDEX_DISTRIBUTION_SUCCESS, riskdashtype2 } }
    function failure(error) { return { type: riskdashtype2Constants.LIFECYCLE_ENG_INDEX_DISTRIBUTION_FAILURE, error } }
}




