export const questionsConstants = {

    LOGOUT: 'USERS_LOGOUT',

    ADD_QUESTIONS_REQUEST: 'ADD_QUESTIONS_REQUEST',
    ADD_QUESTIONS_SUCCESS: 'ADD_QUESTIONS_SUCCESS',
    ADD_QUESTIONS_FAILURE: 'ADD_QUESTIONS_FAILURE',

    DELETE_QUESTIONS_REQUEST: 'DELETE_QUESTIONS_REQUEST',
    DELETE_QUESTIONS_SUCCESS: 'DELETE_QUESTIONS_SUCCESS',
    DELETE_QUESTIONS_FAILURE: 'DELETE_QUESTIONS_FAILURE',
    
    UPDATE_QUESTIONS_REQUEST: 'UPDATE_QUESTIONS_REQUEST',
    UPDATE_QUESTIONS_SUCCESS: 'UPDATE_QUESTIONS_SUCCESS',
    UPDATE_QUESTIONS_FAILURE: 'UPDATE_QUESTIONS_FAILURE',
    

    DELETE_QUESTIONOPTION_REQUEST: 'DELETE_QUESTIONOPTION_REQUEST',
    DELETE_QUESTIONOPTION_SUCCESS: 'DELETE_QUESTIONOPTION_SUCCESS',
    DELETE_QUESTIONOPTION_FAILURE: 'DELETE_QUESTIONOPTION_FAILURE',

    QUESTIONS_GETALL_REQUEST: 'QUESTIONS_GETALL_REQUEST',
    QUESTIONS_GETALL_SUCCESS: 'QUESTIONS_GETALL_SUCCESS',
    QUESTIONS_GETALL_FAILURE: 'QUESTIONS_GETALL_FAILURE',

    ANSWERS_SUBMIT_REQUEST: 'ANSWERS_SUBMIT_REQUEST',
    ANSWERS_SUBMIT_SUCCESS: 'ANSWERS_SUBMIT_SUCCESS',
    ANSWERS_SUBMIT_FAILURE: 'ANSWERS_SUBMIT_FAILURE',


    QUESTIONS_LIST_GETALL_REQUEST: 'QUESTIONS_LIST_GETALL_REQUEST',
    QUESTIONS_LIST_GETALL_SUCCESS: 'QUESTIONS_LIST_GETALL_SUCCESS',
    QUESTIONS_LIST_GETALL_FAILURE: 'QUESTIONS_LIST_GETALL_FAILURE',

    UPDATE_QUESTIONS_RANK_REQUEST:'UPDATE_QUESTIONS_RANK_REQUEST',
    UPDATE_QUESTIONS_RANK_SUCCESS: 'UPDATE_QUESTIONS_RANK_SUCCESS',
    UPDATE_QUESTIONS_RANK_FAILURE: 'UPDATE_QUESTIONS_RANK_FAILURE',


    COPY_QUES_LIBRARY_REQUEST:'COPY_QUES_LIBRARY_REQUEST',
    COPY_QUES_LIBRARY_SUCCESS: 'COPY_QUES_LIBRARY_SUCCESS',
    COPY_QUES_LIBRARY_FAILURE: 'COPY_QUES_LIBRARY_FAILURE',

    TRANSLATE_QUESTION_REQUEST:'TRANSLATE_QUESTION_REQUEST',
    TRANSLATE_QUESTION_SUCCESS: 'TRANSLATE_QUESTION_SUCCESS',
    TRANSLATE_QUESTION_FAILURE: 'TRANSLATE_QUESTION_FAILURE',

    TRANSLATE_MANY_QUESTION_REQUEST:'TRANSLATE_MANY_QUESTION_REQUEST',
    TRANSLATE_MANY_QUESTION_SUCCESS: 'TRANSLATE_MANY_QUESTION_SUCCESS',
    TRANSLATE_MANY_QUESTION_FAILURE: 'TRANSLATE_MANY_QUESTION_FAILURE',

    TRANSLATE_SURVEY_REQUEST:'TRANSLATE_SURVEY_REQUEST',
    TRANSLATE_SURVEY_SUCCESS: 'TRANSLATE_SURVEY_SUCCESS',
    TRANSLATE_SURVEY_FAILURE: 'TRANSLATE_SURVEY_FAILURE',

    GET_ALL_QUESTION_LIST_TYPE1_REQUEST:'GET_ALL_QUESTION_LIST_TYPE1_REQUEST',
    GET_ALL_QUESTION_LIST_TYPE1_SUCCESS: 'GET_ALL_QUESTION_LIST_TYPE1_SUCCESS',
    GET_ALL_QUESTION_LIST_TYPE1_FAILURE: 'GET_ALL_QUESTION_LIST_TYPE1_FAILURE',


    // PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST:'PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST',
    // PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS: 'PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS',
    // PARAMETERLIST_BYTYPE_PARENT_GETALL_FAILURE: 'PARAMETERLIST_BYTYPE_PARENT_GETALL_FAILURE',

    SURVEY_QUESTIONS_GETALL_REQUEST:'SURVEY_QUESTIONS_GETALL_REQUEST',
    SURVEY_QUESTIONS_GETALL_SUCCESS: 'SURVEY_QUESTIONS_GETALL_SUCCESS',
    SURVEY_QUESTIONS_GETALL_FAILURE: 'SURVEY_QUESTIONS_GETALL_FAILURE',

    GET_SURVEY_TEMPLATE_CODE_REQUEST: 'GET_SURVEY_TEMPLATE_CODE_REQUEST',
    GET_SURVEY_TEMPLATE_CODE_SUCCESS: 'GET_SURVEY_TEMPLATE_CODE_SUCCESS',
    GET_SURVEY_TEMPLATE_CODE_FAILURE: 'GET_SURVEY_TEMPLATE_CODE_FAILURE',

    GET_LANGUAGE_LABEL_REQUEST: 'GET_LANGUAGE_LABEL_REQUEST',
    GET_LANGUAGE_LABEL_SUCCESS: 'GET_LANGUAGE_LABEL_SUCCESS',
    GET_LANGUAGE_LABEL_FAILURE: 'GET_LANGUAGE_LABEL_FAILURE',

    UPDATE_SURVEY_PARAMETER_REQUEST: 'UPDATE_SURVEY_PARAMETER_REQUEST',
    UPDATE_SURVEY_PARAMETER_SUCCESS: 'UPDATE_SURVEY_PARAMETER_SUCCESS',
    UPDATE_SURVEY_PARAMETER_FAILURE: 'UPDATE_SURVEY_PARAMETER_FAILURE',

    GET_AUTO_CHANGE_DASH_TYPE_REQUEST: 'GET_AUTO_CHANGE_DASH_TYPE_REQUEST',
    GET_AUTO_CHANGE_DASH_TYPE_SUCCESS: 'GET_AUTO_CHANGE_DASH_TYPE_SUCCESS',
    GET_AUTO_CHANGE_DASH_TYPE_FAILURE: 'GET_AUTO_CHANGE_DASH_TYPE_FAILURE',

    ON_SURVEY_ALL_QUE_SECONDS_REQUEST: 'ON_SURVEY_ALL_QUE_SECONDS_REQUEST',
    ON_SURVEY_ALL_QUE_SECONDS_SUCCESS: 'ON_SURVEY_ALL_QUE_SECONDS_SUCCESS',
    ON_SURVEY_ALL_QUE_SECONDS_FAILURE: 'ON_SURVEY_ALL_QUE_SECONDS_FAILURE',

    UPDATE_QUES_ALL_MANDATORY_REQUEST: 'UPDATE_QUES_ALL_MANDATORY_REQUEST',
    UPDATE_QUES_ALL_MANDATORY_SUCCESS: 'UPDATE_QUES_ALL_MANDATORY_SUCCESS',
    UPDATE_QUES_ALL_MANDATORY_FAILURE: 'UPDATE_QUES_ALL_MANDATORY_FAILURE',

    COPY_QUESTIONS_REQUEST: 'COPY_QUESTIONS_REQUEST',
    COPY_QUESTIONS_SUCCESS: 'COPY_QUESTIONS_SUCCESS',
    COPY_QUESTIONS_FAILURE: 'COPY_QUESTIONS_FAILURE',

    ADD_NUDGE_TO_QUES_REQUEST: 'ADD_NUDGE_TO_QUES_REQUEST',
    ADD_NUDGE_TO_QUES_SUCCESS: 'ADD_NUDGE_TO_QUES_SUCCESS',
    ADD_NUDGE_TO_QUES_FAILURE: 'ADD_NUDGE_TO_QUES_FAILURE',

    DELETE_MANY_QUESTIONS_REQUEST: 'DELETE_MANY_QUESTIONS_REQUEST',
    DELETE_MANY_QUESTIONS_SUCCESS: 'DELETE_MANY_QUESTIONS_SUCCESS',
    DELETE_MANY_QUESTIONS_FAILURE: 'DELETE_MANY_QUESTIONS_FAILURE',

    UPDATE_QUES_DMG_REQUEST: 'UPDATE_QUES_DMG_REQUEST',
    UPDATE_QUES_DMG_SUCCESS: 'UPDATE_QUES_DMG_SUCCESS',
    UPDATE_QUES_DMG_FAILURE: 'UPDATE_QUES_DMG_FAILURE',

    SAVE_PREVIEW_SUCCESS:'SAVE_PREVIEW_SUCCESS',

    SAVE_PREVIEW_CLEAR_SUCCESS:'SAVE_PREVIEW_CLEAR_SUCCESS'
};
