import React from "react";
import View1 from './View/View1';

export default function Help(props) {
  let { closeModal, name, desc, OptionName } = props;
  return (

   
    <div className="bg-black bg-opacity-40 fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full flex items-center justify-center md:p-0 p-4" >
      <div className="xl:w-4/12 lg:w-4/6 w-full  mx-auto bg-white rounded-md mb-20  ">
        <div className="flex items-center justify-between p-6 py-4 border-b">
          <h2 className="text-xl font-medium">Help</h2>
          <button onClick={() => closeModal()} className="p-2 bg-[#f5f5f5] rounded-full text-[#212121]/70">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-x "><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
          </button>
        </div>


        <div className="p-6 flex justify-center">
          <div className="text-center space-y-6">
            <div className="flex justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
                <rect width="120" height="120" rx="60" fill="#2196F3" fill-opacity="0.16" />
                <rect x="24" y="24" width="72" height="72" rx="36" fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 60C17 36.252 36.252 17 60 17C83.748 17 103 36.252 103 60C103 83.748 83.748 103 60 103C52.576 103 45.58 101.116 39.48 97.8L22.548 100.948C22.0696 101.037 21.5767 101.007 21.112 100.863C20.6473 100.719 20.2247 100.464 19.8806 100.119C19.5365 99.7754 19.2813 99.3527 19.1369 98.888C18.9926 98.4233 18.9635 97.9305 19.052 97.452L22.204 80.52C18.7793 74.2234 16.9899 67.1677 17 60ZM53.38 42.412C52 43.58 51 45.364 51 48C51 48.7956 50.684 49.5587 50.1214 50.1213C49.5587 50.6839 48.7957 51 48 51C47.2044 51 46.4413 50.6839 45.8787 50.1213C45.3161 49.5587 45 48.7956 45 48C45 43.636 46.748 40.168 49.496 37.84C52.176 35.56 55.648 34.5 59 34.5C62.352 34.5 65.82 35.56 68.504 37.836C71.252 40.168 73 43.636 73 48C73 51.064 72.216 53.4 70.932 55.32C69.856 56.936 68.456 58.184 67.356 59.168L67.008 59.48C65.776 60.584 64.852 61.496 64.172 62.696C63.524 63.844 63 65.448 63 68C63 68.7956 62.684 69.5587 62.1214 70.1213C61.5587 70.6839 60.7957 71 60 71C59.2044 71 58.4413 70.6839 57.8787 70.1213C57.3161 69.5587 57 68.7956 57 68C57 64.552 57.724 61.904 58.952 59.74C60.148 57.628 61.724 56.164 62.992 55.02L63.28 54.76C64.48 53.68 65.312 52.94 65.94 51.992C66.536 51.104 67 49.936 67 48C67 45.364 66 43.58 64.62 42.412C63.18 41.188 61.148 40.5 59 40.5C56.852 40.5 54.82 41.188 53.38 42.412ZM62.268 86.004C62.5415 85.7131 62.7541 85.3705 62.8935 84.9964C63.0328 84.6222 63.0961 84.224 63.0796 83.8251C63.063 83.4262 62.967 83.0346 62.7972 82.6732C62.6274 82.3119 62.3871 81.9881 62.0906 81.7208C61.794 81.4535 61.447 81.2481 61.0701 81.1166C60.6931 80.9852 60.2936 80.9302 59.8951 80.9551C59.4967 80.98 59.1072 81.0842 58.7495 81.2616C58.3918 81.4389 58.0731 81.6859 57.812 81.988L57.772 82.032C57.259 82.6258 56.999 83.397 57.048 84.1803C57.0969 84.9635 57.4507 85.6964 58.0337 86.2218C58.6166 86.7472 59.3822 87.0232 60.1663 86.9907C60.9504 86.9582 61.6906 86.6198 62.228 86.048L62.268 86.004Z" fill="#2196F3" />
              </svg>
            </div>
            {/* <img alt="img" src="/img/help.png" className="mx-auto w-28" /> */}
            <div className="space-y-2 ">
              <h1 className="md:text-xl text-lg font-semibold capitalize">What we show in {name}?</h1>
              <div className="flex justify-center text-center text-base text-[#3D405B]/80 " >
                <View1 desc={desc} />
              </div>

            </div>
           
          </div>

        </div>





      </div>




    </div>
  );
}
