import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filter from './Filter';
import HeatMap from './Components/HeatMap';
import DonutChart from '../../../../Overview/Components/QuestionTypeCards/Charts/DonutChart';
import HorizontalBarChart from '../../../../Overview/Components/QuestionTypeCards/Charts/HorizontalBarChart';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      viewScore: true,
      showLeaderBoard: false,
      showFilter: false,
      showValue: '',
      XAxis: ""
    }
  }
  componentDidMount() {

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleFilterSave = (name) => {
    this.setState({ currFilterName: name })
  }

  handleMultifilterIndexScore2 = (finalFilter, XAxis) => {
    //console.log(':----------------------------------------------------------------------------------------------------------------XAxis:-------------------->',finalFilter, XAxis)

    let { handleOthersFilterCall } = this.props;
    this.setState({ FilterValues: finalFilter, XAxis: XAxis });
    handleOthersFilterCall(finalFilter, XAxis, 11);

  }

  render() {
    let {
      getQuestionOthersResultData, currentQuestionId, EmpFilterData, getQuestionOthersResultFilterData } = this.props;

    let { currFilterName, FilterValues, XAxis } = this.state;

    //-------------------------------------------------------------------------------------------------------------------------
    let nEwAxis = "";
    if (XAxis) {
      nEwAxis = XAxis;
    }
    else if (EmpFilterData && EmpFilterData.length > 0) {
      nEwAxis = EmpFilterData[0].name;
    }

    let QuestionName = "";

    function FormattFun(inputData, quesId) {
      let LIST = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {


          if (currentQuestionId === ques.total[0]._id.questionId) {


            let temp = {
              name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
              answerList: []
            }
            QuestionName = temp["name"];

            if (ques && ques.total && ques.total.length > 0) {
              ques.total.forEach((item) => {
                let ans = {
                  count: item.count,
                  answerText: item["_id"]["answerText"],
                  template: item["_id"]["qtemplate"],
                  dmgValue: item["_id"][nEwAxis]
                }
                temp["answerList"].push(ans);
              });
            }
            LIST.push(temp);
          }
        });
      }
      return LIST
    }

    let NPSQuesList = FormattFun(getQuestionOthersResultData,currentQuestionId);
    let NPSQuesListFilter = FormattFun(getQuestionOthersResultFilterData);
    let totalCount = 0;
    let DemographicValues = [];
    let DemographicValuesObj = {};
    let item = NPSQuesList[0];
    let itemOthers = NPSQuesListFilter[0];
    let AnswerObjOverall = {};
    if (item && item.answerList && item.answerList.length > 0) {
      item.answerList.forEach((ans) => {

        if (AnswerObjOverall && AnswerObjOverall[ans.answerText]) {
          AnswerObjOverall[ans.answerText] += (ans.count);
        }
        else {
          AnswerObjOverall[ans.answerText] = (ans.count)
        }

      });
    }

    //console.log('AnswerObjOverall==============>: ',AnswerObjOverall);


    function ordering(name) {
      let order = 111;
      if (name === "<25%") {
        order = 1;
      }
      else if (name === "25-50%") {
        order = 2;
      }
      else if (name === "50 - 75%") {
        order = 3;
      }
      else if (name === "More than 75%") {
        order = 4;
      }
      return order
    }

    let optionList1 = Object.keys(AnswerObjOverall);
    let optionList2 = [];
    let optionList = [];
    if (optionList1 && optionList1.length > 0) {
      optionList1.forEach((item) => {
        if (item && item !== 'null') {
          optionList2.push({
            "name": item,
            "number": ordering(item)
          })
        }
      });
    }

    optionList2.sort((a, b) => a.number - b.number);

    if (optionList2 && optionList2.length > 0) {
      optionList2.forEach((item1) => {
        optionList.push(item1.name);
      });
    }
    //console.log('optionList==============>: ',optionList)

    let AnswerObjList = [];

    if (itemOthers && itemOthers.answerList && itemOthers.answerList.length > 0) {
      itemOthers.answerList.forEach((ans) => {

        let getIndex = DemographicValues.findIndex(prev => prev === ans.dmgValue);
        if (getIndex === -1) {
          DemographicValues.push(ans.dmgValue);
          DemographicValuesObj[ans.dmgValue] = {
            "count": 0
          }
          if (optionList && optionList.length > 0) {
            optionList.forEach((opt) => {
              DemographicValuesObj[ans.dmgValue][opt] = 0;
            });
          }

        }
        //console.log('ans (NPSQuesList)',NPSQuesList)
        //console.log('dmgValue (NPS)',ans.dmgValue)

        let code = ans.answerText + ans.dmgValue;

        let getIndex1 = AnswerObjList.findIndex(prev => prev.code === code);
        if (getIndex1 === -1) {
          AnswerObjList.push({
            "text": ans.answerText,
            "name": ans.dmgValue,
            "value": ans.count,
            "code": ans.answerText + ans.dmgValue
          });
        }
        else {
          AnswerObjList[getIndex1].value += ans.count;
        }



      });
    }

    if (AnswerObjList && AnswerObjList.length > 0) {


      AnswerObjList.forEach(item1 => {

        let key = item1.text;
        let name = item1.name;
        let score = item1.value;

        totalCount++;
        DemographicValuesObj[name]["count"] += score;


        if (optionList && optionList.length > 0) {
          optionList.forEach((opt) => {
            if ((key) === opt) {

              DemographicValuesObj[name][opt] += score;

            }
          });
        }
        //  if((key) === "Yes"){

        //   DemographicValuesObj[name]["Yes"]+=score;

        //  }
        //  if((key) === "No"){

        //   DemographicValuesObj[name]["No"]+=score;
        //  }

      });
    }

    let { getIndexFilterData } = this.props;
    
    return (
      <>
        <div className="items-center justify-between h-10 xl:flex lg:flex md:flex">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData}
            getIndexFilterData={getIndexFilterData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />
        </div>

        <div onClick={() => this.closeFilter(false)} className="">
          <div className="flex w-full pt-6" >
            {!(FilterValues && FilterValues.length > 0) && false ?
              <>
                <div className="w-full px-4 py-4 pb-10 bg-white border rounded">
                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">{QuestionName}
                    {/* <p className="text-sm font-semibold text-gray-500">Single Choice Question</p> */}
                  </h1>
                  {item && item.answerList[0] && item.answerList[0].template === 1 ?
                    <HorizontalBarChart AnswerObj={AnswerObjOverall} name={"Overall"} templateId={1} stage={3} />
                    : null}
                </div>
              </>
              :
              <div className="w-full p-5 bg-white border rounded mb-60">
                <div className="flex items-center justify-between px-1 text-base font-semibold text-gray-700">
                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">{QuestionName}
                    {/* <p className="text-sm font-semibold text-gray-500">Single Choice Question</p> */}
                    <p className="text-base font-semibold text-gray-700">
                      <span className="text-blue-500">{(currFilterName !== 'none') ? "( " + currFilterName + " wise )" : ""}</span></p>
                  </h1>
                </div>

                <HeatMap
                  AnswerObjOverall={AnswerObjOverall}
                  DemographicValues={DemographicValues}
                  DemographicValuesObj={DemographicValuesObj}
                  optionList={optionList}
                  XAxis={XAxis}
                />
              </div>
            }
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
