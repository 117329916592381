import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let { DemographicValues, DemographicValuesObj, XAxis, AnswerObjOverall,


      currFilterValue, currFilterName, getDistributionIndexData, getIndexDriverData, GetParameterLabel } = this.props;

    ////console.log('xxxxx--------nps -----------------AnswerObjOverall: ',AnswerObjOverall)     


    function GetNPSScore(ValuesObj, item) {
      if (item === "OVERALL") {

        let totalCount = 0;
        let Promoters = 0;
        let Passsives = 0;
        let Distractors = 0;

        if (ValuesObj) {
          Object.keys(ValuesObj).forEach(key => {
            let score = parseInt(ValuesObj[key]);

            totalCount += score;

            if (parseInt(key) >= 9 && parseInt(key) <= 10) {
              Promoters += score;
            }
            if (parseInt(key) >= 7 && parseInt(key) <= 8) {
              Passsives += score;
            }
            if (parseInt(key) >= 1 && parseInt(key) <= 6) {
              Distractors += score;
            }

          });
        }

        let total = Promoters + Passsives + Distractors;
        let NPScore = ((Promoters * 100 / total) - (Distractors * 100 / total)).toFixed(2);
        return NPScore === "NaN" ? 0 : NPScore
      }
      else {
        let Promoters = ValuesObj[item].Promoters;
        let Passsives = ValuesObj[item].Passsives;
        let Distractors = ValuesObj[item].Distractors;
        let total = Promoters + Passsives + Distractors;
        let NPScore = ((Promoters * 100 / total) - (Distractors * 100 / total)).toFixed(2);
        // return NPScore==="NaN"?0:NPScore

        let count = ValuesObj[item]["count"];
        return count > 5 ? (NPScore === "NaN" ? 0 : NPScore) : "NaN"


      }

    }

    let OverALLTotal = 0;
    let KeyList = AnswerObjOverall ? Object.keys(AnswerObjOverall) : []
    if (KeyList && KeyList.length > 0) {
      KeyList.forEach((data1) => {
        OverALLTotal += (AnswerObjOverall && AnswerObjOverall[data1] ? AnswerObjOverall[data1] : 0)
      });
    }


    function GetPerc(ValuesObj, item, type) {

      if (item === "OVERALL") {

        let totalCount = 0;
        let Promoters = 0;
        let Passsives = 0;
        let Distractors = 0;

        if (ValuesObj) {
          Object.keys(ValuesObj).forEach(key => {
            let score = parseInt(ValuesObj[key]);

            totalCount += score;

            if (parseInt(key) >= 9 && parseInt(key) <= 10) {
              Promoters += score;
            }
            if (parseInt(key) >= 7 && parseInt(key) <= 8) {
              Passsives += score;
            }
            if (parseInt(key) >= 1 && parseInt(key) <= 6) {
              Distractors += score;
            }

          });
        }
        ////console.log('xxxxx--------nps -----------------ValuesObj + ValuesObj + ValuesObj: ',OverALLTotal,type,ValuesObj);
        let score = ValuesObj && ValuesObj[type] ? ((ValuesObj[type] * 100) / OverALLTotal) : 0;
        return score ? score.toFixed(2) : 0
      }
      else {
        let sum = 0;
        if (KeyList && KeyList.length > 0) {
          KeyList.forEach((opt) => {
            sum += ValuesObj[item][opt]
          });
        }
        let total = sum;
        // let score = (ValuesObj[item][type])
        // return score?score.toFixed(2):0
        let score = (((ValuesObj[item][type]) * 100) / total)

        let count = ValuesObj[item]["count"];
        return count > 5 ? (score ? score.toFixed(2) : 0) : "NaN"
      }

    }





    DemographicValues.sort();



    //////////console.log("---DemographicValues---",DemographicValues)

    //////////console.log("---DemographicValuesObj---",DemographicValuesObj)


    return (
      <>

        <div className='space-y-4 bg-white'>
          {/* <div className='flex flex-row items-center justify-between w-full gap-4'>
            <div className='flex items-center text-[#212121] space-x-2'>
              <p className='p-3 bg-[#ededed] font-semibold'>Question 111</p>
            </div>
          </div>
          <div className='space-y-2 '>
            <h2 className='text-xl font-medium text-[#212121]'>question name</h2>
            <p className='text-base text-[#212121]/80 flex space-x-2 items-center'>
              <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>Email</p>
            </p>
          </div> */}

          <div className='bg-[#f9f9f9] rounded'>

            <div class="space-y-4 text-sm text-slate-800">
              <table class="w-full border-collapse">
                <thead class="text-sm  capitalize bg-[#2196F3]/20">
                  <tr class="border-b-2 border-white">
                    <th class="text-[#212121] font-medium p-4 py-3 text-sm text-left border-r-2 border-white">{XAxis}</th>
                    {KeyList && KeyList.length > 0 ?
                      KeyList.map((item) =>
                        <th class="text-[#212121] font-medium p-4 py-3 text-sm text-center border-r-2 border-white">{item + "(%)"}</th>
                      ) : null}
                  </tr>
                </thead>
                <tbody class="divide-y-2 divide-white ">
                  <tr class="even:bg-gray-100 odd:bg-gray-50">
                    <td class="p-2 px-4 border-r-2 border-white"><p class="font-medium ">{"OVERALL"}</p></td>
                    {KeyList && KeyList.length > 0 ?
                      KeyList.map((item) =>
                        <td class="p-2 px-4 text-center border-r-2 border-white">{GetPerc(AnswerObjOverall, "OVERALL", item)}</td>
                      ) : null}
                  </tr>

                  {DemographicValues && DemographicValues.length > 0 ?
                    DemographicValues.map((item, index) =>
                      (item) ?
                        <tr class="even:bg-gray-100 odd:bg-gray-50">
                          <td class="p-2 px-4 border-r-2 border-white"><p class="font-medium">{item}</p></td>
                          {KeyList && KeyList.length > 0 ?
                            KeyList.map((Opt) =>
                              <td class="p-2 px-4 text-center border-r-2 border-white"> {GetPerc(DemographicValuesObj, item, Opt)}</td>
                            ) : null}
                        </tr>
                        : null
                    ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </>

    );
  }
}
function mapStateToProps(state) {
  // //////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
