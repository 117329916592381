
import { authHeader } from '../_helpers';
import { CONST } from '../_config';
// let backendUrl = JSON.parse(localStorage.getItem('backendUrl'));
// import CryptoJS from 'crypto-js';

export const questionsService = {
    disableQuestions,
    deleteQuestion,
    logout,
    createQuestion,
    updateQuestion,
    getAllQuestions,
    getQuestionListBySurveyID,
    surveySubmit,
    deleteQuestionOption,
    updateQuestionRenk,
    copyFromQuestionLibrary,
    translateQuestion,
    translateManyQuestions,
    translateSurvey,
    getType1AllQuestionList,


    getQuestionList,
    getSurveyTemplateByCode,
    getLanguageLabel,
    updateSurveyParameter,
    autoChangeDashboardType,
    onSurveyAllQueSeconds,
    updateQuesAllMandatory,
    copyQuestion,
    addNudgeToQuestion,
    deleteManyQuestions,
    updateQuestionDMG
    
    // getParameterListbyParentId
    
};



function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
}


function handleResponse(response) {
    // //////console.log("response22222   ", response);
 
     return response.text().then(text => {
         const data = text && JSON.parse(text);
         if (!response.ok) {
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logoutSession();
                 //location.reload(true);
             }
             // //////console.log("datadatadata ", response);
 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         ////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             // //////console.log("datadatadatadatadata   ", data);
             if (data.code===3) {
                 logoutSession();
             }
             if(data && data.message && (data.message === "UnauthorizedError")){
                logout()

             }
//              else if (data.code===1) {
//                 logoutSession();
//             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 
 

function handleResponse2(response) {
    // //////console.log("response22222   ", response);
 
     return response.text().then(text => {
         const data = text && JSON.parse(text);
         if (!response.ok) {
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logout();
                 //location.reload(true);
             }
             // //////console.log("datadatadata ", response);
 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         ////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             // //////console.log("datadatadatadatadata   ", data);
             if (data.code===3) {
                 logout();
             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 




function logoutSession(data) {

    let header = new Headers({
            'Content-Type': 'application/json',
            'Authorization': authHeader().Authorization
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/userlogout`, requestOptions)
            .then(handleResponse2)
            .then(user => {
                let userObj = {
                    userLogout: user
                }
                localStorage.removeItem('adminuser');
                window.location.href = "#/login";
                return userObj;
            });
}




async function deleteManyQuestions(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deleteManyQuestions`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionsObj = {
                deleteManyQuestionsData: data.data
            }
            //console.log();
            return questionsObj;
        });
}




async function addNudgeToQuestion(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/addNudgeToQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionsObj = {
                addNudgeToQuestionData: data.data
            }
            //console.log();
            return questionsObj;
        });
}




async function getAllQuestions(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionsList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionsObj = {
                listOfQuestionss: data.data
            }
            //console.log();
            return questionsObj;
        });
}


async function copyQuestion(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/copyQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionsObj = {
                listOfQuestionss: data.data
            }
            //console.log();
            return questionsObj;
        });
}

async function updateQuesAllMandatory(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateQuesAllMandatory`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let questionsObj = {
                updateQuesAllMandatoryData: data.data
            }
            //console.log();

            return questionsObj;
        });
}


async function createQuestion(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let questionsObj = {
                createQuestion: data.data
            }
            //console.log();

            return questionsObj;
        });
}
async function updateQuestion(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                questionsList: data.data
            }
            //console.log(questionsObj);

            return questionsObj;
        });
}


async function updateQuestionDMG(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateQuestionDMG`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                updateQuestionDMGData: data.data
            }
            //console.log(questionsObj);

            return questionsObj;
        });
}


async function deleteQuestion(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });


    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deleteQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let questionsObj = {
                deleteQuestion: data.data
            }
            //console.log(questionsObj);

            return questionsObj;
        });
}
async function disableQuestions(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateQuestionsStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let questionsObj = {
                deleteQuestions: data.data
            }
            //console.log();

            return questionsObj;
        });
}
// async function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 logout();
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         if (data.error) {
//             if (data.code === 3) {
//                 logout();
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         return data;
//     });
// }

// async function getQuestionListBySurveyID(data,employeeId) {
//     let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    // let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionListBySurveyID`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             var QuesAns=[];
            
//             data.data.map((quesData,quesDataIndex)=>{
//                 if(quesData.templateId===2){
//                     QuesAns.push({
//                         questionId:quesData.id,
//                         answer:"",
//                         id:"",
//                         isMandatory:quesData.isMandatory,
//                         selected:""
//                     });

//                     return QuesAns;
//                 }
//                 else{
//                     return QuesAns.push({
//                         questionId:quesData.id,
//                         answer:"",
//                         id:"",
//                         isMandatory:quesData.isMandatory,
//                         selected:""
//                     })
//             }
                
//             })
//             let questionObj = {
//                 questionsList: data.data,
//                 answersList:QuesAns
//             }
//             //////console.log("questionObj  ", questionObj);

//             return questionObj;
//         });
// }




async function surveySubmit(data,quesList) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/saveAnswers`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data.data;
        });
}


async function deleteQuestionOption(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deleteQuestionOption`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data.data;
        });
}



async function getQuestionListBySurveyID(data,employeeId) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionListBySurveyID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            var QuesAns=[];
            //////console.log("data data: ",data)
            
            let QuestionMain = [];
            let MatrixQuestion = [];
            let GroupQuestion = [];

            if(data && data.data && data.data.questionList){
                data.data.questionList.forEach((ques)=>{
                    
                    if(ques.templateId===13){
                        if(ques.parentId){
                            MatrixQuestion.push(ques);
                        }
                        else{
                            QuestionMain.push({
                                ...ques,
                                SubQuestionList:[]
                            });
                        }
                    }


                    // else if(ques.templateId === 14){
                    //     QuestionMain.push({
                    //         ...ques,
                    //         SubQuestionList:[]
                    //     });
                    // }


                    else if(ques.templateId !==13 && ques.templateId !==14 ){
                        if(ques.parentId){
                            GroupQuestion.push(ques);
                        }
                        else{
                            QuestionMain.push(ques);
                        }
                    }


                    else{
                        QuestionMain.push(ques);
                    }
                    
                });
            }

            if(QuestionMain && QuestionMain.length>0){
                QuestionMain.map((quesData,quesDataIndex)=>{


                    if(quesData.templateId===2){
                        QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            questionOptionId:""
                        });
                        return QuesAns;
                    }
                    else if(quesData.templateId===13){
                        QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            multiAnswer:[],
                            questionOptionId:"",
                            isMandatory:quesData.isMandatory

                        });
                        return QuesAns;
                    }
                    else{
                        return QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            questionOptionId:"",
                            isMandatory:quesData.isMandatory

                        })
                    }


                    
                })
            }


            //console.log('---->------------>MatrixQuestion',MatrixQuestion)

            if(MatrixQuestion && MatrixQuestion.length>0){
                MatrixQuestion.forEach((ques)=>{
                    let getIndex = QuestionMain.findIndex(prev=>prev.id===ques.parentId);
                    if(getIndex!==-1){
                        QuestionMain[getIndex]["SubQuestionList"].push(ques);

                    }
                });
            }

            let QuestionFinal = []
            if(QuestionMain && QuestionMain.length>0){
                QuestionMain.forEach((Qmain)=>{

                    if(Qmain.templateId===14){
                        QuestionFinal.push(Qmain);

                        if(GroupQuestion && GroupQuestion.length>0){
                            GroupQuestion.forEach((ques,index)=>{
                                if(ques.parentId === Qmain.id){
                                    let temp = ques;
                                    temp["rank"] = Qmain.rank
                                    QuestionFinal.push(temp);
                                }
                            });
                        }


                    }else{
                        QuestionFinal.push(Qmain);
                    }

                });
            }


            // if(GroupQuestion && GroupQuestion.length>0){
            //     GroupQuestion.forEach((ques)=>{
            //         let getIndex = QuestionMain.findIndex(prev=>prev.id===ques.parentId);
            //         if(getIndex!==-1){
            //             QuestionMain[getIndex]["SubQuestionList"].push(ques);
            //         }
            //     });
            // }

            
            //console.log('---->------------>QuestionMain',QuestionMain)

            let questionObj = {
                questionsList: QuestionFinal,
                answersList:QuesAns,
                surveyScreenData :data.data.survey
            }
            //////console.log("questionObj  ", questionObj);

            return questionObj;
        });
}

async function updateQuestionRenk(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateQuestionRenk`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                updatequestionData: data.data
            }
            //console.log(questionsObj);

            return questionsObj;
        });
}


async function copyFromQuestionLibrary(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/copyFromQuestionLibrary`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                copyFromQuestionLibraryData: data.data
            }
            //console.log(questionsObj);

            return questionsObj;
        });
}


async function translateQuestion(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/translateQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                translateQuestionData: data.data
            }
            //console.log(questionsObj);

            return questionsObj;
        });
}

async function translateManyQuestions(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/translateManyQuestions`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                translateManyQuestionData: data.data
            }
            //console.log(questionsObj);

            return questionsObj;
        });
}


async function translateSurvey(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/translateSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                translateSurveyData: data.data
            }
            //console.log(questionsObj);

            return questionsObj;
        });
}



async function getType1AllQuestionList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionListBySurveyID`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                getType1AllQuestionListData: data.data.questionList
            }
            //console.log(questionsObj);

            return questionsObj;
        });
}



// async function getParameterListbyParentId(data) {
//     let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    // let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getParameterListbyParentId`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let parameterObj = {
//                 getParameterListbyParentId: data.data
//             }

//             return parameterObj;
//         });
// }











//SURVEY APIS PREVIEW --------------------------------------------------------------------------------------

async function getQuestionList(data,Template) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }


    if(Template==="v4"){
        return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionListForPreview`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let SortedQuestionList = []
            let GroupQuestionOnlyList = []

            if(data.data.questionList && data.data.questionList.length>0){
                SortedQuestionList = data.data.questionList.sort((a, b) => a.rank - b.rank);
                // //console.log('questionsList V : ',SortedQuestionList)

                GroupQuestionOnlyList = data.data.questionList.filter(prev => prev.parentId === null);

              }

            var QuesAns=[];
            // //////console.log("data data: ",data)

            let QuestionMain = [];
            let MatrixQuestion = [];
            let GroupQuestion = [];

            if(SortedQuestionList && SortedQuestionList.length>0){
                SortedQuestionList.forEach((ques)=>{
                    if(ques.templateId === 13){
                        if(ques.parentId){
                            MatrixQuestion.push(ques);
                        }
                        else{
                            QuestionMain.push({
                                ...ques,
                                SubQuestionList:[]
                            });
                        }
                    }
                    else if(ques.templateId === 14){

                        // QuestionMain.push({
                        //     ...ques,
                        //     SubQuestionList:[]
                        // });

                        QuestionMain.push(ques);

                    }
                    else if(ques.templateId !==13 && ques.templateId !==14 ){


                        if(ques.parentId){
                            GroupQuestion.push(ques);
                        }
                        else{
                            QuestionMain.push(ques);
                        }
                    }
                    else{


                        QuestionMain.push(ques);
                    }
                    
                });
            }



            if(SortedQuestionList && SortedQuestionList.length>0){
                SortedQuestionList.map((quesData,quesDataIndex)=>{


                    if(quesData.templateId===2){
                        QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            multiAnswer:[],
                            questionOptionId:"",
                            isMandatory:quesData.isMandatory,
                            parentId:quesData.parentId,
                            template:quesData.templateId

                        });
                        return QuesAns;
                    }
                    else if(quesData.templateId===4){
                        QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            answer1:"",
                            answer2:"",
                            questionOptionId:"",
                            isMandatory:quesData.isMandatory,
                            parentId:quesData.parentId,
                            template:quesData.templateId

                        });
                        return QuesAns;
                    }

                    else if(quesData.templateId===13){
                        QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            multiAnswer:[],
                            questionOptionId:"",
                            isMandatory:quesData.isMandatory,
                            parentId:quesData.parentId,
                            template:quesData.templateId

                        });
                        return QuesAns;
                    }
                    
                    else{
                        return QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            questionOptionId:"",
                            isMandatory:quesData.isMandatory,
                            questionTemplateId:quesData.templateId,
                            parentId:quesData.parentId,
                            template:quesData.templateId


                        })
                }
                    
                })
            }



            if(MatrixQuestion && MatrixQuestion.length>0){
                MatrixQuestion.forEach((ques)=>{
                    let getIndex = QuestionMain.findIndex(prev=>prev.id===ques.parentId);
                    if(getIndex!==-1){
                        QuestionMain[getIndex]["SubQuestionList"].push(ques);
                    }

                });
            }

            let QuestionMainFinal = [];

            ////////console.log("x Template2===========>",Template,GroupQuestion)

            if(Template==="v4"){
                if(QuestionMain && QuestionMain.length>0){
                    QuestionMain.forEach((Mainques)=>{

                        if(Mainques.templateId !== 14){
                            QuestionMainFinal.push(Mainques);
                        }

                        // //////console.log("x Mainques===========>",Mainques)
                        ////////console.log("x GroupQuestion===========>",GroupQuestion)

                        if(GroupQuestion && GroupQuestion.length>0){
                            GroupQuestion.forEach((ques)=>{
                                if(ques.parentId === Mainques.id){
                                    let tempData = {
                                        ...ques,
                                        QuesMain:Mainques
                                    }
                                    QuestionMainFinal.push(tempData);
                                }
                            });
                        }

                    });
                }
            }
            else{
                // if(GroupQuestion && GroupQuestion.length>0){
                //     GroupQuestion.forEach((ques)=>{
                //         let getIndex = QuestionMain.findIndex(prev=>prev.id===ques.parentId);
                //         if(getIndex!==-1){
                //             QuestionMain[getIndex]["SubQuestionList"].push(ques);
                //         }
                //     });
                // }
                // QuestionMainFinal = QuestionMain;
            }




            // //console.log('{{{{{{{----------------> QuestionMainFinal',QuestionMainFinal)

            // let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(question), "secretkey123").toString();
            // localStorage.setItem('tempQuestion', JSON.stringify(cryptedText));


            // let storedAnswers = JSON.parse(localStorage.getItem('myAnswers'));


            // let localData = localStorage.getItem('myAnswers');
            // let cryptedText = localData?JSON.parse(localData):"";
            // let bytes = cryptedText?CryptoJS.AES.decrypt(cryptedText, "secretkey123"):"";
            let storedAnswers = "";
        
            // try {
            //   storedAnswers = bytes?JSON.parse(bytes.toString(CryptoJS.enc.Utf8)):"";
            // } catch(error) {
            //   storedAnswers = ""
            // }



            
            // //console.log('----------{{{{{{{----------------> storedAnswers',storedAnswers)
            ////console.log('----------{{{{{{{----------------> QuesAns',QuesAns)

            let NewAnswerList = [] 
            if(storedAnswers && storedAnswers.length>0 && QuesAns && QuesAns.length>0 &&  (storedAnswers.length === QuesAns.length)){
                storedAnswers.forEach((item)=>{

                    let tempData = {
                        ...item
                    }
                    let getIndex = QuesAns.findIndex(prev=>prev.questionId === item.questionId);
                    if(getIndex!==-1){


                        // if(item.template===13){
                        //     tempData['multiAnswer'] =  QuesAns && QuesAns[getIndex] && QuesAns[getIndex].multiAnswer && QuesAns[getIndex].multiAnswer.length>0?QuesAns[getIndex].multiAnswer:[];
                        // }


                        tempData['isMandatory'] = QuesAns && QuesAns[getIndex] && QuesAns[getIndex].isMandatory?true:false;
                    }
                    NewAnswerList.push(tempData);
                });
            }
            else{
                NewAnswerList = QuesAns
            }

            ////console.log('----------{{{{{{{----------------> QuesAns',QuesAns)

            ////console.log('----------{{{{{{{----------------> GroupQuestionOnlyList',GroupQuestionOnlyList)

            let questionObj = {
                questionsList: QuestionMainFinal,
                answersList: NewAnswerList,
                surveyScreenData :data.data.survey,
                surveyStatus: data.data.status,
                langList:data.data.langList,
                GroupQuestionOnlyList:GroupQuestionOnlyList
            }
            // //////console.log("questionObj  ", questionObj);

            return questionObj;
        });
    }
    else{
        return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionListForPreview`, requestOptions)

        .then(handleResponse)
        .then(data => {

            let SortedQuestionList = []
            if(data.data.questionList && data.data.questionList.length>0){
                SortedQuestionList = data.data.questionList.sort((a, b) => a.rank - b.rank);
                // //console.log('questionsList V : ',SortedQuestionList)
              }

            var QuesAns=[];
            // //////console.log("data data: ",data)

            let QuestionMain = [];
            let MatrixQuestion = [];
            let GroupQuestion = [];

            if(SortedQuestionList && SortedQuestionList.length>0){
                SortedQuestionList.forEach((ques)=>{
                    if(ques.templateId === 13){
                        if(ques.parentId){
                            MatrixQuestion.push(ques);
                        }
                        else{
                            QuestionMain.push({
                                ...ques,
                                SubQuestionList:[]
                            });
                        }
                    }
                    else if(ques.templateId === 14){

                        QuestionMain.push({
                            ...ques,
                            SubQuestionList:[]
                        });
                    }
                    else if(ques.templateId !==13 && ques.templateId !==14 ){


                        if(ques.parentId){
                            GroupQuestion.push(ques);
                        }
                        else{
                            QuestionMain.push(ques);
                        }
                    }
                    else{


                        QuestionMain.push(ques);
                    }
                    
                });
            }



            if(SortedQuestionList && SortedQuestionList.length>0){
                SortedQuestionList.map((quesData,quesDataIndex)=>{


                    if(quesData.templateId===2){
                        QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            multiAnswer:[],
                            questionOptionId:"",
                            isMandatory:quesData.isMandatory,
                            parentId:quesData.parentId,
                            template:quesData.templateId

                        });
                        return QuesAns;
                    }
                    else if(quesData.templateId===4){
                        QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            answer1:"",
                            answer2:"",
                            questionOptionId:"",
                            isMandatory:quesData.isMandatory,
                            parentId:quesData.parentId,
                            template:quesData.templateId

                        });
                        return QuesAns;
                    }

                    else if(quesData.templateId===13){
                        QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            multiAnswer:[],
                            questionOptionId:"",
                            isMandatory:quesData.isMandatory,
                            parentId:quesData.parentId,
                            template:quesData.templateId

                        });
                        return QuesAns;
                    }
                    
                    else{
                        return QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            questionOptionId:"",
                            isMandatory:quesData.isMandatory,
                            parentId:quesData.parentId,
                            template:quesData.templateId


                        })
                }
                    
                })
            }



            if(MatrixQuestion && MatrixQuestion.length>0){
                MatrixQuestion.forEach((ques)=>{
                    let getIndex = QuestionMain.findIndex(prev=>prev.id===ques.parentId);
                    if(getIndex!==-1){
                        QuestionMain[getIndex]["SubQuestionList"].push(ques);
                    }

                });
            }


            if(GroupQuestion && GroupQuestion.length>0){
                GroupQuestion.forEach((ques)=>{
                    let getIndex = QuestionMain.findIndex(prev=>prev.id===ques.parentId);
                    if(getIndex!==-1){
                        QuestionMain[getIndex]["SubQuestionList"].push(ques);
                    }
                });
            }



            ////console.log('{{{{{{{----------------> QuestionMain',QuestionMain)


               
            let questionObj = {
                questionsList: QuestionMain,
                answersList:QuesAns,
                surveyScreenData :data.data.survey,
                surveyStatus: data.data.status,
                langList:data.data.langList
            }
            // //////console.log("questionObj  ", questionObj);

            return questionObj;
        });
    }


}



async function getSurveyTemplateByCode(data) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getSurveyTemplateByCodeForPreview`, requestOptions)
        .then(handleResponse)
        .then(data => {            
            let questionObj = {
                getSurveyTemplateByCodeData :data.data
            }
            return questionObj;
        });
}



function getLanguageLabel(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getLanguageLabel`, requestOptions)
        .then(handleResponse)
        .then(data => {            
            let questionObj = {
                getLanguageLabelData :data.data
            }
            return questionObj;
        });
}


function updateSurveyParameter(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateSurveyParameter`, requestOptions)
        .then(handleResponse)
        .then(data => {            
            let questionObj = {
                updateSurveyParameterData :data.data
            }
            return questionObj;
        });
}



function autoChangeDashboardType(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/autoChangeDashboardType`, requestOptions)
        .then(handleResponse)
        .then(data => {            
            let questionObj = {
                autoChangeDashboardTypeData :data.data
            }
            return questionObj;
        });
}



function onSurveyAllQueSeconds(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/onSurveyAllQueSeconds`, requestOptions)
        .then(handleResponse)
        .then(data => {            
            let questionObj = {
                onSurveyAllQueSecondsData :data.data
            }
            return questionObj;
        });
}

