import React from "react";
export default function Help(props) {
  let { desc } = props;
  return (
   <>

            
            
                <div className="flex">
                {desc}                
                </div>

       
          
   </>
  );
}
