import React, { Component } from 'react';
import { connect } from 'react-redux';
// import SurveyReport from './SurveyReport/SurveyReport';
import SurveyReport from '../../Dashboard/Overview/SurveyMeta360Report/SurveyMeta360Report';

import DimensionQuestions from './DimensionQuestions/DimensionQuestions';
import DimensionReviewer from './DimensionReviewer/DimensionReviewer';
import Summary from './Summany/Summary';
import Report360 from './Report360/Report360';
import ResponseHeader from '../../CommonComponents/ResponseHeader';


class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerDetailsIndexType: 0,
      showManagerList: true,
      CurrentManagerName: 'Select Manager',
      optionType: 1,

      showOverview: true,

      allIndexList: [
        {
          "name": "Happiness",
          "template": 1,
          "score": "NaN",
          "type": "Score",
          "color": "#FF4081",
          "tailwindcolor": "bg-pink-100 text-pink-500 hover:bg-pink-500",
          "image": "/img/overview_assets/with_circle/happiness-index.png"
        },
        {
          "name": "Engagement",
          "template": 2,
          "score": "NaN",
          "type": "Percentage",
          "color": "#2196F3",
          "tailwindcolor": "bg-blue-100 text-blue-500 hover:bg-blue-500",
          "image": "/img/overview_assets/with_circle/engagement-index.png"

        },
        {
          "name": "Hope",
          "template": 3,
          "score": "NaN",
          "type": "Percentage",
          "color": "#009688",
          "tailwindcolor": " bg-green-100 text-green-500 hover:bg-green-500",
          "image": "/img/overview_assets/with_circle/hope-index.png"
        },
        {
          "name": "Stress",
          "template": 4,
          "score": "NaN",
          "type": "Score",
          "color": "#EF5350",
          "tailwindcolor": " bg-red-100 text-red-500 hover:bg-red-500",
          "image": "/img/overview_assets/with_circle/stress-index.png"
        },
        {
          "name": "Manager",
          "template": 5,
          "score": "NaN",
          "type": "Percentage",
          "color": "#9C27B0",
          "tailwindcolor": "bg-purple-100 text-purple-500 hover:bg-purple-500",
          "image": "/img/overview_assets/with_circle/manager-index.png"

        },
        {
          "name": "Leadership",
          "template": 6,
          "score": "NaN",
          "type": "Percentage",
          "color": "#FFB300",
          "tailwindcolor": " bg-yellow-100 text-yellow-500 hover:bg-yellow-500",
          "image": "/img/overview_assets/with_circle/leadership-index.png"

        },
        {
          "name": "Value",
          "template": 7,
          "score": "NaN",
          "type": "Percentage",
          "color": "#C0CA33",
          "tailwindcolor": " bg-lime-100 text-[#b2b33f] hover:bg-[#b2b33f] ",
          "image": "/img/overview_assets/with_circle/value-index.png"
        },

        {
          "name": "Safety",
          "template": 9,
          "score": "NaN",
          "type": "Percentage",
          "color": "#b2b33f",
          "tailwindcolor": " bg-green-100 text-green-500 hover:bg-green-500",
          "image": "/img/overview_assets/with_circle/safety-index.png"

        },
        {
          "name": "Diversity",
          "template": 10,
          "score": "NaN",
          "type": "Percentage",
          "color": "#3F51B5",
          "tailwindcolor": " bg-indigo-100 text-indigo-500 hover:bg-indigo-500",
          "image": "/img/overview_assets/with_circle/diversity-index.png"

        }
      ],



      crrSelectedEmp: {

      },

      showReport360Modal: false




    }
  }

  componentDidMount() {

  }

  openSurveyResponse = () => {
    this.setState({ showOverview: false });

  }

  closeResponseAnalytics = () => {
    this.setState({ showOverview: true })
  }

  onOptionType = (value) => {
    this.setState({ optionType: value }, () => {
      let { get360Overview, get360DimensionItemDetails } = this.props;
      if (value === 1) {
        get360Overview()
        get360DimensionItemDetails()
      }
      else if (value === 2) {
        get360DimensionItemDetails()
      }
      else if (value === 3) {
        get360DimensionItemDetails()
      }
    })
  }

  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { get360Overview, get360DimensionItemDetails } = this.props;
      let { optionType } = this.state;

      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = value.emp_id;
      }

      if (optionType === 1) {
        get360Overview(emp_id)
      } else if (optionType === 2) {
        get360DimensionItemDetails(emp_id)
      }

    })
  }

  handle360QuestionFilter = (a, b, c, d, emp_id) => {
    let { handle360QuestionFilter } = this.props;

    // let { crrSelectedEmp } = this.state;
    // let emp_id = "";
    // if (crrSelectedEmp && crrSelectedEmp.emp_id) {
    //   emp_id = crrSelectedEmp.emp_id;
    // } else {
    //   emp_id = crrSelectedEmp.emp_id;
    // }

    //console.log("A2 handle360QuestionFilter--------->",a, b, c, d, emp_id)
    handle360QuestionFilter(a, b, c, d, emp_id)
  }


  get360Overview = (a, b, c, d, emp_id) => {
    let { get360Overview } = this.props;
    let { crrSelectedEmp } = this.state;

    // let emp_id = "";
    // if (crrSelectedEmp && crrSelectedEmp.emp_id) {
    //   emp_id = crrSelectedEmp.emp_id;
    // } else {
    //   emp_id = crrSelectedEmp.emp_id;
    // }

    get360Overview(a, b, c, d, emp_id)
  }



  onReportModal = (value) => {
    this.setState({ showReport360Modal: value })
    let { get360Overview, get360DimensionItemDetails, get360RelationResponse, getFeedbackAndQuestionName } = this.props;

    get360Overview()
    get360DimensionItemDetails()
    get360RelationResponse()
    getFeedbackAndQuestionName()

  }

  handleGoBack = () => {
    this.setState({ showOverview: true })
  }

  render() {
    let { optionType, allIndexList, showOverview, showReport360Modal } = this.state;
    let {

      getTemp360EmpStrengthData,
      getTemp360EmpStrengthCall,
      surveyLogo,
      get360DimensionRelationScoreData,
      get360DimensionQuestionsData,
      getAnsweredEmployeesData,
      get360Overview,
      get360DimensionItemDetails,
      getFeedbackAndQuestionNameData,
      getTextAndQuestionNameData,
      GetParameterDesc,
      getFeedbackAndQuestionName,


      onCustomScoreCard,
      getQuestionAvgScoreData,
      getIndexData,
      SurveyList,
      CurrentSurveyId,
      onOptionType,

      getHappinessIndexV2Data,
      getEngagementIndexV2Data,
      getStressIndexV2Data,
      getManagerIndexV2Data,

      getRespondantDetailsData,

      EmpFilterData,
      getIndexFilterData,

      getQuestionOthersResultData,

      get360RelationResponseData,

      get360RelationResponse,

      getSurveyData

    } = this.props;

    let surveyId = '';
    // if(SurveyList && SurveyList.length>0){

    //   if(CurrentSurveyId){
    //     surveyId = CurrentSurveyId;
    //   }
    //   else{
    //     surveyId = SurveyList[0].id;
    //   }

    // }
    surveyId = CurrentSurveyId;

    console.log('x-------->>>>> get360DimensionRelationScoreData:-------->>>>>', get360DimensionRelationScoreData)
    console.log('x-------->>>>> get360DimensionQuestionsData:-------->>>>>', get360DimensionQuestionsData)

    let overviewScoreList = []
    let IndexList = []

    function getIndexName(key) {
      let name = ''
      if (key === 1) { name = 'Happiness' }
      else if (key === 2) { name = 'Engagement' }
      else if (key === 3) { name = 'Hope' }
      else if (key === 4) { name = 'Stress' }
      else if (key === 5) { name = 'Manager' }
      else if (key === 6) { name = 'Leadership' }
      else if (key === 7) { name = 'Safety' }
      else if (key === 9) { name = 'Diversity' }
      else { name = 'NaN' }

      return name;
    }

    function checkIndex(key) {
      let check = false
      if (key === 1) { check = true }
      else if (key === 2) { check = true }
      else if (key === 3) { check = true }
      else if (key === 4) { check = true }
      else if (key === 5) { check = true }
      else if (key === 6) { check = true }
      else if (key === 7) { check = true }
      else if (key === 9) { check = true }
      else { check = false }

      return check;
    }
    let IndexListNew = [];
    let newFilterObj = {}
    if (getIndexData) {



      IndexList = Object.keys(getIndexData).map((key) => [Number(key), getIndexData[key]]);

      if (getIndexFilterData) {
        if (IndexList && IndexList.length > 0) {
          IndexList.forEach((data) => {
            if (checkIndex(data[0])) {
              newFilterObj[data[0]] = getIndexFilterData[data[0]];
            }
          });
        };

        IndexListNew = Object.keys(newFilterObj).map((key) => [Number(key), newFilterObj[key]]);
      }
      ////console.log('IndexListNew: ',IndexListNew)

      if (IndexListNew && IndexListNew.length > 0) {
        IndexListNew.forEach((data) => {
          let GetIndex = allIndexList.findIndex(prev => prev.template === data[0])
          if (getIndexName(data[0]) !== 'NaN' && data && data[0] && data[1]) {
            overviewScoreList.push({
              template: data[0],
              score: data[1].toFixed(2),
              name: getIndexName(data[0]),
              type: (GetIndex !== -1) ? allIndexList[GetIndex].type : 'score',
              color: (GetIndex !== -1) ? allIndexList[GetIndex].color : '#757575',
              image: (GetIndex !== -1) ? allIndexList[GetIndex].image : null
            })
          }

        })
      }

    }

    let OverViewIndexList = overviewScoreList;

    if (allIndexList && allIndexList.length > 0) {
      allIndexList.forEach((item) => {
        let GetIndex = OverViewIndexList.findIndex(prev => prev.template === item.template);
        if (GetIndex === -1) {
          OverViewIndexList.push({
            ...item,
            "type": item.type,
            "color": item.color,
            "image": item.image
          });
        }
      });
    }

    // //console.log('OverViewIndexList: ',OverViewIndexList)


    function FormattFun(inputData, isSummary) {
      let RankObj = 0

      let LIST = []
      let prevTemList = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {

          let templateId = ques.total[0]._id.qtemplate;

          let temp = {
            name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
            answerList: [],
            rank: RankObj && RankObj[templateId] ? RankObj[templateId] : 100,
            templateId: templateId
          }
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading: item && item._id && item._id.heading ? item._id.heading : "",
                parentId: item && item._id && item._id.parentId ? item._id.parentId : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId
              }
              temp["answerList"].push(ans);
            });
          }


          if (isSummary) {
            let getIndex = prevTemList.findIndex(prev => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId)
            }
          }
          else {
            LIST.push(temp);
          }


        });
      }
      return LIST
    }


    let OthersQuesList = FormattFun(getQuestionOthersResultData, true).sort((a, b) => a.rank - b.rank);

    let { onAllQuestions } = this.props;


    //console.log('getQuestionAvgScoreData---------->', getQuestionAvgScoreData)

    let itemList = getQuestionAvgScoreData && getQuestionAvgScoreData[0] && getQuestionAvgScoreData[0]["total"] ? getQuestionAvgScoreData[0]["total"] : []

    let parameterList = []
    if (itemList && itemList.length > 0) {
      itemList.forEach((item) => {
        let getIndex = parameterList.findIndex(prev => prev.index === item["_id"].parameterBucketName);
        if (getIndex === -1) {
          parameterList.push({
            index: item["_id"].parameterBucketName,
            data: [{
              count: item.count,
              sum: item.sum,
              ...item["_id"]
            }]
          })
        } else {
          parameterList[getIndex]["data"].push({
            count: item.count,
            sum: item.sum,
            ...item["_id"]
          })
        }

      });
    }




    let parameter2List = []
    if (parameterList && parameterList.length > 0) {
      parameterList.forEach((item) => {
        let temp = {
          "index": item.index,
          "DimenionList": []
        }
        if (item && item.data && item.data.length > 0) {
          item.data.forEach((item2) => {



            let getIndex = temp["DimenionList"].findIndex(prev => prev.dimension === item2.parameterDimensionName);
            if (getIndex === -1) {
              temp["DimenionList"].push({
                dimension: item2.parameterDimensionName,
                inner: [{
                  count: item2.count,
                  sum: item2.sum,
                  answer: item2.answer
                }]
              })
            } else {
              temp["DimenionList"][getIndex]["inner"].push({
                count: item2.count,
                sum: item2.sum,
                answer: item2.answer
              });

            }



          })
        }

        parameter2List.push(temp)


      });
    }




    let { getIndexByFilterDMGData, getEMPFilterLabel, CountRestrict,
      GetParameterLabel, loading, indexType, getCommentData,
      handle360QuestionFilter
    } = this.props;

    //console.log('Q))))))))) get360DimensionQuestionsData---------->', get360DimensionQuestionsData)



    let ParticipationObj = {
      total: 0,
      completed: 0,
      mailSent: 0,
      clicked: 0,
      participated: 0,
      performance: 0,
      perfPlusSign: true
    }

    if (getRespondantDetailsData && getRespondantDetailsData[0]) {
      let apiData = getRespondantDetailsData[0]
      ParticipationObj['total'] = apiData['total'] ? apiData['total'] : 0;
      ParticipationObj['completed'] = apiData['completed'] ? apiData['completed'] : 0;
      ParticipationObj['mailSent'] = apiData['mailSent'] ? apiData['mailSent'] : 0;
      ParticipationObj['clicked'] = apiData['clicked'] ? apiData['clicked'] : 0;
      ParticipationObj['participated'] = apiData['completed'] && apiData['total'] ? (apiData['completed'] * 100 / apiData['total']).toFixed(2) : 0;



    }

    let responseOption = {
      "item1": {
        "label": "Sent",
        "value": ParticipationObj['total']
      },
      "item2": {
        "label": "Responded",
        "value": ParticipationObj['completed']
      },
      "item3": {
        "label": "Response Rate",
        "value": ParticipationObj['participated'] + '%'
      }
    }




    

    let OptionList = [
      {
        "name": "Dashboard",
        "template": 1
      },
      {
        "name": "Dimension Item Details",
        "template": 2
      },
      // {
      //   "name": "Dimension Reviewer Details",
      //   "template": 3
      // }
    ]


    
    return (
      <>
        <main className="flex-1 w-full p-4 overflow-hidden overflow-y-auto  bg-[#f9fafb] md:p-6 ">

          {showOverview ?
            <div className="space-y-4">

              <div className='flex items-center justify-between' >
                <h1 className='xl:text-2xl text-xl font-medium text-[#3D405B] ' >Overview</h1>

                {/* <div className='flex items-center p-2 px-4 text-sm text-blue-500 bg-blue-100 rounded-full cursor-pointer '>
                  <span className='mr-1 material-symbols-outlined' >file_download</span>
                  <span >View Report</span>
                </div> */}

                <button onClick={() => this.onReportModal(true)} class=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-report-analytics" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path><rect x="9" y="3" width="6" height="4" rx="2"></rect><path d="M9 17v-5"></path><path d="M12 17v-1"></path><path d="M15 17v-3"></path></svg>
                  <span> View Report</span>
                </button>
              </div>

              <div onClick={() => this.openSurveyResponse()}>
                <ResponseHeader responseOption={responseOption} />

              </div>



              {/* Tab View */}
              <div className="items-center justify-between border-b md:flex">
                <div className="flex space-x-2 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 whitespace-nowrap tab-scroll">
                  {OptionList && OptionList.length > 0 ?
                    OptionList.map((item) =>
                      <span onClick={() => this.onOptionType(item.template)}
                        className={optionType === item.template ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}>
                        {item && item.name ? item.name : ""}
                      </span>
                    ) : null}
                </div>
              </div>



              {optionType === 1 ?
                <>
                  {/*Tab In Dashboard Under The Content*/}
                  <Summary
                    getAnsweredEmployeesData={getAnsweredEmployeesData}
                    get360DimensionRelationScoreData={get360DimensionRelationScoreData}
                    EmpFilterData={EmpFilterData}
                    getIndexFilterData={getIndexFilterData}
                    getIndexByFilterDMGData={getIndexByFilterDMGData}
                    get360Overview={get360Overview}
                    getEMPFilterLabel={getEMPFilterLabel}
                    getRespondantDetailsData={getRespondantDetailsData}
                    GetParameterLabel={GetParameterLabel}
                    getQuestionsData={get360DimensionQuestionsData}
                    get360DimensionItemDetails={get360DimensionItemDetails}
                  />


                  {/* 
              <div onClick={() => this.openSurveyResponse()}><IndexKPI
                getRespondantDetailsData={getRespondantDetailsData} />
              </div>
              <h1 className='text-lg font-medium '>Overview</h1>

              <div>
              <EmployeeDropdown
                getAnsweredEmployeesData={getAnsweredEmployeesData}
                crrSelectedEmp={this.state.crrSelectedEmp}
                handleSelectEmployee={this.handleSelectEmployee}
              />
              </div>

              <div className="grid w-full grid-cols-1 gap-6 xl:grid-cols-2 md:grid-cols-2">


              
                <RadarChartPage1
                  get360DimensionRelationScoreData={get360DimensionRelationScoreData}
                />


                <BarDistribution1
                  get360DimensionRelationScoreData={get360DimensionRelationScoreData}
                />



              </div> */}
                </>
                : null}



              {optionType === 2 ?
                <>
                  {/* 

                  <DimensionQuestions
                    getIndexByFilterDMGData={getIndexByFilterDMGData}

                    name={"Dimension Item Details"}


                    getEMPFilterLabel={getEMPFilterLabel}

                    CountRestrict={CountRestrict}

                    GetParameterLabel={GetParameterLabel}

                    loading={loading}


                    indexType={indexType} optionType={optionType} getCommentData={getCommentData}

                    EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}

                    getQuestionData={get360DimensionQuestionsData}
                    getQuestionFilterData={get360DimensionQuestionsData}

                    handle360GetQuestionFilter={this.handle360QuestionFilter}

                    getAnsweredEmployeesData={getAnsweredEmployeesData}

                    get360DimensionItemDetails={get360DimensionItemDetails}

                  /> */}





                  {/*Tab In Dimension Item Details Under The Content*/}
                  <DimensionReviewer
                    getIndexByFilterDMGData={getIndexByFilterDMGData}

                    name={"Dimension Reviewer Details"}


                    getEMPFilterLabel={getEMPFilterLabel}

                    CountRestrict={CountRestrict}

                    GetParameterLabel={GetParameterLabel}

                    loading={loading}


                    indexType={indexType} optionType={optionType} getCommentData={getCommentData}

                    EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}

                    getQuestionData={get360DimensionQuestionsData}
                    getQuestionFilterData={get360DimensionQuestionsData}

                    handle360GetQuestionFilter={this.handle360QuestionFilter}

                    getAnsweredEmployeesData={getAnsweredEmployeesData}

                  />



                </>
                : null}


              {/* {optionType === 3 ?
                <>




                  <DimensionReviewer
                    getIndexByFilterDMGData={getIndexByFilterDMGData}

                    name={"Dimension Reviewer Details"}


                    getEMPFilterLabel={getEMPFilterLabel}

                    CountRestrict={CountRestrict}

                    GetParameterLabel={GetParameterLabel}

                    loading={loading}


                    indexType={indexType} optionType={optionType} getCommentData={getCommentData}

                    EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}

                    getQuestionData={get360DimensionQuestionsData}
                    getQuestionFilterData={get360DimensionQuestionsData}

                    handle360GetQuestionFilter={this.handle360QuestionFilter}

                    getAnsweredEmployeesData={getAnsweredEmployeesData}

                  />
                </>
                : null} */}


            </div>
            :

            <div className="px-2 bg-gray-100">
              <SurveyReport
                handleGoBack={this.handleGoBack}
                CurrentSurveyId={surveyId}
                closeResponseAnalytics={this.closeResponseAnalytics}
                EmpFilterData={EmpFilterData}
                getIndexFilterData={getIndexFilterData}
                getRespondantDetailsData={getRespondantDetailsData}
              />
            </div>
          }

          {showReport360Modal ?
            <>
              <Report360
                closeReportModal={this.onReportModal}
                getAnsweredEmployeesData={getAnsweredEmployeesData}
                get360DimensionRelationScoreData={get360DimensionRelationScoreData}
                EmpFilterData={EmpFilterData}
                getIndexFilterData={getIndexFilterData}
                getIndexByFilterDMGData={getIndexByFilterDMGData}
                get360Overview={get360Overview}
                getEMPFilterLabel={getEMPFilterLabel}
                getRespondantDetailsData={getRespondantDetailsData}


                name={"Dimension Reviewer Details"}

                CountRestrict={CountRestrict}

                GetParameterLabel={GetParameterLabel}

                loading={loading}


                indexType={indexType} optionType={optionType} getCommentData={getCommentData}




                getQuestionData={get360DimensionQuestionsData}
                getQuestionFilterData={get360DimensionQuestionsData}

                handle360GetQuestionFilter={this.handle360QuestionFilter}


                get360RelationResponseData={get360RelationResponseData}
                getFeedbackAndQuestionNameData={getFeedbackAndQuestionNameData}
                getTextAndQuestionNameData={getTextAndQuestionNameData}
                GetParameterDesc={GetParameterDesc}

                get360RelationResponse={get360RelationResponse}

                getFeedbackAndQuestionName={getFeedbackAndQuestionName}

                getSurveyData={getSurveyData}

                surveyLogo={surveyLogo}

                getTemp360EmpStrengthCall={getTemp360EmpStrengthCall}

                getTemp360EmpStrengthData={getTemp360EmpStrengthData}
              />
            </>
            : null}





        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
