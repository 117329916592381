import React from 'react';
import GaugeScore from './Charts/GaugeScore';
import BenchmarkScale from './Charts/BenchmarkScale';




export default function ScoreBenchmark(props) {

  let {

    item,

    DistributionData,
    Benchmark,
    cronBachAlpha,

    indexOverallValue,
    isCrole,
    name,
    getBenchmarkQuantileData

  } = props;


  //  let Benchmark ={
  //    score:0
  //  }


  console.log('getBenchmarkQuantileData----------.: ', getBenchmarkQuantileData)




  return (
    <div>
      {/* <div className="h-60" /> */}
      <div className="flex justify-center item-center">
        <GaugeScore item={item} Benchmark={Benchmark} />
      </div>


      {isCrole ?
        <div className="flex justify-center pb-2  ">
          <div className="text-sm items-center text-center mx-2"><p className="text-gray-500 font-semibold">
            {indexOverallValue && indexOverallValue.toString() ? "Organization " + name + " Score: " + indexOverallValue.toString() + "%" : "NA"}</p></div>
        </div>
        : <div />}


        <div className="flex items-center justify-center 2xl:pb-6">
        {/* 1 */}
        <div className="flex flex-wrap justify-center gap-4 text-sm text-gray-500   "> 
          <div className="flex items-center "><div style={{ background: "#e53935" }} className="p-2 rounded-sm" /><p className="ml-2">Actively Disengaged</p></div>
          <div className="flex items-center "><div style={{ background: "#ffc107" }} className="p-2 rounded-sm" /><p className="ml-2">Passive</p></div>
          <div className="flex items-center "><div style={{ background: "#a2e37d" }} className="p-2 rounded-sm" /><p className="ml-2">Moderately Engaged</p></div>
          <div className="flex items-center "><div style={{ background: "#00cc66" }} className="p-2 rounded-sm" /><p className="ml-2">Highly Engaged</p></div>
        </div>
      </div>


      {/* 
              <BenchmarkScale 
                getBenchmarkQuantileData={getBenchmarkQuantileData}
                item={item}
              />
               */}


    </div>
  );
}
