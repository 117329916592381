import React, { useState } from "react";
// import Sidebar from "./Components/Sidebar/Sidebar";
import Sidebar from "./sidebar/sidebar";
export default function NineBoxMatrix(props, history) {
  // console.log('locationlocationlocationlocationlocation', props);

  let { item, name, stage } = props;
  const [isAddGoal, setIsAddGoal] = useState(false);
  const [isAddTask, setIsAddTask] = useState(false);
  // State to track the active tab
  const [activeTab, setActiveTab] = useState("EmployeeList");
  const [activeTabOne, setActiveTabOne] = useState("Review");

  const handleTabClick = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTab(tab);
  };

  const handleTabClickTop = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTabOne(tab);
  };

  const handleAddGoal = () => {
    setIsAddGoal(!isAddGoal);
  };
  const handleCloseAddGoal = () => {
    setIsAddGoal(false);
  };

  const handleAddTask = () => {
    setIsAddTask(!isAddTask);
  };
  const handleCloseAddTask = () => {
    setIsAddTask(false);
  };

  return (
    <>
      <div className="h-screen overflow-hidden bg-gray-50">
        <header
          className=" w-full flex justify-between items-center  md:px-4 px-4 bg-white  border-b sticky top-0 z-20"
          style={{ height: "4rem" }}
        >
          <div className="flex itmes-center  space-x-4">
            <button className="text-blue-500 bg-[#edf6fe] rounded-full cursor-pointer hover:bg-blue-100 trasition duration-150 p-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-arrow-left  "
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#2196f3"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1={5} y1={12} x2={19} y2={12} />
                <line x1={5} y1={12} x2={11} y2={18} />
                <line x1={5} y1={12} x2={11} y2={6} />
              </svg>
            </button>
            <div className="flex items-center space-x-4 ">
              <a href="#/app/overview">
                <img
                  src="/img/plus_ex_logo.svg"
                  className="w-24 object-cover"
                />
              </a>
              <span className="border py-1 px-2 text-gray-500 text-sm rounded-md">
                ABC Pvt Ltd
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between py-3 select-none">
            <div className="lg:flex hidden border-r pr-3 mx-2 items-center " />
            <div className="relative flex items-center ">
              <button className="relative block h-10 w-10 ml-2 rounded-full overflow-hidden  focus:outline-none bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] ">
                <span className="text-sm font-semibold text-white tracking-widest">
                  TE
                </span>
              </button>
              <div className="ml-2 lg:flex items-center hidden cursor-pointer relative">
                <h1 className="text-base text-slate-800 font-medium select-none">
                  Temp005
                </h1>
                <span className="material-symbols-outlined text-slate-600 text-[1.8rem]">
                  arrow_drop_down
                </span>
              </div>
            </div>
          </div>
        </header>
        <div className="px-4  flex  justify-between items-center bg-white border-b py-2.5">
          <div className="flex items-center space-x-4">
            <h2 className="xl:text-lg text-base text-[#3D405B] truncate font-medium">
              {" "}
              Appraisal HR - Q3
            </h2>

            <div class="flex items-center px-4 py-2 space-x-2 rounded-md w-fit bg-green-50 text-sm">
              {" "}
              <span class="font-medium text-green-500">Completed</span>
            </div>
          </div>
          <div>
            <span class="text-[#212121]/80">01-Jan-2023 - 01-Apr-2023</span>
          </div>
        </div>

        <div className="flex w-full" style={{ height: "calc(100% - 6.5rem)" }}>
          <Sidebar />

          <main className="flex-1 md:p-6 p-4 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto ">
            <div className="space-y-4">
              <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
                <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]  ">
                  Nine-Box Matrix
                </h1>

                <div className="md:flex  hidden items-center text-sm text-[#3D405B]">
                  <span className="mx-2 material-symbols-rounded">home</span>
                  <span>/</span>
                  <span className="px-2">Pms</span>
                  <span>/</span>
                  <span className="px-2 font-semibold">Nine-Box Matrix</span>
                </div>
              </div>

              <div className="flex justify-between bg-white border px-4 p-2 rounded-md capitalize cursor-pointer items-center text-[#3D405B]">
                <h1 className="text-sm truncate  font-medium">
                  Appraisal HR - Q3
                </h1>
                <span className="material-symbols-outlined border-l -m-4 px-2">
                  arrow_drop_down
                </span>
              </div>
              <div className="items-center justify-between border-b md:flex">
                <div className="flex space-x-2 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 whitespace-nowrap tab-scroll">
                  <span
                    onClick={() => handleTabClick("EmployeeList")}
                    className={
                      activeTab === "EmployeeList"
                        ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                        : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"
                    }
                  >
                    Employee List
                  </span>

                  <span
                    onClick={() => handleTabClick("AppraisalStatus")}
                    className={
                      activeTab === "AppraisalStatus"
                        ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                        : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"
                    }
                  >
                    Nine-Box Mapping
                  </span>
                </div>
              </div>

              <div className=" md:flex  justify-between items-center ">
                <div className="flex gap-2 flex-wrap">
                  <div className="cursor-pointer bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] flex justify-between px-4 items-center relative py-2 rounded-md w-40 ">
                    <div className="flex space-x-2 items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-filter"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#fff"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
                      </svg>
                      <span className=" text-sm text-white ">Filters</span>
                    </div>
                    <span className=" bg-white  rounded-full text-[#2196F3] text-xs w-6 h-6 flex items-center justify-center font-semibold">
                      2
                    </span>
                  </div>
                  <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                    <span className=" text-sm text-[#3D405B]/70 font-medium ">
                      Location
                    </span>
                    <span className="material-symbols-outlined ">
                      arrow_drop_down
                    </span>
                  </div>
                  <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                    <span className=" text-sm text-[#3D405B]/70 font-medium ">
                      Department
                    </span>
                    <span className="material-symbols-outlined ">
                      arrow_drop_down
                    </span>
                  </div>
                </div>
                <div className="flex space-x-2 ">
                  <button className="flex items-center justify-center p-2 bg-white border rounded-md">
                    <span className="material-symbols-rounded  text-[1.5rem] text-[#2196f3]">
                      grid_view
                    </span>
                  </button>

                  <button className="flex items-center justify-center p-2 bg-white border rounded-md">
                    <span className="material-symbols-rounded  text-[1.5rem] ">
                      format_list_bulleted
                    </span>
                  </button>
                </div>
              </div>

              {activeTab === "EmployeeList" ? (
                <div className="bg-white p-6 space-y-6 border rounded-md">
                  <h1 class="text-xl font-medium text-[#3D405B]">
                    Employee List
                  </h1>
                  <div className="overflow-x-auto border border-gray-200  ">
                    <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse xl:whitespace-normal whitespace-nowrap">
                      <thead className="bg-white">
                        <tr className="">
                          <th
                            rowspan="2"
                            className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r"
                          >
                            Employees
                          </th>

                          <th
                            colSpan="3"
                            className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                          >
                            Modules
                          </th>
                          <th
                            colSpan="2"
                            className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                          >
                            Performance
                          </th>
                          <th
                            colSpan="2"
                            className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                          >
                            Potential
                          </th>
                        </tr>
                        <tr className="bg-[#f9fafb] border-t text-sm text-[#3D405B]">
                          {" "}
                          <th className="p-3 px-6 border-r  font-semibold">
                            Goal
                          </th>
                          <th className="p-3 px-6 border-r  font-semibold">
                            KRA
                          </th>
                          <th className="p-3 px-6  border-r  font-semibold">
                            360 Feedback
                          </th>
                          <th className="p-3 px-6 border-r  font-semibold">
                            Score
                          </th>
                          <th className="p-3 px-6  border-r  font-semibold">
                            Rating
                          </th>
                          <th className="p-3 px-6 border-r  font-semibold">
                            Score
                          </th>
                          <th className="p-3 px-6  border-r  font-semibold">
                            Rating
                          </th>
                        </tr>
                      </thead>
                      <tbody className="border-t   divide-y  ">
                        <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                          <td className="px-6 py-3   border-r">
                            <div className="flex items-center space-x-2">
                              <img
                                src="img/newimg/Rectangle 11116.png"
                                alt=""
                                className="rounded-full w-12 h-12"
                              />
                              <div className=" ">
                                <span className="capitalize text-base font-medium block text-[#3D405B]">
                                  Hari KC
                                </span>
                                <p className="capitalize text-sm  block text-[#3D405B] font-normal">
                                  HR Manager - Dept of Human Resources
                                </p>
                              </div>
                            </div>
                          </td>

                          <td className="p-3 px-6 border-r text-center">5.0</td>
                          <td className="p-3 px-6 border-r text-center">5.0</td>
                          <td className="p-3 px-6 border-r text-center ">
                            5.0
                          </td>

                          <td className="px-6 py-3 text-center border-r">
                            8-10
                          </td>
                          <td className="px-6 py-3 text-center border-r">
                            Outstanding
                          </td>
                          <td className="px-6 py-3 text-center border-r ">
                            0.0-2.0
                          </td>
                          <td className="px-6 py-3 text-center ">
                            Outstanding
                          </td>
                        </tr>

                        <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                          <td className="px-6 py-3   border-r">
                            <div className="flex items-center space-x-2">
                              <img
                                src="img/newimg/Rectangle 11116.png"
                                alt=""
                                className="rounded-full w-12 h-12"
                              />
                              <div className=" ">
                                <span className="capitalize text-base font-medium block text-[#3D405B]">
                                  Hari KC
                                </span>
                                <p className="capitalize text-sm  block text-[#3D405B] font-normal">
                                  HR Manager - Dept of Human Resources
                                </p>
                              </div>
                            </div>
                          </td>

                          <td className="p-3 px-6 border-r text-center">5.0</td>
                          <td className="p-3 px-6 border-r text-center">5.0</td>
                          <td className="p-3 px-6 border-r text-center ">
                            5.0
                          </td>

                          <td className="px-6 py-3 text-center border-r">
                            8-10
                          </td>
                          <td className="px-6 py-3 text-center border-r">
                            Outstanding
                          </td>
                          <td className="px-6 py-3 text-center border-r ">
                            0.0-2.0
                          </td>
                          <td className="px-6 py-3 text-center ">
                            Outstanding
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="text-[#3D405B] font-medium text-sm">
                      Showing 1 to 5 of 150 entries
                    </p>
                    <div className="flex border divide-x rounded-md ">
                      <button className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                        Previous
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">
                        1
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                        2
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                        3
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                        4
                      </button>
                      <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                        5
                      </button>
                      <button className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
