import React, { Component } from "react";
import { connect } from "react-redux";
import ReactEcharts from "echarts-for-react";

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    let { indexValue } = this.props;

    let responseRate = indexValue ? indexValue : 0;

    //////console.log("responseRate--->",responseRate)
    return (
      <>
        {/* <div className="stack-title">
            {currFilterName} 
          </div> */}
        <div>
          {true ? (
            <>
              <ReactEcharts
                style={{ height: "200px", width: "200px" }}
                option={{
                  // tooltip: {
                  //   trigger: 'item'
                  // },
                  series: [
                    {
                      name: "Access From",
                      type: "pie",
                      radius: ["50%", "70%"],
                      avoidLabelOverlap: true,
                      label: {
                        show: true,
                        position: "center",
                        fontSize: "20",
                        fontWeight: "bold",
                      },
                      emphasis: {
                        label: {
                          show: true,
                          fontSize: "20",
                          fontWeight: "bold",
                        },
                      },
                      labelLine: {
                        show: true,
                      },
                      data: [
                        {
                          value: (100 - parseFloat(responseRate)).toFixed(2),
                          itemStyle: {
                            color: "#f1f1f1",
                          },
                          name: "",
                        },
                        {
                          value: parseFloat(responseRate),
                          itemStyle: {
                            // color:"#6536d1"
                            color: "#2196f3",
                          },
                          name: `${parseFloat(responseRate)}%`,
                        },
                      ],
                    },
                  ],
                }}
              />
            </>
          ) : null}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(StackChart);
