import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';


export default function ReminderCampaign(props) {
  let { getCampaignDetailsByIdData,
    reminderformData, handleReminderInput, handleReminderMessageInput, getCampaignEmployeesByIDData, actionList, handleReminderSubmit, SelectAllEmployees, selectAllEmp, showReminderEmployeeFilter, handleEmpReminderDropdown, empSelectRemindFilter,
    handleEmpRemindFilterSelect, LanguageObj, CurrentLanguage } = props;


  let IsAutomatic = false;
  let IsAutomaticStatus = false;
  let reminderDate = []
  if (getCampaignDetailsByIdData && getCampaignDetailsByIdData && Number(getCampaignDetailsByIdData.reminderOption) === 1) {
    IsAutomatic = true;
    IsAutomaticStatus = getCampaignDetailsByIdData && getCampaignDetailsByIdData.reminderActive ? true : false;
    reminderDate = getCampaignDetailsByIdData && getCampaignDetailsByIdData.reminderDate && getCampaignDetailsByIdData.reminderDate.length > 0 ? getCampaignDetailsByIdData.reminderDate : [];
  }


  return (
    <div className="flex lg:flex-row flex-col gap-6 md:p-6 p-4  w-full">

      <div className="bg-white border rounded-md lg:w-[50%] w-full h-full">
        <div className='xl:text-xl text-lg font-medium text-[#3D405B] py-4 md:px-6 px-4 border-b flex justify-between items-center flex-row gap-4 '>
          <h2 className="text-xl font-medium  bg-white">{LanguageObj && LanguageObj["reminder_campaign_" + CurrentLanguage] ? LanguageObj["reminder_campaign_" + CurrentLanguage] : "Campaign Reminder"}</h2>
        </div>
        <div className="md:px-6 p-4 overflow-y-auto grid md:grid-cols-2 grid-cols-1 gap-4" >
          <div className='md:col-span-2 space-y-1 '>
            <label className="text-md text-gray-900 font-medium">{LanguageObj && LanguageObj["campaign_title_" + CurrentLanguage] ? LanguageObj["campaign_title_" + CurrentLanguage] : "Campaign Name"}</label><br />
            <input disabled={true} type="text" name="title" value={reminderformData.title} onChange={handleReminderInput} placeholder={LanguageObj && LanguageObj["campaign_title_" + CurrentLanguage] ? LanguageObj["campaign_title_" + CurrentLanguage] : "Campaign Name"} className="text-sm text-[#212121] outline-none border p-2 w-full rounded" />
          </div>

          <div className='space-y-1 '>
            <label className="text-md text-gray-900 font-medium">Campaign Reminder Type</label><br />
            <div className="text-sm text-[#212121] outline-none border p-2 py-1.5 w-full rounded flex justify-between cursor-pointer  items-center relative">
              <span className=" text-slate-800 text-xs font-semibold pr-4 w-full" onClick={() => handleEmpReminderDropdown(showReminderEmployeeFilter)}>{
                reminderformData.actionId === 2 ? LanguageObj && LanguageObj["reminder_to_incomplete_" + CurrentLanguage] ? LanguageObj["reminder_to_incomplete_" + CurrentLanguage] : reminderformData.action : reminderformData.action
              }</span>
              <span className="material-symbols-outlined text-gray-500" onClick={() => handleEmpReminderDropdown(showReminderEmployeeFilter)}>expand_more</span>

              {showReminderEmployeeFilter ?
                <div className="absolute top-10 left-0 bg-white shadow-md rounded-md w-full text-gray-500 overflow-hidden">
                  {actionList && actionList.length > 0 ?
                    actionList.map((item, index) =>
                      (item.id !== reminderformData.actionId) ?
                        <span onClick={() => handleEmpRemindFilterSelect(item)} className={(empSelectRemindFilter.template === item.type) ? "inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100" : "inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}>
                          {item.type}</span>
                        : null
                    )
                    : null}
                </div>
                : null}
            </div>
          </div>


          <div className='space-y-1 '>
            <label className="text-md text-gray-900 font-medium">{"Reminder Type"}</label><br />
            <input disabled={true} type="text" name="subject" value={IsAutomatic ? "AUTOMATIC" : "MANUAL"} onChange={() => { }} placeholder="Reminder Type" className="text-sm text-[#212121] outline-none border p-2 w-full rounded" />
          </div>


          {IsAutomatic ?
            <>
              <label className="text-md text-gray-900 font-medium">{"Reminder Dates"}</label><br />
              <textarea disabled={true} type="text" name="reminderDate" placeholder="Reminder Dates"
                className="text-sm text-gray-900 outline-none border-b py-1.5 w-full px-1 font-medium" >
                {reminderDate.map((element) => (element)).join(", ")}
              </textarea>
            </>
            : null}


          <div className='space-y-1 '>
            <label className="text-md text-gray-900 font-medium">{LanguageObj && LanguageObj["email_subject_" + CurrentLanguage] ? LanguageObj["email_subject_" + CurrentLanguage] : "Email Subject"}</label><br />
            <input type="text" name="subject" value={reminderformData.subject} onChange={handleReminderInput} placeholder="Email Subject" className="text-sm text-[#212121] outline-none border p-2 w-full rounded" />

          </div>

          <div className='space-y-1 '>
            <label className="text-md text-gray-900 font-medium">{LanguageObj && LanguageObj["email_name_" + CurrentLanguage] ? LanguageObj["email_name_" + CurrentLanguage] : "Email Name"}</label><br />
            <input type="text" name="emailName" value={reminderformData.emailName} onChange={handleReminderInput} placeholder="Email Name" className="text-sm text-[#212121] outline-none border p-2 w-full rounded" />
          </div>

          <div className='md:col-span-2 space-y-1  '>
            <label className="text-md text-gray-900 font-medium">{LanguageObj && LanguageObj["email_message_" + CurrentLanguage] ? LanguageObj["email_message_" + CurrentLanguage] : "Email Message"}</label><br />
            <div className="overflow-y-auto" >
              <ReactQuill className="w-full" theme="snow" value={reminderformData.message} onChange={handleReminderMessageInput} placeholder="Message" />
            </div>
          </div>

          <div className='space-y-1 '>
            <label className="text-md text-gray-900 font-medium">{LanguageObj && LanguageObj["team_name_" + CurrentLanguage] ? LanguageObj["team_name_" + CurrentLanguage] : "Team Name"}</label><br />
            <input type="text" name="teamname" value={reminderformData.teamname} onChange={handleReminderInput} placeholder={LanguageObj && LanguageObj["team_name_" + CurrentLanguage] ? LanguageObj["team_name_" + CurrentLanguage] : "Team Name"} className="text-sm text-[#212121] outline-none border p-2 w-full rounded" />
          </div>


          <div className='space-y-1 '>
            <label className="text-md text-gray-900 font-medium">{LanguageObj && LanguageObj["created_date_" + CurrentLanguage] ? LanguageObj["created_date_" + CurrentLanguage] : "Campaign Date	"}</label><br />
            <input disabled={true} type="text" name="teamname" value={moment.unix(reminderformData.createdAt / 1000).format("DD-MM-YYYY, HH:mm")} onChange={handleReminderInput} placeholder="Campaign Date" className="text-sm text-[#212121] outline-none border p-2 w-full rounded" />
          </div>

          <div className="flex justify-start py-6">
            <button className="bg-[#2196F3] text-white   py-3 px-6   text-base  justify-center  flex items-center uppercase font-medium rounded-md" onClick={() => handleReminderSubmit()}>{LanguageObj && LanguageObj["send_reminder_" + CurrentLanguage] ? LanguageObj["send_reminder_" + CurrentLanguage] : "SEND REMINDER"}<span className="fa fa-paper-plane px-2" /></button>
          </div>
        </div>
      </div>


      <div className="bg-white border  rounded-md lg:w-[50%] w-full h-full">
        <div className='xl:text-xl text-lg font-medium text-[#3D405B] py-4 md:px-6 px-4 border-b flex justify-start items-center flex-row gap-2 '>
          <h2 className="text-xl font-medium  bg-white">{LanguageObj && LanguageObj["employees_" + CurrentLanguage] ? LanguageObj["employees_" + CurrentLanguage] : "Employees"}</h2>
          <p className=" font-medium text-base">
            ( {
              reminderformData.actionId === 2 ? LanguageObj && LanguageObj["reminder_to_incomplete_" + CurrentLanguage] ? LanguageObj["reminder_to_incomplete_" + CurrentLanguage] : reminderformData.action : reminderformData.action
            } )</p>

        </div>

        <div className="px-4 py-4 overflow-y-auto overflow-x-hidden"  >
          <div className="flex flex-col ">
            <div className=" overflow-auto lang ">
              <div className="  whitespace-nowrap   ">
                <table className="w-full  border">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121]">
                        {/* <input type="checkbox" checked={selectAllEmp} onClick={()=>SelectAllEmployees(selectAllEmp)} name="list-check" className="mr-4" /> */}
                        {LanguageObj && LanguageObj["name_label_" + CurrentLanguage] ? LanguageObj["name_label_" + CurrentLanguage] : "Name"}</th>
                      <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121]">
                        {LanguageObj && LanguageObj["email_address_label_" + CurrentLanguage] ? LanguageObj["email_address_label_" + CurrentLanguage] : "Email Address"}</th>
                      <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121]">
                        {LanguageObj && LanguageObj["mobile_no_label_" + CurrentLanguage] ? LanguageObj["mobile_no_label_" + CurrentLanguage] : "Mobile No	"}</th>
                      {/* <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121]">
                      {LanguageObj && LanguageObj["created_date_"+CurrentLanguage]? LanguageObj["created_date_"+CurrentLanguage]:"Created Date"}</th> */}
                      <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121]">
                        {LanguageObj && LanguageObj["status_label_" + CurrentLanguage] ? LanguageObj["status_label_" + CurrentLanguage] : "Status"}</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">

                    {getCampaignEmployeesByIDData && getCampaignEmployeesByIDData.length > 0 ?
                      getCampaignEmployeesByIDData.map((item, index) =>
                        <>
                          {(reminderformData.actionId === 2 && !item.isComplete) ?
                            <tr>
                              <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 ">
                                    {/* <input type="checkbox" value={item.id} checked={employees.some(prev => prev.id === item.id)?true:false} onClick={() => handleCampaignEmployees(item)} onChange={()=>{}} /> */}
                                  </div>
                                  <div className="ml-4">
                                    <div className="text-xs leading-5 text-gray-500">{(item && item.employeeId.name ? item.employeeId.name : 'Not Available').slice(0, 15)}</div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                <div className="leading-5 text-gray-500 text-xs">{item.employeeId.email}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-xs font-medium text-gray-500">
                                {item && item.employeeId.mobile ? item.employeeId.mobile : 'Not Available'}
                              </td>
                              {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-xs leading-5 text-gray-500">
                          { moment.unix(item.createdAt / 1000).format(" DD MMM, YYYY ")}
                          </td> */}
                              <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200 text-sm leading-5 font-medium text-center" >
                                {item && item.employeeId.isDisable ?
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                    {LanguageObj && LanguageObj["inactive_" + CurrentLanguage] ? LanguageObj["inactive_" + CurrentLanguage] : "InActive"}
                                  </span>
                                  :
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    {LanguageObj && LanguageObj["active_" + CurrentLanguage] ? LanguageObj["active_" + CurrentLanguage] : "Active"}
                                  </span>
                                }
                                {/* <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span> */}
                              </td>
                            </tr>
                            : null}

                          {(reminderformData.actionId === 3 && !item.isMailSent) ?
                            <tr>
                              <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 ">
                                    {/* <input type="checkbox" value={item.id} checked={employees.some(prev => prev.id === item.id)?true:false} onClick={() => handleCampaignEmployees(item)} onChange={()=>{}} /> */}
                                  </div>
                                  <div className="ml-4">
                                    <div className="text-xs leading-5 text-gray-500">{(item && item.employeeId.name ? item.employeeId.name : 'Not Available').slice(0, 15)}</div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                <div className="leading-5 text-gray-500 text-xs">{item.employeeId.email}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-xs font-medium text-gray-500">
                                {item && item.employeeId.mobile ? item.employeeId.mobile : 'Not Available'}
                              </td>
                              {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-xs leading-5 text-gray-500">
                          { moment.unix(item.createdAt / 1000).format(" DD MMM, YYYY ")}
                          </td> */}
                              <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200 text-sm leading-5 font-medium text-center" >
                                {item && item.employeeId.isDisable ?
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">InActive</span>
                                  :
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span>
                                }
                                {/* <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span> */}
                              </td>
                            </tr>
                            : null}
                        </>

                      )
                      : null}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>

  );
}
