import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let { handleDMGOrgFilter,CountRestrict,valueList, currFilterValue, currFilterName,
       getDistributionIndexData,GetParameterLabel}= this.props;

    //console.log('getDistributionIndexData: ',getDistributionIndexData)

    let YAxis= []
    let XAxis= []
    let XAxisName= []
    let DataValues = []
    let list = []
    let overallData = {
      name: "Overall"
    }
    //console.log('valueListvalueListvalueList: ',valueList)

    if(valueList && valueList.length>0){
      valueList.forEach((val)=>{
        XAxis.push(val[0]);
        XAxisName.push(val[0]+"Avg");



          overallData[val[0]+"Avg"] = val[1];

          if(list.findIndex(prev=>prev.name === "Overall")===-1){
            list.push(overallData)
          }
       
      });
    }



    //console.log('XAxis: ',XAxis)
    //console.log('XAxisName: ',XAxisName)




    
    if(getDistributionIndexData && getDistributionIndexData.length>0){

       getDistributionIndexData.forEach((hello)=>{
        list.push(hello);
       });

      


      list.forEach((item,index)=>{
        XAxisName.forEach((axis,axisIndex)=>{
          
          if(index!==(list.length-1)){
            DataValues.push({
              value:[axisIndex,index,parseFloat(item[axis]).toFixed(2)],
              itemStyle:{color:
                (parseFloat(overallData[axis]) > parseFloat(item[axis]))?
                  (parseFloat(item[axis])===0)?'#ffb3b3':'rgba(230, 0, 0,'+parseFloat(item[axis])/100+')'
                  :
                  (parseFloat(item[axis])===0)?'#c2f0c2':'rgba(46, 184, 46,'+parseFloat(item[axis])/100+')'
              }
            })
          }
          else{
            DataValues.push({
              value:[axisIndex,index,parseFloat(item[axis]).toFixed(2)],
              itemStyle:{color:(parseFloat(item[axis])===0)?'#b3ecff':'rgba(0, 172, 230,'+parseFloat(item[axis])/100+')'}
            })  
          }

        })
      })



    }


      if(list && list.length>0){
        list.forEach((driver)=>{
          YAxis.push(driver.name)
        })
      }

      // //console.log('XAxis: ',XAxis)
      // //console.log('YAxis: ',YAxis)
      // //console.log('DataValues: ',DataValues)
      //console.log('list------------------->: ',list)


      // list.reverse()


      // function getColor(data, index) {
      //   let Color = '';
      //   if(data.name==='Overall'){
      //     return Color = '#bdddf2'
      //   }
      //   else{
      //     if(overallData[index] <= data[index]){
      //       return Color = '#b2edc9'
      //     }
      //     else if(overallData[index] === data[index]){
      //       return Color = 'yellow'
      //     }
      //     else{
      //       return Color = '#f2bdbd'
      //     }
      //   }

      // }


         
      function showNaN (item,name){
        let dataScore = CountRestrict(item.count, item.name==='Overall')?"NA":(  name && item && item[name]? (item[name]).toFixed(2):"NA"  );
        return dataScore
      }

      function getColor(data, index) {

        if(data.name==="Overall" || showNaN(data, index)==="NA"){
          return {Color:'#ffffff',diffTextColor:'#000'}

        }
        else{

          let diffNo = parseInt(Math.abs(parseFloat(overallData[index]) - parseFloat(data[index])));
          if(parseFloat(overallData[index]) <= parseFloat(data[index])){ //greater (blue)
            if(diffNo>=0 && diffNo<=2){
              return { Color: "linear-gradient(90deg, #33cc33, #85e085)", diffTextColor: "#ffffff" };

            }
            else if(diffNo>=2 && diffNo<=12){
              return { Color: "linear-gradient(90deg, #70db70, #c1f0c1)", diffTextColor: "#00000" };

            }
            else{
              return { Color: "linear-gradient(90deg, #adebad, #eafaea)", diffTextColor: "#00000" };
            }

          }
          else{ //lesse (red)
            if(diffNo>=0 && diffNo<=2){
              return { Color: "linear-gradient(90deg, #f5babd, #fce9ea)", diffTextColor: "#000000" };
            }
            else if(diffNo>=2 && diffNo<=12){
              return { Color: "linear-gradient(90deg, #ea95a1, #f2bfc6)", diffTextColor: "#000000" };
            }
            else{
              return { Color: "linear-gradient(90deg, #d03e4d, #e1848d)", diffTextColor: "#ffffff" };

            }
          }
        }

      }



    let {FilterValues}=this.props;  
    
 





    return (
      <>
      {list && list.length>0?
      <div className="overflow-x-auto whitespace-nowrap w-full overflow-hidden  ">
      <table className="w-full border ">
        <thead>
        {list && list.length>0?
            list.map((item,index)=>
            (index===0)?
              <tr className="bg-gray-100 border-b text-xs   text-gray-800">
                <th className="py-2 px-4 border-r font-bold"><div className="flex items-center justify-left">{currFilterName}</div></th>
                
                {XAxis && XAxis.length>0?
                  XAxis.map((driver)=>
                    <th className="py-2 px-4 border-r font-bold"><div className="flex items-center justify-center">{GetParameterLabel(driver)} {(item[driver+"Avg"])?" ("+item[driver+"Avg"].toFixed(2)+")":"NA"}</div></th>

                  )
                  :null}
              
              </tr>
            :null
          ):null}
        </thead>
        <tbody>
          {list && list.length>0?
            list.map((item,index)=>
            (!CountRestrict(item.count, item.name==='Overall'))?
            (index!==0)?
              <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                <td className="p-3 border border-b text-left font-semibold" style={{fontWeight:(item.name==='Overall')?'bold':null}}>    
                  {(item.name)?item.name:'Unavailable'}
                </td>




                  

                {XAxisName&&XAxisName.length>0?
                  XAxisName.map((driver)=>
                    <td onClick={()=>handleDMGOrgFilter(   driver,item.name, item&&(item[driver])?showNaN(item,driver) :"NA" )} className="cursor-pointer p-3 border border-b" style={{background:getColor(item,driver).Color,color:getColor(item,driver).diffTextColor}}>      
                    {item&&(item[driver])?showNaN(item,driver) :"NA"}
                    </td>


                  )
                  :null}
              </tr>
              :null
            :null
            ):null}

        </tbody>
      </table>
      <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>
    </div>
    :null}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
