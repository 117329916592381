import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { dashboardActions } from '../../_actions'
// import Sidebar from '../Sidebar/Sidebar';
// import Profile from '../Profile/Profile';
// import { DotScale, Circular } from 'styled-loaders-react'
// import { Polar } from 'react-chartjs-2';
// import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css';
// import ScratchCard from 'react-scratchcard';
// import ScratchCard from 'react-scratchcard-v2';

// import couponCover from "./card.jpg";

// const settings = {
//   width: 500,
//   height: 500,
//   image: couponCover,
//   finishPercent: 50,

//   onComplete:console.log('complete')
// };

class Day extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flag: false,
            showSelected: false,
            showCurrTrackerDataId: '',
            habitIndexA: 0,
            habitIndexB: 2,
            fieldRemarks: '',
            showDimensionChart: false,
            moodBox: false,
            descnes: '',
            notesinglepage: false,
            taskstatus: '',
            hideallpage: false,
            buttonshow: false,
            newdataall: '',
            remarksdata: '',
            ranks: '',
            neid: '',
            taskcomplete: false,
            titilenam: '',
            idcompleteed: '',
            habitcomplete: false,
            BucketInit: {
                name: 'Time',
                colorCode: '#2196f3',
                imageLinkV2: 'http://13.232.207.221/images/ic_time.png'
            },
            CurrDescId: ''


        }

        this.inputRef = React.createRef();

    }
    componentDidMount() {

        // let data = { weekId: this.props.weekId }


        // this.props.weekId;


        ////this.props.dispatch(dashboardActions.getAllGrowNote(data));





    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.dashboard.getExploreTrackSuccess) {
            return {
                ...nextProps,
                showDimensionChart: true
            }
        } else {
            return {
                ...nextProps
            }
        }

    }


    showFull = (Id) => {
        if (this.state.CurrDescId === Id) {
            this.setState({ CurrDescId: '' })
        }
        else {
            this.setState({ CurrDescId: Id })
        }
    }

    trackDataActivities = (id) => {

        if (this.state.showCurrTrackerDataId) {
            this.setState({ showCurrTrackerDataId: '' })

        }
        else {
            this.setState({ showCurrTrackerDataId: id })

        }
    }
    backbutton = () => {
        let { closeNote } = this.props;
        closeNote()
    }


    taskall = (idcompleteed) => {
        let Id = this.props.weekId;
        // this.setState({ neid: Id })
        let data = { id: idcompleteed }
        this.setState({ habitcomplete: true })
        ////this.props.dispatch(dashboardActions.updateEmployeeSubactivityId(data, Id));
        let { callDataActionAPI } = this.props;
        callDataActionAPI(data, "updateEmployeeSubactivityId", Id)

    }
    showcomplete = () => {
        this.setState({ buttonshow: true })
    }

    hideallpage = () => {
        this.setState({ hideallpage: false })
    }
    moodalcanel = () => {
        this.setState({ habitcomplete: false })
        this.setState({ taskcomplete: false })
        this.setState({ buttonshow: false })
    }
    daycomplete = (score) => {
        this.setState({ taskcomplete: true })
        this.setState({ ranks: score.rank })
        this.setState({ descss: score && score.subactivityId && score.subactivityId.name })
        this.setState({ taskstatus: score.isComplete })
        this.setState({ idcompleteed: score.id })
    }
    notetoself = () => {

        this.setState({ hideallpage: true })

    }


    notetoone = (element) => {
        this.setState({ hideallpage: true })
        this.setState({ notesinglepage: true })
        console.log("element", element)
        this.setState({ newdataall: element })
    }
    inputChange = (event) => {
        event.preventDefault();

        let data = event.target.value

        this.setState({ remarksdata: data })
        console.log(data)
    }

    backpage = () => {
        this.setState({ notesinglepage: false })
    }
    weeknote = (newdataall) => {

        let data = {
            weekId: this.props.weekId, noteToSelf: [
                {
                    noteid: newdataall && newdataall.id,
                    question: newdataall && newdataall.question,
                    answer: this.state.remarksdata

                }

            ]
        }

        ////this.props.dispatch(dashboardActions.submitGrowWeek(data));

        let { callDataActionAPI } = this.props;
        callDataActionAPI(data, "submitGrowWeek")

        this.setState({ notesinglepage: false })
        this.setState({ remarksdata: "" })
    }


    render() {

        let { getAllGrowNote } = this.props;
        // let { clientProfile, getAllParamterByLevelData, getAllGrowNote } = dashboard;




        let { taskcomplete, ranks, descss, taskstatus, idcompleteed, buttonshow, hideallpage, notesinglepage, newdataall } = this.state;


        // if (clientProfile && clientProfile.profile && clientProfile.profile.isHappySurvey) {
        //     this.props.history.push('/app/nextmonth');
        // }
        // let shortName = "hi"
        // function TranslationFn(item) {
        //     if (item && item["transl"] && item["transl"][shortName]) {
        //         let tranlationObj = item["transl"][shortName];
        //         return tranlationObj;
        //     }
        // }
        // console.log("newdataall",newdataall)
        return (


            <div className="p-6 bg-gray-100 ">
                {/* <Profile /> */}
                <main className="">
                    {notesinglepage ?

                        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black bg-opacity-40" >
                            <div className='mx-auto bg-white rounded-lg xl:w-2/6 lg:w-3/6' >
                                <div className='flex items-start justify-between w-full p-4 border-b' >
                                    <h1 className='text-lg font-semibold' >
                                        {newdataall && newdataall.question}
                                    </h1>

                                    <div onClick={() => this.backpage()} >
                                        <span class="material-symbols-outlined bg-gray-100 rounded-full p-2 cursor-pointer" style={{ fontSize: '1.3rem', }}>
                                            close
                                        </span>
                                    </div>
                                </div>

                                <div className='p-6' >
                                    <input
                                        placeholder="REMARK"
                                        multiline={true}
                                        required value={this.state.remarksdata}
                                        name="remarks" onChange={this.inputChange} className="w-full p-2 overflow-hidden bg-white border rounmde-md" />
                                </div>


                                <div className='p-4' >
                                    <div onClick={() => this.weeknote(newdataall)} className="flex justify-center w-full p-2 px-4 text-white bg-blue-500 rounded-md cursor-pointer"> Done</div>
                                </div>


                            </div>
                        </div>
                        :
                        <div className='' >
                            {hideallpage ?
                                <div className='space-y-6 ' >
                                    <div className='flex items-center space-x-4'>
                                        <span onClick={() => this.backbutton()} className="p-2 text-blue-500 bg-blue-100 rounded-full cursor-pointer material-symbols-outlined ">arrow_back</span>
                                        <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Note to Self</h1>
                                    </div>


                                    <div className='grid grid-cols-1 gap-6 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2'>
                                        {
                                            getAllGrowNote && getAllGrowNote.length > 0 ?
                                                getAllGrowNote.map((element, index) => (

                                                    <div className='flex flex-col justify-between p-6 space-y-6' style={{ background: element && element.colorCode, borderRadius: 10 }}>

                                                        <h1 className='text-xl font-medium'>
                                                            {element.question}
                                                        </h1>
                                                        <h1 className='text-base font-medium' > REMARK</h1>

                                                        {/* <input  style={{background:element&&element.colorCode}}
                                                             placeholder="REMARK" required  name="remarks" onChange={this.inputChange} /> */}

                                                        <button onClick={() => this.notetoone(element)} className='bg-black bg-opacity-10 rounded-md px-4 py-2.5 flex items-center space-x-2 justify-center hover:bg-black hover:text-white transition duration-150' >
                                                            <span class="material-symbols-outlined mr-2">
                                                                edit
                                                            </span>
                                                            Edit
                                                        </button>

                                                    </div>
                                                )) : null
                                        }
                                    </div>


                                </div>

                                :
                                <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen p-4 bg-black bg-opacity-40 md:p-0" >
                                    <div className='mx-auto bg-white rounded-lg xl:w-2/6 lg:w-3/6' >
                                        <div className='flex justify-between p-4 border-b' >
                                            <h1 className='text-lg font-semibold' >Congratulations</h1>
                                            <span onClick={() => this.backbutton()} class="material-symbols-outlined bg-gray-100 rounded-full p-2 cursor-pointer" style={{ fontSize: '1.3rem', }}>
                                                close
                                            </span>
                                       
                                        </div>
                                        <div className='flex justify-center mt-4 '>
                                            <img alt="mood" className="w-32 bg-gray-100 rounded-full hover:bg-gray-200 " src="img/grow/images12.jpg" />
                                        </div>
                                        <div className='space-y-4 text-center'>
                                            <h1 className='text-xl font-semibold'>Well Done</h1>
                                            <p className='px-4 '>You have successfully completed your journey. Yor're on they right track.</p>
                                            <p className='font-medium'>Note: what you experienced in this journey.</p>
                                        </div>
                                        <div className="flex items-center justify-end w-full p-4 mt-12 space-x-4 border-t">
                                            <button className="p-2 px-6 text-gray-500 border rounded-md " onClick={() => this.backbutton()}>Cancel</button>
                                            <button className="p-2 px-6 text-white bg-blue-600 rounded-md cursor-pointer" onClick={() => this.notetoself()}>Note  to Self</button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </main>

            </div>





        );
    }
}

function mapStateToProps(state) {
    console.log("state  ", state.authentication);
    const { loggingIn } = state.authentication;
    const { questions, users, dashboard } = state;
    return {
        loggingIn,
        questions,
        users,
        dashboard

    };
}

export default connect(mapStateToProps)(Day);