import React, {useEffect, useState  } from "react";
import { connect } from "react-redux";
import Header from "../Header/Header";
import { Link } from 'react-router-dom';
import Sidebar from "./Components/Sidebar/Sidebar";
import { CiFilter } from "react-icons/ci";

import { pmsActions } from '../../../_actions';
import moment from "moment";
function NPSSurveypage2(props) {


  useEffect(()=>{

    props.dispatch(pmsActions.pmsGetEmpAppraisalList(props));
  
    },[]) 

let {pms}=props

let{pmsGetEmpAppraisalList}=pms


let newpagedataa=(data)=>{
  props.history.push(`/app/empMyReview/${data.id}`);
}



    return (
      <>
        <div className="h-screen overflow-hidden bg-gray-50">
          <Header 
          // sidebarToggle={sidebarToggle}


          />
         

          <div className="flex w-full" style={{ height: "calc(100% - 2rem)" }}>
            <Sidebar />
           
          
            <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto ">
              <div className="md:p-6 p-4 mx-auto  overflow-hidden overflow-y-auto space-y-6 h-[calc(100vh-4rem)] ">
                <div className="flex items-center justify-between">
                  <h1 className="text-xl text-[#3D405B] font-medium">Appraisal Cycle(s) </h1>
                  <div className="flex items-center space-x-3 ">
                    <div className="flex items-center justify-between px-2 space-x-2 overflow-hidden text-sm bg-white border rounded-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search text-gray-600" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>  <path d="M21 21l-6 -6"></path> </svg>
                      <input type="search" name="search"  className="w-full py-2 md:w-48 focus:outline-none placeholder:text-slate-600" placeholder="Search" />
                    </div>

                    <div className="flex justify-center md:justify-start">
                      <button className="w-full px-4 py-2 text-center text-white transition-all duration-200 transform bg-white border rounded-sm cursor-pointer md:w-auto text-md"  >
                        <CiFilter className="w-5 h-5 text-gray-600" />
                      </button>
                    </div>
                  </div>
                </div>

               

                <div className="w-full space-y-3">
                  {pmsGetEmpAppraisalList&&pmsGetEmpAppraisalList.length>0?
                  pmsGetEmpAppraisalList.map((item,index)=>
                  <div className="flex flex-col items-center justify-center text-center bg-white border rounded-md">
                  <div class="flex items-center justify-between py-3 px-4 w-full">
                    <div class="text-start text-lg w-56">{item&&item.name}</div>
                    <div class="flex justify-center items-center">Submission End Date: {moment(item&&item.createdAt).format("DD/MM/YYYY")}</div>
                    <div class="flex justify-center items-center space-x-2"><span>Progress</span>
                      <div class="w-48 h-1.5 bg-[#E8E8E8] overflow-hidden rounded-full">
                        <div class="bg-[#009EA7] w-2/3 h-1.5"></div>
                      </div>
                      <span> 50%</span>
                    </div>
                    <div class="text-end flex justify-end">
{!item&&item.isActive?
                        <button type="button" class="uppercase font-medium text-sm text-[#FFA000] bg-[#FFA000]/10 w-24 py-2 px-2 rounded focus:outline-none focus:shadow-outline">InProgress</button>
:                      <button onClick={()=>newpagedataa(item)} type="button" class="uppercase font-medium text-sm text-[#009EA7] border border-[#009EA7] w-24 py-2 px-2 rounded focus:outline-none focus:shadow-outline">Start</button>

}

                    </div>
                  </div>
                </div >
                  ):null}
               
             



                </div >
              </div >
            </main >






          </div >
        </div >
      </>
    );
  }

function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard,pms } = state;
  return {
    loggingIn,
    survey,
    dashboard,
    pms
  };
}
export default connect(mapStateToProps)(NPSSurveypage2);