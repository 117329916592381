import React from "react";
import moment from "moment";

export default function Item(props) {
let { CurrentManagerData, getSurveyData  } = props;

  return (
    <>
      <section className=" space-y-2 gap-5 px-10 pt-12">
        <header className="bg-white ">
    <div className="flex justify-between capitalize w-12/12 mx-auto py-6 pt-3 space-y-1">
      
      
      <h1 className="text-xl text-white font-bold">
      {getSurveyData && getSurveyData.logo?
        <img src={getSurveyData && getSurveyData.logo?getSurveyData.logo:""}  style={{height:'90px'}}/>
        :null}
      </h1>

      <h1 className="text-xl text-white font-bold">
        <img src={"img/logo/happypluslogo.png"}  style={{height:'60px'}}/>
      </h1>

      
    </div>
  </header>
          <main className="flex h-screen items-center pb-4">
            
            {/* text area */}
            <div className="bg-white w-full text-white py-6 ">
              <div className="flex items-center  "> 
                <h1 className="text-indigo-700 font-bold text-5xl text-left leading-relaxed w-full">
                  If We Continue<br />
                  Doing What We<br />
                  Are Doing, We<br />
                  Will Get What<br />
                  We Are Getting!
                </h1>
              </div>
            </div>
            {/* img area */}

            {/* <div
            className="w-full h-full bg-no-repeat bg-cover bg-center " 
              style={{backgroundImage: 'url("./img/managerreport/we_continue.jpg")'}}>
            </div> */}

          </main>
        </section>

    </>
  );
}







