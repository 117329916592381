import React, { Component } from "react";
import { connect } from "react-redux";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // showFilter:false,
      // showValue:'',
      currFilterList: [],
      finalFilter: [],
      searchName: "",
    };
  }
  componentDidMount() {}

  handleParameterQuestion = () => {};

  handleSelectFilter = (name) => {
    // this.setState({showFilter:false});
    this.props.closeFilter(false);

    let { currFilterList, finalFilter } = this.state;
    let { handleMultifilterIndexScore } = this.props;
    let getIndex = currFilterList.findIndex((prev) => prev === name);
    if (getIndex === -1) {
      currFilterList.push(name);
      finalFilter.push({
        level: name,
        valueList: ["All"],
      });

      this.setState({ currFilterList, finalFilter }, () => {
        handleMultifilterIndexScore(finalFilter, name);
      });
    } else {
      currFilterList.splice(getIndex, 1);
      let getIndex2 = finalFilter.findIndex((prev) => prev.level === name);
      finalFilter.splice(getIndex2, 1);

      this.setState({ currFilterList, finalFilter }, () => {
        if (finalFilter && finalFilter.length > 0) {
          handleMultifilterIndexScore(
            finalFilter,
            finalFilter[finalFilter.length - 1].level
          );
        } else {
          handleMultifilterIndexScore([]);
          // this.setState({showFilter:false})
          this.props.closeFilter(false);
        }
      });
    }
  };

  handleOpenFilter = (check) => {
    // this.setState({showFilter:!check,showValue:''});
    this.props.closeFilter(!check, "");
    this.setState({ searchName: "" });

    // if (check) {
    //   this.setState({ finalFilter: [] })
    // }
  };

  handleOpenValue = (name) => {
    if (this.props.showValue === name) {
      // this.setState({showValue:'',showFilter:false})
      this.props.closeFilter(false, "");
    } else {
      // this.setState({showValue:name,showFilter:false})
      this.props.closeFilter(false, name);
    }
    this.setState({ searchName: "" });
  };

  // handleSelectValue=(value,name)=>{
  //     let { finalFilter } = this.state;

  //     let getIndex =  finalFilter.findIndex(prev=>prev.level===name);

  //     if(getIndex===-1){

  //         finalFilter.push({
  //             "level":name,
  //             "value":value
  //         });

  //     }
  //     else{

  //         let getIndex2 =  finalFilter.findIndex(prev=>prev.value===value);
  //         if(getIndex2===-1){
  //             finalFilter[getIndex].value=value;
  //         }
  //         else if(value==="All"){
  //             finalFilter[getIndex].value="All";
  //         }

  //     }
  //     let { handleMultifilterIndexScore } = this.props;
  //     this.props.closeFilter(false,'');
  //     this.setState({finalFilter},()=>{
  //         handleMultifilterIndexScore(finalFilter,name);
  //     })
  // }

  handleSelectValue = (value, name) => {
    let { finalFilter } = this.state;

    if (value !== "All") {
      // finalFilter.push({
      //   "level":name,
      //   "valueList":["All"]
      // })

      if (finalFilter && finalFilter.length > 0) {
        let getIndex = finalFilter.findIndex((prev) => prev.level === name);
        if (getIndex === -1) {
          finalFilter.push({
            level: name,
            valueList: [value],
          });
        } else {
          if (
            finalFilter &&
            finalFilter[getIndex] &&
            finalFilter[getIndex]["valueList"]
          ) {
            if (finalFilter[getIndex]["valueList"][0] === "All") {
              finalFilter[getIndex]["valueList"].splice(0, 1);
            }

            let valueList = finalFilter[getIndex]["valueList"];

            let getIndexValue = valueList.findIndex((prev) => prev === value);

            if (getIndexValue === -1) {
              finalFilter[getIndex]["valueList"].push(value);
            } else {
              finalFilter[getIndex]["valueList"].splice(getIndexValue, 1);
            }
          } else {
            finalFilter.push({
              level: name,
              valueList: [value],
            });
          }
        }
      } else {
        finalFilter.push({
          level: name,
          valueList: [value],
        });
      }
    } else {
      if (finalFilter && finalFilter.length > 0) {
        let getIndex = finalFilter.findIndex((prev) => prev.level === name);
        if (getIndex === -1) {
          finalFilter.push({
            level: name,
            valueList: ["All"],
          });
        } else {
          finalFilter[getIndex] = {
            level: name,
            valueList: ["All"],
          };
        }
      } else {
        finalFilter.push({
          level: name,
          valueList: ["All"],
        });
      }
    }

    // let getIndex =  finalFilter.findIndex(prev=>prev.level===name);

    // if(getIndex===-1){

    //     finalFilter.push({
    //         "level":name,
    //         "value":value
    //     });

    // }
    // else{

    //     let getIndex2 =  finalFilter.findIndex(prev=>prev.value===value);
    //     if(getIndex2===-1){
    //         finalFilter[getIndex].value=value;
    //     }
    //     else if(value==="All"){
    //         finalFilter[getIndex].value="All";
    //     }

    // }
    let { handleMultifilterIndexScore } = this.props;
    // this.props.closeFilter(false,'');

    this.setState({ finalFilter }, () => {
      handleMultifilterIndexScore(finalFilter, name);
    });
  };

  handleFilterClear = () => {
    let { handleMultifilterIndexScore } = this.props;
    this.setState({ finalFilter: [], currFilterList: [] }, () => {
      handleMultifilterIndexScore([]);
    });
  };

  handleSelectAllFilter = () => {};

  handleSearch = (e) => {
    this.setState({ searchName: e.target.value });
  };

  render() {
    // const ref = React.createRef();
    let { EmpFilterData2, getIndexFilterData, showValue, showFilter } =
      this.props;
    let { searchName, currFilterList, finalFilter } = this.state;

    let DemographicValueList = {};
    if (getIndexFilterData) {
      DemographicValueList = getIndexFilterData;
      // DemographicValueList.sort();
    }

    // //console.log('EmpFilterData2: ',EmpFilterData2)
    // //console.log('DemographicValueList: ',DemographicValueList)
    //console.log('finalFilter:--------> ', finalFilter)
    // //console.log('currFilterList: ',currFilterList)

    function SortList(list) {
      let temp = [];
      if (list && list.length > 0) {
        temp = list.sort();
      }
      return temp;
    }

    function checkedFn(value, name) {
      let check = false;
      if (finalFilter && finalFilter.length > 0) {
        let getIndex = finalFilter.findIndex((prev) => prev.level === name);
        if (getIndex !== -1) {
          if (
            finalFilter &&
            finalFilter[getIndex] &&
            finalFilter[getIndex]["valueList"]
          ) {
            if (finalFilter[getIndex]["valueList"][0] === "All") {
              finalFilter[getIndex]["valueList"].splice(0, 1);
            } else {
              let valueList = finalFilter[getIndex]["valueList"];

              let getIndexValue = valueList.findIndex((prev) => prev === value);

              if (getIndexValue !== -1) {
                check = true;
              }
            }
          }
        }
      }
      // //////console.log("check--->",value,"-",check)
      return check;
    }

    // function selectedValue(item) {
    //   let Value = '';
    //   let getIndex = finalFilter.findIndex(prev => prev.level === item);
    //   if (getIndex !== -1) {
    //     if (finalFilter && finalFilter[getIndex] && finalFilter[getIndex].value) {
    //       Value = finalFilter[getIndex].value;
    //     }
    //   }
    //   return Value;
    // }

    function getValueLabel(item) {
      let Value = "";
      let getIndex = finalFilter.findIndex((prev) => prev.level === item);
      if (getIndex !== -1) {
        if (
          finalFilter &&
          finalFilter[getIndex] &&
          finalFilter[getIndex].valueList &&
          finalFilter[getIndex].valueList.length > 0
        ) {
          if (finalFilter[getIndex].valueList.length === 1) {
            Value = item + " (" + finalFilter[getIndex].valueList[0] + ")";
          } else {
            Value = item + " (" + finalFilter[getIndex].valueList.length + ")";
          }
        }
      }
      //console.log('finalFilter:--------> ', finalFilter)

      // let value = (selectedValue(item)? ( selectedValue(item)==="All"? item+" ("+selectedValue(item)+")":selectedValue(item)  ):item+' (All)')
      return Value;
    }

    return (
      <>
        <div className=" space-y-4   gap-4">
          {/* 1 */}
          <div
            onClick={() => this.handleOpenFilter(showFilter)}
            className="cursor-pointer bg-[#2196F3] border flex justify-between px-4 items-center relative py-2 rounded-md w-full"
          >
            <div className="flex items-center text-white">
            <div className="flex space-x-2 items-center ">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5"></path></svg>
              <p
                onClick={() => this.handleOpenFilter(showFilter)}
            
              >
                Demographics
              </p>
              <span
                onClick={() => this.handleOpenFilter(showFilter)}
                className=" bg-white  rounded-full text-[#2196F3] text-xs w-6 h-6 flex items-center justify-center font-semibold"
              >
                {currFilterList && currFilterList.length > 0
                  ? currFilterList.length
                  : 0}
              </span>
            </div>
           
            </div>
            <span
              onClick={() => this.handleOpenFilter(showFilter)}
              className="material-symbols-outlined text-white"
            >
              arrow_drop_down
            </span>
            {showFilter ? (
              <div
                className="z-10 absolute left-0 top-12 bg-white shadow-lg rounded-lg px-4 py-2  font-normal font-xs  w-full border h-60 overflow-y-auto"
                id="chatbot"
              >
                {EmpFilterData2 && EmpFilterData2.length > 0
                  ? EmpFilterData2.map((dmg, index) => (
                      <div
                        className="flex"
                        key={index}
                        onClick={() => this.handleSelectFilter(dmg.name)}
                      >
                        <input
                          type="checkbox"
                          className="mr-2 cursor-pointer"
                          checked={
                            currFilterList.some((prev) => prev === dmg.name)
                              ? true
                              : false
                          }
                          onChange={() => {}}
                        />
                        <label className="py-2  flex items-center w-full cursor-pointer hover:bg-gray-100">
                          {dmg.label}
                        </label>
                      </div>
                    ))
                  : null}
              </div>
            ) : null}
          </div>
          {/* 2 */} 
          <div className="grid 2xl:grid-cols-3 xl:grid-cols-2 grid-cols-1  gap-4">
            {currFilterList && currFilterList.length > 0
              ? currFilterList.map((item) => (
                  <div className="cursor-pointer flex items-center justify-between text-sm font-medium rounded-md px-4 py-2 text-[#3D405B] relative border bg-white">
                    <p
                      onClick={() => this.handleOpenValue(item)}
                      className="mr-2 line-clamp-1"
                    >
                      {getValueLabel(item)}
                    </p>{" "}
                    <span
                      onClick={() => this.handleOpenValue(item)}
                      className="material-symbols-outlined"
                    >
                      arrow_drop_down
                    </span>
                    {showValue === item ? (
                      <>
                        <div
                          className="z-10 absolute left-0 top-8 bg-white shadow-lg  px-4 py-2 font-normal font-xs w-full h-60 overflow-y-auto"
                          id="chatbot"
                        >
                          <input
                            type="text"
                            name="searchName"
                            value={searchName}
                            onChange={this.handleSearch}
                            placeholder="Search"
                            className="w-full border p-1.5 rounded-sm focus:outline-none text-gray-500 mb-2 focus:ring-blue-500 focus:ring-1"
                          />
                        
                          <div className="py-2 m-1 flex items-center">
                            <input
                              type="checkbox"
                              onClick={() =>
                                this.handleSelectValue("All", item)
                              }
                              onChange={() => {}}
                            />
                            <label
                              onClick={() =>
                                this.handleSelectValue("All", item)
                              }
                              className="ml-2"
                            >
                              {"All"}
                            </label>
                          </div>
                          {SortList(DemographicValueList[item]) &&
                          DemographicValueList &&
                          DemographicValueList[item] &&
                          DemographicValueList[item].length > 0
                            ? SortList(DemographicValueList[item]).map(
                                (value, index) =>
                                  value &&
                                  value.match(
                                    new RegExp(`${searchName}`, "gi")
                                  ) ? (
                                    <div
                                      className="flex"
                                      key={index}
                                      onClick={() =>
                                        this.handleSelectValue(value, item)
                                      }
                                    >
                                      <input
                                        type="checkbox"
                                        checked={checkedFn(value, item)}
                                        onChange={() => {}}
                                        className="mr-2 cursor-pointer"
                                      />
                                      <label className="py-2 flex items-center w-full cursor-pointer hover:bg-gray-100">
                                        {value}
                                      </label>
                                    </div>
                                  ) : null
                              )
                            : null}
                        </div>
                      </>
                    ) : null}
                  </div>
                ))
              : null}
          </div>


          {/* <button
              onClick={() => this.handleFilterClear()}
              className="cursor-pointer bg-[#2196F3] text-white space-x-1 text-sm  border-[#2196F3] flex justify-between px-4 items-center  py-2 rounded-md   "
            > 

             <span> {"Clear All"}</span>
            </button> */}



          {currFilterList && currFilterList.length > 0 ? (

            <button
            onClick={() => this.handleFilterClear()}
            className="cursor-pointer border  text-[#EF4444] space-x-1 text-base flex justify-center items-center px-4 py-2.5 rounded-md w-full "
          >
     
            <span> Clear All</span>
          </button>

          ) : null}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(Filter);
