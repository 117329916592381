import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


import * as echarts from 'echarts';

import ecStat from 'echarts-stat';


echarts.registerTransform(ecStat.transform.regression);

// class QuadrantChart extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//     }
//   }
//   componentDidMount() {

//   }

  export default function QuadrantChart(props) {
    let { CountRestrict, getDemographicIndexData, indexType, selectedItem1, selectedItem2  } = props;
    
    let happinessIndex = selectedItem1.index;
    let engagementIndex = selectedItem2.index;

    let happinessName = selectedItem1.name;
    let engagementName = selectedItem2.name;

    let happinessCutoff = selectedItem1.cutOff;
    let engagementCutoff = selectedItem2.cutOff;



    let LL = []
    let HL = []
    let LH = []
    let HH = []


    let list = []
    let DepartmentList = []

    if(getDemographicIndexData && getDemographicIndexData.length>0){
      getDemographicIndexData.forEach((item)=>{
        if(!CountRestrict(item.count,false,"other")){
        let temp = {
          name:item.name,
          x:(item[happinessIndex])?(item[happinessIndex]).toFixed(2):item[happinessIndex],
          y:(item[engagementIndex])?(item[engagementIndex]).toFixed(2):item[engagementIndex],
        }
        if(parseFloat(temp["x"]) && parseFloat(temp["y"])){
          list.push([
            parseFloat(temp["x"]),parseFloat(temp["y"])
          ])
        }


        DepartmentList.push(temp)




        if(item[happinessIndex]<happinessCutoff && item[engagementIndex]<engagementCutoff){
          LL.push([
            item[happinessIndex],item[engagementIndex]
          ]);
        }
        else if(item[happinessIndex]>=happinessCutoff && item[engagementIndex]<engagementCutoff){
          HL.push([
            item[happinessIndex],item[engagementIndex]
          ]);
        }
        else if(item[happinessIndex]<happinessCutoff && item[engagementIndex]>=engagementCutoff){
          LH.push([
            item[happinessIndex],item[engagementIndex]
          ]);
        }
        else if(item[happinessIndex]>=happinessCutoff && item[engagementIndex]>=engagementCutoff){
          HH.push([
            item[happinessIndex],item[engagementIndex]
          ]);
        } 




      }


      })
    }


    //console.log('LL--->',LL);
    //console.log('HL--->',HL);
    //console.log('LH--->',LH);
    //console.log('HH--->',HH);


    console.log('/===================/list/===================',list)








    return (
      <>
        <div className="W-full">

              {list && list.length>0?
             
                <ReactEcharts
                    style={{width: '100%', height:'400px'}}
                    option={{
                      dataset: [{
                          source: list
                      }, {
                          transform: {
                              type: 'ecStat:regression'
                              // 'linear' by default.
                              // config: { method: 'linear', formulaOn: 'end'}
                          }
                      }],
                      // title: {
                      //     text: 'Linear Regression',
                      //     left: 'center'
                      // },
                      legend: {
                          bottom: 5
                      },
                      tooltip: {
                          trigger: 'axis',
                          axisPointer: {
                              type: 'cross'
                          },
                          formatter:function(b) {
                            let a = b[0];


                            let getIndex1 = -1
                            if(DepartmentList && DepartmentList.length>0){
                              let a0 =  a && a.data && a.data[0] && a.data[0].toFixed(2)?a.data[0].toFixed(2):"";
                              let a1 =  a && a.data && a.data[1] && a.data[1].toFixed(2)?a.data[1].toFixed(2):"";
                              getIndex1 = DepartmentList.findIndex(prev=>prev.x===a0 && prev.y===a1)
                            }
                            //////console.log("getIndex1----",getIndex1)

                            let name1 = getIndex1!==-1 && DepartmentList && DepartmentList[getIndex1] && DepartmentList[getIndex1].name?DepartmentList[getIndex1].name:""

                            if(DepartmentList && DepartmentList[getIndex1] && DepartmentList[getIndex1].name){

                              return (
                                  '<b>'+name1+'</b><br/>'+
                                  happinessName+': '+DepartmentList[a.dataIndex].x+'<br/>'+
                                  engagementName+': '+DepartmentList[a.dataIndex].y
                                )
                            }


                          }
                      },
                      xAxis: {
                          // splitLine: {
                          //     lineStyle: {
                          //         type: 'dashed'
                          //     }
                          // },
                          max:100
                      },
                      yAxis: {
                          // splitLine: {
                          //     lineStyle: {
                          //         type: 'dashed'
                          //     }
                          // },
                          max:100
                      },
                      series: [
                        {
                            name: 'Scatter plot',
                            type: 'scatter',
                            itemStyle:{color:'#3d8bf2'}
                        }, 
                        {
                            name: 'Best fit line',
                            type: 'line',
                            datasetIndex: 1,
                            symbolSize: 0.1,
                            symbol: 'circle',
                            itemStyle:{
                              color:"#48d97a"
                            },
                            // label: { show: true, fontSize: 16 },
                            // labelLayout: { dx: -20 },
                            // encode: { label: 2, tooltip: 1 }
                        }
                    ]
                  }}
                  />
                  :<div className='text-gray-400 font-medium text-center my-24 text-2xl'>{"Data Not Available"}</div>
                }

        </div>
      </>
    );
  }
// }
// function mapStateToProps(state) {
// //   //////console.log("state  ", state);
//   const { loggingIn } = state.authentication;
//   const { users, dashboard } = state;
//   return {
//     loggingIn,
//     users,
//     dashboard
//   };
// }
// export default connect(mapStateToProps)(QuadrantChart);
