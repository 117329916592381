import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let { getCategoryOverallIndexData, indexNumber, getIndexTrendScoreFilterData, CountRestrict, currFilterValue, currFilterName, getDistributionIndexData, getIndexDriverData, GetParameterLabel } = this.props;




    let { FilterValues } = this.props;

    let surveyList = []
    if (getCategoryOverallIndexData && getCategoryOverallIndexData.length > 0) {
      getCategoryOverallIndexData.forEach((item) => {

        if ((item && item.scoreData && item.scoreData[indexNumber])) {

          let label = item["surveyData"]["name"];
          surveyList.push((label))


        }




      })
    }





    function getValueScore(data1, survey1, type) {
      let score = 0

      if (type === "overall") {
        if (data1 && data1.length > 0) {
          let getindex22 = data1.findIndex(prev => prev.surveyData.name === survey1);
          if (getindex22 !== -1) {
            score = data1 && data1[getindex22] && data1[getindex22]["scoreData"] && data1[getindex22]["scoreData"][indexNumber] ? data1[getindex22]["scoreData"][indexNumber] : 0
          }

        }

      } else {
        if (data1 && data1.surveyDetails && data1.surveyDetails.length > 0) {
          let getindex22 = data1.surveyDetails.findIndex(prev => prev.name === survey1);
          if (getindex22 !== -1) {
            score = data1 && data1.surveyDetails && data1.surveyDetails[getindex22] && data1.surveyDetails[getindex22]["data"] && data1.surveyDetails[getindex22]["data"][indexNumber] ? data1.surveyDetails[getindex22]["data"][indexNumber] : 0
          }
        }

      }
      return score ? score.toFixed(2) : 0

    }



    console.log('a a a a a a a a aq FilterValues: ', FilterValues)

    console.log('getIndexTrendScoreFilterData: ', getIndexTrendScoreFilterData)




    function getColor(crrScore, overallScore, type) {


      if (type === "OVERALL" || crrScore === 0) {
        return { Color: '#f1f1f1', diffTextColor: '#000' }

      }
      else {

        let diffNo = parseInt(Math.abs(parseFloat(overallScore) - parseFloat(crrScore)));
        ////console.log("diffNo:",diffNo)
        if (parseFloat(overallScore) <= parseFloat(crrScore)) { //greater (blue)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: '#33cc33', diffTextColor: '#ffffff' }

          }
          else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: '#70db70', diffTextColor: '#00000' }

          }
          else {
            return { Color: '#adebad', diffTextColor: '#00000' }
          }

        }
        else { //lesse (red)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: '#f5babd', diffTextColor: '#000000' }

          }
          else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: '#ea95a1', diffTextColor: '#000000' }

          }
          else {
            return { Color: '#d03e4d', diffTextColor: '#ffffff' }

          }
        }
      }

    }



    return (
      <>
        {!(FilterValues && FilterValues.length > 0) ?
          <>

          </>
          :
          <>
            {getIndexTrendScoreFilterData && getIndexTrendScoreFilterData.length > 0 ?
              <div className="py-1 pb-6 overflow-x-auto whitespace-nowrap ">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="text-xs">
                      <th className="p-3 font-bold text-gray-800 border border-gray-300 bg-gray-50 ">{currFilterName}</th>

                      {surveyList && surveyList.length > 0 ?
                        surveyList.map((item) =>
                          <th className="p-3 px-10 font-bold text-gray-800 border border-gray-300 bg-gray-50">{item} </th>
                        ) : null}

                    </tr>
                  </thead>
                  <tbody>


                    <tr className="mb-10 text-xs text-center text-gray-800 bg-white lg:mb-0">
                      <td className="p-3 font-medium text-left border border-b" >
                        {"Overview"}
                      </td>

                      {surveyList && surveyList.length > 0 ?
                        surveyList.map((item) =>
                          <td className="p-3 px-6 font-medium border border-b" style={{ background: getColor(0, 0, "overall").Color, color: getColor(0, 0, "overall").diffTextColor }}>
                            {getValueScore(getCategoryOverallIndexData, item, "overall")}
                          </td>
                        ) : null}

                    </tr>



                    {getIndexTrendScoreFilterData && getIndexTrendScoreFilterData.length > 0 ?
                      getIndexTrendScoreFilterData.map((demo) =>
                        <>

                          <tr className="mb-10 text-xs text-center text-gray-800 bg-white lg:mb-0">
                            <td className="p-3 font-medium text-left border border-b" >
                              {demo["dmgValue"]}
                            </td>

                            {surveyList && surveyList.length > 0 ?
                              surveyList.map((item) =>
                                <td className="p-3 px-6 font-medium border border-b" style={{ background: getColor(getValueScore(demo, item, "score"), getValueScore(getCategoryOverallIndexData, item, "overall")).Color, color: getColor(getValueScore(demo, item, "score"), getValueScore(getCategoryOverallIndexData, item, "overall")).diffTextColor }}>
                                  {getValueScore(demo, item, "score")}
                                </td>
                              ) : null}

                          </tr>


                        </>
                      ) : null}




                  </tbody>
                </table>
                {/* <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div> */}

              </div>
              : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
