import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import Header from "../Header/Header";
import Sidebar from "./Components/Sidebar/Sidebar";
import { CiFilter } from "react-icons/ci";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { GoGoal } from "react-icons/go";
import { VscFeedback } from "react-icons/vsc";
import { LiaUserCogSolid } from "react-icons/lia";
import { AiOutlineClose } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { TiDocumentText } from "react-icons/ti";
import { TbEdit } from "react-icons/tb";
import { Button } from '@material-ui/core';
import { useEffect } from 'react';
import { pmsActions } from '../../../_actions';


function EmpMyReview(props, history) {

  const [useid, setid] = useState('');
  useEffect(() => {

    let data = { "appraisalCycleId": props.match.params.id }
    props.dispatch(pmsActions.GetEmpPMSGoalList(data))
    props.dispatch(pmsActions.pmsGetEmpDetails())

    props.dispatch(pmsActions.getMyPeersList({
      reviewer_relation: ['Manager', 'Peer', 'Self']
    }))
    setid(props.match.params.id)

  }, [])

  let { pms } = props;
  let { GetEmpPMSGoalList, GetEmpPMSTaskList, getMyPeersList, getPeersGoalList, pmsGetEmpDetails } = pms
  const [isAddGoal, setIsAddGoal] = useState(false);
  const [isAddTask, setIsAddTask] = useState(false);
  const [pageCurrentList, setCurrentPage] = useState(true);

  let initialvalue1 = {

  }
  const [useUpdateFormdata, setUpdateFormdata] = useState(initialvalue1);
  const [isUpdateGoal, setIsUpdateGoal] = useState(false);
  const [useGoalTaskid, setGoalTaskid] = useState('');
  const [getgoalid, setgoalid] = useState("");

  // State to track the active tab
  const [activeTab, setActiveTab] = useState('goals');
  const [activeTabOne, setActiveTabOne] = useState('Review');
  let initialvalue = {

  }
  const [isUpdateAddTask, setisUpdateAddTask] = useState(false)
  const [isUpdateAdddata, setisUpdateAdddata] = useState('')
  const [useFormdata, setFormdata] = useState(initialvalue);
  const [useUpdateForm, setupdateform] = useState();
  const [updatetasklist, setupdatelist] = useState('');
  const [usegoalidappraisal, setgoalidappraisal] = useState('')
  const [useselfapp, setselfapp] = useState('')


  let initialtask = {

  }
  const [useTaskForm, setTaskForm] = useState(initialtask);

  let initialtask3 = {

  }
  const [useTaskFormUpdate, setTaskFormUpdate] = useState(initialtask3);

  const handleTabClick = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTab(tab);
  };

  const handleTabClickTop = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTabOne(tab);
  };


  const handleAddGoal = () => {
    setIsAddGoal(!isAddGoal);
  };
  const handleCloseAddGoal = () => {
    setIsAddGoal(false);
  };
  const handleupdateClose = () => {
    setIsUpdateGoal(false);
  };

  const handleAddTask = () => {
    setIsAddTask(!isAddTask);
    // setGoalTaskid(value && value.id)

  };
  const handleCloseAddTask = () => {
    setIsAddTask(false);
  };

  const submitform = () => {
    let data = {
      ...useFormdata, 'appraisalCycleId': useid
    }
    let taskid = useid
    props.dispatch(pmsActions.AddEmpPMSGoal(data, props, taskid))
    setIsAddGoal(false)
  }

  const updateFormdata = () => {
    let data = {
      ...useUpdateFormdata, 'appraisalCycleId': useid, "id": getgoalid
    }
    props.dispatch(pmsActions.UpdateEmpPMSGoal(data, useid, props))
    setIsUpdateGoal(false)
  }

  const newSetgaolid = (value) => {
    setgoalid(value && value.id)
    setIsUpdateGoal(!isUpdateGoal)

    setupdateform(value)

  }
  // console.log('locationlocationlocationlocationlocation', GetEmpPMSGoalList);
  const viewTasklist = (item) => {

    props.dispatch(pmsActions.GetEmpPMSTaskList({ "goalId": item && item.id }))

    setCurrentPage(false)
    setGoalTaskid(item && item.id)
  }

  const submitListdata = () => {

    let data = { ...useTaskForm, "goalId": useGoalTaskid }
    let taskid = useGoalTaskid
    props.dispatch(pmsActions.AddEmpPMSTask(data, props, taskid))
    setIsAddTask(!isAddTask);
  }
  let updatetasklists = (value) => {
    setupdatelist(value && value.id)
    setisUpdateAddTask(true)
    setisUpdateAdddata(value)
    // let date={...useTaskFormUpdate,"goalId": useGoalTaskid}
    //     props.dispatch(pmsActions.UpdateEmpPMSTask(date))
  }
  let submitListdataupdate = () => {
    let data = { ...useTaskFormUpdate, "goalId": useGoalTaskid, "id": updatetasklist }
    let taskid = useGoalTaskid
    props.dispatch(pmsActions.UpdateEmpPMSTask(data, props, taskid))
    setisUpdateAddTask(!isUpdateAddTask)

  }
  // console.log(pmsGetEmpDetails,'pmsGetEmpDetails');


  const reviewpage = (value) => {
    setActiveTab("reviewApprial")
    let data = {
      'appraisalCycleId': useid,
      'emp_id': value && value.emp_id,
      'level': [1, 2]
    }
    props.dispatch(pmsActions.getPeersGoalList(data))
    setselfapp(value)

  }


  const mypeerlist = (value) => {
    // props.history.push("teammatesPeers")
  }
  const appraisalsubmit = () => {
    let data = {
      "goalId": usegoalidappraisal, "rating": "7", "emp_id": useselfapp,
      "comment": "great", 'appraisalCycleId': useid,
    }
    props.dispatch(pmsActions.submitGoalReview(data))

  }
  console.log(getPeersGoalList, 'getPeersGoalList');
  return (
    <>
      <div className="h-screen overflow-hidden bg-gray-50">
        <Header />



        <div className="flex w-full" style={{ height: "calc(100% - 2rem)" }}>
          <Sidebar />

          {pageCurrentList ?
            (activeTab === 'reviewApprial' ?

              <>
                <div className="h-screen overflow-y-auto bg-gray-50">
                  {/* code here  */}
                  <div className='space-y-4 '>

                    <div className='flex items-center px-6 py-6 space-x-4 bg-white'>

                      <span>
                        <svg onClick={() => setActiveTab("appraisal")} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M15 6l-6 6l6 6"></path>
                        </svg></span>
                      <p className='text-xl font-medium text-[#3D405B]'>Appraisal 2023</p>
                      <span>Submission End Date: 15-Jul-2023</span>
                    </div>

                    <main className='p-6 space-y-4'>
                      <div className='grid grid-cols-3 gap-4 p-6 py-2 bg-white border rounded-md ' >
                        <div className='text-[#3D405B]   flex items-center space-x-4 '>
                          <img src="https://media.istockphoto.com/id/1391365592/photo/beautiful-afro-woman.jpg?s=612x612&amp;w=0&amp;k=20&amp;c=o4M2f5nPzpU3ipnjK6jZF6xRNCFem_kz51IcRFGZ6qs=" className="object-cover w-10 h-10 rounded-full" />
                          <h2 className='text-lg font-semibold'>{useselfapp && useselfapp.name}</h2>
                        </div>

                        <div className="text-[#3D405B]  flex items-center space-x-4 w-full "><p className="text-base">Fonal Score </p>
                          <h2 className="p-4 py-2 text-base font-semibold border rounded-md bg-gray-50">10</h2></div>

                        <div className='text-[#3D405B]  flex items-center space-x-2 '>
                          <p className='text-base'>Rating: </p>
                          <h2 className='text-base font-semibold'>Satisfactory</h2>
                        </div>

                      </div>

                      {getPeersGoalList && getPeersGoalList.goalList.length > 0 ?
                        getPeersGoalList.goalList.map((item) =>



                          <div className='flex flex-col gap-10 p-6 bg-white border rounded-sm lg:flex-row'>
                            <div className='w-full space-y-4'>
                              <div className='flex items-center px-4 py-2 space-x-2 rounded-md w-fit bg-red-50'>
                                <span className='w-2 h-2 bg-red-500 rounded-full'></span>
                                <span className='font-medium text-red-500'>{item && item.priority}</span>
                              </div>
                              <h3 className='text-lg font-semibold text-[#3D405B]'>{item && item.name}</h3>

                              <p>{item && item.desc}</p>


                              <div className='flex items-center space-x-4'>
                                <span className='font-medium '>Progress</span>
                                <div className='h-2 overflow-hidden rounded-full bg-slate-100 w-60'><div className='w-3/6 h-2 bg-green-500'></div></div>
                                <span className='font-medium '>{item && item.weightage}</span>
                              </div>
                            </div>
                            <div className='w-full space-y-4'>

                              <div className='flex flex-col gap-4 '>
                                <input type='text' name="rating" className='w-16 px-4 py-2 font-medium text-black border rounded-md focus:outline-none bg-gray-50 ' placeholder='10' />
                                <label className="flex items-center space-x-2"><span onClick={() => setgoalidappraisal(item && item.id)} className='font-medium text-black'>Done</span></label>

                              </div>
                              <input name="comment" placeholder='input goal' className=' px-4 py-2 font-medium text-black border rounded-md focus:outline-none bg-gray-50' ></input>


                            </div>


                          </div>




                        ) : null}


                      <div className='flex items-center justify-end px-6 py-6 space-x-4 bg-white '>

                        <button className='w-32 px-4 py-3 uppercase border rounded-md'>cancel</button>
                        <button onClick={() => appraisalsubmit()} className='border rounded-md w-32 py-3 px-4 uppercase bg-[#3ab1b7] text-white '>Submit</button>
                      </div>

                    </main>
                  </div>



                </div>



              </>
              : <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden">


                <div className="bg-white ">
                  <div className="px-6 py-4 h-[64px] border flex justify-between">
                    <div className="flex items-center space-x-4 text-[#212121]">
                      <button onClick={() => props.history.goBack()} className="p-2 duration-200 rounded-md cursor-pointer hover:bg-slate-100">
                        <MdOutlineArrowBackIos className="w-5 h-5" /> </button>
                      <div className="text-2xl font-semibold">
                        <b className="font-medium">Appraisal 2023</b>
                      </div>
                      <div>
                        <p>Submission End Date: 15-Jul-2023</p>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="bg-white">
                  <div className="px-6 py-2 h-[64px] border flex justify-between">
                    <ul className="flex">
                      <li>
                        <div className={`relative block p-4 ${activeTabOne === 'Review' ? 'font-medium bg-[#009EA7] text-white rounded-t-md' : 'text-[#212121]/70'}`} onClick={() => handleTabClickTop('Review')}>
                          <div className="flex items-center justify-center gap-4 cursor-pointer">
                            <GoGoal className="w-5 h-5" />
                            <span className="text-sm">My Review</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className={`relative block p-4 ${activeTabOne === 'Peers' ? 'font-medium bg-[#009EA7] text-white rounded-t-md' : 'text-[#212121]/70'}`} onClick={() => handleTabClickTop('Peers')}>
                          <div className="flex items-center justify-center gap-4 cursor-pointer">
                            <VscFeedback className="w-5 h-5" />
                            <span className="text-sm">My Peers</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                {
                  activeTabOne === 'Review' &&
                  <>
                    <div className="bg-white">
                      <div className="px-6 py-2 h-[64px] border flex justify-between">
                        <ul className="flex">
                          <li>
                            <div className={`relative block p-4 ${activeTab === 'goals' ? 'font-medium text-[#009EA7]' : 'text-[#212121]/70'}`} onClick={() => handleTabClick('goals')}>
                              <span className={`absolute inset-x-0 w-full bg-[#009EA7] h-[3px] -bottom-px ${activeTab === 'goals' ? '' : 'hidden'}`} />
                              <div className="flex items-center justify-center gap-4 cursor-pointer">
                                <GoGoal className="w-5 h-5" />
                                <span className="text-sm">Goals</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className={`relative block p-4 ${activeTab === 'feedback' ? 'font-medium text-[#009EA7]' : 'text-[#212121]/70'}`} onClick={() => handleTabClick('feedback')}>
                              <span className={`absolute inset-x-0 w-full bg-[#009EA7] h-[3px] -bottom-px ${activeTab === 'feedback' ? '' : 'hidden'}`} />
                              <div className="flex items-center justify-center gap-4 cursor-pointer">
                                <VscFeedback className="w-5 h-5" />
                                <span className="text-sm">360 Feedback</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className={`relative block p-4 ${activeTab === 'appraisal' ? 'font-medium text-[#009EA7]' : 'text-[#212121]/70'}`} onClick={() => handleTabClick('appraisal')}>
                              <span className={`absolute inset-x-0 w-full bg-[#009EA7] h-[3px] -bottom-px ${activeTab === 'appraisal' ? '' : 'hidden'}`} />
                              <div className="flex items-center justify-center gap-4 cursor-pointer">
                                <LiaUserCogSolid className="w-5 h-5" />
                                <span className="text-sm">Self Appraisal</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>


                    {activeTab === 'goals' &&
                      <>
                        <div className="md:p-6 p-4 mx-auto overflow-hidden overflow-y-auto space-y-4 h-[calc(100vh-12rem)] ">
                          <div className="flex items-center justify-between">
                            <h1 className="text-xl text-[#3D405B] font-medium">Goals</h1>
                            <div className="flex items-center space-x-3 ">
                              <div className="flex items-center justify-between px-2 space-x-2 overflow-hidden text-sm bg-white border rounded-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search text-gray-600" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>  <path d="M21 21l-6 -6"></path> </svg>
                                <input type="search" name="search" className="w-full py-2 md:w-48 focus:outline-none placeholder:text-slate-600" placeholder="Search" />
                              </div>

                              <div className="flex justify-center md:justify-start">
                                <button className="w-full px-4 py-2 text-center text-white transition-all duration-200 transform bg-white border rounded-sm cursor-pointer md:w-auto text-md"  >
                                  <CiFilter className="w-5 h-5 text-gray-600" />
                                </button>
                              </div>

                              <div className="flex justify-center md:justify-start">
                                <button onClick={handleAddGoal} className="bg-[#18afb8] hover:bg-[#18afb8] transition-all duration-200 md:w-auto w-full md:py-2 py-3  px-4 rounded-sm text-center justify-center cursor-pointer transform  flex items-center text-white space-x-1 md:text-sm text-md">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path><path d="M9 12l6 0"></path><path d="M12 9l0 6"></path></svg>
                                  <h1 className>Add Goal</h1>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex space-x-3">

                            <div className="relative block px-4 py-2 bg-white border">
                              <div className="flex items-center justify-center gap-4 text-[#212121]">
                                <div class="rounded-full h-2 w-2 bg-[#009EA7]"></div>
                                <span className="text-sm font-medium"> Low Priority </span>
                              </div>
                            </div>

                            <div className="relative block px-4 py-2 bg-white border">
                              <div className="flex items-center justify-center gap-4 text-[#212121]">
                                <div class="rounded-full h-2 w-2 bg-[#FFC107]"></div>
                                <span className="text-sm font-medium"> Medium Priority </span>
                              </div>
                            </div>
                            <div className="relative block px-4 py-2 bg-white border">
                              <div className="flex items-center justify-center gap-4 text-[#212121]">
                                <div class="rounded-full h-2 w-2 bg-[#F44336]"></div>
                                <span className="text-sm font-medium"> High Priority </span>
                              </div>
                            </div>
                          </div>

                          {/* <div className="">
                    <div className="flex flex-col items-center justify-center p-6 py-10 space-y-10 text-center bg-white border rounded-md">
                      <img src="./img/overview/empty_survey.png" />
                      <div className="space-y-2">
                        <h1 className="text-2xl font-medium text-[#3D405B]">No Appraisal Added Yet!</h1>
                        <p className="text-[#3D405B]/70">Looks like no appraisal has been initiatedyet.</p>
                        <div className="flex justify-center pt-4">
                          <button onClick={handleAddGoal} className="bg-[#18afb8] hover:bg-[#18afb8] transition-all duration-200 md:w-auto w-full md:py-2 py-3  px-4 rounded-sm text-center justify-center cursor-pointer transform  flex items-center text-white space-x-1 md:text-sm text-md">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path><path d="M9 12l6 0"></path><path d="M12 9l0 6"></path></svg>
                            <h1 className>Add A New Goal</h1>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                          {GetEmpPMSGoalList && GetEmpPMSGoalList.length > 0 ?
                            GetEmpPMSGoalList.map((item, index) =>

                              <div className="w-full space-y-2">
                                <div className="flex flex-col items-center justify-center text-center bg-white border rounded-md">
                                  <div class="flex items-center justify-between py-3 px-4 w-full">
                                    <div className='flex items-center space-x-3'>
                                      <div class="rounded-full h-2 w-2 bg-[#009EA7]"></div>
                                      <div class="text-start text-sm w-56">{item && item.goalName}</div>
                                    </div>
                                    <div class="flex justify-center items-center space-x-2">
                                      <div class="w-48 h-1.5 bg-[#E8E8E8] overflow-hidden rounded-full">
                                        <div class="bg-[#009EA7] w-2/3 h-1.5"></div>
                                      </div>
                                      <span> 50%</span>
                                    </div>
                                    <div class="flex justify-center items-center">
                                      <div
                                        // onClick={() => handleAddTask(item)}
                                        class="cursor-pointer bg-[#ECEFF1] py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                                        <TiDocumentText className='w-6 h-6 text-[#212121]/70' />
                                      </div>
                                    </div>
                                    <div class="text-end flex justify-end space-x-4">
                                      <button onClick={() => newSetgaolid(item)} type="button" class="bg-[#009EA7]/10 py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                                        <TbEdit className='w-6 h-6 text-[#009EA7]/70' />
                                      </button>
                                      <button onClick={() => viewTasklist(item)} type="button" class="bg-[#ECEFF1] py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                                        <BsThreeDotsVertical className='w-6 h-6 text-[#212121]/70' />
                                      </button>
                                      <button type="button" class="bg-[#ECEFF1] py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                                        <TiDocumentText className='w-6 h-6 text-[#212121]/70' />
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="flex flex-col items-center justify-center text-center bg-white border rounded-md">
                      <div class="flex items-center justify-between py-3 px-4 w-full">
                        <div className='flex items-center space-x-3'>
                          <div class="rounded-full h-2 w-2 bg-[#FFC107]"></div>
                          <div class="text-start text-sm w-56">Employee Engagement Survey</div>
                        </div>
                        <div class="flex justify-center items-center space-x-2">
                          <div class="w-48 h-1.5 bg-[#E8E8E8] overflow-hidden rounded-full">
                            <div class="bg-[#009EA7] w-2/3 h-1.5"></div>
                          </div>
                          <span> 50%</span>
                        </div>
                        <div class="flex justify-center items-center">
                          <button onClick={handleAddTask} type="button" class="cursor-pointer bg-[#ECEFF1] py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                            <TiDocumentText className='w-6 h-6 text-[#212121]/70' />
                          </button>
                        </div>
                        <div class="text-end flex justify-end space-x-4">
                          <button type="button" class="bg-[#009EA7]/10 py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                            <TbEdit className='w-6 h-6 text-[#009EA7]/70' />
                          </button>
                          <button type="button" class="bg-[#ECEFF1] py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                            <BsThreeDotsVertical className='w-6 h-6 text-[#212121]/70' />
                          </button>
                          <button type="button" class="bg-[#ECEFF1] py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                            <TiDocumentText className='w-6 h-6 text-[#212121]/70' />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col items-center justify-center text-center bg-white border rounded-md">
                      <div class="flex items-center justify-between py-3 px-4 w-full">
                        <div className='flex items-center space-x-3'>
                          <div class="rounded-full h-2 w-2 bg-[#F44336]"></div>
                          <div class="text-start text-sm w-56">Employee Engagement Survey</div>
                        </div>
                        <div class="flex justify-center items-center space-x-2">
                          <div class="w-48 h-1.5 bg-[#E8E8E8] overflow-hidden rounded-full">
                            <div class="bg-[#009EA7] w-2/3 h-1.5"></div>
                          </div>
                          <span> 50%</span>
                        </div>
                        <div class="flex justify-center items-center">
                          <button onClick={handleAddTask} type="button" class="cursor-pointer bg-[#ECEFF1] py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                            <TiDocumentText className='w-6 h-6 text-[#212121]/70' />
                          </button>
                        </div>
                        <div class="text-end flex justify-end space-x-4">
                          <button type="button" class="bg-[#009EA7]/10 py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                            <TbEdit className='w-6 h-6 text-[#009EA7]/70' />
                          </button>
                          <button type="button" class="bg-[#ECEFF1] py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                            <BsThreeDotsVertical className='w-6 h-6 text-[#212121]/70' />
                          </button>
                          <button type="button" class="bg-[#ECEFF1] py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                            <TiDocumentText className='w-6 h-6 text-[#212121]/70' />
                          </button>
                        </div>
                      </div>
                    </div> */}

                              </div>
                            ) : null}
                        </div >

                      </>
                    }

                    {activeTab === 'feedback' &&
                      <>
                        <div className="md:p-6 p-4 mx-auto overflow-hidden overflow-y-auto space-y-4 h-[calc(100vh-12rem)] ">
                          <div className="flex items-center justify-between">
                            <h1 className="text-xl text-[#3D405B] font-medium">360 Feedback</h1>
                          </div>

                          <div className="m-5 overflow-hidden border border-gray-200 rounded-lg shadow-md">
                            <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Feedback Link</th>
                                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Submission End Date</th>
                                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Status</th>
                                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Action</th>
                                  <th scope="col" className="px-6 py-4 font-medium text-gray-900" />
                                </tr>
                              </thead>
                              <tbody className="border-t border-gray-100 divide-y divide-gray-100">
                                <tr className="hover:bg-gray-50">
                                  <td className="px-6 py-4">https://sprw.io/stt-iML2QnNFCaZ3qxDtZNzkMr</td>
                                  <td className="px-6 py-4">01-Jan-2023</td>
                                  <td className="px-6 py-4">
                                    <div class="text-start flex justify-start">
                                      <button type="button" class="uppercase font-medium text-sm text-[#FFA000] bg-[#FFA000]/10 w-32 py-2 px-2 rounded focus:outline-none focus:shadow-outline">InProgress</button>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4">
                                    <div class="text-start flex justify-start space-x-3">
                                      <button type="button" class="uppercase font-medium text-sm text-[#009EA7] border border-[#009EA7] w-24 py-2 px-2 rounded focus:outline-none focus:shadow-outline">Start</button>
                                      <button type="button" class="bg-[#ECEFF1] py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                                        <BsThreeDotsVertical className='w-6 h-6 text-[#212121]/70' />
                                      </button>
                                      <button type="button" class="bg-[#ECEFF1] py-2 px-2 rounded focus:outline-none focus:shadow-outline">
                                        <TiDocumentText className='w-6 h-6 text-[#212121]/70' />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                        </div >

                      </>
                    }
                    {activeTab === 'appraisal' &&
                      <>
                        <div className="md:p-6 p-4 mx-auto overflow-hidden overflow-y-auto space-y-4 h-[calc(100vh-12rem)] ">
                          <div className="flex items-center justify-between">
                            <h1 className="text-xl text-[#3D405B] font-medium">Self Appraisal</h1>
                            <div className="flex items-center space-x-3 ">
                              <div className="flex items-center justify-between px-2 space-x-2 overflow-hidden text-sm bg-white border rounded-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search text-gray-600" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>  <path d="M21 21l-6 -6"></path> </svg>
                                <input type="search" name="search" className="w-full py-2 md:w-48 focus:outline-none placeholder:text-slate-600" placeholder="Search" />
                              </div>

                              <div className="flex justify-center md:justify-start">
                                <button className="w-full px-4 py-2 text-center text-white transition-all duration-200 transform bg-white border rounded-sm cursor-pointer md:w-auto text-md"  >
                                  <CiFilter className="w-5 h-5 text-gray-600" />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="overflow-hidden border border-gray-200 rounded-lg shadow-md">
                            <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Appraisal Cycle</th>
                                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Submission End Date</th>
                                  <th scope="col" className="px-6 py-4 font-medium text-gray-900">Status</th>
                                </tr>
                              </thead>
                              {pmsGetEmpDetails && pmsGetEmpDetails.length > 0 ?
                                pmsGetEmpDetails.map((item) =>
                                  <tbody className="border-t border-gray-100 divide-y divide-gray-100">
                                    <tr className="hover:bg-gray-50">
                                      <td className="px-6 py-4">{item && item.name}</td>
                                      <td className="px-6 py-4">Submission End Date: 01-Jan-2023</td>


                                      <td className="px-6 py-4">
                                        <div class="text-start flex justify-start space-x-3">
                                          <button onClick={() => reviewpage(item)} type="button" class="uppercase font-medium text-sm text-[#009EA7] border border-[#009EA7] w-24 py-2 px-2 rounded focus:outline-none focus:shadow-outline">Start</button>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ) : null}
                            </table>
                          </div>


                        </div >

                      </>
                    }
                  </>
                }

                {/* {activeTab === 'reviewApprial' &&

        } */}

                {
                  activeTabOne === 'Peers' &&
                  <>
                    <div className="bg-white ">
                      <div className="px-6 py-4 h-[64px] border flex justify-between">
                        <div className="flex items-center space-x-4 text-[#212121]">
                          <div className="text-2xl font-semibold">
                            <b className="pl-4 font-medium">My Peers</b>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='space-y-2 md:m-6'>
                      {getMyPeersList && getMyPeersList.length > 0 ?
                        getMyPeersList.map((item, index) =>
                          <div class="bg-white shadow flex p-5 items-center rounded-lg">
                            <Link to='/app/teammatesPeers'>
                              <div class="flex items-center">
                                <img src='img/newimg/Rectangle 11116.png' alt='' class="rounded-full" />
                                <div onClick={() => mypeerlist(item)} class="ml-4">
                                  <span class="capitalize text-xl font-medium block text-gray-800">{item && item.name}</span>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ) : null}

                    </div>
                  </>
                }

                {/* Content to show below the navigation bar */}

              </main >
            )
            :
            <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden">


              <div className="bg-white ">
                <div className="px-6 py-4 h-[64px] border flex justify-between">
                  <div className="flex items-center space-x-4 text-[#212121]">
                    <button onClick={() => setCurrentPage(true)} className="p-2 duration-200 rounded-md cursor-pointer hover:bg-slate-100">
                      <MdOutlineArrowBackIos className="w-5 h-5" /> </button>
                    <div className="text-2xl font-semibold">
                      <b className="font-medium">Task List</b>
                    </div>
                    <div>
                      <p>Submission End Date: 15-Jul-2023</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="md:p-6 p-4 mx-auto overflow-hidden overflow-y-auto  ">
                <div className="flex items-center justify-between">
                  <h1 className="text-xl text-[#3D405B] font-medium">Goals</h1>
                  <div className="flex items-center space-x-3 ">
                    <div className="flex items-center justify-between px-2 space-x-2 overflow-hidden text-sm bg-white border rounded-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search text-gray-600" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>  <path d="M21 21l-6 -6"></path> </svg>
                      <input type="search" name="search" className="w-full py-2 md:w-48 focus:outline-none placeholder:text-slate-600" placeholder="Search" />
                    </div>

                    <div className="flex justify-center md:justify-start">
                      <button className="w-full px-4 py-2 text-center text-white transition-all duration-200 transform bg-white border rounded-sm cursor-pointer md:w-auto text-md"  >
                        <CiFilter className="w-5 h-5 text-gray-600" />
                      </button>
                    </div>

                    <div className="flex justify-center md:justify-start">
                      <button onClick={handleAddTask} className="bg-[#18afb8] hover:bg-[#18afb8] transition-all duration-200 md:w-auto w-full md:py-2 py-3  px-4 rounded-sm text-center justify-center cursor-pointer transform  flex items-center text-white space-x-1 md:text-sm text-md">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path><path d="M9 12l6 0"></path><path d="M12 9l0 6"></path></svg>
                        <h1 className>Add Task</h1>
                      </button>
                    </div>
                  </div>
                </div>


              </div >
              <div className='flex 	 flex-wrap w-100 m-4'>
                {GetEmpPMSTaskList && GetEmpPMSTaskList.length > 0 ?
                  GetEmpPMSTaskList.map((item, index) =>


                    <div className='bg-white w-1/4 p-4 mx-2 border rounded-sm' >

                      <p className="font-medium text-black " >{item && item.taskName}</p>
                      <p className=" text-black mt-2px" >{item && item.desc}</p>
                      <div className='flex pt-5 items-center '>
                        <button onClick={() => updatetasklists(item)} type="button" class="bg-[#009EA7]/10 py-2 px-2  rounded focus:outline-none focus:shadow-outline ">
                          <TbEdit className='w-6 h-6 text-[#009EA7]/70' />
                        </button>
                      </div>
                    </div>



                  ) : null}
              </div>



              {/* Content to show below the navigation bar */}

            </main >

          }
        </div >
      </div >

      {isAddGoal && (
        <div className="fixed inset-0 z-40 flex items-center justify-center w-full h-full mt-0 overflow-hidden overflow-y-auto bg-black/40">
          <div className="bg-[#fff] model mx-auto rounded-lg shadow-lg lg:w-2/6 w-full overflow-y-auto">

            <div className="flex items-center justify-between p-6 py-3 border-b bg-dark">
              <p className="font-medium text-black">Add Goal</p>
              <div className="rounded-lg cursor-pointer modal-close bg-[#F1F1F1] p-1" onClick={handleCloseAddGoal}>
                <AiOutlineClose className='w-6 h-6 text-gray-600' />
              </div>
            </div>

            <div className="p-4 space-y-4">
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Goal Name</lable>
                <input name="goalName" onChange={(e) => setFormdata({ ...useFormdata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Start Date</lable>
                <input name="startDate" onChange={(e) => setFormdata({ ...useFormdata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="DD-MM-YYYY" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">End Date</lable>
                <input name="endDate" onChange={(e) => setFormdata({ ...useFormdata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="DD-MM-YYYY" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Priority</lable>
                <input name="priority" onChange={(e) => setFormdata({ ...useFormdata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Description</lable>
                <input name="desc" onChange={(e) => setFormdata({ ...useFormdata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Progress % (A goal is complete when progress is 100%)</lable>
                <input name="progress" onChange={(e) => setFormdata({ ...useFormdata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Weightage %</lable>
                <input name="weightage" onChange={(e) => setFormdata({ ...useFormdata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='pb-4'>
                <div onClick={() => submitform()} className="text-center text-white w-full px-3 py-2 text-sm uppercase transition-all duration-150 ease-linear bg-[#009EA7] border focus:outline-none focus:ring">Submit</div>
              </div>

            </div>

          </div>
        </div>
      )
      }

      {isUpdateGoal && (
        <div className="fixed inset-0 z-40 flex items-center justify-center w-full h-full mt-0 overflow-hidden overflow-y-auto bg-black/40">
          <div className="bg-[#fff] model mx-auto rounded-lg shadow-lg lg:w-2/6 w-full overflow-y-auto">

            <div className="flex items-center justify-between p-6 py-3 border-b bg-dark">
              <p className="font-medium text-black">Update Goal</p>
              <div className="rounded-lg cursor-pointer modal-close bg-[#F1F1F1] p-1" onClick={() => handleupdateClose()}>
                <AiOutlineClose className='w-6 h-6 text-gray-600' />
              </div>
            </div>

            <div className="p-4 space-y-4">
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Goal Name</lable>
                <input name="goalName" onChange={(e) => setUpdateFormdata({ ...useUpdateFormdata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder={useUpdateForm && useUpdateForm.goalName ? useUpdateForm.goalName : "chips"} />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Start Date</lable>
                <input name="startDate" onChange={(e) => setUpdateFormdata({ ...useUpdateFormdata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder={useUpdateForm && useUpdateForm.startDate ? useUpdateForm.startDate : "DD-MM-YYYY"} />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">End Date</lable>
                <input name="endDate" onChange={(e) => setUpdateFormdata({ ...useUpdateFormdata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder={useUpdateForm && useUpdateForm.endDate ? useUpdateForm.endDate : "DD-MM-YYYY"} />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Priority</lable>
                <input name="priority" onChange={(e) => setUpdateFormdata({ ...useUpdateFormdata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder={useUpdateForm && useUpdateForm.priority ? useUpdateForm.priority : "priority"} />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Description</lable>
                <input name="desc" onChange={(e) => setUpdateFormdata({ ...useUpdateFormdata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder={useUpdateForm && useUpdateForm.desc ? useUpdateForm.desc : "desc"} />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Progress % (A goal is complete when progress is 100%)</lable>
                <input name="progress" onChange={(e) => setUpdateFormdata({ ...useUpdateFormdata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder={useUpdateForm && useUpdateForm.progress ? useUpdateForm.progress : "progress"} />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Weightage %</lable>
                <input name="weightage" onChange={(e) => setUpdateFormdata({ ...useUpdateFormdata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder={useUpdateForm && useUpdateForm.weightage ? useUpdateForm.weightage : "weightage"} />
              </div>
              <div className='pb-4'>
                <div onClick={() => updateFormdata()} className="text-center text-white w-full px-3 py-2 text-sm uppercase transition-all duration-150 ease-linear bg-[#009EA7] border focus:outline-none focus:ring">Submit</div>
              </div>

            </div>

          </div>
        </div>
      )
      }

      {
        isAddTask && (
          <div className="fixed inset-0 z-40 flex items-center justify-center w-full h-full mt-0 overflow-hidden overflow-y-auto bg-black/40">
            <div className="bg-[#fff] model mx-auto rounded-lg shadow-lg lg:w-2/6 w-full overflow-y-auto">

              <div className="flex items-center justify-between p-6 py-3 border-b bg-dark">
                <p className="font-medium text-black">Add Task</p>
                <div className="rounded-lg cursor-pointer modal-close bg-[#F1F1F1] p-1" onClick={handleCloseAddTask}>
                  <AiOutlineClose className='w-6 h-6 text-gray-600' />
                </div>
              </div>

              <div className="p-4 space-y-4">
                <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">Task Name</lable>
                  <input name='taskName' onChange={(e) => setTaskForm({ ...useTaskForm, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="Enter" />
                </div>
                <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">Priority</lable>
                  <input name='priority' onChange={(e) => setTaskForm({ ...useTaskForm, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="DD-MM-YYYY" />
                </div>
                {/* <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">End Date</lable>
                  <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="DD-MM-YYYY" />
                </div> */}
                <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">Description</lable>
                  <textarea row={5} name="desc" onChange={(e) => setTaskForm({ ...useTaskForm, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="Write here" />
                </div>
                <div className='pb-4'>
                  <div onClick={() => submitListdata()} className="text-center text-white w-full px-3 py-2 text-sm uppercase transition-all duration-150 ease-linear bg-[#009EA7] border focus:outline-none focus:ring">Submit</div>
                </div>
              </div>

            </div>
          </div>
        )
      }
      {
        isUpdateAddTask && (
          <div className="fixed inset-0 z-40 flex items-center justify-center w-full h-full mt-0 overflow-hidden overflow-y-auto bg-black/40">
            <div className="bg-[#fff] model mx-auto rounded-lg shadow-lg lg:w-2/6 w-full overflow-y-auto">

              <div className="flex items-center justify-between p-6 py-3 border-b bg-dark">
                <p className="font-medium text-black">Update Task</p>
                <div className="rounded-lg cursor-pointer modal-close bg-[#F1F1F1] p-1" onClick={() => setisUpdateAddTask(!isUpdateAddTask)}>
                  <AiOutlineClose className='w-6 h-6 text-gray-600' />
                </div>
              </div>

              <div className="p-4 space-y-4">
                <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">Task Name</lable>
                  <input name='taskName' onChange={(e) => setTaskFormUpdate({ ...useTaskFormUpdate, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder={isUpdateAdddata && isUpdateAdddata.taskName ? isUpdateAdddata.taskName : "TaskName"} />
                </div>
                <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">Priority</lable>
                  <input name='priority' onChange={(e) => setTaskFormUpdate({ ...useTaskFormUpdate, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder={isUpdateAdddata && isUpdateAdddata.priority ? isUpdateAdddata.priority : "Priority"} />
                </div>
                {/* <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">End Date</lable>
                  <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="DD-MM-YYYY" />
                </div> */}
                <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">Description</lable>
                  <textarea row={5} name="desc" onChange={(e) => setTaskFormUpdate({ ...useTaskFormUpdate, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder={isUpdateAdddata && isUpdateAdddata.desc ? isUpdateAdddata.desc : "Description"} />
                </div>
                <div className='pb-4'>
                  <div onClick={() => submitListdataupdate()} className="text-center text-white w-full px-3 py-2 text-sm uppercase transition-all duration-150 ease-linear bg-[#009EA7] border focus:outline-none focus:ring">Submit</div>
                </div>
              </div>

            </div>
          </div>
        )
      }



    </>
  );
}

function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard, pms } = state;
  return {
    loggingIn,
    survey,
    dashboard,
    pms
  };
}
export default connect(mapStateToProps)(EmpMyReview);