import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let { item } = this.props;


    function getPercFn(row, details) {
      let NPSObj = {
        "npsScore": 0,
        "PromotersPer": 0,
        "PasssivesPer": 0,
        "DistractorsPer": 0
      }
      if (details && details.length > 0) {

        let totalCount = 0;
        let Promoters = 0;
        let Passsives = 0;
        let Distractors = 0;

        details.forEach(item => {
          let score = parseInt(item.count);
          let answer = parseInt(item["answerText"]);
          totalCount += score;
          if (parseInt(answer) >= 9 && parseInt(answer) <= 10) {
            Promoters += score;
          }
          if (parseInt(answer) >= 7 && parseInt(answer) <= 8) {
            Passsives += score;
          }
          if (parseInt(answer) >= 0 && parseInt(answer) <= 6) {
            Distractors += score;
          }
        });

        let PromotersPer = (Promoters * 100 / totalCount);
        let PasssivesPer = (Passsives * 100 / totalCount);
        let DistractorsPer = (Distractors * 100 / totalCount);

        let npsScore = Math.round((PromotersPer) - (DistractorsPer));

        NPSObj = {
          "NPSScore": npsScore,
          "PromotersPer": Math.round(PromotersPer),
          "PasssivesPer": Math.round(PasssivesPer),
          "DistractorsPer": Math.round(DistractorsPer)
        }

      }
      return NPSObj && NPSObj[row] ? NPSObj[row] : 0
    }




    let rowList = [];

    if (item?.list?.length > 0) {
      item.list.forEach(inner2 => {
        if (inner2?.details?.length > 0) {
          inner2.details.forEach(inner3 => {
            if (inner3?.answerText) {
              rowList.push(inner3.answerText);
            }
          });
        }
      });
      // Remove duplicates using a Set and sort the list
      rowList = [...new Set(rowList)].sort((a, b) => a - b);
    }



    // const menuOrder = [
    //   'Option A','Option B','Option C','Option D',
    //   'Yes','No'
    // ];



    // let alphaAnswerTemplate = [9]
    // if(optionList && optionList.length>0){
    //   if(alphaAnswerTemplate.includes(templateId)){
    //     optionList.sort(
    //       (a, b) => a.charCodeAt(0) - b.charCodeAt(0)
    //     );
    //   }else{
    //     optionList.sort((a, b) => menuOrder.indexOf(a) - menuOrder.indexOf(b));
    //   }
    // }


    return (
      <>

        <div className='space-y-4 bg-white'>
          <div className='bg-[#f9f9f9] rounded'>
            <div class="space-y-4 text-sm text-slate-800">
              <table class="w-full border-collapse">
                <thead class="text-sm  capitalize bg-[#2196F3]/20">
                  <tr class="border-b-2 border-white">
                    <th class="text-[#212121] font-medium p-4 py-3 text-sm text-left border-r-2 border-white">{"Demographic"}</th>
                    <th class="text-[#212121] font-medium p-4 py-3 text-sm text-center border-r-2 border-white">{"NPS Score (%)"}</th>
                    <th class="text-[#212121] font-medium p-4 py-3 text-sm text-center border-r-2 border-white">{"Promoters (%)"}</th>
                    <th class="text-[#212121] font-medium p-4 py-3 text-sm text-center border-r-2 border-white">{"Passive (%)"}</th>
                    <th class="text-[#212121] font-medium p-4 py-3 text-sm text-center border-r-2 border-white">{"Detractors (%)"}</th>

                  </tr>
                </thead>
                <tbody class="divide-y-2 divide-white ">
                  {item && item.list && item.list.length > 0 ?
                    item.list.map((ele, index) =>
                      <tr key={index} class="even:bg-gray-100 odd:bg-gray-50">
                        <td class="p-2 px-4 border-r-2 border-white"><p class="font-medium">{ele && ele.feedback ? ele.feedback : ""}</p></td>
                        <td class="p-2 px-4 text-center border-r-2 border-white">{getPercFn("NPSScore", ele.details, index, ele.feedback)}</td>
                        <td class="p-2 px-4 text-center border-r-2 border-white">{getPercFn("PromotersPer", ele.details, index, ele.feedback)}</td>
                        <td class="p-2 px-4 text-center border-r-2 border-white">{getPercFn("PasssivesPer", ele.details, index, ele.feedback)}</td>
                        <td class="p-2 px-4 text-center border-r-2 border-white">{getPercFn("DistractorsPer", ele.details, index, ele.feedback)}</td>
                      </tr>
                    ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
