import { userConstants } from '../_constants';
import { userService } from '../_services';
import { CONST } from '../_config';
import CryptoJS from 'crypto-js';
import { pmsActions } from '../_actions';

export const userActions = {
    userlogin,
    // logout,
    validateOtp,


    login,

    uploadImage,
    uploadImageLogo,
    uploadImageLogo2,
    uploadPDF,
    logoutSession,

    // PMS ACTION
    pmsValidateOtp ,
    pmsSignIn 

    
};

function pmsSignIn(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.pmsSignIn(data)
            .then(
                user => {
                 
                    // localStorage.removeItem('adminuser');
                    // window.location.href = "#/login";
                    // localStorage.removeItem('backendUrl');
                    // localStorage.removeItem('accessModule');

                    dispatch(success(user));

                },
                error => {
                    //////console.log("errorerror ", error);
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: userConstants.PMS_LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.PMS_LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.PMS_LOGIN_FAILURE, error } }
}

function pmsValidateOtp(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.pmsValidateOtp(data)
            .then(
                user => {
                    dispatch(success(user));
                    //console.log('user:--------------------> ',user);
                    let userRole="";
                    let accessModule="";
                    if(user && user.userinfo && user.userinfo.role){
                        userRole = user.userinfo.role;
                    }
                    if(user && user.userinfo && user.userinfo.role){
                        accessModule = user.userinfo.moduleAccess;
                    }
                    if(accessModule){
                        let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(accessModule), "secretkey123").toString();
                        localStorage.setItem('accessModule',  JSON.stringify(cryptedText));
                    }
                    //////console.log("accessModule login:",accessModule)


                    if(userRole==="EMP"){
                        localStorage.setItem('backendUrl', JSON.stringify(CONST.BACKEND_URL_PMS));
   
                           setTimeout(()=>{
                               props.history.push(`app/pmsemployee`);
                               
    props.dispatch(pmsActions.pmsGetEmpAppraisalList(props));
   
                           },1000)
                       }
else{
    localStorage.setItem('backendUrl', JSON.stringify(CONST.BACKEND_URL_PMS));
   
    setTimeout(()=>{
        props.history.push(`app/pmsemployee`);

        props.dispatch(pmsActions.pmsGetEmpAppraisalList(props));
    },1000)
}

                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: userConstants.PMS_LOGIN_OTP_REQUEST, user } }
    function success(user) { return { type: userConstants.PMS_LOGIN_OTP_SUCCESS, user } }
    function failure(error) { return { type: userConstants.PMS_LOGIN_OTP_FAILURE, error } }
}

function userlogin(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.userlogin(data)
            .then(
                user => {

                    localStorage.removeItem('adminuser');
                    window.location.href = "#/login";
                    localStorage.removeItem('backendUrl');
                    localStorage.removeItem('accessModule');

                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    //////console.log("error--->",error);
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function validateOtp(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.validateOtp(data)
            .then(
                user => {
                    dispatch(success(user));
                    //console.log('user:--------------------> ',user);
                    let userRole="";
                    let accessModule="";
                    if(user && user.userinfo && user.userinfo.role){
                        userRole = user.userinfo.role;
                    }
                    if(user && user.userinfo && user.userinfo.role){
                        accessModule = user.userinfo.moduleAccess;
                    }
                    if(accessModule){
                        let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(accessModule), "secretkey123").toString();
                        localStorage.setItem('accessModule',  JSON.stringify(cryptedText));
                    }
                    //////console.log("accessModule login:",accessModule)



                    if(userRole==="ADMIN"){
                    localStorage.setItem('backendUrl', JSON.stringify(CONST.BACKEND_URL+""));

                        setTimeout(()=>{
                            props.history.push(`app/overview/`);
                        },1000)
                    }
                    else if(userRole==="MNGR"){
                    localStorage.setItem('backendUrl', JSON.stringify(CONST.BACKEND_URL+""));

                        setTimeout(()=>{
                            props.history.push(`app/manageractionplan/`);
                        },1000)
                    }
                    else if(userRole==="CROLE"){
                     localStorage.setItem('backendUrl', JSON.stringify(CONST.BACKEND_URL+"/crole"));

                        setTimeout(()=>{
                            props.history.push(`app/overview/`);

                        },1000)
                    }
                    else if(userRole==="EMP"){
                        localStorage.setItem('backendUrl', JSON.stringify(CONST.BACKEND_URL+"/emprole"));
   
                           setTimeout(()=>{
                               props.history.push(`app/employeeactionplan/`);
   
                           },1000)
                       }
                    else{
                    localStorage.setItem('backendUrl', JSON.stringify(CONST.BACKEND_URL+""));

                        setTimeout(()=>{
                            props.history.push(`app/overview/`);
                        },1000)
                    }


                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_OTP_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_OTP_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_OTP_FAILURE, error } }
}

// function logout() {
//     userService.logout();
//     return { type: userConstants.LOGOUT };
// }



function logoutSession(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.logoutSession(data)
            .then(
                user => {
                    // dispatch(this.logout());
                    dispatch(success(user));
                    localStorage.removeItem('backendUrl');
                    localStorage.removeItem('accessModule');
                    window.location.href = "#/login";
                         

                },
                error => {
                    window.location.href = "#/login";
                    dispatch(failure(error));
                    //console.log(error);
                }
            );
    };

    function request(user) { return { type: userConstants.LOGOUT_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGOUT_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGOUT_FAILURE, error } }
}



//SUPER ADMIN LOGIN 

function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/survey' });
                    //////console.log("user: ", user);

                },
                error => {
                    //////console.log("errorerror ", error);
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_SUPER_ADMIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUPER_ADMIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_SUPER_ADMIN_FAILURE, error } }
}


function uploadImage(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}
function uploadImageLogo(data) {
    return dispatch => {
        userService.uploadImageLogo(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: userConstants.LOGO_FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.LOGO_FILE_UPLOAD_STATUS_FAILURE, error } }
}
function uploadPDF(data) {
    return dispatch => {
        userService.uploadPDF(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request(uploadImage) { return { type: userConstants.LOGIN_SUPER_ADMIN_REQUEST, uploadImage } }
    function success(uploadImage) { return { type: userConstants.UPLOAD_PDF_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.UPLOAD_PDF_STATUS_FAILURE, error } }
}

function uploadImageLogo2(data) {
    return dispatch => {
        userService.uploadImageLogo2(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: userConstants.LOGO_FILE_UPLOAD_2_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.LOGO_FILE_UPLOAD_2_STATUS_FAILURE, error } }
}
