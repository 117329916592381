import React from "react";
import ReactEcharts from "echarts-for-react";

export default function EmployeesConsider(props) {
  let {  handleSelectChart, getExitQuestionAllCountData } =
    props;



    let list = [];
    let value = [];
    let total = 0;
    if (getExitQuestionAllCountData && getExitQuestionAllCountData.length > 0) {
      let temp = getExitQuestionAllCountData.sort((a,b) => a.count - b.count);
  
      temp.forEach((element,index) => {
        if (element && element._id) {
          list.push(element._id);
          value.push(element.count);
  
          total += Number(element.count);
        }
      });
    }

  // //console.log('DistObj: ',DistObj)
  function spliceDecimal(no) {
    if (no) {
      let str = no.toString();
      let first = str.split(".")[0];
      let second = str.split(".")[1];
      let second2 = second ? second.substring(0, 2) : "";
      let final = first + "." + second2;
      if (second2) {
        return parseFloat(final).toFixed(2);
      } else {
        return no;
      }
    } else {
      return 0;
    }
  }

  return (
    <>
      {/* <div className="pt-1 -mt-6" style={{ width: '500px' }}> */}
      <ReactEcharts
        onEvents={{
          click: (params) => handleSelectChart(params),
        }}
        style={{
          marginTop: " ",
          height: list && list.length > 12 ? "1500px" : "460px",
        }}
        option={{
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            formatter: function (a, b, c, d) {
              let nameLabel = a && a[0] && a[0].name ? a[0].name : "";
              let valueLabel = a && a[0] && a[0].value ? a[0].value : "NA";
              let valueScore = nameLabel
                ? nameLabel +
                  " = " +
                  (valueLabel && valueLabel !== "NA" ? valueLabel + "" : "NA")
                : "";
              let htmlElement = "<p>" + valueScore + "</p>";
              htmlElement +=
                "<p>" +
                " (" +
                spliceDecimal((Number(valueLabel) * 100) / total) +
                "%)" +
                "</p>";
              return htmlElement;
            },
          },
          legend: {
            data: [],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "15%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            splitLine: {
              lineStyle: {
                color: "#f2f2f2",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#e6e6e6",
              },
            },
          },
          yAxis: {
            type: "category",
            data: list,
            axisLine: {
              lineStyle: {
                color: "#e6e6e6",
              },
            },
            axisLabel: {
              color: "#000",
              // formatter: function (value) {
              //   let wordNEW = GetParameterLabel(value)
              //   let word = wordNEW.replace('_', '')
              //   // //console.log('axisLabel',word)
              //   if (word.length > 40) {
              //     return word.substring(0, 40) + "..";
              //   } else {
              //     return word;
              //   }
              // }
            },
          },
          axisLabel: {
            color: "#000",
            interval: 0,
            formatter: function (value) {
              if (value.length > 40) {
                return value.substring(0, 40) + "..";
              } else {
                return value;
              }
            },
          },
          series: [
            {
              name: "Demographics wise Exit",
              type: "bar",
              barWidth: "20px",
              // barCategoryGap: "10px",
              stack: "total",
              label: {
                show: true,
                position: "right",
                formatter: function (value) {
                  return value.data > 0 ? value.data + "" : "0";
                },
              },
              // emphasis: {
              //   focus: 'series'
              // },
              data: value,
              color: "#6536d1",
            },
          ],
        }}
      />
      {/* </div> */}
    </>
  );
}
