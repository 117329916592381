import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from "../Header/Header";
// import Sidebar from "./Components/Sidebar/Sidebar";
import { CiFilter } from "react-icons/ci";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { GoGoal } from "react-icons/go";
import { VscFeedback } from "react-icons/vsc";
import { LiaUserCogSolid } from "react-icons/lia";
import { AiOutlineClose } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { TiDocumentText } from "react-icons/ti";


export default function ReviewApprialOne(props, history) {

  let { item, name, stage } = props;
  const [isAddGoal, setIsAddGoal] = useState(false);
  const [isAddTask, setIsAddTask] = useState(false);
  // State to track the active tab
  const [activeTab, setActiveTab] = useState('goals');
  const [activeTabOne, setActiveTabOne] = useState('Review');

  const handleTabClick = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTab(tab);
  };

  const handleTabClickTop = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTabOne(tab);
  };


  const handleAddGoal = () => {
    setIsAddGoal(!isAddGoal);
  };
  const handleCloseAddGoal = () => {
    setIsAddGoal(false);
  };

  const handleAddTask = () => {
    setIsAddTask(!isAddTask);
  };
  const handleCloseAddTask = () => {
    setIsAddTask(false);
  };


  return (
    <>
      <div className="h-screen overflow-y-auto bg-gray-50">
        {/* code here  */}
        <div className='space-y-4 '>

          <div className='flex items-center px-6 py-6 space-x-4 bg-white'>

            <span>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M15 6l-6 6l6 6"></path>
              </svg></span>
            <p className='text-xl font-medium text-[#3D405B]'>Appraisal 2023</p>
            <span>Submission End Date: 15-Jul-2023</span>
          </div>

          <main className='p-6 space-y-4'>
            <div className='grid grid-cols-3 gap-4 p-6 py-2 bg-white border rounded-md ' >
              <div className='text-[#3D405B]   flex items-center space-x-4 '>
                <img src="https://media.istockphoto.com/id/1391365592/photo/beautiful-afro-woman.jpg?s=612x612&amp;w=0&amp;k=20&amp;c=o4M2f5nPzpU3ipnjK6jZF6xRNCFem_kz51IcRFGZ6qs=" className="object-cover w-10 h-10 rounded-full" />
                <h2 className='text-lg font-semibold'>Taj Khan</h2>
              </div>

              <div className="text-[#3D405B]  flex items-center space-x-4 w-full "><p className="text-base">Fonal Score </p>
                  <h2 className="p-4 py-2 text-base font-semibold border rounded-md bg-gray-50">10</h2></div>

              <div className='text-[#3D405B]  flex items-center space-x-2 '>
                <p className='text-base'>Rating: </p>
                <h2 className='text-base font-semibold'>Satisfactory</h2>
              </div>

            </div>

            <div className='flex flex-col gap-10 p-6 bg-white border rounded-sm lg:flex-row'>
              <div className='w-full space-y-4'>
                <div className='flex items-center px-4 py-2 space-x-2 rounded-md w-fit bg-red-50'>
                  <span className='w-2 h-2 bg-red-500 rounded-full'></span>
                  <span className='font-medium text-red-500'>High Priority</span>
                </div>
                <h3 className='text-lg font-semibold text-[#3D405B]'>Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.</h3>

                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                  took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                  but also the leap into electronic typesetting, remaining essentially unchanged</p>

                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                  took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                  but also the leap into electronic typesetting, remaining essentially unchanged</p>

                <div className='flex items-center space-x-4'>
                  <span className='font-medium '>Progress</span>
                  <div className='h-2 overflow-hidden rounded-full bg-slate-100 w-60'><div className='w-3/6 h-2 bg-green-500'></div></div>
                  <span className='font-medium '>90%</span>
                </div>
              </div>
              <div className='w-full space-y-4'>

                <div className='flex flex-col gap-4 '>
                  <input type='text' className='w-16 px-4 py-2 font-medium text-black border rounded-md focus:outline-none bg-gray-50 ' value='10' />
                  <label className="flex items-center space-x-2"><span className='font-medium text-black'>Done</span></label>

                </div>



              </div>


            </div>
            <div className='flex flex-col gap-10 p-6 bg-white border rounded-sm lg:flex-row'>
              <div className='w-full space-y-4'>
                <div className='flex items-center px-4 py-2 space-x-2 rounded-md w-fit bg-amber-50'>
                  <span className='w-2 h-2 rounded-full bg-amber-500'></span>
                  <span className='font-medium text-amber-500'>Medium Priority</span>
                </div>
                <h3 className='text-lg font-semibold text-[#3D405B]'>Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.</h3>

                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                  took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                  but also the leap into electronic typesetting, remaining essentially unchanged</p>

                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                  took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                  but also the leap into electronic typesetting, remaining essentially unchanged</p>

                <div className='flex items-center space-x-4'>
                  <span className='font-medium '>Progress</span>
                  <div className='h-2 overflow-hidden rounded-full bg-slate-100 w-60'><div className='w-3/6 h-2 bg-green-500'></div></div>
                  <span className='font-medium '>90%</span>
                </div>
              </div>
              <div className='w-full space-y-4'>

                <div className='flex flex-col gap-4 '>
                  <input type='text' className='w-16 px-4 py-2 font-medium text-black border rounded-md focus:outline-none bg-gray-50 ' value='10' />
                  <label className="flex items-center space-x-2"><span className='font-medium text-black'>Done</span></label>

                </div>


              </div>


            </div>

            <div className='flex flex-col gap-10 p-6 bg-white border rounded-sm lg:flex-row'>
              <div className='w-full space-y-4'>
                <div className='flex items-center px-4 py-2 space-x-2 rounded-md w-fit bg-green-50'>
                  <span className='w-2 h-2 bg-green-500 rounded-full'></span>
                  <span className='font-medium text-green-500'>Low Priority</span>
                </div>
                <h3 className='text-lg font-semibold text-[#3D405B]'>Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.</h3>

                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                  took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                  but also the leap into electronic typesetting, remaining essentially unchanged</p>

                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                  took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                  but also the leap into electronic typesetting, remaining essentially unchanged</p>

                <div className='flex items-center space-x-4'>
                  <span className='font-medium '>Progress</span>
                  <div className='h-2 overflow-hidden rounded-full bg-slate-100 w-60'><div className='w-3/6 h-2 bg-green-500'></div></div>
                  <span className='font-medium '>90%</span>
                </div>
              </div>
              <div className='w-full space-y-4'>

                <div className='flex flex-col gap-4 '>
                  <input type='text' className='w-16 px-4 py-2 font-medium text-black border rounded-md focus:outline-none bg-gray-50 ' value='10' />
                  <label className="flex items-center space-x-2"><span className='font-medium text-black'>Done</span></label>
                </div>

              </div>


            </div>
            <div className='flex items-center gap-4 p-6 py-2 bg-white rounded-md' >
                <div className='text-[#3D405B]    w-full '>
                  <h2 className='text-lg font-semibold'> </h2>
                </div>

                <div className='flex flex-row w-full gap-6'>
                  <div className="text-[#3D405B]  flex items-center space-x-4 w-full "><p className="text-base">Total Avarage </p>
                  <h2 className="p-4 py-2 text-base font-semibold border rounded-md bg-gray-50">8</h2></div>

                  <div className='text-[#3D405B]   flex items-center space-x-4 w-full '>
                  
                  </div>

                </div>
              </div>

            <div className='flex items-center justify-end px-6 py-6 space-x-4 bg-white '>

              <button className='w-32 px-4 py-3 uppercase border rounded-md'>cancel</button>
              <button className='border rounded-md w-32 py-3 px-4 uppercase bg-[#3ab1b7] text-white '>Submit</button>
            </div>

          </main>
        </div>



      </div>



    </>
  );
}
