import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";


export default function GaugeScoreEng(props) {
    
    let {  riskScore } = props;

    function spliceDecimal(no){
      if(no){
        let str = no.toString();
        let first = str.split('.')[0];
        let second = str.split('.')[1];
        let second2 = second?second.substring(0, 2):""
        let final = first+'.'+second2;
        if(second2){
          return parseFloat(final).toFixed(2)
        }else{
          return no
        }
      }else{
        return 0
      }
    }

    let score = riskScore?spliceDecimal(riskScore):"NaN"
    

    return (
      <>	
      <div className="pt-1 flex flex-col justify-center items-center "  >
      {score !== "NaN" && score !== "0"?
      <>
     <div className='h-60'>
     <ReactSpeedometer className="meter "
          value={score}
          minValue={0}
          maxValue={6}
          needleColor="#4db8ff"
          segments={3}
          segmentColors={[
          "#d12e2e",
          "#f4ae34",
          "#a2e37d",
          "#00cc66"
          ]}
          ringWidth={35}
          customSegmentStops={[0, 3.5, 4.5, 5.5, 6]}
          // fluidWidth={true}
          needleTransitionDuration={3333}
          needleTransition="easeElastic"
        />
     </div>

        <div className="flex items-center justify-center">
          <div className=" grid xl:grid-cols-2 grid-cols-2 gap-6 text-xs text-gray-500  ">
            <div className="flex items-center "><div style={{background:"#d12e2e"}} className="p-1 rounded-sm" /><p className="ml-2">{"Actively Disengaged (0-3.5)"}</p></div>
            <div className="flex items-center "><div style={{background:"#f4ae34"}} className="p-1 rounded-sm" /><p className="ml-2">{"Passive (3.5-4.5)"}</p></div>
            <div className="flex items-center "><div style={{background:"#a2e37d"}} className="p-1 rounded-sm" /><p className="ml-2">{"Moderately Engaged (4.5-5.5)"}</p></div>
            <div className="flex items-center "><div style={{background:"#00cc66"}} className="p-1 rounded-sm" /><p className="ml-2">{"Highly Engaged (5.5-6.0)"}</p></div>
          </div>
        </div>
      </>
      :<>
      <div className='text-gray-500 text-center mt-4'>Please select employee</div>
      </>}
         
      
      </div>

      </>
    );
  }
