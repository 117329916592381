import React, { Component } from 'react';
import { connect } from 'react-redux';
import BarRWA from '../../Charts/BarRWA';
import Commentary from '../../../../../Index/Components/Commentary/Commentary';
import Filter from "../../../../../../CommonComponents/Filters/IndexFilter/Filter";
import QuestionFilter from './QuestionFilter';
import DataNotFound from '../../../../../Components/DataNotFound';
class DimensionIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilterName: 'none',
      currentFilterValue: 'none',
      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',

      showViewBy: '',
      FilterList: [],
      currentQuesId: ''
    }
  }
  componentDidMount() {

  }
  handleChangeQuesIdSave = (Id) => {
    this.setState({ currentQuesId: Id })
  }

  callQuestionWordCloudQuesChange = (initQuesId) => {
    let { handleRwaNpsInsight } = this.props;
    let { FilterList } = this.state;
    handleRwaNpsInsight(initQuesId, FilterList);

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }
  closeFilterQues = (check1) => {
    this.setState({ showViewBy: check1 });
  }
  handleRWAFilterNameSave = (val) => {
    this.setState({ currentFilterName: val })
    if (val === 'none') {
      this.setState({ currentFilterValue: 'none' })
    }
  }

  handleRWAFilterValueSave = (val) => {
    this.setState({ currentFilterValue: val })

  }




  handleSelectMultiFilter = (name) => {
    let { FilterValues } = this.state;
    let Check = FilterValues && FilterValues.length > 0 ? FilterValues[(FilterValues.length - 1)].value === "All" ? false : true : true;

    if (Check) {
      let getIndex = FilterValues.findIndex(prev => prev.level === name);
      if (getIndex === -1) {
        FilterValues.push({
          level: name,
          value: "All"
        });
      }
      else {
        FilterValues.splice(getIndex, 1);
      }
      this.setState({ FilterValues }, () => {
        // let { handleMultiFilterGetQuestion } = this.props;
        // handleMultiFilterGetQuestion(FilterValues);
      });
    }

  }


  handleSelectMultiFilterClose = (name) => {
    let { FilterValues } = this.state;
    let getIndex = FilterValues.findIndex(prev => prev.level === name);
    if (getIndex !== -1) {
      FilterValues.splice(getIndex, 1);
    }

    //console.log('FilterValues: ',FilterValues);
    this.setState({ FilterValues }, () => {
      let { handleFilterRWA, indexType } = this.props;
      handleFilterRWA(FilterValues, indexType);
    });

  }






  handleSelectedListDropdown = (name) => {
    if (this.state.currentSelectValue === name) {
      this.setState({ currentSelectValue: "" });
    }
    else {
      this.setState({ currentSelectValue: name });
    }
  }

  handleSubmitFilter = (name, value) => {
    let { FilterValues } = this.state;
    let getIndex = FilterValues.findIndex(prev => prev.level === name);
    if (getIndex === -1) {
      FilterValues.push({
        level: name,
        value: value
      });
    }
    else {
      FilterValues[getIndex].value = value;
      // FilterValues.splice(getIndex,1)
    }

    this.setState({ FilterValues, currentSelectValue: '' }, () => {
      let { handleFilterRWA, rwaIndexType } = this.props;
      handleFilterRWA(FilterValues, rwaIndexType);
    });

  }

  handleFilterClear = () => {
    this.setState({ FilterValues: [] }, () => {
      let { handleRwaNpsInsight, rwaIndexType } = this.props;
      handleRwaNpsInsight([], rwaIndexType);
    });
  }




  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleRwaNpsInsight, getNameQuestionData } = this.props;
    this.setState({ FilterValues: finalFilter });


    let { currentQuesId } = this.state;
    let initQuesId = '';
    if (currentQuesId) {
      initQuesId = currentQuesId;
    }
    else {
      initQuesId = getNameQuestionData && getNameQuestionData.length > 0 ? getNameQuestionData[0].id : '';
    }


    handleRwaNpsInsight(initQuesId, finalFilter);

  }


  render() {
    let { isHappinessLadder, getNameQuestionData, ErrorIndexObj, getRWAError, getEMPFilterLabel, getIndexByFilterDMGData, GetParameterLabel, loading, indexType, optionType, getCommentData, Name, EmpFilterData, getIndexFilterData, handleRWAFilter, driverVsNpsRWAData, rwaIndexType } = this.props;



    console.log('driverVsNpsRWAData: ', driverVsNpsRWAData)

    let { CountRestrict } = this.props;
    let count = driverVsNpsRWAData && driverVsNpsRWAData.count ? driverVsNpsRWAData.count : 0;
    let isShowCount = CountRestrict(count, false, "rwa") ? false : true;

    let { currentSelectValue, FilterValues } = this.state;

    let DriverName = []
    let DriverValues = []
    let RawValues = []
    let ThirdValues = []

    let showData = false;
    if (driverVsNpsRWAData && driverVsNpsRWAData.rwa) {
      if (driverVsNpsRWAData && driverVsNpsRWAData.rwa && driverVsNpsRWAData.rwa.driver && driverVsNpsRWAData.rwa.driver.length > 0) {
        driverVsNpsRWAData.rwa.driver.forEach((item) => {
          DriverName.push(GetParameterLabel(item));
        });
      }
      DriverValues = driverVsNpsRWAData.rwa.normRelaImpt;
      // RawValues= driverVsNpsRWAData.rwa.rawRelaImpt
      ThirdValues = driverVsNpsRWAData.rwa.rawRelaImpt
      showData = true;
    }




    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    //  let DemographicDataScore = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      //  DemographicDataScore=getDemographicIndexMultiFilterData;
      //  DemographicDataDistribution=getIndexDistributionData;

    }


    return (
      <>

        <div onClick={() => this.closeFilter(false)} >

          <div className='flex flex-wrap items-start justify-between w-full gap-4' >

            <QuestionFilter
              showViewBy={this.state.showViewBy}
              closeFilterQues={this.closeFilterQues}

              callQuestionWordCloud={this.callQuestionWordCloudQuesChange}
              getNameQuestionData={getNameQuestionData}
              handleChangeQuesIdSave={this.handleChangeQuesIdSave}
            />

          </div>
        </div>




        <div className="items-center justify-between  md:flex">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />

          {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
          <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        </div>






        {/* <div className="items-center justify-start xl:flex lg:flex md:flex"> */}

        {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} /> */}

        {/* <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}



        {/* </div> */}


        {/* {FilterValues && FilterValues.length > 0 ?
          <div className="items-center h-10 px-4 py-3 bg-white border-b  xl:flex lg:flex md:flex justify-left">
            {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
              <p className="flex mx-2 my-4 text-sm"><p className="mr-1 text-blue-500">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}</div>
          : null} */}


        <div onClick={() => this.closeFilter(false)} className="" >
          {/* <div className="flex w-full" >
                
              
                <div className="justify-center w-full p-4 m-4 mr-2 bg-white shadow-lg" >
                <div className="text-lg font-medium">{Name} RWA</div> */}

          <div className="w-full" >

            <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">

              <div className="p-6 space-y-6 transition duration-150 bg-white border rounded-lg cursor-pointer hover:shadow-lg">

                <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">Driver Vs {isHappinessLadder ? "Happiness Ladder" : "NPS"}</h1>



                {showData ?
                  <>

                    {ErrorIndexObj && ErrorIndexObj["getEngagementRWAError"] ?
                      <>
                        <div className='my-24 text-2xl font-medium text-center text-gray-400'>{ErrorIndexObj["getEngagementRWAError"]}</div>
                      </>
                      :
                      <div className="h-screen py-4">
                        {isShowCount ?
                          <BarRWA DriverName={DriverName} RawValues={RawValues} DriverValues={DriverValues} ThirdValues={ThirdValues} />
                          :
                          null
                        }
                        <div className="py-4 text-" style={{ color: "#3366ff" }}>* We are not showing data where employee count is less than 30</div>
                      </div>
                    }


                  </>
                  :
                  <>
                    {!loading ?

                      <DataNotFound />

                      : null}
                  </>
                }



              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DimensionIndex);
