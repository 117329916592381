import React, { Component } from "react";
import Header from "../Components/Header";

export default class Behaviors extends Component {
  render() {
    let {
      getQuesOverallData,
      setPDF,
      SummaryName,
      getQuestionData,
      getQuestion2Data,
      GetParameterLabel,
      CountRestrict,
      surveyLogo,
      indexTemplate,
      showYearData,
      yearValue1,
      yearValue2
    } = this.props;



    function getListData(Qdata) {

      let paraList = [];
      let count = 0;
      let rowSpan = 0;
      let NewParaList = [];

      if (Qdata && Qdata.length > 0) {
        Qdata.forEach((ques) => {
          if (
            paraList.findIndex((prev) => prev === ques.parameterDimensionName) ===
            -1
          ) {
            paraList.push(ques.parameterDimensionName);
            count += 1;
          } else {
          }
        });

        let obj = {};
        paraList.forEach((para) => {
          obj[para] = 0;
          Qdata.forEach((ques) => {
            if (para === ques.parameterDimensionName) {
              obj[para] += 1;
            }
          });
        });

        NewParaList = Object.keys(obj).map((key) => [key, obj[key]]);

        rowSpan = Qdata.length / count;
      }

      let NewQList = [];
      if (Qdata && Qdata.length > 0) {
        Qdata.forEach((ques) => {
          let total =
            ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];


          let score = parseFloat(
            (((ques["5"] + ques["6"]) * 100) / total).toFixed(2)
          );

          NewQList.push({
            ...ques,
            score: CountRestrict(ques.count) ? "NA" : score,
          });
        });
      }

      NewQList.sort((a, b) => b.score - a.score);

      return NewQList
    }



    let NewQuesList = getListData(getQuestionData);
    let NewQues2List = getListData(getQuestion2Data);





    console.log(SummaryName, " NewQuesList------>", NewQuesList)
    console.log(SummaryName, " NewQues2List------>", NewQues2List)


    console.log(SummaryName, " getQuestion2Data------>", getQuestion2Data)

    let ListNewQuestions = [];

    if (NewQuesList && NewQuesList.length > 0) {
      if (NewQuesList && NewQuesList.length > 0) {
        for (var i = 0; i < NewQuesList.length; i++) {
          let question1 = NewQuesList[i];
          let questionTemp = {
            ...question1
          }
          questionTemp["score"] = question1["score"]
          questionTemp["score2"] = 0
          ListNewQuestions.push(questionTemp)
        }
      }
    }
    if (NewQues2List && NewQues2List.length > 0) {
      if (NewQues2List && NewQues2List.length > 0) {
        for (var i2 = 0; i2 < NewQues2List.length; i2++) {
          let question2 = NewQues2List[i2];
          let getQMatch = ListNewQuestions.findIndex(prev => prev.name === question2.name)
          if (getQMatch !== -1) {
            ListNewQuestions[getQMatch]["score2"] = question2["score"];
          } else {
            ListNewQuestions.push({
              ...question2,
              "score2": question2["score"]
            })
          }

        }
      }
    }

    console.log(SummaryName, " ListNewQuestions------>", ListNewQuestions)






    function calcFavourability(quesZ) {
      let total =
        quesZ["1"] +
        quesZ["2"] +
        quesZ["3"] +
        quesZ["4"] +
        quesZ["5"] +
        quesZ["6"];
      let scoreX = parseFloat(
        (((quesZ["5"] + quesZ["6"]) * 100) / total).toFixed(2)
      );
      return scoreX;
    }

    function calcOverall(queItem) {
      let overallX = 0;
      if (getQuesOverallData && getQuesOverallData.length > 0) {
        let getIndex = getQuesOverallData.findIndex(
          (prev) => prev.name === queItem.name
        );
        let overallQue = getQuesOverallData[getIndex];
        overallX = calcFavourability(overallQue);
      }
      return overallX;
    }

    return (
      <div>

        {/* Engagement Dimensions Item333333333333333333 report design */}
        <div className="pt-4">
          <Header surveyLogo={surveyLogo} />

          <div className>
            <div className="w-full px-8 py-6 capitalize ">
              <h1 className="text-2xl text-[#3D405B] font-semibold border-b border-[#DDDDDD] pb-4 capitalize">
                {SummaryName + " Dimensions Item"}
              </h1>
            </div>
          </div>
        </div>

        <main className="w-full px-8">
          {setPDF ? (
            <>
              <table
                className="w-full border border-[#FFFFFF]"
                id={"Behaviors" + indexTemplate.toString()}
              >
                <tbody>
                  <tr className="text-left text-[#3D405B] capitalize  border-b border-[#FFFFFF] bg-[#F1F5F9] text-sm border-l border-t">
                    <th className="w-[10%] font-medium p-2  text-left border-r border-[#FFFFFF]">
                      Dimension
                    </th>
                    <th className="w-[20%] font-medium p-2  text-left border-r border-[#FFFFFF]">
                      Question
                    </th>
                    <th className="w-[10%] font-medium p-2 text-left border-r border-[#FFFFFF]">
                      favorability score
                    </th>
                    <th className="w-[5%] font-medium p-2 text-center ">
                      overall
                    </th>
                  </tr>

                  {ListNewQuestions && ListNewQuestions.length > 0
                    ? ListNewQuestions.map((question, index) => (
                      <>
                        {index % 12 === 0 && index !== 0 ? (
                          <>
                            <tr className="page-break bg-[#F1F5F9] text-[#3D405B] capitalize border-b text-sm">
                              <th className="w-[20%] font-medium p-2  text-left border-r border-[#FFFFFF]">
                                Dimention
                              </th>
                              <th className="w-[40%] font-medium p-2  text-left border-r border-[#FFFFFF]">
                                Question
                              </th>
                              <th className="w-[25%] font-medium p-2 text-center border-r border-[#FFFFFF]">
                                favorability score
                              </th>
                              <th className="w-[5%] font-medium p-2 text-center ">
                                overall
                              </th>
                            </tr>
                          </>
                        ) : null}

                        <tr className="border-b border-[#FFFFFF] text-[#3D405B] bg-[#F1F5F9] text-sm border-l">
                          <td className="p-2 font-medium capitalize border-r border-[#FFFFFF]">
                            {question && question.parameterDimensionName
                              ? GetParameterLabel(
                                question.parameterDimensionName
                              )
                              : ""}
                          </td>
                          <td className="p-2 text-sm border-r border-[#FFFFFF] ">
                            {question.name}
                          </td>

                          <td className="p-2 text-sm border-r border-[#FFFFFF] ">
                            <div className="flex items-center ">
                              <div className="w-full space-y-0">

                                {showYearData(yearValue1, indexTemplate) ?
                                  <div className="flex flex-row py-0.5 items-center gap-1.5">
                                    <p className="flex justify-end overflow-hidden text-right w-14">2021</p>

                                    <div className="h-3 w-full bg-[#CFD8DC]">
                                      <div
                                        className="h-3 w-full bg-[#2196F3]"
                                        style={{
                                          background: "#2196F3",
                                          width:
                                            (question &&
                                              question.score &&
                                              question.score !== "NA"
                                              ? question.score
                                              : 0) + "%",
                                        }}
                                      />
                                    </div>
                                    <div className=" text-sm  font-medium text-left w-[90px] flex   overflow-hidden ">
                                      {question && question.score
                                        ? question.score
                                        : 0}
                                      %
                                    </div>

                                  </div>
                                  : null}


                                {showYearData(yearValue2, indexTemplate) ?
                                  <div className="flex flex-row py-0.5 items-center gap-1.5">
                                    <p className="flex justify-end overflow-hidden text-right w-14">2022</p>

                                    <div className="h-3 w-full bg-[#CFD8DC]">
                                      <div
                                        className="h-3 w-full bg-[#9576CD]"
                                        style={{
                                          background: "#9576CD",
                                          width:
                                            (question &&
                                              question.score2 &&
                                              question.score2 !== "NA"
                                              ? question.score2
                                              : 0) + "%",
                                        }}
                                      />
                                    </div>
                                    <div className=" text-sm  font-medium text-left w-[90px] flex   overflow-hidden ">
                                      {question && question.score2
                                        ? question.score2
                                        : 0}
                                      %
                                    </div>

                                  </div>
                                  : null}

                              </div>
                            </div>
                          </td>

                          <td className="p-2 text-sm text-center ">
                            <div className="h-6 px-2 text-sm font-medium text-center text-gray-500">
                              {calcOverall(question)}%{" "}
                            </div>
                          </td>
                        </tr>
                      </>
                    ))
                    : null}
                </tbody>
              </table>
            </>
          ) : (
            <>
              {/* PPT Table */}
              <table id={"Behaviors" + indexTemplate.toString()}>
                <thead>
                  <tr>
                    <th
                      data-pptx-min-width="2.6"
                      style={{
                        textAlign: "left",
                        width: "20%",
                        fontSize: 10,
                        background: "#e6e6e6",
                      }}
                    >
                      Dimension
                    </th>

                    <th
                      data-pptx-min-width="2.6"
                      style={{
                        textAlign: "left",
                        width: "50%",
                        fontSize: 10,
                        background: "#e6e6e6",
                      }}
                    >
                      Question
                    </th>
                    <th
                      data-pptx-min-width="0.8"
                      style={{
                        width: "10%",
                        fontSize: 10,
                        background: "#e6e6e6",
                      }}
                    >
                      Favorability Score
                    </th>
                    <th
                      data-pptx-min-width="0.8"
                      style={{
                        width: "10%",
                        fontSize: 10,
                        background: "#e6e6e6",
                      }}
                    >
                      Overall
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {NewQuesList && NewQuesList.length > 0
                    ? NewQuesList.map((question, index) => (
                      <tr>
                        <td
                          data-pptx-min-width="2.6"
                          style={{
                            textAlign: "left",
                            width: "20%",
                            fontSize: 10,
                            background:
                              index % 2 === 0 ? "#dbefff" : "#ffffff",
                          }}
                        >
                          {question && question.parameterDimensionName
                            ? GetParameterLabel(
                              question.parameterDimensionName
                            )
                            : ""}
                        </td>
                        <td
                          data-pptx-min-width="2.6"
                          style={{
                            textAlign: "left",
                            width: "50%",
                            fontSize: 10,
                            background:
                              index % 2 === 0 ? "#dbefff" : "#ffffff",
                          }}
                        >
                          {" "}
                          {question.name}
                        </td>
                        <td
                          data-pptx-min-width="2.6"
                          style={{
                            textAlign: "center",
                            width: "10%",
                            fontSize: 10,
                            background:
                              index % 2 === 0 ? "#dbefff" : "#ffffff",
                          }}
                        >
                          {(question && question.score ? question.score : 0) +
                            "%"}
                        </td>

                        <td
                          data-pptx-min-width="0.8"
                          style={{
                            textAlign: "center",
                            width: "10%",
                            fontSize: 10,
                            background:
                              index % 2 === 0 ? "#dbefff" : "#ffffff",
                          }}
                        >
                          {" "}
                          {calcOverall(question)}%{" "}
                        </td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </table>
            </>
          )}
        </main>
      </div>
    );
  }
}
