import React, { Component } from "react";
import { ReactReduxContext, connect } from "react-redux";
import ShareHeader from "../Header/ShareHeader";

function ShareSurvey() {
  return (
    <div className=" bg-[#f9fafb] w-full h-screen text-[#3D405B]  ">
      <ShareHeader />

      <main className="h-[calc(100vh-72px)] flex flex-col md:items-center items-center  md:justify-center justify-start lg:w-8/12 w-full mx-auto space-y-10 p-6 overflow-y-auto">
        <h1 className="md:text-3xl text-2xl text-center font-medium text-[#3D405B]">
          How would you like to launch survey?
        </h1>

        <div className="flex items-center border bg-white md:w-auto w-11/12">
          <p className="bg-white px-4 lg:w-96 md:w-full    text-ellipsis  line-clamp-1  ">
            https://harikunwar1010.surveysparrow.com/s/Online-Donation-Form 
          </p>
          <button className="flex items-center space-x-2 px-4 py-3 bg-[#18afb8] text-white whitespace-nowrap ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-copy"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path>
              <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
            </svg>
            <span className="md:flex hidden">Copy uRL</span>
          </button>
        </div>
        <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 md:gap-6 gap-4 w-full">
          <div className="border p-6 bg-white rounded-lg space-y-4 flex flex-col items-center justify-center cursor-pointer ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-mail-share text-[#18afb8] md:w-[80px] w-[60px] md:h-[80px] h-[60px]"
              width="80"
              height="80"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M13 19h-8a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v6"></path>
              <path d="M3 7l9 6l9 -6"></path>
              <path d="M16 22l5 -5"></path>
              <path d="M21 21.5v-4.5h-4.5"></path>
            </svg>
            <p className="md:text-xl text-lg font-medium">Send Email</p>
          </div>
          <div className="border p-6 bg-white rounded-lg space-y-4 flex flex-col items-center justify-center cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-mail-share text-[#18afb8] md:w-[80px] w-[60px] md:h-[80px] h-[60px]"
              width="80"
              height="80"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M9 15l6 -6"></path>
              <path d="M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464"></path>
              <path d="M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463"></path>
            </svg>
            <p className="md:text-xl text-lg font-medium">Create URL</p>
          </div>
          <div className="border p-6 bg-white rounded-lg space-y-4 flex flex-col items-center justify-center cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-mail-share text-[#25D366] md:w-[80px] w-[60px] md:h-[80px] h-[60px]"
              width="80"
              height="80"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9"></path>
              <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1"></path>
            </svg>
            <p className="md:text-xl text-lg font-medium">WhatsApp</p>
          </div>
          <div className="border p-6 bg-white rounded-lg space-y-4 flex flex-col items-center justify-center cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-mail-share text-[#18afb8] md:w-[80px] w-[60px] md:h-[80px] h-[60px]"
              width="80"
              height="80"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
              <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
              <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
              <path d="M8.7 10.7l6.6 -3.4"></path>
              <path d="M8.7 13.3l6.6 3.4"></path>
            </svg>
            <p className="md:text-xl text-lg font-medium">Social</p>
          </div>
          <div className="border p-6 bg-white rounded-lg space-y-4 flex flex-col items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-mail-share text-[#18afb8] md:w-[80px] w-[60px] md:h-[80px] h-[60px]"
              width="80"
              height="80"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M8 9h8"></path>
              <path d="M8 13h6"></path>
              <path d="M9 18h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-3l-3 3l-3 -3z"></path>
            </svg>
            <p className="md:text-xl text-lg font-medium">SMS</p>
          </div>
        </div>
      </main>
    </div>
  );
}
function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard,
  };
}
export default connect(mapStateToProps)(ShareSurvey);
