import React from "react";

export default function Item(props) {
let { getSurveyData  } = props;

  return (
    <>
      <section className=" space-y-2 gap-5 px-10 pt-12">

    <main className="flex h-screen">
  {/* text area */}
  <div className="bg-white w-full text-white py-10 pt-1">
    <div className="space-y-10">
    <h1 className="text-xl text-white font-bold">
      {getSurveyData && getSurveyData.logo?
        <img src={getSurveyData && getSurveyData.logo?getSurveyData.logo:""}  style={{height:'90px'}}/>
        :null}
      </h1>
      <div className="my-auto">


                <div className="flex items-center text-3xl font-bold bg-indigo-500  mr-28 relative h-16  "> <h1 className="w-5/6 mx-auto px-4">Table of contents</h1>
                <span className="absolute -right-6 top-0 h-16 w-16 bg-blue-400  transform rotate-45 hidden " /></div>



                <div className="w-5/6 mx-auto text-indigo-700">
                  <div className="flex  ">
                    {/* <span className="material-symbols-outlined text-blue-400 -pt-3" style={{fontSize: '4rem', margin: 0}}>arrow_right</span>  */}
                    <h1 className="text-3xl font-bold mr-4 mt-4 ">01</h1> <h1 className="text-2xl mt-4 font-semibold">Overview of Methodology</h1>
                  </div>
                  <div className="flex    ">
                    {/* <span className="material-symbols-outlined text-blue-400 -pt-3" style={{fontSize: '4rem', margin: 0, padding: '0px'}}>arrow_right</span>  */}
                    <h1 className="text-3xl font-bold mr-4 mt-4">02</h1> <h1 className="text-2xl mt-4 font-semibold">Manager Scorecard</h1>
                  </div>
                  <div className="flex ">
                    {/* <span className="material-symbols-outlined text-blue-400 -pt-3" style={{fontSize: '4rem', margin: 0, padding: '0px'}}>arrow_right</span>  */}
                  <h1 className="text-3xl font-bold mr-4 mt-4">03</h1> <h1 className="text-2xl mt-4 font-semibold">Action Plan</h1>
                  </div>
                </div>
      </div>
    </div>
  </div>
  {/* img area */}
  <div className="w-full h-full bg-no-repeat bg-cover " 
  // style={{backgroundImage: 'url("./img/managerreport/img1.jpg")'}}
  >
    <div className="flex justify-end pt-6 ">
      {/* <img src="img/managerreport/logo.png " className="w-60" /> */}
      <img src={"img/logo/happypluslogo.png"} className="px-4 bg-white p-2 mr-2"  style={{height:'60px'}}/>


</div>
  </div>
</main>
</section>
    </>
  );
}







