import React, { Component } from "react";
import { connect } from "react-redux";
import Help from "./Help/Help";

class Commentary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  componentDidMount() {}

  openHelpModal = () => {
    this.setState({ showModal: true });
  };

  render() {
    let { getCommentData, indexType, optionType } = this.props;
    let { showModal } = this.state;

    function getIndexName(key) {
      let name = "";
      if (key === 1) {
        name = "Happiness";
      } else if (key === 2) {
        name = "Engagement";
      } else if (key === 3) {
        name = "Hope";
      } else if (key === 4) {
        name = "Stress";
      } else if (key === 5) {
        name = "Manager";
      } else if (key === 6) {
        name = "Leadership";
      } else if (key === 7) {
        name = "Safety";
      } else if (key === 9) {
        name = "Diversity";
      } else {
        name = "NaN";
      }

      return name;
    }

    function getOptionName(key, IndexName) {
      let name = "";
      if (key === 1) {
        name = IndexName + " Summary";
      } else if (key === 2) {
        name = IndexName + " Behaviour";
      } else if (key === 3) {
        name = "Dimension Priorities";
      } else if (key === 4) {
        name = "Response Distribution";
      } else if (key === 5) {
        name = "Driver Comparision";
      } else {
        name = "NaN";
      }

      return name;
    }

    let IndexName = getIndexName(indexType);
    let OptionName = getOptionName(indexType, IndexName);

    let name = "";
    let desc = "";

    let flag = 0;
    if (getCommentData && getCommentData.length > 0) {
      getCommentData.forEach((item) => {
        // ////console.log('item',item)

        if (item.indexType == indexType && item.optionType === optionType) {
          name = item.name;
          desc = item.desc;
          flag = 1;

          // ////console.log('item',item)
        }
      });
    }
    ////console.log('indexType, optionType',indexType, optionType)

    return (
      <>
        {flag ? (
          <button
            onClick={() => this.openHelpModal()}
            className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"
          >
            <svg
              className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B]"
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-help"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="9" />
              <line x1="12" y1="17" x2="12" y2="17.01" />
              <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
            </svg>
            <span> Help</span>
          </button>
        ) : null}
        {showModal ? (
          <Help
            IndexName={IndexName}
            OptionName={OptionName}
            name={name}
            desc={desc}
            closeModal={() => this.setState({ showModal: false })}
          />
        ) : null}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(Commentary);
