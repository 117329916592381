import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class TimeSeriesChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  openChartList=()=>{
    if(this.state.showChartList){
      this.setState({showChartList:false})
    }
    else{
      this.setState({showChartList:true})
    }
  }

  handleChartType=(item)=>{
    this.setState({chartTypeName:item})
  }

  render() {
    let { getIndexByFilterAllStagesData, indexType, name,
    
      getCategoryOverallIndexData, allIndexList, indexScoreColor,

      LifecycleEngamentIndexV2Data

    } = this.props;



    let IndexName = ""
    if(allIndexList && allIndexList.length>0){
      let getIndex = allIndexList.findIndex(prev=>prev.template === indexType);
      if(getIndex !== -1){
        IndexName = allIndexList[getIndex]["name"]
      }
    }


    let valueList = [];
    let labelList = [];
    
    if(getCategoryOverallIndexData && getCategoryOverallIndexData.length>0){
      getCategoryOverallIndexData.forEach((item)=>{

        if((item && item.scoreData && item.scoreData[indexType])){

          let label = item["surveyData"]["name"];

          labelList.push(label)
  
          let indexValue = item && item["scoreData"] && indexType && item["scoreData"][indexType] && item["scoreData"][indexType].toFixed(2)?item["scoreData"][indexType].toFixed(2):"0"
          valueList.push((indexValue))


        }




      })
    }



    let categoryList = [
      {
        "label":"Highly Engaged",
        "name":"HighlyEngaged"
      },
      {
        "label":"Moderately Engaged",
        "name":"ModeratelyEngaged"
      },
      {
        "label":"Actively Disengaged",
        "name":"ActivelyDisengaged"
      },
      {
        "label":"Passive",
        "name":"Passive"
      }
    ]
    
    function spliceDecimal(no){
      if(no){
        let str = no.toString();
        let first = str.split('.')[0];
        let second = str.split('.')[1];
        let second2 = second?second.substring(0, 2):""
        let final = first+'.'+second2;
        if(second2){
          return parseFloat(final).toFixed(2)
        }else{
          return no
        }
      }else{
        return 0
      }
    }
    
    let seriesList = []
    if(LifecycleEngamentIndexV2Data && LifecycleEngamentIndexV2Data.length>0){
      LifecycleEngamentIndexV2Data.forEach((survey)=>{

        let values = []

        if(categoryList && categoryList.length>0){
          categoryList.forEach((item)=>{
              let score = survey && survey["data"] && survey["data"][item.name]?survey["data"][item.name]:0
              values.push(Number(spliceDecimal(score)))
          })
        }

        console.log("values--->",values)
        console.log("survey--->",survey)


        if(values && values.length>0){
          const sum = values.reduce((partialSum, a) => partialSum + a, 0);
          if(sum>0){
            seriesList.push({
              name: survey.surveyName,
              type: 'line',
              data: values
            })
          }
    
        }


      })
    }

    let categoryLabelList = categoryList.map((item)=>(item.label))

    console.log("categoryLabelList--->",categoryLabelList)
    console.log("seriesList--->",seriesList)
    return (
      <>

      {seriesList && seriesList.length>0?
      <>
      <div className="px-4 stack-body">
          <ReactEcharts
                  option={{
                    title: {
                      text:""
                    },
                    tooltip: {
                      trigger: 'axis'
                      // formatter:function (a){
                      //   ////console.log("a--------->",a)
                      //   return "Name"
                      // }
                    },
                    legend:{},
                    grid: {
                      left: '3%',
                      right: '4%',
                      bottom: '3%',
                      containLabel: true
                    },
                    // toolbox: {
                    //   feature: {
                    //     saveAsImage: {}
                    //   }
                    // },
                    xAxis: {
                      type: 'category',
                      boundaryGap: false,
                      data: categoryLabelList,
                      // color:indexScoreColor
                    },
                    yAxis: {
                      type: 'value'
                    },
                    series: seriesList
                  }}
                />

          </div>
     
      </>
      :null}
          

      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(TimeSeriesChart);
