import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let { ErrorIndexObj, CountRestrict, currFilterValue, currFilterName, getDistributionIndexData,getIndexDriverData ,GetParameterLabel}= this.props;

    // //console.log('getIndexDriverData: ',getIndexDriverData)
    // //console.log('getDistributionIndexData: ',getDistributionIndexData)

    let YAxis= []
    let XAxis= ['EmployeeExperience']
    let XAxisName= ['EmployeeExperienceAvg']
    let DataValues = []
    let list = []
    let overallData = {}

    //console.log('getDistributionIndexData: ',getDistributionIndexData);


    if(getIndexDriverData){
      overallData = {
        EmployeeExperienceAvg:  getIndexDriverData.EmployeeExperienceCount,
        name: "Overall"
      }

      if(list.findIndex(prev=>prev.name === "Overall")===-1){
        list.push(overallData)
      }
    }


    if(getDistributionIndexData   && getDistributionIndexData.length>0 && getIndexDriverData){
      getDistributionIndexData.forEach((hello)=>{
        list.push(hello);
      })


      



          list.forEach((item,index)=>{
            XAxisName.forEach((axis,axisIndex)=>{
              //console.log('data-->  : ',axis,axisIndex,index,parseFloat(item[axis]).toFixed(2))
              
              if(index!==(list.length-1)){
                DataValues.push({
                  value:[axisIndex,index,parseFloat(item[axis]).toFixed(2)],
                  itemStyle:{color:
                    (parseFloat(overallData[axis]) > parseFloat(item[axis]))?
                      (parseFloat(item[axis])===0)?'#ffb3b3':'rgba(230, 0, 0,'+parseFloat(item[axis])/100+')'
                      :
                      (parseFloat(item[axis])===0)?'#c2f0c2':'rgba(46, 184, 46,'+parseFloat(item[axis])/100+')'
                  }
                })
              }
              else{
                DataValues.push({
                  value:[axisIndex,index,parseFloat(item[axis]).toFixed(2)],
                  itemStyle:{color:(parseFloat(item[axis])===0)?'#b3ecff':'rgba(0, 172, 230,'+parseFloat(item[axis])/100+')'}
                })  
              }
    
            })
          })



    }


      if(list && list.length>0){
        list.forEach((driver)=>{
          YAxis.push(driver.name)
        })
      }


      // list.reverse()

      function showNaN (item,name){
        console.log("item,name",item,name)
        let dataScore = CountRestrict(item && item.count?item.count:"NA", item.name==='Overall')?"NA":( name && item && item[name]? (item[name]).toFixed(2):"NA" );
        return dataScore
      }

      function getColor(data, index) {

        if(data.name==="Overall" || showNaN(data, index)==="NA"){
          return {Color:'#f1f1f1',diffTextColor:'#000'}

        }
        else{

          let diffNo = parseInt(Math.abs(parseFloat(overallData[index]) - parseFloat(data[index])));
          //////console.log("diffNo:",diffNo)
          if(parseFloat(overallData[index]) <= parseFloat(data[index])){ //greater (blue)
            if(diffNo>=0 && diffNo<=2){
              return {Color:'#33cc33',diffTextColor:'#ffffff'}

            }
            else if(diffNo>=2 && diffNo<=12){
              return {Color:'#70db70',diffTextColor:'#00000'}

            }
            else{
              return {Color:'#adebad',diffTextColor:'#00000'}
            }

          }
          else{ //lesse (red)
            if(diffNo>=0 && diffNo<=2){
              return {Color:'#f5babd',diffTextColor:'#000000'}

            }
            else if(diffNo>=2 && diffNo<=12){
              return {Color:'#ea95a1',diffTextColor:'#000000'}

            }
            else{
              return {Color:'#d03e4d',diffTextColor:'#ffffff'}

            }
          }
        }

      }

      
      // //console.log('XAxis: ',XAxis)
      // //console.log('YAxis: ',YAxis)
      // //console.log('DataValues: ',DataValues)
      console.log('list: ',list)


    let {FilterValues} = this.props;






    return (
      <>
{!(FilterValues && FilterValues.length>0)?

<>    
       {list && list.length>0?
      <div className="py-1  whitespace-nowrap overflow-x-hidden py-10 w-full mx-2">
      <table className="border-collapse w-full ">
        <thead>
          <tr className="text-xs">
            <th className="p-3 font-semibold  text-gray-800">{GetParameterLabel("EmployeeExperience")}</th>
          </tr>
        </thead>
        <tbody>
          {list && list.length>0?
            list.map((item, index)=>
            index===0?
              <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                  {item.name!=='Overall'?
                  <td className="p-3 border border-b text-left" style={{fontWeight:(item.name==='Overall')?'bold':null}}>    
                    {(item.name)?item.name:'Unavailable'}
                  </td>
                  :null}

                <td className="p-3 border border-b text-xl font-bold text-gray-700" style={{background:getColor(item,'EmployeeExperienceAvg')}}>      
                  {/* {(item.EmployeeExperienceAvg)?item.EmployeeExperienceAvg.toFixed(2):"NA"} */}
                  {(item.EmployeeExperienceAvg)?showNaN(item,"EmployeeExperienceAvg") :"NA"}

                </td>

              </tr>
              :null
            ):null}


        </tbody>
      </table>
    </div>
      :null}
      </>
      : 
      <>
          {ErrorIndexObj && ErrorIndexObj["getDemographicIndexError"]?
          <>
           <div className='text-gray-400 font-medium text-center my-24 text-2xl'>{ErrorIndexObj["getDemographicIndexError"]}</div>
          </>
          :
      <>   
       {list && list.length>0?
      <div className="py-1  whitespace-nowrap overflow-x-auto ">
      <table className="border-collapse w-full ">
        <thead>
        {list && list.length>0?
            list.map((item,index)=>
            index===0?
          <tr className="text-xs">
            <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300    ">{currFilterName}</th>
            <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300">{GetParameterLabel("EmployeeExperience")} </th>
          </tr>
          :null
          ):null}
        </thead>
        <tbody>
          {list && list.length>0?
            list.map((item,index)=>
            index!==0 || true?
              <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                <td className="p-3 border border-b text-left" style={{fontWeight:(item.name==='Overall')?'bold':null}}>    
                  {(item.name)?item.name:'Unavailable'}
                </td>
                <td className="p-3 border border-b" style={{background:getColor(item,'EmployeeExperienceAvg').Color,color:getColor(item,'EmployeeExperienceAvg').diffTextColor}}>      
                  {(item.EmployeeExperienceAvg)?showNaN(item,"EmployeeExperienceAvg") :"NA"}
                </td>

              </tr>
              :null
            ):null}


        </tbody>
      </table>
      {/* <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than {CountRestrict("","","",true)}</div> */}

    </div>
      :null}
      </> }

</>
  }
  </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
