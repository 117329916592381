import React, { Component } from 'react'


export default class Header extends Component {
    render() {
        let { surveyLogo } = this.props;

        return (
            <>
                <header className="bg-white   bg-white  h-20 flex items-center">
                    <div className=" capitalize w-full px-8 mx-auto flex justify-between  ">
                    <div className="flex items-center"> <img src={surveyLogo} className="w-36" /></div>
                    <div className="flex items-center"> <img src="img/logo/happypluslogo.png" className="w-32" /></div>
                    </div>
                </header>
            </>
        )
    }
}
