import React, { Component } from "react";
import ScoreCircleChart from "./chart/ScoreCircleChart";

import Header from "../Components/Header";

class ManagerOverall extends Component {
  render() {
    let {
      getQuesOverallData,
      setPDF,

      getIndexData,
      getIndex2Data,
      getOverallIndexData,
      getOverallIndex2Data,

      getQuestionData,
      getQuestion2Data,
      indexTemplate,
      GetParameterLabel,
      CountRestrict,
      surveyLogo,

      showYearData,
      yearValue1,
      yearValue2
    } = this.props;



    let indexValue =
      getIndexData &&
        getIndexData[indexTemplate] &&
        parseFloat(getIndexData[indexTemplate].toFixed(2))
        ? parseFloat(getIndexData[indexTemplate].toFixed(2))
        : "NA";

    let indexOverallValue =
      getOverallIndexData &&
        getOverallIndexData[indexTemplate] &&
        parseFloat(getOverallIndexData[indexTemplate].toFixed(2))
        ? parseFloat(getOverallIndexData[indexTemplate].toFixed(2))
        : 0;




    let indexValue2 =
      getIndex2Data &&
        getIndex2Data[indexTemplate] &&
        parseFloat(getIndex2Data[indexTemplate].toFixed(2))
        ? parseFloat(getIndex2Data[indexTemplate].toFixed(2))
        : "NA";

    let indexOverallValue2 =
      getOverallIndex2Data &&
        getOverallIndex2Data[indexTemplate] &&
        parseFloat(getOverallIndex2Data[indexTemplate].toFixed(2))
        ? parseFloat(getOverallIndex2Data[indexTemplate].toFixed(2))
        : 0;







    let { SummaryName } = this.props;

    function calcFavourability(quesZ) {
      let total =
        quesZ["1"] +
        quesZ["2"] +
        quesZ["3"] +
        quesZ["4"] +
        quesZ["5"] +
        quesZ["6"];
      let scoreX = parseFloat(
        (((quesZ["5"] + quesZ["6"]) * 100) / total).toFixed(2)
      );
      return scoreX;
    }

    function calcOverall(queItem) {
      let overallX = 0;
      if (getQuesOverallData && getQuesOverallData.length > 0) {
        let getIndex = getQuesOverallData.findIndex(
          (prev) => prev.name === queItem.name
        );
        let overallQue = getQuesOverallData[getIndex];
        overallX = calcFavourability(overallQue);
      }
      return overallX;
    }












    function getListData(Qdata) {

      let paraList = [];
      let count = 0;
      let rowSpan = 0;
      let NewParaList = [];

      if (Qdata && Qdata.length > 0) {
        Qdata.forEach((ques) => {
          if (
            paraList.findIndex((prev) => prev === ques.parameterDimensionName) ===
            -1
          ) {
            paraList.push(ques.parameterDimensionName);
            count += 1;
          } else {
          }
        });

        let obj = {};
        paraList.forEach((para) => {
          obj[para] = 0;
          Qdata.forEach((ques) => {
            if (para === ques.parameterDimensionName) {
              obj[para] += 1;
            }
          });
        });

        NewParaList = Object.keys(obj).map((key) => [key, obj[key]]);

        rowSpan = Qdata.length / count;
      }

      let NewQList = [];
      if (Qdata && Qdata.length > 0) {
        Qdata.forEach((ques) => {
          let total =
            ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];


          let score = parseFloat(
            (((ques["5"] + ques["6"]) * 100) / total).toFixed(2)
          );

          NewQList.push({
            ...ques,
            score: CountRestrict(ques.count) ? "NA" : score,
          });
        });
      }

      NewQList.sort((a, b) => b.score - a.score);

      return NewQList
    }



    let NewQuesList = getListData(getQuestionData);
    let NewQues2List = getListData(getQuestion2Data);





    console.log(SummaryName, "111 NewQuesList------>", NewQuesList)
    console.log(SummaryName, "222 NewQues2List------>", NewQues2List)


    console.log(SummaryName, " getQuestion2Data------>", getQuestion2Data)

    let ListNewQuestions = [];

    if (NewQuesList && NewQuesList.length > 0 && NewQues2List && NewQues2List.length > 0) {


      if (NewQuesList && NewQuesList.length > 0) {
        for (var i = 0; i < NewQuesList.length; i++) {
          let question1 = NewQuesList[i];
          let questionTemp = {
            ...question1
          }
          questionTemp["score"] = question1["score"]
          questionTemp["score2"] = 0
          ListNewQuestions.push(questionTemp)
        }
      }
      if (NewQues2List && NewQues2List.length > 0) {
        for (var i2 = 0; i2 < NewQues2List.length; i2++) {
          let question2 = NewQues2List[i2];
          let getQMatch = ListNewQuestions.findIndex(prev => prev.name === question2.name)
          if (getQMatch !== -1) {
            ListNewQuestions[getQMatch]["score2"] = question2["score"];
          } else {
            ListNewQuestions.push({
              ...question2,
              "score2": question2["score"]
            })
          }

        }
      }
    }

    console.log(SummaryName, " ListNewQuestions------>", ListNewQuestions)






    function calcFavourability(quesZ) {
      let total =
        quesZ["1"] +
        quesZ["2"] +
        quesZ["3"] +
        quesZ["4"] +
        quesZ["5"] +
        quesZ["6"];
      let scoreX = parseFloat(
        (((quesZ["5"] + quesZ["6"]) * 100) / total).toFixed(2)
      );
      return scoreX;
    }

    function calcOverall(queItem) {
      let overallX = 0;
      if (getQuesOverallData && getQuesOverallData.length > 0) {
        let getIndex = getQuesOverallData.findIndex(
          (prev) => prev.name === queItem.name
        );
        let overallQue = getQuesOverallData[getIndex];
        overallX = calcFavourability(overallQue);
      }
      return overallX;
    }



    return (
      <>
        <div className="px-8 py-6">
          {/* report header */}
          <div class="flex items-center justify-between border-b py-4 text-[#212121]/80 ">
            <p class="font-medium italic text-base ">Employee Wellness Survey</p>
            <p class="font-medium italic text-base">Overall | 15 March, 2024</p>
          </div>
          <div className="pt-4 -mx-2">
            <Header surveyLogo={surveyLogo} />

            <div className>
              <div className="w-full px-8 py-6 capitalize ">
                <div className="text-indigo-700 opacity-90"></div>
                <h1 className="text-2xl text-[#3D405B] font-semibold border-b border-[#DDDDDD] pb-4 capitalize">
                  {SummaryName + " Summary"}
                </h1>
              </div>
            </div> 

          </div>

          <main className="w-full ">
            <div className="space-y-4 ">
              <div className="grid w-full grid-cols-2 gap-6 pb-4 mx-auto">
                {/* 1 */}


                {showYearData(yearValue1, indexTemplate) ?
                  <div className="p-4 border rounded-md">
                    <h1 className="text-[#3D405B]  text-xl capitalize">
                      {SummaryName + " Score (" + yearValue1 + ")"}
                    </h1>

                    <div className="flex justify-between items-center text-[#3D405B] ">
                      <div className="w-full space-y-6">
                        <div className="flex items-start space-x-2 text-xl">
                          <span className="w-6 h-3 bg-[#673AB7]/70  rounded-full mt-1.5" />

                          <div className="text-sm">
                            <p className="text-base uppercase">Overall Score </p>
                            <div className="flex flex-row items-center gap-1 font-semibold">
                              <img src="/img/icon/Vector.svg" className="w-3" />
                              <span>{indexOverallValue.toString() + "% "} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end w-full">
                        <ScoreCircleChart indexValue={indexValue} />
                      </div>
                    </div>
                  </div>
                  : null}


                {showYearData(yearValue2, indexTemplate) ?
                  <div className="p-4 border rounded-md">
                    <h1 className="text-[#3D405B]  text-xl capitalize">
                      {SummaryName + " Score (" + yearValue2 + ")"}
                    </h1>

                    <div className="flex justify-between items-center text-[#3D405B] ">
                      <div className="w-full space-y-6">
                        <div className="flex items-start space-x-2 text-xl">
                          <span className="w-6 h-3 bg-[#673AB7]/70  rounded-full mt-1.5" />

                          <div className="text-sm">
                            <p className="text-base uppercase">Overall Score</p>
                            <div className="flex flex-row items-center gap-1 font-semibold">
                              {/* <img src="/img/icon/Vector.svg" className="w-3" /> */}
                              <span>{indexOverallValue2.toString() + "% "} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end w-full">
                        <ScoreCircleChart indexValue={indexValue2} />
                      </div>
                    </div>
                  </div>
                  : null}


              </div>

              {setPDF ? (
                <>

                  <div className>
                    <div className="w-full py-2 capitalize ">
                      <div className="text-indigo-700 opacity-90"></div>
                      <h1 className="text-2xl text-[#3D405B] font-semibold  capitalize">
                        {SummaryName + " Dimension Item"}
                      </h1>
                    </div>
                  </div>

                  <table className="w-full border border-white bg-[#F1F5F9]">
                    <tbody>
                      <tr className="text-left text-[#3D405B] capitalize  border-b border-white bg-[#DBEEFD] text-sm border-l border-t">
                        <th className="w-[15%] font-medium p-2 text-left border-r border-white">
                          Dimension
                        </th>
                        <th className="w-[45%] font-medium p-2 text-left border-r border-white">
                          Question
                        </th>
                        <th className="w-[30%] font-medium p-2 text-left border-r border-white">
                          favorability score
                        </th>
                        <th className="w-[5%] font-medium p-2 text-center border-r border-white">
                          overall
                        </th>
                      </tr>

                      {ListNewQuestions && ListNewQuestions.length > 0
                        ? ListNewQuestions.map((question, index) => (
                          <>
                            {index % 8 === 0 && index !== 0 ? (
                              <>
                                <tr className="border-b border-white text-[#3D405B] text-sm border-l">
                                  <th
                                    className="font-normal p-2 text-sm text-left text-[#3D405B]/70 "
                                    colSpan={5}
                                  >
                                    Continue...
                                  </th>
                                </tr>

                                <div className="page-break" />

                                <tr className="border-b border-white text-[#3D405B] text-sm border-l">
                                  <td colSpan={5} className="text-left ">
                                    <div className="pt-4 -mx-8">
                                      <Header surveyLogo={surveyLogo} />
                                      <div className>
                                        <div className="w-full px-8 py-6 capitalize ">
                                          <div className="text-indigo-700 opacity-90">
                                            {/* <p className="flex items-center">Emerging Areas of Strength for </p> */}
                                          </div>
                                          <h1 className="pb-2 text-xl font-semibold text-gray-800">
                                            {SummaryName + " Score"}{" "}
                                          </h1>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr className="text-left border-white text-[#3D405B] capitalize  border-b text-sm border-l border-t">
                                  <th className="w-[15%] font-medium p-2  text-left border-r">
                                    Dimension
                                  </th>
                                  <th className="w-[45%] font-medium p-2  text-left border-r">
                                    Question
                                  </th>
                                  <th className="w-[30%] font-medium p-2  text-left border-r">
                                    favorability score
                                  </th>
                                  <th className="w-[5%] font-medium p-2  text-left border-r">
                                    overall
                                  </th>
                                </tr>
                              </>
                            ) : null}

                            <tr className="border-b text-[#3D405B] text-sm border-l border-white">
                              <td className="p-2 text-sm border-r border-white">
                                {question && question.parameterDimensionName
                                  ? GetParameterLabel(
                                    question.parameterDimensionName
                                  )
                                  : ""}
                              </td>
                              <td className="p-2 text-sm border-r border-white">
                                {question.name}
                              </td>
                              <td className="p-2 text-sm border-r border-white">
                                <div className="w-full space-y-0">

                                  {showYearData(yearValue1, indexTemplate) ?
                                    <div className="flex items-center w-full space-y-0.5">
                                      <div className="w-16">{yearValue1}</div>
                                      <div className="h-3 w-full mr-2 bg-[#CFD8DC] ">
                                        <div
                                          className="w-full h-3 bg-[#2196F3]"
                                          style={{
                                            background: "#2196F3",
                                            width:
                                              (question &&
                                                question.score &&
                                                question.score !== "NA"
                                                ? question.score
                                                : 0) + "%",
                                          }}
                                        />
                                      </div>
                                      <div className="w-20 text-sm font-medium">
                                        {question && question.score
                                          ? question.score
                                          : 0}
                                        %
                                      </div>
                                    </div>
                                    : null}

                                  {showYearData(yearValue2, indexTemplate) ?
                                    <div className="flex items-center w-full space-y-0.5">
                                      <div className="w-16">{yearValue2}</div>
                                      <div className="h-3 w-full mr-2 bg-[#CFD8DC]">
                                        <div
                                          className="w-full h-3 bg-[#9576CD]"
                                          style={{
                                            background: "#9576CD",
                                            width: (question &&
                                              question.score2 &&
                                              question.score2 !== "NA"
                                              ? question.score2
                                              : 0) + "%",
                                          }}
                                        />
                                      </div>
                                      <div className="w-20 text-sm font-medium">
                                        {question && question.score2
                                          ? question.score2
                                          : 0}
                                        %
                                      </div>
                                    </div>
                                    : null}

                                </div>
                              </td>

                              <td className="p-2 text-sm text-center border-r border-white">
                                <div className="text-sm font-medium ">
                                  {calcOverall(question)}%{" "}
                                </div>
                              </td>
                            </tr>
                          </>
                        ))
                        : null}
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <table id={"Behavior2" + indexTemplate}>
                    <thead>
                      <tr>
                        <th
                          data-pptx-min-width="2.6"
                          style={{
                            width: "15%",
                            fontSize: 10,
                            background: "#e6e6e6",
                          }}
                        >
                          Dimension
                        </th>
                        <th
                          data-pptx-min-width="3.6"
                          style={{
                            width: "50%",
                            fontSize: 10,
                            background: "#e6e6e6",
                          }}
                        >
                          Question
                        </th>
                        <th
                          data-pptx-min-width="0.8"
                          style={{
                            width: "10%",
                            fontSize: 10,
                            background: "#e6e6e6",
                          }}
                        >
                          Favorability Score
                        </th>
                        <th
                          data-pptx-min-width="0.8"
                          style={{
                            width: "10%",
                            fontSize: 10,
                            background: "#e6e6e6",
                          }}
                        >
                          Overall
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {NewQuesList && NewQuesList.length > 0
                        ? NewQuesList.map((question, index) => (
                          <tr>
                            <td
                              data-pptx-min-width="2.6"
                              style={{
                                width: "15%",
                                fontSize: 10,
                                background:
                                  index % 2 === 0 ? "#dbefff" : "#ffffff",
                              }}
                            >
                              {question && question.parameterDimensionName
                                ? GetParameterLabel(
                                  question.parameterDimensionName
                                )
                                : ""}
                            </td>
                            <td
                              data-pptx-min-width="2.6"
                              style={{
                                width: "50%",
                                fontSize: 10,
                                background:
                                  index % 2 === 0 ? "#dbefff" : "#ffffff",
                              }}
                            >
                              {" "}
                              {question.name}
                            </td>

                            <td
                              data-pptx-min-width="2.6"
                              style={{
                                width: "10%",
                                fontSize: 10,
                                background:
                                  index % 2 === 0 ? "#dbefff" : "#ffffff",
                              }}
                            >
                              {(question && question.score
                                ? question.score
                                : 0) + "%"}
                            </td>

                            <td
                              data-pptx-min-width="2.6"
                              style={{
                                width: "10%",
                                fontSize: 10,
                                background:
                                  index % 2 === 0 ? "#dbefff" : "#ffffff",
                              }}
                            >
                              {calcOverall(question)}%
                            </td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </main>
        </div>
      </>
    );
  }
}
export default ManagerOverall;
