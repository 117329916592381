import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../../../../_helpers';


import Header from '../../../Header/Header';
import { dashboardActions, surveyActions, campaignActions } from '../../../../../_actions';
import moment from 'moment';
import { CSVLink } from "react-csv";
import ConfirmMail from './Components/ConfirmMail';
import SendNotifyModal from './Components/SendNotifyModal';
import Sidebar from '../../../Survey/Components/Sidebar/Sidebar';
import LoadingOverlay from 'react-loading-overlay';

class CampaignReport extends Component {
  constructor(props) {
    super(props);
    this.state = {

      selectFilterType: 1,
      surveyReportEmail: '',
      openModalComfirmSend: false,
      SaveEmployeeId: '',

      showSendNotifyModal: false,




      filterformData: {
        "showAll": true,
        "isMailSent": true,
        "isClicked": true,
        "isComplete": true
      },

      showSidebar: false,
      CrrRoute: "survey",
      crrSurvey: "",

      CurrentLanguage: 'en',
      CurrentLanguageName: 'English',
      showLanguageModal: false,

      campaignEmpPageNo: 1,
      campaignEmpSize: 5,

      notifyFormData: {
        "title": "",
        "message": ""
      }

    }
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getClientProfile());



    let data4x = {
      "campaignId": this.props.match.params.id,
      "surveyId": this.props.match.params.idc
    }

    this.props.dispatch(campaignActions.getCampaignCustomerReponseNumberByID(data4x));







    let data4 = {
      "campaignId": this.props.match.params.id,
      "surveyId": this.props.match.params.idc,
      "pageNo": this.state.campaignEmpPageNo,
      "size": this.state.campaignEmpSize
    }

    this.props.dispatch(campaignActions.getCampaignCustomersByID(data4));







    let data = {
      campaignId: this.props.match.params.id,
      surveyId: this.props.match.params.idc
    }
    this.props.dispatch(campaignActions.getCampaignListBySurveyID({ surveyId: data.surveyId }));

    this.props.dispatch(campaignActions.responseCustomerAnalysisByCampaignId({ campaignId: data.campaignId }));



    let data3 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(surveyActions.getLanguageLabel(data3));
    this.props.dispatch(surveyActions.getLanguage(data3));





  }


  handleCreateSurveyPopup = () => {

  }


  handleSetFilter = (value) => {

    let { filterformData } = this.state;

    if (value === 'true' || value === true) {
      this.setState({ selectFilterType: 1 });
      filterformData['showAll'] = true;
    }
    else {
      this.setState({ selectFilterType: 2 });
      filterformData['showAll'] = false;
    }

    this.setState({ filterformData });

  }


  handleIsMailSent = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isMailSent'] = true;
    }
    else {
      filterformData['isMailSent'] = false;

    }
    this.setState({ filterformData });

  }

  handleIsClicked = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isClicked'] = true;
    }
    else {
      filterformData['isClicked'] = false;

    }
    this.setState({ filterformData });

  }

  handleIsComplete = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isComplete'] = true;
    }
    else {
      filterformData['isComplete'] = false;

    }
    this.setState({ filterformData });

  }


  handleEmailInput = (val) => {
    this.setState({ surveyReportEmail: val }, () => {

      if (!this.state.surveyReportEmail) {
        let data4 = {
          "campaignId": this.props.match.params.id,
          "surveyId": this.props.match.params.idc,
          "pageNo": this.state.campaignEmpPageNo,
          "size": this.state.campaignEmpSize
        }

        this.props.dispatch(campaignActions.getCampaignEmployeesByID(data4));
      }


    });

  }

  handleEmailSearchInput = (val) => {
    let data4 = {
      "campaignId": this.props.match.params.id,
      "surveyId": this.props.match.params.idc,
      "keyWord": this.state.surveyReportEmail,
      "pageNo": this.state.campaignEmpPageNo,
      "size": this.state.campaignEmpSize
    }

    this.props.dispatch(campaignActions.getCampaignEmployeesByKeyWord(data4));

  }



  HandleFilterType = (value) => {
    this.setState({ selectFilterType: value });
  }

  RefreshAPI = (check) => {


    let data = {
      campaignId: this.props.match.params.id,
      surveyId: this.props.match.params.idc
    }

    if (check) {
      this.props.dispatch(campaignActions.responseAnalysisByCampaignId({ campaignId: data.campaignId }));
    }
    this.props.dispatch(campaignActions.getCampaignCustomersByID(data));


  }


  forceMail = (id) => {
    this.setState({
      openModalComfirmSend: true,
      SaveEmployeeId: id
    })
  }


  handleSendSingleMail = () => {
    let { SaveEmployeeId } = this.state;
    this.props.dispatch(surveyActions.resendCampain({ id: SaveEmployeeId }));
    this.setState({ SaveEmployeeId: '', openModalComfirmSend: false });
  }

  resendCampain = (id) => {
    this.setState({
      openModalComfirmSend: true,
      SaveEmployeeId: id
    })
    //  this.props.dispatch(surveyActions.resendCampain({ id: id }));
  }

  handleConfirmMailClose = () => {
    this.setState({ openModalComfirmSend: false, SaveEmployeeId: '' });
  }


  handleLanguage = (item) => {
    this.setState({ CurrentLanguage: item.shortName, CurrentLanguageName: item.name, showLanguageModal: false });
  }

  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  }
  routeLink = (link) => {
    this.props.history.push('/app/' + link);
    this.setState({ CrrRoute: link });
  }
  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  }






  handlePaginaton = (type) => {
    let { campaignEmpPageNo } = this.state;
    let PageNo = 1;
    if (type === "prev") {
      if (campaignEmpPageNo > 1) {
        PageNo = campaignEmpPageNo - 1;
      }
    }
    else if (type === "next") {
      PageNo = campaignEmpPageNo + 1;
    }

    this.setState({ campaignEmpPageNo: PageNo }, () => {


      // let dataX1 = {
      //   surveyId: this.props.match.params.id,
      //   pageNo:this.state.campaignEmpPageNo,
      //   size:this.state.campaignEmpSize
      // }
      // this.props.dispatch(surveyActions.getResponseAnalyticsPagination(dataX1));



      let data4 = {
        "campaignId": this.props.match.params.id,
        "surveyId": this.props.match.params.idc,
        "pageNo": this.state.campaignEmpPageNo,
        "size": this.state.campaignEmpSize
      }

      this.props.dispatch(campaignActions.getCampaignEmployeesByID(data4));


    });






  }



  SendSurveyNotify = (check) => {
    this.setState({ showSendNotifyModal: check });
  }

  handleNotifyInput = (e) => {
    let { value, name } = e.target;
    let { notifyFormData } = this.state;
    notifyFormData[name] = value;
    //////console.log("notifyFormData-----<>",notifyFormData)
    this.setState({ notifyFormData });
  }


  submitAppNotification = () => {
    let data = {
      "campaignId": this.props.match.params.id,
      "surveyId": this.props.match.params.idc,
      "title": this.state.notifyFormData.title,
      "message": this.state.notifyFormData.message
    }
    this.props.dispatch(campaignActions.sendAppSurveyNotify(data));
    let clearData = {
      "title": "",
      "message": ""
    }
    this.setState({ notifyFormData: clearData, showSendNotifyModal: false });

  }

  render() {
    let { campaign, survey } = this.props;
    let { getLanguageLabelData, getLanguageData } = survey;
    let { getCampaignReponseNumberByIDData, getCampaignCustomersByIDData, getCampaignEmployeesByIDTotal, responseAnalysisByCampaignIdData, campaignList, loading } = campaign;
    let { notifyFormData, showSendNotifyModal, showLanguageModal, CurrentLanguageName, CurrentLanguage, CrrRoute, showSidebar, openModalComfirmSend, filterformData, selectFilterType, surveyReportEmail } = this.state;

    //////console.log("notifyFormData------------------->  ", notifyFormData)




    // //////console.log("getCampaignCustomersByIDData  ",getCampaignCustomersByIDData )



    let delivered = 0;
    let notdelivered = 0;

    let clicked = 0;
    let notclicked = 0;

    let completed = 0;
    let notcompleted = 0;

    let total = 0;

    let reminderCount = 0;

    // let completedEmpList = [];
    // let notCompletedEmpList = [];

    let campaignId = this.props.match.params.id;

    if (campaignList && campaignList.length > 0) {
      campaignList.forEach((item) => {
        if (item.id === campaignId)
          reminderCount = (item && item.reminderCount) ? item.reminderCount : 0
      })
    }

    if (getCampaignReponseNumberByIDData && getCampaignReponseNumberByIDData[0]) {
      let RespondantDetailsData = getCampaignReponseNumberByIDData[0];

      total = RespondantDetailsData && RespondantDetailsData.total ? RespondantDetailsData.total : 0;

      delivered = RespondantDetailsData && RespondantDetailsData.mailSent ? RespondantDetailsData.mailSent : 0;
      notdelivered = (total - delivered) >= 0 ? total - delivered : 0;

      clicked = RespondantDetailsData && RespondantDetailsData.clicked ? RespondantDetailsData.clicked : 0;
      notclicked = (total - clicked) >= 0 ? total - clicked : 0;

      completed = RespondantDetailsData && RespondantDetailsData.completed ? RespondantDetailsData.completed : 0;
      notcompleted = (total - completed) >= 0 ? total - completed : 0;

    }




    // if (getCampaignCustomersByIDData && getCampaignCustomersByIDData.length > 0) {
    //   getCampaignCustomersByIDData.forEach((item) => {

    //     total = total + 1;

    //     // delivered
    //     if (item.isMailSent === true) {
    //       delivered = delivered + 1;
    //     }
    //     else {
    //       notdelivered = notdelivered + 1;
    //     }
    //     // clicked
    //     if (item.isClicked === true) {
    //       clicked = clicked + 1;
    //     }
    //     else {
    //       notclicked = notclicked + 1;
    //     }
    //     // completed
    //     if (item.isComplete === true) {
    //       completed = completed + 1;
    //       completedEmpList.push(item);
    //     }
    //     else {
    //       notcompleted = notcompleted + 1;
    //       notCompletedEmpList.push(item);
    //     }


    //   })
    // }

    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }
    let ReportDataList = [
      {
        "name": LanguageObj && LanguageObj["total_" + CurrentLanguage] ? LanguageObj["total_" + CurrentLanguage] : "Total",
        "value": total,
        "color": '#99cc00',
        "icon": <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-align-box-center-bottom" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 19v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" /><path d="M11 17h2" /><path d="M9 14h6" /><path d="M10 11h4" /></svg>
      },
      {
        "name": LanguageObj && LanguageObj["delivered_" + CurrentLanguage] ? LanguageObj["delivered_" + CurrentLanguage] : "Delivered",
        "value": delivered,
        "color": '#00cc66',
        "icon": <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-send" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 14l11 -11" /><path d="M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5" /></svg>
      },
      {
        "name": LanguageObj && LanguageObj["not_delivered_" + CurrentLanguage] ? LanguageObj["not_delivered_" + CurrentLanguage] : "Not Delivered",
        "value": notdelivered,
        "color": '#ff4d4d',
        "icon": <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-send-off" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 14l2 -2m2 -2l7 -7" /><path d="M10.718 6.713l10.282 -3.713l-3.715 10.289m-1.063 2.941l-1.722 4.77a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l4.772 -1.723" /><path d="M3 3l18 18" /></svg>
      },
      {
        "name": LanguageObj && LanguageObj["clicked_" + CurrentLanguage] ? LanguageObj["clicked_" + CurrentLanguage] : "Clicked",
        "value": clicked,
        "color": '#bf00ff',
        "icon": <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-click" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12l3 0" /><path d="M12 3l0 3" /><path d="M7.8 7.8l-2.2 -2.2" /><path d="M16.2 7.8l2.2 -2.2" /><path d="M7.8 16.2l-2.2 2.2" /><path d="M12 12l9 3l-4 2l-2 4l-3 -9" /></svg>
      },
      {
        "name": LanguageObj && LanguageObj["not_clicked_" + CurrentLanguage] ? LanguageObj["not_clicked_" + CurrentLanguage] : "Not Clicked",
        "value": notclicked,
        "color": '#3399ff',
        "icon": <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-exclamation-mark-off" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 19v.01" /><path d="M12 15v-3m0 -4v-3" /><path d="M3 3l18 18" /></svg>
      },
      {
        "name": LanguageObj && LanguageObj["completed_" + CurrentLanguage] ? LanguageObj["completed_" + CurrentLanguage] : "Completed",
        "value": completed,
        "color": '#008080',
        "icon": <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-check" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M9 15l2 2l4 -4" /></svg>
      },
      {
        "name": LanguageObj && LanguageObj["not_completed_" + CurrentLanguage] ? LanguageObj["not_completed_" + CurrentLanguage] : "Not Completed",
        "value": notcompleted,
        "color": '#ff9933',
        "icon": <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clipboard-off" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5.575 5.597a2 2 0 0 0 -.575 1.403v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2m0 -4v-8a2 2 0 0 0 -2 -2h-2" /><path d="M9 5a2 2 0 0 1 2 -2h2a2 2 0 1 1 0 4h-2" /><path d="M3 3l18 18" /></svg>
      },

      {
        "name": LanguageObj && LanguageObj["reminder_count_" + CurrentLanguage] ? LanguageObj["reminder_count_" + CurrentLanguage] : "Reminder Count",
        "value": reminderCount,
        "color": '#99cc00',
        "icon": <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clock-cancel" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M20.997 12.25a9 9 0 1 0 -8.718 8.745" /><path d="M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M17 21l4 -4" /><path d="M12 7v5l2 2" /></svg>
      },

    ]


    let filterList = [
      {
        id: true,
        type: LanguageObj && LanguageObj["show_all_" + CurrentLanguage] ? LanguageObj["show_all_" + CurrentLanguage] : 'Show All'
      },
      {
        id: false,
        type: LanguageObj && LanguageObj["show_filter_" + CurrentLanguage] ? LanguageObj["show_filter_" + CurrentLanguage] : 'Show Filter'
      }
    ]

    let isMailList = [
      {
        id: true,
        type: LanguageObj && LanguageObj["delivered_" + CurrentLanguage] ? LanguageObj["delivered_" + CurrentLanguage] : "Delivered"
      },
      {
        id: false,
        type: LanguageObj && LanguageObj["not_delivered_" + CurrentLanguage] ? LanguageObj["not_delivered_" + CurrentLanguage] : "Not Delivered"
      }
    ]

    let isClickedList = [
      {
        id: true,
        type: LanguageObj && LanguageObj["clicked_" + CurrentLanguage] ? LanguageObj["clicked_" + CurrentLanguage] : "Clicked",
      },
      {
        id: false,
        type: LanguageObj && LanguageObj["not_clicked_" + CurrentLanguage] ? LanguageObj["not_clicked_" + CurrentLanguage] : "Not Clicked"
      }
    ]

    let isCompleteList = [
      {
        id: true,
        type: LanguageObj && LanguageObj["completed_" + CurrentLanguage] ? LanguageObj["completed_" + CurrentLanguage] : "Completed"
      },
      {
        id: false,
        type: LanguageObj && LanguageObj["not_completed_" + CurrentLanguage] ? LanguageObj["not_completed_" + CurrentLanguage] : "Not Completed"
      }
    ]



    let NeglectedList = ["isTest", "isDisable", "actionPlanStage", "id", "createdAt", "companyId", "currentStage"]

    let EmpFieldList = [];
    if (getCampaignCustomersByIDData && getCampaignCustomersByIDData.length > 0) {

      let tempLevelList = Object.keys(getCampaignCustomersByIDData[0].customerId);
      if (tempLevelList && tempLevelList.length > 0) {
        tempLevelList.forEach((temp) => {
          if (NeglectedList.findIndex(prev => prev === temp) === -1) {
            EmpFieldList.push(temp);
          }
        });
      }

    }


    let NewEmployeeList = [];
    if (getCampaignCustomersByIDData && getCampaignCustomersByIDData.length > 0) {
      getCampaignCustomersByIDData.forEach((item, index) => {
        if (!index) {
          //console.log('item.isClicked: ', item.isClicked)
        }

        let temp = {};
        if (EmpFieldList && EmpFieldList.length > 0) {
          EmpFieldList.forEach((filter) => {
            temp[filter] = item && item.customerId && item.customerId[filter] ? item.customerId[filter] : "";
          });
        }
        //console.log('item.isClicked: ', temp)


        temp["Delivered"] = item && item.isMailSent ? "Yes" : "No";
        temp["Clicked"] = item && item.isClicked ? item && item.clickedDate ? moment.unix(item.clickedDate / 1000).format("DD/MM/YYYY, HH:mm") : "####" : "No";
        temp["Completed"] = item && item.isComplete ? item && item.completeDate ? moment.unix(item.completeDate / 1000).format("DD/MM/YYYY, HH:mm") : "####" : "No";

        // temp["ClickedDate"]=item && item.isClicked?item && item.clickedDate?moment.unix(item.clickedDate / 1000).format("DD/MM/YYYY, HH:mm"):"####":"####";
        // temp["CompletedDate"]=item && item.isComplete?item && item.completeDate?moment.unix(item.completeDate / 1000).format("DD/MM/YYYY, HH:mm"):"####":"####";


        NewEmployeeList.push(temp);

      });
    }


    //console.log('getCampaignCustomersByIDData:--------------------------> ', getCampaignCustomersByIDData)
    //console.log('EmpFieldList: ', EmpFieldList)


    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    //console.log('moduleAccess: ', moduleAccess)



    //let SHOW_ELEMENT = false;
    let Isadmin = false;

    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
        Isadmin = true
      }
      else if (clientData.role === "MNGR") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        //SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    //END -------------------------------------------------------


    let EmployeeList = [];

    if (filterformData.showAll) {
      EmployeeList = getCampaignCustomersByIDData;
    }
    else {
      if (getCampaignCustomersByIDData && getCampaignCustomersByIDData.length > 0) {
        getCampaignCustomersByIDData.forEach((tempdata) => {
          if (
            (filterformData.isMailSent === tempdata.isMailSent) &&
            (filterformData.isClicked === tempdata.isClicked) &&
            (filterformData.isComplete === tempdata.isComplete)
          ) {
            EmployeeList.push(tempdata)
          }
        })
      }
    }


    return (
      <>

        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text='Loading Data...'
          className="loader"
        >

          <div>

            <Sidebar sidebarToggle={this.sidebarToggle} accessAllow={accessAllow} showSidebar={showSidebar} routeLink={this.routeLink} CrrRoute={CrrRoute}
              LanguageLabel={this.LanguageLabel}

              LanguageObj={LanguageObj}
              CurrentLanguage={CurrentLanguage}
            />

            <main className="lg:ml-16 overflow-y-auto  h-screen bg-[#f9fafb]">

              <Header onLogout={this.onLogout}
                CurrentLanguageName={CurrentLanguageName}
                getLanguageData={getLanguageData}
                handleLanguage={this.handleLanguage}
                openLanguageModal={this.openLanguageModal}
                showLanguageModal={showLanguageModal}

              />


              <div className="py-4 mx-6 ">

                <div className="items-center justify-between pb-8 xl:flex lg:flex md:flex">
                  <div className="flex items-center justify-between xl:pb-0 lg:pb-0 xl:flex-none">
                    <div className="flex items-center space-x-4 ">
                      <button onClick={() => this.props.history.goBack()} className="text-[#2196F3] bg-[#2196F3]/10 rounded-full cursor-pointer hover:bg-[#2196F3]/20 trasition duration-150   flex items-center justify-center w-10 h-10  "><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left " width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#2196f3" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="5" y1="12" x2="19" y2="12"></line><line x1="5" y1="12" x2="11" y2="18"></line><line x1="5" y1="12" x2="11" y2="6"></line></svg></button><h2 className="xl:text-2xl text-xl font-medium text-[#3D405B]">{LanguageObj && LanguageObj["campaign_report_" + CurrentLanguage] ? LanguageObj["campaign_report_" + CurrentLanguage] : "Campaign Response Analytics"}</h2></div>

                  </div>
                </div>
                <div className="grid grid-cols-1 gap-2 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 ">

                  {ReportDataList && ReportDataList.length > 0 ?
                    ReportDataList.map((item) =>
                      <>
                        <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] ">
                          <div className="flex items-center w-full ">
                            <div className="space-y-2">
                              <p className="block text-base text-[#fff] w-full uppercase">{item.name}</p>
                              <h1 className="text-3xl font-medium w-full text-[#fff]">
                                {item.value}
                              </h1>
                            </div>
                          </div>
                          <div className='text-white'>
                            {item.icon}
                          </div>

                        </div>
                      </>
                    )
                    : null}

                </div>

                <div className="justify-center">
                  <div className="py-4 mt-6 bg-white border rounded-lg">

                    <div className="p-4 space-y-4 ">

                      <div className="flex flex-wrap items-center justify-between gap-4 pb-2">
                        <div className='flex flex-wrap gap-2 itmes-center '>
                          <div className='flex items-center space-x-2'>
                            <div className="text-lg font-medium md:text-xl">{"Response Status Analytics"} </div></div>
                        </div>
                        <div className='flex flex-wrap gap-2 itmes-center '>

                          <button onClick={() => this.SendSurveyNotify(true)} className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                            <span>{"Send App Notification"}</span>
                          </button>

                          <div>
                            <div className="">
                              <select onChange={(event) => this.handleSetFilter(event.target.value)} className="bg-white p-2 px-2 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150 cursor-pointer text-sm flex items-center font-medium ">
                                {filterList && filterList.length > 0 ?
                                  filterList.map((item) =>
                                    <option value={item.id} className="text-xs">{item.type}</option>
                                  )
                                  : null}
                              </select>
                            </div>
                          </div>

                          <div className=''>
                            <button onClick={() => this.RefreshAPI(Isadmin)} className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-refresh" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path>
                                <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path>
                              </svg>
                              <span>{"Load Reponse"}</span>
                            </button>
                          </div>


                          <div className=''>
                            <button onClick={() => this.handleLoadResponse()} className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-refresh" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path>
                                <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path>
                              </svg>
                              <span>{LanguageObj && LanguageObj["refresh_" + CurrentLanguage] ? LanguageObj["refresh_" + CurrentLanguage] : "Refresh"}</span>
                            </button>
                          </div>

                          <div className='flex flex-wrap items-center gap-2'>

                            {Isadmin ?
                              <>
                                {accessAllow("downloadResponse") ?
                                  <>
                                    {responseAnalysisByCampaignIdData && responseAnalysisByCampaignIdData.length > 0 ?
                                      <CSVLink data={responseAnalysisByCampaignIdData} filename={"CampaignReport.csv"}>
                                        <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                          > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <path d="M10 12l4 4m0 -4l-4 4" />
                                          </svg>
                                          <span>{LanguageObj && LanguageObj["export_csv_label_" + CurrentLanguage] ? LanguageObj["export_csv_label_" + CurrentLanguage] : "Export Response	"}</span>
                                        </button>
                                        {/* <div className="p-1 px-2 mx-2 text-white bg-blue-400 cursor-pointer hover:bg-blue-500">{LanguageObj && LanguageObj["export_csv_label_" + CurrentLanguage] ? LanguageObj["export_csv_label_" + CurrentLanguage] : "Export Response	"}</div> */}
                                      </CSVLink>
                                      : null
                                    }
                                  </>
                                  :
                                  <div className="p-1 px-2 mx-2 text-white bg-blue-300 cursor-pointer ">{LanguageObj && LanguageObj["export_csv_label_" + CurrentLanguage] ? LanguageObj["export_csv_label_" + CurrentLanguage] : "Export Response	"}</div>
                                }
                              </>
                              : null}

                            {NewEmployeeList && NewEmployeeList.length > 0 ?
                              <CSVLink data={NewEmployeeList} filename={"CampaignResponseAnalytics.csv"}>
                                <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                  > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <path d="M10 12l4 4m0 -4l-4 4" />
                                  </svg>
                                  <span>{LanguageObj && LanguageObj["export_csv_label_" + CurrentLanguage] ? LanguageObj["export_csv_label_" + CurrentLanguage] : "Export Response Analytics	"}</span>
                                </button>
                                {/* <div className="p-1 px-2 mx-2 text-white bg-blue-400 cursor-pointer hover:bg-blue-500">{LanguageObj && LanguageObj["export_csv_label_" + CurrentLanguage] ? LanguageObj["export_csv_label_" + CurrentLanguage] : "Export Response Analytics	"}</div> */}
                              </CSVLink>
                              : null
                            }
                          </div>

                        </div>
                      </div>


                      {/* filter alll start  */}

                      {/* <div className="px-2 mr-2 text-xl">{LanguageObj && LanguageObj["employees_" + CurrentLanguage] ? LanguageObj["employees_" + CurrentLanguage] : "Employees"}</div>
                        <div className="pt-1 material-symbols-outlined">filter_list</div>
                        <div className="block ml-2 bg-white border xl:block lg:block md:block">
                          <select onChange={(event) => this.handleSetFilter(event.target.value)} className="w-full py-1 pl-2 text-sm font-normal outline-none text-slate-800">
                            {filterList && filterList.length > 0 ?
                              filterList.map((item) =>
                                <option value={item.id} className="text-xs">{item.type}</option>
                              )
                              : null}
                          </select>
                        </div> */}

                      {/* <div onClick={() => this.RefreshAPI(Isadmin)} className="p-1 px-2 mx-2 text-white bg-gray-400 cursor-pointer hover:bg-gray-500">{LanguageObj && LanguageObj["refresh_" + CurrentLanguage] ? LanguageObj["refresh_" + CurrentLanguage] : "Refresh"}</div>

                        <div onClick={() => this.handleLoadResponse()} className="p-1 px-2 mx-2 text-white bg-gray-400 cursor-pointer hover:bg-gray-500">{"Load Reponse"}</div> */}





                      {/* {Isadmin ?
                          <>
                            {accessAllow("downloadResponse") ?
                              <>
                                {responseAnalysisByCampaignIdData && responseAnalysisByCampaignIdData.length > 0 ?
                                  <CSVLink data={responseAnalysisByCampaignIdData} filename={"CampaignReport.csv"}>
                                    <div className="p-1 px-2 mx-2 text-white bg-blue-400 cursor-pointer hover:bg-blue-500">{LanguageObj && LanguageObj["export_csv_label_" + CurrentLanguage] ? LanguageObj["export_csv_label_" + CurrentLanguage] : "Export Response	"}</div>
                                  </CSVLink>
                                  : '...'}
                              </>
                              :
                              <div className="p-1 px-2 mx-2 text-white bg-blue-300 cursor-pointer cursor-not-allowed">{LanguageObj && LanguageObj["export_csv_label_" + CurrentLanguage] ? LanguageObj["export_csv_label_" + CurrentLanguage] : "Export Response	"}</div>
                            }
                          </>
                          : null} */}





                      {/* {NewEmployeeList && NewEmployeeList.length > 0 ?
                          <CSVLink data={NewEmployeeList} filename={"CampaignResponseAnalytics.csv"}>
                            <div className="p-1 px-2 mx-2 text-white bg-blue-400 cursor-pointer hover:bg-blue-500">{LanguageObj && LanguageObj["export_csv_label_" + CurrentLanguage] ? LanguageObj["export_csv_label_" + CurrentLanguage] : "Export Response Analytics	"}</div>
                          </CSVLink>
                          : '...'} */}



                      {/* <div onClick={() => this.SendSurveyNotify(true)} className="p-1 px-2 mx-2 text-white bg-purple-400 cursor-pointer hover:bg-purple-500">
                          {"Send App Notification"}
                        </div> */}

                      {/* filter alll end  */}

                    </div>
                    <div className="w-full overflow-x-auto whitespace-nowrap "  >
                      <table className="w-full border-collapse ">
                        <thead>
                          <tr className="text-xs">
                            <th className="p-1 font-semibold text-gray-800 border border-gray-300 bg-gray-50">S. NO</th>

                            {selectFilterType === 1 && false ?
                              <th className="p-3 font-semibold text-gray-800 border border-gray-300 bg-gray-50">{LanguageObj && LanguageObj["email_address_label_" + CurrentLanguage] ? LanguageObj["email_address_label_" + CurrentLanguage] : "Email Address"}</th>
                              :
                              <th className="p-1 font-semibold text-gray-800 border border-gray-300 bg-gray-50">
                                <span className='flex'>
                                  <input className="w-5/6 p-2 bg-white border" placeholder={LanguageObj && LanguageObj["search_by_email_text_" + CurrentLanguage] ? LanguageObj["search_by_email_text_" + CurrentLanguage] : "Search email..."} value={surveyReportEmail} onChange={(e) => this.handleEmailInput(e.target.value)} />
                                  <span onClick={() => this.handleEmailSearchInput()} className="mx-1 mt-1 cursor-pointer material-symbols-outlined">search</span>
                                </span>
                              </th>}

                            {selectFilterType === 1 ?
                              <th className="p-3 font-semibold text-gray-800 border border-gray-300 bg-gray-50">{LanguageObj && LanguageObj["delivered_" + CurrentLanguage] ? LanguageObj["delivered_" + CurrentLanguage] : "Delivered"}</th>
                              :
                              <th className="p-1 font-semibold text-gray-800 border border-gray-300 bg-gray-50">
                                <div onClick={() => this.HandleFilterType(2)} className="block mx-1 bg-white border xl:block lg:block md:block">
                                  <select onChange={(event) => this.handleIsMailSent(event.target.value)} className="w-full py-1 pl-2 text-xs font-normal outline-none text-slate-800">
                                    {isMailList && isMailList.length > 0 ?
                                      isMailList.map((item) =>
                                        <option value={item.id} className="text-xs">{item.type}</option>
                                      )
                                      : null}
                                  </select>
                                </div>
                              </th>}

                            <th className="py-3 font-semibold text-gray-800 border border-gray-300 bg-gray-50">{LanguageObj && LanguageObj["reminder_count_" + CurrentLanguage] ? LanguageObj["reminder_count_" + CurrentLanguage] : "Reminder Count"}</th>
                            <th className="py-3 font-semibold text-gray-800 border border-gray-300 bg-gray-50">{LanguageObj && LanguageObj["reminder_last_date_" + CurrentLanguage] ? LanguageObj["reminder_last_date_" + CurrentLanguage] : "Reminder Last Date"}</th>


                            {selectFilterType === 1 ?
                              <th className="p-3 font-semibold text-gray-800 border border-gray-300 bg-gray-50">{LanguageObj && LanguageObj["clicked_" + CurrentLanguage] ? LanguageObj["clicked_" + CurrentLanguage] : "Clicked"}</th>
                              :
                              <th className="p-1 font-semibold text-gray-800 border border-gray-300 bg-gray-50">
                                <div onClick={() => this.HandleFilterType(3)} className="block mx-1 bg-white border xl:block lg:block md:block">
                                  <select onChange={(event) => this.handleIsClicked(event.target.value)} className="w-full py-1 pl-2 text-xs font-normal outline-none text-slate-800">
                                    {isClickedList && isClickedList.length > 0 ?
                                      isClickedList.map((item) =>
                                        <option value={item.id} className="text-xs">{item.type}</option>
                                      )
                                      : null}
                                  </select>
                                </div>
                              </th>}

                            {selectFilterType === 1 ?
                              <th className="p-3 font-semibold text-gray-800 border border-gray-300 bg-gray-50">{LanguageObj && LanguageObj["completed_" + CurrentLanguage] ? LanguageObj["completed_" + CurrentLanguage] : "Completed"}</th>
                              :
                              <th className="p-1 font-semibold text-gray-800 border border-gray-300 bg-gray-50">
                                <div onClick={() => this.HandleFilterType(4)} className="block mx-1 bg-white border xl:block lg:block md:block">
                                  <select onChange={(event) => this.handleIsComplete(event.target.value)} className="w-full py-1 pl-2 text-xs font-normal outline-none text-slate-800">
                                    {isCompleteList && isCompleteList.length > 0 ?
                                      isCompleteList.map((item) =>
                                        <option value={item.id} className="text-xs">{item.type}</option>
                                      )
                                      : null}
                                  </select>
                                </div>
                              </th>}
                            <th className="py-3 font-semibold text-gray-800 border border-gray-300 bg-gray-50">{LanguageObj && LanguageObj["mail_label_" + CurrentLanguage] ? LanguageObj["mail_label_" + CurrentLanguage] : "Send Mail"}</th>

                          </tr>
                        </thead>


                        <tbody>



                          {EmployeeList && EmployeeList.length > 0 ?
                            EmployeeList.map((tempdata, index) =>
                              <>
                                {/* {(tempdata.customerId && tempdata.customerId.email.match(new RegExp(`${surveyReportEmail}`, 'gi'))) ?
                                  <EmployeeTableRows LanguageObj={LanguageObj} CurrentLanguage={CurrentLanguage} tempdata={tempdata} index={index} forceMail={this.forceMail} resendCampain={this.resendCampain} />
                                  : null} */}
                                <EmployeeTableRows LanguageObj={LanguageObj} CurrentLanguage={CurrentLanguage} tempdata={tempdata} index={index} forceMail={this.forceMail} resendCampain={this.resendCampain} />

                              </>
                            )
                            : null}


                        </tbody>





                      </table>
                    </div>





                    {!surveyReportEmail ?
                      <>

                        <div className="flex items-center justify-center w-full h-16 bg-white ">
                          <div class="flex flex-wrap border divide-x rounded-md ">
                            <button onClick={() => this.handlePaginaton("prev")}
                              class="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                              Prev
                            </button>

                            {(getCampaignEmployeesByIDTotal) ? (
                              <>
                                <button class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] flex">
                                  {this.state.campaignEmpPageNo}
                                  <p className='text-[#3D405B]/50 pl-2'>{" of "}{getCampaignEmployeesByIDTotal ? getCampaignEmployeesByIDTotal : 0}</p>
                                </button>

                              </>
                            ) : (
                              <button class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] flex">
                                <p className='text-[#3D405B]/50 pl-2'>{0}</p>
                              </button>
                            )}

                            <button onClick={() => this.handlePaginaton("next")} class="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition"> Next </button>
                          </div>

                        </div>
                      </>
                      : null}



                  </div>

                </div>



              </div></main>
          </div>


          {showSendNotifyModal ?

            <SendNotifyModal
              notifyFormData={notifyFormData}
              SendSurveyNotify={this.SendSurveyNotify}
              handleNotifyInput={this.handleNotifyInput}
              submitAppNotification={this.submitAppNotification}

            />

            : null}


          {openModalComfirmSend ?

            <ConfirmMail handleConfirmMailClose={this.handleConfirmMailClose} handleSendSingleMail={this.handleSendSingleMail} />

            : null}

        </LoadingOverlay>
      </>
    );
  }
}


function EmployeeTableRows(props) {
  let { tempdata, index, forceMail, LanguageObj, CurrentLanguage } = props;
  return (
    <tr className="mb-10 text-xs text-center text-gray-800 bg-white lg:mb-0">
      <td className="p-3 border border-b ">
        {index + 1}
      </td>
      <td className="p-3 border border-b">
        {tempdata.customerId && tempdata.customerId.email ? tempdata.customerId.email : '-'}
      </td>
      {/* <td className="p-3 border border-b"> 
          {tempdata.isMailSent ?
          <div className="p-1 bg-gray-200 border rounded cursor-default">{LanguageObj && LanguageObj["mail_sent_"+CurrentLanguage]? LanguageObj["mail_sent_"+CurrentLanguage]:"Mail Sent"}</div>
          :
          <div onClick={()=>resendCampain(tempdata.id)} className="p-1 text-white bg-blue-400 border rounded">{LanguageObj && LanguageObj["resend_mail_"+CurrentLanguage]? LanguageObj["resend_mail_"+CurrentLanguage]:"Resend Mail"}</div>
          }
        </td> */}


      <td className="p-3 border border-b">
        {tempdata.isMailSent ?
          // <div className="p-1 bg-gray-200 border rounded cursor-default">{LanguageObj && LanguageObj["yes_"+CurrentLanguage]? LanguageObj["yes_"+CurrentLanguage]:"Yes"}</div>
          <span className="px-3 py-1 text-blue-500">{LanguageObj && LanguageObj["yes_" + CurrentLanguage] ? LanguageObj["yes_" + CurrentLanguage] : "Yes"}</span>

          :
          // onClick={()=>resendCampain(tempdata.id)} 
          <span className="px-3 py-1 text-red-500">{LanguageObj && LanguageObj["no_" + CurrentLanguage] ? LanguageObj["no_" + CurrentLanguage] : "No"}</span>

        }
      </td>


      <td className="p-3 border border-b">
        {tempdata && tempdata.reminderCount ? tempdata.reminderCount : '0'}
      </td>
      <td className="p-3 border border-b">
        <span className="py-1">{tempdata && tempdata.reminderDate && tempdata.reminderDate.length > 0 ? moment.unix(tempdata.reminderDate[tempdata.reminderDate.length - 1] / 1000).format("DD/MM/YYYY, HH:mm") : '-'}</span>
      </td>
      <td className="p-3 border border-b">
        <span className="px-3 py-1">{tempdata.isClicked ? moment.unix(tempdata.clickedDate / 1000).format("DD/MM/YYYY, HH:mm") : 'No'}</span>
      </td>
      <td className="p-3 border border-b">
        <span className="px-3 py-1 ">{tempdata.isComplete ? moment.unix(tempdata.completeDate / 1000).format("DD/MM/YYYY, HH:mm") : 'No'}</span>
      </td>

      <td className="p-3 border border-b">
        <span onClick={() => forceMail(tempdata.id)} className="cursor-pointer material-symbols-outlined">mail</span>
      </td>

    </tr>


  )
}



function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { campaign, survey, dashboard } = state;
  return {
    loggingIn,
    campaign,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(CampaignReport);
