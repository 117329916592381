import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../_helpers';

//api call
import { dashboardActions, surveyActions, pmsActions } from '../../_actions';

//libraries
import LoadingOverlay from 'react-loading-overlay';

import moment from 'moment';

//Layout

import Header from './Header/Header';
import Sidebar from "./Sidebar/Sidebar";


import * as XLSX from 'xlsx';
import { make_cols } from '../Employees/MakeColumns';
import { SheetJSFT } from '../Employees/types';



// import Overview from './Overview//Overview';
import AppraisalCycleList from './AppraisalCycleList/AppraisalCycleList';
import AddAppraisalCycle from './AddAppraisalCycle/AddAppraisalCycle';


import UploadPreview from './Modal/UploadPreview';


class HappinessDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSection: 'appraisal_cycle_list',
            showAddAppraisalModal: false,
            showSidebar: true,

            stagesList: [
                {
                    "id": 1,
                    "name": 'Appraisal Cycle Details',
                    "done": false
                },
                {
                    "id": 2,
                    "name": 'Module Settings',
                    "done": false
                },
                {
                    "id": 3,
                    "name": 'Normalization',
                    "done": false
                },
                {
                    "id": 4,
                    "name": 'Raters Setting',
                    "done": false
                },
                {
                    "id": 5,
                    "name": 'Applicable For',
                    "done": false
                },
                {
                    "id": 6,
                    "name": 'Review Approval',
                    "done": false
                }
            ],

            showUploadPreview: false,
            showRelationTable: false,

        }
    }
    componentDidMount() {

        this.props.dispatch(dashboardActions.getClientProfile());
        this.props.dispatch(dashboardActions.getCompanyNameOnly());

        let dataX = {
            "appraisalCycleId": this.props.match.params.id
        }
        this.props.dispatch(pmsActions.getEmployeeAppraisalStatus(dataX));

        // this.props.dispatch(pmsActions.PMSGetAppraisalDetailsById({"id":this.props.match.params.id}));
        let data = { "id": this.props.match.params.id }
        this.props.dispatch(pmsActions.PMSGetAppraisalName(data));

        this.props.dispatch(pmsActions.PMSGetAppraisalDetailsById({ "id": this.props.match.params.id }));

    }

    openAddAppraisalCycle = (value) => {
        if (value === 'add_appraisal_cycle') {

            this.props.dispatch(dashboardActions.getPMSModuleNames())
            this.props.dispatch(dashboardActions.getPMSRaterNames())
            this.props.dispatch(dashboardActions.getEmpFilter());
            this.props.dispatch(surveyActions.getIndexByFilterDMG());

        } else if (value === 'appraisal_cycle_list') {
            this.props.dispatch(dashboardActions.PMSAppraisalCycleList())
        } else if (value === 'pms_relationship_list') {
            let data2x2 = {
                "keyWord": "",
                "pageNo": 1,
                "size": 100000,
                "isSense": false
            }

            this.props.dispatch(surveyActions.getPMSRelationList(data2x2));
        }
        this.setState({ showSection: value });
    }


    handleLogout = () => {

    }

    onOverviewFromHeader = () => {
        this.props.history.goBack()
    }

    sidebarToggle = () => {
        this.setState({ showSidebar: !this.state.showSidebar });
    }

    handleSelect = (id) => {
        let { stagesList } = this.state;

        let temp1 = stagesList.filter(prev => prev.done === true).length;
        let temp2 = stagesList.length;

        if (temp1 === temp2) {
            this.openAddAppraisalCycle('appraisal_cycle_list');
        } else {
            let getIndex = stagesList.findIndex(prev => prev.id === id);
            if (getIndex !== -1) {
                stagesList[getIndex]["done"] = !stagesList[getIndex]["done"]
                this.setState({ stagesList });
            }
        }
    }






    openAddRelationship = () => {
        this.handleRelationshipUpload();
    }


    handleRelationshipUpload = () => {
        this.setState({ showRelationModal: true })
    }

    closeRelationshipModal = () => {
        this.setState({ showRelationModal: false })
    }


    downLoadSampleCSV = () => {


        let binary_univers = [
            { "reviewer_name": "NA", "reviewer_email": "NA", "reviewer_emp_id": "E002", "reviewee_emp_id": "E002", "reviewer_relation": "Self", "relation_type": "INTERNAL" },
            { "reviewer_name": "NA", "reviewer_email": "NA", "reviewer_emp_id": "E001", "reviewee_emp_id": "E002", "reviewer_relation": "Manager", "relation_type": "INTERNAL" },
            { "reviewer_name": "NA", "reviewer_email": "NA", "reviewer_emp_id": "E004", "reviewee_emp_id": "E002", "reviewer_relation": "Peer", "relation_type": "INTERNAL" },
            { "reviewer_name": "john", "reviewer_email": "empsample1@mailinator.com", "reviewer_emp_id": "NA", "reviewee_emp_id": "E002", "reviewer_relation": "Subordinate", "relation_type": "EXTERNAL" },
            { "reviewer_name": "john", "reviewer_email": "empsample1@mailinator.com", "reviewer_emp_id": "NA", "reviewee_emp_id": "E002", "reviewer_relation": "Subordinate", "relation_type": "EXTERNAL" },
            { "reviewer_name": "john", "reviewer_email": "empsample1@mailinator.com", "reviewer_emp_id": "NA", "reviewee_emp_id": "E002", "reviewer_relation": "Subordinate", "relation_type": "EXTERNAL" }
        ];

        //console.log('binary_univers: ', binary_univers)

        let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

        // Create a new Workbook
        var wb = XLSX.utils.book_new()

        // Name your sheet
        XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values')

        // export your excel
        XLSX.writeFile(wb, 'sample relationship.xlsx');
    }




    handleDataChange = (e) => {
        this.props.dispatch(surveyActions.emptyPMSSave());

        this.setState({ loadedDataSheet: [] }, () => {

            const files = e.target.files;
            // //////console.log("files; ",files)

            if (files && files[0]) {
                let fileName = files && files[0] && files[0].name ? files[0].name : "";
                let extName1 = fileName && fileName.substr(fileName.length - 3) ? fileName.substr(fileName.length - 3) : "";
                let extName2 = fileName && fileName.substr(fileName.length - 4) ? fileName.substr(fileName.length - 4) : "";
                if ((extName1 === "csv") || (extName2 === "xlsx")) {

                    this.setState({ file: files[0] }, () => {
                        //////console.log("this.state.file", this.state.file)
                        this.setState({ fileUploadError: "" });



                        //----------------------------------------------------------------------------------------------------------------------------------------------------------
                        const reader = new FileReader();
                        const rABS = !!reader.readAsBinaryString;


                        if (this.state.file) {
                            if (rABS) {
                                reader.readAsBinaryString(this.state.file);
                            } else {
                                reader.readAsArrayBuffer(this.state.file);
                            };
                        }

                        console.log('rABS--------------------------------------------->', rABS)


                        reader.onload = (e) => {
                            /* Parse data */
                            const bstr = e.target.result;
                            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
                            /* Get first worksheet */
                            const wsname = wb.SheetNames[0];
                            const ws = wb.Sheets[wsname];
                            console.log('before ws--------------------------------------------->', ws)

                            /* Convert array of arrays */
                            let data = XLSX.utils.sheet_to_json(ws);
                            data = data.map(v => ({ ...v }))



                            console.log('data-------------11111-------------------------------->', data)


                            setTimeout(() => {
                                this.setState({ loadedDataSheet: data, showUploadPreview: true, showRelationModal: false })
                            }, 1000)



                        };



                        //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------



                    });

                }
                else {
                    this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
                    //////console.log("not csv or xlsx file")
                }

            }
            else {
                this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
            }

        })

    };



    saveUploadRelation = () => {
        let { loadedDataSheet } = this.state;
        if (loadedDataSheet && loadedDataSheet.length > 0) {
            let Temp1 = {
                "keyWord": '',
                "pageNo": 1,
                "size": 6
            }

            let finalData = []
            if (loadedDataSheet && loadedDataSheet.length > 0) {
                finalData = loadedDataSheet.map((element) => ({ ...element, 'isSense': false }))
            }
            this.props.dispatch(surveyActions.savePMSRelationList(finalData, Temp1));
        }


    }


    handleDataFile = () => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            let data = XLSX.utils.sheet_to_json(ws);
            // //////console.log("datadatadatadata ", data);
            data = data.map(v => ({ ...v }))
            //////console.log("datasssss-------->", data)
            let flag = 0;
            let locList = [];
            if (data && data.length > 0) {
                for (var i = 0; i < data.length; i++) {
                    flag = flag + 1;
                    locList.push(i + 1);
                }
            }

            //////console.log("flag-------->", flag)

            if (flag && false) {
                this.setState({ isNotMail: true, isNotMailPos: locList })
            }
            else {

                let Temp1 = {
                    "keyWord": '',
                    "pageNo": 1,
                    "size": 100000
                }
                this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
                    console.log("this.state.data-------->", this.state.data)

                    let finalData = []
                    if (this.state.data && this.state.data.length > 0) {
                        finalData = this.state.data.map((element) => ({ ...element, 'isSense': false }))
                    }

                    this.props.dispatch(surveyActions.savePMSRelationList(finalData, Temp1));
                    this.setState({ isNotMail: false, isNotMailPos: [] })

                });
            }



        };
        if (this.state.file) {
            if (rABS) {
                reader.readAsBinaryString(this.state.file);
            } else {
                reader.readAsArrayBuffer(this.state.file);
            };
        }
    }


    closeUploadPreview = () => {
        this.setState({ showUploadPreview: false, loadedDataSheet: [] })

    }

    closeRelationshipTable = () => {
        this.setState({ showRelationTable: false })
    }

    handleMyRelationshipTable = () => {
        this.setState({ showRelationTable: true })
    }

    handlePMSDisableStatue = (id) => {
        let data2x2 = {
            "keyWord": "",
            "pageNo": 1,
            "size": 1000
        }
        let data = {
            "id": id
        }
        // this.props.dispatch(surveyActions.handlePMSFeedbackDisable(data, data2x2));
    }


    gotoSection = (type, item) => {
        if (type === "dashboard") {
            if (item && item.id) {
                this.props.history.push('/app/pmsdashboard/' + item.id)
            }
        }
    }


    handleContinueAppraisal = (data) => {
        if (data && data.appraisalCycleId) {
            this.props.history.push('/app/pmsemplist/' + data.appraisalCycleId)
        }
    }


    handleStartAppraisal = () => {

    }

    goBack = () => {
        this.props.history.push('/app/performance');
    }


    render() {
        let { dashboard, getCompanyNameOnlyData, survey, pms } = this.props;
        let { ClientProfileData } = dashboard;
        let { getEmployeeAppraisalStatus, PMSGetAppraisalName, PMSGetAppraisalDetailsById } = pms;
        // let { getEmployeeAppraisalStatus, PMSGetAppraisalDetailsById } = pms;
        let { showSection, stagesList } = this.state;

        let { loading, showGrid, showRelationTable, loadedDataSheet, showUploadPreview, showRelationModal, crrLinkedCluster, crrLinkedSurveyId, crrLifecycleStageId, showNODashboardModal, showUpdateSurveyModal, updateformSurveyData, showLanguageModal, CurrentLanguageName, CurrentLanguage, crrSurvey, CrrRoute, showSidebar, savePackage, isPackageSelected, showQuestionLibrary, packageList, CurrentSurveyData, showSurveyLibrary, showChooseSurveyModal, showSurveyList, CurrentSurveyName, confirmDeleteSurvey, surveyFormData, showCreateSurveyPopup, surveyValidation, showTab } = this.state;



        let { savePMSRelationListData, getPMSRelationListData, getIndexByFilterDMGData } = survey;



        console.log("getEmployeeAppraisalStatus---->", getEmployeeAppraisalStatus);


        return (
            <>
                <LoadingOverlay
                    active={loading}
                    // active={false}

                    spinner
                    text='Loading Data...'
                    className="loader"
                >
                    <div className="h-screen overflow-hidden bg-gray-50">
                        <Header
                            ClientProfileData={ClientProfileData}
                            handleLogout={this.handleLogout}
                            props={this.props}
                            companyName={
                                getCompanyNameOnlyData && getCompanyNameOnlyData.name
                                    ? getCompanyNameOnlyData.name
                                    : ""
                            }
                            sidebarToggle={this.sidebarToggle}
                            onOverviewFromHeader={this.onOverviewFromHeader}
                        />

                        <div className="flex w-full" style={{ height: "calc(100% - 2rem)" }}>

                            {/* side bar */}
                            <div
                                className="cursor-pointer transition-width w-64  lg:block lang lg:relative fixed top-0 left-0 z-20  h-screen     duration-200 transform bg-white border-r hidden"
                                style={{ height: "calc(100% - 0rem)" }}
                            >
                                <div className="my-4 whitespace-nowrap ">

                                    <div
                                        title="Users"
                                        className="flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer   border-l-4 border-[#2196F3] bg-[#2196F3]/10"
                                    >
                                        <div className="flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center">
                                            <span className=" material-symbols-outlined">
                                                <svg onClick={() => this.goBack()}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="icon icon-tabler icon-tabler-users"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.8"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <circle cx={9} cy={7} r={4} />
                                                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                                                </svg>
                                            </span>
                                        </div>
                                        <span className=" text-sm text-[#2196F3]">Appraisal Cycle</span>
                                    </div>
                                </div>
                            </div>


                            <div className="md:p-6 p-4 w-full space-y-6 overflow-y-auto">
                                <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
                                    <div className="flex items-center space-x-4 ">
                                        <svg onClick={() => this.goBack()} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="28" height="28" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M15 6l-6 6l6 6"></path>
                                        </svg>
                                        <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">
                                            Appraisal Cycle
                                        </h1>
                                    </div>

                                    <div className="md:flex hidden items-center text-sm">
                                        <span className="mx-2 material-symbols-rounded">home</span>
                                        <span>/</span>
                                        <span className="px-2">PMS</span>
                                        <span>/</span>
                                        <span className="px-2 ">Appraisal Cycle</span>
                                        <span>/</span>
                                        <span className="px-2 font-semibold">Appraisal Cycle</span>
                                    </div>
                                </div>
                                <div className="bg-white p-6 space-y-6 border rounded-md  ">
                                    {/* <div className='flex justify-between'> */}
                                    <div className='grid lg:grid-cols-3 grid-cols-1  gap-4 justify-between text-[#3D405B]'>
                                        <div className='space-y-2 mx-2'>
                                            <p>Name of Appraisal cycle</p>
                                            <h1 className="xl:text-1xl text-xl font-medium text-[#3D405B]">
                                                {PMSGetAppraisalDetailsById && PMSGetAppraisalDetailsById.name ? PMSGetAppraisalDetailsById.name : ''}
                                            </h1>
                                        </div>
                                        <div className='space-y-2 '>
                                            <p>   Appraisal Period</p>
                                            <h1 className="xl:text-1xl text-xl font-medium text-[#3D405B]">
                                                {/* {PMSGetAppraisalDetailsById && PMSGetAppraisalDetailsById.name ? PMSGetAppraisalDetailsById.name : ''} */}
                                                01 Apr 2023 - 01 Jul 2023
                                            </h1>
                                        </div>
                                        <div className='space-y-2 '>
                                            <p> Total Employee</p>
                                            <div className="xl:text-1xl text-xl font-medium text-[#3D405B] flex flex-row gap-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users-group" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                                    <path d="M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1"></path>
                                                    <path d="M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                                    <path d="M17 10h2a2 2 0 0 1 2 2v1"></path>
                                                    <path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                                    <path d="M3 13v-1a2 2 0 0 1 2 -2h2"></path>
                                                </svg>
                                                <span>{getEmployeeAppraisalStatus && getEmployeeAppraisalStatus.length > 0 ? getEmployeeAppraisalStatus.length : 0}</span>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </div>

                                </div>
                                <div className="bg-white p-6 space-y-6 border rounded-md  ">


                                    <div className='bg-white p-4 space-y-4 border rounded-lg'>
                                       
                                        <div className='justify-between md:flex md:space-y-2  '>
                                        <h1 class="text-xl font-medium text-[#3D405B]">Appraisal Cycle List</h1>

                                            <div className='gap-2 flex md:flex-row flex-col justify-between w-full'>

                                                <div className="flex items-center justify-between overflow-hidden text-sm bg-white border rounded-md ">
                                                    <span
                                                        className="p-2 rounded-l-full material-symbols-outlined text-slate-600"
                                                        style={{ fontSize: "1.4rem" }}
                                                    >
                                                        search
                                                    </span>
                                                    <input
                                                        type="search"
                                                        name="search"
                                                        onChange={this.handleSearchSurvey}
                                                        className="w-full py-2 md:w-48 focus:outline-none placeholder:text-slate-600"
                                                        placeholder="Search"
                                                    />
                                                </div>


                                                {/* 
                                                <button
                                                    onClick={() =>
                                                        this.handleRelationshipUpload()
                                                    }
                                                    className="bg-[#2196f3] hover:bg-[#1E88E5] transition-all duration-200 md:w-auto w-full md:py-2 py-3  px-4 rounded-md text-center justify-center cursor-pointer transform  flex items-center text-white space-x-1 md:text-sm  text-md whitespace-nowrap"
                                                >
                                                    <span className="text-white material-symbols-outlined ">
                                                        add
                                                    </span>{" "}
                                                    <h1 className="">Add PMS Relationship</h1>
                                                </button> */}

                                            </div>
                                        </div>
                                        <div className="bg-white space-y-6 rounded-md">

                                            <div className="overflow-x-auto whitespace-nowrap border border-gray-200">
                                                <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse">
                                                    <thead className="bg-white">
                                                        <tr className="bg-[#f9fafb] border-b">
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 font-semibold text-sm text-[#3D405B] lg:w-[40%]"
                                                            >
                                                                Employees
                                                            </th>
                                                            {/* <th
                                                                scope="col"
                                                                className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center"
                                                            >
                                                                Reviewer
                                                            </th> */}
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-left"
                                                            >
                                                                Created On
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center"
                                                            >
                                                                Review status
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center"
                                                            >
                                                                Actions
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="border-t border-gray-100 divide-y divide-gray-100">
                                                        {getEmployeeAppraisalStatus && getEmployeeAppraisalStatus.length > 0 ?
                                                            getEmployeeAppraisalStatus.map((element) =>
                                                                <tr className="bg-white ">
                                                                    <td className="px-6 py-3 ">
                                                                        <div className="flex items-center space-x-2 ">
                                                                            <img
                                                                                src="img/newimg/Rectangle 11116.png"
                                                                                alt=""
                                                                                className="rounded-full w-12 h-12 object-cover  "
                                                                            />
                                                                            <div className="flex-1 w-full">
                                                                                <span className="capitalize text-base font-semibold block text-[#3D405B]">
                                                                                    {element && element.name}
                                                                                </span>
                                                                                <p className="capitalize text-sm  block text-[#3D405B]">
                                                                                    {/* HR Manager - Dept of Human Resources */}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-6 text-base font-medium whitespace-nowrap p-3 text-center text-[#3D405B] ">
                                                                        {element && element.reviewer_name}
                                                                    </td>

                                                                    <td className="px-6 text-base font-medium whitespace-nowrap p-3 text-left text-[#3D405B] ">
                                                                        {
                                                                            PMSGetAppraisalDetailsById && PMSGetAppraisalDetailsById.createdAt ?
                                                                                moment(new Date(parseInt(PMSGetAppraisalDetailsById.createdAt)))
                                                                                    .utcOffset("+05:30")
                                                                                    .format("DD-MMM-YYYY") : ""}
                                                                    </td>

                                                                    <td className="px-6 align-middle text-sm whitespace-nowrap p-3 text-center">
                                                                        {((element && element.status && element.status.reviewStatus && element.status.reviewStatus.pending) + (element && element.status && element.status.reviewStatus && element.status.reviewStatus.completed)) === (element && element.status && element.status.reviewStatus && element.status.reviewStatus.completed) ?
                                                                            <button className='bg-[#FFA000] text-white w-32 px-8 py-2 rounded-md uppercase text-sm font-medium '>In Progress </button>
                                                                            :
                                                                            <button className='border bg-[#4CAF50] text-white w-32 px-8 py-2 rounded-md uppercase text-sm font-medium '>Completed  </button>
                                                                        }

                                                                    </td>
                                                                    <td className='text-center '>
                                                                        <div className='flex justify-center'>
                                                                            <button className=' border border-[#CFD8DC]  rounded-md p-1.5 cursor-pointer text-[#3D405B] hover:text-[#2196F3] hover:bg-[#2196F3]/10 transition-all duration-100'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="22" height="22" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M4 7l16 0"></path>
                                                                                    <path d="M10 11l0 6"></path>
                                                                                    <path d="M14 11l0 6"></path>
                                                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            ) : null}


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>





                            </div>

                            <div id="sidebar-btn" className="fixed z-30 flex items-center justify-center w-10 h-10 text-gray-900 bg-white rounded-full shadow-md hover:bg-blue-500 hover:text-white bottom-8 right-8 lg:hidden">
                                <span className="material-symbols-outlined">view_quilt</span>
                            </div>
                        </div>
                    </div>


                </LoadingOverlay>

            </>

        );
    }
}
function mapStateToProps(state) {
    // ////console.log("state  ", state);
    const { loggingIn } = state.authentication;
    const { users, general, dashboard, survey, questions, happiness, pms } = state;
    return {
        loggingIn,
        users,
        general,
        dashboard,
        survey,
        questions,
        happiness,
        pms
    };
}
export default connect(mapStateToProps)(HappinessDashboard);
