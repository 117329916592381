import React, { Component } from 'react';
import { connect } from 'react-redux';

class YearFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
        // showFilter:false,
        // showValue:'',
        currFilterList:[],
        finalFilter:[],
        searchName:'',

        show1Value:false

    }
  }
  componentDidMount() {

  }

  handleOpen1Value=(value)=>{
    this.setState({show1Value:value})
  }

  handleYearValue=(value)=>{
    let { handleYearValueYear } = this.props;
    handleYearValueYear(value)
  }

  render() {
    let { crrTrendYear, YearList } = this.props;
    let { show1Value } = this.state;


    function sort(list){
      let finalist = list.sort((b,a)=>(a-b))
      return finalist
    }


    return (
      <>
          <div className="flex flex-wrap gap-2">


              <div className="cursor-pointer bg-white text-[#3D405B] hover:text-[#2196f3] transition-all duration-150 border flex justify-between px-4 items-center relative py-1.5 rounded-md space-x-1.5">
                
                <span onClick={()=>this.handleOpen1Value(!show1Value)} className="text-sm font-bold ">{crrTrendYear}</span>
                <span onClick={()=>this.handleOpen1Value(!show1Value)} className="material-icons ">arrow_drop_down</span>

                  {show1Value?
                  <>
                  <div className="absolute z-10 w-24 h-24 space-y-2 overflow-hidden overflow-y-auto font-normal bg-white shadow-lg right-5 top-8 font-xs" id="chatbot">
          
                    {YearList && YearList.length>0?
                      sort(YearList).map((value,index)=>
                     <div className="w-full" onClick={()=>{this.handleYearValue(value);this.handleOpen1Value(!show1Value)}}>
                        <label  className="w-full p-2 text-xs cursor-pointer hover:bg-blue-100">    
                        <input className='mr-2' type="checkbox" checked={(value === crrTrendYear)?true:false}  onChange={()=>{}}/>{value}</label> </div>
                      )
                    :null}
                  </div>
                  </>
                  :null}



              </div>

         </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(YearFilter);
