import React from "react";
export default function ConfirmMail(props) {
  let {  showModal,  onCloseModal,  handleSubmit } = props;
  return (
   <>
   {showModal?
   <>
            <div className="top-0 xl:h-screen h-full  w-full flex justify-center items-center z-40 fixed" style={{background: '#0000005c'}}>
              <div className="bg-white rounded-lg  shadow-sm  xl:w-4/12  lg:w-5/12 md:w-6/12 w-11/12">
                <div className="flex justify-between p-4 border-b"> <h2 className="text-base font-semibold text-center test-gray-800 capitalize">Complete Schedule</h2>
                  <span onClick={()=>onCloseModal(false)} className="cursor-pointer material-symbols-outlined   font-medium text-gray-500 p-1 bg-gray-100 rounded-full hover:bg-sky-100 transtion duration-150">close</span></div>
                <div className="p-4 space-y-4">

                  <div className="flex justify-center items-center ">
                    <button onClick={()=>handleSubmit()} className="w-52 h-10 cursor-pointer bg-green-500 rounded-md text-white py-1 px-2 mx-2">Mark As Completed</button>                  
                  </div>


                </div>

              </div>
            </div>

    </>
   
    :null}
   </>
  );
}
