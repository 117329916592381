
import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuadrantChart from './Charts/QuadrantChart';
// import Filter from './Filter';
import Commentary from '../../../Index/Components/Commentary/Commentary';
// import Filter from './Filter/Filter';
import Filter from "../../../../CommonComponents/Filters/IndexFilter/Filter"


class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      viewScore: true,
      showDropDown1: false,
      showDropDown2: false,
      showFilter: false,
      showValue: '',
      DimensionList: [
        {
          index: 1,
          name: 'Happiness',
          cutOff: 59,
          range: 72,
          type: ""
        },
        {
          index: 2,
          name: 'Engagement',
          cutOff: 78,
          range: 100,
          type: "%"

        },
        {
          index: 4,
          name: 'Stress',
          cutOff: 28,
          range: 60,
          type: "%"

        },
        {
          index: 5,
          name: 'Manager',
          cutOff: 59,
          range: 100,
          type: "%"

        },
        {
          index: 6,
          name: 'Leader',
          cutOff: 50,
          range: 100,
          type: "%"

        },
        // {
        //   index:8,
        //   name:'OrgCore',
        //   cutOff:59
        // },
      ],

      selectedItem1: '',
      selectedItem2: '',


      currentSelectValue: '',
      FilterValues: []



    }
  }
  componentDidMount() {

  }

  handleViewScore = (data) => {
    this.setState({ viewScore: data })
  }




  handleDropdown1 = (check) => {
    this.setState({ showDropDown1: !check, showDropDown2: false })
  }
  handleDropdown2 = (check) => {
    this.setState({ showDropDown2: !check, showDropDown1: false })
  }

  changeItem1 = (item) => {
    this.setState({ selectedItem1: item })
    this.setState({ showDropDown1: false, showDropDown2: false })

  }

  changeItem2 = (item) => {
    this.setState({ selectedItem2: item })
    this.setState({ showDropDown1: false, showDropDown2: false })

  }



  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultiFilterAdvanc4Quadrant } = this.props;
    this.setState({ FilterValues: finalFilter });

    //////////console.log("finalFilter, name",finalFilter, name)
    handleMultiFilterAdvanc4Quadrant(finalFilter, true, name);

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });

  }


  render() {
    let {
      ErrorOtherObj,
      getEMPFilterLabel, getIndexByFilterDMGData, CountRestrict, validateList, indexType, optionType, loading, getCommentData, getIndexData, getDemographicIndexData, EmpFilterData, getIndexFilterData } = this.props;


    let { showDropDown1, showDropDown2, DimensionList, selectedItem1, selectedItem2, currentSelectValue, FilterValues } = this.state;

    // let valueList=[]
    // if(getIndexData){
    //   valueList = Object.keys(getIndexData).map((key) => [ key, getIndexData[key] ]);
    //   //////console.log('valueList: ',valueList)
    // }


    //////console.log('DimensionList: ',DimensionList)
    //////console.log('getDemographicIndexData: ',getDemographicIndexData);




    // let { DimensionList} = this.state;

    // let { validateIndex } = this.props;

    // let validateList = [];
    // let validObj = Object.keys(validateIndex).map((key)=>[key,validateIndex[key]])
    // //////console.log('validObj: ',validObj);
    // if(validObj && validObj.length>0){
    //   validObj.forEach((item)=>{
    //     if(item[1]){
    //       let getIndex = DimensionList.findIndex(prev=>prev.index===parseInt(item[0]));
    //       if(getIndex!==-1?DimensionList[getIndex].name:""){
    //         validateList.push({
    //           "name":getIndex!==-1?DimensionList[getIndex].name:"",
    //           "template":parseInt(item[0])
    //         });
    //       }

    //     }
    //   })
    // }
    // //////console.log('validateList: ',validateList);

    

    function getCuttOff(template, item){
      return (item && item[template]?item[template]:0)
    }

    let DimensionIndex = []
    if (validateList && validateList.length > 0) {
      validateList.forEach((valid) => {
        if (DimensionList && DimensionList.length > 0) {
          DimensionList.forEach((dimen) => {
            if (valid.template === dimen.index) {
              DimensionIndex.push({
                ...dimen,
                cutOff:getCuttOff(valid.template,getIndexData)
              });
            }
          })
        }
      })
    }


    //////console.log('DimensionIndex: ',DimensionIndex)
    //////console.log('validateList: ',validateList)

    let Item1 = '';
    let Item2 = '';
    if (selectedItem1) {
      Item1 = selectedItem1
    }
    else {
      let getIndex1 = DimensionIndex.findIndex(prev => prev.template === selectedItem1.index)
      if (getIndex1 === -1) {
        Item1 = DimensionIndex[0];
      }
      else {
        Item1 = DimensionIndex[getIndex1];
      }
    }


    if (selectedItem2) {
      Item2 = selectedItem2
    }
    else {
      let getIndex2 = DimensionIndex.findIndex(prev => prev.template === selectedItem2.index)
      if (getIndex2 === -1) {
        Item2 = DimensionIndex[0];
      }
      else {
        Item2 = DimensionIndex[getIndex2];
      }

      if (Item1 === Item2) {

        if (getIndex2 === -1) {
          Item2 = DimensionIndex[1];
        }
        else {
          Item2 = DimensionIndex[getIndex2 + 1];
        }

      }
    }



    // selectedItem1:{
    //   index:1,
    //   name:'Happiness',
    //   cutOff:59,
    //   range:72,
    //   type:""
    // },
    // selectedItem2:{
    //   index:2,
    //   name:'Engagement',
    //   cutOff:78,
    //   range:100,
    //   type:"%"
    // },


    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    // let DistributionIndexData = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      //  DistributionIndexDataScore=getIndexDriverData;
      //  DemographicDataDistribution=getIndexDistributionData;
      //  DistributionIndexData = getDistributionIndexData

      // getDistributionIndexData && getIndexDriverData

    }


    return (
      <>
        {/* <div className="items-center justify-between h-10 px-4 py-3 bg-white border-b xl:flex lg:flex md:flex">
        <Filter
                          showFilter={this.state.showFilter}
                          showValue={this.state.showValue}
                          closeFilter={this.closeFilter}

          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexByFilterDMGData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        />
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

      </div> */}
        <div className="items-center justify-between md:flex">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />
          {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
          {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} /> */}



          <div className='flex flex-col gap-4 mt-4 md:flex-row md:mt-0'>
            {(Item1 && Item1.name) && (Item2 && Item2.name) ?
              <div className="flex items-center pr-2 space-x-3 capitalize">

                {Item1 && Item1.name ?
                  <>

                    <div className="  bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center justify-between   font-medium space-x-2 relative md:w-auto w-full">
                      <span onClick={() => this.handleDropdown1(showDropDown1)} className="">{Item1.name}</span>
                      <span onClick={() => this.handleDropdown1(showDropDown1)} className="cursor-pointer material-symbols-outlined">arrow_drop_down</span>

                      {showDropDown1 ?
                        <ul className="absolute z-10 w-full py-2 font-normal bg-white rounded-lg shadow-lg -left-2 md:w-auto top-12 ">
                          {DimensionIndex && DimensionIndex.length > 0 ?
                            DimensionIndex.map((item) =>
                              (selectedItem2.index !== item.index) ?
                                <li onClick={() => this.changeItem1(item)} className="block px-4 p-2 text-sm  text-[#3D405B]  cursor-pointer hover:bg-gray-100">{item.name}</li>
                                : null
                            )
                            : null}
                        </ul>
                        : null}
                    </div>
                  </>
                  : null}



                <p className="font-medium text-[#3D405B]/70">VS</p>


                {Item2 && Item2.name ?
                  <>
                    <div className="  bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center justify-between font-medium space-x-2 relative md:w-auto w-full">
                      <span onClick={() => this.handleDropdown2(showDropDown2)} className="">{Item2.name}</span>
                      <span onClick={() => this.handleDropdown2(showDropDown2)} className="cursor-pointer material-symbols-outlined">arrow_drop_down</span>
                      {showDropDown2 ?
                        <ul className="absolute z-10 w-full py-2 font-normal bg-white rounded-lg shadow-lg -left-2 md:w-auto top-12 ">
                          {DimensionIndex && DimensionIndex.length > 0 ?
                            DimensionIndex.map((item) =>
                              (selectedItem1.index !== item.index) ?
                                <li onClick={() => this.changeItem2(item)} className="block px-4 p-2 text-sm  text-[#3D405B]  cursor-pointer hover:bg-gray-100">{item.name}</li>
                                : null
                            )
                            : null}
                        </ul>
                        : null}

                    </div>
                  </>

                  : null}


              </div>
              : null}
            <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />
          </div>



        </div>



        <div onClick={() => this.closeFilter(false)} className="" >

          <div className="w-full" >

            <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">

              <div className="p-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">

                <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">4 Quadrant Matrix</h1>

                {ErrorOtherObj && ErrorOtherObj["getDemographicIndexError"] ?
                  <>
                    <div className='my-24 text-2xl font-medium text-center text-gray-400'>{ErrorOtherObj["getDemographicIndexError"]}</div>
                  </>
                  : <>
                    {getDemographicIndexData ?
                      <>
                        <div className="relative inline-block px-1 dropdown"><i className="fa fa-pencil"></i></div>


                        {Item2 && Item2.name ?
                          <div className="" style={{ zIndex: '-10' }}>
                            <QuadrantChart getIndexData={getIndexData} CountRestrict={CountRestrict} getDemographicIndexData={getDemographicIndexData} selectedItem1={Item1} selectedItem2={Item2} />
                            {Item1 && Item1.name && Item2 && Item2.name ?
                              <div className="px-2 py-4 text-center"><b>{"X-Axis: "}</b>{Item1.name + "(0-" + Item1.range + Item1.type + ")"}{", "}<b>{"Y-Axis: "}</b>{Item2.name + "(0-" + Item2.range + Item2.type + ")"}</div>
                              : null}

                            <div className="grid justify-center grid-cols-4 px-4 py-4 ml-20">
                              <div className="w-full">
                                <div style={{
                                  float: 'left',
                                  width: '20px',
                                  height: '20px',
                                  background: '#e35454',
                                  marginRight: '10px',
                                  borderRadius: "50%"
                                }}></div>
                                <div style={{
                                }}>Low, Low</div>
                              </div>
                              <div className="w-full">
                                <div style={{ float: 'left', width: '20px', height: '20px', background: '#a76af7', marginRight: '10px', borderRadius: "50%" }}>
                                </div>
                                <div>Low, High</div>
                              </div>

                              <div className="w-full">
                                <div style={{ float: 'left', width: '20px', height: '20px', background: '#548be3', marginRight: '10px', borderRadius: "50%" }}></div>
                                <div style={{}}>High, Low</div>
                              </div>

                              <div className="w-full">
                                <div style={{
                                  float: 'left', width: '20px', height: '20px', background: '#54e371', marginRight: '10px', borderRadius: "50%"
                                }}></div>
                                <div style={{}}>High, High</div>
                              </div>

                            </div>

                          </div>
                          : null}

                      </>
                      : <>
                        <div className='my-24 text-2xl font-medium text-center text-gray-400'>{"Data Not Available"}</div>
                      </>}

                  </>}

              </div>
            </div>

          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
