import React, { Component } from 'react';
import { connect } from 'react-redux';

class ResponseMetaTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { getResponseOverall, getResponseValue, getMetaResponseAnalyticsFilterData, getMetaRespondantDetailsData, litsXAxis } = this.props;



    return (
      <>
        <div className="w-full p-4 mb-4 overflow-hidden overflow-x-auto bg-white whitespace-nowrap">
          <table className="w-full border ">
            <thead>
              <tr className="text-sm text-gray-800 bg-gray-100 border-b bg-blue-50">
                <th className="px-2 py-2 font-bold text-left border-r">{litsXAxis}</th>
                <th className="px-2 py-2 font-bold border-r">Sent</th>
                <th className="px-2 py-2 font-bold border-r">Clicked</th>
                <th className="px-2 py-2 font-bold border-r">Not Clicked</th>
                <th className="px-2 py-2 font-bold border-r">Completed</th>
                <th className="px-2 py-2 font-bold border-r">Not Completed</th>
                <th className="px-2 py-2 font-bold border-r">Response Rate(%)</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-sm text-gray-800 border-b">
                <td className="w-4/12 px-2 py-2 font-medium border-r"><div className="text-left whitespace-pre-line" >{"Overall"}</div></td>
                <td className="w-1/12 px-2 py-2 font-medium border-r"><div className="text-center whitespace-pre-line" >{getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "mailSent")}</div></td>
                <td className="w-1/12 px-2 py-2 font-medium border-r"><div className="text-center whitespace-pre-line" >{getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "clicked")}</div></td>
                <td className="w-1/12 px-2 py-2 font-medium border-r"><div className="text-center whitespace-pre-line" >{getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "notclicked")}</div></td>
                <td className="w-1/12 px-2 py-2 font-medium border-r"><div className="text-center whitespace-pre-line" >{getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "completed")}</div></td>
                <td className="w-1/12 px-2 py-2 font-medium border-r"><div className="text-center whitespace-pre-line" >{getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "notcompleted")}</div></td>
                <td className="w-1/12 px-2 py-2 font-medium border-r"><div className="text-center whitespace-pre-line" >{getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "responserate") + "%"}</div></td>
              </tr>
              {getMetaResponseAnalyticsFilterData && getMetaResponseAnalyticsFilterData.length > 0 ?
                getMetaResponseAnalyticsFilterData.map((element, index) =>
                  <tr key={index} className="text-sm text-gray-800 border-b">
                    <td className="w-4/12 px-2 py-2 font-medium border-r"><div className="text-left whitespace-pre-line" >{element && element.name ? element.name : "-"}</div></td>
                    <td className="w-1/12 px-2 py-2 font-medium border-r"><div className="text-center whitespace-pre-line" >{getResponseValue(element.data, "mailSent")}</div></td>
                    <td className="w-1/12 px-2 py-2 font-medium border-r"><div className="text-center whitespace-pre-line" >{getResponseValue(element.data, "clicked")}</div></td>
                    <td className="w-1/12 px-2 py-2 font-medium border-r"><div className="text-center whitespace-pre-line" >{getResponseValue(element.data, "notclicked")}</div></td>
                    <td className="w-1/12 px-2 py-2 font-medium border-r"><div className="text-center whitespace-pre-line" >{getResponseValue(element.data, "completed")}</div></td>
                    <td className="w-1/12 px-2 py-2 font-medium border-r"><div className="text-center whitespace-pre-line" >{getResponseValue(element.data, "notcompleted")}</div></td>
                    <td className="w-1/12 px-2 py-2 font-medium border-r"><div className="text-center whitespace-pre-line" >{getResponseValue(element.data, "responserate") + "%"}</div></td>
                  </tr>
                ) : null}

            </tbody>
          </table>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ResponseMetaTable);
