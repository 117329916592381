import React from "react";
export default function UserAccessModal(props) {
  let { addUserModal, handleOpenLevel, handleOpenValue, selectDMGLevel, selectDMGValue, handleScreenAcess, giveAccessSubmit
    , selectedEmployeeData, showUserDmgLevel, showUserDmgValue, DMGLevelList, finalDemoList, AccessObj, AccessList, ComponentList, DemographicLevels,
    DemographicValues, searchName, handleSearch, type, removeAccessModal, getSurveyListByRoleData, crrSelectedSurveyList, selectSurveys, isUpdate } = props;

  let [showSurveyDrop, handleSurveyDrop] = React.useState(false);

  console.log('ComponentList: ', ComponentList);


  return (
    <>
      <div className="fixed h-screen top-0 left-0 w-full flex justify-center xl:items-center z-40 xl:py-0 py-2 bg-black bg-opacity-20"  >
        <div className="bg-white rounded-md shadow-sm xl:w-6/12 md:w-9/12 w-11/12 ">
          {/* add user */}
          <div className="flex justify-between items-center p-4 border-b">
            <h1 className="text-lg font-semibold">{"Create Access"}</h1>
            <span onClick={() => addUserModal(false)} className="cursor-pointer material-symbols-outlined p-2 bg-gray-50   hover:bg-gray-100 transtion duration-150 rounded-full" >close</span></div>

          <div className="md:h-96 h-80 overflow-y-auto overflow-hidden customscroll4">

            {/* selected user */}
            <div className="flex items-center p-4 border-b"><p className="text-sm font-medium">User :-</p><p className="text-sm pl-4">{selectedEmployeeData && selectedEmployeeData.name ? selectedEmployeeData.name + " (Emp Id: " + selectedEmployeeData.employeeID + ")" : "Emp Id: " + selectedEmployeeData.employeeID}</p></div>
            {/* demograohical access */}



           
            {/* success */}
            <div className="p-4" onClick={() => {
              handleOpenLevel(false);
              handleOpenValue(false);
            }}>
              <h1 className="pb-4 text-sm font-medium">Module Access</h1>
              <div className="grid md:grid-cols-3 grid-cols-2 gap-4   text-gray-500 md:text-sm text-xs">
                {AccessList && AccessList.length > 0 ?
                  AccessList.map((item) =>
                    <div className="flex items-center ">
                      <label className={item.value === "viewSurvey" ? "text-red-500  cursor-pointer" : " cursor-pointer"}>
                        <input className="mr-2" checked={AccessObj && AccessObj[item.value] ? true : false}
                          type="checkbox" onChange={() => handleScreenAcess(!(AccessObj && AccessObj[item.value]) ? true : false, item.value)} />  {item.label}</label></div>
                  )
                  : null}
              </div>
            </div>
            {/* end */}


            {/* success */}
            <div className="p-4" onClick={() => {
              handleOpenLevel(false);
              handleOpenValue(false);
            }}>
              <h1 className="pb-4 text-sm font-medium">Component Access</h1>
              <div className="grid md:grid-cols-3 grid-cols-2 gap-4   text-gray-500 md:text-sm text-xs">
                {ComponentList && ComponentList.length > 0 ?
                  ComponentList.map((item) =>
                    <div className="flex items-center ">
                      <label className={item.value === "viewSurvey" ? "text-red-500  cursor-pointer" : " cursor-pointer"}>
                        <input className="mr-2" checked={AccessObj && AccessObj[item.value] ? true : false}
                          type="checkbox" onChange={() => handleScreenAcess(!(AccessObj && AccessObj[item.value]) ? true : false, item.value)} />  {item.label}</label></div>
                  )
                  : null}
              </div>
            </div>
            {/* end */}


            <div className="border-b p-4">
              <h1 className="font-medium text-sm pb-4">Survey Access</h1>
              <div className="grid  md:grid-cols-3 grid-cols-2  md:gap-6 gap-4  py-2 text-xs font-medium text-gray-500">


              <div className="flex items-center justify-between border rounded-md p-2 relative">
                  <div onClick={() => handleSurveyDrop(!showSurveyDrop)} className="w-full cursor-pointer"><span >Surveys</span>
                    <span className="text-blue-500 pl-1 text-sm font-normal">{crrSelectedSurveyList && crrSelectedSurveyList.length > 0 ? crrSelectedSurveyList.length : 0}</span></div>
                  <span onClick={() => handleSurveyDrop(!showSurveyDrop)} className="cursor-pointer material-symbols-outlined">arrow_drop_down</span>


                  {showSurveyDrop ?
                    <div className="absolute top-10 border z-20 shadow-xl w-full right-0 bg-white p-4 font-normal 
                    text-gray-500 h-44 overflow-hidden overflow-y-auto lang  ">
                      {getSurveyListByRoleData && getSurveyListByRoleData.length > 0 ?
                        getSurveyListByRoleData.map((survey, index) =>
                          <div key={index} className="flex items-center py-2"><input type="checkbox"
                            checked={crrSelectedSurveyList.some(prev => prev === survey.id) ? true : false}
                            onClick={() => {
                              selectSurveys(survey);
                              handleSurveyDrop(false)
                            }} onChange={() => { }} />
                            <label className="ml-2">{survey.name}</label></div>
                        )
                        : null}

                    </div>
                    : null}
                </div>


              </div>
              </div>


            <div className="border-b p-4">
              <h1 className="font-medium text-sm pb-4">Demographic Access</h1>
              <div className="grid  md:grid-cols-3 grid-cols-2  md:gap-6 gap-4  py-2 text-xs font-medium text-gray-500">
                {/* drop 1 */}


                <div className="flex items-center justify-between border rounded-md p-2 relative">
                  <div onClick={() => handleOpenLevel(!showUserDmgLevel)} className="w-full cursor-pointer"><span >Demographic</span>
                    <span className="text-blue-500 pl-1 text-sm font-normal">{DMGLevelList && DMGLevelList.length > 0 ? DMGLevelList.length : 0}</span></div>
                  <span onClick={() => handleOpenLevel(!showUserDmgLevel)} className="cursor-pointer material-symbols-outlined">arrow_drop_down</span>
                  {/* drop down */}

                  {showUserDmgLevel ?
                    <div className="absolute top-10 border z-20 shadow-xl w-full right-0 bg-white p-4 font-normal 
                text-gray-500 h-44 overflow-hidden overflow-y-auto lang  ">
                      {DemographicLevels && DemographicLevels.length > 0 ?
                        DemographicLevels.map((value, index) =>
                          <div key={index} className="flex items-center py-2"><input type="checkbox"
                            checked={DMGLevelList.some(prev => prev === value) ? true : false}
                            onClick={() => selectDMGLevel(value)} onChange={() => { }} />
                            <label className="ml-2">{value}</label></div>
                        )
                        : null}

                    </div>
                    : null}
                </div>


                {DMGLevelList && DMGLevelList.length > 0 ?
                  DMGLevelList.map((item) =>
                    <div className="flex items-center justify-between border rounded-md p-2 relative">
                      <div onClick={() => handleOpenValue(item)} className="w-full cursor-pointer"><span >{item}</span>
                        <span className="text-blue-500 pl-1 text-sm font-normal">{(finalDemoList && finalDemoList[item] && finalDemoList[item].length > 0) ? finalDemoList[item].length : 0}</span></div>
                      <span onClick={() => handleOpenValue(item)} className="cursor-pointer material-symbols-outlined">arrow_drop_down</span>
                      {/* drop down */}

                      {showUserDmgValue === item ?
                        <div className="absolute top-10 border z-20 shadow-xl w-full right-0 bg-white p-4 font-normal 
                text-gray-500 h-44 overflow-hidden overflow-y-auto lang  ">
                          <input type="text" name="searchName" value={searchName} onChange={handleSearch} placeholder="Search" className="w-full border p-1.5 rounded-sm focus:outline-none text-gray-500 mb-2 focus:ring-blue-500 focus:ring-1" />

                          {DemographicValues && DemographicValues[item] && DemographicValues[item].length > 0 ?
                            DemographicValues[item].map((value, index) =>
                              value && value.match(new RegExp(`${searchName}`, 'gi')) ?
                                <div className="flex items-center py-2"><input type="checkbox"
                                  checked={(finalDemoList && finalDemoList[item] && finalDemoList[item].length > 0) ? finalDemoList[item].some(prev => prev === value) ? true : false : false}
                                  onClick={() => selectDMGValue(value, item)} onChange={() => { }} />
                                  <label onClick={() => selectDMGValue(value, item)} className="ml-2">{value}</label></div>
                                : null)
                            : null}

                        </div>
                        : null}
                    </div>
                  ) : null}





              </div>
            </div>
         

          </div>
          <div className="p-4 md:flex justify-between border-t md:space-y-0 space-y-6">
            {type === "UPDATE" ?
              <>

                <button onClick={() => removeAccessModal(false)} className="text-sm flex items-center text-red-500  font-medium"><span className="material-symbols-outlined mr-2">speaker_notes_off</span> Clear access </button>
              </>
              : null}

            <div className="flex items-center md:justify-end justify-center space-x-4">
              <button onClick={() => removeAccessModal(true)} className="text-md  capitalize text-gray-500 border   py-2 px-4 focus:outline-none rounded-md">Delete</button>

              <button onClick={() => giveAccessSubmit()} className="cursor-pointer bg-blue-600 rounded-md text-white px-4 p-2 capitalize">Assign</button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
