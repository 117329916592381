import React, { useState } from 'react';

export default function CreateCampaign(props) {

  let { formData, list, selectedItem } = props;
  let [showDropdown, handleDropdown] = useState(false)


  return (
    <>

      <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer">
        <span className="w-full pr-4 text-sm font-medium text-slate-800" onClick={() => handleDropdown(!showDropdown)}>{
          formData && formData.name ? formData.name : "Select"
        }</span>
        <span className="text-gray-500 material-symbols-outlined" onClick={() => handleDropdown(!showDropdown)}>arrow_drop_down</span>

        {showDropdown ?
          <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
            {list && list.length > 0 ?
              list.map((item, index) =>
                (item.id !== formData.id) ?
                  <span key={index} onClick={() => { selectedItem(item); handleDropdown(!showDropdown); }}
                    className={(formData.id === item.id) ? "inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b bg-gray-100" :
                      "inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b hover:bg-gray-100"}>
                    {item.name}</span>
                  : null
              )
              : null}
          </div>
          : null}
      </div>

    </>
  );
}
