import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';


export default function Reminder360Campaign(props) {
  let { getCampaignDetailsByIdData,
    reminderformData, handleReminderInput, handleReminderMessageInput, getCampaignEmployeesByIDData, actionList, handleReminderSubmit, SelectAllEmployees, selectAllEmp, showReminderEmployeeFilter, handleEmpReminderDropdown, empSelectRemindFilter,
    handleEmpRemindFilterSelect, LanguageObj, CurrentLanguage, handle360DisableReminder } = props;


  let IsAutomatic = false;
  let IsAutomaticStatus = false;
  let reminderDate = []
  if (getCampaignDetailsByIdData && getCampaignDetailsByIdData && Number(getCampaignDetailsByIdData.reminderOption) === 1) {
    IsAutomatic = true;
    IsAutomaticStatus = getCampaignDetailsByIdData && getCampaignDetailsByIdData.reminderActive ? true : false;
    reminderDate = getCampaignDetailsByIdData && getCampaignDetailsByIdData.reminderDate && getCampaignDetailsByIdData.reminderDate.length > 0 ? getCampaignDetailsByIdData.reminderDate : [];
  }

  //console.log("getCampaignEmployeesByIDData--1----->", getCampaignEmployeesByIDData)

  function getLabelName(value, type) {
    if (value && value["relationId"] && value["relationId"] && value["relationId"][type]) {
      return value["relationId"][type]
    } else {
      return ""
    }
  }

  return (

    <div className="flex flex-col w-full gap-6 p-4 lg:flex-row md:p-6">

      <div className="bg-white border rounded-md lg:w-[50%] w-full h-full">
        <div className='xl:text-xl text-lg font-medium text-[#3D405B] py-4 md:px-6 px-4 border-b flex justify-between items-center flex-row gap-4 '>
          <h2 className="text-xl font-medium bg-white">{LanguageObj && LanguageObj["reminder_campaign_" + CurrentLanguage] ? LanguageObj["reminder_campaign_" + CurrentLanguage] : "Campaign Reminder"}</h2>
        </div>
        <div className="grid grid-cols-1 gap-4 p-4 overflow-y-auto md:px-6 md:grid-cols-2" >
          <div className='space-y-1 md:col-span-2 '>
            <label className="font-medium text-gray-900 text-md">{LanguageObj && LanguageObj["campaign_title_" + CurrentLanguage] ? LanguageObj["campaign_title_" + CurrentLanguage] : "Campaign Name"}</label><br />
            <input disabled={true} type="text" name="title" value={reminderformData.title} onChange={handleReminderInput} placeholder={LanguageObj && LanguageObj["campaign_title_" + CurrentLanguage] ? LanguageObj["campaign_title_" + CurrentLanguage] : "Campaign Name"} className="text-sm text-[#212121] outline-none border p-2 w-full rounded" />
          </div>

          <div className='space-y-1 '>
            <label className="font-medium text-gray-900 text-md">Campaign Reminder Type</label><br />
            <div className="text-sm text-[#212121] outline-none border p-2 py-1.5 w-full rounded flex justify-between cursor-pointer  items-center relative">
              <span className="w-full pr-4 text-xs font-semibold text-slate-800" onClick={() => handleEmpReminderDropdown(showReminderEmployeeFilter)}>{
                reminderformData.actionId === 2 ? LanguageObj && LanguageObj["reminder_to_incomplete_" + CurrentLanguage] ? LanguageObj["reminder_to_incomplete_" + CurrentLanguage] : reminderformData.action : reminderformData.action
              }</span>
              <span className="text-gray-500 material-symbols-outlined" onClick={() => handleEmpReminderDropdown(showReminderEmployeeFilter)}>expand_more</span>

              {showReminderEmployeeFilter ?
                <div className="absolute left-0 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
                  {actionList && actionList.length > 0 ?
                    actionList.map((item, index) =>
                      (item.id !== reminderformData.actionId) ?
                        <span onClick={() => handleEmpRemindFilterSelect(item)} className={(empSelectRemindFilter.template === item.type) ? "inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100" : "inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}>
                          {item.type}</span>
                        : null
                    )
                    : null}
                </div>
                : null}
            </div>
          </div>


          <div className='space-y-1 '>
            <label className="font-medium text-gray-900 text-md">{"Reminder Type"}</label><br />
            <input disabled={true} type="text" name="subject" value={IsAutomatic ? "AUTOMATIC" : "MANUAL"} onChange={() => { }} placeholder="Reminder Type" className="text-sm text-[#212121] outline-none border p-2 w-full rounded" />
          </div>


          {IsAutomatic ?
            <>
              <label className="font-medium text-gray-900 text-md">{"Reminder Dates"}</label><br />
              <textarea disabled={true} type="text" name="reminderDate" placeholder="Reminder Dates"
                className="text-sm text-gray-900 outline-none border-b py-1.5 w-full px-1 font-medium" >
                {reminderDate.map((element) => (element)).join(", ")}
              </textarea>
            </>
            : null}


          <div className='space-y-1 '>
            <label className="font-medium text-gray-900 text-md">{LanguageObj && LanguageObj["email_subject_" + CurrentLanguage] ? LanguageObj["email_subject_" + CurrentLanguage] : "Email Subject"}</label><br />
            <input type="text" name="subject" value={reminderformData.subject} onChange={handleReminderInput} placeholder="Email Subject" className="text-sm text-[#212121] outline-none border p-2 w-full rounded" />

          </div>

          <div className='space-y-1 '>
            <label className="font-medium text-gray-900 text-md">{LanguageObj && LanguageObj["email_name_" + CurrentLanguage] ? LanguageObj["email_name_" + CurrentLanguage] : "Email Name"}</label><br />
            <input type="text" name="emailName" value={reminderformData.emailName} onChange={handleReminderInput} placeholder="Email Name" className="text-sm text-[#212121] outline-none border p-2 w-full rounded" />
          </div>

          <div className='space-y-1 md:col-span-2 '>
            <label className="font-medium text-gray-900 text-md">{LanguageObj && LanguageObj["email_message_" + CurrentLanguage] ? LanguageObj["email_message_" + CurrentLanguage] : "Email Message"}</label><br />
            <div className="overflow-y-auto" >
              <ReactQuill className="w-full" theme="snow" value={reminderformData.message} onChange={handleReminderMessageInput} placeholder="Message" />
            </div>
          </div>

          <div className='space-y-1 '>
            <label className="font-medium text-gray-900 text-md">{LanguageObj && LanguageObj["team_name_" + CurrentLanguage] ? LanguageObj["team_name_" + CurrentLanguage] : "Team Name"}</label><br />
            <input type="text" name="teamname" value={reminderformData.teamname} onChange={handleReminderInput} placeholder={LanguageObj && LanguageObj["team_name_" + CurrentLanguage] ? LanguageObj["team_name_" + CurrentLanguage] : "Team Name"} className="text-sm text-[#212121] outline-none border p-2 w-full rounded" />
          </div>


          <div className='space-y-1 '>
            <label className="font-medium text-gray-900 text-md">{LanguageObj && LanguageObj["created_date_" + CurrentLanguage] ? LanguageObj["created_date_" + CurrentLanguage] : "Campaign Date	"}</label><br />
            <input disabled={true} type="text" name="teamname" value={moment.unix(reminderformData.createdAt / 1000).format("DD-MM-YYYY, HH:mm")} onChange={handleReminderInput} placeholder="Campaign Date" className="text-sm text-[#212121] outline-none border p-2 w-full rounded" />
          </div>

          <div className="flex justify-start py-6">
            <button className="bg-[#2196F3] text-white   py-3 px-6   text-base  justify-center  flex items-center uppercase font-medium rounded-md" onClick={() => handleReminderSubmit()}>{LanguageObj && LanguageObj["send_reminder_" + CurrentLanguage] ? LanguageObj["send_reminder_" + CurrentLanguage] : "SEND REMINDER"}<span className="px-2 fa fa-paper-plane" /></button>
          </div>
        </div>
      </div>

      <div className="bg-white border  rounded-md lg:w-[50%] w-full h-full">
        <div className='xl:text-xl text-lg font-medium text-[#3D405B] py-4 md:px-6 px-4 border-b flex justify-start items-center flex-row gap-2 '>
          <h2 className="text-xl font-medium bg-white">{LanguageObj && LanguageObj["employees_" + CurrentLanguage] ? LanguageObj["employees_" + CurrentLanguage] : "Employees"}</h2>
          <p className="text-base font-medium ">
            ( {
              reminderformData.actionId === 2 ? LanguageObj && LanguageObj["reminder_to_incomplete_" + CurrentLanguage] ? LanguageObj["reminder_to_incomplete_" + CurrentLanguage] : reminderformData.action : reminderformData.action
            } )</p>

        </div>

        <div className="px-4 py-4 overflow-x-hidden overflow-y-auto"  >
          <div className="flex flex-col ">
            <div className="overflow-auto lang">
              <div className=" whitespace-nowrap">
                <table className="w-full border">
                  <thead>
                    <tr className="bg-white">

                      <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121]  ">
                        Reviewer Name</th>
                      <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121]  ">
                        Reviewer Email</th>
                      <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121]  ">
                        Reviewer emp_id</th>
                      <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121]  ">
                        Reviewee emp_id</th>
                      <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121]  ">
                        Reviewer Relation</th>
                      <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121]  ">
                        Relation Type</th>
                      <th className="px-6 py-3 border-b  text-left text-xs leading-4 font-semibold tracking-wider  text-[#212121]  ">
                        Send Mail</th>

                    </tr>


                  </thead>
                  <tbody className="bg-white">

                    {getCampaignEmployeesByIDData && getCampaignEmployeesByIDData.length > 0 ?
                      getCampaignEmployeesByIDData.map((item, index) =>
                        <>
                          {(reminderformData.actionId === 2 && !item.isComplete) ?
                            <>
                              <tr>
                                <td className="px-6 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-blue-500">{getLabelName(item, "reviewer_name")}</div>
                                </td>
                                <td className="px-6 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-blue-500">{getLabelName(item, "reviewer_email")}</div>
                                </td>
                                <td className="px-6 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-blue-500">{getLabelName(item, "reviewer_emp_id")}</div>
                                </td>
                                <td className="px-6 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-blue-500">{getLabelName(item, "reviewee_emp_id")}</div>
                                </td>
                                <td className="px-6 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-gray-500">{getLabelName(item, "reviewer_relation")}</div>
                                </td>
                                <td className="px-6 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-gray-500">{getLabelName(item, "relation_type")}</div>
                                </td>
                                <td className='text-center border-b'>
                                  <div className='flex justify-center cursor-pointer'>
                                    {!reminderformData["disableList"].some(prev => prev === item.id) ?
                                      <>
                                        <div onClick={() => handle360DisableReminder(item.id)} className="rounded-full w-11 h-5 p-0.5 bg-green-400">
                                          <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform translate-x-3 bg-white rounded-full" />
                                        </div>
                                      </>
                                      :
                                      <>

                                        <div onClick={() => handle360DisableReminder(item.id)} className="rounded-full w-11 h-5 p-0.5 bg-gray-200">
                                          <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform -translate-x-3 bg-white rounded-full" />
                                        </div>
                                      </>
                                    }
                                  </div>
                                </td>
                              </tr>

                            </>
                            :

                            null}


                          {(reminderformData.actionId === 3 && !item.isMailSent) ?
                            <>

                              <tr>
                                <td className="px-6 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-blue-500">{getLabelName(item, "reviewer_name")}</div>
                                </td>
                                <td className="px-6 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-blue-500">{getLabelName(item, "reviewer_email")}</div>
                                </td>
                                <td className="px-6 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-blue-500">{getLabelName(item, "reviewer_emp_id")}</div>
                                </td>
                                <td className="px-6 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-blue-500">{getLabelName(item, "reviewee_emp_id")}</div>
                                </td>
                                <td className="px-6 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-gray-500">{getLabelName(item, "reviewer_relation")}</div>
                                </td>
                                <td className="px-6 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-gray-500">{getLabelName(item, "relation_type")}</div>
                                </td>
                                <td className='text-center'>
                                  <div className='flex justify-center cursor-pointer'>
                                    {!item.isDisable ?
                                      <>
                                        <div onClick={() => handle360DisableReminder(item.id)} className="rounded-full w-11 h-5 p-0.5 bg-green-400">
                                          <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform translate-x-3 bg-white rounded-full" />
                                        </div>
                                      </>
                                      :
                                      <>

                                        <div onClick={() => handle360DisableReminder(item.id)} className="rounded-full w-11 h-5 p-0.5 bg-gray-200">
                                          <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform -translate-x-3 bg-white rounded-full" />
                                        </div>
                                      </>
                                    }
                                  </div>
                                </td>
                              </tr>




                              {/* <tr>
                          <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 ">
                              </div>
                              <div className="ml-4">
                                <div className="text-xs leading-5 text-gray-500">{(item && item.employeeId.name?item.employeeId.name:'Not Available').slice(0,15)}</div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="text-xs leading-5 text-gray-500">{item.employeeId.email}</div>
                          </td>
                          <td className="px-6 py-4 text-xs font-medium text-gray-500 whitespace-no-wrap border-b border-gray-200">
                          {item && item.employeeId.mobile?item.employeeId.mobile:'Not Available'}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium leading-5 text-center whitespace-no-wrap border-b border-gray-200" >
                            {item && item.employeeId.isDisable?
                            <span className="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">InActive</span>
                            :
                            <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">Active</span>
                            }
                          </td>
                          </tr> */}
                            </>
                            : null}
                        </>

                      )
                      : null}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>

  );
}
