import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import Header from "../Header/Header";
import Sidebar from '../Sidebar/Sidebar';
import { CiFilter } from "react-icons/ci";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { GoGoal } from "react-icons/go";
import { VscFeedback } from "react-icons/vsc";
import { LiaUserCogSolid } from "react-icons/lia";
import { AiOutlineClose } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { TiDocumentText } from "react-icons/ti";
import { TbEdit } from "react-icons/tb";
import { pmsActions } from '../../../_actions';
import DatePicker from "react-datepicker";
import moment from 'moment'

import "react-datepicker/dist/react-datepicker.css";
function PeerGoalList(props) {

    const [useid, setid] = useState('');
    const [empid, setempid] = useState('');

    useEffect(() => {

        let id = props.match.params.id;
        let idc = props.match.params.idc;

        if (id && idc) {
            setid(id);
            setempid(idc);
        }



        let data = {
            "appraisalCycleId": id,
            "emp_id": idc
        }
        props.dispatch(pmsActions.GetEmpPMSManagerGoalList(data));
        props.dispatch(pmsActions.PMSGetAppraisalDetailsById({ "id": data && data.appraisalCycleId }));


        let dataX = {
            "appraisalCycleId": props.match.params.id,
            "moduleName": "Goal"
        }
        props.dispatch(pmsActions.getPMSProcessSetting(dataX))


        let data1 = {
            "emp_id": props.match.params.idc
        }
        props.dispatch(pmsActions.pmsGetPeerDetails(data1));
        setid(props.match.params.id)

        
        
        let data221 = {
            "appraisalCycleId": props.match.params.id,
            "emp_id":[props.match.params.idc],
            "level":2
        }
        props.dispatch(pmsActions.getPeersGoalStatus(data221));


    }, [])



    let { pms } = props;
    let { getPMSProcessSetting, getPeersGoalStatus, goaladdedsucess, PMSGetAppraisalDetailsById, GetEmpPMSGoalList, GetEmpPMSTaskList, getMyPeersList, getPeersGoalList, getPeersListNew, pmsGetPeerDetails, pmsGetEmpDetails } = pms;

    const [startDate, setStartDate] = useState();
    const [isAddGoal, setIsAddGoal] = useState(false);
    const [isAddTask, setIsAddTask] = useState(false);
    const [usetasksuccess, settasksuccess] = useState(false);
    // State to track the active tab
    const [activeTab, setActiveTab] = useState('review');
    const [activeTabOne, setActiveTabOne] = useState('Review');
    const [activeTabTow, setActiveTabTwo] = useState('Review');
    const [innertab, setinnertab] = useState("mypeer");
    const [usePriority, SetPriority] = useState('All');


    const handleinnertab = (innertab1) => {
        // Set the active tab based on the clicked tab
        if (innertab1 === "mypeer") {
            props.dispatch(pmsActions.getMyPeersList({
                reviewer_relation: ['Peer']
            }))

        } else if (innertab1 === "myemployee") {
            props.dispatch(pmsActions.getMyPeersList({
                reviewer_relation: ['Manager']
            }))

        }

        setinnertab(innertab1);
    };





    const handleTabClick = (tab) => {
        let emp_id = props.match.params.idc;
        if (useid && emp_id) {
            if (tab === "review") {
                let data2x = {
                    "appraisalCycleId": useid,
                    "emp_id": [emp_id],
                    "level": 2
                }
                props.dispatch(pmsActions.getPeersGoalStatus(data2x));
                setActiveTab(tab);
            } else if (tab === "preview") {
                let data2x = {
                    "appraisalCycleId": useid,
                    "emp_id": [emp_id],
                    "level": 1
                }
                props.dispatch(pmsActions.getPeersGoalStatus(data2x));
            }
        }
        setActiveTab(tab);
    };


    const handleTabReviewStart = () => {
        let emp_id = props.match.params.idc;
        if (useid && emp_id) {
            props.history.push(`/app/pmsemployeepeerreview/${useid}/${emp_id}`);
        }
    };
    const handleTabPREVIEWStart = () => {
        let emp_id = props.match.params.idc;
        if (useid && emp_id) {
            props.history.push(`/app/pmsemployeepreview/${useid}/${emp_id}`);
        }
    };






    let initialtask2 = {

    }
    let [formErrors, setFormErrors] = useState(initialtask2)
    let initialtask3 = {

    }
    let [taskErrors, settaskErrors] = useState(initialtask3)



    const [pageCurrentList, setCurrentPage] = useState(true);


    const [isUpdateGoal, setIsUpdateGoal] = useState(false);
    const [useGoalTaskid, setGoalTaskid] = useState('');
    const [getgoalid, setgoalid] = useState("");


    let initialvalue = {
        goalName: '',
        priority: '',
        startDate: '',
        desc: '',
        weightage: '',
        endDate: '',
        progress: '',

    }
    let initialtask = {

        priority: '',
        taskName: '',
        desc: ''
    }
    const [useTaskForm, setTaskForm] = useState(initialtask);



    const [isUpdateAddTask, setisUpdateAddTask] = useState(false)
    const [isUpdateAdddata, setisUpdateAdddata] = useState('')
    const [useFormdata, setFormdata] = useState(initialvalue);
    const [useUpdateForm, setupdateform] = useState();
    const [updatetasklist, setupdatelist] = useState('');
    const [useenddate, setEnddate] = useState('');
    const [message, setMessage] = useState(false);


    const handleAddGoal = () => {
        setIsAddGoal(!isAddGoal);
    };
    const handleCloseAddGoal = () => {
        setIsAddGoal(false);
        setStartDate('')
        setEnddate('')
        setFormdata(initialvalue)
        setFormErrors({
            goalName: '',

            priority: '',
            startDate: '',
            desc: '',
            weightage: '',
            endDate: '',
            progress: '',
        })

    };
    const handleupdateClose = () => {
        setIsUpdateGoal(false);
        setStartDate('')
        setEnddate('')
    };

    const handleAddTask = () => {
        setIsAddTask(!isAddTask);
        // setGoalTaskid(value && value.id)

    };
    const handleCloseAddTask = () => {
        setIsAddTask(false);
        setTaskForm(initialtask)
        settaskErrors({
            taskErrors: '',
            priority: '',

            desc: '',

        })
    };
    const validateForm = () => {
        let isValid = true;

        const errors = {
            goalName: '',

            priority: '',
            startDate: '',
            desc: '',
            weightage: '',
            endDate: '',
            progress: '',
        };

        if (useFormdata.goalName.trim() === '') {
            errors.goalName = 'GoalName is required';
            isValid = false;
        }
        if (useFormdata.priority.trim() === '') {
            errors.priority = 'Select Priority';
            isValid = false;
        }
        if (useFormdata.desc.trim() === '') {
            errors.desc = 'Please enter goal description';
            isValid = false;
        }
        if (useFormdata.startDate.trim() === '') {
            errors.startDate = 'Select start date';
            isValid = false;
        }
        if (useFormdata.endDate.trim() === '') {
            errors.endDate = 'Select end date';
            isValid = false;
        }
        if (useFormdata.progress.trim() === '') {
            errors.progress = 'Progress';
            isValid = false;
        }
        if (useFormdata.weightage.trim() === '') {
            errors.weightage = 'weightage';
            isValid = false;
        }



        console.log(errors, "errors");
        setFormErrors(errors);

        return isValid;
    };
    const submitform = () => {


        if (validateForm()) {
            let empid = props.match.params.idc;
            let data = {
                ...useFormdata, 'appraisalCycleId': useid, 'emp_id': empid
            }
            let taskid = useid
            props.dispatch(pmsActions.AddEmpPMSGoal(data, props, taskid, 2))
            setIsAddGoal(false)
            setStartDate('')
            setEnddate('')
            setMessage(true)

            setFormdata(initialvalue)
        } else {

        }
    };



    let udateappenddata = { ...useUpdateForm }
    delete udateappenddata['createdAt'];
    // delete udateappenddata['isComplete'];

    const updateFormdata = (udateappenddata) => {
        let emp_id = props.match.params.idc;


        let data = {
            "appraisalCycleId": useid,
            "id": udateappenddata.id,
            "goalName": udateappenddata.goalName,
            "startDate": udateappenddata.startDate,
            "endDate": udateappenddata.endDate,
            "priority": udateappenddata.priority,
            "desc": udateappenddata.desc,
            "progress": udateappenddata.progress,
            "weightage": udateappenddata.weightage,
            "emp_id": emp_id
            // ...udateappenddata, 'appraisalCycleId': useid,
        }
        props.dispatch(pmsActions.UpdateEmpPMSGoal(data, useid, props, 2))
        setIsUpdateGoal(false)

    }

    const newSetgaolid = (value) => {
        setgoalid(value && value.id)
        setIsUpdateGoal(!isUpdateGoal)

        setupdateform(value)

    }

    const viewTasklist = (item) => {

        props.dispatch(pmsActions.GetEmpPMSTaskList({ "goalId": item && item.id }))

        setCurrentPage(false)
        setGoalTaskid(item && item.id)
    }


    let updatetasklists = (value) => {
        setupdatelist(value && value.id)
        setisUpdateAddTask(true)
        setisUpdateAdddata(value)
        // let date={...useTaskFormUpdate,"goalId": useGoalTaskid}
        //     props.dispatch(pmsActions.UpdateEmpPMSTask(date))
    }
    let submitListdataupdate = () => {


        let data = { "desc": isUpdateAdddata && isUpdateAdddata.desc ? isUpdateAdddata.desc : '', 'priority': isUpdateAdddata && isUpdateAdddata.priority ? isUpdateAdddata.priority : '', 'taskName': isUpdateAdddata && isUpdateAdddata.taskName ? isUpdateAdddata.taskName : '', "goalId": useGoalTaskid, "id": updatetasklist }
        let taskid = useGoalTaskid
        props.dispatch(pmsActions.UpdateEmpPMSTask(data, props, taskid))
        setisUpdateAddTask(!isUpdateAddTask)

    }
    // console.log(pmsGetPeerDetails,'pmsGetPeerDetails');



    const handleChangeDates = (valued) => {
        var value = moment(valued).format('DD-MM-YYYY');
        setStartDate(valued)
        setupdateform({ ...useUpdateForm, ['startDate']: value })
        setFormdata({ ...useFormdata, ['startDate']: value })
        setFormErrors({
            ...formErrors,
            ['startDate']: '',
        });

    }

    const handleChangeDates2 = (valued) => {
        var value = moment(valued).format('DD-MM-YYYY');
        setEnddate(valued)
        setupdateform({ ...useUpdateForm, ['endDate']: value })
        setFormdata({ ...useFormdata, ['endDate']: value })
        setFormErrors({
            ...formErrors,
            ['endDate']: '',
        });
    }



    let initList = GetEmpPMSGoalList && GetEmpPMSGoalList.length > 0 ?
        GetEmpPMSGoalList.map((ele) => ({
            goalName: ele.goalName,
            desc: ele.desc,
            priority: ele.priority,
            id: ele.id,
            startDate: ele.startDate,
            endDate: ele.endDate,
            weightage: ele.weightage

        })) : []

    function getPrevValue(initList, item, field) {


        let value = "";
        if (initList && initList.length > 0) {
            let index = initList.findIndex(prev => prev.id === item.id);
            if (index !== -1) {
                value = initList && initList[index] && initList[index][field] ? initList[index][field] : ""
            }
        }
        return value
    }
    let formdatain = (e) => {


        const { name, value } = e.target;
        setFormdata({
            ...useFormdata,
            [name]: value,
        });
        setFormErrors({
            ...formErrors,
            [name]: '',
        });

    };

    let taskonchange = (e) => {
        let { name, value } = e.target

        setTaskForm({ ...useTaskForm, [name]: value })
        settaskErrors({
            ...taskErrors,
            [name]: '',
        });
    }

    const validateTask = () => {
        let isValid = true;

        const errors = {
            taskName: '',

            priority: '',

            desc: '',

        };

        if (useTaskForm.taskName.trim() === '') {
            errors.taskName = 'Task Name is required';
            isValid = false;
        }
        if (useTaskForm.priority.trim() === '') {
            errors.priority = 'Select Priority';
            isValid = false;
        }
        if (useTaskForm.desc.trim() === '') {
            errors.desc = 'Please enter task description';
            isValid = false;
        }




        console.log(errors, "errors");
        settaskErrors(errors);

        return isValid;
    };
    const submitListdata = () => {




        if (validateTask()) {
            let data = { ...useTaskForm, "goalId": useGoalTaskid }
            let taskid = useGoalTaskid
            props.dispatch(pmsActions.AddEmpPMSTask(data, props, taskid))
            setIsAddTask(!isAddTask);
            setTaskForm(initialtask)
        } else {

        }


    }


    if (goaladdedsucess && message) {

        setTimeout(() => {

            setMessage(false);

        }, 2000);

    }



    let isActive = false;
    if (PMSGetAppraisalDetailsById && PMSGetAppraisalDetailsById.active) {
        isActive = PMSGetAppraisalDetailsById.active;
    }

    console.log("getPMSProcessSetting------->", getPMSProcessSetting);


    function isComponentActive(rank) {
        let isActive = false;
        if (getPMSProcessSetting && getPMSProcessSetting.length > 0) {
            let getIndex = getPMSProcessSetting.findIndex(prev => prev.rank === rank);
            if (getIndex !== -1) {
                isActive = getPMSProcessSetting[getIndex]["isActive"];
            }
        }
        return isActive
    }
    

    return (
        <>
            <div className="h-screen overflow-hidden bg-gray-50">
                <Header />

                {goaladdedsucess && message ?
                    <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative" role="alert">
                        <strong className="font-bold">Goal successfully Added</strong>

                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                            <svg
                                className="fill-current h-6 w-6 text-blue-500"
                                role="button"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                            </svg>
                        </span>
                    </div>

                    : null}

                <div className="flex w-full" style={{ height: "calc(100% - 2rem)" }}>
                    <Sidebar />

                    {pageCurrentList ?
                        <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden">

                            <div className=' p-6 py-4 bg-white   rounded-md ' >
                                <div className='text-[#3D405B]   flex items-center space-x-2 '>
                                    <button onClick={() => props.history.push(`/app/pmsemployeegoals/${useid}`)} className="p-2 duration-200 rounded-md cursor-pointer hover:bg-slate-100">
                                        <MdOutlineArrowBackIos className="w-5 h-5" /> </button>
                                    <img src="https://media.istockphoto.com/id/1391365592/photo/beautiful-afro-woman.jpg?s=612x612&amp;w=0&amp;k=20&amp;c=o4M2f5nPzpU3ipnjK6jZF6xRNCFem_kz51IcRFGZ6qs=" className="object-cover w-12 h-12 rounded-full" />
                                    <h2 className='text-xl font-semibold'>For {pmsGetPeerDetails && pmsGetPeerDetails[0] && pmsGetPeerDetails[0].name}</h2>
                                </div>


                            </div>

                            {
                                activeTabOne === 'Review' &&
                                <>
                                    <div className="bg-white">
                                        <div className="px-6 border-b flex justify-between whitespace-nowrap overflow-x-auto">
                                            <ul className="flex flex-row gap-6">


                                                <li>
                                                    <div className={`relative block p-4 px-2 font-medium ${activeTab === 'review' ? 'font-medium text-[#009EA7]' : 'text-[#212121]/70'}`} onClick={() => handleTabClick('review')}>
                                                        <span className={`absolute inset-x-0 w-full bg-[#009EA7] h-[3px] -bottom-px ${activeTab === 'review' ? '' : 'hidden'}`} />
                                                        <div className="flex items-center justify-center gap-2 cursor-pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none"  >
                                                                <path d="M15 7.5L16.7925 10.9716L20.625 11.5284L17.8125 14.0625L18.75 17.8125L15 15.7031L11.25 17.8125L12.1875 14.0625L9.375 11.5284L13.3125 10.9716L15 7.5Z" fill="currentColor" />
                                                                <path d="M16.6275 28.125L15 27.1875L18.75 20.625H24.375C24.6213 20.6254 24.8653 20.5771 25.093 20.483C25.3206 20.3889 25.5275 20.2508 25.7017 20.0767C25.8758 19.9025 26.0139 19.6956 26.108 19.468C26.2021 19.2403 26.2504 18.9963 26.25 18.75V7.5C26.2504 7.25367 26.2021 7.00968 26.108 6.78203C26.0139 6.55438 25.8758 6.34753 25.7017 6.17335C25.5275 5.99917 25.3206 5.86107 25.093 5.76697C24.8653 5.67288 24.6213 5.62463 24.375 5.625H5.625C5.37867 5.62463 5.13468 5.67288 4.90703 5.76697C4.67938 5.86107 4.47253 5.99917 4.29835 6.17335C4.12417 6.34753 3.98607 6.55438 3.89197 6.78203C3.79788 7.00968 3.74963 7.25367 3.75 7.5V18.75C3.74963 18.9963 3.79788 19.2403 3.89197 19.468C3.98607 19.6956 4.12417 19.9025 4.29835 20.0767C4.47253 20.2508 4.67938 20.3889 4.90703 20.483C5.13468 20.5771 5.37867 20.6254 5.625 20.625H14.0625V22.5H5.625C4.63044 22.5 3.67661 22.1049 2.97335 21.4016C2.27009 20.6984 1.875 19.7446 1.875 18.75V7.5C1.87488 7.00751 1.97179 6.51982 2.1602 6.06479C2.34861 5.60977 2.62483 5.19632 2.97308 4.84808C3.32132 4.49983 3.73477 4.22361 4.18979 4.0352C4.64482 3.84679 5.13251 3.74988 5.625 3.75H24.375C24.8675 3.74988 25.3552 3.84679 25.8102 4.0352C26.2652 4.22361 26.6787 4.49983 27.0269 4.84808C27.3752 5.19632 27.6514 5.60977 27.8398 6.06479C28.0282 6.51982 28.1251 7.00751 28.125 7.5V18.75C28.125 19.7446 27.7299 20.6984 27.0266 21.4016C26.3234 22.1049 25.3696 22.5 24.375 22.5H19.8422L16.6275 28.125Z" fill="currentColor" />
                                                            </svg>
                                                            <span className="text-sm">Review</span>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                    {activeTab === 'review' &&
                                        <>
                                            <div className="md:p-6 p-4 mx-auto overflow-hidden overflow-y-auto space-y-4 h-[calc(100vh-12rem)] ">
                                                <div className="flex items-center justify-between">
                                                    <h1 className="md:text-2xl text-xl text-[#212121] font-semibold">Review</h1>
                                                    <div className="flex items-center space-x-3 ">
                                                        <div className="flex items-center justify-between px-2 space-x-2 overflow-hidden text-sm bg-white border rounded-sm">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search text-gray-600" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>  <path d="M21 21l-6 -6"></path> </svg>
                                                            <input type="search" name="search" className="w-full py-2 md:w-48 w-full focus:outline-none placeholder:text-[#212121]/50" placeholder="Search" />
                                                        </div>

                                                        <div className="flex justify-center md:justify-start">
                                                            <button className="w-full px-2 py-2 text-center text-white transition-all duration-200 transform bg-white border rounded-sm cursor-pointer md:w-auto text-md"  >
                                                                <CiFilter className="w-5 h-5 text-gray-600" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="overflow-hidden border border-gray-200 rounded-sm ">
                                                    <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse">
                                                        <thead className="bg-white">
                                                            <tr>
                                                                <th scope="col" className="px-6 py-3 font-semibold text-base text-[#3D405B] text-left">Appraisal Cycle Name</th>
                                                                {/* <th scope="col" className="px-6 py-3 font-semibold text-base text-[#3D405B] text-center">Reviewed On</th> */}
                                                                {/* <th scope="col" className="px-6 py-3 font-semibold text-base text-[#3D405B] text-center">Rating</th> */}
                                                                <th scope="col" className="px-6 py-3 font-semibold text-base text-[#3D405B] text-center">Review status</th>
                                                                <th scope="col" className="px-6 py-3 font-semibold text-base text-[#3D405B] text-left">Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody className="border-t border-gray-100 divide-y divide-gray-100">
                                                            <tr className="hover:bg-gray-50">
                                                                <td className="px-6 py-4 items-center justify-center">{PMSGetAppraisalDetailsById && PMSGetAppraisalDetailsById.name ? PMSGetAppraisalDetailsById.name : ""}</td>
                                                                {/* <td className="px-6 py-4 items-center justify-center">Yet to Review</td> */}
                                                                {/* <td className="px-6 py-4 items-center justify-center">{getPeersGoalStatus && getPeersGoalStatus.isComplete?"Already Reviewed":"To be Reviewed"}</td> */}
                                                                <td className="px-6 py-4 items-center justify-center">
                                                                    <div className="flex items-center justify-center">
                                                                        <div className='relative '>
                                                                            <img src="img/newimg/Rectangle 11116.png" alt="" className="rounded-full w-14 h-14" />
                                                                            <span className='absolute bottom-0 right-0 flex items-center justify-center w-6 h-6 text-white rounded-full bg-amber-500 '>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clock-hour-4" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                                                                    <path d="M12 12l3 2"></path>
                                                                                    <path d="M12 7v5"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                </td>
                                                                <td className="px-6 py-4 items-center justify-center">

                                                                    {isActive && isComponentActive(true?4:7) ?
                                                                        (getPeersGoalStatus && getPeersGoalStatus[0] && getPeersGoalStatus[0].isComplete ?
                                                                            <div class="text-start flex justify-start space-x-3">
                                                                                <button type="button" class="uppercase font-medium text-sm text-green-500 border border-green-500 w-24 py-2 px-2 rounded focus:outline-none focus:shadow-outline">Completed</button>
                                                                            </div> :
                                                                            <div class="text-start flex justify-start space-x-3">
                                                                                <button onClick={() => handleTabReviewStart()} type="button" class="uppercase font-medium text-sm text-[#009EA7] border border-[#009EA7] w-24 py-2 px-2 rounded focus:outline-none focus:shadow-outline">Review</button>
                                                                            </div>)
                                                                        :
                                                                        <div class="text-start flex justify-start space-x-3">
                                                                            <button type="button" class="uppercase font-medium text-sm text-green-500 border border-green-500 w-24 py-2 px-2 rounded focus:outline-none focus:shadow-outline">Closed</button>
                                                                        </div>
                                                                    }


                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                    }


                                </>
                            }


                        </main>
                        :
                        <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden">


                            <div className="bg-white ">
                                <div className="px-6 py-4 h-[64px] border flex justify-between">
                                    <div className="flex items-center space-x-4 text-[#212121]">
                                        <button onClick={() => setCurrentPage(true)} className="p-2 duration-200 rounded-md cursor-pointer hover:bg-slate-100">
                                            <MdOutlineArrowBackIos className="w-5 h-5" /> </button>

                                        <h1 className=" text-2xl font-semibold">{PMSGetAppraisalDetailsById && PMSGetAppraisalDetailsById.name ? PMSGetAppraisalDetailsById.name : ''}</h1>

                                        <div>
                                            <p className='text-base'>Submission End Date: {moment(PMSGetAppraisalDetailsById && PMSGetAppraisalDetailsById.createdAt).format("DD-MM-YYYY")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="md:p-6 p-4 mx-auto overflow-hidden overflow-y-auto space-y-4 h-[calc(100vh-12rem)] ">
                                <div className="flex items-center justify-between">
                                    <h1 className="md:text-2xl text-xl text-[#212121] font-semibold">Tasks</h1>
                                    <div className="flex items-center space-x-3 ">
                                        <div className="flex items-center justify-between px-2 space-x-2 overflow-hidden text-sm bg-white border rounded-sm">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search text-gray-600" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>  <path d="M21 21l-6 -6"></path> </svg>
                                            <input type="search" name="search" className="w-full py-2 md:w-48 w-full focus:outline-none placeholder:text-[#212121]/50" placeholder="Search" />
                                        </div>

                                        <div className="flex justify-center md:justify-start">
                                            <button className="w-full px-2 py-2 text-center text-white transition-all duration-200 transform bg-white border rounded-sm cursor-pointer md:w-auto text-md"  >
                                                <CiFilter className="w-5 h-5 text-gray-600" />
                                            </button>
                                        </div>

                                        <div className="flex justify-center md:justify-start">
                                            <button onClick={handleAddTask} className="bg-[#18afb8] hover:bg-[#18afb8] transition-all duration-200 md:w-auto w-full md:py-2 py-3  px-4 rounded-sm text-center justify-center cursor-pointer transform  flex items-center text-white space-x-1 md:text-sm text-md">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path><path d="M9 12l6 0"></path><path d="M12 9l0 6"></path></svg>
                                                <h1 className>Add Task</h1>
                                            </button>
                                        </div>
                                    </div>
                                </div>



                                {GetEmpPMSTaskList && GetEmpPMSTaskList.length > 0 ?

                                    <div className='grid gap-6 xl:grid-cols-4 lg:grid-cols-2'>
                                        {GetEmpPMSTaskList.map((item, index) =>
                                            <div class="p-6 bg-white rounded-md">
                                                <h4 class="text-xl font-medium pb-2">{item && item.taskName}</h4>
                                                <p class="my-1 text-justify">{item && item.desc}</p>
                                                <div className='flex pt-5 items-center '>
                                                    <button onClick={() => updatetasklists(item)} type="button" class="bg-[#009EA7]/10 py-2 px-2  rounded focus:outline-none focus:shadow-outline ">
                                                        <TbEdit className='w-6 h-6 text-[#009EA7]/70' />
                                                    </button>
                                                </div>
                                            </div>)}

                                    </div>

                                    : <>
                                        <div className="">
                                            <div className="flex flex-col items-center justify-center p-6 py-10 space-y-10 text-center bg-white border rounded-md">
                                                <img src="./img/overview/empty_survey.png" />
                                                <div className="space-y-2">
                                                    <h1 className="text-3xl font-medium text-[#212121]">No Task Added Yet!</h1>
                                                    <p className="text-[#3D405B]/70 text-lg">Looks like no task has been initiated yet.</p>
                                                    <div className="flex justify-center pt-4">
                                                        <button onClick={handleAddTask} className="bg-[#18afb8] hover:bg-[#18afb8] transition-all duration-200 md:w-auto w-full md:py-2 py-3  px-4 rounded-sm text-center justify-center cursor-pointer transform  flex items-center text-white space-x-1 md:text-sm text-md">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path><path d="M9 12l6 0"></path><path d="M12 9l0 6"></path></svg>
                                                            <h1 className>Add A New Task</h1>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>}


                            </div>


                        </main>

                    }
                </div>
            </div>


            {isAddGoal && (
                <div className="fixed inset-0 z-40 flex items-center justify-center w-full h-full mt-0 overflow-hidden overflow-y-auto bg-black/40">
                    <div className="bg-[#fff] model mx-auto rounded-lg shadow-lg lg:w-2/6 w-full overflow-y-auto">

                        <div className="flex items-center justify-between p-6 py-3 border-b bg-dark">
                            <p className="font-semibold text-[#212121] text-lg ">Add Goal</p>
                            <div className="rounded-lg cursor-pointer modal-close bg-[#F1F1F1] p-1 text-[#212121]/70" onClick={handleCloseAddGoal}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M18 6l-12 12"></path>
                                    <path d="M6 6l12 12"></path>
                                </svg>
                            </div>
                        </div>

                        <div className="p-6 space-y-4">
                            <div className='space-y-1'>
                                <lable className="text-sm text-[#212121]/70 font-medium ">Goal Name</lable>
                                <input name="goalName" onChange={(e) => formdatain(e)} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring-1" placeholder="Enter Goal Name" />
                                {formErrors.goalName ? (
                                    <div className="text-xs text-red-600 invalid-feedback">
                                        {formErrors.goalName}
                                    </div>
                                ) : null}
                            </div>



                            <div className='flex flex-row gap-4'>
                                <div className='space-y-1 w-full'>
                                    <lable className="text-sm text-[#212121]/70 font-medium  ">Start Date</lable>
                                    <div className=" w-full flex justify-between  border bg-white focus:outline-none focus:ring transition-all duration-150 ease-linear px-3 py-2 ">
                                        <DatePicker
                                            className=" text-sm  w-full  outline-none flex-1 cursor-pointer "
                                            name="startDate"
                                            selected={startDate}
                                            placeholderText={'DD-MM-YYYY'}

                                            autoComplete="off"
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date()}

                                            onChange={(date) => handleChangeDates(date)}
                                        />
                                        <svg

                                            xmlns="http://www.w3.org/2000/svg" className="cursor-pointer  text-[#212121]/70 font-medium   icon icon-tabler icon-tabler-calendar-event" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                            <path d="M16 3l0 4"></path>
                                            <path d="M8 3l0 4"></path>
                                            <path d="M4 11l16 0"></path>
                                            <path d="M8 15h2v2h-2z"></path>
                                        </svg>

                                    </div>
                                    {formErrors.startDate ? (
                                        <div className="text-xs text-red-600 invalid-feedback">
                                            {formErrors.startDate}
                                        </div>
                                    ) : null}
                                    {/* <input name="startDate" onChange={(e) => setupdateform({ ...useUpdateForm, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring"
                                    value={useUpdateForm && useUpdateForm.startDate ? useUpdateForm.startDate : ''} /> */}
                                </div>
                                <div className='space-y-1 w-full'>
                                    <lable className="text-sm text-[#212121]/70 font-medium  ">End Date</lable>
                                    <div className=" w-full flex justify-between  border bg-white focus:outline-none focus:ring transition-all duration-150 ease-linear px-3 py-2 ">
                                        <DatePicker
                                            className=" text-sm  w-full  outline-none flex-1 cursor-pointer "
                                            name="endDate"
                                            selected={useenddate}
                                            placeholderText={'DD-MM-YYYY'}

                                            autoComplete="off"
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date()}

                                            onChange={(date) => handleChangeDates2(date)}
                                        />
                                        <svg

                                            xmlns="http://www.w3.org/2000/svg" className="cursor-pointer  text-[#212121]/70 font-medium   icon icon-tabler icon-tabler-calendar-event" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                            <path d="M16 3l0 4"></path>
                                            <path d="M8 3l0 4"></path>
                                            <path d="M4 11l16 0"></path>
                                            <path d="M8 15h2v2h-2z"></path>
                                        </svg>

                                    </div>
                                    {formErrors.endDate ? (
                                        <div className="text-xs text-red-600 invalid-feedback">
                                            {formErrors.endDate}
                                        </div>
                                    ) : null}
                                </div>

                            </div>
                            <div className='space-y-1'>
                                <lable className="text-sm text-[#212121]/70 font-medium  ">Priority</lable>
                                <select defaultValue={"new"} name="priority" onChange={(e) => formdatain(e)} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring-1">
                                    <option disabled={true} value="new">
                                        Select Goal priority
                                    </option>
                                    <option value="High">High</option>

                                    <option value="Medium">Medium</option>

                                    <option value="Low">Low</option>

                                </select>
                                {formErrors.priority ? (
                                    <div className="text-xs text-red-600 invalid-feedback">
                                        {formErrors.priority}
                                    </div>
                                ) : null}
                            </div>
                            <div className='space-y-1'>
                                <lable className="text-sm text-[#212121]/70 font-medium ">Description</lable>
                                <textarea name="desc" onChange={(e) => formdatain(e)} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring-1" placeholder="Enter Goal Description" />
                                {formErrors.desc ? (
                                    <div className="text-xs text-red-600 invalid-feedback">
                                        {formErrors.desc}
                                    </div>
                                ) : null}
                            </div>
                            <div className='space-y-1'>
                                <lable className="text-sm text-[#212121]/70 font-medium ">Progress % (A goal is complete when progress is 100%)</lable>
                                <input type='number' name="progress" onChange={(e) => formdatain(e)} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring-1" placeholder="0" />
                                {formErrors.progress ? (
                                    <div className="text-xs text-red-600 invalid-feedback">
                                        {formErrors.progress}
                                    </div>
                                ) : null}
                            </div>
                            <div className='space-y-1'>
                                <lable className="text-sm text-[#212121]/70 font-medium ">Weightage %</lable>
                                <input type='number' name="weightage" onChange={(e) => formdatain(e)} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring-1" placeholder="0" />
                                {formErrors.weightage ? (
                                    <div className="text-xs text-red-600 invalid-feedback">
                                        {formErrors.weightage}
                                    </div>
                                ) : null}
                            </div>
                            <div className='pb-4'>
                                <div onClick={() => submitform()} className="text-center text-white w-full px-4 py-3 cursor-pointer text-base uppercase transition-all duration-150 ease-linear bg-[#009EA7] border focus:outline-none focus:ring-1 font-semibold rounded-md">Submit </div>
                            </div>

                        </div>

                    </div>
                </div>
            )
            }

            {isUpdateGoal && (
                <div className="fixed inset-0 z-40 flex items-center justify-center w-full h-full mt-0 overflow-hidden overflow-y-auto bg-black/40">
                    <div className="bg-[#fff] model mx-auto rounded-lg shadow-lg lg:w-2/6 w-full overflow-y-auto">

                        <div className="flex items-center justify-between p-6 py-3 border-b bg-dark">
                            <p className="font-medium text-black">Update Goal</p>

                            <div className="rounded-lg cursor-pointer modal-close bg-[#F1F1F1] p-1" onClick={() => handleupdateClose()}>
                                <AiOutlineClose className='w-6 h-6 text-gray-600' />
                            </div>
                        </div>

                        <div className="p-4 space-y-4">
                            <div className='space-y-1'>
                                <lable className="text-sm text-[#212121]/70">Goals Name</lable>
                                <input name="goalName" onChange={(e) => setupdateform({ ...useUpdateForm, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring"
                                    value={useUpdateForm && useUpdateForm.goalName ? useUpdateForm.goalName : ''} />
                            </div>
                            <div>



                            </div>
                            <div className='space-y-1'>
                                <lable className="text-sm text-[#212121]/70">Start Date</lable>
                                <div className=" w-[60%]">
                                    <DatePicker
                                        className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring"

                                        name="startDate"
                                        selected={startDate}
                                        placeholderText={useUpdateForm && useUpdateForm.startDate ? useUpdateForm.startDate : ''}
                                        selectsStart
                                        startDate={startDate}

                                        autoComplete="off"
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}

                                        onChange={(date) => handleChangeDates(date)}
                                    />
                                </div>
                                {/* <input name="startDate" onChange={(e) => setupdateform({ ...useUpdateForm, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring"
                                    value={useUpdateForm && useUpdateForm.startDate ? useUpdateForm.startDate : ''} /> */}
                            </div>
                            <div className='space-y-1'>
                                <lable className="text-sm text-[#212121]/70">End Date</lable>
                                <div className=" w-[60%]">
                                    <DatePicker
                                        className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring"

                                        name="endDate"
                                        selected={useenddate}
                                        placeholderText={useUpdateForm && useUpdateForm.endDate ? useUpdateForm.endDate : ''}
                                        selectsStart
                                        startDate={useenddate}

                                        autoComplete="off"
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}

                                        onChange={(date) => handleChangeDates2(date)}
                                    />
                                    {/* <input name="endDate" onChange={(e) => setupdateform({ ...useUpdateForm, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring"
                                    value={useUpdateForm && useUpdateForm.endDate ? useUpdateForm.endDate : ''}
                                /> */}
                                </div>
                            </div>
                            <div className='space-y-1'>
                                <lable className="text-sm text-[#212121]/70">Priority</lable>
                                <select defaultValue={"new"} name="priority" onChange={(e) => setupdateform({ ...useUpdateForm, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring">
                                    <option disabled={true} value="new">
                                        {useUpdateForm && useUpdateForm.priority ? useUpdateForm.priority : ''}
                                    </option>
                                    <option value="High">High</option>

                                    <option value="Medium">Medium</option>

                                    <option value="Low">Low</option>

                                </select>
                                {/* <input name="priority" type='' onChange={(e) => setupdateform({ ...useUpdateForm, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring"
                                    value={useUpdateForm && useUpdateForm.priority ? useUpdateForm.priority : ''}
                                /> */}
                            </div>
                            <div className='space-y-1'>
                                <lable className="text-sm text-[#212121]/70">Description</lable>
                                <input name="desc" onChange={(e) => setupdateform({ ...useUpdateForm, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring resize-none h-24 overflow-y-auto"
                                    value={useUpdateForm && useUpdateForm.desc ? useUpdateForm.desc : ''}
                                />
                            </div>
                            <div className='space-y-1'>
                                <lable className="text-sm text-[#212121]/70">Progress % (A goal is complete when progress is 100%)</lable>
                                <input name="progress" onChange={(e) => setupdateform({ ...useUpdateForm, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder={useUpdateForm && useUpdateForm.progress ? useUpdateForm.progress : "progress"} />
                            </div>
                            <div className='space-y-1'>
                                <lable className="text-sm text-[#212121]/70">Weightage %</lable>
                                <input name="weightage" onChange={(e) => setupdateform({ ...useUpdateForm, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring"
                                    value={useUpdateForm && useUpdateForm.weightage ? useUpdateForm.weightage : ''}
                                />
                            </div>
                            <div className='pb-4'>
                                <div onClick={() => updateFormdata(udateappenddata)} className="text-center text-white w-full px-3 py-2 text-sm uppercase transition-all duration-150 ease-linear bg-[#009EA7] border focus:outline-none focus:ring">Submit</div>
                            </div>

                        </div>

                    </div>
                </div>
            )
            }


            {
                isAddTask && (
                    <div className="fixed inset-0 z-40 flex items-center justify-center w-full h-full mt-0 overflow-hidden overflow-y-auto bg-black/40">
                        <div className="bg-[#fff] model mx-auto rounded-lg shadow-lg lg:w-2/6 w-full overflow-y-auto">

                            <div className="flex items-center justify-between p-6 py-3 border-b bg-dark">
                                <p className="font-medium text-black">Add Task</p>
                                <div className="rounded-lg cursor-pointer modal-close bg-[#F1F1F1] p-1" onClick={handleCloseAddTask}>
                                    <AiOutlineClose className='w-6 h-6 text-gray-600' />
                                </div>
                            </div>

                            <div className="p-4 space-y-4">
                                <div className='space-y-1'>
                                    <lable className="text-sm text-[#212121]/70">Task Name</lable>
                                    <input name='taskName' onChange={(e) => taskonchange(e)} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="Enter Task Name" />
                                    {taskErrors.taskName ? (
                                        <div className="text-xs text-red-600 invalid-feedback">
                                            {taskErrors.taskName}
                                        </div>
                                    ) : null}
                                </div>
                                <div className='space-y-1'>
                                    <lable className="text-sm text-[#212121]/70">Priority</lable>
                                    <select defaultValue={"new"} name="priority" onChange={(e) => taskonchange(e)} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring">
                                        <option disabled={true} value="new">
                                            Select Task Priority
                                        </option>
                                        <option value="High">High</option>

                                        <option value="Medium">Medium</option>

                                        <option value="Low">Low</option>

                                    </select>

                                    {taskErrors.priority ? (
                                        <div className="text-xs text-red-600 invalid-feedback">
                                            {taskErrors.priority}
                                        </div>
                                    ) : null}     {/* <input name='priority' onChange={(e) => setTaskForm({ ...useTaskForm, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="Enter Task Priority" /> */}
                                </div>

                                <div className='space-y-1'>
                                    <lable className="text-sm text-[#212121]/70">Description</lable>
                                    <textarea row={5} name="desc" onChange={(e) => taskonchange(e)} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="Enter Task Description" />
                                    {taskErrors.desc ? (
                                        <div className="text-xs text-red-600 invalid-feedback">
                                            {taskErrors.desc}
                                        </div>
                                    ) : null}
                                </div>
                                <div className='pb-4'>
                                    <div onClick={() => submitListdata()} className="text-center text-white w-full px-3 py-2 text-sm uppercase transition-all duration-150 ease-linear bg-[#009EA7] border focus:outline-none focus:ring">Submit</div>
                                </div>
                            </div>

                        </div>
                    </div>
                )
            }
            {
                isUpdateAddTask && (
                    <div className="fixed inset-0 z-40 flex items-center justify-center w-full h-full mt-0 overflow-hidden overflow-y-auto bg-black/40">
                        <div className="bg-[#fff] model mx-auto rounded-lg shadow-lg lg:w-2/6 w-full overflow-y-auto">

                            <div className="flex items-center justify-between p-6 py-3 border-b bg-dark">
                                <p className="font-medium text-black">Update Task</p>
                                <div className="rounded-lg cursor-pointer modal-close bg-[#F1F1F1] p-1" onClick={() => setisUpdateAddTask(!isUpdateAddTask)}>
                                    <AiOutlineClose className='w-6 h-6 text-gray-600' />
                                </div>
                            </div>

                            <div className="p-4 space-y-4">
                                <div className='space-y-1'>
                                    <lable className="text-sm text-[#212121]/70">Task Name</lable>
                                    <input name='taskName' onChange={(e) => setisUpdateAdddata({ ...isUpdateAdddata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" value={isUpdateAdddata && isUpdateAdddata.taskName ? isUpdateAdddata.taskName : ""} />
                                </div>
                                <div className='space-y-1'>
                                    <lable className="text-sm text-[#212121]/70">Priority</lable>
                                    <select defaultValue={"new"} name="priority" onChange={(e) => setisUpdateAdddata({ ...isUpdateAdddata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring">
                                        <option disabled={true} value="new">
                                            {isUpdateAdddata && isUpdateAdddata.priority ? isUpdateAdddata.priority : ""}
                                        </option>
                                        <option value="High">High</option>

                                        <option value="Medium">Medium</option>

                                        <option value="Low">Low</option>

                                    </select>
                                    {/* <input name='priority' onChange={(e) => setisUpdateAdddata({ ...isUpdateAdddata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" value={isUpdateAdddata && isUpdateAdddata.priority ? isUpdateAdddata.priority : ""} /> */}
                                </div>
                                {/* <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">End Date</lable>
                  <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="DD-MM-YYYY" />
                </div> */}
                                <div className='space-y-1'>
                                    <lable className="text-sm text-[#212121]/70">Description</lable>
                                    <textarea row={5} name="desc" onChange={(e) => setisUpdateAdddata({ ...isUpdateAdddata, [e.target.name]: e.target.value })} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" value={isUpdateAdddata && isUpdateAdddata.desc ? isUpdateAdddata.desc : ""} />
                                </div>
                                <div className='pb-4'>
                                    <div onClick={() => submitListdataupdate()} className="text-center text-white w-full px-3 py-2 text-sm uppercase transition-all duration-150 ease-linear bg-[#009EA7] border focus:outline-none focus:ring">Submit</div>
                                </div>
                            </div>

                        </div>
                    </div>
                )
            }

        </>
    );
}

function mapStateToProps(state) {
    //////console.log("state  ", state);
    const { loggingIn } = state.authentication;
    const { survey, dashboard, pms } = state;
    return {
        loggingIn,
        survey,
        dashboard,
        pms
    };
}
export default connect(mapStateToProps)(PeerGoalList);