import React, { Component } from "react";
import moment from "moment";
// import Header from "../Components/Header";

export default class ReportIntro extends Component {

  render() {

    let { surveyName, createdAt } = this.props;

    return (
      <>
        <div class="flex items-center justify-between border-b py-4 mx-5 text-[#212121]/80 mb-2 mt-3">
          <p class="font-medium italic text-base ">{surveyName}</p>
          <p class="font-medium italic text-base">{'Overall | '+createdAt}</p>
        </div>
      </>
    );
  }
}
