import React, { Component } from 'react';
import { connect } from 'react-redux';
// import IndexKPI from './Components/IndexKPI';
// import ReactSpeedometer from "react-d3-speedometer";
// import Happiness from './OverIndex/HappinessOverview/Happiness';
// import Engagement from './OverIndex/EngagementOverview/Engagement';
// import Stress from './OverIndex/StressOverview/Stress';
// import Manager from './OverIndex/Manager';
// import QuestionCard from './Components/QuestionTypeCards/QuestionCard';
// import ScoreGauge from './Components/ScoreGauge';
// import CustomScore from './Components/CustomScore';
// import OverviewNPSZeroToTen from './Components/NPSCardZeroToTen/OverviewNPSZeroToTen';
import SurveyReport from '../../Dashboard/Overview/SurveyReport/SurveyReport';
import ResponseHeader from '../../CommonComponents/ResponseHeader';
import Summary from './Summary/Summary';
import RankTop5Talents from './RankTop5Talents/RankTop5Talents';
import RankTalents from './RankTalents/RankTalents';
import { CSVLink } from "react-csv";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { RiArrowDropDownLine } from "react-icons/ri";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerDetailsIndexType: 0,
      showManagerList: true,
      CurrentManagerName: 'Select Manager',
      optionType: 1,

      showOverview: true,

      allIndexList: [
        {
          "name": "Happiness",
          "template": 1,
          "score": "NaN",
          "type": "Score",
          "color": "#FF4081",
          "tailwindcolor": "bg-pink-100 text-pink-500 hover:bg-pink-500",
          "image": "/img/overview_assets/with_circle/happiness-index.png"
        },
        {
          "name": "Engagement",
          "template": 2,
          "score": "NaN",
          "type": "Percentage",
          "color": "#2196F3",
          "tailwindcolor": "bg-blue-100 text-blue-500 hover:bg-blue-500",
          "image": "/img/overview_assets/with_circle/engagement-index.png"

        },
        {
          "name": "Hope",
          "template": 3,
          "score": "NaN",
          "type": "Percentage",
          "color": "#009688",
          "tailwindcolor": " bg-green-100 text-green-500 hover:bg-green-500",
          "image": "/img/overview_assets/with_circle/hope-index.png"
        },
        {
          "name": "Stress",
          "template": 4,
          "score": "NaN",
          "type": "Score",
          "color": "#EF5350",
          "tailwindcolor": " bg-red-100 text-red-500 hover:bg-red-500",
          "image": "/img/overview_assets/with_circle/stress-index.png"
        },
        {
          "name": "Manager",
          "template": 5,
          "score": "NaN",
          "type": "Percentage",
          "color": "#9C27B0",
          "tailwindcolor": "bg-purple-100 text-purple-500 hover:bg-purple-500",
          "image": "/img/overview_assets/with_circle/manager-index.png"

        },
        {
          "name": "Leadership",
          "template": 6,
          "score": "NaN",
          "type": "Percentage",
          "color": "#FFB300",
          "tailwindcolor": " bg-yellow-100 text-yellow-500 hover:bg-yellow-500",
          "image": "/img/overview_assets/with_circle/leadership-index.png"

        },
        {
          "name": "Value",
          "template": 7,
          "score": "NaN",
          "type": "Percentage",
          "color": "#C0CA33",
          "tailwindcolor": " bg-lime-100 text-[#b2b33f] hover:bg-[#b2b33f] ",
          "image": "/img/overview_assets/with_circle/value-index.png"
        },

        {
          "name": "Safety",
          "template": 9,
          "score": "NaN",
          "type": "Percentage",
          "color": "#b2b33f",
          "tailwindcolor": " bg-green-100 text-green-500 hover:bg-green-500",
          "image": "/img/overview_assets/with_circle/safety-index.png"

        },
        {
          "name": "Diversity",
          "template": 10,
          "score": "NaN",
          "type": "Percentage",
          "color": "#3F51B5",
          "tailwindcolor": " bg-indigo-100 text-indigo-500 hover:bg-indigo-500",
          "image": "/img/overview_assets/with_circle/diversity-index.png"

        }
      ],




    }
  }

  componentDidMount() {

  }


  openSurveyResponse = () => {
    this.setState({ showOverview: false });

  }

  closeResponseAnalytics = () => {
    this.setState({ showOverview: true })
  }

  onOptionType = (value) => {
    let { getAssessmentDimensionsTop5 } = this.props;
    if (value === 1) {
      getAssessmentDimensionsTop5(false)
    } else if (value === 2) {
      getAssessmentDimensionsTop5(false)
    } else {
      getAssessmentDimensionsTop5(true)
    }
    this.setState({ optionType: value })
  }

  handleGoBack = () => {
    this.setState({ showOverview: true })
  }


  render() {
    let { optionType, allIndexList, showOverview } = this.state;
    let {
      getAllAnsweredAssessmentEmpDataList,
      getAssessmentDimensionsData,
      handlAssessmentDashboard,
      getEMPFilterLabel,
      getIndexByFilterDMGData,
      handleThemeStrenght,
      loading,
      onCustomScoreCard,
      getQuestionAvgScoreData,
      getIndexData,
      SurveyList,
      CurrentSurveyId,
      onOptionType,

      getHappinessIndexV2Data,
      getEngagementIndexV2Data,
      getStressIndexV2Data,
      getManagerIndexV2Data,

      getRespondantDetailsData,

      EmpFilterData,
      getIndexFilterData,

      getQuestionOthersResultData,

      getAssessmentDashboardData,
      getAssessmentByThemeData,


      onReportModal,
      cronBachAlpha

    } = this.props;

    let surveyId = '';
    // if(SurveyList && SurveyList.length>0){

    //   if(CurrentSurveyId){
    //     surveyId = CurrentSurveyId;
    //   }
    //   else{
    //     surveyId = SurveyList[0].id;
    //   }

    // }
    surveyId = CurrentSurveyId;

    //console.log('x-------->>>>> IndexList:-------->>>>>', getIndexData)

    let overviewScoreList = []
    let IndexList = []

    function getIndexName(key) {
      let name = ''
      if (key === 1) { name = 'Happiness' }
      else if (key === 2) { name = 'Engagement' }
      else if (key === 3) { name = 'Hope' }
      else if (key === 4) { name = 'Stress' }
      else if (key === 5) { name = 'Manager' }
      else if (key === 6) { name = 'Leadership' }
      else if (key === 7) { name = 'Safety' }
      else if (key === 9) { name = 'Diversity' }
      else { name = 'NaN' }

      return name;
    }

    function checkIndex(key) {
      let check = false
      if (key === 1) { check = true }
      else if (key === 2) { check = true }
      else if (key === 3) { check = true }
      else if (key === 4) { check = true }
      else if (key === 5) { check = true }
      else if (key === 6) { check = true }
      else if (key === 7) { check = true }
      else if (key === 9) { check = true }
      else { check = false }

      return check;
    }
    let IndexListNew = [];
    let newFilterObj = {}
    if (getIndexData) {



      IndexList = Object.keys(getIndexData).map((key) => [Number(key), getIndexData[key]]);

      if (getIndexFilterData) {
        if (IndexList && IndexList.length > 0) {
          IndexList.forEach((data) => {
            if (checkIndex(data[0])) {
              newFilterObj[data[0]] = getIndexFilterData[data[0]];
            }
          });
        };

        IndexListNew = Object.keys(newFilterObj).map((key) => [Number(key), newFilterObj[key]]);
      }
      //console.log('IndexListNew: ',IndexListNew)

      if (IndexListNew && IndexListNew.length > 0) {
        IndexListNew.forEach((data) => {
          let GetIndex = allIndexList.findIndex(prev => prev.template === data[0])
          if (getIndexName(data[0]) !== 'NaN' && data && data[0] && data[1]) {
            overviewScoreList.push({
              template: data[0],
              score: data[1].toFixed(2),
              name: getIndexName(data[0]),
              type: (GetIndex !== -1) ? allIndexList[GetIndex].type : 'score',
              color: (GetIndex !== -1) ? allIndexList[GetIndex].color : '#757575',
              image: (GetIndex !== -1) ? allIndexList[GetIndex].image : null
            })
          }

        })
      }

    }

    let OverViewIndexList = overviewScoreList;

    if (allIndexList && allIndexList.length > 0) {
      allIndexList.forEach((item) => {
        let GetIndex = OverViewIndexList.findIndex(prev => prev.template === item.template);
        if (GetIndex === -1) {
          OverViewIndexList.push({
            ...item,
            "type": item.type,
            "color": item.color,
            "image": item.image
          });
        }
      });
    }

    // console.log('OverViewIndexList: ',OverViewIndexList)


    function FormattFun(inputData, isSummary) {
      let RankObj = 0

      let LIST = []
      let prevTemList = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {

          let templateId = ques.total[0]._id.qtemplate;

          let temp = {
            name: ques && ques.question && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
            answerList: [],
            rank: RankObj && RankObj[templateId] ? RankObj[templateId] : 100,
            templateId: templateId
          }
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading: item && item._id && item._id.heading ? item._id.heading : "",
                parentId: item && item._id && item._id.parentId ? item._id.parentId : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId
              }
              temp["answerList"].push(ans);
            });
          }


          if (isSummary) {
            let getIndex = prevTemList.findIndex(prev => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId)
            }
          }
          else {
            LIST.push(temp);
          }


        });
      }
      return LIST
    }


    let OthersQuesList = FormattFun(getQuestionOthersResultData, true).sort((a, b) => a.rank - b.rank);

    let { onAllQuestions } = this.props;


    console.log('getQuestionAvgScoreData---------->', getQuestionAvgScoreData)

    let itemList = getQuestionAvgScoreData && getQuestionAvgScoreData[0] && getQuestionAvgScoreData[0]["total"] ? getQuestionAvgScoreData[0]["total"] : []

    let parameterList = []
    if (itemList && itemList.length > 0) {
      itemList.forEach((item) => {
        let getIndex = parameterList.findIndex(prev => prev.index === item["_id"].parameterBucketName);
        if (getIndex === -1) {
          parameterList.push({
            index: item["_id"].parameterBucketName,
            data: [{
              count: item.count,
              sum: item.sum,
              ...item["_id"]
            }]
          })
        } else {
          parameterList[getIndex]["data"].push({
            count: item.count,
            sum: item.sum,
            ...item["_id"]
          })
        }

      });
    }




    let parameter2List = []
    if (parameterList && parameterList.length > 0) {
      parameterList.forEach((item) => {
        let temp = {
          "index": item.index,
          "DimenionList": []
        }
        if (item && item.data && item.data.length > 0) {
          item.data.forEach((item2) => {



            let getIndex = temp["DimenionList"].findIndex(prev => prev.dimension === item2.parameterDimensionName);
            if (getIndex === -1) {
              temp["DimenionList"].push({
                dimension: item2.parameterDimensionName,
                inner: [{
                  count: item2.count,
                  sum: item2.sum,
                  answer: item2.answer
                }]
              })
            } else {
              temp["DimenionList"][getIndex]["inner"].push({
                count: item2.count,
                sum: item2.sum,
                answer: item2.answer
              });

            }



          })
        }

        parameter2List.push(temp)


      });
    }

    console.log('parameter2List---------->', parameter2List)



    function getQueTemplateIn(templateId) {
      let check = false;
      if (templateId) {
        if ([2, 11, 6, 5].includes(Number(templateId))) {
          check = true
        }
      }
      return check
    }

    let ParticipationObj = {
      total: 0,
      completed: 0,
      mailSent: 0,
      clicked: 0,
      participated: 0,
      performance: 0,
      perfPlusSign: true
    }

    if (getRespondantDetailsData && getRespondantDetailsData[0]) {
      let apiData = getRespondantDetailsData[0]
      ParticipationObj['total'] = apiData['total'] ? apiData['total'] : 0;
      ParticipationObj['completed'] = apiData['completed'] ? apiData['completed'] : 0;
      ParticipationObj['mailSent'] = apiData['mailSent'] ? apiData['mailSent'] : 0;
      ParticipationObj['clicked'] = apiData['clicked'] ? apiData['clicked'] : 0;
      ParticipationObj['participated'] = apiData['completed'] && apiData['total'] ? (apiData['completed'] * 100 / apiData['total']).toFixed(2) : 0;
    }

    let responseOption = {
      "item1": {
        "label": "Sent",
        "value": ParticipationObj['total']
      },
      "item2": {
        "label": "Responded",
        "value": ParticipationObj['completed']
      },
      "item3": {
        "label": "Response Rate",
        "value": ParticipationObj['participated'] + '%'
      }
    }


    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split('.')[0];
        let second = str.split('.')[1];
        let second2 = second ? second.substring(0, 2) : ""
        let final = first + '.' + second2;
        if (second2) {
          return parseFloat(final).toFixed(2)
        } else {
          return no
        }
      } else {
        return 0
      }
    }


    let OptionList = [
      {
        "name": "Overall Dominant Themes",
        "template": 1
      },
      {
        "name": "Overall Dominant Talents",
        "template": 2
      },
      {
        "name": "Dominant Theme Frequency",
        "template": 3
      }
    ]



    console.log('getAllAnsweredAssessmentEmpDataList---------->', getAllAnsweredAssessmentEmpDataList)



    let { loadAllAssessment, loadingAllAssessment } = this.props;



    return (
      <>
        <main className="flex-1 w-full p-4 overflow-hidden overflow-y-auto  bg-[#f9fafb] md:p-6 ">

          {showOverview ?
            <div className="space-y-4">


              <div className='flex items-center justify-between' >
                <h1 className=' xl:text-2xl text-xl font-medium text-[#3D405B] '>Overview</h1>

                <div className='flex items-center justify-between space-x-2'>

                  {getAllAnsweredAssessmentEmpDataList && getAllAnsweredAssessmentEmpDataList.length > 0 ? (
                    <CSVLink data={getAllAnsweredAssessmentEmpDataList} filename={"AssessmentEmployees.csv"}>
                      <button className=" bg-white flex items-center py-2 px-4 space-x-2 text-sm font-medium text-[#3D405B] border rounded-md cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <path d="M10 12l4 4m0 -4l-4 4" /></svg>
                        <span>Download All Data</span>
                      </button>
                    </CSVLink>
                  ) :
                    <>
                      {/* {loadingAllAssessment ?
                        <button onClick={() => loadAllAssessment()} className="flex items-center p-2 px-4 space-x-2 text-sm font-medium text-white transition-all duration-150 bg-gray-400 border rounded-md cursor-pointer group hover:text-white">
                          <svg xmlns="http://www.w3.org/2000/svg" className="text-white transition-all duration-150 group-hover:text-white icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M10 12l4 4m0 -4l-4 4" /></svg>
                          <span>Laoding Excel..</span>
                        </button>
                        :
                        <button onClick={() => loadAllAssessment()} className="flex items-center p-2 px-4 space-x-2 text-sm font-medium text-[#3D405B] border rounded-md cursor-pointer">
                          <svg xmlns="http://www.w3.org/2000/svg" className="text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <path d="M10 12l4 4m0 -4l-4 4" /></svg>
                          <span>Download All Data</span>
                        </button>
                      } */}

                    </>
                  }


                  <div>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex justify-between items-center w-56 px-4 py-2 text-sm font-medium text-[#3D405B] bg-white border rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                          Manage Reports
                          <RiArrowDropDownLine
                            className="w-7 h-7 ml-2 -mr-1 text-[#3D405B]"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 w-56 mt-1 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black/5 focus:outline-none">
                          <div className="px-1 py-1 ">
                            <Menu.Item>
                              {({ active }) => (
                                <button onClick={() => onReportModal(1)} className={`${active ? 'bg-[#2196F3]/10 text-[#3D405B]' : 'text-[#3D405B]'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}>Individual Report</button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button onClick={() => onReportModal(3)} className={`${active ? 'bg-[#2196F3]/10 text-[#3D405B]' : 'text-[#3D405B]'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}> Recruitment Report</button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button onClick={() => onReportModal(4)} className={`${active ? 'bg-[#2196F3]/10 text-[#3D405B]' : 'text-[#3D405B]'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}>Team Report</button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button onClick={() => onReportModal(2)} className={`${active ? 'bg-[#2196F3]/10 text-[#3D405B]' : 'text-[#3D405B]'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}>Manager Report</button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>

                </div>





                {/* <div className='flex items-center justify-between space-x-2'>


                 

                  <button onClick={() => onReportModal(4)} class=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-report-analytics" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path><rect x="9" y="3" width="6" height="4" rx="2"></rect><path d="M9 17v-5"></path><path d="M12 17v-1"></path><path d="M15 17v-3"></path></svg>
                    <span> Team Report</span>
                  </button>

                  <button onClick={() => onReportModal(3)} class=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-report-analytics" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path><rect x="9" y="3" width="6" height="4" rx="2"></rect><path d="M9 17v-5"></path><path d="M12 17v-1"></path><path d="M15 17v-3"></path></svg>
                    <span>Recruitment Report</span>
                  </button>


                  <button onClick={() => onReportModal(2)} class=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-report-analytics" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path><rect x="9" y="3" width="6" height="4" rx="2"></rect><path d="M9 17v-5"></path><path d="M12 17v-1"></path><path d="M15 17v-3"></path></svg>
                    <span>Manager Report</span>
                  </button>

                  <button onClick={() => onReportModal(1)} class=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-report-analytics" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path><rect x="9" y="3" width="6" height="4" rx="2"></rect><path d="M9 17v-5"></path><path d="M12 17v-1"></path><path d="M15 17v-3"></path></svg>
                    <span>Individual Report</span>
                  </button>

                  {getAllAnsweredAssessmentEmpDataList && getAllAnsweredAssessmentEmpDataList.length > 0 ? (
                    <CSVLink data={getAllAnsweredAssessmentEmpDataList} filename={"AssessmentEmployees.csv"}>
                      <button className="flex items-center p-2 px-4 space-x-2 text-sm font-medium text-white transition-all duration-150 bg-green-600 border rounded-md cursor-pointer group hover:text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-white transition-all duration-150 group-hover:text-white icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M10 12l4 4m0 -4l-4 4" /></svg>
                        <span>Save Excel Data</span>
                      </button>
                    </CSVLink>
                  ) :
                    <>
                      {loadingAllAssessment ?
                        <button onClick={() => loadAllAssessment()} className="flex items-center p-2 px-4 space-x-2 text-sm font-medium text-white transition-all duration-150 bg-gray-400 border rounded-md cursor-pointer group hover:text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-white transition-all duration-150 group-hover:text-white icon icon-tabler icon-tabler-file-x"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                            <path d="M10 12l4 4m0 -4l-4 4" />
                          </svg>

                          <span>Laoding Excel..</span>
                        </button>
                        :
                        <button onClick={() => loadAllAssessment()} className="flex items-center p-2 px-4 space-x-2 text-sm font-medium text-white transition-all duration-150 bg-blue-500 border rounded-md cursor-pointer group hover:text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-white transition-all duration-150 group-hover:text-white icon icon-tabler icon-tabler-file-x"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                            <path d="M10 12l4 4m0 -4l-4 4" />
                          </svg>

                          <span>Download All Data</span>
                        </button>
                      }

                    </>
                  }

                </div> */}


              </div>



              <div onClick={() => this.openSurveyResponse()}>
                <ResponseHeader responseOption={responseOption} />
                {/* <IndexKPI getRespondantDetailsData={getRespondantDetailsData} /> */}

              </div>



              {/* Tab */}
              <div className="items-center justify-between border-b md:flex">
                <div className="flex space-x-2 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 whitespace-nowrap tab-scroll">
                  {OptionList && OptionList.length > 0 ?
                    OptionList.map((item) =>
                      <span onClick={() => this.onOptionType(item.template)}
                        className={optionType === item.template ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}>
                        {item && item.name ? item.name : ""}
                      </span>
                    ) : null}
                </div>

              </div>





              {optionType === 1 ?

                <>

                  <div>
                    <Summary
                      getAssessmentDashboardData={getAssessmentDashboardData}
                      getAssessmentByThemeData={getAssessmentByThemeData}
                      handleThemeStrenght={handleThemeStrenght}
                      EmpFilterData={EmpFilterData}
                      getIndexByFilterDMGData={getIndexByFilterDMGData}
                      getEMPFilterLabel={getEMPFilterLabel}

                      handlAssessmentDashboard={handlAssessmentDashboard}

                      getAssessmentDimensionsData={getAssessmentDimensionsData}
                      loading={loading}
                      cronBachAlpha={cronBachAlpha}
                    />

                  </div>


                </>
                : null}


              {optionType === 2 ?

                <>

                  <div>
                    <RankTalents
                      getAssessmentDashboardData={getAssessmentDashboardData}
                      getAssessmentByThemeData={getAssessmentByThemeData}
                      handleThemeStrenght={handleThemeStrenght}
                      EmpFilterData={EmpFilterData}
                      getIndexByFilterDMGData={getIndexByFilterDMGData}
                      getEMPFilterLabel={getEMPFilterLabel}
                      handlAssessmentDashboard={handlAssessmentDashboard}
                      getAssessmentDimensionsData={getAssessmentDimensionsData}
                      loading={loading}
                    />
                  </div>
                </>
                : null}

              {optionType === 3 ?
                <>
                  <div>
                    <RankTop5Talents
                      getAssessmentDashboardData={getAssessmentDashboardData}
                      getAssessmentByThemeData={getAssessmentByThemeData}
                      handleThemeStrenght={handleThemeStrenght}
                      EmpFilterData={EmpFilterData}
                      getIndexByFilterDMGData={getIndexByFilterDMGData}
                      getEMPFilterLabel={getEMPFilterLabel}
                      handlAssessmentDashboard={handlAssessmentDashboard}
                      getAssessmentDimensionsData={getAssessmentDimensionsData}
                      loading={loading}
                    />
                  </div>
                </>
                : null}

            </div>
            :

            <div className="px-2">
              <SurveyReport
                handleGoBack={this.handleGoBack}
                CurrentSurveyId={surveyId}
                closeResponseAnalytics={this.closeResponseAnalytics}
                EmpFilterData={EmpFilterData}
                getIndexFilterData={getIndexFilterData}
                getRespondantDetailsData={getRespondantDetailsData}
                loading={loading}
              />

            </div>

          }

        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
