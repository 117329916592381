import React, { Component } from "react";
import { connect } from "react-redux";

class ManagerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // showFilter:false,
      // showValue:'',
      currFilterList: [],
      finalFilter: [],
      searchName: "",
      showMngrFilter: false,
    };
  }
  componentDidMount() {}

  handleParameterQuestion = () => {};

  handleSelectFilter = (name) => {
    // this.setState({showFilter:false});

    this.props.closeFilter(false);

    let { currFilterList, finalFilter } = this.state;
    let { handleMultifilterIndexScore } = this.props;
    let getIndex = currFilterList.findIndex((prev) => prev === name);
    if (getIndex === -1) {
      currFilterList.push(name);
      finalFilter.push({
        level: name,
        value: "All",
      });

      this.setState({ currFilterList, finalFilter }, () => {
        handleMultifilterIndexScore(finalFilter, name);
      });
    } else {
      currFilterList.splice(getIndex, 1);
      let getIndex2 = finalFilter.findIndex((prev) => prev.level === name);
      finalFilter.splice(getIndex2, 1);

      this.setState({ currFilterList, finalFilter }, () => {
        if (finalFilter && finalFilter.length > 0) {
          handleMultifilterIndexScore(
            finalFilter,
            finalFilter[finalFilter.length - 1].level
          );
        } else {
          handleMultifilterIndexScore([]);
          // this.setState({showFilter:false})
          this.props.closeFilter(false);
        }
      });
    }
  };

  handleOpenFilter = (value) => {
    // this.setState({showValue:''});
    // this.props.closeFilter(!check,'');
    this.setState({ showMngrFilter: value });
  };

  handleOpenValue = (name) => {
    if (this.props.showValue === name) {
      // this.setState({showValue:''});
      this.props.closeFilter(false, "");
    } else {
      // this.setState({showValue:name});
      this.props.closeFilter(false, name);
    }
  };

  handleSelectValue = (value, name) => {
    let { finalFilter } = this.state;

    let getIndex = finalFilter.findIndex((prev) => prev.level === name);

    if (getIndex === -1) {
      finalFilter.push({
        level: name,
        value: value,
      });
    } else {
      let getIndex2 = finalFilter.findIndex((prev) => prev.value === value);
      if (getIndex2 === -1) {
        finalFilter[getIndex].value = value;
      } else if (value === "All") {
        finalFilter[getIndex].value = "All";
      }
    }
    let { handleMultifilterIndexScore } = this.props;
    this.props.closeFilter(false, "");
    this.setState({ finalFilter }, () => {
      handleMultifilterIndexScore(finalFilter, name);
    });
  };

  handleFilterClear = () => {
    let { handleMultifilterIndexScore } = this.props;
    this.setState({ finalFilter: [], currFilterList: [] }, () => {
      handleMultifilterIndexScore([]);
    });
  };

  handleSelectAllFilter = () => {};

  handleSearch = (e) => {
    this.setState({ searchName: e.target.value });
  };

  render() {
    const ref = React.createRef();
    let {
      EmpFilterData2,
      getIndexFilterData,
      showValue,
      showFilter,
      crrMngrEmp_idList,
      getEMPFilterLabel,
      empDataList,
      handleSelectMngrValue,
    } = this.props;
    let { searchName, currFilterList, finalFilter, showMngrFilter } =
      this.state;

    let DemographicValueList = {};
    if (getIndexFilterData) {
      DemographicValueList = getIndexFilterData;
      // DemographicValueList.sort();
    }

    //console.log('EmpFilterData2: ',EmpFilterData2)
    //console.log('currFilterList: ',currFilterList)
    //console.log('DemographicValueList: ',DemographicValueList)
    //console.log('finalFilter: ',finalFilter)

    function selectedValue(item) {
      let Value = "";
      let getIndex = finalFilter.findIndex((prev) => prev.level === item);
      if (getIndex !== -1) {
        if (
          finalFilter &&
          finalFilter[getIndex] &&
          finalFilter[getIndex].value
        ) {
          Value = finalFilter[getIndex].value;
        }
      }
      return Value;
    }

    function SortList(list) {
      let temp = [];
      if (list && list.length > 0) {
        temp = list.sort();
      }
      return temp;
    }

    function getEmpValue(item) {
      return selectedValue(item)
        ? selectedValue(item) === "All"
          ? item + " (" + selectedValue(item) + ")"
          : selectedValue(item)
        : item + " (All)";
    }

    function getFilterCount() {
      return currFilterList && currFilterList.length > 0
        ? currFilterList.length
        : 0;
    }

    if (EmpFilterData2 && EmpFilterData2.length > 0) {
      EmpFilterData2.sort(
        (a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0)
      );
    }

    function getValue(data, type) {
      if (type === "name") {
        let name =
          data && data.employeeDetails && data.employeeDetails.name
            ? data.employeeDetails.name
            : "";
        return name;
      } else if (type === "email") {
        let email =
          data && data.employeeDetails && data.employeeDetails.email
            ? data.employeeDetails.email
            : "";
        return email;
      } else if (type === "emp_id") {
        let emp_id =
          data && data.employeeDetails && data.employeeDetails.emp_id
            ? data.employeeDetails.emp_id
            : "";
        return emp_id;
      }
    }

    function getDataByEmp_id(empList, value) {
      let Data = {};
      if (empList && empList.length > 0) {
        let getIndex = empList.findIndex(
          (prev) => prev.employeeDetails.emp_id === value
        );
        if (getIndex !== -1) {
          Data = empList[getIndex];
        }
      }
      return Data && Data.name ? Data.name : "";
    }

    function tryCatch(valueX, searchNameX) {
      let value1 = true;
      try {
        value1 =
          valueX &&
          getValue(valueX, "name").match(new RegExp(`${searchNameX}`, "gi"));
      } catch (e) {
        return true;
      }
      return value1;
    }

    console.log("empDataList----1>", empDataList);
    console.log("crrMngrEmp_idList----1>", crrMngrEmp_idList);

    return (
      <>
        <div className="flex gap-2 flex-wrap">
          {/*  selelect box */}

          <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5 font-medium">
            <div className="flex space-x-2 items-center ">
              <span
                onClick={() => this.handleOpenFilter(!showMngrFilter)}
                className=" text-sm  "
              >
                Managers
              </span>
              <span
                onClick={() => this.handleOpenFilter(!showMngrFilter)}
                className="bg-slate-100  font-medium rounded-full
                  text-sm w-6 h-6 flex items-center justify-center"
              >
                {crrMngrEmp_idList && crrMngrEmp_idList.length > 0
                  ? crrMngrEmp_idList.length
                  : 0}
              </span>
            </div>
            <span
              onClick={() => this.handleOpenFilter(!showMngrFilter)}
              className="material-symbols-outlined  "
            >
              arrow_drop_down
            </span>

            {showMngrFilter ? (
              <>
                <div className="z-10 absolute left-0 top-12 bg-white shadow-lg p-4 font-normal font-xs w-56 rounded-lg overflow-y-auto h-60 scollar-xs-blue">
                  <div className="border relative  mb-4 rounded-md bg-white overflow-hidden">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-search absolute  top-1.5 left-1.5"
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#3D405B"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="10" cy="10" r="7" />
                      <line x1="21" y1="21" x2="15" y2="15" />
                    </svg>
                    <input
                      type="text"
                      name="searchName"
                      value={searchName}
                      onChange={this.handleSearch}
                      placeholder="Search"
                      className="text-sm w-full pl-8 p-2 rounded-sm focus:outline-none text-[#707070] placeholder:text-[#707070]  "
                    />
                  </div>

                  <div
                    className="py-2 m-1 flex items-center accent-[#2196F3] text-sm"
                    onClick={() => {
                      handleSelectMngrValue([], "All");
                      this.handleOpenFilter(false);
                    }}
                  >
                    <input
                      type="checkbox"
                      className="scale-105 "
                      onChange={() => {}}
                    />

                    <label className="ml-2 text-sm cursor-pointer text-[#3D405B] ">
                      {"All Managers"}
                    </label>
                  </div>

                  {empDataList && empDataList.length > 0
                    ? empDataList.map((empAct, index) =>
                        tryCatch(empAct, searchName) ? (
                          <div
                            className="py-2 m-1 flex items-center accent-[#2196F3] text-sm"
                            onClick={() => {
                              handleSelectMngrValue(
                                empDataList,
                                getValue(empAct, "emp_id")
                              );
                              this.handleOpenFilter(false);
                            }}
                          >
                            <input
                              type="checkbox"
                              className="scale-105 "
                              checked={
                                crrMngrEmp_idList &&
                                crrMngrEmp_idList.length > 0
                                  ? crrMngrEmp_idList.some(
                                      (prev) =>
                                        prev === getValue(empAct, "emp_id")
                                    )
                                  : false
                              }
                              onChange={() => {}}
                            />

                            <label className="ml-2 text-sm cursor-pointer text-[#3D405B] ">
                              {getValue(empAct, "name")}
                            </label>
                          </div>
                        ) : null
                      )
                    : null}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(ManagerList);
