import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let { DemographicValues, DemographicValuesObj, XAxis, AnswerObjOverall,


      currFilterValue, currFilterName, getDistributionIndexData, getIndexDriverData, GetParameterLabel } = this.props;

    //console.log('xxxxx--------nps -----------------AnswerObjOverall: ',AnswerObjOverall)     


    function GetNPSScore(ValuesObj, item) {
      if (item === "OVERALL") {

        let totalCount = 0;
        let Promoters = 0;
        let Passsives = 0;
        let Distractors = 0;

        if (ValuesObj) {
          Object.keys(ValuesObj).forEach(key => {
            let score = parseInt(ValuesObj[key]);

            totalCount += score;

            if (parseInt(key) >= 9 && parseInt(key) <= 10) {
              Promoters += score;
            }
            if (parseInt(key) >= 7 && parseInt(key) <= 8) {
              Passsives += score;
            }
            if (parseInt(key) >= 0 && parseInt(key) <= 6) {
              Distractors += score;
            }

          });
        }

        let total = Promoters + Passsives + Distractors;
        let NPScore = ((Promoters * 100 / total) - (Distractors * 100 / total));
        return NPScore === "NaN" ? "NA" : Math.round(NPScore)
      }
      else {
        let Promoters = ValuesObj[item].Promoters;
        let Passsives = ValuesObj[item].Passsives;
        let Distractors = ValuesObj[item].Distractors;
        let total = Promoters + Passsives + Distractors;
        let NPScore = ((Promoters * 100 / total) - (Distractors * 100 / total));
        // return NPScore==="NaN"?0:NPScore

        let count = ValuesObj[item]["count"];
        return count > 5 ? (NPScore === "NaN" ? 0 : Math.round(NPScore)) : "NA"


      }

    }

    function GetPerc(ValuesObj, item, type) {

      if (item === "OVERALL") {

        let totalCount = 0;
        let Promoters = 0;
        let Passsives = 0;
        let Distractors = 0;

        if (ValuesObj) {
          Object.keys(ValuesObj).forEach(key => {
            let score = parseInt(ValuesObj[key]);

            totalCount += score;

            if (parseInt(key) >= 9 && parseInt(key) <= 10) {
              Promoters += score;
            }
            if (parseInt(key) >= 7 && parseInt(key) <= 8) {
              Passsives += score;
            }
            if (parseInt(key) >= 0 && parseInt(key) <= 6) {
              Distractors += score;
            }

          });
        }
        //console.log('xxxxx--------nps -----------------Promoters + Passsives + Distractors: ',Promoters , Passsives , Distractors)     
        let typeValue = 0;
        if (type === "Promoters") { typeValue = Promoters } else if (type === "Passsives") { typeValue = Passsives } else if (type === "Distractors") { typeValue = Distractors }
        let total = Promoters + Passsives + Distractors;
        let score = ((typeValue * 100) / total);
        return score ? Math.round(score) : 0
      }
      else {
        let Promoters = ValuesObj[item]["Promoters"];
        let Passsives = ValuesObj[item]["Passsives"];
        let Distractors = ValuesObj[item]["Distractors"];
        let total = Promoters + Passsives + Distractors;
        let score = (((ValuesObj[item][type]) * 100) / total)
        // return score?score.toFixed(2):0

        let count = ValuesObj[item]["count"];
        return count > 5 ? (score ? Math.round(score) : 0) : "NA"
      }

    }


    const customOrder = [
      '0-1 Year', '1-3 Years', '3-5 Years','5-10 Years','10-15 Years','15-20 Years'
    ];
    DemographicValues.sort((a, b) => customOrder.indexOf(a) - customOrder.indexOf(b));

    return (
      <>

        <div className='space-y-4 bg-white'>
          {/* <div className='flex flex-row items-center justify-between w-full gap-4'>
            <div className='flex items-center text-[#212121] space-x-2'>
              <p className='p-3 bg-[#ededed] font-semibold'>Question 111</p>
            </div>
          </div>
          <div className='space-y-2 '>
            <h2 className='text-xl font-medium text-[#212121]'>question name</h2>
            <p className='text-base text-[#212121]/80 flex space-x-2 items-center'>
              <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>Email</p>
            </p>
          </div> */}

          <div className='bg-[#f9f9f9] rounded'>

            <div class="space-y-4 text-sm text-slate-800">
              <table class="w-full border-collapse">
                <thead class="text-sm  capitalize bg-[#2196F3]/20">
                  <tr class="border-b-2 border-white">
                    <th className="text-[#212121] font-medium p-4 py-3 text-sm text-left border-r-2 border-white">{XAxis}</th>
                    <th className="text-[#212121] font-medium p-4 py-3 text-sm text-center border-r-2 border-white">{"NPS Score (%)"}</th>
                    <th className="text-[#212121] font-medium p-4 py-3 text-sm text-center border-r-2 border-white">{"Promoters (%)"}</th>
                    <th className="text-[#212121] font-medium p-4 py-3 text-sm text-center border-r-2 border-white">{"Passsives (%)"}</th>
                    <th className="text-[#212121] font-medium p-4 py-3 text-sm text-center border-r-2 border-white">{"Detractors (%)"}</th>
                  </tr>
                </thead>
                <tbody class="divide-y-2 divide-white ">
                  <tr class="even:bg-gray-100 odd:bg-gray-50">
                    <td class="p-2 px-4 border-r-2 border-white"><p class="font-medium ">{"OVERALL"}</p></td>
                    <td class="p-2 px-4 text-center border-r-2 border-white">{GetNPSScore(AnswerObjOverall, "OVERALL")}</td>
                    <td class="p-2 px-4 text-center border-r-2 border-white">{GetPerc(AnswerObjOverall, "OVERALL", "Promoters")}</td>
                    <td class="p-2 px-4 text-center border-r-2 border-white">{GetPerc(AnswerObjOverall, "OVERALL", "Passsives")}</td>
                    <td class="p-2 px-4 text-center border-r-2 border-white">{GetPerc(AnswerObjOverall, "OVERALL", "Distractors")}</td>
                  </tr>

                  {DemographicValues && DemographicValues.length > 0 ?
                    DemographicValues.map((item, index) =>
                      (item) ?
                        <tr class="even:bg-gray-100 odd:bg-gray-50">
                          <td class="p-2 px-4 border-r-2 border-white"><p class="font-medium">{item}</p></td>
                          <td class="p-2 px-4 text-center border-r-2 border-white">{GetNPSScore(DemographicValuesObj, item)}</td>
                          <td class="p-2 px-4 text-center border-r-2 border-white">{GetPerc(DemographicValuesObj, item, "Promoters")}</td>
                          <td class="p-2 px-4 text-center border-r-2 border-white">{GetPerc(DemographicValuesObj, item, "Passsives")}</td>
                          <td class="p-2 px-4 text-center border-r-2 border-white">{GetPerc(DemographicValuesObj, item, "Distractors")}</td>
                        </tr>
                        : null
                    ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>



      </>

    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
