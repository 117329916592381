import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import ReactTooltip from "react-tooltip";

class SurveySubSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurveyName: "",
      currentSurveyId: "",
      showDropdown: false,
      gameType: "",
    };
  }
  componentDidMount() {}

  onDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false });
    } else {
      this.setState({ showDropdown: true });
    }
  };

  logout = () => {
    this.props.dispatch(userActions.logout());
  };

  IndexHoverOptions = (surveyId, indexType) => {
    let { onOptionType } = this.props;

    return (
      <span>
        <i
          className="fa fa-scribd"
          onClick={() => onOptionType(surveyId, indexType, 1)}
        />
        <i
          className="fa fa-quora"
          onClick={() => onOptionType(surveyId, indexType, 2)}
        />
        <i
          className="fa fa-align-left"
          onClick={() => onOptionType(surveyId, indexType, 3)}
        />
        {/* <i className="fa fa-facebook" onClick={()=>onOptionType(surveyId,indexType,4)}/>  */}
      </span>
    );
  };

  handleIndexType = (surveyId, indexType, isDisable, event) => {
    this.setState({ gameType: event });
    let { onOptionType } = this.props;
    if (!isDisable) {
      onOptionType(surveyId, indexType, 1);
    }

    // let {handleSidebar}=this.props;
    // handleSidebar(false);
  };

  sidebarToggle = () => {
    // this.setState({showSidebar:!this.state.showSidebar});

    let { handleSidebar, showSidebar } = this.props;
    handleSidebar(!showSidebar);

    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },6000)

    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },7000)
  };

  handleLifecyclePTM = (value) => {
    let { callLifecyclePTM } = this.props;
    callLifecyclePTM(true, value);
  };

  render() {
    let {
      SurveyList,
      getIndexData,
      getCategoryOverallIndexData,
      selectSurvey,
      showInnerMenu,
      handleGoToLifecycle,
      showOnboardingInsight,
      onComponentNumber,
      // onHappinessIndex, onEngagementIndex, onHopeIndex, onStressIndex, onOptionType,
      indexType,
      CurrentSurveyId,
      showMobileSidebar,
      isLifecycle,
    } = this.props;
    let {
      // currentSurveyName, showDropdown,
      showSidebar,
    } = this.props;

    let surveyId = "";
    if (SurveyList && SurveyList.length > 0) {
      if (CurrentSurveyId) {
        surveyId = CurrentSurveyId;
      } else {
        surveyId = SurveyList[0].id;
      }
    }

    function getIcons(value) {
      if (value) {
        return "img/feedbacksurveyicons/svg/" + (value.toString() + "days.svg");
      }
      {
        return "";
      }
    }
    function getIconsActive(value) {
      if (value) {
        return (
          "img/feedbacksurveyicons/svg/" +
          (value.toString() + "days_active.svg")
        );
      }
      {
        return "";
      }
    }

    console.log(
      "getCategoryOverallIndexData---->",
      getCategoryOverallIndexData
    );

    let surveyList = [];
    if (getCategoryOverallIndexData && getCategoryOverallIndexData.length > 0) {
      getCategoryOverallIndexData.forEach((element) => {
        if (element && element.surveyData && element.surveyData.name) {
          if (element && element.surveyData && element.surveyData.name) {
            let temp = {
              ...element.surveyData,
              scoreData: element.scoreData,
              icons: getIcons(element.surveyData.value),
              iconsActive: getIconsActive(element.surveyData.value),
            };
            surveyList.push(temp);
          }
        }
      });
    }

    surveyList.sort((a, b) => a.value - b.value);

    ////console.log('=============================================?=================CurrentSurveyId',CurrentSurveyId)
    ////console.log('=============================================?=================surveyId',surveyId)

    let indexLabelsList = [];
    let IndexList = [];

    function getIndexName(key) {
      let name = "";
      if (key === 1) {
        name = "Happiness";
      } else if (key === 2) {
        name = "Engagement";
      } else if (key === 3) {
        name = showOnboardingInsight ? "Experience" : "Hope";
      } else if (key === 4) {
        name = "Stress";
      } else if (key === 5) {
        name = "Manager";
      } else if (key === 6) {
        name = "Leadership";
      } else if (key === 7) {
        name = "Safety";
      } else if (key === 9) {
        name = "Diversity";
      } else {
        name = "NaN";
      }
      return name;
    }

    if (getIndexData) {
      IndexList = Object.keys(getIndexData).map((key) => [
        Number(key),
        getIndexData[key],
      ]);
      if (IndexList && IndexList.length > 0) {
        IndexList.forEach((data) => {
          if (getIndexName(data[0]) !== "NaN" && data && data[0] && data[1]) {
            indexLabelsList.push({
              template: data[0],
              score: data[1].toFixed(2),
              name: getIndexName(data[0]),
            });
          }
        });
      }
    }

    function getIndexLabelsList(objIndex) {
      console.log("objIndex------>", objIndex);

      let indexLabelsList2 = [];
      console.log(
        "objIndex------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------>"
      );
      console.log("objIndex------>", objIndex);
      if (objIndex) {
        let IndexList2 = Object.keys(objIndex).map((key) => [
          Number(key),
          objIndex[key],
        ]);
        if (IndexList2 && IndexList2.length > 0) {
          IndexList2.forEach((data) => {
            if (getIndexName(data[0]) !== "NaN" && data && data[0] && data[1]) {
              indexLabelsList2.push({
                template: data[0],
                score: data[1].toFixed(2),
                name: getIndexName(data[0]),
              });
            }
          });
        }
      }

      return indexLabelsList2;
    }

    // ////console.log('indexType:-------------------> ',indexType)

    function getIconsByTemplateNo(template) {
      return template === 1
        ? "emoji_emotions"
        : template === 2
        ? "group"
        : // (template===3)?"sentiment_dissatisfied":

        template === 4
        ? "sentiment_dissatisfied"
        : template === 5
        ? "manage_accounts"
        : template === 6
        ? "leaderboard"
        : template === 8
        ? "business"
        : // (template===7)?"business":
        // (template===9)?"business":

        template === 102
        ? "account_box"
        : template === 103
        ? "insert_chart"
        : template === 104
        ? "query_stats"
        : template === 105
        ? "fitness_center"
        : template === 106
        ? "event_available"
        : template === 107
        ? "description"
        : template === 108
        ? "flag_circle"
        : template === 121
        ? "emoji_people"
        : "dashboard";
    }

    let otherLifecycleList = [
      // {
      //   "name":"Organization Core",
      //   "template":8,
      //   "show":true
      // },
      // {
      //   "name":"Manager Score Card",
      //   "template":102,
      //   "show":!isLifecycle
      // },
      // {
      //   "name":"Advance Insights",
      //   "template":103,
      //   "show":!isLifecycle
      // },
      // {
      //   "name":"Text Analysis",
      //   "template":104,
      //   "show":true
      // },
      // {
      //   "name":"Strength Weakness Analysis",
      //   "template":105,
      //   "show":!isLifecycle
      // },
      // {
      //   "name":"Manager Action Plan",
      //   "template":106,
      //   "show":!isLifecycle
      // },
      // {
      //   "name":"Goal Plan",
      //   "template":108,
      //   "show":!isLifecycle
      // },
      // {
      //   "name":"Report",
      //   "template":107,
      //   "show":!isLifecycle
      // },
      {
        name: "People to Meet",
        template: 121,
        show: true,
        icon: "people_to_meet",
      },
      {
        name: "Overall Analysis",
        template: 122,
        show: true,
        icon: "overall_analysis",
      },
    ];

    //////console.log("showSidebar",showSidebar)

    function matchSurvey(crrSurId, survey) {
      if (crrSurId === survey.id) {
        return true;
      } else {
        return false;
      }
    }

    let otherInnerLabelList = [
      {
        name: "Organization Core",
        template: 8,
        show: true,
      },
      // {
      //   "name":"Manager Score Card",
      //   "template":102,
      //   "show":!isLifecycle
      // },
      // {
      //   "name":"Advance Insights",
      //   "template":103,
      //   "show":!isLifecycle
      // },
      {
        name: "Text Analysis",
        template: 104,
        show: true,
      },
      {
        name: "Strength Weakness",
        template: 105,
        show: !isLifecycle,
      },
      // {
      //   "name":"Manager Action Plan",
      //   "template":106,
      //   "show":!isLifecycle
      // },
      // {
      //   "name":"Goal Plan",
      //   "template":108,
      //   "show":!isLifecycle
      // },
      // {
      //   "name":"Report",
      //   "template":107,
      //   "show":!isLifecycle
      // },
      {
        name: "People to Meet",
        template: 121,
        show: true,
      },
    ];

    console.log("CurrentSurveyId------->", CurrentSurveyId);

    return (
      <>
        <div
          className={
            showSidebar
              ? "cursor-pointer transition-width w-64 lg:block scollar-xs  lg:relative fixed top-0 left-0  h-screen lg:z-20 z-20 overflow-y-auto overflow-hidden  duration-200 transform bg-white border-r customscroll4 " +
                (showMobileSidebar ? "" : "hidden")
              : "cursor-pointer transition-width  w-16 lg:block scollar-xs lg:relative fixed top-0 left-0  h-screen lg:z-20 z-20 overflow-y-auto overflow-hidden  duration-200 transform bg-white border-r customscroll4 " +
                (showMobileSidebar ? "" : "hidden")
          }
          style={{ height: "calc(100% - 0rem)" }}
        >
          {!showMobileSidebar ? (
            <div className="sticky top-0 flex items-center bg-white ">
              <div className="flex items-center justify-center w-16 px-4 py-3 text-gray-500">
                <span
                  onClick={() => this.sidebarToggle()}
                  className="p-1 bg-gray-100 rounded material-symbols-outlined"
                >
                  {showSidebar ? "chevron_left" : "chevron_right"}
                </span>
              </div>
              <span className="text-sm text-gray-500 ">Insight</span>
            </div>
          ) : (
            <div className="py-1" />
          )}

          <div className="mb-4 whitespace-nowrap ">
            <div
              data-tip="Overview"
              onClick={() => handleGoToLifecycle()}
              className={
                !CurrentSurveyId && onComponentNumber === 0
                  ? "flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer border-l-4 border-[#2196F3] bg-[#2196F3]/10"
                  : "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer"
              }
            >
              <div
                className={
                  !CurrentSurveyId && onComponentNumber === 0
                    ? "flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center"
                    : "flex items-center px-4 py-4 w-16  text-[#3D405B] h-12 justify-center"
                }
              >
                <span className="p-2 material-symbols-outlined">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-layout-dashboard"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.8"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 4h6v8h-6z"></path>
                    <path d="M4 16h6v4h-6z"></path>
                    <path d="M14 12h6v8h-6z"></path>
                    <path d="M14 4h6v4h-6z"></path>
                  </svg>
                </span>
              </div>
              <span
                className={
                  !CurrentSurveyId && onComponentNumber === 0
                    ? " text-sm text-[#2196F3]"
                    : " text-sm text-[#3D405B] "
                }
              >
                Lifecycle Overview
              </span>
            </div>
            <ReactTooltip
              place="right"
              className="extraClass1"
              type="dark"
              effect="solid"
            />

            {surveyList && surveyList.length > 0
              ? surveyList.map((item, index) => (
                  <>
                    <div
                      title={item.name}
                      key={index}
                      onClick={() => selectSurvey(item)}
                      className={
                        matchSurvey(CurrentSurveyId, item)
                          ? "flex justify-between items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer border-l-4 border-[#2196F3] bg-[#2196F3]/10"
                          : "flex justify-between  items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer"
                      }
                    >
                      <div className="flex items-center">
                        <div
                          className={
                            matchSurvey(CurrentSurveyId, item)
                              ? "flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center"
                              : "flex items-center px-4 py-4 w-16   h-12 justify-center"
                          }
                        >
                          {item && item.icons ? (
                            <span className="w-16 p-1">
                              {" "}
                              <img
                                className={
                                  matchSurvey(CurrentSurveyId, item)
                                    ? "text-blue-500"
                                    : ""
                                }
                                src={
                                  matchSurvey(CurrentSurveyId, item)
                                    ? item.iconsActive
                                    : item.icons
                                }
                                alt="icons"
                              />
                            </span>
                          ) : null}
                        </div>
                        <span
                          className={
                            matchSurvey(CurrentSurveyId, item)
                              ? " text-sm text-[#2196F3]"
                              : " text-sm  "
                          }
                        >
                          {item.name}
                        </span>
                      </div>

                      <span
                        className={
                          matchSurvey(CurrentSurveyId, item) &&
                          getIndexData &&
                          showInnerMenu
                            ? "material-symbols-outlined mx-4 text-[#3D405B] -rotate-180 transition-all duration-200 text-[1.2rem] "
                            : "material-symbols-outlined mx-4 text-[#3D405B] rotate-0 transition-all duration-200 text-[1.2rem]"
                        }
                      >
                        expand_more
                      </span>
                    </div>

                    {/* Submenu start  --- also place in hover*/}
                    <div
                      className={
                        matchSurvey(CurrentSurveyId, item) &&
                        getIndexData &&
                        showInnerMenu
                          ? "space-y-4 pl-16 pr-4 py-4 transition-height transform duration-200 overflow-hidden "
                          : "space-y-4 pl-16 pr-4 transition-height transform duration-200 h-0 overflow-hidden "
                      }
                    >
                      {!showOnboardingInsight ? (
                        <p
                          onClick={() =>
                            this.handleIndexType(surveyId, 0, indexType === 0)
                          }
                          className={
                            indexType === 0
                              ? "text-[#2196F3] hover:text-[#2196F3]  text-sm block  font-normal "
                              : "text-[#3D405B] hover:text-[#2196F3] text-sm block  font-normal "
                          }
                        >
                          Overview
                        </p>
                      ) : null}

                      {getIndexLabelsList(item.scoreData) &&
                      getIndexLabelsList(item.scoreData).length > 0
                        ? getIndexLabelsList(item.scoreData).map(
                            (item, index) => (
                              <p
                                onClick={() =>
                                  this.handleIndexType(
                                    surveyId,
                                    item.template,
                                    indexType === item.template
                                  )
                                }
                                className={
                                  indexType === item.template
                                    ? "text-[#2196F3] hover:text-[#2196F3]  text-sm block  font-normal "
                                    : "text-[#3D405B] hover:text-[#2196F3] text-sm block  font-normal "
                                }
                              >
                                {item.name + " Insight"}
                              </p>
                            )
                          )
                        : null}

                      {otherInnerLabelList && otherInnerLabelList.length > 0
                        ? otherInnerLabelList.map((item, index) => (
                            <p
                              onClick={() =>
                                this.handleIndexType(
                                  surveyId,
                                  item.template,
                                  indexType === item.template
                                )
                              }
                              className={
                                indexType === item.template
                                  ? "text-[#2196F3] hover:text-[#2196F3]  text-sm block  font-normal "
                                  : "text-[#3D405B] hover:text-[#2196F3] text-sm block  font-normal "
                              }
                            >
                              {item.name}
                            </p>
                          ))
                        : null}
                    </div>
                    {/* Submenu end */}
                  </>
                ))
              : null}

            {otherLifecycleList && otherLifecycleList.length > 0
              ? otherLifecycleList.map((item, index) =>
                  item.show ? (
                    <div
                      title={item.name}
                      key={index}
                      onClick={() => this.handleLifecyclePTM(item.template)}
                      className={
                        onComponentNumber === item.template
                          ? "flex justify-between  items-center hover:bg-blue-50 text-gray-500 cursor-pointer bg-blue-50  border-l-2 border-blue-500"
                          : "flex justify-between items-center hover:bg-blue-50 text-gray-500 cursor-pointer"
                      }
                    >
                      <div className="flex items-center">
                        <div
                          className={
                            onComponentNumber === item.template
                              ? "flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center"
                              : "flex items-center px-4 py-4 w-16   h-12 justify-center"
                          }
                        >
                          {item && item.icon ? (
                            <span className="w-16 p-1">
                              {" "}
                              <img
                                className={
                                  onComponentNumber === item.template
                                    ? "text-blue-500"
                                    : ""
                                }
                                src={
                                  onComponentNumber === item.template
                                    ? getIconsActive(item.icon)
                                    : getIcons(item.icon)
                                }
                                alt="icons"
                              />
                            </span>
                          ) : null}
                        </div>
                        <span
                          className={
                            onComponentNumber === item.template
                              ? " text-sm text-[#2196F3]"
                              : " text-sm text-[#3D405B] "
                          }
                        >
                          {item.name}
                        </span>
                      </div>
                    </div>
                  ) : null
                )
              : null}
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(SurveySubSidebar);
