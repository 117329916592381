import React, { Component } from 'react';
import { connect } from 'react-redux';

//api call
import { dashboardActions, userActions, growActions, surveyActions } from '../../_actions';

import LoadingOverlay from 'react-loading-overlay';

// import ManagerActionPlan from './ManagerActionPlan/ManagerActionPlan';

import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
// import Survey from '../SurveyMgmt/Survey/Survey';
import DimensionListComponent from './DimensionList/DimensionList';
import ActionListComponent from './ActionList/ActionList';
import TaskListComponent from './TaskList/TaskList';
import ResourceListComponent from './ResourceList/ResourceList';
import TaskDetailsComponent from './TaskList/TaskDetails';
import ResourceDetailsComponent from './ResourceList/ResourceDetails';
import CompleteModal from './CompleteModal';
import EmptyDimensions from './EmptyDimensions';
import StartModal from './StartModal';

import ReportModal from './ReportModal/ReportModal';

import { CONST } from '../../_config';

import CreateGoalModal from './GoalPlan/CreateGoalModal';
import CreateTaskModal from './GoalPlan/CreateTaskModal';

import UpdateTaskModal from './GoalPlan/UpdateTaskModal';
import UpdateGoalModal from './GoalPlan/UpdateGoalModal';
import TaskCompleteModal from './GoalPlan/TaskCompleteModal';
import TaskStartModal from './GoalPlan/TaskStartModal';

import TaskDeleteModal from './GoalPlan/TaskDeleteModal';
import TaskRemarkModal from './GoalPlan/TaskRemarkModal';

import GoalDeleteModal from './GoalPlan/GoalDeleteModal';

import GrowMonth from './Grow/GrowMonth/GrowMonth';


import moment from 'moment'

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CurrentSurveyId: "",
      CurrentSurveyName: "",
      showDimensionCards: false,
      CurrentDimensionId: "",
      CurrentDimensionData: "",
      CurrentActionId: "",
      ShowComponent: 1,
      CurrentActionData: "",
      CurrentTaskId: "",
      CurrentTaskData: "",
      CurrentResourceId: "",
      CurrentResourceData: "",
      showCompleteModal: false,
      showStartModal: false,
      remarkTask: "",
      remarkResource: "",
      showTaskDeleteModal: false,

      showTaskRemarkModal: false,

      showSidebar: true,

      errorList: [],

      ShowScreens: "goalplan",

      DimensionList: [
        {
          index: 1,
          name: 'Happiness',
          cutOff: 59
        },
        {
          index: 2,
          name: 'Engagement',
          cutOff: 78

        },
        {
          index: 4,
          name: 'Stress',
          cutOff: 28

        },
        {
          index: 5,
          name: 'Manager',
          cutOff: 50
        }
      ],


      ManagerReportFConfig: {
        "managerIdType": CONST.MANAGER_ID_TYPE //Int or String
      },
      showCreateGoalModal: false,
      goalFormData: {
        "name": "",
        "desc": ""
      },
      goalUpdateFormData: {
        "id": "",
        "name": "",
        "desc": ""
      },
      crrGoalId: "",
      taskFormData: {
        "name": "",
        "desc": "",
        "timeline": "",
        "goalId": "",
        "employeeList": [],
        "startDate": "",
        "endDate": "",
        "remark": "",
        "deadline": ""

      },
      taskUpdateFormData: {
        "id": "",
        "name": "",
        "desc": "",
        "timeline": "",
        "goalId": "",
        "employeeList": [],
        "startDate": "",
        "endDate": "",
        "remark": "",
        "deadline": ""

      },
      formBatchRange: {
        "pageNo": 1,
        "size": 5
      },
      search: "",
      showUpdateGoalModal: false,
      showTaskCompleteModal: false,
      showTaskUpdateModal: false,
      taskCompleteFormData: {
        "id": "",
        "remark": ""
      },
      taskStartFormData: {
        "id": ""
      },
      goalDeleteFormData: {
        "id": ""
      },
      showDeleteGoalModal: false,
      empSize: 5,
      empOffset: 0,
      empPage: 1,
      crrMainGoalData: {},
      taskStatus: "all",
      ViewPlanDetailModal: false,
      crrMainPlanData: {},

      submitSucess: false

    }
  }
  componentDidMount() {

    // this.props.dispatch(dashboardActions.getManagerActionPlan({}));
    this.callInitGoalPlan()



    this.props.dispatch(dashboardActions.getIDPLastDate({}))


    this.props.dispatch(dashboardActions.emptyManagerActionPlan());
    this.props.dispatch(dashboardActions.getManagerProfile());
    // this.props.dispatch(dashboardActions.getSurvey(data,"ManageActionPlan"));

    let data6 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.getEmpFilter());
    this.props.dispatch(dashboardActions.getAllParameterList(data6));

    this.props.dispatch(dashboardActions.getMNGRSurveyList(data6));


  }

  handleLogout = () => {
    this.props.dispatch(userActions.logout());
  }




  ChooseSurvey = (data, check) => {
    this.setState({ CurrentSurveyId: data.id, CurrentSurveyName: data.name, showDimensionCards: check ? true : false });
    this.props.dispatch(dashboardActions.getManagerActionPlan({ surveyId: data.id }));
  }

  onSelectDimension = (parameter) => {
    this.setState({ CurrentDimensionId: parameter.id, CurrentDimensionData: parameter });
    this.setState({ CurrentActionId: '', ShowComponent: 1, CurrentResourceId: '', CurrentResourceData: "", CurrentTaskId: "", CurrentTaskData: "" });

  }

  onSelectAction = (action) => {
    if (action) {
      this.setState({ CurrentActionId: action.id, CurrentActionData: action, ShowComponent: 1 });
    }
    else {
      this.setState({ CurrentActionId: "", CurrentActionData: "", ShowComponent: 1 });
    }
  }

  navigate = (page) => {
    if (page === "tasklist") {
      this.setState({ ShowComponent: 1 });
    }
    else if (page === "resourcelist") {
      this.setState({ ShowComponent: 2 });
    }
    else if (page === "actionlist") {
      this.setState({ CurrentActionId: "", CurrentActionData: "", ShowComponent: 1 });
    }
  }

  onSelectTask = (task, number) => {
    ////console.log("task------->")
    this.setState({ CurrentTaskId: task.id, CurrentTaskData: { ...task, "number": number }, ShowComponent: 3 });
  }
  onSelectResource = (resource, number) => {
    this.setState({ CurrentResourceId: resource.id, CurrentResourceData: { ...resource, "number": number }, ShowComponent: 4 });
  }


  handleManagerActionTaskComplete = (task, isStart) => {
    let { dashboard } = this.props;
    let { SurveyList } = dashboard;
    let { CurrentSurveyId } = this.state;

    // let surveyId="";
    // if(CurrentSurveyId){
    //     surveyId = CurrentSurveyId;
    // }
    // else{
    //     surveyId=(SurveyList && SurveyList.length>0)?SurveyList[0].id:""
    // }

    let data = {
      "actionId": task.actionId,
      "taskId": task.taskId,
      "remark": task.remark,
      "emp_id": task.emp_id,
      "isStart": isStart
    }
    console.log('e------------------------------->', data)
    let risktype = this.state.ShowScreens === "riskactionplan" ? 2 : null
    this.props.dispatch(dashboardActions.updateEmployeeTask(data, risktype));
    this.setState({ showCompleteModal: false, showStartModal: false });

  }



  handleManagerActionResourceComplete = (resource) => {
    let { dashboard } = this.props;
    let { SurveyList } = dashboard;
    let { CurrentSurveyId } = this.state;

    // let surveyId="";
    // if(CurrentSurveyId){
    //     surveyId = CurrentSurveyId;
    // }
    // else{
    //     surveyId=(SurveyList && SurveyList.length>0)?SurveyList[0].id:""
    // }

    let data = {
      // "surveyId": surveyId,
      "actionId": resource.actionId,
      "resourceId": resource.resourceId,
      "remark": resource.remark,
      "emp_id": resource.emp_id
    }
    console.log('emp_id: ', data)
    let risktype = this.state.ShowScreens === "riskactionplan" ? 2 : null;
    this.props.dispatch(dashboardActions.updateEmployeeResource(data, risktype));
    this.setState({ showCompleteModal: false });

  }


  onComplete = () => {
    this.setState({ showCompleteModal: true });
  }

  onStartTask = () => {
    this.setState({ showStartModal: true });
  }


  closeCompleteModal = () => {
    this.setState({ showCompleteModal: false });
  }

  closeStartModal = () => {
    this.setState({ showStartModal: false });
  }


  handleRemark = (e) => {
    console.log('e------------------------------->', e.target.value)
    let { ShowComponent } = this.state;
    if (ShowComponent === 3) {
      this.setState({ remarkTask: e.target.value });
    }
    else if (ShowComponent === 4) {
      this.setState({ remarkResource: e.target.value });
    }
  }
  onRemarkCompleteSubmit = (data) => {
    let { CurrentTaskId, CurrentResourceId, ShowComponent, CurrentActionId, remarkTask, remarkResource } = this.state;
    let { ManagerProfileData } = this.props.dashboard;
    let emp_id = '';
    if (ManagerProfileData && ManagerProfileData.ManagerPID) {
      emp_id = ManagerProfileData.emp_id;
    }

    if (ShowComponent === 3) {
      let data1 = {
        "taskId": CurrentTaskId,
        "remark": remarkTask,
        "actionId": CurrentActionId,
        "emp_id": emp_id
      }
      let isStart = false;
      this.handleManagerActionTaskComplete(data1, isStart)
      ////console.log("data1:",data1)
      this.setState({ ShowComponent: 1 });

    }
    else if (ShowComponent === 4) {

      let data2 = {
        "resourceId": CurrentResourceId,
        "remark": remarkResource,
        "actionId": CurrentActionId,
        "emp_id": emp_id
      }
      this.handleManagerActionResourceComplete(data2)
      ////console.log("data2:",data2)

      this.setState({ ShowComponent: 2 });
    }


  }


  onStartTaskEntry = (data) => {
    let { CurrentTaskId, CurrentResourceId, ShowComponent, CurrentActionId, remarkTask, remarkResource } = this.state;
    let { ManagerProfileData } = this.props.dashboard;
    let emp_id = '';
    if (ManagerProfileData && ManagerProfileData.ManagerPID) {
      emp_id = ManagerProfileData.emp_id;
    }

    if (ShowComponent === 3) {
      let data1 = {
        "taskId": CurrentTaskId,
        "actionId": CurrentActionId,
        "remark": "",
        "emp_id": emp_id
      }
      let isStart = true;
      this.handleManagerActionTaskComplete(data1, isStart)
      ////console.log("data1:",data1)
      this.setState({ ShowComponent: 1 });

    }
    // else if(ShowComponent===4){

    //   let data2 = {
    //     "resourceId":CurrentResourceId,
    //     "actionId":CurrentActionId,
    //     "emp_id":emp_id
    //   }
    //   this.handleManagerActionResourceComplete(data2)
    //   ////console.log("data2:",data2)

    //   this.setState({ShowComponent:2});
    // }


  }

  onAssignManagerActionPlan = () => {
    let { CurrentSurveyId } = this.state;
    this.props.dispatch(dashboardActions.AssignManagerActionPlan({ surveyId: CurrentSurveyId }));

  }


  callInitGoalPlan = () => {
    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.getIDPLastDate({}))
    this.props.dispatch(dashboardActions.getGoalList(data1));
  }

  callInitManagerReport = () => {
    let data6 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.getManagerProfile());
    this.props.dispatch(dashboardActions.getMNGRSurveyList(data6));
  }

  handleShowScreen = (value) => {
    this.setState({ ShowScreens: value })
    if (value === "actionplan") {
      this.props.dispatch(dashboardActions.getManagerActionPlan({}));
    } else if (value === "riskactionplan") {
      this.props.dispatch(dashboardActions.getEmployeeActionPlan({}));
    } else if (value === "goalplan") {
      this.callInitGoalPlan()
    } else if (value === "report") {
      this.callInitManagerReport()
    } else if (value === "grow") {
      this.props.dispatch(growActions.getGrowMonth());
    }


  }


  onCloseManagerReportModal = () => {
    this.handleShowScreen("goalplan")
  }


  onLoadManagerReport = () => {
    let { dashboard } = this.props;
    let { getIndexData, ManagerProfileData } = dashboard;

    let { ManagerReportFConfig } = this.state;

    let IndexList = getIndexData ? Object.keys(getIndexData) : []

    let mngrId = ManagerProfileData && ManagerProfileData["emp_id"] ? ManagerProfileData["emp_id"] : "";
    let mngrDMGValue = ManagerProfileData && ManagerProfileData["Department"] ? ManagerProfileData["Department"] : "";

    if (mngrId) {

      let data1 = {
        "surveyId": this.state.CurrentSurveyId
      }

      this.props.dispatch(dashboardActions.getOverallScoreBySurveyId(data1));

      this.props.dispatch(surveyActions.getSurveyById(data1));
      this.props.dispatch(dashboardActions.getIndex(data1));


      let ManagerIDNumber = mngrId;

      if (ManagerReportFConfig && ManagerReportFConfig.managerIdType) {
        if (ManagerReportFConfig.managerIdType === "Int") {
          ManagerIDNumber = parseInt(mngrId)
        }
        else if (ManagerReportFConfig.managerIdType === "String") {
          ManagerIDNumber = (mngrId).toString()
        }
      }



      this.props.dispatch(dashboardActions.getIndexFilter({
        "surveyId": this.state.CurrentSurveyId,
        "filters": [
          {
            "level": "ManagerPID",
            "value": ManagerIDNumber
          }
        ]
      }));








      if (IndexList.some(prev => (prev.toString()) === "1") && getIndexData && getIndexData["1"] || true) {

        this.props.dispatch(dashboardActions.getDemographicIndexHappiness({
          ...data1, "xAxis": "ManagerPID"
        }));

        //Happiness Manager
        this.props.dispatch(dashboardActions.getHappinessQuestion({
          ...data1,
          "template": 1,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getHappinessManagerQuestion({
          ...data1,
          "template": 1,
          "filters": [
            {
              "level": "ManagerPID",
              "value": ManagerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getHappinessDMGQuestion({
            ...data1,
            "template": 1,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }
      }






      if (IndexList.some(prev => (prev.toString()) === "2") && getIndexData && getIndexData["2"] || true) {

        this.props.dispatch(dashboardActions.getDemographicIndexEngagement({
          ...data1, "xAxis": "ManagerPID"
        }));

        //Engagement Manager
        this.props.dispatch(dashboardActions.getEngagementQuestion({
          ...data1,
          "template": 2,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getEngagementManagerQuestion({
          ...data1,
          "template": 2,
          "filters": [
            {
              "level": "ManagerPID",
              "value": ManagerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getEngagementDMGQuestion({
            ...data1,
            "template": 2,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }
      }


      if (IndexList.some(prev => (prev.toString()) === "4") && getIndexData && getIndexData["4"] || true) {

        this.props.dispatch(dashboardActions.getDemographicIndexStress({
          ...data1, "xAxis": "ManagerPID"
        }));

        //Stress Manager
        this.props.dispatch(dashboardActions.getStressQuestion({
          ...data1,
          "template": 4,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getStressManagerQuestion({
          ...data1,
          "template": 4,
          "filters": [
            {
              "level": "ManagerPID",
              "value": ManagerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getStressDMGQuestion({
            ...data1,
            "template": 4,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }
      }





      if (IndexList.some(prev => (prev.toString()) === "5") && getIndexData && getIndexData["5"] || true) {

        this.props.dispatch(dashboardActions.getDemographicIndexManager({
          ...data1, "xAxis": "ManagerPID"
        }));


        //Manager Manager
        this.props.dispatch(dashboardActions.getManagerQuestion({
          ...data1,
          "template": 5,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getManagerManagerQuestion({
          ...data1,
          "template": 5,
          "filters": [
            {
              "level": "ManagerPID",
              "value": ManagerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getManagerDMGQuestion({
            ...data1,
            "template": 5,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }


      }

      if (IndexList.some(prev => (prev.toString()) === "6") && getIndexData && getIndexData["6"] || true) {

        this.props.dispatch(dashboardActions.getDemographicIndexLeader({
          ...data1, "xAxis": "ManagerPID"
        }));

        //Leader Manager
        this.props.dispatch(dashboardActions.getLeaderQuestion({
          ...data1,
          "template": 6,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getLeaderManagerQuestion({
          ...data1,
          "template": 6,
          "filters": [
            {
              "level": "ManagerPID",
              "value": ManagerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getLeaderDMGQuestion({
            ...data1,
            "template": 6,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }



      }








    }


  }



  saveSurveyData = (surveyData) => {
    let SurveyId = surveyData && surveyData.id ? surveyData.id : "";
    this.setState({ CurrentSurveyId: SurveyId })
  }

  openCreateGoalModal = (check) => {
    this.setState({ showCreateGoalModal: check })
  }

  openDeleteGoalModel = (goalId, check) => {

    if (check) {
      let data = {
        "id": goalId.id
      }
      this.setState({ goalDeleteFormData: data, showDeleteGoalModal: check });
    }
    else {
      let clear = {
        "id": ""
      }
      this.setState({ goalDeleteFormData: clear, showDeleteGoalModal: check });
    }

  }


  deleteGoalSubmit = () => {
    let data = {
      "id": this.state.goalDeleteFormData.id
    }
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    ////console.log("data-------------->",data)
    this.props.dispatch(dashboardActions.deleteGoal(data, temp));
    this.setState({ showDeleteGoalModal: false }, () => {
      let clear = {
        "id": ""
      }
      this.setState({ goalDeleteFormData: clear })
    })
  }

  openUpdateGoalModel = (goalId, check) => {

    if (check) {
      let data = {
        "id": goalId.id,
        "name": goalId.name,
        "desc": goalId.desc
      }
      this.setState({ goalUpdateFormData: data, showUpdateGoalModal: check });
    }
    else {
      this.setState({ showUpdateGoalModal: check });
    }

  }

  handleGoalInput = (e) => {
    let { value, name } = e.target;
    let { goalFormData } = this.state;
    goalFormData[name] = value;
    this.setState({ goalFormData })
  }

  handleUpdateGoalInput = (e) => {
    let { value, name } = e.target;
    let { goalUpdateFormData } = this.state;
    goalUpdateFormData[name] = value;
    this.setState({ goalUpdateFormData })
  }

  handleTaskCompleteInput = (e) => {
    let { value, name } = e.target;
    let { taskCompleteFormData } = this.state;
    taskCompleteFormData[name] = value;
    this.setState({ taskCompleteFormData })
  }

  handleTaskInput = (e) => {
    let { value, name } = e.target;
    let { taskFormData } = this.state;
    taskFormData[name] = value;
    this.setState({ taskFormData })
  }

  handleUpdateTaskInput = (e) => {
    let { value, name } = e.target;
    let { taskUpdateFormData } = this.state;
    taskUpdateFormData[name] = value;
    this.setState({ taskUpdateFormData })
  }

  submitCreateGoal = () => {
    let data = {
      "name": this.state.goalFormData.name,
      "desc": this.state.goalFormData.desc,
      "planList": []
    }
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.createGoal(data, temp));
    this.setState({ showCreateGoalModal: false }, () => {

      let clearData = {
        "name": "",
        "desc": ""
      }
      this.setState({ goalFormData: clearData })

    })


  }

  submitUpdateGoal = () => {
    let data = {
      "id": this.state.goalUpdateFormData.id,
      "name": this.state.goalUpdateFormData.name,
      "desc": this.state.goalUpdateFormData.desc
    }
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.updateGoal(data, temp));
    this.setState({ showUpdateGoalModal: false }, () => {
      let clearData = {
        "id": "",
        "name": "",
        "desc": ""
      }
      this.setState({ goalUpdateFormData: clearData })
    })


  }

  submitTaskStart = () => {
    let data = {
      "id": this.state.taskStartFormData.id
    }
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.startPlan(data, temp));
    this.setState({ showTaskStartModal: false }, () => {
      let clearData = {
        "id": ""
      }
      this.setState({ taskStartFormData: clearData })
    })
  }

  deleteTaskSubmit = () => {
    let data = {
      "id": this.state.crrTaskId
    }
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    ////console.log("data-------------->",data)
    this.props.dispatch(dashboardActions.deletePlan(data, temp));
    this.setState({ showTaskDeleteModal: false }, () => {
      this.setState({ crrTaskId: '' })
    })
  }

  submitTaskComplete = () => {
    let data = {
      "id": this.state.taskCompleteFormData.id,
      "remark": this.state.taskCompleteFormData.remark
    }
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.closePlan(data, temp));
    this.setState({ showTaskCompleteModal: false }, () => {
      let clearData = {
        "id": "",
        "remark": ""
      }
      this.setState({ taskCompleteFormData: clearData })
    })
  }

  openAddPlanModal = (goalId) => {
    this.setState({ crrGoalId: goalId }, () => {
      let { taskFormData } = this.state;
      taskFormData["goalId"] = goalId;
      this.setState({ taskFormData });

      let data5 = {
        "keyWord": "",
        "pageNo": this.state.formBatchRange.pageNo,
        "size": this.state.formBatchRange.size
      }
      // this.props.dispatch(surveyActions.getEmployeeLimitedList(data5));
    })
  }

  submitCreateTask = () => {
    this.setState({ errorList: [] }, () => {



      if (this.state.taskFormData.name && this.state.taskFormData.deadline) {
        let data = {
          "name": this.state.taskFormData.name,
          "desc": this.state.taskFormData.desc,
          "timeline": this.state.taskFormData.timeline,
          "deadline": this.state.taskFormData.deadline,

          "goalId": this.state.crrGoalId,
          "employeeList": this.state.taskFormData.employeeList
          // "startDate":this.state.taskFormData.startDate,
          // "endDate":this.state.taskFormData.endDate,
          // "remark":this.state.taskFormData.remark
        }
        let temp = {
          "keyWord": "",
          "pageNo": 1,
          "size": 500
        }
        this.props.dispatch(dashboardActions.createPlan(data, temp));
        setTimeout(() => {
          this.setState({ crrGoalId: "" }, () => {
            let clearData = {
              "name": "",
              "desc": "",
              "timeline": "",
              "deadline": "",
              "goalId": "",
              "employeeList": [],
              "startDate": "",
              "endDate": "",
              "remark": ""
            }
            this.setState({ taskFormData: clearData, errorList: [] })
          })
        }, 1000)
      }

      else {
        let { errorList } = this.state;
        if (!this.state.taskFormData.name) {
          errorList.push("task name")
        }
        if (!this.state.taskFormData.deadline) {
          errorList.push("task deadline")

        }
        this.setState({ errorList })

      }

    })


  }

  submitUpdateTask = () => {

    this.setState({ errorList: [] }, () => {



      if (this.state.taskUpdateFormData.name && this.state.taskUpdateFormData.deadline) {
        let data = {
          "id": this.state.taskUpdateFormData.id,
          "name": this.state.taskUpdateFormData.name,
          "desc": this.state.taskUpdateFormData.desc,
          "timeline": this.state.taskUpdateFormData.timeline,
          "deadline": this.state.taskUpdateFormData.deadline,
          "employeeList": this.state.taskUpdateFormData.employeeList
          // "startDate":this.state.taskUpdateFormData.startDate,
          // "endDate":this.state.taskUpdateFormData.endDate,
          // "remark":this.state.taskUpdateFormData.remark
        }
        let temp = {
          "keyWord": "",
          "pageNo": 1,
          "size": 500
        }
        this.props.dispatch(dashboardActions.updatePlan(data, temp));
        setTimeout(() => {
          this.setState({ crrTaskId: "", showTaskUpdateModal: false }, () => {
            let clearData = {
              "id": "",
              "name": "",
              "desc": "",
              "timeline": "",
              "deadline": "",
              "goalId": "",
              "employeeList": [],
              "startDate": "",
              "endDate": "",
              "remark": ""
            }
            this.setState({ taskUpdateFormData: clearData, errorList: [] })
          })
        }, 1000)
      }

      else {
        let { errorList } = this.state;
        if (!this.state.taskUpdateFormData.name) {
          errorList.push("task name")
        }
        if (!this.state.taskUpdateFormData.deadline) {
          errorList.push("task deadline")

        }
        this.setState({ errorList })

      }

    })








  }

  handleSelectEmployees = (empId) => {
    let { taskFormData } = this.state;
    let getIndex = taskFormData["employeeList"].findIndex(prev => prev === empId);
    if (getIndex === -1) {
      taskFormData["employeeList"].push(empId)
    }
    else {
      taskFormData["employeeList"].splice(getIndex, 1)
    }
    this.setState({ taskFormData })

  }


  handleUpdateSelectEmployees = (empId) => {
    let { taskUpdateFormData } = this.state;
    let getIndex = taskUpdateFormData["employeeList"].findIndex(prev => prev === empId);
    if (getIndex === -1) {
      taskUpdateFormData["employeeList"].push(empId)
    }
    else {
      taskUpdateFormData["employeeList"].splice(getIndex, 1)
    }
    this.setState({ taskUpdateFormData })

  }

  openTaskCompleteModel = (taskId, check) => {

    this.setState({ crrTaskId: taskId.id, showTaskCompleteModal: check }, () => {
      let data = {
        "id": taskId.id,
        "remark": taskId.remark
      }
      this.setState({ taskCompleteFormData: data })
    })

    if (!check) {
      let clearData = {
        "id": "",
        "remark": ""
      }
      this.setState({ taskCompleteFormData: clearData })
    }

  }

  openTaskStartModel = (taskId, check) => {
    this.setState({ crrTaskId: taskId.id, showTaskStartModal: check }, () => {
      let data = {
        "id": taskId.id
      }
      this.setState({ taskStartFormData: data })
    })

    if (!check) {
      let clearData = {
        "id": ""
      }
      this.setState({ taskStartFormData: clearData })
    }
  }

  closeDeleteModal = (check) => {
    this.setState({ crrTaskId: "", showTaskDeleteModal: check })
  }

  openDeleteTask = (taskId, check) => {
    this.setState({ crrTaskId: taskId.id, showTaskDeleteModal: check })
  }

  openUpdateTaskModel = (taskId, check) => {
    this.setState({ crrTaskId: taskId.id, showTaskUpdateModal: check }, () => {

      let data = {
        "id": taskId.id,
        "name": taskId.name,
        "desc": taskId.desc,
        "timeline": taskId.timeline,
        "deadline": taskId.deadline,
        "employeeList": [],
        "startDate": taskId.startDate,
        "endDate": taskId.endDate,
        "remark": taskId.remark
      }

      // ////console.log("taskId------------------>",taskId)

      // if(taskId && taskId.employeeList && taskId.employeeList.length>0){
      //   taskId.employeeList.forEach((emp)=>{
      //     data["employeeList"].push(emp.id)
      //   })
      // }

      ////console.log("data------------------>",data)

      this.setState({ taskUpdateFormData: data }, () => {
        // if(check){
        //   let data5 = {
        //     "keyWord":"",
        //     "pageNo": this.state.formBatchRange.pageNo,
        //     "size":this.state.formBatchRange.size
        //   }
        //   this.props.dispatch(surveyActions.getEmployeeLimitedList(data5));
        // }

      });

    })

    if (!check) {
      let clearData = {
        "id": "",
        "name": "",
        "desc": "",
        "timeline": "",
        "deadline": "",
        "goalId": "",
        "employeeList": [],
        "startDate": "",
        "endDate": "",
        "remark": ""
      }
      this.setState({ taskUpdateFormData: clearData })
    }
  }

  handleSearch = (e) => {
    this.setState({ search: e.target.value }, () => {
      let data5 = {
        "keyWord": e.target.value,
        "pageNo": this.state.formBatchRange.pageNo,
        "size": this.state.formBatchRange.size
      }
      // this.props.dispatch(surveyActions.getEmployeeLimitedList(data5));
    });
  }


  handleEmpPageClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ empOffset: offset, empPage: page });

    let data5 = {
      "keyWord": this.state.search,
      "pageNo": page,
      "size": this.state.empSize
    }
    // this.props.dispatch(surveyActions.getEmployeeLimitedList(data5));
  }


  handleViewTask = (goal) => {
    this.setState({ crrMainGoalData: goal })
  }

  handelTaskStatus = (check) => {
    this.setState({ taskStatus: check })
  }

  handleViewPlanDetails = (plan, check) => {
    this.setState({ ViewPlanDetailModal: check, crrMainPlanData: plan })
  }


  handleSidebar = (check) => {
    this.setState({ showSidebar: check })
  }

  // showRemarks=(remark)=>{
  //   this.setState({crrRemark:remark})
  // }

  showRemarks = (taskId, check) => {
    this.setState({ crrTaskId: taskId.id, showTaskRemarkModal: check }, () => {

      let data = {
        "id": taskId.id,
        "name": taskId.name,
        "desc": taskId.desc,
        "timeline": taskId.timeline,
        "deadline": taskId.deadline,
        "employeeList": [],
        "startDate": taskId.startDate,
        "endDate": taskId.endDate,
        "remark": taskId.remark
      }

      // ////console.log("taskId------------------>",taskId)

      // if(taskId && taskId.employeeList && taskId.employeeList.length>0){
      //   taskId.employeeList.forEach((emp)=>{
      //     data["employeeList"].push(emp.id)
      //   })
      // }

      ////console.log("data------------------>",data)

      this.setState({ taskUpdateFormData: data }, () => {
      });

    })

    if (!check) {
      let clearData = {
        "id": "",
        "name": "",
        "desc": "",
        "timeline": "",
        "deadline": "",
        "goalId": "",
        "employeeList": [],
        "startDate": "",
        "endDate": "",
        "remark": ""
      }
      this.setState({ taskUpdateFormData: clearData })
    }

  }

  managerDownloadAPI = () => {
    let { CurrentSurveyId } = this.state;
    let data = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.managerDownloadStatus(data));
  }

  callGrowAPI = (isStart, innerapi, data, api) => {

    if (isStart) {
      if (innerapi === "startGrowMonth") {
        this.props.dispatch(growActions.startGrowMonth(data, this.props));
      } else if (innerapi === "startGrowWeek") {
        this.props.dispatch(growActions.startGrowWeek(data, this.props));
      }

    }

    if (api === "getGrowWeekListbyMonthId") {
      this.props.dispatch(growActions.getGrowWeekListbyMonthId(data, this.props));

    } else if (api === "getGrowDayListbyWeekId") {
      this.props.dispatch(growActions.getGrowDayListbyWeekId(data, this.props));

    } else if (api === "startGrowWeek") {
      this.props.dispatch(growActions.startGrowWeek(data, this.props));

    }

    this.setState({ submitSucess: false })
  }

  call_startGrowMonth_getGrowWeekListbyMonthId = (isActive, data, alsoCall) => {
    if (isActive) {
      this.props.dispatch(growActions.startGrowMonth(data, alsoCall));
    }
    else {
      this.props.dispatch(growActions.getGrowWeekListbyMonthId(data));
    }
    this.setState({ submitSucess: false })
  }

  call_startGrowWeek_getGrowDayListbyWeekId = (isActive, data, alsoCall) => {
    if (isActive) {
      this.props.dispatch(growActions.startGrowWeek(data, alsoCall));
    }
    else {
      this.props.dispatch(growActions.getGrowDayListbyWeekId(data));
    }
    this.setState({ submitSucess: false })
  }

  call_startGrowDay_getGrowDayDatabyDayId = (isActive, data1, alsoCall, data2) => {
    if (isActive) {
      this.props.dispatch(growActions.startGrowDay(data1, alsoCall, data2));
    }
    else {
      this.props.dispatch(growActions.getGrowDayDatabyDayId(data2));
    }
    this.setState({ submitSucess: false })
  }

  callDataActionAPI = (data1, apiname, data2) => {

    if (apiname === "getGrowDayDatabyDayId") {
      this.props.dispatch(growActions.getGrowDayDatabyDayId(data1));
      this.setState({ submitSucess: false })
    }
    else if (apiname === "startGrowWeek") {
      this.props.dispatch(growActions.startGrowWeek(data1));
      this.setState({ submitSucess: false })
    }
    else if (apiname === "startGrowDay") {
      this.props.dispatch(growActions.startGrowDay(data1));
      this.setState({ submitSucess: false })
    }
    else if (apiname === "updatecomment") {
      this.props.dispatch(growActions.updatecomment(data1));
      this.setState({ submitSucess: false })
    }
    else if (apiname === "startGrowDayAction") {
      this.props.dispatch(growActions.startGrowDayAction(data1));
      this.setState({ submitSucess: false })
    }
    else if (apiname === "startGrowDayKnowledge") {
      this.props.dispatch(growActions.startGrowDayKnowledge(data1));
      this.setState({ submitSucess: false })
    }
    else if (apiname === "startGrowDayReflection") {
      this.props.dispatch(growActions.startGrowDayReflection(data1));
      this.setState({ submitSucess: false })
    }
    else if (apiname === "submitGrowDay") {
      this.props.dispatch(growActions.submitGrowDay(data1, data2));
      this.setState({ submitSucess: true })
    }


    else if (apiname === "updateEmployeeSubactivityId") {
      this.props.dispatch(growActions.updateEmployeeSubactivityId(data1, data2));
      this.setState({ submitSucess: false })
    }
    else if (apiname === "submitGrowWeek") {
      this.props.dispatch(growActions.submitGrowWeek(data1));
      this.setState({ submitSucess: true })
    }

  }


  render() {

    let { dashboard, survey, grow } = this.props;

    let {
      allGrowParametersList,
      getMonth,
      getWeekListbyMonthId,
      getGrowDayListbyWeekId,
      getGrowDayDatabyDayId,
      submitGrowDaySuccess,
      submitGrowDayData,
      getAllGrowNote

    } = grow;



    let { getEmployeeLimitedListData, getEmployeeLimitedListTotal } = survey;
    let {
      getEmployeeActionPlanData,
      getIDPLastDateData,
      errorUpdateMsg,
      getGoalListData,
      getGoalListTotal,

      ManagerProfileData, loading,
      getManagerActionPlanData,
      SurveyList,

      getOverallScoreBySurveyIdData,

      getDemographicIndexForManagerData,
      getSurveyData,
      EmpFilterData,
      DynaObj,
      getIndexData,
      QuesDynaObj,
      getQuestionReportData,
      QuesMngrHappinessReportObject,
      QuesMngrEngagementReportObject,
      QuesMngrStressReportObject,
      QuesMngrManagerReportObject,

      DriverMngrHappinessReportObject,
      DriverMngrEngagementReportObject,
      DriverMngrStressReportObject,
      DriverMngrManagerReportObject,
      DemographicRestriction,
      getHappinessIndexDriverData,
      getEngagementIndexDriverData,
      getStressIndexDriverData,
      getManagerIndexDriverData,


      getDemographicIndexHappinessData,
      getDemographicIndexEngagementData,
      getDemographicIndexStressData,
      getDemographicIndexManagerData,
      getDemographicIndexLeaderData,

      getHappinessQuestionData,
      getHappinessManagerQuestionData,
      getHappinessDMGQuestionData,

      getEngagementQuestionData,
      getEngagementManagerQuestionData,
      getEngagementDMGQuestionData,

      getStressQuestionData,
      getStressManagerQuestionData,
      getStressDMGQuestionData,

      getManagerQuestionData,
      getManagerManagerQuestionData,
      getManagerDMGQuestionData,

      getLeaderQuestionData,
      getLeaderManagerQuestionData,
      getLeaderDMGQuestionData,


      getAllParameterListData,

      getIndexFilterData,

      getMNGRSurveyListData




    } = dashboard;

    let { goalDeleteFormData, showDeleteGoalModal, showTaskRemarkModal, showTaskDeleteModal, errorList, ViewPlanDetailModal, crrMainPlanData, taskStatus, crrMainGoalData, ManagerReportFConfig, ShowScreens, showStartModal, remarkTask, remarkResource, showCompleteModal, showDimensionCards, CurrentSurveyId, CurrentSurveyName, CurrentDimensionId, CurrentDimensionData, CurrentActionId, ShowComponent,
      CurrentActionData, CurrentTaskId, CurrentTaskData, CurrentResourceId, CurrentResourceData, showCreateGoalModal, crrGoalId, crrTaskId, showUpdateGoalModal, showTaskCompleteModal, showTaskStartModal, showTaskUpdateModal, taskCompleteFormData, showSidebar } = this.state;

    let isAlreadyAssigned = false;
    if (ManagerProfileData && ManagerProfileData.actionPlanStage && ManagerProfileData.actionPlanStage === 1) {
      isAlreadyAssigned = true;
    }


    let showReport = false;
    if (ManagerProfileData && ManagerProfileData.showMNGRReport) {
      showReport = true;
    }

    let isMNGRMoreThan5 = false;
    if (ManagerProfileData && ManagerProfileData.isMNGRMoreThan5) {
      isMNGRMoreThan5 = true;
    }


    let IndexDimension = {
      "Happiness": ['Time', 'Being', 'Relationship', 'WorkLife', 'Achivement', 'Meeningfull'],
      "Engagement": ['Pride', 'Presence', 'Performance'],
      "Stress": ['Recognition', 'WorkSatisfaction', 'Inclusion', 'Collaboration', 'Empowerment'],
      "Manager": ['Connect', 'ACT', 'Respect', 'Empower'],
      "Leader": ['Vision', 'Impact', 'Execute', 'Wisdom']
    }

    let { DimensionList, submitSucess } = this.state;


    let validateList = [];

    let validateIndex = getIndexData;
    let validObj = []
    if (validateIndex) {
      validObj = Object.keys(validateIndex).map((key) => [key, validateIndex[key]])
    }
    console.log('validObj: ', validObj);
    if (validObj && validObj.length > 0) {
      validObj.forEach((item) => {
        if (item[1]) {
          let getIndex = DimensionList.findIndex(prev => prev.index === parseInt(item[0]));
          if (getIndex !== -1 ? DimensionList[getIndex].name : "") {
            validateList.push({
              "name": getIndex !== -1 ? DimensionList[getIndex].name : "",
              "template": parseInt(item[0])
            });
          }

        }
      })
    }




    function GetParameterLabel(name) {
      let AllParameterObj = {};
      if (getAllParameterListData && getAllParameterListData.length > 0) {
        getAllParameterListData.forEach((item) => {
          AllParameterObj[item.name] = item && item.label ? item.label : item.name;
        });
      }
      return (AllParameterObj && AllParameterObj[name] ? AllParameterObj[name] : name);
    }




    let optionType = 2;
    // if(!loading){
    //   if(isAlreadyAssigned){
    //     optionType = 2;
    //   }
    //   else{
    //     optionType = 1;
    //   }
    // }



    if (ManagerProfileData && ManagerProfileData.role && (ManagerProfileData.role === "ADMIN")) {
      this.props.history.push(`/app/survey`)
    }



    let ParameterList = []
    if (getManagerActionPlanData && getManagerActionPlanData.length > 0) {
      getManagerActionPlanData.forEach((action) => {
        if (ParameterList.findIndex(prev => prev.id === action.parameterId.id) === -1) {
          let temp = {
            ...action.parameterId,
            score: action.parameterScore
          }
          ParameterList.push(temp);
        }
      })
    }


    let DimensionId = '';
    let DimensionData = '';
    if (CurrentDimensionId) {
      DimensionId = CurrentDimensionId;
      DimensionData = CurrentDimensionData;
    }
    else {
      if (ParameterList && ParameterList.length > 0) {
        DimensionId = ParameterList[0].id;
        DimensionData = ParameterList[0];
      }
    }




    console.log('CurrentActionId----///--------', CurrentActionId);
    console.log('CurrentTaskId----///------------', CurrentTaskId);



    let surveyLogo = "";

    let surveyId = CurrentSurveyId ? CurrentSurveyId : "";

    // if(surveyId === "618b673c9503f41bad61667f"){
    //   surveyLogo = 'img/logo/kotaklifelogo.png';

    //   // surveyLogo = 'img/logo/tcillogo.jpeg';

    // }
    // else if(surveyId === "61541d6cf0f59b75a9b50d44"){
    //   surveyLogo = 'img/logo/tcillogo.jpeg';

    //   // surveyLogo = 'img/logo/kotaklogo.jpg';

    // }
    // else if(surveyId === "61b1850ada0e4ed403288e52"){
    //   surveyLogo = 'img/logo/tcellogo.jpg';
    // }
    // else if(surveyId === "61c06c871a708fbb5cf1c9a3"){
    //   surveyLogo = 'img/logo/bppllogo.png';
    // }
    // else if(surveyId === "5fa04d316ede0f0336066cce"){
    //   surveyLogo = 'img/logo/happypluslogo.png';
    // }

    if (surveyId === "618b673c9503f41bad61667f") {
      surveyLogo = 'img/logo/kotaklifelogo.png';
    }
    else if (surveyId === "61541d6cf0f59b75a9b50d44") {
      surveyLogo = 'img/logo/tcillogo.jpeg';
    }
    else if (surveyId === "61b1850ada0e4ed403288e52") {
      surveyLogo = 'img/logo/tcellogo.jpg';
    }
    else if (surveyId === "61c06c871a708fbb5cf1c9a3") {
      surveyLogo = 'img/logo/bppllogo.png';
    }
    else if (surveyId === "5fa04d316ede0f0336066cce") {
      surveyLogo = 'img/logo/happypluslogo.png';
    }
    else if (surveyId === "621dc1de0f73b3a8e532f1b5") {
      surveyLogo = 'img/logo/axissecuritylogo.png';
    }





    let PlanList = []
    if (crrMainGoalData) {
      if (getGoalListData && getGoalListData.length > 0) {
        let getIndex = getGoalListData.findIndex(prev => prev.id === crrMainGoalData.id)
        if (getIndex !== -1) {
          let newPlanList = getGoalListData[getIndex].planList;

          if (taskStatus === "completed") {
            ////console.log("PlanList--------->",PlanList)
            PlanList = (newPlanList.filter(prev => prev.isComplete === true)).filter(prev => (prev.isStarted === true))
            ////console.log("PlanList--------->",PlanList)

          }
          else if (taskStatus === "pending") {
            ////console.log("PlanList--------->",PlanList)

            PlanList = (newPlanList.filter(prev => (prev.isComplete === false))).filter(prev => (prev.isStarted === true))
          }
          else {
            PlanList = newPlanList
          }



        }
      }
    }





    ////console.log("PlanList---------->",PlanList)



    function getTaskStatus(listPlan) {
      let allCount = 0;
      let completedCount = 0;
      if (listPlan && listPlan.length > 0) {
        allCount = listPlan.length
        completedCount = (listPlan.filter(prev => prev.isComplete === true)).filter(prev => (prev.isStarted === true)).length
      }
      return {
        "completedCount": completedCount,
        "allCount": allCount
      }

    }


    return (
      <>

        <LoadingOverlay
          active={loading} 
          spinner
          text='Loading Data...'
          className="loader"
        > 
          <div className='h-screen overflow-hidden bg-gray-50'>

            <Header
              ManagerProfileData={ManagerProfileData}
              handleLogout={this.handleLogout}
            /> 
            <div className="flex w-full"> 
              <Sidebar
                currSidebarValue={this.state.ShowScreens}
                handleShowScreen={this.handleShowScreen}
                handleSidebar={this.handleSidebar}
                showSidebar={this.state.showSidebar}
              /> 
              <main className="flex-1 w-full p-4 overflow-y-auto bg-[#f9fafb] md:p-6 " style={{ height: "calc(100vh - 4rem)" }}> 
                {/* {ShowScreens==="intro"?
      <div className='grid items-center justify-center grid-cols-1 mx-4 mt-24 ml-6 lg:grid-cols-3'>
        
        
        <div onClick={()=>this.handleShowScreen("report")} className='mt-4 mr-6 bg-white rounded-lg shadow-lg cursor-pointer'>
          <h1 className='w-full p-2 font-semibold text-center text-gray-600 rounded-lg hover:bg-blue-500 hover:text-white'>Manager Report</h1>
        </div>


        <div onClick={()=>this.handleShowScreen("actionplan")} className='mt-4 mr-6 bg-white rounded-lg shadow-lg cursor-pointer'>
          <h1 className='w-full p-2 font-semibold text-center text-gray-600 rounded-lg hover:bg-blue-500 hover:text-white'>Action Plan</h1>
        </div>

        <div onClick={()=>this.handleShowScreen("goalplan")} className='mt-4 mr-6 bg-white rounded-lg shadow-lg cursor-pointer'>
          <h1 className='w-full p-2 font-semibold text-center text-gray-600 rounded-lg hover:bg-blue-500 hover:text-white'>Goal Plan</h1>
        </div>



      </div>
    :null} */}


                {ShowScreens === "report" && showReport && isMNGRMoreThan5 ?
                  <div className="mx-auto" >
                    <div className="items-center justify-between md:flex ">
                      {/* left side */}
                      <div className="flex items-center">
                        <div className='pt-3 pl-3'><span onClick={() => this.handleShowScreen("intro")} className='cursor-pointer material-symbols-outlined'>arrow_back</span></div>

                        <h1 className="text-lg font-medium text-slate-800">{ManagerProfileData && ManagerProfileData.name ? ManagerProfileData.name + " (Report)" : "Manager Report"}</h1>
                      </div>

                    </div>


                    <ReportModal
                      onCloseManagerReportModal={this.onCloseManagerReportModal}
                      getDemographicIndexForManagerData={getDemographicIndexForManagerData}
                      getSurveyData={getSurveyData}
                      SelectManager={this.SelectManager}
                      EmpFilterData={EmpFilterData}
                      DynaObj={DynaObj}
                      getIndexData={getIndexData}
                      getReportMngrQuestion={this.getReportMngrQuestion}
                      getReportMngrDriver={this.getReportMngrDriver}
                      QuesDynaObj={QuesDynaObj}
                      getQuestionReportData={getQuestionReportData}

                      QuesMngrHappinessReportObject={QuesMngrHappinessReportObject}
                      QuesMngrEngagementReportObject={QuesMngrEngagementReportObject}
                      QuesMngrStressReportObject={QuesMngrStressReportObject}
                      QuesMngrManagerReportObject={QuesMngrManagerReportObject}

                      DriverMngrHappinessReportObject={DriverMngrHappinessReportObject}
                      DriverMngrEngagementReportObject={DriverMngrEngagementReportObject}
                      DriverMngrStressReportObject={DriverMngrStressReportObject}
                      DriverMngrManagerReportObject={DriverMngrManagerReportObject}
                      DemographicRestriction={DemographicRestriction}
                      validateList={validateList}

                      getHappinessIndexDriverData={getHappinessIndexDriverData}
                      getEngagementIndexDriverData={getEngagementIndexDriverData}
                      getStressIndexDriverData={getStressIndexDriverData}
                      getManagerIndexDriverData={getManagerIndexDriverData}


                      getDemographicIndexHappinessData={getDemographicIndexHappinessData}
                      getDemographicIndexEngagementData={getDemographicIndexEngagementData}
                      getDemographicIndexStressData={getDemographicIndexStressData}
                      getDemographicIndexManagerData={getDemographicIndexManagerData}
                      getDemographicIndexLeaderData={getDemographicIndexLeaderData}



                      getHappinessQuestionData={getHappinessQuestionData}
                      getHappinessManagerQuestionData={getHappinessManagerQuestionData}
                      getHappinessDMGQuestionData={getHappinessDMGQuestionData}

                      getEngagementQuestionData={getEngagementQuestionData}
                      getEngagementManagerQuestionData={getEngagementManagerQuestionData}
                      getEngagementDMGQuestionData={getEngagementDMGQuestionData}

                      getStressQuestionData={getStressQuestionData}
                      getStressManagerQuestionData={getStressManagerQuestionData}
                      getStressDMGQuestionData={getStressDMGQuestionData}



                      getManagerQuestionData={getManagerQuestionData}
                      getManagerManagerQuestionData={getManagerManagerQuestionData}
                      getManagerDMGQuestionData={getManagerDMGQuestionData}

                      getLeaderQuestionData={getLeaderQuestionData}
                      getLeaderManagerQuestionData={getLeaderManagerQuestionData}
                      getLeaderDMGQuestionData={getLeaderDMGQuestionData}


                      IndexDimension={IndexDimension}
                      onLoadManagerReport={this.onLoadManagerReport}
                      GetParameterLabel={GetParameterLabel}

                      surveyLogo={surveyLogo}


                      ClientProfileData={ManagerProfileData}
                      CurrentManagerData={ManagerProfileData}


                      getManagerIndexByFilterData={getIndexFilterData}

                      getMNGRSurveyListData={getMNGRSurveyListData}

                      saveSurveyData={this.saveSurveyData}

                      showReport={showReport}

                      ManagerReportFConfig={ManagerReportFConfig}

                      getOverallScoreBySurveyIdData={getOverallScoreBySurveyIdData}

                      managerDownloadAPI={this.managerDownloadAPI}

                    />




                  </div>
                  : null}


                {!showReport && ShowScreens === "report" ?
                  <div className="flex items-center justify-between px-4 pt-12 text-lg font-semibold text-center text-gray-500">Thank you for building happiness habits,The Manager score card feature not enabled for you !</div>
                  : null}


                {showReport && !isMNGRMoreThan5 && ShowScreens === "report" ?
                  <div className="flex items-center justify-between px-4 pt-12 text-lg font-semibold text-center text-gray-500">
                    Manager Scorecard is generated for the manager with 5 or more direct responded reportees!</div>
                  : null}





                {ShowScreens === "actionplan" ?
                  <div className="h-full mx-auto space-y-6"  >
                    <div className="items-center justify-between md:flex ">
                      <div className="flex items-center">
                        {/* <div className='pt-3 pl-3'><span onClick={()=>this.handleShowScreen("intro")} className='cursor-pointer material-symbols-outlined'>arrow_back</span></div> */}

                        <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B] ">{ManagerProfileData && ManagerProfileData.name ? ManagerProfileData.name + " (Action Plan)" : "Manager Action Plan"}</h1>
                      </div>

                    </div>
                    {!(getManagerActionPlanData && getManagerActionPlanData.length > 0) ?
                      <>

                        <div className='flex items-center justify-center'>

                          <div className="flex items-center justify-center w-full mt-4">
                            <div className="text-center">
                              <img src="img/empty_survey.png" className="mb-4" />
                              <h1 className="font-semibold text-gray-800 cursor-default text-md">Action Plan Not Available!</h1>
                            </div>
                          </div>

                        </div>



                      </> :

                      <>


                        {ParameterList && ParameterList.length > 0 ?
                          <>
                            <div className="grid h-full grid-cols-1 gap-6 lg:grid-cols-3">

                              <DimensionListComponent
                                ParameterList={ParameterList}
                                onSelectDimension={this.onSelectDimension}
                                CurrentDimensionId={DimensionId}
                              />

                              {CurrentActionId ?
                                <>

                                  {ShowComponent === 1 ?
                                    <TaskListComponent
                                      getManagerActionPlanData={getManagerActionPlanData}
                                      CurrentDimensionData={DimensionData}
                                      CurrentActionId={CurrentActionId}
                                      CurrentActionData={CurrentActionData}
                                      navigate={this.navigate}
                                      onSelectTask={this.onSelectTask}

                                    />
                                    : null}

                                  {ShowComponent === 2 ?
                                    <ResourceListComponent
                                      getManagerActionPlanData={getManagerActionPlanData}
                                      CurrentDimensionData={DimensionData}
                                      CurrentActionId={CurrentActionId}
                                      CurrentActionData={CurrentActionData}
                                      navigate={this.navigate}
                                      onSelectResource={this.onSelectResource}
                                    />
                                    : null}

                                  {ShowComponent === 3 ?
                                    <TaskDetailsComponent
                                      getManagerActionPlanData={getManagerActionPlanData}
                                      CurrentDimensionData={DimensionData}
                                      CurrentActionId={CurrentActionId}
                                      CurrentActionData={CurrentActionData}
                                      navigate={this.navigate}
                                      onSelectTask={this.onSelectTask}
                                      CurrentTaskId={CurrentTaskId}
                                      CurrentTaskData={CurrentTaskData}
                                      onComplete={this.onComplete}
                                      onStartTask={this.onStartTask}
                                    />
                                    : null}

                                  {ShowComponent === 4 ?
                                    <ResourceDetailsComponent
                                      getManagerActionPlanData={getManagerActionPlanData}
                                      CurrentDimensionData={DimensionData}
                                      CurrentActionId={CurrentActionId}
                                      CurrentActionData={CurrentActionData}
                                      navigate={this.navigate}
                                      onSelectResource={this.onSelectResource}
                                      CurrentResourceId={CurrentResourceId}
                                      CurrentResourceData={CurrentResourceData}
                                      onComplete={this.onComplete}
                                    />
                                    : null}

                                </>
                                :
                                <ActionListComponent
                                  getManagerActionPlanData={getManagerActionPlanData}
                                  CurrentDimensionId={DimensionId}
                                  onSelectAction={this.onSelectAction}
                                />
                              }

                            </div>


                          </>
                          :
                          <>
                            {!loading ?
                              <EmptyDimensions />
                              : null}
                          </>
                        }



                      </>}


                    {showCompleteModal ?
                      <CompleteModal closeCompleteModal={this.closeCompleteModal}

                        tempData={ShowComponent === 3 ? CurrentTaskData : CurrentResourceData}
                        remarkContent={ShowComponent === 3 ? remarkTask : remarkResource}

                        handleRemark={this.handleRemark}
                        onRemarkCompleteSubmit={this.onRemarkCompleteSubmit} />
                      : null}


                    {showStartModal ?
                      <StartModal closeStartModal={this.closeStartModal}
                        onStartTaskEntry={this.onStartTaskEntry} />
                      : null}





                  </div>
                  : null}


                {ShowScreens === "riskactionplan" ?
                  <div className="h-full mx-auto " >
                    <div className="items-center justify-between md:flex">
                      <div className="flex items-center">

                        {CurrentActionId ?
                          <>
                            <div className='pt-3 pl-3'><span onClick={() => this.onSelectAction()} className='cursor-pointer material-symbols-outlined'>arrow_back</span></div>
                          </>
                          : null}

                        <h1 className="text-lg font-medium text-slate-800">{ManagerProfileData && ManagerProfileData.name ? ManagerProfileData.name + " (Sense Action Plan)" : "Manager Action Plan"}</h1>
                      </div>

                    </div>
                    {!(getEmployeeActionPlanData && getEmployeeActionPlanData.length > 0) ?


                      <div className='flex items-center justify-center h-full '>

                        <div className="flex items-center justify-center w-full">
                          <div className="text-center">
                            <img src="img/empty_survey.png" className="mb-4" />
                            <h1 className="text-lg font-semibold text-gray-800 cursor-default">Action Plan Not Available!</h1>
                          </div>
                        </div>

                      </div>



                      :

                      <>


                        {(ParameterList && ParameterList.length > 0) || true ?
                          <>
                            <div className="block h-full lg:flex">

                              {CurrentActionId ?
                                <>

                                  {ShowComponent === 1 ?
                                    <TaskListComponent
                                      getManagerActionPlanData={getEmployeeActionPlanData}
                                      CurrentDimensionData={DimensionData}
                                      CurrentActionId={CurrentActionId}
                                      CurrentActionData={CurrentActionData}
                                      navigate={this.navigate}
                                      onSelectTask={this.onSelectTask}
                                      isRiskAction={true}
                                    />
                                    : null}

                                  {ShowComponent === 2 ?
                                    <ResourceListComponent
                                      getManagerActionPlanData={getEmployeeActionPlanData}
                                      CurrentDimensionData={DimensionData}
                                      CurrentActionId={CurrentActionId}
                                      CurrentActionData={CurrentActionData}
                                      navigate={this.navigate}
                                      onSelectResource={this.onSelectResource}
                                      isRiskAction={true}
                                    />
                                    : null}

                                  {ShowComponent === 3 ?
                                    <TaskDetailsComponent
                                      getManagerActionPlanData={getEmployeeActionPlanData}
                                      CurrentDimensionData={DimensionData}
                                      CurrentActionId={CurrentActionId}
                                      CurrentActionData={CurrentActionData}
                                      navigate={this.navigate}
                                      onSelectTask={this.onSelectTask}
                                      CurrentTaskId={CurrentTaskId}
                                      CurrentTaskData={CurrentTaskData}
                                      onComplete={this.onComplete}
                                      onStartTask={this.onStartTask}
                                      isRiskAction={true}
                                    />
                                    : null}

                                  {ShowComponent === 4 ?
                                    <ResourceDetailsComponent
                                      getManagerActionPlanData={getEmployeeActionPlanData}
                                      CurrentDimensionData={DimensionData}
                                      CurrentActionId={CurrentActionId}
                                      CurrentActionData={CurrentActionData}
                                      navigate={this.navigate}
                                      onSelectResource={this.onSelectResource}
                                      CurrentResourceId={CurrentResourceId}
                                      CurrentResourceData={CurrentResourceData}
                                      onComplete={this.onComplete}
                                      isRiskAction={true}
                                    />
                                    : null}

                                </>
                                :
                                <ActionListComponent
                                  getManagerActionPlanData={getEmployeeActionPlanData}
                                  CurrentDimensionId={DimensionId}
                                  onSelectAction={this.onSelectAction}
                                  isRiskAction={true}
                                />
                              }

                            </div>


                          </>
                          :
                          <>
                            {!loading ?
                              <EmptyDimensions />
                              : null}
                          </>
                        }



                      </>}


                    {showCompleteModal ?
                      <CompleteModal closeCompleteModal={this.closeCompleteModal}

                        tempData={ShowComponent === 3 ? CurrentTaskData : CurrentResourceData}
                        remarkContent={ShowComponent === 3 ? remarkTask : remarkResource}

                        handleRemark={this.handleRemark}
                        onRemarkCompleteSubmit={this.onRemarkCompleteSubmit} />
                      : null}


                    {showStartModal ?
                      <StartModal closeStartModal={this.closeStartModal}
                        onStartTaskEntry={this.onStartTaskEntry} />
                      : null}





                  </div>
                  : null}


                {ShowScreens === "goalplan" ?
                  <>


                    <div className="space-y-6"  >



                      {!(crrMainGoalData && crrMainGoalData.id) ?
                        <div className='space-y-6'>


                          <div className="items-center justify-between space-y-4 md:flex md:space-y-0">
                            {/* left side */}
                            <div>
                              <div className="flex items-center cursor-pointer" onClick={() => this.handleShowScreen("intro")}>
                                <h1 className="text-lg font-medium text-gray-800 capitalize ">Goal plan</h1>
                                <div className="flex items-center pl-2 ml-2 text-xs border-l border-blue-500 ">
                                  {/* <p className='text-gray-500 '>Home</p> <span className="material-symbols-outlined" style={{fontSize: '12px'}}>navigate_next</span>  */}
                                  <p className='text-blue-500 '>Goals</p></div>
                              </div>

                              <div className="flex justify-start text-left ">

                                {getIDPLastDateData && getIDPLastDateData.lastIDPDate ?
                                  <button className="cursor-default   text-gray-600 text-sm py-1.5 w-full">
                                    {getIDPLastDateData && getIDPLastDateData.lastIDPDate ? "Last Date to bulid Goal: " + moment(getIDPLastDateData.lastIDPDate, "YYYY-MM-DD").format("DD-MM-YYYY") : ""}</button>
                                  : null}
                              </div>
                            </div>
                            {/* right side */}

                            <div className='px-2 lg:flex'>



                              <div className="flex items-center justify-start mt-2 lg:mt-0">
                                {getIDPLastDateData && getIDPLastDateData.editable ?
                                  <button onClick={() => this.openCreateGoalModal(true)} className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white capitalize bg-blue-500 rounded-md cursor-pointer "><span className="mr-2 material-symbols-outlined">add</span> create goal</button>
                                  :
                                  <button className="flex items-center px-4 py-2 text-sm text-white capitalize bg-blue-500 rounded-full cursor-pointer"><span className="mr-1 material-symbols-outlined" style={{ fontSize: "1.3rem" }} >add</span> create goal</button>}
                              </div>

                            </div>

                          </div>


                          {getGoalListData && getGoalListData.length > 0 ?
                            <div className="grid gap-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
                              {getGoalListData.map((item) =>
                                <>

                                  <div className="p-4 space-y-4 bg-white rounded-lg shadow-md">
                                    <div>

                                      <div className='flex justify-between'>
                                        <h1 className="font-medium capitalize text-md h-14">{item.name}</h1>
                                        {getIDPLastDateData && getIDPLastDateData.editable ?
                                          <>
                                            <div>
                                              <span onClick={() => this.openUpdateGoalModel(item, true)} className="p-2 space-x-4 text-gray-500 bg-gray-100 rounded-full cursor-pointer material-symbols-outlined hover:bg-blue-100" style={{ fontSize: '1.2rem', }}>edit</span>

                                              <span onClick={() => this.openDeleteGoalModel(item, true)} className="p-2 text-gray-500 bg-gray-100 rounded-full cursor-pointer material-symbols-outlined hover:bg-blue-100" style={{ fontSize: '1.2rem', }}>delete</span>
                                            </div>

                                          </>
                                          :
                                          null
                                        }
                                      </div>


                                      <p className="text-xs text-gray-500 capitalize">created date {moment(new Date(parseInt(item.createdAt))).utcOffset("+05:30").format("DD MMM YYYY")}</p>
                                    </div>
                                    <p className="h-24 text-sm text-gray-600 line-clamp-3">
                                      {item && item.desc ? " - " + item.desc : ""}
                                    </p>
                                    <div className="space-y-1.5">
                                      <div className="flex justify-between text-sm font-medium">
                                        <p>Task</p> <p>{getTaskStatus(item.planList).completedCount}/{getTaskStatus(item.planList).allCount}</p>
                                      </div>
                                      <div className="h-1 overflow-hidden bg-gray-100 rounded-full">
                                        <div className="w-2/6 h-1 bg-blue-500" style={{ width: (getTaskStatus(item.planList).allCount) ? (getTaskStatus(item.planList).completedCount * 100 / getTaskStatus(item.planList).allCount).toString() + "%" : "0%" }} />
                                      </div>
                                      {/* <p className="text-xs font-medium text-red-400 capitalize">expired in 3 days</p> */}
                                    </div>
                                    <div className="flex items-center justify-between text-blue-500">
                                      <h1 onClick={() => this.handleViewTask(item)} className="text-sm font-medium uppercase cursor-pointer">view tasks</h1>

                                      <button onClick={() => this.openAddPlanModal(item.id)} className="cursor-pointer text-xs uppercase bg-blue-100 rounded-full flex items-center px-4 py-0.5 font-semibold">
                                        <span className="mr-1 text-lg material-symbols-outlined">add</span> add task </button>




                                    </div>




                                  </div>


                                </>)}
                            </div>
                            : <>

                              <div className=''>

                                <div className="flex items-center justify-center w-full mt-4">
                                  <div className="text-center">
                                    <img src="img/empty_survey.png" className="mb-4" />
                                    <h1 className="font-semibold text-gray-800 cursor-default text-md">Goals not available, create your first goal!</h1>

                                    <div className="flex justify-center -ml-4"><button onClick={() => this.openCreateGoalModal(true)} className="flex items-center px-6 py-3 my-6 text-sm text-white bg-blue-500 rounded-md focus:outline-none"><span className="mr-2 material-symbols-outlined">add</span> CREATE GOAL</button>    </div>
                                  </div>
                                </div>

                              </div>



                            </>}


                        </div>
                        :
                        <>
                          <div className="justify-between md:flex">
                            {/* left side */}


                            <div className="flex items-center space-x-4 cursor-pointer" onClick={() => this.handleViewTask({})}>

                              <span onClick={() => this.handleViewTask({})} className="p-2 text-blue-500 bg-blue-100 rounded-full cursor-pointer material-symbols-outlined">arrow_back</span>

                              <h1 className="text-lg font-medium text-gray-800 capitalize ">{crrMainGoalData && crrMainGoalData.name ? crrMainGoalData.name : "NaN"}</h1>
                              <div className="flex items-center pl-2 ml-2 text-xs border-l border-blue-500 ">
                                <p className='text-gray-500 cursor-pointer' >Goal</p>
                                <span className="material-symbols-outlined " style={{ fontSize: '12px' }}>navigate_next</span> <p className='text-blue-500'>Task</p></div>
                            </div>
                            {/* right side */}
                            <div className="flex items-center justify-end mt-4 lg:mt-0 ">
                              {/* btn */}

                              {getIDPLastDateData && getIDPLastDateData.editable ?
                                <button onClick={() => this.openAddPlanModal(crrMainGoalData.id)}
                                  className="flex items-center px-4 py-2 text-sm text-white capitalize bg-blue-500 rounded-full cursor-pointer">
                                  <span className="mr-1 material-symbols-outlined" style={{ fontSize: "1.3rem" }} >add</span> Add task
                                </button>
                                :
                                <button
                                  className="flex items-center px-4 py-2 text-sm text-white capitalize bg-blue-500 rounded-full cursor-pointer">
                                  <span className="mr-1 material-symbols-outlined" style={{ fontSize: "1.3rem" }} >add</span> Add task
                                </button>}


                            </div>
                          </div>





                          {/* end */}
                          {/*sub nav bar 1 */}
                          <div className="items-center justify-between border-b md:flex">
                            <div className="flex space-x-6 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 capitalize whitespace-nowrap tab-scroll">
                              <span onClick={() => this.handelTaskStatus("all")} className={"cursor-pointer py-3 px-4  block " + (taskStatus === "all" ? "text-blue-500 border-blue-500 border-b-2" : "")}>All </span>
                              <span onClick={() => this.handelTaskStatus("pending")} className={"cursor-pointer py-3 px-4  block " + (taskStatus === "pending" ? "text-yellow-500 border-yellow-500 border-b-2" : "")}>pending</span>
                              <span onClick={() => this.handelTaskStatus("completed")} className={"cursor-pointer py-3 px-4  block " + (taskStatus === "completed" ? "text-green-500 border-green-500 border-b-2" : "")}>Completed</span>
                            </div></div>




                          {PlanList && PlanList.length > 0 ?
                            <div className="grid gap-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">

                              {PlanList.map((plan) =>

                                <div className={"shadow-md p-4 rounded-lg space-y-4 bg-no-repeat flex flex-col justify-between " +
                                  (plan.isComplete ? 'bg-green-100' : (plan.isStarted ? 'bg-yellow-100' : 'bg-blue-100'))
                                } style={{
                                  backgroundSize: 'cover', backgroundImage:
                                    plan.isComplete ?
                                      'url("img/goalplan/bgIMG/completed_sm.png")'
                                      :
                                      (plan.isStarted ?
                                        'url("img/goalplan/bgIMG/pending_sm.png")'
                                        :
                                        'url("img/goalplan/bgIMG/default_sm.png")'
                                      )

                                }}>

                                  {/* <span className='cursor-pointer' onClick={()=>this.handleViewPlanDetails(plan,true)}> */}

                                  <div onClick={() => this.handleViewPlanDetails(plan, true)} className="flex items-start justify-between cursor-pointer">
                                    <div>
                                      <h1 className="text-lg font-medium">{plan.name}</h1>
                                      <p className="text-xs text-gray-600 capitalize">created date {moment(new Date(parseInt(plan.createdAt))).utcOffset("+05:30").format("DD MMM YYYY")}</p>
                                    </div>

                                    <div className='flex items-center space-x-2' >
                                      {getIDPLastDateData && getIDPLastDateData.editable ?

                                        <span onClick={() => this.openUpdateTaskModel(plan, true)} className="p-2 bg-white rounded-full cursor-pointer material-symbols-outlined" style={{ fontSize: "1.1rem" }} >edit</span>
                                        :
                                        <span className="p-2 bg-white rounded-full cursor-pointer material-symbols-outlined" style={{ fontSize: "1.1rem" }}>edit</span>

                                      }

                                      {getIDPLastDateData && getIDPLastDateData.editable ?

                                        <span onClick={() => this.openDeleteTask(plan, true)} class="material-symbols-outlined bg-white p-2 rounded-full cursor-pointer" style={{ fontSize: "1.1rem" }}>
                                          delete
                                        </span>
                                        :
                                        <span class="material-symbols-outlined bg-white p-2 rounded-full cursor-pointer" style={{ fontSize: "1.1rem" }} >
                                          delete
                                        </span>

                                      }
                                    </div>

                                  </div>





                                  <div>
                                    <p onClick={() => this.handleViewPlanDetails(plan, true)} className="h-12 text-sm text-gray-600 truncate cursor-pointer line-clamp-3">
                                      {plan && plan.desc ? plan.desc : ""}
                                    </p>

                                    <div className='h-6'>
                                      {plan.isComplete ?
                                        <div className='text-xs text-purple-500 cursor-pointer' onClick={() => this.showRemarks(plan, true)}>{"Remarks"}</div>
                                        : null}
                                    </div>

                                    {plan.startDate ?
                                      <div className='text-xs'><span className='font-semibold'>Start Date:</span>  <span>{moment(new Date(parseInt(plan.startDate))).utcOffset("+05:30").format("DD MMM YYYY")}</span></div>
                                      : null}

                                    {plan.endDate ?
                                      <div className='text-xs'><span className='font-semibold'>End Date:</span>  <span>{moment(new Date(parseInt(plan.endDate))).utcOffset("+05:30").format("DD MMM YYYY")}</span></div>
                                      : null}



                                    <div onClick={() => this.handleViewPlanDetails(plan, true)} className="cursor-pointer space-y-1.5 pt-4">
                                      <p className="text-xs font-medium text-red-400 capitalize">{plan && plan.deadline ? "Deadline in " + (moment.duration((moment(plan.deadline, "YYYY-MM-DD")).diff((moment().startOf('day')))).asDays()) + " days" : "No Deadline"}</p>
                                    </div>
                                  </div>





                                  {/* </span> */}

                                  <div className="flex items-center justify-between ">

                                    {plan.isComplete ?
                                      <button onClick={() => this.handleViewPlanDetails(plan, true)}
                                        className="px-4 py-2 text-sm font-semibold text-blue-500 capitalize bg-white rounded-full shadow-md cursor-pointer">Completed</button>

                                      :
                                      (plan.isStarted ?
                                        <button
                                          // onClick={()=>this.openTaskCompleteModel(plan,true)} 
                                          onClick={() => this.handleViewPlanDetails(plan, true)}
                                          className="px-4 py-2 text-sm font-semibold text-blue-500 capitalize bg-white rounded-full shadow-md cursor-pointer">Complete Task</button>

                                        :
                                        <button
                                          // onClick={()=>this.openTaskStartModel(plan,true)}
                                          onClick={() => this.handleViewPlanDetails(plan, true)}
                                          className="px-4 py-2 text-sm font-semibold text-blue-500 capitalize bg-white rounded-full shadow-md cursor-pointer">Let's Start</button>

                                      )
                                    }

                                  </div>


                                </div>


                              )}
                            </div>
                            : <>


                              <div className=''>

                                <div className="flex items-center justify-center w-full mt-12">
                                  <div className="text-center">
                                    <h1 className="text-2xl font-normal text-gray-400 cursor-default">No Data Available!</h1>
                                  </div>
                                </div>

                              </div>



                            </>}








                        </>}














                    </div>
                    {showCreateGoalModal ?
                      <CreateGoalModal
                        closeCompleteModal={this.openCreateGoalModal}
                        goalFormData={this.state.goalFormData}
                        handleGoalInput={this.handleGoalInput}
                        submitCreateGoal={this.submitCreateGoal}



                      />
                      : null}


                    {crrGoalId ?
                      <CreateTaskModal
                        closeCompleteModal={this.openAddPlanModal}
                        taskFormData={this.state.taskFormData}
                        handleTaskInput={this.handleTaskInput}
                        submitCreateTask={this.submitCreateTask}
                        EmployeesList={getEmployeeLimitedListData}
                        EmployeesListTotal={getEmployeeLimitedListTotal}
                        handleSelectEmployees={this.handleSelectEmployees}
                        handleSearch={this.handleSearch}
                        search={this.state.search}

                        empSize={this.state.empSize}
                        empOffset={this.state.empOffset}
                        empPage={this.state.empPage}

                        handleEmpPageClick={this.handleEmpPageClick}

                        errorList={errorList}
                      />
                      : null}


                    {showTaskUpdateModal ?
                      <UpdateTaskModal
                        closeCompleteModal={this.openUpdateTaskModel}
                        taskFormData={this.state.taskUpdateFormData}
                        handleTaskInput={this.handleUpdateTaskInput}
                        submitCreateTask={this.submitUpdateTask}
                        EmployeesList={getEmployeeLimitedListData}
                        EmployeesListTotal={getEmployeeLimitedListTotal}
                        handleSelectEmployees={this.handleUpdateSelectEmployees}
                        handleSearch={this.handleSearch}
                        search={this.state.search}

                        empSize={this.state.empSize}
                        empOffset={this.state.empOffset}
                        empPage={this.state.empPage}

                        handleEmpPageClick={this.handleEmpPageClick}

                        errorList={errorList}
                      />
                      : null}

                    {showUpdateGoalModal ?
                      <UpdateGoalModal
                        closeCompleteModal={this.openUpdateGoalModel}
                        goalFormData={this.state.goalUpdateFormData}
                        handleGoalInput={this.handleUpdateGoalInput}
                        submitCreateGoal={this.submitUpdateGoal}

                      />
                      : null}


                    {showTaskStartModal ?
                      <TaskStartModal
                        closeStartModal={this.openTaskStartModel}
                        submitTaskStart={this.submitTaskStart}
                      />
                      : null}


                    {showTaskDeleteModal ?
                      <TaskDeleteModal
                        closeDeleteModal={this.closeDeleteModal}
                        deleteTaskSubmit={this.deleteTaskSubmit}
                      />
                      : null}

                    {showTaskCompleteModal ?
                      <TaskCompleteModal
                        closeCompleteModal={this.openTaskCompleteModel}
                        taskCompleteFormData={this.state.taskCompleteFormData}
                        handleTaskCompleteInput={this.handleTaskCompleteInput}
                        submitTaskComplete={this.submitTaskComplete}
                      />
                      : null}



                    {showTaskRemarkModal ?
                      <TaskRemarkModal
                        closeCompleteModal={this.showRemarks}
                        taskFormData={this.state.taskUpdateFormData}
                        handleTaskInput={this.handleUpdateTaskInput}
                        EmployeesList={getEmployeeLimitedListData}
                        EmployeesListTotal={getEmployeeLimitedListTotal}
                        handleSelectEmployees={this.handleUpdateSelectEmployees}
                        handleSearch={this.handleSearch}
                        search={this.state.search}

                        empSize={this.state.empSize}
                        empOffset={this.state.empOffset}
                        empPage={this.state.empPage}

                        handleEmpPageClick={this.handleEmpPageClick}

                        errorList={errorList}
                      />
                      : null}



                    {showDeleteGoalModal ?
                      <GoalDeleteModal
                        closeDeleteGoalModal={this.openDeleteGoalModel}
                        deleteGoalSubmit={this.deleteGoalSubmit}
                      />
                      : null}


                    {ViewPlanDetailModal ?
                      <div className="fixed top-0 left-0 z-50 flex justify-end w-full h-screen bg-black bg-opacity-30 ">
                        <div className={"md:w-96 w-full shadow-xl p-6 text-center h-full bg-no-repeat " +
                          (crrMainPlanData.isComplete ? 'bg-green-100' : (crrMainPlanData.isStarted ? 'bg-yellow-100' : 'bg-blue-100'))}
                          style={{
                            backgroundSize: 'cover',

                            backgroundImage:
                              crrMainPlanData.isComplete ?
                                'url("img/goalplan/bgIMG/completed.png")'
                                :
                                (crrMainPlanData.isStarted ?
                                  'url("img/goalplan/bgIMG/pending.png")'
                                  :
                                  'url("img/goalplan/bgIMG/default.png")'
                                )

                          }}>
                          <div className="relative h-full space-y-6">
                            <div className="flex justify-end">  <span onClick={() => this.handleViewPlanDetails({}, false)} className="p-2 transition duration-150 bg-white rounded-full cursor-pointer material-symbols-outlined bg-opacity-20 hover:bg-opacity-30">close</span></div>
                            {/* <div className="h-40 mx-auto bg-center " style={{backgroundImage: 
                
                crrMainPlanData.isComplete?
                'url("img/goalplan/boy_tree.png")'
                :                
                (crrMainPlanData.isStarted?
                  'url("img/goalplan/boy_tree.png")'
                  :
                  'url("img/goalplan/boy_tree.png")'
                )

                , backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}} /> */}
                            <div className='h-40' />
                            <h1 className="text-xl font-medium capitalize">{crrMainPlanData.name}</h1>
                            <p className="text-xs text-gray-600 capitalize">created date {moment(new Date(parseInt(crrMainPlanData.createdAt))).utcOffset("+05:30").format("DD MMM YYYY")}</p>
                            <p className="text-sm text-gray-600 line-clamp-3">
                              {crrMainPlanData && crrMainPlanData.desc ? crrMainPlanData.desc : ""}
                            </p>
                            <p className="text-xs font-medium text-red-400 capitalize">{crrMainPlanData && crrMainPlanData.deadline ? "Target in " + (moment.duration((moment(crrMainPlanData.deadline, "YYYY-MM-DD")).diff((moment().startOf('day')))).asDays()) + " days" : "No Target Date"}</p>

                            {crrMainPlanData.isComplete ?
                              <button className="absolute right-0 w-full p-2 text-base font-medium uppercase transition duration-150 bg-green-400 rounded-md bottom-2 hover:bg-green-300">completed</button>

                              :
                              (crrMainPlanData.isStarted ?
                                <button onClick={() => { this.openTaskCompleteModel(crrMainPlanData, true); this.handleViewPlanDetails({}, false) }} className="absolute right-0 w-full p-2 text-base font-medium uppercase transition duration-150 bg-yellow-400 rounded-md bottom-2 hover:bg-yellow-300">Complete Task</button>

                                :
                                <button onClick={() => { this.openTaskStartModel(crrMainPlanData, true); this.handleViewPlanDetails({}, false) }} className="absolute right-0 w-full p-2 text-base font-medium uppercase transition duration-150 bg-blue-400 rounded-md bottom-2 hover:bg-blue-300">Start Task</button>

                              )
                            }

                          </div>
                        </div>
                      </div>
                      : null}
                    {/* end1010 */}


                  </>
                  : null}




                {ShowScreens === "grow" ?
                  <>
                    <GrowMonth
                      allGrowParametersList={allGrowParametersList}
                      getMonth={getMonth}

                      call_startGrowMonth_getGrowWeekListbyMonthId={this.call_startGrowMonth_getGrowWeekListbyMonthId}
                      call_startGrowWeek_getGrowDayListbyWeekId={this.call_startGrowWeek_getGrowDayListbyWeekId}
                      call_startGrowDay_getGrowDayDatabyDayId={this.call_startGrowDay_getGrowDayDatabyDayId}
                      callDataActionAPI={this.callDataActionAPI}

                      callGrowAPI={this.callGrowAPI}
                      getWeekListbyMonthId={getWeekListbyMonthId}
                      getGrowDayListbyWeekId={getGrowDayListbyWeekId}
                      getGrowDayDatabyDayId={getGrowDayDatabyDayId}

                      submitGrowDaySuccess={submitGrowDaySuccess}
                      submitGrowDayData={submitGrowDayData}

                      submitSucess={submitSucess}
                      getAllGrowNote={getAllGrowNote}
                      closeNote={() => { this.setState({ submitSucess: false }) }}

                    />
                  </>
                  : null}


              </main>
            </div>
          </div>
        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, survey, grow } = state;
  return {
    loggingIn,
    users,
    dashboard,
    survey,
    grow
  };
}
export default connect(mapStateToProps)(Dashboard);
