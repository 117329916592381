import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScoreGauge from './Components/ScoreGauge';
import QuestionCard from './Components/QuestionTypeCards/QuestionCard';
import SurveyReport from '../../SurveyMgmt/Survey/Components/SurveyReportCustomer/SurveyReport';
import QuestionMean from './Components/QuestionMean/QuestionMean';
import OverallDistribution from './Components/OverallDistribution/OverallDistribution';
import ResponseHeader from '../../CommonComponents/ResponseHeader';

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerDetailsIndexType: 0,
      showManagerList: true,
      CurrentManagerName: 'Select Manager',

      showOverview: true,
      showSurveyReport: false,
      showAllQuestions: false,
      CurrentTemplateId: '',

      allIndexList: [
        {
          "name": "Happiness",
          "template": 1,
          "score": "NaN",
          "type": "Score",
          "color": "#FF4081",
          "image": "/img/Dashboard/Index/happiness_index.png"
        },
        {
          "name": "Engagement",
          "template": 2,
          "score": "NaN",
          "type": "Percentage",
          "color": "#2196F3",
          "image": "/img/Dashboard/Index/engagement_index.png"

        },
        {
          "name": "Hope",
          "template": 3,
          "score": "NaN",
          "type": "Percentage",
          "color": "#009688",
          "image": "/img/Dashboard/Index/hope_index.png"

        },
        {
          "name": "Stress",
          "template": 4,
          "score": "NaN",
          "type": "Percentage",
          "color": "#EF5350",
          "image": "/img/Dashboard/Index/stress_index.png"

        },
        {
          "name": "Manager",
          "template": 5,
          "score": "NaN",
          "type": "Percentage",
          "color": "#9C27B0",
          "image": "/img/Dashboard/Index/manager_index.png"

        },
        {
          "name": "Leadership",
          "template": 6,
          "score": "NaN",
          "type": "Percentage",
          "color": "#FFB300",
          "image": "/img/Dashboard/Index/leadership_index.png"

        },
        {
          "name": "Value",
          "template": 7,
          "score": "NaN",
          "type": "Percentage",
          "color": "#C0CA33",
          "image": "/img/Dashboard/Index/value_index.png"

        },
        // {
        //   "name":"Organization Core",
        //   "template":8,
        //   "score":"NaN"
        // },
        {
          "name": "Safety",
          "template": 9,
          "score": "NaN",
          "type": "Percentage",
          "color": "#00CD7E",
          "image": "/img/Dashboard/Index/safety_index.png"

        },
        {
          "name": "Diversity",
          "template": 10,
          "score": "NaN",
          "type": "Percentage",
          "color": "#3F51B5",
          "image": "/img/Dashboard/Index/diversity_index.png"

        }
      ]


    }
  }

  componentDidMount() {

  }

  openSurveyResponse = () => {
    this.setState({
      showSurveyReport: true,
      showOverview: false,
      showAllQuestions: false
    });

  }

  closeResponseAnalytics = () => {
    this.setState({
      showSurveyReport: false,
      showOverview: true,
      showAllQuestions: false
    })

    let { storeInnerRoute } = this.props;
    storeInnerRoute(false)
  }

  // onAllQuestions=(item)=>{
  //   this.setState({CurrentTemplateId:item.templateId})
  //   this.setState({ 
  //     showSurveyReport:false,
  //     showOverview: false ,
  //     showAllQuestions:true
  //   })

  //   let { storeInnerRoute } = this.props;
  //   storeInnerRoute(true);
  // }

  // onAllMatrixQuestions=()=>{
  //   this.setState({CurrentTemplateId:13})
  //   this.setState({ 
  //     showSurveyReport:false,
  //     showOverview: false ,
  //     showAllQuestions:true
  //   })

  //   let { storeInnerRoute } = this.props;
  //   storeInnerRoute(true)


  // }



  goToOverview = (item) => {
    let { overviewAPI } = this.props;
    overviewAPI();
    this.setState({ CurrentTemplateId: "" })
    this.setState({
      showSurveyReport: false,
      showOverview: true,
      showAllQuestions: false
    })
  }

  render() {
    let { allIndexList, showOverview, showSurveyReport, showAllQuestions, CurrentTemplateId } = this.state;
    let {

      EmpFilterData,
      CurrentSurveyId,
      OverviewList,
      OverallMean,
      onCardClick,
      getRespondantDetailsData,
      getIndexFilterData,
      getQuestionDistOverallData,
      getMeanScoreQwiseData,
      getQuestionNPSResultData,
      getQuestionOthersResultData,

      getSurveyData
    } = this.props;


    let OverallMeanData = {
      "name": "Overall",
      "mean": (typeof OverallMean !== 'string' || OverallMean instanceof Number) && OverallMean ? OverallMean.toFixed(2) : 'NaN',
      "color": "#4080bf"
    }

    //  //console.log('OverviewList: ',OverviewList)
    //  //console.log('sdsa getQuestionNPSResultData:',getQuestionNPSResultData)
    //  //console.log('getQuestionNPSResultData:',getQuestionNPSResultData)



    function FormattFun(inputData, isSummary) {
      let RankObj = getSurveyData && getSurveyData.designObj && getSurveyData.designObj.componentRank ? getSurveyData.designObj.componentRank : {}

      let LIST = []
      let prevTemList = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {

          let templateId = ques.total[0]._id.qtemplate;

          let temp = {
            name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
            answerList: [],
            rank: RankObj && RankObj[templateId] ? RankObj[templateId] : 100,
            templateId: templateId
          }
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading: item && item._id && item._id.heading ? item._id.heading : "",
                parentId: item && item._id && item._id.parentId ? item._id.parentId : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId
              }
              temp["answerList"].push(ans);
            });
          }


          if (isSummary) {
            let getIndex = prevTemList.findIndex(prev => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId)
            }
          }
          else {
            LIST.push(temp);
          }


        });
      }
      return LIST
    }

    //  let NPSQuesList = FormattFun(getQuestionNPSResultData);
    let OthersQuesList = FormattFun(getQuestionOthersResultData, true).sort((a, b) => a.rank - b.rank);

    let AllQuesList = FormattFun(getQuestionOthersResultData, false).filter(x => x.templateId === CurrentTemplateId);



    //////console.log("----->OthersQuesList<-------",OthersQuesList)


    let { onOthersCardClick, getQuestionMatrixResultData, questionsList, handleMatrixDetails, onAllQuestions, onAllMatrixQuestions } = this.props;



    let ParticipationObj = {
      total: 0,
      completed: 0,
      mailSent: 0,
      clicked: 0,
      participated: 0,
      performance: 0,
      perfPlusSign: true
    }

    if (getRespondantDetailsData && getRespondantDetailsData[0]) {
      let apiData = getRespondantDetailsData[0]
      ParticipationObj['total'] = apiData['total'] ? apiData['total'] : 0;
      ParticipationObj['completed'] = apiData['completed'] ? apiData['completed'] : 0;
      ParticipationObj['mailSent'] = apiData['mailSent'] ? apiData['mailSent'] : 0;
      ParticipationObj['clicked'] = apiData['clicked'] ? apiData['clicked'] : 0;
      ParticipationObj['participated'] = apiData['completed'] && apiData['total'] ? (apiData['completed'] * 100 / apiData['total']).toFixed(2) : 0;



    }

    let responseOption = {
      "item1": {
        "label": "Sent",
        "value": ParticipationObj['total']
      },
      "item2": {
        "label": "Responded",
        "value": ParticipationObj['completed']
      },
      "item3": {
        "label": "Response Rate",
        "value": ParticipationObj['participated'] + '%'
      }
    }



    return (
      <>
        <main className="flex-1 w-full pb-20 overflow-hidden overflow-y-auto bg-gray-50 ">


          {showOverview ?
            <>
              <div className="px-2 py-2 bg-gray-50">
                <div className="h-full p-4 mx-auto overflow-hidden overflow-y-auto " style={{ height: 'calc(100% - 4rem)' }}>

                  <h1 class="xl:text-2xl text-xl font-medium text-[#3D405B]">Overview</h1>
                  <div
                  //onClick={() => this.openSurveyResponse()}
                  >
                    <ResponseHeader responseOption={responseOption} />

                    {/* <IndexKPI dashboardType={2} getRespondantDetailsData={getRespondantDetailsData} /> */}
                  </div>

                  <h1 className="py-2 font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Summary</h1>

                  <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 lg:gap-6">

                    {OthersQuesList && OthersQuesList.length > 0 ? OthersQuesList.map((item, index) =>
                      <div key={index} onClick={() => onAllQuestions(item)} className="p-4 bg-white border">
                        <QuestionCard item={item} name={""} />
                      </div>
                    ) : null}





                    {getQuestionMatrixResultData && getQuestionMatrixResultData.length > 0 ?
                      <div onClick={() => onAllMatrixQuestions()}>
                        <QuestionCard
                          questionType={"matrix"}
                          getQuestionMatrixResultData={getQuestionMatrixResultData}
                          questionsList={questionsList}
                        />
                      </div>
                      : null}

                  </div>


                  {/* <div onClick={() => onCardClick('overall', OverallMeanData, null, OverallMeanData.mean !== "NaN")} >
                    <ScoreGauge item={OverallMeanData} />
                  </div> */}

                  {/* {OverviewList && OverviewList.length>0? OverviewList.map((item, index) =>
                      // (item && item._id)?
                      <div key={index}>
                        {item.score !== 'NaN' ?
                          <div onClick={() => onCardClick('index', item, index, item.mean !== "NaN")} >
                            <ScoreGauge item={item} />
                          </div>
                          :
                          <div >
                            <ScoreGauge item={item} />
                          </div>
                        }
                      </div>
                      // :null
                    ): null} */}












                </div>
              </div>

              {/* <div className="grid grid-cols-1 gap-2 px-1 lg:grid-cols-1 md:grid-cols-1 xl:grid-cols-1 ">

                <div className="m-4 bg-white shadow-lg">
                  <OverallDistribution getQuestionDistOverallData={getQuestionDistOverallData} />
                </div>

                <div className="m-4 bg-white shadow-lg">
                  <QuestionMean getQuestionDistOverallData={getQuestionDistOverallData} getMeanScoreQwiseData={getMeanScoreQwiseData}
                    cardType={'overall'} getQuestionDistData={getQuestionDistOverallData}

                  />

                </div>

              </div> */}
            </>
            :
            null}

          {showSurveyReport ?
            <div className="px-2 bg-gray-100">
              <SurveyReport
                CurrentSurveyId={CurrentSurveyId}
                closeResponseAnalytics={this.closeResponseAnalytics}
                EmpFilterData={EmpFilterData}
                getIndexFilterData={getIndexFilterData}
                getRespondantDetailsData={getRespondantDetailsData}
              />
            </div>
            : null}



          {/* {showAllQuestions?

          :null} */}


        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
