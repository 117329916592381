import React, { Component } from 'react';
import { connect } from 'react-redux';
import DemographicFilter from './Filters/DemographicFilter';
import QuestionFilter from "../Filters/QuestionFilter";
 
import WordChart from './Content/WordChart';
import WordDetails from './Content/WordDetails';
import Comments from './Content/Comments';
import Commentary from '../../../Index/Components/Commentary/Commentary';
import WordsBarChart from './Content/Charts/WordsBarChart';

class TextAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuesId:'',
      DemographicFilterName:'',
      DemographicFilterValue:'',
      FilterList:[],
      showFilter:false,
      showValue:'',

      showViewBy:false
    }
  }



  componentDidMount() {
  }

  closeFilter=(check1,check2)=>{
    this.setState({showFilter:check1, showValue:check2});
  }

  closeFilterQues=(check1)=>{
    this.setState({showViewBy:check1});
  }

  handleChangeQuesIdSave=(Id)=>{
    this.setState({currentQuesId:Id})
  }

  FilterSave=(name, value)=>{
    this.setState({DemographicFilterName:name, DemographicFilterValue:value})
  }

  handleKeywordCall=(keyWord)=>{
    let { currentQuesId, DemographicFilterName, DemographicFilterValue, FilterList } = this.state;
    let { getTextQuestionData, callWordCloudText } = this.props;

    let initQuesId = '';
    if(currentQuesId){
      initQuesId = currentQuesId;
    }
    else{
      initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
    }



    callWordCloudText(initQuesId, keyWord, FilterList)
  }


  handleMultifilterIndexScore2=(finalFilter)=>{
    let { getTextQuestionData } = this.props;
    let { currentQuesId , graphType } = this.state;
    let initQuesId = '';
    if(currentQuesId){
      initQuesId = currentQuesId;
    }
    else{
      initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
    }

    let { handleWordCloudDemographicFilter }= this.props;
    
    this.setState({FilterList:finalFilter});

    handleWordCloudDemographicFilter(initQuesId,finalFilter, graphType, "wordCloud");

  }

  callQuestionWordCloudQuesChange=(initQuesId)=>{
    let {  callWordCloudText, handleWordCloudDemographicFilter} = this.props;
    let { FilterList} = this.state;  
    // callWordCloudText(initQuesId, "", FilterList);
    handleWordCloudDemographicFilter(initQuesId,FilterList, "", "wordCloud");

  }

    render(){

        let { GetParameterLabel, ErrorOtherObj, wordCloudError, loading,callQuestionWordCloud, wordTextKeyWord, wordCloudTextData, getTextQuestionData, handleChangeQuestion, wordCloudData,EmpFilterData,
           getIndexByFilterDMGData } = this.props;

        let { currentQuesId } = this.state;

        //console.log('getTextQuestionData: ',getTextQuestionData)

        let initQuesId = '';


        if(currentQuesId){
          initQuesId = currentQuesId;
        }
        else{
          initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
        }

        let checkFalse = false;
        let { CountRestrict } = this.props;
        let count = wordCloudData && wordCloudData.count?wordCloudData.count:"NaN";
        let CloudData = CountRestrict(count) && checkFalse?[]:wordCloudData;
        let CloudTextData = CountRestrict(count)&& checkFalse?[]:wordCloudTextData;
        let wordTextKeyWordData = CountRestrict(count)&& checkFalse?"":wordTextKeyWord;
        let newWordList = CountRestrict(count)&& checkFalse?[]:(wordCloudData && wordCloudData.list && wordCloudData.list.length>0?wordCloudData.list:[]);



        let maxCount = 0;
        if(newWordList && newWordList.length>0){
          newWordList.forEach((item)=>{
            if(item[1]>maxCount){
              maxCount = item[1];
            }
          });
        }
        let { indexType, optionType, getCommentData} = this.props;

        return(
            <>
        <div onClick={()=>this.closeFilter(false)} >

        <div className='flex flex-wrap gap-4  justify-between w-full items-start' >

        <QuestionFilter
            showViewBy={this.state.showViewBy}
            closeFilterQues={this.closeFilterQues}

            callQuestionWordCloud={this.callQuestionWordCloudQuesChange} 
            getTextQuestionData={getTextQuestionData} 
            handleChangeQuestion={handleChangeQuestion} 
            handleChangeQuesIdSave={this.handleChangeQuesIdSave}
          />     

          </div>
         </div>

      
      
      
      
      {/*sub nav bar 2 */}
      <div className=" md:flex  justify-between items-center lg:space-y-0 space-y-4">
            <DemographicFilter 
              showFilter={this.state.showFilter}
              showValue={this.state.showValue}
              closeFilter={this.closeFilter}

              EmpFilterData2={EmpFilterData}
              getIndexFilterData={getIndexByFilterDMGData}
              handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
              />
                 <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />
      
      </div>

    


      {ErrorOtherObj && ErrorOtherObj["wordCloudTextError"]?
        <>
          <div className='text-gray-400 font-medium text-center my-24 text-2xl'>{ErrorOtherObj["wordCloudTextError"]}</div>
        </>
        :
        <>
          <div className="grid xl:grid-cols-2 grid-cols-1 gap-4 ">

          <WordChart wordCloudError={wordCloudError} loading={loading} wordCloudData={CloudData} handleKeywordCall={this.handleKeywordCall}/>


          <WordDetails GetParameterLabel={GetParameterLabel} checkFalse={checkFalse} EmpFilterData2={EmpFilterData} loading={loading} wordCloudTextData={CloudTextData} wordTextKeyWord={wordTextKeyWordData}/>


          </div>
      </>
      }

</>
        );
    }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(TextAnalysis);
