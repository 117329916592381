import React from "react";
import CustomHorizontalBar from "./CustomHorizontalBar";

export default function EmployeeWiseTable(props) {
  let {
    getAssessmentDimensionsData,
    getAllAnsweredAssessmentEmpDataList,

    getAssessmentReportByEmpIDData,
    crrSelectedEmp,
    EditDetailsFn,
    EditButtonFn,
    hindiPDFstatic
  } = props;

  // function hexToRgbA(hex, opacity) {
  //   var c;
  //   if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
  //     c = hex.substring(1).split("");
  //     if (c.length == 3) {
  //       c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  //     }
  //     c = "0x" + c.join("");
  //     return (
  //       "rgba(" +
  //       [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
  //       "," +
  //       opacity +
  //       ")"
  //     );
  //   }
  //   throw hex;
  // }

  // let newList = [];
  // if (
  //   getAssessmentReportByEmpIDData &&
  //   getAssessmentReportByEmpIDData.length > 0
  // ) {
  //   getAssessmentReportByEmpIDData.forEach((element, index) => {
  //     let getIndex = newList.findIndex(
  //       (prev) => prev.bucket === element.bucket
  //     );
  //     if (getIndex === -1) {
  //       let temp = {
  //         bucket: element.bucket,
  //         colorCode: element.colorCode,
  //         dimensions: [
  //           {
  //             rank: index + 1,
  //             name: element.dimension,
  //           },
  //         ],
  //       };
  //       newList.push(temp);
  //     } else {
  //       newList[getIndex]["dimensions"].push({
  //         rank: index + 1,
  //         name: element.dimension,
  //       });
  //     }
  //   });
  // }

  // //console.log(
  //   "getAssessmentReportByEmpIDData--->",
  //   getAssessmentReportByEmpIDData
  // );
  // //console.log("newList--->", newList);

  // function getOpacity(rank, item) {
  //   let total =
  //     item && item.dimensions && item.dimensions.length > 0
  //       ? item.dimensions.length
  //       : 0;
  //   return (total + 1 - Number(rank)) / (total + 2);
  // }


  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }





  let AssessmentList = []

  if (getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0) {
    getAssessmentDimensionsData.forEach((element) => {
      AssessmentList.push({
        ...element,
        finalSum: (Number(element.count) * Number(element.rankSum))
      })
    })
    AssessmentList.sort((b, a) => (a.finalSum - b.finalSum))
  }

  //console.log("AssessmentList--1->", AssessmentList)



  function checkForGeneralship(bucket, dimension, isSchool) {
    let text = "";
    if (bucket && dimension) {
      if (isSchool) {
        text = "school_";
      } else if (dimension.toLowerCase() === "generalship" && bucket.toLowerCase() === "thinking talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "enhancer" && bucket.toLowerCase() === "relating talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "coach" && bucket.toLowerCase() === "leading talents") {
        text = "old";
      }
    }
    return text
  }


  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }





  return (
    <>


      <div className='w-full'>
        {/* <thead>
            <tr className='bg-[#F1F5F9] border-b border-white'>
              <th className='font-medium p-4 text-left border-r border-white  w-[25%]' colSpan={2}>Name</th>
              <th className='p-4 w-[75%]' colSpan={5}>
                <div className='flex itmes-center justify-evenly'>
                  <div className='flex space-x-1 items-center font-normal'>
                    <span className='w-4 h-4 bg-[#673AB7] flex-shrink-0'></span>
                    <span>Owner</span>
                  </div>
                  <div className='flex space-x-1 items-center font-normal'>
                    <span className='w-4 h-4 bg-[#2196F3] flex-shrink-0'></span>
                    <span>Planner</span>
                  </div>
                  <div className='flex space-x-1 items-center font-normal'>
                    <span className='w-4 h-4 bg-[#FFC107] flex-shrink-0'></span>
                    <span>Finisher</span>
                  </div>
                  <div className='flex space-x-1 items-center font-normal'>
                    <span className='w-4 h-4 bg-[#4CAF50] flex-shrink-0'></span>
                    <span>Values</span>
                  </div>
                  <div className='flex space-x-1 items-center font-normal'>
                    <span className='w-4 h-4 bg-[#F44336] flex-shrink-0'></span>
                    <span>Multitasker</span>
                  </div>

                </div>

              </th>
            </tr>
          </thead> */}
        <div>

          {getAllAnsweredAssessmentEmpDataList && getAllAnsweredAssessmentEmpDataList.length > 0 ?
            getAllAnsweredAssessmentEmpDataList.map((ele) =>
              <div className='bg-[#F1F5F9] border-b border-white space-y-2 px-4 py-4' >
                <div className='    '  >
                  {ele && ele.name}
                </div>
                <div className='  '  >
                  <CustomHorizontalBar
                    ele={ele}
                    EditDetailsFn={EditDetailsFn}
                    getAssessmentDimensionsData={getAssessmentDimensionsData}
                  />
                </div>
              </div>
            ) : null}


        </div>

      </div>
    </>
  );
}
