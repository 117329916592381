import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurveyName: '',
      currentSurveyId: '',

      showDropdown: false,

      showSidebar: false,


      showSubMenu: false
    }
  }
  componentDidMount() {
  }

  onDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false })
    }
    else {
      this.setState({ showDropdown: true })
    }
  }

  logout = () => {
    this.props.dispatch(userActions.logout());
  }



  onSubMenu = (check) => {
    if (this.state.showSidebar) {
      this.setState({ showSubMenu: check });
    }
  }


  sidebarToggle = () => {
    if (this.state.showSubMenu) {
      this.setState({ showSubMenu: false })
    }
    this.setState({ showSidebar: !this.state.showSidebar })
  }


  render() {
    let { OverviewList, handleSidebarExtra, handleSidebarClick, IndexNo, showOverview, showTextAnalysis, showAdvanceInsight, showOrgCore } = this.props;
    let { showSidebar, showSubMenu  } = this.state;

    // console.log('OverviewList: ',OverviewList);

    return (

      <div className="w-16 lg:block hidden" style={{ zIndex: '110' }}>
        <div className={showSidebar ?
          "fixed lg:block hidden shadow-xl w-64 left-0 top-16 h-screen h-full z-30 transition duration-300 transform bg-white border-r h-increse" :
          "fixed lg:block hidden w-16 left-0 top-16 h-screen h-full z-30 transition duration-300 transform bg-white border-r h-increse"
        } style={{ height: 'calc(100% - 4rem)' }} id="customscroll2">
          <div className="flex items-center ">
            <div className="flex items-center px-4 py-3 w-16  text-blue-500  justify-center">
              <span onClick={() => this.sidebarToggle()} className="cursor-pointer material-symbols-outlined text-gray-500 rounded hover:bg-gray-100 p-1">
                {showSidebar ?
                  "chevron_left" :
                  "chevron_right"
                }
              </span>
            </div>
            {showSidebar ?
              <span className="cursor-default font-normal ml-4 text-sm text-gray-500">Insight</span>
              : null}
          </div>
          <div className="mb-4 font-medium   overflow-y-auto overflow-hidden" style={{ height: 'calc(100% - 4rem)' }}>
            {/* 1 */}
            <div title={"Overview"} onClick={() => handleSidebarClick(false)}
              className={showOverview ? "cursor-pointer flex items-center bg-blue-50 text-blue-500 border-r-2 border-blue-500" :
                "cursor-pointer flex items-center hover:bg-blue-50 text-gray-500"}>
              <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
                <span className="material-symbols-outlined   p-2">dashboard</span>
              </div>
              {showSidebar ? <span class=" text-sm text-[#3D405B]/70 font-medium ">Overview</span> : null}
            </div>


            <div title={"Text Analysis"} onClick={() => handleSidebarExtra(1)}
              className={showTextAnalysis ? "cursor-pointer flex items-center bg-blue-50   text-blue-500 border-r-2 border-blue-500" :
                "cursor-pointer flex items-center hover:bg-blue-50   text-gray-500"}>

              <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
                {/* <span className="material-symbols-outlined  p-2">manage_search</span> */}

                <span class="p-2 material-symbols-outlined"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-graph" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.8" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M4 18v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path><path d="M7 14l3 -3l2 2l3 -3l2 2"></path></svg></span>


              </div>
              <span class=" text-sm text-[#3D405B]/70 font-medium ">Text Analysis</span>
            </div>

            {/* <div title={"Advance Insight"} onClick={() => handleSidebarExtra(2)}
              className={showAdvanceInsight ? "cursor-pointer flex items-center bg-blue-50   text-blue-500 border-r-2 border-blue-500" :
                "cursor-pointer flex items-center hover:bg-blue-50   text-gray-500"}>

              <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">

                <span class="p-2 material-symbols-outlined">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chart-pie-2"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.8"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 3v9h9" />
                    <circle cx="12" cy="12" r="9" />
                  </svg>
                </span>


              </div>
              <span class=" text-sm text-[#3D405B]/70 font-medium ">Advance Insight</span>
            </div> */}

{/* 
            <div title={"Organization Core"} onClick={() => handleSidebarExtra(3)}
              className={showOrgCore ? "cursor-pointer flex items-center bg-blue-50   text-blue-500 border-r-2 border-blue-500" :
                "cursor-pointer flex items-center hover:bg-blue-50   text-gray-500"}>

              <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">

                <span class="p-2 material-symbols-outlined">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-building-community"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.8"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8" />
                    <line x1="13" y1="7" x2="13" y2="7.01" />
                    <line x1="17" y1="7" x2="17" y2="7.01" />
                    <line x1="17" y1="11" x2="17" y2="11.01" />
                    <line x1="17" y1="15" x2="17" y2="15.01" />
                  </svg>
                </span>


              </div>
              <span class=" text-sm text-[#3D405B]/70 font-medium ">Organization Core</span>
            </div> */}

          </div>
        </div>
      </div>

    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(Sidebar);
