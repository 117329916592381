import React, { Component } from 'react';
import { connect } from 'react-redux';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // showFilter:false,
      // showValue:'',
      currFilterList: [],
      finalFilter: [],
      searchName: ''

    }
  }
  componentDidMount() {

  }


  handleParameterQuestion = () => {

  }

  handleSelectFilter = (name) => {
    // this.setState({showFilter:false});
    this.props.closeFilter(false);

    let { currFilterList, finalFilter } = this.state;
    let { handleMultifilterIndexScore } = this.props;

    currFilterList = [name];
    finalFilter = [{
      "level": name,
      "value": "All"
    }]
    this.setState({ currFilterList, finalFilter }, () => {
      handleMultifilterIndexScore(finalFilter, name);
    });

    // let getIndex = currFilterList.findIndex(prev=>prev===name)
    // if(getIndex===-1){
    //     currFilterList.push(name);
    //     finalFilter.push({
    //         "level":name,
    //         "value":"All"
    //     });  

    //     this.setState({currFilterList,finalFilter},()=>{
    //         handleMultifilterIndexScore(finalFilter,name); 
    //     });
    // }
    // else{
    //     currFilterList.splice(getIndex,1);
    //     let getIndex2 = finalFilter.findIndex(prev=>prev.level===name);
    //     finalFilter.splice(getIndex2,1);

    //     this.setState({currFilterList,finalFilter},()=>{
    //         if(finalFilter && finalFilter.length>0){
    //             handleMultifilterIndexScore(finalFilter,finalFilter[finalFilter.length-1].level); 
    //         }
    //         else{
    //             handleMultifilterIndexScore([]); 
    //             this.props.closeFilter(false);

    //         }
    //     });
    // }

  }

  handleOpenFilter = (check) => {
    // this.setState({showFilter:!check,showValue:''});
    this.props.closeFilter(!check, '');

  }


  handleOpenValue = (name) => {
    if (this.state.showValue === name) {
      // this.setState({showValue:'',showFilter:false})
      this.props.closeFilter(false, '');
    }
    else {
      // this.setState({showValue:name,showFilter:false})
      this.props.closeFilter(false, name);
    }
  }


  handleSelectValue = (value, name) => {
    let { finalFilter } = this.state;

    let getIndex = finalFilter.findIndex(prev => prev.level === name);

    if (getIndex === -1) {

      finalFilter.push({
        "level": name,
        "value": value
      });

    }
    else {

      let getIndex2 = finalFilter.findIndex(prev => prev.value === value);
      if (getIndex2 === -1) {
        finalFilter[getIndex].value = value;
      }
      else if (value === "All") {
        finalFilter[getIndex].value = "All";
      }

    }
    let { handleMultifilterIndexScore } = this.props;
    this.props.closeFilter(false, '');
    this.setState({ finalFilter }, () => {
      handleMultifilterIndexScore(finalFilter, name);
    })
  }



  handleFilterClear = () => {
    let { handleMultifilterIndexScore } = this.props;
    this.setState({ finalFilter: [], currFilterList: [] }, () => {
      handleMultifilterIndexScore([]);
    })
  }

  handleSelectAllFilter = () => {

  }

  handleSearch = (e) => {
    this.setState({ searchName: e.target.value });
  }

  render() {

    const ref = React.createRef();
    let { EmpFilterData2, getIndexFilterData, DemographicValueList2, showValue, showFilter } = this.props;
    let { searchName, currFilterList, finalFilter } = this.state;

    let DemographicValueList = {};
    if (DemographicValueList2) {
      DemographicValueList = DemographicValueList2;
      // DemographicValueList.sort();
    }
    // filterValues = DemographicValueList



    console.log('EmpFilterData2: ', EmpFilterData2)
    console.log('currFilterList: ', currFilterList)
    console.log('DemographicValueList: ', DemographicValueList)
    console.log('finalFilter: ', finalFilter)



    function selectedValue(item) {
      let Value = '';
      let getIndex = finalFilter.findIndex(prev => prev.level === item);
      if (getIndex !== -1) {
        if (finalFilter && finalFilter[getIndex] && finalFilter[getIndex].value) {
          Value = finalFilter[getIndex].value;
        }
      }
      return Value;
    }


    function SortList(list) {
      let temp = [];
      if (list && list.length > 0) {
        temp = list.sort()
      }
      return temp;
    }


    return (
      <>
        <div className="flex">

          <div onClick={() => this.handleOpenFilter(showFilter)} className="relative flex items-center justify-between px-4 py-2 bg-white border rounded-md cursor-pointer w-44 ">
            <p className="text-sm text-[#3D405B] font-medium">{currFilterList && currFilterList.length > 0 ? currFilterList[0] : showValue}</p>
            <span onClick={() => this.handleOpenFilter(showFilter)} className="text-[#3D405B]/70 material-symbols-outlined">arrow_drop_down</span>
            {showFilter ?
              <div className="absolute left-0 z-10 p-4 overflow-y-auto font-normal bg-white rounded-lg shadow-lg w-44 top-12 font-xs h-60 scollar-xs-blue">
                {EmpFilterData2 && EmpFilterData2.length > 0 ?
                  EmpFilterData2.map((dmg, index) =>
                    <div key={index} className="flex items-center py-2 m-1 text-[#3D405B]">
                      <input type="checkbox" checked={currFilterList.some(prev => prev === dmg.name) ? true : false} onClick={() => this.handleSelectFilter(dmg.name)}
                        onChange={() => { }} /><label onClick={() => this.handleSelectFilter(dmg.name)} className="ml-2 truncate">{dmg.name}</label>
                    </div>
                  ) : null}
              </div>
              : null}

          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Filter);
