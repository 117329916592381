import React, { Component } from "react";
import { connect } from "react-redux";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // showFilter:false,
      // showValue:'',
      currFilterList: [],
      finalFilter: [],
      searchName: "",
    };
  }
  componentDidMount() { }

  handleParameterQuestion = () => { };

  handleSelectFilter = (name) => {
    // this.setState({showFilter:false});
    this.props.closeFilter(false);

    let { currFilterList, finalFilter } = this.state;
    let { handleMultifilterIndexScore } = this.props;
    let getIndex = currFilterList.findIndex((prev) => prev === name);

    if (getIndex === -1) {
      currFilterList = [name];
      finalFilter = [
        {
          level: name,
          value: "All",
        },
      ];

      this.setState({ currFilterList, finalFilter }, () => {
        handleMultifilterIndexScore(finalFilter, name);
      });
    } else {
      currFilterList.splice(getIndex, 1);
      let getIndex2 = finalFilter.findIndex((prev) => prev.level === name);
      finalFilter.splice(getIndex2, 1);

      this.setState({ currFilterList, finalFilter }, () => {
        if (finalFilter && finalFilter.length > 0) {
          handleMultifilterIndexScore(
            finalFilter,
            finalFilter[finalFilter.length - 1].level
          );
        } else {
          handleMultifilterIndexScore([]);
          // this.setState({showFilter:false})
          this.props.closeFilter(false);
        }
      });
    }
  };

  handleOpenFilter = (check) => {
    this.setState({ showFilter: !check, showValue: "" });
    this.props.closeFilter(!check, "");
  };

  handleOpenValue = (name) => {
    if (this.props.showValue === name) {
      // this.setState({showValue:'',showFilter:false});
      this.props.closeFilter(false, "");
    } else {
      // this.setState({showValue:name,showFilter:false})
      this.props.closeFilter(false, name);
    }
  };

  handleSelectValue = (value, name) => {
    let { finalFilter } = this.state;

    let getIndex = finalFilter.findIndex((prev) => prev.level === name);

    if (getIndex === -1) {
      finalFilter.push({
        level: name,
        value: value,
      });
    } else {
      let getIndex2 = finalFilter.findIndex((prev) => prev.value === value);
      if (getIndex2 === -1) {
        finalFilter[getIndex].value = value;
      } else if (value === "All") {
        finalFilter[getIndex].value = "All";
      }
    }
    this.props.closeFilter(false, "");
    let { handleMultifilterIndexScore } = this.props;
    this.setState({ finalFilter }, () => {
      handleMultifilterIndexScore(finalFilter, name);
    });
  };

  handleFilterClear = () => {
    let { handleMultifilterIndexScore } = this.props;
    this.setState({ finalFilter: [], currFilterList: [] }, () => {
      handleMultifilterIndexScore([]);
    });
  };

  handleSelectAllFilter = () => { };

  handleSearch = (e) => {
    this.setState({ searchName: e.target.value });
  };

  render() {
    // const ref = React.createRef();
    let { EmpFilterData2, showFilter, xaXis } = this.props;
    let { currFilterList, finalFilter } = this.state;


    return ( 
        <div className="flex flex-wrap gap-2 ">
          <div className="relative flex items-center justify-between w-40 px-4 py-2 bg-white border rounded-md cursor-pointer" onClick={() => this.handleOpenFilter(showFilter)} >
            <p onClick={() => this.handleOpenFilter(showFilter)} className=" text-[#3D405B] font-medium text-sm">  {currFilterList && currFilterList.length > 0 ? currFilterList[0] : xaXis ? xaXis : "Select Demographic"} </p>
            <span onClick={() => this.handleOpenFilter(showFilter)} className="text-[#3D405B] material-symbols-outlined">  arrow_drop_down</span>
            {showFilter ? (
              <div className="absolute left-0 z-10 w-full p-3 font-normal bg-white shadow-lg top-11 font-xs" id="chatbot" style={{ overflowY: "auto", height: "300px" }}>
                {EmpFilterData2 && EmpFilterData2.length > 0
                  ? EmpFilterData2.map((dmg, index) =>
                    dmg.name !== "ManagerPID" ? (
                      <div key={index} className="flex items-center py-2 m-1">
                        <input type="checkbox" checked={currFilterList.some((prev) => prev === dmg.name) ? true : false} onClick={() => this.handleSelectFilter(dmg.name)} onChange={() => { }} />
                        <label onClick={() => this.handleSelectFilter(dmg.name)} className="ml-2 text-xs"> {dmg.label}</label>
                      </div>
                    ) : null
                  )
                  : null}
              </div>
            ) : null}
          </div>
        </div> 
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(Filter);
