import React, { Component } from 'react';
import { connect } from 'react-redux';
import { surveyActions, dashboardActions } from '../../../../_actions';
import { CSVLink } from "react-csv";

import FilterDMG from './FilterDMG/Filter';
import ResponseMetaTable from './Components/ResponseMetaTable';

import ReactEcharts from "echarts-for-react";


class SurveyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {

      FilterValues: [],
      showFilter: false,
      showValue: '',

      litsXAxis: 'Gender',



      selectFilterType: 1,
      surveyReportEmail: '',
      openModalComfirmSend: false,
      SaveEmployeeId: '',
      DemographValue: '',



      filterList: [
        {
          id: true,
          type: 'Show All'
        },
        {
          id: false,
          type: 'Show Filter'
        }
      ],

      isMailList: [
        {
          id: true,
          type: 'Delivered'
        },
        {
          id: false,
          type: 'Not Delivered'
        }
      ],

      isClickedList:
        [
          {
            id: true,
            type: 'Clicked',
          },
          {
            id: false,
            type: 'Not Clicked'
          }
        ],

      isCompleteList: [
        {
          id: true,
          type: 'Completed'
        },
        {
          id: false,
          type: 'Not Completed'
        }
      ],

      filterformData: {
        "showAll": true,
        "isMailSent": true,
        "isClicked": true,
        "isComplete": true
      },

      currFilterName: "Demographic"

    }
  }
  componentDidMount() {
    let { EmpFilterData, getIndexFilterData } = this.props;
    let { selectedFilterName, showViewBy } = this.state;

    let CurrentFilterName = this.state.litsXAxis;
    if (EmpFilterData && EmpFilterData.length > 0) {
      CurrentFilterName = EmpFilterData[0].name
    }

    let data = {
      "surveyId": this.props.CurrentSurveyId,
      "xAxis": CurrentFilterName
    }
    // this.props.dispatch(dashboardActions.responseAnalysisBySurveyId(data));
    // this.props.dispatch(dashboardActions.getResponseAnalytics(data));
    this.props.dispatch(dashboardActions.getMetaResponseAnalyticsFilter(data));

    let data2 = {
      "surveyId": this.props.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getMetaRespondantDetails(data2));

    this.props.dispatch(surveyActions.getIndexByFilterDMG({
      filters: []
    }));

  }


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }


  handleCreateSurveyPopup = () => {

  }

  handleSetFilter = (value) => {

    let { filterformData } = this.state;

    if (value === 'true' || value === true) {
      this.setState({ selectFilterType: 1 });
      filterformData['showAll'] = true;
    }
    else {
      this.setState({ selectFilterType: 2 });
      filterformData['showAll'] = false;
    }

    this.setState({ filterformData });

  }


  handleIsMailSent = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isMailSent'] = true;
    }
    else {
      filterformData['isMailSent'] = false;

    }
    this.setState({ filterformData });

  }

  handleIsClicked = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isClicked'] = true;
    }
    else {
      filterformData['isClicked'] = false;

    }
    this.setState({ filterformData });

  }

  handleIsComplete = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isComplete'] = true;
    }
    else {
      filterformData['isComplete'] = false;

    }
    this.setState({ filterformData });

  }

  handleEmailInput = (val) => {
    this.setState({ surveyReportEmail: val });
  }

  HandleFilterType = (value) => {
    this.setState({ selectFilterType: value });
  }

  RefreshAPI = () => {
    let data = {
      surveyId: this.props.CurrentSurveyId
    }
    this.props.dispatch(surveyActions.responseAnalysisBySurveyId(data));
    this.props.dispatch(surveyActions.getCampaignEmployeesByIDSurveyWise(data));

  }


  forceMail = (id) => {
    this.setState({
      openModalComfirmSend: true,
      SaveEmployeeId: id
    })
    // this.props.dispatch(surveyActions.resendCampain({ id: id }));
  }


  handleSendSingleMail = () => {
    let { SaveEmployeeId } = this.state;
    this.props.dispatch(surveyActions.resendCampain({ id: SaveEmployeeId }));
    this.setState({ SaveEmployeeId: '' });
  }

  resendCampain = (id) => {
    this.setState({
      openModalComfirmSend: true,
      SaveEmployeeId: id
    })
    //  this.props.dispatch(surveyActions.resendCampain({ id: id }));
  }

  handleConfirmMailClose = () => {
    this.setState({ openModalComfirmSend: false, SaveEmployeeId: '' });
  }


  handleQuestionFilterName = (Name, Value) => {
    this.setState({ DemographValue: '' });

    let data = {
      "surveyId": this.props.CurrentSurveyId,
      "xAxis": Name
    }
    this.props.dispatch(dashboardActions.getResponseAnalytics(data));
  }

  handleQuestionFilterValue = (Name, Value) => {
    console.log('Value:', Value)
    if (Value === 'All') {
      this.setState({ DemographValue: '' });
    }
    else {
      this.setState({ DemographValue: Value });
    }
  }

  handleQuestionFilterValueSave = () => {

  }

  handleQuestionFilterNameSave = () => {

  }


  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultifilterBehaviour } = this.props;
    this.setState({ FilterValues: finalFilter, currFilterName: name });
    ////console.log("finalFilter, true, name====>",finalFilter, true, name)
    // handleMultifilterBehaviour(finalFilter, true, name);





    let FilterValues = finalFilter;

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues;
      }

    }



    let data = {
      "surveyId": this.props.CurrentSurveyId,
      "xAxis": XAxis,
      "filters": Filters
    }

    this.setState({ litsXAxis: XAxis })
    this.props.dispatch(dashboardActions.getMetaResponseAnalyticsFilter(data));


  }



  handleSelectSurvey = (params, list) => {
    console.log("params----------->", params);
    let surveyId = "";
    let surveyName2 = "";
    if (params && params.name) {
      let surveyName = params.name;
      if (list && list.length > 0) {
        let getIndex = list.findIndex(prev => prev.surveyLabel === surveyName);
        if (getIndex !== -1) {
          surveyId = list[getIndex]["surveyId"]
          surveyName2 = list[getIndex]["surveyName"]
        }

        console.log("list----------->", list);

      }
    }

    let { EmpFilterData } = this.props;
    let CurrentFilterName = this.state.litsXAxis;
    if (EmpFilterData && EmpFilterData.length > 0) {
      CurrentFilterName = EmpFilterData[0].name
    }



    this.setState({ surveyIdV2: surveyId, surveyNameV2: surveyName2 }, () => {
      let data = {
        "surveyId": surveyId,
        "xAxis": CurrentFilterName
      }
      this.props.dispatch(dashboardActions.getMetaResponseAnalyticsFilter(data));
    })
  }

  render() {
    let { survey, dashboard, closeResponseAnalytics,



      getIndexFilterData,
      currFilterName,
      getRespondantDetailsData,
      styleType,
      loading,
      handleGoBack

    } = this.props;
    // let {  getResponseAnalyticsData } = survey;
    let { getResponseAnalyticsData, getMetaRespondantDetailsData, getMetaResponseAnalyticsFilterData } = dashboard;
    let { getIndexByFilterDMGData } = survey;

    let { openModalComfirmSend, DemographValue, FilterValues, surveyNameV2 } = this.state;


    let DemographicResponseNewData = getResponseAnalyticsData;

    function ResponseRate(item) {
      return (item.completed * 100) / item.totalInvite
    }


    if (DemographicResponseNewData && DemographicResponseNewData.length > 0) {
      DemographicResponseNewData.sort((a, b) => ResponseRate(b) - ResponseRate(a));
    }

    let EmpFilterData = [];
    let filterLevels = this.props.EmpFilterData;
    if (filterLevels && filterLevels.length > 0) {
      filterLevels.forEach((item) => {
        if (item.name !== "ManagerPID") { EmpFilterData.push(item); }
      })
    }


    console.log('EmpFilterData====?', EmpFilterData)



    console.log('==============>getIndexByFilterDMGData==============>', getIndexByFilterDMGData);

    console.log('==============>DemographicResponseNewData==============>', DemographicResponseNewData);


    let DemographicValueList = []
    if (DemographicResponseNewData && DemographicResponseNewData.length > 0) {
      DemographicResponseNewData.forEach((value, index) => {
        DemographicValueList.push(value.name);
      });
    }

    console.log('==============>DemographicResponseNewData==============>', DemographicResponseNewData);


    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split('.')[0];
        let second = str.split('.')[1];
        let second2 = second ? second.substring(0, 2) : ""
        let final = first + '.' + second2;
        if (second2) {
          return parseFloat(final).toFixed(2)
        } else {
          return no
        }
      } else {
        return 0
      }
    }


    function getResponseOverall(data, type) {
      let value = 0;
      let mailSent = data["mailSent"] ? data["mailSent"] : 0
      let clicked = data["clicked"] ? data["clicked"] : 0
      let completed = data["completed"] ? data["completed"] : 0

      if (type === "mailSent") {
        value = mailSent
      } else if (type === "clicked") {
        value = clicked
      } else if (type === "notclicked") {
        value = mailSent - clicked
      } else if (type === "completed") {
        value = completed
      } else if (type === "notcompleted") {
        value = mailSent - completed
      } else if (type === "responserate") {
        let perc = spliceDecimal((completed * 100) / mailSent);
        console.log("perc", perc)
        value = perc
      }
      if (type === "responserate") {
        console.log("value--->", value)
      }
      return value

    }





    function getResponseValue(data, type) {
      let value = 0;
      let mailSent = data["mailSent"] ? data["mailSent"] : 0
      let clicked = data["clicked"] ? data["clicked"] : 0
      let completed = data["completed"] ? data["completed"] : 0

      if (type === "mailSent") {
        value = mailSent
      } else if (type === "clicked") {
        value = clicked
      } else if (type === "notclicked") {
        value = mailSent - clicked
      } else if (type === "completed") {
        value = completed
      } else if (type === "notcompleted") {
        value = mailSent - completed
      } else if (type === "responserate") {
        let perc = spliceDecimal((Number(completed) * 100) / Number(mailSent));
        console.log("perc", perc)
        value = perc
      }
      if (type === "responserate") {
        console.log("value--->", value)
      }
      return value

    }




    let CurrentFilterName = this.state.litsXAxis;
    if (EmpFilterData && EmpFilterData.length > 0) {
      CurrentFilterName = EmpFilterData[0].name
    }



    let temp1 = {}

    temp1[CurrentFilterName] = "Overall";
    temp1["Sent"] = getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "mailSent");
    temp1["Clicked"] = getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "clicked");
    temp1["Notclicked"] = getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "notclicked");
    temp1["Completed"] = getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "completed");
    temp1["Notcompleted"] = getResponseOverall(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "notcompleted");
    temp1["ResponseRate"] = getResponseValue(getMetaRespondantDetailsData && getMetaRespondantDetailsData[0] ? getMetaRespondantDetailsData[0] : {}, "responserate").toString() + "%";

    let DownloadExcelResponse = [temp1];
    if (getMetaResponseAnalyticsFilterData && getMetaResponseAnalyticsFilterData.length > 0) {
      getMetaResponseAnalyticsFilterData.forEach((element) => {

        let temp = {}


        temp[CurrentFilterName] = element && element.name ? element.name : "NA";
        temp["Sent"] = getResponseValue(element.data, "mailSent");
        temp["Clicked"] = getResponseValue(element.data, "clicked");
        temp["Notclicked"] = getResponseValue(element.data, "notclicked");
        temp["Completed"] = getResponseValue(element.data, "completed");
        temp["Notcompleted"] = getResponseValue(element.data, "notcompleted");
        temp["ResponseRate"] = getResponseValue(element.data, "responserate").toString() + "%";


        DownloadExcelResponse.push(temp)
      })
    }









    let { get360DimensionRelationScoreData, getRespondantAllLifecycleDetailsData, GetParameterLabel } = this.props;





    function getListSorted(list) {
      if (list && list.length > 0) {
        let List1 = list.filter(prev => prev.relation === "Self")
        let List2 = list.filter(prev => prev.relation !== "Self")
        let RelationListMainA = List1.concat(List2)
        return RelationListMainA
      } else {
        return []
      }
    }

    let get360DimensionRelationScoreList = []
    if (get360DimensionRelationScoreData && get360DimensionRelationScoreData.length > 0) {
      get360DimensionRelationScoreList = get360DimensionRelationScoreData.map((element) => (element))
      get360DimensionRelationScoreList.sort((a, b) => ((a && a.item && a.item._id ? a.item._id : "").charCodeAt(0) - (b && b.item && b.item._id ? b.item._id : "").charCodeAt(0)))
    }

    let ValueList = [];
    let DimensionList = [];
    let RelationList = [];
    let RelationListMain = [];

    if (get360DimensionRelationScoreList && get360DimensionRelationScoreList.length > 0) {
      get360DimensionRelationScoreList.forEach((item) => {
        let dimension1 = item && item._id ? item._id : "";

        let dimension = dimension1;


        let temp = {
          "dimen_xyz": dimension
        }

        let miniList = []
        if (item && item.total && item.total.length > 0) {
          item.total.forEach((inner) => {
            if (inner && inner._id && inner._id.relation) {
              miniList.push({
                "relation": inner._id.relation,
                "mean": inner && inner.mean ? inner.mean : 0
              })
            }
          })
        }
        let miniListSorted = getListSorted(miniList)





        if (miniListSorted && miniListSorted.length > 0) {
          miniListSorted.forEach((inner) => {
            if (inner && inner.relation) {

              let scoreMean1 = inner && inner.mean ? inner.mean : 0;

              let scoreMean = scoreMean1.toFixed(2);

              temp[inner.relation] = scoreMean;

              let getIndex2 = RelationList.findIndex(prev => prev.name === inner.relation);
              if (getIndex2 === -1) {
                let tempx = {
                  name: inner.relation,
                  value: [scoreMean]
                }
                RelationList.push(tempx);
                RelationListMain.push(tempx)

              } else {
                RelationList[getIndex2]["value"].push(scoreMean)
              }



            }
          })
        }


        DimensionList.push({ name: dimension, max: 6 })



        ValueList.push(temp)
      })
    }

    console.log('-------->>>>DimensionList; ', DimensionList)
    console.log('-------->>>>RelationList; ', RelationList)
    console.log('-------->>>>ValueList; ', ValueList)

    let listSurvey = []
    let sourceListV2 = [
      ['Survey', 'Sent', 'Responded']
    ]
    if (getRespondantAllLifecycleDetailsData && getRespondantAllLifecycleDetailsData.length > 0) {
      getRespondantAllLifecycleDetailsData.forEach((item) => {

        let surveyId = item.surveyId;
        let SurveyName = item.surveyName;
        let Sent = item && item.Data && item.Data.total ? item.Data.total : 0
        let Respond = item && item.Data && item.Data.completed ? item.Data.completed : 0
        let ResponseRate = ((Respond * 100) / Sent)
        console.log('///////////////////////////////////////-------->>>>ResponseRate; ', ResponseRate);

        if (Sent) {
          let eachDimen = [
            (SurveyName + (" (" + ResponseRate.toFixed(2) + "%)")), Sent, Respond
          ]
          sourceListV2.push(eachDimen)
        }

        listSurvey.push({
          "surveyLabel": (SurveyName + (" (" + ResponseRate.toFixed(2) + "%)")),
          "surveyName": SurveyName,
          "surveyId": surveyId
        })
      })
    }
    console.log('///////////////////////////////////////-------->>>>sourceListV2; ', sourceListV2)

    console.log('//////////-------->>>>getRespondantAllLifecycleDetailsData; ', getRespondantAllLifecycleDetailsData)





    return (
      <>
        <div className="">
          <div className="space-y-4" style={{}}>


            <div className='flex items-center space-x-4'>
              <span onClick={handleGoBack} class="cursor-pointer material-symbols-outlined bg-blue-100 p-2 rounded-full text-blue-500 ">arrow_back</span>
              <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Employee Life Cycle Response Rate</h1>
            </div>


            <div className="p-4 bg-white border rounded-md cursor-pointer">
              <div className='space-y-6' >
                <h1 className="text-lg font-medium ">Employee Life Cycle Response Rate</h1>

                {getRespondantAllLifecycleDetailsData && getRespondantAllLifecycleDetailsData.length > 0 ?
                  <>
                    <div className="flex justify-center w-full mb-4 ">
                      <ReactEcharts className='w-full'
                        style={{ height: '500px' }}
                        onEvents={{
                          'click': (params) => this.handleSelectSurvey(params, listSurvey)
                        }}
                        option={
                          {
                            legend: {},
                            tooltip: {
                              trigger: 'axis',
                              axisPointer: {
                                type: 'shadow'
                              },
                              formatter: function (data) {
                                console.log("a,data,d--->", data)
                                let sent = data[0]["value"][1];
                                let responded = data[0]["value"][2];
                                let htmlElement = ''
                                htmlElement += '<p>Sent:</p> <p><b>No. of Employees:</b> ' + (sent) + '</p><br/>'
                                htmlElement += '<p>Responded:</p> <p><b>No. of Employees:</b> ' + (responded) + '</p>'
                                return htmlElement
                              }


                            },

                            dataset: {
                              source: sourceListV2
                            },
                            xAxis: { type: 'category' },
                            yAxis: {},
                            series: [{ type: 'bar', label: { show: true, position: 'top' }, barWidth: 40 }, { type: 'bar', label: { show: true, position: 'top' }, barWidth: 40 }]
                          }}

                      />


                    </div>

                    <div className='flex items-center justify-center p-2 text-center'>
                      <p><b className='font-bold'>X-Axis: </b>Employee Life Cycle Surveys,</p>
                      <p className='px-4'><b className='font-bold'>Y-Axis: </b>No. of Employees</p>
                    </div>

                  </>
                  :
                  <>
                    <div className="pt-40 text-3xl text-center text-gray-400">Data Not Available!</div>
                  </>}


              </div>
            </div>

            {getMetaResponseAnalyticsFilterData && getMetaResponseAnalyticsFilterData.length > 0 ?
              <>
                <div className='px-2 py-2 text-lg font-bold'>{surveyNameV2}</div>
                <div className="capitalize bg-white border rounded-md">

                  <div className='flex items-center justify-between'>

                    <FilterDMG
                      showFilter={this.state.showFilter}
                      showValue={this.state.showValue}
                      EmpFilterData2={EmpFilterData}
                      DemographicValueList2={getIndexByFilterDMGData}
                      closeFilter={this.closeFilter}
                      handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
                    />

                    <div className='mx-2'>
                      {DownloadExcelResponse && DownloadExcelResponse.length > 0 ?
                        <CSVLink data={DownloadExcelResponse} filename={"SurveyMetaResponseAnalytics.csv"}>
                          <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                            > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <path d="M10 12l4 4m0 -4l-4 4" />
                            </svg>
                            <span>{"Download response"}</span>
                          </button>
                        </CSVLink>
                        : '...'}
                    </div>
                  </div>

                  {getMetaResponseAnalyticsFilterData && getMetaResponseAnalyticsFilterData.length > 0 ?
                    <ResponseMetaTable
                      getResponseOverall={getResponseOverall}
                      getResponseValue={getResponseValue}
                      litsXAxis={this.state.litsXAxis}
                      getMetaRespondantDetailsData={getMetaRespondantDetailsData}
                      getMetaResponseAnalyticsFilterData={getMetaResponseAnalyticsFilterData}
                    />
                    : <>
                      <div className="w-full mb-4 overflow-hidden overflow-x-auto bg-white whitespace-nowrap">
                        {loading ? <div>
                          <div className='py-12 text-lg text-center text-gray-500'>Loading...</div>
                        </div> :
                          <div className='py-12 text-lg text-center text-gray-500'>Data not available</div>
                        }
                      </div>
                    </>}
                </div>

              </>

              : null}
          </div>

        </div>


      </>
    );
  }
}


function mapStateToProps(state) {
  ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(SurveyReport);
