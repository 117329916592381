import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionCard from '../../Dashboard/Overview/Components/QuestionTypeCards/QuestionCard';
// import IndexKPI from './Components/IndexKPI';
import SurveyReport from '../../Dashboard/Overview/SurveyReport/SurveyReport';
// import RiskOverallChart from '../RiskProfile/Components/RiskDemographics/Charts/RiskOverallChart';
import RiskOverallDrivers from '../RiskProfile/Components/RiskDemographics/Charts/RiskOverallDrivers';
// import { HiArrowNarrowRight } from 'react-icons/hi';
import ResponseHeader from '../../CommonComponents/ResponseHeader';

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerDetailsIndexType: 0,
      showManagerList: true,
      CurrentManagerName: 'Select Manager',

      showOverview: true,
      showSurveyReport: false,
      showAllQuestions: false,
      CurrentTemplateId: '',

      optionType: 1,

      allIndexList: [
        {
          "name": "Happiness",
          "template": 1,
          "score": "NaN",
          "type": "Score",
          "color": "#FF4081",
          "image": "/img/Dashboard/Index/happiness_index.png"
        },
        {
          "name": "Engagement",
          "template": 2,
          "score": "NaN",
          "type": "Percentage",
          "color": "#2196F3",
          "image": "/img/Dashboard/Index/engagement_index.png"

        },
        {
          "name": "Hope",
          "template": 3,
          "score": "NaN",
          "type": "Percentage",
          "color": "#009688",
          "image": "/img/Dashboard/Index/hope_index.png"

        },
        {
          "name": "Stress",
          "template": 4,
          "score": "NaN",
          "type": "Percentage",
          "color": "#EF5350",
          "image": "/img/Dashboard/Index/stress_index.png"

        },
        {
          "name": "Manager",
          "template": 5,
          "score": "NaN",
          "type": "Percentage",
          "color": "#9C27B0",
          "image": "/img/Dashboard/Index/manager_index.png"

        },
        {
          "name": "Leadership",
          "template": 6,
          "score": "NaN",
          "type": "Percentage",
          "color": "#FFB300",
          "image": "/img/Dashboard/Index/leadership_index.png"

        },
        {
          "name": "Value",
          "template": 7,
          "score": "NaN",
          "type": "Percentage",
          "color": "#C0CA33",
          "image": "/img/Dashboard/Index/value_index.png"

        },
        // {
        //   "name":"Organization Core",
        //   "template":8,
        //   "score":"NaN"
        // },
        {
          "name": "Safety",
          "template": 9,
          "score": "NaN",
          "type": "Percentage",
          "color": "#00CD7E",
          "image": "/img/Dashboard/Index/safety_index.png"

        },
        {
          "name": "Diversity",
          "template": 10,
          "score": "NaN",
          "type": "Percentage",
          "color": "#3F51B5",
          "image": "/img/Dashboard/Index/diversity_index.png"

        }
      ]


    }
  }

  componentDidMount() {

  }

  openSurveyResponse = () => {
    this.setState({
      showSurveyReport: true,
      showOverview: false,
      showAllQuestions: false
    });

  }

  closeResponseAnalytics = () => {
    this.setState({
      showSurveyReport: false,
      showOverview: true,
      showAllQuestions: false
    })

    let { storeInnerRoute } = this.props;
    storeInnerRoute(false)
  }

  // onAllQuestions=(item)=>{
  //   this.setState({CurrentTemplateId:item.templateId})
  //   this.setState({ 
  //     showSurveyReport:false,
  //     showOverview: false ,
  //     showAllQuestions:true
  //   })

  //   let { storeInnerRoute } = this.props;
  //   storeInnerRoute(true);
  // }

  // onAllMatrixQuestions=()=>{
  //   this.setState({CurrentTemplateId:13})
  //   this.setState({ 
  //     showSurveyReport:false,
  //     showOverview: false ,
  //     showAllQuestions:true
  //   })

  //   let { storeInnerRoute } = this.props;
  //   storeInnerRoute(true)


  // }



  goToOverview = (item) => {
    let { overviewAPI } = this.props;
    overviewAPI();
    this.setState({ CurrentTemplateId: "" })
    this.setState({
      showSurveyReport: false,
      showOverview: true,
      showAllQuestions: false
    })
  }

  onOptionType = (value) => {
    this.setState({ optionType: value })
  }



  handleGoBack = () => {
    this.setState({ showOverview: true })
  }


  render() {
    let { optionType, showOverview, showSurveyReport, CurrentTemplateId } = this.state;
    let {

      EmpFilterData,
      CurrentSurveyId,
      OverallMean,
      getRespondantDetailsData,
      getIndexFilterData,
      getQuestionOthersResultData,

      getSurveyData,

      handleSidebarExtra
    } = this.props;


    // let OverallMeanData = {
    //   "name": "Overall",
    //   "mean": (typeof OverallMean !== 'string' || OverallMean instanceof Number) && OverallMean ? OverallMean.toFixed(2) : 'NaN',
    //   "color": "#4080bf"
    // }

    //  //console.log('OverviewList: ',OverviewList)
    //  //console.log('sdsa getQuestionNPSResultData:',getQuestionNPSResultData)
    //  //console.log('getQuestionNPSResultData:',getQuestionNPSResultData)



    function FormattFun(inputData, isSummary) {
      let RankObj = getSurveyData && getSurveyData.designObj && getSurveyData.designObj.componentRank ? getSurveyData.designObj.componentRank : {}

      let LIST = []
      let prevTemList = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {

          let templateId = ques.total[0]._id.qtemplate;

          let temp = {
            name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
            answerList: [],
            rank: RankObj && RankObj[templateId] ? RankObj[templateId] : 100,
            templateId: templateId
          }
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading: item && item._id && item._id.heading ? item._id.heading : "",
                parentId: item && item._id && item._id.parentId ? item._id.parentId : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId
              }
              temp["answerList"].push(ans);
            });
          }


          if (isSummary) {
            let getIndex = prevTemList.findIndex(prev => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId)
            }
          }
          else {
            LIST.push(temp);
          }


        });
      }
      return LIST
    }

    //  let NPSQuesList = FormattFun(getQuestionNPSResultData);
    let OthersQuesList = FormattFun(getQuestionOthersResultData, true).sort((a, b) => a.rank - b.rank);

    // let AllQuesList = FormattFun(getQuestionOthersResultData, false).filter(x => x.templateId === CurrentTemplateId);



    //////console.log("----->OthersQuesList<-------", OthersQuesList)


    let { getAllRiskEmpConversationData, overallCategoryScore, RiskgetRiskCategoryData, riskMeterCountObj, getQuestionMatrixResultData, questionsList, onAllQuestions, onAllMatrixQuestions, handleRiskDemographics } = this.props;


    let OptionList = [
      {
        "name": "Dashboard",
        "template": 1
      },
      {
        "name": "Question Analysis",
        "template": 2
      }
    ]



    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++
      }
      return count ? true : false
    }

    let PTMTotal = 0;
    let isResolvedCount = 0;
    let inProgressCount = 0;
    let isOpenCount = 0;
    if (getAllRiskEmpConversationData && getAllRiskEmpConversationData.list && getAllRiskEmpConversationData.list.length > 0) {
      getAllRiskEmpConversationData.list.forEach((item) => {
        PTMTotal++
        if (item && item.isHistory) {
          isResolvedCount++
        }
        else if (getInProgress(item)) {
          inProgressCount++
        }
        else {
          isOpenCount++
        }

      })

    }


    let isResolvedPerc = PTMTotal ? ((isResolvedCount / PTMTotal) * 100).toFixed() : "0";
    let inProgressPerc = PTMTotal ? ((inProgressCount / PTMTotal) * 100).toFixed() : "0";
    let isOpenPerc = PTMTotal ? ((isOpenCount / PTMTotal) * 100).toFixed() : "0";





    let ParticipationObj = {
      total: 0,
      completed: 0,
      mailSent: 0,
      clicked: 0,
      participated: 0,
      performance: 0,
      perfPlusSign: true
    }

    if (getRespondantDetailsData && getRespondantDetailsData[0]) {
      let apiData = getRespondantDetailsData[0]
      ParticipationObj['total'] = apiData['total'] ? apiData['total'] : 0;
      ParticipationObj['completed'] = apiData['completed'] ? apiData['completed'] : 0;
      ParticipationObj['mailSent'] = apiData['mailSent'] ? apiData['mailSent'] : 0;
      ParticipationObj['clicked'] = apiData['clicked'] ? apiData['clicked'] : 0;
      ParticipationObj['participated'] = apiData['completed'] && apiData['total'] ? (apiData['completed'] * 100 / apiData['total']).toFixed(2) : 0;



    }

    let responseOption = {
      "item1": {
        "label": "Sent",
        "value": ParticipationObj['total']
      },
      "item2": {
        "label": "Responded",
        "value": ParticipationObj['completed']
      },
      "item3": {
        "label": "Response Rate",
        "value": ParticipationObj['participated'] + '%'
      }
    }


    return (
      <>
        <main className="flex-1 w-full p-4 pb-20 overflow-hidden overflow-y-auto bg-[#f9fafb] md:p-6 " style={{ height: 'calc(100% - 4rem)' }}>


          {showOverview ?
            <>

              <div className="space-y-6" >

                {/* Dashboard */}
                {optionType === 1 ?
                  <>

                    <div className='flex items-center justify-between' >
                      <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Sense Overview </h1>
                      <span class="material-symbols-outlined cursor-pointer lg:hidden">menu_open</span>
                    </div>

                    <div onClick={() => this.openSurveyResponse()}>
                      {/* <IndexKPI dashboardType={2} getRespondantDetailsData={getRespondantDetailsData} /> */}
                      <ResponseHeader responseOption={responseOption} />

                    </div>

                    {/* Tab */}


                    <div className="items-center justify-between space-x-6 md:flex">
                      {/* left side */}
                      <div className="flex items-center w-full space-x-2 overflow-x-auto border-b whitespace-nowrap">
                        <h1
                          onClick={() => this.setState({ optionType: 1 })}
                          className={
                            optionType === 1
                              ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                              : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-slate-600   rounded-t-md border"
                          }
                        >
                          {"Dashboard"}
                        </h1>

                        <h1
                          onClick={() => this.setState({ optionType: 2 })}
                          className={
                            optionType === 2
                              ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                              : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-slate-600   rounded-t-md border  "
                          }
                        >
                          {"Question Analysis"}
                        </h1>
                      </div>
                    </div>


                    {/* <div className="items-center justify-between border-b md:flex">
                      <div className="flex space-x-4 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 whitespace-nowrap tab-scroll">

                        {OptionList && OptionList.length > 0 ?
                          OptionList.map((item) =>
                            <span onClick={() => this.onOptionType(item.template)}
                              className={optionType === item.template ? "cursor-pointer py-3 block   text-blue-500 border-blue-500  border-b-2" : "cursor-pointer py-3 block "}>
                              {item.name}
                            </span>
                          ) : null}

                      </div>
                    </div> */}

                    <div className='bg-white p-6 border space-y-4 '>
                      <div className='flex items-center flex-row gap-4 justify-between w-full'>
                        <div className='flex items-center text-[#212121] space-x-2'>
                          <p className='p-3 bg-[#ededed] font-semibold'>Q1</p>
                          <p className='font-medium '>Yes / No</p>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <button className='p-3 bg-[#ededed] rounded flex flex-row items-center gap-2 text-[#212121] relative'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                              <path d="M12.1875 2.8125V25.3125H10.3125V6.5625H4.6875V25.3125H2.8125V27.1875H27.1875V25.3125H25.3125V13.125H19.6875V25.3125H17.8125V2.8125H12.1875ZM14.0625 4.6875H15.9375V25.3125H14.0625V4.6875Z" fill="#212121" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor" class="icon icon-tabler icons-tabler-filled icon-tabler-caret-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" /></svg>

                            <div className='bg-white rounded absolute top-12 w-fit divide-y left-0 shadow-xl whitespace-nowrap px-2'>
                              <div className='flex items-center space-x-2 text-[#212121] p-3 text-sm'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                                  <path d="M12.1875 2.8125V25.3125H10.3125V6.5625H4.6875V25.3125H2.8125V27.1875H27.1875V25.3125H25.3125V13.125H19.6875V25.3125H17.8125V2.8125H12.1875ZM14.0625 4.6875H15.9375V25.3125H14.0625V4.6875Z" fill="#212121" />
                                </svg>
                                <span>Bar chart</span>
                              </div>
                              <div className='flex items-center space-x-2 text-[#212121] p-3 text-sm'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                                  <path d="M26.25 2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V26.25C2.5 26.5815 2.6317 26.8995 2.86612 27.1339C3.10054 27.3683 3.41848 27.5 3.75 27.5H26.25C26.5815 27.5 26.8995 27.3683 27.1339 27.1339C27.3683 26.8995 27.5 26.5815 27.5 26.25V3.75C27.5 3.41848 27.3683 3.10054 27.1339 2.86612C26.8995 2.6317 26.5815 2.5 26.25 2.5ZM10 25H5V20H10V25ZM10 17.5H5V12.5H10V17.5ZM10 10H5V5H10V10ZM17.5 25H12.5V20H17.5V25ZM17.5 17.5H12.5V12.5H17.5V17.5ZM17.5 10H12.5V5H17.5V10ZM25 25H20V20H25V25ZM25 17.5H20V12.5H25V17.5ZM25 10H20V5H25V10Z" fill="#212121" />
                                </svg>
                                <span>Table</span>
                              </div>

                            </div>
                          </button>
                          <button className='p-3 bg-[#ededed] rounded text-[#212121]'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-download"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" /><path d="M7 11l5 5l5 -5" /><path d="M12 4l0 12" /></svg></button>

                        </div>
                      </div>
                      <h2 className='text-xl font-medium text-[#212121]'>
                        I feel proud to recommend this organization to a friend seeking employment.
                      </h2>
                      <p className='text-base text-[#212121]/80'><b className='font-medium text-[#212121] '>10</b> out of 10 answered</p>

                      <div className='bg-red-50 h-40 w-full'></div>
                      <div className='bg-[#f9f9f9] p-6 rounded'>
                        <table className='w-full'>
                          <thead>
                            <tr className='border-b'>
                              <th className='font-medium text-left text-lg p-4'>Answer Choices</th>
                              <th className='font-medium text-left text-lg p-4'>Responses</th>
                              <th className='font-medium text-left text-lg p-4'>Responses Percentage</th>
                            </tr>
                          </thead>
                          <tbody className='divide-y '>
                            <tr className='text-[#212121]'>
                              <td className='p-4 text-left'>Yes</td>
                              <td className='p-4 text-left'>5</td>
                              <td className='p-4 text-left'>100.00%</td>
                            </tr>
                            <tr className='text-[#212121]'>
                              <td className='p-4 text-left'>No</td>
                              <td className='p-4 text-left'>5</td>
                              <td className='p-4 text-left'>100.00%</td>
                            </tr>
                          </tbody>

                        </table>
                      </div>
                      <button className='flex items-center space-x-2 font-medium text-[#2196F3] p-2 w-fit mx-auto'>
                        <span>View Demographic Wise</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg>
                      </button>

                    </div>




                    <div className="grid grid-cols-1 gap-6 xl:grid-cols-2 lg:grid-cols-2 ">

                      {/* NPS */}
                      {OthersQuesList && OthersQuesList.length > 0 ? OthersQuesList.map((item) =>
                        (item && item.answerList[0] && item.answerList[0].template === 6) ?
                          <div className="flex flex-col justify-between w-full p-4 space-y-4 bg-white border rounded-lg" onClick={() => onAllQuestions(item)}>
                            <h1 className='font-medium xl:text-xl text-lg text-[#3D405B] line-clamp-2' >{"NPS ( Net Promoter Score )"}</h1>
                            <div className="w-full space-y-4 ">
                              <QuestionCard item={item} name={""} isMainCard={true} />
                            </div>
                            {/*                               
                              <div className='flex justify-center p-4 ' ><div onClick={() => onAllQuestions(item)} className='flex items-center space-x-2 font-medium text-blue-500 cursor-pointer' ><span>View More  </span>
                              </div></div> */}
                          </div>

                          : null
                      ) : null}


                      {/* Overall Sense Driver Score */}


                      <div className="flex flex-col justify-between w-full p-4 space-y-4 bg-white border rounded-lg">
                        <RiskOverallDrivers
                          overallCategoryScore={overallCategoryScore}
                          RiskgetRiskCategoryData={RiskgetRiskCategoryData}
                        />
                        <div className='flex justify-center p-4 ' >
                          <button onClick={() => handleRiskDemographics(true)} class=" text-[#2196f3]  cursor-pointer transition duration-150 flex items-center uppercase font-medium xl:text-base text-sm ">View More<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right ml-2" width="34" height="34" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2196f3" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="5" y1="12" x2="19" y2="12"></line><line x1="15" y1="16" x2="19" y2="12"></line><line x1="15" y1="8" x2="19" y2="12"></line></svg>
                          </button>
                        </div>

                      </div>



                    </div>

                    <div className='grid w-full grid-cols-1 gap-6 lg:grid-cols-2 '>


                      {/* People to meet case breakdown */}
                      <div className='w-full p-4 space-y-4 bg-white border rounded-lg' >
                        <h1 className='font-medium xl:text-xl text-lg text-[#3D405B] line-clamp-2' >People to meet case breakdown</h1>

                        <table className='w-full ' >
                          <tr>
                            <th> </th>
                            <th> </th>
                            <th></th>
                          </tr>
                          <tr>
                            <td className='px-12'></td>
                            <td className='p-2 text-white bg-red-500' >
                              <div className='flex justify-center items-left' >{riskMeterCountObj["Extremely_High_Risk"]}</div>
                            </td>
                            <td className='p-2 py-4 pl-8 font-medium text-left text-gray-500'>Extremely High Risk</td>


                          </tr>
                          <tr>
                            <td className='px-12'></td>
                            <td className='p-2 text-white bg-red-400' ><div className='flex justify-center items-left' >
                              {riskMeterCountObj["High_Risk"]}</div>
                            </td>
                            <td className='p-2 py-4 pl-8 font-medium text-left text-gray-500'>High Risk</td>

                          </tr>
                          <tr>
                            <td className='px-12'></td>
                            <td className='p-2 text-white bg-red-300' ><div className='flex justify-center items-left' >
                              {riskMeterCountObj["Borderline"]}</div>
                            </td>
                            <td className='p-2 py-4 pl-8 font-medium text-left text-gray-500'>Borderline </td>

                          </tr>
                          <tr>
                            <td className='px-12'></td>
                            <td className='p-2 text-white bg-yellow-400' ><div className='flex justify-center items-left ' >
                              {riskMeterCountObj["Moderate_Risk"]}</div>
                            </td>
                            <td className='p-2 py-4 pl-8 font-medium text-left text-gray-500'>Moderate Risk </td>

                          </tr>
                          <tr>
                            <td className='px-12'></td>
                            <td className='p-2 text-white bg-green-500' ><div className='flex justify-center items-left ' >
                              {riskMeterCountObj["No_Risk"]}</div>
                            </td>
                            <td className='p-2 py-4 pl-8 font-medium text-left text-gray-500'>No Risk </td>

                          </tr>
                        </table>

                        <div className='flex justify-center p-4 ' >
                          <button onClick={() => handleRiskDemographics(false)} class=" text-[#2196f3]  cursor-pointer transition duration-150 flex items-center uppercase font-medium xl:text-base text-sm ">View More<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right ml-2" width="34" height="34" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2196f3" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="5" y1="12" x2="19" y2="12"></line><line x1="15" y1="16" x2="19" y2="12"></line><line x1="15" y1="8" x2="19" y2="12"></line></svg>
                          </button>
                        </div>

                      </div>

                      {/*PTM case resolve */}

                      <div className='flex flex-col justify-between w-full p-4 space-y-8 bg-white border rounded-lg' >
                        <div className='flex divide-x' >
                          <div className='flex flex-col items-center justify-center w-full p-4 space-y-4' >
                            <h1 className='font-medium xl:text-xl text-lg text-[#3D405B] line-clamp-2' >PTM case Open</h1>
                            <h1 className='text-xl md:text-3xl' >{isOpenCount}</h1>
                          </div>

                          <div className='flex flex-col items-center justify-center w-full p-4 space-y-4' >
                            <h1 className='font-medium xl:text-xl text-lg text-[#3D405B] line-clamp-2' >PTM case InProgress</h1>
                            <h1 className='text-xl md:text-3xl' >{inProgressCount}</h1>
                          </div>

                          <div className='flex flex-col items-center justify-center w-full p-4 space-y-4' >
                            <h1 className='font-medium xl:text-xl text-lg text-[#3D405B] line-clamp-2' >PTM case Resolved</h1>
                            <h1 className='text-xl md:text-3xl' >{isResolvedCount}</h1>
                          </div>
                        </div>

                        <div className='flex w-5/6 h-10 mx-auto 2xl:w-5/6' >
                          {/* green */}
                          <div className='w-2/6 h-10 bg-yellow-400' style={{ width: isOpenPerc + "%" }}></div>
                          {/* red */}
                          <div className='w-2/6 h-10 bg-blue-400' style={{ width: inProgressPerc + "%" }}></div>
                          {/* red */}
                          <div className='w-2/6 h-10 bg-green-400' style={{ width: isResolvedPerc + "%" }}></div>



                        </div>
                        {/* text */}
                        <div className='flex flex-col items-center justify-center xl:flex-row md:space-x-4' >

                          <div className='flex items-center ' >
                            <div className='w-4 h-4 mr-2 bg-yellow-400' ></div>
                            <p>Open -</p>
                            <p className='font-medium' >{isOpenCount} ({isOpenPerc}%)</p>
                          </div>

                          <div className='flex items-center ' >
                            <div className='w-4 h-4 mr-2 bg-blue-400' ></div>
                            <p>InProgress -</p>
                            <p className='font-medium' >{inProgressCount} ({inProgressPerc}%)</p>
                          </div>

                          <div className='flex items-center ' >
                            <div className='w-4 h-4 mr-2 bg-green-400' ></div>
                            <p>Resolved -</p>
                            <p className='font-medium' >{isResolvedCount} ({isResolvedPerc}%)</p>
                          </div>


                        </div>

                        <div className='flex justify-center p-4 ' >
                          <button onClick={() => handleSidebarExtra(5)} class=" text-[#2196f3]  cursor-pointer transition duration-150 flex items-center uppercase font-medium xl:text-base text-sm ">View More<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right ml-2" width="34" height="34" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2196f3" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="5" y1="12" x2="19" y2="12"></line><line x1="15" y1="16" x2="19" y2="12"></line><line x1="15" y1="8" x2="19" y2="12"></line></svg>
                          </button>
                        </div>

                      </div>
                    </div>

                  </>
                  : null}



                {/* Question Analysis */}
                {optionType === 2 ?
                  <>
                    <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Sense Question Analysis</h1>
                    <div onClick={() => this.openSurveyResponse()}>
                      {/* <IndexKPI dashboardType={2} getRespondantDetailsData={getRespondantDetailsData} /> */}
                      <ResponseHeader responseOption={responseOption} />

                    </div>

                    {/* Tab */}
                    {/* <div className="items-center justify-between border-b md:flex">
                      <div className="flex space-x-4 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 whitespace-nowrap tab-scroll">

                        {OptionList && OptionList.length > 0 ?
                          OptionList.map((item) =>
                            <span onClick={() => this.onOptionType(item.template)}
                              className={optionType === item.template ? "cursor-pointer py-3 block   text-blue-500 border-blue-500  border-b-2" : "cursor-pointer py-3 block "}>
                              {item.name}
                            </span>
                          ) : null}

                      </div>
                    </div> */}


                    <div className="items-center justify-between space-x-6 md:flex">
                      {/* left side */}
                      <div className="flex items-center w-full space-x-2 overflow-x-auto border-b whitespace-nowrap">
                        <h1
                          onClick={() => this.setState({ optionType: 1 })}
                          className={
                            optionType === 1
                              ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                              : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-slate-600 rounded-t-md border"
                          }
                        >
                          {"Dashboard"}
                        </h1>

                        <h1
                          onClick={() => this.setState({ optionType: 2 })}
                          className={
                            optionType === 2
                              ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                              : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-slate-600 rounded-t-md border  "
                          }
                        >
                          {"Question Analysis"}
                        </h1>
                      </div>
                    </div>


                    <div className="grid gap-4 mb-24 xl:grid-cols-3 md:grid-cols-2 md:gap-6">



                      {OthersQuesList && OthersQuesList.length > 0 ? OthersQuesList.map((item) =>
                        // (item && item.answerList[0] && item.answerList[0].template !== 6) ?
                        <div onClick={() => onAllQuestions(item)} className="p-4 bg-white border rounded-lg">
                          <QuestionCard item={item} name={""} />
                        </div>
                        // : null
                      ) : null}

                      {getQuestionMatrixResultData && getQuestionMatrixResultData.length > 0 ?
                        <div onClick={() => onAllMatrixQuestions()}>
                          <QuestionCard
                            questionType={"matrix"}
                            getQuestionMatrixResultData={getQuestionMatrixResultData}
                            questionsList={questionsList}
                          />
                        </div>
                        : null}
                    </div>

                  </>
                  : null}
              </div>

            </>
            :
            null}

          {showSurveyReport ?
            <div className="">
              <SurveyReport
                handleGoBack={this.handleGoBack}
                CurrentSurveyId={CurrentSurveyId}
                closeResponseAnalytics={this.closeResponseAnalytics}
                EmpFilterData={EmpFilterData}
                getIndexFilterData={getIndexFilterData}
                getRespondantDetailsData={getRespondantDetailsData}
              />
            </div>
            : null}

          {/* {showAllQuestions?

          :null} */}
        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
