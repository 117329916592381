import React, { Component } from "react";
import { connect } from "react-redux";
import ReactEcharts from "echarts-for-react";

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList: [],
      EmpIndexA: 0,
      EmpIndexB: 4,
    };
  }
  componentDidMount() {}

  render() {
    let { AnswerObj, name, size, questionName } = this.props;

    let DataList = [];
    let total = 0;
    if (AnswerObj) {
      Object.keys(AnswerObj).forEach((key) => {
        let score = parseInt(AnswerObj[key]);
        total += score;
        DataList.push({
          name: key,
          value: score,
        });
      });
    }

    // //console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaAnswerObj: ',AnswerObj)
    // //console.log('DataList: ',DataList)

    let starRating = 0;
    if (AnswerObj) {
      let calA =
        1 * AnswerObj["Star 1"] +
        2 * AnswerObj["Star 2"] +
        2 * AnswerObj["Star 2"] +
        2 * AnswerObj["Star 2"] +
        2 * AnswerObj["Star 2"];
      let calB =
        AnswerObj["Star 1"] +
        AnswerObj["Star 2"] +
        AnswerObj["Star 2"] +
        AnswerObj["Star 2"] +
        AnswerObj["Star 2"];
      starRating = parseFloat(calA / calB).toFixed(2);
    }

    //console.log('starRating: ',starRating)

    // [
    //   {value: 1, name: '7'},
    //   {value: 2, name: '2'},
    //   {value: 3, name: '9'},
    //   {value: 4, name: '10'},
    //   {value: 5, name: '3'}
    // ]

    DataList.sort((a, b) => {
      if (a.name.slice(0, -2) && b.name.slice(0, -2)) {
        return a.name.slice(5, 6) - b.name.slice(5, 6);
      }
    });

    let LabelList = [];
    let ValueList = [];

    let List = [];

    if (DataList && DataList.length > 0) {
      DataList.forEach((item) => {
        List.push({
          label: item.name,
          value: ((parseFloat(item.value) * 100) / total).toFixed(2),
          itemStyle: { color: "#9933ff" },
        });

        // LabelList.push(item.name);
        // ValueList.push({
        //   value:((parseInt(item.value)*100)/total).toFixed(2),
        //   itemStyle:{color:'#9933ff'}
        // });
      });
    }

    // //console.log('Math.round(starRating):------> ',Math.round(starRating))
    // //console.log('total: ',total)
    function isFloat(n) {
      return Number(n) % 1 != 0;
    }
    // //console.log('Math.round(starRating):------> ',isFloat(parseFloat(starRating)))

    let tempArray = [1, 2, 3, 4, 5];
    let { stage, index } = this.props;
    return (
      <>
        {/* <div className="flex justify-between">
            {stage!==3?
            <div><h1 className="text-xl font-medium">Star Rating</h1>
              <p className="text-sm  py-1">  
                {"1. "+questionName}</p></div>
            :null}

            <div className="whitespace-nowrap ml-4 cursor-pointer"><span className="text-blue-500 text-center hover:text-blue-700">{stage==2?"View Details":(stage==3?"":"View More")}</span></div>
          </div> */}

        {/* <div className="flex justify-between pb-2">
                            <h1 className="text-lg font-medium">Star Rating</h1>
                            <div className="whitespace-nowrap ml-4 cursor-pointer"><span className="text-white text-center text-xs px-4 py-2 rounded-md  bg-blue-500 hover:bg-blue-600 ">{stage==2?"View Details":(stage==3?"":"View More")}</span></div>
                        </div> 


                        <p className="text-sm text-gray-800 py-1 truncate">
                                {stage==2?
                                <b>{"Q"+(index+1)+"."}</b>
                                :
                                <b>Q1.</b>
                                }'
                        {questionName}</p> */}

        <div className="flex justify-between pb-2">
          <div className="dropdown inline-block relative">
            <h1 className="text-lg font-medium truncate cursor-pointer">
              {/* {stage==2?
                                <b>{"Q"+(index+1)+"."}</b>
                                :
                                <b>Q1.</b>
                                } */}
              {questionName && questionName.length > 30
                ? questionName.slice(0, 30) + "..."
                : questionName}
            </h1>
            <div className="cursor-pointer dropdown-menu hidden bg-white font-semibold absolute -left-4 -top-12 z-30 pt-1 text-lg rounded-md shadow-md p-4">
              {questionName}
            </div>
          </div>
          <div className="whitespace-nowrap ml-4 cursor-pointer">
            <span className="text-white text-center text-xs px-4 py-2 rounded-md  bg-blue-500 hover:bg-blue-600 ">
              {stage == 2 ? "View Details" : stage == 3 ? "" : "View More"}
            </span>
          </div>
        </div>

        <div className="mb-8 mt-4">
          <h1 className="text-2xl text-center ">{starRating}</h1>
          <div className="my-1  flex justify-center">
            {tempArray.map((item, index) =>
              item <= Math.round(starRating) ? (
                isFloat(starRating) && item === Math.round(starRating) ? (
                  <span className="material-symbols-outlined text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-white">
                    star
                  </span>
                ) : (
                  <span className="material-symbols-outlined text-yellow-400">
                    star
                  </span>
                )
              ) : (
                <span className="material-symbols-outlined text-gray-100">
                  star
                </span>
              )
            )}
          </div>
        </div>

        {/* progress */}
        <div className="space-y-2">
          {List && List.length > 0
            ? List.map((item) => (
                <div className="flex items-center">
                  <p className="whitespace-nowrap text-sm w-16 font-semibold">
                    {item.label}
                  </p>
                  <div className="h-6 bg-gray-200 w-full">
                    <div
                      style={{ width: item.value + "%" }}
                      className="w-3/6 bg-gradient-to-r from-yellow-400 to-yellow-500 h-6  relative"
                    >
                      <div className="absolute right-2 text-sm font-normal text-white p-0.5">
                        {item.value + "%"}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}

          {/* 
            <div className="flex items-center">
              <p className="whitespace-nowrap text-xs text-gray-500 w-16">5 star</p>
              <div className="h-1 bg-gray-100 w-full">
                <div className="w-3/6 bg-yellow-400 h-1" />
              </div>
            </div>


            <div className="flex items-center">
              <p className="whitespace-nowrap text-xs text-gray-500 w-16">4 star</p>
              <div className="h-1 bg-gray-100 w-full">
                <div className="w-3/6 bg-yellow-400 h-1" />
              </div>
            </div>


            <div className="flex items-center">
              <p className="whitespace-nowrap text-xs text-gray-500 w-16">3 star</p>
              <div className="h-1 bg-gray-100 w-full">
                <div className="w-3/6 bg-yellow-400 h-1" />
              </div>
            </div>

            <div className="flex items-center">
              <p className="whitespace-nowrap text-xs text-gray-500 w-16">2 star</p>
              <div className="h-1 bg-gray-100 w-full">
                <div className="w-3/6 bg-yellow-400 h-1" />
              </div>
            </div>


            <div className="flex items-center">
              <p className="whitespace-nowrap text-xs text-gray-500 w-16">1 star</p>
              <div className="h-1 bg-gray-100 w-full">
                <div className="w-3/6 bg-yellow-400 h-1" />
              </div>
            </div> */}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(PieChart);
