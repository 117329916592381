import React, { Component } from "react";
import { connect } from "react-redux";
import GaugeScore from "../Components/Charts/GaugeScore";

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();

    this.state = {
      currFilterName: "none",
      viewScore: true,
      showDropDown1: false,
      showDropDown2: false,
      showFilter: false,
      showValue: "",

      showFilterDMG: false,
      showValueDMG: "",
      FilterValuesDMG: [],

      selectedCategory: {
        name: "All",
        label: "Show All",
      },
      showReportModal: false,

      DimensionList: [
        {
          index: 1,
          name: "Happiness",
          cutOff: 59,
          range: 72,
          type: "",
        },
        {
          index: 2,
          name: "Engagement",
          cutOff: 78,
          range: 100,
          type: "%",
        },
        {
          index: 4,
          name: "Stress",
          cutOff: 28,
          range: 60,
          type: "%",
        },
        {
          index: 5,
          name: "Manager",
          cutOff: 59,
          range: 100,
          type: "%",
        },
        {
          index: 6,
          name: "Leader",
          cutOff: 50,
          range: 100,
          type: "%",
        },
        // {
        //   index:8,
        //   name:'OrgCore',
        //   cutOff:59
        // },
      ],

      selectedItem1: {
        index: 1,
        name: "Happiness",
        cutOff: 59,
        range: 72,
        type: "",
      },
      selectedItem2: {
        index: 2,
        name: "Engagement",
        cutOff: 78,
        range: 100,
        type: "%",
      },

      currentSelectValue: "",
      FilterValues: [],
      showQueDrop: false,
      storeQues1: "",
      storeQues2: "",
    };
  }
  componentDidMount() {}

  handleDropdown1 = (check) => {
    this.setState({ showDropDown1: !check, showDropDown2: false });
  };
  handleDropdown2 = (check) => {
    this.setState({ showDropDown2: !check, showDropDown1: false });
  };

  openQuesDrop = (check, no) => {
    if (no === 1) {
      this.setState({ showQueDrop1: check, showQueDrop2: false });
    }
    if (no === 2) {
      this.setState({ showQueDrop2: check, showQueDrop1: false });
    }
  };

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  closeFilterDMG = (check1, check2) => {
    this.setState({ showFilterDMG: check1, showValueDMG: check2 });
  };

  handleMultifilterIndexScore2DMG = (finalFilter) => {
    this.setState({ FilterValuesDMG: finalFilter }, () => {
      let { emptyRiskQuesEmp } = this.props;
      emptyRiskQuesEmp();
    });
  };

  onSelectedCategory = (value) => {
    this.setState({ selectedCategory: value }, () => {
      let { emptyRiskQuesEmp } = this.props;
      emptyRiskQuesEmp();
    });
  };

  handleExportWithComponent = (event) => {
    this.setState({ setPDF: true }, () => {
      this.pdfExportComponent.current.save();
    });
  };

  openPopupReport = (check) => {
    this.setState({ showReportModal: check });
  };

  render() {
    let {
      RiskRespondantEmployeesData,
      loading,
      RiskAllEmpQuesResponsesData,
      check,
    } = this.props;
    let { showReportModal, selectedCategory, FilterValuesDMG } = this.state;

    let {
      handleSelectEmpFilter,
      crrSelectedEmp,
      RiskgetEmpQuestionResponsesData,
      RiskgetRiskCategoryData,
    } = this.props;

    // let CategoryList = []

    // if(qList && qList.length>0){
    //   CategoryList = qList.filter(prev=>prev.riskcategoryBucket)

    // }

    function calcScore(questionDetail) {
      let sum = 0;
      // let total = 0;
      let noResponse = false;
      if (questionDetail && questionDetail.length > 0) {
        questionDetail.forEach((ques) => {
          if (ques.qtemplate === 1) {
            // total += 6
            sum += Number(ques.answer);
          } else if (ques.qtemplate === 6 || ques.qtemplate === 16) {
            // total += 6

            if (ques && ques.answerText) {
              let temp = Number(ques.answerText);
              sum += Number(temp);
            } else {
              noResponse = true;
            }
            // if(temp >=0 && temp <=5){
            //   npsNewScore = 1;
            // }
            // else if(temp >=6 && temp <=7){
            //   npsNewScore = 4;
            // }
            // else{
            //   npsNewScore = 6;
            // }
          }
        });
      }
      // ////console.log("start-------")
      // ////console.log("sum",sum)
      // ////console.log("total",total)

      return noResponse ? "-" : sum;
    }

    let qList = RiskgetEmpQuestionResponsesData;

    ////console.log("qList-------->>", qList)

    let RiskCategory = [];
    let scoreSum = 0;
    let scoreCount = 0;

    ////console.log("RiskgetRiskCategoryData-------->>", RiskgetRiskCategoryData)

    function ToString(value) {
      return value ? value.toString().trim() : "";
    }
    // let AlreadyList = []

    if (
      RiskgetRiskCategoryData &&
      RiskgetRiskCategoryData.length > 0 &&
      qList &&
      qList.length > 0
    ) {
      RiskgetRiskCategoryData.forEach((category) => {
        ////console.log("category-------->>", category);

        let questionDetailFinal = qList.filter(
          (prev) => ToString(prev.riskCategoryId) === ToString(category.id)
        );

        RiskCategory.push({
          name: category.name,
          id: category.id,
          questionList: questionDetailFinal,
          categoryCount:
            questionDetailFinal && questionDetailFinal.length > 0
              ? questionDetailFinal.length
              : 0,
          score: calcScore(questionDetailFinal),
        });
      });
    }

    ////console.log("RiskCategory-------->>", RiskCategory)

    // ////console.log("scoreSum-------->>",scoreSum)
    // ////console.log("scoreCount-------->>",scoreCount)

    let totalScore = scoreSum / scoreCount;

    let categoryShow = [];
    let newQuestionList = [];
    if (RiskCategory && RiskCategory.length > 0) {
      RiskCategory.forEach((item, index1) => {
        if (item && item.questionList && item.questionList.length > 0) {
          item.questionList.forEach((item3, index3) => {
            let temp = {
              ...item3,
              questionScore: item.score,
            };
            temp["riskcategoryValue"] = item.name;

            let getIndex = categoryShow.findIndex((prev) => prev === item.name);
            if (getIndex === -1) {
              categoryShow.push(item.name);
              temp["categoryShow"] = true;
              temp["categoryCount"] = item.categoryCount;
            } else {
              temp["categoryShow"] = false;
            }

            newQuestionList.push(temp);
          });
        }
      });
    }

    //let Qcount = 0
    let grandTotal = 0;
    let grandScore = 0;
    if (newQuestionList && newQuestionList.length > 0) {
      newQuestionList.forEach((ques) => {
        ////console.log("npsNewScore-------->>", ques.qtemplate);
        if (Number(ques.qtemplate) === 1) {
          //Qcount++
          grandTotal += 6;
          grandScore += Number(ques.answer);
        }
        // else if(Number(ques.qtemplate) === 6 || Number(ques.qtemplate) === 16){

        //   grandTotal += 6
        //   let npsNewScore = 0;
        //   let temp = Number(ques.answerText)
        //   if(temp >=0 && temp <=5){
        //     npsNewScore = 1;
        //   }
        //   else if(temp >=6 && temp <=7){
        //     npsNewScore = 4;
        //   }
        //   else{
        //     npsNewScore = 6;
        //   }
        //   ////console.log("temp-------->>",temp);
        //   ////console.log("npsNewScore-------->>",npsNewScore);

        //   grandScore += Number(npsNewScore)
        // }
      });
    }
    // console.log("Qcount-------->>", Qcount);
    console.log(
      "newQuestionList Count-------->",
      newQuestionList && newQuestionList.length > 0 ? newQuestionList.length : 0
    );
    console.log("grandTotal-------->>1111111111", grandTotal);
    console.log("grandScore-------->>22222222222", grandScore);

    // "#b31717",
    // "#e65757",
    // "#edb409",
    // "#1bbf36"

    function getColor(item) {
      let color = "#858784";

      if (item && item.questionScore && item.questionScore !== "-") {
        if (item.qtemplate.toString() === "1") {
          let score = Number(item.questionScore);
          if (score > 24 && score <= 30) {
            color = "#1bbf36";
          } else if (score > 17 && score <= 24) {
            color = "#edb409";
          } else if (score > 12 && score <= 17) {
            color = "#e65757";
          } else if (score >= 6 && score <= 12) {
            color = "#b31717";
          }
        } else if (
          item.qtemplate.toString() === "6" ||
          item.qtemplate.toString() === "16"
        ) {
          let score = Number(item.questionScore);
          if (score >= 9 && score <= 10) {
            color = "#1bbf36";
          } else if (score >= 7 && score <= 8) {
            color = "#edb409";
          } else if (score >= 0 && score <= 6) {
            color = "#e65757";
          }
        }
      } else if (
        item.qtemplate.toString() === "16" &&
        item.questionScore === 0
      ) {
        color = "#e65757";
      }

      return color;
    }

    // function getAnswer(ques){
    //   let answer = "-"
    //   if(Number(ques.qtemplate) === 1){
    //     answer = ques && ques.answer?ques.answer:"-"
    //   }
    //   else if(Number(ques.qtemplate) === 6 || Number(ques.qtemplate) === 16){
    //     answer = ques && ques.answerText?ques.answerText:"-"
    //   }
    //   return answer

    // }

    let { EmpFilterData, getIndexFilterData } = this.props;

    // function ToString(value){
    //   return value?(value.toString()).trim():""
    // }

    // function calcScore(questionDetail){
    //   let sum = 0;
    //   if(questionDetail && questionDetail.length>0){
    //     questionDetail.forEach((ques)=>{
    //       if(ques.qtemplate===1){
    //         sum += Number(ques.answer)
    //       }
    //     })
    //   }
    //   return sum
    // }

    function getRiskMeterScore(resList, CatList, name) {
      let RiskCategory = 0;
      if (CatList && CatList.length > 0 && resList && resList.length > 0) {
        CatList.forEach((category) => {
          let questionDetailFinal = resList.filter(
            (prev) => ToString(prev.riskCategoryId) === ToString(category.id)
          );
          RiskCategory += calcScore(questionDetailFinal);
        });
      }

      let getRiskScore = Number(RiskCategory);
      let isContain = false;
      if (getRiskScore >= 25 && getRiskScore < 50) {
        if (name === "Extremely_High_Risk") {
          isContain = true;
        }
      } else if (getRiskScore >= 50 && getRiskScore < 75) {
        if (name === "High_Risk") {
          isContain = true;
        }
      } else if (getRiskScore >= 75 && getRiskScore < 100) {
        if (name === "Borderline") {
          isContain = true;
        }
      } else if (getRiskScore >= 100 && getRiskScore < 125) {
        if (name === "Moderate_Risk") {
          isContain = true;
        }
      } else if (getRiskScore >= 125 && getRiskScore <= 150) {
        if (name === "No_Risk") {
          isContain = true;
        }
      }

      return isContain ? getRiskScore : 0;
    }

    let FilterAllEmpResponses = [];

    if (RiskAllEmpQuesResponsesData && RiskAllEmpQuesResponsesData.length > 0) {
      RiskAllEmpQuesResponsesData.forEach((item) => {
        let isCheck = 0;
        if (FilterValuesDMG && FilterValuesDMG.length > 0) {
          FilterValuesDMG.forEach((filter) => {
            if (filter.value !== "All") {
              let Emp =
                item && item["responses"] && item["responses"][0]
                  ? item["responses"][0]
                  : {};
              if (!(Emp[filter.level] === filter.value)) {
                isCheck++;
              }
            }
          });
        }
        // console.log('x Emp: ',Emp);
        // console.log('x isCheck: ',isCheck);

        if (isCheck === 0) {
          FilterAllEmpResponses.push(item);
        }
      });
    }

    ////console.log("FilterAllEmpResponses--->", FilterAllEmpResponses)

    let empResponseList = [];
    if (selectedCategory && selectedCategory.name === "All") {
      if (
        FilterAllEmpResponses &&
        FilterAllEmpResponses.length > 0 &&
        selectedCategory &&
        selectedCategory.name
      ) {
        FilterAllEmpResponses.forEach((item) => {
          if (item && item["_id"]) {
            empResponseList.push({
              ...item["_id"],
            });
          }
        });
      }
    } else {
      if (
        FilterAllEmpResponses &&
        FilterAllEmpResponses.length > 0 &&
        selectedCategory &&
        selectedCategory.name
      ) {
        FilterAllEmpResponses.forEach((item) => {
          ////console.log("-Values--->", item.responses, RiskgetRiskCategoryData, selectedCategory.name)
          ////console.log("---->", getRiskMeterScore(item.responses, RiskgetRiskCategoryData, selectedCategory.name))

          if (
            item &&
            item["_id"] &&
            getRiskMeterScore(
              item.responses,
              RiskgetRiskCategoryData,
              selectedCategory.name
            )
          ) {
            empResponseList.push({
              ...item["_id"],
            });
          }
        });
      }
    }

    ////console.log("empResponseList--->", empResponseList)
    ////console.log("///////selectedCategory--->", selectedCategory)

    ////console.log("///////crrSelectedEmp--->", crrSelectedEmp)

    function getFirstEmp(list) {
      if (list && list.length > 0) {
        return list && list[0] && list[0].name ? list[0].name : "-";
      } else {
        return "No Employee";
      }
    }

    function getRiskLabel(score) {
      let name = "";
      let color = "";
      let getRiskScore = Number(score);
      console.log("getRiskScore---->", getRiskScore);
      if (getRiskScore >= 25 && getRiskScore < 50) {
        name = "Extremely_High_Risk";
        color = "#b31717";
      } else if (getRiskScore >= 50 && getRiskScore < 75) {
        name = "High_Risk";
        color = "#e65757";
      } else if (getRiskScore >= 75 && getRiskScore < 100) {
        name = "Borderline";
        color = "#f29083";
      } else if (getRiskScore >= 100 && getRiskScore < 125) {
        name = "Moderate_Risk";
        color = "#edb409";
      } else if (getRiskScore >= 125 && getRiskScore <= 150) {
        name = "No_Risk";
        color = "#1bbf36";
      }

      return {
        name: name,
        color: color,
      };
    }

    function getCheck(item) {
      let color = "#858784";

      if (item && item.questionScore && item.questionScore !== "-") {
        if (item.qtemplate.toString() === "1") {
          let score = Number(item.questionScore);
          if (score > 24 && score <= 30) {
            color = 1;
          } else if (score > 17 && score <= 24) {
            color = 1;
          } else if (score > 12 && score <= 17) {
            color = 2;
          } else if (score >= 6 && score <= 12) {
            color = 2;
          }
        } else if (
          item.qtemplate.toString() === "6" ||
          item.qtemplate.toString() === "16"
        ) {
          let score = Number(item.questionScore);
          if (score >= 9 && score <= 10) {
            color = 1;
          } else if (score >= 7 && score <= 8) {
            color = 1;
          } else if (score >= 0 && score <= 6) {
            color = 2;
          }
        }
      } else if (
        item.qtemplate.toString() === "16" &&
        item.questionScore === 0
      ) {
        color = 2;
      }

      return color;
    }

    let listFocus = [];
    let listSustain = [];
    if (newQuestionList && newQuestionList.length > 0) {
      newQuestionList.forEach((item) => {
        if (
          item.categoryShow &&
          item &&
          item.riskcategoryValue &&
          getCheck(item) === 2
        ) {
          listFocus.push(item.riskcategoryValue);
        }
        if (
          item.categoryShow &&
          item &&
          item.riskcategoryValue &&
          getCheck(item) === 1
        ) {
          listSustain.push(item.riskcategoryValue);
        }
      });
    }

    return (
      <>
        <div className="w-full"> 
            <GaugeScore grandScore={grandScore} /> 
        </div>

        <div className="w-full lg:p-6">
          <div className="space-y-8">
             
              <h1 className="text-xl font-semibold   ">
                {/* Are you in {''}  */}
                <span  className="text-orange-500 "
                  style={{ color: getRiskLabel(grandScore).color }}
                > 
                 {getRiskLabel(grandScore).name} {''} 
                </span> 
                zone
              </h1>
           
            <div className="space-y-4">
              <p className="text-lg font-medium text-slate-800 capitalize ">
              Focus Area 
              </p>
       
            <div className=" flex gap-2 flex-wrap">
              {listFocus && listFocus.length > 0
                ? listFocus.map((ele) => (
                    <button className="py-1 px-3 bg-blue-100 rounded-full text-base font-medium text-slate-800">
                      {ele}
                    </button>
                  ))
                : null}
            </div>
            </div>


          <div>
          <div className="space-y-4">
            <p className="text-lg font-medium text-slate-800 capitalize ">
                Going Good
              </p>
           
              <div className=" flex gap-2 flex-wrap">
              {listSustain && listSustain.length > 0
                ? listSustain.map((ele) => (
                  <button className="py-1 px-3 bg-blue-100 rounded-full text-base font-medium text-slate-800">
                      {ele}
                    </button>
                  ))
                : null}
            </div>
            </div>
          </div>

          
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(SummaryData);
