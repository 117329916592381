import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
import DoubleBarChart from './Charts/DoubleBarChart';

class WordDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }



  componentDidMount() {
  }


    render(){

        let { 
          
          getDemographicIndexHappinessDataTreeMap,getDemographicIndexEngagementDataTreeMap,
          getDemographicIndexStressDataTreeMap,getDemographicIndexManagerDataTreeMap,
          getDemographicIndexLeaderDataTreeMap,getDemographicIndexMultiFilterDataTreeMap,

          getIndexData,

          getHappinessIndexDriverData,getEngagementIndexDriverData,getStressIndexDriverData,
          getManagerIndexDriverData,getLeaderIndexDriverData,

          FilterList

        } = this.props;

        //////console.log("getIndexData---",getIndexData)


      let DataList = []
      let IndexValidList = getIndexData? Object.keys(getIndexData):[];
      //////console.log("IndexValidList---",IndexValidList)

      if(IndexValidList && IndexValidList.length>0){
        IndexValidList.forEach((item)=>{
          if(getIndexData && getIndexData[item]){

         
          if(parseInt(item)===1){
            DataList.push({
              "indexName":"Happiness",
              "indexNumber":parseInt(item),
              "indexScore":getIndexData && getIndexData[item]?getIndexData[item]:0,
              "indexFilterScore":getDemographicIndexMultiFilterDataTreeMap && getDemographicIndexMultiFilterDataTreeMap[item]?getDemographicIndexMultiFilterDataTreeMap[item]:0,

              "indexDrivers":getHappinessIndexDriverData,
              "indexFilterDrivers":getDemographicIndexHappinessDataTreeMap

            })
          }
          else if(parseInt(item)===2){
            DataList.push({
              "indexName":"Engagement",
              "indexNumber":parseInt(item),
              "indexScore":getIndexData && getIndexData[item]?getIndexData[item]:0,
              "indexFilterScore":getDemographicIndexMultiFilterDataTreeMap && getDemographicIndexMultiFilterDataTreeMap[item]?getDemographicIndexMultiFilterDataTreeMap[item]:0,

              "indexDrivers":getEngagementIndexDriverData,
              "indexFilterDrivers":getDemographicIndexEngagementDataTreeMap

            })
          }
          else if(parseInt(item)===4){
            DataList.push({
              "indexName":"Stress",
              "indexNumber":parseInt(item),
              "indexScore":getIndexData && getIndexData[item]?getIndexData[item]:0,
              "indexFilterScore":getDemographicIndexMultiFilterDataTreeMap && getDemographicIndexMultiFilterDataTreeMap[item]?getDemographicIndexMultiFilterDataTreeMap[item]:0,

              "indexDrivers":getStressIndexDriverData,
              "indexFilterDrivers":getDemographicIndexStressDataTreeMap

            })
          }
          else if(parseInt(item)===5){
            DataList.push({
              "indexName":"Manager",
              "indexNumber":parseInt(item),
              "indexScore":getIndexData && getIndexData[item]?getIndexData[item]:0,
              "indexFilterScore":getDemographicIndexMultiFilterDataTreeMap && getDemographicIndexMultiFilterDataTreeMap[item]?getDemographicIndexMultiFilterDataTreeMap[item]:0,

              "indexDrivers":getManagerIndexDriverData,
              "indexFilterDrivers":getDemographicIndexManagerDataTreeMap

            })
          }
          else if(parseInt(item)===6){
            DataList.push({
              "indexName":"Leader",
              "indexNumber":parseInt(item),
              "indexScore":getIndexData && getIndexData[item]?getIndexData[item]:0,
              "indexFilterScore":getDemographicIndexMultiFilterDataTreeMap && getDemographicIndexMultiFilterDataTreeMap[item]?getDemographicIndexMultiFilterDataTreeMap[item]:0,

              "indexDrivers":getLeaderIndexDriverData,
              "indexFilterDrivers":getDemographicIndexLeaderDataTreeMap

            })
          }

        }

        });
      }


      //////console.log("DataList",DataList)

        return(
        <>
      <div>
        {DataList && DataList.length>0?
        DataList.map((item,index)=>
        <div key={index} className="transition duration-150 bg-white border rounded-lg cursor-pointer hover:shadow-lg p-4 mb-4">
            <h1 className="text-lg text-gray-800 font-medium">
              {FilterList && FilterList.length>0?
              <><b className='text-blue-600'>{FilterList[0]["level"]}</b>{': '+FilterList[0]["value"]} 
              </>:<b className='text-blue-600'>{"Overall"}</b>}</h1>

            <p className='py-2 font-semibold text-md '>{item.indexName + " Dimension Comparison"}</p>
            <DoubleBarChart 
              item={item}
              FilterList={FilterList}
            />
          </div>
        ):null}
        </div>


      
                    
        </>
        );
    }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(WordDetails);
