import React from "react";

export default function AssessmentDetails(props) {
  let {
    getAssessmentReportByEmpIDData,
    crrSelectedEmp,
    EditDetailsFn,
    EditButtonFn,
    hindiPDFstatic
  } = props;

  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }

  let newList = [];
  if (
    getAssessmentReportByEmpIDData &&
    getAssessmentReportByEmpIDData.length > 0
  ) {
    getAssessmentReportByEmpIDData.forEach((element, index) => {
      let getIndex = newList.findIndex(
        (prev) => prev.bucket === element.bucket
      );
      if (getIndex === -1) {
        let temp = {
          bucket: element.bucket,
          colorCode: element.colorCode,
          dimensions: [
            {
              rank: index + 1,
              name: element.dimension,
            },
          ],
        };
        newList.push(temp);
      } else {
        newList[getIndex]["dimensions"].push({
          rank: index + 1,
          name: element.dimension,
        });
      }
    });
  }

  console.log(
    "getAssessmentReportByEmpIDData--->",
    getAssessmentReportByEmpIDData
  );
  console.log("newList--->", newList);

  function getOpacity(rank, item) {
    let total =
      item && item.dimensions && item.dimensions.length > 0
        ? item.dimensions.length
        : 0;
    return (total + 1 - Number(rank)) / (total + 2);
  }

  return (
    <>
      <main ref={hindiPDFstatic[6]} className="bg-white p-6 px-6 space-y-6 page-break bg-no-repeat relative h-full">
        <div className="flex items-center w-full justify-between border-b py-4">
          <h1 className="text-3xl font-medium text-[#3D405B]">
            {EditButtonFn(
              "TalentAssessmentReport",
              "Teen TalentPlusStrengths Assessment Report"
            )}
          </h1>
          <p className="text-base font-medium">
            {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}
          </p>
        </div>

        <div className="">
          <div className="w-full space-y-1 page-break">
            <h1 className="text-3xl font-light text-[#3D405B]">
              {/* <b>{getTitle(element, 1)}</b> */}
              <b>
                {EditButtonFn(
                  "YourTeen TalentPlusStrengthsThemesByDomain",
                  "Your Teen TalentPlusStrengths Themes By Domain"
                )}
              </b>
            </h1>
          </div>
          

          <div className="grid grid-cols-3 gap-4 mt-6">
            {newList && newList.length > 0
              ? newList.map((item) => (
                  <div className="grid-cols-2 gap-1 grid  mb-6">
                    <div
                      style={{
                        background:
                        hexToRgbA(
                          item && item.colorCode
                            ? item.colorCode
                            : "#DDDDDD",'0.12'),
                      }}
                      className={`px-2 py-2 mb-2 font-medium flex justify-center col-span-2 space-x-2 text-[#3D405B] `} 
                    >
                      <p
                        className="w-4 h-4 m-1 text-lg"
                        style={{
                          background:
                            item && item.colorCode ? item.colorCode : "#DDDDDD",
                        }}
                      />

                      <span>{EditDetailsFn(item, "bucket", item.bucket)}</span>
                    </div>
                    {item && item.dimensions && item.dimensions.length > 0
                      ? item.dimensions.map((element, index2) => (
                          <div
                            className="px-2 py-1 h-20 flex flex-col gap-1 items-center justify-center text-center  "
                            style={{
                              background: hexToRgbA(
                                item && item.colorCode
                                  ? item.colorCode
                                  : "#DDDDDD",
                                element.rank <= 5 ? "0.8" : "0.12"
                                // getOpacity(index2+1,item)
                              ),
                              color:
                                element.rank <= 5
                                  ? item.bucket.trim() === "THINKING TALENTS"
                                    ? "#3D405B"
                                    : "#FFFFFF"
                                  : "#3D405B",
                            }}
                          >
                            <p className="text-2xl font-medium">
                              {element.rank}
                            </p>
                            <h1>{EditDetailsFn(
                              element,
                              "dimension",
                              element.name
                            )}</h1>
                          </div>
                        ))
                      : null}
                  </div>
                ))
              : null}
          </div>
        </div>
      </main>
    </>
  );
}
