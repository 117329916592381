import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import { dashboardActions, pmsActions } from '../../../_actions';

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggled: false,
      crrAppraisalCycleId: ""
    }
  }

  componentDidMount() {

  }
  toggleState = (element) => {

    let { isToggled } = this.state;
    if (!isToggled) {
      this.setState({ crrAppraisalCycleId: element.id });
      this.setState(prevState => ({
        isToggled: !prevState.isToggled,
      }));
    } else {
      this.setState({ crrAppraisalCycleId: "" });
      this.setState(prevState => ({
        isToggled: !prevState.isToggled,
      }));
    }

  };

  continueAppraisal = (element) => {
    if (element && element.id) {
      let { handleContinueAppraisal } = this.props;
      let dataX = {
        "appraisalCycleId": element.id
      }
      handleContinueAppraisal(dataX)
    }
  }

  startAppraisal = (elementId) => {
    if (elementId) {
      // let { handleContinueAppraisal } = this.props;
      let dataX = {
        "id": elementId,
        "avoidEmpIdList": []
      }
      this.props.dispatch(pmsActions.PMSLaunchAppraisal(dataX, this.props));
      // handleContinueAppraisal(dataX)
    }
  }


  showPreview = (element) => {
    this.setState({ isToggled: true, crrAppraisalCycleId: element.id })

    let data = { "id": element && element.id }
    this.props.dispatch(dashboardActions.PMSGetAppraisalCycleById(data));
    this.props.dispatch(dashboardActions.PMSGetModuleSetting2(data));
    this.props.dispatch(dashboardActions.PMSGetRaterSetting3(data));


  }
  closePreview = () => {
    this.setState({ isToggled: false, crrAppraisalCycleId: "" })
  }

  seacrhtest = (e, PMSAppraisalCycleList) => {
    this.setState({ newfilter: e.target.value })
    const filtervalue = PMSAppraisalCycleList.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    )
    this.setState({ pmsAppsearch: filtervalue })
  }

  handleSearchEmployee = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ searchEmployee: value }, () => {
      let data = {
        "keyWord": value,
        "pageNo": 1,
        "size": 1000
      }
      this.props.dispatch(dashboardActions.PMSAppraisalCycleList(data));
    })
  }

  render() {
    const { isToggled, pmsAppsearch } = this.state;

    let { openAddAppraisalCycle, dashboard, gotoSection } = this.props;
    let { PMSAppraisalCycleList, PMSGetAppraisalCycleById, PMSGetModuleSetting2, PMSGetRaterSetting3 } = dashboard;

    console.log("PMSGetAppraisalCycleById,PMSGetModuleSetting2 ,PMSGetRaterSetting3 ---->", PMSAppraisalCycleList)


    return (
      <div className="w-full p-4 bg-white rounded-lg md:p-6 border space-y-6"> 

        <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
          <h3 class="text-xl font-medium  text-[#3D405B] pb-4"> Appraisal Cycle List</h3>

          {/* <div className="flex items-center justify-between w-full overflow-hidden text-sm bg-white border rounded-md md:w-auto ">
            <span value={this.state.newfilter} className="p-2 rounded-l-full material-symbols-outlined text-slate-600" style={{ fontSize: '1.4rem' }}>search</span><input type="search" name="search" className="w-full py-2 md:w-48 focus:outline-none placeholder:text-slate-600" placeholder="Search" onChange={this.handleSearchEmployee} />
          </div> */}

          <button onClick={() => openAddAppraisalCycle("add_appraisal_cycle")} className="p-1 bg-gradient-to-l from-[#62cff4]  to-[#2c67f2]  text-white  rounded-md  flex items-center justify-center px-4 py-2 font-medium space-x-2 md:w-auto w-full ">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
              <path d="M9 12l6 0"></path>
              <path d="M12 9l0 6"></path>
            </svg> <span>Add New</span></button>
        </div>




        {PMSAppraisalCycleList && PMSAppraisalCycleList.length > 0 ? (
          <div className="block w-full overflow-x-auto">
            <table class="items-center bg-transparent w-full border">
              <thead className="border">
                <tr className='bg-[#f9fafb]'>
                  <th class="px-6  py-3 text-sm whitespace-nowrap text-[#3D405B] font-semibold  text-left">Appraisal Cycle Name</th>
                  <th class="px-6  py-3 text-sm whitespace-nowrap text-[#3D405B] font-semibold  text-center">Manage Employee</th>
                  <th class="px-6  py-3 text-sm whitespace-nowrap text-[#3D405B] font-semibold  text-center">Created On</th>
                  <th class="px-6  py-3 text-sm whitespace-nowrap text-[#3D405B] font-semibold  text-left">Status</th>
                  <th class="px-6  py-3 text-sm whitespace-nowrap text-[#3D405B] font-semibold  text-center">Action</th>
                </tr>
              </thead>

              <tbody className="border">

                {PMSAppraisalCycleList && PMSAppraisalCycleList.length > 0 ?
                  PMSAppraisalCycleList.map((element) =>
                    <tr className="border">
                      <td className="px-6 text-sm whitespace-nowrap p-3 text-left text-[#3D405B] font-medium capitalize ">{element.name}</td>
                      <td className="p-3 px-6 text-sm align-middle whitespace-nowrap ">
                        <div className='flex justify-center'>
                          <span onClick={() => this.continueAppraisal(element)} className='cursor-pointer  px-6 bg-[#f9fafb] py-1 border rounded-md flex space-x-1 font-medium items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                              <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                              <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                            </svg>
                            <span>{element && element.count ? element.count : 0}</span></span>
                        </div>
                      </td>
                      <td className="p-3 px-6 text-sm font-medium text-center align-center whitespace-nowrap">{moment(new Date(parseInt(element.createdAt)))
                        .utcOffset("+05:30")
                        .format("DD-MMM-YYYY")}</td>
                      <td className="p-3 px-6 text-sm align-middle whitespace-nowrap">
                        {element.isActive ?
                          <button onClick={() => this.continueAppraisal(element)} className='bg-[#FFA000] text-white w-32 mx-auto px-4 py-2 rounded-md uppercase text-sm font-medium'>In Progress</button>
                          :
                          <button onClick={() => this.showPreview(element)} className='border text-[3D405B] w-32 px-8 py-2 rounded-md uppercase text-sm font-medium'>Start</button>
                        }
                      </td>
                      <td className='text-center '>
                        <div className='flex justify-center space-x-2'>
                          {/* <button onClick={() => gotoSection("dashboard", element)} className='bg-[#F9FAFB] rounded-md p-2 cursor-pointer text-[#3D405B] hover:text-[#2196F3] hover:bg-[#2196F3]/10 transition-all duration-100'>
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                            <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                            <path d="M16 5l3 3"></path>
                          </svg>
                        </button> */}
                          <button onClick={() => gotoSection("dashboard", element)} className='bg-[#F9FAFB] rounded-md p-2 cursor-pointer text-[#3D405B] hover:text-[#2196F3] hover:bg-[#2196F3]/10 transition-all duration-100'>
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-layout-dashboard" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M4 4h6v8h-6z"></path>
                              <path d="M4 16h6v4h-6z"></path>
                              <path d="M14 12h6v8h-6z"></path>
                              <path d="M14 4h6v4h-6z"></path>
                            </svg>
                          </button>
                        </div>
                      </td>
                    </tr>

                  ) : null}

              </tbody>
            </table>
          </div>
        ) : (
          <div className="">
            <div className="flex flex-col items-center justify-center p-6 py-10 space-y-10 text-center bg-white border rounded-md">
              <img src="img/overview/empty_survey.png" />
              <div className="space-y-2">
                <h1 className="text-2xl font-medium text-[#3D405B]">No Appraisal Added Yet!</h1>
                <p className="text-[#3D405B]/70">Looks like no appraisal has been initiatedyet.</p>
              </div>
            </div>
          </div>
        )}







        {isToggled ? <div className="fixed top-0 left-0 z-20 flex items-center justify-center w-full h-screen bg-black/60">

          <div className="mx-auto 2xl:w-6/12 xl:w-7/12 md:w-8/12 bg-white rounded-lg text-[#3D405B] text-base">
            <div className="flex items-center justify-between p-4 px-6 border-b">
              <h2 className="text-lg font-semibold text-center text-gray-800 ">Appraisal Cycle Preview </h2>
              <span onClick={this.closePreview} className="material-symbols-outlined bg-gray-100 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150">close</span>
            </div>
            <div className='p-6 w-full space-y-4 h-[34rem] overflow-x-auto'>

              <div className="flex flex-row items-center gap-6">
                <div className="  font-semibold  text-right w-[50%]">Appraisal Cycle Details</div>
                <div className="w-full ">
                  <p>   </p>
                </div>
              </div>

              <div className="flex flex-row items-center gap-6">
                <div className="   text-right w-[50%]">Name of Appraisal</div>
                <div className="w-full ">
                  <p className='font-medium '>{PMSGetAppraisalCycleById && PMSGetAppraisalCycleById.name}</p>
                </div>
              </div>

              <div className="flex flex-row items-center gap-6">
                <div className="   text-right w-[50%]">Appraisal Cycle Period:</div>
                <div className="w-full ">
                  <p className='font-medium '>{PMSGetAppraisalCycleById && PMSGetAppraisalCycleById.period}</p>
                </div>
              </div>

              <div className="flex flex-row items-center gap-6">
                <div className="   text-right w-[50%]">Appraisal Cycle Description:</div>
                <div className="w-full ">
                  <p className='font-medium '>{PMSGetAppraisalCycleById && PMSGetAppraisalCycleById.desc}</p>
                </div>
              </div>


              <div className="flex flex-row items-start gap-6">
                <div className="   text-right w-[50%] font-semibold ">Module(s) Setting</div>

                <div className={"grid gap-2 grid-cols-" + (PMSGetModuleSetting2 && PMSGetModuleSetting2.length > 0 ? PMSGetModuleSetting2.length.toString() : "1")}>

                  {PMSGetModuleSetting2 && PMSGetModuleSetting2.length > 0 ?
                    PMSGetModuleSetting2.map((item) =>
                      (item.moduleActive) ?
                        <div className="w-full space-y-2 ">
                          <table className='w-full border border-collapse'>
                            <tr className='border-b '>
                              <th className='p-2 font-medium text-left'>{item && item.moduleName}</th>
                              <th className='p-2 font-medium text-right'>
                                <div className='flex justify-end'>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down text-[#2196F3] cursor-pointer" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M6 9l6 6l6 -6"></path>
                                  </svg>
                                </div>
                              </th>
                            </tr>
                            <tbody className=''>
                              <tr className='border-b bg-[#F9FAFB]'>
                                <th className='p-2 font-medium text-left border-r'>Rating</th>
                                <th className='p-2 font-medium text-left'>Score Range</th>
                              </tr>
                              {item && item.scoreLabels.length > 0 ?
                                item.scoreLabels.map((element) =>
                                  <>
                                    <tr className='border-b'>
                                      <td className='p-2 text-left border-r '>{element && element.label}</td>
                                      <td className='p-2 text-left '>{element && element.a}-{element && element.b}</td>
                                    </tr>
                                  </>
                                ) : null}
                            </tbody>
                          </table>


                        </div>
                        : null
                    ) : null
                  }
                </div>
              </div>


              <div className="flex flex-row items-center gap-6">
                <div className="   text-right w-[50%] font-semibold ">Rater(s) Setting</div>
                <div className="w-full ">
                  <p> </p>
                </div>
              </div>
              {PMSGetRaterSetting3 && PMSGetRaterSetting3.length > 0 ?
                PMSGetRaterSetting3.map((dmg) =>
                  <>
                    <div className="flex flex-row items-center gap-6">
                      <div className="   text-right w-[50%]">{dmg && dmg.raterName}</div>
                      <div className="flex w-full">

                        <p className='font-medium '>
                          {dmg && dmg.moduleAccessList && dmg.moduleAccessList.length > 0 ?
                            dmg.moduleAccessList.map((inner) =>
                              (inner)
                            ).join(", ") : ""}
                        </p>

                      </div>
                    </div>
                  </>) : null}



              <div className="flex flex-row items-center gap-6">
                <div className="   text-right w-[50%] font-semibold ">Applicable For</div>
                <div className="w-full ">
                  <p> </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-6">
                <div className="text-right w-[50%]">Date of Joining:</div>
                <div className="w-full ">
                  <p className='font-medium '>{PMSGetAppraisalCycleById && PMSGetAppraisalCycleById.doj}</p>
                </div>
              </div>
              {PMSGetAppraisalCycleById && PMSGetAppraisalCycleById["dmg"] && PMSGetAppraisalCycleById["dmg"].length > 0 ?
                PMSGetAppraisalCycleById["dmg"].map((dmg) =>
                  <>
                    <div className="flex flex-row items-center gap-6">
                      <div className="text-right w-[50%]">{dmg && dmg.level}</div>
                      <div className="flex w-full">

                        <p className='font-medium '>
                          {dmg && dmg.valueList && dmg.valueList.length > 0 ?
                            dmg.valueList.map((inner) =>
                              (inner)
                            ).join(", ") : ""}
                        </p>

                      </div>
                    </div>
                  </>) : null}
              {/* <div className="flex flex-row items-center gap-6">
                <div className="   text-right w-[50%]">Appraisal Location:</div>
                <div className="w-full ">
                  <p className='font-medium '>All</p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-6">
                <div className="   text-right w-[50%]">Role:</div>
                <div className="w-full ">
                  <p className='font-medium '>Manager</p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-6">
                <div className="   text-right w-[50%]">Department:</div>
                <div className="w-full ">
                  <p className='font-medium '>HR Department</p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-6">
                <div className="   text-right w-[50%]">Designation:</div>
                <div className="w-full ">
                  <p className='font-medium '>Manager</p>
                </div>
              </div> */}




            </div>

            <div className="flex items-center justify-end w-full p-6 border-t">
              <button onClick={() => {
                this.startAppraisal(this.state.crrAppraisalCycleId)
                this.closePreview()
              }} className="bg-[#2196F3] text-white py-2.5 px-12 rounded-md uppercase">Initiate the process</button>
            </div>

          </div>

        </div> : null}

      </div>

    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
