import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurveyName: '',
      currentSurveyId: '',

      showDropdown: false,

      showSidebar: false,


      showSubMenu: false
    }
  }
  componentDidMount() {
  }

  onDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false })
    }
    else {
      this.setState({ showDropdown: true })
    }
  }

  logout = () => {
    this.props.dispatch(userActions.logout());
  }



  onSubMenu = (check) => {
    if (this.state.showSidebar) {
      this.setState({ showSubMenu: check });
    }
  }


  sidebarToggle = () => {
    if (this.state.showSubMenu) {
      this.setState({ showSubMenu: false })
    }
    this.setState({ showSidebar: !this.state.showSidebar })
  }


  render() {
    let { OverviewList, handleSidebarExtra, handleSidebarClick, IndexNo, showOverview, showTextAnalysis, showMobileSidebar, showAdvanceInsight } = this.props;
    let { showSidebar } = this.state;

    // //console.log('OverviewList: ',OverviewList);

    return (
      <>
        <div>
          {/* <div className={showSidebar ?
            "fixed lg:block hidden shadow-xl w-64 left-0 top-16 h-screen h-full z-30 transition duration-300 transform bg-white border-r h-increse" :
            "fixed lg:block hidden w-16 left-0 top-16 h-screen h-full z-30 transition duration-300 transform bg-white border-r h-increse"
          } style={{ height: 'calc(100% - 4rem)' }} id="customscroll2">
            <div className="flex items-center ">
              <div className="flex items-center justify-center w-16 px-4 py-3 text-blue-500">
                <span onClick={() => this.sidebarToggle()} className="p-1 text-gray-500 rounded cursor-pointer material-symbols-outlined hover:bg-gray-100">
                  {showSidebar ?
                    "chevron_left" :
                    "chevron_right"
                  }
                </span>
              </div>
              {showSidebar ?
                <span className="ml-4 text-sm font-normal text-gray-500 cursor-default">Insight</span>
                : null}
            </div> */}

          <div
            className={
              showSidebar
                ? "cursor-pointer transition-width w-64 lg:block scollar-xs  lg:relative fixed top-0 left-0 h-screen lg:z-20 z-20 overflow-y-auto overflow-hidden  duration-200 transform bg-white border-r " +
                (showMobileSidebar ? "" : "hidden")
                : "cursor-pointer transition-width  w-16 lg:block scollar-xs lg:relative fixed top-0 left-0 h-screen lg:z-20 z-20 overflow-y-auto overflow-hidden  duration-200 transform bg-white border-r " +
                (showMobileSidebar ? "" : "hidden")
            } style={{ height: "calc(100% - 0rem)" }}>


            {!showMobileSidebar ? (
              <div className="sticky top-0 flex items-center bg-white ">
                <div className="flex items-center justify-center w-16 px-4 py-3 text-gray-500">
                  <span
                    onClick={() => this.sidebarToggle()}
                    className="p-1 bg-gray-100 rounded material-symbols-outlined"
                  >
                    {showSidebar ? "chevron_left" : "chevron_right"}
                  </span>
                </div>
                <span className="text-sm text-gray-500 ">Insight</span>
              </div>
            ) : (
              <div className="py-1" />
            )}


            <div className="mb-4 overflow-hidden overflow-y-auto font-medium" style={{ height: 'calc(100% - 4rem)' }}>
              {/* 1 */}
              <div title={"Overview"} onClick={() => handleSidebarClick(false)}
                className={showOverview ? "flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer border-l-4 border-[#2196F3] bg-[#2196F3]/10" :
                  "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer"}>
                <div className="flex items-center justify-center w-16 h-12 px-4 py-4">
                  {/* <span className="p-2 material-symbols-outlined">dashboard</span> */}
                  <span className={showOverview ? "flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center material-symbols-outlined" :
                    "flex items-center px-4 py-4 w-16 h-12 justify-center material-symbols-outlined"}>dashboard</span>
                </div>
                {showSidebar ? <span class={showOverview ? "text-sm text-[#2196F3]" : "text-sm"}>Overview</span> : null}
              </div>


              {OverviewList && OverviewList.length > 0 ?
                OverviewList.map((item, index) =>
                  // (item && item._id)?

                  <div title={item.name + " Insight"} keys={index} onClick={() => handleSidebarClick(true, item, index + 1, item.mean !== "NaN")}
                    className={(index + 1) === IndexNo ? "flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer border-l-4 border-[#2196F3] bg-[#2196F3]/10" :
                      "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer"}>
                    <div className="flex items-center justify-center w-16 h-12 px-4 py-4">
                      <span className="p-2 material-symbols-outlined">{
                        ((index + 1) === IndexNo) ? "sentiment_satisfied_alt" : "sentiment_satisfied_alt"
                      }</span>
                    </div>
                    {showSidebar ? <span class=" text-sm text-[#3D405B]/70 font-medium ">{item.name + " Insight"}</span> : null}
                  </div>


                  // :null 
                ) : null}




              <div title={"Text Analysis"} onClick={() => handleSidebarExtra(1)}
                className={showTextAnalysis ? "flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer border-l-4 border-[#2196F3] bg-[#2196F3]/10" :
                  "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer"}>

                <div className="flex items-center justify-center w-16 h-12 px-4 py-4">
                  <span className={showTextAnalysis ? "flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center material-symbols-outlined" :
                    "flex items-center px-4 py-4 w-16 h-12 justify-center material-symbols-outlined"}>manage_search</span>
                </div>
                <span class={showTextAnalysis ? "text-sm text-[#2196F3]" : "text-sm"}>Text Analysis</span>
              </div>

            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(Sidebar);
