import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import { spliceDecimal } from "../../../../../../FunctionLibrary/FuntionList";
import { graphic } from 'echarts';

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  openChartList = () => {
    if (this.state.showChartList) {
      this.setState({ showChartList: false })
    }
    else {
      this.setState({ showChartList: true })
    }
  }

  handleChartType = (item) => {
    this.setState({ chartTypeName: item })
  }

  render() {
    let { CountRestrict, getDemographicIndexData, tempSortDistribution, currFilterName, getIndexScore, getIndexData, indexType } = this.props;
    //console.log('getIndexData: ',getIndexData)
    let nameList = []
    let weightage1 = [];
    let weightage2 = [];
    let weightage3 = [];
    let weightage4 = [];
    let weightage5 = [];

    if (getIndexData) {
      nameList.push({
        value: "Overall",
        textStyle: { fontWeight: 'bold' }
      })
      // weightage1.push({
      //   value:(getIndexData["HighlyEngaged"]).toFixed(2),
      //   itemStyle: {color: '#ff4081'}
      // });
      weightage1.push({
        value: spliceDecimal(getIndexData["HighlyEngaged"], 2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#b3ffd9'
            },
            {
              offset: 1,
              color: '#00cc66'
            }])
        }
      });
      // weightage2.push({
      //   value:(getIndexData["Engagement"],2),
      //   itemStyle: {color: '#00cd7e'}
      // });
      weightage3.push({
        value: spliceDecimal(getIndexData["ModeratelyEngaged"], 2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#d2f2c0'
            },
            {
              offset: 1,
              color: '#a2e37d'
            }])
        }
      });
      weightage4.push({
        value: spliceDecimal(getIndexData["ActivelyDisengaged"], 2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#f1c0c0'
            },
            {
              offset: 1,
              color: '#d12e2e'
            }])
        }
      });
      weightage5.push({
        value: spliceDecimal(getIndexData["Passive"], 2),
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#fbe2b6'
            },
            {
              offset: 1,
              color: '#f4ae34'
            }])
        }
      });
    }

    let tempSortData = tempSortDistribution;
    if (tempSortData && tempSortData.length > 0) {

      function showNaN(item, name) {
        let dataScore = CountRestrict(item.count) ? "NA" : spliceDecimal(item[name], 2);
        return dataScore
      }

      tempSortData.forEach((item) => {
        nameList.push({
          value: item.name,
          textStyle: { fontWeight: 'bold' }
        })
        weightage1.push({
          value: showNaN(item, "HighlyEngaged"),
          itemStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#b3ffd9'
              },
              {
                offset: 1,
                color: '#00cc66'
              }])
          }
        });
        // weightage2.push({
        //   value:(item["Engagement"]).toFixed(2),
        //   itemStyle: {color: '#00cd7e'}
        // });
        weightage3.push({
          // value:(item["ModeratelyEngaged"]).toFixed(2),
          value: showNaN(item, "ModeratelyEngaged"),
          itemStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#d2f2c0'
              },
              {
                offset: 1,
                color: '#a2e37d'
              }])
          }
        });
        weightage4.push({
          // value:(item["ActivelyDisengaged"]).toFixed(2),
          value: showNaN(item, "ActivelyDisengaged"),
          itemStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#f1c0c0'
              },
              {
                offset: 1,
                color: '#d12e2e'
              }])
          }
        });
        weightage5.push({
          // value:(item["Passive"]).toFixed(2),
          value: showNaN(item, "Passive"),
          itemStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#fbe2b6'
              },
              {
                offset: 1,
                color: '#f4ae34'
              }])
          }
        });
      })
    }



    return (
   
        <div className="stack-body">
          {true ?
            <>
              <ReactEcharts
                style={{
                  height: (nameList.length < 4) ? '200px' : (nameList.length < 5) ? '300px' : (nameList.length > 20) ? (nameList.length * 60).toString() + 'px' : (nameList.length * 50).toString() + 'px',
                  marginTop: ''
                }}
                option={{
                  tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                      type: 'shadow'
                    },
                    // formatter: function(a,b,c,d){
                    //   return (a && a[0] && a[0].name)?a[0].name+' = '+a[0].value+'%':''
                    // } 


                  },
                  legend: {
                    data: []
                  },
                  grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                  },
                  xAxis: {
                    type: 'value',
                    splitLine: {
                      lineStyle: {
                        color: '#f2f2f2'
                      }
                    },
                    axisLine: {
                      lineStyle: {
                        color: '#e6e6e6'
                      }
                    },
                    max: 100
                  },
                  yAxis: {
                    type: 'category',
                    data: nameList.reverse(),
                    axisLine: {
                      lineStyle: {
                        color: '#e6e6e6'
                      }
                    }
                  },
                  axisLabel: {
                    color: "#000",
                    interval: 0,
                    formatter: function (value) {
                      if (value.length > 15) {
                        return value.substring(0, 15) + "..";
                      } else {
                        return value;
                      }
                    }
                  },
                  series: [
                    {
                      name: 'Happiest',
                      type: 'bar',
                      stack: '总量',
                      barWidth: '20px',
                      label: {
                        show: true,
                        position: 'insideRight',
                        color: "#000",
                        formatter: function (d) {
                          if (parseInt(d.value) < 7) {
                            return '';
                          }
                          else {
                            return d.value;
                          }
                        }
                      },
                      data: weightage1.reverse(),
                      inverse: true
                    },
                    {
                      name: 'Happier',
                      type: 'bar',
                      stack: '总量',
                      barWidth: '20px',
                      label: {
                        show: true,
                        position: 'insideRight',
                        color: "#000",
                        formatter: function (d) {
                          if (parseInt(d.value) < 7) {
                            return '';
                          }
                          else {
                            return d.value;
                          }
                        }
                      },
                      data: weightage2.reverse(),
                      inverse: true
                    },
                    {
                      name: 'Moderate',
                      type: 'bar',
                      stack: '总量',
                      barWidth: '20px',
                      label: {
                        show: true,
                        position: 'insideRight',
                        color: "#000",
                        formatter: function (d) {
                          if (parseInt(d.value) < 7) {
                            return '';
                          }
                          else {
                            return d.value;
                          }
                        }
                      },
                      data: weightage3.reverse(),
                      inverse: true
                    },
                    {
                      name: 'Unhappy',
                      type: 'bar',
                      stack: '总量',
                      barWidth: '20px',
                      label: {
                        show: true,
                        position: 'insideRight',
                        color: "#000",
                        formatter: function (d) {
                          if (parseInt(d.value) < 7) {
                            return '';
                          }
                          else {
                            return d.value;
                          }
                        }
                      },
                      data: weightage4.reverse(),
                      inverse: true
                    }

                  ],
                  grid: {
                    left: '5%',
                    right: '1%',
                    bottom: '12%',
                    top: '3%',
                    // containLabel: true
                  },
                }}
              />
            </>
            : null}
        </div>

 
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(StackChart);
