import React from 'react';
import ParameterView from '../Parameter/ParameterView';
import { TbPlus } from 'react-icons/tb';
import { TbTrash } from 'react-icons/tb';
export default function MultipleChoice(props) {
  let { SurveyTemplateId, question, quesindex, currQuesEditId, translShortName, showTranslatePage,
    translQuestion, handleTranslateQuestion, handleTranslateOption, onTranslateQuestionSave,

    handleQuestionInput, handleOptionInput, handleWeightageInput, onDeleteOption, onAddNewOption, onQuesMandatory, onSaveQues,
    innerEdit, handleQuesCancel, GetParameterLabel, dashType1Check
  } = props;

  let [showBotResEdit, handleBotRespEdit] = React.useState(false);


  return (
    <>
      {currQuesEditId === question.id ?
        <>
          {showTranslatePage ?
            <>

              <div className="flex justify-between items-center">
                <div className="text-sm font-medium text-[#212121]">Question</div>
                <div className="flex px-2">
                  <div onClick={() => onTranslateQuestionSave(true, translShortName)} className="py-1 px-3 rounded-sm border border-1 border-green-500 cursor-pointer text-sm text-green-500 mr-2">Save</div>
                  <div onClick={() => onTranslateQuestionSave(false, translShortName)} className="py-1 px-2 rounded-sm border border-1 border-red-500 cursor-pointer text-sm text-red-500">Cancel</div>
                </div>
              </div>
              <input
                type="text"
                value={question.name}
                onChange={(event) => handleQuestionInput(event.target.value, question.id)}
                placeholder={"Q." + (quesindex + 1) + " " + question.name}
                className="py-2 px-4 border outline-none w-full mt-2 rounded-md"
              />
              <input
                type="text"
                value={translQuestion["transl"][translShortName].name}
                onChange={(event) => handleTranslateQuestion(event.target.value, question.id, translShortName)}
                placeholder="Translate here..."
                className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2"
              />

              <ul className="pb-4">

                <div className="flex ">
                  <div className="text-sm font-semibold w-8/12 mr-16 text-[#212121]">Options</div>
                </div>
                {question && question.optionsList && question.optionsList.length ?
                  question.optionsList.map((option, optionindex) =>
                    <div className="flex items-center justify-between py-2">
                      <div className=" w-full">
                        <input
                          type="text"
                          value={option.name}
                          onChange={(event) => handleOptionInput(event.target.value, question.id, option.id)}
                          placeholder={option.name}
                          className="border border-gray-500 outline-none  px-4 text-sm py-1.5 w-9/12 mr-4"
                        />
                      </div>
                      <div className=" w-full">
                        <input
                          type="text"
                          value={translQuestion && translQuestion["transl"] && translQuestion["transl"][translShortName] && translQuestion["transl"][translShortName].optionsList && translQuestion["transl"][translShortName].optionsList[option.id] ? translQuestion["transl"][translShortName].optionsList[option.id] : ""}
                          onChange={(event) => handleTranslateOption(event.target.value, question.id, option.id, translShortName)}
                          placeholder="Translate here..."
                          className="border border-purple-500 outline-none  px-4 text-sm py-1.5 w-9/12 mr-4"
                        />
                      </div>
                    </div>
                  )
                  : null}
              </ul>

            </>
            :
            <>

              <div className="flex justify-between items-center">
                <div className="text-sm font-medium text-[#212121]">Question</div>
                <div onClick={() => handleQuesCancel(false)} className="py-1 px-2 rounded-sm border border-1 border-red-500 cursor-pointer text-sm text-red-500 mr-2">Cancel</div>
              </div>
              <input
                type="text"
                value={question.name}
                onChange={(event) => handleQuestionInput(event.target.value, question.id)}
                placeholder={"Q." + (quesindex + 1) + " " + question.name}
                className="py-2 px-4 border outline-none w-full mt-2 rounded-md"
              />

              <ul className="pb-4">
                {/* <p className="text-xs text-gray-500 pb-2">Answer</p> */}
                <div className="mb-1.5 mt-2" >

                  <div className='flex justify-between px-2'>

                    <div className="py-1" >
                      <input
                        id="mandatory-check"
                        value={question.isMandatory}
                        checked={question.isMandatory}
                        type="checkbox"
                        onChange={() => { }}
                        className="cursor-pointer ml-1"
                        onClick={() => onQuesMandatory(question.isMandatory, question.id)}
                      />
                      <label htmlFor="mandatory-check" className="text-sm text-gray-500 pb-8 pl-2">Mandatory</label>



                    </div>

                    <div className="py-1 flex space-x-2" >
                      <div onClick={() => handleQuestionInput(" [variable] ", question.id, true)}
                        className='cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md '>
                        Add Variable</div>
                      <div onClick={() => handleBotRespEdit(!showBotResEdit)} className={showBotResEdit ?
                        'cursor-pointer text-sm py-2 px-4 font-medium bg-yellow-500 text-white rounded-md ' :
                        'cursor-pointer text-sm py-2 px-4 font-medium bg-purple-500 text-white rounded-md '
                      }>Bot Edit</div>
                    </div>

                  </div>


                  {/* <div className="ml-6 mt-1 cursor-pointer text-sm text-purple-500 uppercase font-semibold" onClick={()=>onParamaterModal(!showParameterModal)} ><i className={showParameterModal?"fa fa-times":"pr-2 fa fa-plus"} />{showParameterModal?"":"Add Paramaters"}</div> */}



                  {/*                         
                        <Parameter
                        showBucketDropdown={showBucketDropdown} 
                        parameterList={parameterList}
                        handleParameterBucket={handleParameterBucket}
                        handleParameterQuestion={handleParameterQuestion}
                        getParameterListbyParentData={getParameterListbyParentData}
                        paraBucketName={paraBucketName}
                        question={question}
                        showDimensionDropdown={showDimensionDropdown}
                        paraDimensionName={paraDimensionName}
                        handleParameterDimension={handleParameterDimension}
                        GetParameterLabel={GetParameterLabel}
                        SurveyTemplateId={SurveyTemplateId}
                        /> */}


                </div>



                <div className="flex ">
                  <div className="text-sm font-medium w-8/12 mr-16 text-[#212121]">Options</div>
                  <div className="text-sm font-medium w-1/12 text-[#212121]">Feedback</div>
                  {dashType1Check ?
                    <div className="text-sm font-semibold w-2/12 text-gray-400">Weightage</div>
                    : null}
                </div>
                {question && question.optionsList && question.optionsList.length ?
                  question.optionsList.map((option, optionindex) =>
                    <div className="flex items-center justify-between py-2">
                      {/* <input type="radio" name="ans" className="w-4 h-4 " /> */}
                      <div className=" w-full">
                        <input
                          type="text"
                          value={option.name}
                          onChange={(event) => handleOptionInput(event.target.value, question.id, option.id)}
                          placeholder={option.name}
                          className="border outline-none  px-4 text-sm py-1.5 w-9/12 mr-4"
                        />

                        <input
                          type="checkbox"
                          checked={option && option.isFeedback ? true : false}
                          onChange={(event) => handleOptionInput(!(option && option.isFeedback ? true : false), question.id, option.id, "feedback")}
                          className="mx-2 mr-4"
                        />

                        {dashType1Check ?
                          <input
                            type="number"
                            value={option.weightage}
                            onChange={(event) => handleWeightageInput(event, question.id, option.id)}
                            className="border outline-none text-center px-2 text-sm py-1.5 w-2/12 "
                          />
                          : null}

                      </div>
                      <div className="-ml-6">
                        <button onClick={() => onDeleteOption(question.id, option.id)} className="cursor-pointer border rounded-full w-8 h-8 inline-flex items-center justify-center  text-[#212121]  hover:bg-red-100 hover:text-red-500">
                          <TbTrash />
                        </button>
                      </div>
                    </div>
                  )
                  : null}
              </ul>

              <div className="flex justify-center pb-2 pt-8">
                <span className="cursor-pointer text-sm rounded uppercase   bg-[#2196f3] py-2 px-4 text-white w-28  mx-1 text-center" onClick={() => onSaveQues(question)}> save</span>
              </div>

              <div className="flex justify-center">
                <span onClick={() => onAddNewOption(question)} className="cursor-pointer text-sm text-[#2196f3] uppercase font-medium flex items-center"><TbPlus /> Add more options</span>
              </div>
            </>}

        </>
        :
        <>
          <div title={(question && question.isNonEdit ? "" : "Double Click to Edit!")} onDoubleClick={() => innerEdit(question, SurveyTemplateId)} className="space-y-4">


            <div className="text-slate-800 font-semibold text-sm inline-flex">{question.parentId ? <p className="pr-2">{" - "}</p> : <p className="pr-2">Q{quesindex + 1}.</p>}  <h1>{question.name}{(question.isMandatory) ? '*' : null}</h1> </div>

            <ul className=" pl-6">
              {question && question.optionsList && question.optionsList.length ?
                question.optionsList.map((option, optionindex) =>
                  <>
                    <div key={optionindex} className="inline-flex items-center py-1.5"><input type="checkbox" name="ans" className=" " /><label className="text-sm pl-4 text-gray-600 font-medium">{option.name}</label></div> <br />
                  </>
                )
                : null}
            </ul>
          </div>
        </>
      }
    </>
  );
}