import React, { Component } from 'react';
import { connect } from 'react-redux';
import BoxPlot from './Charts/BoxPlot';
import Commentary from '../../../Components/Commentary/Commentary';
import Filter from '../../../../../CommonComponents/Filters/IndexFilter/Filter';
import QuestionFilter from './Filter/QuestionFilter';

class IndexVSNPS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue:'none',
      currFilterName:'none',

      currentSelectValue:'',
      FilterValues:[],
      showFilter:false,
      showValue:'',
      npsType:1,

      showViewBy:'',
      FilterList:[],
      currentQuesId:''

    }
  }
  componentDidMount() {

  }

  handleChangeQuesIdSave=(Id)=>{
    this.setState({currentQuesId:Id})
  }

  callQuestionWordCloudQuesChange=(initQuesId)=>{
    let { handleIndexVSNpsType,isHappinessLadder } = this.props;
    let { FilterList, npsType} = this.state;  
    handleIndexVSNpsType(npsType,initQuesId,FilterList,isHappinessLadder);

  }
  closeFilterQues=(check1)=>{
    this.setState({showViewBy:check1});
  }

  closeFilter=(check1,check2)=>{
    this.setState({showFilter:check1, showValue:check2});
  }

  handleMultifilterIndexScore2=(finalFilter)=>{
    let {handleIndexVSNpsType, isHappinessLadder, getNameQuestionData}= this.props;

    let { currentQuesId, FilterValues, npsType } = this.state;
    let initQuesId = '';
    if(currentQuesId){
      initQuesId = currentQuesId;
    }
    else{
      initQuesId = getNameQuestionData && getNameQuestionData.length>0?getNameQuestionData[0].id:'';
    }


    this.setState({FilterValues:finalFilter});
      handleIndexVSNpsType(npsType, initQuesId, FilterValues,isHappinessLadder)

  }


  changeIndexVSNpsType=(value)=>{
    this.setState({npsType:value},()=>{
      let { handleIndexVSNpsType, getNameQuestionData, isHappinessLadder } = this.props;
      let { currentQuesId, FilterValues } = this.state;
      let initQuesId = '';
      if(currentQuesId){
        initQuesId = currentQuesId;
      }
      else{
        initQuesId = getNameQuestionData && getNameQuestionData.length>0?getNameQuestionData[0].id:'';
      }
      console.log("value, initQuesId, FilterValues3--->",value, initQuesId, FilterValues)
      handleIndexVSNpsType(value, initQuesId, FilterValues,isHappinessLadder)
    });

  }

  render() {
    let { isHappinessLadder, getIndexVsNpsData, ErrorIndexObj, getIndexQuantileError, selectedFilterValue, loading,CountRestrict,getIndexQuantileCount,getEMPFilterLabel,
      indexType, optionType,getCommentData, getNameQuestionData,

      getQuestionData, getQuestionFilterData,handleQuantileFilter, EmpFilterData,getIndexFilterData, getIndexQuantileData, getIndexByFilterDMGData
    
    } = this.props;

    let { currentSelectValue, FilterValues, npsType }  = this.state;


    let EmpFilterData2 = []
    if(EmpFilterData && EmpFilterData.length>0){
     EmpFilterData.forEach((emp)=>{
       if(emp.name !=='ManagerPID'){
         EmpFilterData2.push(emp);
       }
     })
    }
 
    let isDisable  = true;
   //  let DemographicDataScore = [];
   //  let DemographicDataDistribution = [];
 
    if(FilterValues && FilterValues.length>0){
 
      if(isDisable){
         FilterValues.forEach((item)=>{
             if(item.value==='All'){
               isDisable = false
             }
         });
      }
 
 
     //  DemographicDataScore=getDemographicIndexMultiFilterData;
     //  DemographicDataDistribution=getIndexDistributionData;
 
    }

    let {GetParameterLabel, XAxis, name}= this.props;




    let IndexQuantile = CountRestrict(getIndexQuantileCount)?"NaN":getIndexQuantileData;




  //   function sliceIntoChunks(arr, chunkSize) {
  //     const res = [];
  //     for (let i = 0; i < arr.length; i += chunkSize) {
  //         const chunk = arr.slice(i, i + chunkSize);
  //         res.push(chunk);
  //     }
  //     return res;
  // }
  
  // const arr = [1, 2, 3, 4, 5, 6];
  // console.log('sliceIntoChunks----->',sliceIntoChunks(arr, 2))

  let scoreUniqueList = []
  if(getIndexVsNpsData && getIndexVsNpsData.length>0){
    getIndexVsNpsData.forEach((element)=>{
      if(element && element[indexType]){
        let getIndex = scoreUniqueList.findIndex(prev=>prev===element[indexType])
        if(getIndex===-1){
          scoreUniqueList.push( element[indexType])

        }
      }
    })
    scoreUniqueList.sort((a,b)=>a-b)
  }


  const chunkArray = (arr = [], chunkCount) => {
    const chunks = [];
    while(arr.length) {
        const chunkSize = Math.ceil(arr.length / chunkCount--);
        const chunk = arr.slice(0, chunkSize);
        chunks.push(chunk);
        arr = arr.slice(chunkSize);
    };
    return chunks;
  };
  let scoreSplitList = chunkArray(scoreUniqueList, 10)



  console.log('scoreUniqueList----->',scoreUniqueList)
  console.log('scoreSplitList----->',scoreSplitList)


  function getCountScore(a,b,dataList){
    let count=0
    if(dataList && dataList.length>0){
      for(var i =0; i< dataList.length; i++){
        let score = dataList[i][indexType];
        if(a<=score && score<=b){
          count++
        }
      }
    }
    return count
  }
  function spliceDecimal(no){
    if(no){
      let str = no.toString();
      let first = str.split('.')[0];
      let second = str.split('.')[1];
      let second2 = second?second.substring(0, 2):""
      let final = first+'.'+second2;
      if(second2){
        return parseFloat(final).toFixed(2)
      }else{
        return no
      }
    }else{
      return 0
    }
  }
  let pairScore = []
  if(scoreSplitList && scoreSplitList.length>0){
    scoreSplitList.forEach((element)=>{

      if(element && element.length>1){
        pairScore.push({
          "label":"("+spliceDecimal(element[0]).toString()+", "+spliceDecimal(element[element.length-1]).toString()+")",
          "value":[element[0],element[element.length-1]],
          "count":getCountScore(element[0],element[element.length-1],getIndexVsNpsData)
        })
      }else{
        pairScore.push({
          "label":"("+spliceDecimal(element[0]).toString()+")",
          "value":[element[0]],
          "count":getCountScore(element[0],element[0],getIndexVsNpsData)
        })
      }

    })
  }
  console.log('pairScore----->',pairScore)


    // let list = []
    // if(getIndexVsNpsData && getIndexVsNpsData.length>0){
    //   var arrays = getIndexVsNpsData.reduce(function(result, value, index, array) {
    //     if (index % 2 === 0)
    //       result.push(array.slice(index, index + 2));
    //     return result;
    //   }, []);
    //     console.log('arrays----->',arrays)

    // }



    return (
      <>
        <div onClick={()=>this.closeFilter(false)} >

          <div className='flex flex-wrap gap-4  justify-between w-full items-start' >

          <QuestionFilter
              showViewBy={this.state.showViewBy}
              closeFilterQues={this.closeFilterQues}

              callQuestionWordCloud={this.callQuestionWordCloudQuesChange} 
              getNameQuestionData={getNameQuestionData} 
              handleChangeQuesIdSave={this.handleChangeQuesIdSave}
            />      

              </div>
          </div>




        <div className="md:items-center justify-between flex md:flex-row flex-col gap-4">
            <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
            />

            {/* <span className="text-xs font-medium text-blue-500 hidden">Need Help?</span> */}
            <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

            </div>


        {true ?
          <>

            {/* <div className="xl:flex lg:flex md:flex justify-start items-center"> */}

              {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}



            {/* </div> */}

            {/* {FilterValues && FilterValues.length > 0 ?
              <div className=" px-4 xl:flex lg:flex md:flex justify-left items-center bg-white border-b py-3 h-10 ">
                {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
                  <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
                ) : null}</div>
              : null} */}



            <div onClick={() => this.closeFilter(false)} className=" " >
              {/* <div className="flex w-full" >


                <div className="bg-white rounded w-full shadow-lg px-4 m-4">
                  <div className="text-lg font-semibold py-4">{name} Behaviour-Range</div> */}

                 <div className="w-full" >

                  <div className="grid md:grid-cols-1 grid-cols-1 gap-4 ">

                  <div className="bg-white hover:shadow-lg duration-150 transition  border p-6 rounded-lg space-y-6   cursor-pointer">

                      <div className='flex justify-between item=center'>
                        <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap mr-24">{name} VS {isHappinessLadder?"Happiness Ladder":"NPS"} Score</h1>
                        <div className='flex'>
                          <h1 onClick={()=>this.changeIndexVSNpsType(1)} className={"cursor-pointer mx-2 text-base font-medium  pb-1 "+(npsType===1?" text-blue-500 border-b-2 border-blue-500":"text-gray-500 border-b-2 border-white")}>
                            {isHappinessLadder?"Happy":"Promoters"}</h1>
                          <h1 onClick={()=>this.changeIndexVSNpsType(2)} className={"cursor-pointer mx-2 text-base font-medium  pb-1 "+(npsType===2?" text-blue-500 border-b-2 border-blue-500":"text-gray-500 border-b-2 border-white")}>
                          {isHappinessLadder?"UnHappy":"Detractors"}</h1>
                          <h1 onClick={()=>this.changeIndexVSNpsType(3)} className={"cursor-pointer mx-2 text-base font-medium  pb-1 "+(npsType===3?" text-blue-500 border-b-2 border-blue-500":"text-gray-500 border-b-2 border-white")}>
                          {isHappinessLadder?"Mid-Happy":"Passives"}</h1>
                        </div>

                      </div>



                      <BoxPlot pairScore={pairScore} list={getIndexVsNpsData}/>

    

                  {/* {ErrorIndexObj && ErrorIndexObj["getIndexQuantileError"]?
                    <>
                      <div className='text-gray-400 font-medium text-center my-24 text-2xl'>{ErrorIndexObj["getIndexQuantileError"]}</div>
                    </>
                    :
                    <>
                  {IndexQuantile?
                  <BoxPlot getIndexQuantileData={IndexQuantile} GetParameterLabel={GetParameterLabel} XAxis={XAxis} name={name} />
                  :
                  <div className='text-center text-gray-500 p-2 text-lg'>{getIndexQuantileError}</div>}
                   </>} */}

                  
                </div>
              </div>
            </div>

            </div>
          </>
          :
          <>
            {!loading ?
              <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>
              : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexVSNPS);
