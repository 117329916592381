
import { authHeader } from '../_helpers';
import { CONST } from '../_config';
import FileDownload from "js-file-download";
import Axios from "axios";
import CryptoJS from 'crypto-js';

export const dashboardService = {
    logout,
    getClientProfile,
    getSurvey,
    generateCompanySurveyCode,


    getManagerList,

    assignRole,


    getIndex,
    getIndexVsNps,
    driverVsNpsRWA,
    getEmpFilter,
    getIndexFilter,
    getDemographicIndex,

    getHappinessIndexV2,
    getEngagementIndexV2,
    getHopeIndexV2,
    getStressIndexV2,
    getManagerIndexV2,
    getLeaderIndexV2,
    getSeniorleadershipIndexV2,
    getOrgIndexV2,


    getDemographicIndexHappiness,
    getDemographicIndexEngagement,
    getDemographicIndexStress,
    getDemographicIndexManager,
    getDemographicIndexLeader,
    getDemographicIndexOrg,
    getDemographicIndexMultiAxisOrg,

    getDemographicIndexExperience,

    getHappinessIndexDriver,
    getEngagementIndexDriver,
    getStressIndexDriver,
    getManagerIndexDriver,
    getLeaderIndexDriver,
    getOrgIndexDriver,

    getExperienceIndexDriver,


    getQuestion,
    getQuestionOverall,
    getQuestionReport,
    getMultiManagerQuestionList,
    getQuestionFilter,


    getHappinessIndexV2Demographic,
    getEngagementIndexV2Demographic,
    getStressIndexV2Demographic,



    getDemographicIndexForManager,
    getManagerListDashboardManagerIndex,

    getAllBenchMark,

    getRespondantDetails,
    getMeta360RespondantDetails,

    getMetaRespondantDetails,

    getCustomerRespondantDetails,

    getManagerListDomainFilter,


    getQuestionManagerOfHappiness,
    getQuestionManagerOfEngagement,
    getQuestionManagerOfStress,
    getQuestionManagerOfManager,

    getIndexQuantile,

    getDemographicIndexScatter,



    getHappinessCorrelation,
    getHappinessCorrelationOverAll,

    getEngagementCorrelation,
    getEngagementCorrelationOverAll,

    getStressCorrelation,
    getStressCorrelationOverAll,

    getManagerCorrelation,
    getManagerCorrelationOverAll,

    getLeaderCorrelation,
    getLeaderCorrelationOverAll,



    
    getRWAnalysis,
    getTextQuestion,
    getNameQuestion,
    getFeedbackTextQuestion,
    wordCloud,
    textcluster,
    textDist,
    wordCloudText,
    wordCloudMultiText,
    AdvanceWordCloud,
    cooccurmatrix,
    neighborGraph,


    getEngagementRWA,


    cronbatchAlphaVariance,

    getQuestionFavorability,
    getQuestionFavorability2,

    getHistoryIndex,

    getResponseAnalytics,
    getMetaResponseAnalyticsFilter,
    getMeta360ResponseAnalyticsFilter,
    getCustomerResponseAnalyticsFilter,
    getComment,



    getDemographicIndexMultiFilter,
    getDemographicIndexMultiAxisFilter,
    getDemographicIndexFilterReport,

    AssignManagerActionPlan,
    getManagerActionPlan,
    getEmployeeActionPlan,
    updateEmployeeTask,
    updateEmployeeResource,


    getAllManagerActionPlan,
    crossTab,
    crossTabDMG,
    crossTabAnalysis,
    getCorrelationQuestionType2,
    getAllParameterList,

    getCorrelationDimenionVsDimension,
    getCorrelationDimensionVsDimensionFilter,
    getDemographicOrgReport,




    getHappinessQuestion,
    getEngagementQuestion,
    getHopeQuestion,
    getStressQuestion,
    getManagerQuestion,
    getLeaderQuestion,

    TreeMap,
    textDistTreeMap,




    getManagerProfile,
    getMNGRSurveyList,
    
    getDemographicIndexMultiFilterEngComp,
    getDemographicIndexMultiFilterEngComp2,

    getOverallIndex,
    getOverallIndex2,


    getDemographicBranchCodeIndexMultiFilter,

    getParameterTypeList,

    createParameter,

    getOrgIndexV2Filter,

    getHappinessIndexV2Filter,
    getEngagementIndexV2Filter,
    getStressIndexV2Filter,

    getHappinessIndexDriverFilter,
    getEngagementIndexDriverFilter,
    getStressIndexDriverFilter,

    getParameterLibraryList,

    clusterAnalysis,
    getClusterEmpInfoList,

    topicModelling,

    createTopic,
    updateTopic,
    deleteTopic,
    getTopicList,
    getAllWordKeywordsText,
    getDemographicIndexEngCompOverall,
    getOverallScoreBySurveyId,
    getAllManagerScores,

    createGoal,
    getGoalList,
    createPlan,
    updateGoal,
    deleteGoal,
    updatePlan,
    deletePlan,
    startPlan,
    closePlan,
    getAllManagerGoalPlan,

    getIDPLastDate,
    editIDPLastDate,

    getCompanyNameOnly,
    managerDownloadStatus,
    getMngrReportDownloadStatus,
    getIndexByFilterAllStages,
    getQuestionMap,

    getDemographicIndexMultiManyFilter,
    getDemographicIndexOrgManyFilter,
    getQuestionDistribution,
    getQuesDistributionIndexOrg,


    getQuestionCustomTemplate,
    getCategoryOverallIndex,
    getLifecycleListenById,


    get360DimensionRelationScore,
    get360DimensionQuestions,
    getAnsweredEmployees,
    getOrgQuestion,
    getQuestionManagerPIDWise,
    getIndexScoreManagerPIDWise,

    getHappinessIndexDriverManagerPIDWise,
    getEngagementIndexDriverManagerPIDWise,
    getStressIndexDriverManagerPIDWise,
    getManagerIndexDriverManagerPIDWise,
    getOrgCoreDriverSentiment,
    AnswerFeedbackText,

    get360RelationResponse,
    getRespondantAllLifecycleDetails,

    getUserAccess,
    getFeedbackAndQuestionName,
    getParameterLibraryLabelByParentName,
    getIndexTrendChartByMonth,
    getQuestionTrendChartByMonth,
    getBenchmarkQuantile,
    getTextAndQuestionName,
    getLifecycleOverallPTM,

    getTextCluster,
    getTextClusterSentimentCount,
    getClusterKeywords,
    getClusterSentences,
    getAssessmentReportByEmpID,
    getType2AssessmentReportByEmpID,
    getAssessmentReportByEmpIDOnly,
    getTemp360EmpStrength,
    getAssessmentDashboard,
    getAssessmentByTheme,

    // Exit Dashboard
    getExitQuestion1,
    getExitQuestionAvg,
    getExitQuestionYesNo,
    getExitQuestionAllCount,
    getExitQuestionAllDemographics,
    rolkeywordbyemployee,

    getAssessmentDownloadStatus,
    getAssessmentDimensions,
    getAssessmentManagerList,
    getAssessmentManagerReport,
    getAssessmentOnlyDetails,
    getAssessmentEmpListByMngr,
    getReportType,
    getReportIndexYearCheck,
    getDemographicWiseRankedScore,
    getIndexOverallScoreDistribution,
    PMSAddAppraisalCycle1,
    PMSGetAppraisalCycleById,

    PMSAddModuleSetting2,
    PMSGetModuleSetting2,

    PMSAddRaterSetting3,
    PMSGetRaterSetting3,

    PMSApplicableFor3,
    PMSApplicableFor4,
    getPMSModuleNames,
    getPMSRaterNames,
    PMSAppraisalCycleList,
    CreateNpsSurvey,
    getNpsSurvey,
    PMSNormalization5,
    getEmployeeRatingAndNormalization,
    saveEmployeeNormalization,
    getSurveyTakenDateAdmin,
    PMSDMGEmployeeList,
    topicCloud,
    PMSGetProcessList,
    PMSAddProcessList,
    AddTextKeywords,
    GetTextKeywords,
    GetTextDistHighlights,
    getAllAnsweredAssessmentEmpData,
    getNudgeLibList,
    mapNudgeToQuestion,
    getNudgeTextQuesList,
    getNudgeTextBySurveyId,
    getQuestionWiseExcel,
    getReportQuesWiseWorldCloud,
    getAssessmentTop5DescByNames,
    getMngrTeamSize,
    getAllBenchMarkStatic,
    getAllFeedbackWords
};



async function getAllBenchMarkStatic(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getAllBenchMarkStatic`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAllBenchMarkStaticData: data.data
            }
            return ProfileObj;
        });
}



async function getMngrTeamSize(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getMngrTeamSize`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getMngrTeamSizeData: data.data
            }
            return ProfileObj;
        });
}






async function getAssessmentTop5DescByNames(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getAssessmentTop5DescByNames`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAssessmentTop5DescByNamesData: data.data
            }
            return ProfileObj;
        });
}



async function getQuestionWiseExcel(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getQuestionWiseExcel`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionWiseExcelData: data.data
            }
            return ProfileObj;
        });
}



async function getReportQuesWiseWorldCloud(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getReportQuesWiseWorldCloud`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getReportQuesWiseWorldCloudData: data.data
            }
            return ProfileObj;
        });
}



async function getAllAnsweredAssessmentEmpData(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getAllAnsweredAssessmentEmpData`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAllAnsweredAssessmentEmpDataList: data.data
            }
            return ProfileObj;
        });
}



async function getNudgeTextBySurveyId(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getNudgeTextBySurveyId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getNudgeTextBySurveyIdData: data.data
            }
            return ProfileObj;
        });
}



async function mapNudgeToQuestion(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/mapNudgeToQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                mapNudgeToQuestionData: data.data
            }
            return ProfileObj;
        });
}


async function getNudgeTextQuesList(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getNudgeTextQuesList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getNudgeTextQuesList: data.data
            }
            return ProfileObj;
        });
}



async function getNudgeLibList(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getNudgeLibList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getNudgeLibListData: data.data
            }
            return ProfileObj;
        });
}


async function AddTextKeywords(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/AddTextKeywords`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                addTextKeywordsData: data.data
            }
            return ProfileObj;
        });
}

async function GetTextKeywords(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/GetTextKeywords`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getTextKeywordsData: data.data
            }
            return ProfileObj;
        });
}

async function GetTextDistHighlights(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/GetTextDistHighlights`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getTextDistHighlightData: data.data
            }
            return ProfileObj;
        });
}

async function getSurveyTakenDateAdmin(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getSurveyTakenDateAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getSurveyTakenDateAdminData: data.data
            }
            return ProfileObj;
        });
}


async function saveEmployeeNormalization(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/saveEmployeeNormalization`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                saveEmployeeNormalization: data.data
            }
            return ProfileObj;
        });
}

async function getEmployeeRatingAndNormalization(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getDashboardOverviewStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getEmployeeRatingAndNormalization: data.data
            }
            return ProfileObj;
        });
}

async function getNpsSurvey(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getNpsSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getNpsSurvey: data.data
            }
            return ProfileObj;
        });
}
async function PMSNormalization5(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/PMSNormalization5`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                PMSNormalization5: data.data
            }
            return ProfileObj;
        });
}
async function CreateNpsSurvey(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/CreateNpsSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                CreateNpsSurvey: data.data
            }
            return ProfileObj;
        });
}
async function getPMSModuleNames(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getPMSModuleNames`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getPMSModuleNames: data.data
            }
            return ProfileObj;
        });
}
async function getPMSRaterNames(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getPMSRaterNames`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getPMSRaterNames: data.data
            }
            return ProfileObj;
        });
}
async function PMSAppraisalCycleList(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/PMSAppraisalCycleList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                PMSAppraisalCycleList: data.data
            }
            return ProfileObj;
        });
}
async function PMSApplicableFor4(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/PMSApplicableFor4`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                PMSApplicableFor4: data.data
            }
            return ProfileObj;
        });
}
async function PMSApplicableFor3(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/PMSApplicableFor3`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                PMSApplicableFor3: data.data
            }
            return ProfileObj;
        });
}
async function PMSAddRaterSetting3(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/PMSAddRaterSetting3`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                PMSAddRaterSetting3: data.data
            }
            return ProfileObj;
        });
}

async function PMSGetRaterSetting3(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/PMSGetRaterSetting3`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                PMSGetRaterSetting3: data.data
            }
            return ProfileObj;
        });
}

async function PMSAddModuleSetting2(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/PMSAddModuleSetting2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                PMSAddModuleSetting2: data.data
            }
            return ProfileObj;
        });
}

async function PMSGetModuleSetting2(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/PMSGetModuleSetting2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                PMSGetModuleSetting2: data.data
            }
            return ProfileObj;
        });
}
async function PMSAddAppraisalCycle1(value) {
    
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/PMSAddAppraisalCycle1`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                PMSAddAppraisalCycle1: data.data
            }
            return ProfileObj;
        });
}

async function PMSGetAppraisalCycleById(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/PMSGetAppraisalCycleById`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                PMSGetAppraisalCycleById: data.data
            }
            return ProfileObj;
        });
}

function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
}


function handleResponse(response) {
    // //////console.log("response22222   ", response);
 
     return response.text().then(text => {
         const data = text && JSON.parse(text);
         if (!response.ok) {
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logoutSession();
                 //location.reload(true);
             }
             // //////console.log("datadatadata ", response);
 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         ////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             // //////console.log("datadatadatadatadata   ", data);
             if (data.code===3) {
                 logoutSession();
             }
             if(data && data.message && (data.message === "UnauthorizedError")){
                logout()

             }
//              else if (data.code===1) {
//                 logoutSession();
//             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 
 

function handleResponse2(response) {
    // //////console.log("response22222   ", response);
 
     return response.text().then(text => {
         const data = text && JSON.parse(text);
         if (!response.ok) {
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logout();
                 //location.reload(true);
             }
             // //////console.log("datadatadata ", response);
 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         ////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             // //////console.log("datadatadatadatadata   ", data);
             if (data.code===3) {
                 logout();
             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 





function logoutSession(data) {

    let header = new Headers({
            'Content-Type': 'application/json',
            'Authorization': authHeader().Authorization
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/userlogout`, requestOptions)
            .then(handleResponse2)
            .then(user => {
                let userObj = {
                    userLogout: user
                }
                localStorage.removeItem('adminuser');
                window.location.href = "#/login";
                return userObj;
            });
}




//  function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 logout();
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         if (data.error) {
//             if (data.code === 3) {
//                 logout();
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         return data;
//     });
// }

async function getClientProfile() {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getClientProfile`, requestOptions)
        .then(handleResponse)
        .then(dataX => {

            let userX1 = dataX && dataX.data && dataX.data.text ? dataX.data.text : "";

            let cryptedText = userX1 ? JSON.parse(userX1) : "";
            let bytes = cryptedText ? CryptoJS.AES.decrypt(cryptedText, "secretkey123") : "";
            let userY = "";

            try {
                userY = bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : "";
            } catch (error) {
                userY = ""
            }


            let userZ = userY ?userY : "";

            let data = {
                "data":userY
            }
            // console.log('data:---------X-----------> ',data);

            if(data && data.data && data.data.moduleAccess){
                let accessModule = data.data.moduleAccess;
                if(accessModule){
                    let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(accessModule), "secretkey123").toString();
                    localStorage.setItem('accessModule',  JSON.stringify(cryptedText));
                }
            }

            let ProfileObj = {
                ClientProfileData: data.data
            }
            return ProfileObj;
        });
}



async function generateCompanySurveyCode(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/generateCompanySurveyCode`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                generateCompanySurveyCodeData: data.data
            }
            return ProfileObj;
        });
}


async function getSurvey(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }

    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                SurveyData: data.data
            }
            return SurveyObj;
        });
}





async function getEmpFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEmpFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let filterData = []
            if(data && data.data && data.data.length>0){
                data.data.forEach((filter)=>{
                    // if(["ManagerPID","Department","Function","Business"].findIndex(prev=>prev===filter.name)!==-1){
                    //     filterData.push(filter);
                    // }
                    if(!(filter && (filter.name === "ManagerPID"))){
                        filterData.push(filter);
                    }

                });
            }
            let IndexObj = {
                EmpFilterData: filterData && filterData.length>0?filterData.sort((a,b)=>(a.rank-b.rank)):[]
            }
            // console.log("IndexObj------------------>",IndexObj)

            // let getIndex = IndexObj["EmpFilterData"].findIndex(prev=>prev.name==="ManagerPID");
            // if(getIndex===-1){
            //     IndexObj["EmpFilterData"].push({
            //         "label":"ManagerPID",
            //         "name":"ManagerPID"
            //     })
            // }

            ////console.log('IndexObj: ',IndexObj)
            return IndexObj;
        });
}

async function getManagerList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getManagerList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                ManagerList: data.data
            }
            return IndexObj;
        });
}




async function assignRole(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)+ `/assignRole`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                CompanyEmployeeList: data.data
            }
            return IndexObj;
        });
}



// new api s -------------------------------->


async function getIndex(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndex`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getIndexData: data.data
            }
            return ProfileObj;
        });
}




async function getIndexVsNps(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndexVsNps`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getIndexVsNpsData: data.data
            }
            return ProfileObj;
        });
}



async function driverVsNpsRWA(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/driverVsNpsRWA`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                driverVsNpsRWAData: data.data
            }
            return ProfileObj;
        });
}





async function getOverallIndex(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getOverallIndex`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOverallIndexData: data.data
            }
            return ProfileObj;
        });
}


async function getOverallIndex2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getOverallIndex`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOverallIndex2Data: data.data
            }
            return ProfileObj;
        });
}








async function getIndexFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndexByFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getIndexFilterData: data.data
            }
            return ProfileObj;
        });
}

async function getDemographicIndex(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getDemographicIndex`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexData: data.data
            }
            return ProfileObj;
        });
}



async function getQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionData: data.data
            }
            return ProfileObj;
        });
}

async function getQuestionOverall(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionOverallData: data.data
            }
            return ProfileObj;
        });
}

async function getQuestionReport(value, template) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionReportData: {
                    "template":template,
                    "data":data.data
                }             
            }
            return ProfileObj;
        });
}

async function getQuestionFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionFilterData: data.data
            }
            return ProfileObj;
        });
}





async function getHappinessIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getHappinessIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getHappinessIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getEngagementIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEngamentIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getEngagementIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getHopeIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHopeIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getHopeIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getStressIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getStressIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getManagerIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getManagerIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getLeaderIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getLeaderIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getLeaderIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getSeniorleadershipIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getSeniorleadershipIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getSeniorleadershipIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getOrgIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getOrgIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOrgIndexV2Data: data.data
            }
            return ProfileObj;
        });
}




async function getDemographicIndexHappiness(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexHappiness`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexHappinessData: data.data
            }
            return ProfileObj;
        });
}

async function getDemographicIndexEngagement(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexEngagement`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexEngagementData: data.data
            }
            return ProfileObj;
        });
}

async function getDemographicIndexStress(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexStress`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexStressData: data.data
            }
            return ProfileObj;
        });
}

async function getDemographicIndexManager(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexManager`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexManagerData: data.data
            }
            return ProfileObj;
        });
}


async function getDemographicIndexExperience(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexExperience`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexExperienceData: data.data
            }
            return ProfileObj;
        });
}




async function getDemographicIndexLeader(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexLeader`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexLeaderData: data.data
            }
            return ProfileObj;
        });
}


async function getDemographicIndexOrg(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexOrg`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexOrgData: data.data
            }
            return ProfileObj;
        });
}

async function getDemographicIndexMultiAxisOrg(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexMultiAxisOrg`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexMultiAxisOrgData: data.data
            }
            return ProfileObj;
        });
}


async function getDemographicOrgReport(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexOrg`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicOrgReportData: data.data
            }
            return ProfileObj;
        });
}



async function getHappinessIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHappinessIndexDriver`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getHappinessIndexDriverData: data.data
            }
            return ProfileObj;
        });
}

async function getEngagementIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getEngagementIndexDriver`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getEngagementIndexDriverData: data.data
            }
            return ProfileObj;
        });
}

async function getStressIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressIndexDriver`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getStressIndexDriverData: data.data
            }
            return ProfileObj;
        });
}

async function getManagerIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerIndexDriver`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getManagerIndexDriverData: data.data
            }
            return ProfileObj;
        });
}

async function getExperienceIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getExperienceIndexDriver`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getExperienceIndexDriverData: data.data
            }
            return ProfileObj;
        });
}




async function getLeaderIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getLeaderIndexDriver`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getLeaderIndexDriverData: data.data
            }
            return ProfileObj;
        });
}




async function getOrgIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getOrgIndexDriver`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOrgIndexDriverData: data.data
            }
            return ProfileObj;
        });
}



async function getHappinessIndexV2Demographic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHappinessIndexV2Demographic`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHappinessIndexV2DemographicData: data.data
            }
            return IndexObj;
        });
}


async function getEngagementIndexV2Demographic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getEngagementIndexV2Demographic`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementIndexV2DemographicData: data.data
            }
            return IndexObj;
        });
}


async function getStressIndexV2Demographic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressIndexV2Demographic`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getStressIndexV2DemographicData: data.data
            }
            return IndexObj;
        });
}
async function getManagerListDashboardManagerIndex(value,mngr) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getIndexByFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerListDashboardManagerIndexData: {
                    "ManagerPID":mngr.id,
                    "ManagerName":mngr.name,
                    "ManagerData":data.data
                }
            }
            
            return IndexObj;
        });
}


async function getMultiManagerQuestionList(value,mngr) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getMultiManagerQuestionListData: {
                    "ManagerPID":mngr.id,
                    "ManagerName":mngr.name,
                    "ManagerData":data.data
                }
            }
            
            return IndexObj;
        });
}



async function getDemographicIndexForManager(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ApiData = data.data;
            ////console.log('ApiData======?',ApiData)
            let PassData = []
            if(ApiData && ApiData.list && ApiData.list.length>0){
                ApiData.list.forEach((item)=>{
                    if(!(item&&item.isTest)){
                        let temp = {
                            ...item
                            // Function: item&&item.Function?item.Function:"",
                            // Business: item&&item.Business?item.Business:"",
                            // Department: item&&item.Department?item.Department:"",
                            // ManagerPID: item&&item.emp_id?parseInt(item.emp_id):"",
                            // id: item&&item.emp_id?parseInt(item.emp_id):"",
                            // emp_id: item&&item.emp_id?item.emp_id:"",
                            // name: item&&item.name?item.name:""
                        }
                        // temp["ManagerPID"] = item&&item.ManagerPID?parseInt(item.ManagerPID):"";
                        temp["id"] = item&&item.emp_id?(item.emp_id):"";
                        temp["emp_id"] = item&&item.emp_id?(item.emp_id):"";

                        PassData.push(temp);
                    }
                });
            }
            let IndexObj = {
                getDemographicIndexForManagerData: PassData
            }
            return IndexObj;
        });
}

async function getAllBenchMark(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getAllBenchMark`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getAllBenchMarkData: data.data
            }
            return IndexObj;
        });
}

async function getRespondantDetails(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getRespondantDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getRespondantDetailsData: data.data
            }
            return IndexObj;
        });
}

async function getMeta360RespondantDetails(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getMeta360RespondantDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getMetaRespondantDetailsData: data.data
            }
            return IndexObj;
        });
}

async function getMetaRespondantDetails(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getMetaRespondantDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getMetaRespondantDetailsData: data.data
            }
            return IndexObj;
        });
}




async function getCustomerRespondantDetails(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCustomerRespondantDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getRespondantDetailsData: data.data
            }
            return IndexObj;
        });
}


async function getManagerListDomainFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getIndexByFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerListDomainFilterData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionManagerOfHappiness(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuestionManagerOfHappinessData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionManagerOfEngagement(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuestionManagerOfEngagementData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionManagerOfStress(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuestionManagerOfStressData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionManagerOfManager(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuestionManagerOfManagerData: data.data
            }
            return IndexObj;
        });
}


async function getIndexQuantile(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getIndexQuantile`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getIndexQuantileData: data.data
            }
            return IndexObj;
        });
}




async function getHappinessCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHappinessCorrelation`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHappinessCorrelationData: data.data
            }
            return IndexObj;
        });
}

async function getHappinessCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHappinessCorrelationOverAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHappinessCorrelationData: data.data
            }
            return IndexObj;
        });
}



async function getEngagementCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationDimensionVsDimensionFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementCorrelationData: data.data
            }
            return IndexObj;
        });
}


async function getEngagementCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationDimenionVsDimension`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementCorrelationData: data.data
            }
            return IndexObj;
        });
}



async function getStressCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressCorrelation`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getStressCorrelationData: data.data
            }
            return IndexObj;
        });
}

async function getStressCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressCorrelationOverAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getStressCorrelationData: data.data
            }
            return IndexObj;
        });
}




async function getManagerCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerCorrelation`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerCorrelationData: data.data
            }
            return IndexObj;
        });
}
async function getManagerCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerCorrelationOverAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerCorrelationData: data.data
            }
            return IndexObj;
        });
}


async function getLeaderCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getLeaderCorrelation`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getLeaderCorrelationData: data.data
            }
            return IndexObj;
        });
}

async function getLeaderCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getLeaderCorrelationOverAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getLeaderCorrelationData: data.data
            }
            return IndexObj;
        });
}




async function getDemographicIndexScatter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndex`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getDemographicIndexScatterData: data.data
            }
            return IndexObj;
        });
}



async function getRWAnalysis(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getRWAnalysis`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getRWAnalysisData: data.data
            }
            return IndexObj;
        });
}




async function getTextQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getTextQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getTextQuestionData: data.data.data
            }
            return IndexObj;
        });
}

async function getNameQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getTextQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getNameQuestionData: data.data.data
            }
            return IndexObj;
        });
}


async function getFeedbackTextQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getFeedbackTextQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getFeedbackTextQuestionData: data.data
            }
            return IndexObj;
        });
}



async function wordCloud(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/wordCloud`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                wordCloudData: data.data
            }
            return IndexObj;
        });
}



async function getAllFeedbackWords(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getAllFeedbackWords`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getAllFeedbackWordsData: data.data
            }
            return IndexObj;
        });
}

async function topicCloud(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/topicCloud`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                topicCloudData: data.data
            }
            return IndexObj;
        });
}



async function textcluster(value) {
    console.log("authHeader().Authorization",authHeader().Authorization)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/textcluster`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                textclusterData: data.data
            }
            return IndexObj;
        });
}


async function textDist(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/textDist`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                textDistData: data.data
            }
            return IndexObj;
        });
}

async function AdvanceWordCloud(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/AdvanceWordCloud`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                AdvanceWordCloudData: data.data
            }
            return IndexObj;
        });
}


async function wordCloudText(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/wordCloudText`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                wordCloudTextData: data.data,
                wordTextKeyWord: value.keyWord
            }
            return IndexObj;
        });
}



async function wordCloudMultiText(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/wordCloudMultiText`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                wordCloudMultiTextData: data.data,
                wordMultiTextKeyWord: value.keyWord1+", "+value.keyWord2
            }
            return IndexObj;
        });
}


async function cooccurmatrix(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/cooccurmatrix`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                cooccurmatrixData: data.data
            }
            return IndexObj;
        });
}



async function neighborGraph(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/neighborGraph`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                neighborGraphData: data.data
            }
            return IndexObj;
        });
}



async function getEngagementRWA(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getRWALatest`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementRWAData: data.data
            }
            return IndexObj;
        });
}




async function cronbatchAlphaVariance(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/cronbatchAlphaVariance`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                cronbatchAlphaVarianceData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionFavorability(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionFavorability`, requestOptions)
        .then(handleResponse)
        .then(data => {
            ////console.log('getQuestionFavorabilityData: ',data)
            let IndexObj = {
                getQuestionFavorabilityData: data.data
            }
            return IndexObj;
        });
}



async function getQuestionFavorability2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionFavorability`, requestOptions)
        .then(handleResponse)
        .then(data => {
            ////console.log('getQuestionFavorability2Data: ',data)
            let IndexObj = {
                getQuestionFavorability2Data: data.data
            }
            return IndexObj;
        });
}




async function getHistoryIndex(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHistoryIndex`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHistoryIndexData: data.data
            }
            return IndexObj;
        });
}


async function getResponseAnalytics(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getResponseAnalyticsFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getResponseAnalyticsData: data.data
            }
            return IndexObj;
        });
}

async function getMetaResponseAnalyticsFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getMetaResponseAnalyticsFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getMetaResponseAnalyticsFilterData: data.data
            }
            return IndexObj;
        });
}

async function getMeta360ResponseAnalyticsFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getMeta360ResponseAnalyticsFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getMetaResponseAnalyticsFilterData: data.data
            }
            return IndexObj;
        });
}


async function getCustomerResponseAnalyticsFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCustomerResponseAnalyticsFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getResponseAnalyticsData: data.data
            }
            return IndexObj;
        });
}


async function getComment(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getComment`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getCommentData: data.data
            }
            return IndexObj;
        });
}


async function getDemographicIndexMultiFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexMultiFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getDemographicIndexMultiFilterData: data.data
            }
            return IndexObj;
        });
}

async function getDemographicIndexMultiAxisFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexMultiAxisFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getDemographicIndexMultiAxisFilterData: data.data
            }
            return IndexObj;
        });
}




async function getDemographicIndexFilterReport(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexMultiFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getDemographicIndexFilterReportData: data.data
            }
            return IndexObj;
        });
}


async function getDemographicIndexMultiManyFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexMultiFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getDemographicIndexMultiManyFilterData: data.data
            }
            return IndexObj;
        });
}



async function getDemographicIndexOrgManyFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexOrg`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getDemographicIndexOrgManyFilterData: data.data
            }
            return IndexObj;
        });
}




async function AssignManagerActionPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/AssignManagerActionPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                AssignManagerActionPlanData: data.data
            }
            return IndexObj;
        });
}


async function getManagerActionPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerActionPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerActionPlanData: data.data
            }
            return IndexObj;
        });
}

async function getEmployeeActionPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getEmployeeActionPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEmployeeActionPlanData: data.data
            }
            return IndexObj;
        });
}



async function updateEmployeeTask(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/updateEmployeeTask`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                updateEmployeeTaskData: data.data
            }
            return IndexObj;
        });
}



async function updateEmployeeResource(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/updateEmployeeResource`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                updateEmployeeResourceData: data.data
            }
            return IndexObj;
        });
}



async function getAllManagerActionPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getAllManagerActionPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getAllManagerActionPlanData: data.data
            }
            return IndexObj;
        });
}


async function crossTab(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/crossTab`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                crossTabData: data.data
            }
            return IndexObj;
        });
}

async function crossTabDMG(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/crossTabDMG`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                crossTabDMGData: data.data
            }
            return IndexObj;
        });
}

async function crossTabAnalysis(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/crossTabAnalysis`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                crossTabAnalysisData: data.data
            }
            return IndexObj;
        });
}



async function getCorrelationQuestionType2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationQuestionType2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getCorrelationQuestionType2Data: data.data
            }
            return IndexObj;
        });
}



async function getAllParameterList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getParameterList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getAllParameterListData: data.data
            }
            return IndexObj;
        });
}


async function getCorrelationDimenionVsDimension(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationDimenionVsDimension`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getCorrelationDimenionVsDimensionData: data.data
            }
            return IndexObj;
        });
}

async function getCorrelationDimensionVsDimensionFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationDimensionVsDimensionFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getCorrelationDimensionVsDimensionFilterData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionDistribution(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionDistribution`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuestionDistributionData: data.data
            }
            return IndexObj;
        });
}


async function getQuesDistributionIndexOrg(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuesDistributionIndexOrg`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuesDistributionIndexOrgData: data.data
            }
            return IndexObj;
        });
}


async function getHappinessQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHappinessQuestionData: data.data
            }
            return IndexObj;
        });
}



async function getEngagementQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementQuestionData: data.data
            }
            return IndexObj;
        });
}




async function getHopeQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHopeQuestionData: data.data
            }
            return IndexObj;
        });
}



async function getStressQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getStressQuestionData: data.data
            }
            return IndexObj;
        });
}




async function getManagerQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerQuestionData: data.data
            }
            return IndexObj;
        });
}


async function getLeaderQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getLeaderQuestionData: data.data
            }
            return IndexObj;
        });
}



async function TreeMap(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/TreeMap`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                TreeMapData: data.data
            }
            return IndexObj;
        });
}



async function textDistTreeMap(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/textDistTreeMap`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                textDistTreeMapData: data.data
            }
            return IndexObj;
        });
}












async function getManagerProfile() {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getManagerProfile`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                ManagerProfileData: data.data
            }
            return ProfileObj;
        });
}


async function getMNGRSurveyList() {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getMNGRSurveyList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getMNGRSurveyListData: data.data
            }
            return ProfileObj;
        });
}


async function getDemographicIndexMultiFilterEngComp(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getDemographicIndexMultiFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexMultiFilterEngCompData: data.data
            }
            return ProfileObj;
        });
}


async function getDemographicIndexMultiFilterEngComp2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getDemographicIndexMultiFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexMultiFilterEngComp2Data: data.data
            }
            return ProfileObj;
        });
}

async function getDemographicIndexEngCompOverall(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getDemographicIndexMultiFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexEngCompOverallData: data.data
            }
            return ProfileObj;
        });
}



async function getDemographicBranchCodeIndexMultiFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getDemographicBranchCodeIndexMultiFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicBranchCodeIndexMultiFilterData: data.data
            }
            return ProfileObj;
        });
}




async function getParameterTypeList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getParameterTypeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getParameterTypeListData: data.data
            }
            return ProfileObj;
        });
}




async function createParameter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createParameter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                createParameterData: data.data
            }
            return ProfileObj;
        });
}



async function getOrgIndexV2Filter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getOrgIndexV2Filter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOrgIndexV2FilterData: data.data
            }
            return ProfileObj;
        });
}




async function getHappinessIndexV2Filter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getHappinessIndexV2Filter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getHappinessIndexV2FilterData: data.data
            }
            return ProfileObj;
        });
}



async function getEngagementIndexV2Filter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEngagementIndexV2Filter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getEngagementIndexV2FilterData: data.data
            }
            return ProfileObj;
        });
}



async function getStressIndexV2Filter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getStressIndexV2Filter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getStressIndexV2FilterData: data.data
            }
            return ProfileObj;
        });
}




async function getHappinessIndexDriverFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getHappinessIndexDriverFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getHappinessIndexDriverFilterData: data.data
            }
            return ProfileObj;
        });
}




async function getEngagementIndexDriverFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEngagementIndexDriverFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getEngagementIndexDriverFilterData: data.data
            }
            return ProfileObj;
        });
}



async function getStressIndexDriverFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getStressIndexDriverFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getStressIndexDriverFilterData: data.data
            }
            return ProfileObj;
        });
}





async function getParameterLibraryList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getParameterLibraryAllList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getParameterLibraryListData: data.data
            }
            return ProfileObj;
        });
}




async function clusterAnalysis(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/clusterAnalysis`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                clusterAnalysisData: data.data
            }
            return ProfileObj;
        });
}



async function getClusterEmpInfoList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getClusterEmpInfoList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getClusterEmpInfoListData: data.data
            }
            return ProfileObj;
        });
}




async function topicModelling(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/topicModelling`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                topicModellingData: data.data
            }
            return ProfileObj;
        });
}




async function createTopic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createTopic`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                createTopicData: data.data
            }
            return ProfileObj;
        });
}



async function updateTopic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateTopic`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                updateTopicData: data.data
            }
            return ProfileObj;
        });
}



async function deleteTopic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deleteTopic`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                deleteTopicData: data.data
            }
            return ProfileObj;
        });
}



async function getTopicList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getTopicList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getTopicListData: data.data
            }
            return ProfileObj;
        });
}


async function getAllWordKeywordsText(value,topic) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAllWordKeywordsText`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let ProfileObj = {
                getAllWordKeywordsTextData: data.data,
                getAllWordKeywordsTopic: topic

            }
            return ProfileObj;
        });
}




async function getOverallScoreBySurveyId(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getOverallScoreBySurveyId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOverallScoreBySurveyIdData: data.data
            }
            return ProfileObj;
        });
}



async function getAllManagerScores(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAllManagerScores`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("getAllManagerScores--->",data.data)
            let ProfileObj = {
                getAllManagerScoresData: data.data
            }
            return ProfileObj;
        });
}


async function createGoal(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createGoal`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("createGoal--->",data.data)
            let ProfileObj = {
                createGoalData: data.data
            }
            return ProfileObj;
        });
}


async function getGoalList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getGoalList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("getGoalList--->",data.data)
            let ProfileObj = {
                getGoalListData: data.data
            }
            return ProfileObj;
        });
}


async function updateGoal(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateGoal`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("updateGoal--->",data.data)
            let ProfileObj = {
                updateGoalData: data.data
            }
            return ProfileObj;
        });
}

async function deleteGoal(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deleteGoal`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("deleteGoal--->",data.data)
            let ProfileObj = {
                deleteGoalData: data.data
            }
            return ProfileObj;
        });
}



async function createPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("createPlan--->",data.data)
            let ProfileObj = {
                createPlanData: data.data
            }
            return ProfileObj;
        });
}




async function updatePlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updatePlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("updatePlan--->",data.data)
            let ProfileObj = {
                updatePlanData: data.data
            }
            return ProfileObj;
        });
}


async function deletePlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deletePlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("deletePlan--->",data.data)
            let ProfileObj = {
                deletePlanData: data.data
            }
            return ProfileObj;
        });
}


async function startPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/startPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("startPlan--->",data.data)
            let ProfileObj = {
                startPlanData: data.data
            }
            return ProfileObj;
        });
}


async function closePlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/closePlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("closePlan--->",data.data)
            let ProfileObj = {
                closePlanData: data.data
            }
            return ProfileObj;
        });
}


async function getAllManagerGoalPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAllManagerGoalPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("getAllManagerGoalPlan--->",data.data)
            let ProfileObj = {
                getAllManagerGoalPlanData: data.data
            }
            return ProfileObj;
        });
}

async function getIDPLastDate(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIDPLastDate`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("getIDPLastDate--->",data.data)
            let ProfileObj = {
                getIDPLastDateData: data.data
            }
            return ProfileObj;
        });
}

async function editIDPLastDate(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/editIDPLastDate`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("editIDPLastDate--->",data.data)
            let ProfileObj = {
                editIDPLastDateData: data.data
            }
            return ProfileObj;
        });
}


async function getCompanyNameOnly(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCompanyNameOnly`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("getCompanyNameOnly--->",data.data)
            let ProfileObj = {
                getCompanyNameOnlyData: data.data
            }
            return ProfileObj;
        });
}


async function managerDownloadStatus(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/managerDownloadStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                managerDownloadStatus: data.data
            }
            return ProfileObj;
        });
}


async function getMngrReportDownloadStatus(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getMngrReportDownloadStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getMngrReportDownloadStatusData: data.data
            }
            return ProfileObj;
        });
}


async function getIndexByFilterAllStages(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndexByFilterAllStages`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getIndexByFilterAllStagesData: data.data
            }
            return ProfileObj;
        });
}

async function getQuestionMap(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionMap`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionMapData: data.data
            }
            return ProfileObj;
        });
}


async function getQuestionCustomTemplate(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionCustomTemplate`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionCustomTemplateData: data.data
            }
            return ProfileObj;
        });
}


async function getCategoryOverallIndex(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCategoryOverallIndex`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getCategoryOverallIndexData: data.data
            }
            return ProfileObj;
        });
}

async function getLifecycleListenById(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getLifecycleListenById`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getLifecycleListenByIdData: data.data
            }
            return ProfileObj;
        });
}


async function get360DimensionRelationScore(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/get360DimensionRelationScore`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                get360DimensionRelationScoreData: data.data
            }
            return ProfileObj;
        });
}


async function get360DimensionQuestions(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/get360DimensionQuestions`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                get360DimensionQuestionsData: data.data
            }
            return ProfileObj;
        });
}


async function getAnsweredEmployees(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAnsweredEmployees`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAnsweredEmployeesData: data.data
            }
            return ProfileObj;
        });
}


async function getOrgQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOrgQuestionData: data.data
            }
            return ProfileObj;
        });
}


async function getQuestionManagerPIDWise(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionManagerPIDWise`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionManagerPIDWiseData: data.data
            }
            return ProfileObj;
        });
}



async function getIndexScoreManagerPIDWise(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndexScoreManagerPIDWise`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getIndexScoreManagerPIDWiseData: data.data
            }
            return ProfileObj;
        });
}

async function getHappinessIndexDriverManagerPIDWise(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getHappinessIndexDriverManagerPIDWise`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getHappinessIndexDriverManagerPIDWiseData: data.data
            }
            return ProfileObj;
        });
}

async function getEngagementIndexDriverManagerPIDWise(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEngagementIndexDriverManagerPIDWise`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getEngagementIndexDriverManagerPIDWiseData: data.data
            }
            return ProfileObj;
        });
}

async function getStressIndexDriverManagerPIDWise(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getStressIndexDriverManagerPIDWise`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getStressIndexDriverManagerPIDWiseData: data.data
            }
            return ProfileObj;
        });
}

async function getManagerIndexDriverManagerPIDWise(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getManagerIndexDriverManagerPIDWise`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getManagerIndexDriverManagerPIDWiseData: data.data
            }
            return ProfileObj;
        });
}


async function getOrgCoreDriverSentiment(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getOrgCoreDriverSentiment`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOrgCoreDriverSentimentData: data.data
            }
            return ProfileObj;
        });
}

async function AnswerFeedbackText(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/AnswerFeedbackText`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                AnswerFeedbackTextData: data.data,
                AnswerFeedbackTextKeyWord: value.keyWord
            }
            return ProfileObj;
        });
}


async function get360RelationResponse(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/get360RelationResponse`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                get360RelationResponseData: data.data
            }
            return ProfileObj;
        });
}

async function getRespondantAllLifecycleDetails(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getRespondantAllLifecycleDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getRespondantAllLifecycleDetailsData: data.data
            }
            return ProfileObj;
        });
}

async function getUserAccess(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getUserAccess`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getUserAccessData: data.data
            }
            return ProfileObj;
        });
}


async function getFeedbackAndQuestionName(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getFeedbackAndQuestionName`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getFeedbackAndQuestionNameData: data.data
            }
            return ProfileObj;
        });
}
async function getTextAndQuestionName(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getTextAndQuestionName`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getTextAndQuestionNameData: data.data
            }
            return ProfileObj;
        });
}



async function getParameterLibraryLabelByParentName(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getParameterLibraryLabelByParentName`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getParameterLibraryLabelByParentNameData: data.data
            }
            return ProfileObj;
        });
}

async function getIndexTrendChartByMonth(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndexTrendChartByMonth`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getIndexTrendChartByMonthData: data.data
            }
            return ProfileObj;
        });
}

async function getQuestionTrendChartByMonth(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionTrendChartByMonth`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionTrendChartByMonthData: data.data
            }
            return ProfileObj;
        });
}


async function getBenchmarkQuantile(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getBenchmarkQuantile`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getBenchmarkQuantileData: data.data
            }
            return ProfileObj;
        });
}


async function getLifecycleOverallPTM(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getLifecycleOverallPTM`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getLifecycleOverallPTMData: data.data
            }
            return ProfileObj;
        });
}


async function getTextCluster(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getTextCluster`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getTextClusterData: data.data
            }
            return ProfileObj;
        });
}



async function getTextClusterSentimentCount(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getTextClusterSentimentCount`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getTextClusterSentimentCountData: data.data
            }
            return ProfileObj;
        });
}


async function getClusterKeywords(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getClusterKeywords`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getClusterKeywordsData: data.data
            }
            return ProfileObj;
        });
}

async function getClusterSentences(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getClusterSentences`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getClusterSentencesData: data.data,
                getClusterSaveKeyWord:value.keyWord
            }
            return ProfileObj;
        });
}

async function getAssessmentReportByEmpID(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAssessmentReportByEmpID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAssessmentReportByEmpIDData: data.data
            }
            return ProfileObj;
        });
}

async function getType2AssessmentReportByEmpID(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    return Axios({
        url:(backendUrl?backendUrl:CONST.BACKEND_URL) + `/getType2AssessmentReportByEmpID`,
        method:'POST',
        responseType:"blob",
        headers: {
            'Content-Type': 'application/json',
            "authorization": authHeader().Authorization
        },
        data: value
    }).then((res)=>{

        // console.log("res.data---------->",res.data)

        // let blob = new Blob([new Uint8Array(res.data.data).buffer], { type:"application/pdf" })
        // let link = document.createElement('a')
        // link.href = window.URL.createObjectURL(blob)
        // link.download = 'Assessment.pdf'
        // link.click()

        // if(res && res.data){
        //     const url = window.URL.createObjectURL(new Blob([new Uint8Array(res.data).buffer]));
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', 'Assessment.pdf');
        //     document.body.appendChild(link);
        //     link.click();
        // }

        FileDownload(res.data,"Assessment.pdf")

        let ProfileObj = {
            getType2AssessmentReportByEmpIDDara: []
        }
        return ProfileObj;
    })
}

async function getAssessmentReportByEmpIDOnly(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAssessmentReportByEmpIDOnly`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAssessmentReportByEmpIDOnlyData: data.data
            }
            return ProfileObj;
        });
}


async function getTemp360EmpStrength(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getTemp360EmpStrength`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getTemp360EmpStrengthData: data.data
            }
            return ProfileObj;
        });
}



async function getAssessmentDashboard(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAssessmentDashboard`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAssessmentDashboardData: data.data
            }
            return ProfileObj;
        });
}


async function getAssessmentByTheme(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAssessmentByTheme`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAssessmentByThemeData: data.data
            }
            return ProfileObj;
        });
}



async function getExitQuestionAllCount(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getExitQuestionAllCount`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getExitQuestionAllCountData: data.data
            }
            // console.log('getExitQuestionOnegetExitQuestionOnegetExitQuestionOne', getExitQuestionOne);
            return ProfileObj;
        });
}

async function getExitQuestionAvg(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getExitQuestionAvg`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getExitQuestionAvgData: data.data
            }
            // console.log('getExitQuestionOnegetExitQuestionOnegetExitQuestionOne', getExitQuestionOne);
            return ProfileObj;
        });
}

async function getExitQuestionYesNo(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getExitQuestionYesNo`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getExitQuestionYesNoData: data.data
            }
            // console.log('getExitQuestionOnegetExitQuestionOnegetExitQuestionOne', getExitQuestionOne);
            return ProfileObj;
        });
}

async function getExitQuestion1(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getExitQuestion1`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getExitQuestionOne: data.data
            }
            // console.log('getExitQuestionOnegetExitQuestionOnegetExitQuestionOne', getExitQuestionOne);
            return ProfileObj;
        });
}
async function getExitQuestionAllDemographics(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getExitQuestionAllDemographics`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getExitQuestionAllDemographicsData: data.data
            }
            // console.log('getExitQuestionOnegetExitQuestionOnegetExitQuestionOne', getExitQuestionOne);
            return ProfileObj;
        });
}
async function rolkeywordbyemployee(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/rolkeywordbyemployee`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                rolkeywordbyemployeeData: data.data
            }
            return ProfileObj;
        });
}

async function getAssessmentDownloadStatus(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getAssessmentDownloadStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAssessmentDownloadStatusData: data.data
            }
            return ProfileObj;
        });
}


async function getAssessmentDimensions(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getAssessmentDimensions`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAssessmentDimensionsData: data.data
            }
            return ProfileObj;
        });
}

async function getAssessmentManagerList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getAssessmentManagerList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAssessmentManagerListData: data.data
            }
            return ProfileObj;
        });
}
async function getAssessmentManagerReport(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getAssessmentManagerReport`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAssessmentManagerReportData: data.data
            }
            return ProfileObj;
        });
}
async function getAssessmentOnlyDetails(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getAssessmentOnlyDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAssessmentOnlyDetailsData: data.data
            }
            return ProfileObj;
        });
}
async function getAssessmentEmpListByMngr(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getAssessmentEmpListByMngr`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAssessmentEmpListByMngrData: data.data
            }
            return ProfileObj;
        });
}

async function getReportType(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getReportType`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getReportTypeData: data.data
            }
            return ProfileObj;
        });
}

async function getReportIndexYearCheck(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getReportIndexYearCheck`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getReportIndexYearCheckData: data.data
            }
            return ProfileObj;
        });
}


async function getDemographicWiseRankedScore(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getDemographicWiseRankedScore`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicWiseRankedScoreData: data.data
            }
            return ProfileObj;
        });
}


async function getIndexOverallScoreDistribution(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL) + `/getIndexOverallScoreDistribution`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getIndexOverallDistributionData: data.data
            }
            return ProfileObj;
        });
}



async function PMSDMGEmployeeList(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/PMSDMGEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let ProfileObj = {
                PMSDMGEmployeeList: data.data
            }
            return ProfileObj;
        });
}





async function PMSGetProcessList(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/PMSGetProcessList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let final = []
            for(var i = 0; i < data.data.length; i++){
                let details = data.data[i];

                let temp = {
                    "moduleName":details.moduleName,
                    "processList":details.processList.map((ele)=>({
                        ...ele,
                        "startDate":new Date(),
                        "endDate":new Date()
                    }))
                }

                final.push(temp)
            }

            let ProfileObj = {
                PMSGetProcessDataList:final
            }
            return ProfileObj;
        });
}



async function PMSAddProcessList(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/PMSAddProcessList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let ProfileObj = {
                PMSAddProcessList: data.data
            }
            return ProfileObj;
        });
}
