
import { authHeader } from '../_helpers';
import { CONST } from '../_config';
// let backendUrl = JSON.parse(localStorage.getItem('backendUrl'));

export const riskdashtype2Service = {
    logout,
    getSurvey,
    getDashboardCard,
    getQuestionDist,
    getMeanScore,
    getMeanScoreQwise,
    getQuestionDistOverall,
    getQuestionMatrixResult,
    getQuestionOthersResult,
    getQuestionOthersResultFilter,
    getQuestion2OthersResultFilter,
    getQuestionByQuestionIdResult,
    getQuestionWithBranchCodeByQuestionId,
    getQuestionWithBranchCodeByQuestionId2,

    getIndexByFilterDMG,
    getQuestionOthersMultiResultFilter,
    RiskRespondantEmployees,
    RiskgetEmpQuestionResponses,
    RiskgetRiskCategory,
    RiskgetEmpQuestionAllStagesResponses,
    RiskAllEmpQuesResponses,
    RiskFilterEmpQuesResponses,
    getAllRiskActionPlan,
    getAllRiskEmpConversation,
    onScheduleSubmit,
    submitPeoptoMeetResolved,
    OnboardingEmpQuesResponses,
    LifecyclePTMEmpQuesResponses,
    LifecycleEngQuesResponses,
    LifecyclePTMEmpQuesOpenEnded,
    LifecycleEngamentIndexV2
    
};


function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
}


function handleResponse(response) {
    // //////console.log("response22222   ", response);
 
     return response.text().then(text => {
         const data = text && JSON.parse(text);
         if (!response.ok) {
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logoutSession();
                 //location.reload(true);
             }
             // //////console.log("datadatadata ", response);
 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         ////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             // //////console.log("datadatadatadatadata   ", data);
             if (data.code===3) {
                 logoutSession();
             }
             if(data && data.message && (data.message === "UnauthorizedError")){
                logout()

             }
//              else if (data.code===1) {
//                 logoutSession();
//             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 
 

function handleResponse2(response) {
    // //////console.log("response22222   ", response);
 
     return response.text().then(text => {
         const data = text && JSON.parse(text);
         if (!response.ok) {
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logout();
                 //location.reload(true);
             }
             // //////console.log("datadatadata ", response);
 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         ////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             // //////console.log("datadatadatadatadata   ", data);
             if (data.code===3) {
                 logout();
             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 




function logoutSession(data) {

    let header = new Headers({
            'Content-Type': 'application/json',
            'Authorization': authHeader().Authorization
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/userlogout`, requestOptions)
            .then(handleResponse2)
            .then(user => {
                let userObj = {
                    userLogout: user
                }
                localStorage.removeItem('adminuser');
                window.location.href = "#/login";
                return userObj;
            });
}


// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 logout();
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         if (data.error) {
//             if (data.code === 3) {
//                 logout();
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         return data;
//     });
// }


async function getSurvey(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                SurveyData: data.data
            }
            return SurveyObj;
        });
}


async function getDashboardCard(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getDashboardCard`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getDashboardCardData: data.data
            }
            return SurveyObj;
        });
}


async function getQuestionDist(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionDist`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionDistData: data.data
            }
            return SurveyObj;
        });
}

async function getQuestionDistOverall(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionDist`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionDistOverallData: data.data
            }
            return SurveyObj;
        });
}

async function getMeanScore(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getMeanScore`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getMeanScoreData: data.data.data
            }
            return SurveyObj;
        });
}


async function getMeanScoreQwise(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getMeanScoreQwise`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getMeanScoreQwiseData: data.data.data
            }
            return SurveyObj;
        });
}

async function getQuestionMatrixResult(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/RiskgetQuestionResult`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionMatrixResultData: data.data
            }
            return SurveyObj;
        });
}

async function getQuestionOthersResult(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/RiskgetQuestionResult`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionOthersResultData: data.data
            }
            return SurveyObj;
        });
}




async function getQuestionOthersResultFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/RiskgetQuestionResult`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionOthersResultFilterData: data.data
            }
            return SurveyObj;
        });
}



async function getQuestion2OthersResultFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/RiskgetQuestionResult`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestion2OthersResultFilterData: data.data
            }
            return SurveyObj;
        });
}




async function getQuestionByQuestionIdResult(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionByQuestionIdResult`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionByQuestionIdResultData: data.data
            }
            return SurveyObj;
        });
}



async function getQuestionWithBranchCodeByQuestionId(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionWithBranchCodeByQuestionId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionWithBranchCodeByQuestionIdData: data.data
            }
            return SurveyObj;
        });
}


async function getQuestionWithBranchCodeByQuestionId2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionWithBranchCodeByQuestionId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionWithBranchCodeByQuestionId2Data: data.data
            }
            return SurveyObj;
        });
}



async function getIndexByFilterDMG(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndexByFilterDMG`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getIndexByFilterDMGData: data.data
            }
            return SurveyObj;
        });
}


async function getQuestionOthersMultiResultFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionResult`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionOthersMultiResultFilterData: data.data
            }
            return SurveyObj;
        });
}



async function RiskRespondantEmployees(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/RiskRespondantEmployees`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                RiskRespondantEmployeesData: data.data
            }
            return SurveyObj;
        });
}


async function RiskgetEmpQuestionResponses(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/RiskgetEmpQuestionResponses`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                RiskgetEmpQuestionResponsesData: data.data
            }
            return SurveyObj;
        });
}


async function RiskgetRiskCategory(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/RiskgetRiskCategory`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                RiskgetRiskCategoryData: data.data
            }
            return SurveyObj;
        });
}


async function RiskgetEmpQuestionAllStagesResponses(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/RiskgetEmpQuestionAllStagesResponses`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                RiskEmpQuesAllStagesResData: data.data
            }
            return SurveyObj;
        });
}


async function RiskAllEmpQuesResponses(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/RiskAllEmpQuesResponses`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                RiskAllEmpQuesResponsesData: data.data
            }
            return SurveyObj;
        });
}


async function RiskFilterEmpQuesResponses(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/RiskFilterEmpQuesResponses`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                RiskFilterEmpQuesResponsesData: data.data
            }
            return SurveyObj;
        });
}


async function OnboardingEmpQuesResponses(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/OnboardingEmpQuesResponses`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                OnboardingEmpQuesResponsesData: data.data
            }
            return SurveyObj;
        });
}


async function LifecyclePTMEmpQuesResponses(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/LifecyclePTMEmpQuesResponses`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                LifecyclePTMEmpQuesResponsesData: data.data
            }
            return SurveyObj;
        });
}

async function LifecycleEngQuesResponses(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/LifecycleEngQuesResponses`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                LifecycleEngQuesResponsesData: data.data
            }
            return SurveyObj;
        });
}



async function LifecyclePTMEmpQuesOpenEnded(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/LifecyclePTMEmpQuesOpenEnded`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                LifecyclePTMEmpQuesOpenEndedData: data.data
            }
            return SurveyObj;
        });
}


async function getAllRiskActionPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAllRiskActionPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getAllRiskActionPlanData: data.data
            }
            return SurveyObj;
        });
}

async function getAllRiskEmpConversation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAllRiskEmpConversation`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getAllRiskEmpConversationData: data.data
            }
            return SurveyObj;
        });
}


async function onScheduleSubmit(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/onScheduleSubmit`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                onScheduleSubmitData: data.data
            }
            return SurveyObj;
        });
}

async function submitPeoptoMeetResolved(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/submitPeoptoMeetResolved`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                submitPeoptoMeetResolvedData: data.data
            }
            return SurveyObj;
        });
}

async function LifecycleEngamentIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/LifecycleEngamentIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                LifecycleEngamentIndexV2Data: data.data
            }
            return SurveyObj;
        });
}

