import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import ReactTooltip from "react-tooltip";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurveyName: "",
      currentSurveyId: "",

      showDropdown: false,

      showSidebar: false,

      showSubMenu: false,
    };
  }
  componentDidMount() {}

  onDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false });
    } else {
      this.setState({ showDropdown: true });
    }
  };

  logout = () => {
    this.props.dispatch(userActions.logout());
  };

  onSubMenu = (check) => {
    if (this.state.showSidebar) {
      this.setState({ showSubMenu: check });
    }
  };

  sidebarToggle = () => {
    if (this.state.showSubMenu) {
      this.setState({ showSubMenu: false });
    }
    this.setState({ showSidebar: !this.state.showSidebar });
  };

  render() {
    let {
      OverviewList,
      handleSidebarExtra,
      handleSidebarClick,
      IndexNo,
      showOverview,
      showRiskProfile,
      showOneToOneConversation,
    } = this.props;
    let { showSidebar } = this.state;

    // console.log('OverviewList: ',OverviewList);

    return (
      <>
        <div className="hidden w-16 lg:block " >
          <div className={showSidebar ?
            "fixed lg:block hidden shadow-xl w-64 left-0 top-16 h-screen z-20 transition duration-300 transform bg-white border-r h-increse" :
            "fixed lg:block hidden w-16 left-0 top-16 h-screen z-20 transition duration-300 transform bg-white border-r h-increse"
          } style={{ height: 'calc(100% - 4rem)' }} id="customscroll2">

            <div className="flex items-center " >
              <div className="flex items-center justify-center w-16 px-4 py-3 text-blue-500">
                <span onClick={() => this.sidebarToggle()} className="p-1 text-gray-500 bg-gray-100 rounded cursor-pointer material-symbols-outlined">
                  {showSidebar ?
                    "chevron_left" :
                    "chevron_right"
                  }
                </span>
              </div>
              {showSidebar ?
                <span className="ml-4 text-sm font-normal text-gray-500 cursor-default">Insight</span>
                : null}


            </div>
            <ReactTooltip
              place="right"
              className="extraClass1"
              type="dark"
              effect="solid"
            />
 

            <div className="mb-4 overflow-hidden overflow-y-auto font-medium" style={{ height: 'calc(100% - 4rem)' }}>
              {/* 1 */}
              <div data-tip="Overview" onClick={() => handleSidebarClick(false)}
                className={showOverview ? "cursor-pointer flex items-center bg-blue-50 text-blue-500 border-r-2 border-blue-500" :
                  "cursor-pointer flex items-center hover:bg-blue-50 text-gray-500"}>
                <div className="flex items-center justify-center w-16 h-12 px-4 py-4">
                  <span className="p-2 material-symbols-outlined">dashboard</span>
                </div>
                {showSidebar ? <span class=" text-sm text-[#3D405B]/70 font-medium ">Overview</span> : null}

              </div>
              <ReactTooltip place="right" className='extraClass1' type="dark" effect="solid" />


              {/* {OverviewList && OverviewList.length > 0 ?
                OverviewList.map((item, index) =>
                  <div title={item.name + " Insight"} keys={index} onClick={() => handleSidebarClick(true, item, index + 1, item.mean !== "NaN")}
                    className={(index + 1) === IndexNo ? "cursor-pointer flex items-center bg-blue-50  text-blue-500 border-r-2 border-blue-500" :
                      "cursor-pointer flex items-center hover:bg-blue-50  text-gray-500 "}>
                    <div className="flex items-center justify-center w-16 h-12 px-4 py-4">
                      <span className="p-2 material-symbols-outlined">{
                        ((index + 1) === IndexNo) ? "dashboard" : "dashboard"
                      }</span>
                    </div>
                    {showSidebar ? <span class=" text-sm text-[#3D405B]/70 font-medium ">{item.name + " Insight"}</span> : null}

                  </div>


                ) : null} */}




              {/* <div data-tip="Advance Insights" onClick={() => handleSidebarExtra(2)}
                className={showAdvanceInsight ? "cursor-pointer flex items-center bg-blue-50   text-blue-500 border-r-2 border-blue-500" :
                  "cursor-pointer flex items-center hover:bg-blue-50   text-gray-500"}>

                <div className="flex items-center justify-center w-16 h-12 px-4 py-4">
                  <span className="p-2 material-symbols-outlined">insert_chart</span>
                </div>
                <span class=" text-sm text-[#3D405B]/70 font-medium ">Advance Insights</span>

              </div>
              <ReactTooltip place="right" className='extraClass2' type="dark" effect="solid" /> */}







              {/* <div title={"Advance Insights"} onClick={()=>{ this.onSubMenu(!showSubMenu); handleSidebarExtra(2);  }} 
    className={showAdvanceInsight?"cursor-pointer flex items-center bg-blue-50   text-blue-500 border-r-2 border-blue-500":
    "cursor-pointer flex items-center hover:bg-blue-50   text-gray-500 "}>

        <div className="flex items-center justify-center w-16 h-12 px-4 py-4">
          <span className="p-2 material-symbols-outlined">engineering</span>
        </div>
        <span className="w-full text-xs font-medium text-gray-500">Advance Insights</span>
        <span className="mr-4 text-gray-500 material-symbols-outlined" style={{fontSize: '18px'}}>expand_more</span>
      </div>



      {showSubMenu?
      <>
      <div onClick={()=>handleSidebarExtra(2)}  className="ml-16 text-xs text-gray-500 cursor-pointer">
        <span className={showAdvanceInsight?"block py-1.5 text-blue-500":"block py-1.5 hover:text-blue-500  text-gray-500 font-medium"}>CrossTab</span>
      </div>
      <div onClick={()=>handleSidebarExtra(2)}  className="ml-16 text-xs text-gray-500 cursor-pointer">
        <span className={showAdvanceInsight?"block py-1.5 text-blue-500":"block py-1.5 hover:text-blue-500  text-gray-500 font-medium"}>Correlation</span>
      </div>
      </>
      :null} */}



              {/* <div>
      <div className="flex items-center">
        <div className="flex items-center justify-center w-16 h-12 px-4 py-4 text-blue-500">
          <span className="p-2 text-gray-500 rounded material-symbols-outlined hover:bg-blue-50">event_available</span>
        </div>
        <span className="w-full text-xs font-medium text-gray-500">Actions Plan</span>
        <span className="mr-4 text-gray-500 material-symbols-outlined" style={{fontSize: '18px'}}>expand_more</span>
      </div>
      <div className="ml-16 text-xs text-gray-500 ">
        <span className="block py-1.5 hover:text-blue-500">Heading 1</span>
        <span className="block py-1.5 hover:text-blue-500  text-blue-500 font-medium">Heading 2</span>
        <span className="block py-1.5 hover:text-blue-500">Heading 3</span>
        <span className="block py-1.5 hover:text-blue-500">Heading 4</span>
      </div>
    </div> */}



{/* 
              <div data-tip="Text Analysis" onClick={() => handleSidebarExtra(1)}
                className={showTextAnalysis ? "cursor-pointer flex items-center bg-blue-50   text-blue-500 border-r-2 border-blue-500" :
                  "cursor-pointer flex items-center hover:bg-blue-50   text-gray-500"}>

                <div className="flex items-center justify-center w-16 h-12 px-4 py-4">
                  <span className="p-2 material-symbols-outlined">query_stats</span>
                </div>
                <span class=" text-sm text-[#3D405B]/70 font-medium ">Text Analysis</span>
              </div>
              <ReactTooltip place="right" className='extraClass3' type="dark" effect="solid" /> */}




              <div data-tip="Sense Insights" onClick={() => handleSidebarExtra(3)}
                className={showRiskProfile ? "cursor-pointer flex items-center bg-blue-50   text-blue-500 border-r-2 border-blue-500" :
                  "cursor-pointer flex items-center hover:bg-blue-50   text-gray-500"}>

                <div className="flex items-center justify-center w-16 h-12 px-4 py-4">
                  <span className="p-2 material-symbols-outlined">emergency</span>
                </div>
                <span class=" text-sm text-[#3D405B]/70 font-medium ">Sense Insight</span>
              </div>
              <ReactTooltip place="right" className='extraClass4' type="dark" effect="solid" />

              {/* 
              <div  data-tip="Sense Action Plan" onClick={() => handleSidebarExtra(4)}
                className={showRiskActionPlan ? "cursor-pointer flex items-center bg-blue-50   text-blue-500 border-r-2 border-blue-500" :
                  "cursor-pointer flex items-center hover:bg-blue-50   text-gray-500"}>

                <div className="flex items-center justify-center w-16 h-12 px-4 py-4">
                  <span className="p-2 material-symbols-outlined">event_available</span>
                </div>
                <span class=" text-sm text-[#3D405B]/70 font-medium ">Sense Action Plan</span>
              </div>
              <ReactTooltip place="right" className='extraClass4' type="dark" effect="solid"/> */}



              <div data-tip="1:1 Conversation" onClick={() => handleSidebarExtra(5)}
                className={showOneToOneConversation ? "cursor-pointer flex items-center bg-blue-50   text-blue-500 border-r-2 border-blue-500" :
                  "cursor-pointer flex items-center hover:bg-blue-50   text-gray-500"}>

                <div className="flex items-center justify-center w-16 h-12 px-4 py-4">
                  <span className="p-2 material-symbols-outlined">record_voice_over</span>
                </div>
                <span className="text-xs ">1:1 Conversation</span>
              </div>
              <ReactTooltip place="right" className='extraClass5' type="dark" effect="solid" />



            </div>
            <ReactTooltip
              place="right"
              className="extraClass4"
              type="dark"
              effect="solid"
            />

            

            <div
              data-tip="1:1 Conversation"
              onClick={() => handleSidebarExtra(5)}
              className={
                showOneToOneConversation
                ? "flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer border-l-4 border-[#2196F3] bg-[#2196F3]/10"
                  : "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer"
              }
            >
              <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
                <span className="material-symbols-outlined  p-2">
                  record_voice_over
                </span>
              </div>
              <span className="text-xs ">1:1 Conversation</span>
            </div>
            <ReactTooltip
              place="right"
              className="extraClass5"
              type="dark"
              effect="solid"
            />
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(Sidebar);
