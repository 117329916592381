import React, { Component } from 'react';
import { connect } from 'react-redux';


class TempEmpStrength extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      crrSelectedEmp: {}
    }
  }

  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { get360Overview } = this.props;
    this.setState({ FilterValues: finalFilter });
    get360Overview(finalFilter, "", "", false);

  }

  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { get360Overview } = this.props;

      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = value.emp_id;
      }
      let { FilterValues } = this.state;
      get360Overview(FilterValues, "", "", false, emp_id)

    })
  }


  render() {


    let { getTemp360EmpStrengthData } = this.props;

    const barWidthColor=(element,max)=>{
      return (<span  className={"w-5/6 h-4 bg-green-500"} />)
    }
    return ( 
        <section className="p-6 px-6 flex flex-col justify-between page-break">
          <div className="space-y-6 flex flex-col  ">
            <h1 className="text-2xl text-slate-800 font-semibold border-b-2 border-sky-500 pb-4 capitalize">
              CliftonStrength Top 5 Theme
            </h1>

            <div className="text-slate-800 space-y-4 text-sm">
              <p>
                Unleash Your Infinite Potential: Your Strongest CliftonStrength top 5 theme are:-
              </p>
            </div>

                <div className="w-full   ">

                <div className=" divide-y mb-6 ">
                <div className="w-full  py-4 flex">
                {/* <div className=" flex items-center justify-center bg-slate-100 w-10 flex-col  ">
                  <p className="text-base" 
                style={{writingMode: 'vertical-lr'}}></p></div> */}

                    <div className="space-y-2 w-full">
                    {getTemp360EmpStrengthData && getTemp360EmpStrengthData.length>0?
                        getTemp360EmpStrengthData.map((element,index)=>
                            <div className="flex items-center">    
                              {/* <p className="text-base font-normal w-36  ">{(index+1).toString()+". "+(element.strength)}</p>    */}
                              <span style={{
                                background:'#8BC34A',
                                color:'#FFFFFF'
                              }} className={(index===0?"w-full ":"w-"+(6-index).toString()+"/6 ")+" bg-green-500 mx-2 p-3 text-lg"} >
                              {(index+1).toString()+". "+(element.strength)}</span>                       
                            </div>
                        )
                    :null}


                    </div>
                </div>
                </div>
            </div>


    


          </div>
        </section>

      
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(TempEmpStrength);
