export const generalConstants = {

    GET_SURVEY_LIST_REQUEST:'GET_SURVEY_LIST_REQUEST',
    GET_SURVEY_LIST_SUCCESS: 'GET_SURVEY_LIST_SUCCESS',
    GET_SURVEY_LIST_FAILURE: 'GET_SURVEY_LIST_FAILURE',

    GET_DASHBOARD_CARD_REQUEST:'GET_DASHBOARD_CARD_REQUEST',
    GET_DASHBOARD_CARD_SUCCESS: 'GET_DASHBOARD_CARD_SUCCESS',
    GET_DASHBOARD_CARD_FAILURE: 'GET_DASHBOARD_CARD_FAILURE',

    GET_QUESTION_DIST_REQUEST:'GET_QUESTION_DIST_REQUEST',
    GET_QUESTION_DIST_SUCCESS: 'GET_QUESTION_DIST_SUCCESS',
    GET_QUESTION_DIST_FAILURE: 'GET_QUESTION_DIST_FAILURE',

    GET_QUESTION_DIST_OVERALL_REQUEST:'GET_QUESTION_DIST_OVERALL_REQUEST',
    GET_QUESTION_DIST_OVERALL_SUCCESS: 'GET_QUESTION_DIST_OVERALL_SUCCESS',
    GET_QUESTION_DIST_OVERALL_FAILURE: 'GET_QUESTION_DIST_OVERALL_FAILURE',

    GET_MEAN_SCORE_REQUEST: 'GET_MEAN_SCORE_REQUEST',
    GET_MEAN_SCORE_SUCCESS: 'GET_MEAN_SCORE_SUCCESS',
    GET_MEAN_SCORE_FAILURE: 'GET_MEAN_SCORE_FAILURE',

    GET_MEAN_SCORE_QWISE_REQUEST: 'GET_MEAN_SCORE_QWISE_REQUEST',
    GET_MEAN_SCORE_QWISE_SUCCESS: 'GET_MEAN_SCORE_QWISE_SUCCESS',
    GET_MEAN_SCORE_QWISE_FAILURE: 'GET_MEAN_SCORE_QWISE_FAILURE',
    
    GET_QUES_NPS_RESULT_REQUEST: 'GET_QUES_NPS_RESULT_REQUEST',
    GET_QUES_NPS_RESULT_SUCCESS: 'GET_QUES_NPS_RESULT_SUCCESS',
    GET_QUES_NPS_RESULT_FAILURE: 'GET_QUES_NPS_RESULT_FAILURE',

    GET_QUES_OTHERS_RESULT_REQUEST: 'GET_QUES_OTHERS_RESULT_REQUEST',
    GET_QUES_OTHERS_RESULT_SUCCESS: 'GET_QUES_OTHERS_RESULT_SUCCESS',
    GET_QUES_OTHERS_RESULT_FAILURE: 'GET_QUES_OTHERS_RESULT_FAILURE',

    GET_QUES_FILTER_OTHERS_RESULT_REQUEST: 'GET_QUES_FILTER_OTHERS_RESULT_REQUEST',
    GET_QUES_FILTER_OTHERS_RESULT_SUCCESS: 'GET_QUES_FILTER_OTHERS_RESULT_SUCCESS',
    GET_QUES_FILTER_OTHERS_RESULT_FAILURE: 'GET_QUES_FILTER_OTHERS_RESULT_FAILURE',

    GET_QUES_TYPE1_RESULT_REQUEST: 'GET_QUES_TYPE1_RESULT_REQUEST',
    GET_QUES_TYPE1_RESULT_SUCCESS: 'GET_QUES_TYPE1_RESULT_SUCCESS',
    GET_QUES_TYPE1_RESULT_FAILURE: 'GET_QUES_TYPE1_RESULT_FAILURE',

    GET_QUES_2_FILTER_OTHERS_RESULT_REQUEST: 'GET_QUES_2_FILTER_OTHERS_RESULT_REQUEST',
    GET_QUES_2_FILTER_OTHERS_RESULT_SUCCESS: 'GET_QUES_2_FILTER_OTHERS_RESULT_SUCCESS',
    GET_QUES_2_FILTER_OTHERS_RESULT_FAILURE: 'GET_QUES_2_FILTER_OTHERS_RESULT_FAILURE',

    GET_QUES_BRANCHCODE_TYPE1_RESULT_REQUEST: 'GET_QUES_BRANCHCODE_TYPE1_RESULT_REQUEST',
    GET_QUES_BRANCHCODE_TYPE1_RESULT_SUCCESS: 'GET_QUES_BRANCHCODE_TYPE1_RESULT_SUCCESS',
    GET_QUES_BRANCHCODE_TYPE1_RESULT_FAILURE: 'GET_QUES_BRANCHCODE_TYPE1_RESULT_FAILURE',


    GET_QUES_BRANCHCODE_TYPE1_RESULT_2_REQUEST: 'GET_QUES_BRANCHCODE_TYPE1_RESULT_2_REQUEST',
    GET_QUES_BRANCHCODE_TYPE1_RESULT_2_SUCCESS: 'GET_QUES_BRANCHCODE_TYPE1_RESULT_2_SUCCESS',
    GET_QUES_BRANCHCODE_TYPE1_RESULT_2_FAILURE: 'GET_QUES_BRANCHCODE_TYPE1_RESULT_2_FAILURE',


    GET_INDEX_BY_FILTER_DMG_X_REQUEST: 'GET_INDEX_BY_FILTER_DMG_X_REQUEST',
    GET_INDEX_BY_FILTER_DMG_X_SUCCESS: ' GET_INDEX_BY_FILTER_DMG_X_SUCCESS',
    GET_INDEX_BY_FILTER_DMG_X_FAILURE: ' GET_INDEX_BY_FILTER_DMG_X_FAILURE',

    GET_QUES_MULTI_RESULT_REQUEST: 'GET_QUES_MULTI_RESULT_REQUEST',
    GET_QUES_MULTI_RESULT_SUCCESS: ' GET_QUES_MULTI_RESULT_SUCCESS',
    GET_QUES_MULTI_RESULT_FAILURE: ' GET_QUES_MULTI_RESULT_FAILURE',

    
    GET_QUES_LIVE_RES_REQUEST: 'GET_QUES_LIVE_RES_REQUEST',
    GET_QUES_LIVE_RES_SUCCESS: ' GET_QUES_LIVE_RES_SUCCESS',
    GET_QUES_LIVE_RES_FAILURE: ' GET_QUES_LIVE_RES_FAILURE',


    SURVEY_QUESTIONS_GETALL_REQUEST:'SURVEY_QUESTIONS_GETALL_REQUEST',
    SURVEY_QUESTIONS_GETALL_SUCCESS: 'SURVEY_QUESTIONS_GETALL_SUCCESS',
    SURVEY_QUESTIONS_GETALL_FAILURE: 'SURVEY_QUESTIONS_GETALL_FAILURE',

    GET_QUESTION_DMG_RESULT_REQUEST:'GET_QUESTION_DMG_RESULT_REQUEST',
    GET_QUESTION_DMG_RESULT_SUCCESS: 'GET_QUESTION_DMG_RESULT_SUCCESS',
    GET_QUESTION_DMG_RESULT_FAILURE: 'GET_QUESTION_DMG_RESULT_FAILURE',

    GET_QUES_AVG_SCORE_REQUEST:'GET_QUES_AVG_SCORE_REQUEST',
    GET_QUES_AVG_SCORE_SUCCESS: 'GET_QUES_AVG_SCORE_SUCCESS',
    GET_QUES_AVG_SCORE_FAILURE: 'GET_QUES_AVG_SCORE_FAILURE',
};

