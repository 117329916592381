import React, { Component } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";

class WordDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEmpPage: 1,
      EmpIndexA: 0,
      EmpIndexB: 4,
      pageCount: 10,

      sentimentType: "All",
    };
  }

  componentDidMount() {}

  SelectEmpPage = (val) => {
    this.setState({ currentEmpPage: val });
  };

  prevEmpPage = () => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (EmpIndexA > 0) {
      this.setState({ EmpIndexA: EmpIndexA - 1, EmpIndexB: EmpIndexB - 1 });
    }
  };
  nextEmpPage = (IndexListIndex) => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (
      IndexListIndex &&
      IndexListIndex.length &&
      EmpIndexB < IndexListIndex.length
    ) {
      this.setState({ EmpIndexA: EmpIndexA + 1, EmpIndexB: EmpIndexB + 1 });
    }
  };

  handleComments = (sentiment) => {
    this.setState({ sentimentType: sentiment });
  };

  render() {
    let {
      EmpFilterData2,
      GetParameterLabel,
      getClusterSentencesData,
      wordCloudTextData,
      getClusterSaveKeyWord,
      loading,
    } = this.props;
    let { currentEmpPage, EmpIndexA, EmpIndexB, pageCount, sentimentType } =
      this.state;

    let textData = [];

    console.log("getClusterSentencesData1->", getClusterSentencesData);

    if (getClusterSentencesData && getClusterSentencesData.length > 0) {
      getClusterSentencesData.forEach((item) => {
        if (
          sentimentType === "All"
            ? true
            : item.sentiment === sentimentType
            ? true
            : false
        ) {
          if (
            item.sentence.includes("answer1:") ||
            item.sentence.includes("answer2:")
          ) {
            textData.push({
              ...item,
              ans1: item.sentence.substring(
                item.sentence.lastIndexOf("answer1:") + 8,
                item.sentence.lastIndexOf("answer2:") - 1
              ),
              ans2: item.sentence.substring(
                item.sentence.lastIndexOf("answer2:") + 8
              ),
            });
          } else {
            textData.push({
              ...item,
              ans3: item.sentence,
            });
          }
        }
      });
    }

    function getCount(sen, list2) {
      // let textData = []
      let count = 0;
      if (list2 && list2.length > 0) {
        list2.forEach((item) => {
          if (sen === "All" ? true : item.sentiment === sen ? true : false) {
            if (
              item.sentence.includes("answer1:") ||
              item.sentence.includes("answer2:")
            ) {
              count++;
              // textData.push({
              //   ...item,
              //   ans1: item.sentence.substring(
              //     item.sentence.lastIndexOf("answer1:") + 8,
              //     item.sentence.lastIndexOf("answer2:") - 1
              //   ),
              //   ans2: item.sentence.substring(
              //     item.sentence.lastIndexOf("answer2:") + 8
              //   ),
              // });
            } else {
              count++;
              // textData.push({
              //   ...item,
              //   ans3: item.sentence,
              // });
            }
          }
        });
      }
      return count;
    }

    //console.log('getClusterSaveKeyWord',getClusterSaveKeyWord)

    let KEYWORD = "";
    if (getClusterSaveKeyWord) {
      KEYWORD = getClusterSaveKeyWord.toLowerCase();
    }

    //--------------------------------------------------------------------------------

    let IndexList = [];
    let IndexListIndex = [];
    let indexA = 0;
    let indexB = 0;

    if (textData && textData.length > 0) {
      textData.forEach((item, index) => {
        if (index % pageCount === 0) {
          IndexList.push(index);
        }
        if (index === textData.length - 1) {
          IndexList.push(index);
        }
      });
      //console.log('IndexList: ',IndexList)

      if (IndexList && IndexList.length > 0) {
        IndexList.forEach((item, index) => {
          if (index !== IndexList.length - 1) {
            IndexListIndex.push(index + 1);
          }
        });
      }
      //console.log('textData====================>: ',textData)

      if (IndexListIndex && IndexListIndex.length > 0) {
        IndexListIndex.forEach((item, index) => {
          if (item === currentEmpPage) {
            if (index < IndexList.length - 2) {
              indexA = IndexList[index];
              indexB = IndexList[index] + (pageCount - 1);
            } else {
              indexA = IndexList[index];
              indexB = IndexList[IndexList.length - 1];
            }
          }
        });
      }
    }
    //--------------------------------------------------------------------------------
    //console.log('----------------textData:--------------- ',textData)

    let List = [];
    let ExporttextData = [];
    if (textData && textData.length > 0) {
      textData.forEach((item, index) => {
        if (index === 0) {
          List = Object.keys(item);
        }
        let temp = {};
        if (List && List.length > 0) {
          List.forEach((ele) => {
            // let getIndex = EmpFilterData2 && EmpFilterData2.length>0?EmpFilterData2.findIndex(prev=>prev.name === ele):-1;
            // let validDMG = (ele==="Age_Range" || ele==="Business_Unit" || ele==="Department" || ele==="Gender") || true;
            // if(ele!=="ans3" && ele!=="answerText" && ele!="ManagerPID" && getIndex!==-1 && validDMG){
            //   temp[ele] = item[ele];
            // }
            if (EmpFilterData2 && EmpFilterData2.length > 0) {
              EmpFilterData2.forEach((ele) => {
                if (item && item[ele.name]) {
                  temp[GetParameterLabel(ele.name)] =
                    item && item[ele.name] ? item[ele.name] : "NaN";
                }
              });
            }
          });
        }
        temp["answerText"] =
          item && item["answerText"]
            ? item["answerText"]
                .replace("answer1:", "")
                .replace("answer2:", ", ")
            : "";
        ExporttextData.push(temp);
      });
    }
    console.log("----------------textData:--------------- ", textData);

    return (
      <>
        {/* box right */}
        <div className="transition duration-150 bg-white border rounded-lg cursor-pointer hover:shadow-lg 2xl:col-span-3 lg:col-span-2 ">
          <div className="flex items-center justify-between p-4 px-6 text-gray-500">
            <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap capitalize  ">
              {KEYWORD ? KEYWORD : "All Feedbacks"}
            </h1>

            {ExporttextData && ExporttextData.length > 0 ? (
              <CSVLink data={ExporttextData} filename={"TextData.csv"}>
                <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                    <path d="M10 12l4 4m0 -4l-4 4" />
                  </svg>
                  <span> {"Download Excel"}</span>
                </button>
              </CSVLink>
            ) : 
            (
              
                <button className="cursor-not-allowed   bg-white p-2 px-4 rounded-md border text-[#3D405B] group    transition-all duration-150  text-sm flex items-center font-medium space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=" transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                    <path d="M10 12l4 4m0 -4l-4 4" />
                  </svg>
                  <span> {"Download Excel"}</span>
                </button>
             
            )}
          </div>
          {/*sub nav bar 1 */}
          <div className="items-center justify-between px-6 border-b  md:flex">
            <div className="flex space-x-4 overflow-hidden overflow-x-auto text-sm font-medium text-[#3D405B] capitalize whitespace-nowrap w-full">
              <span
                onClick={() => this.handleComments("All")}
                className={
                  sentimentType === "All"
                    ? " pb-2 cursor-pointer  font-normal flex items-center  border-b-2 border-blue-500  text-blue-500"
                    : " pb-2 cursor-pointer  font-normal flex items-center  border-b-2 border-transparent  text-[#3D405B]"
                }
              >
                <p>All comments</p>
                {sentimentType === "All" ? (
                  <b className="font-medium">
                    {" "}
                    {getCount("All", getClusterSentencesData)}{" "}
                  </b>
                ) : (
                  <b className="font-medium">
                    {" "}
                    {getCount("All", getClusterSentencesData)}{" "}
                  </b>
                )}
              </span>

              <span
                onClick={() => this.handleComments("Positive")}
                className={
                  sentimentType === "Positive"
                    ? " pb-2 cursor-pointer  font-normal flex items-center  border-b-2 border-green-500  text-green-500"
                    : " pb-2 cursor-pointer  font-normal flex items-center  border-b-2 border-transparent  text-[#3D405B]"
                }
              >
                <p>Positive</p>
                {sentimentType === "Positive" ? (
                  <b className="font-medium">
                    {" "}
                    {getCount("Positive", getClusterSentencesData)}{" "}
                  </b>
                ) : (
                  <b className=" font-medium">
                    {" "}
                    {getCount("Positive", getClusterSentencesData)}{" "}
                  </b>
                )}
              </span>

              <span
                onClick={() => this.handleComments("Negative")}
                className={
                  sentimentType === "Negative"
                    ? " pb-2 cursor-pointer  font-normal flex items-center  border-b-2 border-red-500  text-red-500"
                    : " pb-2 cursor-pointer  font-normal flex items-center  border-b-2 border-transparent  text-[#3D405B]"
                }
              >
                <p>Negative</p>
                {sentimentType === "Negative" ? (
                  <b className="font-medium">
                    {" "}
                    {getCount("Negative", getClusterSentencesData)}{" "}
                  </b>
                ) : (
                  <b className=" font-medium">
                    {" "}
                    {getCount("Negative", getClusterSentencesData)}{" "}
                  </b>
                )}
              </span>

              <span
                onClick={() => this.handleComments("Neutral")}
                className={
                  sentimentType === "Neutral"
                    ? " pb-2 cursor-pointer  font-normal flex items-center  border-b-2 border-yellow-500  text-yellow-500"
                    : " pb-2 cursor-pointer  font-normal flex items-center  border-b-2 border-transparent  text-[#3D405B]"
                }
              >
                <p>Neutral</p>
                {sentimentType === "Neutral" ? (
                  <b className="font-medium">
                    {" "}
                    {getCount("Negative", getClusterSentencesData)}{" "}
                  </b>
                ) : (
                  <b className=" font-medium">
                    {" "}
                    {getCount("Negative", getClusterSentencesData)}{" "}
                  </b>
                )}
              </span>
            </div>
          </div>
          {/* end */}
          {/* chart */}
          <div className="h-[550px] p-6 py-2 divide-y overflow-y-auto customscroll4">
            {textData && textData.length > 0 && textData.length >= 5 ? (
              textData.map((item, index) =>
                index >= indexA && index <= indexB ? (
                  <div className="flex items-start py-4 space-x-4 capitalize">
                    <span className="p-1 rounded-full bg-yellow-400  mt-1.5" />
                    <div className="space-y-2">
                      {item.ans1 ? (
                        <h1 className="text-base font-medium text-[#3D405B] ">
                          {"A) " + item.ans1}
                        </h1>
                      ) : null}
                      {item.ans2 ? (
                        <h1 className="text-base font-medium text-[#3D405B] ">
                          {"B) " + item.ans2}
                        </h1>
                      ) : null}
                      {item.ans3 ? (
                        <h1 className="text-base font-medium text-[#3D405B] ">
                          {item.ans3}
                        </h1>
                      ) : null}

                      <ul className="flex items-center space-x-4 text-sm font-medium">
                        {item && item.Department ? (
                          <li className="text-sm text-[#3D405B]/70 ">
                            {item.Department + ", "}
                          </li>
                        ) : null}
                        {item && item.Gender ? (
                          <li className="text-sm text-[#3D405B]/70">
                            {item.Gender + ", "}
                          </li>
                        ) : null}
                        {item && item.Country ? (
                          <li className="text-sm text-[#3D405B]/70">
                            {item.Country}
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                ) : null
              )
            ) : (
              <>
                {!loading ? (
                  <div className="h-[550px] flex items-center justify-center p-6 py-2 divide-y overflow-y-auto customscroll4">
                    <h2 className="text-xl font-medium text-[#3D405B]/50 capitalize">Data not available!</h2>
                  </div>
                ) : null}
              </>
            )}
            <h1 className="text-sm text-[#3d405B]/70 py-4">
              * We are not showing data where employee count is less than 5
            </h1>
          </div>
          <div className="flex md:flex-row flex-col justify-center items-center md:gap-6 gap-4 text-[#3D405B] text-sm font-medium p-6 border-t">
            <p className="">Pages</p>
            <div className="flex flex-wrap border divide-x rounded-md ">
              <button
                onClick={() => this.prevEmpPage()}
                className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition"
              >
                Prev
              </button>

              {IndexListIndex && IndexListIndex.length > 0
                ? IndexListIndex.map((item, index) =>
                    index >= EmpIndexA && index <= EmpIndexB ? (
                      item === currentEmpPage ? (
                        <button
                          onClick={() => this.SelectEmpPage(item)}
                          className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] "
                        >
                          {item}
                        </button>
                      ) : (
                        <button
                          onClick={() => this.SelectEmpPage(item)}
                          className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition "
                        >
                          {item}
                        </button>
                      )
                    ) : null
                  )
                : null}

              <button
                onClick={() => this.nextEmpPage(IndexListIndex)}
                className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition"
              >
                Next
              </button>
            </div>
            <p>2 of 10</p>
          </div>
          {/* end */}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(WordDetails);
