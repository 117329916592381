import React, { useState } from "react";
// import Sidebar from "./Components/Sidebar/Sidebar";
import Sidebar from "./sidebar/sidebar";
export default function NineBox(props, history) {
  // console.log('locationlocationlocationlocationlocation', props);

  let { item, name, stage } = props;
  const [isAddGoal, setIsAddGoal] = useState(false);
  const [isAddTask, setIsAddTask] = useState(false);
  // State to track the active tab
  const [activeTab, setActiveTab] = useState("goals");
  const [activeTabOne, setActiveTabOne] = useState("Review");

  const handleTabClick = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTab(tab);
  };

  const handleTabClickTop = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTabOne(tab);
  };

  const handleAddGoal = () => {
    setIsAddGoal(!isAddGoal);
  };
  const handleCloseAddGoal = () => {
    setIsAddGoal(false);
  };

  const handleAddTask = () => {
    setIsAddTask(!isAddTask);
  };
  const handleCloseAddTask = () => {
    setIsAddTask(false);
  };

  return (
    <>
      <div className="h-screen overflow-hidden bg-gray-50">
        <header
          className=" w-full flex justify-between items-center  md:px-4 px-4 bg-white  border-b sticky top-0 z-20"
          style={{ height: "4rem" }}
        >
          <div className="flex itmes-center  space-x-4">
            <button className="text-blue-500 bg-[#edf6fe] rounded-full cursor-pointer hover:bg-blue-100 trasition duration-150 p-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-arrow-left  "
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#2196f3"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1={5} y1={12} x2={19} y2={12} />
                <line x1={5} y1={12} x2={11} y2={18} />
                <line x1={5} y1={12} x2={11} y2={6} />
              </svg>
            </button>
            <div className="flex items-center space-x-4 ">
              <a href="#/app/overview">
                <img
                  src="/img/plus_ex_logo.svg"
                  className="w-24 object-cover"
                />
              </a>
              <span className="border py-1 px-2 text-gray-500 text-sm rounded-md">
                ABC Pvt Ltd
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between py-3 select-none">
            <div className="lg:flex hidden border-r pr-3 mx-2 items-center " />
            <div className="relative flex items-center ">
              <button className="relative block h-10 w-10 ml-2 rounded-full overflow-hidden  focus:outline-none bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] ">
                <span className="text-sm font-semibold text-white tracking-widest">
                  TE
                </span>
              </button>
              <div className="ml-2 lg:flex items-center hidden cursor-pointer relative">
                <h1 className="text-base text-slate-800 font-medium select-none">
                  Temp005
                </h1>
                <span className="material-symbols-outlined text-slate-600 text-[1.8rem]">
                  arrow_drop_down
                </span>
              </div>
            </div>
          </div>
        </header>
        <div className="px-4  flex  justify-between items-center bg-white border-b py-2.5">
          <div className="flex items-center space-x-4">
            <h2 className="xl:text-lg text-base text-[#3D405B] truncate font-medium">
              {" "}
              Appraisal HR - Q3
            </h2>

            <div class="flex items-center px-4 py-2 space-x-2 rounded-md w-fit bg-green-50 text-sm">
              {" "}
              <span class="font-medium text-green-500">Completed</span>
            </div>
          </div>
          <div>
            <span class="text-[#212121]/80">01-Jan-2023 - 01-Apr-2023</span>
          </div>
        </div>

        <div className="flex w-full" style={{ height: "calc(100% - 6.5rem)" }}>

          <Sidebar />

          <main className="flex-1 md:p-6 p-4 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto ">
            <div className="space-y-4">
              <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
                <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]  ">
                  Nine-Box Matrix
                </h1>
                <div className="md:flex  hidden items-center text-sm text-[#3D405B]">
                  <span className="mx-2 material-symbols-rounded">home</span>
                  <span>/</span>
                  <span className="px-2">Employee Engagement</span>
                  <span>/</span>
                  <span className="px-2">Dashboard</span>
                  <span>/</span>
                  <span className="px-2 font-semibold">Manager Score Card</span>
                </div>
              </div>


              <div className=" md:flex  justify-between items-center ">
                <div className="flex gap-2 flex-wrap">
                  <div className="cursor-pointer bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] flex justify-between px-4 items-center relative py-2 rounded-md w-40 ">
                    <div className="flex space-x-2 items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-filter"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#fff"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
                      </svg>
                      <span className=" text-sm text-white ">Filters</span>
                    </div>
                    <span className=" bg-white  rounded-full text-[#2196F3] text-xs w-6 h-6 flex items-center justify-center font-semibold">
                      2
                    </span>
                  </div>
                  <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                    <span className=" text-sm text-[#3D405B]/70 font-medium ">
                      Location
                    </span>
                    <span className="material-symbols-outlined ">
                      arrow_drop_down
                    </span>
                  </div>
                  <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                    <span className=" text-sm text-[#3D405B]/70 font-medium ">
                      Department
                    </span>
                    <span className="material-symbols-outlined ">
                      arrow_drop_down
                    </span>
                  </div>
                </div>

              </div>




              <div className="bg-white p-6 space-y-6 border rounded-md flex w-full ">

                <div className=" text-neutral-800 text-2xl font-medium capitalize w-12   flex justify-center relative items-center ">
                  <h1 className=" -rotate-90   absolute  translate-y-auto  origin-left-top whitespace-nowrap ">Potential Assessment</h1>

                </div>

                <div className="flex flex-col gap-4 w-full">
                  <div className="flex items-center ">
                    <div className="w-12 relative flex justify-center bg-red-400">
                      <span className="text-lg font-medium text-[#212121]/70   -rotate-90   absolute  translate-y-auto  origin-left-top ">High</span>
                    </div>
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-4 w-full">

                      <div className=" px-10 py-10 bg-blue-200 items-center justify-center gap-3 inline-flex">
                        <div className=" flex-col justify-center items-center gap-3 inline-flex  ">
                          <div className="self-stretch h-32 flex-col justify-center items-center gap-3 flex">
                            <div className=" text-center text-neutral-800 text-xl font-medium capitalize">Rough Dimond</div>
                            <div className=" text-center text-neutral-800 text-base font-medium capitalize">Low Performance / High Potential</div>
                            <div className=" text-center"><span className="text-neutral-800 text-2xl font-medium capitalize">21</span><span className="text-neutral-800 text-2xl font-medium capitalize"> Employee(s)</span></div>
                          </div>
                        </div>
                      </div>
                      <div className=" px-10 py-10 bg-[#DCEDC8] items-center justify-center gap-3 inline-flex">
                        <div className=" flex-col justify-center items-center gap-3 inline-flex  ">
                          <div className="self-stretch h-32 flex-col justify-center items-center gap-3 flex">
                            <div className=" text-center text-neutral-800 text-xl font-medium capitalize">Future Star</div>
                            <div className=" text-center text-neutral-800 text-base font-medium capitalize">Moderate Performance / High Potential</div>
                            <div className=" text-center"><span className="text-neutral-800 text-2xl font-medium capitalize">21</span><span className="text-neutral-800 text-2xl font-medium capitalize"> Employee(s)</span></div>
                          </div>
                        </div>
                      </div>
                      <div className=" px-10 py-10 bg-[#C8E6C9] items-center justify-center gap-3 inline-flex">
                        <div className=" flex-col justify-center items-center gap-3 inline-flex  ">
                          <div className="self-stretch h-32 flex-col justify-center items-center gap-3 flex">
                            <div className=" text-center text-neutral-800 text-xl font-medium capitalize">Consistent Star</div>
                            <div className=" text-center text-neutral-800 text-base font-medium capitalize">Outstanding Performance / High Potential</div>
                            <div className=" text-center"><span className="text-neutral-800 text-2xl font-medium capitalize">21</span><span className="text-neutral-800 text-2xl font-medium capitalize"> Employee(s)</span></div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>

                  <div className="flex items-center ">
                    <div className="w-12 relative flex justify-center bg-red-400">
                      <span className="text-lg font-medium text-[#212121]/70   -rotate-90   absolute  translate-y-auto  origin-left-top ">Moderate</span>
                    </div>

                    <div className="grid md:grid-cols-3 grid-cols-1 gap-4 w-full">

                      <div className=" px-10 py-10 bg-[#FFECB3] items-center justify-center gap-3 inline-flex">
                        <div className=" flex-col justify-center items-center gap-3 inline-flex  ">
                          <div className="self-stretch h-32 flex-col justify-center items-center gap-3 flex">
                            <div className=" text-center text-neutral-800 text-xl font-medium capitalize">Inconsistent Player</div>
                            <div className=" text-center text-neutral-800 text-base font-medium capitalize">Low Performance / Moderate Potential</div>
                            <div className=" text-center"><span className="text-neutral-800 text-2xl font-medium capitalize">21</span><span className="text-neutral-800 text-2xl font-medium capitalize"> Employee(s)</span></div>
                          </div>
                        </div>
                      </div>
                      <div className=" px-10 py-10 bg-[#F0F4C3] items-center justify-center gap-3 inline-flex">
                        <div className=" flex-col justify-center items-center gap-3 inline-flex  ">
                          <div className="self-stretch h-32 flex-col justify-center items-center gap-3 flex">
                            <div className=" text-center text-neutral-800 text-xl font-medium capitalize">Key Player</div>
                            <div className=" text-center text-neutral-800 text-base font-medium capitalize">Moderate Performance / Moderate Potential</div>
                            <div className=" text-center"><span className="text-neutral-800 text-2xl font-medium capitalize">21</span><span className="text-neutral-800 text-2xl font-medium capitalize"> Employee(s)</span></div>
                          </div>
                        </div>
                      </div>
                      <div className=" px-10 py-10 bg-[#DCEDC8] items-center justify-center gap-3 inline-flex">
                        <div className=" flex-col justify-center items-center gap-3 inline-flex  ">
                          <div className="self-stretch h-32 flex-col justify-center items-center gap-3 flex">
                            <div className=" text-center text-neutral-800 text-xl font-medium capitalize">Current Star</div>
                            <div className=" text-center text-neutral-800 text-base font-medium capitalize">Outstanding Performance / Moderate Potential</div>
                            <div className=" text-center"><span className="text-neutral-800 text-2xl font-medium capitalize">21</span><span className="text-neutral-800 text-2xl font-medium capitalize"> Employee(s)</span></div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>

                  <div className="flex items-center ">
                    <div className="w-12 relative flex justify-center bg-red-400">
                      <span className="text-lg font-medium text-[#212121]/70   -rotate-90   absolute  translate-y-auto  origin-left-top ">Low</span>
                    </div>

                    <div className="grid md:grid-cols-3 grid-cols-1 gap-4 w-full">

                      <div className=" px-10 py-10 bg-[#FFCDD2] items-center justify-center gap-3 inline-flex">
                        <div className=" flex-col justify-center items-center gap-3 inline-flex  ">
                          <div className="self-stretch h-32 flex-col justify-center items-center gap-3 flex">
                            <div className=" text-center text-neutral-800 text-xl font-medium capitalize">Talent Risk</div>
                            <div className=" text-center text-neutral-800 text-base font-medium capitalize">Low Performance / Low Potential</div>
                            <div className=" text-center"><span className="text-neutral-800 text-2xl font-medium capitalize">21</span><span className="text-neutral-800 text-2xl font-medium capitalize"> Employee(s)</span></div>
                          </div>
                        </div>
                      </div>
                      <div className=" px-10 py-10 bg-[#FFECB3] items-center justify-center gap-3 inline-flex">
                        <div className=" flex-col justify-center items-center gap-3 inline-flex  ">
                          <div className="self-stretch h-32 flex-col justify-center items-center gap-3 flex">
                            <div className=" text-center text-neutral-800 text-xl font-medium capitalize">Solid professional</div>
                            <div className=" text-center text-neutral-800 text-base font-medium capitalize">Moderate Performer / Low Potential</div>
                            <div className=" text-center"><span className="text-neutral-800 text-2xl font-medium capitalize">21</span><span className="text-neutral-800 text-2xl font-medium capitalize"> Employee(s)</span></div>
                          </div>
                        </div>
                      </div>
                      <div className=" px-10 py-10 bg-[#BBDEFB] items-center justify-center gap-3 inline-flex">
                        <div className=" flex-col justify-center items-center gap-3 inline-flex  ">
                          <div className="self-stretch h-32 flex-col justify-center items-center gap-3 flex">
                            <div className=" text-center text-neutral-800 text-xl font-medium capitalize">High Professional</div>
                            <div className=" text-center text-neutral-800 text-base font-medium capitalize">Outstanding Performer / Low Potential</div>
                            <div className=" text-center"><span className="text-neutral-800 text-2xl font-medium capitalize">21</span><span className="text-neutral-800 text-2xl font-medium capitalize"> Employee(s)</span></div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>

                  <div className="flex items-center ">
                    <div className="w-10 relative">
                      <span className="text-lg font-medium text-[#212121]/70     "> </span>
                    </div>

                    <div className="grid grid-cols-3 gap-4 w-full">

                      <div className="  items-center justify-center gap-3 inline-flex">
                        <span className="text-lg font-medium text-[#212121]/70     ">Low </span>
                      </div>
                      <div className="  items-center justify-center gap-3 inline-flex">
                        <span className="text-lg font-medium text-[#212121]/70     ">Moderate</span>
                      </div>
                      <div className="  items-center justify-center gap-3 inline-flex">
                        <span className="text-lg font-medium text-[#212121]/70     ">  High</span>
                      </div>


                    </div>
                  </div>

                  <div className=" text-neutral-800 text-2xl font-medium    flex justify-center relative ">
                    <h1 className="   ">Potential Assessment</h1>

                  </div>




                </div>

              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
