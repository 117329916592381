import React, { Component } from 'react';
import { connect } from 'react-redux';
import BarRWA from '../../Charts/BarRWA';
import Commentary from '../../../../../Index/Components/Commentary/Commentary';
// import Filter from './Filter';
import Filter from "../../../../../../CommonComponents/Filters/IndexFilter/Filter";
import { Slider } from 'material-ui-slider';

import DataNotFound from '../../../../../Components/DataNotFound';



class DimensionIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilterName: 'none',
      currentFilterValue: 'none',
      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      sliderValueObj: {},
      calValueObj: {},
      testValueObj: {},
      overallAddSum: 0
    }
  }
  componentDidMount() {

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }
  handleRWAFilterNameSave = (val) => {
    this.setState({ currentFilterName: val })
    if (val === 'none') {
      this.setState({ currentFilterValue: 'none' })
    }
  }

  handleRWAFilterValueSave = (val) => {
    this.setState({ currentFilterValue: val })

  }




  handleSelectMultiFilter = (name) => {
    let { FilterValues } = this.state;
    let Check = FilterValues && FilterValues.length > 0 ? FilterValues[(FilterValues.length - 1)].value === "All" ? false : true : true;

    if (Check) {
      let getIndex = FilterValues.findIndex(prev => prev.level === name);
      if (getIndex === -1) {
        FilterValues.push({
          level: name,
          value: "All"
        });
      }
      else {
        FilterValues.splice(getIndex, 1);
      }
      this.setState({ FilterValues }, () => {
        // let { handleMultiFilterGetQuestion } = this.props;
        // handleMultiFilterGetQuestion(FilterValues);
      });
    }

  }


  handleSelectMultiFilterClose = (name) => {
    let { FilterValues } = this.state;
    let getIndex = FilterValues.findIndex(prev => prev.level === name);
    if (getIndex !== -1) {
      FilterValues.splice(getIndex, 1);
    }

    //console.log('FilterValues: ',FilterValues);
    this.setState({ FilterValues }, () => {
      let { handleFilterRWA, indexType } = this.props;
      handleFilterRWA(FilterValues, indexType);
    });

  }






  handleSelectedListDropdown = (name) => {
    if (this.state.currentSelectValue === name) {
      this.setState({ currentSelectValue: "" });
    }
    else {
      this.setState({ currentSelectValue: name });
    }
  }

  handleSubmitFilter = (name, value) => {
    let { FilterValues } = this.state;
    let getIndex = FilterValues.findIndex(prev => prev.level === name);
    if (getIndex === -1) {
      FilterValues.push({
        level: name,
        value: value
      });
    }
    else {
      FilterValues[getIndex].value = value;
      // FilterValues.splice(getIndex,1)
    }

    this.setState({ FilterValues, currentSelectValue: '' }, () => {
      let { handleFilterRWA, rwaIndexType } = this.props;
      handleFilterRWA(FilterValues, rwaIndexType);
    });

  }

  handleFilterClear = () => {
    this.setState({ FilterValues: [] }, () => {
      let { handleFilterRWA, rwaIndexType } = this.props;
      handleFilterRWA([], rwaIndexType);
    });
  }




  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleFilterRWA, rwaIndexType } = this.props;
    this.setState({ FilterValues: finalFilter });
    handleFilterRWA(finalFilter, rwaIndexType);

  }

  handleSliderChange = (e) => {
    console.log("handleSliderChange---e-->", e)
  }

  setSliderValue = (driver, e1, init, coeff) => {
    console.log("handleSliderChange-" + driver + "--e1-->", e1)

    this.setState({ calValueObj: {}, sliderValueObj: {}, testValueObj: {} }, () => {
      let e = e1 > init ? e1 : init;
      console.log("handleSliderChange-" + driver + "--e-->", e)

      if (true) {
        let { sliderValueObj, calValueObj, testValueObj } = this.state;
        sliderValueObj[driver] = e > init ? e : init;

        calValueObj[driver] = (e - init) * coeff;

        console.log(" e--->", e)
        console.log(" init--->", init)
        console.log(" coeff--->", coeff)
        testValueObj[driver] = "newValue: " + (e) + ",initValue: " + (init) + ", change: " + (e - init) + ", coeff: " + coeff + ", totalSum" + (Number(e - init) * Number(coeff));

        console.log(" (e-init)*coeff--->", (e - init) * coeff)

        this.setState({ sliderValueObj, calValueObj, testValueObj }, () => {

          console.log(" sliderValueObj[driver]--->", sliderValueObj[driver])


          let sum = 0
          let vObjList = Object.keys(calValueObj);
          if (vObjList && vObjList.length > 0) {
            vObjList.forEach((key) => {
              sum += calValueObj[key]
            })
          }

          this.setState({ overallAddSum: sum })
        })
      }
    })

  }

  render() {
    let { getIndexData, getSurveyData, validateIndex, ErrorIndexObj, getRWAError,
      getEMPFilterLabel, getIndexByFilterDMGData, GetParameterLabel, loading, indexType, optionType,
      getCommentData, Name, EmpFilterData, getIndexFilterData, handleRWAFilter,
      getRWAData, rwaIndexType, CountRestrict } = this.props;


    let count = getRWAData && getRWAData.count ? getRWAData.count : 0;
    let isShowCount = CountRestrict(count, false, "rwa") ? false : true;

    let { currentSelectValue, FilterValues, sliderValue, sliderValueObj, overallAddSum, testValueObj } = this.state;

    let DriverName = []
    let DriverValues = []
    let RawValues = []
    let ThirdValues = []

    let showData = false;
    if (getRWAData && getRWAData.rwa) {
      if (getRWAData && getRWAData.rwa && getRWAData.rwa.driver && getRWAData.rwa.driver.length > 0) {
        getRWAData.rwa.driver.forEach((item) => {
          DriverName.push((item));
        });
      }
      DriverValues = getRWAData.rwa.normRelaImpt;
      RawValues = getRWAData.rwa.rawRelaImpt
      ThirdValues = getRWAData.rwa.thirdRelaImpt
      showData = true;
    }




    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    //  let DemographicDataScore = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      //  DemographicDataScore=getDemographicIndexMultiFilterData;
      //  DemographicDataDistribution=getIndexDistributionData;

    }

    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split('.')[0];
        let second = str.split('.')[1];
        let second2 = second ? second.substring(0, 2) : ""
        let final = first + '.' + second2;
        if (second2) {
          return parseFloat(final).toFixed(2)
        } else {
          return no
        }
      } else {
        return 0
      }
    }


    // console.log("---rwaIndexType--->",rwaIndexType)
    // console.log("---validateIndex--->",validateIndex)
    // console.log("---getIndexData--->",getIndexData)
    // console.log("---getSurveyData--->",getSurveyData)




    let validateExtraObj = {
      "isManager": validateIndex && validateIndex["5"] ? true : false,
      "isSeniorLeadership": validateIndex && validateIndex["6"] ? true : false
    }

    let OrgCoreDriverData = {};

    let getSurveyOrgList = []
    if (getIndexData && getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length > 0) {
      getSurveyData.orgList.forEach((org) => {
        let getIndex1 = getSurveyOrgList.findIndex(prev => prev === org.name);
        if (getIndex1 === -1) {
          getSurveyOrgList.push(org.name);
        }
      })
    }

    if (getIndexData && getSurveyOrgList && getSurveyOrgList.length > 0) {
      getSurveyOrgList.forEach((org) => {
        OrgCoreDriverData[org] = getIndexData[org];
      })
    }


    if (validateExtraObj.isManager) {
      OrgCoreDriverData['Manager'] = getIndexData && getIndexData['Manager'] ? getIndexData['Manager'] : 0;
    }
    if (validateExtraObj.isSeniorLeadership) {
      OrgCoreDriverData["SeniorLeadership"] = getIndexData && getIndexData["SeniorLeadership"] ? getIndexData["SeniorLeadership"] : 0;
    }

    let valueList = []
    if (OrgCoreDriverData && getIndexData) {
      valueList = Object.keys(OrgCoreDriverData).map((key) => [key, OrgCoreDriverData[key], getIndexData[key + "Count"]]);
    }

    if (valueList && valueList.length > 0) {
      valueList.sort((b, a) => a[1] - b[1])
    }

    // console.log("---getSurveyOrgList--->",getSurveyOrgList)
    // console.log("---OrgCoreDriverData--->",OrgCoreDriverData)


    // console.log("---valueList--->",valueList)



    function getCoreInit(driver, list) {
      if (list && list.length > 0) {
        let getIndex = list.findIndex(prev => prev[0] === driver);
        if (getIndex !== -1) {
          return Number(spliceDecimal(list[getIndex][1]))
        }
      }

      return 0
    }


    function getCoreNo(sliderValueObj, driver, valueList) {
      return (sliderValueObj && sliderValueObj[driver] ? sliderValueObj[driver] : 0) > getCoreInit(driver, valueList) ? sliderValueObj[driver] : getCoreInit(driver, valueList)
    }


    return (
      <>
        <div className="items-center justify-between md:flex">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />


          <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />
        </div>





        <div onClick={() => this.closeFilter(false)} className="" >

          <div className="w-full" >
            {showData ?
              <>

                {ErrorIndexObj && ErrorIndexObj["getEngagementRWAError"] ?
                  <>
                    <div className='my-24 text-2xl font-medium text-center text-gray-400'>{ErrorIndexObj["getEngagementRWAError"]}</div>
                  </>
                  :
                  <div className="">

                    {isShowCount ?
                      <>
                        {/* <BarRWA DriverName={DriverName} RawValues={RawValues} DriverValues={DriverValues} ThirdValues={ThirdValues}/> */}
                        {/* Engagement Calculator */}

                        <div className="grid grid-cols-1 gap-4 xl:grid-cols-3 ">

                          <div className="space-y-0 bg-white border rounded-lg md:col-span-2">
                            <h1 className="md:p-6 p-4" >
                              <b className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">{Name} Calculator</b>
                            </h1>

                            <div className='overflow-x-auto whitespace-nowrap px-4 h-[30rem] overflow-y-auto'>
                              <table className='w-full border-collapse' >
                                <thead>
                                  <tr className='whitespace-nowrap bg-gray-100 border-b text-sm  text-[#3D405B] '>
                                    <th className='w-4/12 p-2 px-6 font-semibold text-left text-slate-800'>DRIVERS</th>
                                    <th className='w-2/12 p-2 font-semibold text-center text-slate-800'>DRI*</th>
                                    <th className='w-2/12 p-2 font-semibold text-center text-slate-800'>Drivers Score</th>
                                    <th className='w-2/12 p-2 font-semibold text-center text-slate-800'>PCS*</th>

                                    {/* <th className='w-6/12 p-2 font-semibold text-center text-slate-800'>COFFECIENT IMPACT SCORE</th> */}
                                    {/* <th className='w-6/12 p-2 text-center'>Test 1</th> */}

                                  </tr>
                                </thead>
                                <tbody className='divide-y '>
                                  {DriverName && DriverName.length > 0 ?
                                    DriverName.map((driver, index) =>
                                      <tr className='text-sm'>
                                        <td className='w-4/12 p-2 px-6 text-left'>{GetParameterLabel(driver)}</td>
                                        <td className='w-2/12 p-2 text-center'>{spliceDecimal(DriverValues[index])}</td>
                                        <td className='w-2/12 p-2 text-center'>{spliceDecimal(getCoreInit(driver, valueList))}</td>
                                        <td className='w-6/12 p-2 text-center'>

                                          <div className='flex px-2'>

                                            <Slider
                                              value={getCoreNo(sliderValueObj, driver, valueList)}
                                              onChange={(e) => this.setSliderValue(driver, e, getCoreInit(driver, valueList), ThirdValues[index])}
                                              min={getCoreInit(driver, valueList)}
                                              max={100}
                                              color="#2196f3" 
                                             
                                            ></Slider>

                                            {/* <div className='w-12 mt-3 ml-2'>{100}</div> */}

                                            <div className='w-16 mt-3 ml-2 font-bold'>{getCoreNo(sliderValueObj, driver, valueList)}</div>

                                          </div>


                                          {/* <td className='w-2/12 p-2 text-center'>{testValueObj[driver]}</td> */}


                                        </td>
                                      </tr>
                                    )
                                    : null}

                                </tbody>
                              </table>
                            </div>




                            <div className="text-sm text-[#3d405B]/70 p-4">
                              {"DRI* (Drivers Relative Importance), PCS* (Predictive Coffecient Score )"}
                            </div>
                          </div>


                          <div className="flex justify-center items-center w-full h-full md:p-6 p-4  bg-white border rounded-lg ">
                            <div className=''>
                              <p className='text-[5rem]  text-slate-800' >
                                {
                                  (getIndexFilterData && getIndexFilterData[rwaIndexType] ? spliceDecimal(Number(getIndexFilterData[rwaIndexType]) + Number(overallAddSum)) : 0)
                                }
                              </p>
                              <p className='text-2xl font-medium text-slate-800'>{Name} Score</p>
                            </div>
                          </div>
                        </div>



                      </>
                      :
                      null
                    }


                  </div>
                }


              </>
              :
              <>
                {!loading ?
                  <div className="bg-white p-6 border rounded-lg hover:shadow-lg">
                    <DataNotFound />
                  </div>
                  : null}
              </>
            }



          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DimensionIndex);
