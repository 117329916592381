import React, { Component } from 'react';
import { connect } from 'react-redux';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // showFilter:false,
      // showValue:'',
      currFilterList: [],
      finalFilter: [],
      searchName: ''

    }
  }
  componentDidMount() {

  }


  handleParameterQuestion = () => {

  }

  handleSelectFilter = (name) => {
    // this.setState({showFilter:false});
    this.props.closeFilter(false);

    let { currFilterList, finalFilter } = this.state;
    let { handleMultifilterIndexScore } = this.props;
    let getIndex = currFilterList.findIndex(prev => prev === name);

    if (getIndex === -1) {
      currFilterList = [name];
      finalFilter = [{
        "level": name,
        "value": "All"
      }];

      this.setState({ currFilterList, finalFilter }, () => {
        handleMultifilterIndexScore(finalFilter, name);
      });
    }
    else {
      currFilterList.splice(getIndex, 1);
      let getIndex2 = finalFilter.findIndex(prev => prev.level === name);
      finalFilter.splice(getIndex2, 1);

      this.setState({ currFilterList, finalFilter }, () => {
        if (finalFilter && finalFilter.length > 0) {
          handleMultifilterIndexScore(finalFilter, finalFilter[finalFilter.length - 1].level);
        }
        else {
          handleMultifilterIndexScore([]);
          // this.setState({showFilter:false})
          this.props.closeFilter(false);

        }
      });
    }

  }

  handleOpenFilter = (check) => {
    this.setState({ showFilter: !check, showValue: '' });
    this.props.closeFilter(!check, '');

  }


  handleOpenValue = (name) => {
    if (this.props.showValue === name) {
      // this.setState({showValue:'',showFilter:false});
      this.props.closeFilter(false, '');


    }
    else {
      // this.setState({showValue:name,showFilter:false})
      this.props.closeFilter(false, name);

    }
  }


  handleSelectValue = (value, name) => {
    let { finalFilter } = this.state;

    let getIndex = finalFilter.findIndex(prev => prev.level === name);

    if (getIndex === -1) {

      finalFilter.push({
        "level": name,
        "value": value
      });

    }
    else {

      let getIndex2 = finalFilter.findIndex(prev => prev.value === value);
      if (getIndex2 === -1) {
        finalFilter[getIndex].value = value;
      }
      else if (value === "All") {
        finalFilter[getIndex].value = "All";
      }

    }
    this.props.closeFilter(false, '');
    let { handleMultifilterIndexScore } = this.props;
    this.setState({ finalFilter }, () => {
      handleMultifilterIndexScore(finalFilter, name);
    })
  }



  handleFilterClear = () => {
    let { handleMultifilterIndexScore } = this.props;
    this.setState({ finalFilter: [], currFilterList: [] }, () => {
      handleMultifilterIndexScore([]);
    })
  }

  handleSelectAllFilter = () => {

  }

  handleSearch = (e) => {
    this.setState({ searchName: e.target.value });
  }

  render() {

    const ref = React.createRef();
    let { EmpFilterData2, getIndexFilterData, showValue, showFilter } = this.props;
    let { searchName, currFilterList, finalFilter } = this.state;

    let DemographicValueList = {};
    if (getIndexFilterData) {
      DemographicValueList = getIndexFilterData;
      // DemographicValueList.sort();
    }



    //console.log('EmpFilterData2: ',EmpFilterData2)
    //console.log('currFilterList: ',currFilterList)
    //console.log('DemographicValueList: ',DemographicValueList)
    //console.log('finalFilter: ',finalFilter)



    function selectedValue(item) {
      let Value = '';
      let getIndex = finalFilter.findIndex(prev => prev.level === item);
      if (getIndex !== -1) {
        if (finalFilter && finalFilter[getIndex] && finalFilter[getIndex].value) {
          Value = finalFilter[getIndex].value;
        }
      }
      return Value;
    }


    function SortList(list) {
      let temp = [];
      if (list && list.length > 0) {
        temp = list.sort()
      }
      return temp;
    }


    function getFilterCount() {
      return currFilterList && currFilterList.length > 0
        ? currFilterList.length
        : 0;
    }

    return (
      <>
        {/* <div className="flex "> */}
        {/* <div className="relative flex items-center mr-6 text-xs font-medium text-gray-500 bg-blue-500 cursor-pointer">
          <span className="mr-2 text-gray-400 material-symbols-outlined" style={{ fontSize: '18px' }} onClick={() => this.handleOpenFilter(showFilter)}>filter_list</span>

          <p onClick={() => this.handleOpenFilter(showFilter)} className="text-gray-800">{
            currFilterList && currFilterList.length > 0 ? currFilterList[0] : "Select Demographic"
          }</p>

          <span onClick={() => this.handleOpenFilter(showFilter)} className="material-symbols-outlined">arrow_drop_down</span>
          {showFilter ?
            <div className="absolute left-0 z-10 w-56 p-4 overflow-y-auto font-normal bg-white rounded-lg shadow-lg top-12 font-xs h-60 scollar-xs-blue">
              {EmpFilterData2 && EmpFilterData2.length > 0 ?
                EmpFilterData2.map((dmg, index) =>
                  (dmg.name !== "ManagerPID") ?

                    <div key={index} className="flex items-center py-2 m-1">
                      <input type="checkbox" checked={currFilterList.some(prev => prev === dmg.name) ? true : false} onClick={() => this.handleSelectFilter(dmg.name)} onChange={() => { }} /><label onClick={() => this.handleSelectFilter(dmg.name)} className="ml-2">{dmg.label}</label>
                    </div>
                    : null
                ) : null}
            </div>
            : null}
        </div> */}
        <div className="flex flex-wrap gap-2">
          <div className="cursor-pointer bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] flex justify-between px-4 items-center relative py-2 rounded-md w-40 ">
            <div className="flex items-center space-x-2 ">
              <svg onClick={() => this.handleOpenFilter(showFilter)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round"
              > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
              </svg>
              <span onClick={() => this.handleOpenFilter(showFilter)} className="text-sm text-white">Filters</span>
            </div>

            <span onClick={() => this.handleOpenFilter(showFilter)} className=" bg-white  rounded-full text-[#2196F3] text-xs w-6 h-6 flex items-center justify-center font-semibold" >  {getFilterCount()}</span>

            {showFilter ? (
              <div className="absolute left-0 z-10 w-48 p-4 font-normal bg-white rounded-lg shadow-lg top-12 font-xs" id="chatbot" >
                {EmpFilterData2 && EmpFilterData2.length > 0
                  ? EmpFilterData2.map((dmg, index) =>
                    (dmg.name !== "ManagerPID") ?
                      <div key={index} className="" onClick={() => this.handleSelectFilter(dmg.name)} >
                        <label className="py-2 m-1 flex items-center accent-[#2196F3] text-sm">
                          <input className="mr-2 scale-105" type="checkbox" checked={currFilterList.some(prev => prev === dmg.name) ? true : false} onClick={() => this.handleSelectFilter(dmg.name)} onChange={() => { }} />
                          {dmg.label}
                        </label>
                      </div>
                      : null
                  )
                  : null}
              </div>
            ) : null}
          </div>
        </div >

        {/* </div> */}

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Filter);
