export const campaignConstants = {

    ADD_CAMPAIGN_REQUEST: 'ADD_CAMPAIGN_REQUEST',
    ADD_CAMPAIGN_SUCCESS: 'ADD_CAMPAIGN_SUCCESS',
    ADD_CAMPAIGN_FAILURE: 'ADD_CAMPAIGN_FAILURE',

    ADD_CAMPAIGN_CUSTOMER_REQUEST: 'ADD_CAMPAIGN_CUSTOMER_REQUEST',
    ADD_CAMPAIGN_CUSTOMER_SUCCESS: 'ADD_CAMPAIGN_CUSTOMER_SUCCESS',
    ADD_CAMPAIGN_CUSTOMER_FAILURE: 'ADD_CAMPAIGN_CUSTOMER_FAILURE',

    EXPIRE_CAMPAIGN_REQUEST: 'EXPIRE_CAMPAIGN_REQUEST',
    EXPIRE_CAMPAIGN_SUCCESS: 'EXPIRE_CAMPAIGN_SUCCESS',
    EXPIRE_CAMPAIGN_FAILURE: 'EXPIRE_CAMPAIGN_FAILURE',

    RESEND_CAMPAIGN_REQUEST: 'RESEND_CAMPAIGN_REQUEST',
    RESEND_CAMPAIGN_SUCCESS: 'RESEND_CAMPAIGN_SUCCESS',
    RESEND_CAMPAIGN_FAILURE: 'RESEND_CAMPAIGN_FAILURE',

    CAMPAIGN_GETALL_REQUEST: 'CAMPAIGN_GETALL_REQUEST',
    CAMPAIGN_GETALL_SUCCESS: 'CAMPAIGN_GETALL_SUCCESS',
    CAMPAIGN_GETALL_FAILURE: 'CAMPAIGN_GETALL_FAILURE',

    CAMPAIGN_EMPLOYEES_GETALL_REQUEST: 'CAMPAIGN_EMPLOYEES_GETALL_REQUEST',
    CAMPAIGN_EMPLOYEES_GETALL_SUCCESS: 'CAMPAIGN_EMPLOYEES_GETALL_SUCCESS',
    CAMPAIGN_EMPLOYEES_GETALL_FAILURE: 'CAMPAIGN_EMPLOYEES_GETALL_FAILURE',

    CAMPAIGN_CUSTOMERS_GETALL_REQUEST: 'CAMPAIGN_CUSTOMERS_GETALL_REQUEST',
    CAMPAIGN_CUSTOMERS_GETALL_SUCCESS: 'CAMPAIGN_CUSTOMERS_GETALL_SUCCESS',
    CAMPAIGN_CUSTOMERS_GETALL_FAILURE: 'CAMPAIGN_CUSTOMERS_GETALL_FAILURE',

    GET_RESPONSE_ANALYTICS_REQUEST: 'GET_RESPONSE_ANALYTICS_REQUEST',
    GET_RESPONSE_ANALYTICS_SUCCESS: 'GET_RESPONSE_ANALYTICS_SUCCESS',
    GET_RESPONSE_ANALYTICS_FAILURE: 'GET_RESPONSE_ANALYTICS_FAILURE',

    RESEND_MAIL_IN_BULK_REQUEST:'RESEND_MAIL_IN_BULK_REQUEST',
    RESEND_MAIL_IN_BULK_SUCCESS: 'RESEND_MAIL_IN_BULK_SUCCESS',
    RESEND_MAIL_IN_BULK_FAILURE: 'RESEND_MAIL_IN_BULK_FAILURE',

    RESEND_CUSTOMER_MAIL_IN_BULK_REQUEST:'RESEND_CUSTOMER_MAIL_IN_BULK_REQUEST',
    RESEND_CUSTOMER_MAIL_IN_BULK_SUCCESS: 'RESEND_CUSTOMER_MAIL_IN_BULK_SUCCESS',
    RESEND_CUSTOMER_MAIL_IN_BULK_FAILURE: 'RESEND_CUSTOMER_MAIL_IN_BULK_FAILURE',

    RESEND_MAIL_360_IN_BULK_REQUEST:'RESEND_MAIL_360_IN_BULK_REQUEST',
    RESEND_MAIL_360_IN_BULK_SUCCESS: 'RESEND_MAIL_360_IN_BULK_SUCCESS',
    RESEND_MAIL_360_IN_BULK_FAILURE: 'RESEND_MAIL_360_IN_BULK_FAILURE',
    
    GET_REMINDER_HISTORY_REQUEST:'GET_REMINDER_HISTORY_REQUEST',
    GET_REMINDER_HISTORY_SUCCESS: 'GET_REMINDER_HISTORY_SUCCESS',
    GET_REMINDER_HISTORY_FAILURE: 'GET_REMINDER_HISTORY_FAILURE',


    CAMPAIGN_EMPLOYEES_NUMMBERS_GETALL_REQUEST:'CAMPAIGN_EMPLOYEES_NUMMBERS_GETALL_REQUEST',
    CAMPAIGN_EMPLOYEES_NUMMBERS_GETALL_SUCCESS: 'CAMPAIGN_EMPLOYEES_NUMMBERS_GETALL_SUCCESS',
    CAMPAIGN_EMPLOYEES_NUMMBERS_GETALL_FAILURE: 'CAMPAIGN_EMPLOYEES_NUMMBERS_GETALL_FAILURE',


    ALLEMPLOYEE_GETALL_REQUEST: 'ALLEMPLOYEE_GETALL_REQUEST',
    ALLEMPLOYEE_GETALL_SUCCESS: 'ALLEMPLOYEE_GETALL_SUCCESS',
    ALLEMPLOYEE_GETALL_FAILURE: 'ALLEMPLOYEE_GETALL_FAILURE',


    SEND_APP_NOTIFY_REQUEST: 'SEND_APP_NOTIFY_REQUEST',
    SEND_APP_NOTIFY_SUCCESS: 'SEND_APP_NOTIFY_SUCCESS',
    SEND_APP_NOTIFY_FAILURE: 'SEND_APP_NOTIFY_FAILURE',


    CAMPAIGN_EMPLOYEES_GETALL_KEYWORD_REQUEST: 'CAMPAIGN_EMPLOYEES_GETALL_KEYWORD_REQUEST',
    CAMPAIGN_EMPLOYEES_GETALL_KEYWORD_SUCCESS: 'CAMPAIGN_EMPLOYEES_GETALL_KEYWORD_SUCCESS',
    CAMPAIGN_EMPLOYEES_GETALL_KEYWORD_FAILURE: 'CAMPAIGN_EMPLOYEES_GETALL_KEYWORD_FAILURE',

    GET_CAMPAIGN_BY_ID_REQUEST: 'GET_CAMPAIGN_BY_ID_REQUEST',
    GET_CAMPAIGN_BY_ID_SUCCESS: 'GET_CAMPAIGN_BY_ID_SUCCESS',
    GET_CAMPAIGN_BY_ID_FAILURE: 'GET_CAMPAIGN_BY_ID_FAILURE',

    GET_RESPONSE360_ANALYTICS_REQUEST: 'GET_RESPONSE360_ANALYTICS_REQUEST',
    GET_RESPONSE360_ANALYTICS_SUCCESS: 'GET_RESPONSE360_ANALYTICS_SUCCESS',
    GET_RESPONSE360_ANALYTICS_FAILURE: 'GET_RESPONSE360_ANALYTICS_FAILURE',

    GET_CAMPAIGN_ACTIVE_HISTORY_REQUEST: 'GET_CAMPAIGN_ACTIVE_HISTORY_REQUEST',
    GET_CAMPAIGN_ACTIVE_HISTORY_SUCCESS: 'GET_CAMPAIGN_ACTIVE_HISTORY_SUCCESS',
    GET_CAMPAIGN_ACTIVE_HISTORY_FAILURE: 'GET_CAMPAIGN_ACTIVE_HISTORY_FAILURE',
    
    GET_CAMPAIGN_360_EMPLOYEE_ID_REQUEST: 'GET_CAMPAIGN_360_EMPLOYEE_ID_REQUEST',
    GET_CAMPAIGN_360_EMPLOYEE_ID_SUCCESS: 'GET_CAMPAIGN_360_EMPLOYEE_ID_SUCCESS',
    GET_CAMPAIGN_360_EMPLOYEE_ID_FAILURE: 'GET_CAMPAIGN_360_EMPLOYEE_ID_FAILURE',

    GET_CAMPAIGN_DETAILS_BY_ID_REQUEST: 'GET_CAMPAIGN_DETAILS_BY_ID_REQUEST',
    GET_CAMPAIGN_DETAILS_BY_ID_SUCCESS: 'GET_CAMPAIGN_DETAILS_BY_ID_SUCCESS',
    GET_CAMPAIGN_DETAILS_BY_ID_FAILURE: 'GET_CAMPAIGN_DETAILS_BY_ID_FAILURE',
    
    UPDATE_REMINDER_ACTIVE_STATUS_REQUEST: 'UPDATE_REMINDER_ACTIVE_STATUS_REQUEST',
    UPDATE_REMINDER_ACTIVE_STATUS_SUCCESS: 'UPDATE_REMINDER_ACTIVE_STATUS_SUCCESS',
    UPDATE_REMINDER_ACTIVE_STATUS_FAILURE: 'UPDATE_REMINDER_ACTIVE_STATUS_FAILURE',
        
    GET_RESPONSE_CUSTOMER_ANALYTICS_REQUEST: 'GET_RESPONSE_CUSTOMER_ANALYTICS_REQUEST',
    GET_RESPONSE_CUSTOMER_ANALYTICS_SUCCESS: 'GET_RESPONSE_CUSTOMER_ANALYTICS_SUCCESS',
    GET_RESPONSE_CUSTOMER_ANALYTICS_FAILURE: 'GET_RESPONSE_CUSTOMER_ANALYTICS_FAILURE',

    CAMPAIGN_CUSTOMER_NUMMBERS_GETALL_REQUEST: 'CAMPAIGN_CUSTOMER_NUMMBERS_GETALL_REQUEST',
    CAMPAIGN_CUSTOMER_NUMMBERS_GETALL_SUCCESS: 'CAMPAIGN_CUSTOMER_NUMMBERS_GETALL_SUCCESS',
    CAMPAIGN_CUSTOMER_NUMMBERS_GETALL_FAILURE: 'CAMPAIGN_CUSTOMER_NUMMBERS_GETALL_FAILURE',

    GET_CAMPAIGN_ACTIVE_LAUNCH_DATE_WISE_REQUEST: 'GET_CAMPAIGN_ACTIVE_LAUNCH_DATE_WISE_REQUEST',
    GET_CAMPAIGN_ACTIVE_LAUNCH_DATE_WISE_SUCCESS: 'GET_CAMPAIGN_ACTIVE_LAUNCH_DATE_WISE_SUCCESS',
    GET_CAMPAIGN_ACTIVE_LAUNCH_DATE_WISE_FAILURE: 'GET_CAMPAIGN_ACTIVE_LAUNCH_DATE_WISE_FAILURE',

    ADD_ASSESSMENT_CAMPAIGN_REQUEST: 'ADD_ASSESSMENT_CAMPAIGN_REQUEST',
    ADD_ASSESSMENT_CAMPAIGN_SUCCESS: 'ADD_ASSESSMENT_CAMPAIGN_SUCCESS',
    ADD_ASSESSMENT_CAMPAIGN_FAILURE: 'ADD_ASSESSMENT_CAMPAIGN_FAILURE',

    SAVE_CAMPAIGN_TEMPLATE_REQUEST: 'SAVE_CAMPAIGN_TEMPLATE_REQUEST',
    SAVE_CAMPAIGN_TEMPLATE_SUCCESS: 'SAVE_CAMPAIGN_TEMPLATE_SUCCESS',
    SAVE_CAMPAIGN_TEMPLATE_FAILURE: 'SAVE_CAMPAIGN_TEMPLATE_FAILURE',
    
    GET_CAMPAIGN_TEMPLATE_REQUEST: 'GET_CAMPAIGN_TEMPLATE_REQUEST',
    GET_CAMPAIGN_TEMPLATE_SUCCESS: 'GET_CAMPAIGN_TEMPLATE_SUCCESS',
    GET_CAMPAIGN_TEMPLATE_FAILURE: 'GET_CAMPAIGN_TEMPLATE_FAILURE',
};
