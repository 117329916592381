import React from "react";

export default function Item(props) {
  let {
    getSurveyData,
    IndexEngagementDimensionList,
    getEngagementQuestionData,
    IndexManagerDimensionList,
    getManagerQuestionData,
    getIndexData,
    getAllBenchMarkStaticData
  } = props;
  let { manageName, createdAt } = props;

  let questionEngList = [];
  let questionMngrList = [];
  if (getIndexData && getIndexData["2"] && getIndexData && getIndexData["5"]) {
    if (
      IndexEngagementDimensionList &&
      IndexEngagementDimensionList.length > 0
    ) {
      IndexEngagementDimensionList.forEach((para) => {
        if (getEngagementQuestionData && getEngagementQuestionData.length > 0) {
          getEngagementQuestionData.forEach((ques) => {
            if (para === ques.parameterDimensionName) {
              questionEngList.push(ques);
            }
          });
        }
      });
    }

    if (IndexManagerDimensionList && IndexManagerDimensionList.length > 0) {
      IndexManagerDimensionList.forEach((para) => {
        if (getManagerQuestionData && getManagerQuestionData.length > 0) {
          getManagerQuestionData.forEach((ques) => {
            if (para === ques.parameterDimensionName) {
              questionMngrList.push(ques);
            }
          });
        }
      });
    }
  }



  function getSelectiveScores(list, item, type) {
    let scoreObj = {
      "HWScore": 0,
      "OverallScore": 0
    };
    if (list && list.length > 0) {

      if (type === "dimension") {
        let newList = list.filter(prev => prev.type === "dimension");
        let getIndex = newList.findIndex(prev => prev.name === item);
        if (getIndex !== -1) {
          scoreObj["HWScore"] = newList[getIndex]["HWScore"];
          scoreObj["OverallScore"] = newList[getIndex]["OverallScore"];
        }
      } else if (type === "index") {
        let newList = list.filter(prev => prev.type === "index");
        console.log("newList------x------>", newList)
        console.log("item------x------>", item)

        let getIndex = newList.findIndex(prev => prev.name === item);
        if (getIndex !== -1) {
          scoreObj["HWScore"] = Number(newList[getIndex]["HWScore"]);
          scoreObj["OverallScore"] = Number(newList[getIndex]["OverallScore"]);
        }
      }

    }
    return {
      "HWScore": scoreObj["HWScore"] > 0 ? scoreObj["HWScore"] : "-",
      "OverallScore": scoreObj["OverallScore"] > 0 ? scoreObj["OverallScore"] : "-"
    }
  }



  let list1 = [
    `Close ended questions are asked on a six-point scale ranging from Strongly Disagree (1) to Strongly Agree (6).`,
    `Engagement behaviors contain the satisfaction scores or Top 2 scores (i.e. Agree and Strongly Agree on the rating scale)  on the six engagement behaviors.`,
    `These questions indicate the extent to which employees feel "Pride", exhibit "Presence", and strive to deliver "Performance" for the organization.`,
    `Engagement Score is calculated basis direct employee responses on these six questions.`,
    `Engagement Score is determined by averaging an employee response to each of the six engagement questions based on a six-point scale.`,
    `Team Engagement score represents the percentage of direct team members scoring an average of 4.5 or above on these six statements.`
  ]


  let list2 = [
    `Close ended questions are asked on a six-point scale ranging from Strongly Disagree (1) to Strongly Agree (6).`,
    `The manager index questions cover the aspects for an employee that are affected by his/her immediate manager. It helps managers understand the perceptions of their teams towards them as people managers.`,
    `Manager Index questions encompass the CARE framework, with 12 questions representing the four critical aspects of managerial capability: Connect, Act, Respect and Empower.`,
    `Manager Index contains the satisfaction scores or Top 2 scores (i.e. Agree and Strongly Agree on the rating scale) on the 12 Manager Index behaviors.`,
    `The Manager Index is calculated basis direct employee responses on these twelve questions.`,
    `The Manager Index is determined by averaging an employee response to each of the twelve manager questions based on a six-point scale.`
  ]


  let list3 = [
    `Engagement is a mindset.`,
    `Your Score and Scorecard is not everything.`,
    `Effective dialogue is the key.`,
    `Instead of being interesting, BE INTERESTED.`,
    `Before calling a team meeting for action planning`
  ]



  return (
    <>
      <section className=" space-y-2   px-10">
        <main className=" w-full pb-4 space-y-6 text-left  ">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/70 italic">
            <h1 className="text-base font-semibold">Manager Score Card</h1>
            <p className="text-base font-semibold">{manageName + " | " + createdAt}</p>
          </div>

          <h1 className="px-6 text-4xl text-left text-[#fff] uppercase bg-[#2364AD] p-4 py-4  ">
            Overview Of Methodology
          </h1>

          <div className="w-full gap-6 space-y-4">
            <div className="bg-[#2364AD] p-4 py-2">
              <p className="text-xl font-medium text-white">Engagement Score</p>
            </div>
            <p className="font-bold">Engagement is the state of emotional and intellectual commitment to an organization or group. Simply put, it is the extent to which an organization ‘wins’ the ‘hearts and minds’ of its employees.</p>


            <div className="list-disc space-y-2 pl-6 leading-snug	line-height: 1.375;">
              {list1 && list1.length > 0 ?
                list1.map((ele) =>
                  <div className="flex">
                    <div className="mt-2 mr-2">
                      <svg width="6" height="6" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.25" cy="1.25" r="1.25" fill="#212121" />
                      </svg>
                    </div>
                    <p>
                      {ele}
                    </p>
                  </div>
                ) : null}
            </div>



          </div>
          <div className="w-full gap-6 space-y-3 ">
            <div className="bg-[#2364AD] p-4 py-2">
              <p className="text-xl font-medium text-white">Manager Index</p>
            </div>
            <p className="font-bold">Manager Index indicates your team satisfaction (Agree & Strongly Agree) on the aspects that are critical for you to drive as a People Manager to build an engaged team.</p>


            <div className="list-disc space-y-2 pl-6 leading-snug	line-height: 1.375;">
              {list2 && list2.length > 0 ?
                list2.map((ele) =>
                  <div className="flex">
                    <div className="mt-2 mr-2">
                      <svg width="6" height="6" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.25" cy="1.25" r="1.25" fill="#212121" />
                      </svg>
                    </div>
                    <p>
                      {ele}
                    </p>
                  </div>
                ) : null}
            </div>


          </div>
        </main>
      </section>



      <h3 className="page-break mt-4"></h3>
      <section className=" space-y-2   px-10">
        <main className=" w-full pb-4 space-y-6 text-left  ">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/70 italic">
            <h1 className="text-base font-semibold">Manager Score Card</h1>
            <p className="text-base font-semibold">{manageName} | {createdAt}</p>
          </div>

          <h1 className="px-6 text-4xl text-left text-[#fff] uppercase bg-[#2364AD] p-4 py-4  ">
            Overview Of Methodology
          </h1>
          {/* <div className="w-full gap-6 space-y-4">
            <div className="bg-[#2364AD] p-4 ">
              <p className="text-xl font-medium text-white">Benchmark Comparison</p>
            </div>
            <div className="grid grid-cols-2 gap-10 ">
              <div>
                <p className="font-bold pb-4 ">Overall Average Benchmark:<br /><span className="font-medium  text-sm">The "Overall Average Benchmark" represents the average score across organizations in India.</span></p>
                <br />
                <div className="space-y-2 ">
                  <div className="flex items-center">
                    <span className="w-[75%]">Overall Engagement Benchmark -</span>
                    <span style={{ fontWeight: "bold" }}>{getSelectiveScores(getAllBenchMarkStaticData, "Engagement", "index")["OverallScore"]}%</span>
                  </div>
                  <div className="flex items-center">
                    <span className="w-[75%]">Overall Manager Benchmark -</span>
                    <span style={{ fontWeight: "bold" }}>{getSelectiveScores(getAllBenchMarkStaticData, "Manager", "index")["OverallScore"]}%</span>
                  </div>
                </div>
              </div>
              <div>
                <p className="font-bold pb-4">Happy Workplaces (HW) Benchmark:<br /><span className="font-medium text-sm">Exclusive Recognition of Top Performers that excel in fostering employee happiness, well-being and engagement.</span></p>

                <div className="space-y-2 ">
                  <div className="flex items-center">
                    <span className="w-[75%]">Overall Engagement Benchmark -</span>
                    <span style={{ fontWeight: "bold" }}>{getSelectiveScores(getAllBenchMarkStaticData, "Engagement", "index")["HWScore"]}%</span>
                  </div>
                  <div className="flex items-center">
                    <span className="w-[75%]">Overall Manager Benchmark -</span>
                    <span style={{ fontWeight: "bold" }}>{getSelectiveScores(getAllBenchMarkStaticData, "Manager", "index")["HWScore"]}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="w-full gap-6 space-y-4">
            <div className="bg-[#2364AD] p-4 ">
              <p className="text-xl font-medium text-white">Key Principles of Manager Action Planning</p>
            </div>
            <ul className="list-disc pl-8 space-y-4 leading-snug	line-height: 1.375;">


              {list3 && list3.length > 0 ?
                list3.map((ele) =>
                  <div className="flex">
                    <div className="mt-2 mr-2">
                      <svg width="6" height="6" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.25" cy="1.25" r="1.25" fill="#212121" />
                      </svg>
                    </div>
                    <p>
                      {ele}
                    </p>
                  </div>
                ) : null}

              <div className="pl-4">
                <li className="flex items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21 20" fill="none">
                    <path d="M1.18164 9.80088C3.96875 12.8165 6.67109 15.5313 9.26875 18.9649C12.093 13.3478 14.9836 7.71104 19.7531 1.60674L18.468 1.01807C14.4406 5.28916 11.3117 9.33213 8.59297 14.1368C6.70234 12.4337 3.64688 10.0235 1.78125 8.78525L1.18164 9.80088Z" fill="#2364AD" />
                  </svg>
                  <span>Derive your own insight.</span></li>
                <li className="flex items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21 20" fill="none">
                    <path d="M1.18164 9.80088C3.96875 12.8165 6.67109 15.5313 9.26875 18.9649C12.093 13.3478 14.9836 7.71104 19.7531 1.60674L18.468 1.01807C14.4406 5.28916 11.3117 9.33213 8.59297 14.1368C6.70234 12.4337 3.64688 10.0235 1.78125 8.78525L1.18164 9.80088Z" fill="#2364AD" />
                  </svg>
                  <span>Have self-reflection of own behavior.</span></li>
                <li className="flex items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21 20" fill="none">
                    <path d="M1.18164 9.80088C3.96875 12.8165 6.67109 15.5313 9.26875 18.9649C12.093 13.3478 14.9836 7.71104 19.7531 1.60674L18.468 1.01807C14.4406 5.28916 11.3117 9.33213 8.59297 14.1368C6.70234 12.4337 3.64688 10.0235 1.78125 8.78525L1.18164 9.80088Z" fill="#2364AD" />
                  </svg>
                  <span>Have One on One or group conversation with your team member.</span></li>
              </div>

            </ul>
            <p className="text-lg font-bold text-[#EF5350] pt-6">Note: A detailed guide will be shared with you to help with action planning.</p>
          </div>
        </main>
      </section>
    </>
  );
}
