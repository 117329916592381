import React from "react";
import moment from 'moment';

export default function SurveyList(props) {
  let { clusterAnalysisData,  isRiskPusle,

    cardImage,cardColor,
    CLIENT_ID, CLIENT_ROLE,  indexA, indexB, accessAllow,
      onOptionDot, crrSurvey, getSurveyListData,  onDashboard,
    onEditSurvey,   onReportSurvey, onPublishSurvey, onDeleteSurvey, LanguageObj, CurrentLanguage, onUpdateSurvey, onNoDashboardModal, isRiskSurvey
  } = props;

  //console.log('getSurveyListData CLIENT_ROLE:', CLIENT_ROLE);

  // let [showFilter, handleOpenFilter] = React.useState(false);
  // let [showFilter2, handleOpenFilter2] = React.useState(false);



  // let [CrrRiskOtherSurvey, handleSelectFilter] = React.useState("");


  function AllowChange(survey) {
    if (CLIENT_ROLE === "ADMIN") {
      return true
    }
    if ((survey && survey.roles && survey.roles.length > 0 && ((survey.roles[0] && survey.roles[0] === "ADMIN") || (survey.roles[1] && survey.roles[1] === "ADMIN")))) {
      return false
    }
    else {
      return true
    }
  }

  function ShowUserSurvey(survey) {
    if (CLIENT_ROLE === "ADMIN") {
      if (survey && survey.roles && survey.roles.length === 1 && ((survey.roles[0] && survey.roles[0] === "CROLE"))) {
        return false
      }
      else if (survey && survey.roles && survey.roles.length > 0 && ((survey.roles[0] && survey.roles[0] === "ADMIN") || (survey.roles[1] && survey.roles[1] === "ADMIN"))) {
        return true
      }
      else { //can comment
        return true
      }
    }
    else if (CLIENT_ROLE === "CROLE" && CLIENT_ID === survey.createdBy) {
      return true
    }
    else if (survey && survey.roles && survey.roles.length > 0 && ((survey.roles[0] && survey.roles[0] === "ADMIN") || (survey.roles[1] && survey.roles[1] === "ADMIN"))) {
      return true
    }
    else {
      return false
    }

  }


  if(isRiskPusle){
    // console.log('getSurveyListData-------------------->', getSurveyListData);
    // console.log("indexA,indexB--------->",indexA,indexB);
  }




  let LabelList = [
    "Detached", "Low Attached", "Attached", "Highly Attached"
  ]

  let newClusterList = []
  if (clusterAnalysisData && clusterAnalysisData.length > 0) {
    clusterAnalysisData.forEach((item, indexZ) => {
      newClusterList.push({
        "clusterName": LabelList[indexZ],
        "clusterNumber": item[0],
        "clusterSize": item[1],
        "clusterCenter": item[2],
        "clusterList": item[3]
      });
    })
  }



  // && (isRiskSurvey ? (survey.templateId === 6) : (survey.templateId !== 6))
  return (
    <>
      {getSurveyListData && getSurveyListData.length > 0 ?
        getSurveyListData.map((survey, index) =>
          ( ShowUserSurvey(survey))  ?
          <div className="capitalize transition duration-500 ease-in-out transform scale-100 border rounded-md shadow-md cursor-pointer hover:scale-105" >


            <div className="flex justify-center " style={{background : cardColor?cardColor: '#d0f1f8'}} >
              <img alt="code" src={cardImage?cardImage:"/img/employee_listening_platform.png"} className="w-3/6" />
              </div>
 
              <div
                className="p-4 capitalize transition duration-500 ease-in-out transform scale-100 bg-white border rounded-md shadow-lg cursor-pointer hover:scale-105 "
              // className={
              //   survey.isExpire === true ?
              //     "bg-white  p-4 rounded-md shadow-md hover:shadow-2xl"
              //     :
              //     ((moment(moment.unix(survey.expireTime / 1000).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) === true) ?
              //       "bg-white  p-4 rounded-md shadow-md hover:shadow-2xl"
              //       :
              //       "bg-white  p-4 rounded-md shadow-md hover:shadow-2xl")
              // } 
              >

                <div className="relative flex items-start justify-between">
                  {/* <img src="https://logos-download.com/wp-content/uploads/2016/10/icici_bank_logo_symbol.png" className="w-2/3 mr-4" alt="company_logo" />  */}

                  <div className="" style={{ height: '40px' }} >
                    {survey && survey.logo ? <img src={survey.logo} style={{ height: '40px' }} className="w-auto mr-4" alt="company_logo" /> : <img src={"/img/logo/happypluslogo.png"}
                      style={{ height: '40px' }} className="w-auto mr-4" alt="default_survey_logo" />}
                  </div>
                  <div className="">
                    <div className="relative inline-block dropdown">
                      <span onClick={() => onOptionDot(survey)} className="p-1 text-gray-600 rounded-full cursor-pointer material-symbols-outlined hover:text-blue-500 hover:bg-blue-100"
                        style={{ fontSize: '18px' }}>more_vert
                        {/* drop down */}
                      </span>

                      {/* {(crrSurvey === survey.id) || true?
                      <ul className="absolute z-30 hidden pt-1 text-xs text-gray-500 bg-white shadow dropdown-menu right-2 top-6">
                        <span onClick={() => onPreviewSurvey(survey)} className="block px-4 py-2 cursor-pointer hover:bg-gray-100 ">Preview</span>
                        {accessAllow("uploadUser") && AllowChange(survey) ?
                          <span onClick={() => onUpdateSurvey(survey)} className="block px-4 py-2 cursor-pointer hover:bg-gray-100">Access</span>
                          : null}
                        <span onClick={() => onDeleteSurvey(survey)} className="block px-4 py-2 cursor-pointer hover:bg-gray-100">Delete</span>
                      </ul>
                      : null} */}


                          {(crrSurvey === survey.id) && AllowChange(survey)  ?
                          <div className="absolute z-30 w-24 overflow-hidden text-xs text-gray-500 bg-white border shadow-lg dropdown-menu right-2 top-6">
                            {/* <span onClick={() => onPreviewSurvey(survey)}  className="block px-4 py-2 hover:bg-blue-50 hover:text-blue-500">Preview</span>  */}
                            {accessAllow("uploadUser") && AllowChange(survey) ?
                              <span onClick={() => onUpdateSurvey(survey)} className="block px-4 py-2 hover:bg-blue-50 hover:text-blue-500">Access</span>
                              : null}

                            {AllowChange(survey)?
                            <span onClick={() => onDeleteSurvey(survey)} className="block px-4 py-2 hover:bg-blue-50 hover:text-blue-500">Delete</span>
                            :null}

                          </div>
                          : null}


                    </div>


                  </div>
                </div>

                {/* <div className="py-6 " style={{ height: "70px" }}>
                <h1 className="text-sm font-semibold text-gray-800 ">{survey.name}</h1>
                <p className="text-xs text-gray-400">{survey && survey.stageId && survey.stageId.label?survey.stageId.label:""}</p>
              </div> */}

                <div className="flex space-y-1 h-28">
                  <div className="py-4 space-y-2 " >
                    <h1 className="text-base font-semibold text-gray-800 line-clamp-2">{survey.name}</h1>
                    <div className="flex " >
                      {/* <p className="text-xs text-gray-400">{survey && survey.stageId && survey.stageId.label ? survey.stageId.label : ""}</p> */}

                      {survey.isExpire ?
                        <>
                          <p className="inline-flex px-2 py-1 text-xs font-medium text-red-500 bg-red-100 rounded-full">close</p>
                        </>
                        :
                        <>
                          {moment(moment.unix(survey.expireTime / 1000).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) ?
                            <p className="inline-flex px-2 py-1 text-xs font-medium text-red-500 bg-red-100 rounded-full">close</p>
                            :
                            <p className="inline-flex px-2 py-1 text-xs font-medium text-green-500 bg-green-100 rounded-full">open</p>
                          }
                        </>
                      }


                    </div>
                  </div>

                </div>

                <div className="flex justify-between space-x-6">
                  <div><p className="text-xs text-gray-500 ">Created: </p>
                    <p className="text-sm font-medium text-slate-800 ">{moment(new Date(parseInt(survey.createdAt))).utcOffset("+05:30").format("DD MMM, YYYY")}</p></div>
                    <div><p className="text-xs text-gray-500 ">{survey.closedDate?"Closed":"Expiry"}</p>
                        <p className="text-sm font-medium text-slate-800 ">{survey.closedDate?moment.unix(survey.closedDate / 1000).format("DD MMM, YYYY"):moment.unix(survey.expireTime / 1000).format("DD MMM, YYYY")}</p>
                      </div>
                </div>



                {/* {survey.templateId === 6 && survey.isRiskSurveyType !== "DEFAULTEXIT"?
                  <div className="my-3">


                    {survey && survey.linkedSurveyId && survey.linkedSurveyId.name ?
                      <div className="flex justify-between py-1">
                        <div className="cursor-pointer bg-gray-100 border truncate flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5 w-full">
                          <span className="w-10/12 text-xs text-gray-800 ">{survey && survey.linkedSurveyId && survey.linkedSurveyId.name ? survey.linkedSurveyId.name : "No Linked Survey"}</span>
                        </div>
                      </div>
                      :

                      <div className="cursor-pointer bg-gray-100 border flex justify-between px-4 items-center relative py-1 rounded-full space-x-1.5">
                        <span onClick={() => handleOpenFilter(!showFilter)} className="w-32 text-xs text-gray-500 truncate ">{crrLinkedSurveyId && crrLinkedSurveyId.name ? crrLinkedSurveyId.name : "Select Survey"}</span>
                        <span onClick={() => handleOpenFilter(!showFilter)} className="text-gray-500 material-symbols-outlined ">arrow_drop_down</span>

                        {showFilter ?
                          <div className="absolute w-48 px-3 py-1 font-normal bg-white shadow-lg z-96 -left-2 top-10 font-xs" id="chatbot" style={{ overflowY: 'auto', height: '90px', zIndex: 120 }}>
                            {getSurveyOneTemplateData && getSurveyOneTemplateData.length > 0 ?
                              getSurveyOneTemplateData.map((innerSurveyDetails, innerSurveyIndex) =>
                                (innerSurveyDetails.templateId === 1) ?
                                  <div key={innerSurveyIndex} className="flex items-center py-1 m-1" onClick={() => { selectLinkedSurvey(innerSurveyDetails, survey, true); handleOpenFilter(!showFilter) }}>
                                    <input type="checkbox" checked={crrLinkedSurveyId.id === innerSurveyDetails.id ? true : false}
                                      onChange={() => { }} /><label className="ml-2 text-sm cursor-pointer text-[#3D405B] ">{innerSurveyDetails.name}</label>
                                  </div>
                                  : null
                              ) : null}
                          </div>
                          : null}

                      </div>


                    }



                    {survey && survey.linkedClusterName ?
                      <div className="flex justify-between py-1">
                        <div className="cursor-pointer bg-blue-50 border truncate flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5 w-full">
                          <span className="w-10/12 text-xs text-gray-500 ">{survey && survey.linkedClusterName ? survey.linkedClusterName : "No Linked Cluster"}</span>
                        </div>
                      </div>
                      :

                      <div className="my-0">
                        <div className="flex justify-between py-1">


                          <div className="cursor-pointer bg-gray-100 border flex justify-between px-4 items-center relative py-1 rounded-full space-x-1.5">
                            <span onClick={() => { handleOpenFilter2(!showFilter2); loadCluster(survey); }} className="w-32 text-xs text-gray-500 truncate ">{crrLinkedCluster && crrLinkedCluster.clusterName ? crrLinkedCluster.clusterName : "Select Cluster"}</span>
                            <span onClick={() => { handleOpenFilter2(!showFilter2); loadCluster(survey); }} className="text-gray-500 material-symbols-outlined ">arrow_drop_down</span>

                            {showFilter2 && newClusterList && newClusterList.length > 0 ?
                              <div className="absolute w-48 px-3 py-1 font-normal bg-white shadow-lg z-96 -left-2 top-10 font-xs" id="chatbot" style={{ overflowY: 'auto', height: '120px', zIndex: 120 }}>
                                {newClusterList && newClusterList.length > 0 ?
                                  newClusterList.map((item, innerSurveyIndex) =>
                                    <div key={innerSurveyIndex} className="flex items-center py-1 m-1" onClick={() => { selectLinkedCluster(item, survey, true); handleOpenFilter2(!showFilter2) }}>
                                      <input type="checkbox" checked={crrLinkedCluster.clusterName === item.clusterName ? true : false}
                                        onChange={() => { }} /><label className="ml-2 text-sm cursor-pointer text-[#3D405B] ">{item && item.clusterName ? item.clusterName : "NaN"}</label>
                                    </div>
                                  ) : null}
                              </div>
                              : null}

                          </div>



                        </div>
                      </div>

                    }


                  </div>
                  : <div className=""></div>
                } */}



                <div className="flex items-center justify-between pt-4 text-gray-400">

                  {accessAllow("createSurvey") ?
                    <>
                      <div onClick={() => onEditSurvey(survey)} className="relative flex items-center justify-center w-10 h-10 text-blue-500 bg-blue-100 rounded-full cursor-pointer hover:bg-blue-100 hover:text-blue-500 tooltipshow">
                        <span className="material-symbols-outlined" style={{ fontSize: '18px' }}>edit</span>
                        <span className="absolute p-2 text-xs text-white bg-gray-800 rounded-md tooltipcard -top-10 whitespace-nowrap ">{LanguageObj && LanguageObj["edit_survey_label_" + CurrentLanguage] ? LanguageObj["edit_survey_label_" + CurrentLanguage] : "View Question"}</span>
                      </div>
                    </>
                    : null}


                  {accessAllow("responseAnalytics") ?
                    <>
                      <div onClick={() => onReportSurvey(survey)} className="relative flex items-center justify-center w-10 h-10 text-blue-500 bg-blue-100 rounded-full cursor-pointer hover:bg-blue-100 hover:text-blue-500 tooltipshow">
                        <span className="material-symbols-outlined" style={{ fontSize: '18px' }}>signal_cellular_alt</span>
                        <span className="absolute p-2 text-xs text-white bg-gray-800 rounded-md tooltipcard -top-10 whitespace-nowrap">{LanguageObj && LanguageObj["response_analytics_label_" + CurrentLanguage] ? LanguageObj["response_analytics_label_" + CurrentLanguage] : "Response Analytics"}</span>
                      </div>
                    </>
                    : null}





                  {!isRiskPusle?
                  <>
                  {(survey && survey.showDashboard) ?
                    <>

                      {accessAllow("dashboard") ?
                        <>
                          <div onClick={() => onDashboard(survey)} className="relative flex items-center justify-center w-10 h-10 text-blue-500 bg-blue-100 rounded-full cursor-pointer hover:bg-blue-100 hover:text-blue-500 tooltipshow">
                            <span className="material-symbols-outlined" style={{ fontSize: '18px' }}>dashboard</span>
                            <span className="absolute p-2 text-xs text-white bg-gray-800 rounded-md tooltipcard -top-10 whitespace-nowrap">{LanguageObj && LanguageObj["dashboard_label_" + CurrentLanguage] ? LanguageObj["dashboard_label_" + CurrentLanguage] : "Dashboard"}</span>
                          </div>
                        </>
                        : null}

                    </>
                    :
                    <>

                      <div onClick={() => onNoDashboardModal(true, survey)} className="relative flex items-center justify-center w-10 h-10 text-blue-500 bg-blue-100 rounded-full cursor-pointer hover:bg-blue-100 hover:text-blue-500 tooltipshow">
                        <span className="material-symbols-outlined" style={{ fontSize: '18px' }}>dashboard</span>
                        <span className="absolute p-2 text-xs text-white bg-gray-800 rounded-md tooltipcard -top-10 whitespace-nowrap">{LanguageObj && LanguageObj["dashboard_label_" + CurrentLanguage] ? LanguageObj["dashboard_label_" + CurrentLanguage] : "Dashboard"}</span>

                      </div>
                    </>
                  }
                  </>
                  :null}



                  {accessAllow("publishSurvey") ?
                    <>

                      {isRiskSurvey ?
                        <div onClick={() => onPublishSurvey(survey, 6, (survey && survey.linkedSurveyId && survey.linkedSurveyId.id ? survey.linkedSurveyId : ""), isRiskPusle)} className="relative flex items-center justify-center w-10 h-10 text-blue-500 bg-blue-100 rounded-full cursor-pointer hover:bg-blue-100 hover:text-blue-500 tooltipshow">
                          <span className="material-symbols-outlined" style={{ fontSize: '18px' }}>send</span>
                          <span className="absolute p-2 text-xs text-white bg-gray-800 rounded-md tooltipcard -top-10 whitespace-nowrap">{LanguageObj && LanguageObj["campaign_label_" + CurrentLanguage] ? LanguageObj["campaign_label_" + CurrentLanguage] : "Launch"} </span>
                        </div>
                        :
                        <div onClick={() => onPublishSurvey(survey)} className="relative flex items-center justify-center w-10 h-10 text-blue-500 bg-blue-100 rounded-full cursor-pointer hover:bg-blue-100 hover:text-blue-500 tooltipshow">
                          <span className="material-symbols-outlined" style={{ fontSize: '18px' }}>send</span>
                          <span className="absolute p-2 text-xs text-white bg-gray-800 rounded-md tooltipcard -top-10 whitespace-nowrap">{LanguageObj && LanguageObj["campaign_label_" + CurrentLanguage] ? LanguageObj["campaign_label_" + CurrentLanguage] : "Launch"} </span>
                        </div>}



                    </>
                    : null}


                </div>




              </div>
 
            </div>
            : null
        )
        : null
      }
    </>
  );
}
