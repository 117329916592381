import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filter from './Filter';
import HeatMap from './Components/HeatMap';
import NPSChart from '../../../../Overview/Components/QuestionTypeCards/Charts/NPSChart';


import DoubleBarChart from './Chart/DoubleBarChart';


class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      viewScore: true,
      showLeaderBoard: false,
      showFilter: false,
      showValue: '',
      XAxis: ""
    }
  }
  componentDidMount() {

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleFilterSave = (name) => {
    this.setState({ currFilterName: name })
  }



  handleMultifilterIndexScore2 = (finalFilter, XAxis) => {
    //console.log(':----------------------------------------------------------------------------------------------------------------XAxis:-------------------->',finalFilter, XAxis)

    let { handleOthersFilterCall } = this.props;
    this.setState({ FilterValues: finalFilter, XAxis: XAxis });
    handleOthersFilterCall(finalFilter, XAxis);

  }

  render() {
    let {
      getQuestionOthersResultData, getQuestionOthersResultFilterData, currentQuestionId, EmpFilterData } = this.props;

    let { currFilterName, FilterValues, XAxis } = this.state;

    //-------------------------------------------------------------------------------------------------------------------------

    let nEwAxis = "";
    if (XAxis) {
      nEwAxis = XAxis;
    }
    else if (EmpFilterData && EmpFilterData.length > 0) {
      nEwAxis = EmpFilterData[0].name;
    }


    //////console.log("==========>EmpFilterData",EmpFilterData)



    //////console.log("//-------------------------------------------------------------------------------------------------------------------------getQuestionOthersResultData",getQuestionOthersResultData)

    let QuestionName = "";

    function FormattFun(inputData) {
      let LIST = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {


          if (currentQuestionId === ques.total[0]._id.questionId) {


            let temp = {
              name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
              answerList: []
            }
            QuestionName = temp["name"];

            if (ques && ques.total && ques.total.length > 0) {
              ques.total.forEach((item) => {
                let ans = {
                  count: item.count,
                  answerText: item._id.answer,
                  template: item._id.qtemplate,
                  dmgValue: item._id[nEwAxis]
                }
                temp["answerList"].push(ans);
              });
            }
            LIST.push(temp);
          }
        });
      }
      return LIST
    }

    let NPSQuesList = FormattFun(getQuestionOthersResultData);
    let NPSQuesListFilter = FormattFun(getQuestionOthersResultFilterData);
    //////console.log("//-------------------------------------------------------------------------------------------------------------------------getQuestionOthersResultData",getQuestionOthersResultData)
    //////console.log("NPSQuesList",NPSQuesList)
    //////console.log("NPSQuesListFilter",NPSQuesListFilter)

    let totalCount = 0;

    let Promoters = 0;
    let Passsives = 0;
    let Distractors = 0;

    let DemographicValues = [];
    let DemographicValuesObj = {};

    let item = NPSQuesList[0];
    let itemOthers = NPSQuesListFilter[0];


    //////console.log("item-overall---->",item);


    let AnswerObjOverall = {};
    if (item && item.answerList && item.answerList.length > 0) {
      item.answerList.forEach((ans) => {
        ////console.log('ans (NPS)',ans)

        if (AnswerObjOverall && AnswerObjOverall[ans.answerText]) {
          AnswerObjOverall[ans.answerText] += (ans.count);
        }
        else {
          AnswerObjOverall[ans.answerText] = (ans.count)
        }

      });
    }




    let AnswerObj = {};
    let AnswerObjList = [];

    if (itemOthers && itemOthers.answerList && itemOthers.answerList.length > 0) {
      itemOthers.answerList.forEach((ans) => {

        let getIndex = DemographicValues.findIndex(prev => prev === ans.dmgValue);
        if (getIndex === -1) {
          DemographicValues.push(ans.dmgValue);
          DemographicValuesObj[ans.dmgValue] = {
            "Promoters": 0,
            "Passsives": 0,
            "Distractors": 0,
            "count": 0
          }
        }
        ////console.log('ans (NPS)',ans)
        ////console.log('dmgValue (NPS)',ans.dmgValue)

        let code = ans.answerText + ans.dmgValue;

        let getIndex1 = AnswerObjList.findIndex(prev => prev.code === code);
        if (getIndex1 === -1) {
          AnswerObjList.push({
            "text": ans.answerText,
            "name": ans.dmgValue,
            "value": ans.count,
            "code": ans.answerText + ans.dmgValue
          });
        }
        else {
          AnswerObjList[getIndex1].value += ans.count;
        }


        //  if(AnswerObj && AnswerObj[ans.answerText] && AnswerObj[ans.answerText].name){
        //    AnswerObj[ans.answerText]["value"] += (ans.count);
        //  }
        //  else{
        //    AnswerObj[ans.answerText]={
        //      "name":ans.dmgValue,
        //      "value":(ans.count)
        //    }
        //  }

      });
    }


    ////console.log('AnswerObj:',AnswerObj)

    if (AnswerObjList) {

      //console.log('--->AnswerObjOverall:',AnswerObjOverall)
      //console.log('--->DemographicValues:',DemographicValues)



      ////console.log('test : AnswerObj',AnswerObj)
      AnswerObjList.forEach(item1 => {
        //  let score = parseInt(AnswerObj[key]["value"]);
        //  let name = AnswerObj[key]["name"];
        ////console.log('test : score',score)
        ////console.log('test : name',name)
        ////console.log('test : key',parseInt(key))

        let key = item1.text;
        let name = item1.name;
        let score = item1.value;

        totalCount++;
        DemographicValuesObj[name]["count"] += score;

        if (parseInt(key) >= 9 && parseInt(key) <= 10) {

          DemographicValuesObj[name]["Promoters"] += score;
          //  Promoters+=score;

        }
        if (parseInt(key) >= 7 && parseInt(key) <= 8) {

          DemographicValuesObj[name]["Passsives"] += score;

          // Passsives+=score;

        }
        if (parseInt(key) >= 1 && parseInt(key) <= 6) {
          DemographicValuesObj[name]["Distractors"] += score;

          //  Distractors+=score;
        }

      });
    }

    ////console.log('DemographicValues: ',DemographicValues)
    ////console.log('FINAL: DemographicValuesObj: ',DemographicValuesObj)

    let PromotersPer = (Promoters * 100 / totalCount);
    let PasssivesPer = (Promoters * 100 / totalCount);
    let DistractorsPer = (Distractors * 100 / totalCount);

    let npsScore = Math.round((PromotersPer) - (DistractorsPer));

    let PromotersColorRange = PromotersPer / 100;
    let PasssivesColorRange = (PasssivesPer / 100) + PromotersColorRange;
    let DistractorsColorRange = (DistractorsPer / 100) + PasssivesColorRange;




    ////console.log('Promoters: ',Promoters);
    ////console.log('Passsives: ',Passsives);
    ////console.log('Distractors: ',Distractors);

    ////console.log('npsScore: ',npsScore);

    ////console.log('PromotersColorRange: ',PromotersColorRange);
    ////console.log('PasssivesColorRange: ',PasssivesColorRange);
    ////console.log('DistractorsColorRange: ',DistractorsColorRange);






    ////console.log('NPSQuesList:',NPSQuesList)


    let { getIndexFilterData } = this.props;



    let DemographicValuesNew = []
    if (DemographicValues && DemographicValues.length > 0) {
      DemographicValues.forEach((item) => {
        if (item && item !== '0' && item !== 0) {
          DemographicValuesNew.push(item)
        }
      });
    }





    return (
      <>
        <div className="items-center justify-between h-10 py-3 xl:flex lg:flex md:flex">

          <Filter

            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData}
            getIndexFilterData={getIndexFilterData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}

          />


        </div>
        <div onClick={() => this.closeFilter(false)} className="">
          <div className="flex w-full" >

            {!(FilterValues && FilterValues.length > 0) && false ?
              <>


                <div className="w-full px-4 pb-10 bg-white border rounded">
                  <h1 className="py-4 font-medium xl:text-xl text-lg text-[#3D405B]">{QuestionName}
                    {/* <p className="text-sm font-semibold text-gray-500">NPS QUESTION</p> */}
                  </h1>
                  {item && item.answerList[0] && item.answerList[0].template === 6 ?
                    <NPSChart AnswerObj={AnswerObjOverall} name={"Overall"} templateId={6} stage={3} />
                    : null}
                </div>


              </>
              :
              <div className="w-full px-4 bg-white border rounded mb-60">
                <div className="flex items-center justify-between px-1 text-base font-semibold text-gray-700">
                  <h1 className="py-4 font-medium xl:text-xl text-lg text-[#3D405B]">{QuestionName}
                    {/* <p className="text-sm font-semibold text-gray-500">NPS QUESTION</p> */}
                    <p className="text-base font-semibold text-gray-700"><span className="text-blue-500">{(currFilterName !== 'none') ? "( " + currFilterName + " wise )" : ""}</span></p>
                  </h1>
                </div>

                <DoubleBarChart
                  AnswerObjOverall={AnswerObjOverall}
                  DemographicValues={DemographicValuesNew}
                  DemographicValuesObj={DemographicValuesObj}
                />


              </div>
            }

          </div>
        </div>









      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
