import React, { Component } from "react";
import { connect } from "react-redux";
import { dashboardActions, userActions } from "../../../_actions";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileBox: false,

      showDropdown: false,
    };
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getClientProfile());
  }

  openProfile = (check) => {
    this.setState({ showProfileBox: !check });

    setTimeout(() => {
      this.setState({ showProfileBox: false });
    }, 4000);
  };
  handleLogout = () => {
    this.props.dispatch(userActions.logoutSession());
  };

  handleOverview = () => {
    let { onOverviewFromHeader } = this.props;
    console.log("working-------------------------------------------------------------------------")
    onOverviewFromHeader();
  };

  render() {
    let {
      dashboard,
      CurrentLanguageName,
      getLanguageData,
      handleLanguage,
      showLanguageModal,
      openLanguageModal,
    } = this.props;
    let { showProfileBox } = this.state;
    let { ClientProfileData } = dashboard;

    ////console.log('ClientProfileData:', ClientProfileData)

    function firstWord(ClientProfileData) {
      if (ClientProfileData && ClientProfileData.name) {
        var firstWords = [];
        var firstWords2 = [];
        var codelines = ClientProfileData.name;

        for (var i = 0; i < codelines.length; i++) {
          var words = codelines[i].split(" ");

          firstWords.push(words[0]);
        }
        if (firstWords && firstWords.length > 0) {
          firstWords.every((name, index) => {
            if (name === "") {
              return false;
            } else {
              firstWords2.push(name);
              return true;
            }
          });
        }

        // var Username = firstWords2.join("");
        var Username = codelines;

        return Username;
      }
      return "User";
    }

    let ProfileName = "";

    let ProfileGender = "";
    if (ClientProfileData && ClientProfileData.name) {
      let nameGender = "";
      if (ClientProfileData && ClientProfileData.Gender) {
        nameGender = ClientProfileData.Gender;
      } else if (ClientProfileData && ClientProfileData.gender) {
        nameGender = ClientProfileData.gender;
      }

      ProfileGender = nameGender;
      ProfileName = ClientProfileData.name;
    }

    let { navigateId, props, getSurveyData, companyName } = this.props;

    return (
      <>
        <header className=" w-full flex justify-between items-center px-4 bg-white  border-b h-16 sticky top-0 z-10">
          <div className="flex items-center lg:space-x-6 space-x-4"> 
            <button
              className="text-blue-500 bg-[#edf6fe] rounded-full cursor-pointer hover:bg-blue-100 trasition duration-150 p-2"
              onClick={() => this.handleOverview()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-arrow-left  "
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#2196f3"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="5" y1="12" x2="19" y2="12" />
                <line x1="5" y1="12" x2="11" y2="18" />
                <line x1="5" y1="12" x2="11" y2="6" />
              </svg>
            </button>
            <a href="#/app/overview">
              <img src="/img/plus_ex_logo.svg" className="w-24 object-cover" />
            </a>

            <span className="px-2 py-1 rounded-md text-gray-500 text-sm border">
              {companyName ? companyName : ""}
            </span>
          </div>

          <div className="flex items-center justify-between py-3">
            {/* <div className="lg:flex hidden border-r pr-3   items-center relative"><h1 className="text-sm">Eng</h1><span className="material-symbols-outlined">arrow_drop_down</span>
          </div> */}

            <div className="relative flex items-center ">
              <button
                onClick={() => this.openProfile(showProfileBox)}
                className="relative block h-8 w-8 ml-2 rounded-full overflow-hidden  focus:outline-none  cursor-pointer"
              >
                {/* <img className="h-full w-full object-cover" src="https://4.bp.blogspot.com/-szJiVBCNYq8/XHPIf2urmgI/AAAAAAAAA3Q/rgzjBI6jxFsORJjM0XxQ8kHV5r2wLzipgCEwYBhgL/s1600/IMG_20190225_160623.jpg" alt="Your avatar" /> */}
                {ProfileGender === "Female" ? (
                  <img
                    className="h-full w-full object-cover"
                    src="img/profile/user2.png"
                    alt="Your avatar"
                  />
                ) : (
                  <img
                    className="h-full w-full object-cover"
                    src="img/profile/user1.png"
                    alt="Your avatar"
                  />
                )}
              </button>
              <div
                onClick={() => this.openProfile(showProfileBox)}
                className="ml-2 lg:flex items-center  cursor-pointer"
              >
                <h1 className="text-sm text-gray-700 font-medium lg:flex hidden">
                  {ProfileName}
                </h1>
                <span className="material-symbols-outlined lg:flex hidden">
                  arrow_drop_down
                </span>
              </div>
              {showProfileBox ? (
                <div className="bg-white rounded-lg shadow-xl p-4 absolute top-12 right-0 w-44  ">
                  <p
                    onClick={() => this.openProfile(showProfileBox)}
                    className="text-gray-500  text-base truncate px-2  cursor-pointer"
                  >
                    {ProfileName}
                  </p>
                  <span
                    onClick={() => this.handleLogout()}
                    className="text-sm hover:text-red-600 text-red-500 py-2 hover:font-medium block px-2  cursor-pointer"
                  >
                    Logout
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </header>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { user, dashboard } = state;
  return {
    loggingIn,
    user,
    dashboard,
  };
}
export default connect(mapStateToProps)(Header);
