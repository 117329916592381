import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import IndexKPI from './Components/IndexKPI';
import Filter from './Components/Filter';
import DonutChart from './Components/DonutChart';

class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown:"",
      showTaskDropdown:[],
      showMAPView:true,

      FilterValues: [],
      showFilter: false,
      showValue: '',


      currEMPData:{}, 

      showMngrActionsPage:0,
      currACTData:{},
      showChangeStatus:"all"



    }
  }
  componentDidMount() {

  }


  openDropdown=(emp)=>{
    if(this.state.showDropdown === emp.employeeId){
      this.setState({showDropdown:""});
    }
    else{
      this.setState({showDropdown:emp.employeeId});
    }
  }


  openTaskDropdown=(act)=>{
    let { showTaskDropdown } = this.state;

    let getIndex = showTaskDropdown.findIndex(prev=>prev===act.id);
    if(getIndex===-1){
      showTaskDropdown.push(act.id);
    }
    else{
      showTaskDropdown.splice(getIndex,1);
    }
    this.setState({showTaskDropdown});
  }

  handleMAPView=(val)=>{
    this.setState({showMAPView:val});
  }


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    this.setState({ FilterValues: finalFilter });

    console.log('========s===================>',finalFilter)
    // handleMultiFilterGetQuestion(finalFilter);

  }






  showActions=(empData)=>{
    ////console.log("empData--->",empData);
    this.setState({currEMPData:empData, showMngrActionsPage:1 , showChangeStatus:'all'})
  }

  showTasks=(actData)=>{
    ////console.log("actData--->",actData);
    this.setState({currACTData:actData, showMngrActionsPage:2, showChangeStatus:'all'})
  }

  handleBackActionTask=()=>{
    let { showMngrActionsPage } = this.state;
    if(showMngrActionsPage===2){
      this.setState({showMngrActionsPage:1, showChangeStatus:'all'})
    }
    else if(showMngrActionsPage===1){
      this.setState({showMngrActionsPage:0, showChangeStatus:'all'})
    }
  }

  handleChangeStatus=(check)=>{
    this.setState({showChangeStatus:check})
  }





  render() {
    let { getAllManagerActionPlanData, getDemographicIndexForManagerData ,EmpFilterData, getIndexFilterData} = this.props;

    let { showMngrActionsPage, showChangeStatus, currACTData, currEMPData, showMAPView, FilterValues} = this.state;



//-------------------------------------------------------------------------------------------


    let DmgForMngrData = [];
    console.log('x FilterValues: ',FilterValues);

    if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
      getDemographicIndexForManagerData.forEach((Emp)=>{

        let isCheck = 0;
        if(FilterValues && FilterValues.length>0){
          FilterValues.forEach((filter)=>{
            if(filter.value !== "All"){
              if(!(Emp[filter.level] === filter.value)){
                isCheck++
              }
            }

            console.log('x 1: ',Emp[filter.level]);
            console.log('x 2: ',filter.value);

          });
        }
        // console.log('x Emp: ',Emp);
        // console.log('x isCheck: ',isCheck);

        if(isCheck===0){
          DmgForMngrData.push(Emp)
        }



      
      });
      
    }

    console.log('x DmgForMngrData: ',DmgForMngrData);


    let AllMngrAPlanData = [];

    if(getAllManagerActionPlanData && getAllManagerActionPlanData.length>0){
      getAllManagerActionPlanData.forEach((action)=>{
        let emp_id = action && action.employeeId && action.employeeId.emp_id?action.employeeId.emp_id:"";

        let getIndex = DmgForMngrData.findIndex(prev=>prev.emp_id===emp_id);
        if(getIndex!==-1 || true){
          AllMngrAPlanData.push(action)
        }
        
      });
    }


    //-------------------------------------------------------------------------------










    console.log('getAllManagerActionPlanData: ',getAllManagerActionPlanData);
    // //console.log('DmgForMngrData: ',DmgForMngrData);

    let EmployeeList = [];
    // eslint-disable-next-line no-unused-vars
    let actionCompleteCount = 0;
    // eslint-disable-next-line no-unused-vars
    let totalTasks = 0;
    // eslint-disable-next-line no-unused-vars
    let completedTasks = 0;
    // eslint-disable-next-line no-unused-vars
    let StartedTasks = 0;

    if(AllMngrAPlanData && AllMngrAPlanData.length>0){
      AllMngrAPlanData.forEach((action)=>{

        if(action && action.employeeTaskList && action.employeeTaskList.length>0){
          // //console.log('action.employeeTaskList: ',action.employeeTaskList);

          totalTasks +=action.employeeTaskList.length;
          completedTasks += action.employeeTaskList.filter(x => x.isComplete == true).length;

          StartedTasks += action.employeeTaskList.filter(x => x.isStarted == true).length;
        }


        if(action.isComplete){
          actionCompleteCount++;
        }
        let emp_id = action && action.employeeId && action.employeeId.emp_id?action.employeeId.emp_id:"";
        let EmpEmail = action && action.employeeId && action.employeeId.email?action.employeeId.email:"";
        let EmpName = action && action.employeeId && action.employeeId.name?action.employeeId.name:"";

        let getIndex = EmployeeList.findIndex(prev=>prev.emp_id === emp_id);
        if(getIndex===-1){
          EmployeeList.push({
            "emp_id":emp_id,
            "EmpName":EmpName,
            "EmpEmail":EmpEmail
          });
        }
      });
    }

    let EmployeeActionList = [];
    if(EmployeeList && EmployeeList.length>0){
      EmployeeList.forEach((emp)=>{

        let actionFilteredList = AllMngrAPlanData.filter(x => (x && x.employeeId && x.employeeId.emp_id?x.employeeId.emp_id:"")== emp.emp_id);
        let completedActionCount = actionFilteredList.filter(action => action.isComplete == true).length;
        let totalActionCount = actionFilteredList && actionFilteredList.length>0?actionFilteredList.length:0;
        ////console.log('actionFilteredList: ',actionFilteredList);
        let startedActionCount = actionFilteredList.filter(action => action.isStarted == true).length;


        let actionFilteredStartDate = AllMngrAPlanData.filter(x => x.isStarted == true);
        let actionFilteredEndDate = AllMngrAPlanData.filter(x => x.isComplete == true);
        //console.log('actionFilteredStartDate: ',actionFilteredStartDate);
        ////console.log('actionFilteredEndDate: ',actionFilteredEndDate);


        let startDateList = [];
        if(actionFilteredStartDate && actionFilteredStartDate.length>0){
          actionFilteredStartDate.forEach((item)=>{
            startDateList.push(item.startDate);
          });
        }
        let endDateList = [];
        if(actionFilteredEndDate && actionFilteredEndDate.length>0){
          actionFilteredEndDate.forEach((item)=>{
            endDateList.push(item.startDate);
          });
        }
        //console.log('startDateList: emp',emp,startDateList);

        // let min = 0
        // let max = 0
        // if(startDateList && startDateList.length>0){
        //   min = Math.min(...startDateList);
        // }
        // if(endDateList && endDateList.length>0){
        //   max = Math.max(...endDateList);
        // }

        ////console.log('minimum: ',min);
        ////console.log('maximum: ',max);


        let ManagerList = [];
        if(DmgForMngrData && DmgForMngrData.length>0){
          ManagerList = DmgForMngrData;
        }
        let getIndex = ManagerList.findIndex(prev=>prev.emp_id===emp.emp_id);
        let AllStartDates = [];
        let AllEndDates = [];
        if(actionFilteredList && actionFilteredList.length>0){
          actionFilteredList.forEach((item)=>{
            if(true){
                //console.log('action---item: ',item);

                if(item.isStarted){
                  AllStartDates.push(item.startDate)
                }
                if(item.isComplete){
                  AllEndDates.push(item.endDate)
                }

            }
          });
        }
        //console.log('AllStartDates: ',AllStartDates);
        //console.log('AllEndDates: ',AllEndDates);

        

        let tempList = {
          "EmpName":emp.EmpName,
          "EmpEmail":emp.EmpEmail,
          "emp_id":emp.emp_id,
          "emp_details":(getIndex!==-1)?DmgForMngrData[getIndex]:"",
          "action_list":actionFilteredList,
          "startDate":AllStartDates && AllStartDates.length>0 ? Math.min(...AllStartDates):0,
          "endDate":AllEndDates && AllEndDates.length>0 ? Math.max(...AllEndDates):0,
          "isStarted":startedActionCount?true:false,
          "isComplete":(completedActionCount === totalActionCount)?true:false,
        }
        EmployeeActionList.push(tempList);

      });
    }
    //console.log('EmployeeActionList: ',EmployeeActionList);



 

















    // let ActionType2List = [];
    // if(AllMngrAPlanData && AllMngrAPlanData.length>0){
    //   AllMngrAPlanData.forEach((item)=>{
    //     let getIndex = ActionType2List.findIndex(prev=>prev.actionId.id===item.actionId.id);
    //     if(getIndex===-1){
    //       let temp= {
    //         actionId:item.actionId,
    //         empList:[]
    //       }
    //       if(item.emp_id){
    //         temp["empList"].push({
    //           "createdAt":item.createdAt,
    //           "emp_id":item.emp_id
    //         });
    //         ActionType2List.push(temp);
    //       }
    //     }
    //     else{
    //       if(item.emp_id){
    //         ActionType2List[getIndex]["empList"].push({
    //           "createdAt":item.createdAt,
    //           "emp_id":item.emp_id
    //         });
    //       }
    //     }
    //   });
    // }

    console.log('AllMngrAPlanData: ',AllMngrAPlanData)


    let TaskList = [];

    if(AllMngrAPlanData && AllMngrAPlanData.length>0){
      AllMngrAPlanData.forEach((action)=>{
        if(action && action.employeeTaskList && action.employeeTaskList.length>0){
          action.employeeTaskList.forEach((task)=>{

            let getIndex = TaskList.findIndex(prev=>prev.taskId.id===task.taskId.id);
            if(getIndex === -1){
              TaskList.push({
                ...task,
                managerList:[{"managerId":task.emp_id,"isComplete":task.isComplete}],
                taskCount:1,
                actionName:action.actionId.name,
                parameterName:action.actionId.parameterName
              });
            }
            else{
              TaskList[getIndex]["managerList"].push({"managerId":task.emp_id,"isComplete":task.isComplete});
              TaskList[getIndex]["taskCount"] += 1;
            }


            
            // let getIndex = TaskList.findIndex(prev=>prev.id===task.id);
            // if(getIndex === -1){
            //   TaskList.push({
            //     ...task,
            //     completeList:[task.isComplete],
            //     taskCount:1,
            //     actionName:action.actionId.name,
            //     parameterName:action.actionId.parameterName
            //   });
            // }
            // else{
            //   TaskList[getIndex]["completeList"].push(task.isComplete);
            //   TaskList[getIndex]["taskCount"] += 1;
            // }



          });
        }
      });
    }




    // let PendingTaskList = [];
    // let CompletedTaskList = [];

    // if(TaskList && TaskList.length>0){
    //   TaskList.forEach((item)=>{

    //     let booleanCount = 0;
    //     if(item && item.completeList && item.completeList.length>0){
    //       booleanCount = item.completeList.filter(x=>x === true).length;
    //     }

    //     if(booleanCount === item.taskCount){
    //       CompletedTaskList.push(item);
    //     }
    //     else{
    //       PendingTaskList.push(item);
    //     }

    //   });
    // }


    // if(TaskList && TaskList.length>0){
    //   TaskList.forEach((item)=>{

    //     if(item.isComplete){
    //       CompletedTaskList.push(item);
    //     }
    //     else{
    //       PendingTaskList.push(item);
    //     }

    //   });
    // }


    // console.log('PendingTaskList:--------> ',PendingTaskList)
    // console.log('CompletedTaskList:--------> ',CompletedTaskList)






    



    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }




    //console.log('showTaskDropdown :',showTaskDropdown)











    let FilteredAllManagerActionPlanData = [];
    if(getAllManagerActionPlanData && getAllManagerActionPlanData.length>0){
      getAllManagerActionPlanData.forEach((Emp)=>{
        let empData = Emp && Emp.employeeId?Emp.employeeId:"";
        let isCheck = 0;
        if(FilterValues && FilterValues.length>0){
          FilterValues.forEach((filter)=>{
            if(filter.value !== "All"){
              if(!(empData[filter.level] === filter.value)){
                isCheck++
              }
            }

          });
        }

        if(isCheck===0){
          FilteredAllManagerActionPlanData.push(Emp)
        }

      });
    }



    function ManagerStatus (task) {
      let ResponseAction = {
        "assigned":task && task.managerList.length>0? task.managerList.length:0,
        "pending":task && task.managerList.length>0? task.managerList.filter(x=>x.isComplete===false).length:0,
        "completed":task && task.managerList.length>0? task.managerList.filter(x=>x.isComplete===true).length:0
      }
      return ResponseAction
    }

    let EmployeeList2 = []
    if(FilteredAllManagerActionPlanData && FilteredAllManagerActionPlanData.length>0){
      FilteredAllManagerActionPlanData.forEach((emp)=>{
        let employeeDetails = emp && emp.employeeId?emp.employeeId:"";
        let employeeId = emp && emp.employeeId && emp.employeeId.id?emp.employeeId.id:"";

        let getIndex = EmployeeList2.findIndex(prev=>prev.employeeId === employeeId)

        if(getIndex!==-1){
          EmployeeList2[getIndex]["actionList"].push(emp)
        }
        else{
          EmployeeList2.push({
            "actionList":[emp],
            "employeeDetails":employeeDetails,
            "employeeId":employeeId
          });
        }

      });
    }


    let TaskList2 = []
    if(FilteredAllManagerActionPlanData && FilteredAllManagerActionPlanData.length>0){
      FilteredAllManagerActionPlanData.forEach((action)=>{
        let taskList = action && action.employeeTaskList && action.employeeTaskList.length>0?action.employeeTaskList:[];
        if(taskList && taskList.length>0){
          taskList.forEach((task)=>{

            let getIndex = TaskList2.findIndex(prev=>prev.taskId.id===task.taskId.id);
            if(getIndex === -1){
              TaskList2.push({
                ...task,
                managerList:[{"managerId":task.employeeId,"isComplete":task.isComplete}],
                taskCount:1,
                actionName:action.actionId.name,
                parameterName:action.actionId.parameterName
              });
            }
            else{
              TaskList2[getIndex]["managerList"].push({"managerId":task.employeeId,"isComplete":task.isComplete});
              TaskList2[getIndex]["taskCount"] += 1;
            }


          });
        }
      })
    }



    if(FilteredAllManagerActionPlanData && FilteredAllManagerActionPlanData.length>0){
      FilteredAllManagerActionPlanData.forEach((action)=>{

        if(action && action.employeeTaskList && action.employeeTaskList.length>0){
          totalTasks +=action.employeeTaskList.length;
          completedTasks += action.employeeTaskList.filter(x => x.isComplete == true).length;
          StartedTasks += action.employeeTaskList.filter(x => x.isStarted == true).length;
        }

      });
    }
    
    console.log('(((((((((((((getAllManagerActionPlanData :',getAllManagerActionPlanData)


    // let ResponseAction = {
    //   "assigned":totalTasks,
    //   "pending":totalTasks-completedTasks,
    //   "completed":completedTasks
    // }

    let totalTaskCount = 0;
    let completedTaskCount = 0;
    if(TaskList2 && TaskList2.length>0){
      TaskList2.forEach((item)=>{
        if(item && item.managerList && item.managerList.length>0){
          totalTaskCount+=item.managerList.length;
          item.managerList.forEach((task)=>{
            if(task.isComplete){
              completedTaskCount++
            }
          })

        }

      })
    }


    let ResponseAction = {
      "assigned":totalTaskCount,
      "pending":totalTaskCount-completedTaskCount,
      "completed":completedTaskCount
    }



    console.log('(((((((((((((EmployeeList2 :',EmployeeList2);


    console.log('TaskList:--------> ',TaskList);
    console.log('TaskList2:--------> ',TaskList2);


    console.log('(((((((((((((getDemographicIndexForManagerData :',getDemographicIndexForManagerData)


    console.log('(((((((((((((EmployeeActionList :',EmployeeActionList)

    function getValue(data, type){
      if(type==="name"){
        let name = data && data.employeeDetails && data.employeeDetails.name?data.employeeDetails.name:"";
        return (name)
      }
      else if(type==="email"){
        let email = data && data.employeeDetails && data.employeeDetails.email?data.employeeDetails.email:"";
        return (email)
      }
      else if(type==="emp_id"){
        let emp_id = data && data.employeeDetails && data.employeeDetails.emp_id?data.employeeDetails.emp_id:"";
        return (emp_id)
      }
      else if(type==="emp_startdate"){
        let ActionList = data && data.actionList?data.actionList:"";
        let StartedActionList = ActionList.filter(x=>x.isStarted===true);

        let startDate=0;
        if(StartedActionList && StartedActionList.length>0){
          StartedActionList.forEach((item, index)=>{
            if(index===0){
              startDate = item.startDate;
            }else{
              if(item.startDate<startDate){
                startDate = item.startDate;
              }
            }

          });
        }

        return (startDate?moment.unix(startDate).format("DD MMM, yyyy"):"")
      }
      else if(type==="emp_enddate"){
        let ActionList = data && data.actionList?data.actionList:"";
        let CompleteActionList = ActionList.filter(x=>x.isComplete===true);

        let endDate=0;
        if(CompleteActionList && CompleteActionList.length>0){
          CompleteActionList.forEach((item, index)=>{
            if(index===0){
              endDate = item.endDate;
            }else{
              if(item.endDate>endDate){
                endDate = item.endDate;
              }
            }

          });
        }

        return (endDate?moment.unix(endDate).format("DD MMM, yyyy"):"")
      }
      else{
        return ("")
      }
      
    }

    function getActionCalculation(action,type){
      ////console.log("action----------------->",action)
      let totalTaskCount = 0;
      let completedTaskCount = 0;
      if(action && action.actionList && action.actionList.length>0){
        totalTaskCount = action.actionList.length;
        completedTaskCount = action.actionList.filter(x=>x.isComplete===true).length;
      }
      if(type===1){
        return completedTaskCount.toString()+"/"+totalTaskCount.toString()
      }
      else if(type===2){
        return completedTaskCount*100/totalTaskCount+"%"
      }
      else if(type===3){
        return completedTaskCount===totalTaskCount
      }
      else if(type===4){
        return completedTaskCount!==totalTaskCount
      }
      else{
        return 0
      }
    }


    function getMNGRDetails(value){
      return currEMPData && currEMPData["employeeDetails"] && currEMPData["employeeDetails"][value]?currEMPData["employeeDetails"][value]:"";
    }
    function getTaskCalculation(activity,type){
      ////console.log("activity---------------->",activity);
      let totalTaskCount = 0;
      let completedTaskCount = 0;
      if(activity && activity.employeeTaskList && activity.employeeTaskList.length>0){
        totalTaskCount = activity.employeeTaskList.length;
        completedTaskCount = activity.employeeTaskList.filter(x=>x.isComplete===true).length;
      }
      if(type===1){
        return completedTaskCount.toString()+"/"+totalTaskCount.toString()
      }
      else if(type===2){
        return completedTaskCount*100/totalTaskCount+"%"
      }
      else{
        return 0
      }
    }

    let taskDataList = []
    let actionDataList = []
    let empDataList = []

    if(showMngrActionsPage===2){
      if(currACTData && currACTData["employeeTaskList"] && currACTData["employeeTaskList"].length>0){

        if(showChangeStatus==="completed"){
          taskDataList = currACTData["employeeTaskList"].filter(x=>x.isComplete === true)
        }
        else if(showChangeStatus==="pending"){
          taskDataList = currACTData["employeeTaskList"].filter(x=>x.isComplete === false)
        }
        else{
          taskDataList = currACTData["employeeTaskList"]
        }
        
      }
    }
    else if(showMngrActionsPage===1){
      if(currEMPData && currEMPData["actionList"] && currEMPData["actionList"].length>0){

        if(showChangeStatus==="completed"){
          actionDataList = currEMPData["actionList"].filter(x=>x.isComplete === true)
        }
        else if(showChangeStatus==="pending"){
          actionDataList = currEMPData["actionList"].filter(x=>x.isComplete === false)
        }
        else{
          actionDataList = currEMPData["actionList"]
        }
        
      }
    }
    else{
      if(EmployeeList2 && EmployeeList2.length>0){

        if(showChangeStatus==="completed"){
          empDataList = EmployeeList2.filter(x=>getActionCalculation(x,3))
        }
        else if(showChangeStatus==="pending"){
          empDataList = EmployeeList2.filter(x=>getActionCalculation(x,4))
        }
        else{
          empDataList = EmployeeList2
        }
        
      }
    }


    ////console.log("taskDataList--------->",taskDataList)



    return (
      <> 
    {showMngrActionsPage===0?

      <div className="w-full h-full overflow-hidden md:p-6 p-4 overflow-y-auto space-y-4">
      
      <div className="md:flex justify-between">
        {/* left side */}
        <div className="flex items-center">
          <h1 className="text-lg font-medium text-gray-800 capitalize ">Sense Action Plan Summary</h1>
        </div>
      </div>

      
        <Filter
              showFilter={this.state.showFilter}
              showValue={this.state.showValue}
              closeFilter={this.closeFilter}

              EmpFilterData2={EmpFilterData2}
              getIndexFilterData={getIndexFilterData}
              handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            />




      <IndexKPI ResponseAction={ResponseAction} />


      

      
      
    <div className="flex justify-end mb-4 -ml-2 text-sm">
      <div 
        onClick={()=>this.handleMAPView(true)} 
        className={showMAPView?
          "cursor-pointer  py-1 block px-4 text-blue-500 border border-blue-500 rounded-full":
          "cursor-pointer  py-1 block px-4"}
        >{"Manager Wise"}</div>
      <div 
        onClick={()=>this.handleMAPView(false)} 
        className={!showMAPView?
          "cursor-pointer  py-1 block px-4 text-blue-500 border border-blue-500 rounded-full":
          "cursor-pointer  py-1 block px-4"}
        >{"Task View"}</div>
    </div>




      {showMAPView?
      <>
      
      {/* <div className="  md:flex  justify-between items-center border-b">
        <div className="text-sm font-medium flex text-gray-600 whitespace-nowrap overflow-x-auto overflow-hidden space-x-6 tab-scroll capitalize cursor-pointer">
          <span onClick={()=>this.handleChangeStatus("all")} className={showChangeStatus==="all"?"py-3 px-4  block   text-slate-800 border-black border-b-2":"py-3 px-4  block "}>All </span>
          <span onClick={()=>this.handleChangeStatus("pending")} className={showChangeStatus==="pending"?"py-3 px-4  block   text-slate-800 border-black border-b-2":"py-3 px-4  block "}>pending</span>
          <span onClick={()=>this.handleChangeStatus("completed")} className={showChangeStatus==="completed"?"py-3 px-4  block   text-slate-800 border-black border-b-2":"py-3 px-4  block "}>Completed</span>
        </div>
      </div> */}
        
      {empDataList && empDataList.length>0?
            <div className="grid  lg:grid-cols-3 md:grid-cols-2 gap-4">

          {empDataList.map((empAct, index)=>   
        <div key={index} className="bg-white shadow-md p-4 rounded-lg space-y-4 border border-gray-300">
          <div className="flex items-start space-x-4">
            <div>
              <span className="material-symbols-outlined rounded-full bg-gray-100 p-4">person</span> 
            </div>
            <div className="space-y-2"> <h1 className="text-lg font-medium">{getValue(empAct,"name")}</h1>
              <p className="text-gray-500 text-xs capitalize">Employee ID: <b className="font-semibold">{getValue(empAct,"emp_id")}</b></p>
              <p className="text-gray-500 text-xs capitalize">Email: <b className="font-semibold">{getValue(empAct,"email")}</b></p>
            </div>
          </div>
          <div className="space-y-1.5">
            <div className="flex justify-between text-sm font-medium">
              <p>Action</p> <p>{getActionCalculation(empAct,1)}</p>
            </div>
            <div className="h-1 bg-gray-100 rounded-full overflow-hidden"><div className="w-2/6 bg-yellow-400 h-1" style={{"width":getActionCalculation(empAct,1)}}/></div>
          </div>
          <div onClick={()=>this.showActions(empAct)} className="flex justify-between items-center text-blue-500">
            <span className="uppercase font-medium text-sm cursor-pointer">view Action</span>
            {empAct.isComplete?
              <button className="cursor-pointer text-xs uppercase bg-green-400 text-white rounded-full flex items-center px-4 py-2 font-semibold">  completed </button>
              :
              <button className="cursor-pointer text-xs uppercase bg-yellow-400 text-white rounded-full flex items-center px-4 py-2 font-semibold">  pending </button>
            }
            </div>
        </div>

        )}
        </div>
        :
        <><div className='py-4 font-medium text-gray-400 text-center text-2xl'>No Data Available</div></>
        }

      
      
      </>
      :
      <>
      <div className="grid grid-cols-3 gap-2">

          {TaskList2 && TaskList2.length>0?
            TaskList2.map((task)=>   
                <>
                  <div className=" bg-white mb-4 rounded-md capitalize shadow-lg m-2">
                    <div className="md:flex justify-between items-center text-sm text-gray-500 relative p-3 ">

                      <div className="w-full">
                        <div className="" >

                        <div className="" >
                          <div className="text-gray-700 mx-2"><span className="text-sm text-red-500">ACTION:</span> {task.actionName}  <span className='text-purple-500 '>({task.parameterName})</span></div>
                          <h1 className="font-medium text-gray-800 whitespace-pre-line m-2"><span className="text-sm text-blue-500">TASK:</span> {task && task.taskId && task.taskId.name?task.taskId.name:""}</h1>
                          
                          <div className="grid grid-cols-2 ">
                            <div title="Employee id" className="text-gray-500 pt-1 mx-2">
                              <div className="text-gray-500"><span className="pb-2">Assigned Managers:</span> <span className="text-sm text-blue-500">{ManagerStatus(task).assigned}</span></div>
                              <div className="text-gray-500"><span className="pb-2">Pending Managers:</span> <span className="text-sm text-yellow-500">{ManagerStatus(task).pending}</span></div>
                              <div className="text-gray-500"><span className="pb-2">Completed Managers:</span> <span className="text-sm text-green-500">{ManagerStatus(task).completed}</span></div>
                            </div>
                            <div className="">
                              <DonutChart managerStatus={ManagerStatus(task)} />
                            </div>
                          </div>

                        </div>


                        </div>
                      </div>
                      
                    </div>

                  </div>
              </>
            ):null}
        </div>
      </>
      }
      </div>


:null}

  
{showMngrActionsPage===1?

<div className=" py-8  w-full px-4 h-full overflow-hidden overflow-y-auto space-y-4"  >
  <div className="md:flex justify-between">
    {/* left side */}
    <div className="flex items-center">
    <div className="mt-2 ml-2"><span onClick={()=>this.handleBackActionTask()} className="cursor-pointer material-symbols-outlined mr-4 -ml-1">keyboard_backspace</span></div>
      <h1 className="text-lg font-medium text-gray-800 capitalize ">Action plans</h1>
      <div className="flex text-blue-500 text-xs items-center ml-2 border-l border-blue-500  pl-2 "><p>Manager</p> <span className="material-symbols-outlined" style={{fontSize: '12px'}}>navigate_next</span> <p>Actions</p></div>
    </div>
  </div>
  {/* end */}
  <div className="flex items-start space-x-4 border-b pb-4">
    <div>
      <span className="material-symbols-outlined rounded-full bg-gray-200 p-4">person</span> 
    </div>
    <div className="space-y-2"> <h1 className="text-lg font-medium">{getMNGRDetails("name")}</h1>
      <p className="text-gray-500 text-xs capitalize">Employee ID: <b className="font-semibold">{getMNGRDetails("emp_id")}</b></p>
      <p className="text-gray-500 text-xs capitalize">Email: <b className="font-semibold">{getMNGRDetails("email")}</b></p>
    </div>
  </div>
  {/*sub nav bar 1 */}
  <div className="  md:flex  justify-between items-center border-b">
    <div className="text-sm font-medium flex text-gray-600 whitespace-nowrap overflow-x-auto overflow-hidden space-x-6 tab-scroll capitalize cursor-pointer">
      <span onClick={()=>this.handleChangeStatus("all")} className={showChangeStatus==="all"?"py-3 px-4  block   text-slate-800 border-black border-b-2":"py-3 px-4  block "}>All </span>
      <span onClick={()=>this.handleChangeStatus("pending")} className={showChangeStatus==="pending"?"py-3 px-4  block   text-slate-800 border-black border-b-2":"py-3 px-4  block "}>pending</span>
      <span onClick={()=>this.handleChangeStatus("completed")} className={showChangeStatus==="completed"?"py-3 px-4  block   text-slate-800 border-black border-b-2":"py-3 px-4  block "}>Completed</span>
    </div>
  </div>
  {/* end */}
  {/* card work */}
 
  {actionDataList && actionDataList.length>0?
   <div className="grid  lg:grid-cols-4 md:grid-cols-2 gap-4">
    {actionDataList.map((activity)=>
    <div className="bg-white shadow-md p-4 rounded-lg space-y-4">
      <div>
        <h1 className="text-lg font-medium">{activity && activity.actionId && activity.actionId.name?activity.actionId.name:""}</h1>
        <p className="text-gray-500 text-xs capitalize">created date {moment(new Date(parseInt(activity.createdAt))).utcOffset("+05:30").format("DD MMM YYYY")}</p>
      </div>
      <p className="text-sm text-gray-600 line-clamp-3">
      {activity && activity.actionId && activity.actionId.desc?activity.actionId.desc:""}
      </p>
      <div className="space-y-1.5">
        <div className="flex justify-between text-sm font-medium">
          <p>Task</p> <p>{getTaskCalculation(activity,1)}</p>
        </div>
        <div className="h-1 bg-gray-100 rounded-full overflow-hidden"><div className="w-0/6 bg-blue-500 h-1" style={{width:getTaskCalculation(activity,2)}} /></div>
        {/* <p className="capitalize text-xs text-red-400 font-medium">expired in 3 days</p> */}
      </div>
      <div onClick={()=>this.showTasks(activity)} className="flex justify-between items-center text-sky-500"><h1 className="cursor-pointer uppercase font-medium text-sm">view tasks</h1>
      {activity.isComplete?
      <button  className="cursor-pointer text-xs uppercase bg-green-500 text-white rounded-full flex items-center px-4 py-2 font-semibold">  completed </button>
      :
      <button  className="cursor-pointer text-xs uppercase bg-yellow-500 text-white rounded-full flex items-center px-4 py-2 font-semibold">  pending </button>
      }
      </div>
    </div>
   )}
   </div>
   :
   <><div className='py-4 font-medium text-gray-400 text-center text-2xl'>No Data Available</div></>
   }


</div>
:null}


    {showMngrActionsPage===2?
      <div className=" py-8  w-full px-4 h-full overflow-hidden overflow-y-auto space-y-4"  >
          <div className="md:flex justify-between">
            {/* left side */}
            <div className="flex items-center">
              <div className="mt-2 ml-2"><span onClick={()=>this.handleBackActionTask()} className="cursor-pointer material-symbols-outlined mr-4 -ml-1">keyboard_backspace</span></div>
              <h1 className="text-lg font-medium text-gray-800 capitalize ">Action plans</h1>

              <div className="flex text-blue-500 text-xs items-center ml-2 border-l border-blue-500  pl-2 "><p>Manager</p> <span className="material-symbols-outlined" style={{fontSize: '12px'}}>navigate_next</span> <p>Actions</p> <span className="material-symbols-outlined" style={{fontSize: '12px'}}>navigate_next</span> <p>Tasks</p></div>
            </div>
          </div>
          {/* end */}
          <div className="flex items-start space-x-4 border-b pb-4">
            <div>
              <span className="material-symbols-outlined rounded-full bg-gray-200 p-4">person</span> 
            </div>
            <div className="space-y-2"> <h1 className="text-lg font-medium">{getMNGRDetails("name")}</h1>
              <p className="text-gray-500 text-xs capitalize">Employee ID: <b className="font-semibold">{getMNGRDetails("emp_id")}</b></p>
              <p className="text-gray-500 text-xs capitalize">Email: <b className="font-semibold">{getMNGRDetails("email")}</b></p>
            </div>
          </div>
          {/*sub nav bar 1 */}
          <div className="  md:flex  justify-between items-center border-b">
            <div className="text-sm font-medium flex text-gray-600 whitespace-nowrap overflow-x-auto overflow-hidden space-x-6 tab-scroll capitalize cursor-pointer">
              <span onClick={()=>this.handleChangeStatus("all")} className={showChangeStatus==="all"?"py-3 px-4  block   text-slate-800 border-black border-b-2":"py-3 px-4  block "}>All </span>
              <span onClick={()=>this.handleChangeStatus("pending")} className={showChangeStatus==="pending"?"py-3 px-4  block   text-slate-800 border-black border-b-2":"py-3 px-4  block "}>pending</span>
              <span onClick={()=>this.handleChangeStatus("completed")} className={showChangeStatus==="completed"?"py-3 px-4  block   text-slate-800 border-black border-b-2":"py-3 px-4  block "}>Completed</span>
            </div>
          </div>
          {/* end */}
          {/* card work */}
          
            {taskDataList && taskDataList.length>0?
            <div className="grid  lg:grid-cols-4 md:grid-cols-2 gap-4">
              {taskDataList.map((task)=>

                <div className={"shadow-md p-4 rounded-lg space-y-4  "+(task.isComplete?'bg-green-100':(task.isStarted?'bg-yellow-100':'bg-blue-100'))} style={{backgroundImage: 'url("img/default_sm.png")', backgroundSize: 'cover'}}>
                  <div>
                    <h1 className="text-lg font-medium">{task  && task.taskId && task.taskId.name?task.taskId.name:""}</h1>
                    <p className="text-gray-600 text-xs capitalize">created date {moment(new Date(parseInt(task.createdAt))).utcOffset("+05:30").format("DD MMM YYYY")}</p>
                  </div>
                  <p className="text-sm text-gray-600  line-clamp-3 ">
                    {task  && task.taskId && task.taskId.desc?task.taskId.desc:""}
                  </p>
                  {/* <div className="space-y-1.5 pt-4">
                    <p className="capitalize text-xs text-red-400 font-medium">{task && task.deadline?"Deadline in "+(moment.duration((moment(task.deadline, "YYYY-MM-DD")).diff((moment().startOf('day')))).asDays())+" days":"No Deadline"}</p>
                  </div> */}
                    {task.isComplete?
                    <h1 className="uppercase font-semibold text-sm cursor-pointer text-blue-500">Completed</h1>
                    :
                    <h1 className="uppercase font-semibold text-sm cursor-pointer text-yellow-500">Pending</h1>
                    }
                </div>

                )}
                </div>
                :
                <><div className='py-4 font-medium text-gray-400 text-center text-2xl'>No Data Available</div></>
                }


        </div>
    :null}





      </>
    );
  }
}
function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dimension);
