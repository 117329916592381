import React from "react";
export default function ConfirmMail(props) {
  let {  showModal, talking, onCloseModal, handleInputField, handleSubmit } = props;
  return (
   <>
   {showModal?
   <>
            <div className="top-0 xl:h-screen h-full  w-full flex justify-center items-center z-40 fixed" style={{background: '#0000005c'}}>
              <div className="bg-white rounded-lg  shadow-sm  xl:w-4/12  lg:w-5/12 md:w-6/12 w-11/12">
                <div className="flex justify-between p-4 border-b"> <h2 className="text-base font-semibold text-center test-gray-800 capitalize">Create Discussion Point</h2>
                  <span onClick={()=>onCloseModal(false)} className="cursor-pointer material-symbols-outlined   font-medium text-gray-500 p-1 bg-gray-100 rounded-full hover:bg-sky-100 transtion duration-150">close</span></div>
                <div className="p-4 space-y-4">


                  <div className=" text-sm text-gray-500 space-y-2">
                    <p className="font-medium">Enter Point</p>
                    <div className="">
                      <textarea cols="" rows="6" type="text" name="talking" placeholder="Enter Point" value={talking} onChange={(e)=>handleInputField(e)} className="border flex justify-between p-2 rounded-md cursor-pointer w-full text-slate-800 focus:outline-none " />
                    </div>
                  </div>


                  <div className="flex justify-center items-center pt-10 -mb-24">
                    <button onClick={()=>handleSubmit()} className=" cursor-pointer bg-blue-500 rounded-md text-white py-2 px-6 ">Create Point</button>                  
                  </div>


                </div>

              </div>
            </div>

    </>
    :null}
   </>
  );
}
