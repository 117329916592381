import React from "react";
import { CONST } from '../../../../_config';

export default function ConfirmMail(props) {
  let { generateCompanyBotLinkData, handleClose } = props;
  return (
    <>
      {generateCompanyBotLinkData && generateCompanyBotLinkData.botCode ?

        <div className="bg-black bg-opacity-40 fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full flex items-center justify-center md:p-0 p-4" >


          <div className="xl:w-4/12 lg:w-4/6 w-5/6  mx-auto bg-white rounded-md mb-20">
            <div className="flex items-center justify-between p-4 border-b">
              <h1 className="text-lg font-semibold text-center text-gray-800 ">Employee Venting Platform</h1>
              <span onClick={() => handleClose(false)} className="
                material-symbols-outlined bg-gray-50 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150 ">close</span>
            </div>



            <div className=" p-4 space-y-4">
              {generateCompanyBotLinkData && generateCompanyBotLinkData.botCode ?
                <>
                  <div className="text-slate-800 font-medium ">Generated Vent Link</div>
                  <div className='w-full bg-white border border-green-300 rounded-md flex justify-between items-center '>
                    <input value={(CONST.BOT_URL + (generateCompanyBotLinkData.botCode)).toString()} disabled={true} className="  cursor-text w-full  p-2 select-all   text-green-500" />
                    {/* <svg xmlns="http://www.w3.org/2000/svg" className='h-10 w-10 px-2 cursor-pointer text-gray-500 hover:text-gray-800'  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                      </svg> */}
                  </div>
                  <div class="text-gray-500 font-medium text-sm">*This URL can be used by all who have no access to Survey Email.</div>
                </>
                : null}
            </div>

            <div class="p-4"><button class="uppercase text-white bg-green-500 w-full flex items-center py-3 px-4 rounded-md font-medium text-base justify-center  "><span class="material-symbols-outlined mr-2">content_copy</span>Copy link</button></div>
          </div>

        </div>

        : null}
    </>
  );
}
