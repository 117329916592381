import React from "react";

export default function Happiness(props) {
  let {
    item,

    DistributionData,
  } = props;

  // console.log('item: ',item)

  let score = "NaN";
  let type = "";

  if (item["score"] !== "NaN") {
    score = parseFloat(item["score"]);
    type = item["type"];
  }

  return (
    <>
      <div className="flex items-center justify-between p-6 transition duration-150 bg-white border rounded-lg cursor-pointer hover:shadow-lg">
        <div className="space-y-6">
          <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">{item.name} Index</h1>
          <div className="space-y-2" >
            <p className="block text-sm  text-[#3D405B]/70 uppercase  font-medium">
              Total score
            </p>
            {score !== "NaN" ? (
              <h1 className="xl:text-2xl xl:text-2xl text-xl font-medium text-[#3D405B] ">
                {type === "Percentage" ? score + "%" : score}
              </h1>
            ) : (
              <p className="xl:text-2xl xl:text-2xl text-xl font-medium text-[#3D405B]  line-clamp-1">
                Not calculated!{" "}
              </p>
            )}
          </div>
          <button className=" text-[#2196f3]  cursor-pointer transition duration-150 flex items-center uppercase font-medium xl:text-base text-sm ">
            View More
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-arrow-narrow-right ml-2"
              width="34"
              height="34"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#2196f3"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1="5" y1="12" x2="19" y2="12" />
              <line x1="15" y1="16" x2="19" y2="12" />
              <line x1="15" y1="8" x2="19" y2="12" />
            </svg>
          </button>
        </div>
        <div>
          <img alt="card" src={item["image"]} className="w-24 2xl:w-32 md:w-28" />
        </div>
      </div>
    </>
  );
}
