import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionScore from './Charts/QuestionScore';
import QuestionStack from './Charts/QuestionStack';
import Filter from './Filter';

class ResponseDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue:'none',
      currFilterName:'none'
    }
  }
  componentDidMount() {

  }

  handleFilterSave=(name)=>{
    this.setState({currFilterName:name});
  }

  handleFilterValueSave=(name,value)=>{
    this.setState({currFilterValue:value});
    let { handleResponseDistributionFilter } = this.props;
    if(name !=='none' && value !=='none'){
      handleResponseDistributionFilter(name,value)
    }
  }

  render() {
    let { getIndexFilterData, cardType,ItemSaveData,getQuestionDistOverallData, getQuestionDistData,EmpFilterData,handleDemographicOverallQFilter, getMeanScoreQwiseData } = this.props;
    let { currFilterName, currFilterValue } = this.state;

    // console.log('getQuestionDistData:',getQuestionDistData);
    // console.log('getMeanScoreQwiseData:',getMeanScoreQwiseData);


      let QuestionList = []
      let AvgList = []

      let indexName = '';
      if(ItemSaveData && ItemSaveData.name){
        indexName = ItemSaveData.name;
      }

      //---------------------------------------overall-------------------------------------------------------------


      // if(getQuestionDistOverallData && getQuestionDistOverallData.length>0){
      //   getQuestionDistOverallData.forEach((ques,index1)=>{
              
      //           let total = 0;
      //             let para = ""
      //             if(ques && ques.total && ques.total.length>0){
      //               ques.total.forEach((item, index)=>{
      //                 total += item.count;
      //                 if(index === 0){
      //                   para = (item && item._id && item._id.parameterBucketName)?item._id.parameterBucketName:"";
      //                 }
      //               });
      //             }
        
      //             let data = {
      //               "name":ques && ques.question && ques.question.length>0 && ques.question[0].name?ques.question[0].name:"NaN",
      //               "answers":[],
      //               "parameter":para
      //             }

      //             if(ques && ques.total && ques.total.length>0){
      //               ques.total.forEach((item, index)=>{
      //                 let optionName = item._id.answerText;
      //                 let optionWeightage = item._id.answer;
      //                 let optionScore = (item.count*100)/total;

      //                 if(AvgList.findIndex(prev=>prev.name === optionName)===-1){
      //                   AvgList.push({
      //                     "name":optionName,
      //                     "score":optionScore,
      //                     "total":total,
      //                     "weightage":optionWeightage
      //                   });
      //                 }
      //                 else{
      //                   let getIndex = AvgList.findIndex(prev=>prev.name === optionName);
      //                   AvgList[getIndex]["score"]+=optionScore;

      //                   if(index1 === (getQuestionDistOverallData.length-1)){
      //                     AvgList[getIndex]["score"] = AvgList[getIndex]["score"]/getQuestionDistOverallData.length;
      //                   }

      //                 }
      //               })
      //             }

      //             let avgData = {
      //               "name":"Overall Question Distributions",
      //               "answers":AvgList,
      //               "parameter":"overall"
      //             }

      //             if(index1 === 0){
      //               QuestionList.push(avgData);
      //             }
          
      //   });
      // }




      //---------------------------------------Filter-------------------------------------------------------------







      if(getQuestionDistData && getQuestionDistData.length>0){
        getQuestionDistData.forEach((ques,index1)=>{
              
                let total = 0;
                  let para = ""
                  if(ques && ques.total && ques.total.length>0){
                    ques.total.forEach((item, index)=>{
                      total += item.count;
                      if(index === 0){
                        para = (item && item._id && item._id.parameterBucketName)?item._id.parameterBucketName:"";

                        if(cardType==='overall'){
                          para = (item && item._id && item._id.parameterBucketName)?item._id.parameterBucketName:"";
                        }
                        if(cardType==='index'){
                          para = (item && item._id && item._id.parameterDimensionName)?item._id.parameterDimensionName:"";
                        }
                      }
                      // para = (item && item._id && item._id.parameterDimensionName)?item._id.parameterDimensionName:"";

                      console.log('para-------->',cardType,para)

                    });
                  }

        
                  let data = {
                    "name":ques && ques.question && ques.question.length>0 && ques.question[0].name?ques.question[0].name:"NaN",
                    "answers":[],
                    "parameter":para
                  }

                  if(ques && ques.total && ques.total.length>0){
                    ques.total.forEach((item, index)=>{
                      let optionName = item._id.answerText;
                      let optionWeightage = item._id.answer;
                      let optionScore = (item.count*100)/total;
                      data["answers"].push(
                        {
                          "name":optionName,
                          "score":optionScore,
                          "total":total,
                          "weightage":optionWeightage
                        }
                      )



                      
                    })
                  }
                  
                  
                  if(cardType==='overall'){
                    QuestionList.push(data);

                  }
                  else if(cardType==='index'){
                      // if(ItemSaveData && ItemSaveData.name && (data.parameter === ItemSaveData.name)){
                        QuestionList.push(data);
                      // }
                  }
          
        });
      }




      let MeanScoreQwiseList = []
      if(currFilterValue !== 'none'){
          if(getMeanScoreQwiseData && getMeanScoreQwiseData.length>0){
            getMeanScoreQwiseData.forEach((ques)=>{
              if(ques.dmgName === currFilterValue){
                if(cardType==='overall'){
                  MeanScoreQwiseList.push(ques);
                }
                else if(cardType==='index'){
                  if(ItemSaveData && ItemSaveData.name && (ques.parameter === ItemSaveData.name)){
                    MeanScoreQwiseList.push(ques);
                  }
                }
              }
            })
          }
      }
      else{
        MeanScoreQwiseList = getMeanScoreQwiseData;
      }


      let FilterValueList = []
      if(getMeanScoreQwiseData && getMeanScoreQwiseData.length>0){
        getMeanScoreQwiseData.forEach((ques)=>{
          if(ques.dmg === currFilterName){
            if(!FilterValueList.some(prev=>prev === ques.dmgName) && ques.dmgName){
              FilterValueList.push(ques.dmgName);
            }
          }
        })
      }
    
    console.log('asdas QuestionList: ',QuestionList);


    let NewQuestionList = []
    if(QuestionList && QuestionList.length>0){
      QuestionList.forEach((ques)=>{

          let favourableScore = 0;
          let totalScore = 0;
          let finalScore = 0;
      
          if(ques && ques.answers.length>0){
      
            ques.answers.forEach((ans)=>{
              totalScore+=parseFloat(ans.score);
              if(ans.weightage===5 || ans.weightage===6){
                favourableScore+=parseFloat(ans.score);
              } 
            });
            finalScore =(favourableScore*100)/totalScore;
          }

          let temp = {
            "name":ques.name,
            "parameter":ques.parameter,
            "favourableScore":finalScore
          }

          NewQuestionList.push(temp);
      
      });
    }

    NewQuestionList.sort((a,b)=>b.favourableScore-a.favourableScore)

    let tempOverallObj = {
      "name":"Overall Question Favourability Mean",
      "parameter":"overall",
      "favourableScore":0
    }
    let QuestionListFinal = []
    if(NewQuestionList && NewQuestionList.length>0){
      NewQuestionList.forEach((item)=>{
        tempOverallObj["favourableScore"] += item.favourableScore;
      });
      tempOverallObj["favourableScore"] /= NewQuestionList.length;   

      QuestionListFinal.push(tempOverallObj);

      NewQuestionList.forEach((item)=>{
        QuestionListFinal.push(item);
      });

    }



    console.log('QuestionListFinal: ',QuestionListFinal);


    return (
      <>
     <div className="xl:flex lg:flex md:flex justify-end items-center">


          <Filter 
                    EmpFilterData = {EmpFilterData}
                    
                    getIndexFilterData={getIndexFilterData}

                    handleDemographicFilter={handleDemographicOverallQFilter}

                    handleFilterSave={this.handleFilterSave}

                    handleFilterValueSave={this.handleFilterValueSave}

                    FilterValueList={FilterValueList}
        
                  />

        </div>  

  
    <div className="container mx-auto px-2 mt-2">
      <div className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 xl:grid-cols-1  gap-2">           
      <div className="bg-white rounded w-full shadow-lg px-2">

      {QuestionListFinal && QuestionListFinal.length>0?
          <div className="py-2  whitespace-nowrap  px-4">
                <table className="border-collapse w-full ">
                  <thead>
                    <tr className=" text-xs   text-gray-800">
                    <th className="py-2 px-4 font-bold"><div className="flex items-center justify-left">
                      {cardType==="overall"?"Index":(indexName==='OrganizationCore'?"Driver":"Dimension")}
                    </div></th>
                    <th className="py-2 px-4 font-bold"><div className="flex items-center justify-center">Question</div></th>
                    <th className="py-2 px-4 font-bold"><div className="flex items-center justify-center">Favourability (%)</div></th>
                    </tr>
                  </thead>
                  <tbody>
                    {QuestionListFinal && QuestionListFinal.length>0?
                      QuestionListFinal.map((question,index)=>
                        <tr key={index} className="bg-white  mb-10 lg:mb-0 text-xs text-gray-800">
                          <td className="tooltip-response-question1 px-2  w-2/12">                             
                          <div className={question.parameter==="overall"? "cursor-pointer font-bold":"cursor-pointer"} style={{width:'20%'}}>
                            {question.parameter==="overall"?(cardType==="overall"?"Overall Index":(indexName==="OrganizationCore"?"Overall Driver":"Overall Dimension")):question.parameter}
                          </div>
                          </td>

                          <td className="tooltip-response-question1 px-2  w-2/12">                             
                          <div className={question.parameter==="overall"? "cursor-pointer font-bold":"cursor-pointer"} style={{width:'20%'}}>
                            {(question && question.name && question.name.length>65)?question.name.slice(0,65)+'...':question.name}
                          </div>
                            <div className="tooltip-response-question2">
                              {question.name}
                            </div>
                          </td>
                          <td className="  w-10/12" >      
                            <div className="">
                              {/* <QuestionStack question={question} /> */}
                              <QuestionScore question={question} />
                            </div>
                          </td>
                        </tr>
                      )
                    :null}

                  </tbody>
                </table>
              </div>
              :
              <div className="py-10 text-center text-lg text-gray-500">No Data Awailable</div>
            }
      </div>
  </div>
</div>



      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ResponseDistribution);
