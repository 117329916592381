import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import ReactTooltip from "react-tooltip";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurveyName: "",
      currentSurveyId: "",
      showDropdown: false,
      gameType: "",

      // showSidebar:false
    };
  }
  componentDidMount() { }

  onDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false });
    } else {
      this.setState({ showDropdown: true });
    }
  };

  logout = () => {
    this.props.dispatch(userActions.logout());
  };

  IndexHoverOptions = (surveyId, indexType) => {
    let { onOptionType } = this.props;

    return (
      <span>
        <i
          className="fa fa-scribd"
          onClick={() => onOptionType(surveyId, indexType, 1)}
        />
        <i
          className="fa fa-quora"
          onClick={() => onOptionType(surveyId, indexType, 2)}
        />
        <i
          className="fa fa-align-left"
          onClick={() => onOptionType(surveyId, indexType, 3)}
        />
        {/* <i className="fa fa-facebook" onClick={()=>onOptionType(surveyId,indexType,4)}/>  */}
      </span>
    );
  };

  handleIndexType = (surveyId, indexType, isDisable, event) => {
    this.setState({ gameType: event });
    let { onOptionType, callOverviewApi } = this.props;
    if (!isDisable) {
      onOptionType(surveyId, indexType, 1);
    } else {
      callOverviewApi();
    }
  };

  sidebarToggle = () => {
    let { handleSidebar, showSidebar } = this.props;
    handleSidebar(!showSidebar);
  };

  render() {
    let { handleMainTabClick, mainTab } = this.props;


    let itemList = [
      {
        label: "Appraisal Cycle",
        value: "AppraisalCycle"
      },
      {
        label: "Normalization",
        value: "Normalization"
      }
    ]

    return (
      <>
        <div className="fixed top-0 left-0 z-20 hidden w-64 h-screen duration-200 transform bg-white border-r cursor-pointer transition-width lg:block lang lg:relative"
          style={{ height: "calc(100% - 0rem)" }} >
          <div className="my-4 whitespace-nowrap ">


            {itemList && itemList.length > 0 ?
              itemList.map((element) =>
                <>
                  <div
                    title="Users"
                    onClick={() => handleMainTabClick(element.value)}
                    className={
                      (mainTab === element.value?"bg-[#2196F3]/10 border-l-4 border-[#2196F3]":"border-l-4 border-white ")+
                      " flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer    "}
                  >
                    <div className={
                      (mainTab === element.value?"text-[#2196F3]":"")+" flex items-center px-4 py-4 w-16 h-12 justify-center"}>
                      <span className=" material-symbols-outlined">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-users"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          strokeWidth="1.8"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <circle cx={9} cy={7} r={4} />
                          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                        </svg>
                      </span>
                    </div>
                    <span className={(mainTab === element.value?"text-[#2196F3]":"")+" text-sm "}>{element.label}</span>
                  </div>
                </>)
              : null}

          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(Sidebar);
