import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import moment from 'moment';
import { CONST } from '../../../../../../../_config';


class MultiTrendChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  openChartList = () => {
    if (this.state.showChartList) {
      this.setState({ showChartList: false })
    }
    else {
      this.setState({ showChartList: true })
    }
  }

  handleChartType = (item) => {
    this.setState({ chartTypeName: item })
  }

  render() {
    let { getIndexTrendChartByMonthData, crrTrendYear } = this.props;

    let colorCodeObj = {
      "1": "#FF4081",
      "2": "#2196F3",
      "4": "#EF5350",
      "5": "#9C27B0",
      "6": "#FFB300",
    }


    

    // let IndexNameListV2 = ["Experience"]

    // let surveyNameListV2 = ["Jan", "Feb", "March", "April", "May", "June"]

    let IndexNameListV2 = []
    let surveyNameListV2 = []


    // console.log("IndexNameListV2-------------->",IndexNameListV2)
    // console.log("surveyNameListV2-------------->",surveyNameListV2)
    // console.log("SeriesItemListV2-------------->",SeriesItemListV2)

    let scoreList2 = []
    if(getIndexTrendChartByMonthData && getIndexTrendChartByMonthData.length>0){
      getIndexTrendChartByMonthData.forEach((element)=>{
        let score = element && element["Data"] && element["Data"]["3"]?element["Data"]["3"]:0;
        if(score){
          surveyNameListV2.push(element.monthName);
          scoreList2.push(score>0?(Number(score)).toFixed(2):0)
        }

      })
    }

    console.log("getIndexTrendChartByMonthData-------------->",getIndexTrendChartByMonthData)

    let IndexNumber = 3;

    function getMarkPoints(scoreListV1){
      let happyEmoji = 'image://'+CONST.BACKEND_URL.replace('5000','3000').replace('/api/v1','')+'/img/emojis/happy.png';
      let sadEmoji = 'image://'+CONST.BACKEND_URL.replace('5000','3000').replace('/api/v1','')+'/img/emojis/sad.png';

      let list = []
      if(scoreListV1 && scoreListV1.length>0){
        scoreListV1.forEach((value,index)=>{
          if(value>0){
            let temp = {
              name: 'coordinate',
              coord: [index, Number(value)],
              symbol:(Number(value)<70)?sadEmoji:happyEmoji,
              symbolSize:25
  
            }
            list.push(temp)
          }
        })
      }

      return list
    }

    let SeriesItemListV2 = {
      name: "Experience",
      type: 'line',
      color: colorCodeObj[IndexNumber],
      data: scoreList2,
      markPoint: {
        data:getMarkPoints(scoreList2)
      }
    }

    function getEmpCount(value,type){
      let final = 0
      if(getIndexTrendChartByMonthData && getIndexTrendChartByMonthData.length>0){
        let getIndex = getIndexTrendChartByMonthData.findIndex(prev=>prev.monthName === value);

        if(getIndex!==-1){
          final = getIndexTrendChartByMonthData[getIndex] && getIndexTrendChartByMonthData[getIndex][type==2?"Count":"Total"]?getIndexTrendChartByMonthData[getIndex][type==2?"Count":"Total"]:0;
        }
      }
      return final
    }


    return (
      <>
        {/* <div className="stack-title">
            {currFilterName} 
          </div> */}
        <div className="stack-body bg-white p-4">
          {scoreList2 && scoreList2.length>0 ?
            <>
              <ReactEcharts
                style={{
                  height: '350px',
                }}
                option={{
                  // title: {
                  //   text: 'Stacked Line'
                  // },

                  tooltip: {
                    trigger: 'axis',
                    formatter: function(list){
                      console.log(list)

                      let value = `<p><b>Month: </b> ${list[0]['name']+"'"+moment(crrTrendYear,"YYYY").format("YY")}</p></b>`;
                      value+=`<p><b>Launched: </b> ${+(getEmpCount(list[0]['name'],1))}</p></b>`
                      value+=`<p><b>Responded: </b> ${+(getEmpCount(list[0]['name'],2))}</p></b>`

                      // value+=`<p style="color:${"#757575"}">${"Launched: "+(getEmpCount(list[0]['name'],1))}</p></b>`
                      // value+=`<p style="color:${"#757575"}">${"Survey Responded = "+(getEmpCount(list[0]['name'],2))}</p></b>`

                      if(list && list.length>0){
                        list.forEach((element)=>{

                          if(element['data']){
                            value+=`<p style="color:${element['color']}">${element['seriesName']+" Score: "+(element['data'])}</p></b>`
                          }

                        })
                      }

                      return (value)

                    } 
                  },
                  legend: {
                    data: IndexNameListV2
                  },
                  grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                  },
                  toolbox: {
                    feature: {
                      saveAsImage: {}
                    }
                  },
                  xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: surveyNameListV2,
                    axisLabel: {
                      color: "#000",
                      fontStyle: "bold",
                      formatter: function(value) {
                        console.log("value-------------->",value)
                        return value+"'"+moment(crrTrendYear,"YYYY").format("YY");
                      }
                    },
                  },
                  yAxis: {
                    type: 'value'
                  },

                  


                  series: SeriesItemListV2
                }}

              />


                <div className='flex justify-center items-center text-center p-4'>
                  <p><b className='font-bold'>X-Axis: </b>Month,</p>
                  <p className='px-4'><b className='font-bold'>Y-Axis: </b>Score</p>
                </div>

            </>
            : <div className='text-xl text-center pt-12 text-gray-300 font-medium'>Data not available</div>}
        </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(MultiTrendChart);
