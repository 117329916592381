import React, { Component } from "react";
import moment from "moment";
// import Header from "../Components/Header";

export default class ReportIntro extends Component {
  render() {
    // let {
    //   getEMPFilterLabel,
    //   FilterValues,
    //   getSurveyData,
    //   surveyLogo,
    //   ReportFilterObj,
    // } = this.props;

    // console.log("getSurveyData--------->", getSurveyData);

    let { surveyName, surveyLogo } = this.props;

    let createdAt = moment(Date.now()).format("DD MMMM, YYYY");


    return (
      <>
        <section className="flex flex-col items-center justify-between space-y-10 ">
          <div className="flex flex-col items-center space-y-8 text-center">
            <div className="flex justify-center pt-6">
              <img
                src={surveyLogo}
                className="w-auto "
                alt="default_survey_logo"
              />
            </div>
            <div className="py-2 space-y-4 text-center">

              <h1 className=" text-4xl text-[#212121] font-normal text-center items-center justify-center uppercase">
                {surveyName}
              </h1>

              <h1 className="text-lg font-medium text-[#212121]/80 italic">
                 {'Overall | '+createdAt}{" "}
                {/* {moment().format("DD/MM/YYYY")}{" "} */}
              </h1>
            </div>
            <div className="flex justify-center">
              <img src="/img/tataexitcover.png" className="w-full mx-auto" />
            </div>
          </div>
          <div class="text-center">
            <p class="text-lg text-[#212121] font-medium">Powered by HappyPlus Copyright 2024</p>
            <p class="text-base font-medium text-[#212121]/70 italic">This report is confidential and should not be distributed without permission.</p>
          </div>
        </section>
      </>
    );
  }
}
