import React from 'react';
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';


export default function BarDistribution(props) {

  let { DistributionData } = props;

  console.log('DistributionData:====> ', DistributionData)

  let List = ['ActivelyDisengaged', 'Passive', 'ModeratelyEngaged', 'HighlyEngaged'];

  let DistObj = {}


  let ScoreList = []

  if (DistributionData) {

    if (List && List.length > 0) {
      List.forEach((item) => {
        ScoreList.push({
          "name": item,
          "score": DistributionData && DistributionData[item] && parseFloat(DistributionData[item]) ? parseFloat(DistributionData[item]) : 0
        })
        DistObj[item] = DistributionData[item] ? DistributionData[item].toFixed(2) : 0;
      });
    }


    // DistObj['ActivelyDisengaged'] = DistributionData['ActivelyDisengaged']? DistributionData['ActivelyDisengaged'].toFixed(2) :0;
    // DistObj['Passive'] = DistributionData['Passive']? DistributionData['Passive'].toFixed(2) :0;
    // DistObj['ModeratelyEngaged'] = DistributionData['ModeratelyEngaged']? DistributionData['ModeratelyEngaged'].toFixed(2) :0;
    // DistObj['HighlyEngaged'] = DistributionData['HighlyEngaged']? DistributionData['HighlyEngaged'].toFixed(2) :0;


  }

  // ScoreList.sort((a,b)=>a.score - b.score)





  let data = [
    {
      value: DistObj['ActivelyDisengaged'],
      itemStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#f1c0c0'
          },
          {
            offset: 1,
            color: '#d12e2e'
          }])
        // color:'#d12e2e'
      }
    },
    {
      value: DistObj['Passive'],
      itemStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#fbe2b6'
          },
          {
            offset: 1,
            color: '#f4ae34'
          }])
      }
    },
    {
      value: DistObj['ModeratelyEngaged'],
      itemStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#d2f2c0'
          },
          {
            offset: 1,
            color: '#a2e37d'
          }])
      }
    },
    {
      value: DistObj['HighlyEngaged'],
      itemStyle: {
        // color:'#00cc66',
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#b3ffd9'
          },
          {
            offset: 1,
            color: '#00cc66'
          }])
      }
    },
  ]



  //console.log('ScoreList------------>: ',ScoreList)


  return (
    <ReactEcharts
      className='!w-full'
      option={{
        tooltip: {
          trigger: 'item',
          formatter: function (a) {
            //console.log(a)
            return a.name + ': <b>' + a.value + '%</b>'
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: "#212121",
            textStyle: {
              fontSize: 12,
              fontWeight: 'normal'
            },
          },
          axisLine: {
            lineStyle: {
              color: '#e6e6e6'
            }
          },
          data: [
           `Actively Disengaged`,
            `Passive`,
            `Moderately Engaged`,
            `Highly Engaged`
          ]
        },
        yAxis: {
          type: 'value',
          max: 100,
          splitLine: {
            lineStyle: {
              color: '#f2f2f2'
            }
          },
          axisLabel: {
            color: "#000",
          }
        },
        series: [{
          data: data,
          type: 'bar',
          barWidth: '50px',
          showBackground: true,
          label: {
            show: true,
            position: 'top',
            color: "#212121",
            textStyle: {
              fontSize: 12,
              fontWeight: '600'
            },
            formatter: function (obj) {
              console.log("value---1->", obj.value)
              return obj.value > 0 ? obj.value + '%' : "0";
            }
          },

        }],
        grid: {
          left: '1%',
          right: '1%',
          bottom: '0%',
          top: '10px',
          containLabel: true
        },
      }}

    />
  );
}
