import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PageTemplate from './PageTemplate';
import EmployeeDropdown from './Components/EmployeeDropdown';
import LanguageDropdown from './Components/LanguageDropdown';

import TopBottom5Themes from './Components/TopBottom5Themes';
import EmployeeWiseTable from './Components/EmployeeWiseTable';
import TeamTop5Themes from './Components/TeamTop5Themes';

import AssessmentScore from './Components/AssessmentScore';
import AssessmentTop5 from './Components/AssessmentTop5';
import AssessmentAllInOne from './Components/AssessmentAllInOne';



class AssessmentReport extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();

    this.hindiPDFstatic = [...Array(700).keys()].map((index) => (React.createRef()))

    this.state = {
      crrSelectedEmp: "",
      languageName: 'English',
      languageShortName: 'en'
    }
  }

  componentDidMount() {

  }

  // exportPDFWithComponent = () => {
  //   if (this.pdfExportComponent.current) {
  //     this.pdfExportComponent.current.save();
  //   }
  // };

  exportPDFWithComponent = async () => {


    if (this.state.languageShortName === 'hi') {


      // const doc = new jsPDF('p', 'mm', [210, 297]);
      // console.log("this.hindiPDFstatic---->", this.hindiPDFstatic)
      // if (this.hindiPDFstatic && this.hindiPDFstatic.length > 0) {
      //   let list = this.hindiPDFstatic.filter(prev => prev.current !== null);
      //   console.log("this.hindiPDFstatic---->", this.hindiPDFstatic)

      //   if (list && list.length > 0) {
      //     console.log("list---->", list)

      //     for (var i = 0; i < list.length; i++) {
      //       let dataUrl = await getImage(list[i].current);
      //       if (dataUrl) {
      //         console.log("i---->", i)
      //         doc.addImage(dataUrl, 'PNG', 0, 0, 210, 287);

      //         if(i<(list.length-1)){
      //           doc.addPage();
      //         }

      //       }
      //     }
      //   }
      //   doc.save("AssessmentReport.pdf");
      // }
      // this.hindiPDFstatic = [...Array(700).keys()].map((index) => (React.createRef()))


    } else {

      if (this.pdfExportComponent.current) {
        this.pdfExportComponent.current.save();
      }

    }




  };


  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { onTeamAssessmentByEmpID } = this.props;
      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = "";
      }
      onTeamAssessmentByEmpID(emp_id)

    })
  }

  handleLanguageLabel = (data) => {
    this.setState({
      languageName: data.name,
      languageShortName: data.shortName
    })
  }



  render() {

    let { getAssessmentTop5DescByNamesData, getAssessmentDimensionsV2Data, getAllAnsweredAssessmentEmpDataList, getAssessmentDimensionsData, onDownloadAssessmentType2ByEmpID, closeReportModal, getTranslateElementAllData, getAssessmentReportByEmpIDData, getAnsweredEmployeesData, getLanguageData, createdAt } = this.props;
    let { languageName, languageShortName, crrSelectedEmp } = this.state;


    const GetTextByLanguage = (name, text) => {
      if (languageShortName && languageShortName !== "en") {
        let sentence = "";
        if (getTranslateElementAllData && getTranslateElementAllData.length > 0) {
          let getIndex = getTranslateElementAllData.findIndex(prev => prev.name === name);
          if (getIndex !== -1) {
            sentence = getTranslateElementAllData && getTranslateElementAllData[getIndex] && getTranslateElementAllData[getIndex]["transl"] && getTranslateElementAllData[getIndex]["transl"][languageShortName] ? getTranslateElementAllData[getIndex]["transl"][languageShortName] : ""
          }
        }
        return sentence ? sentence : text
      } else {
        return text
      }
    }

    const GetDetailsByLanguage = (element, name, text) => {
      if (languageShortName && languageShortName !== "en") {
        let sentence = element && element["details"] && element["details"]["transl"] && element["details"]["transl"][languageShortName] && element["details"]["transl"][languageShortName][name] ?
          element["details"]["transl"][languageShortName][name] : ""
        return sentence ? sentence : text
      } else {
        return text
      }
    }


    function EditButtonFn(name, text) {
      return (
        <>
          {GetTextByLanguage(name, text)}
        </>
      )
    }
    function EditDetailsFn(element, name, text) {
      return (
        <>
          {GetDetailsByLanguage(element, name, text)}
        </>
      )
    }




    function matchDimension(parameterDimensionNames, topDimensions) {
      let check = false;
      let paraList = parameterDimensionNames.split(",");
      const intersection = paraList.filter(element => topDimensions.includes(element.trim()));
      if (intersection && intersection.length > 4) {
        check = true;
      } else {
        check = false;
      }
      return check
    }




    function getHeading(field, list1, list2) {

      let AssessmentList = []
      let topDimensions = [];

      if (list1 && list1.length > 0) {
        list1.forEach((element) => {
          AssessmentList.push({
            ...element,
            finalSum: (Number(element.count) * Number(element.rankSum))
          })
        })
        AssessmentList.sort((b, a) => (a.finalSum - b.finalSum))
      }
      if (AssessmentList && AssessmentList.length > 0) {
        AssessmentList.forEach((ele, index) => {
          if (index < 5) {
            topDimensions.push(ele.dimension);
          }
        })
      }





      let desc = "";
      if (list2 && list2.length > 0) {
        for (var x = 0; x < list2.length > 0; x++) {
          if (matchDimension(list2[x]["parameterDimensionNames"], topDimensions)) {
            desc = list2[x][field];
            break;
          }
        }
      }

      return desc
    }



    return (


      <div className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full bg-black bg-opacity-60" >
        <div className="flex items-start justify-center h-screen p-4 overflow-hidden">

          <div className="mx-auto overflow-hidden overflow-y-auto bg-white rounded-md 2xl:w-7/12 xl:w-9/12 lg:w-5/6 customscroll5 h-[calc(100vh-2rem)]" >
            <div className="sticky top-0 z-20 flex flex-col items-center justify-between p-4 px-6 bg-white border-b lg:flex-row gap-4"  >
              <h1 className="text-base font-semibold text-[#212121] md:text-lg ">
                Team Report
              </h1>

              <div className='flex md:flex-row flex-col items-center gap-2 md:w-auto w-full'>

                <LanguageDropdown
                  getLanguageData={getLanguageData}
                  languageName={languageName}
                  languageShortName={languageShortName}
                  handleLanguageLabel={this.handleLanguageLabel}
                />


                <EmployeeDropdown
                  getAnsweredEmployeesData={getAnsweredEmployeesData}
                  crrSelectedEmp={this.state.crrSelectedEmp}
                  handleSelectEmployee={this.handleSelectEmployee}
                />

                {getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0 ?

                  <div onClick={() => {
                    this.state.languageShortName === 'hi' ?
                      onDownloadAssessmentType2ByEmpID(this.state.crrSelectedEmp && this.state.crrSelectedEmp.emp_id ? this.state.crrSelectedEmp.emp_id : "") :
                      this.exportPDFWithComponent()
                  }}
                    className="flex items-center py-3.5 px-4 text-sm border rounded-md cursor-pointer font-medium text-[#212121] md:w-auto w-full" >
                    <span class="material-symbols-outlined mr-2">
                      file_download
                    </span> Download PDF
                  </div>

                  : null}

                 

                <div onClick={() => closeReportModal(false)} className="inline-flex items-center justify-center w-10 h-10 text-gray-500 bg-gray-100 rounded-full cursor-pointer hover:text-gray-800" >
                  <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                  </svg>
                </div>
              </div>

            </div>






            {getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0 && this.state.crrSelectedEmp && this.state.crrSelectedEmp.emp_id ?
              <div >
                <PDFExport ref={this.pdfExportComponent}
                  pageTemplate={(pageObj) => <PageTemplate pageNum={pageObj.pageNum} />}
                  scale={0.6}
                  forcePageBreak=".page-break"
                  paperSize="A4"
                  margin={0}
                  fileName={'Team Assessment Report'}
                  author="Happyplus Team"
                  landscape={false}
                >

                  <div className=''>

                    <AssessmentScore
                      crrSelectedEmp={this.state.crrSelectedEmp}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                      createdAt={createdAt}
                      hindiPDFstatic={{}}
                    />

                    <AssessmentTop5
                      crrSelectedEmp={this.state.crrSelectedEmp}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                      hindiPDFstatic={{}}
                    />


                    {/* <AssessmentAllInOne
                      crrSelectedEmp={getAssessmentReportByEmpIDData}
                      getAssessmentReportByEmpIDData={getAssessmentDimensionsData}
                      getAssessmenrEmployeeDetailsData={getAssessmentDimensionsData}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                      hindiPDFstatic={{}}
                    /> */}

                    <main className="p-10 px-6 space-y-6 bg-white page-break">
                      <div className='page-break'>
                        <div className="flex items-center justify-between w-full py-4 border-b">
                          <h1 className="text-lg font-semibold">
                            {EditButtonFn(
                              "TalentAssessmentReport",
                              "Team TalentPlusStrengths Assessment Report"
                            )}
                          </h1>
                          <p className="text-lg font-semibold">
                            {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}
                          </p>
                        </div>

                        <div className='py-2 space-y-4'>
                          <p className='text-[#212121]/70'>{getHeading("desc", getAssessmentDimensionsData, getAssessmentTop5DescByNamesData)}</p>
                          <p className='text-[#212121]/70'>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  getHeading("desc1", getAssessmentDimensionsData, getAssessmentTop5DescByNamesData),
                              }}
                            />
                          </p>

                          <h1 className="text-3xl text-[#212121]">
                            Team Overview
                          </h1>

                          <h1 className='text-xl font-medium text-[#212121]'>Team Dominant and Non Dominant Themes</h1>
                          <div className='flex flex-row gap-1'>
                            <TopBottom5Themes
                              getAssessmentDimensionsData={getAssessmentDimensionsData}
                              EditDetailsFn={EditDetailsFn}
                              EditButtonFn={EditButtonFn}
                            />
                          </div>
                        </div>
                      </div>
                    </main>

                    <main className="p-10 px-6 space-y-6 bg-white page-break">
                      <div className="page-break">
                        <div className="flex items-center justify-between w-full py-4 border-b">
                          <h1 className="text-lg font-semibold"> {EditButtonFn("TalentAssessmentReport", "Team TalentPlusStrengths Assessment Report")}</h1>
                          <p className="text-lg font-semibold">
                            {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}
                          </p>
                        </div>

                        <div className='py-2 space-y-4'>
                          <h1 className="text-3xl text-[#212121] pt-2">
                            Team Analytics
                          </h1>
                          <h1 className='text-xl font-medium text-[#212121]'>Compare between Manager and Team Top Themes</h1>

                          {getAssessmentDimensionsV2Data && getAssessmentDimensionsV2Data.length > 0 ?
                            <>
                              <h2 className='p-4 py-2 text-lg font-medium bg-[#F1F5F9]'>Manager's Dominant Themes</h2>
                              <TeamTop5Themes
                                getAssessmentDimensionsData={getAssessmentDimensionsV2Data}
                                EditDetailsFn={EditDetailsFn}
                                EditButtonFn={EditButtonFn}
                              />
                            </>
                            : null}
                          <br />

                          <h2 className='p-4 py-2 text-lg font-medium bg-[#F1F5F9]'>Team's Dominant Themes</h2>

                          <TeamTop5Themes
                            getAssessmentDimensionsData={getAssessmentDimensionsData}
                            EditDetailsFn={EditDetailsFn}
                            EditButtonFn={EditButtonFn}
                          />

                        </div>
                      </div>
                    </main>


                    <main className="p-10 px-6 space-y-6 bg-white page-break">
                      <div className='page-break'>
                        <div className="flex items-center justify-between w-full py-4 border-b">
                          <h1 className="text-lg font-semibold"> {EditButtonFn("TalentAssessmentReport", "Team TalentPlusStrengths Assessment Report")}</h1>
                          <p className="text-lg font-semibold">
                            {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}
                          </p>
                        </div>

                        <div className='py-2 space-y-6'>
                          <h1 className='text-3xl text-[#212121] pt-2'>Team Members Dominant Themes</h1>
                          <EmployeeWiseTable
                            getAllAnsweredAssessmentEmpDataList={getAllAnsweredAssessmentEmpDataList}
                            getAssessmentDimensionsData={getAssessmentDimensionsData}
                            EditDetailsFn={EditDetailsFn}
                            EditButtonFn={EditButtonFn}
                          />
                        </div>
                      </div>
                    </main>

                  </div>



                </PDFExport>
              </div>
              : <div className='py-24 text-2xl font-bold text-center text-gray-500'>{"Please Select Manager"}</div>}

          </div>
        </div >
      </div >

    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AssessmentReport);
