import React from 'react';

export default function Component(props) {
const { question, answersList,handleRadio } = props;
let gridType = question && question.optionsList && question.optionsList.length>0?question.optionsList.length:0;

let designId = question && question.designId?question.designId:"";
let designType = "star";
if(designId==="number"){
  designType = "number"
}
else{
  designType = "star"
}
return(
    <>
      {designType==="star"?
      <>
        <div className={"flex  pt-4 gap-4 md:px-6 px-4 sm:px-40 md:px-40 lg:px-30 xl:px-32 2xl:px-40 justify-center items-center grid md:grid-cols-"+gridType+" grid-cols-"+gridType}>
        {question && question.optionsList && question.optionsList.length>0?
          question.optionsList.map((option, optionindex) =>
          <span key={optionindex}>
         { ((answersList && answersList.length > 0 && answersList.findIndex(item => item.questionId === question.id) !== -1) ?
            answersList[answersList.findIndex(item => item.questionId === question.id)].answer: '')?
                ((optionindex+1)<=answersList[answersList.findIndex(item => item.questionId === question.id)].answer)?
                <div>
                <div onClick={() => handleRadio((optionindex+1), question, option)}  className="px-2 cursor-pointer"><img src="img/questiontype/star/star.png" style={{width:"45px",height:'45px'}} alt="star"/></div>
                <div className='w-24 h-24 pt-2 text-xs font-bold'>{(optionindex===0 || optionindex===(question.optionsList.length-1))?option.name:""}</div>
                </div>
                :
                <div>
                <div onClick={() => handleRadio((optionindex+1), question, option)}  className="px-2 cursor-pointer"><img src="img/questiontype/star/star-outline.png" style={{width:"45px",height:'45px'}} alt="emptystar"/></div>
                <div className='w-24 h-24 pt-2 text-xs font-bold'>{(optionindex===0 || optionindex===(question.optionsList.length-1))?option.name:""}</div>
                </div>
              :
              <div>
              <div onClick={() => handleRadio((optionindex+1), question, option)}  className="px-2 cursor-pointer"><img src="img/questiontype/star/star-outline.png" style={{width:"45px",height:'45px'}} alt="emptystar"/></div>
              <div className='w-24 h-24 pt-2 text-xs font-bold'>{(optionindex===0 || optionindex===(question.optionsList.length-1))?option.name:""}</div>
              </div>}
              </span>
        ):null}
        </div>
      </>
      :null}


    {designType==="number"?
      <>
        <div className={"flex  pt-4 gap-4 md:px-6 px-4 sm:px-40 md:px-40 lg:px-30 xl:px-32 2xl:px-40 justify-center items-center grid md:grid-cols-"+gridType+" grid-cols-"+gridType}>
        {question && question.optionsList && question.optionsList.length>0?
          question.optionsList.map((option, optionindex) =>

          ((answersList && answersList.length > 0 && answersList.findIndex(item => item.questionId === question.id) !== -1) ?
            answersList[answersList.findIndex(item => item.questionId === question.id)].answer: '')?
                ((optionindex+1)<=answersList[answersList.findIndex(item => item.questionId === question.id)].answer)?
                <div>
                <div onClick={() => handleRadio((optionindex+1), question, option)}  className="rounded-md md:p-4 md:py-4 p-4 py-2 border-2 border-blue-500  flex items-center justify-center  bg-blue-400 text-white font-medium  cursor-pointer   px-3">
                  {optionindex+1}
                </div>
                <div className='w-24 h-24 pt-2 text-xs font-bold'>{(optionindex===0 || optionindex===(question.optionsList.length-1))?option.name:""}</div>
                </div>
                :
                <div>
                <div onClick={() => handleRadio((optionindex+1), question, option)}  className="bg-gray-100 rounded-md md:p-4 md:py-4 p-4 py-2 border-2 border-gray-500  flex items-center justify-center  hover:bg-blue-400 hover:text-white font-medium  cursor-pointer   px-3">
                  {optionindex+1}
                  </div>
                <div className='w-24 h-24 pt-2 text-xs font-bold'>{(optionindex===0 || optionindex===(question.optionsList.length-1))?option.name:""}</div>
                </div>
              :
              <div>
              <div onClick={() => handleRadio((optionindex+1), question, option)}  className="bg-gray-100 rounded-md md:p-4 md:py-4 p-4 py-2 border-2 border-gray-500  flex items-center justify-center  hover:bg-blue-400 hover:text-white font-medium  cursor-pointer   px-3">
                {optionindex+1}
                </div>
              <div className='w-24 h-24 pt-2 text-xs font-bold'>{(optionindex===0 || optionindex===(question.optionsList.length-1))?option.name:""}</div>
              </div>
        ):null}
        </div>
      </>
      :null}


    </>
  );}