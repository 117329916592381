import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList: [],
      EmpIndexA: 0,
      EmpIndexB: 4
    }
  }
  componentDidMount() {

  }

  render() {
    let { AnswerObj, name, size, questionName } = this.props;

    let DataList = [];
    let total = 0;
    if (AnswerObj) {
      Object.keys(AnswerObj).forEach(key => {
        let score = parseInt(AnswerObj[key]);
        total += score;
        DataList.push({
          name: key,
          value: score
        });
      });
    }

    //console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaAnswerObj: ',AnswerObj)
    // //console.log('DataList: ',DataList)


    let starRating = 0;
    let tempArray = [1, 2, 3, 4, 5, 6, 7];

    let maxObj = 0

    if (AnswerObj) {

      var keys = Object.keys(AnswerObj);
      var min = AnswerObj[keys[0]];
      maxObj = AnswerObj[keys[0]];
      var i;

      for (i = 1; i < keys.length; i++) {
        var value = AnswerObj[keys[i]];
        if (value < min) min = value;
        if (value > maxObj) maxObj = value;
      }


    }

    // [
    //   {value: 1, name: '7'},
    //   {value: 2, name: '2'},
    //   {value: 3, name: '9'},
    //   {value: 4, name: '10'},
    //   {value: 5, name: '3'}
    // ]


    DataList.sort((a, b) => {
      if (a.name.slice(0, -2) && b.name.slice(0, -2)) {
        return (a.name.slice(5, 6) - b.name.slice(5, 6))
      }
    });


    let LabelList = [];
    let ValueList = [];

    let List = [];

    if (DataList && DataList.length > 0) {
      DataList.forEach((item) => {

        List.push({
          "label": item.name,
          "value": ((parseFloat(item.value) * 100) / total).toFixed(2),
          "itemStyle": { color: '#9933ff' }
        });

        // LabelList.push(item.name);
        // ValueList.push({
        //   value:((parseInt(item.value)*100)/total).toFixed(2),
        //   itemStyle:{color:'#9933ff'}
        // });

      })
    }


    // //console.log('Math.round(starRating):------> ',Math.round(starRating))
    // //console.log('total: ',total)
    function isFloat(n) {
      return Number(n) % 1 != 0;
    }
    // //console.log('Math.round(starRating):------> ',isFloat(parseFloat(starRating)))

    let { stage, index } = this.props;

    function getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    }
    return (
      <>
        <div className="relative flex flex-col justify-between h-full">

          <div className="flex justify-between pb-2">
            <div className="relative inline-block dropdown">

              <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] line-clamp-2">
                {questionName && questionName.length > 80 ? questionName.slice(0, 80) + "..." : questionName}</h1>

              <div className="absolute top-0 z-30 hidden p-4 pt-1 text-sm font-semibold bg-white border rounded-md shadow-md tooltipcard dropdown-menu -left-4">
                {questionName}
              </div>
            </div>
          </div>

          <div className="">
            <h1 className="text-4xl text-center text-[#212121]">{starRating + " Star"}</h1>
            <div className="flex justify-center my-1">
              {tempArray.map((item, index) =>
                ((item) <= Math.round(starRating)) ?
                  (isFloat(starRating) && ((item) === Math.round(starRating))) ?
                    <span className="text-transparent material-icons bg-clip-text bg-gradient-to-r from-yellow-400 to-white">star_rate</span> :
                    <span className="text-[#FF9800] material-icons">star_rate</span>
                  :
                  <span className="text-[#E8E8E8] material-icons">star_rate</span>
              )}
            </div>
          </div>


          {/* progress */}
          <div className="space-y-2 min-h-auto max-h-[360px] w-full px-3 pb-4 overflow-y-auto customscroll4">
            {List && List.length > 0 ?
              List.map((item) =>
                <div className="space-y-1">
                  <p className="text-sm truncate whitespace-nowrap">{item.label + " Star"}</p>
                  <div className="w-full h-6 bg-gray-200">
                    <div style={{ width: item.value + '%' }}
                      className="relative w-3/6 h-6 bg-gradient-to-r from-yellow-400 to-yellow-500" >
                      <div className="absolute left-2 text-sm font-normal text-white p-0.5">{item.value + '%'}</div>
                    </div>
                  </div>
                </div>
              ) : null}

          </div>

          <div className="flex justify-center p-4 mt-auto">
            <button class="text-[#2196f3]  cursor-pointer transition duration-150 flex items-center uppercase font-medium xl:text-base text-sm">{stage == 2 ? "View More" : (stage == 3 ? "" : "View More")}<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right ml-2" width="34" height="34" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2196f3" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="5" y1="12" x2="19" y2="12"></line><line x1="15" y1="16" x2="19" y2="12"></line><line x1="15" y1="8" x2="19" y2="12"></line></svg>
            </button>
          </div>

        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(PieChart);
