import React from "react";

export default function Sidebar(props) {
  let { name, sidebarToggle, showSidebar, routeLink, LanguageObj, CurrentLanguage, accessAllow } = props;


  const isMobile = window.innerWidth <= 700;

  return (
    <>
      <div className={showSidebar ?
        "fixed block  left-0 h-full z-30  w-64 shadow-lg  transition duration-150 transform bg-white overflow-y-auto  overflow-hidden overflow-x-hidden border-r "
        :
        (isMobile ?
          "fixed block  left-0 h-12 z-30  w-16  transition duration-300 transform bg-white overflow-y-hidden  overflow-hidden overflow-x-hidden  "
          :
          "fixed lg:block hidden left-0 h-full z-30  w-16  transition duration-300 transform bg-white overflow-y-auto  overflow-hidden overflow-x-hidden border-r "
        )
      }
        id="customscroll">


        <div className="flex items-center border-b space-x-4">
          <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-16 justify-center">
            <span onClick={() => sidebarToggle()} className="cursor-pointer material-symbols-outlined text-gray-600 bg-gray-100 rounded hover:bg-gray-100 p-2">menu_open</span>
          </div>
          <a href="#/app/overview">
            <img src='/img/plus_ex_logo.svg' className='w-24 object-cover' alt="logo" />
          </a>
        </div>
        <div className="py-4">


          {accessAllow("overview") ?
            <div data-tip="My Surveys" className={name === "overview" ?
              "flex items-center hover:bg-blue-50 text-gray-500 cursor-pointer bg-blue-50  border-r-2 border-blue-500"
              :
              "flex items-center cursor-pointer hover:bg-blue-50 text-gray-500 transition duration-150"
            }
              onClick={() => routeLink("overview")}>
              <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
                <span className={" material-symbols-outlined rounded hover:bg-blue-50 p-2 " + (name === "overview" ? "text-blue-500" : "")}>insert_chart</span>
              </div>
              <span className={"font-normal text-sm " + (name === "overview" ? "text-blue-500" : "")} >{"Overview"}</span>
            </div>
            : null}

          {accessAllow("uploadEmp") ?
            <div data-tip="My Surveys" title="My Employees" className={name === "employees" ?
              "flex items-center hover:bg-blue-50 text-gray-500 cursor-pointer bg-blue-50  border-r-2 border-blue-500"
              :
              "flex items-center cursor-pointer hover:bg-blue-50 text-gray-500 transition duration-150"
            } onClick={() => routeLink("employees")}>

              <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
                <span className={" material-symbols-outlined rounded hover:bg-blue-50 p-2 " + (name === "employees" ? "text-blue-500" : "")}>group</span>
              </div>
              <span className={"font-normal text-sm " + (name === "employees" ? "text-blue-500" : "")} >{LanguageObj && LanguageObj["my_employees_label_" + CurrentLanguage] ? LanguageObj["my_employees_label_" + CurrentLanguage] : "My Employees"}</span>
            </div>
            : null}

          {accessAllow("uploadCust") ?
            <div title="My Customers" className={name === "customers" ?
              "flex items-center hover:bg-blue-50 text-gray-500 cursor-pointer bg-blue-50  border-r-2 border-blue-500"
              :
              "flex items-center cursor-pointer hover:bg-blue-50 text-gray-500 transition duration-150"
            } onClick={() => routeLink("customers")}>
              <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
                <span className="material-symbols-outlined rounded hover:bg-blue-50 p-2">groups</span>
              </div>
              <span className="font-normal text-sm">{LanguageObj && LanguageObj["my_customers_label_" + CurrentLanguage] ? LanguageObj["my_customers_label_" + CurrentLanguage] : "My Customers"}</span>
            </div>
            : null}

          {accessAllow("uploadUser") ?
            <div title="My Users" className={name === "users" ?
              "flex items-center hover:bg-blue-50 text-gray-500 cursor-pointer bg-blue-50  border-r-2 border-blue-500"
              :
              "flex items-center cursor-pointer hover:bg-blue-50 text-gray-500 transition duration-150"
            } onClick={() => routeLink("users")}>
              <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
                <span className={" material-symbols-outlined rounded hover:bg-blue-50 p-2 " + (name === "users" ? "text-blue-500" : "")}>admin_panel_settings</span>
              </div>
              <span className={"font-normal text-sm " + (name === "users" ? "text-blue-500" : "")} >{"My Users"}</span>
            </div>
            : null}


          {accessAllow("parameterSetting") ?
            <div title={"Index and Dimension Settings"} className={name === "parametersetting" ?
              "flex items-center hover:bg-blue-50 text-gray-500 cursor-pointer bg-blue-50  border-r-2 border-blue-500"
              :
              "flex items-center cursor-pointer hover:bg-blue-50 text-gray-500 transition duration-150"
            } onClick={() => routeLink("parametersetting")}>
              <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
                <span className={" material-symbols-outlined rounded hover:bg-blue-50 p-2 " + (name === "parametersetting" ? "text-blue-500" : "")}>view_list</span>
              </div>
              <span className={"font-normal text-sm " + (name === "parametersetting" ? "text-blue-500" : "")} >{"Index & Dimension"}</span>

            </div>
            : null}

          {accessAllow("filterSetting") ?
            <div title={"Filter Setting"} className={name === "filterSetting" ?
              "flex items-center hover:bg-blue-50 text-gray-500 cursor-pointer bg-blue-50  border-r-2 border-blue-500"
              :
              "flex items-center cursor-pointer hover:bg-blue-50 text-gray-500 transition duration-150"
            } onClick={() => routeLink("filterSetting")}>

              <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
                <span className={" material-symbols-outlined rounded hover:bg-blue-50 p-2 " + (name === "filterSetting" ? "text-blue-500" : "")}>settings</span>
              </div>
              <span className={"font-normal text-sm " + (name === "filterSetting" ? "text-blue-500" : "")} >{"Filters Settings"}</span>

            </div>
            : null}




        </div>
      </div>

    </>
  );
}
