import React from "react";

function DataNotFound() {
    return (

        <div className="text-center mt-10 flex flex-col items-center justify-center gap-4 py-4">

            <div className=' '>
                <svg xmlns="http://www.w3.org/2000/svg" width="320" height="320" viewBox="0 0 320 320" fill="none">
                    <rect width="320" height="320" rx="160" fill="#F9FAFB" />
                    <g clip-path="url(#clip0_5535_4787)">
                        <path d="M118.504 142.643L160.171 118.833L195.837 139.214C197.66 140.256 199.175 141.76 200.229 143.576C201.283 145.392 201.838 147.454 201.837 149.553V177.399C201.838 179.498 201.283 181.56 200.229 183.376C199.175 185.192 197.66 186.697 195.837 187.738L166.075 204.744C164.277 205.771 162.242 206.312 160.171 206.312C158.099 206.312 156.064 205.771 154.266 204.744L124.504 187.738C122.681 186.697 121.166 185.192 120.112 183.376C119.058 181.56 118.504 179.498 118.504 177.399V160.72" stroke="#B0BEC5" stroke-width="5.95238" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M154.531 163.417C156.266 164.35 158.204 164.839 160.174 164.839C162.144 164.839 164.083 164.35 165.817 163.417L198.865 145.619M160.174 166.452V205.143" stroke="#B0BEC5" stroke-width="5.95238" stroke-linecap="round" stroke-linejoin="round" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M118.506 142.643L160.172 166.452L142.315 172.405L100.648 148.595L118.506 142.643ZM160.172 118.833L201.839 142.643L213.744 130.738L172.077 106.928L160.172 118.833Z" stroke="#B0BEC5" stroke-width="5.95238" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <g opacity="0.5">
                        <path d="M228.318 108.264C228.318 102.94 219.406 94.0278 214.082 94.0278C219.406 94.0278 228.318 85.1157 228.318 79.7917C228.318 85.1157 237.23 94.0278 242.554 94.0278C237.23 94.0278 228.318 102.94 228.318 108.264Z" fill="#B0BEC5" />
                    </g>
                    <path d="M228.293 100.875C228.293 98.3039 223.989 94 221.418 94C223.989 94 228.293 89.6961 228.293 87.125C228.293 89.6961 232.597 94 235.168 94C232.597 94 228.293 98.3039 228.293 100.875Z" fill="#B0BEC5" />
                    <path d="M242.771 208.472C242.771 203.522 234.485 195.236 229.535 195.236C234.485 195.236 242.771 186.95 242.771 182C242.771 186.95 251.057 195.236 256.007 195.236C251.057 195.236 242.771 203.522 242.771 208.472Z" fill="#B0BEC5" />
                    <path d="M89.0039 111C89.0039 107.634 83.3699 102 80.0039 102C83.3699 102 89.0039 96.366 89.0039 93C89.0039 96.366 94.6379 102 98.0039 102C94.6379 102 89.0039 107.634 89.0039 111Z" fill="#B0BEC5" />
                    <path opacity="0.5" d="M79.5 242C79.5 230.966 61.034 212.5 50 212.5C61.034 212.5 79.5 194.034 79.5 183C79.5 194.034 97.966 212.5 109 212.5C97.966 212.504 79.5 230.966 79.5 242Z" fill="#B0BEC5" />
                    <path d="M79.495 224.118C79.495 219.774 72.221 212.498 67.875 212.498C72.219 212.498 79.495 205.222 79.495 200.878C79.495 205.222 86.771 212.498 91.115 212.498C86.767 212.5 79.495 219.772 79.495 224.118Z" fill="#B0BEC5" />
                    <defs>
                        <clipPath id="clip0_5535_4787">
                            <rect width="125" height="125" fill="white" transform="translate(97.6719 98)" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div className='text-center'>
                <h2 className='text-[#212121] text-2xl font-semibold'>   Data Not Found</h2>
                <p className='text-lg text-[#212121]/70'>Opps! Looks like there isn't any data yet. </p>
            </div>

        </div>
    )

}
export default DataNotFound;