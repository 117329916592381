import React, { Component } from 'react';
import { connect } from 'react-redux';
// import CoreValue from './Components/CoreValue/CoreValue';
import Overview from './Components/Overview/Overview';
import RWA from './Components/RWA/RWA';
import DimensionPriorities from './Components/DimensionPriorities/DimensionPriorities';
// import ResponseDistribution from './Components/ResponseDistribution/ResponseDistribution';
import DimensionPrioritiesMap from './Components/DimensionPrioritiesMap/DimensionPrioritiesMap';
import ResponseDistribution from './Components/ResponseDistribution/ResponseDistribution';


class OrgCore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType:1,
      DimensionList:[

      ],
    }
  }
  componentDidMount() {

  }


  onOptionType=(data, validateList)=>{
    this.setState({optionType:data});
    if(data===2){
      let { onRWAnalysis } = this.props;
      onRWAnalysis([],validateList&&validateList.length?validateList[0].template:1);
    }
    else if(data===3 || data===4 || data===6){
      let { onCustomQuestion } = this.props;
      onCustomQuestion();
    }
  }

  onSwitchOptionScreen=(validateList)=>{
    let { handleMultifilterOrgDriverSummary, indexType,getIndexByFilterDMGData, getCommentData ,loading,GetParameterLabel, CountRestrict ,
       EmpFilterData, getIndexFilterData, getEngagementRWAData, rwaIndexType,handleRWAFilter,getSurveyData,validateIndex,getEMPFilterLabel,
       
      getAllBenchMarkData, getIndexScore, handleSummaryFilter, handleSummaryCard, getDemographicIndexData, getIndexData,getIndexDistributionData,
      getQuestionData,getQuestionFilterData, handleQuestionFilter,  handleMultiFilterGetCustomQuestion,
      getDistributionIndexData,getEDAOrgdata,
      // getDistributionIndexData,handleDistributionFilter,getIndexDriverData, getIndexQuantileData
      onRWAnalysis, handleFilterRWA, getQuestionMapData, getdmgOrgManyFilterData,



      getQuestionAvgScoreData,
      customeScoreData,
      getQuestionCustomTemplateData,
      handleMultifilterQuesAvgSummary


    } = this.props;

    let { optionType } = this.state;

    ////////console.log("getQuestionCustomTemplateData------------.",getQuestionCustomTemplateData)

    switch(optionType){

        case 1: return <Overview 

        getQuestionAvgScoreData={getQuestionAvgScoreData}
        customeScoreData={customeScoreData}
        handleMultifilterQuesAvgSummary={handleMultifilterQuesAvgSummary}


        getEDAOrgdata={getEDAOrgdata} getdmgOrgManyFilterData={getdmgOrgManyFilterData}
        getIndexByFilterDMGData={getIndexByFilterDMGData} getEMPFilterLabel={getEMPFilterLabel}
        CountRestrict={CountRestrict} validateIndex={validateIndex}  
        GetParameterLabel={GetParameterLabel} getDistributionIndexData={getDistributionIndexData}   
        handleMultifilterOrgDriverSummary={handleMultifilterOrgDriverSummary} 
        loading={loading} getSurveyData={getSurveyData} indexType={indexType} optionType={optionType} 
        getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} 
        getAllBenchMarkData={getAllBenchMarkData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} 
        handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard} getDemographicIndexData={getDemographicIndexData} 
        getIndexData={getIndexData} />

        // case 2: return <RWA getIndexByFilterDMGData={getIndexByFilterDMGData} getEMPFilterLabel={getEMPFilterLabel}
        // CountRestrict={CountRestrict}  validateIndex={validateIndex}  GetParameterLabel={GetParameterLabel}   validateList={validateList}     loading={loading} handleFilterRWA={handleFilterRWA}
        // indexType={indexType} optionType={optionType} getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} 
        // getEngagementRWAData={getEngagementRWAData}
        // onRWAnalysis={onRWAnalysis} rwaIndexType={rwaIndexType} handleRWAFilter={handleRWAFilter}
        // />

        case 3: return <DimensionPriorities  
        
        // getQuestionCustomTemplateData={getQuestionCustomTemplateData}

        getIndexByFilterDMGData={getIndexByFilterDMGData} getEMPFilterLabel={getEMPFilterLabel}
        CountRestrict={CountRestrict} GetParameterLabel={GetParameterLabel}   loading={loading}    handleMultiFilterGetCustomQuestion={handleMultiFilterGetCustomQuestion}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} 
        getQuestionData={getQuestionCustomTemplateData} getQuestionFilterData={getQuestionFilterData} handleQuestionFilter={handleQuestionFilter}/>
      
        // case 6: return <DimensionPrioritiesMap  getIndexByFilterDMGData={getIndexByFilterDMGData} getEMPFilterLabel={getEMPFilterLabel}
        // CountRestrict={CountRestrict} GetParameterLabel={GetParameterLabel}   loading={loading}    handleMultiFilterGetQuestion={handleMultiFilterGetCustomQuestion}
        // indexType={indexType} optionType={optionType} getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} 
        // getQuestionMapData={getQuestionCustomTemplateData} getQuestionData={getQuestionData} getQuestionFilterData={getQuestionFilterData} handleQuestionFilter={handleQuestionFilter}/>
      

        case 4: return <ResponseDistribution  getIndexByFilterDMGData={getIndexByFilterDMGData} handleMultiFilterGetCustomQuestion={handleMultiFilterGetCustomQuestion}
        CountRestrict={CountRestrict}  GetParameterLabel={GetParameterLabel}   loading={loading}   handleMultiFilterGetQuestion={handleMultiFilterGetCustomQuestion}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} 
        getQuestionData={getQuestionCustomTemplateData} getQuestionFilterData={getQuestionFilterData} handleQuestionFilter={handleQuestionFilter}/>
      
      
      
      default: return <div>Default Option</div> 
    }
  }

  render() {
    let { optionType ,DimensionList} = this.state;

    let { customeScoreData } = this.props;


    return (
      <> 
      {/* <main className="flex-1 w-full bg-gray-100 overflow-hidden overflow-y-auto ">
      <div className="px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b h-10">
        <div className="cursor-pointer text-xs font-medium flex text-gray-600 whitespace-nowrap overflow-x-auto overflow-hidden">
          <span onClick={()=>this.onOptionType(1)} className={(optionType===1)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Overview</span>
          <span onClick={()=>this.onOptionType(2,validateList)} className={(optionType===2)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>RWA</span>
          <span onClick={()=>this.onOptionType(3)} className={(optionType===3)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Item Ranking</span>
          <span onClick={()=>this.onOptionType(4)} className={(optionType===4)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Detailed Item Distribution</span>
        </div>
      </div>
          {this.onSwitchOptionScreen(validateList)}
      </main>  */}



        {/* side bar end */}
        <div className="md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto bg-gray-100">
          <div className="flex justify-between text-gray-800 items-center"> <h1 className="text-lg font-medium ">{customeScoreData && customeScoreData.index?customeScoreData.index:""}</h1> 
          {/* <span className="material-symbols-outlined cursor-pointer lg:hidden flex">menu_open</span> */}
          </div>
          {/*sub nav bar 1 */}
          <div className="  md:flex  justify-between items-center border-b">
            <div className="text-xs font-medium flex text-gray-600 whitespace-nowrap overflow-x-auto overflow-hidden space-x-4 tab-scroll">
              <span onClick={()=>this.onOptionType(1)} className={optionType===1?"cursor-pointer py-3 block text-blue-500 border-blue-500  border-b-2":"cursor-pointer py-3 block "}> Overview </span>
              {/* <span onClick={()=>this.onOptionType(2)} className={optionType===2?"cursor-pointer py-3 block text-blue-500 border-blue-500  border-b-2":"cursor-pointer py-3 block "}> RWA </span> */}
              <span onClick={()=>this.onOptionType(3)} className={optionType===3?"cursor-pointer py-3 block text-blue-500 border-blue-500  border-b-2":"cursor-pointer py-3 block "}> Item Ranking </span>
              {/* <span onClick={()=>this.onOptionType(6)} className={optionType===6?"cursor-pointer py-3 block text-blue-500 border-blue-500  border-b-2":"cursor-pointer py-3 block "}> Item Ranking Detail</span> */}
              <span onClick={()=>this.onOptionType(4)} className={optionType===4?"cursor-pointer py-3 block text-blue-500 border-blue-500  border-b-2":"cursor-pointer py-3 block "}> Detailed Item Distribution </span>

            </div>
          </div>

          {this.onSwitchOptionScreen()}

        </div>




      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(OrgCore);
