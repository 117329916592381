import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filter from '../../../../../CommonComponents/Filters/IndexFilter/Filter';
import HeatMap from './Components/HeatMap';
import DonutChart from '../../../../Overview/Components/QuestionTypeCards/Charts/DonutChart';
import { CSVLink } from "react-csv";

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      viewScore: true,
      showLeaderBoard: false,
      showFilter: false,
      showValue: '',
      XAxis: ""
    }
  }
  componentDidMount() {

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleFilterSave = (name) => {
    this.setState({ currFilterName: name })
  }

  handleMultifilterIndexScore2 = (finalFilter, XAxis) => {
    let { handleOthersFilterCall } = this.props;
    this.setState({ FilterValues: finalFilter, XAxis: XAxis });
    handleOthersFilterCall(finalFilter, XAxis, 11);
  }

  render() {

    let { getQuestionOthersResultData, currentQuestionId, EmpFilterData, getQuestionOthersResultFilterData } = this.props;
    let { currFilterName, FilterValues, XAxis } = this.state
    let nEwAxis = "";

    if (XAxis) {
      nEwAxis = XAxis;
    }
    else if (EmpFilterData && EmpFilterData.length > 0) {
      nEwAxis = EmpFilterData[0].name;
    }

    let QuestionName = "";

    function FormattFun(inputData) {
      let LIST = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {
          if (currentQuestionId === ques.total[0]._id.questionId) {
            let temp = {
              name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
              answerList: []
            }
            QuestionName = temp["name"];

            if (ques && ques.total && ques.total.length > 0) {
              ques.total.forEach((item) => {
                //console.log('item: -------->nEwAxis',item)
                let ans = {
                  count: item.count,
                  answerText: item["_id"]["answerText"],
                  template: item["_id"]["qtemplate"],
                  dmgValue: item["_id"][nEwAxis]
                }
                temp["answerList"].push(ans);
              });
            }
            LIST.push(temp);
          }
        });
      }
      return LIST
    }

    let NPSQuesList = FormattFun(getQuestionOthersResultData);
    let NPSQuesListFilter = FormattFun(getQuestionOthersResultFilterData);
    let totalCount = 0;
    let Promoters = 0;
    let Passsives = 0;
    let Distractors = 0;
    let DemographicValues = [];
    let DemographicValuesObj = {};
    let item = NPSQuesList[0];
    let itemOthers = NPSQuesListFilter[0];

    let AnswerObjOverall = {};
    if (item && item.answerList && item.answerList.length > 0) {
      item.answerList.forEach((ans) => {
        if (AnswerObjOverall && AnswerObjOverall[ans.answerText]) {
          AnswerObjOverall[ans.answerText] += (ans.count);
        }
        else {
          AnswerObjOverall[ans.answerText] = (ans.count)
        }
      });
    }

    let AnswerObj = {};
    let AnswerObjList = [];

    if (itemOthers && itemOthers.answerList && itemOthers.answerList.length > 0) {
      itemOthers.answerList.forEach((ans) => {
        let getIndex = DemographicValues.findIndex(prev => prev === ans.dmgValue);
        if (getIndex === -1) {
          DemographicValues.push(ans.dmgValue);
          DemographicValuesObj[ans.dmgValue] = {
            "Yes": 0,
            "No": 0,
            "count": 0
          }
        }
        let code = ans.answerText + ans.dmgValue;
        let getIndex1 = AnswerObjList.findIndex(prev => prev.code === code);
        if (getIndex1 === -1) {
          AnswerObjList.push({
            "text": ans.answerText,
            "name": ans.dmgValue,
            "value": ans.count,
            "code": ans.answerText + ans.dmgValue
          });
        }
        else {
          AnswerObjList[getIndex1].value += ans.count;
        }
      });
    }

    if (AnswerObjList && AnswerObjList.length > 0) {
      AnswerObjList.forEach(item1 => {
        let key = item1.text;
        let name = item1.name;
        let score = item1.value;
        totalCount++;
        DemographicValuesObj[name]["count"] += score;
        if ((key) === "Yes") {
          DemographicValuesObj[name]["Yes"] += score;
        }
        if ((key) === "No") {
          DemographicValuesObj[name]["No"] += score;
        }
      });
    }

    let PromotersPer = (Promoters * 100 / totalCount);
    let PasssivesPer = (Promoters * 100 / totalCount);
    let DistractorsPer = (Distractors * 100 / totalCount);
    let npsScore = ((PromotersPer) - (DistractorsPer)).toFixed(2);
    let PromotersColorRange = PromotersPer / 100;
    let PasssivesColorRange = (PasssivesPer / 100) + PromotersColorRange;
    let DistractorsColorRange = (DistractorsPer / 100) + PasssivesColorRange;
    let { getIndexFilterData } = this.props;
    let textData = []
    let filter = FilterValues && FilterValues.length > 0 ? " (" + FilterValues.map((element) => { return element.value }).join(",") + ")" : " (Filter)"

    if (DemographicValuesObj && DemographicValues && DemographicValues.length > 0) {
      DemographicValues.forEach((item) => {
        if (item) {
          let value = DemographicValuesObj[item];
          let temp = {}
          temp[XAxis + filter] = item;

          if (value["count"] > 5) {
            temp["Yes"] = (value["Yes"] * 100) / value["count"];
            temp["No"] = (value["No"] * 100) / value["count"];
          } else {
            temp["Yes"] = "NaN";
            temp["No"] = "NaN";
          }
          textData.push(temp)
        }
      });
    }

    return (
      <>
        <div className="items-center justify-between h-10 xl:flex lg:flex md:flex">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData}
            getIndexFilterData={getIndexFilterData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />
        </div>

        <div onClick={() => this.closeFilter(false)} className="">
          <div className="flex w-full" >

            {!(FilterValues && FilterValues.length > 0) && false ?
              <>
                <div className="w-full px-4 pb-10 m-4 bg-white rounded shadow-lg">
                  <h1 className="py-4 text-lg font-semibold">{QuestionName}</h1>
                  {item && item.answerList[0] && item.answerList[0].template === 11 ?
                    <DonutChart AnswerObj={AnswerObjOverall} name={"Overall"} templateId={11} stage={3} />
                    : null}
                </div>
              </>
              :
              <div className="w-full p-5 mt-4 bg-white rounded shadow-lg mb-60">
                <div className="flex items-center justify-between px-1 text-base font-semibold text-gray-700">
                  <h1 className="text-lg font-semibold">{QuestionName}
                    <p className="text-base font-semibold text-gray-700">
                      <span className="text-blue-500">{(currFilterName !== 'none') ? "( " + currFilterName + " wise )" : ""}</span>
                    </p>
                  </h1>
                  {textData && textData.length > 0 ?
                    <CSVLink data={textData} filename={"TextData.csv"}>
                      <div className="w-32 px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded-lg cursor-pointer">{"Export CSV"}</div>
                    </CSVLink>
                    : ''}
                </div>

                <HeatMap
                  AnswerObjOverall={AnswerObjOverall}
                  DemographicValues={DemographicValues}
                  DemographicValuesObj={DemographicValuesObj}
                  XAxis={XAxis}
                />
              </div>
            }

          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
