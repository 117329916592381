import { data } from 'autoprefixer';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class NudgesOrgOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
        showModal:false
    }
  }
  componentDidMount() {

  }

  openHelpModal=(check)=>{
    this.setState({showModal:check})
  }

  render() {
    let { name, optionType, dataItem, DistList,IndexNme, CountRestrict, XAxisList, GetParameterLabel, getOverallScore, getFavouribilityScore } = this.props;
    let { showModal } = this.state;


    let { valueList, currFilterValue, currFilterName,
      getDistributionIndexData}= this.props;




    // function showNaN(item, name) {
    //   let dataScore = CountRestrict(item.count) ? "NA" : (item && item[name] && item[name]?item[name].toFixed(2):0);
    //   return dataScore
    // }





    //-----------------------Nudeges-------------------------------------------------
    let textLabel = "";
    let dataListAsc = [];
    let dataListDsc = [];
    let dataListDetail = [];
    
 
    let YAxis= []
    let XAxis= []
    let XAxisName= []
    let DataValues = []
    let list = []
    let overallData = {
      name: "Overall"
    }


    if(optionType==="itemdetails"){
       textLabel = "Performing "+IndexNme+" Drivers";


     //console.log('getDistributionIndexData: ',getDistributionIndexData)


     if(valueList && valueList.length>0){
       valueList.forEach((val)=>{
         XAxis.push(val[0]);
         XAxisName.push(val[0]+"Avg");
 
 
 
           overallData[val[0]+"Avg"] = val[1];
 
           if(list.findIndex(prev=>prev.name === "Overall")===-1){
             list.push(overallData)
           }
        
       });
     }
 
 
 
     
     if(getDistributionIndexData && getDistributionIndexData.length>0){
 
        getDistributionIndexData.forEach((hello)=>{
         list.push(hello);
        });
 
       
 
 
       list.forEach((item,index)=>{
         XAxisName.forEach((axis,axisIndex)=>{
           
           if(index!==(list.length-1)){
             DataValues.push({
               value:[axisIndex,index,parseFloat(item[axis]).toFixed(2)],
               itemStyle:{color:
                 (parseFloat(overallData[axis]) > parseFloat(item[axis]))?
                   (parseFloat(item[axis])===0)?'#ffb3b3':'rgba(230, 0, 0,'+parseFloat(item[axis])/100+')'
                   :
                   (parseFloat(item[axis])===0)?'#c2f0c2':'rgba(46, 184, 46,'+parseFloat(item[axis])/100+')'
               }
             })
           }
           else{
             DataValues.push({
               value:[axisIndex,index,parseFloat(item[axis]).toFixed(2)],
               itemStyle:{color:(parseFloat(item[axis])===0)?'#b3ecff':'rgba(0, 172, 230,'+parseFloat(item[axis])/100+')'}
             })  
           }
 
         })
       })
 
 
 
     }
 
 
       if(list && list.length>0){
         list.forEach((driver)=>{
           YAxis.push(driver.name)
         })
       }
 
 
     


    }
 




    function showNaN (item,name){
      let dataScore = CountRestrict(item.count, item.name==='Overall')?"NA":(  name && item && item[name]? (item[name]).toFixed(2):"NA"  );
      return dataScore
    }

    function getColor(data, index) {

      if(data.name==="Overall" || showNaN(data, index)==="NA"){
        return {Color:'#ffffff',diffTextColor:'#000'}

      }
      else{

        let diffNo = parseInt(Math.abs(parseFloat(overallData[index]) - parseFloat(data[index])));
        if(parseFloat(overallData[index]) <= parseFloat(data[index])){ //greater (blue)
          if(diffNo>=0 && diffNo<=2){
            return {Color:'#33cc33',diffTextColor:'#ffffff'}

          }
          else if(diffNo>=2 && diffNo<=12){
            return {Color:'#70db70',diffTextColor:'#00000'}

          }
          else{
            return {Color:'#adebad',diffTextColor:'#00000'}
          }

        }
        else{ //lesse (red)
          if(diffNo>=0 && diffNo<=2){
            return {Color:'#f5babd',diffTextColor:'#000000'}

          }
          else if(diffNo>=2 && diffNo<=12){
            return {Color:'#ea95a1',diffTextColor:'#000000'}

          }
          else{
            return {Color:'#d03e4d',diffTextColor:'#ffffff'}

          }
        }
      }

    }






    function showLowScore(data, index) {
      if(parseFloat(overallData[index]) <= parseFloat(data[index])){ //greater (blue)
        return false
      }
      else{ //lesse (red)
        return true

      }
    }
    function showHighScore(data, index) {
      if(parseFloat(overallData[index]) <= parseFloat(data[index])){ //greater (blue)
        return true
      }
      else{ //lesse (red)
        return false

      }
  }




    return (
      <>
      { true? 
        <>
          {dataItem && dataItem.show?
      <>
        
        {true?<span onClick={()=>this.openHelpModal(true)} className="cursor-pointer text-xs font-medium text-blue-500 bg-blue-100 px-4 py-2.5 rounded-full">Nudges</span>:null}

        {showModal?
        <div className="bg-black bg-opacity-40 fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full flex items-center justify-center md:p-0 p-4" >
        <div className="xl:w-8/12 lg:w-5/6 w-5/6  mx-auto bg-white rounded-md mb-20  ">
          
        <div className="flex items-center justify-between p-4 border-b"> <h1 className="text-lg p-1 font-semibold capitalize">{name}</h1>
            <span onClick={() =>this.openHelpModal(false)} className=" material-symbols-outlined bg-gray-50 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150">close</span></div>

          <div className='px-4' style={{overflowY:'auto',overflowX:'auto', height:'500px'}}>


        {optionType==="itemdetails"?
          <>
          <div className="p-6  " >
              <div className='p-2 ' >
                
                
                <div className="space-y-6">
                  <div className="text-left text-lg font-medium" >

                    {textLabel?<div className='text-left'>{"Low "+textLabel}</div>:null}


                  </div>


                  <div className='p-4'>
                  <table className="w-full border ">
                  <thead>
                  {list && list.length>0?
                      list.map((item,index)=>
                      (index===0)?
                        <tr className="bg-gray-100 border-b text-xs   text-gray-800">
                          <th className="py-2 px-4 border-r font-bold"><div className="flex items-center justify-left">{currFilterName}</div></th>
                          
                          {XAxis && XAxis.length>0?
                            XAxis.map((driver)=>
                              <th className="py-2 px-4 border-r font-bold"><div className="flex items-center justify-center">{GetParameterLabel(driver)} {(item[driver+"Avg"])?" ("+item[driver+"Avg"].toFixed(2)+")":"NA"}</div></th>

                            )
                            :null}
                        
                        </tr>
                      :null
                    ):null}
                  </thead>
                  <tbody>
                    {list && list.length>0?
                      list.map((item,index)=>
                      (index!==0)?
                        <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                          <td className="p-3 border border-b text-left" style={{fontWeight:(item.name==='Overall')?'bold':null}}>    
                            {(item.name)?item.name:'Unavailable'}
                          </td>




                            

                          {XAxisName&&XAxisName.length>0?
                            XAxisName.map((driver)=>
                              <td  className="cursor-pointer p-3 border border-b" style={{
                                background:showLowScore(item,driver)?getColor(item,driver).Color:"#ffffff",
                                color:showLowScore(item,driver)?getColor(item,driver).diffTextColor:"#757575"
                              }}>      
                              {showLowScore(item,driver)?(item&&(item[driver])?showNaN(item,driver) :"NA"):"NA"}
                              </td>


                            )
                            :null}
                        </tr>
                        :null
                      ):null}

                  </tbody>
                  </table>



                    
                  </div>


                  


                </div>


                <div className="space-y-6 mt-4">
                  <div className="text-left text-lg font-medium" >

                    {textLabel?<div className='text-left'>{"High "+textLabel}</div>:null}


                  </div>


                  <div className='p-4'>
                  <table className="w-full border ">
                  <thead>
                  {list && list.length>0?
                      list.map((item,index)=>
                      (index===0)?
                        <tr className="bg-gray-100 border-b text-xs   text-gray-800">
                          <th className="py-2 px-4 border-r font-bold"><div className="flex items-center justify-left">{currFilterName}</div></th>
                          
                          {XAxis && XAxis.length>0?
                            XAxis.map((driver)=>
                              <th className="py-2 px-4 border-r font-bold"><div className="flex items-center justify-center">{GetParameterLabel(driver)} {(item[driver+"Avg"])?" ("+item[driver+"Avg"].toFixed(2)+")":"NA"}</div></th>

                            )
                            :null}
                        
                        </tr>
                      :null
                    ):null}
                  </thead>
                  <tbody>
                    {list && list.length>0?
                      list.map((item,index)=>
                      (index!==0)?
                        <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                          <td className="p-3 border border-b text-left" style={{fontWeight:(item.name==='Overall')?'bold':null}}>    
                            {(item.name)?item.name:'Unavailable'}
                          </td>




                            

                          {XAxisName&&XAxisName.length>0?
                            XAxisName.map((driver)=>
                              <td  className="cursor-pointer p-3 border border-b" style={{
                                background:showHighScore(item,driver)?getColor(item,driver).Color:"#ffffff",
                                color:showHighScore(item,driver)?getColor(item,driver).diffTextColor:"#757575"
                              }}>      
                              {showHighScore(item,driver)?(item&&(item[driver])?showNaN(item,driver) :"NA"):"NA"}
                              </td>


                            )
                            :null}
                        </tr>
                        :null
                      ):null}

                  </tbody>
                  </table>



                    
                  </div>


                  


                </div>
                
                

              </div>


            </div>

          </>
          :null
          }


          </div>

        </div>




        </div>
        :null}

      </>
      :null}

        </>
      :null}
    

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(NudgesOrgOverview);
  