import React from 'react';
import ReactEcharts from "echarts-for-react";


export default function BarCorelation(props) {
    
  let { DriverName, DriverValues, TotalOverAllCount } = props;


  let XAxis = DriverName
  let List = []


  
  if(DriverValues && DriverValues.length>0){
    DriverValues.forEach((item4)=>{
      List.push((item4*100)/TotalOverAllCount)
    });
  }


  return (
    <>	
      <div className="pt-1 pb-20 -mb-20">

        <ReactEcharts
        style={{height:'208px'}}
          option={{
            tooltip:{
              trigger: 'item',
              formatter:function (a) {
                // //console.log('params: ', a)
                return '<b>Category:</b> '+XAxis[a.dataIndex]+'</br>'+'<b>Value:</b> '+((a.value)?a.value.toFixed(2)+"%":0).toString()
              }
            },
            xAxis: {
                type: 'category',
                data: XAxis,
                axisLabel:{
                  show:true,
                  rotate:0,
                  interval:0,
                  formatter:function(a){
                    //console.log(a)
                    return a?a.length>12?a.slice(0,12)+'..':a:a
                  }
                }
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: List,
                type: 'bar',
                showBackground: true,
                barWidth:'15px',
                color:'#6536d1'
            }]
        }}
                          
        />
        <div className='px-2 py-4 '>
           <div className="text-center text-sm"><b>{"X-Axis: "}</b>{"Life evaluation response category (ladder 0-10)"}</div>
           <div className="text-center text-sm"><b>{"Y-Axis: "}</b>{"Percentage of overall response"}</div>
        </div>

    </div>
                 
    </>
  );
}
