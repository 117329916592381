import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactWordcloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';
import CircleCharts from './Charts/CircleCharts';

class WordChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedText: '',
      showCreateTopicModal: false,
      isCustom: false,
      showKeywordModal: false,
      crrKeywordList: [],
      topicValue: "",
      collectTopicList: [],
      collectCustomTopicList: []
    }
  }



  componentDidMount() {
  }


  handleKeyword = (data) => {
    let { handleKeywordCallOnly } = this.props;


    let reqData = [{
      "topic": data.topic,
      "keywordList": data.keywordList
    }]
    ////console.log("handleKeyword reqData-------------------->",reqData)
    ////console.log("handleKeyword data-------------------->",data)

    if (data && data.isCustom) {
      handleKeywordCallOnly(reqData, true)
    }
    else {
      handleKeywordCallOnly(data.topic)
    }
    this.setState({ SelectedText: data.topic + ' (' + data.value + ')' });





  }

  handleCollectKeyword = () => {
    let { handleKeywordCall } = this.props;
    let { collectTopicList } = this.state;

    let reqData = []
    if (collectTopicList && collectTopicList.length > 0) {
      collectTopicList.forEach((item) => {
        reqData.push({
          "topic": item.topic,
          "keywordList": item.keywordList,
          "isCustom": item.isCustom
        })
      })
      handleKeywordCall(reqData, true)
      // this.setState({SelectedText:"NaN", isCustom:true});
    }


    // if(check){
    //   let reqData = collectTopicList;
    //   handleKeywordCall(reqData,true)
    //   this.setState({SelectedText:"NaN", isCustom:true});
    // }
    // else{
    //   handleKeywordCall(data.text)
    //   this.setState({SelectedText:data.text+' ('+data.value+')', isCustom:false});
    // }


  }


  handleSelectKeyword = (data) => {
    let { collectTopicList } = this.state;
    let getIndex = collectTopicList.findIndex(prev => prev.topic === data.topic);
    if (getIndex === -1) {
      if (collectTopicList && collectTopicList.length >= 2) {

      } else {
        collectTopicList.push(data)
      }
    }
    else {
      collectTopicList.splice(getIndex, 1)
    }

    this.setState({ collectTopicList });

    ////console.log("collectTopicList--->",collectTopicList)
  }


  // handleSelectCustomKeyword=(data,check)=>{
  //   let { collectCustomTopicList } = this.state;
  //   let getIndex = collectCustomTopicList.findIndex(prev=>prev.topic===data.topic);
  //   if(getIndex===-1){
  //     collectCustomTopicList.push(data)
  //   }
  //   else{
  //     collectCustomTopicList.splice(getIndex,1)
  //   }

  //   this.setState({collectCustomTopicList});

  // }




  handleKeywordTooltip = (data) => {
    this.setState({ SelectedText: data.text + ' (' + data.value + ')' });
  }

  openCreateTopicModal = (check) => {
    this.setState({ showCreateTopicModal: check })
  }

  openKeywordModal = (check) => {
    this.setState({ showKeywordModal: check })
  }

  handleInputTopic = (value) => {
    this.setState({ topicValue: value })
  }

  collectKeywords = (word) => {
    let { crrKeywordList } = this.state;
    let getIndex = crrKeywordList.findIndex(prev => prev === word);
    if (getIndex === -1) {
      crrKeywordList.push(word)
    }
    else {
      crrKeywordList.splice(getIndex, 1)
    }
    this.setState({ crrKeywordList })

  }

  handleCreateTopicSubmit = () => {
    let { crrKeywordList, topicValue } = this.state;
    let { onCreateTopicSubmit } = this.props;
    if (crrKeywordList && crrKeywordList.length > 0) {
      let reqData = {
        "topic": topicValue,
        "wordList": crrKeywordList
      }
      onCreateTopicSubmit(reqData)
    }

    this.setState({ showCreateTopicModal: false })
    setTimeout(() => {
      this.setState({ topicValue: "", crrKeywordList: [] })
    }, 1000)

  }

  render() {

    let { topicModellingError, topicData, wordCloudData, getTopicListData, topicValue } = this.props;
    let { showCreateTopicModal, isCustom, showKeywordModal, crrKeywordList, collectTopicList } = this.state;

    //console.log('SelectedText: ',SelectedText)

    let words = []

    // let CurrentWord = '';

    if (wordCloudData && wordCloudData.list && wordCloudData.list.length > 0) {
      wordCloudData.list.forEach((item) => {
        words.push({
          text: item[0],
          value: item[1]
        })
      })
    }

    // const callbacks = {
    //   // getWordColor: word => word.value > 50 ? "blue" : "red",
    //   onWordClick: (data)=>this.handleKeyword(data),

    //   //onWordMouseOver: console.log,
    //   // getWordTooltip: (data)=>{},
    //   getWordTooltip: function(a){
    //     return (a.text+': '+a.value)
    //   },
    // }



    // const options = {
    //   rotations: 2,
    //   rotationAngles: [0, -90],
    //   padding:5,
    //   fontWeight:600,
    //   fontSizes:[20,50]

    // };


    let wordList = words.sort((a, b) => b.value - a.value)

    ////console.log("getTopicListData----->",getTopicListData)
    ////console.log("topicData----->",topicData)

    let tempCustomData = []
    let tempData = []
    let maxSize = 0

    let newWordsList = []
    if (getTopicListData && getTopicListData.length > 0) {
      getTopicListData.forEach((item, index) => {
        newWordsList.push({
          "topic": item.topic,
          "value": 0,
          "isCustom": true,
          "keywordList": item.wordList
        })
      })
    }
    if (wordList && wordList.length > 0) {
      wordList.forEach((item, index) => {
        newWordsList.push({
          "topic": item.text,
          "value": item.value,
          "isCustom": false,
          "keywordList": []
        })
      })
    }
    // ////console.log("---------wordList----->",wordList)
    // ////console.log("getTopicListData----->",getTopicListData)

    // ////console.log("newWordsList----->",newWordsList)


    let allWordList = []
    if (newWordsList && newWordsList.length > 0) {
      newWordsList.forEach((item, index) => {
        let tempObj = {}
        let check = false;
        if (topicData && topicData.length > 0) {
          let getIndex = topicData.findIndex(prev => prev.keyword === item.topic)
          if (getIndex !== -1) {
            tempObj = topicData[getIndex];
            check = true
          }
        }

        let newTemp = {
          "topic": item.topic,
          "sentimentObj": check ? tempObj.sentimentPerc : { "Positive": 0, "Negative": 0, "Neutral": 0 },
          "isCustom": item.isCustom,
          "keywordList": item.keywordList,
          "value": 0

        }
        allWordList.push(newTemp);



      });
    }
    ////console.log("topicData----->",topicData)

    ////console.log("newWordsList----->",newWordsList)
    ////console.log("allWordList----->",allWordList)



    // if(isCustom){        
    // if(getTopicListData && getTopicListData.length>0){
    //   getTopicListData.forEach((item,index)=>{
    //     let tempObj= {}
    //     let check = false;
    //     if(topicData && topicData.length>0){
    //       let getIndex = topicData.findIndex(prev=>prev.keyword === item.topic)
    //       if(getIndex!==-1){
    //         tempObj = topicData[getIndex];
    //         check = true
    //       }
    //     }
    //     let newTemp = {
    //       "text":item.topic,
    //       "sentimentObj":check?tempObj.sentimentPerc:{"Positive":0,"Negative":0,"Neutral":0},

    //     }
    //     tempCustomData.push(newTemp)



    //   });
    // }


    // }
    // else{


    // }



    if (newWordsList && newWordsList.length > 0) {
      newWordsList.forEach((item, index) => {
        let tempObj = {}
        let check = false;
        if (topicData && topicData.length > 0) {
          let getIndex = topicData.findIndex(prev => prev.keyword === item.topic)
          if (getIndex !== -1) {
            tempObj = topicData[getIndex];
            check = true
          }
        }

        if (check) {
          let newTemp = {
            "topic": item.topic,
            "value": item.value,
            "sentimentObj": check ? tempObj.sentimentPerc : { "Positive": 0, "Negative": 0, "Neutral": 0 },

          }
          tempData.push(newTemp)
        }


        if (index === 0) {
          maxSize = parseInt(item.value)
        }


      });
    }



    ////console.log("topix isCustom----->",isCustom)

    ////console.log("topix tempData----->",tempData)
    ////console.log("topix tempCustomData----->",tempCustomData)


    return (
      <>

        <div className="overflow-y-auto bg-white border md:flex lg:col-span-2">
          <div className="p-4 space-y-4 overflow-y-auto capitalize border-b  md:w-52 md:border-r md:h-auto h-60">
            <div className="sticky top-0 space-y-4 bg-white">
              <button onClick={() => this.openCreateTopicModal(true)} className="  w-full bg-blue-500 px-4 py-2.5 mx-auto rounded-full text-sm text-white flex items-center capitalize  justify-center hover:bg-opacity-90 transition duration-150"><span className="mr-2 material-symbols-outlined " style={{ fontSize: '1rem' }}>add</span>
                create topic
              </button>

              {/* <button onClick={()=>this.handleCollectKeyword(true)} className=" w-full  text-sm capitalize  font-medium   flex items-center justify-between bg-white border border-gray-300 p-1.5  px-2 rounded-lg">
          <span>Load Topic</span>
          </button> */}

              <button onClick={() => this.handleCollectKeyword(true)} className=" w-full border border-blue-500 px-4 py-2.5 mx-auto rounded-full text-sm text-blue-500 flex items-center capitalize  justify-center hover:bg-opacity-90 transition duration-150"><span className="mr-2 material-symbols-outlined " style={{ fontSize: '1rem' }}>restart_alt</span>
                load topic
              </button>


              {/* <button className=" w-full  text-sm capitalize  font-medium   flex items-center justify-between bg-white border border-gray-300 p-1.5  px-2 rounded-lg"><span>Topics</span></button> */}

            </div>
            {/* chart */}
            <div className="w-full pl-4 space-y-3 overflow-y-auto text-xs text-gray-500 capitalize  md:h-96 h-44 customscroll4 md:text-sm">


              {allWordList && allWordList.length > 0 ?
                allWordList.map((item) =>
                  <>
                    <div className="flex">
                      <input type="checkbox" checked={collectTopicList.some(prev => prev.topic === item.topic)} onChange={() => { }} onClick={() => this.handleSelectKeyword(item)} />
                      <p onClick={() => this.handleKeyword(item)} className="pl-2 font-medium transition duration-150 cursor-pointer hover:text-blue-500">{item.topic} {item.value ? "(" + item.value + ")" : ""}</p>
                    </div>
                  </>
                ) : null}
              {/* 
        {getTopicListData && getTopicListData.length>0?
        getTopicListData.map((item)=>
        <>
          <div className="flex">
            <input type="checkbox" onChange={()=>{}} onClick={()=>this.handleSelectKeyword(item,true)}/>
            <p onClick={()=>this.handleKeyword(item,true)} className="pl-2 font-medium transition duration-150 cursor-pointer hover:text-blue-500">{item.topic}</p>
          </div> 
        </>       
        ):null}
        
        {words && words.length>0?
        words.map((item)=>
          <>
          <div className="flex">
            <input type="checkbox" onChange={()=>{}} onClick={()=>this.handleSelectCustomKeyword(item,true)}/>
            <p onClick={()=>this.handleKeyword(item)} className="pl-2 font-medium transition duration-150 cursor-pointer hover:text-blue-500">{item.text} ({item.value})</p>
          </div>
          </>
        ):null} */}

            </div>
            {/* end */}
          </div>
          <div className="flex-1 p-4 space-y-4">
            {/* <div className="flex items-center space-x-4 text-gray-500">
        <span className="px-4 py-1 text-sm border rounded-full  border-sky-500 text-sky-500">Donut chart</span>
        <span className="px-4 py-1 text-sm border rounded-full ">Bar chart</span>
      </div> */}
            <h1 className="text-sm text-gray-500 "><b className="text-sm font-medium text-gray-800">Topic Modeling</b>
              <span className="font-normal text-blue-500">{" ( Topic modeling is a type of statistical modeling for discovering the abstract “topics” that occur in a collection of documents. ) "}</span>
            </h1>
            {/* chart */}
            {/* <div className="h-60">
      </div> */}

            <div className='flex grid flex-row flex-wrap items-start justify-center min-w-full grid-cols-2'>
              {tempData && tempData.length > 0 ?
                tempData.map((item, index) =>
                  (index < 2) ?
                    <>
                      <div className='relative'>
                        <CircleCharts allWordList={allWordList} handleKeyword={this.handleKeyword} word={item.topic} maxSize={maxSize} size={item.value} sentimentObj={item.sentimentObj} />
                      </div>
                      <div className='absolute top-0 left-0'>{item.text}</div>
                    </>
                    : null
                ) :
                <div className='p-2 text-lg text-center text-gray-500'>{topicModellingError}</div>
              }
            </div>





            {/* <h1 className="text-sm text-[#3d405B]/70" style={{color:"#3366ff"}}>
        * We are not showing data where employee count is less than 5
      </h1> */}
          </div>
        </div>




        {showCreateTopicModal ?
          <>
            <div className="fixed top-0 z-40 flex items-center justify-center w-full h-full xl:h-screen" style={{ background: '#0000005c' }}>
              <div className="w-11/12 bg-white rounded-lg shadow-sm xl:w-4/12 lg:w-5/12 md:w-6/12">
                <div className="flex justify-between p-4 border-b"> <h2 className="text-base font-semibold text-center capitalize test-gray-800">Create Topics</h2>
                  <span onClick={() => this.openCreateTopicModal(false)} className="p-1 font-medium text-gray-500 duration-150 bg-gray-100 rounded-full cursor-pointer material-symbols-outlined hover:bg-sky-100 transtion">close</span></div>
                <div className="p-4 space-y-4">

                  <div className="space-y-2 text-sm text-gray-500 ">
                    <p>Enter Topic</p>
                    <div className="">
                      <input type="text" name="topicValue" placeholder="Enter Topic" value={topicValue} onChange={(e) => this.handleInputTopic(e.target.value)} className="flex justify-between w-full p-2 text-blue-500 border rounded-lg cursor-pointer" />
                    </div>
                  </div>
                  <div className="space-y-2 text-sm text-gray-500 ">
                    <p>Select Keywords</p>
                    <div className="relative flex justify-between p-2 border rounded-lg cursor-pointer">
                      <span className="w-full text-gray-500" onClick={() => this.openKeywordModal(!showKeywordModal)}>{crrKeywordList && crrKeywordList.length > 0 ? crrKeywordList.map((element) => element).join(", ") : "Select Keywords"}</span>
                      <span className="material-symbols-outlined" onClick={() => this.openKeywordModal(!showKeywordModal)}>arrow_drop_down</span>

                      {showKeywordModal ?
                        <div className="absolute left-0 z-10 w-48 p-3 font-normal bg-white shadow-lg top-10 font-xs" id="chatbot" style={{ overflowY: 'auto', height: '300px' }}>
                          {words && words.length > 0 ?
                            words.map((item, index1) =>
                              <div key={index1} className="flex items-center py-2 m-1" onClick={() => this.collectKeywords(item.text)}>
                                <input type="checkbox" checked={crrKeywordList.some(prev => prev === item.text)}
                                  onChange={() => { }} /><label className="ml-2 text-sm cursor-pointer text-[#3D405B] ">{item.text}</label>
                              </div>
                            ) : null}
                        </div>
                        : null}

                    </div>
                  </div>

                  {/* <div className="flex justify-end mt-8">
                    <button onClick={()=>this.handleCreateTopicSubmit()} type="button" className="border border-blue-500 rounded-md px-6 py-2.5 text-sm bg-blue-500 text-white focus:outline-none flex items-center hover:bg-blue-600">
                      {"Create"}
                    </button>
                  </div> */}

                  <div className="flex items-center justify-center pt-10 -mb-24">
                    <button onClick={() => this.handleCreateTopicSubmit()} className="h-10 px-2 py-1 mx-2 text-white bg-blue-500 cursor-pointer w-52 rounded-2xl">Create Topic</button>
                  </div>

                  <button className="w-full p-3 text-sm text-white uppercase transition duration-150 rounded-lg bg-sky-500 hover:bg-sky-400 ">loading...</button>

                </div>
                {/*  */}
              </div>
            </div>

          </>
          : null}




      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(WordChart);
