import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";

// pages
import Dashboard from "../../pages/Dashboard/Dashboard";
import AssessmentDashboard from "../../pages/AssessmentDashboard/AssessmentDashboard";

import Logout from "../../pages/Other/Logout";
import AddQuestion from "../../pages/SurveyMgmt/AddQuestion/AddQuestion";

//  SecondDashboard
import GeneralDashboard from "../../pages/GeneralDashboard/GeneralDashboard";

import LiveDashboard from "../../pages/LiveDashboard/LiveDashboard";
import LiveDashboardOriginal from "../../pages/LiveDashboardOriginal/LiveDashboardOriginal";

// CustomerDashboard
import CustomerDashboard from "../../pages/CustomerDashboard/CustomerDashboard";

// Survey Management 
import Survey from "../../pages/SurveyMgmt/Survey/Survey";
import Campaign from "../../pages/SurveyMgmt/Campaign/Campaign";
import CampaignCustomer from "../../pages/SurveyMgmt/Campaign/CampaignCustomer";

// import CampaignRiskSurvey from "../../pages/SurveyMgmt/Campaign/CampaignRiskSurvey";
import CampaignSense from "../../pages/SurveyMgmt/Campaign/CampaignSense";
import CampaignFeedback from "../../pages/SurveyMgmt/Campaign/CampaignFeedback";
import CampaignPulse from "../../pages/SurveyMgmt/Campaign/CampaignPulse";



import LandingPage from "../../pages/SurveyMgmt/LandingPage/LandingPage";

import SurveyAssessmentReport from "../../pages/SurveyMgmt/Survey/Components/SurveyAssessmentReport/SurveyAssessmentReport";
import SurveyReport from "../../pages/SurveyMgmt/Survey/Components/SurveyReport/SurveyReport";
import SurveyReportCustomer from "../../pages/SurveyMgmt/Survey/Components/SurveyReportCustomer/SurveyReport";


import Survey360Report from "../../pages/SurveyMgmt/Survey/Components/Survey360Report/Survey360Report";

import CampaignReport from "../../pages/SurveyMgmt/Campaign/Components/CampaignReport/CampaignReport";
import CampaignAssessmentReport from "../../pages/SurveyMgmt/Campaign/Components/CampaignAssessmentReport/CampaignAssessmentReport";
import Campaign360Report from "../../pages/SurveyMgmt/Campaign/Components/Campaign360Report/Campaign360Report";

import Employees from '../../pages/Employees/Employees';
import Customers from '../../pages/Customers/Customers';
import ManagerActionPlan from "../../pages/ManagerActionPlan/ManagerActionPlan";
import Preview from "../../pages/SurveyMgmt/AddQuestion/Components/Preview/Surveylink/MainPage";
import Users from '../../pages/Users/Users';
import CampaignReportCustomer from "../../pages/SurveyMgmt/Campaign/Components/CampaignReportCustomer/CampaignReportCustomer";
import CampaignReportCustomerV2 from "../../pages/SurveyMgmt/Campaign/Components/CampaignReportCustomerV2/CampaignReport";

import ParameterSetting from "../../pages/ParameterSetting/ParameterSetting";

import FilterSetting from "../../pages/FilterSetting/FilterSetting";
import FilterSurveyWise from "../../pages/FilterSurveyWise/FilterSurveyWise";

import AssessmentSurvey from "../../pages/SurveyMgmt/Survey/AssessmentSurvey";

import ExitSurvey from "../../pages/SurveyMgmt/Survey/ExitSurvey";
import Feedback360Survey from "../../pages/SurveyMgmt/Survey/Feedback360Survey";




import SenseGeneral from '../../pages/SenseGeneral/SenseGeneral';
// import SenseRecursiveGeneral from '../../pages/SenseRecursiveGeneral/SenseRecursiveGeneral';

import EmployeeActionPlan from "../../pages/EmployeeActionPlan/EmployeeActionPlan";
import HappinessDashboard from "../../pages/HappinessDashboard/HappinessDashboard";


import Overview from "../../pages/Overview/MainPage/Main";

// import LifecycleDash from "../../pages/LifecycleDash/LifecycleDash";
import LifecycleDashboard from "../../pages/LifecycleDashboard/LifecycleDashboard";
import FeedbackDash from "../../pages/FeedbackDash/FeedbackDash";

import RiskSurvey from "../../pages/SurveyMgmt/Survey/RiskSurvey";
import CustomerSurvey from "../../pages/SurveyMgmt/Survey/CustomerSurvey";
import ExitDashboard from "../../pages/ExitDashboard/ExitDashboard";
import CampaignAssessment from "../../pages/SurveyMgmt/Campaign/CampaignAssessment";

import PerformanceManagement from "../../pages/PerformanceManagement/PerformanceManagement";

import NPSSurvey from "../../pages/NPSSurvey/NPSSurvey/NPSSurvey";
import MYReview from "../../pages/NPSSurvey/NPSSurvey/MYReview";
import MYReviewDetails from "../../pages/NPSSurvey/NPSSurvey/MYReviewDetails";
import ShareSurvey from "../../pages/NPSSurvey/NPSSurvey/ShareSurvey";
import EmpAppraisalCycle from "../../pages/NPSSurvey/NPSSurvey/EmpAppraisalCycle";
import EmpMyReview from "../../pages/NPSSurvey/NPSSurvey/EmpMyReview";
import ReviewApprialOne from "../../pages/NPSSurvey/NPSSurvey/ReviewApprialOne";
import ReviewApprialTwo from "../../pages/NPSSurvey/NPSSurvey/ReviewApprialTwo";
import TeammatesPeers from "../../pages/NPSSurvey/NPSSurvey/TeammatesPeers";

import PMSDashboard from "../../pages/PMSDashboard/PMSDashboard";

import MYReviewX from "../../pages/PMSDashboard/NPSSurvey/MYReview";
import MYReview2X from "../../pages/PMSDashboard/NPSSurvey/MYReview2";
import EmployeeViewX from "../../pages/PMSDashboard/NPSSurvey/EmployeeView";
import MYReviewDetailsX from "../../pages/PMSDashboard/NPSSurvey/MYReviewDetails";
import TeammatesPeersX from "../../pages/PMSDashboard/NPSSurvey/TeammatesPeers";
import GoalsX from "../../pages/PMSDashboard/NPSSurvey/Goals";
import ReviewApprialOneX from "../../pages/PMSDashboard/NPSSurvey/ReviewApprialOne";
import ReviewApprialTwoX from "../../pages/PMSDashboard/NPSSurvey/ReviewApprialTwo";
import AppraisalStatusX from "../../pages/PMSDashboard/NPSSurvey/AppraisalStatus";
import NormalizationX from "../../pages/PMSDashboard/NPSSurvey/Normalization";
import NineBoxX from "../../pages/PMSDashboard/NPSSurvey/NineBox";
import NineBoxEmployeeListX from "../../pages/PMSDashboard/NPSSurvey/NineBoxEmployeeList";
import NineBoxMatrixX from "../../pages/PMSDashboard/NPSSurvey/NineBoxMatrix";

import PMSEmployee from "../../pages/PMSEmployee/PMSEmployee";
import PMSFinalApproval from "../../pages/PMSEmployee/PMSFinalApproval";
import PMSEmployeeGoals from "../../pages/PMSEmployee/Components/GoalList";
import PMSEmployeeGoalsReview from "../../pages/PMSEmployee/Components/SelftReview";
import PMSEmployeeGoalsSelfView from "../../pages/PMSEmployee/Components/SelfView";
import PMSEmployeePeerReview from "../../pages/PMSEmployee/Components/PeerReview";
import ManagerAppraisal from "../../pages/PMSEmployee/Components/ManagerReview";
// import EmployeePeerList from "../../pages/PMSEmployee/Components/EmployeePeerList";
import PeerGoalList from "../../pages/PMSEmployee/Components/PeerGoalList";
import TeammateGoalList from "../../pages/PMSEmployee/Components/TeammateGoalList";
import PreviewAppraisal from "../../pages/PMSEmployee/Components/PreviewReview";
import AppraisalCycleEmpList from "../../pages/PerformanceManagement/AppraisalCycleEmpList";
import SalaryHike from "../../pages/PerformanceManagement/SalaryHike";
import SalaryHikeAppraisal from "../../pages/PerformanceManagement/SalaryHikeAppraisal ";
import SalaryHikeStatus from "../../pages/PerformanceManagement/SalaryHikeStatus";
import EmployeeSalaryHikeStatus from "../../pages/PerformanceManagement/EmployeeSalaryHikeStatus";
import EmployeeSalaryHikeEdit from "../../pages/PerformanceManagement/EmployeeSalaryHikeEdit";
import HikeAppraiser from "../../pages/PerformanceManagement/HikeAppraiser";
import SalaryHikeStatusEmployee from "../../pages/PerformanceManagement/SalaryHikeStatusEmployee";

import ReviewedGoalsFeedback from "../../pages/PMSEmployee/Components/ReviewedGoalsFeedback";
import SchoolDashboard from "../../pages/SchoolDashboard/SchoolDashboard";



function Layout(props) {
  return (
    <div>
      <>
        <Switch>

          <Route path="/app/myreview1x" component={withRouter(MYReviewX)} />
          <Route path="/app/myreview2x" component={withRouter(MYReview2X)} />
          <Route path="/app/employee-viewx" component={withRouter(EmployeeViewX)} />
          <Route path="/app/myreviewdetailsx" component={withRouter(MYReviewDetailsX)} />
          <Route path="/app/teammatespeersx" component={withRouter(TeammatesPeersX)} />
          <Route path="/app/goalsx" component={withRouter(GoalsX)} />
          <Route path="/app/review-apprial-onex" component={withRouter(ReviewApprialOneX)} />
          <Route path="/app/review-apprial-twox" component={withRouter(ReviewApprialTwoX)} />

          <Route path="/app/employeeactionplan" component={withRouter(AppraisalStatusX)} />
          <Route path="/app/normalizationx" component={withRouter(NormalizationX)} />
          <Route path="/app/nineboxx" component={withRouter(NineBoxX)} />
          <Route path="/app/nine-box-matrixxX" component={withRouter(NineBoxMatrixX)} />

          {/* salary hike manger link */}
          <Route path="/app/salary-hike" component={withRouter(SalaryHike)} />
          <Route path="/app/salary-hike-appraisal" component={withRouter(SalaryHikeAppraisal)} />
          <Route path="/app/salary-hike-status" component={withRouter(SalaryHikeStatus)} />
          <Route path="/app/employee-salary-hike-status" component={withRouter(EmployeeSalaryHikeStatus)} />
          <Route path="/app/employee-salary-hike-edit" component={withRouter(EmployeeSalaryHikeEdit)} />
          <Route path="/app/hike-appraiser" component={withRouter(HikeAppraiser)} />
          <Route path="/app/salary-hike-status-employee" component={withRouter(SalaryHikeStatusEmployee)} />




          <Route path="/app/dashboard/:id" component={withRouter(Dashboard)} />
          <Route path="/app/exitdashboard/:id" component={withRouter(ExitDashboard)} />
          <Route path="/app/feebackdash/:id" component={withRouter(FeedbackDash)} />
          <Route path="/app/risksurvey" component={withRouter(RiskSurvey)} />


          {/* <Route path="/app/lifecycledash/:id" component={withRouter(LifecycleDash)} /> */}
          <Route path="/app/lifecycledashboard/:id" component={withRouter(LifecycleDashboard)} />


          {/* <Route path="/app/recursivedashboardt1/:id/:idc" component={withRouter(RecursiveDashboard)} /> */}
          {/* <Route path="/app/recursivedashboardt2/:id/:idc" component={withRouter(RecursiveGeneral)} /> */}

          {/* <Route path="/app/recursivesensedashboardt1/:id/:idc" component={withRouter(SenseRecursiveDashboard)} /> */}

          <Route path="/app/sensedashboard/:id" component={withRouter(SenseGeneral)} />
          {/* <Route path="/app/recursivesensedashboardt2/:id/:idc" component={withRouter(SenseRecursiveGeneral)} /> */}

          <Route path="/app/dashboard/:id" component={withRouter(Dashboard)} />
          <Route path="/app/assessmentdashboard/:id" component={withRouter(AssessmentDashboard)} />
          <Route path="/app/schooldashboard/:id" component={withRouter(SchoolDashboard)} />


          <Route path="/app/generaldashboard/:id" component={withRouter(GeneralDashboard)} />

          {/* <Route path="/app/livedashboard2/:id" component={withRouter(LiveDashboard)} /> */}
          <Route path="/app/livedashboard/:id" component={withRouter(LiveDashboard)} />


          <Route path="/app/customerdashboard/:id" component={withRouter(CustomerDashboard)} />

          <Route path="/app/happinessdashboard" component={withRouter(HappinessDashboard)} />

          <Route path="/app/logout" component={withRouter(Logout)} />

          {/* Survey Managerment */}
          <Route path="/app/home" component={withRouter(LandingPage)} />

          <Route path="/app/survey" component={withRouter(Survey)} />
          <Route path="/app/assessment" component={withRouter(AssessmentSurvey)} />
          <Route path="/app/exitsurvey" component={withRouter(ExitSurvey)} />
          <Route path="/app/feedbacksurvey" component={withRouter(Feedback360Survey)} />

          <Route path="/app/customersurvey" component={withRouter(CustomerSurvey)} />

          <Route path="/app/question/:id" component={withRouter(AddQuestion)} />

          <Route path="/app/publish/:id" component={withRouter(Campaign)} />
          <Route path="/app/publishassessment/:id" component={withRouter(CampaignAssessment)} />
          <Route path="/app/publishcustomer/:id" component={withRouter(CampaignCustomer)} />

          <Route path="/app/publishsense/:id" component={withRouter(CampaignSense)} />
          <Route path="/app/publishfeedback/:id/:idc" component={withRouter(CampaignFeedback)} />
          <Route path="/app/publishpulse/:id" component={withRouter(CampaignPulse)} />

          <Route path="/app/surveyassessmentreport/:id" component={withRouter(SurveyAssessmentReport)} />
          <Route path="/app/surveyreport/:id" component={withRouter(SurveyReport)} />
          <Route path="/app/customersurveyreport/:id" component={withRouter(SurveyReportCustomer)} />
          <Route path="/app/survey360report/:id" component={withRouter(Survey360Report)} />
          <Route path="/app/campaignreport/:id/:idc" component={withRouter(CampaignReport)} />
          <Route path="/app/campaignassessmentreport/:id/:idc" component={withRouter(CampaignAssessmentReport)} />

          <Route path="/app/campaignreportcustomer/:id/:idc" component={withRouter(CampaignReportCustomerV2)} />

          {/* <Route path="/app/campaignreportcustomer/:id/:idc" component={withRouter(CampaignReportCustomerV2)} /> */}
          <Route path="/app/campaign360report/:id/:idc" component={withRouter(Campaign360Report)} />
          <Route path="/app/campaignkioskreport/:id/:idc" component={withRouter(CampaignReportCustomer)} />
          <Route path="/app/employees/" component={withRouter(Employees)} />
          <Route path="/app/customers/" component={withRouter(Customers)} />
          <Route path="/app/users/" component={withRouter(Users)} />
          <Route path="/app/preview/:id" component={withRouter(Preview)} />

          <Route path="/app/parametersetting" component={withRouter(ParameterSetting)} />

          <Route path="/app/filterSetting" component={withRouter(FilterSetting)} />
          <Route path="/app/filterSurveywise/:id" component={withRouter(FilterSurveyWise)} />

          <Route path="/app/manageractionplan" component={withRouter(ManagerActionPlan)} />
          <Route path="/app/employeeactionplan" component={withRouter(EmployeeActionPlan)} />
          <Route path="/app/overview" component={withRouter(Overview)} />

          <Route path="/app/pmsdashboard/:id" component={withRouter(PMSDashboard)} />

          <Route path="/app/performance" component={withRouter(PerformanceManagement)} />
          <Route path="/app/appraisalCycle" component={withRouter(NPSSurvey)} />
          <Route path="/app/empAppraisalCycle" component={withRouter(EmpAppraisalCycle)} />
          <Route path="/app/myreview" component={withRouter(MYReview)} />
          <Route path="/app/empMyReview/:id" component={withRouter(EmpMyReview)} />
          <Route path="/app/myreviewdetails" component={withRouter(MYReviewDetails)} />
          <Route path="/app/ShareSurvey" component={withRouter(ShareSurvey)} />
          <Route path="/app/reviewApprialOne" component={withRouter(ReviewApprialOne)} />
          <Route path="/app/reviewApprialTwo" component={withRouter(ReviewApprialTwo)} />
          <Route path="/app/teammatesPeers" component={withRouter(TeammatesPeers)} />


          <Route path="/app/pmsemployee" component={withRouter(PMSEmployee)} />
          <Route path="/app/pmsfinalapproval" component={withRouter(PMSFinalApproval)} />
          <Route path="/app/pmsemployeegoals/:id" component={withRouter(PMSEmployeeGoals)} />
          <Route path="/app/pmsemployeegoalsreview/:id/:idc" component={withRouter(PMSEmployeeGoalsReview)} />
          <Route path="/app/pmsemployeegoalsview/:id/:idc" component={withRouter(PMSEmployeeGoalsSelfView)} />



          <Route path="/app/pmsemployeepeerreview/:id/:idc" component={withRouter(PMSEmployeePeerReview)} />
          <Route path="/app/pmsemployeeempreview/:id/:idc" component={withRouter(ManagerAppraisal)} />
          <Route path="/app/pmsemployeepreview/:id/:idc" component={withRouter(PreviewAppraisal)} />

          <Route path="/app/pmsempreviewedgoals/:id/:idc" component={withRouter(ReviewedGoalsFeedback)} />

          {/* <Route path="/app/employeepeerlist" component={withRouter(EmployeePeerList)} /> */}

          <Route path="/app/pmsemployeepeergoals/:id/:idc" component={withRouter(PeerGoalList)} />
          <Route path="/app/pmsemployeeteamgoals/:id/:idc" component={withRouter(TeammateGoalList)} />

          <Route path="/app/pmsemplist/:id" component={withRouter(AppraisalCycleEmpList)} />



        </Switch>
      </>
    </div>
  );
}

export default withRouter(Layout);
