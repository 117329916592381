import React from "react";
import ScoreCircleChart from "./Charts/ScoreCircleChart";

export default function Item(props) {
let {  getIndexByFilterData,
  getManagerQuestionData,IndexDimensionList,

  IndexNumber, getQuestionData, IndexName, getDemographicIndexData ,CurrentManagerData, getIndexData, getSurveyData ,

  SentenceObj, GetParameterLabel, IconsObj, ManagerReportFConfig, QuartileData

} = props;

////console.log("IndexDimensionList---->",IndexDimensionList)

let IndexScore = getIndexByFilterData && getIndexByFilterData[IndexNumber] && getIndexByFilterData[IndexNumber].toFixed(2)?getIndexByFilterData[IndexNumber].toFixed(2):"0";


let ValueObj = {}


if(IndexDimensionList && IndexDimensionList.length>0){
  IndexDimensionList.forEach((item)=>{
    ValueObj[item+"Avg"] = 0
  });
}


let managerId = CurrentManagerData && CurrentManagerData["emp_id"]?CurrentManagerData["emp_id"]:"";

//---------------------------
let ManagerIDValue = managerId.toString();
//---------------------------



let index = getDemographicIndexData?getDemographicIndexData.findIndex(prev=>prev.name.toString() === ManagerIDValue):-1;

let DataItem = {}

if(index!==-1){
  DataItem = getDemographicIndexData[index];
}


if(IndexDimensionList && IndexDimensionList.length>0){
  IndexDimensionList.forEach((item)=>{

    ValueObj[item+"Avg"] = DataItem && DataItem[item+"Avg"] && DataItem[item+"Avg"].toFixed(2)? DataItem[item+"Avg"].toFixed(2):0;


  });
}


////console.log("getDemographicIndexData----",getDemographicIndexData);
////console.log("DataItem----",DataItem);
////console.log("IndexDimensionList----",IndexDimensionList);

////console.log("ValueObj----",ValueObj);


let OverallScore = getIndexData && getIndexData[IndexNumber] && getIndexData[IndexNumber].toFixed(2)?getIndexData[IndexNumber].toFixed(2):10;



////console.log("---ss-----IconsObj----",IconsObj);

function getQuestionByParameter (para){
  let questionList = getQuestionData && getQuestionData.length>0? getQuestionData.filter(x=>x.parameterDimensionName===para) : [];
  return questionList
}



let valueMin = QuartileData && QuartileData["min"] && QuartileData["min"].toFixed(2)?QuartileData["min"].toFixed(2):0;
let value25 = QuartileData && QuartileData["Q1_25"] && QuartileData["Q1_25"].toFixed(2)?QuartileData["Q1_25"].toFixed(2):0;
let value50 = QuartileData && QuartileData["Q2_50"] && QuartileData["Q2_50"].toFixed(2)?QuartileData["Q2_50"].toFixed(2):0;
let value75 = QuartileData && QuartileData["Q3_75"] && QuartileData["Q3_75"].toFixed(2)?QuartileData["Q3_75"].toFixed(2):0;
let value100 = QuartileData && QuartileData["Q4_100"] && QuartileData["Q4_100"].toFixed(2)?QuartileData["Q4_100"].toFixed(2):0;

let Bottom25 = "";
if(valueMin===value25){
  Bottom25 = value25.toString()
}else{
  Bottom25 = valueMin.toString()+" - "+value25.toString()
}

let Top100 = "";
if(value75===value100){
  Top100 = value100.toString()
}else{
  Top100 = value75.toString()+" - "+value100.toString()
}



  return (
    <>
      <section className=" space-y-2 gap-5 px-10 pt-12">



    <header className="bg-white ">
    <div className="flex justify-between capitalize w-12/12 mx-auto py-6 pt-3 space-y-1">
      
      
      <h1 className="text-xl text-white font-bold">
      {getSurveyData && getSurveyData.logo?
        <img src={getSurveyData && getSurveyData.logo?getSurveyData.logo:""}  style={{height:'90px'}}/>
        :null}
      </h1>

      <h1 className="text-xl text-white font-bold">
        <img src={"img/logo/happypluslogo.png"}  style={{height:'60px'}}/>
      </h1>

      
    </div>
  </header>




  <main className=" mx-auto w-full pb-4 ">
    <h1 className="text-left text-3xl text-gray-700 font-normal pb-2 ">{IndexName} Scorecard</h1>


    <div className="flex items-center py-4 space-x-8 border  border-indigo-400 rounded-xl p-3 px-5 my-2 ">
      <div> <h1 className="text-left text-2xl text-indigo-700">{IndexName} Index</h1>
        <p className="text-left text-sm text-gray-600">{IndexName}s to create a better today</p></div>

        <div className="flex justify-between items-center pl-10">

        {/* <div className="flex pr-10">
            <h2 className="mt-8 mr-4 text-blue-600 font-bold w-46">{CurrentManagerData && CurrentManagerData.name?CurrentManagerData.name:"NaN"}</h2>
            <ScoreCircleChart
              score = {IndexScore}
              color={"#374adb"}
            />
          </div>
        <div className="flex pr-10">
            <h2 className="mt-8 mr-4 text-gray-500 font-bold">{"Overall "+IndexName+" Index"}</h2>
            <ScoreCircleChart
              score = {OverallScore}
              color={"#6e6e6e"}
            />
          </div> */}


          <div>
            <div className="flex pr-10">
              <h2 className="text-gray-700 font-bold">{"Team "+IndexName+" Index"}:</h2>
              <h2 className="text-blue-500 font-bold pl-2 text-lg">{IndexScore}</h2>
            </div>
            <div className="flex pr-10">
              <h2 className="text-gray-500 font-semibold">{"Overall "+IndexName+" Index"}:</h2>
              <h2 className="text-blue-500 font-bold pl-2 text-lg">{OverallScore}</h2>
            </div>
          </div>

          <div>
            <div className="flex pr-10">
              <h2 className="text-gray-500 font-semibold">{"Bottom 25 Quartile"}:</h2>
              <h2 className="text-blue-500 font-bold pl-2 text-lg">{Bottom25}</h2>
            </div>
            <div className="flex pr-10">
              <h2 className="text-gray-500 font-semibold">{"Top 100 Quartile"}:</h2>
              <h2 className="text-blue-500 font-bold pl-2 text-lg">{Top100}</h2>
            </div>
          </div>




        </div>


    </div>


    <div className="w-full">
      <table>
          <tbody>
          {IndexDimensionList && IndexDimensionList.length>0?
            IndexDimensionList.map((item)=>
            <tr>
                    <td className="p-4 w-6/12">
                        <div className="flex items-start space-x-2">
                          <img src={IconsObj && IconsObj[item]?IconsObj[item]:"img/managerreport/icons_scorecards/png/connect.png"} className="w-8" />
                          <div>
                            <h1 className="text-left text-md font-medium">{GetParameterLabel(item)}</h1>
                            <p className="text-left text-sm text-gray-600">{SentenceObj[item]}</p>
                          </div>
                        </div>
                    </td>
                    <td className="p-4 w-6/12">
                      <div className="flex items-center space-x-6">
                        <div>
                          <ScoreCircleChart
                            score = {ValueObj[item+"Avg"]}
                          />
                        </div>
                        <ul className="text-left list-disc text-gray-500 px-4 space-y-1 text-sm">
                          {getQuestionByParameter(item) && getQuestionByParameter(item).length>0?
                          getQuestionByParameter(item).map((data)=>
                            <li className="text-xs">{data.name}</li>
                          )
                          :null}
                        </ul>
                      </div>
                    </td>
            </tr>
            ):null}
          </tbody>
      </table>
    </div>



    {/* <div className="flex space-x-12">
      <div className="space-y-8">


        {IndexDimensionList && IndexDimensionList.length>0?
        IndexDimensionList.map((item)=>
        <div className="flex items-start space-x-2">
          <img src="img/managerreport/icons_scorecards/png/connect.png" className="w-8" />
          <div>
            <h1 className="text-left text-md font-medium">{item}</h1>
            <p className="text-left text-sm text-gray-600">{SentenceObj[item]}</p>
          </div>
        </div>
        ):null}


      </div>
      <div className="space-y-6">

      {IndexDimensionList && IndexDimensionList.length>0?
        IndexDimensionList.map((item)=>
        <div className="flex items-center space-x-6">
          <div>
            <ScoreCircleChart
              score = {ValueObj[item+"Avg"]}
            />
          </div>
          <ul className="text-left list-disc text-gray-500 px-4 space-y-1 text-sm">
            {getQuestionByParameter(item) && getQuestionByParameter(item).length>0?
            getQuestionByParameter(item).map((data)=>
              <li className="text-xs">{data.name}</li>
            )
            :null}
          </ul>
        </div>
         ):null}


      </div>
    </div> */}



  </main>
</section>


    </>
  );
}







