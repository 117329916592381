import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeatMap from './Charts/HeatMap';
import RadarChart from './Charts/RadarChart';
import Commentary from '../../../Components/Commentary/Commentary';
// import Filter from './Filter/Filter';
import Filter from '../../../../../CommonComponents/Filters/IndexFilter/Filter';

class HappinessBehaviour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue:'none',
      currFilterName:'none',

      showHeatMap:true,

      currentPage:1,


      currentSelectValue:'',
      FilterValues:[],
      showFilter:false,
      showValue:''

    }
  }

  closeFilter=(check1,check2)=>{
    this.setState({showFilter:check1, showValue:check2});
  }

  componentDidMount() {

  }

  handleViewType=(check)=>{
    this.setState({showHeatMap:!check})
  }

  storeFilterName=(val)=>{
    this.setState({currFilterName:val})
  }

  handleDistributionFilterValueSave=(val)=>{
    this.setState({currFilterValue:val})
  }
  handleDistributionFilterNameSave=(val)=>{
    this.setState({currFilterName:val})
  }


  handlePageChange=(page)=>{
    this.setState({currentPage:page})
  }

  handlePageReset=()=>{
    this.setState({currentPage:1})
  }






  handleMultifilterIndexScore2=(finalFilter, name)=>{
    let {handleMultifilterBehaviour}= this.props;
    this.setState({FilterValues:finalFilter, currFilterName:name});
    handleMultifilterBehaviour(finalFilter,true, name);

  }



  render() {
    let { 
      indexType, optionType,getCommentData,  loading,name,CountRestrict, getEMPFilterLabel,

      EmpFilterData, FilteredIndexData, getDistributionIndexData,handleDistributionFilter,getIndexDriverData, getIndexFilterData, getIndexByFilterDMGData
    
    } = this.props;

    let list = []
    let level = ''

    if(FilteredIndexData && FilteredIndexData.list && FilteredIndexData.list.length>0){
      list = FilteredIndexData.list;
      level = FilteredIndexData.level;
    }

    let { currFilterName , showHeatMap, currentPage, currentSelectValue, FilterValues } = this.state;

    let filterNames = [];
    if(EmpFilterData && EmpFilterData.length>0){
      EmpFilterData.forEach((item)=>{
        filterNames.push(item.name)
      })
    }
    let CurrentFilterName = '';
    if(currFilterName!=='none'){
      CurrentFilterName = currFilterName;
    }
    else if(filterNames && filterNames.length>0){
      CurrentFilterName = filterNames[0];
    }
    



    
   let EmpFilterData2 = []
   if(EmpFilterData && EmpFilterData.length>0){
    EmpFilterData.forEach((emp)=>{
      if(emp.name !=='ManagerPID'){
        EmpFilterData2.push(emp);
      }
    })
   }

   let isDisable  = true;
   let DistributionIndexData = [];
  //  let DemographicDataDistribution = [];

   if(FilterValues && FilterValues.length>0){

     if(isDisable){
        FilterValues.forEach((item)=>{
            if(item.value==='All'){
              isDisable = false
            }
        });
     }


    //  DistributionIndexDataScore=getIndexDriverData;
    //  DemographicDataDistribution=getIndexDistributionData;
    DistributionIndexData = getDistributionIndexData

    // getDistributionIndexData && getIndexDriverData

   }


    //console.log('getIndexDriverData: ',getIndexDriverData)
    //console.log('getDistributionIndexData: ',getDistributionIndexData)

    let {GetParameterLabel, ErrorIndexObj}= this.props;


    

    return (
      <>
          <div className="items-center justify-between md:flex">
            <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
            />

            {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
            <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

            </div>


        {true ?
          <>
            {/* <div className="items-center justify-start xl:flex lg:flex md:flex"> */}
              {/* 
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}



            {/* </div> */}
{/* 
            {FilterValues && FilterValues.length > 0 ?
              <div className="items-center h-10 px-4 py-3 bg-white border-b xl:flex lg:flex md:flex justify-left">
                {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
                  <p className="flex mx-2 my-4 text-sm"><p className="mr-1 text-blue-500">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
                ) : null}</div>
              : null} */}


            <div onClick={() => this.closeFilter(false)} className="" >
              <div className="w-full" >

              <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">

              <div className="p-4 bg-white border">
  
                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">{name} Behaviour - All Score (%)</h1>
                  {/* <div className="justify-between mb-4 md:flex">
                    <div className="text-lg font-semibold">{name} Behaviour - All Score (%)</div>
                    <div className="flex items-center text-xs">


                    </div>
                  </div> */}


                  {ErrorIndexObj && ErrorIndexObj["getIndexDriverError"]?
                    <>
                      <div className='my-24 text-2xl font-medium text-center text-gray-400'>{ErrorIndexObj["getIndexDriverError"]}</div>
                    </>
                    :
                    <>
                      {getDistributionIndexData && getIndexDriverData ?
                        <HeatMap
                          handleDistributionFilterNameSave={this.handleDistributionFilterNameSave}
                          handleDistributionFilterValueSave={this.handleDistributionFilterValueSave}
                          getDistributionIndexData={DistributionIndexData}
                          getIndexDriverData={getIndexDriverData}
                          currFilterName={CurrentFilterName}
                          FilterValues={FilterValues}
                          GetParameterLabel={GetParameterLabel}
                          CountRestrict={CountRestrict}
                          ErrorIndexObj={ErrorIndexObj}
                        />
                        : null}
                    </>}






                </div>

                </div>



              </div>
            </div>
          </>
          :
          <>
            {!loading ?
              <div className="pt-40 text-3xl text-center text-gray-400">We have not calculated this section!</div>
              : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HappinessBehaviour);
