import React from "react";

export default function Item(props) {
  let { manageName, createdAt } = props;

  let list = [
    "Manager Scorecard",
    "Care Framework Scores",
    "3P Framework Scores",
    "Overview Of Methodology",
    "First Impression Of Your Manager Report",
  ]



  return (

    <section className=" space-y-2 px-10">
      <main className=" w-full pb-4 space-y-6 text-left  ">
        <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/70 italic">
          <h1 className="text-base font-semibold">Manager Score Card</h1>
          <p className="text-base font-semibold">{manageName} | {createdAt}</p>
        </div>


        <h1 className="px-6 text-4xl text-left text-[#fff] uppercase bg-[#2364AD] p-4 tracking-widest">Table of Content</h1>
        {/* {IndexName === "Engagement" ? "Manager Engagement Index" : "Manager Scorecard"} */}


        {/* <h1 className="text-left px-6 text-4xl  text-[#212121] uppercase bg-[#2196F3]/10 p-4 tracking-widest ">
          Table of Content
        </h1> */}

        <ul className="text-left space-y-2  text-[#212121] list-decimal divide-y-2 divide-dashed pl-10">
          {list && list.length > 0 ?
            list.map((ele) =>
              <li className="text-xl font-bold py-4">
                {ele}
              </li>
            ) : null}
        </ul>

      </main>
    </section>

  );
}
