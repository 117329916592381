import React, { Component } from "react";
import { connect } from "react-redux";
// import ReactEcharts from "echarts-for-react";
import ScoreCircleChart from "./ScoreCircleChart";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() { }

  render() {
    let {
      crrManagersList,
      getIndexScoreManagerPIDWiseData,
      singleManagerData,
      DemographicRestriction,
      validateList,
      IndexLabels,
      getIndexData,
      showMultiple,
      IndexForManagerData,
      ManagerListDasboardDataObj,
      DynaObj,
      EmpFilterData,
    } = this.props;

    //console.log('singleManagerData: ',singleManagerData);

    let DataValues = [];
    let list = [];
    let overallData = {};
    let managerTeam = {};
    let VariableData = {};

    if (true) {
      if (IndexLabels && IndexLabels.length > 0) {
        IndexLabels.forEach((item) => {
          if (getIndexData[item.indexType]) {
            overallData[item.indexType] =
              getIndexData[item.indexType].toFixed(2);
            overallData["name"] = "Overall Score";
          }

          if (IndexForManagerData[item.indexType]) {
            managerTeam[item.indexType] =
              IndexForManagerData[item.indexType].toFixed(2);
            managerTeam["name"] = "Team Score";
          }

          // VariableData[item.indexType]=getManagerListDomainFilterData[item.indexType]
          // VariableData["name"]="Department Score";
        });

        // list.push(VariableData)
        list.push(overallData);
        list.push(managerTeam);
      }

      //------------------------------------------------------------------------------------------------------

      ////console.log('managerTeam: ',managerTeam);

      let NewData = [];
      if (
        EmpFilterData &&
        EmpFilterData.length > 0 &&
        DynaObj &&
        DynaObj.length > 0
      ) {
        EmpFilterData.forEach((emp) => {
          if (
            DemographicRestriction.findIndex((prev) => prev === emp.name) !== -1
          ) {
            DynaObj.forEach((item) => {
              //console.log('item emp: ',item,emp);
              // //console.log('DynaObj: ',DynaObj);

              if (item && item.name && item.name[0]) {
                if (emp.name === item.name[0]) {
                  NewData.push(item);
                }
              }
            });
          }
        });
      }
      // //console.log('EmpFilterData: ',EmpFilterData);
      // //console.log('DynaObj: ',DynaObj);
      //console.log('NewData: ',NewData);

      //Manager score
      if (NewData && NewData.length > 0) {
        NewData.forEach((item) => {
          if (item.name[0] === "ManagerPID") {
            let Data = {};

            if (IndexLabels && IndexLabels.length > 0) {
              IndexLabels.forEach((label) => {
                Data[label.indexType] =
                  item && item[label.indexType]
                    ? item[label.indexType].toFixed(2)
                    : "NaN";
              });
            }

            Data["name"] = "Team Score";

            list.push(Data);
          }
        });
      }

      //Demographic score
      if (NewData && NewData.length > 0) {
        NewData.forEach((item) => {
          if (item.name[0] !== "ManagerPID") {
            let Data = {};

            if (IndexLabels && IndexLabels.length > 0) {
              IndexLabels.forEach((label) => {
                Data[label.indexType] =
                  item && item[label.indexType]
                    ? item[label.indexType].toFixed(2)
                    : "NaN";
              });
            }

            let newLevel =
              item && item.name && item.name[0] ? item.name[0] : "";
            Data["name"] =
              singleManagerData && singleManagerData[newLevel]
                ? newLevel + " (" + singleManagerData[newLevel] + ") "
                : newLevel;

            list.push(Data);
          }
        });
      }

      //console.log('NewData: ',NewData);
    }
    console.log('list:-------------> ', list);
    // console.log('getIndexData:-------------> ',getIndexData);

    if (getIndexScoreManagerPIDWiseData && getIndexScoreManagerPIDWiseData.length > 0) {
      list.push({
        "name": "Team Score",
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0
      })
    }


    if (list && list.length > 0 && getIndexScoreManagerPIDWiseData && getIndexScoreManagerPIDWiseData.length > 0) {
      let getIndex = list.findIndex(prev => prev.name === "Team Score");
      if (getIndex !== -1) {
        ["1", "2", "4", "5"].forEach((ele) => {
          list[getIndex][ele] = getIndexScoreManagerPIDWiseData[0]["finalData"][ele]
        })
      }
    }

    let XAxis = [];
    let XAxisName = [];

    if (IndexLabels && IndexLabels.length > 0) {
      IndexLabels.forEach((item) => {
        XAxis.push(item.indexName);
        XAxisName.push(item.indexType);
      });
    }

    let YAxis = [];

    if (list && list.length > 0) {
      list.forEach((driver) => {
        YAxis.push(driver.name);
      });
    }

    // ////console.log('XAxis: ',XAxis)
    ////console.log('YAxis: ',YAxis)
    // ////console.log('DataValues: ',DataValues)
    // ////console.log('list: ',list)

    // let XAxis = []

    // if(EmpFilterData && EmpFilterData.length>0){
    //   EmpFilterData.forEach((emp)=>{
    //     XAxis.push(emp.label)
    //   })
    // }

    ////console.log('IndexLabels: ',IndexLabels)
    ////console.log('asdadaslist: ',list)

    //console.log('------------ManagerListDasboardDataObj-----------===>',ManagerListDasboardDataObj)

    // let List2x = []
    // List2x.push(overallData);
    // if (ManagerListDasboardDataObj && ManagerListDasboardDataObj.length > 0) {
    //   ManagerListDasboardDataObj.forEach((item) => {
    //     let data2x = {}
    //     if (IndexLabels && IndexLabels.length > 0) {
    //       IndexLabels.forEach((valueIndex) => {
    //         data2x[valueIndex.indexType] = item && item.ManagerData && item.ManagerData[valueIndex.indexType] ? parseFloat(item.ManagerData[valueIndex.indexType].toFixed(2)) : 0
    //       });
    //     }
    //     data2x["name"] = item.ManagerName;
    //     List2x.push(data2x);
    //   })
    // }

    ////console.log('showMultiple--->',showMultiple)

    //------------------------------------------------------------------------------------------------------

    function getColor(data, index) {
      let Color = "";
      if (data.name === "Overall Score") {
        return (Color = "#bdddf2");
      } else {
        if (parseFloat(overallData[index]) <= parseFloat(data[index])) {
          return (Color = "#b2edc9");
        } else if (parseFloat(overallData[index]) === parseFloat(data[index])) {
          return (Color = "yellow");
        } else {
          return (Color = "#f2bdbd");
        }
      }
    }

    let IndexNameList = [
      {
        index: 1,
        name: "Happiness",
        color: "#FF4081",
      },
      {
        index: 2,
        name: "Engagement",
        color: "#2196F3",
      },
      {
        index: 4,
        name: "Stress",
        color: "#EF5350",
      },
      {
        index: 5,
        name: "Manager",
        color: "#9C27B0",
      },
    ];

    let IndexNameData = [];
    if (validateList && validateList.length > 0) {
      validateList.forEach((valid) => {
        if (IndexNameList && IndexNameList.length > 0) {
          IndexNameList.forEach((item) => {
            if (item.index === valid.template) IndexNameData.push(item);
          });
        }
      });
    }

    function getManagerName(mngrId) {
      let name = "";
      if (crrManagersList && crrManagersList.length > 0) {
        let getIndex = crrManagersList.findIndex(
          (prev) => prev.emp_id.toString() === mngrId.toString()
        );
        if (getIndex !== -1) {
          name = crrManagersList[getIndex]["name"];
        }
      }
      return name ? name : mngrId;
    }

    let List2x = [];
    List2x.push(overallData);

    //console.log('list: ',list)

    if (
      getIndexScoreManagerPIDWiseData &&
      getIndexScoreManagerPIDWiseData.length > 0
    ) {
      getIndexScoreManagerPIDWiseData.forEach((manager) => {
        let temp = {
          name: getManagerName(manager.mngrId),
        };
        if (IndexLabels && IndexLabels.length > 0) {
          IndexLabels.forEach((item) => {
            if (
              manager &&
              manager["finalData"] &&
              manager["finalData"][item.indexType]
            ) {
              temp[item.indexType] =
                manager["finalData"][item.indexType].toFixed(2);
            }
          });
        }
        List2x.push(temp);
      });
    }

    console.log(
      "----wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww-----.getIndexScoreManagerPIDWiseData: ",
      getIndexScoreManagerPIDWiseData
    );
    console.log("----s--------.showMultiple: ", showMultiple);

    let ListFinal = [];
    if (showMultiple) {
      ListFinal = List2x;
    } else {
      ListFinal = list;
    }

    console.log("----s--------.ListFinal: ", ListFinal);
    return (
      <>
        <div className="pb-6 overflow-y-auto space-y-4 ">
          {IndexNameData && IndexNameData.length > 0
            ? IndexNameData.map((IndexItem, Index) => (
              <>
                <div className="bg-white hover:shadow-lg duration-150 transition border md:px-6 p-4 rounded-lg space-y-4 ">
                  <div
                    // style={{ background: IndexItem.color }}
                    className=" w-full md:text-center flex items-center justify-between "
                  >
                    <div className="flex items-center text-[#3D405B] space-x-1">
                      <h1 className="md:text-xl font-medium  ">
                        {IndexItem.name} Score
                      </h1>

                    </div>

                    <div
                      className="md:text-base font-medium text-sm px-6 py-2 border rounded-md bg-[#F9FAFB] text-[#3D405B] flex space-x-2 justify-center "
                    // style={{ color: IndexItem.color }}
                    >
                      <span>{"Overall"}</span>
                      <span>{overallData && overallData[IndexItem.index]
                        ? overallData[IndexItem.index] + "%"
                        : "NA"}</span>

                    </div>
                  </div>

                  <div className="flex items-center w-full space-x-4 overflow-x-auto">
                    {ListFinal && ListFinal.length > 0
                      ? ListFinal.map((item) =>
                        item &&
                          item.name !== "Overall Score" &&
                          item.name ? (
                          <div className=" flex flex-col items-center w-56 flex-shrink-0 border rounded-sm p-4 space-y-2 ">
                            <div className="percent">


                              {/* TESTING CODE ------- */}
                              {/* <div className="bg-white text-gyay-700 p-6">
                                    A: {JSON.stringify([List2x])}
                                    <br/>
                                    B: {JSON.stringify([item])}
                                    <br/>
                                    C: {JSON.stringify([list])}
                                  </div> */}
                              {/* TESTING CODE ------- */}


                              <ScoreCircleChart
                                color={
                                  Number(overallData[IndexItem.index]) <=
                                    Number(item[IndexItem.index])
                                    ? "#22c968"
                                    : "#c7323e"
                                }
                                score={item[IndexItem.index]}
                              />

                            </div>
                            <div className=" text-gray-700 font-medium text-base text-center line-clamp-1  ">
                              {item.name}
                            </div>
                          </div>
                        ) : null
                      )
                      : null}
                  </div>
                </div>
              </>
            ))
            : null}
        </div>

        {/* <div key={Index} className="bg-white rounded w-full shadow-lg my-2">

      <div style={{height:'230px'}} className="flex">
        <div className="text-center pt-10" style={{
          width:'20%',
          height:'100%',
          background:IndexItem.color,

        }}>
          <div style={{fontSize:'25px',paddingTop:'5px',color:'white'}} >{IndexItem.name}</div>
          <div className="text-white" style={{fontSize:overallData && overallData[IndexItem.index]?'40px':"25px"}}>{overallData && overallData[IndexItem.index]?overallData[IndexItem.index]:'Unavailable'}</div>
          <div className="text-white" style={{fontSize:'15px'}}>Overall</div>

        </div>

          <div className="flex" style={{
          width:'80%'
        }}>
          
        <div className={ListFinal && ListFinal.length>0 && ListFinal.length>5?"flex overflow-x-auto":"flex"}>

          {ListFinal && ListFinal.length>0?
          ListFinal.map((item)=>
          (item && item.name !== "Overall Score") && item.name?
              <div className="Happier-box1" style={{width:'45px', margin:("20px 60px ")}}>
              <div className="percent ">
              <svg>
                  <circle cx={60} cy={60} r={60} />
                  <circle cx={60} cy={60} r={60}
                  style={{
                        stroke:(overallData[IndexItem.index]<item[IndexItem.index])?'#22c968':'#c7323e',
                        strokeDashoffset:540 - (540 * ((parseInt(item[IndexItem.index])*100)/100))/ 100 }} />
                  </svg>
                  <div className="number cursor-default" style={{marginTop:'-10px',marginLeft:'-10px'}}>
                  <h1 className="text-lg font-semibold">{(item[IndexItem.index])}%</h1>
                  </div>
              </div>
              <div className=" text-gray-700 font-semibold text-sm" style={{marginLeft:"20px", marginTop:"-15px",width:'90px'}}>{item.name}</div>
            </div>
         :null
         
         )
          :null}



          </div>     

        </div>
      </div>


      </div> */}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(HeatMap);
