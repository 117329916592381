import React from 'react';
import GaugeScore from './Charts/GaugeScore';
import BarDistribution from './Charts/BarDistribution';


export default function ScoreBenchmark(props) {

  let {

    item,
    DistributionData,
    Benchmark,
    cronBachAlpha

  } = props;


  //console.log('Benchmark: ',Benchmark)


  return ( 
    <BarDistribution DistributionData={DistributionData} /> 

  );
}
