import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class QuestionStack extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { rowList, feedback } = this.props;


    let names = []

    // let ColorList = ["#757575","#e60000","#ff3333","#ff8080","#99e699","#70db70","#33cc33"];
    let ColorList = ["#81C784", "#FFA726", "#e57373"];
    let weightage1All = []



    function getColor2(name) {
      let color = ""
      if (name === "Strongly Disagree") {
        color = ColorList[1]
      }
      else if (name === "Disagree") {
        color = ColorList[2]
      }
      else if (name === "Slightly Disagree") {
        color = ColorList[3]
      }
      else if (name === "Slightly Agree") {
        color = ColorList[4]
      }
      else if (name === "Agree") {
        color = ColorList[5]
      }
      else if (name === "Strongly Agree") {
        color = ColorList[5]
      }
      return color
    }

    if (true) {

      if (rowList && rowList.length > 0) {
        rowList.forEach((inner, index) => {
          let valueData = {
            value: inner.value,
            itemStyle: { color: ColorList[index]}
          }


          weightage1All.push({
            name: inner.name,
            type: 'bar',
            stack: '总量',
            barWidth: '30px',
            label: {
              show: true,
              position: 'insideRight',
              formatter: function (d) {
                if (parseInt(d.value) < 7) {
                  return '';
                }
                else {
                  return d.value;
                }
              }
            },
            data: [valueData],
            inverse: true
          });
        })
      }


      names.push({
        value: "Overall",
        textStyle: { fontWeight: 'bold' }
      })

    }



    return (
      <>
        <div style={{ zIndex: '' }}>

          {true ?
            <ReactEcharts
              style={{ height: '37px', marginTop: '6px', width: '100%' }}
              option={{
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow',
                  }
                },
                // grid: {
                //     left: '3%',
                //     right: '4%',
                //     bottom: '3%',
                //     containLabel: true
                // },
                xAxis: {
                  type: 'value',
                  axisLine: 'left',
                  splitLine: {
                    lineStyle: {
                      color: '#fff'
                    }
                  },
                  axisLabel: {
                    show: false
                  },
                  max: 100

                },
                yAxis: {
                  type: 'category',
                  data: names,
                  position: 'left',
                  inverse: true,
                  axisLine: {
                    lineStyle: {
                      color: '#fff'
                    }
                  },
                  axisLabel: {
                    show: false
                  }
                },
                axisLabel: {
                  color: "#000",
                  interval: 0,
                  formatter: function (value) {
                    if (value.length > 20) {
                      return value.substring(0, 20) + ".. :";
                    } else {
                      return value + ":";
                    }
                  }
                },
                series: weightage1All
              }}
            />
            : null}

        </div>

        {/* {/* <div
          className="tooltip-response2"
        >
          <div style={{
            paddingLeft: '10px',
            paddingTop: '10px',

          }}>{(currFilterValue !== 'none') ? currFilterValue : "Overall"}</div>
          <div style={{
            paddingLeft: '15px',
            paddingTop: '7px'
          }}>
            <div style={{
              width: '10px',
              height: '10px',
              background: ColorList[1],
              borderRadius: '50%',
              float: 'left',
              marginTop: '3px'
            }}></div>
            <div style={{
              marginLeft: '12px',
              marginRight: '20px',
              float: 'left',
              width: '100px'
            }}>{"Strongly Disagree"}</div>
            <div style={{
              marginLeft: '25px',
              fontWeight: 'bold',
              width: '20px'
            }}>{weightage1[0].value + ' %'}</div>

          </div>
          <div style={{
            paddingLeft: '15px',
            paddingTop: '7px'
          }}>
            <div style={{
              width: '10px',
              height: '10px',
              background: ColorList[2],
              borderRadius: '50%',
              float: 'left',
              marginTop: '3px'
            }}></div>
            <div style={{
              marginLeft: '12px',
              marginRight: '20px',
              float: 'left',
              width: '100px'
            }}>{"Disagree"}</div>
            <div style={{
              marginLeft: '25px',
              fontWeight: 'bold'
            }}>{weightage2[0].value + ' %'}</div>

          </div>
          <div style={{
            paddingLeft: '15px',
            paddingTop: '7px'
          }}>
            <div style={{
              width: '10px',
              height: '10px',
              background: ColorList[3],
              borderRadius: '50%',
              float: 'left',
              marginTop: '3px'
            }}></div>
            <div style={{
              marginLeft: '12px',
              marginRight: '20px',
              float: 'left',
              width: '100px'
            }}>{"Slightly Disagree"}</div>
            <div style={{
              marginLeft: '25px',
              fontWeight: 'bold'
            }}>{weightage3[0].value + ' %'}</div>

          </div>
          <div style={{
            paddingLeft: '15px',
            paddingTop: '7px'
          }}>
            <div style={{
              width: '10px',
              height: '10px',
              background: ColorList[4],
              borderRadius: '50%',
              float: 'left',
              marginTop: '3px'
            }}></div>
            <div style={{
              marginLeft: '12px',
              marginRight: '20px',
              float: 'left',
              width: '100px'
            }}>{"Slightly Agree"}</div>
            <div style={{
              marginLeft: '25px',
              fontWeight: 'bold'
            }}>{weightage4[0].value + ' %'}</div>

          </div>
          <div style={{
            paddingLeft: '15px',
            paddingTop: '7px'
          }}>
            <div style={{
              width: '10px',
              height: '10px',
              background: ColorList[5],
              borderRadius: '50%',
              float: 'left',
              marginTop: '3px'
            }}></div>
            <div style={{
              marginLeft: '12px',
              marginRight: '20px',
              float: 'left',
              width: '100px'
            }}>{"Agree"}</div>
            <div style={{
              marginLeft: '25px',
              fontWeight: 'bold'
            }}>{weightage5[0].value + ' %'}</div>

          </div>
          <div style={{
            paddingLeft: '15px',
            paddingTop: '7px'
          }}>
            <div style={{
              width: '10px',
              height: '10px',
              background: ColorList[6],
              borderRadius: '50%',
              float: 'left',
              marginTop: '3px'
            }}></div>
            <div style={{
              marginLeft: '12px',
              marginRight: '20px',
              float: 'left',
              width: '100px'
            }}>{"Strongly Agree"}</div>
            <div style={{
              marginLeft: '25px',
              fontWeight: 'bold'
            }}>{weightage6[0].value + ' %'}</div> */}

        {/* </div> */}

        {/* </div> */}



      </>
    );
  }
}
function mapStateToProps(state) {
  //   //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionStack);
