import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions, dashboardActions, pmsActions } from "../../../../_actions";
import Filter from "../../../../pages/CommonComponents/Filters/IndexFilter/Filter";
import ReactEcharts from "echarts-for-react";
//libraries
import * as echarts from 'echarts';
import RoundChart from '../Chart/RoundChart';

class Normalization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: "none",
      showLeaderBoard: false,
      isMultifilter: false,
      currentSelectValue: "",
      FilterValues: [],
      showFilter: false,
      showValue: "",
      ListName: "Demographic",
      showEDAModal: false,
      crrDMGValue: "",
      activeTab: "TableView",
      activeTab2: "BeforeNormalized",
      activeCard: 'selfAppraisalStatus',
      mainTab: "AppraisalCycle",
      showhide: false,
      valueRatingFilter: "All",
      modalToggle: 0,

      currentPage: 1,
      itemsPerPage: 10,

    };
  }
  componentDidMount() {


    let data = {
      "appraisalCycleId": this.props.appraisalCycleId,
    };
    this.props.dispatch(pmsActions.PMSGetDetailsByAppraisalCycleId(data));


  }

  // handleToggle = () => {
  //   this.setState((prevState) => ({
  //     modalToggle: !prevState.modalToggle,
  //   }));
  // };

  handleTabClick = (tabName) => {
    this.setState({ activeTab: tabName });
  };
  handleTabClick2 = (tabName) => {
    this.setState({ activeTab2: tabName });
  };
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    // let { handleAppraisalStatusFilter } = this.props;
    this.setState({ FilterValues: finalFilter, ListName: name });
    this.filterEmployeeRatingAndNormalization(finalFilter, true, name);
  };



  filterEmployeeRatingAndNormalization = (FilterValues) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "appraisalCycleId": this.props.appraisalCycleId,
      "filters": Filters
    }

    this.props.dispatch(dashboardActions.getEmployeeRatingAndNormalization(data7))
  }

  saveapi = (e, item) => {
    let data = {
      "appraisalCycleId": this.props.appraisalCycleId,
      "emp_id": item && item.reviewee_emp_id,
      "label": e.target.value,
      "value": 1
    }
    let data2x = {
      "appraisalCycleId": this.props.appraisalCycleId,
      "type": "RATING",
      "filters": this.state.FilterValues
    }
    this.props.dispatch(dashboardActions.saveEmployeeNormalization(data, this.props, data2x));
  }

  submitNormalization = () => {
    let data = {
      "appraisalCycleId": this.props.appraisalCycleId,
    };
    this.props.dispatch(pmsActions.PMSNormlStatusChange(data));
    setTimeout(() => {
      this.setState({ modalToggle: false });
    }, 4000);
  }



  handleRatingFilter = (value) => {
    this.setState({ valueRatingFilter: value });
  }

  render() {

    let { pms, dashboard } = this.props;
    let { EmpFilterData, getIndexByFilterDMGData, getEMPFilterLabel, getEmployeeRatingAndNormalization } = dashboard;
    let { PMSGetDetailsByAppraisalCycleId } = pms;
    let { activeTab, valueRatingFilter } = this.state;

    let activeTab2 = PMSGetDetailsByAppraisalCycleId && PMSGetDetailsByAppraisalCycleId.isNormlDone ? "AfterNormalized" : "BeforeNormalized";

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    let expectedScoreObj = (getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.normlLabelCount ? getEmployeeRatingAndNormalization.normlLabelCount : {})

    let actualScoreObj = (getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.labels ? getEmployeeRatingAndNormalization.labels : {})
    console.log(expectedScoreObj, actualScoreObj, 'actualScoreObj')

    let optionloop = Object.keys(getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.labels ? getEmployeeRatingAndNormalization.labels : {})

    const socreResult = {};

    const finalresult = [expectedScoreObj, actualScoreObj];
    for (const obj of finalresult) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (!socreResult.hasOwnProperty(key)) {
            socreResult[key] = obj[key];
          } else {
            socreResult[key] += obj[key];
          }
        }
      }
    }
    let socreResult2 = [socreResult]

    function sumObjItems(value) {
      console.log("value", value)

      let dataadd = Object.keys(value) && Object.keys(value).length > 0 ? Object.values(value).reduce((a, b) => a + b) : []
      return dataadd
      // return []
    }

    function getScoreLabel(ss, value) {
      let final = ''
      let vv = value ? (parseFloat(value) !== "NaN" ? parseFloat(value) : 0) : 0;
      if (ss && ss.scoreLabels && ss.scoreLabels.length > 0) {
        ss.scoreLabels.forEach((ele) => {
          if ((parseFloat(ele.a) <= vv) && (vv < parseFloat(ele.b))) {
            final = ele.label
          }
        })
      }
      return final
    }

    // function getStatusDetails(item, field) {
    //   let score = item && item["status"] && item["status"][field]?item["status"][field]:0;
    //   let settings = PMSGetDetailsByAppraisalCycleId && PMSGetDetailsByAppraisalCycleId.settings ? PMSGetDetailsByAppraisalCycleId.settings : {}
    //   console.log("score----a>", score);
    //   console.log("settings----a>", settings);
    //   return getScoreLabel(settings, score)
    // }

    function getStatusDetails(item, field) {
      let score = item && item["status"] && item["status"][field] ? item["status"][field] : (field === "normlLabel" ? "Select" : "NA");
      let settings = PMSGetDetailsByAppraisalCycleId && PMSGetDetailsByAppraisalCycleId.settings ? PMSGetDetailsByAppraisalCycleId.settings : {}
      console.log("score----a>", score);
      console.log("settings----a>", settings);
      return score
    }

    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split('.')[0];
        let second = str.split('.')[1];
        let second2 = second ? second.substring(0, 2) : ""
        let final = first + '.' + second2;
        if (second2) {
          return parseFloat(final).toFixed(2)
        } else {
          return no
        }
      } else {
        return 0
      }
    }

    function getNormlStatusDetails(item, field, field2) {
      if (item && item[field] && item[field][field2]) {
        return (item[field][field2])
      }
    }
    function perCalc(value, total) {
      if (value && total) {
        return spliceDecimal((value / total) * 100) + "%"
      } else {
        return 0
      }
    }

    let getEmployeeRatingAndNormalizationList = []
    if (valueRatingFilter) {
      if (getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.list && getEmployeeRatingAndNormalization.list.length > 0) {
        if (valueRatingFilter === "All") {
          getEmployeeRatingAndNormalizationList = getEmployeeRatingAndNormalization.list;
        } else {
          getEmployeeRatingAndNormalization.list.forEach((item) => {
            if (valueRatingFilter === getStatusDetails(item, "scoreLabel").trim()) {
              getEmployeeRatingAndNormalizationList.push(item);
            }
          })
        }
      }
    }

    let optionloopV2 = ['All']
    if (optionloop && optionloop.length > 0) {
      optionloop.forEach((ele) => {
        optionloopV2.push(ele)
      })
    }

    let getEmployeeRatingAndNormalizationListV2 = getEmployeeRatingAndNormalizationList;

    // console.log("getEmployeeRatingAndNormalizationList------------>", getEmployeeRatingAndNormalizationList);
    // console.log('valueRatingFilter------------------------------------->', valueRatingFilter);
    // console.log("valueRatingFilter(details)---", valueRatingFilter)
    // console.log("getEmployeeRatingAndNormalizationList(11)---", getEmployeeRatingAndNormalizationList)





    // const itemsPerPage = 10; // Number of items to show per page
    // const totalItems = this.props.getEmployeeRatingAndNormalizationList ? this.props.getEmployeeRatingAndNormalizationList.length : 0;
    // const totalPages = Math.ceil(totalItems / itemsPerPage);

    // // Calculate the range of items to display on the current page
    // const startIndex = (this.state.currentPage - 1) * itemsPerPage;
    // const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

    // // Create an array for the buttons
    // const pageButtons = Array.from({ length: totalPages }, (_, i) => i + 1);

    // const handlePageChange = (page) => {
    //   this.setState({ currentPage: page });
    // };



    // const { currentPage, itemsPerPage } = this.state;
    // const totalItems = this.props.getEmployeeRatingAndNormalizationListV2
    //   ? this.props.getEmployeeRatingAndNormalizationListV2.length
    //   : 0;
    // const totalPages = Math.ceil(totalItems / itemsPerPage);

    const { currentPage, itemsPerPage } = this.state;
    const totalItems = this.props.getEmployeeRatingAndNormalizationListV2
      ? this.props.getEmployeeRatingAndNormalizationListV2.length
      : 0;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage + 1;
    const endIndex = Math.min(currentPage * itemsPerPage, totalItems);




    return (
      <>
        <main className="flex-1 md:p-6 p-4 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto ">
          <div className="space-y-4">
            <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
              <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]  ">
                Normalization
              </h1>
              <div className="md:flex  hidden items-center text-sm text-[#3D405B]">
                <span className="mx-2 material-symbols-rounded">home</span>
                <span>/</span>
                <span className="px-2">PMS</span>
                <span>/</span>
                {/* <span className="px-2">Appraisal Cycle</span>
                <span>/</span> */}
                <span className="px-2 font-semibold">Normalization</span>
              </div>
            </div>

            <div className="items-center justify-between md:flex">
              <div className="flex flex-wrap gap-2">
                {/* <div className="cursor-pointer bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] flex justify-between px-4 items-center relative py-2 rounded-md w-40 "> */}
                <div className="flex items-center space-x-2 ">
                  <Filter
                    showFilter={this.state.showFilter}
                    showValue={this.state.showValue}
                    closeFilter={this.closeFilter}
                    EmpFilterData2={EmpFilterData2}
                    getIndexFilterData={getIndexByFilterDMGData}
                    handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
                    getEMPFilterLabel={getEMPFilterLabel}
                  />
                </div>
              </div>

            </div>

            <div className="p-6 space-y-6 bg-white border rounded-md">

              <div className="items-center justify-between md:flex">
                <h1 class="text-xl font-medium text-[#3D405B]">Expected vS Actual Rating</h1>
                <div className="flex  text-sm font-medium text-gray-600 border rounded-full bg-[#F9FAFB]  ">
                  <span
                    onClick={() => this.handleTabClick2("BeforeNormalized")}
                    className={
                      activeTab2 === "BeforeNormalized"
                        ? "cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white   rounded-full   border-[#2196f3]"
                        : "cursor-pointer text-sm py-2 px-4 font-medium   text-[#3D405B]/70   rounded-full  "
                    }
                  >
                    Before Normalized
                  </span>
                  <span
                    onClick={() => this.handleTabClick2("AfterNormalized")}
                    className={
                      activeTab2 === "AfterNormalized"
                        ? "cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white   rounded-full   border-[#2196f3]"
                        : "cursor-pointer text-sm py-2 px-4 font-medium   text-[#3D405B]/70   rounded-full  "
                    }
                  >
                    After Normalized
                  </span>

                </div>
              </div>

              <div className="flex space-x-2 text-sm font-medium text-gray-600 ">
                <span
                  onClick={() => this.handleTabClick("TableView")}
                  className={
                    activeTab === "TableView"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium text-[#2196f3] flex items-center space-x-2  border   rounded-md   border-[#2196f3]"
                      : "cursor-pointer text-sm py-2 px-4 font-medium   text-[#3D405B]/70 border  rounded-md flex items-center space-x-2  "
                  }
                >

                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-table" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z"></path>
                    <path d="M3 10h18"></path>
                    <path d="M10 3v18"></path>
                  </svg>
                  <span>  Table View</span>
                </span>
                <span
                  onClick={() => this.handleTabClick("BellCurve")}
                  className={
                    activeTab === "BellCurve"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium text-[#2196f3] flex items-center space-x-2   border   rounded-md   border-[#2196f3]"
                      : "cursor-pointer text-sm py-2 px-4 font-medium   text-[#3D405B]/70 border  rounded-md flex items-center space-x-2  "
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-sankey" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M3 3v18h18"></path>
                    <path d="M3 6h18"></path>
                    <path d="M3 8c10 0 8 9 18 9"></path>
                  </svg>
                  <span>  Bell Curve</span>
                </span>

              </div>
              {activeTab === "BellCurve" ?
                <RoundChart
                  actualScoreObj={actualScoreObj}
                  expectedScoreObj={expectedScoreObj}
                />
                : <div className="p-6 space-y-6 border rounded-md">
                  <h1 class="text-xl font-medium text-[#3D405B]">Expected VS Actual Rating</h1>

                  <div className="overflow-x-auto border ">
                    <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse xl:whitespace-normal whitespace-nowrap">
                      <thead className="bg-white">
                        <tr className="bg-[#F9FAFB] ">
                          <th
                            className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r"
                          >

                          </th>

                          {Object.keys(expectedScoreObj) && Object.keys(expectedScoreObj).length > 0 ?
                            Object.keys(expectedScoreObj).map((key) => (
                              <th className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r">
                                <p>{key}</p>
                                {/* <span className="font-semibold" >{actualScoreObj && actualScoreObj[key]?actualScoreObj[key]:0}/{expectedScoreObj[key]}</span> */}
                              </th>
                            )) : null}




                          <th
                            className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                          >
                            Total
                          </th>
                        </tr>

                      </thead>
                      <tbody className="border-t divide-y ">
                        <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                          <td className="px-6 py-3 font-semibold border-r ">
                            Expected % (Numbers)
                          </td>


                          {Object.keys(expectedScoreObj) && Object.keys(expectedScoreObj).length > 0 ?
                            Object.keys(expectedScoreObj).map((key) => (
                              <td className="px-6 py-3 text-center border-r ">
                                <span className="font-semibold" >{perCalc(expectedScoreObj[key], sumObjItems(expectedScoreObj))}({expectedScoreObj[key]})</span>
                              </td>
                            )) : null}



                          <td className="px-6 py-3 text-center border-r">
                            {perCalc(sumObjItems(expectedScoreObj), sumObjItems(expectedScoreObj))}({sumObjItems(expectedScoreObj)})
                          </td>



                        </tr>

                        <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                          <td className="px-6 py-3 font-semibold border-r ">
                            Actual % (Numbers)
                          </td>

                          {Object.keys(actualScoreObj) && Object.keys(actualScoreObj).length > 0 ?
                            Object.keys(actualScoreObj).map((key) => (
                              <td className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"  >
                                <span className="font-semibold" >{perCalc(actualScoreObj[key], sumObjItems(actualScoreObj))}({actualScoreObj[key]})</span>
                              </td>
                            )) : null}




                          <td className="px-6 py-3 text-center border-r">

                            {perCalc(sumObjItems(actualScoreObj), sumObjItems(actualScoreObj))}({sumObjItems(actualScoreObj)})

                          </td>



                        </tr>



                      </tbody>
                    </table>
                  </div>
                </div>}



            </div>

            <div className="p-6 space-y-6 bg-white border rounded-md">

              <div className="items-center justify-between md:flex">
                <h1 class="text-xl font-medium text-[#3D405B]">Manage Normalization</h1>

                <div className="flex">
                  <div className="px-2 py-2">Rating: </div>
                  <div className="flex  text-sm font-medium text-gray-600 border rounded-full bg-[#F9FAFB]  ">
                    <select defaultValue={valueRatingFilter} name="priority" onChange={(e) => this.handleRatingFilter(e.target.value)}
                      className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring-1">
                      <option disabled={true} value={valueRatingFilter}>
                        {valueRatingFilter}
                      </option>
                      {optionloopV2 && optionloopV2.length > 0 ?
                        optionloopV2.map((elements) =>
                          (valueRatingFilter === "All" ? elements !== "All" : true) ?
                            <option value={elements}> {elements}</option>
                            : null
                        ) : null}

                    </select>

                  </div>
                </div>


              </div>

              <div className="overflow-x-auto border ">
                <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse xl:whitespace-normal whitespace-nowrap">
                  <thead className="bg-white">
                    <tr className="bg-[#F9FAFB] ">
                      <th
                        className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r"
                      >
                        Rating / Normalized Rating
                      </th>
                      <th
                        className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                      >

                      </th>

                      <th
                        className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                      >

                      </th>
                      <th
                        className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                      >

                      </th>
                      <th
                        className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                      >

                      </th>

                    </tr>

                  </thead>

                  <tbody className="border-t divide-y ">
                    <tr className="bg-white  font-normal text-sm text-[#3D405B] ">

                      {Object.keys(expectedScoreObj) && Object.keys(expectedScoreObj).length > 0 ?
                        Object.keys(expectedScoreObj).map((key) => (
                          <td className="px-6 py-3 border-r ">
                            <p>{key}</p>
                            <span className="font-semibold" >{actualScoreObj && actualScoreObj[key] ? actualScoreObj[key] : 0}/{expectedScoreObj[key]}</span>
                          </td>
                        )) : null}
                    </tr>

                  </tbody>
                </table>
              </div>


              <div className="overflow-x-auto border ">
                <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse xl:whitespace-normal whitespace-nowrap">
                  <thead className="bg-white">
                    <tr className="bg-[#F9FAFB] ">
                      <th className="px-6 py-3 font-semibold text-sm text-[#3D405B] border-r text-center w-[10%]">Sr.No</th>
                      <th className="px-6 py-3 font-semibold text-sm text-[#3D405B] border-r w-[25%]">Employee</th>
                      <th className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r w-[25%]">Actual Rating</th>
                      <th className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r w-[25%]">Normalized Rating</th>
                    </tr>
                  </thead>

                  {/* {this.props.getEmployeeRatingAndNormalizationListV2 &&
                    this.props.getEmployeeRatingAndNormalizationListV2
                      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                      .map((item, index) => ( */}

                  {getEmployeeRatingAndNormalizationListV2 && getEmployeeRatingAndNormalizationListV2.length > 0 ?
                    getEmployeeRatingAndNormalizationListV2.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                      .map((item, index) =>
                        (true) ?
                          <tbody className="border-t divide-y ">

                            <tr key={index} className="bg-white  font-normal text-sm text-[#3D405B]">
                              <td className="px-6 py-3 text-center border-r">{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                              <td className="px-6 py-3 border-r ">
                                <div className="flex items-center space-x-2">
                                  <img src="img/newimg/Rectangle 11116.png" alt="" className="w-12 h-12 rounded-full" />
                                  <div className="">
                                    <span className="capitalize text-base font-medium block text-[#3D405B]">{item && item.reviewee_name}</span>
                                    <p className="capitalize text-sm  block text-[#3D405B] font-normal">{item && item.reviewee_emp_id}</p>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-3 text-center border-r ">
                                {getStatusDetails(item, "scoreLabel")}
                              </td>

                              {activeTab2 === "AfterNormalized" ?
                                <td className="px-6 py-3 text-center border-r ">
                                  {getStatusDetails(item, "normlLabel") ? getStatusDetails(item, "normlLabel") : getStatusDetails(item, "scoreLabel")}
                                </td>
                                :
                                <td className="px-6 py-3 border-r">
                                  <div className="flex justify-center">
                                    <select defaultValue={getStatusDetails(item, "normlLabel")} name="priority" onChange={(e) => this.saveapi(e, item)}
                                      className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring-1">
                                      <option disabled={true} value={getNormlStatusDetails(item, "normlStatus", "scoreLabel")}>
                                        {getStatusDetails(item, "normlLabel") ? getStatusDetails(item, "normlLabel") : "Select"}
                                      </option>
                                      {optionloop && optionloop.length > 0 ?
                                        optionloop.map((elements) =>
                                          <option value={elements}> {elements}</option>
                                        ) : null}
                                    </select>
                                  </div>
                                </td>}
                            </tr>
                          </tbody>
                          : null
                      )
                    : null}
                </table>
              </div>


              {/* Pagination buttons */}





              {/* Pagination start*/}
              <div className="flex items-center justify-center w-full h-16 bg-white ">
                <div className="flex flex-wrap border divide-x rounded-md ">
                  <button onClick={() => this.setState({ currentPage: currentPage - 1, getEmployeeRatingAndNormalizationList })}
                    disabled={currentPage === 1}
                    class="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                    Prev
                  </button>

                  {getEmployeeRatingAndNormalizationList && getEmployeeRatingAndNormalizationList.length > 0 ? (
                    <>
                      <button class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">
                        {this.state.currentPage}
                      </button>
                      <button class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">
                        {getEmployeeRatingAndNormalizationList &&
                          getEmployeeRatingAndNormalizationList.length > 0
                          ? getEmployeeRatingAndNormalizationList.length
                          : 0}
                      </button>
                    </>
                  ) : (
                    <button class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] flex">
                      <p className='text-[#3D405B]/50 pl-2'>{0}</p>
                    </button>
                  )}
                  <button
                    onClick={() => this.setState({ currentPage: currentPage + 1, getEmployeeRatingAndNormalizationList })}
                    disabled={this.state.currentPage === getEmployeeRatingAndNormalizationList.length}
                    class="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">Next</button>
                </div>
              </div>

              {/* Pagination start*/}


              {activeTab2 === "BeforeNormalized" ?
                <div className="flex items-center justify-end px-6 py-6 -m-6 space-x-4 text-sm font-medium bg-white border-t">
                  {/* <button className="w-32 px-4 py-2 uppercase bg-[#F9FAFB] text-[#3D405B] border rounded-sm">save</button> */}
                  <button onClick={() => this.setState({ modalToggle: 1 })} className="border rounded-sm w-32 py-2 px-4 uppercase bg-[#F9FAFB] text-[#3D405B]">Submit</button>
                </div>
                : null}


              <div className="py-6"></div>
            </div>
          </div>

          {/* modal */}
          {this.state.modalToggle === 1 ? <div className="fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center w-screen h-screen bg-black/60">
            <div className="max-w-xl mx-auto -mt-10 ">
              <div className="w-full bg-white rounded-lg shadow-xl ">
                <div className="flex flex-col items-center p-6 space-y-6">
                  <div className="flex items-center justify-center p-4 bg-[#FFC107]/10 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-exclamation-mark text-[#FFC107]" width="60" height="60" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 19v.01"></path>
                      <path d="M12 15v-10"></path>
                    </svg>
                  </div>
                  <h2 className=" text-xl text-[#212121] font-semibold  text-center">Are you sure you want to Submit<br /> the Normalized Rating?</h2>
                </div>

                <div className="flex justify-center p-6 space-x-4 text-center ">
                  <button onClick={() => this.setState({ modalToggle: false })} type="button" className="border w-56 rounded-md  py-2.5 text-base bg-white text-[#212121]/70 focus:outline-none flex items-center justify-center hover:bg-gray-50 Capitalize text-center font-medium uppercase">NO, Cancel</button>
                  {/* <button onClick={() => { this.setState({ modalToggle: 2 }) }} type="button" className="border w-56 border-[#2196F3] rounded-md  py-2.5 text-base bg-[#2196F3] text-white focus:outline-none flex items-center justify-center hover:bg-[#2196F3]/90 Capitalize text-center font-medium uppercase">YEs, COntinue</button> */}
                  {/* <button onClick={() => { this.setState({ modalToggle: 2 }); submitNormalization() }} type="button" className="border w-56 border-[#2196F3] rounded-md  py-2.5 text-base bg-[#2196F3] text-white focus:outline-none flex items-center justify-center hover:bg-[#2196F3]/90 Capitalize text-center font-medium uppercase">YEs, COntinue</button> */}
                  <button onClick={() => { this.setState({ modalToggle: 2 }); this.submitNormalization() }} type="button" className="border w-56 border-[#2196F3] rounded-md  py-2.5 text-base bg-[#2196F3] text-white focus:outline-none flex items-center justify-center hover:bg-[#2196F3]/90 Capitalize text-center font-medium uppercase">YEs, COntinue</button>
                </div>
              </div>
            </div>
          </div>
            :
            null}


          <div className={this.state.modalToggle === 2 ? "fixed right-0 bottom-6 w-full flex" : "hidden"}>
            <div className="hidden w-64 lg:block">
            </div>
            <div className="w-full">
              <div className="bg-[#4CAF50] lg:p-6 p-4 flex justify-between items-center rounded-lg shadow-2xl  lg:w-8/12 w-11/12 mx-auto   ">
                <div className="flex items-center space-x-4">
                  <div className="h-12 w-12 flex justify-center items-center bg-white text-[#4CAF50] rounded-full flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                  </div>
                  <p className="text-base font-semibold text-white lg:text-xl">Changes has been successfully saved.</p>
                </div>

                <div onClick={() => this.setState({ modalToggle: false })} className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-full cursor-pointer bg-black/10">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M18 6l-12 12"></path>
                    <path d="M6 6l12 12"></path>
                  </svg>
                </div>

              </div>
            </div>
          </div>

        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, pms } = state;
  return {
    loggingIn,
    dashboard,
    users,
    pms
  };
}
export default connect(mapStateToProps)(Normalization);
