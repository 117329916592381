import React, { Component } from 'react';
import { connect } from 'react-redux';

class ReportWaiseData extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }

  render() {

    let { item, index } = this.props;

    // console.log('Start111111111111111111111111', item);
    let { total } = item ? item : {};

    const customOrder = ['1 Star', '2 Star', '3 Star', '4 Star', '5 Star'];
    total.sort((a, b) => customOrder.indexOf(a._id.answerText + ' Star') - customOrder.indexOf(b._id.answerText + ' Star'));

    const chartData = total.map(item => ({
      name: item._id && item._id.answerText ? item._id.answerText : '',
      value: parseFloat(item && item.count ? Math.round((item.count / total.reduce((acc, curr) => acc + curr.count, 0) * 100)) : 0),
      count: item.count,
    }));

    // Generate xAxis data dynamically
    const xAxisData = total
      .map(item => item._id && item._id.answerText + ' Star')
      .sort((a, b) => customOrder.indexOf(a) - customOrder.indexOf(b));

    let avgCount = 0;
    if (chartData && chartData.length > 0) {
      chartData.forEach(element => {
        avgCount = (parseInt(element.name) * element.count) / chartData.reduce((acc, curr) => acc + curr.count, 0)
      })
    }


    return (

      <div className='p-6 space-y-4 bg-white'>
        {/* <div className='flex flex-row items-center justify-between w-full gap-4'>
          <div className='flex items-center text-[#212121] space-x-2'>
            <p className='p-3 bg-[#ededed] font-semibold'>Question {index + 1}</p>
          </div>
        </div>
        <div className='space-y-2 '>
          <h2 className='text-xl font-medium text-[#212121]'>{item && item.question.name ? item.question.name : 'null'}</h2>
          <p className='text-base text-[#212121]/80 flex space-x-2 items-center pt-3'>
            <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>Star Rating</p>
            <b className='font-medium text-[#212121] pr-1'>10</b> out of<b className='font-medium text-[#212121] pr-1'>10</b> answered
          </p>
        </div> */}

        <button class="flex w-full text-left space-x-4">
          <div class="text-primary flex h-10 items-center justify-center">
            <p className='bg-[#ededed] px-3 py-2 font-semibold whitespace-nowrap' style={{ fontWeight: "bold" }}>Q. {index + 1}</p>
          </div>
          <div class="w-full">
            <h4 class="text-xl font-medium text-[#212121]">{item && item.question.name ? item.question.name : 'null'}</h4>
            <p className='text-base text-[#212121]/80 flex space-x-2 items-center pt-3'>
              <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>Rating Scale</p>
              {/* <b className='font-medium text-[#212121] pr-1'>10</b> out of<b className='font-medium text-[#212121] pr-1'>10</b> answered */}
            </p>
          </div>
        </button>


        <div className="relative w-full">
          <div>
            <div className='flex items-center space-x-5'>
              <div className='bg-[#212121]/5 flex flex-col items-center justify-center space-y-4 p-10 h-72'>
                <p className='flex flex-nowrap whitespace-nowrap'>Average Rating</p>
                <div className='flex items-center space-x-2'>
                  <p className='flex items-center text-5xl'>{avgCount}</p>
                  <span style={{ paddingLeft: "4px" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                      <path d="M5.28125 23.75L7.3125 14.9688L0.5 9.0625L9.5 8.28125L13 0L16.5 8.28125L25.5 9.0625L18.6875 14.9688L20.7188 23.75L13 19.0938L5.28125 23.75Z" fill="#FFA726" />
                    </svg>
                  </span>
                </div>
              </div>

              <div className='w-full space-y-2'>
                {xAxisData.map((name, index) => (
                  <div key={index} className='flex flex-col pb-2 space-y-1'>
                    <div className='flex space-x-2'>
                      <p className='flex-shrink-0'>
                        <span className=''>{name}</span>
                        <b className='font-medium text-[#212121] pr-1'></b>
                      </p>

                      {[...Array(5)].map((_, starIndex) => (
                        <div key={starIndex}>
                          {starIndex < parseInt(name) ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 26 24" fill="none">
                            <path d="M5.28125 23.75L7.3125 14.9688L0.5 9.0625L9.5 8.28125L13 0L16.5 8.28125L25.5 9.0625L18.6875 14.9688L20.7188 23.75L13 19.0938L5.28125 23.75Z" fill="#FFA726" />
                          </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 26 24" fill="none">
                              <path d="M5.28125 23.75L7.3125 14.9688L0.5 9.0625L9.5 8.28125L13 0L16.5 8.28125L25.5 9.0625L18.6875 14.9688L20.7188 23.75L13 19.0938L5.28125 23.75Z" fill="#cccccc" />
                            </svg>}
                        </div>
                      ))}
                    </div>

                    <div className='flex items-center w-full space-x-3'>
                      <div className='h-8 bg-[#f5f5f5] w-full relative flex items-center space-x-6'>
                        <div className='h-8 bg-[#FFA726] absolute top-0 bottom-0 text-[#212121] flex items-center' style={{ width: `${chartData[index].value}%` }}>
                        </div>
                      </div>
                      <span className='flex-shrink-0 text-[#212121] w-10 '>{chartData[index] && `${chartData[index].value}%`}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>


      </div>


    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ReportWaiseData);
