import React, { Component } from 'react';
import { connect } from 'react-redux';

class WordDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
        currentEmpPage:1,
        EmpIndexA:0,
        EmpIndexB:4,
        pageCount:10
    }
  }



  componentDidMount() {
  }

  SelectEmpPage=(val)=>{
    this.setState({currentEmpPage:val})
  }


  prevEmpPage=()=>{
    let { EmpIndexA, EmpIndexB } = this.state;
    if(EmpIndexA>0){
        this.setState({EmpIndexA:EmpIndexA-1, EmpIndexB:EmpIndexB-1});
    }
  }
  nextEmpPage=(IndexListIndex)=>{
    let { EmpIndexA, EmpIndexB } = this.state;
    if(IndexListIndex && IndexListIndex.length && (EmpIndexB<IndexListIndex.length)){
      this.setState({EmpIndexA:EmpIndexA+1, EmpIndexB:EmpIndexB+1});
    }
  }

    render(){

        let { wordCloudTextData, wordTextKeyWord, loading } = this.props;
        let { currentEmpPage, EmpIndexA, EmpIndexB, pageCount } = this.state;


        let textData = []

        if(wordCloudTextData && wordCloudTextData.length>0){
            wordCloudTextData.forEach((item)=>{
              if(item.answerText.includes("answer1:") || item.answerText.includes("answer2:")){
                textData.push({
                  ...item,
                  ans1:item.answerText.substring(
                      item.answerText.lastIndexOf("answer1:") + 8, 
                      item.answerText.lastIndexOf("answer2:")-1
                  ),
                  ans2:item.answerText.substring(
                      item.answerText.lastIndexOf("answer2:") + 8, 
                      
                  )
                });
              }
              else{
                textData.push({
                  ...item,
                  ans3:item.answerText
                })
              }

            })
        }

        //console.log('wordTextKeyWord',wordTextKeyWord)

        let KEYWORD = '';
        if(wordTextKeyWord){
            KEYWORD = wordTextKeyWord.toLowerCase();
        }




                     //--------------------------------------------------------------------------------

    let IndexList = []
    let IndexListIndex = []
    let indexA=0
    let indexB=0

    if(textData && textData.length>0){
    

     if(textData && textData.length>0){
      textData.forEach((item,index)=>{
        if(index % pageCount === 0){
          IndexList.push(index)
        }
        if(index===(textData.length-1)){
          IndexList.push(index)
        }
      })
     }
     
     //console.log('IndexList: ',IndexList)

     if(IndexList && IndexList.length>0){
      IndexList.forEach((item,index)=>{
        if(index!==(IndexList.length-1)){
          IndexListIndex.push(index+1)
        }
      })
     }
     //console.log('IndexListIndex: ',IndexListIndex)





     if(IndexListIndex && IndexListIndex.length>0){
      IndexListIndex.forEach((item,index)=>{
        if(item === currentEmpPage){

          if(index<(IndexList.length-2)){
            indexA=IndexList[index];
            indexB=IndexList[index]+(pageCount-1)
          }
          else{
            indexA=IndexList[index];
            indexB=IndexList[IndexList.length-1]
          }
        }
      })
     }

  }
     //--------------------------------------------------------------------------------





        return(
        <>
        
        <div className="justify-center w-full p-4 m-4 mr-2 bg-white shadow-lg" >
                        
                        
                        
                        
                        <div className="items-center justify-between py-4 border-b md:flex">
                        
                        <h1 className="px-4 text-sm font-semibold text-gray-900">{KEYWORD}</h1>
                        {/* <div id="expand" className="md:mt-0 mt-4 bg-white mx-4  text-slate-800 flex justify-between px-4 items-center relative border py-1.5 rounded-md">
                            <span className="pr-4 text-xs font-semibold text-slate-800">Where does it come from? </span> 
                            <span id="expandicon" className="text-gray-500 material-symbols-outlined">expand_more</span>
                            <div id="dropdown" className="absolute right-0 z-20 hidden w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10 w-60 ">
                            <span className="inline-flex items-center w-full px-4 py-3 text-xs border-b hover:bg-gray-100 whitespace-nowrap"> 
                                Multiple Choice</span>
                            <span className="inline-flex items-center w-full px-4 py-3 text-xs border-b hover:bg-gray-100 ">
                                Check Box</span>
                            <span className="inline-flex items-center w-full px-4 py-3 text-xs hover:bg-gray-100 ">
                                Text Box</span>
                            </div>
                        </div> */}

                        
                        </div>

                        <div className="p-4 ">


                        {textData && textData.length>0?
                            textData.map((item, index)=>
                            (index >= indexA && index <=indexB)?
                                <div key={index} className="flex items-center justify-between py-2 border-b">
                                    <div className="leading-loose">
                                                {item.ans1?
                                                    <h2 className="py-1 text-xs font-bold">{'A) '+item.ans1}</h2>
                                                :null}
                                                {item.ans2?
                                                    <h2 className="py-1 text-xs font-bold">{'B) '+item.ans2}</h2>
                                                :null}
                                                {item.ans3?
                                                    <h2 className="py-1 text-xs font-bold">{item.ans3}</h2>
                                                :null}
                                                <div className="flex px-2 py-1">
                                                  {item && item.Department?<p className="px-1 pl-4 text-xs text-gray-500">{item.Department+', '}</p>:null}
                                                  {item && item.Gender?<p className="px-1 text-xs text-gray-500">{item.Gender+', '}</p>:null}
                                                  {item && item.Country?<p className="px-1 text-xs text-gray-500">{item.Country}</p>:null}
                                                </div>
                                                {/* <p className="text-xs text-gray-500">January 22 2021, 11:00 AM</p>
                                                <span className="text-xs font-bold">Agree</span>
                                                </div>
                                                <div className="text-center"><span className="px-4 py-2 text-xs text-white bg-green-400 rounded-full">Suggestion</span>
                                                <p className="py-3 text-xs font-bold">Confidence: 40</p> */}
                                    </div>
                                </div>
                            :null
                            )
                        :
                        <>
                        {!loading?
                        <div className="pt-40 text-2xl text-center text-gray-400">Data not available!</div>
                        :null}
                        </>
                        }



                        {/*end */}
                        {/* <div className="flex items-center justify-center py-4 text-center ">
                            <span className="inline-flex items-center justify-center w-8 h-8 mr-4 text-sm text-white bg-gray-400 border border-transparent rounded-full material-symbols-outlined" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_left</span>
                            <span className="inline-flex items-center justify-center mx-1 text-sm ">1-10</span>
                            <span className="inline-flex items-center justify-center mx-1 text-sm ">of</span>
                            <span className="inline-flex items-center justify-center mx-1 text-sm ">238</span>
                            <span className="inline-flex items-center justify-center w-8 h-8 ml-4 text-sm text-white bg-blue-500 border rounded-full material-symbols-outlined " style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_right</span>
                        </div> */}

                        <div className="flex items-center justify-center py-8 text-center">
                            <span onClick={()=>this.prevEmpPage()} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-gray-400 border border-transparent rounded-full select-none material-symbols-outlined" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_left</span>
                            {IndexListIndex && IndexListIndex.length>0?
                                IndexListIndex.map((item,index)=>
                                    (index >= EmpIndexA && index <= EmpIndexB)?
                                    (item===currentEmpPage)?
                                    <span onClick={()=>this.SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-blue-500 border border-transparent rounded-full">{item}</span>
                                    :
                                    <span onClick={()=>this.SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm bg-white border rounded-full">{item}</span>
                                    :null
                                )

                            :null}
                            {/* <span className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-blue-500 border border-transparent rounded-full">1</span>
                            <span className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm bg-white border rounded-full">2</span>
                            <span className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm bg-white border rounded-full">3</span> */}
                            <span onClick={()=>this.nextEmpPage(IndexListIndex)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-blue-500 bg-white border rounded-full select-none material-symbols-outlined" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_right</span>
                        </div>



                        </div>
                        <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>

                    </div>
                    
            </>
        );
    }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(WordDetails);
