import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class DriverRadar extends Component {
  constructor(props) {
    super(props);
    this.state = {


    }
  }

  componentDidMount() {

  }


  render() {
    let { GetParameterLabel, get360DimensionRelationScoreData } = this.props;



    let ValueList = [];
    let DimensionList = [];
    let RelationList = [];


    let get360DimensionRelationScoreList = []
    if(get360DimensionRelationScoreData && get360DimensionRelationScoreData.length>0){
      get360DimensionRelationScoreList = get360DimensionRelationScoreData.map((element)=>(element))
      get360DimensionRelationScoreList.sort((a,b)=>((a && a.item && a.item._id?a.item._id:"").charCodeAt(0)-(b && b.item && b.item._id?b.item._id:"").charCodeAt(0)))
    }



    if (get360DimensionRelationScoreList && get360DimensionRelationScoreList.length > 0) {
      get360DimensionRelationScoreList.forEach((item) => {
        let dimension1 = item && item._id ? item._id : "";

        let dimension = dimension1;


        let temp = {
          "dimension": dimension,
          "Self": 0,
          "Others": 0,
          "OthersCount": 0
        }
        if (item && item.total && item.total.length > 0) {
          item.total.forEach((inner) => {
            if (inner && inner._id && inner._id.relation) {
              let scoreMean1 = inner && inner.mean ? inner.mean : 0;
              let scoreMean = Number(scoreMean1);

              if (inner._id.relation === "Self") {
                temp["Self"] = scoreMean;
              } else {
                temp["Others"] += scoreMean;
                temp["OthersCount"] += 1
              }



            }
          })
          temp["Others"] /= temp["OthersCount"];
        }


        DimensionList.push(GetParameterLabel(dimension))



        ValueList.push(temp)
      })
    }

    //console.log('-------->>>>ValueList; ', ValueList)

    //console.log('-------->>>>DimensionList; ', DimensionList)


    function getScoreValueX(type) {
      let List = []
      if (ValueList && ValueList.length > 0) {
        ValueList.forEach((item) => {
          let scoreX = item && item[type] ? item[type].toFixed(2) : 0
          List.push(scoreX)
        })
      }
      return List
    }

    let typeList = ["Self", "Others"]

    if (typeList && typeList.length > 0) {
      typeList.forEach((item2) => {
        RelationList.push({
          name: item2,
          type: 'bar',
          barWidth: '25px',
          data: getScoreValueX(item2),
          label: {
            show: true,
            position: 'right'
        },
        })
      })
    }



    //console.log('-------->>>>RelationList; ', RelationList)


    return (
      <>
        <div className="bg-white border  p-4 rounded-md   cursor-pointer">
          <div className='space-y-6' >
            <h1 className="font-medium text-lg ">Dimension Summary</h1>

            {get360DimensionRelationScoreData && get360DimensionRelationScoreData.length > 0 ?
              <div className="overflow-x-auto whitespace-nowrap w-full overflow-hidden  mb-4 flex items-center justify-center">


                <ReactEcharts className='w-full  mx-auto'
                  style={{ height: '500px' }}
                  option={{
                    // title: {
                    //   text: 'World Population'
                    // },
                    color: ["#F6736A", "#f9d350"],
                    tooltip: {
                      trigger: 'axis',
                      axisPointer: {
                        type: 'shadow'
                      }
                    },
                    legend: {},
                    grid: {
                      left: '3%',
                      right: '4%',
                      bottom: '3%',
                      containLabel: true
                    },
                    xAxis: {
                      type: 'value',
                      boundaryGap: [0, 0.01]
                    },
                    yAxis: {
                      type: 'category',
                      data: DimensionList
                    },
                    series: RelationList
                  }}
                />



              </div>
              :
              <>
                <div className="text-center text-3xl text-gray-400 pt-40">Data Not Available!</div>
              </>}

          </div>
        </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DriverRadar);
