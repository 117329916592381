import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";

class WordDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEmpPage: 1,
      EmpIndexA: 0,
      EmpIndexB: 4,
      pageCount: 10
    }
  }



  componentDidMount() {
  }

  SelectEmpPage = (val) => {
    this.setState({ currentEmpPage: val })
  }


  prevEmpPage = () => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (EmpIndexA > 0) {
      this.setState({ EmpIndexA: EmpIndexA - 1, EmpIndexB: EmpIndexB - 1 });
    }
  }
  nextEmpPage = (IndexListIndex) => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (IndexListIndex && IndexListIndex.length && (EmpIndexB < IndexListIndex.length)) {
      this.setState({ EmpIndexA: EmpIndexA + 1, EmpIndexB: EmpIndexB + 1 });
    }
  }

  render() {

    let { wordCloudTextData, wordTextKeyWord, loading } = this.props;
    let { currentEmpPage, EmpIndexA, EmpIndexB, pageCount } = this.state;


    let textData = []

    if (wordCloudTextData && wordCloudTextData.length > 0) {
      wordCloudTextData.forEach((item) => {
        if (item.answerText.includes("answer1:") || item.answerText.includes("answer2:")) {
          textData.push({
            ...item,
            ans1: item.answerText.substring(
              item.answerText.lastIndexOf("answer1:") + 8,
              item.answerText.lastIndexOf("answer2:") - 1
            ),
            ans2: item.answerText.substring(
              item.answerText.lastIndexOf("answer2:") + 8,

            )
          });
        }
        else {
          textData.push({
            ...item,
            ans3: item.answerText
          })
        }

      })
    }

    ////console.log('wordTextKeyWord',wordTextKeyWord)

    let KEYWORD = '';
    if (wordTextKeyWord) {
      KEYWORD = wordTextKeyWord.toLowerCase();
    }




    //--------------------------------------------------------------------------------

    let IndexList = []
    let IndexListIndex = []
    let indexA = 0
    let indexB = 0

    if (textData && textData.length > 0) {


      if (textData && textData.length > 0) {
        textData.forEach((item, index) => {
          if (index % pageCount === 0) {
            IndexList.push(index)
          }
          if (index === (textData.length - 1)) {
            IndexList.push(index)
          }
        })
      }

      ////console.log('IndexList: ',IndexList)

      if (IndexList && IndexList.length > 0) {
        IndexList.forEach((item, index) => {
          if (index !== (IndexList.length - 1)) {
            IndexListIndex.push(index + 1)
          }
        })
      }
      ////console.log('textData====================>: ',textData)





      if (IndexListIndex && IndexListIndex.length > 0) {
        IndexListIndex.forEach((item, index) => {
          if (item === currentEmpPage) {

            if (index < (IndexList.length - 2)) {
              indexA = IndexList[index];
              indexB = IndexList[index] + (pageCount - 1)
            }
            else {
              indexA = IndexList[index];
              indexB = IndexList[IndexList.length - 1]
            }
          }
        })
      }

    }
    //--------------------------------------------------------------------------------
    ////console.log('----------------textData:--------------- ',textData)

    let List = []
    let ExporttextData = [];
    if (textData && textData.length >= 5) {
      textData.forEach((item, index) => {
        if (index === 0) {
          List = Object.keys(item)
        }
        let temp = {}
        if (List && List.length > 0) {
          List.forEach((ele) => {

            // let getIndex = EmpFilterData2 && EmpFilterData2.length>0?EmpFilterData2.findIndex(prev=>prev.name === ele):-1;
            // let validDMG = (ele==="Age_Range" || ele==="Business_Unit" || ele==="Department" || ele==="Gender") || true;
            // if(ele!=="ans3" && ele!=="answerText" && ele!="ManagerPID" && getIndex!==-1 && validDMG){
            //   temp[ele] = item[ele];
            // }

            temp['Department'] = item && item['Department'] ? item['Department'] : "NaN"

          });
        }
        temp["answerText"] = item["answerText"]
        ExporttextData.push(temp)
      });
    }
    ////console.log('----------------ExporttextData:--------------- ',ExporttextData)




    return (
      <>
        {/* box right */}
        <div className="bg-white border ">
          <div className="flex items-center justify-between p-4 text-gray-500">
            <h1 className="text-lg font-medium text-gray-800">{KEYWORD}</h1>


            {ExporttextData && ExporttextData.length >= 5 ?
              <CSVLink data={ExporttextData} filename={"TextData.csv"}>
                <span className=" text-sm border px-4 py-1.5 rounded-full flex items-center capitalize hover:border-gray-500 hover:text-gray-900"><span className="mr-2 material-symbols-outlined" style={{ fontSize: '1rem' }}>download</span> Export excel</span>
              </CSVLink>
              : '...'}

          </div>
          {/*sub nav bar 1 */}
          <div className="items-center justify-between px-4 border-b md:flex">
            <div className="flex space-x-4 overflow-hidden overflow-x-auto text-xs font-medium text-gray-500 capitalize whitespace-nowrap tab-scroll">
              <span className="flex items-center pb-2 space-x-1 font-normal border-b-2 border-sky-500"><p>All comments</p>
                <b className="font-medium"> {textData && textData.length > 0 ? textData.length : 0} </b></span>
              {/* <span className="flex items-center pb-2 space-x-1 font-normal"><span className="p-1 bg-red-400 rounded-full" /><p>negative</p>
                <b className="font-medi "> 256 </b></span>
              <span className="flex items-center pb-2 space-x-1 font-normal"><span className="p-1 bg-yellow-400 rounded-full" /><p>natural</p>
                <b className="font-medi "> 256 </b></span>
              <span className="flex items-center pb-2 space-x-1 font-normal"><span className="p-1 bg-green-400 rounded-full" /><p>positive</p>
                <b className="font-medium"> 256 </b></span> */}
            </div>
          </div>
          {/* end */}
          {/* chart */}
          <div className="p-4 overflow-y-auto divide-y h-96 customscroll4">




            {textData && textData.length > 0 && textData.length >= 5 ?
              textData.map((item, index) =>
                (index >= indexA && index <= indexB) ?
                  <div className="flex items-start py-2 space-x-4 capitalize">
                    <span className="p-1 rounded-full bg-yellow-400  mt-1.5" />
                    <div className="space-y-1">


                      {item.ans1 ?
                        <h1 className="text-sm ">{'A) ' + item.ans1}</h1>
                        : null}
                      {item.ans2 ?
                        <h1 className="text-sm ">{'B) ' + item.ans2}</h1>
                        : null}
                      {item.ans3 ?
                        <h1 className="text-sm ">{item.ans3}</h1>
                        : null}

                      <div className="flex items-center space-x-2 text-xs text-gray-500">
                        {item && item.Department ? <><span className="p-0.5 bg-gray-500 rounded-full" /><p className="px-1 pl-4 text-xs text-gray-500">{item.Department + ', '}</p></> : null}
                        {item && item.Gender ? <p className="px-1 text-xs text-gray-500">{item.Gender + ', '}</p> : null}
                        {item && item.Country ? <><span className="p-0.5 bg-gray-500 rounded-full" /><p className="px-1 text-xs text-gray-500">{item.Country}</p></> : null}



                        {/* <p>india</p><span className="p-0.5 bg-gray-500 rounded-full" /> 
                    <p>delhi</p>
                  <span className="p-0.5 bg-gray-500 rounded-full" />
                  <p>Quality assurance</p> */}


                      </div>

                    </div>
                  </div>
                  : null
              )
              :
              <>
                {!loading ?
                  <>
                    <div className="pt-40 text-2xl text-center text-gray-400">Data not available!</div>
                  </>
                  : null}
              </>
            }

            {/* 
            <div className="flex items-center justify-center py-8 text-center">
            <span onClick={()=>this.prevEmpPage()} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-gray-400 border border-transparent rounded-full select-none material-symbols-outlined" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_left</span>
            {IndexListIndex && IndexListIndex.length>0?
                IndexListIndex.map((item,index)=>
                    (index >= EmpIndexA && index <= EmpIndexB)?
                    (item===currentEmpPage)?
                    <span onClick={()=>this.SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-blue-500 border border-transparent rounded-full">{item}</span>
                    :
                    <span onClick={()=>this.SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm bg-white border rounded-full">{item}</span>
                    :null
                )

            :null}
                <span onClick={()=>this.nextEmpPage(IndexListIndex)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-blue-500 bg-white border rounded-full select-none material-symbols-outlined" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_right</span>
            </div>
            <div className="py-4 mb-10 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div> */}




          </div>
          <div className="px-2 pb-2" style={{ color: "#3366ff", fontSize: '14px' }}>* We are not showing data where employee count is less than 5</div>

          <div className="flex items-center justify-center w-full h-16 bg-white border-t ">
            <div className="flex items-center">

              <span onClick={() => this.prevEmpPage()} className="p-2 text-gray-500 bg-gray-200 rounded-full cursor-pointer material-symbols-outlined hover:bg-blue-500 hover:text-white">chevron_left</span>
              {IndexListIndex && IndexListIndex.length > 0 ?
                IndexListIndex.map((item, index) =>
                  (index >= EmpIndexA && index <= EmpIndexB) ?
                    (item === currentEmpPage) ?
                      <span onClick={() => this.SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-blue-500 border border-transparent rounded-full cursor-pointer">{item}</span>
                      :
                      <span onClick={() => this.SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm bg-white border rounded-full cursor-pointer">{item}</span>
                    : null
                )

                : null}


              {/* <div className="flex items-center mx-4 text-sm font-medium text-gray-800"><p>{currentEmpPage}</p>  <p className="px-1">of</p> <p>{IndexListIndex && IndexListIndex.length>0?IndexListIndex.length:0}</p></div> */}
              <span onClick={() => this.nextEmpPage(IndexListIndex)} className="p-2 text-gray-500 bg-gray-200 rounded-full cursor-pointer material-symbols-outlined hover:bg-blue-500 hover:text-white">chevron_right</span>

            </div>
          </div>
          {/* end */}
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(WordDetails);
