import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileBox: false,


      showDropdown: false
    }
  }
  componentDidMount() {
  }

  openProfile = (check) => {
    this.setState({ showProfileBox: !check })

    setTimeout(() => {
      this.setState({ showProfileBox: false })
    }, 4000)
  }
  handleLogout = () => {
    this.props.dispatch(userActions.logoutSession());
  }

  render() {
    let { ClientProfileData } = this.props;
    let { showProfileBox } = this.state;



    function firstWord(ClientProfileData) {
      if (ClientProfileData && ClientProfileData.name) {
        var firstWords = [];
        var firstWords2 = [];
        var codelines = ClientProfileData.name;

        for (var i = 0; i < codelines.length; i++) {
          var words = codelines[i].split(" ");

          firstWords.push(words[0]);
        }
        if (firstWords && firstWords.length > 0) {
          firstWords.every((name, index) => {
            if (name === "") {
              return false;
            }
            else {
              firstWords2.push(name);
              return true;
            }
          }

          )
        }


        // var Username = firstWords2.join("");
        var Username = codelines;


        return Username

      }
      return 'User'
    }

    let ProfileName = firstWord(ClientProfileData);

    let ProfileGender = ''
    if (ClientProfileData && ClientProfileData.name) {
      let nameGender = "";
      if (ClientProfileData && ClientProfileData.Gender) {
        nameGender = ClientProfileData.Gender;
      }
      else if (ClientProfileData && ClientProfileData.gender) {
        nameGender = ClientProfileData.gender;
      }

      ProfileGender = nameGender;
    }


    return (
      <>
        <header className=" w-full flex justify-between items-center xl:px-6 lg:px-6 md:px-6 px-4 bg-white  border-b " style={{ height: '4rem' }}>
          <div className="lg:hidden block cursor-pointer "><span className="material-symbols-outlined text-gray-600 bg-gray-100 hover:bg-gray-100 p-2  rounded-md">menu_open</span></div>

          <div className="flex items-center space-x-4 "><a href="#/app/survey"><img src="/img/plus_ex_logo.svg" className="w-24 object-cover" /></a><span class="border py-1 px-2 text-gray-500 text-sm rounded-md">ABC Pvt Ltd</span></div>
          
          <div className="flex items-center justify-between py-3">
            {/* <div className="lg:flex hidden border-r pr-3 mx-2 items-center "><h1 onClick={()=>openLanguageModal(showLanguageModal)} className="text-sm cursor-pointer">{CurrentLanguageName}</h1><span onClick={()=>openLanguageModal(showLanguageModal)} className="cursor-pointer material-symbols-outlined">arrow_drop_down</span>

            {showLanguageModal?
            <div className="absolute top-10 right-52 bg-white shadow-xl w-auro  py-1.5  lang" style={{maxHeight:"150px", overflowY:'auto'}}>
            {getLanguageData && getLanguageData.length>0?
              getLanguageData.map((item)=>
                <span onClick={()=>handleLanguage(item)} className="cursor-pointer block text-xs w-full hover:bg-gray-100 py-1.5 px-4">{item.name}</span>
              )
            :null}
            </div>
            :null} 

        </div> */}





            <div className="relative flex items-center ">
              <button onClick={() => this.openProfile(showProfileBox)} className="relative block h-10 w-10 ml-2 rounded-full overflow-hidden  focus:outline-none">
                {/* <img className="h-full w-full object-cover" src="https://4.bp.blogspot.com/-szJiVBCNYq8/XHPIf2urmgI/AAAAAAAAA3Q/rgzjBI6jxFsORJjM0XxQ8kHV5r2wLzipgCEwYBhgL/s1600/IMG_20190225_160623.jpg" alt="Your avatar" /> */}

                {(ProfileGender === "Female") ?
                  <img className="h-full w-full object-cover" src="img/profile/user2.png" alt="Your avatar" />
                  :
                  <img className="h-full w-full object-cover" src="img/profile/user1.png" alt="Your avatar" />
                }



              </button>
              <div onClick={() => this.openProfile(showProfileBox)} className="ml-2 lg:flex items-center hidden cursor-pointer"><h1 className="text-sm text-gray-700 font-medium">{ProfileName}</h1><span className="material-symbols-outlined">arrow_drop_down</span></div>

              {showProfileBox ?
                <div className="bg-white rounded-sm shadow-xl p-4 absolute top-12 right-0 w-44" style={{ zIndex: 200 }}>
                  <p onClick={() => this.openProfile(showProfileBox)} className="text-gray-500  text-base truncate cursor-pointer">{ProfileName}</p>
                  <span onClick={() => this.handleLogout()} className="text-sm hover:text-red-600 text-red-500 py-2 hover:font-medium block cursor-pointer">Logout</span>
                </div>
                : null}


            </div>
          </div>
        </header>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { user, dashboard } = state;
  return {
    loggingIn,
    user
  };
}
export default connect(mapStateToProps)(Header);