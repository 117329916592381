import React from 'react';
// import { connect } from 'react-redux';



export default function Filter(props) {

  let { getAnsweredEmployeesData, handleSelectEmployee } = props;

  let [showFilter, handleOpenFilter] = React.useState(false);



  let { crrSelectedEmp } = props;





  return (
 

      <div className="cursor-pointer bg-white  border pl-2 pr-1 relative py-1.5   rounded-md select-none md:w-auto w-full   ">
        <p className='text-xs text-[#212121]/70 font-medium'>Employee</p>
        <div className='min-w-40 max-w-fit flex justify-between items-center  space-x-1.5'>
          <p onClick={() => handleOpenFilter(!showFilter)} className="text-sm font-medium  text-[#212121]">{crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Select"}</p>
          <span onClick={() => handleOpenFilter(!showFilter)} className="material-icons ">arrow_drop_down</span>
        </div>
        {showFilter && getAnsweredEmployeesData && getAnsweredEmployeesData.length > 0 ?
          <div className="z-10 absolute right-0 top-10 bg-white shadow-lg p-3 font-normal text-xs w-fit h-auto max-h-60 overflow-x-auto customscroll2 rounded-md">

            {getAnsweredEmployeesData && getAnsweredEmployeesData.length > 0 ?
              getAnsweredEmployeesData.map((emp, index) =>
                <div key={index} className="py-2 m-1 flex items-center cursor-pointer"
                  onClick={() => { handleSelectEmployee(emp); handleOpenFilter(false) }}
                >
                  <input type="checkbox" checked={crrSelectedEmp.emp_id === emp.emp_id} onChange={() => { }} />
                  <label className="ml-2 font-medium">{emp.name}</label>
                </div>
              ) : null}
          </div>
          : null}
      </div>

 
  );
}
