import React from "react";
import ScoreCircleChart from "./Charts/ScoreCircleChart";
import GaugeChartOverall from "./Charts/GaugeChartOverall";
import GaugeChartEngagement from "./Charts/GaugeChartEngagement";
import NPSChartScore from "./NPSChartScore";

export default function Item(props) {
	let {
		getIndexByFilterData,
		getIndexByFilterDMGData,
		IndexDimensionList,
		IndexDimensionEngagementList,

		IndexNumber,
		getQuestionData,

		getDemographicIndexData,
		getDemographicEngagementIndexData,

		CurrentManagerData,
		getIndexData,

		SentenceObj,
		GetParameterLabel,
		QuartileData,
		userName,
		getIndexDriverData,
		getEngagementIndexDriverData,

		manageName,
		createdAt,

		getIndexDMGData,
		getEngagementIndexDMGData,

		IndexName,
		teamSize,
		npsQuesList,
		questionData,
		QueTemplate,
		getAllBenchMarkStaticData,
	} = props;


	let IndexScore =
		getIndexByFilterData &&
			getIndexByFilterData[IndexNumber] &&
			getIndexByFilterData[IndexNumber].toFixed(2)
			? getIndexByFilterData[IndexNumber].toFixed(2)
			: "0";


	let IndexScoreNo2 =
		getIndexByFilterData &&
			getIndexByFilterData["2"] &&
			getIndexByFilterData["2"].toFixed(2)
			? getIndexByFilterData["2"].toFixed(2)
			: "0";




	let IndexDMGScore =
		getIndexByFilterDMGData &&
			getIndexByFilterDMGData[IndexNumber] &&
			getIndexByFilterDMGData[IndexNumber].toFixed(2)
			? getIndexByFilterDMGData[IndexNumber].toFixed(2)
			: "0";





	let ValueObj = {};
	let OverallObj = {};
	let DimenObj = {};

	if (IndexDimensionList && IndexDimensionList.length > 0) {
		IndexDimensionList.forEach((item) => {
			ValueObj[item + "Avg"] = 0;
			OverallObj[item + "Avg"] = 0;
			DimenObj[item + "Avg"] = 0;
		});
	}


	let managerId =
		CurrentManagerData && CurrentManagerData.id ? CurrentManagerData.id : "";
	let index = getDemographicIndexData
		? getDemographicIndexData.findIndex(
			(prev) => prev.name.toString() === managerId.toString()
		)
		: -1;

	let DataItem = {};

	if (index !== -1) {
		DataItem = getDemographicIndexData[index];
	}

	console.log("getIndexDMGData--xxxxx1--", getIndexDMGData);

	let dmgXLabel = ""



	if (IndexDimensionList && IndexDimensionList.length > 0) {
		IndexDimensionList.forEach((item) => {
			ValueObj[item + "Avg"] =
				DataItem && DataItem[item + "Avg"] && DataItem[item + "Avg"].toFixed(2)
					? DataItem[item + "Avg"].toFixed(2)
					: 0;

			OverallObj[item + "Avg"] = getIndexDriverData && getIndexDriverData[item + "Count"] ? getIndexDriverData[item + "Count"].toFixed(2) : 0;

			let dmgScoreDimen = {}
			if (getIndexDMGData && getIndexDMGData.length > 0) {
				dmgScoreDimen = getIndexDMGData[0]
				dmgXLabel = getIndexDMGData[0]["name"]
			}
			DimenObj[item + "Avg"] = dmgScoreDimen && dmgScoreDimen[item + "Avg"] ? dmgScoreDimen[item + "Avg"].toFixed(2) : 0;

		});
	}
	console.log("DimenObj--xxxxx1--", DimenObj);





	let ValueObjEng = {};
	let OverallObjEng = {};
	let DimenObjEng = {};

	if (IndexDimensionEngagementList && IndexDimensionEngagementList.length > 0) {
		IndexDimensionEngagementList.forEach((item) => {
			ValueObjEng[item + "Avg"] = 0;
			OverallObjEng[item + "Avg"] = 0;
			DimenObjEng[item + "Avg"] = 0;
		});
	}

	let managerIdX =
		CurrentManagerData && CurrentManagerData.id ? CurrentManagerData.id : "";
	let indexX = getDemographicEngagementIndexData
		? getDemographicEngagementIndexData.findIndex(
			(prev) => prev.name.toString() === managerIdX.toString()
		)
		: -1;

	let DataItemX = {};

	if (indexX !== -1) {
		DataItemX = getDemographicEngagementIndexData[indexX];
	}

	console.log("getEngagementIndexDMGData--xxxxx1--", getEngagementIndexDMGData);

	let dmgXLabelX = ""

	if (IndexDimensionEngagementList && IndexDimensionEngagementList.length > 0) {
		IndexDimensionEngagementList.forEach((item) => {
			ValueObjEng[item + "Avg"] =
				DataItemX && DataItemX[item + "Avg"] && DataItemX[item + "Avg"].toFixed(2)
					? DataItemX[item + "Avg"].toFixed(2)
					: 0;

			OverallObjEng[item + "Avg"] = getEngagementIndexDriverData && getEngagementIndexDriverData[item + "Count"] ? getEngagementIndexDriverData[item + "Count"].toFixed(2) : 0;

			let dmgScoreDimenX = {}
			if (getEngagementIndexDMGData && getEngagementIndexDMGData.length > 0) {
				dmgScoreDimenX = getEngagementIndexDMGData[0]
				dmgXLabelX = getEngagementIndexDMGData[0]["name"]
			}
			DimenObjEng[item + "Avg"] = dmgScoreDimenX && dmgScoreDimenX[item + "Avg"] ? dmgScoreDimenX[item + "Avg"].toFixed(2) : 0;
		});
	}
	console.log("DimenObjEng--xxxxx1--", DimenObjEng);


	//////console.log("DataItem----",DataItem);
	//////console.log("getDemographicIndexData----",getDemographicIndexData);
	//////console.log("CurrentManagerData----",CurrentManagerData);


	let OverallScore =
		getIndexData &&
			getIndexData[IndexNumber] &&
			getIndexData[IndexNumber].toFixed(2)
			? getIndexData[IndexNumber].toFixed(2)
			: 10;


	let OverallScoreNo2 =
		getIndexData &&
			getIndexData["2"] &&
			getIndexData["2"].toFixed(2)
			? getIndexData["2"].toFixed(2)
			: 10;



	function getQuestionByParameter(para) {
		let questionList =
			getQuestionData && getQuestionData.length > 0
				? getQuestionData.filter((x) => x.parameterDimensionName === para)
				: [];
		return questionList;
	}

	let valueMin =
		QuartileData && QuartileData["min"] && QuartileData["min"].toFixed(2)
			? QuartileData["min"].toFixed(2)
			: 0;
	let value25 =
		QuartileData && QuartileData["Q1_25"] && QuartileData["Q1_25"].toFixed(2)
			? QuartileData["Q1_25"].toFixed(2)
			: 0;
	let value50 =
		QuartileData && QuartileData["Q2_50"] && QuartileData["Q2_50"].toFixed(2)
			? QuartileData["Q2_50"].toFixed(2)
			: 0;
	let value75 =
		QuartileData && QuartileData["Q3_75"] && QuartileData["Q3_75"].toFixed(2)
			? QuartileData["Q3_75"].toFixed(2)
			: 0;
	let value100 =
		QuartileData && QuartileData["Q4_100"] && QuartileData["Q4_100"].toFixed(2)
			? QuartileData["Q4_100"].toFixed(2)
			: 0;

	let Bottom25 = "";
	if (valueMin === value25) {
		Bottom25 = value25.toString();
	} else {
		Bottom25 = valueMin.toString() + " - " + value25.toString();
	}

	let Top100 = "";
	if (value75 === value100) {
		Top100 = value100.toString();
	} else {
		Top100 = value75.toString() + " - " + value100.toString();
	}


	let valueXObj = {
		"Min": valueMin,
		"Q1": value25,
		"Q2": value50,
		"Q3": value75,
		"Q4": value100
	}


	let RANGElIST = [
		parseInt(valueXObj["Min"]),
		Math.round(parseFloat(valueXObj["Q1"])),
		Math.round(parseFloat(valueXObj["Q2"])),
		Math.round(parseFloat(valueXObj["Q3"])),
		parseInt(valueXObj["Q4"] + 1)
	]

	let labelNames = [
		"Actively Disengaged",
		"Disengaged",
		"Moderately Engaged",
		"Actively Engaged"
	]

	function getLabel(overallScore) {
		let score = parseFloat(overallScore);
		let label = "-";
		let colorCode = "-";
		let colorBg = "-";
		let colorText = "-";

		if (0 <= score && score < 58) {
			label = labelNames[0];
			colorBg = "#d12e2e";
			colorText = "#fff";
			colorCode = (<div
				className="p-1.5 "
				style={{ background: "#d12e2e" }}
			/>)
		} else if (58 <= score && score < 75) {
			label = labelNames[1];
			colorBg = "#f4ae34";
			colorText = "#fff";
			colorCode = (<div
				className="p-1.5 "
				style={{ background: "#f4ae34" }}
			/>)
		} else if (75 <= score && score < 91) {
			label = labelNames[2];
			colorBg = "#a2e37d";
			colorText = "#000";
			colorCode = (<div
				className="p-1.5 "
				style={{ background: "#a2e37d" }}
			/>)
		} else if (91 <= score && score <= 100) {
			label = labelNames[3];
			colorBg = "#00cc66";
			colorText = "#000";
			colorCode = (<div
				className="p-1.5 "
				style={{ background: "#00cc66" }}
			/>)
		}
		return { label: label, colorCode: colorCode, colorBg: colorBg, colorText: colorText }
	}

	const labelRangeCompo = () => {
		return (<div className="flex flex-wrap justify-center gap-3 text-xs text-[#212121]/70 font-medium">
			<div className="flex items-center ">
				<div
					className="p-1.5 "
					style={{ background: "#d12e2e" }}
				/>
				<p className="ml-2">{"Actively Disengaged"}</p>
			</div>
			<div className="flex items-center ">
				<div
					className="p-1.5 "
					style={{ background: "#f4ae34" }}
				/>
				<p className="ml-2">{"Disengaged"}</p>

			</div>
			<div className="flex items-center ">
				<div
					className="p-1.5 "
					style={{ background: "#a2e37d" }}
				/>

				<p className="ml-2">{"Moderately Engaged"}</p>

			</div>
			<div className="flex items-center ">
				<div
					className="p-1.5 "
					style={{ background: "#00cc66" }}
				/>

				<p className="ml-2">{"Actively Engaged"}</p>

			</div>
		</div>)
	}

	const colorRangeCompo = () => {
		return (
			<div className="flex flex-wrap justify-end gap-0 text-sm text-[#212121] font-medium w-full">
				<div style={{ background: getColorLabel(IndexScore, IndexName, npsScore)["label"] === "Red"? hexToRgbA(getColorLabel(IndexScore, IndexName, npsScore)["colorBg"], "0.20"):null }}
					className="flex items-center w-full flex-row gap-4  p-2 px-4">
					<div
						className="p-1.5 "
						style={{ background: "#ef5350" }}
					/>
					<div>
						{/* <p className="  font-bold">{"Red (0 - 70)"}</p> */}
						<p>Require Significant Improvement (0 - 70)</p>
					</div>
				</div>
				<div style={{ background: getColorLabel(IndexScore, IndexName, npsScore)["label"] === "Amber"? hexToRgbA(getColorLabel(IndexScore, IndexName, npsScore)["colorBg"], "0.20"):null }}
				className="flex items-center w-full flex-row gap-4 p-2 px-4">
					<div
						className="p-1.5 "
						style={{ background: "#ffb300" }}
					/>
					<div>
						{/* <p className="  font-bold">{"Amber (71 - 78)"}</p> */}
						<p>Adequate but room for improvement (71 - 78)</p>
					</div>

				</div>
				<div style={{ background: getColorLabel(IndexScore, IndexName, npsScore)["label"] === "Yellow"? hexToRgbA(getColorLabel(IndexScore, IndexName, npsScore)["colorBg"], "0.20"):null }}
				className="flex items-center w-full flex-row gap-4  p-2 px-4">
					<div
						className="p-1.5 "
						style={{ background: "#fdd835" }}
					/>

					<div>
						{/* <p className="font-bold">{"Yellow (79 - 87)"}</p> */}
						<p>Good & has Opportunity to grow (79 - 87)</p>
					</div>

				</div>
				<div style={{ background: getColorLabel(IndexScore, IndexName, npsScore)["label"] === "Green"? hexToRgbA(getColorLabel(IndexScore, IndexName, npsScore)["colorBg"], "0.20"):null }}
				className="flex items-center w-full flex-row gap-4 p-2 px-4">
					<div
						className="p-1.5 "
						style={{ background: "#66bb6a" }}
					/>

					<div>
						{/* <p className="  font-bold">{"Green (88 - 100)"}</p> */}
						<p>Excellent, needs to sustain & leverage (88 - 100)</p>
					</div>

				</div>
			</div>
		)
	}


	let colorNames = [
		"Red",
		"Amber",
		"Yellow",
		"Green"
	]

	function getColorLabel(overallScore, indexName, eNPS) {
		let score = parseFloat(overallScore);
		let label = "-";
		let colorCode = "-";
		let colorBg = "-";
		let colorText = "-";
		let colorCodeNew = "-";
		let text = "-";

		if (0 <= score && score < 71) {
			label = colorNames[0];
			colorBg = "#ef5350";
			colorCodeNew = "bg-red-500";
			colorText = "#fff";
			colorCode = (<div
				className="p-1.5 "
				style={{ background: "#ef5350" }}
			/>)
			text = "Require Significant Improvement"

		} else if (71 <= score && score < 79) {

			if (indexName === "Manager" && eNPS !== "NaN" && Number(eNPS) <= 0) {
				label = colorNames[0];
				colorBg = "#ef5350";
				colorCodeNew = "bg-amber-500";
				colorText = "#fff";
				colorCode = (<div
					className="p-1.5 "
					style={{ background: "#ef5350" }}
				/>)
				text = "Require Significant Improvement"
			} else {
				label = colorNames[1];
				colorBg = "#ffb300";
				colorCodeNew = "bg-amber-500";
				colorText = "#fff";
				colorCode = (<div
					className="p-1.5 "
					style={{ background: "#ffb300" }}
				/>)
				text = "Adequate but room for improvement"
			}

		} else if (79 <= score && score < 88) {

			if (indexName === "Manager" && eNPS !== "NaN" && Number(eNPS) <= 0) {
				label = colorNames[1];
				colorBg = "#ffb300";
				colorCodeNew = "bg-yellow-500";
				colorText = "#fff";
				colorCode = (<div
					className="p-1.5 "
					style={{ background: "#ffb300" }}
				/>)
				text = "Adequate but room for improvement"
			} else {
				label = colorNames[2];
				colorBg = "#fdd835";
				colorCodeNew = "bg-yellow-500";
				colorText = "#000";
				colorCode = (<div
					className="p-1.5 "
					style={{ background: "#fdd835" }}
				/>)
				text = "Good & has Opportunity to grow"
			}

		} else if (88 <= score && score <= 100) {

			if (indexName === "Manager" && eNPS !== "NaN" && Number(eNPS) <= 0) {
				label = colorNames[2];
				colorBg = "#fdd835";
				colorCodeNew = "bg-green-500";
				colorText = "#000";
				colorCode = (<div
					className="p-1.5 "
					style={{ background: "#fdd835" }}
				/>)
				text = "Good & has Opportunity to grow"
			} else {
				label = colorNames[3];
				colorBg = "#66bb6a";
				colorCodeNew = "bg-green-500";
				colorText = "#000";
				colorCode = (<div
					className="p-1.5 "
					style={{ background: "#66bb6a" }}
				/>)
				text = "Excellent, needs to sustain & leverage"
			}
		}
		return { label: label, colorCode: colorCode, colorBg: colorBg, colorText: colorText, colorCodeNew: colorCodeNew, text:text }
	}



	function getDimensionDesc(value, sentence) {
		if (value === "Pride") {
			return "Embrace pride, promote positivity, and advocate for the organization. Employees who cultivate a deep sense of pride in their work exhibit heightened energy, focus, and motivation."
		} else if (value === "Presence") {
			return "The intent of an employee to have an intense desire to be a member of the organization mentally and physically, fueled by a consistent positive drive to align with the organization's mission."
		} else if (value === "Performance") {
			return "Achieving high performance and consistently striving to exceed goals to drive organizational success, while maintaining alignment with the business's core purpose."
		} else {
			return sentence
		}
	}


	// console.log("questionData--------------------------->", questionData)



	let npsScore = 0;

	if (IndexName === "Manager") {

		function FormattFun(quesXitem, isSummary) {
			let LIST = [];
			let prevTemList = [];
			let ques = quesXitem && quesXitem[0] ? quesXitem[0] : {};
			console.log("ques--X------------------------->", '1', ques)
			if (ques && ques.total && ques.total.length > 0) {
				console.log("ques--total------------------------->", '1', ques.total)

				let templateId = ques.total[0]._id.qtemplate;
				if (true) {
					console.log("working--------------------------->", '1')

					let temp = {
						name:
							ques && ques.question[0] && ques.question[0].name
								? ques.question[0].name
								: "",
						answerList: [],
						templateId: templateId,
					};
					if (ques && ques.total && ques.total.length > 0) {
						ques.total.forEach((item) => {
							let ans = {
								count: item.count,
								answerText: parseInt(item._id.answer),
								heading:
									item && item._id && item._id.heading
										? item._id.heading
										: "",
								parentId:
									item && item._id && item._id.parentId
										? item._id.parentId
										: "",
								template: item._id.qtemplate,
								QuesId: item._id.questionId,
							};
							temp["answerList"].push(ans);
						});
					}
					console.log("working--------------------------->", '2', temp)

					if (isSummary) {
						let getIndex = prevTemList.findIndex(
							(prev) => prev === templateId
						);
						if (getIndex === -1) {
							LIST.push(temp);
							prevTemList.push(templateId);
						}
					} else {
						LIST.push(temp);
					}
				}
			}

			return LIST;
		}


		console.log("working--------------------------->", '0')

		let OthersQuesList = FormattFun(questionData, true);

		console.log("OthersQuesList--------------------------->", OthersQuesList)

		let item =
			OthersQuesList && OthersQuesList.length > 0 ? OthersQuesList[0] : {};

		console.log("item--------------------------->", item)


		let AnswerObj = {};
		if (item && item.answerList && item.answerList.length > 0) {
			//////console.log('item.answerList (STAR)',item.answerList)

			item.answerList.forEach((ans) => {
				if (ans && ans.answerText) {
					if (AnswerObj && AnswerObj[ans.answerText]) {
						AnswerObj[ans.answerText] += ans.count;
					} else {
						AnswerObj[ans.answerText] = ans.count;
					}
				}
			});
		}


		let name = "NPS";

		let DataList = [];

		let totalCount = 0;

		let Promoters = 0;
		let Passsives = 0;
		let Distractors = 0;



		console.log('AnswerObj (<---->)', AnswerObj)



		if (AnswerObj) {
			Object.keys(AnswerObj).forEach(key => {
				let score = parseInt(AnswerObj[key]);

				totalCount += score;

				if (parseInt(key) >= 9 && parseInt(key) <= 10) {
					Promoters += score;
				}
				if (parseInt(key) >= 7 && parseInt(key) <= 8) {
					Passsives += score;
				}
				if (parseInt(key) >= 1 && parseInt(key) <= 6) {
					Distractors += score;
				}

			});
		}

		let PromotersPer = (Promoters * 100 / totalCount);
		let PasssivesPer = (Passsives * 100 / totalCount);
		let DistractorsPer = (Distractors * 100 / totalCount);

		npsScore = Math.round((PromotersPer) - (DistractorsPer));
		// console.log('npsScorenpsScorenpsScorenpsScore', npsScore);

		let NPSObj = {
			"PromotersPer": Math.round(PromotersPer),
			"PasssivesPer": Math.round(PasssivesPer),
			"DistractorsPer": Math.round(DistractorsPer)
		}


		let PromotersColorRange = PromotersPer / 100;
		let PasssivesColorRange = (PasssivesPer / 100) + PromotersColorRange;
		let DistractorsColorRange = (DistractorsPer / 100) + PasssivesColorRange;

	}

	// console.log("getAllBenchMarkStaticData------------x--------------->", getAllBenchMarkStaticData)




	function getSelectiveScores(list, item, type) {
		let scoreObj = {
			"HWScore": 0,
			"OverallScore": 0
		};
		if (list && list.length > 0) {

			if (type === "dimension") {
				let newList = list.filter(prev => prev.type === "dimension");
				let getIndex = newList.findIndex(prev => prev.name === item);
				if (getIndex !== -1) {
					scoreObj["HWScore"] = newList[getIndex]["HWScore"];
					scoreObj["OverallScore"] = newList[getIndex]["OverallScore"];
				}
			} else if (type === "index") {
				let newList = list.filter(prev => prev.type === "index");
				console.log("newList------x------>", newList)
				console.log("item------x------>", item)

				let getIndex = newList.findIndex(prev => prev.name === item);
				if (getIndex !== -1) {
					scoreObj["HWScore"] = Number(newList[getIndex]["HWScore"]);
					scoreObj["OverallScore"] = Number(newList[getIndex]["OverallScore"]);
				}
			}

		}
		return {
			"HWScore": scoreObj["HWScore"] > 0 ? scoreObj["HWScore"] : "-",
			"OverallScore": scoreObj["OverallScore"] > 0 ? scoreObj["OverallScore"] : "-"
		}
	}

	// console.log("npsQuesList------x-111111111----->", npsQuesList);
	// const colorLabel = getColorLabel(IndexScore, IndexName, npsScore);

	function hexToRgbA(hex, opacity) {
		var c;
		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			c = hex.substring(1).split("");
			if (c.length == 3) {
				c = [c[0], c[0], c[1], c[1], c[2], c[2]];
			}
			c = "0x" + c.join("");
			return (
				"rgba(" +
				[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
				"," +
				opacity +
				")"
			);
		}
		throw hex;
	}


	return (
		<section className=" space-y-2   px-10">
			<main className=" w-full pb-4 space-y-6 text-left  ">
				<div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/70 italic">
					<h1 className="text-base font-bold">Manager Score Card</h1>
					<p className="text-base font-semibold">{manageName + " | " + createdAt}</p>
				</div>
				<h1 className="px-6 text-4xl text-left text-[#fff] uppercase bg-[#2364AD] p-4  ">
					{IndexName === "Engagement" ? "Manager Engagement Index" : "Manager Scorecard"}
				</h1>
				<div className="grid grid-cols-2    ">
					{questionData && questionData.length > 0 ?
						questionData.map((ele, index) =>
							<NPSChartScore
								questionData={ele}
								questionIndex={index + 1}
								manageName={manageName}
								createdAt={createdAt}
							/>
						) : null}

					<div className="border-t border-[#212121]   p-4 px-0  col-span-2 flex pt-10 ">

						<div className="flex items-start justify-center  p-6 w-[60%] mr-4"
							// style={{ background: hexToRgbA(getColorLabel(IndexScore, IndexName, npsScore)["colorBg"],"0.20") }}
						>
							<div className="space-y-4 ">

								<h1 className="text-lg font-bold text-[#212121]  text-center">
									{/* Based on your Team <br /> Manager Index and your <br />Team NPS Score : */}
									Based on your <br /> Manager Index and your eNPS Score, <br /> your overall score falls in the below category - 
								</h1>
								{/* {getColorLabel(IndexScore, IndexName, npsScore)["colorCode"]} */}

								<p className="text-xl font-bold text-center px-2 py-2 "
									style={{ background: hexToRgbA(getColorLabel(IndexScore, IndexName, npsScore)["colorBg"],"0.20"),
									color: getColorLabel(IndexScore, IndexName, npsScore)["colorBg"] }} >{getColorLabel(IndexScore, IndexName, npsScore,)["text"]}</p>

								{/* <p className="text-xl font-bold text-center p-6 py-4" style={{ background: getColorLabel(IndexScore, IndexName, npsScore)["colorCode"] }}>Your Zone is {getColorLabel(IndexScore, IndexName, npsScore,)["label"]}</p> */}

								{/* <p className="text-center ">(Good & Opportunity to grow)</p> */}
							</div>
							{/* {IndexScore ?
								<GaugeChartOverall
									score={IndexScore}
									overallScore={100}
									valueObj={valueXObj}
									userName={userName}
								/> : null
							} */}
						</div>



						{colorRangeCompo(RANGElIST, labelNames)}

					</div>



				</div>
			</main>
		</section>


	);
}
