import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurveyName:'',
      currentSurveyId:'',
      
      showDropdown:false,

      // showSidebar:false
    }
  }
  componentDidMount() {
  }

  onDropdown=()=>{
    if(this.state.showDropdown){
      this.setState({showDropdown:false})
    }
    else{
      this.setState({showDropdown:true})
    }
  }

  logout=()=>{
    this.props.dispatch(userActions.logout());
  }

  IndexHoverOptions=(surveyId,indexType)=>{ 
    let { onOptionType } = this.props;

    return(    
      <span>
        <i className="fa fa-scribd" onClick={()=>onOptionType(surveyId,indexType,1)} />
        <i className="fa fa-quora" onClick={()=>onOptionType(surveyId,indexType,2)}/>
        <i className="fa fa-align-left" onClick={()=>onOptionType(surveyId,indexType,3)}/> 
        {/* <i className="fa fa-facebook" onClick={()=>onOptionType(surveyId,indexType,4)}/>  */}
      </span>
    )

  }




  handleIndexType=(value)=>{
    let { handleShowScreen } = this.props;
    handleShowScreen(value);

    // this.setState({showSidebar:false});

    let {handleSidebar}=this.props;
    handleSidebar(false);

    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },5000)
  }



  sidebarToggle=()=>{
    // this.setState({showSidebar:!this.state.showSidebar});

    let {handleSidebar,showSidebar}=this.props;
    handleSidebar(!showSidebar);

    
    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },6000)



    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },7000)
  }


  render() {
    let { SurveyList, getIndexData,
      // onHappinessIndex, onEngagementIndex, onHopeIndex, onStressIndex, onOptionType,
      CurrentSurveyId, currSidebarValue} = this.props;
    let { 
      // currentSurveyName, showDropdown,
        showSidebar } = this.props;

    // let surveyId = '';
    // if(SurveyList && SurveyList.length>0){

    //   if(CurrentSurveyId){
    //     surveyId = CurrentSurveyId;
    //   }
    //   else{
    //     surveyId = SurveyList[0].id;
    //   }
      
    // }

    //console.log('=============================================?=================CurrentSurveyId',CurrentSurveyId)
    //console.log('=============================================?=================surveyId',surveyId)





    let indexLabelsList = []
    let IndexList  = []

    function getIndexName(key){
      let name = ''
      if     (key===1){ name='Happiness' }
      else if(key===2){ name='Engagement' }
      else if(key===3){ name='Hope' }
      else if(key===4){ name='Stress' }
      else if(key===5){ name='Manager' }
      else if(key===6){ name='Leadership' }
      else if(key===7){ name='Safety' }
      else if(key===9){ name='Diversity' }
      else { name='NaN' }
      return name;
    }

    if(getIndexData){

      IndexList = Object.keys(getIndexData).map((key) => [ Number(key), getIndexData[key] ]);

      IndexList.forEach((data)=>{
        if(getIndexName(data[0]) !=='NaN' && data && data[0] && data[1]){

          indexLabelsList.push({
            template:data[0],
            score:data[1].toFixed(2),
            name:getIndexName(data[0])
          })

        }


      })
    }



    // //console.log('indexType:-------------------> ',indexType)




    // function getIconsByTemplateNo(template){
    //   return (template===1)?"mood":
    //   (template===2)?"emoji_people":
    //   (template===3)?"loyalty":
    //   (template===4)?"sentiment_neutral":
    //   (template===5)?"manage_accounts":
    //   (template===6)?"leaderboard":
    //   (template===7)?"security":
    //   (template===9)?"dashboard":

    //   (template===8)?"business":
    //   (template===102)?"assessment":
    //   (template===103)?"engineering":
    //   (template===104)?"manage_search":
    //   (template===105)?"fitness_center":
    //   (template===106)?"add_task":
    //   (template===107)?"summarize":


    //   "dashboard"
    // }

    // let otherLabelList = [
    //   {
    //     "name":"Organization Core",
    //     "template":8
    //   },
    //   {
    //     "name":"Manager Score Card",
    //     "template":102
    //   },
    //   {
    //     "name":"Advance Insights",
    //     "template":103
    //   },
    //   {
    //     "name":"Text Analysis",
    //     "template":104
    //   },
    //   {
    //     "name":"Strength Weakness Analysis",
    //     "template":105
    //   },
    //   {
    //     "name":"Manager Action Plan",
    //     "template":106
    //   },
    //   {
    //     "name":"Goal Plan",
    //     "template":108
    //   },
    //   {
    //     "name":"Report",
    //     "template":107
    //   },

    // ]

    ////console.log("showSidebar",showSidebar)



    let sidebarList = [
      {
        "name":"Organization Action Plan",
        "value":"actionplan",
        "icon":"add_task"
      },
      {
        "name":"Sense Action Plan",
        "value":"riskactionplan",
        "icon":"psychology"
      },
      {
        "name":"Goal Plan",
        "value":"goalplan",
        "icon":"track_changes"
      },
      {
        "name":"Manager Report",
        "value":"report",
        "icon":"summarize"
      },
      {
        "name":"Grow",
        "value":"grow",
        "icon":"add_task"
      }
    ]

    return (
      <>
        <div className={showSidebar?
        
        "cursor-pointer w-64 lg:block hidden lg:relative fixed top-0 left-0  h-screen lg:z-0 z-20 overflow-y-auto overflow-hidden transition duration-300 transform bg-white border-r "
        :
        "cursor-pointer w-16 lg:block hidden lg:relative fixed top-0 left-0  h-screen lg:z-0 z-20 overflow-y-auto overflow-hidden transition duration-300 transform bg-white border-r "

        } style={{height: '100%'}} id="customscroll2">
         
          <div className="flex items-center ">
            <div className="flex items-center px-4 py-3 w-16  text-blue-500  justify-center">
              <span  onClick={()=>this.sidebarToggle()} className="material-symbols-outlined text-gray-500 rounded hover:bg-gray-100 p-1">{showSidebar?"chevron_left":"chevron_right"}</span>
            </div>
            <span className="font-normal ml-4 text-sm text-gray-500">Insight</span>
          </div>
          
          
          
          <div className="mb-4 font-medium  h-screen">


{/* 
            <div onClick={()=>this.handleIndexType(surveyId,0, (indexType===0))} className="flex items-center hover:bg-blue-50  mr-6 rounded-r-full cursor-pointer">
            <div className={indexType===0?"flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center":"flex items-center px-4 py-4 w-16   h-12 justify-center"}>
              <span className="material-symbols-outlined  p-2">dashboard</span>
              </div>
              <span className={indexType===0?" text-xs text-blue-500":" text-xs "}>Overview</span>
            </div>




            {indexLabelsList && indexLabelsList.length>0?
              indexLabelsList.map((item,index)=>  
              
              <div key={index} onClick={()=>this.handleIndexType(surveyId,item.template, (indexType===item.template))}  className="flex items-center hover:bg-blue-50 mr-6 rounded-r-full text-gray-500 cursor-pointer">
                <div className={indexType===item.template?"flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center":"flex items-center px-4 py-4 w-16   h-12 justify-center"}>
                  <span className="material-symbols-outlined  p-2">{getIconsByTemplateNo(item.template)}</span>
                </div>
                <span className={indexType===item.template?" text-xs text-blue-500":" text-xs "}>{item.name+" Insight"}</span>
              </div>


            ):null}  


            {otherLabelList && otherLabelList.length>0?
              otherLabelList.map((item,index)=>  

              <div key={index} onClick={()=>this.handleIndexType(surveyId,item.template, (indexType===item.template))}  className="flex items-center hover:bg-blue-50 mr-6 rounded-r-full text-gray-500 cursor-pointer">
                <div className={indexType===item.template?"flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center":"flex items-center px-4 py-4 w-16   h-12 justify-center"}>
                  <span className="material-symbols-outlined  p-2">{getIconsByTemplateNo(item.template)}</span>
                </div>
                <span className={indexType===item.template?" text-xs text-blue-500":" text-xs "}>{item.name}</span>
              </div>

            ):null}   */}



          {sidebarList && sidebarList.length>0?
              sidebarList.map((item,index)=>  

              <div key={index} onClick={()=>this.handleIndexType(item.value)}  
              className={currSidebarValue===item.value?"flex items-center bg-blue-50   text-gray-500 cursor-pointer ":"flex items-center hover:bg-blue-50   text-gray-500 cursor-pointer "}>
                <div className={currSidebarValue===item.value?"flex items-center px-4 py-4 w-16 bg-blue-50   text-blue-500 h-12 justify-center":"flex items-center px-4 py-4 w-16   h-12 justify-center"}>
                  <span className="material-symbols-outlined p-2">{item.icon}</span>
                </div>
                <span className={currSidebarValue===item.value?" text-xs  text-blue-500":" text-xs "}>{item.name}</span>
              </div>

            ):null}  





          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(Sidebar);
