
import { authHeader } from '../_helpers';
import { CONST } from '../_config';
import CryptoJS from 'crypto-js';

export const userService = {
    userlogin,
    validateOtp,
    // logout,
    logoutSession,

//Super Admin Login
    login,

    uploadImage,
    uploadPDF,
    uploadImageLogo,
    uploadImageLogo2,
    pmsValidateOtp,
    pmsSignIn
    
};
function pmsSignIn(data) {

    let header = new Headers({
            'Content-Type': 'application/json'
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/pms/pmsSignIn`, requestOptions)
            .then(handleResponsePMS)
            .then(user => {
                let userObj = {
                    userinfoToken: user.data
                }
                
                return userObj;
            });
}
  
function userlogin(data) {

    let header = new Headers({
            'Content-Type': 'application/json'
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/userlogin`, requestOptions)
            .then(handleResponse)
            .then(user => {
                let userObj = {
                    userinfoToken: user.data
                }
                
                return userObj;
            });
}
function pmsValidateOtp(data) {

    let header = new Headers({
            'Content-Type': 'application/json'
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/pms/pmsValidateOtp`, requestOptions)
            .then(handleResponsePMS)
            .then(user => {
                let userObj = {
                    userinfo: user.data
                }

                
                if (user.data) {

                    let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(user.data), "secretkey123").toString();
                    localStorage.setItem('adminuser', JSON.stringify(cryptedText));
                }
                
                return userObj;
            });
}

function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
}

function handleResponsePMS(response) {

    return response.text().then(text => {
        let data = "";
         try{
            data = text && JSON.parse(text);
            ////////console.log("text ", JSON.parse(text));
         }
         catch(err){
            ////////console.log("response.status ", response.status);
            if (response.status === 429) {
                data = "Too many request, please try again after 10 min"
            }
            else{
                data = err
            }
            ////////console.log("data.status ", data);

         }


         if (!response.ok) {
            ////////console.log("datadatadata22 ", response);

             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                //  logoutSession();
                 //location.reload(true);

                 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
             }
             else if(response.status === 429){

                return Promise.reject({message:response.statusText});
             }
             // //////console.log("datadatadata ", response);


         }
         ////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             if (data.code===3) {
                //  logoutSession();
             }
             if(data && data.message && (data.message === "UnauthorizedError")){
                // logout()

             }
//              else if (data.code===1) {
//                 logoutSession();
//             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });

}

function handleResponse(response) {
    // ////////console.log("response22222   ", response);
 
     return response.text().then(text => {
        let data = "";
         try{
            data = text && JSON.parse(text);
            ////////console.log("text ", JSON.parse(text));
         }
         catch(err){
            ////////console.log("response.status ", response.status);
            if (response.status === 429) {
                data = "Too many request, please try again after 10 min"
            }
            else{
                data = err
            }
            ////////console.log("data.status ", data);

         }


         if (!response.ok) {
            ////////console.log("datadatadata22 ", response);

             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logoutSession();
                 //location.reload(true);

                 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
             }
             else if(response.status === 429){

                return Promise.reject({message:response.statusText});
             }
             // //////console.log("datadatadata ", response);


         }
         ////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             if (data.code===3) {
                 logoutSession();
             }
             if(data && data.message && (data.message === "UnauthorizedError")){
                logout()

             }
//              else if (data.code===1) {
//                 logoutSession();
//             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 
 
 function handleResponse2(response) {
     // //////console.log("response22222   ", response);
  
      return response.text().then(text => {
          const data = text && JSON.parse(text);
          if (!response.ok) {
              if (response.status === 401) {
                  // auto logout if 401 response returned from api
                  logout();
                  //location.reload(true);
              }
              // //////console.log("datadatadata ", response);
  
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
          }
          ////////console.log("datadatadatadatadata   ", data.error);
          if (data.error) {
              // //////console.log("datadatadatadatadata   ", data);
              if (data.code===3) {
                  logout();
              }
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
          }
          return data;
      });
  }
  
 





function logoutSession(data) {

    let header = new Headers({
            'Content-Type': 'application/json',
            'Authorization': authHeader().Authorization
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/userlogout`, requestOptions)
            .then(handleResponse2)
            .then(user => {
                let userObj = {
                    userLogout: user
                }
                localStorage.removeItem('adminuser');
                window.location.href = "#/login";
                return userObj;
            });
}



function validateOtp(data) {

    let header = new Headers({
            'Content-Type': 'application/json'
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/validateOtp`, requestOptions)
            .then(handleResponse)
            .then(user => {

                //console.log('user:--------------------> ',user);


                let userX1 = user && user.data && user.data.text ? user.data.text : "";
                //console.log('userX1:--------------------> ',userX1);

                let cryptedText = userX1 ? JSON.parse(userX1) : "";
                let bytes = cryptedText ? CryptoJS.AES.decrypt(cryptedText, "secretkey123") : "";
                let userY = "";

                try {
                    userY = bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : "";
                } catch (error) {
                    userY = ""
                }


                let userZ = userY ?userY : "";



                let userObj = {
                    userinfo: userZ
                }

                //console.log("userObj---->",userObj)

                
                if (userZ) {

                    let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(userZ), "secretkey123").toString();
                    localStorage.setItem('adminuser', JSON.stringify(cryptedText));
                }
                
                return userObj;
            });
}




//Super Admin Login

function login(data) {

    let header = new Headers({
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
            .then(handleResponse)
            .then(user => {
                let userObj = {
                    userinfo: user.data
                }
                if (user.data) {
                    let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(user.data), "secretkey123").toString();
                    localStorage.setItem('adminuser', JSON.stringify(cryptedText));
                }
                
                return userObj;
            });
}



function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetails: res.data
            }
            return userObj;
        });
}




function uploadPDF(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filePDFDetails: res.data
            }
            return userObj;
        });
}


function uploadImageLogo(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetailsLogo: res.data
            }
            return userObj;
        });
}

function uploadImageLogo2(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);


    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetailsLogo2: res.data
            }
            return userObj;
        });
}
