import React, { Component } from "react";
import { connect } from "react-redux";
import HeatMap from "./Charts/HeatMap";
import IndexCards from "./Charts/IndexCards";
import { CSVLink } from "react-csv";

import IndexComponent from "./Components/IndexComponent/IndexComponent";
import Commentary from "../Index/Components/Commentary/Commentary";

import ReportModal from "./Components/ReportModal/ReportModal";

class ManagerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerDetailsIndexType: 0,
      showManagerList: true,

      optionTypeInner: 1,

      mgrIndexA: 0,
      mgrIndexB: 8,
      CurrentManagerName: "",
      CurrentManagerId: "",
      dynamicList: [],

      optionTypeM: 101,

      CurrentManagerData: "",

      showCircle: true,

      crrManagersList: [],

      crrManagerName: "none",
      crrMangerId: "",
      showMultiple: false,

      showManagerReportModal: false,
      AllSelected: false,

      managerSearchName: "",
      DimensionList: [
        {
          index: 1,
          name: "Happiness",
          cutOff: 59,
        },
        {
          index: 2,
          name: "Engagement",
          cutOff: 78,
        },
        {
          index: 4,
          name: "Stress",
          cutOff: 28,
        },
        {
          index: 5,
          name: "Manager",
          cutOff: 50,
        },
        // {
        //   index:6,
        //   name:'Leader',
        //   cutOff:50
        // },
        // {
        //   index:8,
        //   name:'OrgCore',
        //   cutOff:59
        // },
      ],
      DemographicRestriction: [
        "ManagerPID",
        "Department",
        "Function",
        "Business",
      ],
    };
  }

  componentDidMount() { }

  handleViewType = (data) => {
    this.setState({ showCircle: data });
  };

  goPrev = () => {
    let { getDemographicIndexForManagerData } = this.props;
    let { mgrIndexA, mgrIndexB } = this.state;
    if (
      getDemographicIndexForManagerData &&
      getDemographicIndexForManagerData.length > 0
    ) {
      if (mgrIndexA > 0) {
        this.setState({ mgrIndexA: mgrIndexA - 1, mgrIndexB: mgrIndexB - 1 });
      }
    }
  };
  goNext = () => {
    let { getDemographicIndexForManagerData } = this.props;
    let { mgrIndexA, mgrIndexB } = this.state;
    if (
      getDemographicIndexForManagerData &&
      getDemographicIndexForManagerData.length > 0
    ) {
      if (mgrIndexB < getDemographicIndexForManagerData.length - 1) {
        this.setState({ mgrIndexA: mgrIndexA + 1, mgrIndexB: mgrIndexB + 1 });
      }
    }
  };

  SelectManager = (data, ManagerName1) => {
    let { handleManagerHeatmap, EmpFilterData } = this.props;
    let { optionTypeM, DemographicRestriction } = this.state;

    // this.setState({CurrentManagerName:data.name, CurrentManagerId:data.ManagerPID, CurrentManagerData:data})
    this.setState({ dynamicList: [] });
    // DynaObj=[]

    if (EmpFilterData && EmpFilterData.length > 0) {
      // handleManagerHeatmap({
      //   level:'ManagerPID',
      //   value:data['ID']
      // });
      EmpFilterData.forEach((filter) => {
        if (
          DemographicRestriction.findIndex((prev) => prev === filter.name) !==
          -1
        ) {
          if (data && data[filter.name]) {
            let filterdata = {
              level: filter.name,
              value:
                data[
                filter && filter.name === "ManagerPID" ? "id" : filter.name
                ],
            };
            // //////console.log('filterdata: ',filterdata);
            handleManagerHeatmap(filterdata);
          }
        }
      });
    }

    if (optionTypeM !== 101) {
      // if(optionTypeM==1){
      // this.handleOption(optionTypeM,data.ManagerPID,data, ManagerName1);
      // }
    }
  };

  // handleOption = (optionTypeM, managerPID, managerData, ManagerName1) => {
  //   let { crrManagersList, DemographicRestriction } = this.state;
  //   this.setState({ optionTypeM: optionTypeM });

  //   if (true) {
  //     if (crrManagersList.findIndex(prev => prev === managerData) === -1) {
  //       crrManagersList.push(managerData);
  //       this.setState({ crrManagersList });
  //     }
  //   }

  //   this.setState({ CurrentManagerId: managerPID, CurrentManagerData: managerData, CurrentManagerName: ManagerName1 })
  //   this.setState({ showViewBy: false });

  //   let { handleManagerQuestion, handleManagerDashboardCall, handleMultiManagerWiseDriver, getDemographicIndexForManagerData, handleManagerWiseDriver, EmpFilterData, handleManagerListIndexQuestionCall, QuesDynaObj, ManagerListQuestionDataObj } = this.props;

  //   if (optionTypeM !== 101) {
  //     let { crrManagersList } = this.state;

  //     if (crrManagersList && crrManagersList.length > 0) {

  //       if (crrManagersList.length === 1) {

  //         this.setState({ showMultiple: false });

  //         if (EmpFilterData && EmpFilterData.length > 0) {
  //           // handleManagerQuestion({
  //           //   template:optionTypeM,
  //           //   filters:[
  //           //     {
  //           //       "level":"ManagerPID",
  //           //       "value":crrManagersList[0]["id"]
  //           //     }
  //           //   ]
  //           // });
  //           EmpFilterData.forEach((emp) => {
  //             if (DemographicRestriction.findIndex(prev => prev === emp.name) !== -1) {

  //               let data = {
  //                 template: optionTypeM,
  //                 filters: [
  //                   {
  //                     "level": emp.name,
  //                     "value": crrManagersList[0][emp && emp.name === "ManagerPID" ? "id" : emp.name]
  //                   }
  //                 ]
  //               }
  //               // //////console.log('///////////////////////////////////---------------------------------:data',data)
  //               handleManagerQuestion(data);
  //             }
  //           })
  //         }

  //         for (let i = 0; i < 2; i++) {
  //           if (EmpFilterData && EmpFilterData.length > 0) {
  //             // handleManagerWiseDriver(optionTypeM,crrManagersList[0].id,"ManagerPID");
  //             EmpFilterData.forEach((emp) => {
  //               if (DemographicRestriction.findIndex(prev => prev === emp.name) !== -1) {

  //                 handleManagerWiseDriver(optionTypeM, crrManagersList[0].id, emp.name);
  //               }
  //             })
  //           }
  //         }

  //       }
  //       else {
  //         this.setState({ showMultiple: true });

  //         for (let i = 0; i < 2; i++) {

  //           if (crrManagersList && crrManagersList.length > 0) {
  //             crrManagersList.forEach((mngr) => {

  //               handleManagerListIndexQuestionCall(optionTypeM, mngr);

  //               handleMultiManagerWiseDriver(optionTypeM, mngr);

  //             });
  //           }
  //         }

  //       }

  //     }
  //     else {
  //       // //////console.log('testing---getDemographicIndexForManagerData',getDemographicIndexForManagerData)
  //       if (getDemographicIndexForManagerData && getDemographicIndexForManagerData.length > 0) {
  //         // //////console.log('testing---getDemographicIndexForManagerData',getDemographicIndexForManagerData)
  //         if (EmpFilterData && EmpFilterData.length > 0) {
  //           handleManagerQuestion({
  //             template: optionTypeM,
  //             filters: [
  //               {
  //                 "level": "ManagerPID",
  //                 "value": getDemographicIndexForManagerData[0]["ManagerPID"]
  //               }
  //             ]
  //           });
  //           EmpFilterData.forEach((emp) => {
  //             if (DemographicRestriction.findIndex(prev => prev === emp.name) !== -1) {

  //               let data = {
  //                 template: optionTypeM,
  //                 filters: [
  //                   {
  //                     "level": emp.name,
  //                     "value": getDemographicIndexForManagerData[0][emp.name]
  //                   }
  //                 ]
  //               }
  //               handleManagerQuestion(data);
  //               // //////console.log('testing---handleManagerQuestion',data)
  //               // //////console.log('testing---getDemographicIndexForManagerData',getDemographicIndexForManagerData)

  //             }
  //           })
  //         }

  //         for (let i = 0; i < 2; i++) {
  //           if (EmpFilterData && EmpFilterData.length > 0) {
  //             handleManagerWiseDriver(optionTypeM, getDemographicIndexForManagerData[0].id, 'ManagerPID');
  //             EmpFilterData.forEach((emp) => {
  //               if (DemographicRestriction.findIndex(prev => prev === emp.name) !== -1) {

  //                 handleManagerWiseDriver(optionTypeM, getDemographicIndexForManagerData[0].id, emp.name);

  //               }
  //             })
  //           }
  //         }

  //       }
  //     }

  //   }
  //   else {
  //     if (crrManagersList && crrManagersList.length > 0) {

  //       if (crrManagersList.length === 1) {
  //         this.SelectManager(crrManagersList[0], crrManagersList[0].name);
  //       }
  //       else {
  //         crrManagersList.forEach((mngr) => {
  //           handleManagerDashboardCall(mngr);
  //         });
  //       }

  //     }
  //   }

  // }

  handleOption = (optionTypeM, managerPID, managerData, ManagerName1) => {
    let { crrManagersList, DemographicRestriction, optionTypeInner } =
      this.state;
    this.setState({ optionTypeM: optionTypeM });

    if (true) {
      if (crrManagersList.findIndex((prev) => prev === managerData) === -1) {
        crrManagersList.push(managerData);
        this.setState({ crrManagersList });
      }
    }

    this.setState({
      CurrentManagerId: managerPID,
      CurrentManagerData: managerData,
      CurrentManagerName: ManagerName1,
    });
    this.setState({ showViewBy: false });

    let {
      callManagersDimension,
      handleManagerQuestion,
      handleManagerDashboardCall,
      handleMultiManagerWiseDriver,
      getDemographicIndexForManagerData,
      handleManagerWiseDriver,
      EmpFilterData,
      handleManagerListIndexQuestionCall,
      QuesDynaObj,
      ManagerListQuestionDataObj,
    } = this.props;


    if (optionTypeM !== 101) {
      let { crrManagersList } = this.state;

      if (crrManagersList && crrManagersList.length > 0) {
        if (crrManagersList.length === 1 && false) {
          this.setState({ showMultiple: false });

          if (EmpFilterData && EmpFilterData.length > 0) {
            // handleManagerQuestion({
            //   template:optionTypeM,
            //   filters:[
            //     {
            //       "level":"ManagerPID",
            //       "value":crrManagersList[0]["id"]
            //     }
            //   ]
            // });
            EmpFilterData.forEach((emp) => {
              if (
                DemographicRestriction.findIndex(
                  (prev) => prev === emp.name
                ) !== -1
              ) {
                let data = {
                  template: optionTypeM,
                  filters: [
                    {
                      level: emp.name,
                      value:
                        crrManagersList[0][
                        emp && emp.name === "ManagerPID" ? "id" : emp.name
                        ],
                    },
                  ],
                };
                // //////console.log('///////////////////////////////////---------------------------------:data',data)
                handleManagerQuestion(data);
              }
            });
          }

          for (let i = 0; i < 2; i++) {
            if (EmpFilterData && EmpFilterData.length > 0) {
              // handleManagerWiseDriver(optionTypeM,crrManagersList[0].id,"ManagerPID");
              EmpFilterData.forEach((emp) => {
                if (
                  DemographicRestriction.findIndex(
                    (prev) => prev === emp.name
                  ) !== -1
                ) {
                  handleManagerWiseDriver(
                    optionTypeM,
                    crrManagersList[0].id,
                    emp.name
                  );
                }
              });
            }
          }
        } else {
          this.setState({ showMultiple: true });

          // for (let i = 0; i < 2; i++) {

          //   if (crrManagersList && crrManagersList.length > 0) {
          //     crrManagersList.forEach((mngr) => {

          //       handleManagerListIndexQuestionCall(optionTypeM, mngr);

          //       handleMultiManagerWiseDriver(optionTypeM, mngr);

          //     });
          //   }
          // }

          let { callManagersQuestion } = this.props;

          let mngrEmpIdList = crrManagersList.map((element) =>
            element.emp_id.toString()
          );
          console.log("mngrEmpIdList-------->", mngrEmpIdList);

          if (optionTypeInner === 1) {
            callManagersQuestion({
              indexNumber: optionTypeM,
              mngrEmpIdList: mngrEmpIdList,
            });
          } else {
            callManagersDimension({
              indexNumber: optionTypeM,
              mngrEmpIdList: mngrEmpIdList,
            });
          }
        }
      } else {
        // //////console.log('testing---getDemographicIndexForManagerData',getDemographicIndexForManagerData)
        if (
          getDemographicIndexForManagerData &&
          getDemographicIndexForManagerData.length > 0
        ) {
          // //////console.log('testing---getDemographicIndexForManagerData',getDemographicIndexForManagerData)
          if (EmpFilterData && EmpFilterData.length > 0) {
            handleManagerQuestion({
              template: optionTypeM,
              filters: [
                {
                  level: "ManagerPID",
                  value: getDemographicIndexForManagerData[0]["ManagerPID"],
                },
              ],
            });
            EmpFilterData.forEach((emp) => {
              if (
                DemographicRestriction.findIndex(
                  (prev) => prev === emp.name
                ) !== -1
              ) {
                let data = {
                  template: optionTypeM,
                  filters: [
                    {
                      level: emp.name,
                      value: getDemographicIndexForManagerData[0][emp.name],
                    },
                  ],
                };
                handleManagerQuestion(data);
                // //////console.log('testing---handleManagerQuestion',data)
                // //////console.log('testing---getDemographicIndexForManagerData',getDemographicIndexForManagerData)
              }
            });
          }

          for (let i = 0; i < 2; i++) {
            if (EmpFilterData && EmpFilterData.length > 0) {
              handleManagerWiseDriver(
                optionTypeM,
                getDemographicIndexForManagerData[0].id,
                "ManagerPID"
              );
              EmpFilterData.forEach((emp) => {
                if (
                  DemographicRestriction.findIndex(
                    (prev) => prev === emp.name
                  ) !== -1
                ) {
                  handleManagerWiseDriver(
                    optionTypeM,
                    getDemographicIndexForManagerData[0].id,
                    emp.name
                  );
                }
              });
            }
          }
        }
      }
    } else {
      if (crrManagersList && crrManagersList.length > 0) {
        if (crrManagersList.length === 1 && false) {
          this.SelectManager(crrManagersList[0], crrManagersList[0].name);
        } else {
          // crrManagersList.forEach((mngr) => {
          //   handleManagerDashboardCall(mngr);
          // });

          let { callManagersIndex } = this.props;

          let mngrEmpIdList = crrManagersList.map((element) =>
            element.emp_id.toString()
          );
          console.log("mngrEmpIdList-------->", mngrEmpIdList);

          callManagersIndex({
            indexNumber: optionTypeM,
            mngrEmpIdList: mngrEmpIdList,
          });
        }
      }
    }
  };

  handleManagerQuestion = (optionType) => {
    let { CurrentManagerId, CurrentManagerData } = this.state;
    this.handleOption(optionType, CurrentManagerId, CurrentManagerData);
  };

  handleManagerDriver = (Mindextype) => {
    let { handleManagerWiseDriver, EmpFilterData } = this.props;

    let { CurrentManagerId, DemographicRestriction } = this.state;

    if (EmpFilterData && EmpFilterData.length > 0) {
      handleManagerWiseDriver(Mindextype, CurrentManagerId, "ManagerPID");
      EmpFilterData.forEach((emp) => {
        if (
          DemographicRestriction.findIndex((prev) => prev === emp.name) !== -1
        ) {
          handleManagerWiseDriver(Mindextype, CurrentManagerId, emp.name);
        }
      });
    }
  };

  openDropdown = (check) => {
    this.setState({ showViewBy: !check });
  };

  handleManagerALLSelection = () => {
    let { AllSelected, crrManagersList } = this.state;
    if (AllSelected) {
      this.setState({ crrManagersList: [] });
      this.setState({ AllSelected: false });
    } else {
      let { dashboard } = this.props;
      let { getDemographicIndexForManagerData } = dashboard;
      if (
        getDemographicIndexForManagerData &&
        getDemographicIndexForManagerData.length > 0
      ) {
        getDemographicIndexForManagerData.forEach((mngr) => {
          crrManagersList.push(mngr);
        });
      }
      this.setState({ crrManagersList, AllSelected: true });
    }

    let defaultMngr = [
      this.prop.dashboard &&
        this.prop.dashboard.sgetDemographicIndexForManagerData &&
        this.prop.dashboard.getDemographicIndexForManagerData.length > 0
        ? this.prop.dashboard.getDemographicIndexForManagerData[0]
        : {},
    ];
  };

  handleManagerSelection = (mngr, isCheck) => {
    let { crrManagersList } = this.state;
    this.setState({ AllSelected: false });

    let getIndex = crrManagersList.findIndex(
      (prev) => prev.emp_id === mngr.emp_id
    );
    if (getIndex === -1) {
      crrManagersList.push(mngr);
    } else {
      crrManagersList.splice(getIndex, 1);
    }
    this.setState({ crrManagersList });

    if (isCheck) {
      this.setState({ showViewBy: false });
    }
  };

  handleManagersCall = () => {
    let { crrManagersList, optionTypeM, optionTypeInner } = this.state;

    let isMultipleUser = false;
    if (crrManagersList && crrManagersList.length > 1) {
      isMultipleUser = true;
    }
    this.setState({ showMultiple: isMultipleUser });

    let { callManagersQuestion, callManagersDimension, callManagersIndex } =
      this.props;

    let mngrEmpIdList = crrManagersList.map((element) =>
      element.emp_id.toString()
    );
    console.log("mngrEmpIdList-------->", mngrEmpIdList);
    console.log(
      "optionTypeInner-----optionTypeM-->",
      optionTypeInner,
      optionTypeM
    );

    if (optionTypeM !== 101) {
      if (optionTypeInner === 1) {
        callManagersQuestion({
          indexNumber: optionTypeM,
          mngrEmpIdList: mngrEmpIdList,
        });
      } else {
        callManagersDimension({
          indexNumber: optionTypeM,
          mngrEmpIdList: mngrEmpIdList,
        });
      }
    } else {
      callManagersIndex({
        indexNumber: optionTypeM,
        mngrEmpIdList: mngrEmpIdList,
      });
    }

    // let { handleManagerDashboardCall } = this.props;
    // let { handleManagerQuestion, handleMultiManagerWiseDriver, handleManagerWiseDriver, EmpFilterData, handleManagerListIndexQuestionCall, QuesDynaObj } = this.props;

    // if (optionTypeM !== 101) {
    //   if (crrManagersList && crrManagersList.length > 0) {
    //     if (crrManagersList.length === 1) {

    //       if (EmpFilterData && EmpFilterData.length > 0) {

    //         EmpFilterData.forEach((emp) => {
    //           if (DemographicRestriction.findIndex(prev => prev === emp.name) !== -1) {

    //             let data = {
    //               template: optionTypeM,
    //               filters: [
    //                 {
    //                   "level": emp.name,
    //                   "value": crrManagersList[0][emp && emp.name === "ManagerPID" ? "id" : emp.name]
    //                 }
    //               ]
    //             }
    //             handleManagerQuestion(data);
    //           }
    //         })
    //       }

    //       for (let i = 0; i < 2; i++) {
    //         if (EmpFilterData && EmpFilterData.length > 0) {
    //           handleManagerWiseDriver(optionTypeM, crrManagersList[0].id, "ManagerPID");
    //           EmpFilterData.forEach((emp) => {
    //             if (DemographicRestriction.findIndex(prev => prev === emp.name) !== -1) {

    //               handleManagerWiseDriver(optionTypeM, crrManagersList[0].id, emp.name);
    //             }
    //           })
    //         }
    //       }

    //       this.setState({ showMultiple: false, CurrentManagerData: crrManagersList[0] });

    //     }
    //     else {
    //       this.setState({ showMultiple: true });
    //       for (let i = 0; i < 2; i++) {

    //         if (crrManagersList && crrManagersList.length > 0) {
    //           crrManagersList.forEach((mngr) => {

    //             handleManagerListIndexQuestionCall(optionTypeM, mngr);

    //             handleMultiManagerWiseDriver(optionTypeM, mngr);

    //           });
    //         }
    //       }

    //     }

    //   }

    // }
    // else {
    //   if (crrManagersList && crrManagersList.length > 0) {

    //     if (crrManagersList.length === 1) {
    //       this.SelectManager(crrManagersList[0], crrManagersList[0].name);
    //     }
    //     else {
    //       crrManagersList.forEach((mngr) => {
    //         handleManagerDashboardCall(mngr);
    //       });
    //     }

    //   }
    // }

    this.setState({ showViewBy: false });
  };

  // handleManagersCall = (optionTypeM) => {
  //   let { crrManagersList, DemographicRestriction } = this.state;
  //   if (crrManagersList && crrManagersList.length > 1) {
  //     this.setState({ showMultiple: true });
  //   }
  //   else {
  //     this.setState({ showMultiple: false });
  //   }
  //   let { handleManagerDashboardCall } = this.props;
  //   let { handleManagerQuestion, handleMultiManagerWiseDriver, handleManagerWiseDriver, EmpFilterData, handleManagerListIndexQuestionCall, QuesDynaObj } = this.props;

  //   if (optionTypeM !== 101) {
  //     if (crrManagersList && crrManagersList.length > 0) {
  //       if (crrManagersList.length === 1) {

  //         if (EmpFilterData && EmpFilterData.length > 0) {

  //           EmpFilterData.forEach((emp) => {
  //             if (DemographicRestriction.findIndex(prev => prev === emp.name) !== -1) {

  //               let data = {
  //                 template: optionTypeM,
  //                 filters: [
  //                   {
  //                     "level": emp.name,
  //                     "value": crrManagersList[0][emp && emp.name === "ManagerPID" ? "id" : emp.name]
  //                   }
  //                 ]
  //               }
  //               handleManagerQuestion(data);
  //             }
  //           })
  //         }

  //         for (let i = 0; i < 2; i++) {
  //           if (EmpFilterData && EmpFilterData.length > 0) {
  //             handleManagerWiseDriver(optionTypeM, crrManagersList[0].id, "ManagerPID");
  //             EmpFilterData.forEach((emp) => {
  //               if (DemographicRestriction.findIndex(prev => prev === emp.name) !== -1) {

  //                 handleManagerWiseDriver(optionTypeM, crrManagersList[0].id, emp.name);
  //               }
  //             })
  //           }
  //         }

  //         this.setState({ showMultiple: false, CurrentManagerData: crrManagersList[0] });

  //       }
  //       else {
  //         this.setState({ showMultiple: true });
  //         for (let i = 0; i < 2; i++) {

  //           if (crrManagersList && crrManagersList.length > 0) {
  //             crrManagersList.forEach((mngr) => {

  //               handleManagerListIndexQuestionCall(optionTypeM, mngr);

  //               handleMultiManagerWiseDriver(optionTypeM, mngr);

  //             });
  //           }
  //         }

  //       }

  //     }

  //   }
  //   else {
  //     if (crrManagersList && crrManagersList.length > 0) {

  //       if (crrManagersList.length === 1) {
  //         this.SelectManager(crrManagersList[0], crrManagersList[0].name);
  //       }
  //       else {
  //         crrManagersList.forEach((mngr) => {
  //           handleManagerDashboardCall(mngr);
  //         });
  //       }

  //     }
  //   }

  //   this.setState({ showViewBy: false });

  // }

  onManagerReportModal = (value) => {
    this.setState({ showManagerReportModal: value, optionTypeM: 101 });
  };

  getReportMngrQuestion = (mngr, template) => {
    let { EmpFilterData, handleManagerQuestion } = this.props;
    let { DemographicRestriction } = this.state;
    if (EmpFilterData && EmpFilterData.length > 0) {
      // handleManagerQuestion({
      //   template:template,
      //   filters:[
      //     {
      //       "level":"ManagerPID",
      //       "value":mngr["ManagerPID"]
      //     }
      //   ]
      // });
      EmpFilterData.forEach((emp) => {
        if (
          DemographicRestriction.findIndex((prev) => prev === emp.name) !== -1
        ) {
          let data = {
            template: template,
            filters: [
              {
                level: emp.name,
                value:
                  mngr[
                    emp && emp.name === "ManagerPID" ? "id" : emp.name
                  ].toString(),
              },
            ],
          };
          handleManagerQuestion(data);
        }
      });
    }
  };

  getReportMngrDriver = (mngr, template) => {
    let { EmpFilterData, handleManagerWiseDriver } = this.props;
    let { DemographicRestriction } = this.state;

    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (
          DemographicRestriction.findIndex((prev) => prev === emp.name) !== -1
        ) {
          let filters = {
            level: emp.name,
            value:
              mngr[
                emp && emp.name === "ManagerPID" ? "id" : emp.name
              ].toString(),
          };
          handleManagerWiseDriver(template, mngr.id, emp.name, filters, true);
        }
      });
    }
  };

  handleSearchManager = (e) => {
    this.setState({ managerSearchName: e.target.value });
  };

  OrgLevelDriverScore = () => {
    let { optionTypeM } = this.state;
    let { IndexDriverScore } = this.props;

    if (optionTypeM !== 101) {
      IndexDriverScore(optionTypeM);
    }
  };

  getAllManagerExcel = () => {
    let { getAllManagerScores } = this.props;
    getAllManagerScores();
  };

  handleInnerOption = (data) => {
    this.setState({ optionTypeInner: data }, () => {
      this.handleManagersCall();
    });
  };

  render() {
    let {
      getAllBenchMarkStaticData,
      CurrentSurveyId,
      getMngrTeamSizeData,
      getDemographicIndexEngagementV2Data,
      getDemographicIndexManagerV2Data,
      onLoadManagerReport,

      getAllManagerScoresData,

      getIndexByFilterDMGData,
      getOverallScoreBySurveyIdData,

      getHappinessQuestionData,
      getHappinessManagerQuestionData,
      getHappinessDMGQuestionData,
      getDemographicIndexHappinessData,

      getEngagementQuestionData,
      getEngagementManagerQuestionData,
      getEngagementDMGQuestionData,
      getDemographicIndexEngagementData,

      getStressQuestionData,
      getStressManagerQuestionData,
      getStressDMGQuestionData,
      getDemographicIndexStressData,

      getManagerQuestionData,
      getManagerManagerQuestionData,
      getManagerDMGQuestionData,
      getDemographicIndexManagerData,

      getLeaderQuestionData,
      getLeaderManagerQuestionData,
      getLeaderDMGQuestionData,
      getDemographicIndexLeaderData,

      getSurveyData,
      indexType,
      getCommentData,
      getIndexData,
      getDemographicIndexForManagerData,
      getManagerListDomainFilterData,
      ManagerIndexDriverData,
      ManagerDynaObj,
      EmpFilterData,
      QuesDynaObj,
      ManagerIndexQuestionData,

      ManagerListDasboardDataObj,
      ManagerListQuestionDataObj,

      QuesMngrHappinessReportObject,
      QuesMngrEngagementReportObject,
      QuesMngrStressReportObject,
      QuesMngrManagerReportObject,

      DriverMngrHappinessReportObject,
      DriverMngrEngagementReportObject,
      DriverMngrStressReportObject,
      DriverMngrManagerReportObject,

      getHappinessIndexDriverData,
      getEngagementIndexDriverData,
      getStressIndexDriverData,
      getManagerIndexDriverData,
      getLeaderIndexDriverData,

      getHappinessIndexByFilterData,
      getEngagementIndexByFilterData,
      getEngagementIndexByFilterV2Data,
      getStressIndexByFilterData,

      getManagerIndexByFilterData,
      getManagerIndexByFilterV2Data,

      getLeaderIndexByFilterData,

      surveyLogo,

      ManagerReportFConfig,
      onManagerDownloadStatus,

      getMngrReportDownloadStatusData,
      getMngrReportDownloadStatusTotal,

      getOrgQuestionData,
      getOrgManagerQuestionData,
      getOrgDMGQuestionData,

      getDemographicIndexOrgData,

      allowSpecificFn,

      getQuestionOthersResultData,

      getQuestionManagerPIDWiseData,
      getIndexScoreManagerPIDWiseData,
      getIndexDriverManagerPIDWiseData,
    } = this.props;

    let ScoreExcel = getAllManagerScoresData;
    let {
      managerSearchName,
      crrManagerName,
      crrMangerId,
      crrManagersList,
      showViewBy,
      DemographicRestriction,
    } = this.state;

    let {
      CurrentManagerName,
      CurrentManagerId,
      optionTypeM,

      CurrentManagerData,

      showCircle,
    } = this.state;

    // dynamicList.push(getManagerListDomainFilterData)

    //////console.log('crrManagersList: ', crrManagersList)
    console.log(
      "----------getManagerIndexDriverData---------------------------------->: ",
      getManagerIndexDriverData
    );

    let DefaultManagerId = "";
    let DefaultManagerName = "";

    let IndexForManagerData = {};
    let IndexData = {};

    function getIndexName(key) {
      let name = "";
      if (key === 1) {
        name = "Happiness";
      } else if (key === 2) {
        name = "Engagement";
      } else if (key === 3) {
        name = "Hope";
      } else if (key === 4) {
        name = "Stress";
      } else if (key === 5) {
        name = "Manager";
      }

      return name;
    }

    let IndexList = [];
    let IndexLabels = [];
    if (getIndexData) {
      IndexList = Object.keys(getIndexData).map((key) => [
        Number(key),
        getIndexData[key],
      ]);
    }
    if (IndexList && IndexList.length > 0) {
      IndexList.forEach((item) => {
        if (item[1]) {
          IndexData[item[0]] = item[1] ? item[1].toFixed(2) : "NaN";
        }

        if (getIndexName(item[0])) {
          IndexLabels.push({
            indexType: item[0],
            indexName: getIndexName(item[0]),
          });
        }
      });
    }
    this.state.FilterList = IndexLabels;

    if (
      getDemographicIndexForManagerData &&
      getDemographicIndexForManagerData.length > 0
    ) {
      if (!CurrentManagerId) {
        DefaultManagerId = getDemographicIndexForManagerData[0].ManagerPID;
        DefaultManagerName = getDemographicIndexForManagerData[0].name;

        if (IndexList && IndexList.length > 0) {
          IndexList.forEach((item) => {
            IndexForManagerData[item[0]] =
              getDemographicIndexForManagerData[0][item[0]];
          });
        }
      } else {
        getDemographicIndexForManagerData.forEach((mgr) => {
          if (mgr.ManagerPID === CurrentManagerId) {
            if (IndexList && IndexList.length > 0) {
              IndexList.forEach((item) => {
                IndexForManagerData[item[0]] = mgr[item[0]];
              });
            }
          }
        });
      }
    }

    let managerPID = "";
    let managerData = "";
    let managerNewName = "";

    if (
      getDemographicIndexForManagerData &&
      getDemographicIndexForManagerData.length > 0
    ) {
      if (!CurrentManagerId) {
        managerPID = getDemographicIndexForManagerData[0].id;
        managerData = getDemographicIndexForManagerData[0];
        managerNewName = getDemographicIndexForManagerData[0].name;
      } else {
        managerPID = CurrentManagerId;
        managerData = CurrentManagerData;
        managerNewName = CurrentManagerName;
      }
    }

    // //////console.log('managerPID: ',managerPID);
    // //////console.log('managerData: ',managerData);
    // //////console.log('managerNewName: ',managerNewName);
    // //////console.log('getDemographicIndexForManagerData: ',getDemographicIndexForManagerData);

    // //////console.log('IndexLabels: ',IndexLabels);

    let {
      DynaObj,
      getQuestionData,
      getQuestionReportData,
      tempList,
      loading,
      getQuestionOverallData,
      IndexDimension,
    } = this.props;



    console.log('DynaObj:-----vvvvvv--> ',DynaObj);


    let ManagerName1 = "";
    if (CurrentManagerName) {
      ManagerName1 = CurrentManagerName;
    } else {
      ManagerName1 = DefaultManagerName;
    }

    // //////console.log('tempList: ',tempList);

    let selectedManagerName = "";
    let selectedManagerId = "";

    if (
      getDemographicIndexForManagerData &&
      getDemographicIndexForManagerData.length > 0
    ) {
      if (crrManagerName && crrMangerId) {
        selectedManagerName = crrManagerName;
        selectedManagerId = crrMangerId;
      } else {
        selectedManagerName = getDemographicIndexForManagerData[0].name;
        selectedManagerId = getDemographicIndexForManagerData[0].id;
      }
      //------
    }
    let { showMultiple } = this.state;

    let DemographicDriverManagerData = [];
    let temShowMultiple = false;
    if (crrManagersList && crrManagersList.length > 0) {
      if (ManagerIndexDriverData && ManagerIndexDriverData.length > 0) {
        ManagerIndexDriverData.forEach((item) => {
          crrManagersList.forEach((mngr) => {
            if (item.name === mngr.id.toString()) {
              let tempObj = {
                ...item,
                id: mngr.id.toString(),
              };
              tempObj["name"] = mngr.name;
              DemographicDriverManagerData.push(tempObj);
            }
          });
        });
      }
      temShowMultiple = true;
    } else {
      temShowMultiple = false;
    }
    // //////console.log('DemographicDriverManagerData: ',DemographicDriverManagerData);

    // //////console.log('//////////////////DemographicDriverManagerData: ',DemographicDriverManagerData)
    // //////console.log('//////////////////ManagerIndexDriverData: ',ManagerIndexDriverData)

    // //////console.log('-------------------------------------------->: ');

    // //////console.log('ManagerListDasboardDataObj: ',ManagerListDasboardDataObj);
    // //////console.log('ManagerListQuestionDataObj: ',ManagerListQuestionDataObj);

    // //////console.log('-------------------------------------------->: ');

    let NEW_ManagerListDasboardDataObj = [];
    let NEW_ManagerListQuestionDataObj = [];

    if (ManagerListDasboardDataObj && ManagerListDasboardDataObj.length > 0) {
      ManagerListDasboardDataObj.forEach((item1) => {
        let getIndex = crrManagersList.findIndex(
          (prev) => prev.id === item1.ManagerPID
        );
        if (getIndex !== -1) {
          NEW_ManagerListDasboardDataObj.push(item1);
        }
      });
    }

    if (ManagerListQuestionDataObj && ManagerListQuestionDataObj.length > 0) {
      ManagerListQuestionDataObj.forEach((item1) => {
        let getIndex = crrManagersList.findIndex(
          (prev) => prev.id === item1.ManagerPID
        );
        if (getIndex !== -1) {
          NEW_ManagerListQuestionDataObj.push(item1);
        }
      });
    }

    // //////console.log('-------------------------------------------->: ');

    // //////console.log('NEW_ManagerListDasboardDataObj: ',NEW_ManagerListDasboardDataObj);

    // //////console.log('-------------------------------------------->: ');
    let DemographicIndexForManagerData = [];
    if (
      getDemographicIndexForManagerData &&
      getDemographicIndexForManagerData.length > 0
    ) {
      getDemographicIndexForManagerData.forEach((item) => {
        DemographicIndexForManagerData.push(item);
      });
    }

    function SortList(list) {
      if (list && list.length > 0) {
        // return list.sort((b,a)=>(a.name-b.name))
        return list.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        return [];
      }
    }

    //////console.log('getDemographicIndexForManagerData=>', getDemographicIndexForManagerData)
    // //////console.log('DemographicIndexForManagerData=>',DemographicIndexForManagerData)

    let { DimensionList } = this.state;

    let { validateIndex, GetParameterLabel } = this.props;

    // //////console.log('asdsd validateIndex=>',validateIndex)

    let validateList = [];
    // let validObj = Object.keys(validateIndex).map((key)=>[key,validateIndex[key]])
    let validObj = [];
    if (validateIndex) {
      validObj = Object.keys(validateIndex).map((key) => [
        key,
        validateIndex[key],
      ]);
    }
    //////console.log('validObj: ', validObj);
    if (validObj && validObj.length > 0) {
      validObj.forEach((item) => {
        if (item[1]) {
          let getIndex = DimensionList.findIndex(
            (prev) => prev.index === parseInt(item[0])
          );
          if (getIndex !== -1 ? DimensionList[getIndex].name : "") {
            validateList.push({
              name: getIndex !== -1 ? DimensionList[getIndex].name : "",
              template: parseInt(item[0]),
            });
          }
        }
      });
    }

    let singleManagerData = "";
    if (crrManagersList && crrManagersList.length === 1 && false) {
      singleManagerData = crrManagersList[0];
    } else {
      singleManagerData =
        getDemographicIndexForManagerData &&
          getDemographicIndexForManagerData.length > 0
          ? getDemographicIndexForManagerData[0]
          : "";
    }

    //////console.log(':-------------->:-------------->:--------------> getAllManagerScoresData:--------------> ', getAllManagerScoresData);

    return (
      <>
        <div className="md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto bg-[#f9fafb]">
          <div className="items-center justify-between space-y-4 text-gray-800 capitalize md:flex md:space-y-0">
            <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B] pb-2 ">
              Manger score card{" "}
            </h1>
          </div>
          {/*sub nav bar 1 */}
          <div className="items-center justify-between border-b md:flex">
            <div className="flex space-x-2 overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 whitespace-nowrap tab-scroll scollar-xs">
              <span
                onClick={() =>
                  this.handleOption(101, managerPID, managerData, ManagerName1)
                }
                className={
                  optionTypeM === 101
                    ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                    : "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                }
              >
                Manager dashboard
              </span>

              {validateList && validateList.length > 0
                ? validateList.map((item) =>
                  item.template !== 5 ? (
                  
                      <span
                        onClick={() =>
                          this.handleOption(
                            item.template,
                            managerPID,
                            managerData,
                            ManagerName1
                          )
                        }
                        className={
                          optionTypeM === item.template
                            ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                            : "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                        }
                      >
                        {item.name} Behaviour
                      </span>
                
                  ) : null
                )
                : null}

              <span
                onClick={() =>
                  this.handleOption(5, managerPID, managerData, ManagerName1)
                }
                className={
                  optionTypeM === 5
                    ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                    : "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "
                }
              >
                Manager Behaviour
              </span>
            </div>
          </div>

          <div className="items-start justify-start space-y-4 md:flex md:space-x-4 lg:space-y-0">
            <div className="border p-1.5 px-2 bg-white rounded-md  w-full flex justify-between items-start relative">
              <div onClick={() => this.openDropdown(showViewBy)} className="flex flex-wrap w-full gap-2 overflow-hidden cursor-pointer whitespace-nowrap scollar-xs">
                {crrManagersList && crrManagersList.length > 0 ? (
                  crrManagersList.map((element, index) => (

                    <button className="flex items-center p-1 px-2  bg-[#F9FAFB] text-xs font-medium rounded-sm text-[#3D405B] border space-x-0.5">
                      <span> {element.name}</span>
                      <span
                        onClick={() => this.handleManagerSelection(element)}
                        className="material-symbols-outlined text-[1.2rem]"
                      >
                        close
                      </span>
                    </button>

                  ))
                ) : (
                  <button className="flex items-center p-1.5 px-2  bg-[#F9FAFB] text-xs font-medium rounded-sm text-[#3D405B] border">
                    {getDemographicIndexForManagerData &&
                      getDemographicIndexForManagerData.length > 0
                      ? getDemographicIndexForManagerData[0].name
                      : ""}
                  </button>
                )}
              </div>

              <div className="absolute left-0 z-10 w-full font-normal bg-white rounded-md shadow-lg top-12 font-xs">
                {showViewBy ? (
                  <>
                    <div className="flex">
                      <div className="w-full p-3">
                        <input
                          type="text"
                          value={managerSearchName}
                          name="managerName"
                          onChange={this.handleSearchManager}
                          placeholder="Search"
                          className="w-full border p-1.5 rounded-sm focus:outline-none text-xs text-gray-500 mb-2 focus:ring-blue-500 focus:ring-1"
                        />
                      </div>

                      <div className="hidden w-2/12 p-3">
                        {/* <button onClick={()=>this.openDropdown(showViewBy)} className="px-10 py-2 ml-2 text-sm text-red-500 focus:outline-none">Close</button> */}
                      </div>
                    </div>

                    <div className="px-3 overflow-y-auto h-60" id="customscroll3">
                      {SortList(DemographicIndexForManagerData) &&
                        DemographicIndexForManagerData &&
                        DemographicIndexForManagerData.length > 0
                        ? SortList(DemographicIndexForManagerData).map(
                          (mngr, index) =>
                            mngr &&
                              mngr.name.match(
                                new RegExp(`${managerSearchName}`, "gi")
                              ) ? (
                              <div key={index} className="flex items-center py-2 m-1 ">
                                <label className="text-xs text-gray-500 cursor-pointer ">
                                  <input
                                    className="mr-2"
                                    type="checkbox"
                                    checked={
                                      crrManagersList.some(
                                        (prev) => prev.emp_id === mngr.emp_id
                                      )
                                        ? true
                                        : false
                                    }
                                    onClick={() =>
                                      this.handleManagerSelection(mngr)
                                    }
                                    onChange={() => { }}
                                  />
                                  {mngr.name}
                                </label>
                              </div>
                            ) : null
                        )
                        : null}
                    </div>
                  </>
                ) : null}
              </div>

              {showViewBy ? (
                <span
                  onClick={() => this.openDropdown(showViewBy)}
                  className="mt-1 cursor-pointer material-symbols-outlined"
                >
                  arrow_drop_up
                </span>
              ) : (
                <span
                  onClick={() => this.openDropdown(showViewBy)}
                  className="mt-1 cursor-pointer material-symbols-outlined"
                >
                  arrow_drop_down
                </span>
              )}
            </div>

            <button
              onClick={() => this.handleManagersCall(optionTypeM)}
              className="cursor-pointer bg-[#2196F3] text-white caipitalize  flex-shrink-0  px-6 items-center  py-3 rounded-md font-medium text-sm"
            >
              Apply
            </button>
          </div>

          <div onClick={() => this.openDropdown(true)}>
            {optionTypeM === 101 ? (
              <>
                {getIndexScoreManagerPIDWiseData && getIndexScoreManagerPIDWiseData.length > 0 ? (
                  <>
                    <div className="space-y-4 ">
                      <div className="flex flex-wrap justify-between gap-4 ">
                        <div className="flex flex-wrap items-center justify-end gap-2 text-sm">
                          <button
                            onClick={() => this.handleViewType(true)}
                            className={
                              showCircle
                                ? " bg-white p-2 px-4 rounded-md border text-[#2196f3] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"
                                : " bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className={
                                showCircle
                                  ? "group-hover:text-[#2196f3] transition-all duration-150  text-[#2196f3] icon icon-tabler icon-tabler-layout-grid"
                                  : "group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-layout-grid"
                              }
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <rect x="4" y="4" width="6" height="6" rx="1" />
                              <rect x="14" y="4" width="6" height="6" rx="1" />
                              <rect x="4" y="14" width="6" height="6" rx="1" />
                              <rect x="14" y="14" width="6" height="6" rx="1" />
                            </svg>

                            <span>Card View</span>
                          </button>


                          <button
                            onClick={() => this.handleViewType(false)}
                            className={
                              !showCircle
                                ? " bg-white p-2 px-4 rounded-md border text-[#2196f3] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"
                                : " bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className={
                                !showCircle
                                  ? "group-hover:text-[#2196f3] transition-all duration-150  text-[#2196f3] icon icon-tabler icon-tabler-table"
                                  : "group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-table"
                              }
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <rect x="4" y="4" width="16" height="16" rx="2" />
                              <line x1="4" y1="10" x2="20" y2="10" />
                              <line x1="10" y1="4" x2="10" y2="20" />
                            </svg>

                            <span>Table View</span>
                          </button>



                        </div>

                        <div className="flex flex-wrap items-center gap-2 ">
                          {ScoreExcel && ScoreExcel.length > 0 ? (
                            <CSVLink
                              data={ScoreExcel}
                              filename={"ManagersExcel.csv"}
                            >
                              <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                  <path d="M10 12l4 4m0 -4l-4 4" />
                                </svg>
                                <span> {"Download Excel"}</span>
                              </button>
                            </CSVLink>
                          ) : (
                            <button
                              onClick={() => this.getAllManagerExcel()}
                              className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                <path d="M10 12l4 4m0 -4l-4 4" />
                              </svg>
                              <span> {"Download Excel"}</span>
                            </button>
                          )}

                          <button
                            onClick={() => {
                              this.onManagerReportModal(true);
                              onManagerDownloadStatus();
                            }}
                            className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-report-analytics"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                              <rect x="9" y="3" width="6" height="4" rx="2" />
                              <path d="M9 17v-5" />
                              <path d="M12 17v-1" />
                              <path d="M15 17v-3" />
                            </svg>

                            <span> {"Download Report"}</span>
                          </button>

                          <Commentary
                            indexType={indexType}
                            optionType={1}
                            getCommentData={getCommentData}
                          />
                        </div>
                      </div>

                      <div className="">
                        {showCircle ? (
                          <IndexCards
                            IndexForManagerData={IndexForManagerData}
                            getIndexData={getIndexData}
                            getManagerListDomainFilterData={
                              getManagerListDomainFilterData
                            }
                            IndexLabels={IndexLabels}
                            DynaObj={DynaObj}
                            EmpFilterData={EmpFilterData}
                            showMultiple={showMultiple}
                            ManagerListDasboardDataObj={
                              NEW_ManagerListDasboardDataObj
                            }
                            validateList={validateList}
                            DemographicRestriction={DemographicRestriction}
                            GetParameterLabel={GetParameterLabel}
                            singleManagerData={singleManagerData}
                            getIndexScoreManagerPIDWiseData={
                              getIndexScoreManagerPIDWiseData
                            }
                            crrManagersList={crrManagersList}
                          />
                        ) : (
                          <HeatMap
                            IndexForManagerData={IndexForManagerData}
                            getIndexData={getIndexData}
                            getManagerListDomainFilterData={
                              getManagerListDomainFilterData
                            }
                            IndexLabels={IndexLabels}
                            DynaObj={DynaObj}
                            EmpFilterData={EmpFilterData}
                            showMultiple={showMultiple}
                            ManagerListDasboardDataObj={
                              NEW_ManagerListDasboardDataObj
                            }
                            validateList={validateList}
                            DemographicRestriction={DemographicRestriction}
                            GetParameterLabel={GetParameterLabel}
                            singleManagerData={singleManagerData}
                            getIndexScoreManagerPIDWiseData={
                              getIndexScoreManagerPIDWiseData
                            }
                            crrManagersList={crrManagersList}
                          />
                        )}
                      </div>

                      {/* {false ? (
                        <div className="space-y-0 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                          <div className="p-6 space-y-4 ">


                            <table className="w-full border border-collapse ">
                              <tr className="text-sm text-[#3D405B]">
                                <th className="w-[25%] border-r text-left p-4">
                                  <div className="flex items-center space-x-2">
                                    <span className="w-6 h-3 rounded-[4px] bg-[#EC407A] "></span>
                                    <span className="flex-1 w-full">
                                      Happiness
                                    </span>
                                  </div>
                                </th>
                                <th className="w-[25%] border-r text-left p-4">
                                  <div className="flex items-center space-x-2">
                                    <span className="w-6 h-3 rounded-[4px] bg-[#42A5F5] "></span>
                                    <span className="flex-1 w-full">
                                      Engagement
                                    </span>
                                  </div>
                                </th>
                                <th className="w-[25%] border-r text-left p-4">
                                  <div className="flex items-center space-x-2">
                                    <span className="w-6 h-3 rounded-[4px] bg-[#EF5350] "></span>
                                    <span className="flex-1 w-full">Stress</span>
                                  </div>
                                </th>
                                <th className="w-[25%] border-r text-left p-4">
                                  <div className="flex items-center space-x-2">
                                    <span className="w-6 h-3 rounded-[4px] bg-[#AB47BC] "></span>
                                    <span className="flex-1 w-full">Manager</span>
                                  </div>
                                </th>
                              </tr>

                              <tr className="text-sm font-medium ">
                                <td className="p-4 text-left bg-[#FCE4EC] border">
                                  <div className="flex items-center space-x-2">
                                    <span className=" text-[#FF4081] ">
                                      59.04%
                                    </span>
                                    <span className=""> (Overall)</span>
                                  </div>
                                </td>
                                <td className="p-4 text-left bg-[#E3F2FD] border">
                                  <div className="flex items-center space-x-2">
                                    <span className=" text-[#2196F3] ">
                                      59.04%
                                    </span>
                                    <span className=""> (Overall)</span>
                                  </div>
                                </td>
                                <td className="p-4 text-left bg-[#FFEBEE] border">
                                  <div className="flex items-center space-x-2">
                                    <span className=" text-[#EF4444] ">
                                      59.04%
                                    </span>
                                    <span className=""> (Overall)</span>
                                  </div>
                                </td>
                                <td className="p-4 text-left bg-[#F3E5F5] border">
                                  <div className="flex items-center space-x-2">
                                    <span className=" text-[#9C27B0] ">
                                      59.04%
                                    </span>
                                    <span className=""> (Overall)</span>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>

                          <div className="border-t border-b divide-y">
                            <div className="flex items-center w-full h-56 p-6">
                              <div className="h-full flex justify-start items-center w-[20%] ">
                                <h1 className="text-lg font-medium text-[#3D405B]/70">
                                  Akash Sharma
                                </h1>
                              </div>
                              <div className="w-[80%]  h-full"></div>
                            </div>
                            <div className="flex items-center w-full h-56 p-6">
                              <div className="h-full flex justify-start items-center w-[20%] ">
                                <h1 className="text-lg font-medium text-[#3D405B]/70">
                                  Akash Sharma
                                </h1>
                              </div>
                              <div className="w-[80%]  h-full"></div>
                            </div>
                          </div>
                          <div className="flex justify-center items-center space-x-6 text-[#3D405B] text-sm font-medium p-6">
                            <p className="">Pages</p>
                            <div className="flex border divide-x rounded-md ">
                              <button className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                                Previous
                              </button>
                              <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                                1
                              </button>
                              <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                                2
                              </button>
                              <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                                3
                              </button>
                              <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                                4
                              </button>
                              <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                                5
                              </button>
                              <button className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                                Next
                              </button>
                            </div>
                            <p>2 of 10</p>
                          </div>
                        </div>
                      ) : null} */}
                    </div>
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="pt-40 text-3xl text-center text-gray-400">
                        We have not calculated Manager Insight Data!
                      </div>
                    ) : null}
                  </>
                )}
              </>
            ) : null}

            {optionTypeM !== 101 ? (
              <>
                <IndexComponent
                  indexType={indexType}
                  optionType={optionTypeM}
                  getCommentData={getCommentData}
                  getQuestionData={getQuestionData}
                  Name={
                    optionTypeM === 1
                      ? "Happiness"
                      : optionTypeM === 2
                        ? "Engagement"
                        : optionTypeM === 5
                          ? "Manager"
                          : ""
                  }
                  handleManagerDriver={this.handleManagerDriver}
                  ManagerIndexDriverData={ManagerIndexDriverData}
                  CurrentManagerId={CurrentManagerId}
                  optionTypeM={optionTypeM}
                  ManagerDynaObj={ManagerDynaObj}
                  EmpFilterData={EmpFilterData}
                  CurrentManagerData={CurrentManagerData}
                  crrManagersList={crrManagersList}
                  QuesDynaObj={QuesDynaObj}
                  handleManagerQuestion={this.handleManagerQuestion}
                  ManagerIndexQuestionData={ManagerIndexQuestionData}
                  ManagerName={ManagerName1}
                  CurrentManagerName={CurrentManagerName}
                  loading={loading}
                  ManagerListQuestionDataObj={NEW_ManagerListQuestionDataObj}
                  showMultiple={showMultiple}
                  DemographicDriverManagerData={DemographicDriverManagerData}
                  temShowMultiple={temShowMultiple}
                  ParaLabelList={
                    optionTypeM === 1
                      ? IndexDimension.Happiness
                      : optionTypeM === 2
                        ? IndexDimension.Engagement
                        : optionTypeM === 4
                          ? IndexDimension.Stress
                          : optionTypeM === 5
                            ? IndexDimension.Manager
                            : []
                  }
                  IndexDriverData={
                    optionTypeM === 1
                      ? getHappinessIndexDriverData
                      : optionTypeM === 2
                        ? getEngagementIndexDriverData
                        : optionTypeM === 4
                          ? getStressIndexDriverData
                          : optionTypeM === 5
                            ? getManagerIndexDriverData
                            : {}
                  }
                  getQuestionOverallData={getQuestionOverallData}
                  DemographicRestriction={DemographicRestriction}
                  GetParameterLabel={GetParameterLabel}
                  OrgLevelDriverScore={this.OrgLevelDriverScore}
                  getQuestionManagerPIDWiseData={getQuestionManagerPIDWiseData}
                  handleInnerOption={this.handleInnerOption}
                  optionTypeInner={this.state.optionTypeInner}
                  getIndexDriverManagerPIDWiseData={
                    getIndexDriverManagerPIDWiseData
                  }
                  handleManagersCall={this.handleManagersCall}
                />
              </>
            ) : null}

            {this.state.showManagerReportModal ? (
              <ReportModal
              getAllBenchMarkStaticData={getAllBenchMarkStaticData}
              CurrentSurveyId={CurrentSurveyId}
                onCloseManagerReportModal={this.onManagerReportModal}
                getDemographicIndexForManagerData={
                  getDemographicIndexForManagerData
                }
                getSurveyData={getSurveyData}
                SelectManager={this.SelectManager}
                EmpFilterData={EmpFilterData}
                DynaObj={DynaObj}
                getIndexData={getIndexData}
                getReportMngrQuestion={this.getReportMngrQuestion}
                getReportMngrDriver={this.getReportMngrDriver}
                QuesDynaObj={QuesDynaObj}
                getQuestionReportData={getQuestionReportData}
                QuesMngrHappinessReportObject={QuesMngrHappinessReportObject}
                QuesMngrEngagementReportObject={QuesMngrEngagementReportObject}
                QuesMngrStressReportObject={QuesMngrStressReportObject}
                QuesMngrManagerReportObject={QuesMngrManagerReportObject}
                DriverMngrHappinessReportObject={
                  DriverMngrHappinessReportObject
                }
                DriverMngrEngagementReportObject={
                  DriverMngrEngagementReportObject
                }
                DriverMngrStressReportObject={DriverMngrStressReportObject}
                DriverMngrManagerReportObject={DriverMngrManagerReportObject}
                DemographicRestriction={DemographicRestriction}
                validateList={validateList}
                getHappinessIndexDriverData={getHappinessIndexDriverData}
                getEngagementIndexDriverData={getEngagementIndexDriverData}
                getStressIndexDriverData={getStressIndexDriverData}
                getManagerIndexDriverData={getManagerIndexDriverData}
                getDemographicIndexHappinessData={
                  getDemographicIndexHappinessData
                }
                getDemographicIndexEngagementData={
                  getDemographicIndexEngagementData
                }
                getDemographicIndexStressData={getDemographicIndexStressData}
                getDemographicIndexManagerData={getDemographicIndexManagerData}
                getDemographicIndexLeaderData={getDemographicIndexLeaderData}
                getHappinessQuestionData={getHappinessQuestionData}
                getHappinessManagerQuestionData={
                  getHappinessManagerQuestionData
                }
                getHappinessDMGQuestionData={getHappinessDMGQuestionData}
                getEngagementQuestionData={getEngagementQuestionData}
                getEngagementManagerQuestionData={
                  getEngagementManagerQuestionData
                }
                getEngagementDMGQuestionData={getEngagementDMGQuestionData}
                getStressQuestionData={getStressQuestionData}
                getStressManagerQuestionData={getStressManagerQuestionData}
                getStressDMGQuestionData={getStressDMGQuestionData}
                getManagerQuestionData={getManagerQuestionData}
                getManagerManagerQuestionData={getManagerManagerQuestionData}
                getManagerDMGQuestionData={getManagerDMGQuestionData}
                getLeaderQuestionData={getLeaderQuestionData}
                getLeaderManagerQuestionData={getLeaderManagerQuestionData}
                getLeaderDMGQuestionData={getLeaderDMGQuestionData}
                IndexDimension={IndexDimension}
                onLoadManagerReport={onLoadManagerReport}
                GetParameterLabel={GetParameterLabel}

                getHappinessIndexByFilterData={getHappinessIndexByFilterData}

                getEngagementIndexByFilterData={getEngagementIndexByFilterData}

                getStressIndexByFilterData={getStressIndexByFilterData}

                
                getManagerIndexByFilterData={getManagerIndexByFilterData}


                getLeaderIndexByFilterData={getLeaderIndexByFilterData}


                surveyLogo={surveyLogo}
                ManagerReportFConfig={ManagerReportFConfig}
                getOverallScoreBySurveyIdData={getOverallScoreBySurveyIdData}
                onManagerDownloadStatus={onManagerDownloadStatus}
                getMngrReportDownloadStatusData={
                  getMngrReportDownloadStatusData
                }
                getMngrReportDownloadStatusTotal={
                  getMngrReportDownloadStatusTotal
                }
                getOrgQuestionData={getOrgQuestionData}
                getOrgManagerQuestionData={getOrgManagerQuestionData}
                getOrgDMGQuestionData={getOrgDMGQuestionData}
                allowSpecificFn={allowSpecificFn}
                getDemographicIndexOrgData={getDemographicIndexOrgData}
                getQuestionOthersResultData={getQuestionOthersResultData}

                getManagerIndexByFilterV2Data={getManagerIndexByFilterV2Data}
                getEngagementIndexByFilterV2Data={getEngagementIndexByFilterV2Data}
                getDemographicIndexEngagementV2Data={getDemographicIndexEngagementV2Data}
                getDemographicIndexManagerV2Data={getDemographicIndexManagerV2Data}
                getMngrTeamSizeData={getMngrTeamSizeData}
                
              />
            ) : null}
          </div>

          {/* <div className="items-center w-full mb-4 bg-white border md:flex">
        <div className="relative flex items-center w-full p-4 text-white bg-pink-500 md:w-52 md:h-40 md:text-center md:justify-center">
          <div><h1 className="text-base font-medium md:text-xl md:font-light md:pb-4">Happiness</h1>
            <h1 className="absolute text-xl font-light md:text-3xl md:font-normal md:relative md:right-0 right-4 md:top-0 top-6">61.75%</h1>
            <p className="text-xs font-light md:font-normal">Overall</p></div>
        </div>
        <div className="flex items-center justify-between w-full p-4 overflow-x-auto">
          <div className="border-4 border-blue-500 rounded-full h-28 w-28">
          </div>
        </div>
      </div> */}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(ManagerList);
