import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import { CONST } from '../../../_config';

class TimeSeriesChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  openChartList = () => {
    if (this.state.showChartList) {
      this.setState({ showChartList: false })
    }
    else {
      this.setState({ showChartList: true })
    }
  }

  handleChartType = (item) => {
    this.setState({ chartTypeName: item })
  }

  render() {
    let { getIndexByFilterAllStagesData, name,
      getRespondantAllLifecycleDetailsData,

      getCategoryOverallIndexData, allIndexList, indexDataX

    } = this.props;


    let dataItemMain = []

    let surveyNameList = []
    let IndexNameList = []
    let SeriesItemList = []

    let colorCodeObj = {
      "1": "#FF4081",
      "2": "#2196F3",
      "4": "#EF5350",
      "5": "#9C27B0",
      "6": "#FFB300",
    }



    function getIndexNumber(name){
      let template = null
      if(allIndexList && allIndexList.length>0){
        let getIndex = allIndexList.findIndex(prev=>prev.name === name);
        if(getIndex !==-1){
          template = allIndexList[getIndex]["template"]
        }
      }
      return template
    }
    
    let surveyNameListV2 = []

    if (getCategoryOverallIndexData && getCategoryOverallIndexData.length > 0) {
      getCategoryOverallIndexData.forEach((item) => {
        let surveyName = item && item.surveyData && item.surveyData.name?item.surveyData.name:"";
        if(surveyName){
          surveyNameListV2.push(surveyName)
        }
      })
    }

    
    function getSurveyScore(indexTemplate){
      let scoreList = []
      let dataSourceList = []
      if(surveyNameListV2 && surveyNameListV2.length>0){
        surveyNameListV2.forEach((surveyName)=>{
          if (getCategoryOverallIndexData && getCategoryOverallIndexData.length > 0) {
            getCategoryOverallIndexData.forEach((dataItem) => {
              if(dataItem.surveyData.name === surveyName){
                let score = dataItem && dataItem.scoreData && dataItem.scoreData[indexTemplate]?dataItem.scoreData[indexTemplate]:null;
                let scorefINAL = score? score.toFixed(2):null
                scoreList.push(scorefINAL)
                dataSourceList.push({ name: '周最低', value: Number(scorefINAL), xAxis: 1, yAxis: -1.5 })

              }
            })
          }
        })
      }
      return {
        "scoreList":scoreList,
        "dataSourceList":dataSourceList
      }
    }



    function getMarkPoints(scoreListV1){
      let happyEmoji = 'image://'+CONST.BACKEND_URL.replace('5000','3000').replace('/api/v1','')+'/img/emojis/happy.png';
      let sadEmoji = 'image://'+CONST.BACKEND_URL.replace('5000','3000').replace('/api/v1','')+'/img/emojis/sad.png';

      let list = []
      if(scoreListV1 && scoreListV1.length>0){
        scoreListV1.forEach((value,index)=>{
          if(value>0){
            let temp = {
              name: 'coordinate',
              coord: [index, Number(value)],
              symbol:(Number(value)<70)?sadEmoji:happyEmoji,
              symbolSize:25
  
            }
            list.push(temp)
          }
        })
      }

      return list
    }


    let IndexNameListV2 = []
    let SeriesItemListV2 = []
    if(indexDataX && indexDataX.length>0){
      indexDataX.forEach((item)=>{
        let IndexName = item.name;
        let IndexNumber = getIndexNumber(item.name);
        IndexNameListV2.push(IndexName)

        SeriesItemListV2.push({
          name: IndexName,
          type: 'line',
          // stack: 'Total',
          color: colorCodeObj[IndexNumber],
          data: getSurveyScore(IndexNumber)["scoreList"],
          markPoint: {
            data:getMarkPoints(getSurveyScore(IndexNumber)["scoreList"])
          }
        })

      })
    }



    console.log("IndexNameListV2-------------->",IndexNameListV2)
    console.log("surveyNameListV2-------------->",surveyNameListV2)
    console.log("SeriesItemListV2-------------->",SeriesItemListV2)
    console.log("getRespondantAllLifecycleDetailsData-------------->",getRespondantAllLifecycleDetailsData)

    let surveyNameListV3 = []
    if(surveyNameListV2 && surveyNameListV2.length>0){
      surveyNameListV2.forEach((element,index)=>{
        if(index === 0){
          surveyNameListV3.push("..   "+element)
        }else if(index === (surveyNameListV2.length-1)){
          surveyNameListV3.push(element+"  ..")
        }else{
          surveyNameListV3.push(element)
        }
      })
    }
    


    function getEmpCount(surveyName,list){

      let DateItem = {
        'mailSent':0,
        'completed':0,
        'reponserate':0
      }
      if(list && list.length>0){
        let getIndex = list.findIndex(prev=>prev.surveyName === surveyName);
        if(getIndex!==-1){
          DateItem = list[getIndex]["Data"];
          // console.log("list[getIndex]---->",list[getIndex])
        }
      }
      let value = (Number(DateItem["completed"])*100)/Number(DateItem["mailSent"]);
      return {
        "mailSent":Number(DateItem["mailSent"]),
        "completed":Number(DateItem["completed"]),
        "reponserate":(value && value > 0 ? value.toFixed():0).toString()+"%"
      }
    }


    return (
      <>
        {/* <div className="stack-title">
            {currFilterName} 
          </div> */}
        <div className="p-6 bg-white border stack-body">
          
        <h1 class="font-medium xl:text-xl pb-4 text-lg text-[#3D405B] whitespace-nowrap">Employee Life Cycle</h1>
        
          {true ?
            <>
              <ReactEcharts
                style={{
                  height: '350px',
                  paddingLeft:'20px',
                  paddingRight:'20px'
                }}
                option={{
                  // title: {
                  //   text: 'Stacked Line'
                  // },

                  tooltip: {
                    trigger: 'axis',
                    formatter: function(list){
                      let surveyItem = list[0]['name']?list[0]['name'].replace("___","").trim():"";


                      let value = `<p><b>${surveyItem}</b></p></b>`;

                      
                      value+=`<p style="color:${"#757575"}">${"Survey Launched =  "+(getEmpCount(surveyItem,getRespondantAllLifecycleDetailsData))["mailSent"]}</p></b>`
                      value+=`<p style="color:${"#757575"}">${"Survey Responded = "+(getEmpCount(surveyItem,getRespondantAllLifecycleDetailsData))["completed"]}</p></b>`
                      value+=`<p style="color:${"#757575"}">${"Survey Responde Rate = "+(getEmpCount(surveyItem,getRespondantAllLifecycleDetailsData))["reponserate"]}</p></b>`

                      if(list && list.length>0){
                        list.forEach((element)=>{

                          if(element['data']){
                            value+=`<p style="color:${element['color']}">${element['seriesName']+" Score - "+(element['data'])}</p></b>`
                          }

                        })
                      }



                      return (value)

                    } 
                  },
                  legend: {
                    data: IndexNameListV2
                  },
                  grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                  },
                  toolbox: {
                    feature: {
                      saveAsImage: {}
                    }
                  },
                  xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: surveyNameListV3,
                    axisLabel: {
                      color: "#000",
                      fontStyle: "bold"
                    },
                  },
                  yAxis: {
                    type: 'value',
                    graphic:{
                      type: 'rect'
                    }
                  },
                  series: SeriesItemListV2
                }}

              />



                <div className='flex items-center justify-center p-4 text-center'>
                  <p><b className='font-bold'>X-Axis: </b>Employee Life Cycle Surveys,</p>
                  <p className='px-4'><b className='font-bold'>Y-Axis: </b>Score</p>
                </div>
            </>
            : null}
        </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(TimeSeriesChart);
