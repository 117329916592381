import React from 'react';
import ParameterView from '../Parameter/ParameterView';
import { TbTrash, TbPlus, TbStar, TbStarFilled } from 'react-icons/tb';
export default function RatingScale(props) {
  let { SurveyTemplateId, question, quesindex, currQuesEditId, showTranslatePage, translShortName,
    translQuestion, handleTranslateQuestion, onTranslateQuestionSave, handleNudgeModal,

    handleQuestionInput, handleOptionInput, handleWeightageInput, onDeleteOption, onAddNewOption, onQuesMandatory, onSaveQues,
    innerEdit, handleQuesCancel, GetParameterLabel, dashType1Check, handleQuestionDesign
  } = props;


  let type = (!(question && question["designId"]) || (question && question["designId"] && question["designId"] === "star") ? "Star " : ((question && question["designId"] && question["designId"] === "number") ? "Number " : "Star "))
  let typeName = (!(question && question["designId"]) || (question && question["designId"] && question["designId"] === "star") ? "star" : ((question && question["designId"] && question["designId"] === "number") ? "number" : "star"))

  let [showBotResEdit, handleBotRespEdit] = React.useState(false);

  return (
    <>
      {currQuesEditId === question.id ?
        <>
          {showTranslatePage ?
            <>
              <div className="flex justify-between items-center">
                <div className="text-sm font-medium text-[#212121]">Question</div>
                <div className="flex px-2">
                  <div onClick={() => onTranslateQuestionSave(true, translShortName)} className="py-1 px-3 rounded-sm border border-1 border-green-500 cursor-pointer text-sm text-green-500 mr-2">Save</div>
                  <div onClick={() => onTranslateQuestionSave(false, translShortName)} className="py-1 px-2 rounded-sm border border-1 border-red-500 cursor-pointer text-sm text-red-500">Cancel</div>
                </div>                    </div>
              <input
                type="text"
                value={question.name}
                onChange={(event) => handleQuestionInput(event.target.value, question.id)}
                placeholder={"Q." + (quesindex + 1) + " " + question.name}
                className="py-2 px-4 border outline-none w-full mt-2 rounded-md"
              />
              <input
                type="text"
                value={translQuestion["transl"][translShortName].name}
                onChange={(event) => handleTranslateQuestion(event.target.value, question.id, translShortName)}
                placeholder="Translate here..."
                className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2"
              />
              <hr />


            </>
            :
            <>

              <div className="flex justify-between items-center">
                <div className="text-sm font-medium text-[#212121]">Question</div>
                <div onClick={() => handleQuesCancel(false)} className="py-1 px-2 rounded-sm border border-1 border-red-500 cursor-pointer text-sm text-red-500 mr-2">Cancel</div>
              </div>
              <input
                type="text"
                value={question.name}
                onChange={(event) => handleQuestionInput(event.target.value, question.id)}
                placeholder={"Q." + (quesindex + 1) + " " + question.name}
                className="py-2 px-4 border outline-none w-full mt-2 rounded-md"
              />
              <hr />
              <ul className="pb-4">
                {/* <p className="text-xs text-gray-500 pb-2">Answer</p> */}
                <div className="mb-1.5 mt-2" >


                  <div className='flex justify-between px-2'>
                    <div className="py-1" >
                      <input
                        id="mandatory-check"
                        value={question.isMandatory}
                        checked={question.isMandatory}
                        type="checkbox"
                        onChange={() => { }}
                        className="cursor-pointer ml-1"
                        onClick={() => onQuesMandatory(question.isMandatory, question.id)}
                      />
                      <label htmlFor="mandatory-check" className="text-sm text-gray-500 pb-8 pl-2">Mandatory</label>

                    </div>

                    <div className="py-1 flex space-x-2" >
                      <div onClick={() => handleNudgeModal(question, true)}
                        className='cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md '>
                        Add Nudge</div>
                      <div onClick={() => handleQuestionInput(" [variable] ", question.id, true)}
                        className='cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md '>
                        Add Variable</div>
                      <div onClick={() => handleBotRespEdit(!showBotResEdit)} className={showBotResEdit ?
                        'cursor-pointer text-sm py-2 px-4 font-medium bg-yellow-500 text-white rounded-md ' :
                        'cursor-pointer text-sm py-2 px-4 font-medium bg-purple-500 text-white rounded-md '
                      }>Bot Edit</div>
                    </div>

                  </div>



                  {/* <Parameter
                        showBucketDropdown={showBucketDropdown} 
                        parameterList={parameterList}
                        handleParameterBucket={handleParameterBucket}
                        handleParameterQuestion={handleParameterQuestion}
                        getParameterListbyParentData={getParameterListbyParentData}
                        paraBucketName={paraBucketName}
                        question={question}
                        showDimensionDropdown={showDimensionDropdown}
                        paraDimensionName={paraDimensionName}
                        handleParameterDimension={handleParameterDimension}
                        GetParameterLabel={GetParameterLabel}
                        SurveyTemplateId={SurveyTemplateId}
                        /> */}


                </div>


                <div className='flex p-2 mt-6'>
                  <div onClick={() => handleQuestionDesign(question.id, "star")} className={'text-white px-2 p-1 rounded-lg mx-2 text-xs cursor-pointer ' + (!(question && question["designId"]) || (question && question["designId"] && question["designId"] === "star") ? 'bg-blue-500' : 'bg-gray-500')}>Default (Star)</div>
                  <div onClick={() => handleQuestionDesign(question.id, "number")} className={'text-white px-2 p-1 rounded-lg mx-2 text-xs cursor-pointer ' + (question && question["designId"] && question["designId"] === "number" ? 'bg-blue-500' : 'bg-gray-500')}>Number</div>
                </div>

                <div className="flex ml-1 pt-4 mt-2">
                  <div className="text-sm font-medium w-8/12 mr-16 text-[#212121]">Options</div>
                  {dashType1Check ?
                    <div className="text-sm font-semibold w-2/12 text-gray-400">Weightage</div>
                    : null}
                </div>

                {question && question.optionsList && question.optionsList.length ?
                  question.optionsList.map((option, optionindex) =>
                    <>
                      <div className="flex items-center justify-between py-2">
                        {/* <input type="radio" name="ans" className="w-4 h-4 " /> */}
                        <div className=" w-full">
                          <input
                            type="text"
                            value={type + (optionindex + 1).toString()}
                            onChange={() => { }}
                            placeholder={option.name}
                            className="border border-gray-500 outline-none  px-4 text-sm py-1.5 w-2/12 mr-4"
                            disabled={true}
                          />
                          <input
                            type="text"
                            value={option.name}
                            onChange={(event) => handleOptionInput(event.target.value, question.id, option.id)}
                            placeholder={option.name}
                            className="border border-gray-500 outline-none  px-4 text-sm py-1.5 w-6/12 mr-4"
                          />
                          {dashType1Check ?
                            <input
                              type="number"
                              value={option.weightage}
                              onChange={(event) => handleWeightageInput(event, question.id, option.id)}
                              className="border outline-none text-center px-2 text-sm py-1.5 w-2/12 "
                            /> : null}

                        </div>
                        <div className="-ml-6">
                          <button onClick={() => onDeleteOption(question.id, option.id)} className="cursor-pointer border rounded-full w-8 h-8 inline-flex items-center justify-center  text-[#212121]  hover:bg-red-100 hover:text-red-500">
                            <TbTrash />
                          </button>
                        </div>
                      </div>

                      {showBotResEdit ?

                        <div className="flex items-center justify-between py-2">
                          <div className='mr-2'>Bot: </div>
                          <div className=" w-full">
                            <input
                              type="text"
                              value={option.bot}
                              onChange={(event) => handleOptionInput(event.target.value, question.id, option.id, "bot")}
                              placeholder={option.bot}
                              className="border border-purple-500 outline-none  px-4 text-xs py-1.5 w-full mr-4"
                            />

                          </div>
                        </div>
                        : null}


                    </>
                  )
                  : null}
              </ul>

              <div className="flex justify-center">
                <span onClick={() => onAddNewOption(question)} className="cursor-pointer text-sm text-[#2196f3] uppercase font-medium flex items-center"><TbPlus /> Add more options</span>
              </div>


              <div className="flex justify-center pb-2 pt-8">
                <span className="cursor-pointer text-sm rounded uppercase   bg-[#2196f3] py-2 px-4 text-white w-28  mx-1 text-center" onClick={() => onSaveQues(question)}> save</span>
              </div>

            </>}

        </>
        :
        <>
          <div title={(question && question.isNonEdit ? "" : "Double Click to Edit!")} onDoubleClick={() => innerEdit(question, SurveyTemplateId)} className="space-y-4">
            <ParameterView question={question} GetParameterLabel={GetParameterLabel} />
            <div className="text-slate-800 font-semibold text-sm inline-flex">{question.parentId ? <p className="pr-2">{" - "}</p> : <p className="pr-2">Q{quesindex + 1}.</p>}  <h1>{question.name}{(question.isMandatory) ? '*' : null}</h1> </div>


            {typeName === "star" ?
              <ul className=" pl-6 flex space-x-4">
                {question && question.optionsList && question.optionsList.length ?
                  question.optionsList.map((option, optionindex) =>
                    <TbStarFilled className='text-[1.5rem] text-yellow-400' />
                  )
                  : null}
              </ul>
              : null}

            {typeName === "number" ?
              <ul className=" pl-6 flex">
                {question && question.optionsList && question.optionsList.length ?
                  question.optionsList.map((option, optionindex) =>
                    <>
                      <div className="font-semibold   border-2 border-gray-400 p-2 mx-2 my-1 rounded-md" >{optionindex + 1}</div>
                    </>
                  )
                  : null}
              </ul>
              : null}



          </div>
        </>
      }
    </>
  );
}