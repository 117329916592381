import React, { Component } from 'react';
import { connect } from 'react-redux';
import Filter from './Filter';
import HeatMap from './Charts/HeatMap';

class QuestionAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue:'TQM',
      currFilterName:'Department'
    }
  }
  componentDidMount() {

  }

  handleFilterSave=(name)=>{
    this.setState({currFilterName:name});
    let { handleQuestionMeanScore } = this.props;
       handleQuestionMeanScore(name);

  }

  handleFilterValueSave=(name,value)=>{
    this.setState({currFilterValue:value});
    let { handleQuestionMeanFilter } = this.props;
    // if(name !=='none' && value !=='none'){
    //   handleQuestionMeanFilter(name,value)
    // }
  }

  render() {
    let { getIndexFilterData, cardType,ItemSaveData,EmpFilterData,handleDemographicOverallQFilter, getMeanScoreQwiseData } = this.props;
    let { currFilterName, currFilterValue } = this.state;

    // //console.log('getMeanScoreQwiseData:',getMeanScoreQwiseData);

    let FilterList = []
    if(getMeanScoreQwiseData && getMeanScoreQwiseData.length>0){
      getMeanScoreQwiseData.forEach((ques)=>{
          if(FilterList.findIndex(prev=>prev === ques.dmgName)===-1 && ques && ques.dmgName){
            FilterList.push(ques.dmgName);
          }
    })}


      let QuesMeanScoreList = []


      let MeanScoreQwiseList = []
          if(getMeanScoreQwiseData && getMeanScoreQwiseData.length>0){
            getMeanScoreQwiseData.forEach((ques)=>{


                if(QuesMeanScoreList.findIndex(prev=>prev.questionName === ques.questionName) ===-1){

                  let data = {
                    ...ques,
                    demographic:[]
                  }

                  if(ques && ques.dmgName){
                    data["demographic"].push({
                      "dmgName":ques.dmgName,
                      "score":ques.score
                    })
                  }


                  QuesMeanScoreList.push(data);

                  
                }
                else{

                  let getIndex = QuesMeanScoreList.findIndex(prev=>prev.questionName === ques.questionName);

                  if(ques && ques.dmgName){
                    QuesMeanScoreList[getIndex]["demographic"].push({
                      "dmgName":ques.dmgName,
                      "score":ques.score
                    })
                  }



                }

                // if(ques.questionId === ){

                // }

                if(cardType==='overall'){
                  MeanScoreQwiseList.push(ques);
                }
                else if(cardType==='index'){
                    if(ItemSaveData && ItemSaveData.name && (ques.parameterBucketName === ItemSaveData.name)){
                      MeanScoreQwiseList.push(ques);
                    }
                }
            })
          }


    let NewQuesMeanList = []

        if(QuesMeanScoreList && QuesMeanScoreList.length>0){
          QuesMeanScoreList.forEach((ques)=>{
            let data2 ={
              ...ques,
              demographic:[],
              quesMean:0
            }

            if(FilterList && FilterList.length>0){
              FilterList.forEach((fil)=>{
                if(ques && ques.demographic.length>0){
                  ques.demographic.forEach((item)=>{
                    if(item.dmgName === fil){

                      if(fil){
                        data2["demographic"].push({
                          "dmgName":fil,
                          "score":item.score
                        });
                        data2["quesMean"]+=item.score
                      }

                    }
                  })
                }
              });

              data2["quesMean"] = data2["quesMean"] / FilterList.length;

            }



            if(cardType==='overall'){
              NewQuesMeanList.push(data2);
            }
            else if(cardType==='index'){
                if(ItemSaveData && ItemSaveData.name && (ques.parameterBucketName === ItemSaveData.name)){
                  NewQuesMeanList.push(data2);
                }
            }




          });
   
    }



    // //console.log('FilterList:',FilterList)
    //console.log('NewQuesMeanList: ',NewQuesMeanList);


    if(NewQuesMeanList )
    

    var overallQuestion = {
      "score":0
    }
    if(ItemSaveData && ItemSaveData.mean){
      overallQuestion["score"] = parseFloat(ItemSaveData.mean);
    }

    // //console.log('MeanScoreQwiseList:',MeanScoreQwiseList);


    return (
      <>
     <div className="xl:flex lg:flex md:flex justify-end items-center">


          <Filter 
                    EmpFilterData = {EmpFilterData}
                    
                    FilterList={FilterList}

                    handleDemographicFilter={handleDemographicOverallQFilter}

                    handleFilterSave={this.handleFilterSave}

                    handleFilterValueSave={this.handleFilterValueSave}

                    // FilterValueList={FilterValueList}
        
                  />

        </div>  

    
        <div className="container mx-auto px-2">
          <div className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 xl:grid-cols-1  gap-2">
            
                        <div className="bg-white rounded w-full shadow-lg px-4">
                            {/* <h1 className="px-2 py-4 text-base text-gray-700 font-semibold ">{" By "+currFilterName}</h1>  */}
                            <div className=" pt-4 text-base text-gray-700 font-semibold flex justify-between items-center">
                              <h1 className="pb-1 text-base text-gray-700 font-semibold ">{overallQuestion && overallQuestion.score?" Overall Mean: "+overallQuestion.score+"":""}</h1> 
                              <div className="text-lg">
                                {/* <i className="show Table" onClick={()=>this.handleViewType(false)} style={{opacity:(showHeatMap)?1:0.3}} className="cursor-pointer fa fa-table mx-2"></i>  */}
                                {/* <i className="show Radar" onClick={()=>this.handleViewType(true)} style={{opacity:(!showHeatMap)?1:0.3}} className="cursor-pointer fa fa-snowflake-o mx-2"></i>  */}
                              </div>

                            </div>

                            {MeanScoreQwiseList && MeanScoreQwiseList.length>0?
                              <HeatMap 
                                MeanScoreQwiseList={MeanScoreQwiseList}
                                QuesMeanScoreList={NewQuesMeanList}
                                EmpFilterData = {EmpFilterData}
                                FilterList={FilterList}
                                currFilterName={currFilterName}
                                ItemSaveData={ItemSaveData}
                                cardType={cardType}
                              />
                              :null}
                        </div>
            
          
          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionAnalysis);
