import { React, useState } from "react";

export default function Sidebar() {

    const [activeTab, setActiveTab] = useState("Dashboard");

    const handleTabClick = (tab) => {
        // Set the active tab based on the clicked tab
        setActiveTab(tab);
    };

    return (


        <div
            className="cursor-pointer transition-width w-64 lg:block scollar-xs  lg:relative fixed top-0 left-0  h-screen lg:z-20 z-20 overflow-y-auto overflow-hidden  duration-200 transform bg-white border-r customscroll4 hidden"
            style={{ height: "calc(100% - 0rem)" }}
        >
            <div className="mb-4 whitespace-nowrap ">
                <div
                    data-tip="Dashboard"
                    onClick={() => handleTabClick("Dashboard")}
                    className={activeTab === "Dashboard" ? "flex items-center hover:bg-[#2196F3]/10 text-[#2196F3] cursor-pointer border-l-4 border-[#2196F3] bg-[#2196F3]/10" : "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer"}
                    currentitem="false"
                >
                    <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
                        <span className="p-2 material-symbols-outlined">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-layout-dashboard"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                strokeWidth="1.8"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M4 4h6v8h-6z" />
                                <path d="M4 16h6v4h-6z" />
                                <path d="M14 12h6v8h-6z" />
                                <path d="M14 4h6v4h-6z" />
                            </svg>
                        </span>
                    </div>
                    <span className="text-sm  ">Dashboard</span>
                </div>

                <div
                    title="Nudge"
                    onClick={() => handleTabClick("Normalization")}
                    className={activeTab === "Normalization" ? "flex items-center hover:bg-[#2196F3]/10 text-[#2196F3] cursor-pointer border-l-4 border-[#2196F3] bg-[#2196F3]/10" : "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer"}
                >
                    <div className="flex items-center px-4 py-4 w-16    h-12 justify-center">
                        <span className="p-2 material-symbols-outlined">
                            dashboard
                        </span>
                    </div>
                    <span className=" text-sm   ">Normalization</span>
                </div>
                <div
                    title="Nudge"
                    onClick={() => handleTabClick("Box")}
                    className={activeTab === "Box" ? "flex items-center hover:bg-[#2196F3]/10 text-[#2196F3] cursor-pointer border-l-4 border-[#2196F3] bg-[#2196F3]/10" : "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer"}
                >
                    <div className="flex items-center px-4 py-4 w-16     h-12 justify-center">
                        <span className="p-2 material-symbols-outlined">
                            dashboard
                        </span>
                    </div>
                    <span className="text-sm">Nine-Box Matrix</span>
                </div>
            </div>
        </div>

    );


}