import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { GoGoal } from "react-icons/go";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurveyName: "",
      currentSurveyId: "",
      showDropdown: false,
      gameType: "",
      showElement: false,
    };
  }
  componentDidMount() { }


  render() {


    return (
      <>
        <div className="fixed top-0 left-0 z-20 w-64 h-screen duration-200 transform bg-black border-r cursor-pointer transition-width lg:block lang lg:relative "
          style={{ height: "calc(100% - 0rem)" }} >
          <div className="my-4 space-y-4 whitespace-nowrap">
            <div className="flex  text-white transition-all duration-200 cursor-pointer bg-[#009EA7]">
              <div className="flex items-center px-6 py-2 space-x-4 text-white transition-all duration-200 cursor-pointer bg-[#009EA7]">
                <div><GoGoal className="w-5 h-5" /></div>
                <span>Appraisal Cycle</span>
              </div>
            </div>
          </div>
        </div >
      </>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(Sidebar);
