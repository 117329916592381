import React, { Component } from 'react';
import { connect } from 'react-redux';

import WordCloud from './Components/WordCloud/WordCloud';
import AnswerFeedback from './Components/AnswerFeedback/AnswerFeedback';

import AdvanceWordCloud from './Components/AdvanceWordCloud/AdvanceWordCloud';
import Network from './Components/Network/Network';
import TreeMap from './Components/TreeMap/TreeMap';
import TextDistribution from './Components/TextDistribution/TextDistribution';
import TopicModelling from './Components/TopicModelling/TopicModelling';
import TextCluster from './Components/TextCluster/TextCluster';
import TopicCloud from './Components/TopicCloud/TopicCloud';

class AdvanceComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 1
    }
  }
  componentDidMount() {

  }

  onOptionType = (val) => {
    this.setState({ optionType: val })
    let { EmpFilterData, onTextCluster, onNeightboardGraph, onNetworkGraph, onTopicModel, onAdvanceWorldCloud, onWorldCloud, onFeedbackCloud, onTreeMap, onTextWordDistribution, onTopicCloud } = this.props;
    let EmpFilterData2 = EmpFilterData;
    if (EmpFilterData2 && EmpFilterData2.length > 0) {
      EmpFilterData2.sort((a, b) => (a.name.charCodeAt(0) - b.name.charCodeAt(0)))
    }
    if (val === 1) {
      onWorldCloud(1);
    }
    else if (val === 2) {
      onNeightboardGraph();
    }
    else if (val === 3) {
      onAdvanceWorldCloud(3);
    }
    else if (val === 4) {
      onTreeMap(EmpFilterData2 && EmpFilterData2.length > 0 ? EmpFilterData2[0]["name"] : "Gender");
    }
    else if (val === 5) {

      onTextWordDistribution(EmpFilterData2 && EmpFilterData2.length > 0 ? EmpFilterData2[0]["name"] : "Gender");
    }
    else if (val === 6) {
      onTopicModel("topicModelling");
    }
    else if (val === 7) {
      onFeedbackCloud();
    }
    else if (val === 8) {
      onTextCluster(3);
    }
    else if (val === 9) {
      onTopicCloud(9);
    }
  }

  onSwitchOptionScreen = () => {
    let {
      GetParameterLabel,
      getClusterSaveKeyWord,
      getClusterSentencesData,
      getClusterSentences,
      getClusterKeywordsData,
      getTextClusterData,
      getTextClusterSentimentCountData,

      getDemographicIndexHappinessDataTreeMap, getDemographicIndexEngagementDataTreeMap,
      getDemographicIndexStressDataTreeMap, getDemographicIndexManagerDataTreeMap,
      getDemographicIndexLeaderDataTreeMap, getDemographicIndexMultiFilterDataTreeMap,

      getIndexData, topicModellingData, onCreateTopicSubmit,


      getHappinessIndexDriverData, getEngagementIndexDriverData, getStressIndexDriverData,
      getManagerIndexDriverData, getLeaderIndexDriverData,




      CountRestrict, AdvanceWordCloudData, textDistData, indexType, getCommentData, loading, callQuestionNetworkCloud,
      handleNeighborGraph, neighborGraphData, handleNetworkDemographicFilter, cooccurmatrixData,
      callWordCloudText, callQuestionWordCloud, wordCloudTextData, wordTextKeyWord, getTextQuestionData,
      handleChangeQuestion, wordCloudData, EmpFilterData, getIndexFilterData, handleWordCloudDemographicFilter,
      wordCloudMultiTextData, wordMultiTextKeyWord, TreeMapData, textDistTreeMapData, getEMPFilterLabel, getIndexByFilterDMGData,

      getTopicListData,

      wordCloudError,
      neighborGraphError,
      topicModellingError,
      TreeMapError,
      textDistError,

      ErrorOtherObj,

      AnswerFeedbackTextData,
      AnswerFeedbackTextKeyWord,
      getFeedbackTextQuestionData,
      callAnswerFeedbackText,
      handleAnswerFeedbackDemographicFilter,
      getOrgCoreDriverSentimentData,

      getKeywordsByCluster,
      getTextClusterFilter,
      topicCloudData,

      addKeywordsAPI,
      handleTopicCloudDemographicFilter,
      callTopicCloudText,
      getTextKeywordsData,
      onTextDistSentence,
      getTextDistHighlightData,


      isDMGText,
      getAllFeedbackWordsData,
      handleDMGCloudDemographicFilter


    } = this.props;

    // console.log("getFeedbackTextQuestionData------------->", getFeedbackTextQuestionData)

    let { optionType } = this.state;

    switch (optionType) {

      case 1: return <WordCloud
        isDMGText={isDMGText}
        getAllFeedbackWordsData={getAllFeedbackWordsData}
        handleDMGCloudDemographicFilter={handleDMGCloudDemographicFilter}

        getTextKeywordsData={getTextKeywordsData}
        addKeywordsAPI={addKeywordsAPI}
        GetParameterLabel={GetParameterLabel}
        ErrorOtherObj={ErrorOtherObj}
        wordCloudError={wordCloudError} getIndexByFilterDMGData={getIndexByFilterDMGData}
        getEMPFilterLabel={getEMPFilterLabel} loading={loading} CountRestrict={CountRestrict}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getTextQuestionData={getTextQuestionData} handleChangeQuestion={handleChangeQuestion}
        wordCloudData={wordCloudData} handleWordCloudDemographicFilter={handleWordCloudDemographicFilter}
        callWordCloudText={callWordCloudText} callQuestionWordCloud={callQuestionWordCloud} wordCloudTextData={wordCloudTextData} wordTextKeyWord={wordTextKeyWord}


      />

      case 2: return <Network
        getTextKeywordsData={getTextKeywordsData}
        addKeywordsAPI={addKeywordsAPI}
        GetParameterLabel={GetParameterLabel}
        ErrorOtherObj={ErrorOtherObj}
        neighborGraphError={neighborGraphError} getIndexByFilterDMGData={getIndexByFilterDMGData}
        getEMPFilterLabel={getEMPFilterLabel} loading={loading} callQuestionNetworkCloud={callQuestionNetworkCloud} CountRestrict={CountRestrict}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        cooccurmatrixData={cooccurmatrixData}
        getTextQuestionData={getTextQuestionData}
        handleNetworkDemographicFilter={handleNetworkDemographicFilter}
        neighborGraphData={neighborGraphData}
        callWordCloudText={callWordCloudText}
        wordCloudTextData={wordCloudMultiTextData}
        wordCloudTextDataOld={wordCloudTextData}
        wordTextKeyWord={wordMultiTextKeyWord}
        wordTextKeyWordOld={wordTextKeyWord}
        handleNeighborGraph={handleNeighborGraph}

        isDMGText={isDMGText}
        getAllFeedbackWordsData={getAllFeedbackWordsData}
        handleDMGCloudDemographicFilter={handleDMGCloudDemographicFilter}

      />

      case 3: return <AdvanceWordCloud
        GetParameterLabel={GetParameterLabel}
        getIndexByFilterDMGData={getIndexByFilterDMGData}
        getEMPFilterLabel={getEMPFilterLabel} loading={loading} CountRestrict={CountRestrict}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getTextQuestionData={getTextQuestionData} handleChangeQuestion={handleChangeQuestion}
        wordCloudData={AdvanceWordCloudData} handleWordCloudDemographicFilter={handleWordCloudDemographicFilter}
        callWordCloudText={callWordCloudText} callQuestionWordCloud={callQuestionWordCloud} wordCloudTextData={wordCloudTextData} wordTextKeyWord={wordTextKeyWord}
      />

      case 4: return <TreeMap
        GetParameterLabel={GetParameterLabel}
        ErrorOtherObj={ErrorOtherObj}
        TreeMapError={TreeMapError} getIndexByFilterDMGData={getIndexByFilterDMGData}
        getEMPFilterLabel={getEMPFilterLabel} loading={loading} CountRestrict={CountRestrict}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getTextQuestionData={getTextQuestionData} handleChangeQuestion={handleChangeQuestion}
        wordCloudData={wordCloudData} handleWordCloudDemographicFilter={handleWordCloudDemographicFilter}
        callWordCloudText={callWordCloudText} callQuestionWordCloud={callQuestionWordCloud}
        wordCloudTextData={wordCloudMultiTextData} wordTextKeyWord={wordTextKeyWord}

        TreeMapData={TreeMapData}

        getDemographicIndexHappinessDataTreeMap={getDemographicIndexHappinessDataTreeMap}
        getDemographicIndexEngagementDataTreeMap={getDemographicIndexEngagementDataTreeMap}
        getDemographicIndexStressDataTreeMap={getDemographicIndexStressDataTreeMap}
        getDemographicIndexManagerDataTreeMap={getDemographicIndexManagerDataTreeMap}
        getDemographicIndexLeaderDataTreeMap={getDemographicIndexLeaderDataTreeMap}

        getDemographicIndexMultiFilterDataTreeMap={getDemographicIndexMultiFilterDataTreeMap}

        getIndexData={getIndexData}

        getHappinessIndexDriverData={getHappinessIndexDriverData}
        getEngagementIndexDriverData={getEngagementIndexDriverData}
        getStressIndexDriverData={getStressIndexDriverData}
        getManagerIndexDriverData={getManagerIndexDriverData}
        getLeaderIndexDriverData={getLeaderIndexDriverData}

      />

      case 5: return <TextDistribution
        GetParameterLabel={GetParameterLabel}
        ErrorOtherObj={ErrorOtherObj}
        textDistError={textDistError} getIndexByFilterDMGData={getIndexByFilterDMGData}
        loading={loading} CountRestrict={CountRestrict}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getTextQuestionData={getTextQuestionData} handleChangeQuestion={handleChangeQuestion}
        textDistData={textDistData} handleWordCloudDemographicFilter={handleWordCloudDemographicFilter}
        callWordCloudText={callWordCloudText} callQuestionWordCloud={callQuestionWordCloud} wordCloudTextData={wordCloudTextData} wordTextKeyWord={wordTextKeyWord}

        textDistTreeMapData={textDistTreeMapData}
        onTextDistSentence={onTextDistSentence}
        getTextDistHighlightData={getTextDistHighlightData}

        isDMGText={isDMGText}
        getAllFeedbackWordsData={getAllFeedbackWordsData}
        handleDMGCloudDemographicFilter={handleDMGCloudDemographicFilter}


      />

      case 6: return <TopicModelling
        GetParameterLabel={GetParameterLabel}
        ErrorOtherObj={ErrorOtherObj}
        topicModellingError={topicModellingError} getIndexByFilterDMGData={getIndexByFilterDMGData}
        getEMPFilterLabel={getEMPFilterLabel} loading={loading} CountRestrict={CountRestrict}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getTextQuestionData={getTextQuestionData} handleChangeQuestion={handleChangeQuestion}
        wordCloudData={wordCloudData} handleWordCloudDemographicFilter={handleWordCloudDemographicFilter}
        callWordCloudText={callWordCloudText} callQuestionWordCloud={callQuestionWordCloud} wordCloudTextData={wordCloudTextData} wordTextKeyWord={wordTextKeyWord}
        topicModellingData={topicModellingData} getTopicListData={getTopicListData} onCreateTopicSubmit={onCreateTopicSubmit}

      />

      case 7: return <AnswerFeedback
        GetParameterLabel={GetParameterLabel}
        wordCloudData={getOrgCoreDriverSentimentData}
        AnswerFeedbackTextData={AnswerFeedbackTextData}
        wordCloudTextData={AnswerFeedbackTextData}
        wordTextKeyWord={AnswerFeedbackTextKeyWord}
        getTextQuestionData={getFeedbackTextQuestionData}
        callAnswerFeedbackText={callAnswerFeedbackText}
        callWordCloudText={callAnswerFeedbackText}
        handleWordCloudDemographicFilter={handleAnswerFeedbackDemographicFilter}
        callQuestionWordCloud={callQuestionWordCloud}

        ErrorOtherObj={ErrorOtherObj}
        wordCloudError={wordCloudError} getIndexByFilterDMGData={getIndexByFilterDMGData}
        getEMPFilterLabel={getEMPFilterLabel} loading={loading} CountRestrict={CountRestrict}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} handleChangeQuestion={handleChangeQuestion}

      />

      case 8: return <TextCluster
        GetParameterLabel={GetParameterLabel}
        ErrorOtherObj={ErrorOtherObj}
        wordCloudError={wordCloudError} getIndexByFilterDMGData={getIndexByFilterDMGData}
        getEMPFilterLabel={getEMPFilterLabel} loading={loading} CountRestrict={CountRestrict}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getTextQuestionData={getTextQuestionData} handleChangeQuestion={handleChangeQuestion}
        wordCloudData={wordCloudData} handleWordCloudDemographicFilter={handleWordCloudDemographicFilter}
        callWordCloudText={callWordCloudText} callQuestionWordCloud={callQuestionWordCloud} wordCloudTextData={wordCloudTextData} wordTextKeyWord={wordTextKeyWord}

        getTextClusterData={getTextClusterData}
        getTextClusterSentimentCountData={getTextClusterSentimentCountData}
        getKeywordsByCluster={getKeywordsByCluster}
        getClusterKeywordsData={getClusterKeywordsData}
        getClusterSentences={getClusterSentences}
        getClusterSentencesData={getClusterSentencesData}

        getClusterSaveKeyWord={getClusterSaveKeyWord}
        getTextClusterFilter={getTextClusterFilter}
        topicCloudData={topicCloudData}
      />

      case 9: return <TopicCloud
        GetParameterLabel={GetParameterLabel}
        ErrorOtherObj={ErrorOtherObj}
        getIndexByFilterDMGData={getIndexByFilterDMGData}
        getEMPFilterLabel={getEMPFilterLabel} loading={loading} CountRestrict={CountRestrict}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getTextQuestionData={getTextQuestionData} handleChangeQuestion={handleChangeQuestion}


        handleTopicCloudDemographicFilter={handleTopicCloudDemographicFilter}
        callTopicCloudText={callTopicCloudText}


        wordCloudData={wordCloudData}
        wordCloudTextData={wordCloudTextData}
        wordTextKeyWord={wordTextKeyWord}
        wordCloudError={wordCloudError}

        topicCloudData={topicCloudData}

        isDMGText={isDMGText}
        getAllFeedbackWordsData={getAllFeedbackWordsData}
        handleDMGCloudDemographicFilter={handleDMGCloudDemographicFilter}

      />

      // case 2: return <BestFitData />

      default: return <div>Default Option</div>
    }
  }

  render() {
    let { optionType } = this.state;

    return (
      <>

        {/* side bar end */}
        <div className="md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto overflow-hidden bg-[#f9fafb] h-[calc(100vh-7rem)]">
          <div className="flex items-center justify-between text-gray-800">
            <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]  ">{"Text Analysis"} </h1>

            {/* <span className="flex cursor-pointer material-symbols-outlined lg:hidden">menu_open</span> */}
          </div>
          {/*sub nav bar 1 */}
          <div className="items-center justify-between border-b md:flex">
            <div className="flex space-x-2 overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 whitespace-nowrap tab-scroll">
              <span onClick={() => this.onOptionType(1)} className={optionType === 1 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> Word Cloud </span>
              <span onClick={() => this.onOptionType(9)} className={optionType === 9 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> Topic Cloud </span>
              {/* <span onClick={()=>this.onOptionType(6)} className={optionType===6?"cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> Topic Modeling </span> */}
              <span onClick={() => this.onOptionType(2)} className={optionType === 2 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> Network Graph </span>
              {/* <span onClick={()=>this.onOptionType(3)} className={optionType===3?"cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> Advance Word Cloud </span> */}
              {/* <span onClick={() => this.onOptionType(4)} className={optionType === 4 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> Co-Occurrence Graph </span> */}
              <span onClick={() => this.onOptionType(5)} className={optionType === 5 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> Text Distribution Insight </span>
              {/* <span onClick={() => this.onOptionType(7)} className={optionType === 7 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> Answer Feedback</span>
              <span onClick={() => this.onOptionType(8)} className={optionType === 8 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> Text Cluster</span> */}

            </div>
          </div>

          {this.onSwitchOptionScreen()}

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AdvanceComparision);
