import { lifecycledashConstants } from '../_constants';
import { lifecycledashService } from '../_services';
import { generalActions, surveyActions } from '../_actions';

export const lifecycledashActions = {
    getClientProfile,
    getSurvey,
    generateCompanySurveyCode,

    // assignRole,

    newStackList,



    getIndex,
    getEmpFilter,
    getIndexFilter,
    getDemographicIndex,

    // summary score
    getHappinessIndexV2,
    getEngagementIndexV2,
    getHopeIndexV2,
    getStressIndexV2,
    getManagerIndexV2,
    getLeaderIndexV2,
    getSeniorleadershipIndexV2,


    // driver
    getDemographicIndexHappiness,
    getDemographicIndexEngagement,
    getDemographicIndexStress,
    getDemographicIndexManager,
    getDemographicIndexLeader,
    getDemographicIndexOrg,


    getHappinessIndexDriver,
    getEngagementIndexDriver,
    getStressIndexDriver,
    getManagerIndexDriver,
    getLeaderIndexDriver,
    getOrgIndexDriver,


    // question analysis
    getQuestion,
    getQuestionOverall,
    getQuestionReport,
    getMultiManagerQuestionList,
    getQuestionFilter,


    getHappinessQuestion,
    getHappinessManagerQuestion,
    getHappinessDMGQuestion,

    getEngagementQuestion,
    getEngagementManagerQuestion,
    getEngagementDMGQuestion,

    getHopeQuestion,

    getStressQuestion,
    getStressManagerQuestion,
    getStressDMGQuestion,

    getManagerQuestion,
    getManagerManagerQuestion,
    getManagerDMGQuestion,

    getLeaderQuestion,
    getLeaderManagerQuestion,
    getLeaderDMGQuestion,



    // score distribution
    getHappinessIndexV2Demographic,
    getEngagementIndexV2Demographic,
    getStressIndexV2Demographic,


    getManagerList,
    getDemographicIndexForManager,

    getManagerListLifecycledashManagerIndex,

    getAllBenchMark,
    getOrgIndexV2,

    getRespondantLifecycleDetails,

    getManagerListDomainFilter,



    getQuestionManagerOfHappiness,
    getQuestionManagerOfEngagement,
    getQuestionManagerOfStress,
    getQuestionManagerOfManager,



    getIndexQuantile,

    getDemographicIndexScatter,


    getHappinessCorrelation,
    getHappinessCorrelationOverAll,

    getEngagementCorrelation,
    getEngagementCorrelationOverAll,


    getStressCorrelation,
    getStressCorrelationOverAll,

    getManagerCorrelation,
    getManagerCorrelationOverAll,


    getLeaderCorrelation,
    getLeaderCorrelationOverAll,


    getRWAnalysis,


    getTextQuestion,

    wordCloud,
    textDist,
    wordCloudText,
    wordCloudMultiText,
    AdvanceWordCloud,

    cooccurmatrix,

    neighborGraph,

    getEngagementRWA,

    cronbatchAlphaVariance,

    getQuestionFavorability,
    getQuestionFavorability2,


    getHistoryIndex,


    getResponseAnalytics,

    getComment,




    getDemographicIndexMultiFilter,

    AssignManagerActionPlan,

    getManagerActionPlan,
    getEmployeeActionPlan,

    updateEmployeeTask,
    updateEmployeeResource,
    emptyClientProfile,

    getAllManagerActionPlan,
    crossTab,
    crossTabDMG,
    
    emptycrossTabAnalysis,
    emptyManagerActionPlan,

    crossTabAnalysis,
    getCorrelationQuestionType2,

    getAllParameterList,

    getCorrelationDimenionVsDimension,
    getCorrelationDimensionVsDimensionFilter,
    getDemographicIndexFilterReport,

    getDemographicOrgReport,


    TreeMap,
    EmptyTreeMap,

    textDistTreeMap,





    getManagerProfile,
    getMNGRSurveyList,



    getHappinessIndexByFilter,
    getEngagementIndexByFilter,
    getStressIndexByFilter,
    getManagerIndexByFilter,
    getLeaderIndexByFilter,

    
    getDemographicIndexMultiFilterEngComp,

    getOverallIndex,

    getDemographicBranchCodeIndexMultiFilter,

    getParameterTypeList,

    createParameter,

    getOrgIndexV2Filter,

    getHappinessIndexV2Filter,
    getEngagementIndexV2Filter,
    getStressIndexV2Filter,

    getHappinessIndexDriverFilter,
    getEngagementIndexDriverFilter,
    getStressIndexDriverFilter,

    getParameterLibraryList,

    clusterAnalysis,
    getClusterEmpInfoList,

    topicModelling,

    createTopic,
    updateTopic,
    deleteTopic,
    getTopicList,
    getAllWordKeywordsText,
    getDemographicIndexEngCompOverall,

    getOverallScoreBySurveyId,
    getAllManagerScores,

    createGoal,
    getGoalList,
    updateGoal,
    deleteGoal,
    createPlan,
    updatePlan,
    deletePlan,
    startPlan,
    closePlan,
    getAllManagerGoalPlan,

    getIDPLastDate,
    editIDPLastDate,

    getCompanyNameOnly,

    managerDownloadStatus,
    getMngrReportDownloadStatus,
    getIndexByFilterAllStages,
    getQuestionMap,

    getDemographicIndexMultiManyFilter,
    getDemographicIndexOrgManyFilter,
    getQuestionDistribution,


    getQuestionCustomTemplate,





    getCategoryOverallIndex,
    getLifecycleListenById,
    getQuestionListBySurveyIDLifecycle,

    getIndexTrendScoreFilter,
    getIndexTrendScore,

    getCategoryById

    
};

function emptyClientProfile() {
    let obj={
        ClientProfileData:{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        lifecycledashService.getClientProfile()
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_CLIENT_PROFILE_DATA_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_CLIENT_PROFILE_DATA_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_CLIENT_PROFILE_DATA_FAILURE, error } }
}


function getClientProfile() {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getClientProfile()
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_CLIENT_PROFILE_DATA_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_CLIENT_PROFILE_DATA_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_CLIENT_PROFILE_DATA_FAILURE, error } }
}


function generateCompanySurveyCode(data,temp) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.generateCompanySurveyCode(data)
            .then(
                lifecycledash => 
                {
                    dispatch(success(lifecycledash));
                    dispatch(surveyActions.getSurveyById(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GENERATE_COMPANY_SURVEY_CODE_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GENERATE_COMPANY_SURVEY_CODE_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GENERATE_COMPANY_SURVEY_CODE_FAILURE, error } }
}

function getSurvey(data, type) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getSurvey(data)
            .then(
                lifecycledash =>{ 
                    dispatch(success(lifecycledash));
                    dispatch(this.getAllManagerActionPlan({categoryId:data.categoryId}));
                    // if(type==="ManagerActionPlan"){
                    //     if(lifecycledash && lifecycledash.SurveyData && lifecycledash.SurveyData.list && lifecycledash.SurveyData.list.length>0 && lifecycledash.SurveyData.list[0] && lifecycledash.SurveyData.list[0].id){
                    //         const firstSurveyId = lifecycledash.SurveyData.list[0].id;
                    //         dispatch(this.AssignManagerActionPlan({categoryId:firstSurveyId}));
                    //     }
                    // }
                    // if(lifecycledash && lifecycledash.SurveyData && lifecycledash.SurveyData.list && lifecycledash.SurveyData.list.length>0 && lifecycledash.SurveyData.list[0] && lifecycledash.SurveyData.list[0].id){
                    //     let data2 = {
                    //         "categoryId": lifecycledash.SurveyData.list[0].id
                    //     }
                    //     let data3 = {
                    //         "categoryId": lifecycledash.SurveyData.list[0].id,
                    //         "filters":null
                    //     }
                    //     if(data2.categoryId){
                    //         dispatch(this.getRespondantLifecycleDetails(data2));

                    //         dispatch(this.getIndex(data2));

                    //     }

                    // }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_SURVEY_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_SURVEY_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_SURVEY_FAILURE, error } }
}



function getEmpFilter(apiName,categoryId) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getEmpFilter()
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));
                    if(lifecycledash && lifecycledash.EmpFilterData && lifecycledash.EmpFilterData.length>0){
                        let Level = lifecycledash.EmpFilterData[0].name;
                        if(apiName==="getMeanScoreQwise"){
                            let data4 = {
                                "categoryId": categoryId,
                                "xAxis": Level
                              }
                              dispatch(generalActions.getMeanScoreQwise(data4)); 
                        }
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_EMP_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_EMP_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_EMP_FILTER_FAILURE, error } }
}



// function assignRole(data) {
//     return dispatch => {
//         dispatch(request());
//         lifecycledashService.assignRole(data)
//             .then(
//                 lifecycledash => dispatch(success(lifecycledash)),
//                 error => {
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ASSIGN_ROLE_REQUEST } }
//     function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ASSIGN_ROLE_SUCCESS, lifecycledash } }
//     function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ASSIGN_ROLE_FAILURE, error } }
// }


function newStackList() {
    let obj={
        StackListData:[]
    }
    return dispatch => {
        dispatch(success2(obj));
    };
    function success2(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_STACK_LIST_SUCCESS, lifecycledash } }
}


// new api s------------------------------------>

function getIndex(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getIndex(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_INDEX_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_INDEX_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_INDEX_FAILURE, error } }
}


function getOverallIndex(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getOverallIndex(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_OVERALL_INDEX_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_OVERALL_INDEX_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_OVERALL_INDEX_FAILURE, error } }
}


function getIndexFilter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getIndexFilter(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_INDEX_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_INDEX_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_INDEX_FILTER_FAILURE, error } }
}



function getDemographicIndex(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getDemographicIndex(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FAILURE, error } }
}


function getQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_FAILURE, error } }
}

function getQuestionDistribution(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getQuestionDistribution(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_ONLY_STRESS_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_ONLY_STRESS_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_ONLY_STRESS_FAILURE, error } }
}

function getHappinessQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getHappinessQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPINESS_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPINESS_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPINESS_QUESTION_FAILURE, error } }
}
function getHappinessManagerQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getHappinessQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPINESS_MANAGER_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPINESS_MANAGER_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPINESS_MANAGER_QUESTION_FAILURE, error } }
}
function getHappinessDMGQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getHappinessQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPINESS_DMG_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPINESS_DMG_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPINESS_DMG_QUESTION_FAILURE, error } }
}


function getEngagementQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getEngagementQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_QUESTION_FAILURE, error } }
}
function getEngagementManagerQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getEngagementQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_MANAGER_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_MANAGER_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_MANAGER_QUESTION_FAILURE, error } }
}
function getEngagementDMGQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getEngagementQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_DMG_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_DMG_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_DMG_QUESTION_FAILURE, error } }
}



function getHopeQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getHopeQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_HOPE_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_HOPE_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_HOPE_QUESTION_FAILURE, error } }
}

function getStressQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getStressQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_QUESTION_FAILURE, error } }
}
function getStressManagerQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getStressQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_MANAGER_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_MANAGER_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_MANAGER_QUESTION_FAILURE, error } }
}
function getStressDMGQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getStressQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_DMG_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_DMG_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_DMG_QUESTION_FAILURE, error } }
}



function getManagerQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getManagerQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_QUESTION_FAILURE, error } }
}

function getManagerManagerQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getManagerQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_MANAGER_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_MANAGER_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_MANAGER_QUESTION_FAILURE, error } }
}

function getManagerDMGQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getManagerQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_DMG_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_DMG_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_DMG_QUESTION_FAILURE, error } }
}



function getLeaderQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getLeaderQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_QUESTION_FAILURE, error } }
}

function getLeaderManagerQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getLeaderQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_MANAGER_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_MANAGER_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_MANAGER_QUESTION_FAILURE, error } }
}

function getLeaderDMGQuestion(data) {
    let Obj ={
        getQuestionData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getLeaderQuestion(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_DMG_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_DMG_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_DMG_QUESTION_FAILURE, error } }
}



function getQuestionOverall(data) {
    let Obj ={
        getQuestionOverallData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getQuestionOverall(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_OVERALL_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_OVERALL_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_OVERALL_FAILURE, error } }
}

function getQuestionReport(data, template) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getQuestionReport(data, template)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));                  
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_REPORT_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_REPORT_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_REPORT_FAILURE, error } }
}



function getQuestionFilter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getQuestionFilter(data)
            .then(
                lifecycledash => 
                {
                    dispatch(success(lifecycledash))
                    //console.log('lifecycledash: ',lifecycledash)
                },

                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_FILTER_FAILURE, error } }
}


function getHappinessIndexV2(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getHappinessIndexV2(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_V2_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_V2_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_V2_FAILURE, error } }
}


function getEngagementIndexV2(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getEngagementIndexV2(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FAILURE, error } }
}


function getHopeIndexV2(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getHopeIndexV2(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_V2_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_V2_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_V2_FAILURE, error } }
}


function getStressIndexV2(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getStressIndexV2(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_FAILURE, error } }
}


function getManagerIndexV2(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getManagerIndexV2(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_V2_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_V2_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_V2_FAILURE, error } }
}


function getLeaderIndexV2(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getLeaderIndexV2(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_V2_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_V2_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_V2_FAILURE, error } }
}


function getSeniorleadershipIndexV2(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getSeniorleadershipIndexV2(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_SENIORLEADERSHIP_INDEX_V2_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_SENIORLEADERSHIP_INDEX_V2_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_SENIORLEADERSHIP_INDEX_V2_FAILURE, error } }
}

function getOrgIndexV2(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getOrgIndexV2(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_V2_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_V2_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_V2_FAILURE, error } }
}



function getDemographicIndexHappiness(data, type, ManagerDynaObj, isReport, DriverMngrHappinessReportObject,filters) {
    let obj = {
        getDemographicIndexHappinessData:[]
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        lifecycledashService.getDemographicIndexHappiness(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    if(isReport){
                        if(lifecycledash && lifecycledash.getDemographicIndexHappinessData){
                            lifecycledash.getDemographicIndexHappinessData.forEach((item)=>{
                                if(filters.value===item.name){
                                            if(DriverMngrHappinessReportObject&&DriverMngrHappinessReportObject[filters.level]&&DriverMngrHappinessReportObject[filters.level].findIndex(prev=>prev.name === item.name)===-1){
                                                DriverMngrHappinessReportObject[filters.level].push(item);
                                            }
                                            else{
                                                if(DriverMngrHappinessReportObject&&DriverMngrHappinessReportObject[filters.level]&&DriverMngrHappinessReportObject[filters.level].findIndex(prev=>prev.name === item.name)){
                                                    let getIndex = DriverMngrHappinessReportObject[filters.level].findIndex(prev=>prev.name === item.name);
                                                    DriverMngrHappinessReportObject[filters.level][getIndex] = item;
                                                }
                                            }
                                }
                            });
                        }
                    }
                    if(type===5){
                        if(lifecycledash && lifecycledash.getDemographicIndexHappinessData){
                            lifecycledash.getDemographicIndexHappinessData.forEach((item)=>{
                                if(ManagerDynaObj.findIndex(prev=>prev.name === item.name)===-1){
                                    ManagerDynaObj.push(item);
                                }
                                else{
                                    let getIndex = ManagerDynaObj.findIndex(prev=>prev.name === item.name);
                                    ManagerDynaObj[getIndex]=item
                                }
                            })
                        }
                    }
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_HAPPINESS_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_HAPPINESS_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_HAPPINESS_FAILURE, error } }
}


function getDemographicIndexEngagement(data, type, ManagerDynaObj,isReport, DriverMngrEngagementReportObject,filters) {
    let obj = {
        getDemographicIndexEngagementData:[]
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        lifecycledashService.getDemographicIndexEngagement(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    if(isReport){
                        if(lifecycledash && lifecycledash.getDemographicIndexEngagementData){
                            lifecycledash.getDemographicIndexEngagementData.forEach((item)=>{
                                if(filters.value===item.name){
                                            if(DriverMngrEngagementReportObject&&DriverMngrEngagementReportObject[filters.level]&&DriverMngrEngagementReportObject[filters.level].findIndex(prev=>prev.name === item.name)===-1){
                                                DriverMngrEngagementReportObject[filters.level].push(item);
                                            }
                                            else{
                                                if(DriverMngrEngagementReportObject&&DriverMngrEngagementReportObject[filters.level]&&DriverMngrEngagementReportObject[filters.level].findIndex(prev=>prev.name === item.name)){
                                                    let getIndex = DriverMngrEngagementReportObject[filters.level].findIndex(prev=>prev.name === item.name);
                                                    DriverMngrEngagementReportObject[filters.level][getIndex] = item;
                                                }
                                            }
                                }
                            });
                        }
                    }
                    if(type===5){
                        if(lifecycledash && lifecycledash.getDemographicIndexEngagementData){
                            // ManagerDynaObj.concat(lifecycledash.getDemographicIndexEngagementData)
                            lifecycledash.getDemographicIndexEngagementData.forEach((item)=>{
                                if(ManagerDynaObj.findIndex(prev=>prev.name === item.name)===-1){
                                    ManagerDynaObj.push(item);
                                }
                                else{
                                    let getIndex = ManagerDynaObj.findIndex(prev=>prev.name === item.name);
                                    ManagerDynaObj[getIndex]=item
                                }
                            })
                        }
                    }
                },                
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_FAILURE, error } }
}

function getDemographicIndexStress(data, type, ManagerDynaObj,isReport,DriverMngrStressReportObject,filters) {
    let obj = {
        getDemographicIndexStressData:[]
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        lifecycledashService.getDemographicIndexStress(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    if(isReport){
                        if(lifecycledash && lifecycledash.getDemographicIndexStressData){
                            lifecycledash.getDemographicIndexStressData.forEach((item)=>{
                                if(filters.value===item.name){
                                            if(DriverMngrStressReportObject&&DriverMngrStressReportObject[filters.level]&&DriverMngrStressReportObject[filters.level].findIndex(prev=>prev.name === item.name)===-1){
                                                DriverMngrStressReportObject[filters.level].push(item);
                                            }
                                            else{
                                                if(DriverMngrStressReportObject&&DriverMngrStressReportObject[filters.level]&&DriverMngrStressReportObject[filters.level].findIndex(prev=>prev.name === item.name)){
                                                    let getIndex = DriverMngrStressReportObject[filters.level].findIndex(prev=>prev.name === item.name);
                                                    DriverMngrStressReportObject[filters.level][getIndex] = item;
                                                }
                                            }
                                }
                            });
                        }
                    }
                    if(type===5){
                        if(lifecycledash && lifecycledash.getDemographicIndexStressData){
                            // ManagerDynaObj.concat(lifecycledash.getDemographicIndexStressData)
                            lifecycledash.getDemographicIndexStressData.forEach((item)=>{
                                if(ManagerDynaObj.findIndex(prev=>prev.name === item.name)===-1){
                                    ManagerDynaObj.push(item);
                                }
                                else{
                                    let getIndex = ManagerDynaObj.findIndex(prev=>prev.name === item.name);
                                    ManagerDynaObj[getIndex]=item
                                }
                            })
                        }
                    }
                },                   
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_STRESS_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_STRESS_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_STRESS_FAILURE, error } }
}


function getDemographicIndexManager(data, type, ManagerDynaObj,isReport,DriverMngrManagerReportObject,filters) {
    let obj = {
        getDemographicIndexManagerData:[]
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        lifecycledashService.getDemographicIndexManager(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    if(isReport){
                        if(lifecycledash && lifecycledash.getDemographicIndexManagerData){
                            lifecycledash.getDemographicIndexManagerData.forEach((item)=>{
                                if(filters.value===item.name){
                                            if(DriverMngrManagerReportObject&&DriverMngrManagerReportObject[filters.level]&&DriverMngrManagerReportObject[filters.level].findIndex(prev=>prev.name === item.name)===-1){
                                                DriverMngrManagerReportObject[filters.level].push(item);
                                            }
                                            else{
                                                if(DriverMngrManagerReportObject&&DriverMngrManagerReportObject[filters.level]&&DriverMngrManagerReportObject[filters.level].findIndex(prev=>prev.name === item.name)){
                                                    let getIndex = DriverMngrManagerReportObject[filters.level].findIndex(prev=>prev.name === item.name);
                                                    DriverMngrManagerReportObject[filters.level][getIndex] = item;
                                                }
                                            }
                                }
                            });
                        }
                    }
                    if(type===5){
                        if(lifecycledash && lifecycledash.getDemographicIndexManagerData){
                            // ManagerDynaObj.concat(lifecycledash.getDemographicIndexManagerData)
                            lifecycledash.getDemographicIndexManagerData.forEach((item)=>{
                                if(ManagerDynaObj.findIndex(prev=>prev.name === item.name)===-1){
                                    ManagerDynaObj.push(item);
                                }
                                else{
                                    let getIndex = ManagerDynaObj.findIndex(prev=>prev.name === item.name);
                                    ManagerDynaObj[getIndex]=item
                                }
                            })
                        }
                    }
                },                   
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANAGER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANAGER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANAGER_FAILURE, error } }
}

function getDemographicIndexLeader(data, type, ManagerDynaObj) {
    let obj = {
        getDemographicIndexLeaderData:[]
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        lifecycledashService.getDemographicIndexLeader(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    if(type===5){
                        if(lifecycledash && lifecycledash.getDemographicIndexLeaderData){
                            // ManagerDynaObj.concat(lifecycledash.getDemographicIndexLeaderData)
                            lifecycledash.getDemographicIndexLeaderData.forEach((item)=>{
                                if(ManagerDynaObj.findIndex(prev=>prev.name === item.name)===-1){
                                    ManagerDynaObj.push(item);
                                }
                                else{
                                    let getIndex = ManagerDynaObj.findIndex(prev=>prev.name === item.name);
                                    ManagerDynaObj[getIndex]=item
                                }
                            })
                        }
                    }
                },                   
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_LEADER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_LEADER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_LEADER_FAILURE, error } }
}

function getDemographicIndexOrg(data) {
    let obj = {
        getDemographicIndexOrgData:[]
    }
    return dispatch => {
        dispatch(success(obj))
        dispatch(request());
        lifecycledashService.getDemographicIndexOrg(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ORG_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ORG_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ORG_FAILURE, error } }
}

function getHappinessIndexDriver(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getHappinessIndexDriver(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FAILURE, error } }
}

function getEngagementIndexDriver(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getEngagementIndexDriver(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_DRIVER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_DRIVER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_DRIVER_FAILURE, error } }
}

function getStressIndexDriver(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getStressIndexDriver(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_DRIVER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_DRIVER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_DRIVER_FAILURE, error } }
}


function getManagerIndexDriver(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getManagerIndexDriver(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_DRIVER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_DRIVER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_DRIVER_FAILURE, error } }
}


function getLeaderIndexDriver(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getLeaderIndexDriver(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_DRIVER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_DRIVER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_DRIVER_FAILURE, error } }
}

function getOrgIndexDriver(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getOrgIndexDriver(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_DRIVER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_DRIVER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_DRIVER_FAILURE, error } }
}






function getHappinessIndexV2Demographic(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getHappinessIndexV2Demographic(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_FAILURE, error } }
}

function getEngagementIndexV2Demographic(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getEngagementIndexV2Demographic(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_FAILURE, error } }
}

function getStressIndexV2Demographic(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getStressIndexV2Demographic(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_DEMOGRAPHIC_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_DEMOGRAPHIC_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_DEMOGRAPHIC_FAILURE, error } }
}


function getManagerList(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getManagerList(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    // if(lifecycledash && lifecycledash.ManagerList && lifecycledash.ManagerList.list && lifecycledash.ManagerList.list.length>0 && lifecycledash.ManagerList.list[0] && lifecycledash.ManagerList.list[0].ManagerPID){
                    //     let data1 = {
                    //         "categoryId": data.categoryId,
                    //         "filters": [
                    //             {
                    //                 "level": "ManagerPID",
                    //                 "value":lifecycledash.ManagerList.list[0].ManagerPID
                    //             }
                    //         ]
                    //     }
                    //     dispatch(this.getIndexByManagerWise(data1));
                    // }

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_FAILURE, error } }
}


function getManagerListLifecycledashManagerIndex(data,mngr,ManagerListDasboardDataObj) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getManagerListLifecycledashManagerIndex(data,mngr)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));  
                    if(lifecycledash && lifecycledash.getManagerListLifecycledashManagerIndexData && lifecycledash.getManagerListLifecycledashManagerIndexData.ManagerPID){
                        let managerId = lifecycledash.getManagerListLifecycledashManagerIndexData.ManagerPID;
                        let getIndex = ManagerListDasboardDataObj.findIndex(prev=>prev.ManagerPID === managerId)
                        if(getIndex===-1){
                            ManagerListDasboardDataObj.push(lifecycledash.getManagerListLifecycledashManagerIndexData);
                        }
                        else{
                            ManagerListDasboardDataObj[getIndex]=lifecycledash.getManagerListLifecycledashManagerIndexData;
                        }


                    }
                    // else{
                    //     ManagerListDasboardDataObj.push({
                            
                    //     })
                    // }
                    
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_LIFECYCLEDASH_MANAGER_INDEX_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_LIFECYCLEDASH_MANAGER_INDEX_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_LIFECYCLEDASH_MANAGER_INDEX_FAILURE, error } }
}


function getDemographicIndexForManager(data, type, DynaObj, EmpFilterData,categoryId) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getDemographicIndexForManager(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    if(type === 5){

                    if(lifecycledash && lifecycledash.getDemographicIndexForManagerData && lifecycledash.getDemographicIndexForManagerData.length>0 ){

   
                    // //////console.log("EmpFilterData[0]",EmpFilterData)
                    let data2 =  lifecycledash.getDemographicIndexForManagerData[0];
                    for(let i=0;i<2;i++){
                      if(EmpFilterData && EmpFilterData.length>0){
                        // //////console.log("lifecycledash[0]",data2)

                        // let filterdata2 = {
                        //     "categoryId":categoryId,
                        //     "filters":[{
                        //          "level":"ManagerPID",
                        //          "value":data2["id"]
                        //      }]
                        // }
                        // //////console.log("lifecycledash[0]",filterdata2)

                        // dispatch(this.getManagerListDomainFilter(filterdata2,5,DynaObj));
                        EmpFilterData.forEach((filter)=>{
                            if(["ManagerPID","Department","Function","Business"].findIndex(prev=>prev===filter.name)!==-1){

                    
                                        if(data2 && data2[filter.name]){
                                        let filterdata = {
                                            "categoryId":categoryId,
                                            "filters":[{
                                                    "level":filter.name,
                                                    "value":data2[filter&&filter.name==="ManagerPID"?"id":filter.name]
                                                }]
                                        }

                                        dispatch(this.getManagerListDomainFilter(filterdata,5,DynaObj));
                            }
                 
                        }
                 
                       })
                     }
  
                    }

                    }

                }
                
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_FAILURE, error } }
}


function getAllBenchMark(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getAllBenchMark(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_BENCHMARK_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_BENCHMARK_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_BENCHMARK_FAILURE, error } }
}

function getRespondantLifecycleDetails(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getRespondantLifecycleDetails(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_RESPONDANT_DETAILS_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_RESPONDANT_DETAILS_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_RESPONDANT_DETAILS_FAILURE, error } }
}


function getManagerListDomainFilter(data,indexType,DynaObj) {
    let refresh = {
        getManagerListDomainFilterData:{}
    }
    return dispatch => {
        dispatch(success(refresh));
        dispatch(request());
        lifecycledashService.getManagerListDomainFilter(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    if(indexType === 5){
                        if(lifecycledash && lifecycledash.getManagerListDomainFilterData){
                            let newData = lifecycledash.getManagerListDomainFilterData;
                            if(DynaObj.findIndex(prev=>prev.name[0] === newData.name[0])===-1){
                                DynaObj.push(newData);
                            }
                            else{
                                let getIndex = DynaObj.findIndex(prev=>prev.name[0] === newData.name[0]);
                                DynaObj[getIndex]=newData;
                            }
                        }
                    }
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_DOMAIN_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_DOMAIN_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_DOMAIN_FILTER_FAILURE, error } }
}





function getQuestionManagerOfHappiness(data,type,QuesDynaObj, isReport, QuesMngrHappinessReportObject) {
    let Obj ={
        getQuestionManagerOfHappinessData:[]
    }
    return dispatch => {

        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getQuestionManagerOfHappiness(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));  
                    if(true){
                        if(lifecycledash && lifecycledash.getQuestionManagerOfHappinessData){


                            if(lifecycledash && lifecycledash.getQuestionManagerOfHappinessData){
                                lifecycledash.getQuestionManagerOfHappinessData.forEach((item)=>{



                                        if(QuesMngrHappinessReportObject&&QuesMngrHappinessReportObject[item.filters[0].level]&&QuesMngrHappinessReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                            QuesMngrHappinessReportObject[item.filters[0].level].push(item);
                                        }
                                        else{
                                            if(QuesMngrHappinessReportObject&&QuesMngrHappinessReportObject[item.filters[0].level]&&QuesMngrHappinessReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                                let getIndex = QuesMngrHappinessReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                                QuesMngrHappinessReportObject[item.filters[0].level][getIndex] = item;
                                            }
                                        }




                                    }
                                )
    
                            }
                        }
                            
                    } 
                    if(type===5){
                        if(lifecycledash && lifecycledash.getQuestionManagerOfHappinessData){
                            
                            lifecycledash.getQuestionManagerOfHappinessData.forEach((item)=>{



                                    if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                        QuesDynaObj[item.filters[0].level].push(item);
                                    }
                                    else{
                                        if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                            let getIndex = QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                            QuesDynaObj[item.filters[0].level][getIndex] = item;
                                        }

                                    }




                                }
                            )

                        }
                    }


                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_HAPPINESS_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_HAPPINESS_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_HAPPINESS_FAILURE, error } }
}

function getQuestionManagerOfEngagement(data,type,QuesDynaObj, isReport, QuesMngrEngagementReportObject) {
    let Obj ={
        getQuestionManagerOfEngagementData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getQuestionManagerOfEngagement(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));  
                    if(true){
                        if(lifecycledash && lifecycledash.getQuestionManagerOfEngagementData){
                            
                            if(lifecycledash && lifecycledash.getQuestionManagerOfEngagementData){
                            
                                lifecycledash.getQuestionManagerOfEngagementData.forEach((item)=>{
                                        if(QuesMngrEngagementReportObject&&QuesMngrEngagementReportObject[item.filters[0].level]&&QuesMngrEngagementReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                            QuesMngrEngagementReportObject[item.filters[0].level].push(item);
                                        }
                                        else{
                                            if(QuesMngrEngagementReportObject&&QuesMngrEngagementReportObject[item.filters[0].level]&&QuesMngrEngagementReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                                let getIndex = QuesMngrEngagementReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                                QuesMngrEngagementReportObject[item.filters[0].level][getIndex] = item;
                                            }
                                        }
                                    }
                                )
    
                            }
                        }
                            
                    } 
                    if(type===5){
                        if(lifecycledash && lifecycledash.getQuestionManagerOfEngagementData){
                            
                            lifecycledash.getQuestionManagerOfEngagementData.forEach((item)=>{



                                    if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                        QuesDynaObj[item.filters[0].level].push(item);
                                    }
                                    else{
                                        if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                            let getIndex = QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                            QuesDynaObj[item.filters[0].level][getIndex] = item;
                                        }

                                    }
                                    
                                }
                            )

                        }
                    }


                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_ENGAGEMENT_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_ENGAGEMENT_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_ENGAGEMENT_FAILURE, error } }
}

function getQuestionManagerOfStress(data,type,QuesDynaObj, isReport, QuesMngrStressReportObject) {
    let Obj ={
        getQuestionManagerOfStressData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getQuestionManagerOfStress(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));   
                    //console.log('QuesDynaObj: ',QuesDynaObj)
                    if(true){
                        if(lifecycledash && lifecycledash.getQuestionManagerOfStressData){
                            
                            if(lifecycledash && lifecycledash.getQuestionManagerOfStressData){
                            
                                lifecycledash.getQuestionManagerOfStressData.forEach((item)=>{
                                        if(QuesMngrStressReportObject&&QuesMngrStressReportObject[item.filters[0].level]&&QuesMngrStressReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                            QuesMngrStressReportObject[item.filters[0].level].push(item);
                                        }
                                        else{
                                            if(QuesMngrStressReportObject&&QuesMngrStressReportObject[item.filters[0].level]&&QuesMngrStressReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                                let getIndex = QuesMngrStressReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                                QuesMngrStressReportObject[item.filters[0].level][getIndex] = item;
                                            }
 
                                        }
                                    }
                                )
    
                            }
                        }
                            
                    } 
                    if(type===5){
                        if(lifecycledash && lifecycledash.getQuestionManagerOfStressData){
                            
                            lifecycledash.getQuestionManagerOfStressData.forEach((item)=>{
                                    if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                        QuesDynaObj[item.filters[0].level].push(item);
                                    }
                                    else{
                                        if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                            let getIndex = QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                            QuesDynaObj[item.filters[0].level][getIndex] = item;
                                        }

                                    }
                                }
                            )

                        }
                    }              
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_STRESS_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_STRESS_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_STRESS_FAILURE, error } }
}

function getQuestionManagerOfManager(data,type,QuesDynaObj, isReport, QuesMngrManagerReportObject) {
    let Obj ={
        getQuestionManagerOfManagerData:[]
    }
    return dispatch => {
        dispatch(success(Obj));
        dispatch(request());
        lifecycledashService.getQuestionManagerOfManager(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));   
                    //console.log('QuesDynaObj: ',QuesDynaObj)
                    if(true){
                        if(lifecycledash && lifecycledash.getQuestionManagerOfManagerData){
                            
                            if(lifecycledash && lifecycledash.getQuestionManagerOfManagerData){
                            
                                lifecycledash.getQuestionManagerOfManagerData.forEach((item)=>{
                                        if(QuesMngrManagerReportObject&&QuesMngrManagerReportObject[item.filters[0].level]&&QuesMngrManagerReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                            QuesMngrManagerReportObject[item.filters[0].level].push(item);
                                        }
                                        else{
                                            if(QuesMngrManagerReportObject&&QuesMngrManagerReportObject[item.filters[0].level]&&QuesMngrManagerReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                                let getIndex = QuesMngrManagerReportObject[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                                QuesMngrManagerReportObject[item.filters[0].level][getIndex] = item;
                                            }

                                        }
                                    }
                                )
    
                            }
                        }
                            
                    } 
                    if(type===5){
                        if(lifecycledash && lifecycledash.getQuestionManagerOfManagerData){
                            
                            lifecycledash.getQuestionManagerOfManagerData.forEach((item)=>{
                                    if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                                        QuesDynaObj[item.filters[0].level].push(item);
                                    }
                                    else{
                                        if(QuesDynaObj&&QuesDynaObj[item.filters[0].level]&&QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)){
                                            let getIndex = QuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                                            QuesDynaObj[item.filters[0].level][getIndex] = item;
                                        }

                                    }
                                }
                            )

                        }
                    }             
                } ,
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_MANAGER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_MANAGER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_MANAGER_FAILURE, error } }
}




function getIndexQuantile(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getIndexQuantile(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_INDEX_QUANTILE_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_INDEX_QUANTILE_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_INDEX_QUANTILE_FAILURE, error } }
}


function getDemographicIndexScatter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getDemographicIndexScatter(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SCATTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SCATTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SCATTER_FAILURE, error } }
}


function getHappinessCorrelation(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getHappinessCorrelation(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_CORRELATION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_CORRELATION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_CORRELATION_FAILURE, error } }
}


function getHappinessCorrelationOverAll(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getHappinessCorrelationOverAll(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_CORRELATION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_CORRELATION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_CORRELATION_FAILURE, error } }
}



function getEngagementCorrelation(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getEngagementCorrelation(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_CORRELATION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_CORRELATION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_CORRELATION_FAILURE, error } }
}

function getEngagementCorrelationOverAll(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getEngagementCorrelationOverAll(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_CORRELATION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_CORRELATION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_CORRELATION_FAILURE, error } }
}



function getStressCorrelation(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getStressCorrelation(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_CORRELATION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_CORRELATION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_CORRELATION_FAILURE, error } }
}


function getStressCorrelationOverAll(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getStressCorrelationOverAll(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_CORRELATION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_CORRELATION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_CORRELATION_FAILURE, error } }
}



function getManagerCorrelation(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getManagerCorrelation(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_CORRELATION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_CORRELATION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_CORRELATION_FAILURE, error } }
}

function getManagerCorrelationOverAll(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getManagerCorrelationOverAll(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_CORRELATION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_CORRELATION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_CORRELATION_FAILURE, error } }
}




function getLeaderCorrelation(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getLeaderCorrelation(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_CORRELATION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_CORRELATION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_CORRELATION_FAILURE, error } }
}

function getLeaderCorrelationOverAll(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getLeaderCorrelationOverAll(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_CORRELATION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_CORRELATION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_CORRELATION_FAILURE, error } }
}



function getRWAnalysis(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getRWAnalysis(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_RWA_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_RWA_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_RWA_FAILURE, error } }
}


function getTextQuestion(data, check,filer,xAxis) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getTextQuestion(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));

          
                        if(lifecycledash.getTextQuestionData && lifecycledash.getTextQuestionData.length>0){
                            let data2 ={
                                "categoryId": data.categoryId,
                                "questionName": lifecycledash.getTextQuestionData[0]._id,
                                "filters": filer&&filer.length>0?filer:[]
                            }
    
                            if(check===1){
                                dispatch(this.wordCloud(data2));
                            }
                            else if(check===2){
                                dispatch(this.cooccurmatrix(data2));
                            }
                            else if(check===3){
                                dispatch(this.neighborGraph(data2));
                            }
                            else if(check===4){
                                dispatch(this.AdvanceWordCloud(data2));
                            }
                            else if(check===5){
                                dispatch(this.textDist(data2));
                            }
                            else if(check===6){
                                let data3 ={
                                    "categoryId": data.categoryId,
                                    "questionName": lifecycledash.getTextQuestionData[0]._id,
                                    "xAxis": xAxis
                                }
                                dispatch(this.TreeMap(data3));
                            } 
                            else if(check===111){
                                
                                let FilteredObj = {
                                    getQuestionCommentsData:[]
                                };

                                if(lifecycledash.getTextQuestionData && lifecycledash.getTextQuestionData.length>0){
                                    lifecycledash.getTextQuestionData.forEach((ques, index2)=>{
                                        //////console.log("ques----->",ques)
                                    let item = {
                                        "categoryId": data.categoryId,
                                        "questionName": ques._id,
                                        "filters": filer&&filer.length>0?filer:[]
                                    };

                            
                                    lifecycledashService.wordCloud(item)
                                    .then(
                                        lifecycledashx1 => {
                                            if(lifecycledashx1 && lifecycledashx1.wordCloudData){
                                                let List = lifecycledashx1.wordCloudData.list;
                                                List.sort((a,b)=> b[0] - a[0])
                                                if(List && List.length>0){
                                                    let KeyWord = List[0][0];
                        
                                                    let data3 = {
                                                        ...item,
                                                        "keyWord": KeyWord
                                                        }



                                                lifecycledashService.wordCloudText(data3)
                                                .then(
                                                    lifecycledashx2 => {
                                                        if(lifecycledashx2 && lifecycledashx2.wordCloudTextData){
                                                            FilteredObj['getQuestionCommentsData'].push({
                                                                "questionId":ques._id,
                                                                "questionName":ques._id,
                                                                "Data":lifecycledashx2.wordCloudTextData
                                                            });
                                                        }

                                                        if((index2+1) === lifecycledash.getTextQuestionData.length){
                                                            dispatch(success2(FilteredObj))
                                                        }

                                                        },
                                                    error => {
                                                        // dispatch(failure(error))
                                                    }
                                                );

                                            }


                                                
                                            }
                                        },
                                        error => {
                                            // dispatch(failure(error))
                                        }
                                    );
                    
                            
                      
                                    
                            



                                })
                            }

                            }
                            else if(check===7){
                                dispatch(this.wordCloud(data2,true));
                            }

                        }




                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_TEXT_QUESTION_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_TEXT_QUESTION_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_TEXT_QUESTION_FAILURE, error } }

    function success2(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_COMMENT_SUCCESS, lifecycledash } }

}


function textDist(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.textDist(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_TEXT_DIST_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_TEXT_DIST_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_TEXT_DIST_FAILURE, error } }
}


function wordCloud(data,type) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.wordCloud(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));

                    if(lifecycledash && lifecycledash.wordCloudData && lifecycledash.wordCloudData.list.length>0){
                        let List = lifecycledash.wordCloudData.list;

                        if(type){
                            let keyWorkList = []
                            if(List && List.length>0){
                                List.forEach((item,index)=>{
                                    if(index<2){
                                        let KeyWord = item[0];
                                        keyWorkList.push({
                                            "topic":KeyWord,
                                            "keywordList":[],
                                            "isCustom":false
                                        });
                                    }
                                   
                                });
                            }

                            dispatch(this.topicModelling({
                                ...data,
                                "wordList":keyWorkList
                            }))

                        }
                        else{
                            List.sort((a,b)=> b[0] - a[0]);
                            if(List && List.length>0){
                                let KeyWord = List[0][0];
    
                                let data3 = {
                                    ...data,
                                    "keyWord": KeyWord
                                  }
    
    
                                dispatch(this.wordCloudText(data3))
    
                            }
                        }





                    }

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_WORD_CLOUD_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_WORD_CLOUD_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_WORD_CLOUD_FAILURE, error } }
}

function AdvanceWordCloud(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.AdvanceWordCloud(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));

                    if(lifecycledash && lifecycledash.AdvanceWordCloudData && lifecycledash.AdvanceWordCloudData.list.length>0){
                        let List = lifecycledash.AdvanceWordCloudData.list;
                        List.sort((a,b)=> b[0] - a[0])
                        if(List && List.length>0){
                            let KeyWord = List[0][0];

                            let data3 = {
                                ...data,
                                "keyWord": KeyWord
                              }


                            dispatch(this.wordCloudText(data3))

                        }
                    }

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_ADVANCE_WORD_CLOUD_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_ADVANCE_WORD_CLOUD_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_ADVANCE_WORD_CLOUD_FAILURE, error } }
}



function wordCloudText(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.wordCloudText(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_WORD_CLOUD_TEXT_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_WORD_CLOUD_TEXT_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_WORD_CLOUD_TEXT_FAILURE, error } }
}


function wordCloudMultiText(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.wordCloudMultiText(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_WORD_CLOUD_MULTI_TEXT_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_WORD_CLOUD_MULTI_TEXT_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_WORD_CLOUD_MULTI_TEXT_FAILURE, error } }
}

function cooccurmatrix(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.cooccurmatrix(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_COOCCUR_MATRIX_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_COOCCUR_MATRIX_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_COOCCUR_MATRIX_FAILURE, error } }
}

function neighborGraph(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.neighborGraph(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_NEIGHBOR_GRAPH_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_NEIGHBOR_GRAPH_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_NEIGHBOR_GRAPH_FAILURE, error } }
}


function getEngagementRWA(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getEngagementRWA(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_RWA_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_RWA_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_RWA_FAILURE, error } }
}


function cronbatchAlphaVariance(data) {
    let ClearObj = {
        cronbatchAlphaVarianceData:{}
    }
    return dispatch => {
        dispatch(success2(ClearObj));
        dispatch(request());
        lifecycledashService.cronbatchAlphaVariance(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_SUCCESS, lifecycledash } }
    function success2(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_SUCCESS_TWO, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_FAILURE, error } }

}


function getQuestionFavorability(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getQuestionFavorability(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_FAVOURABILITY_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_FAVOURABILITY_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_FAVOURABILITY_FAILURE, error } }

}

function getQuestionFavorability2(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getQuestionFavorability2(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_FAVOURABILITY_2_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_FAVOURABILITY_2_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_FAVOURABILITY_2_FAILURE, error } }

}

function getHistoryIndex(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getHistoryIndex(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_HISTORY_INDEX_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_HISTORY_INDEX_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_HISTORY_INDEX_FAILURE, error } }

}



function getResponseAnalytics(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getResponseAnalytics(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_RESPONSE_ANALYTIYCS_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_RESPONSE_ANALYTIYCS_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_RESPONSE_ANALYTIYCS_FAILURE, error } }

}



function getComment(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getComment(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_COMMENT_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_COMMENT_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_COMMENT_FAILURE, error } }

}

function getMultiManagerQuestionList(data,mngr,ManagerListQuestionDataObj) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getMultiManagerQuestionList(data,mngr)
            .then(
                lifecycledash =>{ 
                    dispatch(success(lifecycledash));
                    if(lifecycledash && lifecycledash.getMultiManagerQuestionListData && lifecycledash.getMultiManagerQuestionListData.ManagerPID){
                        let managerId = lifecycledash.getMultiManagerQuestionListData.ManagerPID;
                        let getIndex = ManagerListQuestionDataObj.findIndex(prev=>prev.ManagerPID === managerId)
                        if(getIndex===-1){
                            ManagerListQuestionDataObj.push(lifecycledash.getMultiManagerQuestionListData);
                        }
                        else{
                            ManagerListQuestionDataObj[getIndex]=lifecycledash.getMultiManagerQuestionListData;
                        }


                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MULTIMANAGER_QUESTIONLIST_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MULTIMANAGER_QUESTIONLIST_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MULTIMANAGER_QUESTIONLIST_FAILURE, error } }

}



//------------- multifilter ---------------------------

function getDemographicIndexMultiFilter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getDemographicIndexMultiFilter(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_MULTI_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_MULTI_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_MULTI_FILTER_FAILURE, error } }

}


function getDemographicIndexFilterReport(data) {

    
    return dispatch => {
        dispatch(request());

        let FilteredObj = {
            getDemographicIndexFilterReportData:[]
        };
        if(data && data.length>0){
            data.forEach((item)=>{

                lifecycledashService.getDemographicIndexFilterReport(item)
                .then(
                    lifecycledash => {
                        if(lifecycledash && lifecycledash.getDemographicIndexFilterReportData){
                            FilteredObj['getDemographicIndexFilterReportData'].push({
                                "xAxis":item.xAxis,
                                "Data":lifecycledash.getDemographicIndexFilterReportData
                            });
                        }
                    },
                    error => {
                        // dispatch(failure(error))
                    }
                );


            });
        }
        dispatch(success(FilteredObj))
    };



    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_REPORT_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_REPORT_FILTER_SUCCESS, lifecycledash } }
    // function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_REPORT_FILTER_FAILURE, error } }

}


function getDemographicIndexMultiManyFilter(data) {

    
    return async dispatch => {
        dispatch(request());

        let FilteredObj = {
            getDemographicIndexMultiManyFilterData:[]
        };

        function getLoop (data1,FilteredObj1){
            if(data1 && data1.length>0){
                for(var i = 0; i<data1.length; i++ ){
                    let item = data1[i];
                    // tempList.forEach((item)=>{
    
                        lifecycledashService.getDemographicIndexMultiManyFilter(item)
                        .then(
                            lifecycledash => {
                                if(lifecycledash && lifecycledash.getDemographicIndexMultiManyFilterData){
                                    FilteredObj1['getDemographicIndexMultiManyFilterData'].push({
                                        "xAxis":item.xAxis,
                                        "Data":lifecycledash.getDemographicIndexMultiManyFilterData
                                    });
                                }
                            },
                            error => {
                                // dispatch(failure(error))
                            }
                        );
    
                        // });
    
                    
                }
    
            }
            return JSON.parse(JSON.stringify(FilteredObj1))
        }

        let finalList = await getLoop(data,FilteredObj)
        //////console.log("finalList------>",finalList)
        dispatch(success(FilteredObj))
    };



    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANY_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANY_SUCCESS, lifecycledash } }
    // function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANY_FAILURE, error } }

}

function getDemographicIndexOrgManyFilter(data,driver, type) {

    
    return async dispatch => {
        dispatch(request());

        let FilteredObj = {
            getDemographicIndexOrgManyFilterData:[]
        };

        function getLoop (data1,FilteredObj1){
            if(data1 && data1.length>0){
                for(var i = 0; i<data1.length; i++ ){
                    let item = data1[i];
                    // tempList.forEach((item)=>{
    
                        lifecycledashService.getDemographicIndexOrgManyFilter(item)
                        .then(
                            lifecycledash => {
                                if(lifecycledash && lifecycledash.getDemographicIndexOrgManyFilterData){
                                    FilteredObj1['getDemographicIndexOrgManyFilterData'].push({
                                        "xAxis":item.xAxis,
                                        "driver":driver,
                                        "type":type,
                                        "Data":lifecycledash.getDemographicIndexOrgManyFilterData
                                    });
                                }
                            },
                            error => {
                                // dispatch(failure(error))
                            }
                        );
    
                        // });
    
                    
                }
    
            }
            return JSON.parse(JSON.stringify(FilteredObj1))
        }

        let finalList = await getLoop(data,FilteredObj)
        //////console.log("finalList------>",finalList)
        dispatch(success(FilteredObj))
    };



    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ORG_DEMOGRAPHIC_INDEX_MANY_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ORG_DEMOGRAPHIC_INDEX_MANY_SUCCESS, lifecycledash } }
    // function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ORG_DEMOGRAPHIC_INDEX_MANY_FAILURE, error } }

}


function AssignManagerActionPlan(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.AssignManagerActionPlan(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    dispatch(this.getClientProfile({}));
                    // dispatch(this.getManagerActionPlan({categoryId:data.categoryId}));
                    dispatch(this.getAllManagerActionPlan({categoryId:data.categoryId}));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_ASSIGN_MANAGER_ACTION_PLAN_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_ASSIGN_MANAGER_ACTION_PLAN_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_ASSIGN_MANAGER_ACTION_PLAN_FAILURE, error } }
}


function getManagerActionPlan(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getManagerActionPlan(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_ACTION_PLAN_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_ACTION_PLAN_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_ACTION_PLAN_FAILURE, error } }
}

function getEmployeeActionPlan(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getEmployeeActionPlan(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_EMPLOYEE_ACTION_PLAN_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_EMPLOYEE_ACTION_PLAN_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_EMPLOYEE_ACTION_PLAN_FAILURE, error } }
}



function emptyManagerActionPlan() {
    let obj = {
        getManagerActionPlanDat:[]
    }
    return dispatch => {
        dispatch(success(obj));
    };
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_ACTION_PLAN_SUCCESS, lifecycledash } }
}


function updateEmployeeTask(data,type) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.updateEmployeeTask(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    if(type===2){
                        dispatch(this.getEmployeeActionPlan());
                    }else{
                        dispatch(this.getManagerActionPlan());
                    }
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_EMPLOYEE_TASK_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_EMPLOYEE_TASK_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_EMPLOYEE_TASK_FAILURE, error } }
}


function updateEmployeeResource(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.updateEmployeeResource(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    dispatch(this.getManagerActionPlan({categoryId:data.categoryId}));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_EMPLOYEE_RESOURCE_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_EMPLOYEE_RESOURCE_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_EMPLOYEE_RESOURCE_FAILURE, error } }
}


function getAllManagerActionPlan(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getAllManagerActionPlan(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_ACTION_PLAN_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_ACTION_PLAN_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_ACTION_PLAN_FAILURE, error } }
}


function crossTab(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.crossTab(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_CROSS_TAB_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_CROSS_TAB_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_CROSS_TAB_FAILURE, error } }
}

function emptycrossTabAnalysis() {
    let obj = {
        "crossTabAnalysisData":[]
    }
    return dispatch => {
        dispatch(request());
        dispatch(success(obj));
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_CROSS_TAB_ANALYSIS_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_CROSS_TAB_ANALYSIS_SUCCESS, lifecycledash } }
    // function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_CROSS_TAB_FAILURE, error } }

}



function crossTabDMG(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.crossTabDMG(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_CROSS_TAB_DMG_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_CROSS_TAB_DMG_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_CROSS_TAB_DMG_FAILURE, error } }
}


function crossTabAnalysis(data) {
    let obj = {
        crossTabAnalysisData:[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        lifecycledashService.crossTabAnalysis(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_CROSS_TAB_ANALYSIS_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_CROSS_TAB_ANALYSIS_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_CROSS_TAB_ANALYSIS_FAILURE, error } }
}


function getAllParameterList(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getAllParameterList(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_PARAMETER_LIST_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_PARAMETER_LIST_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_PARAMETER_LIST_FAILURE, error } }
}


function getCorrelationDimenionVsDimension(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getCorrelationDimenionVsDimension(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_CORRELATION_OVERALL_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_CORRELATION_OVERALL_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_CORRELATION_OVERALL_FAILURE, error } }
}

function getCorrelationDimensionVsDimensionFilter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getCorrelationDimensionVsDimensionFilter(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_CORRELATION_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_CORRELATION_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_CORRELATION_FILTER_FAILURE, error } }
}



function getCorrelationQuestionType2(data) {
    let obj = {
        getCorrelationQuestionType2Data:[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        lifecycledashService.getCorrelationQuestionType2(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_CORRELATION_TYPE2_ANALYSIS_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_CORRELATION_TYPE2_ANALYSIS_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_CORRELATION_TYPE2_ANALYSIS_FAILURE, error } }
}



function getDemographicOrgReport(data) {
    // let obj = {
    //     getDemographicOrgReportData:[]
    // }
    // return dispatch => {
    //     dispatch(success(obj))
    //     dispatch(request());
    //     lifecycledashService.getDemographicOrgReport(data)
    //         .then(
    //             lifecycledash => dispatch(success(lifecycledash)),
    //             error => {
                    
    //                 dispatch(failure(error))
    //             }
    //         );
    // };


        
    return dispatch => {
        dispatch(request());

        let FilteredObj = {
            getDemographicOrgReportData:[]
        };
        if(data && data.length>0){
            data.forEach((item)=>{

                lifecycledashService.getDemographicOrgReport(item)
                .then(
                    lifecycledash => {
                        if(lifecycledash && lifecycledash.getDemographicOrgReportData){
                            FilteredObj['getDemographicOrgReportData'].push({
                                "xAxis":item.xAxis,
                                "Data":lifecycledash.getDemographicOrgReportData
                            });
                        }
                    },
                    error => {
                        // dispatch(failure(error))
                    }
                );


            });
        }
        dispatch(success(FilteredObj))
    };



    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_ORG_REPORT_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_ORG_REPORT_SUCCESS, lifecycledash } }
    // function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_ORG_REPORT_FAILURE, error } }
}



function EmptyTreeMap(data) {
    let obj = {
        TreeMapData:[]
    }
    let obj2 = {
        wordCloudMultiTextData:[]
    }
    return dispatch => {
        dispatch(success2(obj2))
        dispatch(success(obj))

    };
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_TREE_MAP_SUCCESS, lifecycledash } }
    function success2(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_WORD_CLOUD_MULTI_TEXT_SUCCESS, lifecycledash } }

}



function TreeMap(data) {
    let obj = {
        TreeMapData:[]
    }
    let obj2 = {
        wordCloudMultiTextData:[]
    }
    return dispatch => {
        dispatch(success2(obj2))
        dispatch(success(obj))
        dispatch(request());
        lifecycledashService.TreeMap(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_TREE_MAP_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_TREE_MAP_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_TREE_MAP_FAILURE, error } }

    function success2(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_WORD_CLOUD_MULTI_TEXT_SUCCESS, lifecycledash } }

}



function textDistTreeMap(data) {
    let obj = {
        textDistTreeMapData:[]
    }
    let obj2 = {
        wordCloudMultiTextData:[]
    }
    return dispatch => {
        dispatch(success2(obj2))
        dispatch(success(obj))
        dispatch(request());
        lifecycledashService.textDistTreeMap(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_TREE_MAP_LEN_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_TREE_MAP_LEN_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_TREE_MAP_LEN_FAILURE, error } }

    function success2(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_WORD_CLOUD_MULTI_TEXT_SUCCESS, lifecycledash } }

}















function getManagerProfile() {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getManagerProfile()
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_PROFILE_DATA_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_PROFILE_DATA_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_PROFILE_DATA_FAILURE, error } }
}


function getMNGRSurveyList() {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getMNGRSurveyList()
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_SURVEY_LIST_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_SURVEY_LIST_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_SURVEY_LIST_FAILURE, error } }
}





function getHappinessIndexByFilter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getIndexFilter(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_BY_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_BY_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_BY_FILTER_FAILURE, error } }
}

function getEngagementIndexByFilter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getIndexFilter(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_BY_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_BY_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_BY_FILTER_FAILURE, error } }
}

function getStressIndexByFilter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getIndexFilter(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_BY_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_BY_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_BY_FILTER_FAILURE, error } }
}

function getManagerIndexByFilter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getIndexFilter(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_BY_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_BY_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_BY_FILTER_FAILURE, error } }
}


function getLeaderIndexByFilter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getIndexFilter(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));

                 },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_BY_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_BY_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_BY_FILTER_FAILURE, error } }
}





function getDemographicIndexMultiFilterEngComp(data) {

    //////console.log("(((((((((((((((((((---getDemographicIndexMultiFilterEngComp---",data)

    return dispatch => {
        dispatch(request());

        let FilteredObj = {
            getDemographicIndexMultiFilterEngCompData:[]
        };
        if(data && data.length>0){
            data.forEach((item)=>{

                lifecycledashService.getDemographicIndexMultiFilterEngComp(item)
                .then(
                    lifecycledash => {
                        if(lifecycledash && lifecycledash.getDemographicIndexMultiFilterEngCompData){
                            FilteredObj['getDemographicIndexMultiFilterEngCompData'].push({
                                "xAxis":item.xAxis,
                                "Data":lifecycledash.getDemographicIndexMultiFilterEngCompData
                            });
                        }
                    },
                    error => {
                        // dispatch(failure(error))
                    }
                );


            });
        }
        dispatch(success(FilteredObj))
    };

    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_COMPARISON_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_COMPARISON_SUCCESS, lifecycledash } }
    // function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_COMPARISON_FAILURE, error } }
}



function getDemographicIndexEngCompOverall(data) {

    // //////console.log("(((((((((((((((((((---getDemographicIndexEngCompOverall---",data)

    return dispatch => {
        dispatch(request());

        let FilteredObj = {
            getDemographicIndexEngCompOverallData:[]
        };
        if(data && data.length>0){
            data.forEach((item)=>{

                lifecycledashService.getDemographicIndexEngCompOverall(item)
                .then(
                    lifecycledash => {
                        if(lifecycledash && lifecycledash.getDemographicIndexEngCompOverallData){
                            FilteredObj['getDemographicIndexEngCompOverallData'].push({
                                "xAxis":item.xAxis,
                                "Data":lifecycledash.getDemographicIndexEngCompOverallData
                            });
                        }
                    },
                    error => {
                        // dispatch(failure(error))
                    }
                );


            });
        }
        dispatch(success(FilteredObj))
    };

    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_COMPARISON_OVERALL_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_COMPARISON_OVERALL_SUCCESS, lifecycledash } }
    // function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_COMPARISON_OVERALL_FAILURE, error } }
}


function getDemographicBranchCodeIndexMultiFilter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getDemographicBranchCodeIndexMultiFilter(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_BRANCODE_INDEX_SCORE_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_BRANCODE_INDEX_SCORE_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_BRANCODE_INDEX_SCORE_FAILURE, error } }
}



function getParameterTypeList(data, check) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getParameterTypeList(data)
            .then(
                lifecycledash =>{
                     dispatch(success(lifecycledash));
                     if(check){

                            let dataParaType = {"id":""}
                            if(lifecycledash && lifecycledash.getParameterTypeListData && lifecycledash.getParameterTypeListData.list && lifecycledash.getParameterTypeListData.list.length>0){
                            let tempList = lifecycledash.getParameterTypeListData.list;
                            let getIndex = tempList.findIndex(prev=>prev.level===1);
                            dataParaType = tempList[getIndex];
                            }
                    
                            dispatch(surveyActions.getParameterLibraryListbyParameterType({
                            "parameterTypeId":dataParaType.id
                            }));

                     }
                    
                    },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_PARAMETER_TYPE_LIST_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_PARAMETER_TYPE_LIST_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_PARAMETER_TYPE_LIST_FAILURE, error } }
}


function createParameter(data, temp, isBucket) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.createParameter(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));

                    if(isBucket){
                        dispatch(surveyActions.getParameterList(temp));
                    }
                    else{
                        dispatch(surveyActions.getParameterListbyParentId(temp));

                    }
                    
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_CREATE_PARAMETER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_CREATE_PARAMETER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_CREATE_PARAMETER_FAILURE, error } }
}







function getOrgIndexV2Filter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getOrgIndexV2Filter(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_FILTER_FAILURE, error } }
}




function getHappinessIndexV2Filter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getHappinessIndexV2Filter(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPINESS_INDEX_V2_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPINESS_INDEX_V2_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPINESS_INDEX_V2_FILTER_FAILURE, error } }
}


function getEngagementIndexV2Filter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getEngagementIndexV2Filter(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FILTER_FAILURE, error } }
}


function getStressIndexV2Filter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getStressIndexV2Filter(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_FILTER_FAILURE, error } }
}





function getHappinessIndexDriverFilter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getHappinessIndexDriverFilter(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FILTER_FAILURE, error } }
}


function getEngagementIndexDriverFilter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getEngagementIndexDriverFilter(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_FAILURE, error } }
}



function getStressIndexDriverFilter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getStressIndexDriverFilter(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_DRIVER_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_DRIVER_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_DRIVER_FILTER_FAILURE, error } }
}





function getParameterLibraryList(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getParameterLibraryList(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_PARAMETER_LIBRARY_ALL_LIST_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_PARAMETER_LIBRARY_ALL_LIST_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_PARAMETER_LIBRARY_ALL_LIST_FAILURE, error } }
}





function clusterAnalysis(data) {
    let obj = {
        "clusterAnalysisData":[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        lifecycledashService.clusterAnalysis(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_CLUSTER_ANALYSIS_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_CLUSTER_ANALYSIS_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_CLUSTER_ANALYSIS_FAILURE, error } }
}



function getClusterEmpInfoList(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getClusterEmpInfoList(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_CLUSTER_EMP_INFO_LIST_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_CLUSTER_EMP_INFO_LIST_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_CLUSTER_EMP_INFO_LIST_FAILURE, error } }
}



function topicModelling(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.topicModelling(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_TOPIC_MODELLING_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_TOPIC_MODELLING_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_TOPIC_MODELLING_FAILURE, error } }
}



function createTopic(data,temp) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.createTopic(data)
            .then(
                lifecycledash =>{
                    dispatch(success(lifecycledash));
                    dispatch(this.getTopicList(temp));

                },
                
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_CREATE_TOPIC_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_CREATE_TOPIC_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_CREATE_TOPIC_FAILURE, error } }
}



function updateTopic(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.updateTopic(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_TOPIC_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_TOPIC_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_TOPIC_FAILURE, error } }
}


function deleteTopic(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.deleteTopic(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_DELETE_TOPIC_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_DELETE_TOPIC_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_DELETE_TOPIC_FAILURE, error } }
}



function getTopicList(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getTopicList(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_TOPIC_LIST_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_TOPIC_LIST_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_TOPIC_LIST_FAILURE, error } }
}

function getAllWordKeywordsText(data,topic) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getAllWordKeywordsText(data,topic)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_KEYWORDS_TEXT_LIST_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_KEYWORDS_TEXT_LIST_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_KEYWORDS_TEXT_LIST_FAILURE, error } }
}


function getOverallScoreBySurveyId(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getOverallScoreBySurveyId(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_OVERALL_SCORE_BY_SURVEY_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_OVERALL_SCORE_BY_SURVEY_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_OVERALL_SCORE_BY_SURVEY_FAILURE, error } }
}




function getAllManagerScores(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getAllManagerScores(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_SCORES_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_SCORES_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_SCORES_FAILURE, error } }
}



function createGoal(data,temp) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.createGoal(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    dispatch(this.getGoalList(temp));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_CREATE_GOAL_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_CREATE_GOAL_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_CREATE_GOAL_FAILURE, error } }
}


function getGoalList(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getGoalList(data)
            .then(
                lifecycledash => dispatch(success(lifecycledash)),
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_GOAL_LIST_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_GOAL_LIST_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_GOAL_LIST_FAILURE, error } }
}


function updateGoal(data,temp) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.updateGoal(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    dispatch(this.getGoalList(temp));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_GOAL_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_GOAL_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_GOAL_FAILURE, error } }
}


function deleteGoal(data,temp) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.deleteGoal(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    dispatch(this.getGoalList(temp));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_DELETE_GOAL_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_DELETE_GOAL_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_DELETE_GOAL_FAILURE, error } }
}

function createPlan(data,temp) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.createPlan(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    dispatch(this.getGoalList(temp));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_CREATE_PLAN_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_CREATE_PLAN_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_CREATE_PLAN_FAILURE, error } }
}


function updatePlan(data,temp) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.updatePlan(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    dispatch(this.getGoalList(temp));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_PLAN_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_PLAN_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_UPDATE_PLAN_FAILURE, error } }
}

function deletePlan(data,temp) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.deletePlan(data)
            .then(
                lifecycledash =>  {
                    dispatch(success(lifecycledash));
                    dispatch(this.getGoalList(temp));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_DELETE_PLAN_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_DELETE_PLAN_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_DELETE_PLAN_FAILURE, error } }
}


function startPlan(data,temp) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.startPlan(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    dispatch(this.getGoalList(temp));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_START_PLAN_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_START_PLAN_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_START_PLAN_FAILURE, error } }
}


 
function closePlan(data,temp) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.closePlan(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    dispatch(this.getGoalList(temp));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_CLOSE_PLAN_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_CLOSE_PLAN_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_CLOSE_PLAN_FAILURE, error } }
}



function getAllManagerGoalPlan(data,temp) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getAllManagerGoalPlan(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_GOAL_PLAN_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_GOAL_PLAN_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_GOAL_PLAN_FAILURE, error } }
}



function getIDPLastDate(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getIDPLastDate(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_IDP_LAST_DATE_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_IDP_LAST_DATE_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_IDP_LAST_DATE_FAILURE, error } }
}


function editIDPLastDate(data,temp) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.editIDPLastDate(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                    dispatch(this.getIDPLastDate(temp));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_EDIT_IDP_LAST_DATE_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_EDIT_IDP_LAST_DATE_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_EDIT_IDP_LAST_DATE_FAILURE, error } }
}




function getCompanyNameOnly(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getCompanyNameOnly(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_COMPANY_NAME_ONLY_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_COMPANY_NAME_ONLY_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_COMPANY_NAME_ONLY_FAILURE, error } }
}





function managerDownloadStatus(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.managerDownloadStatus(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_DOWNLOAD_STATUS_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_DOWNLOAD_STATUS_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_MANAGER_DOWNLOAD_STATUS_FAILURE, error } }
}




function getMngrReportDownloadStatus(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getMngrReportDownloadStatus(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_DOWNLOAD_STATUS_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_DOWNLOAD_STATUS_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_DOWNLOAD_STATUS_FAILURE, error } }
}



function getIndexByFilterAllStages(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getIndexByFilterAllStages(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_INDEX_BY_FILTER_ALL_STAGES_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_INDEX_BY_FILTER_ALL_STAGES_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_INDEX_BY_FILTER_ALL_STAGES_FAILURE, error } }
}





function getQuestionMap(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getQuestionMap(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MAP_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MAP_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_MAP_FAILURE, error } }
}




function getQuestionCustomTemplate(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getQuestionCustomTemplate(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_CUSTOM_TEMPLATE_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_CUSTOM_TEMPLATE_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_QUESTION_CUSTOM_TEMPLATE_FAILURE, error } }
}




function getCategoryOverallIndex(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getCategoryOverallIndex(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_CAT_OVERALL_INDEX_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_CAT_OVERALL_INDEX_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_CAT_OVERALL_INDEX_FAILURE, error } }
}



function getLifecycleListenById(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getLifecycleListenById(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.LIFECYCLE_GET_LIFECYCLE_LISTEN_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.LIFECYCLE_GET_LIFECYCLE_LISTEN_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.LIFECYCLE_GET_LIFECYCLE_LISTEN_FAILURE, error } }
}




function getQuestionListBySurveyIDLifecycle(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getQuestionListBySurveyIDLifecycle(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.GET_QUES_LIST_SURVEY_ID_LIFECYCLE_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.GET_QUES_LIST_SURVEY_ID_LIFECYCLE_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.GET_QUES_LIST_SURVEY_ID_LIFECYCLE_FAILURE, error } }
}




function getIndexTrendScoreFilter(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getIndexTrendScoreFilter(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.GET_INDEX_TREND_SCORE_FILTER_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.GET_INDEX_TREND_SCORE_FILTER_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.GET_INDEX_TREND_SCORE_FILTER_FAILURE, error } }
}

function getIndexTrendScore(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getIndexTrendScore(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.GET_INDEX_TREND_SCORE_OVERALL_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.GET_INDEX_TREND_SCORE_OVERALL_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.GET_INDEX_TREND_SCORE_OVERALL_FAILURE, error } }
}



function getCategoryById(data) {
    return dispatch => {
        dispatch(request());
        lifecycledashService.getCategoryById(data)
            .then(
                lifecycledash => {
                    dispatch(success(lifecycledash));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: lifecycledashConstants.GET_CATEGORY_BY_ID_REQUEST } }
    function success(lifecycledash) { return { type: lifecycledashConstants.GET_CATEGORY_BY_ID_SUCCESS, lifecycledash } }
    function failure(error) { return { type: lifecycledashConstants.GET_CATEGORY_BY_ID_FAILURE, error } }
}