import React, { Component } from "react";
import { connect } from "react-redux";
import { dashboardActions, surveyActions } from "../../_actions";
import Header from "../SurveyMgmt/Header/Header";
import Sidebar from "../Overview/Sidebar/SidebarOverview";
import { accessModule } from "../../_helpers";
import { userActions } from "../../_actions";
import LoadingOverlay from "react-loading-overlay";
import { SheetJSFT } from "./types";
import * as XLSX from "xlsx";
import AddFilter from "./Modal/AddFilter";
import AllFilters from "./Modal/AllFilters";
import NewUserModal from "./Modal/NewUserModal";
import UploadPreview from "./Modal/UploadPreview";
import moment from "moment";
import ReactPaginate from 'react-paginate';

class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveysize: 10000,
      fileUploadError: "",
      showSurveyList: true,
      showAddNewSurvey: false,
      showEditSurvey: false,
      confirmDeleteSurvey: false,
      CurrentSurveyId: "",
      CurrentSurveyName: "",
      showChooseSurveyModal: false,
      showSurveyLibrary: false,
      isPackageSelected: false,
      CurrentSurveyData: "",
      showQuestionLibrary: "",
      searchEmployee: "",

      showEmployeeModal: false,
      showNewEmployeeModal: false,

      EmpIndexA: 0,
      EmpIndexB: 8,
      employeesize2: 10,


      offset: 0,
      page: 0,
      size: 10,

      currentSurveyPage: 1,

      newEmpOtherFieldsList: [],

      savePackage: "",
      packageList: [],
      surveyValidation: {
        name: true,
        desc: true,
      },

      surveyFormData: {
        name: "",
        desc: "",
      },

      showSidebar: true,

      showCreateSurveyPopup: false,

      CurrentLanguage: "en",
      CurrentLanguageName: "English",

      showLanguageModal: false,

      openEmpFilterModal: false,
      openEmpFilterModal2: false,

      formDataFilter: {
        name: "",
        label: "",
        rank: 1,
        isDisable: false,
      },
      updateformDataFilter: {
        id: "",
        name: "",
        label: "",
        rank: 1,
      },

      showFilterNames: false,
      employeeData: "",
      showEditDetails: false,

      tempEmpData: "",
      CrrEmpFilterLabel: "",
      showInputBox: false,
      newEmpFormData: {},

      loadedDataSheet: [],
      showUploadPreview: false,
      onboardingDateField: "",
      crrFieldType: [],

      showMobileSidebar: false,
    };
  }
  componentDidMount() {
    let data = {
      keyWord: "",
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      pageNo: 1,
      size: this.state.surveysize,
    };
    this.props.dispatch(dashboardActions.getCompanyNameOnly());

    // this.props.dispatch(surveyActions.getSurveyList(data));
    this.props.dispatch(dashboardActions.getClientProfile());

    this.props.dispatch(surveyActions.getEmployeeFilterListByCompanyId());

    let datatempemployee = {
      keyWord: "",
      pageNo: 1,
      companyId: this.props.match.params.id,
      size: this.state.employeesize2,
    };

    this.props.dispatch(surveyActions.getEmployeeList(datatempemployee));

    let data2 = {
      keyWord: "",
      pageNo: 1,
      size: 100,
    };

    this.props.dispatch(surveyActions.getLanguageLabel(data2));
    this.props.dispatch(surveyActions.getLanguage(data2));
  }

  handleChange = (e) => {
    this.setState({ loadedDataSheet: [], showUploadPreview: true }, () => {
      const files = e.target.files;
      // ////console.log("files; ",files)

      if (files && files[0]) {
        let fileName = files && files[0] && files[0].name ? files[0].name : "";
        let extName1 =
          fileName && fileName.substr(fileName.length - 3)
            ? fileName.substr(fileName.length - 3)
            : "";
        let extName2 =
          fileName && fileName.substr(fileName.length - 4)
            ? fileName.substr(fileName.length - 4)
            : "";
        if (extName1 === "csv" || extName2 === "xlsx") {
          this.setState({ file: files[0] }, () => {
            console.log("this.state.file", this.state.file);
            this.setState({ fileUploadError: "" });

            //----------------------------------------------------------------------------------------------------------------------------------------------------------
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;

            if (this.state.file) {
              if (rABS) {
                reader.readAsBinaryString(this.state.file);
              } else {
                reader.readAsArrayBuffer(this.state.file);
              }
            }

            console.log(
              "rABS--------------------------------------------->",
              rABS
            );

            reader.onload = (e) => {
              /* Parse data */
              const bstr = e.target.result;
              const wb = XLSX.read(bstr, {
                type: rABS ? "binary" : "array",
                bookVBA: true,
              });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              console.log(
                "before ws--------------------------------------------->",
                ws
              );

              //-------------------------------------------------------------------------------------------------------------
              // var isDate = function (date) {
              //   return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
              // }
              let listKeys = ws ? Object.keys(ws) : [];
              if (listKeys && listKeys.length > 0) {
                listKeys.forEach((element) => {
                  if (ws[element]["t"] === "n") {
                    let wData = ws[element]["w"];
                    const toDateFormat = wData.toString();

                    if (moment(toDateFormat, "MM/DD/YY", true).isValid()) {
                      let wNewDate = moment(wData, "MM/DD/YY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        // console.log("wData--------->",ws[element])
                      }
                    } else if (
                      moment(toDateFormat, "MM/DD/YYYY", true).isValid()
                    ) {
                      let wNewDate = moment(wData, "MM/DD/YYYY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        // console.log("wData--------->",ws[element])
                      }
                    } else if (
                      moment(toDateFormat, "M/DD/YY", true).isValid()
                    ) {
                      let wNewDate = moment(wData, "M/DD/YY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        console.log("wData--------->", ws[element]);
                      }
                    } else if (
                      moment(toDateFormat, "MM/D/YY", true).isValid()
                    ) {
                      let wNewDate = moment(wData, "MM/D/YY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        console.log("wData--------->", ws[element]);
                      }
                    } else if (moment(toDateFormat, "M/D/YY", true).isValid()) {
                      let wNewDate = moment(wData, "M/D/YY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        console.log("wData--------->", ws[element]);
                      }
                    } else if (
                      moment(toDateFormat, "M/DD/YYYY", true).isValid()
                    ) {
                      let wNewDate = moment(wData, "M/DD/YYYY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        console.log("wData--------->", ws[element]);
                      }
                    } else if (
                      moment(toDateFormat, "MM/D/YYYY", true).isValid()
                    ) {
                      let wNewDate = moment(wData, "MM/D/YYYY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        console.log("wData--------->", ws[element]);
                      }
                    } else if (
                      moment(toDateFormat, "M/D/YYYY", true).isValid()
                    ) {
                      let wNewDate = moment(wData, "M/D/YYYY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        console.log("wData--------->", ws[element]);
                      }
                    }

                    // const dateFormat = ['MM/DD/YY', 'MM/DD/YYYY'];
                    // if (moment(toDateFormat, dateFormat, true).isValid()) {
                    //   // console.log('toDateFormat ws--------------------------------------------->', toDateFormat)
                    //   // console.log('wData ws--------------------------------------------->', wData)
                    //   // console.log('moment ws--------------------------------------------->', moment(toDateFormat, dateFormat, true).isValid())

                    //   let wNewDate = moment(wData, "MM/DD/YYYY").format("DD-MM-YYYY");
                    //   if (wNewDate) {
                    //     ws[element]["v"] = wNewDate
                    //     ws[element]["w"] = wNewDate
                    //   }

                    //   // console.log('valid2 ws--------------------------------------------->', ws[element]["v"])
                    //   // console.log('valid2 element--------------------------------------------->', ws[element])

                    // }
                  }
                });
              }

              console.log(
                "after ws--------------------------------------------->",
                ws
              );
              //-------------------------------------------------------------------------------------------------------------

              /* Convert array of arrays */
              let data = XLSX.utils.sheet_to_json(ws);
              // ////console.log("datadatadatadata ", data);

              // moment("06/22/2015", "MM/DD/YYYY", true).isValid()

              data = data.map((v) => ({ ...v }));

              console.log(
                "data--------------------------------------------->",
                data
              );

              setTimeout(() => {
                this.setState({
                  loadedDataSheet: data,
                  showUploadPreview: true,
                  showEmployeeModal: false,
                });
              }, 1000);
            };

            //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
          });
        } else {
          this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
          ////console.log("not csv or xlsx file")
        }
      } else {
        this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
      }
    });
  };

  // handleFile = () => {
  //   /* Boilerplate to set up FileReader */
  //   const reader = new FileReader();
  //   const rABS = !!reader.readAsBinaryString;

  //   reader.onload = (e) => {
  //     /* Parse data */
  //     const bstr = e.target.result;
  //     const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
  //     /* Get first worksheet */
  //     const wsname = wb.SheetNames[0];
  //     const ws = wb.Sheets[wsname];
  //     /* Convert array of arrays */
  //     let data = XLSX.utils.sheet_to_json(ws);
  //     // ////console.log("datadatadatadata ", data);
  //     data = data.map(v => ({ ...v }))

  //     let flag = 0;
  //     let locList = [];
  //     if (data && data.length > 0) {
  //       for (var i = 0; i < data.length; i++) {
  //         let value = validateEmail(data[i].email);
  //         ////console.log("emails:x:", data[i].email);

  //         if (value) {
  //           //all valid
  //         }
  //         else {
  //           flag = flag + 1;
  //           locList.push(i + 1);
  //         }
  //       }
  //     }
  //     function validateEmail(email) {
  //       const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //       return re.test(String(email).toLowerCase());
  //     }

  //     if (flag) {
  //       this.setState({ isNotMail: true, isNotMailPos: locList })
  //     }
  //     else {
  //       /* Update state */
  //       let datatemp0 = {
  //         "keyWord": this.state.keyWord,
  //         "pageNo": 1,
  //         "size": this.state.employeesize
  //       }
  //       let Temp1 = {
  //         "keyWord": '',
  //         "pageNo": 1,
  //         "size": 6
  //       }
  //       this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
  //         this.props.dispatch(surveyActions.saveEmployeeList(this.state.data, Temp1));
  //         this.setState({ isNotMail: false, isNotMailPos: [], showEmployeeModal: false })

  //       });
  //     }

  //   };
  //   if (this.state.file) {
  //     if (rABS) {
  //       reader.readAsBinaryString(this.state.file);
  //     } else {
  //       reader.readAsArrayBuffer(this.state.file);
  //     };
  //   }
  // }

  onDisableEmployee = (data) => {
    ////console.log("data Employee: ", data);
    let tempdata = {
      id: data.id.toString(),
      keyWord: this.state.keyWord,
      pageNo: this.state.employeepage,
      size: this.state.size,
    };

    ////console.log("asdf :: ", tempdata);
    this.props.dispatch(surveyActions.updateEmployeeStatus(tempdata));
  };

  handleSearchEmployee = (event) => {
    event.preventDefault();
    let { value } = event.target;

    this.setState({ searchEmployee: value }, () => {
      let data = {
        "keyWord": value,
        "pageNo": 1,
        "size": this.state.employeesize2
      }
      this.props.dispatch(surveyActions.getEmployeeList(data));
    })

  }

  // handleSearch = (event) => {
  //   event.preventDefault();
  //   let { value } = event.target;
  //   let { searchEmployee } = this.state;

  //   this.setState({ searchEmployee: value });
  //   this.setState({ keyWord: searchEmployee, offset: 0 });

  //   let data = {
  //     keyWord: "",
  //     pageNo: 1,
  //     companyId: this.props.match.params.id,
  //     size: this.state.employeesize2,
  //   };
  //   if (searchEmployee) {
  //     data = {
  //       keyWord: searchEmployee,
  //       pageNo: 1,
  //       companyId: this.props.match.params.id,
  //       size: this.state.size,
  //     };
  //   }
  //   this.setState({ employeeData: "" });
  //   this.props.dispatch(surveyActions.getEmployeeList(data));
  // };

  handleSearchAPI = (event) => {
    event.preventDefault();
    let { searchEmployee } = this.state;

    this.setState({ keyWord: searchEmployee, offset: 0 });

    let data = {
      keyWord: "",
      pageNo: 1,
      companyId: this.props.match.params.id,
      size: this.state.employeesize2,
    };
    if (searchEmployee) {
      data = {
        keyWord: searchEmployee,
        pageNo: 1,
        companyId: this.props.match.params.id,
        size: this.state.size,
      };
    }
    this.setState({ employeeData: "" });
    this.props.dispatch(surveyActions.getEmployeeList(data));
  };

  handleADDpackage = (survey) => {
    this.setState({ showQuestionLibrary: true });

    let { packageList } = this.state;
    if (packageList.findIndex((prev) => prev === survey.id) === -1) {
      packageList.push(survey.id);
      this.setState({ isPackageSelected: false });
    } else {
      let getIndex = packageList.findIndex((prev) => prev === survey.id);
      packageList.splice(getIndex, 1);
      this.setState({ isPackageSelected: true });
    }
    this.setState({ packageList });
  };

  onSurveyDelete = () => {
    let data = {
      id: this.state.CurrentSurveyId,
    };
    let datatempsurvey = {
      keyWord: "",
      pageNo: 1,
      size: this.state.surveysize,
    };
    this.props.dispatch(surveyActions.deleteSurvey(data, datatempsurvey));
    this.setState({
      confirmDeleteSurvey: false,
      CurrentSurveyId: "",
      CurrentSurveyName: "",
    });
  };

  onEditSurvey = (data) => {
    this.props.history.push(`/app/question/${data.id}`);
  };

  onPreviewSurvey = () => { };

  onReportSurvey = (data) => {
    this.props.history.push(`/app/surveyreport/${data.id}`);
  };

  onPublishSurvey = (data) => {
    this.props.history.push(`/app/publish/${data.id}`);
  };

  handleChooseSurveyPopup = () => {
    this.setState({
      showCreateSurveyPopup: false,
      showChooseSurveyModal: true,
    });
  };

  handleCreateSurveyPopup = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showCreateSurveyPopup: true });
  };

  handleCreateSurveyFromLibrary = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showSurveyLibrary: true });

    this.props.dispatch(surveyActions.getSurveyFromLib());
  };

  handleSurveyLibraryClose = (check) => {
    if (check) {
      this.setState({ CurrentSurveyData: false });
    } else {
      this.setState({ showSurveyLibrary: false });
    }
  };

  QuestionLibraryClose = () => {
    this.setState({
      CurrentSurveyData: false,
      savePackage: "",
      showQuestionLibrary: false,
    });
  };

  handleCreateSurveyPopupClose = () => {
    this.handleChooseSurveyPopupClose();

    let validate = {
      name: true,
      desc: true,
    };
    let tempData = {
      name: "",
      desc: "",
    };
    this.setState({
      showCreateSurveyPopup: false,
      surveyValidation: validate,
      surveyFormData: tempData,
    });
  };

  handleChooseSurveyPopupClose = () => {
    this.setState({ showChooseSurveyModal: false });
  };

  onLogout = () => {
    console.log("logout");
    this.props.dispatch(userActions.logout());
  };

  goToDashboard = () => {
    this.props.history.push("/app/dashboard");
  };

  goToGeneralDashboard = () => {
    let SurveyId = "5fa04d316ede0f0336066cce";

    this.props.history.push("/app/generaldashboard/" + SurveyId);
  };

  onDashboard = (survey) => {
    console.log("survey: ", survey);

    // let SurveyId = '5fa04d316ede0f0336066cce';

    if (survey && survey.templateId === 1) {
      this.props.history.push("/app/dashboard/" + survey.id);
    } else if (survey && survey.templateId === 2) {
      this.props.history.push("/app/dashboard/" + survey.id);
    } else if (survey && survey.templateId === 3) {
      this.props.history.push("/app/generaldashboard/" + survey.id);
    }
  };

  handleSurveyLibQuestions = (survey) => {
    this.setState({ CurrentSurveyData: survey });
  };

  handleUseSurveyPage = () => {
    let { CurrentSurveyData } = this.state;
    console.log("CurrentSurveyData: ", CurrentSurveyData);

    // let data = {
    //   "surveyId": "605993ff4e91a52e9f001f80",
    //   "surveyLib": ["60598a67ade90e2a7fbf2830", "6058a75c6ba424628f903463"]
    // }
  };
  onLogout = () => {
    console.log("logout");
    this.props.dispatch(userActions.logout());
  };
  handleSuveyPackageSubmit = () => {
    let { SurveySubmitData, packageList } = this.state;

    let resData = {
      name: SurveySubmitData.name,
      desc: SurveySubmitData.desc,
      templateId: 2,
    };
    let datatempsurvey = {
      keyWord: "",
      pageNo: 1,
      size: this.state.surveysize,
    };

    this.props.dispatch(
      surveyActions.createSurvey(resData, datatempsurvey, false, packageList)
    );

    let validate = {
      name: true,
      desc: true,
    };
    let tempData = {
      name: "",
      desc: "",
    };
    this.setState({
      showSurveyLibrary: false,
      showCreateSurveyPopup: false,
      showChooseSurveyModal: false,
      surveyValidation: validate,
      surveyFormData: tempData,
    });
  };

  addEmployeeModal = (value) => {
    this.setState({ showEmployeeModal: value });
    this.setState({ fileUploadError: "" });
  };

  prevEmpPage = () => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (EmpIndexA > 0) {
      this.setState({ EmpIndexA: EmpIndexA - 1, EmpIndexB: EmpIndexB - 1 });
    }
  };
  nextEmpPage = (IndexListIndex) => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (
      IndexListIndex &&
      IndexListIndex.length &&
      EmpIndexB < IndexListIndex.length
    ) {
      this.setState({ EmpIndexA: EmpIndexA + 1, EmpIndexB: EmpIndexB + 1 });
    }
  };

  SelectEmpPage = (val, IndexListIndex) => {
    if (
      val > 0 &&
      val <
      (IndexListIndex && IndexListIndex.length > 0
        ? IndexListIndex.length
        : 0)
    ) {
      this.setState({ currentSurveyPage: val });
      let datatempemployee = {
        keyWord: "",
        pageNo: val,
        companyId: this.props.match.params.id,
        size: this.state.employeesize2,
      };

      this.props.dispatch(surveyActions.getEmployeeList(datatempemployee));
    }
  };

  ExportAllEmployeeListData = (finalList, check) => {
    if (check) {
      let binary_univers = finalList;
      console.log("binary_univers: ", binary_univers);

      let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

      // Create a new Workbook
      var wb = XLSX.utils.book_new();

      // Name your sheet
      XLSX.utils.book_append_sheet(wb, binaryWS, "Binary values");

      // export your excel
      XLSX.writeFile(wb, "employees.xlsx");
    } else {
      this.props.dispatch(surveyActions.getAllEmployeeList({}));
    }
  };

  DownloadEmployees = (employeesItem, isFilter) => {
    // this.props.dispatch(surveyActions.getAllEmployeeList({}));

    let list = [];
    let keyList = [];
    let keyNewList = [];

    if (employeesItem && employeesItem.length > 0 && employeesItem[0]) {
      keyList = Object.keys(employeesItem[0]);
    }

    if (keyList && keyList.length > 0) {
      keyList.forEach((key) => {
        if (
          key !== "id" &&
          key !== "_id" &&
          key !== "isMNGRMoreThan5" &&
          key !== "showMNGRReport" &&
          key !== "orgRole" &&
          key !== "actionPlanStage" &&
          key !== "isTest" &&
          key !== "__v" &&
          key !== "isDisable"
        ) {
          keyNewList.push(key);
        }
      });
    }

    if (employeesItem && employeesItem.length > 0) {
      employeesItem.forEach((emp) => {
        let data = {};

        if (keyNewList && keyNewList.length > 0) {
          keyNewList.forEach((keY) => {
            data[keY] = emp[keY];
          });
        }

        list.push(data);
      });

      let finalList = [];
      if (isFilter) {
        let { getEmployeeFilterListByCompanyIdData } = this.props.survey;

        console.log("asdad: ", getEmployeeFilterListByCompanyIdData);

        if (list && list.length > 0) {
          list.forEach((emp) => {
            let tempObj = {};
            tempObj["email"] = emp["email"];
            tempObj["emp_id"] = emp["emp_id"];
            if (
              getEmployeeFilterListByCompanyIdData &&
              getEmployeeFilterListByCompanyIdData.length > 0
            ) {
              getEmployeeFilterListByCompanyIdData.forEach((filter) => {
                if (!filter.isDisable) {
                  tempObj[filter.name] = emp[filter.name];
                }
              });
            }
            finalList.push(tempObj);
          });
        }
      } else {
        finalList = list;
      }

      let binary_univers = finalList;
      console.log("binary_univers: ", binary_univers);

      let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

      // Create a new Workbook
      var wb = XLSX.utils.book_new();

      // Name your sheet
      XLSX.utils.book_append_sheet(wb, binaryWS, "Binary values");

      // export your excel
      XLSX.writeFile(wb, "employees.xlsx");
    }
  };

  handleViewDetails = (emp) => {
    this.setState({ employeeData: emp });
  };

  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });

    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },4000);
  };

  routeLink = (link) => {
    this.props.history.push("/app/" + link);
    this.setState({ CrrRoute: link });
  };

  handleEmpInput = (e) => {
    let { employeeData } = this.state;
    employeeData[e.target.name] = e.target.value;
    this.setState({ employeeData });
  };

  handleLanguage = (item) => {
    this.setState({
      CurrentLanguage: item.shortName,
      CurrentLanguageName: item.name,
      showLanguageModal: false,
    });
  };

  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  };

  onEmployeeFilter = () => {
    let { openEmpFilterModal } = this.state;
    this.props.dispatch(surveyActions.getEmployeeColumnName());
    this.setState({
      openEmpFilterModal: !openEmpFilterModal,
      openEmpFilterModal2: false,
    });
  };

  onAddFilter = () => {
    let { openEmpFilterModal2 } = this.state;
    this.props.dispatch(surveyActions.getEmployeeColumnName());
    this.setState({
      openEmpFilterModal2: !openEmpFilterModal2,
      openEmpFilterModal: false,
    });
  };

  closeEmployeeFilter = (check) => {
    this.setState({ openEmpFilterModal: check });
  };

  closeEmployeeFilter2 = (check) => {
    this.setState({ openEmpFilterModal2: check });
  };

  handleFilterInput = (e) => {
    let { formDataFilter } = this.state;
    formDataFilter["label"] = e.target.value;
    this.setState({ formDataFilter });
  };
  handleFilterRank = (e) => {
    let { formDataFilter } = this.state;
    formDataFilter["rank"] = e.target.value;
    this.setState({ formDataFilter });
  };
  updatehandleFilterRank = (value, emp) => {
    // let { updateformDataFilter } = this.state;
    // updateformDataFilter["rank"] = value;
    // this.setState({updateformDataFilter});
    this.props.dispatch(
      surveyActions.updateEmployeeFilterRank({ id: emp.id, rank: value })
    );
  };

  onEmployeeFilterDropdown = (check) => {
    this.setState({ showFilterNames: check });
  };

  handleEmployeeColumnSelect = (name) => {
    let { formDataFilter } = this.state;
    formDataFilter["name"] = name;
    formDataFilter["label"] = name;
    this.setState({ formDataFilter, showFilterNames: false });
  };

  handleFilterStatus = (value) => {
    ////console.log("handleFilterStatus::", value);
    this.props.dispatch(
      surveyActions.updateEmployeeFilterStatus({ id: value })
    );
  };

  handleShowReportStatus = (value) => {
    ////console.log("handleShowReportStatus::", value);
    this.props.dispatch(
      surveyActions.updateEmployeeShowReportStatus({ id: value })
    );
  };

  handleIsEngCompStatus = (value) => {
    ////console.log("handleShowReportStatus::", value);
    this.props.dispatch(
      surveyActions.updateEmployeeEngagementComparisonStatus({ id: value })
    );
  };

  handleIsEDAStatus = (value) => {
    ////console.log("handleShowReportStatus::", value);
    this.props.dispatch(surveyActions.updateEmployeeEDAStatus({ id: value }));
  };

  handleFilterCheck = () => {
    let { formDataFilter } = this.state;
    if (formDataFilter.isDisable) {
      formDataFilter["isDisable"] = false;
    } else {
      formDataFilter["isDisable"] = true;
    }
    this.setState({ formDataFilter });
  };

  createEmployeeFilter = (NameItem) => {
    // let { formDataFilter } = this.state;

    let { getEmployeeFilterListByCompanyIdData } = this.props.survey;
    let Rank =
      getEmployeeFilterListByCompanyIdData &&
        getEmployeeFilterListByCompanyIdData.length > 0
        ? getEmployeeFilterListByCompanyIdData.length + 1
        : 1;
    // console.log('Rank: ',Rank)

    if (NameItem) {
      let tempData = {
        name: NameItem,
        label: NameItem,
        rank: Rank,
        isDisable: false,
      };
      this.props.dispatch(surveyActions.createEmployeeFilter(tempData));
    }

    // let tempClear ={
    //   "name": "",
    //   "label": "",
    //   "isDisable": false
    // }
    // this.setState({formDataFilter:tempClear});

    this.setState({ openEmpFilterModal2: false });
  };

  onClickQuesUp = (emp1, index) => {
    let { survey } = this.props;
    let { getEmployeeFilterListByCompanyIdData } = survey;
    // ////console.log("working:onClickUp: ",emp1,index,'==>',surveyId)

    let emp2 = "";
    if (
      getEmployeeFilterListByCompanyIdData &&
      getEmployeeFilterListByCompanyIdData.length > 0 &&
      index
    ) {
      emp2 = getEmployeeFilterListByCompanyIdData[index - 1].id;
    }

    if (emp1 && emp2) {
      let data = {
        idA: emp1,
        idB: emp2,
      };
      console.log("data idA, IdB: ", data);
      this.props.dispatch(surveyActions.updateEmployeeFilterRank(data));
    }
  };

  onClickQuesDown = (emp1, index) => {
    let { survey } = this.props;
    let { getEmployeeFilterListByCompanyIdData } = survey;

    let emp2 = "";
    if (
      getEmployeeFilterListByCompanyIdData &&
      getEmployeeFilterListByCompanyIdData.length > 0 &&
      index !== getEmployeeFilterListByCompanyIdData.length - 1
    ) {
      emp2 = getEmployeeFilterListByCompanyIdData[index + 1].id;
    }

    if (emp1 && emp2) {
      let data = {
        idA: emp1,
        idB: emp2,
      };
      console.log("data idA, IdB: ", data);

      this.props.dispatch(surveyActions.updateEmployeeFilterRank(data));
    }
  };

  downLoadSampleCSV = () => {
    let binary_universObj = {};
    //-------------------------------------------------------------------------------------------------
    let { survey } = this.props;
    let { getEmployeeFieldsData } = survey;
    let empKeys =
      getEmployeeFieldsData &&
        getEmployeeFieldsData[0] &&
        getEmployeeFieldsData[0].allkeys &&
        getEmployeeFieldsData[0].allkeys.length > 0
        ? getEmployeeFieldsData[0].allkeys
        : [];
    // let ValidKeys = [];
    if (empKeys && empKeys.length > 0) {
      empKeys.forEach((key) => {
        if (
          key !== "id" &&
          key !== "_id" &&
          key !== "isMNGRMoreThan5" &&
          key !== "showMNGRReport" &&
          key !== "orgRole" &&
          key !== "actionPlanStage" &&
          key !== "isTest" &&
          key !== "__v"
        ) {
          // ValidKeys.push(key);

          binary_universObj[key] = "";
        }
      });
    }
    // console.log('---------ValidKeys--------- ',ValidKeys)
    //-------------------------------------------------------------------------------------------------

    let binary_univers = [
      {
        emp_id: "001",
        email: "sample@mail.com",
        ...binary_universObj,
      },
    ];

    console.log("binary_univers: ", binary_univers);

    let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

    // Create a new Workbook
    var wb = XLSX.utils.book_new();

    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, "Binary values");

    // export your excel
    XLSX.writeFile(wb, "sample employee.xlsx");
  };

  onDeleteFilter = (empFilter) => {
    let tempData = {
      id: empFilter.id,
    };
    this.props.dispatch(surveyActions.deleteEmployeeFilter(tempData));
  };

  handleEmployeeDetails = (emp) => {
    this.setState({ employeeData: emp, tempEmpData: emp });
  };

  onEditDetails = (check, employeeData) => {
    ////console.log("employeeData:", employeeData)
    if (employeeData && employeeData.role && employeeData.role === "ADMIN") {
      this.setState({ showEditDetails: check });
    }
  };

  handleRoleInput = (value) => {
    let { employeeData } = this.state;
    employeeData["role"] = value;
    this.setState({ employeeData });
  };

  handleTestUserInput = (value) => {
    let { employeeData } = this.state;
    employeeData["isTest"] = value;
    this.setState({ employeeData });
  };

  handleUpdateEmployeeSubmit = () => {
    let { employeeData } = this.state;

    let data = {
      id: employeeData.id,
      email: employeeData.email,
      mobile: employeeData.mobile,
      emp_id: employeeData.emp_id,
      role: employeeData.role,
      isTest: employeeData.isTest,
    };
    let tempdata = {
      keyWord: "",
      pageNo: 1,
      companyId: this.props.match.params.id,
      size: this.state.employeesize2,
    };
    ////console.log("employeeData final: ", data)
    this.setState({ showEditDetails: false });
    this.props.dispatch(surveyActions.updateEmployee(data, tempdata));
  };

  handleChangeLabel = (empData) => {
    let Id = empData && empData.id ? empData.id : "";
    let label = empData && empData.label ? empData.label : "";
    this.setState({ CrrEmpFilterLabel: label, showInputBox: Id });
  };

  handleLabelInput = (e) => {
    this.setState({ CrrEmpFilterLabel: e.target.value });
  };
  submitEmployeeFilterLabelChange = (id) => {
    let { CrrEmpFilterLabel } = this.state;
    let data = {
      id: id,
      label: CrrEmpFilterLabel,
    };
    this.props.dispatch(surveyActions.updateEmployeeFilterLabel(data));
    this.setState({ showInputBox: "" });
    setTimeout(() => {
      this.setState({ CrrEmpFilterLabel: "" });
    }, 500);
  };

  addNewEmployee = (check) => {
    this.setState({ showNewEmployeeModal: check });
  };

  handleNewEmpInput = (e) => {
    let { name, value } = e.target;
    let { newEmpFormData } = this.state;
    newEmpFormData[name] = value;
    this.setState({ newEmpFormData });
  };

  saveNewEmployee = () => {
    let { newEmpFormData, newEmpOtherFieldsList } = this.state;
    let data = {
      email: newEmpFormData.email,
      emp_id: newEmpFormData.emp_id,
      mobile: newEmpFormData.mobile,
    };
    if (newEmpOtherFieldsList && newEmpOtherFieldsList.length > 0) {
      newEmpOtherFieldsList.forEach((item) => {
        if (item.name && item.value) {
          data[item.name] = item.value;
        }
      });
    }
    ////console.log("data------------>", data)
    let datatempemployee = {
      keyWord: "",
      pageNo: 1,
      companyId: this.props.match.params.id,
      size: this.state.employeesize2,
    };
    this.props.dispatch(
      surveyActions.createEmployeeRecord(data, datatempemployee)
    );
    setTimeout(() => {
      this.setState({
        newEmpFormData: {},
        newEmpOtherFieldsList: [],
        showNewEmployeeModal: false,
      });
    }, 2000);
  };

  addNewEmpOtherField = () => {
    let { newEmpOtherFieldsList } = this.state;
    newEmpOtherFieldsList.push({
      index:
        newEmpOtherFieldsList && newEmpOtherFieldsList.length > 0
          ? newEmpOtherFieldsList.length + 1
          : 1,
      name: "",
      value: "",
    });
    this.setState({ newEmpOtherFieldsList });
  };

  handleNewEmpOtherInput = (e, index) => {
    let { newEmpOtherFieldsList } = this.state;
    let { name, value } = e.target;

    let getIndex = newEmpOtherFieldsList.findIndex(
      (prev) => prev.index === index
    );
    if (getIndex !== -1) {
      newEmpOtherFieldsList[getIndex][name] = value;
    }

    this.setState({ newEmpOtherFieldsList });
  };

  closeUploadPreview = () => {
    this.setState({ showUploadPreview: false, loadedDataSheet: [] });
  };

  saveUploadEmployee = () => {
    let { loadedDataSheet } = this.state;
    if (loadedDataSheet && loadedDataSheet.length > 0) {
      let Temp1 = {
        keyWord: "",
        pageNo: 1,
        size: 6,
      };
      this.props.dispatch(
        surveyActions.saveEmployeeList(loadedDataSheet, Temp1)
      );
    }

    // if(onboardingDateField){

    //   this.props.dispatch(surveyActions.saveEmpDateField({
    //     "onboardingField":onboardingDateField?onboardingDateField:null
    //   }));

    // }
  };

  selectOnboardingDateField = (field) => {
    this.setState({ onboardingDateField: field }, () => {
      let option = "Date (DD-MM-YYYY)";

      let { crrFieldType } = this.state;
      let temp = {
        field: field,
        option: option,
      };
      let getIndex = crrFieldType.findIndex((prev) => prev.field === field);
      if (getIndex !== -1) {
        crrFieldType[getIndex]["option"] = option;
      } else {
        crrFieldType.push(temp);
      }
      this.setState({ crrFieldType });
    });
  };

  selectFieldType = (field, option) => {
    let { crrFieldType } = this.state;
    let temp = {
      field: field,
      option: option,
    };
    let getIndex = crrFieldType.findIndex((prev) => prev.field === field);
    if (getIndex !== -1) {
      crrFieldType[getIndex]["option"] = option;
    } else {
      crrFieldType.push(temp);
    }
    this.setState({ crrFieldType });
  };

  handleSidebar = (check) => {
    this.setState({ showSidebar: check });
  };
  handleMobileSidebar = (check) => {
    this.setState({ showMobileSidebar: check });
    this.setState({ showSidebar: check });
  };


  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });

    let datatempemployee = {
      keyWord: "",
      pageNo: data.selected + 1,
      companyId: this.props.match.params.id,
      size: this.state.size,
    }
    this.props.dispatch(surveyActions.getEmployeeList(datatempemployee));
  }


  handleSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    this.setState({ size: newSize, page: 0, offset: 0 }, () => {
      let datatempemployee = {
        keyWord: "",
        pageNo: 1,
        companyId: this.props.match.params.id,
        size: newSize,
      };
      this.props.dispatch(surveyActions.getEmployeeList(datatempemployee));
    });
  }

  render() {
    let { survey, dashboard } = this.props;
    let {
      getAllEmployeeListData,
      getEmployeeFieldsData,
      saveEmployeeListData,
      loading,
      getEmployeeColumnNameData,
      getEmployeeFilterListByCompanyIdData,
      getLanguageLabelData,
      getLanguageData,
      getEmployeeListData,
      getEmployeeListTotal,
      getSurveyListData,
    } = survey;
    let { getCompanyNameOnlyData } = dashboard;
    let {
      showMobileSidebar,
      crrFieldType,
      onboardingDateField,
      showUploadPreview,
      loadedDataSheet,
      newEmpOtherFieldsList,
      showNewEmployeeModal,
      showInputBox,
      CrrEmpFilterLabel,
      showEditDetails,
      showFilterNames,
      formDataFilter,
      updateformDataFilter,
      openEmpFilterModal2,
      openEmpFilterModal,
      showEmployeeModal,
      showLanguageModal,
      CurrentLanguageName,
      CurrentLanguage,
      showSidebar,
      CrrRoute,
      employeeData,
    } = this.state;

    console.log(
      "getAllEmployeeListData:---------------------> ",
      getAllEmployeeListData
    );

    let EmployeeFilterCompanyList = [];
    if (
      getEmployeeFilterListByCompanyIdData &&
      getEmployeeFilterListByCompanyIdData.length > 0
    ) {
      getEmployeeFilterListByCompanyIdData.forEach((filter) => {
        if (!filter.isDisable) {
          EmployeeFilterCompanyList.push(filter);
        }
      });
    }
    //--------------------------------------------------------------------------------

    let IndexList = [];
    let pageCount = 7;
    let IndexListIndex = [];

    if (getEmployeeListTotal) {
      if (getEmployeeListTotal) {
        [...Array(getEmployeeListTotal).keys()].forEach((item, index) => {
          if (index % pageCount === 0) {
            IndexList.push(index);
          }
          if (index === getEmployeeListTotal - 1) {
            IndexList.push(index);
          }
        });
      }

      console.log("IndexList: ", IndexList);

      if (IndexList && IndexList.length > 0) {
        IndexList.forEach((item, index) => {
          if (index !== IndexList.length - 1) {
            IndexListIndex.push(index + 1);
          }
        });
      }
      console.log("IndexListIndex: ", IndexListIndex);
    }
    //--------------------------------------------------------------------------------

    //ROLE MANAGEMENT ----------------------------------------------

    let moduleAccess = {
      isAdmin: false,
      access: {},
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    console.log("moduleAccess: ", moduleAccess);

    let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    let EmpRole = "";
    if (clientData && clientData.role) {
      if (clientData.role === "ADMIN") {
        EmpRole = "ADMIN";
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      } else if (clientData.role === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      } else if (clientData.role === "CROLE") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      } else if (clientData.role === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return moduleAccess.isAdmin
        ? true
        : moduleAccess && moduleAccess.access && moduleAccess.access[item]
          ? true
          : false;
    }

    //END -------------------------------------------------------

    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }

    let { ClientProfileData } = this.props.dashboard;
    if (
      ClientProfileData &&
      ClientProfileData.role &&
      ClientProfileData.role === "MNGR"
    ) {
      this.props.history.push(`/app/manageractionplan`);
    } else if (
      ClientProfileData &&
      ClientProfileData.role &&
      ClientProfileData.role === "EMP"
    ) {
      this.props.history.push(`/app/employeeactionplan`);
    }

    // if(getEmployeeListData && getEmployeeListData.length>0){
    //   EmployeeListData = Object.key(getEmployeeListData)
    // }
    // console.log('getEmployeeListData: ',getEmployeeListData)
    // console.log('EmployeeListData: ',EmployeeListData)

    let EmployeeFieldList = [];
    if (getEmployeeListData && getEmployeeListData.length > 0) {
      EmployeeFieldList = Object.keys(getEmployeeListData[0]);
    }
    console.log("EmployeeFieldList: ", EmployeeFieldList);

    let NewEmployeeFieldList = [];
    if (EmployeeFieldList && EmployeeFieldList.length > 0) {
      EmployeeFieldList.forEach((item) => {
        if (
          item !== "showMNGRReport" &&
          item !== "isMNGRMoreThan5" &&
          item !== "isDisable" &&
          item !== "isTest" &&
          item !== "createdAt" &&
          item !== "id" &&
          item !== "actionPlanStage" &&
          item !== "companyId" &&
          item !== "email" &&
          item !== "emp_id"
        ) {
          NewEmployeeFieldList.push(item);
        }
      });
    }

    if (EmployeeFieldList) {
    }

    const { page, size, offset } = this.state;
    const startItem = page * size + 1;
    const endItem = Math.min((page + 1) * size, getEmployeeListTotal);

    // console.log("NewEmployeeFieldList: ", NewEmployeeFieldList);
    // console.log("employeeData: ", employeeData);
    // console.log("---------> IndexListIndex: ", IndexListIndex);

    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text="Loading Data..."
          className="loader"
        >
          <div className="h-screen overflow-hidden bg-gray-50">
            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              props={this.props}
              companyName={
                getCompanyNameOnlyData && getCompanyNameOnlyData.name
                  ? getCompanyNameOnlyData.name
                  : ""
              }
              sidebarToggle={this.sidebarToggle}
            />

            <div
              className="flex w-full"
              style={{ height: "calc(100% - 2rem)" }}
            >
              <Sidebar
                onOverview={this.onOverview}
                showSidebar={showSidebar}
                handleSidebar={this.handleSidebar}
                accessAllow={accessAllow}
                routeLink={this.routeLink}
                showMobileSidebar={this.state.showMobileSidebar}
                sidebarToggle={this.sidebarToggle}
              />
              <main className="flex-1 w-full overflow-hidden overflow-y-auto h-[calc(100vh-4rem)] bg-[#f9fafb]">
                {accessAllow("uploadEmp") ? (
                  <>
                    {employeeData ? (
                      <div className="h-full p-4 overflow-hidden overflow-y-auto bg-gray-100">
                        <div className="p-6 bg-white">
                          <div className="w-full lg:flex ">
                            {/* first */}
                            <div className="flex justify-center text-center lg:w-8/12 ">
                              <div className>
                                <div className="relative">
                                  <img
                                    src="img/profile/user1.png"
                                    alt="users"
                                    className="my-4 rounded-full lg:w-40 w-36 h-36 lg:h-40"
                                  />
                                  <span
                                    className="absolute bottom-0 right-0 p-2 text-white bg-pink-500 rounded-full material-symbols-outlined"
                                    style={{ fontSize: "18px" }}
                                  >
                                    photo_camera
                                  </span>{" "}
                                </div>
                                <h1 className="font-medium text-blue-500">
                                  {employeeData && employeeData.name
                                    ? employeeData.name
                                    : "Na"}
                                </h1>
                                <p className="text-xs text-gray-500">
                                  {employeeData && employeeData.role
                                    ? employeeData.role
                                    : ""}
                                </p>

                                {employeeData &&
                                  employeeData.role &&
                                  employeeData.role === "ADMIN" ? (
                                  <div
                                    onClick={() =>
                                      this.onEditDetails(true, employeeData)
                                    }
                                    className="flex justify-center"
                                  >
                                    <button className="flex items-center px-6 py-2 my-4 text-xs text-blue-500 border border-blue-500 rounded-full bg-blue-50 focus:outline-none">
                                      Edit Details{" "}
                                      <span
                                        className="pl-2 material-symbols-outlined"
                                        style={{ fontSize: "18px" }}
                                      >
                                        edit
                                      </span>
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            {showEditDetails ? (
                              <>
                                <div className="w-full md:px-6 lg:pr-4 lg:pl-8 ">
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Email
                                    </label>
                                    <input
                                      type="text"
                                      name="email"
                                      value={employeeData.email}
                                      onChange={() => { }}
                                      disabled={true}
                                      placeholder="employee01mailinator.com"
                                      className="block w-full p-1 text-sm placeholder-black border text-slate-800"
                                    />
                                  </div>
                                  {/* 3 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Employee Id
                                    </label>
                                    <input
                                      type="text"
                                      name="emp_id"
                                      value={employeeData.emp_id}
                                      onChange={() => { }}
                                      disabled={true}
                                      placeholder={194808}
                                      className="block w-full p-1 text-sm placeholder-black border text-slate-800"
                                    />
                                  </div>
                                  {/* 1 */}
                                  <div className="pb-4 ">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Mobile{" "}
                                    </label>
                                    <input
                                      type="text"
                                      name="mobile"
                                      value={employeeData.mobile}
                                      onChange={() => { }}
                                      disabled={true}
                                      placeholder="+91 XXXXXXXXXX"
                                      className="block w-full p-1 text-sm placeholder-black border text-slate-800"
                                    />
                                  </div>
                                </div>

                                <div className="w-full md:px-6 lg:px-4">
                                  {/* 2 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Role
                                    </label>
                                    <div className="flex">
                                      {/* <div className=""><input checked={employeeData && employeeData.role==="ADMIN"?true:false} type="radio" onClick={()=>this.handleRoleInput("ADMIN")} onChange={()=>{}}/></div><div className="px-2 text-gray-500">ADMIN</div> */}
                                      <div className="pl-2">
                                        <input
                                          checked={
                                            employeeData &&
                                              employeeData.role === "CROLE"
                                              ? true
                                              : false
                                          }
                                          type="radio"
                                          onClick={() =>
                                            this.handleRoleInput("CROLE")
                                          }
                                          onChange={() => { }}
                                        />
                                      </div>
                                      <div className="px-2 text-gray-500">
                                        CROLE
                                      </div>
                                      <div className="pl-2">
                                        <input
                                          checked={
                                            employeeData &&
                                              employeeData.role === "MNGR"
                                              ? true
                                              : false
                                          }
                                          type="radio"
                                          onClick={() =>
                                            this.handleRoleInput("MNGR")
                                          }
                                          onChange={() => { }}
                                        />
                                      </div>
                                      <div className="px-2 text-gray-500">
                                        MNGR
                                      </div>
                                      <div className="pl-2">
                                        <input
                                          checked={
                                            employeeData &&
                                              employeeData.role === "EMP"
                                              ? true
                                              : false
                                          }
                                          type="radio"
                                          onClick={() =>
                                            this.handleRoleInput("EMP")
                                          }
                                          onChange={() => { }}
                                        />
                                      </div>
                                      <div className="px-2 text-gray-500">
                                        EMP
                                      </div>
                                    </div>
                                  </div>
                                  {/* 3 */}
                                  <div className="pb-4 ">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Test User
                                    </label>
                                    <div className="flex">
                                      <div className="">
                                        <input
                                          type="radio"
                                          checked={
                                            employeeData && employeeData.isTest
                                              ? true
                                              : false
                                          }
                                          onClick={() =>
                                            this.handleTestUserInput(true)
                                          }
                                          onChange={() => { }}
                                        />
                                      </div>
                                      <div className="px-2 text-gray-500">
                                        Yes
                                      </div>
                                      <div className="pl-2">
                                        <input
                                          type="radio"
                                          checked={
                                            employeeData && employeeData.isTest
                                              ? false
                                              : true
                                          }
                                          onClick={() =>
                                            this.handleTestUserInput(false)
                                          }
                                          onChange={() => { }}
                                        />
                                      </div>
                                      <div className="px-2 text-gray-500">
                                        No
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="w-full md:px-6 lg:pr-4 lg:pl-8 ">
                                  {/* 1 */}
                                  <div className="pb-4 ">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Employee Name:
                                    </label>
                                    <input
                                      placeholder={
                                        employeeData && employeeData.name
                                          ? employeeData.name
                                          : "NaN"
                                      }
                                      disabled={true}
                                      className="block w-full p-1 text-sm placeholder-black bg-white text-slate-800"
                                    />
                                  </div>
                                  {/* 2 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Email:
                                    </label>
                                    <input
                                      placeholder={
                                        employeeData && employeeData.email
                                          ? employeeData.email
                                          : "NaN"
                                      }
                                      disabled={true}
                                      className="block w-full p-1 text-sm placeholder-black bg-white text-slate-800"
                                    />
                                  </div>
                                  {/* 3 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Employee Id:
                                    </label>
                                    <input
                                      placeholder={
                                        employeeData && employeeData.emp_id
                                          ? employeeData.emp_id
                                          : "NaN"
                                      }
                                      disabled={true}
                                      className="block w-full p-1 text-sm placeholder-black bg-white text-slate-800"
                                    />
                                  </div>
                                  {/* 4 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Mobile:
                                    </label>
                                    <input
                                      placeholder={
                                        employeeData && employeeData.mobile
                                          ? employeeData.mobile
                                          : "NaN"
                                      }
                                      disabled={true}
                                      className="block w-full p-1 text-sm placeholder-black bg-white text-slate-800"
                                    />
                                  </div>
                                  {/* 5 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Gender:
                                    </label>
                                    <input
                                      placeholder={
                                        employeeData && employeeData.Gender
                                          ? employeeData.Gender
                                          : "NaN"
                                      }
                                      disabled={true}
                                      className="block w-full p-1 text-sm placeholder-black bg-white text-slate-800"
                                    />
                                  </div>
                                </div>
                                <div className="w-full md:px-6 lg:px-4">
                                  {/* 2 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Role:
                                    </label>
                                    <input
                                      placeholder={
                                        employeeData && employeeData.role
                                          ? employeeData.role
                                          : "NaN"
                                      }
                                      disabled={true}
                                      className="block w-full p-1 text-sm placeholder-black bg-white text-slate-800"
                                    />
                                  </div>
                                  {/* 3 */}
                                  <div className="pb-4 ">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Test User:
                                    </label>
                                    <input
                                      placeholder={
                                        employeeData && employeeData.isTest
                                          ? "Yes"
                                          : "No"
                                      }
                                      disabled={true}
                                      className="block w-full p-1 text-sm placeholder-black bg-white text-slate-800"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          <div className="flex justify-center mt-4 lg:justify-end md:px-4">
                            {showEditDetails ? (
                              <button
                                onClick={() =>
                                  this.handleUpdateEmployeeSubmit()
                                }
                                className="px-6 py-2 mr-1 text-xs text-white uppercase bg-blue-500"
                              >
                                update
                              </button>
                            ) : null}
                            {showEditDetails ? (
                              <button
                                onClick={() =>
                                  this.onEditDetails(false, employeeData)
                                }
                                className="px-6 py-2 ml-1 text-xs font-medium text-red-500 uppercase bg-white border-2 border-red-500 "
                              >
                                cancel
                              </button>
                            ) : (
                              <button
                                onClick={() => this.handleEmployeeDetails("")}
                                className="px-6 py-2 ml-1 text-xs font-medium text-gray-500 uppercase bg-white border-2 "
                              >
                                close
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex-1 md:p-6 p-4 w-full bg-[#f9fafb]  overflow-hidden overflow-y-auto h-[calc(100vh-4rem)] ">
                        {/* <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
                          <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]   ">
                            Employees
                          </h1>

                          <div className="md:flex hidden items-center text-sm">
                            <span className="mx-2 material-symbols-rounded">
                              home
                            </span>
                            <span>/</span>
                            <span className="px-2">My survey</span>
                            <span>/</span>
                            <span className="px-2 font-semibold">
                              Employee Experience
                            </span>
                          </div>
                        </div> */}

                        <div className="flex flex-col p-4 space-y-4 overflow-hidden bg-white border rounded-lg md:p-6">


                          <div className="items-stretch justify-between space-y-4 lg:flex md:space-x-6 md:space-y-0">
                            <div className="flex space-x-3 items-center">
                              <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]"> Employees List</h1>
                              <div className="flex space-x-2 capitalize items-center text-sm text-[#3D405B] font-medium">
                                <span>Show</span>
                                <form class="w-16">
                                  <select id="entries" value={size} onChange={this.handleSizeChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full px-2 py-1.5 focus:outline-none">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </form>
                                <span>entries</span>
                              </div>
                            </div>

                            <div className="flex space-x-4">
                              <div className="w-full flex justify-between border px-4 text-[#3D405B]/70 bg-white rounded-md text-sm items-center  overflow-hidden">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <circle cx="10" cy="10" r="7" /> <line x1="21" y1="21" x2="15" y2="15" />
                                </svg>
                                <input type="search" name="search" onChange={this.handleSearchEmployee} placeholder={LanguageObj && LanguageObj["search_by_email_text_" + CurrentLanguage] ? LanguageObj["search_by_email_text_" + CurrentLanguage] : "Search by email..."} className="w-56 focus:outline-none px-2 py-2 text-[#3D405B] placeholder:font-normal font-medium " />
                              </div>
                              {EmpRole === "ADMIN" ? (
                                <button onClick={() => this.addEmployeeModal(true)} className="cursor-pointer bg-[#2196F3] text-white space-x-1 text-sm  border-[#2196F3] flex justify-between px-4 items-center py-2 rounded-md whitespace-nowrap">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="9" />  <line x1="9" y1="12" x2="15" y2="12" />  <line x1="12" y1="9" x2="12" y2="15" /></svg><span> Add Employee</span>
                                </button>
                              ) : null}
                            </div>
                          </div>

                          <div className="w-full overflow-x-auto scroll-smooth whitespace-nowrap">
                            <div className="inline-block min-w-full overflow-hidden align-middle ">
                              <table className="min-w-full border">
                                <thead>
                                  <tr className="capitalize text-[#3D405B] border-b ">
                                    <th className="w-20 p-3 text-sm font-medium text-left border-r">{" "}{LanguageObj && LanguageObj["sno_label_" + CurrentLanguage] ? LanguageObj["sno_label_" + CurrentLanguage] : "sno"}
                                    </th>
                                    <th className="p-3 text-sm font-semibold text-left border-r w-60 ">{" "}{LanguageObj && LanguageObj["emp_id_label_" + CurrentLanguage] ? LanguageObj["emp_id_label_" + CurrentLanguage] : "emp_id"}
                                    </th>
                                    <th className="p-3 text-sm font-semibold text-left border-r w-80">{LanguageObj && LanguageObj["email_address_label_" + CurrentLanguage] ? LanguageObj["email_address_label_" + CurrentLanguage] : "email"}
                                    </th>

                                    {NewEmployeeFieldList && NewEmployeeFieldList.length > 0 ? NewEmployeeFieldList.map((item) => !item.isDisable ? (
                                      <th className={EmployeeFilterCompanyList.some((prev) => prev.name === item) ? " p-3  text-left text-sm  font-semibold border-r w-40 " : " p-3  text-left text-sm  font-semibold border-r w-40 "} >
                                        <div className="flex">
                                          <span className=""> {item} </span> {EmployeeFilterCompanyList.some((prev) => prev.name === item) ? (
                                            <span className="hidden py-2 material-symbols-outlined"
                                              style={{ height: "5px", width: "5px", }} > filter_list
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </th>
                                    ) : null
                                    )
                                      : null}
                                    <th className="w-40 p-3 text-sm font-semibold text-left capitalize border-r">
                                      {LanguageObj && LanguageObj["action_label_" + CurrentLanguage] ? LanguageObj["action_label_" + CurrentLanguage] : "Action"}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y">
                                  {getEmployeeListData &&
                                    getEmployeeListData.length > 0
                                    ? getEmployeeListData.map((emp, index) => (
                                      <tr className="odd:bg-[#F9FAFB] even:bg-white text-sm">
                                        <td className="px-2 py-1.5 whitespace-no-wrap border-r text-center">
                                          <div className="text-sm text-gray-600 ">
                                            {(this.state.currentSurveyPage - 1) * this.state.size + (index + 1)}
                                          </div>
                                        </td>

                                        <td className="px-2 py-1.5 whitespace-no-wrap border-r ">
                                          <div className="text-sm text-gray-600 ">
                                            {emp.emp_id}
                                          </div>
                                        </td>

                                        <td className="px-2 py-1.5 whitespace-no-wrap border-r ">
                                          <div className="text-sm text-gray-600 ">
                                            {emp.email}
                                          </div>
                                        </td>

                                        {NewEmployeeFieldList &&
                                          NewEmployeeFieldList.length > 0
                                          ? NewEmployeeFieldList.map((item) =>
                                            !item.isDisable ? (
                                              <td className="px-2 py-1.5 whitespace-no-wrap border-r ">
                                                <div className="text-sm text-gray-600 ">
                                                  {emp && emp[item]
                                                    ? emp[item]
                                                    : "------"}
                                                </div>
                                              </td>
                                            ) : null
                                          )
                                          : null}

                                        {/* 
                                      <td className="px-2 py-1.5 text-sm font-medium leading-5 text-center whitespace-no-wrap border-b border-r border-gray-200">
                                        <span className="inline-flex px-2 text-xs font-medium leading-5 text-green-800 bg-green-100 rounded-full">
                                          {emp.isDisable ? "Inactive" : "Active"}</span>
                                      </td> */}

                                        <td className="px-2 py-1.5 text-sm font-medium leading-5 text-center whitespace-no-wrap border-r">
                                          <span
                                            onClick={() =>
                                              this.handleEmployeeDetails(emp)
                                            }
                                            className="p-2 text-gray-500 rounded-md cursor-pointer material-symbols-outlined hover:bg-blue-100 hover:text-blue-500"
                                            style={{ fontSize: "16px" }}
                                          >
                                            edit
                                          </span>
                                        </td>
                                      </tr>
                                    ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          {/* Pagination start */}
                          <div class="sm:flex sm:flex-1 sm:items-center items-center justify-center sm:justify-between">
                            <div className="text-center">
                              <p class="text-sm text-gray-700 md:pb-0 pb-2">
                                Showing
                                <span class="font-medium"> {startItem} </span>
                                to
                                <span class="font-medium"> {endItem} </span>
                                of
                                <span class="font-medium"> {getEmployeeListTotal} </span>
                                results
                              </p>
                            </div>
                            <div className="relative z-0 flex md:justify-end justify-center w-76">
                              {
                                getEmployeeListTotal && getEmployeeListTotal > 10 ?
                                  <ReactPaginate
                                    previousLabel={'Prev'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={getEmployeeListTotal / this.state.size}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={1}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-cls'}
                                    activeClassName={'active'}
                                  />
                                  : null}
                            </div>
                          </div>

                        </div>
                      </div>
                    )}

                    {/* EMPLOYEE FILTER */}
                    {/* {openEmpFilterModal?
                      <AddFilter
                      closeEmployeeFilter={this.closeEmployeeFilter} 
                      onEmployeeFilterDropdown={this.onEmployeeFilterDropdown} 
                      handleEmployeeColumnSelect={this.handleEmployeeColumnSelect} 
                      handleFilterCheck={this.handleFilterCheck} 
                      createEmployeeFilter={this.createEmployeeFilter} 
                      onDeleteFilter={this.onDeleteFilter} 
                      handleFilterStatus={this.handleFilterStatus} 

                      showFilterNames={showFilterNames}
                      formDataFilter={formDataFilter}
                      getEmployeeColumnNameData={getEmployeeColumnNameData}
                      EmployeeFilterCompanyList={EmployeeFilterCompanyList}
                      LanguageObj={LanguageObj}
                      CurrentLanguage={CurrentLanguage}
                      getEmployeeFilterListByCompanyIdData={getEmployeeFilterListByCompanyIdData}
                      handleFilterRank={this.handleFilterRank}
                      updateformDataFilter={updateformDataFilter}
                      updatehandleFilterRank={this.updatehandleFilterRank}

                      onClickQuesUp={this.onClickQuesUp}
                      onClickQuesDown={this.onClickQuesDown}

                      />
                    :
                    null} */}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div
                        className="container h-full px-4 py-12 mx-auto overflow-hidden overflow-y-auto"
                        style={{ height: "calc(100% - 4rem)" }}
                      >
                        <div className="flex items-center justify-center w-full h-full">
                          {/*  text start*/}
                          <div className="text-center">
                            <img
                              src="img/empty_survey.png"
                              alt="survey"
                              className="mb-4"
                            />
                            <h1 className="text-xl font-semibold text-gray-500 cursor-default">
                              You have no access <br /> to this module!
                            </h1>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}

                {showEmployeeModal ? (
                  <div className="fixed top-0 right-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-40 xl:h-screen">
                    <div className="w-11/12 mx-auto bg-white rounded-lg shadow-sm xl:w-6/12 lg:w-9/12 ">
                      {/* head */}
                      <div className="flex items-center justify-between p-4 border-b md:px-8 ">
                        <h2 className="text-lg font-semibold md:text-xl test-gray-800 ">
                          Add Employees
                        </h2>

                        <div
                          onClick={() => this.addEmployeeModal(false)}
                          className="p-2 rounded-full cursor-pointer bg-slate-100 hover:bg-slate-200 "
                        >
                          <svg
                            className="w-6 h-6 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path>
                          </svg>
                        </div>
                      </div>

                      <div className="m-4 space-y-8 md:m-8 ">
                        {/* data here */}
                        <div className="p-4 space-y-2 text-sm border divide-y rounded-md text-slate-800">
                          <h1 className="text-lg font-semibold text-slate-800 ">
                            {" "}
                            Guidelines for uploading the data
                          </h1>
                          <ol className="list-disc pl-6 space-y-1.5 py-4 text-slate-800 ">
                            <li className="">
                              {" "}
                              Mandatory Column include emp_id and email as first
                              column & second column should be in the file.
                            </li>
                            <li className="capitalize">
                              {" "}
                              Your file columns/headers should not contain any
                              special characters – this includes characters that
                              are not numbers or the English alphabet.
                            </li>
                            <li className="capitalize">
                              {" "}
                              Be careful not to include duplicates of the same
                              row – no two employees should have the same emp_id
                              or email.
                            </li>
                            <li className="capitalize">
                              {" "}
                              Date format must be same as DD-MM-YYYY
                            </li>
                            <li className="capitalize">
                              {" "}
                              See the sample data format for reference and
                              prepare your master data accordingly without any
                              change in mandatory column names
                            </li>
                            <li className="capitalize">
                              {" "}
                              Make sure your Excel sheet is not password
                              protected.
                            </li>

                            {/* <li className='capitalize' ><b>Mandatory column</b> include full Name, Email id, Doj, department, location. <br />
                                <p className='text-sm' ><b>Please Note :</b>  if case you have left any column blanks, the same will be reflect on the dashboard as blank.</p>

                              </li> */}
                            {/* <li className='font-semibold' >About Preset Language column:</li>

                              <ul className='pl-6 space-y-1 list-circle'  >
                                <li>  Amber now supports 4 languages: English, Malay, Indonesian, and French. This column helps Amber know employee’s preset language and she converses using the same.</li>
                                <li> If language support is <b className='font-semibold' >disabled</b> for your account, then leave cells blank. Amber will continue reaching out in English. Note: By default, language support is disabled for your account.</li>
                                <li>  If language support is <b className='font-semibold' >enabled</b> for your account, then enter preset language value for each employee as English, Malay, Indonesian, or French</li>
                                <li> If language support was ever enabled in the past (and is now disabled), enter preset language value for each employee as English</li>
                                <li> If you want to enable/disable language support then reach out to your customer success manager or contact us at help@infeedo.com</li>
                              </ul> */}
                            {/* <li><b>Do not remove any column</b> from file if not related to your organization </li>
                              <li className='font-semibold' ><b className='font-semibold' >Date format</b> must be same as sample file </li>
                              <li>Ensure there are no <b className='font-semibold' >duplicate email id and employee code</b></li> */}
                            {/* <li>Make Sure Primary Admin/Admin/subAdmin or the user who is, included in the file while uploading the master file  to avoid  being marked as <b className='font-semibold' >ex-employee</b> </li>
                              <li>Ensure <b className='font-semibold'
                              >DoJs are correct </b>to avoid amber reachig out to employee on incorrect occasions. </li>
                              <li>Mention <b className='font-semibold' >leaving Date </b> to mark an employee as <b>Ex-employee</b></li> */}
                          </ol>
                        </div>

                        {/* <div className="m-4 mt-2">
                          <div className="flex pb-2">
                            <div className="text-gray-500">
                              <span className="font-semibold cursor-pointer">Mandatory fields* : </span>
                              <span className="text-blue-500">emp_id, email</span>
                            </div>
                            <div className="ml-12">
                              <span className="text-red-500">{fileUploadError}</span>
                            </div>
                          </div>

                          <div className="py-4 text-xs text-center text-gray-500 border-2 border-dotted rounded-md bg-gray-50"> <span className="text-blue-500 material-symbols-outlined" style={{ fontSize: '42px' }}>cloud_upload</span>

                            <div className="">
                              <input type="file" className="ml-12 py-1.5 rounded-md focus:outline-none font-medium"
                                id="file" accept={SheetJSFT} onChange={this.handleChange} />
                            </div>
                          </div>
                        </div> */}

                        <div className="space-y-4">
                          <div className="grid grid-cols-1 gap-4 xl:grid-cols-4 md:grid-cols-2">
                            {/* 1 */}
                            <div
                              onClick={() => this.downLoadSampleCSV()}
                              className="flex items-start p-2 px-4 space-x-4 rounded shadow-sm cursor-pointer 2xl:items-center bg-sky-100 md:col-span-2"
                            >
                              <span class="material-symbols-outlined p-2 bg-white rounded-full text-blue-500 shadow-sm">
                                download
                              </span>
                              <div>
                                <h1 className="text-sm font-medium md:text-base text-slate-800 ">
                                  Download Sample Data
                                </h1>
                                <p className="text-sm text-gray-500">
                                  See the sample data format for reference
                                </p>
                              </div>
                            </div>

                            {/* 2 */}
                            <input
                              type="file"
                              id="file"
                              accept={SheetJSFT}
                              onChange={this.handleChange}
                              hidden
                            />
                            <label
                              for="file"
                              className="rounded shadow-sm cursor-pointer bg-sky-100 md:col-span-2"
                            >
                              <div className="flex items-start p-2 px-4 space-x-4 2xl:items-center ">
                                <span class="material-symbols-outlined p-2 bg-white rounded-full text-blue-500 shadow-sm">
                                  upload
                                </span>
                                <div>
                                  <h1 className="text-sm font-medium md:text-base text-slate-800 ">
                                    Upload Master Data
                                  </h1>
                                  <p className="text-sm text-gray-500">
                                    Upload master data as per the guidelines
                                  </p>
                                </div>
                              </div>
                            </label>

                            {/* 3 */}
                            {/* <div className='flex items-start p-2 px-4 space-x-4 bg-blue-100 rounded shadow-md cursor-pointer 2xl:items-center md:col-span-4' >
                                <span class="material-symbols-outlined">
                                  person_off
                                </span>
                                <div>
                                  <h1 className='text-sm font-medium md:text-base' >Add/update/disable employee</h1>
                                  <p className='text-sm text-gray-500' >See the sample format our system</p>
                                </div>
                              </div> */}
                          </div>

                          <div className="hidden">
                            <button
                              onClick={() => this.downLoadSampleCSV()}
                              className=" border border-green-500 rounded-md px-6 py-1.5 text-xs text-green-500 focus:outline-none"
                            >
                              Sample CSV
                            </button>

                            <button
                              onClick={this.handleFile}
                              className="ml-22 border border-blue-500 rounded-md px-6 py-1.5 text-xs text-blue-500 focus:outline-none"
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {openEmpFilterModal ? (
                  <AllFilters
                    closeEmployeeFilter={this.closeEmployeeFilter}
                    onEmployeeFilterDropdown={this.onEmployeeFilterDropdown}
                    handleEmployeeColumnSelect={this.handleEmployeeColumnSelect}
                    handleFilterCheck={this.handleFilterCheck}
                    createEmployeeFilter={this.createEmployeeFilter}
                    onDeleteFilter={this.onDeleteFilter}
                    handleFilterStatus={this.handleFilterStatus}
                    handleShowReportStatus={this.handleShowReportStatus}
                    handleIsEngCompStatus={this.handleIsEngCompStatus}
                    handleIsEDAStatus={this.handleIsEDAStatus}
                    showFilterNames={showFilterNames}
                    formDataFilter={formDataFilter}
                    getEmployeeColumnNameData={getEmployeeColumnNameData}
                    EmployeeFilterCompanyList={EmployeeFilterCompanyList}
                    LanguageObj={LanguageObj}
                    CurrentLanguage={CurrentLanguage}
                    getEmployeeFilterListByCompanyIdData={
                      getEmployeeFilterListByCompanyIdData
                    }
                    handleFilterRank={this.handleFilterRank}
                    updateformDataFilter={updateformDataFilter}
                    updatehandleFilterRank={this.updatehandleFilterRank}
                    onClickQuesUp={this.onClickQuesUp}
                    onClickQuesDown={this.onClickQuesDown}
                    handleChangeLabel={this.handleChangeLabel}
                    CrrEmpFilterLabel={CrrEmpFilterLabel}
                    handleLabelInput={this.handleLabelInput}
                    showInputBox={showInputBox}
                    submitEmployeeFilterLabelChange={
                      this.submitEmployeeFilterLabelChange
                    }
                  />
                ) : null}

                {openEmpFilterModal2 ? (
                  <AddFilter
                    closeEmployeeFilter={this.closeEmployeeFilter2}
                    onEmployeeFilterDropdown={this.onEmployeeFilterDropdown}
                    handleEmployeeColumnSelect={this.handleEmployeeColumnSelect}
                    handleFilterCheck={this.handleFilterCheck}
                    createEmployeeFilter={this.createEmployeeFilter}
                    onDeleteFilter={this.onDeleteFilter}
                    handleFilterStatus={this.handleFilterStatus}
                    showFilterNames={showFilterNames}
                    formDataFilter={formDataFilter}
                    getEmployeeColumnNameData={getEmployeeColumnNameData}
                    EmployeeFilterCompanyList={EmployeeFilterCompanyList}
                    LanguageObj={LanguageObj}
                    CurrentLanguage={CurrentLanguage}
                    getEmployeeFilterListByCompanyIdData={
                      getEmployeeFilterListByCompanyIdData
                    }
                    handleFilterRank={this.handleFilterRank}
                    updateformDataFilter={updateformDataFilter}
                    updatehandleFilterRank={this.updatehandleFilterRank}
                    onClickQuesUp={this.onClickQuesUp}
                    onClickQuesDown={this.onClickQuesDown}
                  />
                ) : null}

                {showNewEmployeeModal ? (
                  <>
                    <NewUserModal
                      newEmpFormData={this.state.newEmpFormData}
                      handleNewEmpInput={this.handleNewEmpInput}
                      saveNewEmployee={this.saveNewEmployee}
                      addNewEmployee={this.addNewEmployee}
                      addNewEmpOtherField={this.addNewEmpOtherField}
                      newEmpOtherFieldsList={newEmpOtherFieldsList}
                      handleNewEmpOtherInput={this.handleNewEmpOtherInput}
                    />
                  </>
                ) : null}

                {showUploadPreview ? (
                  <>
                    <UploadPreview
                      loadedDataSheet={loadedDataSheet}
                      closeUploadPreview={this.closeUploadPreview}
                      saveUploadEmployee={this.saveUploadEmployee}
                      handleChange={this.handleChange}
                      SheetJSFT={SheetJSFT}
                      saveEmployeeListData={saveEmployeeListData}
                      selectOnboardingDateField={this.selectOnboardingDateField}
                      onboardingDateField={onboardingDateField}
                      selectFieldType={this.selectFieldType}
                      crrFieldType={crrFieldType}
                      getEmployeeFieldsData={getEmployeeFieldsData}
                    />
                  </>
                ) : null}
              </main>
            </div>
          </div>
        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard,
  };
}
export default connect(mapStateToProps)(Employees);