import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurveyName: '',
      currentSurveyId: '',

      showDropdown: false,

      showSidebar: false,


      showSubMenu: false
    }
  }
  componentDidMount() {
  }

  onDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false })
    }
    else {
      this.setState({ showDropdown: true })
    }
  }

  logout = () => {
    this.props.dispatch(userActions.logout());
  }



  onSubMenu = (check) => {
    if (this.state.showSidebar) {
      this.setState({ showSubMenu: check });
    }
  }


  sidebarToggle = () => {
    if (this.state.showSubMenu) {
      this.setState({ showSubMenu: false })
    }
    this.setState({ showSidebar: !this.state.showSidebar })
  }


  render() {
    let { handleSidebarClick, showOverview } = this.props;
    let { showSidebar } = this.state;

    // console.log('OverviewList: ',OverviewList);

    return (
      <>

        <div className={showSidebar ?
          "cursor-pointer w-64 lg:block  lg:relative fixed top-0 left-0  h-screen lg:z-20 z-20 overflow-y-auto overflow-hidden transition duration-300 transform bg-white border-r sidebar-scrollar hidden" :

          "cursor-pointer w-16 lg:block  lg:relative fixed top-0 left-0  h-screen lg:z-20 z-20 overflow-y-auto overflow-hidden transition duration-300 transform bg-white border-r sidebar-scrollar hidden"
        } style={{ height: 'calc(100% - 4rem)' }} >
          <div className="flex items-center ">
            <div className="flex items-center px-4 py-3 w-16  text-blue-500  justify-center">
              <span onClick={() => this.sidebarToggle()} className="cursor-pointer material-symbols-outlined text-gray-500 rounded hover:bg-gray-100 p-1">
                {showSidebar ?
                  "chevron_left" :
                  "chevron_right"
                }
              </span>
            </div>
            {showSidebar ?
              <span className="cursor-default font-normal ml-4 text-sm text-gray-500">Insight</span>
              : null}
          </div>
          <div className="mb-4 font-medium   overflow-y-auto overflow-hidden" style={{ height: 'calc(100% - 4rem)' }}>
            {/* 1 */}
            <div title={"Overview"} onClick={() => handleSidebarClick(false)}
              className={showOverview ? "cursor-pointer flex items-center bg-blue-50 text-blue-500 border-r-2 border-blue-500" :
                "cursor-pointer flex items-center hover:bg-blue-50 text-gray-500"}>
              <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
                <span className="material-symbols-outlined   p-2">dashboard</span>
              </div>
              {showSidebar ? <span class=" text-sm text-[#3D405B]/70 font-medium ">Overview</span> : null}
            </div>

            {/* 
    {OverviewList && OverviewList.length>0?
      OverviewList.map((item,index)=>  
      // (item && item._id)?

      <div title={item.name+" Insight"} keys={index} onClick={()=>handleSidebarClick(true, item, index+1,item.mean!=="NaN")} 
      className={(index+1)===IndexNo?"cursor-pointer flex items-center bg-blue-50  text-blue-500 border-r-2 border-blue-500":
      "cursor-pointer flex items-center hover:bg-blue-50  text-gray-500 "}>
        <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
          <span className="material-symbols-outlined  p-2">{
            ((index+1)===IndexNo)?"sentiment_satisfied_alt":"sentiment_satisfied_alt"    
          }</span>
        </div>
        {showSidebar?<span class=" text-sm text-[#3D405B]/70 font-medium ">{item.name+" Insight"}</span> :null}
      </div>  

      
      // :null 
    ):null}   */}



            {/* 
  <div title={"Advance Insights"} onClick={()=>handleSidebarExtra(2)} 
    className={showAdvanceInsight?"cursor-pointer flex items-center bg-blue-50   text-blue-500 border-r-2 border-blue-500":
    "cursor-pointer flex items-center hover:bg-blue-50   text-gray-500"}>

      <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
        <span className="material-symbols-outlined  p-2">engineering</span>
      </div>
      <span class=" text-sm text-[#3D405B]/70 font-medium ">Advance Insights</span>
    </div> */}




            {/* <div title={"Advance Insights"} onClick={()=>{ this.onSubMenu(!showSubMenu); handleSidebarExtra(2);  }} 
    className={showAdvanceInsight?"cursor-pointer flex items-center bg-blue-50   text-blue-500 border-r-2 border-blue-500":
    "cursor-pointer flex items-center hover:bg-blue-50   text-gray-500 "}>

        <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
          <span className="material-symbols-outlined  p-2">engineering</span>
        </div>
        <span className="font-medium text-xs text-gray-500 w-full">Advance Insights</span>
        <span className="material-symbols-outlined text-gray-500 mr-4" style={{fontSize: '18px'}}>expand_more</span>
      </div>



      {showSubMenu?
      <>
      <div onClick={()=>handleSidebarExtra(2)}  className="ml-16 text-xs text-gray-500  cursor-pointer">
        <span className={showAdvanceInsight?"block py-1.5 text-blue-500":"block py-1.5 hover:text-blue-500  text-gray-500 font-medium"}>CrossTab</span>
      </div>
      <div onClick={()=>handleSidebarExtra(2)}  className="ml-16 text-xs text-gray-500  cursor-pointer">
        <span className={showAdvanceInsight?"block py-1.5 text-blue-500":"block py-1.5 hover:text-blue-500  text-gray-500 font-medium"}>Correlation</span>
      </div>
      </>
      :null} */}



            {/* <div>
      <div className="flex items-center">
        <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center">
          <span className="material-symbols-outlined text-gray-500 rounded hover:bg-blue-50 p-2">event_available</span>
        </div>
        <span className="font-medium text-xs text-gray-500 w-full">Actions Plan</span>
        <span className="material-symbols-outlined text-gray-500 mr-4" style={{fontSize: '18px'}}>expand_more</span>
      </div>
      <div className="ml-16 text-xs text-gray-500  ">
        <span className="block py-1.5 hover:text-blue-500">Heading 1</span>
        <span className="block py-1.5 hover:text-blue-500  text-blue-500 font-medium">Heading 2</span>
        <span className="block py-1.5 hover:text-blue-500">Heading 3</span>
        <span className="block py-1.5 hover:text-blue-500">Heading 4</span>
      </div>
    </div> */}




            {/* <div title={"Text Analysis"} onClick={()=>handleSidebarExtra(1)} 
    className={showTextAnalysis?"cursor-pointer flex items-center bg-blue-50   text-blue-500 border-r-2 border-blue-500":
    "cursor-pointer flex items-center hover:bg-blue-50   text-gray-500"}>

      <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
        <span className="material-symbols-outlined  p-2">manage_search</span>
      </div>
      <span class=" text-sm text-[#3D405B]/70 font-medium ">Text Analysis</span>
    </div> */}

          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(Sidebar);
