import React from 'react';
import GaugeScore from './Charts/GaugeScore';
import BarDistribution from './Charts/BarDistribution';


export default function Stress(props) {

  let {

    item,

    DistributionData,
    Benchmark,
    cronBachAlpha

  } = props;


  //console.log('DistributionData:====> ',DistributionData)



  return ( 
    <BarDistribution DistributionData={DistributionData} /> 
  );
}
