import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const RoundChart = (props) => {
  let { actualScoreObj, expectedScoreObj } = props


  let actualScoreList = []
  let expectedScoreList = []
  let keyList = []
  if(Object.keys(actualScoreObj) && Object.keys(actualScoreObj).length>0){
    keyList = Object.keys(actualScoreObj);
    actualScoreList = keyList.map((ele)=>(actualScoreObj[ele]));
    expectedScoreList = keyList.map((ele)=>(expectedScoreObj[ele]));
  }
  
  useEffect(() => {

    const chart = echarts.init(document.getElementById('main'));

    const option  = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['Actual Rating', 'Expected Rating']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: keyList
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'Actual Rating',
          type: 'line',
          stack: 'Total',
          data: actualScoreList
        },
        {
          name: 'Expected Rating',
          type: 'line',
          stack: 'Total',
          data: expectedScoreList
        }
      ]
    };



    // const option = {

    //   xAxis: {

    //     type: 'category',
    //     data: Object.keys(socreResult)


    //   },




    //   yAxis: {
    //     type: 'value',
    //     min: 0,
    //     max: 80,
    //     splitNumber: 10,
    //   }
    //   ,

    //   series: [
    //     {
    //       data: Object.values(socreResult),
    //       type: 'line',
    //       smooth: true
    //     }
    //   ]
    // };


    chart.setOption(option);


    return () => {
      chart.dispose();
    };
  }, []);

  return <div id="main" style={{ height: '600px' }} />;
};

export default RoundChart;
