// import React from 'react'
// import  { Redirect } from 'react-router-dom'
// return <Redirect to='/login'  />
import CryptoJS from 'crypto-js';

export function accessModule() {
    // return authorization header with jwt token

    let localData = localStorage.getItem('accessModule');
    let cryptedText = localData?JSON.parse(localData):"";
    let bytes = cryptedText?CryptoJS.AES.decrypt(cryptedText, "secretkey123"):"";
    let data = "";

    try {
        data = bytes?JSON.parse(bytes.toString(CryptoJS.enc.Utf8)):"";
      } catch(error) {
        data = ""
      }

    //////console.log("usersssssssss : ",data);
    
    if (data) {
        return data;
    } else {
        return {};
    }
}