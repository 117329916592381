import React, { Component } from 'react';
import { connect } from 'react-redux';
// import IndexKPI from './Components/IndexKPI';
// import ReactSpeedometer from "react-d3-speedometer";

// import Happiness from './OverIndex/HappinessOverview/Happiness';
// import Engagement from './OverIndex/EngagementOverview/Engagement';
// import Stress from './OverIndex/StressOverview/Stress';
// import Manager from './OverIndex/Manager';
import QuestionCard from './Components/QuestionTypeCards/QuestionCard';

import ScoreGauge from './Components/ScoreGauge';
import CustomScore from './Components/CustomScore';
// import SurveyReport from './SurveyReport/SurveyReport';



import TimeSeriesMultiChart from './TimeSeriesMultiChart';
import Filter from './Filter/Filter';
import HeatMap from './Charts/HeatMap';

import TimeSeriesChart from './TimeSeriesChart';
import ResponseHeader from '../../CommonComponents/ResponseHeader';
import MultiBarChart from './Charts/MultiBarChart';
import SurveyReport from './SurveyReport/SurveyReport';


class TrendInsight extends Component {
  constructor(props) {
    super(props);
    this.state = {


      FilterValues: [],
      showFilter: false,
      showValue: '',
      ListName: 'Demographic',



      managerDetailsIndexType: 0,
      showManagerList: true,
      CurrentManagerName: 'Select Manager',

      showOverview: true,

      viewFilter: 0,

      allIndexList: [
        {
          "name": "Happiness",
          "template": 1,
          "score": "NaN",
          "type": "Score",
          "color": "#FF4081",
          "tailwindcolor": "bg-pink-100 text-pink-500 hover:bg-pink-500",
          "image": "/img/overview_assets/with_circle/happiness-index.png"
        },
        {
          "name": "Engagement",
          "template": 2,
          "score": "NaN",
          "type": "Percentage",
          "color": "#2196F3",
          "tailwindcolor": "bg-blue-100 text-blue-500 hover:bg-blue-500",
          "image": "/img/overview_assets/with_circle/engagement-index.png"

        },
        {
          "name": "Experience",
          "template": 3,
          "score": "NaN",
          "type": "Percentage",
          "color": "#009688",
          "tailwindcolor": " bg-green-100 text-green-500 hover:bg-green-500",
          "image": "/img/overview_assets/with_circle/hope-index.png"
        },
        {
          "name": "Stress",
          "template": 4,
          "score": "NaN",
          "type": "Score",
          "color": "#EF5350",
          "tailwindcolor": " bg-red-100 text-red-500 hover:bg-red-500",
          "image": "/img/overview_assets/with_circle/stress-index.png"
        },
        {
          "name": "Manager",
          "template": 5,
          "score": "NaN",
          "type": "Percentage",
          "color": "#9C27B0",
          "tailwindcolor": "bg-purple-100 text-purple-500 hover:bg-purple-500",
          "image": "/img/overview_assets/with_circle/manager-index.png"

        },
        {
          "name": "Leadership",
          "template": 6,
          "score": "NaN",
          "type": "Percentage",
          "color": "#FFB300",
          "tailwindcolor": " bg-yellow-100 text-yellow-500 hover:bg-yellow-500",
          "image": "/img/overview_assets/with_circle/leadership-index.png"

        },
        {
          "name": "Value",
          "template": 7,
          "score": "NaN",
          "type": "Percentage",
          "color": "#C0CA33",
          "tailwindcolor": " bg-lime-100 text-[#b2b33f] hover:bg-[#b2b33f] ",
          "image": "/img/overview_assets/with_circle/value-index.png"
        },

        {
          "name": "Safety",
          "template": 9,
          "score": "NaN",
          "type": "Percentage",
          "color": "#b2b33f",
          "tailwindcolor": " bg-green-100 text-green-500 hover:bg-green-500",
          "image": "/img/overview_assets/with_circle/safety-index.png"

        },
        {
          "name": "Diversity",
          "template": 10,
          "score": "NaN",
          "type": "Percentage",
          "color": "#3F51B5",
          "tailwindcolor": " bg-indigo-100 text-indigo-500 hover:bg-indigo-500",
          "image": "/img/overview_assets/with_circle/diversity-index.png"

        }
      ],
      surveyIdV2:""


    }
  }

  componentDidMount() {

  }


  openSurveyResponse = () => {
    this.setState({ showOverview: false },()=>{
      let { callAllRespondantApi } = this.props;
      callAllRespondantApi()
    });

  }

  closeResponseAnalytics = () => {
    this.setState({ showOverview: true })
  }


  newHandleFilter = (value) => {
    if (value === this.state.viewFilter) {
      this.setState({ viewFilter: 0 });
    } else {
      this.setState({ viewFilter: value });
    }
  }
  closeNewFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }


  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleIndexTrendFilter } = this.props;
    this.setState({ FilterValues: finalFilter, ListName: name });
    ////console.log("finalFilter----------->", finalFilter);
    ////console.log("name----------->", name);
    ////console.log("viewFilter----------->", this.state.viewFilter);

    handleIndexTrendFilter(finalFilter, true, name);


  }

  handleSelectSurvey=(params,list)=>{
    console.log("params----------->", params);
    let surveyId = "";
    if(params && params.name){
      let surveyName = params.name;
      if(list && list.length>0){
        let getIndex = list.findIndex(prev=>prev.surveyLabel === surveyName);
        if(getIndex!==-1){
          surveyId = list[getIndex]["surveyId"]
        }

        console.log("list----------->", list);

      }
    }

    this.setState({surveyIdV2:surveyId},()=>{
      // let { getMetaResponseAnalyticsFn } = this.props;
      // getMetaResponseAnalyticsFn(surveyId)
      // let data = {
      //   "surveyId": this.props.CurrentSurveyId,
      //   "xAxis": CurrentFilterName
      // }
      // this.props.dispatch(dashboardActions.getMetaResponseAnalyticsFilter(data));
    })
  }

  handleGoBack=()=>{
    this.setState({ showOverview: true });
  }

  render() {
    let { allIndexList, showOverview, viewFilter, ListName, FilterValues } = this.state;
    let {
      getRespondantDetailsData,
      getIndexTrendScoreFilterData,
      getCategoryOverallIndexData,
      onCustomScoreCard,
      getQuestionAvgScoreData,
      getIndexData,
      SurveyList,
      CurrentSurveyId,
      onOptionType,

      getHappinessIndexV2Data,
      getEngagementIndexV2Data,
      getStressIndexV2Data,
      getManagerIndexV2Data,

      getRespondantLifecycleDetailsData,

      EmpFilterData,
      getIndexFilterData,

      getQuestionOthersResultData,
      getRespondantAllLifecycleDetailsData

    } = this.props;

    let surveyId = '';
    // if(SurveyList && SurveyList.length>0){

    //   if(CurrentSurveyId){
    //     surveyId = CurrentSurveyId;
    //   }
    //   else{
    //     surveyId = SurveyList[0].id;
    //   }

    // }
    surveyId = CurrentSurveyId;

    //console.log('x-------->>>>> IndexList:-------->>>>>', getIndexData)

    let overviewScoreList = []
    let IndexList = []

    function getIndexName(key) {
      let name = ''
      if (key === 1) { name = 'Happiness' }
      else if (key === 2) { name = 'Engagement' }
      else if (key === 3) { name = 'Experience' }
      else if (key === 4) { name = 'Stress' }
      else if (key === 5) { name = 'Manager' }
      else if (key === 6) { name = 'Leadership' }
      else if (key === 7) { name = 'Safety' }
      else if (key === 9) { name = 'Diversity' }
      else { name = 'NaN' }

      return name;
    }

    function checkIndex(key) {
      let check = false
      if (key === 1) { check = true }
      else if (key === 2) { check = true }
      else if (key === 3) { check = true }
      else if (key === 4) { check = true }
      else if (key === 5) { check = true }
      else if (key === 6) { check = true }
      else if (key === 7) { check = true }
      else if (key === 9) { check = true }
      else { check = false }

      return check;
    }
    let IndexListNew = [];
    let newFilterObj = {}
    if (getIndexData) {



      IndexList = Object.keys(getIndexData).map((key) => [Number(key), getIndexData[key]]);

      if (getIndexFilterData) {
        if (IndexList && IndexList.length > 0) {
          IndexList.forEach((data) => {
            if (checkIndex(data[0])) {
              newFilterObj[data[0]] = getIndexFilterData[data[0]];
            }
          });
        };

        IndexListNew = Object.keys(newFilterObj).map((key) => [Number(key), newFilterObj[key]]);
      }
      //console.log('IndexListNew: ',IndexListNew)

      if (IndexListNew && IndexListNew.length > 0) {
        IndexListNew.forEach((data) => {
          let GetIndex = allIndexList.findIndex(prev => prev.template === data[0])
          if (getIndexName(data[0]) !== 'NaN' && data && data[0] && data[1]) {
            overviewScoreList.push({
              template: data[0],
              score: data[1].toFixed(2),
              name: getIndexName(data[0]),
              type: (GetIndex !== -1) ? allIndexList[GetIndex].type : 'score',
              color: (GetIndex !== -1) ? allIndexList[GetIndex].color : '#757575',
              image: (GetIndex !== -1) ? allIndexList[GetIndex].image : null
            })
          }

        })
      }

    }

    let OverViewIndexList = overviewScoreList;

    if (allIndexList && allIndexList.length > 0) {
      allIndexList.forEach((item) => {
        let GetIndex = OverViewIndexList.findIndex(prev => prev.template === item.template);
        if (GetIndex === -1) {
          OverViewIndexList.push({
            ...item,
            "type": item.type,
            "color": item.color,
            "image": item.image
          });
        }
      });
    }

    console.log('allIndexList: ', allIndexList)
    console.log('overviewScoreList: ', overviewScoreList)
    console.log('getIndexData:-----------> ', getIndexData)

    function FormattFun(inputData, isSummary) {
      let RankObj = 0

      let LIST = []
      let prevTemList = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {

          let templateId = ques.total[0]._id.qtemplate;

          let temp = {
            name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
            answerList: [],
            rank: RankObj && RankObj[templateId] ? RankObj[templateId] : 100,
            templateId: templateId
          }
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading: item && item._id && item._id.heading ? item._id.heading : "",
                parentId: item && item._id && item._id.parentId ? item._id.parentId : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId
              }
              temp["answerList"].push(ans);
            });
          }


          if (isSummary) {
            let getIndex = prevTemList.findIndex(prev => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId)
            }
          }
          else {
            LIST.push(temp);
          }


        });
      }
      return LIST
    }


    let OthersQuesList = FormattFun(getQuestionOthersResultData, true).sort((a, b) => a.rank - b.rank);

    let { onAllQuestions } = this.props;


    console.log('getQuestionAvgScoreData---------->', getQuestionAvgScoreData)

    let itemList = getQuestionAvgScoreData && getQuestionAvgScoreData[0] && getQuestionAvgScoreData[0]["total"] ? getQuestionAvgScoreData[0]["total"] : []

    let parameterList = []
    if (itemList && itemList.length > 0) {
      itemList.forEach((item) => {
        let getIndex = parameterList.findIndex(prev => prev.index === item["_id"].parameterBucketName);
        if (getIndex === -1) {
          parameterList.push({
            index: item["_id"].parameterBucketName,
            data: [{
              count: item.count,
              sum: item.sum,
              ...item["_id"]
            }]
          })
        } else {
          parameterList[getIndex]["data"].push({
            count: item.count,
            sum: item.sum,
            ...item["_id"]
          })
        }

      });
    }

    let parameter2List = []
    if (parameterList && parameterList.length > 0) {
      parameterList.forEach((item) => {
        let temp = {
          "index": item.index,
          "DimenionList": []
        }
        if (item && item.data && item.data.length > 0) {
          item.data.forEach((item2) => {



            let getIndex = temp["DimenionList"].findIndex(prev => prev.dimension === item2.parameterDimensionName);
            if (getIndex === -1) {
              temp["DimenionList"].push({
                dimension: item2.parameterDimensionName,
                inner: [{
                  count: item2.count,
                  sum: item2.sum,
                  answer: item2.answer
                }]
              })
            } else {
              temp["DimenionList"][getIndex]["inner"].push({
                count: item2.count,
                sum: item2.sum,
                answer: item2.answer
              });

            }



          })
        }

        parameter2List.push(temp)


      });
    }

    console.log('sssssss OverViewIndexList---------->', OverViewIndexList)
    console.log('parameter2List---------->', parameter2List)





    let indexDataX = []

    if (allIndexList && allIndexList.length > 0) {
      allIndexList.forEach((indexItem) => {
        let temp = {
          "name": indexItem.name,
          "count": 0,
          "sum": 0,
          "score": 0,
          "color": indexItem.color,
          "image": indexItem.image,
          "type": indexItem.type,
          "template": Number(indexItem.template)
        }

        let check = 0
        if (getCategoryOverallIndexData && getCategoryOverallIndexData.length > 0) {
          getCategoryOverallIndexData.forEach((item) => {


            if (item && item.scoreData && item.scoreData[indexItem.template]) {

              console.log('ss---------->', item.scoreData[indexItem.template])

              temp["count"]++
              temp["sum"] = item.scoreData[indexItem.template]

              check++

            }

          });
        }

        if (check) {
          temp["score"] = parseFloat(temp["sum"] / temp["count"]).toFixed()
          indexDataX.push(temp);
        }


      })
    }

    console.log('sssssss getCategoryOverallIndexData---------->', getCategoryOverallIndexData)


    let { getIndexByFilterDMGData, getEMPFilterLabel } = this.props;

    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let ParticipationObj = {
      total: 0,
      completed: 0,
      mailSent: 0,
      clicked: 0,
      participated: 0,
      performance: 0,
      perfPlusSign: true
    }

    if (getRespondantLifecycleDetailsData && getRespondantLifecycleDetailsData[0]) {
      let apiData = getRespondantLifecycleDetailsData[0]
      ParticipationObj['total'] = apiData['total'] ? apiData['total'] : 0;
      ParticipationObj['completed'] = apiData['completed'] ? apiData['completed'] : 0;
      ParticipationObj['mailSent'] = apiData['mailSent'] ? apiData['mailSent'] : 0;
      ParticipationObj['clicked'] = apiData['clicked'] ? apiData['clicked'] : 0;
      ParticipationObj['participated'] = apiData['completed'] && apiData['total'] ? (apiData['completed'] * 100 / apiData['total']).toFixed(2) : 0;



    }

    let responseOption ={
      "item1":{
        "label":"Sent",
        "value":ParticipationObj['total']
      },
      "item2":{
        "label":"Responded",
        "value":ParticipationObj['completed']
      },
      "item3":{
        "label":"Response Rate",
        "value":ParticipationObj['participated'] + '%'
      }
    }


    console.log("OverViewIndexList----->",OverViewIndexList)

    return (
      <>
        <main className="flex-1 w-full p-4 overflow-hidden overflow-y-auto  bg-[#f9fafb] md:p-6 ">

          {showOverview ?
            <div className="space-y-4">
              <h1 className='xl:text-2xl text-xl font-medium text-[#3D405B]'>Lifecycle Overview</h1>
              <div onClick={() => this.openSurveyResponse()}>
                {/* <IndexKPI
                getRespondantLifecycleDetailsData={getRespondantLifecycleDetailsData} /> */}
                <ResponseHeader responseOption={responseOption}/>

                </div>

              {OverViewIndexList && OverViewIndexList.length > 0 ?
                <div className="flex flex-wrap gap-2">

                  <Filter
                    showFilter={this.state.showFilter}
                    showValue={this.state.showValue}
                    closeFilter={this.closeNewFilter}

                    EmpFilterData2={EmpFilterData2}
                    getIndexFilterData={getIndexByFilterDMGData}
                    handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
                    getEMPFilterLabel={getEMPFilterLabel}
                  />

                </div>
                : null}




                {!(FilterValues && FilterValues.length > 0) ?
                  <>

                    <TimeSeriesMultiChart
                      getCategoryOverallIndexData={getCategoryOverallIndexData}
                      allIndexList={allIndexList}
                      // indexType={item.template}
                      // indexScoreType={item.type}
                      // indexScoreColor={item.color}
                      indexDataX={indexDataX}
                      getRespondantAllLifecycleDetailsData={getRespondantAllLifecycleDetailsData}
                    />

                </>
                :
                <>
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 ">

                      {indexDataX && indexDataX.length > 0 ?
                        indexDataX.map((item, index) =>
                          <div key={index} className="p-4 bg-white border">



                            <h1 className="font-medium text-md ">{item.name + ' Index Trend'}</h1>


                            {(FilterValues && FilterValues.length > 0) ?
                              <>
                                <HeatMap
                                  FilterValues={this.state.FilterValues}
                                  getIndexTrendScoreFilterData={getIndexTrendScoreFilterData}
                                  currFilterName={ListName}
                                  indexNumber={Number(item.template)}
                                  getCategoryOverallIndexData={getCategoryOverallIndexData}

                                />
                              </>
                            :<>
                            
                            <TimeSeriesChart
                              getCategoryOverallIndexData={getCategoryOverallIndexData}
                              allIndexList={allIndexList}
                              indexType={item.template}
                              indexScoreType={item.type}
                              indexScoreColor={item.color}
                              indexDataX={indexDataX}
                              getRespondantAllLifecycleDetailsData={getRespondantAllLifecycleDetailsData}
                            />
                            
                            </>}

                          </div>
                        )
                        : null}
                    </div>
                </>
              }




              {/* Custom Score */}

              <div className="grid grid-cols-1 gap-6 xl:grid-cols-1 md:grid-cols-3">

                {parameter2List && parameter2List.length > 0 ?
                  parameter2List.map((item, index) =>
                    <div key={index} onClick={() => onCustomScoreCard(item)} >
                      {item.score !== 'NaN' ?
                        <div  >
                          <CustomScore item={item} />
                        </div>
                        :
                        <div >
                          <CustomScore item={item} />
                        </div>
                      }
                    </div>
                  )
                  : null}


              </div>


              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">


                {/* <div>
                  {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                    <div onClick={() => onOptionType(surveyId, "nps6", 6)} >
                      <OverviewNPS getQuestionOthersResultData={getQuestionOthersResultData} QueTemplate={6} />
                    </div>
                    :
                    <div >
                    </div>
                  }
                </div>



                <div>
                  {getQuestionOthersResultData && getQuestionOthersResultData.length > 0 ?
                    <div onClick={() => onOptionType(surveyId, "nps6", 16)} >
                      <OverviewNPSZeroToTen getQuestionOthersResultData={getQuestionOthersResultData} QueTemplate={16} />
                    </div>
                    :
                    <div >
                    </div>
                  }
                </div> */}



                {OthersQuesList && OthersQuesList.length > 0 ? OthersQuesList.map((item) =>
                  <div onClick={() => onAllQuestions(item)} className="p-4 bg-white border">
                    <QuestionCard item={item} name={""} />
                  </div>
                ) : null}

              </div>

            </div>
            :

            <div className="px-2 bg-gray-100">

              {/* <MultiBarChart
                getRespondantAllLifecycleDetailsData={getRespondantAllLifecycleDetailsData}
                handleSelectSurvey={this.handleSelectSurvey}
              /> */}

              <SurveyReport
                closeResponseAnalytics={this.closeResponseAnalytics}
                handleGoBack={this.handleGoBack}
                EmpFilterData={EmpFilterData}
                getIndexFilterData={getIndexFilterData}
                getRespondantDetailsData={getRespondantDetailsData}
                getRespondantAllLifecycleDetailsData={getRespondantAllLifecycleDetailsData}

              />
              

            </div>

          }

        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(TrendInsight);
