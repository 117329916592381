import React, { Component } from "react";
import Header from "../Components/Header";
import HorizontalBarChart from "./chart/HorizontalBarChart/HorizontalBarChart";
// import HappinessHorizontalBarChart from "./chart/HorizontalBarChart/HappinessHorizontalBarChart";
// import EngagementHorizontalBarChart from "./chart/HorizontalBarChart/EngagementHorizontalBarChart";
// import StressHorizontalBarChart from "./chart/HorizontalBarChart/StressHorizontalBarChart";

// import CircleChart from "./chart/CircleChart";
import BarChart from "./chart/BarChart";

import GaugeScore from "./chart/GaugeScore";
import BarDistribution from "./chart/BarDistribution/BarDistribution";

export default class Engagement_Index_Report extends Component {
  render() {
    let {
      getOverallDistribution,
      getOverallDistribution2,

      getOverallDriver,
      getOverallIndexData,
      getOverallIndex2Data,
      surveyLogo,
      isFilter,
      SummaryName,
      indexTemplate,
      getIndexV2Data,
      getIndexV22Data,
      getIndexDistributionData,

      getIndexData,
      getIndex2Data,

      getAllBenchMarkData,

      getRespondantDetailsData,
      getRespondantDetails2Data,

      getIndexDriverData,
      getIndexDriver2Data,

      GetParameterLabel,
      showYearData,
      yearValue1,
      yearValue2,
    } = this.props;

    // ActivelyDisengaged: 4.436557231588288
    // Engagement: 78.88198757763976
    // HighlyEngaged: 27.24046140195209
    // ModeratelyEngaged: 51.641526175687666
    // Passive: 16.68145519077196

    let indexValue =
      getIndexData &&
        getIndexData[indexTemplate] &&
        parseFloat(getIndexData[indexTemplate].toFixed(2))
        ? parseFloat(getIndexData[indexTemplate].toFixed(2))
        : 0;

    let indexValue2 =
      getIndex2Data &&
        getIndex2Data[indexTemplate] &&
        parseFloat(getIndex2Data[indexTemplate].toFixed(2))
        ? parseFloat(getIndex2Data[indexTemplate].toFixed(2))
        : 0;

    let indexOverallValue =
      getOverallIndexData &&
        getOverallIndexData[indexTemplate] &&
        parseFloat(getOverallIndexData[indexTemplate].toFixed(2))
        ? parseFloat(getOverallIndexData[indexTemplate].toFixed(2))
        : 0;

    let indexOverallValue2 =
      getOverallIndex2Data &&
        getOverallIndex2Data[indexTemplate] &&
        parseFloat(getOverallIndex2Data[indexTemplate].toFixed(2))
        ? parseFloat(getOverallIndex2Data[indexTemplate].toFixed(2))
        : 0;

    function getParticipationObj(list) {
      let ParticipationObj = {
        total: 0,
        completed: 0,
        mailSent: 0,
        clicked: 0,
        participated: 0,
        performance: 0,
        perfPlusSign: true,
      };

      if (list && list[0]) {
        let apiData = list[0];
        ParticipationObj["total"] = apiData["total"] ? apiData["total"] : 0;
        ParticipationObj["completed"] = apiData["completed"]
          ? apiData["completed"]
          : 0;
        ParticipationObj["mailSent"] = apiData["mailSent"]
          ? apiData["mailSent"]
          : 0;
        ParticipationObj["clicked"] = apiData["clicked"]
          ? apiData["clicked"]
          : 0;
        ParticipationObj["participated"] =
          apiData["completed"] && apiData["total"]
            ? ((apiData["completed"] * 100) / apiData["total"]).toFixed(2)
            : 0;
      }

      return ParticipationObj;
    }

    let ParticipationObj = getParticipationObj(getRespondantDetailsData);
    let ParticipationObj2 = getParticipationObj(getRespondantDetails2Data);

    let indexType = 2;
    let Benchmark = 0;
    if (getAllBenchMarkData) {
      Benchmark =
        (typeof getAllBenchMarkData[indexType] !== "string" ||
          getAllBenchMarkData[indexType] instanceof Number) &&
          getAllBenchMarkData &&
          getAllBenchMarkData[indexType]
          ? getAllBenchMarkData[indexType].toFixed(2)
          : "";
    }

    let isScoreFormat = false;
    if (SummaryName === "Happiness" || SummaryName === "Stress") {
      isScoreFormat = true;
    }

    return (
      <div className="relative">
        <div className="pt-4">
          <Header surveyLogo={surveyLogo} />
          <div className>
            <div className="capitalize w-full px-8 py-6 ">
              <div className="text-indigo-700 opacity-90"></div>
              <h1 className="text-2xl text-[#3D405B] font-semibold border-b border-[#DDDDDD] pb-4 capitalize">
                {SummaryName + " Summary"}
              </h1>
            </div>
          </div>
        </div>

        <main className="w-full px-8 grid grid-cols-2 gap-6 mx-auto   pb-4">
          <div className="space-y-4 ">
            <h1 className="text-[#212121] text-xl font-medium capitalize">
              {SummaryName + " Meter"}
            </h1>
            <div>
              <GaugeScore
                score={indexOverallValue2.toString()}
                benchmark={40}
              />
            </div>

            {indexTemplate === 1 && (<>
              <div className="grid grid-cols-2 justify-center gap-4 text-xs text-gray-500  -mt-14">

                <div className="flex items-center "><div style={{ background: "#e53935" }} className="p-1 rounded-sm" /><p className="ml-2">Unhappy (0-48)</p></div>
                <div className="flex items-center "><div style={{ background: "#ffc107" }} className="p-1 rounded-sm" /><p className="ml-2">Moderate (49-60)</p></div>
                <div className="flex items-center "><div style={{ background: "#a2e37d" }} className="p-1 rounded-sm" /><p className="ml-2">Happier (61-66)</p></div>
                <div className="flex items-center "><div style={{ background: "#00cc66" }} className="p-1 rounded-sm" /><p className="ml-2">Happiest (67-72)</p></div>

              </div>
            </>)}

            {indexTemplate === 2 && (<>
              <div className="grid grid-cols-2 justify-center gap-4 text-xs text-gray-500  -mt-14">

                <div className="flex items-center "><div style={{ background: "#d12e2e" }} className="p-1 rounded-sm" /><p className="ml-2">Actively Disengaged</p></div>
                <div className="flex items-center "><div style={{ background: "#f4ae34" }} className="p-1 rounded-sm" /><p className="ml-2">Passive</p></div>
                <div className="flex items-center "><div style={{ background: "#a2e37d" }} className="p-1 rounded-sm" /><p className="ml-2">Moderately Engaged</p></div>
                <div className="flex items-center "><div style={{ background: "#00cc66" }} className="p-1 rounded-sm" /><p className="ml-2">Highly Engaged</p></div>

              </div>
            </>)}

            {indexTemplate === 4 && (<>
              <div className="grid grid-cols-2 justify-center gap-4 text-xs text-gray-500  -mt-14">

                <div className="flex items-center "><div className="p-1 bg-pink-400 rounded-sm" /><p className="ml-2">{"Well Managed Stess (=6)"}</p></div>
                <div className="flex items-center "><div className="p-1 bg-green-400 rounded-sm" /><p className="ml-2">{"Moderately Well (4.5-6)"}</p></div>
                <div className="flex items-center "><div className="p-1 bg-yellow-400 rounded-sm" /><p className="ml-2">{"Often Stressed (3-4.5)"}</p></div>
                <div className="flex items-center "><div className="p-1 bg-red-500 rounded-sm" /><p className="ml-2">{"Need Immediate Attention (<3)"}</p></div>

              </div>
            </>)}


          </div>

          <div className="w-full space-y-6">

            <div className="w-full space-y-4">
              {showYearData(yearValue2, indexTemplate) ? (
                <div className="">
                  <h1 className="text-[#212121] text-lg font-semibold capitalize">
                    Response Rate
                  </h1>

                  <div className="flex flex-col  ">
                    <div className="w-full flex justify-start">
                      <BarChart ParticipationObj={ParticipationObj2} />
                    </div>
                  </div>
                  <div className="space-y-4 w-full">
                    <p className="text-[#212121] font-medium text-sm">
                      <b className="font-semibold ">{yearValue2} - </b> <b>{ParticipationObj2["total"]}</b> Sent of  <b> {ParticipationObj2["completed"]}</b> Response have completed the survey</p>
                  </div>

                </div>
              ) : null}
              {showYearData(yearValue1, indexTemplate) ? (

                <div className="flex flex-col  ">
                  <div className="w-full flex justify-start">
                    <BarChart ParticipationObj={ParticipationObj} />
                  </div>

                  <div className="space-y-4 w-full">
                    <p className="text-[#212121] font-medium text-sm">
                      <b className="font-semibold ">{yearValue1} - </b> <b>{ParticipationObj2["total"]}</b> Sent of  <b> {ParticipationObj2["completed"]}</b> Response have completed the survey</p>
                  </div>
                </div>

              ) : null}
            </div>
            {/* table */}
            <table className="w-full">
              <thead>
                <tr className="border-b-2 border-white  divide-x-2 divide-white whitespace-nowrap">

                  {showYearData(yearValue1, indexTemplate) ?
                    <th className="bg-gradient-to-r from-[#673AB7]  to-[#673AB7]/60 p-2 px-2 text-white font-medium">Year {yearValue1}</th>
                    : null}
                  {showYearData(yearValue2, indexTemplate) ?
                    <th className="bg-gradient-to-r from-[#2196F3]  to-[#2196F3]/60 p-2 px-2 text-white font-medium">Year {yearValue2}</th>
                    : null}

                  <th className="bg-gradient-to-r from-[#3F51B5]  to-[#3F51B5]/60 p-2 px-2 text-white font-medium">Benchmark</th>
                </tr>
              </thead>
              <tbody className="divide-y-2  divide-white">
                <tr className=" divide-x-2 divide-white">
                  {showYearData(yearValue1, indexTemplate) ?
                    <td className="p-2 text-[#212121]/80 font-semibold bg-[#F1F1F1B2]">
                      {indexOverallValue.toString() +
                        (isScoreFormat ? "" : "%")}
                    </td>
                    : null}
                  {showYearData(yearValue2, indexTemplate) ?
                    <td className="p-2 text-[#212121]/80 font-semibold bg-[#F1F1F1B2]">
                      {indexOverallValue2.toString() +
                        (isScoreFormat ? "" : "%")}
                    </td>
                    : null}

                  <td className="p-2 text-[#212121]/80 font-semibold bg-[#F1F1F1B2]">68%</td>
                </tr>
              </tbody>

            </table>

          </div>

          <div className="col-span-2 space-y-4">
            <h1 className="text-[#212121] text-xl font-medium capitalize">
              {SummaryName + " Distribution"}
            </h1>

            <div className="w-full">
              {indexTemplate === 1 ? (
                <BarDistribution
                  DistributionData={getIndexV2Data}
                  Distribution2Data={getIndexV22Data}
                  getOverallDistribution={getOverallDistribution}
                  getOverallDistribution2={getOverallDistribution2}
                  year1={showYearData(yearValue1, indexTemplate)}
                  year2={showYearData(yearValue2, indexTemplate)}
                  year1Label={yearValue1}
                  year2Label={yearValue2}

                  List={['Unhappy', 'ModeratelyHappy', 'Happy', 'Happiest']}
                  labelObj={{
                    'Unhappy': `Unhappy`,
                    'ModeratelyHappy': `Moderate`,
                    'Happy': `Happy`,
                    'Happiest': `Happiest`
                  }}
                  colorObj={{
                    'Unhappy': '#e53935',
                    'ModeratelyHappy': '#ffc107',
                    'Happy': '#a2e37d',
                    'Happiest': '#00cc66'
                  }}
                />
              ) : null}
              {indexTemplate === 2 ? (
                <BarDistribution
                  DistributionData={getIndexV2Data}
                  Distribution2Data={getIndexV22Data}
                  getOverallDistribution={getOverallDistribution}
                  getOverallDistribution2={getOverallDistribution2}
                  year1={showYearData(yearValue1, indexTemplate)}
                  year2={showYearData(yearValue2, indexTemplate)}
                  year1Label={yearValue1}
                  year2Label={yearValue2}

                  List={['ActivelyDisengaged', 'Passive', 'ModeratelyEngaged', 'HighlyEngaged']}
                  labelObj={{
                    'ActivelyDisengaged': `Actively Disengaged`,
                    'Passive': `Passive`,
                    'ModeratelyEngaged': ` Moderately Engaged`,
                    'HighlyEngaged': `Highly Engaged`
                  }}
                  colorObj={{
                    'ActivelyDisengaged': '#d12e2e',
                    'Passive': '#f4ae34',
                    'ModeratelyEngaged': '#a2e37d',
                    'HighlyEngaged': '#00cc66'
                  }}
                />
              ) : null}
              {indexTemplate === 4 ? (
                <BarDistribution
                  DistributionData={getIndexV2Data}
                  Distribution2Data={getIndexV22Data}
                  getOverallDistribution={getOverallDistribution}
                  getOverallDistribution2={getOverallDistribution2}
                  year1={showYearData(yearValue1, indexTemplate)}
                  year2={showYearData(yearValue2, indexTemplate)}
                  year1Label={yearValue1}
                  year2Label={yearValue2}

                  List={['NeedImmediateattention', 'OftenStressed', 'ModeratelyWell', 'WellManagedStess']}
                  labelObj={{
                    'NeedImmediateattention': `Need
                    Immediate
                    attention(<3)`,

                    'OftenStressed': `Often
                    Stressed 
                    (<4.5 and >=3)`,

                    'ModeratelyWell': `Moderately
                    Well
                    (<6 and >=4.5)`,

                    'WellManagedStess': `Well
                    Managed
                    Stess(=6)`
                  }}
                  colorObj={{
                    'NeedImmediateattention': '#e53935',
                    'OftenStressed': '#ffc107',
                    'ModeratelyWell': '#00cd7e',
                    'WellManagedStess': '#ff4081'
                  }}
                />
              ) : null}
            </div>
          </div>

          <h3 className="page-break"></h3>

          {indexTemplate !== 4 ? (
            <div className=" col-span-2 ">
              <div className="flex justify-between ">
                <h1 className="text-[#212121] text-xl font-medium capitalize">
                  {SummaryName + " Dimensions"}
                </h1>
                <div className="flex items-center space-x-4">

                  {showYearData(yearValue1, indexTemplate) ?
                    <div className="flex items-center text-xs capitalize text-[#3D405B] space-x-1">
                      <span className="w-5 h-2 bg-[#673AB7]/70  rounded-full"></span>
                      <span>{yearValue1}</span>
                    </div>
                    : null}

                  {showYearData(yearValue2, indexTemplate) ?
                    <div className="flex items-center text-xs capitalize text-[#3D405B] space-x-1">
                      <span className="w-5 h-2 bg-[#673AB7]/70  rounded-full"></span>
                      <span> {yearValue2}</span>
                    </div>
                    : null}

                </div>
              </div>
              <div>
                {indexTemplate === 1 ? (
                  <HorizontalBarChart
                    getIndexDriverData={getIndexDriverData}
                    getIndexDriver2Data={getIndexDriver2Data}
                    isFilter={isFilter}
                    indexTemplate={indexTemplate}
                    GetParameterLabel={GetParameterLabel}
                    getOverallDriver={getOverallDriver}
                    year1={showYearData(yearValue1, indexTemplate)}
                    year2={showYearData(yearValue2, indexTemplate)}
                  />
                ) : null}

                {indexTemplate === 2 ? (
                  <HorizontalBarChart
                    getIndexDriverData={getIndexDriverData}
                    getIndexDriver2Data={getIndexDriver2Data}
                    isFilter={isFilter}
                    indexTemplate={indexTemplate}
                    GetParameterLabel={GetParameterLabel}
                    getOverallDriver={getOverallDriver}
                    year1={showYearData(yearValue1, indexTemplate)}
                    year2={showYearData(yearValue2, indexTemplate)}
                  />
                ) : null}

                {indexTemplate === 4 ? (
                  <HorizontalBarChart
                    getIndexDriverData={getIndexDriverData}
                    getIndexDriver2Data={getIndexDriver2Data}
                    isFilter={isFilter}
                    indexTemplate={indexTemplate}
                    GetParameterLabel={GetParameterLabel}
                    getOverallDriver={getOverallDriver}
                    year1={showYearData(yearValue1, indexTemplate)}
                    year2={showYearData(yearValue2, indexTemplate)}
                  />
                ) : null}
              </div>
            </div>
          ) : null}
        </main>
      </div>
    );
  }
}
