import React, { Component } from "react";
import { connect } from "react-redux";
import ReactEcharts from "echarts-for-react";

class HorizontalBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    let { count, max } = this.props;

    let total = 0;
    let names = [];
    let weightage1 = [];
    let weightage2 = [];

    let Score = 0;
    if (true) {
      Score = count;
      names.push({
        value: "Overall",
        textStyle: { fontWeight: "bold" },
      });
      weightage1.push({
        value: Score,
        itemStyle: { color: "#2196F3" },
      });
      weightage2.push({
        value: max - Score,
        itemStyle: { color: "#ffffff" },
      });
    }

    return (
      <>
        <div style={{ zIndex: "10" }}>
          {Score ? (
            <ReactEcharts
              style={{ height: "95px", marginTop: "-55px", width: "600px" }}
              option={{
                // tooltip: {
                //     trigger: 'axis',
                //     axisPointer: {
                //         type: 'shadow'
                //     },
                //     formatter:function(a,b) {
                //         //console.log('a,b--->',a,b)
                //     }
                // },
                grid: {
                  left: "3%",
                  right: "4%",
                  bottom: "3%",
                  containLabel: true,
                },
                xAxis: {
                  type: "value",
                  axisLine: "left",
                  splitLine: {
                    lineStyle: {
                      color: "#fff",
                    },
                  },
                  axisLabel: {
                    show: false,
                  },
                },
                yAxis: {
                  type: "category",
                  data: names,
                  position: "left",
                  inverse: true,
                  axisLine: {
                    lineStyle: {
                      color: "#fff",
                    },
                  },
                  axisLabel: {
                    show: false,
                  },
                },
                axisLabel: {
                  color: "#000",
                  interval: 0,
                  formatter: function (value) {
                    if (value.length > 20) {
                      return value.substring(0, 20) + ".. :";
                    } else {
                      return value + ":";
                    }
                  },
                },
                series: [
                  {
                    // name: 'Strongly Disagree',
                    type: "bar",
                    stack: "总量",
                    barWidth: "30px",
                    label: {
                      show: true,
                      position: "insideRight",
                      formatter: function (d) {
                        if (parseInt(d.value) < 7) {
                          return "";
                        } else {
                          return d.value;
                        }
                      },
                    },
                    data: weightage1,
                    inverse: true,
                  },
                  {
                    // name: 'Disagree',
                    type: "bar",
                    stack: "总量",
                    barWidth: "30px",
                    label: {
                      show: false,
                      position: "insideRight",
                      formatter: function (d) {
                        if (parseInt(d.value) < 7) {
                          return "";
                        } else {
                          return d.value;
                        }
                      },
                    },
                    data: weightage2,
                    inverse: true,
                  },
                ],
              }}
            />
          ) : (
            <div className="text-[#2196F3] font-semibold text-left p-2 px-4">
              NaN
            </div>
          )}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  //   //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(HorizontalBar);
