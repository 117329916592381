import React, { Component } from 'react';
import { connect } from 'react-redux';


import EmployeeProfile from './Components/EmployeeProfile/EmployeeProfile';


class AdvanceComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 1,

    }
  }
  componentDidMount() {

  }

  onOptionType = (val) => {
    this.setState({ optionType: val })
    let { onCrossTab } = this.props;

    if (val === 1) {
      onCrossTab()
    }

  }

  onSwitchOptionScreen = () => {
    let {
      EmpFilterData, getIndexFilterData, questionsList,
      crossTabAnalysisData,
      handleCrossTabApi,
      crossTabData,


      RiskRespondantEmployeesData,
      crrSelectedEmp,
      handleSelectEmpFilter,
      RiskgetEmpQuestionResponsesData,
      RiskgetRiskCategoryData,

      CurrentSurveyId,
      RiskAllEmpQuesResponsesData,
      emptyRiskQuesEmp

    } = this.props;

    let { optionType } = this.state;



    switch (optionType) {

      case 1: return <EmployeeProfile
      
        questionsList={questionsList && questionsList.length > 0 ? questionsList.filter(x => x.templateId !== 13).filter(x => x.templateId !== 14).filter(x => x.templateId !== 3).filter(x => x.templateId !== 4) : []}
        handleCrossTabApi={handleCrossTabApi}
        crossTabData={crossTabData}
        EmpFilterData={EmpFilterData}
        getIndexFilterData={getIndexFilterData}
        crossTabAnalysisData={crossTabAnalysisData}


        RiskRespondantEmployeesData={RiskRespondantEmployeesData}
        crrSelectedEmp={crrSelectedEmp}
        handleSelectEmpFilter={handleSelectEmpFilter}
        RiskgetEmpQuestionResponsesData={RiskgetEmpQuestionResponsesData}
        RiskgetRiskCategoryData={RiskgetRiskCategoryData}

        CurrentSurveyId={CurrentSurveyId}

        RiskAllEmpQuesResponsesData={RiskAllEmpQuesResponsesData}
        emptyRiskQuesEmp={emptyRiskQuesEmp}
      />


      default: return <div>Default Option</div>
    }
  }

  render() {
    // let { optionType} = this.state;



    return (
      <>
        <main className="flex-1 w-full p-4 space-y-4 overflow-hidden overflow-y-auto bg-gray-100 md:p-6" style={{ height: 'calc(100% - 4rem)' }}>

          <div className='flex items-center justify-between' >
            <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Sense Insight</h1>
            <span class="material-symbols-outlined cursor-pointer lg:hidden">menu_open</span>
          </div>

          {this.onSwitchOptionScreen()}
        </main>


      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AdvanceComparision);
