import {
  riskdashtype2Constants
} from '../_constants';

export function riskdashtype2(state = {}, action) {

  switch (action.type) {
      //----------------------------------------------------------------- 

      case riskdashtype2Constants.RISKDASH_GET_SURVEY_LIST_REQUEST:
        return {
          ...state,
          loading: true
        };
      case riskdashtype2Constants.RISKDASH_GET_SURVEY_LIST_SUCCESS:
        return {
          ...state,
          getSurveySuccess: true,
          loading: false,
          SurveyList:action.riskdashtype2.SurveyData.list,
          SurveyTotal:action.riskdashtype2.SurveyData.total

        };
      case riskdashtype2Constants.RISKDASH_GET_SURVEY_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };

        //----------------------------------------------------------------- 

        case riskdashtype2Constants.RISKDASH_GET_DASHBOARD_CARD_REQUEST:
          return {
            ...state,
            loading: true
          };
        case riskdashtype2Constants.RISKDASH_GET_DASHBOARD_CARD_SUCCESS:
          return {
            ...state,
            loading: false,
            getDashboardCardSuccess: true,
            getDashboardCardData:action.riskdashtype2.getDashboardCardData
  
          };
        case riskdashtype2Constants.RISKDASH_GET_DASHBOARD_CARD_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  
          //----------------------------------------------------------------- 

        case riskdashtype2Constants.RISKDASH_GET_QUESTION_DIST_REQUEST:
          return {
            ...state,
            loading: true
          };
        case riskdashtype2Constants.RISKDASH_GET_QUESTION_DIST_SUCCESS:
          return {
            ...state,
            loading: false,
            getQuestionDistSuccess: true,
            getQuestionDistData:action.riskdashtype2.getQuestionDistData
  
          };
        case riskdashtype2Constants.RISKDASH_GET_QUESTION_DIST_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  
          //----------------------------------------------------------------- 

          case riskdashtype2Constants.RISKDASH_GET_QUESTION_DIST_OVERALL_REQUEST:
            return {
              ...state,
              loading: true
            };
          case riskdashtype2Constants.RISKDASH_GET_QUESTION_DIST_OVERALL_SUCCESS:
            return {
              ...state,
              loading: false,
              getQuestionDistOverallSuccess: true,
              getQuestionDistOverallData:action.riskdashtype2.getQuestionDistOverallData
    
            };
          case riskdashtype2Constants.RISKDASH_GET_QUESTION_DIST_OVERALL_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
    
            //----------------------------------------------------------------- 

          case riskdashtype2Constants.RISKDASH_GET_MEAN_SCORE_REQUEST:
            return {
              ...state,
              loading: true
            };
          case riskdashtype2Constants.RISKDASH_GET_MEAN_SCORE_SUCCESS:
            return {
              ...state,
              loading: false,
              getMeanScoreSuccess: true,
              getMeanScoreData:action.riskdashtype2.getMeanScoreData
    
            };
          case riskdashtype2Constants.RISKDASH_GET_MEAN_SCORE_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
    
            //----------------------------------------------------------------- 

            case riskdashtype2Constants.RISKDASH_GET_MEAN_SCORE_QWISE_REQUEST:
              return {
                ...state,
                loading: true
              };
            case riskdashtype2Constants.RISKDASH_GET_MEAN_SCORE_QWISE_SUCCESS:
              return {
                ...state,
                loading: false,
                getMeanScoreQwiseSuccess: true,
                getMeanScoreQwiseData:action.riskdashtype2.getMeanScoreQwiseData
      
              };
            case riskdashtype2Constants.RISKDASH_GET_MEAN_SCORE_QWISE_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };
      
              //----------------------------------------------------------------- 

                 
            case riskdashtype2Constants.RISKDASH_GET_QUES_NPS_RESULT_REQUEST:
              return {
                ...state,
                loading: true
              };
            case riskdashtype2Constants.RISKDASH_GET_QUES_NPS_RESULT_SUCCESS:
              return {
                ...state,
                loading: false,
                getQuestionResultNPSSuccess: true,
                getQuestionMatrixResultData:action.riskdashtype2.getQuestionMatrixResultData
      
              };
            case riskdashtype2Constants.RISKDASH_GET_QUES_NPS_RESULT_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };


              case riskdashtype2Constants.RISKDASH_GET_QUES_OTHERS_RESULT_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case riskdashtype2Constants.RISKDASH_GET_QUES_OTHERS_RESULT_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  getQuestionResultOthersSuccess: true,
                  getQuestionOthersResultData:action.riskdashtype2.getQuestionOthersResultData
        
                };
              case riskdashtype2Constants.RISKDASH_GET_QUES_OTHERS_RESULT_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };
      
              //----------------------------------------------------------------- 
 


              
              case riskdashtype2Constants.RISKDASH_GET_QUES_FILTER_OTHERS_RESULT_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case riskdashtype2Constants.RISKDASH_GET_QUES_FILTER_OTHERS_RESULT_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  getQuestionOthersResultFilterSuccess: true,
                  getQuestionOthersResultFilterData:action.riskdashtype2.getQuestionOthersResultFilterData
        
                };
              case riskdashtype2Constants.RISKDASH_GET_QUES_FILTER_OTHERS_RESULT_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };




                case riskdashtype2Constants.RISKDASH_GET_QUES_2_FILTER_OTHERS_RESULT_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case riskdashtype2Constants.RISKDASH_GET_QUES_2_FILTER_OTHERS_RESULT_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getQuestion2OthersResultFilterSuccess: true,
                    getQuestion2OthersResultFilterData:action.riskdashtype2.getQuestion2OthersResultFilterData
          
                  };
                case riskdashtype2Constants.RISKDASH_GET_QUES_2_FILTER_OTHERS_RESULT_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };
                




                  
                case riskdashtype2Constants.RISKDASH_GET_QUES_TYPE1_RESULT_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case riskdashtype2Constants.RISKDASH_GET_QUES_TYPE1_RESULT_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getQuestionByQuestionIdResultSuccess: true,
                    getQuestionByQuestionIdResultData:action.riskdashtype2.getQuestionByQuestionIdResultData
          
                  };
                case riskdashtype2Constants.RISKDASH_GET_QUES_TYPE1_RESULT_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };
                


            case riskdashtype2Constants.RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_REQUEST:
              return {
                ...state,
                loading: true
              };
            case riskdashtype2Constants.RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_SUCCESS:
              return {
                ...state,
                loading: false,
                getQuestionWithBranchCodeByQuestionIdSuccess: true,
                getQuestionWithBranchCodeByQuestionIdData:action.riskdashtype2.getQuestionWithBranchCodeByQuestionIdData
      
              };
            case riskdashtype2Constants.RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };
                  

              case riskdashtype2Constants.RISKDASH_GET_INDEX_BY_FILTER_DMG_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case riskdashtype2Constants.RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_2_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  getQuestionWithBranchCodeByQuestionId2Success: true,
                  getQuestionWithBranchCodeByQuestionId2Data:action.riskdashtype2.getQuestionWithBranchCodeByQuestionId2Data
        
                };
              case riskdashtype2Constants.RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_2_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };
                
                


                case riskdashtype2Constants.RISKDASH_GET_INDEX_BY_FILTER_DMG_X_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case riskdashtype2Constants.RISKDASH_GET_INDEX_BY_FILTER_DMG_X_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getIndexByFilterDMGSuccess: true,
                    getIndexByFilterDMGData:action.riskdashtype2.getIndexByFilterDMGData
          
                  };
                case riskdashtype2Constants.RISKDASH_GET_INDEX_BY_FILTER_DMG_X_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };
                     

                  case riskdashtype2Constants.RISKDASH_GET_QUES_MULTI_RESULT_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case riskdashtype2Constants.RISKDASH_GET_QUES_MULTI_RESULT_SUCCESS:
                    return {
                      ...state,
                      loading: false,
                      getQuestionOthersMultiResultFilterSuccess: true,
                      getQuestionOthersMultiResultFilterData:action.riskdashtype2.getQuestionOthersMultiResultFilterData
            
                    };
                  case riskdashtype2Constants.RISKDASH_GET_QUES_MULTI_RESULT_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };



            case riskdashtype2Constants.RISK_RESPONDANT_EMPLOYEES_REQUEST:
              return {
                ...state,
                loading: true
              };
            case riskdashtype2Constants.RISK_RESPONDANT_EMPLOYEES_SUCCESS:
              return {
                ...state,
                loading: false,
                RiskRespondantEmployeesSuccess: true,
                RiskRespondantEmployeesData:action.riskdashtype2.RiskRespondantEmployeesData
      
              };
            case riskdashtype2Constants.RISK_RESPONDANT_EMPLOYEES_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };


              
            case riskdashtype2Constants.RISK_EMP_RESPONSES_REQUEST:
              return {
                ...state,
                loading: true
              };
            case riskdashtype2Constants.RISK_EMP_RESPONSES_SUCCESS:
              return {
                ...state,
                loading: false,
                RiskgetEmpQuestionResponsesSuccess: true,
                RiskgetEmpQuestionResponsesData:action.riskdashtype2.RiskgetEmpQuestionResponsesData
      
              };
            case riskdashtype2Constants.RISK_EMP_RESPONSES_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };


              case riskdashtype2Constants.ONBOARDING_EMP_QUES_RESPONSES_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case riskdashtype2Constants.ONBOARDING_EMP_QUES_RESPONSES_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  OnboardingEmpQuesResponsesSuccess: true,
                  OnboardingEmpQuesResponsesData:action.riskdashtype2.OnboardingEmpQuesResponsesData
        
                };
              case riskdashtype2Constants.ONBOARDING_EMP_QUES_RESPONSES_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };

                

                            
            case riskdashtype2Constants.RISK_CATEGORY_REQUEST:
              return {
                ...state,
                loading: true
              };
            case riskdashtype2Constants.RISK_CATEGORY_SUCCESS:
              return {
                ...state,
                loading: false,
                RiskgetRiskCategorySuccess: true,
                RiskgetRiskCategoryData:action.riskdashtype2.RiskgetRiskCategoryData
      
              };
            case riskdashtype2Constants.RISK_CATEGORY_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };


                                        
            case riskdashtype2Constants.RISK_ALL_EMP_QUES_RESPONSES_REQUEST:
              return {
                ...state,
                loading: true
              };
            case riskdashtype2Constants.RISK_ALL_EMP_QUES_RESPONSES_SUCCESS:
              return {
                ...state,
                loading: false,
                RiskAllEmpQuesResponsesSuccess: true,
                RiskAllEmpQuesResponsesData:action.riskdashtype2.RiskAllEmpQuesResponsesData
      
              };
            case riskdashtype2Constants.RISK_ALL_EMP_QUES_RESPONSES_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };


                                                      
            case riskdashtype2Constants.RISK_FILTER_EMP_QUES_RESPONSES_REQUEST:
              return {
                ...state,
                loading: true
              };
            case riskdashtype2Constants.RISK_FILTER_EMP_QUES_RESPONSES_SUCCESS:
              return {
                ...state,
                loading: false,
                RiskFilterEmpQuesResponsesSuccess: true,
                RiskFilterEmpQuesResponsesData:action.riskdashtype2.RiskFilterEmpQuesResponsesData
      
              };
            case riskdashtype2Constants.RISK_FILTER_EMP_QUES_RESPONSES_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };


                                                                    
            case riskdashtype2Constants.RISK_ACTION_PLAN_REQUEST:
              return {
                ...state,
                loading: true
              };
            case riskdashtype2Constants.RISK_ACTION_PLAN_SUCCESS:
              return {
                ...state,
                loading: false,
                getAllRiskActionPlanSuccess: true,
                getAllRiskActionPlanData:action.riskdashtype2.getAllRiskActionPlanData
      
              };
            case riskdashtype2Constants.RISK_ACTION_PLAN_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };


                            
              case riskdashtype2Constants.RISK_EMP_QUES_ALL_STAGES_RESPONSES_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case riskdashtype2Constants.RISK_EMP_QUES_ALL_STAGES_RESPONSES_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  RiskEmpQuesAllStagesResSuccess: true,
                  RiskEmpQuesAllStagesResData:action.riskdashtype2.RiskEmpQuesAllStagesResData
        
                };
              case riskdashtype2Constants.RISK_EMP_QUES_ALL_STAGES_RESPONSES_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };



                case riskdashtype2Constants.GET_ALL_RISK_EMP_CONVERATION_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case riskdashtype2Constants.GET_ALL_RISK_EMP_CONVERATION_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getAllRiskEmpConversationSuccess: true,
                    getAllRiskEmpConversationData:action.riskdashtype2.getAllRiskEmpConversationData
          
                  };
                case riskdashtype2Constants.GET_ALL_RISK_EMP_CONVERATION_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };



                  

                case riskdashtype2Constants.ON_SCHEDULE_SUBMIT_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case riskdashtype2Constants.ON_SCHEDULE_SUBMIT_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    onScheduleSubmitSuccess: true,
                    onScheduleSubmitData:action.riskdashtype2.onScheduleSubmitData
          
                  };
                case riskdashtype2Constants.ON_SCHEDULE_SUBMIT_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };



                  case riskdashtype2Constants.SUBMIT_PEOPLE_TOMEET_RESOLVED_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case riskdashtype2Constants.SUBMIT_PEOPLE_TOMEET_RESOLVED_SUCCESS:
                    return {
                      ...state,
                      loading: false,
                      submitPeoptoMeetResolvedSuccess: true,
                      submitPeoptoMeetResolvedData:action.riskdashtype2.submitPeoptoMeetResolvedData
            
                    };
                  case riskdashtype2Constants.SUBMIT_PEOPLE_TOMEET_RESOLVED_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };
  
  


                    

                    case riskdashtype2Constants.LIFECYCLE_PTM_EMP_QUES_RESPONSES_REQUEST:
                      return {
                        ...state,
                        loading: true
                      };
                    case riskdashtype2Constants.LIFECYCLE_PTM_EMP_QUES_RESPONSES_SUCCESS:
                      return {
                        ...state,
                        loading: false,
                        LifecyclePTMEmpQuesResponsesSuccess: true,
                        LifecyclePTMEmpQuesResponsesData:action.riskdashtype2.LifecyclePTMEmpQuesResponsesData
              
                      };
                    case riskdashtype2Constants.LIFECYCLE_PTM_EMP_QUES_RESPONSES_FAILURE:
                      return {
                        ...state,
                        loading: false,
                        error: action.error
                      };
      


                      
                    case riskdashtype2Constants.LIFECYCLE_PTM_ENG_QUES_RESPONSES_REQUEST:
                      return {
                        ...state,
                        loading: true
                      };
                    case riskdashtype2Constants.LIFECYCLE_PTM_ENG_QUES_RESPONSES_SUCCESS:
                      return {
                        ...state,
                        loading: false,
                        LifecycleEngQuesResponsesSuccess: true,
                        LifecycleEngQuesResponsesData:action.riskdashtype2.LifecycleEngQuesResponsesData
              
                      };
                    case riskdashtype2Constants.LIFECYCLE_PTM_ENG_QUES_RESPONSES_FAILURE:
                      return {
                        ...state,
                        loading: false,
                        error: action.error
                      };
      



                      
                    case riskdashtype2Constants.LIFECYCLE_PTM_EMP_QUES_OPEN_ENDED_REQUEST:
                      return {
                        ...state,
                        loading: true
                      };
                    case riskdashtype2Constants.LIFECYCLE_PTM_EMP_QUES_OPEN_ENDED_SUCCESS:
                      return {
                        ...state,
                        loading: false,
                        LifecyclePTMEmpQuesOpenEndedSuccess: true,
                        LifecyclePTMEmpQuesOpenEndedData:action.riskdashtype2.LifecyclePTMEmpQuesOpenEndedData
              
                      };
                    case riskdashtype2Constants.LIFECYCLE_PTM_EMP_QUES_OPEN_ENDED_FAILURE:
                      return {
                        ...state,
                        loading: false,
                        error: action.error
                      };
      

                      
                      
                case riskdashtype2Constants.LIFECYCLE_ENG_INDEX_DISTRIBUTION_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case riskdashtype2Constants.LIFECYCLE_ENG_INDEX_DISTRIBUTION_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    LifecycleEngamentIndexV2Success: true,
                    LifecycleEngamentIndexV2Data:action.riskdashtype2.LifecycleEngamentIndexV2Data
          
                  };
                case riskdashtype2Constants.LIFECYCLE_ENG_INDEX_DISTRIBUTION_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };
      



    default:
      return state
  }
}