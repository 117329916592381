import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class TimeSeriesChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  openChartList=()=>{
    if(this.state.showChartList){
      this.setState({showChartList:false})
    }
    else{
      this.setState({showChartList:true})
    }
  }

  handleChartType=(item)=>{
    this.setState({chartTypeName:item})
  }

  render() {
    let { getIndexByFilterAllStagesData, indexType, name,
    
      getCategoryOverallIndexData, allIndexList, indexScoreColor

    } = this.props;



    let IndexName = ""
    if(allIndexList && allIndexList.length>0){
      let getIndex = allIndexList.findIndex(prev=>prev.template === indexType);
      if(getIndex !== -1){
        IndexName = allIndexList[getIndex]["name"]
      }
    }


    let valueList = [];
    let labelList = [];
    
    if(getCategoryOverallIndexData && getCategoryOverallIndexData.length>0){
      getCategoryOverallIndexData.forEach((item)=>{

        if((item && item.scoreData && item.scoreData[indexType])){

          let label = item["surveyData"]["name"];

          labelList.push(label)
  
          let indexValue = item && item["scoreData"] && indexType && item["scoreData"][indexType] && item["scoreData"][indexType].toFixed(2)?item["scoreData"][indexType].toFixed(2):"0"
          valueList.push((indexValue))


        }




      })
    }




    return (
      <>
          <div className="stack-body px-4">
          <ReactEcharts
                  style={{height:'350px',
                }}
                  option={{
                    title: {
                      text:""
                    },
                    tooltip: {
                      trigger: 'axis'
                      // formatter:function (a){
                      //   ////console.log("a--------->",a)
                      //   return "Name"
                      // }
                    },
                    grid: {
                      left: '3%',
                      right: '4%',
                      bottom: '3%',
                      containLabel: true
                    },
                    toolbox: {
                      feature: {
                        saveAsImage: {}
                      }
                    },
                    xAxis: {
                      type: 'category',
                      boundaryGap: false,
                      data: labelList,
                      color:indexScoreColor
                    },
                    yAxis: {
                      type: 'value',
                      max:100
                    },
                    series: [
                      {
                        name: name,
                        type: 'line',
                        stack: 'Total',
                        data: valueList
                      }

                    ]
                  }}
                />

          </div>
     

      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(TimeSeriesChart);
