import React from "react";
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';

export default function TeamTop5Themes(props) {
  let {
    getAssessmentDimensionsData,

    getAssessmentReportByEmpIDData,
    crrSelectedEmp,
    EditDetailsFn,
    EditButtonFn,
    hindiPDFstatic
  } = props;

  // function hexToRgbA(hex, opacity) {
  //   var c;
  //   if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
  //     c = hex.substring(1).split("");
  //     if (c.length == 3) {
  //       c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  //     }
  //     c = "0x" + c.join("");
  //     return (
  //       "rgba(" +
  //       [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
  //       "," +
  //       opacity +
  //       ")"
  //     );
  //   }
  //   throw hex;
  // }

  // let newList = [];
  // if (
  //   getAssessmentReportByEmpIDData &&
  //   getAssessmentReportByEmpIDData.length > 0
  // ) {
  //   getAssessmentReportByEmpIDData.forEach((element, index) => {
  //     let getIndex = newList.findIndex(
  //       (prev) => prev.bucket === element.bucket
  //     );
  //     if (getIndex === -1) {
  //       let temp = {
  //         bucket: element.bucket,
  //         colorCode: element.colorCode,
  //         dimensions: [
  //           {
  //             rank: index + 1,
  //             name: element.dimension,
  //           },
  //         ],
  //       };
  //       newList.push(temp);
  //     } else {
  //       newList[getIndex]["dimensions"].push({
  //         rank: index + 1,
  //         name: element.dimension,
  //       });
  //     }
  //   });
  // }

  // console.log(
  //   "getAssessmentReportByEmpIDData--->",
  //   getAssessmentReportByEmpIDData
  // );
  // console.log("newList--->", newList);

  // function getOpacity(rank, item) {
  //   let total =
  //     item && item.dimensions && item.dimensions.length > 0
  //       ? item.dimensions.length
  //       : 0;
  //   return (total + 1 - Number(rank)) / (total + 2);
  // }


  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }






  let AssessmentList = []
  if (getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0) {
    getAssessmentDimensionsData.forEach((element) => {
      AssessmentList.push({
        ...element,
        finalSum: (Number(element.count) * Number(element.rankSum))
      })
    })
    AssessmentList.sort((b, a) => (a.finalSum - b.finalSum))
  }

  console.log("AssessmentList--1->", AssessmentList)



  function checkForGeneralship(bucket, dimension, isSchool) {
    let text = "";
    if (bucket && dimension) {
      if (isSchool) {
        text = "school_";
      } else if (dimension.toLowerCase() === "generalship" && bucket.toLowerCase() === "thinking talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "enhancer" && bucket.toLowerCase() === "relating talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "coach" && bucket.toLowerCase() === "leading talents") {
        text = "old";
      }
    }
    return text
  }


  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }


  let dataList = [];
  if (AssessmentList && AssessmentList.length > 0) {
    for(var i = 0; i < AssessmentList.length; i++){
      let element  = AssessmentList[i];
      if ((i + 1 <= getTopBottomIndex(AssessmentList, "top").range1)) {
        dataList.push({ value: (i+1)*20, name: (i+1).toString(), itemStyle:{color:element && element.colorCode?element.colorCode:"#757575"} })
      }
    }
  }

  return (
    <>

      <div className='flex flex-row '>
        <div className='w-full'>
          <div  >
            <ReactEcharts
              style={{ height: '450px' }}
              option={{
                series: [
                  {
                    name: 'Funnel',
                    type: 'funnel',
                    left: '10%',
                    top: 10,
                    bottom: 0,
                    width: '80%',
                    min: 0,
                    max: 100,
                    minSize: '0%',
                    maxSize: '100%',
                    sort: 'ascending',
                    gap: 2,
                    label: {
                      show: true,
                      fontSize: 20,
                      position: 'inside',
                      color: '#fff',
                    },
                    labelLine: {
                      length: 10,
                      lineStyle: {
                        width: 1,
                        type: 'solid'
                      }
                    },
                    itemStyle: {
                      borderColor: '#fff',
                      borderWidth: 1,
                      
                    },
                    emphasis: {
                      label: {
                        show: false,
                        fontSize: 20,
                        color: '#fff'
                      }
                    },
                    data: dataList
                  }
                ]
              }}
            />
          </div>
        </div>


        <div className='w-full space-y-4 py-3'>
          {AssessmentList && AssessmentList.length > 0 ?
            AssessmentList.map((element, index) =>
              (index + 1 <= getTopBottomIndex(AssessmentList, "top").range1) ?
                <div className='space-y-1 border-l-4 pl-4' style={ {borderColor:element && element.colorCode?element.colorCode:"#757575"} }>
                  <b className='text-lg'>{(index + 1).toString()}. {EditDetailsFn(element, "dimension", element.dimension)}</b>
                  <p>{EditDetailsFn(element, "desc", element.details.desc)}</p>
                </div>
                : null) : null}
        </div>
      </div>


    </>
  );
}
