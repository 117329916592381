import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../Header/Header";
import Sidebar from "./Components/Sidebar/Sidebar";
import { CiFilter } from "react-icons/ci";


class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {

  }

  render() {


    return (
      <>
        <div className="h-screen overflow-hidden bg-gray-50">
          <Header sidebarToggle={this.sidebarToggle}
          />

          <div className="flex w-full" style={{ height: "calc(100% - 2rem)" }}>
            <Sidebar />
            <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto ">
              <div className="md:p-6 p-4 mx-auto  overflow-hidden overflow-y-auto space-y-6 h-[calc(100vh-4rem)] ">
                <div className="flex items-center justify-between">
                  <h1 className="text-xl text-[#3D405B] font-medium">Appraisal Cycle(s) </h1>
                  <div className="flex items-center space-x-3 ">
                    <div className="flex items-center justify-between px-2 space-x-2 overflow-hidden text-sm bg-white border rounded-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search text-gray-600" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>  <path d="M21 21l-6 -6"></path> </svg>
                      <input type="search" name="search" onChange={this.handleSearchSurvey} className="w-full py-2 md:w-48 focus:outline-none placeholder:text-slate-600" placeholder="Search" />
                    </div>

                    <div className="flex justify-center md:justify-start">
                      <button className="w-full px-4 py-2 text-center text-white transition-all duration-200 transform bg-white border rounded-sm cursor-pointer md:w-auto text-md"  >
                        <CiFilter className="w-5 h-5 text-gray-600" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="flex flex-col items-center justify-center p-6 py-10 space-y-10 text-center bg-white border rounded-md">
                    <img src="./img/overview/empty_survey.png" />
                    <div className="space-y-2">
                      <h1 className="text-2xl font-medium text-[#3D405B]">No Appraisal Added Yet!</h1>
                      <p className="text-[#3D405B]/70">Looks like no appraisal has been initiatedyet.</p>
                    </div>
                  </div>
                </div>

              </div >
            </main >
          </div >
        </div >
      </>
    );
  }
}
function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard,
  };
}
export default connect(mapStateToProps)(Survey);