import { dataTool } from "echarts";
import React from "react";
export default function CompleteModal(props) {
  let { saveNewEmployee, addNewEmployee, newEmpOtherFieldsList, newEmpFormData, handleNewEmpInput, addNewEmpOtherField, handleNewEmpOtherInput } = props;
  return (
   <>
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-xl mx-auto relative   left-0 right-0 overflow-hidden mt-24">
         
          <div className="shadow w-full rounded-lg bg-white overflow-hidden block">

          <div className="flex justify-between items-center w-full border-b p-4 px-6">
              <h2 className="font-medium text-xl   text-gray-800 ">Add Employee</h2>
              <div onClick={()=>addNewEmployee(false)} className=" rounded-full p-2  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>
          </div>
            
            <div className="py-4 p-6 space-y-2">
              
            <div className="text-sm space-y-2">
            <label>Email:</label>
            <div className="mb-2 text-xs">
            <input type="email" name="email" value={newEmpFormData && newEmpFormData.email?newEmpFormData.email:""} onChange={handleNewEmpInput} placeholder="Employee Email.." className="p-2 w-full border border-gray-300 outline-none rounded-md" />
            </div>
            </div>

            <div className="text-sm space-y-2">
            <label>EmployeeID:</label>
            <div className="mb-2 text-xs">
            <input type="text" name="emp_id" value={newEmpFormData && newEmpFormData.emp_id?newEmpFormData.emp_id:""} onChange={handleNewEmpInput} placeholder="Employee EmployeeID.." className="p-2 w-full border border-gray-300 outline-none rounded-md" />
            </div>
            </div>

            <div className="text-sm space-y-2">
            <label>Mobile Number:</label>
            <div className="mb-2 text-xs">
            <input type="text" name="mobile" value={newEmpFormData && newEmpFormData.mobile?newEmpFormData.mobile:""} onChange={handleNewEmpInput} placeholder="Employee Mobile Number.." className="p-2 w-full border border-gray-300 outline-none rounded-md" />
            </div>
            </div>


            <div className="text-sm mt-2">

            <div className="flex">
              <label>Add Metadata:</label> <span onClick={()=>addNewEmpOtherField()} className="cursor-pointer ml-2 mb-2 material-symbols-outlined bg-blue-500 rounded-3xl p-0.2 w-6 h-6 text-white">add_outlined</span>
            </div>

            <div style={{height:"100px",overflowY:'auto'}}>
              {newEmpOtherFieldsList && newEmpOtherFieldsList.length>0?
              newEmpOtherFieldsList.map((element)=>
              <>
                <div className="mt-1  border-gray-300 p-1 flex">
                  <span className="font-bold mt-2 px-2">{element.index+")"}</span>
                  <input type="text" name="name" value={element && element.name?element.name:""} onChange={(e)=>handleNewEmpOtherInput(e,element.index)} placeholder="Metadata field name" className="w-5/12 p-2  border-b border-gray-500 outline-none rounded-md " />
                  <span className="font-bold mt-2 px-2">:</span>
                  <input type="text" name="value" value={element && element.value?element.value:""} onChange={(e)=>handleNewEmpOtherInput(e,element.index)} placeholder="Metadata field value" className="w-6/12 p-2  border-b border-blue-500 outline-none rounded-md" />
                </div>
              </>
              ):null}
            </div>



            </div>



            <div className=" text-center pt-4">
              <button onClick={()=>saveNewEmployee()} type="button" className="w-full bg-blue-500 hover:bg-opacity-90 transition duration-150 text-white font-medium py-2 px-6  rounded-md uppercase" >
              Save
              </button> 
            </div>
          </div>

          </div>
        </div>
        </div>
   </>
  );
}
