import React from "react";
export default function ConfirmMail(props) {
  let { closeModal, handleSendSingleMail, } = props;
  return (
    <>
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-screen w-screen " >
        <div className="p-4 max-w-xl mx-auto md:relative absolute left-0 right-0 overflow-hidden mt-24">

          <div className="shadow w-full rounded-lg bg-white overflow-hidden  block">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="font-semibold text-lg text-gray-800 capitalize">Assessment Codes</h2>
              <div onClick={() => closeModal()} className=" w-10 h-10 rounded-full bg-gray-100 text-gray-800 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                <svg className="fill-current w-7 h-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                </svg>
              </div>
            </div>

            <div className="flex  flex-col items-center space-y-6 mt-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-envelope-check" viewBox="0 0 16 16">
              <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>
              <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z"/>
            </svg>
              <h2 className=" text-md text-gray-600  text-center">Are you sure you want to send mail?</h2>

              <div>
                
              </div>







            </div>

            <div className="mt-3 text-center p-4">
              <button type="button" className="bg-blue-500 hover:bg-blue-500 text-white font-medium py-2 px-6  rounded-md capitalize " >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
