
import { authHeader } from '../_helpers';
import { CONST } from '../_config';
// let backendUrl = JSON.parse(localStorage.getItem('backendUrl'));

export const customerService = {
    logout,
    getSurvey,
    getDashboardCard,
    getQuestionDist,
    getMeanScore,
    getMeanScoreQwise,
    getQuestionDistOverall,
    getQuestionMatrixResult,
    getQuestionOthersResult,
    getQuestionOthersResultFilter,
    getQuestion2OthersResultFilter,


    getCustomerColumnName,
    getCustomerFilterListByCompanyId,
    createCustomerFilter,
    updateCustomerFilterStatus,
    deleteCustomerFilter,
    updateCustomerFilterRank,
    updateCustomerShowReportStatus,
    updateCustomerFilterLabel,
    getCustomerList,
    getCustFilter,
    getIndexByFilterDMG_Customer,
    getRespondantDetails_Customers,
    createCustomerRecord,
    getCampaignCustomerssByID
};


function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
}


function handleResponse(response) {
    // //////console.log("response22222   ", response);
 
     return response.text().then(text => {
         const data = text && JSON.parse(text);
         if (!response.ok) {
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logoutSession();
                 //location.reload(true);
             }
             // //////console.log("datadatadata ", response);
 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         ////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             // //////console.log("datadatadatadatadata   ", data);
             if (data.code===3) {
                 logoutSession();
             }
             if(data && data.message && (data.message === "UnauthorizedError")){
                logout()

             }
//              else if (data.code===1) {
//                 logoutSession();
//             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 
 

function handleResponse2(response) {
    // //////console.log("response22222   ", response);
 
     return response.text().then(text => {
         const data = text && JSON.parse(text);
         if (!response.ok) {
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logout();
                 //location.reload(true);
             }
             // //////console.log("datadatadata ", response);
 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         ////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             // //////console.log("datadatadatadatadata   ", data);
             if (data.code===3) {
                 logout();
             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 




function logoutSession(data) {

    let header = new Headers({
            'Content-Type': 'application/json',
            'Authorization': authHeader().Authorization
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/userlogout`, requestOptions)
            .then(handleResponse2)
            .then(user => {
                let userObj = {
                    userLogout: user
                }
                localStorage.removeItem('adminuser');
                window.location.href = "#/login";
                return userObj;
            });
}


// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 logout();
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         if (data.error) {
//             if (data.code === 3) {
//                 logout();
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         return data;
//     });
// }


async function getSurvey(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                SurveyData: data.data
            }
            return SurveyObj;
        });
}


async function getDashboardCard(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getDashboardCard`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getDashboardCardData: data.data
            }
            return SurveyObj;
        });
}


async function getQuestionDist(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionDist_Customer`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionDistData: data.data
            }
            return SurveyObj;
        });
}

async function getQuestionDistOverall(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionDist_Customer`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionDistOverallData: data.data
            }
            return SurveyObj;
        });
}

async function getMeanScore(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getMeanScore`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getMeanScoreData: data.data.data
            }
            return SurveyObj;
        });
}


async function getMeanScoreQwise(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getMeanScoreQwise`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getMeanScoreQwiseData: data.data.data
            }
            return SurveyObj;
        });
}

async function getQuestionMatrixResult(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionResult_Customer`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionMatrixResultData: data.data
            }
            return SurveyObj;
        });
}

async function getQuestionOthersResult(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionResult_Customer`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionOthersResultData: data.data
            }
            return SurveyObj;
        });
}




async function getQuestionOthersResultFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionResult_Customer`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestionOthersResultFilterData: data.data
            }
            return SurveyObj;
        });
}



async function getQuestion2OthersResultFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionResult_Customer`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getQuestion2OthersResultFilterData: data.data
            }
            return SurveyObj;
        });
}






async function getCustomerColumnName(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCustomerColumnName`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getCustomerColumnNameData: data.data
            }
            return SurveyObj;
        });
}


async function getCustomerFilterListByCompanyId(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCustomerFilterListByCompanyId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getCustomerFilterListByCompanyIdData: data.data
            }
            return SurveyObj;
        });
}


async function createCustomerFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createCustomerFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                createCustomerFilterData: data.data
            }
            return SurveyObj;
        });
}

async function updateCustomerFilterStatus(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateCustomerFilterStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                updateCustomerFilterStatusData: data.data
            }
            return SurveyObj;
        });
}


async function deleteCustomerFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deleteCustomerFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                deleteCustomerFilterData: data.data
            }
            return SurveyObj;
        });
}


async function updateCustomerFilterRank(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateCustomerFilterRank`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                updateCustomerFilterRankData: data.data
            }
            return SurveyObj;
        });
}


async function updateCustomerShowReportStatus(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateCustomerShowReportStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                updateCustomerShowReportStatusData: data.data
            }
            return SurveyObj;
        });
}




async function updateCustomerFilterLabel(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateCustomerFilterLabel`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                updateCustomerFilterLabelData: data.data
            }
            return SurveyObj;
        });
}




async function getCustomerList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCustomerList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getCustomerListData: data.data
            }
            return SurveyObj;
        });
}


async function getCustFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCustFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getCustFilterData: data.data
            }
            return SurveyObj;
        });
}


async function getIndexByFilterDMG_Customer(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndexByFilterDMG_Customer`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getIndexByFilterDMG_CustomerData: data.data
            }
            return SurveyObj;
        });
}


async function getRespondantDetails_Customers(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getRespondantDetails_Customer`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getRespondantDetails_CustomersData: data.data
            }
            return SurveyObj;
        });
}


async function createCustomerRecord(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createCustomerRecord`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                createCustomerRecordData: data.data
            }
            return SurveyObj;
        });
}

async function getCampaignCustomerssByID(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignCustomerssByID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                getCampaignCustomerssByIDData: data.data
            }
            return SurveyObj;
        });
}

