import React from "react";
export default function ConfirmMail(props) {
  let { handleConfirmMailClose, handleSendSingleMail, } = props;
  return (
    <>
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-xl mx-auto md:relative absolute left-0 right-0 overflow-hidden mt-24"> 
          <div className="bg-white shadow w-full rounded-lg  overflow-hidden   block ">
            <div className="p-4 flex items-start justify-between border-b">
              <h1 className="  text-slate-800 text-lg font-medium">Confirm Delete</h1>
              <div onClick={() => handleConfirmMailClose()} className="bg-gray-100   rounded-full  text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                </svg>
              </div>
            </div>


            <div className="bg-white p-4">
              <div className="space-y-4 text-center flex flex-col items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
                {/* <div className="pr-4"><img className="" style={{height:"35px",width:"35px"}} src={"imagesplushr/icons/dialog_ques_mark.png"} alt="question mark icon"/></div> */}
                <div className="text-center pt-1">Are you sure to delete this question permanently?</div>
              </div>
              <div className="flex justify-end items-center pt-10 space-x-4">
                <button onClick={() => handleConfirmMailClose()} className="border rounded-md text-gray-500 py-2 px-4 w-20">Cancel</button>
                <button onClick={() => handleSendSingleMail()} className="cursor-pointer bg-blue-600 rounded-md text-white py-2 px-4 w-20">OK</button>

              </div>
            </div>
          </div>



        </div>
      </div>
    </>
  );
}
