import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import html2canvas from 'html2canvas';
import { LiaHandPointRightSolid } from "react-icons/lia";


class ReportWaiseData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      selectedOption: 'Bar chart',

      dimensionValueList: [],
      EmpIndexA: 0,
      EmpIndexB: 4
    }
  }
  componentDidMount() {

  }

  handleDropdownToggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  handleOptionClick = (option) => {
    this.setState({
      selectedOption: option,
      dropdownOpen: false // Close dropdown after selection
    });
  };

  handleDownloadSection = () => {
    // Get the container element of the section
    const sectionContainer = document.querySelector('.report-section');
    // Use html2canvas to capture the section container as an image
    html2canvas(sectionContainer).then(canvas => {
      // Convert the canvas to a data URL
      const imageUrl = canvas.toDataURL('image/png');
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = 'section.png'; // Set the download filename
      // Simulate a click on the link to trigger the download
      document.body.appendChild(link);
      link.click();
      // Clean up
      document.body.removeChild(link);
    });
  };


  render() {

    let { item, size, stage, index, onOthersCardClick } = this.props;
    let { total } = item ? item : {};

    console.log('totaltotaltotaltotal');

    // Initialize variables to hold counts for each category
    let totalCount = 0;
    let Promoters = 0;
    let Passsives = 0;
    let Distractors = 0;

    // Iterate through total and count the occurrences in each category
    if (total) {
      total.forEach(item => {
        let score = parseInt(item.count);
        let answer = parseInt(item["_id"]["answer"]);

        totalCount += score;

        if (parseInt(answer) >= 9 && parseInt(answer) <= 10) {
          Promoters += score;
        }
        if (parseInt(answer) >= 7 && parseInt(answer) <= 8) {
          Passsives += score;
        }
        if (parseInt(answer) >= 0 && parseInt(answer) <= 6) {
          Distractors += score;
        }

      });
    }

    let PromotersPer = (Promoters * 100 / totalCount);
    let PasssivesPer = (Passsives * 100 / totalCount);
    let DistractorsPer = (Distractors * 100 / totalCount);

    let npsScore = ((PromotersPer) - (DistractorsPer)).toFixed(0);
    // console.log('npsScorenpsScorenpsScorenpsScore', npsScore);

    let NPSObj = {
      "PromotersPer": PromotersPer.toFixed(0),
      "PasssivesPer": PasssivesPer.toFixed(0),
      "DistractorsPer": DistractorsPer.toFixed(0)
    }


    let PromotersColorRange = PromotersPer / 100;
    let PasssivesColorRange = (PasssivesPer / 100) + PromotersColorRange;
    let DistractorsColorRange = (DistractorsPer / 100) + PasssivesColorRange;

    // console.log('PromotersColorRange: ', PromotersColorRange);
    // console.log('PasssivesColorRange: ', PasssivesColorRange);
    // console.log('DistractorsColorRange: ', DistractorsColorRange);



    return (

      <div className='p-6 space-y-4 bg-white'>
        {/* <div className='flex flex-row items-center justify-between w-full gap-4'>
          <div className='flex items-center text-[#212121] space-x-2'>
            <p className='p-3 bg-[#ededed] font-semibold'>Question {index + 1}</p>
          </div>
        </div>
        <div className=''>
          <h2 className='text-xl font-medium text-[#212121]'>{item && item.question.name ? item.question.name : 'null'}</h2>
          <p className='text-base text-[#212121]/80 flex space-x-2 items-center pt-3'>
            <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>NPS (1-10 Scale)</p>
          </p>
        </div> */}

        <button class="flex w-full text-left space-x-4">
          <div class="text-primary flex h-10 items-center justify-center">
            <p className='bg-[#ededed] px-3 py-2 font-semibold whitespace-nowrap' style={{ fontWeight: "bold" }}>Q. {index + 1}</p>
          </div>
          <div class="w-full">
            <h4 class="text-xl font-medium text-[#212121]">{item && item.question.name ? item.question.name : 'null'}</h4>
            <p className='text-base text-[#212121]/80 flex space-x-2 items-center pt-3'>
              <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>NPS (0-10 Scale)</p>
              {/* <b className='font-medium text-[#212121] pr-1'>10</b> out of<b className='font-medium text-[#212121] pr-1'>10</b> answered */}
            </p>
          </div>
        </button>

        <div className="w-full px-3 space-y-2 ">
          <div className='items-center xl:flex'>
            <div className=''>
              <ReactEcharts
                style={{ width: '300px', height: size === 2 ? "300px" : "300px" }}
                option={{
                  series: [{
                    type: 'gauge',
                    min: -100,
                    max: 100,
                    axisLine: {
                      lineStyle: {
                        width: 15,
                        color: [
                          [0.5, '#EF5350'],
                          // [0.8, '#FFA726'],
                          [1, '#81c784']
                        ]
                      }
                    },
                    pointer: {
                      itemStyle: {
                        color: 'auto'
                      }
                    },
                    axisTick: {
                      distance: -30,
                      length: 8,
                      lineStyle: {
                        color: '#fff',
                        width: 2
                      }
                    },
                    splitLine: {
                      distance: -30,
                      length: 30,
                      lineStyle: {
                        color: '#fff',
                        width: 4
                      }
                    },
                    axisLabel: {
                      color: 'auto',
                      distance: 25,
                      fontSize: 10
                    },
                    detail: {
                      valueAnimation: false,
                      formatter: '{value}',
                      color: '#757575',
                      fontSize: 0
                    },
                    data: [{
                      value: npsScore // Replace npsScore with your hardcoded value
                    }]
                  }]
                }}
              />
              <div className="text-xl font-bold text-center -mt-14" style={{ fontWeight: 'bold', color: "#212121" }}>{npsScore}%</div>
            </div>
            <div className='w-full'>

              {size !== 2 ?
                <>

                  <div className='divide-y'>
                    <p className='py-4'><span style={{ fontWeight: 'bold', color: "#212121" }}>NPS Score</span> (Promoters - Detractors): <span className='p-2 font-semibold bg-gray-200 rounded-lg' style={{ fontWeight: 'bold', color: "#212121" }}>{npsScore}%</span></p>
                    <div className='flex flex-col py-3 space-y-3'>
                      <div className='flex items-center space-x-4'>
                        <span className="text-[#66BB6A]">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 32 31" fill="none">
                            <path id="promoters" d="M24.325 30.549H7.37V11L17.986 0L19.475 1.184C19.6378 1.32637 19.757 1.51183 19.819 1.719C19.9017 1.99112 19.9405 2.27467 19.934 2.559V2.94L18.215 11H29.632C30.235 11.0147 30.8092 11.2608 31.2357 11.6873C31.6622 12.1138 31.9083 12.688 31.923 13.291V16.42C31.9237 16.6061 31.9428 16.7917 31.98 16.974C32.0188 17.1601 31.9989 17.3537 31.923 17.528L27.112 28.6C26.8688 29.1523 26.4795 29.6277 25.986 29.975C25.5095 30.3419 24.9264 30.5434 24.325 30.549ZM9.661 28.258H24.821L29.632 16.84V13.289H15.389L17.413 3.78L9.661 11.952V28.258ZM7.37 11V13.291H2.291V28.258H7.37V30.549H0V11H7.37Z" fill="#4CAF50" />
                          </svg>
                        </span>
                        <p className='flex-shrink-0 w-56'><span className='font-semibold' style={{ fontWeight: 'bold', color: "#212121" }}>Promoters</span>{" (>=9)"}</p>
                      </div>
                      <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                        <div className='flex items-center justify-start h-8 bg-[#81c784]' style={{ width: `calc(${NPSObj["PromotersPer"]}%)` }}>
                          {/* <span className='text-center p-2 text-[#212121]'>{data.value}%</span> */}
                        </div>
                        <span className='flex items-center text-center p-2 text-[#212121]'>{NPSObj["PromotersPer"]}%</span>
                      </div>
                    </div>

                    <div className='flex flex-col py-3 space-y-3'>
                      <div className='flex items-center space-x-4'>
                        <span className="text-[#FFA726]">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
                            <path d="M24.325 31.0981H7.37V11.5491L17.986 0.549072L19.475 1.73307C19.6378 1.87545 19.757 2.0609 19.819 2.26807C19.9017 2.54019 19.9405 2.82374 19.934 3.10807V3.48907L18.215 11.5491H29.632C30.235 11.5638 30.8092 11.8099 31.2357 12.2364C31.6622 12.6629 31.9083 13.2371 31.923 13.8401V16.9691C31.9237 17.1552 31.9428 17.3407 31.98 17.5231C32.0188 17.7092 31.9989 17.9028 31.923 18.0771L27.112 29.1491C26.8688 29.7013 26.4795 30.1768 25.986 30.5241C25.5095 30.8909 24.9264 31.0925 24.325 31.0981ZM9.661 28.8071H24.821L29.632 17.3891V13.8381H15.389L17.413 4.32907L9.661 12.5011V28.8071ZM7.37 11.5491V13.8401H2.291V28.8071H7.37V31.0981H0V11.5491H7.37Z" fill="#FFA726" />
                          </svg>
                        </span>
                        <p className='flex-shrink-0'>
                          <span className='font-semibold' style={{ fontWeight: 'bold', color: "#212121" }}>Passives</span>{" (7,8)"}</p>
                      </div>
                      <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                        <div className='flex items-center justify-start h-8 bg-[#FFA726]' style={{ width: `calc(${NPSObj["PasssivesPer"]}%)` }}>
                          {/* <span className='text-center p-2 text-[#212121]'>{data.value}%</span> */}
                        </div>
                        <span className='flex items-center text-center p-2 text-[#212121]'>{NPSObj["PasssivesPer"]}%</span>
                      </div>
                    </div>

                    <div className='flex flex-col py-3 space-y-3'>
                      <div className='flex items-center space-x-4'>
                        <span className="text-[#EF5350]">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 32 31" fill="none">
                            <path d="M2.36725 19.6489C2.06785 19.6493 1.77151 19.5886 1.49639 19.4704C1.22127 19.3523 0.973163 19.1793 0.767253 18.9619C0.549893 18.756 0.37685 18.5079 0.258721 18.2328C0.140592 17.9576 0.079863 17.6613 0.0802531 17.3619V14.2269C0.0795662 14.0408 0.0604733 13.8552 0.0232531 13.6729C-0.0155727 13.4868 0.00434398 13.2932 0.0802531 13.1189L4.88725 2.0449C5.1306 1.49299 5.51991 1.01793 6.01325 0.6709C6.48989 0.304384 7.07301 0.103223 7.67425 0.0979004H24.6293V19.6489L14.0133 30.6489L12.5243 29.4649C12.3615 29.3225 12.2422 29.1371 12.1803 28.9299C12.0976 28.6578 12.0588 28.3742 12.0653 28.0899V27.7079L13.7833 19.6509L2.36725 19.6489ZM22.3383 2.3889H7.17825L2.36725 13.8069V17.3579H16.6103L14.5863 26.8659L22.3383 18.6979V2.3889ZM24.6293 19.6489V17.3579H29.7083V2.3889H24.6293V0.0979004H31.9993V19.6489H24.6293Z" fill="#F44336" />
                          </svg>
                        </span>
                        <p className='flex-shrink-0 w-56'><span className='font-semibold' style={{ fontWeight: 'bold', color: "#212121" }}>Detractors</span>{" (<=6)"}</p>
                      </div>
                      <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                        <div className='flex items-center justify-start h-8 bg-[#e57373]' style={{ width: `calc(${NPSObj["DistractorsPer"]}%)` }}>
                          {/* <span className='text-center p-2 text-[#212121]'>{data.value}%</span> */}
                        </div>
                        <span className='flex items-center text-center p-2 text-[#212121]'>{NPSObj["DistractorsPer"]}%</span>
                      </div>
                    </div>
                  </div>

                </>
                : null}
            </div>
          </div>
        </div>


      </div>

    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ReportWaiseData);
