import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions, dashboardActions, pmsActions } from "../../../../_actions";
import Filter from "../../../../pages/CommonComponents/Filters/IndexFilter/Filter";
import ReactEcharts from "echarts-for-react";
//libraries
import * as echarts from 'echarts';
import AppraisalStatus from "./SubComponents/AppraisalStatus";
import AppraisalRating from "./SubComponents/AppraisalRating";
import AppraisalScore from "./SubComponents/AppraisalScore";
class AppraisalCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: "none",
      showLeaderBoard: false,
      isMultifilter: false,
      currentSelectValue: "",
      FilterValues: [],
      showFilter: false,
      showValue: "",
      ListName: "Demographic",
      showEDAModal: false,
      crrDMGValue: "",



      activeTab: "AppraisalStatus",
      activeCard: 'selfAppraisalStatus',
      mainTab: "AppraisalCycle",
      showhide: false


    };
  }
  componentDidMount() { }

  handleTabClick = (tabName) => {
    this.setState({ activeTab: tabName }, () => {

      if (tabName === "AppraisalStatus") {
        this.filterDashboardOverviewStatus([])
      } else if (tabName === "AppraisalRating") {
        this.filterEmployeeRatingAndNormalization([])
      } else if (tabName === "AppraisalScore") {
        this.filterPMSEmployeeWiseScore([])
      }

    });
  };


  filterDashboardOverviewStatus = (FilterValues) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "appraisalCycleId": this.props.appraisalCycleId,
      "filters": Filters
    }

    this.props.dispatch(pmsActions.getDashboardOverviewStatus(data7))
  }

  filterEmployeeRatingAndNormalization = (FilterValues) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "appraisalCycleId": this.props.appraisalCycleId,
      "filters": Filters,
      "type": "RATING"
    }

    this.props.dispatch(dashboardActions.getEmployeeRatingAndNormalization(data7))
  }

  filterPMSEmployeeWiseScore = (FilterValues) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "appraisalCycleId": this.props.appraisalCycleId,
      "filters": Filters,
      "type": "REVIEWEE_LIST"
    }

    this.props.dispatch(pmsActions.getPMSEmployeeWiseScore(data7))
  }


  filterPMSEmployeeWiseStatus = (FilterValues) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "appraisalCycleId": this.props.appraisalCycleId,
      "type": "REVIEWER_LIST"
      // "filters": Filters
    }

    this.props.dispatch(pmsActions.getEmployeeAppraisalStatus(data7));
  }


  render() {
    let { pms, dashboard, survey } = this.props;
    let { EmpFilterData, getEMPFilterLabel, getEmployeeRatingAndNormalization } = dashboard;
    let { getIndexByFilterDMGData } = survey;

    let { getEMPASData, getDashboardOverviewStatusData, getPMSEWScore } = pms;
    let { activeTab, activeCard, showhide } = this.state;

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    console.log("getEmployeeRatingAndNormalization--1------->", getEmployeeRatingAndNormalization)


    const completedCountSelfAppraisal = getDashboardOverviewStatusData?.selfAppraisalStatus?.completed || 0;
    const pendingCountSelfAppraisal = getDashboardOverviewStatusData?.selfAppraisalStatus?.pending || 0;
    const totalSelfAppraisalStatus = completedCountSelfAppraisal + pendingCountSelfAppraisal;

    // Extract completed and pending counts from reviewStatus
    const completedCountMultiRater = getDashboardOverviewStatusData?.multiRaterStatus?.completed || 0;
    const pendingCountMultiRater = getDashboardOverviewStatusData?.multiRaterStatus?.pending || 0;
    const totalMultiRaterStatus = completedCountMultiRater + pendingCountMultiRater;

    // Extract completed and pending counts from reviewStatus
    const completedCountReviewStatus = getDashboardOverviewStatusData?.reviewStatus?.completed || 0;
    const pendingCountReviewStatus = getDashboardOverviewStatusData?.reviewStatus?.pending || 0;
    const totalReviewStatus = completedCountReviewStatus + pendingCountReviewStatus;



    let data5 = (getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.normlLabelCount ? getEmployeeRatingAndNormalization.normlLabelCount : {})

    let data6 = (getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.labels ? getEmployeeRatingAndNormalization.labels : {})

    var labelsListScore = data5 ? Object.keys(data5).map((key) => [key, data6[key], data5[key]]) : [];


    return (
      <>
        <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto pb-20 p-6" style={{ height: 'calc(100% - 4rem)' }}>
          <div className="w-full mx-auto mt-6 space-y-6">
            <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
              <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]  ">
                Dashboard
              </h1>
              <div className="md:flex  hidden items-center text-sm text-[#3D405B]">
                <span className="mx-2 material-symbols-rounded">home</span>
                <span>/</span>
                <span className="px-2">PMS</span>
                <span>/</span>
                <span className="px-2">Appraisal Cycle</span>
                <span>/</span>
                <span className="px-2 font-semibold">Dashboard</span>
              </div>
            </div>

            <div className="items-center justify-between border-b md:flex">
              <div className="flex space-x-2 overflow-hidden overflow-x-auto text-sm font-medium text-gray-600 whitespace-nowrap tab-scroll">
                <span
                  onClick={() => this.handleTabClick("AppraisalStatus")}
                  className={
                    activeTab === "AppraisalStatus"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"
                  }
                >
                  Appraisal Status
                </span>
                <span
                  onClick={() => this.handleTabClick("AppraisalRating")}
                  className={
                    activeTab === "AppraisalRating"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"
                  }
                >
                  Appraisal Rating
                </span>
                <span
                  onClick={() => this.handleTabClick("AppraisalScore")}
                  className={
                    activeTab === "AppraisalScore"
                      ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                      : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"
                  }
                >
                  Appraisal Score
                </span>
              </div>
            </div>

            {activeTab === "AppraisalStatus" ?
              <AppraisalStatus
                filterDashboardOverviewStatus={this.filterDashboardOverviewStatus}
                filterPMSEmployeeWiseStatus={this.filterPMSEmployeeWiseStatus}
              />
              : null}

            {activeTab === "AppraisalRating" ?
              <AppraisalRating
                filterEmployeeRatingAndNormalization={this.filterEmployeeRatingAndNormalization}
              />
              : null}


            {activeTab === "AppraisalScore" ?
              <AppraisalScore
                filterPMSEmployeeWiseScore={this.filterPMSEmployeeWiseScore}
              />
              : null}


          </div>
        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, pms, survey } = state;
  return {
    loggingIn,
    dashboard,
    users,
    pms,
    survey
  };
}
export default connect(mapStateToProps)(AppraisalCycle);
