import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList:[],
      EmpIndexA:0,
      EmpIndexB:4
    }
  }
  componentDidMount() {

  }

  render() {
    let { AnswerObj , name, templateId, size, questionName} = this.props;
    
    function ColorFn(key){
      if(templateId===11){
        return {color:key==="Yes"?'#00cc66':'#ff5050'}
      }
      else{
        return {}
      }
    }

    let total = 0;
    let DataList = []
    if(AnswerObj){
      Object.keys(AnswerObj).forEach(key => {
        let score = parseInt(AnswerObj[key]);
        total+=score;
        DataList.push({
          name: key,
          value: score,
          itemStyle:ColorFn(key)
        });
      });
    }

    let NewDataList = [];
    if(DataList&&DataList.length>0){
      DataList.forEach((item)=>{
        NewDataList.push({
          name: item.name,
          number:item &&  item.name && (item.name==="Yes")?1:2,
          value:  ((item.value*100)/total).toFixed(2),
          itemStyle: item.itemStyle
        })
      })
    }


    NewDataList.sort((a,b)=>a.number-b.number)

    // //console.log('AnswerObj: ',AnswerObj)
    // //console.log('DataList: ',DataList)
    // //console.log('NewDataList: ',NewDataList)

    // [
    //   {value: 1, name: '7'},
    //   {value: 2, name: '2'},
    //   {value: 3, name: '9'},
    //   {value: 4, name: '10'},
    //   {value: 5, name: '3'}
    // ]
    let {stage,index}= this.props;

    return (
      <>
                                    
  {/* <div className="flex justify-between">
  {stage!==3?
    <div><h1 className="text-xl font-medium">Yes / No</h1>
      <p className="text-sm  py-1">  
        {"1. "+questionName}</p></div>
  :null}
        <div className="whitespace-nowrap ml-4 cursor-pointer"><span className="text-blue-500 text-center hover:text-blue-700">{stage==2?"View Details":(stage==3?"":"View More")}</span></div>
  </div> */}


  <div className="flex justify-between pb-2">
                        <div className="dropdown inline-block relative tooltipshow">
                            <h1 className="text-lg font-medium truncate cursor-pointer">
                              {/* {stage==2?
                                <b>{"Q"+(index+1)+"."}</b>
                                :
                                <b>Q1.</b>
                                } */}
                            {questionName && questionName.length>30?questionName.slice(0,30)+"...":questionName}</h1>
                            <div className="tooltipcard cursor-pointer dropdown-menu hidden bg-white font-semibold absolute -left-4 -top-6 z-30 pt-1 text-lg rounded-md shadow-md p-4">
                            {questionName}
                            </div>
                        </div>


                            <div className="whitespace-nowrap ml-4 cursor-pointer">
                              <span className="text-white text-center text-xs px-4 py-2 rounded-md  bg-blue-500 hover:bg-blue-600 ">
                                {stage==2?"View Details":(stage==3?"":"View More")}</span></div>
                        </div> 


                        {/* <p className="text-sm text-gray-800 py-1 truncate">
                                {stage==2?
                                <b>{"Q"+(index+1)+"."}</b>
                                :
                                <b>Q1.</b>
                                }
                        {questionName}</p> */}



  <div className="flex justify-between items-center mt-24  ">

    {/*  circle     */}
    <div className="w-7/12 -mt-8 " >
          <ReactEcharts
            style={{height:size===2?"250px":"170px"}}
            option={{
              tooltip: {
                  trigger: 'item'
              },
              // legend: {
              //     top: '5%',
              //     left: 'center'
              // },
              series: [
                  {
                      name: name?name:"Responses",
                      type: 'pie',
                      radius: ['40%', '70%'],
                      avoidLabelOverlap: false,
                      label: {
                          show: false,
                          position: 'center'
                      },
                      // emphasis: {
                      //     label: {
                      //         show: true,
                      //         fontSize: '40',
                      //         fontWeight: 'bold'
                      //     }
                      // },
                      labelLine: {
                          show: false
                      },
                      data: NewDataList
                  }
              ]
          }}
                            
          />
      </div>


    <div className="mr-20 -mt-8">
      {NewDataList && NewDataList.length>0?
      NewDataList.map((item, index)=>
        <div key={index} className="flex items-center text-lg"> <div style={{background:item.itemStyle.color}} className="bg-green-400 p-1.5 mr-2" /> 
        <p className="text-gray-600  w-12">{item.name}</p> <p>{item.value+"%"}</p></div>
      ):null}
      {/* <div className="flex items-center"> <div className="bg-green-400 p-1.5 mr-2" /> <p className="text-gray-600 text-sm w-12">Yes</p> <p>52.01%</p></div>
      <div className="flex items-center"> <div className="bg-red-400 p-1.5 mr-2" /> <p className="text-gray-600 text-sm w-12">No</p> <p>52.01%</p></div> */}
    </div>



  </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(PieChart);
