import React from "react";
import StackEDAChart from './StackEDAChart';

export default function EmployeeProfileModal(props) {
  let {  closeModal, showModal, getdmgOrgManyFilterData, indexType, dmgLevel, dmgValue, GetParameterLabel } = props;



          // let OrgCoreDriverData = {};
        // if(getOrgIndexV2Data && getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length>0){
        //   getSurveyData.orgList.forEach((org)=>{
        //     OrgCoreDriverData[org.name] = getOrgIndexV2Data[org.name];
        //   })
        // }

    
        // if(OrgCoreDriverData){
        //   valueList = Object.keys(OrgCoreDriverData).map((key) => [ key, OrgCoreDriverData[key] ]);
        // }

    let DriverName = ''
    let FinalData = []
    if(getdmgOrgManyFilterData && getdmgOrgManyFilterData.length>0){
      getdmgOrgManyFilterData.forEach((item)=>{
        //console.log('item------>',item)

        DriverName = item.driver;
          let tempData = {
            "xAxis":item.xAxis,
            "driver":item.driver,
            "data":[]
        }
        let innerList = []
        if(item && item.Data && item.Data.length>0){
            item.Data.forEach((inner)=>{
              //console.log('inner------>',inner)
              //console.log('inneitem.driverr------>',item.driver)
              let scoreZ = 0
                if(item && item.type===2){
                  scoreZ = inner && inner[item.driver] && item && item.driver?inner[item.driver]:""
                }
                else{
                  scoreZ = inner && inner[item.driver+"Avg"] && item && item.driver?inner[item.driver+"Avg"]:""
                }
                innerList.push({
                  'count':inner && inner.count?inner.count:"NaN",
                  'name':inner && inner.name?inner.name:"",
                  'score':scoreZ
                })
            })
        }

        tempData["data"] = innerList
        FinalData.push(tempData)


      })
    }

    //console.log('showModal------>',showModal)
    //console.log('zzz getdmgOrgManyFilterData------>',getdmgOrgManyFilterData)
    //console.log('List------>',FinalData)




  return (
   <>
   {showModal?
  <>
      <div  style={{zIndex:'100',backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-6xl mx-auto relative   left-0 right-0 overflow-hidden mt-12">
          
          
          <div onClick={()=>closeModal()}  className="shadow absolute right-60 top-20 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>


          
          
          <div  className="shadow mt-20 md:mx-60 lg:mx-60 xl:mx-60 rounded-lg bg-white overflow-hidden block">
            

            <div className="px-2 py-1 font-medium text-lg border-b ">
              <span className="font-bold">{"Organization Core: "}</span>
              <span className="text-blue-500">{" ("+GetParameterLabel(DriverName.replace('Avg',''))+") "}</span>
            </div>

            <div className="w-full px-2" style={{height:'400px', overflowY:'auto', width:'640px'}}>
                  {FinalData && FinalData.length>0?
                  FinalData.map((item)=>
                  <>
                  <div className="p-1 border-b" >
                      <div className="px-2 py-1 font-bold">{item.xAxis}</div>

                      <StackEDAChart
                        getDemographicIndexData={item.data}
                        indexType={indexType}
                        xAxis={item.xAxis}
                      />

                  </div>
                  </>
                  ):
                  <div className="text-cenetr pt-6 text-gray-500 font-bold ">Data not awailable!</div>}
            </div>
              


          
          
          </div>



        </div>
        </div>
  </> 
  :null}

   </>
  );
}
