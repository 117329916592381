import {
  happinessConstants
} from '../_constants';

export function happiness(state = {}, action) {

  switch (action.type) {


        case happinessConstants.GET_HAPPINESS_EMP_RESPONDANTS_REQUEST:
          return {
            ...state,
            loading: true
          };
        case happinessConstants.GET_HAPPINESS_EMP_RESPONDANTS_SUCCESS:
          return {
            ...state,
            loading: false,
            getHappyPlusEmpRespondantsSuccess: true,
            getHappyPlusEmpRespondantsData:action.happiness.getHappyPlusEmpRespondantsData
  
          };
        case happinessConstants.GET_HAPPINESS_EMP_RESPONDANTS_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  


          case happinessConstants.GET_HAPPINESS_ORG_HAPPINESS_REQUEST:
            return {
              ...state,
              loading: true
            };
          case happinessConstants.GET_HAPPINESS_ORG_HAPPINESS_SUCCESS:
            return {
              ...state,
              loading: false,
              getHappyPlusOrgHappinessSuccess: true,
              getHappyPlusOrgHappinessData:action.happiness.getHappyPlusOrgHappinessData
    
            };
          case happinessConstants.GET_HAPPINESS_ORG_HAPPINESS_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };



            case happinessConstants.GET_HAPPINESS_ORG_HAPPINESS_DMG_WISE_REQUEST:
              return {
                ...state,
                loading: true
              };
            case happinessConstants.GET_HAPPINESS_ORG_HAPPINESS_DMG_WISE_SUCCESS:
              return {
                ...state,
                loading: false,
                getHappyPlusOrgHappinessDMGWiseSuccess: true,
                getHappyPlusOrgHappinessDMGWiseData:action.happiness.getHappyPlusOrgHappinessDMGWiseData
      
              };
            case happinessConstants.GET_HAPPINESS_ORG_HAPPINESS_DMG_WISE_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };
  


              case happinessConstants.GET_HAPPYPLUS_FILTER_DMG_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case happinessConstants.GET_HAPPYPLUS_FILTER_DMG_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  getHappyPlusFilterDMGSuccess: true,
                  getHappyPlusFilterDMGData:action.happiness.getHappyPlusFilterDMGData
        
                };
              case happinessConstants.GET_HAPPYPLUS_FILTER_DMG_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };


    default:
      return state
  }
}