import React, { Component } from "react";
import { connect } from "react-redux";
import { dashboardActions, userActions } from "../../../_actions";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileBox: false,

      showDropdown: false,
    };
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getClientProfile());
  }

  openProfile = (check) => {
    this.setState({ showProfileBox: !check });

    setTimeout(() => {
      this.setState({ showProfileBox: false });
    }, 4000);
  };
  handleLogout = () => {
    this.props.dispatch(userActions.logoutSession());
  };

  render() {
    let {
      dashboard,
      CurrentLanguageName,
      getLanguageData,
      handleLanguage,
      showLanguageModal,
      openLanguageModal,
    } = this.props;
    let { showProfileBox } = this.state;
    let { ClientProfileData } = dashboard;
    //console.log('ClientProfileData:', ClientProfileData)

    let ProfileName = "";

    let ProfileGender = "";
    if (ClientProfileData && ClientProfileData.name) {
      let nameGender = "";
      if (ClientProfileData && ClientProfileData.Gender) {
        nameGender = ClientProfileData.Gender;
      } else if (ClientProfileData && ClientProfileData.gender) {
        nameGender = ClientProfileData.gender;
      }

      ProfileGender = nameGender;
      ProfileName = ClientProfileData.name;
    }

    let { goBackFn, companyName } = this.props;

    return (
      <>
        <header className=" w-full flex justify-between items-center  md:px-4 px-4 bg-white  border-b sticky top-0 z-30">
          <div className="flex itmes-center space-x-4">
            <button
              onClick={() => goBackFn()}
              className="text-blue-500 bg-[#edf6fe] rounded-full cursor-pointer hover:bg-blue-100 trasition duration-150 p-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-arrow-left  "
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#2196f3"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <line x1="5" y1="12" x2="11" y2="18"></line>
                <line x1="5" y1="12" x2="11" y2="6"></line>
              </svg>
            </button>

            <div className="flex items-center space-x-4 ">
              <a href="#/app/overview">
                <img
                  src="/img/plus_ex_logo.svg"
                  className="w-24 object-cover"
                />
              </a>
              <span className="border py-1 px-2 text-gray-500 text-sm rounded-md">
                {companyName ? companyName : ""}
              </span>
            </div>
          </div>

          <div className="flex items-center justify-between py-3">
            <div className="lg:flex hidden border-r pr-3 mx-2 items-center relative ">
              <h1
                onClick={() => {
                  openLanguageModal(showLanguageModal);
                  this.openProfile(!false);
                }}
                className="text-sm font-medium cursor-pointer"
              >
                {CurrentLanguageName}
              </h1>
              <span
                onClick={() => {
                  openLanguageModal(showLanguageModal);
                  this.openProfile(!false);
                }}
                className="cursor-pointer material-symbols-outlined"
              >
                arrow_drop_down
              </span>

              {showLanguageModal ? (
                <>
                  <div className="absolute top-12 right-0 bg-white shadow-2xl w-40 whitespace-nowrap z-10  overflow-hidden  lang text-sm rounded-xl ">
                    {getLanguageData && getLanguageData.length > 0
                      ? getLanguageData.map((item) => (
                        <>
                          <div
                            onClick={() => handleLanguage(item)}
                            className="cursor-pointer w-full hover:bg-blue-50 py-2 px-4 flex  items-center "
                          >
                            <img
                              alt="code"
                              src={
                                item.name === "English"
                                  ? "https://cdn-icons-png.flaticon.com/512/330/330459.png"
                                  : item.name === "Chinese"
                                    ? "https://cdn-icons-png.flaticon.com/512/330/330651.png"
                                    : item.name === "Hindi"
                                      ? "https://cdn-icons-png.flaticon.com/512/330/330439.png"
                                      : item.name === "Japanise"
                                        ? "https://cdn-icons-png.flaticon.com/512/330/330622.png"
                                        : item.name === "French"
                                          ? "https://cdn-icons-png.flaticon.com/512/330/330490.png"
                                          : item.name === "Malayalam"
                                            ? "https://cdn-icons-png.flaticon.com/512/330/330439.png"
                                            : "https://cdn-icons-png.flaticon.com/512/330/330534.png"
                              }
                              className="w-4 mr-2"
                            />
                            {item.name}
                          </div>
                        </>
                      ))
                      : null}
                  </div>
                </>
              ) : null}
            </div>

            <div className="relative flex items-center ">
              <button
                onClick={() => this.openProfile(showProfileBox)}
                className="relative block h-8 w-8 ml-2 rounded-full overflow-hidden  focus:outline-none"
              >
                {ProfileGender === "Female" ? (
                  <img
                    className="h-full w-full object-cover"
                    src="img/profile/user2.png"
                    alt="Your avatar"
                  />
                ) : (
                  <img
                    className="h-full w-full object-cover"
                    src="img/profile/user1.png"
                    alt="Your avatar"
                  />
                )}
              </button>
              <div
                className="ml-2 lg:flex items-center cursor-pointer"
                onClick={() => this.openProfile(showProfileBox)}
              >
                <h1 className="text-sm text-[#212121] font-medium lg:flex hidden">
                  {ProfileName}
                </h1>
                <span className="material-symbols-outlined lg:flex hidden">
                  arrow_drop_down
                </span>
              </div>
              {showProfileBox ? (
                <>
                  <div className="bg-white shadow-xl rounded-xl p-4 absolute top-12 right-0 w-44 z-20 ">
                    <p
                      onClick={() => this.openProfile(showProfileBox)}
                      className="text-[#212121] font-medium  text-base truncate px-2 cursor-pointer"
                    >
                      {ProfileName}
                    </p>
                    <span
                      onClick={() => this.handleLogout()}
                      className="text-base hover:text-red-600 text-red-500 py-2 hover:font-medium block px-2 cursor-pointer"
                    >
                      Logout
                    </span>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </header>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { user, dashboard } = state;
  return {
    loggingIn,
    user,
    dashboard,
  };
}
export default connect(mapStateToProps)(Header);
