import React, { Component } from 'react';
import { connect } from 'react-redux';
import { surveyActions, dashboardActions } from '../../../../../../_actions';
import { CSVLink } from "react-csv";

import FilterDMG from './FilterDMG/Filter';
import ResponseCards from '../../../../../Dashboard/Overview/SurveyReport/Components/ResponseCards';
import MultiBarStaticChart from './Charts/MultiBarStaticChart';


class SurveyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {

      FilterValues: [],
      showFilter: false,
      showValue: '',



      selectFilterType: 1,
      surveyReportEmail: '',
      openModalComfirmSend: false,
      SaveEmployeeId: '',
      DemographValue: '',



      filterList: [
        {
          id: true,
          type: 'Show All'
        },
        {
          id: false,
          type: 'Show Filter'
        }
      ],

      isMailList: [
        {
          id: true,
          type: 'Delivered'
        },
        {
          id: false,
          type: 'Not Delivered'
        }
      ],

      isClickedList:
        [
          {
            id: true,
            type: 'Clicked',
          },
          {
            id: false,
            type: 'Not Clicked'
          }
        ],

      isCompleteList: [
        {
          id: true,
          type: 'Completed'
        },
        {
          id: false,
          type: 'Not Completed'
        }
      ],

      filterformData: {
        "showAll": true,
        "isMailSent": true,
        "isClicked": true,
        "isComplete": true
      },

      currFilterName: "Demographic"

    }
  }
  componentDidMount() {
    let { EmpFilterData, getIndexFilterData } = this.props;
    let { selectedFilterName, showViewBy } = this.state;

    let CurrentFilterName = 'Department';
    if (EmpFilterData && EmpFilterData.length > 0) {
      CurrentFilterName = EmpFilterData[0].name
    }

    let data = {
      "surveyId": this.props.CurrentSurveyId,
      "xAxis": CurrentFilterName
    }
    // this.props.dispatch(dashboardActions.responseAnalysisBySurveyId(data));
    this.props.dispatch(dashboardActions.getCustomerResponseAnalyticsFilter(data));

    this.props.dispatch(surveyActions.getIndexByFilterDMG({
      filters: []
    }));

  }


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }


  handleCreateSurveyPopup = () => {

  }

  handleSetFilter = (value) => {

    let { filterformData } = this.state;

    if (value === 'true' || value === true) {
      this.setState({ selectFilterType: 1 });
      filterformData['showAll'] = true;
    }
    else {
      this.setState({ selectFilterType: 2 });
      filterformData['showAll'] = false;
    }

    this.setState({ filterformData });

  }


  handleIsMailSent = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isMailSent'] = true;
    }
    else {
      filterformData['isMailSent'] = false;

    }
    this.setState({ filterformData });

  }

  handleIsClicked = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isClicked'] = true;
    }
    else {
      filterformData['isClicked'] = false;

    }
    this.setState({ filterformData });

  }

  handleIsComplete = (value) => {
    let { filterformData } = this.state;
    if (value === 'true' || value === true) {
      filterformData['isComplete'] = true;
    }
    else {
      filterformData['isComplete'] = false;

    }
    this.setState({ filterformData });

  }

  handleEmailInput = (val) => {
    this.setState({ surveyReportEmail: val });
  }

  HandleFilterType = (value) => {
    this.setState({ selectFilterType: value });
  }

  RefreshAPI = () => {
    let data = {
      surveyId: this.props.CurrentSurveyId
    }
    this.props.dispatch(surveyActions.responseAnalysisBySurveyId(data));
    this.props.dispatch(surveyActions.getCampaignEmployeesByIDSurveyWise(data));

  }


  forceMail = (id) => {
    this.setState({
      openModalComfirmSend: true,
      SaveEmployeeId: id
    })
    // this.props.dispatch(surveyActions.resendCampain({ id: id }));
  }


  handleSendSingleMail = () => {
    let { SaveEmployeeId } = this.state;
    this.props.dispatch(surveyActions.resendCampain({ id: SaveEmployeeId }));
    this.setState({ SaveEmployeeId: '' });
  }

  resendCampain = (id) => {
    this.setState({
      openModalComfirmSend: true,
      SaveEmployeeId: id
    })
    //  this.props.dispatch(surveyActions.resendCampain({ id: id }));
  }

  handleConfirmMailClose = () => {
    this.setState({ openModalComfirmSend: false, SaveEmployeeId: '' });
  }


  handleQuestionFilterName = (Name, Value) => {
    this.setState({ DemographValue: '' });

    let data = {
      "surveyId": this.props.CurrentSurveyId,
      "xAxis": Name
    }
    this.props.dispatch(dashboardActions.getResponseAnalytics(data));
  }

  handleQuestionFilterValue = (Name, Value) => {
    //console.log('Value:', Value)
    if (Value === 'All') {
      this.setState({ DemographValue: '' });
    }
    else {
      this.setState({ DemographValue: Value });
    }
  }

  handleQuestionFilterValueSave = () => {

  }

  handleQuestionFilterNameSave = () => {

  }


  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultifilterBehaviour } = this.props;
    this.setState({ FilterValues: finalFilter, currFilterName: name });
    //////console.log("finalFilter, true, name====>",finalFilter, true, name)
    // handleMultifilterBehaviour(finalFilter, true, name);





    let FilterValues = finalFilter;

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues;
      }

    }



    let data = {
      "surveyId": this.props.CurrentSurveyId,
      "xAxis": XAxis,
      "filters": Filters
    }
    this.props.dispatch(dashboardActions.getResponseAnalytics(data));


  }


  render() {
    let { survey, dashboard, closeResponseAnalytics,



      getIndexFilterData,
      currFilterName,
      getRespondantDetailsData,
      styleType

    } = this.props;
    // let {  getResponseAnalyticsData } = survey;
    let { getResponseAnalyticsData } = dashboard;
    let { getIndexByFilterDMGData } = survey;

    let { openModalComfirmSend, DemographValue, FilterValues } = this.state;


    let DemographicResponseNewData = getResponseAnalyticsData;

    function ResponseRate(item) {
      return (item.completed * 100) / item.totalInvite
    }


    if (DemographicResponseNewData && DemographicResponseNewData.length > 0) {
      DemographicResponseNewData.sort((a, b) => ResponseRate(b) - ResponseRate(a));
    }

    let EmpFilterData = [];
    let filterLevels = this.props.EmpFilterData;
    if (filterLevels && filterLevels.length > 0) {
      filterLevels.forEach((item) => {
        if (item.name !== "ManagerPID") { EmpFilterData.push(item); }
      })
    }


    //console.log('EmpFilterData====?', EmpFilterData)



    //console.log('==============>getIndexByFilterDMGData==============>', getIndexByFilterDMGData);

    //console.log('==============>DemographicResponseNewData==============>', DemographicResponseNewData);


    let DemographicValueList = []
    if (DemographicResponseNewData && DemographicResponseNewData.length > 0) {
      DemographicResponseNewData.forEach((value, index) => {
        DemographicValueList.push(value.name);
      });
    }

    //console.log('==============>DemographicResponseNewData==============>', DemographicResponseNewData);



    let CurrentFilterName = 'Department';
    if (EmpFilterData && EmpFilterData.length > 0) {
      CurrentFilterName = EmpFilterData[0].name
    }



    let DownloadExcelResponse = [];
    if (DemographicResponseNewData && DemographicResponseNewData.length > 0) {
      DemographicResponseNewData.forEach((item) => {
        let temp = {}
        temp[currFilterName ? currFilterName : 'Demographic'] = item.name;
        temp["Responded"] = item.completed;
        temp["Sent"] = item.totalInvite;
        temp["Response Rate"] = ((item.completed * 100) / item.totalInvite);

        DownloadExcelResponse.push(temp)
      })
    }



    return (
      <>
        <div className="">
          <div className="space-y-4" style={{}}>


            <div className="grid gap-6 lg:grid-cols-3 md:grid-cols-3 ">
              <ResponseCards getRespondantDetailsData={getRespondantDetailsData} />
            </div>
            {/* <div className="capitalize bg-white border rounded-md">

<div className='flex items-center justify-between pt-4 mx-3'>
      
        <FilterDMG
          showFilter={this.state.showFilter}
          showValue={this.state.showValue}
          EmpFilterData2={EmpFilterData}
          DemographicValueList2={getIndexByFilterDMGData}
          closeFilter={this.closeFilter}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        />
      

              <div className='mx-2'>
                  {DownloadExcelResponse && DownloadExcelResponse.length > 0 ?
                    <CSVLink data={DownloadExcelResponse} filename={"SurveyMetaResponseAnalytics.csv"}>
                      <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                        > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <path d="M10 12l4 4m0 -4l-4 4" />
                        </svg>
                        <span>Download Excel</span>
                      </button>
                    </CSVLink>
                    : '...'}
                </div>

    </div>



    <div className="divide-y">
     
     
    {(DemographicResponseNewData && DemographicResponseNewData.length > 0) ?
      DemographicResponseNewData.map((item) =>
        (DemographValue) ?
          (DemographValue === item.name) ?
            <div className="p-4 ">
              <div className="items-center justify-between md:flex ">
                <h1 className="pb-4 text-lg font-medium capitalize md:pb-0">{item.name} </h1> <span className="text-sm text-blue-500">{' Response Rate: ' + ResponseRate(item).toFixed(2) + '% '}</span> </div>
                <MultiBarStaticChart item={item} />
            </div>
            : null
            :       
            <div className="p-4 ">
              <div className="items-center justify-between md:flex ">
                <h1 className="pb-4 text-lg font-medium capitalize md:pb-0">{item.name} </h1> <span className="text-sm text-blue-500">{' Response Rate: ' + ResponseRate(item).toFixed(2) + '% '}</span> </div>
                <MultiBarStaticChart item={item} />
            </div>
         )
      : null}


    </div>
  </div> */}
          </div> </div>
      </>
    );
  }
}


function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(SurveyReport);
