import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';

class WordChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedText: ''
    }
  }



  componentDidMount() {
  }


  handleKeyword = (data) => {
    let { handleKeywordCall } = this.props;
    handleKeywordCall(data.text)
    this.setState({ SelectedText: data.text + ' (' + data.value + ')' });

  }

  handleKeywordTooltip = (data) => {
    this.setState({ SelectedText: data.text + ' (' + data.value + ')' });
  }


  render() {

    let { CloudData, loading } = this.props;
    // let { SelectedText }= this.state;


    let XAxisName = [CloudData]
    let XAxisNew = [""]

    let list = []

    if (XAxisName && XAxisName.length > 0) {
      XAxisName.forEach((item, index) => {
        let data = []
        if (item && item.length > 0) {
          item.forEach((item2) => {
            data.push(parseFloat(typeof item2 !== 'string' || item2 ? item2.toFixed(2) : 0))
          })
        }
        list.push(data)
      })
    }
    ////console.log('list: ',list)
    ////console.log('CloudData: ',CloudData)
    ////console.log('BoxPlotObj: ',BoxPlotObj)




    //       0: ["count", 1129]
    // 1: ["mean", 16.394154118689105]
    // 2: ["std", 18.919613481940207]
    // 3: ["min", 0]
    // 4: ["25%", 7]
    // 5: ["50%", 12]
    // 6: ["75%", 19]
    // 7: ["max", 279]




    return (

      <div className="md:p-6 p-4 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">

        <h1 className="font-medium xl:text-xl text-lg text-[#3D405B]">Text Length Distribution BoxPlot (Without Outliers)
        </h1>


        <div className='w-full'>
          {CloudData && CloudData.length > 0 ?
            <div className='space-y-6 '>
              <ReactEcharts
                className='!h-[200px]'
                style={{ height: '100%' }}
                option={{

                  dataset: [
                    {
                      source: list
                    },
                    {
                      transform: {
                        type: 'boxplot',
                        config: {
                          itemNameFormatter: function name(a) {
                            return XAxisNew[a.value]
                          }
                        }
                      }

                    },
                    {
                      fromDatasetIndex: 1,
                      fromTransformResult: 1
                    }
                  ],

                  tooltip: {
                    trigger: 'item',
                    axisPointer: {
                      type: 'shadow'
                    },
                    position: ['60%', '0%'],
                    formatter: function (a, b) {
                      return (
                        `<div>` +
                        `<b>` + XAxisNew[a.dataIndex] + `</b><br/>` +

                        `<p>` + '<b>Q4(No. of words in 75% to 100% of review):</b> ' + a.data[5] + `</p>` +
                        `<p>` + '<b>Q3(No. of words in 50% to 75% of review):</b> ' + a.data[4] + `</p>` +
                        `<p>` + '<b>Q2(No. of words in 25% to 50% of review):</b> ' + a.data[3] + `</p> ` +
                        `<p>` + '<b>Q1(No. of words upto 25% of review):</b> ' + a.data[2] + `</p>` +
                        `<p>` + '<b>Minimum no. of words in a review:</b> ' + a.data[1] + `</p>`
                        + `</div>`
                      )
                    }
                  },
                  grid: {
                    left: "10px",
                    right: "10px",
                    bottom: "0%",
                    top: '10px',
                    containLabel: true
                  },
                  yAxis: {
                    data: XAxisNew,
                    type: 'category',
                    boundaryGap: true,
                    nameGap: 30,
                    splitArea: {
                      show: false
                    },
                    splitLine: {
                      show: false
                    },
                    label: {
                      show: false
                    }
                  },
                  xAxis: {
                    type: 'value',
                    //   name: 'km/s minus 299,000',
                    splitArea: {
                      show: true
                    },
                  },
                  series: [
                    {
                      name: 'boxplot',
                      type: 'boxplot',
                      color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: '#62cff4'
                        },
                        {
                          offset: 1,
                          color: '#2c67f2'
                        }]),
                      datasetIndex: 1
                    }
                  ]
                }}
              />
              <div className="text-center"><b>X-Axis:</b>{' Total no. of words in a review'}</div>

            </div>
            :
            <>
              {!loading ?
                <div className="pt-40 text-3xl text-center text-gray-400">We have not calculated this section!</div> : null}
            </>
          }
        </div>
        <div className="text-sm text-[#3d405B]/70" >* We are not showing data where employee count is less than 5</div>

      </div>

    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(WordChart);
