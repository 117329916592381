import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList: [],
      EmpIndexA: 0,
      EmpIndexB: 4
    }
  }
  componentDidMount() {

  }

  render() {
    let { AnswerObj, name, size, questionName } = this.props;

    let DataList = [];
    let total = 0;
    if (AnswerObj) {
      Object.keys(AnswerObj).forEach(key => {
        let score = parseInt(AnswerObj[key]);
        total += score;
        DataList.push({
          name: key,
          value: score
        });
      });
    }

    console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaAnswerObj: ', AnswerObj)
    // console.log('DataList: ',DataList)
    DataList.sort((a, b) => {
      if (a.name.slice(0, -2) && b.name.slice(0, -2)) {
        return (a.name.slice(5, 6) - b.name.slice(5, 6))
      }
    });


    function ordering(name) {
      let order = 111;
      if (name === "<25%") {
        order = 1;
      }
      else if (name === "25-50%") {
        order = 2;
      }
      else if (name === "50 - 75%") {
        order = 3;
      }
      else if (name === "More than 75%") {
        order = 4;
      }
      return order
    }

    let List = [];
    let MaxScore = 0;
    let MaxObj = {
      "value": 0
    }

    let sortingArr = [
      "Strongly Agree",
      "Agree",
      "Slightly Agree",
      "Slightly Disagree",
      "Disagree",
      "Strongly Disagree"
    ]

    if (DataList && DataList.length > 0) {
      DataList.sort((a, b) => sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name));
    }


    if (DataList && DataList.length > 0) {
      DataList.forEach((item) => {
        ////console.log("item.name==========>",item.name)
        if (item && item.name) {
          let temp = {
            "label": item.name,
            "number": ordering(item.name),
            "value": ((parseFloat(item.value) * 100) / total).toFixed(2),
            "itemStyle": { color: '#9933ff' }
          }


          if (item.name === "Strongly Agree" || item.name === "Agree") {
            let scorePerc = ((parseFloat(item.value) * 100) / total);
            MaxScore += scorePerc;

          }


          List.push(temp);
        }

      })
    }

    if (MaxScore) {
      MaxObj["value"] = (MaxScore).toFixed(2)
    }

    List.sort((a, b) => a.number - b.number)


    function isFloat(n) {
      return Number(n) % 1 != 0;
    }
    let { stage, index } = this.props;

    return (
      <>
        <div className='relative flex flex-col justify-between h-full tooltipshow '>
          <div className="flex justify-between pb-2">
            <div className="relative inline-block dropdown">
              <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] line-clamp-2">
                {questionName && questionName.length > 80 ? (index === -1 ? "" : ((index + 1) + ") ")) + questionName.slice(0, 80) + "..." : (index === -1 ? "" : ((index + 1) + ") ")) + questionName}</h1>
              <div className="absolute top-0 z-30 hidden p-4 pt-1 text-sm font-semibold bg-white border rounded-md shadow-md tooltipcard dropdown-menu -left-4">
                {(index === -1 ? "" : ((index + 1) + ") ")) + questionName}
              </div>
            </div>
          </div>

          <div className="mt-4 mb-8">
            <h1 className="text-2xl text-center ">{MaxObj && MaxObj["value"] ? MaxObj["value"] + "%" : "0%"}</h1>
            <div className="flex justify-center my-1">
              <span className="text-gray-500">{"Strongly Agree + Agree" + " with the question"}</span>
            </div>
          </div>

          <div className="space-y-2">
            {List && List.length > 0 ?
              List.map((item) =>
                <div className="flex items-center ">
                  <p className="w-40 text-sm font-semibold whitespace-nowrap">{item.label}</p>
                  <div className="w-full h-6 ml-1 bg-gray-200">
                    <div style={{ width: item.value + '%' }}
                      className="relative w-3/6 h-6 bg-gradient-to-r from-blue-400 to-blue-500 " >
                      <div className="absolute left-2 text-sm font-normal text-white p-0.5 bg-blue-500 bg-opacity-30">{item.value + '%'}</div>
                    </div>
                  </div>
                </div>
              ) : null}
          </div>

          {stage !== 2 ?
            <>
              <div class="flex justify-center p-4 mt-auto">
                <button class=" text-[#2196f3]  cursor-pointer transition duration-150 flex items-center uppercase font-medium xl:text-base text-sm ">{stage == 2 ? "View Details" : (stage == 3 ? "" : "View More")}<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right ml-2" width="34" height="34" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2196f3" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="5" y1="12" x2="19" y2="12"></line><line x1="15" y1="16" x2="19" y2="12"></line><line x1="15" y1="8" x2="19" y2="12"></line></svg>
                </button>
              </div>
            </>
            : null}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(PieChart);
