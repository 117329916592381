import React, { Component } from 'react';
import { connect } from 'react-redux';

class IndexKPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { ResponseAction } = this.props;




    return (
      <div className="grid  md:grid-cols-3 grid-cols-1  gap-4 ">

        <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-white ">
          <div className="w-full flex items-center ">
            <div className="space-y-2">
              <p className="block text-base text-[#212112]/80 w-full capitalize ">
                Assigned Goals
              </p>
              <h1 className="text-3xl font-medium w-full text-[#212121]">
                {ResponseAction["assigned"]}
              </h1>
            </div>
          </div>
          <div className="p-4 bg-[#2196F3]/10 text-[#2196F3] rounded-md">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checklist " width="50" height="50" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9.615 20h-2.615a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8"></path><path d="M14 19l2 2l4 -4"></path><path d="M9 8h4"></path><path d="M9 12h2"></path></svg>
          </div>
        </div>

        <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-white ">
          <div className="w-full flex items-center ">
            <div className="space-y-2">
              <p className="block text-base text-[#212112]/80 w-full capitalize ">
                Pending Goals
              </p>
              <h1 className="text-3xl font-medium w-full text-[#212121]">
                {ResponseAction["pending"]}
              </h1>
            </div>
          </div>
          <div className="p-4 bg-[#2196F3]/10 text-[#2196F3] rounded-md">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clipboard-text" width="50" height="50" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" /><path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /><path d="M9 12h6" /><path d="M9 16h6" /></svg>
          </div>
        </div>

        <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-white ">
          <div className="w-full flex items-center ">
            <div className="space-y-2">
              <p className="block text-base text-[#212112]/80 w-full capitalize ">
                completed
              </p>
              <h1 className="text-3xl font-medium w-full text-[#212121]">
                {ResponseAction["completed"]}
              </h1>
            </div>
          </div>
          <div className="p-4 bg-[#2196F3]/10 text-[#2196F3] rounded-md">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clipboard-check " width="50" height="50" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path><path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path><path d="M9 14l2 2l4 -4"></path></svg>
          </div>
        </div>

      </div>

    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexKPI);
