import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../_helpers';

//api call
import { surveyActions, dashboardActions, riskdashtype2Actions, questionsActions, userActions } from '../../_actions';

//libraries
import LoadingOverlay from 'react-loading-overlay';

//Layout

import Header from '../CommonComponents/DashboardHeader/Header';
import Sidebar from './Sidebar/Sidebar';
import Overview from './Overview//Overview';
// import TextAnalysis from '../Dashboard/TextAnalysis/TextAnalysis';
import QuestionDetails from '../Dashboard/QuestionDetails/QuestionDetails';

import QuestionCard from '../Dashboard/Overview/Components/QuestionTypeCards/QuestionCard';

import RiskProfile from './RiskProfile/RiskProfile';

import RiskDemographics from './RiskProfile/Components/RiskDemographics/RiskDemographics';

import RiskActionPlan from './RiskActionPlan/RiskActionPlan';
import OneToOneConversation from './OneToOneConversation/OneToOneConversation';



class GeneralDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IndexNo: 0,
      CurrentSurveyName: '',
      CurrentSurveyId: this.props.match.params.id,
      // "5fa04d316ede0f0336066cce"
      showOverview: true,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showRiskProfile: false,
      showRiskActionPlan: false,
      showOneToOneConversation: false,
      ItemSaveData: '',
      cardType: '',
      currentQuestionId: '',
      currentQuestionTemplate: '',

      itemSurveyReport: false,
      itemOverview: false,
      itemAllQuestions: false,

      showAllQues: false,

      crrSelectedEmp: {},

      DriverTable: false

    }
  }
  componentDidMount() {

    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }


    // let data2 = {
    //   "surveyId": this.state.CurrentSurveyId
    // }





    this.props.dispatch(questionsActions.getQuestionListBySurveyID({ "surveyId": this.state.CurrentSurveyId }));

    this.props.dispatch(dashboardActions.getEmpFilter("getMeanScoreQwise", this.state.CurrentSurveyId));

    this.props.dispatch(dashboardActions.getClientProfile(data1));

    // this.props.dispatch(riskdashtype2Actions.getSurvey(data1));


    let data2 = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(surveyActions.getSurveyById(data2));

    this.props.dispatch(riskdashtype2Actions.getDashboardCard(data2));

    this.props.dispatch(dashboardActions.getRespondantDetails(data2));

    this.props.dispatch(riskdashtype2Actions.RiskgetRiskCategory());

    this.props.dispatch(riskdashtype2Actions.RiskRespondantEmployees(data2));


    let data3 = {
      // "surveyId": this.state.CurrentSurveyId,
      "filters": []
    }

    this.props.dispatch(riskdashtype2Actions.getIndexByFilterDMG(data3));

    this.props.dispatch(riskdashtype2Actions.getQuestionDistOverall(data3));

    this.props.dispatch(riskdashtype2Actions.getQuestionMatrixResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [13] }));

    this.props.dispatch(riskdashtype2Actions.getQuestionOthersResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [1, 5, 6, 11] }));

    // this.props.dispatch(riskdashtype2Actions.getQuestionOthersResult({surveyId:this.state.CurrentSurveyId,qtemplateList:[13]}));

    // this.props.dispatch(riskdashtype2Actions.getQuestionOthersResult({
    //   surveyId:this.state.CurrentSurveyId,
    //   qtemplateList:[5,11],
    //   filters:[{
    //     level:"Gender",
    //     value:"Male"
    //   }]
    // }));
    this.props.dispatch(riskdashtype2Actions.RiskAllEmpQuesResponses(data2));

    this.props.dispatch(riskdashtype2Actions.getAllRiskEmpConversation(data2));
  }



  handleLogout = () => {
    this.props.dispatch(userActions.logoutSession());
  }


  ChooseSurvey = (val) => {

    // //console.log('CurrentSurveyId: -------->',val)

    this.setState({ CurrentSurveyId: val })


  }

  onOverview = () => {
    this.setState({
      showOverview: true,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showRiskProfile: false,
      showQuesCard: false,
      showRiskDemographics: false,
      showRiskActionPlan: false,
      showOneToOneConversation: false,
      CurrentSurveyId: this.state.CurrentSurveyId
    });
    this.props.dispatch(riskdashtype2Actions.getQuestionOthersResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [1, 5, 6, 11] }));

  }


  handleSidebarClick = (isIndex, data, index, check) => {
    if (isIndex) {
      this.onCardClick('index', data, index, check);
    }
    else {
      this.onOverview()
      // this.setState({
      //   showOverview:true,
      //   showAllQuestions:false,
      //   showIndex:false,
      //   showTextAnalysis:false,
      //   showAdvanceInsight:false,
      //   showQuesCard:false,
      //   IndexNo:0,
      //   ItemSaveData:'',
      //   CurrentSurveyId:this.state.CurrentSurveyId
      // });
    }
  }


  callEmpQuestionResponses = (emp_id, isEmpty) => {
    let data21 = {
      "emp_id": emp_id,
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(riskdashtype2Actions.RiskgetEmpQuestionResponses(data21, isEmpty));

    let data212 = {
      "emp_id": emp_id
    }
    this.props.dispatch(dashboardActions.getAssessmentReportByEmpIDOnly(data212, isEmpty));

  }


  handleSidebarExtra = (type) => {
    if (type === 1) {
      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showIndex: false,
        showTextAnalysis: true,
        showAdvanceInsight: false,
        showRiskProfile: false,
        showQuesCard: false,
        showRiskDemographics: false,
        showRiskActionPlan: false,
        showOneToOneConversation: false,
        IndexNo: 0
      });

      let data2 = {
        "surveyId": this.state.CurrentSurveyId
      }
      this.props.dispatch(dashboardActions.getTextQuestion(data2, 1));
    }
    else if (type === 2) {
      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showIndex: false,
        showTextAnalysis: false,
        showAdvanceInsight: true,
        showRiskProfile: false,
        showQuesCard: false,
        showRiskDemographics: false,
        showRiskActionPlan: false,
        showOneToOneConversation: false,
        IndexNo: 0
      });


    }
    else if (type === 3) {
      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showIndex: false,
        showTextAnalysis: false,
        showAdvanceInsight: false,
        showRiskProfile: true,
        showQuesCard: false,
        showRiskDemographics: false,
        showRiskActionPlan: false,
        showOneToOneConversation: false,
        IndexNo: 0
      });

      let data2 = {
        "surveyId": this.state.CurrentSurveyId
      }

      this.props.dispatch(riskdashtype2Actions.RiskgetRiskCategory());
      this.props.dispatch(riskdashtype2Actions.RiskRespondantEmployees(data2));



    }
    else if (type === 4) {
      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showIndex: false,
        showTextAnalysis: false,
        showAdvanceInsight: false,
        showRiskProfile: false,
        showQuesCard: false,
        showRiskDemographics: false,
        showRiskActionPlan: true,
        showOneToOneConversation: false,
        IndexNo: 0
      });

      let data2 = {
        "surveyId": this.state.CurrentSurveyId
      }

      this.props.dispatch(riskdashtype2Actions.getAllRiskActionPlan(data2));

    }

    else if (type === 5) {
      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showIndex: false,
        showTextAnalysis: false,
        showAdvanceInsight: false,
        showRiskProfile: false,
        showQuesCard: false,
        showRiskDemographics: false,
        showRiskActionPlan: false,
        showOneToOneConversation: true,
        IndexNo: 0
      });

      let data2 = {
        "surveyId": this.state.CurrentSurveyId
      }

      this.props.dispatch(riskdashtype2Actions.getAllRiskEmpConversation(data2));

      this.props.dispatch(riskdashtype2Actions.RiskgetRiskCategory());
      this.props.dispatch(riskdashtype2Actions.RiskRespondantEmployees(data2));
    }




  }


  //------------------------------------------------------------------------------------------------------------------------




  handleDemographicFilter = (value) => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": value
    }
    // this.props.dispatch(riskdashtype2Actions.getMeanScore(data2));
    this.props.dispatch(riskdashtype2Actions.getMeanScoreQwise(data2));

  }
  handleQuestionMeanScore = (value) => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": value
    }
    this.props.dispatch(riskdashtype2Actions.getMeanScoreQwise(data2));
  }

  handleDemographicOverallQFilter = (value) => {
    // ////console.log('value handleDemographicOverallQFilter',value)
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": value
    }
    this.props.dispatch(riskdashtype2Actions.getMeanScoreQwise(data2));

  }


  //------------------------------------------------------------------------------------------------------------------------

  handleQuestion = () => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": []
    }
    this.props.dispatch(riskdashtype2Actions.getQuestionDist(data2));
    this.props.dispatch(riskdashtype2Actions.getQuestionDistOverall(data2));

  }

  handleResponseDistributionFilter = (name, value) => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": [{
        "level": name,
        "value": value
      }]
    }
    this.props.dispatch(riskdashtype2Actions.getQuestionDist(data2));
  }
  //------------------------------------------------------------------------------------------------------------------------

  goBackFromPage = () => {
    this.props.history.goBack()
  }

  handleOverview = () => {
    console.log("working...............")
    // this.overviewAPI();
    if (this.state.showOverview) {
      if (this.state.showAllQuestions) {
        this.setState({
          showOverview: true,
          showAllQuestions: false,
          showIndex: false,
          showTextAnalysis: false,
          showAdvanceInsight: false,
          showRiskProfile: false,
          showQuesCard: false,
          showRiskDemographics: false,
          showRiskActionPlan: false,
          showOneToOneConversation: false,
          currentQuestionTemplate: 0
        })
      }
      else {
        this.props.history.goBack()
      }
    }
    else {

      if (this.state.showQuesCard) {
        this.setState({
          showOverview: false,
          showAllQuestions: true,
          showIndex: false,
          showTextAnalysis: false,
          showAdvanceInsight: false,
          showRiskProfile: false,
          showRiskActionPlan: false,
          showRiskDemographics: false,
          showOneToOneConversation: false,
          showQuesCard: false
        })
      }
      else {

        this.onOverview();

        this.handleSidebarClick(false)
      }


    }
  }




  onOthersCardClick = (item) => {

    // //console.log('item:',item);
    // //console.log('item Qid:',item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"");


    this.setState({
      showOverview: false,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showRiskProfile: false,
      showQuesCard: true,
      showRiskDemographics: false,
      showRiskActionPlan: false,
      showOneToOneConversation: false,
      currentQuestionId: item.answerList && item.answerList[0] && item.answerList[0].QuesId ? item.answerList[0].QuesId : "",
      currentQuestionTemplate: item.answerList && item.answerList[0] && item.answerList[0].template ? item.answerList[0].template : ""

    }, () => {
      let { currentQuestionTemplate } = this.state;

      let { dashboard } = this.props;
      let { EmpFilterData } = dashboard;

      let AxisInit = "Department";
      if (EmpFilterData && EmpFilterData.length > 0) {
        EmpFilterData.forEach((item, index) => {
          if (item.level && index === 0) {
            AxisInit = item.level;
          }
        });
      }

      if (currentQuestionTemplate !== 13) {
        let data7 = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplateList": [currentQuestionTemplate],
          "xAxis": AxisInit,
          "filters": []
        }
        this.props.dispatch(riskdashtype2Actions.getQuestionOthersResult(data7));
      }

    });



  }




  handleOverViewRoute = (item1, item2, item3) => {

    this.setState({
      itemSurveyReport: item1,
      itemOverview: item2,
      itemAllQuestions: item3
    });


  }





  handleOthersFilterCall = (FilterValues, isValue, qTemplate, q2Template) => {
    //let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        //XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        //XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }


    //////console.log("--------------qTemplate-------.",qTemplate)

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplateList": [qTemplate],
      "xAxis": isValue,
      "filters": Filters
    }

    if (qTemplate === 13) {
      this.props.dispatch(riskdashtype2Actions.getQuestionMatrixResult(data7));
    }
    else {
      this.props.dispatch(riskdashtype2Actions.getQuestionOthersResultFilter(data7));
    }



    if (q2Template) {



      let data72 = {
        "surveyId": this.state.CurrentSurveyId,
        "qtemplateList": [q2Template],
        "xAxis": isValue,
        "filters": Filters
      }

      this.props.dispatch(riskdashtype2Actions.getQuestion2OthersResultFilter(data72));



    }

  }




  overviewAPI = () => {
    this.props.dispatch(riskdashtype2Actions.getQuestionOthersResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [1, 5, 6, 11] }));
  }




  onCrossTab = () => {

    let data = {
      surveyId: this.state.CurrentSurveyId
    }

    this.props.dispatch(questionsActions.getQuestionListBySurveyID(data));


  }
  handleCrossTabApi = (QuesID1, QuesID2, FilterValues) => {

    let data = {
      // "xAxis": "Department",
      "surveyId": this.state.CurrentSurveyId,
      "questionId1": QuesID1.id,
      "questionId2": QuesID2.id,
      "filters": FilterValues
    }
    // this.props.dispatch(dashboardActions.crossTab(data));

    this.props.dispatch(dashboardActions.crossTabAnalysis(data));

  }


  handleQueCorrelation = (QuesID1, QuesList, FilterValues) => {
    //////console.log("asdasdasd")
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "questionId1": QuesID1.id,
      "questionList": QuesList,
      "filters": FilterValues
    }

    //////console.log("===========handleQueCorrelation============")

    this.props.dispatch(dashboardActions.getCorrelationQuestionType2(data2));

  }




  storeInnerRoute = (value) => {
    this.setState({ showAllQues: value });
  }



  onAllQuestions = (item) => {
    // this.setState({CurrentTemplateId:item.templateId})
    this.setState({
      showOverview: false,
      showAllQuestions: true,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showRiskProfile: false,
      showQuesCard: false,
      showRiskDemographics: false,
      showRiskActionPlan: false,
      showOneToOneConversation: false,
      currentQuestionTemplate: item.templateId
    })

  }


  onAdvanceWorldCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 4));

  }


  onTreeMap = () => {
    this.props.dispatch(dashboardActions.EmptyTreeMap());
  }



  onAllMatrixQuestions = (item) => {
    // this.setState({CurrentTemplateId:item.templateId})
    this.setState({
      showOverview: false,
      showAllQuestions: true,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showRiskProfile: false,
      showQuesCard: false,
      showRiskDemographics: false,
      showRiskActionPlan: false,
      showOneToOneConversation: false,
      currentQuestionTemplate: 13
    })

  }


  handleRiskDemographics = (check) => {
    // this.setState({CurrentTemplateId:item.templateId})
    this.setState({
      showOverview: false,
      showAllQuestions: true,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showRiskProfile: false,
      showQuesCard: false,
      showRiskDemographics: true,
      showRiskActionPlan: false,
      showOneToOneConversation: false,
      currentQuestionTemplate: 13,
      DriverTable: check ? true : false
    });





  }





  // oMatrixQuestionDetail=()=>{
  //   this.setState({
  //     showOverview:false,
  //     showAllQuestions:false,
  //     showIndex:false,
  //     showTextAnalysis:false,
  //     showAdvanceInsight:false,
  //     showQuesCard:true,
  //     currentQuestionId:ques && ques.id?ques.id:"",
  //     currentQuestionTemplate:13

  //   });
  //   this.props.dispatch(riskdashtype2Actions.getQuestionMatrixResult({surveyId:this.state.CurrentSurveyId,qtemplateList:[13]}));

  // }
  handleMatrixDetails = (ques) => {
    this.setState({
      showOverview: false,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showRiskProfile: false,
      showQuesCard: true,
      showRiskDemographics: false,
      showRiskActionPlan: false,
      showOneToOneConversation: false,
      currentQuestionId: ques && ques.id ? ques.id : "",
      currentQuestionTemplate: 13

    });
    this.props.dispatch(riskdashtype2Actions.getQuestionMatrixResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [13] }));

  }


  callQuestionNetworkCloud = (QuesId, graphType, FilterValues) => {
    let { CurrentSurveyId } = this.state;


    // let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        // XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        // XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }



    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters && Filters.length > 0 ? Filters : []
    }

    if (graphType === 1) {
      this.props.dispatch(dashboardActions.cooccurmatrix(data2));

    }
    else if (graphType === 2) {
      this.props.dispatch(dashboardActions.neighborGraph(data2));
    }


  }


  handleSelectEmpFilter = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {

      if (value && value.emp_id) {
        let data2 = {
          "surveyId": this.state.CurrentSurveyId,
          "emp_id": value.emp_id
        }
        this.props.dispatch(riskdashtype2Actions.RiskgetEmpQuestionResponses(data2));
      }



    })
  }



  handleRiskAllEmpQueFilter = (FilterValues, isValue) => {

    // let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        // XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        // XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": isValue,
      "filters": Filters
    }

    this.props.dispatch(riskdashtype2Actions.RiskFilterEmpQuesResponses(data7));


  }


  handleRedictToProfile = (empDetails) => {
    if (empDetails && empDetails.emp_id && this.state.CurrentSurveyId) {

      // this.props.history.push(`/app/sensedashboardEmp/${this.state.CurrentSurveyId}/${emp_id}`);
      this.setState({ crrSelectedEmp: empDetails })

      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showIndex: false,
        showTextAnalysis: false,
        showAdvanceInsight: false,
        showRiskProfile: true,
        showQuesCard: false,
        showRiskDemographics: false,
        showRiskActionPlan: false,
        showOneToOneConversation: false,
        IndexNo: 0
      });

      let data2 = {
        "surveyId": this.state.CurrentSurveyId
      }

      this.props.dispatch(riskdashtype2Actions.RiskgetRiskCategory());
      this.props.dispatch(riskdashtype2Actions.RiskRespondantEmployees(data2, "custom", empDetails.emp_id));



    }
  }



  emptyRiskQuesEmp = () => {
    this.setState({ crrSelectedEmp: {} })
    this.props.dispatch(riskdashtype2Actions.emptyRiskgetEmpQuestionResponses());
  }


  handleOneToOneScheduleSubmit = (data, type) => {
    //////console.log("data----------->",data)
    let temp = {
      ...data,
      "surveyId": this.state.CurrentSurveyId,
      "type": type
    }
    this.props.dispatch(riskdashtype2Actions.onScheduleSubmit(temp));
  }

  handleTalkingSubmit = (data, type) => {
    let temp = {
      ...data,
      "surveyId": this.state.CurrentSurveyId,
      "type": type
    }
    this.props.dispatch(riskdashtype2Actions.onScheduleSubmit(temp));
  }

  submitPeoptoMeetResolved = (data) => {
    let query = {
      "id": data.id,
      "text": data && data.text ? data.text : "",
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(riskdashtype2Actions.submitPeoptoMeetResolved(query));
  }


  handlePTMAssessment = (emp_id) => {
    let query = {
      "emp_id": emp_id
    }
    this.props.dispatch(dashboardActions.getAssessmentReportByEmpIDOnly(query));
  }


  handleAllRiskEmpConversation = (FilterValues) => {

    // let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        // XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        // XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }

    let query = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters
    }

    this.props.dispatch(riskdashtype2Actions.getAllRiskEmpConversation(query));

  }

  onOverviewFromHeader = () => {
    this.props.history.goBack()
  }


  render() {

    let { riskdashtype2, dashboard, survey, questions } = this.props;

    let { getSurveyData } = survey;
    let { questionsList } = questions;

    let { getAllRiskEmpConversationData, getAllRiskActionPlanData, RiskFilterEmpQuesResponsesData, RiskAllEmpQuesResponsesData, getIndexByFilterDMGData, RiskRespondantEmployeesData, RiskgetEmpQuestionResponsesData, RiskgetRiskCategoryData } = riskdashtype2;

    let getIndexFilterData = getIndexByFilterDMGData;


    let { neighborGraphData, cooccurmatrixData, wordCloudData, wordTextKeyWord, wordMultiTextKeyWord,
      wordCloudTextData, wordCloudMultiTextData, getTextQuestionData, ClientProfileData, EmpFilterData,
      getRespondantDetailsData,
      AdvanceWordCloudData,
      crossTabAnalysisData,
      crossTabData,
      TreeMapData,

      getCorrelationQuestionType2Data,

      getAssessmentReportByEmpIDOnlyData

    } = dashboard;
    let { getQuestion2OthersResultFilterData, getQuestionOthersResultData, getQuestionOthersResultFilterData, getQuestionMatrixResultData, SurveyList, getMeanScoreQwiseData, getDashboardCardData, getQuestionDistData, getQuestionDistOverallData, getMeanScoreData } = riskdashtype2;

    let { showOneToOneConversation, showRiskActionPlan, CurrentSurveyId, ItemSaveData, currentQuestionId, currentQuestionTemplate,

      showOverview, showAllQuestions,
      showTextAnalysis, showAdvanceInsight, showQuesCard, showRiskDemographics, showIndex, IndexNo, cardType, showRiskProfile,

      crrSelectedEmp,


      DriverTable

    } = this.state;

    let loading = dashboard.loading || riskdashtype2.loading;
    // let loading = false;




    ////console.log('/////////////////////////////////////////////////=================================|||=========================>:');
    //console.log( '-----------------------------> crrSelectedEmp ',crrSelectedEmp)








    // ////console.log('getDashboardCardData: ',getDashboardCardData)
    // ////console.log('getQuestionDistData: ',getQuestionDistData)

    // ////console.log('showIndex: ',showIndex)
    // ////console.log('showManagerList: ',showManagerList)
    // ////console.log('showAdvanceComparision: ',showAdvanceComparision)
    // ////console.log('showTextAnalysis: ',showTextAnalysis)
    let OverviewList = [];
    let SumMean = 0;
    let OverallMean = 0;
    let total = 0;

    if (getDashboardCardData && getDashboardCardData.length > 0) {
      getDashboardCardData.forEach((item, index) => {
        if (item && item._id) {
          SumMean += parseFloat(item && item.mean ? item.mean : 0.0);
          total += 1;
          OverviewList.push({
            "id": item._id,
            "name": item.parameter && item.parameter.length > 0 && item.parameter[0] && item.parameter[0].name ? item.parameter[0].name : "NaN",
            "mean": (typeof item !== 'string' || item instanceof Number) && item && item.mean ? item.mean.toFixed(2) : 'NaN',
            "color": '#757575'
          });
        }
      });
      OverallMean = (SumMean / total);

    }
    ////////console.log("OverallMean",OverallMean)
    ////////console.log("SumMean",SumMean)
    ////////console.log("total",total)
    ////////console.log("getDashboardCardData",getDashboardCardData)


    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageactionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }

    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    ////console.log('moduleAccess: ',moduleAccess)



    // let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        // SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    //END -------------------------------------------------------







    function FormattFun(inputData, isSummary) {
      let RankObj = getSurveyData && getSurveyData.designObj && getSurveyData.designObj.componentRank ? getSurveyData.designObj.componentRank : {}

      let LIST = []
      let prevTemList = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {

          let templateId = ques.total[0]._id.qtemplate;

          let temp = {
            name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
            answerList: [],
            rank: RankObj && RankObj[templateId] ? RankObj[templateId] : 100,
            templateId: templateId
          }
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading: item && item._id && item._id.heading ? item._id.heading : "",
                parentId: item && item._id && item._id.parentId ? item._id.parentId : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId
              }
              temp["answerList"].push(ans);
            });
          }


          if (isSummary) {
            let getIndex = prevTemList.findIndex(prev => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId)
            }
          }
          else {
            LIST.push(temp);
          }


        });
      }
      return LIST
    }

    //  let NPSQuesList = FormattFun(getQuestionNPSResultData);
    let AllQuesList = FormattFun(getQuestionOthersResultData, false).filter(x => x.templateId === currentQuestionTemplate);


    //Risk Demographic Calculations


    function ToString(value) {
      return value ? (value.toString()).trim() : ""
    }

    function calcScore(questionDetail) {
      let sum = 0;
      if (questionDetail && questionDetail.length > 0) {
        questionDetail.forEach((ques) => {
          if (ques.qtemplate === 1) {
            sum += Number(ques.answer)
          }
        })
      }
      return sum
    }


    function getRiskMeterScore(resList, CatList, type) {
      let RiskOverallScore = 0
      let RiskCategoryScore = {}

      if (CatList && CatList.length > 0 && resList && resList.length > 0) {
        CatList.forEach((category) => {
          //console.log('category-------->',category);
          let questionDetailFinal = resList.filter(prev => ToString(prev.riskCategoryId) === ToString(category.id));
          RiskOverallScore += calcScore(questionDetailFinal);

          RiskCategoryScore[category.name] = calcScore(questionDetailFinal);

        })
      }
      return (type === "overall" ? Number(RiskOverallScore) : RiskCategoryScore)
    }



    let riskMeterCountObj = {
      "Extremely_High_Risk": 0,
      "High_Risk": 0,
      "Borderline": 0,
      "Moderate_Risk": 0,
      "No_Risk": 0
    }
    // let empResScoreList = []

    let overallCategoryScore = {}
    if (RiskgetRiskCategoryData && RiskgetRiskCategoryData.length > 0) {
      RiskgetRiskCategoryData.forEach((category) => {
        overallCategoryScore[category.name] = 0
      })
    }
    //console.log('<<<<<<<<<<<<<<<<<<< ------------------- getAllRiskEmpConversationData',getAllRiskEmpConversationData);


    function getNoRiskResolved(emp_id) {
      //console.log('<<<<<<<<<<<<<<<<<<< ------------------- resolvedCategory emp_id',emp_id);

      let value = null;
      if (getAllRiskEmpConversationData && getAllRiskEmpConversationData && getAllRiskEmpConversationData.list.length > 0) {
        let getIndexRMC = getAllRiskEmpConversationData.list.findIndex(prev => prev.emp_id === emp_id);
        if (getIndexRMC !== -1) {
          value = getAllRiskEmpConversationData["list"][getIndexRMC].riskCategory;
        }
      }
      //console.log('<<<<<<<<<<<<<<<<<<< ------------------- resolvedCategory value',value);

      return value
    }

    if (RiskAllEmpQuesResponsesData && RiskAllEmpQuesResponsesData.length > 0) {
      RiskAllEmpQuesResponsesData.forEach((resData, index) => {

        let resolvedCategory = null;
        if (resData && resData["_id"] && resData["_id"]["emp_id"]) {
          resolvedCategory = getNoRiskResolved(resData["_id"]["emp_id"])
        }

        let getRiskScore = getRiskMeterScore(resData.responses, RiskgetRiskCategoryData, "overall");

        let getDriverScoreObj = getRiskMeterScore(resData.responses, RiskgetRiskCategoryData, "category");
        if (RiskgetRiskCategoryData && RiskgetRiskCategoryData.length > 0) {
          RiskgetRiskCategoryData.forEach((category) => {
            overallCategoryScore[category.name] += getDriverScoreObj[category.name]

            if (RiskAllEmpQuesResponsesData.length === (index + 1)) {
              overallCategoryScore[category.name] = (overallCategoryScore[category.name] / RiskAllEmpQuesResponsesData.length).toFixed(2);
            }

          })
        }



        // empResScoreList.push({
        //   "emp_id":resData && resData._id && resData._id.emp_id?resData._id.emp_id:("empId_"+(index+1)),
        //   "name":resData && resData._id && resData._id.name?resData._id.name:("empName_"+(index+1)),
        //   "score":getRiskScore
        // })
        //console.log('resolvedCategory ',resolvedCategory);

        if (resolvedCategory) {

          if (resolvedCategory === "Extremely_High_Risk") {
            riskMeterCountObj["Extremely_High_Risk"] += 1;
          }
          else if (resolvedCategory === "High_Risk") {
            riskMeterCountObj["High_Risk"] += 1;
          }
          else if (resolvedCategory === "Borderline") {
            riskMeterCountObj["Borderline"] += 1;
          }
          else if (resolvedCategory === "Moderate_Risk") {
            riskMeterCountObj["Moderate_Risk"] += 1;
          }
          else if (resolvedCategory === "No_Risk") {
            riskMeterCountObj["No_Risk"] += 1;
          }

        }
        else {

          if (getRiskScore >= 25 && getRiskScore < 50) {
            riskMeterCountObj["Extremely_High_Risk"] += 1;
          }
          else if (getRiskScore >= 50 && getRiskScore < 75) {
            riskMeterCountObj["High_Risk"] += 1;
          }
          else if (getRiskScore >= 75 && getRiskScore < 100) {
            riskMeterCountObj["Borderline"] += 1;
          }
          else if (getRiskScore >= 100 && getRiskScore < 125) {
            riskMeterCountObj["Moderate_Risk"] += 1;
          }
          else if (getRiskScore >= 125 && getRiskScore <= 150) {
            riskMeterCountObj["No_Risk"] += 1;
          }

        }


      });
    }

    //console.log('((((((((((((( overallCategoryScore-------->',overallCategoryScore);



    // 0
    // Extremely High Risk (25-50)

    // 100
    // High Risk (50-75)

    // 100
    // Borderline (75-100)

    // 100
    // Moderate Risk (100-125)

    // 100
    // No Risk (125-150)






    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text='Loading Data...'
          className="loader"
        >

          <div>

            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              SurveyList={SurveyList}
              ChooseSurvey={this.ChooseSurvey}
              CurrentSurveyId={CurrentSurveyId}
              props={this.props}
              handleOverview={this.goBackFromPage}
              onOverviewFromHeader={this.onOverviewFromHeader}
            />

            <div className="fixed flex w-full h-screen bg-gray-100" >
              {accessAllow("dashboard") ?
                <>

                  <Sidebar
                    SurveyList={SurveyList}
                    OverviewList={OverviewList}
                    handleSidebarClick={this.handleSidebarClick}
                    IndexNo={IndexNo}
                    showIndex={showIndex}
                    showOverview={showOverview}
                    showTextAnalysis={showTextAnalysis}
                    showAdvanceInsight={showAdvanceInsight}
                    showRiskProfile={showRiskProfile}
                    showOneToOneConversation={showOneToOneConversation}
                    handleSidebarExtra={this.handleSidebarExtra}
                  />


                  {showOverview ?
                    <Overview
                      CurrentSurveyId={CurrentSurveyId}
                      SurveyList={SurveyList}
                      EmpFilterData={EmpFilterData}
                      OverviewList={OverviewList}
                      OverallMean={OverallMean}
                      getMeanScoreQwiseData={getMeanScoreQwiseData}
                      getIndexFilterData={getIndexFilterData}
                      getRespondantDetailsData={getRespondantDetailsData}
                      getQuestionDistOverallData={getQuestionDistOverallData}
                      getQuestionMatrixResultData={getQuestionMatrixResultData}
                      questionsList={questionsList}
                      getQuestionOthersResultData={getQuestionOthersResultData}
                      onOthersCardClick={this.onOthersCardClick}
                      getSurveyData={getSurveyData}
                      overviewAPI={this.overviewAPI}
                      handleMatrixDetails={this.handleMatrixDetails}
                      handleOverViewRoute={this.handleOverViewRoute}
                      storeInnerRoute={this.storeInnerRoute}
                      onAllQuestions={this.onAllQuestions}
                      onAllMatrixQuestions={this.onAllMatrixQuestions}

                      //Risk Demographics
                      handleRiskDemographics={this.handleRiskDemographics}
                      riskMeterCountObj={riskMeterCountObj}
                      overallCategoryScore={overallCategoryScore}
                      RiskgetRiskCategoryData={RiskgetRiskCategoryData}
                      getAllRiskEmpConversationData={getAllRiskEmpConversationData}

                      handleSidebarExtra={this.handleSidebarExtra}

                    />
                    : null}


                  {showAllQuestions ?

                    <div className="">

                      {currentQuestionTemplate !== 13 ?
                        <div className="flex-shrink-0 md:ml-4  w-full h-full lg:p-6 p-4 space-y-6 overflow-hidden overflow-y-auto  bg-[#F9FAFB]" style={{ height: "calc(100% - 4rem)" }}>

                          <div className='flex items-center space-x-4'>
                            <span onClick={() => this.handleOverview()} class="cursor-pointer material-symbols-outlined bg-blue-100 p-2 rounded-full text-blue-500 ">arrow_back</span>
                            <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Questions Analysis</h1>
                          </div>
                          <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 ">
                            {AllQuesList && AllQuesList.length > 0 ? AllQuesList.map((item, index) =>
                              <div onClick={() => this.onOthersCardClick(item)} className="p-4 bg-white border rounded-lg">
                                <QuestionCard item={item} name={""} stage={2} index={index} />
                              </div>
                            ) : null}
                          </div>

                        </div>
                        :
                        <>
                          {getQuestionMatrixResultData && getQuestionMatrixResultData.length > 0 ?
                            <div className="p-4 border " >
                              <QuestionCard
                                questionType={"matrix"}
                                getQuestionMatrixResultData={getQuestionMatrixResultData}
                                questionsList={questionsList}
                                stage={2}
                                handleMatrixDetails={this.handleMatrixDetails}
                              />
                            </div>
                            : null}
                        </>

                      }

                    </div>

                    : null}



                  {showQuesCard ?

                    <QuestionDetails
                      handleGoBack={this.handleOverview}
                      ItemSaveData={ItemSaveData}
                      cardType={cardType}
                      handleQuestion={this.handleQuestion}
                      getQuestionDistData={getQuestionDistData}
                      getQuestionDistOverallData={getQuestionDistOverallData}
                      getMeanScoreQwiseData={getMeanScoreQwiseData}
                      handleResponseDistributionFilter={this.handleResponseDistributionFilter}
                      handleQuestionMeanScore={this.handleQuestionMeanScore}
                      EmpFilterData={EmpFilterData}
                      getIndexFilterData={getIndexFilterData}
                      handleDemographicFilter={this.handleDemographicFilter}
                      getMeanScoreData={getMeanScoreData}
                      handleDemographicOverallQFilter={this.handleDemographicOverallQFilter}
                      OverviewList={OverviewList}
                      getQuestionOthersResultData={getQuestionOthersResultData}
                      currentQuestionId={currentQuestionId}
                      currentQuestionTemplate={currentQuestionTemplate}

                      handleOthersFilterCall={this.handleOthersFilterCall}

                      getQuestionMatrixResultData={getQuestionMatrixResultData}
                      getQuestionOthersResultFilterData={getQuestionOthersResultFilterData}


                      questionsList={questionsList}

                      getQuestion2OthersResultFilterData={getQuestion2OthersResultFilterData}

                    />

                    : null}


                  {showRiskProfile ?
                    <RiskProfile
                      onCrossTab={this.onCrossTab}
                      EmpFilterData={EmpFilterData}
                      getIndexFilterData={getIndexFilterData}
                      handleCrossTabApi={this.handleCrossTabApi}
                      crossTabData={crossTabData}
                      crossTabAnalysisData={crossTabAnalysisData}
                      questionsList={questionsList}
                      handleQueCorrelation={this.handleQueCorrelation}
                      getCorrelationQuestionType2Data={getCorrelationQuestionType2Data}


                      RiskRespondantEmployeesData={RiskRespondantEmployeesData}
                      crrSelectedEmp={crrSelectedEmp}
                      handleSelectEmpFilter={this.handleSelectEmpFilter}

                      RiskgetEmpQuestionResponsesData={RiskgetEmpQuestionResponsesData}

                      RiskgetRiskCategoryData={RiskgetRiskCategoryData}

                      CurrentSurveyId={CurrentSurveyId}

                      RiskAllEmpQuesResponsesData={RiskAllEmpQuesResponsesData}

                      emptyRiskQuesEmp={this.emptyRiskQuesEmp}

                    />
                    : null}



                  {showRiskDemographics ?
                    <RiskDemographics
                      handleGoBack={this.handleOverview}
                      EmpFilterData={EmpFilterData}
                      getIndexFilterData={getIndexFilterData}
                      riskMeterCountObj={riskMeterCountObj}
                      handleRiskAllEmpQueFilter={this.handleRiskAllEmpQueFilter}
                      RiskAllEmpQuesResponsesData={RiskAllEmpQuesResponsesData}
                      RiskFilterEmpQuesResponsesData={RiskFilterEmpQuesResponsesData}
                      RiskgetRiskCategoryData={RiskgetRiskCategoryData}
                      CurrentSurveyId={CurrentSurveyId}
                      handleRedictToProfile={this.handleRedictToProfile}

                      DriverTable={DriverTable}
                      overallCategoryScore={overallCategoryScore}

                      getNoRiskResolved={getNoRiskResolved}
                      getAllRiskEmpConversationData={getAllRiskEmpConversationData}
                    />
                    : null}


                  {showRiskActionPlan ?
                    <RiskActionPlan

                      EmpFilterData={EmpFilterData}
                      getIndexFilterData={getIndexFilterData}
                      getAllManagerActionPlanData={getAllRiskActionPlanData}
                      getIndexByFilterDMGData={getIndexByFilterDMGData}
                    />
                    : null}



                  {showOneToOneConversation ?
                    <>
                      <OneToOneConversation
                        getAllRiskEmpConversationData={getAllRiskEmpConversationData}
                        handleOneToOneScheduleSubmit={this.handleOneToOneScheduleSubmit}
                        handleTalkingSubmit={this.handleTalkingSubmit}
                        handleSelectEmpFilter={this.handleSelectEmpFilter}
                        RiskgetEmpQuestionResponsesData={RiskgetEmpQuestionResponsesData}
                        RiskgetRiskCategoryData={RiskgetRiskCategoryData}
                        EmpFilterData={EmpFilterData}
                        getIndexFilterData={getIndexFilterData}
                        emptyRiskQuesEmp={this.emptyRiskQuesEmp}
                        submitPeoptoMeetResolved={this.submitPeoptoMeetResolved}
                        handlePTMAssessment={this.handlePTMAssessment}
                        getAssessmentReportByEmpIDOnlyData={getAssessmentReportByEmpIDOnlyData}
                        handleAllRiskEmpConversation={this.handleAllRiskEmpConversation}
                        callEmpQuestionResponses={this.callEmpQuestionResponses}
                        loading={loading}
                      />
                    </> : null}

                </>
                : null}
            </div>


          </div>





        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, riskdashtype2, dashboard, survey, questions } = state;
  return {
    loggingIn,
    users,
    riskdashtype2,
    dashboard,
    survey,
    questions
  };
}
export default connect(mapStateToProps)(GeneralDashboard);
