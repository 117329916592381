import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import moment from 'moment';

import Dropdown from '../SubComponents/Dropdown';


export default function CreateCampaign(props) {
  let { getSurveyData, handleExitSurveyAutofill,
    
    
    senseSurveyList,crrSenseSurvey,handleSelectSenseSurvey, getPeopleToMeetList,


    EmpFilterData, recursiveSettings, campaignRecursiveFreqList, handleCampaignRecursiveType, campaignRecursiveTypeList, handleRecursiveType,

    showBatchRange, handleBatchRangeDropdown, selectBatchRange,  formBatchRange, AllEmpTotal,  campaignFormData, handleTestUserCheck, handleCampaignInput, handleMessageInput, handleCampaignSubmit, employees,
     handleCampaignEmployees, EmpIndexA, EmpIndexB, prevEmpPage, nextEmpPage, SelectEmpPage,
    currentEmpPage,  LanguageObj, CurrentLanguage,

    currFilterLevel, showLevelFilter, handleEmpLevelDropdown, handleLevelSelect,
    currFilterValue, showValueFilter, handleEmpValueDropdown, handleValueSelect, allCustomerList,

    employeeValueSearch, handleEmployeeValueSearch, getIndexByFilterDMGData, handleGetEmployees,
    campaignValidationList, handleRecursiveCampaignInput, handleType, TypeList,


    crrLaunchType,
    clusterAnalysisData,


    clusterCrrName, handleCusterSelect,



  } = props;


  // let [showSizeDrop, handleSizeDrop] = React.useState(false)
  let [showCampaignTypeDropdown, handleshowCampaignTypeDropdown] = React.useState(false);

  let [showTypeDropdown, handleshowTypeDropdown] = React.useState(false);

  let [showCampaignRecursiveDropdown, handleshowCampaignRecursiveDropdown] = React.useState(false);

  let [showRecursiveTypeDropdown, handleshowRecursiveTypeDropdown] = React.useState(false);


  let CustomerList = allCustomerList;


  ////console.log('-------------getPeopleToMeetList:---------------- ', getPeopleToMeetList);

  
  //  let NeglectedList = ["name","list","isTest", "isDisable", "Age","Alternatenumber", "emp_id","actionPlanStage", "ManagerPID","id","mngr_id","createdAt", "companyId", "currentStage"]
  let NeglectedList = ["name", "list"]

  let EmployeesList = [];

  let EmpLevelList = [];
  let EmpValueList = [];
  let EmpValueObj = {};



  let allEmployeesList = getPeopleToMeetList;
  if (allEmployeesList && allEmployeesList.length > 0) {

    let tempLevelList = getIndexByFilterDMGData ? Object.keys(getIndexByFilterDMGData) : {};
    if (tempLevelList && tempLevelList.length > 0) {
      tempLevelList.forEach((temp) => {
        if (NeglectedList.findIndex(prev => prev === temp) === -1) {
          EmpLevelList.push(temp)
        }
      });
    }

    if (EmpLevelList) {
      EmpLevelList.forEach((filter) => {
        EmpValueObj[filter] = [];

        allEmployeesList.forEach((emp) => {
          let getIndex = EmpValueObj[filter].findIndex(prev => prev === emp[filter]);
          if (getIndex === -1 && emp && emp[filter]) {
            EmpValueObj[filter].push(emp[filter]);
          }
        });
      });
    }


    // if(currFilterLevel!=="Select Filter" && currFilterValue!=="Select Value"){
    //   allEmployeesList.forEach((emp)=>{
    //     if(emp && (emp[currFilterLevel] === currFilterValue)){
    //       EmployeesList.push(emp);
    //     }
    //    });
    // }
    // else{
    //   EmployeesList = allEmployeesList;
    // }
    EmployeesList = allEmployeesList;



  }
  if (currFilterLevel && getIndexByFilterDMGData && getIndexByFilterDMGData[currFilterLevel]) {
    EmpValueList = getIndexByFilterDMGData[currFilterLevel];
  }



  ////console.log('EmpLevelList: ', EmpLevelList);


  


  //--------------------------------------------------------------------------------

  let User_List = [];

  if (campaignFormData.campaign_type === "CUSTOMER") {
    User_List = CustomerList;
  }
  else {
    User_List = EmployeesList;
  }

  // ////console.log('<<<<<<<<-EmployeesList:----------> ',EmployeesList);
  // ////console.log('<<<<<<<<-CustomerList:----------> ',CustomerList);


  //--------------------------------------------------------------------------------

  let IndexList = []
  let pageCount = 7
  let IndexListIndex = []
  let indexA = 0
  let indexB = 0

  if (User_List && User_List.length > 0) {


    if (User_List && User_List.length > 0) {
      User_List.forEach((item, index) => {
        if (index % pageCount === 0) {
          IndexList.push(index)
        }
        if (index === (User_List.length - 1)) {
          IndexList.push(index)
        }
      })
    }

    ////console.log('IndexList: ', IndexList)

    if (IndexList && IndexList.length > 0) {
      IndexList.forEach((item, index) => {
        if (index !== (IndexList.length - 1)) {
          IndexListIndex.push(index + 1)
        }
      })
    }
 
    if (IndexListIndex && IndexListIndex.length > 0) {
      IndexListIndex.forEach((item, index) => {
        if (item === currentEmpPage) {

          if (index < (IndexList.length - 2)) {
            indexA = IndexList[index];
            indexB = IndexList[index] + (pageCount - 1)
          }
          else {
            indexA = IndexList[index];
            indexB = IndexList[IndexList.length - 1]
          }
        }
      })
    }

  }


  // ////console.log('IndexListIndex: ',IndexListIndex)

  //--------------------------------------------------------------------------------

  let initSize = formBatchRange && formBatchRange.size ? formBatchRange.size : 1000;

  function getBatchRange(total) {
    let rangeList = []
    if (total && total > 0) {
      [...Array(total).keys()].forEach((item, index) => {
        if (index % initSize === 0) {
          rangeList.push(index)
        }
        if (index === ([...Array(total).keys()].length - 1)) {
          rangeList.push(index)
        }
      })
    }
    let RangeNameList = []
    if (rangeList && rangeList.length > 0) {
      rangeList.forEach((item, index) => {
        if ((index + 1) !== rangeList.length) {
          let front = (item + 1).toString();
          let end = (item + initSize).toString();
          if ((index + 1) === (rangeList.length - 1)) {
            end = total.toString()
          }
          let temp = {
            "label": front + " - " + end,
            "rangeNo": index + 1
          }
          RangeNameList.push(temp);
        }
      });
    }
    return RangeNameList
  }


  let RangeLabelList = getBatchRange(AllEmpTotal);

  ////console.log('Range: ', RangeLabelList)
  ////console.log('AllEmpTotal: ', AllEmpTotal)

  //  let sizeList = [200,500,1000,2000,3000,5000]

  // let sizeList = [1000]



  let { campaignTypeList, handleCampaignType } = props;



  ////////console.log("employeeValueSearch: ", employeeValueSearch)



  function getLauchTypeLabel(value) {
    let getIndex = campaignRecursiveFreqList.findIndex(prev => prev.value === value);
    let label = ""
    if (getIndex !== -1) {
      label = campaignRecursiveFreqList[getIndex]["label"]
    }
    return label
  }


  function getRECURISIVETypeLabel(value) {
    let getIndex = campaignRecursiveTypeList.findIndex(prev => prev.value === value);
    let label = ""
    if (getIndex !== -1) {
      label = campaignRecursiveTypeList[getIndex]["label"]
    }
    return label
  }

  let isAutoFillShow = false;
  if (getSurveyData && getSurveyData.isRiskSurveyType && getSurveyData.isRiskSurveyType === "DEFAULTEXIT") {
    isAutoFillShow = true
  }

  let LabelList = [
    "Detached", "Low Attached", "Attached", "Highly Attached"
  ]


  let ClusterData = {}
  // let newClusterList = [];


  let newClusterNewList = []



  if (clusterAnalysisData && clusterAnalysisData.length > 0) {
    clusterAnalysisData.forEach((item, indexZ) => {
      //  newClusterList.push({
      //    "clusterName":LabelList[indexZ],
      //    "clusterNumber":item[0],
      //    "clusterSize":item[1],
      //    "clusterCenter":item[2],
      //    "clusterList":item[3]
      //  });

      newClusterNewList.push({
        "id": LabelList[indexZ],
        "name": LabelList[indexZ],
        "clusterName": LabelList[indexZ],
        "clusterNumber": item[0],
        "clusterSize": item[1],
        "clusterCenter": item[2],
        "clusterList": item[3]
      });


    })

    ClusterData = newClusterNewList.filter(prev => prev.name === clusterCrrName)[0]
  }



  function getValueData(itemX, name){
    return itemX && itemX[name]?itemX[name]:"";
  }


  //console.log("EmployeesList------>",EmployeesList)


  return (
    <>

      <main className="w-full ">
        <div className="grid w-full grid-cols-1 gap-6 p-4 overflow-y-auto bg-gray-100 lg:grid-cols-3 lg:gap-10 xl:gap-10 md:p-6 lg:overflow-hidden "   style={{ height: 'calc(100vh - 6.8rem)' }}>
          {/*  */}
          {/* form   style={{ height: 'calc(100vh - 7rem)' }}*/}
          <div className="w-full px-4 space-y-4 bg-white border rounded-md lang md:px-6" >

            <div className='sticky top-0 z-20 flex p-4 bg-white border-b xl:p-6'>
              <div>
                <h2 className="text-xl font-medium bg-white ">Create Campaign </h2>
              </div>
              {isAutoFillShow ?
                <>
                  <div className=''>
                    <div onClick={() => handleExitSurveyAutofill()} className='p-1 px-2 text-white bg-blue-500 rounded-lg cursor-pointer'>Autofill</div>
                  </div>
                </>
                : null}
            </div>


            <div className="space-y-4 " >
              <div className="space-y-1">
                <label className="font-medium text-gray-900 text-md">Campaign Name</label>
                <input type="text" name="title" value={campaignFormData.title} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["campaign_title_" + CurrentLanguage] ? LanguageObj["campaign_title_" + CurrentLanguage] : "Title"} className="w-full p-2 text-sm text-gray-900 border rounded outline-none" />
              </div>



              <div className="space-y-1">
                <label className="font-medium text-gray-900 text-md">Select Sense Survey</label>

                <Dropdown
                  list={senseSurveyList}
                  formData={crrSenseSurvey}
                  selectedItem={handleSelectSenseSurvey}
                />

              </div>



              <div className="space-y-1">
                <label className="font-medium text-gray-900 text-md">Campaign Type</label>


                <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer">
                  <span className="w-full pr-4 text-xs font-semibold text-slate-800" onClick={() => handleshowCampaignTypeDropdown(!showCampaignTypeDropdown)}>{
                    campaignFormData && campaignFormData.campaign_type ? campaignFormData.campaign_type : "Select Type"
                  }</span>
                  <span className="text-gray-500 material-symbols-outlined" onClick={() => handleshowCampaignTypeDropdown(!showCampaignTypeDropdown)}>arrow_drop_down</span>

                  {showCampaignTypeDropdown ?
                    <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
                      {campaignTypeList && campaignTypeList.length > 0 ?
                        campaignTypeList.map((item, index) =>
                          (item !== campaignFormData.campaign_type) ?
                            <span onClick={() => {
                              handleCampaignType(item);
                              handleshowCampaignTypeDropdown(!showCampaignTypeDropdown);
                            }}
                              className={(campaignFormData.campaign_type === item) ? "inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100" :
                                "inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}>
                              {item}</span>
                            : null
                        )
                        : null}
                    </div>
                    : null}
                </div>
              </div>


              <div className="space-y-1">
                <label className="font-medium text-gray-900 text-md">Campaign Mode</label>

                <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer">
                  <span className="w-full pr-4 text-xs font-semibold text-slate-800" onClick={() => handleshowTypeDropdown(!showTypeDropdown)}>{
                    campaignFormData && campaignFormData.type ? campaignFormData.type : "Mode"
                  }</span>
                  <span className="text-gray-500 material-symbols-outlined" onClick={() => handleshowTypeDropdown(!showTypeDropdown)}>arrow_drop_down</span>

                  {showTypeDropdown ?
                    <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
                      {TypeList && TypeList.length > 0 ?
                        TypeList.map((item, index) =>
                          (item !== campaignFormData.type) ?
                            <span onClick={() => {
                              handleType(item);
                              handleshowTypeDropdown(!showTypeDropdown);
                            }}
                              className={(campaignFormData.type === item) ? "inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100" :
                                "inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}>
                              {item}</span>
                            : null
                        )
                        : null}
                    </div>
                    : null}
                </div>
              </div>

              {campaignFormData.type === "EMAIL" ?
                <>
                  <div className="space-y-1">
                    <label className="text-sm text-gray-500 ">Email Subject</label>
                    <input type="text" name="subject" value={campaignFormData.subject} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["subject_" + CurrentLanguage] ? LanguageObj["subject_" + CurrentLanguage] : "Subject"} className="w-full p-2 text-sm text-gray-900 border rounded outline-none" />
                  </div>
                  <div className="space-y-1">
                    <label className="text-sm text-gray-500 ">Email Name</label>
                    <input type="text" name="emailName" value={campaignFormData.emailName} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["emailName_" + CurrentLanguage] ? LanguageObj["emailName_" + CurrentLanguage] : "Name"} className="w-full p-2 text-sm text-gray-900 border rounded outline-none" />
                  </div>

                  <div className="space-y-1 ">
                    <label className="text-sm text-gray-500 ">Email Message</label>
                    {/* <textarea type="text" name="discption" rows={4} className="w-full p-2 px-2 text-sm text-gray-900 border border-b rounded outline-none resize-none" defaultValue={""} /> */}
                    <ReactQuill className="w-12/12 h-28" theme="snow" value={campaignFormData.message} onChange={handleMessageInput} placeholder={"Message"} />
                  </div>


                  <div className="pt-16 space-y-1 xl:pt-10">
                    <label className="text-xs text-gray-500 ">Team Name</label>
                    <input type="text" name="teamname" value={campaignFormData.teamname} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["team_name_" + CurrentLanguage] ? LanguageObj["team_name_" + CurrentLanguage] : "Team"} className="w-full p-2 text-sm text-gray-900 border rounded outline-none" />
                  </div>
                </>
                : null}


              <div className="flex items-end space-x-6">
                <div className="w-full space-y-1">
                  <label className="font-medium text-gray-900 text-md">No. of employees:</label>
                  {campaignFormData.campaign_type === "RECURSIVE" ?
                    <input value={"All"} className="w-full p-2 text-sm text-gray-900 border rounded outline-none" />
                    :
                    <input value={employees && employees.length > 0 ? employees.length : 0} className="w-full p-2 text-sm text-gray-900 border rounded outline-none" />
                  }
                </div>
                <div className="w-40 mb-1.5 whitespace-nowrap">
                  <label className="text-sm text-gray-500 capitalize cursor-pointer">
                    <input
                      id="mandatory-check"
                      value={campaignFormData.isTest}
                      checked={campaignFormData.isTest}
                      type="checkbox"
                      onChange={() => { }}
                      className="mr-2 cursor-pointer"
                      onClick={() => handleTestUserCheck(!campaignFormData.isTest)}
                    />

                    test user</label>
                </div>
              </div>

              {/* <button className="flex items-center justify-center w-full p-2 px-3 text-xs font-medium text-center capitalize rounded text-sky-500">
                <span className="mr-2 material-symbols-outlined">add</span> employee</button> */}
              <div className="flex justify-center pt-2">
                <span className="px-1 text-xs font-medium text-red-500">{campaignValidationList && campaignValidationList.length > 0 ? "Please enter :- " + campaignValidationList.map(element => { return element }).join(", ") : ""}</span>
              </div>

             
            </div>

            <div className="sticky bottom-0 z-20 flex justify-center bg-white border-t -shadow-md">
                <button onClick={() => handleCampaignSubmit()} className="flex items-center justify-center w-full px-6 py-3 text-lg font-medium text-white uppercase bg-blue-500">Launch Campaign
                  <span className="ml-4 transform material-symbols-outlined" style={{ fontSize: '1.5rem' }}>send</span></button>
              </div>
          </div>



          {/* right side img */}


          {/* <div className='items-center justify-center hidden w-full xl:w-7/12 lg:flex' >

            <div className='flex flex-col items-center space-y-20 text-center ' >
              <h1 className='text-3xl font-semibold text-gray-800 capitalize' >launching 365 Days survey's campaign</h1>
              <img src='/img/launch_campaign.png' />

            </div>

          </div> */}



          {crrLaunchType && crrLaunchType.id && (crrLaunchType.id === "A") && (ClusterData && ClusterData.clusterName) ?
          
              <div className="w-full p-4 space-y-4 overflow-y-auto rounded-md md:p-6 lg:col-span-2" style={{ height: 'calc(100vh - 10rem)' }}>
                <h2 className="text-xl ">Cluster Analysis</h2>



                <div className="w-full p-4 space-y-4 overflow-y-auto rounded-md md:p-6">
                  {ClusterData && ClusterData.clusterName ?
                    <>
                      <div className='grid grid-cols-2'>
                        <>
                          <div onClick={() => handleCusterSelect((ClusterData.clusterName), (ClusterData && ClusterData.clusterList && ClusterData.clusterList.length > 0 ? ClusterData.clusterList : []))}
                            className={'flex cursor-pointer  rounded-lg m-2 p-4 h-42 shadow-lg hover:bg-blue-300 hover:text-white hover:shadow-xl' + (clusterCrrName === ClusterData.clusterName ? " bg-blue-500 text-white" : " bg-white")}>
                            <div>
                              <div className='text-md'>{ClusterData && ClusterData.clusterName ? ClusterData.clusterName : "NaN"}</div>
                              <div className='text-xs'>{ClusterData && ClusterData.clusterNumber ? ClusterData.clusterNumber : "NaN"}</div>
                            </div>
                            <div>
                              <div className='px-4 text-4xl text-gray-300 '>{ClusterData && ClusterData.clusterList && ClusterData.clusterList.length > 0 ? ClusterData.clusterList.length : 0}</div>
                            </div>

                          </div>
                        </>
                      </div>
                    </>
                    : null}

                </div>



              </div>


           
            : null}




{campaignFormData.campaign_type === "NORMAL" || campaignFormData.campaign_type === "" ?

 <div className="w-full px-4 mr-4 space-y-4 overflow-y-auto bg-white border rounded-md md:px-6 ">
  <div className="justify-between space-y-4 xl:flex xl:space-y-0">

    <h2 className="xl:text-xl text-lg font-medium text-[#3D405B] -mx-6 py-4 md:px-6 px-4 border-b flex justify-between items-center flex-row gap-4 ">People to Meet List</h2>
    {/* <div className="flex items-center px-2 py-2 space-x-2 text-gray-500 bg-white border rounded-full md:w-auto w-60">
      <span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }}>search </span>
      <input type="text" value={employeeValueSearch} onChange={handleEmployeeValueSearch} placeholder="Search..." className="text-xs focus:outline-none" />
    </div> */}
  </div>

    {EmployeesList && EmployeesList.length > 0 ?
    <>
      <div className="flex flex-col ">
    <div className="overflow-x-auto ">
      <div className="inline-block min-w-full overflow-hidden align-middle">
        <table className="min-w-full whitespace-nowrap">
          <thead>
            <tr className="bg-white">
              <th className="px-4 py-3 text-sm text-left text-gray-800 border-b border-gray-200 text-normal ">
                {/* <input type="checkbox" checked={selectAllEmp} onClick={() => SelectAllEmployees(selectAllEmp, EmployeesList)} name="list-check" className="mr-4" /> */}
                 Name</th>
              <th className="px-6 py-3 text-sm text-left text-gray-800 border-b border-gray-200 text-normal ">
                 Emp ID</th>
              <th className="px-6 py-3 text-sm text-left text-gray-800 border-b border-gray-200 text-normal ">
                 Email</th>
            </tr>
          </thead>
          <tbody className="bg-white">


            {EmployeesList && EmployeesList.length > 0 ?
              EmployeesList.map((item, index) =>
                (index >= indexA && index <= indexB)  && (!item.isHistory)?

                  <tr>
                    <td className="px-4 py-4 border-b border-gray-200">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 ">
                          <input type="checkbox" value={item.id} checked={employees.some(prev => prev.id === getValueData(item, "employeeId")) ? true : false} onClick={() => handleCampaignEmployees({
                            "name": getValueData(item, "name"),
                            "id": getValueData(item, "employeeId")
                          })} onChange={() => { }} />
                        </div>
                        <div className="ml-4">
                          <div className="text-xs leading-5 text-gray-500">{getValueData(item, "name")}</div>
                        </div>
                      </div>
                    </td>

                    <td className="px-6 py-4 border-b border-gray-200">
                      <div className="text-xs leading-5 text-gray-500">{getValueData(item, "emp_id")}</div>
                    </td>

                    <td className="px-6 py-4 border-b border-gray-200">
                      <div className="text-xs leading-5 text-gray-500">{getValueData(item, "email")}</div>
                    </td>

                  </tr>

                  : null
              )
              : null}

          </tbody>
        </table>
      </div>
    </div>


    <div className="flex items-center justify-center p-4 text-center bg-white rounded-b-md ">
      <span onClick={() => prevEmpPage()} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-blue-500 bg-white border rounded-full cursor-pointer select-none material-symbols-outlined hover:bg-gray-400 hover:text-white" style={{ fontSize: '18px', display: 'inline-flex' }}>keyboard_arrow_left</span>
      {IndexListIndex && IndexListIndex.length > 0 ?
        IndexListIndex.map((item, index) =>
          (index >= EmpIndexA && index <= EmpIndexB) ?
            (item === currentEmpPage) ?
              <span onClick={() => SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-blue-500 border rounded-full cursor-pointer">{item}</span>
              :
              <span onClick={() => SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm bg-white border rounded-full cursor-pointer">{item}</span>
            : null
        )

        : null}
      <span onClick={() => nextEmpPage(IndexListIndex)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-blue-500 bg-white border rounded-full cursor-pointer select-none material-symbols-outlined hover:bg-gray-400 hover:text-white" style={{ fontSize: '18px', display: 'inline-flex' }}>keyboard_arrow_right</span>
    </div>


  </div>
    </>:
    <div className='px-24 pt-24 text-2xl font-medium text-center text-gray-400'>Please choose sense survey to get people to meet employee's list</div>}

</div>

: null}

{campaignFormData.campaign_type === "CUSTOMER" ?

 <div className="w-full px-4 mr-4 space-y-4 overflow-y-auto bg-white border rounded-md md:px-6 ">
  <h2 className="xl:text-xl text-lg font-medium text-[#3D405B] -mx-6 py-4 md:px-6 px-4 border-b flex justify-between items-center flex-row gap-4 ">Customer list</h2>



  <div className="flex flex-col ">
    <div className="overflow-x-auto ">
      <div className="inline-block min-w-full overflow-hidden align-middle">
        <table className="min-w-full whitespace-nowrap">
          <thead>
            <tr className="bg-white">
              <th className="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                <input type="checkbox" name="list-check" className="mr-4" />Name</th>
              <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                Email Address</th>
              <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                Mobile No.</th>
              <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center border-b border-gray-200 text-slate-800">
                Status</th>
            </tr>
          </thead>
          <tbody className="bg-white">


            {CustomerList && CustomerList.length > 0 ?
              CustomerList.map((item, index) =>
                (index >= indexA && index <= indexB) ?

                  <tr>
                    <td className="px-4 py-4 border-b border-gray-200">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 ">
                          <input type="checkbox" value={item.id} checked={employees.some(prev => prev.id === item.id) ? true : false} onClick={() => handleCampaignEmployees(item)} onChange={() => { }} />
                        </div>
                        <div className="ml-4">
                          <div className="text-xs leading-5 text-gray-500">{(item && item.name ? item && item.name && item.name.length>15?item.name.slice(0, 15):item.name : 'Not Available')}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 border-b border-gray-200">
                      <div className="text-xs leading-5 text-gray-500">{item && item.email ? item.email : 'Not Available'}</div>
                    </td>
                    <td className="px-6 py-4 text-xs font-medium text-gray-500 border-b border-gray-200">
                      {item && item.mobile ? item.mobile : 'Not Available'}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium leading-5 text-center whitespace-no-wrap border-b border-gray-200">
                      {item.isDisable ?
                        <span className="px-2 py-0.5 inline-flex text-xs  rounded-full bg-red-100 text-green-500">InActive</span>
                        :
                        <span className="px-2 py-0.5 inline-flex text-xs  rounded-full bg-green-100 text-green-500">Active</span>
                      }
                    </td>
                  </tr>

                  : null
              )
              : null}

          </tbody>
        </table>
      </div>
    </div>


    <div className="flex items-center justify-center p-4 text-center bg-white rounded-b-md ">
      <span onClick={() => prevEmpPage()} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-blue-500 bg-white border rounded-full cursor-pointer select-none material-symbols-outlined hover:bg-gray-400 hover:text-white" style={{ fontSize: '18px', display: 'inline-flex' }}>keyboard_arrow_left</span>
      {IndexListIndex && IndexListIndex.length > 0 ?
        IndexListIndex.map((item, index) =>
          (index >= EmpIndexA && index <= EmpIndexB) ?
            (item === currentEmpPage) ?
              <span onClick={() => SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-blue-500 border rounded-full cursor-pointer">{item}</span>
              :
              <span onClick={() => SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm bg-white border rounded-full cursor-pointer">{item}</span>
            : null
        )

        : null}
      <span onClick={() => nextEmpPage(IndexListIndex)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-blue-500 bg-white border rounded-full cursor-pointer select-none material-symbols-outlined hover:bg-gray-400 hover:text-white" style={{ fontSize: '18px', display: 'inline-flex' }}>keyboard_arrow_right</span>
    </div>


  </div>
</div>

: null}

{campaignFormData.campaign_type === "KIOSK" ?

 <div className="w-full px-4 mr-4 space-y-4 overflow-y-auto bg-white border rounded-md md:px-6 ">



</div>

: null}

{campaignFormData.campaign_type === "RECURSIVE" ?

 <div className="w-full px-4 mr-4 space-y-4 overflow-y-auto bg-white border rounded-md md:px-6 ">
  <h2 className="xl:text-xl text-lg font-medium text-[#3D405B] -mx-6 py-4 md:px-6 px-4 border-b flex justify-between items-center flex-row gap-4 ">Recursive Options</h2>
  <div className="grid gap-4 capitalize bg-white rounded md:grid-cols-2 ">

    <div className="space-y-1">
      <label className="font-medium text-gray-900 text-md">Recursive Type</label>
      {/* <div className="flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer"> <span>Select</span> <span className="material-symbols-outlined">arrow_drop_down</span></div> */}

      <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 bg-white border rounded cursor-pointer">
        <span className="w-full pr-4 text-xs font-semibold text-slate-800" onClick={() => handleshowRecursiveTypeDropdown(!showRecursiveTypeDropdown)}>{
          recursiveSettings && recursiveSettings.recursiveType ? getRECURISIVETypeLabel(recursiveSettings.recursiveType) : 'Select Recursive Type'
        }</span>
        <span className="text-gray-500 material-symbols-outlined" onClick={() => handleshowRecursiveTypeDropdown(!showRecursiveTypeDropdown)}>arrow_drop_down</span>

        {showRecursiveTypeDropdown ?
          <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
            {campaignRecursiveTypeList && campaignRecursiveTypeList.length > 0 ?
              campaignRecursiveTypeList.map((item, index) =>
                <span onClick={() => {
                  handleRecursiveType(item.value);
                  handleshowRecursiveTypeDropdown(!showRecursiveTypeDropdown);
                }}
                  className={(recursiveSettings.isLaunchNow === item.value) ? "inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100" :
                    "inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}>
                  {item.label}</span>
              )
              : null}
          </div>
          : null}
      </div>
    </div>

    <div className="space-y-1">
      <label className="font-medium text-gray-900 text-md">Launch Type</label>
      {/* <div className="flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer"> <span>Select</span> <span className="material-symbols-outlined">arrow_drop_down</span></div> */}

      <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 bg-white border rounded cursor-pointer">
        <span className="w-full pr-4 text-xs font-semibold text-slate-800" onClick={() => handleshowCampaignRecursiveDropdown(!showCampaignRecursiveDropdown)}>{
          recursiveSettings && recursiveSettings.isLaunchNow ? getLauchTypeLabel(true) : getLauchTypeLabel(false)
        }</span>
        <span className="text-gray-500 material-symbols-outlined" onClick={() => handleshowCampaignRecursiveDropdown(!showCampaignRecursiveDropdown)}>arrow_drop_down</span>

        {showCampaignRecursiveDropdown ?
          <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
            {campaignRecursiveFreqList && campaignRecursiveFreqList.length > 0 ?
              campaignRecursiveFreqList.map((item, index) =>
                <span onClick={() => {
                  handleCampaignRecursiveType(item.value);
                  handleshowCampaignRecursiveDropdown(!showCampaignRecursiveDropdown);
                }}
                  className={(recursiveSettings.isLaunchNow === item.value) ? "inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100" :
                    "inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}>
                  {item.label}</span>
              )
              : null}
          </div>
          : null}
      </div>
    </div>

    {!recursiveSettings.isLaunchNow ?
      <div className="space-y-1">
        <label className="font-medium text-gray-900 text-md">Launch Date</label>
        <input type="date" name="date" value={recursiveSettings.date} checked={recursiveSettings.isLaunchNow} onChange={handleRecursiveCampaignInput} placeholder={"Survey Date"} className="w-full p-2 text-sm text-gray-900 border rounded outline-none" />
      </div>
      : null}

    <div className="space-y-1">
      <label className="font-medium text-gray-900 text-md">Recursive Frequency</label>
      <input type="number" name="frequency" value={recursiveSettings.frequency} onChange={handleRecursiveCampaignInput} placeholder={"Survey Date"} className="w-full p-2 text-sm text-gray-900 border rounded outline-none" />
    </div>


    {recursiveSettings.recursiveType === "RECURSIVE_EVENT" ?
      <div className="space-y-1">
        <label className="font-medium text-gray-900 text-md">Employee Field Logic</label>
        <input type="text" disabled={true} value={"(currentDate - Employee_startDate) = " + recursiveSettings.frequency} placeholder={"Survey Date"} className="w-full p-2 text-sm text-gray-900 border rounded outline-none" />
      </div>
      : null}


  </div>



  <div className="w-full space-y-4 overflow-y-auto rounded-md">
    <h2 className="text-xl font-medium ">Employees list</h2>
    <div className="justify-between space-y-4 xl:flex xl:space-y-0">
      <div className="flex space-x-2">

        <div className="bg-blue-500 border text-white flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5">
          <span className="w-full pr-4 overflow-hidden text-xs font-semibold text-white cursor-pointer " onClick={() => handleEmpLevelDropdown(showLevelFilter)}>{currFilterLevel}</span>
          <span className="text-white cursor-pointer material-symbols-outlined" onClick={() => handleEmpLevelDropdown(showLevelFilter)}>arrow_drop_down</span>

          {showLevelFilter ?
            <div className="absolute left-0 w-full overflow-y-auto text-gray-500 bg-white rounded-md shadow-xl top-10 h-60 lang" style={{ zIndex: '200' }}>
              <span onClick={() => handleLevelSelect("All")} className={(false) ? "inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100" : "inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}>
                {"All"}</span>
              {EmpFilterData && EmpFilterData.length > 0 ?
                EmpFilterData.map((item, index) =>
                  <span onClick={() => handleLevelSelect(item.name)} className={(currFilterLevel === item.name) ? "cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100" : "cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}>
                    {item.name}</span>
                )
                : null}
            </div>
            : null}


        </div>



        {currFilterLevel !== "Select Filter" ?
          <>
            <div className="bg-white text-gray-500    border  flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5">
              <span style={{ height: "20px" }} className="w-full pr-4 overflow-hidden text-xs font-semibold cursor-pointer text-slate-800"
                onClick={() => handleEmpValueDropdown(showValueFilter)}>{currFilterValue}</span>
              <span className="text-gray-500 cursor-pointer material-symbols-outlined" onClick={() => handleEmpValueDropdown(showValueFilter)}>arrow_drop_down</span>

              {showValueFilter ?
                <div className="absolute left-0 w-full overflow-y-auto text-gray-500 bg-white rounded-md shadow-xl top-10" style={{ height: '400px', zIndex: '200' }}>
                  {EmpValueList && EmpValueList.length > 0 ?
                    EmpValueList.map((item, index) =>
                      (item.name !== "ManagerPID") ?
                        <span onClick={() => handleValueSelect(item)} className={(currFilterValue === item) ? "cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100" : "cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}>
                          {item}</span>
                        : null)
                    : null}
                </div>
                : null}


            </div>
          </>
          : null}



        <div className="bg-white text-gray-500  border  flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5">
          <span className="w-full pr-4 text-xs font-semibold cursor-pointer text-slate-800 " onClick={() => handleBatchRangeDropdown(!showBatchRange)}>

            {formBatchRange && formBatchRange.label ? formBatchRange.label : "Select Batch"}
          </span>
          <span className="text-gray-500 cursor-pointer material-symbols-outlined " onClick={() => handleBatchRangeDropdown(!showBatchRange)}>arrow_drop_down</span>

          {showBatchRange ?
            <div className="absolute left-0 w-full overflow-hidden font-medium text-gray-500 bg-white rounded-md shadow-xl top-10" >
              {RangeLabelList && RangeLabelList.length > 0 ?
                RangeLabelList.map((item, index) =>
                  <span onClick={() => selectBatchRange(item)}
                    className={(formBatchRange.rangeNo === item.rangeNo) ?
                      "cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100 font-medium" :
                      "cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100 font-medium"}>
                    {item.label}</span>
                )
                : null}
            </div>
            : null}


        </div>

        <button onClick={() => handleGetEmployees()} className="p-2 px-4 text-xs font-medium text-white bg-blue-500 rounded-full cursor-pointer">Get Employees</button>



      </div>
      <div className="flex justify-between space-x-4">
        <div className="flex items-center px-2 py-2 space-x-2 text-gray-500 bg-white border rounded-full md:w-auto w-60">
          <span className="material-symbols-outlined" style={{ fontSize: '1.2rem' }}>search </span>
          <input type="text" value={employeeValueSearch} onChange={handleEmployeeValueSearch} placeholder="Search..." className="text-xs focus:outline-none" />
        </div>
      </div>
    </div>
    <div className="flex items-center justify-between">
      <h1 className="text-sm text-gray-500 ">Currently Showing :<b className="font-medium text-slate-800"> {EmployeesList && EmployeesList.length > 0 ? EmployeesList.length : 0}</b></h1>


      <div className='h-8' />

    </div>
    <div className="flex flex-col ">
      <div className="overflow-x-auto ">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full whitespace-nowrap">
            <thead>
              <tr className="bg-white">
                <th className="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                  Name</th>
                <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                  Email Address</th>
                <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                  Mobile No.</th>
                <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center border-b border-gray-200 text-slate-800">
                  Status</th>
              </tr>
            </thead>
            <tbody className="bg-white">


              {EmployeesList && EmployeesList.length > 0 ?
                EmployeesList.map((item, index) =>
                  (index >= indexA && index <= indexB) ?

                    <tr>
                      <td className="px-4 py-4 border-b border-gray-200">
                        <div className="text-xs leading-5 text-gray-500">{(item && item.name ? item.name : 'Not Available').slice(0, 15)}</div>
                      </td>
                      <td className="px-6 py-4 border-b border-gray-200">
                        <div className="text-xs leading-5 text-gray-500">{item && item.email ? item.email : 'Not Available'}</div>
                      </td>
                      <td className="px-6 py-4 text-xs font-medium text-gray-500 border-b border-gray-200">
                        {item && item.mobile ? item.mobile : 'Not Available'}
                      </td>
                      <td className="px-6 py-4 text-sm font-medium leading-5 text-center whitespace-no-wrap border-b border-gray-200">
                        {item.isDisable ?
                          <span className="px-2 py-0.5 inline-flex text-xs  rounded-full bg-red-100 text-green-500">InActive</span>
                          :
                          <span className="px-2 py-0.5 inline-flex text-xs  rounded-full bg-green-100 text-green-500">Active</span>
                        }
                      </td>
                    </tr>

                    : null
                )
                : null}

            </tbody>
          </table>
        </div>
      </div>


      <div className="flex items-center justify-center p-4 text-center bg-white rounded-b-md ">
                  <span
                    onClick={() => prevEmpPage()}
                    className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-blue-500 bg-white border rounded-full cursor-pointer select-none material-symbols-outlined hover:bg-gray-400 hover:text-white"
                    style={{ fontSize: "18px", display: "inline-flex" }}
                  >
                    keyboard_arrow_left
                  </span>
                  {IndexListIndex && IndexListIndex.length > 0
                    ? IndexListIndex.map((item, index) =>
                      index >= EmpIndexA && index <= EmpIndexB ? (
                        item === currentEmpPage ? (
                          <span
                            onClick={() => SelectEmpPage(item)}
                            className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-blue-500 border rounded-full cursor-pointer"
                          >
                            {item}
                          </span>
                        ) : (
                          <span
                            onClick={() => SelectEmpPage(item)}
                            className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm bg-white border rounded-full cursor-pointer"
                          >
                            {item}
                          </span>
                        )
                      ) : null
                    )
                    : null}
                  <span
                    onClick={() => nextEmpPage(IndexListIndex)}
                    className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-blue-500 bg-white border rounded-full cursor-pointer select-none material-symbols-outlined hover:bg-gray-400 hover:text-white"
                    style={{ fontSize: "18px", display: "inline-flex" }}
                  >
                    keyboard_arrow_right
                  </span>
                </div>


    </div>
  </div>


</div>

: null}









        </div>




      </main>
    </>
  );
}
