import { dataTool } from 'echarts';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Actions from '../Actions/Actions';
// import Resource from '../TaskResouce/Resource/Resource';
// import Task from '../TaskResouce/Task/Task';
// import TaskResource from '../TaskResouce/TaskResource';
// import CompleteModal from './CompleteModal';

class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { ParameterList, onSelectDimension, CurrentDimensionId } = this.props;


    // getManagerActionPlanData,currentParameterId
    return (

      <div className="col-span-1  w-full bg-white border p-6 space-y-6 rounded-lg ">
        <h1 className="text-xl font-medium text-[#3D405B]">Recommend Dimensions
          {/* <p className="ml-2">({ParameterList && ParameterList.length>0?ParameterList.length:0})</p> */}
        </h1>
        <div className="overflow-y-auto overflow-hidden customscroll4 h-full space-y-4">


          {ParameterList && ParameterList.length > 0 ?
            ParameterList.map((parameter) =>
              <div onClick={() => onSelectDimension(parameter)} className={
                CurrentDimensionId === parameter.id ?
                  "flex p-4   border  bg-blue-50  rounded-md cursor-pointer items-center " :
                  "flex p-4 bg-white border   rounded-md cursor-pointer items-center  "
              }>
                <div>
                  <h1 className="text-3xl font-light text-white rounded-full h-16 bg-gradient-to-l from-[#62cff4] to-[#2c67f2] w-16  flex items-center justify-center ">{parameter && parameter.score ? parameter.score.toFixed(0) : "0"}</h1>
                </div>
                <div className="pl-3 text-[#3D405B]">
                  <h1 className="text-base font-semibold">{parameter && parameter.name ? parameter.name : ""}</h1>
                  <p className="text-sm text-[#3D405B]/70">{parameter && parameter.desc ? parameter.desc : ""}</p>
                  <p className="text-sm text-[#3D405B]/70 ">Assigned!</p></div>
              </div>
            ) : null}



        </div>
      </div>

    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dimension);
