import React from "react";
import moment from "moment";

export default function Item(props) {
let { CurrentManagerData, getSurveyData  } = props;

  return (
    <>
      <section className=" space-y-2 gap-5 px-10 pt-12">


<header className="bg-white ">
    <div className="flex justify-between capitalize w-12/12 mx-auto py-6 pt-3 space-y-1">
      
      
      <h1 className="text-xl text-white font-bold">
      {getSurveyData && getSurveyData.logo?
        <img src={getSurveyData && getSurveyData.logo?getSurveyData.logo:""}  style={{height:'90px'}}/>
        :null}
      </h1>

      <h1 className="text-xl text-white font-bold">
        <img src={"img/logo/happypluslogo.png"}  style={{height:'60px'}}/>
      </h1>

      
    </div>
  </header>

  
  <main className="text-left mx-auto w-full pb-4 flex space-x-12">
    <div className="w-full">
      <h1 className="text-left text-3xl text-gray-700 font-normal pb-3 ">Action Planning Format</h1>
      <ul className="list-disc px-4 text-gray-600 text-sm leading-loose">
        <li>Did we discuss in detail to arrive at the underlying issues that have
          manifested in the scores we see? </li>
        <li>Did we have participation of all team members, during the
          discussion? Are all/most team members involved in the action plan
          roll-out? </li>
        <li>Are the focus areas and the associated action plans, within the
          control of the team?</li>
        <li>Have we put in realistic timelines? </li>
        <li>Did we qualify the right measures of success for each action plan? Are
          these in line with the business priorities for the team? </li>
      </ul>
      <div className="w-full relative">
        <p className="bg-white p-1 absolute top-4 text-xs  left-1/3  top-20
     px-2 font-medium">Prioritizing Focus Areas</p>
        <table className="border-collapse block mt-8 w-full ">
          <tbody><tr className="bg-blue-500 text-white text-sm">
              <th className="p-2 font-medium border">Issue
                Identified</th>
              <th className="p-2 font-medium border">Action
                To Take</th>
              <th className="p-2 font-medium border">Description
                of Action
              </th>
              <th className="p-2 font-medium border">Success
                Measure</th>
              <th className="p-2 font-medium border">Closure
                By
              </th>
              <th className="p-2 font-medium border">Review
                Status</th>
            </tr>
            <tr>
              <td className="border h-10" />
              <td className="border h-10" />
              <td className="border h-10" />
              <td className="border h-10" />
              <td className="border h-10" />
              <td className="border h-10" />
            </tr>
            <tr>
              <td className="border h-10" />
              <td className="border h-10" />
              <td className="border h-10" />
              <td className="border h-10" />
              <td className="border h-10" />
              <td className="border h-10" />
            </tr>
          </tbody></table>
      </div>
    </div>
    {/* right */}
    <div className="w-full">
      <div className="flex space-x-6">
        <div className="text-sm text-gray-600 flex flex-col  space-y-3">
          <p>High</p>
          <p className="text-blue-500">Impact</p>
          <p>Low</p>
        </div>
        <div className="w-full">
          <div className="grid grid-cols-2 w-full border border-black relative">
            <p className="bg-white p-1 absolute top-4 text-xs left-1/3 top-6 px-2 font-medium">Prioritizing Focus Areas</p>
            <div className="border h-10 bg-yellow-200 border-black" />
            <div className="border h-10 bg-yellow-200 border-black" />
            <div className="border h-10 bg-yellow-200 border-black" />
            <div className="border h-10 bg-yellow-200 border-black" />
          </div>
          <div className="text-sm text-gray-600 flex justify-between py-4"> <p>Low</p> <p className="text-blue-600">Ease of Implementation</p> <p>High</p></div>
        </div>
      </div>
      <p className="text-sm text-gray-800">Take the time to reflect as you assess and prepare your possible actions</p>
      <h2 className="text-blue-400 font-medium text-base">Critical Questions to Answer</h2>
      <div className="grid grid-cols-2 gap-6 pt-4    ">
        <div className="border p-4 rounded-xl">
          <p className="font-medium text-sm pb-3">Does this action plan work?</p>
          <p className="text-sm text-gray-600">Is this action plan implementation in
            the organization? Is it actionable?</p>
        </div>
        <div className="border p-4 rounded-xl">
          <p className="font-medium text-sm pb-3">Can we honestly commit to
            doing this?</p>
          <p className="text-sm text-gray-600">Do we have the requisite time and
            financial and human resources to
            implement it?</p>
        </div>
        <div className="border p-4 rounded-xl">
          <p className="font-medium text-sm pb-3">As the solutions practical?</p>
          <p className="text-sm text-gray-600">Are the solutions grounded in reality?
            Are they feasible?
          </p>
        </div>
        <div className="border p-4 rounded-xl">
          <p className="font-medium text-sm pb-3">Will the solutions move the
            needle on engagement?</p>
          <p className="text-sm text-gray-600">Are the solutions effective? Do they
            impact engagement?</p>
        </div>
      </div>
    </div>
  </main>
</section>


    </>
  );
}







