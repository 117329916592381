import React, { Component } from "react";

function DevelopmentActionPlanConitnue(props) {
  let { userName, createdAt } = props;

  return (
    <section className="p-6 px-6 flex flex-col justify-between page-break">
      {/* report header */}
      <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
        <p className='font-medium italic text-base '>360 Feedback</p>
        <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
      </div>
      <div className="space-y-4 flex flex-col  ">
        <h1 className="text-2xl text-[#212121] font-medium capitalize">
          Development Action Plan
        </h1>

        <div className="text-[#212121] space-y-4 text-sm">
          <p className="italic ">
            This document, when completed, should assist you in analysing your
            360º Assessment feedback along with your strength assessment report,
            and creating a specific action-based development plan. This
            Development Action Plan is a key resource in enabling you to design
            you goals and activities and help you to seamlessly achieve your
            objectives.
          </p>
        </div>

        <span className="text-base font-medium text-sky-500 underline  ">
          Part 3:
        </span>
        <p className="text-base font-medium ">
          PROGRESS REVIEW | Take a pause, check what achieved and what left.{" "}
        </p>

        <table className="w-full border">
          <thead>
            <tr className=" ">
              <th className="w-5/12"></th>
              <th className="w-7/12"></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className=" bg-gray-100 border-r">
                <div className="flex   p-2 px-4  ">
                  <p>
                    <b className="font-semibold  "> ACHIEVEMENT:</b>: What
                    goals/steps I have achieved or made progress
                  </p>
                </div>
              </td>
              <td className="p-2 px-4"></td>
            </tr>
          </tbody>
        </table>

        <table className="w-full border">
          <thead>
            <tr className=" ">
              <th className="w-5/12"></th>
              <th className="w-7/12"></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className=" bg-gray-100 border-r">
                <div className="flex   p-2 px-4  ">
                  <p>
                    <b className="font-semibold  "> MISSES:</b> Which
                    goals/steps/tasks were unable to make enough progress
                  </p>
                </div>
              </td>
              <td className="p-2 px-4"></td>
            </tr>
          </tbody>
        </table>

        <table className="w-full border">
          <thead>
            <tr className=" ">
              <th className="w-5/12"></th>
              <th className="w-7/12"></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className=" bg-gray-100 border-r">
                <div className="flex   p-2 px-4  ">
                  <p>
                    <b className="font-semibold  "> LEARNINGS:</b>What are my
                    key learnings from achievement and misses
                  </p>
                </div>
              </td>
              <td className="p-2 px-4"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}
export default DevelopmentActionPlanConitnue;
