import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

// class QuadrantChart extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//     }
//   }
//   componentDidMount() {

//   }

  export default function QuadrantChart(props) {
    let {  getDemographicIndexData, indexType, selectedItem1, selectedItem2, CountRestrict ,
      clusterAnalysisData, clusterAnalysisCount, handleSelectCluster,
    
    } = props;
 
    // let XAxis= ['Time','Being','Relationship','Worklife','Achievement','Meaning']
    // let XAxisName= ['TimeAvg','BeingAvg','RelationshipAvg','WorkLifeAvg','AchivementAvg','MeeningfullAvg']


    // //console.log('/===================/getDemographicIndexData/===================',getDemographicIndexData)
    // //console.log('/===================/selectedItem1/===================',selectedItem1)
    // //console.log('/===================/selectedItem2/===================',selectedItem2)
    
    let happinessIndex = selectedItem1.index;
    let engagementIndex = selectedItem2.index;


    let happinessCutoff = selectedItem1.cutOff;
    let engagementCutoff = selectedItem2.cutOff;


    let LL = []
    let HL = []
    let LH = []
    let HH = []


    let list = []
    let DepartmentList = []

    if(getDemographicIndexData && getDemographicIndexData.length>0){
      getDemographicIndexData.forEach((item)=>{
        // //////console.log("item=====",item)
        if(!CountRestrict(item.count,false,"other")){

    
        list.push([
          item[happinessIndex],item[engagementIndex]
        ])

        DepartmentList.push({
          name:item.name,
          x:(item[happinessIndex])?(item[happinessIndex]).toFixed(2):item[happinessIndex],
          y:(item[engagementIndex])?(item[engagementIndex]).toFixed(2):item[engagementIndex],
        })




        if(item[happinessIndex]<happinessCutoff && item[engagementIndex]<engagementCutoff){
          LL.push([
            item[happinessIndex],item[engagementIndex]
          ]);
        }
        else if(item[happinessIndex]>=happinessCutoff && item[engagementIndex]<engagementCutoff){
          HL.push([
            item[happinessIndex],item[engagementIndex]
          ]);
        }
        else if(item[happinessIndex]<happinessCutoff && item[engagementIndex]>=engagementCutoff){
          LH.push([
            item[happinessIndex],item[engagementIndex]
          ]);
        }
        else if(item[happinessIndex]>=happinessCutoff && item[engagementIndex]>=engagementCutoff){
          HH.push([
            item[happinessIndex],item[engagementIndex]
          ]);
        } 





      }

      })
    }


    // //console.log('LL--->',LL);
    // //console.log('HL--->',HL);
    // //console.log('LH--->',LH);
    // //console.log('HH--->',HH);


    // //console.log('/===================/DepartmentList/===================',DepartmentList)



    // //console.log('/===================/list/===================',list)


    let newList = []
    if(clusterAnalysisData && clusterAnalysisData.length>0){
      clusterAnalysisData.forEach((item)=>{
        newList.push([item[1],item[2]]);
      })
    }

    // //console.log('/===================/clusterAnalysisData/===================',clusterAnalysisData)
    
    let colorList = ['#e35454','#548be3','#a76af7','#54e371'];

    function getLabel(valueArray){
      let label1 = "";
      if(clusterAnalysisData && clusterAnalysisData.length>0){
        let getIndex1 = clusterAnalysisData.findIndex(prev=>prev[1] === valueArray[0]);
        let getIndex2 = clusterAnalysisData.findIndex(prev=>prev[2] === valueArray[1]);

        // //////console.log("getIndex1========>",getIndex1)
        // //////console.log("getIndex2========>",getIndex2)
        // //////console.log("valueArray========>",valueArray)
        // //////console.log("clusterAnalysisData========>",clusterAnalysisData)
        if(getIndex1!==-1 && getIndex2!==-2){
          label1 = clusterAnalysisData[getIndex1][0];
        }
      }
      // //////console.log("label1--->",label1)
      return {
        "clusterName":label1,
        "clusterSize":valueArray[0],
        "clusterCenter":valueArray[1]
      }
    }


    let LabelList = [
      "Detached","Low Attached","Attached","Highly Attached"
    ]
    let max = 0

    function getClusterLabel (size,name){
      let label2 = "";

      //console.log('/===================/size/===================',size)
      //console.log('/===================/clusterAnalysisData/===================',clusterAnalysisData)
      //console.log('/===================/list/===================',list)

      if(clusterAnalysisData && clusterAnalysisData.length>0){
        

        let getIndex3 = clusterAnalysisData.findIndex(prev=>prev[2] === size);
        //console.log('/===================/getIndex3/===================',getIndex3)

        if(getIndex3!==-1){
          label2 = LabelList && LabelList[getIndex3]?LabelList[getIndex3]:name;
        }
      }
      return label2
    }


    if(clusterAnalysisData && clusterAnalysisData.length>0){
        
      clusterAnalysisData.forEach((item2)=>{
        //console.log('/===================/max/===================',item2)

        if(max < item2[1]){
          max = item2[1];
        }
      })
    }

    //console.log('/===================/max/===================',max)

    return (
      <>
        {/* <div className="px-2 font-semibold text-xl py-4">{"Happiness VS Engagement"}</div>
        <div className="px-2">{"Happiness (x-axis), Engagement(y-axis)"}</div> */}
        <div className="pt-2">


                <ReactEcharts
                    onEvents= {{
                      'click': (params)=>handleSelectCluster(params)
                    }}
                    option={{
                      // title: {
                      //     text: 'Happiness VS Engagement',
                      //     subtext: 'Happiness (x-axis), Engagement(y-axis)'
                      // },
                      grid: {
                          left: '3%',
                          right: '7%',
                          bottom: '7%',
                          containLabel: true
                      },
                      tooltip: {
                        // trigger: 'axis',

                        showDelay: 0,
                        formatter: function (a) {
                          let clusterData = getLabel(a.data);
                          return (
                            '<p><b>Name:</b> '+getClusterLabel((a && a.data && a.data[1]?a.data[1]:0),clusterData.clusterName)+'</p>'+
                            '<p><b>Size:</b> '+clusterData.clusterSize+'</p>'+
                            '<p><b>Centroid:</b> '+clusterData.clusterCenter+'</p>')
                        },
                        axisPointer: {
                            show: true,
                            type: 'cross',
                            lineStyle: {
                                type: 'dashed',
                                width: 1
                            }
                        }
                    },
                      // toolbox: {
                      //     feature: {
                      //         dataZoom: {},
                      //         brush: {
                      //             type: ['rect', 'polygon', 'clear']
                      //         }
                      //     }
                      // },
                      // legend: {
                      //     data: ['Female', 'Male'],
                      //     left: 'center',
                      //     bottom: 10
                      // },
                      xAxis: [
                          {
                              type: 'value',
                              scale: true,
                              min:0,
                              max:max+100,
                              axisLabel: {
                                  formatter: '{value}'
                              },
                              splitLine: {
                                  show: false
                              }
                          }
                      ],
                      yAxis: [
                          {
                              type: 'value',
                              scale: true,
                              min:0,
                              max:10,
                              axisLabel: {
                                  formatter: '{value}'
                              },
                              splitLine: {
                                  show: false
                              }
                          }
                      ],
                      series: [
                          {
                              name: 'LL',
                              type: 'scatter',
                              emphasis: {
                                  focus: 'series'
                              },
                              data: newList,
                              symbolSize:function (a) {
                                return max>1500? parseFloat(a[0]/(max/100)) : parseFloat(a[0]/10)
                              },
                              itemStyle:{

                                color:function (a) {
                                    let color = colorList[a.dataIndex];
                                    return color

                                }
                              },
                          },
                          
                      ]
                  }}
                  />
        </div>
      </>
    );
  }
// }
// function mapStateToProps(state) {
// //   //////console.log("state  ", state);
//   const { loggingIn } = state.authentication;
//   const { users, dashboard } = state;
//   return {
//     loggingIn,
//     users,
//     dashboard
//   };
// }
// export default connect(mapStateToProps)(QuadrantChart);
