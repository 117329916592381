
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const lifecycledashService = {
    logout,
    getClientProfile,
    getSurvey,
    generateCompanySurveyCode,


    getManagerList,

    assignRole,


    getIndex,
    getEmpFilter,
    getIndexFilter,
    getDemographicIndex,

    getHappinessIndexV2,
    getEngagementIndexV2,
    getHopeIndexV2,
    getStressIndexV2,
    getManagerIndexV2,
    getLeaderIndexV2,
    getSeniorleadershipIndexV2,
    getOrgIndexV2,


    getDemographicIndexHappiness,
    getDemographicIndexEngagement,
    getDemographicIndexStress,
    getDemographicIndexManager,
    getDemographicIndexLeader,
    getDemographicIndexOrg,


    getHappinessIndexDriver,
    getEngagementIndexDriver,
    getStressIndexDriver,
    getManagerIndexDriver,
    getLeaderIndexDriver,
    getOrgIndexDriver,




    getQuestion,
    getQuestionOverall,
    getQuestionReport,
    getMultiManagerQuestionList,
    getQuestionFilter,


    getHappinessIndexV2Demographic,
    getEngagementIndexV2Demographic,
    getStressIndexV2Demographic,



    getDemographicIndexForManager,
    getManagerListLifecycledashManagerIndex,

    getAllBenchMark,

    getRespondantLifecycleDetails,

    getManagerListDomainFilter,


    getQuestionManagerOfHappiness,
    getQuestionManagerOfEngagement,
    getQuestionManagerOfStress,
    getQuestionManagerOfManager,

    getIndexQuantile,

    getDemographicIndexScatter,



    getHappinessCorrelation,
    getHappinessCorrelationOverAll,

    getEngagementCorrelation,
    getEngagementCorrelationOverAll,

    getStressCorrelation,
    getStressCorrelationOverAll,

    getManagerCorrelation,
    getManagerCorrelationOverAll,

    getLeaderCorrelation,
    getLeaderCorrelationOverAll,



    
    getRWAnalysis,
    getTextQuestion,
    wordCloud,
    textDist,
    wordCloudText,
    wordCloudMultiText,
    AdvanceWordCloud,
    cooccurmatrix,
    neighborGraph,


    getEngagementRWA,


    cronbatchAlphaVariance,

    getQuestionFavorability,
    getQuestionFavorability2,

    getHistoryIndex,

    getResponseAnalytics,

    getComment,



    getDemographicIndexMultiFilter,
    getDemographicIndexFilterReport,

    AssignManagerActionPlan,
    getManagerActionPlan,
    getEmployeeActionPlan,
    updateEmployeeTask,
    updateEmployeeResource,


    getAllManagerActionPlan,
    crossTab,
    crossTabDMG,
    crossTabAnalysis,
    getCorrelationQuestionType2,
    getAllParameterList,

    getCorrelationDimenionVsDimension,
    getCorrelationDimensionVsDimensionFilter,
    getDemographicOrgReport,




    getHappinessQuestion,
    getEngagementQuestion,
    getHopeQuestion,
    getStressQuestion,
    getManagerQuestion,
    getLeaderQuestion,

    TreeMap,
    textDistTreeMap,




    getManagerProfile,
    getMNGRSurveyList,
    getDemographicIndexMultiFilterEngComp,
    getOverallIndex,


    getDemographicBranchCodeIndexMultiFilter,

    getParameterTypeList,

    createParameter,

    getOrgIndexV2Filter,

    getHappinessIndexV2Filter,
    getEngagementIndexV2Filter,
    getStressIndexV2Filter,

    getHappinessIndexDriverFilter,
    getEngagementIndexDriverFilter,
    getStressIndexDriverFilter,

    getParameterLibraryList,

    clusterAnalysis,
    getClusterEmpInfoList,

    topicModelling,

    createTopic,
    updateTopic,
    deleteTopic,
    getTopicList,
    getAllWordKeywordsText,
    getDemographicIndexEngCompOverall,
    getOverallScoreBySurveyId,
    getAllManagerScores,

    createGoal,
    getGoalList,
    createPlan,
    updateGoal,
    deleteGoal,
    updatePlan,
    deletePlan,
    startPlan,
    closePlan,
    getAllManagerGoalPlan,

    getIDPLastDate,
    editIDPLastDate,

    getCompanyNameOnly,
    managerDownloadStatus,
    getMngrReportDownloadStatus,
    getIndexByFilterAllStages,
    getQuestionMap,

    getDemographicIndexMultiManyFilter,
    getDemographicIndexOrgManyFilter,
    getQuestionDistribution,


    getQuestionCustomTemplate,
    getCategoryOverallIndex,
    getLifecycleListenById,
    getQuestionListBySurveyIDLifecycle,
    getIndexTrendScoreFilter,
    getIndexTrendScore,
    getCategoryById

    
};

function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
}


function handleResponse(response) {
    // //////console.log("response22222   ", response);
 
     return response.text().then(text => {
         const data = text && JSON.parse(text);
         if (!response.ok) {
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logoutSession();
                 //location.reload(true);
             }
             // //////console.log("datadatadata ", response);
 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         ////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             // //////console.log("datadatadatadatadata   ", data);
             if (data.code===3) {
                 logoutSession();
             }
             if(data && data.message && (data.message === "UnauthorizedError")){
                logout()

             }
//              else if (data.code===1) {
//                 logoutSession();
//             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 
 

function handleResponse2(response) {
    // //////console.log("response22222   ", response);
 
     return response.text().then(text => {
         const data = text && JSON.parse(text);
         if (!response.ok) {
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logout();
                 //location.reload(true);
             }
             // //////console.log("datadatadata ", response);
 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         ////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             // //////console.log("datadatadatadatadata   ", data);
             if (data.code===3) {
                 logout();
             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 





function logoutSession(data) {

    let header = new Headers({
            'Content-Type': 'application/json',
            'Authorization': authHeader().Authorization
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/userlogout`, requestOptions)
            .then(handleResponse2)
            .then(user => {
                let userObj = {
                    userLogout: user
                }
                localStorage.removeItem('adminuser');
                window.location.href = "#/login";
                return userObj;
            });
}




//  function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 logout();
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         if (data.error) {
//             if (data.code === 3) {
//                 logout();
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         return data;
//     });
// }

async function getClientProfile() {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getClientProfile`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                ClientProfileData: data.data
            }
            return ProfileObj;
        });
}



async function generateCompanySurveyCode(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/generateCompanySurveyCode`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                generateCompanySurveyCodeData: data.data
            }
            return ProfileObj;
        });
}


async function getSurvey(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }

    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                SurveyData: data.data
            }
            return SurveyObj;
        });
}





async function getEmpFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEmpFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let filterData = []
            if(data && data.data && data.data.length>0){
                data.data.forEach((filter)=>{
                    // if(["ManagerPID","Department","Function","Business"].findIndex(prev=>prev===filter.name)!==-1){
                    //     filterData.push(filter);
                    // }

                    filterData.push(filter);

                });
            }
            let IndexObj = {
                EmpFilterData: filterData
            }

            let getIndex = IndexObj["EmpFilterData"].findIndex(prev=>prev.name==="ManagerPID");
            if(getIndex===-1){
                IndexObj["EmpFilterData"].push({
                    "label":"ManagerPID",
                    "name":"ManagerPID"
                })
            }

            ////console.log('IndexObj: ',IndexObj)
            return IndexObj;
        });
}

async function getManagerList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getManagerList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                ManagerList: data.data
            }
            return IndexObj;
        });
}




async function assignRole(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)+ `/assignRole`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                CompanyEmployeeList: data.data
            }
            return IndexObj;
        });
}



// new api s -------------------------------->


async function getIndex(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndexLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getIndexData: data.data
            }
            return ProfileObj;
        });
}


async function getOverallIndex(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getOverallIndexLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOverallIndexData: data.data
            }
            return ProfileObj;
        });
}



async function getIndexFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndexByFilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getIndexFilterData: data.data
            }
            return ProfileObj;
        });
}

async function getDemographicIndex(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getDemographicIndexLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexData: data.data
            }
            return ProfileObj;
        });
}



async function getQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionData: data.data
            }
            return ProfileObj;
        });
}

async function getQuestionOverall(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionOverallData: data.data
            }
            return ProfileObj;
        });
}

async function getQuestionReport(value, template) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionReportData: {
                    "template":template,
                    "data":data.data
                }             
            }
            return ProfileObj;
        });
}

async function getQuestionFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionFilterData: data.data
            }
            return ProfileObj;
        });
}





async function getHappinessIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getHappinessIndexV2Lifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getHappinessIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getEngagementIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEngamentIndexV2Lifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getEngagementIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getHopeIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHopeIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getHopeIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getStressIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressIndexV2Lifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getStressIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getManagerIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getManagerIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getLeaderIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getLeaderIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getLeaderIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getSeniorleadershipIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getSeniorleadershipIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getSeniorleadershipIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getOrgIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getOrgIndexV2Lifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOrgIndexV2Data: data.data
            }
            return ProfileObj;
        });
}




async function getDemographicIndexHappiness(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexHappinessLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexHappinessData: data.data
            }
            return ProfileObj;
        });
}

async function getDemographicIndexEngagement(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexEngagementLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexEngagementData: data.data
            }
            return ProfileObj;
        });
}

async function getDemographicIndexStress(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexStressLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexStressData: data.data
            }
            return ProfileObj;
        });
}

async function getDemographicIndexManager(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexManagerLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexManagerData: data.data
            }
            return ProfileObj;
        });
}


async function getDemographicIndexLeader(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexLeaderLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexLeaderData: data.data
            }
            return ProfileObj;
        });
}


async function getDemographicIndexOrg(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexOrgLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexOrgData: data.data
            }
            return ProfileObj;
        });
}


async function getDemographicOrgReport(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexOrgLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicOrgReportData: data.data
            }
            return ProfileObj;
        });
}



async function getHappinessIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHappinessIndexDriverLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getHappinessIndexDriverData: data.data
            }
            return ProfileObj;
        });
}

async function getEngagementIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getEngagementIndexDriverLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getEngagementIndexDriverData: data.data
            }
            return ProfileObj;
        });
}

async function getStressIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressIndexDriverLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getStressIndexDriverData: data.data
            }
            return ProfileObj;
        });
}

async function getManagerIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerIndexDriverLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getManagerIndexDriverData: data.data
            }
            return ProfileObj;
        });
}



async function getLeaderIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getLeaderIndexDriverLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getLeaderIndexDriverData: data.data
            }
            return ProfileObj;
        });
}




async function getOrgIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getOrgIndexDriver`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOrgIndexDriverData: data.data
            }
            return ProfileObj;
        });
}



async function getHappinessIndexV2Demographic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHappinessIndexV2DemographicLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHappinessIndexV2DemographicData: data.data
            }
            return IndexObj;
        });
}


async function getEngagementIndexV2Demographic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getEngagementIndexV2DemographicLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementIndexV2DemographicData: data.data
            }
            return IndexObj;
        });
}


async function getStressIndexV2Demographic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressIndexV2DemographicLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getStressIndexV2DemographicData: data.data
            }
            return IndexObj;
        });
}
async function getManagerListLifecycledashManagerIndex(value,mngr) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getIndexByFilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerListLifecycledashManagerIndexData: {
                    "ManagerPID":mngr.id,
                    "ManagerName":mngr.name,
                    "ManagerData":data.data
                }
            }
            
            return IndexObj;
        });
}


async function getMultiManagerQuestionList(value,mngr) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getMultiManagerQuestionListData: {
                    "ManagerPID":mngr.id,
                    "ManagerName":mngr.name,
                    "ManagerData":data.data
                }
            }
            
            return IndexObj;
        });
}



async function getDemographicIndexForManager(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ApiData = data.data;
            ////console.log('ApiData======?',ApiData)
            let PassData = []
            if(ApiData && ApiData.list && ApiData.list.length>0){
                ApiData.list.forEach((item)=>{
                    if(!(item&&item.isTest)){
                        let temp = {
                            ...item
                            // Function: item&&item.Function?item.Function:"",
                            // Business: item&&item.Business?item.Business:"",
                            // Department: item&&item.Department?item.Department:"",
                            // ManagerPID: item&&item.emp_id?parseInt(item.emp_id):"",
                            // id: item&&item.emp_id?parseInt(item.emp_id):"",
                            // emp_id: item&&item.emp_id?item.emp_id:"",
                            // name: item&&item.name?item.name:""
                        }
                        // temp["ManagerPID"] = item&&item.ManagerPID?parseInt(item.ManagerPID):"";
                        temp["id"] = item&&item.emp_id?parseInt(item.emp_id):"";
                        temp["emp_id"] = item&&item.emp_id?parseInt(item.emp_id):"";

                        PassData.push(temp);
                    }
                });
            }
            let IndexObj = {
                getDemographicIndexForManagerData: PassData
            }
            return IndexObj;
        });
}

async function getAllBenchMark(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getAllBenchMark`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getAllBenchMarkData: data.data
            }
            return IndexObj;
        });
}

async function getRespondantLifecycleDetails(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getRespondantDetailsLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getRespondantLifecycleDetailsData: data.data
            }
            return IndexObj;
        });
}

async function getManagerListDomainFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getIndexByFilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerListDomainFilterData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionManagerOfHappiness(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuestionManagerOfHappinessData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionManagerOfEngagement(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuestionManagerOfEngagementData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionManagerOfStress(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuestionManagerOfStressData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionManagerOfManager(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuestionManagerOfManagerData: data.data
            }
            return IndexObj;
        });
}


async function getIndexQuantile(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getIndexQuantileLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getIndexQuantileData: data.data
            }
            return IndexObj;
        });
}




async function getHappinessCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHappinessCorrelation`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHappinessCorrelationData: data.data
            }
            return IndexObj;
        });
}

async function getHappinessCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHappinessCorrelationOverAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHappinessCorrelationData: data.data
            }
            return IndexObj;
        });
}



async function getEngagementCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationDimensionVsDimensionFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementCorrelationData: data.data
            }
            return IndexObj;
        });
}


async function getEngagementCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationDimenionVsDimension`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementCorrelationData: data.data
            }
            return IndexObj;
        });
}



async function getStressCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressCorrelation`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getStressCorrelationData: data.data
            }
            return IndexObj;
        });
}

async function getStressCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressCorrelationOverAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getStressCorrelationData: data.data
            }
            return IndexObj;
        });
}




async function getManagerCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerCorrelation`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerCorrelationData: data.data
            }
            return IndexObj;
        });
}
async function getManagerCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerCorrelationOverAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerCorrelationData: data.data
            }
            return IndexObj;
        });
}


async function getLeaderCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getLeaderCorrelation`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getLeaderCorrelationData: data.data
            }
            return IndexObj;
        });
}

async function getLeaderCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getLeaderCorrelationOverAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getLeaderCorrelationData: data.data
            }
            return IndexObj;
        });
}




async function getDemographicIndexScatter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getDemographicIndexScatterData: data.data
            }
            return IndexObj;
        });
}



async function getRWAnalysis(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getRWAnalysis`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getRWAnalysisData: data.data
            }
            return IndexObj;
        });
}




async function getTextQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getTextQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getTextQuestionData: data.data.data
            }
            return IndexObj;
        });
}


async function wordCloud(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/wordCloudLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                wordCloudData: data.data
            }
            return IndexObj;
        });
}


async function textDist(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/textDistLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                textDistData: data.data
            }
            return IndexObj;
        });
}

async function AdvanceWordCloud(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/AdvanceWordCloud`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                AdvanceWordCloudData: data.data
            }
            return IndexObj;
        });
}


async function wordCloudText(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/wordCloudTextLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                wordCloudTextData: data.data,
                wordTextKeyWord: value.keyWord
            }
            return IndexObj;
        });
}



async function wordCloudMultiText(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/wordCloudMultiTextLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                wordCloudMultiTextData: data.data,
                wordMultiTextKeyWord: value.keyWord1+", "+value.keyWord2
            }
            return IndexObj;
        });
}


async function cooccurmatrix(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/cooccurmatrix`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                cooccurmatrixData: data.data
            }
            return IndexObj;
        });
}



async function neighborGraph(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/neighborGraphLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                neighborGraphData: data.data
            }
            return IndexObj;
        });
}



async function getEngagementRWA(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getEngagementRWALifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementRWAData: data.data
            }
            return IndexObj;
        });
}




async function cronbatchAlphaVariance(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/cronbatchAlphaVariance`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                cronbatchAlphaVarianceData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionFavorability(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionFavorabilityLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            ////console.log('getQuestionFavorabilityData: ',data)
            let IndexObj = {
                getQuestionFavorabilityData: data.data
            }
            return IndexObj;
        });
}



async function getQuestionFavorability2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionFavorabilityLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            ////console.log('getQuestionFavorability2Data: ',data)
            let IndexObj = {
                getQuestionFavorability2Data: data.data
            }
            return IndexObj;
        });
}




async function getHistoryIndex(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHistoryIndex`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHistoryIndexData: data.data
            }
            return IndexObj;
        });
}


async function getResponseAnalytics(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getResponseAnalyticsFilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getResponseAnalyticsData: data.data
            }
            return IndexObj;
        });
}



async function getComment(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getComment`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getCommentData: data.data
            }
            return IndexObj;
        });
}


async function getDemographicIndexMultiFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexMultiFilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getDemographicIndexMultiFilterData: data.data
            }
            return IndexObj;
        });
}


async function getDemographicIndexFilterReport(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexMultiFilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getDemographicIndexFilterReportData: data.data
            }
            return IndexObj;
        });
}


async function getDemographicIndexMultiManyFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexMultiFilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getDemographicIndexMultiManyFilterData: data.data
            }
            return IndexObj;
        });
}



async function getDemographicIndexOrgManyFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexOrgLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getDemographicIndexOrgManyFilterData: data.data
            }
            return IndexObj;
        });
}




async function AssignManagerActionPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/AssignManagerActionPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                AssignManagerActionPlanData: data.data
            }
            return IndexObj;
        });
}


async function getManagerActionPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerActionPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerActionPlanData: data.data
            }
            return IndexObj;
        });
}

async function getEmployeeActionPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getEmployeeActionPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEmployeeActionPlanData: data.data
            }
            return IndexObj;
        });
}



async function updateEmployeeTask(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/updateEmployeeTask`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                updateEmployeeTaskData: data.data
            }
            return IndexObj;
        });
}



async function updateEmployeeResource(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/updateEmployeeResource`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                updateEmployeeResourceData: data.data
            }
            return IndexObj;
        });
}



async function getAllManagerActionPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getAllManagerActionPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getAllManagerActionPlanData: data.data
            }
            return IndexObj;
        });
}


async function crossTab(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/crossTab`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                crossTabData: data.data
            }
            return IndexObj;
        });
}

async function crossTabDMG(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/crossTabDMG`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                crossTabDMGData: data.data
            }
            return IndexObj;
        });
}

async function crossTabAnalysis(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/crossTabAnalysis`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                crossTabAnalysisData: data.data
            }
            return IndexObj;
        });
}



async function getCorrelationQuestionType2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationQuestionType2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getCorrelationQuestionType2Data: data.data
            }
            return IndexObj;
        });
}



async function getAllParameterList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getParameterList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getAllParameterListData: data.data
            }
            return IndexObj;
        });
}


async function getCorrelationDimenionVsDimension(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationDimenionVsDimensionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getCorrelationDimenionVsDimensionData: data.data
            }
            return IndexObj;
        });
}

async function getCorrelationDimensionVsDimensionFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationDimensionVsDimensionFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getCorrelationDimensionVsDimensionFilterData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionDistribution(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionDistributionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuestionDistributionData: data.data
            }
            return IndexObj;
        });
}


async function getHappinessQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHappinessQuestionData: data.data
            }
            return IndexObj;
        });
}



async function getEngagementQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementQuestionData: data.data
            }
            return IndexObj;
        });
}




async function getHopeQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHopeQuestionData: data.data
            }
            return IndexObj;
        });
}



async function getStressQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getStressQuestionData: data.data
            }
            return IndexObj;
        });
}




async function getManagerQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerQuestionData: data.data
            }
            return IndexObj;
        });
}


async function getLeaderQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getLeaderQuestionData: data.data
            }
            return IndexObj;
        });
}



async function TreeMap(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/TreeMapLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                TreeMapData: data.data
            }
            return IndexObj;
        });
}



async function textDistTreeMap(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/textDistTreeMapLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                textDistTreeMapData: data.data
            }
            return IndexObj;
        });
}












async function getManagerProfile() {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getManagerProfile`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                ManagerProfileData: data.data
            }
            return ProfileObj;
        });
}


async function getMNGRSurveyList() {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getMNGRSurveyList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getMNGRSurveyListData: data.data
            }
            return ProfileObj;
        });
}


async function getDemographicIndexMultiFilterEngComp(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getDemographicIndexMultiFilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexMultiFilterEngCompData: data.data
            }
            return ProfileObj;
        });
}


async function getDemographicIndexEngCompOverall(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getDemographicIndexMultiFilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexEngCompOverallData: data.data
            }
            return ProfileObj;
        });
}



async function getDemographicBranchCodeIndexMultiFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getDemographicBranchCodeIndexMultiFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicBranchCodeIndexMultiFilterData: data.data
            }
            return ProfileObj;
        });
}




async function getParameterTypeList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getParameterTypeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getParameterTypeListData: data.data
            }
            return ProfileObj;
        });
}




async function createParameter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createParameter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                createParameterData: data.data
            }
            return ProfileObj;
        });
}



async function getOrgIndexV2Filter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getOrgIndexV2FilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOrgIndexV2FilterData: data.data
            }
            return ProfileObj;
        });
}




async function getHappinessIndexV2Filter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getHappinessIndexV2FilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getHappinessIndexV2FilterData: data.data
            }
            return ProfileObj;
        });
}



async function getEngagementIndexV2Filter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEngagementIndexV2FilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getEngagementIndexV2FilterData: data.data
            }
            return ProfileObj;
        });
}



async function getStressIndexV2Filter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getStressIndexV2FilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getStressIndexV2FilterData: data.data
            }
            return ProfileObj;
        });
}




async function getHappinessIndexDriverFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getHappinessIndexDriverFilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getHappinessIndexDriverFilterData: data.data
            }
            return ProfileObj;
        });
}




async function getEngagementIndexDriverFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEngagementIndexDriverFilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getEngagementIndexDriverFilterData: data.data
            }
            return ProfileObj;
        });
}



async function getStressIndexDriverFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getStressIndexDriverFilterLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getStressIndexDriverFilterData: data.data
            }
            return ProfileObj;
        });
}





async function getParameterLibraryList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getParameterLibraryAllList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getParameterLibraryListData: data.data
            }
            return ProfileObj;
        });
}




async function clusterAnalysis(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/clusterAnalysisLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                clusterAnalysisData: data.data
            }
            return ProfileObj;
        });
}



async function getClusterEmpInfoList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getClusterEmpInfoList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getClusterEmpInfoListData: data.data
            }
            return ProfileObj;
        });
}




async function topicModelling(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/topicModellingLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                topicModellingData: data.data
            }
            return ProfileObj;
        });
}




async function createTopic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createTopic`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                createTopicData: data.data
            }
            return ProfileObj;
        });
}



async function updateTopic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateTopic`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                updateTopicData: data.data
            }
            return ProfileObj;
        });
}



async function deleteTopic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deleteTopic`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                deleteTopicData: data.data
            }
            return ProfileObj;
        });
}



async function getTopicList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getTopicList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getTopicListData: data.data
            }
            return ProfileObj;
        });
}


async function getAllWordKeywordsText(value,topic) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAllWordKeywordsText`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let ProfileObj = {
                getAllWordKeywordsTextData: data.data,
                getAllWordKeywordsTopic: topic

            }
            return ProfileObj;
        });
}




async function getOverallScoreBySurveyId(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getOverallScoreBySurveyIdLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOverallScoreBySurveyIdData: data.data
            }
            return ProfileObj;
        });
}



async function getAllManagerScores(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAllManagerScores`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("getAllManagerScores--->",data.data)
            let ProfileObj = {
                getAllManagerScoresData: data.data
            }
            return ProfileObj;
        });
}


async function createGoal(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createGoal`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("createGoal--->",data.data)
            let ProfileObj = {
                createGoalData: data.data
            }
            return ProfileObj;
        });
}


async function getGoalList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getGoalList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("getGoalList--->",data.data)
            let ProfileObj = {
                getGoalListData: data.data
            }
            return ProfileObj;
        });
}


async function updateGoal(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateGoal`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("updateGoal--->",data.data)
            let ProfileObj = {
                updateGoalData: data.data
            }
            return ProfileObj;
        });
}

async function deleteGoal(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deleteGoal`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("deleteGoal--->",data.data)
            let ProfileObj = {
                deleteGoalData: data.data
            }
            return ProfileObj;
        });
}



async function createPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("createPlan--->",data.data)
            let ProfileObj = {
                createPlanData: data.data
            }
            return ProfileObj;
        });
}




async function updatePlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updatePlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("updatePlan--->",data.data)
            let ProfileObj = {
                updatePlanData: data.data
            }
            return ProfileObj;
        });
}


async function deletePlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deletePlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("deletePlan--->",data.data)
            let ProfileObj = {
                deletePlanData: data.data
            }
            return ProfileObj;
        });
}


async function startPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/startPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("startPlan--->",data.data)
            let ProfileObj = {
                startPlanData: data.data
            }
            return ProfileObj;
        });
}


async function closePlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/closePlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("closePlan--->",data.data)
            let ProfileObj = {
                closePlanData: data.data
            }
            return ProfileObj;
        });
}


async function getAllManagerGoalPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAllManagerGoalPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("getAllManagerGoalPlan--->",data.data)
            let ProfileObj = {
                getAllManagerGoalPlanData: data.data
            }
            return ProfileObj;
        });
}

async function getIDPLastDate(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIDPLastDate`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("getIDPLastDate--->",data.data)
            let ProfileObj = {
                getIDPLastDateData: data.data
            }
            return ProfileObj;
        });
}

async function editIDPLastDate(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/editIDPLastDate`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("editIDPLastDate--->",data.data)
            let ProfileObj = {
                editIDPLastDateData: data.data
            }
            return ProfileObj;
        });
}


async function getCompanyNameOnly(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCompanyNameOnly`, requestOptions)
        .then(handleResponse)
        .then(data => {
            //////console.log("getCompanyNameOnly--->",data.data)
            let ProfileObj = {
                getCompanyNameOnlyData: data.data
            }
            return ProfileObj;
        });
}


async function managerDownloadStatus(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/managerDownloadStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                managerDownloadStatus: data.data
            }
            return ProfileObj;
        });
}


async function getMngrReportDownloadStatus(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getMngrReportDownloadStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getMngrReportDownloadStatusData: data.data
            }
            return ProfileObj;
        });
}


async function getIndexByFilterAllStages(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndexByFilterAllStages`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getIndexByFilterAllStagesData: data.data
            }
            return ProfileObj;
        });
}

async function getQuestionMap(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionMapLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionMapData: data.data
            }
            return ProfileObj;
        });
}


async function getQuestionCustomTemplate(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionCustomTemplate`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionCustomTemplateData: data.data
            }
            return ProfileObj;
        });
}


async function getCategoryOverallIndex(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCategoryOverallIndex`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getCategoryOverallIndexData: data.data
            }
            return ProfileObj;
        });
}

async function getLifecycleListenById(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getLifecycleListenById`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getLifecycleListenByIdData: data.data
            }
            return ProfileObj;
        });
}


async function getQuestionListBySurveyIDLifecycle(data,employeeId) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionListBySurveyIDLifecycle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            var QuesAns=[];
            //////console.log("data data: ",data)
            
            let QuestionMain = [];
            let MatrixQuestion = [];
            let GroupQuestion = [];

            if(data && data.data && data.data.questionList){
                data.data.questionList.forEach((ques)=>{
                    
                    if(ques.templateId===13){
                        if(ques.parentId){
                            MatrixQuestion.push(ques);
                        }
                        else{
                            QuestionMain.push({
                                ...ques,
                                SubQuestionList:[]
                            });
                        }
                    }


                    // else if(ques.templateId === 14){
                    //     QuestionMain.push({
                    //         ...ques,
                    //         SubQuestionList:[]
                    //     });
                    // }


                    else if(ques.templateId !==13 && ques.templateId !==14 ){
                        if(ques.parentId){
                            GroupQuestion.push(ques);
                        }
                        else{
                            QuestionMain.push(ques);
                        }
                    }


                    else{
                        QuestionMain.push(ques);
                    }
                    
                });
            }

            if(QuestionMain && QuestionMain.length>0){
                QuestionMain.map((quesData,quesDataIndex)=>{


                    if(quesData.templateId===2){
                        QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            questionOptionId:""
                        });
                        return QuesAns;
                    }
                    else if(quesData.templateId===13){
                        QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            multiAnswer:[],
                            questionOptionId:"",
                            isMandatory:quesData.isMandatory

                        });
                        return QuesAns;
                    }
                    else{
                        return QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            questionOptionId:"",
                            isMandatory:quesData.isMandatory

                        })
                    }


                    
                })
            }


            //console.log('---->------------>MatrixQuestion',MatrixQuestion)

            if(MatrixQuestion && MatrixQuestion.length>0){
                MatrixQuestion.forEach((ques)=>{
                    let getIndex = QuestionMain.findIndex(prev=>prev.id===ques.parentId);
                    if(getIndex!==-1){
                        QuestionMain[getIndex]["SubQuestionList"].push(ques);

                    }
                });
            }

            let QuestionFinal = []
            if(QuestionMain && QuestionMain.length>0){
                QuestionMain.forEach((Qmain)=>{

                    if(Qmain.templateId===14){
                        QuestionFinal.push(Qmain);

                        if(GroupQuestion && GroupQuestion.length>0){
                            GroupQuestion.forEach((ques,index)=>{
                                if(ques.parentId === Qmain.id){
                                    let temp = ques;
                                    temp["rank"] = Qmain.rank
                                    QuestionFinal.push(temp);
                                }
                            });
                        }


                    }else{
                        QuestionFinal.push(Qmain);
                    }

                });
            }


            // if(GroupQuestion && GroupQuestion.length>0){
            //     GroupQuestion.forEach((ques)=>{
            //         let getIndex = QuestionMain.findIndex(prev=>prev.id===ques.parentId);
            //         if(getIndex!==-1){
            //             QuestionMain[getIndex]["SubQuestionList"].push(ques);
            //         }
            //     });
            // }

            
            //console.log('---->------------>QuestionMain',QuestionMain)

            let questionObj = {
                questionsList: QuestionFinal,
                answersList:QuesAns,
                surveyScreenData :data.data.survey
            }
            //////console.log("questionObj  ", questionObj);

            return questionObj;
        });
}




async function getIndexTrendScoreFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndexTrendScoreFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getIndexTrendScoreFilterData: data.data
            }
            return ProfileObj;
        });
}



async function getIndexTrendScore(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndexTrendScoreFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getIndexTrendScoreData: data.data
            }
            return ProfileObj;
        });
}






async function getCategoryById(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCategoryById`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getCategoryByIdData: data.data
            }
            return ProfileObj;
        });
}
