// import { dataTool } from "echarts";
import React from "react";
export default function CompleteModal(props) {
  let { closeDeleteGoalModal, deleteGoalSubmit } = props;
  return (
    <>
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">

          <div className="shadow w-full rounded-lg bg-white overflow-hidden w-full block">
            <div className="flex justify-between p-4 border-b" >
              <h2 className="font-medium text-lg text-gray-800 capitalize ">Delete Goal</h2>
              <div onClick={() => closeDeleteGoalModal(false)} className=" p-2 rounded-full bg-gray-100 text-gray-500  inline-flex items-center justify-center cursor-pointer" >
                <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                </svg>
              </div>
            </div>

           <div className="p-4 " >
             <div className="flex justify-center py-4">
             <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
</svg>
             </div>
           <h2 className=" text-md text-gray-500  text-center  px-4">Are you sure you want to delete this goal ? Please note all tasks under this goal will also be deleted.</h2>


<div className="mt-8 text-center">
  <button onClick={() => deleteGoalSubmit()} type="button" className="bg-red-500 hover:bg-opacity-90 text-white font-medium py-2 px-6  rounded-md " >
    Delete Goal
  </button>
</div>
           </div>

          </div>
        </div>
      </div>
    </>
  );
}
