import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import Header from "../Header/Header";
// import Sidebar from "./Components/Sidebar/Sidebar";
import { CiFilter } from "react-icons/ci";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { GoGoal } from "react-icons/go";
import { VscFeedback } from "react-icons/vsc";
import { LiaUserCogSolid } from "react-icons/lia";
import { AiOutlineClose } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { TiDocumentText } from "react-icons/ti";
import { TbEdit } from "react-icons/tb";
import { Button } from '@material-ui/core';
import Sidebar from "./Components/Sidebar/Sidebar";


export default function TeammatesPeers(props, history) {

  // console.log('locationlocationlocationlocationlocation', props);

  let { item, name, stage } = props;
  const [isAddGoal, setIsAddGoal] = useState(false);
  const [isAddTask, setIsAddTask] = useState(false);
  // State to track the active tab
  const [activeTab, setActiveTab] = useState('goals');
  const [activeTabOne, setActiveTabOne] = useState('Review');

  const handleTabClick = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTab(tab);
  };

  const handleTabClickTop = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTabOne(tab);
  };


  const handleAddGoal = () => {
    setIsAddGoal(!isAddGoal);
  };
  const handleCloseAddGoal = () => {
    setIsAddGoal(false);
  };

  const handleAddTask = () => {
    setIsAddTask(!isAddTask);
  };
  const handleCloseAddTask = () => {
    setIsAddTask(false);
  };


  return (
    <>
      <div className="h-screen overflow-hidden bg-gray-50">
        
        <Header />



        <div className="flex w-full" style={{ height: "calc(100% - 2rem)" }}>
          <Sidebar />


          <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden">


            <div className="bg-white ">
              <div className="px-6 py-4 h-[64px] border flex justify-between">
                <div className="flex items-center space-x-4 text-[#212121]">
                  <button className="p-2 duration-200 rounded-md cursor-pointer hover:bg-slate-100">
                    <MdOutlineArrowBackIos className="w-5 h-5" /> </button>
                  <div className="text-2xl font-semibold">
                    <b className="font-medium">Appraisal 2023</b>
                  </div>
                  <div>
                    <p>Submission End Date: 15-Jul-2023</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="bg-white">
              <div className="flex justify-between px-6 py-4">
                <ul className="flex w-full space-x-4 border-b">
                  <li>
                    <div className={`  block px-4 py-2 rounded-t-md border font-medium ${activeTabOne === 'Review' ? ' bg-[#009EA7] text-white ' : 'text-[#212121]/70 bg-slate-100'}`} onClick={() => handleTabClickTop('Review')}>
                      <div className="flex items-center justify-center gap-2 cursor-pointer">
                        <GoGoal className="w-5 h-5" />
                        <span className="text-sm">My Review</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`  block px-4 py-2 rounded-t-md border font-medium ${activeTabOne === 'Peers' ? ' bg-[#009EA7] text-white ' : 'text-[#212121]/70 bg-slate-100'}`} onClick={() => handleTabClickTop('Peers')}>
                      <div className="flex items-center justify-center gap-2 cursor-pointer">
                        <VscFeedback className="w-5 h-5" />
                        <span className="text-sm">My Teammates</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {
              activeTabOne === 'Review' &&
              <>
                <div className="bg-white border-b">
                  <div className="px-6  h-[64px]   flex justify-between">
                    <ul className="flex">
                      <li>
                        <div className={`  block p-4 ${activeTab === 'goals' ? 'font-medium text-[#009EA7]' : 'text-[#212121]/70'}`} onClick={() => handleTabClick('goals')}>
                          <span className={`absolute inset-x-0 w-full bg-[#009EA7] h-[3px] -bottom-px ${activeTab === 'goals' ? '' : 'hidden'}`} />
                          <div className="flex items-center justify-center gap-4 cursor-pointer">
                            <GoGoal className="w-5 h-5" />
                            <span className="text-sm">My Peers</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className={`  block p-4 ${activeTab === 'feedback' ? 'font-medium text-[#009EA7]' : 'text-[#212121]/70'}`} onClick={() => handleTabClick('feedback')}>
                          <span className={`absolute inset-x-0 w-full bg-[#009EA7] h-[3px] -bottom-px ${activeTab === 'feedback' ? '' : 'hidden'}`} />
                          <div className="flex items-center justify-center gap-4 cursor-pointer">
                            <VscFeedback className="w-5 h-5" />
                            <span className="text-sm">My Employees</span>
                          </div>
                        </div>
                      </li>

                    </ul>
                  </div>
                </div>


                {activeTab === 'goals' &&
                  <>
                    <div className="md:p-6 p-4 mx-auto overflow-hidden overflow-y-auto space-y-4 h-[calc(100vh-12rem)] ">
                      <h1 className='text-xl font-semibold text-[#3D405B]'>My Peers</h1>
                      <div className="space-y-2 ">
                        <div className="flex items-center px-6 py-2 bg-white border rounded-sm"><a href="#/app/myreviewdetails"><div className="flex items-center"><img src="img/newimg/Rectangle 11116.png" alt="" className="rounded-full" /><div className="ml-4">
                          <span className="capitalize text-lg font-medium block text-[#3D405B]">Hari KC</span></div></div></a></div>
                        <div className="flex items-center px-6 py-2 bg-white border rounded-sm"><a href="#/app/myreviewdetails"><div className="flex items-center"><img src="img/newimg/Rectangle 11116.png" alt="" className="rounded-full" /><div className="ml-4">
                          <span className="capitalize text-lg font-medium block text-[#3D405B]">Hari KC</span></div></div></a></div>
                        <div className="flex items-center px-6 py-2 bg-white border rounded-sm"><a href="#/app/myreviewdetails"><div className="flex items-center"><img src="img/newimg/Rectangle 11116.png" alt="" className="rounded-full" /><div className="ml-4">
                          <span className="capitalize text-lg font-medium block text-[#3D405B]">Hari KC</span></div></div></a></div>
                        <div className="flex items-center px-6 py-2 bg-white border rounded-sm"><a href="#/app/myreviewdetails"><div className="flex items-center"><img src="img/newimg/Rectangle 11116.png" alt="" className="rounded-full" /><div className="ml-4">
                          <span className="capitalize text-lg font-medium block text-[#3D405B]">Hari KC</span></div></div></a></div>

                      </div>

                    </div>

                  </>
                }

                {activeTab === 'feedback' &&
                  <>
                    <div className="md:p-6 p-4 mx-auto overflow-hidden overflow-y-auto space-y-4 h-[calc(100vh-12rem)] ">
                      <div className="flex items-center justify-between">
                        <h1 className='text-xl font-semibold text-[#3D405B]'>My Employees</h1>
                      </div>

                      <div className="overflow-hidden border border-gray-200 rounded-lg ">
                        <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse">
                          <thead className="bg-white">
                            <tr>
                              <th scope="col" className="px-6 py-3 font-semibold text-base text-[#3D405B] w-[40%]">Employees</th>
                              <th scope="col" className="px-6 py-3 font-semibold text-base text-[#3D405B] text-center">360 feedback</th>
                              <th scope="col" className="px-6 py-3 font-semibold text-base text-[#3D405B] text-center">Self appraisal</th>
                              <th scope="col" className="px-6 py-3 font-semibold text-base text-[#3D405B] text-center">Review status</th>
                              <th scope="col" className="px-6 py-3 font-semibold text-base text-[#3D405B] " >Rating</th>
                            </tr>
                          </thead>
                          <tbody className="border-t border-gray-100 divide-y divide-gray-100">
                            <tr className="bg-white ">
                              <td className="px-6 py-4 w-[40%]">
                                <div className="flex items-center"><img src="img/newimg/Rectangle 11116.png" alt="" className="rounded-full w-14 h-14" /><div className="ml-4"><span className="capitalize text-lg font-medium block text-[#3D405B]">Hari KC</span></div></div>

                              </td>
                              <td className="px-6 py-4">  <div class=" flex justify-center">
                                <button type="button" class="uppercase font-medium text-sm text-green-500 bg-green-500/10 w-32 py-2 px-2 rounded focus:outline-none focus:shadow-outline">Completed</button>
                              </div></td>
                              <td className="px-6 py-4">
                                <div class=" flex justify-center">
                                  <button type="button" class="uppercase font-medium text-sm text-[#FFA000] bg-[#FFA000]/10 w-32 py-2 px-2 rounded focus:outline-none focus:shadow-outline">Pending</button>
                                </div>
                              </td>
                              <td className="px-6 py-4">
                                <div className="flex items-center justify-center">
                                  <div className='relative '>
                                    <img src="img/newimg/Rectangle 11116.png" alt="" className="rounded-full w-14 h-14" />
                                    <span className='absolute bottom-0 right-0 flex items-center justify-center w-6 h-6 text-white rounded-full bg-amber-500 '>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clock-hour-4" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                        <path d="M12 12l3 2"></path>
                                        <path d="M12 7v5"></path>
                                      </svg>
                                    </span>
                                  </div>

                                </div>
                              </td>
                              <td className="px-6 py-4 text-[#3D405B] font-medium text-base">To be provide</td>

                            </tr>
                            <tr className="bg-white ">
                              <td className="px-6 py-4 w-[40%]">

                                <div className="flex items-center"><img src="img/newimg/Rectangle 11116.png" alt="" className="rounded-full w-14 h-14" /><div className="ml-4"><span className="capitalize text-lg font-medium block text-[#3D405B]">Hari KC</span></div></div>

                              </td>
                              <td className="px-6 py-4">   <div class=" flex justify-center">
                                <button type="button" class="uppercase font-medium text-sm text-green-500 bg-green-500/10 w-32 py-2 px-2 rounded focus:outline-none focus:shadow-outline">Completed</button>
                              </div></td>
                              <td className="px-6 py-4">
                                <div class=" flex justify-center">
                                  <button type="button" class="uppercase font-medium text-sm text-[#FFA000] bg-[#FFA000]/10 w-32 py-2 px-2 rounded focus:outline-none focus:shadow-outline">Pending</button>
                                </div>
                              </td>
                              <td className="px-6 py-4">
                                <div className="flex items-center justify-center">
                                  <div className='relative '>
                                    <img src="img/newimg/Rectangle 11116.png" alt="" className="rounded-full w-14 h-14" />
                                    <span className='absolute bottom-0 right-0 flex items-center justify-center w-6 h-6 text-white bg-green-500 rounded-full '>
                                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M5 12l5 5l10 -10"></path>
                                      </svg>
                                    </span>
                                  </div>

                                </div>
                              </td>
                              <td className="px-6 py-4 text-[#3D405B] font-medium text-base">Good</td>

                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>

                  </>
                }

              </>
            }

            {
              activeTabOne === 'Peers' &&
              <>
                <div className="bg-white ">
                  <div className="px-6 py-4 h-[64px] border flex justify-between">
                    <div className="flex items-center space-x-4 text-[#212121]">
                      <div className="text-2xl font-semibold">
                        <b className="pl-4 font-medium">My Peers</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='space-y-2 md:m-6'>
                  <div class="bg-white shadow flex p-5 items-center rounded-lg">
                    <Link to='/app/myreviewdetails'>
                      <div class="flex items-center">
                        <img src='img/newimg/Rectangle 11116.png' alt='' class="rounded-full" />
                        <div class="ml-4">
                          <span class="capitalize text-xl font-medium block text-gray-800">Hari KC</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div class="bg-white shadow flex p-5 items-center rounded-lg">
                    <Link to='/app/myreviewdetails'>
                      <div class="flex items-center">
                        <img src='img/newimg/Rectangle 11116.png' alt='' class="rounded-full" />
                        <div class="ml-4">
                          <span class="capitalize text-xl font-medium block text-gray-800">Hari KC</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div class="bg-white shadow flex p-5 items-center rounded-lg">
                    <div class="flex items-center">
                      <img src='img/newimg/Rectangle 11116.png' alt='' class="rounded-full" />
                      <div class="ml-4">
                        <span class="capitalize text-xl font-medium block text-gray-800">Hari KC</span>
                      </div>
                    </div>
                  </div>
                  <div class="bg-white shadow flex p-5 items-center rounded-lg">
                    <div class="flex items-center">
                      <img src='img/newimg/Rectangle 11116.png' alt='' class="rounded-full" />
                      <div class="ml-4">
                        <span class="capitalize text-xl font-medium block text-gray-800">Hari KC</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }


          </main >
        </div >
      </div >

      {isAddGoal && (
        <div className="fixed inset-0 z-40 flex items-center justify-center w-full h-full mt-0 overflow-hidden overflow-y-auto bg-black/40">
          <div className="bg-[#fff] model mx-auto rounded-lg shadow-lg lg:w-2/6 w-full overflow-y-auto">

            <div className="flex items-center justify-between p-6 py-3 border-b bg-dark">
              <p className="font-medium text-black">Add Goal</p>
              <div className="rounded-lg cursor-pointer modal-close bg-[#F1F1F1] p-1" onClick={handleCloseAddGoal}>
                <AiOutlineClose className='w-6 h-6 text-gray-600' />
              </div>
            </div>

            <div className="p-4 space-y-4">
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Goal Name</lable>
                <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Start Date</lable>
                <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="DD-MM-YYYY" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">End Date</lable>
                <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="DD-MM-YYYY" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Priority</lable>
                <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Description</lable>
                <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Progress % (A goal is complete when progress is 100%)</lable>
                <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='space-y-1'>
                <lable className="text-sm text-[#212121]/70">Weightage %</lable>
                <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="chips" />
              </div>
              <div className='pb-4'>
                <div className="text-center text-white w-full px-3 py-2 text-sm uppercase transition-all duration-150 ease-linear bg-[#009EA7] border focus:outline-none focus:ring">Submit</div>
              </div>

            </div>

          </div>
        </div>
      )
      }

      {
        isAddTask && (
          <div className="fixed inset-0 z-40 flex items-center justify-center w-full h-full mt-0 overflow-hidden overflow-y-auto bg-black/40">
            <div className="bg-[#fff] model mx-auto rounded-lg shadow-lg lg:w-2/6 w-full overflow-y-auto">

              <div className="flex items-center justify-between p-6 py-3 border-b bg-dark">
                <p className="font-medium text-black">Add Goal</p>
                <div className="rounded-lg cursor-pointer modal-close bg-[#F1F1F1] p-1" onClick={handleCloseAddTask}>
                  <AiOutlineClose className='w-6 h-6 text-gray-600' />
                </div>
              </div>

              <div className="p-4 space-y-4">
                <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">Task Name</lable>
                  <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="Enter" />
                </div>
                <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">Start Date</lable>
                  <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="DD-MM-YYYY" />
                </div>
                <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">End Date</lable>
                  <input className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="DD-MM-YYYY" />
                </div>
                <div className='space-y-1'>
                  <lable className="text-sm text-[#212121]/70">Description</lable>
                  <textarea row={5} className="w-full px-3 py-2 text-sm transition-all duration-150 ease-linear bg-white border focus:outline-none focus:ring" placeholder="Write here" />
                </div>
                <div className='pb-4'>
                  <div className="text-center text-white w-full px-3 py-2 text-sm uppercase transition-all duration-150 ease-linear bg-[#009EA7] border focus:outline-none focus:ring">Submit</div>
                </div>
              </div>

            </div>
          </div>
        )
      }
    </>
  );
}
