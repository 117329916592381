import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { user } from './user.reducer';
import { dashboard } from './dashboard.reducer';
import { survey } from './survey.reducer';
import { questions } from './questions.reducer';
import { campaign } from './campaign.reducer';
import { general } from './general.reducer';
import { customer } from './customer.reducer';
import { riskdashtype2 } from './riskdashtype2.reducer';
import { happiness } from './happiness.reducer';
import { lifecycledash } from './lifecycledash.reducer';
import { grow } from './grow.reducer';
import { pms } from './pms.reducer';

const rootReducer = combineReducers({
  authentication,
  user,
  dashboard,
  survey,
  questions,
  campaign,
  general,
  customer,
  riskdashtype2,
  happiness,
  lifecycledash,
  grow,
  pms

});

export default rootReducer;
