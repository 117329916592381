import * as React from 'react';

const PageTemplate = props => {

  let Number1 = props && props.pageNum ? props.pageNum : 0;
  let SurveyLogo = props && props.surveyLogo ? props.surveyLogo : "/img/logo/happypluslogo.png";

  let pageNo = 0
  if (Number1 > 2) {
    pageNo = Number1 - 2
  }
  return (<>
    <div style={{
      position: "absolute",
      bottom: "10px",
      left: '20px'
    }}>

      <div className='py-4 flex justify-between items-center' style={{ justifyContent: 'space-between' }}>
        <img style={{ marginLeft: '20px' }} src={SurveyLogo} className=" w-32 object-cover" alt="default_survey_logo" />
      </div>


    </div>

    <div style={{
      position: "absolute",
      bottom: "10px",
      right: '20px'
    }}>
      <div className='py-4 flex justify-between items-center' style={{ justifyContent: 'space-between' }}>
        {/* <p className='text-slate-600'>Page {pageNo}</p> */}
        <img src={"/img/logo/happypluslogo.png"} className=" w-24 object-cover mx-4" alt="default_survey_logo" />
      </div>

    </div>


  </>);
};

export default PageTemplate;