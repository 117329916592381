import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dashboardActions, userActions } from '../../../_actions';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileBox: false,

      showDropdown: false
    };
    this.dropdownRef = React.createRef();
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getClientProfile());
    document.addEventListener('mousedown', this.handleClickOutside);
  }


  openProfile = () => {
    this.setState(prevState => ({ showProfileBox: !prevState.showProfileBox }));
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ showProfileBox: false });
    }
  }

  openProfile = (check) => {
    this.setState({ showProfileBox: !check })

    setTimeout(() => {
      this.setState({ showProfileBox: false })
    }, 4000)
  }
  handleLogout = () => {
    this.props.dispatch(userActions.logoutSession());
  }


  render() {
    let { dashboard, CurrentLanguageName, getLanguageData, handleLanguage, showLanguageModal, openLanguageModal } = this.props;
    let { showProfileBox } = this.state;
    let { ClientProfileData } = dashboard;
    //console.log('ClientProfileData:', ClientProfileData)

    let ProfileName = '';

    let ProfileGender = ''
    if (ClientProfileData && ClientProfileData.name) {
      let nameGender = "";
      if (ClientProfileData && ClientProfileData.Gender) {
        nameGender = ClientProfileData.Gender;
      }
      else if (ClientProfileData && ClientProfileData.gender) {
        nameGender = ClientProfileData.gender;
      }

      ProfileGender = nameGender;
      ProfileName = ClientProfileData.name;

    }

    let { companyName } = this.props;
    ////console.log("companyName-ID--------->",companyName)
    return (
      <>
        <header className=" w-full flex justify-between items-center  md:px-6 px-4 bg-white  border-b " style={{ height: '4rem' }}>
          <a href="#/app/overview" className='flex itmes-center  space-x-4'>
            <button class="text-blue-500 bg-[#edf6fe] rounded-full cursor-pointer hover:bg-blue-100 trasition duration-150 p-2"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-left  " width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2196f3" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="5" y1="12" x2="19" y2="12"></line><line x1="5" y1="12" x2="11" y2="18"></line><line x1="5" y1="12" x2="11" y2="6"></line></svg></button> 
            <div className='flex items-center'>
              <img src='/img/plus_ex_logo.svg' className='w-24 object-cover flex-shrink-0' />
            </div>

          </a>

          <div className="flex items-center justify-between py-3">
            {/* <div className="lg:flex hidden border-r pr-3 mx-2 items-center ">

              <h1 onClick={() => { openLanguageModal(showLanguageModal); this.openProfile(!false); }}
                className="text-sm cursor-pointer">{CurrentLanguageName}
              </h1>
              <span onClick={() => { openLanguageModal(showLanguageModal); this.openProfile(!false); }}
                className="cursor-pointer material-symbols-outlined">arrow_drop_down</span>

              {showLanguageModal ?
                <>
                  <div className="absolute top-16 z-10 right-52 bg-white shadow-xl w-auro  py-1.5  lang" style={{ maxHeight: "150px", overflowY: 'auto' }}>
                  {getLanguageData && getLanguageData.length > 0 ?
                    getLanguageData.map((item) =>
                      <span onClick={() => handleLanguage(item)} className="cursor-pointer block text-xs w-full hover:bg-gray-100 py-1.5 px-4">{item.name}</span>
                    )
                    : null}
                </div>


                  <div className="absolute top-12 right-30 bg-white shadow-2xl w-32 z-10  overflow-hidden  lang text-xs rounded-lg ">

                    {CurrentLanguageName !== "English" ?
                      <>
                        <span onClick={() => handleLanguage({
                          "shortName": "en",
                          "name": "English"
                        })} className="cursor-pointer block  w-full hover:bg-blue-50 py-2 px-4 flex items-center">
                          <img alt='language' src={"https://cdn-icons-png.flaticon.com/512/330/330459.png"}
                            className="w-4 mr-2" />
                          {"English"}
                        </span>
                      </>
                      : null}




                    {getLanguageData && getLanguageData.length > 0 ?
                      getLanguageData.map((item) =>
                        (CurrentLanguageName !== item.name) ?
                          <>
                            <span onClick={() => handleLanguage(item)} className="cursor-pointer block  w-full hover:bg-blue-50 py-2 px-4 flex items-center">
                              <img
                                alt="language"
                                src={
                                  item.name === "English" ? "https://cdn-icons-png.flaticon.com/512/330/330459.png" :
                                    item.name === "Chinese" ? "https://cdn-icons-png.flaticon.com/512/330/330651.png" :
                                      item.name === "Hindi" ? "https://cdn-icons-png.flaticon.com/512/330/330439.png" :
                                        item.name === "Japanise" ? "https://cdn-icons-png.flaticon.com/512/330/330622.png" :
                                          item.name === "French" ? "https://cdn-icons-png.flaticon.com/512/330/330490.png" :
                                            item.name === "Malayalam" ? "https://cdn-icons-png.flaticon.com/512/330/330439.png" :

                                              "https://cdn-icons-png.flaticon.com/512/330/330534.png"

                                } className="w-4 mr-2" />
                              {item.name}</span>
                          </>
                          : null
                      )
                      : null}


                  </div>


                </>
                : null}

            </div> */}


            <div ref={this.dropdownRef} className="relative flex items-center ">
              <button
                onClick={this.openProfile}
                className="relative block h-10 w-10 ml-2 rounded-full overflow-hidden  focus:outline-none bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] "
              >
                <span className="text-sm font-semibold text-white tracking-widest">
                  {ProfileName ? (ProfileName.toUpperCase()).slice(0, 2) : ''}
                </span>
              </button>
              <div
                onClick={this.openProfile}
                className="ml-2 lg:flex items-center hidden cursor-pointer relative"
              >
                <h1 className="text-base text-slate-800 font-medium select-none">
                  {ProfileName}
                </h1>
                <span
                  className={
                    showProfileBox
                      ? "material-symbols-outlined text-slate-600 text-[1.8rem] rotate-180"
                      : "material-symbols-outlined text-slate-600 text-[1.8rem]"
                  }
                >
                  arrow_drop_down
                </span>

                {showProfileBox && (
                  <>
                    <div className="bg-white rounded-xl border shadow-2xl   py-6 absolute top-10 right-0 w-60  space-y-2  ">
                      <div className="flex items-center space-x-2 hover:bg-blue-100 transition-all duration-200 px-6 py-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-user "
                          width="26"
                          height="26"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="#2c3e50"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <circle cx="12" cy="7" r="4" />
                          <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                        </svg>
                        <p
                          onClick={() => this.openProfile(showProfileBox)}
                          className="text-base truncate font-medium"
                        >
                          {ProfileName}
                        </p>
                      </div>
                      <div
                        onClick={() => this.handleLogout()}
                        className="flex items-center space-x-2 hover:bg-blue-100 transition-all duration-200 px-6 py-2 text-red-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-logout"
                          width="26"
                          height="26"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="#e24a4f"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                          <path d="M7 12h14l-3 -3m0 6l3 -3" />
                        </svg>
                        <span> Logout</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { user, dashboard } = state;
  return {
    loggingIn,
    user,
    dashboard
  };
}
export default connect(mapStateToProps)(Header);