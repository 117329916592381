import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class QuestionStack extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { question  } = this.props;
    
    //console.log('question: ',question)


    let total = 0
    let names = []
    let weightage1 = [];
    let weightage2 = [];
    let weightage3 = [];
    let weightage4 = [];
    let weightage5 = [];
    let weightage6 = [];

    let AnswerList = [];
    if(question){
      names.push({
        value:"Overall",
        textStyle:{fontWeight:'bold'}
    })


        let AnswersOptions = [];
        if(question && question.answers.length>0){

          question.answers.forEach((item)=>{
            AnswersOptions.push(item);
          });

          AnswersOptions.sort(function (a, b) {
            return ('' + a.name).localeCompare(b.name);
          });

          AnswersOptions.forEach((item)=>{
            let name = item.name;
            let score = (typeof item.score !== 'string' || item.score instanceof Number) && item && item.score?item.score.toFixed(2):"0";
            // //console.log('question score : ',score)

            let OptionInput =  {
              name: '',
              type: 'bar',
              stack: '总量',
              barWidth:'30px',

              label: {
                  show: true,
                  position: 'insideRight',
                  formatter: function(d) {
                      if(parseInt(d.value) <7){
                          return '';
                      }
                      else{
                          return d.value;
                      }
                    }
              },
              data: [],
              inverse:true
          }

            OptionInput["name"] = name;
            OptionInput["data"].push(score);

            AnswerList.push(OptionInput);

          })
        } 

    }


    // //console.log('AnswerList: ',AnswerList)

                        

    return (
      <>
        <div style={{zIndex:'10'}}>

              {true?
                            <ReactEcharts
                            style={{height:'37px',marginTop:'6px',width:'100%'}}
                            option={{
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {            
                                    type: 'shadow'        
                                }
                            },
                            // grid: {
                            //     left: '3%',
                            //     right: '4%',
                            //     bottom: '3%',
                            //     containLabel: true
                            // },
                            xAxis: {
                                type: 'value',
                                axisLine:'left',
                                splitLine: {
                                    lineStyle: {
                                        color: '#fff'
                                    }
                                },
                                axisLabel:{
                                    show:false
                                },
                                max:100


                            },
                            yAxis: {
                                type: 'category',
                                data: names,
                                position:'left',
                                inverse:true,
                                axisLine: {
                                    lineStyle: {
                                      color:'#fff'
                                    }
                                  },
                                axisLabel:{
                                    show:false
                                }
                            },
                            axisLabel: {
                                color: "#000",
                                interval: 0,
                                // formatter: function(value) {
                                //   if (value.length > 20) {
                                //     return value.substring(0, 20) + ".. :";
                                //   } else {
                                //     return value + ":";
                                //   }
                                // }
                              },
                            series: AnswerList
                        }}
                          />
              :null}

        </div>
                            
      </>
    );
  }
}
function mapStateToProps(state) {
//   //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionStack);
