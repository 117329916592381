export const lifecycledashConstants = {

    LIFECYCLE_GET_CLIENT_PROFILE_DATA_REQUEST: 'LIFECYCLE_GET_CLIENT_PROFILE_DATA_REQUEST',
    LIFECYCLE_GET_CLIENT_PROFILE_DATA_SUCCESS: 'LIFECYCLE_GET_CLIENT_PROFILE_DATA_SUCCESS',
    LIFECYCLE_GET_CLIENT_PROFILE_DATA_FAILURE: 'LIFECYCLE_GET_CLIENT_PROFILE_DATA_FAILURE',


    LIFECYCLE_GET_SURVEY_REQUEST:'LIFECYCLE_GET_SURVEY_REQUEST',
    LIFECYCLE_GET_SURVEY_SUCCESS: 'LIFECYCLE_GET_SURVEY_SUCCESS',
    LIFECYCLE_GET_SURVEY_FAILURE: 'LIFECYCLE_GET_SURVEY_FAILURE',

    LIFECYCLE_TREE_MAP_REQUEST:'LIFECYCLE_TREE_MAP_REQUEST',
    LIFECYCLE_TREE_MAP_SUCCESS: 'LIFECYCLE_TREE_MAP_SUCCESS',
    LIFECYCLE_TREE_MAP_FAILURE: 'LIFECYCLE_TREE_MAP_FAILURE',

    
    LIFECYCLE_TREE_MAP_LEN_REQUEST:'LIFECYCLE_TREE_MAP_LEN_REQUEST',
    LIFECYCLE_TREE_MAP_LEN_SUCCESS: 'LIFECYCLE_TREE_MAP_LEN_SUCCESS',
    LIFECYCLE_TREE_MAP_LEN_FAILURE: 'LIFECYCLE_TREE_MAP_LEN_FAILURE',


    LIFECYCLE_GENERATE_COMPANY_SURVEY_CODE_REQUEST:'LIFECYCLE_GENERATE_COMPANY_SURVEY_CODE_REQUEST',
    LIFECYCLE_GENERATE_COMPANY_SURVEY_CODE_SUCCESS: 'LIFECYCLE_GENERATE_COMPANY_SURVEY_CODE_SUCCESS',
    LIFECYCLE_GENERATE_COMPANY_SURVEY_CODE_FAILURE: 'LIFECYCLE_GENERATE_COMPANY_SURVEY_CODE_FAILURE',

    LIFECYCLE_GET_HAPPINESS_INDEX_REQUEST:'LIFECYCLE_GET_HAPPINESS_INDEX_REQUEST',
    LIFECYCLE_GET_HAPPINESS_INDEX_SUCCESS: 'LIFECYCLE_GET_HAPPINESS_INDEX_SUCCESS',
    LIFECYCLE_GET_HAPPINESS_INDEX_FAILURE: 'LIFECYCLE_GET_HAPPINESS_INDEX_FAILURE',

    LIFECYCLE_GET_ENGAGEMENT_INDEX_REQUEST:'LIFECYCLE_GET_ENGAGEMENT_INDEX_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_SUCCESS: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_FAILURE: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_FAILURE',

    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANY_REQUEST:'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANY_REQUEST',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANY_SUCCESS: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANY_SUCCESS',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANY_FAILURE: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANY_FAILURE',


    LIFECYCLE_GET_HOPE_INDEX_REQUEST:'LIFECYCLE_GET_HOPE_INDEX_REQUEST',
    LIFECYCLE_GET_HOPE_INDEX_SUCCESS: 'LIFECYCLE_GET_HOPE_INDEX_SUCCESS',
    LIFECYCLE_GET_HOPE_INDEX_FAILURE: 'LIFECYCLE_GET_HOPE_INDEX_FAILURE',

    LIFECYCLE_GET_STRESS_INDEX_REQUEST:'LIFECYCLE_GET_STRESS_INDEX_REQUEST',
    LIFECYCLE_GET_STRESS_INDEX_SUCCESS: 'LIFECYCLE_GET_STRESS_INDEX_SUCCESS',
    LIFECYCLE_GET_STRESS_INDEX_FAILURE: 'LIFECYCLE_GET_STRESS_INDEX_FAILURE',

    LIFECYCLE_GET_MANAGER_INDEX_REQUEST:'LIFECYCLE_GET_MANAGER_INDEX_REQUEST',
    LIFECYCLE_GET_MANAGER_INDEX_SUCCESS: 'LIFECYCLE_GET_MANAGER_INDEX_SUCCESS',
    LIFECYCLE_GET_MANAGER_INDEX_FAILURE: 'LIFECYCLE_GET_MANAGER_INDEX_FAILURE',

    LIFECYCLE_GET_DEMOGRAPHIC_REPORT_FILTER_REQUEST:'LIFECYCLE_GET_DEMOGRAPHIC_REPORT_FILTER_REQUEST',
    LIFECYCLE_GET_DEMOGRAPHIC_REPORT_FILTER_SUCCESS: 'LIFECYCLE_GET_DEMOGRAPHIC_REPORT_FILTER_SUCCESS',
    LIFECYCLE_GET_DEMOGRAPHIC_REPORT_FILTER_FAILURE: 'LIFECYCLE_GET_DEMOGRAPHIC_REPORT_FILTER_FAILURE',

    LIFECYCLE_GET_ORG_DEMOGRAPHIC_INDEX_MANY_REQUEST:'LIFECYCLE_GET_ORG_DEMOGRAPHIC_INDEX_MANY_REQUEST',
    LIFECYCLE_GET_ORG_DEMOGRAPHIC_INDEX_MANY_SUCCESS: 'LIFECYCLE_GET_ORG_DEMOGRAPHIC_INDEX_MANY_SUCCESS',
    LIFECYCLE_GET_ORG_DEMOGRAPHIC_INDEX_MANY_FAILURE: 'LIFECYCLE_GET_ORG_DEMOGRAPHIC_INDEX_MANY_FAILURE',



    LIFECYCLE_GET_DEMOGRAPHIC_ORG_REPORT_REQUEST:'LIFECYCLE_GET_DEMOGRAPHIC_ORG_REPORT_REQUEST',
    LIFECYCLE_GET_DEMOGRAPHIC_ORG_REPORT_SUCCESS: 'LIFECYCLE_GET_DEMOGRAPHIC_ORG_REPORT_SUCCESS',
    LIFECYCLE_GET_DEMOGRAPHIC_ORG_REPORT_FAILURE: 'LIFECYCLE_GET_DEMOGRAPHIC_ORG_REPORT_FAILURE',


    LIFECYCLE_GET_LEADER_INDEX_REQUEST:'LIFECYCLE_GET_LEADER_INDEX_REQUEST',
    LIFECYCLE_GET_LEADER_INDEX_SUCCESS: 'LIFECYCLE_GET_LEADER_INDEX_SUCCESS',
    LIFECYCLE_GET_LEADER_INDEX_FAILURE: 'LIFECYCLE_GET_LEADER_INDEX_FAILURE',

    LIFECYCLE_TEXT_DIST_REQUEST:'LIFECYCLE_TEXT_DIST_REQUEST',
    LIFECYCLE_TEXT_DIST_SUCCESS: 'LIFECYCLE_TEXT_DIST_SUCCESS',
    LIFECYCLE_TEXT_DIST_FAILURE: 'LIFECYCLE_TEXT_DIST_FAILURE',

    LIFECYCLE_GET_HAPPINESS_INDEX_MNGR_REQUEST:'LIFECYCLE_GET_HAPPINESS_INDEX_MNGR_REQUEST',
    LIFECYCLE_GET_HAPPINESS_INDEX_MNGR_SUCCESS: 'LIFECYCLE_GET_HAPPINESS_INDEX_MNGR_SUCCESS',
    LIFECYCLE_GET_HAPPINESS_INDEX_MNGR_FAILURE: 'LIFECYCLE_GET_HAPPINESS_INDEX_MNGR_FAILURE',

    LIFECYCLE_GET_ENGAGEMENT_INDEX_MNGR_REQUEST:'LIFECYCLE_GET_ENGAGEMENT_INDEX_MNGR_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_MNGR_SUCCESS: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_MNGR_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_MNGR_FAILURE: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_MNGR_FAILURE',

    LIFECYCLE_GET_HOPE_INDEX_MNGR_REQUEST:'LIFECYCLE_GET_HOPE_INDEX_MNGR_REQUEST',
    LIFECYCLE_GET_HOPE_INDEX_MNGR_SUCCESS: 'LIFECYCLE_GET_HOPE_INDEX_MNGR_SUCCESS',
    LIFECYCLE_GET_HOPE_INDEX_MNGR_FAILURE: 'LIFECYCLE_GET_HOPE_INDEX_MNGR_FAILURE',

    LIFECYCLE_GET_STRESS_INDEX_MNGR_REQUEST:'LIFECYCLE_GET_STRESS_INDEX_MNGR_REQUEST',
    LIFECYCLE_GET_STRESS_INDEX_MNGR_SUCCESS: 'LIFECYCLE_GET_STRESS_INDEX_MNGR_SUCCESS',
    LIFECYCLE_GET_STRESS_INDEX_MNGR_FAILURE: 'LIFECYCLE_GET_STRESS_INDEX_MNGR_FAILURE',

    LIFECYCLE_GET_HAPPINESS_INDEX_FILTER_REQUEST:'LIFECYCLE_GET_HAPPINESS_INDEX_FILTER_REQUEST',
    LIFECYCLE_GET_HAPPINESS_INDEX_FILTER_SUCCESS: 'LIFECYCLE_GET_HAPPINESS_INDEX_FILTER_SUCCESS',
    LIFECYCLE_GET_HAPPINESS_INDEX_FILTER_FAILURE: 'LIFECYCLE_GET_HAPPINESS_INDEX_FILTER_FAILURE',
    
    LIFECYCLE_GET_ENGAGEMENT_INDEX_FILTER_REQUEST:'LIFECYCLE_GET_ENGAGEMENT_INDEX_FILTER_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_FILTER_SUCCESS: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_FILTER_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_FILTER_FAILURE: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_FILTER_FAILURE',

    LIFECYCLE_GET_HOPE_INDEX_FILTER_REQUEST:'LIFECYCLE_GET_HOPE_INDEX_FILTER_REQUEST',
    LIFECYCLE_GET_HOPE_INDEX_FILTER_SUCCESS: 'LIFECYCLE_GET_HOPE_INDEX_FILTER_SUCCESS',
    LIFECYCLE_GET_HOPE_INDEX_FILTER_FAILURE: 'LIFECYCLE_GET_HOPE_INDEX_FILTER_FAILURE',

    LIFECYCLE_GET_STRESS_INDEX_FILTER_REQUEST:'LIFECYCLE_GET_STRESS_INDEX_FILTER_REQUEST',
    LIFECYCLE_GET_STRESS_INDEX_FILTER_SUCCESS: 'LIFECYCLE_GET_STRESS_INDEX_FILTER_SUCCESS',
    LIFECYCLE_GET_STRESS_INDEX_FILTER_FAILURE: 'LIFECYCLE_GET_STRESS_INDEX_FILTER_FAILURE',

    LIFECYCLE_GET_MANAGER_INDEX_FILTER_REQUEST:'LIFECYCLE_GET_MANAGER_INDEX_FILTER_REQUEST',
    LIFECYCLE_GET_MANAGER_INDEX_FILTER_SUCCESS: 'LIFECYCLE_GET_MANAGER_INDEX_FILTER_SUCCESS',
    LIFECYCLE_GET_MANAGER_INDEX_FILTER_FAILURE: 'LIFECYCLE_GET_MANAGER_INDEX_FILTER_FAILURE',

    LIFECYCLE_GET_LEADER_INDEX_FILTER_REQUEST:'LIFECYCLE_GET_LEADER_INDEX_FILTER_REQUEST',
    LIFECYCLE_GET_LEADER_INDEX_FILTER_SUCCESS: 'LIFECYCLE_GET_LEADER_INDEX_FILTER_SUCCESS',
    LIFECYCLE_GET_LEADER_INDEX_FILTER_FAILURE: 'LIFECYCLE_GET_LEADER_INDEX_FILTER_FAILURE',

    LIFECYCLE_GET_EMP_FILTER_REQUEST:'LIFECYCLE_GET_EMP_FILTER_REQUEST',
    LIFECYCLE_GET_EMP_FILTER_SUCCESS: 'LIFECYCLE_GET_EMP_FILTER_SUCCESS',
    LIFECYCLE_GET_EMP_FILTER_FAILURE: 'LIFECYCLE_GET_EMP_FILTER_FAILURE',

    LIFECYCLE_GET_MANAGER_LIST_REQUEST:'LIFECYCLE_GET_MANAGER_LIST_REQUEST',
    LIFECYCLE_GET_MANAGER_LIST_SUCCESS: 'LIFECYCLE_GET_MANAGER_LIST_SUCCESS',
    LIFECYCLE_GET_MANAGER_LIST_FAILURE: 'LIFECYCLE_GET_MANAGER_LIST_FAILURE',

    LIFECYCLE_GET_QUESTION_ANALYSIS_REQUEST:'LIFECYCLE_GET_QUESTION_ANALYSIS_REQUEST',
    LIFECYCLE_GET_QUESTION_ANALYSIS_SUCCESS: 'LIFECYCLE_GET_QUESTION_ANALYSIS_SUCCESS',
    LIFECYCLE_GET_QUESTION_ANALYSIS_FAILURE: 'LIFECYCLE_GET_QUESTION_ANALYSIS_FAILURE',

    LIFECYCLE_GET_QUESTION_ANALYSIS_NEW_REQUEST:'LIFECYCLE_GET_QUESTION_ANALYSIS_NEW_REQUEST',
    LIFECYCLE_GET_QUESTION_ANALYSIS_NEW_SUCCESS: 'LIFECYCLE_GET_QUESTION_ANALYSIS_NEW_SUCCESS',
    LIFECYCLE_GET_QUESTION_ANALYSIS_NEW_FAILURE: 'LIFECYCLE_GET_QUESTION_ANALYSIS_NEW_FAILURE',

    LIFECYCLE_GET_QUESTION_ANALYSIS_FILTER_REQUEST:'LIFECYCLE_GET_QUESTION_ANALYSIS_FILTER_REQUEST',
    LIFECYCLE_GET_QUESTION_ANALYSIS_FILTER_SUCCESS: 'LIFECYCLE_GET_QUESTION_ANALYSIS_FILTER_SUCCESS',
    LIFECYCLE_GET_QUESTION_ANALYSIS_FILTER_FAILURE: 'LIFECYCLE_GET_QUESTION_ANALYSIS_FILTER_FAILURE',

    LIFECYCLE_GET_COMPANY_EMP_LIST_REQUEST:'LIFECYCLE_GET_COMPANY_EMP_LIST_REQUEST',
    LIFECYCLE_GET_COMPANY_EMP_LIST_SUCCESS: 'LIFECYCLE_GET_COMPANY_EMP_LIST_SUCCESS',
    LIFECYCLE_GET_COMPANY_EMP_LIST_FAILURE: 'LIFECYCLE_GET_COMPANY_EMP_LIST_FAILURE',

    LIFECYCLE_GET_ASSIGN_ROLE_REQUEST:'LIFECYCLE_GET_ASSIGN_ROLE_REQUEST',
    LIFECYCLE_GET_ASSIGN_ROLE_SUCCESS: 'LIFECYCLE_GET_ASSIGN_ROLE_SUCCESS',
    LIFECYCLE_GET_ASSIGN_ROLE_FAILURE: 'LIFECYCLE_GET_ASSIGN_ROLE_FAILURE',

    LIFECYCLE_GET_HAPPINESS_INDEX_NEW_REQUEST:'LIFECYCLE_GET_HAPPINESS_INDEX_NEW_REQUEST',
    LIFECYCLE_GET_HAPPINESS_INDEX_NEW_SUCCESS: 'LIFECYCLE_GET_HAPPINESS_INDEX_NEW_SUCCESS',
    LIFECYCLE_GET_HAPPINESS_INDEX_NEW_FAILURE: 'LIFECYCLE_GET_HAPPINESS_INDEX_NEW_FAILURE',

    LIFECYCLE_GET_ENGAGEMENT_INDEX_NEW_REQUEST:'LIFECYCLE_GET_ENGAGEMENT_INDEX_NEW_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_NEW_SUCCESS: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_NEW_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_NEW_FAILURE: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_NEW_FAILURE',

    LIFECYCLE_GET_HOPE_INDEX_NEW_REQUEST:'LIFECYCLE_GET_HOPE_INDEX_NEW_REQUEST',
    LIFECYCLE_GET_HOPE_INDEX_NEW_SUCCESS: 'LIFECYCLE_GET_HOPE_INDEX_NEW_SUCCESS',
    LIFECYCLE_GET_HOPE_INDEX_NEW_FAILURE: 'LIFECYCLE_GET_HOPE_INDEX_NEW_FAILURE',

    LIFECYCLE_GET_STRESS_INDEX_NEW_REQUEST:'LIFECYCLE_GET_STRESS_INDEX_NEW_REQUEST',
    LIFECYCLE_GET_STRESS_INDEX_NEW_SUCCESS: 'LIFECYCLE_GET_STRESS_INDEX_NEW_SUCCESS',
    LIFECYCLE_GET_STRESS_INDEX_NEW_FAILURE: 'LIFECYCLE_GET_STRESS_INDEX_NEW_FAILURE',

    LIFECYCLE_GET_MANAGER_INDEX_NEW_REQUEST:'LIFECYCLE_GET_MANAGER_INDEX_NEW_REQUEST',
    LIFECYCLE_GET_MANAGER_INDEX_NEW_SUCCESS: 'LIFECYCLE_GET_MANAGER_INDEX_NEW_SUCCESS',
    LIFECYCLE_GET_MANAGER_INDEX_NEW_FAILURE: 'LIFECYCLE_GET_MANAGER_INDEX_NEW_FAILURE',

    LIFECYCLE_GET_DATA_FILTER_OBJECT_SUCCESS:'LIFECYCLE_GET_DATA_FILTER_OBJECT_SUCCESS',

    LIFECYCLE_GET_STACK_LIST_SUCCESS:'LIFECYCLE_GET_STACK_LIST_SUCCESS',

    LIFECYCLE_GET_ORG_CORE_INDEX_REQUEST:'LIFECYCLE_GET_ORG_CORE_INDEX_REQUEST',
    LIFECYCLE_GET_ORG_CORE_INDEX_SUCCESS: 'LIFECYCLE_GET_ORG_CORE_INDEX_SUCCESS',
    LIFECYCLE_GET_ORG_CORE_INDEX_FAILURE: 'LIFECYCLE_GET_ORG_CORE_INDEX_FAILURE',






    

    LIFECYCLE_GET_INDEX_REQUEST: 'LIFECYCLE_GET_INDEX_REQUEST',
    LIFECYCLE_GET_INDEX_SUCCESS: 'LIFECYCLE_GET_INDEX_SUCCESS',
    LIFECYCLE_GET_INDEX_FAILURE: 'LIFECYCLE_GET_INDEX_FAILURE',

    LIFECYCLE_GET_OVERALL_INDEX_REQUEST: 'LIFECYCLE_GET_OVERALL_INDEX_REQUEST',
    LIFECYCLE_GET_OVERALL_INDEX_SUCCESS: 'LIFECYCLE_GET_OVERALL_INDEX_SUCCESS',
    LIFECYCLE_GET_OVERALL_INDEX_FAILURE: 'LIFECYCLE_GET_OVERALL_INDEX_FAILURE',


    LIFECYCLE_GET_INDEX_FILTER_REQUEST: 'LIFECYCLE_GET_INDEX_FILTER_REQUEST',
    LIFECYCLE_GET_INDEX_FILTER_SUCCESS: 'LIFECYCLE_GET_INDEX_FILTER_SUCCESS',
    LIFECYCLE_GET_INDEX_FILTER_FAILURE: 'LIFECYCLE_GET_INDEX_FILTER_FAILURE',

    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_REQUEST: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_REQUEST',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SUCCESS: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SUCCESS',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FAILURE: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FAILURE',

    LIFECYCLE_GET_QUESTION_REQUEST:'LIFECYCLE_GET_QUESTION_REQUEST',
    LIFECYCLE_GET_QUESTION_SUCCESS: 'LIFECYCLE_GET_QUESTION_SUCCESS',
    LIFECYCLE_GET_QUESTION_FAILURE: 'LIFECYCLE_GET_QUESTION_FAILURE',



    LIFECYCLE_GET_HAPINESS_QUESTION_REQUEST:'LIFECYCLE_GET_HAPINESS_QUESTION_REQUEST',
    LIFECYCLE_GET_HAPINESS_QUESTION_SUCCESS: 'LIFECYCLE_GET_HAPINESS_QUESTION_SUCCESS',
    LIFECYCLE_GET_HAPINESS_QUESTION_FAILURE: 'LIFECYCLE_GET_HAPINESS_QUESTION_FAILURE',

    LIFECYCLE_GET_HAPINESS_MANAGER_QUESTION_REQUEST:'LIFECYCLE_GET_HAPINESS_MANAGER_QUESTION_REQUEST',
    LIFECYCLE_GET_HAPINESS_MANAGER_QUESTION_SUCCESS: 'LIFECYCLE_GET_HAPINESS_MANAGER_QUESTION_SUCCESS',
    LIFECYCLE_GET_HAPINESS_MANAGER_QUESTION_FAILURE: 'LIFECYCLE_GET_HAPINESS_MANAGER_QUESTION_FAILURE',

    LIFECYCLE_GET_HAPINESS_DMG_QUESTION_REQUEST:'LIFECYCLE_GET_HAPINESS_DMG_QUESTION_REQUEST',
    LIFECYCLE_GET_HAPINESS_DMG_QUESTION_SUCCESS: 'LIFECYCLE_GET_HAPINESS_DMG_QUESTION_SUCCESS',
    LIFECYCLE_GET_HAPINESS_DMG_QUESTION_FAILURE: 'LIFECYCLE_GET_HAPINESS_DMG_QUESTION_FAILURE',



    LIFECYCLE_GET_ENGAGEMENT_QUESTION_REQUEST:'LIFECYCLE_GET_ENGAGEMENT_QUESTION_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_QUESTION_SUCCESS: 'LIFECYCLE_GET_ENGAGEMENT_QUESTION_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_QUESTION_FAILURE: 'LIFECYCLE_GET_ENGAGEMENT_QUESTION_FAILURE',

    LIFECYCLE_GET_ENGAGEMENT_MANAGER_QUESTION_REQUEST:'LIFECYCLE_GET_ENGAGEMENT_MANAGER_QUESTION_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_MANAGER_QUESTION_SUCCESS: 'LIFECYCLE_GET_ENGAGEMENT_MANAGER_QUESTION_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_MANAGER_QUESTION_FAILURE: 'LIFECYCLE_GET_ENGAGEMENT_MANAGER_QUESTION_FAILURE',

    LIFECYCLE_GET_ENGAGEMENT_DMG_QUESTION_REQUEST:'LIFECYCLE_GET_ENGAGEMENT_DMG_QUESTION_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_DMG_QUESTION_SUCCESS: 'LIFECYCLE_GET_ENGAGEMENT_DMG_QUESTION_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_DMG_QUESTION_FAILURE: 'LIFECYCLE_GET_ENGAGEMENT_DMG_QUESTION_FAILURE',


    

    LIFECYCLE_GET_STRESS_QUESTION_REQUEST:'LIFECYCLE_GET_STRESS_QUESTION_REQUEST',
    LIFECYCLE_GET_STRESS_QUESTION_SUCCESS: 'LIFECYCLE_GET_STRESS_QUESTION_SUCCESS',
    LIFECYCLE_GET_STRESS_QUESTION_FAILURE: 'LIFECYCLE_GET_STRESS_QUESTION_FAILURE',

    LIFECYCLE_GET_STRESS_MANAGER_QUESTION_REQUEST:'LIFECYCLE_GET_STRESS_MANAGER_QUESTION_REQUEST',
    LIFECYCLE_GET_STRESS_MANAGER_QUESTION_SUCCESS: 'LIFECYCLE_GET_STRESS_MANAGER_QUESTION_SUCCESS',
    LIFECYCLE_GET_STRESS_MANAGER_QUESTION_FAILURE: 'LIFECYCLE_GET_STRESS_MANAGER_QUESTION_FAILURE',

    LIFECYCLE_GET_STRESS_DMG_QUESTION_REQUEST:'LIFECYCLE_GET_STRESS_DMG_QUESTION_REQUEST',
    LIFECYCLE_GET_STRESS_DMG_QUESTION_SUCCESS: 'LIFECYCLE_GET_STRESS_DMG_QUESTION_SUCCESS',
    LIFECYCLE_GET_STRESS_DMG_QUESTION_FAILURE: 'LIFECYCLE_GET_STRESS_DMG_QUESTION_FAILURE',





    LIFECYCLE_GET_HOPE_QUESTION_REQUEST:'LIFECYCLE_GET_HOPE_QUESTION_REQUEST',
    LIFECYCLE_GET_HOPE_QUESTION_SUCCESS: 'LIFECYCLE_GET_HOPE_QUESTION_SUCCESS',
    LIFECYCLE_GET_HOPE_QUESTION_FAILURE: 'LIFECYCLE_GET_HOPE_QUESTION_FAILURE',



    LIFECYCLE_GET_MANAGER_QUESTION_REQUEST:'LIFECYCLE_GET_MANAGER_QUESTION_REQUEST',
    LIFECYCLE_GET_MANAGER_QUESTION_SUCCESS: 'LIFECYCLE_GET_MANAGER_QUESTION_SUCCESS',
    LIFECYCLE_GET_MANAGER_QUESTION_FAILURE: 'LIFECYCLE_GET_MANAGER_QUESTION_FAILURE',

    LIFECYCLE_GET_MANAGER_MANAGER_QUESTION_REQUEST:'LIFECYCLE_GET_MANAGER_MANAGER_QUESTION_REQUEST',
    LIFECYCLE_GET_MANAGER_MANAGER_QUESTION_SUCCESS: 'LIFECYCLE_GET_MANAGER_MANAGER_QUESTION_SUCCESS',
    LIFECYCLE_GET_MANAGER_MANAGER_QUESTION_FAILURE: 'LIFECYCLE_GET_MANAGER_QUESTION_FAILURE',

    LIFECYCLE_GET_MANAGER_DMG_QUESTION_REQUEST:'LIFECYCLE_GET_MANAGER_DMG_QUESTION_REQUEST',
    LIFECYCLE_GET_MANAGER_DMG_QUESTION_SUCCESS: 'LIFECYCLE_GET_MANAGER_DMG_QUESTION_SUCCESS',
    LIFECYCLE_GET_MANAGER_DMG_QUESTION_FAILURE: 'LIFECYCLE_GET_MANAGER_DMG_QUESTION_FAILURE',



    LIFECYCLE_GET_LEADER_QUESTION_REQUEST:'LIFECYCLE_GET_LEADER_QUESTION_REQUEST',
    LIFECYCLE_GET_LEADER_QUESTION_SUCCESS: 'LIFECYCLE_GET_LEADER_QUESTION_SUCCESS',
    LIFECYCLE_GET_LEADER_QUESTION_FAILURE: 'LIFECYCLE_GET_LEADER_QUESTION_FAILURE',

    LIFECYCLE_GET_LEADER_MANAGER_QUESTION_REQUEST:'LIFECYCLE_GET_LEADER_MANAGER_QUESTION_REQUEST',
    LIFECYCLE_GET_LEADER_MANAGER_QUESTION_SUCCESS: 'LIFECYCLE_GET_LEADER_MANAGER_QUESTION_SUCCESS',
    LIFECYCLE_GET_LEADER_MANAGER_QUESTION_FAILURE: 'LIFECYCLE_GET_LEADER_MANAGER_QUESTION_FAILURE',

    LIFECYCLE_GET_LEADER_DMG_QUESTION_REQUEST:'LIFECYCLE_GET_LEADER_DMG_QUESTION_REQUEST',
    LIFECYCLE_GET_LEADER_DMG_QUESTION_SUCCESS: 'LIFECYCLE_GET_LEADER_DMG_QUESTION_SUCCESS',
    LIFECYCLE_GET_LEADER_DMG_QUESTION_FAILURE: 'LIFECYCLE_GET_LEADER_DMG_QUESTION_FAILURE',



    LIFECYCLE_GET_QUESTION_REPORT_REQUEST:'LIFECYCLE_GET_QUESTION_REPORT_REQUEST',
    LIFECYCLE_GET_QUESTION_REPORT_SUCCESS: 'LIFECYCLE_GET_QUESTION_REPORT_SUCCESS',
    LIFECYCLE_GET_QUESTION_REPORT_FAILURE: 'LIFECYCLE_GET_QUESTION_REPORT_FAILURE',

    LIFECYCLE_GET_QUESTION_FILTER_REQUEST: 'LIFECYCLE_GET_QUESTION_FILTER_REQUEST',
    LIFECYCLE_GET_QUESTION_FILTER_SUCCESS: 'LIFECYCLE_GET_QUESTION_FILTER_SUCCESS',
    LIFECYCLE_GET_QUESTION_FILTER_FAILURE: 'LIFECYCLE_GET_QUESTION_FILTER_FAILURE',


    
    LIFECYCLE_GET_HAPPINESS_INDEX_V2_REQUEST: 'LIFECYCLE_GET_HAPPINESS_INDEX_V2_REQUEST',
    LIFECYCLE_GET_HAPPINESS_INDEX_V2_SUCCESS: 'LIFECYCLE_GET_HAPPINESS_INDEX_V2_SUCCESS',
    LIFECYCLE_GET_HAPPINESS_INDEX_V2_FAILURE: 'LIFECYCLE_GET_HAPPINESS_INDEX_V2_FAILURE',

    LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_REQUEST: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_SUCCESS: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FAILURE: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FAILURE',

    LIFECYCLE_GET_HOPE_INDEX_V2_REQUEST: 'LIFECYCLE_GET_HOPE_INDEX_V2_REQUEST',
    LIFECYCLE_GET_HOPE_INDEX_V2_SUCCESS: 'LIFECYCLE_GET_HOPE_INDEX_V2_SUCCESS',
    LIFECYCLE_GET_HOPE_INDEX_V2_FAILURE: 'LIFECYCLE_GET_HOPE_INDEX_V2_FAILURE',

    LIFECYCLE_GET_STRESS_INDEX_V2_REQUEST: 'LIFECYCLE_GET_STRESS_INDEX_V2_REQUEST',
    LIFECYCLE_GET_STRESS_INDEX_V2_SUCCESS: 'LIFECYCLE_GET_STRESS_INDEX_V2_SUCCESS',
    LIFECYCLE_GET_STRESS_INDEX_V2_FAILURE: 'LIFECYCLE_GET_STRESS_INDEX_V2_FAILURE',

    LIFECYCLE_GET_MANAGER_INDEX_V2_REQUEST: 'LIFECYCLE_GET_MANAGER_INDEX_V2_REQUEST',
    LIFECYCLE_GET_MANAGER_INDEX_V2_SUCCESS: 'LIFECYCLE_GET_MANAGER_INDEX_V2_SUCCESS',
    LIFECYCLE_GET_MANAGER_INDEX_V2_FAILURE: 'LIFECYCLE_GET_MANAGER_INDEX_V2_FAILURE',

    LIFECYCLE_GET_LEADER_INDEX_V2_REQUEST: 'LIFECYCLE_GET_LEADER_INDEX_V2_REQUEST',
    LIFECYCLE_GET_LEADER_INDEX_V2_SUCCESS: 'LIFECYCLE_GET_LEADER_INDEX_V2_SUCCESS',
    LIFECYCLE_GET_LEADER_INDEX_V2_FAILURE: 'LIFECYCLE_GET_LEADER_INDEX_V2_FAILURE',

    LIFECYCLE_GET_SENIORLEADERSHIP_INDEX_V2_REQUEST: 'LIFECYCLE_GET_SENIORLEADERSHIP_INDEX_V2_REQUEST',
    LIFECYCLE_GET_SENIORLEADERSHIP_INDEX_V2_SUCCESS: 'LIFECYCLE_GET_SENIORLEADERSHIP_INDEX_V2_SUCCESS',
    LIFECYCLE_GET_SENIORLEADERSHIP_INDEX_V2_FAILURE: 'LIFECYCLE_GET_SENIORLEADERSHIP_INDEX_V2_FAILURE',

    LIFECYCLE_GET_ORG_INDEX_V2_REQUEST: 'LIFECYCLE_GET_ORG_INDEX_V2_REQUEST',
    LIFECYCLE_GET_ORG_INDEX_V2_SUCCESS: 'LIFECYCLE_GET_ORG_INDEX_V2_SUCCESS',
    LIFECYCLE_GET_ORG_INDEX_V2_FAILURE: 'LIFECYCLE_GET_ORG_INDEX_V2_FAILURE',




    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_HAPPINESS_REQUEST: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_HAPPINESS_REQUEST',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_HAPPINESS_SUCCESS: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_HAPPINESS_SUCCESS',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_HAPPINESS_FAILURE: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_HAPPINESS_FAILURE',

    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_REQUEST:'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_REQUEST',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_SUCCESS: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_SUCCESS',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_FAILURE: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_FAILURE',
 
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_STRESS_REQUEST:'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_STRESS_REQUEST',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_STRESS_SUCCESS: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_STRESS_SUCCESS',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_STRESS_FAILURE: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_STRESS_FAILURE',

    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANAGER_REQUEST:'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANAGER_REQUEST',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANAGER_SUCCESS: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANAGER_SUCCESS',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANAGER_FAILURE: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANAGER_FAILURE',

    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_LEADER_REQUEST:'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_LEADER_REQUEST',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_LEADER_SUCCESS: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_LEADER_SUCCESS',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_LEADER_FAILURE: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_LEADER_FAILURE',

    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ORG_REQUEST:'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ORG_REQUEST',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ORG_SUCCESS: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ORG_SUCCESS',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ORG_FAILURE: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ORG_FAILURE',



    LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_REQUEST: 'LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_REQUEST',
    LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_SUCCESS: 'LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_SUCCESS',
    LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FAILURE: 'LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FAILURE',

    LIFECYCLE_GET_ENGAGEMENT_INDEX_DRIVER_REQUEST: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_DRIVER_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_DRIVER_SUCCESS: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_DRIVER_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_DRIVER_FAILURE: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_DRIVER_FAILURE',

    LIFECYCLE_GET_STRESS_INDEX_DRIVER_REQUEST: 'LIFECYCLE_GET_STRESS_INDEX_DRIVER_REQUEST',
    LIFECYCLE_GET_STRESS_INDEX_DRIVER_SUCCESS: 'LIFECYCLE_GET_STRESS_INDEX_DRIVER_SUCCESS',
    LIFECYCLE_GET_STRESS_INDEX_DRIVER_FAILURE: 'LIFECYCLE_GET_STRESS_INDEX_DRIVER_FAILURE',

    LIFECYCLE_GET_MANAGER_INDEX_DRIVER_REQUEST: 'LIFECYCLE_GET_MANAGER_INDEX_DRIVER_REQUEST',
    LIFECYCLE_GET_MANAGER_INDEX_DRIVER_SUCCESS: 'LIFECYCLE_GET_MANAGER_INDEX_DRIVER_SUCCESS',
    LIFECYCLE_GET_MANAGER_INDEX_DRIVER_FAILURE: 'LIFECYCLE_GET_MANAGER_INDEX_DRIVER_FAILURE',

    LIFECYCLE_GET_LEADER_INDEX_DRIVER_REQUEST: 'LIFECYCLE_GET_LEADER_INDEX_DRIVER_REQUEST',
    LIFECYCLE_GET_LEADER_INDEX_DRIVER_SUCCESS: 'LIFECYCLE_GET_LEADER_INDEX_DRIVER_SUCCESS',
    LIFECYCLE_GET_LEADER_INDEX_DRIVER_FAILURE: 'LIFECYCLE_GET_LEADER_INDEX_DRIVER_FAILURE',

    LIFECYCLE_GET_ORG_INDEX_DRIVER_REQUEST: 'LIFECYCLE_GET_ORG_INDEX_DRIVER_REQUEST',
    LIFECYCLE_GET_ORG_INDEX_DRIVER_SUCCESS: 'LIFECYCLE_GET_ORG_INDEX_DRIVER_SUCCESS',
    LIFECYCLE_GET_ORG_INDEX_DRIVER_FAILURE: 'LIFECYCLE_GET_ORG_INDEX_DRIVER_FAILURE',

    LIFECYCLE_GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_REQUEST:'LIFECYCLE_GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_REQUEST',
    LIFECYCLE_GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_SUCCESS: 'LIFECYCLE_GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_SUCCESS',
    LIFECYCLE_GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_FAILURE: 'LIFECYCLE_GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_FAILURE',

    LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_REQUEST:'LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_SUCCESS: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_FAILURE: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_FAILURE',

    LIFECYCLE_GET_STRESS_INDEX_V2_DEMOGRAPHIC_REQUEST:'LIFECYCLE_GET_STRESS_INDEX_V2_DEMOGRAPHIC_REQUEST',
    LIFECYCLE_GET_STRESS_INDEX_V2_DEMOGRAPHIC_SUCCESS: 'LIFECYCLE_GET_STRESS_INDEX_V2_DEMOGRAPHIC_SUCCESS',
    LIFECYCLE_GET_STRESS_INDEX_V2_DEMOGRAPHIC_FAILURE: 'LIFECYCLE_GET_STRESS_INDEX_V2_DEMOGRAPHIC_FAILURE',

    LIFECYCLE_GET_INDEX_BY_MANAGER_WISE_REQUEST:'LIFECYCLE_GET_INDEX_BY_MANAGER_WISE_REQUEST',
    LIFECYCLE_GET_INDEX_BY_MANAGER_WISE_SUCCESS: 'LIFECYCLE_GET_INDEX_BY_MANAGER_WISE_SUCCESS',
    LIFECYCLE_GET_INDEX_BY_MANAGER_WISE_FAILURE: 'LIFECYCLE_GET_INDEX_BY_MANAGER_WISE_FAILURE',

    LIFECYCLE_GET_ALL_BENCHMARK_REQUEST: 'LIFECYCLE_GET_ALL_BENCHMARK_REQUEST',
    LIFECYCLE_GET_ALL_BENCHMARK_SUCCESS: 'LIFECYCLE_GET_ALL_BENCHMARK_SUCCESS',
    LIFECYCLE_GET_ALL_BENCHMARK_FAILURE: 'LIFECYCLE_GET_ALL_BENCHMARK_FAILURE',

    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_REQUEST: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_REQUEST',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_SUCCESS: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_SUCCESS',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_FAILURE: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_FAILURE',

    LIFECYCLE_GET_RESPONDANT_DETAILS_REQUEST: 'LIFECYCLE_GET_RESPONDANT_DETAILS_REQUEST',
    LIFECYCLE_GET_RESPONDANT_DETAILS_SUCCESS: 'LIFECYCLE_GET_RESPONDANT_DETAILS_SUCCESS',
    LIFECYCLE_GET_RESPONDANT_DETAILS_FAILURE: 'LIFECYCLE_GET_RESPONDANT_DETAILS_FAILURE',

    LIFECYCLE_GET_MANAGER_LIST_DOMAIN_FILTER_REQUEST: 'LIFECYCLE_GET_MANAGER_LIST_DOMAIN_FILTER_REQUEST',
    LIFECYCLE_GET_MANAGER_LIST_DOMAIN_FILTER_SUCCESS: 'LIFECYCLE_GET_MANAGER_LIST_DOMAIN_FILTER_SUCCESS',
    LIFECYCLE_GET_MANAGER_LIST_DOMAIN_FILTER_FAILURE: 'LIFECYCLE_GET_MANAGER_LIST_DOMAIN_FILTER_FAILURE',


    LIFECYCLE_GET_QUESTION_MANAGER_OF_HAPPINESS_REQUEST: 'LIFECYCLE_GET_QUESTION_MANAGER_OF_HAPPINESS_REQUEST',
    LIFECYCLE_GET_QUESTION_MANAGER_OF_HAPPINESS_SUCCESS: 'LIFECYCLE_GET_QUESTION_MANAGER_OF_HAPPINESS_SUCCESS',
    LIFECYCLE_GET_QUESTION_MANAGER_OF_HAPPINESS_FAILURE: 'LIFECYCLE_GET_QUESTION_MANAGER_OF_HAPPINESS_FAILURE',

    LIFECYCLE_GET_QUESTION_MANAGER_OF_ENGAGEMENT_REQUEST: 'LIFECYCLE_GET_QUESTION_MANAGER_OF_ENGAGEMENT_REQUEST',
    LIFECYCLE_GET_QUESTION_MANAGER_OF_ENGAGEMENT_SUCCESS: 'LIFECYCLE_GET_QUESTION_MANAGER_OF_ENGAGEMENT_SUCCESS',
    LIFECYCLE_GET_QUESTION_MANAGER_OF_ENGAGEMENT_FAILURE: 'LIFECYCLE_GET_QUESTION_MANAGER_OF_ENGAGEMENT_FAILURE',

    LIFECYCLE_GET_QUESTION_MANAGER_OF_STRESS_REQUEST: 'LIFECYCLE_GET_QUESTION_MANAGER_OF_STRESS_REQUEST',
    LIFECYCLE_GET_QUESTION_MANAGER_OF_STRESS_SUCCESS: 'LIFECYCLE_GET_QUESTION_MANAGER_OF_STRESS_SUCCESS',
    LIFECYCLE_GET_QUESTION_MANAGER_OF_STRESS_FAILURE: 'LIFECYCLE_GET_QUESTION_MANAGER_OF_STRESS_FAILURE',

    LIFECYCLE_GET_QUESTION_MANAGER_OF_MANAGER_REQUEST: 'LIFECYCLE_GET_QUESTION_MANAGER_OF_MANAGER_REQUEST',
    LIFECYCLE_GET_QUESTION_MANAGER_OF_MANAGER_SUCCESS: 'LIFECYCLE_GET_QUESTION_MANAGER_OF_MANAGER_SUCCESS',
    LIFECYCLE_GET_QUESTION_MANAGER_OF_MANAGER_FAILURE: 'LIFECYCLE_GET_QUESTION_MANAGER_OF_MANAGER_FAILURE',

    LIFECYCLE_GET_INDEX_QUANTILE_REQUEST: 'LIFECYCLE_GET_INDEX_QUANTILE_REQUEST',
    LIFECYCLE_GET_INDEX_QUANTILE_SUCCESS: 'LIFECYCLE_GET_INDEX_QUANTILE_SUCCESS',
    LIFECYCLE_GET_INDEX_QUANTILE_FAILURE: 'LIFECYCLE_GET_INDEX_QUANTILE_FAILURE',


    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SCATTER_REQUEST:'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SCATTER_REQUEST',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SCATTER_SUCCESS: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SCATTER_SUCCESS',
    LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SCATTER_FAILURE: 'LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SCATTER_FAILURE',


    LIFECYCLE_GET_HAPPINESS_CORRELATION_REQUEST: 'LIFECYCLE_GET_HAPPINESS_CORRELATION_REQUEST',
    LIFECYCLE_GET_HAPPINESS_CORRELATION_SUCCESS: 'LIFECYCLE_GET_HAPPINESS_CORRELATION_SUCCESS',
    LIFECYCLE_GET_HAPPINESS_CORRELATION_FAILURE: 'LIFECYCLE_GET_HAPPINESS_CORRELATION_FAILURE',

    LIFECYCLE_GET_ENGAGEMENT_CORRELATION_REQUEST: 'LIFECYCLE_GET_ENGAGEMENT_CORRELATION_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_CORRELATION_SUCCESS: 'LIFECYCLE_GET_ENGAGEMENT_CORRELATION_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_CORRELATION_FAILURE: 'LIFECYCLE_GET_ENGAGEMENT_CORRELATION_FAILURE',

    LIFECYCLE_GET_STRESS_CORRELATION_REQUEST: 'LIFECYCLE_GET_STRESS_CORRELATION_REQUEST',
    LIFECYCLE_GET_STRESS_CORRELATION_SUCCESS: 'LIFECYCLE_GET_STRESS_CORRELATION_SUCCESS',
    LIFECYCLE_GET_STRESS_CORRELATION_FAILURE: 'LIFECYCLE_GET_STRESS_CORRELATION_FAILURE',

    LIFECYCLE_GET_MANAGER_CORRELATION_REQUEST: 'LIFECYCLE_GET_MANAGER_CORRELATION_REQUEST',
    LIFECYCLE_GET_MANAGER_CORRELATION_SUCCESS: 'LIFECYCLE_GET_MANAGER_CORRELATION_SUCCESS',
    LIFECYCLE_GET_MANAGER_CORRELATION_FAILURE: 'LIFECYCLE_GET_MANAGER_CORRELATION_FAILURE',

    LIFECYCLE_GET_LEADER_CORRELATION_REQUEST:'LIFECYCLE_GET_LEADER_CORRELATION_REQUEST',
    LIFECYCLE_GET_LEADER_CORRELATION_SUCCESS: 'LIFECYCLE_GET_LEADER_CORRELATION_SUCCESS',
    LIFECYCLE_GET_LEADER_CORRELATION_FAILURE: 'LIFECYCLE_GET_LEADER_CORRELATION_FAILURE',

    LIFECYCLE_GET_RWA_REQUEST: 'LIFECYCLE_GET_RWA_REQUEST',
    LIFECYCLE_GET_RWA_SUCCESS: 'LIFECYCLE_GET_RWA_SUCCESS',
    LIFECYCLE_GET_RWA_FAILURE: 'LIFECYCLE_GET_RWA_FAILURE',

    LIFECYCLE_GET_TEXT_QUESTION_REQUEST: 'LIFECYCLE_GET_TEXT_QUESTION_REQUEST',
    LIFECYCLE_GET_TEXT_QUESTION_SUCCESS: 'LIFECYCLE_GET_TEXT_QUESTION_SUCCESS',
    LIFECYCLE_GET_TEXT_QUESTION_FAILURE: 'LIFECYCLE_GET_TEXT_QUESTION_FAILURE',

    LIFECYCLE_WORD_CLOUD_REQUEST: 'LIFECYCLE_WORD_CLOUD_REQUEST',
    LIFECYCLE_WORD_CLOUD_SUCCESS: 'LIFECYCLE_WORD_CLOUD_SUCCESS',
    LIFECYCLE_WORD_CLOUD_FAILURE: 'LIFECYCLE_WORD_CLOUD_FAILURE',

    LIFECYCLE_ADVANCE_WORD_CLOUD_REQUEST: 'LIFECYCLE_ADVANCE_WORD_CLOUD_REQUEST',
    LIFECYCLE_ADVANCE_WORD_CLOUD_SUCCESS: 'LIFECYCLE_ADVANCE_WORD_CLOUD_SUCCESS',
    LIFECYCLE_ADVANCE_WORD_CLOUD_FAILURE: 'LIFECYCLE_ADVANCE_WORD_CLOUD_FAILURE',

    LIFECYCLE_WORD_CLOUD_TEXT_REQUEST: 'LIFECYCLE_WORD_CLOUD_TEXT_REQUEST',
    LIFECYCLE_WORD_CLOUD_TEXT_SUCCESS: 'LIFECYCLE_WORD_CLOUD_TEXT_SUCCESS',
    LIFECYCLE_WORD_CLOUD_TEXT_FAILURE: 'LIFECYCLE_WORD_CLOUD_TEXT_FAILURE',

    LIFECYCLE_WORD_CLOUD_MULTI_TEXT_REQUEST: 'LIFECYCLE_WORD_CLOUD_MULTI_TEXT_REQUEST',
    LIFECYCLE_WORD_CLOUD_MULTI_TEXT_SUCCESS: 'LIFECYCLE_WORD_CLOUD_MULTI_TEXT_SUCCESS',
    LIFECYCLE_WORD_CLOUD_MULTI_TEXT_FAILURE: 'LIFECYCLE_WORD_CLOUD_MULTI_TEXT_FAILURE',

    LIFECYCLE_COOCCUR_MATRIX_REQUEST: 'LIFECYCLE_COOCCUR_MATRIX_REQUEST',
    LIFECYCLE_COOCCUR_MATRIX_SUCCESS: 'LIFECYCLE_COOCCUR_MATRIX_SUCCESS',
    LIFECYCLE_COOCCUR_MATRIX_FAILURE: 'LIFECYCLE_COOCCUR_MATRIX_FAILURE',

    LIFECYCLE_NEIGHBOR_GRAPH_REQUEST: 'LIFECYCLE_NEIGHBOR_GRAPH_REQUEST',
    LIFECYCLE_NEIGHBOR_GRAPH_SUCCESS: 'LIFECYCLE_NEIGHBOR_GRAPH_SUCCESS',
    LIFECYCLE_NEIGHBOR_GRAPH_FAILURE: 'LIFECYCLE_NEIGHBOR_GRAPH_FAILURE',

    LIFECYCLE_GET_ENGAGEMENT_RWA_REQUEST: 'LIFECYCLE_GET_ENGAGEMENT_RWA_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_RWA_SUCCESS: 'LIFECYCLE_GET_ENGAGEMENT_RWA_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_RWA_FAILURE: 'LIFECYCLE_GET_ENGAGEMENT_RWA_FAILURE',

    LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_REQUEST: 'LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_REQUEST',
    LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_SUCCESS: 'LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_SUCCESS',
    LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_SUCCESS_TWO: 'LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_SUCCESS_TWO',
    LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_FAILURE: 'CRONE_BATCH_ALPHA_VARIANCE_FAILURE',

    LIFECYCLE_GET_QUESTION_FAVOURABILITY_REQUEST: 'LIFECYCLE_GET_QUESTION_FAVOURABILITY_REQUEST',
    LIFECYCLE_GET_QUESTION_FAVOURABILITY_SUCCESS: 'LIFECYCLE_GET_QUESTION_FAVOURABILITY_SUCCESS',
    LIFECYCLE_GET_QUESTION_FAVOURABILITY_FAILURE: 'LIFECYCLE_GET_QUESTION_FAVOURABILITY_FAILURE',

    LIFECYCLE_GET_HISTORY_INDEX_REQUEST: 'LIFECYCLE_GET_HISTORY_INDEX_REQUEST',
    LIFECYCLE_GET_HISTORY_INDEX_SUCCESS: 'LIFECYCLE_GET_HISTORY_INDEX_SUCCESS',
    LIFECYCLE_GET_HISTORY_INDEX_FAILURE: 'LIFECYCLE_GET_HISTORY_INDEX_FAILURE',


    LIFECYCLE_GET_RESPONSE_ANALYTIYCS_REQUEST: 'LIFECYCLE_GET_RESPONSE_ANALYTIYCS_REQUEST',
    LIFECYCLE_GET_RESPONSE_ANALYTIYCS_SUCCESS: 'LIFECYCLE_GET_RESPONSE_ANALYTIYCS_SUCCESS',
    LIFECYCLE_GET_RESPONSE_ANALYTIYCS_FAILURE: 'LIFECYCLE_GET_RESPONSE_ANALYTIYCS_FAILURE',

    
    LIFECYCLE_GET_COMMENT_REQUEST: 'LIFECYCLE_GET_COMMENT_REQUEST',
    LIFECYCLE_GET_COMMENT_SUCCESS: 'LIFECYCLE_GET_COMMENT_SUCCESS',
    LIFECYCLE_GET_COMMENT_FAILURE: 'LIFECYCLE_GET_COMMENT_FAILURE',

    LIFECYCLE_GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_REQUEST: 'LIFECYCLE_GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_REQUEST',
    LIFECYCLE_GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_SUCCESS: 'LIFECYCLE_GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_SUCCESS',
    LIFECYCLE_GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_FAILURE: 'LIFECYCLE_GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_FAILURE',

    LIFECYCLE_GET_MULTIMANAGER_QUESTIONLIST_REQUEST: 'LIFECYCLE_GET_MULTIMANAGER_QUESTIONLIST_REQUEST',
    LIFECYCLE_GET_MULTIMANAGER_QUESTIONLIST_SUCCESS: 'LIFECYCLE_GET_MULTIMANAGER_QUESTIONLIST_SUCCESS',
    LIFECYCLE_GET_MULTIMANAGER_QUESTIONLIST_FAILURE: 'LIFECYCLE_GET_MULTIMANAGER_QUESTIONLIST_FAILURE',

    LIFECYCLE_GET_DEMOGRAPHIC_MULTI_FILTER_REQUEST: 'LIFECYCLE_GET_DEMOGRAPHIC_MULTI_FILTER_REQUEST',
    LIFECYCLE_GET_DEMOGRAPHIC_MULTI_FILTER_SUCCESS: 'LIFECYCLE_GET_DEMOGRAPHIC_MULTI_FILTER_SUCCESS',
    LIFECYCLE_GET_DEMOGRAPHIC_MULTI_FILTER_FAILURE: 'LIFECYCLE_GET_DEMOGRAPHIC_MULTI_FILTER_FAILURE',

    LIFECYCLE_ASSIGN_MANAGER_ACTION_PLAN_REQUEST:'LIFECYCLE_ASSIGN_MANAGER_ACTION_PLAN_REQUEST',
    LIFECYCLE_ASSIGN_MANAGER_ACTION_PLAN_SUCCESS: 'LIFECYCLE_ASSIGN_MANAGER_ACTION_PLAN_SUCCESS',
    LIFECYCLE_ASSIGN_MANAGER_ACTION_PLAN_FAILURE: 'LIFECYCLE_ASSIGN_MANAGER_ACTION_PLAN_FAILURE',

    LIFECYCLE_GET_MANAGER_ACTION_PLAN_REQUEST:'LIFECYCLE_GET_MANAGER_ACTION_PLAN_REQUEST',
    LIFECYCLE_GET_MANAGER_ACTION_PLAN_SUCCESS: 'LIFECYCLE_GET_MANAGER_ACTION_PLAN_SUCCESS',
    LIFECYCLE_GET_MANAGER_ACTION_PLAN_FAILURE: 'LIFECYCLE_GET_MANAGER_ACTION_PLAN_FAILURE',

    LIFECYCLE_GET_EMPLOYEE_ACTION_PLAN_REQUEST:'LIFECYCLE_GET_EMPLOYEE_ACTION_PLAN_REQUEST',
    LIFECYCLE_GET_EMPLOYEE_ACTION_PLAN_SUCCESS: 'LIFECYCLE_GET_EMPLOYEE_ACTION_PLAN_SUCCESS',
    LIFECYCLE_GET_EMPLOYEE_ACTION_PLAN_FAILURE: 'LIFECYCLE_GET_EMPLOYEE_ACTION_PLAN_FAILURE',

    LIFECYCLE_UPDATE_EMPLOYEE_TASK_REQUEST:'LIFECYCLE_UPDATE_EMPLOYEE_TASK_REQUEST',
    LIFECYCLE_UPDATE_EMPLOYEE_TASK_SUCCESS: 'LIFECYCLE_UPDATE_EMPLOYEE_TASK_SUCCESS',
    LIFECYCLE_UPDATE_EMPLOYEE_TASK_FAILURE: 'LIFECYCLE_UPDATE_EMPLOYEE_TASK_FAILURE',

    LIFECYCLE_UPDATE_EMPLOYEE_RESOURCE_REQUEST:'LIFECYCLE_UPDATE_EMPLOYEE_RESOURCE_REQUEST',
    LIFECYCLE_UPDATE_EMPLOYEE_RESOURCE_SUCCESS: 'LIFECYCLE_UPDATE_EMPLOYEE_RESOURCE_SUCCESS',
    LIFECYCLE_UPDATE_EMPLOYEE_RESOURCE_FAILURE: 'LIFECYCLE_UPDATE_EMPLOYEE_RESOURCE_FAILURE',

    LIFECYCLE_GET_ALL_MANAGER_ACTION_PLAN_REQUEST:'LIFECYCLE_GET_ALL_MANAGER_ACTION_PLAN_REQUEST',
    LIFECYCLE_GET_ALL_MANAGER_ACTION_PLAN_SUCCESS: 'LIFECYCLE_GET_ALL_MANAGER_ACTION_PLAN_SUCCESS',
    LIFECYCLE_GET_ALL_MANAGER_ACTION_PLAN_FAILURE: 'LIFECYCLE_GET_ALL_MANAGER_ACTION_PLAN_FAILURE',


    
    LIFECYCLE_GET_QUESTION_ONLY_STRESS_REQUEST:'LIFECYCLE_GET_QUESTION_ONLY_STRESS_REQUEST',
    LIFECYCLE_GET_QUESTION_ONLY_STRESS_SUCCESS: 'LIFECYCLE_GET_QUESTION_ONLY_STRESS_SUCCESS',
    LIFECYCLE_GET_QUESTION_ONLY_STRESS_FAILURE: 'LIFECYCLE_GET_QUESTION_ONLY_STRESS_FAILURE',
    


    LIFECYCLE_CROSS_TAB_REQUEST:'LIFECYCLE_CROSS_TAB_REQUEST',
    LIFECYCLE_CROSS_TAB_SUCCESS: 'LIFECYCLE_CROSS_TAB_SUCCESS',
    LIFECYCLE_CROSS_TAB_FAILURE: 'LIFECYCLE_CROSS_TAB_FAILURE',

    LIFECYCLE_CROSS_TAB_DMG_REQUEST:'LIFECYCLE_CROSS_TAB_DMG_REQUEST',
    LIFECYCLE_CROSS_TAB_DMG_SUCCESS: 'LIFECYCLE_CROSS_TAB_DMG_SUCCESS',
    LIFECYCLE_CROSS_TAB_DMG_FAILURE: 'LIFECYCLE_CROSS_TAB_DMG_FAILURE',

    LIFECYCLE_GET_QUESTION_OVERALL_REQUEST:'LIFECYCLE_GET_QUESTION_OVERALL_REQUEST',
    LIFECYCLE_GET_QUESTION_OVERALL_SUCCESS: 'LIFECYCLE_GET_QUESTION_OVERALL_SUCCESS',
    LIFECYCLE_GET_QUESTION_OVERALL_FAILURE: 'LIFECYCLE_GET_QUESTION_OVERALL_FAILURE',
    
    LIFECYCLE_CROSS_TAB_ANALYSIS_REQUEST:'LIFECYCLE_CROSS_TAB_ANALYSIS_REQUEST',
    LIFECYCLE_CROSS_TAB_ANALYSIS_SUCCESS: 'LIFECYCLE_CROSS_TAB_ANALYSIS_SUCCESS',
    LIFECYCLE_CROSS_TAB_ANALYSIS_FAILURE: 'LIFECYCLE_CROSS_TAB_ANALYSIS_FAILURE',
    
    LIFECYCLE_GET_ALL_PARAMETER_LIST_REQUEST:'LIFECYCLE_GET_ALL_PARAMETER_LIST_REQUEST',
    LIFECYCLE_GET_ALL_PARAMETER_LIST_SUCCESS: 'LIFECYCLE_GET_ALL_PARAMETER_LIST_SUCCESS',
    LIFECYCLE_GET_ALL_PARAMETER_LIST_FAILURE: 'LIFECYCLE_GET_ALL_PARAMETER_LIST_FAILURE',

    LIFECYCLE_GET_CORRELATION_OVERALL_REQUEST:'LIFECYCLE_GET_CORRELATION_OVERALL_REQUEST',
    LIFECYCLE_GET_CORRELATION_OVERALL_SUCCESS: 'LIFECYCLE_GET_CORRELATION_OVERALL_SUCCESS',
    LIFECYCLE_GET_CORRELATION_OVERALL_FAILURE: 'LIFECYCLE_GET_CORRELATION_OVERALL_FAILURE',

    LIFECYCLE_GET_CORRELATION_FILTER_REQUEST:'LIFECYCLE_GET_CORRELATION_FILTER_REQUEST',
    LIFECYCLE_GET_CORRELATION_FILTER_SUCCESS: 'LIFECYCLE_GET_CORRELATION_FILTER_SUCCESS',
    LIFECYCLE_GET_CORRELATION_FILTER_FAILURE: 'LIFECYCLE_GET_CORRELATION_FILTER_FAILURE',

    LIFECYCLE_CORRELATION_TYPE2_ANALYSIS_REQUEST:'LIFECYCLE_CORRELATION_TYPE2_ANALYSIS_REQUEST',
    LIFECYCLE_CORRELATION_TYPE2_ANALYSIS_SUCCESS: 'LIFECYCLE_CORRELATION_TYPE2_ANALYSIS_SUCCESS',
    LIFECYCLE_CORRELATION_TYPE2_ANALYSIS_FAILURE: 'LIFECYCLE_CORRELATION_TYPE2_ANALYSIS_FAILURE',
    
    LIFECYCLE_GET_QUESTION_FAVOURABILITY_2_REQUEST:'LIFECYCLE_GET_QUESTION_FAVOURABILITY_2_REQUEST',
    LIFECYCLE_GET_QUESTION_FAVOURABILITY_2_SUCCESS: 'LIFECYCLE_GET_QUESTION_FAVOURABILITY_2_SUCCESS',
    LIFECYCLE_GET_QUESTION_FAVOURABILITY_2_FAILURE: 'LIFECYCLE_GET_QUESTION_FAVOURABILITY_2_FAILURE',




    //MANAGER
    LIFECYCLE_GET_MANAGER_PROFILE_DATA_REQUEST:'LIFECYCLE_GET_MANAGER_PROFILE_DATA_REQUEST',
    LIFECYCLE_GET_MANAGER_PROFILE_DATA_SUCCESS: 'LIFECYCLE_GET_MANAGER_PROFILE_DATA_SUCCESS',
    LIFECYCLE_GET_MANAGER_PROFILE_DATA_FAILURE: 'LIFECYCLE_GET_MANAGER_PROFILE_DATA_FAILURE',


    LIFECYCLE_GET_MANAGER_SURVEY_LIST_REQUEST:'LIFECYCLE_GET_MANAGER_SURVEY_LIST_REQUEST',
    LIFECYCLE_GET_MANAGER_SURVEY_LIST_SUCCESS: 'LIFECYCLE_GET_MANAGER_SURVEY_LIST_SUCCESS',
    LIFECYCLE_GET_MANAGER_SURVEY_LIST_FAILURE: 'LIFECYCLE_GET_MANAGER_SURVEY_LIST_FAILURE',



    LIFECYCLE_GET_HAPPINESS_INDEX_BY_FILTER_REQUEST:'LIFECYCLE_GET_HAPPINESS_INDEX_BY_FILTER_REQUEST',
    LIFECYCLE_GET_HAPPINESS_INDEX_BY_FILTER_SUCCESS:'LIFECYCLE_GET_HAPPINESS_INDEX_BY_FILTER_SUCCESS',
    LIFECYCLE_GET_HAPPINESS_INDEX_BY_FILTER_FAILURE:'LIFECYCLE_GET_HAPPINESS_INDEX_BY_FILTER_FAILURE',

    LIFECYCLE_GET_ENGAGEMENT_INDEX_BY_FILTER_REQUEST:'LIFECYCLE_GET_ENGAGEMENT_INDEX_BY_FILTER_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_BY_FILTER_SUCCESS:'LIFECYCLE_GET_ENGAGEMENT_INDEX_BY_FILTER_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_BY_FILTER_FAILURE:'LIFECYCLE_GET_ENGAGEMENT_INDEX_BY_FILTER_FAILURE',

    LIFECYCLE_GET_STRESS_INDEX_BY_FILTER_REQUEST:'LIFECYCLE_GET_STRESS_INDEX_BY_FILTER_REQUEST',
    LIFECYCLE_GET_STRESS_INDEX_BY_FILTER_SUCCESS:'LIFECYCLE_GET_STRESS_INDEX_BY_FILTER_SUCCESS',
    LIFECYCLE_GET_STRESS_INDEX_BY_FILTER_FAILURE:'LIFECYCLE_GET_STRESS_INDEX_BY_FILTER_FAILURE',

    LIFECYCLE_GET_MANAGER_INDEX_BY_FILTER_REQUEST:'LIFECYCLE_GET_MANAGER_INDEX_BY_FILTER_REQUEST',
    LIFECYCLE_GET_MANAGER_INDEX_BY_FILTER_SUCCESS:'LIFECYCLE_GET_MANAGER_INDEX_BY_FILTER_SUCCESS',
    LIFECYCLE_GET_MANAGER_INDEX_BY_FILTER_FAILURE:'LIFECYCLE_GET_MANAGER_INDEX_BY_FILTER_FAILURE',

    LIFECYCLE_GET_LEADER_INDEX_BY_FILTER_REQUEST:'LIFECYCLE_GET_LEADER_INDEX_BY_FILTER_REQUEST',
    LIFECYCLE_GET_LEADER_INDEX_BY_FILTER_SUCCESS:'LIFECYCLE_GET_LEADER_INDEX_BY_FILTER_SUCCESS',
    LIFECYCLE_GET_LEADER_INDEX_BY_FILTER_FAILURE:'LIFECYCLE_GET_LEADER_INDEX_BY_FILTER_FAILURE',

    LIFECYCLE_GET_ENGAGEMENT_COMPARISON_REQUEST:'LIFECYCLE_GET_ENGAGEMENT_COMPARISON_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_COMPARISON_SUCCESS:'LIFECYCLE_GET_ENGAGEMENT_COMPARISON_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_COMPARISON_FAILURE:'LIFECYCLE_GET_ENGAGEMENT_COMPARISON_FAILURE',
    
    LIFECYCLE_GET_QUESTION_COMMENT_SUCCESS:'LIFECYCLE_GET_QUESTION_COMMENT_SUCCESS',


    LIFECYCLE_GET_BRANCODE_INDEX_SCORE_REQUEST:'LIFECYCLE_GET_BRANCODE_INDEX_SCORE_REQUEST',
    LIFECYCLE_GET_BRANCODE_INDEX_SCORE_SUCCESS:'LIFECYCLE_GET_BRANCODE_INDEX_SCORE_SUCCESS',
    LIFECYCLE_GET_BRANCODE_INDEX_SCORE_FAILURE:'LIFECYCLE_GET_BRANCODE_INDEX_SCORE_FAILURE',
    
    LIFECYCLE_GET_PARAMETER_TYPE_LIST_REQUEST:'LIFECYCLE_GET_PARAMETER_TYPE_LIST_REQUEST',
    LIFECYCLE_GET_PARAMETER_TYPE_LIST_SUCCESS:'LIFECYCLE_GET_PARAMETER_TYPE_LIST_SUCCESS',
    LIFECYCLE_GET_PARAMETER_TYPE_LIST_FAILURE:'LIFECYCLE_GET_PARAMETER_TYPE_LIST_FAILURE',
    

    LIFECYCLE_CREATE_PARAMETER_REQUEST:'LIFECYCLE_CREATE_PARAMETER_REQUEST',
    LIFECYCLE_CREATE_PARAMETER_SUCCESS:'LIFECYCLE_CREATE_PARAMETER_SUCCESS',
    LIFECYCLE_CREATE_PARAMETER_FAILURE:'LIFECYCLE_CREATE_PARAMETER_FAILURE',
    
    
    LIFECYCLE_GET_ORG_INDEX_FILTER_REQUEST:'LIFECYCLE_GET_ORG_INDEX_FILTER_REQUEST',
    LIFECYCLE_GET_ORG_INDEX_FILTER_SUCCESS: 'LIFECYCLE_GET_ORG_INDEX_FILTER_SUCCESS',
    LIFECYCLE_GET_ORG_INDEX_FILTER_FAILURE: 'LIFECYCLE_GET_ORG_INDEX_FILTER_FAILURE',


    LIFECYCLE_GET_HAPINESS_INDEX_V2_FILTER_REQUEST:'LIFECYCLE_GET_HAPINESS_INDEX_V2_FILTER_REQUEST',
    LIFECYCLE_GET_HAPINESS_INDEX_V2_FILTER_SUCCESS: 'LIFECYCLE_GET_HAPINESS_INDEX_V2_FILTER_SUCCESS',
    LIFECYCLE_GET_HAPINESS_INDEX_V2_FILTER_FAILURE: 'LIFECYCLE_GET_HAPINESS_INDEX_V2_FILTER_FAILURE',

    LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FILTER_REQUEST:'LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FILTER_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FILTER_SUCCESS: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FILTER_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FILTER_FAILURE: 'LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FILTER_FAILURE',

    LIFECYCLE_GET_STRESS_INDEX_V2_FILTER_REQUEST:'LIFECYCLE_GET_STRESS_INDEX_V2_FILTER_REQUEST',
    LIFECYCLE_GET_STRESS_INDEX_V2_FILTER_SUCCESS: 'LIFECYCLE_GET_STRESS_INDEX_V2_FILTER_SUCCESS',
    LIFECYCLE_GET_STRESS_INDEX_V2_FILTER_FAILURE: 'LIFECYCLE_GET_STRESS_INDEX_V2_FILTER_FAILURE',


    LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FILTER_REQUEST:'LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FILTER_REQUEST',
    LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FILTER_SUCCESS: 'LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FILTER_SUCCESS',
    LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FILTER_FAILURE: 'LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FILTER_FAILURE',

    LIFECYCLE_GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_REQUEST:'LIFECYCLE_GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_REQUEST',
    LIFECYCLE_GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_SUCCESS: 'LIFECYCLE_GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_SUCCESS',
    LIFECYCLE_GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_FAILURE: 'LIFECYCLE_GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_FAILURE',

    LIFECYCLE_GET_STRESS_INDEX_DRIVER_FILTER_REQUEST:'LIFECYCLE_GET_STRESS_INDEX_DRIVER_FILTER_REQUEST',
    LIFECYCLE_GET_STRESS_INDEX_DRIVER_FILTER_SUCCESS: 'LIFECYCLE_GET_STRESS_INDEX_DRIVER_FILTER_SUCCESS',
    LIFECYCLE_GET_STRESS_INDEX_DRIVER_FILTER_FAILURE: 'LIFECYCLE_GET_STRESS_INDEX_DRIVER_FILTER_FAILURE',

    LIFECYCLE_GET_PARAMETER_LIBRARY_ALL_LIST_REQUEST:'LIFECYCLE_GET_PARAMETER_LIBRARY_ALL_LIST_REQUEST',
    LIFECYCLE_GET_PARAMETER_LIBRARY_ALL_LIST_SUCCESS: 'LIFECYCLE_GET_PARAMETER_LIBRARY_ALL_LIST_SUCCESS',
    LIFECYCLE_GET_PARAMETER_LIBRARY_ALL_LIST_FAILURE: 'LIFECYCLE_GET_PARAMETER_LIBRARY_ALL_LIST_FAILURE',


    LIFECYCLE_CLUSTER_ANALYSIS_REQUEST:'LIFECYCLE_CLUSTER_ANALYSIS_REQUEST',
    LIFECYCLE_CLUSTER_ANALYSIS_SUCCESS: 'LIFECYCLE_CLUSTER_ANALYSIS_SUCCESS',
    LIFECYCLE_CLUSTER_ANALYSIS_FAILURE: 'LIFECYCLE_CLUSTER_ANALYSIS_FAILURE',

    LIFECYCLE_GET_CLUSTER_EMP_INFO_LIST_REQUEST:'LIFECYCLE_GET_CLUSTER_EMP_INFO_LIST_REQUEST',
    LIFECYCLE_GET_CLUSTER_EMP_INFO_LIST_SUCCESS: 'LIFECYCLE_GET_CLUSTER_EMP_INFO_LIST_SUCCESS',
    LIFECYCLE_GET_CLUSTER_EMP_INFO_LIST_FAILURE: 'LIFECYCLE_GET_CLUSTER_EMP_INFO_LIST_FAILURE',

    
    LIFECYCLE_TOPIC_MODELLING_REQUEST:'LIFECYCLE_TOPIC_MODELLING_REQUEST',
    LIFECYCLE_TOPIC_MODELLING_SUCCESS: 'LIFECYCLE_TOPIC_MODELLING_SUCCESS',
    LIFECYCLE_TOPIC_MODELLING_FAILURE: 'LIFECYCLE_TOPIC_MODELLING_FAILURE',



    LIFECYCLE_CREATE_TOPIC_REQUEST:'LIFECYCLE_CREATE_TOPIC_REQUEST',
    LIFECYCLE_CREATE_TOPIC_SUCCESS: 'LIFECYCLE_CREATE_TOPIC_SUCCESS',
    LIFECYCLE_CREATE_TOPIC_FAILURE: 'LIFECYCLE_CREATE_TOPIC_FAILURE',

    LIFECYCLE_UPDATE_TOPIC_REQUEST:'LIFECYCLE_UPDATE_TOPIC_REQUEST',
    LIFECYCLE_UPDATE_TOPIC_SUCCESS: 'LIFECYCLE_UPDATE_TOPIC_SUCCESS',
    LIFECYCLE_UPDATE_TOPIC_FAILURE: 'LIFECYCLE_UPDATE_TOPIC_FAILURE',

    LIFECYCLE_DELETE_TOPIC_REQUEST:'LIFECYCLE_DELETE_TOPIC_REQUEST',
    LIFECYCLE_DELETE_TOPIC_SUCCESS: 'LIFECYCLE_DELETE_TOPIC_SUCCESS',
    LIFECYCLE_DELETE_TOPIC_FAILURE: 'LIFECYCLE_DELETE_TOPIC_FAILURE',

    LIFECYCLE_GET_TOPIC_LIST_REQUEST:'LIFECYCLE_GET_TOPIC_LIST_REQUEST',
    LIFECYCLE_GET_TOPIC_LIST_SUCCESS: 'LIFECYCLE_GET_TOPIC_LIST_SUCCESS',
    LIFECYCLE_GET_TOPIC_LIST_FAILURE: 'LIFECYCLE_GET_TOPIC_LIST_FAILURE',

    LIFECYCLE_GET_ALL_KEYWORDS_TEXT_LIST_REQUEST:'LIFECYCLE_GET_ALL_KEYWORDS_TEXT_LIST_REQUEST',
    LIFECYCLE_GET_ALL_KEYWORDS_TEXT_LIST_SUCCESS: 'LIFECYCLE_GET_ALL_KEYWORDS_TEXT_LIST_SUCCESS',
    LIFECYCLE_GET_ALL_KEYWORDS_TEXT_LIST_FAILURE: 'LIFECYCLE_GET_ALL_KEYWORDS_TEXT_LIST_FAILURE',
    
    LIFECYCLE_GET_ENGAGEMENT_COMPARISON_OVERALL_REQUEST:'LIFECYCLE_GET_ENGAGEMENT_COMPARISON_OVERALL_REQUEST',
    LIFECYCLE_GET_ENGAGEMENT_COMPARISON_OVERALL_SUCCESS: 'LIFECYCLE_GET_ENGAGEMENT_COMPARISON_OVERALL_SUCCESS',
    LIFECYCLE_GET_ENGAGEMENT_COMPARISON_OVERALL_FAILURE: 'LIFECYCLE_GET_ENGAGEMENT_COMPARISON_OVERALL_FAILURE',

    LIFECYCLE_GET_OVERALL_SCORE_BY_SURVEY_REQUEST:'LIFECYCLE_GET_OVERALL_SCORE_BY_SURVEY_REQUEST',
    LIFECYCLE_GET_OVERALL_SCORE_BY_SURVEY_SUCCESS: 'LIFECYCLE_GET_OVERALL_SCORE_BY_SURVEY_SUCCESS',
    LIFECYCLE_GET_OVERALL_SCORE_BY_SURVEY_FAILURE: 'LIFECYCLE_GET_OVERALL_SCORE_BY_SURVEY_FAILURE',

    LIFECYCLE_GET_ALL_MANAGER_SCORES_REQUEST:'LIFECYCLE_GET_ALL_MANAGER_SCORES_REQUEST',
    LIFECYCLE_GET_ALL_MANAGER_SCORES_SUCCESS: 'LIFECYCLE_GET_ALL_MANAGER_SCORES_SUCCESS',
    LIFECYCLE_GET_ALL_MANAGER_SCORES_FAILURE: 'LIFECYCLE_GET_ALL_MANAGER_SCORES_FAILURE',


    LIFECYCLE_CREATE_GOAL_REQUEST:'LIFECYCLE_CREATE_GOAL_REQUEST',
    LIFECYCLE_CREATE_GOAL_SUCCESS: 'LIFECYCLE_CREATE_GOAL_SUCCESS',
    LIFECYCLE_CREATE_GOAL_FAILURE: 'LIFECYCLE_CREATE_GOAL_FAILURE',

    LIFECYCLE_GET_GOAL_LIST_REQUEST:'LIFECYCLE_GET_GOAL_LIST_REQUEST',
    LIFECYCLE_GET_GOAL_LIST_SUCCESS: 'LIFECYCLE_GET_GOAL_LIST_SUCCESS',
    LIFECYCLE_GET_GOAL_LIST_FAILURE: 'LIFECYCLE_GET_GOAL_LIST_FAILURE',

    LIFECYCLE_UPDATE_GOAL_REQUEST:'LIFECYCLE_UPDATE_GOAL_REQUEST',
    LIFECYCLE_UPDATE_GOAL_SUCCESS: 'LIFECYCLE_UPDATE_GOAL_SUCCESS',
    LIFECYCLE_UPDATE_GOAL_FAILURE: 'LIFECYCLE_UPDATE_GOAL_FAILURE',

    LIFECYCLE_DELETE_GOAL_REQUEST:'LIFECYCLE_DELETE_GOAL_REQUEST',
    LIFECYCLE_DELETE_GOAL_SUCCESS: 'LIFECYCLE_DELETE_GOAL_SUCCESS',
    LIFECYCLE_DELETE_GOAL_FAILURE: 'LIFECYCLE_DELETE_GOAL_FAILURE',

    
    LIFECYCLE_CREATE_PLAN_REQUEST:'LIFECYCLE_CREATE_PLAN_REQUEST',
    LIFECYCLE_CREATE_PLAN_SUCCESS: 'LIFECYCLE_CREATE_PLAN_SUCCESS',
    LIFECYCLE_CREATE_PLAN_FAILURE: 'LIFECYCLE_CREATE_PLAN_FAILURE',

    LIFECYCLE_UPDATE_PLAN_REQUEST:'LIFECYCLE_UPDATE_PLAN_REQUEST',
    LIFECYCLE_UPDATE_PLAN_SUCCESS: 'LIFECYCLE_UPDATE_PLAN_SUCCESS',
    LIFECYCLE_UPDATE_PLAN_FAILURE: 'LIFECYCLE_UPDATE_PLAN_FAILURE',

    LIFECYCLE_DELETE_PLAN_REQUEST:'LIFECYCLE_DELETE_PLAN_REQUEST',
    LIFECYCLE_DELETE_PLAN_SUCCESS: 'LIFECYCLE_DELETE_PLAN_SUCCESS',
    LIFECYCLE_DELETE_PLAN_FAILURE: 'LIFECYCLE_DELETE_PLAN_FAILURE',

    LIFECYCLE_START_PLAN_REQUEST:'LIFECYCLE_START_PLAN_REQUEST',
    LIFECYCLE_START_PLAN_SUCCESS: 'LIFECYCLE_START_PLAN_SUCCESS',
    LIFECYCLE_START_PLAN_FAILURE: 'LIFECYCLE_START_PLAN_FAILURE',

    LIFECYCLE_CLOSE_PLAN_REQUEST:'LIFECYCLE_CLOSE_PLAN_REQUEST',
    LIFECYCLE_CLOSE_PLAN_SUCCESS: 'LIFECYCLE_CLOSE_PLAN_SUCCESS',
    LIFECYCLE_CLOSE_PLAN_FAILURE: 'LIFECYCLE_CLOSE_PLAN_FAILURE',

    LIFECYCLE_GET_ALL_MANAGER_GOAL_PLAN_REQUEST:'LIFECYCLE_GET_ALL_MANAGER_GOAL_PLAN_REQUEST',
    LIFECYCLE_GET_ALL_MANAGER_GOAL_PLAN_SUCCESS: 'LIFECYCLE_GET_ALL_MANAGER_GOAL_PLAN_SUCCESS',
    LIFECYCLE_GET_ALL_MANAGER_GOAL_PLAN_FAILURE: 'LIFECYCLE_GET_ALL_MANAGER_GOAL_PLAN_FAILURE',
    
    LIFECYCLE_GET_IDP_LAST_DATE_REQUEST:'LIFECYCLE_GET_IDP_LAST_DATE_REQUEST',
    LIFECYCLE_GET_IDP_LAST_DATE_SUCCESS: 'LIFECYCLE_GET_IDP_LAST_DATE_SUCCESS',
    LIFECYCLE_GET_IDP_LAST_DATE_FAILURE: 'LIFECYCLE_GET_IDP_LAST_DATE_FAILURE',

    LIFECYCLE_EDIT_IDP_LAST_DATE_REQUEST:'LIFECYCLE_EDIT_IDP_LAST_DATE_REQUEST',
    LIFECYCLE_EDIT_IDP_LAST_DATE_SUCCESS: 'LIFECYCLE_EDIT_IDP_LAST_DATE_SUCCESS',
    LIFECYCLE_EDIT_IDP_LAST_DATE_FAILURE: 'LIFECYCLE_EDIT_IDP_LAST_DATE_FAILURE',

    LIFECYCLE_GET_COMPANY_NAME_ONLY_REQUEST:'LIFECYCLE_GET_COMPANY_NAME_ONLY_REQUEST',
    LIFECYCLE_GET_COMPANY_NAME_ONLY_SUCCESS: 'LIFECYCLE_GET_COMPANY_NAME_ONLY_SUCCESS',
    LIFECYCLE_GET_COMPANY_NAME_ONLY_FAILURE: 'LIFECYCLE_GET_COMPANY_NAME_ONLY_FAILURE',

    LIFECYCLE_GET_MANAGER_DOWNLOAD_STATUS_REQUEST:'LIFECYCLE_GET_MANAGER_DOWNLOAD_STATUS_REQUEST',
    LIFECYCLE_GET_MANAGER_DOWNLOAD_STATUS_SUCCESS: 'LIFECYCLE_GET_MANAGER_DOWNLOAD_STATUS_SUCCESS',
    LIFECYCLE_GET_MANAGER_DOWNLOAD_STATUS_FAILURE: 'LIFECYCLE_GET_MANAGER_DOWNLOAD_STATUS_FAILURE',

    LIFECYCLE_GET_ALL_MANAGER_DOWNLOAD_STATUS_REQUEST:'LIFECYCLE_GET_ALL_MANAGER_DOWNLOAD_STATUS_REQUEST',
    LIFECYCLE_GET_ALL_MANAGER_DOWNLOAD_STATUS_SUCCESS: 'LIFECYCLE_GET_ALL_MANAGER_DOWNLOAD_STATUS_SUCCESS',
    LIFECYCLE_GET_ALL_MANAGER_DOWNLOAD_STATUS_FAILURE: 'LIFECYCLE_GET_ALL_MANAGER_DOWNLOAD_STATUS_FAILURE',

    LIFECYCLE_GET_QUESTION_MAP_REQUEST:'LIFECYCLE_GET_QUESTION_MAP_REQUEST',
    LIFECYCLE_GET_QUESTION_MAP_SUCCESS: 'LIFECYCLE_GET_QUESTION_MAP_SUCCESS',
    LIFECYCLE_GET_QUESTION_MAP_FAILURE: 'LIFECYCLE_GET_QUESTION_MAP_FAILURE',


    LIFECYCLE_GET_QUESTION_CUSTOM_TEMPLATE_REQUEST:'LIFECYCLE_GET_QUESTION_CUSTOM_TEMPLATE_REQUEST',
    LIFECYCLE_GET_QUESTION_CUSTOM_TEMPLATE_SUCCESS: 'LIFECYCLE_GET_QUESTION_CUSTOM_TEMPLATE_SUCCESS',
    LIFECYCLE_GET_QUESTION_CUSTOM_TEMPLATE_FAILURE: 'LIFECYCLE_GET_QUESTION_CUSTOM_TEMPLATE_FAILURE',

    LIFECYCLE_GET_INDEX_BY_FILTER_ALL_STAGES_REQUEST:'LIFECYCLE_GET_INDEX_BY_FILTER_ALL_STAGES_REQUEST',
    LIFECYCLE_GET_INDEX_BY_FILTER_ALL_STAGES_SUCCESS: 'LIFECYCLE_GET_INDEX_BY_FILTER_ALL_STAGES_SUCCESS',
    LIFECYCLE_GET_INDEX_BY_FILTER_ALL_STAGES_FAILURE: 'LIFECYCLE_GET_INDEX_BY_FILTER_ALL_STAGES_FAILURE',
  
    
    
    LIFECYCLE_GET_CAT_OVERALL_INDEX_REQUEST:'LIFECYCLE_GET_CAT_OVERALL_INDEX_REQUEST',
    LIFECYCLE_GET_CAT_OVERALL_INDEX_SUCCESS: 'LIFECYCLE_GET_CAT_OVERALL_INDEX_SUCCESS',
    LIFECYCLE_GET_CAT_OVERALL_INDEX_FAILURE: 'LIFECYCLE_GET_CAT_OVERALL_INDEX_FAILURE',


    LIFECYCLE_GET_LISTEN_REQUEST:'LIFECYCLE_GET_LISTEN_REQUEST',
    LIFECYCLE_GET_LISTEN_SUCCESS: 'LIFECYCLE_GET_LISTEN_SUCCESS',
    LIFECYCLE_GET_LISTEN_FAILURE: 'LIFECYCLE_GET_LISTEN_FAILURE',

    GET_QUES_LIST_SURVEY_ID_LIFECYCLE_REQUEST:'GET_QUES_LIST_SURVEY_ID_LIFECYCLE_REQUEST',
    GET_QUES_LIST_SURVEY_ID_LIFECYCLE_SUCCESS: 'GET_QUES_LIST_SURVEY_ID_LIFECYCLE_SUCCESS',
    GET_QUES_LIST_SURVEY_ID_LIFECYCLE_FAILURE: 'GET_QUES_LIST_SURVEY_ID_LIFECYCLE_FAILURE',

    GET_INDEX_TREND_SCORE_FILTER_REQUEST:'GET_INDEX_TREND_SCORE_FILTER_REQUEST',
    GET_INDEX_TREND_SCORE_FILTER_SUCCESS: 'GET_INDEX_TREND_SCORE_FILTER_SUCCESS',
    GET_INDEX_TREND_SCORE_FILTER_FAILURE: 'GET_INDEX_TREND_SCORE_FILTER_FAILURE',

    GET_INDEX_TREND_SCORE_OVERALL_REQUEST:'GET_INDEX_TREND_SCORE_OVERALL_REQUEST',
    GET_INDEX_TREND_SCORE_OVERALL_SUCCESS: 'GET_INDEX_TREND_SCORE_OVERALL_SUCCESS',
    GET_INDEX_TREND_SCORE_OVERALL_FAILURE: 'GET_INDEX_TREND_SCORE_OVERALL_FAILURE',

    GET_CATEGORY_BY_ID_REQUEST:'GET_CATEGORY_BY_ID_REQUEST',
    GET_CATEGORY_BY_ID_SUCCESS: 'GET_CATEGORY_BY_ID_SUCCESS',
    GET_CATEGORY_BY_ID_FAILURE: 'GET_CATEGORY_BY_ID_FAILURE',
};

