import React, { Component } from 'react';
import { connect } from 'react-redux';

// import Filter from './Filter';
import Filter from '../../../../../CommonComponents/Filters/IndexFilter/Filter';
import HeatMap from './Components/HeatMap';
import HeatMapDriver from './Components/HeatMapDriver';
import ViewEmpModal from './Components/ViewEmpModal';


class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      viewScore: true,
      showLeaderBoard: false,
      showFilter: false,
      showValue: '',
      XAxis: "",
      crrRiskEmpList: [],
      showViewEmpModal: false,
      crrCategory: ""
    }
  }
  componentDidMount() {

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleFilterSave = (name) => {
    this.setState({ currFilterName: name })
  }



  handleMultifilterIndexScore2 = (finalFilter, XAxis) => {
    //console.log(':----------------------------------------------------------------------------------------------------------------XAxis:-------------------->',finalFilter, XAxis)

    let { handleRiskAllEmpQueFilter } = this.props;
    this.setState({ FilterValues: finalFilter, XAxis: XAxis });
    handleRiskAllEmpQueFilter(finalFilter, XAxis);

  }


  viewEmpAtRisk = (axisValue, category) => {
    let { XAxis } = this.state;
    let { RiskgetRiskCategoryData, RiskAllEmpQuesResponsesData, RiskFilterEmpQuesResponsesData, EmpFilterData, getAllRiskEmpConversationData } = this.props;

    let nEwAxis = "";
    if (XAxis) {
      nEwAxis = XAxis;
    }
    else if (EmpFilterData && EmpFilterData.length > 0) {
      nEwAxis = EmpFilterData[0].name;
    }


    function ToString(value) {
      return value ? (value.toString()).trim() : ""
    }

    function calcScore(questionDetail) {
      let sum = 0;
      if (questionDetail && questionDetail.length > 0) {
        questionDetail.forEach((ques) => {
          if (ques.qtemplate === 1) {
            sum += Number(ques.answer)
          }
        })
      }
      return sum
    }
    function getRiskMeterScore(resList, CatList, name) {
      let RiskCategory = 0
      if (CatList && CatList.length > 0 && resList && resList.length > 0) {
        CatList.forEach((category) => {
          let questionDetailFinal = resList.filter(prev => ToString(prev.riskCategoryId) === ToString(category.id));
          RiskCategory += calcScore(questionDetailFinal);
        })
      }

      let getRiskScore = Number(RiskCategory);
      let isContain = false
      if (getRiskScore >= 25 && getRiskScore < 50) {
        if (name === "Extremely_High_Risk") {
          isContain = true
        }
      }
      else if (getRiskScore >= 50 && getRiskScore < 75) {
        if (name === "High_Risk") {
          isContain = true
        }
      }
      else if (getRiskScore >= 75 && getRiskScore < 100) {
        if (name === "Borderline") {
          isContain = true
        }
      }
      else if (getRiskScore >= 100 && getRiskScore < 125) {
        if (name === "Moderate_Risk") {
          isContain = true
        }
      }
      else if (getRiskScore >= 125 && getRiskScore <= 150) {
        if (name === "No_Risk") {
          isContain = true
        }
      }


      return isContain ? getRiskScore : 0
    }
    function getRiskMeterScoreNoRisk(emp_id, name) {

      let value = 0;
      if (getAllRiskEmpConversationData && getAllRiskEmpConversationData && getAllRiskEmpConversationData.list.length > 0) {
        let getIndexRMC = getAllRiskEmpConversationData.list.findIndex(prev => prev.emp_id === emp_id);
        let getIndexRMC2 = getAllRiskEmpConversationData.list.findIndex(prev => prev.riskCategory === name);
        if ((getIndexRMC !== -1) && (getIndexRMC2 !== -1)) {
          value = getAllRiskEmpConversationData["list"][getIndexRMC].riskscore;
        }
      }
      return value ? value.toString() + " (Resolved)" : 0
    }



    //console.log('<<<<<<<<<<<<<<<<<<< ------------------- wwww axisValue',axisValue);
    //console.log('<<<<<<<<<<<<<<<<<<< ------------------- wwww category',category);


    function getNoRiskResolvedCheck(emp_id, category) {

      let resolvedCategoryOne = true;


      let value = null;
      if (getAllRiskEmpConversationData && getAllRiskEmpConversationData && getAllRiskEmpConversationData.list.length > 0) {
        let getIndexRMC = getAllRiskEmpConversationData.list.findIndex(prev => prev.emp_id === emp_id);
        if (getIndexRMC !== -1) {
          value = getAllRiskEmpConversationData["list"][getIndexRMC].riskCategory;
        }
      }

      if (value) {
        if (category.toString() !== value.toString()) {
          resolvedCategoryOne = false
        }
      }

      //console.log('<<<<<<<<<<<<<<<<<<< ------------------- resolvedCategory value',value);
      //console.log('<<<<<<<<<<<<<<<<<<< ------------------- resolvedCategory category',category);




      return resolvedCategoryOne
    }



    function getNoRiskOnlyCheck(emp_id, category) {
      let resolvedCategoryOne = false;

      if (category === "No_Risk") {


        let value = null;
        if (getAllRiskEmpConversationData && getAllRiskEmpConversationData && getAllRiskEmpConversationData.list.length > 0) {
          let getIndexRMC = getAllRiskEmpConversationData.list.findIndex(prev => prev.emp_id === emp_id);
          if (getIndexRMC !== -1) {
            value = getAllRiskEmpConversationData["list"][getIndexRMC].riskCategory;
          }
        }

        if (value) {
          if (category.toString() === value.toString()) {
            resolvedCategoryOne = true
          }
        }

        //console.log('<<<<<<<<<<<<<<<<<<< ------------------- resolvedCategory value',value);
        //console.log('<<<<<<<<<<<<<<<<<<< ------------------- resolvedCategory category',category);

      }


      return resolvedCategoryOne
    }




    let emp_id_list = [];
    if (axisValue === "overall") {
      if (RiskAllEmpQuesResponsesData && RiskAllEmpQuesResponsesData.length > 0) {
        RiskAllEmpQuesResponsesData.forEach((item) => {
          if (item && item["_id"] && getRiskMeterScore(item.responses, RiskgetRiskCategoryData, category)) {

            emp_id_list.push({
              ...item["_id"],
              "score": getRiskMeterScore(item.responses, RiskgetRiskCategoryData, category)
            })

          }
          else if (getNoRiskOnlyCheck(item["_id"]["emp_id"], category)) {
            emp_id_list.push({
              ...item["_id"],
              "score": getRiskMeterScoreNoRisk(item["_id"]["emp_id"], "No_Risk")
            })
          }

        })
      }
    } else {
      if (RiskFilterEmpQuesResponsesData && RiskFilterEmpQuesResponsesData.length > 0) {
        RiskFilterEmpQuesResponsesData.forEach((item) => {
          let itemAxisValue = item && item.responses && item.responses[0] && item.responses[0][nEwAxis] ? item.responses[0][nEwAxis] : "";
          if (itemAxisValue === axisValue) {
            if (item && item["_id"] && getRiskMeterScore(item.responses, RiskgetRiskCategoryData, category)) {

              emp_id_list.push({
                ...item["_id"],
                "score": getRiskMeterScore(item.responses, RiskgetRiskCategoryData, category)
              })

            }
            else if (getNoRiskOnlyCheck(item["_id"]["emp_id"], category)) {
              emp_id_list.push({
                ...item["_id"],
                "score": getRiskMeterScoreNoRisk(item["_id"]["emp_id"], "No_Risk")
              })
            }
          }
        });
      }
    }

    let newList = []
    if (emp_id_list && emp_id_list.length > 0) {
      emp_id_list.forEach((item) => {
        if (getNoRiskResolvedCheck(item["emp_id"], category)) {
          newList.push(item)
        }

      })
    }

    this.setState({ crrRiskEmpList: newList, showViewEmpModal: true, crrCategory: category })

  }

  closeCategoryModal = () => {
    this.setState({ crrRiskEmpList: [], showViewEmpModal: false, crrCategory: "" })
  }



  render() {
    let {
      DriverTable, getNoRiskResolved,handleGoBack,
      handleRedictToProfile, RiskgetRiskCategoryData, RiskFilterEmpQuesResponsesData, EmpFilterData } = this.props;

    let { crrCategory, currFilterName, XAxis, crrRiskEmpList, showViewEmpModal } = this.state;

    //-------------------------------------------------------------------------------------------------------------------------

    let nEwAxis = "";
    if (XAxis) {
      nEwAxis = XAxis;
    }
    else if (EmpFilterData && EmpFilterData.length > 0) {
      nEwAxis = EmpFilterData[0].name;
    }





    let { getIndexFilterData, riskMeterCountObj, overallCategoryScore } = this.props;



    function ToString(value) {
      return value ? (value.toString()).trim() : ""
    }

    function calcScore(questionDetail) {
      let sum = 0;
      if (questionDetail && questionDetail.length > 0) {
        questionDetail.forEach((ques) => {
          if (ques.qtemplate === 1) {
            sum += Number(ques.answer)
          }
        })
      }
      return sum
    }

    function getRiskMeterScore(resList, CatList) {
      let RiskCategory = 0;
      if (CatList && CatList.length > 0 && resList && resList.length > 0) {
        CatList.forEach((category) => {
          let questionDetailFinal = resList.filter(prev => ToString(prev.riskCategoryId) === ToString(category.id));
          RiskCategory += calcScore(questionDetailFinal);
        })
      }
      return Number(RiskCategory)
    }





    function getRiskCategoryScore(resList, CatList) {
      let RiskCategoryScore = {}
      if (CatList && CatList.length > 0 && resList && resList.length > 0) {
        CatList.forEach((category) => {
          let questionDetailFinal = resList.filter(prev => ToString(prev.riskCategoryId) === ToString(category.id));
          RiskCategoryScore[category.name] = calcScore(questionDetailFinal);

        })
      }
      return RiskCategoryScore
    }



    function getAxisValue(item, nAxis) {
      return (item && item._id && item._id[nAxis] ? item._id[nAxis] : "")
    }
    let AnswerObjList = [];
    let DemographicValues = [];
    let DemographicValuesObj = {};



    //console.log('/////RiskFilterEmpQuesResponsesData--->',RiskFilterEmpQuesResponsesData);

    if (RiskFilterEmpQuesResponsesData && RiskFilterEmpQuesResponsesData.length > 0) {
      RiskFilterEmpQuesResponsesData.forEach((item) => {
        let getIndex = DemographicValues.findIndex(prev => prev === getAxisValue(item, nEwAxis));
        if (getIndex === -1 && getAxisValue(item, nEwAxis)) {
          DemographicValues.push(getAxisValue(item, nEwAxis));
          DemographicValuesObj[getAxisValue(item, nEwAxis)] = {
            "Extremely_High_Risk": 0,
            "High_Risk": 0,
            "Borderline": 0,
            "Moderate_Risk": 0,
            "No_Risk": 0
          }
        }

        let code = getAxisValue(item, "emp_id") + getAxisValue(item, nEwAxis);

        let getIndex1 = AnswerObjList.findIndex(prev => prev.code === code);
        if (getIndex1 === -1) {
          AnswerObjList.push({
            "emp_id": getAxisValue(item, "emp_id"),
            "name": getAxisValue(item, nEwAxis),
            "value": getRiskMeterScore(item.responses, RiskgetRiskCategoryData),
            "code": getAxisValue(item, "emp_id") + getAxisValue(item, nEwAxis),
            "driver": getRiskCategoryScore(item.responses, RiskgetRiskCategoryData)
          });
        }
        else {
          AnswerObjList[getIndex1].value += getRiskMeterScore(item.responses, RiskgetRiskCategoryData);
        }


      })



    }
    //console.log('/////AnswerObjList--->',AnswerObjList);


    return (
      <div className='space-y-6'>


        <div className='flex items-center space-x-4'>
          <span onClick={()=>handleGoBack()} class="cursor-pointer material-symbols-outlined bg-blue-100 p-2 rounded-full text-blue-500 ">arrow_back</span>
          <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Drivers leading to unhappiness</h1>
        </div>

        <Filter
          showFilter={this.state.showFilter}
          showValue={this.state.showValue}
          closeFilter={this.closeFilter}
          EmpFilterData2={EmpFilterData}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        />

        <div onClick={() => this.closeFilter(false)} className="">
          <div className="flex w-full" >

            <div className="w-full px-4 bg-white border rounded">
              <div className="flex items-center justify-between px-1 py-4 text-base font-semibold text-gray-700">
                <h1 className="py-4 text-lg font-semibold">{DriverTable ? "Drivers leading to unhappiness" : "Demographic Risk Insight"}
                  {/* <p className="text-sm font-semibold text-gray-500">NPS QUESTION</p> */}
                  <p className="text-base font-semibold text-gray-700"><span className="text-blue-500">{(currFilterName !== 'none') ? "( " + currFilterName + " wise )" : ""}</span></p>
                </h1>
              </div>
              {DriverTable ?
                <>
                  <HeatMapDriver
                    // AnswerObjOverall={AnswerObjOverall}
                    DemographicValues={DemographicValues}
                    DemographicValuesObj={DemographicValuesObj}
                    XAxis={XAxis}
                    riskMeterCountObj={riskMeterCountObj}


                    overallCategoryScore={overallCategoryScore}
                    RiskgetRiskCategoryData={RiskgetRiskCategoryData}

                    AnswerObjList={AnswerObjList}
                    viewEmpAtRisk={this.viewEmpAtRisk}
                  />
                </>
                :
                <>
                  <HeatMap
                    // AnswerObjOverall={AnswerObjOverall}
                    DemographicValues={DemographicValues}
                    DemographicValuesObj={DemographicValuesObj}
                    XAxis={XAxis}

                    riskMeterCountObj={riskMeterCountObj}
                    AnswerObjList={AnswerObjList}
                    viewEmpAtRisk={this.viewEmpAtRisk}
                    getNoRiskResolved={getNoRiskResolved}
                  />
                </>}



            </div>

          </div>
        </div>


        {showViewEmpModal ?
          <ViewEmpModal
            crrRiskEmpList={crrRiskEmpList}
            crrCategory={crrCategory}
            handleRedictToProfile={handleRedictToProfile}
            closeModal={this.closeCategoryModal}
          />
          : null}






      </div>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
