import React, { Component } from 'react';
import { connect } from 'react-redux';
import DimensionPriorities from './Components/DimensionPriorities/DimensionPriorities';
import TrendDistribution from './Components/TrendDistribution/TrendDistribution';

class IndexMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 1
    }
  }
  componentDidMount() {

  }

  handleOptions = (option) => {
    let { onOverallAnalysis, onTrendDistribution } = this.props;
    this.setState({ optionType: option })
    if (option === 1) {
      onOverallAnalysis()
    } else if (option === 2) {
      onTrendDistribution()
    };
  }

  onSwitchOptionScreen = () => {
    let { GetParameterLabel, CountRestrict, getEMPFilterLabel,
      loading, EmpFilterData, getIndexByFilterDMGData, getCommentData, ErrorIndexObj,
      handleEngQuesResponse, LifecycleEngQuesResponsesData, LifecycleEngamentIndexV2Data


    } = this.props;

    let { optionType } = this.state;
    switch (optionType) {


      case 1: return <DimensionPriorities
        LifecycleEngQuesResponsesData={LifecycleEngQuesResponsesData}
        ErrorIndexObj={ErrorIndexObj}
        getIndexByFilterDMGData={getIndexByFilterDMGData}

        getEMPFilterLabel={getEMPFilterLabel} CountRestrict={CountRestrict} GetParameterLabel={GetParameterLabel} loading={loading}
        getCommentData={getCommentData}

        EmpFilterData={EmpFilterData}
        handleEngQuesResponse={handleEngQuesResponse}
      />

      case 2: return <TrendDistribution
        LifecycleEngamentIndexV2Data={LifecycleEngamentIndexV2Data}
        ErrorIndexObj={ErrorIndexObj}
        getIndexByFilterDMGData={getIndexByFilterDMGData}

        getEMPFilterLabel={getEMPFilterLabel} CountRestrict={CountRestrict} GetParameterLabel={GetParameterLabel} loading={loading}
        getCommentData={getCommentData}

        EmpFilterData={EmpFilterData}
        handleEngQuesResponse={handleEngQuesResponse}
      />


      default: return <div>Default Option</div>
    }
  }

  render() {
    let { optionType } = this.state;

    let OptionList = [
      {
        "name": "Engagement Behaviour Item",
        "template": 1
      },
      {
        "name": "Engagement Distribution Trend",
        "template": 2
      }
    ]

    return (
      <>
        {/* side bar end */}
        <div className="flex-1 w-full p-4 space-y-4 overflow-y-auto bg-[#F9FAFB] md:p-6">
        <div className="flex items-center justify-between text-gray-800">
          <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Overall Analysis</h1>
          </div>
          {/*sub nav bar 1 */}
          <div className="items-center justify-between border-b md:flex">
            <div className="flex space-x-2 overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 whitespace-nowrap tab-scroll scollar-xs">

              {OptionList && OptionList.length > 0 ?
                OptionList.map((item) =>
                  <span onClick={() => this.handleOptions(item.template)}
                    className={optionType === item.template ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                    "hover:bg-[#F9FAFB] transition-all duration-150   cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}>
                    {item.name}
                  </span>
                ) : null}

            </div>
          </div>

          {this.onSwitchOptionScreen()}

        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexMain);
