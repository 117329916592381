import React from "react";
import moment from "moment";

export default function AssessmentScore(props) {
  let {
    crrSelectedEmp,
    EditDetailsFn,
    EditButtonFn,
    createdAt,
    hindiPDFstatic
  } = props;

  const cirleColor = (element) => {
    return (
      <span
        style={{
          background:
            element && element.colorCode ? element.colorCode : "#DDDDDD",
        }}
        className={"w-5 h-5 rounded-full "}
      />
    );
  };

  const barWidthColor = (element) => {
    return (
      <span
        style={{
          background:
            element && element.colorCode ? element.colorCode : "#DDDDDD",
        }}
        className={"w-5 h-5 rounded-full "}
      />
    );
  };

  const shortSummary = (element, index) => {
    //console.log("element--->",element)

    return (
      <div className="flex items-center px-2 space-x-4">
        {barWidthColor(element)}

        <div className="flex">
          <p className="text-base font-normal text-[#3D405B]">
            <b className="pr-2 text-[#3D405B]">
              {(index + 1).toString() + ". "}
              {EditDetailsFn(element, "dimension", element.dimension)}:
            </b>
            {/* {element &&
            element.details &&
            element.details.desc
              ? element.details.desc
              : ""} */}
            {EditDetailsFn(element, "desc", element.details.desc)}
          </p>
        </div>
      </div>
    );
  };

  let textList1 = [
    "Read every word of the report. Reflect on it and understand your uniqueness. Think about a time when your talent was in use and how it affected your performance. Keep making notes on the same.",
    "Focus on the Dominant themes as it has a huge influence on your behaviour. It not only allows you to achieve more but also creates your personal brand. People see your behaviour but you can now see from where this behaviour comes and thus help you create an authentic perception of yourself.",
    "You should also give attention to your non-dominant talent themes as it allows you to understand where you will need to partner with someone and what are some areas you might not be naturally gifted.",
    "Once you understand your inherent talent, try planning to use it proactively for your next task and see how it helps you be better at it.",
    "In Case you want more insights and clarity, feel free to write to us at contact@happyplus.in so that we can set up a coaching call with you to explain/clarify questions you have.",
  ];

  let textList2 = [
    "Keep your Dominant talents in front of you & before you undertake any task, think of the ways you are going to use it.",
    "Share the report with your friends and close ones and ask them to reflect it with you.",
    "Share it with your manager to help them understand what makes you tick and help you leverage it much more.",
    "Share it with your colleagues so that they can leverage unique talents which you bring on table.",
  ];



  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }



  return (
    <>
      <main ref={hindiPDFstatic[1]} className="py-10 space-y-10 bg-white">
        <section className="flex flex-col items-center justify-between space-y-10 ">
          <div className="flex flex-col items-center space-y-8 text-center">
            <div className="flex justify-center pt-2">
              <img src="/img/assessment/TalentPlusStrengths_logo.svg" className="w-52 " />
            </div>

            <div className="py-2 space-y-4 text-center">
              <h1 className="text-5xl text-[#3D405B] font-normal text-center items-center justify-center">
                {EditButtonFn("page_1_title_1", "TEAM TALENTPLUSSTRENGTHS")}
                <br />
                {EditButtonFn("page_1_title_1", "ASSESSMENT REPORT")}
              </h1>
              <h1 className="text-lg font-semibold text-[#212121]/85 italic">
                {crrSelectedEmp && crrSelectedEmp.name
                  ? crrSelectedEmp.name
                  : ""}{" "}
                {createdAt ? " | " + createdAt : ""}
              </h1>
            </div>

            <div className="flex justify-center pt-6">
              <img
                src="/img/talentplus_assessment_report.png"
                className="w-full mx-auto"
              />
            </div>
            <div className="text-center">
              <p className="text-lg text-[#212121] font-medium">
                {EditButtonFn(
                  "page_1_footer_text_1",
                  "HappyPlus Online Assessments Copyright 2024"
                )}
              </p>
              <p className="text-base font-medium text-[#212121]/70 italic">
                {EditButtonFn(
                  "page_1_confidential_text_1",
                  "This report is confidential and should not be distributed without permission"
                )}
              </p>
            </div>
          </div>
        </section>
      </main>

      <main ref={hindiPDFstatic[2]} className="p-10 px-6 space-y-6 bg-white page-break">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-semibold">
              {EditButtonFn(
                "TalentAssessmentReport",
                "Team TalentPlusStrengths Assessment Report"
              )}
            </h1>
            <p className="text-lg font-semibold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}
            </p>
          </div>

          <div className="space-y-6">
            {/* <h1 className="text-3xl font-medium text-[#3D405B]">
              {EditButtonFn(
                "page_2_title_2",
                "Your TalentPlusStrengths Assessment Report"
              )}
            </h1> */}

            <p className="text-[#212121]">
              {EditButtonFn(
                "page_2_desc_1",
                "Talents are our natural ways of thinking, feeling, and behaving. They define how we do what we do. Talents are core to our being. Successful people know their talents and are able to utilise it beautifully for the work they do and success they achieve. Knowing your talents allows you to experience flow at work and produces superior performance. Your talents make you unique and allow you to stand out amongst others. It also makes you authentic and allows you to lead a fulfilling and happy life by being who you are and not trying to be someone else. "
              )}

              <br />
              <br />
              {EditButtonFn(
                "page_2_desc_2",
                "Based on your response to the TalentPlusStrengths assessment, this report has been created for you. Idea is that you go through the result, reflect on the same and be aware of your uniqueness. Once aware, you can start utilising it proactively to add appropriate skills & knowledge to do anything you want to do in life. It also will help you understand how you think, relate, act and lead in your life. All these four buckets are the basic structure of making you successful."
              )}
            </p>

            <div className="w-full space-y-1 page-break">
              <h1 className="text-3xl font-light text-[#212121]">
                <b>{EditButtonFn("page_2_title_3", "How to go through this report")}</b>
              </h1>
            </div>

            <div className="space-y-1 text-base">
              {textList1 && textList1.length > 0 ? textList1.map((text, index6) => (
                <div className={`flex p-3 ${index6 % 2 === 0 ? 'bg-[#F5F5F5]' : 'bg-[#E2E2E2]'}`}>
                  <img src={"/img/icon/dot.png"} className="w-2 h-2 mt-2 bg-gray-900 rounded-full" />
                  <p className="mx-2">{(EditButtonFn("textList1" + (index6 + 1).toString(), text))}</p>
                </div>
              )) : null}
            </div>

            <div className="page-break">
              <div className="w-full space-y-1 page-break">
                <h1 className="text-3xl font-light text-[#212121]">
                  <b>
                    {EditButtonFn("page_2_title_4", "How to use this report")}
                  </b>
                </h1>
              </div>
            </div>

            <div className="space-y-1 text-base">
              {textList2 && textList2.length > 0 ? textList2.map((text, index6) => (
                <div className={`flex p-3 ${index6 % 2 === 0 ? 'bg-[#F5F5F5]' : 'bg-[#E2E2E2]'}`}>
                  <img src={"/img/icon/dot.png"} className="w-2 h-2 mt-2 bg-gray-900 rounded-full" />
                  <p className="mx-2">{EditButtonFn("textList2" + (index6 + 1).toString(), text)}</p>
                </div>
              )) : null}
            </div>
          </div>
        </div>
      </main>

      <main ref={hindiPDFstatic[3]} className="p-10 px-6 space-y-6 bg-white page-break">
        <div className="space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-semibold">
              {EditButtonFn(
                "TalentAssessmentReport",
                "Team TalentPlusStrengths Assessment Report"
              )}
            </h1>
            <p className="text-lg font-semibold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}
            </p>
          </div>

          <div className="w-full space-y-1 page-break" >
            <h1 className="text-3xl font-light text-[#212121]">
              <b>
                {EditButtonFn(
                  "domain_4_text",
                  "Domains of TALENTPLUS themes: "
                )}
              </b>
            </h1>
          </div>

          <div>
            <div class="px-4 py-1 font-semibold bg-gradient-to-r from-[#ffc107] to-[#ffc107]/10">
              <p class="text-md  text-[#212121] tracking-wide">{EditButtonFn("THINKINGTALENTS", "THINKING TALENTS")}</p>
            </div>
            <div className="flex items-start w-full bg-[#ffc107]/10 p-4">
              <div className="text-md">
                <p className="">
                  {EditButtonFn(
                    "THINKINGDESC",
                    "These are the talents which define your way of thinking and making a sense of the world. Using these talents, you deliberate on ideas, possibilities, and next course of action. This theme deals with intellectual abilities which is the hallmark of human progress. Thinking about themes allows us to explore our past and gaze into the future so that we can plan things in the present. Thinking talents are the strategic pathway to our future."
                  )}
                </p>
              </div>
            </div>
          </div>

          <div>
            <div class="px-4 py-1 font-semibold bg-gradient-to-r from-[#4caf50] to-[#4caf50]/10">
              <p class="text-md text-[#FFFFFF] tracking-wide"> {EditButtonFn("RELATINGTALENTS", "RELATING TALENTS")}</p>
            </div>
            <div className="flex items-start w-full bg-[#4caf50]/10 p-4">
              <div className="text-md">
                <p className="">
                  {EditButtonFn(
                    "RELATINGDESC",
                    "Human beings are social animals. We thrive with others. We need others in life to share our successes, miseries, and our condition. The ability to connect with others is what defines the possibilities of our success in life. It is the bond between one person and another, which takes us higher and ensures we come together to achieve the goals we set for ourselves, challenges we want to overcome and write history together."
                  )}
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className="px-4 py-1 font-semibold  bg-gradient-to-r from-[#2196f3] to-[#2196f3]/10">
              <p class="text-md text-[#FFFFFF] tracking-wide">{EditButtonFn("ACTINGTALENTS", "ACTING TALENTS")}</p>
            </div>
            <div className="flex items-start w-full bg-[#2196f3]/10 p-4">
              <div className="text-md">
                <p className="">
                  {EditButtonFn(
                    "ACTINGDESC",
                    "These are action talents, which allow you to make things happen. Ideas are good but the real progress takes place when we put these ideas to action. The acting talent allows us to put our thoughts into action and see the magic happen. It is these themes which make things happen and lets everyone see the progress happening. Our acting talents ensure that we make progress and finish tasks and achieve things consistently."
                  )}
                </p>
              </div>
            </div>
          </div>

          <div>
            <div class="px-4 py-1 font-semibold bg-gradient-to-r from-[#673AB7] to-[#673AB7]/10">
              <p class="text-md text-[#FFFFFF] tracking-wide">{EditButtonFn("LEADINGTALENTS", "LEADING TALENTS")}</p>
            </div>
            <div className="flex items-start w-full bg-[#673AB7]/10 p-4">
              <div className="text-md">
                <p className="">
                  {EditButtonFn(
                    "EMOTIONSDESC",
                    "These are the talents, which defines our leadership. Leadership is not a position but it is a mindset, through which people share their vision with others, get them aligned and motivated and ensure work takes place towards that desired future. Leaders are the people responsible for better a tomorrow, so they not only have to keep looking at the things to come but also make that dream a reality with the help of others."
                  )}
                </p>
              </div>
            </div>
          </div>

        </div>
      </main >

    </>
  );
}
