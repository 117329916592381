import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionScore from './Charts/QuestionScore';
// import QuestionStack from './Charts/QuestionStack';
import Filter from './Filter';

class QuestionAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue:'none',
      currFilterName:'Department'
    }
  }
  componentDidMount() {

  }

  handleFilterSave=(name)=>{
    this.setState({currFilterName:name});
    let { handleQuestionMeanScore } = this.props;
       handleQuestionMeanScore(name);

  }

  handleFilterValueSave=(name,value)=>{
    this.setState({currFilterValue:value});
    let { handleQuestionMeanFilter } = this.props;
    // if(name !=='none' && value !=='none'){
    //   handleQuestionMeanFilter(name,value)
    // }
  }

  render() {
    let { getIndexFilterData, cardType,ItemSaveData,EmpFilterData,handleDemographicOverallQFilter, getMeanScoreQwiseData } = this.props;
    let { currFilterName, currFilterValue } = this.state;

    console.log('getMeanScoreQwiseData:',getMeanScoreQwiseData);

    let indexName = '';
    if(ItemSaveData && ItemSaveData.name){
      indexName = ItemSaveData.name;
    }
    

    let FilterValueList = []
    if(getMeanScoreQwiseData && getMeanScoreQwiseData.length>0){
      getMeanScoreQwiseData.forEach((ques)=>{
          if((FilterValueList.findIndex(prev=>prev === ques.dmgName)===-1)&&ques.dmgName){
            FilterValueList.push(ques.dmgName);
          }
      })
    }


    let FilValue = '';

    if(currFilterValue === 'none'){
      if(FilterValueList && FilterValueList.length>0){
        FilValue = FilterValueList[0];
      }
    }
    else{
      FilValue = currFilterValue;
    }



      let MeanScoreQwiseList = []
      // if(currFilterValue !== 'none'){
          if(getMeanScoreQwiseData && getMeanScoreQwiseData.length>0){
            getMeanScoreQwiseData.forEach((ques)=>{
              if(ques.dmgName === FilValue){
                if(cardType==='overall'){
                  MeanScoreQwiseList.push(ques);
                }
                else if(cardType==='index'){
                    if(ItemSaveData && ItemSaveData.name && (ques.parameterBucketName === ItemSaveData.name)){
                      MeanScoreQwiseList.push(ques);
                    }
                }
              }
            })
          }
      // }
      // else{
      //   MeanScoreQwiseList = getMeanScoreQwiseData;
      // }

      console.log('FilValue',FilValue);
      console.log('FilterValueList',FilterValueList);
      console.log('MeanScoreQwiseList',MeanScoreQwiseList);

    

    let overallQuestion= {
      "score":0
    }
    if(ItemSaveData && ItemSaveData.mean){
      overallQuestion["score"] = parseFloat(ItemSaveData.mean);
    }


    return (
      <>
     <div className="xl:flex lg:flex md:flex justify-end items-center">


          <Filter 
                    EmpFilterData = {EmpFilterData}
                    
                    getIndexFilterData={getIndexFilterData}

                    handleDemographicFilter={handleDemographicOverallQFilter}

                    handleFilterSave={this.handleFilterSave}

                    handleFilterValueSave={this.handleFilterValueSave}

                    FilterValueList={FilterValueList}

                    FilValue={FilValue}
        
                  />

        </div>  

    
          <div className="container mx-auto px-2 mt-2">
          <div className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 xl:grid-cols-1  gap-2">           
          <div className="bg-white rounded w-full shadow-lg px-2">
        <>
             {MeanScoreQwiseList && MeanScoreQwiseList.length>0?
              <div className="py-2  whitespace-nowrap  ">
                    <table className="border-collapse w-full ">
                      <thead>
                        <tr className="text-xs text-left">
                        <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300">
                          {cardType==="overall"?"Index":(indexName==="OrganizationCore"?"Driver":"Dimesions")}
                        </th>
                          <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300">Question</th>
                          <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300">Mean</th>
                        </tr>
                      </thead>
                      <tbody>
                      {/* <tr className="bg-white  mb-10 lg:mb-0 text-xs text-gray-800">
                              <td className="tooltip-response-question1 px-2 border border-b w-2/12"></td>
                              <td className="tooltip-response-question1 px-2 border border-b w-2/12">                             
                              <div className=" cursor-pointer" style={{width:'20%'}}>{"Overall Question Mean"}</div>
            
                              </td>
                              <td className=" border border-b w-10/12" >      
                                <div className="tooltip-response1">
                                  <QuestionScore question={overallQuestion} valueType={"overall"}/>
                                </div>
                              </td>
                        </tr> */}

                        {MeanScoreQwiseList && MeanScoreQwiseList.length>0?
                          MeanScoreQwiseList.map((question,index)=>
                            <tr key={index} className="bg-white  mb-10 lg:mb-0 text-xs text-gray-800">
                              <td className="tooltip-response-question1 px-2 border border-b w-2/12">                             
                              <div className=" cursor-pointer" style={{width:'20%'}}>{(question && question.parameterDimensionName)?question.parameterDimensionName:"-"}</div>
                              </td>
                              <td className="tooltip-response-question1 px-2 border border-b w-2/12">                             
                              <div className=" cursor-pointer" style={{width:'20%'}}>{(question && question.questionName && question.questionName.length>55)?question.questionName.slice(0,55)+'...':question.questionName}</div>
                                {/* <div className="tooltip-response-question2">
                                  {question.name}
                                </div> */}
                              </td>
                              <td className=" border border-b w-10/12" >      
                                <div className="tooltip-response1">
                                  <QuestionScore question={question} />
                                </div>
                              </td>
                            </tr>
                          )
                        :null}
    
                      </tbody>
                    </table>
                  </div>
                  :
                  <div className="py-10 text-center text-lg text-gray-500">No Data Awailable</div>
                }
            </>
          </div>
      </div>
    </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionAnalysis);
