import React, { useState } from "react";
export default function AddFilter(props) {
  let [view, onAddFilter] = React.useState(false);



  let {
    closeEmployeeFilter, onEmployeeFilterDropdown, handleEmployeeColumnSelect, handleFilterCheck, createEmployeeFilter,
    onDeleteFilter, handleFilterStatus, handleFilterRank, updateformDataFilter, updatehandleFilterRank,

    showFilterNames, formDataFilter, getEmployeeColumnNameData, EmployeeFilterCompanyList, LanguageObj, CurrentLanguage, getEmployeeFilterListByCompanyIdData,
    onClickQuesUp, onClickQuesDown

  } = props;

  let avoidList = ["isTest", "role", "isDisable", "companyId", "mngr_id", "__v", "currentStage", "actionPlanStage", "orgRole", "isMNGRMoreThan5", "showMNGRReport"];
  console.log("getEmployeeColumnNameData===========>", getEmployeeColumnNameData)
  return (
    <>
      <div className="absolute right-0 top-12 bg-white shadow-lg  font-normal font-xs w-52 h-56 overflow-y-auto  border rounded-md lang">
        <div className="px-3 py-2">
          {/* <input type name placeholder="Search" className=" border w-full p-1.5 text-xs rounded-sm focus:outline-none text-gray-500  focus:ring-blue-500 focus:ring-1" /> */}

          {/* <input type="text" name="searchName" value={searchName} onChange={()=>handleSearch(searchName)} placeholder="Search" 
              className="w-full border p-1.5 rounded-sm  text-gray-500 mb-2 " autoComplete={false}/> */}


        </div>
        <div className="text-xs    text-gray-500">
          {getEmployeeColumnNameData && getEmployeeColumnNameData.length > 0 ?
            getEmployeeColumnNameData.map((item, index) =>
              (!avoidList.includes(item.name)) ?
                <label className="px-3 py-2 flex items-center justify-between hover:bg-gray-50 cursor-pointer"><span className="text-gray-700 font-semibold">{item.name}</span>
                  <input type="checkbox" checked={(EmployeeFilterCompanyList.findIndex(prev => prev.name === item.name) !== -1)}
                    onChange={() => { }}
                    // onClick={()=>handleEmployeeColumnSelect(item.name)} 
                    onClick={() => createEmployeeFilter(item.name)}
                    className="cursor-pointer" /> </label>
                : null
            ) : null}

        </div>
      </div>
    </>
  );
}
