import React from 'react';
import GaugeScore from './Charts/GaugeScore';


export default function ScoreBenchmark(props) {

  let {

    item,

    DistributionData,
    Benchmark,
    cronBachAlpha,
    isCrole,
    indexOverallValue,
    name
  } = props;


  //  let Benchmark ={
  //    score:0
  //  }


  //console.log('Benchmark: ',Benchmark)

  return (
    <div className='space-y-0 '> 
      <div className="flex justify-center item-center w-full">
        <GaugeScore item={item} Benchmark={Benchmark} />
      </div>
      {isCrole ?
        <div className="flex justify-center ">
          <div className="text-sm items-center text-center mx-2"><p className="text-gray-500 font-semibold">
            {indexOverallValue && indexOverallValue.toString() ? "Organization " + name + " Score: " + indexOverallValue.toString() : "NaN"}</p></div>
        </div>
        : <div />}

      <div className="flex items-center justify-center 2xl:pb-6">
        {/* 1 */}
        <div className="flex flex-wrap justify-center gap-4 text-sm text-gray-500   ">
          <div className="flex items-center "><div style={{ background: "#e53935" }} className="p-2 rounded-sm" /><p className="ml-2">Unhappy (0-48)</p></div>
          <div className="flex items-center "><div style={{ background: "#ffc107" }} className="p-2 rounded-sm" /><p className="ml-2">Moderate (49-60)</p></div>
          <div className="flex items-center "><div style={{ background: "#a2e37d" }} className="p-2 rounded-sm" /><p className="ml-2">Happier (61-66)</p></div>
          <div className="flex items-center "><div style={{ background: "#00cc66" }} className="p-2 rounded-sm" /><p className="ml-2">Happiest (67-72)
          </p>
          </div>
        </div>
      </div>

    </div>
  );
}
