import React, { Component } from "react";
import { connect } from "react-redux";
import {
  campaignActions,
  dashboardActions,
  surveyActions,
} from "../../../_actions";
import { accessModule } from "../../../_helpers";
import { CONST } from "../../../_config";
import moment from "moment";

import CreateCampaign from "./Modal/CreateCampaign";
import CampaignList from "./Components/CampaignList";
import Header from "./Header";
// import { Checkboard } from 'react-color';
import ReminderCampaign from "./Components/ReminderCampaign/ReminderCampaign";
import ConfirmSubmit from "./Components/ReminderCampaign/ConfirmSubmit";
import ConfirmCreateCampaign from "./Modal/ConfirmCreateCampaign";

import LifecycleInputCampaign from "./Modal/LifecycleInputCampaign";
import ChooseCampaignType from "./Modal/ChooseCampaignType";
import { IoMdShare } from 'react-icons/io';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper/modules';

// import 'swiper-bundle/swiper-bundle.min.css';
// import 'swiper/swiper.min.css';
// import 'swiper/components/navigation/navigation.min.css';



class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateCampaign: false,
      showReminderModal: false,
      CurrCampaignID: "",
      showReminderEmployeeFilter: false,
      empSelectRemindFilter: "",
      reminderSubmitConfirm: false,
      confirmCreateCampaignAlert: false,

      isTemplateshow: false,

      showCampaignOptions: false,
      showsurveylink: false,

      showCampaignType: false,

      campaignValidationList: [],

      campaignFormData: {
        title: "",
        subject: "",
        type: "EMAIL",
        message: "",
        emailName: "",
        campaign_type: "NORMAL",
        teamname: "",
        surveyId: this.props.match.params.id,
        employees: [],
        isTest: false,
        campaignReminderType: 0,
        campaignReminderFreq: 0,
        campaignReminderOption: 0,
        isDMGSurvey: false
      },
      reminderformData: {
        action: "Undelivered Survey",
        actionId: 1,
        title: "",
        subject: "",
        emailName: "",
        message: "",
        teamname: "",
        createdAt: "",
        type: "EMAIL"
      },

      formBatchRange: {
        label: "1-1000",
        rangeNo: 1,
        size: 1000,
      },

      storeBatchData: {
        label: "1-1000",
        rangeNo: 1,
        size: 1000,
      },

      showBatchRange: false,

      selectAll: false,
      employees: [],

      EmpIndexA: 0,
      EmpIndexB: 6,

      currentEmpPage: 1,

      selectAllEmp: false,

      showEmployeeFilter: false,

      empSelectFilter: {
        name: "Show All",
        template: 1,
      },

      showSidebar: false,
      CrrRoute: "survey",
      crrSurvey: "",

      CurrentLanguage: "en",
      CurrentLanguageName: "English",
      showLanguageModal: false,

      currFilterLevel: "Select Filter",
      showLevelFilter: false,
      currFilterValue: "Select Value",
      showValueFilter: false,

      employeeValueSearch: "",

      recursiveSettings: {
        date: "",
        frequency: 15,
        isLaunchNow: true,
        recursiveType: "RECURSIVE_NORMAL",
      },
    };
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getCompanyNameOnly());

    this.props.dispatch(dashboardActions.getClientProfile());

    let data = {
      surveyId: this.props.match.params.id,
    };
    this.props.dispatch(surveyActions.getSurveyById(data));

    let data1 = {
      surveyId: this.props.match.params.id,
    };
    this.props.dispatch(campaignActions.getCampaignListBySurveyID(data1));

    this.props.dispatch(campaignActions.getCampaignTemplateList());




    // let data2 = {
    //   "keyWord": "",
    //   "pageNo": 1
    // }
    // // this.props.dispatch(campaignActions.getAllEmployees(data2));

    let data5 = {
      rangeNo: 1,
      size: this.state.formBatchRange.size,
    };
    this.props.dispatch(surveyActions.getEmployeeBatchRange(data5));

    let data52 = {
      rangeNo: 1,
      size: this.state.formBatchRange.size,
    };
    // this.props.dispatch(surveyActions.getCustomerBatchRange(data52));

    let data3 = {
      keyWord: "",
      pageNo: 1,
      size: 100,
    };

    this.props.dispatch(surveyActions.getLanguageLabel(data3));
    this.props.dispatch(surveyActions.getLanguage(data3));

    this.props.dispatch(dashboardActions.getEmpFilter());
    this.props.dispatch(
      surveyActions.getIndexByFilterDMG({
        filters: [],
      })
    );
  }

  handleReminderFreqInc = (isInc) => {
    let { campaignFormData } = this.state;
    if (isInc) {
      if (campaignFormData["campaignReminderFreq"] < 50) {
        campaignFormData["campaignReminderFreq"] += 1;
      }
    } else {
      if (campaignFormData["campaignReminderFreq"] > 0) {
        campaignFormData["campaignReminderFreq"] -= 1;
      }
    }
    this.setState({});
  };

  onpenSurveyLinkmodal = () => {
    this.setState({
      showsurveylink: true,
    });
  };

  closeSurveyLinkmodal = () => {
    this.setState({
      showsurveylink: false,
    });
  };

  handleEmpDropdown = (check) => {
    this.setState({ showEmployeeFilter: !check });
  };

  handleEmpFilterSelect = (data) => {
    this.setState({
      empSelectFilter: data,
      showEmployeeFilter: false,
    });
  };

  prevEmpPage = () => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (EmpIndexA > 0) {
      this.setState({ EmpIndexA: EmpIndexA - 1, EmpIndexB: EmpIndexB - 1 });
    }
  };
  nextEmpPage = (IndexListIndex) => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (
      IndexListIndex &&
      IndexListIndex.length &&
      EmpIndexB < IndexListIndex.length
    ) {
      this.setState({ EmpIndexA: EmpIndexA + 1, EmpIndexB: EmpIndexB + 1 });
    }
  };

  SelectAllEmployees = (check, EmployeesList) => {
    this.setState({ selectAllEmp: !check });

    let { employees, campaignFormData } = this.state;

    this.setState({ employees }, () => {
      if (EmployeesList && EmployeesList.length > 0) {
        if (!check) {
          EmployeesList.forEach((emp) => {
            if (employees.findIndex((prev) => prev.id === emp.id) === -1) {
              employees.push({
                id: emp.id,
              });
              this.setState({ employees }, () => {
                campaignFormData["employees"] = employees;
                this.setState({ campaignFormData });
              });
            }
          });
        } else {
          employees = [];
          campaignFormData["employees"] = [];
          this.setState({ employees, campaignFormData });
        }
      }
    });
  };

  SelectEmpPage = (val) => {
    this.setState({ currentEmpPage: val });
  };

  onCreateCampaign = (type) => {
    if (type === 1) {
      this.setState({
        showCreateCampaign: true,
        showReminderModal: false,
      });
    } else if (type === 3) {
      this.setState({
        showCampaignType: true,
      });
    } else if (2) {
      this.setState({
        showCreateCampaign: false,
        showReminderModal: false,
      });
    }
  };

  handleCampaignInput = (e) => {
    let { campaignFormData } = this.state;

    let value = e.target.value;
    var newValue = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, "");

    campaignFormData[e.target.name] = newValue;
    this.setState({ campaignFormData });
  };

  handleMessageInput = (value) => {
    let { campaignFormData } = this.state;

    var newValue = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, "");

    campaignFormData["message"] = newValue;
    this.setState({ campaignFormData });
  };

  handleTestUserCheck = (value) => {
    let { campaignFormData } = this.state;
    campaignFormData["isTest"] = value;
    this.setState({ campaignFormData });
  };

  handleSendTypeCheck = (value) => {
    const { campaignFormData } = this.state;
    campaignFormData["type"] = value;
    this.setState({ campaignFormData });
  };

  handleCampaignEmployees = (emp) => {
    const { campaignFormData, employees, selectAll } = this.state;

    if (!selectAll) {
      if (employees.length > 0) {
        var matchFlag = null;
        employees.forEach((selectedEmp, empIndex) => {
          if (emp.id === selectedEmp.id) {
            matchFlag = 1;
            var data = employees;
            data.splice(empIndex, 1);

            campaignFormData["employees"] = data;
            this.setState({ campaignFormData });
          }
        });

        if (matchFlag === 1) {
          //////console.log("matchFlag = 1:---->", employees)
        } else {
          var data = employees;
          data.push({ id: emp.id });

          campaignFormData["employees"] = data;
          this.setState({ campaignFormData });

          //////console.log("matchFlag = 0:---->", employees)
        }
      } else {
        var datax = employees;

        datax.push({ id: emp.id });

        //////console.log("setZero:---->", employees)
        campaignFormData["employees"] = datax;
        this.setState({ campaignFormData });
      }
    }
  };

  handleCampaignSubmit = () => {
    let { campaignFormData } = this.state;
    let data = {
      title: campaignFormData.title,
      subject: campaignFormData.subject,
      message: campaignFormData.message,
      type: campaignFormData.type,
      emailName: campaignFormData.emailName,
      campaign_type:
        campaignFormData && campaignFormData.campaign_type
          ? campaignFormData.campaign_type
          : null,
      teamname: campaignFormData.teamname,
      surveyId: campaignFormData.surveyId,
      employees: campaignFormData.employees,
      isTest: campaignFormData && campaignFormData.isTest ? true : false,
      isDMGSurvey: campaignFormData.isDMGSurvey
    };

    // if(campaignFormData.campaign_type==="RECURSIVE"){
    //   data["recursiveDate"] = recursiveSettings && recursiveSettings.date?recursiveSettings.date:"";
    //   data["recursiveFrequency"] = recursiveSettings && recursiveSettings.frequency?recursiveSettings.frequency:0;
    //   data["recursiveIsLaunch"] = recursiveSettings && recursiveSettings.isLaunchNow?true:false;
    // }

    let validateList = [];
    let fieldNames = Object.keys(data);
    if (fieldNames && fieldNames.length > 0) {
      fieldNames.forEach((item, index) => {
        //////console.log("campaignFormData--->", campaignFormData)

        if (item === "employees") {
          if (campaignFormData.campaign_type === "NORMAL") {
            if (
              !(
                campaignFormData &&
                campaignFormData.employees &&
                campaignFormData.employees.length > 0
              )
            ) {
              validateList.push("Select Minimum 1 Employee");
            }
          }
        } else {
          if (item !== "isTest") {
            if (!(data && data[item])) {
              if (item === "title") {
                validateList.push("Title");
              } else if (item === "campaign_type") {
                validateList.push("Campaign Type");
              }

              if (campaignFormData.type === "EMAIL") {
                if (item === "subject") {
                  validateList.push("Subject");
                } else if (item === "message") {
                  validateList.push("Message");
                } else if (item === "emailName") {
                  validateList.push("Email Name");
                } else if (item === "teamname") {
                  validateList.push("Team Name");
                }
              }
            }
          }
        }
      });
    }

    if (validateList && validateList.length > 0) {
      this.setState({ campaignValidationList: validateList });
    } else {
      this.setState({ confirmCreateCampaignAlert: true }, () => {
        this.setState({ campaignValidationList: [] });
      });
    }
  };

  handleConfirmCreateCampaignMailClose = () => {
    this.setState({ confirmCreateCampaignAlert: false });
    // let data = {
    //   "title": "",
    //   "subject": "",
    //   "message": "",
    //   "type":"EMAIL",
    //   "teamname": "",
    //   "emailName":"",
    //   "campaign_type":"",
    //   "surveyId": this.props.match.params.id,
    //   "employees": []
    // }
    // this.setState({campaignFormData:data})
  };

  onCampaignSubmit = () => {
    let { campaignFormData, recursiveSettings } = this.state;

    function scriptRemover(FormData, item) {
      let withJSdata = "";
      if (FormData && FormData[item]) {
        withJSdata = FormData[item].replace(/<script.*>.*<\/script>/ims, " ");
      }
      return withJSdata;
    }

    let data = {
      title: scriptRemover(campaignFormData, "title"),
      subject: scriptRemover(campaignFormData, "subject"),
      message: scriptRemover(campaignFormData, "message"),
      type:
        campaignFormData.type && campaignFormData.type !== "NONE"
          ? campaignFormData.type
          : null,
      emailName: scriptRemover(campaignFormData, "emailName"),
      campaign_type:
        campaignFormData && campaignFormData.campaign_type
          ? campaignFormData.campaign_type
          : null,
      teamname: scriptRemover(campaignFormData, "teamname"),
      surveyId: campaignFormData.surveyId,
      employees: campaignFormData.employees,
      isTest: campaignFormData && campaignFormData.isTest ? true : false,
      isDMGSurvey:campaignFormData.isDMGSurvey
    };

    if (
      campaignFormData &&
      campaignFormData["campaignReminderOption"] &&
      campaignFormData["campaignReminderOption"] === 1
    ) {
      data["campaignReminderType"] = campaignFormData["campaignReminderType"];
      data["campaignReminderFreq"] = campaignFormData["campaignReminderFreq"];
      data["campaignReminderOption"] =
        campaignFormData["campaignReminderOption"];
    }

    if (campaignFormData.campaign_type === "RECURSIVE") {
      data["recursiveStartDate"] =
        recursiveSettings && recursiveSettings.date
          ? recursiveSettings.date
          : null; //2022-03-17 YYYY-MM-DD
      data["recursiveFrequency"] =
        recursiveSettings && recursiveSettings.frequency
          ? recursiveSettings.frequency
          : 0;
      data["recursiveIsLaunchNow"] =
        recursiveSettings && recursiveSettings.isLaunchNow ? true : false;
      data["recursiveType"] =
        recursiveSettings && recursiveSettings.recursiveType
          ? recursiveSettings.recursiveType
          : "RECURSIVE_NORMAL";
    }

    //////console.log("---------------------createNewCampaign---------------------->", data)

    this.props.dispatch(campaignActions.createNewCampaign(data));

    this.setState({
      confirmCreateCampaignAlert: false,
      showCreateCampaign: false,
      showReminderModal: false,
    });
    let data2 = {
      title: "",
      subject: "",
      message: "",
      type: "EMAIL",
      teamname: "",
      emailName: "",
      campaign_type: "NORMAL",
      surveyId: this.props.match.params.id,
      employees: [],
    };
    let data3 = {
      date: "",
      frequency: 15,
      isLaunchNow: true,
    };
    this.setState({ campaignFormData: data2, recursiveSettings: data3 });
  };

  onRecDayCampaignLaunch = () => {
    let { survey, campaign, dashboard } = this.props;
    let { getSurveyData } = survey;
    let { campaignList } = campaign;
    let { getCompanyNameOnlyData } = dashboard;
    let { campaignFormData } = this.state;
    if (
      getSurveyData &&
      getSurveyData.name &&
      (getSurveyData.isRiskSurveyType === "DEFAULTDAYS" ||
        getSurveyData.isRiskSurveyType === "DEFAULTONBOARDING") &&
      getSurveyData.value &&
      !(campaignList && campaignList.length > 0)
    ) {
      let days = Number(getSurveyData.value);
      let data = {
        surveyId: getSurveyData && getSurveyData.id ? getSurveyData.id : null,
        campaign_type: "RECURSIVEDAYS",
        recursiveType: "RECURSIVE_EMP_LOGIC",
        type: "EMAIL",

        employees: [],
        isTest: false,

        recursiveFrequency: days,
        recursiveIsLaunchNow: true,
        recursiveStartDate: null,

        title: campaignFormData["title"] ? campaignFormData["title"] : "",
        subject: campaignFormData["subject"] ? campaignFormData["subject"] : "",
        emailName: campaignFormData["emailName"]
          ? campaignFormData["emailName"]
          : "",
        message: campaignFormData["message"]
          ? campaignFormData["message"]
          : "<p>Welcome, please take this Survey</p>",

        teamname: campaignFormData["teamname"]
          ? campaignFormData["teamname"]
          : getCompanyNameOnlyData && getCompanyNameOnlyData.name
            ? getCompanyNameOnlyData.name
            : "HappyPlus",
      };
      //////console.log("recursiveDayinput-------->", data)
      this.props.dispatch(campaignActions.createNewCampaign(data));
    }
  };

  onReportCampaign = (data) => {
    let surveyId = this.props.match.params.id;
    //////console.log("onReportCampaign-------------->", data)
    if (data && data.campaign_type && data.campaign_type === "KIOSK") {
      this.props.history.push(
        `/app/campaignkioskreport/${data.id}/${surveyId}`
      );
    } else {
      this.props.history.push(`/app/campaignreport/${data.id}/${surveyId}`);
    }
  };

  onReminderCampaign = (data) => {
    let { reminderformData } = this.state;
    this.setState({ showReminderModal: true }, () => {
      //console.log("optionType,onReminderCampaign------>", this.state.showReminderModal);
    });
    reminderformData["title"] = data.title;
    reminderformData["subject"] = "Re: " + data.subject;
    reminderformData["emailName"] = data.emailName;
    reminderformData["message"] = data.message;
    reminderformData["teamname"] = data.teamname;
    reminderformData["type"] = "EMAIL";
    reminderformData["createdAt"] = data.createdAt;

    this.setState({ CurrCampaignID: data.id, reminderformData });

    let campaignObj = {
      surveyId: this.props.match.params.id,
      campaignId: data.id,
    };
    this.props.dispatch(
      campaignActions.getCampaignDetailsById({
        id: data.id,
      })
    );
    this.props.dispatch(campaignActions.getCampaignEmployeesByID(campaignObj));
  };

  handleReminderInput = (e) => {
    let { reminderformData } = this.state;

    let value = e.target.value;
    var newValue = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, "");

    reminderformData[e.target.name] = newValue;
    this.setState({ reminderformData });
  };

  handleReminderMessageInput = (value) => {
    let { reminderformData } = this.state;
    var newValue = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, "");

    reminderformData["message"] = newValue;
    this.setState({ reminderformData });
  };

  handleEmpReminderDropdown = (check) => {
    this.setState({ showReminderEmployeeFilter: !check });
  };

  handleEmpRemindFilterSelect = (value) => {
    //console.log("handleEmpRemindFilterSelect", value);
    let { reminderformData } = this.state;
    reminderformData["action"] = value.type;
    reminderformData["actionId"] = value.id;
    this.setState({ reminderformData, showReminderEmployeeFilter: false });
  };

  handleReminderSubmit = () => {
    this.setState({ reminderSubmitConfirm: true });
  };

  handleConfirmReminderMailClose = () => {
    this.setState({ reminderSubmitConfirm: false });
  };

  onReminderSubmit = () => {
    let { reminderformData, CurrCampaignID } = this.state;

    function scriptRemover(FormData, item) {
      let withJSdata = "";
      if (FormData && FormData[item]) {
        withJSdata = FormData[item].replace(/<script.*>.*<\/script>/ims, " ");
      }
      return withJSdata;
    }

    let data = {
      campaignId: CurrCampaignID,
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      isMailDeliveryFailed: reminderformData.actionId === 2 ? false : true,
      subject: scriptRemover(reminderformData, "subject"),
      emailName: scriptRemover(reminderformData, "emailName"),
      type: reminderformData.type,
      message: scriptRemover(reminderformData, "message"),
    };

    //console.log("onReminderSubmit: ", data);
    this.props.dispatch(campaignActions.resendMailInBulk(data));
    this.setState({
      reminderSubmitConfirm: false,
      showReminderModal: false,
      showCreateCampaign: false,
    });
  };

  handleLanguage = (item) => {
    this.setState({
      CurrentLanguage: item.shortName,
      CurrentLanguageName: item.name,
      showLanguageModal: false,
    });
  };

  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  };
  routeLink = (link) => {
    this.props.history.push("/app/" + link);
    this.setState({ CrrRoute: link });
  };
  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  };

  handleCampaignOptions = (value) => {
    if (value === this.state.showCampaignOptions) {
      this.setState({ showCampaignOptions: "" });
    } else {
      this.setState({ showCampaignOptions: value });
    }
  };

  chooseCampaignOption = (item, optionType) => {
    if (optionType === 1) {
      this.onReportCampaign(item);
    } else if (optionType === 2) {
      this.onReminderCampaign(item);
    }
    //console.log("optionType,item------>", optionType, item);
    this.setState({ showCampaignOptions: "" });
  };

  handleEmpLevelDropdown = (check) => {
    this.setState({ showLevelFilter: !check, showValueFilter: false });
  };

  handleEmpValueDropdown = (check) => {
    this.setState({ showValueFilter: !check, showLevelFilter: false });
  };

  handleLevelSelect = (level) => {
    if (level === "All") {
      this.setState({
        currFilterLevel: "Select Filter",
        currFilterValue: "Select Value",
        showValueFilter: false,
        showLevelFilter: false,
      });
    } else {
      this.setState({
        currFilterLevel: level,
        currFilterValue: "Select Value",
        showLevelFilter: false,
      });
    }
  };

  handleValueSelect = (value) => {
    this.setState({ currFilterValue: value, showValueFilter: false });
  };

  selectBatchSize = (item) => {
    let { formBatchRange } = this.state;
    formBatchRange["size"] = item;
    formBatchRange["label"] = "";
    formBatchRange["rangeNo"] = 1;
    this.setState({ formBatchRange });

    // let data5 = {
    //   "rangeNo": 1,
    //   "size":item
    // }
    // this.props.dispatch(surveyActions.getEmployeeBatchRange(data5));
  };

  selectBatchRange = (item) => {
    let { campaignFormData } = this.state;

    this.setState({ storeBatchData: item });

    campaignFormData["employees"] = [];
    this.setState({ selectAllEmp: false, campaignFormData, employees: [] });

    //console.log("item; ", item.rangeNo);
    let { formBatchRange } = this.state;
    formBatchRange["label"] = item.label;
    formBatchRange["rangeNo"] = item.rangeNo;
    this.setState({ formBatchRange, showBatchRange: false }, () => {
      this.handleGetEmployees();
    });

    // campaignFormData["employees"]=[];
    // this.setState({selectAllEmp:false,campaignFormData,employees:[]});

    // //console.log('item; ',item.rangeNo);
    // let { formBatchRange } = this.state;
    // formBatchRange["label"] = item.label;
    // formBatchRange["rangeNo"] = item.rangeNo;
    // this.setState({formBatchRange,showBatchRange:false});
    // let data5 = {
    //   "rangeNo": item.rangeNo,
    //   "size":formBatchRange.size
    // }
    // this.props.dispatch(surveyActions.getEmployeeBatchRange(data5));
  };

  handleBatchRangeDropdown = (check) => {
    this.setState({ showBatchRange: check });
  };

  handleCampaignType = (value) => {
    let { campaignFormData } = this.state;
    campaignFormData["campaign_type"] = value;
    this.setState({ campaignFormData });
  };

  handleType = (value) => {
    let { campaignFormData } = this.state;
    campaignFormData["type"] = value;
    this.setState({ campaignFormData });
  };


  handleReminderModeType = (value) => {
    let { reminderformData } = this.state;
    reminderformData["type"] = value;
    this.setState({ reminderformData });
  };

  handleEmployeeValueSearch = (e) => {
    this.setState({ employeeValueSearch: e.target.value }, () => {
      let data5 = {
        keyWord: this.state.employeeValueSearch,
        pageNo: 1,
        size: 10,
      };
      this.props.dispatch(surveyActions.getEmployeeSearch(data5));
    });
  };

  handleGetEmployees = (item) => {
    let { formBatchRange, currFilterLevel, currFilterValue } = this.state;

    let data5 = {
      rangeNo: formBatchRange.rangeNo,
      size: formBatchRange.size,
    };
    if (
      currFilterLevel !== "Select Filter" &&
      currFilterValue !== "Select Value"
    ) {
      // data5["filterName"] = currFilterLevel;
      // data5["filterValue"] = currFilterValue;

      data5["filters"] = [
        {
          level: currFilterLevel,
          value: currFilterValue,
        },
      ];
    }

    //////console.log("FilterName---->", data5)

    this.props.dispatch(surveyActions.getEmployeeBatchRange(data5));
  };

  onGenerateCompanySurveyCode = () => {
    let data5 = {
      id: this.props.match.params.id,
    };

    let temp = {
      surveyId: this.props.match.params.id,
    };

    this.props.dispatch(
      dashboardActions.generateCompanySurveyCode(data5, temp)
    );
  };

  goBackFn = () => {
    let { showCreateCampaign, showReminderModal } = this.state;
    if (showReminderModal) {
      this.setState({ showReminderModal: false });

    }
    else if (showCreateCampaign) {
      this.onCreateCampaign(2);
    } else {
      this.props.history.goBack();
    }
  };

  handleRecursiveCampaignInput = (e) => {
    let { recursiveSettings } = this.state;
    recursiveSettings[e.target.name] = e.target.value;
    this.setState({ recursiveSettings });
  };

  handleCampaignRecursiveType = (check) => {
    let { recursiveSettings } = this.state;
    recursiveSettings["isLaunchNow"] = check;
    this.setState({ recursiveSettings });
  };

  handleRecursiveType = (check) => {
    let { recursiveSettings } = this.state;
    recursiveSettings["recursiveType"] = check;
    this.setState({ recursiveSettings });
  };

  gotoRecursiveDashboard = (item, type) => {
    let surveyId = this.props.match.params.id;
    let campaignId = item.id;
    if (campaignId && surveyId) {
      if (type === "rtype1") {
        this.props.history.push(
          "/app/recursivedashboardt1/" + surveyId + "/" + campaignId
        );
      } else if (type === "rtype2") {
        this.props.history.push(
          "/app/recursivedashboardt2/" + surveyId + "/" + campaignId
        );
      }
    }
  };

  handleExitSurveyAutofill = () => {
    let { survey } = this.props;
    let { getSurveyData } = survey;
    if (
      getSurveyData &&
      getSurveyData.isRiskSurveyType &&
      getSurveyData.isRiskSurveyType === "DEFAULTEXIT"
    ) {
      let { campaignFormData } = this.state;
      campaignFormData["title"] = "Exit Survey";
      campaignFormData["subject"] = "This is Exit Survey Subject";
      campaignFormData["message"] = "<p>This is Exit Survey Message</p>";
      campaignFormData["emailName"] = "Exit Survey emailName";
      campaignFormData["teamname"] = "Exit Survey teamname";

      this.setState({ campaignFormData });
    }
  };

  handleRecDaysActive = (value) => {
    let data5 = {
      surveyId: this.props.match.params.id,
      id: value,
    };
    let data5temp2 = {
      surveyId: this.props.match.params.id,
    };
    this.props.dispatch(
      surveyActions.updateActiveStatusRecCampaign(data5, data5temp2)
    );
  };

  handleCampaignReminderType = (value) => {
    let { campaignFormData } = this.state;
    campaignFormData["campaignReminderType"] = value;
    campaignFormData["campaignReminderFreq"] = 1;
    this.setState({ campaignFormData });
  };

  handleCampaignReminderOption = (value) => {
    let { campaignFormData } = this.state;
    if (value === 0) {
      campaignFormData["campaignReminderType"] = 0;
      campaignFormData["campaignReminderFreq"] = 0;
    }
    campaignFormData["campaignReminderOption"] = value;
    this.setState({ campaignFormData });
  };

  handleReminderActiveStatus = (id) => {
    let data = {
      id: id,
    };
    let dataV2 = {
      surveyId: this.props.match.params.id,
    };
    this.props.dispatch(
      campaignActions.updateReminderActiveStatus(data, dataV2)
    );
  };

  onCampaignTypeSubmit = (type) => {
    let { survey } = this.props;
    let { getSurveyData } = survey;
    if (
      getSurveyData &&
      getSurveyData.surveyCategory &&
      getSurveyData.surveyCategory.template === 2
    ) {
      let data = {
        surveyId: this.props.match.params.id,
        isAutomatic: type,
      };
      this.props.dispatch(surveyActions.setSurveyCampaignType(data));
      this.setState({ showCampaignType: false, createNewCampaign: true });
    }
  };

  closeCampaignType = () => {
    this.setState({ showCampaignType: false });
  };

  handleTemplateSave = () => {
    let { campaignFormData } = this.state;
    let data = {
      title: campaignFormData.title,
      subject: campaignFormData.subject,
      message: campaignFormData.message,
      type: campaignFormData.type,
      emailName: campaignFormData.emailName,
      campaign_type:
        campaignFormData && campaignFormData.campaign_type
          ? campaignFormData.campaign_type
          : null,
      teamname: campaignFormData.teamname,
      surveyId: campaignFormData.surveyId,
      isTest: campaignFormData && campaignFormData.isTest ? true : false,
    };

    // if(campaignFormData.campaign_type==="RECURSIVE"){
    //   data["recursiveDate"] = recursiveSettings && recursiveSettings.date?recursiveSettings.date:"";
    //   data["recursiveFrequency"] = recursiveSettings && recursiveSettings.frequency?recursiveSettings.frequency:0;
    //   data["recursiveIsLaunch"] = recursiveSettings && recursiveSettings.isLaunchNow?true:false;
    // }

    let validateList = [];
    let fieldNames = Object.keys(data);
    if (fieldNames && fieldNames.length > 0) {
      fieldNames.forEach((item, index) => {
        //////console.log("campaignFormData--->", campaignFormData)
        if (item !== "isTest") {
          if (!(data && data[item])) {
            if (item === "title") {
              validateList.push("Title");
            } else if (item === "campaign_type") {
              validateList.push("Campaign Type");
            }

            if (campaignFormData.type === "EMAIL") {
              if (item === "subject") {
                validateList.push("Subject");
              } else if (item === "message") {
                validateList.push("Message");
              } else if (item === "emailName") {
                validateList.push("Email Name");
              } else if (item === "teamname") {
                validateList.push("Team Name");
              }
            }
          }
        }
      });
    }

    if (validateList && validateList.length > 0) {
      this.setState({ campaignValidationList: validateList });
    } else {

      let { campaignFormData, recursiveSettings } = this.state;
      function scriptRemover(FormData, item) {
        let withJSdata = "";
        if (FormData && FormData[item]) {
          withJSdata = FormData[item].replace(/<script.*>.*<\/script>/ims, " ");
        }
        return withJSdata;
      }
      let dataX = {
        title: scriptRemover(campaignFormData, "title"),
        subject: scriptRemover(campaignFormData, "subject"),
        message: scriptRemover(campaignFormData, "message"),
        type:
          campaignFormData.type && campaignFormData.type !== "NONE"
            ? campaignFormData.type
            : null,
        emailName: scriptRemover(campaignFormData, "emailName"),
        campaign_type:
          campaignFormData && campaignFormData.campaign_type
            ? campaignFormData.campaign_type
            : null,
        teamname: scriptRemover(campaignFormData, "teamname"),
        isTest: campaignFormData && campaignFormData.isTest ? true : false,
      };
      if (
        campaignFormData &&
        campaignFormData["campaignReminderOption"] &&
        campaignFormData["campaignReminderOption"] === 1
      ) {
        dataX["campaignReminderType"] = campaignFormData["campaignReminderType"];
        dataX["campaignReminderFreq"] = campaignFormData["campaignReminderFreq"];
        dataX["campaignReminderOption"] =
          campaignFormData["campaignReminderOption"];
      }

      if (campaignFormData.campaign_type === "RECURSIVE") {
        dataX["recursiveStartDate"] =
          recursiveSettings && recursiveSettings.date
            ? recursiveSettings.date
            : null; //2022-03-17 YYYY-MM-DD
        dataX["recursiveFrequency"] =
          recursiveSettings && recursiveSettings.frequency
            ? recursiveSettings.frequency
            : 0;
        dataX["recursiveIsLaunchNow"] =
          recursiveSettings && recursiveSettings.isLaunchNow ? true : false;
        dataX["recursiveType"] =
          recursiveSettings && recursiveSettings.recursiveType
            ? recursiveSettings.recursiveType
            : "RECURSIVE_NORMAL";
      }
      //console.log("1111---------------------data---------------------->", dataX)
      this.props.dispatch(campaignActions.saveCampaignTemplate(dataX));
    }

  }


  handleOpenModalTemplate = () => {
    //console.log('Opening modal...');
    this.setState({ isTemplateshow: true });
  };

  handleCloseModalTemplate = () => {
    //console.log('Closing modal...');
    this.setState({ isTemplateshow: false });
  };

  handleUseTemplateSubmit = (templateData) => {
    //console.log('Template Data:111111111111111111111111111111------------>>>>>>', templateData);
    this.setState({ isTemplateshow: false });
    // Perform further actions with the templateData if needed
    let { campaignFormData } = this.state;


    let list_fields = ["subject", "title", "teamname", "campaign_type", "emailName", "message", "recursiveFrequency", "recursiveType", "reminderOption"]
    list_fields.forEach((ele) => {
      if (templateData && templateData[ele]) {
        campaignFormData[ele] = templateData[ele]
      }
    })

    this.setState({ campaignFormData, isTemplateshow: false })

  };

  handleDMGSurvey = (value) => {
    let { campaignFormData } = this.state;
    campaignFormData["isDMGSurvey"] = value;
    this.setState({ campaignFormData });
  }


  render() {
    let {
      showCampaignType,
      recursiveSettings,
      campaignValidationList,
      formBatchRange,
      showBatchRange,
      employeeValueSearch,
      isTemplateshow
    } = this.state;



    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      isAdmin: false,
      access: {},
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    //console.log("moduleAccess: ", moduleAccess);

    //let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {
      if (clientData.role === "ADMIN") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      } else if (clientData.role === "MNGR") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      } else if (clientData.role === "CROLE") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      } else if (clientData.role === "EMP") {
        //SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    // function accessAllow(item) {
    //   return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    // }

    //END -------------------------------------------------------

    let { campaign, survey, dashboard } = this.props;
    let { EmpFilterData, getCompanyNameOnlyData } = dashboard;

    let {
      campaignList,
      getCampaignEmployeesByIDData,
      getCampaignActiveHistoryData,
      getCampaignActiveLaunchDateWiseData,
      getCampaignDetailsByIdData,
      getCampaignTemplateListData,
    } = campaign;
    let {
      getIndexByFilterDMGData,
      getLanguageLabelData,
      getLanguageData,
      getSurveyData,
      getEmployeeSearchData,
      getEmployeeBatchRangeData,
      getCustomerBatchRangeData,
    } = survey;
    let {
      showCampaignOptions,
      showLanguageModal,
      CurrentLanguageName,
      CurrentLanguage,
      reminderSubmitConfirm,
      confirmCreateCampaignAlert,
      empSelectRemindFilter,
      showReminderEmployeeFilter,
      reminderformData,
      showReminderModal,
      showCreateCampaign,
      campaignFormData,
      employees,
      EmpIndexA,
      EmpIndexB,
      currentEmpPage,
      selectAllEmp,
      showEmployeeFilter,
      empSelectFilter,
    } = this.state;
    // //console.log('getCampaignEmployeesByIDData: ',getCampaignEmployeesByIDData)

    //console.log("1111---------------------getCampaignTemplateListData---------------------->", getCampaignTemplateListData)


    let IsAutomatic = false;
    if (
      getCampaignDetailsByIdData &&
      getCampaignDetailsByIdData.reminderOption &&
      Number(getCampaignDetailsByIdData.reminderOption) === 1
    ) {
      IsAutomatic = true;
    }
    //console.log("getCampaignDetailsByIdData---------->", getCampaignDetailsByIdData);
    //console.log("IsAutomatic---------->", IsAutomatic);

    let companySurveyCode = "";
    if (getSurveyData && getSurveyData.code) {
      companySurveyCode = getSurveyData.code;
    }

    let allEmployeesList = [];
    let AllEmpTotal = 0;

    if (employeeValueSearch) {
      allEmployeesList =
        getEmployeeSearchData &&
          getEmployeeSearchData.list &&
          getEmployeeSearchData.list.length > 0
          ? getEmployeeSearchData.list
          : [];
      AllEmpTotal =
        getEmployeeSearchData && getEmployeeSearchData.total
          ? getEmployeeSearchData.total
          : 0;
    } else {
      allEmployeesList =
        getEmployeeBatchRangeData &&
          getEmployeeBatchRangeData.list &&
          getEmployeeBatchRangeData.list.length > 0
          ? getEmployeeBatchRangeData.list
          : [];
      AllEmpTotal =
        getEmployeeBatchRangeData && getEmployeeBatchRangeData.total
          ? getEmployeeBatchRangeData.total
          : 0;
    }

    let allCustomerList =
      getCustomerBatchRangeData &&
        getCustomerBatchRangeData.list &&
        getCustomerBatchRangeData.list.length > 0
        ? getCustomerBatchRangeData.list
        : [];
    // let AllCustTotal = getCustomerBatchRangeData && getCustomerBatchRangeData.total ? getCustomerBatchRangeData.total : 0;

    let loading = survey.loading || campaign.loading;

    let SurveyName = "";
    if (getSurveyData) {
      SurveyName = getSurveyData.name;
    }

    let EmployeeList = [];
    if (allEmployeesList && allEmployeesList.length > 0) {
      if (empSelectFilter.template === 2) {
        allEmployeesList.forEach((item, index) => {
          if (!item.isDisable) {
            EmployeeList.push(item);
          }
        });
      } else if (empSelectFilter.template === 3) {
        allEmployeesList.forEach((item, index) => {
          if (item.isDisable) {
            EmployeeList.push(item);
          }
        });
      } else {
        EmployeeList = allEmployeesList;
      }
    }

    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }
    let EmployeFilterList = [
      {
        name:
          LanguageObj && LanguageObj["show_all_" + CurrentLanguage]
            ? LanguageObj["show_all_" + CurrentLanguage]
            : "Show All",
        template: 1,
      },
      {
        name:
          LanguageObj && LanguageObj["active_" + CurrentLanguage]
            ? LanguageObj["active_" + CurrentLanguage]
            : "Active",
        template: 2,
      },
      {
        name:
          LanguageObj && LanguageObj["inactive_" + CurrentLanguage]
            ? LanguageObj["inactive_" + CurrentLanguage]
            : "InActive",
        template: 3,
      },
    ];

    let actionList = [];

    if (IsAutomatic) {
      actionList = [
        {
          id: 3,
          type:
            LanguageObj &&
              LanguageObj["reminder_to_not_delivered_" + CurrentLanguage]
              ? LanguageObj["reminder_to_not_delivered_" + CurrentLanguage]
              : "Undelivered Survey",
        },
      ];
    } else {
      actionList = [
        {
          id: 2,
          type:
            LanguageObj &&
              LanguageObj["reminder_to_incomplete_" + CurrentLanguage]
              ? LanguageObj["reminder_to_incomplete_" + CurrentLanguage]
              : "Incomplete Survey",
        },
        {
          id: 3,
          type:
            LanguageObj &&
              LanguageObj["reminder_to_not_delivered_" + CurrentLanguage]
              ? LanguageObj["reminder_to_not_delivered_" + CurrentLanguage]
              : "Undelivered Survey",
        },
      ];
    }

    let { ClientProfileData } = this.props.dashboard;
    if (
      ClientProfileData &&
      ClientProfileData.role &&
      ClientProfileData.role === "MNGR"
    ) {
      this.props.history.push(`/app/manageractionplan`);
    } else if (
      ClientProfileData &&
      ClientProfileData.role &&
      ClientProfileData.role === "EMP"
    ) {
      this.props.history.push(`/app/employeeactionplan`);
    }

    let { currFilterLevel, showLevelFilter, currFilterValue, showValueFilter } =
      this.state;

    // //console.log('currFilterLevel: ',currFilterLevel);
    // //console.log('confirmCreateCampaignAlert: ',confirmCreateCampaignAlert);

    // //console.log("formBatchRange: ", formBatchRange);

    // let campaignTypeList = ['RECURSIVE', 'NORMAL', 'EVENT', 'SEGMENT', 'CUSTOMER','KIOSK'];
    // let campaignTypeList = ['NORMAL','CUSTOMER','KIOSK'];
    let campaignTypeList = ["NORMAL", "CUSTOMER", "KIOSK", "RECURSIVE"];

    let campaignReminderTypeList = [
      {
        label: "No Reminder",
        value: 0,
      },
      {
        label: "7 Days",
        value: 7,
      },
      {
        label: "15 Days",
        value: 15,
      },
      {
        label: "30 Days",
        value: 30,
      },
    ];

    let campaignReminderOptionList = [
      {
        label: "MANUAL",
        value: 0,
      },
      {
        label: "AUTOMATIC",
        value: 1,
      },
    ];

    let TypeList = ["EMAIL", "MOBILE", "WHATSAPP", "NONE"];

    // let campaignTypeList = ['NORMAL'];

    //////console.log("---EmpFilterData---->", EmpFilterData)
    console.log("---campaignFormData---->", this.state.campaignFormData)

    let campaignRecursiveFreqList = [
      {
        label: "Launch Now",
        value: true,
      },
      {
        label: "Select Launch Date",
        value: false,
      },
    ];

    let campaignRecursiveTypeList = [
      {
        label: "RECURSIVE NORMAL",
        value: "RECURSIVE_NORMAL",
      },
      {
        label: "RECURSIVE LIFECYCLE",
        value: "RECURSIVE_EVENT",
      },
    ];
    // {
    //   "label":"RECURSIVE EVENT",
    //   "value":"RECURSIVE_EVENT"
    // }

    // let SurveyisExpire = '';
    let SurveyexpireTime = "";

    if (getSurveyData) {
      SurveyName = getSurveyData.name;
      // SurveyisExpire = getSurveyData.isExpire ? true : (moment(moment.unix(getSurveyData.expireTime / 1000).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) ? true : false);
      SurveyexpireTime =
        getSurveyData && getSurveyData.expireTime
          ? moment.unix(getSurveyData.expireTime / 1000).format("DD MMM YYYY")
          : moment().format("DD MMM YYYY");
    }

    let isRecursiveDays = 0;
    if (
      getSurveyData &&
      getSurveyData.surveyCategory &&
      getSurveyData.surveyCategory.template === 2
    ) {
      if (
        getSurveyData &&
        getSurveyData.isAutomatic &&
        getSurveyData.isAutomatic === 2
      ) {
        isRecursiveDays = 2;
      } else if (
        getSurveyData &&
        getSurveyData.isAutomatic &&
        getSurveyData.isAutomatic === 1
      ) {
        isRecursiveDays = 1;
      }
    }
    // if (getSurveyData && (getSurveyData.isRiskSurveyType === "DEFAULTDAYS" || getSurveyData.isRiskSurveyType === "DEFAULTONBOARDING")) {
    //   isRecursiveDays = true;
    // }

    let isValidSurvey = false;
    if (getSurveyData && getSurveyData.id) {
      isValidSurvey = true;
    }



    // const settings = {
    //   dots: true,
    //   infinite: true,
    //   autoplay: true,
    //   speed: 100,
    //   slidesToShow: 2,
    //   slidesToScroll: 2
    // };

    return (

      <div className='bg-[#f9fafb] h-screen overflow-y-auto'>
        <div>
          <Header
            onLogout={this.onLogout}
            CurrentLanguageName={CurrentLanguageName}
            getLanguageData={getLanguageData}
            handleLanguage={this.handleLanguage}
            openLanguageModal={this.openLanguageModal}
            showLanguageModal={showLanguageModal}
            goBackFn={this.goBackFn}
            companyName={
              getCompanyNameOnlyData && getCompanyNameOnlyData.name
                ? getCompanyNameOnlyData.name
                : ""
            }
          />

          <div className="sticky z-20 flex items-center justify-between h-16 px-4 py-1 py-2 bg-white border-b top-14 ">
            <div className="flex space-x-2 text-gray-700">
              <h2 className="xl:text-lg text-base text-[#3D405B] truncate font-medium">
                {SurveyName}
              </h2>
            </div>

            <div className="flex items-center space-x-4 ">
              {companySurveyCode ? (
                <button
                  onClick={() => this.onpenSurveyLinkmodal()}
                  className="md:px-6 text-sm border border-[#00CD7E] uppercase rounded-md md:py-2 p-2 text-[#00CD7E] font-medium cursor-pointer flex items-center justify-center space-x-2"
                >
                  <IoMdShare className="text-[1.5rem]" />
                  <p className="hidden md:block">share link</p>
                </button>
              ) : (
                <button
                  className="bg-[#00CD7E] text-white px-2 py-1 rounded-lg"
                  onClick={() => this.onGenerateCompanySurveyCode()}
                >
                  Generate Survey Code
                </button>
              )}
              <button className=" py-2 text-sm md:block hidden rounded-full  capitalize  font-medium text-[#3D405B]">
                Exp: {SurveyexpireTime}
              </button>
            </div>
            {/* end */}
          </div>

          {isValidSurvey ? (
            <>
              {isRecursiveDays === 2 ? (
                <>
                  {showReminderModal ? (
                    <ReminderCampaign
                      reminderformData={reminderformData}
                      handleReminderInput={this.handleReminderInput}
                      handleReminderMessageInput={
                        this.handleReminderMessageInput
                      }
                      campaignList={campaignList}
                      getCampaignEmployeesByIDData={
                        getCampaignEmployeesByIDData
                      }
                      actionList={actionList}
                      showReminderEmployeeFilter={showReminderEmployeeFilter}
                      handleEmpReminderDropdown={
                        this.handleEmpReminderDropdown
                      }
                      empSelectRemindFilter={empSelectRemindFilter}
                      handleEmpRemindFilterSelect={
                        this.handleEmpRemindFilterSelect
                      }
                      handleReminderSubmit={this.handleReminderSubmit}
                      LanguageObj={LanguageObj}
                      CurrentLanguage={CurrentLanguage}
                      getCampaignDetailsByIdData={getCampaignDetailsByIdData}

                      handleReminderModeType={this.handleReminderModeType}
                      TypeList={TypeList}
                    />
                  ) : (
                    <>
                      {showCreateCampaign ? (
                        <CreateCampaign
                          handleDMGSurvey={this.handleDMGSurvey}
                          handleTemplateSave={this.handleTemplateSave}
                          campaignFormData={campaignFormData}
                          handleCampaignInput={this.handleCampaignInput}
                          handleMessageInput={this.handleMessageInput}
                          handleCampaignSubmit={this.handleCampaignSubmit}
                          allEmployeesList={EmployeeList}
                          allCustomerList={allCustomerList}
                          handleCampaignEmployees={
                            this.handleCampaignEmployees
                          }
                          employees={employees}
                          EmpIndexA={EmpIndexA}
                          EmpIndexB={EmpIndexB}
                          prevEmpPage={this.prevEmpPage}
                          nextEmpPage={this.nextEmpPage}
                          SelectEmpPage={this.SelectEmpPage}
                          currentEmpPage={currentEmpPage}
                          SelectAllEmployees={this.SelectAllEmployees}
                          selectAllEmp={selectAllEmp}
                          showEmployeeFilter={showEmployeeFilter}
                          EmployeFilterList={EmployeFilterList}
                          empSelectFilter={empSelectFilter}
                          handleEmpFilterSelect={this.handleEmpFilterSelect}
                          handleEmpDropdown={this.handleEmpDropdown}
                          handleTestUserCheck={this.handleTestUserCheck}
                          LanguageObj={LanguageObj}
                          CurrentLanguage={CurrentLanguage}
                          currFilterLevel={currFilterLevel}
                          currFilterValue={currFilterValue}
                          showLevelFilter={showLevelFilter}
                          showValueFilter={showValueFilter}
                          handleEmpLevelDropdown={this.handleEmpLevelDropdown}
                          handleEmpValueDropdown={this.handleEmpValueDropdown}
                          handleLevelSelect={this.handleLevelSelect}
                          handleValueSelect={this.handleValueSelect}
                          handleSendTypeCheck={this.handleSendTypeCheck}
                          AllEmpTotal={AllEmpTotal}
                          formBatchRange={formBatchRange}
                          selectBatchRange={this.selectBatchRange}
                          selectBatchSize={this.selectBatchSize}
                          showBatchRange={showBatchRange}
                          handleBatchRangeDropdown={
                            this.handleBatchRangeDropdown
                          }
                          campaignTypeList={campaignTypeList}
                          handleCampaignType={this.handleCampaignType}
                          employeeValueSearch={employeeValueSearch}
                          handleEmployeeValueSearch={
                            this.handleEmployeeValueSearch
                          }
                          handleGetEmployees={this.handleGetEmployees}
                          campaignValidationList={campaignValidationList}
                          EmpFilterData={EmpFilterData}
                          getIndexByFilterDMGData={getIndexByFilterDMGData}
                          recursiveSettings={recursiveSettings}
                          handleRecursiveCampaignInput={
                            this.handleRecursiveCampaignInput
                          }
                          campaignRecursiveFreqList={
                            campaignRecursiveFreqList
                          }
                          campaignRecursiveTypeList={
                            campaignRecursiveTypeList
                          }
                          handleCampaignRecursiveType={
                            this.handleCampaignRecursiveType
                          }
                          handleRecursiveType={this.handleRecursiveType}
                          handleType={this.handleType}
                          TypeList={TypeList}
                          getSurveyData={getSurveyData}
                          handleExitSurveyAutofill={
                            this.handleExitSurveyAutofill
                          }
                          campaignReminderTypeList={campaignReminderTypeList}
                          handleCampaignReminderType={
                            this.handleCampaignReminderType
                          }
                          handleReminderFreqInc={this.handleReminderFreqInc}
                          campaignReminderOptionList={
                            campaignReminderOptionList
                          }
                          handleCampaignReminderOption={
                            this.handleCampaignReminderOption
                          }
                        />
                      ) : (
                        <>
                          {campaignList && campaignList.length > 0 ? (
                            <CampaignList
                              campaignList={campaignList}
                              onReportCampaign={this.onReportCampaign}
                              onReminderCampaign={this.onReminderCampaign}
                              LanguageObj={LanguageObj}
                              CurrentLanguage={CurrentLanguage}
                              handleCampaignOptions={
                                this.handleCampaignOptions
                              }
                              showCampaignOptions={showCampaignOptions}
                              chooseCampaignOption={this.chooseCampaignOption}
                              onCreateCampaign={this.onCreateCampaign}
                              gotoRecursiveDashboard={
                                this.gotoRecursiveDashboard
                              }
                              isRecursiveDays={true}
                              handleRecDaysActive={this.handleRecDaysActive}
                              getCampaignActiveHistoryData={
                                getCampaignActiveHistoryData
                              }
                              handleReminderActiveStatus={
                                this.handleReminderActiveStatus
                              }
                              getCampaignActiveLaunchDateWiseData={
                                getCampaignActiveLaunchDateWiseData
                              }
                            />
                          ) : (
                            <>
                              {!loading ? (
                                <div className="w-full bg-white">
                                  {/*  text start*/}
                                  <div
                                    className="grid w-full grid-cols-1 gap-6 bg-gray-100 xl:grid-cols-3 lg:grid-cols-2"
                                    style={{ height: "calc(100vh - 8rem)" }}
                                  >
                                    <div
                                      className="relative flex flex-col justify-between w-full bg-white border-r xl:col-span-1 lang "
                                      style={{ height: "calc(100vh - 8rem)" }}
                                    >
                                      <LifecycleInputCampaign
                                        campaignFormData={campaignFormData}
                                        handleCampaignInput={
                                          this.handleCampaignInput
                                        }
                                        handleMessageInput={
                                          this.handleMessageInput
                                        }
                                      />

                                      <button
                                        onClick={() =>
                                          this.onRecDayCampaignLaunch()
                                        }
                                        className="sticky bottom-0 flex items-center justify-center w-full px-6 py-3 text-lg font-medium text-white uppercase bg-blue-500 focus:outline-none "
                                      >
                                        LAUNCH campaign{" "}
                                        <span className="ml-2 material-symbols-outlined">
                                          send
                                        </span>
                                      </button>
                                    </div>

                                    <div className="flex items-center justify-center xl:col-span-2 ">
                                      <div className="flex-col items-center hidden -space-x-6 space-y-16 lg:flex ">
                                        <h1 className="text-xl font-semibold text-center text-gray-800 capitalize cursor-default 2xl:text-3xl xl:text-2xl ">
                                          {" "}
                                          Launching {SurveyName}'s Campaign{" "}
                                        </h1>
                                        <img
                                          alt="icon"
                                          src="/img/launch_campaign.png"
                                          className="w-4/6 mx-auto"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div
                    className={
                      this.state.showsurveylink
                        ? " fixed h-screen bg-black bg-opacity-40 md:flex items-center     overflow-x-auto md:space-x-2 md:space-y-0 space-y-4 py-1 w-full top-0 left-0 flex items-center justify-center z-40"
                        : "hidden"
                    }
                  >
                    <div className="w-5/6 mx-auto mb-20 bg-white rounded-md xl:w-4/12 lg:w-4/6">
                      <div className="flex items-center justify-between p-4 px-6 border-b">
                        <h1 className="text-lg font-semibold text-center text-gray-800 ">
                          Survey Unique URL
                        </h1>
                        <span
                          onClick={() => this.closeSurveyLinkmodal()}
                          className="material-symbols-outlined bg-gray-100 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150"
                        >
                          close
                        </span>
                      </div>

                      <div className="p-4 space-y-2">
                        <label className="text-sm font-medium text-gray-600 capitalize">
                          survey link
                        </label>
                        <div className="flex items-center justify-between w-full bg-white border border-green-300 rounded-md ">
                          <input
                            value={(
                              CONST.SURVEY_LOGIN + companySurveyCode
                            ).toString()}
                            disabled={true}
                            className="w-full p-2 text-green-500 select-all cursor-text"
                          />
                        </div>
                        <div className="text-sm font-medium text-gray-500">
                          *This URL can be used by all who have no access to
                          Survey Email.
                        </div>
                      </div>

                      <div className="p-4">
                        <button className="flex items-center justify-center w-full px-4 py-3 text-base font-medium text-white uppercase bg-green-500 rounded-md ">
                          <span className="mr-2 material-symbols-outlined">
                            content_copy
                          </span>
                          Copy link
                        </button>
                      </div>
                    </div>
                  </div>

                  {showReminderModal ? (
                    <ReminderCampaign
                      reminderformData={reminderformData}
                      handleReminderInput={this.handleReminderInput}
                      handleReminderMessageInput={
                        this.handleReminderMessageInput
                      }
                      campaignList={campaignList}
                      getCampaignEmployeesByIDData={
                        getCampaignEmployeesByIDData
                      }
                      actionList={actionList}
                      showReminderEmployeeFilter={showReminderEmployeeFilter}
                      handleEmpReminderDropdown={
                        this.handleEmpReminderDropdown
                      }
                      empSelectRemindFilter={empSelectRemindFilter}
                      handleEmpRemindFilterSelect={
                        this.handleEmpRemindFilterSelect
                      }
                      handleReminderSubmit={this.handleReminderSubmit}
                      LanguageObj={LanguageObj}
                      CurrentLanguage={CurrentLanguage}
                      getCampaignDetailsByIdData={getCampaignDetailsByIdData}

                      handleReminderModeType={this.handleReminderModeType}
                      TypeList={TypeList}


                    />
                  ) : (
                    <>
                      {showCreateCampaign ||
                        (campaignList && campaignList.length > 0
                          ? false
                          : isRecursiveDays === 1) ? (
                        <CreateCampaign
                          handleDMGSurvey={this.handleDMGSurvey}
                          handleTemplateSave={this.handleTemplateSave}
                          campaignFormData={campaignFormData}
                          handleCampaignInput={this.handleCampaignInput}
                          handleMessageInput={this.handleMessageInput}
                          handleCampaignSubmit={this.handleCampaignSubmit}
                          allEmployeesList={EmployeeList}
                          allCustomerList={allCustomerList}
                          handleCampaignEmployees={
                            this.handleCampaignEmployees
                          }

                          handleOpenModalTemplate={this.handleOpenModalTemplate}
                          handleCloseModalTemplate={this.handleCloseModalTemplate}

                          employees={employees}
                          EmpIndexA={EmpIndexA}
                          EmpIndexB={EmpIndexB}
                          prevEmpPage={this.prevEmpPage}
                          nextEmpPage={this.nextEmpPage}
                          SelectEmpPage={this.SelectEmpPage}
                          currentEmpPage={currentEmpPage}
                          SelectAllEmployees={this.SelectAllEmployees}
                          selectAllEmp={selectAllEmp}
                          showEmployeeFilter={showEmployeeFilter}
                          EmployeFilterList={EmployeFilterList}
                          empSelectFilter={empSelectFilter}
                          handleEmpFilterSelect={this.handleEmpFilterSelect}
                          handleEmpDropdown={this.handleEmpDropdown}
                          handleTestUserCheck={this.handleTestUserCheck}
                          LanguageObj={LanguageObj}
                          CurrentLanguage={CurrentLanguage}
                          currFilterLevel={currFilterLevel}
                          currFilterValue={currFilterValue}
                          showLevelFilter={showLevelFilter}
                          showValueFilter={showValueFilter}
                          handleEmpLevelDropdown={this.handleEmpLevelDropdown}
                          handleEmpValueDropdown={this.handleEmpValueDropdown}
                          handleLevelSelect={this.handleLevelSelect}
                          handleValueSelect={this.handleValueSelect}
                          handleSendTypeCheck={this.handleSendTypeCheck}
                          AllEmpTotal={AllEmpTotal}
                          formBatchRange={formBatchRange}
                          selectBatchRange={this.selectBatchRange}
                          selectBatchSize={this.selectBatchSize}
                          showBatchRange={showBatchRange}
                          handleBatchRangeDropdown={
                            this.handleBatchRangeDropdown
                          }
                          campaignTypeList={campaignTypeList}
                          handleCampaignType={this.handleCampaignType}
                          employeeValueSearch={employeeValueSearch}
                          handleEmployeeValueSearch={
                            this.handleEmployeeValueSearch
                          }
                          handleGetEmployees={this.handleGetEmployees}
                          campaignValidationList={campaignValidationList}
                          EmpFilterData={EmpFilterData}
                          getIndexByFilterDMGData={getIndexByFilterDMGData}
                          recursiveSettings={recursiveSettings}
                          handleRecursiveCampaignInput={
                            this.handleRecursiveCampaignInput
                          }
                          campaignRecursiveFreqList={
                            campaignRecursiveFreqList
                          }
                          campaignRecursiveTypeList={
                            campaignRecursiveTypeList
                          }
                          handleCampaignRecursiveType={
                            this.handleCampaignRecursiveType
                          }
                          handleRecursiveType={this.handleRecursiveType}
                          handleType={this.handleType}
                          TypeList={TypeList}
                          getSurveyData={getSurveyData}
                          handleExitSurveyAutofill={
                            this.handleExitSurveyAutofill
                          }
                          campaignReminderTypeList={campaignReminderTypeList}
                          handleCampaignReminderType={
                            this.handleCampaignReminderType
                          }
                          handleReminderFreqInc={this.handleReminderFreqInc}
                          campaignReminderOptionList={
                            campaignReminderOptionList
                          }
                          handleCampaignReminderOption={
                            this.handleCampaignReminderOption
                          }
                        />

                      ) : (
                        <>
                          {campaignList && campaignList.length > 0 ? (
                            <CampaignList
                              campaignList={campaignList}
                              onReportCampaign={this.onReportCampaign}
                              onReminderCampaign={this.onReminderCampaign}
                              LanguageObj={LanguageObj}
                              CurrentLanguage={CurrentLanguage}
                              handleCampaignOptions={
                                this.handleCampaignOptions
                              }
                              showCampaignOptions={showCampaignOptions}
                              chooseCampaignOption={this.chooseCampaignOption}
                              onCreateCampaign={this.onCreateCampaign}
                              gotoRecursiveDashboard={
                                this.gotoRecursiveDashboard
                              }
                              handleReminderActiveStatus={
                                this.handleReminderActiveStatus
                              }
                            />
                          ) : (
                            <>
                              {!loading ? (
                                <div
                                  className="flex items-center justify-center w-full bg-gray-100 "
                                  style={{ height: "calc(100vh - 8rem)" }}
                                >
                                  {/*  text start*/}
                                  <div className="text-center">
                                    <img
                                      alt="icon"
                                      src="img/empty_survey.png"
                                      className="mx-auto"
                                    />
                                    {/* <h1 className="text-lg font-semibold text-gray-800 cursor-default">No Campaign has been created yet.</h1> */}
                                    {/* <p className="text-xs text-gray-500 cursor-default">Let's get started &amp; create your first campaign!</p>    */}
                                    <h1 className="text-lg font-semibold text-gray-800 cursor-default">
                                      Let's get started, create your first
                                      campaign!
                                    </h1>
                                    {getSurveyData &&
                                      getSurveyData.surveyCategory &&
                                      getSurveyData.surveyCategory.template ===
                                      2 ? (
                                      <div className="flex justify-center">
                                        <button
                                          onClick={() =>
                                            this.onCreateCampaign(3)
                                          }
                                          className="flex items-center px-6 py-3 my-6 text-sm font-medium text-white bg-blue-500 rounded-md focus:outline-none"
                                        >
                                          <span className="mr-2 material-symbols-outlined">
                                            add
                                          </span>{" "}
                                          CREATE CAMPAIGN
                                        </button>{" "}
                                      </div>
                                    ) : (
                                      <div className="flex justify-center">
                                        <button
                                          onClick={() =>
                                            this.onCreateCampaign(1)
                                          }
                                          className="flex items-center px-6 py-3 my-6 text-sm font-medium text-white bg-blue-500 rounded-md focus:outline-none"
                                        >
                                          <span className="mr-2 material-symbols-outlined">
                                            add
                                          </span>{" "}
                                          CREATE CAMPAIGN
                                        </button>{" "}
                                      </div>
                                    )}
                                  </div>
                                  {/* tex end */}
                                </div>
                              ) : null}
                            </>
                          )}
                        </>
                      )}

                      {isTemplateshow && (
                        <div className="fixed top-0 left-0 z-20 w-full h-full py-10 bg-black bg-opacity-50">
                          <div className="flex justify-end pt-2 mr-6">
                            <span onClick={() => this.handleCloseModalTemplate()} class="material-symbols-outlined bg-gray-100 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150">close</span>
                          </div>



                          <Swiper
                            cssMode={true}
                            navigation={true}
                            spaceBetween={5}
                            slidesPerView={2}
                            pagination={{
                              clickable: true,
                            }}
                            loop={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                          >
                            {getCampaignTemplateListData &&
                              getCampaignTemplateListData.length > 0 ? (
                              getCampaignTemplateListData.map((campaignElement, index) => (
                                <SwiperSlide key={index}>
                                  {/* Your template content here */}
                                  <div className="flex items-center justify-center w-full space-x-4">
                                    <div className="">
                                      <div className="w-full max-w-xl max-h-full overflow-y-auto bg-white sm:rounded-2xl">
                                        <div className="w-full p-6">
                                          <div className="space-y-4 ">
                                            <div className="space-y-1">
                                              <label className="font-medium text-gray-900 text-md">
                                                Campaign Name
                                              </label>
                                              <input
                                                type="text"
                                                name="title"
                                                value={campaignElement.title}
                                                placeholder="Campaign Name"
                                                className="w-full p-2 text-sm text-gray-900 border rounded outline-none"
                                              />
                                            </div>

                                            <div className="grid grid-cols-2 space-x-2">
                                              <div className="space-y-1">
                                                <label className="font-medium text-gray-900 text-md">
                                                  Campaign Type
                                                </label>
                                                <input
                                                  type="text"
                                                  name="title"
                                                  value={campaignElement.campaign_type}
                                                  placeholder="Campaign Type"
                                                  className="w-full p-2 text-sm text-gray-900 border rounded outline-none"
                                                />
                                              </div>

                                              <div className="pl-4 space-y-1">
                                                <label className="font-medium text-gray-900 text-md">
                                                  Campaign Mode
                                                </label>
                                                <input
                                                  type="text"
                                                  name="title"
                                                  value={campaignElement.type}
                                                  placeholder="Campaign Mode"
                                                  className="w-full p-2 text-sm text-gray-900 border rounded outline-none"
                                                />
                                              </div>
                                            </div>

                                            <div className="grid grid-cols-2 space-x-2">
                                              <div className="space-y-1">
                                                <label className="font-medium text-gray-900 text-md">
                                                  Reminder Type
                                                </label>
                                                <input
                                                  type="text"
                                                  name="title"
                                                  value={campaignElement.campaignReminderOption}
                                                  // onChange={handleCampaignInput}
                                                  placeholder="Reminder Type"
                                                  className="w-full p-2 text-sm text-gray-900 border rounded outline-none"
                                                />
                                              </div>

                                              {campaignElement &&
                                                campaignElement.campaignReminderOption &&
                                                campaignElement.campaignReminderOption === 1 ? (
                                                <>
                                                  <div className="pl-4 space-y-1">
                                                    <label className="font-medium text-gray-900 text-md">
                                                      Reminder Days
                                                    </label>
                                                    <input
                                                      type="text"
                                                      name="title"
                                                      value={campaignElement.campaignReminderType}
                                                      // onChange={handleCampaignInput}
                                                      placeholder="Reminder Days"
                                                      className="w-full p-2 text-sm text-gray-900 border rounded outline-none"
                                                    />
                                                  </div>
                                                </>
                                              ) : null}
                                            </div>

                                            {campaignElement && campaignElement.campaignReminderType ? (
                                              <div className="flex w-full space-y-1">
                                                <div className="w-6/12 mt-2">
                                                  <span className="font-medium text-gray-900 text-md ">
                                                    Reminder Frequency
                                                  </span>
                                                </div>
                                                <input
                                                  type="text"
                                                  name="title"
                                                  value={campaignElement.campaignReminderFreq}
                                                  // onChange={handleCampaignInput}
                                                  placeholder="Reminder Frequency"
                                                  className="w-full p-2 text-sm text-gray-900 border rounded outline-none"
                                                />
                                              </div>
                                            ) : null}

                                            {/* {campaignElement.type === "EMAIL" ? (
                                          <> */}
                                            <div className="grid grid-cols-2 space-x-2">
                                              <div className="space-y-1">
                                                <label className="font-medium text-gray-900 text-md">
                                                  Email Subject
                                                </label>
                                                <input
                                                  type="text"
                                                  name="subject"
                                                  value={campaignElement.subject}
                                                  // onChange={handleCampaignInput}
                                                  placeholder="Email Subject"
                                                  className="w-full p-2 text-sm text-gray-900 border rounded outline-none"
                                                />
                                              </div>
                                              <div className="pl-4 space-y-1">
                                                <label className="font-medium text-gray-900 text-md">
                                                  Email Name
                                                </label>
                                                <input
                                                  type="text"
                                                  name="emailName"
                                                  value={campaignElement.emailName}
                                                  // onChange={handleCampaignInput}
                                                  placeholder="Email Name"
                                                  className="w-full p-2 text-sm text-gray-900 border rounded outline-none"
                                                />
                                              </div>
                                            </div>

                                            <div className="space-y-1 ">
                                              <label className="font-medium text-gray-900 text-md">Email Message</label>
                                              <ReactQuill
                                                className="w-12/12"
                                                theme="snow"
                                                value={campaignElement.message}
                                                // onChange={handleMessageInput}
                                                placeholder={"Message"}
                                              />
                                            </div>

                                            <div className="grid grid-cols-2 space-x-2">
                                              {campaignElement.type === "EMAIL" ? (
                                                <>
                                                  <div className="space-y-1">
                                                    <label className="font-medium text-gray-900 text-md">
                                                      Team Name
                                                    </label>
                                                    <input
                                                      type="text"
                                                      name="teamname"
                                                      value={campaignElement.teamname}
                                                      // onChange={handleCampaignInput}
                                                      placeholder={
                                                        LanguageObj &&
                                                          LanguageObj["team_name_" + CurrentLanguage]
                                                          ? LanguageObj["team_name_" + CurrentLanguage]
                                                          : "Team"
                                                      }
                                                      className="w-full p-2 text-sm text-gray-900 border rounded outline-none"
                                                    />
                                                  </div>
                                                </>
                                              ) : null}


                                            </div>

                                            <div className="flex items-center justify-center">
                                              <button onClick={() => this.handleUseTemplateSubmit(campaignElement)} className="bg-[#2196F3] text-white py-3 px-6 text-base justify-center flex items-center uppercase font-medium rounded-md">  Use Template  <span className="px-2 material-symbols-outlined" style={{ fontSize: "1.2rem" }}> send  </span>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              ))
                            ) : null}
                          </Swiper>
                        </div>



                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : null}

          {showCampaignType ? (
            <ChooseCampaignType
              handleSubmit={this.onCampaignTypeSubmit}
              handleClose={this.closeCampaignType}
            />
          ) : null}

          {reminderSubmitConfirm ? (
            <ConfirmSubmit
              onReminderSubmit={this.onReminderSubmit}
              handleConfirmReminderMailClose={
                this.handleConfirmReminderMailClose
              }
            />
          ) : null}

          {confirmCreateCampaignAlert ? (
            <ConfirmCreateCampaign
              handleSendSingleMail={this.onCampaignSubmit}
              handleConfirmMailClose={
                this.handleConfirmCreateCampaignMailClose
              }
            />
          ) : null}
        </div>
      </div>

    );
  }
}
function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { campaign, survey, dashboard } = state;
  return {
    loggingIn,
    campaign,
    survey,
    dashboard,
  };
}
export default connect(mapStateToProps)(Campaign);
