import React, { Component } from 'react';
import { connect } from 'react-redux';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FilterName:'',
      FilterList2:[],
      selectedFilterList:[],
      FinalFilterList:[],
      StackObject:{},


      selectedFilterName:'Department',
      selectedFilterValue:'none',

      showFilterNameBy:false,
      showFilterValueBy:false

    }
  }
  componentDidMount() {
  }

  openNameDropdown=(check)=>{
    this.setState({showFilterNameBy:!check,showFilterValueBy:false})
  }
  openValueDropdown=(check)=>{
    this.setState({showFilterValueBy:!check,showFilterNameBy:false})
  }


  handleFilterName=(val)=>{
    let { handleDemographicFilter, handleFilterSave } =this.props;
    this.setState({selectedFilterName:val,showFilterNameBy:false, showFilterValueBy:false,selectedFilterValue:'none' })
    // if(val !=='none'){
    //   handleDemographicFilter(val);
    // }
    handleFilterSave(val);
  }


  handleFilterValue=(val)=>{
    let {  handleFilterValueSave } =this.props;
    let { selectedFilterName } =this.state;
    this.setState({selectedFilterValue:val, showFilterValueBy:false})

    if(selectedFilterName !=='none' && val !=='none'){
      handleFilterValueSave(selectedFilterName,val)
    }
  }



  render() {
        let { EmpFilterData,FilterValueList, getIndexFilterData, FilValue } = this.props;
        let { selectedFilterName,selectedFilterValue, showFilterNameBy,showFilterValueBy } = this.state;

        // //console.log('getIndexFilterData: ',getIndexFilterData)

        let filterNames = []
        let filterValues = []
    
        if(EmpFilterData && EmpFilterData.length>0){
          EmpFilterData.forEach((item)=>{
            filterNames.push(item.name)
          })
        }
    
        if(getIndexFilterData){
          filterValues = getIndexFilterData[selectedFilterName]
        }


        //console.log('filterValues: ',filterValues)

        let SFilValue = '';
        if(selectedFilterValue !=='none'){
          SFilValue = selectedFilterValue
        }
        else{
          SFilValue = FilValue
        }


    return (
      <>
        <div className="xl:flex lg:flex md:flex justify-start  items-center pr-2">
          <div className=" flex justify-left z-30 ">
          


            
            <div className="dropdown inline-block relative py-2 pl-2">
              <button onClick={()=>this.openNameDropdown(showFilterNameBy)} className="bg-white text-gray-700 max-w-md py-2 px-4 rounded flex justify-between items-center">
                <span className="font-semibold text-xs pr-6">{(selectedFilterName!=='none')?selectedFilterName:"Filter By"} </span> <i className="fa fa-angle-down" />
              </button>
              
              {showFilterNameBy?
                <ul className="dropdown-content absolute visible text-gray-700 w-full pt-1" style={{  }}>
                {/* {(selectedFilterName !=='none')?
                  <li onClick={()=>this.handleFilterName("none")}><a className="border border-gray-100 cursor-pointer rounded-t bg-white hover:bg-gray-100 py-2 px-4 block text-xs font-semibold " href>None</a></li>
                :null} */}
                  {filterNames && filterNames.length>0?
                    filterNames.map((item, index)=>
                      (selectedFilterName !== item)?
                        <li key={index} onClick={()=>this.handleFilterName(item)}><a  className="border border-gray-100 cursor-pointer rounded-t bg-white hover:bg-gray-100 py-2 px-4 block text-xs font-semibold " href>{item}</a></li>
                      :null
                    )
                  :null}                  
                </ul>
              :null}


            </div>


            {selectedFilterName !=='none' && filterValues && filterValues.length>0?
            <div className="dropdown inline-block relative py-2 pl-2">
              <button onClick={()=>this.openValueDropdown(showFilterValueBy)} className="bg-white text-gray-700 max-w-md py-2 px-4 rounded flex justify-between items-center">
                <span className="font-semibold text-xs pr-6">{SFilValue} </span> <i className="fa fa-angle-down" />
              </button>
              
              {showFilterValueBy?
                <ul style={{height:'400px'}} className="dropdown-content absolute visible text-gray-700 w-full pt-1 overflow-auto">

                  {filterValues && filterValues.length>0?
                      filterValues.map((item, index)=>
                        (SFilValue !== item)?
                          <li key={index} onClick={()=>this.handleFilterValue(item)}><a  className="border border-gray-100 cursor-pointer rounded-t bg-white hover:bg-gray-100 py-2 px-4 block text-xs font-semibold " href>{item}</a></li>
                        :null
                      )
                    :null}    
              
                </ul>
              :null}

            </div>
            :null}



          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(Filter);
