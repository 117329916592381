import { number } from 'echarts';
import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";


export default function GaugeScoreTwo(props) {

  let { getExitQuestionAvgData } = props;


  // console.log('MODAL__CHART_____________getExitQuestionAvgDatagetExitQuestionAvgDatagetExitQuestionAvgDatagetExitQuestionAvgData11111:::', getExitQuestionAvgData);
  let { Total, countgreater5 } = getExitQuestionAvgData && getExitQuestionAvgData[0] ? getExitQuestionAvgData[0] : {};
  let score = (countgreater5 / Total) * 100;
  // console.log('totaltotaltotaltotaltotaltotaltotaltotaltotaltotaltotaltotaltotaltotaltotaltotaltotal', Total);
  // console.log('MOdal_____scorescorescorescorescorescorescorescore???', score);

  return (
    <>
      <div className="items-center justify-center pt-1">
        <ReactSpeedometer
          height={210}
          value={score}
          minValue={0}
          maxValue={100}
          needleColor="#4db8ff"
          segments={4}
          segmentColors={[
            "#e53935",
            "#ffc107",
            "#a2e37d",
            "#00cc66"
          ]}
          ringWidth={35}
          customSegmentStops={[0, 40, 60, 100]}
        // fluidWidth={true}
        // needleTransitionDuration={3333}
        // needleTransition="easeElastic"
        />
        {/* {Benchmark && Benchmark.score || true ?
          <div className="gauge-outer">
            <div className="gauge-inner" style={{ transform: 'rotate(calc(1deg * (-45 + ' + 0 + ' * 2.50)))' }}></div>
          </div>
          : null} */}
      </div>

    </>
  );
}
