import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionScore from './Charts/QuestionScore';
import Filter from '../../../../CommonComponents/Filters/IndexFilter/Filter';
import Commentary from '../../../Index/Components/Commentary/Commentary';

class Strength extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: ''
    }
  }
  componentDidMount() {

  }


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handleMultiFilterStrenghtWeakness } = this.props;
    this.setState({ FilterValues: finalFilter });
    handleMultiFilterStrenghtWeakness(finalFilter, true);
  }

  render() {
    let { EmpFilterData, getIndexByFilterDMGData, getEMPFilterLabel, ErrorOtherObj, CountRestrict, getOrgIndexV2Data, selectedFilterValue, indexType, optionType, getCommentData, loading, GetParameterLabel,

      getQuestionData, getQuestionFavorabilityData, getQuestionFilterData, getRespondantDetailsData,

    } = this.props;

    let QuestionData = []

    if (this.state.currFilterValue === 'none') {
      QuestionData = getQuestionData
    }
    else {
      QuestionData = getQuestionFilterData
    }


    //console.log('getRespondantDetailsData--->',getRespondantDetailsData)
    //console.log('getQuestionData--->',getQuestionData)
    //console.log('getQuestionFilterData--->',getQuestionFilterData)

    let totalCount = 0;
    if (getRespondantDetailsData && getRespondantDetailsData.length > 0) {
      if (getRespondantDetailsData && getRespondantDetailsData[0] && getRespondantDetailsData[0].completed) {
        totalCount = getRespondantDetailsData[0].completed
      }
    }

    let paraList = []

    let count = 0;
    let rowSpan = 0;
    let NewParaList = []

    if (QuestionData && QuestionData.length > 0) {

      QuestionData.forEach((ques) => {
        if (paraList.findIndex(prev => prev === ques.parameterDimensionName) === -1) {
          paraList.push(ques.parameterDimensionName);
          count += 1
        }
        else {
        }
      })

      let obj = {}
      paraList.forEach((para) => {
        obj[para] = 0
        QuestionData.forEach((ques) => {
          if (para === ques.parameterDimensionName) {
            obj[para] += 1
          }
        })
      })


      // NewParaList = Object.keys(obj).map((key) => [key, obj[key]])
      //   //console.log('data NewParaList: ',NewParaList)

      // rowSpan=(QuestionData.length/count)

      // //console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,QuestionData.length)

    }
    // //console.log('ORG ==== NewParaList ===== > : ',NewParaList)
    // //console.log('ORG ==== QuestionData ===== > : ',QuestionData)

    let NewQuesList = [];
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        let total = ques['1'] + ques['2'] + ques['3'] + ques['4'] + ques['5'] + ques['6'];
        NewQuesList.push({
          ...ques,
          score: parseFloat(((ques['5'] + ques['6']) * 100 / total).toFixed(2))
        })
      })
    }


    NewQuesList.sort((a, b) => b.score - a.score);


    //console.log('ORG ==== optionType ===== > : ',indexType,optionType)


    // let isAllow = getOrgIndexV2Data? (CountRestrict(getOrgIndexV2Data && getOrgIndexV2Data["count"]?getOrgIndexV2Data["count"]:0)?false:true ): false;

    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }
    console.log('ORG ==== EmpFilterData ===== > : ', EmpFilterData)
    console.log('ORG ==== EmpFilterData2 ===== > : ', EmpFilterData2)
    return (
      <>
        {true ?
          <>

            <div className="items-center justify-between flex md:flex-row gap-4">
              <Filter
                showFilter={this.state.showFilter}
                showValue={this.state.showValue}
                closeFilter={this.closeFilter}

                EmpFilterData2={EmpFilterData2}
                getIndexFilterData={getIndexByFilterDMGData}
                handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
                getEMPFilterLabel={getEMPFilterLabel}
              />



              <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />
            </div>

            <div className="">

              <div className="w-full md:p-6 p-4 bg-white border rounded shadow-lg">
                <div className="font-medium xl:text-xl pb-4 text-lg text-[#3D405B] ">Top 10 Questions with Greatest Favourability Score :-</div>

                {ErrorOtherObj && ErrorOtherObj["getQuestionFavorabilityError"] ?
                  <>
                    <div className='my-24 text-2xl font-medium text-center text-gray-400'>{ErrorOtherObj["getQuestionFavorabilityError"]}</div>
                  </>
                  :
                  <>
                    <div className="w-full mb-4 overflow-hidden overflow-x-auto customscroll2 ">
                      {true ?
                        <table className="w-full border whitespace-nowrap ">
                          <thead>
                            <tr className="bg-gray-100 border-b text-sm  text-[#3D405B]">
                              <th className="py-2 px-2 border-r font-bold md:w-[20%] w-32">
                                <div className="flex items-center justify-left">
                                  Dimension
                                </div>
                              </th>
                              <th className="py-2 px-2 border-r font-bold md:w-[50%] w-60">
                                <div className="flex items-center justify-left">
                                  Question
                                </div>
                              </th>
                              <th className="py-2 px-4 border-r font-bold md:w-[30%] w-40">
                                <div className="flex items-center justify-left">
                                  Favorability Score
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {getQuestionFavorabilityData && getQuestionFavorabilityData.length > 0 ?
                              getQuestionFavorabilityData.map((question, index) =>
                                <tr key={index} className="bg-white  mb-10 lg:mb-0 text-sm text-[#3D405B]">
                                  <td className="px-2 border border-b w-[20%]">
                                    <div style={{ width: '20%' }}>{(question && question._id && question._id.dimension) ? GetParameterLabel(question._id.dimension) : 'Any'}</div>
                                  </td>
                                  <td className="tooltip-response-question1 px-2 border border-b w-[50%]">
                                    <div className="md:w-auto w-60 whitespace-normal">{(question && question._id && question._id.question) ? question._id.question : 'NaN'}</div>
                                    {/* <div className="tooltip-response-question2">
                              {(question && question._id &&question._id.question)?question._id.question:'NaN'}
                            </div> */}
                                  </td>
                                  <td className="border border-b w-[30%]">
                                    <div className='md:w-auto w-40'>
                                      <QuestionScore question={question} totalCount={totalCount} currFilterValue={this.state.currFilterValue} currFilterName={this.state.currFilterName} selectedFilterValue={selectedFilterValue} getQuestionFilterData={getQuestionFilterData} />
                                    </div>
                                  </td>
                                </tr>
                              )
                              : null}

                          </tbody>
                        </table>
                        :
                        <>
                          {!loading ?
                            <div className="p-4 pt-24 text-3xl text-center text-gray-400" style={{ height: '300px' }}>Data not available!</div>
                            : null}
                        </>

                      }

                    </div>
                  </>}



              </div>
            </div>
          </>
          :
          <>
            {!loading ?
              <div className="pt-40 text-3xl text-center text-gray-400">We have not calculated this section!</div>
              : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Strength);
