import React from "react";

export default function AssessmentDetails(props) {
  let { getAssessmentReportByEmpIDData, crrSelectedEmp, EditDetailsFn, EditButtonFn } = props;

  function chunkList(array) {
    const chunkSize = 8;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    //console.log("list--->", list);
    return list;
  }

  return (
    <>
  {chunkList(getAssessmentReportByEmpIDData) &&
    chunkList(getAssessmentReportByEmpIDData).length > 0
    ? chunkList(getAssessmentReportByEmpIDData).map((getList, index1) => 
      <>

      <main className="bg-white p-10 px-6 space-y-6 page-break">
        <div className="space-y-6 page-break px-12">
          <div className="flex items-center w-full justify-between  border-b py-4">
          <h1 className="text-3xl font-medium text-[#3D405B]">
              {EditButtonFn(
                "TalentAssessmentReport",
                "TalentPlus Assessment Report"
              )}
            </h1>
            <p className="text-lg font-semibold text-[#3D405B]">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}
            </p>
          </div>

      <div className="grid grid-cols-2 gap-4 py-6">

      {getList &&
        getList.length > 0
        ? getList.map((element, index1) => (
          <>

              <div className="w-full space-y-2 py-2 px-2">
                  <div className="space-y-2">
                    <button
                      style={{
                        background:
                          element && element.colorCode
                            ? element.colorCode
                            : "#DDDDDD",
                      }}
                      className={
                        " px-2 py-1 text-sm flex text-white uppercase tracking-wide rounded-md "
                      }
                    >
                      {/* {element.bucket} */}
                      {EditDetailsFn(element,'bucket',element.bucket)}
                    </button>
                    <h1 className="text-2xl font-medium text-[#3D405B] ">
                      {/* {((index1 + 1)>16?(index1 + 1 - 16).toString()   :(index1 + 1).toString()) + ". " + element.dimension} */}
                      {EditDetailsFn(element,'dimension',element.dimension)}
                    </h1>
                  </div>


                <p className="text-base font-normal">
                      <div>
                      {EditDetailsFn(element,'desc1',<div
                      dangerouslySetInnerHTML={{
                        __html:
                          element &&
                          element.details &&
                          element.details.desc1
                            ? element.details.desc1
                            : "",
                      }}
                    />)}
                    </div>
                </p>
              </div>


            </>
          ))
        : null}
      </div>

      </div></main>
    
    </>
  ):null}
  </>
  );
}
