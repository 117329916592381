import { generalConstants } from '../_constants';
import { generalService } from '../_services';

export const generalActions = {
    getSurvey,
    getDashboardCard,
    getQuestionDist,
    getQuestionDistOverall,
    getMeanScore,
    getMeanScoreQwise,
    getQuestionOthersResult,
    getQuestionDMGResult,
    getQuestionOthersResultFilter,
    getQuestionOthersResultFilterEmpty,
    getQuestion2OthersResultFilter,
    getQuestionMatrixResult,

    getQuestionByQuestionIdResult,
    getQuestionWithBranchCodeByQuestionId,
    getQuestionWithBranchCodeByQuestionId2,

    getIndexByFilterDMG,
    getQuestionOthersMultiResultFilter,

    getQuestionLiveResponse,

    getQuestionAvgScore
};

function getSurvey(data) {
    return dispatch => {
        dispatch(request());
        generalService.getSurvey(data)
            .then(
                general =>{ 
                    dispatch(success(general));
                    if(general && general.SurveyData && general.SurveyData.list && general.SurveyData.list.length>0){
                        let SurveyList = []
                        general.SurveyData.list.forEach((survey)=>{
                            if(survey && survey.templateId && survey.templateId === 3){
                                SurveyList.push(survey);
                            }

                        });
                        if(SurveyList && SurveyList.length>0){
                            let data2 = {
                                "surveyId": false? SurveyList[0].id:"5fa04d316ede0f0336066cce"
                            }
                            //console.log('surveyId', data2.surveyId)

                            if(data2 && data2.surveyId){
                                dispatch(this.getDashboardCard(data2));
                            }
                        }

                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: generalConstants.GET_SURVEY_LIST_REQUEST } }
    function success(general) { return { type: generalConstants.GET_SURVEY_LIST_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_SURVEY_LIST_FAILURE, error } }
}


function getDashboardCard(data) {
    let obj = {
        getDashboardCardData :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getDashboardCard(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: generalConstants.GET_DASHBOARD_CARD_REQUEST } }
    function success(general) { return { type: generalConstants.GET_DASHBOARD_CARD_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_DASHBOARD_CARD_FAILURE, error } }
}



function getQuestionDist(data) {
    let obj = {
        getQuestionDistData:[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getQuestionDist(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUESTION_DIST_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUESTION_DIST_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUESTION_DIST_FAILURE, error } }
}


function getQuestionDistOverall(data) {
    let obj = {
        getQuestionDistOverallData:[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getQuestionDistOverall(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUESTION_DIST_OVERALL_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUESTION_DIST_OVERALL_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUESTION_DIST_OVERALL_FAILURE, error } }
}

function getMeanScore(data) {
    let obj = {
        getMeanScoreData:[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getMeanScore(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_MEAN_SCORE_REQUEST } }
    function success(general) { return { type: generalConstants.GET_MEAN_SCORE_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_MEAN_SCORE_FAILURE, error } }
}



function getMeanScoreQwise(data) {
    let obj = {
        getMeanScoreQwiseData:[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getMeanScoreQwise(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_MEAN_SCORE_QWISE_REQUEST } }
    function success(general) { return { type: generalConstants.GET_MEAN_SCORE_QWISE_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_MEAN_SCORE_QWISE_FAILURE, error } }
}


function getQuestionOthersResult(data) {
    let obj = {
        getQuestionOthersResultData:[]
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getQuestionOthersResult(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUES_OTHERS_RESULT_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUES_OTHERS_RESULT_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUES_OTHERS_RESULT_FAILURE, error } }
}


function getQuestionOthersResultFilterEmpty(data) {
    let obj = {
        getQuestionOthersResultFilterData :{}
    }
    return dispatch => {
        dispatch(success(obj));
    };
    function success(general) { return { type: generalConstants.GET_QUES_FILTER_OTHERS_RESULT_SUCCESS, general } }
}


function getQuestionOthersResultFilter(data) {
    let obj = {
        getQuestionOthersResultFilterData :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getQuestionOthersResultFilter(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUES_FILTER_OTHERS_RESULT_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUES_FILTER_OTHERS_RESULT_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUES_FILTER_OTHERS_RESULT_FAILURE, error } }
}


function getQuestionDMGResult(data) {
    let obj = {
        getQuestionDMGResultData :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getQuestionDMGResult(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUESTION_DMG_RESULT_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUESTION_DMG_RESULT_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUESTION_DMG_RESULT_FAILURE, error } }
}



function getQuestion2OthersResultFilter(data) {
    let obj = {
        getQuestion2OthersResultFilterData :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getQuestion2OthersResultFilter(data)
            .then(
                general => {
                    dispatch(success(general));
                    //////console.log("---------general--------",general)
                },
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUES_2_FILTER_OTHERS_RESULT_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUES_2_FILTER_OTHERS_RESULT_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUES_2_FILTER_OTHERS_RESULT_FAILURE, error } }
}



function getQuestionMatrixResult(data) {
    let obj = {
        getQuestionMatrixResultData :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getQuestionMatrixResult(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUES_NPS_RESULT_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUES_NPS_RESULT_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUES_NPS_RESULT_FAILURE, error } }
}



function getQuestionByQuestionIdResult(data) {
    let obj = {
        getQuestionByQuestionIdResultData :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getQuestionByQuestionIdResult(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUES_TYPE1_RESULT_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUES_TYPE1_RESULT_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUES_TYPE1_RESULT_FAILURE, error } }
}


function getQuestionWithBranchCodeByQuestionId(data) {
    let obj = {
        getQuestionWithBranchCodeByQuestionIdData :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getQuestionWithBranchCodeByQuestionId(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUES_BRANCHCODE_TYPE1_RESULT_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUES_BRANCHCODE_TYPE1_RESULT_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUES_BRANCHCODE_TYPE1_RESULT_FAILURE, error } }
}


function getQuestionWithBranchCodeByQuestionId2(data) {
    let obj = {
        getQuestionWithBranchCodeByQuestionId2Data :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getQuestionWithBranchCodeByQuestionId2(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUES_BRANCHCODE_TYPE1_RESULT_2_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUES_BRANCHCODE_TYPE1_RESULT_2_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUES_BRANCHCODE_TYPE1_RESULT_2_FAILURE, error } }
}


function getIndexByFilterDMG(data) {
    let obj = {
        getIndexByFilterDMGData :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getIndexByFilterDMG(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_INDEX_BY_FILTER_DMG_X_REQUEST } }
    function success(general) { return { type: generalConstants.GET_INDEX_BY_FILTER_DMG_X_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_INDEX_BY_FILTER_DMG_X_FAILURE, error } }
}




function getQuestionLiveResponse(data) {
    let obj = {
        getQuestionLiveResponseData :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getQuestionLiveResponse(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUES_LIVE_RES_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUES_LIVE_RES_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUES_LIVE_RES_FAILURE, error } }
}


function getQuestionAvgScore(data) {
    let obj = {
        getQuestionAvgScoreData :{}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        generalService.getQuestionAvgScore(data)
            .then(
                general => dispatch(success(general)),
                error => {
                    dispatch(failure(error)) 
                }
            );
    };
    function request() { return { type: generalConstants.GET_QUES_AVG_SCORE_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUES_AVG_SCORE_SUCCESS, general } }
    function failure(error) { return { type: generalConstants.GET_QUES_AVG_SCORE_FAILURE, error } }
}

function getQuestionOthersMultiResultFilter(data) {

    return dispatch => {
        dispatch(request());

        let FilteredObj = {
            getQuestionOthersMultiResultFilterData:[]
        };
        if(data && data.length>0){
            data.forEach((item)=>{

                generalService.getQuestionOthersMultiResultFilter(item)
                .then(
                    general => {
                        if(general && general.getQuestionOthersMultiResultFilterData){
                            FilteredObj['getQuestionOthersMultiResultFilterData'].push({
                                "xAxis":item.xAxis,
                                "Data":general.getQuestionOthersMultiResultFilterData
                            });
                        }
                    },
                    error => {
                        // dispatch(failure(error))
                    }
                );


            });
        }
        //////console.log("FilteredObj-------->",FilteredObj)
        dispatch(success(FilteredObj))
    };

    function request() { return { type: generalConstants.GET_QUES_MULTI_RESULT_REQUEST } }
    function success(general) { return { type: generalConstants.GET_QUES_MULTI_RESULT_SUCCESS, general } }
    // function failure(error) { return { type: generalConstants.GET_QUES_MULTI_RESULT_FAILURE, error } }
}