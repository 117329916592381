import {
  generalConstants
} from '../_constants';

export function general(state = {}, action) {

  switch (action.type) {
      //----------------------------------------------------------------- 

      case generalConstants.GET_SURVEY_LIST_REQUEST:
        return {
          ...state,
          loading: true
        };
      case generalConstants.GET_SURVEY_LIST_SUCCESS:
        return {
          ...state,
          getSurveySuccess: true,
          loading: false,
          SurveyList:action.general.SurveyData.list,
          SurveyTotal:action.general.SurveyData.total

        };
      case generalConstants.GET_SURVEY_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };

        //----------------------------------------------------------------- 

        case generalConstants.GET_DASHBOARD_CARD_REQUEST:
          return {
            ...state,
            loading: true
          };
        case generalConstants.GET_DASHBOARD_CARD_SUCCESS:
          return {
            ...state,
            loading: false,
            getDashboardCardSuccess: true,
            getDashboardCardData:action.general.getDashboardCardData
  
          };
        case generalConstants.GET_DASHBOARD_CARD_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  
          //----------------------------------------------------------------- 

        case generalConstants.GET_QUESTION_DIST_REQUEST:
          return {
            ...state,
            loading: true
          };
        case generalConstants.GET_QUESTION_DIST_SUCCESS:
          return {
            ...state,
            loading: false,
            getQuestionDistSuccess: true,
            getQuestionDistData:action.general.getQuestionDistData
  
          };
        case generalConstants.GET_QUESTION_DIST_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  
          //----------------------------------------------------------------- 

          case generalConstants.GET_QUESTION_DIST_OVERALL_REQUEST:
            return {
              ...state,
              loading: true
            };
          case generalConstants.GET_QUESTION_DIST_OVERALL_SUCCESS:
            return {
              ...state,
              loading: false,
              getQuestionDistOverallSuccess: true,
              getQuestionDistOverallData:action.general.getQuestionDistOverallData
    
            };
          case generalConstants.GET_QUESTION_DIST_OVERALL_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
    
            //----------------------------------------------------------------- 

          case generalConstants.GET_MEAN_SCORE_REQUEST:
            return {
              ...state,
              loading: true
            };
          case generalConstants.GET_MEAN_SCORE_SUCCESS:
            return {
              ...state,
              loading: false,
              getMeanScoreSuccess: true,
              getMeanScoreData:action.general.getMeanScoreData
    
            };
          case generalConstants.GET_MEAN_SCORE_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
    
            //----------------------------------------------------------------- 

            case generalConstants.GET_MEAN_SCORE_QWISE_REQUEST:
              return {
                ...state,
                loading: true
              };
            case generalConstants.GET_MEAN_SCORE_QWISE_SUCCESS:
              return {
                ...state,
                loading: false,
                getMeanScoreQwiseSuccess: true,
                getMeanScoreQwiseData:action.general.getMeanScoreQwiseData
      
              };
            case generalConstants.GET_MEAN_SCORE_QWISE_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };
      
              //----------------------------------------------------------------- 

                 
            case generalConstants.GET_QUES_NPS_RESULT_REQUEST:
              return {
                ...state,
                loading: true
              };
            case generalConstants.GET_QUES_NPS_RESULT_SUCCESS:
              return {
                ...state,
                loading: false,
                getQuestionResultNPSSuccess: true,
                getQuestionMatrixResultData:action.general.getQuestionMatrixResultData
      
              };
            case generalConstants.GET_QUES_NPS_RESULT_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };


              case generalConstants.GET_QUES_OTHERS_RESULT_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case generalConstants.GET_QUES_OTHERS_RESULT_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  getQuestionResultOthersSuccess: true,
                  getQuestionOthersResultData:action.general.getQuestionOthersResultData
        
                };
              case generalConstants.GET_QUES_OTHERS_RESULT_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };
      
              //----------------------------------------------------------------- 
 


              
              case generalConstants.GET_QUES_FILTER_OTHERS_RESULT_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case generalConstants.GET_QUES_FILTER_OTHERS_RESULT_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  getQuestionOthersResultFilterSuccess: true,
                  getQuestionOthersResultFilterData:action.general.getQuestionOthersResultFilterData
        
                };
              case generalConstants.GET_QUES_FILTER_OTHERS_RESULT_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };




                case generalConstants.GET_QUES_2_FILTER_OTHERS_RESULT_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case generalConstants.GET_QUES_2_FILTER_OTHERS_RESULT_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getQuestion2OthersResultFilterSuccess: true,
                    getQuestion2OthersResultFilterData:action.general.getQuestion2OthersResultFilterData
          
                  };
                case generalConstants.GET_QUES_2_FILTER_OTHERS_RESULT_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };
                




                  
                case generalConstants.GET_QUES_TYPE1_RESULT_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case generalConstants.GET_QUES_TYPE1_RESULT_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getQuestionByQuestionIdResultSuccess: true,
                    getQuestionByQuestionIdResultData:action.general.getQuestionByQuestionIdResultData
          
                  };
                case generalConstants.GET_QUES_TYPE1_RESULT_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };
                


            case generalConstants.GET_QUES_BRANCHCODE_TYPE1_RESULT_REQUEST:
              return {
                ...state,
                loading: true
              };
            case generalConstants.GET_QUES_BRANCHCODE_TYPE1_RESULT_SUCCESS:
              return {
                ...state,
                loading: false,
                getQuestionWithBranchCodeByQuestionIdSuccess: true,
                getQuestionWithBranchCodeByQuestionIdData:action.general.getQuestionWithBranchCodeByQuestionIdData
      
              };
            case generalConstants.GET_QUES_BRANCHCODE_TYPE1_RESULT_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };
                  

              case generalConstants.GET_INDEX_BY_FILTER_DMG_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case generalConstants.GET_QUES_BRANCHCODE_TYPE1_RESULT_2_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  getQuestionWithBranchCodeByQuestionId2Success: true,
                  getQuestionWithBranchCodeByQuestionId2Data:action.general.getQuestionWithBranchCodeByQuestionId2Data
        
                };
              case generalConstants.GET_QUES_BRANCHCODE_TYPE1_RESULT_2_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };
                
                


                case generalConstants.GET_INDEX_BY_FILTER_DMG_X_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case generalConstants.GET_INDEX_BY_FILTER_DMG_X_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getIndexByFilterDMGSuccess: true,
                    getIndexByFilterDMGData:action.general.getIndexByFilterDMGData
          
                  };
                case generalConstants.GET_INDEX_BY_FILTER_DMG_X_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };
                     

                  case generalConstants.GET_QUES_MULTI_RESULT_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case generalConstants.GET_QUES_MULTI_RESULT_SUCCESS:
                    return {
                      ...state,
                      loading: false,
                      getQuestionOthersMultiResultFilterSuccess: true,
                      getQuestionOthersMultiResultFilterData:action.general.getQuestionOthersMultiResultFilterData
            
                    };
                  case generalConstants.GET_QUES_MULTI_RESULT_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };

                    case generalConstants.GET_QUES_LIVE_RES_REQUEST:
                      return {
                        ...state,
                        loading: true
                      };
                    case generalConstants.GET_QUES_LIVE_RES_SUCCESS:
                      return {
                        ...state,
                        loading: false,
                        getQuestionLiveResponseSuccess: true,
                        getQuestionLiveResponseData:action.general.getQuestionLiveResponseData
              
                      };
                    case generalConstants.GET_QUES_LIVE_RES_FAILURE:
                      return {
                        ...state,
                        loading: false,
                        error: action.error
                      };
  


                      case generalConstants.GET_QUES_AVG_SCORE_REQUEST:
                        return {
                          ...state,
                          loading: true
                        };
                      case generalConstants.GET_QUES_AVG_SCORE_SUCCESS:
                        return {
                          ...state,
                          loading: false,
                          getQuestionAvgScoreSuccess: true,
                          getQuestionAvgScoreData:action.general.getQuestionAvgScoreData
                
                        };
                      case generalConstants.GET_QUES_AVG_SCORE_FAILURE:
                        return {
                          ...state,
                          loading: false,
                          error: action.error
                        };



                        
                      case generalConstants.GET_QUESTION_DMG_RESULT_REQUEST:
                        return {
                          ...state,
                          loading: true
                        };
                      case generalConstants.GET_QUESTION_DMG_RESULT_SUCCESS:
                        return {
                          ...state,
                          loading: false,
                          getQuestionDMGResultSuccess: true,
                          getQuestionDMGResultData:action.general.getQuestionDMGResultData
                
                        };
                      case generalConstants.GET_QUESTION_DMG_RESULT_FAILURE:
                        return {
                          ...state,
                          loading: false,
                          error: action.error
                        };


    default:
      return state
  }
}