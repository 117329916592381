import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Filter from './Filter';
import Filter from './Filter/Filter';

import PageTemplate from './PageTemplate';

import GaugeScore from '../Charts/GaugeScore';
import Demographics from './Filter/Demographics';
import { PDFExport } from "@progress/kendo-react-pdf";
import moment from "moment";

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();

    this.state = {
      currFilterName: 'none',
      viewScore: true,
      showDropDown1: false,
      showDropDown2: false,
      showFilter: false,
      showValue: '',


      showFilterDMG: false,
      showValueDMG: '',
      FilterValuesDMG: [],


      selectedCategory: {
        "name": "All",
        "label": "Show All"
      },
      showReportModal: false,



      DimensionList: [
        {
          index: 1,
          name: 'Happiness',
          cutOff: 59,
          range: 72,
          type: ""
        },
        {
          index: 2,
          name: 'Engagement',
          cutOff: 78,
          range: 100,
          type: "%"

        },
        {
          index: 4,
          name: 'Stress',
          cutOff: 28,
          range: 60,
          type: "%"

        },
        {
          index: 5,
          name: 'Manager',
          cutOff: 59,
          range: 100,
          type: "%"

        },
        {
          index: 6,
          name: 'Leader',
          cutOff: 50,
          range: 100,
          type: "%"

        },
        // {
        //   index:8,
        //   name:'OrgCore',
        //   cutOff:59
        // },
      ],

      selectedItem1: {
        index: 1,
        name: 'Happiness',
        cutOff: 59,
        range: 72,
        type: ""
      },
      selectedItem2: {
        index: 2,
        name: 'Engagement',
        cutOff: 78,
        range: 100,
        type: "%"
      },


      currentSelectValue: '',
      FilterValues: [],
      showQueDrop: false,
      storeQues1: '',
      storeQues2: '',




    }



  }
  componentDidMount() {

  }



  handleDropdown1 = (check) => {
    this.setState({ showDropDown1: !check, showDropDown2: false })
  }
  handleDropdown2 = (check) => {
    this.setState({ showDropDown2: !check, showDropDown1: false })
  }



  openQuesDrop = (check, no) => {
    if (no === 1) {
      this.setState({ showQueDrop1: check, showQueDrop2: false });
    }
    if (no === 2) {
      this.setState({ showQueDrop2: check, showQueDrop1: false });
    }
  }


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }




  closeFilterDMG = (check1, check2) => {
    this.setState({ showFilterDMG: check1, showValueDMG: check2 });
  }

  handleMultifilterIndexScore2DMG = (finalFilter) => {
    this.setState({ FilterValuesDMG: finalFilter }, () => {
      let { emptyRiskQuesEmp } = this.props;
      emptyRiskQuesEmp()
    });
  }

  onSelectedCategory = (value) => {
    this.setState({ selectedCategory: value }, () => {
      let { emptyRiskQuesEmp } = this.props;
      emptyRiskQuesEmp()
    });
  }

  handleExportWithComponent = (event) => {
    this.setState({ setPDF: true }, () => {
      this.pdfExportComponent.current.save();
    })
  }


  openPopupReport = (check) => {
    this.setState({ showReportModal: check });
  }

  render() {
    let { RiskRespondantEmployeesData, loading, RiskAllEmpQuesResponsesData } = this.props;
    let { showReportModal, selectedCategory, FilterValuesDMG } = this.state;



    let { handleSelectEmpFilter, crrSelectedEmp, RiskgetEmpQuestionResponsesData, RiskgetRiskCategoryData } = this.props;


    // let CategoryList = []

    // if(qList && qList.length>0){
    //   CategoryList = qList.filter(prev=>prev.riskcategoryBucket)

    // }

    function calcScore(questionDetail) {
      let sum = 0;
      // let total = 0;
      let noResponse = false
      if (questionDetail && questionDetail.length > 0) {
        questionDetail.forEach((ques) => {
          if (ques.qtemplate === 1) {
            // total += 6
            sum += Number(ques.answer)
          }
          else if (ques.qtemplate === 6 || ques.qtemplate === 16) {
            // total += 6

            if (ques && ques.answerText) {
              let temp = Number(ques.answerText)
              sum += Number(temp)
            }
            else {
              noResponse = true
            }
            // if(temp >=0 && temp <=5){
            //   npsNewScore = 1;
            // }
            // else if(temp >=6 && temp <=7){
            //   npsNewScore = 4;
            // }
            // else{
            //   npsNewScore = 6;
            // }

          }
        })
      }
      // ////console.log("start-------")
      // ////console.log("sum",sum)
      // ////console.log("total",total)

      return (noResponse ? "-" : sum)
    }


    let qList = RiskgetEmpQuestionResponsesData;

    ////console.log("qList-------->>", qList)

    let RiskCategory = []
    let scoreSum = 0;
    let scoreCount = 0;

    ////console.log("RiskgetRiskCategoryData-------->>", RiskgetRiskCategoryData)

    function ToString(value) {
      return value ? (value.toString()).trim() : ""
    }
    // let AlreadyList = []

    if (RiskgetRiskCategoryData && RiskgetRiskCategoryData.length > 0 && qList && qList.length > 0) {
      RiskgetRiskCategoryData.forEach((category) => {
        ////console.log("category-------->>", category);

        let questionDetailFinal = qList.filter(prev => ToString(prev.riskCategoryId) === ToString(category.id));

        RiskCategory.push({
          "name": category.name,
          "id": category.id,
          "questionList": questionDetailFinal,
          "categoryCount": questionDetailFinal && questionDetailFinal.length > 0 ? questionDetailFinal.length : 0,
          "score": calcScore(questionDetailFinal)
        });


      })
    }

    ////console.log("RiskCategory-------->>", RiskCategory)

    // ////console.log("scoreSum-------->>",scoreSum)
    // ////console.log("scoreCount-------->>",scoreCount)

    let totalScore = (scoreSum) / scoreCount;

    let categoryShow = []
    let newQuestionList = []
    if (RiskCategory && RiskCategory.length > 0) {
      RiskCategory.forEach((item, index1) => {
        if (item && item.questionList && item.questionList.length > 0) {
          item.questionList.forEach((item3, index3) => {
            let temp = {
              ...item3,
              "questionScore": item.score
            }
            temp["riskcategoryValue"] = item.name;


            let getIndex = categoryShow.findIndex(prev => prev === item.name)
            if (getIndex === -1) {
              categoryShow.push(item.name)
              temp["categoryShow"] = true;
              temp["categoryCount"] = item.categoryCount;
            }
            else {
              temp["categoryShow"] = false
            }



            newQuestionList.push(temp)
          })
        }

      })
    }

    //let Qcount = 0
    let grandTotal = 0;
    let grandScore = 0;
    if (newQuestionList && newQuestionList.length > 0) {
      newQuestionList.forEach((ques) => {
        ////console.log("npsNewScore-------->>", ques.qtemplate);
        if (Number(ques.qtemplate) === 1) {
          //Qcount++
          grandTotal += 6;
          grandScore += Number(ques.answer)
        }
        // else if(Number(ques.qtemplate) === 6 || Number(ques.qtemplate) === 16){

        //   grandTotal += 6 
        //   let npsNewScore = 0;
        //   let temp = Number(ques.answerText)
        //   if(temp >=0 && temp <=5){
        //     npsNewScore = 1;
        //   }
        //   else if(temp >=6 && temp <=7){
        //     npsNewScore = 4;
        //   }
        //   else{
        //     npsNewScore = 6;
        //   }
        //   ////console.log("temp-------->>",temp);
        //   ////console.log("npsNewScore-------->>",npsNewScore);

        //   grandScore += Number(npsNewScore)
        // }
      })
    }
    ////console.log("Qcount-------->>", Qcount);
    ////console.log("newQuestionList Count-------->", newQuestionList && newQuestionList.length > 0 ? newQuestionList.length : 0);
    ////console.log("grandTotal-------->>", grandTotal);
    ////console.log("grandScore-------->>", grandScore);

    // "#b31717",
    // "#e65757",
    // "#edb409",
    // "#1bbf36"

    function getColor(item) {
      let color = "#858784";

      if (item && item.questionScore && item.questionScore !== "-") {
        if ((item.qtemplate).toString() === "1") {
          let score = Number(item.questionScore);
          if (score > 24 && score <= 30) {
            color = "#1bbf36";
          }
          else if (score > 17 && score <= 24) {
            color = "#edb409";
          }
          else if (score > 12 && score <= 17) {
            color = "#e65757";
          }
          else if (score >= 6 && score <= 12) {
            color = "#b31717";
          }
        }
        else if ((item.qtemplate).toString() === "6" || (item.qtemplate).toString() === "16") {
          let score = Number(item.questionScore);
          if (score >= 9 && score <= 10) {
            color = "#1bbf36";
          }
          else if (score >= 7 && score <= 8) {
            color = "#edb409";
          }
          else if (score >= 0 && score <= 6) {
            color = "#e65757";
          }
        }


      }
      else if ((item.qtemplate).toString() === "16" && item.questionScore === 0) {
        color = "#e65757";
      }

      return color
    }


    // function getAnswer(ques){
    //   let answer = "-"
    //   if(Number(ques.qtemplate) === 1){
    //     answer = ques && ques.answer?ques.answer:"-"
    //   }
    //   else if(Number(ques.qtemplate) === 6 || Number(ques.qtemplate) === 16){
    //     answer = ques && ques.answerText?ques.answerText:"-"
    //   }
    //   return answer

    // }



    let { EmpFilterData, getIndexFilterData } = this.props;



















    // function ToString(value){
    //   return value?(value.toString()).trim():""
    // }

    // function calcScore(questionDetail){
    //   let sum = 0;
    //   if(questionDetail && questionDetail.length>0){
    //     questionDetail.forEach((ques)=>{
    //       if(ques.qtemplate===1){
    //         sum += Number(ques.answer)
    //       }
    //     })
    //   }
    //   return sum
    // }



    function getRiskMeterScore(resList, CatList, name) {
      let RiskCategory = 0
      if (CatList && CatList.length > 0 && resList && resList.length > 0) {
        CatList.forEach((category) => {
          let questionDetailFinal = resList.filter(prev => ToString(prev.riskCategoryId) === ToString(category.id));
          RiskCategory += calcScore(questionDetailFinal);
        })
      }

      let getRiskScore = Number(RiskCategory);
      let isContain = false
      if (getRiskScore >= 25 && getRiskScore < 50) {
        if (name === "Extremely_High_Risk") {
          isContain = true
        }
      }
      else if (getRiskScore >= 50 && getRiskScore < 75) {
        if (name === "High_Risk") {
          isContain = true
        }
      }
      else if (getRiskScore >= 75 && getRiskScore < 100) {
        if (name === "Borderline") {
          isContain = true
        }
      }
      else if (getRiskScore >= 100 && getRiskScore < 125) {
        if (name === "Moderate_Risk") {
          isContain = true
        }
      }
      else if (getRiskScore >= 125 && getRiskScore <= 150) {
        if (name === "No_Risk") {
          isContain = true
        }
      }


      return isContain ? getRiskScore : 0
    }










    let FilterAllEmpResponses = [];

    if (RiskAllEmpQuesResponsesData && RiskAllEmpQuesResponsesData.length > 0) {
      RiskAllEmpQuesResponsesData.forEach((item) => {

        let isCheck = 0;
        if (FilterValuesDMG && FilterValuesDMG.length > 0) {
          FilterValuesDMG.forEach((filter) => {
            if (filter.value !== "All") {
              let Emp = item && item["responses"] && item["responses"][0] ? item["responses"][0] : {}
              if (!(Emp[filter.level] === filter.value)) {
                isCheck++
              }
            }


          });
        }
        // console.log('x Emp: ',Emp);
        // console.log('x isCheck: ',isCheck);

        if (isCheck === 0) {
          FilterAllEmpResponses.push(item)
        }




      });

    }





    ////console.log("FilterAllEmpResponses--->", FilterAllEmpResponses)

    let empResponseList = []
    if (selectedCategory && selectedCategory.name === "All") {
      if (FilterAllEmpResponses && FilterAllEmpResponses.length > 0 && selectedCategory && selectedCategory.name) {
        FilterAllEmpResponses.forEach((item) => {
          if (item && item["_id"]) {
            empResponseList.push({
              ...item["_id"]
            })
          }
        });
      }
    }
    else {
      if (FilterAllEmpResponses && FilterAllEmpResponses.length > 0 && selectedCategory && selectedCategory.name) {
        FilterAllEmpResponses.forEach((item) => {
          ////console.log("-Values--->", item.responses, RiskgetRiskCategoryData, selectedCategory.name)
          ////console.log("---->", getRiskMeterScore(item.responses, RiskgetRiskCategoryData, selectedCategory.name))

          if (item && item["_id"] && getRiskMeterScore(item.responses, RiskgetRiskCategoryData, selectedCategory.name)) {
            empResponseList.push({
              ...item["_id"]
            })
          }

        });
      }
    }


    ////console.log("empResponseList--->", empResponseList)
    ////console.log("///////selectedCategory--->", selectedCategory)





    ////console.log("///////crrSelectedEmp--->", crrSelectedEmp)


    function getFirstEmp(list) {
      if (list && list.length > 0) {
        return list && list[0] && list[0].name ? list[0].name : "-"
      }
      else {
        return "No Employee"
      }
    }


    return (
      <>
        <div className="items-center justify-between py-2 md:flex">

          <div className="flex flex-wrap gap-2 ">

            {/* <Demographics
              showFilter={this.state.showFilterDMG}
              showValue={this.state.showValueDMG}
              closeFilter={this.closeFilterDMG}

              EmpFilterData2={EmpFilterData}
              getIndexFilterData={getIndexFilterData}
              handleMultifilterIndexScore={this.handleMultifilterIndexScore2DMG}
            /> */}


            <Filter
              showFilter={this.state.showFilter}
              showValue={this.state.showValue}
              closeFilter={this.closeFilter}

              crrSelectedEmp={crrSelectedEmp}
              handleSelectEmpFilter={handleSelectEmpFilter}
              RiskgetRiskCategoryData={RiskgetRiskCategoryData}

              RiskRespondantEmployeesData={RiskRespondantEmployeesData}
              RiskAllEmpQuesResponsesData={RiskAllEmpQuesResponsesData}

              FilterValuesDMG={FilterValuesDMG}

              empResponseList={empResponseList}
              selectedCategory={selectedCategory}
              onSelectedCategory={this.onSelectedCategory}
            />




          </div>



          {/* <div onClick={() => this.openPopupReport(true)} className="flex items-center p-2 px-4 text-sm text-blue-500 bg-blue-100 rounded-full cursor-pointer">
            <span className="mr-1 material-symbols-outlined" >
              file_download
            </span> View Report</div> */}

          <div onClick={() => this.openPopupReport(true)} className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-report-analytics" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path><rect x="9" y="3" width="6" height="4" rx="2"></rect><path d="M9 17v-5"></path><path d="M12 17v-1"></path><path d="M15 17v-3"></path></svg>
            <span>View Report</span>
          </div>

          {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} /> */}

        </div>



        {true ?
          <>


            {/* <PDFExport
              pageTemplate={PageTemplate}
              scale={0.6}
              forcePageBreak=".page-break"
              ref={this.pdfExportComponent}
              paperSize="A4"
              margin={40}
              fileName={"RiskReport"}
              author="Happyplus"
              landscape={true}
            > */}

            <div onClick={() => this.closeFilter(false)} className="pt-4 md:pt-0" >
              <div className="grid w-full grid-cols-1 gap-6 xl:grid-cols-3" >

                <div className="w-full p-4 space-y-4 bg-white border rounded xl:col-span-1">
                  <div className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Risk Meter</div>

                  <div className='' >
                    {empResponseList && empResponseList.length > 0 ?
                      <GaugeScore
                        totalScore={totalScore}
                        grandTotal={grandTotal}
                        grandScore={grandScore}

                      />
                      : <div className="text-xl text-center text-gray-500">No Data Found</div>}
                  </div>


                </div>

                <div className="w-full space-y-4 bg-white border rounded xl:col-span-2 ">
                  <div className="p-4 font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Employee Response</div>

                  <div className="px-4 pb-4 overflow-x-auto overflow-y-auto lg:whitespace-normal whitespace-nowrap customscroll4" style={{ height: '30rem' }} >

                    {empResponseList && empResponseList.length > 0 ?
                      <>
                        {newQuestionList && newQuestionList.length > 0 ?
                          <div className="table w-full">
                            <table className="w-full border border-collapse">
                              <thead className>

                                <tr className="border-b bg-gray-50">

                                  <th className="p-2 text-sm font-bold text-gray-800 border border-r cursor-pointer xl:w-40">
                                    <div className="flex items-center justify-center">
                                      Risk Category
                                    </div>
                                  </th>

                                  <th className="p-2 text-sm font-bold text-gray-800 border border-r cursor-pointer">
                                    <div className="flex items-center justify-center">
                                      Questions
                                    </div>
                                  </th>

                                  {/* <th  className="p-2 text-sm font-bold text-gray-800 border-r cursor-pointer">
                            <div className="flex items-center justify-center">
                              Answers
                            </div>
                          </th> */}

                                  <th className="w-1/6 p-2 text-sm font-bold text-gray-800 border border-r cursor-pointer xl:w-40">
                                    <div className="flex items-center justify-center">
                                      Score
                                    </div>
                                  </th>

                                </tr>


                              </thead>
                              <tbody>

                                {newQuestionList && newQuestionList.length > 0 ?
                                  newQuestionList.map((item, index) =>
                                    <tr className={"text-center text-sm text-gray-600 border " + (item.categoryShow ? "border-t border-b" : "border-b")}>

                                      {item.categoryShow ?
                                        <td className="border border-b" rowSpan={item.categoryCount}> <p className="p-2 font-bold ">{item && item.riskcategoryValue ? item.riskcategoryValue : "-"}</p></td>
                                        : null}

                                      <td className={(item.categoryShow ? "border border-b" : "") + " border-r text-left"}> <p className="p-2 font-normal whitespace-normal ">{item && item.question ? item.question : "-"}</p></td>

                                      {/* <td className="border-r "> <p className="p-2 font-bold ">{getAnswer(item)}</p></td> */}


                                      {item.categoryShow ?
                                        <td className="text-xl font-semibold text-white border border-b " style={{ background: getColor(item) }} rowSpan={item.categoryCount}> <p className="p-2 ">{item.questionScore}</p></td>
                                        : null}


                                    </tr>
                                  )
                                  : null}

                              </tbody>
                            </table>
                          </div>
                          :
                          <>
                            <div className="text-xl text-center text-gray-500">Please select employee</div>
                          </>
                        }
                      </>
                      :
                      <>
                        <div className="text-xl text-center text-gray-500">No Data Found</div>
                      </>
                    }



                  </div>

                </div>




              </div>
            </div>

            {/* </PDFExport> */}








            {showReportModal ?
              <>

                <div className="fixed top-0 left-0 z-40 flex items-start justify-center w-full h-screen p-4 bg-black bg-opacity-60" >

                  <div className="mx-auto overflow-y-auto bg-white rounded-md xl:w-4/6 " style={{ height: 'calc(100vh - 4rem)' }} >

                    <div className='sticky top-0 items-center justify-between p-6 py-2 bg-white border-b md:flex'>

                      <h1 className='text-xl font-medium capitalize' >Report</h1>

                      <div className='flex items-center space-x-4 ' >
                        <div className="flex items-center p-2 px-4 text-sm text-blue-500 bg-blue-100 rounded-full cursor-pointer"
                          onClick={() => { this.setState({ setPDF: true }, () => { this.handleExportWithComponent() }); }}>

                          <span className="material-symbols-outlined " >
                            file_download
                          </span> Download Report
                        </div>
                        <div onClick={() => this.openPopupReport(false)} className="inline-flex items-center justify-center w-10 h-10 text-gray-500 bg-gray-100 rounded-full cursor-pointer hover:text-gray-800" >
                          <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                          </svg>
                        </div>
                      </div>

                    </div>


                    <PDFExport
                      pageTemplate={PageTemplate}
                      scale={0.6}
                      // forcePageBreak=".page-break"
                      ref={this.pdfExportComponent}
                      // paperSize="A4"
                      margin={40}
                      fileName={"Sense Report" + ((empResponseList && empResponseList.length > 0 ?
                        crrSelectedEmp && crrSelectedEmp.name ? " - " + crrSelectedEmp.name :
                          (selectedCategory && selectedCategory.name !== "All" ? "" : " - " + getFirstEmp(empResponseList))
                        : ""))}
                      author="Happyplus"
                    // landscape={true}
                    >

                      <main className="p-6 py-10 bg-blue-100">
                        <div className="flex items-center justify-between text-left">
                          <div className="w-full text-5xl font-bold">
                            <h1 className="leading-tight"><b className="font-bold text-blue-400 ">Sense</b> <br /> Scorecard</h1>
                            <div className="pt-6 space-y-2 text-xl font-medium text-gray-600">
                              <p>Employee Name: {(empResponseList && empResponseList.length > 0 ?
                                crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : (selectedCategory && selectedCategory.name !== "All" ? "Select Employee" : getFirstEmp(empResponseList))
                                : "Unavailable")}</p>
                              <p>Date:<b>{moment().format("DD/MM/YYYY")}</b></p>
                              {/* <p>Manager Id: asdfhaskldhfkajsd</p>  */}
                            </div>
                          </div>

                          <div className="flex justify-center w-full">
                            <img alt="icon" src="img/managerreport/manager_scorecard.png" className="w-10/12 " style={{ height: '' }} />
                          </div>
                        </div>

                      </main>

                      <div className="pt-4 m-6 md:pt-0" >
                        <div className="w-full space-y-6" >


                          {/* <div className="w-full space-y-4 bg-white rounded xl:col-span-1">
                            <div className="text-lg font-semibold ">Risk Meter</div>

                            <h1 className='font-medium text-gray-500'>{"Employee Name: " + (empResponseList && empResponseList.length > 0 ?
                              crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : (selectedCategory && selectedCategory.name !== "All" ? "Select Employee" : getFirstEmp(empResponseList))
                              : "Unavailable")}</h1>

                          </div> */}




                          <div className="w-full space-y-4 bg-white rounded xl:col-span-1">
                            <div className="p-4 text-lg font-semibold bg-blue-100 ">Risk Meter</div>

                            <div className='' >
                              {empResponseList && empResponseList.length > 0 ?
                                <GaugeScore
                                  totalScore={totalScore}
                                  grandTotal={grandTotal}
                                  grandScore={grandScore}

                                />
                                : <div className="text-xl text-center text-gray-500">No Data Found</div>}
                            </div>


                          </div>

                          <div className="w-full space-y-4 bg-white rounded xl:col-span-2 ">
                            <div className="p-4 text-lg font-semibold bg-blue-100 ">Employee Response</div>

                            <div className="pb-4 overflow-x-auto lg:whitespace-normal whitespace-nowrap"  >

                              {empResponseList && empResponseList.length > 0 ?
                                <>
                                  {newQuestionList && newQuestionList.length > 0 ?
                                    <div className="table w-full">
                                      <table className="w-full border border-collapse">
                                        <thead className>

                                          <tr className="border-b bg-gray-50">

                                            <th className="p-2 text-sm font-bold text-gray-800 border border-r cursor-pointer xl:w-40">
                                              <div className="flex items-center justify-center">
                                                Risk Category
                                              </div>
                                            </th>

                                            <th className="p-2 text-sm font-bold text-gray-800 border border-r cursor-pointer">
                                              <div className="flex items-center justify-center">
                                                Questions
                                              </div>
                                            </th>

                                            {/* <th  className="p-2 text-sm font-bold text-gray-800 border-r cursor-pointer">
                              <div className="flex items-center justify-center">
                                Answers
                              </div>
                            </th> */}

                                            <th className="w-1/6 p-2 text-sm font-bold text-gray-800 border border-r cursor-pointer xl:w-40">
                                              <div className="flex items-center justify-center">
                                                Score
                                              </div>
                                            </th>

                                          </tr>


                                        </thead>
                                        <tbody>

                                          {newQuestionList && newQuestionList.length > 0 ?
                                            newQuestionList.map((item, index) =>
                                              <tr className={"text-center text-sm text-gray-600 border " + (item.categoryShow ? "border-t border-b" : "border-b")}>

                                                {item.categoryShow ?
                                                  <td className="border border-b" rowSpan={item.categoryCount}> <p className="p-2 font-bold ">{item && item.riskcategoryValue ? item.riskcategoryValue : "-"}</p></td>
                                                  : null}

                                                <td className={(item.categoryShow ? "border border-b" : "") + " border-r text-left"}> <p className="p-2 font-normal whitespace-normal ">{item && item.question ? item.question : "-"}</p></td>

                                                {/* <td className="border-r "> <p className="p-2 font-bold ">{getAnswer(item)}</p></td> */}


                                                {item.categoryShow ?
                                                  <td className="text-xl font-semibold text-white border border-b " style={{ background: getColor(item) }} rowSpan={item.categoryCount}> <p className="p-2 ">{item.questionScore}</p></td>
                                                  : null}


                                              </tr>
                                            )
                                            : null}

                                        </tbody>
                                      </table>
                                    </div>
                                    :
                                    <>
                                      <div className="text-xl text-center text-gray-500">Please select employee</div>
                                    </>
                                  }
                                </>
                                :
                                <>
                                  <div className="text-xl text-center text-gray-500">No Data Found</div>
                                </>
                              }



                            </div>

                          </div>




                        </div>
                      </div>

                    </PDFExport>

                  </div>

                </div>
              </>
              : null}








          </>
          :
          <>
            {!loading ?
              <div className="pt-40 text-3xl text-center text-gray-400">We have not calculated this section!</div> : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
