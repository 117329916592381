import {
  pmsConstants
} from '../_constants';

export function pms(state = {}, action) {

  switch (action.type) {


    case pmsConstants.GET_PMS_EMPLOYEE_WISE_STATUS_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_PMS_EMPLOYEE_WISE_STATUS_SUCCESS:
      return {
        ...state,
        // loading: false
        getPMSEWScore: action.pms.getPMSEWScore.list,
      };
    case pmsConstants.GET_PMS_EMPLOYEE_WISE_STATUS_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };



    case pmsConstants.GET_PMS_LAUNCH_STATUS_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_PMS_LAUNCH_STATUS_SUCCESS:
      return {
        ...state,
        // loading: false
        PMSLaunchAppraisal: action.pms.PMSLaunchAppraisal,
      };
    case pmsConstants.GET_PMS_LAUNCH_STATUS_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    // case pmsConstants.GET_EMPLOYEE_APPRAISAL_STATUS_REQUEST:
    //   return {
    //     ...state,
    //     // loading: true
    //   };
    // case pmsConstants.GET_EMPLOYEE_APPRAISAL_STATUS_SUCCESS:
    //   return {
    //     ...state,
    //     // loading: false
    //     getEMPASData: action.pms.getEMPASData,
    //   };
    // case pmsConstants.GET_EMPLOYEE_APPRAISAL_STATUS_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error,
    //     // loading: false
    //   };


    case pmsConstants.GET_OVERVIEW_STATUS_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_OVERVIEW_STATUS_SUCCESS:
      return {
        ...state,
        // loading: false
        getDashboardOverviewStatusData: action.pms.getDashboardOverviewStatusData,
      };
    case pmsConstants.GET_OVERVIEW_STATUS_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };

    case pmsConstants.EMP_PMS_GOAL_LIST_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.EMP_PMS_GOAL_LIST_SUCCESS:
      return {
        ...state,
        // loading: false
        GetEmpPMSGoalList: action.pms.GetEmpPMSGoalList,
      };
    case pmsConstants.EMP_PMS_GOAL_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case pmsConstants.ADD_PMS_GETEMP_LIST_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.ADD_PMS_GETEMP_LIST_SUCCESS:
      return {
        ...state,
        // loading: false
        goaladdedsucess: true,
        AddEmpPMSGoal: action.pms.AddEmpPMSGoal
      };
    case pmsConstants.ADD_PMS_GETEMP_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };



    case pmsConstants.GET_APPRAISAL_NAME_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_APPRAISAL_NAME_SUCCESS:
      return {
        ...state,
        // loading: false
        getAppraisalNameDetais: action.pms.getAppraisalNameDetais
      };
    case pmsConstants.GET_APPRAISAL_NAME_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case pmsConstants.PMSGET_SCORE_NAME_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.PMSGET_SCORE_NAME_SUCCESS:
      return {
        ...state,
        // loading: false
        PMSGetAppraisalDetailsById: action.pms.PMSGetAppraisalDetailsById
      };
    case pmsConstants.PMSGET_SCORE_NAME_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case pmsConstants.EMP_SCORE_NAME_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.EMP_SCORE_NAME_SUCCESS:
      return {
        ...state,
        // loading: false
        getPMSEmpScoreLabels: action.pms.getPMSEmpScoreLabels
      };
    case pmsConstants.EMP_SCORE_NAME_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };



    case pmsConstants.PMSGETNANME_SCORE_NAME_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.PMSGETNANME_SCORE_NAME_SUCCESS:
      return {
        ...state,
        // loading: false
        PMSGetAppraisalName: action.pms.PMSGetAppraisalName
      };
    case pmsConstants.PMSGETNANME_SCORE_NAME_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case pmsConstants.GET_PEERS_GOAL_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_PEERS_GOAL_SUCCESS:
      return {
        ...state,
        // loading: false
        getPeersGoalList: action.pms.getPeersGoalList
      };
    case pmsConstants.GET_PEERS_GOAL_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };




    case pmsConstants.GET_PEERS_GOAL_STATUS_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_PEERS_GOAL_STATUS_SUCCESS:
      return {
        ...state,
        // loading: false
        getPeersGoalStatus: action.pms.getPeersGoalStatus
      };
    case pmsConstants.GET_PEERS_GOAL_STATUS_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case pmsConstants.GET_APPRAISAL_NAME_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_APPRAISAL_NAME_SUCCESS:
      return {
        ...state,
        // loading: false
        getAppraisalNameDetais: action.pms.getAppraisalNameDetais
      };
    case pmsConstants.GET_APPRAISAL_NAME_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case pmsConstants.PMSGET_SCORE_NAME_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.PMSGET_SCORE_NAME_SUCCESS:
      return {
        ...state,
        // loading: false
        PMSGetAppraisalDetailsById: action.pms.PMSGetAppraisalDetailsById
      };
    case pmsConstants.PMSGET_SCORE_NAME_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case pmsConstants.EMP_SCORE_NAME_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.EMP_SCORE_NAME_SUCCESS:
      return {
        ...state,
        // loading: false
        getPMSEmpScoreLabels: action.pms.getPMSEmpScoreLabels
      };
    case pmsConstants.EMP_SCORE_NAME_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };




    case pmsConstants.GET_PMS_STATUS_EMP_v2_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_PMS_STATUS_EMP_v2_SUCCESS:
      return {
        ...state,
        // loading: false
        getPeersGoalStatusV2: action.pms.getPeersGoalStatus
      };
    case pmsConstants.GET_PMS_STATUS_EMP_v2_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case pmsConstants.GET_PMSLIST_NAME_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_PMSLIST_NAME_SUCCESS:
      return {
        ...state,
        // loading: false
        getPMSAppraisalListStatus: action.pms.getPMSAppraisalListStatus
      };
    case pmsConstants.GET_PMSLIST_NAME_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };





    case pmsConstants.GET_PEERS_NEW_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_PEERS_NEW_SUCCESS:
      return {
        ...state,
        // loading: false
        getPeersListNew: action.pms.getPeersListNew,
      };
    case pmsConstants.GET_PEERS_NEW_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case pmsConstants.GET_PEER_DETAILS_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_PEER_DETAILS_SUCCESS:
      return {
        ...state,
        // loading: false
        pmsGetPeerDetails: action.pms.pmsGetPeerDetails,
      };
    case pmsConstants.GET_PEER_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case pmsConstants.GET_PMS_EMPDETAILS_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_PMS_EMPDETAILS_SUCCESS:
      return {
        ...state,
        // loading: false
        pmsGetEmpDetails: action.pms.pmsGetEmpDetails,
      };
    case pmsConstants.GET_PMS_EMPDETAILS_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case pmsConstants.PMS_GETEMP_LIST_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.PMS_GETEMP_LIST_SUCCESS:
      return {
        ...state,
        // loading: false
        pmsGetEmpAppraisalList: action.pms.pmsGetEmpAppraisalList,
      };
    case pmsConstants.PMS_GETEMP_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };

    case pmsConstants.GET_PMS_LIST_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_PMS_LIST_SUCCESS:
      return {
        ...state,
        // loading: false
        getMyPeersList: action.pms.getMyPeersList,
      };
    case pmsConstants.GET_PMS_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };



    case pmsConstants.GETEMP_PMS_TASK_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GETEMP_PMS_TASK_SUCCESS:
      return {
        ...state,
        // loading: false
        GetEmpPMSTaskList: action.pms.GetEmpPMSTaskList,
      };
    case pmsConstants.GETEMP_PMS_TASK_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case pmsConstants.GET_AND_UPDATE_EMP_PMSSTAGE_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_AND_UPDATE_EMP_PMSSTAGE_SUCCESS:
      return {
        ...state,
        // loading: false
        getAndUpdateEmpPMSStageData: action.pms.getAndUpdateEmpPMSStageData,
      };
    case pmsConstants.GET_AND_UPDATE_EMP_PMSSTAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };

    case pmsConstants.GET_PMS_NORMAL_SCHANGE_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_PMS_NORMAL_SCHANGE_SUCCESS:
      return {
        ...state,
        // loading: false
        PMSNormlStatusChange: action.pms.PMSNormlStatusChange,
      };
    case pmsConstants.GET_PMS_NORMAL_SCHANGE_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case pmsConstants.GET_PMS_STATUS_EMP_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_PMS_STATUS_EMP_SUCCESS:
      return {
        ...state,
        // loading: false
        getPMSAppraisalStatusByEmpList: action.pms.getPMSAppraisalStatusByEmpList,
      };
    case pmsConstants.GET_PMS_STATUS_EMP_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case pmsConstants.GET_EMPLOYEE_APPRAISAL_STATUS_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_EMPLOYEE_APPRAISAL_STATUS_SUCCESS:
      return {
        ...state,
        // loading: false
        getEmployeeAppraisalStatus: action.pms.getEmployeeAppraisalStatus.list,
        getEMPASData: action.pms.getEmployeeAppraisalStatus.list,
        getEMPASLabels: action.pms.getEmployeeAppraisalStatus.labels
      };
    case pmsConstants.GET_EMPLOYEE_APPRAISAL_STATUS_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };




    case pmsConstants.GET_PMS_STATUS_EMP_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.GET_PMS_STATUS_EMP_SUCCESS:
      return {
        ...state,
        // loading: false
        PMSGetDetailsByAppraisalCycleId: action.pms.PMSGetDetailsByAppraisalCycleId
      };
    case pmsConstants.GET_PMS_STATUS_EMP_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };




    case pmsConstants.PMS_UPDATE_DETAILS_BY_APPRAISAL_CYCLE_ID_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.PMS_UPDATE_DETAILS_BY_APPRAISAL_CYCLE_ID_SUCCESS:
      return {
        ...state,
        // loading: false
        PMSUpdateAppraisalCycleById: action.pms.PMSUpdateAppraisalCycleById
      };
    case pmsConstants.PMS_UPDATE_DETAILS_BY_APPRAISAL_CYCLE_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };






    case pmsConstants.PMS_GET_DETAILS_BY_APPRAISAL_CYCLE_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case pmsConstants.PMS_GET_DETAILS_BY_APPRAISAL_CYCLE_SUCCESS:
      return {
        ...state,
        // loading: false
        PMSGetDetailsByAppraisalCycleId: action.pms.PMSGetDetailsByAppraisalCycleId
      };
    case pmsConstants.PMS_GET_DETAILS_BY_APPRAISAL_CYCLE_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };



      case pmsConstants.PMS_GET_PROCESS_SETTING_REQUEST:
        return {
          ...state,
          // loading: true
        };
      case pmsConstants.PMS_GET_PROCESS_SETTING_SUCCESS:
        return {
          ...state,
          // loading: false
          getPMSProcessSetting: action.pms.getPMSProcessSetting
        };
      case pmsConstants.PMS_GET_PROCESS_SETTING_FAILURE:
        return {
          ...state,
          error: action.error,
          // loading: false
        };
  


    default:
      return state
  }
}