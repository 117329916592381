import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList: [],
      EmpIndexA: 0,
      EmpIndexB: 4,

      dropdownOpen: false,
      selectedOption: 'Bar chart', // Default selected option

    }
  }
  componentDidMount() {

  }

  handleDropdownToggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  handleOptionClick = (option) => {
    this.setState({
      selectedOption: option,
      dropdownOpen: false // Close dropdown after selection
    });
  };

  render() {

    let { AnswerObj, name, size, questionName, dropdownOpen, selectedOption } = this.props;
    let DataList = [];
    let total = 0;

    console.log('dropdownOpendropdownOpendropdownOpendropdownOpen', this.state.dropdownOpen);

    if (AnswerObj) {
      Object.keys(AnswerObj).forEach(key => {
        let score = parseInt(AnswerObj[key]);
        total += score;
        DataList.push({
          name: key,
          value: score
        });
      });
    }

    DataList.sort((a, b) => {
      if (a.name.slice(0, -2) && b.name.slice(0, -2)) {
        return (a.name.slice(5, 6) - b.name.slice(5, 6))
      }
    });

    function ordering(name) {
      let order = 111;
      if (name === "<25%") {
        order = 1;
      }
      else if (name === "25-50%") {
        order = 2;
      }
      else if (name === "50 - 75%") {
        order = 3;
      }
      else if (name === "More than 75%") {
        order = 4;
      }
      return order
    }

    let List = [];
    let MaxScore = 0;
    let MaxObj = {
      "value": 0
    }

    let sortingArr = [
      "Strongly Agree",
      "Agree",
      "Slightly Agree",
      "Slightly Disagree",
      "Disagree",
      "Strongly Disagree"
    ]

    if (DataList && DataList.length > 0) {
      DataList.sort((a, b) => sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name));
    }

    if (DataList && DataList.length > 0) {
      DataList.forEach((item) => {
        ////console.log("item.name==========>",item.name)
        if (item && item.name) {
          let temp = {
            "label": item.name,
            "number": ordering(item.name),
            "value": ((parseFloat(item.value) * 100) / total).toFixed(2),
            "itemStyle": { color: '#9933ff' }
          }
          if (item.name === "Strongly Agree" || item.name === "Agree") {
            let scorePerc = ((parseFloat(item.value) * 100) / total);
            MaxScore += scorePerc;

          }
          List.push(temp);
        }

      })
    }

    if (MaxScore) {
      MaxObj["value"] = (MaxScore).toFixed(2)
    }

    List.sort((a, b) => a.number - b.number)

    function isFloat(n) {
      return Number(n) % 1 != 0;
    }



    // console.log('ListListListListListListListListListListListListListListListListList', List);
    let { stage, index } = this.props;

    return (
      <>

        <div className='space-y-4 bg-white'>
          <div className='flex flex-row items-center justify-between w-full gap-4'>
            <div className='flex items-center text-[#212121] space-x-2'>
              <p className='p-3 bg-[#ededed] font-semibold'>Question 1</p>
              {/* <p className='font-medium '>Yes / No</p> */}
            </div>
            <div className='flex items-center space-x-2'>
              <button className='p-3 bg-[#ededed] rounded flex flex-row items-center gap-2 text-[#212121] relative'>
                <span className='flex items-center gap-2' onClick={this.handleDropdownToggle}>
                  {this.state.selectedOption === 'Bar chart' && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                      <path d="M12.1875 2.8125V25.3125H10.3125V6.5625H4.6875V25.3125H2.8125V27.1875H27.1875V25.3125H25.3125V13.125H19.6875V25.3125H17.8125V2.8125H12.1875ZM14.0625 4.6875H15.9375V25.3125H14.0625V4.6875Z" fill="#212121" />
                    </svg>
                  )}
                  {this.state.selectedOption === 'Table' && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                      <path d="M26.25 2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V26.25C2.5 26.5815 2.6317 26.8995 2.86612 27.1339C3.10054 27.3683 3.41848 27.5 3.75 27.5H26.25C26.5815 27.5 26.8995 27.3683 27.1339 27.1339C27.3683 26.8995 27.5 26.5815 27.5 26.25V3.75C27.5 3.41848 27.3683 3.10054 27.1339 2.86612C26.8995 2.6317 26.5815 2.5 26.25 2.5ZM10 25H5V20H10V25ZM10 17.5H5V12.5H10V17.5ZM10 10H5V5H10V10ZM17.5 25H12.5V20H17.5V25ZM17.5 17.5H12.5V12.5H17.5V17.5ZM17.5 10H12.5V5H17.5V10ZM25 25H20V20H25V25ZM25 17.5H20V12.5H25V17.5ZM25 10H20V5H25V10Z" fill="#212121" />
                    </svg>
                  )}
                  <span>{this.state.selectedOption}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor" class="icon icon-tabler icons-tabler-filled icon-tabler-caret-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" /></svg>
                </span>


                {this.state.dropdownOpen && (
                  <div className='absolute left-0 z-20 px-2 bg-white divide-y rounded shadow-xl top-12 w-fit whitespace-nowrap'>
                    <div className="flex items-center space-x-2 text-[#212121] p-3 text-sm" onClick={() => this.handleOptionClick('Bar chart')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                        <path d="M12.1875 2.8125V25.3125H10.3125V6.5625H4.6875V25.3125H2.8125V27.1875H27.1875V25.3125H25.3125V13.125H19.6875V25.3125H17.8125V2.8125H12.1875ZM14.0625 4.6875H15.9375V25.3125H14.0625V4.6875Z" fill="#212121" />
                      </svg>
                      <span>Bar chart</span>
                    </div>
                    <div className="flex items-center space-x-2 text-[#212121] p-3 text-sm" onClick={() => this.handleOptionClick('Table')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                        <path d="M26.25 2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V26.25C2.5 26.5815 2.6317 26.8995 2.86612 27.1339C3.10054 27.3683 3.41848 27.5 3.75 27.5H26.25C26.5815 27.5 26.8995 27.3683 27.1339 27.1339C27.3683 26.8995 27.5 26.5815 27.5 26.25V3.75C27.5 3.41848 27.3683 3.10054 27.1339 2.86612C26.8995 2.6317 26.5815 2.5 26.25 2.5ZM10 25H5V20H10V25ZM10 17.5H5V12.5H10V17.5ZM10 10H5V5H10V10ZM17.5 25H12.5V20H17.5V25ZM17.5 17.5H12.5V12.5H17.5V17.5ZM17.5 10H12.5V5H17.5V10ZM25 25H20V20H25V25ZM25 17.5H20V12.5H25V17.5ZM25 10H20V5H25V10Z" fill="#212121" />
                      </svg>
                      <span>Table</span>
                    </div>
                  </div>
                )}

              </button>
              <button className='p-3 bg-[#ededed] rounded text-[#212121]'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-download"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" /><path d="M7 11l5 5l5 -5" /><path d="M12 4l0 12" /></svg></button>
            </div>
          </div>
          <h2 className='text-xl font-medium text-[#212121]'>{questionName && questionName.length > 80 ? (index === -1 ? "" : ((index + 1) + ") ")) + questionName.slice(0, 80) + "..." : (index === -1 ? "" : ((index + 1) + ") ")) + questionName}</h2>
          <p className='text-base text-[#212121]/80 flex space-x-2 items-center'>
            <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold'>Single Choice</p>
            <b className='font-medium text-[#212121] pr-1'>10</b> out of<b className='font-medium text-[#212121] pr-1'>10</b> answered</p>

          {this.state.selectedOption === 'Bar chart' && (
            <div className="space-y-2 min-h-auto max-h-[360px] w-full px-3 pb-4 overflow-y-auto customscroll4">
              <div className='items-center xl:flex'>
                <div className='w-full'>
                  <ReactEcharts
                    style={{ height: "340px" }}
                    option={{
                      xAxis: {
                        type: 'category',
                        data: ['Strongly Agree', 'Agree', 'Slightly Agree', 'Slightly Disagree', 'Strongly Disagree']
                      },
                      yAxis: {
                        type: 'value',
                        axisLine: {
                          show: true
                        },
                      },
                      series: [
                        {
                          data: [120, 140, 100, 150, 130],
                          type: 'bar',
                          showBackground: true,
                          backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                          },
                          label: {
                            show: true,
                            position: 'inside'
                          },
                          itemStyle: {
                            color: function (params) {
                              // Define your colors here
                              var colorList = ['#337357', '#2ecc71', '#FFD23F', '#ED2B2A', '#FF004D'];
                              return colorList[params.dataIndex];
                            }
                          }
                        }
                      ]
                    }}
                  />


                </div>


              </div>

              {/* {List && List.length > 0 ?
                List.map((item) =>
                  <div className="space-y-1 ">
                    <p className="text-sm truncate whitespace-nowrap">{item.label}</p>
                    <div className="w-full h-6 bg-gray-200">
                      <div style={{ width: item.value + '%' }}
                        className="relative w-3/6 h-6 bg-gradient-to-r from-blue-400 to-blue-500 " >
                        <div className="absolute left-2 text-sm font-normal text-white p-0.5 bg-blue-500 bg-opacity-30">{item.value + '%'}</div>
                      </div>
                    </div>
                  </div>
                ) : null} */}
            </div>
          )}

          {this.state.selectedOption === 'Table' && (
            <div className='bg-[#f9f9f9] p-6 rounded'>
              <table className='w-full'>
                <thead>
                  <tr className='border-b'>
                    <th className='p-4 text-lg font-medium text-left'>Answer Choices</th>
                    <th className='p-4 text-lg font-medium text-left'>Responses</th>
                    <th className='p-4 text-lg font-medium text-left'>Responses Percentage</th>
                  </tr>
                </thead>

                <tbody className='divide-y '>
                  {List && List.length > 0 ?
                    List.map((item) =>
                      <tr className='text-[#212121]'>
                        <td className='p-4 text-left'>{item.label}</td>
                        <td className='p-4 text-left'>5</td>
                        <td className='p-4 text-left'>{item.value + '%'}</td>
                      </tr>
                    ) :
                    <tr>
                      <td colSpan={3} className='pt-2 text-center'>Data not found</td>
                    </tr>
                  }
                </tbody>

              </table>
            </div>
          )}



          {/* {stage !== 2 ?
            <>
              <div class="flex justify-center p-4 mt-auto">
                <button class=" text-[#2196f3]  cursor-pointer transition duration-150 flex items-center uppercase font-medium xl:text-base text-sm ">{stage == 2 ? "View Details" : (stage == 3 ? "" : "View More")}<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right ml-2" width="34" height="34" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2196f3" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="5" y1="12" x2="19" y2="12"></line><line x1="15" y1="16" x2="19" y2="12"></line><line x1="15" y1="8" x2="19" y2="12"></line></svg>
                </button>
              </div>
            </>
            : null} */}

          {stage !== 2 ?
            <>
              <button className='flex items-center space-x-2 font-medium text-[#2196F3] p-2 w-fit mx-auto'>
                <span>{stage == 2 ? "View Details" : (stage == 3 ? "" : " View Demographic Wise")} </span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg>
              </button>
            </>
            : null}
        </div>



        {/* <div className='relative flex flex-col justify-between h-full space-y-6'>

          <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] line-clamp-2">
            {questionName && questionName.length > 80 ? (index === -1 ? "" : ((index + 1) + ") ")) + questionName.slice(0, 80) + "..." : (index === -1 ? "" : ((index + 1) + ") ")) + questionName}</h1>

          <div className="absolute top-0 z-30 hidden p-4 pt-1 text-sm font-semibold bg-white border rounded-md shadow-md tooltipcard dropdown-menu -left-4">
            {(index === -1 ? "" : ((index + 1) + ") ")) + questionName}
          </div>

          <div className="">
            <h1 className="text-4xl text-center text-[#212121] ">{MaxObj && MaxObj["value"] ? MaxObj["value"] + "%" : "0%"}</h1>
            <div className="flex justify-center my-1">
              <span className="text-[#212121]/70 font-medium">{"Strongly Agree + Agree" + " with the question"}</span>
            </div>
          </div>

          <div className="space-y-2 min-h-auto max-h-[360px] w-full px-3 pb-4 overflow-y-auto customscroll4">
            {List && List.length > 0 ?
              List.map((item) =>
                <div className="space-y-1 ">
                  <p className="text-sm truncate whitespace-nowrap">{item.label}</p>
                  <div className="w-full h-6 ml-1 bg-gray-200">
                    <div style={{ width: item.value + '%' }}
                      className="relative w-3/6 h-6 bg-gradient-to-r from-blue-400 to-blue-500 " >
                      <div className="absolute left-2 text-sm font-normal text-white p-0.5 bg-blue-500 bg-opacity-30">{item.value + '%'}</div>
                    </div>
                  </div>
                </div>
              ) : null}
          </div>

          {stage !== 2 ?
            <>
              <div class="flex justify-center p-4 mt-auto">
                <button class=" text-[#2196f3]  cursor-pointer transition duration-150 flex items-center uppercase font-medium xl:text-base text-sm ">{stage == 2 ? "View Details" : (stage == 3 ? "" : "View More")}<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right ml-2" width="34" height="34" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2196f3" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="5" y1="12" x2="19" y2="12"></line><line x1="15" y1="16" x2="19" y2="12"></line><line x1="15" y1="8" x2="19" y2="12"></line></svg>
                </button>
              </div>
            </>
            : null}
        </div> */}
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(PieChart);
