import React, { Component } from "react";
import { connect } from "react-redux";
import { accessModule } from "../../_helpers";

//api call
import {
  dashboardActions,
  questionsActions,
  surveyActions,
  userActions,
  generalActions,
} from "../../_actions";

//Layout
import Sidebar from "./Sidebar/Sidebar";
import Header from '../CommonComponents/DashboardHeader/Header';

//libraries
import LoadingOverlay from "react-loading-overlay";

import Overview from "./Overview/Overview";

import QuestionDetails from "../Dashboard/QuestionDetails/QuestionDetails";
// import QuestionDetails from "./QuestionDetails/QuestionDetails";

import QuestionCard from "../Dashboard/Overview/Components/QuestionTypeCards/QuestionCard";

import { CONST } from "../../_config";
import TextAnalysis from "../Dashboard/TextAnalysis/TextAnalysis";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexType: 0,
      CurrentSurveyName: "",
      CurrentSurveyId: this.props.match.params.id,
      optionType: 0,
      showFilterDropdownName: false,
      showFilterDropdownValue: false,
      CurrentIndexName: "",
      filterSelectIndex: 0,
      loaderActive: false,
      filterOn: false,
      FilterList2: [],
      showFilter: true,

      loadReport: false,
      isFilter: false,

      showMobileSidebar: false,

      showOverview: true,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showCustomScoreCard: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false,

      StackObject: [],
      StackObjectTotal: [],
      ReturnFinalFilterList: [],
      selectedFilterList: [],
      selectedFilterValue: "none",
      HeatMapSelectedFilter: "Department",

      DynaObj: [],

      Mindextype: "",

      customeScoreData: "",

      ManagerDynaObj: [],

      QuesDynaObj: {},

      QuesMngrHappinessReportObject: {},
      QuesMngrEngagementReportObject: {},
      QuesMngrStressReportObject: {},
      QuesMngrManagerReportObject: {},

      DriverMngrHappinessReportObject: {},
      DriverMngrEngagementReportObject: {},
      DriverMngrStressReportObject: {},
      DriverMngrManagerReportObject: {},

      MQuesIndextype: "",
      rwaIndexType: 1,
      ManagerListDasboardDataObj: [],
      ManagerListQuestionDataObj: [],

      showSidebar: true,
      currentQuestionId: "",
      currentQuestionTemplate: "",
      showAllQuestions: true,

      showReportDMGModal: false,

      ReportDMGindexType: "",
      ReportDMGcheck: "",
      ReportDMGfiltersListNew: "",
      ReportxAxis: "",
      isReportOverall: false,

      ManagerReportFConfig: {
        managerIdType: CONST.MANAGER_ID_TYPE, //Int or String
      },
    };
  }

  componentDidMount() {
    this.LoadOverviewInitFn();

    // let data5 = {
    //   "surveyId": this.state.CurrentSurveyId
    // }

    // this.props.dispatch(dashboardActions.getExitQuestion1(data5));
    // this.props.dispatch(dashboardActions.getUserAccess({}));
  }

  callOverviewApi = () => {
    this.setState({
      showOverview: true,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: false,
      showCustomScoreCard: false,
      showPeopleToMeet: false,
    });

    this.LoadOverviewInitFn();
  };

  LoadOverviewInitFn = () => {
    let data5 = {
      surveyId: this.state.CurrentSurveyId,
    };
    this.props.dispatch(surveyActions.getSurveyById(data5));

    let data1 = {
      keyWord: "",
      pageNo: 1,
      size: 500,
    };

    let data2 = {
      surveyId: this.state.CurrentSurveyId,
      filters: [],
    };

    let data6 = {
      surveyId: this.state.CurrentSurveyId,
      variable: "EXIT_GAUGE_1",
      // questionId: ["640993bf47000254b2736037"]
    };
    this.props.dispatch(dashboardActions.getExitQuestion1(data6));

    let data7 = {
      surveyId: this.state.CurrentSurveyId,
      variable: "EXIT_GAUGE_2",
      // questionId: ["640993bf47000254b2736051", "640993bf47000254b2736053"],
    };
    this.props.dispatch(dashboardActions.getExitQuestionAvg(data7));

    let data8 = {
      surveyId: this.state.CurrentSurveyId,
      variable: "EXIT_GAUGE_3",
      // questionId: ["640993bf47000254b2735faf"],
    };
    this.props.dispatch(dashboardActions.getExitQuestionYesNo(data8));

    let data9 = {
      surveyId: this.state.CurrentSurveyId,
      variable: "EXIT_MULTIPLE_BAR",
      // questionId: ["640993bf47000254b2735fab"],
    };
    this.props.dispatch(dashboardActions.getExitQuestionAllCount(data9, {
      questionId: "640993bf47000254b2735fac"
    }));



    // let data91 = {
    //   surveyId: this.state.CurrentSurveyId,
    //   xAxis: "Gender",
    // };
    // this.props.dispatch(dashboardActions.getExitQuestionAllDemographics(data91));



    this.props.dispatch(dashboardActions.getEmpFilter("getExitQuestionAllDemographics", this.state.CurrentSurveyId));

    this.props.dispatch(dashboardActions.getOverallIndex(data5));

    this.props.dispatch(dashboardActions.getOverallScoreBySurveyId(data5));

    //=============== org core

    this.props.dispatch(dashboardActions.getCompanyNameOnly());

    this.props.dispatch(surveyActions.getIndexByFilterDMG());

    this.props.dispatch(dashboardActions.getMetaRespondantDetails(data5));

    this.props.dispatch(dashboardActions.getClientProfile());

    this.props.dispatch(
      dashboardActions.getComment({
        keyWord: "",
        pageNo: 1,
        size: 60,
      })
    );

    this.props.dispatch(
      generalActions.getQuestionAvgScore({
        surveyId: this.state.CurrentSurveyId,
        qtemplateList: [1],
      })
    );

    this.props.dispatch(
      generalActions.getQuestionOthersResult({
        surveyId: this.state.CurrentSurveyId,
        qtemplateList: [1, 2, 6, 11, 16],
      })
    );

    this.props.dispatch(surveyActions.getIndexByFilterDMG());

    let data10 = {
      keyWord: "",
      pageNo: 1,
      size: 1000,
    };

    // this.props.dispatch(surveyActions.getParameterList(data10));
  };

  handleLogout = () => {
    this.props.dispatch(userActions.logoutSession());
  };

  handleSidebar = (check) => {
    this.setState({ showSidebar: check });
  };
  handleMobileSidebar = (check) => {
    this.setState({ showMobileSidebar: check });
    this.setState({ showSidebar: check });
  };

  handleSummaryCard = (name) => {
    let { CurrentSurveyId } = this.state;

    let { indexType } = this.state;
    let data = {
      surveyId: CurrentSurveyId,
      xAxis: name,
    };
    this.props.dispatch(dashboardActions.getDemographicIndex(data));

    if (indexType === 1) {
      this.props.dispatch(
        dashboardActions.getHappinessIndexV2Demographic(data)
      );
    } else if (indexType === 2) {
      this.props.dispatch(
        dashboardActions.getEngagementIndexV2Demographic(data)
      );
    } else if (indexType === 4) {
      this.props.dispatch(dashboardActions.getStressIndexV2Demographic(data));
    } else if (indexType === 5) {
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data));
    } else if (indexType === 6) {
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data));
    }
  };

  handleSummaryFilter = (name, value) => {
    let { CurrentSurveyId } = this.state;
    let data = {
      surveyId: CurrentSurveyId,
      filters: [{ level: name, value: value }],
    };
    this.props.dispatch(dashboardActions.getIndexByFilter(data));
  };

  handleQuestionFilter = (name, value) => {
    let { indexType, CurrentSurveyId } = this.state;
    let data = {
      surveyId: CurrentSurveyId,
      template: indexType,
      filters: [{ level: name, value: value }],
    };
    // //////console.log('data: ',data)
    this.props.dispatch(dashboardActions.getQuestionFilter(data));
  };

  handleDistributionFilter = (name) => {
    let { indexType } = this.state;
    let data = {
      surveyId: this.state.CurrentSurveyId,
      xAxis: name,
    };
    if (indexType === 1) {
      this.props.dispatch(dashboardActions.getDemographicIndexHappiness(data));
    } else if (indexType === 2) {
      this.props.dispatch(dashboardActions.getDemographicIndexEngagement(data));
    } else if (indexType === 4) {
      this.props.dispatch(dashboardActions.getDemographicIndexStress(data));
    } else if (indexType === 5) {
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data));
    } else if (indexType === 6) {
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data));
    } else if (indexType === 8) {
      this.props.dispatch(dashboardActions.getDemographicIndexOrg(data));
    }
  };

  onCustomScoreCard = (item) => {
    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: false,
      showAllQuestions: false,
      showCustomScoreCard: true,
      currentQuestionTemplate: "",

      customeScoreData: item,
      showPeopleToMeet: false,
    });
  };

  onAllQuestions = (item) => {
    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: true,
      showAllQuestions: true,
      showCustomScoreCard: false,
      currentQuestionTemplate: item.templateId,
      showPeopleToMeet: false,
    });
  };

  onTextAnalysis = (surveyId) => {
    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: true,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false,
    });

    let data2 = {
      surveyId: this.state.CurrentSurveyId,
    };
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 1));
  };

  onOptionType = (NoData, indexType, optionType) => {
    // this.handleSidebar(false);
    this.setState({ showMobileSidebar: false });

    if (indexType === "nps6") {
      this.setState({
        showOverview: false,
        showIndex: false,
        showManagerList: false,
        showOrgCore: false,
        showAdvanceComparision: false,
        showTextAnalysis: false,
        showStrengthAndWeaknesses: false,
        showManagerActionPlan: false,
        showManagerGoalPlan: false,
        showReport: false,
        showOtherTypes: true,
        showAllQuestions: false,
        showCustomScoreCard: false,
        currentQuestionTemplate: optionType,
        showPeopleToMeet: false,
      });
    } else {
      let { dashboard } = this.props;
      let { EmpFilterData } = dashboard;

      let filterNames = "";
      if (EmpFilterData && EmpFilterData.length > 0) {
        filterNames =
          EmpFilterData && EmpFilterData[0] && EmpFilterData[0].name
            ? EmpFilterData[0].name
            : "";
      }

      // this.setState({CurrentSurveyId:surveyId})

      let surveyId = this.state.CurrentSurveyId;

      let {
        getHappinessIndexDriverData,
        getEngagementIndexDriverData,
        getStressIndexDriverData,
        getManagerIndexDriverData,
        getLeaderIndexDriverData,
        getOrgIndexDriverData,
      } = dashboard;

      this.setState({
        showOverview: false,
        showIndex: true,
        showManagerList: false,
        showOrgCore: false,
        showAdvanceComparision: false,
        showTextAnalysis: false,
        showStrengthAndWeaknesses: false,
        showManagerActionPlan: false,
        showManagerGoalPlan: false,
        showReport: false,
        showOtherTypes: false,
        showCustomScoreCard: false,
        showPeopleToMeet: false,
      });

      this.setState({ indexType: indexType, optionType: optionType });

      function callIndexapi(indexType, props, data) {
        if (indexType === 1) {
          props.dispatch(dashboardActions.getHappinessIndexV2(data));
        } else if (indexType === 2) {
          props.dispatch(dashboardActions.getEngagementIndexV2(data));

          let data1 = {
            dmgLabel: "IndexScore",
            index: 2,
          };
          props.dispatch(dashboardActions.getBenchmarkQuantile(data1));
        } else if (indexType === 4) {
          props.dispatch(dashboardActions.getStressIndexV2(data));
        } else if (indexType === 5) {
          // props.dispatch(dashboardActions.getManagerIndexV2(data));
        } else if (indexType === 6) {
          // props.dispatch(dashboardActions.getLeaderIndexV2(data));
        } else if (indexType === 8) {
          props.dispatch(dashboardActions.getOrgIndexV2(data));
        }
      }

      function callDemographicapi(
        indexType,
        props,
        data,
        getHappinessIndexDriverData,
        getEngagementIndexDriverData,
        getStressIndexDriverData,
        getManagerIndexDriverData,
        getLeaderIndexDriverData,
        getOrgIndexDriverData
      ) {
        if (indexType === 1) {
          props.dispatch(dashboardActions.getDemographicIndexHappiness(data));
          props.dispatch(
            dashboardActions.getHappinessIndexDriver({
              surveyId: data.surveyId,
            })
          );
        } else if (indexType === 2) {
          props.dispatch(dashboardActions.getDemographicIndexEngagement(data));
          props.dispatch(
            dashboardActions.getEngagementIndexDriver({
              surveyId: data.surveyId,
            })
          );
        } else if (indexType === 4) {
          props.dispatch(dashboardActions.getDemographicIndexStress(data));
          props.dispatch(
            dashboardActions.getStressIndexDriver({ surveyId: data.surveyId })
          );
        } else if (indexType === 5) {
          props.dispatch(dashboardActions.getDemographicIndexManager(data));
          props.dispatch(
            dashboardActions.getManagerIndexDriver({ surveyId: data.surveyId })
          );
        } else if (indexType === 6) {
          props.dispatch(dashboardActions.getDemographicIndexLeader(data));
          props.dispatch(
            dashboardActions.getLeaderIndexDriver({ surveyId: data.surveyId })
          );
        } else if (indexType === 8) {
          props.dispatch(dashboardActions.getDemographicIndexOrg(data));
          props.dispatch(
            dashboardActions.getOrgIndexDriver({ surveyId: data.surveyId })
          );
        }
      }

      if (indexType) {
        if (indexType === 102) {
          //MANAGER INSIGHT
          this.onManagerList(surveyId);
        } else if (indexType === 103) {
          // ADVANCE INSIGHT
          this.onAdvanceComparision(surveyId, filterNames);
        } else if (indexType === 104) {
          // TEXT ANALYSIS
          this.onTextAnalysis(surveyId);
        } else if (indexType === 105) {
          // STRENGHT & WEAKNESS
          this.onStrengthAndWeaknessess(surveyId);
        } else if (indexType === 106) {
          // MANAGER ACTION PLAN
          // this.managerActionPlan(surveyId);
          this.onManagerActionPlanSelect(surveyId);
        } else if (indexType === 107) {
          // REPORT
          // this.managerActionPlan(surveyId);
          this.onReport(surveyId);
        } else if (indexType === 108) {
          // REPORT
          // this.managerActionPlan(surveyId);
          this.onManagerGoalPlan(surveyId);
        } else if (indexType === 121) {
          // REPORT
          // this.managerActionPlan(categoryId);
          this.onPeopleToMeetTab();
        } else {
          if (optionType === 1) {
            let data1 = {
              surveyId: surveyId,
            };
            let data3 = {
              surveyId: surveyId,
              index: indexType,
            };
            // this.props.dispatch(dashboardActions.cronbatchAlphaVariance(data3));
            // this.props.dispatch(dashboardActions.getRespondantDetails(data1));
            // this.props.dispatch(dashboardActions.getIndex(data1));

            if (indexType === 2) {
              let data6 = {
                keyWord: "",
                pageNo: 1,
                size: 10,
              };
              this.props.dispatch(dashboardActions.getHistoryIndex(data6));
            }

            callIndexapi(indexType, this.props, data1);
          } else if (optionType === 2) {
            let data3 = {
              surveyId: surveyId,
              xAxis: filterNames,
            };
            callDemographicapi(
              indexType,
              this.props,
              data3,
              getHappinessIndexDriverData,
              getEngagementIndexDriverData,
              getStressIndexDriverData,
              getManagerIndexDriverData,
              getLeaderIndexDriverData,
              getOrgIndexDriverData
            );
          } else if (optionType === 3) {
            let data2 = {
              surveyId: surveyId,
              template: indexType,
            };
            this.props.dispatch(dashboardActions.getQuestion(data2));
          } else if (optionType === 4) {
            let data2 = {
              surveyId: surveyId,
              template: indexType,
            };
            // this.props.dispatch(dashboardActions.getQuestion(data2));
            this.props.dispatch(
              dashboardActions.getQuestionDistribution(data2)
            );
          } else if (optionType === 5) {
            let data2 = {
              surveyId: surveyId,
              index: indexType,
            };
            this.props.dispatch(dashboardActions.getIndexQuantile(data2));
          } else if (optionType === 6) {
            let data2 = {
              surveyId: surveyId,
              template: indexType,
            };
            this.props.dispatch(dashboardActions.getQuestionMap(data2));
            this.props.dispatch(dashboardActions.getQuestion(data2));
          } else if (optionType === 7) {
            let data11 = {
              surveyId: surveyId,
              otherType: 6,
            };
            let data2222 = {
              surveyId: surveyId,
              npsRange: ["9", "10"],
              template: indexType,
              filters: [],
            };
            this.props.dispatch(
              dashboardActions.getNameQuestion(data11, 1, data2222)
            );
            // this.props.dispatch(dashboardActions.getIndexVsNps(data2222));
          } else if (optionType === 8) {
            let data11 = {
              surveyId: surveyId,
              otherType: 16,
            };
            let data2222 = {
              surveyId: surveyId,
              npsRange: ["8", "9", "10"],
              template: indexType,
              filters: [],
            };
            this.props.dispatch(
              dashboardActions.getNameQuestion(data11, 1, data2222)
            );
            // this.props.dispatch(dashboardActions.getIndexVsNps(data2222));
          }
        }
      } else {
        this.setState({
          showOverview: true,
          showIndex: false,
          showManagerList: false,
          showOrgCore: false,
          showAdvanceComparision: false,
          showTextAnalysis: false,
          showStrengthAndWeaknesses: false,
          showManagerActionPlan: false,
          showManagerGoalPlan: false,
          showReport: false,
          showOtherTypes: false,
          showCustomScoreCard: false,
          showPeopleToMeet: false,
        });

        this.LoadOverviewInitFn();
      }
    }
  };

  firstWord = (ClientProfileData) => {
    if (ClientProfileData && ClientProfileData.name) {
      var firstWords = [];
      var firstWords2 = [];
      var codelines = ClientProfileData.name;
      for (var i = 0; i < codelines.length; i++) {
        var words = codelines[i].split(" ");

        firstWords.push(words[0]);
      }
      if (firstWords && firstWords.length > 0) {
        firstWords.every((name, index) => {
          if (name === "") {
            return false;
          } else {
            firstWords2.push(name);
            return true;
          }
        });
      }
      var Username = firstWords2.join("");

      return Username;
    }
    return "User";
  };

  onOverview = (surveyId) => {
    this.setState({
      showOverview: true,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false,
    });
    // this.setState({CurrentSurveyId:surveyId})
  };

  handleManagerDashboardCall = (mngr) => {
    let { ManagerListDasboardDataObj } = this.state;
    let data = {
      surveyId: this.state.CurrentSurveyId,
      filters: [
        {
          level: "ManagerPID",
          value: mngr.id,
        },
      ],
    };
    for (let i = 0; i < 2; i++) {
      if (
        ManagerListDasboardDataObj.findIndex((prev) => prev === mngr.id) === -1
      ) {
        this.props.dispatch(
          dashboardActions.getManagerListDashboardManagerIndex(
            data,
            mngr,
            ManagerListDasboardDataObj
          )
        );
        let asdas = ManagerListDasboardDataObj;
      }
    }
    setTimeout(() => {
      this.setState({ ManagerListDasboardDataObj });
    }, 3000);
  };

  handleCallTemp = () => {
    let data = {
      surveyId: this.state.CurrentSurveyId,
      xAxis: 80000011,
    };
    this.props.dispatch(dashboardActions.getDemographicIndex(data));
  };

  handleManagerActionTaskComplete = (task) => {
    let data = {
      surveyId: this.state.CurrentSurveyId,
      taskId: task.id,
      remark: task.remark,
    };
    this.props.dispatch(dashboardActions.updateEmployeeTask(data));
  };

  handleManagerActionResourceComplete = (resource) => {
    let data = {
      surveyId: this.state.CurrentSurveyId,
      resourceId: resource.id,
      remark: resource.remark,
    };
    this.props.dispatch(dashboardActions.updateEmployeeResource(data));
  };

  onCrossTab = () => {
    this.props.dispatch(dashboardActions.emptycrossTabAnalysis());

    let data = {
      surveyId: this.state.CurrentSurveyId,
    };

    this.props.dispatch(questionsActions.getQuestionListBySurveyID(data));
  };

  handleCrossTabApi = (QuesID1, QuesID2, FilterValues) => {
    let data = {
      // "xAxis": "Department",
      surveyId: this.state.CurrentSurveyId,
      questionId1: QuesID1.id,
      questionId2: QuesID2.id,
      filters: FilterValues,
    };
    // this.props.dispatch(dashboardActions.crossTab(data));

    this.props.dispatch(dashboardActions.crossTabAnalysis(data));
  };

  IndexDriverScore = (template) => {
    let data = {
      surveyId: this.state.CurrentSurveyId,
    };
    if (template === 1) {
      this.props.dispatch(dashboardActions.getHappinessIndexDriver(data));
    } else if (template === 2) {
      this.props.dispatch(dashboardActions.getEngagementIndexDriver(data));
    } else if (template === 4) {
      this.props.dispatch(dashboardActions.getStressIndexDriver(data));
    } else if (template === 5) {
      this.props.dispatch(dashboardActions.getManagerIndexDriver(data));
    } else if (template === 6) {
      this.props.dispatch(dashboardActions.getLeaderIndexDriver(data));
    }
  };

  AssignManagerActionPlan = (data) => {
    let { CurrentSurveyId } = this.state;
    let temp = {
      surveyId: CurrentSurveyId,
      managerList: data,
    };
    ////console.log('temp:--------------> ',temp)
    this.props.dispatch(dashboardActions.AssignManagerActionPlan(temp));
  };

  //Report apis

  onReport = (surveyId) => {
    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: true,
      showOtherTypes: false,
      showPeopleToMeet: false,
    });

    let data = {
      surveyId: this.state.CurrentSurveyId,
    };
  };

  // onLoadIndex=(indexType,check, filtersListNew,isFilterTwice,name, isClose)=>{

  //   let X2Axis = "";
  //   let Filters = [];
  //   let isAllCheck = false;
  //   let isOverall = false
  //   if(filtersListNew && filtersListNew.length>0){
  //     let getIndex = filtersListNew.findIndex(prev=>prev.value === "All");
  //     if(getIndex !== -1){
  //       X2Axis = filtersListNew[getIndex].level;
  //       filtersListNew.forEach((item)=>{
  //         if(item.value !== "All"){
  //           Filters.push(item);
  //         }
  //         else{
  //           isAllCheck = true
  //         }
  //       });
  //     }
  //     else{
  //       X2Axis = filtersListNew[filtersListNew.length-1].level;
  //       Filters = filtersListNew;
  //     }

  //     this.setState({isReportOverall:false});
  //   }
  //   else{
  //     this.setState({isReportOverall:true});
  //     isOverall = true;
  //   }

  //   // let data7 = {
  //   //   "surveyId": this.state.CurrentSurveyId,
  //   //   "xAxis": isFilterTwice?name:X2Axis,
  //   //   "filters":Filters
  //   // }

  //   let filter = Filters;

  //   let isFilter = false;
  //   let xAxis = "";
  //   if(filter && filter.length>0){
  //     isFilter=true;
  //     xAxis = isFilterTwice?name:X2Axis;
  //     // xAxis = filter && filter[0] && filter[0]["level"]?filter[0]["level"]:""
  //     this.setState({isFilter:true});
  //   }
  //   else{
  //     this.setState({isFilter:false});
  //   }

  //   let data1 = {
  //     "surveyId":this.state.CurrentSurveyId
  //   }

  //   this.props.dispatch(dashboardActions.getRespondantDetails({...data1,"filters":filter}));

  //   if(!isClose){

  //     if(!isOverall){
  //       this.setState({
  //         ReportDMGindexType:indexType,
  //         ReportDMGcheck:check,
  //         ReportDMGfiltersListNew:Filters,
  //         ReportxAxis:xAxis
  //       });
  //       this.setState({showReportDMGModal:true});

  //     }
  //     else{
  //       this.setState({loadReport:check});
  //       this.onLoadIndexConfirm(indexType,check, filtersListNew);
  //       this.setState({showReportDMGModal:false});

  //     }

  //   }
  //   else{

  //       let data1x1 = {
  //         "surveyId":this.state.CurrentSurveyId,
  //         "filters":[]
  //       }
  //       this.props.dispatch(dashboardActions.getIndexFilter(data1x1));

  //       this.setState({
  //         ReportDMGindexType:"",
  //         ReportDMGcheck:"",
  //         ReportDMGfiltersListNew:"",
  //         ReportxAxis:"",
  //         showReportDMGModal:false,
  //         loadReport:false,
  //         isFilter:false
  //       });
  //   }

  // }

  onLoadIndex = (
    indexType,
    check,
    filtersListNew,
    isFilterTwice,
    name,
    isClose
  ) => {
    let X2Axis = "";
    let Filters = [];
    let isAllCheck = false;
    let isOverall = false;
    if (filtersListNew && filtersListNew.length > 0) {
      let getIndex = filtersListNew.findIndex(
        (prev) => prev.valueList === "All"
      );
      if (getIndex !== -1) {
        X2Axis = filtersListNew[getIndex].level;
        filtersListNew.forEach((item) => {
          if (item.valueList && item.valueList.length > 0) {
            if (item.valueList[0] !== ["All"]) {
              Filters.push(item);
            } else {
              isAllCheck = true;
            }
          } else {
            isAllCheck = true;
          }
        });
      } else {
        X2Axis = filtersListNew[filtersListNew.length - 1].level;
        Filters = filtersListNew;
      }

      this.setState({ isReportOverall: false });
    } else {
      this.setState({ isReportOverall: true });
      isOverall = true;
    }

    // let data7 = {
    //   "surveyId": this.state.CurrentSurveyId,
    //   "xAxis": isFilterTwice?name:X2Axis,
    //   "filters":Filters
    // }

    let MultiValueFilters = [];
    let AllLevelsList = [];
    let FilLevelsList = [];

    if (Filters && Filters.length > 0) {
      Filters.forEach((inner) => {
        if (inner && inner.valueList && inner.valueList.length > 0) {
          inner.valueList.forEach((item) => {
            if (item !== "All") {
              MultiValueFilters.push({
                level: inner.level,
                value: item,
              });
              FilLevelsList.push(inner.level);
            } else {
              AllLevelsList.push(inner.level);
            }
          });
        }
      });
    }

    let NewXaxis = "";
    if (AllLevelsList && AllLevelsList.length > 0) {
      NewXaxis = AllLevelsList[AllLevelsList.length - 1]
        ? AllLevelsList[AllLevelsList.length - 1]
        : "";
    } else {
      if (FilLevelsList && FilLevelsList.length > 0) {
        NewXaxis = FilLevelsList[FilLevelsList.length - 1]
          ? FilLevelsList[FilLevelsList.length - 1]
          : "";
      } else {
        NewXaxis = X2Axis;
      }
    }

    ////////console.log("AllLevelsList---confirm2--->",AllLevelsList)
    ////////console.log("FilLevelsList---confirm2--->",FilLevelsList)
    ////////console.log("NewXaxis---confirm2--->",NewXaxis)
    ////////console.log("MultiValueFilters---X2Axis--->",X2Axis)

    ////////console.log("MultiValueFilters---confirm2--->",MultiValueFilters)

    let filter = MultiValueFilters;

    let isFilter = false;
    let xAxis = "";
    if (filter && filter.length > 0) {
      isFilter = true;
      xAxis = isFilterTwice ? name : X2Axis;
      // xAxis = filter && filter[0] && filter[0]["level"]?filter[0]["level"]:""
      this.setState({ isFilter: true });
    } else {
      this.setState({ isFilter: false });
    }

    let data1 = {
      surveyId: this.state.CurrentSurveyId,
    };

    this.props.dispatch(
      dashboardActions.getRespondantDetails({ ...data1, filters: filter })
    );

    if (!isClose) {
      ////////console.log("filtersListNew---confirm1--->",filtersListNew)
      ////////console.log("filtersListNew---confirm2--->",filter)

      if (!isOverall) {
        this.setState({
          ReportDMGindexType: indexType,
          ReportDMGcheck: check,
          ReportDMGfiltersListNew: filter,
          ReportxAxis: NewXaxis,
        });
        this.setState({ showReportDMGModal: true });
      } else {
        this.setState({ loadReport: check });
        this.onLoadIndexConfirm(indexType, check, filtersListNew);
        this.setState({ showReportDMGModal: false });
      }
    } else {
      let data1x1 = {
        surveyId: this.state.CurrentSurveyId,
        filters: [],
      };
      this.props.dispatch(dashboardActions.getIndexFilter(data1x1));

      this.setState({
        ReportDMGindexType: "",
        ReportDMGcheck: "",
        ReportDMGfiltersListNew: "",
        ReportxAxis: "",
        showReportDMGModal: false,
        loadReport: false,
        isFilter: false,
      });
    }
  };

  onLoadDMGReportConfirmation = (value) => {
    if (value) {
      this.setState({ loadReport: true });
      let {
        ReportDMGindexType,
        ReportDMGcheck,
        ReportDMGfiltersListNew,
        ReportxAxis,
      } = this.state;
      this.onLoadIndexConfirm(
        ReportDMGindexType,
        ReportDMGcheck,
        ReportDMGfiltersListNew,
        ReportxAxis
      );
      this.setState({ showReportDMGModal: false });
    } else {
      this.setState({ loadReport: false });
      this.setState({ showReportDMGModal: false });
    }
  };

  onLoadIndexConfirm = (indexType, check, filtersListNew, xAxis) => {
    this.setState({ loadReport: check });

    let filter = filtersListNew;

    // let isFilter = false;
    // let xAxis = "";
    if (filter && filter.length > 0) {
      // isFilter=true;
      // xAxis = filter && filter[0] && filter[0]["level"]?filter[0]["level"]:""
      this.setState({ isFilter: true });
    } else {
      this.setState({ isFilter: false });
    }

    let { isReportOverall } = this.state;

    let isFilter = !isReportOverall;

    // if(isFilter){
    //   this.props.dispatch(dashboardActions.getRespondantDetails({...data1,"filters":filter}));
    // }
    // else{
    //   this.props.dispatch(dashboardActions.getRespondantDetails({...data1,"filters":filter}));

    // }
    let data1x1 = {
      surveyId: this.state.CurrentSurveyId,
      filters: filter,
    };
    // "xAxis": xAxis

    ////////console.log("------data1x1-------",data1x1)
    // this.props.dispatch(dashboardActions.getDemographicIndexMultiFilter(data1x1));
    this.props.dispatch(dashboardActions.getIndexFilter(data1x1));

    if (true) {
      let data1 = {
        surveyId: this.state.CurrentSurveyId,
      };
      this.props.dispatch(
        dashboardActions.getRespondantDetails({ ...data1, filters: filter })
      );
      this.props.dispatch(dashboardActions.getOverallIndex(data1));

      if (isFilter) {
        let data1x1 = {
          surveyId: this.state.CurrentSurveyId,
          filters: filter,
        };
        // "xAxis": xAxis

        ////////console.log("------data1x1-------",data1x1)
        // this.props.dispatch(dashboardActions.getDemographicIndexMultiFilter(data1x1));
        this.props.dispatch(dashboardActions.getIndexFilter(data1x1));
      } else {
        let data1x2 = {
          surveyId: this.state.CurrentSurveyId,
        };
        this.props.dispatch(dashboardActions.getIndex(data1x2));
      }

      let { dashboard } = this.props;
      let { getIndexData } = dashboard;

      ////////console.log("repoirt --->getIndexData: ",getIndexData)

      let IndexValidList = getIndexData ? Object.keys(getIndexData) : [];

      if (IndexValidList && IndexValidList.length > 0) {
        IndexValidList.forEach((item) => {
          if (item && getIndexData && getIndexData[item]) {
            ////////console.log("repoirt --->item: ",item)

            if (parseInt(item) === 1) {
              this.props.dispatch(dashboardActions.getHappinessIndexV2(data1));
              this.props.dispatch(
                dashboardActions.getHappinessIndexDriver(data1)
              );

              if (isFilter) {
                this.props.dispatch(
                  dashboardActions.getHappinessIndexV2Filter({
                    ...data1,
                    filters: filter,
                  })
                );
                this.props.dispatch(
                  dashboardActions.getHappinessIndexDriverFilter({
                    ...data1,
                    filters: filter,
                  })
                );
              } else {
                // this.props.dispatch(dashboardActions.getHappinessIndexV2(data1));
                // this.props.dispatch(dashboardActions.getHappinessIndexDriver(data1));
              }

              //Overall
              this.props.dispatch(
                dashboardActions.getHappinessDMGQuestion({
                  ...data1,
                  template: parseInt(item),
                  filters: [],
                })
              );

              //Filter
              this.props.dispatch(
                dashboardActions.getHappinessQuestion({
                  ...data1,
                  template: parseInt(item),
                  filters: filter,
                })
              );
            } else if (parseInt(item) === 2) {
              this.props.dispatch(dashboardActions.getEngagementIndexV2(data1));
              this.props.dispatch(
                dashboardActions.getEngagementIndexDriver(data1)
              );

              if (isFilter) {
                ////////console.log("--filter---","YES")
                this.props.dispatch(
                  dashboardActions.getEngagementIndexV2Filter({
                    ...data1,
                    filters: filter,
                  })
                );

                this.props.dispatch(
                  dashboardActions.getEngagementIndexDriverFilter({
                    ...data1,
                    filters: filter,
                  })
                );
              } else {
                // this.props.dispatch(dashboardActions.getEngagementIndexV2(data1));
                // this.props.dispatch(dashboardActions.getEngagementIndexDriver(data1));
              }
              // this.props.dispatch(dashboardActions.getEngagementIndexV2(data1));
              //Overall
              this.props.dispatch(
                dashboardActions.getEngagementDMGQuestion({
                  ...data1,
                  template: parseInt(item),
                  filters: [],
                })
              );
              //Filter
              this.props.dispatch(
                dashboardActions.getEngagementQuestion({
                  ...data1,
                  template: parseInt(item),
                  filters: filter,
                })
              );
            } else if (parseInt(item) === 4) {
              this.props.dispatch(dashboardActions.getStressIndexV2(data1));
              this.props.dispatch(dashboardActions.getStressIndexDriver(data1));
              if (isFilter) {
                this.props.dispatch(
                  dashboardActions.getStressIndexV2Filter({
                    ...data1,
                    filters: filter,
                  })
                );
                this.props.dispatch(
                  dashboardActions.getStressIndexDriverFilter({
                    ...data1,
                    filters: filter,
                  })
                );
              } else {
                // this.props.dispatch(dashboardActions.getStressIndexV2(data1));
                // this.props.dispatch(dashboardActions.getStressIndexDriver(data1));
              }
              // this.props.dispatch(dashboardActions.getStressIndexV2(data1));
              //Overall
              this.props.dispatch(
                dashboardActions.getStressDMGQuestion({
                  ...data1,
                  template: parseInt(item),
                  filters: [],
                })
              );
              //Filter
              this.props.dispatch(
                dashboardActions.getStressQuestion({
                  ...data1,
                  template: parseInt(item),
                  filters: filter,
                })
              );
            } else if (parseInt(item) === 5) {
              // this.props.dispatch(dashboardActions.getManagerIndexV2(data1));
              // this.props.dispatch(dashboardActions.getManagerIndexDriver(data1));
              //Overall
              this.props.dispatch(
                dashboardActions.getManagerDMGQuestion({
                  ...data1,
                  template: parseInt(item),
                  filters: [],
                })
              );
              //Filter
              this.props.dispatch(
                dashboardActions.getManagerQuestion({
                  ...data1,
                  template: parseInt(item),
                  filters: filter,
                })
              );
              if (isFilter) {
                this.props.dispatch(
                  dashboardActions.getDemographicIndexManager({
                    ...data1,
                    filters: filter,
                    xAxis: xAxis,
                  })
                );
              } else {
                this.props.dispatch(
                  dashboardActions.getManagerIndexDriver(data1)
                );
              }
            } else if (parseInt(item) === 6) {
              // this.props.dispatch(dashboardActions.getLeaderIndexV2(data1));
              // this.props.dispatch(dashboardActions.getLeaderIndexDriver(data1));
              //Overall
              this.props.dispatch(
                dashboardActions.getLeaderDMGQuestion({
                  ...data1,
                  template: parseInt(item),
                  filters: [],
                })
              );
              //Filter
              this.props.dispatch(
                dashboardActions.getLeaderQuestion({
                  ...data1,
                  template: parseInt(item),
                  filters: filter,
                })
              );
              if (isFilter) {
                this.props.dispatch(
                  dashboardActions.getDemographicIndexLeader({
                    ...data1,
                    filters: filter,
                    xAxis: xAxis,
                  })
                );
              } else {
                this.props.dispatch(
                  dashboardActions.getLeaderIndexDriver(data1)
                );
              }
            }
          }
        });
      }

      // Page9
      //overall org
      let data9x = {
        surveyId: this.state.CurrentSurveyId,
        template: 8,
        filters: [],
      };
      this.props.dispatch(dashboardActions.getQuestionOverall(data9x));
      //Filter org
      let data9 = {
        surveyId: this.state.CurrentSurveyId,
        template: 8,
        filters: filter,
      };
      this.props.dispatch(dashboardActions.getQuestionFilter(data9));

      let allowAPI = true;

      if (true) {
        //Page1

        if (allowAPI) {
          // getHappinessIndexV2,
          // getEngagementIndexV2,
          // getHopeIndexV2,
          // getStressIndexV2,
          // getManagerIndexV2,
          // getLeaderIndexV2,
          // this.props.dispatch(dashboardActions.getAllBenchMark());
          // getHappinessIndexDriver,
          // getEngagementIndexDriver,
          // getStressIndexDriver,
          // getManagerIndexDriver,
          // getLeaderIndexDriver,
        }

        //Page2
        let data2 = {
          surveyId: this.state.CurrentSurveyId,
          template: indexType,
          filters: filter,
        };

        if (allowAPI) {
          this.props.dispatch(dashboardActions.getQuestion(data2));

          this.props.dispatch(dashboardActions.getOrgIndexV2(data1));

          //Page3
          if (isFilter) {
            this.props.dispatch(
              dashboardActions.getDemographicIndexOrg({
                ...data1,
                filters: filter,
                xAxis: xAxis,
              })
            );
            this.props.dispatch(
              dashboardActions.getOrgIndexV2Filter({
                ...data1,
                filters: filter,
              })
            );
          } else {
            this.props.dispatch(dashboardActions.getOrgIndexV2(data1));
          }

          this.props.dispatch(surveyActions.getSurveyById(data1));
        }

        //Page4
        let data3 = {
          surveyId: this.state.CurrentSurveyId,
          index: indexType,
          filters: filter,
          addValueTemplate: [],
        };

        let { getIndexData } = this.props;

        if (getIndexData && getIndexData["5"]) {
          data3["addValueTemplate"].push(5);
        }

        if (getIndexData && getIndexData["6"]) {
          data3["addValueTemplate"].push(6);
        }

        if (allowAPI) {
          this.props.dispatch(dashboardActions.getEngagementRWA(data3));
        }

        //Page5
        let data4 = {
          surveyId: this.state.CurrentSurveyId,
          limit: 10,
          top: true,
          filters: filter,
        };

        if (allowAPI) {
          this.props.dispatch(dashboardActions.getQuestionFavorability(data4));
        }

        //Page6
        let data5 = {
          surveyId: this.state.CurrentSurveyId,
          limit: 10,
          top: false,
          filters: filter,
        };

        if (allowAPI) {
          this.props.dispatch(dashboardActions.getQuestionFavorability2(data5));
        }

        let { dashboard } = this.props;
        let { EmpFilterData } = dashboard;

        if (!isFilter) {
          // Page7

          let data6 = [];

          let data7 = [];

          if (EmpFilterData && EmpFilterData.length > 0) {
            EmpFilterData.forEach((filter1) => {
              if (filter1.isShowReport) {
                data6.push({
                  surveyId: this.state.CurrentSurveyId,
                  xAxis: filter1 && filter1.name ? filter1.name : "",
                });

                data7.push({
                  surveyId: this.state.CurrentSurveyId,
                  xAxis: filter1 && filter1.name ? filter1.name : "",
                });
              }
            });
          }

          if (allowAPI) {
            this.props.dispatch(
              dashboardActions.getDemographicIndexFilterReport(data6)
            );
          }

          // Page8
          this.props.dispatch(dashboardActions.getDemographicOrgReport(data7));
        } else {
          // Page7

          let data6 = [];

          let data7 = [];

          // if(EmpFilterData && EmpFilterData.length>0){
          //   EmpFilterData.forEach((filter1)=>{
          //     if(filter1.isShowReport){

          data6.push({
            surveyId: this.state.CurrentSurveyId,
            xAxis: xAxis,
            filters: filter,
          });

          data7.push({
            surveyId: this.state.CurrentSurveyId,
            xAxis: xAxis,
            filters: filter,
          });

          //   }

          //   });
          // }

          if (allowAPI) {
            this.props.dispatch(
              dashboardActions.getDemographicIndexFilterReport(data6)
            );
          }

          // Page8
          this.props.dispatch(dashboardActions.getDemographicOrgReport(data7));
        }

        // NPS------------------------------------------------------------------------

        let dataNPSOverall = {
          surveyId: this.state.CurrentSurveyId,
          qtemplateList: [6],
          filters: [],
        };

        this.props.dispatch(
          generalActions.getQuestion2OthersResultFilter(dataNPSOverall)
        );

        let dataNPS = {
          surveyId: this.state.CurrentSurveyId,
          qtemplateList: [6],
          filters: filter,
        };

        this.props.dispatch(generalActions.getQuestionOthersResult(dataNPS));

        let dataNPSFilter = [];
        ////////console.log("EmpFilterData",EmpFilterData)

        if (EmpFilterData && EmpFilterData.length > 0) {
          EmpFilterData.forEach((filter1) => {
            if (filter1.isInEngComp) {
              ////////console.log("dataNPSFilter",filter1)
              dataNPSFilter.push({
                surveyId: this.state.CurrentSurveyId,
                qtemplateList: [6],
                xAxis: filter1 && filter1.name ? filter1.name : "",
                filters: filter,
              });
            }
          });
        }

        if (dataNPSFilter && dataNPSFilter.length > 0) {
          this.props.dispatch(
            generalActions.getQuestionOthersMultiResultFilter(dataNPSFilter)
          );
        }

        // NPS---end---------------------------------------------------------------------

        // Engagement Comparison
        let data10 = [];
        let data10x = [];

        if (EmpFilterData && EmpFilterData.length > 0) {
          EmpFilterData.forEach((filter1) => {
            if (filter1.isInEngComp) {
              data10.push({
                surveyId: this.state.CurrentSurveyId,
                xAxis: filter1 && filter1.name ? filter1.name : "",
                filters: filter,
              });

              data10x.push({
                surveyId: this.state.CurrentSurveyId,
                xAxis: filter1 && filter1.name ? filter1.name : "",
                filters: [],
              });
            }
          });
        }

        //Filter
        setTimeout(() => {
          this.props.dispatch(
            dashboardActions.getDemographicIndexMultiFilterEngComp(data10)
          );
        }, 400);

        //Overall
        setTimeout(() => {
          this.props.dispatch(
            dashboardActions.getDemographicIndexEngCompOverall(data10x)
          );
        }, 400);

        // this.props.dispatch(dashboardActions.getTextQuestion(data1, 111,filter));
      }
    }
  };

  onLoadManagerReport = (managerDetails) => {
    let { dashboard } = this.props;
    let { getIndexData } = dashboard;
    let { ManagerReportFConfig } = this.state;

    let IndexList = getIndexData ? Object.keys(getIndexData) : [];

    let mngrId =
      managerDetails && managerDetails["emp_id"]
        ? managerDetails["emp_id"]
        : "";
    let mngrDMGValue =
      managerDetails && managerDetails["Department"]
        ? managerDetails["Department"]
        : "";

    if (mngrId) {
      let data1 = {
        surveyId: this.state.CurrentSurveyId,
      };

      this.props.dispatch(surveyActions.getSurveyById(data1));

      let managerIDNumber = mngrId;

      if (ManagerReportFConfig && ManagerReportFConfig.managerIdType) {
        if (ManagerReportFConfig.managerIdType === "Int") {
          managerIDNumber = parseInt(mngrId);
        } else if (ManagerReportFConfig.managerIdType === "String") {
          managerIDNumber = mngrId.toString();
        }
      }

      if (
        IndexList.some((prev) => prev.toString() === "1") &&
        getIndexData &&
        getIndexData["1"]
      ) {
        this.props.dispatch(
          dashboardActions.getDemographicIndexHappiness({
            ...data1,
            xAxis: "ManagerPID",
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber.toString(),
              },
            ],
          })
        );

        this.props.dispatch(
          dashboardActions.getHappinessIndexByFilter({
            ...data1,
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber,
              },
            ],
          })
        );

        //Happiness Manager
        this.props.dispatch(
          dashboardActions.getHappinessQuestion({
            ...data1,
            template: 1,
            filters: [],
          })
        );
        this.props.dispatch(
          dashboardActions.getHappinessManagerQuestion({
            ...data1,
            template: 1,
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber,
              },
            ],
          })
        );
        if (mngrDMGValue) {
          this.props.dispatch(
            dashboardActions.getHappinessDMGQuestion({
              ...data1,
              template: 1,
              filters: [
                {
                  level: "Department",
                  value: mngrDMGValue.toString(),
                },
              ],
            })
          );
        }
      }

      if (
        IndexList.some((prev) => prev.toString() === "2") &&
        getIndexData &&
        getIndexData["2"]
      ) {
        this.props.dispatch(
          dashboardActions.getDemographicIndexEngagement({
            ...data1,
            xAxis: "ManagerPID",
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber.toString(),
              },
            ],
          })
        );

        this.props.dispatch(
          dashboardActions.getEngagementIndexByFilter({
            ...data1,
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber,
              },
            ],
          })
        );

        //Engagement Manager
        this.props.dispatch(
          dashboardActions.getEngagementQuestion({
            ...data1,
            template: 2,
            filters: [],
          })
        );
        this.props.dispatch(
          dashboardActions.getEngagementManagerQuestion({
            ...data1,
            template: 2,
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber,
              },
            ],
          })
        );
        if (mngrDMGValue) {
          this.props.dispatch(
            dashboardActions.getEngagementDMGQuestion({
              ...data1,
              template: 2,
              filters: [
                {
                  level: "Department",
                  value: mngrDMGValue.toString(),
                },
              ],
            })
          );
        }
      }

      if (
        IndexList.some((prev) => prev.toString() === "4") &&
        getIndexData &&
        getIndexData["4"]
      ) {
        this.props.dispatch(
          dashboardActions.getDemographicIndexStress({
            ...data1,
            xAxis: "ManagerPID",
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber.toString(),
              },
            ],
          })
        );

        this.props.dispatch(
          dashboardActions.getStressIndexByFilter({
            ...data1,
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber,
              },
            ],
          })
        );

        //Stress Manager
        this.props.dispatch(
          dashboardActions.getStressQuestion({
            ...data1,
            template: 4,
            filters: [],
          })
        );
        this.props.dispatch(
          dashboardActions.getStressManagerQuestion({
            ...data1,
            template: 4,
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber,
              },
            ],
          })
        );
        if (mngrDMGValue) {
          this.props.dispatch(
            dashboardActions.getStressDMGQuestion({
              ...data1,
              template: 4,
              filters: [
                {
                  level: "Department",
                  value: mngrDMGValue.toString(),
                },
              ],
            })
          );
        }
      }

      if (
        IndexList.some((prev) => prev.toString() === "5") &&
        getIndexData &&
        getIndexData["5"]
      ) {
        this.props.dispatch(
          dashboardActions.getDemographicIndexManager({
            ...data1,
            xAxis: "ManagerPID",
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber.toString(),
              },
            ],
          })
        );

        this.props.dispatch(
          dashboardActions.getManagerIndexByFilter({
            ...data1,
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber,
              },
            ],
          })
        );

        //Manager Manager
        this.props.dispatch(
          dashboardActions.getManagerQuestion({
            ...data1,
            template: 5,
            filters: [],
          })
        );
        this.props.dispatch(
          dashboardActions.getManagerManagerQuestion({
            ...data1,
            template: 5,
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber,
              },
            ],
          })
        );
        if (mngrDMGValue) {
          this.props.dispatch(
            dashboardActions.getManagerDMGQuestion({
              ...data1,
              template: 5,
              filters: [
                {
                  level: "Department",
                  value: mngrDMGValue.toString(),
                },
              ],
            })
          );
        }
      }

      if (
        IndexList.some((prev) => prev.toString() === "6") &&
        getIndexData &&
        getIndexData["6"]
      ) {
        this.props.dispatch(
          dashboardActions.getDemographicIndexLeader({
            ...data1,
            xAxis: "ManagerPID",
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber.toString(),
              },
            ],
          })
        );
        this.props.dispatch(
          dashboardActions.getLeaderIndexByFilter({
            ...data1,
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber,
              },
            ],
          })
        );

        //Leader Manager
        this.props.dispatch(
          dashboardActions.getLeaderQuestion({
            ...data1,
            template: 6,
            filters: [],
          })
        );
        this.props.dispatch(
          dashboardActions.getLeaderManagerQuestion({
            ...data1,
            template: 6,
            filters: [
              {
                level: "ManagerPID",
                value: managerIDNumber,
              },
            ],
          })
        );
        if (mngrDMGValue) {
          this.props.dispatch(
            dashboardActions.getLeaderDMGQuestion({
              ...data1,
              template: 6,
              filters: [
                {
                  level: "Department",
                  value: mngrDMGValue.toString(),
                },
              ],
            })
          );
        }
      }
    }
  };

  onTreeMap = (EMPFieldFirstName) => {
    this.props.dispatch(dashboardActions.EmptyTreeMap());

    let data = {
      surveyId: this.state.CurrentSurveyId,
    };
    this.props.dispatch(dashboardActions.getIndex(data));

    let { dashboard } = this.props;
    let { getIndexData } = dashboard;

    let IndexValidList = getIndexData ? Object.keys(getIndexData) : [];
    if (IndexValidList && IndexValidList.length > 0) {
      IndexValidList.forEach((item) => {
        if (item && getIndexData && getIndexData[item]) {
          let template = parseInt(item);
          if (template === 1) {
            this.props.dispatch(dashboardActions.getHappinessIndexDriver(data));
          } else if (template === 2) {
            this.props.dispatch(
              dashboardActions.getEngagementIndexDriver(data)
            );
          } else if (template === 4) {
            this.props.dispatch(dashboardActions.getStressIndexDriver(data));
          } else if (template === 5) {
            this.props.dispatch(dashboardActions.getManagerIndexDriver(data));
          } else if (template === 6) {
            this.props.dispatch(dashboardActions.getLeaderIndexDriver(data));
          }
        }
      });
    }

    let data2 = {
      surveyId: this.state.CurrentSurveyId,
    };
    this.props.dispatch(
      dashboardActions.getTextQuestion(data2, 6, [], EMPFieldFirstName)
    );
  };

  onOthersCardClick = (item) => {
    // ////console.log('item:',item);
    // ////console.log('item Qid:',item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"");

    this.setState(
      {
        showOverview: false,
        showIndex: false,
        showManagerList: false,
        showOrgCore: false,
        showAdvanceComparision: false,
        showTextAnalysis: false,
        showStrengthAndWeaknesses: false,
        showManagerActionPlan: false,
        showManagerGoalPlan: false,
        showCustomScoreCard: false,
        showReport: false,
        showOtherTypes: true,

        showAllQuestions: false,

        showPeopleToMeet: false,

        currentQuestionId:
          item.answerList && item.answerList[0] && item.answerList[0].QuesId
            ? item.answerList[0].QuesId
            : "",
        currentQuestionTemplate:
          item.answerList && item.answerList[0] && item.answerList[0].template
            ? item.answerList[0].template
            : "",
      },
      () => {
        let { currentQuestionTemplate } = this.state;

        let { dashboard } = this.props;
        let { EmpFilterData } = dashboard;

        let AxisInit = "Department";
        if (EmpFilterData && EmpFilterData.length > 0) {
          EmpFilterData.forEach((item, index) => {
            if (item.level && index === 0) {
              AxisInit = item.level;
            }
          });
        }

        if (currentQuestionTemplate !== 13) {
          let data7 = {
            surveyId: this.state.CurrentSurveyId,
            qtemplateList: [currentQuestionTemplate],
            xAxis: AxisInit,
            filters: [],
          };
          this.props.dispatch(generalActions.getQuestionOthersResult(data7));
        }
      }
    );
  };

  handleOthersFilterCall = (FilterValues, isValue, qTemplate) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex((prev) => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      } else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues;
      }
    }

    let data7 = {
      surveyId: this.state.CurrentSurveyId,
      qtemplateList: [qTemplate],
      xAxis: isValue,
      filters: Filters,
    };

    if (qTemplate === 13) {
      this.props.dispatch(generalActions.getQuestionMatrixResult(data7));
    } else {
      this.props.dispatch(generalActions.getQuestionOthersResultFilter(data7));
    }
  };

  onClusterAnalysis = (FilterValues) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex((prev) => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      } else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues;
      }
    }

    let data7 = {
      surveyId: this.state.CurrentSurveyId,
      filters: Filters,
    };

    this.props.dispatch(dashboardActions.clusterAnalysis(data7));
  };

  getClusterEmpData = (empList) => {
    if (empList && empList.length > 0) {
      let data = {
        empList: empList,
      };
      this.props.dispatch(dashboardActions.getClusterEmpInfoList(data));
    }
  };

  onPeopleToMeetTab = () => {
    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: true,
    });
    this.onClusterAnalysis();
  };

  getAllManagerScores = () => {
    let data = {
      surveyId: this.state.CurrentSurveyId,
    };
    ////////console.log("getAllManagerScores--->//////////////////")
    this.props.dispatch(dashboardActions.getAllManagerScores(data));
  };

  onManagerDownloadStatus = () => {
    let data = {
      surveyId: this.state.CurrentSurveyId,
    };
    this.props.dispatch(dashboardActions.getMngrReportDownloadStatus(data));
  };

  onManagerGoalPlan = () => {
    let data = {
      keyWord: "",
      pageNo: 1,
      size: 500,
    };

    ////////console.log("----------------------------------------------onManagerGoalPlan----------------------------------------------------------->")

    this.props.dispatch(dashboardActions.getIDPLastDate({}));

    this.props.dispatch(dashboardActions.getAllManagerGoalPlan(data));
    this.props.dispatch(
      dashboardActions.getDemographicIndexForManager({
        surveyId: this.state.CurrentSurveyId,
      })
    );

    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: true,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false,
    });
  };

  onCustomQuestion = () => {
    let data = {
      template:
        this.state &&
          this.state.customeScoreData &&
          this.state.customeScoreData.index
          ? this.state.customeScoreData.index
          : "",
      surveyId: this.state.CurrentSurveyId,
    };
    this.props.dispatch(dashboardActions.getQuestionCustomTemplate(data));
  };

  handleMultifilterQuesAvgSummary = (FilterValues, isFilterTwice, name) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex((prev) => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      } else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues;
      }
    }

    let data7 = {
      surveyId: this.state.CurrentSurveyId,
      xAxis: isFilterTwice ? name : XAxis,
      filters: Filters,
      qtemplateList: [1],
    };

    if (data7.xAxis) {
      this.props.dispatch(generalActions.getQuestionAvgScore(data7));
    }

    //////console.log('data7:::',data7);
    // //////console.log('data7:::',data7);
  };

  callManagersQuestion = (data) => {
    let data5SSS1 = {
      surveyId: this.state.CurrentSurveyId,
      template: data.indexNumber,
    };
    this.props.dispatch(dashboardActions.getQuestion(data5SSS1));

    let data5SSS = {
      surveyId: this.state.CurrentSurveyId,
      template: data.indexNumber,
      mngrList: data.mngrEmpIdList,
    };
    console.log("data5SSS------->", data5SSS);
    this.props.dispatch(dashboardActions.getQuestionManagerPIDWise(data5SSS));
  };

  callManagersDimension = (data) => {
    let data5SSS1 = {
      surveyId: this.state.CurrentSurveyId,
    };

    let data5SSS = {
      surveyId: this.state.CurrentSurveyId,
      mngrList: data.mngrEmpIdList,
    };
    console.log("data5SSS-----callManagersDimension-->", data5SSS);

    if (data.indexNumber === 1) {
      this.props.dispatch(dashboardActions.getHappinessIndexDriver(data5SSS1));
      this.props.dispatch(
        dashboardActions.getHappinessIndexDriverManagerPIDWise(data5SSS)
      );
    } else if (data.indexNumber === 2) {
      this.props.dispatch(dashboardActions.getEngagementIndexDriver(data5SSS1));
      this.props.dispatch(
        dashboardActions.getEngagementIndexDriverManagerPIDWise(data5SSS)
      );
    } else if (data.indexNumber === 4) {
      this.props.dispatch(dashboardActions.getStressIndexDriver(data5SSS1));
      this.props.dispatch(
        dashboardActions.getStressIndexDriverManagerPIDWise(data5SSS)
      );
    } else if (data.indexNumber === 5) {
      this.props.dispatch(dashboardActions.getManagerIndexDriver(data5SSS1));
      this.props.dispatch(
        dashboardActions.getManagerIndexDriverManagerPIDWise(data5SSS)
      );
    }
  };

  callManagersIndex = (data) => {
    let data5SSS1 = {
      surveyId: this.state.CurrentSurveyId,
    };
    this.props.dispatch(dashboardActions.getIndex(data5SSS1));

    let data5SSS = {
      surveyId: this.state.CurrentSurveyId,
      mngrList: data.mngrEmpIdList,
    };
    console.log("data5SSS------->", data5SSS);
    this.props.dispatch(dashboardActions.getIndexScoreManagerPIDWise(data5SSS));
  };

  onTextCluster = () => {
    let data = {
      surveyId: this.state.CurrentSurveyId,
    };
    this.props.dispatch(dashboardActions.getTextQuestion(data, 9));
  };

  getClusterSentences = (QuesId, FilterValues, clusterNo, keyword) => {
    let { CurrentSurveyId } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex((prev) => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      } else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues;
      }
    }

    let data2 = {
      surveyId: CurrentSurveyId,
      questionId: QuesId,
      filters: Filters,
      cluster: clusterNo,
      keyWord: keyword,
    };

    this.props.dispatch(dashboardActions.getClusterSentences(data2));
  };

  getTextClusterFilter = (QuesId, FilterValues) => {
    let { CurrentSurveyId } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex((prev) => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      } else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues;
      }
    }

    let data2 = {
      surveyId: CurrentSurveyId,
      questionId: QuesId,
      filters: Filters,
    };

    this.props.dispatch(dashboardActions.getTextCluster(data2));
  };

  handleIndexVSNpsType = (npsType, QuesId, FilterValues, isLadder) => {
    let { CurrentSurveyId, indexType } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex((prev) => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      } else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues;
      }
    }

    let data2222 = {
      surveyId: CurrentSurveyId,
      npsRange:
        npsType === 1
          ? ["8", "9", "10"]
          : npsType === 3
            ? ["6", "7"]
            : ["0", "1", "2", "3", "4", "5"],
      template: indexType,
      questionId: QuesId,
      filters: Filters,
    };
    this.props.dispatch(dashboardActions.getIndexVsNps(data2222));
  };
  handleWordCloudDemographicFilter = (
    QuesId,
    FilterValues,
    blank,
    type,
    wordList
  ) => {
    let { CurrentSurveyId } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex((prev) => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      } else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues;
      }
    }

    let data2 = {
      surveyId: CurrentSurveyId,
      questionId: QuesId,
      filters: Filters,
    };

    console.log("------------------------------type====", type);

    if (type === "advance") {
      this.props.dispatch(dashboardActions.AdvanceWordCloud(data2));
    } else if (type === "wordCloud") {
      this.props.dispatch(dashboardActions.wordCloud(data2));
    } else if (type === "topicModelling") {
      let data2x = {
        surveyId: CurrentSurveyId,
        questionId: QuesId,
        wordList: wordList,
        // "wordwithKeywordList":wordList,
        filters: Filters,
      };
      ////////console.log("topic input ------------->",data2x)

      this.props.dispatch(dashboardActions.topicModelling(data2x));
    } else if (type === "topicModellingCustom") {
      let data2x = {
        surveyId: CurrentSurveyId,
        questionId: QuesId,
        wordList: wordList,
        // "wordwithKeywordList":wordList,
        filters: Filters,
      };
      ////////console.log("topic input ------------->",data2x)

      this.props.dispatch(dashboardActions.topicModelling(data2x));
    } else {
      let data3 = {
        surveyId: CurrentSurveyId,
        questionId: QuesId,
        xAxis:
          FilterValues && FilterValues.length > 0
            ? FilterValues[0].level
            : "Department",
      };
      if (type === "textDistTreeMap") {
        this.props.dispatch(dashboardActions.textDistTreeMap(data3));
      } else if (type === "textDist") {
        this.props.dispatch(dashboardActions.textDist(data2));
      } else {
        this.props.dispatch(dashboardActions.TreeMap(data3));
      }
    }
  };
  callWordCloudText = (
    QuesId,
    keyWord,
    FilterList,
    FilterValue,
    isMultiKeyword,
    type
  ) => {
    let { CurrentSurveyId } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterList && FilterList.length > 0) {
      let getIndex = FilterList.findIndex((prev) => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterList[getIndex].level;
        FilterList.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      } else {
        XAxis = FilterList[FilterList.length - 1].level;
        Filters = FilterList;
      }
    }

    if (type === "manyKeywords") {
      let data2 = {
        surveyId: CurrentSurveyId,
        questionId: QuesId,
        keywordList:
          keyWord && keyWord.keywordList && keyWord.keywordList.length > 0
            ? keyWord.keywordList
            : [],
        filters: Filters && Filters.length > 0 ? Filters : [],
      };

      this.props.dispatch(
        dashboardActions.getAllWordKeywordsText(data2, keyWord.topic)
      );
    } else {
      if (isMultiKeyword) {
        let data2 = {
          surveyId: CurrentSurveyId,
          questionId: QuesId,
          keyWord1: keyWord && keyWord[0] ? keyWord[0] : "",
          keyWord2: keyWord && keyWord[1] ? keyWord[1] : "",
          filters: Filters && Filters.length > 0 ? Filters : [],
        };
        this.props.dispatch(dashboardActions.wordCloudMultiText(data2));
      } else {
        let data2 = {
          surveyId: CurrentSurveyId,
          questionId: QuesId,
          keyWord: keyWord,
          filters: Filters && Filters.length > 0 ? Filters : [],
        };
        this.props.dispatch(dashboardActions.wordCloudText(data2));
      }
    }

    if (type === "TreeMap") {
      let data7 = {
        surveyId: CurrentSurveyId,
        xAxis: XAxis,
        filters: Filters && Filters.length > 0 ? Filters : [],
      };

      let { dashboard } = this.props;
      let { getIndexData } = dashboard;
      let IndexValidList = getIndexData ? Object.keys(getIndexData) : [];
      if (IndexValidList && IndexValidList.length > 0) {
        IndexValidList.forEach((item) => {
          if (item && getIndexData && getIndexData[item]) {
            ////////console.log("repoirt --->item: ",item)

            if (parseInt(item) === 1) {
              this.props.dispatch(
                dashboardActions.getDemographicIndexHappiness(data7)
              );
            } else if (parseInt(item) === 2) {
              this.props.dispatch(
                dashboardActions.getDemographicIndexEngagement(data7)
              );
            } else if (parseInt(item) === 4) {
              this.props.dispatch(
                dashboardActions.getDemographicIndexStress(data7)
              );
            } else if (parseInt(item) === 5) {
              this.props.dispatch(
                dashboardActions.getDemographicIndexManager(data7)
              );
            } else if (parseInt(item) === 6) {
              this.props.dispatch(
                dashboardActions.getDemographicIndexLeader(data7)
              );
            }
          }
        });
      }

      this.props.dispatch(
        dashboardActions.getDemographicIndexMultiFilter(data7)
      );
    }
  };

  callQuestionWordCloud = (QuesId, type, wordList) => {
    let { CurrentSurveyId } = this.state;

    if (type === "topicModelling") {
      let data2 = {
        surveyId: CurrentSurveyId,
        questionId: QuesId,
        wordList: wordList,
        filters: [],
      };
      this.props.dispatch(dashboardActions.topicModelling(data2));
    } else {
      let data2 = {
        surveyId: CurrentSurveyId,
        questionId: QuesId,
        filters: [],
      };
      this.props.dispatch(dashboardActions.wordCloud(data2));
    }
  };
  onWorldCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      surveyId: CurrentSurveyId,
    };
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 1));
  };
  onNetworkGraph = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      surveyId: CurrentSurveyId,
    };
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 2));
  };
  onNeightboardGraph = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      surveyId: CurrentSurveyId,
    };
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 3));
  };
  onTextWordDistribution = (EMPFieldFirstName) => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      surveyId: CurrentSurveyId,
    };
    console.log("EMPFieldFirstName---------->", EMPFieldFirstName);
    this.props.dispatch(
      dashboardActions.getTextQuestion(data2, 5, [], EMPFieldFirstName)
    );
  };

  callQuestionNetworkCloud = (QuesId, graphType, FilterList) => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      surveyId: CurrentSurveyId,
      questionId: QuesId,
      filters: FilterList && FilterList.length > 0 ? FilterList : [],
    };
    this.props.dispatch(dashboardActions.neighborGraph(data2));
    // if(graphType===1){
    //   this.props.dispatch(dashboardActions.cooccurmatrix(data2));

    // }
    // else if(graphType===2){
    //   this.props.dispatch(dashboardActions.neighborGraph(data2));
    // }
  };

  handleNetworkDemographicFilter = (Qid, FilterValues, graphType) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex((prev) => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      } else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues;
      }
    }

    let { CurrentSurveyId } = this.state;
    let data2 = {};
    data2 = {
      surveyId: CurrentSurveyId,
      questionId: Qid,
      filters: Filters,
    };
    // ////console.log('----asadata2',data2)

    if (graphType === 1) {
      this.props.dispatch(dashboardActions.cooccurmatrix(data2));
    } else if (graphType === 2) {
      this.props.dispatch(dashboardActions.neighborGraph(data2));
    }
  };
  handleNeighborGraph = (FilterList) => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      surveyId: CurrentSurveyId,
    };
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 3, FilterList));
  };
  onAdvanceWorldCloud = () => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      surveyId: CurrentSurveyId,
    };
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 4));
  };


  onTreeMap = (EMPFieldFirstName) => {
    this.props.dispatch(dashboardActions.EmptyTreeMap());



    let data = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getIndex(data));

    let { dashboard } = this.props;
    let { getIndexData } = dashboard;


    let IndexValidList = getIndexData ? Object.keys(getIndexData) : [];
    if (IndexValidList && IndexValidList.length > 0) {
      IndexValidList.forEach((item) => {
        if (item && getIndexData && getIndexData[item]) {
          let template = parseInt(item);
          if (template === 1) {
            this.props.dispatch(dashboardActions.getHappinessIndexDriver(data));
          }
          else if (template === 2) {
            this.props.dispatch(dashboardActions.getEngagementIndexDriver(data));
          }
          else if (template === 4) {
            this.props.dispatch(dashboardActions.getStressIndexDriver(data));
          }
          else if (template === 5) {
            this.props.dispatch(dashboardActions.getManagerIndexDriver(data));
          }
          else if (template === 6) {
            this.props.dispatch(dashboardActions.getLeaderIndexDriver(data));
          }
        }
      })



    }


    let data2 = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 6, [], EMPFieldFirstName));


  }
  onTopicModel = () => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      surveyId: CurrentSurveyId,
    };
    let data1 = {
      keyWord: "",
      pageNo: 1,
      size: 500,
    };
    this.props.dispatch(dashboardActions.getTopicList(data1));
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 7));
  };
  onCreateTopicSubmit = (data) => {
    let temp = {
      keyWord: "",
      pageNo: 1,
      size: 500,
    };
    this.props.dispatch(dashboardActions.createTopic(data, temp));
  };

  onFeedbackCloud = () => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      surveyId: CurrentSurveyId,
    };
    this.props.dispatch(dashboardActions.getFeedbackTextQuestion(data2));
  };

  callAnswerFeedbackText = (
    QuesId,
    keyWord,
    FilterList,
    FilterValue,
    isMultiKeyword,
    type
  ) => {
    let { CurrentSurveyId } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterList && FilterList.length > 0) {
      let getIndex = FilterList.findIndex((prev) => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterList[getIndex].level;
        FilterList.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      } else {
        XAxis = FilterList[FilterList.length - 1].level;
        Filters = FilterList;
      }
    }

    let data2 = {
      surveyId: CurrentSurveyId,
      questionId: QuesId,
      keyWord: keyWord,
      filters: Filters && Filters.length > 0 ? Filters : [],
    };
    this.props.dispatch(dashboardActions.AnswerFeedbackText(data2));
  };

  handleAnswerFeedbackDemographicFilter = (
    QuesId,
    FilterValues,
    blank,
    type,
    wordList
  ) => {
    let { CurrentSurveyId } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex((prev) => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      } else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues;
      }
    }

    let data2 = {
      surveyId: CurrentSurveyId,
      questionId: QuesId,
      filters: Filters,
    };

    this.props.dispatch(dashboardActions.getOrgCoreDriverSentiment(data2));
  };

  getKeywordsByCluster = (QuesId, FilterValues, clusterNo) => {
    let { CurrentSurveyId } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex((prev) => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      } else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues;
      }
    }

    let data2 = {
      surveyId: CurrentSurveyId,
      questionId: QuesId,
      filters: Filters,
      cluster: clusterNo,
    };

    this.props.dispatch(dashboardActions.getClusterKeywords(data2));
  };

  handleDmgExitCall = (FilterValues) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex((prev) => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      } else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues;
      }
    }

    let data91 = {
      surveyId: this.state.CurrentSurveyId,
      xAxis:
        FilterValues && FilterValues.length > 0
          ? FilterValues[0].level
          : "Department",
    };
    this.props.dispatch(
      dashboardActions.getExitQuestionAllDemographics(data91)
    );
  };

  onOverviewFromHeader = () => {
    let { CurrentSurveyId, showOverview } = this.state;
    if (showOverview) {
      this.props.history.goBack();
    } else {
      this.onOptionType(CurrentSurveyId, 0, 1);
    }
  };

  handleSelectChart = (name) => {
    console.log("parmsparmsparmsparmsparmsparmsparmsparmsparms", name);
    let { dashboard } = this.props;
    let { getExitQuestionAllCountData } = dashboard;

    let emplist = [];
    if (getExitQuestionAllCountData && getExitQuestionAllCountData.length > 0) {
      let getIndex = getExitQuestionAllCountData.findIndex(
        (prev) => prev._id === name
      );
      if (getIndex !== -1) {
        emplist = getExitQuestionAllCountData[getIndex]["emplist"];
      }
    }

    console.log(
      "getExitQuestionAllCountDatagetExitQuestionAllCountDatagetExitQuestionAllCountData",
      getExitQuestionAllCountData
    );
    console.log("empListempListempListempListempListempListempList", emplist);

    let data11 = {
      surveyId: this.state.CurrentSurveyId,
      questionId: "640993bf47000254b2735fac",
      emplist: emplist,
    };
    this.props.dispatch(dashboardActions.rolkeywordbyemployee(data11));
  };


  handleGoBack = () => {
    let { showOtherTypes, showAllQuestions } = this.state;
    if (showOtherTypes && !showAllQuestions) {
      this.setState({
        showOverview: false,
        showAllQuestions: true,
        showOtherTypes: true
      })
    } else if (showOtherTypes && showAllQuestions) {
      this.setState({
        showOverview: true,
        showAllQuestions: false,
        showOtherTypes: false
      })
    }
  }


  render() {
    let { dashboard, questions, survey, general } = this.props;
    let { questionsList } = questions;

    let {
      getQuestionAvgScoreData,
      getQuestion2OthersResultFilterData,
      getQuestionOthersResultData,
      getQuestionOthersResultFilterData,
      getQuestionOthersMultiResultFilterData,
    } = general;

    // console.log('getExitQuestionOnegetExitQuestionOnegetExitQuestionOne', getExitQuestionOne);

    ////////console.log("----------------------------------------------------------------------------------------------------------")
    ////////console.log("many result: ",getQuestionOthersMultiResultFilterData)
    ////////console.log("----------------------------------------------------------------------------------------------------------")

    let {
      showPeopleToMeet,
      customeScoreData,
      showCustomScoreCard,
      showReportDMGModal,
      ManagerReportFConfig,
      loadReport,
      isFilter,
      currentQuestionId,
      currentQuestionTemplate,
    } = this.state;

    let { getSurveyData, getIndexByFilterDMGData, parameterList } = survey;

    let {
      getMetaRespondantDetailsData,
      getClusterSaveKeyWord,
      getClusterSentencesData,
      getClusterKeywordsData,
      getTextClusterSentimentCountData,
      textclusterData,
      getTextClusterData,
      getUserAccessData,

      AnswerFeedbackTextData,
      AnswerFeedbackTextKeyWord,

      getQuestionManagerPIDWiseData,
      getIndexScoreManagerPIDWiseData,
      getIndexDriverManagerPIDWiseData,

      clusterAnalysisError,
      textDistError,
      TreeMapError,
      neighborGraphError,
      wordCloudError,
      topicModellingError,
      getCorrelationDimenionVsDimensionError,
      crossTabError,

      getQuestionCustomTemplateData,
      getQuestionMapData,
      getCompanyNameOnlyData,
      getIDPLastDateData,
      getAllManagerGoalPlanData,
      clusterAnalysisData,
      clusterAnalysisCount,

      getOverallScoreBySurveyIdData,

      getAllManagerScoresData,

      getMngrReportDownloadStatusData,
      getMngrReportDownloadStatusTotal,

      getTopicListData,

      clusterAnalysisMSG,

      topicModellingData,

      getClusterEmpInfoListData,

      getParameterLibraryListData,

      ClientProfileData,
      SurveyList,
      HappinessIndexMNGRData,
      EngagementIndexMNGRData,
      HopeIndexMNGRData,
      StressIndexMNGRData,

      getDemographicIndexMultiFilterEngCompData,
      getQuestionCommentsData,

      getOrgIndexV2FilterData,

      TreeMapData,
      textDistTreeMapData,
      EmpFilterData,
      getEngagementRWAData,
      getDemographicIndexFilterReportData,

      textDistData,
      getDemographicIndexMultiFilterData,

      ManagerListData,
      getDemographicIndexData,

      wordCloudMultiTextData,
      wordMultiTextKeyWord,

      loading,

      crossTabAnalysisData,
      getQuestionFavorability2Data,

      getManagerListDashboardManagerIndexData,

      getDemographicOrgReportData,

      getDemographicIndexEngCompOverallData,

      HappinessIndexNewData,
      EngagementIndexNewData,
      HopeIndexNewData,
      StressIndexNewData,
      ManagerIndexNewData,

      getQuestionFilterData,

      OrgCoreIndexData,

      getIndexFilterData,
      getManagerListDomainFilterData,

      getQuestionData,

      getDemographicIndexHappinessData,
      getDemographicIndexEngagementData,
      getDemographicIndexHopeData,
      getDemographicIndexStressData,
      getDemographicIndexManagerData,
      getDemographicIndexLeaderData,

      getQuestionManagerOfHappinessData,
      getQuestionManagerOfEngagementData,
      getQuestionManagerOfStressData,
      getQuestionManagerOfManagerData,

      // getHappinessIndexV2Data,
      // getEngagementIndexV2Data,
      // getStressIndexV2Data,
      // getManagerIndexV2Data,

      getDemographicIndexScatterData,

      getHappinessCorrelationData,
      getEngagementCorrelationData,
      getStressCorrelationData,
      getManagerCorrelationData,
      getLeaderCorrelationData,

      getRWAnalysisData,

      getTextQuestionData,

      wordCloudData,
      AdvanceWordCloudData,

      wordTextKeyWord,

      cooccurmatrixData,

      getQuestionFavorabilityData,

      getCommentData,

      crossTabData,
      crossTabDMGData,

      getQuestionOverallData,

      getCorrelationDimenionVsDimensionData,
      getCorrelationDimensionVsDimensionFilterData,
      getOrgIndexV2Data,
      getAllBenchMarkData,

      getHappinessQuestionData,
      getHappinessManagerQuestionData,
      getHappinessDMGQuestionData,

      getEngagementQuestionData,
      getEngagementManagerQuestionData,
      getEngagementDMGQuestionData,

      getHopeQuestionData,

      getStressQuestionData,
      getStressManagerQuestionData,
      getStressDMGQuestionData,

      getManagerQuestionData,
      getManagerManagerQuestionData,
      getManagerDMGQuestionData,

      getLeaderQuestionData,
      getLeaderManagerQuestionData,
      getLeaderDMGQuestionData,

      getHappinessIndexV2Data,
      getEngagementIndexV2Data,
      getHopeIndexV2Data,
      getStressIndexV2Data,
      getManagerIndexV2Data,
      getLeaderIndexV2Data,

      getHappinessIndexDriverData,
      getEngagementIndexDriverData,
      getHopeIndexDriverData,
      getStressIndexDriverData,
      getManagerIndexDriverData,
      getLeaderIndexDriverData,

      getHappinessIndexV2DemographicData,
      getEngagementIndexV2DemographicData,
      getHopeIndexV2DemographicData,
      getStressIndexV2DemographicData,
      getDemographicIndexOrgData,

      getHappinessIndexByFilterData,
      getEngagementIndexByFilterData,
      getStressIndexByFilterData,
      getManagerIndexByFilterData,
      getLeaderIndexByFilterData,
      getOverallIndexData,

      getHappinessIndexV2FilterData,
      getEngagementIndexV2FilterData,
      getHopeIndexV2FilterData,
      getStressIndexV2FilterData,

      getHappinessIndexDriverFilterData,
      getEngagementIndexDriverFilterData,
      getHopeIndexDriverFilterData,
      getStressIndexDriverFilterData,

      getDemographicIndexError,
      crossTabAnalysisError,
      wordCloudTextError,
      textDistTreeError,
      getQuestionFavorabilityError,
      getClusterEmpInfoError,

      getOrgQuestionData,
      getOrgDMGQuestionData,
      getOrgManagerQuestionData,
      getOrgCoreDriverSentimentData,
      getFeedbackTextQuestionData,
      getExitQuestionOne,
      getExitQuestionAvgData,
      getExitQuestionYesNoData,
      getExitQuestionAllCountData,
      getExitQuestionAllDemographicsData,
      rolkeywordbyemployeeData,
    } = dashboard;

    let ErrorOtherObj = {
      getDemographicIndexError: getDemographicIndexError,
      getCorrelationDimenionVsDimensionError:
        getCorrelationDimenionVsDimensionError,
      crossTabAnalysisError: crossTabAnalysisError,

      wordCloudTextError: wordCloudTextError,
      neighborGraphError: neighborGraphError,
      TreeMapError: TreeMapError,
      textDistError: textDistError,
      textDistTreeError: textDistTreeError,
      topicModellingError: topicModellingError,
      getQuestionFavorabilityError: getQuestionFavorabilityError,
      clusterAnalysisError: clusterAnalysisError,
      getClusterEmpInfoError: getClusterEmpInfoError,
    };

    console.log(
      "Exit_Deshbord___________________________________000000000000000rolkeywordbyemployeeData",
      rolkeywordbyemployeeData
    );

    let {
      showManagerGoalPlan,
      Mindextype,
      MQuesIndextype,
      ManagerListDasboardDataObj,
      ManagerListQuestionDataObj,
      SelectedAPDimension,
      SelectedAPAction,
      SelectedAPTask,
    } = this.state;

    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      isAdmin: false,
      access: {},
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }

    function getFilterViewFn(getIndexDMGData, getUserAcc) {
      let FilterDMGDataObj = {};
      if (getIndexDMGData) {
        let list = Object.keys(getIndexDMGData);
        if (list && list.length > 0) {
          list.forEach((key) => {
            if (getUserAcc && getUserAcc[key] && getUserAcc[key].length > 0) {
              FilterDMGDataObj[key] = getUserAcc[key];
            } else {
              FilterDMGDataObj[key] = getIndexDMGData[key];
            }
          });
        }
      }
      return FilterDMGDataObj;
    }
    getIndexByFilterDMGData = getFilterViewFn(
      getIndexByFilterDMGData,
      getUserAccessData
    );
    // console.log("textclusterData----NEW--->", textclusterData);

    let SHOW_ELEMENT = false;
    let isCrole = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {
      if (clientData.role === "ADMIN") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      } else if (clientData.role === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      } else if (clientData.role === "CROLE") {
        isCrole = true;
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      } else if (clientData.role === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return moduleAccess.isAdmin
        ? true
        : moduleAccess && moduleAccess.access && moduleAccess.access[item]
          ? true
          : false;
    }

    //END -------------------------------------------------------

    let ManagerIndexQuestionData = "";

    if (MQuesIndextype === 1) {
      ManagerIndexQuestionData = getQuestionManagerOfHappinessData;
    } else if (MQuesIndextype === 2) {
      ManagerIndexQuestionData = getQuestionManagerOfEngagementData;
    } else if (MQuesIndextype === 4) {
      ManagerIndexQuestionData = getQuestionManagerOfStressData;
    } else if (MQuesIndextype === 5) {
      ManagerIndexQuestionData = getQuestionManagerOfManagerData;
    }

    let ManagerIndexDriverData = [];

    if (Mindextype === 1) {
      ManagerIndexDriverData = getDemographicIndexHappinessData;
    } else if (Mindextype === 2) {
      ManagerIndexDriverData = getDemographicIndexEngagementData;
    } else if (Mindextype === 4) {
      ManagerIndexDriverData = getDemographicIndexStressData;
    } else if (Mindextype === 5) {
      ManagerIndexDriverData = getDemographicIndexManagerData;
    }

    let {
      showAllQuestions,
      showOtherTypes,
      showReport,
      showSidebar,
      CurrentSurveyId,
      indexType,
      showOverview,
      showIndex,
      showManagerList,
      showAdvanceComparision,
      showTextAnalysis,
      showStrengthAndWeaknesses,
      showManagerActionPlan,
      DynaObj,
      ManagerDynaObj,
      QuesDynaObj,
    } = this.state;
    let {
      getAllParameterListData,
      getAllManagerActionPlanData,
      getManagerActionPlanData,
      getIndexData,
      getDemographicIndexForManagerData,
      wordCloudTextData,
      neighborGraphData,
      getRespondantDetailsData,
    } = dashboard;

    // //////console.log('showOverview: ',showOverview)
    // //////console.log('showIndex: ',showIndex)
    // //////console.log('showManagerList: ',showManagerList)
    // //////console.log('sshowAdvanceComparision: ',showAdvanceComparision)
    let ManagerNameIdList = getDemographicIndexForManagerData;

    if (
      ClientProfileData &&
      ClientProfileData.role &&
      ClientProfileData.role === "MNGR"
    ) {
      this.props.history.push(`/app/manageractionplan`);
    } else if (
      ClientProfileData &&
      ClientProfileData.role &&
      ClientProfileData.role === "EMP"
    ) {
      this.props.history.push(`/app/employeeactionplan`);
    }

    console.log(
      "------------( getFeedbackTextQuestionData )----->",
      getFeedbackTextQuestionData
    );

    function checkIndex(key) {
      let check = false;
      if (key === 1) {
        check = true;
      } else if (key === 2) {
        check = true;
      } else if (key === 3) {
        check = true;
      } else if (key === 4) {
        check = true;
      } else if (key === 5) {
        check = true;
      } else if (key === 6) {
        check = true;
      } else if (key === 7) {
        check = true;
      } else if (key === 9) {
        check = true;
      } else {
        check = false;
      }

      return check;
    }

    let IndexList = [];
    let newFilterObj = {};
    if (getIndexData) {
      IndexList = Object.keys(getIndexData).map((key) => [
        Number(key),
        getIndexData[key],
      ]);

      if (getIndexFilterData) {
        if (IndexList && IndexList.length > 0) {
          IndexList.forEach((data) => {
            if (checkIndex(data[0])) {
              newFilterObj[data[0]] = getIndexFilterData[data[0]];
            }
          });
        }
      }
    }

    let IndexDimension = {
      Happiness: [
        "Time",
        "Being",
        "Relationship",
        "WorkLife",
        "Achivement",
        "Meeningfull",
      ],
      Engagement: ["Pride", "Presence", "Performance"],
      Stress: [
        "Recognition",
        "WorkSatisfaction",
        "Inclusion",
        "Collaboration",
        "Empowerment",
      ],
      Manager: ["Connect", "ACT", "Respect", "Empower"],
      Leader: ["Vision", "Impact", "Execute", "Wisdom"],
    };

    function CountRestrict(count, isOverall, section) {
      let isCountLessThanFive = false;

      // ////////console.log("count---",count)

      if (count !== "NaN") {
        if (isOverall) {
          isCountLessThanFive = false;
        } else {
          if (section === "rwa") {
            if (parseInt(count) < 30) {
              isCountLessThanFive = true;
            }
          } else {
            if (parseInt(count) < 5) {
              isCountLessThanFive = true;
            }
          }
        }
      }
      // ////////console.log("isCountLessThanFive---",isCountLessThanFive)

      return isCountLessThanFive;
    }

    function GetParameterLabel(name) {
      let AllParameterObj = {};
      let NewParameterList =
        survey && survey.parameterList && survey.parameterList.length > 0
          ? survey.parameterList
          : [];
      if (NewParameterList && NewParameterList.length > 0) {
        NewParameterList.forEach((item) => {
          if (item && item.name) {
            AllParameterObj[item.name] =
              item && item.label ? item.label : item.name;
          }
        });
      }
      return AllParameterObj && AllParameterObj[name]
        ? AllParameterObj[name]
        : name;
    }

    // ////////console.log("getSurveyData------------>",getSurveyData)

    function FormattFun(inputData, isSummary) {
      let LIST = [];
      let prevTemList = [];
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {
          let templateId = ques.total[0]._id.qtemplate;

          let temp = {
            name:
              ques && ques.question && ques.question[0] && ques.question[0].name
                ? ques.question[0].name
                : "",
            answerList: [],
            templateId: templateId,
          };
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading:
                  item && item._id && item._id.heading ? item._id.heading : "",
                parentId:
                  item && item._id && item._id.parentId
                    ? item._id.parentId
                    : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId,
              };
              temp["answerList"].push(ans);
            });
          }

          if (isSummary) {
            let getIndex = prevTemList.findIndex((prev) => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId);
            }
          } else {
            LIST.push(temp);
          }
        });
      }
      return LIST;
    }
    let AllQuesList = FormattFun(getQuestionOthersResultData, false).filter(
      (x) => x.templateId === currentQuestionTemplate
    );

    ////////console.log("----AllQuesList----,",AllQuesList)
    ////////console.log("----getQuestionOthersResultData----,",getQuestionOthersResultData)

    //////console.log('currentQuestionId, currentQuestionTemplate :',currentQuestionId, currentQuestionTemplate );

    let surveyLogo = "";

    let surveyId = getSurveyData && getSurveyData.id ? getSurveyData.id : "";

    if (surveyId === "618b673c9503f41bad61667f") {
      surveyLogo = "img/logo/kotaklifelogo.png";
    } else if (surveyId === "61541d6cf0f59b75a9b50d44") {
      surveyLogo = "img/logo/tcillogo.jpeg";
    } else if (surveyId === "61b1850ada0e4ed403288e52") {
      surveyLogo = "img/logo/tcellogo.jpg";
    } else if (surveyId === "61c06c871a708fbb5cf1c9a3") {
      surveyLogo = "img/logo/bppllogo.png";
    } else if (surveyId === "5fa04d316ede0f0336066cce") {
      surveyLogo = "img/logo/happypluslogo.png";
    } else if (surveyId === "621dc1de0f73b3a8e532f1b5") {
      surveyLogo = "img/logo/axissecuritylogo.png";
    }

    function getEMPFilterLabel(name) {
      let label = name;
      if (EmpFilterData && EmpFilterData.length > 0) {
        let getIndex = EmpFilterData.findIndex((prev) => prev.name === name);
        if (getIndex !== 1) {
          label =
            EmpFilterData &&
              EmpFilterData[getIndex] &&
              EmpFilterData[getIndex].label
              ? EmpFilterData[getIndex].label
              : name;
        }
      }
      return label;
    }

    // getHappinessIndexV2DemographicData
    // getEngagementIndexV2DemographicData
    // getHopeIndexV2DemographicData
    // getStressIndexV2DemographicData

    function ObjectFieldAveragingFn(getTempIndexV2DemographicData, type) {
      let count = 0;
      let keyList = Object.keys(
        getTempIndexV2DemographicData && getTempIndexV2DemographicData[0]
          ? getTempIndexV2DemographicData[0]
          : {}
      );
      let obj = {};
      if (keyList && keyList.length > 0) {
        keyList.forEach((key) => {
          obj[key] = 0;
        });
      }
      if (
        getTempIndexV2DemographicData &&
        getTempIndexV2DemographicData.length > 0
      ) {
        getTempIndexV2DemographicData.forEach((item) => {
          if (keyList && keyList.length > 0) {
            keyList.forEach((key) => {
              obj[key] += item[key];
              count += item.count;
            });
          }
        });
        if (keyList && keyList.length > 0) {
          keyList.forEach((key) => {
            obj[key] /= getTempIndexV2DemographicData.length;
          });
        }
      }
      if (type === "count") {
        obj["count"] = count;
      }
      return obj;
    }

    function getSurveyDetails(name) {
      return getSurveyData && getSurveyData[name] ? getSurveyData[name] : "NaN";
    }

    let isLifecycle = false;
    if (getSurveyData && getSurveyData.isRiskSurveyType === "DEFAULTDAYS") {
      isLifecycle = true;
    }

    //console.log("<><><><< isLifecycle- <><><><<--->",isLifecycle)

    //Access Compoenent specific

    function allowSpecificFn(name) {
      if (name === "tce" && CurrentSurveyId === "61b1850ada0e4ed403288e52") {
        return true;
      } else {
        return false;
      }
    }

    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text="Loading Data..."
          className="loader"
        >
          <div className="h-screen overflow-hidden bg-gray-50">
            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              SurveyList={SurveyList}
              ChooseSurvey={this.ChooseSurvey}
              CurrentSurveyId={CurrentSurveyId}
              props={this.props}
              onOverviewFromHeader={this.onOverviewFromHeader}
              getSurveyData={getSurveyData}
              companyName={
                getCompanyNameOnlyData && getCompanyNameOnlyData.name
                  ? getCompanyNameOnlyData.name
                  : ""
              }
            />

            {/* header end */}
            {/*sub nav bar 1 */}
            <div
              className="px-4  md:flex  justify-between items-center bg-white
      border-b py-2.5"
            >
              <div className="flex justify-between w-full space-x-2 text-gray-700">
                <div className="flex space-x-2">
                  <span className="material-symbols-outlined -mt-0.5">
                    description
                  </span>
                  <h2 className="text-sm font-medium truncate">
                    {" "}
                    {getSurveyDetails("name")}{" "}
                  </h2>
                </div>

                <div
                  onClick={() =>
                    this.handleMobileSidebar(!this.state.showMobileSidebar)
                  }
                >
                  <span className="cursor-pointer material-symbols-outlined lg:hidden">
                    menu_open
                  </span>
                </div>
              </div>

              {/* <div className="inline-flex items-center">
          <button className="px-4 py-2 text-xs font-medium uppercase border rounded-full border-sky-500 text-sky-500 ">VIEW CAMPAIGN</button>
        </div> */}
            </div>
            {/* end */}
            <div
              className="flex w-full"
              style={{ height: "calc(100% - 6.5rem)" }}
            >
              {/*this is side bar  */}

              <Sidebar
                SurveyList={SurveyList}
                onOverview={this.onOverview}
                onOptionType={this.onOptionType}
                onManagerList={this.onManagerList}
                onOrganizationCore={this.onOrganizationCore}
                indexType={indexType}
                getIndexData={newFilterObj}
                CurrentSurveyId={CurrentSurveyId}
                showSidebar={showSidebar}
                handleSidebar={this.handleSidebar}
                showMobileSidebar={this.state.showMobileSidebar}
                isLifecycle={isLifecycle}
                callOverviewApi={this.callOverviewApi}
              />

              {showOverview ? (
                <Overview
                  // CurrentSurveyId={this.state.CurrentSurveyId}
                  getIndexData={newFilterObj}
                  SurveyList={SurveyList}
                  rolkeywordbyemployeeData={rolkeywordbyemployeeData}
                  getExitQuestionOne={getExitQuestionOne}
                  getExitQuestionAvgData={getExitQuestionAvgData}
                  getExitQuestionYesNoData={getExitQuestionYesNoData}
                  getExitQuestionAllCountData={getExitQuestionAllCountData}
                  CurrentSurveyId={CurrentSurveyId}
                  onOptionType={this.onOptionType}
                  getHappinessIndexV2Data={getHappinessIndexV2Data}
                  getEngagementIndexV2Data={getEngagementIndexV2Data}
                  getStressIndexV2Data={getStressIndexV2Data}
                  getManagerIndexV2Data={getManagerIndexV2Data}
                  getRespondantDetailsData={getMetaRespondantDetailsData}
                  EmpFilterData={EmpFilterData}
                  handleSelectChart={this.handleSelectChart}
                  getIndexFilterData={getIndexFilterData}
                  getCommentData={getCommentData}
                  getQuestionOthersResultData={getQuestionOthersResultData}
                  onAllQuestions={this.onAllQuestions}
                  getQuestionAvgScoreData={getQuestionAvgScoreData}
                  onCustomScoreCard={this.onCustomScoreCard}
                  loading={loading}
                  getExitQuestionAllDemographicsData={
                    getExitQuestionAllDemographicsData
                  }
                  getIndexByFilterDMGData={getIndexByFilterDMGData}
                  handleDmgExitCall={this.handleDmgExitCall}
                  getEMPFilterLabel={getEMPFilterLabel}
                />
              ) : null}

              {showOtherTypes ? (
                <>
                  {showAllQuestions ? (
                    <>
                      <main className="flex-1 w-full h-full pb-20 overflow-hidden overflow-y-auto bg-gray-50">

                        <div className="px-2 py-2 bg-gray-50">
                          <div className="h-full p-4 mx-auto overflow-hidden overflow-y-auto ">
                            <div className='flex items-center pb-5 space-x-4'>
                              <span onClick={() => this.handleGoBack()} class="cursor-pointer material-symbols-outlined bg-blue-100 p-2 rounded-full text-blue-500 ">arrow_back</span>
                              <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Questions Analysis</h1>
                            </div>
                            <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-2 lg:gap-6">
                              {AllQuesList && AllQuesList.length > 0
                                ? AllQuesList.map((item, index) => (
                                  <div
                                    onClick={() =>
                                      this.onOthersCardClick(item)
                                    }
                                    className="p-4 bg-white border"
                                  >
                                    <QuestionCard
                                      item={item}
                                      name={""}
                                      stage={2}
                                      index={index}
                                    />
                                  </div>
                                ))
                                : null}
                            </div>
                          </div>
                        </div>
                      </main>
                    </>
                  ) : (
                    <QuestionDetails
                      handleGoBack={this.handleGoBack}
                      getEMPFilterLabel={getEMPFilterLabel}
                      EmpFilterData={EmpFilterData}
                      getIndexFilterData={getIndexByFilterDMGData}
                      getQuestionOthersResultData={getQuestionOthersResultData}
                      getQuestionOthersResultFilterData={
                        getQuestionOthersResultFilterData
                      }
                      currentQuestionId={currentQuestionId}
                      currentQuestionTemplate={currentQuestionTemplate}
                      handleOthersFilterCall={this.handleOthersFilterCall}
                    />
                  )}
                </>
              ) : null}

              {showTextAnalysis ?
                <TextAnalysis
                  textDistError={textDistError}
                  TreeMapError={TreeMapError}
                  topicModellingError={topicModellingError}
                  neighborGraphError={neighborGraphError}
                  wordCloudError={wordCloudError}
                  indexType={indexType}
                  getTextQuestionData={getTextQuestionData}

                  handleChangeQuestion={this.handleChangeQuestion}

                  wordCloudData={wordCloudData}

                  EmpFilterData={EmpFilterData}
                  getIndexFilterData={getIndexFilterData}

                  handleWordCloudDemographicFilter={this.handleWordCloudDemographicFilter}

                  callWordCloudText={this.callWordCloudText}

                  wordCloudTextData={wordCloudTextData}

                  wordTextKeyWord={wordTextKeyWord}

                  callQuestionWordCloud={this.callQuestionWordCloud}

                  cooccurmatrixData={cooccurmatrixData}

                  onWorldCloud={this.onWorldCloud}

                  onNetworkGraph={this.onNetworkGraph}
                  onNeightboardGraph={this.onNeightboardGraph}
                  onTextWordDistribution={this.onTextWordDistribution}

                  callQuestionNetworkCloud={this.callQuestionNetworkCloud}
                  handleNetworkDemographicFilter={this.handleNetworkDemographicFilter}


                  neighborGraphData={neighborGraphData}

                  handleNeighborGraph={this.handleNeighborGraph}

                  getCommentData={getCommentData}

                  loading={loading}

                  GetParameterLabel={GetParameterLabel}

                  CountRestrict={CountRestrict}

                  wordCloudMultiTextData={wordCloudMultiTextData}
                  wordMultiTextKeyWord={wordMultiTextKeyWord}

                  textDistData={textDistData}


                  onAdvanceWorldCloud={this.onAdvanceWorldCloud}
                  AdvanceWordCloudData={AdvanceWordCloudData}

                  onTreeMap={this.onTreeMap}
                  TreeMapData={TreeMapData}
                  textDistTreeMapData={textDistTreeMapData}

                  getEMPFilterLabel={getEMPFilterLabel}

                  getIndexByFilterDMGData={getIndexByFilterDMGData}




                  getDemographicIndexHappinessDataTreeMap={ObjectFieldAveragingFn(getDemographicIndexHappinessData)}
                  getDemographicIndexEngagementDataTreeMap={ObjectFieldAveragingFn(getDemographicIndexEngagementData)}
                  getDemographicIndexStressDataTreeMap={ObjectFieldAveragingFn(getDemographicIndexStressData)}
                  getDemographicIndexManagerDataTreeMap={ObjectFieldAveragingFn(getDemographicIndexManagerData)}
                  getDemographicIndexLeaderDataTreeMap={ObjectFieldAveragingFn(getDemographicIndexLeaderData)}


                  getDemographicIndexMultiFilterDataTreeMap={ObjectFieldAveragingFn(getDemographicIndexMultiFilterData)}


                  getIndexData={getIndexData}


                  getHappinessIndexDriverData={getHappinessIndexDriverData}
                  getEngagementIndexDriverData={getEngagementIndexDriverData}
                  getStressIndexDriverData={getStressIndexDriverData}
                  getManagerIndexDriverData={getManagerIndexDriverData}
                  getLeaderIndexDriverData={getLeaderIndexDriverData}


                  onTopicModel={this.onTopicModel}
                  topicModellingData={topicModellingData}

                  getTopicListData={getTopicListData}

                  onCreateTopicSubmit={this.onCreateTopicSubmit}

                  ErrorOtherObj={ErrorOtherObj}



                  //AnswerFeedback
                  getOrgCoreDriverSentimentData={getOrgCoreDriverSentimentData}
                  AnswerFeedbackTextData={AnswerFeedbackTextData}
                  AnswerFeedbackTextKeyWord={AnswerFeedbackTextKeyWord}
                  getFeedbackTextQuestionData={getFeedbackTextQuestionData}
                  onFeedbackCloud={this.onFeedbackCloud}
                  callAnswerFeedbackText={this.callAnswerFeedbackText}
                  handleAnswerFeedbackDemographicFilter={this.handleAnswerFeedbackDemographicFilter}

                  //onTextCluster
                  getTextClusterData={getTextClusterData}
                  getTextClusterSentimentCountData={getTextClusterSentimentCountData}
                  getClusterKeywordsData={getClusterKeywordsData}
                  getClusterSentencesData={getClusterSentencesData}
                  getClusterSaveKeyWord={getClusterSaveKeyWord}
                  onTextCluster={this.onTextCluster}
                  getKeywordsByCluster={this.getKeywordsByCluster}
                  getClusterSentences={this.getClusterSentences}
                  getTextClusterFilter={this.getTextClusterFilter}


                />
                : null}




            </div>
          </div>
        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, questions, survey, general } = state;
  return {
    loggingIn,
    users,
    dashboard,
    questions,
    survey,
    general,
  };
}
export default connect(mapStateToProps)(Dashboard);
