export const pmsConstants = {

    START_GROW_GETALL_REQUEST: 'START_GROW_GETALL_REQUEST',
    START_GROW_GETALL_SUCCESS: 'START_GROW_GETALL_SUCCESS',
    START_GROW_GETALL_FAILURE: 'START_GROW_GETALL_FAILURE',

    PMS_GETEMP_LIST_REQUEST: 'PMS_GETEMP_LIST_REQUEST',
    PMS_GETEMP_LIST_SUCCESS: "PMS_GETEMP_LIST_SUCCESS",
    PMS_GETEMP_LIST_FAILURE: 'PMS_GETEMP_LIST_FAILURE',

    PMSGETNANME_SCORE_NAME_REQUEST: 'PMSGETNANME_SCORE_NAME_REQUEST',
    PMSGETNANME_SCORE_NAME_SUCCESS: 'PMSGETNANME_SCORE_NAME_SUCCESS',
    PMSGETNANME_SCORE_NAME_FAILURE: 'PMSGETNANME_SCORE_NAME_FAILURE',
    PMSGET_SCORE_NAME_REQUEST: 'PMSGET_SCORE_NAME_REQUEST',
    PMSGET_SCORE_NAME_SUCCESS: 'PMSGET_SCORE_NAME_SUCCESS',
    PMSGET_SCORE_NAME_FAILURE: 'PMSGET_SCORE_NAME_FAILURE',
    PMSGET_SCORE_NAME_REQUEST: 'PMSGET_SCORE_NAME_REQUEST',
    PMSGET_SCORE_NAME_SUCCESS: 'PMSGET_SCORE_NAME_SUCCESS',
    PMSGET_SCORE_NAME_FAILURE: 'PMSGET_SCORE_NAME_FAILURE',

    ADD_PMS_GETEMP_LIST_REQUEST: 'ADD_PMS_GETEMP_LIST_REQUEST',
    ADD_PMS_GETEMP_LIST_SUCCESS: 'ADD_PMS_GETEMP_LIST_SUCCESS',
    ADD_PMS_GETEMP_LIST_FAILURE: 'ADD_PMS_GETEMP_LIST_FAILURE',

    EMP_SCORE_NAME_REQUEST: 'EMP_SCORE_NAME_REQUEST',
    EMP_SCORE_NAME_SUCCESS: 'EMP_SCORE_NAME_SUCCESS',
    EMP_SCORE_NAME_FAILURE: 'EMP_SCORE_NAME_FAILURE',

    SUBMIT2_REVIEW_REQUEST: 'SUBMIT2_REVIEW_REQUEST',
    SUBMIT2_REVIEW_SUCCESS: 'SUBMIT2_REVIEW_SUCCESS',
    SUBMIT2_REVIEW_FAILURE: 'SUBMIT2_REVIEW_FAILURE',

    GET_PEER_DETAILS_REQUEST: 'GET_PEER_DETAILS_REQUEST',
    GET_PEER_DETAILS_SUCCESS: 'GET_PEER_DETAILS_SUCCESS',
    GET_PEER_DETAILS_FAILURE: 'GET_PEER_DETAILS_FAILURE',

    GET_PMSLIST_NAME_REQUEST: 'GET_PMSLIST_NAME_REQUEST',
    GET_PMSLIST_NAME_SUCCESS: 'GET_PMSLIST_NAME_SUCCESS',
    GET_PMSLIST_NAME_FAILURE: 'GET_PMSLIST_NAME_FAILURE',

    GET_APPRAISAL_NAME_REQUEST: 'GET_APPRAISAL_NAME_REQUEST',
    GET_APPRAISAL_NAME_SUCCESS: 'GET_APPRAISAL_NAME_SUCCESS',
    GET_APPRAISAL_NAME_FAILURE: 'GET_APPRAISAL_NAME_FAILURE',


    GET_PMS_EMPDETAILS_REQUEST: 'GET_PMS_EMPDETAILS_REQUEST',
    GET_PMS_EMPDETAILS_SUCCESS: 'GET_PMS_EMPDETAILS_SUCCESS',
    GET_PMS_EMPDETAILS_FAILURE: 'GET_PMS_EMPDETAILS_FAILURE',

    GET_PEERS_NEW_REQUEST: 'GET_PEERS_NEW_REQUEST',
    GET_PEERS_NEW_SUCCESS: 'GET_PEERS_NEW_SUCCESS',
    GET_PEERS_NEW_FAILURE: 'GET_PEERS_NEW_FAILURE',

    GET_PEERS_GOAL_REQUEST: 'GET_PEERS_GOAL_REQUEST',
    GET_PEERS_GOAL_SUCCESS: 'GET_PEERS_GOAL_SUCCESS',
    GET_PEERS_GOAL_FAILURE: "GET_PEERS_GOAL_FAILURE",

    SUBMIT_REVIEW_REQUEST: 'SUBMIT_REVIEW_REQUEST',
    SUBMIT_REVIEW_SUCCESS: 'SUBMIT_REVIEW_SUCCESS',
    SUBMIT_REVIEW_FAILURE: 'SUBMIT_REVIEW_FAILURE',

    GET_PMS_LIST_REQUEST: 'GET_PMS_LIST_REQUEST',
    GET_PMS_LIST_SUCCESS: 'GET_PMS_LIST_SUCCESS',
    GET_PMS_LIST_FAILURE: 'GET_PMS_LIST_FAILURE',

    UPDATE_PMS_TASK_REQUEST: 'UPDATE_PMS_TASK_REQUEST',
    UPDATE_PMS_TASK_SUCCESS: 'UPDATE_PMS_TASK_SUCCESS',
    UPDATE_PMS_TASK_FAILURE: 'UPDATE_PMS_TASK_FAILURE',

    EMP_PMS_GOAL_LIST_REQUEST: 'EMP_PMS_GOAL_LIST_REQUEST',
    EMP_PMS_GOAL_LIST_SUCCESS: 'EMP_PMS_GOAL_LIST_SUCCESS',
    EMP_PMS_GOAL_LIST_FAILURE: 'EMP_PMS_GOAL_LIST_FAILURE',

    UPDATE_EMPOMS_GOAL_REQUEST: 'UPDATE_EMPOMS_GOAL_REQUEST',
    UPDATE_EMPOMS_GOAL_SUCCESS: 'UPDATE_EMPOMS_GOAL_SUCCESS',
    UPDATE_EMPOMS_GOAL_FAILURE: 'UPDATE_EMPOMS_GOAL_FAILURE',

    ADDEMP_PMSTASK_REQUEST: 'ADDEMP_PMSTASK_REQUEST',
    ADDEMP_PMSTASK_SUCCESS: 'ADDEMP_PMSTASK_SUCCESS',
    ADDEMP_PMSTASK_FAILURE: 'ADDEMP_PMSTASK_FAILURE',

    GETEMP_PMS_TASK_REQUEST: 'GETEMP_PMS_TASK_REQUEST',
    GETEMP_PMS_TASK_SUCCESS: 'GETEMP_PMS_TASK_SUCCESS',
    GETEMP_PMS_TASK_FAILURE: 'GETEMP_PMS_TASK_FAILURE',

    GET_PMS_STATUS_EMP_REQUEST: 'GET_PMS_STATUS_EMP_REQUEST',
    GET_PMS_STATUS_EMP_SUCCESS: 'GET_PMS_STATUS_EMP_SUCCESS',
    GET_PMS_STATUS_EMP_FAILURE: 'GET_PMS_STATUS_EMP_FAILURE',

    GET_AND_UPDATE_EMP_PMSSTAGE_REQUEST: 'GET_AND_UPDATE_EMP_PMSSTAGE_REQUEST',
    GET_AND_UPDATE_EMP_PMSSTAGE_SUCCESS: 'GET_AND_UPDATE_EMP_PMSSTAGE_SUCCESS',
    GET_AND_UPDATE_EMP_PMSSTAGE_FAILURE: 'GET_AND_UPDATE_EMP_PMSSTAGE_FAILURE',

    GET_PMS_NORMAL_SCHANGE_REQUEST: 'GET_PMS_NORMAL_SCHANGE_REQUEST',
    GET_PMS_NORMAL_SCHANGE_SUCCESS: 'GET_PMS_NORMAL_SCHANGE_SUCCESS',
    GET_PMS_NORMAL_SCHANGE_FAILURE: 'GET_PMS_NORMAL_SCHANGE_FAILURE',

    GET_PEERS_GOAL_STATUS_REQUEST: 'GET_PEERS_GOAL_STATUS_REQUEST',
    GET_PEERS_GOAL_STATUS_SUCCESS: 'GET_PEERS_GOAL_STATUS_SUCCESS',
    GET_PEERS_GOAL_STATUS_FAILURE: 'GET_PEERS_GOAL_STATUS_FAILURE',

    GET_PMS_STATUS_EMP_v2_REQUEST: 'GET_PMS_STATUS_EMP_v2_REQUEST',
    GET_PMS_STATUS_EMP_v2_SUCCESS: 'GET_PMS_STATUS_EMP_v2_SUCCESS',
    GET_PMS_STATUS_EMP_v2_FAILURE: 'GET_PMS_STATUS_EMP_v2_FAILURE',

    GET_EMPLOYEE_APPRAISAL_STATUS_REQUEST: 'GET_EMPLOYEE_APPRAISAL_STATUS_REQUEST',
    GET_EMPLOYEE_APPRAISAL_STATUS_SUCCESS: 'GET_EMPLOYEE_APPRAISAL_STATUS_SUCCESS',
    GET_EMPLOYEE_APPRAISAL_STATUS_FAILURE: 'GET_EMPLOYEE_APPRAISAL_STATUS_FAILURE',

    GET_OVERVIEW_STATUS_REQUEST: 'GET_OVERVIEW_STATUS_REQUEST',
    GET_OVERVIEW_STATUS_SUCCESS: 'GET_OVERVIEW_STATUS_SUCCESS',
    GET_OVERVIEW_STATUS_FAILURE: 'GET_OVERVIEW_STATUS_FAILURE',

    GET_PMS_EMPLOYEE_WISE_STATUS_REQUEST: 'GET_PMS_EMPLOYEE_WISE_STATUS_REQUEST',
    GET_PMS_EMPLOYEE_WISE_STATUS_SUCCESS: 'GET_PMS_EMPLOYEE_WISE_STATUS_SUCCESS',
    GET_PMS_EMPLOYEE_WISE_STATUS_FAILURE: 'GET_PMS_EMPLOYEE_WISE_STATUS_FAILURE',

    GET_PMS_LAUNCH_STATUS_REQUEST: 'GET_PMS_LAUNCH_STATUS_REQUEST',
    GET_PMS_LAUNCH_STATUS_SUCCESS: 'GET_PMS_LAUNCH_STATUS_SUCCESS',
    GET_PMS_LAUNCH_STATUS_FAILURE: 'GET_PMS_LAUNCH_STATUS_FAILURE',

    PMS_UPDATE_DETAILS_BY_APPRAISAL_CYCLE_ID_REQUEST: 'PMS_UPDATE_DETAILS_BY_APPRAISAL_CYCLE_ID_REQUEST',
    PMS_UPDATE_DETAILS_BY_APPRAISAL_CYCLE_ID_SUCCESS: 'PMS_UPDATE_DETAILS_BY_APPRAISAL_CYCLE_ID_SUCCESS',
    PMS_UPDATE_DETAILS_BY_APPRAISAL_CYCLE_ID_FAILURE: 'PMS_UPDATE_DETAILS_BY_APPRAISAL_CYCLE_ID_FAILURE',

    PMS_GET_DETAILS_BY_APPRAISAL_CYCLE_REQUEST: 'PMS_GET_DETAILS_BY_APPRAISAL_CYCLE_REQUEST',
    PMS_GET_DETAILS_BY_APPRAISAL_CYCLE_SUCCESS: 'PMS_GET_DETAILS_BY_APPRAISAL_CYCLE_SUCCESS',
    PMS_GET_DETAILS_BY_APPRAISAL_CYCLE_FAILURE: 'PMS_GET_DETAILS_BY_APPRAISAL_CYCLE_FAILURE',

    PMS_GET_PROCESS_SETTING_REQUEST: 'PMS_GET_PROCESS_SETTING_REQUEST',
    PMS_GET_PROCESS_SETTING_SUCCESS: 'PMS_GET_PROCESS_SETTING_SUCCESS',
    PMS_GET_PROCESS_SETTING_FAILURE: 'PMS_GET_PROCESS_SETTING_FAILURE',

};