import React from 'react';
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';


export default function BarDistribution(props) {

  let { optionLists, question, calCScore } = props;



  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }


  let DistObj = {};
  let max = 0;
  if (optionLists && optionLists.length > 0) {
    optionLists.forEach((relation) => {
      DistObj[relation.value] = calCScore(question, relation.value).value;
      if (max < calCScore(question, relation.value).value) {
        max = calCScore(question, relation.value).value;
      }
    });
  }





  let colorTheme = 1;

  let ColorList = ["#757575", "#ff6600", "#ff944d", "#ffb380", "#9fbfdf", "#6699cc", "#336699"];

  if (colorTheme === 2) {
    ColorList = ["#757575", "#ff6600", "#ff944d", "#ffb380", "#9fbfdf", "#6699cc", "#336699"];
  }
  else if (colorTheme === 1) {
    ColorList = ["#757575", "#e60000", "#ff3333", "#ff8080", "#99e699", "#70db70", "#33cc33"];
  }


  let data = [];


  let finalList = []

  for (var i = 1; i <= 6; i++) {
    finalList.push({
      "score": (DistObj && DistObj[i.toString()] ? DistObj[i.toString()] : ""),
      "color": ColorList[i]
    })
  }


  if (question) {
    data.push({
      value: (DistObj['1']),
      itemStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: hexToRgbA(ColorList[1], "0.50")
          },
          {
            offset: 1,
            color: ColorList[1]
          }])
      }
    });
    data.push({
      value: (DistObj['2']),
      itemStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: hexToRgbA(ColorList[2], "0.50")
          },
          {
            offset: 1,
            color: ColorList[2]
          }])
      }

    });
    data.push({
      value: (DistObj['3']),
      itemStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: hexToRgbA(ColorList[3], "0.50")
          },
          {
            offset: 1,
            color: ColorList[3]
          }])
      }

    });
    data.push({
      value: (DistObj['4']),
      itemStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: hexToRgbA(ColorList[4], "0.50")
          },
          {
            offset: 1,
            color: ColorList[4]
          }])
      }

    });
    data.push({
      value: (DistObj['5']),
      itemStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: hexToRgbA(ColorList[5], "0.50")
          },
          {
            offset: 1,
            color: ColorList[5]
          }])
      }

    });
    data.push({
      value: (DistObj['6']),
      itemStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: hexToRgbA(ColorList[6], "0.50")
          },
          {
            offset: 1,
            color: ColorList[6]
          }])
      }

    });
  }


  console.log("max----->", max)

  return (
    <div className='flex items-end h-20 overflow-hidden   space-x-1 '>
      {finalList && finalList.length > 0 ?
        finalList.map((ele) =>
          <div
            style={{ background: ele && ele.color ? ele.color : "#757575", height: (ele && ele.score ? ele.score : "") + "%" }}
            className='h-[20%] bg-[#F77B72] flex items-end justify-center text-[#212121] font-medium w-8'>
            <span className='text-sm'>{ele && ele.score}</span>
          </div>
        ) : null}

    </div>


  );
}
