import React from "react";
import { TbTrash, TbPlus } from 'react-icons/tb';

export default function ModifyIndexModal(props) {
  let { onCloseModal, handleSendSingleMail, questionsList, onDeleteQuestionList } = props;


  function parameterName(a, b, c) {
    return (a && a[b] && a[b][c] ? a[b][c] : "")
  }


  let parameterNameList = [];
  if (questionsList && questionsList.length > 0) {
    questionsList.forEach((ques) => {
      //console.log("ques---------x------>", ques);

      let getIndex = parameterNameList.findIndex(prev => prev.name === parameterName(ques, "parameterBucketId", "name"));
      if (getIndex === -1) {
        if(parameterName(ques, "parameterBucketId", "name")){
          parameterNameList.push({
            "name": parameterName(ques, "parameterBucketId", "name"),
            "list": questionsList.filter(prev => parameterName(prev, "parameterBucketId", "name") === parameterName(ques, "parameterBucketId", "name")).map((ele)=>(ele.id))
          });
        }
      
      }

    })
  }
  //console.log("questionsList--------x------->", questionsList);
  //console.log("parameterNameList--------x------->", parameterNameList);


  return (
    <>
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }} className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full" >
        <div className="absolute left-0 right-0 max-w-xl p-4 mx-auto mt-24 overflow-hidden md:relative">
          <div className="block w-full overflow-hidden bg-white rounded-lg shadow ">
            <div className="flex items-start justify-between p-4 border-b">
              <h1 className="text-lg font-medium text-slate-800">Modify Index</h1>
              <div onClick={() => onCloseModal(false)} className="inline-flex items-center justify-center text-gray-500 bg-gray-100 rounded-full cursor-pointer hover:text-gray-800" >
                <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                </svg>
              </div>
            </div>

            <div className="p-4 bg-white">
              <div className="flex flex-col items-center space-y-4 text-center">
                <div className="pt-1 text-center">Are you sure to delete index questions permanently?</div>

                <div>
                  {parameterNameList && parameterNameList.length > 0 ?
                    parameterNameList.map((ele,index) =>
                      (ele && ele.name) ?
                        <>
                          <div className="flex space-x-2 bg-blue-100 border-b rounded-lg boder">
                            <div className="inline-flex items-center justify-center px-2 cursor-default">{ele && ele.name ? ele.name + " Questions" : "Others"}</div>
                            <button onClick={() => onDeleteQuestionList(ele.list)} className="cursor-pointer border rounded-full w-8 h-8 inline-flex items-center justify-center bg-white  text-[#212121]  hover:bg-red-100 hover:text-red-500">
                              <TbTrash />
                            </button>
                          </div>
                        </>
                        : null) : 
                        
                        <>
                        <div className="pt-1 text-center">Index Questions Not Available!</div>
                        </>}
                </div>


              </div>
              <div className="flex items-center justify-end pt-10 space-x-4">
                <button onClick={() => onCloseModal(false)} className="w-20 px-4 py-2 text-gray-500 border rounded-md">Cancel</button>

              </div>
            </div>
          </div>



        </div>
      </div>
    </>
  );
}
