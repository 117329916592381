import React from "react";
import StackEDAChart from './StackEDAChart';

export default function EmployeeProfileModal(props) {
  let { closeModal, showModal, getDMGIndexManyFilterData, indexType, dmgLevel, dmgValue } = props;



  // let OrgCoreDriverData = {};
  // if(getOrgIndexV2Data && getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length>0){
  //   getSurveyData.orgList.forEach((org)=>{
  //     OrgCoreDriverData[org.name] = getOrgIndexV2Data[org.name];
  //   })
  // }


  // if(OrgCoreDriverData){
  //   valueList = Object.keys(OrgCoreDriverData).map((key) => [ key, OrgCoreDriverData[key] ]);
  // }


  let FinalData = []
  if (getDMGIndexManyFilterData && getDMGIndexManyFilterData.length > 0) {
    getDMGIndexManyFilterData.forEach((item) => {
      //console.log('item------>', item)

      let tempData = {
        "xAxis": item.xAxis,
        "data": []
      }
      let innerList = []
      if (item && item.Data && item.Data.length > 0) {
        item.Data.forEach((inner) => {

          innerList.push(inner)
        })
      }

      tempData["data"] = innerList
      FinalData.push(tempData)


    })
  }

  //console.log('showModal------>', showModal)
  //console.log('getDMGIndexManyFilterData------>', getDMGIndexManyFilterData)
  //console.log('List------>', FinalData)




  return (
    <>
      {showModal ?
        <>
          <div  className="fixed z-40 top-0  left-0  h-screen w-full bg-black bg-opacity-50 " >
            <div className="p-4 overflow-hidden  flex h-screen justify-center items-center w-full">

              <div className="shadow rounded-lg bg-white overflow-hidden xl:w-3/6 lg:w-4/6 md:w-5/6 w-full ">

                <div className="p-4 flex justify-between items-center font-medium text-lg border-b ">
                  <div className="md:flex items-center">
                    <h1 className="font-medium text-xl text-gray-800 ">{"Engagement Score: "}</h1>
                    <p className="text-blue-500 text-sm">{" (" + dmgLevel + ": " + dmgValue + ") "}</p>
                  </div>
                  {/* close btn */}
                  <div onClick={() => closeModal()} className="rounded-full p-2  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                    <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                    </svg>
                  </div>

                </div>

                <div className=" px-2 h-96 overflow-y-auto" >
                  {FinalData && FinalData.length > 0 ?
                    FinalData.map((item) =>
                      <>
                        <div className="p-1 border-b" >
                          <div className="px-2 py-1 font-bold">{item.xAxis}</div>

                          <StackEDAChart
                            getDemographicIndexData={item.data}
                            indexType={indexType}
                            xAxis={item.xAxis}
                          />

                        </div>
                      </>
                    ) :
                    <div className="text-cenetr pt-6 text-gray-500 font-bold ">Data not awailable!</div>}
                </div>





              </div>



            </div>
          </div>
        </>
        : null}

    </>
  );
}
