import React from 'react';


export default function SingleChoice(props) {
  let { question, GetParameterLabel } = props;

  let BucketName = question && question.parameterBucketId && question.parameterBucketId.name ? question.parameterBucketId.name : "";
  let DimensionName = question && question.parameterDimensionId && question.parameterDimensionId.name ? question.parameterDimensionId.name : "";

  return (
    <>
      <div className="flex pt-2 px-4 font-semibold text-xs space-x-2" >
        {BucketName ?
          <span className="bg-purple-100 rounded-full px-4 py-1">
            <>
              <span className="text-purple-500">{"Index:"} </span>
              <span className="text-gray-500">{GetParameterLabel(BucketName)}</span>
            </>

          </span>
          : ""}
        {
          DimensionName ?
            <span className="bg-green-100 rounded-full px-4 py-1">
              <>
                <span className="text-green-500">{"Dimension: "}</span>
                <span className="text-gray-500">{GetParameterLabel(DimensionName)}</span>
              </>
            </span> : ""}
      </div>
    </>
  );
}