import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionScore from './Charts/QuestionScore';
import Filter from './Filter/Filter';
import { CSVLink } from "react-csv";
import EmployeeDropdown from '../Filter/EmployeeDropdown';
import DimensionList from './Filter/DimensionList';



class DimensionPriorities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      crrSelectedEmp: {},
      crrSelectedDimension: ""
    }
  }
  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handle360GetQuestionFilter } = this.props;
    this.setState({ FilterValues: finalFilter });
    handle360GetQuestionFilter(finalFilter, "", "", false);

  }
  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { handle360GetQuestionFilter } = this.props;

      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = value.emp_id;
      }
      let { FilterValues } = this.state;
      console.log("A1 handle360GetQuestionFilter--------->", FilterValues, "", "", false, emp_id)
      handle360GetQuestionFilter(FilterValues, "", "", false, emp_id)

    })
  }

  handleSelectDimension = (value) => {
    this.setState({ crrSelectedDimension: value })
  }

  render() {
    let { selectedFilterValue, loading,
      indexType, optionType, getCommentData, CountRestrict, getEMPFilterLabel, getAnsweredEmployeesData,

      getQuestionData, getQuestionFilterData, EmpFilterData, getIndexFilterData, getIndexByFilterDMGData

    } = this.props;



    let { currentSelectValue, FilterValues } = this.state;
    let { crrSelectedDimension } = this.state;

    console.log('crrSelectedDimension---------', crrSelectedDimension)


    let QuestionData = []

    if (this.state.currFilterValue === 'none') {
      QuestionData = getQuestionData
    }
    else {
      QuestionData = getQuestionFilterData
    }


    let parameterList = [];
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((quesX) => {
        if (quesX && quesX.parameterDimensionName) {
          let getIndex = parameterList.findIndex(prev => prev === quesX.parameterDimensionName);
          if (getIndex === -1) {

            parameterList.push(quesX.parameterDimensionName);



          }
        }
      })
    }

    function getQuesByDimen(dimen) {
      if (QuestionData && QuestionData.length > 0) {
        let quesList = QuestionData.filter(prev => prev.parameterDimensionName === dimen);
        return quesList
      } else {
        return []
      }
    }
    let newQuestionlist = []
    if (parameterList && parameterList.length > 0) {
      parameterList.forEach((item, index) => {

        if (crrSelectedDimension) {
          if (crrSelectedDimension === item) {
            newQuestionlist.push({
              "dimension": item,
              "questionList": getQuesByDimen(item)
            })
          }

        } else {
          if (index < 1) {
            newQuestionlist.push({
              "dimension": item,
              "questionList": getQuesByDimen(item)
            })
          }


        }



      })
    }

    ////console.log("parameterList====>", parameterList)
    console.log("newQuestionlist====>", newQuestionlist)
    ////console.log("QuestionData====>", QuestionData)






    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }
    }
    let { GetParameterLabel, name } = this.props;


    function getRelationScore(question, relation, type, answer) {
      let mean = 0;
      // let sum = question && question[relation + "_sum"] ? question[relation + "_sum"] : 0;
      let count = question && question[relation + "_count"] ? question[relation + "_count"] : 0;

      if (type === "mean") {

        function getScore(answerA) {
          return question && question[relation + "_answer"] && question[relation + "_answer"][answerA] ? question[relation + "_answer"][answerA] : 0
        }

        let sum = 0
        let listX = [1, 2, 3, 4, 5, 6];
        listX.forEach((element) => {
          let score = getScore(element.toString())
          if (score) {
            sum += (element * score)
          }
        })

        mean = Number((sum / count).toFixed(2));
      } else if (type === "count") {
        mean = Number(count);
      }
      else if (type === "answer") {
        mean = question && question[relation + "_answer"] && question[relation + "_answer"][answer] ? question[relation + "_answer"][answer] : ""
      }

      return mean
    }


    function getParaQues(QuestionData22) {
      if (QuestionData22 && QuestionData22.length > 0) {
        return QuestionData.filter(prev => prev.parameterDimensionName === (crrSelectedDimension ? crrSelectedDimension : (parameterList && parameterList.length > 0 ?
          parameterList[0] : "")))
      }
      else {
        return []
      }
    }




    function sortRelation(list) {
      if (list && list.length > 0) {
        let List1 = list.filter(prev => prev === "Self")
        let List2 = list.filter(prev => prev !== "Self")
        let listMerged = List1.concat(List2)
        // var first = "Self";
        // list.sort(function(x,y){ return x == first ? -1 : y == first ? 1 : 0; });
        return listMerged
      } else {
        return []
      }
    }



    return (
      <>
        <div className="items-center justify-between space-y-4 md:flex md:space-y-0">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />

          <div className='flex flex-wrap items-center gap-4' >

            <DimensionList
              parameterList={parameterList}
              crrSelectedDimension={this.state.crrSelectedDimension}
              handleSelectDimension={this.handleSelectDimension}
              GetParameterLabel={GetParameterLabel}
            />



            <EmployeeDropdown
              getAnsweredEmployeesData={getAnsweredEmployeesData}
              crrSelectedEmp={this.state.crrSelectedEmp}
              handleSelectEmployee={this.handleSelectEmployee}
            />

          </div>




          {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
          {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} /> */}

          {/* <div className='mr-4'>
            {ScoreExcel ?
            <CSVLink data={ScoreExcel} filename={"EnagagementFavorability.csv"}>
            <p className="text-sm font-normal text-purple-700 cursor-pointer hover:text-purple-500">
              {"Download Excel"}
            </p>                
            </CSVLink>
            : '...'}
            </div> */}



        </div>




        {true ?
          <>

            <div onClick={() => this.closeFilter(false)} className="" >

              <div className="w-full" >

                <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">

                  <div className="p-4 space-y-6 bg-white border rounded-md">
                    <div>

                      <h1 className="py-3 font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap capitalize">{GetParameterLabel(crrSelectedDimension ? crrSelectedDimension : (parameterList && parameterList.length > 0 ?
                        parameterList[0] : ""))}</h1>
                      <div className='flex flex-wrap pt-1 divide-x divide-black' >

                        <div className="flex items-center pr-4"><div style={{ background: "#F6736A" }} className="p-2 rounded-sm" /><p className="ml-2">Self score</p></div>
                        <div className="flex items-center pl-4"><div style={{ background: "#f9d350" }} className="p-2 rounded-sm" /><p className="ml-2">Other Respondents Average score</p></div>

                      </div>
                    </div>


                    {QuestionData && QuestionData.length > 0 ?



                      <div className='w-full divide-y' >
                        {getParaQues(QuestionData) && getParaQues(QuestionData).length > 0 ?
                          getParaQues(QuestionData).map((question, index) =>

                            <div key={index} className="flex flex-col items-center w-full py-4 text-sm text-gray-800 bg-white lg:flex-row">
                              {/* <td className="w-2/12 px-2 border border-b">
                                    <div style={{ width: '20%' }}>{question && question.parameterDimensionName ?
                                      GetParameterLabel(question.parameterDimensionName) :
                                      ""}</div>
                                  </td> */}
                              <div className="w-full px-2 ">
                                <div className="font-medium whitespace-pre-line cursor-pointer ">{String.fromCharCode(97 + index) + ") "}{question.name}</div>
                                {/* <div className="tooltip-response-question2">
                                  {question.name}
                                </div> */}
                              </div>

                              <div className="w-full ">
                                <QuestionScore question={question} CountRestrict={CountRestrict} currFilterValue={this.state.currFilterValue} currFilterName={this.state.currFilterName} selectedFilterValue={selectedFilterValue} getQuestionFilterData={getQuestionFilterData} />
                              </div>
                            </div>
                          )
                          : null}

                      </div>


                      :
                      <>
                        {!loading ?
                          <div className="pt-40 text-3xl text-center text-gray-400">Data Not Available!</div>
                          : null}
                      </>
                    }



                    {/* <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div> */}
                  </div>
                </div>
              </div>





              {/* A,B,C,D Table In One Design */}

              <div className="w-full mt-12 space-y-4" >

                <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">

                  <div className="">



                    <div className="">
                      {newQuestionlist && newQuestionlist.length > 0 ?
                        newQuestionlist.map((item) =>
                          <>
                            <div className="w-full ">

                              <div className="grid grid-cols-1 gap-4 ">
                                {item && item.questionList && item.questionList.length > 0 ?
                                  item.questionList.map((question, index) =>
                                    <>
                                      <div className='p-4 space-y-4 bg-white border rounde-md'>

                                        <h1 className="py-3 font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap capitalize">{String.fromCharCode(97 + index) + ") "}{question.name}</h1>

                                        <div className="w-full mb-4 overflow-hidden overflow-x-auto whitespace-nowrap">
                                          <table className="w-full border ">
                                            <thead>
                                              <tr className="text-sm text-gray-800 capitalize border-b" style={{ background: '#dcedc8', }} >
                                                <th className="p-2 font-medium text-left border-r">Relationship</th>
                                                <th className="p-2 font-medium text-center border-r">Number of Reviewers</th>
                                                <th className="p-2 font-medium text-center border-r">Avg Score</th>

                                                <th className="p-2 font-medium text-center border-r">Strongly Disagree</th>
                                                <th className="p-2 font-medium text-center border-r">Disagree</th>
                                                <th className="p-2 font-medium text-center border-r">Slightly Disagree</th>
                                                <th className="p-2 font-medium text-center border-r">Slightly Agree</th>
                                                <th className="p-2 font-medium text-center border-r">Agree</th>
                                                <th className="p-2 font-medium text-center border-r">Strongly Agree</th>


                                              </tr>
                                            </thead>
                                            <tbody>

                                              {sortRelation(question.relationList) && sortRelation(question.relationList).length > 0 ?
                                                sortRelation(question.relationList).map((relation, index2) =>

                                                  <tr key={index2} className="mb-10 text-sm text-gray-800 bg-white lg:mb-0">
                                                    <td className="p-2 text-left border border-b ">
                                                      {relation}
                                                    </td>
                                                    <td className="p-2 text-center border border-b ">
                                                      {getRelationScore(question, relation, "count")}
                                                    </td>
                                                    <td className="p-2 text-center border border-b ">
                                                      {getRelationScore(question, relation, "mean")}
                                                    </td>

                                                    <td className="p-2 text-center border border-b ">
                                                      {getRelationScore(question, relation, "answer", "1")}
                                                    </td>
                                                    <td className="p-2 text-center border border-b ">
                                                      {getRelationScore(question, relation, "answer", "2")}
                                                    </td>
                                                    <td className="p-2 text-center border border-b ">
                                                      {getRelationScore(question, relation, "answer", "3")}
                                                    </td>
                                                    <td className="p-2 text-center border border-b ">
                                                      {getRelationScore(question, relation, "answer", "4")}
                                                    </td>
                                                    <td className="p-2 text-center border border-b ">
                                                      {getRelationScore(question, relation, "answer", "5")}
                                                    </td>
                                                    <td className="p-2 text-center border border-b ">
                                                      {getRelationScore(question, relation, "answer", "6")}
                                                    </td>

                                                  </tr>
                                                )
                                                : null}

                                            </tbody>
                                          </table>
                                        </div>



                                      </div>
                                    </>
                                  )
                                  : null}
                              </div>




                              {/* <table className="w-full border ">
      <thead>
        <tr className="text-xs text-gray-800 bg-gray-100 border-b">
          <th className="px-2 py-2 font-bold border-r"><div className="flex items-center justify-left">Dimension</div></th>
          <th className="px-2 py-2 font-bold border-r"><div className="flex items-center justify-left">Question</div></th>
          <th className="px-4 py-2 font-bold border-r"><div className="flex items-center justify-left">Favorability Score</div></th>
        </tr>
      </thead>
      <tbody>
        {QuestionData && QuestionData.length > 0 ?
          QuestionData.map((question, index) =>
            <tr key={index} className="mb-10 text-xs text-gray-800 bg-white lg:mb-0">
              <td className="w-2/12 px-2 border border-b">
                <div style={{ width: '20%' }}>{question && question.parameterDimensionName ?
                  GetParameterLabel(question.parameterDimensionName) :
                  ""}</div>
              </td>
              <td className="w-2/12 px-2 border border-b tooltip-response-question1">
                <div className="whitespace-pre-line cursor-pointer w-96">{question.name}</div>

              </td>
              <td className="w-10/12 border border-b ">
                <QuestionScore question={question} CountRestrict={CountRestrict} currFilterValue={this.state.currFilterValue} currFilterName={this.state.currFilterName} selectedFilterValue={selectedFilterValue} getQuestionFilterData={getQuestionFilterData} />
              </td>
            </tr>
          )
          : null}

      </tbody>
    </table> */}





                            </div>
                          </>
                        ) :
                        <>
                          {!loading ?
                            <div className="pt-40 text-3xl text-center text-gray-400">Data Not Available!</div>
                            : null}
                        </>


                      }
                    </div>





                    {/* <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div> */}
                  </div>
                </div>
              </div>


            </div>

          </>
          :
          <>
            {!loading ?
              <div className="pt-40 text-3xl text-center text-gray-400">We have not calculated this section!</div>
              : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DimensionPriorities);
