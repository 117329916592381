import React, { Component } from 'react';
import { connect } from 'react-redux';
import Summary from './Components/Summary/Summary';

class HappinessIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
        optionType:1
    }
  }
  componentDidMount() {

  }

  onOptionType=(type)=>{
    this.setState({optionType:type});

    // if(type===2 || type===5){
    //     let { handleQuestion } = this.props;
    //     handleQuestion();
    // }

    // if(type===3){
    //   let { handleQuestionMeanScore,EmpFilterData } = this.props;
    //   if(EmpFilterData && EmpFilterData.length>0){
    //     let level = EmpFilterData[0].name;
    //     handleQuestionMeanScore(level);
    //   }
    // }

  }


  onSwitchOptionScreen=()=>{
    let {  getQuestionOthersResultData ,handleOthersFilterCall,getQuestionOthersResultFilterData,
      currentQuestionId, getIndexFilterData, EmpFilterData, getEMPFilterLabel } = this.props;


    let {  optionType } = this.state;

      switch(optionType){
        case 1: return <Summary 
        getEMPFilterLabel={getEMPFilterLabel}
        getQuestionOthersResultData={getQuestionOthersResultData} 
        getQuestionOthersResultFilterData={getQuestionOthersResultFilterData}
        handleOthersFilterCall={handleOthersFilterCall} currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData}  getIndexFilterData={getIndexFilterData}

        />
        default: return <div>Default Option</div> 
      }


  }

  render() {
    let { ItemSaveData , cardType} = this.props;
    let { optionType } = this.state;

    //console.log('--------->ItemSaveData:',ItemSaveData)
    let indexName = ''
    if(ItemSaveData && ItemSaveData.name){
      indexName = ItemSaveData.name;
    }


    let OptionList = [
      {
        "index": 1,
        "name": "Summary"
      }
    ]


    return (
      <> 
          <div className="items-center justify-between border-b md:flex">


            <div className="flex space-x-2 overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 whitespace-nowrap tab-scroll scollar-xs">
              {OptionList && OptionList.length > 0 ?
                OptionList.map((item) =>
                  <span onClick={() => this.onOptionType(item.index)}
                    className={(optionType === item.index) ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                      "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}>
                    {item.name}
                  </span>
                ) : null}

            </div>
          </div>

          {this.onSwitchOptionScreen()}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HappinessIndex);
