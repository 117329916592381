import React, { Component } from "react";
import { connect } from "react-redux";

class IndexKPI extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() { }

  render() {
    let { ResponseAction } = this.props;

    return (
     
        <div className="grid grid-cols-1 gap-4 md:grid-cols-4 ">
          {/* box 1 */}
          <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-white ">
            <div className="flex items-center w-full ">
              <div className="space-y-2">
                <p className="block text-base text-[#212112]/80 w-full capitalize ">
                  Assigned Tasks
                </p>
                <h1 className="text-3xl font-medium w-full text-[#212121]">
                  {ResponseAction["assigned"]}
                </h1>
              </div>
            </div>
            <div className="p-4 bg-[#2196F3]/10 text-[#2196F3] rounded-md">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checklist " width="50" height="50" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9.615 20h-2.615a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8" /><path d="M14 19l2 2l4 -4" /><path d="M9 8h4" /><path d="M9 12h2" /></svg>
            </div>
          </div>
          {/* box 2 */}
          <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-white ">
            <div className="flex items-center w-full ">
              <div className="space-y-2">
                <p className="block text-base text-[#212112]/80 w-full capitalize ">
                  In-Progress Tasks
                </p>
                <h1 className="text-3xl font-medium w-full text-[#212121]">
                  {ResponseAction["pending"]}
                </h1>
              </div>
            </div>
            <div className="p-4 bg-[#2196F3]/10 text-[#2196F3] rounded-md">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clipboard-copy " width="50" height="50" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h3m9 -9v-5a2 2 0 0 0 -2 -2h-2" /><path d="M13 17v-1a1 1 0 0 1 1 -1h1m3 0h1a1 1 0 0 1 1 1v1m0 3v1a1 1 0 0 1 -1 1h-1m-3 0h-1a1 1 0 0 1 -1 -1v-1" /><path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /></svg>
            </div>
          </div>
          {/* box 3 */}
          <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-white ">
            <div className="flex items-center w-full ">
              <div className="space-y-2">
                <p className="block text-base text-[#212112]/80 w-full capitalize ">
                  Completed Tasks
                </p>
                <h1 className="text-3xl font-medium w-full text-[#212121]">
                  {ResponseAction["completed"]}
                </h1>
              </div>
            </div>
            <div className="p-4 bg-[#2196F3]/10 text-[#2196F3] rounded-md">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clipboard-check " width="50" height="50" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" /><path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /><path d="M9 14l2 2l4 -4" /></svg>
            </div>
          </div>

          <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-white ">
            <div className="flex items-center w-full ">
              <div className="space-y-2">
                <p className="block text-base text-[#212112]/80 w-full capitalize ">
                  Not Started Tasks
                </p>
                <h1 className="text-3xl font-medium w-full text-[#212121]">
                  {ResponseAction["notstarted"]}
                </h1>
              </div>
            </div>
            <div className="p-4 bg-[#2196F3]/10 text-[#2196F3] rounded-md">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clipboard-list" width="50" height="50" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" /><path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /><path d="M9 12l.01 0" /><path d="M13 12l2 0" /><path d="M9 16l.01 0" /><path d="M13 16l2 0" /></svg>
            </div> 
          </div>
        </div>
   
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(IndexKPI);
