import React, { useState } from "react";
import Header from "./Header/Header";
export default function EmployeeSalaryHikeStatus() {
  const [open, close] = useState(0);
  return (
    <div className="h-screen overflow-hidden bg-gray-50">
      <Header />

      <div
        className="flex flex-row w-full"
        style={{ height: "calc(100% - 2rem)" }}
      >
        <div
          className="cursor-pointer transition-width w-64  lg:block lang lg:relative fixed top-0 left-0 z-20  h-screen     duration-200 transform bg-white border-r hidden"
          style={{ height: "calc(100% - 0rem)" }}
        >
          <div className="my-4 whitespace-nowrap ">
            <div
              title="Users"
              className="flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer   border-l-4 border-[#2196F3] bg-[#2196F3]/10"
            >
              <div className="flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center">
                <span className=" material-symbols-outlined">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-users"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth="1.8"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx={9} cy={7} r={4} />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                </span>
              </div>
              <span className=" text-sm text-[#2196F3]">Appraisal Cycle</span>
            </div>
          </div>
        </div>

        <main
          className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto  md:p-6 p-4 space-y-6  "
          style={{ height: "calc(100% - 4rem)" }}
        >
          <div className="bg-white -m-6 ">
            <div className="flex justify-between px-6 py-4">
              <div className="flex items-center space-x-1 text-[#212121]">
                <div className="flex items-center space-x-4">
                  <button className="duration-200 rounded-md cursor-pointer hover:bg-slate-100">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth={0}
                      viewBox="0 0 24 24"
                      className="w-5 h-5"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0V0z" opacity=".87" />
                      <path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z" />
                    </svg>{" "}
                  </button>
                  <img
                    src="img/newimg/Rectangle 11116.png"
                    alt="userimg"
                    className="object-cover w-12 h-12 rounded-full"
                  />
                  <div className="ml-4">
                    <span className="text-xl font-semibold text-[#3D405B]">
                      Hari KC
                    </span>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-slash text-[#3D405B]/70"
                  width={18}
                  height={18}
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M17 5l-10 14" />
                </svg>
                <p className="text-sm text-[#3D405B]/70 font-medium">
                  {" "}
                  Salary Hike
                </p>
              </div>
            </div>
          </div>

          <div className="grid gap-4 my-4 lg:grid-cols-4 md:grid-cols-2 pt-6">
            <div className="flex items-start h-32 p-6 bg-white border rounded-sm cursor-pointer space-x-4 ">
              <div className="p-1.5 bg-green-500 rounded-full text-white"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="28" height="28" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg></div>
              <div className="flex items-center w-full ">
                <div className="space-y-2">
                  <p className="block text-base text-[#3d405b] w-full   font-semibold">
                    Total
                  </p>
                  <h1 className="text-3xl font-medium w-full text-[#fff]">
                    9
                  </h1>
                </div>
              </div>
            </div>
            <div className="flex items-start h-32 p-6 bg-white border rounded-sm cursor-pointer space-x-4 ">
              <div className="p-1.5 bg-green-500 rounded-full text-white"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="28" height="28" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg></div>
              <div className="flex items-center w-full ">
                <div className="space-y-2">
                  <p className="block text-base text-[#3d405b] w-full   font-semibold">
                    Total
                  </p>
                  <h1 className="text-3xl font-medium w-full text-[#fff]">
                    9
                  </h1>
                </div>
              </div>
            </div>
            <div className="flex items-start h-32 p-6 bg-white border rounded-sm cursor-pointer space-x-4 ">
              <div className="p-1.5 bg-amber-500 rounded-full text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clock-hour-5" width="28" height="28" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                  <path d="M12 12l2 3"></path>
                  <path d="M12 7v5"></path>
                </svg></div>
              <div className="flex items-center w-full ">
                <div className="space-y-2">
                  <p className="block text-base text-[#3d405b] w-full   font-semibold">
                    Pending
                  </p>
                  <h1 className="text-3xl font-medium w-full text-[#fff]">
                    9
                  </h1>
                </div>
              </div>
            </div>
            <div className="flex items-start h-32 p-6 bg-white border rounded-sm cursor-pointer space-x-4 ">
              <div className="p-1.5 bg-red-500 rounded-full text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M18 6l-12 12"></path>
                  <path d="M6 6l12 12"></path>
                </svg>
              </div>
              <div className="flex items-center w-full ">
                <div className="space-y-2">
                  <p className="block text-base text-[#3d405b] w-full   font-semibold">
                    Rejected
                  </p>
                  <h1 className="text-3xl font-medium w-full text-[#fff]">
                    9
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white p-6 space-y-6 border rounded-md">
            <h1 className="text-xl font-medium text-[#3D405B]">
              Employee List
            </h1>
            <div className="overflow-x-auto   whitespace-nowrap border border-gray-200  ">
              <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse">
                <thead className="bg-white">
                  <tr className>
                    <th
                      rowSpan={2}
                      className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r"
                    >
                      Employees
                    </th>
                    <th
                      rowSpan={2}
                      className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r text-center"
                    >
                     Rating
                    </th>
                    <th
                      rowSpan={2}
                      className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r text-center"
                    >
                     Salary Yearly
                    </th>
                    <th
                      rowSpan={2}
                      className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r text-center"
                    >
                     Purposed Promotion
                    </th>
                    
                    <th
                      colSpan={2}
                      className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                    >
                      Performance
                    </th>
                    <th
                      colSpan={2}
                      className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                    >
                      Potential
                    </th>
                    <th
                       rowSpan={1}
                       colSpan={2}
                      className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                    >
                     Action

                    </th>
                  </tr>
                  <tr className="bg-[#f9fafb] border-t text-sm text-[#3D405B]">
                    {" "}
                   
                    <th className="p-3 px-6 border-r  font-semibold">Score</th>
                    <th className="p-3 px-6  border-r  font-semibold">
                      Rating
                    </th>
                    <th className="p-3 px-6 border-r  font-semibold">Score</th>
                    <th className="p-3 px-6  border-r  font-semibold">
                      Rating
                    </th> 
                  
                  </tr>
                </thead>
                <tbody className="border-t   divide-y  ">
                  <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                    <td className="px-4 py-3   border-r">
                    <div className="flex items-center space-x-2">
                       <div className="w-12">
                       <img
                          src="img/newimg/Rectangle 11116.png"
                          alt=""
                          className="rounded-full w-12 h-12  "
                        />
                       </div>
                        <div className="flex-1">
                          <p className="capitalize text-base font-medium block text-[#3D405B]">
                            Hari KC
                          </p>
                          <p className="capitalize text-sm  block text-[#3D405B] font-normal">
                            HR Manager - Dept of Human  
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                    Outstanding
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                    ₹ 20000
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                    Team Leader
                    </td>
                  
                    <td className="px-6 py-3 text-center border-r">8-10</td>
                    <td className="px-6 py-3 text-center border-r">
                      Outstanding
                    </td>
                    <td className="px-6 py-3 text-center border-r ">0.0-2.0</td>
                    <td className="px-6 py-3 text-center ">Outstanding</td>
                    <td className="px-6 py-3   border-l text-center">
                    <button className="  bg-[#FFA000] text-white w-32 px-8 py-2 rounded-md capitalize  text-sm font-medium ">Pending</button>
                    </td>
                  </tr>
                  <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                    <td className="px-4 py-3   border-r">
                    <div className="flex items-center space-x-2">
                       <div className="w-12">
                       <img
                          src="img/newimg/Rectangle 11116.png"
                          alt=""
                          className="rounded-full w-12 h-12  "
                        />
                       </div>
                        <div className="flex-1">
                          <p className="capitalize text-base font-medium block text-[#3D405B]">
                            Hari KC
                          </p>
                          <p className="capitalize text-sm  block text-[#3D405B] font-normal">
                            HR Manager - Dept of Human  
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                    Outstanding
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                    ₹ 20000
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                    Team Leader
                    </td>
                  
                    <td className="px-6 py-3 text-center border-r">8-10</td>
                    <td className="px-6 py-3 text-center border-r">
                      Outstanding
                    </td>
                    <td className="px-6 py-3 text-center border-r ">0.0-2.0</td>
                    <td className="px-6 py-3 text-center ">Outstanding</td>
                    <td className="px-6 py-3   border-l text-center">
                    <button className="  bg-[#4CAF50] text-white w-32 px-8 py-2 rounded-md capitalize  text-sm font-medium ">Approved</button>
                    </td>
                  </tr>
                  <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                    <td className="px-4 py-3   border-r">
                    <div className="flex items-center space-x-2">
                       <div className="w-12">
                       <img
                          src="img/newimg/Rectangle 11116.png"
                          alt=""
                          className="rounded-full w-12 h-12  "
                        />
                       </div>
                        <div className="flex-1">
                          <p className="capitalize text-base font-medium block text-[#3D405B]">
                            Hari KC
                          </p>
                          <p className="capitalize text-sm  block text-[#3D405B] font-normal">
                            HR Manager - Dept of Human  
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                    Outstanding
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                    ₹ 20000
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                    Team Leader
                    </td>
                  
                    <td className="px-6 py-3 text-center border-r">8-10</td>
                    <td className="px-6 py-3 text-center border-r">
                      Outstanding
                    </td>
                    <td className="px-6 py-3 text-center border-r ">0.0-2.0</td>
                    <td className="px-6 py-3 text-center ">Outstanding</td>
                    <td className="px-6 py-3   border-l text-center">
                    <button className="  bg-[#F44336] text-white w-32 px-8 py-2 rounded-md capitalize  text-sm font-medium ">rejected</button>
                    </td>
                  </tr>
                  
              
                </tbody>
              </table>
            </div>

            <div className="flex md:flex-row gap-6 flex-col items-center justify-between border-t p-6 -m-6">
            <p className="text-[#3D405B] md:text-base text-sm font-semibold">
                Showing 1 to 5 of 150 entries
              </p>
              <div className="flex border divide-x rounded-md ">
                <button className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                  Previous
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">
                  1
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                  2
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                  3
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                  4
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                  5
                </button>
                <button className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                  Next
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
