import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurveyName: '',
      currentSurveyId: '',

      showDropdown: false,

      showSidebar: false,


      showSubMenu: false
    }
  }
  componentDidMount() {
  }

  onDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false })
    }
    else {
      this.setState({ showDropdown: true })
    }
  }

  logout = () => {
    this.props.dispatch(userActions.logout());
  }



  onSubMenu = (check) => {
    if (this.state.showSidebar) {
      this.setState({ showSubMenu: check });
    }
  }


  sidebarToggle = () => {
    if (this.state.showSubMenu) {
      this.setState({ showSubMenu: false })
    }
    this.setState({ showSidebar: !this.state.showSidebar })
  }


  render() {
    let { OverviewList, handleSidebarClick, IndexNo, showOverview } = this.props;
    let { showSidebar } = this.state;

    // console.log('OverviewList: ',OverviewList);

    return (


      <div className={showSidebar ?
        "fixed lg:block hidden shadow-xl w-64 left-0 top-16 h-screen   z-30 transition duration-300 transform bg-white border-r h-increse" :
        "  lg:block hidden w-16 left-0 top-16 h-screen   z-30 transition duration-300 transform bg-white border-r h-increse"
      } style={{ height: 'calc(100% - 4rem)' }} id="customscroll2">
        <div className="flex items-center ">
          <div className="flex items-center px-4 py-3 w-16  text-blue-500  justify-center">
            <span onClick={() => this.sidebarToggle()} className="cursor-pointer material-symbols-outlined text-gray-500 rounded hover:bg-gray-100 p-1">
              {showSidebar ?
                "chevron_left" :
                "chevron_right"
              }
            </span>
          </div> 
          {showSidebar ?
            <span className="cursor-default font-normal ml-4 text-sm text-gray-500">Insight</span>
            : null}
        </div>
        <div className="mb-4 font-medium   overflow-y-auto overflow-hidden" style={{ height: 'calc(100% - 4rem)' }}>
          
          
        <div title={"Overview"} onClick={() => handleSidebarClick(false)}
            className={showOverview ? "cursor-pointer flex items-center bg-blue-50 text-blue-500 border-r-2 border-blue-500" :
              "cursor-pointer flex items-center hover:bg-blue-50 text-gray-500"}>
            <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
              <span className="material-symbols-outlined   p-2">dashboard</span>
            </div>
            {showSidebar ? <span class=" text-sm text-[#3D405B]/70 font-medium ">Overview</span> : null}
          </div>

  {/* 
          {OverviewList && OverviewList.length > 0 ?
            OverviewList.map((item, index) =>

              <div title={item.name + " Insight"} keys={index} onClick={() => handleSidebarClick(true, item, index + 1, item.mean !== "NaN")}
                className={(index + 1) === IndexNo ? "cursor-pointer flex items-center bg-blue-50  text-blue-500 border-r-2 border-blue-500" :
                  "cursor-pointer flex items-center hover:bg-blue-50  text-gray-500 "}>
                <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
                  <span className="material-symbols-outlined  p-2">{
                    ((index + 1) === IndexNo) ? "sentiment_satisfied_alt" : "sentiment_satisfied_alt"
                  }</span>
                </div>
                {showSidebar ? <span class=" text-sm text-[#3D405B]/70 font-medium ">{item.name + " Insight"}</span> : null}
              </div>


            ) : null} */}





          {/* 
  <div title={"Advance Insights"} onClick={()=>handleSidebarExtra(2)} 
    className={showAdvanceInsight?"cursor-pointer flex items-center bg-blue-50   text-blue-500 border-r-2 border-blue-500":
    "cursor-pointer flex items-center hover:bg-blue-50   text-gray-500"}>

      <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
        <span className="material-symbols-outlined  p-2">engineering</span>
      </div>
      <span class=" text-sm text-[#3D405B]/70 font-medium ">Advance Insights</span>
    </div>
 */}

          {/* 
<div title={"Text Analysis"} onClick={()=>handleSidebarExtra(1)} 
    className={showTextAnalysis?"cursor-pointer flex items-center bg-blue-50   text-blue-500 border-r-2 border-blue-500":
    "cursor-pointer flex items-center hover:bg-blue-50   text-gray-500"}>

      <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
        <span className="material-symbols-outlined  p-2">manage_search</span>
      </div>
      <span class=" text-sm text-[#3D405B]/70 font-medium ">Text Analysis</span>
    </div>

 */}



        </div>
      </div>

    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(Sidebar);
