import { data } from "autoprefixer";
import React, { Component } from "react";
import { connect } from "react-redux";

class NudgesBehaviourItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  componentDidMount() { }

  openHelpModal = (check) => {
    this.setState({ showModal: check });
  };

  render() {
    let {
      name,
      optionType,
      dataItem,
      DistList,
      IndexNme,
      CountRestrict,
      XAxisList,
      getColor,
      GetParameterLabel,
      getOverallScore,
      getFavouribilityScore,
      nudgeTextList
    } = this.props;
    let { showModal } = this.state;

    function showNaN(item, name) {
      let dataScore = CountRestrict(item.count)
        ? "NA"
        : item && item[name] && item[name]
          ? item[name].toFixed(2)
          : 0;
      return dataScore;
    }

    //-----------------------Nudeges-------------------------------------------------
    let textLabel = "";
    let dataListAsc = [];
    let dataListDsc = [];
    let dataListDetail = [];

    if (optionType === "item") {
      textLabel =
        "Performing " + IndexNme + " Dimensions Behaviour Item Summary ";

      function getScoreData(dataItem, sortType) {
        let dataList = [];
        let tempSortData = [];
        if (
          dataItem &&
          dataItem.questionList &&
          dataItem.questionList.length > 0
        ) {
          dataItem.questionList.forEach((data) => {
            tempSortData.push(data);
          });
        }

        console.log("tempSortData----->", tempSortData);

        if (tempSortData && tempSortData.length > 0) {
          if (sortType === 1) {
            tempSortData.sort((a, b) => a.score - b.score);
          } else {
            tempSortData.sort((b, a) => a.score - b.score);
          }
          tempSortData.forEach((question) => {
            dataList.push({
              questionId: question.id,
              name: question.name,
              para: question.parameterDimensionName,
              score:
                question && question.score > 0
                  ? Number(question.score).toFixed(2)
                  : 0,
              count: question.count,
            });
          });
        }
        return dataList;
      }

      dataListAsc = getScoreData(dataItem, 1);
      dataListDsc = getScoreData(dataItem, 2);
    }

    if (optionType === "itemdetails") {
      textLabel =
        "Performing " + IndexNme + " Dimensions Behaviour Item Detail";
    }

    function showLowScore(overallScore, dmgScore) {
      if (parseFloat(overallScore) <= parseFloat(dmgScore)) {
        //greater (blue)
        return false;
      } else {
        //lesse (red)
        return true;
      }
    }
    function showHighScore(overallScore, dmgScore) {
      if (parseFloat(overallScore) <= parseFloat(dmgScore)) {
        //greater (blue)
        return true;
      } else {
        //lesse (red)
        return false;
      }
    }


    let rangeList = [
      {
        "label": "less than 30%",
        "range1": 0,
        "range2": 30,
      },
      {
        "label": "30 to 50%",
        "range1": 30,
        "range2": 50,
      },
      {
        "label": "50 to 60%",
        "range1": 50,
        "range2": 60,
      },
      {
        "label": "60 to 80%",
        "range1": 60,
        "range2": 80,
      },
      {
        "label": "greater than 80%",
        "range1": 80,
        "range2": 100,
      }
    ];



    function getRangeLabel(score) {
      let output = ""
      if (rangeList && rangeList.length > 0) {
        rangeList.forEach((ele) => {
          console.log("ele.range1", ele.range1)
          console.log("score", score)
          console.log("ele.range2", ele.range2)

          if (ele.range1 < score && score <= ele.range2) {
            output = ele.label;
          }
        })
      }
      return output
    }



    function getRangeText(rangeLabel, dimension, rangeListV1) {
      console.log("rangeLabel->", rangeLabel)
      console.log("rangeListV1->", rangeListV1)

      let output = "";
      if (rangeListV1 && rangeListV1.length > 0) {
        let getIndex = rangeListV1.findIndex(prev => prev.label === rangeLabel);
        console.log("getIndex->", getIndex)

        if (getIndex !== -1) {
          let temp = rangeListV1[getIndex]["text"];
          console.log("temp->", temp)
          if (dimension) {
            output = temp.replace("@", dimension.trim()).replace("@", dimension.trim()).replace("@", dimension.trim());
          } else {
            output = temp;
          }
        }

      }
      return output
    }


    function getRangeList(qNudgeList, quesId) {
      let rangeList = []
      if (qNudgeList && qNudgeList.length > 0 && quesId) {
        let getIndex = qNudgeList.findIndex(prev => prev.questionId.toString() === quesId.toString());

        if (getIndex !== -1) {
          rangeList = qNudgeList[getIndex]["rangeList"]
        }
      }
      // console.log("rangeList->",rangeList)

      return rangeList
    }


    let scoreRange = [];
    if (dataListAsc && dataListAsc.length > 0) {
      dataListAsc.forEach((ele) => {
        console.log("ele.questionId--1231->", ele)

        let questionName = ele.name;
        let questionScore = parseFloat(ele.score);
        let questionRange = getRangeLabel(questionScore);
        let rangeListV1 = getRangeList(dataItem.nudgeTextList, ele.questionId);
        let questionText = getRangeText(questionRange, GetParameterLabel(ele.para), rangeListV1);
        console.log("questionText123123->", questionText);

        let inner = {
          "rangeLabel": questionRange,
          "question": [{
            "questionName": questionName,
            "questionText": questionText,
            "name": `"` + questionName + `" ` + questionText,
            "score": questionScore
          }]
        }

        let getIndex1 = scoreRange.findIndex(prev => prev.para === ele.para);
        if (getIndex1 === -1) {
          scoreRange.push({
            "para": ele.para,
            "list": [inner]
          });

        } else {
          let getIndex2 = scoreRange[getIndex1]["list"].findIndex(prev => prev.rangeLabel === questionRange);
          if (getIndex2 === -1) {
            scoreRange[getIndex1]["list"].push(inner)
          } else {
            scoreRange[getIndex1]["list"][getIndex2]["question"].push({
              "name": `"` + questionName + `" ` + questionText,
              "questionName": questionName,
              "questionText": questionText,
              "score": questionScore
            })
          }
        }


      })
    }

    console.log("scoreRange------>", scoreRange)



    return (
      <>

        {/* nudges model code on page*/}

        {true ? (
          <button
            onClick={() => this.openHelpModal(true)}
            className="bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"
          >
            <svg
              className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B]"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 8C5.46957 8 4.96086 7.78929 4.58579 7.41421C4.21071 7.03914 4 6.53043 4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4C6.53043 4 7.03914 4.21071 7.41421 4.58579C7.78929 4.96086 8 5.46957 8 6C8 6.53043 7.78929 7.03914 7.41421 7.41421C7.03914 7.78929 6.53043 8 6 8ZM6 8V16C6 16.5304 6.21071 17.0391 6.58579 17.4142C6.96086 17.7893 7.46957 18 8 18H13M18 16C18.5304 16 19.0391 16.2107 19.4142 16.5858C19.7893 16.9609 20 17.4696 20 18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20C17.4696 20 16.9609 19.7893 16.5858 19.4142C16.2107 19.0391 16 18.5304 16 18C16 17.4696 16.2107 16.9609 16.5858 16.5858C16.9609 16.2107 17.4696 16 18 16ZM18 16V8C18 7.46957 17.7893 6.96086 17.4142 6.58579C17.0391 6.21071 16.5304 6 16 6H11M11 6L14 9M11 6L14 3M13 18L10 15M13 18L10 21"

                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>Nudges</span>
          </button>
        ) : null}

        {showModal ? (
          <div className="fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center w-full h-full p-4 bg-black bg-opacity-40 lg:p-0">
            <div className="w-full mx-auto bg-white rounded-md xl:w-7/12 lg:w-4/6 ">
              <div className="flex items-center justify-between p-4 border-b">
                {" "}
                <h1 className="p-1 text-lg font-semibold capitalize">Nudeges</h1>
                <span
                  onClick={() => this.openHelpModal(false)}
                  className=" material-symbols-outlined bg-gray-50 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150"
                >
                  close
                </span>
              </div>



              <div className="lg:p-6 p-4 h-[40rem] overflow-y-auto space-y-6">
                <div className="space-y-4">
                  <h1 className="text-lg font-medium capitalize">{name}</h1>
                  {scoreRange && scoreRange.length > 0 ?
                    scoreRange.map((ele) =>
                      <div className="">
                        <div className="bg-[#2196F3]/20 px-4 py-2">
                          <h1 className="text-lg font-medium">{GetParameterLabel(ele.para)}</h1>
                        </div>
                        <div>
                          {ele && ele.list && ele.list.length > 0 ?
                            ele.list.map((innerEle, index) =>
                              <>

                                {
                                  innerEle && innerEle.question && innerEle.question.length > 0 ?
                                    innerEle.question.map((ques, index2) =>

                                      <div className="px-4 bg-[#f5f5f5]">
                                        <div className="flex items-center pt-3 pb-2">
                                          <span className="pr-1 text-base italic font-medium">{(index + 1 + index2).toString() + '. '}</span>
                                          <h1 className="text-base italic font-medium">{ques.questionName}</h1>
                                        </div>
                                        {/* <div className="bg-[#2196f3]">
                                          <h1 className="p-2 text-lg text-white">{innerEle.rangeLabel}</h1> 
                                          <h1 className="p-2 text-lg text-white">{ques.score + "%"}</h1>
                                        </div> */}


                                        <div className="text-sm text-right border-r-2 border-white bg-[#E9E9E9]">
                                          <div className="h-6 w-full bg-[#CFD8DC]">
                                            <div
                                              className="w-full flex items-center h-6 bg-[#1E88E5]"
                                              style={{ background: ques.colorScore, width: ques && ques.score ? (parseFloat(ques.score)).toString() + "%" : "0%" }}
                                            >
                                              <span className="px-2 text-xs font-medium text-white">
                                                {ques && ques.score ? ques.score + "%" : "NA"}
                                              </span>
                                            </div>
                                          </div>
                                        </div>




                                        <ul className="flex ">
                                          <li className="py-2 text-sm leading-6">
                                            {ques.questionText}
                                            {/* <span className="p-2 py-1 mx-2 text-sm font-semibold bg-green-100 border rounded-lg">{ques.score + "%"}</span> */}
                                          </li>
                                        </ul>
                                      </div>

                                    ) : null
                                }
                              </>


                            ) : null
                          }
                        </div>
                      </div>
                    ) : null}

                </div>


                {optionType === "item" && dataItem && dataItem.show ? (
                  <>
                    <div className="flex flex-col justify-center gap-6 lg:flex-row">
                      <div className="w-full space-y-4 text-center">
                        <div className="flex text-lg font-medium">
                          {textLabel ? (
                            <h2 className="text-left">
                              {"Low " + textLabel}
                            </h2>
                          ) : null}
                        </div>

                        <div className="">
                          <table className="w-full p-2 border border-white">
                            <thead className="p-2 text-xs bg-red-100 border border-white">
                              <th className="p-2 text-left border border-white">Dimension</th>
                              <th className="p-2 border border-white">Question</th>
                              <th className="p-2 border border-white whitespace-nowrap">Score (%)</th>
                            </thead>
                            <tbody>
                              {dataListAsc && dataListAsc.length > 0
                                ? dataListAsc.map((item, index) =>
                                  index < 5 ? (
                                    <tr className={`text-xs text-gray-800 border-b border-white ${index % 2 === 0 ? 'bg-[#f5f5f5]' : 'bg-[#E9E9E9]'}`}>
                                      <td className="px-2 py-2 font-normal text-left border-r border-white">
                                        {item.para}
                                      </td>
                                      <td className="px-2 py-2 font-normal text-left border-r border-white">
                                        {item.name}
                                      </td>
                                      <td className="px-2 py-2 font-normal border-r border-white">
                                        {item && item.score > 0
                                          ? item.score
                                          : "NA"}
                                      </td>
                                    </tr>
                                  ) : null
                                )
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="w-full space-y-4 text-center">
                        <div className="text-lg font-medium">
                          {textLabel ? (
                            <h2 className="text-left">
                              {"High " + textLabel}
                            </h2>
                          ) : null}
                        </div>

                        <div className="">
                          <table className="w-full p-2 border border-white">
                            <thead className="p-2 text-xs bg-green-100 border border-white">
                              <th className="p-2 text-left border border-white">Dimension</th>
                              <th className="p-2 border border-white">Question</th>
                              <th className="p-2 border border-white whitespace-nowrap">Score (%)</th>
                            </thead>
                            <tbody>
                              {dataListDsc && dataListDsc.length > 0
                                ? dataListDsc.map((item, index) =>
                                  index < 5 ? (
                                    <tr className={`text-xs text-gray-800 border-b border-white ${index % 2 === 0 ? 'bg-[#f5f5f5]' : 'bg-[#E9E9E9]'}`}>
                                      <td className="px-2 py-2 font-normal text-left border-r border-white">
                                        {item.para}
                                      </td>
                                      <td className="px-2 py-2 font-normal text-left border-r border-white">
                                        {item.name}
                                      </td>
                                      <td className="px-2 py-2 font-normal text-center border-r border-white">
                                        {item && item.score > 0
                                          ? item.score
                                          : "NA"}
                                      </td>
                                    </tr>
                                  ) : null
                                )
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}




                {optionType === "itemdetails" && dataItem && dataItem.show ? (
                  <>
                    <div className="p-6 ">
                      <div className="p-2 ">
                        <div className="space-y-6">
                          <div className="text-lg font-medium text-left">
                            {textLabel ? (
                              <div className="text-left">
                                {"Low " + textLabel}
                              </div>
                            ) : null}
                          </div>

                          <div className="p-4">
                            <table className="p-2 border border-2">
                              <thead className="p-2 text-xs bg-red-100 border border-2">
                                <th className="p-2 border border-2">
                                  Dimension
                                </th>
                                <th className="p-2 border border-2">
                                  Question
                                </th>
                                <th className="p-2 border border-2">Overall</th>

                                {dataItem && dataItem.dmgList.length > 0
                                  ? dataItem.dmgList.map((dmg) => (
                                    <th className="p-2 border border-2">
                                      {dmg}
                                    </th>
                                  ))
                                  : null}
                              </thead>
                              <tbody>
                                {dataItem &&
                                  dataItem.questionList &&
                                  dataItem.questionList.length > 0
                                  ? dataItem.questionList.map(
                                    (question, index) => (
                                      <tr
                                        key={index}
                                        className="text-xs text-gray-800 border-b"
                                      >
                                        <td className="px-2 py-2 font-normal border-r">
                                          <div style={{ width: "20%" }}>
                                            {question &&
                                              question.parameterDimensionName
                                              ? GetParameterLabel(
                                                question.parameterDimensionName
                                              )
                                              : ""}
                                          </div>
                                        </td>
                                        <td className="px-2 py-2 font-normal border-r">
                                          <div className="whitespace-pre-line cursor-pointer w-96">
                                            {question.name}
                                          </div>
                                        </td>

                                        <td className="px-2 py-2 font-normal border-r">
                                          <div className="text-center whitespace-pre-line cursor-pointer">
                                            {getOverallScore(question)}
                                          </div>
                                        </td>

                                        {dataItem &&
                                          dataItem.dmgList.length > 0
                                          ? dataItem.dmgList.map((dmg) => (
                                            <td
                                              className="px-2 py-2 font-normal border-r"
                                              style={{
                                                color: showLowScore(
                                                  getOverallScore(question),
                                                  getFavouribilityScore(
                                                    question,
                                                    dmg
                                                  )
                                                )
                                                  ? getColor(
                                                    getOverallScore(
                                                      question
                                                    ),
                                                    getFavouribilityScore(
                                                      question,
                                                      dmg
                                                    )
                                                  ).diffTextColor
                                                  : "f1f1f1",
                                                background: showLowScore(
                                                  getOverallScore(question),
                                                  getFavouribilityScore(
                                                    question,
                                                    dmg
                                                  )
                                                )
                                                  ? getColor(
                                                    getOverallScore(
                                                      question
                                                    ),
                                                    getFavouribilityScore(
                                                      question,
                                                      dmg
                                                    )
                                                  ).Color
                                                  : "f1f1f1",
                                              }}
                                            >
                                              <div className="text-center whitespace-pre-line cursor-pointer">
                                                {showLowScore(
                                                  getOverallScore(question),
                                                  getFavouribilityScore(
                                                    question,
                                                    dmg
                                                  )
                                                )
                                                  ? getFavouribilityScore(
                                                    question,
                                                    dmg
                                                  )
                                                  : "NA"}
                                              </div>
                                            </td>
                                          ))
                                          : null}
                                      </tr>
                                    )
                                  )
                                  : null}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="mt-4 space-y-6">
                          <div className="text-lg font-medium text-left">
                            {textLabel ? (
                              <div className="text-left">
                                {"High " + textLabel}
                              </div>
                            ) : null}
                          </div>

                          <div className="p-4">
                            <table className="p-2 border border-2">
                              <thead className="p-2 text-xs bg-green-100 border border-2 ">
                                <th className="p-2 border border-2 text">
                                  Dimension
                                </th>
                                <th className="p-2 border border-2">
                                  Question
                                </th>
                                <th className="p-2 border border-2">Overall</th>

                                {dataItem && dataItem.dmgList.length > 0
                                  ? dataItem.dmgList.map((dmg) => (
                                    <th className="p-2 border border-2">
                                      {dmg}
                                    </th>
                                  ))
                                  : null}
                              </thead>
                              <tbody>
                                {dataItem &&
                                  dataItem.questionList &&
                                  dataItem.questionList.length > 0
                                  ? dataItem.questionList.map(
                                    (question, index) => (
                                      <tr
                                        key={index}
                                        className="text-xs text-gray-800 border-b"
                                      >
                                        <td className="px-2 py-2 font-normal border-r">
                                          <div style={{ width: "20%" }}>
                                            {question &&
                                              question.parameterDimensionName
                                              ? GetParameterLabel(
                                                question.parameterDimensionName
                                              )
                                              : ""}
                                          </div>
                                        </td>
                                        <td className="px-2 py-2 font-normal border-r">
                                          <div className="whitespace-pre-line cursor-pointer w-96">
                                            {question.name}
                                          </div>
                                        </td>

                                        <td className="px-2 py-2 font-normal border-r">
                                          <div className="text-center whitespace-pre-line cursor-pointer">
                                            {getOverallScore(question)}
                                          </div>
                                        </td>

                                        {dataItem &&
                                          dataItem.dmgList.length > 0
                                          ? dataItem.dmgList.map((dmg) => (
                                            <td
                                              className="px-2 py-2 font-normal border-r"
                                              style={{
                                                color: showHighScore(
                                                  getOverallScore(question),
                                                  getFavouribilityScore(
                                                    question,
                                                    dmg
                                                  )
                                                )
                                                  ? getColor(
                                                    getOverallScore(
                                                      question
                                                    ),
                                                    getFavouribilityScore(
                                                      question,
                                                      dmg
                                                    )
                                                  ).diffTextColor
                                                  : "f1f1f1",
                                                background: showHighScore(
                                                  getOverallScore(question),
                                                  getFavouribilityScore(
                                                    question,
                                                    dmg
                                                  )
                                                )
                                                  ? getColor(
                                                    getOverallScore(
                                                      question
                                                    ),
                                                    getFavouribilityScore(
                                                      question,
                                                      dmg
                                                    )
                                                  ).Color
                                                  : "f1f1f1",
                                              }}
                                            >
                                              <div className="text-center whitespace-pre-line cursor-pointer">
                                                {showHighScore(
                                                  getOverallScore(question),
                                                  getFavouribilityScore(
                                                    question,
                                                    dmg
                                                  )
                                                )
                                                  ? getFavouribilityScore(
                                                    question,
                                                    dmg
                                                  )
                                                  : "NA"}
                                              </div>
                                            </td>
                                          ))
                                          : null}
                                      </tr>
                                    )
                                  )
                                  : null}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(NudgesBehaviourItem);
