import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Summary from './Components/Summary/Summary';
// import YesNoQuestion from './YesNoQuestion/QuestionDetails';
// import RatingScaleQuestion from './RatingScaleQuestion/QuestionDetails';
// import SingleChoice from './SingleChoice/QuestionDetails';
// import MultipleChoice from './MultipleChoice/QuestionDetails';
// import MatrxiQuestion from './MatrixQuestion/QuestionDetails';
import CommonTable from './CommonTable/CommonTable';
import NPSQuestion from './NPSQuestion/QuestionDetails';
import NPSQuestionZeroToTen from './NPSQuestionZeroToTen/QuestionDetails';


class HappinessIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 1
    }
  }
  componentDidMount() {

  }

  handleOthersFilterCall = (item1, item2) => {
    let { handleOthersFilterCall, currentQuestionTemplate } = this.props;
    handleOthersFilterCall(item1, item2, currentQuestionTemplate)
  }

  render() {
    let { getQuestionOthersResultData, getQuestionOthersResultFilterData, getEMPFilterLabel, handleNPSFilterCall,
      EmpFilterData, getIndexFilterData, handleGoBack,

      currentQuestionId, currentQuestionTemplate,

    } = this.props;


    let { getQuestionMatrixResultData, questionsList } = this.props;


    console.log("getIndexFilterData------>", getIndexFilterData)


    let avoidTemplate = [6, 16]

    return (
      <>
        <div className="md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto bg-[#f9fafb]">
          <div className="flex items-center justify-between text-gray-800">
            <div className="flex items-center space-x-4 ">
              {/* <span onClick={() => handleGoBack(currentQuestionTemplate)}
                className="p-2 text-blue-500 bg-blue-100 rounded-full cursor-pointer material-symbols-outlined ">arrow_back</span> */}
              <h1 class="xl:text-2xl text-xl font-medium text-[#3D405B]">Question Analysis</h1>
            </div>
          </div>

          {!avoidTemplate.includes(currentQuestionTemplate) ?
            <CommonTable
              getQuestionOthersResultData={getQuestionOthersResultData}
              getQuestionOthersResultFilterData={getQuestionOthersResultFilterData}
              handleOthersFilterCall={this.handleOthersFilterCall} currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData}
              getIndexFilterData={getIndexFilterData}
              getEMPFilterLabel={getEMPFilterLabel}
              templateId={currentQuestionTemplate}
            />
            : null}

          {currentQuestionTemplate === 6 || currentQuestionTemplate === 16 ?
            <NPSQuestion
              getQuestionOthersResultData={getQuestionOthersResultData}
              getQuestionOthersResultFilterData={getQuestionOthersResultFilterData}
              handleOthersFilterCall={this.handleOthersFilterCall} currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData}
              getIndexFilterData={getIndexFilterData}
              getEMPFilterLabel={getEMPFilterLabel}
              templateId={currentQuestionTemplate}
            />
            : null}





          {/* {currentQuestionTemplate === 1 ?
            <SingleChoice
              getQuestionOthersResultData={getQuestionOthersResultData}
              getQuestionOthersResultFilterData={getQuestionOthersResultFilterData}
              handleOthersFilterCall={this.handleOthersFilterCall} currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData}
              getIndexFilterData={getIndexFilterData}
              getEMPFilterLabel={getEMPFilterLabel}

            />
            : null}



          {currentQuestionTemplate === 2 ?
            <MultipleChoice
              getQuestionOthersResultData={getQuestionOthersResultData}
              getQuestionOthersResultFilterData={getQuestionOthersResultFilterData}
              handleOthersFilterCall={this.handleOthersFilterCall} currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData}
              getIndexFilterData={getIndexFilterData}
              getEMPFilterLabel={getEMPFilterLabel}

            />
            : null}


          {currentQuestionTemplate === 5 ?
            <RatingScaleQuestion
              getQuestionOthersResultData={getQuestionOthersResultData}
              getQuestionOthersResultFilterData={getQuestionOthersResultFilterData}
              handleOthersFilterCall={this.handleOthersFilterCall} currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData}
              getIndexFilterData={getIndexFilterData}
              getEMPFilterLabel={getEMPFilterLabel}

            />
            : null}



        

     



          {currentQuestionTemplate === 11 ?
            <YesNoQuestion
              getQuestionOthersResultData={getQuestionOthersResultData}
              getQuestionOthersResultFilterData={getQuestionOthersResultFilterData}
              handleOthersFilterCall={this.handleOthersFilterCall} currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData}
              getIndexFilterData={getIndexFilterData}
              getEMPFilterLabel={getEMPFilterLabel}

            />
            : null}


          {currentQuestionTemplate === 13 ?
            <MatrxiQuestion
              getQuestionMatrixResultData={getQuestionMatrixResultData}
              questionsList={questionsList}
              handleOthersFilterCall={this.handleOthersFilterCall}
              currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData}
              getIndexFilterData={getIndexFilterData}
              getEMPFilterLabel={getEMPFilterLabel}

            />
            : null} */}

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HappinessIndex);
