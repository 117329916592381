
function spliceDecimal(no,upto){
    if(no){
      let str = no.toString();
      let first = str.split('.')[0];
      let second = str.split('.')[1];
      let second2 = second?second.substring(0, upto):""
      let final = first+'.'+second2;
      if(second2){
        return parseFloat(final).toFixed(upto)
      }else{
        return no
      }
    }else{
      return 0
    }
  }

export { spliceDecimal }
