import React, { Component } from 'react';
import { connect } from 'react-redux';
import StackChart from './Charts/StackChart';

import Commentary from '../../../Components/Commentary/Commentary';

import SummaryScore from './Cards/SummaryScore/SummaryScore';

import IndexKPI from '../../../../../CommonComponents/IndexKPI';
import Filter from '../../../../../CommonComponents/Filters/IndexFilter/Filter';
import { CSVLink } from "react-csv";
import EDAModal from '../../../Components/EDA/EDAModal';
import { spliceDecimal } from "../../../../../FunctionLibrary/FuntionList";

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      showLeaderBoard: false,
      isMultifilter: false,
      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      ListName: 'Demographic',
      showEDAModal: false,
      crrDMGValue: ""

    }
  }
  componentDidMount() {

  }

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultifilterIndexScore } = this.props;
    this.setState({ FilterValues: finalFilter, ListName: name });
    handleMultifilterIndexScore(finalFilter, true, name);

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }


  handleSelectEDA = (data) => {
    ////////console.log("data--------->",data);
    let { finalFilter, ListName } = this.state;
    let dmgValue = data && data.name ? data.name : "";
    let { getEDAdata } = this.props;
    if (dmgValue) {
      getEDAdata(dmgValue, 2, finalFilter, ListName);
      setTimeout(() => {
        this.setState({ showEDAModal: true, crrDMGValue: dmgValue });
      }, 500)
    }
  }

  closeEDAModal = () => {
    this.setState({ showEDAModal: false, crrDMGValue: "" });
  }

  render() {
    let { ErrorIndexObj, getDemographicIndexMultiAxisFilterData,

      indexType, optionType, getCommentData, loading, name, CountRestrict, getEMPFilterLabel, getDMGIndexManyFilterData,

      EmpFilterData, getIndexFilterData, getOverallIndexData, isCrole,
      getAllBenchMarkData, getRespondantDetailsData,
      getIndexData, getIndexScore, getIndexDistributionData, cronbatchAlphaVarianceData, getDemographicIndexMultiFilterData, getIndexByFilterDMGData
    } = this.props;
    let { currFilterName, currentSelectValue, FilterValues, ListName } = this.state;



    let cronBachAlpha = {
      score: 0
    }
    // if (cronbatchAlphaVarianceData && cronbatchAlphaVarianceData.score && cronbatchAlphaVarianceData.score.length > 0) {
    //   cronBachAlpha["score"] = (typeof cronbatchAlphaVarianceData.score[0] !== 'string' || cronbatchAlphaVarianceData.score[0] instanceof Number) && cronbatchAlphaVarianceData && cronbatchAlphaVarianceData.score && 
    //   cronbatchAlphaVarianceData.score[0] ? spliceDecimal(cronbatchAlphaVarianceData.score[0],2) : ''
    // }
    if (
      cronbatchAlphaVarianceData &&
      cronbatchAlphaVarianceData.score &&
      cronbatchAlphaVarianceData.score > 0
    ) {
      cronBachAlpha["score"] = spliceDecimal(cronbatchAlphaVarianceData.score, 2);
    }


    let indexOverallValue = getOverallIndexData && getOverallIndexData[indexType] && parseFloat(spliceDecimal(getOverallIndexData[indexType], 2)) ? parseFloat(spliceDecimal(getOverallIndexData[indexType], 2)) : 0;

    let Item = {
      score: 0
    };
    if (getIndexFilterData) {
      Item = {
        score: (typeof getIndexFilterData[indexType] !== 'string' || getIndexFilterData[indexType] instanceof Number) && getIndexFilterData && getIndexFilterData[indexType] ? spliceDecimal(getIndexFilterData[indexType], 2) : ''
      }
    }

    let Benchmark = {
      score: 0
    };
    if (getAllBenchMarkData) {
      Benchmark = {
        score: (typeof getAllBenchMarkData[indexType] !== 'string' || getAllBenchMarkData[indexType] instanceof Number) && getAllBenchMarkData && getAllBenchMarkData[indexType] ? spliceDecimal(getAllBenchMarkData[indexType], 2) : ''
      }
    }


    ////console.log('FilterValues: ', FilterValues)

    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    let DemographicDataScore = [];
    let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      DemographicDataScore = getDemographicIndexMultiFilterData;
      DemographicDataDistribution = getIndexDistributionData;

    }


    let ScoreExcel = []
    let filter = FilterValues && FilterValues.length > 0 ? " (" + FilterValues.map((element) => { return element.value }).join(",") + ")" : " (Filter)"

    if (getIndexScore) {

      let Overall = {}
      Overall[ListName + filter] = "Overall";
      Overall["Scores"] = getIndexScore && getIndexScore[indexType] ? spliceDecimal(getIndexScore[indexType], 2) : "NaN";


      ScoreExcel.push(Overall);

    }

    if (DemographicDataScore && DemographicDataScore.length > 0) {
      DemographicDataScore.forEach((data) => {
        let dataScore = CountRestrict(data.count) ? "NaN" : spliceDecimal(data[indexType], 2);

        let tempData = {}
        if (data && data.name) {
          tempData[ListName + filter] = data.name;
          tempData["Scores"] = dataScore ? dataScore.toString() : "NaN";


        }

        ScoreExcel.push(tempData);


      })
    }

    ////////console.log("------IndexSummaryScoreExcel: ScoreExcel-----------",ScoreExcel)



        //---------------------------------------------------------------------------------------
        let tempSortData = []
        if (DemographicDataScore && DemographicDataScore.length > 0) {
          DemographicDataScore.forEach((data) => {
            tempSortData.push(data)
          })
        }
        tempSortData.sort((b,a) => a[indexType] - b[indexType]);

        //---------------------------------------------------------------------------------------
    
    



    return (
      <>

        {/* end */}
        {/*sub nav bar 2 */}
        <div className="flex flex-col justify-between gap-4 md:items-center md:flex-row">


          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />

          {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
          <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

          {FilterValues && FilterValues.length > 0 ?
            <>
              {ScoreExcel ?
                <div className="cursor-pointer bg-blue-500 border flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5">
                  <CSVLink data={ScoreExcel} filename={"LeadershipIndexScoreAndDistribution.csv"}>
                    <span className="w-24 h-6 text-xs text-center text-white ">Download Excel</span>
                  </CSVLink>
                </div>
                : '...'}
            </>
            : null}


        </div>


        {/* <div className="items-center justify-start xl:flex lg:flex md:flex"> */}
        {/* 
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}

        {/* </div> */}
        {/* 
        {FilterValues && FilterValues.length > 0 ?
          <div className="items-center justify-between h-10 px-4 py-3 bg-white border-b xl:flex lg:flex md:flex">
            <>
            {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
              <p className="flex mx-2 my-4 text-sm"><p className="mr-1 text-blue-500">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}


              {FilterValues && FilterValues.length > 0 ?
                <>
                {ScoreExcel ?
                <CSVLink data={ScoreExcel} filename={"IndexScore.csv"}>
                <p className="text-sm font-normal text-purple-700 cursor-pointer hover:text-purple-500">
                  {"Download Excel"}
                </p>                
                </CSVLink>
                : '...'}
                </>
                :null}


              
            </>
            </div>
          : null} */}


        <div onClick={() => this.closeFilter(false)} className="" >
          {/* <div className="flex w-full" >



            <div className="justify-center w-full px-4 py-4 m-4 bg-white rounded shadow-lg">
              <div className="text-lg font-medium">{name} Score</div> */}

          <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">

            <div className="p-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">

              <div className="flex items-center justify-between">
                <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">
                  {name} Score
                </h1>
                <h1 className="font-medium xl:text-base text-sm text-[#3D405B]/50 whitespace-nowrap  file: ">
                  Cron Batch Alpha: {cronBachAlpha.score}
                </h1>
              </div>

              {ErrorIndexObj && ErrorIndexObj["getIndexV2Error"] ?
                <>
                  <div className='my-24 text-2xl font-medium text-center text-gray-400'>{ErrorIndexObj["getIndexV2Error"]}</div>
                </>
                :
                <>
                  {FilterValues && FilterValues.length > 0 ?
                    <>
                      {ErrorIndexObj && ErrorIndexObj["getDemographicIndexMultiFilterError"] ?
                        <>
                          <div className='my-24 text-2xl font-medium text-center text-gray-400'>{ErrorIndexObj["getDemographicIndexMultiFilterError"]}</div>
                        </>
                        :
                        <div className="py-4">
                          <StackChart
                            getIndexScore={getIndexScore}
                            tempSortData={tempSortData}
                            currFilterName={currFilterName}
                            indexType={indexType}
                            CountRestrict={CountRestrict}
                            handleSelectEDA={this.handleSelectEDA}
                            ErrorIndexObj={ErrorIndexObj}
                          />
                          <div className="py-4 text-" style={{ color: "#3366ff" }}>* We are not showing data where employee count is less than 5</div>
                        </div>
                      }
                    </>




                    :
                    <SummaryScore name={name} isCrole={isCrole} indexOverallValue={indexOverallValue} cronBachAlpha={cronBachAlpha} item={Item} DistributionData={getIndexData} Benchmark={Benchmark} />

                  }
                </>}
            </div>


            {/* <div className="justify-center w-5/12 px-4 bg-white rounded shadow-lg">
                <div className="text-center">Happiness Distribution</div>
                {FilterValues && FilterValues.length>0?
                       <div className="py-4">
                         <div className="flex">{FilterValues && FilterValues.length>0?FilterValues.map((element)=>
                          <p className="flex mx-4 my-4 font-semibold"><p className="text-blue-500">{element.level}</p>{"  >>  "}<p className="text-gray-500">{element.value}</p></p>
                         ):null}</div>

                                    <StackChartDistribution 
                                      getIndexData={getIndexData}
                                      getIndexDistributionData={DemographicDataDistribution}
                                      currFilterName={currFilterName}
                                      indexType={indexType}
                                    />
                      </div>
                      :
                        <div className="py-4">
                          <SummaryDistribution cronBachAlpha={cronBachAlpha}  item={Item} DistributionData={getIndexData} Benchmark={Benchmark}/>
                        </div>
                      }
                </div> */}

          </div>
        </div>

        {FilterValues && FilterValues.length > 0 ?
          null :
          <IndexKPI
            indexType={indexType}
            getIndexData={getIndexData}
            getIndexScore={getIndexScore}
            getAllBenchMarkData={getAllBenchMarkData}
            getRespondantDetailsData={getRespondantDetailsData}
          />
        }




        <EDAModal
          showModal={this.state.showEDAModal}
          closeModal={this.closeEDAModal}
          getDMGIndexManyFilterData={getDemographicIndexMultiAxisFilterData}
          indexType={indexType}
          dmgLevel={this.state.ListName}
          dmgValue={this.state.crrDMGValue}

        />



      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
