import React, { Component } from "react";
import { connect } from "react-redux";
import {
  customerActions,
  dashboardActions,
  surveyActions,
} from "../../_actions";
import Header from '../SurveyMgmt/Header/Header';
import Sidebar from '../Overview/Sidebar/SidebarOverview';
import { accessModule } from "../../_helpers";

import { userActions } from "../../_actions";
import LoadingOverlay from "react-loading-overlay";
import { SheetJSFT } from "./types";
import * as XLSX from "xlsx";
import { make_cols } from "./MakeColumns";
import AddFilter from "./Modal/AddFilter";
import AllFilters from "./Modal/AllFilters";
import UploadPreview from "./Modal/UploadPreview";
import moment from "moment";
import ReactPaginate from 'react-paginate';

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveysize: 10000,
      fileUploadError: "",
      showSurveyList: true,
      showAddNewSurvey: false,
      showEditSurvey: false,
      confirmDeleteSurvey: false,
      CurrentSurveyId: "",
      CurrentSurveyName: "",
      showChooseSurveyModal: false,
      showSurveyLibrary: false,
      isPackageSelected: false,
      CurrentSurveyData: "",
      showQuestionLibrary: "",
      searchCustomer: "",

      showCustomerModal: false,

      currentSurveyPage: 1,

      savePackage: "",
      packageList: [],
      surveyValidation: {
        name: true,
        desc: true,
      },

      surveyFormData: {
        name: "",
        desc: "",
      },

      showSidebar: true,

      CustIndexA: 0,
      CustIndexB: 10,
      customersize2: 10,

      offset: 0,
      page: 0,
      size: 10,

      showCreateSurveyPopup: false,

      CurrentLanguage: "en",
      CurrentLanguageName: "English",

      showLanguageModal: false,

      openCustFilterModal: false,
      openCustFilterModal2: false,

      formDataFilter: {
        name: "",
        label: "",
        rank: 1,
        isDisable: false,
      },
      updateformDataFilter: {
        id: "",
        name: "",
        label: "",
        rank: 1,
      },

      showFilterNames: false,
      customerData: "",
      showEditDetails: false,

      customerData: "",
      tcustCustData: "",
      CrrCustFilterLabel: "",
      showInputBox: false,

      crrFieldType: [],

      loadedDataSheet: [],
    };
  }
  componentDidMount() {
    let data = {
      keyWord: "",
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      pageNo: 1,
      size: this.state.surveysize,
    };

    // this.props.dispatch(surveyActions.getSurveyList(data));
    this.props.dispatch(dashboardActions.getClientProfile());
    this.props.dispatch(dashboardActions.getCompanyNameOnly());

    this.props.dispatch(customerActions.getCustomerFilterListByCompanyId());

    let datatcustcustomer = {
      keyWord: "",
      pageNo: 1,
      // "companyId": this.props.match.params.id,
      size: this.state.customersize2,
    };

    this.props.dispatch(customerActions.getCustomerList(datatcustcustomer));

    let data2 = {
      keyWord: "",
      pageNo: 1,
      size: 100,
    };

    this.props.dispatch(surveyActions.getLanguageLabel(data2));
    this.props.dispatch(surveyActions.getLanguage(data2));
  }

  // handleChange = (e) => {
  //   const files = e.target.files;

  //   if (files && files[0]) {
  //     let fileName = files && files[0] && files[0].name ? files[0].name : "";
  //     let extName1 = fileName && fileName.substr(fileName.length - 3) ? fileName.substr(fileName.length - 3) : "";
  //     let extName2 = fileName && fileName.substr(fileName.length - 4) ? fileName.substr(fileName.length - 4) : "";
  //     if ((extName1 === "csv") || (extName2 === "xlsx")) {

  //       this.setState({ file: files[0] }, () => {
  //         this.setState({ fileUploadError: "" });
  //       });

  //     }
  //     else {
  //       this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
  //     }

  //   }
  //   else {
  //     this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
  //   }

  // };

  handleChange = (e) => {
    this.setState({ loadedDataSheet: [], showUploadPreview: true }, () => {
      const files = e.target.files;
      // ////console.log("files; ",files)

      if (files && files[0]) {
        let fileName = files && files[0] && files[0].name ? files[0].name : "";
        let extName1 =
          fileName && fileName.substr(fileName.length - 3)
            ? fileName.substr(fileName.length - 3)
            : "";
        let extName2 =
          fileName && fileName.substr(fileName.length - 4)
            ? fileName.substr(fileName.length - 4)
            : "";
        if (extName1 === "csv" || extName2 === "xlsx") {
          this.setState({ file: files[0] }, () => {
            console.log("this.state.file", this.state.file);
            this.setState({ fileUploadError: "" });

            //----------------------------------------------------------------------------------------------------------------------------------------------------------
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;

            if (this.state.file) {
              if (rABS) {
                reader.readAsBinaryString(this.state.file);
              } else {
                reader.readAsArrayBuffer(this.state.file);
              }
            }

            console.log(
              "rABS--------------------------------------------->",
              rABS
            );

            reader.onload = (e) => {
              /* Parse data */
              const bstr = e.target.result;
              const wb = XLSX.read(bstr, {
                type: rABS ? "binary" : "array",
                bookVBA: true,
              });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              console.log(
                "before ws--------------------------------------------->",
                ws
              );

              //-------------------------------------------------------------------------------------------------------------
              // var isDate = function (date) {
              //   return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
              // }
              let listKeys = ws ? Object.keys(ws) : [];
              if (listKeys && listKeys.length > 0) {
                listKeys.forEach((element) => {
                  if (ws[element]["t"] === "n") {
                    let wData = ws[element]["w"];
                    const toDateFormat = wData.toString();

                    if (moment(toDateFormat, "MM/DD/YY", true).isValid()) {
                      let wNewDate = moment(wData, "MM/DD/YY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        // console.log("wData--------->",ws[element])
                      }
                    } else if (
                      moment(toDateFormat, "MM/DD/YYYY", true).isValid()
                    ) {
                      let wNewDate = moment(wData, "MM/DD/YYYY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        // console.log("wData--------->",ws[element])
                      }
                    } else if (
                      moment(toDateFormat, "M/DD/YY", true).isValid()
                    ) {
                      let wNewDate = moment(wData, "M/DD/YY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        console.log("wData--------->", ws[element]);
                      }
                    } else if (
                      moment(toDateFormat, "MM/D/YY", true).isValid()
                    ) {
                      let wNewDate = moment(wData, "MM/D/YY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        console.log("wData--------->", ws[element]);
                      }
                    } else if (moment(toDateFormat, "M/D/YY", true).isValid()) {
                      let wNewDate = moment(wData, "M/D/YY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        console.log("wData--------->", ws[element]);
                      }
                    } else if (
                      moment(toDateFormat, "M/DD/YYYY", true).isValid()
                    ) {
                      let wNewDate = moment(wData, "M/DD/YYYY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        console.log("wData--------->", ws[element]);
                      }
                    } else if (
                      moment(toDateFormat, "MM/D/YYYY", true).isValid()
                    ) {
                      let wNewDate = moment(wData, "MM/D/YYYY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        console.log("wData--------->", ws[element]);
                      }
                    } else if (
                      moment(toDateFormat, "M/D/YYYY", true).isValid()
                    ) {
                      let wNewDate = moment(wData, "M/D/YYYY").format(
                        "DD-MM-YYYY"
                      );
                      if (wNewDate) {
                        ws[element]["v"] = wNewDate;
                        ws[element]["w"] = wNewDate;
                        console.log("wData--------->", ws[element]);
                      }
                    }

                    // const dateFormat = ['MM/DD/YY', 'MM/DD/YYYY'];
                    // if (moment(toDateFormat, dateFormat, true).isValid()) {
                    //   // console.log('toDateFormat ws--------------------------------------------->', toDateFormat)
                    //   // console.log('wData ws--------------------------------------------->', wData)
                    //   // console.log('moment ws--------------------------------------------->', moment(toDateFormat, dateFormat, true).isValid())

                    //   let wNewDate = moment(wData, "MM/DD/YYYY").format("DD-MM-YYYY");
                    //   if (wNewDate) {
                    //     ws[element]["v"] = wNewDate
                    //     ws[element]["w"] = wNewDate
                    //   }

                    //   // console.log('valid2 ws--------------------------------------------->', ws[element]["v"])
                    //   // console.log('valid2 element--------------------------------------------->', ws[element])

                    // }
                  }
                });
              }

              console.log(
                "after ws--------------------------------------------->",
                ws
              );
              //-------------------------------------------------------------------------------------------------------------

              /* Convert array of arrays */
              let data = XLSX.utils.sheet_to_json(ws);
              // ////console.log("datadatadatadata ", data);

              // moment("06/22/2015", "MM/DD/YYYY", true).isValid()

              data = data.map((v) => ({ ...v }));

              console.log(
                "data--------------------------------------------->",
                data
              );

              setTimeout(() => {
                this.setState({
                  loadedDataSheet: data,
                  showUploadPreview: true,
                  showCustomerModal: false,
                });
              }, 1000);
            };

            //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
          });
        } else {
          this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
          ////console.log("not csv or xlsx file")
        }
      } else {
        this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
      }
    });
  };

  handleFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws);
      // //////console.log("datadatadatadata ", data);
      data = data.map((v) => ({ ...v, companyId: this.props.match.params.id }));

      let flag = 0;
      let locList = [];
      if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let value = validateEmail(data[i].email);
          //////console.log("emails:x:",data[i].email);

          if (value) {
            //all valid
          } else {
            flag = flag + 1;
            locList.push(i + 1);
          }
        }
      }
      function validateEmail(email) {
        const re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      if (flag) {
        this.setState({ isNotMail: true, isNotMailPos: locList });
      } else {
        /* Update state */
        let datatcust0 = {
          keyWord: this.state.keyWord,
          pageNo: 1,
          size: this.state.customersize,
        };
        let Tcust1 = {
          keyWord: "",
          pageNo: 1,
          size: 6,
        };
        this.setState({ data: data, cols: make_cols(ws["!ref"]) }, () => {
          // this.props.dispatch(customerActions.saveCustomerList(this.state.data, Tcust1));
          this.setState({
            isNotMail: false,
            isNotMailPos: [],
            showCustomerModal: false,
          });
        });
      }
    };
    if (this.state.file) {
      if (rABS) {
        reader.readAsBinaryString(this.state.file);
      } else {
        reader.readAsArrayBuffer(this.state.file);
      }
    }
  };

  onDisableCustomer = (data) => {
    //////console.log("data Customer: ", data);
    let tcustdata = {
      id: data.id.toString(),
      keyWord: this.state.keyWord,
      pageNo: this.state.customerpage,
      size: this.state.size,
    };

    //////console.log("asdf :: ", tcustdata);
    this.props.dispatch(customerActions.updateCustomerStatus(tcustdata));
  };

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;

    this.setState({ searchCustomer: value });
    let { searchCustomer } = this.state;

    this.setState({ keyWord: searchCustomer, offset: 0 });

    let data = {
      keyWord: "",
      pageNo: 1,
      companyId: this.props.match.params.id,
      size: this.state.customersize2,
    };
    if (searchCustomer) {
      data = {
        keyWord: searchCustomer,
        pageNo: 1,
        companyId: this.props.match.params.id,
        size: this.state.size,
      };
    }

    this.setState({ customerData: "" });

    this.props.dispatch(customerActions.getCustomerList(data));

  };

  handleSearchCustomer = (event) => {
    event.preventDefault();
    let { value } = event.target;

    this.setState({ searchEmployee: value }, () => {
      let data = {
        "keyWord": value,
        "pageNo": 1,
        "size": this.state.usersize2
      }
      this.props.dispatch(customerActions.getCustomerList(data));
    })

  }

  // handleSearchAPI = (event) => {
  //   event.preventDefault();
  //   let { searchCustomer } = this.state;

  //   this.setState({ keyWord: searchCustomer, offset: 0 });

  //   let data = {
  //     keyWord: "",
  //     pageNo: 1,
  //     companyId: this.props.match.params.id,
  //     size: this.state.customersize2,
  //   };
  //   if (searchCustomer) {
  //     data = {
  //       keyWord: searchCustomer,
  //       pageNo: 1,
  //       companyId: this.props.match.params.id,
  //       size: this.state.size,
  //     };
  //   }

  //   this.setState({ customerData: "" });

  //   this.props.dispatch(customerActions.getCustomerList(data));
  // };

  handleADDpackage = (survey) => {
    this.setState({ showQuestionLibrary: true });

    let { packageList } = this.state;
    if (packageList.findIndex((prev) => prev === survey.id) === -1) {
      packageList.push(survey.id);
      this.setState({ isPackageSelected: false });
    } else {
      let getIndex = packageList.findIndex((prev) => prev === survey.id);
      packageList.splice(getIndex, 1);
      this.setState({ isPackageSelected: true });
    }
    this.setState({ packageList });
  };

  onSurveyDelete = () => {
    let data = {
      id: this.state.CurrentSurveyId,
    };
    let datatcustsurvey = {
      keyWord: "",
      pageNo: 1,
      size: this.state.surveysize,
    };
    this.props.dispatch(surveyActions.deleteSurvey(data, datatcustsurvey));
    this.setState({
      confirmDeleteSurvey: false,
      CurrentSurveyId: "",
      CurrentSurveyName: "",
    });
  };

  onEditSurvey = (data) => {
    this.props.history.push(`/app/question/${data.id}`);
  };

  onPreviewSurvey = () => { };

  onReportSurvey = (data) => {
    this.props.history.push(`/app/surveyreport/${data.id}`);
  };

  onPublishSurvey = (data) => {
    this.props.history.push(`/app/publish/${data.id}`);
  };

  handleChooseSurveyPopup = () => {
    this.setState({
      showCreateSurveyPopup: false,
      showChooseSurveyModal: true,
    });
  };

  handleCreateSurveyPopup = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showCreateSurveyPopup: true });
  };

  handleCreateSurveyFromLibrary = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showSurveyLibrary: true });

    this.props.dispatch(surveyActions.getSurveyFromLib());
  };

  handleSurveyLibraryClose = (check) => {
    if (check) {
      this.setState({ CurrentSurveyData: false });
    } else {
      this.setState({ showSurveyLibrary: false });
    }
  };

  QuestionLibraryClose = () => {
    this.setState({
      CurrentSurveyData: false,
      savePackage: "",
      showQuestionLibrary: false,
    });
  };

  handleCreateSurveyPopupClose = () => {
    this.handleChooseSurveyPopupClose();

    let validate = {
      name: true,
      desc: true,
    };
    let tcustData = {
      name: "",
      desc: "",
    };
    this.setState({
      showCreateSurveyPopup: false,
      surveyValidation: validate,
      surveyFormData: tcustData,
    });
  };

  handleChooseSurveyPopupClose = () => {
    this.setState({ showChooseSurveyModal: false });
  };

  onLogout = () => {
    //console.log('logout')
    this.props.dispatch(userActions.logout());
  };

  goToDashboard = () => {
    this.props.history.push("/app/dashboard");
  };

  goToGeneralDashboard = () => {
    let SurveyId = "5fa04d316ede0f0336066cce";

    this.props.history.push("/app/generaldashboard/" + SurveyId);
  };

  onDashboard = (survey) => {
    //console.log('survey: ',survey);

    // let SurveyId = '5fa04d316ede0f0336066cce';

    if (survey && survey.tcustlateId === 1) {
      this.props.history.push("/app/dashboard/" + survey.id);
    } else if (survey && survey.tcustlateId === 2) {
      this.props.history.push("/app/dashboard/" + survey.id);
    } else if (survey && survey.tcustlateId === 3) {
      this.props.history.push("/app/generaldashboard/" + survey.id);
    }
  };

  handleSurveyLibQuestions = (survey) => {
    this.setState({ CurrentSurveyData: survey });
  };

  handleUseSurveyPage = () => {
    let { CurrentSurveyData } = this.state;
    //console.log('CurrentSurveyData: ',CurrentSurveyData)

    let data = {
      surveyId: "605993ff4e91a52e9f001f80",
      surveyLib: ["60598a67ade90e2a7fbf2830", "6058a75c6ba424628f903463"],
    };
  };
  onLogout = () => {
    //console.log('logout')
    this.props.dispatch(userActions.logout());
  };
  handleSuveyPackageSubmit = () => {
    let { SurveySubmitData, packageList } = this.state;

    let resData = {
      name: SurveySubmitData.name,
      desc: SurveySubmitData.desc,
      tcustlateId: 2,
    };
    let datatcustsurvey = {
      keyWord: "",
      pageNo: 1,
      size: this.state.surveysize,
    };

    this.props.dispatch(
      surveyActions.createSurvey(resData, datatcustsurvey, false, packageList)
    );

    let validate = {
      name: true,
      desc: true,
    };
    let tcustData = {
      name: "",
      desc: "",
    };
    this.setState({
      showSurveyLibrary: false,
      showCreateSurveyPopup: false,
      showChooseSurveyModal: false,
      surveyValidation: validate,
      surveyFormData: tcustData,
    });
  };

  addCustomerModal = (value) => {
    this.setState({ showCustomerModal: value });
    this.setState({ fileUploadError: "" });
  };

  prevCustPage = () => {
    let { CustIndexA, CustIndexB } = this.state;
    if (CustIndexA > 0) {
      this.setState({ CustIndexA: CustIndexA - 1, CustIndexB: CustIndexB - 1 });
    }
  };
  nextCustPage = (IndexListIndex) => {
    let { CustIndexA, CustIndexB } = this.state;
    if (
      IndexListIndex &&
      IndexListIndex.length &&
      CustIndexB < IndexListIndex.length
    ) {
      this.setState({ CustIndexA: CustIndexA + 1, CustIndexB: CustIndexB + 1 });
    }
  };

  SelectCustPage = (val, IndexListIndex) => {
    if (
      val > 0 &&
      val <
      (IndexListIndex && IndexListIndex.length > 0
        ? IndexListIndex.length
        : 0)
    ) {
      this.setState({ currentSurveyPage: val });
      let datatcustcustomer = {
        keyWord: "",
        pageNo: val,
        companyId: this.props.match.params.id,
        size: this.state.customersize2,
      };

      this.props.dispatch(customerActions.getCustomerList(datatcustcustomer));
    }
  };

  DownloadCustomers = (customersItem, isFilter) => {
    let list = [];
    let keyList = [];
    let keyNewList = [];

    if (customersItem && customersItem.length > 0 && customersItem[0]) {
      keyList = Object.keys(customersItem[0]);
    }

    if (keyList && keyList.length > 0) {
      keyList.forEach((key, index) => {
        if (
          key != "id" &&
          // && key != 'role'
          key != "companyId" &&
          key != "currentStage" &&
          key != "isDisable" &&
          key != "createdAt"
        ) {
          keyNewList.push(key);
        }
      });
    }

    if (customersItem && customersItem.length > 0) {
      customersItem.forEach((cust, index) => {
        let data = {};

        if (keyNewList && keyNewList.length > 0) {
          keyNewList.forEach((keY, indey) => {
            data[keY] = cust[keY];
          });
        }

        list.push(data);
      });

      let finalList = [];
      if (isFilter) {
        let listFiltered = [];
        let { getCustomerFilterListByCompanyIdData } = this.props.customer;

        //console.log('asdad: ',getCustomerFilterListByCompanyIdData)

        if (list && list.length > 0) {
          list.forEach((cust) => {
            let tcustObj = {};
            tcustObj["email"] = cust["email"];
            tcustObj["cust_id"] = cust["cust_id"];
            if (
              getCustomerFilterListByCompanyIdData &&
              getCustomerFilterListByCompanyIdData.length > 0
            ) {
              getCustomerFilterListByCompanyIdData.forEach((filter) => {
                if (!filter.isDisable) {
                  tcustObj[filter.name] = cust[filter.name];
                }
              });
            }
            finalList.push(tcustObj);
          });
        }
      } else {
        finalList = list;
      }

      let binary_univers = finalList;
      //console.log('binary_univers: ',binary_univers)

      let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

      // Create a new Workbook
      var wb = XLSX.utils.book_new();

      // Name your sheet
      XLSX.utils.book_append_sheet(wb, binaryWS, "Binary values");

      // export your excel
      XLSX.writeFile(wb, "customers.xlsx");
    }
  };

  handleViewDetails = (cust) => {
    this.setState({ customerData: cust });
  };

  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });

    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },4000);
  };

  routeLink = (link) => {
    this.props.history.push("/app/" + link);
    this.setState({ CrrRoute: link });
  };

  handleCustInput = (e) => {
    let { customerData } = this.state;
    customerData[e.target.name] = e.target.value;
    this.setState({ customerData });
  };

  handleLanguage = (item) => {
    this.setState({
      CurrentLanguage: item.shortName,
      CurrentLanguageName: item.name,
      showLanguageModal: false,
    });
  };

  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  };

  onCustomerFilter = (getCustomerListData) => {
    let { openCustFilterModal } = this.state;
    this.props.dispatch(customerActions.getCustomerColumnName());
    this.setState({
      openCustFilterModal: !openCustFilterModal,
      openCustFilterModal2: false,
    });
  };

  onAddFilter = (getCustomerListData) => {
    let { openCustFilterModal2 } = this.state;
    this.props.dispatch(customerActions.getCustomerColumnName());
    this.setState({
      openCustFilterModal2: !openCustFilterModal2,
      openCustFilterModal: false,
    });
  };

  closeCustomerFilter = (check) => {
    this.setState({ openCustFilterModal: check });
  };

  closeCustomerFilter2 = (check) => {
    this.setState({ openCustFilterModal2: check });
  };

  handleFilterInput = (e) => {
    let { formDataFilter } = this.state;
    formDataFilter["label"] = e.target.value;
    this.setState({ formDataFilter });
  };
  handleFilterRank = (e) => {
    let { formDataFilter } = this.state;
    formDataFilter["rank"] = e.target.value;
    this.setState({ formDataFilter });
  };
  updatehandleFilterRank = (value, cust) => {
    // let { updateformDataFilter } = this.state;
    // updateformDataFilter["rank"] = value;
    // this.setState({updateformDataFilter});
    this.props.dispatch(
      customerActions.updateCustomerFilterRank({ id: cust.id, rank: value })
    );
  };

  onCustomerFilterDropdown = (check) => {
    this.setState({ showFilterNames: check });
  };

  handleCustomerColumnSelect = (name) => {
    let { formDataFilter } = this.state;
    formDataFilter["name"] = name;
    formDataFilter["label"] = name;
    this.setState({ formDataFilter, showFilterNames: false });
  };

  handleFilterStatus = (value) => {
    //////console.log("handleFilterStatus::", value);
    this.props.dispatch(
      customerActions.updateCustomerFilterStatus({ id: value })
    );
  };

  handleShowReportStatus = (value) => {
    //////console.log("handleShowReportStatus::", value);
    this.props.dispatch(
      customerActions.updateCustomerShowReportStatus({ id: value })
    );
  };

  handleIsEngCompStatus = (value) => {
    //////console.log("handleShowReportStatus::", value);
    // this.props.dispatch(surveyActions.updateCustomerEngagementComparisonStatus({ id: value }));
  };

  handleFilterCheck = () => {
    let { formDataFilter } = this.state;
    if (formDataFilter.isDisable) {
      formDataFilter["isDisable"] = false;
    } else {
      formDataFilter["isDisable"] = true;
    }
    this.setState({ formDataFilter });
  };

  createCustomerFilter = (NameItem) => {
    // let { formDataFilter } = this.state;

    let { getCustomerFilterListByCompanyIdData } = this.props.customer;
    let Rank =
      getCustomerFilterListByCompanyIdData &&
        getCustomerFilterListByCompanyIdData.length > 0
        ? getCustomerFilterListByCompanyIdData.length + 1
        : 1;
    // //console.log('Rank: ',Rank)

    if (NameItem) {
      let tcustData = {
        name: NameItem,
        label: NameItem,
        rank: Rank,
        isDisable: false,
      };
      this.props.dispatch(customerActions.createCustomerFilter(tcustData));
    }

    // let tcustClear ={
    //   "name": "",
    //   "label": "",
    //   "isDisable": false
    // }
    // this.setState({formDataFilter:tcustClear});

    this.setState({ openCustFilterModal2: false });
  };

  onClickQuesUp = (cust1, index) => {
    let { customer } = this.props;
    let { getCustomerFilterListByCompanyIdData } = customer;
    // //////console.log("working:onClickUp: ",cust1,index,'==>',surveyId)

    let cust2 = "";
    if (
      getCustomerFilterListByCompanyIdData &&
      getCustomerFilterListByCompanyIdData.length > 0 &&
      index
    ) {
      cust2 = getCustomerFilterListByCompanyIdData[index - 1].id;
    }

    if (cust1 && cust2) {
      let data = {
        idA: cust1,
        idB: cust2,
      };
      //console.log('data idA, IdB: ', data)
      this.props.dispatch(customerActions.updateCustomerFilterRank(data));
    }
  };

  onClickQuesDown = (cust1, index) => {
    let { customer } = this.props;
    let { getCustomerFilterListByCompanyIdData } = customer;

    let cust2 = "";
    if (
      getCustomerFilterListByCompanyIdData &&
      getCustomerFilterListByCompanyIdData.length > 0 &&
      index !== getCustomerFilterListByCompanyIdData.length - 1
    ) {
      cust2 = getCustomerFilterListByCompanyIdData[index + 1].id;
    }

    if (cust1 && cust2) {
      let data = {
        idA: cust1,
        idB: cust2,
      };
      //console.log('data idA, IdB: ', data)

      this.props.dispatch(customerActions.updateCustomerFilterRank(data));
    }
  };

  downLoadSampleCSV = () => {
    let binary_universObj = {};
    //-------------------------------------------------------------------------------------------------
    let { survey } = this.props;
    let { getCustomerFieldsData } = survey;
    let custKeys =
      getCustomerFieldsData &&
        getCustomerFieldsData[0] &&
        getCustomerFieldsData[0].allkeys &&
        getCustomerFieldsData[0].allkeys.length > 0
        ? getCustomerFieldsData[0].allkeys
        : [];
    // let ValidKeys = [];
    if (custKeys && custKeys.length > 0) {
      custKeys.forEach((key) => {
        if (
          key !== "id" &&
          key !== "_id" &&
          key !== "isMNGRMoreThan5" &&
          key !== "showMNGRReport" &&
          key !== "orgRole" &&
          key !== "actionPlanStage" &&
          key !== "isTest" &&
          key !== "__v"
        ) {
          // ValidKeys.push(key);

          binary_universObj[key] = "";
        }
      });
    }
    // console.log('---------ValidKeys--------- ',ValidKeys)
    //-------------------------------------------------------------------------------------------------

    let binary_univers = [
      {
        cust_id: "001",
        email: "sample@mail.com",
        ...binary_universObj,
      },
    ];

    console.log("binary_univers: ", binary_univers);

    let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

    // Create a new Workbook
    var wb = XLSX.utils.book_new();

    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, "Binary values");

    // export your excel
    XLSX.writeFile(wb, "sample customer.xlsx");
  };

  onDeleteFilter = (custFilter) => {
    let tcustData = {
      id: custFilter.id,
    };
    this.props.dispatch(customerActions.deleteCustomerFilter(tcustData));
  };

  handleCustomerDetails = (cust) => {
    this.setState({ customerData: cust, tcustCustData: cust });
  };

  onEditDetails = (check, customerData) => {
    //////console.log("customerData:",customerData)
    if (customerData && customerData.role && customerData.role === "ADMIN") {
      this.setState({ showEditDetails: check });
    }
  };

  handleRoleInput = (value) => {
    let { customerData } = this.state;
    customerData["role"] = value;
    this.setState({ customerData });
  };

  handleTestUserInput = (value) => {
    let { customerData } = this.state;
    customerData["isTest"] = value;
    this.setState({ customerData });
  };

  handleUpdateCustomerSubmit = () => {
    let { customerData } = this.state;

    let data = {
      id: customerData.id,
      email: customerData.email,
      mobile: customerData.mobile,
      cust_id: customerData.cust_id,
      role: customerData.role,
      isTest: customerData.isTest,
    };
    let tcustdata = {
      keyWord: "",
      pageNo: 1,
      companyId: this.props.match.params.id,
      size: this.state.customersize2,
    };
    //////console.log("customerData final: ", data)
    this.setState({ showEditDetails: false });
    this.props.dispatch(customerActions.updateCustomer(data, tcustdata));
  };

  handleChangeLabel = (custData) => {
    let Id = custData && custData.id ? custData.id : "";
    let label = custData && custData.label ? custData.label : "";
    this.setState({ CrrCustFilterLabel: label, showInputBox: Id });
  };

  handleLabelInput = (e) => {
    this.setState({ CrrCustFilterLabel: e.target.value });
  };
  submitCustomerFilterLabelChange = (id) => {
    let { CrrCustFilterLabel } = this.state;
    let data = {
      id: id,
      label: CrrCustFilterLabel,
    };
    this.props.dispatch(customerActions.updateCustomerFilterLabel(data));
    this.setState({ showInputBox: "" });
    setTimeout(() => {
      this.setState({ CrrCustFilterLabel: "" });
    }, 500);
  };

  selectFieldType = (field, option) => {
    let { crrFieldType } = this.state;
    let temp = {
      field: field,
      option: option,
    };
    let getIndex = crrFieldType.findIndex((prev) => prev.field === field);
    if (getIndex !== -1) {
      crrFieldType[getIndex]["option"] = option;
    } else {
      crrFieldType.push(temp);
    }
    this.setState({ crrFieldType });
  };

  closeUploadPreview = () => {
    this.setState({ showUploadPreview: false, loadedDataSheet: [] });
  };

  saveUploadCustomer = () => {
    let { loadedDataSheet } = this.state;
    if (loadedDataSheet && loadedDataSheet.length > 0) {
      let Temp1 = {
        keyWord: "",
        pageNo: 1,
        size: 6,
      };
      this.props.dispatch(
        surveyActions.saveCustomerList(loadedDataSheet, Temp1)
      );
    }
  };


  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });

    let datatcustcustomer = {
      keyWord: "",
      pageNo: data.selected + 1,
      companyId: this.props.match.params.id,
      size: this.state.size,
    }
    this.props.dispatch(customerActions.getCustomerList(datatcustcustomer));
  }


  handleSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    this.setState({ size: newSize, page: 0, offset: 0 }, () => {
      let datatcustcustomer = {
        keyWord: "",
        pageNo: 1,
        companyId: this.props.match.params.id,
        size: newSize,
      };
      this.props.dispatch(customerActions.getCustomerList(datatcustcustomer));
    });
  }

  render() {
    let { survey, customer, dashboard } = this.props;
    let {
      getCustomerFieldsData,
      saveCustomerListData,
      loading,
      getLanguageLabelData,
      getLanguageData,
      createSurveyData,
      getSurveyFromLibData,
      getSurveyListData,
      getSurveyListTotal,
      error,
    } = survey;
    let {
      getCustomerColumnNameData,
      getCustomerFilterListByCompanyIdData,
      getCustomerListData,
      getCustomerListTotal,
    } = customer;

    let {
      loadedDataSheet,
      showUploadPreview,
      crrFieldType,
      showInputBox,
      CrrCustFilterLabel,
      fileUploadError,
      showEditDetails,
      showFilterNames,
      formDataFilter,
      updateformDataFilter,
      openCustFilterModal2,
      openCustFilterModal,
      showCustomerModal,
      showLanguageModal,
      CurrentLanguageName,
      CurrentLanguage,
      showSidebar,
      CrrRoute,
      customerData,
      savePackage,
      isPackageSelected,
      showQuestionLibrary,
      packageList,
      CurrentSurveyData,
      showSurveyLibrary,
      showChooseSurveyModal,
      showSurveyList,
      CurrentSurveyName,
      confirmDeleteSurvey,
      surveyFormData,
      showCreateSurveyPopup,
      surveyValidation,
    } = this.state;
    let { getCompanyNameOnlyData } = dashboard;

    //console.log('---------CrrCustFilterLabel--------- ',CrrCustFilterLabel)

    //console.log('getCustomerColumnNameData: ',getCustomerColumnNameData)
    //console.log('getCustomerFilterListByCompanyIdData: ',getCustomerFilterListByCompanyIdData)

    let CustomerFilterCompanyList = [];
    if (
      getCustomerFilterListByCompanyIdData &&
      getCustomerFilterListByCompanyIdData.length > 0
    ) {
      getCustomerFilterListByCompanyIdData.forEach((filter) => {
        if (!filter.isDisable) {
          CustomerFilterCompanyList.push(filter);
        }
      });
    }
    //--------------------------------------------------------------------------------

    let IndexList = [];
    let pageCount = 7;
    let IndexListIndex = [];

    if (getCustomerListTotal) {
      if (
        getCustomerListTotal &&
        getSurveyListData &&
        getSurveyListData.length > 0
      ) {
        [...Array(getCustomerListTotal).keys()].forEach((item, index) => {
          if (index % pageCount === 0) {
            IndexList.push(index);
          }
          if (index === getSurveyListData.length - 1) {
            IndexList.push(index);
          }
        });
      }

      //console.log('IndexList: ',IndexList)

      if (IndexList && IndexList.length > 0) {
        IndexList.forEach((item, index) => {
          if (index !== IndexList.length - 1) {
            IndexListIndex.push(index + 1);
          }
        });
      }
      //console.log('IndexListIndex: ',IndexListIndex)
    }
    //--------------------------------------------------------------------------------

    //ROLE MANAGEMENT ----------------------------------------------

    let moduleAccess = {
      isAdmin: false,
      access: {},
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    //console.log('moduleAccess: ',moduleAccess)

    let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    let CustRole = "";
    if (clientData && clientData.role) {
      if (clientData.role === "ADMIN") {
        CustRole = "ADMIN";
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      } else if (clientData.role === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      } else if (clientData.role === "CROLE") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      } else if (clientData.role === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return moduleAccess.isAdmin
        ? true
        : moduleAccess && moduleAccess.access && moduleAccess.access[item]
          ? true
          : false;
    }

    //END -------------------------------------------------------

    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }

    let { ClientProfileData } = this.props.dashboard;
    if (
      ClientProfileData &&
      ClientProfileData.role &&
      ClientProfileData.role === "MNGR"
    ) {
      this.props.history.push(`/app/manageractionplan`);
    } else if (
      ClientProfileData &&
      ClientProfileData.role &&
      ClientProfileData.role === "EMP"
    ) {
      this.props.history.push(`/app/employeeactionplan`);
    }

    let CustomerListData = [];
    // if(getCustomerListData && getCustomerListData.length>0){
    //   CustomerListData = Object.key(getCustomerListData)
    // }
    // //console.log('getCustomerListData: ',getCustomerListData)
    // //console.log('CustomerListData: ',CustomerListData)

    let CustomerFieldList = [];
    if (getCustomerListData && getCustomerListData.length > 0) {
      CustomerFieldList = Object.keys(getCustomerListData[0]);
    }
    //console.log('CustomerFieldList: ',CustomerFieldList)

    let NewCustomerFieldList = [];
    if (CustomerFieldList && CustomerFieldList.length > 0) {
      CustomerFieldList.forEach((item) => {
        if (
          item != "isDisable" &&
          item != "isTest" &&
          item != "createdAt" &&
          item != "id" &&
          item != "actionPlanStage" &&
          item != "companyId" &&
          item != "email" &&
          item != "cust_id"
        ) {
          NewCustomerFieldList.push(item);
        }
      });
    }

    if (CustomerFieldList) {
    }

    const { page, size, offset } = this.state;
    const startItem = page * size + 1;
    const endItem = Math.min((page + 1) * size, getCustomerListTotal);

    //console.log('NewCustomerFieldList: ',NewCustomerFieldList)
    //console.log('customerData: ',customerData)
    //console.log('---------> IndexListIndex: ',IndexListIndex)

    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text="Loading Data..."
          className="loader"
        >
          <div className='h-screen overflow-hidden  bg-[#f9fafb]'>

            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              props={this.props}
              companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}
              sidebarToggle={this.sidebarToggle}
            />

            <div className="flex w-full" style={{ height: 'calc(100% - 2rem)' }}>

              <Sidebar
                onOverview={this.onOverview}
                showSidebar={showSidebar}
                handleSidebar={this.handleSidebar}
                accessAllow={accessAllow}
                routeLink={this.routeLink}
                showMobileSidebar={this.state.showMobileSidebar}
                sidebarToggle={this.sidebarToggle}
              />
              <main className="flex-1 w-full overflow-hidden overflow-y-auto bg-[#f9fafb] ">


                {accessAllow("uploadCust") ? (
                  <>
                    {customerData ? (
                      <div className="h-full p-4 overflow-hidden overflow-y-auto bg-gray-100">
                        <div className="p-6 bg-white border rounded-md">
                          <div className="w-full lg:flex ">
                            {/* first */}
                            <div className="flex justify-center text-center lg:w-8/12 ">
                              <div className>
                                <div className="relative">
                                  <img
                                    src="img/profile/user1.png"
                                    className="my-4 rounded-full lg:w-40 w-36 h-36 lg:h-40"
                                  />
                                  <span
                                    className="absolute bottom-0 right-0 p-2 text-white bg-pink-500 rounded-full material-symbols-outlined"
                                    style={{ fontSize: "18px" }}
                                  >
                                    photo_camera
                                  </span>{" "}
                                </div>
                                <h1 className="font-medium text-blue-500">
                                  {customerData && customerData.name
                                    ? customerData.name
                                    : "Na"}
                                </h1>
                                <p className="text-xs text-gray-500">
                                  {customerData && customerData.role
                                    ? customerData.role
                                    : ""}
                                </p>

                                {customerData &&
                                  customerData.role &&
                                  customerData.role === "ADMIN" ? (
                                  <div
                                    onClick={() =>
                                      this.onEditDetails(true, customerData)
                                    }
                                    className="flex justify-center"
                                  >
                                    <button className="flex items-center px-6 py-2 my-4 text-xs text-blue-500 border border-blue-500 rounded-full bg-blue-50 focus:outline-none">
                                      Edit Details{" "}
                                      <span
                                        className="pl-2 material-symbols-outlined"
                                        style={{ fontSize: "18px" }}
                                      >
                                        edit
                                      </span>
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            {showEditDetails ? (
                              <>
                                <div className="w-full md:px-6 lg:pr-4 lg:pl-8 ">
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Email
                                    </label>
                                    <input
                                      type="text"
                                      name="email"
                                      value={customerData.email}
                                      // onChange={this.handleCustInput}
                                      onChange={() => { }}
                                      disabled={true}
                                      placeholder="customer01mailinator.com"
                                      className="block w-full p-1 text-sm placeholder-black border text-slate-800"
                                    />
                                  </div>
                                  {/* 3 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Customer Id
                                    </label>
                                    <input
                                      type="text"
                                      name="cust_id"
                                      value={customerData.cust_id}
                                      // onChange={this.handleCustInput}
                                      onChange={() => { }}
                                      disabled={true}
                                      placeholder={194808}
                                      className="block w-full p-1 text-sm placeholder-black border text-slate-800"
                                    />
                                  </div>
                                  {/* 1 */}
                                  <div className="pb-4 ">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Mobile{" "}
                                    </label>
                                    <input
                                      type="text"
                                      name="mobile"
                                      value={customerData.mobile}
                                      // onChange={this.handleCustInput}
                                      onChange={() => { }}
                                      disabled={true}
                                      placeholder="+91 XXXXXXXXXX"
                                      className="block w-full p-1 text-sm placeholder-black border text-slate-800"
                                    />
                                  </div>
                                </div>

                                <div className="w-full md:px-6 lg:px-4">
                                  {/* 2 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Role
                                    </label>
                                    <div className="flex">
                                      {/* <div className=""><input checked={customerData && customerData.role==="ADMIN"?true:false} type="radio" onClick={()=>this.handleRoleInput("ADMIN")} onChange={()=>{}}/></div><div className="px-2 text-gray-500">ADMIN</div> */}
                                      <div className="pl-2">
                                        <input
                                          checked={
                                            customerData &&
                                              customerData.role === "CROLE"
                                              ? true
                                              : false
                                          }
                                          type="radio"
                                          onClick={() =>
                                            this.handleRoleInput("CROLE")
                                          }
                                          onChange={() => { }}
                                        />
                                      </div>
                                      <div className="px-2 text-gray-500">
                                        CROLE
                                      </div>
                                      <div className="pl-2">
                                        <input
                                          checked={
                                            customerData &&
                                              customerData.role === "MNGR"
                                              ? true
                                              : false
                                          }
                                          type="radio"
                                          onClick={() =>
                                            this.handleRoleInput("MNGR")
                                          }
                                          onChange={() => { }}
                                        />
                                      </div>
                                      <div className="px-2 text-gray-500">
                                        MNGR
                                      </div>
                                      <div className="pl-2">
                                        <input
                                          checked={
                                            customerData &&
                                              customerData.role === "EMP"
                                              ? true
                                              : false
                                          }
                                          type="radio"
                                          onClick={() =>
                                            this.handleRoleInput("EMP")
                                          }
                                          onChange={() => { }}
                                        />
                                      </div>
                                      <div className="px-2 text-gray-500">
                                        EMP
                                      </div>
                                    </div>
                                  </div>
                                  {/* 3 */}
                                  <div className="pb-4 ">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Test User
                                    </label>
                                    <div className="flex">
                                      <div className="">
                                        <input
                                          type="radio"
                                          checked={
                                            customerData && customerData.isTest
                                              ? true
                                              : false
                                          }
                                          onClick={() =>
                                            this.handleTestUserInput(true)
                                          }
                                          onChange={() => { }}
                                        />
                                      </div>
                                      <div className="px-2 text-gray-500">
                                        Yes
                                      </div>
                                      <div className="pl-2">
                                        <input
                                          type="radio"
                                          checked={
                                            customerData && customerData.isTest
                                              ? false
                                              : true
                                          }
                                          onClick={() =>
                                            this.handleTestUserInput(false)
                                          }
                                          onChange={() => { }}
                                        />
                                      </div>
                                      <div className="px-2 text-gray-500">No</div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="w-full md:px-6 lg:pr-4 lg:pl-8 ">
                                  {/* 1 */}
                                  <div className="pb-4 ">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Customer Name:
                                    </label>
                                    <input
                                      placeholder={
                                        customerData && customerData.name
                                          ? customerData.name
                                          : "NaN"
                                      }
                                      disabled={true}
                                      className="block w-full p-2 text-sm placeholder-black bg-white border rounded text-slate-800"
                                    />
                                  </div>
                                  {/* 2 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Email:
                                    </label>
                                    <input
                                      placeholder={
                                        customerData && customerData.email
                                          ? customerData.email
                                          : "NaN"
                                      }
                                      disabled={true}
                                      className="block w-full p-2 text-sm placeholder-black bg-white border rounded text-slate-800"
                                    />
                                  </div>
                                  {/* 3 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Customer Id:
                                    </label>
                                    <input
                                      placeholder={
                                        customerData && customerData.cust_id
                                          ? customerData.cust_id
                                          : "NaN"
                                      }
                                      disabled={true}
                                      className="block w-full p-2 text-sm placeholder-black bg-white border rounded text-slate-800"
                                    />
                                  </div>
                                  {/* 4 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Mobile:
                                    </label>
                                    <input
                                      placeholder={
                                        customerData && customerData.mobile
                                          ? customerData.mobile
                                          : "NaN"
                                      }
                                      disabled={true}
                                      className="block w-full p-2 text-sm placeholder-black bg-white border rounded text-slate-800"
                                    />
                                  </div>
                                  {/* 5 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Gender:
                                    </label>
                                    <input
                                      placeholder={
                                        customerData && customerData.Gender
                                          ? customerData.Gender
                                          : "NaN"
                                      }
                                      disabled={true}
                                      className="block w-full p-2 text-sm placeholder-black bg-white border rounded text-slate-800"
                                    />
                                  </div>
                                </div>
                                <div className="w-full md:px-6 lg:px-4">
                                  {/* 2 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Role:
                                    </label>
                                    <input
                                      placeholder={
                                        customerData && customerData.role
                                          ? customerData.role
                                          : "NaN"
                                      }
                                      disabled={true}
                                      className="block w-full p-2 text-sm placeholder-black bg-white border rounded text-slate-800"
                                    />
                                  </div>
                                  {/* 3 */}
                                  <div className="pb-4 ">
                                    <label className="block pb-2 text-xs text-gray-500">
                                      Test User:
                                    </label>
                                    <input
                                      placeholder={
                                        customerData && customerData.isTest
                                          ? "Yes"
                                          : "No"
                                      }
                                      disabled={true}
                                      className="block w-full p-2 text-sm placeholder-black bg-white border rounded text-slate-800"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          <div className="flex justify-center mt-4 lg:justify-end md:px-4">
                            {showEditDetails ? (
                              <button
                                onClick={() => this.handleUpdateCustomerSubmit()}
                                className="px-6 py-2 mr-1 text-xs text-white uppercase bg-blue-500"
                              >
                                update
                              </button>
                            ) : null}
                            {showEditDetails ? (
                              <button
                                onClick={() =>
                                  this.onEditDetails(false, customerData)
                                }
                                className="px-6 py-2 ml-1 text-xs font-medium text-red-500 uppercase bg-white border-2 border-red-500 "
                              >
                                cancel
                              </button>
                            ) : (
                              <button
                                onClick={() => this.handleCustomerDetails("")}
                                className="px-6 py-2 text-sm font-medium text-white capitalize bg-blue-500 rounded-md "
                              >
                                close
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (

                      <>

                        <div className="h-full p-4 space-y-4 overflow-hidden overflow-y-auto md:p-6" style={{ height: "calc(100% - 4rem)" }}>

                          <div className="flex flex-col p-4 mt-4 space-y-4 overflow-hidden bg-white border rounded-lg md:p-6">

                            <div className="items-stretch justify-between space-y-4 md:flex md:space-x-6 md:space-y-0">

                              <div className="flex space-x-3 items-center">
                                <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]">Customer List</h1>
                                <div className="flex space-x-2 capitalize items-center text-sm text-[#3D405B] font-medium">
                                  <span>Show</span>
                                  <form class="w-16">
                                    <select id="entries" value={size} onChange={this.handleSizeChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full px-2 py-1.5 focus:outline-none">
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  </form>
                                  <span>entries</span>
                                </div>
                              </div>


                              <div className="flex space-x-4">

                                <div className="lg:w-auto w-full flex justify-between border px-4 text-[#3D405B]/70 bg-white rounded-md text-sm items-center  shadow-sm overflow-hidden">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /> <circle cx="10" cy="10" r="7" /> <line x1="21" y1="21" x2="15" y2="15" />
                                  </svg>
                                  <input type="search" name="search" onChange={this.handleSearchCustomer} placeholder={LanguageObj && LanguageObj["search_by_email_text_" + CurrentLanguage] ? LanguageObj["search_by_email_text_" + CurrentLanguage] : "Search by email..."} className="lg:w-60 w-full focus:outline-none px-2 py-2 text-[#3D405B] placeholder:font-normal font-medium " />
                                </div>

                                <button onClick={() => this.DownloadCustomers(getCustomerListData, false)}
                                  className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2 " >
                                  <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                  > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <path d="M10 12l4 4m0 -4l-4 4" />
                                  </svg>
                                  <span> {"Download CSV"}</span>
                                </button>

                                {CustRole === "ADMIN" ? (
                                  <div onClick={() => this.addCustomerModal(true)} className="cursor-pointer bg-[#2196F3] text-white space-x-1 text-sm  border-[#2196F3] flex justify-between px-4 items-center  py-2 rounded-md">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" >
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="9" />  <line x1="9" y1="12" x2="15" y2="12" />  <line x1="12" y1="9" x2="12" y2="15" /></svg><span> Add Customers</span>
                                  </div>
                                ) : null}


                              </div>
                            </div>



                            {/* Table Start */}
                            <div className="w-full overflow-x-auto scroll-smooth whitespace-nowrap">
                              <div className="inline-block min-w-full overflow-hidden align-middle">
                                <table className="min-w-full border">
                                  <thead>
                                    <tr className="capitalize text-[#3D405B] border-b ">
                                      <th className="w-20 p-3 text-sm font-medium text-left border-r">{LanguageObj && LanguageObj["sno" + CurrentLanguage] ? LanguageObj["sno" + CurrentLanguage] : "sno"}</th>
                                      <th className="w-20 p-3 text-sm font-medium text-left border-r">{LanguageObj && LanguageObj["email_address_label_" + CurrentLanguage] ? LanguageObj["email_address_label_" + CurrentLanguage] : "Email Address"}
                                      </th>
                                      <th className="p-3 text-sm font-semibold text-left border-r w-60 ">{" "}{LanguageObj && LanguageObj["cust_id_label_" + CurrentLanguage] ? LanguageObj["cust_id_label_" + CurrentLanguage] : "Cust ID"}
                                      </th>

                                      {NewCustomerFieldList && NewCustomerFieldList.length > 0 ? NewCustomerFieldList.map((item) => !item.isDisable ? (
                                        <th style={{ color: CustomerFilterCompanyList.some((prev) => prev.name === item) ? " p-3 text-left text-sm  font-semibold border-r w-40 " : "p-3  text-left text-sm  font-semibold border-r w-40 " }} className="p-3 text-sm font-semibold text-left border-r w-80" >
                                          <div className="flex">
                                            <span className=""> {item} </span>
                                            {CustomerFilterCompanyList.some((prev) => prev.name === item) ? (
                                              <span className="hidden py-2 material-symbols-outlined" style={{ height: "5px", width: "5px" }} >
                                                filter_list
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </th>
                                      ) : null
                                      )
                                        : null}

                                      <th className="w-40 p-3 text-sm font-semibold text-left capitalize border-r">
                                        {LanguageObj && LanguageObj["status_label_" + CurrentLanguage] ? LanguageObj["status_label_" + CurrentLanguage] : "Status"}
                                      </th>

                                      <th className="w-40 p-3 text-sm font-semibold text-left capitalize border-r">
                                        {LanguageObj && LanguageObj["action_label_" + CurrentLanguage] ? LanguageObj["action_label_" + CurrentLanguage] : "Action"}
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody className="bg-white divide-y">
                                    {getCustomerListData &&
                                      getCustomerListData.length > 0
                                      ? getCustomerListData.map((cust, index) => (
                                        <tr className='odd:bg-[#F9FAFB] even:bg-white text-sm'>
                                          <td className="p-3 whitespace-no-wrap border-r text-center">
                                            <div className="text-sm text-gray-600">
                                              {(this.state.currentSurveyPage - 1) * this.state.size + (index + 1)}
                                            </div>
                                          </td>
                                          <td className="p-3 whitespace-no-wrap border-r">
                                            <div className="text-sm text-gray-600">
                                              {cust.email}
                                            </div>
                                          </td>

                                          <td className="p-3 whitespace-no-wrap border-r">
                                            <div className="text-sm text-gray-600">
                                              {cust.cust_id}
                                            </div>
                                          </td>

                                          {NewCustomerFieldList &&
                                            NewCustomerFieldList.length > 0
                                            ? NewCustomerFieldList.map((item) =>
                                              !item.isDisable ? (
                                                <td className="p-3 whitespace-no-wrap border-r">
                                                  <div className="text-sm text-gray-600">
                                                    {cust && cust[item]
                                                      ? cust[item]
                                                      : "------"}
                                                  </div>
                                                </td>
                                              ) : null
                                            )
                                            : null}


                                          <td className="p-3 text-sm font-medium leading-5 text-center whitespace-no-wrap border-r" >
                                            <span className="inline-flex px-2 text-sm text-green-800 bg-green-100 rounded-full">
                                              {cust.isDisable ? "Inactive" : "Active"}
                                            </span>
                                          </td>

                                          <td className="p-3 text-sm font-medium leading-5 text-center whitespace-no-wrap border-r">
                                            <span onClick={() => this.handleCustomerDetails(cust)} className="p-2 text-gray-500 rounded-md cursor-pointer material-symbols-outlined hover:bg-blue-100 hover:text-blue-500" style={{ fontSize: "16px" }} >
                                              edit
                                            </span>
                                          </td>
                                        </tr>
                                      ))
                                      : null}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/* Table end */}
                            {/* Pagination start*/}

                            <div class="sm:flex sm:flex-1 sm:items-center items-center justify-center sm:justify-between">
                              <div className="text-center">
                                <p class="text-sm text-gray-700 md:pb-0 pb-2">
                                  Showing
                                  <span class="font-medium"> {startItem} </span>
                                  to
                                  <span class="font-medium"> {endItem} </span>
                                  of
                                  <span class="font-medium"> {getCustomerListTotal} </span>
                                  results
                                </p>
                              </div>
                              <div className="relative z-0 flex md:justify-end justify-center w-76">
                                {
                                  getCustomerListTotal && getCustomerListTotal > 10 ?
                                    <ReactPaginate
                                      previousLabel={'Prev'}
                                      nextLabel={'Next'}
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={getCustomerListTotal / this.state.size}
                                      marginPagesDisplayed={1}
                                      pageRangeDisplayed={1}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination'}
                                      pageClassName={'page-cls'}
                                      activeClassName={'active'}
                                    />
                                    : null}
                              </div>
                            </div>


                            {/* Pagination start*/}
                          </div>
                        </div>

                      </>
                    )}
                    {/* {showCustomerModal?
    <div className="fixed top-0 z-40 flex items-center justify-center w-full h-full xl:h-screen" style={{background: '#0000005c'}}>
        <div className="w-11/12 bg-white rounded-lg shadow-sm xl:w-5/12 lg:w-6/12 md:w-8/12">
          <div className="flex justify-between p-4 border-b"> <h2 className="text-base font-semibold text-center test-gray-800 ">Upload Customers</h2> <span
           onClick={()=>this.addCustomerModal(false)} className="text-sm font-medium text-red-500 cursor-pointer">Close</span></div>
          <div className="m-4 mt-2">
            <div className="flex pb-2">
              <div className="text-gray-500">
                <span className="font-semibold cursor-pointer">Mandatory fields* : </span>
                <span className="text-blue-500">cust_id, email</span>
              </div>
              <div className="ml-12">
                <span className="text-red-500">{fileUploadError}</span>
              </div>
            </div>

            <div className="py-4 text-xs text-center text-gray-500 border-2 border-dotted rounded-md bg-gray-50"> <span className="text-blue-500 material-symbols-outlined" style={{fontSize: '42px'}}>cloud_upload</span>

              <div className="">
                <input type="file" className="ml-12 py-1.5 rounded-md focus:outline-none font-medium"
                 id="file" accept={SheetJSFT} onChange={this.handleChange} />
              </div>

              
            </div>
          </div>
          <div className="flex justify-between py-4 pr-4">
            <div className="text-xs ">
            </div>
            <button onClick={()=>this.downLoadSampleCSV()} className="mr-72 border border-green-500 rounded-md px-6 py-1.5 text-xs text-green-500 focus:outline-none" >Sample CSV</button>

            <button  onClick={this.handleFile} className="ml-22 border border-blue-500 rounded-md px-6 py-1.5 text-xs text-blue-500 focus:outline-none">Continue</button>
          </div>
        </div>
    </div>
:
null} */}

                    {/* CUSTOMER FILTER */}
                    {/* {openCustFilterModal?
  <AddFilter
  closeCustomerFilter={this.closeCustomerFilter} 
  onCustomerFilterDropdown={this.onCustomerFilterDropdown} 
  handleCustomerColumnSelect={this.handleCustomerColumnSelect} 
  handleFilterCheck={this.handleFilterCheck} 
  createCustomerFilter={this.createCustomerFilter} 
  onDeleteFilter={this.onDeleteFilter} 
  handleFilterStatus={this.handleFilterStatus} 

  showFilterNames={showFilterNames}
  formDataFilter={formDataFilter}
  getCustomerColumnNameData={getCustomerColumnNameData}
  CustomerFilterCompanyList={CustomerFilterCompanyList}
  LanguageObj={LanguageObj}
  CurrentLanguage={CurrentLanguage}
  getCustomerFilterListByCompanyIdData={getCustomerFilterListByCompanyIdData}
  handleFilterRank={this.handleFilterRank}
  updateformDataFilter={updateformDataFilter}
  updatehandleFilterRank={this.updatehandleFilterRank}

  onClickQuesUp={this.onClickQuesUp}
  onClickQuesDown={this.onClickQuesDown}

  />
:
null} */}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div
                        className="container h-full px-4 py-12 mx-auto overflow-hidden overflow-y-auto"
                        style={{ height: "calc(100% - 4rem)" }}
                      >
                        <div className="flex items-center justify-center w-full h-full">
                          {/*  text start*/}
                          <div className="text-center">
                            <img src="img/custty_survey.png" className="mb-4" />
                            <h1 className="text-xl font-semibold text-gray-500 cursor-default">
                              You have no access <br /> to this module!
                            </h1>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}

                {showCustomerModal ? (
                  <div className="fixed top-0 right-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-40 xl:h-screen">
                    <div className="w-11/12 mx-auto bg-white rounded-lg shadow-sm xl:w-6/12 lg:w-9/12 ">
                      {/* head */}
                      <div className="flex items-center justify-between p-4 border-b md:px-8">
                        <h2 className=" xl:text-xl text-lg font-medium text-[#3D405B]">
                          Add Customer
                        </h2>

                        <div
                          onClick={() => this.addCustomerModal(false)}
                          className="p-2 rounded-full cursor-pointer bg-slate-100 hover:bg-slate-200 "
                        >
                          <svg
                            className="w-6 h-6 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path>
                          </svg>
                        </div>
                      </div>

                      <div className="m-4 space-y-8 md:m-8 ">
                        {/* data here */}
                        <div className="p-4 space-y-2 text-sm border divide-y rounded-md text-slate-800">
                          <h1 className=" xl:text-xl text-lg font-medium text-[#3D405B]">
                            {" "}
                            Guidelines for uploading the data
                          </h1>
                          <ol className="list-disc pl-6 space-y-1.5 py-4 text-slate-800 ">
                            <li className="">
                              {" "}
                              Mandatory Column include cust_id and email as first
                              column & second column should be in the file.
                            </li>
                            <li className="capitalize">
                              {" "}
                              Your file columns/headers should not contain any
                              special characters – this includes characters that
                              are not numbers or the English alphabet.
                            </li>
                            <li className="capitalize">
                              {" "}
                              Be careful not to include duplicates of the same row
                              – no two customers should have the same cust_id or
                              email.
                            </li>
                            <li className="capitalize">
                              {" "}
                              Date format must be same as DD-MM-YYYY
                            </li>
                            <li className="capitalize">
                              {" "}
                              See the sample data format for reference and prepare
                              your master data accordingly without any change in
                              mandatory column names
                            </li>
                            <li className="capitalize">
                              {" "}
                              Make sure your Excel sheet is not password
                              protected.
                            </li>
                          </ol>
                        </div>

                        <div className="space-y-4">
                          <div className="grid grid-cols-1 gap-4 xl:grid-cols-4 md:grid-cols-2">
                            {/* 1 */}
                            <div
                              onClick={() => this.downLoadSampleCSV()}
                              className="flex items-start p-2 px-4 space-x-4 rounded shadow-sm cursor-pointer 2xl:items-center bg-sky-100 md:col-span-2"
                            >
                              <span class="material-symbols-outlined p-2 bg-white rounded-full text-blue-500 shadow-sm">
                                download
                              </span>
                              <div>
                                <h1 className="text-sm font-medium md:text-base text-slate-800 ">
                                  Download Sample Data
                                </h1>
                                <p className="text-sm text-gray-500">
                                  See the sample data format for reference
                                </p>
                              </div>
                            </div>

                            {/* 2 */}
                            <input
                              type="file"
                              id="file"
                              accept={SheetJSFT}
                              onChange={this.handleChange}
                              hidden
                            />
                            <label
                              for="file"
                              className="rounded shadow-sm cursor-pointer bg-sky-100 md:col-span-2"
                            >
                              <div className="flex items-start p-2 px-4 space-x-4 2xl:items-center ">
                                <span class="material-symbols-outlined p-2 bg-white rounded-full text-blue-500 shadow-sm">
                                  upload
                                </span>
                                <div>
                                  <h1 className="text-sm font-medium md:text-base text-slate-800 ">
                                    Upload Master Data
                                  </h1>
                                  <p className="text-sm text-gray-500">
                                    Upload master data as per the guidelines
                                  </p>
                                </div>
                              </div>
                            </label>

                            {/* 3 */}
                            {/* <div className='flex items-start p-2 px-4 space-x-4 bg-blue-100 rounded shadow-md cursor-pointer 2xl:items-center md:col-span-4' >
                                <span class="material-symbols-outlined">
                                  person_off
                                </span>
                                <div>
                                  <h1 className='text-sm font-medium md:text-base' >Add/update/disable employee</h1>
                                  <p className='text-sm text-gray-500' >See the sample format our system</p>
                                </div>
                              </div> */}
                          </div>

                          <div className="hidden">
                            <button
                              onClick={() => this.downLoadSampleCSV()}
                              className=" border border-green-500 rounded-md px-6 py-1.5 text-xs text-green-500 focus:outline-none"
                            >
                              Sample CSV
                            </button>

                            <button
                              onClick={this.handleFile}
                              className="ml-22 border border-blue-500 rounded-md px-6 py-1.5 text-xs text-blue-500 focus:outline-none"
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {showUploadPreview ? (
                  <>
                    <UploadPreview
                      loadedDataSheet={loadedDataSheet}
                      closeUploadPreview={this.closeUploadPreview}
                      saveUploadEmployee={this.saveUploadCustomer}
                      handleChange={this.handleChange}
                      SheetJSFT={SheetJSFT}
                      saveCustomerListData={saveCustomerListData}
                      selectFieldType={this.selectFieldType}
                      crrFieldType={crrFieldType}
                      getCustomerFieldsData={getCustomerFieldsData}
                    />
                  </>
                ) : null}
              </main>
            </div>
          </div>
        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard, customer } = state;
  return {
    loggingIn,
    survey,
    dashboard,
    customer,
  };
}
export default connect(mapStateToProps)(Customers);
