import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactWordcloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';

class WordChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedText:''
    }
  }



  componentDidMount() {
  }


    handleKeyword=(data)=>{
      let { handleKeywordCall } = this.props;
      handleKeywordCall(data.text)
      this.setState({SelectedText:data.text+' ('+data.value+')'});

    }

    handleKeywordTooltip=(data)=>{
      this.setState({SelectedText:data.text+' ('+data.value+')'});
    }


    render(){

      let { wordCloudData , loading}= this.props;
      // let { SelectedText }= this.state;

      //console.log('SelectedText: ',SelectedText)

      let words = []

      // let CurrentWord = '';

      if(wordCloudData && wordCloudData.list &&  wordCloudData.list.length>0){
        wordCloudData.list.forEach((item)=>{
          words.push({
            text: item[0],
            value: item[1]
          })
        })
      }

      const callbacks = {
        // getWordColor: word => word.value > 50 ? "blue" : "red",
        onWordClick: (data)=>this.handleKeyword(data),
        
        // //onWordMouseOver: //console.log(""),
        // getWordTooltip: (data)=>{},
        getWordTooltip: function(a){
          return (a.text+': '+a.value)
        },
      }



      const options = {
        rotations: 2,
        rotationAngles: [0, -90],
        padding:5,
        fontWeight:600,
        fontSizes:[20,50]
        
      };



        return(
            <>
                <div className="justify-center w-full p-4 m-4 mr-2 bg-white shadow-lg" >
                    <div className="flex items-center justify-between">
                    <h1 className="text-sm font-semibold text-gray-900">Word Cloud</h1>
                    {/* <h1 className="text-sm font-semibold text-gray-900 text-blue-500">{SelectedText}</h1> */}
                    </div>
                    {/* <div className="cursor-pointer" onClick={()=>this.handleKeyword('policy')}>Policy</div>
                    <div className="h-60" /> */}
                    <div style={{height:'400px',marginTop:'10px'}}>
                      {words && words.length>0?
                        <ReactWordcloud 
                        callbacks={callbacks}
                        options={options}
                        words={words} 
                        />
                      :
<>
{!loading?
<div className="pt-40 text-3xl text-center text-gray-400">We have not calculated this section!</div>:null}
</>
                      }
                    </div>
                    <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>

                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(WordChart);
