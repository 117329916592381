import React, { Component } from 'react';
import { connect } from 'react-redux';
// import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let { overallCategoryScore, RiskgetRiskCategoryData, AnswerObjList,  DemographicValues, XAxis}= this.props;

    console.log('xxxxx--------nps -----------------AnswerObjList: ',AnswerObjList)     
    console.log('xxxxx--------nps -----------------DemographicValues: ',DemographicValues)     



    DemographicValues.sort();

    // AnswerObjList
  // console.log('AnswerObjList-------->',AnswerObjList);


  function getColor(score2){
    let color = "#858784";

    if(score2!=="-"){
      if(true){
        let score = Number(score2);
        if(score >=25 && score<=30){
          color = "#1bbf36";
        }
        else if(score >=18 && score<=24){
          color = "#edb409";
        }
        else if(score >=13 && score<=17){
          color = "#e65757";
        }
        else if(score >=6 && score<=12){
          color = "#b31717";
        }
      }
    }
    else{
      color = "#e65757";
    }
    
    return color
  }




    function getValueScore(axis,value, empAns){


      // let riskMeterCountObjFilter = {
      //   "Extremely_High_Risk":0,
      //   "High_Risk":0,
      //   "Borderline":0,
      //   "Moderate_Risk":0,
      //   "No_Risk":0
      // }
      // let score = 0;
      let finalScore = 0;
      if(axis && value && empAns && empAns.length>0){

        let list = empAns.filter(prev=>prev.name === axis)
        console.log('list-------->',list);
        let score = 0
        if(list && list.length>0){
          list.forEach((answ)=>{


            score += answ && answ.driver && answ.driver[value]?answ.driver[value]:0






          });

          finalScore = (score/list.length).toFixed(2)

        }

        // score = DemographicValuesObj && DemographicValuesObj[axis] && DemographicValuesObj[axis][value]?DemographicValuesObj[axis][value]:0
      }
      return finalScore
    }



    


    return (
      <>

      <div className="py-1  whitespace-nowrap overflow-x-auto pb-6 ">
      <table className="border-collapse w-full">
        <thead>

          <tr className="text-xs">
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300 ">{XAxis}</th>
            {RiskgetRiskCategoryData && RiskgetRiskCategoryData.length>0?
            RiskgetRiskCategoryData.map((category)=>
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300 px-10">{category.name}</th>
            ):null}
          </tr>

        </thead>
        <tbody>

            <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                <td className="p-3 border border-b text-left font-bold" >    
                  {"OVERALL"}
                </td>
                {RiskgetRiskCategoryData && RiskgetRiskCategoryData.length>0?
                  RiskgetRiskCategoryData.map((category)=>
                  <td  className="text-white cursor-pointer p-3 border border-b px-6 font-bold" style={{background:getColor(overallCategoryScore[category.name])}}>    
                  {overallCategoryScore[category.name]}
                </td>
                  ):null}
              </tr>

          {DemographicValues && DemographicValues.length>0?
            DemographicValues.map((item,index)=>
            (item)?
              <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                <td className="p-3 border border-b text-left" >    
                  {item}
                </td>

                {RiskgetRiskCategoryData && RiskgetRiskCategoryData.length>0?
                  RiskgetRiskCategoryData.map((category)=>
                  <td className="text-white cursor-pointer p-3 border border-b px-6 font-bold" style={{background:getColor(getValueScore(item,category.name, AnswerObjList))}}>    
                  {getValueScore(item,category.name, AnswerObjList)}
                </td>
                  ):null}

              </tr>
            :null
            ):null}



        </tbody>
      </table>
    </div>

</>
    
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
