// import { dataTool } from 'echarts';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { TbChevronRight } from 'react-icons/tb';
class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }

  render() {
    let { getManagerActionPlanData, CurrentActionId, navigate, CurrentActionData, onSelectTask, CurrentDimensionData } = this.props;

    let TaskList = [];
    if (getManagerActionPlanData && getManagerActionPlanData.length > 0) {
      getManagerActionPlanData.forEach((action) => {
        if (action && action.id === CurrentActionId) {
          if (action && action.TaskList && action.TaskList.length > 0) {
            TaskList = action.TaskList;
          }
        }
      });
    }
    function taskStatusFn(task) {
      //1: start
      //2: pending
      //3: completed

      let taskStatus = 1
      if (task && task.isStarted) {
        if (task && task.isComplete) {
          taskStatus = 3;
        }
        else {
          taskStatus = 2;
        }
      }
      return taskStatus
    }


    function colorCodeFn(task) {
      let color = "#757575";
      let background = 'bg-slate-100';
      if (taskStatusFn(task) === 1) {
        color = "#0083db";
        background = 'bg-blue-100';
      }
      else if (taskStatusFn(task) === 2) {
        color = "#db9900";
        background = 'bg-yellow-100';
      }
      else if (taskStatusFn(task) === 3) {
        color = "#02bd5f";
        background = 'bg-green-100';
      }
      return color, background
    }



    // getManagerActionPlanData,currentParameterId
    return (

      <div className="w-full  bg-white border p-6 lg:col-span-2 rounded-lg space-y-6">
        {/* indicators */}
        <div className="text-base text-[#3D405B] flex items-center space-x-2 ">
          <p className="cursor-pointer"
            onClick={() => navigate("actionlist")}>{CurrentDimensionData && CurrentDimensionData.name ? CurrentDimensionData.name : ""}</p>
          <TbChevronRight />
          <p className="text-blue-500 cursor-pointer">{CurrentActionData && CurrentActionData.name ? CurrentActionData.name : ""}</p>
        </div>
        {/* upperbox */}
        <div className="bg-gradient-to-l from-[#62cff4] to-[#2c67f2] flex p-6 rounded-lg border space-x-4">
          <div>
            <div className="bg-white/10 w-14 h-14 rounded-lg flex items-center justify-center" ><span className="w-8 text-white material-symbols-outlined" style={{ fontSize: '2rem' }}>group</span></div></div>
          {/* right */}
          <div className=" ">
            <h1 className="text-xl font-medium text-[#fff]">{CurrentActionData && CurrentActionData.name ? CurrentActionData.name : ""}</h1>
            <p className="text-sm text-[#fff]/80">
              {CurrentActionData && CurrentActionData.desc ? CurrentActionData.desc : ""}
              {/* <span>View More</span> */}
            </p>
          </div>
        </div>
        {/* tabs */}
        <div className="flex items-center text-xs text-gray-500 border-b space-x-2">
          <span onClick={() => navigate("tasklist")} className="cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] ">Tasks</span>
          <span onClick={() => navigate("resourcelist")} className="hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border ">Resources</span>
        </div>
        {/* a line */}
        <p className="text-base font-medium flex items-center text-[#3D405B]">To improve {CurrentActionData && CurrentActionData.name ? CurrentActionData.name : ""} follow the below tasks.</p>
        {/* boxes */}
        <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 ">

          {TaskList && TaskList.length > 0 ?
            TaskList.map((task, index) =>

              <div onClick={() => onSelectTask(task, index + 1)} className="cursor-pointer bg-slate-50 p-4 rounded-lg border space-y-4">
                <h1 className="text-base font-medium text-[#3D405B] ">{task && task.name ? (task.name.length > 70) ? task.name.slice(0, 70) + "..." : task.name : ""}</h1>
                <div className=" text-sm text-[#3D405B]/70 items-center justify-between">

                  {taskStatusFn(task) === 1 ?
                    <>
                      <p className="">Not started yet,</p>
                      <p className="">click here to start the task</p>
                    </>
                    : null}
                  {taskStatusFn(task) === 2 ?
                    <>
                      <p className="">{(task && task.initdate) ? "Start Date: " + moment.unix(task.initdate).format("DD-MM-YY") : ""}</p>
                      <p className="">This task is pending...</p>
                    </>
                    : null}
                  {taskStatusFn(task) === 3 ?
                    <>
                      <p className="">{(task && task.initdate) ? "Start Date: " + moment.unix(task.initdate).format("DD-MM-YY") : ""}</p>
                      <p className="">{(task && task.date) ? "End Date: " + moment.unix(task.date).format("DD-MM-YY") : ""}</p>
                    </>
                    : null}
                </div>
                {/* loader */}
                <div className="h-1 bg-white rounded-full my-2 bg-opacity-50 w-full overflow-hidden">
                  {task.isComplete ? <div className="bg-white w-full h-1" /> : <div className="bg-white w-0 h-1" />}
                </div>

                <span className="flex">
                  <span
                    //  style={{
                    //   color: colorCodeFn(task),
                    //   background: colorCodeFn(task),
                    // }} 
                    className={task.isStarted ? task.isComplete ? "text-xs block font-semibold bg-green-100 text-green-600 rounded-md px-4 py-2"
                      : 'text-xs block font-semibold bg-yellow-100 text-yellow-600 rounded-md px-4 py-2' :
                      'text-xs block font-semibold bg-sky-100 text-sky-500 rounded-md px-4 py-2'}>
                    {task.isStarted ? task.isComplete ? "COMPLETED" : "PENDING" : "LET'S START"}
                  </span>
                  {task.isComplete ? <span className="material-symbols-outlined text-green-500">check_circle</span> : null}
                </span>


              </div>
            )
            : null}

        </div>
      </div>

    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dimension);
