// import { dataTool } from 'echarts';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Actions from '../Actions/Actions';
// import Resource from '../TaskResouce/Resource/Resource';
// import Task from '../TaskResouce/Task/Task';
// import TaskResource from '../TaskResouce/TaskResource';
// import CompleteModal from './CompleteModal';

class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }

  render() {
    let { getManagerActionPlanData, CurrentDimensionId, onSelectAction, isRiskAction } = this.props;


    let ActionList = []

    if (isRiskAction) {
      ActionList = getManagerActionPlanData;
    }
    else {
      if (getManagerActionPlanData && getManagerActionPlanData.length > 0) {
        getManagerActionPlanData.forEach((item) => {
          if (item && item.parameterId && item.parameterId.id && item.parameterId.id === CurrentDimensionId) {
            ActionList.push(item);
          }
        });
      }
    }


    // getManagerActionPlanData,currentParameterId


    function taskStatus(action) {
      let data = {
        count: 0,
        total: 0
      }
      if (action && action.TaskList && action.TaskList.length > 0) {
        data["count"] = action.TaskList.filter(x => x.isComplete === true).length;
        data["total"] = action.TaskList.length;
      }
      return data
    }

    return (

      <div className="w-full p-6 space-y-6 bg-white border  rounded-lg  lg:col-span-2">
        <h1 className="text-xl font-medium text-[#3D405B]">Working on this action will help you to improve on:</h1>
        {/* boxes */}
        <div className="grid gap-4 my-4 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-3">
          {/* box 1 */}

          {ActionList && ActionList.length > 0 ?
            ActionList.map((action) =>

              <div className="p-4 bg-slate-50 border rounded-md hover:shadow-lg space-y-4">
                <div className="flex "> <div style={{ background: action.colorCode }} className="flex items-center justify-center text-blue-500 rounded-lg  bg-blue-0 w-14 h-14">
                  <span className="text-white material-symbols-outlined" style={{ fontSize: '2rem' }}>group</span></div></div>
                <div>
                  <h1 className="text-base font-medium">{action.name}</h1>
                  <p className="py-2 text-sm text-[#3D405B]/70">{action.desc} </p>
                </div>
                {/* progess */}
                <div>
                  <div className="flex justify-between text-xs font-medium text-gray-500"> <p>Tasks</p> <p>{taskStatus(action).count + "/" + taskStatus(action).total}</p></div>
                  <div className="w-full h-1 my-2 overflow-hidden bg-gray-100 rounded-md"><div style={{ background: action.colorCode, width: ((taskStatus(action).count * 100) / (taskStatus(action).total)).toString() + "%" }} className="w-6/12 h-1 bg-blue-500" /></div>
                </div>
                {/* btn */}
                <button onClick={() => onSelectAction(action)} style={{ background: action.colorCode }} className="w-full p-3 mt-4 text-sm font-medium text-white uppercase rounded-md hover:bg-blue-600">view tasks</button>
              </div>

            ) : null}

        </div>
      </div>

    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dimension);
