import React from "react";

export default function AddUserEmployeeList(props) {
  let {
    getEmployeeListData,
    LanguageObj,
    CurrentLanguage,
    openAccessTab,
    selectedEmployeeData

  } = props;



  function getAlreadyAssignCheck(emp_id, DataV2) {
    let value = false;
    if (DataV2 && DataV2.length > 0) {
      let getIndex = DataV2.findIndex(function (prev) {
        let emp_idprev = prev && prev.employeeId && prev.employeeId.emp_id ? prev.employeeId.emp_id : "NA"
        return (emp_idprev === emp_id)
      });
      if (getIndex !== -1) {
        value = true;
      }
    }
    return value
  }




  return (

    <div className="align-middle inline-block min-w-full overflow-hidden  ">
      <table className="min-w-full  border-collapse">
        <thead>
          <tr className="text-sm text-gray-800">
            <th className="px-6 py-3  bg-gray-50 text-left  font-medium  tracking-wider"> {"Name"}</th>
            <th className="px-6 py-3  bg-gray-50 text-left  font-medium  tracking-wider">{LanguageObj && LanguageObj["emp_id_label_" + CurrentLanguage] ? LanguageObj["emp_id_label_" + CurrentLanguage] : "Emp Id"}</th>
            <th className="px-6 py-3  bg-gray-50 text-left  font-medium  tracking-wider"> {LanguageObj && LanguageObj["email_address_label_" + CurrentLanguage] ? LanguageObj["email_address_label_" + CurrentLanguage] : "Email Address"}</th>
            <th className="px-6 py-3  bg-gray-50 text-left  font-medium  tracking-wider"> {"Role"} </th>
            {/* <th className="px-6 py-3  bg-gray-50 text-center  font-medium  tracking-wider"> {LanguageObj && LanguageObj["status_label_" + CurrentLanguage] ? LanguageObj["status_label_" + CurrentLanguage] : "Status"} </th> */}
            {/* <th className="px-6 py-3  bg-gray-50 text-center  font-medium  tracking-wider"> {LanguageObj && LanguageObj["action_label_" + CurrentLanguage] ? LanguageObj["action_label_" + CurrentLanguage] : "Grant Access"} </th> */}
          </tr>
        </thead>
        <tbody className="bg-white">
          {getEmployeeListData && getEmployeeListData.length > 0 ?
            getEmployeeListData.map((emp, index) =>
              (!(emp && emp.role === "ADMIN") || true) ?
                <tr className="text-sm text-gray-800">
                  <td className="px-6 py-2 whitespace-no-wrap ">

                    <div className="leading-5  "><label className="cursor-pointer  "><input type="checkbox" 
                    checked={selectedEmployeeData.employeeID === emp.emp_id ? true : false}
                      onChange={() => openAccessTab(emp)} className="mr-2" /> {emp && emp.name ? emp.name : "-----"}</label></div>
                  </td>
                  <td className="px-6 py-2 whitespace-no-wrap ">
                    <div className="leading-5  ">{emp && emp.emp_id ? emp.emp_id : "-----"}</div>
                  </td>
                  <td className="px-6 py-2 whitespace-no-wrap    font-normal ">  {emp && emp.email ? emp.email : "-----"} </td>
                  <td className="px-6 py-2 whitespace-no-wrap    leading-5 "> {emp && emp.role ? emp.role : "-----"}</td>

                  {/* {emp.isDisable ?
                    <td className="px-6 py-2 whitespace-no-wrap    leading-5 font-medium text-center">
                      <span className="px-2 inline-flex   leading-5 font-medium rounded-full bg-green-100 text-green-800">Inactive</span>
                    </td>
                    :
                    <td className="px-6 py-2 whitespace-no-wrap    leading-5 font-medium text-center">
                      <span className="px-2 inline-flex   leading-5 font-medium rounded-full bg-red-100 text-red-800">Active</span>
                    </td>} */}

                  {/* <td className="px-6 py-2 whitespace-no-wrap     leading-5 font-medium text-center">
                    <span onClick={() => openAccessTab(emp)} className="cursor-pointer material-symbols-outlined hover:bg-blue-100 p-2 rounded-md hover:text-blue-500 " style={{ fontSize: '1.2rem' }}>privacy_tip</span>
                  </td> */}

                </tr>
                : null
            ) : null
          }
        </tbody>
      </table>

      
    </div >

  );
}
