import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dashboardActions, surveyActions } from '../../_actions';
import Header from '../SurveyMgmt/Header/Header';
import Sidebar from '../Overview/Sidebar/SidebarOverview';
import { accessModule } from '../../_helpers';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import * as XLSX from 'xlsx';
import { make_cols } from './MakeColumns';
import AddFilter from './Modal/AddFilter';
import CreateParameterModal from './Modal/CreateParameterModal';
import ReactPaginate from 'react-paginate';

class ParameterSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveysize: 10000,
      fileUploadError: "",
      showSurveyList: true,
      showAddNewSurvey: false,
      showEditSurvey: false,
      confirmDeleteSurvey: false,
      CurrentSurveyId: '',
      CurrentSurveyName: '',
      showChooseSurveyModal: false,
      showSurveyLibrary: false,
      isPackageSelected: false,
      CurrentSurveyData: '',
      showQuestionLibrary: '',
      searchEmployee: '',

      showEmployeeModal: false,

      EmpIndexA: 0,
      EmpIndexB: 6,

      offset: 0,
      page: 0,
      size: 10,

      currentSurveyPage: 1,

      savePackage: '',
      packageList: [],
      surveyValidation: {
        "name": true,
        "desc": true
      },


      surveyFormData: {
        "name": '',
        "desc": ''
      },

      showSidebar: true,


      employeesize2: 6,

      showCreateSurveyPopup: false,

      CurrentLanguage: 'en',
      CurrentLanguageName: 'English',

      showLanguageModal: false,

      openEmpFilterModal: false,
      openEmpFilterModal2: false,


      formDataFilter: {
        "name": "",
        "label": "",
        "rank": 1,
        "isDisable": false
      },
      updateformDataFilter: {
        "id": "",
        "name": "",
        "label": "",
        "rank": 1
      },

      showFilterNames: false,
      employeeData: "",
      showEditDetails: false,

      employeeData: "",
      tempEmpData: "",
      CrrEmpFilterLabel: "",
      showInputBox: false,


      showCreateParameterModal: false,
      parameterFormData: {
        "name": "",
        "label": "",
        "parameterTypeId": "",
        "parameterTypeLevel": ""
      },

      isBucket: true,
      CurrBucketId: false,
      CrrParaLibCollection: [],
      labelName: "",
      labelCollection: [],
      currentIndex: null,
      itemkeyWord: "",
      showMobileSidebar: false

    }
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getCompanyNameOnly());
    let dataX = {
      "keyWord": "",
      "pageNo": 1,
      "size": 1000
    }
    this.props.dispatch(dashboardActions.getParameterTypeList(dataX, true));
    let dataX1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.employeesize2,
      "parameterTypeLevel": 1
    }
    this.props.dispatch(surveyActions.getParameterList(dataX1));
  }

  handleChange = (e) => {
    const files = e.target.files;

    if (files && files[0]) {
      let fileName = files && files[0] && files[0].name ? files[0].name : "";
      let extName1 = fileName && fileName.substr(fileName.length - 3) ? fileName.substr(fileName.length - 3) : "";
      let extName2 = fileName && fileName.substr(fileName.length - 4) ? fileName.substr(fileName.length - 4) : "";
      if ((extName1 === "csv") || (extName2 === "xlsx")) {

        this.setState({ file: files[0] }, () => {
          ////console.log("this.state.file",this.state.file)
          this.setState({ fileUploadError: "" });
        });

      }
      else {
        this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
        ////console.log("not csv or xlsx file")
      }

    }
    else {
      this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
    }



  };

  handleFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws);
      // ////console.log("datadatadatadata ", data);
      data = data.map(v => ({ ...v, "companyId": this.props.match.params.id }))

      let flag = 0;
      let locList = [];
      if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let value = validateEmail(data[i].email);
          ////console.log("emails:x:",data[i].email);

          if (value) {
            //all valid
          }
          else {
            flag = flag + 1;
            locList.push(i + 1);
          }
        }
      }
      function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      if (flag) {
        this.setState({ isNotMail: true, isNotMailPos: locList })
      }
      else {
        /* Update state */
        let datatemp0 = {
          "keyWord": this.state.keyWord,
          "pageNo": 1,
          "size": this.state.employeesize
        }
        let Temp1 = {
          "keyWord": '',
          "pageNo": 1,
          "size": 6
        }
        this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
          this.props.dispatch(surveyActions.saveEmployeeList(this.state.data, Temp1));
          this.setState({ isNotMail: false, isNotMailPos: [], showEmployeeModal: false })

        });
      }



    };
    if (this.state.file) {
      if (rABS) {
        reader.readAsBinaryString(this.state.file);
      } else {
        reader.readAsArrayBuffer(this.state.file);
      };
    }
  }

  onDisableEmployee = (data) => {
    ////console.log("data Employee: ", data);
    let tempdata = {
      "id": data.id.toString(),
      "keyWord": this.state.keyWord,
      "pageNo": this.state.employeepage,
      "size": this.state.size

    }

    ////console.log("asdf :: ", tempdata);
    this.props.dispatch(surveyActions.updateEmployeeStatus(tempdata));
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;

    this.setState({ searchEmployee: event.target.value }, () => {
      if (!event.target.value) {
        let dataX2 = {
          "keyWord": "",
          "pageNo": 1,
          "size": this.state.employeesize2,
          "parameterTypeLevel": 1
        }
        this.props.dispatch(surveyActions.getParameterList(dataX2));
      }


    })

    // ////console.log("search value:",value)
    // this.setState({ keyWord: value, offset: 0 });
    // let data = {
    //   "keyWord": value,
    //   "pageNo": 1,
    //   "companyId": this.props.match.params.id,
    //   "size": this.state.size
    // }
    // this.setState({employeeData:''});

    // this.props.dispatch(surveyActions.getEmployeeList(data));
  }

  handleSearchParameter = (event) => {
    event.preventDefault();
    let { value } = event.target;

    this.setState({ searchEmployee: value }, () => {
      let data = {
        "keyWord": value,
        "pageNo": 1,
        "size": this.state.employeesize2,
        "parameterTypeLevel": 1
      }
      this.props.dispatch(surveyActions.getParameterList(data));
    })

  }

  handleADDpackage = (survey) => {
    this.setState({ showQuestionLibrary: true })

    let { packageList } = this.state;
    if (packageList.findIndex(prev => prev === survey.id) === -1) {
      packageList.push(survey.id);
      this.setState({ isPackageSelected: false })
    }
    else {
      let getIndex = packageList.findIndex(prev => prev === survey.id);
      packageList.splice(getIndex, 1);
      this.setState({ isPackageSelected: true })
    }
    this.setState({ packageList });
  }


  onSurveyDelete = () => {
    let data = {
      id: this.state.CurrentSurveyId
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }
    this.props.dispatch(surveyActions.deleteSurvey(data, datatempsurvey));
    this.setState({ confirmDeleteSurvey: false, CurrentSurveyId: '', CurrentSurveyName: '' })
  }

  onEditSurvey = (data) => {
    this.props.history.push(`/app/question/${data.id}`)
  }

  onReportSurvey = (data) => {
    this.props.history.push(`/app/surveyreport/${data.id}`)
  }

  onPublishSurvey = (data) => {
    this.props.history.push(`/app/publish/${data.id}`)
  }

  handleChooseSurveyPopup = () => {
    this.setState({ showCreateSurveyPopup: false, showChooseSurveyModal: true })
  }

  handleCreateSurveyPopup = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showCreateSurveyPopup: true })
  }

  handleCreateSurveyFromLibrary = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showSurveyLibrary: true });
    this.props.dispatch(surveyActions.getSurveyFromLib());
  }

  handleSurveyLibraryClose = (check) => {
    if (check) {
      this.setState({ CurrentSurveyData: false });
    }
    else {
      this.setState({ showSurveyLibrary: false });
    }
  }


  QuestionLibraryClose = () => {
    this.setState({ CurrentSurveyData: false, savePackage: '', showQuestionLibrary: false });
  }



  handleCreateSurveyPopupClose = () => {
    this.handleChooseSurveyPopupClose();
    let validate = {
      "name": true,
      "desc": true
    }
    let tempData = {
      "name": '',
      "desc": ''
    }
    this.setState({ showCreateSurveyPopup: false, surveyValidation: validate, surveyFormData: tempData })
  }

  handleChooseSurveyPopupClose = () => {
    this.setState({ showChooseSurveyModal: false })
  }

  onLogout = () => {
    console.log('logout')
    this.props.dispatch(userActions.logout());
  }


  goToDashboard = () => {
    this.props.history.push('/app/dashboard');
  }

  goToGeneralDashboard = () => {
    let SurveyId = '5fa04d316ede0f0336066cce';
    this.props.history.push('/app/generaldashboard/' + SurveyId);
  }


  onDashboard = (survey) => {
    // console.log('survey: ', survey);
    if (survey && survey.templateId === 1) {
      this.props.history.push('/app/dashboard/' + survey.id);
    }
    else if (survey && survey.templateId === 2) {
      this.props.history.push('/app/dashboard/' + survey.id);
    }
    else if (survey && survey.templateId === 3) {
      this.props.history.push('/app/generaldashboard/' + survey.id);
    }

  }

  handleSurveyLibQuestions = (survey) => {
    this.setState({ CurrentSurveyData: survey });
  }

  handleUseSurveyPage = () => {
    let { CurrentSurveyData } = this.state;
    console.log('CurrentSurveyData: ', CurrentSurveyData)
  }

  onLogout = () => {
    console.log('logout')
    this.props.dispatch(userActions.logout());
  }

  handleSuveyPackageSubmit = () => {
    let { SurveySubmitData, packageList } = this.state;
    let resData = {
      "name": SurveySubmitData.name,
      "desc": SurveySubmitData.desc,
      "templateId": 2
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }

    this.props.dispatch(surveyActions.createSurvey(resData, datatempsurvey, false, packageList));

    let validate = {
      "name": true,
      "desc": true
    }
    let tempData = {
      "name": '',
      "desc": ''
    }
    this.setState({ showSurveyLibrary: false, showCreateSurveyPopup: false, showChooseSurveyModal: false, surveyValidation: validate, surveyFormData: tempData })

  }

  addEmployeeModal = (value) => {
    this.setState({ showEmployeeModal: value });
    this.setState({ fileUploadError: "" });
  }

  prevEmpPage = () => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (EmpIndexA > 0) {
      this.setState({ EmpIndexA: EmpIndexA - 1, EmpIndexB: EmpIndexB - 1 });
    }
  }
  nextEmpPage = (IndexListIndex) => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (IndexListIndex && IndexListIndex.length && (EmpIndexB < IndexListIndex.length)) {
      this.setState({ EmpIndexA: EmpIndexA + 1, EmpIndexB: EmpIndexB + 1 });
    }
  }

  SelectEmpPage = (val, IndexListIndex) => {
    if (val > 0 && val <= (IndexListIndex && IndexListIndex.length > 0 ? IndexListIndex.length : 0)) {
      this.setState({ currentSurveyPage: val });
      let datatempemployee = {
        "keyWord": "",
        "pageNo": val,
        "companyId": this.props.match.params.id,
        "size": this.state.employeesize2,
        "parameterTypeLevel": 1
      }
      this.props.dispatch(surveyActions.getParameterList(datatempemployee));
    }
  }

  DownloadEmployees = (employeesItem, isFilter) => {
    let list = [];
    let keyList = []
    let keyNewList = []
    if (employeesItem && employeesItem.length > 0 && employeesItem[0]) {
      keyList = Object.keys(employeesItem[0])
    }
    if (keyList && keyList.length > 0) {
      keyList.forEach((key, index) => {
        if (key != 'id'
          // && key != 'role' 
          && key != 'companyId' && key != 'currentStage' && key != 'isDisable' && key != 'createdAt') {
          keyNewList.push(key);
        }
      })
    }

    if (employeesItem && employeesItem.length > 0) {
      employeesItem.forEach((emp, index) => {
        let data = {}
        if (keyNewList && keyNewList.length > 0) {
          keyNewList.forEach((keY, indey) => {
            data[keY] = emp[keY]
          })
        }
        list.push(data)
      })

      let finalList = [];
      if (isFilter) {
        let listFiltered = []
        let { getEmployeeFilterListByCompanyIdData } = this.props.survey;
        // console.log('asdad: ', getEmployeeFilterListByCompanyIdData)
        if (list && list.length > 0) {
          list.forEach((emp) => {
            let tempObj = {}
            tempObj["email"] = emp["email"];
            tempObj["emp_id"] = emp["emp_id"];
            if (getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length > 0) {
              getEmployeeFilterListByCompanyIdData.forEach((filter) => {
                if (!filter.isDisable) {
                  tempObj[filter.name] = emp[filter.name]
                }
              });
            }
            finalList.push(tempObj);
          });
        }
      }

      else {
        finalList = list;
      }
      let binary_univers = finalList;
      console.log('binary_univers: ', binary_univers)
      let binaryWS = XLSX.utils.json_to_sheet(binary_univers);
      // Create a new Workbook
      var wb = XLSX.utils.book_new()
      // Name your sheet
      XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values')
      // export your excel
      XLSX.writeFile(wb, 'employees.xlsx');
    }
  }

  handleViewDetails = (emp) => {
    this.setState({ employeeData: emp })
  }

  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  }

  routeLink = (link) => {
    this.props.history.push('/app/' + link);
    this.setState({ CrrRoute: link });
  }


  handleEmpInput = (e) => {
    let { employeeData } = this.state;
    employeeData[e.target.name] = e.target.value;
    this.setState({ employeeData });
  }


  handleLanguage = (item) => {
    this.setState({ CurrentLanguage: item.shortName, CurrentLanguageName: item.name, showLanguageModal: false });
  }


  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  }


  onEmployeeFilter = (getEmployeeListData) => {
    let { openEmpFilterModal } = this.state;
    this.props.dispatch(surveyActions.getEmployeeColumnName());
    this.setState({ openEmpFilterModal: !openEmpFilterModal, openEmpFilterModal2: false });

  }

  onAddFilter = (getEmployeeListData) => {
    let { openEmpFilterModal2 } = this.state;
    this.props.dispatch(surveyActions.getEmployeeColumnName());
    this.setState({ openEmpFilterModal2: !openEmpFilterModal2, openEmpFilterModal: false });

  }

  closeEmployeeFilter = (check) => {
    this.setState({ openEmpFilterModal: check });
  }

  closeEmployeeFilter2 = (check) => {
    this.setState({ openEmpFilterModal2: check });
  }

  handleFilterInput = (e) => {
    let { formDataFilter } = this.state;
    formDataFilter["label"] = e.target.value;
    this.setState({ formDataFilter });
  }
  handleFilterRank = (e) => {
    let { formDataFilter } = this.state;
    formDataFilter["rank"] = e.target.value;
    this.setState({ formDataFilter });
  }
  updatehandleFilterRank = (value, emp) => {
    // let { updateformDataFilter } = this.state;
    // updateformDataFilter["rank"] = value;
    // this.setState({updateformDataFilter});
    this.props.dispatch(surveyActions.updateEmployeeFilterRank({ id: emp.id, rank: value }));
  }

  onEmployeeFilterDropdown = (check) => {
    this.setState({ showFilterNames: check });
  }


  handleEmployeeColumnSelect = (name) => {
    let { formDataFilter } = this.state;
    formDataFilter["name"] = name;
    formDataFilter["label"] = name;
    this.setState({ formDataFilter, showFilterNames: false });
  }

  handleFilterStatus = (value) => {
    ////console.log("handleFilterStatus::", value);
    this.props.dispatch(surveyActions.updateEmployeeFilterStatus({ id: value }));
  }


  handleShowReportStatus = (value) => {
    ////console.log("handleShowReportStatus::", value);
    this.props.dispatch(surveyActions.updateEmployeeShowReportStatus({ id: value }));
  }



  handleIsEngCompStatus = (value) => {
    ////console.log("handleShowReportStatus::", value);
    this.props.dispatch(surveyActions.updateEmployeeEngagementComparisonStatus({ id: value }));
  }

  handleFilterCheck = () => {
    let { formDataFilter } = this.state;
    if (formDataFilter.isDisable) {
      formDataFilter['isDisable'] = false;
    }
    else {
      formDataFilter['isDisable'] = true;
    }
    this.setState({ formDataFilter });
  }

  createEmployeeFilter = (NameItem) => {
    let { getEmployeeFilterListByCompanyIdData } = this.props.survey;
    let Rank = (getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length > 0) ? getEmployeeFilterListByCompanyIdData.length + 1 : 1
    if (NameItem) {
      let tempData = {
        "name": NameItem,
        "label": NameItem,
        "rank": Rank,
        "isDisable": false
      }
      this.props.dispatch(surveyActions.createEmployeeFilter(tempData));
    }
    this.setState({ openEmpFilterModal2: false });
  }

  onClickQuesUp = (emp1, index) => {
    let { survey } = this.props;
    let { getEmployeeFilterListByCompanyIdData } = survey;
    // ////console.log("working:onClickUp: ",emp1,index,'==>',surveyId)

    let emp2 = ''
    if (getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length > 0 && index) {
      emp2 = getEmployeeFilterListByCompanyIdData[index - 1].id;
    }

    if (emp1 && emp2) {
      let data = {
        idA: emp1,
        idB: emp2
      }
      console.log('data idA, IdB: ', data)
      this.props.dispatch(surveyActions.updateEmployeeFilterRank(data));
    }

  }

  onClickQuesDown = (emp1, index) => {
    let { survey } = this.props;
    let { getEmployeeFilterListByCompanyIdData } = survey;

    let emp2 = ''
    if (getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length > 0 && (index !== getEmployeeFilterListByCompanyIdData.length - 1)) {
      emp2 = getEmployeeFilterListByCompanyIdData[index + 1].id;
    }


    if (emp1 && emp2) {
      let data = {
        idA: emp1,
        idB: emp2
      }
      console.log('data idA, IdB: ', data)

      this.props.dispatch(surveyActions.updateEmployeeFilterRank(data));
    }

  }

  downLoadSampleCSV = () => {

    let binary_univers = [{
      "emp_id": "001",
      "email": "sample@mail.com"
    }];
    console.log('binary_univers: ', binary_univers)

    let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

    // Create a new Workbook
    var wb = XLSX.utils.book_new()

    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values')

    // export your excel
    XLSX.writeFile(wb, 'sample employee.xlsx');
  }

  onDeleteFilter = (empFilter) => {
    let tempData = {
      id: empFilter.id
    }
    this.props.dispatch(surveyActions.deleteEmployeeFilter(tempData));
  }

  handleEmployeeDetails = (emp) => {
    this.setState({ employeeData: emp, tempEmpData: emp });
  }

  onEditDetails = (check, employeeData) => {
    ////console.log("employeeData:",employeeData)
    if ((employeeData && employeeData.role && (employeeData.role === "ADMIN"))) {
      this.setState({ showEditDetails: check });
    }
  }

  handleRoleInput = (value) => {
    let { employeeData } = this.state;
    employeeData["role"] = value;
    this.setState({ employeeData });
  }

  handleTestUserInput = (value) => {
    let { employeeData } = this.state;
    employeeData["isTest"] = value;
    this.setState({ employeeData });
  }

  handleUpdateEmployeeSubmit = () => {
    let { employeeData } = this.state;

    let data = {
      "id": employeeData.id,
      "email": employeeData.email,
      "mobile": employeeData.mobile,
      "emp_id": employeeData.emp_id,
      "role": employeeData.role,
      "isTest": employeeData.isTest,
    }
    let tempdata = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": this.props.match.params.id,
      "size": this.state.employeesize2
    }
    ////console.log("employeeData final: ", data)
    this.setState({ showEditDetails: false });
    this.props.dispatch(surveyActions.updateEmployee(data, tempdata));
  }


  handleChangeLabel = (empData) => {
    let Id = empData && empData.id ? empData.id : "";
    let label = empData && empData.label ? empData.label : "";
    this.setState({ CrrEmpFilterLabel: label, showInputBox: Id })
  }

  handleLabelInput = (e) => {
    this.setState({ CrrEmpFilterLabel: e.target.value })
  }
  submitEmployeeFilterLabelChange = (id) => {
    let { CrrEmpFilterLabel } = this.state;
    let data = {
      "id": id,
      "label": CrrEmpFilterLabel
    }
    this.props.dispatch(surveyActions.updateEmployeeFilterLabel(data));
    this.setState({ showInputBox: "" })
    setTimeout(() => {
      this.setState({ CrrEmpFilterLabel: "" })
    }, 500)
  }

  onAddParameterModel = (check) => {
    this.setState({ showCreateParameterModal: check });

    this.onCreateNewBucket(check, "bucket")
  }

  onCreateNewBucket = (check, type) => {
    if (check) {
      if (type === "bucket") {
        this.setState({ CrrParaLibCollection: [], CurrBucketId: "" }, () => {
          this.handleClearParameterForm()
        });
      }
    }
    else {
      this.setState({ CrrParaLibCollection: [], CurrBucketId: "" }, () => {
        this.handleClearParameterForm()
      });
    }
  }

  handleNewParameterInput = (e) => {
    let { parameterFormData } = this.state;
    parameterFormData[e.target.name] = e.target.value
    this.setState({ parameterFormData });
  }

  handleLabelNameInput = (e) => {
    let { labelName } = this.state;
    labelName = e.target.value;
    // console.log("labelName.....02122022:", labelName);
    this.setState({ labelName });
  }

  handleLabelCollectionSubmit = () => {
    let { labelCollection, labelName } = this.state;
    labelCollection.push(labelName)
    this.setState({
      labelCollection
      , labelName: ""
    });
  }

  setCurrentIndex = (currentIndex) => {
    this.setState({ currentIndex: currentIndex });
    console.log('kkkkkkkkkk  ', currentIndex);
  }

  0 = (index) => {
    const { labelCollection } = this.state;
    labelCollection[index].splice(index, 1)

    console.log("labelCollection:::", labelCollection);

    this.setState({ labelCollection, currentIndex: index });
  }

  handleNewParameterTypeInput = (data) => {
    let { parameterFormData } = this.state;
    parameterFormData["parameterTypeId"] = data.id;
    parameterFormData["parameterTypeLevel"] = data.level;
    this.setState({ parameterFormData });
  }

  handleNewParameterSubmit = () => {
    let { parameterFormData, isBucket, CurrBucketId } = this.state;
    let { dashboard } = this.props;
    let { getParameterTypeListData } = dashboard;


    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 1000
    }

    if (isBucket) {

      let dataParaType = {
        "name": "",
        "label": ""
      };
      if (getParameterTypeListData && getParameterTypeListData.length > 0) {
        let getIndex = getParameterTypeListData.findIndex(prev => prev.level === 1);
        dataParaType = getParameterTypeListData[getIndex];
      }
      ////console.log("dataParaType--->",dataParaType)

      let data = {
        name: parameterFormData.name,
        label: parameterFormData.label,
        parameterTypeId: dataParaType.id,
        parameterTypeLevel: dataParaType.level,
        paraType: 1
      }

      this.props.dispatch(dashboardActions.createParameter(data, temp, true));
    }
    else {

      let dataParaType = {
        "name": "",
        "label": ""
      };
      if (getParameterTypeListData && getParameterTypeListData.length > 0) {
        let getIndex = getParameterTypeListData.findIndex(prev => prev.level === 2);
        dataParaType = getParameterTypeListData[getIndex];
      }
      let data = {
        name: parameterFormData.name,
        label: parameterFormData.label,
        parameterTypeId: dataParaType.id,
        parameterTypeLevel: dataParaType.level,
        parentId: CurrBucketId,
        paraType: 1
      }

      this.props.dispatch(dashboardActions.createParameter(data, temp, true));
    }


    this.setState({ showCreateParameterModal: false })

  }

  handleClearParameterForm = () => {
    let clearData = {
      "name": "",
      "label": "",
      "parameterTypeId": "",
      "parameterTypeLevel": ""
    }

    this.setState({ parameterFormData: clearData });
  }


  handleCollectParaLib = (data) => {
    let { CrrParaLibCollection } = this.state;

    let getIndex = CrrParaLibCollection.findIndex(prev => prev === data.id);
    if (getIndex === -1) {
      CrrParaLibCollection.push(data.id);
    }
    else {
      CrrParaLibCollection.splice(getIndex, 1);
    }

    this.setState({ CrrParaLibCollection });

  }


  handleSubmitCopyParaLibrary = () => {
    let { CrrParaLibCollection } = this.state;
    if (CrrParaLibCollection && CrrParaLibCollection.length > 0) {
      let data = {
        "paraIdList": CrrParaLibCollection
      }
      let temp = {
        "keyWord": "",
        "pageNo": 1,
        "size": 1000
      }
      this.props.dispatch(surveyActions.copyParaLibToParameter(data, temp));
    }
    this.setState({ showCreateParameterModal: false })
  }

  changeISBacket = (check) => {
    this.setState({ isBucket: check });
  }

  onSetBuckedtId = (value) => {
    this.setState({ CurrBucketId: value });
  }

  onAutoParameterUpdate = () => {

    this.setState({ currentSurveyPage: 1 });

    let dataX1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.employeesize2,
      "parameterTypeLevel": 1
    }

    this.props.dispatch(surveyActions.autoUpdateParameters({}, dataX1));
  }

  handleSidebar = (check) => {
    this.setState({ showSidebar: check })
  }
  handleMobileSidebar = (check) => {
    this.setState({ showMobileSidebar: check })
    this.setState({ showSidebar: check })
  }


  handlePageClick = (data) => {
    console.log("data  ", data);
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });

    let datatempemployee = {
      keyWord: "",
      pageNo: data.selected + 1,
      companyId: this.props.match.params.id,
      size: this.state.size,
    }
    this.props.dispatch(surveyActions.getEmployeeList(datatempemployee));
  }


  handleSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    this.setState({ size: newSize, page: 0, offset: 0 }, () => {
      let datatempemployee = {
        keyWord: "",
        pageNo: 1,
        companyId: this.props.match.params.id,
        size: newSize,
      };
      this.props.dispatch(surveyActions.getEmployeeList(datatempemployee));
    });
  }


  render() {

    let { survey, dashboard } = this.props;
    let { parameterList, parameterTotal, getParameterLibraryListbyParameterTypeData } = survey;
    let { getParameterTypeListData, getCompanyNameOnlyData } = dashboard;
    let { isBucket } = this.state;
    let { loading, getEmployeeColumnNameData, getEmployeeFilterListByCompanyIdData, getLanguageLabelData, getEmployeeListData } = survey;
    let { showEditDetails, showFilterNames, formDataFilter, updateformDataFilter, openEmpFilterModal2, CurrentLanguage, showSidebar, employeeData, } = this.state;

    let AllParameters = parameterList && parameterList.length > 0 ? parameterList.filter(prev => prev.parameterTypeLevel === 1) : [];
    let AllParameterTotal = parameterTotal;

    const { page, size, offset } = this.state;
    const startItem = page * size + 1;
    const endItem = Math.min((page + 1) * size, AllParameterTotal);

    function checkIndexTemplate(para) {
      let avoidTheseTemplates = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      let check = false;
      let templateId = para && para.template ? para.template : "";
      if (!(avoidTheseTemplates.includes(templateId))) {
        check = true;
      }
      return check
    }

    let EmployeeFilterCompanyList = []
    if (getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length > 0) {
      getEmployeeFilterListByCompanyIdData.forEach((filter) => {
        if (!filter.isDisable) {
          EmployeeFilterCompanyList.push(filter);
        }
      });
    }
    //--------------------------------------------------------------------------------

    let IndexList = []
    let pageCount = 7
    let IndexListIndex = []

    let AllParameterTotal1 = AllParameterTotal + 1;

    if (AllParameterTotal1) {
      if (AllParameterTotal1) {
        [...Array(AllParameterTotal1 + 1).keys()].forEach((item, index) => {
          if (index % pageCount === 0) {
            IndexList.push(index)
          }
          if (index === (AllParameterTotal1 - 1)) {
            IndexList.push(index)
          }
        })
      }

      // console.log('IndexList: ', IndexList)

      if (IndexList && IndexList.length > 0) {
        IndexList.forEach((item, index) => {
          if (index !== (IndexList.length - 1)) {
            IndexListIndex.push(index + 1)
          }
        })
      }
      // console.log('IndexListIndex: ', IndexListIndex)


    }
    //--------------------------------------------------------------------------------

    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    // console.log('moduleAccess: ', moduleAccess)


    let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    let EmpRole = "";
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        EmpRole = "ADMIN";
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }

    let { ClientProfileData } = this.props.dashboard;
    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageractionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }

    let EmployeeFieldList = []
    if (getEmployeeListData && getEmployeeListData.length > 0) {
      EmployeeFieldList = Object.keys(getEmployeeListData[0]);
    }
    // console.log('EmployeeFieldList: ', EmployeeFieldList)

    let NewEmployeeFieldList = []
    if (EmployeeFieldList && EmployeeFieldList.length > 0) {
      EmployeeFieldList.forEach((item) => {
        if (item != "isDisable" && item != "isTest" && item != "createdAt" && item != "id" && item != "actionPlanStage" &&
          item != "companyId" && item != "email" && item != "emp_id") {
          NewEmployeeFieldList.push(item);
        }
      });
    }

    let { getAllParameterListData } = this.props.dashboard;

    function GetParameterLabel(name) {
      let AllParameterObj = {};
      if (getAllParameterListData && getAllParameterListData.length > 0) {
        getAllParameterListData.forEach((item) => {
          AllParameterObj[item.name] = item && item.label ? item.label : item.name;
        });
      }
      return (AllParameterObj && AllParameterObj[name] ? AllParameterObj[name] : name);
    }

    function getModuleNameList(emp) {

      let ModuleNameList = [
        {
          "name": "Employee Wellness",
          "id": 0
        },
        {
          "name": "Employee Lifecycle",
          "id": 1
        },
        {
          "name": "Sense",
          "id": 2
        },
        {
          "name": "360 Feedback",
          "id": 3
        },
        {
          "name": "Pulse",
          "id": 4
        },
        {
          "name": "Customer Survey",
          "id": 5
        },

        {
          "name": "Assessment",
          "id": 6
        },
        {
          "name": "Employee Exit",
          "id": 7
        }
      ]


      console.log('---------> emp: ', emp);
      let finalOutput = []


      function noRepeat(name) {
        let getIndex = finalOutput.findIndex(prev => prev === name);
        if (getIndex !== -1) {
          return false
        } else {
          return true
        }
      }

      for (var i = 0; i < ModuleNameList.length; i++) {
        let moduleDetails = ModuleNameList[i];

        if (emp && emp.moduleType && emp.moduleType.length > 0) {
          console.log('---------> emp.moduleType: ', emp.moduleType);
          console.log('---------> emp.moduleDetails["id"]: ', moduleDetails["id"]);

          if (emp.moduleType.includes(moduleDetails["id"])) {
            console.log('---------> moduleDetails["name"]: ', moduleDetails["name"]);

            if (noRepeat(moduleDetails["name"])) {
              finalOutput.push(moduleDetails["name"])
            }

          }
        } else {

          if (noRepeat(ModuleNameList[0]["name"])) {
            finalOutput.push(ModuleNameList[0]["name"])
          }
          if (noRepeat(ModuleNameList[1]["name"])) {
            finalOutput.push(ModuleNameList[1]["name"])
          }

        }
      }

      return finalOutput
    }


    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text='Loading Data...'
          className="loader"
        >

          <div className='h-screen overflow-hidden bg-[#F9FAFB]'>

            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              props={this.props}
              companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}
              sidebarToggle={this.sidebarToggle}
            />

            <div className="flex w-full" style={{ height: 'calc(100% - 2rem)' }}>

              <Sidebar
                onOverview={this.onOverview}
                showSidebar={showSidebar}
                handleSidebar={this.handleSidebar}
                accessAllow={accessAllow}
                routeLink={this.routeLink}
                showMobileSidebar={this.state.showMobileSidebar}
                sidebarToggle={this.sidebarToggle}
              />
              <main className="flex-1 w-full overflow-hidden overflow-y-auto ">



                {accessAllow("uploadEmp") ?
                  <>

                    {employeeData ?

                      <div className="p-4 h-full overflow-hidden overflow-y-auto bg-[#F9FAFB]">
                        <div className="p-6 bg-white">
                          <div className="w-full lg:flex ">
                            {/* first */}
                            <div className="flex justify-center text-center lg:w-8/12 ">
                              <div className>
                                <div className="relative"><img src="img/profile/user1.png" className="my-4 rounded-full lg:w-40 w-36 h-36 lg:h-40" />
                                  <span className="absolute bottom-0 right-0 p-2 text-white bg-pink-500 rounded-full material-symbols-outlined" style={{ fontSize: '18px' }}>photo_camera</span> </div>
                                <h1 className="font-medium text-blue-500">{employeeData && employeeData.name ? employeeData.name : "Na"}</h1>
                                <p className="text-xs text-gray-500">{employeeData && employeeData.role ? employeeData.role : ""}</p>

                                {(employeeData && employeeData.role && (employeeData.role === "ADMIN")) ?
                                  <div onClick={() => this.onEditDetails(true, employeeData)} className="flex justify-center"><button className="flex items-center px-6 py-2 my-4 text-xs text-blue-500 border border-blue-500 rounded-full bg-blue-50 focus:outline-none">Edit Details <span className="pl-2 material-symbols-outlined" style={{ fontSize: '18px' }}>edit</span></button></div>
                                  : null}

                              </div>
                            </div>


                            {showEditDetails ?
                              <>
                                <div className="w-full md:px-6 lg:pr-4 lg:pl-8 ">
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">Email</label>
                                    <input type="text" name="email" value={employeeData.email}
                                      // onChange={this.handleEmpInput} 
                                      onChange={() => { }}
                                      disabled={true} placeholder="employee01mailinator.com" className="block w-full p-1 text-sm placeholder-black border text-slate-800" />
                                  </div>
                                  {/* 3 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">Employee Id</label>
                                    <input type="text" name="emp_id" value={employeeData.emp_id}
                                      // onChange={this.handleEmpInput} 
                                      onChange={() => { }}
                                      disabled={true} placeholder={194808} className="block w-full p-1 text-sm placeholder-black border text-slate-800" />
                                  </div>
                                  {/* 1 */}
                                  <div className="pb-4 ">
                                    <label className="block pb-2 text-xs text-gray-500">Mobile </label>
                                    <input type="text" name="mobile" value={employeeData.mobile}
                                      // onChange={this.handleEmpInput} 
                                      onChange={() => { }}
                                      disabled={true} placeholder="+91 XXXXXXXXXX" className="block w-full p-1 text-sm placeholder-black border text-slate-800" />
                                  </div>
                                </div>

                                <div className="w-full md:px-6 lg:px-4">

                                  {/* 2 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">Role</label>
                                    <div className="flex">
                                      {/* <div className=""><input checked={employeeData && employeeData.role==="ADMIN"?true:false} type="radio" onClick={()=>this.handleRoleInput("ADMIN")} onChange={()=>{}}/></div><div className="px-2 text-gray-500">ADMIN</div> */}
                                      <div className="pl-2"><input checked={employeeData && employeeData.role === "CROLE" ? true : false} type="radio" onClick={() => this.handleRoleInput("CROLE")} onChange={() => { }} /></div><div className="px-2 text-gray-500">CROLE</div>
                                      <div className="pl-2"><input checked={employeeData && employeeData.role === "MNGR" ? true : false} type="radio" onClick={() => this.handleRoleInput("MNGR")} onChange={() => { }} /></div><div className="px-2 text-gray-500">MNGR</div>
                                      <div className="pl-2"><input checked={employeeData && employeeData.role === "EMP" ? true : false} type="radio" onClick={() => this.handleRoleInput("EMP")} onChange={() => { }} /></div><div className="px-2 text-gray-500">EMP</div>
                                    </div>
                                  </div>
                                  {/* 3 */}
                                  <div className="pb-4 ">
                                    <label className="block pb-2 text-xs text-gray-500">Test User</label>
                                    <div className="flex">
                                      <div className=""><input type="radio" checked={employeeData && employeeData.isTest ? true : false} onClick={() => this.handleTestUserInput(true)} onChange={() => { }} /></div><div className="px-2 text-gray-500">Yes</div>
                                      <div className="pl-2"><input type="radio" checked={employeeData && employeeData.isTest ? false : true} onClick={() => this.handleTestUserInput(false)} onChange={() => { }} /></div><div className="px-2 text-gray-500">No</div>
                                    </div>
                                  </div>
                                </div>
                              </>
                              :
                              <>

                                <div className="w-full md:px-6 lg:pr-4 lg:pl-8 ">
                                  {/* 1 */}
                                  <div className="pb-4 ">
                                    <label className="block pb-2 text-xs text-gray-500">Employee Name:</label>
                                    <input placeholder={employeeData && employeeData.name ? employeeData.name : "NaN"} disabled={true} className="block w-full p-1 text-sm placeholder-black bg-white text-slate-800" />
                                  </div>
                                  {/* 2 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">Email:</label>
                                    <input placeholder={employeeData && employeeData.email ? employeeData.email : "NaN"} disabled={true} className="block w-full p-1 text-sm placeholder-black bg-white text-slate-800" />
                                  </div>
                                  {/* 3 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">Employee Id:</label>
                                    <input placeholder={employeeData && employeeData.emp_id ? employeeData.emp_id : "NaN"} disabled={true} className="block w-full p-1 text-sm placeholder-black bg-white text-slate-800" />
                                  </div>
                                  {/* 4 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">Mobile:</label>
                                    <input placeholder={employeeData && employeeData.mobile ? employeeData.mobile : "NaN"} disabled={true} className="block w-full p-1 text-sm placeholder-black bg-white text-slate-800" />
                                  </div>
                                  {/* 5 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">Gender:</label>
                                    <input placeholder={employeeData && employeeData.Gender ? employeeData.Gender : "NaN"} disabled={true} className="block w-full p-1 text-sm placeholder-black bg-white text-slate-800" />
                                  </div>
                                </div>
                                <div className="w-full md:px-6 lg:px-4">
                                  {/* 2 */}
                                  <div className="pb-4">
                                    <label className="block pb-2 text-xs text-gray-500">Role:</label>
                                    <input placeholder={employeeData && employeeData.role ? employeeData.role : "NaN"} disabled={true} className="block w-full p-1 text-sm placeholder-black bg-white text-slate-800" />
                                  </div>
                                  {/* 3 */}
                                  <div className="pb-4 ">
                                    <label className="block pb-2 text-xs text-gray-500">Test User:</label>
                                    <input placeholder={employeeData && employeeData.isTest ? "Yes" : "No"} disabled={true} className="block w-full p-1 text-sm placeholder-black bg-white text-slate-800" />
                                  </div>
                                </div>
                              </>

                            }

                          </div>

                          <div className="flex justify-center mt-4 lg:justify-end md:px-4">
                            {showEditDetails ? <button onClick={() => this.handleUpdateEmployeeSubmit()} className="px-6 py-2 mr-1 text-xs text-white uppercase bg-blue-500">update</button> : null}
                            {showEditDetails ? <button onClick={() => this.onEditDetails(false, employeeData)} className="px-6 py-2 ml-1 text-xs font-medium text-red-500 uppercase bg-white border-2 border-red-500 ">cancel</button> :
                              <button onClick={() => this.handleEmployeeDetails("")} className="px-6 py-2 ml-1 text-xs font-medium text-gray-500 uppercase bg-white border-2 ">close</button>}
                          </div>
                        </div>
                      </div>
                      :
                      <div className="h-full p-4 flex flex-col gap-4 overflow-hidden overflow-y-auto md:p-6" style={{ height: "calc(100% - 4rem)" }}>
                        <div className="flex flex-col p-4 space-y-4 overflow-hidden bg-white border rounded-lg md:p-6">
                          <div className="items-stretch justify-between space-y-4 md:flex md:space-x-6 md:space-y-0 ">
                            <div className="flex space-x-3 items-center">
                              <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]">Index and Dimension</h1>
                              <div className="flex space-x-2 capitalize items-center text-sm text-[#3D405B] font-medium">
                                <span>Show</span>
                                <form class="w-16">
                                  <select id="entries" value={size} onChange={this.handleSizeChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full px-2 py-1.5 focus:outline-none">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </form>
                                <span>entries</span>
                              </div>
                            </div>

                            <div className="flex space-x-4">
                              <div className="lg:w-auto w-full flex justify-between border px-4 text-[#3D405B]/70 bg-white rounded-md text-sm items-center  shadow-sm overflow-hidden">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /> <circle cx="10" cy="10" r="7" /> <line x1="21" y1="21" x2="15" y2="15" />
                                </svg>
                                <input type="search" name="search" onChange={this.handleSearchParameter} placeholder={LanguageObj && LanguageObj["search_by_email_text_" + CurrentLanguage] ? LanguageObj["search_by_email_text_" + CurrentLanguage] : "Search by email..."} className="lg:w-60 w-full focus:outline-none px-2 py-2 text-[#3D405B] placeholder:font-normal font-medium " />
                              </div>

                              <button onClick={() => this.onAutoParameterUpdate()}
                                className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2 " >
                                <span class="material-symbols-outlined md:block hidden group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" style={{ fontSize: "1.5rem" }}>
                                  settings_backup_restore
                                </span>
                                <span> Retrive Updates</span>
                              </button>

                              <div onClick={() => { this.onAddParameterModel(true); this.changeISBacket(true) }} className="cursor-pointer bg-[#2196F3] text-white space-x-1 text-sm  border-[#2196F3] flex justify-between px-4 items-center  py-2 rounded-md">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" >
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="9" />  <line x1="9" y1="12" x2="15" y2="12" />  <line x1="12" y1="9" x2="12" y2="15" /></svg><span>Create New Index</span>
                              </div>

                              {openEmpFilterModal2 ?
                                <AddFilter
                                  closeEmployeeFilter={this.closeEmployeeFilter2}
                                  onEmployeeFilterDropdown={this.onEmployeeFilterDropdown}
                                  handleEmployeeColumnSelect={this.handleEmployeeColumnSelect}
                                  handleFilterCheck={this.handleFilterCheck}
                                  createEmployeeFilter={this.createEmployeeFilter}
                                  onDeleteFilter={this.onDeleteFilter}
                                  handleFilterStatus={this.handleFilterStatus}

                                  showFilterNames={showFilterNames}
                                  formDataFilter={formDataFilter}
                                  getEmployeeColumnNameData={getEmployeeColumnNameData}
                                  EmployeeFilterCompanyList={EmployeeFilterCompanyList}
                                  LanguageObj={LanguageObj}
                                  CurrentLanguage={CurrentLanguage}
                                  getEmployeeFilterListByCompanyIdData={getEmployeeFilterListByCompanyIdData}
                                  handleFilterRank={this.handleFilterRank}
                                  updateformDataFilter={updateformDataFilter}
                                  updatehandleFilterRank={this.updatehandleFilterRank}

                                  onClickQuesUp={this.onClickQuesUp}
                                  onClickQuesDown={this.onClickQuesDown}
                                />
                                :
                                null}
                            </div>

                          </div>

                          {/* Table Start */}
                          <div className="w-full ">
                            <div className="min-w-full overflow-x-auto scroll-smooth whitespace-nowrap align-middle iinline-block">
                              <table className="min-w-full border">
                                <thead>
                                  <tr className='capitalize text-[#3D405B] border-b' >
                                    <th className="w-20 p-3 text-sm font-medium text-left border-r">{"Sno"}</th>
                                    <th className="w-20 p-3 text-sm font-medium text-left border-r">{"Index"}</th>
                                    <th className="p-3 text-sm font-semibold text-left border-r w-60 ">{"Dimensions"}</th>
                                    <th className="p-3 text-sm font-semibold text-left border-r w-60 ">{"Modules"}</th>
                                    <th className="w-40 p-3 text-sm font-semibold text-left border-r">{"Action"}</th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y">
                                  {AllParameters && AllParameters.length > 0 ?
                                    AllParameters.map((emp, index) =>
                                      <tr className='odd:bg-[#F9FAFB] even:bg-white text-sm'>
                                        <td className="p-3 whitespace-no-wrap border-r text-center">
                                          <div className="text-sm text-gray-800"> {(this.state.currentSurveyPage - 1) * this.state.size + (index + 1)}</div>
                                        </td>
                                        <td className="p-3 whitespace-no-wrap border-r">
                                          <div className="text-sm text-gray-800">{GetParameterLabel(emp.name)}</div>
                                        </td>
                                        <td className="p-3 whitespace-no-wrap border-r">
                                          <div className="text-gray-800 text-sm break-words flex-wrap overflow-hidden  lg:w-[32rem] md:w-40 w-40  ">
                                            {emp && emp.childList.length > 0 ? emp.childList.map((element) => GetParameterLabel(element.name)).join(", ") : "-"}
                                          </div>
                                        </td>

                                        <td className="p-3 whitespace-no-wrap border-r">
                                          <div className="text-gray-800 text-sm break-words flex-wrap overflow-hidden lg:w-[20rem] md:w-40 w-40  ">
                                            {getModuleNameList(emp) && getModuleNameList(emp).length > 0 ? getModuleNameList(emp).map((element) => element).join(", ") : "-"}
                                          </div>
                                        </td>


                                        <td className="p-3 text-sm font-medium leading-5 text-center whitespace-no-wrap border-r">
                                          {emp.isEditable ?
                                            <span onClick={() => { this.onAddParameterModel(true); this.changeISBacket(false); this.onSetBuckedtId(emp.id) }}
                                              className="p-2 text-gray-500 rounded-md cursor-pointer material-symbols-outlined hover:bg-blue-100 hover:text-blue-500" style={{ fontSize: "16px" }}>edit_square</span>
                                            :
                                            <span></span>
                                          }
                                        </td>


                                      </tr>
                                    ) : null
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>

                          {/* Pagination start */}
                          <div class="sm:flex sm:flex-1 sm:items-center items-center justify-center sm:justify-between">
                            <div className="text-center">
                              <p class="text-sm text-gray-700 md:pb-0 pb-2">
                                Showing
                                <span class="font-medium"> {startItem} </span>
                                to
                                <span class="font-medium"> {endItem} </span>
                                of
                                <span class="font-medium"> {AllParameterTotal} </span>
                                results
                              </p>
                            </div>
                            <div className="relative z-0 flex md:justify-end justify-center w-76">
                              {
                                AllParameterTotal && AllParameterTotal > 10 ?
                                  <ReactPaginate
                                    previousLabel={'Prev'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={AllParameterTotal / this.state.size}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={1}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-cls'}
                                    activeClassName={'active'}
                                  />
                                  : null}
                            </div>
                          </div>

                        </div>

                        {this.state.showCreateParameterModal ?
                          <CreateParameterModal
                            handleConfirmMailClose={this.onAddParameterModel}
                            parameterFormData={this.state.parameterFormData}
                            handleNewParameterInput={this.handleNewParameterInput}
                            getParameterTypeListData={getParameterTypeListData}
                            handleNewParameterTypeInput={this.handleNewParameterTypeInput}
                            handleNewParameterSubmit={this.handleNewParameterSubmit}
                            handleClearParameterForm={this.handleClearParameterForm}
                            isBucket={isBucket}
                            getParaLibList={getParameterLibraryListbyParameterTypeData}
                            handleCollectParaLib={this.handleCollectParaLib}
                            handleSubmitCopyParaLibrary={this.handleSubmitCopyParaLibrary}
                            changeISBacket={this.changeISBacket}

                            setCurrentIndex={this.setCurrentIndex}
                            labelName={this.state.labelName}
                            labelCollection={this.state.labelCollection}
                            handleLabelNameInput={this.handleLabelNameInput}
                            deleteLabelCollection={this.deleteLabelCollection}
                            handleLabelCollectionSubmit={this.handleLabelCollectionSubmit}
                          />
                          : null}

                      </div>


                    }







                    {/* {showEmployeeModal?
    <div className="fixed top-0 z-40 flex items-center justify-center w-full h-full xl:h-screen" style={{background: '#0000005c'}}>
        <div className="w-11/12 bg-white rounded-lg shadow-sm xl:w-5/12 lg:w-6/12 md:w-8/12">
          <div className="flex justify-between p-4 border-b"> <h2 className="text-base font-semibold text-center test-gray-800 ">Upload Employees</h2> <span
           onClick={()=>this.addEmployeeModal(false)} className="text-sm font-medium text-red-500 cursor-pointer">Close</span></div>
          <div className="m-4 mt-2">
            <div className="flex pb-2">
              <div className="text-gray-500">
                <span className="font-semibold cursor-pointer">Mandatory fields* : </span>
                <span className="text-blue-500">emp_id, email</span>
              </div>
              <div className="ml-12">
                <span className="text-red-500">{fileUploadError}</span>
              </div>
            </div>

            <div className="py-4 text-xs text-center text-gray-500 border-2 border-dotted rounded-md bg-gray-50"> <span className="text-blue-500 material-symbols-outlined" style={{fontSize: '42px'}}>cloud_upload</span>

              <div className="">
                <input type="file" className="ml-12 py-1.5 rounded-md focus:outline-none font-medium"
                 id="file" accept={SheetJSFT} onChange={this.handleChange} />
              </div>

              
            </div>
          </div>
          <div className="flex justify-between py-4 pr-4">
            <div className="text-xs ">
            </div>
            <button onClick={()=>this.downLoadSampleCSV()} className="mr-72 border border-green-500 rounded-md px-6 py-1.5 text-xs text-green-500 focus:outline-none" >Sample CSV</button>

            <button  onClick={this.handleFile} className="ml-22 border border-blue-500 rounded-md px-6 py-1.5 text-xs text-blue-500 focus:outline-none">Continue</button>
          </div>
        </div>
    </div>
:
null} */}



                    {/* EMPLOYEE FILTER */}
                    {/* {openEmpFilterModal?
  <AddFilter
  closeEmployeeFilter={this.closeEmployeeFilter} 
  onEmployeeFilterDropdown={this.onEmployeeFilterDropdown} 
  handleEmployeeColumnSelect={this.handleEmployeeColumnSelect} 
  handleFilterCheck={this.handleFilterCheck} 
  createEmployeeFilter={this.createEmployeeFilter} 
  onDeleteFilter={this.onDeleteFilter} 
  handleFilterStatus={this.handleFilterStatus} 

  showFilterNames={showFilterNames}
  formDataFilter={formDataFilter}
  getEmployeeColumnNameData={getEmployeeColumnNameData}
  EmployeeFilterCompanyList={EmployeeFilterCompanyList}
  LanguageObj={LanguageObj}
  CurrentLanguage={CurrentLanguage}
  getEmployeeFilterListByCompanyIdData={getEmployeeFilterListByCompanyIdData}
  handleFilterRank={this.handleFilterRank}
  updateformDataFilter={updateformDataFilter}
  updatehandleFilterRank={this.updatehandleFilterRank}

  onClickQuesUp={this.onClickQuesUp}
  onClickQuesDown={this.onClickQuesDown}

  />
:
null} */}

                  </>
                  :
                  <>
                    {!loading ?

                      <div className="container h-full px-4 py-12 mx-auto overflow-hidden overflow-y-auto" style={{ height: 'calc(100% - 4rem)' }}>
                        <div className="flex items-center justify-center w-full h-full">
                          {/*  text start*/}
                          <div className="text-center">
                            <img src="img/empty_survey.png" className="mb-4" />
                            <h1 className="text-xl font-semibold text-gray-500 cursor-default">You have no access <br /> to this module!</h1>
                          </div>
                        </div>
                      </div>
                      : null}
                  </>
                }





              </main>
            </div>
          </div>
        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(ParameterSetting);
