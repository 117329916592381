import React from 'react';
import DonutChart from './Charts/DonutChart';
import HorizontalBar from './Charts/HorizontalBar';
import HorizontalBarChart from './Charts/HorizontalBarChart';
import MatrixTable from './Charts/MatrixTable';
import NPSChart from './Charts/NPSChart';


export default function NPS(props) {
    
    let { item, name, stage } = props;

    ////console.log('item (NPS)',item)

    let AnswerObj = {};
    if(item && item.answerList && item.answerList.length>0){
      //console.log('item.answerList (STAR)',item.answerList)

      item.answerList.forEach((ans)=>{
            if(ans && ans.answerText){
              let ansX = ans.answerText;
                        if(AnswerObj && AnswerObj[ansX]){
                          AnswerObj[ansX] += (ans.count);
                        }
                        else{
                          AnswerObj[ansX] = (ans.count)
                        }
            }
      });
    }
    //console.log('AnswerObj (asassd)',AnswerObj)

    let { getQuestionMatrixResultData, questionsList, questionType, handleMatrixDetails, index } = props;





    return (
      <>	

      {questionType!=="matrix"?
      <>
            {/* Rating Scale */}
            {item && item.answerList[0] && item.answerList[0].template===5?
              <HorizontalBar AnswerObj={AnswerObj} name={name} templateId={5} questionName={item.name} stage={stage} index={index}/>
            :null}

            {/* NPS */}
            {item && item.answerList[0] && item.answerList[0].template===6?
              <NPSChart AnswerObj={AnswerObj} name={name} templateId={6} questionName={item.name} stage={stage} index={index}/>
            :null}

            {/* YES/NO */}
            {item && item.answerList[0] && item.answerList[0].template===11?
              <DonutChart AnswerObj={AnswerObj} name={name} templateId={11} questionName={item.name} stage={stage} index={index}/>
            :null}

            {/* SingleChoice */}
            {item && item.answerList[0] && item.answerList[0].template===1?
              <HorizontalBarChart AnswerObj={AnswerObj} name={name} templateId={1} questionName={item.name} stage={stage} index={index}/>
            :null}

            {/* Multiple Choice */}
            {item && item.answerList[0] && item.answerList[0].template===2?
              <HorizontalBarChart AnswerObj={AnswerObj} name={name} templateId={2} questionName={item.name} stage={stage} index={index}/>
            :null}

    </>
    :
    <>
            {/* Matrix */}
            {getQuestionMatrixResultData && getQuestionMatrixResultData.length>0?
              <MatrixTable stage={stage} 
              questionsList={questionsList}
              getQuestionMatrixResultData={getQuestionMatrixResultData} 
              renderType={questionType}
              handleMatrixDetails={handleMatrixDetails}
              />
            :null}
    </>
    }

      </>
    );
  }
