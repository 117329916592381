import React, { useState } from 'react';
import moment from 'moment';
import { CONST } from '../../../../_config';
import { TbMail } from 'react-icons/tb';
export default function CampaignList(props) {
  let { IsAutomatic, getCampaignDetailsByIdData, getCampaignActiveLaunchDateWiseData, handleReminderActiveStatus, getCampaignActiveHistoryData, handleRecDaysActive, isRecursiveDays, isRiskSurvey, gotoRecursiveDashboard, onCreateCampaign, campaignList, showCampaignOptions, chooseCampaignOption, handleCampaignOptions, LanguageObj, CurrentLanguage } = props;
  ////console.log('getCampaignActiveHistoryData c: ', getCampaignActiveHistoryData)

  let getCampaignActiveLaunchDateWiseList = []
  if (getCampaignActiveLaunchDateWiseData && getCampaignActiveLaunchDateWiseData.length > 0) {
    getCampaignActiveLaunchDateWiseData.forEach((item) => {
      let getIndex = getCampaignActiveLaunchDateWiseList.findIndex(prev => prev.day === moment(new Date(parseInt(item._id))).utcOffset("+05:30").format("DD MMM YYYY"));
      if (getIndex === -1) {
        ////console.log("item.count-1-------->", item.count)
        getCampaignActiveLaunchDateWiseList.push({
          "day": moment(new Date(parseInt(item._id))).utcOffset("+05:30").format("DD MMM YYYY"),
          "count": Number(item.count)
        })
      } else {
        ////console.log("item.count-2-------->", item.count)
        getCampaignActiveLaunchDateWiseList[getIndex]["count"] += Number(item.count)
      }

    })
  }

  const [copied, setCopied] = useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000); // Change the duration as needed
  };


  return (
    <div className='p-4 space-y-6 md:p-6 ' >
      <div className='flex flex-col justify-between gap-6 md:flex-row '>
        {!isRecursiveDays ?
          <div className='flex items-center space-x-4'> 
            <h2 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Campaigns</h2>
          </div>
          :
          <h2 className="py-2 text-xl font-medium ">Recursive Day</h2>
        }
        <div className="">
          {!isRecursiveDays ?
            <button onClick={() => onCreateCampaign(1)} className="py-2.5 px-4 text-sm md:w-auto w-full rounded-md font-medium flex items-center bg-[#2196F3] text-white justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg> <span>Create Campaign</span></button>

            : null}
        </div>
      </div>


      <div className='space-y-2 '>
        {campaignList && campaignList.length > 0 ?
          campaignList.map((item, index) =>
            <div className="relative flex flex-col w-full gap-4 p-4 bg-white border rounded-md md:flex-row md:items-center md:justify-between md:gap-10 md:p-6 ">

              <div className='flex flex-col items-start w-full gap-4 md:flex-row md:items-center' >
                <div>
                  <div className="md:w-14 md:h-14 w-12 h-12 flex justify-center items-center text-[#2196F3] bg-[#2196F3]/10 rounded-full">
                    <TbMail strokeWidth={1.5} className='md:text-[2rem] text-[1.5rem]' />
                  </div>
                </div>

                <div className='w-full space-y-1 md:w-60'>
                  <div className=" text-sm font-semibold text-left text-[#3D405B] ">
                    <h1 className='block text-base capitalize md:text-lg line-clamp-1'>{item.title}</h1>
                    <span  >
                      {item.isTest ?
                        <span className="block px-2 py-1 mx-2 font-normal text-green-500 rounded-full bg-green-50">Test</span>
                        : null}
                    </span>
                  </div>
                  <div className="text-sm font-medium text-[#212121]/80">{item.teamname}</div>
                  <div className='flex flex-row gap-2 md:hidden md:flex-col '>
                    <p className=" text-sm font-medium text-[#3D405B]/70 ">
                      {LanguageObj && LanguageObj["created_date_" + CurrentLanguage] ? LanguageObj["created_date_" + CurrentLanguage] : "Created Date"}</p>
                    <p className="text-sm font-medium text-gray-800 whitespace-no-wrap ">
                      {moment.unix(item.createdAt / 1000).format(" DD MMM, YYYY , HH:mm")}
                    </p>
                  </div>
                </div>
              </div>


              <div className='flex-row flex-shrink-0 hidden gap-2 md:flex md:flex-col '>
                <p className=" text-sm font-medium text-[#3D405B]/70 ">
                  {LanguageObj && LanguageObj["created_date_" + CurrentLanguage] ? LanguageObj["created_date_" + CurrentLanguage] : "Created Date"}</p>
                <p className="text-base font-medium text-gray-800 whitespace-no-wrap ">
                  {moment.unix(item.createdAt / 1000).format(" DD MMM, YYYY , HH:mm")}
                </p>
              </div>


              {item.campaign_type === "KIOSK" ?
                <>
                  <div className="relative inline-block dropdown">

                    <div className="space-y-2 overflow-hidden text-gray-500 rounded-md dropdown-menu">
                      <span className=" text-[#3D405B]/70 text-sm font-medium">Survey Link</span>
                      <div className="relative flex items-center border rounded-md ">
                        <input value={CONST.SURVEY_URL + item.code} disabled={true} className="px-4 w-96 overflow-hidden rouned-lg text-sm font-medium text-[#3D405B] bg-transparent" />
                        <button onClick={() => handleCopy(CONST.SURVEY_URL + item.code)} className='bg-[#F9FAFB] hover:bg-slate-100 transition-all duration-150   px-2 py-1.5 border-l text-[#212121]'>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path>
                            <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
                          </svg>
                        </button>
                        {copied && <span className="absolute right-0 p-2 ml-2 text-xs text-green-500 bg-white border rounded-md -top-8 ">Copied!</span>}
                      </div>

                    </div>


                  </div>
                </>
                : null}

              {isRecursiveDays ?
                <>
                  <div className='flex'>
                    <span className='px-2 text-base'>{item.isNotActive ? "Not Active" : "Active"} </span>

                    <span className='mt-1'>

                      {!item.isNotActive ?
                        <>
                          <div onClick={() => handleRecDaysActive(item.id)} className="rounded-full w-8 h-4 p-0.5 bg-green-400">
                            <div className="w-3 h-3 mx-auto duration-300 ease-in-out transform translate-x-2 bg-white rounded-full" />
                          </div>
                        </>
                        :
                        <>

                          <div onClick={() => handleRecDaysActive(item.id)} className="rounded-full w-8 h-4 p-0.5 bg-gray-200">
                            <div className="w-3 h-3 mx-auto duration-300 ease-in-out transform -translate-x-2 bg-white rounded-full" />
                          </div>
                        </>
                      }

                    </span>


                  </div>

                </>
                : null}

              <div className="absolute z-10 inline-block md:relative dropdown md:top-0 top-4 md:right-0 right-4">
                <span onClick={() => handleCampaignOptions(item.id)} title="Action" className="p-1.5 text-[#3D405B]/70 rounded-full cursor-pointer material-symbols-outlined hover:bg-[#ECEFF1] md:text-[1.7rem] text-[1.5rem]"  >more_vert</span>

                {showCampaignOptions === item.id ?
                  <div className="absolute top-0 overflow-hidden text-gray-500 bg-white rounded-md shadow-xl dropdown-menu right-6">
                    <span onClick={() => chooseCampaignOption(item, 1)} className="block px-4 py-2 text-xs cursor-pointer hover:bg-gray-100 whitespace-nowrap "> Campaign Response Analytics</span>
                    <span onClick={() => chooseCampaignOption(item, 2)} className="block px-4 py-2 text-xs cursor-pointer hover:bg-gray-100 whitespace-nowrap "> Campaign Reminder</span>

                    {item.recursiveType === "RECURSIVE_NORMAL" ?
                      <>

                        {isRiskSurvey ?
                          <span onClick={() => gotoRecursiveDashboard(item, "rtype2")} className="block px-4 py-2 text-xs cursor-pointer hover:bg-gray-100 whitespace-nowrap "> Recursive Dashboard</span>
                          :
                          <>
                            <span onClick={() => gotoRecursiveDashboard(item, "rtype1")} className="block px-4 py-2 text-xs cursor-pointer hover:bg-gray-100 whitespace-nowrap "> Recursive Dashboard T1</span>
                            <span onClick={() => gotoRecursiveDashboard(item, "rtype2")} className="block px-4 py-2 text-xs cursor-pointer hover:bg-gray-100 whitespace-nowrap "> Recursive Dashboard T2</span>
                          </>
                        }

                      </>
                      : null}

                  </div>
                  :
                  null}

              </div>

            </div>

          )
          : null}

      </div>


      {isRecursiveDays ?
        <>
          <div className='p-4 mt-8 space-y-6 bg-white border rounded shadow-md md:p-6' >

            <h1 className='text-xl font-medium '>Campaign Launch History</h1>

            <table className='w-full border border-collapse ' >
              <tr>
                <th className='p-2 font-medium border-r' >S.n.</th>
                <th className='p-2 font-medium border-r' >Date</th>
                {/* <th className='p-2 font-medium border-r' >Time</th> */}
                <th className='p-2 font-medium border-r' >No. of Employees</th>
              </tr>
              {getCampaignActiveLaunchDateWiseList && getCampaignActiveLaunchDateWiseList.length > 0 ?
                getCampaignActiveLaunchDateWiseList.map((item, index) =>

                  <tr className='p-2 bg-white border-t '>
                    {/* <span className='w-12 px-4'>{(index + 1).toString() + ") "}</span> */}
                    <td className='p-2 font-medium text-center text-gray-800 border-r' >{(index + 1).toString() + ""}</td>
                    <td className='p-2 text-center text-gray-600 border-r' ><span className='px-4'> {item.day}</span></td>
                    {/* <td className='p-2 text-center text-gray-600 border-r' >3:45 am </td> */}
                    <td className='p-2 text-center text-gray-600 border-r' ><span className='px-4'>{item.count}</span></td>
                  </tr>

                ) : null}
            </table>

          </div>


          <div className='p-4 mt-8 space-y-6 bg-white border rounded shadow-md md:p-6' >

            <h1 className='text-xl font-medium '>Campaign Status History</h1>

            <table className='w-full border border-collapse ' >
              <tr>
                <th className='p-2 font-medium border-r' >S.n.</th>
                <th className='p-2 font-medium border-r' >Date</th>
                {/* <th className='p-2 font-medium border-r' >Time</th> */}
                <th className='p-2 font-medium border-r' >Status</th>
              </tr>
              {getCampaignActiveHistoryData && getCampaignActiveHistoryData.length > 0 ?
                getCampaignActiveHistoryData.map((item, index) =>

                  <tr className='p-2 bg-white border-t '>
                    {/* <span className='w-12 px-4'>{(index + 1).toString() + ") "}</span> */}
                    <td className='p-2 font-medium text-center text-gray-800 border-r' >{(index + 1).toString() + ""}</td>
                    <td className='p-2 text-center text-gray-600 border-r' ><span className='px-4'> {moment(new Date(parseInt(item.createdAt))).utcOffset("+05:30").format("DD MMM YYYY, hh:mm")}</span></td>
                    {/* <td className='p-2 text-center text-gray-600 border-r' >3:45 am </td> */}
                    <td className='p-2 text-center text-gray-600 border-r' ><span className={item.activeStatus ? "px-4 text-green-500" : "px-4 text-red-500"}>{item.activeStatus ? "Active" : "In Active"}</span></td>
                  </tr>

                ) : null}
            </table>

          </div>
        </>
        : null}
    </div>
  );
}
