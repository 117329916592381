import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let { IndexLabels, getIndexData,showMultiple, IndexForManagerData,ManagerListDasboardDataObj, DynaObj, EmpFilterData }= this.props;

    //console.log('IndexLabels: ',IndexLabels);

    let DataValues = []
    let list = []
    let overallData = {}
    let managerTeam = {}
    let VariableData = {}

    
    if(true){

        if(IndexLabels && IndexLabels.length>0){
          IndexLabels.forEach((item)=>{

            if(getIndexData[item.indexType]){
              overallData[item.indexType]=getIndexData[item.indexType].toFixed(2)
              overallData["name"]="Overall Score";


            }

            if(IndexForManagerData[item.indexType]){
              managerTeam[item.indexType]=IndexForManagerData[item.indexType].toFixed(2)
              managerTeam["name"]="Team Score";
            }


            // VariableData[item.indexType]=getManagerListDomainFilterData[item.indexType]
            // VariableData["name"]="Department Score";

          })


                  // list.push(VariableData)
        list.push(overallData)
        list.push(managerTeam)
        }





        //console.log('managerTeam: ',managerTeam);


        let NewData = []
        if(EmpFilterData && EmpFilterData.length>0 && DynaObj && DynaObj.length>0){
          EmpFilterData.forEach((emp)=>{
              DynaObj.forEach((item)=>{
                console.log('item emp: ',item,emp);
                // console.log('DynaObj: ',DynaObj);

                if(item && item.name && item.name[0]){
                  
                  if(emp.name===item.name[0]){
                    NewData.push(item);
                  }
                }

              });
          })
        }
        // console.log('EmpFilterData: ',EmpFilterData);
        // console.log('DynaObj: ',DynaObj);
        // console.log('NewData: ',NewData);


        if(NewData && NewData.length>0){
          NewData.forEach((item)=>{
            if(item.name[0] !== "ManagerPID"){

              let Data = {}

              if(IndexLabels && IndexLabels.length>0){
                IndexLabels.forEach((label)=>{
                  Data[label.indexType] = item && item[label.indexType] ? item[label.indexType].toFixed(2):'NaN'
                })
              }

              Data["name"] = item && item.name && item.name[0]? item.name[0]:'' ;
              
              list.push(Data)

            }
          })
        }

        console.log('NewData: ',NewData);


    }


    
    let XAxis= []
    let XAxisName= []

    if(IndexLabels && IndexLabels.length>0){
      IndexLabels.forEach((item)=>{
        XAxis.push(item.indexName);
        XAxisName.push(item.indexType);
      })
    }

    let YAxis= []

      if(list && list.length>0){
        list.forEach((driver)=>{
          YAxis.push(driver.name)
        })
      }

      // //console.log('XAxis: ',XAxis)
      //console.log('YAxis: ',YAxis)
      // //console.log('DataValues: ',DataValues)
      // //console.log('list: ',list)





      // let XAxis = []


      // if(EmpFilterData && EmpFilterData.length>0){
      //   EmpFilterData.forEach((emp)=>{
      //     XAxis.push(emp.label)
      //   })
      // }

      //console.log('IndexLabels: ',IndexLabels)
      //console.log('asdadaslist: ',list)




      function getColor(data, index) {
        let Color = '';
        if(data.name==="Overall Score"){
          return Color = '#bdddf2'
        }
        else{
          if(parseFloat(overallData[index]) <= parseFloat(data[index])){
            return Color = '#b2edc9'
          }
          else if(parseFloat(overallData[index]) === parseFloat(data[index])){
            return Color = 'yellow'
          }
          else{
            return Color = '#f2bdbd'
          }
        }

      }


      let IndexNameList = [
        {
          index:1,
          name:'Happiness',
          color:'#FF4081'
        },
        {
          index:2,
          name:'Engagement',
          color:'#2196F3'

        },
        {
          index:4,
          name:'Stress',
          color:'#EF5350'

        },
        {
          index:5,
          name:'Manager',
          color:'#9C27B0'

        },
      ]
            
      


      let List2x = []
      if(ManagerListDasboardDataObj && ManagerListDasboardDataObj.length>0){
        List2x.push(overallData);
        ManagerListDasboardDataObj.forEach((item)=>{
          let data2x = {}
          if(IndexLabels && IndexLabels.length>0){
            IndexLabels.forEach((valueIndex)=>{
              console.log('valueIndex.indexType',item.ManagerData,valueIndex.indexType)
              data2x[valueIndex.indexType]=item.ManagerData[valueIndex.indexType].toFixed(2)
            });
          }
          data2x["name"]=item.ManagerName;
          List2x.push(data2x);
        })
      }


      //console.log('showMultiple--->',showMultiple)
      let ListFinal = [];
      if(showMultiple){
        ListFinal = List2x;
      }
      else{
        ListFinal = list;
      }

    console.log('ListFinal: ',ListFinal)
    console.log('list: ',list)


    return (
      <>    
    <div className="pb-6">
    {IndexNameList && IndexNameList.length>0?
      IndexNameList.map((IndexItem,Index)=>

      <div key={Index} className="bg-white rounded w-full shadow-lg my-2">

      <div style={{height:'230px'}} className="flex">
        <div className="text-center pt-10" style={{
          width:'20%',
          height:'100%',
          background:IndexItem.color,

        }}>
          <div style={{fontSize:'25px',paddingTop:'5px',color:'white'}} >{IndexItem.name}</div>
          <div className="text-white" style={{fontSize:overallData && overallData[IndexItem.index]?'40px':"25px"}}>{overallData && overallData[IndexItem.index]?overallData[IndexItem.index]:'Unavailable'}</div>
          <div className="text-white" style={{fontSize:'15px'}}>Overall</div>

        </div>

          <div className="flex" style={{
          width:'80%'
        }}>
          
        <div className={ListFinal && ListFinal.length>0 && ListFinal.length>5?"flex overflow-x-auto":"flex"}>

          {ListFinal && ListFinal.length>0?
          ListFinal.map((item)=>
          (item && item.name !== "Overall Score") && (item && item[IndexItem.index])?
              <div className="Happier-box1" style={{width:'45px', margin:("20px 60px ")}}>
              <div className="percent ">
              <svg>
                  <circle cx={60} cy={60} r={60} />
                  <circle cx={60} cy={60} r={60}
                  style={{
                        stroke:(overallData[IndexItem.index]<item[IndexItem.index])?'#22c968':'#c7323e',
                        strokeDashoffset:540 - (540 * ((parseInt(item[IndexItem.index])*100)/100))/ 100 }} />
                  </svg>
                  <div className="number cursor-default" style={{marginTop:'-10px',marginLeft:'-10px'}}>
                  <h1 className="text-lg font-semibold">{(item[IndexItem.index])}%</h1>
                  </div>
              </div>
              <div className=" text-gray-700 font-semibold text-sm" style={{marginLeft:"20px", marginTop:"-15px",width:'90px'}}>{item.name}</div>
            </div>
         :null
         
         )
          :null}



          </div>     

        </div>
      </div>


      </div>
)
:null}
</div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
