import React from 'react';

const CustomHorizontalBar = (props) => {
  let { ele, EditDetailsFn, getAssessmentDimensionsData } = props;


  function getColor(dimension){
    let colorCode = "#757575";
    if(getAssessmentDimensionsData && getAssessmentDimensionsData.length>0){
      let getIndex = getAssessmentDimensionsData.findIndex(prev=>prev.dimension === dimension);
      if(getIndex!==-1){
        colorCode = getAssessmentDimensionsData[getIndex]["colorCode"];
      }
    }
    return colorCode;
  }


  //console.log("getAssessmentDimensionsData-------->", getAssessmentDimensionsData)
  let list = [];
  if (Object.keys(ele) && Object.keys(ele).length > 0) {
    //console.log("el2e-------->", Object.keys(ele))
    let arrayEle = Object.keys(ele);

    const index = arrayEle.indexOf("emp_id");
    if (index > -1) {
      arrayEle.splice(index, 1);
    }

    const index2 = arrayEle.indexOf("name");
    if (index2 > -1) {
      arrayEle.splice(index2, 1);
    }

    for (var i = 0; i < arrayEle.length; i++) {
      let rankNumber = parseInt(arrayEle[i].replace('Rank', ''));
      let dimension = ele["Rank" + rankNumber.toString()]

      if (rankNumber) {
        list.push({
          dimension: dimension,
          rank: rankNumber,
          colorCode: getColor(dimension)
        });
      }
    }


  }
  //console.log("list-------->", list)

  if (list && list.length > 0) {
    list.sort((a, b) => a.rank - b.rank);
  }


  return (<>
    <div className='flex  w-full divide-x divide-white'>
      {list && list.length > 0 ?
        list.map((element, index3) =>
          (index3 < 5) ?
            <div
              style={{
                background:
                  element && element.colorCode
                    ? element.colorCode
                    : "#DDDDDD",
              }}
              className={
                "w-full px-6 py-2 text-base font-medium flex text-white text-center justify-center  "
              }>{element.rank}. {EditDetailsFn(element, "dimension", element.dimension)}</div>
            : null) : null}
    </div>
  </>);
};

export default CustomHorizontalBar;
