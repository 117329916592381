import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

// import MngrIntroduction from "./Components/MngrIntroduction";
// import MngrTopics from "./Components/MngrTopics";
// import MngrMethodology from "./Components/MngrMethodology";
// import MngrIndex from "./Components/MngrIndex";
// import LeaderIndex from "./Components/LeaderIndex";
// import MngrQuestion from "./Components/MngrQuestion/MngrQuestion";
// import MngrWeContinue from "./Components/MngrWeContinue";
// import MngrActionPlanStatic1 from "./Components/MngrActionPlanStatic1";
// import MngrActionPlanStatic2 from "./Components/MngrActionPlanStatic2";
// import Header from "./Components/Header";
// import NPSChart from "./Components/NPS/NPSChart";

import { CSVLink } from "react-csv";

// import OrgQuestion from "./Components/MngrQuestion/OrgQuestion";

import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

import PageTemplate from './PageTemplate';

import IntroPage from "./ReportPages/IntroPage";
import TableOfContent from "./ReportPages/TableOfContent";
import Methodology from "./ReportPages/Methodology";

import ScoreCard from "./ReportPages/ScoreCard";
import ScoreCardMix from "./ReportPages/ScoreCardMix";

import ScoreCardV2 from "./ReportPages/ScoreCardV2";

import QuestionScore from "./ReportPages/QuestionScore";
import QuestionDetailScore from "./ReportPages/QuestionDetailScore";

import ContinuePage from "./ReportPages/ContinuePage";
import ActionPlanStatic1 from "./ReportPages/ActionPlanStatic1";
import ActionPlanStatic2 from "./ReportPages/ActionPlanStatic2";
import ActionPlanStatic3 from "./ReportPages/ActionPlanStatic3";
import GuidingPrinciple from "./ReportPages/GuidingPrinciple";
import GuidingQuestions from "./ReportPages/GuidingQuestions";
import CriticalQuestions from "./ReportPages/CriticalQuestions";
import IdentificationStrength from "./ReportPages/IdentificationStrength";
import PresentYourAction from "./ReportPages/PresentYourAction";
import NPSChart from "./ReportPages/NPSChart";
import NPSChartScore from "./ReportPages/NPSChartScore";
import StrengthsOpportunity from "./ReportPages/StrengthsOpportunity";
import OverviewMethodology from "./ReportPages/OverviewMethodology";
import FirstImpression from "./ReportPages/FirstImpression";

import ScoreCardPage2 from "./ReportPages/ScoreCardPage2";

// const pdfExportComponent = React.useRef(null);

class ReportModal extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    this.state = {
      showUploadBox: false,
      showManagerDropdown: false,
      CurrentManagerData: "",
      reportFormData: {
        all_sections: false,

        mngr_dashboard: false,

        mngr_happiness_question: false,
        mngr_happiness_driver: false,

        mngr_engagement_question: false,
        mngr_engagement_driver: false,

        mngr_stress_question: false,
        mngr_stress_driver: false,

        mngr_manager_question: false,
        mngr_manager_driver: false,
      },

      isContinue: false,
      managerSearchName: "",
      showManagerDownloadStatus: false,
      showReportNow: false,
    };
  }

  componentDidMount() { }

  handleUploadCheck = (check) => {
    ////console.log('handleUploadCheck');
    this.setState({ showUploadBox: !check });
  };

  showManagerListDropdown = (check) => {
    ////console.log('check: ', check)

    this.setState({ showManagerDropdown: !check });
  };

  selectManager = (mngr) => {
    this.setState({ CurrentManagerData: mngr });
    this.showManagerListDropdown(true);
  };

  handleSearchManager = (e) => {
    this.setState({ managerSearchName: e.target.value });
  };

  selectReportSections = (name, value) => {
    let { reportFormData } = this.state;
    if (name === "all_sections") {
      let data = {
        all_sections: !value,

        mngr_dashboard: !value,

        mngr_happiness_question: !value,
        mngr_happiness_driver: !value,

        mngr_engagement_question: !value,
        mngr_engagement_driver: !value,

        mngr_stress_question: !value,
        mngr_stress_driver: !value,

        mngr_manager_question: !value,
        mngr_manager_driver: !value,
      };
      this.setState({ reportFormData: data });
    } else {
      reportFormData[name] = !value;
      reportFormData["all_sections"] = false;
      this.setState({ reportFormData });
    }
  };

  handleCollectAPI = (validateFn) => {
    let { SelectManager, getReportMngrQuestion, getReportMngrDriver } =
      this.props;
    let { CurrentManagerData, reportFormData } = this.state;

    if (CurrentManagerData) {
      if (reportFormData["mngr_dashboard"]) {
        SelectManager(CurrentManagerData, CurrentManagerData.name);
      }
      //Happiness---------------------------------------------------------------
      if (reportFormData["mngr_happiness_question"] && validateFn(1)) {
        getReportMngrQuestion(CurrentManagerData, 1);
        ////console.log('mngr_happiness_question')
      }
      if (reportFormData["mngr_happiness_driver"] && validateFn(1)) {
        getReportMngrDriver(CurrentManagerData, 1);
        ////console.log('mngr_happiness_driver')
      }
      //Engagement---------------------------------------------------------------
      if (reportFormData["mngr_engagement_question"] && validateFn(2)) {
        getReportMngrQuestion(CurrentManagerData, 2);
        ////console.log('mngr_engagement_question')
      }
      if (reportFormData["mngr_engagement_driver"] && validateFn(2)) {
        getReportMngrDriver(CurrentManagerData, 2);
        ////console.log('mngr_engagement_driver')
      }
      //Stress---------------------------------------------------------------
      if (reportFormData["mngr_stress_question"] && validateFn(4)) {
        getReportMngrQuestion(CurrentManagerData, 4);
        ////console.log('mngr_stress_question')
      }
      if (reportFormData["mngr_stress_driver"] && validateFn(4)) {
        getReportMngrDriver(CurrentManagerData, 4);
        ////console.log('mngr_stress_driver')
      }
      //Manager---------------------------------------------------------------
      if (reportFormData["mngr_manager_question"] && validateFn(5)) {
        getReportMngrQuestion(CurrentManagerData, 5);
        ////console.log('mngr_manager_question')
      }
      if (reportFormData["mngr_manager_driver"] && validateFn(5)) {
        getReportMngrDriver(CurrentManagerData, 5);
        ////console.log('mngr_manager_driver')
      }

      this.handleClosePreview(true);
    }
  };

  handleClosePreview = (value) => {
    this.setState({ isContinue: value });
  };

  closeReportModal = (value) => {
    let { onCloseManagerReportModal } = this.props;
    onCloseManagerReportModal(value);
    let tempreportFormData = {
      mngr_dashboard: false,

      mngr_happiness_question: false,
      mngr_happiness_driver: false,

      mngr_engagement_question: false,
      mngr_engagement_driver: false,

      mngr_stress_question: false,
      mngr_stress_driver: false,

      mngr_manager_question: false,
    };
    this.setState({ reportFormData: tempreportFormData });
  };

  exportPDFWithComponent = () => {
    if (this.pdfExportComponent.current) {
      this.pdfExportComponent.current.save();
    }
  };

  loadManagerReport = () => {
    let { CurrentManagerData } = this.state;
    let { onLoadManagerReport } = this.props;

    ////////console.log("--->CurrentManagerData-->", CurrentManagerData)

    if (CurrentManagerData) {
      onLoadManagerReport(CurrentManagerData);

      this.handleClosePreview(true);
    }
    this.setState({ showReportNow: false });
    setTimeout(() => {
      this.setState({ showReportNow: true });
    }, 7000);
  };

  onShowDownloadStatus = (check) => {
    if (check) {
      let { onManagerDownloadStatus } = this.props;
      onManagerDownloadStatus();
    }
    this.setState({ showManagerDownloadStatus: check });
  };

  render() {
    let {
      getMngrTeamSizeData,
      CurrentSurveyId,
      getAllBenchMarkStaticData,
      getDemographicIndexEngagementV2Data,
      getDemographicIndexManagerV2Data,
      getQuestionOthersResultData,

      getDemographicIndexOrgData,

      allowSpecificFn,

      GetParameterLabel,
      getDemographicIndexForManagerData,

      getHappinessQuestionData,
      getHappinessManagerQuestionData,
      getHappinessDMGQuestionData,

      getEngagementQuestionData,
      getEngagementManagerQuestionData,
      getEngagementDMGQuestionData,

      getStressQuestionData,
      getStressManagerQuestionData,
      getStressDMGQuestionData,

      IndexDimension,

      getManagerQuestionData,
      getManagerManagerQuestionData,
      getManagerDMGQuestionData,

      getLeaderQuestionData,
      getLeaderManagerQuestionData,
      getLeaderDMGQuestionData,

      getDemographicIndexHappinessData,
      getDemographicIndexEngagementData,
      getDemographicIndexStressData,
      getDemographicIndexManagerData,
      getDemographicIndexLeaderData,

      QuesMngrHappinessReportObject,
      QuesMngrEngagementReportObject,
      QuesMngrStressReportObject,
      QuesMngrManagerReportObject,

      DriverMngrHappinessReportObject,
      DriverMngrEngagementReportObject,
      DriverMngrStressReportObject,
      DriverMngrManagerReportObject,

      getHappinessIndexDriverData,
      getEngagementIndexDriverData,
      getStressIndexDriverData,
      getManagerIndexDriverData,

      getHappinessIndexByFilterData,
      getEngagementIndexByFilterData,
      getEngagementIndexByFilterV2Data,
      getStressIndexByFilterData,

      getManagerIndexByFilterData,
      getManagerIndexByFilterV2Data,

      getLeaderIndexByFilterData,

      surveyLogo,

      getOverallScoreBySurveyIdData,

      DynaObj,
      QuesDynaObj,
      EmpFilterData,
      getIndexData,
      getQuestionReportData,
      validateList,

      ManagerReportFConfig,

      getMngrReportDownloadStatusData,
      getMngrReportDownloadStatusTotal,

      getOrgQuestionData,
      getOrgManagerQuestionData,
      getOrgDMGQuestionData,
    } = this.props;

    let {
      showReportNow,
      showUploadBox,
      showManagerDropdown,
      CurrentManagerData,
      reportFormData,
      isContinue,
      managerSearchName,
      showManagerDownloadStatus,
    } = this.state;

    let getSurveyData = {
      logo: surveyLogo,
    };

    // if(surveyId==="618b673c9503f41bad61667f"){

    // }
    // getSurveyData["logo"] = 'img/logo/tcillogo.jpeg'

    // getSurveyData["logo"] = 'img/logo/tcillogo.jpeg'

    // ////console.log('getDemographicIndexForManagerData: ',getDemographicIndexForManagerData);
    // ////console.log('showManagerListDropdown: ',this.state.showManagerDropdown);
    // ////console.log('CurrentManagerData: ',this.state.CurrentManagerData);
    // ////console.log('reportFormData: ',this.state.reportFormData);

    ////console.log('QuesDynaObj--->: ');

    let IndexLabelList = [
      {
        index: "Happiness",
        template: 1,
        overall: getIndexData && getIndexData["1"] ? getIndexData["1"] : 0,
      },
      {
        index: "Engagement",
        template: 2,
        overall: getIndexData && getIndexData["2"] ? getIndexData["2"] : 0,
      },
      {
        index: "Stress",
        template: 4,
        overall: getIndexData && getIndexData["4"] ? getIndexData["4"] : 0,
      },
      {
        index: "Manager",
        template: 5,
        overall: getIndexData && getIndexData["5"] ? getIndexData["5"] : 0,
      },
      {
        index: "Leader",
        template: 6,
        overall: getIndexData && getIndexData["6"] ? getIndexData["6"] : 0,
      },
    ];

    let DataList = [];
    let OverallIndex = {};
    if (getIndexData) {
      OverallIndex = {
        level: "Overall",
        data: {},
      };
      if (IndexLabelList && IndexLabelList.length > 0) {
        IndexLabelList.forEach((label) => {
          OverallIndex.data[label.template] =
            (typeof label.overall !== "string" ||
              label.overall instanceof Number) &&
              label.overall
              ? label.overall.toFixed(2) + "%"
              : "NaN";
        });
      }
    }

    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (DynaObj && DynaObj.length > 0) {
          DynaObj.forEach((data) => {
            if (data.name[0] === emp.name) {
              let Item = {
                level: emp.name,
                data: {},
              };
              if (IndexLabelList && IndexLabelList.length > 0) {
                IndexLabelList.forEach((label) => {
                  Item.data[label.template] =
                    (typeof data[label.template] !== "string" ||
                      data[label.template] instanceof Number) &&
                      data &&
                      data[label.template]
                      ? data[label.template].toFixed(2) + "%"
                      : "NaN";
                });
              }
              let getIndex = DataList.findIndex(
                (prev) => prev.level === emp.name
              );
              if (getIndex === -1) {
                DataList.push(Item);
              }
            }
          });
        }
      });
    }

    // ////console.log('getQuestionReportData--->: ',getQuestionReportData);

    ////console.log('QuesDynaObj--->: ', QuesDynaObj);
    ////console.log('EmpFilterData--->: ', EmpFilterData);

    function QuestionListFn(QuesDynaObj, Behaviour) {
      let tempQuesList = [];
      let ParaList = [];
      if (QuesDynaObj && QuesDynaObj["ManagerPID"]) {
        const quesData = QuesDynaObj["ManagerPID"];

        quesData.forEach((que) => {
          let getIndex = ParaList.findIndex(
            (prev) => prev.name === que.parameterDimensionName
          );
          let show = true;
          if (getIndex === -1) {
            ParaList.push({
              name: que.parameterDimensionName,
              count: 1,
            });
            show = true;
          } else {
            ParaList[getIndex].count += 1;
            show = false;
            let getIndex2 = tempQuesList.findIndex(
              (prev) => prev.parameter === ParaList[getIndex].name
            );
            if (getIndex2 !== -1) {
              tempQuesList[getIndex2].countPara = ParaList[getIndex].count;
            }
          }

          let Item = {
            question: que.name,
            parameter: que.parameterDimensionName,
            showPara: show,
            countPara:
              ParaList && ParaList[getIndex] && ParaList[getIndex].count
                ? ParaList[getIndex].count
                : 1,
            demographic: {},
          };
          let ScoreData = {};
          if (EmpFilterData && EmpFilterData.length > 0) {
            EmpFilterData.forEach((emp) => {
              let getIndex = QuesDynaObj[emp.name].findIndex(
                (prev) => prev.name === que.name
              );
              if (getIndex !== -1) {
                const demoData = QuesDynaObj[emp.name][getIndex];
                const total =
                  demoData["1"] +
                  demoData["2"] +
                  demoData["3"] +
                  demoData["4"] +
                  demoData["5"] +
                  demoData["6"];
                const favourable = demoData["5"] + demoData["6"];
                const score = (favourable * 100) / total;
                ScoreData[emp.name] =
                  (typeof score !== "string" || score instanceof Number) &&
                    score
                    ? score.toFixed(2) + "%"
                    : "NaN";
              }
            });
          }

          Item.demographic = ScoreData;

          tempQuesList.push(Item);
        });
      }

      let QuestionList = [];
      if (Behaviour && Behaviour.length > 0) {
        Behaviour.forEach((item) => {
          if (tempQuesList && tempQuesList.length > 0) {
            tempQuesList.forEach((que) => {
              if (item === que.parameter) {
                QuestionList.push(que);
              }
            });
          }
        });
      }

      return QuestionList;
    }

    function DriverListFn(data, paraList) {
      let dataList = [];
      if (paraList && paraList.length > 0) {
        paraList.forEach((para) => {
          let temp = {
            parameter: para,
          };
          if (EmpFilterData && EmpFilterData.length > 0) {
            EmpFilterData.forEach((emp) => {
              temp[emp.name] =
                data &&
                  data[emp.name] &&
                  data[emp.name][0] &&
                  data[emp.name][0][para + "Avg"]
                  ? data[emp.name][0][para + "Avg"]
                  : 0;
            });
          }
          dataList.push(temp);
        });
      }
      return dataList;
    }

    let HappinessBehaviour = [
      "Time",
      "Being",
      "WorkLife",
      "Achivement",
      "Relationship",
      "Meeningfull",
    ];
    let QuestionListHappiness = QuestionListFn(
      QuesMngrHappinessReportObject,
      HappinessBehaviour
    );
    let DriverListHappiness = DriverListFn(
      DriverMngrHappinessReportObject,
      HappinessBehaviour
    );
    ////console.log('QuesMngrHappinessReportObject--->: ', QuesMngrHappinessReportObject);
    ////console.log('EmpFilterData--->: ', EmpFilterData);

    let EngagementBehaviour = ["Pride", "Presence", "Performance"];
    let QuestionListEngagement = QuestionListFn(
      QuesMngrEngagementReportObject,
      EngagementBehaviour
    );
    let DriverListEngagement = DriverListFn(
      DriverMngrEngagementReportObject,
      EngagementBehaviour
    );

    let StressBehaviour = [
      "Collaboration",
      "Empowerment",
      "Inclusion",
      "Recognition",
      "WorkSatisfaction",
    ];
    let QuestionListStress = QuestionListFn(
      QuesMngrStressReportObject,
      StressBehaviour
    );
    let DriverListStress = DriverListFn(
      DriverMngrStressReportObject,
      StressBehaviour
    );

    let ManagerBehaviour = ["Connect", "ACT", "Empower", "Respect"];
    let QuestionListManager = QuestionListFn(
      QuesMngrManagerReportObject,
      ManagerBehaviour
    );
    let DriverListManager = DriverListFn(
      DriverMngrManagerReportObject,
      ManagerBehaviour
    );

    // ////console.log('x---->QuesMngrHappinessReportObject--->: ',QuesMngrHappinessReportObject);
    // ////console.log('x---->HappinessBehaviour--->: ',HappinessBehaviour);

    // ////console.log('x---->QuesMngrEngagementReportObject--->: ',QuesMngrEngagementReportObject);
    // ////console.log('x---->EngagementBehaviour--->: ',EngagementBehaviour);

    ////console.log('x---->QuesMngrHappinessReportObject--->: ', QuesMngrHappinessReportObject);
    ////console.log('x---->QuesMngrEngagementReportObject--->: ', QuesMngrEngagementReportObject);
    ////console.log('x---->QuesMngrStressReportObject--->: ', QuesMngrStressReportObject);
    ////console.log('x---->QuesMngrManagerReportObject--->: ', QuesMngrManagerReportObject);

    ////console.log('ssdsdsDataList--->: ', DataList);

    ////console.log('x---->QuestionListHappiness--->: ', QuestionListHappiness);
    ////console.log('x---->QuestionListEngagement--->: ', QuestionListEngagement);
    ////console.log('x---->QuestionListStress--->: ', QuestionListStress);
    ////console.log('x---->QuestionListManager--->: ', QuestionListManager);

    let avoidList = ["ManagerPID", "Department", "Function", "Business"];

    function validateFn(template) {
      let getIndex = validateList.findIndex(
        (prev) => prev.template === template
      );
      if (getIndex !== -1) {
        return true;
      } else {
        return false;
      }
    }

    let IconsList = {
      Happiness: {
        Time: "img/managerreport/icons_scorecards/png/Time.png",
        Being: "img/managerreport/icons_scorecards/png/Being.png",
        Relationship: "img/managerreport/icons_scorecards/png/Relationship.png",
        WorkLife: "img/managerreport/icons_scorecards/png/worklife.png",
        Achivement: "img/managerreport/icons_scorecards/png/Achivement.png",
        Meeningfull: "img/managerreport/icons_scorecards/png/Meaningful.png",
      },
      Engagement: {
        Pride: "img/managerreport/icons_scorecards/png/Pride.png",
        Presence: "img/managerreport/icons_scorecards/png/Presence.png",
        Performance: "img/managerreport/icons_scorecards/png/Performance.png",
      },
      Stress: {
        Recognition: "img/managerreport/icons_scorecards/png/Recognition.png",
        "Work Satisfaction":
          "img/managerreport/icons_scorecards/png/Work_Satisfaction.png",
        Inclusion: "img/managerreport/icons_scorecards/png/Inclusion.png",
        Collaboration:
          "img/managerreport/icons_scorecards/png/Collaboration.png",
        Empowerment: "img/managerreport/icons_scorecards/png/Empowerment.png",
      },
      Manager: {
        Connect: "img/managerreport/icons_scorecards/png/connect.png",
        ACT: "img/managerreport/icons_scorecards/png/act.png",
        Respect: "img/managerreport/icons_scorecards/png/respect.png",
        Empower: "img/managerreport/icons_scorecards/png/empower.png",
      },
    };

    let SentenceList = {
      Happiness: {
        Time: "Our ability to take care of our timescale plays an important role in our happiness",
        Being:
          "This context and being is so important that without it, we can lose the balance of a happy life",
        Relationship:
          "Whether at home or at the workplace, we interact with people; and if we have deep relationships with people around us, these interactions become sources of happiness",
        WorkLife:
          "Sometimes, work gives us an identity and allows us to express ourselves very well. Around 75% of our waking hours are spent at work and it is one of the most critical aspects of our life",
        Achivement:
          "In life, people want to learn and grow. Adding to self is something which gives happiness to everyone",
        Meeningfull:
          "Knowing your strengths and doing something to bring a larger change is key to a meaningful life",
      },
      Engagement: {
        Pride:
          "Take Pride, Talk Positively & Recommend the Organization. Employees who feel a strong sense of pride in their work are more ambitious, energetic, focused and motivated",
        Presence:
          "The intent of an employee to have an intense desire to be a member of the organization mentally and physically. Having continuous positive push to associate with organization mission.",
        Performance:
          "Hi-Performance & Strive to go beyond the goals to make Organization successful. Having an aligned purpose with business",
      },
      Stress: {
        Recognition: "",
        "Work Satisfaction": "",
        Inclusion: "",
        Collaboration: "",
        Empowerment: "",
      },
      Manager: {
        Connect:
          "Manager to care and support with team and knowing them individually",
        ACT: "Develop Hi-performing team and continuously perform to achieve organization's goal",
        Respect: "Build an environment of TRUST and RESPECT",
        Empower: "Manager to empower team and bring the best",
      },
    };

    ////////console.log("----------------------------ss-----getMngrReportDownloadStatusData----", getMngrReportDownloadStatusData);

    function calcQuartile(indexName) {
      let itemX = indexName + "_MNGR_Quartile";
      try {
        return getOverallScoreBySurveyIdData &&
          getOverallScoreBySurveyIdData[itemX]
          ? getOverallScoreBySurveyIdData[itemX]
          : {};
      } catch {
        return 0
      }

    }


    // getMngrReportDownloadStatusData = getMngrReportDownloadStatusData.concat(getMngrReportDownloadStatusData)

    function getEmpValue(item, name) {
      return item && item["employeeId"] && item["employeeId"][name]
        ? item["employeeId"][name]
        : "";
    }

    let ManagerDownloadStatusList = [];
    if (
      getMngrReportDownloadStatusData &&
      getMngrReportDownloadStatusData.length > 0
    ) {
      getMngrReportDownloadStatusData.forEach((item) => {
        ManagerDownloadStatusList.push({
          name: getEmpValue(item, "name"),
          email: getEmpValue(item, "email"),
          emp_id: getEmpValue(item, "emp_id"),
          downloaded: "YES",
        });
      });
    }

    let QueTemplate = 6;

    // function FormattFun(inputData, isSummary) {
    //   let LIST = [];
    //   let prevTemList = [];
    //   if (inputData && inputData.length > 0) {
    //     inputData.forEach((ques) => {
    //       let templateId = ques.total[0]._id.qtemplate;
    //       if (QueTemplate === templateId) {
    //         let temp = {
    //           name:
    //             ques && ques.question[0] && ques.question[0].name
    //               ? ques.question[0].name
    //               : "",
    //           answerList: [],
    //           templateId: templateId,
    //         };
    //         if (ques && ques.total && ques.total.length > 0) {
    //           ques.total.forEach((item) => {
    //             let ans = {
    //               count: item.count,
    //               answerText: item._id.answer,
    //               heading:
    //                 item && item._id && item._id.heading
    //                   ? item._id.heading
    //                   : "",
    //               parentId:
    //                 item && item._id && item._id.parentId
    //                   ? item._id.parentId
    //                   : "",
    //               template: item._id.qtemplate,
    //               QuesId: item._id.questionId,
    //             };
    //             temp["answerList"].push(ans);
    //           });
    //         }

    //         if (isSummary) {
    //           let getIndex = prevTemList.findIndex(
    //             (prev) => prev === templateId
    //           );
    //           if (getIndex === -1) {
    //             LIST.push(temp);
    //             prevTemList.push(templateId);
    //           }
    //         } else {
    //           LIST.push(temp);
    //         }
    //       }
    //     });
    //   }
    //   return LIST;
    // }

    // let OthersQuesList = FormattFun(getQuestionOthersResultData, true);

    // console.log('---------- getQuestionOthersResultData-6666---------: ', getQuestionOthersResultData);

    // let item =
    //   OthersQuesList && OthersQuesList.length > 0 ? OthersQuesList[0] : {};

    // let AnswerObj = {};
    // if (item && item.answerList && item.answerList.length > 0) {
    //   //////console.log('item.answerList (STAR)',item.answerList)

    //   item.answerList.forEach((ans) => {
    //     if (ans && ans.answerText) {
    //       if (AnswerObj && AnswerObj[ans.answerText]) {
    //         AnswerObj[ans.answerText] += ans.count;
    //       } else {
    //         AnswerObj[ans.answerText] = ans.count;
    //       }
    //     }
    //   });
    // }


    // let name = "NPS";


    let manageName = CurrentManagerData && CurrentManagerData.name
      ? CurrentManagerData.name
      : "Manager"
    let createdAt = moment().format("DD/MM/YYYY");


    let teamSize = 0;

    if (getManagerManagerQuestionData && getManagerManagerQuestionData.length > 0) {
      teamSize = getManagerManagerQuestionData && getManagerManagerQuestionData[0] && getManagerManagerQuestionData[0]["count"] ? getManagerManagerQuestionData[0]["count"] : 0;
    }

    let npsQuesList = []
    if (getQuestionOthersResultData && getQuestionOthersResultData.length > 0) {
      getQuestionOthersResultData.forEach((element) => {
        if (CurrentSurveyId.toString() === "66263d1e9300643cda1e1d06") {
          console.log("element--/////////////////////--", element)
          if (element && element["_id"] && element["_id"].toString() === "662b43d1d9a1af883ad419ff") {
            npsQuesList.push(element)
          }
        } else {
          if (element && element.question && element.question[0] && element.question[0].templateId === 6) {
            npsQuesList.push(element)
          }
        }

      })
    }
    console.log("getQuestionOthersResultData--/////////////////////--", getQuestionOthersResultData)
    console.log("CurrentSurveyId--/////////////////////--", CurrentSurveyId)
    console.log("npsQuesList--/////////xxxxxxxxx////////////--", npsQuesList)

    return (
      <>
        <div className=" fixed top-0 left-0 w-full bg-black bg-opacity-40 z-20 h-screen 2xl:p-10 p-6 ">
          <div
            className={
              !showManagerDownloadStatus && isContinue
                ? "App bg-white  2xl:w-3/6 xl:w-4/6 lg:w-5/6 mx-auto rounded-md overflow-y-auto customscroll5 overflow-hidden"
                : "bg-white  shadow 2xl:w-2/6  xl:w-2/6 lg:w-3/6 md:w-4/6  mx-auto rounded-md overflow-auto block  "
            }
          >
            <div className="">
              <div className="flex md:flex-row flex-col justify-between border-b items-center p-4 px-6 sticky top-0 bg-white z-20" >
                <div className="flex items-center space-x-4">
                  <h1 className="font-semibold md:text-xl text-lg line-clamp-1 text-slate-800">
                    Generate Manager Report
                  </h1>
                </div>

                <div className="flex items-center space-x-4">
                  {isContinue ? (
                    <>
                      {showReportNow ? (
                        <div
                          onClick={() => this.exportPDFWithComponent()}
                          className="bg-blue-100 p-2 px-4 rounded-full text-blue-500  cursor-pointer text-sm flex items-center "
                        >
                          <span class="material-symbols-outlined mr-2 ">
                            file_download
                          </span>
                          Export
                        </div>
                      ) : (
                        <div className="px-2 py-1 text-blue-500">
                          ....loading report
                        </div>
                      )}
                    </>
                  ) : null}

                  <div
                    onClick={() => this.closeReportModal(false)}
                    className=" w-10 h-10 rounded-full  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
                  >
                    <svg
                      className="fill-current w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                    </svg>
                  </div>
                </div>
              </div>



              <div className="overflow-y-auto h-[calc(100vh-8rem)] ">
                {showManagerDownloadStatus ? (
                  <>
                    <div className="cursor-default flex mx-4 items-center bg-white px-4 py-2 rounded-md my-2 shadow-lg border-2">
                      <div>
                        <span className="material-symbols-outlined text-blue-500 bg-blue-100 p-2 rounded-full">
                          group
                        </span>
                      </div>
                      <div className=" overflow-x-auto whitespace-nowrap w-full ">
                        <div className="align-middle inline-block min-w-full overflow-hidden">
                          <table className="min-w-full">
                            <tbody className="bg-white">
                              <tr className="text-left">
                                <td className="px-4 py-1 whitespace-no-wrap font-medium text-sm  text-gray-800">
                                  <h1 className="text-lg">
                                    <span className="px-2">
                                      {" "}
                                      Total No of Manager who have downloaded
                                      the report:{" "}
                                    </span>
                                    <span className="font-bold text-blue-500">
                                      {getMngrReportDownloadStatusData &&
                                        getMngrReportDownloadStatusData.length > 0
                                        ? getMngrReportDownloadStatusData.length
                                        : 0}
                                    </span>
                                  </h1>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-2">
                      {getMngrReportDownloadStatusData &&
                        getMngrReportDownloadStatusData.length > 0
                        ? getMngrReportDownloadStatusData.map((item, index) => (
                          <div className="cursor-default flex mx-4 items-center bg-white px-4 py-2 rounded-md my-2 shadow-lg border-2">
                            <div>
                              <span className="material-symbols-outlined text-blue-500 bg-blue-100 p-2 rounded-full">
                                person
                              </span>
                            </div>
                            <div className=" overflow-x-auto whitespace-nowrap w-full ">
                              <div className="align-middle inline-block min-w-full overflow-hidden">
                                <table className="min-w-full">
                                  <thead className="bg-white">
                                    <tr className="text-left">
                                      <th
                                        style={{ width: "250px" }}
                                        className="px-4 py-1  flex text-left text-sm  leading-4 font-semibold text-gray-800 mt-2 "
                                      >
                                        <h1>{getEmpValue(item, "name")}</h1>

                                        <span style={{ height: "25px" }}>
                                          <span
                                            style={{ fontSize: "10px" }}
                                            className="bg-green-50 text-green-500 py-1 px-2 rounded-full font-normal mx-2"
                                          >
                                            {getEmpValue(item, "email")}
                                          </span>
                                        </span>
                                        <span style={{ height: "25px" }}>
                                          <span
                                            style={{ fontSize: "10px" }}
                                            className="bg-gray-50 text-green-500 py-1 px-2 rounded-full font-normal mx-2"
                                          >
                                            EmpID:{" "}
                                            {getEmpValue(item, "emp_id")}
                                          </span>
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody className="bg-white">
                                    <tr className="text-left">
                                      <td className="px-4  whitespace-no-wrap  font-medium text-sm  text-gray-800">
                                        <span className="text-gray-400 pr-2">
                                          Last Downloaded Date:
                                        </span>
                                        {moment
                                          .unix(
                                            item.reportDownloadDate / 1000
                                          )
                                          .format(" DD MMM, YYYY , HH:mm")}
                                      </td>

                                      <td className="px-4  whitespace-no-wrap  font-medium text-sm  text-gray-800">
                                        <span className="text-gray-400 pr-2">
                                          Download Count:
                                        </span>
                                        {item.reportDownloadCount}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ))
                        : null}
                    </div>
                  </>
                ) : (
                  <>
                    {!isContinue ? (
                      <div className="p-4">
                        {/* <Header surveyLogo={surveyLogo} /> */}

                        <div className=" bg-blue-100 p-6">
                          <img
                            src="img/managerreport/manager_scorecard.png"
                            className="w-4/6 mx-auto"
                          />
                        </div>

                        <div className="w-full">
                          <div className=" space-y-8 ">
                            {/* Level Filter */}
                            <div className="pt-8">
                              <label className="text-gray-600 text-sm">
                                Select Employees
                              </label>
                              <div
                                className={
                                  false
                                    ? "flex items-center justify-between border border-blue-500 p-1.5 px-4 relative bg-white rounded-lg mt-1"
                                    : "flex items-center justify-between border p-1.5 px-4 relative bg-white rounded-lg mt-1 font-medium"
                                }
                              >
                                <div
                                  onClick={() =>
                                    this.showManagerListDropdown(
                                      showManagerDropdown
                                    )
                                  }
                                  className="w-60 py-2 cursor-pointer truncate"
                                >
                                  <span>
                                    {CurrentManagerData &&
                                      CurrentManagerData.name
                                      ? CurrentManagerData.name
                                      : "SELECT MANAGER"}
                                  </span>
                                  <span className="text-blue-500 pl-1 text-sm font-normal"></span>
                                </div>
                                <span
                                  onClick={() =>
                                    this.showManagerListDropdown(
                                      showManagerDropdown
                                    )
                                  }
                                  className="cursor-pointer material-symbols-outlined"
                                >
                                  arrow_drop_down
                                </span>

                                {showManagerDropdown ? (
                                  <div
                                    className="absolute top-14 border z-20 shadow-xl w-full right-0 bg-white  font-normal 
                              text-gray-500 h-32 overflow-hidden overflow-y-auto lang  "
                                  >
                                    <input
                                      type="text"
                                      value={managerSearchName}
                                      name="managerName"
                                      onChange={this.handleSearchManager}
                                      placeholder="Search"
                                      className="w-full border p-1.5 rounded-sm focus:outline-none text-xs text-gray-500 focus:ring-blue-500 focus:ring-1"
                                    />

                                    {getDemographicIndexForManagerData &&
                                      getDemographicIndexForManagerData.length > 0
                                      ? getDemographicIndexForManagerData.map(
                                        (mngr) =>
                                          mngr &&
                                            mngr.name.match(
                                              new RegExp(
                                                `${managerSearchName}`,
                                                "gi"
                                              )
                                            ) ? (
                                            <>
                                              <div
                                                className={
                                                  CurrentManagerData.id ===
                                                    mngr.id
                                                    ? "flex items-center py-2 bg-blue-500 text-white"
                                                    : "flex items-center py-2"
                                                }
                                                onClick={() =>
                                                  this.selectManager(mngr)
                                                }
                                              >
                                                <label className="ml-2 font-semibold">
                                                  {mngr.name}
                                                </label>
                                              </div>
                                            </>
                                          ) : null
                                      )
                                      : null}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div>
                              <div
                                onClick={() => {
                                  if (
                                    CurrentManagerData &&
                                    CurrentManagerData.id
                                  ) {
                                    this.loadManagerReport();
                                  }
                                }}
                                className="cursor-pointer bg-blue-500 text-white p-2 mt-4 rounded-lg font-medium text-center py-4"
                              >
                                Preview Report
                              </div>

                              {/* <div onClick={()=>this.onShowDownloadStatus(true)}
                              className="cursor-pointer bg-gray-400 text-white p-2 mt-4 rounded-lg font-semibold text-center py-4">DOWNLOAD STATUS</div> */}

                              <CSVLink
                                data={ManagerDownloadStatusList}
                                filename={"ManagersDownloadStatus.csv"}
                              >
                                <div className="cursor-pointer bg-blue-100 text-blue-500 p-2 mt-4 rounded-lg font-medium text-center py-4 flex justify-center items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    className="h-6 w-6 mr-2 text-blue-500"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"
                                    />
                                  </svg>
                                  Download Status
                                </div>
                              </CSVLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="mt-8 text-center overflow-y-auto">
                        <>
                          <PDFExport
                            ref={this.pdfExportComponent}

                            pageTemplate={(pageObj) => <PageTemplate pageNum={pageObj.pageNum} surveyLogo={surveyLogo} />}
                            scale={0.7}
                            forcePageBreak=".page-break"
                            paperSize="A4"
                            margin={0}
                            landscape={false}


                            fileName={`Manager ID - ${CurrentManagerData && CurrentManagerData["emp_id"]
                              ? CurrentManagerData["emp_id"]
                              : ""
                              } - ${createdAt}`}
                            author="Happyplus Team"
                          >

                            <IntroPage
                              CurrentManagerData={CurrentManagerData}
                              CurrentSurveyId={CurrentSurveyId}
                              createdAt={createdAt}
                              surveyLogo={surveyLogo}
                              teamSize={teamSize}
                              getMngrTeamSizeData={getMngrTeamSizeData}
                            />

                            <h3 className="page-break mt-4"></h3>
                            <TableOfContent
                              createdAt={createdAt}
                              manageName={manageName}
                            />

                            <h3 className="page-break mt-4"></h3>
                            <ScoreCardMix
                              IndexName={"Manager"}
                              IndexNumber={5}

                              getDemographicIndexData={
                                getDemographicIndexManagerData
                              }
                              getDemographicEngagementIndexData={
                                getDemographicIndexEngagementData
                              }

                              CurrentManagerData={CurrentManagerData}
                              getIndexData={getIndexData}
                              getSurveyData={getSurveyData}
                              getQuestionData={getManagerQuestionData}
                              GetParameterLabel={GetParameterLabel}
                              getIndexByFilterData={getManagerIndexByFilterData}
                              getIndexByFilterDMGData={getManagerIndexByFilterV2Data}

                              IndexDimensionList={IndexDimension["Manager"]}
                              IndexDimensionEngagementList={IndexDimension["Engagement"]}

                              SentenceObj={SentenceList["Manager"]}
                              QuartileData={calcQuartile("Manager")}
                              hiderOverall={allowSpecificFn("tce")}

                              getIndexDriverData={getManagerIndexDriverData}
                              getEngagementIndexDriverData={getEngagementIndexDriverData}

                              manageName={manageName}
                              createdAt={createdAt}
                              getIndexDMGData={getDemographicIndexManagerV2Data}
                              getEngagementIndexDMGData={getDemographicIndexEngagementV2Data}

                              teamSize={teamSize}
                              questionData={npsQuesList && npsQuesList.length > 0 ? [npsQuesList[0]] : []}
                              QueTemplate={6}
                              getAllBenchMarkStaticData={getAllBenchMarkStaticData}
                            />



                            <h3 className="page-break mt-4"></h3>
                            <ScoreCardPage2
                              IndexName={"Manager"}
                              IndexNumber={5}

                              getDemographicIndexData={
                                getDemographicIndexManagerData
                              }
                              getDemographicEngagementIndexData={
                                getDemographicIndexEngagementData
                              }

                              CurrentManagerData={CurrentManagerData}
                              getIndexData={getIndexData}
                              getSurveyData={getSurveyData}
                              getQuestionData={getManagerQuestionData}
                              GetParameterLabel={GetParameterLabel}
                              getIndexByFilterData={getManagerIndexByFilterData}
                              getIndexByFilterDMGData={getManagerIndexByFilterV2Data}

                              IndexDimensionList={IndexDimension["Manager"]}
                              IndexDimensionEngagementList={IndexDimension["Engagement"]}

                              SentenceObj={SentenceList["Manager"]}
                              QuartileData={calcQuartile("Manager")}
                              hiderOverall={allowSpecificFn("tce")}

                              getIndexDriverData={getManagerIndexDriverData}
                              getEngagementIndexDriverData={getEngagementIndexDriverData}

                              manageName={manageName}
                              createdAt={createdAt}
                              getIndexDMGData={getDemographicIndexManagerV2Data}
                              getEngagementIndexDMGData={getDemographicIndexEngagementV2Data}

                              teamSize={teamSize}
                              questionData={npsQuesList && npsQuesList.length > 0 ? [npsQuesList[0]] : []}
                              QueTemplate={6}
                              getAllBenchMarkStaticData={getAllBenchMarkStaticData}
                            />




                            {getIndexData && getIndexData["5"] ? (
                              <>
                                <h3 className="page-break mt-4"></h3>
                                <QuestionDetailScore
                                  SectionName={"Manager"}
                                  getQuestionOverallData={
                                    getManagerQuestionData
                                  }
                                  getQuestionManagerData={
                                    getManagerManagerQuestionData
                                  }
                                  getDMGQuestionData={getManagerDMGQuestionData}
                                  ManagerDetails={CurrentManagerData}
                                  IndexDimensionList={IndexDimension["Manager"]}
                                  GetParameterLabel={GetParameterLabel}
                                  getDemographicIndexData={
                                    getDemographicIndexManagerData
                                  }
                                  CurrentManagerData={CurrentManagerData}
                                  getSurveyData={getSurveyData}
                                  hiderOverall={allowSpecificFn("tce")}
                                  getIndexDriverData={getEngagementIndexDriverData}
                                  manageName={manageName}
                                  createdAt={createdAt}
                                  getIndexDMGData={getDemographicIndexManagerV2Data}
                                  teamSize={teamSize}
                                  getAllBenchMarkStaticData={getAllBenchMarkStaticData}

                                />
                              </>
                            ) : null}



                            {getIndexData && getIndexData["2"] ? (
                              <>
                                <h3 className="page-break mt-4"></h3>
                                <QuestionDetailScore
                                  SectionName={"Engagement"}
                                  getQuestionOverallData={
                                    getEngagementQuestionData
                                  }
                                  getQuestionManagerData={
                                    getEngagementManagerQuestionData
                                  }
                                  getDMGQuestionData={getEngagementDMGQuestionData}
                                  ManagerDetails={CurrentManagerData}
                                  IndexDimensionList={IndexDimension["Engagement"]}
                                  GetParameterLabel={GetParameterLabel}
                                  getDemographicIndexData={
                                    getDemographicIndexEngagementData
                                  }
                                  CurrentManagerData={CurrentManagerData}
                                  getSurveyData={getSurveyData}
                                  hiderOverall={allowSpecificFn("tce")}
                                  manageName={manageName}
                                  createdAt={createdAt}
                                  teamSize={teamSize}
                                  getIndexDMGData={getDemographicIndexEngagementV2Data}
                                  getAllBenchMarkStaticData={getAllBenchMarkStaticData}

                                />
                              </>
                            ) : null}





                            {!allowSpecificFn("tce") ? (
                              <>
                                <h3 className="page-break mt-4"></h3>
                                <OverviewMethodology
                                  getSurveyData={getSurveyData}
                                  getEngagementQuestionData={
                                    getEngagementQuestionData
                                  }
                                  IndexEngagementDimensionList={
                                    IndexDimension["Engagement"]
                                  }
                                  getManagerQuestionData={
                                    getManagerQuestionData
                                  }
                                  IndexManagerDimensionList={
                                    IndexDimension["Manager"]
                                  }
                                  getIndexData={getIndexData}
                                  manageName={manageName}
                                  createdAt={createdAt}
                                  getAllBenchMarkStaticData={getAllBenchMarkStaticData}
                                />
                              </>
                            ) : null}



                            <h3 className="page-break mt-4"></h3>
                            <FirstImpression
                              manageName={manageName}
                              createdAt={createdAt}
                            />



                          </PDFExport>
                        </>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(ReportModal);
