import React, { useState } from "react";
export default function AddFilter(props) {
  let [view, onAddFilter] = React.useState(false);

  let {
    closeEmployeeFilter,
    onEmployeeFilterDropdown,
    handleEmployeeColumnSelect,
    handleFilterCheck,
    createEmployeeFilter,
    onDeleteFilter,
    handleFilterStatus,
    handleFilterRank,
    updateformDataFilter,
    updatehandleFilterRank,
    handleShowReportStatus,
    handleIsEngCompStatus,
    handleIsEDAStatus,
    showFilterNames,
    formDataFilter,
    getEmployeeColumnNameData,
    EmployeeFilterCompanyList,
    LanguageObj,
    CurrentLanguage,
    getEmployeeFilterListByCompanyIdData,
    onClickQuesUp,
    onClickQuesDown,
    handleChangeLabel,
    handleLabelInput,
    CrrEmpFilterLabel,
    showInputBox,
    submitEmployeeFilterLabelChange,
    handleIsDMGSurveyStatus
  } = props;
  return (
    <div className="w-full h-full p-6 space-y-4 overflow-hidden transition duration-150 bg-white border rounded-lg cursor-pointer hover:shadow-lg ">
      <h1 className=" xl:text-xl text-lg font-medium text-[#3D405B] pb-2  ">
        Filter List
      </h1>
      {/* <div className="justify-between space-y-4 2xl:flex 2xl:space-x-6 2xl:space-y-0 ">

        <div className="flex space-x-2 capitalize items-center text-sm text-[#3D405B] font-medium">
          <span>Show</span>
          <input
            className="w-16 p-2 text-center border rounded-md"
            value={getEmployeeFilterListByCompanyIdData &&
              getEmployeeFilterListByCompanyIdData.length > 0?getEmployeeFilterListByCompanyIdData.length:0}
          />
          <span>entries</span>
        </div>

        <div className="flex flex-col justify-between gap-2 md:flex-row 2xl:justify-start ">
          <div className="lg:w-auto w-full flex border px-4 text-[#3D405B]/70 bg-white rounded-md text-sm items-center  overflow-hidden">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
            > <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <circle cx="10" cy="10" r="7"></circle> <line x1="21" y1="21" x2="15" y2="15"></line>
            </svg>
            <input type="search" name="search" placeholder="Search by name..." className="lg:w-60 w-full focus:outline-none px-2 py-2 text-[#3D405B] placeholder:font-normal font-medium " />
          </div>
          <div className="flex flex-col gap-2 md:flex-row ">
          </div>
        </div>
      </div> */}

      <div className="overflow-x-auto scroll-smooth xl:whitespace-nomal w-full 2xl:h-[calc(76vh-4.5rem)] md:h-[calc(80vh+0.5rem)] h-[30rem]">
        <div className="inline-block min-w-full overflow-hidden align-middle ">
          <table className="min-w-full border ">
            <thead className="sticky top-0 z-20 bg-white border-b ">
              <tr className="capitalize text-[#3D405B] border-b text-sm">
                <th className="w-20 p-3 text-sm font-medium text-left border-r">
                  {LanguageObj && LanguageObj["s_no_label_" + CurrentLanguage]
                    ? LanguageObj["s_no_label_" + CurrentLanguage]
                    : "S No."}
                </th>
                <th className="w-20 p-3 text-sm font-medium text-left border-r ">
                  {LanguageObj && LanguageObj["name_label_" + CurrentLanguage]
                    ? LanguageObj["name_label_" + CurrentLanguage]
                    : "Name"}
                </th>
                <th className="w-20 p-3 text-sm font-medium text-left border-r ">
                  {" "}
                  {LanguageObj && LanguageObj["label_label_" + CurrentLanguage]
                    ? LanguageObj["label_label_" + CurrentLanguage]
                    : "Label"}
                </th>
                <th className="w-20 p-3 text-sm font-medium text-left border-r">
                  {LanguageObj && LanguageObj["enable_label_" + CurrentLanguage]
                    ? LanguageObj["enable_label_" + CurrentLanguage]
                    : "Status"}
                </th>

                <th className="w-20 p-3 text-sm font-medium text-left border-r">
                  {LanguageObj &&
                    LanguageObj["survey_label_" + CurrentLanguage]
                    ? LanguageObj["survey_label_" + CurrentLanguage]
                    : "DMG Self"}
                </th>
                <th className="w-20 p-3 text-sm font-medium text-left border-r">
                  {LanguageObj &&
                    LanguageObj["survey_label_" + CurrentLanguage]
                    ? LanguageObj["survey_label_" + CurrentLanguage]
                    : "DMG Cross"}
                </th>

                <th className="w-20 p-3 text-sm font-medium text-left border-r">
                  {LanguageObj &&
                    LanguageObj["survey_label_" + CurrentLanguage]
                    ? LanguageObj["survey_label_" + CurrentLanguage]
                    : "DMG Wise"}
                </th>

                <th className="w-20 p-3 text-sm font-medium text-left border-r">
                  {LanguageObj &&
                    LanguageObj["showReport_label_" + CurrentLanguage]
                    ? LanguageObj["showReport_label_" + CurrentLanguage]
                    : "Show Report"}
                </th>
                <th className="w-20 p-3 text-sm font-medium text-left border-r ">
                  {LanguageObj &&
                    LanguageObj["showReport_label_" + CurrentLanguage]
                    ? LanguageObj["showReport_label_" + CurrentLanguage]
                    : "Report Eng Comp"}
                </th>
                <th className="w-20 p-3 text-sm font-medium text-left border-r">
                  {LanguageObj &&
                    LanguageObj["showReport_label_" + CurrentLanguage]
                    ? LanguageObj["showReport_label_" + CurrentLanguage]
                    : "EDA"}
                </th>
            
                <th className="w-20 p-3 text-sm font-medium text-left border-r">
                  {LanguageObj && LanguageObj["rank_label_" + CurrentLanguage]
                    ? LanguageObj["rank_label_" + CurrentLanguage]
                    : "Rank"}
                </th>
                <th className="w-20 p-3 text-sm font-medium text-left border-r">
                  {LanguageObj && LanguageObj["delete_label_" + CurrentLanguage]
                    ? LanguageObj["delete_label_" + CurrentLanguage]
                    : "Action"}
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y ">
              {getEmployeeFilterListByCompanyIdData &&
                getEmployeeFilterListByCompanyIdData.length > 0
                ? getEmployeeFilterListByCompanyIdData.map((empFilter, index) => (
                  <tr className="odd:bg-[#F9FAFB] even:bg-white text-sm ">
                    <td className="p-3 whitespace-no-wrap border-r ">
                      {index + 1}
                    </td>
                    <td className="p-3 whitespace-no-wrap border-r ">
                      {empFilter && empFilter.name ? empFilter.name : ""}
                    </td>
                    <td className="p-3 whitespace-no-wrap border-r ">
                      {!(showInputBox === empFilter.id) ? (
                        <div
                          className="text-blue-500"
                          onClick={() => handleChangeLabel(empFilter)}
                        >
                          {empFilter && empFilter.label ? empFilter.label : ""}
                        </div>
                      ) : (
                        <div className="flex border border-blue-500">
                          <input
                            className="px-2 py-1 text-gray-500 rounded-sm "
                            type="text"
                            value={CrrEmpFilterLabel}
                            onChange={handleLabelInput}
                          />
                          <span
                            onClick={() =>
                              submitEmployeeFilterLabelChange(empFilter.id)
                            }
                            className="text-green-500 bg-gray-100 material-symbols-outlined"
                          >
                            done
                          </span>
                        </div>
                      )}
                    </td>
                    <td className="p-3 whitespace-no-wrap border-r ">
                      {empFilter.isDisable ? (
                        <div className="flex justify-center ">
                          <div
                            onClick={() => handleFilterStatus(empFilter.id)}
                            className="rounded-full w-11 h-5 cursor-pointer   p-0.5 bg-gray-200"
                          >
                            <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform -translate-x-3 bg-white rounded-full" />
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-center ">
                          <div
                            onClick={() => handleFilterStatus(empFilter.id)}
                            className="rounded-full w-11 h-5 cursor-pointer   p-0.5 bg-blue-400"
                          >
                            <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform translate-x-3 bg-white rounded-full" />
                          </div>
                        </div>
                      )}
                    </td>

                    
                    <td className="p-3 whitespace-no-wrap border-r ">
                      <div className="flex justify-center">
                        {empFilter && empFilter.isDynSelf ? (
                          <>
                            <div
                              onClick={() => handleIsDMGSurveyStatus(empFilter.id,1)}
                              className="rounded-full w-11 h-5 p-0.5 bg-blue-400"
                            >
                              <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform translate-x-3 bg-white rounded-full" />
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              onClick={() => handleIsDMGSurveyStatus(empFilter.id,1)}
                              className="rounded-full w-11 h-5 p-0.5 bg-gray-200"
                            >
                              <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform -translate-x-3 bg-white rounded-full" />
                            </div>
                          </>
                        )}
                      </div>
                    </td>
                    <td className="p-3 whitespace-no-wrap border-r ">
                      <div className="flex justify-center">
                        {empFilter && empFilter.isDynCross ? (
                          <>
                            <div
                              onClick={() => handleIsDMGSurveyStatus(empFilter.id,2)}
                              className="rounded-full w-11 h-5 p-0.5 bg-blue-400"
                            >
                              <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform translate-x-3 bg-white rounded-full" />
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              onClick={() => handleIsDMGSurveyStatus(empFilter.id,2)}
                              className="rounded-full w-11 h-5 p-0.5 bg-gray-200"
                            >
                              <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform -translate-x-3 bg-white rounded-full" />
                            </div>
                          </>
                        )}
                      </div>
                    </td>


                    <td className="p-3 whitespace-no-wrap border-r ">
                      <div className="flex justify-center">
                        {empFilter && empFilter.isDMGSurvey ? (
                          <>
                            <div
                              onClick={() => handleIsDMGSurveyStatus(empFilter.id,3)}
                              className="rounded-full w-11 h-5 p-0.5 bg-blue-400"
                            >
                              <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform translate-x-3 bg-white rounded-full" />
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              onClick={() => handleIsDMGSurveyStatus(empFilter.id,3)}
                              className="rounded-full w-11 h-5 p-0.5 bg-gray-200"
                            >
                              <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform -translate-x-3 bg-white rounded-full" />
                            </div>
                          </>
                        )}
                      </div>
                    </td>

                    <td className="p-3 whitespace-no-wrap border-r ">
                      <div className="flex justify-center">
                        {empFilter && empFilter.isShowReport ? (
                          <>
                            <div
                              onClick={() =>
                                handleShowReportStatus(empFilter.id)
                              }
                              className="rounded-full w-11 h-5 cursor-pointer   p-0.5 bg-blue-400"
                            >
                              <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform translate-x-3 bg-white rounded-full" />
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              onClick={() =>
                                handleShowReportStatus(empFilter.id)
                              }
                              className="rounded-full w-11 h-5 cursor-pointer   p-0.5 bg-gray-200"
                            >
                              <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform -translate-x-3 bg-white rounded-full" />
                            </div>
                          </>
                        )}
                      </div>
                    </td>

                    <td className="p-3 whitespace-no-wrap border-r ">
                      <div className="flex justify-center">
                        {empFilter && empFilter.isInEngComp ? (
                          <div className="flex justify-center ">
                            <div
                              onClick={() =>
                                handleIsEngCompStatus(empFilter.id)
                              }
                              className="rounded-full w-11 h-5 cursor-pointer   p-0.5 bg-blue-400"
                            >
                              <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform translate-x-3 bg-white rounded-full" />
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-center ">
                            <div
                              onClick={() =>
                                handleIsEngCompStatus(empFilter.id)
                              }
                              className="rounded-full w-11 h-5 p-0.5 bg-gray-200"
                            >
                              <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform -translate-x-3 bg-white rounded-full" />
                            </div>
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="p-3 whitespace-no-wrap border-r ">
                      <div className="flex justify-center">
                        {empFilter && empFilter.isEDA ? (
                          <>
                            <div
                              onClick={() => handleIsEDAStatus(empFilter.id)}
                              className="rounded-full w-11 h-5 p-0.5 bg-blue-400"
                            >
                              <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform translate-x-3 bg-white rounded-full" />
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              onClick={() => handleIsEDAStatus(empFilter.id)}
                              className="rounded-full w-11 h-5 p-0.5 bg-gray-200"
                            >
                              <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform -translate-x-3 bg-white rounded-full" />
                            </div>
                          </>
                        )}
                      </div>
                    </td>




                    <td className="p-3 py-2 whitespace-no-wrap border-r ">
                      <div className="flex justify-center space-x-4">
                        <button
                          onClick={() => onClickQuesUp(empFilter.id, index)}
                          className="bg-white border hover:border-[#2196F3] hover:bg-[#2196F3]/10 hover:text-blue-500  rounded-md cursor-pointer p-1.5 text-[1.2rem] transition-all duration-150 text-[#3D405B]"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-trash "
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="16" y1="9" x2="12" y2="5" />
                            <line x1="8" y1="9" x2="12" y2="5" />
                          </svg>
                        </button>
                        <button
                          onClick={() => onClickQuesDown(empFilter.id, index)}
                          className="bg-white border hover:border-[#2196F3] hover:bg-[#2196F3]/10 hover:text-blue-500  rounded-md cursor-pointer p-1.5 text-[1.2rem] transition-all duration-150 text-[#3D405B]"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-trash "
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="16" y1="15" x2="12" y2="19" />
                            <line x1="8" y1="15" x2="12" y2="19" />
                          </svg>
                        </button>
                      </div>
                    </td>
                    <td className="p-3 py-2 whitespace-no-wrap border-r ">
                      <div
                        onClick={() => onDeleteFilter(empFilter)}
                        className="flex justify-center"
                      >
                        <button className="bg-white border hover:border-[#2196F3] hover:bg-[#2196F3]/10 hover:text-blue-500  rounded-md cursor-pointer p-1.5 text-[1.2rem] transition-all duration-150 text-[#3D405B]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-trash "
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="4" y1="7" x2="20" y2="7" />
                            <line x1="10" y1="11" x2="10" y2="17" />
                            <line x1="14" y1="11" x2="14" y2="17" />
                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
