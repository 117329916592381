import React, { Component } from 'react';
import { connect } from 'react-redux';
import TrendChart from './Charts/TrendChart';
import Filter from '../../../../CommonComponents/Filters/IndexFilter/Filter';
import { CSVLink } from "react-csv";

class TrendDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: ''
    }
  }
  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handleEngQuesResponse } = this.props;
    this.setState({ FilterValues: finalFilter });
    handleEngQuesResponse(finalFilter, "", "", false);

  }

  render() {
    let { ErrorIndexObj, selectedFilterValue, loading, LifecycleEngamentIndexV2Data,
      indexType, optionType, getCommentData, CountRestrict, getEMPFilterLabel,

      getQuestionData, getQuestionFilterData, handleQuestionFilter, EmpFilterData, getIndexFilterData, getIndexByFilterDMGData

    } = this.props;

    let { currentSelectValue, FilterValues } = this.state;



    let QuestionData = []

    if (this.state.currFilterValue === 'none') {
      QuestionData = getQuestionData
    }
    else {
      QuestionData = getQuestionFilterData
    }


    //console.log('QuestionData--->', QuestionData)
    //console.log('getQuestionData--->', getQuestionData)
    //console.log('getQuestionFilterData--->', getQuestionFilterData)


    let paraList = []

    let count = 0;
    let rowSpan = 0;

    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    //  let DemographicDataScore = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      //  DemographicDataScore=getDemographicIndexMultiFilterData;
      //  DemographicDataDistribution=getIndexDistributionData;

    }
    let { GetParameterLabel, name } = this.props;

    //Export Excel Logic
    // let ScoreExcel = []
    // let filter = FilterValues && FilterValues.length > 0 ? " (" + FilterValues.map((element) => { return element.level + ": " + element.value }).join(",") + ")" : " (Filter: All)"

    // if (NewQuesList && NewQuesList.length > 0) {
    //   NewQuesList.forEach((question) => {
    //     let para = question && question.parameterDimensionName ?
    //       GetParameterLabel(question.parameterDimensionName) :
    //       "";
    //     let Score = CountRestrict(question && question.count ? question.count : "NA") ? 0 : question.score
    //     let temp = {}
    //     temp["Dimension " + filter] = para;
    //     temp["Question"] = question.name
    //     temp["Score(%)"] = Score

    //     ScoreExcel.push(temp)
    //   });
    // }



    return (
      <>
        {/* <div className="items-center justify-between md:flex"> */}
          {/* <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />
 */}


          {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
          {/* <div className='flex space-x-4' > */}
          
        
    
{/* 
          <div>
              {ScoreExcel ?
                <CSVLink data={ScoreExcel} filename={"EnagagementBehaviourItemSummary.csv"}>
                  <p className="flex items-center p-2 px-4 text-sm text-green-500 bg-green-100 rounded-full cursor-pointer ">
                    {"Download Excel"}
                  </p>
                </CSVLink>
                : '...'}
            </div> */}

    

            {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} /> */}
          {/* </div> */}



        {/* </div> */}


        {true ?
          <>



            <div onClick={() => this.closeFilter(false)} className="" >


            <div className="w-full" >

              <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">

              <div className="p-4 pb-6 bg-white border">

                  <h1 className="font-medium xl:text-xl pb-4 text-lg text-[#3D405B] whitespace-nowrap">Engagement Distribution Trend</h1>


                <TrendChart
                  LifecycleEngamentIndexV2Data={LifecycleEngamentIndexV2Data}
                />
                

                </div>
              </div>
              </div>

            </div>

          </>
          :
          <>
            {!loading ?
              <div className="pt-40 text-3xl text-center text-gray-400">We have not calculated this section!</div>
              : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(TrendDistribution);
