import React from "react";
// eslint-disable-next-line no-unused-vars
import moment from "moment";


export default function CompleteModal(props) {
  let { save360RelationListData, loadedDataSheet, closeUploadPreview, saveUploadRelation, handleDataChange, SheetJSFT } = props;


  let uploadListData = loadedDataSheet;



  let errorInit = []
  let IssueList = []
  let FieldList = []


  let validKeys = ["reviewer_name", "reviewer_email", "reviewer_emp_id", "reviewee_emp_id", "reviewer_relation","relation_type"]


  if(uploadListData && uploadListData.length>0){
    uploadListData.forEach((element,index)=>{


      let keyList = Object.keys(element);
      if(keyList && keyList.length>0){
        keyList.forEach((key)=>{
          let getIndex = FieldList.findIndex(prev=>prev===key);
          if(getIndex===-1){
            FieldList.push(key)
          }

        })
      }


    
      if(element && element["relation_type"] === "INTERNAL"){
        if(!(element && element["reviewer_emp_id"] && element["reviewer_emp_id"] !=="NA")){

          let getIndex = errorInit.findIndex(prev=>prev.field === "reviewer_emp_id")
          if(getIndex === -1){
            errorInit.push({
              "field":"reviewer_emp_id",
              "name":"Empty reviewer_emp_id at row "+(index+1)
            })
          }else{
            errorInit[getIndex]["name"] += ", "+(index+1)
          }

        }
      }else if(element && element["relation_type"] === "EXTERNAL"){
        if(!(element && element["reviewer_email"] && element["reviewer_email"] !=="NA")){

          let getIndex = errorInit.findIndex(prev=>prev.field === "reviewer_email")
          if(getIndex === -1){
            errorInit.push({
              "field":"reviewer_email",
              "name":"Empty reviewer_email at row "+(index+1)
            })
          }else{
            errorInit[getIndex]["name"] += ", "+(index+1)
          }



        }
      }else{
        if(!(element && element["relation_type"])){

          let getIndex = errorInit.findIndex(prev=>prev.field === "relation_type")
          if(getIndex === -1){
            errorInit.push({
              "field":"relation_type",
              "name":"Empty relation_type at row "+(index+1)
            })
          }else{
            errorInit[getIndex]["name"] += ", "+(index+1)
          }


          
        }else{
          errorInit.push({
            "field":"relation_type",
            "name":"Invalid relation_type at row "+(index+1)
          })
        }
      }
  
      if(!(element && element["reviewee_emp_id"] && element["reviewee_emp_id"] !=="NA")){

        let getIndex = errorInit.findIndex(prev=>prev.field === "reviewee_emp_id")
        if(getIndex === -1){
          errorInit.push({
            "field":"reviewee_emp_id",
            "name":"Empty reviewee_emp_id at row "+(index+1)
          })
        }else{
          errorInit[getIndex]["name"] += ", "+(index+1)
        }

        // errorInit.push({
        //   "field":"reviewee_emp_id",
        //   "name":"Empty reviewee_emp_id at row "+(index+1)
        // })
      }
  
      if(!(element && element["reviewer_relation"] && element["reviewer_relation"] !=="NA")){

        let getIndex = errorInit.findIndex(prev=>prev.field === "reviewer_relation")
        if(getIndex === -1){
          errorInit.push({
            "field":"reviewer_relation",
            "name":"Empty reviewer_relation at row "+(index+1)
          })
        }else{
          errorInit[getIndex]["name"] += ", "+(index+1)
        }

        
      }
  
  

    })
  }



  
  if(validKeys && validKeys.length>0){
    validKeys.forEach((element)=>{

        if(!(FieldList.includes(element))){
          let textA = element+ " column is missing.";
          let getIndex = IssueList.findIndex(prev=>prev.name === textA)
          if(getIndex===-1){
            IssueList.push({
              "field":element,
              "name":textA
            })
          }
        }


    })
  }




  let finalKeyList = []
  if(FieldList && FieldList.length>0){
    finalKeyList = FieldList.sort(function(a, b){  
      return validKeys.indexOf(a) - validKeys.indexOf(b);
    });
  }



  if(errorInit && errorInit.length>0 && FieldList && FieldList.length>0){
    errorInit.forEach((element)=>{
      if(FieldList.includes(element.field)){
        IssueList.push({
          "field":element.field,
          "name":element.name
        })
      }
    })
  }



  //console.log("IssueList-------------->",IssueList);
  //console.log("uploadListData-------------->",uploadListData);




  


  let elementSuccess = ""
  if(save360RelationListData && save360RelationListData.inserted>0){
    let inserted = save360RelationListData && save360RelationListData.inserted>0?save360RelationListData.inserted:0;
    let element1 = "No. of Record Inserted: "+(inserted).toString()

    elementSuccess =  element1;

  }


  //console.log("elementSuccess-------------->",elementSuccess);



  return (
   <>
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full" >
        <div className="relative left-0 right-0 max-w-5xl p-4 mx-auto mt-24 overflow-hidden">
         
          <div className="block w-full overflow-hidden bg-white rounded-lg shadow">

          <div className="flex items-center justify-between w-full p-4 px-6 border-b">
              <h2 className="text-xl font-medium text-gray-800 ">Upload Relationship Preview</h2>
              <div onClick={()=>closeUploadPreview(false)} className="inline-flex items-center justify-center p-2 text-gray-500 bg-gray-100 rounded-full cursor-pointer hover:text-gray-800" >
            <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>
          </div>

          {elementSuccess?
          <>
          
        <div className="items-center justify-center my-24 text-center">
          <h1 className="py-2 text-2xl font-medium text-center text-green-500">{'Relationships Successfully Uploaded!'}</h1>
          <h1 className="text-center text-gray-500 text-md">{elementSuccess?elementSuccess:""}</h1>
        </div>
          </>
          :
          <>

    <div className="px-4" style={{height:'500px',overflowY:'auto'}}>


    <div className="w-full p-4 overflow-auto" style={{height:'300px'}}>
      <table className="w-full p-4">
        <thead className="bg-gray-100">
          <tr className="p-2 border">
            {finalKeyList && finalKeyList.length>0?
            finalKeyList.map((field,index2)=>
              <th key={index2} className="p-2 text-sm font-bold text-center border">{field}</th>
            ):null}
            
          </tr>
        </thead>
        <tbody>
          {uploadListData && uploadListData.length>0?
          uploadListData.map((element,index)=>
          (index<7)?
            <tr key={index} className="p-2 border">
                {finalKeyList && finalKeyList.length>0?
                finalKeyList.map((field,index2)=>
                  <td key={index2} className="p-2 text-sm font-medium text-center border">{element[field]}</td>
                ):null}
            </tr>
          :null
          ):null}
          
        </tbody>
      </table>
    </div>



<div className="px-4 py-1 pt-4 text-lg font-bold">File validation was successful</div>



{/* {getColumnAdded?<div className="px-4 py-1 text-lg">{getColumnAdded}</div>:null} */}

{save360RelationListData && save360RelationListData.error?
<>
      <div className="my-4" style={{height:"150px",overflowY:'auto'}}>
      <div className="px-4 py-2">Following errors are found:</div>

        {save360RelationListData && save360RelationListData.revieweeNotFound && save360RelationListData.revieweeNotFound.length>0?
        <div className="px-4 py-1">
          <p className="text-sm text-red-700">{"1) Reviewee emp_id is not valid at row "+save360RelationListData.revieweeNotFound.map((element)=>(element)).join(",")}</p>
        </div>
        :null}

        {save360RelationListData && save360RelationListData.reviewerNotFound && save360RelationListData.reviewerNotFound.length>0?
        <div className="px-4 py-1">
          <p className="text-sm text-red-700">{"2) Reviewer emp_id is not valid at row "+save360RelationListData.reviewerNotFound.map((element)=>(element)).join(",")}</p>
        </div>
        :null}


    </div>
</>
:
<>
{!(IssueList && IssueList.length>0)?
<>
<div className="px-4 py-1 text-lg">{uploadListData && uploadListData.length>0?(uploadListData.length===1?"1 Relationship will be uploaded!":(uploadListData.length).toString()+" Relationships will be uploaded"):0}</div>
</>
:null} 
</>}


{IssueList && IssueList.length>0?
<>
    <div className="my-4" style={{height:"150px",overflowY:'auto'}}>
        <div className="px-4 py-2">Following errors are found:</div>
        {IssueList.map((item,index)=>
          <div key={index} className="px-4 py-1">
            <p className="text-sm text-red-700">{(index+1).toString()+") "+item.name}</p>
          </div>
        )}
    </div>

</>
:<div className="px-4 py-2"></div>}








</div>



<div className="justify-between p-4 space-y-6 border-t md:flex md:space-y-0">
<div/>

<div className="flex items-center justify-center space-x-4 md:justify-end">
<div/>
{!(IssueList&& IssueList.length>0) && !(save360RelationListData && save360RelationListData.error)?
<>
<button onClick={()=>saveUploadRelation()}  className="px-4 py-2 text-white capitalize bg-blue-500 border rounded-md text-md focus:outline-none">Final Upload</button>
</>
:
<>
<input type="file"
id="file" accept={SheetJSFT} onChange={handleDataChange} hidden />
<label for="file" className='bg-blue-100 rounded shadow-md cursor-pointer md:col-span-2' >
<div className='flex items-start p-2 px-4 space-x-4 bg-blue-100 2xl:items-center' >

  <span className="material-symbols-outlined">
    cloud_upload
  </span>
  <div>
    <h1 className='text-sm font-medium md:text-base' >Re-upload Master Data</h1>
    <p className='text-sm text-gray-500' >Upload master data as per the guidelines</p>
  </div>
</div>
</label>

</>}


</div>
</div>
          </>}






          </div>
            



          


        </div>
        </div>
   </>
  );
}
