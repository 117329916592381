import React from "react";
import ReactEcharts from "echarts-for-react";

export default function Demographics(props) {
  let { getExitQuestionAllDemographicsData } = props;

  let list = [];
  let count = [];
  let total = 0;

  if (
    getExitQuestionAllDemographicsData &&
    getExitQuestionAllDemographicsData.length > 0
  ) {
    getExitQuestionAllDemographicsData.forEach((item) => {
      list.push(item._id);
      count.push(item.count);
      total+=Number(item.count)

    });
  }

  // //console.log('DistObj: ',DistObj)
  function spliceDecimal(no){
    if(no){
      let str = no.toString();
      let first = str.split('.')[0];
      let second = str.split('.')[1];
      let second2 = second?second.substring(0, 2):""
      let final = first+'.'+second2;
      if(second2){
        return parseFloat(final).toFixed(2)
      }else{
        return no
      }
    }else{
      return 0
    }
  }
  
  return (
    <>
      {/* <div className="pt-1 -mt-6" style={{ width: '500px' }}> */}
      <ReactEcharts
        option={{
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            formatter: function (a, b, c, d) {
              let nameLabel = a && a[0] && a[0].name ? a[0].name : "";
              let valueLabel = a && a[0] && a[0].value ? a[0].value : "NA";
              let valueScore = nameLabel
                ? nameLabel +
                  " = " +
                  (valueLabel && valueLabel !== "NA" ? valueLabel + "" : "NA")
                : "";
              let htmlElement = "<p>" + valueScore + "</p>";
              htmlElement += '<p>' + ' (' + spliceDecimal((Number(valueLabel)*100)/total) +'%)'+ '</p>'
              return htmlElement;
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "15%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "category",
            data: list,
          },
          axisLabel: {
            color: "#000",
            // interval: 0,
            // formatter: function (value) {
            //   if (value.length > 40) {
            //     return value.substring(0, 40) + "..";
            //   } else {
            //     return value;
            //   }
            // },
          },

          series: [
            {
              name: "Demographics wise Exit",
              type: "bar",
              barWidth: "20px",
              barCategoryGap: "6px",
              stack: "total",
              label: {
                show: true,
                position: "right",
                formatter: function (value) {
                  return value.data > 0 ? value.data + "" : "0";
                },
              },

              emphasis: {
                focus: "series",
              },
              data: count,
              color: "#6536d1",
            },
          ],
        }}
      />
      {/* </div> */}
    </>
  );
}
