import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class NPSChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList: [],
      EmpIndexA: 0,
      EmpIndexB: 4
    }
  }
  componentDidMount() {

  }

  render() {
    let { overallCategoryScore, RiskgetRiskCategoryData } = this.props;

    ////console.log("overallCategoryScore-----111------->", overallCategoryScore)



    function getColor(score2, a, b) {
      let color = "#858784";
      ////console.log("score2, a, b-----111------->", score2, a, b)

      if (score2 !== "-") {
        if (true) {
          let score = Number(score2);
          if (score > 24 && score <= 30) {
            color = "#1bbf36";
          }
          else if (score > 17 && score <= 24) {
            color = "#edb409";
          }
          else if (score > 12 && score <= 17) {
            color = "#e65757";
          }
          else if (score >= 6 && score <= 12) {
            color = "#b31717";
          }
        }
      }
      else {
        color = "#e65757";
      }
      ////console.log("color-----111------->", color)

      return color
    }




    let dataItem = []
    if (RiskgetRiskCategoryData && RiskgetRiskCategoryData.length > 0) {
      RiskgetRiskCategoryData.forEach((category) => {
        dataItem.push({
          "value": Number(overallCategoryScore[category.name]),
          "name": category.name,
          itemStyle: {
            color: getColor(Number(overallCategoryScore[category.name]), category.name, overallCategoryScore[category.name], )
          }
        })
      })
    }
    ////console.log("dataItem-----111------->", dataItem)

    let temp = [
      {
        value: 20, name: 'NaN 1',
        itemStyle: {
          color: "#757575"
        }
      },
      {
        value: 20, name: 'NaN 2',
        itemStyle: {
          color: "#757575"
        }
      },
      {
        value: 20, name: 'NaN 3',
        itemStyle: {
          color: "#757575"
        }
      },
      {
        value: 20, name: 'NaN 4',
        itemStyle: {
          color: "#757575"
        }
      },
      {
        value: 20, name: 'NaN 5',
        itemStyle: {
          color: "#757575"
        }
      }
    ]

    let FinalData = dataItem && dataItem.length > 0 ? dataItem : temp

    return (
      <>


        <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] line-clamp-2">
          Drivers leading to unhappiness
        </h1>




        <div className='flex justify-center w-full' >

          <div>

            <ReactEcharts className='w-96 h-96'
              option={{
                tooltip: {
                  trigger: 'item'
                },
                // legend: {
                //   top: '5%',
                //   left: 'center'
                // },
                series: [
                  {
                    name: 'Organization Risk Drivers',
                    type: 'pie',
                    radius: ['35%', '50%'],
                    label: {
                      show: true,
                      fontSize: '12',
                      fontWeight: 'bold'
                    },
                    // emphasis: {
                    //   label: {
                    //     show: true,
                    //     fontSize: '15',
                    //     fontWeight: 'bold'
                    //   }
                    // },
                    data: FinalData
                  }
                ]
              }}
            />

          </div>
        </div>




      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(NPSChart);
