import React, { Component } from 'react';
import { connect } from 'react-redux';
import Summary from './Components/Summary';

class HappinessIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 1
    }
  }
  componentDidMount() {

  }

  onOptionType = (type) => {
    this.setState({ optionType: type });

    // if(type===2 || type===5){
    //     let { handleQuestion } = this.props;
    //     handleQuestion();
    // }

    // if(type===3){
    //   let { handleQuestionMeanScore,EmpFilterData } = this.props;
    //   if(EmpFilterData && EmpFilterData.length>0){
    //     let level = EmpFilterData[0].name;
    //     handleQuestionMeanScore(level);
    //   }
    // }

  }


  onSwitchOptionScreen = () => {
    let { handleGoBack,getAllRiskEmpConversationData, getNoRiskResolved, overallCategoryScore, DriverTable, handleRedictToProfile, CurrentSurveyId, RiskAllEmpQuesResponsesData, RiskgetRiskCategoryData, RiskFilterEmpQuesResponsesData, riskMeterCountObj, getIndexFilterData, EmpFilterData, handleRiskAllEmpQueFilter,


    } = this.props;

    let { optionType } = this.state;

    switch (optionType) {
      case 1: return <Summary
      handleGoBack={handleGoBack}
        EmpFilterData={EmpFilterData}
        getIndexFilterData={getIndexFilterData}
        riskMeterCountObj={riskMeterCountObj}
        handleRiskAllEmpQueFilter={handleRiskAllEmpQueFilter}
        RiskFilterEmpQuesResponsesData={RiskFilterEmpQuesResponsesData}
        RiskAllEmpQuesResponsesData={RiskAllEmpQuesResponsesData}
        RiskgetRiskCategoryData={RiskgetRiskCategoryData}
        CurrentSurveyId={CurrentSurveyId}
        handleRedictToProfile={handleRedictToProfile}

        DriverTable={DriverTable}
        overallCategoryScore={overallCategoryScore}
        getNoRiskResolved={getNoRiskResolved}
        getAllRiskEmpConversationData={getAllRiskEmpConversationData}
      />
      default: return <div>Default Option</div>
    }


  }

  render() {
    // let { ItemSaveData , cardType} = this.props;
    let { optionType } = this.state;

    // //console.log('--------->ItemSaveData:',ItemSaveData)
    // let indexName = ''
    // if(ItemSaveData && ItemSaveData.name){
    //   indexName = ItemSaveData.name;
    // }

    return (

      <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto  md:p-6 p-4 space-y-6 ">
        {/* <div style={{ zIndex: '100' }} className="flex border-b" >
          <div className="cursor-pointer text-xs font-medium flex text-gray-600 whitespace-nowrap overflow-x-auto overflow-hidden">
            <span onClick={() => this.onOptionType(1)} className={(optionType === 1) ? "py-3 block px-4 text-blue-600 border-b-2 border-blue-500" : "py-3 block px-4"}>Organization Risk Insight</span>
          </div>
        </div> */}
        <div className="">
          {this.onSwitchOptionScreen()}
        </div>
      </main>

    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HappinessIndex);
