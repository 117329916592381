import React, { Component } from 'react';
import { connect } from 'react-redux';
import { campaignActions, dashboardActions, surveyActions } from '../../../_actions';
import { accessModule } from '../../../_helpers';
import { CONST } from '../../../_config';
import moment from 'moment';

import CampaignList from './Components/CampaignList';
import Header from './Header';
// import { Checkboard } from 'react-color';
import ReminderCampaignCustomer from './Components/ReminderCampaignCustomer/ReminderCampaignCustomer';
import ConfirmSubmit from './Components/ReminderCampaignCustomer/ConfirmSubmit';
import ConfirmCreateCampaign from './Modal/ConfirmCreateCampaign';

import LifecycleInputCampaign from './Modal/LifecycleInputCampaign';
import CreateCampaignCustomer from './Modal/CreateCampaignCustomer';


class CampaignCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateCampaign: false,
      showReminderModal: false,
      CurrCampaignID: '',
      showReminderEmployeeFilter: false,
      empSelectRemindFilter: '',
      reminderSubmitConfirm: false,
      confirmCreateCampaignAlert: false,

      showCampaignOptions: false,
      showsurveylink: false,

      campaignValidationList: [],

      campaignFormData: {
        "title": "",
        "subject": "",
        "type": "EMAIL",
        "message": "",
        "emailName": "",
        "campaign_type": "NORMAL",
        "teamname": "",
        "surveyId": this.props.match.params.id,
        "customers": [],
        "isTest": false,
        "campaignReminderType": 0,
        "campaignReminderFreq": 0,
        "campaignReminderOption": 0,
      },
      reminderformData: {
        "action": 'Undelivered Survey',
        "actionId": 1,
        "title": '',
        "subject": '',
        "emailName": "",
        "message": '',
        "teamname": "",
        "createdAt": ""
      },

      formBatchRange: {
        "label": "1-1000",
        "rangeNo": 1,
        "size": 1000
      },

      storeBatchData: {
        "label": "1-1000",
        "rangeNo": 1,
        "size": 1000
      },

      showBatchRange: false,

      selectAll: false,
      customers: [],

      EmpIndexA: 0,
      EmpIndexB: 6,

      currentEmpPage: 1,

      selectAllEmp: false,

      showEmployeeFilter: false,

      empSelectFilter: {
        name: 'Show All',
        template: 1
      },

      showSidebar: false,
      CrrRoute: "survey",
      crrSurvey: "",

      CurrentLanguage: 'en',
      CurrentLanguageName: 'English',
      showLanguageModal: false,

      currFilterLevel: "Select Filter", showLevelFilter: false,
      currFilterValue: "Select Value", showValueFilter: false,

      employeeValueSearch: "",

      recursiveSettings: {
        "date": "",
        "frequency": 15,
        "isLaunchNow": true,
        "recursiveType": "RECURSIVE_NORMAL"
      }

    }
  }
  componentDidMount() {




    this.props.dispatch(dashboardActions.getCompanyNameOnly());

    this.props.dispatch(dashboardActions.getClientProfile());

    let data = {
      "surveyId": this.props.match.params.id
    }
    this.props.dispatch(surveyActions.getSurveyById(data));

    let data1 = {
      "surveyId": this.props.match.params.id
    }
    this.props.dispatch(campaignActions.getCampaignListBySurveyID(data1));

    // let data2 = {
    //   "keyWord": "",
    //   "pageNo": 1
    // }
    // // this.props.dispatch(campaignActions.getAllEmployees(data2));


    let data5 = {
      "rangeNo": 1,
      "size": this.state.formBatchRange.size
    }
    // this.props.dispatch(surveyActions.getCustomerBatchRange(data5));

    let data52 = {
      "rangeNo": 1,
      "size": this.state.formBatchRange.size
    }
    this.props.dispatch(surveyActions.getCustomerBatchRange(data52));


    let data3 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(surveyActions.getLanguageLabel(data3));
    this.props.dispatch(surveyActions.getLanguage(data3));





    this.props.dispatch(dashboardActions.getEmpFilter());
    this.props.dispatch(surveyActions.getIndexByFilterDMG({
      filters: []
    }));





  }


  handleReminderFreqInc = (isInc) => {
    let { campaignFormData } = this.state;
    if (isInc) {
      if (campaignFormData["campaignReminderFreq"] < 50) {
        campaignFormData["campaignReminderFreq"] += 1
      }
    } else {
      if (campaignFormData["campaignReminderFreq"] > 0) {
        campaignFormData["campaignReminderFreq"] -= 1
      }
    }
    this.setState({})
  }

  onpenSurveyLinkmodal = () => {
    this.setState({
      showsurveylink: true
    })

  }

  closeSurveyLinkmodal = () => {
    this.setState({
      showsurveylink: false
    })

  }

  handleEmpDropdown = (check) => {
    this.setState({ showEmployeeFilter: !check })
  }

  handleEmpFilterSelect = (data) => {
    this.setState({
      empSelectFilter: data,
      showEmployeeFilter: false
    })
  }

  prevEmpPage = () => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (EmpIndexA > 0) {
      this.setState({ EmpIndexA: EmpIndexA - 1, EmpIndexB: EmpIndexB - 1 });
    }
  }
  nextEmpPage = (IndexListIndex) => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (IndexListIndex && IndexListIndex.length && (EmpIndexB < IndexListIndex.length)) {
      this.setState({ EmpIndexA: EmpIndexA + 1, EmpIndexB: EmpIndexB + 1 });
    }
  }

  SelectAllEmployees = (check, EmployeesList) => {
    this.setState({ selectAllEmp: !check })

    let { customers, campaignFormData } = this.state;

    this.setState({ customers }, () => {
      if (EmployeesList && EmployeesList.length > 0) {
        if (!check) {
          EmployeesList.forEach((emp) => {
            if (customers.findIndex(prev => prev.id === emp.id) === -1) {
              customers.push({
                id: emp.id
              })
              this.setState({ customers }, () => {
                campaignFormData['customers'] = customers;
                this.setState({ campaignFormData })
              })
            }
          })
        }
        else {
          customers = []
          campaignFormData['customers'] = []
          this.setState({ customers, campaignFormData })
        }
      }
    })

  }

  SelectEmpPage = (val) => {
    this.setState({ currentEmpPage: val })
  }


  onCreateCampaign = (type) => {
    if (type === 1) {
      this.setState({
        showCreateCampaign: true,
        showReminderModal: false
      })
    }
    else if (2) {
      this.setState({
        showCreateCampaign: false,
        showReminderModal: false
      })
    }

  }

  handleCampaignInput = (e) => {
    let { campaignFormData } = this.state;

    let value = e.target.value;
    var newValue = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');

    campaignFormData[e.target.name] = newValue;
    this.setState({ campaignFormData })
  }

  handleMessageInput = (value) => {
    let { campaignFormData } = this.state;

    var newValue = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');

    campaignFormData['message'] = newValue;
    this.setState({ campaignFormData });
  }

  handleTestUserCheck = (value) => {
    let { campaignFormData } = this.state;
    campaignFormData['isTest'] = value;
    this.setState({ campaignFormData });
  }

  handleSendTypeCheck = (value) => {
    const { campaignFormData } = this.state;
    campaignFormData["type"] = value;
    this.setState({ campaignFormData });
  }

  handleCampaignEmployees = (emp) => {
    const { campaignFormData, customers, selectAll } = this.state;


    if (!selectAll) {



      if (customers.length > 0) {
        var matchFlag = null;
        customers.forEach((selectedEmp, empIndex) => {

          if (emp.id === selectedEmp.id) {
            matchFlag = 1
            var data = customers;
            data.splice(empIndex, 1);

            campaignFormData['customers'] = data;
            this.setState({ campaignFormData });

          }

        });

        if (matchFlag === 1) {
          //////console.log("matchFlag = 1:---->", customers)
        }
        else {
          var data = customers;
          data.push({ id: emp.id })


          campaignFormData['customers'] = data;
          this.setState({ campaignFormData });

          //////console.log("matchFlag = 0:---->", customers)
        }



      }
      else {

        var datax = customers;

        datax.push({ id: emp.id })

        //////console.log("setZero:---->", customers)
        campaignFormData['customers'] = datax;
        this.setState({ campaignFormData });
      }


    }


  }

  handleCampaignSubmit = () => {
    let { campaignFormData } = this.state;
    let data = {
      "title": campaignFormData.title,
      "subject": campaignFormData.subject,
      "message": campaignFormData.message,
      "type": campaignFormData.type,
      "emailName": campaignFormData.emailName,
      "campaign_type": campaignFormData && campaignFormData.campaign_type ? campaignFormData.campaign_type : null,
      "teamname": campaignFormData.teamname,
      "surveyId": campaignFormData.surveyId,
      "customers": campaignFormData.customers,
      "isTest": (campaignFormData && campaignFormData.isTest) ? true : false
    }

    // if(campaignFormData.campaign_type==="RECURSIVE"){
    //   data["recursiveDate"] = recursiveSettings && recursiveSettings.date?recursiveSettings.date:"";
    //   data["recursiveFrequency"] = recursiveSettings && recursiveSettings.frequency?recursiveSettings.frequency:0;
    //   data["recursiveIsLaunch"] = recursiveSettings && recursiveSettings.isLaunchNow?true:false;
    // }


    let validateList = [];
    let fieldNames = Object.keys(data);
    if (fieldNames && fieldNames.length > 0) {
      fieldNames.forEach((item, index) => {
        //////console.log("campaignFormData--->", campaignFormData)

        if (item === "customers") {

          if (campaignFormData.campaign_type === "NORMAL") {
            if (!(campaignFormData && campaignFormData.customers && campaignFormData.customers.length > 0)) {
              validateList.push('Select Minimum 1 Employee');
            }
          }

        }
        else {
          if (item !== "isTest") {

            if (!(data && data[item])) {

              if (item === "title") {
                validateList.push('Title');
              }
              else if (item === "campaign_type") {
                validateList.push("Campaign Type");
              }

              if (campaignFormData.type === "EMAIL") {

                if (item === "subject") {
                  validateList.push("Subject");
                }
                else if (item === "message") {
                  validateList.push("Message");
                }
                else if (item === "emailName") {
                  validateList.push("Email Name");
                }
                else if (item === "teamname") {
                  validateList.push("Team Name");
                }

              }


            }







          }
        }






      });
    }

    if (validateList && validateList.length > 0) {
      this.setState({ campaignValidationList: validateList });
    }
    else {
      this.setState({ confirmCreateCampaignAlert: true }, () => {
        this.setState({ campaignValidationList: [] });
      });

    }




  }

  handleConfirmCreateCampaignMailClose = () => {
    this.setState({ confirmCreateCampaignAlert: false });
    // let data = {
    //   "title": "",
    //   "subject": "",
    //   "message": "",
    //   "type":"EMAIL",
    //   "teamname": "",
    //   "emailName":"",
    //   "campaign_type":"",
    //   "surveyId": this.props.match.params.id,
    //   "customers": []
    // }
    // this.setState({campaignFormData:data})
  }


  onCampaignSubmit = () => {
    let { campaignFormData, recursiveSettings } = this.state;



    function scriptRemover(FormData, item) {
      let withJSdata = "";
      if (FormData && FormData[item]) {
        withJSdata = FormData[item].replace(/<script.*>.*<\/script>/ims, " ");
      }
      return withJSdata
    }

    let data = {
      "title": scriptRemover(campaignFormData, "title"),
      "subject": scriptRemover(campaignFormData, "subject"),
      "message": scriptRemover(campaignFormData, "message"),
      "type": campaignFormData.type && campaignFormData.type !== "NONE" ? campaignFormData.type : null,
      "emailName": scriptRemover(campaignFormData, "emailName"),
      "campaign_type": campaignFormData && campaignFormData.campaign_type ? campaignFormData.campaign_type : null,
      "teamname": scriptRemover(campaignFormData, "teamname"),
      "surveyId": campaignFormData.surveyId,
      "customers": campaignFormData.customers,
      "isTest": (campaignFormData && campaignFormData.isTest) ? true : false
    }

    if (campaignFormData && campaignFormData["campaignReminderOption"] && campaignFormData["campaignReminderOption"] === 1) {
      data["campaignReminderType"] = campaignFormData["campaignReminderType"];
      data["campaignReminderFreq"] = campaignFormData["campaignReminderFreq"];
      data["campaignReminderOption"] = campaignFormData["campaignReminderOption"];
    }


    if (campaignFormData.campaign_type === "RECURSIVE") {
      data["recursiveStartDate"] = recursiveSettings && recursiveSettings.date ? recursiveSettings.date : null; //2022-03-17 YYYY-MM-DD 
      data["recursiveFrequency"] = recursiveSettings && recursiveSettings.frequency ? recursiveSettings.frequency : 0;
      data["recursiveIsLaunchNow"] = recursiveSettings && recursiveSettings.isLaunchNow ? true : false;
      data["recursiveType"] = recursiveSettings && recursiveSettings.recursiveType ? recursiveSettings.recursiveType : "RECURSIVE_NORMAL";
    }



    //////console.log("---------------------createNewCampaign---------------------->", data)

    this.props.dispatch(campaignActions.createCampaignCustomer(data))




    this.setState({ confirmCreateCampaignAlert: false, showCreateCampaign: false, showReminderModal: false })
    let data2 = {
      "title": "",
      "subject": "",
      "message": "",
      "type": "EMAIL",
      "teamname": "",
      "emailName": "",
      "campaign_type": "NORMAL",
      "surveyId": this.props.match.params.id,
      "customers": []
    }
    let data3 = {
      "date": "",
      "frequency": 15,
      "isLaunchNow": true
    }
    this.setState({ campaignFormData: data2, recursiveSettings: data3 })

  }


  onRecDayCampaignLaunch = () => {
    let { survey, campaign, dashboard } = this.props;
    let { getSurveyData } = survey;
    let { campaignList } = campaign;
    let { getCompanyNameOnlyData } = dashboard;
    let { campaignFormData } = this.state;
    if (getSurveyData && getSurveyData.name && ((getSurveyData.isRiskSurveyType === "DEFAULTDAYS") || (getSurveyData.isRiskSurveyType === "DEFAULTONBOARDING")) && getSurveyData.value && !(campaignList && campaignList.length > 0)) {
      let days = Number(getSurveyData.value)
      let data = {
        surveyId: getSurveyData && getSurveyData.id ? getSurveyData.id : null,
        campaign_type: "RECURSIVEDAYS",
        recursiveType: "RECURSIVE_EMP_LOGIC",
        type: "EMAIL",

        customers: [],
        isTest: false,

        recursiveFrequency: days,
        recursiveIsLaunchNow: true,
        recursiveStartDate: null,

        title: campaignFormData["title"] ? campaignFormData["title"] : "",
        subject: campaignFormData["subject"] ? campaignFormData["subject"] : "",
        emailName: campaignFormData["emailName"] ? campaignFormData["emailName"] : "",
        message: campaignFormData["message"] ? campaignFormData["message"] : "<p>Welcome, please take this Survey</p>",

        teamname: campaignFormData["teamname"] ? campaignFormData["teamname"] : (getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : "HappyPlus")
      }
      //////console.log("recursiveDayinput-------->", data)
      this.props.dispatch(campaignActions.createNewCampaign(data))
    }


  }


  onReportCampaign = (data) => {
    let surveyId = this.props.match.params.id;
    // if (data && data.campaign_type && data.campaign_type === "KIOSK") {
    //   this.props.history.push(`/app/campaignkioskreport/${data.id}/${surveyId}`);
    // }
    // else {
    //   this.props.history.push(`/app/campaignreport/${data.id}/${surveyId}`);
    // }

    this.props.history.push(`/app/campaignreportcustomer/${data.id}/${surveyId}`);

  }

  onReminderCampaign = (data) => {
    let { reminderformData } = this.state;
    this.setState({ showReminderModal: true })
    reminderformData['title'] = data.title;
    reminderformData['subject'] = "Re: " + data.subject;
    reminderformData['emailName'] = data.emailName;
    reminderformData['message'] = data.message;
    reminderformData['teamname'] = data.teamname;
    reminderformData['createdAt'] = data.createdAt;

    this.setState({ CurrCampaignID: data.id, reminderformData })

    let campaignObj = {
      "surveyId": this.props.match.params.id,
      "campaignId": data.id
    }
    this.props.dispatch(campaignActions.getCampaignDetailsById({
      "id": data.id
    }));
    this.props.dispatch(campaignActions.getCampaignEmployeesByID(campaignObj));


  }



  handleReminderInput = (e) => {
    let { reminderformData } = this.state;

    let value = e.target.value;
    var newValue = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');

    reminderformData[e.target.name] = newValue;
    this.setState({ reminderformData })
  }


  handleReminderMessageInput = (value) => {
    let { reminderformData } = this.state;
    var newValue = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');

    reminderformData["message"] = newValue;
    this.setState({ reminderformData })
  }

  handleEmpReminderDropdown = (check) => {
    this.setState({ showReminderEmployeeFilter: !check })
  }

  handleEmpRemindFilterSelect = (value) => {
    //console.log('handleEmpRemindFilterSelect', value)
    let { reminderformData } = this.state;
    reminderformData['action'] = value.type;
    reminderformData['actionId'] = value.id;
    this.setState({ reminderformData, showReminderEmployeeFilter: false })
  }

  handleReminderSubmit = () => {
    this.setState({ reminderSubmitConfirm: true })

  }

  handleConfirmReminderMailClose = () => {
    this.setState({ reminderSubmitConfirm: false })
  }


  onReminderSubmit = () => {
    let { reminderformData, CurrCampaignID } = this.state;


    function scriptRemover(FormData, item) {
      let withJSdata = "";
      if (FormData && FormData[item]) {
        withJSdata = FormData[item].replace(/<script.*>.*<\/script>/ims, " ");
      }
      return withJSdata
    }


    let data = {
      "campaignId": CurrCampaignID,
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "isMailDeliveryFailed": (reminderformData.actionId === 2) ? false : true,
      "subject": scriptRemover(reminderformData, "subject"),
      "emailName": scriptRemover(reminderformData, "emailName"),
      "message": scriptRemover(reminderformData, "message")
    }

    //console.log('onReminderSubmit: ', data)
    this.props.dispatch(campaignActions.resendCustomerMailInBulk(data));
    this.setState({ reminderSubmitConfirm: false, showReminderModal: false, showCreateCampaign: false })

  }



  handleLanguage = (item) => {
    this.setState({ CurrentLanguage: item.shortName, CurrentLanguageName: item.name, showLanguageModal: false });
  }




  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  }
  routeLink = (link) => {
    this.props.history.push('/app/' + link);
    this.setState({ CrrRoute: link });
  }
  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  }

  handleCampaignOptions = (value) => {
    if (value === this.state.showCampaignOptions) {
      this.setState({ showCampaignOptions: '' });
    } else {
      this.setState({ showCampaignOptions: value });
    }
  }

  chooseCampaignOption = (item, optionType) => {
    if (optionType === 1) {
      this.onReportCampaign(item);
    }
    else if (optionType === 2) {
      this.onReminderCampaign(item);
    }
    this.setState({ showCampaignOptions: '' });
  }



  handleEmpLevelDropdown = (check) => {
    this.setState({ showLevelFilter: !check, showValueFilter: false });
  }

  handleEmpValueDropdown = (check) => {
    this.setState({ showValueFilter: !check, showLevelFilter: false });
  }


  handleLevelSelect = (level) => {

    if (level === "All") {
      this.setState({ currFilterLevel: "Select Filter", currFilterValue: "Select Value", showValueFilter: false, showLevelFilter: false });
    }
    else {
      this.setState({ currFilterLevel: level, currFilterValue: "Select Value", showLevelFilter: false });
    }

  }


  handleValueSelect = (value) => {
    this.setState({ currFilterValue: value, showValueFilter: false });
  }

  selectBatchSize = (item) => {
    let { formBatchRange } = this.state;
    formBatchRange["size"] = item;
    formBatchRange["label"] = "";
    formBatchRange["rangeNo"] = 1;
    this.setState({ formBatchRange });

    // let data5 = {
    //   "rangeNo": 1,
    //   "size":item
    // }
    // this.props.dispatch(surveyActions.getCustomerBatchRange(data5));

  }

  selectBatchRange = (item) => {
    let { campaignFormData } = this.state;

    this.setState({ storeBatchData: item })

    campaignFormData["customers"] = [];
    this.setState({ selectAllEmp: false, campaignFormData, customers: [] });

    //console.log('item; ', item.rangeNo);
    let { formBatchRange } = this.state;
    formBatchRange["label"] = item.label;
    formBatchRange["rangeNo"] = item.rangeNo;
    this.setState({ formBatchRange, showBatchRange: false }, () => {
      this.handleGetEmployees();
    });



    // campaignFormData["customers"]=[];
    // this.setState({selectAllEmp:false,campaignFormData,customers:[]});

    // //console.log('item; ',item.rangeNo);
    // let { formBatchRange } = this.state;
    // formBatchRange["label"] = item.label;
    // formBatchRange["rangeNo"] = item.rangeNo;
    // this.setState({formBatchRange,showBatchRange:false});
    // let data5 = {
    //   "rangeNo": item.rangeNo,
    //   "size":formBatchRange.size
    // }
    // this.props.dispatch(surveyActions.getCustomerBatchRange(data5));

  }

  handleBatchRangeDropdown = (check) => {
    this.setState({ showBatchRange: check });
  }


  handleCampaignType = (value) => {
    let { campaignFormData } = this.state;
    campaignFormData['campaign_type'] = value;
    this.setState({ campaignFormData });
  }


  handleType = (value) => {
    let { campaignFormData } = this.state;
    campaignFormData['type'] = value;
    this.setState({ campaignFormData });
  }


  handleEmployeeValueSearch = (e) => {
    this.setState({ employeeValueSearch: e.target.value }, () => {


      let data5 = {
        keyWord: this.state.employeeValueSearch,
        pageNo: 1,
        size: 10,
      }
      this.props.dispatch(surveyActions.getEmployeeSearch(data5));



    });

  }



  handleGetEmployees = (item) => {
    let { formBatchRange, currFilterLevel, currFilterValue } = this.state;


    let data5 = {
      "rangeNo": formBatchRange.rangeNo,
      "size": formBatchRange.size,

    }
    if (currFilterLevel !== "Select Filter" && currFilterValue !== "Select Value") {
      // data5["filterName"] = currFilterLevel;
      // data5["filterValue"] = currFilterValue;


      data5["filters"] = [{
        "level": currFilterLevel,
        "value": currFilterValue
      }]


    }




    //////console.log("FilterName---->", data5)

    this.props.dispatch(surveyActions.getCustomerBatchRange(data5));

  }


  onGenerateCompanySurveyCode = () => {
    let data5 = {
      "id": this.props.match.params.id
    }

    let temp = {
      "surveyId": this.props.match.params.id
    }

    this.props.dispatch(dashboardActions.generateCompanySurveyCode(data5, temp));
  }


  goBackFn = () => {
    let { showCreateCampaign, showReminderModal } = this.state;
    if (showReminderModal) {
      this.setState({ showReminderModal: false });

    }
    else if (showCreateCampaign) {
      this.onCreateCampaign(2);
    } else {
      this.props.history.goBack();
    }
  };


  handleRecursiveCampaignInput = (e) => {
    let { recursiveSettings } = this.state;
    recursiveSettings[e.target.name] = e.target.value;
    this.setState({ recursiveSettings });
  }

  handleCampaignRecursiveType = (check) => {
    let { recursiveSettings } = this.state;
    recursiveSettings["isLaunchNow"] = check;
    this.setState({ recursiveSettings })
  }

  handleRecursiveType = (check) => {
    let { recursiveSettings } = this.state;
    recursiveSettings["recursiveType"] = check;
    this.setState({ recursiveSettings })
  }

  gotoRecursiveDashboard = (item, type) => {
    let surveyId = this.props.match.params.id;
    let campaignId = item.id;
    if (campaignId && surveyId) {
      if (type === "rtype1") {
        this.props.history.push('/app/recursivedashboardt1/' + surveyId + "/" + campaignId);
      }
      else if (type === "rtype2") {
        this.props.history.push('/app/recursivedashboardt2/' + surveyId + "/" + campaignId);
      }
    }
  }


  handleExitSurveyAutofill = () => {
    let { survey } = this.props;
    let { getSurveyData } = survey;
    if (getSurveyData && getSurveyData.isRiskSurveyType && getSurveyData.isRiskSurveyType === "DEFAULTEXIT") {
      let { campaignFormData } = this.state;
      campaignFormData["title"] = "Exit Survey"
      campaignFormData["subject"] = "This is Exit Survey Subject"
      campaignFormData["message"] = "<p>This is Exit Survey Message</p>"
      campaignFormData["emailName"] = "Exit Survey emailName"
      campaignFormData["teamname"] = "Exit Survey teamname"

      this.setState({ campaignFormData })

    }

  }


  handleRecDaysActive = (value) => {
    let data5 = {
      "surveyId": this.props.match.params.id,
      "id": value
    }
    let data5temp2 = {
      "surveyId": this.props.match.params.id
    }
    this.props.dispatch(surveyActions.updateActiveStatusRecCampaign(data5, data5temp2));
  }

  handleCampaignReminderType = (value) => {
    let { campaignFormData } = this.state;
    campaignFormData["campaignReminderType"] = value;
    campaignFormData["campaignReminderFreq"] = 1;
    this.setState({ campaignFormData })
  }

  handleCampaignReminderOption = (value) => {
    let { campaignFormData } = this.state;
    if (value === 0) {
      campaignFormData["campaignReminderType"] = 0;
      campaignFormData["campaignReminderFreq"] = 0;
    }
    campaignFormData["campaignReminderOption"] = value;
    this.setState({ campaignFormData })
  }

  handleReminderActiveStatus = (id) => {
    let data = {
      "id": id
    }
    let dataV2 = {
      "surveyId": this.props.match.params.id
    }
    this.props.dispatch(campaignActions.updateReminderActiveStatus(data, dataV2));

  }

  render() {

    let { recursiveSettings, campaignValidationList, formBatchRange, showBatchRange, employeeValueSearch } = this.state;



    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    //console.log('moduleAccess: ', moduleAccess)



    //let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        //SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    // function accessAllow(item) {
    //   return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    // }

    //END -------------------------------------------------------






    let { campaign, survey, dashboard } = this.props;
    let { EmpFilterData, getCompanyNameOnlyData } = dashboard;

    let { campaignList, getCampaignCustomerssByIDData, getCampaignActiveHistoryData, getCampaignActiveLaunchDateWiseData, getCampaignDetailsByIdData } = campaign;
    let { getIndexByFilterDMGData, getLanguageLabelData, getLanguageData, getSurveyData, getEmployeeSearchData, getCustomerBatchRangeData } = survey;
    let { showCampaignOptions, showLanguageModal, CurrentLanguageName, CurrentLanguage, reminderSubmitConfirm, confirmCreateCampaignAlert, empSelectRemindFilter, showReminderEmployeeFilter, reminderformData, showReminderModal, showCreateCampaign, campaignFormData, customers, EmpIndexA, EmpIndexB, currentEmpPage, selectAllEmp, showEmployeeFilter, empSelectFilter } = this.state;
    // //console.log('getCampaignCustomerssByIDData: ',getCampaignCustomerssByIDData)

    let IsAutomatic = false;
    if (getCampaignDetailsByIdData && getCampaignDetailsByIdData.reminderOption && Number(getCampaignDetailsByIdData.reminderOption) === 1) {
      IsAutomatic = true
    }
    //console.log("campaignFormData---------->", campaignFormData)
    //console.log("IsAutomatic---------->", IsAutomatic)


    let companySurveyCode = "";
    if (getSurveyData && getSurveyData.code) {
      companySurveyCode = getSurveyData.code;
    }


    let allCustomerList = [];
    let AllEmpTotal = 0;

    if (employeeValueSearch) {
      allCustomerList = getEmployeeSearchData && getEmployeeSearchData.list && getEmployeeSearchData.list.length > 0 ? getEmployeeSearchData.list : [];
      AllEmpTotal = getEmployeeSearchData && getEmployeeSearchData.total ? getEmployeeSearchData.total : 0;
    }
    else {
      allCustomerList = getCustomerBatchRangeData && getCustomerBatchRangeData.list && getCustomerBatchRangeData.list.length > 0 ? getCustomerBatchRangeData.list : [];
      AllEmpTotal = getCustomerBatchRangeData && getCustomerBatchRangeData.total ? getCustomerBatchRangeData.total : 0;
    }




    let loading = survey.loading || campaign.loading;

    let SurveyName = '';
    if (getSurveyData) {
      SurveyName = getSurveyData.name;
    }

    let CustomerList = []
    if (allCustomerList && allCustomerList.length > 0) {

      if (empSelectFilter.template === 2) {
        allCustomerList.forEach((item, index) => {
          if (!item.isDisable) {
            CustomerList.push(item)
          }
        })
      }
      else if (empSelectFilter.template === 3) {
        allCustomerList.forEach((item, index) => {
          if (item.isDisable) {
            CustomerList.push(item)
          }
        })
      }
      else {
        CustomerList = allCustomerList;
      }



    }


    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }
    let EmployeFilterList = [
      {
        name: LanguageObj && LanguageObj["show_all_" + CurrentLanguage] ? LanguageObj["show_all_" + CurrentLanguage] : "Show All",
        template: 1
      },
      {
        name: LanguageObj && LanguageObj["active_" + CurrentLanguage] ? LanguageObj["active_" + CurrentLanguage] : "Active",
        template: 2
      },
      {
        name: LanguageObj && LanguageObj["inactive_" + CurrentLanguage] ? LanguageObj["inactive_" + CurrentLanguage] : "InActive",
        template: 3
      },
    ]

    let actionList = []

    if (IsAutomatic) {
      actionList = [
        {
          id: 3,
          type: LanguageObj && LanguageObj["reminder_to_not_delivered_" + CurrentLanguage] ? LanguageObj["reminder_to_not_delivered_" + CurrentLanguage] :
            'Undelivered Survey',
        }
      ]
    } else {
      actionList = [
        {
          id: 2,
          type: LanguageObj && LanguageObj["reminder_to_incomplete_" + CurrentLanguage] ? LanguageObj["reminder_to_incomplete_" + CurrentLanguage] :
            'Incomplete Survey',
        },
        {
          id: 3,
          type: LanguageObj && LanguageObj["reminder_to_not_delivered_" + CurrentLanguage] ? LanguageObj["reminder_to_not_delivered_" + CurrentLanguage] :
            'Undelivered Survey',
        }
      ]
    }





    let { ClientProfileData } = this.props.dashboard;
    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageractionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }



    let {
      currFilterLevel, showLevelFilter,
      currFilterValue, showValueFilter
    } = this.state;


    // //console.log('currFilterLevel: ',currFilterLevel);
    // //console.log('confirmCreateCampaignAlert: ',confirmCreateCampaignAlert);

    //console.log('formBatchRange: ', formBatchRange);



    // let campaignTypeList = ['RECURSIVE', 'NORMAL', 'EVENT', 'SEGMENT', 'CUSTOMER','KIOSK'];
    // let campaignTypeList = ['NORMAL','CUSTOMER','KIOSK'];
    let campaignTypeList = ['NORMAL', 'CUSTOMER', 'KIOSK', 'RECURSIVE'];

    let campaignReminderTypeList = [
      {
        "label": "No Reminder",
        "value": 0
      },
      {
        "label": "7 Days",
        "value": 7
      },
      {
        "label": "15 Days",
        "value": 15
      },
      {
        "label": "30 Days",
        "value": 30
      }
    ];


    let campaignReminderOptionList = [
      {
        "label": "MANUAL",
        "value": 0
      },
      {
        "label": "AUTOMATIC",
        "value": 1
      }
    ];


    let TypeList = ['EMAIL', 'MOBILE', 'NONE']

    // let campaignTypeList = ['NORMAL'];



    //////console.log("---EmpFilterData---->", EmpFilterData)
    //////console.log("---getIndexByFilterDMGData---->", getIndexByFilterDMGData)


    let campaignRecursiveFreqList = [
      {
        "label": "Launch Now",
        "value": true
      },
      {
        "label": "Select Launch Date",
        "value": false
      }
    ];


    let campaignRecursiveTypeList = [
      {
        "label": "RECURSIVE NORMAL",
        "value": "RECURSIVE_NORMAL"
      },
      {
        "label": "RECURSIVE LIFECYCLE",
        "value": "RECURSIVE_EVENT"
      }
    ];
    // {
    //   "label":"RECURSIVE EVENT",
    //   "value":"RECURSIVE_EVENT"
    // }

    // let SurveyisExpire = '';
    let SurveyexpireTime = '';

    if (getSurveyData) {
      SurveyName = getSurveyData.name;
      // SurveyisExpire = getSurveyData.isExpire ? true : (moment(moment.unix(getSurveyData.expireTime / 1000).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) ? true : false);
      SurveyexpireTime = (getSurveyData && getSurveyData.expireTime) ? moment.unix(getSurveyData.expireTime / 1000).format("DD MMM YYYY") : moment().format("DD MMM YYYY");
    }






    let isRecursiveDays = false;
    if (getSurveyData && (getSurveyData.isRiskSurveyType === "DEFAULTDAYS" || getSurveyData.isRiskSurveyType === "DEFAULTONBOARDING")) {
      isRecursiveDays = true;
    }

    let isValidSurvey = false;
    if (getSurveyData && getSurveyData.id) {
      isValidSurvey = true
    }

    return (

      <div className='bg-[#f9fafb] h-screen overflow-y-auto'>
        <div>
          <Header onLogout={this.onLogout}
            CurrentLanguageName={CurrentLanguageName}
            getLanguageData={getLanguageData}
            handleLanguage={this.handleLanguage}
            openLanguageModal={this.openLanguageModal}
            showLanguageModal={showLanguageModal}
            goBackFn={this.goBackFn}
            companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}

          />

          <div className="sticky z-20 flex items-center justify-between h-16 px-4 py-1 py-2 bg-white border-b top-14 ">
            <div className="flex space-x-2 text-gray-700">
              <h2 className="xl:text-lg text-base text-[#3D405B] truncate font-medium"> {SurveyName}  </h2>
            </div>
            <div className="flex items-center space-x-4 ">

              {companySurveyCode ?
                <button onClick={() => this.onpenSurveyLinkmodal()} className="md:px-6 text-sm border border-[#00CD7E] uppercase rounded-md md:py-2 p-2 text-[#00CD7E] font-medium cursor-pointer flex items-center justify-center space-x-2"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" className="text-[1.5rem]" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M383.822 344.427c-16.045 0-31.024 5.326-41.721 15.979l-152.957-88.42c1.071-5.328 2.142-9.593 2.142-14.919 0-5.328-1.071-9.593-2.142-14.919l150.826-87.35c11.762 10.653 26.741 17.041 43.852 17.041 35.295 0 64.178-28.766 64.178-63.92C448 72.767 419.117 44 383.822 44c-35.297 0-64.179 28.767-64.179 63.92 0 5.327 1.065 9.593 2.142 14.919l-150.821 87.35c-11.767-10.654-26.741-17.041-43.856-17.041-35.296 0-63.108 28.766-63.108 63.92 0 35.153 28.877 63.92 64.178 63.92 17.115 0 32.089-6.389 43.856-17.042l151.891 88.421c-1.076 4.255-2.141 8.521-2.141 13.847 0 34.094 27.806 61.787 62.037 61.787 34.229 0 62.036-27.693 62.036-61.787.001-34.094-27.805-61.787-62.035-61.787z"></path></svg><p className="hidden md:block">share link</p></button>
                :

                <button
                  className="px-2 py-2 text-white bg-green-500 rounded-md"
                  onClick={() => this.onGenerateCompanySurveyCode()}>
                  Generate Survey Code
                </button>}

              <button className="  py-2 text-sm md:block hidden rounded-full  capitalize  font-medium text-[#3D405B]"><b className="font-normal text-gray-500">Exp: </b>{SurveyexpireTime}</button>
            </div>

            {/* end */}
          </div>


          {isValidSurvey ?
            <>


              {isRecursiveDays ?
                <>
                  {campaignList && campaignList.length > 0 ?
                    <CampaignList
                      campaignList={campaignList}
                      onReportCampaign={this.onReportCampaign}
                      onReminderCampaign={this.onReminderCampaign}
                      LanguageObj={LanguageObj}
                      CurrentLanguage={CurrentLanguage}
                      handleCampaignOptions={this.handleCampaignOptions}
                      showCampaignOptions={showCampaignOptions}
                      chooseCampaignOption={this.chooseCampaignOption}

                      onCreateCampaign={this.onCreateCampaign}

                      gotoRecursiveDashboard={this.gotoRecursiveDashboard}

                      isRecursiveDays={true}
                      handleRecDaysActive={this.handleRecDaysActive}
                      getCampaignActiveHistoryData={getCampaignActiveHistoryData}
                      handleReminderActiveStatus={this.handleReminderActiveStatus}
                      getCampaignActiveLaunchDateWiseData={getCampaignActiveLaunchDateWiseData}
                    />
                    :
                    <>
                      {!loading ?
                        <div className="w-full bg-white"  >
                          {/*  text start*/}
                          <div className='grid w-full grid-cols-1 gap-6 bg-gray-100 xl:grid-cols-3 lg:grid-cols-2' style={{ height: 'calc(100vh - 8rem)' }}>

                            <div className='relative flex flex-col justify-between w-full bg-white border-r xl:col-span-1 lang ' style={{ height: 'calc(100vh - 8rem)' }}>

                              <LifecycleInputCampaign
                                campaignFormData={campaignFormData}
                                handleCampaignInput={this.handleCampaignInput}
                                handleMessageInput={this.handleMessageInput}
                              />

                              <button onClick={() => this.onRecDayCampaignLaunch()} className="sticky bottom-0 flex items-center justify-center w-full px-6 py-3 text-lg font-medium text-white uppercase bg-blue-500 focus:outline-none "> LAUNCH campaign <span className="ml-2 material-symbols-outlined">
                                send
                              </span></button>

                            </div>

                            <div className='flex items-center justify-center xl:col-span-2 ' >
                              <div className='flex-col items-center hidden -space-x-6 space-y-16 lg:flex ' >
                                <h1 className="text-xl font-semibold text-center text-gray-800 capitalize cursor-default 2xl:text-3xl xl:text-2xl "> Launching  {SurveyName}'s Campaign </h1>
                                <img alt="icon" src='/img/launch_campaign.png' className='w-4/6 mx-auto' />
                              </div>

                            </div>

                          </div>


                        </div>
                        : null}
                    </>
                  }




                </>
                :
                <>
                  <div className={this.state.showsurveylink ? " fixed h-screen bg-black bg-opacity-40 md:flex items-center     overflow-x-auto md:space-x-2 md:space-y-0 space-y-4 py-1 w-full top-0 left-0 flex items-center justify-center z-40" : "hidden"}>
                    <div className='w-5/6 mx-auto mb-20 bg-white rounded-md xl:w-4/12 lg:w-4/6'>
                      <div className="flex items-center justify-between p-4 px-6 border-b">
                        <h1 className='text-lg font-semibold text-center text-gray-800 ' >Survey Unique URL</h1>
                        <span onClick={() => this.closeSurveyLinkmodal()} className="material-symbols-outlined bg-gray-100 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150">close</span>
                      </div>

                      <div className='p-4 space-y-2' >
                        <label className='text-sm font-medium text-gray-600 capitalize' >survey link</label>
                        <div className='flex items-center justify-between w-full bg-white border border-green-300 rounded-md '>
                          <input value={(CONST.SURVEY_LOGIN + companySurveyCode).toString()} disabled={true} className="w-full p-2 text-green-500 select-all cursor-text" />

                        </div>
                        <div className='text-sm font-medium text-gray-500'>*This URL can be used by all who have no access to Survey Email.</div>
                      </div>

                      <div className='p-4' >
                        <button className='flex items-center justify-center w-full px-4 py-3 text-base font-medium text-white uppercase bg-green-500 rounded-md ' >
                          <span className="mr-2 material-symbols-outlined">
                            content_copy
                          </span>
                          Copy link

                        </button>

                      </div>
                    </div>
                  </div>







                  {showReminderModal ?
                    <ReminderCampaignCustomer
                      reminderformData={reminderformData}
                      handleReminderInput={this.handleReminderInput}
                      handleReminderMessageInput={this.handleReminderMessageInput}
                      campaignList={campaignList}
                      getCampaignEmployeesByIDData={getCampaignCustomerssByIDData}
                      actionList={actionList}
                      showReminderEmployeeFilter={showReminderEmployeeFilter}
                      handleEmpReminderDropdown={this.handleEmpReminderDropdown}
                      empSelectRemindFilter={empSelectRemindFilter}
                      handleEmpRemindFilterSelect={this.handleEmpRemindFilterSelect}
                      handleReminderSubmit={this.handleReminderSubmit}
                      LanguageObj={LanguageObj}
                      CurrentLanguage={CurrentLanguage}
                      getCampaignDetailsByIdData={getCampaignDetailsByIdData}
                    />
                    :
                    <>
                      {showCreateCampaign ?
                        <CreateCampaignCustomer
                          campaignFormData={campaignFormData}
                          handleCampaignInput={this.handleCampaignInput}
                          handleMessageInput={this.handleMessageInput}
                          handleCampaignSubmit={this.handleCampaignSubmit}
                          allCustomerList={CustomerList}
                          // allCustomerList={allCustomerList}

                          handleCampaignEmployees={this.handleCampaignEmployees}

                          customers={customers}

                          EmpIndexA={EmpIndexA}
                          EmpIndexB={EmpIndexB}

                          prevEmpPage={this.prevEmpPage}
                          nextEmpPage={this.nextEmpPage}

                          SelectEmpPage={this.SelectEmpPage}
                          currentEmpPage={currentEmpPage}
                          SelectAllEmployees={this.SelectAllEmployees}
                          selectAllEmp={selectAllEmp}

                          showEmployeeFilter={showEmployeeFilter}

                          EmployeFilterList={EmployeFilterList}

                          empSelectFilter={empSelectFilter}

                          handleEmpFilterSelect={this.handleEmpFilterSelect}

                          handleEmpDropdown={this.handleEmpDropdown}

                          handleTestUserCheck={this.handleTestUserCheck}
                          LanguageObj={LanguageObj}
                          CurrentLanguage={CurrentLanguage}



                          currFilterLevel={currFilterLevel}
                          currFilterValue={currFilterValue}

                          showLevelFilter={showLevelFilter}
                          showValueFilter={showValueFilter}

                          handleEmpLevelDropdown={this.handleEmpLevelDropdown}
                          handleEmpValueDropdown={this.handleEmpValueDropdown}

                          handleLevelSelect={this.handleLevelSelect}
                          handleValueSelect={this.handleValueSelect}


                          handleSendTypeCheck={this.handleSendTypeCheck}

                          AllEmpTotal={AllEmpTotal}

                          formBatchRange={formBatchRange}

                          selectBatchRange={this.selectBatchRange}
                          selectBatchSize={this.selectBatchSize}

                          showBatchRange={showBatchRange}
                          handleBatchRangeDropdown={this.handleBatchRangeDropdown}
                          campaignTypeList={campaignTypeList}
                          handleCampaignType={this.handleCampaignType}

                          employeeValueSearch={employeeValueSearch}
                          handleEmployeeValueSearch={this.handleEmployeeValueSearch}
                          handleGetEmployees={this.handleGetEmployees}
                          campaignValidationList={campaignValidationList}

                          EmpFilterData={EmpFilterData}
                          getIndexByFilterDMGData={getIndexByFilterDMGData}

                          recursiveSettings={recursiveSettings}

                          handleRecursiveCampaignInput={this.handleRecursiveCampaignInput}

                          campaignRecursiveFreqList={campaignRecursiveFreqList}

                          campaignRecursiveTypeList={campaignRecursiveTypeList}

                          handleCampaignRecursiveType={this.handleCampaignRecursiveType}

                          handleRecursiveType={this.handleRecursiveType}

                          handleType={this.handleType}

                          TypeList={TypeList}

                          getSurveyData={getSurveyData}
                          handleExitSurveyAutofill={this.handleExitSurveyAutofill}

                          campaignReminderTypeList={campaignReminderTypeList}
                          handleCampaignReminderType={this.handleCampaignReminderType}

                          handleReminderFreqInc={this.handleReminderFreqInc}

                          campaignReminderOptionList={campaignReminderOptionList}
                          handleCampaignReminderOption={this.handleCampaignReminderOption}
                        />
                        :
                        <>
                          {campaignList && campaignList.length > 0 ?
                            <CampaignList
                              campaignList={campaignList}
                              onReportCampaign={this.onReportCampaign}
                              onReminderCampaign={this.onReminderCampaign}
                              LanguageObj={LanguageObj}
                              CurrentLanguage={CurrentLanguage}
                              handleCampaignOptions={this.handleCampaignOptions}
                              showCampaignOptions={showCampaignOptions}
                              chooseCampaignOption={this.chooseCampaignOption}

                              onCreateCampaign={this.onCreateCampaign}

                              gotoRecursiveDashboard={this.gotoRecursiveDashboard}
                              handleReminderActiveStatus={this.handleReminderActiveStatus}

                            />
                            :
                            <>
                              {!loading ?
                                <div className="flex items-center justify-center w-full bg-gray-100 " style={{ height: 'calc(100vh - 8rem)' }}>
                                  {/*  text start*/}
                                  <div className="text-center">
                                    <img alt="icon" src="img/empty_survey.png" className="mx-auto" />
                                    {/* <h1 className="text-lg font-semibold text-gray-800 cursor-default">No Campaign has been created yet.</h1> */}
                                    {/* <p className="text-xs text-gray-500 cursor-default">Let's get started &amp; create your first campaign!</p>    */}
                                    <h1 className="text-lg font-semibold text-gray-800 cursor-default">Let's get started, create your first campaign!</h1>

                                    <div className="flex justify-center"><button onClick={() => this.onCreateCampaign(1)} className="flex items-center px-6 py-3 my-6 text-sm font-medium text-white bg-blue-500 rounded-md focus:outline-none"><span className="mr-2 material-symbols-outlined">add</span> CREATE CAMPAIGN</button>    </div>
                                  </div>
                                  {/* tex end */}
                                </div>
                                : null}
                            </>
                          }
                        </>
                      }
                    </>
                  }



                </>}

            </>
            : null}










          {reminderSubmitConfirm ?

            <ConfirmSubmit
              onReminderSubmit={this.onReminderSubmit}
              handleConfirmReminderMailClose={this.handleConfirmReminderMailClose}
            />

            : null}


          {confirmCreateCampaignAlert ?

            <ConfirmCreateCampaign
              handleSendSingleMail={this.onCampaignSubmit}
              handleConfirmMailClose={this.handleConfirmCreateCampaignMailClose}
            />



            : null}



        </div>


      </div>

    );
  }
}
function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { campaign, survey, dashboard } = state;
  return {
    loggingIn,
    campaign,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(CampaignCustomer);
