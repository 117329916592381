import { questionsConstants } from '../_constants';
import { questionsService } from '../_services';
import { surveyActions } from '../_actions';

export const questionsActions = {
    createQuestion,
    updateQuestion,
    updateQuesAllMandatory,

    disableQuestions,
    deleteQuestion,
    copyQuestion,
    getAllQuestions,
    getQuestionListBySurveyID,
    handleNameQuestion,
    handleQuestionTime,
    handleSubQuestionName,
    handleNameOption,
    handleBotOption,
    handleFeedbackOption,
    handlequestionDesign,
    handleNameOptionHeading,
    deleteOption,
    addOption,
    surveySubmit,
    handleParaInput,
    handleUpdateWeightage,
    handleNameWeightage,
    handleMandatoryQuestion,
    handleSubmitPreChange,
    handleParameterQuestion,
    updateQuestionRenk,
    copyFromQuestionLibrary,

    translateQuestion,
    translateManyQuestions,
    translateSurvey,
    getType1AllQuestionList,
    updateSurveyParameter,



    getQuestionList,
    getSurveyTemplateByCode,
    getLanguageLabel,
    saveAnswers,
    emptyReducer,

    autoChangeDashboardType,

    onSurveyAllQueSeconds,
    addNudgeToQuestion,
    deleteManyQuestions,
    updateQuestionDMG

    // getParameterListbyParentId
};



function addNudgeToQuestion(data,temp) {
    return dispatch => {
        dispatch(request());
        questionsService.addNudgeToQuestion(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.ADD_NUDGE_TO_QUES_REQUEST } }
    function success(questions) { return { type: questionsConstants.ADD_NUDGE_TO_QUES_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.ADD_NUDGE_TO_QUES_FAILURE, error } }
}



function createQuestion(data) {
    let survey = {
        surveyId: data.surveyId,
    }
    return dispatch => {
        dispatch(request());
        questionsService.createQuestion(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.ADD_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.ADD_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.ADD_QUESTIONS_FAILURE, error } }
}


function deleteManyQuestions(data) {
    let survey = {
        surveyId: data.surveyId
    }
    return dispatch => {
        dispatch(request());
        questionsService.deleteManyQuestions(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    
    function request() { return { type: questionsConstants.DELETE_MANY_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.DELETE_MANY_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.DELETE_MANY_QUESTIONS_FAILURE, error } }
}



function updateQuestion(data,call, call2) {
    let tempdata = {
        surveyId: data.surveyId,
    }
    let updateData = {   
        id:data.id,
        surveyId: data.surveyId,
        name: data.name,
        options:[],
        questionTypeId: data.questionTypeId,
        templateId: data.templateId,
        designId: data.designId,
        seconds: parseInt(data.seconds),
        isMandatory: data.isMandatory,
        parameterBucketId:(data && data.parameterBucketId && data.parameterBucketId.id)?data.parameterBucketId.id: "",
        parameterDimensionId:(data && data.parameterDimensionId && data.parameterDimensionId.id)?data.parameterDimensionId.id: "",
        SubQuestionList:[]

    }
    data.options.forEach(element => {
        //console.log("data.element>>>>>> ",element)

        updateData.options.push({
            id:element.id , 
            weightage:element.weightage , 
            name:element.name,
            heading:element.heading, 
            bot:element.bot,
            isFeedback:element && element.isFeedback?true:false
        })
    })
    ////////console.log("data.updateData>>>>>> ",updateData)

    if(data && data.SubQuestionList && data.SubQuestionList.length>0){
        data.SubQuestionList.forEach((subQues)=>{
            updateData["SubQuestionList"].push({
                "id":subQues.id,
                "name":subQues.name
            });
        });
    }
    //console.log("data.updateQuestion>>>>>> ",updateData)

    return dispatch => {
        dispatch(request());
        questionsService.updateQuestion(updateData)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.updateSurveyParameter(tempdata));
                    dispatch(this.autoChangeDashboardType(tempdata));
                    dispatch(this.getQuestionListBySurveyID(tempdata));
                    if(!call){
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.UPDATE_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.UPDATE_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.UPDATE_QUESTIONS_FAILURE, error } }
}



function updateQuesAllMandatory(data) {
    return dispatch => {
        dispatch(request());
        questionsService.updateQuesAllMandatory(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(data));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.UPDATE_QUES_ALL_MANDATORY_REQUEST } }
    function success(questions) { return { type: questionsConstants.UPDATE_QUES_ALL_MANDATORY_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.UPDATE_QUES_ALL_MANDATORY_FAILURE, error } }
}


function updateQuestionDMG(data, temp) {
    return dispatch => {
        dispatch(request());
        questionsService.updateQuestionDMG(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.UPDATE_QUES_DMG_REQUEST } }
    function success(questions) { return { type: questionsConstants.UPDATE_QUES_DMG_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.UPDATE_QUES_DMG_FAILURE, error } }
}




function getAllQuestions(data) {
    return dispatch => {
        dispatch(request());
        questionsService.getAllQuestions(data)
            .then(
                questions => dispatch(success(questions)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.QUESTIONS_GETALL_REQUEST } }
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.QUESTIONS_GETALL_FAILURE, error } }
}


function copyQuestion(data, surveyId) {
    return dispatch => {
        dispatch(request());
        questionsService.copyQuestion(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID({"surveyId":surveyId}));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.COPY_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.COPY_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.COPY_QUESTIONS_FAILURE, error } }
}


function deleteQuestion(data) {

    let survey = {
        surveyId: data.surveyId
    }
    return dispatch => {
        dispatch(request());
        questionsService.deleteQuestion({ id: data.id })
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.updateSurveyParameter(survey));
                    dispatch(this.getQuestionListBySurveyID(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.DELETE_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.DELETE_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.DELETE_QUESTIONS_FAILURE, error } }
}
function disableQuestions(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        questionsService.disableQuestions({ id: data.id })
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getAllQuestions(tempdata));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.ADD_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.ADD_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.ADD_QUESTIONS_FAILURE, error } }
}
// function getQuestionListBySurveyID(data) {
   
//     return dispatch => {
//         dispatch(request());
//         questionsService.getQuestionListBySurveyID(data)
//             .then(
//                 questions => {
//                     dispatch(success(questions));
//                 },
//                 error => {
                    
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: questionsConstants.QUESTIONS_GETALL_REQUEST } }
//     function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }
//     function failure(error) { return { type: questionsConstants.QUESTIONS_GETALL_FAILURE, error } }
// }




function handleNameQuestion(questionList, questionId, value, isVariable) {
    let currentQuestionIndex = questionList.findIndex(element=>element.id === questionId);


    if(isVariable){
        questionList[currentQuestionIndex]['name'] += value;
    }else{
        questionList[currentQuestionIndex]['name'] = value;
    }

    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}


function handleQuestionTime(questionList, questionId, value, isVariable) {
    let currentQuestionIndex = questionList.findIndex(element=>element.id === questionId);


    if(isVariable){
        questionList[currentQuestionIndex]['seconds'] += value;
    }else{
        questionList[currentQuestionIndex]['seconds'] = value;
    }

    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

// function handleNameQuestion(questionList, quesId, value) {
//     let quesIndex = questionList.findIndex(element=>element.id === quesId);
//     questionList[quesIndex]['name'] = value;
//     let questionObj = {
//         questionsList: questionList
//     }
//     return dispatch => {
//         dispatch(success(questionObj));
//     };
//     function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

// }





function handleSubmitPreChange(currQues, answersList,questionsList, value, weightage ,surveyScreenData, subQues, GroupQuestionOnlyList) {
    let questionObj={};
    if(currQues.templateId===2){
        
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);

        let valueName = value && value.name?value.name:"";
        let valueId = value && value.id?value.id:"";

        if(valueName){
            let getIndex = answersList[currIndex]['multiAnswer'].findIndex(prev=>prev.optionId===valueId);
            if(getIndex===-1){
                answersList[currIndex]['multiAnswer'].push({
                    "optionId":valueId,
                    "optionAns":valueName,
                });
            }
            else{
                answersList[currIndex]['multiAnswer'].splice(getIndex,1);
            }

            // if(answersList && answersList[currIndex] && answersList[currIndex]['multiAnswer'] && answersList[currIndex]['multiAnswer'].length>0){
            //     answersList[currIndex]['answer'] = JSON.stringify(answersList[currIndex]['multiAnswer']);
            // }
            // else{
            //     answersList[currIndex]['answer'] = "";
            // }

            questionObj = {
                questionsList:questionsList,
                answersList:answersList,
                surveyScreenData:surveyScreenData,
                GroupQuestionOnlyList:GroupQuestionOnlyList
            }
        }



    }
    else if(currQues.templateId===3){
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);

        answersList[currIndex]['questionOptionId'] = "";
        answersList[currIndex]['answer'] = value.name;

        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData,
            GroupQuestionOnlyList:GroupQuestionOnlyList
        }
    
    }
    else if(currQues.templateId===4){
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);

        answersList[currIndex]['questionOptionId'] = "";
        if(value.ans===1){
            answersList[currIndex]['answer1'] = value.name;
        }
        else if(value.ans===2){
            answersList[currIndex]['answer2'] = value.name;
        }

        answersList[currIndex]['answer'] = answersList[currIndex]['answer1'];

            if(!answersList[currIndex]['answer1'] && !answersList[currIndex]['answer2']){
                answersList[currIndex]['answer'] = '';
            }

            
        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData,
            GroupQuestionOnlyList:GroupQuestionOnlyList
        }
    }
    else if(currQues.templateId===5){
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);

        answersList[currIndex]['answer'] = subQues.toString();
        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData,
            GroupQuestionOnlyList:GroupQuestionOnlyList
        }
    }
    else if(currQues.templateId===16 || currQues.templateId===6 || currQues.templateId===7 || currQues.templateId===8){
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);

        answersList[currIndex]['answer'] = value.name;
        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData,
            GroupQuestionOnlyList:GroupQuestionOnlyList
        }
    }
    else if(currQues.templateId===13){

            let currIndex4 = answersList.findIndex(element=>element.questionId === subQues.id);

            // let textAnswer = value.heading + " " +value.name;

            // answersList[currIndex4]['answer'] = textAnswer;  
    
            let getIndex1 = answersList[currIndex4]['multiAnswer'].findIndex(prev=>prev.optionHead===value.heading)
            if(getIndex1===-1){
                answersList[currIndex4]['multiAnswer'].push({
                    optionAns:value.name,
                    optionHead:value.heading,
                    optionId:value.id
                });
            }
            else{
                answersList[currIndex4]['multiAnswer'][getIndex1]["optionId"] = value.id;
                answersList[currIndex4]['multiAnswer'][getIndex1]["optionAns"] = value.name;
                answersList[currIndex4]['multiAnswer'][getIndex1]["optionHead"] = value.heading;
            }


            // if(answersList && answersList[currIndex4] && answersList[currIndex4]['multiAnswer'] && answersList[currIndex4]['multiAnswer'].length>0){
            //     answersList[currIndex4]['answer'] = answersList[currIndex4]['multiAnswer'].map((item,index)=>{
            //         return ( "ans"+(index+1).toString()+": " + item.optionHead+" "+item.optionAns)
            //     }).join(" , ")
            // }



        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData,
            GroupQuestionOnlyList:GroupQuestionOnlyList
        }
    }
    else{
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);

        answersList[currIndex]['questionOptionId'] = value.id;
        answersList[currIndex]['answer'] = value.name;

        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData,
            GroupQuestionOnlyList:GroupQuestionOnlyList
        }
    }
   
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}









function handleSubQuestionName(questionList, QuesId, subQuesId, value) {

    let currentQuestionIndex = questionList.findIndex(element=>element.id === QuesId);

    let SubQuestionIndex = questionList[currentQuestionIndex]['SubQuestionList'].findIndex(element=>element.id === subQuesId);
    
    questionList[currentQuestionIndex]['SubQuestionList'][SubQuestionIndex]["name"] = value;

    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}



function handleMandatoryQuestion(questionList, quesId, value) {
    let quesIndex = questionList.findIndex(element=>element.id === quesId);
    questionList[quesIndex]['isMandatory'] = value;
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleParameterQuestion(questionList, quesId, para, paraType) {
    let quesIndex = questionList.findIndex(element=>element.id === quesId);
    
    if(paraType === 1){
 
            questionList[quesIndex]['parameterBucketId']={
                "id":para.id,
                "name":para.name
            }
       
    }
    else if(paraType === 2){

            questionList[quesIndex]['parameterDimensionId']={
                "id":para.id,
                "name":para.name
            }
    
    }
    else{
        questionList[quesIndex]['parameterBucketId'] = "";
        questionList[quesIndex]['parameterDimensionId'] = "";

    }
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleParaInput(questionList, quesId, value) {
    let quesIndex = questionList.findIndex(element=>element.id === quesId);
    questionList[quesIndex]['parameter'] = value.name;
    questionList[quesIndex]['parameterId'] = value.id;
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleUpdateWeightage(questionList, quesId, value) {
    let quesIndex = questionList.findIndex(element=>element.id === quesId);
    questionList[quesIndex]['weightage'] = value;
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleNameOption(questionList, quesId, optionId, value) {
    let quesindex = questionList.findIndex(element=>element.id === quesId);
    let optionIndex = questionList[quesindex]['optionsList'].findIndex(x=>x.id === optionId);
    if(questionList[quesindex]['optionsList'][optionIndex]){
        questionList[quesindex]['optionsList'][optionIndex]['name'] = value;
        questionList[quesindex]['options'] = questionList[quesindex]['optionsList']

    }
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}



function handleBotOption(questionList, quesId, optionId, value) {
    let quesindex = questionList.findIndex(element=>element.id === quesId);
    let optionIndex = questionList[quesindex]['optionsList'].findIndex(x=>x.id === optionId);
    if(questionList[quesindex]['optionsList'][optionIndex]){
        questionList[quesindex]['optionsList'][optionIndex]['bot'] = value;
        questionList[quesindex]['options'] = questionList[quesindex]['optionsList']

    }
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}


function handleFeedbackOption(questionList, quesId, optionId, value) {
    let quesindex = questionList.findIndex(element=>element.id === quesId);
    let optionIndex = questionList[quesindex]['optionsList'].findIndex(x=>x.id === optionId);
    //console.log("value----------------------------->",value)
    if(questionList[quesindex]['optionsList'][optionIndex]){
        questionList[quesindex]['optionsList'][optionIndex]['isFeedback'] = value?true:false;
        //console.log("questionList[quesindex]['optionsList'][optionIndex]",questionList[quesindex]['optionsList'][optionIndex])
        questionList[quesindex]['options'] = questionList[quesindex]['optionsList']
    }
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}




function handlequestionDesign(questionList, quesId, value) {
    let quesindex = questionList.findIndex(element=>element.id === quesId);
    ////////console.log("quesindex--->",quesindex)
    ////////console.log("questionList[quesindex]--->",questionList[quesindex])
    ////////console.log("value--->",value)

    questionList[quesindex]["designId"] = value
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}


function handleNameOptionHeading(questionList, quesId, optionId, value) {
    let quesindex = questionList.findIndex(element=>element.id === quesId);
    let optionIndex = questionList[quesindex]['optionsList'].findIndex(x=>x.id === optionId);
    if(questionList[quesindex]['optionsList'][optionIndex]){
        questionList[quesindex]['optionsList'][optionIndex]['heading'] = value;
        questionList[quesindex]['options'] = questionList[quesindex]['optionsList']

    }
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleNameWeightage(questionList, quesId, optionId, value) {
    let quesindex = questionList.findIndex(element=>element.id === quesId);
    let optionIndex = questionList[quesindex]['optionsList'].findIndex(x=>x.id === optionId);
    if(questionList[quesindex]['optionsList'][optionIndex]){
        questionList[quesindex]['optionsList'][optionIndex]['weightage'] = value;
        questionList[quesindex]['options'] = questionList[quesindex]['optionsList']
    }
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}




function deleteOption(questionList, quesId, optionId) {
    let quesindex = questionList.findIndex(element=>element.id === quesId);
    let optionIndex = questionList[quesindex]['optionsList'].findIndex(x=>x.id === optionId);
    questionList[quesindex]['optionsList'].splice(optionIndex,1);
    let questionObj = {
        questionsList: questionList
    }
    let cur_ques =  questionList[quesindex];
    ////console.log('X.1>>> ',cur_ques);

    return dispatch => {
        dispatch(success(questionObj));
        dispatch(request());
        questionsService.deleteQuestionOption({id: optionId},false)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.DELETE_QUESTIONOPTION_REQUEST } }
    function success(questions) { return { type: questionsConstants.DELETE_QUESTIONOPTION_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.DELETE_QUESTIONOPTION_FAILURE, error } }
    // function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function addOption(questionList, question) {
    let currentQuestionIndex = questionList.findIndex(element=>element.id === question.id);

    // questionList[currentQuestionIndex]['optionsList'].push({weightage:1,name:"New Choice"})

    questionList[currentQuestionIndex]['options'].push({weightage:1,name:"New Choice"})

    let questionObj = {
        questionsList: questionList
    }
    ////////console.log("{{addOption: ",questionObj)
    delete questionList[currentQuestionIndex].createdBy;
    delete questionList[currentQuestionIndex].createdAt;
    // let cur_ques =  questionList[currentQuestionIndex];
    // let tempdata = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(success(questionObj));
        dispatch(request());
        ////////console.log("{{{question: ",question)
        dispatch(this.updateQuestion(question,true, true));
        // questionsService.updateQuestion(cur_ques)
        //     .then(
        //         questions => {
        //             dispatch(success(questions));
        //             dispatch(this.updateQuestion(survey));
        //             dispatch(this.getQuestionListBySurveyID(tempdata));
        //             dispatch(alertActions.success("Update successfully."));
        //         },
        //         error => {
        //             dispatch(alertActions.error(error));
        //             dispatch(failure(error))
        //         }
        //     );
    };
    function request() { return { type: questionsConstants.UPDATE_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.UPDATE_QUESTIONS_SUCCESS, questions } }
    // function failure(error) { return { type: questionsConstants.UPDATE_QUESTIONS_FAILURE, error } }
    // function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}



// function handleSubmitPreChange(currQues, answersList,questionsList, value, weightage ,currOptionId) {
//     let questionObj={};
//     if(currQues.templateId===2){
        
//         let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
//         ////console.log('currIndex: ', currIndex)
//         if(answersList[currIndex]['answer'] === ""){
//             answersList[currIndex]['selected'] = value.id;
//             answersList[currIndex]['answer'] = value.name;
//             answersList[currIndex]['weightage'] = weightage;
//             answersList[currIndex]['id'] = currOptionId;

//         }
//         else{
//             answersList[currIndex]['selected'] = "";
//             answersList[currIndex]['answer'] = "";
//             answersList[currIndex]['weightage'] = 0;
//             answersList[currIndex]['id'] = currOptionId;

//         }
        
//         questionObj = {
//             questionsList:questionsList,
//             answersList:answersList
//         }
//     }
//     else{
//         let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
//         ////console.log('currIndex: ', currIndex)

//         answersList[currIndex]['selected'] = value.id;
//         answersList[currIndex]['answer'] = value.name;
//         answersList[currIndex]['weightage'] = weightage;
//         answersList[currIndex]['id'] = currOptionId;

//         questionObj = {
//             questionsList:questionsList,
//             answersList:answersList
//         }
//     }
   
//     return dispatch => {
//         dispatch(success(questionObj));
//         ////////console.log("- X- X- C AnswerList: ",questionObj)

//     };
//     function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

// }

function surveySubmit(answersList,dataId,props) {
    ////////console.log("surveyData: ", answersList)

    let ansData={
        surveyId: dataId.surveyId,
        employeeId: dataId.employeeId,
        answers: []
    }

    answersList.forEach((surveyData)=>{
        ansData.answers.push({
            questionId:surveyData.questionId,
            answer:surveyData.answer,
            parameterType:surveyData.parameterType,
            weightage:surveyData.weightage,
            parameter:surveyData.parameter
        })
    })


    ////////console.log("finalData: ", ansData)

    

             return dispatch => {
            dispatch(request());
            questionsService.surveySubmit(ansData)
                .then(
                    answers => {
                        ////////console.log("-------------------------------------------answers ==== == = ",answers)
                        dispatch(success(answers));
                        props.history.push(`/app/thankyou/${dataId.surveyId}`)
                    },
                    error => {
                        ////////console.log("--------------------------------------------failser ==== == = ",error)
                        dispatch(failure(error))
                    }
                );
        };
        function request() { return { type: questionsConstants.ANSWERS_SUBMIT_REQUEST } }
        function success(answers) { return { type: questionsConstants.ANSWERS_SUBMIT_SUCCESS, answers } }
        function failure(error) { return { type: questionsConstants.ANSWERS_SUBMIT_FAILURE, error } }
}


function getQuestionListBySurveyID(data,props) {
   
    return dispatch => {
        dispatch(request());
        questionsService.getQuestionListBySurveyID(data)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    // props.history.push(`/done`)
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.QUESTIONS_GETALL_REQUEST } }
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.QUESTIONS_GETALL_FAILURE, error } }
}



function updateQuestionRenk(data,surveyId) {
    let tempdata = {
        surveyId: surveyId
    }
    
    return dispatch => {
        dispatch(request());
        questionsService.updateQuestionRenk(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(tempdata));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.UPDATE_QUESTIONS_RANK_REQUEST } }
    function success(questions) { return { type: questionsConstants.UPDATE_QUESTIONS_RANK_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.UPDATE_QUESTIONS_RANK_FAILURE, error } }
}


function copyFromQuestionLibrary(data) {
    let tempdata = {
        surveyId: data.surveyId
    }
    
    return dispatch => {
        dispatch(request());
        questionsService.copyFromQuestionLibrary(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(tempdata));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.COPY_QUES_LIBRARY_REQUEST } }
    function success(questions) { return { type: questionsConstants.COPY_QUES_LIBRARY_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.COPY_QUES_LIBRARY_FAILURE, error } }
}


function translateQuestion(data, tempdata,TranslationSubList) {
   
    return dispatch => {
        dispatch(request());
        questionsService.translateQuestion(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    if(TranslationSubList && TranslationSubList.length>0){
                        let dataSubQues = {
                            translList:TranslationSubList
                        }
                        dispatch(this.translateManyQuestions(dataSubQues,tempdata));

                    }
                    else{
                        dispatch(this.getQuestionListBySurveyID(tempdata));
                    }

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.TRANSLATE_QUESTION_REQUEST } }
    function success(questions) { return { type: questionsConstants.TRANSLATE_QUESTION_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.TRANSLATE_QUESTION_FAILURE, error } }
}

function translateManyQuestions(data, tempdata) {
   
    return dispatch => {
        dispatch(request());
        questionsService.translateManyQuestions(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(tempdata));

                },
                error => {
                    dispatch(failure(error));
                    dispatch(this.getQuestionListBySurveyID(tempdata));
                }
            );
    };
    function request() { return { type: questionsConstants.TRANSLATE_MANY_QUESTION_REQUEST } }
    function success(questions) { return { type: questionsConstants.TRANSLATE_MANY_QUESTION_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.TRANSLATE_MANY_QUESTION_FAILURE, error } }
}


function translateSurvey(data, tempdata) {
   
    return dispatch => {
        dispatch(request());
        questionsService.translateSurvey(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(surveyActions.getSurveyById(tempdata));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.TRANSLATE_SURVEY_REQUEST } }
    function success(questions) { return { type: questionsConstants.TRANSLATE_SURVEY_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.TRANSLATE_SURVEY_FAILURE, error } }
}


function getType1AllQuestionList (data, tempdata) {
   
    return dispatch => {
        dispatch(request());
        questionsService.getType1AllQuestionList(data)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_ALL_QUESTION_LIST_TYPE1_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_ALL_QUESTION_LIST_TYPE1_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_ALL_QUESTION_LIST_TYPE1_FAILURE, error } }
}




// function getParameterListbyParentId(data) {
//     return dispatch => {
//         dispatch(request());
//         questionsService.getParameterListbyParentId(data)
//             .then(
//                 questions => {
//                     dispatch(success(questions));
//                 },
//                 error => {
//                     dispatch(failure(error));
//                 }
//             );
//     };
//     function request() { return { type: questionsConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST } }
//     function success(questions) { return { type: questionsConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS, questions } }
//     function failure(error) { return { type: questionsConstants.PARAMETERLIST_BYTYPE_GETALL_PARENT_FAILURE, error } }
// }



function updateSurveyParameter(data) {
   
    return dispatch => {
        dispatch(request());
        questionsService.updateSurveyParameter(data)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.UPDATE_SURVEY_PARAMETER_REQUEST } }
    function success(questions) { return { type: questionsConstants.UPDATE_SURVEY_PARAMETER_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.UPDATE_SURVEY_PARAMETER_FAILURE, error } }
}




function getQuestionList(data,Template) {
   
    return dispatch => {
        dispatch(request());
        questionsService.getQuestionList(data, Template)
            .then(
                questions => {
                    // ////////console.log("answers_submission list 2", QuesSub)
                    dispatch(success(questions));
                },
                error => {
                    // props.history.push(`/done/${data.code}`)
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.SURVEY_QUESTIONS_GETALL_REQUEST } }
    function success(questions) { return { type: questionsConstants.SURVEY_QUESTIONS_GETALL_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.SURVEY_QUESTIONS_GETALL_FAILURE, error } }
}


function getSurveyTemplateByCode(data) {
   
    return dispatch => {
        dispatch(request());
        questionsService.getSurveyTemplateByCode(data)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_SURVEY_TEMPLATE_CODE_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_SURVEY_TEMPLATE_CODE_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_SURVEY_TEMPLATE_CODE_FAILURE, error } }
}



function getLanguageLabel(data) {
   
    return dispatch => {
        dispatch(request());
        questionsService.getLanguageLabel(data)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_LANGUAGE_LABEL_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_LANGUAGE_LABEL_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_LANGUAGE_LABEL_FAILURE, error } }
}




function saveAnswers() {
   
    return dispatch => {
        dispatch(success({}));
    };
    function success(questions) { return { type: questionsConstants.SAVE_PREVIEW_SUCCESS, questions } }

}



function emptyReducer() {
   
    return dispatch => {
        dispatch(success({}));
    };
    function success(questions) { return { type: questionsConstants.SAVE_PREVIEW_CLEAR_SUCCESS, questions } }

}





function autoChangeDashboardType(data) {
   
    return dispatch => {
        dispatch(request());
        questionsService.autoChangeDashboardType(data)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.GET_AUTO_CHANGE_DASH_TYPE_REQUEST } }
    function success(questions) { return { type: questionsConstants.GET_AUTO_CHANGE_DASH_TYPE_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.GET_AUTO_CHANGE_DASH_TYPE_FAILURE, error } }
}






function onSurveyAllQueSeconds(data) {
   
    return dispatch => {
        dispatch(request());
        questionsService.onSurveyAllQueSeconds(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID({surveyId:data.surveyId}));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.ON_SURVEY_ALL_QUE_SECONDS_REQUEST } }
    function success(questions) { return { type: questionsConstants.ON_SURVEY_ALL_QUE_SECONDS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.ON_SURVEY_ALL_QUE_SECONDS_FAILURE, error } }
}

