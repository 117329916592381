import React from "react";
import GaugeScore from '../../RiskProfile/Components/Charts/GaugeScore';

export default function EmployeeProfileModal(props) {
  let {  closeModal, showModal, RiskgetEmpQuestionResponsesData, RiskgetRiskCategoryData } = props;

    // let CategoryList = []

    // if(qList && qList.length>0){
    //   CategoryList = qList.filter(prev=>prev.riskcategoryBucket)

    // }

    function calcScore(questionDetail){
      let sum = 0;
      // let total = 0;
      let noResponse = false
      if(questionDetail && questionDetail.length>0){
        questionDetail.forEach((ques)=>{
          if(ques.qtemplate===1){
            // total += 6
            sum += Number(ques.answer)
          }
          else if(ques.qtemplate===6 || ques.qtemplate===16){
            // total += 6

            if(ques && ques.answerText){
              let temp = Number(ques.answerText)
              sum += Number(temp)
            }
            else{
              noResponse = true
            }
            // if(temp >=0 && temp <=5){
            //   npsNewScore = 1;
            // }
            // else if(temp >=6 && temp <=7){
            //   npsNewScore = 4;
            // }
            // else{
            //   npsNewScore = 6;
            // }

          }
        })
      }
      // ////console.log("start-------")
      // ////console.log("sum",sum)
      // ////console.log("total",total)

      return (noResponse?"-":sum)
    }


    let qList = RiskgetEmpQuestionResponsesData;

    ////console.log("qList-------->>",qList)

    let RiskCategory = []
    let scoreSum = 0;
    let scoreCount = 0;

    ////console.log("RiskgetRiskCategoryData-------->>",RiskgetRiskCategoryData)

    function ToString(value){
      return value?(value.toString()).trim():""
    }
    // let AlreadyList = []

    if(RiskgetRiskCategoryData && RiskgetRiskCategoryData.length>0 && qList && qList.length>0){
      RiskgetRiskCategoryData.forEach((category)=>{
        ////console.log("category-------->>",category);

        let questionDetailFinal = qList.filter(prev => ToString(prev.riskCategoryId) === ToString(category.id));

        RiskCategory.push({
          "name":category.name,
          "id":category.id,
          "questionList":questionDetailFinal,
          "categoryCount":questionDetailFinal && questionDetailFinal.length>0?questionDetailFinal.length:0,
          "score":calcScore(questionDetailFinal)
        });


      })
    }

    ////console.log("RiskCategory-------->>",RiskCategory)

    // ////console.log("scoreSum-------->>",scoreSum)
    // ////console.log("scoreCount-------->>",scoreCount)

    let totalScore = (scoreSum)/scoreCount;

    let categoryShow = []
    let newQuestionList = []
    if(RiskCategory && RiskCategory.length>0){
      RiskCategory.forEach((item,index1)=>{
        if(item &&item.questionList && item.questionList.length>0){
          item.questionList.forEach((item3,index3)=>{
            let temp = {
              ...item3,
              "questionScore":item.score
            }
            temp["riskcategoryValue"] = item.name;

            
            let getIndex = categoryShow.findIndex(prev=>prev === item.name)
            if(getIndex===-1){
              categoryShow.push(item.name)
              temp["categoryShow"]=true;
              temp["categoryCount"]=item.categoryCount;
            }
            else{
              temp["categoryShow"]=false
            }


            
            newQuestionList.push(temp)
          })
        }
        
      })
    }

    let Qcount = 0
    let grandTotal = 0;
    let grandScore = 0;
    if(newQuestionList && newQuestionList.length>0){
      newQuestionList.forEach((ques)=>{
        ////console.log("npsNewScore-------->>",ques.qtemplate);
        if(Number(ques.qtemplate) === 1){
          Qcount++
          grandTotal += 6;
          grandScore += Number(ques.answer)
        }
        // else if(Number(ques.qtemplate) === 6 || Number(ques.qtemplate) === 16){

        //   grandTotal += 6 
        //   let npsNewScore = 0;
        //   let temp = Number(ques.answerText)
        //   if(temp >=0 && temp <=5){
        //     npsNewScore = 1;
        //   }
        //   else if(temp >=6 && temp <=7){
        //     npsNewScore = 4;
        //   }
        //   else{
        //     npsNewScore = 6;
        //   }
        //   ////console.log("temp-------->>",temp);
        //   ////console.log("npsNewScore-------->>",npsNewScore);

        //   grandScore += Number(npsNewScore)
        // }
      })
    }
  

    function getColor(item){
      let color = "#858784";

      if(item && item.questionScore && item.questionScore!=="-"){
        if((item.qtemplate).toString() === "1"){
          let score = Number(item.questionScore);
          if(score >=25 && score<=30){
            color = "#1bbf36";
          }
          else if(score >=18 && score<=24){
            color = "#edb409";
          }
          else if(score >=13 && score<=17){
            color = "#e65757";
          }
          else if(score >=6 && score<=12){
            color = "#b31717";
          }
        }
        else if((item.qtemplate).toString() === "6" || (item.qtemplate).toString() === "16"){
          let score = Number(item.questionScore);
          if(score >=9 && score<=10){
            color = "#1bbf36";
          }
          else if(score >=7 && score<=8){
            color = "#edb409";
          }
          else if(score >=0 && score<=6){
            color = "#e65757";
          }
        }


      }
      else if((item.qtemplate).toString() === "16" && item.questionScore === 0){
        color = "#e65757";
      }
      
      return color
    }




    let empResponseList = RiskgetEmpQuestionResponsesData;


  return (
   <>
   {showModal?
  <>
      <div  style={{zIndex:'100',backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-6xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-12">
          
          
          <div onClick={()=>closeModal()}  className="shadow absolute right-60 top-20 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>


          
          
          <div  className="shadow mt-20 md:mx-60 lg:mx-60 xl:mx-60 rounded-lg bg-white overflow-hidden block">
            
          <div className="xl:flex-col w-full space-y-4" style={{height:'400px', overflowY:'auto'}}>

              <div className="bg-white rounded w-full border   p-4">
                <div className="text-lg font-semibold ">Risk Meter</div>

                {empResponseList && empResponseList.length>0?
                  <GaugeScore 
                  totalScore={totalScore}
                  grandTotal={grandTotal}
                  grandScore={grandScore}

                />
                :<div className="text-gray-500 text-xl text-center">No Data Found</div>}


              </div>

              <div className="bg-white rounded w-full border p-4 ">
                <div className="text-lg font-semibold ">Employee Response</div>

                <div className=" py-4 lg:whitespace-normal whitespace-nowrap overflow-x-auto">
                  
                {empResponseList && empResponseList.length>0?
                  <>
                  {newQuestionList && newQuestionList.length>0?
                  <div className="table  w-full   ">
                    <table className="w-full border border-collapse">
                      <thead className>

                        <tr className="bg-gray-50 border-b">
                        
                          <th  className="p-2 border border-r cursor-pointer text-sm font-bold text-gray-800 xl:w-24">
                            <div className="flex items-center justify-center">
                              Risk Category
                            </div>
                          </th>

                          <th  className="p-2 border border-r cursor-pointer text-sm font-bold text-gray-800">
                            <div className="flex items-center justify-center">
                              Questions
                            </div>
                          </th>


                          <th  className="p-2 border border-r cursor-pointer text-sm font-bold text-gray-800 xl:w-24 w-1/6">
                            <div className="flex items-center justify-center">
                              Score 
                            </div>
                          </th>

                        </tr>


                      </thead>
                      <tbody>

                        {newQuestionList && newQuestionList.length>0?
                          newQuestionList.map((item,index)=>
                          <tr className={"text-center text-sm text-gray-600 border "+(item.categoryShow?"border-t-4 border-b":"border-b")}>

                            {item.categoryShow?
                              <td className="border border-b" rowSpan={item.categoryCount}> <p className=" p-2 font-bold">{item && item.riskcategoryValue?item.riskcategoryValue:"-"}</p></td>
                            :null}

                            <td className={(item.categoryShow?"border border-b":"")+" border-r text-left"}> <p className=" p-2 font-normal whitespace-normal">{item && item.question?item.question:"-"}</p></td>



                            {item.categoryShow?
                              <td className="border border-b text-white font-semibold text-xl " style={{background:getColor(item)}} rowSpan={item.categoryCount}> <p className=" p-2 ">{item.questionScore}</p></td>
                            :null}


                          </tr>
                          )
                          :null}

                      </tbody>
                    </table>
                  </div>
                  :
                  <>
                  <div className="text-gray-500 text-xl text-center">Please select employee</div>
                  </>
                  }
                  </>
                  :
                  <>
                  <div className="text-gray-500 text-xl text-center">No Data Found</div>
                  </>
                }



                </div>
                    
              </div>




              </div>

          
          
          </div>



        </div>
        </div>
  </> 
  :null}

   </>
  );
}
