import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../_actions";
import ReactTooltip from "react-tooltip";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurveyName: "",
      currentSurveyId: "",
      showDropdown: false,
      gameType: "",

      // showSidebar:false
    };
  }
  componentDidMount() {}

  onDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false });
    } else {
      this.setState({ showDropdown: true });
    }
  };

  logout = () => {
    this.props.dispatch(userActions.logout());
  };

  IndexHoverOptions = (surveyId, indexType) => {
    let { onOptionType } = this.props;

    return (
      <span>
        <i
          className="fa fa-scribd"
          onClick={() => onOptionType(surveyId, indexType, 1)}
        />
        <i
          className="fa fa-quora"
          onClick={() => onOptionType(surveyId, indexType, 2)}
        />
        <i
          className="fa fa-align-left"
          onClick={() => onOptionType(surveyId, indexType, 3)}
        />
        {/* <i className="fa fa-facebook" onClick={()=>onOptionType(surveyId,indexType,4)}/>  */}
      </span>
    );
  };

  handleIndexType = (surveyId, indexType, isDisable, event) => {
    this.setState({ gameType: event });
    let { onOptionType } = this.props;
    if (!isDisable) {
      onOptionType(surveyId, indexType, 1);
    }
    // this.setState({showSidebar:false});

    let { handleSidebar } = this.props;
    handleSidebar(false);

    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },5000)
  };

  sidebarToggle = () => {
    // this.setState({showSidebar:!this.state.showSidebar});

    let { handleSidebar, showSidebar } = this.props;
    handleSidebar(!showSidebar);

    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },6000)

    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },7000)
  };

  render() {
    let {
      SurveyList,
      getIndexData,
      // onHappinessIndex, onEngagementIndex, onHopeIndex, onStressIndex, onOptionType,
      indexType,
      CurrentSurveyId,
      showMobileSidebar,
      isLifecycle,
    } = this.props;
    let {
      // currentSurveyName, showDropdown,
      showSidebar,
    } = this.props;

    let surveyId = "";
    if (SurveyList && SurveyList.length > 0) {
      if (CurrentSurveyId) {
        surveyId = CurrentSurveyId;
      } else {
        surveyId = SurveyList[0].id;
      }
    }

    //console.log('=============================================?=================CurrentSurveyId',CurrentSurveyId)
    //console.log('=============================================?=================surveyId',surveyId)

    let indexLabelsList = [];
    let IndexList = [];

    function getIndexName(key) {
      let name = "";
      if (key === 1) {
        name = "Happiness";
      } else if (key === 2) {
        name = "Engagement";
      } else if (key === 3) {
        name = "Hope";
      } else if (key === 4) {
        name = "Stress";
      } else if (key === 5) {
        name = "Manager";
      } else if (key === 6) {
        name = "Leadership";
      } else if (key === 7) {
        name = "Safety";
      } else if (key === 9) {
        name = "Diversity";
      } else {
        name = "NaN";
      }
      return name;
    }

    if (getIndexData) {
      IndexList = Object.keys(getIndexData).map((key) => [
        Number(key),
        getIndexData[key],
      ]);

      IndexList.forEach((data) => {
        if (getIndexName(data[0]) !== "NaN" && data && data[0] && data[1]) {
          indexLabelsList.push({
            template: data[0],
            score: data[1].toFixed(2),
            name: getIndexName(data[0]),
          });
        }
      });
    }

    // //console.log('indexType:-------------------> ',indexType)

    function getIconsByTemplateNo(template) {
      return template === 1
        ? "emoji_emotions"
        : template === 2
        ? "group"
        : // (template===3)?"sentiment_dissatisfied":

        template === 4
        ? "sentiment_dissatisfied"
        : template === 5
        ? "manage_accounts"
        : template === 6
        ? "leaderboard"
        : template === 8
        ? "business"
        : // (template===7)?"business":
        // (template===9)?"business":

        template === 102
        ? "account_box"
        : template === 103
        ? "insert_chart"
        : template === 104
        ? "query_stats"
        : template === 105
        ? "fitness_center"
        : template === 106
        ? "event_available"
        : template === 107
        ? "description"
        : template === 108
        ? "flag_circle"
        : template === 121
        ? "emoji_people"
        : "dashboard";
    }

    return (
      <>
        <div
          className={
            showSidebar
              ? "cursor-pointer transition-width w-64 lg:block scollar-xs  lg:relative fixed top-0 left-0  h-screen lg:z-20 z-20 overflow-y-auto overflow-hidden  duration-200 transform bg-white border-r customscroll4 " +
                (showMobileSidebar ? "" : "hidden")
              : "cursor-pointer transition-width  w-16 lg:block scollar-xs lg:relative fixed top-0 left-0  h-screen lg:z-20 z-20 overflow-y-auto overflow-hidden  duration-200 transform bg-white border-r customscroll4 " +
                (showMobileSidebar ? "" : "hidden")
          }
          style={{ height: "calc(100% - 0rem)" }}
        >
          {!showMobileSidebar ? (
            <div className="sticky top-0 flex items-center bg-white ">
              <div className="flex items-center justify-center w-16 px-4 py-3 text-gray-500">
                <span
                  onClick={() => this.sidebarToggle()}
                  className="p-1 bg-gray-100 rounded material-symbols-outlined"
                >
                  {showSidebar ? "chevron_left" : "chevron_right"}
                </span>
              </div>
              <span className="text-sm text-gray-500 ">Insight</span>
            </div>
          ) : (
            <div className="py-1" />
          )}

          <div className="mb-4 whitespace-nowrap ">
            {/* 1 */}
            <div
              data-tip="Overview"
              onClick={() => this.handleIndexType(surveyId, 0, indexType === 0)}
              className={
                indexType === 0
                  ? "flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer border-l-4 border-[#2196F3] bg-[#2196F3]/10"
                  : "flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer"
              }
            >
              <div
                className={
                  indexType === 0
                    ? "flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center"
                    : "flex items-center px-4 py-4 w-16   h-12 justify-center"
                }
              >
               <span className="p-2 material-symbols-outlined">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-layout-dashboard"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.8"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 4h6v8h-6z"></path>
                    <path d="M4 16h6v4h-6z"></path>
                    <path d="M14 12h6v8h-6z"></path>
                    <path d="M14 4h6v4h-6z"></path>
                  </svg>
                </span>
              </div>
              <span
                className={
                  indexType === 0 ? " text-sm text-[#2196F3]" : " text-sm  "
                }
              >
                Overview
              </span>
            </div>
            <ReactTooltip
              place="right"
              className="extraClass1"
              type="dark"
              effect="solid"
            />
            {/* 2 */}
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(Sidebar);
