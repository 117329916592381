import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TbMailCheck } from "react-icons/tb";
class IndexKPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { getRespondantDetailsData } = this.props;


    let ParticipationObj = {
      total: 0,
      completed: 0,
      mailSent: 0,
      clicked: 0,
      participated: 0,
      performance: 0,
      perfPlusSign: true
    }

    if (getRespondantDetailsData && getRespondantDetailsData[0]) {
      let apiData = getRespondantDetailsData[0]
      ParticipationObj['total'] = apiData['total'] ? apiData['total'] : 0;
      ParticipationObj['completed'] = apiData['completed'] ? apiData['completed'] : 0;
      ParticipationObj['mailSent'] = apiData['mailSent'] ? apiData['mailSent'] : 0;
      ParticipationObj['clicked'] = apiData['clicked'] ? apiData['clicked'] : 0;
      ParticipationObj['participated'] = apiData['completed'] && apiData['total'] ? (apiData['completed'] * 100 / apiData['total']).toFixed(2) : 0;



    }



    // indexType={indexType}
    // getIndexData={getIndexData}
    // getIndexScore={getIndexScore}
    // getAllBenchMarkData={getAllBenchMarkData}
    // getRespondantDetailsData={getRespondantDetailsData}

    return (
      <>
        <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] ">
          <div className="flex items-center w-full ">
            <div className="space-y-2">
              <p className="block text-base text-[#fff] w-full uppercase">Sent</p>
              <h1 className="text-3xl font-medium w-full text-[#fff]">
                {ParticipationObj['total']}
              </h1>
            </div>
          </div>
            <TbMailCheck  className='text-[4rem] text-white font-light' />

        </div>

        <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] ">
          <div className="flex items-center w-full ">
            <div className="space-y-2">
              <p className="block text-base text-[#fff] w-full uppercase">Responded</p>
              <h1 className="text-3xl font-medium w-full text-[#fff]">
                {ParticipationObj['completed']}
              </h1>

            </div>
          </div>

            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-click text-white" width="60" height="60" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12l3 0" /><path d="M12 3l0 3" /><path d="M7.8 7.8l-2.2 -2.2" /><path d="M16.2 7.8l2.2 -2.2" /><path d="M7.8 16.2l-2.2 2.2" /><path d="M12 12l9 3l-4 2l-2 4l-3 -9" /></svg>
        </div>

        <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] ">
          <div className="flex items-center w-full ">
            <div className="space-y-2">
              <p className="block text-base text-[#fff] w-full uppercase">Response Rate</p>
              <h1 className="text-3xl font-medium w-full text-[#fff]">
                {ParticipationObj['participated'] + '%'}
              </h1>

            </div>
          </div>
             <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-percentage text-white" width="60" height="60" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 17m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M7 7m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M6 18l12 -12" /></svg>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexKPI);
