import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList:[],

    }
  }
  componentDidMount() {

  }


  render() {

    let { handleSelectEDA, DimenScoreList, GetParameterLabel,  }= this.props;

    let list = DimenScoreList;

    let ParaNameList = []
    let RadarValueList = []

    let insertData = []





    if(list && list.length>0){
      list.forEach((axisData)=>{

        ParaNameList.push((axisData["dimension"]?axisData["dimension"]:"Others"));

        if(axisData[1]){
          insertData.push(axisData["score"].toFixed(2))
        }
        else{
          insertData.push(axisData["score"])
        }


      })

    }

    //console.log('list--->>>:',list)

    return (
      <>


      <div className="py-10 px-6">
                                    
      {list && list.length>0?
      <ReactEcharts
      onEvents= {{
        'click': (params)=>handleSelectEDA(params)
      }}
      style={{
      marginTop:'-50px',
      height:list && list.length>12?'1500px':'500px'
    }}
      option={{
        tooltip: {
            trigger: 'axis',
            axisPointer: {     
                type: 'shadow'        
            },
            formatter: function(a,b,c,d){
              // //console.log(a)
              return (a && a[0] && a[0].name)?a[0].name+' = '+a[0].value+'':''
            } 


        },
        legend: {
          data:[]
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            splitLine: {
              lineStyle: {
                  color: '#f2f2f2'
              }
          },
          axisLine: {
            lineStyle: {
              color:'#e6e6e6'
            }
          }                              
        },
        yAxis: {
            type: 'category',
            data: ParaNameList,
            axisLine: {
              lineStyle: {
                color:'#e6e6e6'
              }
            },
            axisLabel: {
              color: "#000",
              formatter: function(value) {
                let wordNEW = (value)
                let word = wordNEW.replace('_','')
                // //console.log('axisLabel',word)
                if (word.length > 40) {
                  return word.substring(0, 40) + "..";
                } else {
                  return word;
                }
              }
            },
        },
        axisLabel: {
          color: "#000",
          interval: 0,
          formatter: function(value) {
            if (value.length > 40) {
              return value.substring(0, 40) + "..";
            } else {
              return value;
            }
          }
        },
        series: [
            {
                name: 'Score',
                type: 'bar',
                barWidth: '30px',
                barCategoryGap: '10px',
                stack: '总量',
                label: {
                    show: true,
                    position: 'insideRight',
                    formatter: function(value) {
                        return value.data+'';
                    }
                },
                color:'#6536d1',
                data: insertData
                
            },
           
        ]
    }}
    />
      :null}
                           



    </div>    

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(BarChart);
