import React, { useState } from "react";
import Header from './Header/Header';
export default function SalaryHikeAppraisal() {

  const [open, close] = useState(0);
  return (
    <div className="h-screen overflow-hidden bg-gray-50">
      <Header />

      <div
        className="flex flex-row w-full"
        style={{ height: "calc(100% - 2rem)" }}
      >
        <div
          className="cursor-pointer transition-width w-64  lg:block lang lg:relative fixed top-0 left-0 z-20  h-screen     duration-200 transform bg-white border-r hidden"
          style={{ height: "calc(100% - 0rem)" }}
        >
          <div className="my-4 whitespace-nowrap ">
            <div
              title="Users"
              className="flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer   border-l-4 border-[#2196F3] bg-[#2196F3]/10"
            >
              <div className="flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center">
                <span className=" material-symbols-outlined">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-users"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth="1.8"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx={9} cy={7} r={4} />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                </span>
              </div>
              <span className=" text-sm text-[#2196F3]">Appraisal Cycle</span>
            </div>
          </div>
        </div>

        <main
          className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto  p-6 space-y-6  "
          style={{ height: "calc(100% - 4rem)" }}
        >
          <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
            <div className="flex items-center space-x-4 ">
              <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">
                Add New Appraisal Cycle
              </h1>
            </div>
            <div className="md:flex hidden items-center text-sm">
              <span className="mx-2 material-symbols-rounded">home</span>
              <span>/</span>
              <span className="px-2">PMS</span>
              <span>/</span>
              <span className="px-2 ">Appraisal Cycle</span>
              <span>/</span>
              <span className="px-2 font-semibold">
                Add New Appraisal Cycle
              </span>
            </div>
          </div>

          <div className="flex flex-row w-full gap-10">
            <div className="w-80">
              <div className=" mx-auto max-w-7xl    ">
                <div className="flex bg-white p-4  my-2  rounded-md border-2 border-[#2196F3] cursor-pointer space-x-4">
                  <span className="bg-[#2196F3] text-white p-1 rounded-full w-8 h-8 text-center flex items-center justify-center ">
                    1
                  </span>
                  <div className="text-[#3D405B] font-semibold text-base p-1  ">
                    Appraisal Cycle Details
                  </div>
                </div>
                <div className="flex bg-white p-4  my-2 border rounded-md cursor-pointer space-x-4">
                  <span className="bg-gray-400 text-white p-1 rounded-full w-8 h-8 text-center flex items-center justify-center ">
                    2
                  </span>
                  <div className="text-[#3D405B]/70 text-base p-1 font-medium ">
                    Module Settings
                  </div>
                </div>
                <div className="flex bg-white p-4  my-2 border rounded-md cursor-pointer space-x-4">
                  <span className="bg-gray-400 text-white p-1 rounded-full w-8 h-8 text-center flex items-center justify-center ">
                    3
                  </span>
                  <div className="text-[#3D405B]/70 text-base p-1 font-medium ">
                    Normalization
                  </div>
                </div>
                <div className="flex bg-white p-4  my-2 border rounded-md cursor-pointer space-x-4">
                  <span className="bg-gray-400 text-white p-1 rounded-full w-8 h-8 text-center flex items-center justify-center ">
                    4
                  </span>
                  <div className="text-[#3D405B]/70 text-base p-1 font-medium ">
                    Raters Setting
                  </div>
                </div>
                <div className="flex bg-white p-4  my-2 border rounded-md cursor-pointer space-x-4">
                  <span className="bg-gray-400 text-white p-1 rounded-full w-8 h-8 text-center flex items-center justify-center ">
                    5
                  </span>
                  <div className="text-[#3D405B]/70 text-base p-1 font-medium ">
                    Applicable For
                  </div>
                </div>
                <div className="flex bg-white p-4  my-2  rounded-md border-2 border-[#2196F3] cursor-pointer space-x-4">
                  <span className="bg-[#2196F3] text-white p-1 rounded-full w-8 h-8 text-center flex items-center justify-center ">
                    6
                  </span>
                  <div className="text-[#3D405B] font-semibold text-base p-1  ">
                    Salary Hike
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white border rounded-lg w-full flex-1 p-6">
              <div className="space-y-12">
                <h3 className="text-xl font-semibold  text-[#3D405B]">
                  Salary Hike
                </h3>
                <div className=" border-gray-100 px-6 mt-3 gap-4 flex-col flex 2xl:w-9/12 xl:w-12/12 mx-auto">
                  <div className="flex flex-row gap-6 items-center">
                    <div className="text-sm  text-[#3D405B] before:content-['*']  before:text-red-500 w-[40%] text-right">  Salary Hike</div>
                    <div className="w-full flex items-center space-x-6">
                      <label className="items-center flex space-x-2"><input type="checkbox" className=" " name="Rating" defaultChecked style={{ zoom: '1.5' }} /><span>Enable</span></label>
                    </div>
                  </div>

                  <div className="flex flex-row gap-6 items-center">
                    <div className="text-sm  text-[#3D405B] before:content-['*']  before:text-red-500 w-[40%] text-right">
                      Hike Appraiser
                    </div>
                    <div className=" w-full">
                      <div className="text-gray-700  border rounded-md w-full p-2 px-4 relative flex justify-between items-center">
                        <p>  Appraisal Manager
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer icon icon-tabler icon-tabler-caret-down-filled text-[#3D405B]/70" width={20} height={20} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" strokeWidth={0} fill="currentColor" /></svg>

                        <div className="absolute top-10 right-0 bg-white shadow-lg rounded-md w-full py-4  border h-60 overflow-y-auto z-10 text-base font-medium hidden">
                          <label className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">

                            <span>1</span>
                          </label>
                          <label className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">

                            <span>2</span>
                          </label>
                          <label className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">

                            <span>3</span>
                          </label>
                          <label className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">

                            <span>4</span>
                          </label>
                          <label className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">

                            <span>5</span>
                          </label>

                        </div>

                      </div>

                    </div>
                  </div>

                  <div className="flex flex-row gap-6 items-center">
                    <div className="text-sm  text-[#3D405B] before:content-['*']  before:text-red-500 w-[40%] text-right">
                      Hike Period
                    </div>
                    <div className="flex flex-row gap-6 items-center w-full">
                      <div className="text-gray-700    rounded-md w-full  relative flex justify-between items-center border">
                        <div className="react-datepicker-wrapper">
                          <div className="react-datepicker__input-container">
                            <input
                              type="text"
                              name="startDate"
                              placeholder="Start Date"
                              autoComplete="off"
                              className=" bg-white   py-3 px-4 text-sm rounded-md focus:outline-none focus:ring w-full"
                              defaultValue
                            />
                          </div>
                        </div>
                        <div className="border-l px-4 py-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-calendar-due text-[#212121]/70 cursor-pointer"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                            <path d="M16 3v4" />
                            <path d="M8 3v4" />
                            <path d="M4 11h16" />
                            <path d="M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                          </svg>
                        </div>
                      </div>
                      <p> To</p>
                      <div className="text-gray-700  border rounded-md w-full   relative flex justify-between items-center">
                        <div className="react-datepicker-wrapper">
                          <div className="react-datepicker__input-container">
                            <input
                              type="text"
                              name="endDate"
                              placeholder="End Date"
                              autoComplete="off"
                              className=" bg-white  py-3 px-4 text-sm rounded-md focus:outline-none focus:ring w-full"
                              defaultValue
                            />
                          </div>
                        </div>
                        <div className="border-l px-4 py-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-calendar-due text-[#212121]/70 cursor-pointer"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                            <path d="M16 3v4" />
                            <path d="M8 3v4" />
                            <path d="M4 11h16" />
                            <path d="M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-6 items-center">
                    <div className="text-sm  text-[#3D405B] before:content-['*']  before:text-red-500 w-[40%] text-right">
                      Hike Push Date
                    </div>
                    <div className="flex flex-row gap-6 items-center w-full">
                      <div className="text-gray-700    rounded-md w-full  relative flex justify-between items-center border">
                        <div className="react-datepicker-wrapper">
                          <div className="react-datepicker__input-container">
                            <input
                              type="text"
                              name="startDate"
                              placeholder="Start Date"
                              autoComplete="off"
                              className=" bg-white   py-3 px-4 text-sm rounded-md focus:outline-none focus:ring w-full"
                              defaultValue
                            />
                          </div>
                        </div>
                        <div className="border-l px-4 py-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-calendar-due text-[#212121]/70 cursor-pointer"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                            <path d="M16 3v4" />
                            <path d="M8 3v4" />
                            <path d="M4 11h16" />
                            <path d="M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                          </svg>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="flex flex-row gap-6 items-start">
                    <div className="text-sm  text-[#3D405B] before:content-['*']  before:text-red-500 w-[40%] text-right">  Hike Approval</div>
                    <div className="w-full   items-center space-y-4">
                      <label className="items-center flex space-x-2"><input type="checkbox" className=" " name="Rating" defaultChecked style={{ zoom: '1.5' }} /><span>Enable</span></label>
                      <label className="items-center flex space-x-2"><input type="checkbox" className=" " name="Rating" defaultChecked style={{ zoom: '1.5' }} /><span>Include Proposed Promotion</span></label>
                    </div>
                  </div>

               
                </div>
              </div>
              <div className="flex justify-center items-center pt-12">
                <button className="bg-[#2196F3] text-white py-2.5 px-12 rounded-md uppercase">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}