import React, { Component } from 'react';
import { connect } from 'react-redux';
import StackChart from './Charts/StackChart';

import Commentary from '../../../Components/Commentary/Commentary';

import SummaryScore from './Cards/SummaryScore/SummaryScore';

import IndexKPI from '../../../../../CommonComponents/IndexKPI';
import Filter from '../../../../../CommonComponents/Filters/IndexFilter/Filter';
import { CSVLink } from "react-csv";
import EDAModal from '../../../Components/EDA/EDAModal';
import MultiTrendChart from './Charts/MultiTrendChart';
import YearFilter from './Components/YearFilter';
import moment from 'moment';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      showLeaderBoard: false,
      isMultifilter: false,
      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      ListName: 'Demographic',
      showEDAModal: false,
      crrDMGValue: "",

      showTrendDetails: false,

      crrTrendYear: Number(moment(Date.now()).subtract(1, 'years').format("YYYY"))

    }
  }
  componentDidMount() {

  }

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultifilterIndexScore, getIndexTrendChartYearByMonth, getQuesTrendChartByMonth } = this.props;
    this.setState({ FilterValues: finalFilter, ListName: name });
    let { crrTrendYear, showTrendDetails } = this.state;

    if (showTrendDetails) {
      getQuesTrendChartByMonth(crrTrendYear, finalFilter);
    } else {
      getIndexTrendChartYearByMonth(crrTrendYear, finalFilter);
      handleMultifilterIndexScore(finalFilter, true, name);

    }

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }


  handleSelectEDA = (data) => {
    ////console.log("data--------->",data);
    let { finalFilter, ListName } = this.state;
    let dmgValue = data && data.name ? data.name : "";
    let { getEDAdata } = this.props;
    if (dmgValue) {
      getEDAdata(dmgValue, 2, finalFilter, ListName);
      setTimeout(() => {
        this.setState({ showEDAModal: true, crrDMGValue: dmgValue });
      }, 500)
    }
  }

  closeEDAModal = () => {
    this.setState({ showEDAModal: false, crrDMGValue: "" });
  }

  handleYearValueYear = (value) => {
    let { finalFilter, showTrendDetails } = this.state;
    let { getIndexTrendChartYearByMonth } = this.props;
    let { getQuesTrendChartByMonth } = this.props;
    this.setState({ crrTrendYear: value }, () => {

      if (showTrendDetails) {
        getQuesTrendChartByMonth(value, finalFilter);
      } else {
        getIndexTrendChartYearByMonth(value, finalFilter);
      }
    })


  }

  handleShowTrendDetails = () => {
    let { finalFilter, crrTrendYear } = this.state;
    this.setState({ showTrendDetails: true }, () => {
      let { getQuesTrendChartByMonth } = this.props;
      getQuesTrendChartByMonth(crrTrendYear, finalFilter)
    })
  }

  handleDetailsBack = () => {
    this.setState({ showTrendDetails: false })
  }

  render() {
    let {
      ErrorIndexObj, getIndexTrendChartByMonthData, getQuestionTrendChartByMonthData, GetParameterLabel, getQuestionData,
      indexType, optionType, getCommentData, loading, name, CountRestrict, getEMPFilterLabel, getDMGIndexManyFilterData,

      EmpFilterData, getIndexFilterData, getOverallIndexData, isCrole,
      getAllBenchMarkData, getRespondantDetailsData,
      getIndexData, getIndexScore, getIndexDistributionData, cronbatchAlphaVarianceData, getDemographicIndexMultiFilterData, getIndexByFilterDMGData
    } = this.props;
    let { currFilterName, currentSelectValue, FilterValues, ListName, crrTrendYear, showTrendDetails } = this.state;



    let cronBachAlpha = {
      score: 0
    }
    if (cronbatchAlphaVarianceData && cronbatchAlphaVarianceData.score && cronbatchAlphaVarianceData.score.length > 0) {
      cronBachAlpha["score"] = (typeof cronbatchAlphaVarianceData.score[0] !== 'string' || cronbatchAlphaVarianceData.score[0] instanceof Number) && cronbatchAlphaVarianceData && cronbatchAlphaVarianceData.score && cronbatchAlphaVarianceData.score[0] ? cronbatchAlphaVarianceData.score[0].toFixed(2) : ''
    }

    let indexOverallValue = getOverallIndexData && getOverallIndexData[indexType] && parseFloat(getOverallIndexData[indexType].toFixed(2)) ? parseFloat(getOverallIndexData[indexType].toFixed(2)) : 0;



    let Item = {
      score: 0
    };
    if (getIndexFilterData) {
      Item = {
        score: (typeof getIndexFilterData[indexType] !== 'string' || getIndexFilterData[indexType] instanceof Number) && getIndexFilterData && getIndexFilterData[indexType] ? getIndexFilterData[indexType].toFixed(2) : ''
      }
    }

    let Benchmark = {
      score: 0
    };
    if (getAllBenchMarkData) {
      Benchmark = {
        score: (typeof getAllBenchMarkData[indexType] !== 'string' || getAllBenchMarkData[indexType] instanceof Number) && getAllBenchMarkData && getAllBenchMarkData[indexType] ? getAllBenchMarkData[indexType].toFixed(2) : ''
      }
    }


    console.log('FilterValues: ', FilterValues)

    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    let DemographicDataScore = [];
    let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      DemographicDataScore = getDemographicIndexMultiFilterData;
      DemographicDataDistribution = getIndexDistributionData;

    }


    let ScoreExcel = []
    let filter = FilterValues && FilterValues.length > 0 ? " (" + FilterValues.map((element) => { return element.value }).join(",") + ")" : " (Filter)"

    if (getIndexScore) {

      let Overall = {}
      Overall[ListName + filter] = "Overall";
      Overall["Scores"] = getIndexScore && getIndexScore[indexType] ? getIndexScore[indexType].toFixed(2) : "NaN";


      ScoreExcel.push(Overall);

    }

    if (DemographicDataScore && DemographicDataScore.length > 0) {
      DemographicDataScore.forEach((data) => {
        let dataScore = CountRestrict(data.count) ? "NaN" : data[indexType].toFixed(2);

        let tempData = {}
        if (data && data.name) {
          tempData[ListName + filter] = data.name;
          tempData["Scores"] = dataScore ? dataScore.toString() : "NaN";


        }

        ScoreExcel.push(tempData);


      })
    }






    console.log("11111------getQuestionTrendChartByMonthData-----------", getQuestionTrendChartByMonthData)



    // CountRestrict,getIndexDistributionData,getIndexData

    // let DistributionExcel = []
    // function IndexSummaryDistributionExcel (){

    // }




    let crrYear = Number(moment(Date.now()).format("YYYY"))
    let YearList = []
    for (var i = Number(crrYear); i >= 2020; i--) {
      YearList.push(i)
    }

    console.log("------YearList: -----------", YearList)
    console.log("------crrYear: -----------", crrYear)






    function getFavouribilityScore(question, dmgValue) {
      let score = "";
      if (getQuestionTrendChartByMonthData && getQuestionTrendChartByMonthData.length > 0) {

        let getIndex41 = getQuestionTrendChartByMonthData.findIndex(prev => prev.monthName === dmgValue)
        if (getIndex41 !== -1) {
          let DmgQuesObj = getQuestionTrendChartByMonthData[getIndex41];
          if (DmgQuesObj && DmgQuesObj.questionList && DmgQuesObj.questionList.length > 0) {
            let getIndex42 = DmgQuesObj.questionList.findIndex(prev => prev.name === question.name);
            if (getIndex42 !== -1) {
              let ques = DmgQuesObj.questionList[getIndex42]


              let total = ques['1'] + ques['2'] + ques['3'] + ques['4'] + ques['5'] + ques['6'];

              let tempscore = parseFloat(((ques['5'] + ques['6']) * 100 / total).toFixed(2));
              score = CountRestrict(ques && ques.count ? ques.count : "NA") ? "NA" : tempscore


            }

          }
        }

      }
      return score ? score : 0
    }


    function getOverallScore(ques) {
      let total = ques['1'] + ques['2'] + ques['3'] + ques['4'] + ques['5'] + ques['6'];
      let score = parseFloat(((ques['5'] + ques['6']) * 100 / total).toFixed(2));
      return score ? score : 0
    }


    function getColor(overallScore, dmgScore) {

      if (false) {
        return { Color: '#f1f1f1', diffTextColor: '#000' }

      }
      else {

        let diffNo = parseInt(Math.abs(parseFloat(overallScore) - parseFloat(dmgScore)));
        //////console.log("diffNo:",diffNo)


        if (parseFloat(overallScore) <= parseFloat(dmgScore)) { //greater (blue)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: '#53d453', diffTextColor: '#ffffff' }

          }
          else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: '#30ba30', diffTextColor: '#00000' }

          }
          else {
            return { Color: '#139c13', diffTextColor: '#00000' }
          }

        }
        else { //lesse (red)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: '#f5babd', diffTextColor: '#000000' }

          }
          else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: '#ea95a1', diffTextColor: '#000000' }

          }
          else {
            return { Color: '#d03e4d', diffTextColor: '#ffffff' }

          }
        }
      }

    }




    let MonthList = []
    // let NewQuesList = []
    if (getQuestionTrendChartByMonthData && getQuestionTrendChartByMonthData.length > 0) {
      getQuestionTrendChartByMonthData.forEach((element) => {
        MonthList.push({
          "label": moment(element.monthName, "MMM").format("MMMM"),
          "name": element.monthName
        });

        // if(element && element.questionList && element.questionList.length>0){
        //   element.questionList.forEach((element)=>{
        //       let getIndex1 = NewQuesList.findIndex(prev=>prev.name === element.name);
        //       if(getIndex1 === -1){
        //         NewQuesList.push(element)
        //       }
        //   })
        // }


      })
    }









    return (
      <>
        {/* end */}
        {/*sub nav bar 2 */}
        <div className="items-center justify-between md:flex">


          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />

          {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
          <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

          {FilterValues && FilterValues.length > 0 ?
            <>
              {ScoreExcel ?
                <div className="cursor-pointer bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] flex justify-between px-4 items-center relative py-2 rounded-md w-40 ">
                  <CSVLink data={ScoreExcel} filename={"IndexScore.csv"}>
                    <span className="w-24 h-6 text-xs text-center text-white ">Download Excel</span>
                  </CSVLink>
                </div>
                : '...'}
            </>
            : null}



        </div>


        {FilterValues && FilterValues.length > 0 ?
          null :
          <IndexKPI
            indexType={indexType}
            getIndexData={getIndexData}
            getIndexScore={getIndexScore}
            getAllBenchMarkData={getAllBenchMarkData}
            getRespondantDetailsData={getRespondantDetailsData}
          />
        }

        {showTrendDetails ?
          <>

            <div className="w-full" >

              <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">

                <div className="p-4 bg-white border">

                  <div className='flex'>
                    <span className='mr-2 cursor-pointer material-icons' onClick={() => this.handleDetailsBack()}>arrow_back</span>
                    <h1 className="pb-2 font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Detailed Trend Chart</h1>
                  </div>




                  {ErrorIndexObj && ErrorIndexObj["getQuestionTrendChartByMonthError"] ?
                    <>
                      <div className='my-24 text-2xl font-medium text-center text-gray-400'>{ErrorIndexObj["getQuestionTrendChartByMonthError"]}</div>
                    </>
                    :
                    <>
                      <div className="w-full mb-4 overflow-hidden overflow-x-auto whitespace-nowrap">
                        <table className="w-full border ">
                          <thead>
                            <tr className="text-xs text-gray-800 bg-gray-100 border-b">
                              {/* <th className="p-3 font-semibold text-gray-800 border-r"><div className="flex items-center justify-left">Dimension</div></th> */}
                              <th className="p-3 font-semibold text-gray-800 border-r"><div className="flex items-center justify-left">Question</div></th>

                              <th className="p-3 font-semibold text-gray-800 border-r">
                                <div className="flex items-center justify-center text-center">{"Overall"}</div>
                              </th>

                              {MonthList && MonthList.length > 0 ?
                                MonthList.map((dmg) =>
                                  <th className="p-3 font-semibold text-gray-800 border-r">
                                    <div className="flex items-center justify-center text-center">{dmg.name + moment(crrTrendYear, "YYYY").format("YY")}</div>
                                  </th>
                                ) : null}



                            </tr>
                          </thead>
                          <tbody>
                            {getQuestionData && getQuestionData.length > 0 ?
                              getQuestionData.map((question, index) =>
                                <tr key={index} className="text-xs text-gray-800 border-b">
                                  {/* <td className="px-2 py-2 font-normal border-r">
                                        <div style={{ width: '20%' }}>{question && question.parameterDimensionName ?
                                          GetParameterLabel(question.parameterDimensionName) :
                                          ""}</div>
                                      </td> */}
                                  <td className="px-2 py-2 font-normal border-r">
                                    <div className="whitespace-pre-line cursor-pointer w-96">{question.name}</div>
                                  </td>

                                  <td className="px-2 py-2 font-normal border-r">
                                    <div className="text-center whitespace-pre-line cursor-pointer" >{getOverallScore(question) ? getOverallScore(question) + "%" : "NA"}</div>
                                  </td>


                                  {MonthList && MonthList.length > 0 ?
                                    MonthList.map((dmg) =>
                                      <td className="px-2 py-2 font-normal border-r" style={{ color: getColor(getOverallScore(question), getFavouribilityScore(question, dmg.name)).diffTextColor, background: getColor(getOverallScore(question), getFavouribilityScore(question, dmg.name)).Color }}>
                                        <div className="text-center whitespace-pre-line cursor-pointer" >{getFavouribilityScore(question, dmg.name) ? (getFavouribilityScore(question, dmg.name) + "%") : "NA"}</div>
                                      </td>
                                    ) : null}


                                </tr>
                              )
                              : null}

                          </tbody>
                        </table>
                      </div>
                    </>}



                  {/* <div className="py-4 text-" style={{ color: "#3366ff" }}>* We are not showing data where employee count is less than {CountRestrict("","","",true)}</div> */}
                </div>
              </div>


            </div>
          </>
          :
          <>
            <div onClick={() => this.closeFilter(false)} className="" >
              <div className="flex gap-4 ">

                <div className="w-4/12 p-4 bg-white border">

                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">{name} Score</h1>


                  {ErrorIndexObj && ErrorIndexObj["getIndexV2Error"] ?
                    <>
                      <div className='my-24 text-2xl font-medium text-center text-gray-400'>{ErrorIndexObj["getIndexV2Error"]}</div>
                    </>
                    :
                    <>
                      {FilterValues && FilterValues.length > 0 ?
                        <>
                          {ErrorIndexObj && ErrorIndexObj["getDemographicIndexMultiFilterError"] ?
                            <>
                              <div className='my-24 text-2xl font-medium text-center text-gray-400'>{ErrorIndexObj["getDemographicIndexMultiFilterError"]}</div>
                            </>
                            :
                            <div className="py-4">
                              <StackChart
                                getIndexScore={getIndexScore}
                                getDemographicIndexData={DemographicDataScore}
                                currFilterName={currFilterName}
                                indexType={indexType}
                                CountRestrict={CountRestrict}
                                handleSelectEDA={this.handleSelectEDA}
                                ErrorIndexObj={ErrorIndexObj}
                              />
                              {/* <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than {CountRestrict("","","",true)}</div> */}



                            </div>
                          }
                        </>




                        :
                        <SummaryScore name={name} isCrole={isCrole} indexOverallValue={indexOverallValue} cronBachAlpha={cronBachAlpha} item={Item} DistributionData={getIndexData} Benchmark={Benchmark} />

                      }
                    </>}
                </div>

                <div className="w-full p-4 bg-white border">
                  <div className='flex items-center justify-between mb-1'>

                    <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Experience Score trend by month </h1>

                    <div className='flex items-center justify-between'>
                      <button className='cursor-pointer mr-4 bg-white text-[#3D405B] font-medium border flex justify-between px-4 items-center relative py-1.5 rounded-md space-x-1.5 hover:text-[#2196f3] transition-all duration-150'
                        onClick={() => this.handleShowTrendDetails(true)}>{"View details"}</button>

                      <YearFilter
                        showFilter={this.state.showFilter}
                        showValue={this.state.showValue}
                        closeFilter={this.closeFilter}

                        EmpFilterData2={EmpFilterData2}
                        getIndexFilterData={getIndexByFilterDMGData}
                        handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
                        getEMPFilterLabel={getEMPFilterLabel}

                        crrTrendYear={crrTrendYear}
                        YearList={YearList}
                        handleYearValueYear={this.handleYearValueYear}
                      />
                    </div>


                  </div>

                  <MultiTrendChart
                    crrTrendYear={crrTrendYear}
                    getIndexTrendChartByMonthData={getIndexTrendChartByMonthData}
                  />


                </div>

              </div>
            </div>
          </>}


        <EDAModal
          showModal={this.state.showEDAModal}
          closeModal={this.closeEDAModal}
          getDMGIndexManyFilterData={getDMGIndexManyFilterData}
          indexType={indexType}
          dmgLevel={this.state.ListName}
          dmgValue={this.state.crrDMGValue}

        />


      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
