import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../../../../_helpers';
import { CONST } from "../../../../../_config";

import Header from '../../../Header/Header';
import Sidebar from '../../../../Overview/Sidebar/Sidebar';

import { dashboardActions, surveyActions } from '../../../../../_actions';
import moment from 'moment';
import { CSVLink } from "react-csv";
import ConfirmMail from './Components/ConfirmMail';
import SurveyReport2 from '../../../../Dashboard/Overview/SurveyReport/SurveyReport';
import LoadingOverlay from 'react-loading-overlay';
import ResponseCards from './Components/ResponseCards';
import FilterDMG from './Components/FilterDMG/Filter';
import ResponseMetaTable from './Components/ResponseMetaTable';

class SurveyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {

      surveyReportEmail: '',
      openModalComfirmSend: false,
      SaveEmployeeId: '',

      responseType: 1,
      litsXAxis: 'Gender',

      showExportModal: false,

      filterList: [
        {
          id: true,
          type: 'Show All'
        },
        {
          id: false,
          type: 'Show Filter'
        }
      ],

      isMailList: [
        {
          id: 0,
          type: 'Deliver Status'
        },
        {
          id: 1,
          type: 'Delivered'
        },
        {
          id: 2,
          type: 'Not Delivered'
        }
      ],

      isClickedList:
        [
          {
            id: 0,
            type: 'Click Status'
          },
          {
            id: 1,
            type: 'Clicked',
          },
          {
            id: 2,
            type: 'Not Clicked'
          }
        ],

      isCompleteList: [
        {
          id: 0,
          type: 'Complete Status'
        },
        {
          id: 1,
          type: 'Completed'
        },
        {
          id: 2,
          type: 'Not Completed'
        }
      ],

      selectFilterType: 2,
      filterformData: {
        "showAll": false,
        "isMailSent": 0,
        "isClicked": 0,
        "isComplete": 0
      },

      showSidebar: true,
      CrrRoute: "survey",
      crrSurvey: "",

      CurrentLanguage: 'en',
      CurrentLanguageName: 'English',
      showLanguageModal: false,

      campaignEmpPageNo: 1,
      campaignEmpSize: 100,
      resAnalyticPageNo: 1,
      handleOpenCodeModal: false,
      showMsg: false

    }
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getClientProfile());

    let data = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(dashboardActions.getCompanyNameOnly());


    //commented
    // this.props.dispatch(surveyActions.responseAnalysisBySurveyId(data));

    let data2 = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(dashboardActions.getEmpFilter(data2));

    this.props.dispatch(surveyActions.getAssessmentByTopBottom(data2));
    this.props.dispatch(surveyActions.getResponseAssessmentShortCode(data2));

    this.props.dispatch(dashboardActions.getAssessmentDownloadStatus({}));

    // this.props.dispatch(surveyActions.getCampaignEmployeesByIDSurveyWise(data));


    //commented
    // this.props.dispatch(surveyActions.getAllResponseAnalyticsBySurveyId(data));


    this.props.dispatch(dashboardActions.getRespondantDetails(data));

    this.props.dispatch(surveyActions.getIndexByFilterDMG({
      filters: []
    }));


    let dataX1 = {
      surveyId: this.props.match.params.id,
      pageNo: this.state.campaignEmpPageNo,
      size: this.state.campaignEmpSize
    }
    this.props.dispatch(surveyActions.getResponseAnalyticsPagination(dataX1));







    this.props.dispatch(surveyActions.getSurveyById(data));


    // this.props.dispatch(dashboardActions.getEmpFilter("getMeanScoreQwise",data.surveyId));
    // this.props.dispatch(dashboardActions.getIndexFilter(data));

  }


  handleCreateSurveyPopup = () => {

  }


  handleSetFilter = (value) => {

    let { filterformData } = this.state;

    if (value === 'true' || value === true) {
      this.setState({ selectFilterType: 1 });
      filterformData['showAll'] = true;
    }
    else {
      this.setState({ selectFilterType: 2 });
      filterformData['showAll'] = false;
    }

    this.setState({ filterformData });

  }


  handleIsMailSent = (value) => {
    let { filterformData } = this.state;
    filterformData['isMailSent'] = Number(value);

    // if (value === 'true' || value === true) {
    //   filterformData['isMailSent'] = true;
    // }
    // else {
    //   filterformData['isMailSent'] = false;

    // }
    this.setState({ filterformData });

  }

  handleIsClicked = (value) => {
    let { filterformData } = this.state;
    filterformData['isClicked'] = Number(value);
    // if (value === 'true' || value === true) {
    //   filterformData['isClicked'] = true;
    // }
    // else {
    //   filterformData['isClicked'] = false;

    // }
    this.setState({ filterformData });

  }

  handleIsComplete = (value) => {
    let { filterformData } = this.state;
    filterformData['isComplete'] = Number(value);

    // if (value === 'true' || value === true) {
    //   filterformData['isComplete'] = true;
    // }
    // else {
    //   filterformData['isComplete'] = false;

    // }
    this.setState({ filterformData });

  }



  handleEmailInput = (val) => {
    this.setState({ surveyReportEmail: val }, () => {

      if (!this.state.surveyReportEmail) {
        let dataX1 = {
          "surveyId": this.props.match.params.id,
          "pageNo": this.state.campaignEmpPageNo,
          "size": this.state.campaignEmpSize
        }
        this.props.dispatch(surveyActions.getResponseAnalyticsPagination(dataX1));


      }


    });

  }

  handleEmailSearchInput = () => {

    let dataX1 = {
      "surveyId": this.props.match.params.id,
      "keyWord": this.state.surveyReportEmail,
      "pageNo": this.state.campaignEmpPageNo,
      "size": this.state.campaignEmpSize
    }
    this.props.dispatch(surveyActions.getResponseAnalyticsKeyWord(dataX1));

  }

  HandleFilterType = (value) => {
    this.setState({ selectFilterType: value });
  }

  RefreshAPI = () => {
    let data = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(surveyActions.responseAnalysisBySurveyId(data));
    this.props.dispatch(surveyActions.getCampaignEmployeesByIDSurveyWise(data));

  }

  forceMail = (id) => {
    this.setState({
      openModalComfirmSend: true,
      SaveEmployeeId: id
    })
  }


  handleSendSingleMail = () => {
    let { SaveEmployeeId } = this.state;
    this.props.dispatch(surveyActions.resendCampain({ id: SaveEmployeeId }));
    this.setState({ SaveEmployeeId: '', openModalComfirmSend: false });
  }

  resendCampain = (id) => {
    this.setState({
      openModalComfirmSend: true,
      SaveEmployeeId: id
    })
    //  this.props.dispatch(surveyActions.resendCampain({ id: id }));
  }

  handleConfirmMailClose = () => {
    this.setState({ openModalComfirmSend: false, SaveEmployeeId: '' });
  }

  handleLanguage = (item) => {
    this.setState({ CurrentLanguage: item.shortName, CurrentLanguageName: item.name, showLanguageModal: false });
  }


  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  }
  routeLink = (link) => {
    this.props.history.push('/app/' + link);
    this.setState({ CrrRoute: link });
  }
  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  }

  onEmployeeResponse = (employeeList) => {

  }

  handleResponseType = (value) => {
    this.setState({ responseType: value });



    let data = {
      surveyId: this.props.match.params.id
    }

    if (value === 1) {
      this.props.dispatch(surveyActions.responseAnalysisBySurveyId(data));
      this.props.dispatch(surveyActions.getCampaignEmployeesByIDSurveyWise(data));
    }
    else if (value === 2) {
      this.props.dispatch(dashboardActions.getRespondantDetails(data));

    }
    else {


      let { survey } = this.props;
      let { EmpFilterData } = survey;

      let CurrentFilterName = 'Gender';
      if (EmpFilterData && EmpFilterData.length > 0) {
        CurrentFilterName = EmpFilterData[0].name
      }

      let data2 = {
        "surveyId": this.props.match.params.id,
        "xAxis": CurrentFilterName
      }
      this.props.dispatch(dashboardActions.getMetaResponseAnalyticsFilter(data2));

      this.props.dispatch(dashboardActions.getMetaRespondantDetails(data));



    }


  }


  handlePaginaton = (type, total) => {
    let { campaignEmpPageNo } = this.state;
    let PageNo = 1;
    if (type === "prev") {
      if (campaignEmpPageNo > 1) {
        PageNo = campaignEmpPageNo - 1;
      }
    }
    else if (type === "next" && total > 100) {
      PageNo = campaignEmpPageNo + 1;
    }

    this.setState({ campaignEmpPageNo: PageNo }, () => {
      let dataX1 = {
        surveyId: this.props.match.params.id,
        pageNo: this.state.campaignEmpPageNo,
        size: this.state.campaignEmpSize
      }
      this.props.dispatch(surveyActions.getResponseAnalyticsPagination(dataX1));
    });






  }




  loadResponsePrev = () => {
    let { resAnalyticPageNo } = this.state;
    if (resAnalyticPageNo > 1) {
      this.setState({ resAnalyticPageNo: resAnalyticPageNo - 1 });
    }
  }

  loadResponseNext = () => {
    let { resAnalyticPageNo } = this.state;
    this.setState({ resAnalyticPageNo: resAnalyticPageNo + 1 });
  }

  loadResponse = () => {
    let data = {
      surveyId: this.props.match.params.id,
      pageNo: this.state.resAnalyticPageNo,
      size: 1000
    }

    this.props.dispatch(surveyActions.responseAnalysisBySurveyId(data));

  }

  loadAnalytics = () => {

    let data = {
      surveyId: this.props.match.params.id
    }

    this.props.dispatch(surveyActions.getAllResponseAnalyticsBySurveyId(data));
  }

  loadIceAnalytics = () => {

    let data = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(surveyActions.getQuestionNameIDOnly(data));

    this.props.dispatch(surveyActions.responseIceDownloadBySurveyId(data));
  }


  handleExportModal = (check) => {
    this.setState({ showExportModal: check })
  }


  handleCopyClick() {
    this.setState({ showMsg: true });
    setTimeout(() => {
      this.setState({ showMsg: false });
    }, 3000);
  }



  render() {
    let { survey, dashboard } = this.props;
    let { getAssessmentDownloadStatusData, getIndexByFilterDMGData, getMetaResponseAnalyticsFilterData, EmpFilterData, getIndexFilterData, getRespondantDetailsData, getMetaRespondantDetailsData, getCompanyNameOnlyData } = dashboard;
    let { getQuestionNameIDOnlyData, iceloading, responseIceDownloadBySurveyIdData, getResponseAssessmentShortCodeData, getAssessmentByTopBottomData, getSurveyData, resAnalyticsLoading, AllAnalyticsLoading, getAllResponseAnalyticsBySurveyIdData, getResponseAnalyticsPaginationData, getResponseAnalyticsPaginationTotal, getLanguageLabelData, getLanguageData, responseAnalysisBySurveyIdData } = survey;
    let { showMsg, responseType, showLanguageModal, CurrentLanguageName, CurrentLanguage, CrrRoute, showSidebar, openModalComfirmSend, filterformData, selectFilterType, surveyReportEmail } = this.state;

    let loading = survey.loading || dashboard.loading;

    //console.log("getAssessmentByTopBottomData---->", getAssessmentByTopBottomData);

    let getCampaignEmployeesByIDSurveyWiseData = getResponseAnalyticsPaginationData;


    function getEmpDetails(a, b, c) {
      return (a && a[b] && a[b] && a[b][c] ? a[b][c] : "####")
    }


    let ListAllResponseAnalyticsV1 = []
    if (getAllResponseAnalyticsBySurveyIdData && getAllResponseAnalyticsBySurveyIdData.length > 0) {
      getAllResponseAnalyticsBySurveyIdData.forEach((element) => {

        let temp = {}
        temp["emp_id"] = getEmpDetails(element, "employeeId", "emp_id");
        temp["email"] = getEmpDetails(element, "employeeId", "email");
        temp["name"] = getEmpDetails(element, "employeeId", "name");

        temp["Delivered"] = element && element.Delivered ? element.Delivered : "";
        temp["Clicked"] = element && element.Clicked ? element.Clicked : "";
        temp["Completed"] = element && element.Completed ? element.Completed : "";
        temp["TimeTaken"] = element && element.TimeTaken ? element.TimeTaken : "";

        temp["reminderCount"] = element && element.reminderCount ? element.reminderCount : 0;

        if (element && element.reminderDate && element.reminderDate.length > 0) {
          for (var i = 0; i < element.reminderDate.length; i++) {
            let dateV1 = moment.unix(element.reminderDate[i] / 1000).format("DD-MMM-YYYY");
            temp["reminderDate" + (i + 1).toString()] = dateV1;
          }
        }

        ListAllResponseAnalyticsV1.push(temp)
      })
    }

    // function getFormatted(ele, field) {
    //   return ele && ele["employee"] && ele["employee"][0] && ele["employee"][0][field] ? ele["employee"][0][field] : ""
    // }

    // function getQuesName(list, queId) {
    //   let name = "";
    //   if (list && list.length > 0) {
    //     let getIndex = list.findIndex(prev => prev.id.toString() === queId.toString());
    //     if (getIndex !== -1) {
    //       name = list && list[getIndex] && list[getIndex]["name"] ? list[getIndex]["name"] : ""
    //     }
    //   }
    //   return name
    // }

    // let loadICEList = []
    // if (responseIceDownloadBySurveyIdData && responseIceDownloadBySurveyIdData.length > 0 && getQuestionNameIDOnlyData && getQuestionNameIDOnlyData.length > 0) {
    //   responseIceDownloadBySurveyIdData.forEach((ele) => {

    //     let tempData = {
    //       "name": getFormatted(ele, "name"),
    //       "emp_id": getFormatted(ele, "emp_id")
    //     }
    //     if (ele && ele.answerList && ele.answerList.length > 0) {
    //       ele.answerList.forEach((ans) => {
    //         tempData[getQuesName(getQuestionNameIDOnlyData, ans.questionId)] = ans && ans.responses && ans.responses.length > 0 ? ans.responses.map((inner) => (inner.feedback ? (inner.feedback + " = " + inner.answer) : inner.answer)).join("; ") : ""
    //       })
    //     }
    //     loadICEList.push(tempData)
    //   })

    // }


    // let loadICEList = []
    // if (responseIceDownloadBySurveyIdData && responseIceDownloadBySurveyIdData.length > 0 && getQuestionNameIDOnlyData && getQuestionNameIDOnlyData.length > 0) {
    //   responseIceDownloadBySurveyIdData.forEach((ele) => {
    //     let tempData = {
    //       "name": getFormatted(ele, "name"),
    //       "emp_id": getFormatted(ele, "emp_id")
    //     }
    //     if (ele && ele.answerList && ele.answerList.length > 0) {
    //       ele.answerList.forEach((ans) => {
    //         tempData[getQuesName(getQuestionNameIDOnlyData, ans.questionId)] = ans && ans.responses && ans.responses.length > 0 ? ans.responses : [];
    //       })
    //     }
    //     loadICEList.push(tempData);
    //   })
    // }

    const getFormatted = (ele, field) => ele?.employee?.[0]?.[field] || "";

    const getQuesName = (list, queId) => list?.find(({ id }) => id.toString() === queId.toString())?.name || "";
    
    const getFeedbackAnswer = (responseList, dmgValue) => responseList?.find(({ feedback }) => feedback === dmgValue)?.answer || "NA";
    
    const rowList = [...new Set(
      responseIceDownloadBySurveyIdData?.flatMap(({ answerList }) =>
        answerList?.flatMap(({ responses }) =>
          responses?.map(({ feedback }) => feedback).filter(Boolean)
        )
      )
    )];
    
    let loadICEList = [];
    
    responseIceDownloadBySurveyIdData?.forEach(ele => {
      rowList.forEach(row => {
        const tempData = {
          name: getFormatted(ele, "name"),
          emp_id: getFormatted(ele, "emp_id"),
          demographic: row
        };
    
        ele.answerList?.forEach(ans => {
          tempData[getQuesName(getQuestionNameIDOnlyData, ans.questionId)] =
            getFeedbackAnswer(ans.responses, row);
        });
    
        loadICEList.push(tempData);
      });
    });
    


    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    // //console.log('moduleAccess: ',moduleAccess)

    let Isadmin = false;

    // let SHOW_ELEMENT = false;
    let UserRoleName = ""
    let clientData = this.props.dashboard.ClientProfileData;

    if (clientData && clientData.role) {
      UserRoleName = clientData.role;
      if (clientData.role === "ADMIN") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
        Isadmin = true;
      }
      else if (clientData.role === "MNGR") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        // SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    //END -------------------------------------------------------

    // clicked: 2684
    // completed: 2629
    // mailSent: 2970
    // total: 2970

    // //console.log('surveyReportEmail: ',surveyReportEmail)

    // let delivered = 0;
    // let notdelivered = 0;

    let clicked = 0;
    let notclicked = 0;

    let completed = 0;
    let notcompleted = 0;

    let total = 0;

    let completedEmpList = [];
    let notCompletedEmpList = [];


    if (getRespondantDetailsData && getRespondantDetailsData[0]) {
      let RespondantDetailsData = getRespondantDetailsData[0];

      total = RespondantDetailsData && RespondantDetailsData.total ? RespondantDetailsData.total : 0;

      // delivered = RespondantDetailsData && RespondantDetailsData.mailSent ? RespondantDetailsData.mailSent : 0;
      // notdelivered = (total - delivered) >= 0 ? total - delivered : 0;

      clicked = RespondantDetailsData && RespondantDetailsData.clicked ? RespondantDetailsData.clicked : 0;
      notclicked = (total - clicked) >= 0 ? total - clicked : 0;

      completed = RespondantDetailsData && RespondantDetailsData.completed ? RespondantDetailsData.completed : 0;
      notcompleted = (total - completed) >= 0 ? total - completed : 0;

    }


    if (getCampaignEmployeesByIDSurveyWiseData && getCampaignEmployeesByIDSurveyWiseData.length > 0) {
      getCampaignEmployeesByIDSurveyWiseData.forEach((item) => {

        // total = total + 1;

        // delivered
        if (item.isMailSent === true) {
          // delivered = delivered + 1;
        }
        else {
          // notdelivered = notdelivered + 1;
        }
        // clicked
        if (item.isClicked === true) {
          // clicked = clicked + 1;
        }
        else {
          // notclicked = notclicked + 1;
        }
        // completed
        if (item.isComplete === true) {
          // completed = completed + 1;
          completedEmpList.push(item);
        }
        else {
          // notcompleted = notcompleted + 1;
          notCompletedEmpList.push(item);
        }
      })
    }


    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }

    let ReportDataList = [
      {
        "name": LanguageObj && LanguageObj["total_" + CurrentLanguage] ? LanguageObj["total_" + CurrentLanguage] : "Total",
        "value": total,
        "color": '#99cc00',
        "icon": <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-align-box-center-bottom" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 19v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" /><path d="M11 17h2" /><path d="M9 14h6" /><path d="M10 11h4" /></svg>
      },
      // {
      //   "name": LanguageObj && LanguageObj["delivered_" + CurrentLanguage] ? LanguageObj["delivered_" + CurrentLanguage] : "Delivered",
      //   "value": delivered,
      //   "color": '#00cc66'
      // },
      // {
      //   "name": LanguageObj && LanguageObj["not_delivered_" + CurrentLanguage] ? LanguageObj["not_delivered_" + CurrentLanguage] : "Not Delivered",
      //   "value": notdelivered,
      //   "color": '#ff4d4d'
      // },
      {
        "name": LanguageObj && LanguageObj["clicked_" + CurrentLanguage] ? LanguageObj["clicked_" + CurrentLanguage] : "Clicked",
        "value": clicked,
        "color": '#bf00ff',
        "icon": <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-click" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12l3 0" /><path d="M12 3l0 3" /><path d="M7.8 7.8l-2.2 -2.2" /><path d="M16.2 7.8l2.2 -2.2" /><path d="M7.8 16.2l-2.2 2.2" /><path d="M12 12l9 3l-4 2l-2 4l-3 -9" /></svg>
      },
      {
        "name": LanguageObj && LanguageObj["not_clicked_" + CurrentLanguage] ? LanguageObj["not_clicked_" + CurrentLanguage] : "Not Clicked",
        "value": notclicked,
        "color": '#3399ff',
        "icon": <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-exclamation-mark-off" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 19v.01" /><path d="M12 15v-3m0 -4v-3" /><path d="M3 3l18 18" /></svg>
      },
      {
        "name": LanguageObj && LanguageObj["completed_" + CurrentLanguage] ? LanguageObj["completed_" + CurrentLanguage] : "Completed",
        "value": completed,
        "color": '#008080',
        "icon": <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-check" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M9 15l2 2l4 -4" /></svg>
      },
      {
        "name": LanguageObj && LanguageObj["not_completed_" + CurrentLanguage] ? LanguageObj["not_completed_" + CurrentLanguage] : "Not Completed",
        "value": notcompleted,
        "color": '#ff9933',
        "icon": <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clipboard-off" width="40" height="40" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5.575 5.597a2 2 0 0 0 -.575 1.403v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2m0 -4v-8a2 2 0 0 0 -2 -2h-2" /><path d="M9 5a2 2 0 0 1 2 -2h2a2 2 0 1 1 0 4h-2" /><path d="M3 3l18 18" /></svg>
      },


    ]



    let isMailList = [
      {
        id: 0,
        type: 'Deliver Status'
      },
      {
        id: 1,
        type: LanguageObj && LanguageObj["delivered_" + CurrentLanguage] ? LanguageObj["delivered_" + CurrentLanguage] : "Delivered"
      },
      {
        id: 2,
        type: LanguageObj && LanguageObj["not_delivered_" + CurrentLanguage] ? LanguageObj["not_delivered_" + CurrentLanguage] : "Not Delivered"
      }
    ]

    let isClickedList = [
      {
        id: 0,
        type: 'Click Status'
      },
      {
        id: 1,
        type: LanguageObj && LanguageObj["clicked_" + CurrentLanguage] ? LanguageObj["clicked_" + CurrentLanguage] : "Clicked",
      },
      {
        id: 2,
        type: LanguageObj && LanguageObj["not_clicked_" + CurrentLanguage] ? LanguageObj["not_clicked_" + CurrentLanguage] : "Not Clicked"
      }
    ]

    let isCompleteList = [
      {
        id: 0,
        type: 'Complete Status'
      },
      {
        id: 1,
        type: LanguageObj && LanguageObj["completed_" + CurrentLanguage] ? LanguageObj["completed_" + CurrentLanguage] : "Completed"
      },
      {
        id: 2,
        type: LanguageObj && LanguageObj["not_completed_" + CurrentLanguage] ? LanguageObj["not_completed_" + CurrentLanguage] : "Not Completed"
      }
    ]


    // //console.log('getCampaignEmployeesByIDSurveyWiseData: ',getCampaignEmployeesByIDSurveyWiseData)


    // //console.log('getCampaignEmployeesByIDSurveyWiseData:L ',getCampaignEmployeesByIDSurveyWiseData);
    let NeglectedList = ["isTest", "isDisable", "actionPlanStage", "id", "createdAt", "companyId", "currentStage"]

    let EmpFieldList = [];
    if (getCampaignEmployeesByIDSurveyWiseData && getCampaignEmployeesByIDSurveyWiseData.length > 0) {

      if (getCampaignEmployeesByIDSurveyWiseData && getCampaignEmployeesByIDSurveyWiseData[0] && getCampaignEmployeesByIDSurveyWiseData[0].employeeId) {
        let tempLevelList = Object.keys(getCampaignEmployeesByIDSurveyWiseData[0].employeeId);
        if (tempLevelList && tempLevelList.length > 0) {
          tempLevelList.forEach((temp) => {
            if (NeglectedList.findIndex(prev => prev === temp) === -1) {
              EmpFieldList.push(temp);
            }
          });
        }
      }


    }


    let NewEmployeeList = [];
    if (getCampaignEmployeesByIDSurveyWiseData && getCampaignEmployeesByIDSurveyWiseData.length > 0) {
      getCampaignEmployeesByIDSurveyWiseData.forEach((item, index) => {
        if (!index) {
          console.log('item.isClicked: ', item.isClicked)
        }
        let temp = {}

        if (EmpFieldList && EmpFieldList.length > 0) {
          EmpFieldList.forEach((filter) => {
            temp[filter] = item && item.employeeId && item.employeeId[filter] ? item.employeeId[filter] : "";
          });
        }

        temp["Delivered"] = item && item.isMailSent ? "Yes" : "No";
        temp["Clicked"] = item && item.isClicked ? item && item.clickedDate ? moment.unix(item.clickedDate / 1000).format("DD/MM/YYYY, HH:mm") : "####" : "No";
        temp["Completed"] = item && item.isComplete ? item && item.completeDate ? moment.unix(item.completeDate / 1000).format("DD/MM/YYYY, HH:mm") : "####" : "No";

        // temp["ClickedDate"]=item && item.isClicked?item && item.clickedDate?moment.unix(item.clickedDate / 1000).format("DD/MM/YYYY, HH:mm"):"####":"####";
        // temp["CompletedDate"]=item && item.isComplete?item && item.completeDate?moment.unix(item.completeDate / 1000).format("DD/MM/YYYY, HH:mm"):"####":"####";


        NewEmployeeList.push(temp);

      });
    }


    let EmployeeList = [];

    if (filterformData.showAll) {
      EmployeeList = getCampaignEmployeesByIDSurveyWiseData;
    }
    else {
      if (getCampaignEmployeesByIDSurveyWiseData && getCampaignEmployeesByIDSurveyWiseData.length > 0) {
        getCampaignEmployeesByIDSurveyWiseData.forEach((tempdata) => {

          if (filterformData.isMailSent === 0 && filterformData.isMailSent === 0 && filterformData.isMailSent === 0) {

          }

          if (
            (filterformData.isMailSent === 0 ? true : (filterformData.isMailSent === 1 ? (tempdata.isMailSent === true) : (filterformData.isMailSent === 2 ? (tempdata.isMailSent === false) : false)))
            &&
            (filterformData.isClicked === 0 ? true : (filterformData.isClicked === 1 ? (tempdata.isClicked === true) : (filterformData.isClicked === 2 ? (tempdata.isClicked === false) : false)))
            &&
            (filterformData.isComplete === 0 ? true : (filterformData.isComplete === 1 ? (tempdata.isComplete === true) : (filterformData.isComplete === 2 ? (tempdata.isComplete === false) : false)))
          ) {
            EmployeeList.push(tempdata)
          }
        })
      }
    }

    //console.log('filterformData: ', filterformData)
    //console.log('EmployeeList: ', EmployeeList)

    let surveyId = this.props.match.params.id;


    function getPageValue(type, campaignEmpPageNo, campaignEmpSize, getResponseAnalyticsPaginationTotal) {
      let value1 = (((campaignEmpPageNo - 1) * campaignEmpSize)) + 1
      let value2 = (((campaignEmpPageNo - 1) * campaignEmpSize)) + 1 + (campaignEmpSize - 1)
      let value3 = getResponseAnalyticsPaginationTotal ? getResponseAnalyticsPaginationTotal : 0;

      if (type === 1) {
        return value1
      } else if (type === 2) {
        return (value3 < value2) ? value3 : value2
      } else {
        return value3
      }

    }

    let fileName = "Survey_Report_" + (moment(Date.now()).format('DD-MM-YYYY')) + ".csv"
    if (getSurveyData && getSurveyData.surveyModule && getSurveyData.surveyModule === 2) {
      fileName = "Sense" + "_Survey_Report_" + (moment(Date.now()).format('DD-MM-YYYY')) + ".csv"
    } else {
      if (getSurveyData && getSurveyData.name) {
        fileName = (getSurveyData.name).replace(/\s+/g, '_') + "_Survey_Report_" + (moment(Date.now()).format('DD-MM-YYYY')) + ".csv"
      }

    }
    let isAssessment = false;
    let AssessmentCodeList = []

    if (getSurveyData && getSurveyData.surveyModule && getSurveyData.surveyModule === 6) {
      isAssessment = true;


      function getAssessmentStatus(emp_id, type) {
        let countObj = {
          "date": 0,
          "count": 0
        };
        if (getAssessmentDownloadStatusData && getAssessmentDownloadStatusData.length > 0) {
          getAssessmentDownloadStatusData.forEach((element) => {
            if (element && element.employeeId && element.employeeId.emp_id) {
              if (element.employeeId.emp_id === emp_id) {
                countObj["date"] = moment.unix(((element && element["reportDownloadDate"] ? element["reportDownloadDate"] : 0)) / 1000).format("DD/MM/YYYY, HH:mm");
                countObj["count"] = element && element["reportDownloadCount"] ? element["reportDownloadCount"] : 0;
              }
            }

          })
        }
        return countObj[type] ? countObj[type] : "NA"
      }


      if (getResponseAssessmentShortCodeData && getResponseAssessmentShortCodeData.length > 0) {
        getResponseAssessmentShortCodeData.forEach((element) => {
          let temp = {
            "Name": element && element.employeeId && element.employeeId.name ? element.employeeId.name : "NA",
            "Email": element && element.employeeId && element.employeeId.email ? element.employeeId.email : "NA",
            "EmpId": element && element.employeeId && element.employeeId.emp_id ? element.employeeId.emp_id : "NA",
            "Code": element && element.shortCode ? element.shortCode : "NA",
            "Started": element && element.isClicked ? "YES" : "NO",
            "Completed": element && element.isComplete ? "YES" : "NO",
            "Last Download": getAssessmentStatus(element && element.employeeId && element.employeeId.emp_id ? element.employeeId.emp_id : "NA", "date"),
            "Download Count": getAssessmentStatus(element && element.employeeId && element.employeeId.emp_id ? element.employeeId.emp_id : "NA", "count")
          }
          AssessmentCodeList.push(temp)
        })
      }


    }
    //console.log('AssessmentCodeList: ', AssessmentCodeList)
    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text='Loading Data...'
          className="loader"
        >
          {/* <div>
            <Sidebar sidebarToggle={this.sidebarToggle} accessAllow={accessAllow} showSidebar={showSidebar} routeLink={this.routeLink} CrrRoute={CrrRoute}
              LanguageLabel={this.LanguageLabel}

              LanguageObj={LanguageObj}
              CurrentLanguage={CurrentLanguage}
            />

            <main className="h-screen bg-gray-100 lg:ml-16">

              <Header onLogout={this.onLogout}
                CurrentLanguageName={CurrentLanguageName}
                getLanguageData={getLanguageData}
                handleLanguage={this.handleLanguage}
                openLanguageModal={this.openLanguageModal}
                showLanguageModal={showLanguageModal}
                companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}


              /> */}
          <div className='h-screen overflow-hidden bg-gray-50'>

            <Header
              ClientProfileData={clientData}
              handleLogout={this.handleLogout}
              props={this.props}
              companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}
              sidebarToggle={this.sidebarToggle}
            />

            <div className="flex w-full" style={{ height: 'calc(100% - 2rem)' }}>

              <Sidebar
                onOverview={this.onOverview}
                showSidebar={showSidebar}
                handleSidebar={this.handleSidebar}
                accessAllow={accessAllow}
                routeLink={this.routeLink}
                showMobileSidebar={this.state.showMobileSidebar}
                sidebarToggle={this.sidebarToggle}
              />
              <main className="flex-1 w-full overflow-hidden overflow-y-auto  h-[calc(100vh-4rem)] bg-[#F9FAFB]">

                {accessAllow("responseAnalytics") ?

                  <div className='p-6 space-y-6'>

                    <div className='flex items-center space-x-4'>
                      <span onClick={() => this.props.history.goBack()} class="cursor-pointer material-symbols-outlined bg-blue-100 p-2 rounded-full text-blue-500 ">arrow_back</span>
                      <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Response Analytics</h1>
                    </div>


                    <div className="items-center justify-between space-x-6 md:flex">
                      <div className="flex items-center w-full space-x-2 overflow-x-auto border-b whitespace-nowrap">
                        <h1
                          onClick={() => this.handleResponseType(1)}
                          className={
                            responseType === 1
                              ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                              : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-slate-600   rounded-t-md border"
                          }
                        >
                          {"Response Status Analytics"}
                        </h1>

                        <h1
                          onClick={() => this.handleResponseType(3)}
                          className={
                            responseType === 3
                              ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                              : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-slate-600   rounded-t-md border  "
                          }
                        >
                          {"Response Metadata Analytics"}
                        </h1>

                        <h1
                          onClick={() => this.handleResponseType(4)}
                          className={
                            responseType === 4
                              ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                              : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-slate-600   rounded-t-md border  "
                          }
                        >
                          {"Response Status Tracking"}
                        </h1>




                      </div>

                    </div>




                    {showMsg && (
                      <div className="fixed whitespace-nowrap z-20 top-24 right-0 p-6 py-4 rounded-l-lg shadow-md border-l-4 border-green-500 text-[#3D405B] bg-white ">
                        <p> Assessment Survey Link Copied !</p>
                      </div>
                    )}




                    {responseType === 1 ?
                      <div className="">

                        {/* here is boxes */}
                        <div className="grid justify-center grid-cols-1 gap-4 text-gray-600 xl:grid-cols-5 md:grid-cols-3 ">

                          {ReportDataList && ReportDataList.length > 0 ?
                            ReportDataList.map((item) =>
                              <>
                                <div className="flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] ">
                                  <div className="flex items-center w-full ">
                                    <div className="space-y-2">
                                      <p className="block text-base text-[#fff] w-full uppercase">{item.name}</p>
                                      <h1 className="text-3xl font-medium w-full text-[#fff]">
                                        {item.value}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className='text-white'>
                                    {item.icon}
                                  </div>

                                </div>
                              </>
                            )
                            : null}

                        </div>


                        {isAssessment && getAssessmentByTopBottomData && getAssessmentByTopBottomData.length > 0 ?
                          <div className="mt-4 capitalize bg-white border rounded-md">
                            <div className="px-4">
                              <div className="py-4 space-y-4 ">

                                <div className="gap-4 pb-2">


                                  <div className='flex flex-wrap gap-2 itmes-center '>

                                    <div className='flex items-center space-x-2'>
                                      <div className="flex items-center px-2 text-sm md:text-xl">
                                        {"TalentPlus Themes"} </div></div>



                                  </div>

                                  <div className="flex mt-4 capitalize bg-white border rounded-md">

                                    <div className="w-[50%] px-4 border-r">
                                      <div className="py-4 space-y-4 ">
                                        <h1 className='text-2xl font-medium text-gray-500 text-green-500'>Dominant Talents </h1>
                                        {getAssessmentByTopBottomData && getAssessmentByTopBottomData.length > 0 ?
                                          getAssessmentByTopBottomData.map((talent, index) =>
                                            (index < 5) ?
                                              <div className='pl-2 text-gray-600'>{talent && talent.dimension ? (index + 1).toString() + ". " + talent.dimension : ""}</div>
                                              : null) : null}
                                      </div>
                                    </div>

                                    <div className="w-[50%] px-4">
                                      <div className="py-4 space-y-4 ">
                                        <h1 className='text-2xl font-medium text-gray-500 text-red-500'>Non Dominant Talents </h1>
                                        {getAssessmentByTopBottomData && getAssessmentByTopBottomData.length > 0 ?
                                          getAssessmentByTopBottomData.slice(0).reverse().map((talent, index) =>
                                            (index < 5) ?
                                              <div className='pl-2 text-gray-600'>{talent && talent.dimension ? (index + 1).toString() + ". " + talent.dimension : ""}</div>
                                              : null) : null}
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                          : null}

                        {UserRoleName === "ADMIN" ?
                          <>

                            <div className="mt-4 capitalize bg-white border rounded-md">
                              <div className="px-4">
                                <div className="py-4 space-y-4 ">
                                  <div className="flex flex-wrap items-center justify-between gap-4 pb-2">
                                    <div className='flex flex-wrap gap-2 itmes-center '>
                                      <div className='flex items-center space-x-2'>
                                        <div className="flex items-center px-2 text-sm md:text-xl">{"Response Status Analytics"} </div></div>
                                    </div>
                                    <div className='flex flex-wrap gap-2 itmes-center '>

                                      {isAssessment ?
                                        <>

                                          <div className='mx-2'>
                                            {AssessmentCodeList && AssessmentCodeList.length > 0 ?
                                              <CSVLink data={AssessmentCodeList} filename={"AssessmentCodeList.csv"}>
                                                <span className="bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                                                  <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:text-[#2196f3] transition-all duration-150 mr-2 text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"></path> <path d="M14 3v4a1 1 0 0 0 1 1h4"></path> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path> <path d="M10 12l4 4m0 -4l-4 4"></path>
                                                  </svg>Download Assessment Info</span>
                                              </CSVLink>
                                              : '...'}
                                          </div>
                                        </>
                                        : null}


                                      <div className=''>
                                        <button onClick={() => this.RefreshAPI()} className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-refresh" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path>
                                            <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path>
                                          </svg>
                                          <span>{"Refresh"}</span>
                                        </button>
                                      </div>
                                      <div className='mx-2'>
                                        <button onClick={() => this.handleExportModal(!this.state.showExportModal)} className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                          > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <path d="M10 12l4 4m0 -4l-4 4" />
                                          </svg>
                                          <span>{"Download Excel"}</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>


                                  <div className='w-full overflow-x-auto md:whitespace-normal whitespace-nowrap'>
                                    <table className="w-full border-collapse">
                                      <thead>
                                        <tr className="text-sm">
                                          <th className="p-3 font-semibold text-gray-800 border border-gray-300 bg-gray-50">S/N</th>

                                          {selectFilterType === 1 && false ?
                                            <th className="p-3 font-semibold text-gray-800 border border-gray-300 bg-gray-50">{LanguageObj && LanguageObj["email_address_label_" + CurrentLanguage] ? LanguageObj["email_address_label_" + CurrentLanguage] : "Email Address"}</th>
                                            :
                                            <th className="p-1 font-semibold text-gray-800 border border-gray-300 bg-gray-50">
                                              <span className='flex items-center justify-between'>
                                                <input className="w-full p-2 bg-white focus:outline-none" placeholder={LanguageObj && LanguageObj["search_by_email_text_" + CurrentLanguage] ? LanguageObj["search_by_email_text_" + CurrentLanguage] : "Search email..."} value={surveyReportEmail} onChange={(e) => this.handleEmailInput(e.target.value)} />
                                                <span onClick={() => this.handleEmailSearchInput()} className="mx-1 mt-1 cursor-pointer material-symbols-outlined">search</span>
                                              </span>
                                            </th>}

                                          {selectFilterType === 1 ?
                                            <th className="p-3 font-semibold text-gray-800 border border-gray-300 bg-gray-50">{LanguageObj && LanguageObj["delivered_" + CurrentLanguage] ? LanguageObj["delivered_" + CurrentLanguage] : "Delivered"}</th>
                                            :
                                            <th className="p-1 font-semibold text-gray-800 border border-gray-300 bg-gray-50">
                                              <div onClick={() => this.HandleFilterType(2)} className="block mx-1 bg-white border xl:block lg:block md:block">
                                                <select onChange={(event) => this.handleIsMailSent(event.target.value)} className="w-full py-1 pl-2 text-xs font-normal outline-none text-slate-800">
                                                  {isMailList && isMailList.length > 0 ?
                                                    isMailList.map((item) =>
                                                      <option value={item.id} className="text-xs">{item.type}</option>
                                                    )
                                                    : null}
                                                </select>
                                              </div>
                                            </th>}


                                          {selectFilterType === 1 ?
                                            <th className="p-3 font-semibold text-gray-800 border border-gray-300 bg-gray-50">{LanguageObj && LanguageObj["clicked_" + CurrentLanguage] ? LanguageObj["clicked_" + CurrentLanguage] : "Clicked"}</th>
                                            :
                                            <th className="p-1 font-semibold text-gray-800 border border-gray-300 bg-gray-50">
                                              <div onClick={() => this.HandleFilterType(3)} className="block mx-1 bg-white border xl:block lg:block md:block">
                                                <select onChange={(event) => this.handleIsClicked(event.target.value)} className="w-full py-1 pl-2 text-xs font-normal outline-none text-slate-800">
                                                  {isClickedList && isClickedList.length > 0 ?
                                                    isClickedList.map((item) =>
                                                      <option value={item.id} className="text-xs">{item.type}</option>
                                                    )
                                                    : null}
                                                </select>
                                              </div>
                                            </th>}

                                          {selectFilterType === 1 ?
                                            <th className="p-3 font-semibold text-gray-800 border border-gray-300 bg-gray-50">{LanguageObj && LanguageObj["completed_" + CurrentLanguage] ? LanguageObj["completed_" + CurrentLanguage] : "Completed"}</th>
                                            :
                                            <th className="p-1 font-semibold text-gray-800 border border-gray-300 bg-gray-50">
                                              <div onClick={() => this.HandleFilterType(4)} className="block mx-1 bg-white border xl:block lg:block md:block">
                                                <select onChange={(event) => this.handleIsComplete(event.target.value)} className="w-full py-1 pl-2 text-xs font-normal outline-none text-slate-800">
                                                  {isCompleteList && isCompleteList.length > 0 ?
                                                    isCompleteList.map((item) =>
                                                      <option value={item.id} className="text-xs">{item.type}</option>
                                                    )
                                                    : null}
                                                </select>
                                              </div>
                                            </th>}

                                          {!isAssessment ?
                                            <th className="p-3 font-semibold text-gray-800 border border-gray-300 bg-gray-50">{LanguageObj && LanguageObj["mail_label_" + CurrentLanguage] ? LanguageObj["mail_label_" + CurrentLanguage] : "Send Mail"}</th>
                                            : null}

                                        </tr>
                                      </thead>
                                      <tbody>

                                        {EmployeeList && EmployeeList.length > 0 ?
                                          EmployeeList.map((tempdata, index) =>
                                            <>
                                              <EmployeeTableRows campaignEmpPageNo={(this.state.campaignEmpPageNo - 1) * this.state.campaignEmpSize} LanguageObj={LanguageObj} CurrentLanguage={CurrentLanguage} tempdata={tempdata} index={index} forceMail={this.forceMail} resendCampain={this.resendCampain} />

                                            </>
                                          )
                                          :
                                          <tr className="my-10 text-sm text-center text-gray-800 bg-white border lg:mb-0">
                                            <td colSpan="6" className='py-3'>Data not found</td>
                                          </tr>
                                        }
                                      </tbody>
                                    </table>

                                  </div>

                                  {!surveyReportEmail ?
                                    <>

                                      <div className="flex items-center justify-center w-full h-16 bg-white ">
                                        <div class="flex flex-wrap border divide-x rounded-md ">
                                          <button

                                            onClick={() => this.handlePaginaton("prev")}
                                            class="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                                            Prev
                                          </button>

                                          {getPageValue(3, this.state.campaignEmpPageNo, this.state.campaignEmpSize, getResponseAnalyticsPaginationTotal) ? (
                                            <>
                                              <button class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] flex">
                                                {getPageValue(1, this.state.campaignEmpPageNo, this.state.campaignEmpSize, getResponseAnalyticsPaginationTotal)}
                                                {" - "}
                                                {getPageValue(2, this.state.campaignEmpPageNo, this.state.campaignEmpSize, getResponseAnalyticsPaginationTotal)}
                                                <p className='text-[#3D405B]/50 pl-2'>{" of "}{getPageValue(3, this.state.campaignEmpPageNo, this.state.campaignEmpSize, getResponseAnalyticsPaginationTotal)}</p>
                                              </button>

                                            </>
                                          ) : (
                                            <button class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] flex">
                                              <p className='text-[#3D405B]/50 pl-2'>{0}</p>
                                            </button>
                                          )}

                                          <button
                                            onClick={() => this.handlePaginaton("next", getPageValue(3, this.state.campaignEmpPageNo, this.state.campaignEmpSize, getResponseAnalyticsPaginationTotal))}
                                            class="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                                            Next
                                          </button>
                                        </div>

                                      </div>


                                    </>

                                    : null}


                                </div>





                              </div>

                            </div>
                          </>
                          : null}



                      </div>
                      : null}


                    {/* 
                  {responseType === 2 ?
                    <div className="">
                      <SurveyReport2
                        CurrentSurveyId={surveyId}
                        closeResponseAnalytics={this.closeResponseAnalytics}

                        EmpFilterData={EmpFilterData}
                        getIndexFilterData={getIndexFilterData}
                        getRespondantDetailsData={getRespondantDetailsData}

                        styleType={2}
                      />
                    </div>
                  :null} */}

                    {responseType === 3 ?
                      <>
                        <SurveyReport2
                          CurrentSurveyId={surveyId}
                          // closeResponseAnalytics={this.closeResponseAnalytics}

                          EmpFilterData={EmpFilterData}
                          getIndexFilterData={getIndexFilterData}
                          getRespondantDetailsData={getRespondantDetailsData}

                          styleType={2}
                        />
                      </>
                      : null}


                    {responseType === 4 ?
                      <div className="">

                        {UserRoleName === "ADMIN" ?
                          <>

                            <div className="mt-4 bg-white border rounded-md">
                              <div className="px-4">
                                <div className="py-4 space-y-4 ">
                                  <div className="flex flex-wrap items-center justify-between gap-4 pb-2 capitalize">
                                    <div className='flex justify-between w-full space-x-2 itmes-center md:w-auto md:justify-start md:space-x-4 '>
                                      <div className='flex items-center space-x-2'>
                                        <div className="flex items-center px-2 text-sm md:text-xl">{"Response Status Analytics"} </div></div>
                                    </div>
                                    <div className='flex justify-between w-full space-x-2 itmes-center md:w-auto md:justify-start md:space-x-4 '>


                                    </div>
                                  </div>

                                  <div className="bg-[#F9FAFB] p-6 border w-full space-y-4 rounded">
                                    <h1>Copy Response Link:</h1>
                                    <div className="border bg-white w-full flex justify-between rounded text-[#3D405B] items-center ">
                                      <p className="px-4 truncate ">
                                        {getSurveyData && getSurveyData.code ? CONST.SURVEY_RESPONSE + getSurveyData.code : ""}
                                      </p>
                                      <div onClick={() => {
                                        this.handleCopyClick();
                                        navigator.clipboard.writeText(getSurveyData && getSurveyData.code ? CONST.SURVEY_RESPONSE + getSurveyData.code : "")
                                      }} className="p-2 px-4 border-l hover:text-[#4CAF50]    cursor-pointer hover:bg-[#4CAF50]/10 transition-all duration-100 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">  {" "}  <path stroke="none" d="M0 0h24v24H0z" fill="none" />{" "}  <rect x="8" y="8" width="12" height="12" rx="2" />{" "}  <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" /></svg>

                                      </div>
                                    </div>
                                  </div>



                                </div>





                              </div>

                            </div>
                          </>
                          : null}



                      </div>
                      : null}



                  </div> : null}
              </main>
            </div>

          </div>
          {openModalComfirmSend ?

            <ConfirmMail handleConfirmMailClose={this.handleConfirmMailClose} handleSendSingleMail={this.handleSendSingleMail} />

            : null}

          {this.state.showExportModal ?
            <>
              <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }} className="fixed top-0 bottom-0 left-0 right-0 z-40 w-screen h-screen " >
                <div className="absolute left-0 right-0 max-w-xl p-4 mx-auto mt-24 overflow-hidden md:relative">

                  <div className="block w-full pb-6 overflow-hidden bg-white rounded-lg shadow">
                    <div className="flex items-center justify-between p-4 border-b">

                      <h2 className="text-lg font-semibold text-center text-gray-800 ">Download Excel </h2>
                      <span onClick={() => this.handleExportModal(false)} className="material-symbols-outlined bg-gray-100 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150">close</span> </div>

                    {Isadmin ?
                      <div className="flex flex-wrap items-center w-full gap-4 p-2 border-b bg-gray-50 md:w-auto">

                        {/* <div onClick={() => this.loadResponse()} 
                                            className="captalize rounded-md cursor-pointer bg-blue-500   hover:bg-blue-600 py-1.5 px-4 text-white md:w-auto w-full text-center">
                                              {resAnalyticsLoading ? "please wait..." : "Load Response"}</div> */}





                        <div className='flex items-center ml-2'>
                          <div onClick={() => this.loadResponsePrev("prev")}
                            className="bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2 material-symbols-outlined" style={{ fontSize: '1.3rem' }}>
                            arrow_back
                          </div>
                          <div className="p-1 mx-2 font-bold text-gray-700 cursor-default ">
                            {this.state.resAnalyticPageNo}
                          </div>
                          <div onClick={() => this.loadResponseNext("next")}
                            className="bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2 material-symbols-outlined" style={{ fontSize: '1.3rem' }}>
                            arrow_forward
                          </div>

                        </div>

                        <div className='mx-2'>
                          <button onClick={() => this.loadResponse()} className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                            > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <path d="M10 12l4 4m0 -4l-4 4" />
                            </svg>
                            <span>{resAnalyticsLoading ? "please wait..." : "Load Response"}</span>
                          </button>
                        </div>



                        {accessAllow("downloadResponse") ?
                          <>
                            {/* {responseAnalysisBySurveyIdData && responseAnalysisBySurveyIdData.length > 0 ?
                                                  <CSVLink data={responseAnalysisBySurveyIdData} filename={fileName}>
                                                    <div title='Export Response' className="rounded-md cursor-pointer bg-blue-100   py-1.5 px-2 text-blue-500 flex items-center space-x-2">
                                                      <span className='material-symbols-outlined'>download</span>
                                                      <div className='hidden lg:flex ' >
                                                        {LanguageObj && LanguageObj["export_csv_label_" + CurrentLanguage] ? LanguageObj["export_csv_label_" + CurrentLanguage] : "Export CSV"}</div>
                                                    </div>
                                                  </CSVLink>
                                                  : '...'} */}

                            <div className='mx-2'>
                              {responseAnalysisBySurveyIdData && responseAnalysisBySurveyIdData.length > 0 ?
                                <CSVLink data={responseAnalysisBySurveyIdData} filename={fileName}>
                                  <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                    > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <path d="M10 12l4 4m0 -4l-4 4" />
                                    </svg>
                                    <span>{LanguageObj && LanguageObj["export_csv_label_" + CurrentLanguage] ? LanguageObj["export_csv_label_" + CurrentLanguage] : "Export CSV"}</span>
                                  </button>
                                </CSVLink>
                                : '...'}
                            </div>


                          </>
                          :
                          <div className="p-1 px-2 mx-2 text-white bg-blue-300 cursor-pointer cursor-not-allowed">
                            {LanguageObj && LanguageObj["export_csv_label_" + CurrentLanguage] ? LanguageObj["export_csv_label_" + CurrentLanguage] : "Export Response	"}</div>
                        }

                      </div>
                      : null}

                    {/* {NewEmployeeList && NewEmployeeList.length > 0 ?
                        <CSVLink data={NewEmployeeList} filename={"CampaignResponseAnalytics.csv"}>
                          <div className="p-1 px-2 mx-2 text-white bg-blue-400 cursor-pointer hover:bg-blue-500">{LanguageObj && LanguageObj["export_csv_label_" + CurrentLanguage] ? LanguageObj["export_csv_label_" + CurrentLanguage] : "Export Analytics	"}</div>
                        </CSVLink>
                        : '...'} */}

                    {/* <div className="flex flex-wrap justify-center w-full gap-4 p-2 bg-white md:w-auto md:justify-start">

                      <div onClick={() => this.loadAnalytics()} className="md:w-auto w-full text-center rounded-md cursor-pointer bg-blue-500 hover:bg-blue-600  py-1.5 px-4 text-white">{"Load Analytics"}</div>

                      {ListAllResponseAnalyticsV1 && ListAllResponseAnalyticsV1.length > 0 ?
                        <CSVLink data={ListAllResponseAnalyticsV1} filename={AllAnalyticsLoading ? "please wait..." : "SurveyResponseAnalytics.csv"}>
                          <div title='Export  Analytics' className="md:w-auto w-full rounded-md cursor-pointer bg-blue-100  py-1.5 px-2 text-blue-500 flex items-center"><span className='mr-2 material-symbols-outlined'>download</span> {LanguageObj && LanguageObj["export_csv_label_" + CurrentLanguage] ? LanguageObj["export_csv_label_" + CurrentLanguage] : "Export CSV	"}</div>
                        </CSVLink>
                        : '...'}
                      </div> */}

                    <div className="flex flex-wrap justify-center w-full gap-4 p-2 bg-white md:w-auto md:justify-start">
                      <div className='mx-2'>
                        <button onClick={() => this.loadAnalytics()} className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                          <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                          > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <path d="M10 12l4 4m0 -4l-4 4" />
                          </svg>
                          <span>{"Load Analytics"}</span>
                        </button>
                      </div>

                      <div className='mx-2'>
                        {ListAllResponseAnalyticsV1 && ListAllResponseAnalyticsV1.length > 0 ?
                          <CSVLink data={ListAllResponseAnalyticsV1} filename={AllAnalyticsLoading ? "please wait..." : "SurveyResponseAnalytics.csv"}>
                            <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                              <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                              > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <path d="M10 12l4 4m0 -4l-4 4" />
                              </svg>
                              <span>{"Export  Analytics"}</span>
                            </button>
                          </CSVLink>
                          : '...'}
                      </div>

                    </div>

                    <div className="flex flex-wrap justify-center w-full gap-4 p-2 bg-white md:w-auto md:justify-start">
                      <div className='mx-2'>
                        <button onClick={() => this.loadIceAnalytics()} className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                          <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                          > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <path d="M10 12l4 4m0 -4l-4 4" />
                          </svg>
                          <span>{"Load ICE Response"}</span>
                        </button>
                      </div>

                      <div className='mx-2'>
                        {loadICEList && loadICEList.length > 0 ?
                          <CSVLink data={loadICEList} filename={iceloading ? "please wait..." : "SurveyResponseICEResponse.csv"}>
                            <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                              <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                              > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <path d="M10 12l4 4m0 -4l-4 4" />
                              </svg>
                              <span>{"Export Reponse"}</span>
                            </button>
                          </CSVLink>
                          : '...'}
                      </div>

                    </div>



                  </div>
                </div>
              </div>
            </>
            : null}


        </LoadingOverlay>

      </>
    );
  }
}


function EmployeeTableRows(props) {

  let { tempdata, index, forceMail, LanguageObj, CurrentLanguage, campaignEmpPageNo, isAssessment } = props;
  return (
    <tr className="mb-10 text-xs text-center text-gray-800 bg-white lg:mb-0">
      <td className="p-3 border border-b ">
        {campaignEmpPageNo + index + 1}
      </td>
      <td className="p-3 border border-b">
        {tempdata.employeeId && tempdata.employeeId.email ? tempdata.employeeId.email : '-'}
      </td>
      <td className="p-3 border border-b">
        {tempdata.isMailSent ?
          // <div className="p-1 bg-gray-200 border rounded cursor-default">{LanguageObj && LanguageObj["yes_"+CurrentLanguage]? LanguageObj["yes_"+CurrentLanguage]:"Yes"}</div>
          <span className="px-3 py-1 text-blue-500">{LanguageObj && LanguageObj["yes_" + CurrentLanguage] ? LanguageObj["yes_" + CurrentLanguage] : "Yes"}</span>
          :
          // onClick={()=>resendCampain(tempdata.id)} 
          <span className="px-3 py-1 text-red-500">{LanguageObj && LanguageObj["no_" + CurrentLanguage] ? LanguageObj["no_" + CurrentLanguage] : "No"}</span>
        }
      </td>

      <td className="p-3 border border-b">
        <span className="px-3 py-1">{tempdata.isClicked ? moment.unix(tempdata.clickedDate / 1000).format("DD/MM/YYYY, HH:mm") : 'No'}</span>
      </td>
      <td className="p-3 border border-b">
        <span className="px-3 py-1 ">{tempdata.isComplete ? moment.unix(tempdata.completeDate / 1000).format("DD/MM/YYYY, HH:mm") : 'No'}</span>
      </td>

      {!isAssessment ?
        <td className="p-3 border border-b">
          <span onClick={() => forceMail(tempdata.id)} className="cursor-pointer material-symbols-outlined">mail</span>
        </td>
        : null}



    </tr>


  )
}





function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(SurveyReport);
