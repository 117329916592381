import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class QuestionScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { keyList, DataObj } = this.props;
    





    // 0:
    // children: (3) [{…}, {…}, {…}]
    // collapsed: true
        // name: "analytics"
        // [[Prototype]]: Object
        // 1: {name: 'animate', children: Array(12)}
        // 2: {name: 'data', children: Array(7), collapsed: true}
        // 3: {name: 'display', children: Array(4)}
        // 4: {name: 'flex', children: Array(1), collapsed: true}
        // 5: {name: 'physics', children: Array(8)}
        // 6: {name: 'query', children: Array(29), collapsed: true}
        // 7: {name: 'scale', children: Array(10)}
        // 8: {name: 'util', children: Array(19), collapsed: true}
        // 9: {name: 'vis', children: Array(7)}
        // length: 10
        // [[Prototype]]: Array(0)
    // name: "flare"

    ////console.log('keyList:---> ',keyList)
    ////console.log('DataObj:---> ',DataObj)


    let data = {
      "name":"Words",
      "children": []
    }


    if(keyList && keyList.length>0){
      keyList.forEach((key)=>{


        




        let temp1 = {
          children: [],
          collapsed: true,
          name: key
        }

        if(DataObj && DataObj[key] && DataObj[key].length>0){
          DataObj[key].forEach((item)=>{
            temp1["children"].push({
              "name":item[0],
              "collapsed": true,
              "value":item[1]
            })
          });
        }

        data["children"].push(temp1)

      })
    }




    ////console.log('data new:---> ',data)


    



    return (
      <>
        <div style={{zIndex:'10'}}>

    {true?
              <ReactEcharts
              style={{height:'700px'}}
              option={{
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  }
                },
                legend: {},
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: [
                  {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                  }
                ],
                yAxis: [
                  {
                    type: 'value'
                  }
                ],
                series: [
                  {
                    name: 'Direct',
                    type: 'bar',
                    stack: 'Ad',
                    emphasis: {
                      focus: 'series'
                    },
                    data: [320, 332, 301, 330, 320]
                  },
                  {
                    name: 'Email',
                    type: 'bar',
                    stack: 'Ad',
                    emphasis: {
                      focus: 'series'
                    },
                    data: [120, 132, 101, 134, 90, 230, 210]
                  },
                  {
                    name: 'Union Ads',
                    type: 'bar',
                    stack: 'Ad',
                    emphasis: {
                      focus: 'series'
                    },
                    data: [220, 182, 191, 234, 290, 330, 310]
                  },
                  {
                    name: 'Video Ads',
                    type: 'bar',
                    stack: 'Ad',
                    emphasis: {
                      focus: 'series'
                    },
                    data: [150, 232, 201, 154, 190, 330, 410]
                  },
                  {
                    name: 'Search Engine',
                    type: 'bar',
                    stack: 'Ad',

                    data: [862, 1018, 964, 1026, 1679, 1600, 1570],
                    emphasis: {
                      focus: 'series'
                    },
                    markLine: {
                      lineStyle: {
                        type: 'dashed'
                      },
                      data: [[{ type: 'min' }, { type: 'max' }]]
                    }
                  },
                ]
              }}
            />
            : <div className="text-purple-500 font-semibold text-left p-2 px-4">NaN</div>}

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
//   //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionScore);
