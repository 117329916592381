import React from "react";

export default function Item(props) {
  let { getSurveyData, IndexEngagementDimensionList, getEngagementQuestionData, IndexManagerDimensionList, getManagerQuestionData, getIndexData  } = props;

  let questionEngList = [];
  let questionMngrList = [];
  if(getIndexData && getIndexData["2"] && getIndexData && getIndexData["5"]){

    if(IndexEngagementDimensionList && IndexEngagementDimensionList.length>0){
      IndexEngagementDimensionList.forEach((para)=>{
        if(getEngagementQuestionData && getEngagementQuestionData.length>0){
          getEngagementQuestionData.forEach((ques)=>{
            if(para === ques.parameterDimensionName){
              questionEngList.push(ques)
            }
          })
        }
        

      })
    }


    if(IndexManagerDimensionList && IndexManagerDimensionList.length>0){
      IndexManagerDimensionList.forEach((para)=>{
        if(getManagerQuestionData && getManagerQuestionData.length>0){
          getManagerQuestionData.forEach((ques)=>{
            if(para === ques.parameterDimensionName){
              questionMngrList.push(ques)
            }
          })
        }
        

      })
    }



  }
  
  return (
    <>
      <section className=" space-y-2 gap-5 px-10 pt-12">



    <header className="bg-white ">
    <div className="flex justify-between capitalize w-12/12 mx-auto py-4 pt-3 space-y-1">
      
      
      <h1 className="text-xl text-white font-bold">
      {getSurveyData && getSurveyData.logo?
        <img alt="item" src={getSurveyData && getSurveyData.logo?getSurveyData.logo:""}  style={{height:'90px'}}/>
        :null}
      </h1>

      <h1 className="text-xl text-white font-bold">
        <img alt="item" src={"img/logo/happypluslogo.png"}  style={{height:'60px'}}/>
      </h1>

      
    </div>
  </header>


  <main className="text-left mx-auto w-full pb-8 pt-2">
    <h1 className="text-left text-3xl text-gray-700 font-normal pb-2 ">Overview of Methodology</h1>
    <div className="flex space-x-6">




      <div className="w-10/12">
        <h1 className="text-xl text-indigo-700 py-2">What is Employee Engagement</h1>
        <p className="text-left text-gray-500 text-sm">Engagement is the state of emotional and intellectual commitment to an
organization or group. Simply put, it is the extent to which an organization 'wins'
the 'hearts and minds' of its employees
        </p>
        <table className="text-left block py-4">
          <tbody><tr>
              <td className="border p-2 w-1/6 text-center font-bold">Pride</td>
              <td className="border p-2 text-gray-500 text-xs leading-4"> {"Take Pride, Talk Positively & Recommend the Organization. Employees who feel a strong sense of pride in their work are more ambitious, energetic, focused and motivated"}</td>
            </tr> 
            <tr>
              <td className="border p-2 w-1/6 text-center font-bold">Presence</td>
              <td className="border p-2 text-gray-500 text-xs leading-4"> {"The intent of an employee to have an intense desire to be a member of the organization mentally and physically. Having continuous positive push to associate with organization mission."}</td>

            </tr>
            <tr>
              <td className="border p-2 w-1/6 text-center font-bold">Performance</td>
              <td className="border p-2 text-gray-500 text-xs leading-4">{"Hi-Performance & Strive to go beyond the goals to make Organization successful. Having an aligned purpose with business"}</td>
            </tr>
          </tbody></table>
        <ul className="list-disc text-gray-500 px-4 space-y-2 text-sm">
          <li>Close ended questions are asked on six-point scale ranging from Strongly
Disagree (1) to Strongly Agree (6)</li>
          <li>Percentage scores indicate the extent of satisfaction (Agree & Strongly Agree)
on the indicated aspect</li>
        </ul>
      </div>




      {/* right */}
      <div className="space-y-1">
        <div className="flex items-start space-x-8">
          {/* <img src="img/managerreport/png1.jpg" style={{width:"380px"}} className="w-3/6 shadow-md" /> */}


          {questionEngList && questionEngList.length>0?
          <div className="w- shadow-md p-2" >
          <table className=" border" style={{width:"370px"}}>
            <thead>
              <tr >
                  <th style={{fontSize:10}} className="border-r border-b px-2 text-xs bg-gray-100">Dimension</th>
                  <th style={{fontSize:10}} className="border-r border-b px-2 text-xs bg-gray-100">Question</th>
              </tr>
            </thead>
            <tbody>
              {questionEngList && questionEngList.length>0?
              questionEngList.map((item)=>
                <>
                <tr>
                    <td style={{fontSize:10}} className="border-r border-b px-2 text-xs">{item.parameterDimensionName}</td>
                    <td style={{fontSize:10}} className="border-r border-b px-2 text-xs">{item.name}</td>
                </tr>
              </>
              )
              :null}
              
            </tbody>
          </table>
          </div> :
          <>
           <img src="img/managerreport/png1.jpg" style={{width:"380px"}} className="w-3/6 shadow-md" />
          </>
          }


          
          <ul className="list-disc text-gray-500 space-y-2 text-sm">
            <li>Engagement behaviors contains the
Satisfaction Scores (i.e. Agree and Strong Agree
on the rating scale) on the six engagement
behaviors</li>
            <li>Engagement Score is calculated basis direct/
indirect employee responses on these six
employee behaviors</li>
            <li>Team Engagement Score represents the
percentage of direct/indirect team members
scoring an average of 4.5 or above on these six</li>
          </ul>
        </div>
        <div className="flex items-start space-x-8">
          {/* <img src="img/managerreport/png2.jpg" style={{width:"380px"}} className="w-3/6 shadow-md" /> */}

          
        {questionMngrList && questionMngrList.length>0?
          <div className="w- shadow-md p-2" >
          <table className=" border" style={{width:"370px"}}>
            <thead>
              <tr >
                  <th style={{fontSize:10}} className="border-r border-b px-2 text-xs bg-gray-100">Dimension</th>
                  <th style={{fontSize:10}} className="border-r border-b px-2 text-xs bg-gray-100">Question</th>
              </tr>
            </thead>
            <tbody>
              {questionMngrList && questionMngrList.length>0?
              questionMngrList.map((item)=>
                <>
                <tr>
                    <td style={{fontSize:10}} className="border-r border-b px-2 text-xs">{item.parameterDimensionName}</td>
                    <td style={{fontSize:10}} className="border-r border-b px-2 text-xs">{item.name}</td>
                </tr>
              </>
              )
              :null}
              
            </tbody>
          </table>
          </div>
          :
          <>
          <img src="img/managerreport/png2.jpg" style={{width:"380px"}} className="w-3/6 shadow-md" />  
          </>
          }



          <ul className="list-disc text-gray-500 space-y-2 text-sm">
            <li>Manager Index indicates your team's
satisfaction (Agree & Strongly Agree) on the
aspects that are critical for you to drive as a
People Manager to build an engaged team</li>
            <li>For driving high engagement within your team
you need to focus on impacting your manager</li>
          </ul>
        </div>
      </div>
    </div>
  </main>
</section>

    </>
  );
}







