import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class WordChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedText: ''
    }
  }



  componentDidMount() {
  }


  handleKeyword = (data) => {
    let { handleKeywordCall } = this.props;
    handleKeywordCall(data.text)
    this.setState({ SelectedText: data.text + ' (' + data.value + ')' });

  }

  handleKeywordTooltip = (data) => {
    this.setState({ SelectedText: data.text + ' (' + data.value + ')' });
  }


  render() {

    let { clusterSecondFilter, getClusterEmpInfoListData, loading } = this.props;
    let { SelectedText } = this.state;

    let level = clusterSecondFilter && clusterSecondFilter[0] && clusterSecondFilter[0].level ? clusterSecondFilter[0].level : "Department";
    console.log("clusterSecondFilter", clusterSecondFilter)
    console.log("level", level)

    let DriverName = [];
    let DriverValues = [];

    let DemographicList = [];
    if (getClusterEmpInfoListData && getClusterEmpInfoListData.length > 0) {
      getClusterEmpInfoListData.forEach((item) => {
        console.log("item[level]", item[level])

        let getIndex = DemographicList.findIndex(prev => prev.name === item[level]);
        if (getIndex === -1) {
          let temp = {
            "name": item[level],
            "count": 1
          }

          DemographicList.push(temp);
        }
        else {
          DemographicList[getIndex]["count"] += 1
        }



      });
    }


    if (DemographicList && DemographicList.length > 0) {
      DemographicList.forEach((item) => {
        DriverName.push(item["name"]);
        DriverValues.push(item["count"]);
      });
    }


    console.log("DemographicList---------->", DemographicList)
    console.log("DemographicList---------->", DemographicList)


    let XAxis = DriverName;
    let List = DriverValues;


    return (

      <div className=" w-full"  > 
        <div className='' >
          {XAxis && XAxis.length > 0 ?
            <> 
              <ReactEcharts
                className='w-full'
                option={{
                  tooltip: {
                    trigger: 'item',
                    formatter: function (a) {
                      return (
                        '<b> ' + level + ': </b> ' + XAxis[a.dataIndex] + '</br>' +
                        '<b>No of Employees: </b> ' + ((a.value) ? parseInt(a.value) : 0).toString() + '</br>'
                      )
                    }
                  },
                  xAxis: {
                    type: 'category',
                    data: XAxis,
                    axisLabel: {
                      show: true,
                      rotate: 40,
                      interval: 0,
                      formatter: function (a) {
                        // //console.log(a)
                        return a ? a.length > 12 ? a.slice(0, 12) + '..' : a : a
                      }
                    }
                  },
                  yAxis: {
                    type: 'value'
                  },
                  series: [{
                    data: List,
                    type: 'bar',
                    label: {
                      show: true,
                      position: 'top',

                    },
                    showBackground: true,
                    barWidth: '10px',
                    color: '#6536d1'
                  }],
                  grid: {
                    left: '10px',
                    right: '20px',
                    bottom: '0%',
                    top: '20px',
                    containLabel: true
                  },
                }} 
              /> 
              <div className="mt-4 text-center flex justify-center md:flex-row flex-col gap-2 "><p><b>X-Axis:</b> {' Demographics'}</p> <p><b>Y-Axis:</b> {'No of employees'} </p></div>

            </>
            :
            <>
              {!loading ?
                <div className="pt-40 text-xl text-center text-gray-400">Click on cluster to see employee distribution.</div> : null}
            </>
          }
        </div>
        <div className="py-4 text-sm"  >* We are not showing data where employee count is less than 5</div>

      </div>

    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(WordChart);
