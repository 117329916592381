import React from 'react';
// import { connect } from 'react-redux';



export default function LanguageDropdown(props) {

  let { getLanguageData, handleLanguageLabel } = props;

  let [showFilter, handleOpenFilter] = React.useState(false);



  let { languageShortName, languageName } = props;





  return (

    <div className="cursor-pointer bg-white  border pl-2 pr-1 relative py-1.5   rounded-md select-none md:w-auto w-full    ">
      <p className='text-xs text-[#212121]/70 font-medium'>language</p>

      <div className='md:min-w-40 max-w-fit  flex justify-between items-center  space-x-1.5'>
        <p onClick={() => handleOpenFilter(!showFilter)} className="text-sm font-medium  text-[#212121]">{languageName ? languageName : "English"}</p>
        <span onClick={() => handleOpenFilter(!showFilter)} className="material-icons ">arrow_drop_down</span>
      </div>



      {showFilter && getLanguageData && getLanguageData.length > 0 ?
        <div className="z-10 absolute right-0 top-10 bg-white shadow-lg p-3 font-normal text-xs w-fit h-auto max-h-60 overflow-x-auto customscroll2 rounded-md">

          {getLanguageData && getLanguageData.length > 0 ?
            getLanguageData.map((data, index) =>
              <div key={index} className="py-2 m-1 flex items-center cursor-pointer"
                onClick={() => { handleLanguageLabel(data); handleOpenFilter(false) }}
              >
                <input type="checkbox" checked={languageShortName === data.shortName} onChange={() => { }} />
                <label className="ml-2 font-medium">{data.name}</label>
              </div>
            ) : null}
        </div>
        : null}
    </div>


  );
}
