import React, { Component } from 'react';
import { connect } from 'react-redux'; 
import 'react-quill/dist/quill.snow.css';
import { SketchPicker } from 'react-color';

class WelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal:false
    }
  }
  componentDidMount() {
  }



  hanldleModal=(check)=>{
    this.setState({showModal:check})
  }


  render() {
    let {  welcomeFormData, imageWebLinkLogo, imageWebLinkLogo2, handleChangeColorValue,  

      handleColorCode, ColorCodeList, selectColorType
    } = this.props;


    //console.log('welcomeFormData.logo: ', imageWebLinkLogo, welcomeFormData.logo)
    //console.log('welcomeFormData.logo2: ', imageWebLinkLogo2, welcomeFormData.logo2)

 

    const isMobile = window.innerWidth <= 700;


    return ( 
        <main>
          {/* <div className="cursor-pointer item-end" onClick={()=>saveWelcomePage()}>save</div> */}

          <div className="w-full px-4 py-6 mx-auto xl:w-4/6 lg:w-5/6">
            {/*  */}



            {/* IMAGE UPLOAD */}

            {/* <div>
      <label htmlFor="logo">
      <div className="flex justify-center py-4 bg-gray-300 border border-gray-300 cursor-pointer">
        <div className="text-center text-gray-700">
          <img src={(imageWebLinkLogo?imageWebLinkLogo:welcomeFormData.logo)} style={{width:'70px', marginLeft:"20%", marginBottom:"5%"}}/>
          <p className="text-sm text-gray-700" >Click to add Logo</p>

       
        </div>
      </div>

      </label>
      {imageWebLinkLogo || welcomeFormData.logo?
            <p onClick={()=>handleLogoRemove(1)} className="text-sm text-red-700 cursor-pointer" >Remove Logo</p>
       :null}

              <input
                style={{ display: 'none' }}
                id="logo"
                name="logo"
                type="file"
                onChange={handleFileLogo}
              />  

      <div className="my-2">
      </div>
      </div> */}


            <div className="my-10" />


            {/* ------------------------------------------------------------------------------------------- */}
            {/* logo2 */}


            {/* IMAGE UPLOAD */}


            {/* <div>
      <label htmlFor="logo2">
      <div className="flex justify-center py-4 bg-gray-300 border border-gray-300 cursor-pointer">
        <div className="text-center text-gray-700">
          <img src={(imageWebLinkLogo2?imageWebLinkLogo2:welcomeFormData.logo2)} style={{width:'70px', marginLeft:"20%", marginBottom:"5%"}}/>
          <p className="text-sm text-gray-700" >Click to add Secondary Logo</p>

       
        </div>
      </div>

      </label>
      {imageWebLinkLogo2 || welcomeFormData.logo2?
            <p onClick={()=>handleLogoRemove(2)} className="text-sm text-red-700 cursor-pointer" >Remove Secondary Logo</p>
       :null}

              <input
                style={{ display: 'none' }}
                id="logo2"
                name="logo2"
                type="file"
                onChange={handleFileLogo2}
              />  

      <div className="my-2">
      </div>
      </div>

      <div className="py-1 text-red-500 ">{logoUploadError}</div> */}






 

            <div className="pb-4 mt-4 font-semibold text-slate-800">Survey Visual Design</div>
            <div className="relative p-4 bg-white border md:p-6">

              <div className="flex md:justify-around">

                <div className="">
                  {ColorCodeList && ColorCodeList.length > 0 ?
                    ColorCodeList.map((color, index) =>
                      <div className="flex">
                        <div onClick={() => handleColorCode(color)}
                          className={color.value === selectColorType ? "cursor-pointer bg-gray-700 border text-white p-2 m-2 md:w-60 w-5/6" : "cursor-pointer md:w-60 w-5/6 border bg-white p-2 m-2"} keys={index}

                        >
                          {color.name}
                        </div>

                        <div style={{ background: welcomeFormData[color.value], width: '40px', height: '40px' }} className="p-2 m-2 border rounded-full"></div>
                      </div>
                    )
                    : null}


                </div>





                {this.state.showModal || (!isMobile)?
                <div className="absolute top-0 right-0 flex items-center justify-center w-full h-full bg-black md:justify-start md:relative md:bg-transparent bg-opacity-70 md:w-auto md:h-auto">
                  
                <div onClick={()=>this.hanldleModal(!this.state.showModal)}  className="absolute inline-flex items-center justify-center w-10 h-10 text-gray-500 bg-white rounded-full shadow cursor-pointer right-60 top-20 hover:text-gray-800" >
                  <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                  </svg>
                </div>



                  <div>
                    <div className="pb-4 text-sm font-semibold text-white md:text-slate-800">Select Color / Enter Color Code:</div>
                    <SketchPicker
                      color={welcomeFormData[selectColorType]}
                      onChangeComplete={handleChangeColorValue}
                    />
                  </div>
                </div>
                :null}








              </div>


              <div onClick={()=>this.hanldleModal(!this.state.showModal)} className='w-full px-4 py-2 mt-12 text-white bg-blue-500 rounded-md lg:hidden'>
                Pick Color
              </div>







            </div>



            <div className="my-10" />




            {/* IMAGE UPLOAD */}
            {/* <div className="">
              <label htmlFor="image">
            <div className="flex justify-center py-4 bg-gray-300 border border-gray-300 cursor-pointer">
              <div className="text-center text-gray-700">
                <img src={(imageWebLink?imageWebLink:welcomeFormData.bgImage)} style={{width:'500px', marginBottom:"5%"}}/>

                <p className="text-sm text-gray-700" >Click to add Background Image</p>
            
              </div>
            </div>
            </label>
            {imageWebLink || welcomeFormData.bgImage?
            <p onClick={()=>handleImageRemove()} className="text-sm text-red-700 cursor-pointer" >Remove Background Image</p>
            :null}
                    <input
                      style={{ display: 'none' }}
                      id="image"
                      name="image"
                      type="file"
                      onChange={handleFile}
                    />  
      </div>
      <div className="py-1 text-red-500 ">{imageUploadError}</div> */}





 


          </div>
        </main>
    );
  }
}
function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey } = state;
  return {
    loggingIn,
    survey
  };
}
export default connect(mapStateToProps)(WelcomePage);
