import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import BarSet from '../Components/BarSet';


class NPSChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList: [],
      EmpIndexA: 0,
      EmpIndexB: 4
    }
  }
  componentDidMount() {

  }

  render() {
    let { AnswerObj, size, questionName, index } = this.props;

    // function ColorFn(key){
    //   if(templateId===11){
    //     return {color:key==="Yes"?'#00cc66':'#ff5050'}
    //   }
    //   else{
    //     return {}
    //   }
    // }

    // let DataList = [];

    let totalCount = 0;

    let Promoters = 0;
    let Passsives = 0;
    let Distractors = 0;

    if (AnswerObj) {
      // console.log('AnswerObjAnswerObjAnswerObjAnswerObjAnswerObj', AnswerObj);
      Object.keys(AnswerObj).forEach(key => {
        let score = parseInt(AnswerObj[key]);

        totalCount += score;

        if (parseInt(key) >= 9 && parseInt(key) <= 10) {
          Promoters += score;
        }
        if (parseInt(key) >= 7 && parseInt(key) <= 8) {
          Passsives += score;
        }
        if (parseInt(key) >= 1 && parseInt(key) <= 6) {
          Distractors += score;
        }

      });
    }


    let PromotersPer = (Promoters * 100 / totalCount);
    let PasssivesPer = (Passsives * 100 / totalCount);
    let DistractorsPer = (Distractors * 100 / totalCount);

    let npsScore = ((PromotersPer) - (DistractorsPer)).toFixed(2);

    let PromotersColorRange = PromotersPer / 100;
    let PasssivesColorRange = (PasssivesPer / 100) + PromotersColorRange;
    let DistractorsColorRange = (DistractorsPer / 100) + PasssivesColorRange;


    let NPSObj = {
      "PromotersPer": PromotersPer.toFixed(2),
      "PasssivesPer": PasssivesPer.toFixed(2),
      "DistractorsPer": DistractorsPer.toFixed(2)
    }

    console.log('PromotersColorRange: ', PromotersColorRange);
    console.log('PasssivesColorRange: ', PasssivesColorRange);
    console.log('DistractorsColorRange: ', DistractorsColorRange);



    let { stage } = this.props;

    return (
      <>
        <div className='relative flex flex-col justify-between h-full'>
          <div className="flex justify-between pb-2">
            <div className="relative inline-block w-4/6 dropdown">
              <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] line-clamp-2">
                {questionName && questionName.length > 30 ? (index === -1 ? "" : ((index + 1) + ") ")) + questionName.slice(0, 30) + "..." : (index === -1 ? "" : ((index + 1) + ") ")) + questionName}</h1>
              <div className="absolute top-0 z-30 hidden p-4 pt-1 text-sm font-semibold bg-white border rounded-md shadow-md tooltipcard dropdown-menu -left-4">
                {(index === -1 ? "" : ((index + 1) + ") ")) + questionName}
              </div>
            </div>
          </div>


          <ReactEcharts
            style={{ height: size === 2 ? "260px" : "230px" }}
            option={{
              series: [{
                type: 'gauge',
                min: -100,
                max: 100,
                axisLine: {
                  lineStyle: {
                    width: 15,
                    color: [
                      [0.6, '#ff4d4d'],
                      [0.8, '#ff9933'],
                      [1, '#00cc99']
                    ]
                  }
                },
                pointer: {
                  itemStyle: {
                    color: 'auto'
                  }
                },
                axisTick: {
                  distance: -30,
                  length: 8,
                  lineStyle: {
                    color: '#fff',
                    width: 2
                  }
                },
                splitLine: {
                  distance: -30,
                  length: 30,
                  lineStyle: {
                    color: '#fff',
                    width: 4
                  }
                },
                axisLabel: {
                  color: 'auto',
                  distance: 25,
                  fontSize: 10
                },
                detail: {
                  valueAnimation: false,
                  formatter: '{value}',
                  color: '#757575',
                  fontSize: 0

                },
                data: [{
                  value: npsScore
                }]
              }]
            }}

          />
          <div className="text-xl font-bold text-center -mt-14">{npsScore}</div>


          {size === 2 ?
            <div>
              <BarSet NPSObj={NPSObj} />
            </div>
            : null}

          {size !== 2 ?
            <div className="flex items-center justify-between w-5/6 mx-auto mt-4">
              {/* 1 */}
              <div className="flex flex-col items-center justify-center text-xs">
                <span className="mb-2 text-green-500 material-symbols-outlined" style={{ fontSize: '1.6rem' }}>thumb_up</span>
                <p className="text-sm font-medium text-gray-600">Promoters </p>
                <h1 className="text-sm ">({NPSObj["PromotersPer"]}%)</h1></div>
              <span className="text-3xl font-semibold text-gray-500">- </span>
              {/* 1 */}
              <div className="flex flex-col items-center justify-center text-xs"><span className="mb-2 text-red-500 material-symbols-outlined" style={{ fontSize: '1.6rem' }}>thumb_down_off_alt</span> <p className="text-sm font-medium text-gray-600">Detractors </p>
                <h1 className="text-sm">({NPSObj["DistractorsPer"]}%)</h1></div>
              <span className="text-xl font-semibold text-gray-500">= </span>
              <div> <span className="inline-flex items-center justify-center p-2 font-bold text-gray-800 bg-green-400 rounded-full w-14 h-14 ">{npsScore}</span></div>
            </div>
            : null}


          {stage !== 2 ?
            <>
              <div class="flex justify-center p-4 mt-auto">
                <button class=" text-[#2196f3]  cursor-pointer transition duration-150 flex items-center uppercase font-medium xl:text-base text-sm ">{stage == 2 ? "View Details" : (stage == 3 ? "" : "View More")}<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right ml-2" width="34" height="34" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2196f3" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="5" y1="12" x2="19" y2="12"></line><line x1="15" y1="16" x2="19" y2="12"></line><line x1="15" y1="8" x2="19" y2="12"></line></svg>
                </button>
              </div>
            </>
            : null}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(NPSChart);
