import React, { useRef, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
// import { DateRangePicker } from 'rsuite';
// import subDays from 'date-fns/subDays';
import { connect } from "react-redux";

// import addDays from 'date-fns/addDays';
import {
  userActions,
  dashboardActions,
  generalActions,
  questionsActions,
  happinessActions,
  pmsActions,
} from "../../../_actions";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

function AddAppraisalCycle(props) {
  let {
    stagesList,
    AppraisalCycleList,
    openAddAppraisalCycle,
    handleSelect,
    dashboard,
    pms,
    survey,
    getIndexByFilterDMGData,
    CrrPhaseId,
    handleUploadDoc,
    selectedUploadDoc,
  } = props;
  let {
    EmpFilterData,
    PMSGetRaterSetting3,
    PMSGetModuleSetting2,
    PMSAddAppraisalCycle1,
    PMSGetAppraisalCycleById,
    PMSAddModuleSetting2,
    PMSApplicableFor4,
    getPMSModuleNames,
    getPMSRaterNames,
    PMSDMGEmployeeList,
    PMSDMGEmployeeTotal,
    PMSGetProcessDataList,

  } = dashboard;


  console.log("PMSGetProcessDataList--------->", PMSGetProcessDataList);






  let appraisalId =
    PMSAddAppraisalCycle1 && PMSAddAppraisalCycle1.id
      ? PMSAddAppraisalCycle1.id
      : "";

  const initialvalues2 = {};
  const [useHideList, setHidelist] = useState(false);
  const [useRatername, setratername] = useState(initialvalues2);
  const [useraterindex, setraterindex] = useState("");
  const [crrRaterName, handleCrrRaterName] = useState("");
  const [usename, setnamenew] = useState(false);

  const [isShow5Buton, setShow5Buton] = useState(false);

  let initialtask2 = {};
  let [useerror, setFormErrors] = useState(initialtask2);
  let [useerrorV2, setFormErrorsV2] = useState(initialtask2);

  const [crrRatingTypeDrop, setRatingTypeDrop] = useState(false);

  const [crrMonthType, handleCrrMonthType] = useState(3);

  const [crrDropDownMonth, handleCrrDropDownMonth] = useState(false);

  const initialValues1 = {};
  const initialValues = {
    doc: "news.file",
    name: "",
    desc: "",
    period: "",
  };

  let [useFormData, setFormData] = useState(initialValues);
  let [useFormModule, setFormModule] = useState(initialValues1);
  const [startDate, setStartDate] = useState();
  const [useEnddate, SetEndDate] = useState("");
  const [useinitialDate, setInitialDate] = useState("");



  const [dateList, setDateList] = useState({});




  const [useAllerror, setAllError] = useState("");

  const [startDateV2, setStartDateV2] = useState();
  const [useEnddateV2, SetEndDateV2] = useState("");
  const [useinitialDateV2, setInitialDateV2] = useState("");
  const [useAllerrorV2, setAllErrorV2] = useState("");

  function undefinedFixed(value, field) {
    return value && value[field] ? value[field] : [];
  }

  const [dmgFormData, handleDMGFormData] = useState({
    id: appraisalId,
    months: undefinedFixed(PMSGetAppraisalCycleById, "months"),
    dmg: undefinedFixed(PMSGetAppraisalCycleById, "dmg"),
    doj: undefinedFixed(PMSGetAppraisalCycleById, "doj"),
  });

  const handleDMGInput = (level, value) => {
    let getIndex = dmgFormData["dmg"].findIndex((prev) => prev.level === level);
    if (getIndex !== -1) {
      let getIndex2 = dmgFormData["dmg"][getIndex]["valueList"].findIndex(
        (prev) => prev === value
      );
      if (getIndex2 === -1) {
        dmgFormData["dmg"][getIndex]["valueList"].push(value);
      }
    } else {
      dmgFormData["dmg"].push({
        level: level,
        valueList: [value],
      });
    }
    handleDMGFormData({ ...dmgFormData });
  };
  const handleDMGDOJInput = (value, type) => {
    if (type === 1) {
      dmgFormData["doj1"] = value;
    } else if (type === 1) {
      dmgFormData["doj2"] = value;
    }
    handleDMGFormData({ ...dmgFormData });
  };

  const deleteDMGInput = (level, value) => {
    let getIndex = dmgFormData["dmg"].findIndex((prev) => prev.level === level);
    if (getIndex !== -1) {
      let getIndex2 = dmgFormData["dmg"][getIndex]["valueList"].findIndex(
        (prev) => prev === value
      );
      if (getIndex2 !== -1) {
        dmgFormData["dmg"][getIndex]["valueList"].splice(getIndex2, 1);
      }
    }
    handleDMGFormData({ ...dmgFormData });
  };

  const [crrDropDownDmg, handleCrrDropDownDmg] = useState(false);
  const ref = useRef();

  const handleCrrDropDownDmgV2 = (eleName) => {
    if (crrDropDownDmg === eleName) {
      handleCrrDropDownDmg("");
    } else {
      handleCrrDropDownDmg(eleName);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleCrrDropDownDmg("");
    }
  };

  let [useFormModuleSetting, setFormModuleSetting] =
    useState(PMSGetModuleSetting2);

  let [crrIndex, handleIndex] = useState(0);

  // let [useFormModule,setFormModule]=useState(initialValues1);

  const appraisalName = (e) => {
    e.preventDefault();

    let { name, value } = e.target;

    setFormData({
      ...useFormData,
      [name]: value,
    });

    setFormErrors({
      ...useerror,
      [name]: "",
    });
  };

  // console.log(useFormModule)

  const styles = { width: 490 };

  // console.log("useFormModuleSetting---->", useFormModuleSetting)

  // console.log("getPMSModuleNames---->", getPMSModuleNames)

  // console.log("AppraisalCycleList---->", PMSGetAppraisalCycleById)

  // console.log(
  //   "PMSGetRaterSetting3-111111111111111111111111111--->",
  //   PMSGetRaterSetting3
  // );
  //   let finalarraylist = useFormModule && useFormModule && useFormModule.length > 0 ?
  // useFormModule.map((ele) => ({
  //   label: ele.label,
  //   score: + ele.score,

  // })) : []

  const validatePhase1 = () => {
    let isValid = true;

    const errors = {
      name: "",
      desc: "",
      period: "",
      doc: "",
    };

    if (useFormData.name.trim() === "") {
      errors.name = "Name is required";
      isValid = false;
    }
    if (useFormData.desc.trim() === "") {
      errors.desc = "Description is required";
      isValid = false;
    }

    if (useFormData.period.trim() === "") {
      errors.period = "Select period";
      isValid = false;
    }
    if (useFormData.doc.trim() === "") {
      errors.doc = "Select period";
      isValid = false;
    }

    setFormErrors(errors);

    return isValid;
  };

  function calculate(value) {
    let data =
      value && value.length > 0 ? value.reduce((a, b) => a + +b.score, 0) : 0;
    return data;
  }
  const validatePhase2 = (list) => {
    let count =
      list && list.length > 0
        ? list.filter((prev) => prev.moduleActive === true).length
        : [];
    return count > 0 ? true : false;
  };

  const validatePhase3 = (list) => {
    let count =
      list && list.length > 0
        ? list.filter((prev) => prev.moduleAccessList.length).length
        : [];
    // console.log(count,"count");
    return count > 0 ? true : false;
  };

  const validatePhase4 = () => {
    let isValid = true;

    const errors = {
      doj: "",
    };

    if (dmgFormData.doj.trim() === "") {
      errors.doj = "Select period";
      isValid = false;
    }

    setFormErrorsV2(errors);

    return isValid;
  };

  const newhandle = (value1, isReady) => {
    let { openAddAppraisalCycle } = props;

    if (CrrPhaseId === 1) {
      if (validatePhase1()) {
        let data = useFormData;
        props.dispatch(dashboardActions.PMSAddAppraisalCycle1(data, props));
        handleSelect(CrrPhaseId);
      }
    } else if (CrrPhaseId === 2) {
      function removeIDFn(list) {
        let finalList = [];
        for (var i = 0; i < list.length; i++) {
          let element = list[i];
          element["scoreLabels"] =
            element &&
              element["scoreLabels"] &&
              element["scoreLabels"].length > 0
              ? element["scoreLabels"].map((ele) => ({
                label: ele.label,
                a: ele.a,
                b: ele.b,
              }))
              : [];
          finalList.push(element);
        }
        return finalList;
      }
      let data = {
        id: appraisalId,
        filterSetting: removeIDFn(PMSGetModuleSetting2),
      };
      // console.log("fhhfhfhfh", PMSGetModuleSetting2);

      if (validatePhase2(PMSGetModuleSetting2)) {
        props.dispatch(dashboardActions.PMSAddModuleSetting2(data, props));
        setFormModule(
          PMSAddAppraisalCycle1 && PMSAddAppraisalCycle1.normlExpectedScores
        );
        handleSelect(CrrPhaseId);
        setAllError("");
      } else {
        setAllError("Please activate any module to continue");
      }
    } else if (CrrPhaseId === 3) {
      if (100 === calculate(useFormModule)) {
        let finalarraylist =
          useFormModule && useFormModule && useFormModule.length > 0
            ? useFormModule.map((ele) => ({
              label: ele.label,
              score: ele.score,
            }))
            : [];

        let data = {
          id: appraisalId,
          normlActive: usename,
          normlExpectedScores: finalarraylist,
        };

        props.dispatch(dashboardActions.PMSNormalization5(data, props));

        setAllError("");
        handleSelect(CrrPhaseId);
      } else {
        setAllError("The sum of all values must be equal to 100");
      }
    } else if (CrrPhaseId === 4) {

      let dataxX1 = {
        appraisalCycleId: appraisalId
      };

      props.dispatch(dashboardActions.PMSGetProcessList(dataxX1));

      if (validatePhase3(PMSGetRaterSetting3)) {
        let data = {
          id: appraisalId,
          raterSetting: PMSGetRaterSetting3,
        };

        props.dispatch(dashboardActions.PMSAddRaterSetting3(data, props));
        setAllError("");
        handleSelect(CrrPhaseId);
      } else {
        setAllError("Please add any rater to continue");
      }
      // handleSelect(CrrPhaseId);
    } else if (CrrPhaseId === 5) {
      if (true) {

        let data = {
          id: appraisalId,
          processList: PMSGetProcessDataList.map((ele) => ({
            "moduleName": ele.moduleName,
            "processList": ele.processList.map((ele2) => ({
              "processId": ele2.id,
              "rank": ele2.rank,
              "startDate": moment(ele2.startDate).format("DD-MM-YYYY"),
              "endDate": moment(ele2.endDate).format("DD-MM-YYYY")
            }))
          })),
        };

        props.dispatch(dashboardActions.PMSAddProcessList(data, props));

        handleSelect(CrrPhaseId);
        setAllError("");
      } else {
        setAllError("Please select doj to continue");
      }
    } else if (CrrPhaseId === 6) {
      if (dmgFormData["doj"]) {
        let data = {
          id: appraisalId,
          // months: dmgFormData["months"],
          dmg:
            dmgFormData["dmg"] && dmgFormData["dmg"].length > 0
              ? dmgFormData["dmg"].map((ele) => ({
                level: ele.level,
                valueList: ele.valueList,
              }))
              : [],
          doj: dmgFormData["doj"],
        };
        props.dispatch(dashboardActions.PMSApplicableFor4(data, props));
        handleSelect(CrrPhaseId);
        // console.log(dmgFormData["months"].length,'ksksksksk')
        setAllError("");
      } else {
        setAllError("Please select doj to continue");
      }

      setShow5Buton(true);
    } else if (CrrPhaseId === 7) {
      openAddAppraisalCycle("appraisal_cycle_list");
    }

    if (isReady && CrrPhaseId >= 7) {
      let dataX = {
        id: appraisalId,
      };
      props.dispatch(pmsActions.PMSUpdateAppraisalCycleById(dataX));
    }
  };

  const handleGetEmpList = () => {
    let data = {
      dmg:
        dmgFormData["dmg"] && dmgFormData["dmg"].length > 0
          ? dmgFormData["dmg"].map((ele) => ({
            level: ele.level,
            valueList: ele.valueList,
          }))
          : [],
      doj: dmgFormData["doj"],
    };
    props.dispatch(dashboardActions.PMSDMGEmployeeList(data, props));
  };




  // if(PMSGetProcessDataList && PMSGetProcessDataList.processList && PMSGetProcessDataList.processList.length>0){
  //   for(var ino = 0; ino < PMSGetProcessDataList.processList.length; ino ++){
  //     dateList[PMSGetProcessDataList.processList[ino]["rank"]] = {
  //       "startDate":"",
  //       "endDate":""
  //     }
  //   }
  // }


  const handleChangeDatesDyanamic = (value, rank, field, moduleName) => {

    props.dispatch(dashboardActions.handleReducerDateInput(
      PMSGetProcessDataList, moduleName, value, rank, field
    ));

    // var todayDate = moment(value).format("DD-MM-YYYY");
    // if(!(dateList && dateList[no])){
    //   dateList[no] = {
    //     "startDate":new Date(),
    //     "endDate":new Date()
    //   }
    // }


    // if(type === "start"){
    //   dateList[no]["startDate"] = value;
    // }
    // if(type === "end"){
    //   dateList[no]["endDate"] = value;
    // }
    // console.log("value111111111222-dateList---->", dateList);

    // setDateList(dateList);






    // setStartDate(value);
    // setInitialDate(todayDate);

  };








  const handleChangeDates = (value) => {
    var todayDate = moment(value).format("DD-MM-YYYY");
    setStartDate(value);

    setInitialDate(todayDate);
    //   setFormErrors({
    //     ...useerror,
    //     [name]: '',
    // });
  };
  const handlellasrDate = (value) => {
    var todayDate = moment(value).format("DD-MM-YYYY");
    SetEndDate(value);

    setFormData({
      ...useFormData,
      ["period"]: `${useinitialDate}` + "," + `${todayDate}`,
    });
    setFormErrors({
      ...useerror,
      ["period"]: "",
    });
  };

  const handleChangeDatesV2 = (value) => {


    handleDMGFormData({
      id: appraisalId,
      months: undefinedFixed(PMSGetAppraisalCycleById, "months"),
      dmg: undefinedFixed(PMSGetAppraisalCycleById, "dmg"),
      doj: undefinedFixed(PMSGetAppraisalCycleById, "doj"),
    })
    SetEndDateV2("")


    var todayDate = moment(value).format("DD-MM-YYYY");
    console.log("11111111111111111111111111111111value------->",value)
    console.log("11111111111111111111111111111111todayDate------->",todayDate)

    setStartDateV2(value);

    setInitialDateV2(todayDate);

    //   setFormErrors({
    //     ...useerror,
    //     [name]: '',
    // });
  };
  const handlellasrDateV2 = (value) => {
    var todayDate = moment(value).format("DD-MM-YYYY");
    SetEndDateV2(value);

    console.log("11111111111111111111111111111111useinitialDateV2------->",useinitialDateV2)

    handleDMGFormData({
      ...dmgFormData,
      ["doj"]: `${useinitialDateV2}` + "," + `${todayDate}`,
    });
    console.log("11111111111111111111111111111111dmgFormData------->",dmgFormData)

    
    setFormErrorsV2({
      ...useerrorV2,
      ["doj"]: "",
    });
  };

  const handleReducerInput = (a, b, c, d, e, f) => {
    props.dispatch(dashboardActions.handleReducerInput(a, b, c, d, e, f));
    setAllError("");
  };

  const handleReducerListInput = (a, b, c, d, e, f, g) => {
    props.dispatch(
      dashboardActions.handleReducerListInput(a, b, c, d, e, f, g)
    );
  };

  const handlerRaterReducerInput = (a, b, c, d, e, f) => {
    props.dispatch(dashboardActions.handlerRaterReducerInput(a, b, c, d, e, f));
    setAllError("");
  };

  const removedata = (value) => {
    let newobj = Object.keys(useRatername);
    let newobj1 = Object.values(useRatername);
    let key = useRatername[value];

    const updated = newobj.filter((item, index) => item !== value);
    const updat = newobj1.filter((item, index) => item !== key);
    setratername({ [updated]: updat });
  };
  const moduleNamefunc = (value) => {
    setHidelist(false);

    setratername({ ...useRatername, [value && value.name]: value && value.id });
    setnamenew(useraterindex);
  };

  const allraternamefunc = (value) => {
    let names = [];

    for (let index = 0; index < value.length; index++) {
      names.push(value.name);
    }
  };
  const limitfunc = (rater, index) => {
    setraterindex(index);
    setHidelist((state) => !state);
  };
  let falseList = stagesList.filter((prev) => prev.done === false);
  let crrStageObj = falseList && falseList[0] ? falseList[0] : {};

  function getDMGValue(getIndexByFilterDMGData, field) {
    if (getIndexByFilterDMGData) {
      return getIndexByFilterDMGData && getIndexByFilterDMGData[field]
        ? getIndexByFilterDMGData[field]
        : [];
    } else {
      return [];
    }
  }

  function getDMGValueForm(list, level) {
    let finalList = [];
    if (list && list.length > 0) {
      let getIndex = list.findIndex((prev) => prev.level === level);
      if (getIndex !== -1) {
        finalList = list[getIndex]["valueList"];
      }
    }
    return finalList;
  }

  let monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function handleMonthInput(month, index2) {
    setAllError("");
    let getIndex = dmgFormData["months"].findIndex((prev) => prev === month);
    if (getIndex === -1) {
      if (crrMonthType === 1) {
        if (
          !(
            dmgFormData &&
            dmgFormData["months"] &&
            dmgFormData["months"].length > 0
          )
        ) {
          dmgFormData["months"].push(month);
        }
      } else if (crrMonthType === 2) {
        monthList.map((ele, index) => {
          if (index <= index2) {
            if (!dmgFormData["months"].includes(ele)) {
              dmgFormData["months"].push(ele);
            }
          }
        });
      } else if (crrMonthType === 3) {
        if (!dmgFormData["months"].includes(month)) {
          dmgFormData["months"].push(month);
        }
      }
    }
  }

  function deleteMonthInput(month) {
    if (month === "clear") {
      dmgFormData["months"] = [];
    } else {
      let getIndex = dmgFormData["months"].findIndex((prev) => prev === month);
      if (getIndex !== -1) {
        dmgFormData["months"].splice(getIndex, 1);
      }
    }

    handleDMGFormData({ ...dmgFormData });
  }

  let isAllDone =
    stagesList.filter((prev) => prev.done === true).length ===
    stagesList.length;

  function getPrevValue(item, name, value, newdata) {
    setAllError("");
    let val = "";

    if (newdata && newdata.length > 0) {
      let index = newdata.findIndex((prev) => prev._id === item._id);
      // console.log("indexindex",index);
      if (index !== -1) {
        //  console.log("indexname",newdata[index]);
        if (name === "score") {
          newdata[index]["score"] = value;
          setFormModule(newdata);
        } else if (name === "label") {
          newdata[index]["label"] = value;
          setFormModule(newdata);
        }
      } else {
        newdata.push({
          _id: Math.random,
          label: "",
          score: value,
        });
        setFormModule(newdata);
      }
    }
    return val;
  }

  //   let finalarraylist = useFormModule && useFormModule && useFormModule.length > 0 ?
  //   useFormModule.map((ele) => ({
  //     label: ele.label,
  //     score: + ele.score,

  //   })) : []

  console.log(dmgFormData, "dmgFormData");

  function minZeroRestrict(value, type) {
    let value2 = parseInt(value);
    if (value2 >= 0 && (type === 2 ? value2 <= 100 : true) && value2 < 500) {
      return value2;
    } else {
      return 0;
    }
  }

  function minMaxRestrict(value, item) {
    let min =
      item && item.rating && item.rating.rangeMin ? item.rating.rangeMin : 0;
    let max =
      item && item.rating && item.rating.rangeMax ? item.rating.rangeMax : 0;
    let value2 = parseInt(value);
    if (value2 >= min && value2 <= max) {
      return value2;
    } else {
      return 0;
    }
  }

  console.log("dateList----->", dateList);

  return (
    <div className="w-full p-6 space-y-6 overflow-y-auto">
      <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
        <div className="flex items-center space-x-4 ">
          <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">
            Add New Appraisal Cycle
          </h1>
        </div>
        <div className="md:flex hidden items-center text-sm">
          <span className="mx-2 material-symbols-rounded">home</span>
          <span>/</span>
          <span className="px-2">PMS</span>
          <span>/</span>
          <span className="px-2 ">Appraisal Cycle</span>
          <span>/</span>
          <span className="px-2 font-semibold">Add New Appraisal Cycle</span>
        </div>
      </div>
      <div className="flex flex-row w-full gap-10">
        <div className="w-80">
          <div className="mx-auto max-w-7xl">
            {stagesList && stagesList.length > 0
              ? stagesList.map((item, index) => (
                <div
                  className={
                    item.id === CrrPhaseId
                      ? "flex bg-white p-4  my-2  rounded-md border-2 border-[#2196F3] cursor-pointer space-x-4"
                      : "flex bg-white p-4  my-2 border rounded-md cursor-pointer space-x-4"
                  }
                >
                  {item.done ? (
                    <span
                      className={
                        (item.id === CrrPhaseId || CrrPhaseId > item.id
                          ? "bg-green-500"
                          : "bg-green-400") +
                        " material-icons text-white p-1 rounded-full w-8 h-8  text-center"
                      }
                    >
                      done
                    </span>
                  ) : (
                    <span
                      className={
                        (item.id === CrrPhaseId || CrrPhaseId > item.id
                          ? "bg-[#2196F3]"
                          : "bg-gray-400") +
                        " text-white p-1 rounded-full w-8 h-8 text-center flex items-center justify-center "
                      }
                    >
                      {item.id}
                    </span>
                  )}

                  <div
                    className={
                      (item.id === CrrPhaseId || CrrPhaseId > item.id
                        ? "text-[#3D405B] font-semibold"
                        : "text-[#3D405B]/70") + " text-base p-1 font-medium "
                    }
                  >
                    {item.name}
                  </div>
                </div>
              ))
              : null}
          </div>
        </div>
        <div className="flex-1 w-full p-6 bg-white border rounded-lg">
          {CrrPhaseId === 1 ? (
            <>
              <div className="space-y-12">
                <h3 className="text-xl font-semibold  text-[#3D405B]">
                  Add Appraisal Cycle
                </h3>

                <div className="flex flex-col gap-4 px-6 mx-auto mt-3 border-gray-100 2xl:w-9/12 xl:w-12/12">
                  <div className="flex flex-row items-center gap-6">
                    <div className="text-sm  text-[#3D405B] before:content-['*']  before:text-red-500 w-[40%] text-right">
                      {" "}
                      Name of the Appraisal
                    </div>
                    <div className="w-full ">
                      <input
                        onChange={appraisalName}
                        name="name"
                        placeholder="Enter Your Name"
                        className="w-full px-4 py-3 text-sm bg-white border rounded-md focus:outline-none focus:ring"
                      />
                      {useerror.name ? (
                        <div className="text-xs text-red-600 invalid-feedback">
                          {useerror.name}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="flex flex-row items-center gap-6">
                    <div className="text-sm  text-[#3D405B] before:content-['*']  before:text-red-500 w-[40%] text-right">
                      Appraisal Cycle Period{" "}
                    </div>

                    <div className="flex flex-row items-center w-full gap-6">
                      <div className="relative flex items-center justify-between w-full text-gray-700 border rounded-md">
                        <DatePicker
                          className="w-full px-4 py-3 text-sm bg-white rounded-md focus:outline-none focus:ring"
                          name="startDate"
                          selected={startDate}
                          placeholderText={"Start Date"}
                          selectsStart
                          startDate={startDate}
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date()}
                          onChange={(date) => handleChangeDates(date)}
                        />

                        <div
                          onChange={(date) => handleChangeDates(date)}
                          className="px-4 py-3 border-l"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-calendar-due text-[#212121]/70 cursor-pointer"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                            <path d="M16 3v4"></path>
                            <path d="M8 3v4"></path>
                            <path d="M4 11h16"></path>
                            <path d="M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                          </svg>
                        </div>
                      </div>

                      <p> To</p>
                      <div className="relative flex items-center justify-between w-full text-gray-700 border rounded-md">
                        <DatePicker
                          className="w-full px-4 py-3 text-sm bg-white rounded-md focus:outline-none focus:ring"
                          name="endDate"
                          selected={useEnddate}
                          placeholderText={"End Date"}
                          autoComplete="off"
                          selectsEnd
                          // endDate={endDate}
                          minDate={startDate}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => handlellasrDate(date)}
                        />
                        <div class="border-l px-4 py-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-calendar-due text-[#212121]/70 cursor-pointer"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                            <path d="M16 3v4"></path>
                            <path d="M8 3v4"></path>
                            <path d="M4 11h16"></path>
                            <path d="M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    {useerror.period ? (
                      <div className="text-xs text-red-600 invalid-feedback">
                        {useerror.period}
                      </div>
                    ) : null}
                  </div>

                  <div className="flex flex-row items-start gap-6">
                    <div className="text-sm  text-[#3D405B] before:content-['*']  before:text-red-500 w-[40%] text-right">
                      Description{" "}
                    </div>
                    {/* <div className="text-sm  text-[#3D405B] w-[40%] text-right  "></div> */}
                    <div className="w-full ">
                      {/* <textarea
                        onChange={appraisalName}
                        name="desc"
                        rows={4}
                        placeholder="Write here..."
                        className="w-full px-4 py-3 text-sm bg-white border rounded-md focus:outline-none focus:ring-1"
                      ></textarea> */}
                      <textarea
                        onChange={appraisalName}
                        name="desc"
                        rows={4}
                        placeholder="Write here..."
                        className="w-full px-4 py-3 text-sm bg-white border rounded-md resize-none focus:outline-none focus:ring-1"
                        style={{ height: "100px", overflowY: "auto" }}
                      ></textarea>
                      {useerror.desc ? (
                        <div className="text-xs text-red-600 invalid-feedback">
                          {useerror.desc}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="flex flex-row items-center gap-6">
                    <div className="text-sm  text-[#3D405B] w-[40%] text-right">
                      Guideline Document
                    </div>
                    {/* <div className="text-sm  text-[#3D405B] before:content-['*']  before:text-red-500 w-[40%] text-right"></div> */}
                    {selectedUploadDoc ? (
                      <>
                        <div className="flex flex-row items-center w-full gap-6 ">
                          <label className="border w-full cursor-pointer py-2.5 px-4 rounded-md focus:outline-none  bg-[#F9FAFB] text-[#3D405B] text-base font-medium flex justify-center">
                            <a
                              href={selectedUploadDoc}
                              target="blank"
                              className="text-green-500"
                            >
                              Upload document successfull <br />
                              <span className="flex items-center justify-center text-center text-blue-500 underline">
                                Click here to view
                              </span>
                            </a>
                          </label>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex flex-row items-center w-full gap-6 ">
                          <label className="border w-full cursor-pointer py-2.5 px-4 rounded-md focus:outline-none  bg-[#F9FAFB]  text-[#3D405B]  text-base font-medium flex justify-center">
                            Upload Document
                            <input
                              type="file"
                              required
                              className="absolute -z-10"
                              onChange={handleUploadDoc}
                            />
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {CrrPhaseId === 2 ? (
            <>
              <div className="space-y-4">
                <div className="">
                  <h3 className="text-xl font-semibold  text-[#3D405B]">
                    Module(s) Setting
                  </h3>
                </div>

                {PMSGetModuleSetting2 && PMSGetModuleSetting2.length > 0
                  ? PMSGetModuleSetting2.map((item, index) => (
                    <div className="border ">
                      <div
                        className={
                          (item.moduleActive
                            ? "bg-[#2196F3]/10 text-[#2196F3]"
                            : "bg-[#F9FAFB]/10 text-[#3D405B]/70") +
                          "  font-medium p-4 py-2.5 flex justify-between items-center cursor-pointer"
                        }
                      >
                        <div className="flex">
                          <div className="flex justify-center mt-1">
                            {item.moduleActive ? (
                              <>
                                <div
                                  onClick={() => {
                                    handleReducerInput(
                                      PMSGetModuleSetting2,
                                      item.id,
                                      false,
                                      1,
                                      "moduleActive"
                                    );
                                    handleIndex(0);
                                  }}
                                  className="rounded-full w-11 h-5 p-0.5 bg-blue-400"
                                >
                                  <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform translate-x-3 bg-white rounded-full" />
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  onClick={() => {
                                    handleReducerInput(
                                      PMSGetModuleSetting2,
                                      item.id,
                                      true,
                                      1,
                                      "moduleActive"
                                    );
                                    handleIndex(
                                      crrIndex === index + 1 ? 0 : index + 1
                                    );
                                  }}
                                  className="rounded-full w-11 h-5 p-0.5 bg-gray-200"
                                >
                                  <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform -translate-x-3 bg-white rounded-full" />
                                </div>
                              </>
                            )}
                          </div>

                          <h2 className="px-2 text-lg font-medium">
                            {item.moduleName}
                          </h2>
                        </div>

                        <div className="px-4">
                          <svg
                            onClick={() =>
                              handleIndex(
                                item.moduleActive
                                  ? crrIndex === index + 1
                                    ? 0
                                    : index + 1
                                  : 0
                              )
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-chevron-down"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <path d="M6 9l6 6l6 -6"></path>
                          </svg>
                        </div>
                      </div>

                      {crrIndex === index + 1 ? (
                        <>
                          <div className="flex flex-col gap-4 px-6 pb-6 mx-auto mt-3 border-gray-100 2xl:w-9/12 xl:w-12/12">
                            <div className="flex flex-row items-center gap-6">
                              <div className="text-sm  text-[#3D405B] before:content-['*']  before:text-red-500 w-[40%] text-right">
                                {" "}
                                Select Rating Method
                              </div>
                              <div className=" w-[60%] flex items-center space-x-6">
                                <label className="flex items-center space-x-2">
                                  <input
                                    style={{ zoom: 1.5 }}
                                    type="checkbox"
                                    className=""
                                    name="Rating"
                                    checked={
                                      item &&
                                        item.rating &&
                                        item.rating.active
                                        ? item.rating.active
                                        : false
                                    }
                                    onChange={() =>
                                      handleReducerInput(
                                        PMSGetModuleSetting2,
                                        item.id,
                                        item &&
                                          item.rating &&
                                          item.rating.active
                                          ? false
                                          : true,
                                        2,
                                        "rating",
                                        "active"
                                      )
                                    }
                                  />
                                  <span>Rating</span>
                                </label>
                                <label className="flex items-center space-x-4">
                                  <input
                                    style={{ zoom: 1.5 }}
                                    type="checkbox"
                                    className=""
                                    name="Comment"
                                    checked={
                                      item &&
                                        item.comment &&
                                        item.comment.active
                                        ? item.comment.active
                                        : false
                                    }
                                    onChange={() =>
                                      handleReducerInput(
                                        PMSGetModuleSetting2,
                                        item.id,
                                        item &&
                                          item.comment &&
                                          item.comment.active
                                          ? false
                                          : true,
                                        2,
                                        "comment",
                                        "active"
                                      )
                                    }
                                  />
                                  <span>Comment</span>
                                </label>
                              </div>
                            </div>

                            <div className="flex flex-row items-center gap-6">
                              {(item && item.rating && item.rating.active) ||
                                (item &&
                                  item.comment &&
                                  item.comment.active) ? (
                                <div className="text-sm  text-[#3D405B]  w-[40%] text-right">
                                  {" "}
                                  Set Mandatory
                                </div>
                              ) : null}

                              <div className=" w-[60%] flex items-center space-x-6">
                                {item && item.rating && item.rating.active ? (
                                  <label className="flex items-center space-x-2">
                                    <input
                                      style={{ zoom: 1.5 }}
                                      type="checkbox"
                                      className=""
                                      name="Rating"
                                      checked={
                                        item &&
                                          item.rating &&
                                          item.rating.mandatory
                                          ? item.rating.mandatory
                                          : false
                                      }
                                      onChange={() =>
                                        handleReducerInput(
                                          PMSGetModuleSetting2,
                                          item.id,
                                          item &&
                                            item.rating &&
                                            item.rating.mandatory
                                            ? false
                                            : true,
                                          2,
                                          "rating",
                                          "mandatory"
                                        )
                                      }
                                    />
                                    <span>Rating</span>
                                  </label>
                                ) : null}

                                {item &&
                                  item.comment &&
                                  item.comment.active ? (
                                  <label className="flex items-center space-x-4">
                                    <input
                                      style={{ zoom: 1.5 }}
                                      type="checkbox"
                                      className=""
                                      name="Comment"
                                      checked={
                                        item &&
                                          item.comment &&
                                          item.comment.mandatory
                                          ? item.comment.mandatory
                                          : false
                                      }
                                      onChange={() =>
                                        handleReducerInput(
                                          PMSGetModuleSetting2,
                                          item.id,
                                          item &&
                                            item.comment &&
                                            item.comment.mandatory
                                            ? false
                                            : true,
                                          2,
                                          "comment",
                                          "mandatory"
                                        )
                                      }
                                    />
                                    <span>Comment</span>
                                  </label>
                                ) : null}
                              </div>
                            </div>

                            {item && item.rating && item.rating.active ? (
                              <>
                                <div className="flex flex-row items-center gap-6">
                                  <div className="text-sm  text-[#3D405B] before:content-['*']  before:text-red-500 w-[40%] text-right">
                                    Select Rating Type
                                  </div>
                                  <div className=" w-[60%]">
                                    <div className="relative flex items-center justify-between w-full p-2 px-4 text-gray-700 border rounded-md">
                                      <p
                                        className="w-full"
                                        onClick={() =>
                                          setRatingTypeDrop(
                                            !crrRatingTypeDrop
                                          )
                                        }
                                      >
                                        {" "}
                                        {item.rating && item.rating.type
                                          ? item.rating.type
                                          : "Number"}
                                      </p>
                                      <svg
                                        onClick={() =>
                                          setRatingTypeDrop(
                                            !crrRatingTypeDrop
                                          )
                                        }
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="cursor-pointer icon icon-tabler icon-tabler-caret-down-filled text-[#3D405B]/70"
                                        width={20}
                                        height={20}
                                        viewBox="0 0 24 24"
                                        strokeWidth={2}
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path
                                          d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z"
                                          strokeWidth={0}
                                          fill="currentColor"
                                        />
                                      </svg>
                                      {crrRatingTypeDrop ? (
                                        <>
                                          <div className="absolute right-0 z-10 w-full py-4 overflow-y-auto text-base font-medium bg-white border rounded-md shadow-lg top-10 h-60">
                                            <label
                                              onClick={() => {
                                                setRatingTypeDrop(false);
                                                handleReducerInput(
                                                  PMSGetModuleSetting2,
                                                  item.id,
                                                  "Number",
                                                  2,
                                                  "rating",
                                                  "type"
                                                );
                                              }}
                                              className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 "
                                            >
                                              <span>Number</span>
                                            </label>
                                            <label
                                              onClick={() => {
                                                setRatingTypeDrop(false);
                                                handleReducerInput(
                                                  PMSGetModuleSetting2,
                                                  item.id,
                                                  "Star",
                                                  2,
                                                  "rating",
                                                  "type"
                                                );
                                              }}
                                              className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 "
                                            >
                                              <span>Star</span>
                                            </label>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-row items-center gap-6">
                                  <div className="text-sm  text-[#3D405B] w-[40%] text-right  ">
                                    Set Scale
                                  </div>
                                  <div className="  w-[60%] ">
                                    <label className="flex items-center space-x-4 ">
                                      <input
                                        type="number"
                                        className="w-20 px-4 py-3 text-sm border rounded-md focus:outline-none focus:ring "
                                        value={
                                          item &&
                                            item.rating &&
                                            item.rating.rangeMin
                                            ? item.rating.rangeMin
                                            : 0
                                        }
                                        onChange={(e) =>
                                          handleReducerInput(
                                            PMSGetModuleSetting2,
                                            item.id,
                                            minZeroRestrict(
                                              e.target.value,
                                              0,
                                              item
                                            ),
                                            2,
                                            "rating",
                                            "rangeMin"
                                          )
                                        }
                                        placeholder="0"
                                      />
                                      <span>-</span>
                                      <input
                                        type="number"
                                        className="w-20 px-4 py-3 text-sm border rounded-md focus:outline-none focus:ring "
                                        value={
                                          item &&
                                            item.rating &&
                                            item.rating.rangeMax
                                            ? item.rating.rangeMax
                                            : 0
                                        }
                                        placeholder="10"
                                        onChange={(e) =>
                                          handleReducerInput(
                                            PMSGetModuleSetting2,
                                            item.id,
                                            minZeroRestrict(
                                              e.target.value,
                                              10,
                                              item
                                            ),
                                            2,
                                            "rating",
                                            "rangeMax"
                                          )
                                        }
                                      />
                                    </label>
                                  </div>
                                </div>
                              </>
                            ) : null}

                            {item && item.comment && item.comment.active ? (
                              <div className="flex flex-row items-center gap-6">
                                <div className="text-sm  text-[#3D405B]  w-[40%] text-right">
                                  Minimum Comment Length
                                </div>
                                <div className=" w-[60%]  ">
                                  <label className="flex items-center space-x-4 ">
                                    <input
                                      type="number"
                                      value={
                                        item &&
                                          item.comment &&
                                          item.comment.limit
                                          ? item.comment.limit
                                          : 0
                                      }
                                      onChange={(e) =>
                                        handleReducerInput(
                                          PMSGetModuleSetting2,
                                          item.id,
                                          minZeroRestrict(e.target.value),
                                          2,
                                          "comment",
                                          "limit"
                                        )
                                      }
                                      className="w-20 px-4 py-3 text-sm border rounded-md focus:outline-none focus:ring "
                                      placeholder="250"
                                      name="commentLen"
                                    />
                                    <span>Character</span>
                                  </label>
                                </div>
                              </div>
                            ) : null}

                            <div className="flex flex-row items-center gap-6">
                              <div className="text-sm  text-[#3D405B]  w-[40%] text-right"></div>
                              <div className=" w-[60%]  ">
                                <label className="flex items-center space-x-4">
                                  <input
                                    style={{ zoom: 1.5 }}
                                    value={item.enableNA}
                                    onChange={(e) =>
                                      handleReducerInput(
                                        PMSGetModuleSetting2,
                                        item.id,
                                        !item.enableNA,
                                        1,
                                        "enableNA"
                                      )
                                    }
                                    type="checkbox"
                                    className=""
                                  />
                                  <span>
                                    Enable the not applicable (N/A) option in
                                    module rating ,if you want the reviewers
                                    to be able to give NA and not comment,
                                    then tick the box.
                                  </span>
                                </label>
                              </div>
                            </div>

                            <div className="flex flex-row items-center gap-6">
                              <div className="text-sm  text-[#3D405B]   w-[40%] text-right">
                                {" "}
                                Final Score
                              </div>
                              <div className=" w-[60%] flex items-center space-x-6">
                                <label className="flex items-center space-x-2">
                                  <input
                                    style={{ zoom: 1.5 }}
                                    type="radio"
                                    className=""
                                    name="isRoundOff"
                                    checked={!item.isRoundOff}
                                    onChange={(e) =>
                                      handleReducerInput(
                                        PMSGetModuleSetting2,
                                        item.id,
                                        false,
                                        1,
                                        "isRoundOff"
                                      )
                                    }
                                  />
                                  <span>Actual Score</span>
                                </label>
                                <label className="flex items-center space-x-4">
                                  <input
                                    style={{ zoom: 1.5 }}
                                    type="radio"
                                    className=""
                                    name="isRoundOff"
                                    checked={item.isRoundOff}
                                    onChange={(e) =>
                                      handleReducerInput(
                                        PMSGetModuleSetting2,
                                        item.id,
                                        true,
                                        1,
                                        "isRoundOff"
                                      )
                                    }
                                  />
                                  <span>Round off Score</span>
                                </label>
                              </div>
                            </div>

                            <div className="flex flex-row items-start gap-6">
                              <div className="text-sm  text-[#3D405B]  w-[40%] text-right py-3">
                                Ratings & Score Range
                              </div>
                              <div className=" w-[60%] space-y-4  ">
                                {item &&
                                  item.scoreLabels &&
                                  item.scoreLabels.length > 0
                                  ? item.scoreLabels.map((ele2) => (
                                    <div className="flex items-center justify-between w-full px-4 py-3 border rounded-md ">
                                      <span className="w-full">
                                        <input
                                          type="text"
                                          className="w-30  placeholder:text-base text-[#3D405B]  text-base focus:outline-none"
                                          name="isRoundOff"
                                          value={ele2.label}
                                          checked={item.isRoundOff}
                                          onChange={(e) =>
                                            handleReducerListInput(
                                              PMSGetModuleSetting2,
                                              item.id,
                                              minZeroRestrict(
                                                e.target.value
                                              ),
                                              2,
                                              "scoreLabels",
                                              ele2._id,
                                              "label"
                                            )
                                          }
                                        />
                                      </span>
                                      {/* <span className="flex justify-center w-full">8.1 - 10.0</span> */}
                                      <div className="w-full  flex justify-center text-[#3D405B]">
                                        <input
                                          type="text"
                                          className="w-8 text-center focus:outline-none"
                                          name="isRoundOff"
                                          value={ele2.a}
                                          onChange={(e) =>
                                            handleReducerListInput(
                                              PMSGetModuleSetting2,
                                              item.id,
                                              minMaxRestrict(
                                                e.target.value,
                                                item
                                              ),
                                              2,
                                              "scoreLabels",
                                              ele2._id,
                                              "a"
                                            )
                                          }
                                        />

                                        {" - "}

                                        <input
                                          type="text"
                                          className="w-8 text-center focus:outline-none"
                                          name="isRoundOff"
                                          value={ele2.b}
                                          onChange={(e) =>
                                            handleReducerListInput(
                                              PMSGetModuleSetting2,
                                              item.id,
                                              minMaxRestrict(
                                                e.target.value,
                                                item
                                              ),
                                              2,
                                              "scoreLabels",
                                              ele2._id,
                                              "b"
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="flex flex-row gap-4 text-[#3D405B]/50"></div>
                                    </div>
                                  ))
                                  : null}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ))
                  : null}
              </div>
            </>
          ) : null}

          {CrrPhaseId === 3 ? (
            <>
              <div className="space-y-12">
                <h3 className="text-xl font-semibold  text-[#3D405B]">
                  Normalization
                </h3>

                <div className="flex flex-col gap-4 px-6 mx-auto mt-3 border-gray-100 2xl:w-9/12 xl:w-12/12">
                  <div className="flex flex-row items-center gap-6">
                    <div className="text-sm  text-[#3D405B] before:content-['*']  before:text-red-500 w-[40%] text-right">
                      {" "}
                      Normalization
                    </div>
                    <div className="w-full ">
                      <label className="flex items-center space-x-2 cursor-pointer ">
                        <input
                          type="checkbox"
                          name="name"
                          placeholder="Enable"
                          onClick={() => setnamenew(!usename)}
                          className="px-4 py-3 text-sm transform scale-105 bg-white rounded-md "
                        />
                        <span className="text-sm">Enable</span>
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-row items-start gap-6">
                    <div className="text-sm  text-[#3D405B] before:content-['*']  before:text-red-500 w-[40%] text-right">
                      Ideal Rating Distribution (in%)
                    </div>

                    <div className="w-full space-y-4">
                      {useFormModule && useFormModule.length > 0
                        ? useFormModule.map((item, index) => (
                          <div className="flex flex-row items-center w-full gap-4">
                            <input
                              name="label"
                              placeholder="Enter Label"
                              onChange={(e) =>
                                getPrevValue(
                                  item,
                                  "label",
                                  e.target.value,
                                  useFormModule
                                )
                              }
                              defaultValue={item && item.label}
                              className="border bg-white w-full py-2.5 px-4 text-sm rounded-md focus:outline-none focus:ring-1 font-medium  text-[#3D405B]"
                            />
                            <input
                              name="score"
                              placeholder="Enter Score %"
                              type="number"
                              onChange={(e) =>
                                getPrevValue(
                                  item,
                                  "score",
                                  minZeroRestrict(e.target.value, 2),
                                  useFormModule
                                )
                              }
                              defaultValue={item && item.score}
                              className="border bg-white w-24 py-2.5 px-4 text-sm rounded-md focus:outline-none focus:ring-1 font-medium  text-[#3D405B] text-center"
                            />
                          </div>
                        ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {CrrPhaseId === 4 ? (
            <div className="space-y-6 ">
              <div className="">
                <h3 className="text-xl font-semibold  text-[#3D405B]  ">
                  Raters
                </h3>
              </div>

              <div className="border divide-y ">
                {PMSGetRaterSetting3 && PMSGetRaterSetting3.length > 0
                  ? PMSGetRaterSetting3.map((rater, index) => (
                    <>
                      <div className="flex items-stretch ">
                        <div className="w-[30%] bg-[#F9FAFB] flex items-center px-6 text-[3D405B] font-medium border-r">
                          <span>{rater.raterName}</span>
                        </div>

                        <div className="w-[70%] px-4 py-3 flex space-x-2 items-center relative  ">
                          {rater &&
                            rater.moduleAccessList &&
                            rater.moduleAccessList.length > 0 ? (
                            <div className="">
                              <ul className="flex flex-row flex-wrap w-full gap-2 ">
                                {rater.moduleAccessList.map((moduleName) => (
                                  <li className="bg-[#F9FAFB] p-2 border text-sm flex items-center space-x-1 rounded-md">
                                    <span>{moduleName}</span>

                                    <svg
                                      onClick={() =>
                                        handlerRaterReducerInput(
                                          PMSGetRaterSetting3,
                                          crrRaterName,
                                          moduleName,
                                          4,
                                          "moduleAccessList"
                                        )
                                      }
                                      xmlns="http://www.w3.org/2000/svg"
                                      class="icon icon-tabler icon-tabler-x"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      stroke-width="2"
                                      stroke="currentColor"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <path d="M18 6l-12 12"></path>
                                      <path d="M6 6l12 12"></path>
                                    </svg>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : null}

                          {rater &&
                            rater.moduleAccessList &&
                            rater.moduleAccessList.length > 0 ? (
                            <div className="relative">
                              <button
                                onClick={() => {
                                  handleCrrRaterName(rater.raterName);
                                  limitfunc(rater.name, index);
                                }}
                                className="p-1 bg-[#2196F3] rounded-full text-white "
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="text-white icon icon-tabler icon-tabler-plus"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  stroke-width="2"
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M12 5l0 14"></path>
                                  <path d="M5 12l14 0"></path>
                                </svg>
                              </button>
                            </div>
                          ) : (
                            <button
                              onClick={() => {
                                handleCrrRaterName(rater.raterName);
                                limitfunc(rater.name, index);
                              }}
                              className="px-4 py-2 bg-[#2196F3]/10 rounded-md flex items-center space-x-2 text-[#2196F3] font-medium "
                            >
                              <span className="p-1 bg-[#2196F3] rounded-full ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="text-white icon icon-tabler icon-tabler-plus"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  stroke-width="2"
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M12 5l0 14"></path>
                                  <path d="M5 12l14 0"></path>
                                </svg>
                              </span>
                              <span>ADD Module</span>
                            </button>
                          )}

                          {useHideList &&
                            rater.raterName === crrRaterName &&
                            getPMSModuleNames &&
                            getPMSModuleNames.length > 0 ? (
                            <>
                              <div className="space-y-6 ">
                                <div className="border divide-y ">
                                  <div className="w-[70%] px-4 py-3 flex space-x-2 items-center  ">
                                    <div className="relative">
                                      <div className="absolute z-10 top-0 py-4 overflow-y-auto bg-white border rounded-md shadow-lg right-6 w-60 h-60 ">
                                        {getPMSModuleNames.map(
                                          (item2, index2) => (
                                            <label
                                              onClick={() => {
                                                limitfunc("", index2);
                                                handlerRaterReducerInput(
                                                  PMSGetRaterSetting3,
                                                  crrRaterName,
                                                  item2.name,
                                                  3,
                                                  "moduleAccessList"
                                                );
                                              }}
                                              className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6"
                                            >
                                              <input
                                                style={{ zoom: 1.5 }}
                                                type="checkbox"
                                                className=""
                                              />
                                              <span>
                                                {item2 && item2.name}
                                              </span>
                                            </label>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ))
                  : null}
              </div>
            </div>
          ) : null}

          {CrrPhaseId === 5 ? (
            <div className="space-y-6 ">
              <div className="">
                <h3 className="text-xl font-semibold  text-[#3D405B]  ">
                  Date Control
                </h3>
              </div>

              <div className="flex flex-col gap-4 px-6 mx-auto mt-3 border-gray-100 2xl:w-9/12 xl:w-12/12">


                {PMSGetProcessDataList && PMSGetProcessDataList.length > 0 ?
                  PMSGetProcessDataList.map((ele) =>
                    <>
                      <div className="flex flex-row items-start gap-6">
                        <div className="text-xl  text-[#3D405B] w-full font-semibold pb-4">
                          {ele.moduleName}
                        </div>
                      </div>

                      <div className="flex flex-col gap-6">
                        {ele && ele.processList.length > 0 ?
                          ele.processList.map((ele2) =>

                            <div className="flex flex-row items-start gap-6 border p-4 bg-[#F9FAFB] rounded-md">
                              <div className="w-full space-y-2">
                                <p className="text-base   text-[#3D405B]   text-left  rounded-md p-2 ">
                                  {ele2.rank}. <span>{ele2.user}</span> {">"} <span>{ele2.moduleName}</span> {">"} <span>{ele2.title}</span> {">"} <span>{ele2.activity}</span> {">"} <span className="font-medium  ">{ele2.subactivity}</span>
                                </p>


                                <div className="flex flex-row items-center w-full gap-6">
                                  <div className="relative flex items-center justify-between w-full text-gray-700 border rounded-md bg-white ">
                                    <DatePicker
                                      className="!w-[100%] px-4 py-3 text-sm rounded-md focus:outline-none focus:ring-1"
                                      name="startDate"
                                      selected={ele2 && ele2["startDate"] ? ele2["startDate"] : new Date()}
                                      placeholderText={"Start Date"}
                                      selectsStart
                                      startDate={ele2 && ele2["startDate"] ? ele2["startDate"] : new Date()}
                                      autoComplete="off"
                                      dateFormat="dd/MM/yyyy"
                                      minDate={new Date()}
                                      onChange={(date) => handleChangeDatesDyanamic(date, ele2.rank, "startDate", ele.moduleName)}
                                    />

                                    <div
                                      className="px-4 py-3 border-l"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg" 
                                        class="icon icon-tabler icon-tabler-calendar-due text-[#212121]/70 cursor-pointer"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        ></path>
                                        <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                        <path d="M16 3v4"></path>
                                        <path d="M8 3v4"></path>
                                        <path d="M4 11h16"></path>
                                        <path d="M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                      </svg>
                                    </div>
                                  </div>

                                  <p> To</p>

                                  <div className="relative flex items-center justify-between w-full text-gray-700 border rounded-md bg-white ">
                                    <DatePicker
                                      className="w-full px-4 py-3 text-sm   rounded-md focus:outline-none focus:ring-1"
                                      name="endDate"
                                      selected={ele2 && ele2["endDate"] ? ele2["endDate"] : new Date()}
                                      placeholderText={"End Date"}
                                      autoComplete="off"
                                      selectsEnd
                                      // endDate={endDate}
                                      minDate={ele2 && ele2["startDate"] ? ele2["startDate"] : new Date()}
                                      dateFormat="dd/MM/yyyy"
                                      onChange={(date) => handleChangeDatesDyanamic(date, ele2.rank, "endDate", ele.moduleName)}
                                    />
                                    <div
                                      className="border-l px-4 py-3">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-calendar-due text-[#212121]/70 cursor-pointer"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        ></path>
                                        <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                        <path d="M16 3v4"></path>
                                        <path d="M8 3v4"></path>
                                        <path d="M4 11h16"></path>
                                        <path d="M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          ) : null}
                      </div>

                    </>

                  ) : null}
                {/* 
                
                <div className="flex flex-row items-start gap-6">
                  <div className="text-sm  text-[#3D405B] w-[40%] text-right">
                    My Review{" "}
                  </div>
                  <div className="w-full space-y-2">
                    <p className="text-sm  text-[#3D405B]   text-left">
                      My Review{" "}
                    </p>

                    <div className="flex flex-row items-center w-full gap-6"> 
                      <input
                        type="text"
                        name="startDate"
                        placeholder="Start Date"
                        className="w-full px-4 py-3 text-sm bg-white rounded-md focus:outline-none focus:ring  text-gray-700 border "
                      />
                      <p> To</p>
                      <input
                        type="text"
                        name="startDate"
                        placeholder="End Date"
                        className="w-full px-4 py-3 text-sm bg-white rounded-md focus:outline-none focus:ring  text-gray-700 border "
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-start gap-6">
                  <div className="text-sm  text-[#3D405B] w-[40%] text-right">
                    My Review{" "}
                  </div>
                  <div className="w-full space-y-2">
                    <p className="text-sm  text-[#3D405B]   text-left">
                      My Review{" "}
                    </p>

                    <div className="flex flex-row items-center w-full gap-6"> 
                      <input
                        type="text"
                        name="startDate"
                        placeholder="Start Date"
                        className="w-full px-4 py-3 text-sm bg-white rounded-md focus:outline-none focus:ring  text-gray-700 border "
                      />
                      <p> To</p>
                      <input
                        type="text"
                        name="startDate"
                        placeholder="End Date"
                        className="w-full px-4 py-3 text-sm bg-white rounded-md focus:outline-none focus:ring  text-gray-700 border "
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          ) : null}

          {CrrPhaseId === 6 ? (
            <>
              <div className="">
                <div>
                  <div className="px-4 pb-2 sm:px-0">
                    <h3 className="text-xl font-semibold  text-[#3D405B]">
                      Applicable for
                    </h3>
                  </div>
                  <div className="flex flex-col gap-4 px-6 mx-auto mt-3 border-gray-100 2xl:w-9/12 xl:w-12/12">
                    <div className="flex flex-row items-center gap-6">
                      <div class="text-sm  text-[#3D405B] before:content-['*']  before:text-red-500 w-[40%] text-right">
                        {" "}
                        Date of Joining
                      </div>

                      <div className="flex w-full">
                        <div className="flex flex-row items-center w-full gap-6">
                          <div className="relative flex items-center justify-between w-full text-gray-700 border rounded-md">
                            <DatePicker
                              className="w-full px-4 py-3 text-sm bg-white rounded-md focus:outline-none focus:ring"
                              name="startDate"
                              selected={startDateV2}
                              placeholderText={"Start Date"}
                              selectsStart
                              startDate={"01-01-1950"}
                              autoComplete="off"
                              dateFormat="dd/MM/yyyy"
                              minDate={"01-01-1950"}
                              onChange={(date) => handleChangeDatesV2(date)}
                            />

                            <div
                              onChange={(date) => handleChangeDatesV2(date)}
                              className="px-4 py-3 border-l"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-calendar-due text-[#212121]/70 cursor-pointer"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                <path d="M16 3v4"></path>
                                <path d="M8 3v4"></path>
                                <path d="M4 11h16"></path>
                                <path d="M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                              </svg>
                            </div>
                          </div>

                          <p> To</p>

                          <div className="relative flex items-center justify-between w-full text-gray-700 border rounded-md">
                            <DatePicker
                              className="w-full px-4 py-3 text-sm bg-white rounded-md focus:outline-none focus:ring"
                              name="endDate"
                              selected={useEnddateV2}
                              placeholderText={"End Date"}
                              autoComplete="off"
                              selectsEnd
                              // endDate={endDate}
                              minDate={startDateV2}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => handlellasrDateV2(date)}
                            />
                            <div class="border-l px-4 py-3">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-calendar-due text-[#212121]/70 cursor-pointer"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                <path d="M16 3v4"></path>
                                <path d="M8 3v4"></path>
                                <path d="M4 11h16"></path>
                                <path d="M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="my-2 space-y-4">
                      {EmpFilterData && EmpFilterData.length > 0
                        ? EmpFilterData.map((ele) => (
                          <div className="flex flex-row items-center gap-6">
                            <div className="text-sm  text-[#3D405B]  w-[40%] text-right">
                              {ele.label}
                            </div>
                            <div
                              onClick={() => handleCrrDropDownDmgV2(ele.name)}
                              className="cursor-pointer  relative flex items-center justify-between w-full p-2 px-4 text-gray-700 border rounded-md"
                            >
                              <ul className="flex flex-row flex-wrap w-full gap-2 ">
                                {getDMGValueForm(
                                  dmgFormData["dmg"],
                                  ele.name
                                ) &&
                                  getDMGValueForm(dmgFormData["dmg"], ele.name)
                                    .length > 0 ? (
                                  getDMGValueForm(
                                    dmgFormData["dmg"],
                                    ele.name
                                  ).map((item) => (
                                    <li className="bg-[#F9FAFB] p-2 border text-sm flex items-center space-x-1 rounded-md">
                                      <span>{item}</span>

                                      <svg
                                        onClick={() =>
                                          deleteDMGInput(ele.name, item)
                                        }
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-x"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        stroke-width="2"
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        ></path>
                                        <path d="M18 6l-12 12"></path>
                                        <path d="M6 6l12 12"></path>
                                      </svg>
                                    </li>
                                  ))
                                ) : (
                                  <li className=" p-2 text-sm flex items-center space-x-1 font-medium text-gray-400">
                                    <span>
                                      {"Select " + ele.name + " ..."}
                                    </span>
                                  </li>
                                )}
                              </ul>

                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer icon icon-tabler icon-tabler-caret-down-filled text-[#3D405B]/70"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path
                                  d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z"
                                  stroke-width="0"
                                  fill="currentColor"
                                ></path>
                              </svg>

                              {crrDropDownDmg === ele.name ? (
                                <div
                                  ref={ref}
                                  className="absolute right-0 z-10 py-4 overflow-y-auto bg-white border rounded-md shadow-lg top-12 w-full max-h-60 min-h-auto"
                                >
                                  {getDMGValue(
                                    getIndexByFilterDMGData,
                                    ele.name
                                  ) &&
                                    getDMGValue(
                                      getIndexByFilterDMGData,
                                      ele.name
                                    ).length > 0
                                    ? getDMGValue(
                                      getIndexByFilterDMGData,
                                      ele.name
                                    ).map((item, index) => (
                                      <label
                                        onClick={() => {
                                          handleCrrDropDownDmg("");
                                          handleDMGInput(ele.name, item);
                                        }}
                                        className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6"
                                      >
                                        <input
                                          style={{ zoom: 1.5 }}
                                          type="checkbox"
                                          className=""
                                        />
                                        <span>{item}</span>
                                      </label>
                                    ))
                                    : null}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ))
                        : null}

                      <div className="flex flex-row items-center gap-6">
                        <div className="text-sm  text-[#3D405B]  w-[40%] text-right">
                          No. of Employee
                        </div>
                        <div className="relative flex items-center justify-between w-full p-2 px-4 text-gray-700 border rounded-md">
                          <div className="flex flex-row flex-wrap w-full gap-2 ">
                            <span>
                              {PMSDMGEmployeeTotal ? PMSDMGEmployeeTotal : 0}
                            </span>
                          </div>

                          <div className="flex flex-row flex-wrap w-full gap-2 ">
                            <button
                              onClick={() => handleGetEmpList()}
                              className="border border-gray-700 text-gray-700 py-2.5 px-12 rounded-md uppercase"
                            >
                              Load Data
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {CrrPhaseId === 7 ? (
            <div className="max-w-3xl mx-auto mt-6">
              <div>
                <div className="flex items-center justify-center px-4 pb-2 my-4 sm:px-0">
                  <h3 className="text-2xl font-semibold leading-7 text-[#3D405B] text-center">
                    Press "Submit" to create Appraisal Cycle!
                  </h3>
                </div>
              </div>
            </div>
          ) : null}

          <div className="flex items-center justify-center pt-4 text-xs text-red-600 invalid-feedback">
            {useAllerror ? useAllerror : ""}
          </div>
          <div className="flex items-center justify-center pt-12">
            <button
              onClick={() => {
                newhandle(crrStageObj, CrrPhaseId === 7 ? true : false);
              }}
              className="bg-[#2196F3] text-white py-2.5 px-12 rounded-md uppercase"
            >
              {CrrPhaseId === 7 ? "Submit" : "Continue"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { user, dashboard, pms } = state;
  return {
    loggingIn,
    user,
    dashboard,
    pms
  };
}
export default connect(mapStateToProps)(AddAppraisalCycle);
