import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../../../_actions";
import Filter from "../../../../../pages/CommonComponents/Filters/IndexFilter/Filter";
import ReactEcharts from "echarts-for-react";
//libraries
import * as echarts from 'echarts';


class AppraisalCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: "none",
      showLeaderBoard: false,
      isMultifilter: false,
      currentSelectValue: "",
      FilterValues: [],
      showFilter: false,
      showValue: "",
      ListName: "Demographic",
      showEDAModal: false,
      crrDMGValue: "",
      activeTab: "AppraisalStatus",
      activeCard: 'selfAppraisalStatus',
      mainTab: "AppraisalCycle",
      showhide: false,
      isCardSelected: true


    };
  }
  componentDidMount() { }

  handleTabClick = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { filterDashboardOverviewStatus } = this.props;
    this.setState({ FilterValues: finalFilter, ListName: name });
    filterDashboardOverviewStatus(finalFilter, true, name);
  };



  loadEmpStatus = (value) => {
    this.setState({ isCardSelected: value });
    let { filterPMSEmployeeWiseStatus } = this.props;
    if (!value) {
      filterPMSEmployeeWiseStatus()
    }
  };



  render() {
    let { pms, dashboard, survey } = this.props;
    let { EmpFilterData, getEMPFilterLabel, getEmployeeRatingAndNormalization } = dashboard;
    let { getIndexByFilterDMGData } = survey;

    let { getEMPASData, getDashboardOverviewStatusData, getPMSEWScore } = pms;
    let { activeTab, activeCard, showhide } = this.state;

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    console.log("getIndexByFilterDMGData--------->", getIndexByFilterDMGData)


    const completedCountSelfAppraisal = getDashboardOverviewStatusData?.selfAppraisalStatus?.completed || 0;
    const pendingCountSelfAppraisal = getDashboardOverviewStatusData?.selfAppraisalStatus?.pending || 0;
    const totalSelfAppraisalStatus = completedCountSelfAppraisal + pendingCountSelfAppraisal;

    // Extract completed and pending counts from reviewStatus
    const completedCountMultiRater = getDashboardOverviewStatusData?.multiRaterStatus?.completed || 0;
    const pendingCountMultiRater = getDashboardOverviewStatusData?.multiRaterStatus?.pending || 0;
    const totalMultiRaterStatus = completedCountMultiRater + pendingCountMultiRater;

    // Extract completed and pending counts from reviewStatus
    const completedCountReviewStatus = getDashboardOverviewStatusData?.reviewStatus?.completed || 0;
    const pendingCountReviewStatus = getDashboardOverviewStatusData?.reviewStatus?.pending || 0;
    const totalReviewStatus = completedCountReviewStatus + pendingCountReviewStatus;



    let data5 = (getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.normlLabelCount ? getEmployeeRatingAndNormalization.normlLabelCount : {})
    let data6 = (getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.labels ? getEmployeeRatingAndNormalization.labels : {})
    var labelsListScore = data5 ? Object.keys(data5).map((key) => [key, data6[key], data5[key]]) : [];

    console.log("getEMPASData----->", getEMPASData && getEMPASData[0] ? getEMPASData[0] : {})


    // const data = [
    //   {
    //     value: 1048,
    //     // name: 'Search Engine',
    //     itemStyle: {
    //       color: '#FFC107',
    //     },
    //   },
    //   {
    //     value: 735,
    //     // name: 'Direct',
    //     itemStyle: {
    //       color: '#4caf50',
    //     },
    //   },
    // ];



    function chartCard(name,activeCard,totalStatus,getDashboardOverviewStatusData,statusField, nameType){

      let completed = getDashboardOverviewStatusData && getDashboardOverviewStatusData[statusField] && getDashboardOverviewStatusData[statusField].completed ? getDashboardOverviewStatusData[statusField].completed : 0;
      let pending = getDashboardOverviewStatusData && getDashboardOverviewStatusData[statusField] && getDashboardOverviewStatusData[statusField].pending ? getDashboardOverviewStatusData[statusField].pending : 0;

      return (<>
        <div className="p-6 transition duration-150 bg-white border rounded-lg cursor-pointer hover:shadow-lg">
                <div className={activeCard === nameType ? 'active space-y-6' : 'space-y-6'}
                // onClick={() => this.handleTabChange(this.props.match.params.id, "CardSelected")}
                >
                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">{name}</h1>
                  <div className="relative flex justify-center percent">
                    <ReactEcharts
                      className='!w-full'
                      option={{
                        tooltip: {
                          trigger: 'item',
                        },
                        // legend: {
                        //   top: '5%',
                        //   left: 'center',
                        // },
                        series: [
                          {
                            name: name,
                            type: 'pie',
                            radius: ['60%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                              show: false,
                              position: 'center'
                            },
                            emphasis: {
                              label: {
                                show: false,
                                fontSize: 40,
                                fontWeight: 'bold'
                              }
                            },
                            labelLine: {
                              show: false
                            },
                            data: [
                              {
                                name:"Pending",
                                value: pending,
                                itemStyle: {
                                  color: '#FFC107'
                                }
                              },
                              {
                                name:"Completed",
                                value: completed,
                                itemStyle: {
                                  color: '#4caf50'
                                }
                              }
                            ]
                          }
                        ]
                      }}

                      
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center'
                      }}
                    >
                      <span style={{fontSize:"35px", fontWeight:'500'}}>{totalStatus}</span><br /> <span style={{fontSize:"20px"}}>Total</span>
                    </div>
                  </div>

                  <div className="flex justify-center w-full space-x-4">
                    <div className="flex items-center space-x-2">
                      <span className="w-3 h-3 bg-green-500 rounded-full"></span>
                      <div className="flex items-center space-x-1">
                        <p className="font-medium ">{completed}</p>{" "}
                        <p className="text-sm text-[#212121]">
                          {" "}
                          Completed
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center space-x-2">
                      <span className="w-3 h-3 bg-yellow-400 rounded-full"></span>
                      <div className="flex items-center space-x-1">
                        <p className="font-medium ">{pending}</p>{" "}
                        <p className="text-sm text-[#212121]">Pending</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </>)
    }


    return (
      <>


        <div className="items-center justify-between md:flex">
          <div className="flex flex-wrap gap-2">
            {/* <div className="cursor-pointer bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] flex justify-between px-4 items-center relative py-2 rounded-md w-40 "> */}
            <div className="flex items-center space-x-2 ">
              <Filter
                showFilter={this.state.showFilter}
                showValue={this.state.showValue}
                closeFilter={this.closeFilter}
                EmpFilterData2={EmpFilterData2}
                getIndexFilterData={getIndexByFilterDMGData}
                handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
                getEMPFilterLabel={getEMPFilterLabel}
              />
              {/* </div> */}
            </div>

            {/* <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                  <span className=" text-sm text-[#3D405B]/70 font-medium ">
                    Location
                  </span>
                  <span className="material-symbols-outlined ">
                    arrow_drop_down
                  </span>
                </div>
                <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                  <span className=" text-sm text-[#3D405B]/70 font-medium ">
                    Department
                  </span>
                  <span className="material-symbols-outlined ">
                    arrow_drop_down
                  </span>
                </div> */}
          </div>

          {/* <div className="flex space-x-2 ">
            <button className="flex items-center justify-center p-2 bg-white border rounded-md" onClick={() => this.loadEmpStatus(true)}>
              <span className="material-symbols-rounded  text-[1.5rem] text-[#2196f3]">
                grid_view
              </span>
            </button>
            <button className="flex items-center justify-center p-2 bg-white border rounded-md" onClick={() => this.loadEmpStatus(false)}>
              <span className="material-symbols-rounded  text-[1.5rem] ">
                format_list_bulleted
              </span>
            </button>
          </div> */}


          <div className="flex space-x-2">
            <button className={`flex items-center justify-center p-2 rounded-md ${this.state.isCardSelected ? 'bg-[#2196f3] text-white' : 'bg-white text-[#2196f3]'} `}
              onClick={() => this.loadEmpStatus(true)}>
              <span className="material-symbols-rounded text-[1.5rem]">grid_view</span>
            </button>

            <button className={`flex items-center justify-center p-2 rounded-md ${!this.state.isCardSelected ? 'bg-[#2196f3] text-white' : 'bg-white text-[#2196f3]'} `}
              onClick={() => this.loadEmpStatus(false)}>
              <span className="material-symbols-rounded text-[1.5rem]">format_list_bulleted</span>
            </button>
          </div>
        </div>

        {this.state.isCardSelected ?
          <>
            <div className="grid grid-cols-3 gap-6">
              {/* <div className="p-6 transition duration-150 bg-white border rounded-lg cursor-pointer hover:shadow-lg">
                <div className={activeCard === 'selfAppraisalStatus' ? 'active space-y-6' : 'space-y-6'}
                // onClick={() => this.handleTabChange(this.props.match.params.id, "CardSelected")}
                >
                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Self Appraisal Status</h1>
                  <div className="relative flex justify-center percent">
                    <ReactEcharts
                      className='!w-full'
                      option={{
                        tooltip: {
                          trigger: 'item',
                        },
                        legend: {
                          top: '5%',
                          left: 'center',
                        },
                        series: [
                          {
                            name: 'Access From',
                            type: 'pie',
                            radius: ['60%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                              show: false,
                              position: 'center'
                            },
                            emphasis: {
                              label: {
                                show: true,
                                fontSize: 40,
                                fontWeight: 'bold'
                              }
                            },
                            labelLine: {
                              show: false
                            },
                            data: [
                              {
                                value: 1048,
                                itemStyle: {
                                  color: '#FFC107'
                                }
                              },
                              {
                                value: 735,
                                itemStyle: {
                                  color: '#4caf50'
                                }
                              }
                            ]
                          }
                        ]
                      }}

                      
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center',
                      }}
                    >
                      {totalSelfAppraisalStatus}<br /> Total
                    </div>
                  </div>

                  <div className="flex justify-center w-full space-x-4">
                    <div className="flex items-center space-x-2">
                      <span className="w-3 h-3 bg-green-500 rounded-full"></span>
                      <div className="flex items-center space-x-1">
                        <p className="font-medium ">{getDashboardOverviewStatusData && getDashboardOverviewStatusData.selfAppraisalStatus && getDashboardOverviewStatusData.selfAppraisalStatus.completed ? getDashboardOverviewStatusData.selfAppraisalStatus.completed : "0"}</p>{" "}
                        <p className="text-sm text-[#212121]">
                          {" "}
                          Completed
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center space-x-2">
                      <span className="w-3 h-3 bg-yellow-400 rounded-full"></span>
                      <div className="flex items-center space-x-1">
                        <p className="font-medium ">{getDashboardOverviewStatusData && getDashboardOverviewStatusData.selfAppraisalStatus && getDashboardOverviewStatusData.selfAppraisalStatus.pending ? getDashboardOverviewStatusData.selfAppraisalStatus.pending : "0"}</p>{" "}
                        <p className="text-sm text-[#212121]">Pending</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {chartCard("Self Appraisal Status",activeCard,totalSelfAppraisalStatus,getDashboardOverviewStatusData,"selfAppraisalStatus","selfAppraisal")}

              {chartCard("Multi-rater Status",activeCard,totalMultiRaterStatus,getDashboardOverviewStatusData,"multiRaterStatus","multiRater")}

              {chartCard("Manager Review Status",activeCard,totalReviewStatus,getDashboardOverviewStatusData,"reviewStatus","review")}

{/* 
              <div className="p-6 transition duration-150 bg-white border rounded-lg cursor-pointer hover:shadow-lg">
                <div className={activeCard === 'multiRater' ? 'active' : 'space-y-6'}
                // onClick={() => this.handleTabChange(this.props.match.params.id, "CardSelected")}
                >
                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Multi-rater Status</h1>
                  <div className="flex justify-center percent">
                    <div className={`flex items-center justify-center ${(getDashboardOverviewStatusData && getDashboardOverviewStatusData.multiRaterStatus && getDashboardOverviewStatusData.multiRaterStatus.pending) > (getDashboardOverviewStatusData && getDashboardOverviewStatusData.multiRaterStatus && getDashboardOverviewStatusData.multiRaterStatus.completed) ? `bg-yellow-400` : `bg-green-500`} rounded-full w-44 h-44 `}
                    // style={{ background: "rgb(34, 201, 104)" }}
                    >
                      <div className="flex items-center justify-center w-40 h-40 bg-white rounded-full">
                        <div className="text-2xl font-semibold text-center" style={{ color: (getDashboardOverviewStatusData && getDashboardOverviewStatusData.multiRaterStatus && getDashboardOverviewStatusData.multiRaterStatus.pending) > (getDashboardOverviewStatusData && getDashboardOverviewStatusData.multiRaterStatus && getDashboardOverviewStatusData.multiRaterStatus.completed) ? 'rgb(231, 194, 11)' : "rgb(34, 201, 104)" }}>
                          {totalMultiRaterStatus}<br /><span className="text-base font-normal text-black">Total</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center w-full space-x-4">
                    <div className="flex items-center space-x-2">
                      <span className="w-3 h-3 bg-green-500 rounded-full"></span>
                      <div className="flex items-center space-x-1">
                        <p className="font-medium ">{getDashboardOverviewStatusData && getDashboardOverviewStatusData.multiRaterStatus && getDashboardOverviewStatusData.multiRaterStatus.completed ? getDashboardOverviewStatusData.multiRaterStatus.completed : "0"} </p>{" "}
                        <p className="text-sm text-[#212121]">
                          {" "}
                          Completed
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center space-x-2">
                      <span className="w-3 h-3 bg-yellow-400 rounded-full"></span>
                      <div className="flex items-center space-x-1">
                        <p className="font-medium ">{getDashboardOverviewStatusData && getDashboardOverviewStatusData.multiRaterStatus && getDashboardOverviewStatusData.multiRaterStatus.pending ? getDashboardOverviewStatusData.multiRaterStatus.pending : "0"} </p>{" "}
                        <p className="text-sm text-[#212121]"> Pending</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              <div className="p-6 transition duration-150 bg-white border rounded-lg cursor-pointer hover:shadow-lg">
                <div className={activeCard === 'review' ? 'active' : 'space-y-6'}
                >
                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Manager Review Status</h1>
                  <div className="flex justify-center percent">
                    <div className={`flex items-center justify-center ${(getDashboardOverviewStatusData && getDashboardOverviewStatusData.reviewStatus && getDashboardOverviewStatusData.reviewStatus.pending) > (getDashboardOverviewStatusData && getDashboardOverviewStatusData.reviewStatus && getDashboardOverviewStatusData.reviewStatus.completed) ? `bg-yellow-400` : `bg-green-500`} rounded-full w-44 h-44`}
                    >
                      <div className="flex items-center justify-center w-40 h-40 bg-white rounded-full">
                        <div className="text-2xl font-semibold text-center "
                          style={{ color: (getDashboardOverviewStatusData && getDashboardOverviewStatusData.reviewStatus && getDashboardOverviewStatusData.reviewStatus.pending) > (getDashboardOverviewStatusData && getDashboardOverviewStatusData.reviewStatus && getDashboardOverviewStatusData.reviewStatus.completed) ? 'rgb(231, 194, 11)' : "rgb(34, 201, 104)" }} >
                          {totalReviewStatus}<br /><span className="text-base font-normal text-black">Total</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center w-full space-x-4">
                    <div className="flex items-center space-x-2">
                      <span className="w-3 h-3 bg-green-500 rounded-full"></span>
                      <div className="flex items-center space-x-1">
                        <p className="font-medium ">{getDashboardOverviewStatusData && getDashboardOverviewStatusData.reviewStatus && getDashboardOverviewStatusData.reviewStatus.completed ? getDashboardOverviewStatusData.reviewStatus.completed : "0"} </p>{" "}
                        <p className="text-sm text-[#212121]">
                          {" "}
                          Completed
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center space-x-2">
                      <span className="w-3 h-3 bg-yellow-400 rounded-full"></span>
                      <div className="flex items-center space-x-1">
                        <p className="font-medium ">{getDashboardOverviewStatusData && getDashboardOverviewStatusData.reviewStatus && getDashboardOverviewStatusData.reviewStatus.pending ? getDashboardOverviewStatusData.reviewStatus.pending : "0"} </p>{" "}
                        <p className="text-sm text-[#212121]"> Pending</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

            </div>
          </> :
          <>
            <div className="p-6 space-y-6 bg-white border rounded-md">
              <h1 className="text-xl font-medium text-[#3D405B]">Appraisal Status</h1>
              <div className="overflow-hidden border border-gray-200 rounded-lg ">
                <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse">
                  <thead className="bg-white">
                    <tr className="bg-[#f9fafb]">
                      <th scope="col" className="px-6 py-3 font-semibold text-sm text-[#3D405B]">  Sr. No</th>
                      <th scope="col" className="px-6 py-3 font-semibold text-sm text-[#3D405B] w-[40%]">  Employees</th>
                      <th scope="col" className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center">  Self Appraisal Status</th>
                      <th scope="col" className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center">  Multi-rater Status</th>
                      <th scope="col" className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center">  Manager Review</th>
                    </tr>
                  </thead>
                  <tbody className="border-t border-gray-100 divide-y divide-gray-100">

                    {getEMPASData && getEMPASData.length > 0 ?
                      getEMPASData.map((ele, index) => (
                        <>
                          <tr className="bg-white ">
                            <td className="px-6 py-4 ">
                              <div className="flex items-center">
                                <div className="ml-4">
                                  <span className="capitalize text-base font-medium block text-[#3D405B]">
                                    {index + 1}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 w-[40%]">
                              <div className="flex items-center">
                                <img
                                  src="img/newimg/Rectangle 11116.png"
                                  alt=""
                                  className="rounded-full w-14 h-14"
                                />
                                <div className="ml-4">
                                  <span className="capitalize text-base font-medium block text-[#3D405B]">
                                    {ele && ele.reviewer_name ? (ele.reviewer_name) : null}
                                  </span>
                                  <p className="capitalize text-sm  block text-[#3D405B]">
                                    {ele && ele.reviewer_emp_id ? (ele.reviewer_emp_id) : null}
                                  </p>
                                </div>
                              </div>
                            </td>

                            <td className="px-6 py-4">
                              {" "}
                              <div className="flex items-center justify-center">
                                {
                                  ele && ele.status && ele.status.self && ele.status.self.isComplete === true ?
                                    <>
                                      <span className="flex items-center justify-center w-6 h-6 text-white bg-green-500 rounded-full ">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l5 5l10 -10"></path></svg></span>
                                    </> :
                                    <>
                                      <span className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-amber-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clock-hour-4" width={16} height={16} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M12 12l3 2" /><path d="M12 7v5" />
                                        </svg>
                                      </span>
                                    </>
                                }
                              </div>
                            </td>

                            <td className="px-6 py-4">
                              <div className="flex items-center justify-center">
                                {
                                  ele && ele.status && ele.status.multi && ele.status.multi.isComplete === true ?
                                    <>
                                      <span className="flex items-center justify-center w-6 h-6 text-white bg-green-500 rounded-full ">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l5 5l10 -10"></path></svg></span>
                                    </> :
                                    <>
                                      <span className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-amber-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clock-hour-4" width={16} height={16} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M12 12l3 2" /><path d="M12 7v5" />
                                        </svg>
                                      </span>
                                    </>
                                }
                              </div>
                            </td>

                            <td className="px-6 py-4">
                              <div className="flex items-center justify-center">
                                {
                                  ele && ele.status && ele.status.manager && ele.status.manager.isComplete === true ?
                                    <>
                                      <span className="flex items-center justify-center w-6 h-6 text-white bg-green-500 rounded-full ">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l5 5l10 -10"></path></svg></span>
                                    </> :
                                    <>
                                      <span className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-amber-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clock-hour-4" width={16} height={16} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M12 12l3 2" /><path d="M12 7v5" />
                                        </svg>
                                      </span>
                                    </>
                                }
                              </div>
                            </td>

                          </tr>

                        </>

                      )) : null

                    }
                  </tbody>
                </table>
              </div>
            </div>
          </>
        }


      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, pms, survey } = state;
  return {
    loggingIn,
    dashboard,
    users,
    pms,
    survey
  };
}
export default connect(mapStateToProps)(AppraisalCycle);
