import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let { templateId, DemographicValues, DemographicValuesObj, XAxis, AnswerObjOverall, optionList, currFilterValue, currFilterName, getDistributionIndexData, getIndexDriverData, GetParameterLabel } = this.props;

    function GetPerc(ValuesObj, item, type) {
      if (item === "OVERALL") {
        let sum = 0;
        if (optionList && optionList.length > 0) {
          optionList.forEach((opt) => {
            sum += ValuesObj[opt];
          })
        }
        let total = sum;
        let score = (((ValuesObj[type]) * 100) / total)
        return score ? Math.round(score) : "NA"
      }
      else {
        let sum = 0;
        if (optionList && optionList.length > 0) {
          optionList.forEach((opt) => {
            sum += ValuesObj[item][opt]
          });
        }
        let total = sum;
        let score = (((ValuesObj[item][type]) * 100) / total)
        let count = ValuesObj[item]["count"];
        return count > 5 ? (score ? Math.round(score) : 0) : "NA"
      }

    }

    // DemographicValues.sort();


    const customOrder = [
      '0-1 Year', '1-3 Years', '3-5 Years','5-10 Years','10-15 Years','15-20 Years'
    ];
    if(DemographicValues && DemographicValues.length>0){
      DemographicValues.sort((a, b) => customOrder.indexOf(a) - customOrder.indexOf(b));
    }

    const menuOrder = [
      'Option A','Option B','Option C','Option D',
      'Yes','No'
    ];



    let alphaAnswerTemplate = [9]
    if(optionList && optionList.length>0){
      if(alphaAnswerTemplate.includes(templateId)){
        optionList.sort(
          (a, b) => a.charCodeAt(0) - b.charCodeAt(0)
        );
      }else{
        optionList.sort((a, b) => menuOrder.indexOf(a) - menuOrder.indexOf(b));
      }
    }


    

    // console.log('AnswerObjOverallAnswerObjOverallAnswerObjOverallAnswerObjOverall');

    return (
      <>

        <div className='space-y-4 bg-white'>
          {/* <div className='flex flex-row items-center justify-between w-full gap-4'>
            <div className='flex items-center text-[#212121] space-x-2'>
              <p className='p-3 bg-[#ededed] font-semibold'>Question 111</p>
            </div>
          </div>
          <div className='space-y-2 '>
            <h2 className='text-xl font-medium text-[#212121]'>question name</h2>
            <p className='text-base text-[#212121]/80 flex space-x-2 items-center'>
              <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>Email</p>
            </p>
          </div> */}

          <div className='bg-[#f9f9f9] rounded'>

            <div class="space-y-4 text-sm text-slate-800">
              <table class="w-full border-collapse">
                <thead class="text-sm  capitalize bg-[#2196F3]/20">
                  <tr class="border-b-2 border-white">
                    <th class="text-[#212121] font-medium p-4 py-3 text-sm text-left border-r-2 border-white">{XAxis}</th>
                    {optionList && optionList.length > 0 ?
                      optionList.map((item) =>
                        <th class="text-[#212121] font-medium p-4 py-3 text-sm text-center border-r-2 border-white">{item + "(%)"}</th>
                      ) : null}
                  </tr>
                </thead>
                <tbody class="divide-y-2 divide-white ">
                  <tr class="even:bg-gray-100 odd:bg-gray-50">
                    <td class="p-2 px-4 border-r-2 border-white"><p class="font-medium ">{"OVERALL"}</p></td>
                    {optionList && optionList.length > 0 ?
                      optionList.map((item) =>
                        <td class="p-2 px-4 text-center border-r-2 border-white">{GetPerc(AnswerObjOverall, "OVERALL", item)}</td>
                      ) : null}
                  </tr>

                  {DemographicValues && DemographicValues.length > 0 ?
                    DemographicValues.map((item, index) =>
                      (item) ?
                        <tr class="even:bg-gray-100 odd:bg-gray-50">
                          <td class="p-2 px-4 border-r-2 border-white"><p class="font-medium">{item}</p></td>
                          {optionList && optionList.length > 0 ?
                            optionList.map((Opt) =>
                              <td class="p-2 px-4 text-center border-r-2 border-white">{GetPerc(DemographicValuesObj, item, Opt)}</td>
                            ) : null}
                        </tr>
                        : null
                    ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>

    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
