import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class DriverRadar extends Component {
  constructor(props) {
    super(props);
    this.state = {


    }
  }

  componentDidMount() {

  }


  render() {
    let { getColorByRelation, get360DimensionRelationScoreData, sequence_rel } = this.props;

    let ValueList = [];
    let DimensionList = [];
    let RelationList = [];
    let RelationListMain = [];

    let finalList = [];


    if (get360DimensionRelationScoreData && get360DimensionRelationScoreData.length > 0) {
      let listRelationScore = get360DimensionRelationScoreData;

      try {
        listRelationScore = get360DimensionRelationScoreData.sort((b, a) => (a._id.length - b._id.length));
      } catch (e) {
        listRelationScore = get360DimensionRelationScoreData;
      }

      listRelationScore.forEach((item) => {
        let dimension1 = item && item._id ? item._id : "";
        let dimension = dimension1;
        let temp = {
          "dimen_xyz": dimension
        }

        let relValues = []
        if (item && item.total && item.total.length > 0) {
          item.total.forEach((inner) => {
            if (inner && inner._id && inner._id.relation) {
              let scoreMean1 = inner && inner.mean ? inner.mean : 0;
              let scoreMean = scoreMean1.toFixed(2);
              temp[inner._id.relation] = scoreMean;
              let getIndex2 = RelationList.findIndex(prev => prev.name === inner._id.relation);

              if (getIndex2 === -1) {
                let tempx = {
                  name: inner._id.relation,
                  value: [scoreMean]
                }
                RelationList.push(tempx);
                RelationListMain.push(tempx)

              } else {
                RelationList[getIndex2]["value"].push(scoreMean)
              }


              let relationName = inner._id.relation;

              let tempx = {
                name: relationName,
                value: parseFloat(scoreMean),
                itemStyle: {
                  color: getColorByRelation(relationName)
                },
                children: [
                  {
                    name: scoreMean.toString(),
                    value: parseFloat(scoreMean),
                    itemStyle: {
                      color: getColorByRelation(relationName)
                    },
                  }
                ]
              }
              relValues.push(tempx)




            }
          })
        }
        DimensionList.push({ name: dimension, max: 6 });

        ValueList.push(temp);


        let temp_xyz = {
          name: dimension ? (dimension.replace(" ", "\n")).replace("_", "\n") : "",
          itemStyle: {
            color: '#558b2f',
            fontWeight: 'bold'
          },
          children: relValues
        }
        finalList.push(temp_xyz)

      })
    }

    function sort2Order(fun1, fun2) {
      let list = []
      if (fun1 && fun1.length > 0) {
        list = fun1.sort((a, b) => fun2.indexOf(a.name) - fun2.indexOf(b.name));
      }
      return list
    }
    
    let RelationListMainX = sort2Order(RelationListMain,sequence_rel)

    let colorList = []
    if (RelationListMainX && RelationListMainX.length > 0) {
      RelationListMainX.forEach((ele) => {
        colorList.push(getColorByRelation(ele.name));
      })
    }




    console.log('ValueList', ValueList);
    console.log('finalList', finalList);


    console.log("finalList---------->", finalList)





    return (
      <>


        <div className="bg-white  rounded-md   cursor-pointer">
          <div className='space-y-6' >
            {/* <h1 className="font-medium text-lg ">Sunburst Chart</h1> */}

            <div className="p-4 bg-white">
              <div className='space-y-6'>
                <h1 className="text-lg font-medium"></h1>

                {get360DimensionRelationScoreData && get360DimensionRelationScoreData.length > 0 ?
                  <div className="flex items-center justify-center w-full pb-4">


                    <ReactEcharts className='w-full  mx-auto'
                      style={{ height: '440px' }}
                      option={
                        {
                          tooltip: {
                            show: true,
                            trigger: 'item'
                          },
                          color: colorList,
                          legend: {
                            left: 'center',
                            data: RelationListMainX,
                            top: -5,
                            Bottom: 5
                          },

                          radar: {
                            // shape: 'circle',
                            indicator: DimensionList
                          },
                          textStyle: {
                            color: '#000',
                            fontStyle: 'bold'
                          },
                          series: [
                            {

                              name: 'Relation',
                              type: 'radar',
                              data: RelationList
                            }
                          ]
                        }}
                    />


                    {/* <ReactEcharts
                      className='flex items-center justify-center w-full mx-auto'
                      style={{ height: '600px' }}
                      option={{
                        tooltip: {
                          trigger: 'item',
                          formatter: '{b}: {c}'
                        },
                        series: {
                          type: 'sunburst',
                          data: finalList,
                          radius: [0, '95%'],
                          sort: undefined,
                          emphasis: {
                            focus: 'ancestor'
                          },
                          levels: [
                            {},
                            {
                              r0: '10%',
                              r: '65%',
                              itemStyle: {
                                borderWidth: 2
                              },
                              label: {
                                rotate: 'tangential'
                              }
                            },
                            {
                              r0: '45%',
                              r: '90%',
                              label: {
                                align: 'right'
                              }
                            },
                            {
                              r0: '92%',
                              r: '90%',
                              label: {
                                position: 'outside',
                                padding: 3,
                                silent: false
                              },
                              itemStyle: {
                                borderWidth: 3
                              }
                            }
                          ]
                        }
                      }}
                    /> */}


                  </div>
                  :
                  <>
                    <div className="pt-40 text-3xl text-center text-gray-400">Data Not Available!</div>
                  </>}


              </div>
            </div>


          </div>
        </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DriverRadar);
