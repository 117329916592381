import React, { Component } from 'react';
import { connect } from 'react-redux';
import ExcelDataList from './Components/ExcelDataList';


class AdvanceComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 1,
      DimensionList: [
        {
          index: 1,
          name: 'Happiness',
          cutOff: 59
        },
        {
          index: 2,
          name: 'Engagement',
          cutOff: 78

        },
        {
          index: 4,
          name: 'Stress',
          cutOff: 28
        },
        {
          index: 5,
          name: 'Manager',
          cutOff: 28

        },
        {
          index: 6,
          name: 'Leader',
          cutOff: 28

        }
      ],
    }
  }
  componentDidMount() {

  }

  onOptionType = (val, validateList) => {
    let { } = this.props;

    this.setState({ optionType: val });
  }

  onSwitchOptionScreen = () => {
    let { clearDownloadExcel, EmpFilterData, getIndexByFilterDMGData, getEMPFilterLabel, getQuestionWiseExcelData, handleExcelDownload,getQuesDistributionIndexOrgData, companyName } = this.props;

    let { optionType } = this.state;

    switch (optionType) {


      case 1: return <ExcelDataList 
        getQuestionWiseExcelData={getQuestionWiseExcelData}
        handleExcelDownload={handleExcelDownload}
        getQuesDistributionIndexOrgData={getQuesDistributionIndexOrgData}
        companyName={companyName}

        EmpFilterData={EmpFilterData} 
        getIndexByFilterDMGData={getIndexByFilterDMGData}
        getEMPFilterLabel={getEMPFilterLabel}
        clearDownloadExcel={clearDownloadExcel}
      />

      default: return <div>Default Option</div>
    }
  }

  render() {
    let { CurrentSurveyId, indexType, getDemographicIndexData } = this.props;
    let { optionType } = this.state;
    console.log('validateList------------------------->validateList');
    return (
      <>
        <div className="md:p-6 p-4 space-y-6 w-full flex-1 overflow-y-auto bg-[#f9fafb]">
          {this.onSwitchOptionScreen()}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AdvanceComparision);
