export const riskdashtype2Constants = {

    RISKDASH_GET_SURVEY_LIST_REQUEST:'RISKDASH_GET_SURVEY_LIST_REQUEST',
    RISKDASH_GET_SURVEY_LIST_SUCCESS: 'RISKDASH_GET_SURVEY_LIST_SUCCESS',
    RISKDASH_GET_SURVEY_LIST_FAILURE: 'RISKDASH_GET_SURVEY_LIST_FAILURE',

    RISKDASH_GET_DASHBOARD_CARD_REQUEST:'RISKDASH_GET_DASHBOARD_CARD_REQUEST',
    RISKDASH_GET_DASHBOARD_CARD_SUCCESS: 'RISKDASH_GET_DASHBOARD_CARD_SUCCESS',
    RISKDASH_GET_DASHBOARD_CARD_FAILURE: 'RISKDASH_GET_DASHBOARD_CARD_FAILURE',

    RISKDASH_GET_QUESTION_DIST_REQUEST:'RISKDASH_GET_QUESTION_DIST_REQUEST',
    RISKDASH_GET_QUESTION_DIST_SUCCESS: 'RISKDASH_GET_QUESTION_DIST_SUCCESS',
    RISKDASH_GET_QUESTION_DIST_FAILURE: 'RISKDASH_GET_QUESTION_DIST_FAILURE',

    RISKDASH_GET_QUESTION_DIST_OVERALL_REQUEST:'RISKDASH_GET_QUESTION_DIST_OVERALL_REQUEST',
    RISKDASH_GET_QUESTION_DIST_OVERALL_SUCCESS: 'RISKDASH_GET_QUESTION_DIST_OVERALL_SUCCESS',
    RISKDASH_GET_QUESTION_DIST_OVERALL_FAILURE: 'RISKDASH_GET_QUESTION_DIST_OVERALL_FAILURE',

    RISKDASH_GET_MEAN_SCORE_REQUEST: 'RISKDASH_GET_MEAN_SCORE_REQUEST',
    RISKDASH_GET_MEAN_SCORE_SUCCESS: 'RISKDASH_GET_MEAN_SCORE_SUCCESS',
    RISKDASH_GET_MEAN_SCORE_FAILURE: 'RISKDASH_GET_MEAN_SCORE_FAILURE',

    RISKDASH_GET_MEAN_SCORE_QWISE_REQUEST: 'RISKDASH_GET_MEAN_SCORE_QWISE_REQUEST',
    RISKDASH_GET_MEAN_SCORE_QWISE_SUCCESS: 'RISKDASH_GET_MEAN_SCORE_QWISE_SUCCESS',
    RISKDASH_GET_MEAN_SCORE_QWISE_FAILURE: 'RISKDASH_GET_MEAN_SCORE_QWISE_FAILURE',
    
    RISKDASH_GET_QUES_NPS_RESULT_REQUEST: 'RISKDASH_GET_QUES_NPS_RESULT_REQUEST',
    RISKDASH_GET_QUES_NPS_RESULT_SUCCESS: 'RISKDASH_GET_QUES_NPS_RESULT_SUCCESS',
    RISKDASH_GET_QUES_NPS_RESULT_FAILURE: 'RISKDASH_GET_QUES_NPS_RESULT_FAILURE',

    RISKDASH_GET_QUES_OTHERS_RESULT_REQUEST: 'RISKDASH_GET_QUES_OTHERS_RESULT_REQUEST',
    RISKDASH_GET_QUES_OTHERS_RESULT_SUCCESS: 'RISKDASH_GET_QUES_OTHERS_RESULT_SUCCESS',
    RISKDASH_GET_QUES_OTHERS_RESULT_FAILURE: 'RISKDASH_GET_QUES_OTHERS_RESULT_FAILURE',

    RISKDASH_GET_QUES_FILTER_OTHERS_RESULT_REQUEST: 'RISKDASH_GET_QUES_FILTER_OTHERS_RESULT_REQUEST',
    RISKDASH_GET_QUES_FILTER_OTHERS_RESULT_SUCCESS: 'RISKDASH_GET_QUES_FILTER_OTHERS_RESULT_SUCCESS',
    RISKDASH_GET_QUES_FILTER_OTHERS_RESULT_FAILURE: 'RISKDASH_GET_QUES_FILTER_OTHERS_RESULT_FAILURE',

    RISKDASH_GET_QUES_TYPE1_RESULT_REQUEST: 'RISKDASH_GET_QUES_TYPE1_RESULT_REQUEST',
    RISKDASH_GET_QUES_TYPE1_RESULT_SUCCESS: 'RISKDASH_GET_QUES_TYPE1_RESULT_SUCCESS',
    RISKDASH_GET_QUES_TYPE1_RESULT_FAILURE: 'RISKDASH_GET_QUES_TYPE1_RESULT_FAILURE',

    RISKDASH_GET_QUES_2_FILTER_OTHERS_RESULT_REQUEST: 'RISKDASH_GET_QUES_2_FILTER_OTHERS_RESULT_REQUEST',
    RISKDASH_GET_QUES_2_FILTER_OTHERS_RESULT_SUCCESS: 'RISKDASH_GET_QUES_2_FILTER_OTHERS_RESULT_SUCCESS',
    RISKDASH_GET_QUES_2_FILTER_OTHERS_RESULT_FAILURE: 'RISKDASH_GET_QUES_2_FILTER_OTHERS_RESULT_FAILURE',

    RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_REQUEST: 'RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_REQUEST',
    RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_SUCCESS: 'RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_SUCCESS',
    RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_FAILURE: 'RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_FAILURE',


    RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_2_REQUEST: 'RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_2_REQUEST',
    RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_2_SUCCESS: 'RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_2_SUCCESS',
    RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_2_FAILURE: 'RISKDASH_GET_QUES_BRANCHCODE_TYPE1_RESULT_2_FAILURE',


    RISKDASH_GET_INDEX_BY_FILTER_DMG_X_REQUEST: 'RISKDASH_GET_INDEX_BY_FILTER_DMG_X_REQUEST',
    RISKDASH_GET_INDEX_BY_FILTER_DMG_X_SUCCESS: ' RISKDASH_GET_INDEX_BY_FILTER_DMG_X_SUCCESS',
    RISKDASH_GET_INDEX_BY_FILTER_DMG_X_FAILURE: ' RISKDASH_GET_INDEX_BY_FILTER_DMG_X_FAILURE',

    RISKDASH_GET_QUES_MULTI_RESULT_REQUEST: 'RISKDASH_GET_QUES_MULTI_RESULT_REQUEST',
    RISKDASH_GET_QUES_MULTI_RESULT_SUCCESS: ' RISKDASH_GET_QUES_MULTI_RESULT_SUCCESS',
    RISKDASH_GET_QUES_MULTI_RESULT_FAILURE: ' RISKDASH_GET_QUES_MULTI_RESULT_FAILURE',

    SURVEY_QUESTIONS_RISKDASH_GETALL_REQUEST:'SURVEY_QUESTIONS_RISKDASH_GETALL_REQUEST',
    SURVEY_QUESTIONS_RISKDASH_GETALL_SUCCESS: 'SURVEY_QUESTIONS_RISKDASH_GETALL_SUCCESS',
    SURVEY_QUESTIONS_RISKDASH_GETALL_FAILURE: 'SURVEY_QUESTIONS_RISKDASH_GETALL_FAILURE',

    RISK_RESPONDANT_EMPLOYEES_REQUEST:'RISK_RESPONDANT_EMPLOYEES_REQUEST',
    RISK_RESPONDANT_EMPLOYEES_SUCCESS: 'RISK_RESPONDANT_EMPLOYEES_SUCCESS',
    RISK_RESPONDANT_EMPLOYEES_FAILURE: 'RISK_RESPONDANT_EMPLOYEES_FAILURE',

    RISK_EMP_RESPONSES_REQUEST:'RISK_EMP_RESPONSES_REQUEST',
    RISK_EMP_RESPONSES_SUCCESS: 'RISK_EMP_RESPONSES_SUCCESS',
    RISK_EMP_RESPONSES_FAILURE: 'RISK_EMP_RESPONSES_FAILURE',

    RISK_CATEGORY_REQUEST:'RISK_CATEGORY_REQUEST',
    RISK_CATEGORY_SUCCESS: 'RISK_CATEGORY_SUCCESS',
    RISK_CATEGORY_FAILURE: 'RISK_CATEGORY_FAILURE',

    RISK_ALL_EMP_QUES_RESPONSES_REQUEST:'RISK_ALL_EMP_QUES_RESPONSES_REQUEST',
    RISK_ALL_EMP_QUES_RESPONSES_SUCCESS: 'RISK_ALL_EMP_QUES_RESPONSES_SUCCESS',
    RISK_ALL_EMP_QUES_RESPONSES_FAILURE: 'RISK_ALL_EMP_QUES_RESPONSES_FAILURE',

    RISK_FILTER_EMP_QUES_RESPONSES_REQUEST:'RISK_FILTER_EMP_QUES_RESPONSES_REQUEST',
    RISK_FILTER_EMP_QUES_RESPONSES_SUCCESS: 'RISK_FILTER_EMP_QUES_RESPONSES_SUCCESS',
    RISK_FILTER_EMP_QUES_RESPONSES_FAILURE: 'RISK_FILTER_EMP_QUES_RESPONSES_FAILURE',

    RISK_ACTION_PLAN_REQUEST:'RISK_ACTION_PLAN_REQUEST',
    RISK_ACTION_PLAN_SUCCESS: 'RISK_ACTION_PLAN_SUCCESS',
    RISK_ACTION_PLAN_FAILURE: 'RISK_ACTION_PLAN_FAILURE',

    GET_ALL_RISK_EMP_CONVERATION_REQUEST:'GET_ALL_RISK_EMP_CONVERATION_REQUEST',
    GET_ALL_RISK_EMP_CONVERATION_SUCCESS: 'GET_ALL_RISK_EMP_CONVERATION_SUCCESS',
    GET_ALL_RISK_EMP_CONVERATION_FAILURE: 'GET_ALL_RISK_EMP_CONVERATION_FAILURE',

    RISK_EMP_QUES_ALL_STAGES_RESPONSES_REQUEST:'RISK_EMP_QUES_ALL_STAGES_RESPONSES_REQUEST',
    RISK_EMP_QUES_ALL_STAGES_RESPONSES_SUCCESS: 'RISK_EMP_QUES_ALL_STAGES_RESPONSES_SUCCESS',
    RISK_EMP_QUES_ALL_STAGES_RESPONSES_FAILURE: 'RISK_EMP_QUES_ALL_STAGES_RESPONSES_FAILURE',

    ON_SCHEDULE_SUBMIT_REQUEST:'ON_SCHEDULE_SUBMIT_REQUEST',
    ON_SCHEDULE_SUBMIT_SUCCESS: 'ON_SCHEDULE_SUBMIT_SUCCESS',
    ON_SCHEDULE_SUBMIT_FAILURE: 'ON_SCHEDULE_SUBMIT_FAILURE',

    SUBMIT_PEOPLE_TOMEET_RESOLVED_REQUEST:'SUBMIT_PEOPLE_TOMEET_RESOLVED_REQUEST',
    SUBMIT_PEOPLE_TOMEET_RESOLVED_SUCCESS: 'SUBMIT_PEOPLE_TOMEET_RESOLVED_SUCCESS',
    SUBMIT_PEOPLE_TOMEET_RESOLVED_FAILURE: 'SUBMIT_PEOPLE_TOMEET_RESOLVED_FAILURE',

    ONBOARDING_EMP_QUES_RESPONSES_REQUEST:'ONBOARDING_EMP_QUES_RESPONSES_REQUEST',
    ONBOARDING_EMP_QUES_RESPONSES_SUCCESS: 'ONBOARDING_EMP_QUES_RESPONSES_SUCCESS',
    ONBOARDING_EMP_QUES_RESPONSES_FAILURE: 'ONBOARDING_EMP_QUES_RESPONSES_FAILURE',

    LIFECYCLE_PTM_EMP_QUES_RESPONSES_REQUEST:'LIFECYCLE_PTM_EMP_QUES_RESPONSES_REQUEST',
    LIFECYCLE_PTM_EMP_QUES_RESPONSES_SUCCESS: 'LIFECYCLE_PTM_EMP_QUES_RESPONSES_SUCCESS',
    LIFECYCLE_PTM_EMP_QUES_RESPONSES_FAILURE: 'LIFECYCLE_PTM_EMP_QUES_RESPONSES_FAILURE',

    LIFECYCLE_PTM_ENG_QUES_RESPONSES_REQUEST:'LIFECYCLE_PTM_ENG_QUES_RESPONSES_REQUEST',
    LIFECYCLE_PTM_ENG_QUES_RESPONSES_SUCCESS: 'LIFECYCLE_PTM_ENG_QUES_RESPONSES_SUCCESS',
    LIFECYCLE_PTM_ENG_QUES_RESPONSES_FAILURE: 'LIFECYCLE_PTM_ENG_QUES_RESPONSES_FAILURE',

    LIFECYCLE_PTM_EMP_QUES_OPEN_ENDED_REQUEST:'LIFECYCLE_PTM_EMP_QUES_OPEN_ENDED_REQUEST',
    LIFECYCLE_PTM_EMP_QUES_OPEN_ENDED_SUCCESS: 'LIFECYCLE_PTM_EMP_QUES_OPEN_ENDED_SUCCESS',
    LIFECYCLE_PTM_EMP_QUES_OPEN_ENDED_FAILURE: 'LIFECYCLE_PTM_EMP_QUES_OPEN_ENDED_FAILURE',

    LIFECYCLE_ENG_INDEX_DISTRIBUTION_REQUEST:'LIFECYCLE_ENG_INDEX_DISTRIBUTION_REQUEST',
    LIFECYCLE_ENG_INDEX_DISTRIBUTION_SUCCESS: 'LIFECYCLE_ENG_INDEX_DISTRIBUTION_SUCCESS',
    LIFECYCLE_ENG_INDEX_DISTRIBUTION_FAILURE: 'LIFECYCLE_ENG_INDEX_DISTRIBUTION_FAILURE',
};

