import React, { Component } from 'react';
import { connect } from 'react-redux';


class RespondantSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      crrSelectedEmp: {}
    }
  }

  componentDidMount() {

  }

  render() {

    let { responseOption } = this.props;

    return (

      <section className="items-center justify-between space-y-10 ">

        <div className='w-full p-6 space-y-4 bg-white report-section'>

          <button class="flex w-full text-left space-x-4">
            <div class="w-full">
              <h4 class="text-xl font-medium text-[#212121]">{"Respondent Summary"}</h4>
            </div>
          </button>



          <div className="w-full flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-[#212121]/5 ">
            <div className="flex items-center w-full ">
              <div className="space-y-2">
                <p className="block text-base w-full uppercase text-[#212121] opacity-75">
                  {responseOption["item1"]["label"]}
                </p>
                <h1 className="text-3xl font-medium w-full text-[#1368B3]">
                  {responseOption["item1"]["value"]}
                </h1>
              </div>
            </div>
            <img src={'/img/icon/sent.svg'}/>
          </div>

          <div className="w-full flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-[#212121]/5 ">
            <div className="flex items-center w-full ">
              <div className="space-y-2">
              <p className="block text-base w-full uppercase text-[#212121] opacity-75">
                  {responseOption["item2"]["label"]}
                </p>
                <h1 className="text-3xl font-medium w-full text-[#1368B3]">
                  {responseOption["item2"]["value"]}
                </h1>

              </div>
            </div>

            <img src={'/img/icon/response.svg'}/>
          </div>

          <div className="w-full flex items-center h-32 p-6 border rounded-lg cursor-pointer  bg-[#212121]/5 ">
            <div className="flex items-center w-full ">
              <div className="space-y-2">
              <p className="block text-base w-full uppercase text-[#212121] opacity-75">
                  {responseOption["item3"]["label"]}
                </p>
                <h1 className="text-3xl font-medium w-full text-[#1368B3]">
                  {responseOption["item3"]["value"]}
                </h1>

              </div>
            </div>
            <img src={'/img/icon/response-rate.svg'}/>
          </div>



        </div>
      </section>

    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(RespondantSummary);
