import React from 'react';
// import { connect } from 'react-redux';



  export default function Filter(props) {

    let { selectedCategory,  EmpFilterData2, getIndexFilterData ,  handleSelectEmpFilter, empResponseList} = props;
    // let { searchName, currFilterList, finalFilter, selectedCategory } = this.state;

    // let [selectedCategory, onSelectedCategory ] = React.useState({
    //   "name":"All",
    //   "label":"Show All"
    // });

    let [showCatgory, onShowCatgory ] = React.useState(false);

    let [showFilter, handleOpenFilter ] = React.useState(false);

    
    const handleSelectEmpCategory=(value)=>{
      let { onSelectedCategory } = props;
      onSelectedCategory(value);
      onShowCatgory(false);
    }
    





    let DemographicValueList = {};
    if(getIndexFilterData){
        DemographicValueList = getIndexFilterData;
        // DemographicValueList.sort();
    }



    console.log('EmpFilterData2: ',EmpFilterData2)
    console.log('DemographicValueList: ',DemographicValueList)




    let {  crrSelectedEmp } = props;


    function getFirstEmp(list){
      if(list && list.length>0){
        return list && list[0] && list[0].name?list[0].name:"-"
      }
      else{
        return "No Employee"
      }
    }



    let categoryList = [
      {
        "name":"All",
        "label":"Show All",
        "color":"#000000"
      },
      {
        "name":"Extremely_High_Risk",
        "label":"Extremely High Risk",
        "color":"#b31717"
      },
      {
        "name":"High_Risk",
        "label":"High Risk",
        "color":"#e65757"
      },
      {
        "name":"Borderline",
        "label":"Borderline",
        "color":"#f29083"
      },
      {
        "name":"Moderate_Risk",
        "label":"Moderate Risk",
        "color":"#edb409"
      },{
        "name":"No_Risk",
        "label":"No Risk",
        "color":"#1bbf36"
      }
    ]



    return (
      <>
        {/* <div className="flex "> */}
          {/* 1 */}











          <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
            
            <p  onClick={()=>onShowCatgory(!showCatgory)} className=" text-xs  px-2">{selectedCategory && selectedCategory.name && (selectedCategory.name!=="All")?selectedCategory.label:'Select Category'}</p> 
            
            
            <span  onClick={()=>onShowCatgory(!showCatgory)} className="material-symbols-outlined ">arrow_drop_down</span>
            {showCatgory?
            <div className="z-10 absolute right-0 top-10 bg-white shadow-lg p-3 font-normal text-xs w-full h-60 overflow-x-auto customscroll2 rounded-md">
              {categoryList && categoryList.length>0?
                categoryList.map((category,index2)=>
                <div key={index2}  className="py-2 m-1 flex items-center cursor-pointer" onClick={()=>handleSelectEmpCategory(category)}>
                    <input type="checkbox" checked={selectedCategory.name === category.name}  onChange={()=>{}}/><label style={{color:category.color}} className="ml-2 font-medium">{category.label}</label> 
                </div>
                ):null}
              </div>
             :null}
          </div>







          <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
            
            <p  onClick={()=>handleOpenFilter(!showFilter)} className="text-xs px-2 ">{
            empResponseList && empResponseList.length>0?
            crrSelectedEmp && crrSelectedEmp.name?crrSelectedEmp.name:(selectedCategory && selectedCategory.name!=="All"?"Select Employee":getFirstEmp(empResponseList))
            // getFirstEmp(empResponseList)
            :"No Employees"
            }</p> 
            
            
            <span  onClick={()=>handleOpenFilter(!showFilter)} className="material-symbols-outlined ">arrow_drop_down</span>
            
            {showFilter && empResponseList && empResponseList.length>0?
            <div className="z-10 absolute right-0 top-10 bg-white shadow-lg p-3 font-normal text-xs w-full h-60 overflow-x-auto customscroll2 rounded-md">
              {empResponseList && empResponseList.length>0?
                empResponseList.map((dmg,index)=>
                <div key={index}  className="py-2 m-1 flex items-center cursor-pointer" onClick={()=>{handleOpenFilter(false);handleSelectEmpFilter(dmg)}}>
                    <input type="checkbox" checked={crrSelectedEmp.emp_id === dmg.emp_id}  onChange={()=>{}}/><label  className="ml-2 font-medium">{dmg.name}</label> 
                </div>
                ):null}
              </div>
             :null}
          </div>
          







        {/* </div> */}
        
      </>
    );
  }
