import React from 'react'
import moment from "moment";

export default function AssessmentBottom5(props) {
    let { crrSelectedEmp, EditButtonFn } = props;




    return (
        <>
            <main className="bg-white p-6 px-6 space-y-6 page-break bg-no-repeat relative h-full px-4">
                <div className="flex items-center w-full justify-between border-b py-4 ">
                    <h1 className="text-3xl font-medium text-[#3D405B]">{EditButtonFn('TalentAssessmentReport', 'Teen TalentPlusStrengths Assessment Report')}</h1>
                    <p className="text-base font-medium">{crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}</p>
                </div>
                <div className="space-y-2">
                    <h1 className="text-3xl font-medium">{EditButtonFn('Bottom5TalentThemes', 'Non-Dominant Teen TalentPlusStrengths Themes')} </h1>
                </div>
                <div className="w-full ">

                    {/* right */}
                    <div className="w-full space-y-4 ">
                        <h1 className="text-2xl font-light text-[#3D405B]">
                            <b>{EditButtonFn('Thesetalentthemesare', "These Teen TalentPlusStrengths themes are your non-dominant talent profile: ")}</b>
                        </h1>
                    </div>
                    <br />
                    <ul className="list-num text-base space-y-4">
                        <li>
                            1. {EditButtonFn('bottomlist1', "It does not mean it is not there with you at all. It just means you are naturally not wired like the behaviours mentioned.")}
                        </li><li>
                            2. {EditButtonFn('bottomlist2', "It does not mean,these are your weakness. It only means that you may not be able to use these naturally.")}
                        </li><li>

                            3. {EditButtonFn('bottomlist3', "Non-Dominant is important for us to know and understand so that we know, who are the people with who we can partner (they may have these themes as dominant)")}
                        </li><li>
                            4. {EditButtonFn('bottomlist4', "It can also help us understand that sometime,we may use our dominant talents to overcome/achieve the outcomes we want to achieved.")}
                        </li><li>

                            5. {EditButtonFn('bottomlist5', "Non-Dominant themes allows us to understand our limitations/make us understand what are some of the things we migh not do naturally.")}
                        </li><li>
                            6. {EditButtonFn('bottomlist6', "Knowing our Non-Dominant can make us understand why we do not appreciate others who might not be like us. Once we understand them, we can actually create great partnership by creating complimentary partnerships.")}
                        </li><li>
                            7. {EditButtonFn('bottomlist7', "Focus should always be on dominant themes. Non-Dominant themes are there to make you understand ways in which you are not wired naturally. ")}
                        </li><li>
                            8.	{EditButtonFn('bottomlist8', "Once you are confident of your reflective learning, you can move on to your proactive learning, where you define your goals and decide which talent theme to use to achieve those goals. It will also give you an idea on how to leverage others who bring a talent which you may not have in sufficient quantity. That will create huge synergy. ")}
                        </li><li>
                            9.	{EditButtonFn('bottomlist9', "When you know your talents and your team members know theirs, time has come to reflect on the team talents. Understanding team talents will allow you to lead your team with confidence and help engage them tremendously. ")}
                        </li><li>
                            10.	{EditButtonFn('bottomlist10', "Remember, living your life with talent is a journey and all journeys takes time. Be patient and keep working on the natural gifts of your life. You will experience thriving life for sure. ")}
                        </li>
                    </ul>


                    <div className="w-full space-y-4 ">

                        <p className="text-md font-light text-[#3D405B]">
                            {EditButtonFn('bottom5footertext1', "As part of the development journey, it is good to know your natural wiring and the way it impacts your thinking,feeling and behaving. We do not need to get stuck with what we may have less of, but should always focus on what we are gifted with.")}</p>

                    </div>



                </div>

                {/* <p>Copyright © 2024, HappyPlus, Inc. All rights reserved</p> */}
            </main>


        </>
    )

}
