import React, { useState } from "react";
export default function AddFilter(props) {
  let [view, onAddFilter] = React.useState(false);

  let {
    closeEmployeeFilter,
    onEmployeeFilterDropdown,
    handleEmployeeColumnSelect,
    handleFilterCheck,
    createEmployeeFilter,
    onDeleteFilter,
    handleFilterStatus,
    handleFilterRank,
    updateformDataFilter,
    updatehandleFilterRank,

    showFilterNames,
    formDataFilter,
    getEmployeeColumnNameData,
    EmployeeFilterCompanyList,
    LanguageObj,
    CurrentLanguage,
    getEmployeeFilterListByCompanyIdData,
    onClickQuesUp,
    onClickQuesDown,
    handleSearch,
    searchName
  } = props;

  let avoidList = [
    "isTest",
    "role",
    "isDisable",
    "companyId",
    "mngr_id",
    "__v",
    "currentStage",
    "actionPlanStage",
    "orgRole",
    "isMNGRMoreThan5",
    "showMNGRReport",
  ];
  console.log(
    "getEmployeeColumnNameData===========>",
    getEmployeeColumnNameData
  );

  
  function tryCatch(valueX, searchNameX) {
    let value1 = true;
    try {
      value1 = valueX && valueX.match(new RegExp(`${searchNameX}`, "gi"));
    } catch (e) {
      return true;
    }
    return value1;
  }



  return (
    <div className=" capitalize overflow-y-auto 2xl:h-[calc(80vh-4.5rem)] xl:h-[calc(100vh-0rem)] h-96 py-2 ">
      <div className="2xl:px-6 px-4">
        <div className=" w-full flex  border px-4 py-0.5 text-[#3D405B]/70 bg-white rounded-md text-sm items-center overflow-hidden my-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-search "
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <circle cx="10" cy="10" r="7"></circle>
            <line x1="21" y1="21" x2="15" y2="15"></line>
          </svg>
          <input
            type="search"
            name="search"
            value={searchName}
            onChange={handleSearch}
            placeholder="Search ..."
            className="w-[calc(100%-0rem)] focus:outline-none px-2 py-2 placeholder:text-[#3D405B]/70  text-[#3D405B] placeholder:font-normal font-medium "
          />
        </div>
      </div>
      <div className="2xl:px-6 px-4 py-2">
        <button className="w-full flex justify-center  text-center cursor-pointer bg-[#2196F3] text-white  text-base  border-[#2196F3]   px-4 items-center  py-2 rounded-md uppercase ">
          Apply
        </button>
      </div>
      {/* {JSON.stringify(empFilter)} */}
      {getEmployeeColumnNameData && getEmployeeColumnNameData.length > 0
        ? getEmployeeColumnNameData.map((item, index) =>
            !avoidList.includes(item.name) ? (
              tryCatch(item.name, searchName) ? (
                <label className="py-2 2xl:px-6 px-4 hover:bg-slate-100 cursor-pointer w-full block">
                  <input
                    className="mr-3 text-[#3D405B] scale-125   "
                    type="checkbox"
                    checked={
                      EmployeeFilterCompanyList.findIndex(
                        (prev) => prev.name === item.name
                      ) !== -1
                    }
                    onChange={() => {}}
                    onClick={() => createEmployeeFilter(item.name)}
                  ></input>
                  {item.name}
                </label>
              ) : null
            ) : null
          )
        : null}
    </div>
  );
}
