import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../_helpers';

//api call
import { dashboardActions, surveyActions, pmsActions, userActions } from '../../_actions';

//libraries
import LoadingOverlay from 'react-loading-overlay';



//Layout

import Header from './Header/Header';
import Sidebar from "./Sidebar/Sidebar";


import * as XLSX from 'xlsx';
import { make_cols } from '../Employees/MakeColumns';
import { SheetJSFT } from '../Employees/types';



// import Overview from './Overview//Overview';
import AppraisalCycleList from './AppraisalCycleList/AppraisalCycleList';
import AddAppraisalCycle from './AddAppraisalCycle/AddAppraisalCycle';


import UploadPreview from './Modal/UploadPreview';


class HappinessDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSection: 'appraisal_cycle_list',
      showAddAppraisalModal: false,
      showSidebar: true,

      stagesList: [
        {
          "id": 1,
          "name": 'Appraisal Cycle Details',
          "done": false
        },
        {
          "id": 2,
          "name": 'Module Settings',
          "done": false
        },
        {
          "id": 3,
          "name": 'Normalization',
          "done": false
        },
        {
          "id": 4,
          "name": 'Raters Setting',
          "done": false
        },
        {
          "id": 5,
          "name": 'Date Control',
          "done": false
        },
        {
          "id": 6,
          "name": 'Applicable For',
          "done": false
        },
        {
          "id": 7,
          "name": 'Submit Appraisal',
          "done": false
        }
      ],

      showUploadPreview: false,
      showRelationTable: false,
      CrrPhaseId: 1,
      searchEmployee: "",
      selectedUploadDoc: ""
    }
  }
  componentDidMount() {

    this.props.dispatch(dashboardActions.getClientProfile());
    this.props.dispatch(dashboardActions.getCompanyNameOnly());
    this.props.dispatch(dashboardActions.PMSAppraisalCycleList())


  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log("nextProps------------>", nextProps)
    if (nextProps.user.PDFUploadSuccess) {

      return {
        ...nextProps,
        selectedUploadDoc: nextProps.user.filePDFDetails.imageUrl

      }

    } else {
      return {
        ...nextProps
      }
    }

  }



  openAddAppraisalCycle = (value) => {
    if (value === 'add_appraisal_cycle') {

      this.props.dispatch(dashboardActions.getPMSModuleNames())
      this.props.dispatch(dashboardActions.getPMSRaterNames())
      this.props.dispatch(dashboardActions.getEmpFilter());
      this.props.dispatch(surveyActions.getIndexByFilterDMG());

    } else if (value === 'appraisal_cycle_list') {
      this.props.dispatch(dashboardActions.PMSAppraisalCycleList())
    } else if (value === 'pms_relationship_list') {
      let data2x2 = {
        "keyWord": "",
        "pageNo": 1,
        "size": 100000,
        "isSense": false
      }

      this.props.dispatch(surveyActions.getPMSRelationList(data2x2));
    }
    this.setState({ showSection: value });
  }


  handleLogout = () => {

  }

  onOverviewFromHeader = () => {
    this.props.history.goBack()
  }

  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  }

  handleSelect = () => {
    let { CrrPhaseId } = this.state;
    if (CrrPhaseId < 7) {
      this.setState({ CrrPhaseId: CrrPhaseId + 1 });
    }
  }



  openAddRelationship = () => {
    this.handleRelationshipUpload();
  }


  handleRelationshipUpload = () => {
    this.setState({ showRelationModal: true })
  }

  closeRelationshipModal = () => {
    this.setState({ showRelationModal: false })
  }


  downLoadSampleCSV = () => {


    let binary_univers = [
      { "reviewer_name": "NA", "reviewer_email": "NA", "reviewer_emp_id": "E002", "reviewee_emp_id": "E002", "reviewer_relation": "Self", "relation_type": "INTERNAL" },
      { "reviewer_name": "NA", "reviewer_email": "NA", "reviewer_emp_id": "E001", "reviewee_emp_id": "E002", "reviewer_relation": "Manager", "relation_type": "INTERNAL" },
      { "reviewer_name": "NA", "reviewer_email": "NA", "reviewer_emp_id": "E004", "reviewee_emp_id": "E002", "reviewer_relation": "Peer", "relation_type": "INTERNAL" },
      { "reviewer_name": "john", "reviewer_email": "empsample1@mailinator.com", "reviewer_emp_id": "NA", "reviewee_emp_id": "E002", "reviewer_relation": "Subordinate", "relation_type": "EXTERNAL" },
      { "reviewer_name": "john", "reviewer_email": "empsample1@mailinator.com", "reviewer_emp_id": "NA", "reviewee_emp_id": "E002", "reviewer_relation": "Subordinate", "relation_type": "EXTERNAL" },
      { "reviewer_name": "john", "reviewer_email": "empsample1@mailinator.com", "reviewer_emp_id": "NA", "reviewee_emp_id": "E002", "reviewer_relation": "Subordinate", "relation_type": "EXTERNAL" }
    ];

    //console.log('binary_univers: ', binary_univers)

    let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

    // Create a new Workbook
    var wb = XLSX.utils.book_new()

    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values')

    // export your excel
    XLSX.writeFile(wb, 'sample relationship.xlsx');
  }




  handleDataChange = (e) => {
    this.props.dispatch(surveyActions.emptyPMSSave());

    this.setState({ loadedDataSheet: [] }, () => {

      const files = e.target.files;
      // //////console.log("files; ",files)

      if (files && files[0]) {
        let fileName = files && files[0] && files[0].name ? files[0].name : "";
        let extName1 = fileName && fileName.substr(fileName.length - 3) ? fileName.substr(fileName.length - 3) : "";
        let extName2 = fileName && fileName.substr(fileName.length - 4) ? fileName.substr(fileName.length - 4) : "";
        if ((extName1 === "csv") || (extName2 === "xlsx")) {

          this.setState({ file: files[0] }, () => {
            //////console.log("this.state.file", this.state.file)
            this.setState({ fileUploadError: "" });



            //----------------------------------------------------------------------------------------------------------------------------------------------------------
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;


            if (this.state.file) {
              if (rABS) {
                reader.readAsBinaryString(this.state.file);
              } else {
                reader.readAsArrayBuffer(this.state.file);
              };
            }

            console.log('rABS--------------------------------------------->', rABS)


            reader.onload = (e) => {
              /* Parse data */
              const bstr = e.target.result;
              const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              console.log('before ws--------------------------------------------->', ws)

              /* Convert array of arrays */
              let data = XLSX.utils.sheet_to_json(ws);
              data = data.map(v => ({ ...v }))



              console.log('data-------------11111-------------------------------->', data)


              setTimeout(() => {
                this.setState({ loadedDataSheet: data, showUploadPreview: true, showRelationModal: false })
              }, 1000)



            };



            //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------



          });

        }
        else {
          this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
          //////console.log("not csv or xlsx file")
        }

      }
      else {
        this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
      }

    })

  };



  saveUploadRelation = () => {
    let { loadedDataSheet } = this.state;
    if (loadedDataSheet && loadedDataSheet.length > 0) {
      let Temp1 = {
        "keyWord": '',
        "pageNo": 1,
        "size": 6
      }

      let finalData = []
      if (loadedDataSheet && loadedDataSheet.length > 0) {
        finalData = loadedDataSheet.map((element) => ({ ...element, 'isSense': false }))
      }
      this.props.dispatch(surveyActions.savePMSRelationList(finalData, Temp1));
    }
    setTimeout(() => {
      this.setState({ showUploadPreview: false })
    }, 2000)
  }


  handleDataFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws);
      // //////console.log("datadatadatadata ", data);
      data = data.map(v => ({ ...v }))
      //////console.log("datasssss-------->", data)
      let flag = 0;
      let locList = [];
      if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          flag = flag + 1;
          locList.push(i + 1);
        }
      }

      //////console.log("flag-------->", flag)

      if (flag && false) {
        this.setState({ isNotMail: true, isNotMailPos: locList })
      }
      else {

        let Temp1 = {
          "keyWord": '',
          "pageNo": 1,
          "size": 100000
        }
        this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
          console.log("this.state.data-------->", this.state.data)

          let finalData = []
          if (this.state.data && this.state.data.length > 0) {
            finalData = this.state.data.map((element) => ({ ...element, 'isSense': false }))
          }

          this.props.dispatch(surveyActions.savePMSRelationList(finalData, Temp1));
          this.setState({ isNotMail: false, isNotMailPos: [] })

        });
      }



    };
    if (this.state.file) {
      if (rABS) {
        reader.readAsBinaryString(this.state.file);
      } else {
        reader.readAsArrayBuffer(this.state.file);
      };
    }
  }


  closeUploadPreview = () => {
    this.setState({ showUploadPreview: false, loadedDataSheet: [] })

  }

  closeRelationshipTable = () => {
    this.setState({ showRelationTable: false })
  }

  handleMyRelationshipTable = () => {
    this.setState({ showRelationTable: true })
  }

  handlePMSDisableStatue = (id) => {
    let data2x2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 1000
    }
    let data = {
      "id": id
    }

  }



  gotoSection = (type, item) => {
    if (type === "dashboard") {
      if (item && item.id) {
        this.props.history.push('/app/pmsdashboard/' + item.id)
      }
    }
  }


  handleContinueAppraisal = (data) => {
    if (data && data.appraisalCycleId) {
      this.props.history.push('/app/pmsemplist/' + data.appraisalCycleId)
    }
  }



  handleSearchEmpRel = (event) => {
    event.preventDefault();
    let { value } = event.target;

    this.setState({ searchEmployee: value }, () => {

      let data2x2 = {
        "keyWord": value,
        "pageNo": 1,
        "size": 100000,
        "isSense": false
      }

      this.props.dispatch(surveyActions.getPMSRelationList(data2x2));



    })

  }


  handleUploadDoc = (event) => {
    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadPDF(event.target.files[0]));
      this.setState({ selectedUploadDoc: null });
    }
    else {
      console.log("No File To Upload!")
    }
  }


  render() {
    let { dashboard, getCompanyNameOnlyData, survey } = this.props;
    let { ClientProfileData } = dashboard;
    let { showSection, stagesList, selectedUploadDoc } = this.state;

    let { loading, showGrid, showRelationTable, loadedDataSheet, showUploadPreview, showRelationModal, crrLinkedCluster, crrLinkedSurveyId, crrLifecycleStageId, showNODashboardModal, showUpdateSurveyModal, updateformSurveyData, showLanguageModal, CurrentLanguageName, CurrentLanguage, crrSurvey, CrrRoute, showSidebar, savePackage, isPackageSelected, showQuestionLibrary, packageList, CurrentSurveyData, showSurveyLibrary, showChooseSurveyModal, showSurveyList, CurrentSurveyName, confirmDeleteSurvey, surveyFormData, showCreateSurveyPopup, surveyValidation, showTab } = this.state;



    let { savePMSRelationListData, getPMSRelationListData, getIndexByFilterDMGData } = survey;


    console.log("stagesList---->", stagesList)
    console.log("selectedUploadDoc------------>", selectedUploadDoc)



    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text='Loading Data...'
          className="loader"
        >
          <div className="h-screen overflow-hidden">
            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              props={this.props}
              companyName={
                getCompanyNameOnlyData && getCompanyNameOnlyData.name
                  ? getCompanyNameOnlyData.name
                  : ""
              }
              sidebarToggle={this.sidebarToggle}
              onOverviewFromHeader={this.onOverviewFromHeader}
            />

            <div className="flex w-full" style={{ height: "calc(100% - 2rem)" }}>

              {/* side bar */}
              <div
                className="fixed top-0 left-0 z-20 hidden w-64 h-screen duration-200 transform bg-white border-r cursor-pointer transition-width lg:block lang lg:relative"
                style={{ height: "calc(100% - 0rem)" }}
              >
                <div className="my-4 whitespace-nowrap ">

                  <div
                    title="Users"
                    className="flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer   border-l-4 border-[#2196F3] bg-[#2196F3]/10"
                  >
                    <div className="flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center">
                      <span className=" material-symbols-outlined">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-users"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          strokeWidth="1.8"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <circle cx={9} cy={7} r={4} />
                          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                        </svg>
                      </span>
                    </div>
                    <span className=" text-sm text-[#2196F3]">Appraisal Cycle</span>
                  </div>
                  {/* <div
                    title="Index & Dimension"
                    className="flex items-center hover:bg-[#2196F3]/10 text-[#3D405B] cursor-pointer"
                  >
                    <div className="flex items-center px-4 py-4 w-16 text-[#3D405B]   h-12 justify-center">
                      <span className=" material-symbols-outlined">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-list"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          strokeWidth="1.8"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1={9} y1={6} x2={20} y2={6} />
                          <line x1={9} y1={12} x2={20} y2={12} />
                          <line x1={9} y1={18} x2={20} y2={18} />
                          <line x1={5} y1={6} x2={5} y2="6.01" />
                          <line x1={5} y1={12} x2={5} y2="12.01" />
                          <line x1={5} y1={18} x2={5} y2="18.01" />
                        </svg>
                      </span>
                    </div>
                    <span className=" text-sm text-[#3D405B] ">Employees</span>
                  </div> */}
                </div>
              </div>


              {showSection === 'add_appraisal_cycle' ?
                <>
                  <AddAppraisalCycle
                    stagesList={stagesList}
                    handleSelect={this.handleSelect}
                    getIndexByFilterDMGData={getIndexByFilterDMGData}
                    CrrPhaseId={this.state.CrrPhaseId}
                    openAddAppraisalCycle={this.openAddAppraisalCycle}
                    handleUploadDoc={this.handleUploadDoc}
                    selectedUploadDoc={selectedUploadDoc}
                  />
                </>
                :
                <main className="flex-1 w-full overflow-hidden overflow-y-auto h-[calc(100vh-4rem)] bg-[#f9fafb] md:p-6 p-4">
                  <div className="w-full mx-auto  space-y-6">
                    <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center"><div className="flex items-center space-x-4 ">
                      <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Appraisal Cycle</h1></div>
                      <div className="md:flex  hidden items-center text-sm text-[#3D405B]">
                        <span className="mx-2 material-symbols-rounded">home</span>
                        <span>/</span>
                        <span className="px-2">PMS</span>
                        <span>/</span>
                        <span className="px-2 font-semibold">Appraisal Cycle</span>
                        {/* <span>/</span>
                        <span className="px-2 font-semibold">Normalization</span> */}
                      </div>
                    </div>


                    <div className="items-center justify-between space-x-6 md:flex">
                      <div className="flex items-center w-full space-x-2 overflow-x-auto border-b whitespace-nowrap">
                        <button
                          onClick={() => this.openAddAppraisalCycle('appraisal_cycle_list')}
                          className={
                            showSection === 'appraisal_cycle_list'
                              ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                              : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-slate-600   rounded-t-md border"
                          }
                        >
                          {"Appraisal Cycle List"}
                        </button>

                        <button
                          onClick={() => this.openAddAppraisalCycle('pms_relationship_list')}
                          className={
                            showSection === 'pms_relationship_list'
                              ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                              : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-slate-600   rounded-t-md border  "
                          }
                        >
                          {"PMS Relationship"}
                        </button>
                      </div>
                    </div>

                    {showSection === 'appraisal_cycle_list' ?
                      <AppraisalCycleList
                        openAddAppraisalCycle={this.openAddAppraisalCycle}
                        gotoSection={this.gotoSection}
                        handleContinueAppraisal={this.handleContinueAppraisal}
                        handleStartAppraisal={this.handleStartAppraisal}
                      />
                      : null}


                    {showSection === 'pms_relationship_list' ?
                      <>
                        <div className="w-full h-full xl:h-screen" >
                          <div className="bg-white rounded-lg shadow-sm ">
                            <div className="space-y-2">
                              {true ?
                                <div className='p-4 space-y-6 bg-white rounded-lg border md:p-6'  >
                                  <div className='justify-between flex md:flex-row flex-col gap-4 '>
                                    <h1 className='font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap'>PMS Relationship</h1>
                                    <div className='flex md:flex-row flex-col gap-2'>

                                      <div className="flex items-center justify-between overflow-hidden text-sm bg-white border rounded-md md:w-auto w-full">
                                        <span
                                          className="p-2 rounded-l-full material-symbols-outlined text-slate-600"
                                          style={{ fontSize: "1.4rem" }}
                                        >
                                          search
                                        </span>
                                        <input
                                          type="search"
                                          name="search"
                                          onChange={this.handleSearchEmpRel}
                                          className="w-full py-2 md:w-96 focus:outline-none placeholder:text-slate-600"
                                          placeholder="Search Reviewer, Reviewee, emp_id"
                                        />
                                      </div>


                                      <div className="flex justify-center md:justify-start">
                                        <button
                                          onClick={() =>
                                            this.handleRelationshipUpload()
                                          }
                                          className="bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] hover:bg-[#1E88E5] transition-all duration-200 md:w-auto w-full md:py-2 py-3  px-4 rounded-md text-center justify-center cursor-pointer transform  flex items-center text-white space-x-1 md:text-sm  text-md whitespace-nowrap"
                                        >
                                          <span className="text-white material-symbols-outlined ">
                                            add
                                          </span>{" "}
                                          <h1 className="">Add PMS Relationship</h1>
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='overflow-x-auto whitespace-nowrap  '>
                                    <table className='w-full border border-collapse text-sm  text-[#3D405B]' >
                                      <tr className='bg-[#f9fafb]'>
                                        <th className='p-2 font-semibold border-r' >S.no.</th>
                                        <th className='p-2 font-semibold border-r' >Reviewer Name</th>
                                        <th className='p-2 font-semibold border-r' >Reviewer Email</th>
                                        <th className='p-2 font-semibold border-r' >{"Reviewer Emp Id"}</th>
                                        <th className='p-2 font-semibold border-r' >{"Reviewee Emp Id"}</th>
                                        <th className='p-2 font-semibold border-r' >Reviewer Relation</th>
                                        <th className='p-2 font-semibold border-r' >Relation Type</th>
                                        <th className='p-2 font-semibold border-r' >Active</th>
                                      </tr>
                                      {getPMSRelationListData && getPMSRelationListData.length > 0 ?
                                        getPMSRelationListData.map((item, index) =>

                                          <tr className='p-2 text-sm bg-white border-t '>
                                            <td className='p-2 text-center text-[#3D405B] border-r'>{(index + 1).toString() + ""}</td>
                                            <td className='p-2 text-center text-sm text-[#3D405B] border-r'>{item && item["reviewer_name"] ? item["reviewer_name"] : "-"}</td>
                                            <td className='p-2 text-center text-sm text-[#3D405B] border-r'>{item && item["reviewer_email"] ? item["reviewer_email"] : "-"}</td>
                                            <td className='p-2 text-center text-sm text-[#3D405B] border-r'>{item && item["reviewer_emp_id"] ? item["reviewer_emp_id"] : "-"}</td>
                                            <td className='p-2 text-center text-sm text-[#3D405B] border-r'>{item && item["reviewee_emp_id"] ? item["reviewee_emp_id"] : "-"}</td>
                                            <td className='p-2 text-center text-sm text-[#3D405B] border-r'>{item && item["reviewer_relation"] ? item["reviewer_relation"] : "-"}</td>
                                            <td className='p-2 text-center text-sm text-[#3D405B] border-r'>{item && item["relation_type"] ? item["relation_type"] : "-"}</td>

                                            <td className='text-center'>
                                              <div className='flex justify-center cursor-pointer'>
                                                {!item.isDisable ?
                                                  <>
                                                    <div onClick={() => this.handlePMSDisableStatue(item.id)} className="rounded-full w-11 h-5 p-0.5 bg-green-400">
                                                      <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform translate-x-3 bg-white rounded-full" />
                                                    </div>
                                                  </>
                                                  :
                                                  <>

                                                    <div onClick={() => this.handlePMSDisableStatue(item.id)} className="rounded-full w-11 h-5 p-0.5 bg-gray-200">
                                                      <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform -translate-x-3 bg-white rounded-full" />
                                                    </div>
                                                  </>
                                                }
                                              </div>
                                            </td>



                                          </tr>

                                        ) : <tr>
                                          <td colSpan={8} className='h-40'> <p className='  flex justify-center text-center text-[#3D405B]/50 text-xl'>Data is loading Please wait ...</p></td>
                                        </tr>}
                                    </table>
                                  </div>

                                </div>
                                : null}

                            </div>
                          </div>
                        </div>
                      </>
                      : null}


                  </div>
                </main>}




            </div>


          </div>







          {showUploadPreview ?
            <>
              <UploadPreview
                loadedDataSheet={loadedDataSheet}
                closeUploadPreview={this.closeUploadPreview}
                saveUploadRelation={this.saveUploadRelation}
                handleDataChange={this.handleDataChange}
                SheetJSFT={SheetJSFT}
                savePMSRelationListData={savePMSRelationListData}


              />
            </>
            : null}




          {showRelationModal ? (
            <div className="fixed top-0 right-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-40 xl:h-screen">
              <div className="w-11/12 mx-auto bg-white rounded-lg shadow-sm xl:w-6/12 lg:w-9/12 ">
                {/* head */}
                <div className="flex items-center justify-between p-4 border-b md:px-8">
                  <div className='space-y-2' >
                    <h2 className="text-lg font-semibold md:text-xl text-[#3D405B]">Upload PMS Partcipants Data</h2>
                    <p className='text-sm text-[#3D405B] opacity-70 md:text-base' > Please upload PMS Participants data according to sample and below guidelines.</p>
                  </div>
                  {/* <h2 className="text-lg font-semibold md:text-xl test-[#3D405B]">Upload PMS Partcipants Data</h2>
                    <p className='text-sm text-gray-500 md:text-base' >Please upload PMS Participants data according to sample and below guidelines.</p> */}
                  <div
                    onClick={() => this.closeRelationshipModal(false)}
                    className="p-2 rounded-full cursor-pointer bg-slate-100 hover:bg-slate-200 "
                  >
                    <svg
                      className="w-6 h-6 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path>
                    </svg>
                  </div>
                </div>

                <div className="m-4 space-y-8 md:m-8 ">
                  {/* data here */}
                  <div className="p-4 space-y-2 text-sm border rounded-md text-[#3D405B]">
                    <h1 className="pb-2 text-lg font-semibold border-b text-[#3D405B]">
                      {" "}  Guidelines for uploading the Participants Data</h1>
                    <ol className="list-disc pl-6 space-y-1.5 py-3 text-[#3D405B] ">
                      <li className=""><b className=''>Reviewer Name:</b> Name of the person who reviews or gives the feedback for an another person.</li>
                      <li className="capitalize"><b className=''>Reviewer Email:</b> Email Id of the reviewer.</li>
                      <li className="capitalize"><b className=''>Reviewer Emp_id:</b> Employee Id of the reviewer.</li>
                      <li className="capitalize"><b className=''>Reviewee Emp_id:</b> A Reviewee Empoyee Id represent a person who receives the feedback from the Reviewer.</li>
                      <li className="capitalize"><b className=''>Reviewer Relation:</b> It defines the relationship between Reviewer and Reviewee.</li>
                      <li className="capitalize"><b className=''>Relation Type:</b> It defines the Reviewer is from inside(INTERNAL) or outside(EXTERNAL) of the organization. </li>
                    </ol>
                    <p>{"1.  Reviewee Emp Id is mandatory"}</p>
                    <p>2. For relation type "INTERNAL", Reviewer Emp Id is mandatory </p>
                    <p>3. For relation type "EXTERNAL", Reviewer Email is mandatory</p>
                    <p>4. Reviewer Relation is mandatory.</p>
                    <p>5. Relation type is mandatory</p>
                    <p>6. Reviewer Relation 'Self' means reviewer and reviewee is same,giving feedback for self</p>
                  </div>

                  <div className="space-y-4">
                    <div className="grid grid-cols-1 gap-4 xl:grid-cols-4 md:grid-cols-2">
                      {/* 1 */}
                      <div
                        onClick={() => this.downLoadSampleCSV()}
                        className="flex items-start p-2 px-4 space-x-4 rounded shadow-sm cursor-pointer 2xl:items-center bg-sky-100 md:col-span-2"
                      >
                        <span class="material-symbols-outlined p-2 bg-white rounded-full text-blue-500 shadow-sm">
                          download
                        </span>
                        <div>
                          <h1 className="text-sm font-medium md:text-base text-[#3D405B] ">Download Sample Data</h1>
                          <p className="text-sm text-[#3D405B]">See the sample data</p>
                        </div>
                      </div>

                      {/* 2 */}
                      <input
                        type="file"
                        id="file"
                        accept={SheetJSFT}
                        onChange={this.handleDataChange}
                        hidden
                      />
                      <label
                        for="file"
                        className="rounded shadow-sm cursor-pointer bg-sky-100 md:col-span-2"
                      >
                        <div className="flex items-start p-2 px-4 space-x-4 2xl:items-center ">
                          <span class="material-symbols-outlined p-2 bg-white rounded-full text-blue-500 shadow-sm">
                            upload
                          </span>
                          <div>
                            <h1 className="text-sm font-medium md:text-base text-[#3D405B] ">
                              Upload Master Data
                            </h1>
                            <p className="text-sm text-[#3D405B]">
                              Upload master data as per the guidelines
                            </p>
                          </div>
                        </div>
                      </label>

                      {/* 3 */}
                      {/* <div className='flex items-start p-2 px-4 space-x-4 bg-blue-100 rounded shadow-md cursor-pointer 2xl:items-center md:col-span-4' >
                                <span class="material-symbols-outlined">
                                  person_off
                                </span>
                                <div>
                                  <h1 className='text-sm font-medium md:text-base' >Add/update/disable employee</h1>
                                  <p className='text-sm text-gray-500' >See the sample format our system</p>
                                </div>
                              </div> */}
                    </div>

                    <div className="hidden">
                      <button
                        onClick={() => this.downLoadSampleCSV()}
                        className=" border border-green-500 rounded-md px-6 py-1.5 text-xs text-green-500 focus:outline-none"
                      >
                        Sample CSV
                      </button>

                      <button
                        onClick={this.handleDataFile}
                        className="ml-22 border border-blue-500 rounded-md px-6 py-1.5 text-xs text-blue-500 focus:outline-none"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}


        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, general, dashboard, survey, questions, happiness, user } = state;
  return {
    loggingIn,
    users,
    general,
    dashboard,
    survey,
    questions,
    happiness,
    user
  };
}
export default connect(mapStateToProps)(HappinessDashboard);
