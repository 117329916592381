import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "./Header/Header";
import { Link } from 'react-router-dom';
import Sidebar from "./Sidebar/Sidebar";
import { CiFilter } from "react-icons/ci";

import { pmsActions } from '../../_actions';
import moment from "moment";

function PMSEmployee(props) {


  useEffect(() => {

    props.dispatch(pmsActions.pmsGetEmpAppraisalList(props));


  }, [])

  let { pms } = props

  let { pmsGetEmpAppraisalList, getPMSAppraisalListStatus } = pms


  let gotoPage = (data) => {
    props.history.push(`/app/pmsemployeegoals/${data.id}`);

    props.dispatch(pmsActions.PMSGetAppraisalDetailsById({ "id": data && data.id }));

  }





  function getCompleteValue(list, appraisalCycleId, fieldName) {
    if (list && list.length > 0) {
      let index = list.findIndex(prev => prev.appraisalCycleId === appraisalCycleId);
      if (index !== -1) {
        return list && list[index] && list[index][fieldName] ? list[index][fieldName] : "";
      }
    }
  }



  function spliceDecimal(no) {
    if (no) {
      let str = no.toString();
      let first = str.split('.')[0];
      let second = str.split('.')[1];
      let second2 = second ? second.substring(0, 2) : ""
      let final = first + '.' + second2;
      if (second2) {
        return parseFloat(final).toFixed(2)
      } else {
        return no
      }
    } else {
      return 0
    }
  }


  function spliceDecimal(no) {
    if (no) {
      let str = no.toString();
      let first = str.split('.')[0];
      let second = str.split('.')[1];
      let second2 = second ? second.substring(0, 2) : ""
      let final = first + '.' + second2;
      if (second2) {
        return parseFloat(final).toFixed(2)
      } else {
        return no
      }
    } else {
      return 0
    }
  }


  return (
    <>
      <div className="h-screen overflow-hidden bg-gray-50">
        <Header
        // sidebarToggle={sidebarToggle}


        />


        <div className="flex w-full" style={{ height: "calc(100% - 2rem)" }}>
          <Sidebar />


          <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto ">
            <div className="md:p-6 p-4 mx-auto  overflow-hidden overflow-y-auto space-y-4 h-[calc(100vh-4rem)] ">
              <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
                <h1 className="md:text-2xl text-xl text-[#3D405B] font-semibold">Appraisal Cycle(s) </h1>
                <div className="flex items-center space-x-3 ">
                  <div className="flex items-center justify-between w-full px-2 space-x-2 overflow-hidden text-sm bg-white border rounded-sm md:w-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search text-gray-600" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" fill="none"  ></path>  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>  <path d="M21 21l-6 -6"></path> </svg>
                    <input type="search" name="search" className="w-full py-2 md:w-48 focus:outline-none placeholder:text-[#212121]/50" placeholder="Search" />
                  </div>

                  <div className="flex justify-center md:justify-start">
                    <button className="w-full px-2 py-2 text-center text-white transition-all duration-200 transform bg-white border rounded-sm cursor-pointer md:w-auto text-md"  >
                      <CiFilter className="w-5 h-5 text-gray-600" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="grid w-full grid-cols-1 gap-2 xl:grid-cols-1 md:grid-cols-2">
                {pmsGetEmpAppraisalList && pmsGetEmpAppraisalList.length > 0 ?
                  pmsGetEmpAppraisalList.map((item, index) =>
                    <div className="flex flex-col items-center justify-center text-center bg-white border rounded-sm">
                      <div class="flex xl:flex-row flex-col gap-2  xl:items-center justify-between py-3 px-4 w-full text-[#212121]">
                        <h1 class="text-start md:text-xl text-lg xl:w-[30%] font-medium ">{item && item.name}</h1>
                        <p class="flex xl:justify-center items-center font-medium md:text-base text-sm ">Submission End Date: {moment(item && item.createdAt).format("DD-MM-YYYY")}</p>
                        <div class="flex justify-center items-center space-x-2 md:text-base text-sm xl:py-0 py-4"><span>Progress</span>
                          <div class="lg:w-48 w-full h-1.5 bg-[#E8E8E8] overflow-hidden rounded-full flex-1">
                            {/* <div class="bg-[#009EA7] w-2/3 h-1.5" style={{ width: spliceDecimal(getCompleteValue(getPMSAppraisalListStatus, item.id, "progress")) + "%" }}></div> */}
                            <div class="bg-[#009EA7] w-2/3 h-1.5" style={{ width: `${getCompleteValue(getPMSAppraisalListStatus, item.id, "progress")}%` }}></div>
                          </div>
                          {/* <span> {spliceDecimal(getCompleteValue(getPMSAppraisalListStatus, item.id, "progress"))}%</span> */}
                          <span>{getCompleteValue(getPMSAppraisalListStatus, item.id, "progress")}%</span>
                        </div>
                        <div class=" flex xl:justify-end justify-start">
                          {

                            getCompleteValue(getPMSAppraisalListStatus, item.id, "progress") == 0 ?
                              <button onClick={() => gotoPage(item)} type="button" className="uppercase font-medium text-base text-[#009EA7] border border-[#009EA7] w-32 py-2 px-2 rounded focus:outline-none focus:shadow-outline">Start</button>
                              :
                              (getCompleteValue(getPMSAppraisalListStatus, item.id, "isComplete") ?
                                <button onClick={() => gotoPage(item)} type="button" className="uppercase font-medium text-base text-[#FFA000] bg-[#FFA000]/10 w-32 py-2 px-2 rounded focus:outline-none focus:shadow-outline">Completed</button>
                                :
                                <button onClick={() => gotoPage(item)} type="button" className="uppercase font-medium text-base text-[#009EA7] border border-[#009EA7] w-32 py-2 px-2 rounded focus:outline-none focus:shadow-outline">InProgress</button>)

                          }

                        </div>
                      </div>
                    </div>
                  ) : null}





              </div>
            </div>
          </main>



        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard, pms } = state;
  return {
    loggingIn,
    survey,
    dashboard,
    pms
  };
}
export default connect(mapStateToProps)(PMSEmployee);