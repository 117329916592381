import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import html2canvas from 'html2canvas';


class ReportWaiseData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      selectedOption: 'Bar chart',

      dimensionValueList: [],
      EmpIndexA: 0,
      EmpIndexB: 4
    }
  }
  componentDidMount() {

  }

  handleDropdownToggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  handleOptionClick = (option) => {
    this.setState({
      selectedOption: option,
      dropdownOpen: false // Close dropdown after selection
    });
  };

  handleDownloadSection = () => {
    let { index } = this.props;
    const sectionContainer = document.querySelector('.report-section' + (index + 1).toString());
    // Use html2canvas to capture the section container as an image
    html2canvas(sectionContainer).then(canvas => {
      // Convert the canvas to a data URL
      const imageUrl = canvas.toDataURL('image/png');
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = 'section.png'; // Set the download filename
      // Simulate a click on the link to trigger the download
      document.body.appendChild(link);
      link.click();
      // Clean up
      document.body.removeChild(link);
    });
  };

  render() {

    let { item, stage, index, onOthersCardClick } = this.props;

    // console.log('Start111111111111111111111111', item);
    let { total } = item ? item : {};

    const customOrder = ['1 Star', '2 Star', '3 Star', '4 Star', '5 Star'];
    total.sort((a, b) => customOrder.indexOf(a._id.answerText + ' Star') - customOrder.indexOf(b._id.answerText + ' Star'));

    const chartData = total.map(item => ({
      name: item._id && item._id.answerText ? item._id.answerText : '',
      value: parseFloat(item && item.count ? Math.round((item.count / total.reduce((acc, curr) => acc + curr.count, 0) * 100)) : 0),
      count: item.count,
    }));

    // Generate xAxis data dynamically
    const xAxisData = total
      .map(item => item._id && item._id.answerText + ' Star')
      .sort((a, b) => customOrder.indexOf(a) - customOrder.indexOf(b));

    let avgCount = 0;
    if (chartData && chartData.length > 0) {
      chartData.forEach(element => {
        avgCount = (parseInt(element.name) * element.count) / chartData.reduce((acc, curr) => acc + curr.count, 0)
      })
    }


    return (

      <div className={'w-full pb-6 space-y-4 bg-white report-section'}>

        <div className={'lg:p-6 p-4 space-y-4 report-section' + (index + 1).toString()}>
          <div className='flex lg:flex-row flex-col-reverse   items-start justify-between w-full gap-4'>
            <div className='flex items-start text-[#212121] space-x-3'>
              <p className='p-3 py-2 bg-[#ededed] font-semibold'>Q.{index + 1}</p>
              <div className=''>
                <h2 className='text-xl font-medium text-[#212121]'>{item && item.question.name ? item.question.name : 'null'}</h2>
                <p className='text-base text-[#212121]/80 flex space-x-2 items-center pt-3'>
                  <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>Rating Scale</p>
                  {/* <b className='font-medium text-[#212121] pr-1'>10</b> out of<b className='font-medium text-[#212121] pr-1'>10</b> answered */}
                </p>
              </div>
            </div>
            <div className='flex items-center flex-shrink-0 space-x-2'>
              <div className='p-3 bg-[#ededed] rounded flex flex-row items-center gap-2 text-[#212121] relative'>
                <span className='flex items-center gap-2' onClick={this.handleDropdownToggle}>
                  {this.state.selectedOption === 'Bar chart' && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                      <path d="M12.1875 2.8125V25.3125H10.3125V6.5625H4.6875V25.3125H2.8125V27.1875H27.1875V25.3125H25.3125V13.125H19.6875V25.3125H17.8125V2.8125H12.1875ZM14.0625 4.6875H15.9375V25.3125H14.0625V4.6875Z" fill="#212121" />
                    </svg>
                  )}
                  {this.state.selectedOption === 'Table' && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                      <path d="M26.25 2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V26.25C2.5 26.5815 2.6317 26.8995 2.86612 27.1339C3.10054 27.3683 3.41848 27.5 3.75 27.5H26.25C26.5815 27.5 26.8995 27.3683 27.1339 27.1339C27.3683 26.8995 27.5 26.5815 27.5 26.25V3.75C27.5 3.41848 27.3683 3.10054 27.1339 2.86612C26.8995 2.6317 26.5815 2.5 26.25 2.5ZM10 25H5V20H10V25ZM10 17.5H5V12.5H10V17.5ZM10 10H5V5H10V10ZM17.5 25H12.5V20H17.5V25ZM17.5 17.5H12.5V12.5H17.5V17.5ZM17.5 10H12.5V5H17.5V10ZM25 25H20V20H25V25ZM25 17.5H20V12.5H25V17.5ZM25 10H20V5H25V10Z" fill="#212121" />
                    </svg>
                  )}
                  <span>{this.state.selectedOption}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor" class="icon icon-tabler icons-tabler-filled icon-tabler-caret-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" /></svg>
                </span>
                {this.state.dropdownOpen && (
                  <div className='absolute left-0 z-20 px-2 bg-white divide-y rounded shadow-xl top-12 w-fit whitespace-nowrap'>
                    <div className="flex items-center flex-shrink-0 space-x-2 text-[#212121] p-3 text-sm" onClick={() => this.handleOptionClick('Bar chart')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                        <path d="M12.1875 2.8125V25.3125H10.3125V6.5625H4.6875V25.3125H2.8125V27.1875H27.1875V25.3125H25.3125V13.125H19.6875V25.3125H17.8125V2.8125H12.1875ZM14.0625 4.6875H15.9375V25.3125H14.0625V4.6875Z" fill="#212121" />
                      </svg>
                      <span>Bar chart</span>
                    </div>
                    <div className="flex items-center flex-shrink-0 space-x-2 text-[#212121] p-3 text-sm" onClick={() => this.handleOptionClick('Table')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                        <path d="M26.25 2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V26.25C2.5 26.5815 2.6317 26.8995 2.86612 27.1339C3.10054 27.3683 3.41848 27.5 3.75 27.5H26.25C26.5815 27.5 26.8995 27.3683 27.1339 27.1339C27.3683 26.8995 27.5 26.5815 27.5 26.25V3.75C27.5 3.41848 27.3683 3.10054 27.1339 2.86612C26.8995 2.6317 26.5815 2.5 26.25 2.5ZM10 25H5V20H10V25ZM10 17.5H5V12.5H10V17.5ZM10 10H5V5H10V10ZM17.5 25H12.5V20H17.5V25ZM17.5 17.5H12.5V12.5H17.5V17.5ZM17.5 10H12.5V5H17.5V10ZM25 25H20V20H25V25ZM25 17.5H20V12.5H25V17.5ZM25 10H20V5H25V10Z" fill="#212121" />
                      </svg>
                      <span>Table</span>
                    </div>
                  </div>
                )}
              </div>
              {/* <button className='p-3 bg-[#ededed] rounded text-[#212121]' onClick={this.handleDownloadSection}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-download"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" /><path d="M7 11l5 5l5 -5" /><path d="M12 4l0 12" /></svg></button> */}
            </div>
          </div>
           

          {this.state.selectedOption === 'Bar chart' && (
            <div className="relative w-full">
              <div>
                <div className='flex items-center space-x-10'>
                  <div className='bg-[#212121]/5 flex flex-col items-center justify-center space-y-4 p-10'>
                    <p className='flex flex-nowrap whitespace-nowrap'>Average Rating</p>
                    <p className='flex items-center text-5xl'>{avgCount} <span className='pl-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                        <path d="M5.28125 23.75L7.3125 14.9688L0.5 9.0625L9.5 8.28125L13 0L16.5 8.28125L25.5 9.0625L18.6875 14.9688L20.7188 23.75L13 19.0938L5.28125 23.75Z" fill="#FFA726" />
                      </svg>
                    </span></p>

                    {/* <div className='flex items-center space-x-4'>
                    {[...Array(5)].map((_, starIndex) => (
                      <div key={starIndex}>
                        {starIndex < parseInt(avgCount) ?
                          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                            <path d="M5.28125 23.75L7.3125 14.9688L0.5 9.0625L9.5 8.28125L13 0L16.5 8.28125L25.5 9.0625L18.6875 14.9688L20.7188 23.75L13 19.0938L5.28125 23.75Z" fill="#FFA726" />
                          </svg>
                          :
                          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                            <path d="M5.28125 23.75L7.3125 14.9688L0.5 9.0625L9.5 8.28125L13 0L16.5 8.28125L25.5 9.0625L18.6875 14.9688L20.7188 23.75L13 19.0938L5.28125 23.75Z" fill="#cccccc" />
                          </svg>}
                      </div>
                    ))}
                  </div> */}

                  </div>

                  <div className='w-full space-y-2'>
                    {xAxisData.map((name, index) => (
                      <div key={index} className='flex items-center space-x-5'>
                        <div className='flex items-center space-x-4'>
                          <p className='flex-shrink-0 w-16'>
                            <span className=''>{name}</span>
                            <b className='font-medium text-[#212121] pr-1'></b>
                          </p>

                          {[...Array(5)].map((_, starIndex) => (
                            <div key={starIndex}>
                              {starIndex < parseInt(name) ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 26 24" fill="none">
                                <path d="M5.28125 23.75L7.3125 14.9688L0.5 9.0625L9.5 8.28125L13 0L16.5 8.28125L25.5 9.0625L18.6875 14.9688L20.7188 23.75L13 19.0938L5.28125 23.75Z" fill="#FFA726" />
                              </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 26 24" fill="none">
                                  <path d="M5.28125 23.75L7.3125 14.9688L0.5 9.0625L9.5 8.28125L13 0L16.5 8.28125L25.5 9.0625L18.6875 14.9688L20.7188 23.75L13 19.0938L5.28125 23.75Z" fill="#cccccc" />
                                </svg>}
                            </div>
                          ))}
                        </div>

                        <div className='flex items-center w-full space-x-6'>
                          <div className='h-8 bg-[#f5f5f5] w-full relative flex items-center space-x-6'>
                            <div className='h-8 bg-[#FFA726] absolute top-0 bottom-0 text-[#212121] flex items-center' style={{ width: `${chartData[index].value}%` }}>
                            </div>
                          </div>
                          <span className='flex-shrink-0 text-[#3D405B] w-10'>{chartData[index] && `${chartData[index].value}%`}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.selectedOption === 'Table' && (
            <div className='bg-[#f9f9f9] p-6 rounded'>
              <table className='w-full'>
                <thead>
                  <tr className='border-b'>
                    <th className='p-4 text-lg font-medium text-left'>Answer Choices</th>
                    <th className='p-4 text-lg font-medium text-left'>Responses</th>
                    <th className='p-4 text-lg font-medium text-left'>Responses (%)</th>
                  </tr>
                </thead>

                <tbody className='divide-y '>
                  {total && total.length > 0 ?
                    total.map((ele) =>
                      <tr className='text-[#212121]'>
                        <td className='p-4 text-left'>{ele && ele._id && ele._id && ele._id.answerText ? ele._id.answerText : ''} Star</td>
                        <td className='p-4 pl-6 text-left'>{ele && ele.count ? ele.count : ''}</td>
                        <td className='p-4 pl-6 text-left'>{ele && ele.count ? (ele.count / total.reduce((acc, curr) => acc + curr.count, 0) * 100).toFixed(0) : 0}%</td>
                      </tr>
                    ) :
                    <tr>
                      <td colSpan={3} className='pt-2 text-center'>Data not found</td>
                    </tr>
                  }
                </tbody>

              </table>
            </div>
          )}
        </div>
        {stage !== 2 ?
          <>
            <button className='flex items-center flex-shrink-0 space-x-2 font-medium text-[#2196F3] p-2 w-fit mx-auto' onClick={() => onOthersCardClick(item)}>
              <span>{stage == 2 ? "View More" : (stage == 3 ? "" : " View More")} </span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg>
            </button>
          </>
          : null}
      </div>


    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ReportWaiseData);
