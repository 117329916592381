import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../_helpers';

//api call
import { surveyActions, dashboardActions, customerActions, questionsActions, generalActions } from '../../_actions';

//libraries
import LoadingOverlay from 'react-loading-overlay';

//Layout

import Header from '../CommonComponents/DashboardHeader/Header';
import Sidebar from './Sidebar/Sidebar';
import Overview from './Overview//Overview';
import Index from './Index/Index';
import TextAnalysis from '../Dashboard/TextAnalysis/TextAnalysis';
// import QuestionDetails from './QuestionDetails/QuestionDetails';

// import QuestionCard from './Overview/Components/QuestionTypeCards/QuestionCard';


import QuestionDetails from '../Dashboard/QuestionDetails/QuestionDetails';
import QuestionCard from '../Dashboard/Overview/Components/QuestionTypeCards/QuestionCard';



class CustomerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IndexNo: 0,
      CurrentSurveyName: '',
      CurrentSurveyId: this.props.match.params.id,
      // "5fa04d316ede0f0336066cce"
      showOverview: true,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      ItemSaveData: '',
      cardType: '',
      currentQuestionId: '',
      currentQuestionTemplate: '',

      itemSurveyReport: false,
      itemOverview: false,
      itemAllQuestions: false,

      showAllQues: false,
      surveysize: 10000,
      crrType2SurveyId: "",
      showOtherTypes:false

    }
  }
  componentDidMount() {

    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }


    // let data2 = {
    //   "surveyId": this.state.CurrentSurveyId
    // }





    this.props.dispatch(questionsActions.getQuestionListBySurveyID({ "surveyId": this.state.CurrentSurveyId }));


    this.props.dispatch(dashboardActions.getClientProfile(data1));

    // this.props.dispatch(customerActions.getSurvey(data1));


    let data2 = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(surveyActions.getSurveyById(data2));

    this.props.dispatch(customerActions.getDashboardCard(data2));

    this.props.dispatch(customerActions.getRespondantDetails_Customers(data2));





    let data3 = {
      // "surveyId": this.state.CurrentSurveyId,
      "filters": []
    }

    let data3x1 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": []
    }

    this.props.dispatch(customerActions.getIndexByFilterDMG_Customer(data3));

    this.props.dispatch(customerActions.getQuestionDistOverall(data3));

    this.props.dispatch(customerActions.getQuestionMatrixResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [13] }));

    this.props.dispatch(customerActions.getQuestionOthersResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [1, 5, 6, 11] }));


    this.props.dispatch(customerActions.getCustFilter());



    this.props.dispatch(dashboardActions.getEmpFilter());
    this.props.dispatch(surveyActions.getIndexByFilterDMG(data3));



    //Employee Dashboard API Call









  }




  ChooseSurvey = (val) => {

    // //console.log('CurrentSurveyId: -------->',val)

    this.setState({ CurrentSurveyId: val })


  }

  onOverview = () => {
    this.setState({
      showOverview: true,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showQuesCard: false,
      CurrentSurveyId: this.state.CurrentSurveyId
    });
    this.props.dispatch(customerActions.getQuestionOthersResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [1, 5, 6, 11] }));

  }


  onCardClick = (cardType, item, index, check) => {

    if (check) {
      let { customer } = this.props;
      let { getCustFilterData } = customer;
      if (getCustFilterData && getCustFilterData.length > 0) {
        this.handleDemographicFilter(getCustFilterData[0].name);
      }
      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showIndex: true,
        showTextAnalysis: false,
        showAdvanceInsight: false,
        showQuesCard: false,
        IndexNo: index,
        cardType: cardType,
        ItemSaveData: item
      });
    }

  }

  handleSidebarClick = (isIndex, data, index, check) => {
    if (isIndex) {
      this.onCardClick('index', data, index, check);
    }
    else {
      this.onOverview()
      // this.setState({
      //   showOverview:true,
      //   showAllQuestions:false,
      //   showIndex:false,
      //   showTextAnalysis:false,
      //   showAdvanceInsight:false,
      //   showQuesCard:false,
      //   IndexNo:0,
      //   ItemSaveData:'',
      //   CurrentSurveyId:this.state.CurrentSurveyId
      // });
    }
  }



  handleSidebarExtra = (type) => {
    if (type === 1) {
      this.setState({
        showOverview: false,
        showOtherTypes:false,
        showAllQuestions: false,
        showIndex: false,
        showTextAnalysis: true,
        showAdvanceInsight: false,
        showQuesCard: false,
        IndexNo: 0
      });

      let data2 = {
        "surveyId": this.state.CurrentSurveyId
      }
      this.props.dispatch(dashboardActions.getTextQuestion(data2, 1));
    }
    else if (type == 2) {
      this.setState({
        showOverview: false,
        showOtherTypes:false,
        showAllQuestions: false,
        showIndex: false,
        showTextAnalysis: false,
        showAdvanceInsight: true,
        showQuesCard: false,
        IndexNo: 0
      });

      // let data2 ={
      //   "surveyId": this.state.CurrentSurveyId
      // }



    }

  }


  //------------------------------------------------------------------------------------------------------------------------
  handleCrossTabApi = (QuesID1, QuesID2, FilterValues) => {

    let data = {
      // "xAxis": "Department",
      "surveyId": this.state.CurrentSurveyId,
      "questionId1": QuesID1.id,
      "questionId2": QuesID2.id,
      "filters": FilterValues
    }
    // this.props.dispatch(dashboardActions.crossTab(data));

    this.props.dispatch(dashboardActions.crossTabAnalysis(data));


  }



  handleDemographicFilter = (value) => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": value
    }
    // this.props.dispatch(customerActions.getMeanScore(data2));
    // this.props.dispatch(customerActions.getMeanScoreQwise(data2));

  }
  handleQuestionMeanScore = (value) => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": value
    }
    // this.props.dispatch(customerActions.getMeanScoreQwise(data2));
  }

  handleDemographicOverallQFilter = (value) => {
    // ////console.log('value handleDemographicOverallQFilter',value)
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": value
    }
    // this.props.dispatch(customerActions.getMeanScoreQwise(data2));

  }


  //------------------------------------------------------------------------------------------------------------------------

  handleQuestion = () => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": []
    }
    this.props.dispatch(customerActions.getQuestionDist(data2));
    this.props.dispatch(customerActions.getQuestionDistOverall(data2));

  }

  handleResponseDistributionFilter = (name, value) => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": [{
        "level": name,
        "value": value
      }]
    }
    this.props.dispatch(customerActions.getQuestionDist(data2));
  }
  //------------------------------------------------------------------------------------------------------------------------
  handleWordCloudDemographicFilter = (QuesId, FilterValues, blank, type) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters

    }

    //////console.log("--------------------------------------------------------->type",QuesId, FilterValues, blank, type)

    if (type === "advance") {
      this.props.dispatch(dashboardActions.AdvanceWordCloud(data2));
    }
    else if (type === "wordCloud") {
      this.props.dispatch(dashboardActions.wordCloud(data2));
    }

    else {
      let data3 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "xAxis": FilterValues && FilterValues.length > 0 ? FilterValues[0].level : "Department"
      }
      this.props.dispatch(dashboardActions.TreeMap(data3));
    }


  }



  callWordCloudText = (QuesId, keyWord, FilterList, FilterValue, isMultiKeyword) => {
    let { CurrentSurveyId } = this.state;


    if (isMultiKeyword) {
      let data2 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "keyWord1": keyWord && keyWord[0] ? keyWord[0] : "",
        "keyWord2": keyWord && keyWord[1] ? keyWord[1] : "",
        "filters": FilterList && FilterList.length > 0 ? FilterList : []
      }
      this.props.dispatch(dashboardActions.wordCloudMultiText(data2));
    }
    else {
      let data2 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "keyWord": keyWord,
        "filters": FilterList && FilterList.length > 0 ? FilterList : []
      }
      this.props.dispatch(dashboardActions.wordCloudText(data2));
    }
  }




  callQuestionWordCloud = (QuesId) => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": []
    }
    this.props.dispatch(dashboardActions.wordCloud(data2));
  }

  onWorldCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 1));

  }



  onNetworkGraph = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 2));

  }


  onAdvanceWorldCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 4));

  }


  onTextWordDistribution = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 5));

  }


  handleNeighborGraph = (FilterList) => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 3, FilterList));

  }


  handleNetworkDemographicFilter = (Qid, FilterValues, graphType) => {

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }


    let { CurrentSurveyId } = this.state;
    let data2 = {};
    data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": Qid,
      "filters": Filters
    }


    // //console.log('----asadata2',data2)

    if (graphType === 1) {
      this.props.dispatch(dashboardActions.cooccurmatrix(data2));
    }
    else if (graphType === 2) {
      this.props.dispatch(dashboardActions.neighborGraph(data2));
    }

  }



  handleOverview = () => {
    // this.overviewAPI();
    if (this.state.showOverview) {
      if (this.state.showAllQuestions) {
        this.setState({
          showOverview: true,
          showAllQuestions: false,
          showIndex: false,
          showTextAnalysis: false,
          showAdvanceInsight: false,
          showQuesCard: false,
          currentQuestionTemplate: 0
        })
      }
      else {
        this.props.history.goBack()
      }
    }
    else {

      if (this.state.showQuesCard) {
        this.setState({
          showOverview: false,
          showAllQuestions: true,
          showIndex: false,
          showTextAnalysis: false,
          showAdvanceInsight: false,
          showQuesCard: false
        })
      }
      else {

        this.onOverview();

        this.handleSidebarClick(false)
      }


    }
  }


  // onNPSCardClick=(item)=>{

  //   //console.log('item:',item);
  //   //console.log('item Qid:',item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"");


  //   this.setState({
  //     showOverview:false,
  //     showIndex:false,
  //     showTextAnalysis:false,
  //     showQuesCard:true,
  //     currentQuestionId:item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"",
  //     currentQuestionTemplate:item.answerList&&item.answerList[0]&&item.answerList[0].template?item.answerList[0].template:""

  //   });
  // }


  onOthersCardClick = (item) => {

    // //console.log('item:',item);
    // //console.log('item Qid:',item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"");


    this.setState({
      showOverview: false,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showQuesCard: true,
      currentQuestionId: item.answerList && item.answerList[0] && item.answerList[0].QuesId ? item.answerList[0].QuesId : "",
      currentQuestionTemplate: item.answerList && item.answerList[0] && item.answerList[0].template ? item.answerList[0].template : ""

    }, () => {
      let { currentQuestionTemplate } = this.state;

      let { customer } = this.props;
      let { getCustFilterData } = customer;

      let AxisInit = "Department";
      if (getCustFilterData && getCustFilterData.length > 0) {
        getCustFilterData.forEach((item, index) => {
          if (item.level && index === 0) {
            AxisInit = item.level;
          }
        });
      }

      if (currentQuestionTemplate !== 13) {
        let data7 = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplateList": [currentQuestionTemplate],
          "xAxis": AxisInit,
          "filters": []
        }
        this.props.dispatch(customerActions.getQuestionOthersResult(data7));
      }

    });



  }




  handleOverViewRoute = (item1, item2, item3) => {

    this.setState({
      itemSurveyReport: item1,
      itemOverview: item2,
      itemAllQuestions: item3
    });


  }




  // handleNPSFilterCall=(FilterValues,isValue)=>{
  //   let XAxis = "";
  //   let Filters = [];
  //   if(FilterValues && FilterValues.length>0){
  //     let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
  //     if(getIndex !== -1){
  //       XAxis = FilterValues[getIndex].level;
  //       FilterValues.forEach((item)=>{
  //         if(item.value !== "All"){
  //           Filters.push(item);
  //         }
  //       });
  //     }
  //     else{
  //       XAxis = FilterValues[FilterValues.length-1].level;
  //       Filters = FilterValues
  //     }

  //   }


  //   let data7 = {
  //     "surveyId": this.state.CurrentSurveyId,
  //     "qtemplateList":[6],
  //     "xAxis":isValue,
  //     "filters":Filters
  //   }

  //   this.props.dispatch(customerActions.getQuestionMatrixResult(data7));

  // }




  handleOthersFilterCall = (FilterValues, isValue, qTemplate, q2Template) => {
    // let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        // XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        // XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }


    //////console.log("--------------qTemplate-------.",qTemplate)

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplateList": [qTemplate],
      "xAxis": isValue,
      "filters": Filters
    }

    if (qTemplate === 13) {
      this.props.dispatch(customerActions.getQuestionMatrixResult(data7));
    }
    else {
      this.props.dispatch(customerActions.getQuestionOthersResultFilter(data7));
    }



    if (q2Template) {



      let data72 = {
        "surveyId": this.state.CurrentSurveyId,
        "qtemplateList": [q2Template],
        "xAxis": isValue,
        "filters": Filters
      }

      this.props.dispatch(customerActions.getQuestion2OthersResultFilter(data72));



    }

  }

  handleQuestionFilterCallType1vsType2 = (FilterValues, isValue, QTemplate2, questionID2) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }






    let data72 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplateList": [6],
      "xAxis": "BranchCode",
      "filters": Filters
    }

    this.props.dispatch(customerActions.getQuestionOthersResult(data72));

    let newFilter = []
    if (Filters && Filters.length > 0) {
      Filters.forEach((item) => {
        newFilter.push(item)
      });
    }
    newFilter.push({
      "level": "Department",
      "value": "Sales - Tied Agency"
    })

    let data7 = {
      "surveyId": this.state.crrType2SurveyId,
      "qID": questionID2,
      "xAxis": XAxis,
      "filters": newFilter
    }

    this.props.dispatch(generalActions.getQuestionWithBranchCodeByQuestionId2(data7));








    // if(qTemplate){
    //   let data7 = {
    //     "surveyId": this.state.crrType2SurveyId,
    //     "qtemplateList":[qTemplate],
    //     "xAxis":isValue,
    //     "filters":Filters
    //   }

    //   if(qTemplate===13){
    //     this.props.dispatch(customerActions.getQuestionMatrixResult(data7));
    //   }
    //   else{
    //     this.props.dispatch(customerActions.getQuestionOthersResultFilter(data7));
    //   }

    // }



    // if(q2Template){



    //   let data72 = {
    //     "surveyId": this.state.crrType2SurveyId,
    //     "qtemplateList":[q2Template],
    //     "xAxis":isValue,
    //     "filters":Filters
    //   }

    //   this.props.dispatch(customerActions.getQuestion2OthersResultFilter(data72));



    // }



  }




  handleQuestionFilterCallType1vsTypeIndex = (FilterValues, isValue, QTemplate2, IndexTemplate) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }


    let data72 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplateList": [6],
      "xAxis": "BranchCode",
      "filters": Filters
    }

    this.props.dispatch(customerActions.getQuestionOthersResult(data72));

    let newFilter = []
    if (Filters && Filters.length > 0) {
      Filters.forEach((item) => {
        newFilter.push(item)
      });
    }
    newFilter.push({
      "level": "Department",
      "value": "Sales - Tied Agency"
    })

    let data7 = {

      "surveyId": this.state.crrType2SurveyId,
      "xAxis": XAxis,
      "filters": newFilter

    }

    this.props.dispatch(dashboardActions.getDemographicBranchCodeIndexMultiFilter(data7));


    // this.props.dispatch(generalActions.getQuestionWithBranchCodeByQuestionId2(data7));
    // this.props.dispatch(generalActions.getQuestionWithBranchCodeByQuestionId2(data7));


  }










  overviewAPI = () => {
    this.props.dispatch(customerActions.getQuestionOthersResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [1, 5, 6, 11] }));
  }




  onCrossTab = () => {

    let data = {
      surveyId: this.state.CurrentSurveyId
    }

    this.props.dispatch(questionsActions.getQuestionListBySurveyID(data));


  }
  handleCrossTabApi = (QuesID1, QuesID2, FilterValues) => {

    let data = {
      // "xAxis": "Department",
      "surveyId": this.state.CurrentSurveyId,
      "questionId1": QuesID1.id,
      "questionId2": QuesID2.id,
      "filters": FilterValues
    }
    // this.props.dispatch(dashboardActions.crossTab(data));

    this.props.dispatch(dashboardActions.crossTabAnalysis(data));

  }


  handleQueCorrelation = (QuesID1, QuesList, FilterValues) => {
    //////console.log("asdasdasd")
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "questionId1": QuesID1.id,
      "questionList": QuesList,
      "filters": FilterValues
    }

    //////console.log("===========handleQueCorrelation============")

    this.props.dispatch(dashboardActions.getCorrelationQuestionType2(data2));

  }




  storeInnerRoute = (value) => {
    this.setState({ showAllQues: value });
  }



  onAllQuestions = (item) => {
    // this.setState({CurrentTemplateId:item.templateId})
    this.setState({
      showOverview: false,
      showAllQuestions: true,
      showOtherTypes:true,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showQuesCard: false,
      currentQuestionTemplate: item.templateId
    })

  }



  onTreeMap = () => {
    this.props.dispatch(dashboardActions.EmptyTreeMap());
  }



  onAllMatrixQuestions = (item) => {
    // this.setState({CurrentTemplateId:item.templateId})
    this.setState({
      showOverview: false,
      showAllQuestions: true,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showQuesCard: false,
      currentQuestionTemplate: 13
    })

  }


  // oMatrixQuestionDetail=()=>{
  //   this.setState({
  //     showOverview:false,
  //     showAllQuestions:false,
  //     showIndex:false,
  //     showTextAnalysis:false,
  //     showAdvanceInsight:false,
  //     showQuesCard:true,
  //     currentQuestionId:ques && ques.id?ques.id:"",
  //     currentQuestionTemplate:13

  //   });
  //   this.props.dispatch(customerActions.getQuestionMatrixResult({surveyId:this.state.CurrentSurveyId,qtemplateList:[13]}));

  // }
  handleMatrixDetails = (ques) => {
    this.setState({
      showOverview: false,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showQuesCard: true,
      currentQuestionId: ques && ques.id ? ques.id : "",
      currentQuestionTemplate: 13

    });
    this.props.dispatch(customerActions.getQuestionMatrixResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [13] }));

  }


  callQuestionNetworkCloud = (QuesId, graphType, FilterValues) => {
    let { CurrentSurveyId } = this.state;


    // let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        // XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        // XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }



    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters && Filters.length > 0 ? Filters : []
    }

    if (graphType === 1) {
      this.props.dispatch(dashboardActions.cooccurmatrix(data2));

    }
    else if (graphType === 2) {
      this.props.dispatch(dashboardActions.neighborGraph(data2));
    }


  }


  handleNPSCustomerQuadrant = () => {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }

    this.props.dispatch(surveyActions.getSurveyList(data));
  }

  handleEmployeeSurvey = (survey) => {
    let data1 = {
      "surveyId": survey && survey.id ? survey.id : ""
    }
    this.setState({ crrType2SurveyId: (survey && survey.id ? survey.id : "") })
    this.props.dispatch(dashboardActions.getIndex(data1));

  }

  handleEmployeeDashIndex = (DashIndex) => {
    let data = {
      surveyId: this.state.crrType2SurveyId
    }

    this.props.dispatch(questionsActions.getType1AllQuestionList(data));
  }
  handleGoBack = () => {
    console.log("handleGoBack")
    let { showOtherTypes, showAllQuestions } = this.state;
    if (showOtherTypes && !showAllQuestions) {
      this.setState({
        showOverview: false,
        showAllQuestions: true,
        showOtherTypes: true
      })
    } else if (showOtherTypes && showAllQuestions) {
      this.setState({
        showOverview: true,
        showAllQuestions: false,
        showOtherTypes: false
      })
    }
  }


  onOverviewFromHeader = () => {
    this.props.history.goBack();

  };
  render() {

    let { customer, dashboard, survey, questions, general } = this.props;

    let { getSurveyData, getIndexByFilterDMGData, getSurveyListData } = survey;
    let { questionsList, getType1AllQuestionListData } = questions;

    let { neighborGraphData, cooccurmatrixData, wordCloudData, wordTextKeyWord, wordMultiTextKeyWord,
      wordCloudTextData, wordCloudMultiTextData, getTextQuestionData, ClientProfileData,
      AdvanceWordCloudData,
      crossTabAnalysisData,
      crossTabData,
      TreeMapData,

      getCorrelationQuestionType2Data,

      textDistData,
      textDistTreeMapData,

      getIndexData,

      getDemographicBranchCodeIndexMultiFilterData




    } = dashboard;


    let EmpFilterDataEmployee = dashboard.EmpFilterData;

    let {
      getCustFilterData, getIndexByFilterDMG_CustomerData, getRespondantDetails_CustomersData,
      getQuestionOthersResultData, getQuestionOthersResultFilterData,
      getQuestionMatrixResultData, SurveyList, getMeanScoreQwiseData,

      getDashboardCardData, getQuestionDistData, getQuestionDistOverallData, getMeanScoreData } = customer;

    let { getQuestionWithBranchCodeByQuestionId2Data, getQuestionWithBranchCodeByQuestionIdData, getQuestion2OthersResultFilterData, getQuestionByQuestionIdResultData } = general;

    let getIndexFilterData = getIndexByFilterDMG_CustomerData
    let getRespondantDetailsData = getRespondantDetails_CustomersData;



    let { showOtherTypes, CurrentSurveyId, ItemSaveData, currentQuestionId, currentQuestionTemplate,

      showOverview, showAllQuestions,
      showTextAnalysis, showAdvanceInsight, showQuesCard, showIndex, IndexNo, cardType

    } = this.state;

    let loading = dashboard.loading || customer.loading;
    // let loading = false;




    ////console.log('/////////////////////////////////////////////////=================================|||=========================>:');
    ////console.log( 'getCorrelationQuestionType2Data ',getCorrelationQuestionType2Data)








    // ////console.log('getDashboardCardData: ',getDashboardCardData)
    // ////console.log('getQuestionDistData: ',getQuestionDistData)

    // ////console.log('showIndex: ',showIndex)
    // ////console.log('showManagerList: ',showManagerList)
    // ////console.log('showAdvanceComparision: ',showAdvanceComparision)
    // ////console.log('showTextAnalysis: ',showTextAnalysis)
    let OverviewList = [];
    let SumMean = 0;
    let OverallMean = 0;
    let total = 0;

    if (getDashboardCardData && getDashboardCardData.length > 0) {
      getDashboardCardData.forEach((item, index) => {
        if (item && item._id) {
          SumMean += parseFloat(item && item.mean ? item.mean : 0.0);
          total += 1;
          OverviewList.push({
            "id": item._id,
            "name": item.parameter && item.parameter.length > 0 && item.parameter[0] && item.parameter[0].name ? item.parameter[0].name : "NaN",
            "mean": (typeof item !== 'string' || item instanceof Number) && item && item.mean ? item.mean.toFixed(2) : 'NaN',
            "color": '#757575'
          });
        }
      });
      OverallMean = (SumMean / total);

    }
    ////////console.log("OverallMean",OverallMean)
    ////////console.log("SumMean",SumMean)
    ////////console.log("total",total)
    ////////console.log("getDashboardCardData",getDashboardCardData)


    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageactionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }

    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    ////console.log('moduleAccess: ',moduleAccess)



    // let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        //SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    //END -------------------------------------------------------




    function CountRestrict(count, isOverall, section) {
      let isCountLessThanFive = false;

      if (count !== "NaN") {
        if (isOverall) {
          isCountLessThanFive = false;
        }
        else {
          if (section === "rwa") {
            if (parseInt(count) < 30) {
              isCountLessThanFive = true;
            }
          }
          else {
            if (parseInt(count) < 5) {
              isCountLessThanFive = true;
            }
          }

        }
      }

      return isCountLessThanFive
    }




    function FormattFun(inputData, isSummary) {
      let RankObj = getSurveyData && getSurveyData.designObj && getSurveyData.designObj.componentRank ? getSurveyData.designObj.componentRank : {}

      let LIST = []
      let prevTemList = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {

          let templateId = ques.total[0]._id.qtemplate;

          let temp = {
            name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
            answerList: [],
            rank: RankObj && RankObj[templateId] ? RankObj[templateId] : 100,
            templateId: templateId
          }
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading: item && item._id && item._id.heading ? item._id.heading : "",
                parentId: item && item._id && item._id.parentId ? item._id.parentId : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId
              }
              temp["answerList"].push(ans);
            });
          }


          if (isSummary) {
            let getIndex = prevTemList.findIndex(prev => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId)
            }
          }
          else {
            LIST.push(temp);
          }


        });
      }
      return LIST
    }


    //  let NPSQuesList = FormattFun(getQuestionNPSResultData);
    let AllQuesList = FormattFun(getQuestionOthersResultData, false).filter(x => x.templateId === currentQuestionTemplate);



    function getEMPFilterLabel(name) {

      let label = name;
      if (getCustFilterData && getCustFilterData.length > 0) {
        let getIndex = getCustFilterData.findIndex(prev => prev.name === name);
        if (getIndex !== 1) {
          label = getCustFilterData[getIndex].label
        }

      }
      return label
    }

    //////console.log("-------getCustFilterData------------",getCustFilterData)


    let EmpFilterData = getCustFilterData;

    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text='Loading Data...'
          className="loader"
        >

          <div>

            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              SurveyList={SurveyList}
              ChooseSurvey={this.ChooseSurvey}
              CurrentSurveyId={CurrentSurveyId}
              props={this.props}
              handleOverview={this.handleOverview}
              onOverviewFromHeader={this.onOverviewFromHeader}

            />

            <div className="fixed flex w-full h-screen bg-gray-200" >
              {accessAllow("dashboard") ?
                <>

                  <Sidebar
                    SurveyList={SurveyList}
                    OverviewList={OverviewList}
                    handleSidebarClick={this.handleSidebarClick}
                    IndexNo={IndexNo}
                    showIndex={showIndex}
                    showOverview={showOverview}
                    showTextAnalysis={showTextAnalysis}
                    showAdvanceInsight={showAdvanceInsight}
                    handleSidebarExtra={this.handleSidebarExtra}
                  />


                  {showOverview ?
                    <Overview
                      CurrentSurveyId={CurrentSurveyId}
                      SurveyList={SurveyList}
                      EmpFilterData={EmpFilterData}
                      OverviewList={OverviewList}
                      OverallMean={OverallMean}
                      onCardClick={this.onCardClick}
                      getMeanScoreQwiseData={getMeanScoreQwiseData}
                      getIndexFilterData={getIndexFilterData}
                      getRespondantDetailsData={getRespondantDetailsData}
                      getQuestionDistOverallData={getQuestionDistOverallData}

                      getQuestionMatrixResultData={getQuestionMatrixResultData}
                      questionsList={questionsList}

                      getQuestionOthersResultData={getQuestionOthersResultData}


                      onOthersCardClick={this.onOthersCardClick}

                      getSurveyData={getSurveyData}
                      overviewAPI={this.overviewAPI}
                      handleMatrixDetails={this.handleMatrixDetails}
                      handleOverViewRoute={this.handleOverViewRoute}

                      storeInnerRoute={this.storeInnerRoute}

                      onAllQuestions={this.onAllQuestions}
                      onAllMatrixQuestions={this.onAllMatrixQuestions}
                    />
                    : null}



                      {showOtherTypes ?

                      <>
                        {showAllQuestions ?
                          <>
                            <main className="flex-1 w-full h-full pb-20 overflow-hidden overflow-y-auto bg-gray-50">

                            <div className="px-2 py-2 bg-gray-50">
                              <div className="h-full p-4 mx-auto overflow-hidden overflow-y-auto ">
                                <div className='flex items-center pb-5 space-x-4'>
                                  <span onClick={() => this.handleGoBack()} class="cursor-pointer material-symbols-outlined bg-blue-100 p-2 rounded-full text-blue-500 ">arrow_back</span>
                                  <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Questions Analysis</h1>
                                </div>
                                <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-2 lg:gap-6">
                                  {AllQuesList && AllQuesList.length > 0
                                    ? AllQuesList.map((item, index) => (
                                      <div
                                        onClick={() =>
                                          this.onOthersCardClick(item)
                                        }
                                        className="p-4 bg-white border"
                                      >
                                        <QuestionCard
                                          item={item}
                                          name={""}
                                          stage={2}
                                          index={index}
                                        />
                                      </div>
                                    ))
                                    : null}
                                </div>
                              </div>
                            </div>
                            </main>
                            </>
                          :
                          <QuestionDetails
                          handleGoBack={this.handleGoBack}
                          EmpFilterData={EmpFilterData}
                            getIndexFilterData={getIndexByFilterDMGData}
                            getQuestionOthersResultData={getQuestionOthersResultData}
                            getQuestionOthersResultFilterData={getQuestionOthersResultFilterData}
                            currentQuestionId={currentQuestionId}
                            currentQuestionTemplate={currentQuestionTemplate}

                            handleOthersFilterCall={this.handleOthersFilterCall}

                          />
                        }
                      </>
                      : null}


                  {showIndex ?
                    <Index
                      ItemSaveData={ItemSaveData}
                      cardType={cardType}

                      handleQuestion={this.handleQuestion}
                      getQuestionDistData={getQuestionDistData}
                      getQuestionDistOverallData={getQuestionDistOverallData}

                      getMeanScoreQwiseData={getMeanScoreQwiseData}
                      handleResponseDistributionFilter={this.handleResponseDistributionFilter}
                      handleQuestionMeanScore={this.handleQuestionMeanScore}

                      EmpFilterData={EmpFilterData}
                      getIndexFilterData={getIndexFilterData}
                      handleDemographicFilter={this.handleDemographicFilter}
                      getMeanScoreData={getMeanScoreData}
                      handleDemographicOverallQFilter={this.handleDemographicOverallQFilter}
                      OverviewList={OverviewList}

                    />
                    : null}


                  {/* {showQuesCard ?

                    <QuestionDetails
                      handleGoBack={this.handleGoBack}
                      ItemSaveData={ItemSaveData}
                      cardType={cardType}
                      handleQuestion={this.handleQuestion}
                      getQuestionDistData={getQuestionDistData}
                      getQuestionDistOverallData={getQuestionDistOverallData}
                      getMeanScoreQwiseData={getMeanScoreQwiseData}
                      handleResponseDistributionFilter={this.handleResponseDistributionFilter}
                      handleQuestionMeanScore={this.handleQuestionMeanScore}
                      EmpFilterData={EmpFilterData}
                      getIndexFilterData={getIndexFilterData}
                      handleDemographicFilter={this.handleDemographicFilter}
                      getMeanScoreData={getMeanScoreData}
                      handleDemographicOverallQFilter={this.handleDemographicOverallQFilter}
                      OverviewList={OverviewList}
                      getQuestionOthersResultData={getQuestionOthersResultData}
                      currentQuestionId={currentQuestionId}
                      currentQuestionTemplate={currentQuestionTemplate}

                      handleOthersFilterCall={this.handleOthersFilterCall}

                      getQuestionMatrixResultData={getQuestionMatrixResultData}
                      getQuestionOthersResultFilterData={getQuestionOthersResultFilterData}


                      questionsList={questionsList}

                      getQuestion2OthersResultFilterData={getQuestion2OthersResultFilterData}

                      getSurveyListData={getSurveyListData}
                      handleNPSCustomerQuadrant={this.handleNPSCustomerQuadrant}
                      handleEmployeeSurvey={this.handleEmployeeSurvey}
                      getIndexData={getIndexData}
                      handleEmployeeDashIndex={this.handleEmployeeDashIndex}
                      getType1AllQuestionListData={getType1AllQuestionListData}
                      handleQuestionFilterCallType1vsType2={this.handleQuestionFilterCallType1vsType2}

                      getQuestionByQuestionIdResultData={getQuestionByQuestionIdResultData}


                      getQuestionWithBranchCodeByQuestionIdData={getQuestionWithBranchCodeByQuestionIdData}
                      getQuestionWithBranchCodeByQuestionId2Data={getQuestionWithBranchCodeByQuestionId2Data}

                      EmpFilterDataEmployee={EmpFilterDataEmployee}
                      getIndexByFilterDMGData={getIndexByFilterDMGData}
                      handleQuestionFilterCallType1vsTypeIndex={this.handleQuestionFilterCallType1vsTypeIndex}
                      getDemographicBranchCodeIndexMultiFilterData={getDemographicBranchCodeIndexMultiFilterData}
                    />

                    : null} */}




                  {/* {showTextAnalysis?
            <TextAnalysis
              getTextQuestionData={getTextQuestionData}
              wordCloudData={wordCloudData}
              EmpFilterData={EmpFilterData}
              getIndexFilterData = {getIndexFilterData}
              handleWordCloudDemographicFilter={this.handleWordCloudDemographicFilter}
              callWordCloudText={this.callWordCloudText}
              wordCloudTextData={wordCloudTextData}
              wordCloudMultiTextData={wordCloudMultiTextData}
              callQuestionNetworkCloud={this.callQuestionNetworkCloud}
              wordTextKeyWord={wordTextKeyWord}
              wordMultiTextKeyWord={wordMultiTextKeyWord}
              callQuestionWordCloud={this.callQuestionWordCloud}
              cooccurmatrixData={cooccurmatrixData}
              onNetworkGraph={this.onNetworkGraph}
              handleNetworkDemographicFilter={this.handleNetworkDemographicFilter}
              neighborGraphData={neighborGraphData}
              handleNeighborGraph={this.handleNeighborGraph}
              onWorldCloud={this.onWorldCloud}
              CountRestrict={()=>{return false}}
              onAdvanceWorldCloud={this.onAdvanceWorldCloud}
              AdvanceWordCloudData={AdvanceWordCloudData}
              // getCommentData={getCommentData}

              onTreeMap={this.onTreeMap}
              TreeMapData={TreeMapData}


            />
          :null} */}


                  {showTextAnalysis ?
                    <TextAnalysis
                      // indexType={indexType}
                      getTextQuestionData={getTextQuestionData}

                      handleChangeQuestion={this.handleChangeQuestion}

                      wordCloudData={wordCloudData}

                      EmpFilterData={EmpFilterData}
                      getIndexFilterData={getIndexFilterData}

                      handleWordCloudDemographicFilter={this.handleWordCloudDemographicFilter}

                      callWordCloudText={this.callWordCloudText}

                      wordCloudTextData={wordCloudTextData}

                      wordTextKeyWord={wordTextKeyWord}

                      callQuestionWordCloud={this.callQuestionWordCloud}

                      cooccurmatrixData={cooccurmatrixData}

                      onWorldCloud={this.onWorldCloud}
                      onNetworkGraph={this.onNetworkGraph}
                      onTextWordDistribution={this.onTextWordDistribution}

                      callQuestionNetworkCloud={this.callQuestionNetworkCloud}
                      handleNetworkDemographicFilter={this.handleNetworkDemographicFilter}


                      neighborGraphData={neighborGraphData}

                      handleNeighborGraph={this.handleNeighborGraph}

                      // getCommentData={getCommentData}

                      loading={loading}

                      // GetParameterLabel={GetParameterLabel}

                      CountRestrict={CountRestrict}

                      wordCloudMultiTextData={wordCloudMultiTextData}
                      wordMultiTextKeyWord={wordMultiTextKeyWord}

                      textDistData={textDistData}


                      onAdvanceWorldCloud={this.onAdvanceWorldCloud}
                      AdvanceWordCloudData={AdvanceWordCloudData}

                      onTreeMap={this.onTreeMap}
                      TreeMapData={TreeMapData}
                      textDistTreeMapData={textDistTreeMapData}

                      getEMPFilterLabel={getEMPFilterLabel}

                    />
                    : null}














                  {/* <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto ">
          
          
          <nav className="static flex px-6 overflow-x-auto bg-white scroll justify-left overscroll-x-contain">
            <div className="flex justify-left">
              <span className="py-4 mr-8 text-xs font-semibold tracking-wide text-gray-500 border-b-2 border-blue-500 hover:border-blue-500" href=" ">
                Summary 
              </span>
              <span className="py-4 mr-8 text-xs font-semibold tracking-wide text-gray-500 border-b-2 border-transparent hover:border-blue-500" href=" ">
                Question Analysis 
              </span>
              <span className="py-4 mr-8 text-xs font-semibold tracking-wide text-gray-500 border-b-2 border-transparent hover:border-blue-500" href=" ">
                Driver Distribution
              </span>
            </div>
          </nav>


          <div className="items-center justify-between px-2 py-4 xl:flex lg:flex md:flex">
            <div className="flex justify-left ">
              <div id="expand" className="bg-white w-40 border text-slate-800 flex justify-between px-4 items-center relative py-1.5 mx-1">
                <span className="pr-4 text-xs font-semibold text-slate-800">All</span> 
                <span id="expandicon" className="text-gray-500 material-symbols-outlined">expand_more</span>
                <div id="dropdown" className="absolute left-0 hidden w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
                  <span className="inline-flex items-center w-full px-4 py-3 text-xs border-b hover:bg-gray-100"> 
                    Multiple Choice</span>
                  <span className="inline-flex items-center w-full px-4 py-3 text-xs border-b hover:bg-gray-100">
                    Check Box</span>
                  <span className="inline-flex items-center w-full px-4 py-3 text-xs hover:bg-gray-100">
                    Text Box</span>
                </div>
              </div>
            </div>
          </div>


          <div className="container px-2 pt-0 pb-8 mx-auto">
            <div className="md:flex ">
              <div className="w-full px-4 bg-white rounded shadow-md md:mx-1 md:w-3/6 ">
                <h1 className="px-2 py-4 text-sm font-semibold text-gray-900">Overall Score</h1>
                <div className="flex items-center justify-center py-4 ">
                  <div className="w-40 h-40 p-2 bg-green-100 rounded-full" />
                </div> 
              </div>
              <div className="w-full px-4 my-2 bg-white rounded shadow-md md:mx-1 md:my-0">
                <div className="flex items-center justify-between">
                  <h1 className="px-2 py-4 text-sm font-semibold text-gray-900">Evaluation</h1>
                </div>
                <div className="h-60" />
              </div>
            </div>
            <div className="md:pr-2 md:pt-2">
              <div className="w-full px-4 bg-white rounded shadow-md md:mx-1">
                <div className="flex items-center justify-between">
                  <h1 className="px-2 py-4 text-sm font-semibold text-gray-900">Distribution</h1>
                  <ul className="text-xs text-gray-500">
                    <div className="inline-flex items-center pr-4" title="Unhappy"><li className="inline-flex bg-red-500 w-3 h-3 rounded mr-1.5"> </li> <p className="hidden md:flex">Unhappy</p></div>
                    <div className="inline-flex items-center pr-4 " title="Moderate"><li className="inline-flex bg-yellow-500 w-3 h-3 rounded mr-1.5"> </li> <p className="hidden md:flex">Moderate</p></div>
                    <div className="inline-flex items-center pr-4" title="Happier"><li className="inline-flex bg-green-500 w-3 h-3 rounded mr-1.5"> </li> <p className="hidden md:flex">Happier</p></div>
                    <div className="inline-flex items-center" title="Happiest"><li className="inline-flex bg-pink-500 w-3 h-3 rounded mr-1.5"> </li> <p className="hidden md:flex">Happiest</p></div>
                  </ul>
                </div>
                <div className="h-60" />
              </div>
            </div>
          </div>
        </main> */}


                </>
                : null}
            </div>

            <div id="sidebar-btn" className="fixed z-30 flex items-center justify-center w-10 h-10 text-gray-900 bg-white rounded-full shadow-md hover:bg-blue-500 hover:text-white bottom-8 right-8 lg:hidden">
              <span className="material-symbols-outlined">view_quilt</span>
            </div>
          </div>





        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, customer, dashboard, survey, questions, general } = state;
  return {
    loggingIn,
    users,
    customer,
    dashboard,
    survey,
    questions,
    general
  };
}
export default connect(mapStateToProps)(CustomerDashboard);
