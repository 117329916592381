import React from "react";
// eslint-disable-next-line no-unused-vars
import moment from "moment";


export default function CompleteModal(props) {
  let { save360RelationListData, loadedDataSheet, closeUploadPreview, saveUploadRelation, handleDataChange, SheetJSFT } = props;


  let uploadListData = loadedDataSheet;



  let errorInit = []
  let IssueList = []
  let FieldList = []


  let validKeys = ["reviewer_name", "reviewer_email", "reviewer_emp_id", "reviewee_emp_id", "reviewer_relation", "relation_type"]


  if (uploadListData && uploadListData.length > 0) {
    uploadListData.forEach((element, index) => {


      let keyList = Object.keys(element);
      if (keyList && keyList.length > 0) {
        keyList.forEach((key) => {
          let getIndex = FieldList.findIndex(prev => prev === key);
          if (getIndex === -1) {
            FieldList.push(key)
          }

        })
      }



      if (element && element["relation_type"] === "INTERNAL") {
        if (!(element && element["reviewer_emp_id"] && element["reviewer_emp_id"] !== "NA")) {

          let getIndex = errorInit.findIndex(prev => prev.field === "reviewer_emp_id")
          if (getIndex === -1) {
            errorInit.push({
              "field": "reviewer_emp_id",
              "name": "Empty reviewer_emp_id at row " + (index + 1)
            })
          } else {
            errorInit[getIndex]["name"] += ", " + (index + 1)
          }

        }
      } else if (element && element["relation_type"] === "EXTERNAL") {
        if (!(element && element["reviewer_email"] && element["reviewer_email"] !== "NA")) {

          let getIndex = errorInit.findIndex(prev => prev.field === "reviewer_email")
          if (getIndex === -1) {
            errorInit.push({
              "field": "reviewer_email",
              "name": "Empty reviewer_email at row " + (index + 1)
            })
          } else {
            errorInit[getIndex]["name"] += ", " + (index + 1)
          }



        }
      } else {
        if (!(element && element["relation_type"])) {

          let getIndex = errorInit.findIndex(prev => prev.field === "relation_type")
          if (getIndex === -1) {
            errorInit.push({
              "field": "relation_type",
              "name": "Empty relation_type at row " + (index + 1)
            })
          } else {
            errorInit[getIndex]["name"] += ", " + (index + 1)
          }



        } else {
          errorInit.push({
            "field": "relation_type",
            "name": "Invalid relation_type at row " + (index + 1)
          })
        }
      }

      if (!(element && element["reviewee_emp_id"] && element["reviewee_emp_id"] !== "NA")) {

        let getIndex = errorInit.findIndex(prev => prev.field === "reviewee_emp_id")
        if (getIndex === -1) {
          errorInit.push({
            "field": "reviewee_emp_id",
            "name": "Empty reviewee_emp_id at row " + (index + 1)
          })
        } else {
          errorInit[getIndex]["name"] += ", " + (index + 1)
        }

        // errorInit.push({
        //   "field":"reviewee_emp_id",
        //   "name":"Empty reviewee_emp_id at row "+(index+1)
        // })
      }

      if (!(element && element["reviewer_relation"] && element["reviewer_relation"] !== "NA")) {

        let getIndex = errorInit.findIndex(prev => prev.field === "reviewer_relation")
        if (getIndex === -1) {
          errorInit.push({
            "field": "reviewer_relation",
            "name": "Empty reviewer_relation at row " + (index + 1)
          })
        } else {
          errorInit[getIndex]["name"] += ", " + (index + 1)
        }


      }



    })
  }




  if (validKeys && validKeys.length > 0) {
    validKeys.forEach((element) => {

      if (!(FieldList.includes(element))) {
        let textA = element + " column is missing.";
        let getIndex = IssueList.findIndex(prev => prev.name === textA)
        if (getIndex === -1) {
          IssueList.push({
            "field": element,
            "name": textA
          })
        }
      }


    })
  }




  let finalKeyList = []
  if (FieldList && FieldList.length > 0) {
    finalKeyList = FieldList.sort(function (a, b) {
      return validKeys.indexOf(a) - validKeys.indexOf(b);
    });
  }



  if (errorInit && errorInit.length > 0 && FieldList && FieldList.length > 0) {
    errorInit.forEach((element) => {
      if (FieldList.includes(element.field)) {
        IssueList.push({
          "field": element.field,
          "name": element.name
        })
      }
    })
  }



  console.log("IssueList-------------->", IssueList);
  console.log("uploadListData-------------->", uploadListData);







  let elementSuccess = ""
  if (save360RelationListData && save360RelationListData.inserted > 0) {
    let inserted = save360RelationListData && save360RelationListData.inserted > 0 ? save360RelationListData.inserted : 0;
    let element1 = "No. of Record Inserted: " + (inserted).toString()

    elementSuccess = element1;

  }


  console.log("elementSuccess-------------->", elementSuccess);



  return (
    <>
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-5xl mx-auto relative   left-0 right-0 overflow-hidden mt-24">

          <div className="shadow w-full rounded-lg bg-white overflow-hidden block">

            <div className="flex justify-between items-center w-full border-b p-4 px-6">
              <h2 className="font-medium text-xl   text-gray-800 ">Upload Relationship Preview</h2>
              <div onClick={() => closeUploadPreview(false)} className=" rounded-full p-2  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                </svg>
              </div>
            </div>

            {elementSuccess ?
              <>

                <div className="text-center justify-center items-center my-24">
                  <h1 className="text-green-500 text-2xl font-medium text-center py-2">{'Relationships Successfully Uploaded!'}</h1>
                  <h1 className="text-gray-500 text-md text-center">{elementSuccess ? elementSuccess : ""}</h1>
                </div>
              </>
              :
              <>

                <div className="px-4" style={{ height: '500px', overflowY: 'auto' }}>


                  <div className="w-full p-4 overflow-auto" style={{ height: '300px' }}>
                    <table className="w-full p-4">
                      <thead className="bg-gray-100">
                        <tr className="p-2 border">
                          {finalKeyList && finalKeyList.length > 0 ?
                            finalKeyList.map((field, index2) =>
                              <th key={index2} className="border text-center font-bold text-sm p-2">{field}</th>
                            ) : null}

                        </tr>
                      </thead>
                      <tbody>
                        {uploadListData && uploadListData.length > 0 ?
                          uploadListData.map((element, index) =>
                            (index < 7) ?
                              <tr key={index} className="p-2 border">
                                {finalKeyList && finalKeyList.length > 0 ?
                                  finalKeyList.map((field, index2) =>
                                    <td key={index2} className="border text-center font-medium text-sm p-2">{element[field]}</td>
                                  ) : null}
                              </tr>
                              : null
                          ) : null}

                      </tbody>
                    </table>
                  </div>



                  <div className="py-1 pt-4 px-4 text-lg font-bold">File validation was successful</div>



                  {/* {getColumnAdded?<div className="py-1 px-4 text-lg">{getColumnAdded}</div>:null} */}

                  {save360RelationListData && save360RelationListData.error ?
                    <>
                      <div className="my-4" style={{ height: "150px", overflowY: 'auto' }}>
                        <div className="py-2 px-4">Following errors are found:</div>

                        {save360RelationListData && save360RelationListData.revieweeNotFound && save360RelationListData.revieweeNotFound.length > 0 ?
                          <div className="px-4 py-1">
                            <p className="text-sm text-red-700">{"1) Reviewee emp_id is not valid at row " + save360RelationListData.revieweeNotFound.map((element) => (element)).join(",")}</p>
                          </div>
                          : null}

                        {save360RelationListData && save360RelationListData.reviewerNotFound && save360RelationListData.reviewerNotFound.length > 0 ?
                          <div className="px-4 py-1">
                            <p className="text-sm text-red-700">{"2) Reviewer emp_id is not valid at row " + save360RelationListData.reviewerNotFound.map((element) => (element)).join(",")}</p>
                          </div>
                          : null}


                      </div>
                    </>
                    :
                    <>
                      {!(IssueList && IssueList.length > 0) ?
                        <>
                          <div className="py-1 px-4 text-lg">{uploadListData && uploadListData.length > 0 ? (uploadListData.length === 1 ? "1 Relationship will be uploaded!" : (uploadListData.length).toString() + " Relationships will be uploaded") : 0}</div>
                        </>
                        : null}
                    </>}


                  {IssueList && IssueList.length > 0 ?
                    <>
                      <div className="my-4" style={{ height: "150px", overflowY: 'auto' }}>
                        <div className="py-2 px-4">Following errors are found:</div>
                        {IssueList.map((item, index) =>
                          <div key={index} className="px-4 py-1">
                            <p className="text-sm text-red-700">{(index + 1).toString() + ") " + item.name}</p>
                          </div>
                        )}
                      </div>

                    </>
                    : <div className="py-2 px-4"></div>}








                </div>



                <div className="p-4 md:flex justify-between border-t md:space-y-0 space-y-6">
                  <div />

                  <div className="flex items-center md:justify-end justify-center space-x-4">
                    <div />
                    {!(IssueList && IssueList.length > 0) && !(save360RelationListData && save360RelationListData.error) ?
                      <>
                        <button onClick={() => saveUploadRelation()} className="bg-blue-500 text-md  capitalize text-white border   py-2 px-4 focus:outline-none rounded-md">Final Upload</button>
                      </>
                      :
                      <>
                        <input type="file"
                          id="file" accept={SheetJSFT} onChange={handleDataChange} hidden />
                        <label for="file" className='bg-blue-100 shadow-md rounded cursor-pointer md:col-span-2' >
                          <div className='flex 2xl:items-center items-start space-x-4 px-4 p-2 bg-blue-100' >

                            <span className="material-symbols-outlined">
                              cloud_upload
                            </span>
                            <div>
                              <h1 className='font-medium md:text-base text-sm' >Re-upload Master Data</h1>
                              <p className='text-sm text-gray-500' >Upload master data as per the guidelines</p>
                            </div>
                          </div>
                        </label>

                      </>}


                  </div>
                </div>
              </>}






          </div>







        </div>
      </div>
    </>
  );
}
