import React from "react";
export default function UserAccessModal(props) {
  let { crrCategory, crrRiskEmpList,handleRedictToProfile,
    
    closeModal, DMGLevelList, finalDemoList,  DemographicValues,  type } = props;


    console.log('DMGLevelList: ',DMGLevelList);
    console.log('DemographicValues: ',DemographicValues);

    console.log('finalDemoList: ',finalDemoList);
    console.log('type: ',type);


    let categoryLabel = "";
      if(crrCategory === "Extremely_High_Risk"){
          categoryLabel = "Extremely High Risk"
      }
      else if(crrCategory === "High_Risk"){
          categoryLabel = "High Risk"
        
      }
      else if(crrCategory === "Borderline"){
          categoryLabel = "Borderline"
        
      }
      else if(crrCategory === "Moderate_Risk"){
          categoryLabel = "Moderate Risk"
        
      }
      else if(crrCategory === "No_Risk"){
          categoryLabel = "No Risk"
        
      }

  return (
   <>
   <div className="fixed h-screen top-0 left-0 w-full flex justify-center xl:items-center z-200 xl:py-0 py-2 bg-black bg-opacity-20"  >
  <div className="bg-white rounded-md shadow-sm xl:w-6/12 md:w-9/12 w-11/12 ">
    {/* add user */}
    <div className="flex justify-between items-center p-4 border-b">
      <h1 className="text-lg font-semibold">{categoryLabel}</h1>
    <span onClick={()=>closeModal(false)}  className="cursor-pointer material-symbols-outlined p-2 bg-gray-50   hover:bg-gray-100 transtion duration-150 rounded-full" >close</span></div>

    <div className="md:h-96 h-80 overflow-y-auto overflow-hidden customscroll4">
  

         <div className=" overflow-x-auto     ">
            {crrRiskEmpList && crrRiskEmpList.length>0?
            <div className="align-middle inline-block min-w-full " style={{height:'400px', overflowY:'auto'}}>
              <table className="min-w-full whitespace-nowrap">
                <thead>
                  <tr className="bg-white">
                    <th className="px-4 py-3 border-b border-gray-200  text-left text-xs leading-4 font-medium text-slate-800 tracking-wider">
                    {/* <input type="checkbox" checked={selectAllEmp} onClick={()=>SelectAllEmployees(selectAllEmp,EmployeesList)} name="list-check" className="mr-4" /> */}
                      Name</th>
                    <th className="px-6 py-3 border-b border-gray-200  text-left text-xs leading-4 font-medium text-slate-800 tracking-wider">
                      EmployeeID</th>
                      <th className="px-6 py-3 border-b border-gray-200  text-left text-xs leading-4 font-medium text-slate-800 tracking-wider">
                      Score</th>
                      <th className="px-6 py-3 border-b border-gray-200  text-left text-xs leading-4 font-medium text-slate-800 tracking-wider">
                      Action</th>
                  </tr>
                </thead>
                <tbody className="bg-white">


                {crrRiskEmpList && crrRiskEmpList.length>0?
                  crrRiskEmpList.map((item,index)=>
                

                  <tr>
                    <td className="px-6 py-4 border-b border-gray-200">
                      <div className="leading-5 text-gray-600 text-xs">{item && item.name?item.name:''}</div>
                    </td>
                    <td className="px-6 py-4 border-b border-gray-200">
                      <div className="leading-5 text-gray-600 text-xs">{item && item.emp_id?item.emp_id:''}</div>
                    </td>
                    <td className="px-6 py-4 border-b border-gray-200">
                      <div className="leading-5 text-gray-600 text-xs">{item && item.score?item.score:'-'}</div>
                    </td>
                    <td className="px-2 py-1 border-b border-gray-200">
                        <button onClick={()=>handleRedictToProfile(item)} className="cursor-pointer bg-blue-600 rounded-md text-white px-3 p-1 capitalize">View Details</button>
                    </td>
                  </tr>

                  )
                  :null}

                </tbody>
              </table>
            </div>
            :
            <div className="text-center font-medium text-lg text-gray-500 pt-12">
              No Data Found  
            </div>}
          </div>



    


    </div>
  </div>
</div>
   </>
  );
}
