import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class DriverRadar extends Component {
    constructor(props) {
        super(props);
        this.state = {
    
      
        }
      }
    
      componentDidMount() {
    
      }
    
    
      render() {
        let { IndexDriverData, getQuestionData, Name, ManagerDynaObj, EmpFilterData, CurrentManagerData, ManagerIndexDriverData} = this.props;
        
        ////console.log('>>>>ManagerDynaObj; ',ManagerDynaObj)
        ////console.log('>>>>ManagerIndexDriverData; ',ManagerIndexDriverData)
        ////console.log('>>>>getQuestionData; ',getQuestionData)
        ////console.log('>>>>CurrentManagerData; ',CurrentManagerData)


        let ManagerDimensionList = []
        
        if(EmpFilterData && EmpFilterData.length>0){
            EmpFilterData.forEach((filter)=>{
                ManagerDimensionList.push({
                    "filterName":filter.name,
                    "filterValue":CurrentManagerData[filter.name]
                })
            })
        }

        let FieldList = []
        if(ManagerDimensionList && ManagerDimensionList.length>0){
            ManagerDimensionList.forEach((value)=>{
                if(ManagerDynaObj && ManagerDynaObj.length>0){
                    ManagerDynaObj.forEach((obj)=>{

                        if(obj.name === value.filterValue){
                            let data ={
                                ...obj,
                                level:value.filterName
                            }
                            FieldList.push(data)
                        }
                    })
                }
            })
        }
    

    
        let paraList = []
    
        let count = 0;
        let rowSpan = 0;
        let NewParaList=[]
    
        if(getQuestionData && getQuestionData.length>0){
    
          getQuestionData.forEach((ques)=>{
            if(paraList.findIndex(prev=>prev===ques.parameterDimensionName)===-1){
              paraList.push(ques.parameterDimensionName);
              count+=1
            }
            else{
            }
          })
    
          let obj = {}
          paraList.forEach((para)=>{
            obj[para]=0
            getQuestionData.forEach((ques)=>{
              if(para===ques.parameterDimensionName){
                obj[para]+=1
              }
            })
          })
    
    
          NewParaList = Object.keys(obj).map((key) => [key, obj[key]])
    
          rowSpan=(getQuestionData.length/count)
    
    
        }
        

    


        let RadarValueList = []



        let TempData = {
          value: [],
          name: "Overall"
        }

        if(IndexDriverData){
         let DriverScoreList = Object.keys(IndexDriverData);
         if(DriverScoreList && DriverScoreList.length>0){
           DriverScoreList.forEach((para)=>{
             const driverName = para.slice(0,-5);
             if(driverName){

              if(IndexDriverData&&IndexDriverData[para]){
                TempData["value"].push(IndexDriverData && IndexDriverData[para]?IndexDriverData[para].toFixed(2):"")
              }
              else{
                TempData["value"].push("NaN")
              }

             }
           });
         }
        }

        RadarValueList.push(TempData)





        if(FieldList && FieldList.length>0){
          FieldList.forEach((item, itemIndex)=>{




              let data = {
                value: [],
                name: item&&item.level==="ManagerPID"?"Manager":item.level
              }

              if(NewParaList && NewParaList.length>0){
                NewParaList.forEach((driver)=>{
                  if(item[driver[0]+"Avg"]){
                    data["value"].push(item[driver[0]+"Avg"].toFixed(2))
                  }
                  else{
                    data["value"].push("NaN")
                  }
                })
              }

              RadarValueList.push(data)
          })
        }



        let ParaNameList = []
        if(NewParaList && NewParaList.length>0){
          NewParaList.forEach((driver, driverIndex)=>{
            ParaNameList.push({
              text: driver[0], 
              max: 100
            })
          })
        }


        ////////console.log("getQuestionData-------->",getQuestionData)
        ////////console.log("ParaNameList-------->",ParaNameList)
        ////////console.log("RadarValueList-------->",RadarValueList)

    
        return (
          <>    
          {getQuestionData && getQuestionData.length>0?
          <div className="py-4 pb-8 ">
          <ReactEcharts
            style={{height:"500px"}}
            option={{
              aria: {
                show: true
              },
              tooltip: {
                show: true,

                  trigger: 'item'
              },
              radar: [
                  {
                      indicator: (function (a){
                          var res = ParaNameList;
                          return res;
                      })()
                  }
              ],
              series: [
                  {
                      type: 'radar',
                      tooltip: {
                          trigger: 'item'
                      },
                      label: {
                        show: false,
                        position: 'insideRight',
                        formatter: function(d) {
                          ////console.log('d:',d)

                              return ParaNameList[d.dimensionIndex].text;
                          
                        }
                      },
                      data: RadarValueList
                  }
              ]
          }}
                            
          />
          </div>
          :null}
          </>
        );
      }
    }
    function mapStateToProps(state) {
      // ////////console.log("state  ", state);
      const { loggingIn } = state.authentication;
      const { users, dashboard } = state;
      return {
        loggingIn,
        users,
        dashboard
      };
    }
export default connect(mapStateToProps)(DriverRadar);
