import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class WordChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedText:''
    }
  }



  componentDidMount() {
  }


    handleKeyword=(data)=>{
      let { handleKeywordCall } = this.props;
      handleKeywordCall(data.text)
      this.setState({SelectedText:data.text+' ('+data.value+')'});

    }

    handleKeywordTooltip=(data)=>{
      this.setState({SelectedText:data.text+' ('+data.value+')'});
    }


    render(){

      let { clusterSecondFilter, getClusterEmpInfoListData , loading}= this.props;
      let { SelectedText }= this.state;

      let level = clusterSecondFilter && clusterSecondFilter[0] &&  clusterSecondFilter[0].level?clusterSecondFilter[0].level:"Department";

      let DriverName = [];
      let DriverValues = [];

      let DemographicList = [];
      if(getClusterEmpInfoListData && getClusterEmpInfoListData.length>0){
        getClusterEmpInfoListData.forEach((item)=>{

          let getIndex = DemographicList.findIndex(prev=>prev.name === item[level]);
          if(getIndex===-1){
            let temp = {
              "name":item[level],
              "count":1
            }
  
            DemographicList.push(temp);
          }
          else{
            DemographicList[getIndex]["count"]+=1
          }
        


        });
      }


      if(DemographicList && DemographicList.length>0){
        DemographicList.forEach((item)=>{
          DriverName.push(item["name"]);
          DriverValues.push(item["count"]);
        });
      }



      let XAxis = DriverName;
      let List = DriverValues;


        return(
            <>
                <div className="w-full p-4   justify-center" style={{overflowX:'auto'}} >
                
              
                    {/* <div className="cursor-pointer" onClick={()=>this.handleKeyword('policy')}>Policy</div>
                    <div className="h-60" /> */}
                    <div className='h-80' >
                      {XAxis && XAxis.length>0?
                      <>
                      <div style={{height:'350px',  marginLeft:'',marginBottom:'-40px'}}>
                        <ReactEcharts
                        option={{
                          tooltip:{
                            trigger: 'item',
                            formatter:function (a) {
                              return (
                              '<b> '+level+': </b> '+XAxis[a.dataIndex]+'</br>'+
                              '<b>No of Employees: </b> '+((a.value)?a.value.toFixed(2):0).toString()+'</br>'
                              )
                            }
                          },
                          xAxis: {
                              type: 'category',
                              data: XAxis,
                              axisLabel:{
                                show:true,
                                rotate:40,
                                interval:0,
                                formatter:function(a){
                                  // //console.log(a)
                                  return a?a.length>12?a.slice(0,12)+'..':a:a
                                }
                              }
                          },
                          yAxis: {
                              type: 'value'
                          },
                          series: [{
                              data: List,
                              type: 'bar',
                              label:{
                                show: true,
                                position: 'top',

                              },
                              showBackground: true,
                              barWidth:'10px',
                              color:'#6536d1'
                          }]
                      }}
                                        
                      />
                       </div>
                      <div className="text-center mb-12"><b>X-Axis:</b>{' Demographics'}{',  '}<b>Y-Axis:</b>{' No of employees'}</div>
                      </>
                      :
                      <>
                      {!loading?
                      <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>:null}
                      </>
                      }
                    </div>
                    <div className="py-4 text-sm"  >* We are not showing data where employee count is less than 5</div>

                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(WordChart);
