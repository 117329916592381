import React from 'react';
import ParameterView from '../Parameter/ParameterView';

export default function SingleChoice(props) {
  let { SurveyTemplateId, question, quesindex, currQuesEditId, showTranslatePage,

    translQuestion, handleTranslateQuestion, handleTranslateOption, onTranslateQuestionSave, translShortName,

    handleQuestionInput, handleOptionInput, onDeleteOption, onQuesMandatory, onSaveQues,
    innerEdit, handleQuesCancel, GetParameterLabel, translSubQuestionsMatrix, handleInputTranslateMatrixQues,
    handleTranslateOptionHeadingInput,

    addMatrixQFn, handleInputMatrixQues, onDeleteConfirm, handleOptionHeadingInput, handleNudgeModal
  } = props;

  let [showBotResEdit, handleBotRespEdit] = React.useState(false);


  let paraObjShow = {};


  if (question && question.optionsList && question.optionsList.length > 0) {
    question.optionsList.forEach((item) => {


      if (paraObjShow && paraObjShow[item.heading]) {
        paraObjShow[item.heading] += 1
      }
      else {
        paraObjShow[item.heading] = 1
      }


    });
  }


  let headingList = []
  let tempKey = Object.keys(paraObjShow);
  if (tempKey && tempKey.length > 0) {
    tempKey.forEach((item) => {
      headingList.push({
        name: item,
        span: paraObjShow[item]
      })
    });
  }

  //console.log('headingList=>', headingList)

  let HSortOptionList = []
  if (headingList && headingList.length > 0) {
    headingList.forEach((heading) => {
      if (question && question.optionsList && question.optionsList.length > 0) {
        question.optionsList.forEach((option) => {
          if (heading.name === option.heading) {
            HSortOptionList.push(option);
          }
        });
      }
    });

  }



  function TranslateSubQues(SubQues) {


    let value = ""
    if (translSubQuestionsMatrix && translSubQuestionsMatrix[SubQues.id] &&
      translSubQuestionsMatrix[SubQues.id]["transl"] &&
      translSubQuestionsMatrix[SubQues.id]["transl"][translShortName] &&
      translSubQuestionsMatrix[SubQues.id]["transl"][translShortName].name) {
      value = translSubQuestionsMatrix[SubQues.id]["transl"][translShortName].name



    }
    return value

  }
  //console.log('translSubQuestionsMatrix: ', translSubQuestionsMatrix)
  //console.log('question: ', question)

  return (
    <>
      {currQuesEditId === question.id ?
        <>
          {showTranslatePage ?
            <>
              <div className="flex items-center justify-between">
                <div className="text-sm font-medium text-[#212121]">Question</div>
                <div className="flex px-2">
                  <div onClick={() => onTranslateQuestionSave(true, translShortName)} className="px-3 py-1 mr-2 text-sm text-green-500 border border-green-500 rounded-sm cursor-pointer border-1">Save</div>
                  <div onClick={() => onTranslateQuestionSave(false, translShortName)} className="px-2 py-1 text-sm text-red-500 border border-red-500 rounded-sm cursor-pointer border-1">Cancel</div>
                </div>
              </div>
              <input
                type="text"
                value={question.name}
                onChange={{}}
                disabled={true}
                placeholder={"Q." + (quesindex + 1) + " " + question.name}
                className="w-full px-4 py-2 mt-2 border rounded-md outline-none"
              />
              <input
                type="text"
                value={translQuestion["transl"][translShortName].name}
                onChange={(event) => handleTranslateQuestion(event.target.value, question.id, translShortName)}
                placeholder="Translate here..."
                className="w-full px-4 py-2 mt-2 border-2 border-purple-500 outline-none"
              />

              <ul className="pb-4">
                <div className="w-full overflow-hidden overflow-x-auto whitespace-nowrap ">

                  {question && question.SubQuestionList && question.SubQuestionList.length > 0 ?

                    <table className="w-full border-collapse ">
                      <thead>


                        {/* Heading */}
                        <tr>
                          <td className="p-2 px-2 bg-gray-200 border border-gray-300"></td>
                          {question && question.optionsList && question.optionsList.length ?
                            question.optionsList.map((option, optionindex) =>
                              <td className="px-2 font-semibold text-center text-blue-500 bg-gray-200 border border-gray-300">
                                <input
                                  type="text" value={option.heading}
                                  onChange={{}}
                                  disabled={true}
                                  className="p-1 m-2 border border-blue-500"

                                  placeholder={optionindex + 1 + ') ' + option.heading}
                                  margin="normal"
                                />
                              </td>
                            ) : null}
                        </tr>

                        <tr className="text-xs text-gray-800 bg-gray-100 border-b border-gray-500">
                          <td className="flex justify-between px-2">
                            <span></span>
                            <span className="pt-4 text-purple-500">Translate Heading:-</span>
                          </td>

                          {question && question.optionsList && question.optionsList.length ?
                            question.optionsList.map((option) =>
                              <td className="px-2 border border-gray-300">
                                <div className="flex">
                                  <input
                                    type="text"
                                    // value={option.heading}
                                    value={translQuestion && translQuestion["transl"] && translQuestion["transl"][translShortName] && translQuestion["transl"][translShortName].headingList &&
                                      translQuestion["transl"][translShortName].headingList[option.id] ? translQuestion["transl"][translShortName].headingList[option.id] : ""}

                                    onChange={(event) => handleTranslateOptionHeadingInput(event.target.value, question.id, option.id, translShortName)}
                                    className="p-2 m-2 border border-purple-500"
                                    placeholder={"Translate Heading..."}
                                  />
                                </div>
                              </td>
                            ) : null}
                        </tr>


                        {/* Options */}

                        <tr className="text-xs text-gray-800 bg-gray-100 border-b border-gray-500">

                          <td className="p-2 px-2 bg-gray-200 border border-gray-300"></td>

                          {question && question.optionsList && question.optionsList.length ?
                            question.optionsList.map((option, optionindex) =>
                              <td className="px-2 border border-gray-300">

                                <div className="flex">
                                  <input
                                    type="text" value={option.name}
                                    onChange={{}}
                                    disabled={true}
                                    className="p-2 m-2 border border-blue-500"

                                    placeholder={optionindex + 1 + ') ' + option.name}
                                    margin="normal"
                                  />
                                </div>

                              </td>
                            ) : null}
                        </tr>


                        <tr className="text-xs text-gray-800 bg-gray-100 border-b border-gray-500">
                          <td className="flex justify-between px-2">
                            <span></span>
                            <span className="pt-4 text-purple-500">Translate Options:-</span>
                          </td>

                          {question && question.optionsList && question.optionsList.length ?
                            question.optionsList.map((option, optionindex) =>
                              <td className="px-2 border border-gray-300">

                                <div className="flex">
                                  <input
                                    type="text"
                                    value={translQuestion && translQuestion["transl"] && translQuestion["transl"][translShortName] && translQuestion["transl"][translShortName].optionsList && translQuestion["transl"][translShortName].optionsList[option.id] ? translQuestion["transl"][translShortName].optionsList[option.id] : ""}
                                    onChange={(event) => handleTranslateOption(event.target.value, question.id, option.id, translShortName)}
                                    className="p-2 m-2 border border-purple-500"
                                    placeholder={"Translate Options..."}
                                  />
                                </div>

                              </td>
                            ) : null}
                        </tr>


                      </thead>







                      <tbody className="">
                        {question && question.SubQuestionList && question.SubQuestionList.length > 0 ?
                          question.SubQuestionList.map((SubQues, index) =>
                            <tr className="mb-10 text-xs text-center text-gray-800 bg-white lg:mb-0">
                              <td className="p-3 text-center border border-b">
                                <div className="">
                                  <div>
                                    <input type="text"
                                      value={SubQues.name}
                                      onChange={{}}
                                      disabled={true}
                                      className="p-2 m-2 border border-blue-500" />
                                  </div>
                                  <div>
                                    <input type="text"
                                      value={TranslateSubQues(SubQues)}
                                      onChange={(e) => handleInputTranslateMatrixQues(e.target.value, question, SubQues, translShortName)}
                                      className="p-2 m-2 border border-purple-500"
                                      placeholder="Translate here..." />
                                  </div>
                                </div>
                              </td>
                              {question && question.optionsList && question.optionsList.length > 0 ?
                                question.optionsList.map((option, optionindex) =>
                                  <td className="p-3 text-center border border-b"><input type="radio" className="mx-auto ml-5" /></td>
                                ) : null}
                            </tr>
                          ) : null}

                      </tbody>
                    </table>

                    : null}

                </div>

              </ul>


            </>
            :
            <>
              <div className="flex items-center justify-between">
                <div className="text-sm font-medium text-[#212121]">Question</div>
                <div onClick={() => handleQuesCancel(false)} className="px-2 py-1 mr-2 text-sm text-red-500 border border-red-500 rounded-sm cursor-pointer border-1">Cancel</div>
              </div>
              <input
                type="text"
                value={question.name}
                onChange={(event) => handleQuestionInput(event.target.value, question.id)}
                placeholder={"Q." + (quesindex + 1) + " " + question.name}
                className="w-full px-4 py-2 mt-2 border rounded-md outline-none"
              />

              <ul className="pb-4">
                {/* <p className="pb-2 text-xs text-gray-500">Answer</p> */}
                <div className="mb-1.5 mt-2" >

                  <div className="py-1" >
                    <input
                      id="mandatory-check"
                      value={question.isMandatory}
                      checked={question.isMandatory}
                      type="checkbox"
                      onChange={() => { }}
                      className="ml-1 cursor-pointer"
                      onClick={() => onQuesMandatory(question.isMandatory, question.id)}
                    />
                    <label htmlFor="mandatory-check" className="pb-8 pl-2 text-sm text-gray-500">Mandatory</label>
                  </div>


                  <div className="flex py-1 space-x-2" >
                    <div onClick={() => handleNudgeModal(question, true)}
                      className='cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md '>
                      Add Nudge</div>
                    <div onClick={() => handleQuestionInput(" [variable] ", question.id, true)}
                      className='cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md '>
                      Add Variable</div>
                    <div onClick={() => handleBotRespEdit(!showBotResEdit)} className={showBotResEdit ?
                      'cursor-pointer text-sm py-2 px-4 font-medium bg-yellow-500 text-white rounded-md ' :
                      'cursor-pointer text-sm py-2 px-4 font-medium bg-purple-500 text-white rounded-md '
                    }>Bot Edit</div>
                  </div>


                  {/* <div className="mt-1 ml-6 text-sm font-semibold text-purple-500 uppercase cursor-pointer" onClick={()=>onParamaterModal(!showParameterModal)} ><i className={showParameterModal?"fa fa-times":"pr-2 fa fa-plus"} />{showParameterModal?"":"Add Paramaters"}</div> */}




                  {/* <Parameter
                        showBucketDropdown={showBucketDropdown} 
                        parameterList={parameterList}
                        handleParameterBucket={handleParameterBucket}
                        handleParameterQuestion={handleParameterQuestion}
                        getParameterListbyParentData={getParameterListbyParentData}
                        paraBucketName={paraBucketName}
                        question={question}
                        showDimensionDropdown={showDimensionDropdown}
                        paraDimensionName={paraDimensionName}
                        handleParameterDimension={handleParameterDimension}
                        GetParameterLabel={GetParameterLabel}
                        SurveyTemplateId={SurveyTemplateId}
                        />
                       */}


                </div>



                <div className="w-full overflow-hidden overflow-x-auto whitespace-nowrap ">

                  {question && question.SubQuestionList && question.SubQuestionList.length > 0 ?

                    <table className="w-full border-collapse ">
                      <thead>

                        <tr>
                          <td className="p-2 px-2 bg-gray-200 border border-gray-300"></td>
                          {question && question.optionsList && question.optionsList.length ?
                            question.optionsList.map((option, optionindex) =>
                              <td className="px-2 font-semibold text-center text-blue-500 bg-gray-200 border border-gray-300">
                                <input
                                  type="text" value={option.heading}
                                  onChange={(event) => handleOptionHeadingInput(event.target.value, question.id, option.id)}
                                  className="p-1 m-2 border border-blue-500"

                                  placeholder={optionindex + 1 + ') ' + option.heading}
                                  margin="normal"
                                />
                              </td>
                            ) : null}
                        </tr>


                        <tr className="text-xs text-gray-800 bg-gray-100 border-b border-gray-500">
                          <td className="px-2"></td>

                          {question && question.optionsList && question.optionsList.length ?
                            question.optionsList.map((option, optionindex) =>
                              <td className="px-2 border border-gray-300">

                                <div className="flex">
                                  <input
                                    type="text" value={option.name}
                                    onChange={(event) => handleOptionInput(event.target.value, question.id, option.id)}
                                    className="p-2 m-2 border border-blue-500"

                                    placeholder={optionindex + 1 + ') ' + option.name}
                                    margin="normal"
                                  />
                                  <i className="fa fa-trash" aria-hidden="true"
                                    onClick={() => onDeleteOption(question.id, option.id)}
                                    style={{ marginTop: '15px', fontSize: '19px', color: '#808080', marginLeft: "15px", display: 'inline' }}
                                  ></i>
                                </div>

                              </td>
                            ) : null}
                        </tr>
                      </thead>
                      <tbody className="">
                        {question.SubQuestionList.map((SubQues, index) =>
                          <tr className="mb-10 text-xs text-center text-gray-800 bg-white lg:mb-0">
                            <td className="p-3 text-center border border-b">
                              <div className="flex">
                                <span><i className="fa fa-trash" aria-hidden="true"
                                  title="Delete"
                                  onClick={() => onDeleteConfirm(SubQues)}
                                  style={{ margin: '15px', fontSize: '25px' }}></i>
                                </span>
                                <span><input type="text" value={SubQues.name} onChange={(e) => handleInputMatrixQues(e.target.value, question, SubQues)} className="p-2 m-2 border border-blue-500" />
                                </span>
                              </div>
                            </td>
                            {question && question.optionsList && question.optionsList.length > 0 ?
                              question.optionsList.map((option, optionindex) =>
                                <td className="p-3 text-center border border-b"><input type="radio" className="mx-auto ml-5" /></td>
                              ) : null}
                          </tr>
                        )}

                      </tbody>
                    </table>

                    : null}

                </div>

              </ul>

              <div className="flex justify-between mx-20">
                <div className="flex justify-center">
                  <span onClick={() => addMatrixQFn(question, 'row')} className="mx-4 text-sm font-semibold text-blue-500 uppercase cursor-pointer"><i className="fa fa-plus" /> Add Rows</span>
                </div>
                <div className="flex justify-center">
                  <span onClick={() => addMatrixQFn(question, 'col')} className="mx-4 text-sm font-semibold text-blue-500 uppercase cursor-pointer"><i className="fa fa-plus" /> Add Columns</span>
                </div>


                {/* <div onClick={()=>addMatrixQFn(question, 'row')} className="flex mx-2 my-4 cursor-pointer">
                        <span className="text-blue-500">Add Matrix Rows</span>
                        <i className="fa fa-plus-circle" aria-hidden="true"
                          
                          style={{ marginLeft: '5px' ,fontSize: '25px' }}></i>
                      </div>
                      <div onClick={()=>addMatrixQFn(question, 'col')} className="flex mx-2 my-4 cursor-pointer">
                      <span className="text-red-500">Add Matrix Columns</span>
                        <i className="fa fa-plus-circle" aria-hidden="true"
                          style={{ marginLeft: '5px' ,fontSize: '25px' }}></i>
                      </div> */}


              </div>

              {/* <div className="flex justify-center">
                      <span onClick={()=>onAddNewOption(question)} className="text-sm font-semibold text-blue-500 uppercase cursor-pointer"><i className="fa fa-plus" /> Add more options</span>
                    </div> */}


              <div className="flex justify-center pt-8 pb-2">
                <span className="cursor-pointer text-sm rounded uppercase   bg-[#2196f3] py-2 px-4 text-white w-28  mx-1 text-center" onClick={() => onSaveQues(question)}> save</span>
              </div>

            </>
          }

        </>
        :
        <>

          <div title={(question && question.isNonEdit ? "" : "Double Click to Edit!")} onDoubleClick={() => innerEdit(question, SurveyTemplateId)} className="space-y-4">
            <ParameterView question={question} GetParameterLabel={GetParameterLabel} />
            <div className="inline-flex text-sm font-semibold text-slate-800"><p className="pr-2">Q{quesindex + 1}.</p> <h1>{question.name}{(question.isMandatory) ? '*' : null}</h1> </div>
            <ul className="pl-6 ">
              <div className="w-full overflow-hidden overflow-x-auto whitespace-nowrap ">

                {question && question.SubQuestionList && question.SubQuestionList.length > 0 ?

                  <table className="w-full border-collapse ">
                    <thead>
                      <tr>
                        <td className="p-2 px-2 bg-gray-200 border border-gray-300"></td>
                        {headingList && headingList.length ?
                          headingList.map((heading, optionindex) =>
                            <td colSpan={heading.span} className="px-2 font-semibold text-center text-blue-500 bg-gray-200 border border-gray-300">
                              {heading.name}
                            </td>
                          ) : null}

                      </tr>

                      <tr className="text-xs text-gray-800 bg-gray-100 border-b">
                        <td className="px-2"></td>

                        {HSortOptionList.map((option, optionindex) =>
                          <th className="px-4 py-2 font-bold border-r"><div className="flex items-center justify-center">{option.name}</div>


                          </th>

                        )}
                      </tr>
                    </thead>
                    <tbody className="">
                      {question.SubQuestionList.map((SubQues, index) =>
                        <tr className="mb-10 text-xs text-center text-gray-800 bg-white lg:mb-0">
                          <td className="p-3 text-center border border-b">
                            <div className="flex">
                              <span>
                                <div>{SubQues.name}</div>
                              </span>
                            </div>
                          </td>
                          {HSortOptionList && HSortOptionList.length > 0 ?
                            HSortOptionList.map((option, optionindex) =>
                              <td className="p-3 text-center border border-b"><input type="radio" className="mx-auto ml-5" /></td>
                            ) : null}
                        </tr>
                      )}

                    </tbody>
                  </table>

                  : null}
              </div>
            </ul>
          </div>
        </>
      }
    </>
  );
}