import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class QuestionScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let {  question } = this.props;
    
    //////console.log("question----->",question);


    
    function getScoreValueX(relation, questionX){
      let scoreMean = 0;
      if(relation !== "Self"){
        let otherSum = 0;
        let otherCount = 0;

          if(question && question.relationList && question.relationList.length>0){
            question.relationList.forEach((relation)=>{
              if(relation !== "Self"){
                otherCount+=Number(questionX[relation+"_count"])

                if(questionX.name.trim() === "It's extremely difficult for me to leave this organization"){

                }


                otherSum += Number(questionX[relation+"_sum"]);
              }
            })
          }
          scoreMean = Number((otherSum/otherCount).toFixed(2))
      }
      else{
        scoreMean = Number(questionX["Self_sum"] / questionX["Self_count"]);
        
      }
      return [Number((scoreMean).toFixed(2))]

    }


    let RelationList = [];


  let relationXList = ["Self", "Others"];
  if(relationXList && relationXList.length>0){

    relationXList.forEach((item2)=>{


        if(item2 === "Self"){
          RelationList.push({
            name: item2,
            type: 'bar',
            barWidth:'25px',
            data: getScoreValueX(item2, question),
            label: {
              show: true,
              position: 'right'
          },
          })
        }else {
          RelationList.push({
            name: "Others",
            type: 'bar',
            barWidth:'25px',
            data: getScoreValueX("Others", question),
            label: {
              show: true,
              position: 'right'
            },
          })
        }




      })

    }

    // let dimension = question && question.parameterDimensionName?question.parameterDimensionName: "";
    let DimensionList = [""];

    return (
      <>
        <div style={{zIndex:'10'}}>

        <ReactEcharts
              style={{height:'100px',width:'100%'}}
              option={{
                // title: {
                //   text: 'World Population'
                // },
                color: ["#F6736A", "#f9d350"],

                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  }
                },
                // legend: {},
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: {
                  type: 'value',
                  boundaryGap: [0, 0.01],
                  max:6,
                  splitLine: {
                    lineStyle: {
                        color: '#fff'
                    }
                  },
                  axisLabel:{
                      show:false
                  }

                },
                yAxis: {
                  label:{
                    show:false
                  },
                  type: 'category',
                  data: DimensionList
                },
                series: RelationList
              }}
          />

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
//   //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionScore);
