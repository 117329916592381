import React, { Component } from 'react';
import { connect } from 'react-redux';

import Strength from './Components/Strength/Strength';
import Weaknesses from './Components/Weaknesses/Weaknesses';
// import RWAnalysis from './Components/RWAnalysis/RWAnalysis';
import Commentary from '../Index/Components/Commentary/Commentary';


class StrengthAndWeaknesses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 1
    }
  }
  componentDidMount() {

  }

  onOptionType = (val) => {
    this.setState({ optionType: val })
    if (val === 2) {
      let { onWeaknessQuestionCall } = this.props;
      onWeaknessQuestionCall();
    }
    else {
      let { onStrengthQuestionCall } = this.props;
      onStrengthQuestionCall();
    }
  }

  onSwitchOptionScreen = () => {
    let {
      getEMPFilterLabel, getIndexByFilterDMGData,
      indexType, getCommentData, EmpFilterData, getIndexFilterData, GetParameterLabel, getOrgIndexV2Data,

      getIndexScore, handleSummaryFilter, handleQuadrantFilter, getRespondantDetailsData, handleSummaryCard, getDemographicIndexData, getIndexData, getIndexDistributionData,
      getQuestionData, getQuestionFavorabilityData,


      getDemographicIndexScatterData,

      handleStrengthQuestionFilter,
      handleWeaknessesQuestionFilter,

      loading,
      CountRestrict,
      ErrorOtherObj,
      handleMultiFilterStrenghtWeakness

    } = this.props;

    let { optionType } = this.state;

    switch (optionType) {

      case 1: return <Strength
        indexType={indexType} optionType={optionType} getCommentData={getCommentData} loading={loading}
        GetParameterLabel={GetParameterLabel}
        getOrgIndexV2Data={getOrgIndexV2Data}
        CountRestrict={CountRestrict}
        getRespondantDetailsData={getRespondantDetailsData}
        getDemographicIndexData={getDemographicIndexData} handleQuadrantFilter={handleQuadrantFilter} handleStrengthQuestionFilter={handleStrengthQuestionFilter}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getQuestionData={getQuestionData} getQuestionFavorabilityData={getQuestionFavorabilityData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard}
        getIndexData={getIndexData}
        ErrorOtherObj={ErrorOtherObj}

        handleMultiFilterStrenghtWeakness={handleMultiFilterStrenghtWeakness}
        getEMPFilterLabel={getEMPFilterLabel}
        getIndexByFilterDMGData={getIndexByFilterDMGData}


      />


      case 2: return <Weaknesses loading={loading}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        getRespondantDetailsData={getRespondantDetailsData}
        getDemographicIndexScatterData={getDemographicIndexScatterData}
        GetParameterLabel={GetParameterLabel}
        getOrgIndexV2Data={getOrgIndexV2Data}
        CountRestrict={CountRestrict}
        getDemographicIndexData={getDemographicIndexData} handleQuadrantFilter={handleQuadrantFilter} handleWeaknessesQuestionFilter={handleWeaknessesQuestionFilter}

        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getQuestionData={getQuestionData} getQuestionFavorabilityData={getQuestionFavorabilityData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard}
        getIndexData={getIndexData}
        ErrorOtherObj={ErrorOtherObj}

        handleMultiFilterStrenghtWeakness={handleMultiFilterStrenghtWeakness}
        getEMPFilterLabel={getEMPFilterLabel}
        getIndexByFilterDMGData={getIndexByFilterDMGData}


      />



      default: return <div>Default Option</div>
    }
  }

  render() {
    // let { CurrentSurveyId, indexType, getDemographicIndexData } = this.props;
    let { indexType, getCommentData } = this.props;
    let { optionType } = this.state;

    return (
      <>
        {/* {true?
 <main className="flex-1 w-full overflow-hidden overflow-y-auto h-[calc(100vh-4rem)] bg-[#f9fafb]">
   <div className="h-full pt-10 text-xl text-center text-gray-500 bg-white">This section is under construction...</div>
</main> 
: */}

        {/* <main className="flex-1 w-full overflow-hidden overflow-y-auto h-[calc(100vh-4rem)] bg-[#f9fafb]">
        
        <nav className="flex px-6 overflow-x-auto bg-white scroll justify-left overscroll-x-contain">
          <div className="flex cursor-pointer justify-left">
            <a onClick={()=>this.onOptionType(1)} className={(optionType===1)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            Strength
            </a>
             <a onClick={()=>this.onOptionType(2)} className={(optionType===2)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            Weaknesses
            </a>
          </div>
        </nav>

          {this.onSwitchOptionScreen()}
      </main>  */}

        <div className="flex-1 w-full p-4 space-y-6 overflow-y-auto bg-[#f9fafb] md:p-6">
          <div className="flex items-center justify-between text-gray-800">
            <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]  ">{"Strength & Weaknesses"} </h1>
            {/* <span className="flex cursor-pointer material-symbols-outlined lg:hidden">menu_open</span> */}
          </div>
          {/*sub nav bar 1 */}

          <div className="items-center justify-between border-b md:flex">
            <div className="flex space-x-2 overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 whitespace-nowrap tab-scroll">
              <span onClick={() => this.onOptionType(1)} className={optionType === 1 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> Strength </span>
              <span onClick={() => this.onOptionType(2)} className={optionType === 2 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> Weaknesses </span>
            </div>
          </div>


          {this.onSwitchOptionScreen()}

        </div>





        {/* }  */}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(StrengthAndWeaknesses);
