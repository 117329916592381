import React from "react";

export default function TopBottom5Themes(props) {
  let {
    getAssessmentDimensionsData,

    getAssessmentReportByEmpIDData,
    crrSelectedEmp,
    EditDetailsFn,
    EditButtonFn,
    hindiPDFstatic
  } = props;

  // function hexToRgbA(hex, opacity) {
  //   var c;
  //   if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
  //     c = hex.substring(1).split("");
  //     if (c.length == 3) {
  //       c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  //     }
  //     c = "0x" + c.join("");
  //     return (
  //       "rgba(" +
  //       [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
  //       "," +
  //       opacity +
  //       ")"
  //     );
  //   }
  //   throw hex;
  // }

  // let newList = [];
  // if (
  //   getAssessmentReportByEmpIDData &&
  //   getAssessmentReportByEmpIDData.length > 0
  // ) {
  //   getAssessmentReportByEmpIDData.forEach((element, index) => {
  //     let getIndex = newList.findIndex(
  //       (prev) => prev.bucket === element.bucket
  //     );
  //     if (getIndex === -1) {
  //       let temp = {
  //         bucket: element.bucket,
  //         colorCode: element.colorCode,
  //         dimensions: [
  //           {
  //             rank: index + 1,
  //             name: element.dimension,
  //           },
  //         ],
  //       };
  //       newList.push(temp);
  //     } else {
  //       newList[getIndex]["dimensions"].push({
  //         rank: index + 1,
  //         name: element.dimension,
  //       });
  //     }
  //   });
  // }

  // //console.log(
  //   "getAssessmentReportByEmpIDData--->",
  //   getAssessmentReportByEmpIDData
  // );
  // //console.log("newList--->", newList);

  // function getOpacity(rank, item) {
  //   let total =
  //     item && item.dimensions && item.dimensions.length > 0
  //       ? item.dimensions.length
  //       : 0;
  //   return (total + 1 - Number(rank)) / (total + 2);
  // }


  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }





  let AssessmentList = []

  if (getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0) {
    getAssessmentDimensionsData.forEach((element) => {
      AssessmentList.push({
        ...element,
        finalSum: (Number(element.count) * Number(element.rankSum))
      })
    })
    AssessmentList.sort((b,a) => (a.finalSum - b.finalSum))
  }



  function checkForGeneralship(bucket, dimension, isSchool) {
    let text = "";
    if (bucket && dimension) {
      if (isSchool) {
        text = "school_";
      } else if (dimension.toLowerCase() === "generalship" && bucket.toLowerCase() === "thinking talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "enhancer" && bucket.toLowerCase() === "relating talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "coach" && bucket.toLowerCase() === "leading talents") {
        text = "old";
      }
    }
    return text
  }


  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }






  return (
    <>
      <div className='w-full flex flex-col gap-1 '>
        <div className='bg-[#00CD7E]/10 w-full p-4'>
          <p className='font-semibold '>Dominant Themes</p>
        </div>

        {AssessmentList && AssessmentList.length > 0 ?
          AssessmentList.map((element, index) =>
            (index + 1 <= getTopBottomIndex(AssessmentList, "top").range1) ?
              <div style={{
                background:
                  hexToRgbA(
                    element && element.colorCode
                      ? element.colorCode
                      : "#DDDDDD", '0.12'),
              }}
              className='p-4 bg-[#673AB7]/10 space-y-3'>
                <div className='flex items-center justify-between'>
                  <div className='space-y-2 '>
                    <p className='text-lg font-medium'>{index + 1}. {EditDetailsFn(element, "dimension", element.dimension)}</p>
                    <button style={{
                      background:
                        element && element.colorCode ? element.colorCode : "#DDDDDD",
                    }} className='p-2 rounded-sm bg-[#673AB7] text-white py-1 text-xs uppercase tracking-widest '>{EditDetailsFn(element, "bucket", element.bucket)}</button>

                  </div>
                  <img src={
                    "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension, (element && element.isSchool ? true : false))) +
                    ((element.dimension.replace(' ', '_')).toLowerCase()) +
                    ".svg"
                  } className='w-14 h-14' />
                </div>

                <p>{EditDetailsFn(element, "desc", element.details.desc)}</p>
              </div>
              : null
          ) : null}

      </div>
      <div className='w-full flex flex-col gap-1 '>
        <div className='bg-[#F44336]/10 w-full p-4'>
          <p className='font-semibold'>Non Dominant Themes</p>
        </div>

        {AssessmentList && AssessmentList.length > 0 ?
          AssessmentList.map((element, index) =>
            (getTopBottomIndex(AssessmentList, "bottom").range1 <= index + 1) ?
              <div style={{
                background:
                  hexToRgbA(
                    element && element.colorCode
                      ? element.colorCode
                      : "#DDDDDD", '0.12'),
              }}
                className='p-4 bg-[#673AB7]/10 space-y-3'> 
                <div className='flex items-center justify-between'>
                  <div className='space-y-2 '>
                    <p className='text-lg font-medium'>{index + 1}. {EditDetailsFn(element, "dimension", element.dimension)}</p>
                    <button style={{
                      background:
                        element && element.colorCode ? element.colorCode : "#DDDDDD",
                    }}
                      className='p-2 rounded-sm bg-[#673AB7] text-white py-1 text-xs uppercase tracking-widest '>{EditDetailsFn(element, "bucket", element.bucket)}</button>

                  </div>
                  <img src={
                    "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension, (element && element.isSchool ? true : false))) +
                    ((element.dimension.replace(' ', '_')).toLowerCase()) +
                    ".svg"
                  } className='w-14 h-14' />
                </div>

                <p>{EditDetailsFn(element, "desc", element.details.desc)}</p>
              </div>
              : null
          ) : null}


      </div>
    </>
  );
}
