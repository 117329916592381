import { campaignConstants } from '../_constants';
import { campaignService } from '../_services';
// import { alertActions } from '.';
export const campaignActions = {
    createNewCampaign,
    createAsessmentCampaign,
    createCampaignCustomer,
    create360Campaign,
    getCampaignListBySurveyID,
    getCampaignEmployeesByID,
    getCampaignCustomersByID,
    getCampaignReponseNumberByID,
    getCampaignCustomerReponseNumberByID,
    setExpireCampaign,
    resendCampain,
    responseAnalysisByCampaignId,
    responseCustomerAnalysisByCampaignId,
    response360AnalysisByCampaignId,
    resendMailInBulk,
    resendCustomerMailInBulk,
    resend360MailInBulk,
    getReminderHistoryByCamapaignAndCompanyId,


    getAllEmployees,
    sendAppSurveyNotify,
    getCampaignEmployeesByKeyWord,
    getCampaignById,

    getCampaignActiveHistory,
    getCampaign360EmployeesByID,
    getCampaignDetailsById,
    updateReminderActiveStatus,

    getCampaignActiveLaunchDateWise,
    saveCampaignTemplate,
    getCampaignTemplateList
 
    
};

function saveCampaignTemplate(data) {
    return dispatch => {
        dispatch(request());
        campaignService.saveCampaignTemplate(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.SAVE_CAMPAIGN_TEMPLATE_REQUEST } }
    function success(campaign) { return { type: campaignConstants.SAVE_CAMPAIGN_TEMPLATE_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.SAVE_CAMPAIGN_TEMPLATE_FAILURE, error } }
}

function getCampaignTemplateList(data) {
    return dispatch => {
        dispatch(request());
        campaignService.getCampaignTemplateList(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.GET_CAMPAIGN_TEMPLATE_REQUEST } }
    function success(campaign) { return { type: campaignConstants.GET_CAMPAIGN_TEMPLATE_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.GET_CAMPAIGN_TEMPLATE_FAILURE, error } }
}

function createNewCampaign(data) {
    return dispatch => {
        dispatch(request());
        campaignService.createNewCampaign(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                    dispatch(this.getCampaignListBySurveyID({surveyId:data.surveyId ,companyId:data.companyId }));


                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.ADD_CAMPAIGN_REQUEST } }
    function success(campaign) { return { type: campaignConstants.ADD_CAMPAIGN_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.ADD_CAMPAIGN_FAILURE, error } }
}

function createAsessmentCampaign(data) {
    return dispatch => {
        dispatch(request());
        campaignService.createAsessmentCampaign(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                    dispatch(this.getCampaignListBySurveyID({surveyId:data.surveyId ,companyId:data.companyId }));


                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.ADD_ASSESSMENT_CAMPAIGN_REQUEST } }
    function success(campaign) { return { type: campaignConstants.ADD_ASSESSMENT_CAMPAIGN_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.ADD_ASSESSMENT_CAMPAIGN_FAILURE, error } }
}

function createCampaignCustomer(data) {
    return dispatch => {
        dispatch(request());
        campaignService.createCampaignCustomer(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                    dispatch(this.getCampaignListBySurveyID({surveyId:data.surveyId ,companyId:data.companyId }));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.ADD_CAMPAIGN_CUSTOMER_REQUEST } }
    function success(campaign) { return { type: campaignConstants.ADD_CAMPAIGN_CUSTOMER_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.ADD_CAMPAIGN_CUSTOMER_FAILURE, error } }
}


function create360Campaign(data) {
    return dispatch => {
        dispatch(request());
        campaignService.create360Campaign(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                    dispatch(this.getCampaignListBySurveyID({surveyId:data.surveyId ,companyId:data.companyId }));


                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.ADD_CAMPAIGN_REQUEST } }
    function success(campaign) { return { type: campaignConstants.ADD_CAMPAIGN_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.ADD_CAMPAIGN_FAILURE, error } }
}

function setExpireCampaign(data) {
    return dispatch => {
        dispatch(request());
        campaignService.setExpireCampaign(data)
            .then(
                campaign => {
                    dispatch(success(campaign));


                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.EXPIRE_CAMPAIGN_REQUEST } }
    function success(campaign) { return { type: campaignConstants.EXPIRE_CAMPAIGN_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.EXPIRE_CAMPAIGN_FAILURE, error } }
}
function resendCampain(data) {
    return dispatch => {
        dispatch(request());
        campaignService.resendCampain(data)
            .then(
                campaign => {
                    dispatch(success(campaign));


                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.RESEND_CAMPAIGN_REQUEST } }
    function success(campaign) { return { type: campaignConstants.RESEND_CAMPAIGN_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.RESEND_CAMPAIGN_FAILURE, error } }
}
function getCampaignListBySurveyID(data) {
   //////console.log("-----------------------------------------------------------------------------------data getCampaignListBySurveyID: ",data)
    return dispatch => {
        dispatch(request());
        campaignService.getCampaignListBySurveyID(data)
            .then(
                campaign => {
                    dispatch(success(campaign));

                    if(campaign && campaign.campaignList && campaign.campaignList.length>0){
                        let elementCampaign = campaign.campaignList[0];
                        dispatch(this.getCampaignActiveHistory({
                            "campaignId":elementCampaign.id
                        }));
                        dispatch(this.getCampaignActiveLaunchDateWise({
                            "campaignId":elementCampaign.id
                        }));
                    }
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.CAMPAIGN_GETALL_REQUEST } }
    function success(campaign) { return { type: campaignConstants.CAMPAIGN_GETALL_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.CAMPAIGN_GETALL_FAILURE, error } }
}

function getCampaignEmployeesByID(data) {
     return dispatch => {
         dispatch(request());
         campaignService.getCampaignEmployeesByID(data)
             .then(
                 campaign => {
                     dispatch(success(campaign));
                 },
                 error => {
                     
                     dispatch(failure(error))
                 }
             );
     };
     function request() { return { type: campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_REQUEST } }
     function success(campaign) { return { type: campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_SUCCESS, campaign } }
     function failure(error) { return { type: campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_FAILURE, error } }
 }

 
 function getCampaignCustomersByID(data) {
    return dispatch => {
        dispatch(request());
        campaignService.getCampaignCustomersByID(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.CAMPAIGN_CUSTOMERS_GETALL_REQUEST } }
    function success(campaign) { return { type: campaignConstants.CAMPAIGN_CUSTOMERS_GETALL_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.CAMPAIGN_CUSTOMERS_GETALL_FAILURE, error } }
}

 function getCampaignEmployeesByKeyWord(data) {
    return dispatch => {
        dispatch(request());
        campaignService.getCampaignEmployeesByKeyWord(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_KEYWORD_REQUEST } }
    function success(campaign) { return { type: campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_KEYWORD_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_KEYWORD_FAILURE, error } }
}

 function getCampaignReponseNumberByID(data) {
    return dispatch => {
        dispatch(request());
        campaignService.getCampaignReponseNumberByID(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.CAMPAIGN_EMPLOYEES_NUMMBERS_GETALL_REQUEST } }
    function success(campaign) { return { type: campaignConstants.CAMPAIGN_EMPLOYEES_NUMMBERS_GETALL_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.CAMPAIGN_EMPLOYEES_NUMMBERS_GETALL_FAILURE, error } }
}


function getCampaignCustomerReponseNumberByID(data) {
    return dispatch => {
        dispatch(request());
        campaignService.getCampaignCustomerReponseNumberByID(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.CAMPAIGN_CUSTOMER_NUMMBERS_GETALL_REQUEST } }
    function success(campaign) { return { type: campaignConstants.CAMPAIGN_CUSTOMER_NUMMBERS_GETALL_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.CAMPAIGN_CUSTOMER_NUMMBERS_GETALL_FAILURE, error } }
}

 
 function responseAnalysisByCampaignId(data) {
     return dispatch => {
         dispatch(request());
         campaignService.responseAnalysisByCampaignId(data)
             .then(
                 campaign =>{
                     dispatch(success(campaign));
                 },

                 error => {
                     
                     dispatch(failure(error))
                 }
             );
     };
     function request() { return { type: campaignConstants.GET_RESPONSE_ANALYTICS_REQUEST } }
     function success(campaign) { return { type: campaignConstants.GET_RESPONSE_ANALYTICS_SUCCESS, campaign } }
     function failure(error) { return { type: campaignConstants.GET_RESPONSE_ANALYTICS_FAILURE, error } }
 }

 function responseCustomerAnalysisByCampaignId(data) {
    return dispatch => {
        dispatch(request());
        campaignService.responseCustomerAnalysisByCampaignId(data)
            .then(
                campaign =>{
                    dispatch(success(campaign));
                },

                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.GET_RESPONSE_CUSTOMER_ANALYTICS_REQUEST } }
    function success(campaign) { return { type: campaignConstants.GET_RESPONSE_CUSTOMER_ANALYTICS_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.GET_RESPONSE_CUSTOMER_ANALYTICS_FAILURE, error } }
}

 
 function response360AnalysisByCampaignId(data) {
    return dispatch => {
        dispatch(request());
        campaignService.response360AnalysisByCampaignId(data)
            .then(
                campaign =>{
                    dispatch(success(campaign));
                },

                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.GET_RESPONSE360_ANALYTICS_REQUEST } }
    function success(campaign) { return { type: campaignConstants.GET_RESPONSE360_ANALYTICS_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.GET_RESPONSE360_ANALYTICS_FAILURE, error } }
}


 function resendMailInBulk(data) {
    return dispatch => {
        dispatch(request());
        campaignService.resendMailInBulk(data)
            .then(
                campaign =>{
                    dispatch(success(campaign));
                },

                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.RESEND_MAIL_IN_BULK_REQUEST } }
    function success(campaign) { return { type: campaignConstants.RESEND_MAIL_IN_BULK_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.RESEND_MAIL_IN_BULK_FAILURE, error } }
}


function resendCustomerMailInBulk(data) {
    return dispatch => {
        dispatch(request());
        campaignService.resendCustomerMailInBulk(data)
            .then(
                campaign =>{
                    dispatch(success(campaign));
                },

                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.RESEND_CUSTOMER_MAIL_IN_BULK_REQUEST } }
    function success(campaign) { return { type: campaignConstants.RESEND_CUSTOMER_MAIL_IN_BULK_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.RESEND_CUSTOMER_MAIL_IN_BULK_FAILURE, error } }
}

function resend360MailInBulk(data) {
    return dispatch => {
        dispatch(request());
        campaignService.resend360MailInBulk(data)
            .then(
                campaign =>{
                    dispatch(success(campaign));
                },

                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.RESEND_MAIL_360_IN_BULK_REQUEST } }
    function success(campaign) { return { type: campaignConstants.RESEND_MAIL_360_IN_BULK_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.RESEND_MAIL_360_IN_BULK_FAILURE, error } }
}

function getReminderHistoryByCamapaignAndCompanyId(data) {
     return dispatch => {
         dispatch(request());
         campaignService.getReminderHistoryByCamapaignAndCompanyId(data)
             .then(
                 campaign => {
                     dispatch(success(campaign));
                 },
                 error => {
                     
                     dispatch(failure(error))
                 }
             );
     };
     function request() { return { type: campaignConstants.GET_REMINDER_HISTORY_REQUEST } }
     function success(campaign) { return { type: campaignConstants.GET_REMINDER_HISTORY_SUCCESS, campaign } }
     function failure(error) { return { type: campaignConstants.GET_REMINDER_HISTORY_FAILURE, error } }
 }



 function getAllEmployees(data) {
    return dispatch => {
        dispatch(request());
        campaignService.getAllEmployees(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.ALLEMPLOYEE_GETALL_REQUEST } }
    function success(campaign) { return { type: campaignConstants.ALLEMPLOYEE_GETALL_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.ALLEMPLOYEE_GETALL_FAILURE, error } }
}
 


function sendAppSurveyNotify(data) {
    return dispatch => {
        dispatch(request());
        campaignService.sendAppSurveyNotify(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.SEND_APP_NOTIFY_REQUEST } }
    function success(campaign) { return { type: campaignConstants.SEND_APP_NOTIFY_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.SEND_APP_NOTIFY_FAILURE, error } }
}
 




function getCampaignById(data) {
    return dispatch => {
        dispatch(request());
        campaignService.getCampaignById(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.GET_CAMPAIGN_BY_ID_REQUEST } }
    function success(campaign) { return { type: campaignConstants.GET_CAMPAIGN_BY_ID_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.GET_CAMPAIGN_BY_ID_FAILURE, error } }
}
 





function getCampaignActiveHistory(data) {
    return dispatch => {
        dispatch(request());
        campaignService.getCampaignActiveHistory(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.GET_CAMPAIGN_ACTIVE_HISTORY_REQUEST } }
    function success(campaign) { return { type: campaignConstants.GET_CAMPAIGN_ACTIVE_HISTORY_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.GET_CAMPAIGN_ACTIVE_HISTORY_FAILURE, error } }
}




function getCampaign360EmployeesByID(data) {
    return dispatch => {
        dispatch(request());
        campaignService.getCampaign360EmployeesByID(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.GET_CAMPAIGN_360_EMPLOYEE_ID_REQUEST } }
    function success(campaign) { return { type: campaignConstants.GET_CAMPAIGN_360_EMPLOYEE_ID_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.GET_CAMPAIGN_360_EMPLOYEE_ID_FAILURE, error } }
}


function getCampaignDetailsById(data) {
    return dispatch => {
        dispatch(request());
        campaignService.getCampaignDetailsById(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.GET_CAMPAIGN_DETAILS_BY_ID_REQUEST } }
    function success(campaign) { return { type: campaignConstants.GET_CAMPAIGN_DETAILS_BY_ID_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.GET_CAMPAIGN_DETAILS_BY_ID_FAILURE, error } }
}


function updateReminderActiveStatus(data,temp) {
    return dispatch => {
        dispatch(request());
        campaignService.updateReminderActiveStatus(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                    dispatch(this.getCampaignListBySurveyID(temp));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.UPDATE_REMINDER_ACTIVE_STATUS_REQUEST } }
    function success(campaign) { return { type: campaignConstants.UPDATE_REMINDER_ACTIVE_STATUS_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.UPDATE_REMINDER_ACTIVE_STATUS_FAILURE, error } }
}



function getCampaignActiveLaunchDateWise(data) {
    return dispatch => {
        dispatch(request());
        campaignService.getCampaignActiveLaunchDateWise(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.GET_CAMPAIGN_ACTIVE_LAUNCH_DATE_WISE_REQUEST } }
    function success(campaign) { return { type: campaignConstants.GET_CAMPAIGN_ACTIVE_LAUNCH_DATE_WISE_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.GET_CAMPAIGN_ACTIVE_LAUNCH_DATE_WISE_FAILURE, error } }
}