import React from 'react';
// import { connect } from 'react-redux';



export default function Filter(props) {

  let { getAnsweredEmployeesData, handleSelectEmployee } = props;

  let [showFilter, handleOpenFilter] = React.useState(false);



  let { crrSelectedEmp } = props;





  return (
    <>


      <div className="cursor-pointer bg-white md:w-auto w-full text-gray-500 border flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">


        <div>
          <h1 className='text-xs font-medium text-gray-800'>Employee: </h1>
        </div>
        <p onClick={() => handleOpenFilter(!showFilter)} className="px-2 text-xs ">{crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Overall"}</p>


        <span onClick={() => handleOpenFilter(!showFilter)} className="material-icons ">arrow_drop_down</span>

        {showFilter && getAnsweredEmployeesData && getAnsweredEmployeesData.length > 0 ?
          <div className="absolute right-0 z-10 w-full p-3 overflow-x-auto text-xs font-normal bg-white rounded-md shadow-lg top-10 h-60 customscroll2">

            <div className="flex items-center py-2 m-1 cursor-pointer"
              onClick={() => { handleSelectEmployee({}); handleOpenFilter(false) }}
            >
              <input type="checkbox" checked={!(crrSelectedEmp && crrSelectedEmp.emp_id)} onChange={() => { }} />
              <label className="ml-2 font-medium">{"Overall"}</label>
            </div>

            {getAnsweredEmployeesData && getAnsweredEmployeesData.length > 0 ?
              getAnsweredEmployeesData.map((emp, index) =>
                <div key={index} className="flex items-center py-2 m-1 cursor-pointer"
                  onClick={() => { handleSelectEmployee(emp); handleOpenFilter(false) }}
                >
                  <input type="checkbox" checked={crrSelectedEmp.emp_id === emp.emp_id} onChange={() => { }} />
                  <label className="ml-2 font-medium">{emp.name}</label>
                </div>
              ) : null}
          </div>
          : null}
      </div>








      {/* </div> */}

    </>
  );
}
