import React, { Component } from "react";
import { connect } from "react-redux";
import "tippy.js/dist/tippy.css";
import HorizontalBar from "./Charts/HorizontalBar";

class ClusterChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedText: "",
    };
  }

  componentDidMount() {}

  handleKeyword = (data) => {
    let { handleKeywordCall } = this.props;
    handleKeywordCall(data.text);
    this.setState({ SelectedText: data.text + " (" + data.value + ")" });
  };

  handleKeywordTooltip = (data) => {
    this.setState({ SelectedText: data.text + " (" + data.value + ")" });
  };

  render() {
    let {
      getClusterLabel,
      getClusterKeywordsData,
      getClusterSentencesCall,
      loading,
      clusterNumber,
      crrkeyword
    } = this.props;

    return (
      <>
        <div className=" bg-white hover:shadow-lg duration-150 transition  border  rounded-lg   cursor-pointer lg:col-span-1">
          <h1 class="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap px-6 py-4 border-b ">
            {getClusterLabel(clusterNumber)} {' '} {'Keywords'}
          </h1>

          {/* chart */}

          {true ? (
            <div className="p-6">
              <div className="w-full">
                <div className="space-y-4 capitalize ">
                  <div
                    className="cursor-pointer flex items-center space-x-4 text-[#3D405B]"
                    onClick={() => getClusterSentencesCall("")}
                  >

                    {!crrkeyword && !loading?
                    <span className="material-symbols-outlined">
                    check_box
                    </span>
                    :
                    <span className="material-symbols-outlined">
                      check_box_outline_blank
                    </span>
                    }

                    <span >{"All Keywords"}</span>
                  </div>
                  {getClusterKeywordsData && getClusterKeywordsData.length > 0
                    ? getClusterKeywordsData.map((element, index) => (
                        <div
                          className="cursor-pointer  flex space-x-4 text-[#3D405B] "
                          onClick={() => getClusterSentencesCall(element)}
                        >
                          {crrkeyword === element?
                          <span className="material-symbols-outlined">
                          check_box
                          </span>
                          :
                          <span className="material-symbols-outlined">
                            check_box_outline_blank
                          </span>
                          }
                          
                          <div className="">{element}</div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
          ) : (
            <>
              {!loading ? (
                <div className="text-center text-2XL text-gray-400 py-20">
                  {"Error"}
                </div>
              ) : null}
            </>
          )}
        </div>

        {/* 
                    <div className="flex justify-between items-center">
                    <h1 className="text-sm text-gray-900 font-semibold">Word Cloud
                    <span className="text-blue-500 font-normal">{" ( A word cloud is a collection or cluster of words depicted in different sizes. The bigger and bolder the word appears, the more often it’s mentioned within a given text and the more important it is,on right side words with count bar graph is there. ) "}</span>
                    </h1>
                    </div>
                    <div style={{height:'400px',marginTop:'10px'}}>
                      {words && words.length>0?
                        <ReactWordcloud 
                        callbacks={callbacks}
                        options={options}
                        words={words} 
                        />
                      :
                <>
                {!loading?
                <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>:null}
                </>
                      }
                    </div>
                    <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>

             */}
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(ClusterChart);
