import React, { Component } from 'react';
import { connect } from 'react-redux';
import BarChart from './Charts/BarChart';
import RadarChart from './Charts/RadarChart';
import CoreCircles from './Charts/CoreCircles';
import Commentary from '../../../Index/Components/Commentary/Commentary';
import Filter from './Filter/Filter';
import HeatMap from './Charts/HeatMap';
import { CSVLink } from "react-csv";
import EDAOrgModal from './EDAOrg/EDAModal';

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CurrentFilterName:'none',
      showTab:1,
      ListName:'Demographic',


      currFilterValue: 'none',
      currFilterName: 'none',


      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: ''

    }
  }
  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }
  
  handleMultifilterIndexScore2=(finalFilter, name)=>{
    let {handleMultifilterQuesAvgSummary}= this.props;
    this.setState({FilterValues:finalFilter, CurrentFilterName:name, ListName:name});
    handleMultifilterQuesAvgSummary(finalFilter,true, name);

  }




  handleTab=(data)=>{
    this.setState({showTab:data})
  }


  handleFilternameSave=(name)=>{
    this.setState({CurrentFilterName:name})
  }


  handleSelectEDA=(data)=>{
    //////console.log("data--------->",data);
    let score = data && data.data?data.data:"";

    if(score && score!=="NaN"){
      let { finalFilter, ListName } = this.state;
      let driverName = data && data.name?data.name:"";
      let { getEDAOrgdata } = this.props;
      if(driverName){
        getEDAOrgdata(driverName,"overall",finalFilter, ListName);
        setTimeout(()=>{
          this.setState({ showEDAModal: true, crrdriverName: driverName});
        },500)
      }
    }

  }


  handleDMGOrgFilter=(driverName,xAxisName, check)=>{
    //////console.log("driverName--------->",driverName);
    //////console.log("xAxisName--------->",xAxisName);
    //////console.log("check----handleDMGOrgFilter----->",check);

    if(check && check!=="NaN"){
      let { finalFilter, ListName } = this.state;
      let { getEDAOrgdata } = this.props;
      if(driverName){
        getEDAOrgdata(driverName,"heatmap",finalFilter, ListName, xAxisName);
        setTimeout(()=>{
          this.setState({ showEDAModal: true, crrdriverName: driverName});
        },500)
      }
    }

  }

  closeEDAModal=()=>{
    this.setState({ showEDAModal: false, crrdriverName:""});
  }


  render() {
    let { 
      
      getQuestionAvgScoreData,
      customeScoreData,

      
      getdmgOrgManyFilterData, getEMPFilterLabel, getIndexByFilterDMGData, CountRestrict, validateIndex, GetParameterLabel, getDistributionIndexData, getIndexFilterData,EmpFilterData, getIndexData, indexType, optionType, getCommentData, loading, getSurveyData } = this.props;
    let { ListName, CurrentFilterName,showTab ,FilterValues  } = this.state;

    let EmpFilterData2 = []
    if(EmpFilterData && EmpFilterData.length>0){
     EmpFilterData.forEach((emp)=>{
       if(emp.name !=='ManagerPID'){
         EmpFilterData2.push(emp);
       }
     })
    }

    

    //console.log('getQuestionAvgScoreData---------->',getQuestionAvgScoreData);
    //console.log('customeScoreData---------->',customeScoreData);

    let itemList = getQuestionAvgScoreData && getQuestionAvgScoreData[0] && getQuestionAvgScoreData[0]["total"]?getQuestionAvgScoreData[0]["total"]:[]

    let parameterList = []
    if(itemList && itemList.length>0){
      itemList.forEach((item)=>{
        if(item["_id"].parameterBucketName === (customeScoreData && customeScoreData.index?customeScoreData.index:"")){
          let getIndex = parameterList.findIndex(prev=>prev.index === item["_id"].parameterBucketName);
          if(getIndex===-1){
            parameterList.push({
              index:item["_id"].parameterBucketName,
              data:[{
                count:item.count,
                sum:item.sum,
                ...item["_id"]
              }]
            })
          }else{
            parameterList[getIndex]["data"].push({
              count:item.count,
              sum:item.sum,
              ...item["_id"]
            })
          }
        }


      });
    }




    let parameter2List = []
    if(parameterList && parameterList.length>0){
      parameterList.forEach((item)=>{
        let temp = {
          "index":item.index,
          "DimenionList":[]
        }
        if(item && item.data && item.data.length>0){
          item.data.forEach((item2)=>{



            let getIndex = temp["DimenionList"].findIndex(prev=>prev.dimension === item2.parameterDimensionName);
            if(getIndex===-1){
              temp["DimenionList"].push({
                dimension:item2.parameterDimensionName,
                inner:[{
                  [ListName]:item2[ListName],
                  count:item2.count,
                  sum:item2.sum,
                  answer:item2.answer
                }]
              })
            }else{
              temp["DimenionList"][getIndex]["inner"].push({
                [ListName]:item2[ListName],
                count:item2.count,
                sum:item2.sum,
                answer:item2.answer
              });

            }



          })
        }

        parameter2List.push(temp)


      });
    }


    //////console.log("parameter2List-------------->",parameter2List)

    let DimenionList = parameter2List && parameter2List[0] && parameter2List[0].DimenionList && parameter2List[0].DimenionList.length>0?parameter2List[0].DimenionList:[];

    let DimenScoreList = []
    if(DimenionList && DimenionList.length>0){
      DimenionList.forEach((item2)=>{
        let scoreOverall = 0;
        let countOverall = 0;
        if(item2 && item2.inner && item2.inner.length>0){
          item2.inner.forEach((item3)=>{
            countOverall++
            scoreOverall += (Number(item3.sum)/Number(item3.count))
          })
        }
        
        let newscoreOverall = scoreOverall/countOverall;
        let finalScore = newscoreOverall?newscoreOverall.toFixed(2):0

        DimenScoreList.push({
          "dimension":item2.dimension,
          "score":finalScore
        })

        

      })
    }




    let DimenLabelList = []

    let parameter3List = []
    if(parameter2List && parameter2List.length>0){
      parameter2List.forEach((item)=>{

        let temp = {
          "index":item.index,
          "DimenionList":[]
        }
        if(item && item.DimenionList.length>0){
          item.DimenionList.forEach((itemX)=>{
            let temp2 = {
              "dimension":itemX.dimension,
              "inner":[]
            }

            let getDimenIndex = DimenLabelList.findIndex(prev=>prev === itemX.dimension)
            if(getDimenIndex===-1){
              DimenLabelList.push(itemX.dimension)

            }

            if(itemX && itemX.inner.length>0){
              itemX.inner.forEach((itemY)=>{


    

                let getIndex = temp2.inner.findIndex(prev=>prev.dmgValue  === itemY[ListName]);
                if(getIndex ===-1){



                  temp2.inner.push({
                    dmgValue:itemY[ListName],
                    "inner2":[{
                      count:itemY.count,
                      sum:itemY.sum,
                      answer:itemY.answer
                    }]
                  })
                }
                else{
                  temp2.inner[getIndex]["inner2"].push({
                    count:itemY.count,
                    sum:itemY.sum,
                    answer:itemY.answer
                  })
                }

              })

            }

            temp["DimenionList"].push(temp2)
              


          })
        }

        parameter3List.push(temp)

      })
    }

    //////console.log("parameter3List------->",parameter3List)



    let parameter4List = []
    let DimensionList  = parameter3List && parameter3List[0] && parameter3List[0].DimenionList && parameter3List[0].DimenionList.length>0?parameter3List[0].DimenionList:[];

    if(DimensionList && DimensionList.length>0){
      DimensionList.forEach((item)=>{


        if(item && item.inner && item.inner.length>0){
          item.inner.forEach((item2)=>{

            let getIndex = parameter4List.findIndex(prev=>prev.dmgValue === item2.dmgValue)
            if(getIndex===-1){
              parameter4List.push({
                dmgValue:item2.dmgValue,
                "Data":[{
                    inner2:item2.inner2,
                    dimension:item.dimension
                  }]
              })




            }
            else{
              parameter4List[getIndex]["Data"].push({
                inner2:item2.inner2,
                dimension:item.dimension
              })
            }



          })
        }


      });
    }




    console.log("EmpFilterData2--------->",EmpFilterData2)



    return (
      <>

      <div className=" md:flex  justify-between items-center ">
        <Filter
        showFilter={this.state.showFilter}
        showValue={this.state.showValue}
        closeFilter={this.closeFilter}

        EmpFilterData2={EmpFilterData2}
        getIndexFilterData={getIndexByFilterDMGData}
        handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        getEMPFilterLabel={getEMPFilterLabel}
        />

        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        </div>



      {FilterValues && FilterValues.length>0?
      <>


          <div className=" " >

                    <div className="w-full" >

                      <div className="grid md:grid-cols-1 grid-cols-1 gap-4 ">

                        <div className="p-4 bg-white border">

                        <h1 className="font-medium text-sm pb-2">Score Demographics</h1>


                            {parameter4List && parameter4List.length>0?
                              <HeatMap 
                                handleDistributionFilterNameSave={this.handleDistributionFilterNameSave}
                                handleDistributionFilterValueSave={this.handleDistributionFilterValueSave}
                                getDistributionIndexData={getDistributionIndexData}
                                GetParameterLabel={GetParameterLabel}
                                currFilterName={CurrentFilterName}
                                FilterValues={FilterValues}
                                CountRestrict={CountRestrict}
                                handleDMGOrgFilter={this.handleDMGOrgFilter}


                                parameter4List={parameter4List}
                                DimenLabelList={DimenLabelList}
                                ListName={ListName}

                              />
                              :null}



                        </div>    
                        
                    </div>
            
          
          </div>
        </div>




      </>

      :
        <>
                      {true?

                  <div className="w-full" >
                    <div className="grid md:grid-cols-1 grid-cols-1 gap-4 ">
        
                      <div className="bg-white rounded w-full  px-4 py-2">
                      
                      <div className="px-2 py-4 text-base  font-semibold flex items-center">
                        <h1 className="font-medium text-lg  pb-2">Dimensions</h1>

                        

                        <div className="text-lg ">
                              <i onClick={()=>this.handleTab(1)} style={{opacity:(showTab===1)?1:0.3}} className="cursor-pointer fa fa-bar-chart mx-2"></i> 
                              <i onClick={()=>this.handleTab(2)} style={{opacity:(showTab===2)?1:0.3}} className="cursor-pointer fa fa-circle-o-notch mx-2"></i> 

                        </div>
                      </div>

                        {showTab===1?
                          <BarChart handleSelectEDA={this.handleSelectEDA} DimenScoreList={DimenScoreList} GetParameterLabel={GetParameterLabel} />
                        :null}
                      </div>
  
                  </div>
                </div>
              :
              <>
              {!loading?
              <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated organization core values!</div>
              :null}
              </>
              }

              </>}




              <EDAOrgModal
              showModal={this.state.showEDAModal}
              closeModal={this.closeEDAModal}
              getdmgOrgManyFilterData={getdmgOrgManyFilterData}
              indexType={indexType}
              dmgLevel={this.state.ListName}
              dmgValue={this.state.crrdriverName}
              GetParameterLabel={GetParameterLabel}

            />


                
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
