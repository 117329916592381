import * as React from 'react';

const PageTemplate = props => {

  let Number1 = props && props.pageNum ? props.pageNum : 0;
  let SurveyLogo = props && props.surveyLogo ? props.surveyLogo : "/img/logo/happypluslogo.png";

  let pageNo = 0
  if (Number1 > 2) {
    pageNo = Number1 - 2
  }
  return (
    <div className='absolute flex items-center justify-between bottom-8 left-8 right-8 !w-full'>

      <img src={SurveyLogo} className="object-cover w-32 " alt="default_survey_logo" />
      <p className='text-sm text-[#212121]/70'>Copyright © 2024, HappyPlus, Inc. All rights reserved</p>

      <img src={"/img/logo/happypluslogo.png"} className="object-cover w-32 " alt="default_survey_logo" />

    </div>
  );
};

export default PageTemplate;