import {
  campaignConstants
} from '../_constants';

export function campaign(state = {}, action) {

  switch (action.type) {

      case campaignConstants.ADD_CAMPAIGN_REQUEST:
        return {
          ...state,
          loading:true
        };
      case campaignConstants.ADD_CAMPAIGN_SUCCESS:
        return {
          ...state,
          addCampaignSuccess: true,
          expireCampaignSuccess: false,        
          loading:false, 

          createNewCampaign: action.campaign.createNewCampaign,
        };
      case campaignConstants.ADD_CAMPAIGN_FAILURE:
        return {
          ...state,
          loading:false, 
          error: action.error
        };
  
        
        case campaignConstants.ADD_CAMPAIGN_CUSTOMER_REQUEST:
          return {
            ...state,
            loading:true
          };
        case campaignConstants.ADD_CAMPAIGN_CUSTOMER_SUCCESS:
          return {
            ...state,
            addCampaignSuccess: true,
            expireCampaignSuccess: false,        
            loading:false, 
  
            createNewCampaign: action.campaign.createNewCampaign,
          };
        case campaignConstants.ADD_CAMPAIGN_CUSTOMER_FAILURE:
          return {
            ...state,
            loading:false, 
            error: action.error
          };
    



        case campaignConstants.EXPIRE_CAMPAIGN_REQUEST:
          return {
            ...state,
            loading:true
          };
        case campaignConstants.EXPIRE_CAMPAIGN_SUCCESS:
          return {
            ...state,
            loading:false, 
            expireCampaignSuccess: true
          };
        case campaignConstants.EXPIRE_CAMPAIGN_FAILURE:
          return {
            ...state,
            loading:false, 
            error: action.error
          };

          
          case campaignConstants.RESEND_CAMPAIGN_REQUEST:
            return {
              ...state,
              loading:true
            };
          case campaignConstants.RESEND_CAMPAIGN_SUCCESS:
            return {
              ...state,
              loading:false, 
              resendCampaignSuccess: true
            };
          case campaignConstants.RESEND_CAMPAIGN_FAILURE:
            return {
              ...state,
              loading:false, 
              error: action.error
            };         


      case campaignConstants.CAMPAIGN_GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case campaignConstants.CAMPAIGN_GETALL_SUCCESS:
        return {
          ...state,   
          expireCampaignSuccess: false,  
          addCampaignSuccess:false,   
          loading:false,    
          campaignList: action.campaign.campaignList
        };
      case campaignConstants.CAMPAIGN_GETALL_FAILURE:
        return {
          ...state,
          loading:false, 
          error: action.error
        };


        case campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_REQUEST:
          return {
            ...state,
            loading: true
          };
        case campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_SUCCESS:
          return {
            ...state,
            getCampaignEmployeeSuccess:true,
            expireCampaignSuccess: false,  
            addCampaignSuccess:false,  
            loading:false,     
            resendCampaignSuccess: false,


            getCampaignEmployeesByIDData: action.campaign.getCampaignEmployeesByIDData.list,
            getCampaignEmployeesByIDTotal: action.campaign.getCampaignEmployeesByIDData.total

          };
        case campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_FAILURE:
          return {
            ...state,
            loading:false, 
            error: action.error
          };
  

          
        case campaignConstants.CAMPAIGN_CUSTOMERS_GETALL_REQUEST:
          return {
            ...state,
            loading: true
          };
        case campaignConstants.CAMPAIGN_CUSTOMERS_GETALL_SUCCESS:
          return {
            ...state,
            getCampaignEmployeeSuccess:true,
            expireCampaignSuccess: false,  
            addCampaignSuccess:false,  
            loading:false,     
            resendCampaignSuccess: false,


            getCampaignCustomersByIDData: action.campaign.getCampaignCustomersByIDData.list,
            getCampaignCustomersByIDTotal: action.campaign.getCampaignCustomersByIDData.total

          };
        case campaignConstants.CAMPAIGN_CUSTOMERS_GETALL_FAILURE:
          return {
            ...state,
            loading:false, 
            error: action.error
          };
  



          case campaignConstants.CAMPAIGN_EMPLOYEES_NUMMBERS_GETALL_REQUEST:
            return {
              ...state,
              loading: true
            };
          case campaignConstants.CAMPAIGN_EMPLOYEES_NUMMBERS_GETALL_SUCCESS:
            return {
              ...state,
              getCampaignReponseNumberByIDSuccess:true,
              expireCampaignSuccess: false,  
              addCampaignSuccess:false,  
              loading:false,     
              resendCampaignSuccess: false,
  
  
              getCampaignReponseNumberByIDData: action.campaign.getCampaignReponseNumberByIDData
  
            };
          case campaignConstants.CAMPAIGN_EMPLOYEES_NUMMBERS_GETALL_FAILURE:
            return {
              ...state,
              loading:false, 
              error: action.error
            };



            case campaignConstants.CAMPAIGN_CUSTOMER_NUMMBERS_GETALL_REQUEST:
              return {
                ...state,
                loading: true
              };
            case campaignConstants.CAMPAIGN_CUSTOMER_NUMMBERS_GETALL_SUCCESS:
              return {
                ...state,
                getCampaignReponseNumberByIDSuccess:true,
                expireCampaignSuccess: false,  
                addCampaignSuccess:false,  
                loading:false,     
                resendCampaignSuccess: false,
    
    
                getCampaignReponseNumberByIDData: action.campaign.getCampaignReponseNumberByIDData
    
              };
            case campaignConstants.CAMPAIGN_CUSTOMER_NUMMBERS_GETALL_FAILURE:
              return {
                ...state,
                loading:false, 
                error: action.error
              };


          case campaignConstants.GET_RESPONSE_ANALYTICS_REQUEST:
            return {
              ...state,
              loading: true
            };
          case campaignConstants.GET_RESPONSE_ANALYTICS_SUCCESS:
            return {
              ...state,   
              getResponseAnalyticsSuccess: true,
              addCampaignSuccess:false,
              loading:false, 
              resendCampaignSuccess: false,

              responseAnalysisByCampaignIdData: action.campaign.responseAnalysisByCampaignIdData

            };
          case campaignConstants.GET_RESPONSE_ANALYTICS_FAILURE:
            return {
              ...state,
              loading:false, 
              error: action.error
            };


            
          case campaignConstants.GET_RESPONSE_CUSTOMER_ANALYTICS_REQUEST:
            return {
              ...state,
              loading: true
            };
          case campaignConstants.GET_RESPONSE_CUSTOMER_ANALYTICS_SUCCESS:
            return {
              ...state,   
              getResponseAnalyticsSuccess: true,
              addCampaignSuccess:false,
              loading:false, 
              resendCampaignSuccess: false,

              responseAnalysisByCampaignIdData: action.campaign.responseAnalysisByCampaignIdData

            };
          case campaignConstants.GET_RESPONSE_CUSTOMER_ANALYTICS_FAILURE:
            return {
              ...state,
              loading:false, 
              error: action.error
            };



            
          case campaignConstants.GET_RESPONSE360_ANALYTICS_REQUEST:
            return {
              ...state,
              loading: true
            };
          case campaignConstants.GET_RESPONSE360_ANALYTICS_SUCCESS:
            return {
              ...state,   
              getResponseAnalyticsSuccess: true,
              addCampaignSuccess:false,
              loading:false, 
              resendCampaignSuccess: false,

              responseAnalysisByCampaignIdData: action.campaign.responseAnalysisByCampaignIdData

            };
          case campaignConstants.GET_RESPONSE360_ANALYTICS_FAILURE:
            return {
              ...state,
              loading:false, 
              error: action.error
            };





            case campaignConstants.RESEND_MAIL_IN_BULK_REQUEST:
              return {
                ...state,
                loading: true
              };
            case campaignConstants.RESEND_MAIL_IN_BULK_SUCCESS:
              return {
                ...state,   
                getResendBulkMailSuccess: true ,
                addCampaignSuccess:false,
                loading:false, 
                getResponseAnalyticsSuccess: false

              };
            case campaignConstants.RESEND_MAIL_IN_BULK_FAILURE:
              return {
                ...state,
                loading:false, 
                error: action.error
              };




              case campaignConstants.RESEND_CUSTOMER_MAIL_IN_BULK_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case campaignConstants.RESEND_CUSTOMER_MAIL_IN_BULK_SUCCESS:
                return {
                  ...state,   
                  getResendBulkMailSuccess: true ,
                  addCampaignSuccess:false,
                  loading:false, 
                  getResponseAnalyticsSuccess: false
  
                };
              case campaignConstants.RESEND_CUSTOMER_MAIL_IN_BULK_FAILURE:
                return {
                  ...state,
                  loading:false, 
                  error: action.error
                };
  
  


              

            case campaignConstants.RESEND_MAIL_360_IN_BULK_REQUEST:
              return {
                ...state,
                loading: true
              };
            case campaignConstants.RESEND_MAIL_360_IN_BULK_SUCCESS:
              return {
                ...state,   
                getResendBulkMailSuccess: true ,
                addCampaignSuccess:false,
                loading:false, 
                getResponseAnalyticsSuccess: false

              };
            case campaignConstants.RESEND_MAIL_360_IN_BULK_FAILURE:
              return {
                ...state,
                loading:false, 
                error: action.error
              };




              case campaignConstants.GET_REMINDER_HISTORY_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case campaignConstants.GET_REMINDER_HISTORY_SUCCESS:
                return {
                  ...state,   
                  getReminderHistorySuccess: true,  
                  loading:false,  
                  getReminderHistoryList: action.campaign.getReminderHistoryData.list,
                  getReminderHistoryTotal: action.campaign.getReminderHistoryData.total

                };
              case campaignConstants.GET_REMINDER_HISTORY_FAILURE:
                return {
                  ...state,
                  loading:false, 
                  error: action.error
                };





      case campaignConstants.ALLEMPLOYEE_GETALL_REQUEST:
          return {
          ...state,
          loading: true
        };
      case campaignConstants.ALLEMPLOYEE_GETALL_SUCCESS:
          return {
          ...state,
          addCompanySuccess: false,
          loading:false, 
          expireSurveySuccess: false,
          
          allEmployeesList: action.campaign.allEmployeesList.list
        };
      case campaignConstants.ALLEMPLOYEE_GETALL_FAILURE:
          return {
          ...state,
          loading:false, 
          error: action.error
         };




         case campaignConstants.SEND_APP_NOTIFY_REQUEST:
          return {
          ...state,
          loading: true
        };
      case campaignConstants.SEND_APP_NOTIFY_SUCCESS:
          return {
          ...state,
          loading:false, 
          sendAppSurveyNotifySuccess: true
        };
      case campaignConstants.SEND_APP_NOTIFY_FAILURE:
          return {
          ...state,
          loading:false, 
          error: action.error
         };




         


         case campaignConstants.GET_CAMPAIGN_BY_ID_REQUEST:
          return {
          ...state,
          loading: true
        };
      case campaignConstants.GET_CAMPAIGN_BY_ID_SUCCESS:
          return {
          ...state,
          loading:false, 
          getCampaignByIdSuccess: true,
          getCampaignByIdData: action.campaign.campaigndetails
        };
      case campaignConstants.GET_CAMPAIGN_BY_ID_FAILURE:
          return {
          ...state,
          loading:false, 
          error: action.error
         };





         case campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_KEYWORD_REQUEST:
          return {
            ...state,
            loading: true
          };
        case campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_KEYWORD_SUCCESS:
          return {
            ...state,
            getCampaignEmployeeSuccess:true,
            expireCampaignSuccess: false,  
            addCampaignSuccess:false,  
            loading:false,     
            resendCampaignSuccess: false,


            getCampaignEmployeesByIDData: action.campaign.getCampaignEmployeesByIDData.list,
            getCampaignEmployeesByIDTotal: action.campaign.getCampaignEmployeesByIDData.total

          };
        case campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_KEYWORD_FAILURE:
          return {
            ...state,
            loading:false, 
            error: action.error
          };
  






          
      case campaignConstants.GET_CAMPAIGN_ACTIVE_HISTORY_REQUEST:
        return {
        ...state,
        loading: true
      };
    case campaignConstants.GET_CAMPAIGN_ACTIVE_HISTORY_SUCCESS:
        return {
        ...state,
        loading: false,
        getCampaignActiveHistorySuccess: false,
        getCampaignActiveHistoryData: action.campaign.getCampaignActiveHistoryData
      };
    case campaignConstants.GET_CAMPAIGN_ACTIVE_HISTORY_FAILURE:
        return {
        ...state,
        loading:false, 
        error: action.error
       };




          
       case campaignConstants.GET_CAMPAIGN_360_EMPLOYEE_ID_REQUEST:
        return {
        ...state,
        loading: true
      };
    case campaignConstants.GET_CAMPAIGN_360_EMPLOYEE_ID_SUCCESS:
        return {
        ...state,
        loading: false,
        getCampaign360EmployeesByIDSuccess: false,
        getCampaign360EmployeesByIDData: action.campaign.getCampaign360EmployeesByIDData.list,
        getCampaignEmployeesByIDTotal: action.campaign.getCampaign360EmployeesByIDData.total
      };
    case campaignConstants.GET_CAMPAIGN_360_EMPLOYEE_ID_FAILURE:
        return {
        ...state,
        loading:false, 
        error: action.error
       };


          
       case campaignConstants.GET_CAMPAIGN_DETAILS_BY_ID_REQUEST:
        return {
        ...state,
        loading: true
      };
    case campaignConstants.GET_CAMPAIGN_DETAILS_BY_ID_SUCCESS:
        return {
        ...state,
        loading: false,
        getCampaignDetailsByIdSuccess: false,
        getCampaignDetailsByIdData: action.campaign.getCampaignDetailsByIdData
      };
    case campaignConstants.GET_CAMPAIGN_DETAILS_BY_ID_FAILURE:
        return {
        ...state,
        loading:false, 
        error: action.error
       };




                 
       case campaignConstants.UPDATE_REMINDER_ACTIVE_STATUS_REQUEST:
        return {
        ...state,
        loading: true
      };
    case campaignConstants.UPDATE_REMINDER_ACTIVE_STATUS_SUCCESS:
        return {
        ...state,
        loading: false,
        updateReminderActiveStatusSuccess: false,
      };
    case campaignConstants.UPDATE_REMINDER_ACTIVE_STATUS_FAILURE:
        return {
        ...state,
        loading:false, 
        error: action.error
       };



       case campaignConstants.GET_CAMPAIGN_ACTIVE_LAUNCH_DATE_WISE_REQUEST:
        return {
        ...state,
        loading: true
      };
    case campaignConstants.GET_CAMPAIGN_ACTIVE_LAUNCH_DATE_WISE_SUCCESS:
        return {
        ...state,
        loading: false,
        getCampaignActiveLaunchDateWiseSuccess: false,
        getCampaignActiveLaunchDateWiseData: action.campaign.getCampaignActiveLaunchDateWiseData
      };
    case campaignConstants.GET_CAMPAIGN_ACTIVE_LAUNCH_DATE_WISE_FAILURE:
        return {
        ...state,
        loading:false, 
        error: action.error
       };


       case campaignConstants.ADD_ASSESSMENT_CAMPAIGN_REQUEST:
        return {
        ...state,
        loading: true
      };
    case campaignConstants.ADD_ASSESSMENT_CAMPAIGN_SUCCESS:
        return {
        ...state,
        loading: false,
        createAsessmentCampaignSuccess: false,
        createAsessmentCampaignData: action.campaign.createAsessmentCampaignData
      };
    case campaignConstants.ADD_ASSESSMENT_CAMPAIGN_FAILURE:
        return {
        ...state,
        loading:false, 
        error: action.error
       };



       case campaignConstants.SAVE_CAMPAIGN_TEMPLATE_REQUEST:
        return {
        ...state,
        loading: true
      };
    case campaignConstants.SAVE_CAMPAIGN_TEMPLATE_SUCCESS:
        return {
        ...state,
        loading: false,
        saveCampaignTemplateSuccess: false,
        saveCampaignTemplateData: action.campaign.saveCampaignTemplateData
      };
    case campaignConstants.SAVE_CAMPAIGN_TEMPLATE_FAILURE:
        return {
        ...state,
        loading:false, 
        error: action.error
       };



       case campaignConstants.GET_CAMPAIGN_TEMPLATE_REQUEST:
        return {
        ...state,
        loading: true
      };
    case campaignConstants.GET_CAMPAIGN_TEMPLATE_SUCCESS:
        return {
        ...state,
        loading: false,
        getCampaignTemplateListSuccess: false,
        getCampaignTemplateListData: action.campaign.getCampaignTemplateListData
      };
    case campaignConstants.GET_CAMPAIGN_TEMPLATE_FAILURE:
        return {
        ...state,
        loading:false, 
        error: action.error
       };



    default:
      return state
  }
}