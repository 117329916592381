import React, { Component } from "react";
import { connect } from "react-redux";

export default function SummaryData(props) {
  let { handleAddNudgeModal, question, getNudgeLibListData, addNudgeToQues, getFormattedParameter, GetParameterLabel, crrRangeList } = props;
  let [menu, setMenu] = React.useState(1);
  let [showNudgeText, expandNudgeText] = React.useState(false);


  return (
    <>
      <div className="bg-black bg-opacity-40 fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full flex items-center justify-center lg:p-0 p-4">
        <div className="xl:w-8/12 lg:w-5/6 w-full  mx-auto bg-white rounded-md   ">
          <div className="flex items-center justify-between p-4 border-b">
            {" "}
            <h1 className="text-lg p-1 font-semibold capitalize">{"Nudge Library"}</h1>
            <span
              onClick={() => handleAddNudgeModal(false)}
              className=" material-symbols-outlined bg-gray-50 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150"
            >
              close
            </span>
          </div>


          <div
            className="lg:p-6 p-4  h-[40rem]  overflow-y-auto space-y-6" >
            <div className=" space-y-4" >

              <div className="border border-4 border-gray-500  p-4 bg-white rounded-lg flex justify-between ">
                <div>
                  <div>
                    <div className="flex  ">
                      <span className="px-2 px-1 text-sm leading-7 font-bold">{"Question:"}</span>
                      <span className="px-2 px-1 text-sm leading-7 font-bold">{question.name}</span>
                    </div>



                    <ul className="flex py-1">
                      <li className="px-2 text-sm leading-7  text-purple-500">
                        {(getFormattedParameter(question, "parameterBucketId", "name"))}
                      </li>
                      <li className="px-2 text-sm leading-7  text-green-500">
                        {(getFormattedParameter(question, "parameterDimensionId", "name"))}
                      </li>
                      <li className="px-2 text-sm leading-7 text-green-500 rounded-md mx-4">
                        {crrRangeList && crrRangeList.length > 0?"Nudge Added!":""}
                      </li>
                    </ul>


                    {crrRangeList && crrRangeList.length > 0 && (<>
                      {showNudgeText ?
                      <div onClick={() => expandNudgeText(false)} className="mx-2 text-sm cursor-pointer text-red-500 underline">Show Less...</div>
                      :
                      <div onClick={() => expandNudgeText(true)} className="mx-2 text-sm cursor-pointer text-blue-500 underline">Show More...</div>

                    }
                    </>)}
               


                    {showNudgeText && (<>
                      <ul className="">
                        {crrRangeList && crrRangeList.length > 0 ?
                          crrRangeList.map((inner) =>
                            <>
                              <li className="p-2 text-sm leading-7  ">
                                <div className="font-bold p-2">{inner.label}</div>
                                <div className="px-2">{'"' + question.name + '" ' + inner.text}</div>
                              </li>
                            </>
                          ) : null}

                      </ul>
                    </>)}


                  </div>
                </div>

                <div>
                  {/* <button onClick={() => this.handleAddNudgeModal(true)} className="cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] ">{"Add Nudge"}</button> */}

                </div>
              </div>


              <div className="items-center justify-between border-b md:flex">


                <div className="flex space-x-2 overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 whitespace-nowrap tab-scroll scollar-sm">

                  <span onClick={() => setMenu(1)}
                    className={menu === 1 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                      "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}>
                    {"Library"}
                  </span>
                  <span onClick={() => setMenu(2)}
                    className={menu === 2 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                      "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}>
                    {"Add New"}
                  </span>

                </div>
              </div>




              {menu === 1 && (<>
                <h1 className="text-lg p-1 font-semibold capitalize ">{"Select Nudge from Library for the above question"}</h1>

                {getNudgeLibListData && getNudgeLibListData.length > 0 ?
                  getNudgeLibListData.map((ques, index) =>
                    <div className="border p-4 bg-slate-50 rounded-lg flex justify-between">
                      {/* <h1 className="text-lg  font-medium">{GetParameterLabel(ele.para)}</h1> */}
                      <div>
                        <div>
                          <ul className="flex  ">
                            <span className="p-2 px-1 text-sm leading-7 ">{(index + 1).toString() + '. '}</span>
                            <li className="p-2 text-sm leading-7  ">
                              {ques.name}

                            </li>
                          </ul>

                          <ul className="flex  ">
                            <li className="p-2 text-sm leading-7  text-purple-500">
                              {GetParameterLabel(getFormattedParameter(ques, "parameterBucketId", "name"))}
                            </li>
                            <li className="p-2 text-sm leading-7  text-green-500">
                              {GetParameterLabel(getFormattedParameter(ques, "parameterDimensionId", "name"))}
                            </li>
                          </ul>

                          <ul className="">
                            {ques && ques.rangeList && ques.rangeList.length > 0 ?
                              ques.rangeList.map((inner) =>
                                <>
                                  <li className="p-2 text-sm leading-7  ">
                                    <div className="font-bold p-2">{inner.label}</div>
                                    <div className="px-2">{'"' + ques.name + '" ' + inner.text}</div>
                                  </li>
                                </>
                              ) : null}

                          </ul>


                        </div>
                      </div>

                      <div>
                        <button onClick={() => addNudgeToQues(question, ques)} className="cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] ">{"Select"}</button>

                      </div>
                    </div>
                  ) : null}
              </>)}



              {menu === 2 && (<>
                <h1 className="text-lg p-1 font-semibold capitalize ">{"Create Nudge for the above question"}</h1>

              </>)}


            </div>
          </div>



        </div>
      </div>
    </>
  );
}
