import React, { Component } from 'react'
// import Sidebar from "../Sidebar/Sidebar";
// import Profile from "../Profile/Profile";
// import { dashboardActions } from "../../_actions";
import { connect } from "react-redux";

// import moment from "moment"

class GrowMonth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType1: true,
      showMusic: true,
      viewType2: false,
      width: 0, height: 0,
      newid: '',
      daysid: '',
      newback: '',
      remarksdata: "data",
      showSectionName: 'KNOWLEDGE',
      fieldRemarks: {
        remarks: []
      },
      currentIndex: null,
    };
    // this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }
  componentDidMount() {

    // console.log("fsdfkljsdffffffffffff_______________this.props.match.params_inner", this.props.match.params);
    let id = this.props.crrItemID;
    this.setState({ daysid: id })
    ////this.props.dispatch(dashboardActions.getGrowDayDatabyDayId({ id: id }));
    let { callDataActionAPI } = this.props;
    callDataActionAPI({ id: id }, "getGrowDayDatabyDayId")
  }



  leadingInner = (item) => {
    if (item.isStart === 0) {
      // this.setState({showtrue:true})
      this.setState({ newid: item.id })
      this.setState({ newids: item.monthId })
      let alldata = { item }
      this.props.navigation.navigate('LeadingSelfInner/' + item.id)
      let data = { weekId: item.id, monthId: item.monthId }
      ////this.props.dispatch(dashboardActions.startGrowWeek(data, this.props))
      let { callDataActionAPI } = this.props;
      callDataActionAPI(data, "startGrowWeek")
    }
    else {
      let alldata = { item }
      this.props.navigation.navigate('LeadingSelfInner/' + item.id)
    }
  }


  ShowSection = (data) => {
    this.setState({ showSectionName: data },()=>{
      let temp = {
        remarks: []
      }
      this.setState({ fieldRemarks: temp })
    })
  }


  selectHabit = (item) => {

    console.log("itemitem___________selectHabit:::", item);

    if (item.isStart === 0) {
      // this.setState({showtrue:true})

      this.setState({ newid: item })
      let data1 = { dayId: item && item.id, monthId: item && item.monthId && item.monthId.id, weekId: item && item.weekId && item.weekId.id }

      ////this.props.dispatch(dashboardActions.startGrowDay(data1, this.props));
      let { callDataActionAPI } = this.props;
      callDataActionAPI(data1, "startGrowDay")
      let data = { item }
      this.props.history.push('/app/dayaction/' + item.id)
    }



    else {
      let data = { item }
      this.props.history.push('/app/dayaction/' + item.id)
    }
  }

  actionSubmitupdate = (element) => {

    console.log("smhjsba", element)
    let data2 = { Id: element.id, dayId: this.state.daysid, forcomment: "action", remark: this.state.remarksdata }
    console.log('days2', data2);
    ////this.props.dispatch(dashboardActions.updatecomment(data2));
    let { callDataActionAPI } = this.props;
    callDataActionAPI(data2, "updatecomment")

    let id = this.props.crrItemID;

    this.setState({ daysid: id })
    let newdata = { id: id }
    this.setState({ newback: id })

    ////this.props.dispatch(dashboardActions.getGrowDayDatabyDayId(newdata));
    callDataActionAPI(newdata, "getGrowDayDatabyDayId")
    this.setState({ remarksdata: '' })

  }

  actionSubmit = (item) => {
    let data1 = { dayId: this.state.daysid, actionId: item.id, remark: this.state.remarksdata }

    console.log("data1____________data1::::", data1);

    ////this.props.dispatch(dashboardActions.startGrowDayAction(data1));
    let { callDataActionAPI } = this.props;
    callDataActionAPI(data1, "startGrowDayAction")
    // this.setState({ remarks: '' })
    // this.setState({blankspace:''})




    let id = this.props.crrItemID;

    this.setState({ daysid: id })
    let newdata = { id: id }
    this.setState({ newback: id })

    ////this.props.dispatch(dashboardActions.getGrowDayDatabyDayId(newdata));
    callDataActionAPI(newdata, "getGrowDayDatabyDayId")
    this.setState({ remarksdata: '' })
    // this.setState({blankspace:''})
  }
  knowldgeSubmit = (item) => {
    let data = { dayId: this.state.daysid, knowledgeId: item.id, remark: this.state.remarksdata }

    // console.log("data1____________data1::::", data1);

    ////this.props.dispatch(dashboardActions.startGrowDayKnowledge(data));
    let { callDataActionAPI } = this.props;
    callDataActionAPI(data, "startGrowDayKnowledge")
    // this.setState({ remarks: '' })startGrowDayReflection
    // this.setState({blankspace:''})



    let id = this.props.crrItemID;

    this.setState({ daysid: id })
    let newdata = { id: id }
    this.setState({ newback: id })

    ////this.props.dispatch(dashboardActions.getGrowDayDatabyDayId(newdata));
    callDataActionAPI(newdata, "getGrowDayDatabyDayId")
    this.setState({ remarksdata: '' })
    // this.setState({blankspace:''})
  }

  knowledgeSubmitupdate = (element) => {


    let data2 = { Id: element.id, dayId: this.state.daysid, forcomment: "knowledge", remark: this.state.remarksdata }

    ////this.props.dispatch(dashboardActions.updatecomment(data2));
    let { callDataActionAPI } = this.props;
    callDataActionAPI(data2, "updatecomment")

    let id = this.props.crrItemID;

    this.setState({ daysid: id })
    let newdata = { id: id }
    this.setState({ newback: id })

    ////this.props.dispatch(dashboardActions.getGrowDayDatabyDayId(newdata));
    callDataActionAPI(newdata, "getGrowDayDatabyDayId")
    this.setState({ remarksdata: '' })

  }


  reflectionSubmit = (item) => {
    let data = { dayId: this.state.daysid, reflectionId: item.id, remark: this.state.remarksdata }

    // console.log("data1____________data1::::", data1);

    ////this.props.dispatch(dashboardActions.startGrowDayReflection(data));
    let { callDataActionAPI } = this.props;
    callDataActionAPI(data, "startGrowDayReflection")
    // this.setState({ remarks: '' })startGrowDayReflection
    // this.setState({blankspace:''})



    let id = this.props.crrItemID;

    this.setState({ daysid: id })
    let newdata = { id: id }
    this.setState({ newback: id })

    ////this.props.dispatch(dashboardActions.getGrowDayDatabyDayId(newdata));
    callDataActionAPI(newdata, "getGrowDayDatabyDayId")
    this.setState({ remarksdata: '' })
    // this.setState({blankspace:''})
  }

  finalSubmitupdate = () => {
    let data = { dayId: this.state.daysid }
    let data1 = this.state.daysid
    ////this.props.dispatch(dashboardActions.submitGrowDay(data,data1,this.props));
    let { callDataActionAPI } = this.props;
    callDataActionAPI(data, "submitGrowDay", data1)
    // this.setState({showsubmit:false})
  }


  reflectionSubmitupdate = (element) => {


    let data2 = { Id: element.id, dayId: this.state.daysid, forcomment: "reflection", remark: this.state.remarksdata }

    ////this.props.dispatch(dashboardActions.updatecomment(data2));
    let { callDataActionAPI } = this.props;
    callDataActionAPI(data2, "updatecomment")
    let id = this.props.crrItemID;

    this.setState({ daysid: id })
    let newdata = { id: id }
    this.setState({ newback: id })

    ////this.props.dispatch(dashboardActions.getGrowDayDatabyDayId(newdata));
    callDataActionAPI(newdata, "getGrowDayDatabyDayId")
    this.setState({ remarksdata: '' })

  }
  inputChange = (event,index) => {
    event.preventDefault();
    const { fieldRemarks } = this.state;
    fieldRemarks['remarks'][index] = event.target.value
    this.setState({ fieldRemarks });
    // let data = event.target.value

    this.setState({ fieldRemarks })
  }


  handleCurrentIndex = (index) => {

    this.setState({ currentIndex: index });
  }

  render() {
    let { getGrowDayDatabyDayId, goBackToScreen, crrDayActionName } = this.props;
    // let { allGrowParametersList, getMonth, getGrowDayListbyWeekId, getGrowDayDatabyDayId, actionEmployeeList,
    //   knowledgeEmployeeList,
    //   reflectionEmployeeList } = dashboard;
    let { viewType1, viewType2 } = this.state;
    // console.log("RENDER_______this.state.showSectionName::::", this.state.showSectionName);
    // console.log("RENDER_______getGrowDayDatabyDayId::::", getGrowDayDatabyDayId);
    console.log("RENDER_______remarks::::", this.state.fieldRemarks);
    console.log("RENDER_______currentIndex::::", this.state.currentIndex);

    // let monthId = this.props.match.params.monthId


    return (

      <div className="space-y-6 " >
        <div className='flex items-center justify-between w-full' >
          <div className='flex items-center space-x-4'>
            <span onClick={()=>goBackToScreen(3)} class="cursor-pointer material-symbols-outlined bg-blue-100 p-2 rounded-full text-blue-500 ">arrow_back</span>
            <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">{crrDayActionName?crrDayActionName:"Grow App"}</h1>
          </div>

          {
            this.state.showSectionName && this.state.showSectionName === "ACTION" ?
              <div className="flex items-center justify-center ">
                <button className="p-2 px-6 text-base text-white bg-blue-500 rounded-full shadow-lg cursor-pointer  hover:bg-blue-500 hover:text-white" onClick={() => this.finalSubmitupdate()}>Final Submit</button>
              </div>
              : null}

        </div>


        <div className="flex space-x-8 border-b">

          <span onClick={() => this.ShowSection('KNOWLEDGE')} className={this.state.showSectionName && this.state.showSectionName === "KNOWLEDGE" ? 'cursor-pointer py-3 block   text-blue-500 border-blue-500  border-b-2' : 'cursor-pointer py-3 block   text-gray-500 border-transparent   border-b-2'} >
            Knowledge

          </span>

          <span onClick={() => this.ShowSection('REFLECTION')} className={this.state.showSectionName && this.state.showSectionName === "REFLECTION" ? 'cursor-pointer py-3 block   text-blue-500 border-blue-500  border-b-2' : 'cursor-pointer py-3 block   text-gray-500 border-transparent   border-b-2'} >
            Reflection

          </span>

          <span onClick={() => this.ShowSection('ACTION')} className={this.state.showSectionName && this.state.showSectionName === "ACTION" ? 'cursor-pointer py-3 block   text-blue-500 border-blue-500  border-b-2' : 'cursor-pointer py-3 block   text-gray-500 border-transparent   border-b-2'} >
            Action

          </span>


        </div>

        {/* <Profile /> */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="">
            <div>
              {
                this.state.showSectionName && this.state.showSectionName === "ACTION" ?
                  <>
                    <div className="grid grid-cols-1 gap-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2">


                      {
                        getGrowDayDatabyDayId && getGrowDayDatabyDayId.actionEmployeeList && getGrowDayDatabyDayId.actionEmployeeList.length > 0 ?
                          getGrowDayDatabyDayId.actionEmployeeList.map((element, index) => (

                            <>
                              <div className="w-full">
                                <div className="h-full p-4 overflow-hidden bg-white border rounded shadow-lg">
                                  <div className="flex flex-col justify-between h-full ">
                                    <h1 className="mb-2 text-lg font-medium text-slate-800 ">{element && element.name ? element.name : null}</h1>
                                    {/* <div className="py-8" onClick={() => this.handleCurrentIndex(index)}> */}
                                    <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={element.comment === '' ? "REMARK" : element.comment} 
                                    value={this.state.fieldRemarks && this.state.fieldRemarks['remarks'][index] ? this.state.fieldRemarks['remarks'][index] : ""} 
                                    name="remarks" onChange={(e)=>this.inputChange(e,index)} />
                                    {/* </div> */}

                                    {
                                      element && element.comment === '' ?
                                        <>
                                          <div className="flex items-center justify-start w-full mt-4">
                                            <button className="p-2 px-6 text-base text-white bg-blue-500 border border-none rounded-full shadow-lg cursor-pointer  text-grey-darker hover:bg-blue-500 hover:text-white" onClick={() => this.actionSubmit(element)}>Submit</button>
                                          </div>
                                        </> :
                                        <>
                                          <div className="flex items-center justify-start w-full mt-4">
                                            <button className="p-2 px-6 text-base text-white bg-blue-500 border border-none rounded-full shadow-lg cursor-pointer  text-grey-darker hover:bg-blue-500 hover:text-white" onClick={() => this.actionSubmitupdate(element)}>Update</button>
                                          </div>
                                        </>
                                    }



                                  </div>
                                </div>
                              </div>

                            </>

                          )) : null
                      }

                    </div>

                  </> :
                  null
              }

            </div>



            {
              this.state.showSectionName && this.state.showSectionName === "KNOWLEDGE" ?
                <>
                  <div className="grid grid-cols-1 gap-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2">

                    {
                      getGrowDayDatabyDayId && getGrowDayDatabyDayId.knowledgeEmployeeList && getGrowDayDatabyDayId.knowledgeEmployeeList.length > 0 ?
                        getGrowDayDatabyDayId.knowledgeEmployeeList.map((element, index) => (
                          console.log("elelelele", element),
                          <>
                            <div className="w-full">
                              <div className="h-full p-4 overflow-hidden bg-white border rounded shadow-lg">
                                <div className="">
                                  {element.isLink ?
                                    <div onClick={() => window.open(element.link)}>
                                      <h1 className="mb-2 text-lg font-medium text-blue-600 ">{element && element.name ? element.name : null}</h1>
                                    </div>
                                    :
                                    <h1 className="mb-2 font-medium leading-tight capitalize text-slate-800 text-">{element && element.name ? element.name : null}</h1>
                                  }
                                  <div className="py-8">
                                    <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={element.comment === '' ? "REMARK" : element.comment}  
                                    value={this.state.fieldRemarks && this.state.fieldRemarks['remarks'][index] ? this.state.fieldRemarks['remarks'][index] : ""} name="remarks" 
                                    onChange={(e)=>this.inputChange(e,index)} />
                                  </div>
                                  {
                                    element && element.comment === '' ?
                                      <>
                                        <div className="flex items-center justify-start w-full mt-4">
                                          <button className="p-2 px-6 text-base text-white bg-blue-500 border border-none rounded-full shadow-lg cursor-pointer  text-grey-darker hover:bg-blue-500 hover:text-white" onClick={() => this.knowldgeSubmit(element)}>Submit</button>
                                        </div>
                                      </> :
                                      <>
                                        <div className="flex items-center justify-start w-full mt-4">
                                          <button className="p-2 px-6 text-base text-white bg-blue-500 border border-none rounded-full shadow-lg cursor-pointer  text-grey-darker hover:bg-blue-500 hover:text-white" onClick={() => this.knowledgeSubmitupdate(element)}>Update</button>
                                        </div>
                                      </>
                                  }
                                </div>

                              </div>
                            </div>
                          </>

                        )) : null
                    }

                  </div>

                </>

                :
                null
            }


            {
              this.state.showSectionName && this.state.showSectionName === "REFLECTION" ?
                <>
                  <div className="grid grid-cols-1 gap-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2">

                    {
                      getGrowDayDatabyDayId && getGrowDayDatabyDayId.reflectionEmployeeList && getGrowDayDatabyDayId.reflectionEmployeeList.length > 0 ?
                        getGrowDayDatabyDayId.reflectionEmployeeList.map((element, index) => (

                          <>
                            <div className="w-full">
                              <div className="flex flex-col justify-between h-full p-4 overflow-hidden bg-white border rounded shadow-lg">
                               
                                  <h1 className="mb-2 text-lg font-medium text-slate-800">{element && element.name ? element.name : null}</h1>
                                  <div className="py-8">
                                    <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={element.comment === '' ? "REMARK" : element.comment}  
                                    value={this.state.fieldRemarks && this.state.fieldRemarks['remarks'][index] ? this.state.fieldRemarks['remarks'][index] : ""} name="remarks" 
                                    onChange={(e)=>this.inputChange(e,index)} />
                                  </div>
                                  {
                                    element && element.comment === '' ?
                                      <>
                                        <div className="flex items-center justify-start w-full mt-4">
                                          <button className="p-2 px-6 text-base text-white bg-blue-500 border border-none rounded-full shadow-lg cursor-pointer  text-grey-darker hover:bg-blue-500 hover:text-white" onClick={() => this.reflectionSubmit(element)}>Submit</button>
                                        </div>
                                      </> :
                                      <>
                                        <div className="flex items-center justify-start w-full mt-4">
                                          <button className="p-2 px-6 text-base text-white bg-blue-500 border border-none rounded-full shadow-lg cursor-pointer  text-grey-darker hover:bg-blue-500 hover:text-white" onClick={() => this.reflectionSubmitupdate(element)}>Update</button>
                                        </div>
                                      </>
                                  }
                                
                              </div>
                            </div>
                          </>

                        )) : null
                    }

                  </div>

                </> :
                null
            }



          </div>




        </main>

      </div>


    )
  }
}

function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users, dashboard } = state;
  const { getGrowDayDatabyDayId } = dashboard ? dashboard : {};
  const { actionEmployeeList, knowledgeEmployeeList
    , reflectionEmployeeList
  } = getGrowDayDatabyDayId ? getGrowDayDatabyDayId : {};
  return {
    loggingIn,
    questions,
    users,
    dashboard,
    actionEmployeeList,
    knowledgeEmployeeList,
    reflectionEmployeeList
  };
}
export default connect(mapStateToProps)(GrowMonth)