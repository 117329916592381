import React from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function CreateCampaign(props) {
  let { formBatchRange, AllEmpTotal, campaignFormData, handleCampaignInput, handleMessageInput,
    allEmployeesList, LanguageObj, CurrentLanguage,

    currFilterLevel, getIndexByFilterDMGData, allCustomerList } = props;




  let CustomerList = allCustomerList;


  //  let NeglectedList = ["name","list","isTest", "isDisable", "Age","Alternatenumber", "emp_id","actionPlanStage", "ManagerPID","id","mngr_id","createdAt", "companyId", "currentStage"]
  let NeglectedList = ["name", "list"]

  let EmployeesList = [];

  let EmpLevelList = [];
  let EmpValueList = [];
  let EmpValueObj = {};

  if (allEmployeesList && allEmployeesList.length > 0) {

    let tempLevelList = getIndexByFilterDMGData ? Object.keys(getIndexByFilterDMGData) : {};
    if (tempLevelList && tempLevelList.length > 0) {
      tempLevelList.forEach((temp) => {
        if (NeglectedList.findIndex(prev => prev === temp) === -1) {
          EmpLevelList.push(temp)
        }
      });
    }

    if (EmpLevelList) {
      EmpLevelList.forEach((filter) => {
        EmpValueObj[filter] = [];

        allEmployeesList.forEach((emp) => {
          let getIndex = EmpValueObj[filter].findIndex(prev => prev === emp[filter]);
          if (getIndex === -1 && emp && emp[filter]) {
            EmpValueObj[filter].push(emp[filter]);
          }
        });
      });
    }


    // if(currFilterLevel!=="Select Filter" && currFilterValue!=="Select Value"){
    //   allEmployeesList.forEach((emp)=>{
    //     if(emp && (emp[currFilterLevel] === currFilterValue)){
    //       EmployeesList.push(emp);
    //     }
    //    });
    // }
    // else{
    //   EmployeesList = allEmployeesList;
    // }
    EmployeesList = allEmployeesList;



  }
  if (currFilterLevel && getIndexByFilterDMGData && getIndexByFilterDMGData[currFilterLevel]) {
    EmpValueList = getIndexByFilterDMGData[currFilterLevel];
  }



  //console.log('EmpLevelList: ', EmpLevelList);
  //console.log('EmpValueList: ', EmpValueList);



  //--------------------------------------------------------------------------------

  let User_List = [];

  if (campaignFormData.campaign_type === "CUSTOMER") {
    User_List = CustomerList;
  }
  else {
    User_List = EmployeesList;
  }

  // //console.log('<<<<<<<<-EmployeesList:----------> ',EmployeesList);
  // //console.log('<<<<<<<<-CustomerList:----------> ',CustomerList);


  //--------------------------------------------------------------------------------

  let IndexList = []
  let pageCount = 7
  let IndexListIndex = []
  if (User_List && User_List.length > 0) {


    if (User_List && User_List.length > 0) {
      User_List.forEach((item, index) => {
        if (index % pageCount === 0) {
          IndexList.push(index)
        }
        if (index === (User_List.length - 1)) {
          IndexList.push(index)
        }
      })
    }

    //console.log('IndexList: ', IndexList)

    if (IndexList && IndexList.length > 0) {
      IndexList.forEach((item, index) => {
        if (index !== (IndexList.length - 1)) {
          IndexListIndex.push(index + 1)
        }
      })
    }





    // if (IndexListIndex && IndexListIndex.length > 0) {
    //   IndexListIndex.forEach((item, index) => {
    //     if (item === currentEmpPage) {

    //       if (index < (IndexList.length - 2)) {
    //         indexA = IndexList[index];
    //         indexB = IndexList[index] + (pageCount - 1)
    //       }
    //       else {
    //         indexA = IndexList[index];
    //         indexB = IndexList[IndexList.length - 1]
    //       }
    //     }
    //   })
    // }

  }


  // //console.log('IndexListIndex: ',IndexListIndex)

  //--------------------------------------------------------------------------------

  let initSize = formBatchRange && formBatchRange.size ? formBatchRange.size : 1000;

  function getBatchRange(total) {
    let rangeList = []
    if (total && total > 0) {
      [...Array(total).keys()].forEach((item, index) => {
        if (index % initSize === 0) {
          rangeList.push(index)
        }
        if (index === ([...Array(total).keys()].length - 1)) {
          rangeList.push(index)
        }
      })
    }
    let RangeNameList = []
    if (rangeList && rangeList.length > 0) {
      rangeList.forEach((item, index) => {
        if ((index + 1) !== rangeList.length) {
          let front = (item + 1).toString();
          let end = (item + initSize).toString();
          if ((index + 1) === (rangeList.length - 1)) {
            end = total.toString()
          }
          let temp = {
            "label": front + " - " + end,
            "rangeNo": index + 1
          }
          RangeNameList.push(temp);
        }
      });
    }
    return RangeNameList
  }


  let RangeLabelList = getBatchRange(AllEmpTotal);

  //console.log('Range: ', RangeLabelList)
  //console.log('AllEmpTotal: ', AllEmpTotal)

  //  let sizeList = [200,500,1000,2000,3000,5000]

  // let sizeList = [1000]



  // let { campaignTypeList, handleCampaignType } = props;



  //////console.log("employeeValueSearch: ", employeeValueSearch)



  // function getLauchTypeLabel(value) {
  //   let getIndex = campaignRecursiveFreqList.findIndex(prev => prev.value == value);
  //   let label = ""
  //   if (getIndex !== -1) {
  //     label = campaignRecursiveFreqList[getIndex]["label"]
  //   }
  //   return label
  // }


  // function getRECURISIVETypeLabel(value) {
  //   let getIndex = campaignRecursiveTypeList.findIndex(prev => prev.value == value);
  //   let label = ""
  //   if (getIndex !== -1) {
  //     label = campaignRecursiveTypeList[getIndex]["label"]
  //   }
  //   return label
  // }

  // let isAutoFillShow = false;
  // if (getSurveyData && getSurveyData.isRiskSurveyType && getSurveyData.isRiskSurveyType === "DEFAULTEXIT") {
  //   isAutoFillShow = true
  // }

  return (

    <div className="w-full p-6 overflow-y-auto lang" >

      <div className="space-y-4 ">

        <h1 className='text-xl font-semibold' >Create Campaign</h1>
        <div className="space-y-1">
          <label className="font-medium text-gray-900 text-md">Campaign Title</label>
          <input type="text" name="title" value={campaignFormData.title} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["campaign_title_" + CurrentLanguage] ? LanguageObj["campaign_title_" + CurrentLanguage] : "Title"} className="w-full p-2 text-sm text-gray-900 border rounded outline-none" />
        </div>

        {campaignFormData.type === "EMAIL" ?
          <>
            <div className="space-y-1">
              <label className="font-medium text-gray-900 text-md">Subject</label>
              <input type="text" name="subject" value={campaignFormData.subject} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["subject_" + CurrentLanguage] ? LanguageObj["subject_" + CurrentLanguage] : "Subject"} className="w-full p-2 text-sm text-gray-900 border rounded outline-none" />
            </div>
            <div className="space-y-1">
              <label className="font-medium text-gray-900 text-md">Name</label>
              <input type="text" name="emailName" value={campaignFormData.emailName} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["emailName_" + CurrentLanguage] ? LanguageObj["emailName_" + CurrentLanguage] : "Name"} className="w-full p-2 text-sm text-gray-900 border rounded outline-none" />
            </div>
            <div className="space-y-1">
              <label className="font-medium text-gray-900 text-md">Email Message</label>
              {/* <textarea type="text" name="discption" rows={4} className="w-full p-2 px-2 text-sm text-gray-900 border border-b rounded outline-none resize-none" defaultValue={""} /> */}
              <ReactQuill className="w-full h-32 2xl:h-36 whitespace-nowrap " theme="snow" value={campaignFormData.message} onChange={handleMessageInput} placeholder={"Message"} />
            </div>
            <div className="pt-10 space-y-1">
              <label className="font-medium text-gray-900 text-md">Team Name</label>
              <input type="text" name="teamname" value={campaignFormData.teamname} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["team_name_" + CurrentLanguage] ? LanguageObj["team_name_" + CurrentLanguage] : "Team"} className="w-full p-2 text-sm text-gray-900 border rounded outline-none" />
            </div>
          </>
          : null}




      </div>
    </div>

  );
}
