import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionScore from './Charts/QuestionScore';
import Filter from './Filter/Filter';
import { CSVLink } from "react-csv";
import EmployeeDropdown from '../Filter/EmployeeDropdown';
import DimensionList from './Filter/DimensionList';



class DimensionPriorities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      crrSelectedEmp: {}
    }
  }
  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handle360GetQuestionFilter } = this.props;
    this.setState({ FilterValues: finalFilter });
    handle360GetQuestionFilter(finalFilter, "", "", false);

  }

  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { get360DimensionItemDetails } = this.props;

      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = value.emp_id;
      }
      let { FilterValues } = this.state;
      get360DimensionItemDetails(FilterValues, "", "", false, emp_id)

    })
  }

  handleSelectDimension = (value) => {
    this.setState({ crrSelectedDimension: value })
  }

  render() {
    let { selectedFilterValue, loading,
      indexType, optionType, getCommentData, CountRestrict, getEMPFilterLabel,

      getQuestionData, getQuestionFilterData, EmpFilterData, getIndexFilterData, getIndexByFilterDMGData

    } = this.props;

    let { currentSelectValue, FilterValues } = this.state;



    let QuestionData = []

    if (this.state.currFilterValue === 'none') {
      QuestionData = getQuestionData
    }
    else {
      QuestionData = getQuestionFilterData
    }


    let parameterList = [];
    let relationList = []
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((quesX) => {
        if (quesX && quesX.parameterDimensionName) {
          let getIndex = parameterList.findIndex(prev => prev === quesX.parameterDimensionName);
          if (getIndex === -1) {
            parameterList.push(quesX.parameterDimensionName);
            let dimension = quesX.parameterDimensionName;
            quesX.relationList.forEach((item1) => {
              let getIndex3 = relationList.findIndex(prev => prev === item1);
              if (getIndex3 === -1) {
                relationList.push(item1);
              }
            })




          }
        }
      })
    }

    function getQuesByDimen(dimen) {
      if (QuestionData && QuestionData.length > 0) {
        let quesList = QuestionData.filter(prev => prev.parameterDimensionName === dimen);
        return quesList
      } else {
        return []
      }
    }

    let newQuestionlist = []
    if (parameterList && parameterList.length > 0) {
      parameterList.forEach((item) => {
        newQuestionlist.push({
          "dimension": item,
          "questionList": getQuesByDimen(item)
        })
      })
    }

    ////console.log("parameterList====>", parameterList)
    ////console.log("newQuestionlist====>", newQuestionlist)
    ////console.log("QuestionData====>", QuestionData)






    let NewQuesList = [];
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        let total = ques['1'] + ques['2'] + ques['3'] + ques['4'] + ques['5'] + ques['6'];
        NewQuesList.push({
          ...ques,
          score: parseFloat(((ques['5'] + ques['6']) * 100 / total).toFixed(2))
        })
      })
    }



    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }
    }
    let { GetParameterLabel, name } = this.props;

    //Export Excel Logic
    let ScoreExcel = []
    let filter = FilterValues && FilterValues.length > 0 ? " (" + FilterValues.map((element) => { return element.level + ": " + element.value }).join(",") + ")" : " (Filter: All)"

    if (NewQuesList && NewQuesList.length > 0) {
      NewQuesList.forEach((question) => {
        let para = question && question.parameterDimensionName ?
          GetParameterLabel(question.parameterDimensionName) :
          "";
        let Score = CountRestrict(question && question.count ? question.count : "NaN") ? 0 : question.score
        let temp = {}
        temp["Dimension " + filter] = para;
        temp["Question"] = question.name
        temp["Score(%)"] = Score

        ScoreExcel.push(temp)
      });
    }


    let { getAnsweredEmployeesData } = this.props;

    if (QuestionData && QuestionData.length > 0) {
      QuestionData.sort((a, b) => a.parameterDimensionName.charCodeAt(0) - b.parameterDimensionName.charCodeAt(0))
    }

    return (
      <>
        <div className=" md:flex flex-wrap gap-4 md:space-y-0 space-y-4  justify-between items-center w-full">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />

          <div className='flex items-center flex-wrap gap-4 md:mt-0 mt-4' >

            <DimensionList
              parameterList={parameterList}
              crrSelectedDimension={this.state.crrSelectedDimension}
              handleSelectDimension={this.handleSelectDimension}
            />



            <EmployeeDropdown
              getAnsweredEmployeesData={getAnsweredEmployeesData}
              crrSelectedEmp={this.state.crrSelectedEmp}
              handleSelectEmployee={this.handleSelectEmployee}
            />

          </div>




          {/* <span className="text-xs font-medium text-blue-500 hidden">Need Help?</span> */}
          {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} /> */}

          {/* <div className='mr-4'>
            {ScoreExcel ?
            <CSVLink data={ScoreExcel} filename={"EnagagementFavorability.csv"}>
            <p className="font-normal text-purple-700 text-sm cursor-pointer hover:text-purple-500">
              {"Download Excel"}
            </p>                
            </CSVLink>
            : '...'}
            </div> */}



        </div>


        {true ?
          <>

            {/* <div className="xl:flex lg:flex md:flex justify-start items-center"> */}

            {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}

            {/* </div> */}

            {/* 
            {FilterValues && FilterValues.length > 0 || true?
                <div className=" px-4 xl:flex lg:flex md:flex justify-between items-center bg-white border-b py-3 h-10 ">
                  <>
                  {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
                    <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
                  ) : <p></p>}


                    {FilterValues && FilterValues.length > 0 || true?
                      <>
                      {ScoreExcel ?
                      <CSVLink data={ScoreExcel} filename={"IndexScore.csv"}>
                      <p className="font-normal text-purple-700 text-sm cursor-pointer hover:text-purple-500">
                        {"Download Excel"}
                      </p>                
                      </CSVLink>
                      : '...'}
                      </>
                      :<p></p>}


                    
                  </>
                  </div>
                : null} */}


            <div onClick={() => this.closeFilter(false)} className=" " >
              {/* <div className="flex w-full" >


                <div className="bg-white rounded w-full shadow-lg px-4 m-4">
                  <div className="text-lg font-semibold py-4">{name}  Behaviour Item</div> */}



              <div className="w-full" >

                <div className="grid md:grid-cols-1 grid-cols-1 gap-4 ">

                  <div className="p-4 bg-white border rounded-md space-y-6">
                    <div>

                      <h1 className="font-medium text-xl capitalize text-black pb-2">1. change leadership</h1>
                      <div className='flex flex-wrap divide-x divide-black' ><p className='px-2'>Self score (2.8)</p><p className='px-2'>Other Respondents Average score (2.8)</p></div>
                    </div>



                    {QuestionData && QuestionData.length > 0 ?
                      <div className="overflow-x-auto whitespace-nowrap w-full overflow-hidden  mb-4">
                        <table className="w-full   ">
                          <thead>
                            <tr className="">
                              {/* <th className="py-2 px-2 border-r font-bold">Dimension</div></th> */}
                              <th ></th>
                              <th >  </th>
                            </tr>
                          </thead>
                          <tbody className='divide-y' >
                            {QuestionData && QuestionData.length > 0 ?
                              QuestionData.map((question, index) =>
                                <tr key={index} className="bg-white  mb-10 lg:mb-0 text-sm text-gray-800">
                                  {/* <td className="px-2 border border-b w-2/12">
                                    <div style={{ width: '20%' }}>{question && question.parameterDimensionName ?
                                      GetParameterLabel(question.parameterDimensionName) :
                                      ""}</div>
                                  </td> */}
                                  <td className="tooltip-response-question1 px-2  w-6/12">
                                    <div className="cursor-pointer whitespace-pre-line font-medium ">a) {question.name}</div>
                                    {/* <div className="tooltip-response-question2">
                                  {question.name}
                                </div> */}
                                  </td>
                                  <td className="  w-6/12">
                                    <QuestionScore question={question} CountRestrict={CountRestrict} currFilterValue={this.state.currFilterValue} currFilterName={this.state.currFilterName} selectedFilterValue={selectedFilterValue} getQuestionFilterData={getQuestionFilterData} />
                                  </td>
                                </tr>
                              )
                              : null}

                          </tbody>
                        </table>
                      </div>
                      :
                      <>
                        {!loading ?
                          <div className="text-center text-3xl text-gray-400 pt-40">Data Not Available!</div>
                          : null}
                      </>
                    }



                    {/* <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div> */}
                  </div>
                </div>
              </div>


            </div>

          </>
          :
          <>
            {!loading ?
              <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>
              : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DimensionPriorities);
