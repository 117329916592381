import React,{Component} from "react";
// import GaugeScore from '../../RiskProfile/Components/Charts/GaugeScore';
import GaugeScore from '../Charts/GaugeScore';
import GaugeScoreEng from '../Charts/GaugeScoreEng';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { connect } from 'react-redux';
import PageTemplate from './PageTemplate';

class RiskProfile extends Component {
constructor(props) {
  super(props);
  this.pdfExportComponent = React.createRef();

  this.state = {
    optionType: 1,


    ReportFilterObj: {
      "level": "Overall",
      "value": ""
    },

    showExport: false,


    currentSelectValue: '',
    FilterValues: [],
    showFilter: false,
    showValue: '',
    isSelectTypeOverall: false,
    errorMsg: "",
    setPDF: true

  }
}
componentDidMount() {

}

handleExportWithComponent = (event) => {
  this.setState({ setPDF: true }, () => {
    this.pdfExportComponent.current.save();
  })
}


render(){

 
  let { name, emp_id, scoreType, LifecyclePTMEmpQuesOpenEndedData, closeModal, showModal, OnboardingEmpQuesResponsesData, RiskgetRiskCategoryData, isOverallPTM, getCategoryPTMByScore,riskScore, riskCategory } = this.props;


    let qList = [];
    console.log("OnboardingEmpQuesResponsesData---->",OnboardingEmpQuesResponsesData)

    if(isOverallPTM){

      if(OnboardingEmpQuesResponsesData && OnboardingEmpQuesResponsesData.length>0){
        OnboardingEmpQuesResponsesData.forEach((item1)=>{
          if(item1 && item1.questions && item1.questions.length>0){
            item1.questions.forEach((item2)=>{
              console.log("item2---->",item2)

                if(item2.qtemplate.toString() === "1"){
                  let getIndex1 = qList.findIndex(prev=>prev.question.trim()===item1.question);
                  if(getIndex1 === -1){
                    qList.push(item2)
                  }
                }
                
            })
          }

          
        })
      }

    }else{
      qList = OnboardingEmpQuesResponsesData && OnboardingEmpQuesResponsesData.length>0? OnboardingEmpQuesResponsesData.filter(prev=>prev.qtemplate.toString() === "1") : [];
    }

    console.log("OnboardingEmpQuesResponsesData---->",OnboardingEmpQuesResponsesData)
    console.log("qList---->",qList)
    console.log("isOverallPTM---->",isOverallPTM)

    let Qcount = 0
    let grandTotal = 0;
    let grandScore = 0;
    if(qList && qList.length>0){
      qList.forEach((ques)=>{
        if(Number(ques.qtemplate) === 1){
          Qcount++
          grandTotal += 6;
          grandScore += Number(ques.answer)
        }
      })
    }

    


    function getColor(score1){
      let color = "#858784";
      let score = Number(score1);
      if(score >=0 && score<=3.5){
        color = "#d12e2e";
      }
      else if(score >3.5 && score<=4.5){
        color = "#f4ae34";
      }
      else if(score >4.5 && score<=5.5){
        color = "#a2e37d";
      }
      else if(score >5.5 && score<=6){
        color = "#00cc66";

      }
      return color
    }


    let empResponseList = qList;


    function getSurveyScore(item,EmpQuesResponsesData,survey1){
      let answer = ''
      let questionName = item && item.question?item.question.trim():'';

      let getIndex2 = EmpQuesResponsesData && EmpQuesResponsesData.length>0? EmpQuesResponsesData.findIndex(prev=>prev.surveyName.toString() === survey1.surveyName.toString()):-1
      if(getIndex2!==-1){
        let qListprev = EmpQuesResponsesData && EmpQuesResponsesData.length>0 && 
        EmpQuesResponsesData[getIndex2] && 
        EmpQuesResponsesData[getIndex2]["questions"] && 
        EmpQuesResponsesData[getIndex2]["questions"].length>0?
        EmpQuesResponsesData[getIndex2]["questions"].filter(prev=>prev.qtemplate.toString() === "1"):[];
        
        console.log("questionName--1-->",questionName)
        console.log("qListprev--1-->",qListprev)
  
        if(qListprev && qListprev.length>0){
          let getIndex = qListprev.findIndex(prev=>prev.question.trim() === questionName);
          if(getIndex!==-1){
            answer = qListprev && qListprev[getIndex] && qListprev[getIndex]["answer"] && qListprev[getIndex]["answer"]?qListprev[getIndex]["answer"]:""
          }
        }
      }
      
      
      return answer?answer:'-'

    }


    function getRandomColor(){
      let colorList = ["#E57373","#F06292","#BA68C8","#BA68C8","#9575CD","#7986CB","#64B5F6","#4FC3F7","#4DD0E1","#4DB6AC","#81C784","#DCE775","#FFB74D","#FF8A65","#90A4AE","#757575"]
      var color = colorList[Math.floor(Math.random()*colorList.length)];
      return color
    }

    console.log("LifecyclePTMEmpQuesOpenEndedData--1-->",LifecyclePTMEmpQuesOpenEndedData)


    
    function getArrChunks(arrV1){
      const chunkSize = 15;

      let array1 = []
      for (let i = 0; i < arrV1.length; i += chunkSize) {
          const chunk = arrV1.slice(i, i + chunkSize);
          array1.push(chunk)
      }

      return array1
    }

  return (
    <>
    {showModal?
   <>
       <div  style={{zIndex:'100',backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
         <div className="p-4 max-w-8xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-12">
           
           
           <div onClick={()=>closeModal()}  className="shadow absolute right-60 top-20 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
             <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
               <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
             </svg>
           </div>
 
 
           
           
           <div  className="shadow mt-20 md:mx-60 lg:mx-60 xl:mx-60 rounded-lg bg-white overflow-hidden block">
 
         <div className="flex justify-between items-center">
             <div/>
             <div className="p-4">
               <button className="bg-blue-100 p-2 px-4 rounded-full text-blue-500  cursor-pointer text-sm flex items-center "
                 onClick={() => this.handleExportWithComponent()}>
                 <span class="material-symbols-outlined mr-1">file_download</span>
                 {"Export PDF"}
               </button>
             </div>
 
 
         </div>
             
         <div className="" style={{height:'600px',overflowY:'auto'}}>
 
             <PDFExport
                   pageTemplate={PageTemplate}
                   scale={0.7}
                   forcePageBreak=".page-break"
                   ref={this.pdfExportComponent}
                   paperSize="A4"
                   margin={20}
                   fileName={"RiskProfile "+(emp_id?' EMP - '+emp_id:'')}
                   author="Happyplus"
                   landscape={false}
                   className="divide-y space-y-4"
 
                 >
               <div className="xl:flex-col w-full space-y-4" style={{overflowY:'auto'}}>
 
               <div className="bg-white rounded w-full border p-4">
                 <div className="text-lg font-semibold ">{
                 scoreType===1?
                 "Risk Profile based on Engagement Score":"Risk Profile based on Survey Response"}</div>
 
                 {name && emp_id?
                 <div className="py-4">
                   <div className="text-md">
                     <h1 className="font-semibold">{"Name: "+name}</h1>
                     <h1 className="font-semibold">{"Emp ID: "+emp_id}</h1>
                   </div>
                 </div>
                 :null}
                 
 
                 {isOverallPTM?
                 <>
                 <GaugeScoreEng
                   riskScore={riskScore}
                 />
                 </>
                 :
                 <>
                 {empResponseList && empResponseList.length>0?
                   <GaugeScore 
                   totalScore={grandScore}
                   grandTotal={grandTotal}
                   grandScore={grandScore}
 
                 />
                 :<div className="text-gray-500 text-xl text-center">No Data Found</div>}
                 </>}
               
 
 
               </div>
 
                 {/* <div className="text-lg font-semibold ">Employee Response</div> */}
 
                 {isOverallPTM?
                 <>
                   
                   {OnboardingEmpQuesResponsesData && OnboardingEmpQuesResponsesData.length>0?
                   OnboardingEmpQuesResponsesData.map((item)=>
                     <>
                     <div className="bg-white rounded w-full p-4 page-break">
                       <div className="text-lg font-semibold pt-4">{item && item.surveyName?"Employee Engagement Response ("+item.surveyName+")":""}</div>
                       <div className=" py-4 lg:whitespace-normal whitespace-nowrap overflow-x-auto">
 
                         <div className="table  w-full   ">
                           <table className="w-full border border-collapse">
                             <thead className>
       
                               <tr className="bg-gray-50 border-b">
                               
       
                                 <th  className="p-2 border border-r cursor-pointer text-sm font-bold text-gray-800">
                                   <div className="flex items-center justify-center">
                                     Questions
                                   </div>
                                 </th>
       
                               
                               
       
                                 <th className="p-2 border border-r cursor-pointer text-sm font-bold text-gray-800 xl:w-24 w-1/6">
                                   <div className="flex items-center justify-center">
                                     {"Score"} 
                                   </div>
                                 </th>
                               
                               
       
                               </tr>
       
       
                             </thead>
                             <tbody>
       
                               {item && item.questions && item.questions.length>0?
                                 item.questions.map((question,index)=>
                                 <tr className={"text-center text-sm text-gray-600 border border-b"}>
           
                                   <td className={" border-r text-left"}> <p className=" p-2 font-normal whitespace-normal">{question && question.question?question.question:"-"}</p></td>
                                   <td className="border border-b text-white font-semibold text-xl " 
                                       style={{background:getColor(getSurveyScore(question,OnboardingEmpQuesResponsesData,item))}}  > 
                                       <p className=" p-2 ">{getSurveyScore(question,OnboardingEmpQuesResponsesData,item)}</p></td>
 
                                 </tr>
                                 )
                                 :null}
       
                             </tbody>
                           </table>
                         </div>
                     
                         </div>
                         </div>
 
                     </>
                     
                  
                     )
                     :
                     <>
                     <div className="text-gray-500 text-xl text-center">No Data Found</div>
                     </>
                     }
   
                 </>
                 :
                 <>
                 {getArrChunks(qList) && getArrChunks(qList).length>0?
                 getArrChunks(qList).map((qInnerList,indexInnner)=>
                 <div key={indexInnner} className="bg-white rounded w-full  p-4 page-break">
                  <div className="text-lg font-semibold pt-4">{indexInnner>0?"Employee Response (Continue)":"Employee Response "}</div>
                   <div className=" py-4 lg:whitespace-normal whitespace-nowrap overflow-x-auto">
                   
                   {qInnerList && qInnerList.length>0?
                     <>
                     {qInnerList && qInnerList.length>0?
                     
                     <div className="table  w-full   ">
                       <table className="w-full border border-collapse">
                         <thead className>
   
                           <tr className="bg-gray-50 border-b">
                           
   
                             <th  className="p-2 border border-r cursor-pointer text-sm font-bold text-gray-800">
                               <div className="flex items-center justify-center">
                                 Questions
                               </div>
                             </th>
   
                           
                             <th  className="p-2 border border-r cursor-pointer text-sm font-bold text-gray-800 xl:w-24 w-1/6">
                               <div className="flex items-center justify-center">
                                 Score
                               </div>
                             </th>
                           
   
                           </tr>
   
   
                         </thead>
                         <tbody>
   
                           {qInnerList && qInnerList.length>0?
                             qInnerList.map((item,index)=>
                             <tr className={"text-center text-sm text-gray-600 border "+(item.categoryShow?"border-t-4 border-b":"border-b")}>
       
                               <td className={" border-r text-left"}> <p className=" p-2 font-normal whitespace-normal">{item && item.question?item.question:"-"}</p></td>
                               <td className="border border-b text-white font-semibold text-xl " style={{background:getColor((item && item.answer?item.answer:0))}} > <p className=" p-2 ">{item && item.answer?item.answer:"-"}</p></td>
   
                             </tr>
                             )
                             :null}
   
                         </tbody>
                       </table>
                     </div>
                     :
                     <>
                     <div className="text-gray-500 text-xl text-center">Please select employee</div>
                     </>
                     }
                     </>
                     :
                     <>
                     <div className="text-gray-500 text-xl text-center">No Data Found</div>
                     </>
                   }
   
   
   
                  
   
   
   
                   </div>  
                  </div>
                 ):null}
                   
                 </>}
 
                 
                     
      
              {LifecyclePTMEmpQuesOpenEndedData && LifecyclePTMEmpQuesOpenEndedData.length>0?
              <div className="bg-white rounded w-full p-4 page-break">
              {LifecyclePTMEmpQuesOpenEndedData && LifecyclePTMEmpQuesOpenEndedData.length>0?
              LifecyclePTMEmpQuesOpenEndedData.map((element)=>
              (element && element.questions && element.questions.length > 0 )?
              <div className=" py-4 lg:whitespace-normal whitespace-nowrap my-4">
              <div className="text-lg font-semibold mb-2">Open Ended Response {"("+element.surveyName+")"}</div>

                      <div>
                        {element && element.questions && element.questions.length > 0 ?
                          element.questions.map((item,index) =>
                          <div className='space-y-6 flex flex-col  '>
                
  

                            <div>
                              <h1 className='font-medium text-slate-700 text-md'>{"Q."+(index+1).toString()+" "+(item && item.question?item.question:"")}</h1>

                                <div class="space-x-4 flex items-end py-3">
                                  <div>
                                    <div className='bg-green-500 flex items-center justify-center h-8 w-8 rounded-full p-1' 
                                      style={{background:getRandomColor()}}>
                                      <img src='/img/profile.svg' />
                                    </div>
                                  </div>
                                  <div className='border rounded-tl-lg rounded-tr-lg rounded-br-lg  p-4 w-full'>
                                    <p class="text-sm text-slate-600">{item && item.answerText?item.answerText:""}</p>
                                  </div>
                                </div>

                            </div>
                            


                          </div>
                          ) : null}
                      </div>
                </div>
              :null):null}


          




          </div>
              :null}
               
 
 
 
 
               </div>
             </PDFExport>
 
           
           </div>
         </div>
 
 
         </div>
         </div>
   </> 
   :null}
 
    </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(RiskProfile);
