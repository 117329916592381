import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function CreateCampaignCustomer(props) {
  let { getSurveyData, handleExitSurveyAutofill,
    EmpFilterData, recursiveSettings, campaignRecursiveFreqList, handleCampaignRecursiveType, campaignRecursiveTypeList, handleRecursiveType,

    showBatchRange, handleBatchRangeDropdown, selectBatchRange, formBatchRange, AllEmpTotal, campaignFormData, handleTestUserCheck, handleCampaignInput, handleMessageInput, handleCampaignSubmit, customers,
    allCustomerList, handleCampaignEmployees, EmpIndexA, EmpIndexB, prevEmpPage, nextEmpPage, SelectEmpPage,
    currentEmpPage, SelectAllEmployees, selectAllEmp, LanguageObj, CurrentLanguage,

    currFilterLevel, showLevelFilter, handleEmpLevelDropdown, handleLevelSelect,
    currFilterValue, showValueFilter, handleEmpValueDropdown, handleValueSelect,

    employeeValueSearch, handleEmployeeValueSearch, getIndexByFilterDMGData, handleGetEmployees,
    campaignValidationList, handleRecursiveCampaignInput, handleType, TypeList,
    campaignReminderTypeList, handleCampaignReminderType, handleReminderFreqInc,
    handleCampaignReminderOption, campaignReminderOptionList


  } = props;


  // let [showSizeDrop, handleSizeDrop] = React.useState(false)
  let [showCampaignTypeDropdown, handleshowCampaignTypeDropdown] = React.useState(false);

  let [showcampaignReminderTypeDropdown, handleshowcampaignReminderTypeDropdown] = React.useState(false);
  let [showcampaignReminderOptionDropdown, handleshowcampaignReminderOptionDropdown] = React.useState(false);

  let [showTypeDropdown, handleshowTypeDropdown] = React.useState(false);

  let [showCampaignRecursiveDropdown, handleshowCampaignRecursiveDropdown] = React.useState(false);

  let [showRecursiveTypeDropdown, handleshowRecursiveTypeDropdown] = React.useState(false);


  let CustomerList = allCustomerList;


  //  let NeglectedList = ["name","list","isTest", "isDisable", "Age","Alternatenumber", "emp_id","actionPlanStage", "ManagerPID","id","mngr_id","createdAt", "companyId", "currentStage"]
  let NeglectedList = ["name", "list"]

  let EmployeesList = [];

  let EmpLevelList = [];
  let EmpValueList = [];
  let EmpValueObj = {};

  if (allCustomerList && allCustomerList.length > 0) {

    let tempLevelList = getIndexByFilterDMGData ? Object.keys(getIndexByFilterDMGData) : {};
    if (tempLevelList && tempLevelList.length > 0) {
      tempLevelList.forEach((temp) => {
        if (NeglectedList.findIndex(prev => prev === temp) === -1) {
          EmpLevelList.push(temp)
        }
      });
    }

    if (EmpLevelList) {
      EmpLevelList.forEach((filter) => {
        EmpValueObj[filter] = [];

        allCustomerList.forEach((emp) => {
          let getIndex = EmpValueObj[filter].findIndex(prev => prev === emp[filter]);
          if (getIndex === -1 && emp && emp[filter]) {
            EmpValueObj[filter].push(emp[filter]);
          }
        });
      });
    }


    // if(currFilterLevel!=="Select Filter" && currFilterValue!=="Select Value"){
    //   allCustomerList.forEach((emp)=>{
    //     if(emp && (emp[currFilterLevel] === currFilterValue)){
    //       EmployeesList.push(emp);
    //     }
    //    });
    // }
    // else{
    //   EmployeesList = allCustomerList;
    // }
    EmployeesList = allCustomerList;



  }
  if (currFilterLevel && getIndexByFilterDMGData && getIndexByFilterDMGData[currFilterLevel]) {
    EmpValueList = getIndexByFilterDMGData[currFilterLevel];
  }



  ////////console.log('EmpLevelList: ', EmpLevelList);
  ////////console.log('EmpValueList: ', EmpValueList);



  //--------------------------------------------------------------------------------

  let User_List = [];

  if (campaignFormData.campaign_type === "CUSTOMER") {
    User_List = CustomerList;
  }
  else {
    User_List = EmployeesList;
  }

  // ////////console.log('<<<<<<<<-EmployeesList:----------> ',EmployeesList);
  // ////////console.log('<<<<<<<<-CustomerList:----------> ',CustomerList);


  //--------------------------------------------------------------------------------

  let IndexList = []
  let pageCount = 7
  let IndexListIndex = []
  let indexA = 0
  let indexB = 0

  if (User_List && User_List.length > 0) {


    if (User_List && User_List.length > 0) {
      User_List.forEach((item, index) => {
        if (index % pageCount === 0) {
          IndexList.push(index)
        }
        if (index === (User_List.length - 1)) {
          IndexList.push(index)
        }
      })
    }

    ////////console.log('IndexList: ', IndexList)

    if (IndexList && IndexList.length > 0) {
      IndexList.forEach((item, index) => {
        if (index !== (IndexList.length - 1)) {
          IndexListIndex.push(index + 1)
        }
      })
    }





    if (IndexListIndex && IndexListIndex.length > 0) {
      IndexListIndex.forEach((item, index) => {
        if (item === currentEmpPage) {

          if (index < (IndexList.length - 2)) {
            indexA = IndexList[index];
            indexB = IndexList[index] + (pageCount - 1)
          }
          else {
            indexA = IndexList[index];
            indexB = IndexList[IndexList.length - 1]
          }
        }
      })
    }

  }


  // ////////console.log('IndexListIndex: ',IndexListIndex)

  //--------------------------------------------------------------------------------

  let initSize = formBatchRange && formBatchRange.size ? formBatchRange.size : 1000;

  function getBatchRange(total) {
    let rangeList = []
    if (total && total > 0) {
      [...Array(total).keys()].forEach((item, index) => {
        if (index % initSize === 0) {
          rangeList.push(index)
        }
        if (index === ([...Array(total).keys()].length - 1)) {
          rangeList.push(index)
        }
      })
    }
    let RangeNameList = []
    if (rangeList && rangeList.length > 0) {
      rangeList.forEach((item, index) => {
        if ((index + 1) !== rangeList.length) {
          let front = (item + 1).toString();
          let end = (item + initSize).toString();
          if ((index + 1) === (rangeList.length - 1)) {
            end = total.toString()
          }
          let temp = {
            "label": front + " - " + end,
            "rangeNo": index + 1
          }
          RangeNameList.push(temp);
        }
      });
    }
    return RangeNameList
  }


  let RangeLabelList = getBatchRange(AllEmpTotal);

  ////////console.log('Range: ', RangeLabelList)
  ////////console.log('AllEmpTotal: ', AllEmpTotal)

  //  let sizeList = [200,500,1000,2000,3000,5000]

  // let sizeList = [1000]



  let { campaignTypeList, handleCampaignType } = props;



  ////////////console.log("employeeValueSearch: ", employeeValueSearch)



  function getLauchTypeLabel(value) {
    let getIndex = campaignRecursiveFreqList.findIndex(prev => prev.value === value);
    let label = ""
    if (getIndex !== -1) {
      label = campaignRecursiveFreqList[getIndex]["label"]
    }
    return label
  }


  function getRECURISIVETypeLabel(value) {
    let getIndex = campaignRecursiveTypeList.findIndex(prev => prev.value === value);
    let label = ""
    if (getIndex !== -1) {
      label = campaignRecursiveTypeList[getIndex]["label"]
    }
    return label
  }

  let isAutoFillShow = false;
  if (getSurveyData && getSurveyData.isRiskSurveyType && getSurveyData.isRiskSurveyType === "DEFAULTEXIT") {
    isAutoFillShow = true
  }


  function getReminderLabel(value) {
    let label = ""
    let getIndex212 = campaignReminderTypeList.findIndex(prev => prev.value === value);
    if (getIndex212 !== -1) {
      label = campaignReminderTypeList[getIndex212]["label"]
    }
    return label
  }

  function getReminderOptionLabel(value) {
    let label = ""
    let getIndex212 = campaignReminderOptionList.findIndex(prev => prev.value === value);
    if (getIndex212 !== -1) {
      label = campaignReminderOptionList[getIndex212]["label"]
    }
    return label
  }


  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }


  return (
    <>
      <main className="w-full md:p-6 p-4 overflow-y-auto bg-[#f9fafb] h-[calc(100vh-8rem)] ">
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 ">
          {/*  */}
          {/* form */}
          <div className="w-full px-4 space-y-4 bg-white border rounded-md lang md:px-6" >

            <div className="">
              <h2 className="xl:text-xl text-lg font-medium text-[#3D405B] md:-mx-6 -mx-4 py-4 md:px-6 px-4 border-b flex justify-between items-center flex-row gap-4 ">
                Create Campaign
              </h2>

              {isAutoFillShow ?
                <>
                  <div className=''>
                    <div onClick={() => handleExitSurveyAutofill()} className='p-1 px-2 text-white bg-blue-500 rounded-lg cursor-pointer'>Autofill</div>
                  </div>
                </>
                : null}
            </div>

            <div className="space-y-4">
              <div className="space-y-1">
                <label className="text-sm font-normal text-gray-900">Campaign Name</label>
                <input type="text" name="title" value={campaignFormData.title} onChange={handleCampaignInput} placeholder={LanguageObj && LanguageObj["campaign_title_" + CurrentLanguage] ? LanguageObj["campaign_title_" + CurrentLanguage] : "Title"} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
              </div>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div className="space-y-1">
                  <label className="text-sm font-normal text-gray-900">
                    Campaign Type
                  </label>

                  <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer">
                    <span
                      className="w-full pr-4 text-sm font-medium text-slate-800"
                      onClick={() =>
                        handleshowCampaignTypeDropdown(
                          !showCampaignTypeDropdown
                        )
                      }
                    >
                      {campaignFormData && campaignFormData.campaign_type
                        ? capitalizeFirstLetter(campaignFormData.campaign_type)
                        : "Select Type"}
                    </span>
                    <span
                      className="text-gray-500 material-symbols-outlined"
                      onClick={() =>
                        handleshowCampaignTypeDropdown(
                          !showCampaignTypeDropdown
                        )
                      }
                    >
                      arrow_drop_down
                    </span>

                    {showCampaignTypeDropdown ? (
                      <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
                        {campaignTypeList && campaignTypeList.length > 0
                          ? campaignTypeList.map((item, index) =>
                            item !== campaignFormData.campaign_type ? (
                              <span
                                onClick={() => {
                                  handleCampaignType(item);
                                  handleshowCampaignTypeDropdown(
                                    !showCampaignTypeDropdown
                                  );
                                }}
                                className={
                                  campaignFormData.campaign_type === item
                                    ? "inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b bg-gray-100"
                                    : "inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b hover:bg-gray-100"
                                }
                              >
                                {capitalizeFirstLetter(item)}
                              </span>
                            ) : null
                          )
                          : null}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="space-y-1 ">
                  <label className="text-sm font-normal text-gray-900">
                    Campaign Mode
                  </label>

                  <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer">
                    <span
                      className="w-full pr-4 text-sm font-medium text-slate-800"
                      onClick={() => handleshowTypeDropdown(!showTypeDropdown)}
                    >
                      {campaignFormData && campaignFormData.type
                        ? capitalizeFirstLetter(campaignFormData.type)
                        : "Mode"}
                    </span>
                    <span
                      className="text-gray-500 material-symbols-outlined"
                      onClick={() => handleshowTypeDropdown(!showTypeDropdown)}
                    >
                      arrow_drop_down
                    </span>

                    {showTypeDropdown ? (
                      <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
                        {TypeList && TypeList.length > 0
                          ? TypeList.map((item, index) =>
                            item !== campaignFormData.type ? (
                              <span
                                onClick={() => {
                                  handleType(item);
                                  handleshowTypeDropdown(!showTypeDropdown);
                                }}
                                className={
                                  campaignFormData.type === item
                                    ? "inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b bg-gray-100"
                                    : "inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b hover:bg-gray-100"
                                }
                              >
                                {capitalizeFirstLetter(item)}
                              </span>
                            ) : null
                          )
                          : null}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div className="space-y-1">
                  <label className="text-sm font-normal text-gray-900">
                    Reminder Type
                  </label>

                  <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer">
                    <span
                      className="w-full pr-4 text-sm font-medium text-slate-800"
                      onClick={() =>
                        handleshowcampaignReminderOptionDropdown(
                          !showcampaignReminderOptionDropdown
                        )
                      }
                    >
                      {campaignFormData &&
                        campaignFormData.campaignReminderOption
                        ? capitalizeFirstLetter(getReminderOptionLabel(
                          campaignFormData.campaignReminderOption
                        ))
                        : "Manual"}
                    </span>
                    <span
                      className="text-gray-500 material-symbols-outlined"
                      onClick={() =>
                        handleshowcampaignReminderOptionDropdown(
                          !showcampaignReminderOptionDropdown
                        )
                      }
                    >
                      arrow_drop_down
                    </span>

                    {showcampaignReminderOptionDropdown ? (
                      <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
                        {campaignReminderOptionList &&
                          campaignReminderOptionList.length > 0
                          ? campaignReminderOptionList.map((item, index) =>
                            item.value !==
                              campaignFormData.campaignReminderOption ? (
                              <span
                                onClick={() => {
                                  handleCampaignReminderOption(item.value);
                                  handleshowcampaignReminderOptionDropdown(
                                    !showcampaignReminderOptionDropdown
                                  );
                                }}
                                className={
                                  campaignFormData.campaignReminderOption ===
                                    item.value
                                    ? "inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b bg-gray-100"
                                    : "inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b hover:bg-gray-100"
                                }
                              >
                                {capitalizeFirstLetter(item.label)}
                              </span>
                            ) : null
                          )
                          : null}
                      </div>
                    ) : null}
                  </div>
                </div>

                {campaignFormData &&
                  campaignFormData.campaignReminderOption &&
                  campaignFormData.campaignReminderOption === 1 ? (
                  <>
                    <div className="pl-4 space-y-1">
                      <label className="text-sm font-normal text-gray-900">
                        Reminder Days
                      </label>

                      <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer">
                        <span
                          className="w-full pr-4 text-sm font-medium text-slate-800"
                          onClick={() =>
                            handleshowcampaignReminderTypeDropdown(
                              !showcampaignReminderTypeDropdown
                            )
                          }
                        >
                          {campaignFormData &&
                            campaignFormData.campaignReminderType
                            ? capitalizeFirstLetter(getReminderLabel(
                              campaignFormData.campaignReminderType
                            ))
                            : "Select Reminder Days"}
                        </span>
                        <span
                          className="text-gray-500 material-symbols-outlined"
                          onClick={() =>
                            handleshowcampaignReminderTypeDropdown(
                              !showcampaignReminderTypeDropdown
                            )
                          }
                        >
                          arrow_drop_down
                        </span>

                        {showcampaignReminderTypeDropdown ? (
                          <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
                            {campaignReminderTypeList &&
                              campaignReminderTypeList.length > 0
                              ? campaignReminderTypeList.map((item, index) =>
                                item.value !==
                                  campaignFormData.campaignReminderType ? (
                                  <span
                                    onClick={() => {
                                      handleCampaignReminderType(item.value);
                                      handleshowcampaignReminderTypeDropdown(
                                        !showcampaignReminderTypeDropdown
                                      );
                                    }}
                                    className={
                                      campaignFormData.campaignReminderType ===
                                        item.value
                                        ? "inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b bg-gray-100"
                                        : "inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b hover:bg-gray-100"
                                    }
                                  >
                                    {capitalizeFirstLetter(item.label)}
                                  </span>
                                ) : null
                              )
                              : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>


              {campaignFormData.type === "EMAIL" ? (
                <>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="space-y-1">
                      <label className="text-sm font-normal text-gray-900">
                        Email Subject
                      </label>
                      <input
                        type="text"
                        name="subject"
                        value={campaignFormData.subject}
                        onChange={handleCampaignInput}
                        placeholder={
                          LanguageObj &&
                            LanguageObj["subject_" + CurrentLanguage]
                            ? LanguageObj["subject_" + CurrentLanguage]
                            : "Subject"
                        }
                        className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none"
                      />
                    </div>
                    <div className="space-y-1 ">
                      <label className="text-sm font-normal text-gray-900">
                        Email Name
                      </label>
                      <input
                        type="text"
                        name="emailName"
                        value={campaignFormData.emailName}
                        onChange={handleCampaignInput}
                        placeholder={
                          LanguageObj &&
                            LanguageObj["emailName_" + CurrentLanguage]
                            ? LanguageObj["emailName_" + CurrentLanguage]
                            : "Name"
                        }
                        className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none"
                      />
                    </div>
                  </div>

                  <div className="space-y-1 ">
                    <label className="text-sm font-normal text-gray-900">
                      Email Message
                    </label>
                    <ReactQuill
                      className="w-12/12"
                      theme="snow"
                      value={campaignFormData.message}
                      onChange={handleMessageInput}
                      placeholder={"Message"}
                    />
                  </div>
                </>
              ) : null}

              <div className="flex items-end space-x-6">
                <div className="w-full space-y-1">
                  <label className="text-sm font-normal text-gray-900">No. of customers:</label>
                  {campaignFormData.campaign_type === "RECURSIVE" ?
                    <input value={"All"} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
                    :
                    <input value={customers && customers.length > 0 ? customers.length : 0} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
                  }
                </div>
                <div className="w-40 mb-1.5 whitespace-nowrap">
                  <label className="text-sm text-gray-500 capitalize cursor-pointer">
                    <input
                      id="mandatory-check"
                      value={campaignFormData.isTest}
                      checked={campaignFormData.isTest}
                      type="checkbox"
                      onChange={() => { }}
                      className="mr-2 cursor-pointer"
                      onClick={() => handleTestUserCheck(!campaignFormData.isTest)}
                    />

                    test user</label>
                </div>
              </div>

              <div className="flex justify-center pt-2 ">
                <span className="px-1 text-xs font-medium text-red-500">{campaignValidationList && campaignValidationList.length > 0 ? "Please enter :- " + campaignValidationList.map(element => { return element }).join(", ") : ""}</span>
              </div>
            </div>

            <div className="flex justify-start py-4 pt-0">
              <button
                onClick={() => handleCampaignSubmit()}
                className="bg-[#2196F3] text-white   py-3 px-6   text-base  justify-center  flex items-center uppercase font-medium rounded-md"
              >
                Publish
                <span
                  className="px-2 material-symbols-outlined"
                  style={{ fontSize: "1.2rem" }}
                >
                  send
                </span>
              </button>
            </div>


          </div>

          {campaignFormData.campaign_type === "NORMAL" || campaignFormData.campaign_type === "" ?

            <div className="w-full px-4 space-y-4 overflow-y-auto bg-white border rounded-md md:px-6 ">
              <h2 className="xl:text-xl text-lg font-medium text-[#3D405B] md:-mx-6 -mx-4 py-4 md:px-6 px-4 border-b flex justify-between items-center flex-row gap-4  ">Customers list</h2>
              <div className="flex flex-wrap gap-2 " >
                <div className="cursor-pointer bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] flex justify-between px-4 items-center relative py-2 rounded-md md:w-40 w-full ">
                  <div className="flex items-center space-x-2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-filter"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#fff"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        stroke="none"
                        d="M0 0h24v24H0z"
                        fill="none"
                      ></path>
                      <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5"></path>
                    </svg>
                    <span
                      className="text-sm text-white "
                      onClick={() => handleEmpLevelDropdown(showLevelFilter)}
                    >
                      {currFilterLevel === "Select Filter"
                        ? "Filter"
                        : currFilterLevel}
                    </span>
                  </div>
                  <span
                    className="text-white cursor-pointer material-symbols-outlined"
                    onClick={() => handleEmpLevelDropdown(showLevelFilter)}
                  >
                    arrow_drop_down
                  </span>

                  {showLevelFilter ? (
                    <div className="absolute left-0 z-10 w-full p-4 overflow-y-auto font-normal bg-white rounded-lg shadow-lg top-12 font-xs md:w-48 h-60">
                      <span
                        onClick={() => handleLevelSelect("All")}
                        className={
                          false
                            ? "cursor-pointer py-2 m-1 flex items-center accent-[#2196F3] text-sm"
                            : "cursor-pointer py-2 m-1 flex items-center accent-[#2196F3] text-sm"
                        }
                      >
                        {"All"}
                      </span>
                      {EmpFilterData && EmpFilterData.length > 0
                        ? EmpFilterData.map((item, index) => (
                          <span
                            onClick={() => handleLevelSelect(item.name)}
                            className={
                              currFilterLevel === item.name
                                ? "cursor-pointer py-2 m-1 flex items-center accent-[#2196F3] text-sm"
                                : "cursor-pointer py-2 m-1 flex items-center accent-[#2196F3] text-sm"
                            }
                          >
                            {item.name}
                          </span>
                        ))
                        : null}
                    </div>
                  ) : null}
                </div>

                {currFilterLevel !== "Select Filter" ? (
                  <>
                    <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                      <span
                        style={{ height: "20px" }}
                        className=" text-sm text-[#3D405B]/70 font-medium "
                        onClick={() =>
                          handleEmpValueDropdown(showValueFilter)
                        }
                      >
                        {currFilterValue}
                      </span>
                      <span
                        className="text-gray-500 cursor-pointer material-symbols-outlined"
                        onClick={() =>
                          handleEmpValueDropdown(showValueFilter)
                        }
                      >
                        arrow_drop_down
                      </span>

                      {showValueFilter ? (
                        <div className="absolute left-0 z-20 w-56 p-4 overflow-y-auto font-normal bg-white rounded-lg shadow-lg top-12 font-xs h-60 scollar-xs-blue">
                          {EmpValueList && EmpValueList.length > 0
                            ? EmpValueList.map((item, index) =>
                              item.name !== "ManagerPID" ? (
                                <span
                                  onClick={() => handleValueSelect(item)}
                                  className={
                                    currFilterValue === item
                                      ? "cursor-pointer inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b bg-gray-100"
                                      : "cursor-pointer inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b hover:bg-gray-100"
                                  }
                                >
                                  {item}
                                </span>
                              ) : null
                            )
                            : null}
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}

                <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                  <span
                    className=" text-sm text-[#3D405B]/70 font-medium "
                    onClick={() => handleBatchRangeDropdown(!showBatchRange)}
                  >
                    {formBatchRange && formBatchRange.label
                      ? formBatchRange.label
                      : "Select Batch"}
                  </span>
                  <span
                    className="text-gray-500 cursor-pointer material-symbols-outlined "
                    onClick={() => handleBatchRangeDropdown(!showBatchRange)}
                  >
                    arrow_drop_down
                  </span>

                  {showBatchRange ? (
                    <div className="absolute left-0 w-full overflow-hidden font-bold text-gray-500 bg-white rounded-md shadow-md top-10">
                      {RangeLabelList && RangeLabelList.length > 0
                        ? RangeLabelList.map((item, index) => (
                          <span
                            onClick={() => selectBatchRange(item)}
                            className={
                              formBatchRange.rangeNo === item.rangeNo
                                ? "cursor-pointer inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b bg-gray-100 font-bold"
                                : "cursor-pointer inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b hover:bg-gray-100 font-bold"
                            }
                          >
                            {item.label}
                          </span>
                        ))
                        : null}
                    </div>
                  ) : null}
                </div>

                <button
                  onClick={() => handleGetEmployees()}
                  className="bg-[#2196f3] hover:bg-[#1E88E5] transition-all duration-200 w-auto md:py-2 py-3  px-4 rounded-md text-center justify-center cursor-pointer transform  flex items-center text-white space-x-1 md:text-sm  text-md"
                >
                  Employees
                </button>
              </div>

              <div className="flex flex-col gap-4 md:flex-row md:justify-between md:items-center">
                <p className="flex text-sm text-[#3D405B]/70 ">
                  Current Showing :
                  <b className="block font-medium text-slate-800 md:inline-block">
                    {" "}
                    {EmployeesList && EmployeesList.length > 0
                      ? EmployeesList.length
                      : 0}{" "}
                    of {AllEmpTotal ? AllEmpTotal : 0}
                  </b>
                </p>

                <div className="flex justify-between space-x-4">
                  <div className="flex items-center w-full px-2 py-2 space-x-2 text-gray-500 bg-white border rounded-md md:w-auto">
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: "1.2rem" }}
                    >
                      search{" "}
                    </span>
                    <input
                      type="text"
                      value={employeeValueSearch}
                      onChange={handleEmployeeValueSearch}
                      placeholder="Search..."
                      className="text-xs focus:outline-none"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col ">
                <div className="overflow-x-auto ">
                  <div className="inline-block min-w-full overflow-hidden align-middle">
                    <table className="min-w-full whitespace-nowrap">
                      <thead>
                        <tr className="bg-white">
                          <th className="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                            <input type="checkbox" checked={selectAllEmp} onClick={() => SelectAllEmployees(selectAllEmp, EmployeesList)} name="list-check" className="mr-4" />
                            Name</th>
                          <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                            Email</th>
                          <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                            Mobile No.</th>
                          <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center border-b border-gray-200 text-slate-800">
                            Status</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">


                        {EmployeesList && EmployeesList.length > 0 ?
                          EmployeesList.map((item, index) =>
                            (index >= indexA && index <= indexB) ?

                              <tr>
                                <td className="px-4 py-4 border-b border-gray-200">
                                  <div className="flex items-center">
                                    <div className="flex-shrink-0 ">
                                      <input type="checkbox" value={item.id} checked={customers.some(prev => prev.id === item.id) ? true : false} onClick={() => handleCampaignEmployees(item)} onChange={() => { }} />
                                    </div>
                                    <div className="ml-4">
                                      <div className="text-xs leading-5 text-gray-500">{(item && item.name ? item && item.name && item.name.length > 15 ? item.name.slice(0, 15) : item.name : 'Not Available')}</div>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-6 py-4 border-b border-gray-200">
                                  <div className="text-xs leading-5 text-gray-500">{item && item.email ? item.email : 'Not Available'}</div>
                                </td>
                                <td className="px-6 py-4 text-sm font-normal text-gray-900 border-b border-gray-200">
                                  {item && item.mobile ? item.mobile : 'Not Available'}
                                </td>
                                <td className="px-6 py-4 text-sm font-medium leading-5 text-center whitespace-no-wrap border-b border-gray-200">
                                  {item.isDisable ?
                                    <span className="px-2 py-0.5 inline-flex text-xs  rounded-full bg-red-100 text-green-500">InActive</span>
                                    :
                                    <span className="px-2 py-0.5 inline-flex text-xs  rounded-full bg-green-100 text-green-500">Active</span>
                                  }
                                </td>
                              </tr>

                              : null
                          )
                          : null}

                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="flex justify-center items-center space-x-6 text-[#3D405B] text-sm font-medium p-6">
                  <div class="flex flex-wrap border divide-x rounded-md ">
                    <button
                      onClick={() => prevEmpPage()}
                      class="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition"
                    >
                      Previous
                    </button>

                    {IndexListIndex && IndexListIndex.length > 0
                      ? IndexListIndex.map((item, index) =>
                        index >= EmpIndexA && index <= EmpIndexB ? (
                          item === currentEmpPage ? (
                            <button
                              onClick={() => SelectEmpPage(item)}
                              class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] "
                            >
                              {item}
                            </button>
                          ) : (
                            <button
                              onClick={() => SelectEmpPage(item)}
                              class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition "
                            >
                              {item}
                            </button>
                          )
                        ) : null
                      )
                      : null}

                    <button
                      onClick={() => nextEmpPage(IndexListIndex)}
                      class="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition"
                    >
                      Next
                    </button>
                  </div>
                </div>



              </div>
            </div>
            : null}


          {campaignFormData.campaign_type === "KIOSK" ?

            <div className="w-full p-4 space-y-4 overflow-y-auto rounded-md md:p-6">

            </div>

            : null}


          {campaignFormData.campaign_type === "RECURSIVE" ?

            <div className="w-full px-4 mr-4 space-y-4 overflow-y-auto bg-white border rounded-md md:px-6 ">
              <h2 className="xl:text-xl text-lg font-medium text-[#3D405B] md:-mx-6 -mx-4 py-4 md:px-6 px-4 border-b flex justify-between items-center flex-row gap-4 ">
                Recursive Options
              </h2>

              <div className="grid gap-4 capitalize bg-white rounded md:grid-cols-2">

                <div className="space-y-1">
                  <label className="text-sm font-normal text-gray-900">Recursive Type</label>
                  {/* <div className="flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer"> <span>Select</span> <span className="material-symbols-outlined">arrow_drop_down</span></div> */}

                  <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 bg-white border rounded cursor-pointer">
                    <span className="w-full pr-4 text-sm font-medium text-slate-800" onClick={() => handleshowRecursiveTypeDropdown(!showRecursiveTypeDropdown)}>{
                      recursiveSettings && recursiveSettings.recursiveType ? capitalizeFirstLetter(getRECURISIVETypeLabel(recursiveSettings.recursiveType)) : 'Select Recursive Type'
                    }</span>
                    <span className="text-gray-500 material-symbols-outlined" onClick={() => handleshowRecursiveTypeDropdown(!showRecursiveTypeDropdown)}>arrow_drop_down</span>

                    {showRecursiveTypeDropdown ?
                      <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
                        {campaignRecursiveTypeList && campaignRecursiveTypeList.length > 0 ?
                          campaignRecursiveTypeList.map((item, index) =>
                            <span onClick={() => {
                              handleRecursiveType(item.value);
                              handleshowRecursiveTypeDropdown(!showRecursiveTypeDropdown);
                            }}
                              className={(recursiveSettings.isLaunchNow === item.value) ? "inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b bg-gray-100" :
                                "inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b hover:bg-gray-100"}>
                              {capitalizeFirstLetter(item.label)}</span>
                          )
                          : null}
                      </div>
                      : null}
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-normal text-gray-900">Launch Type</label>
                  {/* <div className="flex items-center justify-between p-2 text-sm text-gray-500 border rounded cursor-pointer"> <span>Select</span> <span className="material-symbols-outlined">arrow_drop_down</span></div> */}

                  <div className="relative flex items-center justify-between p-2 text-sm text-gray-500 bg-white border rounded cursor-pointer">
                    <span className="w-full pr-4 text-sm font-medium text-slate-800" onClick={() => handleshowCampaignRecursiveDropdown(!showCampaignRecursiveDropdown)}>{
                      recursiveSettings && recursiveSettings.isLaunchNow ? getLauchTypeLabel(true) : capitalizeFirstLetter(getLauchTypeLabel(false))
                    }</span>
                    <span className="text-gray-500 material-symbols-outlined" onClick={() => handleshowCampaignRecursiveDropdown(!showCampaignRecursiveDropdown)}>arrow_drop_down</span>

                    {showCampaignRecursiveDropdown ?
                      <div className="absolute left-0 z-10 w-full overflow-hidden text-gray-500 bg-white rounded-md shadow-md top-10">
                        {campaignRecursiveFreqList && campaignRecursiveFreqList.length > 0 ?
                          campaignRecursiveFreqList.map((item, index) =>
                            <span onClick={() => {
                              handleCampaignRecursiveType(item.value);
                              handleshowCampaignRecursiveDropdown(!showCampaignRecursiveDropdown);
                            }}
                              className={(recursiveSettings.isLaunchNow === item.value) ? "inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b bg-gray-100" :
                                "inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b hover:bg-gray-100"}>
                              {capitalizeFirstLetter(item.label)}</span>
                          )
                          : null}
                      </div>
                      : null}
                  </div>
                </div>

                {!recursiveSettings.isLaunchNow ?
                  <div className="space-y-1">
                    <label className="text-sm font-normal text-gray-900">Launch Date</label>
                    <input type="date" name="date" value={recursiveSettings.date} checked={recursiveSettings.isLaunchNow} onChange={handleRecursiveCampaignInput} placeholder={"Survey Date"} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
                  </div>
                  : null}

                <div className="space-y-1">
                  <label className="text-sm font-normal text-gray-900">Recursive Frequency</label>
                  <input type="number" name="frequency" value={recursiveSettings.frequency} onChange={handleRecursiveCampaignInput} placeholder={"Survey Date"} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
                </div>


                {recursiveSettings.recursiveType === "RECURSIVE_EVENT" ?
                  <div className="space-y-1">
                    <label className="text-sm font-normal text-gray-900">Employee Field Logic</label>
                    <input type="text" disabled={true} value={"(currentDate - Employee_startDate) = " + recursiveSettings.frequency} placeholder={"Survey Date"} className="w-full p-2 text-sm font-medium text-gray-900 border rounded outline-none" />
                  </div>
                  : null}


              </div>

              <div className="w-full space-y-4 overflow-y-auto rounded-md">
                <h2 className="text-xl font-medium ">Employees list</h2>

                <div className="flex flex-wrap gap-2 " >
                  <div className="cursor-pointer bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] flex justify-between px-4 items-center relative py-2 rounded-md w-40 ">
                    <div className="flex items-center space-x-2 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-filter"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#fff"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5"></path>
                      </svg>
                      <span
                        className="text-sm text-white "
                        onClick={() => handleEmpLevelDropdown(showLevelFilter)}
                      >
                        {currFilterLevel === "Select Filter"
                          ? "Filter"
                          : currFilterLevel}
                      </span>
                    </div>
                    <span
                      className="text-white cursor-pointer material-symbols-outlined"
                      onClick={() => handleEmpLevelDropdown(showLevelFilter)}
                    >
                      arrow_drop_down
                    </span>

                    {showLevelFilter ? (
                      <div className="absolute left-0 z-10 w-48 p-4 overflow-y-auto font-normal bg-white rounded-lg shadow-lg top-12 font-xs h-60">
                        <span
                          onClick={() => handleLevelSelect("All")}
                          className={
                            false
                              ? "cursor-pointer py-2 m-1 flex items-center accent-[#2196F3] text-sm"
                              : "cursor-pointer py-2 m-1 flex items-center accent-[#2196F3] text-sm"
                          }
                        >
                          {"All"}
                        </span>
                        {EmpFilterData && EmpFilterData.length > 0
                          ? EmpFilterData.map((item, index) => (
                            <span
                              onClick={() => handleLevelSelect(item.name)}
                              className={
                                currFilterLevel === item.name
                                  ? "cursor-pointer py-2 m-1 flex items-center accent-[#2196F3] text-sm"
                                  : "cursor-pointer py-2 m-1 flex items-center accent-[#2196F3] text-sm"
                              }
                            >
                              {item.name}
                            </span>
                          ))
                          : null}
                      </div>
                    ) : null}
                  </div>

                  {currFilterLevel !== "Select Filter" ? (
                    <>
                      <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                        <span
                          style={{ height: "20px" }}
                          className=" text-sm text-[#3D405B]/70 font-medium "
                          onClick={() =>
                            handleEmpValueDropdown(showValueFilter)
                          }
                        >
                          {currFilterValue}
                        </span>
                        <span
                          className="text-gray-500 cursor-pointer material-symbols-outlined"
                          onClick={() =>
                            handleEmpValueDropdown(showValueFilter)
                          }
                        >
                          arrow_drop_down
                        </span>

                        {showValueFilter ? (
                          <div className="absolute left-0 z-20 w-56 p-4 overflow-y-auto font-normal bg-white rounded-lg shadow-lg top-12 font-xs h-60 scollar-xs-blue">
                            {EmpValueList && EmpValueList.length > 0
                              ? EmpValueList.map((item, index) =>
                                item.name !== "ManagerPID" ? (
                                  <span
                                    onClick={() => handleValueSelect(item)}
                                    className={
                                      currFilterValue === item
                                        ? "cursor-pointer inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b bg-gray-100"
                                        : "cursor-pointer inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b hover:bg-gray-100"
                                    }
                                  >
                                    {item}
                                  </span>
                                ) : null
                              )
                              : null}
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}

                  <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                    <span
                      className=" text-sm text-[#3D405B]/70 font-medium "
                      onClick={() => handleBatchRangeDropdown(!showBatchRange)}
                    >
                      {formBatchRange && formBatchRange.label
                        ? formBatchRange.label
                        : "Select Batch"}
                    </span>
                    <span
                      className="text-gray-500 cursor-pointer material-symbols-outlined "
                      onClick={() => handleBatchRangeDropdown(!showBatchRange)}
                    >
                      arrow_drop_down
                    </span>

                    {showBatchRange ? (
                      <div className="absolute left-0 w-full overflow-hidden font-bold text-gray-500 bg-white rounded-md shadow-md top-10">
                        {RangeLabelList && RangeLabelList.length > 0
                          ? RangeLabelList.map((item, index) => (
                            <span
                              onClick={() => selectBatchRange(item)}
                              className={
                                formBatchRange.rangeNo === item.rangeNo
                                  ? "cursor-pointer inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b bg-gray-100 font-bold"
                                  : "cursor-pointer inline-flex items-center py-3 px-4 text-sm font-medium w-full border-b hover:bg-gray-100 font-bold"
                              }
                            >
                              {item.label}
                            </span>
                          ))
                          : null}
                      </div>
                    ) : null}
                  </div>

                  <button
                    onClick={() => handleGetEmployees()}
                    className="bg-[#2196f3] hover:bg-[#1E88E5] transition-all duration-200 w-auto md:py-2 py-3  px-4 rounded-md text-center justify-center cursor-pointer transform  flex items-center text-white space-x-1 md:text-sm  text-md"
                  >
                    Employees
                  </button>
                </div>
                <div className="flex flex-col gap-4 md:flex-row md:justify-between md:items-center">
                  <p className="flex text-sm text-[#3D405B]/70 ">
                    Current Showing :
                    <b className="block pl-4 font-medium text-slate-800 md:inline-block">
                      {EmployeesList && EmployeesList.length > 0
                        ? EmployeesList.length
                        : 0}{" "}
                      of {AllEmpTotal ? AllEmpTotal : 0}
                    </b>
                  </p>

                  <div className="flex justify-between space-x-4">
                    <div className="flex items-center w-full px-2 py-2 space-x-2 text-gray-500 bg-white border rounded-md md:w-auto">
                      <span
                        className="material-symbols-outlined"
                        style={{ fontSize: "1.2rem" }}
                      >
                        search{" "}
                      </span>
                      <input
                        type="text"
                        value={employeeValueSearch}
                        onChange={handleEmployeeValueSearch}
                        placeholder="Search..."
                        className="text-xs focus:outline-none"
                      />
                    </div>
                  </div>
                </div>


                <div className="flex flex-col ">
                  <div className="overflow-x-auto ">
                    <div className="inline-block min-w-full overflow-hidden align-middle">
                      <table className="min-w-full whitespace-nowrap">
                        <thead>
                          <tr className="bg-white">
                            <th className="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                              Name</th>
                            <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                              Email Address</th>
                            <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left border-b border-gray-200 text-slate-800">
                              Mobile No.</th>
                            <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center border-b border-gray-200 text-slate-800">
                              Status</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">


                          {EmployeesList && EmployeesList.length > 0 ?
                            EmployeesList.map((item, index) =>
                              (index >= indexA && index <= indexB) ?

                                <tr>
                                  <td className="px-4 py-4 border-b border-gray-200">
                                    <div className="text-xs leading-5 text-gray-500">{(item && item.name ? item.name : 'Not Available').slice(0, 15)}</div>
                                  </td>
                                  <td className="px-6 py-4 border-b border-gray-200">
                                    <div className="text-xs leading-5 text-gray-500">{item && item.email ? item.email : 'Not Available'}</div>
                                  </td>
                                  <td className="px-6 py-4 text-sm font-normal text-gray-900 border-b border-gray-200">
                                    {item && item.mobile ? item.mobile : 'Not Available'}
                                  </td>
                                  <td className="px-6 py-4 text-sm font-medium leading-5 text-center whitespace-no-wrap border-b border-gray-200">
                                    {item.isDisable ?
                                      <span className="px-2 py-0.5 inline-flex text-xs  rounded-full bg-red-100 text-green-500">InActive</span>
                                      :
                                      <span className="px-2 py-0.5 inline-flex text-xs  rounded-full bg-green-100 text-green-500">Active</span>
                                    }
                                  </td>
                                </tr>

                                : null
                            )
                            : null}

                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="flex justify-center items-center space-x-6 text-[#3D405B] text-sm font-medium p-6">
                    <div class="flex flex-wrap border divide-x rounded-md ">
                      <button
                        onClick={() => prevEmpPage()}
                        class="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition"
                      >
                        Previous
                      </button>

                      {IndexListIndex && IndexListIndex.length > 0
                        ? IndexListIndex.map((item, index) =>
                          index >= EmpIndexA && index <= EmpIndexB ? (
                            item === currentEmpPage ? (
                              <button
                                onClick={() => SelectEmpPage(item)}
                                class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] "
                              >
                                {item}
                              </button>
                            ) : (
                              <button
                                onClick={() => SelectEmpPage(item)}
                                class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition "
                              >
                                {item}
                              </button>
                            )
                          ) : null
                        )
                        : null}

                      <button
                        onClick={() => nextEmpPage(IndexListIndex)}
                        class="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition"
                      >
                        Next
                      </button>
                    </div>
                  </div>

                </div>
              </div>


            </div>

            : null}
        </div>
      </main>
    </>
  );
}
