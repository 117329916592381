import React from 'react';


export default function Happiness(props) {
    
    let { 

        item,

        DistributionData

     } = props;


    //console.log('item:--------ssaa---ss------------------------> ',item)

    let score = 'NaN';
    let type = '';

    if(item['score']!=='NaN'){
        score = parseFloat(item['score']);
        type = item['type'];
    }

    let scoreOverall = 0;
    let countOverall = 0;
    if(item && item.DimenionList && item.DimenionList.length>0){
      item.DimenionList.forEach((item2)=>{
        //console.log('item2:--------ssaa---------------------------> ',item2)

        if(item2 && item2.inner && item2.inner.length>0){
          item2.inner.forEach((item3)=>{
            //console.log('item3:--------ssaa---------------------------> ',item3)
            countOverall++
            scoreOverall += (Number(item3.sum)/Number(item3.count))
          })
        }

        

      })
    }

    let newscoreOverall = scoreOverall/countOverall

    let finalScore = newscoreOverall?newscoreOverall.toFixed(2):0
    return (
      <>	
      {/* <div className="bg-white hover:shadow-lg duration-150 transition  shadow-md border  p-4 rounded-lg flex items-center justify-between cursor-pointer" style={{
            // background:(item['color'])?item['color']:'#2172eb',
            height:'190px',
           
        }}>
        <div className='space-y-6' >
           <div className="text-gray-600 text-lg font-semibold capitalize">{item.index}</div>
            <div className="text-gray-600 pt-8 font-medium" style={{fontSize:'35px'}}>{(finalScore !== 'NaN')?(type==='Percentage')?finalScore+'%':finalScore : 
            <p style={{fontSize:'15px',position:'relative'}}>We have not calculated <br/> {item.index} for the organization</p>
            }</div>
            {(finalScore !== 'NaN')?<div className="text-gray-600 text-sm ">Total {type}</div>:null}
           </div>
        
            <div ><img src={item["image"]} className="w-40 mr-10 " 
           
            /></div>

        </div> */}


        <div className="bg-white hover:shadow-lg duration-150 transition border  p-4 rounded-lg flex items-center justify-between cursor-pointer">
        <div className='space-y-6' >
          <h1 className="font-medium text-lg ">{item.index}</h1>
          <div>
            {(finalScore !== 'NaN') ?
              <h1 className="xl:text-2xl text-base font-medium ">
                {(type === 'Percentage') ? finalScore + '%' : finalScore}
              </h1>
              : <p className='xl:text-xl text-base text-gray-600 font-medium line-clamp-1' >Not calculated! </p>
            }
            <p className="block text-sm font-medium text-gray-500 " >Total score</p>
          </div>
          <button className='bg-blue-100 text-blue-500  px-4 py-2 rounded-full cursor-pointer hover:bg-blue-500 hover:text-white transition duration-150' >View More</button>

        </div>
        <div>
          {/* <img alt="card" src={item["image"]} className="2xl:w-32 w-28" /> */}
        </div>
      </div>



      </>
    );
  }
