import React, { Component } from "react";
import { connect } from "react-redux";
import { dashboardActions, surveyActions } from "../../_actions";
import Header from "../SurveyMgmt/Header/Header";
import Sidebar from "../Overview/Sidebar/SidebarOverview";
import { accessModule } from "../../_helpers";
import { userActions } from "../../_actions";
import LoadingOverlay from "react-loading-overlay";
import * as XLSX from "xlsx";
import AddFilter from "./Modal/AddFilter";
import AllFilters from "./Modal/AllFilters";
import NewUserModal from "./Modal/NewUserModal";
import UploadPreview from "./Modal/UploadPreview";

class FilterSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSidebar: true,
      showFilterNames: false,
      CurrentLanguage: "en",
      CrrEmpFilterLabel: "",
      showInputBox: false,
      formDataFilter: {
        name: "",
        label: "",
        rank: 1,
        isDisable: false,
      },
      updateformDataFilter: {
        id: "",
        name: "",
        label: "",
        rank: 1,
      },
      showMobileSidebar: false,
      searchName:""
    };
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getCompanyNameOnly());
    this.props.dispatch(dashboardActions.getClientProfile());
    this.props.dispatch(surveyActions.getEmployeeFields());
    this.props.dispatch(surveyActions.getEmployeeFilterListByCompanyId());
    this.props.dispatch(surveyActions.getEmployeeColumnName());
  }

  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });

    // setTimeout(()=>{
    //   this.setState({showSidebar:false});
    // },4000);
  };

  routeLink = (link) => {
    this.props.history.push("/app/" + link);
    this.setState({ CrrRoute: link });
  };

  handleLanguage = (item) => {
    this.setState({
      CurrentLanguage: item.shortName,
      CurrentLanguageName: item.name,
      showLanguageModal: false,
    });
  };

  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  };

  onLogout = () => {
    console.log("logout");
    this.props.dispatch(userActions.logout());
  };

  handleShowReportStatus = (value) => {
    ////console.log("handleShowReportStatus::", value);
    this.props.dispatch(
      surveyActions.updateEmployeeShowReportStatus({ id: value })
    );
  };

  closeEmployeeFilter = (check) => {
    this.setState({ openEmpFilterModal: check });
  };

  onEmployeeFilterDropdown = (check) => {
    this.setState({ showFilterNames: check });
  };

  handleEmployeeColumnSelect = (name) => {
    let { formDataFilter } = this.state;
    formDataFilter["name"] = name;
    formDataFilter["label"] = name;
    this.setState({ formDataFilter, showFilterNames: false });
  };

  createEmployeeFilter = (NameItem) => {
    // let { formDataFilter } = this.state;
    let { getEmployeeFilterListByCompanyIdData } = this.props.survey;
    let Rank =
      getEmployeeFilterListByCompanyIdData &&
      getEmployeeFilterListByCompanyIdData.length > 0
        ? getEmployeeFilterListByCompanyIdData.length + 1
        : 1;
    // console.log('Rank: ',Rank)

    if (NameItem) {
      let tempData = {
        name: NameItem,
        label: NameItem,
        rank: Rank,
        isDisable: false,
      };
      this.props.dispatch(surveyActions.createEmployeeFilter(tempData));
    }

    // let tempClear ={
    //   "name": "",
    //   "label": "",
    //   "isDisable": false
    // }
    // this.setState({formDataFilter:tempClear});
    this.setState({ openEmpFilterModal2: false });
  };

  onDeleteFilter = (empFilter) => {
    let tempData = {
      id: empFilter.id,
    };
    this.props.dispatch(surveyActions.deleteEmployeeFilter(tempData));
  };

  handleFilterCheck = () => {
    let { formDataFilter } = this.state;
    if (formDataFilter.isDisable) {
      formDataFilter["isDisable"] = false;
    } else {
      formDataFilter["isDisable"] = true;
    }
    this.setState({ formDataFilter });
  };

  handleFilterStatus = (value) => {
    ////console.log("handleFilterStatus::", value);
    this.props.dispatch(
      surveyActions.updateEmployeeFilterStatus({ id: value })
    );
  };

  handleShowReportStatus = (value) => {
    ////console.log("handleShowReportStatus::", value);
    this.props.dispatch(
      surveyActions.updateEmployeeShowReportStatus({ id: value })
    );
  };

  handleIsEngCompStatus = (value) => {
    ////console.log("handleShowReportStatus::", value);
    this.props.dispatch(
      surveyActions.updateEmployeeEngagementComparisonStatus({ id: value })
    );
  };


  handleIsDMGSurveyStatus = (value, type) => {
    this.props.dispatch(
      surveyActions.updateEmpFilterDMGStatus({ id: value, type:type })
    );
  };



  handleIsEDAStatus = (value) => {
    ////console.log("handleShowReportStatus::", value);
    this.props.dispatch(surveyActions.updateEmployeeEDAStatus({ id: value }));
  };

  handleFilterRank = (e) => {
    let { formDataFilter } = this.state;
    formDataFilter["rank"] = e.target.value;
    this.setState({ formDataFilter });
  };

  updatehandleFilterRank = (value, emp) => {
    // let { updateformDataFilter } = this.state;
    // updateformDataFilter["rank"] = value;
    // this.setState({updateformDataFilter});
    this.props.dispatch(
      surveyActions.updateEmployeeFilterRank({ id: emp.id, rank: value })
    );
  };

  onClickQuesUp = (emp1, index) => {
    let { survey } = this.props;
    let { getEmployeeFilterListByCompanyIdData } = survey;
    // ////console.log("working:onClickUp: ",emp1,index,'==>',surveyId)

    let emp2 = "";
    if (
      getEmployeeFilterListByCompanyIdData &&
      getEmployeeFilterListByCompanyIdData.length > 0 &&
      index
    ) {
      emp2 = getEmployeeFilterListByCompanyIdData[index - 1].id;
    }

    if (emp1 && emp2) {
      let data = {
        idA: emp1,
        idB: emp2,
      };
      console.log("data idA, IdB: ", data);
      this.props.dispatch(surveyActions.updateEmployeeFilterRank(data));
    }
  };

  onClickQuesDown = (emp1, index) => {
    let { survey } = this.props;
    let { getEmployeeFilterListByCompanyIdData } = survey;
    let emp2 = "";
    if (
      getEmployeeFilterListByCompanyIdData &&
      getEmployeeFilterListByCompanyIdData.length > 0 &&
      index !== getEmployeeFilterListByCompanyIdData.length - 1
    ) {
      emp2 = getEmployeeFilterListByCompanyIdData[index + 1].id;
    }

    if (emp1 && emp2) {
      let data = {
        idA: emp1,
        idB: emp2,
      };
      console.log("data idA, IdB: ", data);
      this.props.dispatch(surveyActions.updateEmployeeFilterRank(data));
    }
  };

  handleChangeLabel = (empData) => {
    let Id = empData && empData.id ? empData.id : "";
    let label = empData && empData.label ? empData.label : "";
    this.setState({ CrrEmpFilterLabel: label, showInputBox: Id });
  };
  handleLabelInput = (e) => {
    this.setState({ CrrEmpFilterLabel: e.target.value });
  };

  submitEmployeeFilterLabelChange = (id) => {
    let { CrrEmpFilterLabel } = this.state;
    let data = {
      id: id,
      label: CrrEmpFilterLabel,
    };
    this.props.dispatch(surveyActions.updateEmployeeFilterLabel(data));
    this.setState({ showInputBox: "" });
    setTimeout(() => {
      this.setState({ CrrEmpFilterLabel: "" });
    }, 500);
  };

  handleSidebar = (check) => {
    this.setState({ showSidebar: check });
  };
  handleMobileSidebar = (check) => {
    this.setState({ showMobileSidebar: check });
    this.setState({ showSidebar: check });
  };

  handleSearch = (e) => {
    this.setState({ searchName: e.target.value });
  };

  render() {
    let { survey, dashboard } = this.props;

    let {
      parameterList,
      getParameterLibraryListbyParameterTypeData,
      getParameterListbyParentData,
      getParameterListData,
    } = survey;
    let { getParameterTypeListData, getCompanyNameOnlyData } = dashboard;

    let { CurrBucketId, isBucket, showCreateParameterModal, searchName } = this.state;

    let {
      getAllEmployeeListData,
      getEmployeeFieldsData,
      saveEmployeeListData,
      loading,
      getEmployeeColumnNameData,
      getEmployeeFilterListByCompanyIdData,
      getLanguageLabelData,
      getLanguageData,
      getEmployeeListData,
      getEmployeeListTotal,
      getSurveyListData,
    } = survey;

    let {
      showMobileSidebar,
      showInputBox,
      CrrEmpFilterLabel,
      fileUploadError,
      showEditDetails,
      showFilterNames,
      formDataFilter,
      updateformDataFilter,
      openEmpFilterModal2,
      openEmpFilterModal,
      showEmployeeModal,
      showLanguageModal,
      CurrentLanguageName,
      CurrentLanguage,
      showSidebar,
      CrrRoute,
      employeeData,
      savePackage,
      isPackageSelected,
      showQuestionLibrary,
      packageList,
      CurrentSurveyData,
      showSurveyLibrary,
      showChooseSurveyModal,
      showSurveyList,
      CurrentSurveyName,
      confirmDeleteSurvey,
      surveyFormData,
      showCreateSurveyPopup,
      surveyValidation,
    } = this.state;

    // let itemData = getEmployeeFieldsData && getEmployeeFieldsData[0] && getEmployeeFieldsData[0].allkeys
    //   ? getEmployeeFieldsData[0].allkeys : []

    // console.log("getEmployeeColumnNam1111111111111111111111111111111", getEmployeeColumnNameData);
    // console.log("getEmployeeFieldsDataget111111111111111111111111111111", itemData);
    // console.log("RENDER___________________", getEmployeeFilterListByCompanyIdData);
    // console.log('AllParameters: ', AllParameters)
    // console.log('AllParameterTotal: ', AllParameterTotal)
    // console.log('---------CrrEmpFilterLabel--------- ', CrrEmpFilterLabel)
    // console.log('getEmployeeColumnNameData: ', getEmployeeColumnNameData)
    // console.log('parameterList: ', parameterList)

    let AllParameters =
      parameterList && parameterList.length > 0
        ? parameterList.filter((prev) => prev.parameterTypeLevel === 1)
        : [];

    let AllParameterTotal =
      parameterList && parameterList.length > 0
        ? parameterList.filter((prev) => prev.parameterTypeLevel === 1).length
        : 0;

    function checkIndexTemplate(para) {
      let avoidTheseTemplates = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      let check = false;
      let templateId = para && para.template ? para.template : "";
      if (!avoidTheseTemplates.includes(templateId)) {
        check = true;
      }
      return check;
    }

    let EmployeeFilterCompanyList = [];
    if (
      getEmployeeFilterListByCompanyIdData &&
      getEmployeeFilterListByCompanyIdData.length > 0
    ) {
      getEmployeeFilterListByCompanyIdData.forEach((filter) => {
        if (!filter.isDisable) {
          EmployeeFilterCompanyList.push(filter);
        }
      });
    }
    //--------------------------------------------------------------------------------

    let IndexList = [];
    let pageCount = 7;
    let IndexListIndex = [];

    if (AllParameterTotal) {
      if (AllParameterTotal && AllParameters && AllParameters.length > 0) {
        [...Array(AllParameterTotal).keys()].forEach((item, index) => {
          if (index % pageCount === 0) {
            IndexList.push(index);
          }
          if (index === AllParameters.length - 1) {
            IndexList.push(index);
          }
        });
      }

      console.log("IndexList: ", IndexList);

      if (IndexList && IndexList.length > 0) {
        IndexList.forEach((item, index) => {
          if (index !== IndexList.length - 1) {
            IndexListIndex.push(index + 1);
          }
        });
      }
      // console.log('IndexListIndex: ', IndexListIndex)
    }
    //--------------------------------------------------------------------------------

    //ROLE MANAGEMENT ----------------------------------------------

    let moduleAccess = {
      isAdmin: false,
      access: {},
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    console.log("moduleAccess: ", moduleAccess);

    let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    let EmpRole = "";
    if (clientData && clientData.role) {
      if (clientData.role === "ADMIN") {
        EmpRole = "ADMIN";
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      } else if (clientData.role === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      } else if (clientData.role === "CROLE") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      } else if (clientData.role === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return moduleAccess.isAdmin
        ? true
        : moduleAccess && moduleAccess.access && moduleAccess.access[item]
        ? true
        : false;
    }

    //END -------------------------------------------------------

    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }

    let { ClientProfileData } = this.props.dashboard;
    if (
      ClientProfileData &&
      ClientProfileData.role &&
      ClientProfileData.role === "MNGR"
    ) {
      this.props.history.push(`/app/manageractionplan`);
    } else if (
      ClientProfileData &&
      ClientProfileData.role &&
      ClientProfileData.role === "EMP"
    ) {
      this.props.history.push(`/app/employeeactionplan`);
    }

    let EmployeeListData = [];
    // if(getEmployeeListData && getEmployeeListData.length>0){
    //   EmployeeListData = Object.key(getEmployeeListData)
    // }
    // console.log('getEmployeeListData: ',getEmployeeListData)
    // console.log('EmployeeListData: ',EmployeeListData)

    let EmployeeFieldList = [];
    if (getEmployeeListData && getEmployeeListData.length > 0) {
      EmployeeFieldList = Object.keys(getEmployeeListData[0]);
    }
    console.log("EmployeeFieldList: ", EmployeeFieldList);

    let NewEmployeeFieldList = [];
    if (EmployeeFieldList && EmployeeFieldList.length > 0) {
      EmployeeFieldList.forEach((item) => {
        if (
          item != "isDisable" &&
          item != "isTest" &&
          item != "createdAt" &&
          item != "id" &&
          item != "actionPlanStage" &&
          item != "companyId" &&
          item != "email" &&
          item != "emp_id"
        ) {
          NewEmployeeFieldList.push(item);
        }
      });
    }

    if (EmployeeFieldList) {
    }
    // console.log('NewEmployeeFieldList: ', NewEmployeeFieldList)

    // console.log('employeeData: ', employeeData)

    // console.log('---------> IndexListIndex: ', IndexListIndex)
    let { getAllParameterListData } = this.props.dashboard;

    function GetParameterLabel(name) {
      let AllParameterObj = {};
      if (getAllParameterListData && getAllParameterListData.length > 0) {
        getAllParameterListData.forEach((item) => {
          AllParameterObj[item.name] =
            item && item.label ? item.label : item.name;
        });
      }
      return AllParameterObj && AllParameterObj[name]
        ? AllParameterObj[name]
        : name;
    }

    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text="Loading Data..."
          className="loader"
        >
          <div className="h-screen overflow-hidden bg-gray-50">
            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              props={this.props}
              companyName={
                getCompanyNameOnlyData && getCompanyNameOnlyData.name
                  ? getCompanyNameOnlyData.name
                  : ""
              }
              sidebarToggle={this.sidebarToggle}
            />

            <div
              className="flex w-full"
              style={{ height: "calc(100% - 2rem)" }}
            >
              <Sidebar
                onOverview={this.onOverview}
                showSidebar={showSidebar}
                handleSidebar={this.handleSidebar}
                accessAllow={accessAllow}
                routeLink={this.routeLink}
                showMobileSidebar={this.state.showMobileSidebar}
              />
              <main className="flex-1 md:p-6 p-4 w-full bg-[#f9fafb]  overflow-hidden overflow-y-auto h-[calc(100vh-4rem)] space-y-4">
                <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
                  <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]   ">
                    Filter Setting
                  </h1>

                  <div className="md:flex hidden items-center text-sm text-[#3D405B] ">
                    <span className="mx-2 material-symbols-rounded">home</span>
                    <span>/</span>
                    <span className="px-2 font-semibold">Filter Setting</span>
                  </div>
                </div>

                <div class="  md:flex  justify-between items-center border-b">
                  <div class="text-xs font-medium flex text-gray-600 whitespace-nowrap overflow-x-auto overflow-hidden space-x-2 tab-scroll scollar-xs">
                    <span class="cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] ">
                      Employee Filter
                    </span>
                    {/* <span class="hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border ">
                      Customer Filter
                    </span> */}
                  </div>
                </div>

                <div className="flex flex-col gap-4 xl:flex-row 2xl:gap-6 ">
                  {/* filter section */}
                  <div className="flex-shrink-0 w-full h-full transition duration-150 bg-white border rounded-lg cursor-pointer hover:shadow-lg xl:w-80">
                    <div className="p-4 px-6 flex items-center text-[#3D405B] border-b  font-medium text-lg">
                      Name list{" "}
                      <span className="ml-2 text-sm ">(32)</span>
                    </div>

                    <AddFilter
                      searchName={searchName}
                      handleSearch={this.handleSearch}
                      closeEmployeeFilter={this.closeEmployeeFilter}
                      onEmployeeFilterDropdown={this.onEmployeeFilterDropdown}
                      handleEmployeeColumnSelect={
                        this.handleEmployeeColumnSelect
                      }
                      handleFilterCheck={this.handleFilterCheck}
                      createEmployeeFilter={this.createEmployeeFilter}
                      onDeleteFilter={this.onDeleteFilter}
                      handleFilterStatus={this.handleFilterStatus}
                      handleShowReportStatus={this.handleShowReportStatus}
                      handleIsEngCompStatus={this.handleIsEngCompStatus}
                      handleIsEDAStatus={this.handleIsEDAStatus}
                      showFilterNames={showFilterNames}
                      formDataFilter={formDataFilter}
                      getEmployeeColumnNameData={getEmployeeColumnNameData}
                      EmployeeFilterCompanyList={EmployeeFilterCompanyList}
                      LanguageObj={LanguageObj}
                      CurrentLanguage={CurrentLanguage}
                      getEmployeeFilterListByCompanyIdData={
                        getEmployeeFilterListByCompanyIdData
                      }
                      handleFilterRank={this.handleFilterRank}
                      updateformDataFilter={updateformDataFilter}
                      updatehandleFilterRank={this.updatehandleFilterRank}
                      onClickQuesUp={this.onClickQuesUp}
                      onClickQuesDown={this.onClickQuesDown}
                      handleChangeLabel={this.handleChangeLabel}
                      CrrEmpFilterLabel={CrrEmpFilterLabel}
                      handleLabelInput={this.handleLabelInput}
                      showInputBox={showInputBox}
                      submitEmployeeFilterLabelChange={
                        this.submitEmployeeFilterLabelChange
                      }
                    />
                  </div>


                 <AllFilters
                    closeEmployeeFilter={this.closeEmployeeFilter}
                    onEmployeeFilterDropdown={this.onEmployeeFilterDropdown}
                    handleEmployeeColumnSelect={this.handleEmployeeColumnSelect}
                    handleFilterCheck={this.handleFilterCheck}
                    createEmployeeFilter={this.createEmployeeFilter}
                    onDeleteFilter={this.onDeleteFilter}
                    handleFilterStatus={this.handleFilterStatus}
                    handleShowReportStatus={this.handleShowReportStatus}
                    handleIsDMGSurveyStatus={this.handleIsDMGSurveyStatus}
                    handleIsEngCompStatus={this.handleIsEngCompStatus}
                    handleIsEDAStatus={this.handleIsEDAStatus}
                    showFilterNames={showFilterNames}
                    formDataFilter={formDataFilter}
                    getEmployeeColumnNameData={getEmployeeColumnNameData}
                    EmployeeFilterCompanyList={EmployeeFilterCompanyList}
                    LanguageObj={LanguageObj}
                    CurrentLanguage={CurrentLanguage}
                    getEmployeeFilterListByCompanyIdData={
                      getEmployeeFilterListByCompanyIdData
                    }
                    handleFilterRank={this.handleFilterRank}
                    updateformDataFilter={updateformDataFilter}
                    updatehandleFilterRank={this.updatehandleFilterRank}
                    onClickQuesUp={this.onClickQuesUp}
                    onClickQuesDown={this.onClickQuesDown}
                    handleChangeLabel={this.handleChangeLabel}
                    CrrEmpFilterLabel={CrrEmpFilterLabel}
                    handleLabelInput={this.handleLabelInput}
                    showInputBox={showInputBox}
                    submitEmployeeFilterLabelChange={
                      this.submitEmployeeFilterLabelChange
                    }
                  />
                
                </div>
              </main>
            </div>
          </div>
        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard,
  };
}
export default connect(mapStateToProps)(FilterSetting);
