import React, { Component } from 'react'
// import { growActions } from "../../../../_actions/grow.actions";
import { connect } from "react-redux";
import LeadingSelf from '../LeadingSelf/LeadingSelf';
import LeadingSelfInner from '../LeadingSelfInner/LeadingSelfInner';
import DayAction from '../DayAction/DayAction';
import Notetoself from '../DayAction/Notetoself';


// import moment from "moment"

class GrowMonth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType1: true,
      showMusic: true,
      viewType2: false,
      width: 0, height: 0,
      screen: 1,
      crrItemID: "",
      crrItemInnerData: "",
      crrMonthName:"",
      crrSelfInnerName:"",
      crrDayActionName:""
    };
    // this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }
  componentDidMount() {

    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    // this.props.dispatch(growActions.getGrowMonth());
    // this.props.dispatch(growActions.getGrowParameterList(data));

  }



  leading = (item) => {

    console.log("itemitemitemitemitem____", item);


    let alsoCall = "getGrowWeekListbyMonthId"
    let { call_startGrowMonth_getGrowWeekListbyMonthId } = this.props;

    if (item.isStart === 0) {
      this.setState({ newid: item.id })
      let data = { monthId: item.id }
      // this.props.dispatch(dashboardActions.startGrowMonth(data, this.props))
      call_startGrowMonth_getGrowWeekListbyMonthId(true, data, alsoCall)
      let monthname = { item }
      // this.props.history.push('/app/leadingself/'+item.id)
    }
    else {
      let monthname = { item }
      // this.props.history.push('/app/leadingself/'+item.id)

      let data = { monthId: item.id }
      call_startGrowMonth_getGrowWeekListbyMonthId(false, data, alsoCall);

    }


    this.setState({ screen: 2 ,crrMonthName: item && item.bucketId && item.bucketId.name})


  }


  leading_startGrowWeek_getGrowDayListbyWeekId = (a, b, c) => {
    let { call_startGrowWeek_getGrowDayListbyWeekId } = this.props;

    call_startGrowWeek_getGrowDayListbyWeekId(a, b, c)
    this.setState({ screen: 3 })

  }


  selectHabit_startGrowDay_getGrowDayDatabyDayId = (a, b, c, d) => {
    let { call_startGrowDay_getGrowDayDatabyDayId } = this.props;

    call_startGrowDay_getGrowDayDatabyDayId(a, b, c, d)
    this.setState({ screen: 4, crrItemID: d.id })

  }


  //   leading = (item) => {

  //  console.log("itemitemitemitemitem____",item);

  //     if (item.isStart === 0) {
  //       this.setState({ newid: item.id })
  //       let data = { monthId: item.id }
  //       // this.props.dispatch(growActions.startGrowMonth(data, this.props))
  //       let monthname = { item }

  //     }
  //     else {
  //       let monthname = { item }
  //     }

  //     this.setState({screen:2, crrItemID: item.id },()=>{

  //       let { callGrowAPI } = this.props;
  //       let data = { monthId: item.id }
  //       // callGrowAPI(data,"getGrowWeekListbyMonthId")

  //       callGrowAPI(item.isStart === 0,"startGrowMonth",data,"getGrowWeekListbyMonthId")

  //     })


  //   }

  // callLeadingInner=(isStart,innerapi,item)=>{

  //   this.setState({screen:3, crrItemInnerData: item },()=>{

  //     let { callGrowAPI } = this.props;
  //     let data = { monthId: item.monthId, weekId: item.id }
  //     callGrowAPI(isStart,innerapi,data,"getGrowDayListbyWeekId")


  //   })
  // }

  goBackToScreen=(screen)=>{
    this.setState({ screen: screen })
  }

  setLeaderSelfInnerName=(name)=>{
    this.setState({ crrSelfInnerName: name })
  }

  setDayActionName=(name)=>{
    this.setState({ crrDayActionName: name })
  }

  render() {
    let { closeNote, submitSucess, allGrowParametersList, getMonth, getWeekListbyMonthId, getGrowDayListbyWeekId,
      getGrowDayDatabyDayId, submitGrowDaySuccess, submitGrowDayData, getAllGrowNote } = this.props;

    let { viewType1, viewType2, screen, crrItemID, crrMonthName, crrSelfInnerName, crrDayActionName } = this.state;
    console.log("submitGrowDayData---------> ", submitGrowDayData);
    return (
      <>

        {!(submitGrowDaySuccess && submitSucess) ?
          <>
            {screen === 1 ?
              
                <div className=" space-y-6 " >
                  <div className='flex space-x-4 items-center'>
                    {/* <span class="cursor-pointer material-symbols-outlined bg-blue-100 p-2 rounded-full text-blue-500 " style={{ fontSize: "1.4rem" }} >arrow_back</span> */}
                    <h1 className="text-xl font-medium">Grow App</h1>
                  </div>

                  {/* <Profile /> */}
                  <main className=" flex-1 space-y-6">

                    {/* <div className=" border-b md:text-base text-md my-4 overflow-x-auto py-4 scrollbar-remove whitespace-nowrap  overscroll-contain mt-4">
                      <div className>
                        <span onClick={() => this.handleMenu()} className={viewType1 ? "md:px-6 px-4 font-semibold text-blue-500 py-4 border-b-2 border-blue-500" : "md:px-6 px-4 font-semibold text-gray-600 py-4"}>GrowMonth</span>
                        <span onClick={() => this.handleMenu1()} className={viewType2 ? "md:px-6 px-4 font-semibold text-blue-500 py-4 border-b-2 border-blue-500" : "md:px-6 px-4 font-semibold text-gray-600 py-4"}>MY REWARDS</span>
                      </div>
                    </div> */}
                    <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">


                      {
                        getMonth && getMonth.length > 0 ?
                          getMonth.map((item, index) =>
                            <div className="flex  flex-row justify-between rounded-lg overflow-hidden    shadow-lg cursor-pointer items-center h-52" style={{ backgroundColor: item.colorCode }}>

                              <div className="w-7/12  p-4 flex flex-col justify-between space-y-4">
                                <h1 className="text-slate-800 font-semibold text-xl  capitalize">{item && item.bucketId && item.bucketId.name}</h1>
                                <p className="text-gray-800  font-medium text-sm  capitalize">{item && item.bucketId && item.bucketId.desc}</p>
                                <div>
                                  <button onClick={() => this.leading(item)} className="flex text-slate-800 text-sm  bg-white shadow-lg rounded-full px-8  py-2 hover:bg-gray-100 hover:text-slate-800 cursor-pointer capitalize font-medium ">start</button>
                                </div>
                                {/* <button onClick= {() => this.state.leading(item)} className="text-slate-800 text-base border border-none bg-white shadow-lg rounded-full p-1 hover:bg-gray-100 hover:text-slate-800 cursor-pointer uppercase">start</button> */}
                              </div>

                              <div className='w-5/12' >
                                <img className="block h-auto w-full" src={item.imageLink} alt="img" />
                              </div>

                            </div>


                          ) : null
                      }


                    </div>

                  </main>



                </div>
         
              : null}

            {screen === 2 ?
              <>
                <LeadingSelf
                  getWeekListbyMonthId={getWeekListbyMonthId}
                  leading_startGrowWeek_getGrowDayListbyWeekId={this.leading_startGrowWeek_getGrowDayListbyWeekId}
                  crrMonthName={crrMonthName}
                  goBackToScreen={this.goBackToScreen}
                  setLeaderSelfInnerName={this.setLeaderSelfInnerName}
                />
              </>
              : null}

            {screen === 3 ?
              <>
                <LeadingSelfInner
                  getGrowDayListbyWeekId={getGrowDayListbyWeekId}
                  selectHabit_startGrowDay_getGrowDayDatabyDayId={this.selectHabit_startGrowDay_getGrowDayDatabyDayId}
                  goBackToScreen={this.goBackToScreen}
                  crrSelfInnerName={crrSelfInnerName}
                  setDayActionName={this.setDayActionName}

                />
              </>
              : null}

            {screen === 4 ?
              <>
                <DayAction
                  crrItemID={crrItemID}
                  getGrowDayDatabyDayId={getGrowDayDatabyDayId}
                  callDataActionAPI={this.props.callDataActionAPI}
                  crrDayActionName={crrDayActionName}
                  goBackToScreen={this.goBackToScreen}

                />
              </>
              : null}
          </>
          : null}


        {submitGrowDaySuccess && submitSucess ?
          <Notetoself
            closeNote={() => { closeNote(); this.setState({ screen: 1 }) }}
            getAllGrowNote={getAllGrowNote}
            weekId={submitGrowDayData && submitGrowDayData.weekId ? submitGrowDayData.weekId : ""}
            callDataActionAPI={this.props.callDataActionAPI}
          />
          : null}

      </>
    )
  }
}

function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users } = state;
  return {
    loggingIn,
    questions,
    users,
  };
}
export default connect(mapStateToProps)(GrowMonth)