import {
  userConstants
} from '../_constants';

export function user(state = {}, action) {

  switch (action.type) {

    //LOGIN
  
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        UserLoginEmailSuccess: true,
        UserLoginSuccess: false,
        UserLoginFailure: false,
        UserEmailToken:action.user.userinfoToken.token

      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
        UserLoginErrorMSG: action.error,
        UserLoginFailure: true


      };

// PMS login

case userConstants.PMS_LOGIN_REQUEST:
  return {
    ...state,
    loading: true
  };
case userConstants.PMS_LOGIN_SUCCESS:
  return {
    ...state,
    UserLoginEmailSuccess: true,
    UserLoginSuccess: false,
    UserLoginFailure: false,
    UserEmailToken:action.user.userinfoToken.token

  };
case userConstants.PMS_LOGIN_FAILURE:
  return {
    ...state,
    error: action.error,
    UserLoginErrorMSG: action.error,
    UserLoginFailure: true


  };


    //LOGOUT
    // case userConstants.LOGIN_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case userConstants.LOGIN_SUCCESS:
    //   return {
    //     ...state,
    //     logoutSuccess: true

    //   };
    // case userConstants.LOGIN_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error

    //   };

    
    // PMS OTP
    case userConstants.PMS_LOGIN_OTP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.PMS_LOGIN_OTP_SUCCESS:
      return {
        ...state,
        UserLoginSuccess: true,
        UserLoginEmailSuccess: false,
        UserLoginOTPFailure: false,
        UserCurrentStage:action.user.userinfo.currentStage
      };
    case userConstants.PMS_LOGIN_OTP_FAILURE:
      return {
        ...state,
        error: action.error,
        loginOtpErrorMSG: action.error,
        UserLoginOTPFailure: true


      };



    //LOGIN OTP
    case userConstants.LOGIN_OTP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_OTP_SUCCESS:
      return {
        ...state,
        UserLoginSuccess: true,
        UserLoginEmailSuccess: false,
        UserLoginOTPFailure: false,
        UserCurrentStage:action.user.userinfo.currentStage
      };
    case userConstants.LOGIN_OTP_FAILURE:
      return {
        ...state,
        error: action.error,
        loginOtpErrorMSG: action.error,
        UserLoginOTPFailure: true


      };


      case userConstants.FILE_UPLOAD_STATUS_SUCCESS:
        return {
          ...state, 
          imageUploadSuccess:true,
          filesDetails: action.uploadImage.filesDetails
        };
  
      case userConstants.FILE_UPLOAD_STATUS_FAILURE:
        return {
          ...state
        };



  
        case userConstants.LOGO_FILE_UPLOAD_STATUS_SUCCESS:
          return {
            ...state, 
            logoUploadSuccess:true,
            logo2UploadSuccess:false,
            filesDetailsLogo: action.uploadImage.filesDetailsLogo
          };
    
        case userConstants.LOGO_FILE_UPLOAD_STATUS_FAILURE:
          return {
            ...state
          };
  
      
  
        case userConstants.LOGO_FILE_UPLOAD_2_STATUS_SUCCESS:
          ////console.log("LOGO_FILE_UPLOAD_2_STATUS_SUCCESS",state)  
          return {
            ...state, 
            logo2UploadSuccess:true,
            logoUploadSuccess:false,
            filesDetailsLogo2: action.uploadImage.filesDetailsLogo2
          };
    
        case userConstants.LOGO_FILE_UPLOAD_2_STATUS_FAILURE:
          return {
            ...state
          };
  


                  
          case userConstants.UPLOAD_PDF_STATUS_REQUEST:
            return {
              ...state, 
              PDFUploadSuccess:false
            };
      
        case userConstants.UPLOAD_PDF_STATUS_SUCCESS:
          return {
            ...state, 
            PDFUploadSuccess:true,
            filePDFDetails: action.uploadImage.filePDFDetails
          };
    
        case userConstants.UPLOAD_PDF_STATUS_FAILURE:
          return {
            PDFUploadSuccess:false,
            ...state
          };
  
  


    default:
      return state
  }
}