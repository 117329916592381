export const CONST = {
  // BACKEND_URL: 'http://localhost:5000/api/v1',
  // BACKEND_URL_PMS: 'http://localhost:5000/api/v1/pms',

  BACKEND_URL_PMS: 'https://demoplus.happyplus.in/api/v1/pms',
  BACKEND_URL: 'https://demoplus.happyplus.in/api/v1',

  // BACKEND_URL_PMS: 'https://plusinsight.happyplus.in/api/v1/pms',
  // BACKEND_URL: 'https://plusinsight.happyplus.in/api/v1',

  SURVEY_RESPONSE: "https://demoplus.happyplus.in:8080//#/response/signin/",
  SURVEY_URL: "https://demoplus.happyplus.in:8080//#/v2/",
  SURVEY_LOGIN: "https://demoplus.happyplus.in:8080//#/login/",
  BOT_URL: "https://demoplus.happyplus.in:7002/#/plusvent/",

  MANAGER_ID_TYPE: "String", //Int or String

  APP_NAME: 'PLUS HR',
  FOOTER_TEXT: '©2023 Happyplus. All rights reserved.'

};
