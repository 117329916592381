import React, { useState } from "react";
import Header from "./Header/Header";
export default function SalaryHikeStatusEmployee() {
  const [open, close] = useState(0);
  return (
    <div className="h-screen overflow-hidden bg-gray-50">
      <Header />

      <div
        className="flex flex-row w-full"
        style={{ height: "calc(100% - 2rem)" }}
      >
        <div
          className="cursor-pointer transition-width w-64  lg:block lang lg:relative fixed top-0 left-0 z-20  h-screen     duration-200 transform bg-white border-r hidden"
          style={{ height: "calc(100% - 0rem)" }}
        >
          <div className="my-4 whitespace-nowrap ">
            <div
              title="Users"
              className="flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer   border-l-4 border-[#2196F3] bg-[#2196F3]/10"
            >
              <div className="flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center">
                <span className=" material-symbols-outlined">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-users"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth="1.8"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx={9} cy={7} r={4} />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                </span>
              </div>
              <span className=" text-sm text-[#2196F3]">Appraisal Cycle</span>
            </div>
          </div>
        </div>

        <main
          className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto  md:p-6 p-4 space-y-6 relative   "
          style={{ height: "calc(100% - 4rem)" }}
        >
          <div className="pb-6">
            <div className="bg-white -m-6 ">
              <div className="flex justify-between px-6 py-4">
                <div className="flex md:flex-row flex-col gap-4 items-center   text-[#212121]">
                  <div className="flex items-center space-x-4">
                    <button className="duration-200 rounded-md cursor-pointer hover:bg-slate-100">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth={0}
                        viewBox="0 0 24 24"
                        className="w-5 h-5"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path fill="none" d="M0 0h24v24H0V0z" opacity=".87" />
                        <path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z" />
                      </svg>{" "}
                    </button>
                    <img
                      src="img/newimg/Rectangle 11116.png"
                      alt="userimg"
                      className="object-cover w-12 h-12 rounded-full"
                    />
                    <div className="ml-4">
                      <span className="text-xl font-semibold text-[#3D405B]">
                        Hari KC
                      </span>
                      <p>HR Manager - Dept of Human</p>
                    </div>
                  </div>
                  <button className="  bg-[#FFA000] text-white w-32 px-8 py-2 rounded-md capitalize  text-sm font-medium ">Pending</button>
                </div>
              </div>
            </div>

          </div>


          <div className="bg-white p-6 space-y-6 border rounded-md ">
            <h1 className="text-xl font-medium text-[#3D405B]">
              Employee List
            </h1>
            <div className="overflow-x-auto border border-gray-200  ">
              <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse whitespace-nowrap">
                <thead className="bg-white">
                  <tr className>
                    <th
                      rowSpan={2}
                      className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r"
                    >
                      Employees
                    </th>
                    <th
                      rowSpan={2}
                      className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r text-center"
                    >
                      Rating
                    </th>
                    <th
                      rowSpan={2}
                      className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r text-center"
                    >
                      Salary Yearly
                    </th>
                    <th
                      rowSpan={2}
                      className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r text-center"
                    >
                      Purposed Promotion
                    </th>

                    <th
                      colSpan={2}
                      className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                    >
                      Allocated Hike
                    </th>
                    <th
                      colSpan={2}
                      className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                    >
                      Purposed Hike
                    </th>
                    <th
                      rowSpan={1}
                      colSpan={2}
                      className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                    >
                      Action
                    </th>
                  </tr>
                  <tr className="bg-[#f9fafb] border-t text-sm text-[#3D405B]">
                    {" "}
                    <th className="p-3 px-6 border-r  font-semibold text-center">%</th>
                    <th className="p-3 px-6  border-r  font-semibold text-center">
                      Amount
                    </th>
                    <th className="p-3 px-6 border-r  font-semibold text-center">%</th>
                    <th className="p-3 px-6  border-r  font-semibold text-center">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="border-t   divide-y  ">
                  <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                  <td className="px-4 py-3   border-r">
                    <div className="flex items-center space-x-2">
                       <div className="w-12">
                       <img
                          src="img/newimg/Rectangle 11116.png"
                          alt=""
                          className="rounded-full w-12 h-12  "
                        />
                       </div>
                        <div className="flex-1">
                          <p className="capitalize text-base font-medium block text-[#3D405B]">
                            Hari KC
                          </p>
                          <p className="capitalize text-sm  block text-[#3D405B] font-normal">
                            HR Manager - Dept of Human  
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                      Outstanding
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                      ₹ 20000
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                      Team Leader

                    </td>

                    <td className="px-6 py-3 text-center border-r">20.00%</td>
                    <td className="px-6 py-3 text-center border-r">
                      ₹ 5000
                    </td>
                    <td className="px-6 py-3 text-center border-r ">20.00%</td>
                    <td className="px-6 py-3 text-center ">
                      <input value="₹ 6000" className="focus:outline-none px-4 py-2 font-semibold border w-24 text-center rounded-md " />
                    </td>
                    <td className="px-6 py-3   border-l text-center">
                      {true ? <div className="text-gray-700  border rounded-md w-full p-2 px-4 relative flex justify-between items-center"><p>  Select option</p><svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer icon icon-tabler icon-tabler-caret-down-filled text-[#3D405B]/70" width={20} height={20} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" strokeWidth={0} fill="currentColor" /></svg><div className="absolute top-10 right-0 bg-white shadow-lg rounded-md w-full py-4  border h-auto overflow-y-auto z-10 text-base font-medium hidden"><label className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 "><span>1</span></label><label className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 "><span>2</span></label><label className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 "><span>3</span></label>
                      </div>
                      </div> :
                        <button className="  bg-[#FFA000] text-white w-32 px-8 py-2 rounded-md capitalize  text-sm font-medium ">
                          Pending
                        </button>}
                    </td>
                  </tr>
                  <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                  <td className="px-4 py-3   border-r">
                    <div className="flex items-center space-x-2">
                       <div className="w-12">
                       <img
                          src="img/newimg/Rectangle 11116.png"
                          alt=""
                          className="rounded-full w-12 h-12  "
                        />
                       </div>
                        <div className="flex-1">
                          <p className="capitalize text-base font-medium block text-[#3D405B]">
                            Hari KC
                          </p>
                          <p className="capitalize text-sm  block text-[#3D405B] font-normal">
                            HR Manager - Dept of Human  
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                      Outstanding
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                      ₹ 20000
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                      Sales Manager
                    </td>
                    <td className="px-6 py-3 text-center border-r">20.00%</td>
                    <td className="px-6 py-3 text-center border-r">
                      ₹ 5000
                    </td>
                    <td className="px-6 py-3 text-center border-r ">20.00%</td>
                    <td className="px-6 py-3 text-center ">
                      <input value="₹ 6000" className="focus:outline-none px-4 py-2 font-semibold border w-24 text-center rounded-md " />
                    </td>
                    <td className="px-6 py-3   border-l text-center">
                      {true ? <div className="text-gray-700  border rounded-md w-full p-2 px-4 relative flex justify-between items-center"><p>  Approved</p><svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer icon icon-tabler icon-tabler-caret-down-filled text-[#3D405B]/70" width={20} height={20} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" strokeWidth={0} fill="currentColor" /></svg><div className="absolute top-10 right-0 bg-white shadow-lg rounded-md w-full py-4  border h-auto overflow-y-auto z-10 text-base font-medium hidden"><label className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 "><span>1</span></label><label className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 "><span>2</span></label><label className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 "><span>3</span></label>
                      </div>
                      </div> :
                        <button className="  bg-[#4CAF50] text-white w-32 px-8 py-2 rounded-md capitalize  text-sm font-medium ">
                          Approved
                        </button>}

                    </td>
                  </tr>
                  <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                  <td className="px-4 py-3   border-r">
                    <div className="flex items-center space-x-2">
                       <div className="w-12">
                       <img
                          src="img/newimg/Rectangle 11116.png"
                          alt=""
                          className="rounded-full w-12 h-12  "
                        />
                       </div>
                        <div className="flex-1">
                          <p className="capitalize text-base font-medium block text-[#3D405B]">
                            Hari KC
                          </p>
                          <p className="capitalize text-sm  block text-[#3D405B] font-normal">
                            HR Manager - Dept of Human  
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                      Outstanding
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                      ₹ 20000
                    </td>
                    <td className="px-6 py-3   border-r text-center">
                      Not Define
                    </td>
                    <td className="px-6 py-3 text-center border-r">20.00%</td>
                    <td className="px-6 py-3 text-center border-r">
                      ₹ 5000
                    </td>
                    <td className="px-6 py-3 text-center border-r ">20.00%</td>
                    <td className="px-6 py-3 text-center ">
                      <input value="₹ 6000" className="focus:outline-none px-4 py-2 font-semibold border w-24 text-center rounded-md " />
                    </td>
                    <td className="px-6 py-3   border-l text-center">
                      {true ? <div className="text-gray-700  border rounded-md w-full p-2 px-4 relative flex justify-between items-center"><p>  Rejected</p><svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer icon icon-tabler icon-tabler-caret-down-filled text-[#3D405B]/70" width={20} height={20} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" strokeWidth={0} fill="currentColor" /></svg><div className="absolute top-10 right-0 bg-white shadow-lg rounded-md w-full py-4  border h-auto overflow-y-auto z-10 text-base font-medium hidden"><label className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 "><span>1</span></label><label className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 "><span>2</span></label><label className="items-center flex space-x-2 text-[#3D405B]/70 w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 "><span>3</span></label>
                      </div>
                      </div> :
                        <button className="  bg-[#F44336] text-white w-32 px-8 py-2 rounded-md capitalize  text-sm font-medium ">
                          rejected
                        </button>}

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex md:flex-row gap-6 flex-col items-center justify-between border-t p-6 -m-6">
              <p className="text-[#3D405B] md:text-base text-sm font-semibold">
                Showing 1 to 5 of 150 entries
              </p>
              <div className="flex border divide-x rounded-md ">
                <button className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                  Previous
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">
                  1
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                  2
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                  3
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                  4
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                  5
                </button>
                <button className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                  Next
                </button>
              </div>
            </div>
          </div>


          <div className="flex items-center justify-end  p-6 py-4 space-x-4 bg-white border-t fixed bottom-0 right-0   w-full  ">

            {true ?
              <div className="space-x-4 ">
                <button className="w-32 px-4 py-3 uppercase border rounded-md text-[#3D405B]/70 font-medium">Reset</button><button className="border rounded-md w-32 py-3 px-4 uppercase bg-[#2196F3] text-white font-medium ">Submit</button>
              </div>
              :
              <p className="text-base font-semibold text-[#3D405B]/70">Submitted on: 28-07-2023</p>

            }
          </div>

        </main>
      </div>
    </div>
  );
}
