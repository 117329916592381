import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../_helpers';
//api call
import { dashboardActions, questionsActions, surveyActions, userActions, generalActions, pmsActions } from '../../_actions';
//Layout
import Sidebar from './Sidebar/Sidebar';
//Components...Index..
import ReactEcharts from "echarts-for-react";
//libraries
import * as echarts from 'echarts';
import LoadingOverlay from 'react-loading-overlay';
import Header from '../CommonComponents/DashboardHeader/Header';
import { CONST } from '../../_config';
import RoundChart from './RoundChart';
import Filter from "../../pages/CommonComponents/Filters/IndexFilter/Filter";
import AppraisalCycle from './Components/AppraisalCycle/AppraisalCycle';
import Normalization from './Components/Normalization/Normalization';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexType: 0,
      isCardSelected: null,
      CurrentSurveyName: '',
      CurrentSurveyId: this.props.match.params.id,
      optionType: 0,
      showFilterDropdownName: false,
      showFilterDropdownValue: false,
      CurrentIndexName: '',
      filterSelectIndex: 0,
      loaderActive: false,
      filterOn: false,
      FilterList2: [],
      showFilter: true,
      showValue: "",
      loadReport: false,
      isFilter: false,

      showMobileSidebar: false,

      showOverview: true,
      showIndex: false,
      showManagerList: false, showNudgeReport: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showCustomScoreCard: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false,

      StackObject: [],
      StackObjectTotal: [],
      ReturnFinalFilterList: [],
      selectedFilterList: [],
      selectedFilterValue: 'none',
      HeatMapSelectedFilter: 'Department',

      DynaObj: [],

      Mindextype: '',

      customeScoreData: "",

      ManagerDynaObj: [],

      QuesDynaObj: {},

      QuesMngrHappinessReportObject: {},
      QuesMngrEngagementReportObject: {},
      QuesMngrStressReportObject: {},
      QuesMngrManagerReportObject: {},

      DriverMngrHappinessReportObject: {},
      DriverMngrEngagementReportObject: {},
      DriverMngrStressReportObject: {},
      DriverMngrManagerReportObject: {},

      MQuesIndextype: '',
      rwaIndexType: 1,
      ManagerListDasboardDataObj: [],
      ManagerListQuestionDataObj: [],

      showSidebar: true,
      currentQuestionId: '',
      currentQuestionTemplate: '',
      showAllQuestions: true,

      showReportDMGModal: false,

      ReportDMGindexType: "",
      ReportDMGcheck: "",
      ReportDMGfiltersListNew: "",
      ReportxAxis: "",
      isReportOverall: false,

      ManagerReportFConfig: {
        "managerIdType": CONST.MANAGER_ID_TYPE //Int or String
      },
      showNudgeReport: false,

      appraisalCycleId: this.props.match.params.id,
      activeTab: "AppraisalStatus",
      activeCard: 'selfAppraisalStatus',
      mainTab: "AppraisalCycle"
      , showhide: false
    }
  }

  componentDidMount() {

    this.props.dispatch(dashboardActions.getUserAccess({}));
    this.LoadOverviewInitFn()
    // console.log('this.props.match.params.id???', this.props.match.params.id);
    this.props.dispatch(dashboardActions.getClientProfile());
    this.props.dispatch(dashboardActions.getCompanyNameOnly());
    this.props.dispatch(dashboardActions.PMSAppraisalCycleList())

    let data = {
      appraisalCycleId: this.state.appraisalCycleId
      // appraisalCycleId: "64bfedc91584b578e2ed08b9"
    }

    this.props.dispatch(pmsActions.PMSGetDetailsByAppraisalCycleId(data))
    this.props.dispatch(pmsActions.getDashboardOverviewStatus(data))
    // let data1 = {
    //   appraisalCycleId: this.state.appraisalCycleId
    // }
    // this.props.dispatch(pmsActions.getEmployeeAppraisalStatus(data1))
    // let data2 = {
    //   appraisalCycleId: this.state.appraisalCycleId
    // }
    // this.props.dispatch(pmsActions.getPMSEmployeeWiseScore(data2))
    // let data2x = {
    //   appraisalCycleId: this.state.appraisalCycleId
    // }
    // this.props.dispatch(dashboardActions.getEmployeeRatingAndNormalization(data2x))

  }

  callOverviewApi = () => {

    this.setState({
      showOverview: true,
      showIndex: false,
      showManagerList: false, showNudgeReport: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: false,
      showCustomScoreCard: false,
      showPeopleToMeet: false
    })

    this.LoadOverviewInitFn()
  }

  LoadOverviewInitFn = () => {


    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }

    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": []
    }


    let data5 = {
      "surveyId": this.state.CurrentSurveyId
    }

    this.props.dispatch(dashboardActions.getCompanyNameOnly());
    this.props.dispatch(dashboardActions.getEmpFilter());
    this.props.dispatch(surveyActions.getIndexByFilterDMG());
    this.props.dispatch(dashboardActions.getClientProfile());




    // this.props.dispatch(dashboardActions.getReportType(data5));
    // this.props.dispatch(dashboardActions.getReportIndexYearCheck(data5));
    // this.props.dispatch(dashboardActions.getOverallIndex(data5));
    // this.props.dispatch(dashboardActions.getOverallScoreBySurveyId(data5));


    // this.props.dispatch(dashboardActions.getMetaRespondantDetails(data5));
    // this.props.dispatch(dashboardActions.getIndex(data5));
    // this.props.dispatch(surveyActions.getSurveyById(data5));
    // this.props.dispatch(dashboardActions.getIndexFilter(data2));
    // this.props.dispatch(dashboardActions.getAllBenchMark());
    // this.props.dispatch(dashboardActions.getComment(
    //   {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 60
    //   }
    // ));
    // this.props.dispatch(generalActions.getQuestionAvgScore({ "surveyId": this.state.CurrentSurveyId, qtemplateList: [1] }));
    // this.props.dispatch(generalActions.getQuestionOthersResult({
    //   "surveyId": this.state.CurrentSurveyId,
    //   "qtemplateList": [1, 2, 6, 11, 16]
    // }));


    // let data10 = {
    //   "keyWord": "",
    //   "pageNo": 1,
    //   "size": 1000
    // }

    // this.props.dispatch(surveyActions.getParameterList(data10));

  }

  handleLogout = () => {
    this.props.dispatch(userActions.logoutSession());
  }
  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { handleMultifilterIndexScore } = this.props;
    this.setState({ FilterValues: finalFilter, ListName: name });
    handleMultifilterIndexScore(finalFilter, true, name);
  };

  handleSidebar = (check) => {
    this.setState({ showSidebar: check })
  }

  handleMobileSidebar = (check) => {
    this.setState({ showMobileSidebar: check })
    this.setState({ showSidebar: check })
  }

  handleSummaryCard = (name) => {
    let { CurrentSurveyId } = this.state;

    let { indexType } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "xAxis": name
    }
    this.props.dispatch(dashboardActions.getDemographicIndex(data));

    if (indexType === 1) {
      this.props.dispatch(dashboardActions.getHappinessIndexV2Demographic(data));
    }
    else if (indexType === 2) {
      this.props.dispatch(dashboardActions.getEngagementIndexV2Demographic(data));
    }
    else if (indexType === 4) {
      this.props.dispatch(dashboardActions.getStressIndexV2Demographic(data));
    }
    else if (indexType === 5) {
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data));
    }
    else if (indexType === 6) {
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data));
    }
  }

  handleSummaryFilter = (name, value) => {
    let { CurrentSurveyId } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "filters": [
        { "level": name, "value": value }
      ]
    }
    this.props.dispatch(dashboardActions.getIndexByFilter(data));
  }

  handleQuestionFilter = (name, value) => {
    let { indexType, CurrentSurveyId } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "template": indexType,
      "filters": [
        { "level": name, "value": value }
      ]
    }
    // //////console.log('data: ',data)
    this.props.dispatch(dashboardActions.getQuestionFilter(data));
  }

  handleDistributionFilter = (name) => {
    let { indexType } = this.state;
    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": name
    }
    if (indexType === 1) {
      this.props.dispatch(dashboardActions.getDemographicIndexHappiness(data));
    }
    else if (indexType === 2) {
      this.props.dispatch(dashboardActions.getDemographicIndexEngagement(data));
    }
    else if (indexType === 4) {
      this.props.dispatch(dashboardActions.getDemographicIndexStress(data));
    }
    else if (indexType === 5) {
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data));
    }
    else if (indexType === 6) {
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data));
    }
    else if (indexType === 8) {
      this.props.dispatch(dashboardActions.getDemographicIndexOrg(data));
    }
  }

  onCustomScoreCard = (item) => {

    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false, showNudgeReport: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: false,
      showAllQuestions: false,
      showCustomScoreCard: true,
      currentQuestionTemplate: '',

      customeScoreData: item,
      showPeopleToMeet: false

    })
  }

  onAllQuestions = (item) => {


    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false, showNudgeReport: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: true,
      showAllQuestions: true,
      showCustomScoreCard: false,
      currentQuestionTemplate: item.templateId,
      showPeopleToMeet: false

    })


  }



  onOptionType = (NoData, indexType, optionType) => {

    // this.handleSidebar(false);
    this.setState({ showMobileSidebar: false })

    if (indexType === "nps6") {

      this.setState({
        showOverview: false,
        showIndex: false,
        showManagerList: false, showNudgeReport: false,
        showOrgCore: false,
        showAdvanceComparision: false,
        showTextAnalysis: false,
        showStrengthAndWeaknesses: false,
        showManagerActionPlan: false,
        showManagerGoalPlan: false,
        showReport: false,
        showOtherTypes: true,
        showAllQuestions: false,
        showCustomScoreCard: false,
        currentQuestionTemplate: optionType,
        showPeopleToMeet: false

      })


    }
    else {



      let { dashboard } = this.props;
      let { EmpFilterData } = dashboard;

      let filterNames = ''
      if (EmpFilterData && EmpFilterData.length > 0) {
        filterNames = EmpFilterData && EmpFilterData[0] && EmpFilterData[0].name ? EmpFilterData[0].name : "";
      }




      // this.setState({CurrentSurveyId:surveyId})

      let surveyId = this.state.CurrentSurveyId;

      let {
        getHappinessIndexDriverData,
        getEngagementIndexDriverData,
        getStressIndexDriverData,
        getManagerIndexDriverData,
        getLeaderIndexDriverData,
        getOrgIndexDriverData
      } = dashboard;

      this.setState({
        showOverview: false,
        showIndex: true,
        showManagerList: false, showNudgeReport: false,
        showOrgCore: false,
        showAdvanceComparision: false,
        showTextAnalysis: false,
        showStrengthAndWeaknesses: false,
        showManagerActionPlan: false,
        showManagerGoalPlan: false,
        showReport: false,
        showOtherTypes: false,
        showCustomScoreCard: false,
        showPeopleToMeet: false

      })

      this.setState({ indexType: indexType, optionType: optionType })

      function callIndexapi(indexType, props, data) {
        if (indexType === 1) {
          props.dispatch(dashboardActions.getHappinessIndexV2(data));
        }
        else if (indexType === 2) {
          props.dispatch(dashboardActions.getEngagementIndexV2(data));



          let data1 = {
            dmgLabel: "IndexScore",
            index: 2
          }
          props.dispatch(dashboardActions.getBenchmarkQuantile(data1));


        }
        else if (indexType === 4) {
          props.dispatch(dashboardActions.getStressIndexV2(data));
        }
        else if (indexType === 5) {
          // props.dispatch(dashboardActions.getManagerIndexV2(data));
        }
        else if (indexType === 6) {
          // props.dispatch(dashboardActions.getLeaderIndexV2(data));
        }
        else if (indexType === 8) {
          props.dispatch(dashboardActions.getOrgIndexV2(data));
        }
      }

      function callDemographicapi(indexType, props, data,
        getHappinessIndexDriverData,
        getEngagementIndexDriverData,
        getStressIndexDriverData,
        getManagerIndexDriverData,
        getLeaderIndexDriverData,
        getOrgIndexDriverData,

      ) {
        if (indexType === 1) {
          props.dispatch(dashboardActions.getDemographicIndexHappiness(data));
          props.dispatch(dashboardActions.getHappinessIndexDriver({ surveyId: data.surveyId }));

        }
        else if (indexType === 2) {
          props.dispatch(dashboardActions.getDemographicIndexEngagement(data));
          props.dispatch(dashboardActions.getEngagementIndexDriver({ surveyId: data.surveyId }));

        }
        else if (indexType === 4) {
          props.dispatch(dashboardActions.getDemographicIndexStress(data));
          props.dispatch(dashboardActions.getStressIndexDriver({ surveyId: data.surveyId }));

        }
        else if (indexType === 5) {
          props.dispatch(dashboardActions.getDemographicIndexManager(data));
          props.dispatch(dashboardActions.getManagerIndexDriver({ surveyId: data.surveyId }));

        }
        else if (indexType === 6) {
          props.dispatch(dashboardActions.getDemographicIndexLeader(data));
          props.dispatch(dashboardActions.getLeaderIndexDriver({ surveyId: data.surveyId }));

        }
        else if (indexType === 8) {
          props.dispatch(dashboardActions.getDemographicIndexOrg(data));
          props.dispatch(dashboardActions.getOrgIndexDriver({ surveyId: data.surveyId }));

        }
      }


      if (indexType) {
        if (indexType === 102) { //MANAGER INSIGHT
          this.onManagerList(surveyId);

        }
        else if (indexType === 103) { // ADVANCE INSIGHT
          this.onAdvanceComparision(surveyId, filterNames);
        }
        else if (indexType === 104) { // TEXT ANALYSIS
          this.onTextAnalysis(surveyId);
        }
        else if (indexType === 105) { // STRENGHT & WEAKNESS
          this.onStrengthAndWeaknessess(surveyId);
        }
        else if (indexType === 106) { // MANAGER ACTION PLAN
          // this.managerActionPlan(surveyId);
          this.onManagerActionPlanSelect(surveyId);

        }
        else if (indexType === 107) { // REPORT
          // this.managerActionPlan(surveyId);
          this.onReport(surveyId);

        }
        else if (indexType === 109) { // REPORT
          // this.managerActionPlan(surveyId);
          this.onNudge(surveyId);

        }
        else if (indexType === 108) { // REPORT
          // this.managerActionPlan(surveyId);
          this.onManagerGoalPlan(surveyId);

        }
        else if (indexType === 121) { // REPORT
          // this.managerActionPlan(categoryId);
          this.onPeopleToMeetTab();

        }
        else {

          if (optionType === 1) {
            let data1 = {
              "surveyId": surveyId
            }
            let data3 = {
              "surveyId": surveyId,
              "index": indexType

            }
            // this.props.dispatch(dashboardActions.cronbatchAlphaVariance(data3));
            // this.props.dispatch(dashboardActions.getRespondantDetails(data1));
            // this.props.dispatch(dashboardActions.getIndex(data1));

            if (indexType === 2) {
              let data6 = {
                "keyWord": "",
                "pageNo": 1,
                "size": 10
              }
              this.props.dispatch(dashboardActions.getHistoryIndex(data6));

            }

            callIndexapi(indexType, this.props, data1);







          }
          else if (optionType === 2) {
            let data3 = {
              "surveyId": surveyId,
              "xAxis": filterNames
            }
            callDemographicapi(indexType, this.props, data3,
              getHappinessIndexDriverData,
              getEngagementIndexDriverData,
              getStressIndexDriverData,
              getManagerIndexDriverData,
              getLeaderIndexDriverData,
              getOrgIndexDriverData,)
          }
          else if (optionType === 3) {
            let data2 = {
              "surveyId": surveyId,
              "template": indexType
            }
            this.props.dispatch(dashboardActions.getQuestion(data2));
          }
          else if (optionType === 4) {
            let data2 = {
              "surveyId": surveyId,
              "template": indexType
            }
            // this.props.dispatch(dashboardActions.getQuestion(data2));
            this.props.dispatch(dashboardActions.getQuestionDistribution(data2));

          }
          else if (optionType === 5) {
            let data2 = {
              "surveyId": surveyId,
              "index": indexType
            }
            this.props.dispatch(dashboardActions.getIndexQuantile(data2));
          }


          else if (optionType === 6) {
            let data2 = {
              "surveyId": surveyId,
              "template": indexType
            }
            this.props.dispatch(dashboardActions.getQuestionMap(data2));
            this.props.dispatch(dashboardActions.getQuestion(data2));

          }

          else if (optionType === 7) {
            let data11 = {
              "surveyId": surveyId,
              "otherType": 6
            }
            let data2222 = {
              "surveyId": surveyId,
              "npsRange": ["9", "10"],
              "template": indexType,
              "filters": []
            }
            this.props.dispatch(dashboardActions.getNameQuestion(data11, 1, data2222));
            // this.props.dispatch(dashboardActions.getIndexVsNps(data2222));

          }
          else if (optionType === 8) {
            let data11 = {
              "surveyId": surveyId,
              "otherType": 16
            }
            let data2222 = {
              "surveyId": surveyId,
              "npsRange": ["8", "9", "10"],
              "template": indexType,
              "filters": []
            }
            this.props.dispatch(dashboardActions.getNameQuestion(data11, 1, data2222));
            // this.props.dispatch(dashboardActions.getIndexVsNps(data2222));

          }


        }


      }
      else {

        this.setState({
          showOverview: true,
          showIndex: false,
          showManagerList: false, showNudgeReport: false,
          showOrgCore: false,
          showAdvanceComparision: false,
          showTextAnalysis: false,
          showStrengthAndWeaknesses: false,
          showManagerActionPlan: false,
          showManagerGoalPlan: false,
          showReport: false,
          showOtherTypes: false,
          showCustomScoreCard: false,
          showPeopleToMeet: false
        })


        this.LoadOverviewInitFn()



      }




    }



  }

  managerActionPlan = () => {

    this.props.dispatch(dashboardActions.getAllManagerActionPlan({ surveyId: this.state.CurrentSurveyId }));
    this.props.dispatch(dashboardActions.getDemographicIndexForManager({ surveyId: this.state.CurrentSurveyId }))

    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false, showNudgeReport: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: true,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,


      SelectedAPDimension: '',
      SelectedAPAction: '',
      SelectedAPTask: '',
      showPeopleToMeet: false
    });
  }

  onStrengthAndWeaknessess = (surveyId) => {
    let data1 = {
      "surveyId": this.state.CurrentSurveyId
    }
    // this.props.dispatch(dashboardActions.getOrgIndexV2(data1));

    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false, showNudgeReport: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: true,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false
    })


    let data2 =
    {
      "surveyId": this.state.CurrentSurveyId,
      // "index": 5,
      "limit": 10,
      "top": true,
      "filters": []
    }

    this.props.dispatch(dashboardActions.getQuestionFavorability(data2));

  }

  handleStrengthQuestionFilter = (FilterName, FilterValue) => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId,
      // "index": 5,
      "limit": 10,
      "top": true,
      "filters": [
        { "level": FilterName, "value": FilterValue }
      ]
    }

    this.props.dispatch(dashboardActions.getQuestionFavorability(data2));
  }

  handleWeaknessesQuestionFilter = (FilterName, FilterValue) => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId,
      // "index": 5,
      "limit": 10,
      "top": false,
      "filters": [
        { "level": FilterName, "value": FilterValue }
      ]
    }

    this.props.dispatch(dashboardActions.getQuestionFavorability(data2));
  }

  onWeaknessQuestionCall = () => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId,
      // "index": 5,
      "limit": 10,
      "top": false,
      "filters": []
    }

    this.props.dispatch(dashboardActions.getQuestionFavorability(data2));
  }

  onStrengthQuestionCall = () => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId,
      // "index": 5,
      "limit": 10,
      "top": true,
      "filters": []
    }

    this.props.dispatch(dashboardActions.getQuestionFavorability(data2));
  }

  callQuestionWordCloud = (QuesId, type, wordList) => {
    let { CurrentSurveyId } = this.state;

    if (type === "topicModelling") {
      let data2 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "wordList": wordList,
        "filters": []
      }
      this.props.dispatch(dashboardActions.topicModelling(data2));
    } else {
      let data2 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "filters": []
      }
      this.props.dispatch(dashboardActions.wordCloud(data2));
    }

  }

  callQuestionNetworkCloud = (QuesId, graphType, FilterList) => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": FilterList && FilterList.length > 0 ? FilterList : []
    }

    this.props.dispatch(dashboardActions.neighborGraph(data2));

  }

  onTextAnalysis = (surveyId) => {
    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false, showNudgeReport: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: true,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false


    });


    let data2 = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 1));

  }

  handleAnswerFeedbackDemographicFilter = (QuesId, FilterValues, blank, type, wordList) => {

    let { CurrentSurveyId } = this.state;
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters

    }

    this.props.dispatch(dashboardActions.getOrgCoreDriverSentiment(data2));

  }

  getKeywordsByCluster = (QuesId, FilterValues, clusterNo) => {

    let { CurrentSurveyId } = this.state;
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters,
      "cluster": clusterNo

    }

    this.props.dispatch(dashboardActions.getClusterKeywords(data2));

  }

  handleWordCloudDemographicFilter = (QuesId, FilterValues, blank, type, wordList) => {

    let { CurrentSurveyId } = this.state;
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters

    }

    console.log("------------------------------type====", type)

    if (type === "advance") {
      this.props.dispatch(dashboardActions.AdvanceWordCloud(data2));
    }
    else if (type === "wordCloud") {
      this.props.dispatch(dashboardActions.wordCloud(data2));
    }
    else if (type === "topicModelling") {

      let data2x = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "wordList": wordList,
        // "wordwithKeywordList":wordList,
        "filters": Filters

      }
      ////////console.log("topic input ------------->",data2x)

      this.props.dispatch(dashboardActions.topicModelling(data2x));
    }
    else if (type === "topicModellingCustom") {

      let data2x = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "wordList": wordList,
        // "wordwithKeywordList":wordList,
        "filters": Filters

      }
      ////////console.log("topic input ------------->",data2x)

      this.props.dispatch(dashboardActions.topicModelling(data2x));
    }
    else {
      let data3 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "xAxis": FilterValues && FilterValues.length > 0 ? FilterValues[0].level : "Department"
      }
      if (type === "textDistTreeMap") {
        this.props.dispatch(dashboardActions.textDistTreeMap(data3));

      }
      else if (type === "textDist") {
        this.props.dispatch(dashboardActions.textDist(data2));

      }
      else {
        let data32 = {
          "surveyId": CurrentSurveyId,
          "questionId": QuesId,
          "xAxis": FilterValues && FilterValues.length > 0 ? FilterValues[0].level : "Department",
          "filters": Filters

        }
        this.props.dispatch(dashboardActions.TreeMap(data32));
      }

    }



  }

  callAnswerFeedbackText = (QuesId, keyWord, FilterList, FilterValue, isMultiKeyword, type) => {

    let { CurrentSurveyId } = this.state;
    let XAxis = "";
    let Filters = [];
    if (FilterList && FilterList.length > 0) {
      let getIndex = FilterList.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterList[getIndex].level;
        FilterList.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterList[FilterList.length - 1].level;
        Filters = FilterList
      }

    }

    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "keyWord": keyWord,
      "filters": Filters && Filters.length > 0 ? Filters : []
    }
    this.props.dispatch(dashboardActions.AnswerFeedbackText(data2));

  }

  callWordCloudText = (QuesId, keyWord, FilterList, FilterValue, isMultiKeyword, type) => {
    let { CurrentSurveyId } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterList && FilterList.length > 0) {
      let getIndex = FilterList.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterList[getIndex].level;
        FilterList.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterList[FilterList.length - 1].level;
        Filters = FilterList
      }

    }


    if (type === "manyKeywords") {
      let data2 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "keywordList": keyWord && keyWord.keywordList && keyWord.keywordList.length > 0 ? keyWord.keywordList : [],
        "filters": Filters && Filters.length > 0 ? Filters : []
      }

      this.props.dispatch(dashboardActions.getAllWordKeywordsText(data2, keyWord.topic));
    }
    else {
      if (isMultiKeyword) {
        let data2 = {
          "surveyId": CurrentSurveyId,
          "questionId": QuesId,
          "keyWord1": keyWord && keyWord[0] ? keyWord[0] : "",
          "keyWord2": keyWord && keyWord[1] ? keyWord[1] : "",
          "filters": Filters && Filters.length > 0 ? Filters : []
        }
        this.props.dispatch(dashboardActions.wordCloudMultiText(data2));
      }
      else {
        let data2 = {
          "surveyId": CurrentSurveyId,
          "questionId": QuesId,
          "keyWord": keyWord,
          "filters": Filters && Filters.length > 0 ? Filters : []
        }
        this.props.dispatch(dashboardActions.wordCloudText(data2));
      }
    }





    if (type === "TreeMap") {





      let data7 = {
        "surveyId": CurrentSurveyId,
        "xAxis": XAxis ? XAxis : "Country",
        "filters": Filters && Filters.length > 0 ? Filters : []
      }



      let { dashboard } = this.props;
      let { getIndexData } = dashboard;
      let IndexValidList = getIndexData ? Object.keys(getIndexData) : [];
      if (IndexValidList && IndexValidList.length > 0) {
        IndexValidList.forEach((item) => {
          if (item && getIndexData && getIndexData[item]) {
            ////////console.log("repoirt --->item: ",item)

            if (parseInt(item) === 1) {
              this.props.dispatch(dashboardActions.getDemographicIndexHappiness(data7));

            }
            else if (parseInt(item) === 2) {
              this.props.dispatch(dashboardActions.getDemographicIndexEngagement(data7));

            }
            else if (parseInt(item) === 4) {
              this.props.dispatch(dashboardActions.getDemographicIndexStress(data7));

            }
            else if (parseInt(item) === 5) {
              this.props.dispatch(dashboardActions.getDemographicIndexManager(data7));

            }
            else if (parseInt(item) === 6) {
              this.props.dispatch(dashboardActions.getDemographicIndexLeader(data7));

            }


          }

        });
      }

      this.props.dispatch(dashboardActions.getDemographicIndexMultiFilter(data7));






    }




  }

  handleQuantileFilter = (Name, Value) => {
    let { CurrentSurveyId, indexType } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId,
      "index": indexType,
      "filters": [
        { "level": Name, "value": Value }
      ]
    }
    this.props.dispatch(dashboardActions.getIndexQuantile(data2));
  }

  onFilterDropdownName = (data) => {
    if (this.state.showFilterDropdownName === data) {
      this.setState({ showFilterDropdownName: '' })
    }
    else {
      this.setState({ showFilterDropdownName: data })
    }
  }

  onFilterDropdownValue = () => {
    if (this.state.showFilterDropdownValue) {
      this.setState({ showFilterDropdownValue: false })
    }
    else {
      this.setState({ showFilterDropdownValue: true })
    }
  }

  onAdvanceComparision = (surveyId, Demographic) => {
    let { dashboard } = this.props;

    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false, showNudgeReport: false,
      showOrgCore: false,
      showAdvanceComparision: true,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false

    })

    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": Demographic
    }
    this.props.dispatch(dashboardActions.getDemographicIndex(data2));

    this.props.dispatch(dashboardActions.getDemographicIndexScatter(data2));


  }

  onManagerList = (surveyId) => {
    let { DynaObj } = this.state;
    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;
    // this.getAllManagerScores();

    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: true,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false
    })

    let data = {
      "surveyId": this.state.CurrentSurveyId,
    }

    // this.props.dispatch(dashboardActions.getManagerList(data))

    this.props.dispatch(dashboardActions.getDemographicIndexForManager(data, 5, DynaObj, EmpFilterData, surveyId))

    // this.setState({CurrentSurveyId:surveyId})

    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": "ManagerPID"
    }
    // this.props.dispatch(dashboardActions.getDemographicIndex(data2));

  }

  onManagerActionPlanSelect = (surveyId) => {
    let { DynaObj } = this.state;
    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;

    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false, showNudgeReport: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: true,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showOtherTypes: false,
      showPeopleToMeet: false

    })

    let data = {
      "surveyId": this.state.CurrentSurveyId,
    }

    // this.props.dispatch(dashboardActions.getManagerList(data))

    this.props.dispatch(dashboardActions.getAllManagerActionPlan(data));

    this.props.dispatch(dashboardActions.getDemographicIndexForManager(data, 5, DynaObj, EmpFilterData, surveyId))

    // this.setState({CurrentSurveyId:surveyId})

    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": "ManagerPID"
    }
    // this.props.dispatch(dashboardActions.getDemographicIndex(data2));

  }

  handleManagerHeatmap = (filterdata) => {

    let { CurrentSurveyId, DynaObj } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "filters": [
        { "level": filterdata.level, "value": filterdata.value }
      ]
    }
    this.setState({ DynaObj })
    // this.props.dispatch(dashboardActions.getIndexFilter(data,5,DynaObj));
    this.props.dispatch(dashboardActions.getManagerListDomainFilter(data, 5, DynaObj));

  }

  handleManagerListIndexQuestionCall = (option, mngr) => {
    let { CurrentSurveyId, ManagerListQuestionDataObj } = this.state;
    this.setState({ Mindextype: option });
    let data2 = {
      "surveyId": CurrentSurveyId,
      "template": option,
      "filters": [
        {
          "level": "ManagerPID",
          "value": mngr.id
        }
      ]
    }
    this.props.dispatch(dashboardActions.getQuestionOverall({
      "surveyId": CurrentSurveyId,
      "template": option,
      "filters": []
    }));
    this.props.dispatch(dashboardActions.getMultiManagerQuestionList(data2, mngr, ManagerListQuestionDataObj));
  }

  handleManagerQuestion = (data, isReport) => {
    let { CurrentSurveyId, QuesDynaObj,
      QuesMngrHappinessReportObject,
      QuesMngrEngagementReportObject,
      QuesMngrStressReportObject,
      QuesMngrManagerReportObject
    } = this.state;

    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;


    if (EmpFilterData && EmpFilterData.length > 0) {

      QuesDynaObj["ManagerPID"] = []
      QuesMngrHappinessReportObject["ManagerPID"] = []
      QuesMngrEngagementReportObject["ManagerPID"] = []
      QuesMngrStressReportObject["ManagerPID"] = []
      QuesMngrManagerReportObject["ManagerPID"] = []

      EmpFilterData.forEach((emp) => {
        if (emp && emp.name) {
          QuesDynaObj[emp.name] = []
          QuesMngrHappinessReportObject[emp.name] = []
          QuesMngrEngagementReportObject[emp.name] = []
          QuesMngrStressReportObject[emp.name] = []
          QuesMngrManagerReportObject[emp.name] = []
        }

      });
    }

    this.setState({ MQuesIndextype: data.template })

    let data2 = {
      "surveyId": CurrentSurveyId,
      "template": data.template,
      "filters": data.filters
    }

    if (!isReport) {
      this.props.dispatch(dashboardActions.getQuestion(data2));
      this.props.dispatch(dashboardActions.getQuestionOverall({
        "surveyId": CurrentSurveyId,
        "template": data.template,
        "filters": []
      }));
    }

    for (let i = 0; i < 2; i++) {


      if (data.template === 1) {
        this.props.dispatch(dashboardActions.getQuestionManagerOfHappiness(data2, 5, QuesDynaObj, isReport, QuesMngrHappinessReportObject));
      }
      if (data.template === 2) {
        this.props.dispatch(dashboardActions.getQuestionManagerOfEngagement(data2, 5, QuesDynaObj, isReport, QuesMngrEngagementReportObject));
      }
      if (data.template === 4) {
        this.props.dispatch(dashboardActions.getQuestionManagerOfStress(data2, 5, QuesDynaObj, isReport, QuesMngrStressReportObject));
      }
      if (data.template === 5) {
        this.props.dispatch(dashboardActions.getQuestionManagerOfManager(data2, 5, QuesDynaObj, isReport, QuesMngrManagerReportObject));
      }

    }

  }

  handleManagerWiseDriver = (Mindextype, ManagerPID, filterName, filters, isReport) => {
    let { CurrentSurveyId, ManagerDynaObj,

      DriverMngrHappinessReportObject,
      DriverMngrEngagementReportObject,
      DriverMngrStressReportObject,
      DriverMngrManagerReportObject

    } = this.state;

    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;


    if (EmpFilterData && EmpFilterData.length > 0) {
      ////console.log('-------------------------------> EmpFilterData',EmpFilterData)
      ManagerDynaObj["ManagerPID"] = []
      DriverMngrHappinessReportObject["ManagerPID"] = []
      DriverMngrEngagementReportObject["ManagerPID"] = []
      DriverMngrStressReportObject["ManagerPID"] = []
      DriverMngrManagerReportObject["ManagerPID"] = []

      EmpFilterData.forEach((emp) => {
        if (emp && emp.name) {
          ManagerDynaObj[emp.name] = []
          DriverMngrHappinessReportObject[emp.name] = []
          DriverMngrEngagementReportObject[emp.name] = []
          DriverMngrStressReportObject[emp.name] = []
          DriverMngrManagerReportObject[emp.name] = []
        }

      });
    }



    this.setState({ Mindextype: Mindextype })


    let tempdata = {
      "surveyId": CurrentSurveyId,
      "xAxis": filterName
    }
    if (Mindextype === 1) {
      this.IndexDriverScore(Mindextype)
      this.props.dispatch(dashboardActions.getDemographicIndexHappiness(tempdata, 5, ManagerDynaObj, isReport, DriverMngrHappinessReportObject, filters));
    }
    if (Mindextype === 2) {
      this.IndexDriverScore(Mindextype)
      this.props.dispatch(dashboardActions.getDemographicIndexEngagement(tempdata, 5, ManagerDynaObj, isReport, DriverMngrEngagementReportObject, filters));
    }
    if (Mindextype === 4) {
      this.IndexDriverScore(Mindextype)
      this.props.dispatch(dashboardActions.getDemographicIndexStress(tempdata, 5, ManagerDynaObj, isReport, DriverMngrStressReportObject, filters));
    }
    if (Mindextype === 5) {
      this.IndexDriverScore(Mindextype)
      this.props.dispatch(dashboardActions.getDemographicIndexManager(tempdata, 5, ManagerDynaObj, isReport, DriverMngrManagerReportObject, filters));
    }
    if (Mindextype === 6) {
      // this.IndexDriverScore(Mindextype)
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(tempdata, 5, ManagerDynaObj));
    }
  }

  handleMultiManagerWiseDriver = (OptionM, mngr) => {
    this.setState({ Mindextype: OptionM })

    let { CurrentSurveyId, ManagerListDriverObj } = this.state;
    let tempdata = {
      "surveyId": CurrentSurveyId,
      "xAxis": "ManagerPID"
    }

    if (OptionM === 1) {
      this.props.dispatch(dashboardActions.getDemographicIndexHappiness(tempdata, 0, ManagerListDriverObj));
      // //////console.log('--------------------------------------------------------------------------------------------------------->----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------','>>>');
    }
    else if (OptionM === 2) {
      this.props.dispatch(dashboardActions.getDemographicIndexEngagement(tempdata, 0, ManagerListDriverObj));
    }
    else if (OptionM === 4) {
      this.props.dispatch(dashboardActions.getDemographicIndexStress(tempdata, 0, ManagerListDriverObj));
    }
    else if (OptionM === 5) {
      this.props.dispatch(dashboardActions.getDemographicIndexManager(tempdata, 0, ManagerListDriverObj));
    }
    else if (OptionM === 6) {
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(tempdata, 0, ManagerListDriverObj));
    }
  }

  handleQuadrantFilter = (val) => {
    let { CurrentSurveyId } = this.state;
    this.onAdvanceComparision(CurrentSurveyId, val);
  }

  handleCorelation = (validParameterList) => {
    let { CurrentSurveyId } = this.state;
    let { dashboard, survey } = this.props;
    let { EmpFilterData } = dashboard;
    let { getSurveyData } = survey;

    let filterNames = ''
    if (EmpFilterData && EmpFilterData.length > 0) {
      filterNames = EmpFilterData && EmpFilterData[0] && EmpFilterData[0].name ? EmpFilterData[0].name : "";
    }




    let tempdata2 = {
      "surveyId": CurrentSurveyId,
      "filters": [],
      "targetDimension": '',
      "corrDimenionList": []
    }



    //Organization parameter names

    let ParameterList = [];

    if (validParameterList && validParameterList.length > 0) {
      ParameterList = validParameterList;
    }

    if (getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length > 0) {
      getSurveyData.orgList.forEach((org) => {
        if (org && org.name) {
          ParameterList.push(org.name)
        }
      });
    }

    if (ParameterList && ParameterList.length > 1) {
      let count = 1
      ParameterList.forEach((para, index) => {
        if (index === 0) {
          tempdata2["targetDimension"] = para
        }
        else {
          if (ParameterList.length > 2) {
            if (count < 5) {
              tempdata2["corrDimenionList"].push(para);
              count++
            }
          }
          else {
            tempdata2["corrDimenionList"].push(para);
            count++
          }
        }




      });

      ////console.log('-----c-c-c-c-c-c-ParameterList',ParameterList)

      this.props.dispatch(dashboardActions.getCorrelationDimenionVsDimension(tempdata2));

    }





    // if(val === 1){
    //   this.props.dispatch(dashboardActions.getHappinessCorrelationOverAll(tempdata2));
    // }
    // else if(val === 2){
    //   let tempdataV2 ={
    //     "surveyId": CurrentSurveyId,
    //     "filters":[],
    //     "targetDimension":'Respect',
    //     "corrDimenionList":['Pride','Presence','Performance']
    //   }
    //   this.props.dispatch(dashboardActions.getEngagementCorrelationOverAll(tempdataV2));

    // }
    // else if(val === 4){
    //   this.props.dispatch(dashboardActions.getStressCorrelationOverAll(tempdata2));
    // }
    // else if(val === 5){


    //   let tempdataV2 ={
    //     "surveyId": CurrentSurveyId,
    //     "filters":[],
    //     "targetDimension":'Respect',
    //     "corrDimenionList":['Pride','Presence','Performance']
    //   }
    //   this.props.dispatch(dashboardActions.getEngagementCorrelationOverAll(tempdataV2));
    // }
    // else if(val === 6){
    //   this.props.dispatch(dashboardActions.getLeaderCorrelationOverAll(tempdata2));
    // }




  }

  handleCorrelationFilter = (val, demographic) => {
    // ////console.log('handleCorrelationFilter: ',val, demographic)
    let { CurrentSurveyId } = this.state;
    let tempdata = {
      "surveyId": CurrentSurveyId,
      // "xAxis": demographic,
      "filters": []
    }




    let tempdata2 = {
      "surveyId": CurrentSurveyId,
      "filters": [],
      "targetDimension": 'Respect',
      "corrDimenionList": ['Pride', 'Presence', 'Performance', 'Connect', 'ACT']
    }



    this.props.dispatch(dashboardActions.getCorrelationDimenionVsDimension(tempdata2));



    // if(val === 1){
    //   this.props.dispatch(dashboardActions.getHappinessCorrelationOverAll(tempdata));
    // }
    // else if(val === 2){
    //   this.props.dispatch(dashboardActions.getEngagementCorrelationOverAll(tempdata));
    // }
    // else if(val === 4){
    //   this.props.dispatch(dashboardActions.getStressCorrelationOverAll(tempdata));
    // }
    // else if(val === 5){
    //   this.props.dispatch(dashboardActions.getManagerCorrelationOverAll(tempdata));
    // }
    // else if(val === 6){
    //   this.props.dispatch(dashboardActions.getLeaderCorrelationOverAll(tempdata));
    // }



  }

  onRWAnalysis = (Filter, InnerIndexType, isOnlyRWA) => {
    let { CurrentSurveyId } = this.state;

    let { getIndexData } = this.props.dashboard;
    // ////console.log('getIndexData Input----->: ',getIndexData)

    let data2 = {
      "surveyId": CurrentSurveyId,
      "index": InnerIndexType,
      "filters": Filter,
      "addValueTemplate": []
    }

    if (getIndexData && getIndexData["5"]) {
      data2["addValueTemplate"].push(5)
    }

    if (getIndexData && getIndexData["6"]) {
      data2["addValueTemplate"].push(6)
    }


    this.setState({ rwaIndexType: InnerIndexType })

    let data3 = {
      "surveyId": CurrentSurveyId
    }

    if (isOnlyRWA) {
      this.props.dispatch(dashboardActions.getIndexFilter(data3))
      this.props.dispatch(surveyActions.getSurveyById(data3))
      this.props.dispatch(dashboardActions.getEngagementRWA(data2));
    } else {
      this.props.dispatch(dashboardActions.getEngagementRWA(data2));
    }


    // data2["questionId"] = "61b18a5c2392b3d406b32967"
    // this.props.dispatch(dashboardActions.driverVsNpsRWA(data2));

  }

  onRwaNpsInsight = (Filter, isLadder) => {
    let { CurrentSurveyId } = this.state;

    let { getIndexData } = this.props.dashboard;
    // ////console.log('getIndexData Input----->: ',getIndexData)

    let data2 = {
      "surveyId": CurrentSurveyId,
      "filters": Filter,
      "index": 8,
      "addValueTemplate": []
    }

    if (getIndexData && getIndexData["5"]) {
      data2["addValueTemplate"].push(5)
    }

    if (getIndexData && getIndexData["6"]) {
      data2["addValueTemplate"].push(6)
    }


    let data11 = {
      "surveyId": CurrentSurveyId,
      "otherType": isLadder ? 16 : 6
    }
    // this.props.dispatch(dashboardActions.driverVsNpsRWA(data2));
    this.props.dispatch(dashboardActions.getNameQuestion(data11, 2, data2));

  }

  handleRwaNpsInsight = (initQueId, Filter) => {
    let { CurrentSurveyId } = this.state;

    let { getIndexData } = this.props.dashboard;
    // ////console.log('getIndexData Input----->: ',getIndexData)

    let data2 = {
      "surveyId": CurrentSurveyId,
      "filters": Filter,
      "index": 8,
      "addValueTemplate": []
    }

    if (getIndexData && getIndexData["5"]) {
      data2["addValueTemplate"].push(5)
    }

    if (getIndexData && getIndexData["6"]) {
      data2["addValueTemplate"].push(6)
    }

    data2["questionId"] = initQueId;
    this.props.dispatch(dashboardActions.driverVsNpsRWA(data2));

  }

  handleRWAFilter = (Filter, innerIndex, name, value) => {
    let { CurrentSurveyId } = this.state;

    let data2 = {}
    if (name === 'none') {
      data2 = {
        "surveyId": CurrentSurveyId,
        "index": innerIndex,
        "filters": []
      }
    }
    else {
      data2 = {
        "surveyId": CurrentSurveyId,
        "index": innerIndex,
        "filters": [
          { "level": name, "value": value }
        ]
      }
    }


    this.setState({ rwaIndexType: innerIndex })

    // ////console.log('onRWAnalysis Input----->: ',data2)


    this.props.dispatch(dashboardActions.getEngagementRWA(data2));

  }

  onWorldCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }

    this.props.dispatch(dashboardActions.getTextQuestion(data2, 1));
  }


  onFeedbackCloud = () => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getFeedbackTextQuestion(data2));
  }

  onTopicModel = () => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId
    }
    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.getTopicList(data1));
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 7));
  }

  onNetworkGraph = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 2));

  }


  onNeightboardGraph = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 3));

  }


  onAdvanceWorldCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 4));

  }


  onTextWordDistribution = (EMPFieldFirstName) => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    console.log("EMPFieldFirstName---------->", EMPFieldFirstName)
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 5, [], EMPFieldFirstName));

  }


  handleNeighborGraph = (FilterList) => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 3, FilterList));

  }



  // handleNetworkDemographicFilter=(Qid, Name, Value, graphType)=>{
  //   let { CurrentSurveyId } = this.state;
  //   let data2 = {};
  //   if(Name==='none'){
  //     data2 ={
  //       "surveyId": CurrentSurveyId,
  //       "questionId": Qid,
  //       "filters": []
  //     }
  //   }
  //   else{
  //     data2 ={
  //       "surveyId": CurrentSurveyId,
  //       "questionId": Qid,
  //       "filters": [
  //         {"level": Name,"value":Value}
  //       ]
  //     }
  //   }

  //   ////console.log('----asadata2',data2)

  //   if(graphType===1){
  //     this.props.dispatch(dashboardActions.cooccurmatrix(data2));
  //   }
  //   else if(graphType===2){
  //     this.props.dispatch(dashboardActions.neighborGraph(data2));
  //   }

  // }
  handleNetworkDemographicFilter = (Qid, FilterValues, graphType) => {

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }


    let { CurrentSurveyId } = this.state;
    let data2 = {};
    data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": Qid,
      "filters": Filters
    }


    // ////console.log('----asadata2',data2)

    if (graphType === 1) {
      this.props.dispatch(dashboardActions.cooccurmatrix(data2));
    }
    else if (graphType === 2) {
      this.props.dispatch(dashboardActions.neighborGraph(data2));
    }

  }


  onOrgQuestion = (empty, type) => {
    let { CurrentSurveyId, indexType } = this.state;
    let data2 = {
      surveyId: CurrentSurveyId,
      template: indexType,
    };
    this.props.dispatch(dashboardActions.getQuestion(data2));

  };

  onOrgQuestionDist = (empty, type) => {
    let { CurrentSurveyId, indexType } = this.state;
    let data2 = {
      surveyId: CurrentSurveyId,
      template: indexType,
    };
    this.props.dispatch(dashboardActions.getQuestionDistribution(data2));

  };
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };


  onOverviewFromHeader = () => {
    this.props.history.goBack()
  }



  ChooseSurvey = (val) => {


    // this.setState({CurrentSurveyId:val})

    let data2 = {
      "surveyId": this.state.CurrentSurveyId
    }

    this.props.dispatch(dashboardActions.getRespondantDetails(data2));
    this.props.dispatch(dashboardActions.getIndex(data2));



  }


  handleMultifilterIndexScore = (FilterValues, isAllTwice, name) => {

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": (isAllTwice) ? name : XAxis,
      "filters": Filters
    }

    ////console.log('data7:::',data7);

    this.props.dispatch(dashboardActions.getDemographicIndexMultiFilter(data7));

    if (this.state.indexType === 1) {
      this.props.dispatch(dashboardActions.getHappinessIndexV2Demographic(data7));
    }
    else if (this.state.indexType === 2) {
      this.props.dispatch(dashboardActions.getEngagementIndexV2Demographic(data7));
    }
    else if (this.state.indexType === 4) {
      this.props.dispatch(dashboardActions.getStressIndexV2Demographic(data7));
    }

  }


  handleMultifilterBehaviour = (FilterValues, isFilterTwice, name) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": isFilterTwice ? name : XAxis,
      "filters": Filters
    }

    // ////console.log('data7:::',data7);

    if (this.state.indexType === 1) {
      this.props.dispatch(dashboardActions.getDemographicIndexHappiness(data7));
    }
    else if (this.state.indexType === 2) {
      this.props.dispatch(dashboardActions.getDemographicIndexEngagement(data7));
    }
    else if (this.state.indexType === 4) {
      this.props.dispatch(dashboardActions.getDemographicIndexStress(data7));
    }
    else if (this.state.indexType === 5) {
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data7));
    }
    else if (this.state.indexType === 6) {
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data7));
    }

  }


  handleMultiFilterGetQuestion = (FilterValues, type, name, tabSummary) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "template": this.state.indexType,
      "filters": Filters
    }


    if (type === 2) {
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "template": this.state.indexType,
        "xAxis": name,
        "filters": Filters
      }
    }





    ////////console.log("getQuestion-->1",data7)

    ////console.log('tabSummary---->',tabSummary);


    if (tabSummary) {
      if (type === 2) {
        this.props.dispatch(dashboardActions.getQuestionMap(data7));
      }
      else {
        this.props.dispatch(dashboardActions.getQuestionDistribution(data7));

      }
    } else {
      this.props.dispatch(dashboardActions.getQuestion(data7));
      this.props.dispatch(dashboardActions.getQuestionDistribution(data7));

    }

  }


  handleMultiFilterGetDimenPrioQuestion = (FilterValues, type, name, tabSummary) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "template": this.state.indexType,
      "filters": Filters
    }


    if (type === 2) {
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "template": this.state.indexType,
        "xAxis": name,
        "filters": Filters
      }
    }

    this.props.dispatch(dashboardActions.getQuestion(data7));


  }

  handleMultiFilterGetMapQuestion = (FilterValues, type, name, tabSummary) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "template": this.state.indexType,
      "filters": Filters
    }


    if (type === 2) {
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "template": this.state.indexType,
        "xAxis": name,
        "filters": Filters
      }
    }



    this.props.dispatch(dashboardActions.getQuestionMap(data7));


  }



  handleMultiFilterGetResQuestion = (FilterValues, type, name, tabSummary) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "template": this.state.indexType,
      "filters": Filters
    }


    if (type === 2) {
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "template": this.state.indexType,
        "xAxis": name,
        "filters": Filters
      }
    }





    ////////console.log("getQuestion-->1",data7)

    ////console.log('tabSummary---->',tabSummary);


    this.props.dispatch(dashboardActions.getQuestionDistribution(data7));


  }


  handleMultiFilterGetCustomQuestion = (FilterValues, type, name) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "template": this.state.customeScoreData && this.state.customeScoreData.index ? this.state.customeScoreData.index : "",
      "filters": Filters
    }


    if (type === 2) {
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "template": this.state.customeScoreData && this.state.customeScoreData.index ? this.state.customeScoreData.index : "",
        "xAxis": name,
        "filters": Filters
      }
    }





    ////////console.log("getQuestion-->1",data7)

    // //////console.log('data7:::',data7);

    if (type === 2) {
      this.props.dispatch(dashboardActions.getQuestionCustomTemplate(data7));
    }
    else {
      this.props.dispatch(dashboardActions.getQuestionDistribution(data7));

      // this.props.dispatch(dashboardActions.getQuestion(data7));
    }
  }


  handleMultiFilterQuantile = (FilterValues, clear) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data7 = {}
    if (clear) {
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "index": this.state.indexType,
        "filters": []
      }
    }
    else {
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "index": this.state.indexType,
        "filters": Filters
      }
    }

    // //////console.log('data7:::',data7);
    this.props.dispatch(dashboardActions.getIndexQuantile(data7));
  }



  handleFilterRWA = (FilterValues, IndexType) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }


    let { getIndexData } = this.props.dashboard;



    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "index": IndexType,
      "filters": Filters,
      "addValueTemplate": []
    }

    if (getIndexData && getIndexData["5"]) {
      data7["addValueTemplate"].push(5)
    }

    if (getIndexData && getIndexData["6"]) {
      data7["addValueTemplate"].push(6)
    }
    // //////console.log('data7:::',data7);
    this.props.dispatch(dashboardActions.getEngagementRWA(data7));
  }



  handleMultiFilterAdvanc4Quadrant = (FilterValues, isFilterTwice, name) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": isFilterTwice ? name : XAxis,
      "filters": Filters
    }

    ////console.log('------------------------------------------->>>>.data7:::',name,isFilterTwice,data7);
    // //////console.log('data7:::',data7);
    this.props.dispatch(dashboardActions.getDemographicIndex(data7));

  }


  handleMultiFilterAdvancScatter = (FilterValues, isFilterTwice, name) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": isFilterTwice ? name : XAxis,
      "filters": Filters
    }

    //////console.log('data7:::',data7);
    // //////console.log('data7:::',data7);
    this.props.dispatch(dashboardActions.getDemographicIndexScatter(data7));
  }



  handleMultiFilterCorrelation = (FilterValues, selectedTargetValue, compareDimenList) => {
    let XAxis = "";
    let Filters = [];
    let data7 = {};
    if (FilterValues && FilterValues.length > 0) {


      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
        if (Filters && Filters.length > 0) {
          XAxis = Filters[Filters.length - 1].level;
        }
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }



      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "xAxis": XAxis,
        "filters": Filters
      }



    }
    else {

      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "filters": []
      }


    }


    //////console.log('data7:::',data7);


    // let tempdata2 ={
    //   "surveyId": CurrentSurveyId,
    //   "filters":[],
    //   "targetDimension":'Respect',
    //   "corrDimenionList":['Pride','Presence','Performance','Connect','ACT']
    // }

    data7["targetDimension"] = selectedTargetValue;
    data7["corrDimenionList"] = compareDimenList;


    if (data7 && data7["filters"] && data7["filters"].length > 0) {
      this.props.dispatch(dashboardActions.getCorrelationDimensionVsDimensionFilter(data7));

    } else {
      this.props.dispatch(dashboardActions.getCorrelationDimenionVsDimension(data7));
    }




    // if(subIndexType===1){

    //   if(data7 && data7.filters && data7.filters.length>0){
    //     this.props.dispatch(dashboardActions.getHappinessCorrelation(data7));
    //   }
    //   else{
    //     let Newdata7 = {
    //       "surveyId": this.state.CurrentSurveyId,
    //       "filters":[]
    //     }
    //     this.props.dispatch(dashboardActions.getHappinessCorrelationOverAll(Newdata7));
    //   }


    // }
    // else if(subIndexType===2){

    //   if(data7 && data7.filters && data7.filters.length>0){

    //     data7["targetDimension"]='Respect';
    //     data7["corrDimenionList"]=['Pride','Presence','Performance'];

    //     this.props.dispatch(dashboardActions.getEngagementCorrelation(data7));
    //   }
    //   else{
    //     let Newdata7 = {
    //       "surveyId": this.state.CurrentSurveyId,
    //       "filters":[]
    //     }
    //     this.props.dispatch(dashboardActions.getEngagementCorrelationOverAll(Newdata7));
    //   }

    // }
    // else if(subIndexType===4){

    //   if(data7 && data7.filters && data7.filters.length>0){
    //     this.props.dispatch(dashboardActions.getStressCorrelation(data7));
    //   }
    //   else{
    //     let Newdata7 = {
    //       "surveyId": this.state.CurrentSurveyId,
    //       "filters":[]
    //     }
    //     this.props.dispatch(dashboardActions.getStressCorrelationOverAll(Newdata7));
    //   }


    // }
    // else if(subIndexType===5){

    //   if(data7 && data7.filters && data7.filters.length>0){
    //     this.props.dispatch(dashboardActions.getManagerCorrelation(data7));
    //   }
    //   else{
    //     let Newdata7 = {
    //       "surveyId": this.state.CurrentSurveyId,
    //       "filters":[]
    //     }
    //     this.props.dispatch(dashboardActions.getManagerCorrelationOverAll(Newdata7));
    //   }


    // }
    // else if(subIndexType===6){

    //   if(data7 && data7.filters && data7.filters.length>0){
    //     this.props.dispatch(dashboardActions.getLeaderCorrelation(data7));
    //   }
    //   else{
    //     let Newdata7 = {
    //       "surveyId": this.state.CurrentSurveyId,
    //       "filters":[]
    //     }
    //     this.props.dispatch(dashboardActions.getLeaderCorrelationOverAll(Newdata7));
    //   }

    // }
  }


  onAssignManagerActionPlan = () => {
    let { CurrentSurveyId } = this.state;
    this.props.dispatch(dashboardActions.getAllManagerActionPlan({ surveyId: CurrentSurveyId }));
    this.props.dispatch(dashboardActions.AssignManagerActionPlan({ surveyId: CurrentSurveyId }));
  }


  handleMultifilterOrgDriverSummary = (FilterValues, isFilterTwice, name) => {

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": isFilterTwice ? name : XAxis,
      "filters": Filters
    }

    if (data7.xAxis) {
      this.props.dispatch(dashboardActions.getDemographicIndexOrg(data7));
    }

    //////console.log('data7:::',data7);
    // //////console.log('data7:::',data7);

  }


  getEDAdata = (name, blank, FilterList, xAxis) => {
    console.log('name, blank, FilterList, xAxis:::', name, blank, FilterList, xAxis);

    // let XAxis = "";
    let Filters = [];
    if (FilterList && FilterList.length > 0) {
      let getIndex = FilterList.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        // XAxis = FilterList[getIndex].level;
        FilterList.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        // XAxis = FilterList[FilterList.length-1].level;
        Filters = FilterList
      }

    }

    let NewFilter = [];

    if (name !== "Overall Score") {
      NewFilter = [{
        "level": xAxis,
        "value": name
      }];
    }
    if (Filters && Filters.length > 0) {
      Filters.forEach((item) => {
        let check = 0
        if (item.level === xAxis) {
          check++
        }
        if (check === 0) {
          NewFilter.push(item)
        }

      });
    }


    let data71 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": NewFilter && NewFilter.length > 0 ? NewFilter : [],
      "xAxisList": [],
    }

    let data7 = []

    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((filter1) => {
        if (filter1 && filter1.isEDA && filter1.name) {

          data71["xAxisList"].push(filter1.name)

          // data7.push({
          //   "surveyId": this.state.CurrentSurveyId,
          //   "xAxis": filter1.name,
          //   "filters": NewFilter && NewFilter.length>0?NewFilter:[]
          // });




        }

      });
    }


    console.log("data71---->", data71)



    // this.props.dispatch(dashboardActions.getDemographicIndexMultiManyFilter(data7));

    if (data71 && data71["xAxisList"] && data71["xAxisList"].length > 0) {
      this.props.dispatch(dashboardActions.getDemographicIndexMultiAxisFilter(data71));
    }



  }



  getEDAOrgdata = (name, scoreType, FilterList, xAxis, xAxisName) => {
    ////console.log('name:::',name);
    ////console.log('FilterList:::',FilterList);
    ////console.log('xAxis:::',xAxis);


    // let XAxis = "";
    let Filters = [];
    if (FilterList && FilterList.length > 0) {
      let getIndex = FilterList.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        // XAxis = FilterList[getIndex].level;
        FilterList.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        // XAxis = FilterList[FilterList.length-1].level;
        Filters = FilterList
      }

    }

    let NewFilter = [];
    let type = 1
    if (scoreType !== "overall") {
      NewFilter = [{
        "level": xAxis,
        "value": xAxisName
      }];
      if (Filters && Filters.length > 0) {
        Filters.forEach((item) => {
          let check = 0
          if (item.level === xAxis) {
            check++
          }
          if (check === 0) {
            NewFilter.push(item)
          }

        });
      }
      type = 2
    }




    let data71 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": NewFilter && NewFilter.length > 0 ? NewFilter : [],
      "xAxisList": [],
    }

    let data7 = []

    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((filter1) => {
        if (filter1 && filter1.isEDA && filter1.name) {

          data71["xAxisList"].push(filter1.name)

          // data7.push({
          //   "surveyId": this.state.CurrentSurveyId,
          //   "xAxis": filter1.name,
          //   "filters": NewFilter && NewFilter.length>0?NewFilter:[]
          // });




        }

      });
    }


    ////////console.log("data7---->",data7)


    // getDemographicIndexOrg
    // this.props.dispatch(dashboardActions.getDemographicIndexOrgManyFilter(data7,name, type));

    if (data71 && data71["xAxisList"] && data71["xAxisList"].length > 0) {
      this.props.dispatch(dashboardActions.getDemographicIndexMultiAxisOrg(data71));
    }


  }


  // getOrgCoreDimensionSentiment=(driverName)=>{
  //   let data = {
  //     "surveyId": this.state.CurrentSurveyId,
  //     "driver":driverName
  //   }
  //   this.props.dispatch(dashboardActions.getOrgCoreDriverSentiment(data));
  // }


  handleQuesDistributionIndexOrg = () => {
    let data241 = {
      "surveyId": this.state.CurrentSurveyId,
      "templateList": [1, 2, 4, 5, 6, 8]
    }
    this.props.dispatch(dashboardActions.getQuesDistributionIndexOrg(data241));
  }


  firstWord = (ClientProfileData) => {
    if (ClientProfileData && ClientProfileData.name) {
      var firstWords = [];
      var firstWords2 = [];
      var codelines = ClientProfileData.name;
      for (var i = 0; i < codelines.length; i++) {
        var words = codelines[i].split(" ");

        firstWords.push(words[0]);
      }
      if (firstWords && firstWords.length > 0) {
        firstWords.every((name, index) => {
          if (name === "") {
            return false;
          }
          else {
            firstWords2.push(name);
            return true;
          }
        }

        )
      }
      var Username = firstWords2.join("");

      return Username

    }
    return 'User'
  }


  onOverview = (surveyId) => {
    this.setState({
      showOverview: true,
      showIndex: false,
      showManagerList: false, showNudgeReport: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false


    })
    // this.setState({CurrentSurveyId:surveyId})

  }


  handleManagerDashboardCall = (mngr) => {
    let { ManagerListDasboardDataObj } = this.state;
    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": [{
        "level": "ManagerPID",
        "value": mngr.id
      }]
    }
    for (let i = 0; i < 2; i++) {
      if (ManagerListDasboardDataObj.findIndex(prev => prev === mngr.id) === -1) {
        this.props.dispatch(dashboardActions.getManagerListDashboardManagerIndex(data, mngr, ManagerListDasboardDataObj));
        let asdas = ManagerListDasboardDataObj;

      }
    }
    setTimeout(() => {
      this.setState({ ManagerListDasboardDataObj });
    }, 3000);

  }


  handleCallTemp = () => {
    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": 80000011
    }
    this.props.dispatch(dashboardActions.getDemographicIndex(data));
  }


  handleManagerActionTaskComplete = (task) => {
    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "taskId": task.id,
      "remark": task.remark
    }
    this.props.dispatch(dashboardActions.updateEmployeeTask(data));
  }


  handleManagerActionResourceComplete = (resource) => {
    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "resourceId": resource.id,
      "remark": resource.remark
    }
    this.props.dispatch(dashboardActions.updateEmployeeResource(data));
  }



  onCrossTab = () => {
    this.props.dispatch(dashboardActions.emptycrossTabAnalysis());

    let data = {
      surveyId: this.state.CurrentSurveyId
    }

    this.props.dispatch(questionsActions.getQuestionListBySurveyID(data));


  }

  handleCrossTabApi = (QuesID1, QuesID2, FilterValues) => {

    let data = {
      // "xAxis": "Department",
      "surveyId": this.state.CurrentSurveyId,
      "questionId1": QuesID1.id,
      "questionId2": QuesID2.id,
      "filters": FilterValues
    }
    // this.props.dispatch(dashboardActions.crossTab(data));

    this.props.dispatch(dashboardActions.crossTabAnalysis(data));


  }


  IndexDriverScore = (template) => {
    let data = {
      "surveyId": this.state.CurrentSurveyId
    }
    if (template === 1) {
      this.props.dispatch(dashboardActions.getHappinessIndexDriver(data));
    }
    else if (template === 2) {
      this.props.dispatch(dashboardActions.getEngagementIndexDriver(data));
    }
    else if (template === 4) {
      this.props.dispatch(dashboardActions.getStressIndexDriver(data));
    }
    else if (template === 5) {
      this.props.dispatch(dashboardActions.getManagerIndexDriver(data));
    }
    else if (template === 6) {
      this.props.dispatch(dashboardActions.getLeaderIndexDriver(data));
    }

  }


  AssignManagerActionPlan = (data) => {
    let { CurrentSurveyId } = this.state;
    let temp = {
      "surveyId": CurrentSurveyId,
      "managerList": data
    }
    ////console.log('temp:--------------> ',temp)
    this.props.dispatch(dashboardActions.AssignManagerActionPlan(temp));
  }




  //Report apis

  onReport = (surveyId) => {

    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false, showNudgeReport: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: true,
      showOtherTypes: false,
      showPeopleToMeet: false
    })

    let data = {
      "surveyId": this.state.CurrentSurveyId,
    }


  }

  onNudge = (surveyId) => {

    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false, showNudgeReport: true,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false,

    })


    let { dashboard } = this.props;
    let { getIndexData } = dashboard;

    let initTemplate = 0;

    if (getIndexData) {
      let validObj = Object.keys(getIndexData).map((key) => [key, getIndexData[key]])
      console.log('validObj: ', validObj);
      if (validObj && validObj.length > 0) {
        validObj.forEach((item, index) => {
          if (index === 0) {
            initTemplate = item[0]
          }
        })
      }
    }


    let dataX = {
      "surveyId": this.state.CurrentSurveyId,
      "indexNo": [initTemplate],
      "type": "IndexOverall"
    }

    this.props.dispatch(dashboardActions.getIndexOverallScore(dataX));

    let dataY = {
      "surveyId": this.state.CurrentSurveyId,
      "indexNo": [initTemplate],
      "type": "DistributionOverall"
    }

    this.props.dispatch(dashboardActions.getIndexOverallDistribution(dataY));


    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "indexNo": [initTemplate],
      "category": "Top",
      "type": "Index"
    }

    this.props.dispatch(dashboardActions.getDemographicWiseRankedScoreIndexTop(data));


    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "indexNo": [initTemplate],
      "category": "Bottom",
      "type": "Index"
    }

    this.props.dispatch(dashboardActions.getDemographicWiseRankedScoreIndexBottom(data2));

  }




  onLoadIndex = (indexType, check, filtersListNew, isFilterTwice, name, isClose) => {

    let X2Axis = "";
    let Filters = [];
    let isAllCheck = false;
    let isOverall = false
    if (filtersListNew && filtersListNew.length > 0) {
      let getIndex = filtersListNew.findIndex(prev => prev.valueList === "All");
      if (getIndex !== -1) {
        X2Axis = filtersListNew[getIndex].level;
        filtersListNew.forEach((item) => {
          if (item.valueList && item.valueList.length > 0) {
            if (item.valueList[0] !== "All") {
              Filters.push(item);
            }
            else {
              isAllCheck = true
            }
          }
          else {
            isAllCheck = true
          }

        });
      }
      else {
        X2Axis = filtersListNew[filtersListNew.length - 1].level;
        Filters = filtersListNew;
      }

      this.setState({ isReportOverall: false });
    }
    else {
      this.setState({ isReportOverall: true });
      isOverall = true;
    }

    // let data7 = {
    //   "surveyId": this.state.CurrentSurveyId,
    //   "xAxis": isFilterTwice?name:X2Axis,
    //   "filters":Filters
    // }


    let MultiValueFilters = [];
    let AllLevelsList = [];
    let FilLevelsList = [];

    if (Filters && Filters.length > 0) {
      Filters.forEach((inner) => {
        if (inner && inner.valueList && inner.valueList.length > 0) {
          inner.valueList.forEach((item) => {
            if (item !== "All") {
              MultiValueFilters.push({
                "level": inner.level,
                "value": item
              })
              FilLevelsList.push(inner.level);

            }
            else {
              AllLevelsList.push(inner.level);

            }

          })
        }

      });
    }

    let NewXaxis = ""
    if (AllLevelsList && AllLevelsList.length > 0) {
      NewXaxis = AllLevelsList[AllLevelsList.length - 1] ? AllLevelsList[AllLevelsList.length - 1] : "";
    }
    else {
      if (FilLevelsList && FilLevelsList.length > 0) {
        NewXaxis = FilLevelsList[FilLevelsList.length - 1] ? FilLevelsList[FilLevelsList.length - 1] : "";
      }
      else {
        NewXaxis = X2Axis
      }
    }

    ////////console.log("AllLevelsList---confirm2--->",AllLevelsList)
    ////////console.log("FilLevelsList---confirm2--->",FilLevelsList)
    ////////console.log("NewXaxis---confirm2--->",NewXaxis)
    ////////console.log("MultiValueFilters---X2Axis--->",X2Axis)



    ////////console.log("MultiValueFilters---confirm2--->",MultiValueFilters)


    let filter = MultiValueFilters;

    let isFilter = false;
    let xAxis = "";
    if (filter && filter.length > 0) {
      isFilter = true;
      xAxis = isFilterTwice ? name : X2Axis;
      // xAxis = filter && filter[0] && filter[0]["level"]?filter[0]["level"]:""
      this.setState({ isFilter: true });
    }
    else {
      this.setState({ isFilter: false });
    }

    let data1 = {
      "surveyId": this.state.CurrentSurveyId
    }

    this.props.dispatch(dashboardActions.getRespondantDetails({ ...data1, "filters": filter }));
    this.props.dispatch(dashboardActions.getRespondantDetails2({ ...data1, "filters": filter }));

    if (!isClose) {

      ////////console.log("filtersListNew---confirm1--->",filtersListNew)
      ////////console.log("filtersListNew---confirm2--->",filter)







      if (!isOverall) {
        this.setState({
          ReportDMGindexType: indexType,
          ReportDMGcheck: check,
          ReportDMGfiltersListNew: filter,
          ReportxAxis: NewXaxis
        });
        this.setState({ showReportDMGModal: true });

      }
      else {

        this.setState({ loadReport: check });
        this.onLoadIndexConfirm(indexType, check, filtersListNew);
        this.setState({ showReportDMGModal: false });

      }

    }
    else {

      let data1x1 = {
        "surveyId": this.state.CurrentSurveyId,
        "filters": []
      }
      this.props.dispatch(dashboardActions.getIndexFilter(data1x1));


      this.setState({
        ReportDMGindexType: "",
        ReportDMGcheck: "",
        ReportDMGfiltersListNew: "",
        ReportxAxis: "",
        showReportDMGModal: false,
        loadReport: false,
        isFilter: false
      });
    }



  }

  onLoadDMGReportConfirmation = (value) => {
    if (value) {
      this.setState({ loadReport: true });
      let {
        ReportDMGindexType,
        ReportDMGcheck,
        ReportDMGfiltersListNew,
        ReportxAxis
      } = this.state;
      this.onLoadIndexConfirm(ReportDMGindexType, ReportDMGcheck, ReportDMGfiltersListNew, ReportxAxis);
      this.setState({ showReportDMGModal: false });
    }
    else {
      this.setState({ loadReport: false });
      this.setState({ showReportDMGModal: false });
    }

  }

  onLoadIndexConfirm = (indexType, check, filtersListNew, xAxis) => {

    let { dashboard } = this.props;
    let { getReportTypeData } = dashboard;


    let isReportTwo = false;
    let year1 = "";
    let year2 = "";
    if (getReportTypeData && getReportTypeData.length > 1) {
      isReportTwo = true;
      year1 = getReportTypeData[getReportTypeData.length - 2]
      year2 = getReportTypeData[getReportTypeData.length - 1]
    }



    console.log("isReportTwo---->", isReportTwo)
    console.log("year1---->", year1)
    console.log("year2---->", year2)


    this.setState({ loadReport: check });

    let filter = filtersListNew;

    if (filter && filter.length > 0) {
      this.setState({ isFilter: true });
    }
    else {
      this.setState({ isFilter: false });
    }

    let { isReportOverall } = this.state;


    let isFilter = !isReportOverall;

    let data1x1 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": filter
    }

    if (isReportTwo) {
      this.props.dispatch(dashboardActions.getIndexFilter({ ...data1x1, year: year1 }));
      this.props.dispatch(dashboardActions.getIndex2Filter({ ...data1x1, year: year2 }));
    }
    else {
      this.props.dispatch(dashboardActions.getIndexFilter({ ...data1x1 }));
    }





    if (true) {


      let data1 = {
        "surveyId": this.state.CurrentSurveyId
      }


      if (isReportTwo) {

        this.props.dispatch(dashboardActions.getRespondantDetails2({ ...data1, year: year2, "filters": filter }));
        this.props.dispatch(dashboardActions.getRespondantDetails({ ...data1, year: year1, "filters": filter }));
      }
      else {
        this.props.dispatch(dashboardActions.getRespondantDetails({ ...data1, "filters": filter }));
      }


      if (isReportTwo) {
        this.props.dispatch(dashboardActions.getOverallIndex2({ ...data1, year: year2 }));
        this.props.dispatch(dashboardActions.getOverallIndex({ ...data1, year: year1 }));
      }
      else {
        this.props.dispatch(dashboardActions.getOverallIndex({ ...data1 }));
      }





      if (isFilter) {
        let data1x1 = {
          "surveyId": this.state.CurrentSurveyId,
          "filters": filter
        }


        if (isReportTwo) {
          this.props.dispatch(dashboardActions.getOverallIndex2({ ...data1, year: year2 }));
          this.props.dispatch(dashboardActions.getOverallIndex({ ...data1, year: year1 }));
        }
        else {
          this.props.dispatch(dashboardActions.getIndexFilter({ ...data1x1 }));
        }




      }
      else {
        let data1x2 = {
          "surveyId": this.state.CurrentSurveyId
        }



        if (isReportTwo) {
          this.props.dispatch(dashboardActions.getIndex2({ ...data1x2, year: year2 }));
          this.props.dispatch(dashboardActions.getIndex({ ...data1x2, year: year1 }));
        }
        else {
          this.props.dispatch(dashboardActions.getIndex({ ...data1x2 }));
        }



      }


      console.log("isFilter", isFilter, "---")

      let { dashboard } = this.props;
      let { getIndexData } = dashboard;

      let IndexValidList = getIndexData ? Object.keys(getIndexData) : [];

      if (IndexValidList && IndexValidList.length > 0) {
        IndexValidList.forEach((item) => {
          if (item && getIndexData && getIndexData[item]) {

            if (parseInt(item) === 1) {

              if (isReportTwo) {
                this.props.dispatch(dashboardActions.getHappinessIndexV22({ ...data1, year: year2 }));
                this.props.dispatch(dashboardActions.getHappinessIndexV2({ ...data1, year: year1 }));
              }
              else {
                this.props.dispatch(dashboardActions.getHappinessIndexV2({ ...data1 }));
              }




              this.props.dispatch(dashboardActions.getHappinessIndexDriver(data1));

              if (isFilter) {

                if (isReportTwo) {
                  this.props.dispatch(dashboardActions.getHappinessIndexV22Filter({
                    ...data1,
                    "year": year2,
                    "filters": filter
                  }));
                  this.props.dispatch(dashboardActions.getHappinessIndexV2Filter({
                    ...data1,
                    "year": year1,
                    "filters": filter
                  }));


                  this.props.dispatch(dashboardActions.getHappinessIndexDriver2Filter({
                    ...data1,
                    "year": year2,
                    "filters": filter
                  }));
                  this.props.dispatch(dashboardActions.getHappinessIndexDriverFilter({
                    ...data1,
                    "year": year1,
                    "filters": filter
                  }));





                }
                else {

                  this.props.dispatch(dashboardActions.getHappinessIndexV2Filter({
                    ...data1,
                    "filters": filter
                  }));

                  this.props.dispatch(dashboardActions.getHappinessIndexDriverFilter({
                    ...data1,
                    "filters": filter
                  }));


                }



              }
              else {
              }

              //Overall
              this.props.dispatch(dashboardActions.getHappinessDMGQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": []
              }));



              if (isReportTwo) {

                //Filter
                this.props.dispatch(dashboardActions.getHappinessQuestion2({
                  ...data1,
                  "year": year2,
                  "template": parseInt(item),
                  "filters": filter
                }));
                //Filter
                this.props.dispatch(dashboardActions.getHappinessQuestion({
                  ...data1,
                  "year": year1,
                  "template": parseInt(item),
                  "filters": filter
                }));
              }
              else {
                this.props.dispatch(dashboardActions.getHappinessQuestion({
                  ...data1,
                  "template": parseInt(item),
                  "filters": filter
                }));
              }






              let data111 = {
                ...data1,
                "index": parseInt(item),
                "filters": filter,
                "addValueTemplate": []
              }

              if (getIndexData && getIndexData["5"]) {
                data111["addValueTemplate"].push(5)
              }

              if (getIndexData && getIndexData["6"]) {
                data111["addValueTemplate"].push(6)
              }

              this.props.dispatch(dashboardActions.getHappinessRWA(data111));



            }
            else if (parseInt(item) === 2) {
              if (isReportTwo) {
                this.props.dispatch(dashboardActions.getEngagementIndexV2({ ...data1, year: year1 }));
                this.props.dispatch(dashboardActions.getEngagementIndexV22({ ...data1, year: year2 }));
              }
              else {
                this.props.dispatch(dashboardActions.getEngagementIndexV2({ ...data1 }));
              }




              this.props.dispatch(dashboardActions.getEngagementIndexDriver(data1));

              if (isFilter) {
                ////////console.log("--filter---","YES")



                if (isReportTwo) {

                  this.props.dispatch(dashboardActions.getEngagementIndexV22Filter({
                    ...data1,
                    "year": year2,
                    "filters": filter
                  }));
                  this.props.dispatch(dashboardActions.getEngagementIndexV2Filter({
                    ...data1,
                    "year": year1,
                    "filters": filter
                  }));


                  this.props.dispatch(dashboardActions.getEngagementIndexDriver2Filter({
                    ...data1,
                    "year": year2,
                    "filters": filter
                  }));
                  this.props.dispatch(dashboardActions.getEngagementIndexDriverFilter({
                    ...data1,
                    "year": year1,
                    "filters": filter
                  }));



                }
                else {

                  this.props.dispatch(dashboardActions.getEngagementIndexV2Filter({
                    ...data1,
                    "filters": filter
                  }));

                  this.props.dispatch(dashboardActions.getEngagementIndexDriverFilter({
                    ...data1,
                    "filters": filter
                  }));


                }


              }
              else {
                // this.props.dispatch(dashboardActions.getEngagementIndexV2(data1));
                // this.props.dispatch(dashboardActions.getEngagementIndexDriver(data1));
              }


              // this.props.dispatch(dashboardActions.getEngagementIndexV2(data1));
              //Overall
              this.props.dispatch(dashboardActions.getEngagementDMGQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": []
              }));


              if (isReportTwo) {

                //Filter
                this.props.dispatch(dashboardActions.getEngagementQuestion2({
                  ...data1,
                  "year": year2,
                  "template": parseInt(item),
                  "filters": filter
                }));
                //Filter
                this.props.dispatch(dashboardActions.getEngagementQuestion({
                  ...data1,
                  "year": year1,
                  "template": parseInt(item),
                  "filters": filter
                }));
              }
              else {
                this.props.dispatch(dashboardActions.getEngagementQuestion({
                  ...data1,
                  "template": parseInt(item),
                  "filters": filter
                }));
              }





              let data111 = {
                ...data1,
                "index": parseInt(item),
                "filters": filter,
                "addValueTemplate": []
              }

              if (getIndexData && getIndexData["5"]) {
                data111["addValueTemplate"].push(5)
              }

              if (getIndexData && getIndexData["6"]) {
                data111["addValueTemplate"].push(6)
              }

              this.props.dispatch(dashboardActions.getEngagementRWA(data111));

            }
            else if (parseInt(item) === 4) {


              if (isReportTwo) {
                this.props.dispatch(dashboardActions.getStressIndexV22({ ...data1, year: year2 }));
                this.props.dispatch(dashboardActions.getStressIndexV2({ ...data1, year: year1 }));
              }
              else {
                this.props.dispatch(dashboardActions.getStressIndexV2({ ...data1 }));
              }


              this.props.dispatch(dashboardActions.getStressIndexDriver(data1));


              if (isFilter) {
                this.props.dispatch(dashboardActions.getStressIndexV2Filter({
                  ...data1,
                  "filters": filter
                }));
                this.props.dispatch(dashboardActions.getStressIndexDriverFilter({
                  ...data1,
                  "filters": filter
                }));



                if (isReportTwo) {


                  this.props.dispatch(dashboardActions.getStressIndexV22Filter({
                    ...data1,
                    "year": year2,
                    "filters": filter
                  }));

                  this.props.dispatch(dashboardActions.getStressIndexV2Filter({
                    ...data1,
                    "year": year1,
                    "filters": filter
                  }));

                  // this.props.dispatch(dashboardActions.getStressIndexDriverFilter({
                  //   ...data1,
                  //   "year":year1,
                  //   "filters": filter
                  // }));
                  // this.props.dispatch(dashboardActions.getStressIndexDriver2Filter({
                  //   ...data1,
                  //   "year":year2,
                  //   "filters": filter
                  // }));




                }
                else {

                  this.props.dispatch(dashboardActions.getHappinessIndexV2Filter({
                    ...data1,
                    "filters": filter
                  }));

                  this.props.dispatch(dashboardActions.getHappinessIndexDriverFilter({
                    ...data1,
                    "filters": filter
                  }));


                }




              }
              else {
                // this.props.dispatch(dashboardActions.getStressIndexV2(data1));
                // this.props.dispatch(dashboardActions.getStressIndexDriver(data1));
              }
              // this.props.dispatch(dashboardActions.getStressIndexV2(data1));
              //Overall
              this.props.dispatch(dashboardActions.getStressDMGQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": []
              }));



              if (isReportTwo) {

                //Filter
                this.props.dispatch(dashboardActions.getStressQuestion2({
                  ...data1,
                  "year": year2,
                  "template": parseInt(item),
                  "filters": filter
                }));

                //Filter
                this.props.dispatch(dashboardActions.getStressQuestion({
                  ...data1,
                  "year": year1,
                  "template": parseInt(item),
                  "filters": filter
                }));

              }
              else {
                this.props.dispatch(dashboardActions.getStressQuestion({
                  ...data1,
                  "template": parseInt(item),
                  "filters": filter
                }));
              }





              let data111 = {
                ...data1,
                "index": parseInt(item),
                "filters": filter,
                "addValueTemplate": []
              }

              if (getIndexData && getIndexData["5"]) {
                data111["addValueTemplate"].push(5)
              }

              if (getIndexData && getIndexData["6"]) {
                data111["addValueTemplate"].push(6)
              }

              this.props.dispatch(dashboardActions.getStressRWA(data111));


            }
            else if (parseInt(item) === 5) {
              this.props.dispatch(dashboardActions.getManagerIndexV2(data1));
              // this.props.dispatch(dashboardActions.getManagerIndexDriver(data1));


              //Overall
              this.props.dispatch(dashboardActions.getManagerDMGQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": []
              }));


              if (isReportTwo) {


                //Filter
                this.props.dispatch(dashboardActions.getManagerQuestion2({
                  ...data1,
                  "year": year2,
                  "template": parseInt(item),
                  "filters": filter
                }));

                //Filter
                this.props.dispatch(dashboardActions.getManagerQuestion({
                  ...data1,
                  "year": year1,
                  "template": parseInt(item),
                  "filters": filter
                }));


              } else {
                this.props.dispatch(dashboardActions.getManagerQuestion({
                  ...data1,
                  "template": parseInt(item),
                  "filters": filter
                }));
              }









              if (isFilter) {
                this.props.dispatch(dashboardActions.getDemographicIndexManager({
                  ...data1,
                  "filters": filter,
                  "xAxis": xAxis
                }));
              }
              else {
                this.props.dispatch(dashboardActions.getManagerIndexDriver(data1));
              }


              let data111 = {
                ...data1,
                "index": parseInt(item),
                "filters": filter,
                "addValueTemplate": []
              }

              if (getIndexData && getIndexData["5"]) {
                data111["addValueTemplate"].push(5)
              }

              if (getIndexData && getIndexData["6"]) {
                data111["addValueTemplate"].push(6)
              }

              this.props.dispatch(dashboardActions.getManagerRWA(data111));


            }
            else if (parseInt(item) === 6) {
              // this.props.dispatch(dashboardActions.getLeaderIndexV2(data1));
              // this.props.dispatch(dashboardActions.getLeaderIndexDriver(data1));
              //Overall
              this.props.dispatch(dashboardActions.getLeaderDMGQuestion({
                ...data1,
                "template": parseInt(item),
                "filters": []
              }));

              if (isReportTwo) {


                //Filter
                this.props.dispatch(dashboardActions.getLeaderQuestion2({
                  ...data1,
                  "year": year2,
                  "template": parseInt(item),
                  "filters": filter
                }));

                //Filter
                this.props.dispatch(dashboardActions.getLeaderQuestion({
                  ...data1,
                  "year": year1,
                  "template": parseInt(item),
                  "filters": filter
                }));

              } else {
                this.props.dispatch(dashboardActions.getLeaderQuestion({
                  ...data1,
                  "template": parseInt(item),
                  "filters": filter
                }));
              }





              if (isFilter) {
                this.props.dispatch(dashboardActions.getDemographicIndexLeader({
                  ...data1,
                  "filters": filter,
                  "xAxis": xAxis
                }));
              }
              else {
                this.props.dispatch(dashboardActions.getLeaderIndexDriver(data1));
              }


              let data111 = {
                ...data1,
                "index": parseInt(item),
                "filters": filter,
                "addValueTemplate": []
              }

              if (getIndexData && getIndexData["5"]) {
                data111["addValueTemplate"].push(5)
              }

              if (getIndexData && getIndexData["6"]) {
                data111["addValueTemplate"].push(6)
              }

              this.props.dispatch(dashboardActions.getLeaderRWA(data111));


            }



          }
        });

      }



      // Page9
      //overall org
      let data9x = {
        "surveyId": this.state.CurrentSurveyId,
        "template": 8,
        "filters": []
      }
      this.props.dispatch(dashboardActions.getQuestionOverall(data9x));
      //Filter org
      let data9 = {
        "surveyId": this.state.CurrentSurveyId,
        "template": 8,
        "filters": filter
      }
      this.props.dispatch(dashboardActions.getQuestionFilter(data9));


      let allowAPI = true;

      if (true) {

        //Page1


        if (allowAPI) {

          // getHappinessIndexV2,
          // getEngagementIndexV2,
          // getHopeIndexV2,
          // getStressIndexV2,
          // getManagerIndexV2,
          // getLeaderIndexV2,


          // this.props.dispatch(dashboardActions.getAllBenchMark());

          // getHappinessIndexDriver,
          // getEngagementIndexDriver,
          // getStressIndexDriver,
          // getManagerIndexDriver,
          // getLeaderIndexDriver,

        }


        //Page2 
        let data2 = {
          "surveyId": this.state.CurrentSurveyId,
          "template": indexType,
          "filters": filter
        }

        if (allowAPI) {
          this.props.dispatch(dashboardActions.getQuestion(data2));

          this.props.dispatch(dashboardActions.getOrgIndexV2(data1));

          //Page3
          if (isFilter) {
            this.props.dispatch(dashboardActions.getDemographicIndexOrg({
              ...data1,
              "filters": filter,
              "xAxis": xAxis
            }));

            if (isReportTwo) {


              this.props.dispatch(dashboardActions.getOrgIndexV22Filter({
                ...data1,
                'year': year2,
                "filters": filter
              }));

              this.props.dispatch(dashboardActions.getOrgIndexV2Filter({
                ...data1,
                'year': year1,
                "filters": filter
              }));

            } else {
              this.props.dispatch(dashboardActions.getOrgIndexV2Filter({
                ...data1,
                "filters": filter
              }));
            }


          }
          else {
            this.props.dispatch(dashboardActions.getOrgIndexV2(data1));
          }


          this.props.dispatch(surveyActions.getSurveyById(data1));
        }


        //Page4
        let data3 = {
          "surveyId": this.state.CurrentSurveyId,
          "index": indexType,
          "filters": filter,
          "addValueTemplate": []
        }

        let { getIndexData } = this.props;

        if (getIndexData && getIndexData["5"]) {
          data3["addValueTemplate"].push(5)
        }

        if (getIndexData && getIndexData["6"]) {
          data3["addValueTemplate"].push(6)
        }

        if (allowAPI) {
          this.props.dispatch(dashboardActions.getEngagementRWA(data3));
        }

        //Page5
        let data4 = {
          "surveyId": this.state.CurrentSurveyId,
          "limit": 10,
          "top": true,
          "filters": filter
        }

        if (allowAPI) {

          if (isReportTwo) {
            this.props.dispatch(dashboardActions.getQuestionFavorability({ ...data4, "year": year1 }));
            this.props.dispatch(dashboardActions.getQuestionFavorabilityAPI2({ ...data4, "year": year2 }));
          } else {
            this.props.dispatch(dashboardActions.getQuestionFavorability({ ...data4 }));
          }

        }


        //Page6
        let data5 = {
          "surveyId": this.state.CurrentSurveyId,
          "limit": 10,
          "top": false,
          "filters": filter
        }

        if (allowAPI) {

          if (isReportTwo) {
            this.props.dispatch(dashboardActions.getQuestionFavorability2({ ...data5, "year": year1 }));
            this.props.dispatch(dashboardActions.getQuestionFavorabilityAPI4({ ...data5, "year": year2 }));
          } else {
            this.props.dispatch(dashboardActions.getQuestionFavorability2({ ...data5 }));
          }


        }



        let { dashboard } = this.props;
        let { EmpFilterData } = dashboard;




        if (!isFilter) {

          // Page7


          let data6 = []

          let data7 = []

          if (EmpFilterData && EmpFilterData.length > 0) {
            EmpFilterData.forEach((filter1) => {
              if (filter1.isShowReport) {



                data6.push({
                  "surveyId": this.state.CurrentSurveyId,
                  "xAxis": filter1 && filter1.name ? filter1.name : ""
                });

                data7.push({
                  "surveyId": this.state.CurrentSurveyId,
                  "xAxis": filter1 && filter1.name ? filter1.name : ""
                });




              }

            });
          }


          if (allowAPI) {
            this.props.dispatch(dashboardActions.getDemographicIndexFilterReport(data6));
          }



          // Page8

          if (isReportTwo) {
            this.props.dispatch(dashboardActions.getDemographicOrgReport2({ ...data7, 'year': year2 }));
            this.props.dispatch(dashboardActions.getDemographicOrgReport({ ...data7, 'year': year1 }));
          } else {
            this.props.dispatch(dashboardActions.getDemographicOrgReport({ ...data7 }));
          }















        }
        else {

          // Page7


          let data6 = []

          let data7 = []

          // if(EmpFilterData && EmpFilterData.length>0){
          //   EmpFilterData.forEach((filter1)=>{
          //     if(filter1.isShowReport){



          data6.push({
            "surveyId": this.state.CurrentSurveyId,
            "xAxis": xAxis,
            "filters": filter
          });

          data7.push({
            "surveyId": this.state.CurrentSurveyId,
            "xAxis": xAxis,
            "filters": filter
          });




          //   }

          //   });
          // }


          if (allowAPI) {
            this.props.dispatch(dashboardActions.getDemographicIndexFilterReport(data6));
          }



          // Page8

          if (isReportTwo) {

            this.props.dispatch(dashboardActions.getDemographicOrgReport2({ ...data7, 'year': year2 }));
            this.props.dispatch(dashboardActions.getDemographicOrgReport({ ...data7, 'year': year1 }));

          } else {
            this.props.dispatch(dashboardActions.getDemographicOrgReport({ ...data7 }));
          }



        }







        // NPS------------------------------------------------------------------------

        let dataNPSOverall = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplateList": [6],
          "filters": []
        }

        this.props.dispatch(generalActions.getQuestion2OthersResultFilter(dataNPSOverall));


        let dataNPS = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplateList": [6],
          "filters": filter
        }

        this.props.dispatch(generalActions.getQuestionOthersResult(dataNPS));

        let dataNPSFilter = []
        ////////console.log("EmpFilterData",EmpFilterData)

        if (EmpFilterData && EmpFilterData.length > 0) {
          EmpFilterData.forEach((filter1) => {
            if (filter1.isInEngComp) {
              ////////console.log("dataNPSFilter",filter1)
              dataNPSFilter.push({
                "surveyId": this.state.CurrentSurveyId,
                "qtemplateList": [6],
                "xAxis": filter1 && filter1.name ? filter1.name : "",
                "filters": filter
              })

            }

          });
        }

        if (dataNPSFilter && dataNPSFilter.length > 0) {
          this.props.dispatch(generalActions.getQuestionOthersMultiResultFilter(dataNPSFilter));
        }

        // NPS---end---------------------------------------------------------------------










        // Engagement Comparison
        let data10 = [];
        let data11 = [];

        let data10x = [];

        if (EmpFilterData && EmpFilterData.length > 0) {
          EmpFilterData.forEach((filter1) => {
            if (filter1.isInEngComp) {

              if (isReportTwo) {

                data10.push({
                  "surveyId": this.state.CurrentSurveyId,
                  "xAxis": filter1 && filter1.name ? filter1.name : "",
                  "filters": filter, 'year': year1
                });

                data11.push({
                  "surveyId": this.state.CurrentSurveyId,
                  "xAxis": filter1 && filter1.name ? filter1.name : "",
                  "filters": filter, 'year': year2
                });


              } else {
                data10.push({
                  "surveyId": this.state.CurrentSurveyId,
                  "xAxis": filter1 && filter1.name ? filter1.name : "",
                  "filters": filter
                });
              }

              data10x.push({
                "surveyId": this.state.CurrentSurveyId,
                "xAxis": filter1 && filter1.name ? filter1.name : "",
                "filters": []
              });

            }

          });
        }

        //Filter
        setTimeout(() => {
          this.props.dispatch(dashboardActions.getDemographicIndexMultiFilterEngComp(data10));

          if (isReportTwo) {
            this.props.dispatch(dashboardActions.getDemographicIndexMultiFilterEngComp2(data11));
          }

        }, 400)

        //Overall
        setTimeout(() => {
          this.props.dispatch(dashboardActions.getDemographicIndexEngCompOverall(data10x));
        }, 400)

        // this.props.dispatch(dashboardActions.getTextQuestion(data1, 111,filter));




      }









    }







  }


  onLoadManagerReport = (managerDetails) => {
    let { dashboard } = this.props;
    let { getIndexData } = dashboard;
    let { ManagerReportFConfig } = this.state;

    let IndexList = getIndexData ? Object.keys(getIndexData) : []

    let mngrId = managerDetails && managerDetails["emp_id"] ? managerDetails["emp_id"] : "";
    let mngrDMGValue = managerDetails && managerDetails["Department"] ? managerDetails["Department"] : "";

    if (mngrId) {

      let data1 = {
        "surveyId": this.state.CurrentSurveyId
      }

      this.props.dispatch(surveyActions.getSurveyById(data1));

      let managerIDNumber = mngrId;

      if (ManagerReportFConfig && ManagerReportFConfig.managerIdType) {
        if (ManagerReportFConfig.managerIdType === "Int") {
          managerIDNumber = parseInt(mngrId)
        }
        else if (ManagerReportFConfig.managerIdType === "String") {
          managerIDNumber = (mngrId).toString()
        }
      }



      if (IndexList.some(prev => (prev.toString()) === "1") && getIndexData && getIndexData["1"]) {

        this.props.dispatch(dashboardActions.getDemographicIndexHappiness({
          ...data1, "xAxis": "ManagerPID",
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber.toString()
            }
          ]
        }));

        this.props.dispatch(dashboardActions.getHappinessIndexByFilter({
          ...data1,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));



        //Happiness Manager
        this.props.dispatch(dashboardActions.getHappinessQuestion({
          ...data1,
          "template": 1,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getHappinessManagerQuestion({
          ...data1,
          "template": 1,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getHappinessDMGQuestion({
            ...data1,
            "template": 1,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }
      }






      if (IndexList.some(prev => (prev.toString()) === "2") && getIndexData && getIndexData["2"]) {

        this.props.dispatch(dashboardActions.getDemographicIndexEngagement({
          ...data1, "xAxis": "ManagerPID",
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber.toString()
            }
          ]
        }));

        this.props.dispatch(dashboardActions.getEngagementIndexByFilter({
          ...data1,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));




        //Engagement Manager
        this.props.dispatch(dashboardActions.getEngagementQuestion({
          ...data1,
          "template": 2,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getEngagementManagerQuestion({
          ...data1,
          "template": 2,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getEngagementDMGQuestion({
            ...data1,
            "template": 2,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }
      }


      if (IndexList.some(prev => (prev.toString()) === "4") && getIndexData && getIndexData["4"]) {

        this.props.dispatch(dashboardActions.getDemographicIndexStress({
          ...data1, "xAxis": "ManagerPID",
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber.toString()
            }
          ]
        }));

        this.props.dispatch(dashboardActions.getStressIndexByFilter({
          ...data1,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));




        //Stress Manager
        this.props.dispatch(dashboardActions.getStressQuestion({
          ...data1,
          "template": 4,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getStressManagerQuestion({
          ...data1,
          "template": 4,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getStressDMGQuestion({
            ...data1,
            "template": 4,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }
      }





      if (IndexList.some(prev => (prev.toString()) === "5") && getIndexData && getIndexData["5"]) {

        this.props.dispatch(dashboardActions.getDemographicIndexManager({
          ...data1, "xAxis": "ManagerPID",
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber.toString()
            }
          ]
        }));

        this.props.dispatch(dashboardActions.getManagerIndexByFilter({
          ...data1,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));




        //Manager Manager
        this.props.dispatch(dashboardActions.getManagerQuestion({
          ...data1,
          "template": 5,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getManagerManagerQuestion({
          ...data1,
          "template": 5,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getManagerDMGQuestion({
            ...data1,
            "template": 5,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }


      }

      if (IndexList.some(prev => (prev.toString()) === "6") && getIndexData && getIndexData["6"]) {

        this.props.dispatch(dashboardActions.getDemographicIndexLeader({
          ...data1, "xAxis": "ManagerPID",
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber.toString()
            }
          ]
        }));
        this.props.dispatch(dashboardActions.getLeaderIndexByFilter({
          ...data1,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));


        //Leader Manager
        this.props.dispatch(dashboardActions.getLeaderQuestion({
          ...data1,
          "template": 6,
          "filters": []
        }));
        this.props.dispatch(dashboardActions.getLeaderManagerQuestion({
          ...data1,
          "template": 6,
          "filters": [
            {
              "level": "ManagerPID",
              "value": managerIDNumber
            }
          ]
        }));
        if (mngrDMGValue) {
          this.props.dispatch(dashboardActions.getLeaderDMGQuestion({
            ...data1,
            "template": 6,
            "filters": [
              {
                "level": "Department",
                "value": mngrDMGValue.toString()
              }
            ]
          }));
        }



      }


    }


  }




  onTreeMap = (EMPFieldFirstName) => {
    this.props.dispatch(dashboardActions.EmptyTreeMap());



    let data = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getIndex(data));

    let { dashboard } = this.props;
    let { getIndexData } = dashboard;


    let IndexValidList = getIndexData ? Object.keys(getIndexData) : [];
    if (IndexValidList && IndexValidList.length > 0) {
      IndexValidList.forEach((item) => {
        if (item && getIndexData && getIndexData[item]) {
          let template = parseInt(item);
          if (template === 1) {
            this.props.dispatch(dashboardActions.getHappinessIndexDriver(data));
          }
          else if (template === 2) {
            this.props.dispatch(dashboardActions.getEngagementIndexDriver(data));
          }
          else if (template === 4) {
            this.props.dispatch(dashboardActions.getStressIndexDriver(data));
          }
          else if (template === 5) {
            this.props.dispatch(dashboardActions.getManagerIndexDriver(data));
          }
          else if (template === 6) {
            this.props.dispatch(dashboardActions.getLeaderIndexDriver(data));
          }
        }
      })



    }


    let data2 = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 6, [], EMPFieldFirstName));


  }








  onOthersCardClick = (item) => {

    // ////console.log('item:',item);
    // ////console.log('item Qid:',item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"");


    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false, showNudgeReport: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: true,


      showAllQuestions: false,

      showPeopleToMeet: false,

      currentQuestionId: item.answerList && item.answerList[0] && item.answerList[0].QuesId ? item.answerList[0].QuesId : "",
      currentQuestionTemplate: item.answerList && item.answerList[0] && item.answerList[0].template ? item.answerList[0].template : ""

    }, () => {
      let { currentQuestionTemplate } = this.state;

      let { dashboard } = this.props;
      let { EmpFilterData } = dashboard;

      let AxisInit = "Department";
      if (EmpFilterData && EmpFilterData.length > 0) {
        EmpFilterData.forEach((item, index) => {
          if (item.level && index === 0) {
            AxisInit = item.level;
          }
        });
      }

      if (currentQuestionTemplate !== 13) {
        let data7 = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplateList": [currentQuestionTemplate],
          "xAxis": AxisInit,
          "filters": []
        }
        this.props.dispatch(generalActions.getQuestionOthersResult(data7));
      }

    });



  }




  handleOthersFilterCall = (FilterValues, isValue, qTemplate) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }


    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplateList": [qTemplate],
      "xAxis": isValue,
      "filters": Filters
    }

    if (qTemplate === 13) {
      this.props.dispatch(generalActions.getQuestionMatrixResult(data7));
    }
    else {
      this.props.dispatch(generalActions.getQuestionOthersResultFilter(data7));
    }

  }



  onClusterAnalysis = (FilterValues) => {

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters
    }


    this.props.dispatch(dashboardActions.clusterAnalysis(data7));

  }


  getClusterEmpData = (empList) => {
    if (empList && empList.length > 0) {
      let data = {
        "empList": empList
      }
      this.props.dispatch(dashboardActions.getClusterEmpInfoList(data));
    }

  }


  onPeopleToMeetTab = () => {

    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false, showNudgeReport: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: true
    })
    this.onClusterAnalysis();


  }


  onCreateTopicSubmit = (data) => {
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }

    this.props.dispatch(dashboardActions.createTopic(data, temp))
  }


  getAllManagerScores = () => {
    let data = {
      "surveyId": this.state.CurrentSurveyId,

    }
    ////////console.log("getAllManagerScores--->//////////////////")
    this.props.dispatch(dashboardActions.getAllManagerScores(data))
  }

  onManagerDownloadStatus = () => {
    let data = {
      "surveyId": this.state.CurrentSurveyId,
    }
    this.props.dispatch(dashboardActions.getMngrReportDownloadStatus(data))
  }

  onManagerGoalPlan = () => {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }


    ////////console.log("----------------------------------------------onManagerGoalPlan----------------------------------------------------------->")



    this.props.dispatch(dashboardActions.getIDPLastDate({}))

    this.props.dispatch(dashboardActions.getAllManagerGoalPlan(data))
    this.props.dispatch(dashboardActions.getDemographicIndexForManager({ surveyId: this.state.CurrentSurveyId }))

    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false, showNudgeReport: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: true,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false
    })


  }



  onCustomQuestion = () => {
    let data = {
      "template": this.state && this.state.customeScoreData && this.state.customeScoreData.index ? this.state.customeScoreData.index : "",
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getQuestionCustomTemplate(data))
  }


  handleMultifilterQuesAvgSummary = (FilterValues, isFilterTwice, name) => {

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": isFilterTwice ? name : XAxis,
      "filters": Filters,
      "qtemplateList": [1]

    }

    if (data7.xAxis) {
      this.props.dispatch(generalActions.getQuestionAvgScore(data7));
    }

    //////console.log('data7:::',data7);
    // //////console.log('data7:::',data7);

  }

  callManagersQuestion = (data) => {
    let data5SSS1 = {
      "surveyId": this.state.CurrentSurveyId,
      "template": data.indexNumber
    }
    this.props.dispatch(dashboardActions.getQuestion(data5SSS1));

    let data5SSS = {
      "surveyId": this.state.CurrentSurveyId,
      "template": data.indexNumber,
      "mngrList": data.mngrEmpIdList
    }
    console.log("data5SSS------->", data5SSS)
    this.props.dispatch(dashboardActions.getQuestionManagerPIDWise(data5SSS));
  }

  callManagersDimension = (data) => {
    let data5SSS1 = {
      "surveyId": this.state.CurrentSurveyId
    }

    let data5SSS = {
      "surveyId": this.state.CurrentSurveyId,
      "mngrList": data.mngrEmpIdList
    }
    console.log("data5SSS-----callManagersDimension-->", data5SSS)

    if (data.indexNumber === 1) {
      this.props.dispatch(dashboardActions.getHappinessIndexDriver(data5SSS1));
      this.props.dispatch(dashboardActions.getHappinessIndexDriverManagerPIDWise(data5SSS));
    }
    else if (data.indexNumber === 2) {
      this.props.dispatch(dashboardActions.getEngagementIndexDriver(data5SSS1));
      this.props.dispatch(dashboardActions.getEngagementIndexDriverManagerPIDWise(data5SSS));
    }
    else if (data.indexNumber === 4) {
      this.props.dispatch(dashboardActions.getStressIndexDriver(data5SSS1));
      this.props.dispatch(dashboardActions.getStressIndexDriverManagerPIDWise(data5SSS));
    }
    else if (data.indexNumber === 5) {
      this.props.dispatch(dashboardActions.getManagerIndexDriver(data5SSS1));
      this.props.dispatch(dashboardActions.getManagerIndexDriverManagerPIDWise(data5SSS));
    }
  }


  callManagersIndex = (data) => {
    let data5SSS1 = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getIndex(data5SSS1));

    let data5SSS = {
      "surveyId": this.state.CurrentSurveyId,
      "mngrList": data.mngrEmpIdList
    }
    console.log("data5SSS------->", data5SSS)
    this.props.dispatch(dashboardActions.getIndexScoreManagerPIDWise(data5SSS));
  }


  onTextCluster = () => {
    let data = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data, 9));

  }


  getClusterSentences = (QuesId, FilterValues, clusterNo, keyword) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters,
      "cluster": clusterNo,
      "keyWord": keyword

    }

    this.props.dispatch(dashboardActions.getClusterSentences(data2));

  }


  getTextClusterFilter = (QuesId, FilterValues) => {
    let { CurrentSurveyId } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }
    }

    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters
    }

    this.props.dispatch(dashboardActions.getTextCluster(data2));

  }


  handleIndexVSNpsType = (npsType, QuesId, FilterValues, isLadder) => {
    let { CurrentSurveyId, indexType } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }
    }


    let data2222 = {
      "surveyId": CurrentSurveyId,
      "npsRange": npsType === 1 ? ["8", "9", "10"] : (npsType === 3 ? ["6", "7"] : ["0", "1", "2", "3", "4", "5"]),
      "template": indexType,
      "questionId": QuesId,
      "filters": Filters
    }
    this.props.dispatch(dashboardActions.getIndexVsNps(data2222));

  }


  handleMultiFilterStrenghtWeakness = (FilterValues, isStrenght) => {

    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        Filters = FilterValues
      }
    }


    let data2 =
    {
      "surveyId": this.state.CurrentSurveyId,
      "limit": 10,
      "top": isStrenght ? true : false,
      "filters": Filters
    }

    this.props.dispatch(dashboardActions.getQuestionFavorability(data2));

  }


  // onOverviewFromHeader=()=>{
  //   let {showOtherTypes, showAllQuestions } = this.state;
  //   if(showOtherTypes && showAllQuestions){
  //     this.setState({showAllQuestions:false})
  //   }else if(showOtherTypes && !showAllQuestions){
  //     this.setState({showOtherTypes:false})
  //   }
  // }

  handleGoBack = () => {
    let { showOtherTypes, showAllQuestions } = this.state;
    if (showOtherTypes && !showAllQuestions) {
      this.setState({
        showOverview: false,
        showAllQuestions: true,
        showOtherTypes: true
      })
    } else if (showOtherTypes && showAllQuestions) {
      this.setState({
        showOverview: true,
        showAllQuestions: false,
        showOtherTypes: false
      })
    }
  }


  onNudgeOptionType = (initTemplate) => {

    let dataX = {
      "surveyId": this.state.CurrentSurveyId,
      "indexNo": [initTemplate],
      "type": "IndexOverall"
    }

    this.props.dispatch(dashboardActions.getIndexOverallScore(dataX));

    let dataY = {
      "surveyId": this.state.CurrentSurveyId,
      "indexNo": [initTemplate],
      "type": "DistributionOverall"
    }

    this.props.dispatch(dashboardActions.getIndexOverallDistribution(dataY));



    let data1 = {
      "surveyId": this.state.CurrentSurveyId,
      "indexNo": [initTemplate],
      "category": "Top",
      "type": "Index"
    }

    this.props.dispatch(dashboardActions.getDemographicWiseRankedScoreIndexTop(data1));

    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "indexNo": [initTemplate],
      "category": "Bottom",
      "type": "Index"
    }

    this.props.dispatch(dashboardActions.getDemographicWiseRankedScoreIndexBottom(data2));

  }


  handleTabClick = (tabName) => {
    this.setState({ activeTab: tabName });
  };


  handleMainTabClick = (tabName) => {
    this.setState({ mainTab: tabName }, () => {
      if (tabName === "Normalization") {
        let data2x = {
          appraisalCycleId: this.state.appraisalCycleId,
          type: 'RATING'
        }
        this.props.dispatch(dashboardActions.getEmployeeRatingAndNormalization(data2x))
      }
    }
    );
  };


  handleTabChange = (tab, card) => {
    let data = {
      appraisalCycleId: tab,
      type: "REVIEWER_LIST"
    }
    this.props.dispatch(pmsActions.getEmployeeAppraisalStatus(data));
    this.setState({ isCardSelected: card });
  };


  // handleAppraisalStatusFilter=(FilterValues)=>{
  //   let XAxis = "";
  //   let Filters = [];
  //   if (FilterValues && FilterValues.length > 0) {
  //     let getIndex = FilterValues.findIndex(prev => prev.value === "All");
  //     if (getIndex !== -1) {
  //       XAxis = FilterValues[getIndex].level;
  //       FilterValues.forEach((item) => {
  //         if (item.value !== "All") {
  //           Filters.push(item);
  //         }
  //       });
  //     }
  //     else {
  //       XAxis = FilterValues[FilterValues.length - 1].level;
  //       Filters = FilterValues
  //     }

  //   }

  //   let data7 = {
  //     "appraisalCycleId": this.state.appraisalCycleId,
  //     "filters": Filters
  //   }

  //   this.props.dispatch(pmsActions.getDashboardOverviewStatus(data7))
  // }


  render() {

    let { dashboard, questions, survey, general, pms } = this.props;
    let { questionsList } = questions;

    let { getQuestionAvgScoreData, getQuestion2OthersResultFilterData, getQuestionOthersResultData, getQuestionOthersResultFilterData, getQuestionOthersMultiResultFilterData } = general;

    let { PMSGetDetailsByAppraisalCycleId, getDashboardOverviewStatusData, getEMPASData, getPMSEWScore, getEMPASLabels } = pms;


    // console.log('getEMPASDatagetEMPASDatagetEMPASData111111111111111111111111111111111111111', getDashboardOverviewStatusData);
    // console.log('getEMPASDatagetEMPASDatagetEMPASDatag222222222222222222222222222222222222222', getPMSEWScore);
    ////////console.log("----------------------------------------------------------------------------------------------------------")
    ////////console.log("many result: ",getQuestionOthersMultiResultFilterData)
    ////////console.log("----------------------------------------------------------------------------------------------------------")


    let { showNudgeReport, showPeopleToMeet, customeScoreData, showCustomScoreCard, showReportDMGModal, ManagerReportFConfig, loadReport, isFilter, currentQuestionId, currentQuestionTemplate, activeTab, activeCard } = this.state;


    let { getSurveyData, getIndexByFilterDMGData, parameterList } = survey;

    // console.log('this.state.isCardSelected', this.state.isCardSelected);


    let {
      getEmployeeRatingAndNormalization,

      getIndexOverallScoreData,
      getIndexOverallDistributionData,

      getReportIndexYearCheckData,
      getDemographicWiseRankedScoreIndexTopData,
      getDemographicWiseRankedScoreIndexBottomData,
      getHappinessRWAData,
      getStressRWAData,
      getManagerRWAData,
      getLeaderRWAData,

      getMetaRespondantDetailsData,
      getMetaRespondantDetails2Data,
      getClusterSaveKeyWord,
      getClusterSentencesData,
      getClusterKeywordsData,
      getTextClusterSentimentCountData,
      textclusterData,
      getTextClusterData,
      getUserAccessData,

      AnswerFeedbackTextData,
      AnswerFeedbackTextKeyWord,

      getQuestionManagerPIDWiseData,
      getIndexScoreManagerPIDWiseData,
      getIndexDriverManagerPIDWiseData,

      clusterAnalysisError,
      textDistError,
      TreeMapError,
      neighborGraphError,
      wordCloudError,
      topicModellingError,
      getCorrelationDimenionVsDimensionError,
      crossTabError,



      getQuestionCustomTemplateData,
      getQuestionMapData,
      getCompanyNameOnlyData,
      getIDPLastDateData,
      getAllManagerGoalPlanData,
      clusterAnalysisData,
      clusterAnalysisCount,

      getOverallScoreBySurveyIdData,

      getAllManagerScoresData,

      getMngrReportDownloadStatusData,
      getMngrReportDownloadStatusTotal,

      getTopicListData,

      clusterAnalysisMSG,

      topicModellingData,

      getClusterEmpInfoListData,

      getParameterLibraryListData,

      ClientProfileData, SurveyList, HappinessIndexMNGRData,
      EngagementIndexMNGRData, HopeIndexMNGRData, StressIndexMNGRData,

      getDemographicIndexMultiFilterEngCompData,
      getDemographicIndexMultiFilterEngComp2Data,
      getQuestionCommentsData,

      getOrgIndexV2FilterData,

      TreeMapData,
      textDistTreeMapData,
      EmpFilterData,
      getEngagementRWAData,
      getDemographicIndexFilterReportData,

      textDistData,
      getDemographicIndexMultiFilterData,

      ManagerListData, getDemographicIndexData,

      wordCloudMultiTextData,
      wordMultiTextKeyWord,

      loading,

      crossTabAnalysisData,

      getManagerListDashboardManagerIndexData,


      getDemographicOrgReportData,

      getDemographicIndexEngCompOverallData,


      HappinessIndexNewData,
      EngagementIndexNewData,
      HopeIndexNewData,
      StressIndexNewData,
      ManagerIndexNewData,




      getQuestionFilterData,
      getOrgIndexV22FilterData,

      OrgCoreIndexData,

      getIndexFilterData,
      getIndex2FilterData,


      getManagerListDomainFilterData,

      getQuestionData,

      getDemographicIndexHappinessData,
      getDemographicIndexEngagementData,
      getDemographicIndexHopeData,
      getDemographicIndexStressData,
      getDemographicIndexManagerData,
      getDemographicIndexLeaderData,

      getQuestionManagerOfHappinessData,
      getQuestionManagerOfEngagementData,
      getQuestionManagerOfStressData,
      getQuestionManagerOfManagerData,


      // getHappinessIndexV2Data,
      // getEngagementIndexV2Data,
      // getStressIndexV2Data,
      // getManagerIndexV2Data,

      getDemographicIndexScatterData,


      getHappinessCorrelationData,
      getEngagementCorrelationData,
      getStressCorrelationData,
      getManagerCorrelationData,
      getLeaderCorrelationData,


      getRWAnalysisData,


      getTextQuestionData,

      wordCloudData,
      AdvanceWordCloudData,

      wordTextKeyWord,

      cooccurmatrixData,


      getQuestionFavorabilityData,
      getQuestionFavorabilityAPI2Data,

      getQuestionFavorability2Data,
      getQuestionFavorabilityAPI4Data,



      getCommentData,

      crossTabData,
      crossTabDMGData,

      getQuestionOverallData,

      getCorrelationDimenionVsDimensionData,
      getCorrelationDimensionVsDimensionFilterData,
      getOrgIndexV2Data,
      getOrgIndexV22Data,
      getAllBenchMarkData,




      getHappinessQuestionData,
      getHappinessQuestion2Data,

      getHappinessManagerQuestionData,
      getHappinessDMGQuestionData,

      getEngagementQuestionData,
      getEngagementQuestion2Data,

      getEngagementManagerQuestionData,
      getEngagementDMGQuestionData,

      getHopeQuestionData,

      getStressQuestionData,
      getStressQuestion2Data,

      getStressManagerQuestionData,
      getStressDMGQuestionData,

      getManagerQuestionData,
      getManagerQuestion2Data,

      getManagerManagerQuestionData,
      getManagerDMGQuestionData,

      getLeaderQuestionData,
      getLeaderQuestion2Data,

      getLeaderManagerQuestionData,
      getLeaderDMGQuestionData,

      getHappinessIndexV2Data,
      getHappinessIndexV22Data,

      getEngagementIndexV2Data,
      getEngagementIndexV22Data,


      getHopeIndexV2Data,

      getStressIndexV2Data,
      getStressIndexV22Data,

      getManagerIndexV2Data,
      getLeaderIndexV2Data,

      getHappinessIndexDriverData,
      getEngagementIndexDriverData,
      getHopeIndexDriverData,
      getStressIndexDriverData,
      getManagerIndexDriverData,
      getLeaderIndexDriverData,


      getHappinessIndexV2DemographicData,
      getEngagementIndexV2DemographicData,
      getHopeIndexV2DemographicData,
      getStressIndexV2DemographicData,
      getDemographicIndexOrgData,


      getHappinessIndexByFilterData,
      getEngagementIndexByFilterData,
      getStressIndexByFilterData,
      getManagerIndexByFilterData,
      getLeaderIndexByFilterData,


      getOverallIndexData,
      getOverallIndex2Data,


      getHappinessIndexV2FilterData,
      getHappinessIndexV22FilterData,

      getEngagementIndexV2FilterData,
      getEngagementIndexV22FilterData,

      getHopeIndexV2FilterData,

      getStressIndexV2FilterData,
      getStressIndexV22FilterData,

      getHappinessIndexDriverFilterData,
      getHappinessIndexDriver2FilterData,

      getEngagementIndexDriverFilterData,
      getEngagementIndexDriver2FilterData,

      getHopeIndexDriverFilterData,
      getStressIndexDriverFilterData,



      getDemographicIndexError,
      crossTabAnalysisError,
      wordCloudTextError,
      textDistTreeError,
      getQuestionFavorabilityError,
      getClusterEmpInfoError,


      getOrgQuestionData,
      getOrgDMGQuestionData,
      getOrgManagerQuestionData,
      getOrgCoreDriverSentimentData,
      getFeedbackTextQuestionData,
      getReportTypeData


    } = dashboard;


    let ErrorOtherObj = {
      "getDemographicIndexError": getDemographicIndexError,
      "getCorrelationDimenionVsDimensionError": getCorrelationDimenionVsDimensionError,
      "crossTabAnalysisError": crossTabAnalysisError,

      "wordCloudTextError": wordCloudTextError,
      "neighborGraphError": neighborGraphError,
      "TreeMapError": TreeMapError,
      "textDistError": textDistError,
      "textDistTreeError": textDistTreeError,
      "topicModellingError": topicModellingError,
      "getQuestionFavorabilityError": getQuestionFavorabilityError,
      "clusterAnalysisError": clusterAnalysisError,
      "getClusterEmpInfoError": getClusterEmpInfoError


    }

    let { showManagerGoalPlan, Mindextype, MQuesIndextype, ManagerListDasboardDataObj, ManagerListQuestionDataObj, SelectedAPDimension, SelectedAPAction, SelectedAPTask } = this.state;


    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }



    function getFilterViewFn(getIndexDMGData, getUserAcc) {
      let FilterDMGDataObj = {}
      if (getIndexDMGData) {
        let list = Object.keys(getIndexDMGData);
        if (list && list.length > 0) {
          list.forEach((key) => {
            if (getUserAcc && getUserAcc[key] && getUserAcc[key].length > 0) {
              FilterDMGDataObj[key] = getUserAcc[key]
            } else {
              FilterDMGDataObj[key] = getIndexDMGData[key]
            }
          })
        }

      }
      return FilterDMGDataObj

    }
    getIndexByFilterDMGData = getFilterViewFn(getIndexByFilterDMGData, getUserAccessData)

    // console.log("11111111111 getEngagementIndexV22Data----NEW--->", getEngagementIndexV22Data)
    // console.log("11111111111 getEngagementIndexV22Data----NEW--->", getEngagementIndexV22Data)



    let SHOW_ELEMENT = false;
    let isCrole = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        isCrole = true
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    //END -------------------------------------------------------




    let ManagerIndexQuestionData = '';

    if (MQuesIndextype === 1) {
      ManagerIndexQuestionData = getQuestionManagerOfHappinessData;
    }
    else if (MQuesIndextype === 2) {
      ManagerIndexQuestionData = getQuestionManagerOfEngagementData;
    }
    else if (MQuesIndextype === 4) {
      ManagerIndexQuestionData = getQuestionManagerOfStressData;
    }
    else if (MQuesIndextype === 5) {
      ManagerIndexQuestionData = getQuestionManagerOfManagerData;
    }




    let ManagerIndexDriverData = [];

    if (Mindextype === 1) {
      ManagerIndexDriverData = getDemographicIndexHappinessData;
    }
    else if (Mindextype === 2) {
      ManagerIndexDriverData = getDemographicIndexEngagementData;
    }
    else if (Mindextype === 4) {
      ManagerIndexDriverData = getDemographicIndexStressData;
    }
    else if (Mindextype === 5) {
      ManagerIndexDriverData = getDemographicIndexManagerData;
    }




    let { showAllQuestions, showOtherTypes, showReport, showSidebar, CurrentSurveyId, indexType, showOverview, showIndex, showManagerList, showAdvanceComparision, showTextAnalysis, showStrengthAndWeaknesses, showManagerActionPlan, DynaObj, ManagerDynaObj, QuesDynaObj } = this.state;
    let { getAllParameterListData, getAllManagerActionPlanData, getManagerActionPlanData, getIndexData, getIndex2Data,
      getDemographicIndexForManagerData, wordCloudTextData, neighborGraphData,
      getRespondantDetailsData, getRespondantDetails2Data } = dashboard;

    // //////console.log('showOverview: ',showOverview)
    // //////console.log('showIndex: ',showIndex)
    // //////console.log('showManagerList: ',showManagerList)
    // //////console.log('sshowAdvanceComparision: ',showAdvanceComparision)
    let ManagerNameIdList = getDemographicIndexForManagerData;

    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageractionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }


    // console.log('------------(  getIndexData,getIndex2Data, )----->', getIndexData, getIndex2Data)


    function checkIndex(key) {
      let check = false
      if (key === 1) { check = true }
      else if (key === 2) { check = true }
      else if (key === 3) { check = true }
      else if (key === 4) { check = true }
      else if (key === 5) { check = true }
      else if (key === 6) { check = true }
      else if (key === 7) { check = true }
      else if (key === 9) { check = true }
      else { check = false }

      return check;
    }

    let IndexList = []
    let newFilterObj = {}
    if (getIndexData) {



      IndexList = Object.keys(getIndexData).map((key) => [Number(key), getIndexData[key]]);

      if (getIndexFilterData) {
        if (IndexList && IndexList.length > 0) {
          IndexList.forEach((data) => {
            if (checkIndex(data[0])) {
              newFilterObj[data[0]] = getIndexFilterData[data[0]];
            }
          });
        };
      }
    }




    let IndexDimension = {
      "Happiness": ['Time', 'Being', 'Relationship', 'WorkLife', 'Achivement', 'Meeningfull'],
      "Engagement": ['Pride', 'Presence', 'Performance'],
      "Stress": ['Recognition', 'WorkSatisfaction', 'Inclusion', 'Collaboration', 'Empowerment'],
      "Manager": ['Connect', 'ACT', 'Respect', 'Empower'],
      "Leader": ['Vision', 'Impact', 'Execute', 'Wisdom']
    }


    function CountRestrict(count, isOverall, section) {
      let isCountLessThanFive = false;

      // ////////console.log("count---",count)

      if (count !== "NaN") {
        if (isOverall) {
          isCountLessThanFive = false;
        }
        else {
          if (section === "rwa") {
            if (parseInt(count) < 30) {
              isCountLessThanFive = true;
            }
          }
          else {
            if (parseInt(count) < 5) {
              isCountLessThanFive = true;
            }
          }

        }
      }
      // ////////console.log("isCountLessThanFive---",isCountLessThanFive)

      return isCountLessThanFive
    }


    function GetParameterLabel(name) {
      let AllParameterObj = {};
      let NewParameterList = survey && survey.parameterList && survey.parameterList.length > 0 ? survey.parameterList : []
      if (NewParameterList && NewParameterList.length > 0) {
        NewParameterList.forEach((item) => {
          if (item && item.name) {
            AllParameterObj[item.name] = item && item.label ? item.label : item.name;
          }
        });
      }
      return (AllParameterObj && AllParameterObj[name] ? AllParameterObj[name] : name);
    }


    // ////////console.log("getSurveyData------------>",getSurveyData)


    function FormattFun(inputData, isSummary) {

      let LIST = []
      let prevTemList = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {

          let templateId = ques.total[0]._id.qtemplate;

          let temp = {
            name: ques && ques.question && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
            answerList: [],
            templateId: templateId
          }
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading: item && item._id && item._id.heading ? item._id.heading : "",
                parentId: item && item._id && item._id.parentId ? item._id.parentId : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId
              }
              temp["answerList"].push(ans);
            });
          }


          if (isSummary) {
            let getIndex = prevTemList.findIndex(prev => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId)
            }
          }
          else {
            LIST.push(temp);
          }


        });
      }
      return LIST
    }
    let AllQuesList = FormattFun(getQuestionOthersResultData, false).filter(x => x.templateId === currentQuestionTemplate);


    ////////console.log("----AllQuesList----,",AllQuesList)
    ////////console.log("----getQuestionOthersResultData----,",getQuestionOthersResultData)


    //////console.log('currentQuestionId, currentQuestionTemplate :',currentQuestionId, currentQuestionTemplate );






    let surveyLogo = "";

    let surveyId = getSurveyData && getSurveyData.id ? getSurveyData.id : "";

    if (surveyId === "618b673c9503f41bad61667f") {
      surveyLogo = 'img/logo/kotaklifelogo.png';
    }
    else if (surveyId === "61541d6cf0f59b75a9b50d44") {
      surveyLogo = 'img/logo/tcillogo.jpeg';
    }
    else if (surveyId === "61b1850ada0e4ed403288e52") {
      surveyLogo = 'img/logo/tcellogo.jpg';
    }
    else if (surveyId === "61c06c871a708fbb5cf1c9a3") {
      surveyLogo = 'img/logo/bppllogo.png';
    }
    else if (surveyId === "5fa04d316ede0f0336066cce") {
      surveyLogo = 'img/logo/happypluslogo.png';
    }
    else if (surveyId === "621dc1de0f73b3a8e532f1b5") {
      surveyLogo = 'img/logo/axissecuritylogo.png';
    }



    function getEMPFilterLabel(name) {

      let label = name;
      if (EmpFilterData && EmpFilterData.length > 0) {
        let getIndex = EmpFilterData.findIndex(prev => prev.name === name);
        if (getIndex !== 1) {
          label = EmpFilterData && EmpFilterData[getIndex] && EmpFilterData[getIndex].label ? EmpFilterData[getIndex].label : name
        }

      }
      return label
    }


    function ObjectFieldAveragingFn(getTempIndexV2DemographicData, type) {
      let count = 0
      let keyList = Object.keys(getTempIndexV2DemographicData && getTempIndexV2DemographicData[0] ? getTempIndexV2DemographicData[0] : {});
      let obj = {}
      if (keyList && keyList.length > 0) {
        keyList.forEach((key) => {
          obj[key] = 0;
        });
      }
      if (getTempIndexV2DemographicData && getTempIndexV2DemographicData.length > 0) {
        getTempIndexV2DemographicData.forEach((item) => {
          if (keyList && keyList.length > 0) {
            keyList.forEach((key) => {
              obj[key] += item[key];
              count += item.count;
            });
          }
        });
        if (keyList && keyList.length > 0) {
          keyList.forEach((key) => {
            obj[key] /= getTempIndexV2DemographicData.length;
          });
        }
      }
      if (type === "count") {
        obj["count"] = count;
      }
      return obj
    }



    function getSurveyDetails(name) {
      return getSurveyData && getSurveyData[name] ? getSurveyData[name] : "NaN";
    }

    let isLifecycle = false
    if (getSurveyData && getSurveyData.isRiskSurveyType === "DEFAULTDAYS") {
      isLifecycle = true
    }



    ////////console.log("<><><><< isLifecycle- <><><><<--->",isLifecycle)






    //Access Compoenent specific
    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          console.log(EmpFilterData2, 'EmpFilterData2')
          EmpFilterData2.push(emp);
        }
      });
    }


    function allowSpecificFn(name) {
      if (name === "tce" && (CurrentSurveyId === "61b1850ada0e4ed403288e52")) {
        return true
      }
      else {
        return false
      }
    }

    // console.log("12123123 getReportTypeData----->", getReportTypeData)

    let isReportTwo = false;
    let yearValue1 = "";
    let yearValue2 = "";
    if (getReportTypeData && getReportTypeData.length > 1) {
      isReportTwo = true;
      yearValue1 = getReportTypeData[getReportTypeData.length - 2];
      yearValue2 = getReportTypeData[getReportTypeData.length - 1];
    }

    // Extract completed and pending counts from reviewStatus
    const completedCountSelfAppraisal = getDashboardOverviewStatusData?.selfAppraisalStatus?.completed || 0;
    const pendingCountSelfAppraisal = getDashboardOverviewStatusData?.selfAppraisalStatus?.pending || 0;
    const totalSelfAppraisalStatus = completedCountSelfAppraisal + pendingCountSelfAppraisal;

    // Extract completed and pending counts from reviewStatus
    const completedCountMultiRater = getDashboardOverviewStatusData?.multiRaterStatus?.completed || 0;
    const pendingCountMultiRater = getDashboardOverviewStatusData?.multiRaterStatus?.pending || 0;
    const totalMultiRaterStatus = completedCountMultiRater + pendingCountMultiRater;

    // Extract completed and pending counts from reviewStatus
    const completedCountReviewStatus = getDashboardOverviewStatusData?.reviewStatus?.completed || 0;
    const pendingCountReviewStatus = getDashboardOverviewStatusData?.reviewStatus?.pending || 0;
    const totalReviewStatus = completedCountReviewStatus + pendingCountReviewStatus;




    let optionloop = Object.keys(getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.labels ? getEmployeeRatingAndNormalization.labels : {})
    let optiondata = [getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.labels ? getEmployeeRatingAndNormalization.labels : {}]




    let data5 = (getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.normlLabelCount ? getEmployeeRatingAndNormalization.normlLabelCount : {})

    let data6 = (getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.labels ? getEmployeeRatingAndNormalization.labels : {})
    console.log(data5, data6, 'daTA6')


    const socreResult = {};

    const finalresult = [data5, data6];
    for (const obj of finalresult) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (!socreResult.hasOwnProperty(key)) {
            socreResult[key] = obj[key];
          } else {
            socreResult[key] += obj[key];
          }
        }
      }
    }
    let socreResult2 = [socreResult]

    // console.log(socreResult,'socreResult')

    function valueall(value) {

      let dataadd = Object.values(value).reduce((a, b) => a + b)
      return dataadd
      // console.log(value,dataadd)
    }


    // let labels = getEmployeeRatingAndNormalization.labels;
    // let normlLabelCount = getEmployeeRatingAndNormalization.normlLabelCount;

    var labelsListScore = data5 ? Object.keys(data5).map((key) => [key, data6[key], data5[key]]) : [];

    console.log("labelsListScore----->", labelsListScore);



    return (
      <>
        <LoadingOverlay
          active={loading}
          spinner
          text='Loading Data...'
          className="loader"
        >


          <div className='bg-[#F9FAFB] overflow-hidden h-screen'>

            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              SurveyList={SurveyList}
              ChooseSurvey={this.ChooseSurvey}
              CurrentSurveyId={CurrentSurveyId}
              props={this.props}
              onOverviewFromHeader={this.onOverviewFromHeader}
              getSurveyData={getSurveyData}
              companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}
            />

            <div className="px-4  flex  justify-between items-center bg-white border-b py-2.5">
              <div className="flex items-center space-x-4">
                <h2 className="xl:text-lg text-base text-[#3D405B] truncate font-medium">
                  {" "}
                  Appraisal HR - Q3
                </h2>
                <div className="flex items-center px-4 py-2 space-x-2 text-sm rounded-md w-fit bg-green-50">
                  {" "}
                  <span className="font-medium text-green-500">Completed</span>
                </div>
              </div>
              <div>
                <span className="text-[#212121]/80">01-Jan-2023 - 01-Apr-2023</span>
              </div>
            </div>

            <div className="flex w-full" style={{ height: 'calc(100% - 6.5rem)' }}>

              <Sidebar
                handleMainTabClick={this.handleMainTabClick}
                mainTab={this.state.mainTab}
              />

              {this.state.mainTab === "AppraisalCycle" ?
                <AppraisalCycle
                  handleAppraisalStatusFilter={this.handleAppraisalStatusFilter}
                  appraisalCycleId={this.state.appraisalCycleId}
                />
                : null}


              {this.state.mainTab === "Normalization" ?
                <>
                  <Normalization
                    appraisalCycleId={this.state.appraisalCycleId}
                  />
                </>
                : null}


            </div>
          </div>



        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, questions, survey, general, pms } = state;
  return {
    loggingIn,
    users,
    dashboard,
    questions,
    survey,
    general,
    pms
  };
}
export default connect(mapStateToProps)(Dashboard);