import { React, useState } from "react";

export default function Sidebar() {

    const [activeTab, setActiveTab] = useState("Dashboard");

    const handleTabClick = (tab) => {
        // Set the active tab based on the clicked tab
        setActiveTab(tab);
    };

    return (


        <div
            className="cursor-pointer transition-width w-64 lg:block scollar-xs  lg:relative fixed top-0 left-0  h-screen lg:z-20 z-20 overflow-y-auto overflow-hidden  duration-200 transform bg-[#212121] border-r customscroll4 hidden"
            style={{ height: "calc(100% - 0rem)" }}
        >
            <div className="my-6 whitespace-nowrap ">
                <div
                    data-tip="Dashboard"
                    onClick={() => handleTabClick("Dashboard")}
                    className={activeTab === "Dashboard" ? "flex items-center text-[#fff] cursor-pointer relative    bg-[#009EA7]" : "flex items-center bg-transparent  text-[#fff] cursor-pointer relative"}
                    currentitem="false"
                >
                    <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
                        <span className="p-2 material-symbols-outlined">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                <path d="M15 16.25V2.5L25 7.5L15 12.5" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M25.6872 12.7883C26.3524 14.8385 26.4146 17.0366 25.8665 19.1211C25.3184 21.2056 24.183 23.0888 22.5954 24.5466C21.0078 26.0045 19.0348 26.9755 16.9112 27.3443C14.7876 27.7131 12.6028 27.4641 10.6166 26.6269C8.63047 25.7897 6.92662 24.3996 5.70779 22.6219C4.48895 20.8442 3.80644 18.7538 3.74145 16.5994C3.67645 14.445 4.23172 12.3173 5.34118 10.4694C6.45065 8.62148 8.0676 7.13117 9.99968 6.17578" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.99907 12.5C9.37271 13.3338 8.96492 14.311 8.81288 15.3427C8.66084 16.3744 8.76936 17.4278 9.12858 18.4068C9.48779 19.3858 10.0863 20.2594 10.8695 20.948C11.6527 21.6365 12.5958 22.1181 13.6127 22.349C14.6297 22.5798 15.6883 22.5525 16.692 22.2695C17.6957 21.9865 18.6127 21.4569 19.3593 20.7289C20.106 20.0008 20.6586 19.0975 20.9669 18.1013C21.2751 17.105 21.3291 16.0475 21.1241 15.025" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                    </div>
                    <span className="text-sm  ">Appraisal Cycle</span>
                    {activeTab === "Dashboard" ? <svg xmlns="http://www.w3.org/2000/svg" className="absolute top-2 right-0  " width="18" height="30" viewBox="0 0 18 30" fill="none">
                        <path d="M0 14.9998L18 0.277344V29.7222L0 14.9998Z" fill="#FEFEFE" />
                    </svg> : null}
                </div>

                {/* <div
                    data-tip="Dashboard"
                    onClick={() => handleTabClick("FinalApproval")}
                    className={activeTab === "FinalApproval" ? "flex items-center text-[#fff] cursor-pointer relative    bg-[#009EA7]" : "flex items-center bg-transparent  text-[#fff] cursor-pointer relative"}
                    currentitem="false"
                >
                    <div className="flex items-center px-4 py-4 w-16   h-12 justify-center">
                        <span className="p-2 material-symbols-outlined">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-list-check" width="30" height="30" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M3.5 5.5l1.5 1.5l2.5 -2.5"></path>
                                <path d="M3.5 11.5l1.5 1.5l2.5 -2.5"></path>
                                <path d="M3.5 17.5l1.5 1.5l2.5 -2.5"></path>
                                <path d="M11 6l9 0"></path>
                                <path d="M11 12l9 0"></path>
                                <path d="M11 18l9 0"></path>
                            </svg>
                        </span>
                    </div>
                    <span className="text-sm  ">Final Approval</span>
                    {activeTab === "FinalApproval" ? <svg xmlns="http://www.w3.org/2000/svg" className="absolute top-2 right-0  " width="18" height="30" viewBox="0 0 18 30" fill="none">
                        <path d="M0 14.9998L18 0.277344V29.7222L0 14.9998Z" fill="#FEFEFE" />
                    </svg> : null}
                </div> */}



            </div>
        </div>

    );


}