
import { authHeader } from '../_helpers';
import { CONST } from '../_config';
// let backendUrl = JSON.parse(localStorage.getItem('backendUrl'));

export const campaignService = {
    disableCampaign,
    getCampaignById,
    deleteCampaign,
    logout,
    createNewCampaign,
    createAsessmentCampaign,
    createCampaignCustomer,
    create360Campaign,
    updateNewCampaign,
    getAllCampaign,
    getEmployeeList,
    saveEmployeeList,
    getAllCampaignType,
    getCampaignListBySurveyID,
    getCampaignEmployeesByID,
    getCampaignCustomersByID,
    getCampaignReponseNumberByID,
    getCampaignCustomerReponseNumberByID,
    setExpireCampaign,
    resendCampain,
    responseAnalysisByCampaignId,
    responseCustomerAnalysisByCampaignId,
    response360AnalysisByCampaignId,
    resendMailInBulk,
    resendCustomerMailInBulk,
    resend360MailInBulk,
    getReminderHistoryByCamapaignAndCompanyId,



    getAllEmployees,
    sendAppSurveyNotify,
    getCampaignEmployeesByKeyWord,
    getCampaignActiveHistory,
    getCampaign360EmployeesByID,
    getCampaignDetailsById,
    updateReminderActiveStatus,
    getCampaignActiveLaunchDateWise,
    saveCampaignTemplate,
    getCampaignTemplateList
};



function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
}


function handleResponse(response) {
    // //////console.log("response22222   ", response);
 
     return response.text().then(text => {
         const data = text && JSON.parse(text);
         if (!response.ok) {
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logoutSession();
                 //location.reload(true);
             }
             // //////console.log("datadatadata ", response);
 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         ////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             // //////console.log("datadatadatadatadata   ", data);
             if (data.code===3) {
                 logoutSession();
             }
             if(data && data.message && (data.message === "UnauthorizedError")){
                logout()

             }
//              else if (data.code===1) {
//                 logoutSession();
//             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 
 

function handleResponse2(response) {
    // //////console.log("response22222   ", response);
 
     return response.text().then(text => {
         const data = text && JSON.parse(text);
         if (!response.ok) {
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logout();
                 //location.reload(true);
             }
             // //////console.log("datadatadata ", response);
 
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         ////////console.log("datadatadatadatadata   ", data.error);
         if (data.error) {
             // //////console.log("datadatadatadatadata   ", data);
             if (data.code===3) {
                 logout();
             }
             const error = (data && data.message) || response.statusText;
             return Promise.reject(error);
         }
         return data;
     });
 }
 



function logoutSession(data) {

    let header = new Headers({
            'Content-Type': 'application/json',
            'Authorization': authHeader().Authorization
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/userlogout`, requestOptions)
            .then(handleResponse2)
            .then(user => {
                let userObj = {
                    userLogout: user
                }
                localStorage.removeItem('adminuser');
                window.location.href = "#/login";
                return userObj;
            });
}






async function getAllCampaign(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                listOfCampaigns: data.data
            }
            //console.log();
            return campaignObj;
        });
}
async function createNewCampaign(data) {
    //////console.log("------createNewCampaign------------ ",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createCampaign`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                createNewCampaign: data.data
            }
            //////console.log("After API: ",data.data);

            return campaignObj;
        });
}



async function createAsessmentCampaign(data) {
    //////console.log("------createAsessmentCampaign------------ ",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createAsessmentCampaign`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                createAsessmentCampaign: data.data
            }
            //////console.log("After API: ",data.data);

            return campaignObj;
        });
}


async function createCampaignCustomer(data) {
    //////console.log("------createCampaignCustomer------------ ",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createCampaignCustomer`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                createCampaignCustomer: data.data
            }
            //////console.log("After API: ",data.data);

            return campaignObj;
        });
}

async function create360Campaign(data) {
    //////console.log("------create360Campaign------------ ",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/create360Campaign`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                createNewCampaign: data.data
            }
            //////console.log("After API: ",data.data);

            return campaignObj;
        });
}



async function setExpireCampaign(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/setExpireCampaign`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                createNewCampaign: data.data
            }

            return campaignObj;
        });
}
async function resendCampain(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/resendCampain`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                createNewCampaign: data.data
            }

            return campaignObj;
        });
}
async function updateNewCampaign(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateCampaign`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                updateNewCampaign: data.data
            }
            //console.log(campaignObj);

            return campaignObj;
        });
}
async function deleteCampaign(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deleteCampaign`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                deleteCampaign: data.data
            }
            //console.log();

            return campaignObj;
        });
}
async function disableCampaign(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateCampaignStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                deleteCampaign: data.data
            }
            //console.log();

            return campaignObj;
        });
}
async function getCampaignById(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignById`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                campaigndetails: data.data
            }
            //console.log();

            return userObj;
        });
}
async function saveEmployeeList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/saveEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                createNewCampaign: data.data
            }
            //console.log();

            return campaignObj;
        });
}
async function getEmployeeList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                employeeList: data.data
            }
            //console.log();

            return campaignObj;
        });
}


async function getAllCampaignType(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getAllCampaignType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                campaignTypeList: data.data
            }
            //////console.log("campaignObj  ", campaignObj);

            return campaignObj;
        });
}

async function getCampaignListBySurveyID(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignListBySurveyID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                campaignList: data.data
            }
            //////console.log("campaignObj  ", campaignObj);

            return campaignObj;
        });
}

async function getCampaignEmployeesByID(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignEmployeesByID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                getCampaignEmployeesByIDData: data.data
            }

            return campaignObj;
        });
}



async function getCampaignCustomersByID(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignCustomersByID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                getCampaignCustomersByIDData: data.data
            }

            return campaignObj;
        });
}



async function getCampaignEmployeesByKeyWord(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignEmployeesByKeyWord`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                getCampaignEmployeesByIDData: data.data
            }

            return campaignObj;
        });
}

async function getCampaignReponseNumberByID(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignReponseNumberByID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                getCampaignReponseNumberByIDData: data.data
            }

            return campaignObj;
        });
}


async function getCampaignCustomerReponseNumberByID(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignCustomerReponseNumberByID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                getCampaignReponseNumberByIDData: data.data
            }

            return campaignObj;
        });
}


async function responseAnalysisByCampaignId(data) {
    //////console.log("response data 2222 :",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/responseAnalysisByCampaignId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                responseAnalysisByCampaignIdData: data.data
            }
            //////console.log("campaignObj  ", campaignObj);

            return campaignObj;
        });
}

async function responseCustomerAnalysisByCampaignId(data) {
    //////console.log("response data 2222 :",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/responseCustomerAnalysisByCampaignId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                responseAnalysisByCampaignIdData: data.data
            }
            //////console.log("campaignObj  ", campaignObj);

            return campaignObj;
        });
}






async function response360AnalysisByCampaignId(data) {
    //////console.log("response data 2222 :",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/response360AnalysisByCampaignId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                responseAnalysisByCampaignIdData: data.data
            }

            return campaignObj;
        });
}






async function resendMailInBulk(data) {
    //////console.log("response resendMailInBulk 2222 :",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/resendMailInBulk`, requestOptions)
        .then(handleResponse)
        .then(data => {

                // const items = data.data
                // const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
                // const header = Object.keys(items[0])
                // let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
                // csv.unshift(header.join(','))
                // csv = csv.join('\r\n')
                
                // //console.log(csv)



            let campaignObj = {
                responseAnalyticsData: data.data
            }
            //////console.log("campaignObj  ", campaignObj);

            return campaignObj;
        });
}


async function resendCustomerMailInBulk(data) {
    //////console.log("response resendCustomerMailInBulk 2222 :",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/resendCustomerMailInBulk`, requestOptions)
        .then(handleResponse)
        .then(data => {

                // const items = data.data
                // const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
                // const header = Object.keys(items[0])
                // let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
                // csv.unshift(header.join(','))
                // csv = csv.join('\r\n')
                
                // //console.log(csv)



            let campaignObj = {
                responseAnalyticsData: data.data
            }
            //////console.log("campaignObj  ", campaignObj);

            return campaignObj;
        });
}




async function resend360MailInBulk(data) {
    //////console.log("response resend360MailInBulk 2222 :",data)
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/resend360MailInBulk`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                responseAnalyticsData: data.data
            }

            return campaignObj;
        });
}



async function getReminderHistoryByCamapaignAndCompanyId(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getReminderHistoryByCamapaignAndCompanyId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                getReminderHistoryData: data.data
            }
            //console.log();
            return campaignObj;
        });
}

// async function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if (response.status === 401) {
//                 logout();
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         if (data.error) {
//             if (data.code === 3) {
//                 logout();
//             }
//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }
//         return data;
//     });
// }





async function getAllEmployees(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                allEmployeesList: data.data
            }

            return companyObj;
        });
}



async function sendAppSurveyNotify(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/sendAppSurveyNotify`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                sendAppSurveyNotifyData: data.data
            }

            return companyObj;
        });
}



async function getCampaignActiveHistory(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignActiveHistory`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                getCampaignActiveHistoryData: data.data
            }

            return companyObj;
        });
}





async function getCampaign360EmployeesByID(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaign360EmployeesByID`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                getCampaign360EmployeesByIDData: data.data
            }

            return companyObj;
        });
}



async function getCampaignDetailsById(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignDetailsById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                getCampaignDetailsByIdData: data.data
            }

            return companyObj;
        });
}


async function updateReminderActiveStatus(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateReminderActiveStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                updateReminderActiveStatusData: data.data
            }

            return companyObj;
        });
}

async function getCampaignActiveLaunchDateWise(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignActiveLaunchDateWise`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                getCampaignActiveLaunchDateWiseData: data.data
            }

            return companyObj;
        });
}


async function saveCampaignTemplate(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/saveCampaignTemplateDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                saveCampaignTemplateData: data.data
            }

            return companyObj;
        });
}

async function getCampaignTemplateList(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getCampaignTemplateList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                getCampaignTemplateListData: data.data
            }

            return companyObj;
        });
}







