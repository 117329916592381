import React, { Component } from 'react';
import { connect } from 'react-redux';

import MeanScore from './Components/MeanScore';
import Filter from './Filter';
import StackChart from './Components/StackChart';
import IndexMeanOverall from './Components/IndexMeanOverall/IndexMeanOverall';
import IndexMeanDemographicTable from './Components/IndexMeanDemographicTable/IndexMeanDemographicTable';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName:'none',
      viewScore:true,
      showLeaderBoard:false
    }
  }
  componentDidMount() {

  }


  handleFilterSave=(name)=>{
    this.setState({currFilterName:name})
  }

  render() {
    let { cardType, ItemSaveData,OverviewList, EmpFilterData,getMeanScoreQwiseData, handleDemographicFilter, getMeanScoreData } = this.props;

    let { currFilterName  } = this.state;


    let getMeanScoreQwiseDataUpdate = []
    if(getMeanScoreQwiseData && getMeanScoreQwiseData.length>0){
      getMeanScoreQwiseData.forEach((item)=>{
        if(cardType === 'overall'){
          getMeanScoreQwiseDataUpdate.push(item);
        }
        else if(cardType === 'index'){
          if(item.parameterBucketName === (ItemSaveData && ItemSaveData.name?ItemSaveData.name:"")){
            getMeanScoreQwiseDataUpdate.push(item);
          }
        }
      })
    }

    console.log('getMeanScoreData:-------------------->',getMeanScoreData)
    
    // companyId: "5f9fc1320f72cb05d7a32f2d"
    // createdAt: 1617100368242
    // dmg: "Department"
    // dmgName: "IBMD"
    // id: "6051ba11fb756f7b66c9c528"
    // score: 4.907444668008048
    // surveyId: "5fa04d316ede0f0336066cce"

    console.log('getMeanScoreQwiseData:-------------------->',getMeanScoreQwiseData)

    console.log('currFilterName:',currFilterName);

    let dmgCountList = [];

    if(getMeanScoreQwiseDataUpdate && getMeanScoreQwiseDataUpdate.length>0){
      getMeanScoreQwiseDataUpdate.forEach((ques)=>{

        if(ques.dmgName){


            if(dmgCountList.findIndex(prev=>prev.dmgName === ques.dmgName)===-1){
              dmgCountList.push({
                dmgName:ques.dmgName,
                count:1
              });
            }
            else{
              let getIndex = dmgCountList.findIndex(prev=>prev.dmgName === ques.dmgName);
              dmgCountList[getIndex]["count"] += 1;
            }


      }




    })}


    let NewData = [];

    if(getMeanScoreQwiseDataUpdate && getMeanScoreQwiseDataUpdate.length>0){
      getMeanScoreQwiseDataUpdate.forEach((ques,index)=>{
        
        if(ques.dmgName){

       

        if(NewData.findIndex(prev=>prev.dmgName === ques.dmgName)===-1){
          NewData.push({
            ...ques,
            mean:ques.score
          });
        }
        else{
          let getIndex = NewData.findIndex(prev=>prev.dmgName === ques.dmgName);
          NewData[getIndex]["mean"]+=ques.score;
        }



      }



      })
    }


    if(NewData && NewData.length>0){
      NewData.forEach((item, index)=>{
        let getIndex = dmgCountList.findIndex(prev=>prev.dmgName === item.dmgName);
        if(getIndex!==-1){
          NewData[index]["mean"] = NewData[index]["mean"]/ dmgCountList[getIndex].count;
          NewData[index]["score"] = NewData[index]["mean"];
        } 
      })
    }

    // if(index === (getMeanScoreQwiseData.length-1)){
    //   if()
    //   NewData[getIndex]["mean"]+=ques.score;
    // }

    console.log('getMeanScoreQwiseDataUpdate:',getMeanScoreQwiseDataUpdate);
    console.log('NewData:',NewData);
    console.log('dmgCountList:',dmgCountList);

    // companyId: "5f9fc1320f72cb05d7a32f2d"
    // createdAt: 1617100298159
    // dmg: "Department"
    // dmgName: "RESOURCE POOL"
    // id: "60534e01fb756f7b66ce1985"
    // parameterBucketId: "5f7d92284d2c4345f9fb9353"
    // parameterBucketName: "HOPE Index"
    // questionId: "5fa0f1a06ede0f0336066ec8"
    // questionName: "I am happy the way effective actions were taken after the merger of two organizations"
    // score: 4.666666666666667
    // surveyId: "5fa04d316ede0f0336066cce"

    let MeanScoreDataList = NewData;
    // if(NewData && NewData.length>0){
    //   NewData.forEach((item)=>{
    //     if(cardType === 'overall'){
    //       MeanScoreDataList.push(item);
    //     }
    //     else if(cardType === 'index'){
    //       if(item.parameterBucketName === (ItemSaveData && ItemSaveData.name?ItemSaveData.name:"")){
    //         MeanScoreDataList.push(item);
    //       }
    //     }
    //   })
    // }

    return (
      <>
          <div className="xl:flex lg:flex md:flex justify-end items-center">

                    <Filter 
                    EmpFilterData = {EmpFilterData}
        
                    handleDemographicFilter={handleDemographicFilter}
        
                    handleFilterSave={this.handleFilterSave}
        
                  />  
     

          </div>
                  <div className="">
                  <div className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 xl:grid-cols-1  gap-2">

                  {cardType === 'overall'?
                  <>
                  {currFilterName==="none"?
                      <IndexMeanOverall 
                      OverviewList={OverviewList}

                      ItemSaveData ={ItemSaveData}
                      getMeanScoreData={MeanScoreDataList}
                      currFilterName={currFilterName}
                      />
                    :
                    <div>
                      <IndexMeanDemographicTable 
                        getMeanScoreQwiseData={getMeanScoreQwiseData}
                        OverviewList={OverviewList}
                        currFilterName={currFilterName}
                      />
                    </div>
                  }


                    </>
                    :
                    <div className="bg-white rounded w-full border px-4 justify-center">
                    <div className="px-1 py-4 text-base text-gray-700 font-semibold flex justify-between items-center">
                      <h1 className="py-4">
                      <p className="text-base text-gray-700 font-semibold">Overall Mean Score <span className="text-blue-500">{(currFilterName!=='none')?"( "+currFilterName+" wise )":""}</span></p> 
                      </h1> 
                    </div>

                    <StackChart 
                      ItemSaveData ={ItemSaveData}
                      getMeanScoreData={MeanScoreDataList}
                      currFilterName={currFilterName}
                    />

                  </div>
                    }

                  </div>
                </div>
                
                

                





      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
