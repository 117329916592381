import React, { Component } from 'react';
import { connect } from 'react-redux';
import CrossTab from './Components/CrossTab/CrossTab';


class AdvanceComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 4,
      DimensionList: [
        {
          index: 1,
          name: 'Happiness',
          cutOff: 0
        },
        {
          index: 2,
          name: 'Engagement',
          cutOff: 0

        },
        {
          index: 4,
          name: 'Stress',
          cutOff: 0
        },
        {
          index: 5,
          name: 'Manager',
          cutOff: 0

        },
        {
          index: 6,
          name: 'Leader',
          cutOff: 0

        }
      ],
    }
  }
  componentDidMount() {

  }

  onOptionType = (val) => {
    let { onCrossTab } = this.props;

    this.setState({ optionType: val })
    if (val === 4) {
      onCrossTab()
    }
  }

  onSwitchOptionScreen = () => {
    let {
      indexType, EmpFilterData, getIndexFilterData, getCommentData, loading, questionsList,

      crossTabAnalysisData,
      handleCrossTabApi,
      crossTabData,
      getIndexByFilterDMGData,
      getEMPFilterLabel,
      crossTabError,

    } = this.props;

    let { optionType } = this.state;

    switch (optionType) {

      case 4: return <CrossTab getIndexByFilterDMGData={getIndexByFilterDMGData}
        indexType={indexType}
        optionType={optionType}
        getCommentData={getCommentData}
        questionsList={questionsList}
        handleCrossTabApi={handleCrossTabApi}
        crossTabData={crossTabData}
        EmpFilterData={EmpFilterData}
        getIndexFilterData={getIndexFilterData}
        crossTabAnalysisData={crossTabAnalysisData}

        getEMPFilterLabel={getEMPFilterLabel}
        crossTabError={crossTabError}
      />


      default: return <div>Default Option</div>
    }
  }

  render() {
    let { optionType } = this.state;



    return (
      <>

<div className="md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto overflow-hidden bg-[#f9fafb] h-[calc(100vh-7rem)]">
<div className="flex items-center justify-between text-gray-800">
          <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B] pb-2   ">{"Advance Insight"} </h1>  
          
          {/* <span className="material-symbols-outlined cursor-pointer lg:hidden flex">menu_open</span> */}
          </div>
          {/*sub nav bar 1 */}
          <div className="items-center justify-between border-b md:flex">
            <div className="flex space-x-2 overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 whitespace-nowrap tab-scroll">

              <span onClick={() => this.onOptionType(4)} className={optionType === 4 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}> CrossTab </span>
            </div>
          </div>

          {this.onSwitchOptionScreen()}

        </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AdvanceComparision);
