import React from "react";
export default function CompleteModal(props) {
  let { submitAppNotification, SendSurveyNotify, notifyFormData, handleNotifyInput } = props;
  return (
   <>
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">
         
          <div className="shadow w-full rounded-lg bg-white overflow-hidden w-full block">

          <div className="flex justify-between items-center w-full border-b p-4 px-6">
              <h2 className="font-medium text-xl   text-gray-800 ">App Notification</h2>
              <div onClick={()=>SendSurveyNotify(false)} className=" rounded-full p-2  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>
          </div>
            
            {/* <p className=" text-base mb-6 text-gray-700 border-b pb-2 text-center"></p> */}
<div className="py-4 p-6 space-y-2">
  
<div className="">
              <input type="text" name="title" handleNotifyInput value={notifyFormData && notifyFormData.title?notifyFormData.title:""} onChange={handleNotifyInput} placeholder="Title" className="p-2 w-full border border-gray-300 outline-none rounded-md" />
            </div>

            <div className="">
              <input type="text" name="message" onChange={handleNotifyInput} value={notifyFormData && notifyFormData.message?notifyFormData.message:""}  placeholder="Message" className="p-2 w-full border border-gray-300 outline-none rounded-md" />
            </div>

            <div className=" text-center pt-4">
              <button onClick={()=>submitAppNotification()} type="button" className="w-full bg-blue-500 hover:bg-opacity-90 transition duration-150 text-white font-medium py-2 px-6  rounded-md uppercase" >
                Send Notification
              </button> 
            </div>
</div>

          </div>
        </div>
        </div>
   </>
  );
}
