import {
  growConstants
} from '../_constants';

export function grow(state = {}, action) {

  switch (action.type) {
    case growConstants.START_GROW_GETALL_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case growConstants.START_GROW_GETALL_SUCCESS:
      return {
        ...state,
        // loading: false

      };
    case growConstants.START_GROW_GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


      case growConstants.GET_GROWDAYWEEK_REQUEST:
        return {
          ...state,
          // loading: true
        };
      case growConstants.GET_GROWDAYWEEK_SUCCESS:
        return {
          ...state,
          submitGrowWeek: action.grow.submitGrowWeek
  
        };
      case growConstants.GET_GROWDAYWEEK_FAILURE:
        return {
          ...state,
          error: action.error,
          // loading: false
        };
  



      case growConstants.DASHBOARD_GROWNOTE_REQUEST:
        return {
          ...state,
          // loading: true
        };
      case growConstants.DASHBOARD_GROWNOTE_SUCCESS:
        return {
          ...state,
          getAllGrowNote: action.grow.getAllGrowNote,
  
        };
      case growConstants.DASHBOARD_GROWNOTE_FAILURE:
        return {
          ...state,
          error: action.error,
          // loading: false
        };
  
  


      case growConstants.GET_REFLECTION_DAY_REQUEST:
        return {
          ...state,
          // loading: true
        };
      case growConstants.GET_REFLECTION_DAY_SUCCESS:
        return {
          ...state,
          startGrowDayReflection: action.grow.startGrowDayReflection,
  
        };
      case growConstants.GET_REFLECTION_DAY_FAILURE:
        return {
          ...state,
          error: action.error,
          // loading: false
        };
  

    case growConstants.GET_GROWDAY_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case growConstants.GET_GROWDAY_SUCCESS:
      return {
        ...state,
        // loading: false

      };
    case growConstants.GET_GROWDAY_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


      case growConstants.GET_GROWWEEK_REQUEST:
        return {
          ...state,
          // loading: true
        };
      case growConstants.GET_GROWWEEK_SUCCESS:
        return {
          ...state,
          // loading: false
  
        };
      case growConstants.GET_GROWWEEK_FAILURE:
        return {
          ...state,
          error: action.error,
          // loading: false
        };
  

    case growConstants.GET_GROWDAY_DAY_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case growConstants.GET_GROWDAY_DAY_SUCCESS:
      return {
        ...state,


        getGrowDayDatabyDayId: action.grow.getGrowDayDatabyDayId,



        // loading: false

      };
    case growConstants.GET_GROWDAY_DAY_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };




    case growConstants.GET_GROW_DAY_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case growConstants.GET_GROW_DAY_SUCCESS:
      return {
        ...state,

        // makeDailyHappinessSuccess: false,
        getGrowDayListbyWeekId: action.grow.getGrowDayListbyWeekId,
        // getEmployeeTrackerSuccess: false,
        // startActivitySuccess: false,
        // getExploreTrackSuccess: false,
        // loading: false

      };
    case growConstants.GET_GROW_DAY_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };

    case growConstants.GET_WEEK_MONTH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case growConstants.GET_WEEK_MONTH_SUCCESS:
      return {
        ...state,
        // getWeekListbyMonthId: true,
        loading: false,
        getWeekListbyMonthId: action.grow.getGrowWeekListbyMonthId
      };
    case growConstants.GET_WEEK_MONTH_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };


    case growConstants.GET_MONTH_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case growConstants.GET_MONTH_SUCCESS:
      return {
        ...state,

        makeDailyHappinessSuccess: false,
        getMonth: action.grow.getMonth,
        getEmployeeTrackerSuccess: false,
        startActivitySuccess: false,
        getExploreTrackSuccess: false,
        // loading: false

      };
    case growConstants.GET_MONTH_FAILURE:
      return {
        ...state,
        error: action.error,
        // loading: false
      };


    case growConstants.START_MONTH_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case growConstants.START_MONTH_SUCCESS:
      return {
        ...state,

        makeDailyHappinessSuccess: false,
        startGrowMonth: action.grow.startGrowMonth,
        getEmployeeTrackerSuccess: false,
        startActivitySuccess: false,
        getExploreTrackSuccess: false,
        // loading: false

      };
    case growConstants.START_MONTH_FAILURE:
      return {
        ...state,
        error: action.error
      };


      case growConstants.DASHBOARD_SUBMITGROW_REQUEST:
        return {
          ...state,
          // loading: true
        };
      case growConstants.DASHBOARD_SUBMITGROW_SUCCESS:
        return {
          ...state,
  
          submitGrowDaySuccess: true,
          submitGrowDayData: action.grow.submitGrowDay
          // loading: false
  
        };
      case growConstants.DASHBOARD_SUBMITGROW_FAILURE:
        return {
          ...state,
          error: action.error
        };
  

        case growConstants.UPDATE_EMPLOYEE_TRACKER_REQUEST:
          return {
            ...state,
            loading: true
          };
        case growConstants.UPDATE_EMPLOYEE_TRACKER_SUCCESS:
          return {
            ...state,
            updateEmployeeTrackerSuccess: false,
            startActivitySuccess: false,
            taskStatus: true
    
          };
        case growConstants.UPDATE_EMPLOYEE_TRACKER_FAILURE:
          return {
            ...state,
            error: action.error
          };


          case growConstants.SUB_ACT_REQUEST:
            return {
              ...state,
              loading: true
            };
          case growConstants.SUB_ACT_SUCCESS:
            return {
              ...state,
      
              getSubactivityActivityId: action.grow.getSubactivityActivityId,
      
            };
          case growConstants.SUB_ACT_FAILURE:
            return {
              ...state,
              error: action.error
            };


            case growConstants.UPDATE_COMMENT_REQUEST:
              return {
                ...state,
                loading: true
              };
            case growConstants.UPDATE_COMMENT_SUCCESS:
              return {
                ...state,
                updatecommentSuccess:true,
                updatecomment: action.grow.updatecomment
        
              };
            case growConstants.UPDATE_COMMENT_FAILURE:
              return {
                ...state,
                error: action.error
              };
  
  

          
    default:
      return state
  }
}