import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';

class QuestionScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { question, totalCount } = this.props;


    // //console.log('getQuestionFilterData:--ss-x> ',getQuestionFilterData)


    // let filterQuestion = {}
    // if(question && getQuestionFilterData && getQuestionFilterData.length>0){
    //     getQuestionFilterData.forEach((quesFilter)=>{
    //         if(quesFilter.name === question.name){
    //             filterQuestion = quesFilter;
    //         }
    //     })
    // }


    // let total = 0
    let names = []
    let weightage1 = [];
    let weightage2 = [];
    // let weightage3 = [];
    // let weightage4 = [];
    // let weightage5 = [];
    // let weightage6 = [];


    // let totalCount = 1128;

    // if(question && question.totalCount){
    //   totalCount = question.totalCount;
    // }

    if (question) {
      names.push({
        value: "Overall",
        textStyle: { fontWeight: 'bold' }
      })


      let count1 = question && question.count ? question.count : 0
      // let total1 = question && question.total?question.total:0
      let total1 = totalCount ? totalCount : 0

      let percentageValue = ((count1 * 100) / total1);
      let percentageOffSet = (100 - ((count1 * 100) / total1));


      weightage1.push({
        value: percentageValue,
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#62cff4'
            },
            {
              offset: 1,
              color: '#2c67f2'
            }])

        }
      });
      weightage2.push({
        value: percentageOffSet,
        itemStyle: { color: '#ffffff' }

      });
      //console.log(' percentageValue',percentageValue,' percentageOffSet',percentageOffSet,' ',(percentageValue+percentageOffSet))
    }



    // //console.log('overallQuestion:---> ',question)
    // //console.log('filterQuestion:---> ',filterQuestion)

    // if(currFilterValue !=='none'){
    //     if(currFilterName !=='none'){
    //         total = filterQuestion["1"] + filterQuestion["2"] + filterQuestion["3"] + filterQuestion["4"] + filterQuestion["5"] + filterQuestion["6"];
    //         names.push({
    //             value:currFilterValue,
    //             textStyle:{fontWeight:'bold'}
    //         })
    //         weightage1.push({
    //             value:(parseFloat(filterQuestion["1"])*100/total).toFixed(2),
    //             itemStyle: {color: '#e60000'}
    //         });
    //         weightage2.push({
    //             value:(parseFloat(filterQuestion["2"])*100/total).toFixed(2),
    //             itemStyle: {color: '#ff3333'}
    //         });
    //         weightage3.push({
    //             value:(parseFloat(filterQuestion["3"])*100/total).toFixed(2),
    //             itemStyle: {color: '#ff8080'}
    //         });
    //         weightage4.push({
    //             value:(parseFloat(filterQuestion["4"])*100/total).toFixed(2),
    //             itemStyle: {color: '#6ad4fb'}

    //         });
    //         weightage5.push({
    //             value:(parseFloat(filterQuestion["5"])*100/total).toFixed(2),
    //             itemStyle: {color: '#1fbff9'}
    //         });
    //         weightage6.push({
    //             value:(parseFloat(filterQuestion["6"])*100/total).toFixed(2),
    //             itemStyle: {color: '#06a6e0'}
    //         });
    //     }
    // }



    return (
 
        <div style={{ zIndex: '10' }}>

          {true ?
            <ReactEcharts
              style={{ height: '95px', marginTop: "-55px", }}

              option={{
                // tooltip: {
                //     trigger: 'axis',
                //     axisPointer: {            
                //         type: 'shadow'        
                //     },
                //     formatter:function(a,b) {
                //         //console.log('a,b--->',a,b)
                //     }
                // },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: {
                  type: 'value',
                  axisLine: 'left',
                  splitLine: {
                    lineStyle: {
                      color: '#fff'
                    }
                  },
                  axisLabel: {
                    show: false
                  }

                },
                yAxis: {
                  type: 'category',
                  data: names,
                  position: 'left',
                  inverse: true,
                  axisLine: {
                    lineStyle: {
                      color: '#fff'
                    }
                  },
                  axisLabel: {
                    show: false
                  }
                },
                axisLabel: {
                  color: "#000",
                  interval: 0,
                  formatter: function (value) {
                    if (value.length > 20) {
                      return value.substring(0, 20) + ".. :";
                    } else {
                      return value + ":";
                    }
                  }
                },
                series: [
                  {
                    // name: 'Strongly Disagree',
                    type: 'bar',
                    stack: '总量',
                    barWidth: '30px',
                    label: {
                      show: true,
                      color: "#ffffff",
                      position: 'insideRight',
                      formatter: function (d) {
                        return parseFloat(d.value).toFixed(2) + ' %';
                      }
                    },
                    data: weightage1,
                    inverse: true
                  },
                  {
                    // name: 'Disagree',
                    type: 'bar',
                    stack: '总量',
                    barWidth: '30px',
                    label: {
                      show: false,
                      position: 'insideRight',
                      formatter: function (d) {
                        return ''
                      }
                    },
                    data: weightage2,
                    inverse: true
                  },

                ]
              }}
            />
            : null}

        </div>
     
    );
  }
}
function mapStateToProps(state) {
  //   //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionScore);
