import React from 'react';

export default function Component(props) {

const { question, answersList,handleRadio } = props;

return(
    <>
        <div className="flex grid md:grid-cols-5 grid-cols-5 gap-2 w-96 px-2 justify-left items-left pt-4">
          {question && question.optionsList && question.optionsList.length>0?
            question.optionsList.map((option, optionindex) =>
          ((answersList && answersList.length > 0 && answersList.findIndex(item => item.questionId === question.id) !== -1) ?
            answersList[answersList.findIndex(item => item.questionId === question.id)].answer: '')?
                (option.name<=answersList[answersList.findIndex(item => item.questionId === question.id)].answer)?
                <div onClick={() => handleRadio(optionindex, question, option)}  className="px-2 cursor-pointer">
                  <img src="img/questiontype/star/star.png" style={{width:"40px",height:'40px'}} alt="star"/>
                </div>
                :
                <div onClick={() => handleRadio(optionindex, question, option)}  className="px-2 cursor-pointer">
                  <img src="img/questiontype/star/star-outline.png" style={{width:"40px",height:'40px'}} alt="emptystar"/>
                </div>

              :
              <div onClick={() => handleRadio(optionindex, question, option)}  className="px-2 cursor-pointer">
                <img src="img/questiontype/star/star-outline.png" style={{width:"40px",height:'40px'}} alt="emptystar"/>
              </div>
          ):null}
        </div>
    </>
  );}