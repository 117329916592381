import { dataTool } from 'echarts';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment  from 'moment';

// import Actions from '../Actions/Actions';
// import Resource from '../TaskResouce/Resource/Resource';
// import Task from '../TaskResouce/Task/Task';
// import TaskResource from '../TaskResouce/TaskResource';
// import CompleteModal from './CompleteModal';

class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }

  render() {
    let { onComplete,getManagerActionPlanData, CurrentActionId, navigate, CurrentActionData,onSelectResource,
      CurrentResourceId, CurrentResourceData, CurrentDimensionData  } = this.props;

    let ResourceList = [];
    if(getManagerActionPlanData && getManagerActionPlanData.length>0){
      getManagerActionPlanData.forEach((action)=>{
        if(action && action.id===CurrentActionId){
          if(action && action.ResourceList && action.ResourceList.length>0){
            ResourceList = action.ResourceList;
          }
        }
      });
    }


    // getManagerActionPlanData,currentParameterId


        //1: start
    //2: pending
    //3: completed


    let resourceStatus = 1
    if(CurrentResourceData && CurrentResourceData.isComplete){
      resourceStatus= 2;
    }




    return (
      <> 
  <div className="w-full mt-6 capitalize lg:p-4 bg-gray-50 md:mt-0" >  
    <div className="flex items-center px-4 border-b">
      <span onClick={()=>navigate("resourcelist")} className="text-gray-500 cursor-pointer material-symbols-outlined">arrow_back_ios</span> 
      <h1 onClick={()=>navigate("resourcelist")} className="px-3 py-3 text-lg font-medium cursor-pointer ">{CurrentActionData&&CurrentActionData.name?CurrentActionData.name:""}</h1>
      <p onClick={()=>navigate("actionlist")} className="pl-2 text-xs font-medium text-gray-500 border-l cursor-pointer">{CurrentDimensionData&&CurrentDimensionData.name?CurrentDimensionData.name:""}</p> <span className="text-gray-500 material-symbols-outlined" style={{fontSize: '1rem'}}>chevron_right
      </span><p className="text-xs font-medium text-blue-500">{CurrentActionData&&CurrentActionData.name?CurrentActionData.name:""}</p>
    </div>
    {/* prog */}


    {resourceStatus===1?
      <div style={{background: '#1ca8ff'}} className="h-1.5 w-1/12"> </div>
    :null}
    {resourceStatus===2?
      <div className="h-1.5 bg-green-400 w-12/12"> </div>
    :null}


    {/* boxes */}
    <div className="flex justify-center h-full">
      {/* box 1 */}
      <div className="relative max-w-sm mt-10 ">
        <span className="px-4 py-2 text-sm font-medium text-gray-500 bg-gray-100 border rounded-md">{
        CurrentResourceData&&CurrentResourceData.number?"Resource no. "+CurrentResourceData.number:"Resource"}</span>
        <h1 className="py-8 text-sm text-2xl font-medium font-semibold normal">
         {CurrentResourceData&&CurrentResourceData.name?CurrentResourceData.name:""}
        </h1>


        <div className="p-4">
          {CurrentResourceData&&CurrentResourceData.imageLinkV2?<img src={CurrentResourceData.imageLinkV2} alt="resouce image"/>:null}
        </div>

        <div className="py-2 text-xs font-light" dangerouslySetInnerHTML={{ __html: CurrentResourceData && CurrentResourceData.desc?CurrentResourceData.desc:""}} />

        {resourceStatus===1?
          <button onClick={()=>onComplete()} className="w-full p-4 text-sm font-semibold text-white uppercase rounded-md " style={{background: '#1ca8ff'}}>mark as complete</button>
        :null}
        {resourceStatus===2?
          <button   className="flex items-center justify-center w-full p-4 text-sm font-semibold text-white uppercase bg-green-400 rounded-md">completed 
          <span className="ml-2 material-symbols-outlined">check_circle</span></button>
        :null}

          
          
          <b className="text-gray-800">
        </b></div><b className="text-gray-800">
      </b></div><b className="text-gray-800">
    </b></div><b className="text-gray-800">
  </b>


      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dimension);
