import React from "react";
export default function AddSurvey(props) {
  let {
    isRiskPulse, is360Feedback, senseOption, crrSenseOptionId, selectSenseOption,
    allLifecycleListenListData, selectedSurveyType, isModule, selectCreateType, clusterAnalysisData, isRiskSurvey, crrLifecycleStageId, selectStage, getLifecycleStageListData, handleCreateSurveyPopupClose, handleAddSurveySubmit, handleSurveyType, surveyFormData, handleAddSurveyInput, surveyValidation } = props;

  //////console.log("getLifecycleStageListData--->", getLifecycleStageListData)



  let [showFilter, handleOpenFilter] = React.useState(false);
  let [showFilter2, handleOpenFilter2] = React.useState(false);

  let [showFilter3, handleOpenFilter3] = React.useState(false);

  let [showFilter4, handleOpenFilter4] = React.useState(false);


  let LabelList = [
    "Detached", "Low Attached", "Attached", "Highly Attached"
  ]


  let newClusterList = []
  if (clusterAnalysisData && clusterAnalysisData.length > 0) {
    clusterAnalysisData.forEach((item, indexZ) => {
      newClusterList.push({
        "clusterName": LabelList[indexZ],
        "clusterNumber": item[0],
        "clusterSize": item[1],
        "clusterCenter": item[2],
        "clusterList": item[3]
      });
    })
  }




  //////console.log("getSurveyListData---------->/////", getSurveyListData)

  return (
    <>
      <div className="fixed top-0 z-40 flex items-center justify-center w-full h-full capitalize xl:h-screen" style={{ background: '#0000005c' }}>

        {false ?
          <div className="w-11/12 bg-white rounded-lg shadow-sm xl:w-5/12 lg:w-6/12 md:w-8/12 ">

            <div className="flex justify-between p-4 border-b">
              <h2 className="text-lg font-semibold text-center text-gray-800 ">Choose Lifestyle Stage</h2>
              <span onClick={() => handleCreateSurveyPopupClose()} className="
            material-symbols-outlined bg-gray-50 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150">close</span> </div>
            <div className="p-4 space-y-4">


              <div className="grid grid-cols-3 gap-4 ">
                {getLifecycleStageListData && getLifecycleStageListData.length > 0 ?
                  getLifecycleStageListData.map((item) =>
                    <>
                      <div onClick={() => selectStage(item)} title="Build your own survey from sctrach" className={crrLifecycleStageId === item.id ?
                        "border-2 flex justify-center text-center p-6 rounded-md text-gray-700    border-blue-500 cursor-pointer relative hover:bg-blue-50 transition duration-150" :
                        "border-2 flex justify-center text-center p-6 rounded-md text-gray-700 hover:text-blue-500 hover:border-blue-500 cursor-pointer hover:bg-blue-50"}
                      >


                        <div className="flex flex-col items-center " >
                          <span className="text-3xl material-symbols-outlined" >article</span>
                          <h1 className="py-2 text-sm font-medium">{item.label}</h1>

                          {surveyFormData.type === "custom" ?
                            <span className="absolute text-blue-500 material-symbols-outlined top-1 right-1" style={{ fontSize: '1.3rem' }}>check_circle</span>
                            : null}


                        </div>


                      </div>
                    </>
                  ) : null}



              </div>

              <div className="mt-8 text-center text-red-400">
                {!surveyValidation.name ?
                  <p>Please enter survey name.</p>
                  : null}
              </div>


              {/* upload details */}
              <div className="flex justify-center py-4">
                <button onClick={() => handleAddSurveySubmit()} type="button" className="border border-blue-500 rounded-md px-6 py-2.5 text-sm bg-blue-500 text-white focus:outline-none flex items-center hover:bg-blue-600">
                  {surveyFormData.type === "custom" || surveyFormData.type === "risk" ? "Create Survey" : "Continue"}
                  {/* <span className="ml-2 material-symbols-outlined" style={{fontSize: '1rem'}}>arrow_forward</span> */}
                </button>
              </div>
              {/*  */}

            </div>

          </div>
          :

          <>
            <div className="w-11/12 bg-white rounded-lg shadow-sm 2xl:w-4/12 xl:w-5/12 lg:w-6/12 md:w-8/12 ">

              <div className="flex items-center justify-between p-4 px-6 border-b">
                <h2 className="text-lg font-semibold text-center text-gray-800 ">Add New Survey </h2>
                <span onClick={() => handleCreateSurveyPopupClose()} className="material-symbols-outlined bg-gray-100 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150">close</span> </div>


              <div className="p-6 space-y-4">
                <div className="space-y-2" >
                  <label className="text-sm font-medium text-gray-600" >Survey Name</label>
                  <input type="text" name="name" value={surveyFormData.name} onChange={handleAddSurveyInput}
                    className="w-full border p-2.5 px-4 mt-2 text-sm focus:outline-none rounded-md  focus:ring-2" placeholder="Enter Survey Name" />

                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-600" >Survey Description</label>
                  <textarea name="desc" value={surveyFormData.desc} onChange={handleAddSurveyInput} rows={4}
                    className="border w-full h-20 p-2.5 px-4 text-sm focus:outline-none rounded-md focus:ring-2 resize-none" placeholder="Write here..." defaultValue={""} />
                </div>





                {/* {isModule?
                 
 
                    <div className="space-y-2">
                      <label className="text-sm font-medium text-gray-600" >Select Survey category </label>


                      <div className="cursor-pointer w-full border flex justify-between px-4 items-center relative py-2.5 rounded space-x-1.5">
                        <span onClick={() => handleOpenFilter3(!showFilter3)} className="w-32 text-xs text-gray-500 truncate ">{selectedSurveyType && selectedSurveyType.name ? selectedSurveyType.name : "Select Survey"}</span>
                        <span onClick={() => handleOpenFilter3(!showFilter3)} className="text-gray-500 material-symbols-outlined ">arrow_drop_down</span>

                        {showFilter3 ?
                          <div className="absolute right-0 z-20 w-full h-40 p-3 overflow-y-auto font-normal bg-white border shadow-lg top-10 font-xs" id="chatbot" style={{ zIndex: 120 }}>
                            {allLifecycleListenListData && allLifecycleListenListData.length > 0 ?
                              allLifecycleListenListData.map((innerSurveyDetails, innerSurveyIndex) =>
                                <div key={innerSurveyIndex} className="flex items-center py-2 m-1" onClick={() => { selectCreateType(innerSurveyDetails, true); handleOpenFilter3(!showFilter3) }}>
                                  <input type="checkbox" checked={selectedSurveyType.id === innerSurveyDetails.id ? true : false}
                                    onChange={() => { }} /><label className="ml-2 text-sm cursor-pointer text-[#3D405B] ">{innerSurveyDetails.name}</label>
                                </div>
                              ) : null}
                          </div>
                          : null}

                      </div>


                    </div>

 
                  : null} */}



                {!isRiskSurvey ?
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-600" >Select Survey Template </label>
                    {/* <p className="text-sm text-gray-700 ">Select survey template you want to continue with</p> */}

                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 ">
                      {!is360Feedback ?
                        <div onClick={() => handleSurveyType("custom")} title="Build your own survey from sctrach" className={surveyFormData.type === "custom" ?
                          "border flex justify-center text-center p-6 rounded-md text-gray-700    border-blue-500 cursor-pointer relative  hover:bg-blue-50 transition duration-150" :
                          "border flex justify-center text-center p-6 rounded-md text-gray-700 hover:text-blue-500 hover:border-blue-500 cursor-pointer hover:bg-blue-50"}
                        >
                          <div className="flex items-center space-x-2" >
                            <span class={surveyFormData.type === "custom" ? "material-symbols-outlined  text-blue-500" : "material-symbols-outlined "} style={{ fontSize: '2rem' }}>
                              edit_square
                            </span>

                            <h1 className={surveyFormData.type === "custom" ? "font-medium text-sm py-2 capitalize text-blue-500" : "font-medium text-sm py-2 capitalize"}>Start from scratch</h1>

                            {surveyFormData.type === "custom" ?
                              <span className="absolute text-blue-500 material-symbols-outlined top-1 right-1" style={{ fontSize: '1.3rem' }}>check_circle</span>
                              : null}


                          </div>


                        </div>
                        : null}




                      <div onClick={() => handleSurveyType("package")} title="Build survey using Plus Templates. Please note Plus Templates are not editable" className={surveyFormData.type === "package" ?
                        "border flex justify-center text-center p-6 rounded-md text-gray-700    border-blue-500 cursor-pointer relative hover:bg-blue-50 transition duration-150" :
                        "border flex justify-center text-center p-6 rounded-md text-gray-700 hover:text-blue-500 hover:border-blue-500 cursor-pointer hover:bg-blue-50 transition duration-150"}
                      >


                        <div className="flex items-center space-x-2" >
                          <span className={surveyFormData.type === "package" ? "material-symbols-outlined text-blue-500 " : "material-symbols-outlined "} style={{ fontSize: '2rem' }}>content_copy</span>
                          <h1 className={surveyFormData.type === "package" ? "font-medium text-sm py-2 text-blue-500 " : "font-medium text-sm py-2 "}> Choose Templates</h1>
                          {surveyFormData.type === "package" ?
                            <span className="absolute text-blue-500 material-symbols-outlined top-1 right-1" style={{ fontSize: '1.3rem' }}>check_circle</span>
                            : null}
                        </div>


                      </div>




                    </div>

                  </div>
                  :

                  <>
                    <div className="flex ">

                      {isRiskPulse ?
                        <>
                          {/* Choose Sense Type */}
                          <div className="mr-4 ">
                            <div className="flex justify-between py-3">


                              <div className="cursor-pointer bg-blue-100 border flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5">
                                <span onClick={() => handleOpenFilter4(!showFilter4)} className="w-32 text-xs text-blue-500 truncate ">{crrSenseOptionId && crrSenseOptionId.name ? crrSenseOptionId.name : "Select Sense Type"}</span>
                                <span onClick={() => handleOpenFilter4(!showFilter4)} className="text-blue-500 material-symbols-outlined ">arrow_drop_down</span>

                                {showFilter4 ?
                                  <div className="absolute w-48 p-3 font-normal bg-white shadow-lg z-96 -left-2 top-10 font-xs" id="chatbot" style={{ overflowY: 'auto', height: '90px', zIndex: 120 }}>
                                    {senseOption && senseOption.length > 0 ?
                                      senseOption.map((item, innerSurveyIndex) =>
                                        <div key={innerSurveyIndex} className="flex items-center py-2 m-1" onClick={() => { selectSenseOption(item, true); handleOpenFilter4(!showFilter4) }}>
                                          <input type="checkbox" checked={crrSenseOptionId.id === item.id ? true : false}
                                            onChange={() => { }} /><label className="ml-2 text-sm cursor-pointer text-[#3D405B] ">{item.name}</label>
                                        </div>
                                      ) : null}
                                  </div>
                                  : null}

                              </div>



                            </div>
                          </div>
                        </>
                        : null}

                      {/* {crrSenseOptionId && crrSenseOptionId.id && (crrSenseOptionId.id === 1)?
                    <>
                        <div className="mr-4 ">
                        <div className="flex justify-between py-3">


                          <div className="cursor-pointer bg-gray-100 border flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5">
                            <span onClick={() => handleOpenFilter(!showFilter)} className="w-32 text-xs text-gray-500 truncate ">{crrLinkedSurveyId && crrLinkedSurveyId.name ? crrLinkedSurveyId.name : "Select Survey"}</span>
                            <span onClick={() => handleOpenFilter(!showFilter)} className="text-gray-500 material-symbols-outlined ">arrow_drop_down</span>

                            {showFilter ?
                              <div className="absolute w-48 p-3 font-normal bg-white shadow-lg z-96 -left-2 top-10 font-xs" id="chatbot" style={{ overflowY: 'auto', height: '90px', zIndex: 120 }}>
                                {getSurveyOneTemplateData && getSurveyOneTemplateData.length > 0 ?
                                  getSurveyOneTemplateData.map((innerSurveyDetails, innerSurveyIndex) =>
                                    (innerSurveyDetails.templateId === 1) ?
                                      <div key={innerSurveyIndex} className="flex items-center py-2 m-1" onClick={() => { selectLinkedSurvey(innerSurveyDetails, true); handleOpenFilter(!showFilter) }}>
                                        <input type="checkbox" checked={crrLinkedSurveyId.id === innerSurveyDetails.id ? true : false}
                                          onChange={() => { }} /><label className="ml-2 text-sm cursor-pointer text-[#3D405B] ">{innerSurveyDetails.name}</label>
                                      </div>
                                      : null
                                  ) : null}
                              </div>
                              : null}

                          </div>



                        </div>
                        </div>
            
                        </>
                    :null} */}

                    </div>




                    {/* <div className="flex ">

                    {crrLinkedSurveyId ?
                    <div className="">
                      <div className="flex justify-between py-2">


                        <div className="cursor-pointer bg-gray-100 border flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5">
                          <span onClick={() => handleOpenFilter2(!showFilter2)} className="w-32 text-xs text-gray-500 truncate ">{crrLinkedCluster && crrLinkedCluster.clusterName ? crrLinkedCluster.clusterName : "Select Cluster"}</span>
                          <span onClick={() => handleOpenFilter2(!showFilter2)} className="text-gray-500 material-symbols-outlined ">arrow_drop_down</span>

                          {showFilter2 ?
                            <div className="absolute w-48 p-3 font-normal bg-white shadow-lg z-96 -left-2 top-10 font-xs" id="chatbot" style={{ overflowY: 'auto', height: '120px', zIndex: 120 }}>
                              {newClusterList && newClusterList.length > 0 ?
                                newClusterList.map((item, innerSurveyIndex) =>
                                  <div key={innerSurveyIndex} className="flex items-center py-2 m-1" onClick={() => { selectLinkedCluster(item); handleOpenFilter2(!showFilter2) }}>
                                    <input type="checkbox" checked={crrLinkedCluster.clusterName === item.clusterName ? true : false}
                                      onChange={() => { }} /><label className="ml-2 text-sm cursor-pointer text-[#3D405B] ">{item && item.clusterName ? item.clusterName : "NaN"}</label>
                                  </div>
                                ) : null}
                            </div>
                            : null}

                        </div>



                      </div>
                    </div>
                    : null}

                   </div> */}






                  </>}

                <div className="mt-8 text-center text-red-400">
                  {!surveyValidation.name ?
                    <p>Please enter survey name.</p>
                    : null}
                </div>


                {/* upload details */}

                <div className="pt-6" >
                  <button onClick={() => handleAddSurveySubmit()} type="button" className="border w-full border-blue-500 rounded-md  py-2.5 text-base bg-blue-500 text-white focus:outline-none flex items-center justify-center hover:bg-blue-600 Capitalize text-center font-medium">
                    {surveyFormData.type === "risk" ? (crrSenseOptionId && crrSenseOptionId.id && (crrSenseOptionId.id === 2) ? "Add New Survey" : "Add New Survey") : "Continue"}
                    {/* <span className="ml-2 material-symbols-outlined" style={{fontSize: '1rem'}}>arrow_forward</span> */}
                  </button>
                </div>

                {/*  */}

              </div>

            </div>
          </>}


      </div>

    </>
  );
}
