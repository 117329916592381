import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-quill/dist/quill.snow.css';
import Select2 from 'react-select';

class LogicPage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {
  }






  render() {
    let {
      SurveyTemplateId, welcomeFormData, imageWebLinkLogo,
      parameterListOptions, parameterDefault, orgListOptions, orgDefault,
      handleSurveyParameterList, handleSurveyorgList,
    } = this.props;


    //console.log('welcomeFormData.logo: ', imageWebLinkLogo, welcomeFormData.logo)





    return (
      <>
        <main className="px-2 bg-gray-100 xl:px-6 lg:px-6 md:px-6">
          {/* <div className="cursor-pointer item-end" onClick={()=>saveWelcomePage()}>save</div> */}
          <div className="container w-full px-2 py-12 mx-auto xl:w-2/3 xl:px-12 lg:w-2/3 lg:px-8 md:w-2/3 md:px-0">
            {/*  */}




            <div className="my-10" />





            {SurveyTemplateId !== 2 ?

              <>

              


                <div className="p-3 bg-white">
                  <div className="text-xl font-semibold">Dashboard Logic:</div>
                  <div className="my-4">
                    <div className="text-sm font-semibold text-slate-800">Selected Index</div>
                    <Select2
                      id="parameterList"
                      isMulti
                      name="parameterList"
                      isDisabled={true}
                      defaultValue={(parameterDefault) ? parameterDefault.map((val) => { return val }) : ""}
                      onChange={handleSurveyParameterList}
                      options={parameterListOptions}
                      className="text-sm basic-multi-select"
                      classNamePrefix="Activities"
                    />
                  </div>






                  <div className="my-4 bg-white">
                    <div className="text-sm font-semibold text-slate-800">Selected Organization Core Drivers</div>
                    <Select2
                      id="orgList"
                      isMulti
                      name="orgList"
                      isDisabled={true}
                      defaultValue={(orgDefault) ? orgDefault.map((val) => { return val }) : ""}
                      onChange={handleSurveyorgList}
                      options={orgListOptions}
                      className="text-sm basic-multi-select"
                      classNamePrefix="Activities"

                    />
                  </div>


                </div>



              </>
              : null}




            {/* <div className="mt-4 text-sm font-semibold text-slate-800">Select Background Color/Image</div>
      <div className="block pr-2 bg-white border xl:block lg:block md:block">


            <select onChange={(e)=>handlebgType(e.target.value)} className="w-full py-2 pl-2 text-xs font-normal outline-none text-slate-800">
              <option value={1} className="py-2 text-xs" style={{color:welcomeFormData.bgType===1?'blue':'black'}}>Background Color</option>
              <option value={2} className="py-2 text-xs" style={{color:welcomeFormData.bgType===2?'blue':'black'}}>Background Image</option>
            </select>
            
      </div> */}


            <div className="my-10" />




            {/* <div className="my-2"><input type="text" name placeholder="Button title.." className="w-full px-4 py-4 border border-gray-300 outline-none" /></div> */}
            {/* <div className="flex justify-center py-4 my-2 bg-gray-300 border border-gray-300">
        <div className="text-center text-gray-700">
          <i className="text-3xl fas fa-image" />
          <p className="text-sm">Click to add Logo</p>
        </div>
      </div> */}
            {/* <div className="my-2 border border-gray-300">
        <div className="py-1.5 px-4 bg-gray-200 flex border-gray-300  border-b">
          <span className="p-1 mx-1 text-xs border border-transparent fa fa-bold hover:bg-gray-100 hover:border-gray-300" title="Bold text" />
          <span className="p-1 mx-1 text-xs border border-transparent fa fa-italic hover:bg-gray-100 hover:border-gray-300" title="Italic text" />
          <span className="p-1 mx-1 text-xs border border-transparent fa fa-underline hover:bg-gray-100 hover:border-gray-300" title="Underline text" />
          <div className="mx-2 border-l border-gray-300" /> 
          <span className="p-1 mx-1 text-xs border border-transparent fas fa-list-ul hover:bg-gray-100 hover:border-gray-300" title="Point bullets" />
          <span className="p-1 mx-1 text-xs border border-transparent fa fa-list-ol hover:bg-gray-100 hover:border-gray-300" title="Numbers bullets" />
          <div className="mx-2 border-l border-gray-300" /> 
          <span className="p-1 mx-1 text-xs border border-transparent fa fa-link hover:bg-gray-100 hover:border-gray-300" title="Insert/edit link" />
        </div>
        <textarea type="text" placeholder="Text here.." className="w-full h-24 px-4 py-4 outline-none resize-none " defaultValue={""} /> 
      </div> */}
          </div>
        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey } = state;
  return {
    loggingIn,
    survey
  };
}
export default connect(mapStateToProps)(LogicPage);
