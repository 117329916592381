import React from "react";


export default function RelationshipTable(props) {
  let { closeRelationshipTable, get360RelationListData, handle360DisableStatue } = props;

  return (
   <>
        <div className="bg-black bg-opacity-60 top-0 xl:h-screen h-full  w-full flex justify-center items-center z-40 fixed" >
                <div className="bg-white rounded-lg shadow-sm 2xl:w-10/12 xl:w-10/12 w-11/12 ">
                  {/* head */}

                  <div className="flex justify-between p-4 border-b items-start sticky top-0 ">

                    <h2 className="md:text-xl text-lg font-semibold  test-gray-800">My Relationship</h2>

                    <div onClick={() => closeRelationshipTable(false)} className="p-2 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200 " >
                      <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path></svg>
                    </div>
                  </div>


                  <div className="space-y-2">

                    {true ?
                      <div className='bg-white  md:p-6 p-4 rounded space-y-6 customscroll5' style={{ overflowY: 'auto', 'height': '500px' }}>

                        <h1 className='text-xl font-medium '>Relationship Table</h1>

                        <table className='border-collapse w-full border ' >
                          <tr>
                            <th className='border-r p-2 font-medium' >S.no.</th>
                            <th className='border-r p-2 font-medium' >Reviewer Name</th>
                            <th className='border-r p-2 font-medium' >Reviewer Email</th>
                            <th className='border-r p-2 font-medium' >{"Reviewer Emp Id"}</th>
                            <th className='border-r p-2 font-medium' >{"Reviewee Emp Id"}</th>
                            <th className='border-r p-2 font-medium' >Reviewer Relation</th>
                            <th className='border-r p-2 font-medium' >Relation Type</th>
                            <th className='border-r p-2 font-medium' >Active</th>
                          </tr>
                          {get360RelationListData && get360RelationListData.length > 0 ?
                            get360RelationListData.map((item, index) =>

                              <tr className=' p-2 border-t bg-white text-sm'>
                                <td className='border-r p-2 text-gray-800 font-medium text-center' >{(index + 1).toString() + ""}</td>
                                <td className='border-r p-2 text-blue-600 text-center' >{item && item["reviewer_name"] ? item["reviewer_name"] : "-"}</td>
                                <td className='border-r p-2 text-blue-600 text-center' >{item && item["reviewer_email"] ? item["reviewer_email"] : "-"}</td>
                                <td className='border-r p-2 text-blue-600 text-center' >{item && item["reviewer_emp_id"] ? item["reviewer_emp_id"] : "-"}</td>
                                <td className='border-r p-2 text-blue-600 text-center' >{item && item["reviewee_emp_id"] ? item["reviewee_emp_id"] : "-"}</td>
                                <td className='border-r p-2 text-gray-600 text-center' >{item && item["reviewer_relation"] ? item["reviewer_relation"] : "-"}</td>
                                <td className='border-r p-2 text-gray-600 text-center' >{item && item["relation_type"] ? item["relation_type"] : "-"}</td>

                                <td className='text-center'>
                                  <div className='flex justify-center cursor-pointer'>
                                    {!item.isDisable ?
                                      <>
                                        <div onClick={() => handle360DisableStatue(item.id)} className="rounded-full w-11 h-5 p-0.5 bg-green-400">
                                          <div className="rounded-full w-4 h-4 bg-white translate-x-3 transform mx-auto duration-300 ease-in-out" />
                                        </div>
                                      </>
                                      :
                                      <>

                                        <div onClick={() => handle360DisableStatue(item.id)} className="rounded-full w-11 h-5 p-0.5 bg-gray-200">
                                          <div className="rounded-full w-4 h-4 bg-white -translate-x-3 transform mx-auto duration-300 ease-in-out" />
                                        </div>
                                      </>
                                    }
                                  </div>
                                </td>



                              </tr>

                            ) : null}
                        </table>

                      </div>
                      : null}

                  </div>





                </div>



              </div>
   </>
  );
}
