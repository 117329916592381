import React from 'react';
import ReactEcharts from "echarts-for-react";
import OverallLabels from '../Components/OverallLabels';
import { graphic } from 'echarts';

export default function BarDistribution(props) {

  let { List, labelObj, colorObj, year1Label, year2Label, DistributionData, Distribution2Data, getOverallDistribution, getOverallDistribution2, year1, year2 } = props;

  let ScoreList = []

  if (DistributionData || Distribution2Data) {
    if (List && List.length > 0) {
      List.forEach((item) => {
        ScoreList.push({
          "overall": getOverallDistribution && getOverallDistribution[item] && parseFloat(getOverallDistribution[item]) ? parseFloat(getOverallDistribution[item]) : 0,
          "overall2": getOverallDistribution2 && getOverallDistribution2[item] && parseFloat(getOverallDistribution2[item]) ? parseFloat(getOverallDistribution2[item]) : 0,
          "name": item,
          "score": DistributionData && DistributionData[item] && parseFloat(DistributionData[item]) ? parseFloat(DistributionData[item]) : 0,
          "score2": Distribution2Data && Distribution2Data[item] && parseFloat(Distribution2Data[item]) ? parseFloat(Distribution2Data[item]) : 0
        })
      });
    }

  }

  let names = []
  let Overall = []

  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }


  let seriesX = [];

  let labelX = ['product'];

  if (year1) {
    labelX.push(year1Label.toString());

    seriesX.push({
      barWidth: '40px',
      label: {
        show: true,
        position: 'top',
        color: "#000",
        textStyle: {
          fontSize: 13,
          fontWeight: 'bold'
        },
      },
      type: 'bar', color: new graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: hexToRgbA('#2196F3', "0.50")
        },
        {
          offset: 1,
          color: '#2196F3'
        }])
    })
  }
  if (year2) {
    labelX.push(year2Label.toString());

    seriesX.push({
      barWidth: '40px',
      label: {
        show: true,
        position: 'top',
        color: "#000",
        textStyle: {
          fontSize: 13,
          fontWeight: 'bold'
        },
      },
      type: 'bar', color: new graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: hexToRgbA('#673AB7', "0.50")
        },
        {
          offset: 1,
          color: '#673AB7'
        }])
    })

  }
  let dataX = [];

  if (ScoreList && ScoreList.length > 0) {
    ScoreList.forEach((item) => {

      let temp = { "product": labelObj[item.name] };
      if (year1) {
        temp[year1Label.toString()] = item.score && item.score.toFixed(2) ? item.score.toFixed(2) : 0;
      }
      if (year2) {
        temp[year2Label.toString()] = item.score2 && item.score2.toFixed(2) ? item.score2.toFixed(2) : 0;
      }
      dataX.push(temp);

      names.push(labelObj[item.name])
      Overall.push({
        "name": item.name,
        "score": item.overall,
        "score2": item.overall2,
        "color": colorObj && colorObj[item.name] ? colorObj[item.name] : '#757575'
      })

    });
  }


  return (
    <>
      <div className="" style={{ width: '90%' }}>
        <ReactEcharts
          style={{
            height: '300px',
            marginTop: '-40px',
          }}
          option={{
            legend: {},
            tooltip: {},
            dataset: {
              dimensions: labelX,
              source: dataX
            },
            xAxis: { 
              type: 'category'
            },
            yAxis: {
              type: 'value',
              max: 100,
              splitLine: {
                show: false
              },
            },
            series: seriesX
          }}

        />
      </div>

      <OverallLabels
        Overall={Overall}
        labelObj={labelObj}

        year1={year1}
        year2={year2}

        year1Label={year1Label}
        year2Label={year2Label}
      />


    </>
  );
}
