import React from "react";
import moment from "moment";


export default function CompleteModal(props) {
  let { getEmployeeFieldsData, selectFieldType, onboardingDateField, crrFieldType, selectOnboardingDateField, saveEmployeeListData, loadedDataSheet, closeUploadPreview, saveUploadEmployee, handleChange, SheetJSFT } = props;


  let [showFilter, handleOpenFilter] = React.useState(false)

  let [showFilter2, handleOpenFilter2] = React.useState(0)




  let originalKeyCount = 0;
  let empKeysPrev = getEmployeeFieldsData && getEmployeeFieldsData[0] && getEmployeeFieldsData[0].allkeys && getEmployeeFieldsData[0].allkeys.length > 0 ? getEmployeeFieldsData[0].allkeys : []

  let empKeys = [];

  if (empKeysPrev && empKeysPrev.length > 0) {
    empKeysPrev.forEach((key) => {
      if (key !== 'id' && key !== '_id' && key !== 'isMNGRMoreThan5' && key !== 'showMNGRReport' && key !== 'orgRole' && key !== 'actionPlanStage' && key !== 'isTest' && key !== '__v') {
        empKeys.push(key)
      }
    })
  }



  // console.log("loadedDataSheet-------------->", loadedDataSheet);

  let FieldList = []
  if (loadedDataSheet && loadedDataSheet.length > 0) {
    loadedDataSheet.forEach((element) => {
      let keyList = Object.keys(element);
      if (keyList && keyList.length > 0) {
        keyList.forEach((key) => {
          let getIndex = FieldList.findIndex(prev => prev === key);
          if (getIndex === -1) {
            FieldList.push(key)
          }

        })
      }

    })
  }

  let presentFieldCount = 0;
  if (FieldList && FieldList.length > 0) {
    presentFieldCount = empKeys.length;
  }

  let matched_count = 0;
  let unmatched_count = 0;


  if ((empKeys && empKeys.length > 0 ? empKeys.length : 0) >= (FieldList && FieldList.length > 0 ? FieldList.length : 0)) {
    if (empKeys && empKeys.length > 0) {
      empKeys.forEach((original) => {
        if (FieldList && FieldList.length > 0) {
          FieldList.forEach((present) => {
            if ((original.trim()) === (present.trim())) {
              matched_count++
            }

          })
        }
      })

      unmatched_count = empKeys.length - matched_count
    }
  } else {
    if (FieldList && FieldList.length > 0) {
      FieldList.forEach((present) => {
        if (empKeys && empKeys.length > 0) {
          empKeys.forEach((original) => {
            if ((original.trim()) === (present.trim())) {
              matched_count++
            }

          })
        }
      })
    }
    unmatched_count = empKeys.length - matched_count

  }



  let spec_heading_list = [];
  let date_format_list = [];
  let emp_id_count_list = [];

  let listFinal = [];

  if (loadedDataSheet && loadedDataSheet.length > 0) {
    loadedDataSheet.forEach((element, rowIndex) => {
      let temp = {}
      if (FieldList && FieldList.length > 0) {
        FieldList.forEach((item, colIndex) => {
          temp[item] = element[item]



          function validateDate(key, value) {
            if (getCrrFieldType(crrFieldType, key)) {
              let dateFormat = getCrrFieldType(crrFieldType, key);
              if (dateFormat) {

                const dateFormat = 'DD-MM-YYYY';

                if (moment(value, dateFormat, true).isValid()) {
                  return false
                } else {
                  return true
                }
              } {
                return false
              }
            }
            else {
              return false
            }
          }


          if (validateDate(item, element[item])) {
            let text1 = "Incorrect Date Format at (" + ((rowIndex + 1).toString()) + ", " + ((colIndex + 1).toString()) + ")" + ", please put format as DD-MM-YYYY"
            let getINdex3 = date_format_list.findIndex(prev => prev === text1)
            if (getINdex3 === -1) {
              date_format_list.push(text1)
            }
          }



          function validateAddress(TCode) {
            if (/[^a-zA-Z0-9\-\_\/]/.test(TCode) && (TCode !== "emp_id")) {
              return true;
            } else {
              return false;
            }
          }

          if (validateAddress(item)) {
            let text = "Contains special character at Column " + (colIndex + 1).toString() + " i.e. " + item
            let getINdex2 = spec_heading_list.findIndex(prev => prev === text)
            if (getINdex2 === -1) {
              spec_heading_list.push(text)
            }
          }

          if (item === "emp_id") {
            if (element[item]) {
              // console.log("element-------------->", element[item]);

              let getIndex1 = emp_id_count_list.findIndex(prev => prev.value === element[item])
              if (getIndex1 === -1) {
                emp_id_count_list.push({
                  "value": element[item],
                  "count": 1,
                  "locInit": "",
                  "loc": ((rowIndex + 1).toString())
                })
              } else {
                emp_id_count_list[getIndex1]["count"] += 1
                if (emp_id_count_list[getIndex1]["count"] > 2) {
                  // emp_id_count_list[getIndex1]["loc"] = emp_id_count_list[getIndex1]["loc"]+", ("+((rowIndex+1).toString())+", "+((colIndex+1).toString())+")"
                  emp_id_count_list[getIndex1]["locInit"] = emp_id_count_list[getIndex1]["locInit"] + ", (" + ((rowIndex + 1).toString()) + ", " + ((colIndex + 1).toString()) + ")"
                  emp_id_count_list[getIndex1]["loc"] = emp_id_count_list[getIndex1]["loc"] + ", " + ((rowIndex + 1).toString())


                } else {
                  // emp_id_count_list[getIndex1]["loc"] = "("+((rowIndex+1).toString())+", "+((colIndex+1).toString())+")"
                  emp_id_count_list[getIndex1]["locInit"] = "(" + ((rowIndex + 1).toString()) + ", " + ((colIndex + 1).toString()) + ")"
                  emp_id_count_list[getIndex1]["loc"] = emp_id_count_list[getIndex1]["loc"] + ", " + ((rowIndex + 1).toString())


                }
              }
            }
          }




        })
      }

      listFinal.push(temp)



    })
  }




  // console.log("emp_id_count_list-------------->", emp_id_count_list);

  let IssueList = []

  if (FieldList && FieldList.length > 0) {
    if (!(FieldList.includes("email"))) {
      IssueList.push('Doesn\'t contain valid email heading ')
    }
    if (!(FieldList.includes("emp_id"))) {
      IssueList.push('Doesn\'t contain valid emp_id heading ')
    }

  }

  if (emp_id_count_list && emp_id_count_list.length > 0) {
    emp_id_count_list.forEach((element) => {
      if (element["locInit"]) {
        IssueList.push("Duplicate record having same emp_id at row " + element["loc"])
      }
    })
  }

  if (spec_heading_list && spec_heading_list.length > 0) {
    spec_heading_list.forEach((element) => {
      if (element) {
        IssueList.push(element)
      }
    })
  }


  if (date_format_list && date_format_list.length > 0) {
    date_format_list.forEach((element) => {
      if (element) {
        IssueList.push(element)
      }
    })
  }





  // console.log("saveEmployeeListData-------------->", saveEmployeeListData);
  // console.log("IssueList-------------->", IssueList);

  let elementSuccess = "";

  if (saveEmployeeListData && saveEmployeeListData.length > 0) {
    let a_ele = {
      "ok": 0,
      "nModified": 0
    }
    saveEmployeeListData.forEach((element) => {
      a_ele["ok"] += element["ok"]
      a_ele["nModified"] += element["nModified"]
    })

    let total = a_ele.ok ? a_ele.ok : 0;
    let modified = a_ele.nModified ? a_ele.nModified : 0;
    let inserted = total - modified;
    let element1 = "No. of Record Modified: " + (modified).toString()
    let element2 = "No. of Record Inserted: " + (inserted).toString()

    elementSuccess = element1 + " , " + element2;

  }


  // let a_ele = saveEmployeeListData.data;





  let optionField = ["Date (DD-MM-YYYY)", "Alpha Numeric"]


  function getCrrFieldType(list, field) {
    let getIndeX1 = list.findIndex(prev => prev.field === field)
    if (getIndeX1 !== -1) {
      return list[getIndeX1]["option"]
    } else {
      return ""
    }
  }




  let getColumnAdded = "";
  if (unmatched_count) {
    getColumnAdded = unmatched_count + " new Columns are added! "
  }

  if (FieldList && FieldList.length > 0) {
    console.log("FieldList------>", FieldList)
  }
  if (empKeys && empKeys.length > 0) {
    console.log("empKeys------>", empKeys)
  }

  // console.log("unmatched_count------>", unmatched_count)
  // console.log("unmatched_count------>", unmatched_count)
  // console.log("matched_count------>", matched_count)

  return (

    <div className="fixed top-0 left-0 z-40 flex items-center justify-center w-full h-screen px-4 bg-black bg-opacity-60 md:px-0" >

      <div className="flex flex-col mx-auto mt-4 overflow-hidden bg-white border rounded-md 2xl:w-3/6 xl:w-4/6 md:w-5/6">

        <div className="flex items-start justify-between w-full p-4 border-b md:items-center md:px-6">
          <h2 className="text-lg font-semibold text-center text-gray-800 ">Upload Employee Preview</h2>
          <div onClick={() => closeUploadPreview(false)} className="inline-flex items-center justify-center p-2 text-gray-500 bg-gray-100 rounded-full cursor-pointer hover:text-gray-800" >
            <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>
        </div>

        {elementSuccess ?
          <>

            <div className="items-center justify-center my-24 text-center">
              <h1 className="py-2 text-2xl font-medium text-center text-green-500">{'Employees Successfully Uploaded!'}</h1>
              <h1 className="text-center text-gray-500 text-md">{elementSuccess ? elementSuccess : ""}</h1>
            </div>
          </>
          :
          <>
            <div className=""  >

              <div className="flex flex-row items-start justify-between w-full p-4 md:px-6 md:flex-row md:space-y-0 md:items-center">
                <div className="text-sm font-medium md:text-base text-slate-600">Select Start Date Field for Onboarding Survey </div>
                <div className="cursor-pointer bg-white border border-gray-300 text-slate-600 flex justify-between px-4 items-center relative py-2 rounded-md  space-x-1.5 ">
                  <span onClick={() => handleOpenFilter(!showFilter)} className="text-sm font-medium capitalize whitespace-nowrap">{onboardingDateField ? onboardingDateField : "Created Date"}</span>
                  <span onClick={() => handleOpenFilter(!showFilter)} className="material-symbols-outlined ">arrow_drop_down</span>

                  {showFilter ?
                    <div className="absolute right-0 z-10 w-48 py-2 overflow-hidden overflow-y-auto font-normal text-gray-600 capitalize bg-white border rounded-md shadow-lg top-10 font-xs h-60 scollar-xs"   >
                      {FieldList && FieldList.length > 0 ?
                        FieldList.map((element, index) =>

                          getCrrFieldType(crrFieldType, element) === "Date (DD-MM-YYYY)" ?
                            <div key={index} className="flex items-center " >
                              <label onClick={() => {
                                selectOnboardingDateField(element)
                                handleOpenFilter(!showFilter)
                              }} className="w-full px-3 py-2 text-xs cursor-pointer hover:bg-gray-100">
                                <input type="checkbox" className="mr-2" checked={onboardingDateField === element}
                                  onChange={() => {
                                    selectOnboardingDateField(element)
                                    handleOpenFilter(!showFilter)
                                  }} />{element}</label>
                            </div> : null
                        ) : null}
                    </div>
                    : null}


                </div>


              </div>

              <div className="px-4 md:px-6">

                <div className="w-full h-56 overflow-auto 2xl:h-80 md:h-56 ">
                  <table className="w-full border ">
                    <thead className="">
                      <tr className="p-2 ">
                        {FieldList && FieldList.length > 0 ?
                          FieldList.map((field, index2) =>
                            <th key={index2} className="p-2 text-sm font-semibold text-center capitalize ">{field}</th>
                          ) : null}

                      </tr>
                      <tr className="p-2 ">
                        {FieldList && FieldList.length > 0 ?
                          FieldList.map((field, index2) =>
                            <th key={index2} className="p-2 text-sm font-bold text-center ">



                              <div className="cursor-pointer  flex justify-between px-4 bg-white items-center relative py-1.5 space-x-1.5 ml-2">
                                <span onClick={() => handleOpenFilter2((showFilter2 === (index2 + 1)) ? 0 : index2 + 1)} className="text-xs text-gray-500 truncate ">{getCrrFieldType(crrFieldType, field) ? getCrrFieldType(crrFieldType, field) : "Select Type"}</span>
                                <span onClick={() => handleOpenFilter2((showFilter2 === (index2 + 1)) ? 0 : index2 + 1)} className="text-gray-600 material-symbols-outlined ">arrow_drop_down</span>

                                {showFilter2 === (index2 + 1) ?
                                  <div className="absolute z-10 p-3 font-normal bg-white shadow-lg -left-2 top-10 font-xs w-52" id="chatbot" style={{ overflowY: 'auto', height: '100px' }}>
                                    {optionField && optionField.length > 0 ?
                                      optionField.map((option, index) =>
                                        <div key={index} className="flex items-center py-2 m-1" >
                                          <input type="checkbox" checked={getCrrFieldType(crrFieldType, field) === option}
                                            onChange={() => {
                                              selectFieldType(field, option)
                                              handleOpenFilter2(0)
                                            }} /><label onClick={() => {
                                              selectFieldType(field, option)
                                              handleOpenFilter2(0)
                                            }} className="ml-2 text-sm cursor-pointer text-[#3D405B] ">{option}</label>
                                        </div>
                                      ) : null}
                                  </div>
                                  : null}

                              </div>




                            </th>
                          ) : null}

                      </tr>
                    </thead>
                    <tbody>
                      {listFinal && listFinal.length > 0 ?
                        listFinal.map((element, index) =>
                          <tr key={index} className="p-2 text-sm odd:bg-slate-100 even:bg-white text-slate-600 ">
                            {FieldList && FieldList.length > 0 ?
                              FieldList.map((field, index2) =>
                                <td key={index2} className="p-2 text-center ">{element[field]}</td>
                              ) : null}
                          </tr>
                        ) : null}

                    </tbody>
                  </table>
                </div>
              </div>




              {IssueList && IssueList.length > 0 ?
                <div className="p-4 space-y-2 md:p-6">

                  <div>
                    <h1 className="font-medium text-red-600">Following errors are found</h1>
                    <div className="text-sm text-slate-600" >
                      {!(IssueList && IssueList.length > 0) ?
                        <>
                          <div className="text-sm ">{listFinal && listFinal.length > 0 ? (listFinal.length === 1 ? "1 Employee will be uploaded!" : (listFinal.length).toString() + " Employees will be uploaded") : 0}</div>
                        </> : null}
                      {/* {getColumnAdded ? <div className="text-sm ">{getColumnAdded}</div> : null} */}
                    </div>
                  </div>
                  <div className="h-24 p-3 overflow-y-auto border rounded-md"  >
                    {IssueList.map((item, index) =>
                      <div key={index} className="py-1">
                        <p className="text-xs text-red-600">{(index + 1).toString() + ") " + item}</p>
                      </div>
                    )}
                  </div>

                </div>
                : <div className=""></div>}








            </div>



            <div className="justify-between w-full p-4 space-y-6 border-t md:px-6 md:flex md:space-y-0">
              <div className="flex flex-col items-center w-full space-y-2 md:flex-row md:justify-between md:space-x-4 md:space-y-0">

                <div className="space-y-1.5 md:text-left text-center">
                  <h1 className="text-base font-medium ">File validation was successful</h1>

                  <div>
                    {!(IssueList && IssueList.length > 0) ?
                      <>
                        <div className="text-sm ">{listFinal && listFinal.length > 0 ? (listFinal.length === 1 ? "1 Employee will be uploaded!" : (listFinal.length).toString() + " Employees will be uploaded") : 0}</div>
                      </> : null}


                    {getColumnAdded ? <div className="text-sm ">{getColumnAdded}</div> : null}
                  </div>

                </div>

                {!(IssueList && IssueList.length > 0) ?

                  <div className="flex ">
                    <button onClick={() => saveUploadEmployee()} className="bg-gradient-to-r from-blue-500 to-pink-500 text-base capitalize text-white border py-2.5 font-medium md:px-4 px-6 rounded-md">Finalize Import</button>
                  </div>

                  :
                  <div>
                    <input type="file"
                      id="file" accept={SheetJSFT} onChange={handleChange} hidden />
                    <label for="file" className='bg-white border border-sky-500 text-sky-500  rounded cursor-pointer px-6 py-2.5 flex space-x-1.5   ' >


                      <span className="material-symbols-outlined">
                        publish
                      </span>
                      <p>Reupload</p>

                    </label>

                  </div>
                }


              </div>
            </div>
          </>}






      </div>


    </div>

  );
}
