import React, { Component } from 'react';
import { connect } from 'react-redux';

class ReportWaiseData extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
  }



  render() {

    let { item, index, } = this.props;

    let { total } = item ? item : {};

    function getRandomColor() {
      let colorList = ["#E57373", "#F06292", "#BA68C8", "#BA68C8", "#9575CD", "#7986CB", "#64B5F6", "#4FC3F7", "#4DD0E1", "#4DB6AC", "#81C784", "#DCE775", "#FFB74D", "#FF8A65", "#90A4AE", "#757575"]
      var color = colorList[Math.floor(Math.random() * colorList.length)];
      return color
    }

    let msgList = total.map((item) => ({ "text": item && item._id && item._id && item._id.answerText ? item._id.answerText : '', "color": getRandomColor() }))

    return (

      <div className='p-6 space-y-4 bg-white'>
        {/* <div className='flex flex-row items-center justify-between w-full gap-4'>
          <div className='flex items-center text-[#212121] space-x-2'>
            <p className='p-3 bg-[#ededed] font-semibold'>Question {index + 1}</p>
          </div>
        </div>
        <h2 className='text-xl font-medium text-[#212121]'>{item && item.question.name ? item.question.name : 'null'}</h2>
        <p className='text-base text-[#212121]/80 flex space-x-2 items-center'>
          <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>Multiline Text</p>
          <b className='font-medium text-[#212121] pr-1'>10</b> out of<b className='font-medium text-[#212121] pr-1'>10</b> answered
        </p> */}

        <button class="flex w-full text-left space-x-4">
          <div class="text-primary flex h-10 items-center justify-center">
            <p className='bg-[#ededed] px-3 py-2 font-semibold whitespace-nowrap' style={{ fontWeight: "bold" }}>Q. {index + 1}</p>
          </div>
          <div class="w-full">
            <h4 class="text-xl font-medium text-[#212121]">{item && item.question.name ? item.question.name : 'null'}</h4>
            <p className='text-base text-[#212121]/80 flex space-x-2 items-center pt-3'>
              <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>Multi-line Text</p>
              {/* <b className='font-medium text-[#212121] pr-1'>10</b> out of<b className='font-medium text-[#212121] pr-1'>10</b> answered */}
            </p>
          </div>
        </button>

        <div className='p-6 rounded'>
          {/* {msgList && msgList.length > 0 ?
            msgList.map((text) =>
              <div className="flex items-end py-2 space-x-4">
                <div>
                  <div className='flex items-center justify-center w-8 h-8 p-1 bg-green-500 rounded-full' style={{ background: text.color }}>
                    <img src='/img/profile.svg' />
                  </div>
                </div>
                <div className='w-full p-4 rounded-tl-xl rounded-r-xl' style={{ background: `${text.color}1A`, }}>
                  <p className="text-sm text-slate-600">{text && text.text}</p>
                </div>
              </div>
            ) : null
          } */}

          {msgList && msgList.length > 0 ?
            msgList.slice(0, 5).map((text, index) => // Limiting to 5 messages
              <div key={index} className="flex items-end py-2 space-x-4">
                <div>
                  <div className='flex items-center justify-center w-8 h-8 p-1 bg-green-500 rounded-full' style={{ background: text.color }}>
                    <img src='/img/profile.svg' alt="Profile" />
                  </div>
                </div>
                <div className='w-full p-4 rounded-tl-xl rounded-r-xl'
                  style={{
                    background: `${text.color}1A`, // 1A represents 10% opacity in hexadecimal
                  }}
                >
                  <p className="text-sm text-slate-600">{text && text.text}</p>
                </div>
              </div>
            ) : null
          }

        </div>

        {/* {
          stage !== 2 ?
            <>
              <button className='flex items-center space-x-2 font-medium text-[#2196F3] p-2 w-fit mx-auto' onClick={() => onOthersCardClick(item)}>
                <span>{stage == 2 ? "View More" : (stage == 3 ? "" : " View More")} </span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg>
              </button>
            </>
            : null
        } */}
      </div >


    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ReportWaiseData);
