import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";


export default function GaugeScore(props) {

  let { item, Benchmark } = props;


  return (
    <div className=" " >
      <div className='2xl:!w-[450px] 2xl:!h-[270px] md:!w-[400px] md:!h-[250px] !w-[300px] !h-[200px] -mt-4'>
        <ReactSpeedometer
          fluidWidth={true}
          // width={350}
          // height={200}
          value={item.score}
          minValue={0}
          maxValue={72}
          needleColor="#4db8ff"
          segments={4}
          segmentColors={[
            "#e53935",
            "#ffc107",
            "#a2e37d",
            "#00cc66"
          ]}
          ringWidth={50}
          customSegmentStops={[0, 48, 60, 66, 72]}
        />
      </div>
      {Benchmark && Benchmark.score || true ?
        <div className="gauge-outer">
          <div className="gauge-inner" style={{ transform: 'rotate(calc(1deg * (-45 + ' + 0 + ' * 2.50)))' }}></div>
        </div>
        : null}
    </div>

  );
}
