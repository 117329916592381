import React, { Component } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";

class WordDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEmpPage: 1,
      EmpIndexA: 0,
      EmpIndexB: 4,
      pageCount: 15,
    };
  }

  componentDidMount() {}

  SelectEmpPage = (val) => {
    this.setState({ currentEmpPage: val });
  };

  prevEmpPage = () => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (EmpIndexA > 0) {
      this.setState({ EmpIndexA: EmpIndexA - 1, EmpIndexB: EmpIndexB - 1 });
    }
  };
  nextEmpPage = (IndexListIndex) => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (
      IndexListIndex &&
      IndexListIndex.length &&
      EmpIndexB < IndexListIndex.length
    ) {
      this.setState({ EmpIndexA: EmpIndexA + 1, EmpIndexB: EmpIndexB + 1 });
    }
  };

  render() {
    let {
      EmpFilterData2,
      GetParameterLabel,
      wordCloudTextData,
      wordTextKeyWord,
      loading,
      keyWordList,
    } = this.props;
    let { currentEmpPage, EmpIndexA, EmpIndexB, pageCount } = this.state;

    let textData = [];

    if (wordCloudTextData && wordCloudTextData.length > 0) {
      wordCloudTextData.forEach((item) => {
        if (
          item.answerText.includes("answer1:") ||
          item.answerText.includes("answer2:")
        ) {
          textData.push({
            ...item,
            ans1: item.answerText.substring(
              item.answerText.lastIndexOf("answer1:") + 8,
              item.answerText.lastIndexOf("answer2:") - 1
            ),
            ans2: item.answerText.substring(
              item.answerText.lastIndexOf("answer2:") + 8
            ),
          });
        } else {
          textData.push({
            ...item,
            ans3: item.answerText,
          });
        }
      });
    }

    //console.log('wordTextKeyWord',wordTextKeyWord)

    let KEYWORD = "";
    if (wordTextKeyWord) {
      KEYWORD = wordTextKeyWord.toLowerCase();
    }

    //--------------------------------------------------------------------------------

    let IndexList = [];
    let IndexListIndex = [];
    let indexA = 0;
    let indexB = 0;

    if (textData && textData.length > 0) {
      if (textData && textData.length > 0) {
        textData.forEach((item, index) => {
          if (index % pageCount === 0) {
            IndexList.push(index);
          }
          if (index === textData.length - 1) {
            IndexList.push(index);
          }
        });
      }

      //console.log('IndexList: ',IndexList)

      if (IndexList && IndexList.length > 0) {
        IndexList.forEach((item, index) => {
          if (index !== IndexList.length - 1) {
            IndexListIndex.push(index + 1);
          }
        });
      }
      //console.log('textData====================>: ',textData)

      if (IndexListIndex && IndexListIndex.length > 0) {
        IndexListIndex.forEach((item, index) => {
          if (item === currentEmpPage) {
            if (index < IndexList.length - 2) {
              indexA = IndexList[index];
              indexB = IndexList[index] + (pageCount - 1);
            } else {
              indexA = IndexList[index];
              indexB = IndexList[IndexList.length - 1];
            }
          }
        });
      }
    }
    //--------------------------------------------------------------------------------
    //console.log('----------------textData:--------------- ',textData)

    let List = [];
    let ExporttextData = [];
    if (textData && textData.length >= 5) {
      textData.forEach((item, index) => {
        if (index === 0) {
          List = Object.keys(item);
        }
        let temp = {};
        if (List && List.length > 0) {
          List.forEach((ele) => {
            // let getIndex = EmpFilterData2 && EmpFilterData2.length>0?EmpFilterData2.findIndex(prev=>prev.name === ele):-1;
            // let validDMG = (ele==="Age_Range" || ele==="Business_Unit" || ele==="Department" || ele==="Gender") || true;
            // if(ele!=="ans3" && ele!=="answerText" && ele!="ManagerPID" && getIndex!==-1 && validDMG){
            //   temp[ele] = item[ele];
            // }
            if (EmpFilterData2 && EmpFilterData2.length > 0) {
              EmpFilterData2.forEach((ele) => {
                if (item && item[ele.name]) {
                  temp[GetParameterLabel(ele.name)] =
                    item && item[ele.name] ? item[ele.name] : "NaN";
                }
              });
            }
            // temp['Department'] = item && item['Department']?item['Department']:"NaN"
          });
        }
        temp["answerText"] =
          item && item["answerText"]
            ? item["answerText"]
                .replace("answer1:", "")
                .replace("answer2:", ", ")
            : "";
        ExporttextData.push(temp);
      });
    }
    //console.log('----------------ExporttextData:--------------- ',ExporttextData)

    return (
      <>
        {/* box right */}
        <div className="transition duration-150 bg-white border rounded-lg cursor-pointer hover:shadow-lg">
          <div className="flex items-center justify-between px-6 py-4 ">
            <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap capitalize  ">
              {keyWordList && keyWordList.length>0?keyWordList.map((ele)=>(ele)).join(","):""}
            </h1>

            {ExporttextData && ExporttextData.length >= 5 ? (
              <CSVLink data={ExporttextData} filename={"TextData.csv"}>
                <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                    <path d="M10 12l4 4m0 -4l-4 4" />
                  </svg>
                  <span> {"Download Excel"}</span>
                </button>
              </CSVLink>
            ) : (
              "..."
            )}
          </div>
          {/*sub nav bar 1 */}
          <div className="items-center justify-between px-6 border-b md:flex">
            <span className="pb-2 text-base text-[#3D405B]/70 font-medium  flex items-center space-x-1">
              <p className="">All comments</p>
              <b className="font-medium ">
                {" "}
                {textData && textData.length > 0 ? textData.length : 0}{" "}
              </b>
            </span>
          </div>
          {/* end */}
          {/* chart */}
          <div className="h-[1450px] p-6 py-2 divide-y overflow-y-auto customscroll4">
            {textData && textData.length > 0 && textData.length >= 5 ? (
              textData.map((item, index) =>
                index >= indexA && index <= indexB ? (
                  <div className="flex items-start py-4 space-x-4 capitalize">
                    <span className="p-1 rounded-full bg-yellow-400  mt-1.5" />
                    <div className="space-y-2">
                      {item.ans1 ? (
                        <h1 className="text-base font-medium text-[#3D405B] ">
                          {"A) " + item.ans1}
                        </h1>
                      ) : null}
                      {item.ans2 ? (
                        <h1 className="text-base font-medium text-[#3D405B] ">
                          {"B) " + item.ans2}
                        </h1>
                      ) : null}
                      {item.ans3 ? (
                        <h1 className="text-base font-medium text-[#3D405B] ">
                          {item.ans3}
                        </h1>
                      ) : null}

                      <ul className="flex items-center space-x-4 text-sm font-medium">
                        {item && item.Department ? (
                          <li className="text-sm text-[#3D405B]/70 ">
                            {item.Department + ", "}
                          </li>
                        ) : null}
                        {item && item.Gender ? (
                          <li className="text-sm text-[#3D405B]/70">
                            {item.Gender + ", "}
                          </li>
                        ) : null}
                        {item && item.Country ? (
                          <li className="text-sm text-[#3D405B]/70">
                            {item.Country}
                          </li>
                        ) : null}
 
                      </ul>
                    </div>
                  </div>
                ) : null
              )
            ) : (
              <>
                {!loading ? (
                  <div className="pt-40 text-2xl text-center text-gray-400">
                    Data not available!
                  </div>
                ) : null}
              </>
            )}
            <h1 className="text-sm text-[#3d405B]/70 py-4">
              * We are not showing data where employee count is less than 5
            </h1>

            {/* 
            <div className="flex items-center justify-center py-8 text-center">
            <span onClick={()=>this.prevEmpPage()} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-gray-400 border border-transparent rounded-full select-none material-symbols-outlined" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_left</span>
            {IndexListIndex && IndexListIndex.length>0?
                IndexListIndex.map((item,index)=>
                    (index >= EmpIndexA && index <= EmpIndexB)?
                    (item===currentEmpPage)?
                    <span onClick={()=>this.SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-blue-500 border border-transparent rounded-full">{item}</span>
                    :
                    <span onClick={()=>this.SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm bg-white border rounded-full">{item}</span>
                    :null
                )

            :null}
                <span onClick={()=>this.nextEmpPage(IndexListIndex)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-blue-500 bg-white border rounded-full select-none material-symbols-outlined" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_right</span>
            </div>
            <div className="py-4 mb-10 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div> */}
          </div>

          <div className="flex justify-center items-center space-x-6 text-[#3D405B] text-sm font-medium p-6 border-t">
            <p className="">Pages</p>
            <div className="flex border divide-x rounded-md ">
              <button
                onClick={() => this.prevEmpPage()}
                className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition"
              >
                Previous
              </button>

              {IndexListIndex && IndexListIndex.length > 0
                ? IndexListIndex.map((item, index) =>
                    index >= EmpIndexA && index <= EmpIndexB ? (
                      item === currentEmpPage ? (
                        <button
                          onClick={() => this.SelectEmpPage(item)}
                          className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] "
                        >
                          {item}
                        </button>
                      ) : (
                        <button
                          onClick={() => this.SelectEmpPage(item)}
                          className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition "
                        >
                          {item}
                        </button>
                      )
                    ) : null
                  )
                : null}

              <button
                onClick={() => this.nextEmpPage(IndexListIndex)}
                className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition"
              >
                Next
              </button>
            </div>
            {IndexListIndex && IndexListIndex.length > 0?
              <p>{currentEmpPage} of {IndexListIndex && IndexListIndex.length > 0?IndexListIndex.length:0}</p>
            :null}
            
          </div>

          {/* end */}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(WordDetails);
