import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../../../../_actions";
import Filter from "../../../../../pages/CommonComponents/Filters/IndexFilter/Filter";
import ReactEcharts from "echarts-for-react";
//libraries
import * as echarts from 'echarts';
class AppraisalCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: "none",
      showLeaderBoard: false,
      isMultifilter: false,
      currentSelectValue: "",
      FilterValues: [],
      showFilter: false,
      showValue: "",
      ListName: "Demographic",
      showEDAModal: false,
      crrDMGValue: "",
      activeTab: "AppraisalStatus",
      activeCard: 'selfAppraisalStatus',
      mainTab: "AppraisalCycle",
      showhide: false
    };
  }

  componentDidMount() { }

  handleTabClick = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };

  handleMultifilterIndexScore2 = (finalFilter, name) => {
    let { filterPMSEmployeeWiseScore } = this.props;
    this.setState({ FilterValues: finalFilter, ListName: name });
    filterPMSEmployeeWiseScore(finalFilter, true, name);
  };

  render() {
    let { pms, dashboard, survey } = this.props;
    let { EmpFilterData, getEMPFilterLabel, getEmployeeRatingAndNormalization } = dashboard;
    let { getIndexByFilterDMGData } = survey;

    let { getEMPASData, getDashboardOverviewStatusData, getPMSEWScore, PMSGetDetailsByAppraisalCycleId } = pms;
    let { activeTab, activeCard, showhide } = this.state;

    let EmpFilterData2 = [];
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== "ManagerPID") {
          EmpFilterData2.push(emp);
        }
      });
    }

    // console.log("getIndexByFilterDMGData--------->", getIndexByFilterDMGData)
    const completedCountSelfAppraisal = getDashboardOverviewStatusData?.selfAppraisalStatus?.completed || 0;
    const pendingCountSelfAppraisal = getDashboardOverviewStatusData?.selfAppraisalStatus?.pending || 0;
    const totalSelfAppraisalStatus = completedCountSelfAppraisal + pendingCountSelfAppraisal;

    // Extract completed and pending counts from reviewStatus
    const completedCountMultiRater = getDashboardOverviewStatusData?.multiRaterStatus?.completed || 0;
    const pendingCountMultiRater = getDashboardOverviewStatusData?.multiRaterStatus?.pending || 0;
    const totalMultiRaterStatus = completedCountMultiRater + pendingCountMultiRater;

    // Extract completed and pending counts from reviewStatus
    const completedCountReviewStatus = getDashboardOverviewStatusData?.reviewStatus?.completed || 0;
    const pendingCountReviewStatus = getDashboardOverviewStatusData?.reviewStatus?.pending || 0;
    const totalReviewStatus = completedCountReviewStatus + pendingCountReviewStatus;

    let data5 = (getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.normlLabelCount ? getEmployeeRatingAndNormalization.normlLabelCount : {})
    let data6 = (getEmployeeRatingAndNormalization && getEmployeeRatingAndNormalization.labels ? getEmployeeRatingAndNormalization.labels : {})
    var labelsListScore = data5 ? Object.keys(data5).map((key) => [key, data6[key], data5[key]]) : [];

    console.log("getPMSEWScore--->111111111111111111111111111", getPMSEWScore)

    function undefinedZero(value, f) {
      return value && f && value[f] ? value[f] : 0
    }


    return (
      <>

        <div className="items-center justify-between md:flex">
          <div className="flex flex-wrap gap-2">
            {/* <div className="cursor-pointer bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] flex justify-between px-4 items-center relative py-2 rounded-md w-40 "> */}
            <div className="flex items-center space-x-2 ">
              <Filter
                showFilter={this.state.showFilter}
                showValue={this.state.showValue}
                closeFilter={this.closeFilter}
                EmpFilterData2={EmpFilterData2}
                getIndexFilterData={getIndexByFilterDMGData}
                handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
                getEMPFilterLabel={getEMPFilterLabel}
              />
              {/* </div> */}
            </div>
            {/* <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                  <span className=" text-sm text-[#3D405B]/70 font-medium ">
                    Location
                  </span>
                  <span className="material-symbols-outlined ">
                    arrow_drop_down
                  </span>
                </div>
                <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                  <span className=" text-sm text-[#3D405B]/70 font-medium ">
                    Department
                  </span>
                  <span className="material-symbols-outlined ">
                    arrow_drop_down
                  </span>
                </div> */}
          </div>
          <div className="flex space-x-2 ">
            <button className="flex items-center justify-center p-2 bg-white border rounded-md" onClick={() => this.setState({ showhide: false })}>
              <span className="material-symbols-rounded  text-[1.5rem] text-[#2196f3]">
                grid_view
              </span>
            </button>
            {/* chnage here */}
            <button className="flex items-center justify-center p-2 bg-white border rounded-md" onClick={() => this.setState({ showhide: true })}>
              <span className="material-symbols-rounded  text-[1.5rem] ">
                format_list_bulleted
              </span>
            </button>
          </div>
        </div>


        <div className="p-6 space-y-6 bg-white border rounded-md">
          <h1 className="text-xl font-medium text-[#3D405B]">Appraisal Score</h1>
          <div className="overflow-x-auto border border-gray-200 ">
            <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse xl:whitespace-normal whitespace-nowrap">
              <thead className="bg-white">
                <tr className="">
                  <th rowspan="2" className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r">S.No</th>
                  <th rowspan="2" className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r">Employees</th>
                  <th rowspan="2" className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r">Reviewer</th>
                  <th colSpan="3" className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6">Modules</th>
                  <th rowspan="2" className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r">Final Score</th>
                  <th rowspan="2" className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r">Rating</th>
                  {undefinedZero(PMSGetDetailsByAppraisalCycleId, "normlActive") ?
                    <th rowspan="2" className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center"> Normalized Rating</th>
                    : null}
                </tr>
                <tr className="bg-[#f9fafb] border-t text-sm text-[#3D405B]">
                  <th className="p-3 px-6 font-semibold border-r">Goal</th>
                  <th className="p-3 px-6 font-semibold border-r">KRA</th>
                  <th className="p-3 px-6 font-semibold border-r">360 Feedback</th>
                </tr>
              </thead>
              <tbody className="border-t divide-y ">
                {getPMSEWScore && getPMSEWScore.length > 0 ?
                  getPMSEWScore.map((element, index) => (
                    <>
                      {/* {JSON.stringify(element)}*/}
                      <tr key={index} className="bg-white  font-medium text-sm text-[#3D405B] ">
                        <td className="px-6 py-3 text-center border-r">{index + 1}</td>
                        <td className="px-6 py-3 border-r">
                          <div className="flex items-center space-x-2">
                            <img src="img/newimg/Rectangle 11116.png" alt="" className="w-12 h-12 rounded-full" />
                            <div className="">
                              <span className="capitalize text-base font-medium block text-[#3D405B]">{element && element.reviewee_name ? element.reviewee_name : "NA"}</span>
                              <p className="capitalize text-sm  block text-[#3D405B] font-normal">{element && element.reviewee_emp_id ? element.reviewee_emp_id : "NA"}</p>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-3 text-center border-r">NA</td>
                        <td className="p-3 px-6 text-center border-r"> {(element && element.status && element.status.rating ? (element.status.rating) : 'NA')}</td>
                        <td className="p-3 px-6 text-center border-r">NA</td>
                        <td className="p-3 px-6 text-center border-r ">NA</td>
                        <td className="px-6 py-3 text-center border-r">{(element && element.status && element.status.rating ? (element.status.rating) : 'NA')}</td>
                        <td className="px-6 py-3 text-center border-r">{(element && element.status && element.status.scoreLabel ? (element.status.scoreLabel) : 'NA')}</td>
                        {undefinedZero(PMSGetDetailsByAppraisalCycleId, "normlActive") ?
                          <td className="px-6 py-3 text-center">{(element && element.status && element.status.normlLabel ? (element.status.normlLabel) : 'NA')}</td>
                          : null}
                      </tr>


                      {/* <tr key={index} className="bg-white font-medium text-sm text-[#3D405B]">
                        <td className="px-6 py-3 text-center border-r">{index + 1}</td>
                        <td className="px-6 py-3 border-r">
                          <div className="flex items-center space-x-2">
                            <img
                              src="img/newimg/Rectangle 11116.png"
                              alt=""
                              className="w-12 h-12 rounded-full"
                            />
                            <div className="">
                              <span className="capitalize text-base font-medium block text-[#3D405B]">
                                {element.reviewee_name}
                              </span>
                              <p className="capitalize text-sm block text-[#3D405B] font-normal">
                                HR Manager - Dept of Human Resources
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-3 text-center border-r">
                          {element && element.reviewer_name ? element.reviewer_name : ""}
                        </td>
                        <td className="p-3 px-6 text-center border-r">
                          {element.status ? element.status.completed : ""}
                        </td>
                        <td className="p-3 px-6 text-center border-r">5.0</td>
                        <td className="p-3 px-6 text-center border-r">5.0</td>
                        <td className="px-6 py-3 text-center border-r">
                          {element.status ? element.status.rating : ""}
                        </td>
                        <td className="px-6 py-3 text-center border-r">
                          {element.status ? element.status.scoreLabel : ""}
                        </td>
                        {undefinedZero(PMSGetDetailsByAppraisalCycleId, "normlActive") ? (
                          <td className="px-6 py-3 text-center">Outstanding</td>
                        ) : null}
                      </tr> */}
                    </>
                  )) : null
                }
              </tbody>
            </table>
          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, pms, survey } = state;
  return {
    loggingIn,
    dashboard,
    users,
    pms,
    survey
  };
}
export default connect(mapStateToProps)(AppraisalCycle);
