import React from 'react';
import GaugeScore from './Charts/GaugeScore';


export default function ScoreBenchmark(props) {
    
    let { 

        item,

        DistributionData,
        Benchmark,
        cronBachAlpha,
        indexOverallValue,
        isCrole,
        name


     } = props;


    //  let Benchmark ={
    //    score:0
    //  }


     //console.log('Benchmark: ',Benchmark)

    return (
      <>	

                     {/* <div className="h-60" /> */}
                     <div className="flex justify-center item-center">
                    <GaugeScore item={item} Benchmark={Benchmark}/>
                  </div>


                  {isCrole?
                    <div className="flex justify-center pb-2">
                    <div className="text-sm items-center text-center mx-2 mt-12"><p className="text-gray-500 font-semibold">
                      {indexOverallValue && indexOverallValue.toString()?"Organization "+name+" Score: " +indexOverallValue.toString()+"%":"NaN"}</p></div>
                  </div>
                  :<div />}


      </>
    );
  }
