import React, { Component } from 'react';
import { connect } from 'react-redux';
// import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let { getNoRiskResolved, AnswerObjList, riskMeterCountObj, DemographicValues, viewEmpAtRisk ,XAxis }= this.props;

    //console.log('xxxxx--------nps -----------------AnswerObjOverall: ',AnswerObjOverall)     
    


    DemographicValues.sort();

    // AnswerObjList
  // //console.log('AnswerObjList-------->',AnswerObjList);

    function getValueCount(axis,value){
      let riskMeterCountObjFilter = {
        "Extremely_High_Risk":0,
        "High_Risk":0,
        "Borderline":0,
        "Moderate_Risk":0,
        "No_Risk":0
      }
      // let score = 0
      if(axis && value && AnswerObjList && AnswerObjList.length>0){

        let list = AnswerObjList.filter(prev=>prev.name === axis)
        //console.log('list-------->',list);

        if(list && list.length>0){
          list.forEach((answ)=>{
            //console.log('answanswanswanswanswansw answanswanswanswansw-------->',answ);
            let getRiskScore = answ && answ.value?answ.value:0;


            let resolvedCategory = null;
            if(answ && answ.emp_id){
              resolvedCategory = getNoRiskResolved(answ.emp_id)
            }

            if(resolvedCategory){

              if(resolvedCategory === "Extremely_High_Risk"){
                riskMeterCountObjFilter["Extremely_High_Risk"]+=1;
              }
              else if(resolvedCategory === "High_Risk"){
                riskMeterCountObjFilter["High_Risk"]+=1;
              }
              else if(resolvedCategory === "Borderline"){
                riskMeterCountObjFilter["Borderline"]+=1;
              }
              else if(resolvedCategory === "Moderate_Risk"){
                riskMeterCountObjFilter["Moderate_Risk"]+=1;
              }
              else if(resolvedCategory === "No_Risk"){
                riskMeterCountObjFilter["No_Risk"]+=1;
              }
      
            }else{
              if(getRiskScore>=25 && getRiskScore<50){
                riskMeterCountObjFilter["Extremely_High_Risk"]+=1;
              }
              else if(getRiskScore>=50 && getRiskScore<75){
                riskMeterCountObjFilter["High_Risk"]+=1;
              }
              else if(getRiskScore>=75 && getRiskScore<100){
                riskMeterCountObjFilter["Borderline"]+=1;
              }
              else if(getRiskScore>=100 && getRiskScore<125){
                riskMeterCountObjFilter["Moderate_Risk"]+=1;
              }
              else if(getRiskScore>=125 && getRiskScore<=150){
                riskMeterCountObjFilter["No_Risk"]+=1;
              }
            }
            


          });
        }


        // score = DemographicValuesObj && DemographicValuesObj[axis] && DemographicValuesObj[axis][value]?DemographicValuesObj[axis][value]:0
      }
      return riskMeterCountObjFilter[value]
    }



    


    return (
      <>

      <div className="py-1  whitespace-nowrap overflow-x-auto pb-6 ">
      <table className="border-collapse w-full">
        <thead>

          <tr className="text-xs">
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300 ">{XAxis}</th>
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300 px-10">Extremely High Risk</th>
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300 px-10">High Risk</th>
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300 px-10">Borderline</th>
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300 px-10">Moderate Risk</th>
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300 px-10">No Risk</th>
          </tr>

        </thead>
        <tbody>

            <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                <td className="p-3 border border-b text-left" >    
                  {"OVERALL"}
                </td>
                <td onClick={()=>viewEmpAtRisk("overall","Extremely_High_Risk")} className="text-white cursor-pointer p-3 border border-b px-6 font-bold" style={{background:'#b31717'}}>    
                  {riskMeterCountObj["Extremely_High_Risk"]}
                </td>
                <td onClick={()=>viewEmpAtRisk("overall","High_Risk")} className="cursor-pointer p-3 border border-b px-6 font-bold" style={{background:'#e65757'}}>      
                  {riskMeterCountObj["High_Risk"]}
                </td>
                <td onClick={()=>viewEmpAtRisk("overall","Borderline")} className="cursor-pointer p-3 border border-b px-6 font-bold" style={{background:'#f29083'}}>      
                  {riskMeterCountObj["Borderline"]}
                </td>
                <td onClick={()=>viewEmpAtRisk("overall","Moderate_Risk")} className="cursor-pointer p-3 border border-b px-6 font-bold" style={{background:'#edb409'}}>      
                  {riskMeterCountObj["Moderate_Risk"]}
                </td>
                <td onClick={()=>viewEmpAtRisk("overall","No_Risk")} className="cursor-pointer p-3 border border-b px-6 font-bold" style={{background:'#1bbf36'}}>      
                  {riskMeterCountObj["No_Risk"]}
                </td>
              </tr>

          {DemographicValues && DemographicValues.length>0?
            DemographicValues.map((item,index)=>
            (item)?
              <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                <td className="p-3 border border-b text-left" >    
                  {item}
                </td>
                <td onClick={()=>viewEmpAtRisk(item,"Extremely_High_Risk")} className="text-white cursor-pointer p-3 border border-b px-6 font-bold" style={{background:'#b31717'}}>    
                  {getValueCount(item,"Extremely_High_Risk")}
                </td>
                <td onClick={()=>viewEmpAtRisk(item,"High_Risk")} className="cursor-pointer p-3 border border-b px-6 font-bold" style={{background:'#e65757'}}>      
                  {getValueCount(item,"High_Risk")}
                </td>
                <td onClick={()=>viewEmpAtRisk(item,"Borderline")} className="cursor-pointer p-3 border border-b px-6 font-bold" style={{background:'#f29083'}}>      
                  {getValueCount(item,"Borderline")}
                </td>
                <td onClick={()=>viewEmpAtRisk(item,"Moderate_Risk")} className="cursor-pointer p-3 border border-b px-6 font-bold" style={{background:'#edb409'}}>      
                  {getValueCount(item,"Moderate_Risk")}
                </td>
                <td onClick={()=>viewEmpAtRisk(item,"No_Risk")} className="cursor-pointer p-3 border border-b px-6 font-bold" style={{background:'#1bbf36'}}>      
                  {getValueCount(item,"No_Risk")}
                </td>
              </tr>
            :null
            ):null}



        </tbody>
      </table>
    </div>

</>
    
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
