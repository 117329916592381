import React from 'react';
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';
import { Grid } from '@material-ui/core';

export default function BarCorelation(props) {

  let { DriverName, DriverValues, RawValues, ThirdValues } = props;


  let XAxis = DriverName
  let List = DriverValues
  let RawData = RawValues;
  let ThirdData = ThirdValues


  return (
    <>
      <div className="w-full">
        <ReactEcharts
          className='!h-96 !w-full'
          option={{
            tooltip: {
              trigger: 'item',
              formatter: function (a) {
                // console.log('params: ', a)
                return '<b>' + XAxis[a.dataIndex] + '</b></br>' +
                  'Relative Importance: ' + ((a.value) ? a.value.toFixed(2) : 0).toString() + '</br>' +
                  'Co-efficient/Impact Score: ' + ((ThirdData[a.dataIndex]) ? ThirdData[a.dataIndex].toFixed(2) : 0).toString()
              }
            },
            xAxis: {
              type: 'category',
              data: XAxis,
              axisLabel: {
                show: true,
                textStyle: {
                  fontSize: 12,
                  fontWeight: 'bold'
                },
                rotate: 40,
                interval: 0,
                formatter: function (a) {
                  return a ? a.length > 12 ? a.slice(0, 12) + '..' : a : a
                }
              }
            },
            yAxis: {
              type: 'value'
            },
            series: [{
              data: List,
              type: 'bar',
              showBackground: true,
              barWidth: '30px',
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#62cff4'
                },
                {
                  offset: 1,
                  color: '#2c67f2'
                }]),
            }],
            grid: {
              left: '16px',
              top: '10px',
              right: '16px',
              bottom: '20px',
              containLabel: true
            },
          }}

        />
        <div className="text-center mb-4 flex md:flex-row flex-col items-center justify-center gap-4 font-medium ">
          <p><b>X-Axis:</b> Organization Core Drivers</p>
          <p><b>Y-Axis:</b> Relative weight</p>
        </div>


      </div>

    </>
  );
}
