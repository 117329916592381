import React, { useState } from "react";
import Header from './Header/Header';
export default function SalaryHikeStatus() {

  const [open, close] = useState(0);
  return (
    <div className="h-screen overflow-hidden bg-gray-50">
      <Header />

      <div
        className="flex flex-row w-full"
        style={{ height: "calc(100% - 2rem)" }}
      >
        <div
          className="cursor-pointer transition-width w-64  lg:block lang lg:relative fixed top-0 left-0 z-20  h-screen     duration-200 transform bg-white border-r hidden"
          style={{ height: "calc(100% - 0rem)" }}
        >
          <div className="my-4 whitespace-nowrap ">
            <div
              title="Users"
              className="flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer   border-l-4 border-[#2196F3] bg-[#2196F3]/10"
            >
              <div className="flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center">
                <span className=" material-symbols-outlined">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-users"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth="1.8"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx={9} cy={7} r={4} />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                </span>
              </div>
              <span className=" text-sm text-[#2196F3]">Appraisal Cycle</span>
            </div>
          </div>
        </div>

        <main
          className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto  md:p-6 p-4 space-y-6  "
          style={{ height: "calc(100% - 4rem)" }}
        >
          <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
            <div className="flex items-center space-x-4 ">
              <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">
                Salary Hike
              </h1>
            </div>
            <div className="md:flex items-center text-sm hidden">
              <span className="mx-2 material-symbols-rounded">home</span>
              <span>/</span>
              <span className="px-2">Employee Engagement</span>
              <span>/</span>
              <span className="px-2 ">Dashboard</span>
              <span>/</span>
              <span className="px-2 font-semibold">Manager Score Card</span>
            </div>
          </div>

          <div className=" md:flex  justify-between items-center lg:space-y-0 space-y-4">
            <div className="flex gap-2 flex-wrap">
              <div className="cursor-pointer bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] flex justify-between px-4 items-center relative py-2 rounded-md w-40 ">
                <div className="flex space-x-2 items-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-filter"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#fff"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
                  </svg>
                  <span className=" text-sm text-white ">Filters</span>
                </div>
                <span className=" bg-white  rounded-full text-[#2196F3] text-xs w-6 h-6 flex items-center justify-center font-semibold">
                  3
                </span>
              </div>
              <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                <span className=" text-sm text-[#3D405B]/70 font-medium ">
                  Location (All)
                </span>
                <span className="material-symbols-outlined text-[#3D405B]/70">
                  arrow_drop_down
                </span>
              </div>
              <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                <span className=" text-sm text-[#3D405B]/70 font-medium ">
                  Department (All)
                </span>
                <span className="material-symbols-outlined text-[#3D405B]/70">
                  arrow_drop_down
                </span>
              </div>
              <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                <span className=" text-sm text-[#3D405B]/70 font-medium ">
                  Cadre (All)
                </span>
                <span className="material-symbols-outlined text-[#3D405B]/70">
                  arrow_drop_down
                </span>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
            <div className=" bg-white hover:shadow-lg duration-150 transition  border p-6 rounded-lg   cursor-pointer">
              <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">
                Hike Appraiser Status
              </h1>
              <div className="flex justify-center item-center">
                <div className="pt-1 justify-center items-center">
                  <div>
                    <svg
                      className="speedometer"
                      width="300px"
                      height="250px"
                      role="img"
                      focusable="false"
                      aria-label="React d3 speedometer"
                      style={{ width: "300px", height: "250px" }}
                    >
                      <g className="arc" transform="translate(150, 150)">
                        <path
                          className="speedo-segment"
                          fill="#e53935"
                          d="M-130,-1.592040838891559e-14A130,130,0,0,1,7.960204194457796e-15,-130L5.8170722959499274e-15,-95A95,95,0,0,0,-95,-1.1634144591899855e-14Z"
                        />
                        <path
                          className="speedo-segment"
                          fill="#ffc107 "
                          d="M7.960204194457796e-15,-130A130,130,0,0,1,91.92388155425118,-91.92388155425117L67.17514421272202,-67.175144212722A95,95,0,0,0,5.8170722959499274e-15,-95Z"
                        />
                        <path
                          className="speedo-segment"
                          fill="#00cd7e"
                          d="M91.92388155425118,-91.92388155425117A130,130,0,0,1,130,0L95,0A95,95,0,0,0,67.17514421272202,-67.175144212722Z"
                        />
                        <path
                          className="speedo-segment"
                          fill="#ff4081"
                          d="M130,0L95,0Z"
                        />
                      </g>
                      <g className="label" transform="translate(150, 150)">
                        <text
                          transform="rotate(-90) translate(0, -140)"
                          className="segment-value"
                          style={{
                            textAnchor: "middle",
                            fontSize: "14px",
                            fontWeight: "bold",
                            fill: "rgb(102, 102, 102)",
                          }}
                        >
                          0
                        </text>
                        <text
                          transform="rotate(0) translate(0, -140)"
                          className="segment-value"
                          style={{
                            textAnchor: "middle",
                            fontSize: "14px",
                            fontWeight: "bold",
                            fill: "rgb(102, 102, 102)",
                          }}
                        >
                          3
                        </text>
                        <text
                          transform="rotate(45) translate(0, -140)"
                          className="segment-value"
                          style={{
                            textAnchor: "middle",
                            fontSize: "14px",
                            fontWeight: "bold",
                            fill: "rgb(102, 102, 102)",
                          }}
                        >
                          4.5
                        </text>
                        <text
                          transform="rotate(90) translate(0, -140)"
                          className="segment-value"
                          style={{
                            textAnchor: "middle",
                            fontSize: "14px",
                            fontWeight: "bold",
                            fill: "rgb(102, 102, 102)",
                          }}
                        >
                          6
                        </text>
                        <text
                          transform="rotate(90) translate(0, -140)"
                          className="segment-value"
                          style={{
                            textAnchor: "middle",
                            fontSize: "14px",
                            fontWeight: "bold",
                            fill: "rgb(102, 102, 102)",
                          }}
                        >
                          6
                        </text>
                      </g>
                      <g transform="translate(150, 150)">
                        <text
                          className="current-value"
                          textAnchor="middle"
                          y={23}
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            fill: "rgb(102, 102, 102)",
                          }}
                        >
                          28.06
                        </text>
                      </g>
                      <g
                        className="pointer"
                        transform="translate(150, 150)"
                        style={{ fill: "rgb(77, 184, 255)" }}
                      >
                        <path
                          d="M5,0C3.333333333333333,-67.5,1.6666666666666667,-135,0,-135C-1.6666666666666667,-135,-3.333333333333333,0,-5,0C-3.333333333333333,0,-1.6666666666666667,5,0,5C1.6666666666666667,5,3.333333333333333,2.5,5,0"
                          transform="rotate(31.799987792968775)"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="gauge-outer">
                    <div
                      className="gauge-inner"
                      style={{ transform: "rotate(calc(-45deg))" }}
                    />
                  </div>
                </div>
              </div>
              <div />
              <div className="w-full flex justify-center space-x-4">
                <div className="flex space-x-2 items-center">
                  <span className="w-4 h-4 rounded-full bg-amber-500 "></span>
                  <p><b className="font-semibold">4</b> Pending</p>
                </div>
                <div className="flex space-x-2 items-center">
                  <span className="w-4 h-4 rounded-full bg-green-500 "></span>
                  <p><b className="font-semibold">6</b> Completed</p>
                </div>



              </div>
            </div>
            <div className=" bg-white hover:shadow-lg duration-150 transition  border p-6 rounded-lg   cursor-pointer">
              <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">
                Hike Approver Status
              </h1>
              <div className="py-4">
                <div className="w-full flex justify-center space-x-4">
                  <div className="flex space-x-2 items-center">
                    <span className="w-4 h-4 rounded-full bg-amber-500 "></span>
                    <p><b className="font-semibold">4</b> Pending</p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <span className="w-4 h-4 rounded-full bg-green-500 "></span>
                    <p><b className="font-semibold">4</b> Pending</p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <span className="w-4 h-4 rounded-full bg-red-600 "></span>
                    <p><b className="font-semibold">4</b> Pending</p>
                  </div>


                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 space-y-6 border rounded-md">
            <h1 className="text-xl font-medium text-[#3D405B]">
              Appraiser List
            </h1>
            <div className="overflow-x-auto whitespace-nowrap   border border-gray-200 rounded-lg ">
              <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse">
                <thead className="bg-white">
                  <tr className="bg-[#f9fafb]">
                    <th
                      scope="col"
                      className="px-6 py-3 font-semibold text-sm text-[#3D405B] w-[40%]"
                    >
                      Hike Appraiser
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center"
                    >
                      Employees
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center"
                    >
                      Status
                    </th>

                  </tr>
                </thead>
                <tbody className="border-t border-gray-100 divide-y divide-gray-100">
                  <tr className="bg-white ">
                  <td className="px-4 py-3  ">
                    <div className="flex items-center space-x-2">
                       <div className="w-12">
                       <img
                          src="img/newimg/Rectangle 11116.png"
                          alt=""
                          className="rounded-full w-12 h-12  "
                        />
                       </div>
                        <div className="flex-1">
                          <p className="capitalize text-base font-medium block text-[#3D405B]">
                            Hari KC
                          </p>
                          <p className="capitalize text-sm  block text-[#3D405B] font-normal">
                            HR Manager - Dept of Human  
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex justify-center"><span className="cursor-pointer  px-6 bg-[#f9fafb] py-1 border rounded-md flex space-x-1 font-medium items-center"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg><span>9</span></span></div>
                    </td>
                    <td className="px-6 py-4 text-center">
                      <button className="  bg-[#FFA000] text-white w-32 px-8 py-2 rounded-md uppercase text-sm font-medium ">Pending</button>
                    </td>

                  </tr>
                  <tr className="bg-white ">
                  <td className="px-4 py-3  ">
                    <div className="flex items-center space-x-2">
                       <div className="w-12">
                       <img
                          src="img/newimg/Rectangle 11116.png"
                          alt=""
                          className="rounded-full w-12 h-12  "
                        />
                       </div>
                        <div className="flex-1">
                          <p className="capitalize text-base font-medium block text-[#3D405B]">
                            Hari KC
                          </p>
                          <p className="capitalize text-sm  block text-[#3D405B] font-normal">
                            HR Manager - Dept of Human  
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex justify-center"><span className="cursor-pointer  px-6 bg-[#f9fafb] py-1 border rounded-md flex space-x-1 font-medium items-center"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg><span>12</span></span></div>
                    </td>

                    <td className="px-6 py-4 text-center">
                      <button className="  bg-green-600 text-white w-32 px-8 py-2 rounded-md uppercase text-sm font-medium ">Completed</button>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div className="flex md:flex-row gap-6 flex-col items-center justify-between border-t p-6 -m-6">
              <p className="text-[#3D405B] md:text-base text-sm font-semibold">Showing 1 to 5 of 150 entries</p>
              <div className="flex border divide-x rounded-md "><button className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">Previous</button><button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">1</button><button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">2</button><button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">3</button><button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">4</button><button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">5</button><button className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">Next</button></div></div>
          </div>
        </main>
      </div>
    </div>
  );
}