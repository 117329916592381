import React, { Component } from 'react';
import { connect } from 'react-redux';
import Filter from '../../../CommonComponents/Filters/IndexFilter/Filter'
import BarChartTalents from './Charts/BarChartTalents';

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
        showMainPage: true,
        showFilter: false,
        showValue: '',

    }
  }

  componentDidMount() {

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  openSurveyResponse = () => {
    this.setState({ showOverview: false });

  }

  closeResponseAnalytics = () => {
    this.setState({ showOverview: true })
  }

  onOptionType = (value) => {
    this.setState({ optionType: value })
  }


  handleStrength=(params)=>{
    let { handleThemeStrenght } = this.props;
    let { FilterValues } = this.state;
    let name = ''
    if(params && params.name){
        name = params.name;
    }
    handleThemeStrenght(name,FilterValues);
    this.setState({showMainPage:false})
  }


  handleMultifilterIndexScore2 = (finalFilter, name) => {

    let { handlAssessmentDashboard } = this.props;
    this.setState({ 
      FilterValues: finalFilter, 
      ListName: name, 
      showMainPage:true 
    });

    handlAssessmentDashboard(finalFilter,false);

  }

  handleGOTOTheme=()=>{
    this.setState({showMainPage:true})
  }

  render() {

    let { loading, getAssessmentDimensionsData, getAssessmentDashboardData, getAssessmentByThemeData, EmpFilterData, getIndexByFilterDMGData, getEMPFilterLabel } = this.props;
    let { showMainPage } = this.state;

  
    function spliceDecimal(no){
      if(no){
        let str = no.toString();
        let first = str.split('.')[0];
        let second = str.split('.')[1];
        let second2 = second?second.substring(0, 2):""
        let final = first+'.'+second2;
        if(second2){
          return parseFloat(final).toFixed(2)
        }else{
          return no
        }
      }else{
        return 0
      }
    }
    




    let themetotal = 0;
    let themeScoreList = [];
    if(getAssessmentDashboardData && getAssessmentDashboardData.length>0){
      themetotal = getAssessmentDashboardData.reduce(
        (a, v) => a + v.count,
        0
      );
      getAssessmentDashboardData.forEach((element)=>{
        if(element && element._id){
          themeScoreList.push({
            "name":element._id,
            "score":spliceDecimal((Number(element["count"])*100 / themetotal))
          })
          
        }
      })
    }

    let strenghttotal = 0;
    let strenghtScoreList = [];
    if(getAssessmentByThemeData && getAssessmentByThemeData.length>0){
        strenghttotal = getAssessmentByThemeData.reduce(
        (a, v) => a + v.count,
        0
      );
      getAssessmentByThemeData.forEach((element)=>{
        if(element && element._id){
          strenghtScoreList.push({
            "name":element._id,
            "score":spliceDecimal((Number(element["count"])*100 / strenghttotal))
          })
          
        }
      })
    }


    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }









    let AssessmentList = []
    if(getAssessmentDimensionsData && getAssessmentDimensionsData.length>0){
      getAssessmentDimensionsData.forEach((element)=>{
        AssessmentList.push({
          ...element,
          finalSum:(Number(element.count)*Number(element.rankSum))
        })
      })
      AssessmentList.sort((a,b)=>(a.count -b.count))
    }

    console.log("AssessmentList--1->",AssessmentList)



    console.log("//////////////////////////////getAssessmentDimensionsData//////////////////////////////",getAssessmentDimensionsData)


    let newList = []
    if(AssessmentList && AssessmentList.length>0){
      AssessmentList.forEach((element,index) => {

        let getIndex = newList.findIndex(prev=>prev.bucket === element.bucket)
        if(getIndex===-1){
          let temp = {
            "showCount":0,
            "bucket":element.bucket,
            "colorCode":element.colorCode,
            "dimensions":[{
              ...element,
              "rank":index+1
            }],
          }
          newList.push(temp)
        }else{
          newList[getIndex]["showCount"] +=1
          newList[getIndex]["dimensions"].push({
            ...element,
            "rank":index+1
          })
        }
     
        
  
      });
    }





    return (
      <>
      <div className=" md:flex  justify-between items-center ">



     


      <Filter
        showFilter={this.state.showFilter}
        showValue={this.state.showValue}
        closeFilter={this.closeFilter}

        EmpFilterData2={EmpFilterData2}
        getIndexFilterData={getIndexByFilterDMGData}
        handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        getEMPFilterLabel={getEMPFilterLabel}
      />



      </div>


      <div className="w-full mt-4 " >

          <div className="grid md:grid-cols-1 grid-cols-1 gap-4 ">

            <div className="p-4 bg-white border my-1">
              <div className='flex pb-2'>
                  <h1 className="text-base font-medium  pb-2 mt-2">Dominant Theme Frequency</h1>
                </div>
                  <BarChartTalents
                    list={AssessmentList}
                  />

            {!(AssessmentList && AssessmentList.length>0)?
            <>
            {!loading ?
              <div className="py-24 text-center text-xl text-[#3d405B]/70">No Data Available</div>
              : null}
            </>
          :null}
            </div>

          </div>


   



      </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
