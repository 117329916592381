import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";

class PlusLogin extends Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(userActions.logout());

    this.props.dispatch(userActions.logoutSession());

    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      email: "",
      otp_code: "",
      showLogin: true,
      failureMSG: "",
      failureOTPMSG: "",
    };
  }
  componentDidMount() {
    // this.props.dispatch(dashboardActions.emptyClientProfile());

    var slideIndex = 0;
    showSlides();

    function showSlides() {
      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      slideIndex++;
      if (slideIndex > slides.length) {
        slideIndex = 1;
      }
      for (i = 0; i < dots.length; i++) {
        // ////console.log("dots",dots.length)
        dots[i].className = dots[i].className.replace(" activeslide", "");
      }
      if (slides[slideIndex - 1] && slides[slideIndex - 1].style) {
        slides[slideIndex - 1].style.display = "block";
      }
      if (dots[slideIndex - 1] && dots[slideIndex - 1].className) {
        dots[slideIndex - 1].className += " activeslide";
      }

      setTimeout(showSlides, 5000); // Change image every 5 seconds
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user.UserLoginFailure) {
      return {
        ...nextProps,
        failureMSG:
          "If this is registered username/Mail ID you will recieve one time password reset link",
      };
    }
    if (nextProps.user.UserLoginOTPFailure) {
      return {
        ...nextProps,
        failureOTPMSG: "Invalid or expired OTP !",
      };
    }
    if (nextProps.user.UserLoginEmailSuccess) {
      return {
        ...nextProps,
        // showLogin:false
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }
  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }
  loginSubmit = () => {
    let { email } = this.state;
    this.props.dispatch(userActions.userlogin({ username: email }, this.props));
    this.setState({ failureMSG: "", showLogin: false });
  };

  changeUsername = () => {
    this.setState({ showLogin: true, failureMSG: "", otp_code: "", email: "" });
  };

  OTPSubmit = () => {
    const { user } = this.props;
    const { UserEmailToken } = user;
    // ////console.log("UserEmailToken: ",UserEmailToken)
    let { otp_code } = this.state;
    let data = {
      token: UserEmailToken,
      otp: otp_code,
    };
    // ////console.log("data: ",data)
    this.setState({ failureOTPMSG: "" });

    this.props.dispatch(userActions.validateOtp(data, this.props));
  };
  render() {
    const { user } = this.props;
    const { loginOtpErrorMSG, UserLoginErrorMSG, userLoginStatus } = user;

    let exceedMSG = "";
    if (loginOtpErrorMSG === "Exceeded no of attempts.") {
      exceedMSG = "Exceeded no of attempts, OTP section is locked for 60 min.";
    }

    let lockedMSG = "";
    if (UserLoginErrorMSG === "Your Account is locked for some time.") {
      lockedMSG = "Your Account is locked for some time.";
    } else if (
      UserLoginErrorMSG &&
      UserLoginErrorMSG.message &&
      UserLoginErrorMSG.message === "Too Many Requests"
    ) {
      lockedMSG = "Too many request, please try again after 10 min";
    }

    let emailAttemptExceed = "";
    if (userLoginStatus === 429) {
      emailAttemptExceed = "Too many request, please try again after 10 min";
    }

    ////console.log("UserLoginErrorMSG------>", UserLoginErrorMSG);
    ////console.log("emailAttemptExceed", emailAttemptExceed);

    return (
      <div className="flex h-screen ">
        {/* slider picture area */}
        <div className=" bg-[#2196f3] w-5/12 h-full lg:flex hidden flex-col justify-center  items-center space ">
          <img
            src="/img/login/talent_plus_assessment.svg"
            className="w-11/12 mx-auto"
          />
          <p className="text-xl font-normal capitalize text-white  ">
            Powered by <b className="font-semibold">Happyplus</b>
          </p>
        </div>
        {/* Login area */}
        <div className=" lg:w-7/12 w-full h-full flex justify-center items-center bg-slate-100">
          {this.state.showLogin ? (
            <div className=" md:w-[30rem] w-full p-6 md:mx-auto  md:my-6 my-4 space-y-10  ">
              <div className="  ">
                <img
                  src="img/logo/happypluslogo.png"
                  className="w-40 object-cover"
                />
              </div>

              <div className="space-y-10 text-[#3D405B] ">
                <div className="space-y-4 ">
                  <h1 className="md:text-4xl text-2xl font-semibold   ">
                    Login
                  </h1>
                  <p className="md:text-xl text-lg   ">
                    Enter your email address, We will send you an OTP to
                    verified Email ID
                  </p>
                </div>
                <div className="space-y-2">
                  <label className=" font-semibold text-[#3D405B] text-lg">
                    Enter Email
                  </label>
                  <input
                    className="border bg-white w-full p-3 px-4 text-lg rounded-md focus:outline-none ring-[#2196f3]  transition-all duration-200  focus:ring-1 "
                    type="email"
                    name="email"
                    required
                    value={this.state.email}
                    onChange={this.inputChange}
                    placeholder="example@gmail.com"
                  />

                  {lockedMSG ? (
                    <p className="text-gray-400 text-sm py-2 cursor-default md:w-96">
                      {lockedMSG}
                    </p>
                  ) : (
                    <>
                      {/* <p className="text-gray-400 text-sm py-2 cursor-default w-96">If this is registered username/Mail ID you will recieve one time password reset link</p> */}

                      {/* {!this.state.failureMSG ? (
                          <p className="text-gray-400 text-sm py-2 cursor-default w-96">
                            We will send you an OTP (One Time Password) on this
                            email address.
                          </p>
                        ) : (
                          <p className="text-gray-400 text-sm py-2 cursor-default w-96">
                            {emailAttemptExceed
                              ? emailAttemptExceed
                              : this.state.failureMSG}
                          </p>
                        )} */}
                    </>
                  )}
                </div>
                <button
                  className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                  disabled={this.state.email.length === 0}
                  onClick={() => this.loginSubmit()}
                >
                  <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[30rem] group-hover:h-[30rem] opacity-10"></span>
                  GET OTP
                </button>
              </div>
            </div>
          ) : (
            <div className=" md:w-[30rem] w-full p-6  md:mx-auto  md:my-6 my-4 space-y-10  ">
              <div>
                <img
                  src="img/logo/happypluslogo.png"
                  className="w-40 object-cover"
                />
              </div>

              <div className="space-y-6 text-[#3D405B] ">
                <div className="space-y-4 ">
                  <h1 className="md:text-4xl text-2xl font-semibold   ">
                    Verification
                  </h1>
                  <div className="">
                    <p className="text-gray-600  text-sm py-2  ">
                      We have send 6 digit OTP to:
                    </p>
                    <div className="flex justify-between items-end">
                      <p className="font-semibold">{this.state.email}</p>
                      <button
                        onClick={() => this.changeUsername()}
                        className="text-sm text-[#2196F3] flex items-center"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>

                <div className="space-y-2 pt-4">
                  <label className=" font-semibold text-[#3D405B] text-lg">
                    Enter OTP
                  </label>
                  <input
                    className="border bg-white w-full p-3 px-4 text-lg rounded-md focus:outline-none ring-[#2196f3]  transition-all duration-200  focus:ring-1 "
                    type="text"
                    name="otp_code"
                    required
                    value={this.state.otp_code}
                    onChange={this.inputChange}
                    placeholder="Enter OTP"
                  />

                  {exceedMSG ? (
                    <>
                      <p className="text-gray-400 text-sm py-2 cursor-default w-96">
                        {exceedMSG}
                      </p>
                    </>
                  ) : (
                    <>
                      {/* {!this.state.failureOTPMSG?
                        <p className="text-gray-400 text-sm py-2 cursor-default w-96">OTP is sent to {" "+this.state.email}</p> 
                        :
                        <p className="text-gray-400 text-sm py-2 cursor-default w-96">{this.state.failureOTPMSG}</p> 
                        } */}
                    </>
                  )}
                </div>

                <div className="flex justify-between items-center">
                  <p className="text-[#3D405B] font-medium text-sm py-2 ">
                    Didn't receive otp message ?
                  </p>
                  <button
                    onClick={() => this.loginSubmit()}
                    className="border border-[#2196F3] text-[#2196F3] rounded-md  px-4 py-2 text-sm hover:bg-[#2196F3]/10 transition duration-150 font-medium"
                  >
                    Resend OTP
                  </button>
                </div>

                <div
                  disabled={this.state.otp_code.length === 0}
                  onClick={() => this.OTPSubmit()}
                  className="w-full  uppercase text-lg font-medium rounded-md relative inline-flex group items-center justify-center px-4 py-3 cursor-pointer  bg-gradient-to-tr bg-[#2196f3] text-white overflow-hidden"
                >
                  <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-[30rem] group-hover:h-[30rem] opacity-10"></span>
                  VERIFY
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { user, dashboard } = state;
  return {
    loggingIn,
    user,
    dashboard,
  };
}
export default connect(mapStateToProps)(PlusLogin);
