import React, { Component } from "react";
import BarDistribution from "./Chart/BarDistribution";
import Header from "../Components/Header";

class Employee extends Component {
  render() {
    let {
      SummaryName,
      getIndexData,
      getIndex2Data,
      indexTemplate,

      getOrgDriverOverallData,
      getOrgIndexV2Data,
      getOrgIndexV22Data,

      getSurveyData,
      isFilter,
      surveyLogo,
      GetParameterLabel,
      CountRestrict,
      showYearData,
      yearValue1,
      yearValue2,
    } = this.props;

    let indexValue =
      getIndexData &&
        getIndexData[indexTemplate] &&
        parseFloat(getIndexData[indexTemplate].toFixed(2))
        ? parseFloat(getIndexData[indexTemplate].toFixed(2))
        : 0;

    let indexValue2 =
      getIndex2Data &&
        getIndex2Data[indexTemplate] &&
        parseFloat(getIndex2Data[indexTemplate].toFixed(2))
        ? parseFloat(getIndex2Data[indexTemplate].toFixed(2))
        : 0;

    let OrgCoreDriverData = {};
    let OrgCoreDriver2Data = {};

    // if (getOrgIndexV2Data && getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length > 0) {
    //     getSurveyData.orgList.forEach((org) => {
    //         OrgCoreDriverData[org.name] = getOrgIndexV2Data[org.name];
    //     })
    // }

    let getSurveyOrgList = [];
    if (
      getOrgIndexV2Data &&
      getSurveyData &&
      getSurveyData.orgList &&
      getSurveyData.orgList.length > 0
    ) {
      getSurveyData.orgList.forEach((org) => {
        let getIndex1 = getSurveyOrgList.findIndex((prev) => prev === org.name);
        if (getIndex1 === -1) {
          getSurveyOrgList.push(org.name);
        }
      });
    }

    if (getOrgIndexV2Data && getSurveyOrgList && getSurveyOrgList.length > 0) {
      getSurveyOrgList.forEach((org) => {
        OrgCoreDriverData[org] = {
          score:
            getOrgIndexV2Data && getOrgIndexV2Data[org]
              ? getOrgIndexV2Data[org]
              : 0,
          score2:
            getOrgIndexV22Data && getOrgIndexV22Data[org]
              ? getOrgIndexV22Data[org]
              : 0,
        };
      });
    }

    let isAllow = getOrgIndexV2Data
      ? CountRestrict(
        getOrgIndexV2Data && getOrgIndexV2Data["count"]
          ? getOrgIndexV2Data["count"]
          : 0
      )
        ? false
        : true
      : false;

    let valueList = [];

    valueList.push([
      SummaryName,
      {
        score: indexValue,
        score2: indexValue2,
      },
    ]);

    let valueList2 = [];

    if (OrgCoreDriverData) {
      valueList2 = Object.keys(OrgCoreDriverData).map((key) => [
        key,
        isAllow ? OrgCoreDriverData[key] : "NA",
      ]);
    }

    valueList = valueList.concat(valueList2);

    // valueList.sort((b, a) => a[1] - b[1]);

    console.log("1  valueList---->", valueList);
    console.log("1  getOrgIndexV2Data---->", getOrgIndexV2Data);
    console.log("1  getOrgIndexV22Data---->", getOrgIndexV22Data);

    function calcOverall(itemNamw) {
      return getOrgDriverOverallData &&
        getOrgDriverOverallData[itemNamw] &&
        getOrgDriverOverallData[itemNamw].toFixed(2)
        ? getOrgDriverOverallData[itemNamw].toFixed(2)
        : 0;
    }

    function calOverallIndexScore(itemData) {
      let overall =
        ((itemData &&
          itemData[1] &&
          itemData[1]["score"] &&
          itemData[1]["score"] !== "NA"
          ? itemData[1]["score"]
          : 0) +
          (itemData &&
            itemData[1] &&
            itemData[1]["score2"] &&
            itemData[1]["score2"] !== "NA"
            ? itemData[1]["score2"]
            : 0)) /
        2;

      return overall ? overall.toFixed(2) : "0";
    }

    return (
      <div>
        {/* Stress Vs Core Dimension Ranking55555555555555555 report design */}
        <main className="flex w-full px-8 space-x-16">
          <table className="w-full ">
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tbody>
              {valueList && valueList.length > 0
                ? valueList.map((itemData, index) => (
                  <>
                    {index % 13 === 0 ? (
                      <>
                        {index ? (
                          <tr className="text-lg text-gray-500 capitalize ">
                            <th
                              className="p-2 font-bold text-left text-gray-700 w-12/12 "
                              colSpan={3}
                            >
                              Continue...
                            </th>
                          </tr>
                        ) : null}

                        <tr className="my-2 page-break">
                          <td colSpan={3} className="text-left ">
                            <div className="pt-4 -mx-8">
                              <Header surveyLogo={surveyLogo} />

                              <div className>
                                <div className="w-full px-8 py-6 capitalize ">
                                  <h1 className="text-2xl text-[#3D405B] font-semibold border-b border-[#DDDDDD] pb-4 capitalize">
                                    {SummaryName} vs Core Dimension Ranking
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr className="text-left text-[#3D405B] capitalize  border-b border-white bg-[#F1F5F9] text-sm border-l border-t">
                          <th className="w-[25%] font-medium p-2  text-left border-r border-white">
                            Dimension
                          </th>
                          <th className="w-[60%] font-medium p-2  text-left border-r border-white">
                            Score
                          </th>
                          <th className="w-[5%] font-medium p-2  text-center border-r border-white">
                            overall
                          </th>
                        </tr>
                      </>
                    ) : null}

                    <tr className="border-b text-[#3D405B] text-sm border-l bg-[#F1F5F9] border-white">
                      <td className="p-2 font-medium capitalize border-r border-white">
                        {GetParameterLabel(itemData[0])}
                      </td>
                      <td className="p-2 font-medium capitalize border-r border-white">
                        <div className="flex items-center ">
                          <div className="w-full space-y-2">
                            <div className="py-2 space-y-0.5">
                              {showYearData(yearValue1, indexTemplate) ? (
                                <div className="flex flex-row  items-center gap-1.5 ">
                                  <div className="flex justify-end overflow-hidden text-right w-14">
                                    {yearValue1}
                                  </div>

                                  <div className="h-3 w-full   bg-[#CFD8DC] ">
                                    <div
                                      className="w-full h-3 bg-blue-400"
                                      style={{
                                        background: "#2196f3",
                                        width:
                                          (itemData &&
                                            itemData[1] &&
                                            itemData[1]["score"] &&
                                            itemData[1]["score"] !== "NA"
                                            ? itemData[1]["score"]
                                            : "0") + "%",
                                      }}
                                    />
                                  </div>

                                  <div className=" text-sm  font-medium text-left w-[90px] flex   overflow-hidden ">
                                    {itemData &&
                                      itemData[1] &&
                                      itemData[1]["score"] &&
                                      itemData[1]["score"] !== "NA" &&
                                      itemData[1]["score"].toFixed(2)
                                      ? itemData[1]["score"].toFixed(2) + "%"
                                      : "0"}
                                  </div>
                                </div>
                              ) : null}

                              {showYearData(yearValue2, indexTemplate) ? (
                                <div className="flex flex-row  items-center gap-1.5 ">
                                  <div className="flex justify-end overflow-hidden text-right w-14">
                                    {yearValue2}
                                  </div>

                                  <div className="h-3 w-full  bg-[#CFD8DC]">
                                    <div
                                      className="w-full h-3 bg-blue-400"
                                      style={{
                                        background: "#9576CD",
                                        width:
                                          (itemData &&
                                            itemData[1] &&
                                            itemData[1]["score2"] &&
                                            itemData[1]["score2"] !== "NA"
                                            ? itemData[1]["score2"]
                                            : "0") + "%",
                                      }}
                                    />
                                  </div>

                                  <div className="text-sm  font-medium text-left w-[90px] flex   overflow-hidden">
                                    {itemData &&
                                      itemData[1] &&
                                      itemData[1]["score2"] &&
                                      itemData[1]["score2"] !== "NA" &&
                                      itemData[1]["score2"].toFixed(2)
                                      ? itemData[1]["score2"].toFixed(2) + "%"
                                      : "0"}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </td>

                      {index === 0 ? (
                        <td className="p-2 font-medium capitalize border-r border-white">
                          <div className="h-6 px-2 text-sm font-medium text-center text-gray-500">
                            {calOverallIndexScore(itemData) + "%"}{" "}
                          </div>
                        </td>
                      ) : (
                        <td className="p-2 font-medium capitalize border-r border-white">
                          <div className="h-6 px-2 text-sm font-medium text-center text-gray-500">
                            {calcOverall(itemData[0]) + "%"}{" "}
                          </div>
                        </td>
                      )}
                    </tr>
                  </>
                ))
                : null}
            </tbody>
          </table>
        </main>
      </div>
    );
  }
}
export default Employee;
