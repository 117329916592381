import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class BarChartTalents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList: [],

    }
  }
  componentDidMount() {

  }


  render() {

    let { handleStrength, list } = this.props;


    let ParaNameList = []
    let insertData = []

    function barWidthPerc(value) {
      let valuePerc = Number(value) * 0.10
      console.log("value----->", value)
      console.log("valuePerc----->", valuePerc)
      return valuePerc
    }


    if (list && list.length > 0) {
      list.forEach((ele) => {

        ParaNameList.push(ele.dimension)
        insertData.push({
          value: barWidthPerc(ele.rankSum),
          itemStyle: {
            color: ele.colorCode
          }
        })

      })
    }
    function getValue(dimension) {
      let temp = {
        "rank": "",
        "count": 0,
        "color": '#757575'
      }
      if (list && list.length > 0) {
        let index = list.findIndex(prev => prev.dimension === dimension);
        if (index !== -1) {
          temp["rank"] = 22 - Number(list[index]["rank"]);
          temp["count"] = list[index]["count"];
          temp["color"] = list[index]["colorCode"];
        }
      }

      return temp
    }

    return (
      <>

        <div className="px-6 pb-6">

          {ParaNameList && ParaNameList.length > 0 ?
            <ReactEcharts
              onEvents={{
                'click': (params) => handleStrength(params)
              }}
              style={{
                marginTop: '-40px',
                height: '300px'
              }}
              option={{
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  },
                  formatter: function (a) {
                    let dimension = a && a[0] && a[0]["axisValue"] ? a[0]["axisValue"] : "";
                    let html = `<b>${dimension}</b> <span style=\"display:inline-block;margin-left:4px;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${getValue(dimension).color};\"></span>`
                    html += `<p><b>Rank: </b> ${getValue(dimension).rank}</p>`
                    html += `<p><b>Count: </b> ${getValue(dimension).count}</p>`
                    return html
                  }
                },

                legend: {
                  data: []
                },
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: {
                  type: 'value',
                  splitLine: {
                    lineStyle: {
                      color: '#f2f2f2'
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color: '#e6e6e6'
                    }
                  },

                },
                yAxis: {
                  type: 'category',
                  data: ParaNameList,
                  axisLine: {
                    lineStyle: {
                      color: '#e6e6e6'
                    }
                  },
                  axisLabel: {
                    color: "#000",
                    fontStyle: 'bold'
                  },
                },
                series: [
                  {
                    name: 'Score',
                    type: 'bar',
                    barWidth: '25px',
                    barCategoryGap: '25px',
                    stack: '总量',
                    label: {
                      show: true,
                      position: 'right',
                      formatter: function (value) {
                        return "Rank " + (getValue(value.name ? value.name : "").rank).toString()
                      }
                    },
                    data: insertData

                  },

                ]
              }}
            />
            : null}




        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(BarChartTalents);
