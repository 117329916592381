export const surveyConstants = {

    SURVEY_GETALL_REQUEST: 'SURVEY_GETALL_REQUEST',
    SURVEY_GETALL_SUCCESS: 'SURVEY_GETALL_SUCCESS',
    SURVEY_GETALL_FAILURE: 'SURVEY_GETALL_FAILURE',

    GET_SURVEY_DATA_REQUEST: 'GET_SURVEY_DATA_REQUEST',
    GET_SURVEY_DATA_SUCCESS: 'GET_SURVEY_DATA_SUCCESS',
    GET_SURVEY_DATA_FAILURE: 'GET_SURVEY_DATA_FAILURE',

    GET_SURVEY_QUE_RANDOMIZE_REQUEST: 'GET_SURVEY_QUE_RANDOMIZE_REQUEST',
    GET_SURVEY_QUE_RANDOMIZE_SUCCESS: 'GET_SURVEY_QUE_RANDOMIZE_SUCCESS',
    GET_SURVEY_QUE_RANDOMIZE_FAILURE: 'GET_SURVEY_QUE_RANDOMIZE_FAILURE',

    QUESTIONTYPE_GETALL_REQUEST: 'QUESTIONTYPE_GETALL_REQUEST',
    QUESTIONTYPE_GETALL_SUCCESS: 'QUESTIONTYPE_GETALL_SUCCESS',
    QUESTIONTYPE_GETALL_FAILURE: 'QUESTIONTYPE_GETALL_FAILURE',

    PARAMETERLIST_GETALL_REQUEST: 'PARAMETERLIST_GETALL_REQUEST',
    PARAMETERLIST_GETALL_SUCCESS: 'PARAMETERLIST_GETALL_SUCCESS',
    PARAMETERLIST_GETALL_FAILURE: 'PARAMETERLIST_GETALL_FAILURE',

    PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST:'PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST',
    PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS: 'PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS',
    PARAMETERLIST_BYTYPE_PARENT_GETALL_FAILURE: 'PARAMETERLIST_BYTYPE_PARENT_GETALL_FAILURE',
    
    UPDATE_SHOW_REPORT_STATUS_REQUEST: 'UPDATE_SHOW_REPORT_STATUS_REQUEST',
    UPDATE_SHOW_REPORT_STATUS_SUCCESS: 'UPDATE_SHOW_REPORT_STATUS_SUCCESS',
    UPDATE_SHOW_REPORT_STATUS_FAILURE: 'UPDATE_SHOW_REPORT_STATUS_FAILURE',

    
    WELCOME_SURVEY_REQUEST: 'WELCOME_SURVEY_REQUEST',
    WELCOME_SURVEY_SUCCESS: 'WELCOME_SURVEY_SUCCESS',
    WELCOME_SURVEY_FAILURE: 'WELCOME_SURVEY_FAILURE',

    SET_EXPIRY_SURVEY_REQUEST: 'SET_EXPIRY_SURVEY_REQUEST',
    SET_EXPIRY_SURVEY_SUCCESS: 'SET_EXPIRY_SURVEY_SUCCESS',
    SET_EXPIRY_SURVEY_FAILURE: 'SET_EXPIRY_SURVEY_FAILURE',

    UPDATE_EMP_FILTER_STATUS_REQUEST: 'UPDATE_EMP_FILTER_STATUS_REQUEST',
    UPDATE_EMP_FILTER_STATUS_SUCCESS: 'UPDATE_EMP_FILTER_STATUS_SUCCESS',
    UPDATE_EMP_FILTER_STATUS_FAILURE: 'UPDATE_EMP_FILTER_STATUS_FAILURE',



    CREATE_NEW_SURVEY_REQUEST: 'CREATE_NEW_SURVEY_REQUEST',
    CREATE_NEW_SURVEY_SUCCESS: 'CREATE_NEW_SURVEY_SUCCESS',
    CREATE_NEW_SURVEY_FAILURE: 'CREATE_NEW_SURVEY_FAILURE',

    DELETE_SURVEY_REQUEST: 'DELETE_SURVEY_REQUEST',
    DELETE_SURVEY_SUCCESS: 'DELETE_SURVEY_SUCCESS',
    DELETE_SURVEY_FAILURE: 'DELETE_SURVEY_FAILURE',

    UPDATE_SURVEY_REQUEST: 'UPDATE_SURVEY_REQUEST',
    UPDATE_SURVEY_SUCCESS: 'UPDATE_SURVEY_SUCCESS',
    UPDATE_SURVEY_FAILURE: 'UPDATE_SURVEY_FAILURE',

    RESPONSE_ANALYTICS_SURVEY_REQUEST: 'RESPONSE_ANALYTICS_SURVEY_REQUEST',
    RESPONSE_ANALYTICS_SURVEY_SUCCESS: 'RESPONSE_ANALYTICS_SURVEY_SUCCESS',
    RESPONSE_ANALYTICS_SURVEY_FAILURE: 'RESPONSE_ANALYTICS_SURVEY_FAILURE',

    RESPONSE_360_ANALYTICS_SURVEY_REQUEST: 'RESPONSE_360_ANALYTICS_SURVEY_REQUEST',
    RESPONSE_360_ANALYTICS_SURVEY_SUCCESS: 'RESPONSE_360_ANALYTICS_SURVEY_SUCCESS',
    RESPONSE_360_ANALYTICS_SURVEY_FAILURE: 'RESPONSE_360_ANALYTICS_SURVEY_FAILURE',

    CAMPAIGN_EMPLOYEES_SURVEY_REQUEST: 'CAMPAIGN_EMPLOYEES_SURVEY_REQUEST',
    CAMPAIGN_EMPLOYEES_SURVEY_SUCCESS: 'CAMPAIGN_EMPLOYEES_SURVEY_SUCCESS',
    CAMPAIGN_EMPLOYEES_SURVEY_FAILURE: 'CAMPAIGN_EMPLOYEES_SURVEY_FAILURE',

    RESEND_CAMPAIGN_REQUEST: 'RESEND_CAMPAIGN_REQUEST',
    RESEND_CAMPAIGN_SUCCESS: 'RESEND_CAMPAIGN_SUCCESS',
    RESEND_CAMPAIGN_FAILURE: 'RESEND_CAMPAIGN_FAILURE',

    RESEND_360_CAMPAIGN_REQUEST: 'RESEND_360_CAMPAIGN_REQUEST',
    RESEND_360_CAMPAIGN_SUCCESS: 'RESEND_360_CAMPAIGN_SUCCESS',
    RESEND_360_CAMPAIGN_FAILURE: 'RESEND_360_CAMPAIGN_FAILURE',

    CREATE_SURVEY_FROM_LIB_REQUEST: 'CREATE_SURVEY_FROM_LIB_REQUEST',
    CREATE_SURVEY_FROM_LIB_SUCCESS: 'CREATE_SURVEY_FROM_LIB_SUCCESS',
    CREATE_SURVEY_FROM_LIB_FAILURE: 'CREATE_SURVEY_FROM_LIB_FAILURE',


    GET_SURVEY_FROM_LIB_REQUEST: 'GET_SURVEY_FROM_LIB_REQUEST',
    GET_SURVEY_FROM_LIB_SUCCESS: 'GET_SURVEY_FROM_LIB_SUCCESS',
    GET_SURVEY_FROM_LIB_FAILURE: 'GET_SURVEY_FROM_LIB_FAILURE',

    
    GET_EMPLOYEE_LIST_REQUEST: 'GET_EMPLOYEE_LIST_REQUEST',
    GET_EMPLOYEE_LIST_SUCCESS: 'GET_EMPLOYEE_LIST_SUCCESS',
    GET_EMPLOYEE_LIST_FAILURE: 'GET_EMPLOYEE_LIST_FAILURE',

    SAVE_EMPLOYEE_LIST_REQUEST: 'SAVE_EMPLOYEE_LIST_REQUEST',
    SAVE_EMPLOYEE_LIST_SUCCESS: 'SAVE_EMPLOYEE_LIST_SUCCESS',
    SAVE_EMPLOYEE_LIST_FAILURE: 'SAVE_EMPLOYEE_LIST_FAILURE',

    SAVE_CUSTOMER_LIST_REQUEST: 'SAVE_CUSTOMER_LIST_REQUEST',
    SAVE_CUSTOMER_LIST_SUCCESS: 'SAVE_CUSTOMER_LIST_SUCCESS',
    SAVE_CUSTOMER_LIST_FAILURE: 'SAVE_CUSTOMER_LIST_FAILURE',

    UPDATE_EMPLOYEE_STATUS_REQUEST: 'UPDATE_EMPLOYEE_STATUS_REQUEST',
    UPDATE_EMPLOYEE_STATUS_SUCCESS: 'UPDATE_EMPLOYEE_STATUS_SUCCESS',
    UPDATE_EMPLOYEE_STATUS_FAILURE: 'UPDATE_EMPLOYEE_STATUS_FAILURE',

    GET_ALL_LANGUGAGE_LABEL_REQUEST: 'GET_ALL_LANGUGAGE_LABEL_REQUEST',
    GET_ALL_LANGUGAGE_LABEL_SUCCESS: 'GET_ALL_LANGUGAGE_LABEL_SUCCESS',
    GET_ALL_LANGUGAGE_LABEL_FAILURE: 'GET_ALL_LANGUGAGE_LABEL_FAILURE',

    GET_ALL_LANGUGAGE_REQUEST: 'GET_ALL_LANGUGAGE_REQUEST',
    GET_ALL_LANGUGAGE_SUCCESS: 'GET_ALL_LANGUGAGE_SUCCESS',
    GET_ALL_LANGUGAGE_FAILURE: 'GET_ALL_LANGUGAGE_FAILURE',

    GET_EMPLOYEE_COLUMN_NAME_REQUEST: 'GET_EMPLOYEE_COLUMN_NAME_REQUEST',
    GET_EMPLOYEE_COLUMN_NAME_SUCCESS: 'GET_EMPLOYEE_COLUMN_NAME_SUCCESS',
    GET_EMPLOYEE_COLUMN_NAME_FAILURE: 'GET_EMPLOYEE_COLUMN_NAME_FAILURE',

    GET_EMPLOYEE_FILTER_LIST_REQUEST: 'GET_EMPLOYEE_FILTER_LIST_REQUEST',
    GET_EMPLOYEE_FILTER_LIST_SUCCESS: 'GET_EMPLOYEE_FILTER_LIST_SUCCESS',
    GET_EMPLOYEE_FILTER_LIST_FAILURE: 'GET_EMPLOYEE_FILTER_LIST_FAILURE',

    GET_EMPLOYEE_BATCH_RANGE_REQUEST: 'GET_EMPLOYEE_BATCH_RANGE_REQUEST',
    GET_EMPLOYEE_BATCH_RANGE_SUCCESS: 'GET_EMPLOYEE_BATCH_RANGE_SUCCESS',
    GET_EMPLOYEE_BATCH_RANGE_FAILURE: 'GET_EMPLOYEE_BATCH_RANGE_FAILURE',

    GET_CUSTOMER_BATCH_RANGE_REQUEST: 'GET_CUSTOMER_BATCH_RANGE_REQUEST',
    GET_CUSTOMER_BATCH_RANGE_SUCCESS: 'GET_CUSTOMER_BATCH_RANGE_SUCCESS',
    GET_CUSTOMER_BATCH_RANGE_FAILURE: 'GET_CUSTOMER_BATCH_RANGE_FAILURE',


    CREATE_EMPLOYEE_FILTER_REQUEST: 'CREATE_EMPLOYEE_FILTER_REQUEST',
    CREATE_EMPLOYEE_FILTER_SUCCESS: 'CREATE_EMPLOYEE_FILTER_SUCCESS',
    CREATE_EMPLOYEE_FILTER_FAILURE: 'CREATE_EMPLOYEE_FILTER_FAILURE',

    UPDATE_EMPLOYEE_FILTER_STATUS_REQUEST: 'UPDATE_EMPLOYEE_FILTER_STATUS_REQUEST',
    UPDATE_EMPLOYEE_FILTER_STATUS_SUCCESS: 'UPDATE_EMPLOYEE_FILTER_STATUS_SUCCESS',
    UPDATE_EMPLOYEE_FILTER_STATUS_FAILURE: 'UPDATE_EMPLOYEE_FILTER_STATUS_FAILURE',
    
    DELETE_EMPLOYEE_FILTER_REQUEST: 'DELETE_EMPLOYEE_FILTER_REQUEST',
    DELETE_EMPLOYEE_FILTER_SUCCESS: 'DELETE_EMPLOYEE_FILTER_SUCCESS',
    DELETE_EMPLOYEE_FILTER_FAILURE: 'DELETE_EMPLOYEE_FILTER_FAILURE',

    USER_ASSIGN_ROLE_REQUEST: 'USER_ASSIGN_ROLE_REQUEST',
    USER_ASSIGN_ROLE_SUCCESS: 'USER_ASSIGN_ROLE_SUCCESS',
    USER_ASSIGN_ROLE_FAILURE: 'USER_ASSIGN_ROLE_FAILURE',

    UPDATE_EMPLOYEE_REQUEST: 'UPDATE_EMPLOYEE_REQUEST',
    UPDATE_EMPLOYEE_SUCCESS: 'UPDATE_EMPLOYEE_SUCCESS',
    UPDATE_EMPLOYEE_FAILURE: 'UPDATE_EMPLOYEE_FAILURE',

    QUESTION_LIBRARY_REQUEST: 'QUESTION_LIBRARY_REQUEST',
    QUESTION_LIBRARY_SUCCESS: 'QUESTION_LIBRARY_SUCCESS',
    QUESTION_LIBRARY_FAILURE: 'QUESTION_LIBRARY_FAILURE',
  
    ALL_FILTER_VALUES_REQUEST: 'ALL_FILTER_VALUES_REQUEST',
    ALL_FILTER_VALUES_SUCCESS: 'ALL_FILTER_VALUES_SUCCESS',
    ALL_FILTER_VALUES_FAILURE: 'ALL_FILTER_VALUES_FAILURE',

    UPDATE_EMPLOYEE_FILTER_RANK_REQUEST: 'UPDATE_EMPLOYEE_FILTER_RANK_REQUEST',
    UPDATE_EMPLOYEE_FILTER_RANK_SUCCESS: 'UPDATE_EMPLOYEE_FILTER_RANK_SUCCESS',
    UPDATE_EMPLOYEE_FILTER_RANK_FAILURE: 'UPDATE_EMPLOYEE_FILTER_RANK_FAILURE',

    GET_ASSIGNED_ROLES_REQUEST: 'GET_ASSIGNED_ROLES_REQUEST',
    GET_ASSIGNED_ROLES_SUCCESS: 'GET_ASSIGNED_ROLES_SUCCESS',
    GET_ASSIGNED_ROLES_FAILURE: 'GET_ASSIGNED_ROLES_FAILURE',

    GET_EMPLOYEE_SEARCH_REQUEST: 'GET_EMPLOYEE_SEARCH_REQUEST',
    GET_EMPLOYEE_SEARCH_SUCCESS: 'GET_EMPLOYEE_SEARCH_SUCCESS',
    GET_EMPLOYEE_SEARCH_FAILURE: 'GET_EMPLOYEE_SEARCH_FAILURE',

    GET_INDEX_BY_FILTER_DMG_REQUEST: 'GET_INDEX_BY_FILTER_DMG_REQUEST',
    GET_INDEX_BY_FILTER_DMG_SUCCESS: 'GET_INDEX_BY_FILTER_DMG_SUCCESS',
    GET_INDEX_BY_FILTER_DMG_FAILURE: 'GET_INDEX_BY_FILTER_DMG_FAILURE',

    GET_RESPONSE_ANALYTICS_PAGINATION_REQUEST: 'GET_RESPONSE_ANALYTICS_PAGINATION_REQUEST',
    GET_RESPONSE_ANALYTICS_PAGINATION_SUCCESS: 'GET_RESPONSE_ANALYTICS_PAGINATION_SUCCESS',
    GET_RESPONSE_ANALYTICS_PAGINATION_FAILURE: 'GET_RESPONSE_ANALYTICS_PAGINATION_FAILURE',

    
    GET_RESPONSE_ANALYTICS_KEYWORD_REQUEST: 'GET_RESPONSE_ANALYTICS_KEYWORD_REQUEST',
    GET_RESPONSE_ANALYTICS_KEYWORD_SUCCESS: 'GET_RESPONSE_ANALYTICS_KEYWORD_SUCCESS',
    GET_RESPONSE_ANALYTICS_KEYWORD_FAILURE: 'GET_RESPONSE_ANALYTICS_KEYWORD_FAILURE',

    REMOVE_USER_ROLE_REQUEST: 'REMOVE_USER_ROLE_REQUEST',
    REMOVE_USER_ROLE_SUCCESS: 'REMOVE_USER_ROLE_SUCCESS',
    REMOVE_USER_ROLE_FAILURE: 'REMOVE_USER_ROLE_FAILURE',

    UPDATE_SURVEY_DESIGN_REQUEST: 'UPDATE_SURVEY_DESIGN_REQUEST',
    UPDATE_SURVEY_DESIGN_SUCCESS: 'UPDATE_SURVEY_DESIGN_SUCCESS',
    UPDATE_SURVEY_DESIGN_FAILURE: 'UPDATE_SURVEY_DESIGN_FAILURE',

    GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_REQUEST: 'GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_REQUEST',
    GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_SUCCESS: 'GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_SUCCESS',
    GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_FAILURE: 'GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_FAILURE',

    GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_REQUEST: 'GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_REQUEST',
    GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_SUCCESS: 'GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_SUCCESS',
    GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_FAILURE: 'GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_FAILURE',

    GET_CUSTOMER_V1_RESPONSE_ANALYTICS_PAGINATION_REQUEST: 'GET_CUSTOMER_V1_RESPONSE_ANALYTICS_PAGINATION_REQUEST',
    GET_CUSTOMER_V1_RESPONSE_ANALYTICS_PAGINATION_SUCCESS: 'GET_CUSTOMER_V1_RESPONSE_ANALYTICS_PAGINATION_SUCCESS',
    GET_CUSTOMER_V1_RESPONSE_ANALYTICS_PAGINATION_FAILURE: 'GET_CUSTOMER_V1_RESPONSE_ANALYTICS_PAGINATION_FAILURE',

    UPDATE_ENGAGEMENT_COMPARISON_STATUS_REQUEST: 'UPDATE_ENGAGEMENT_COMPARISON_STATUS_REQUEST',
    UPDATE_ENGAGEMENT_COMPARISON_STATUS_SUCCESS: 'UPDATE_ENGAGEMENT_COMPARISON_STATUS_SUCCESS',
    UPDATE_ENGAGEMENT_COMPARISON_STATUS_FAILURE: 'UPDATE_ENGAGEMENT_COMPARISON_STATUS_FAILURE',

    UPDATE_EDA_STATUS_REQUEST: 'UPDATE_EDA_STATUS_REQUEST',
    UPDATE_EDA_STATUS_SUCCESS: 'UPDATE_EDA_STATUS_SUCCESS',
    UPDATE_EDA_STATUS_FAILURE: 'UPDATE_EDA_STATUS_FAILURE',


    UPDATE_EMPLOYEE_FILTER_LABEL_REQUEST: 'UPDATE_EMPLOYEE_FILTER_LABEL_REQUEST',
    UPDATE_EMPLOYEE_FILTER_LABEL_SUCCESS: 'UPDATE_EMPLOYEE_FILTER_LABEL_SUCCESS',
    UPDATE_EMPLOYEE_FILTER_LABEL_FAILURE: 'UPDATE_EMPLOYEE_FILTER_LABEL_FAILURE',

    GET_PARA_LIB_BY_PARA_TYPE_REQUEST: 'GET_PARA_LIB_BY_PARA_TYPE_REQUEST',
    GET_PARA_LIB_BY_PARA_TYPE_SUCCESS: 'GET_PARA_LIB_BY_PARA_TYPE_SUCCESS',
    GET_PARA_LIB_BY_PARA_TYPE_FAILURE: 'GET_PARA_LIB_BY_PARA_TYPE_FAILURE',

    COPY_PARA_LIB_TO_PARAMETER_REQUEST: 'COPY_PARA_LIB_TO_PARAMETER_REQUEST',
    COPY_PARA_LIB_TO_PARAMETER_SUCCESS: 'COPY_PARA_LIB_TO_PARAMETER_SUCCESS',
    COPY_PARA_LIB_TO_PARAMETER_FAILURE: 'COPY_PARA_LIB_TO_PARAMETER_FAILURE',

    AUTO_UPDATE_PARAMETERS_REQUEST: 'AUTO_UPDATE_PARAMETERS_REQUEST',
    AUTO_UPDATE_PARAMETERS_SUCCESS: 'AUTO_UPDATE_PARAMETERS_SUCCESS',
    AUTO_UPDATE_PARAMETERS_FAILURE: 'AUTO_UPDATE_PARAMETERS_FAILURE',

    GET_EMPLOYEE_LIMITED_LIST_REQUEST: 'GET_EMPLOYEE_LIMITED_LIST_REQUEST',
    GET_EMPLOYEE_LIMITED_LIST_SUCCESS: 'GET_EMPLOYEE_LIMITED_LIST_SUCCESS',
    GET_EMPLOYEE_LIMITED_LIST_FAILURE: 'GET_EMPLOYEE_LIMITED_LIST_FAILURE',
    
    CREATE_EMPLOYEE_RECORD_REQUEST: 'CREATE_EMPLOYEE_RECORD_REQUEST',
    CREATE_EMPLOYEE_RECORD_SUCCESS: 'CREATE_EMPLOYEE_RECORD_SUCCESS',
    CREATE_EMPLOYEE_RECORD_FAILURE: 'CREATE_EMPLOYEE_RECORD_FAILURE',

    GET_LIFECYCLESTAGE_LIST_REQUEST: 'GET_LIFECYCLESTAGE_LIST_REQUEST',
    GET_LIFECYCLESTAGE_LIST_SUCCESS: 'GET_LIFECYCLESTAGE_LIST_SUCCESS',
    GET_LIFECYCLESTAGE_LIST_FAILURE: 'GET_LIFECYCLESTAGE_LIST_FAILURE',

    ALL_TOOLTIP_LIST_REQUEST: 'ALL_TOOLTIP_LIST_REQUEST',
    ALL_TOOLTIP_LIST_SUCCESS: 'ALL_TOOLTIP_LIST_SUCCESS',
    ALL_TOOLTIP_LIST_FAILURE: 'ALL_TOOLTIP_LIST_FAILURE',

    GET_DEFAULT_RISK_SURVEY_REQUEST: 'GET_DEFAULT_RISK_SURVEY_REQUEST',
    GET_DEFAULT_RISK_SURVEY_SUCCESS: 'GET_DEFAULT_RISK_SURVEY_SUCCESS',
    GET_DEFAULT_RISK_SURVEY_FAILURE: 'GET_DEFAULT_RISK_SURVEY_FAILURE',

    GET_EMP_FIELDS_REQUEST: 'GET_EMP_FIELDS_REQUEST',
    GET_EMP_FIELDS_SUCCESS: 'GET_EMP_FIELDS_SUCCESS',
    GET_EMP_FIELDS_FAILURE: 'GET_EMP_FIELDS_FAILURE',

    GET_CMP_FIELDS_REQUEST: 'GET_CMP_FIELDS_REQUEST',
    GET_CMP_FIELDS_SUCCESS: 'GET_CMP_FIELDS_SUCCESS',
    GET_CMP_FIELDS_FAILURE: 'GET_CMP_FIELDS_FAILURE',

    LOAD_DEF_REC_DAYS_SURVEYS_REQUEST: 'LOAD_DEF_REC_DAYS_SURVEYS_REQUEST',
    LOAD_DEF_REC_DAYS_SURVEYS_SUCCESS: 'LOAD_DEF_REC_DAYS_SURVEYS_SUCCESS',
    LOAD_DEF_REC_DAYS_SURVEYS_FAILURE: 'LOAD_DEF_REC_DAYS_SURVEYS_FAILURE',

    LOAD_DEF_ONBOARDING_SURVEYS_REQUEST: 'LOAD_DEF_ONBOARDING_SURVEYS_REQUEST',
    LOAD_DEF_ONBOARDING_SURVEYS_SUCCESS: 'LOAD_DEF_ONBOARDING_SURVEYS_SUCCESS',
    LOAD_DEF_ONBOARDING_SURVEYS_FAILURE: 'LOAD_DEF_ONBOARDING_SURVEYS_FAILURE',

    UPDATE_ACTIVE_STATUS_REC_CAMP_REQUEST: 'UPDATE_ACTIVE_STATUS_REC_CAMP_REQUEST',
    UPDATE_ACTIVE_STATUS_REC_CAMP_SUCCESS: 'UPDATE_ACTIVE_STATUS_REC_CAMP_SUCCESS',
    UPDATE_ACTIVE_STATUS_REC_CAMP_FAILURE: 'UPDATE_ACTIVE_STATUS_REC_CAMP_FAILURE',

    ALL_LIFECYCLE_LISTEN_LIST_REQUEST: 'ALL_LIFECYCLE_LISTEN_LIST_REQUEST',
    ALL_LIFECYCLE_LISTEN_LIST_SUCCESS: 'ALL_LIFECYCLE_LISTEN_LIST_SUCCESS',
    ALL_LIFECYCLE_LISTEN_LIST_FAILURE: 'ALL_LIFECYCLE_LISTEN_LIST_FAILURE',

    GENERATE_COMPANY_BOT_REQUEST: 'GENERATE_COMPANY_BOT_REQUEST',
    GENERATE_COMPANY_BOT_SUCCESS: 'GENERATE_COMPANY_BOT_SUCCESS',
    GENERATE_COMPANY_BOT_FAILURE: 'GENERATE_COMPANY_BOT_FAILURE',

    UPDATE_SURVEY_CLUSTER_SURVEY_ID_REQUEST: 'UPDATE_SURVEY_CLUSTER_SURVEY_ID_REQUEST',
    UPDATE_SURVEY_CLUSTER_SURVEY_ID_SUCCESS: 'UPDATE_SURVEY_CLUSTER_SURVEY_ID_SUCCESS',
    UPDATE_SURVEY_CLUSTER_SURVEY_ID_FAILURE: 'UPDATE_SURVEY_CLUSTER_SURVEY_ID_FAILURE',

    GET_SURVEY_ONE_TEMPLATE_REQUEST: 'GET_SURVEY_ONE_TEMPLATE_REQUEST',
    GET_SURVEY_ONE_TEMPLATE_SUCCESS: 'GET_SURVEY_ONE_TEMPLATE_SUCCESS',
    GET_SURVEY_ONE_TEMPLATE_FAILURE: 'GET_SURVEY_ONE_TEMPLATE_FAILURE',

    
    GET_SURVEY_LIST_BY_CATEGORY_REQUEST: 'GET_SURVEY_LIST_BY_CATEGORY_REQUEST',
    GET_SURVEY_LIST_BY_CATEGORY_SUCCESS: 'GET_SURVEY_LIST_BY_CATEGORY_SUCCESS',
    GET_SURVEY_LIST_BY_CATEGORY_FAILURE: 'GET_SURVEY_LIST_BY_CATEGORY_FAILURE',

    CHANGE_LIFECYCLE_DASH_STATUS_REQUEST: 'CHANGE_LIFECYCLE_DASH_STATUS_REQUEST',
    CHANGE_LIFECYCLE_DASH_STATUS_SUCCESS: 'CHANGE_LIFECYCLE_DASH_STATUS_SUCCESS',
    CHANGE_LIFECYCLE_DASH_STATUS_FAILURE: 'CHANGE_LIFECYCLE_DASH_STATUS_FAILURE',
    
    SAVE_360_RELATION_LIST_REQUEST: 'SAVE_360_RELATION_LIST_REQUEST',
    SAVE_360_RELATION_LIST_SUCCESS: 'SAVE_360_RELATION_LIST_SUCCESS',
    SAVE_360_RELATION_LIST_FAILURE: 'SAVE_360_RELATION_LIST_FAILURE',
        
    SAVE_PMS_RELATION_LIST_REQUEST: 'SAVE_PMS_RELATION_LIST_REQUEST',
    SAVE_PMS_RELATION_LIST_SUCCESS: 'SAVE_PMS_RELATION_LIST_SUCCESS',
    SAVE_PMS_RELATION_LIST_FAILURE: 'SAVE_PMS_RELATION_LIST_FAILURE',

    GET_360_RELATION_LIST_REQUEST: 'GET_360_RELATION_LIST_REQUEST',
    GET_360_RELATION_LIST_SUCCESS: 'GET_360_RELATION_LIST_SUCCESS',
    GET_360_RELATION_LIST_FAILURE: 'GET_360_RELATION_LIST_FAILURE',

    GET_PMS_RELATION_LIST_REQUEST: 'GET_PMS_RELATION_LIST_REQUEST',
    GET_PMS_RELATION_LIST_SUCCESS: 'GET_PMS_RELATION_LIST_SUCCESS',
    GET_PMS_RELATION_LIST_FAILURE: 'GET_PMS_RELATION_LIST_FAILURE',

    COPY_360_PARAMETERS_REQUEST: 'COPY_360_PARAMETERS_REQUEST',
    COPY_360_PARAMETERS_SUCCESS: 'COPY_360_PARAMETERS_SUCCESS',
    COPY_360_PARAMETERS_FAILURE: 'COPY_360_PARAMETERS_FAILURE',

    COPY_PACKAGE_PARAMETERS_REQUEST: 'COPY_PACKAGE_PARAMETERS_REQUEST',
    COPY_PACKAGE_PARAMETERS_SUCCESS: 'COPY_PACKAGE_PARAMETERS_SUCCESS',
    COPY_PACKAGE_PARAMETERS_FAILURE: 'COPY_PACKAGE_PARAMETERS_FAILURE',

    GET_SURVEY_LIST_V3_REQUEST: 'GET_SURVEY_LIST_V3_REQUEST',
    GET_SURVEY_LIST_V3_SUCCESS: 'GET_SURVEY_LIST_V3_SUCCESS',
    GET_SURVEY_LIST_V3_FAILURE: 'GET_SURVEY_LIST_V3_FAILURE',

    GET_SURVEY_LIST_V4_REQUEST: 'GET_SURVEY_LIST_V4_REQUEST',
    GET_SURVEY_LIST_V4_SUCCESS: 'GET_SURVEY_LIST_V4_SUCCESS',
    GET_SURVEY_LIST_V4_FAILURE: 'GET_SURVEY_LIST_V4_FAILURE',

    SAVE_EMP_DATE_FIELD_REQUEST: 'SAVE_EMP_DATE_FIELD_REQUEST',
    SAVE_EMP_DATE_FIELD_SUCCESS: 'SAVE_EMP_DATE_FIELD_SUCCESS',
    SAVE_EMP_DATE_FIELD_FAILURE: 'SAVE_EMP_DATE_FIELD_FAILURE',
    
    GET_PARAMETER_LABEL_NAME_REQUEST: 'GET_PARAMETER_LABEL_NAME_REQUEST',
    GET_PARAMETER_LABEL_NAME_SUCCESS: 'GET_PARAMETER_LABEL_NAME_SUCCESS',
    GET_PARAMETER_LABEL_NAME_FAILURE: 'GET_PARAMETER_LABEL_NAME_FAILURE',

    GET_CROLE_SURVEY_LIST_REQUEST: 'GET_CROLE_SURVEY_LIST_REQUEST',
    GET_CROLE_SURVEY_LIST_SUCCESS: 'GET_CROLE_SURVEY_LIST_SUCCESS',
    GET_CROLE_SURVEY_LIST_FAILURE: 'GET_CROLE_SURVEY_LIST_FAILURE',

    AUTO_CHANGE_DASHBOARD_TYPE_REQUEST: 'AUTO_CHANGE_DASHBOARD_TYPE_REQUEST',
    AUTO_CHANGE_DASHBOARD_TYPE_SUCCESS: 'AUTO_CHANGE_DASHBOARD_TYPE_SUCCESS',
    AUTO_CHANGE_DASHBOARD_TYPE_FAILURE: 'AUTO_CHANGE_DASHBOARD_TYPE_FAILURE',

    GET_RESPONSE_360_ANALYTICS_PAGINATION_REQUEST: 'GET_RESPONSE_360_ANALYTICS_PAGINATION_REQUEST',
    GET_RESPONSE_360_ANALYTICS_PAGINATION_SUCCESS: 'GET_RESPONSE_360_ANALYTICS_PAGINATION_SUCCESS',
    GET_RESPONSE_360_ANALYTICS_PAGINATION_FAILURE: 'GET_RESPONSE_360_ANALYTICS_PAGINATION_FAILURE',
    
    GET_EMPLOYEE_BY_ID_LIST_LIST_REQUEST: 'GET_EMPLOYEE_BY_ID_LIST_LIST_REQUEST',
    GET_EMPLOYEE_BY_ID_LIST_LIST_SUCCESS: 'GET_EMPLOYEE_BY_ID_LIST_LIST_SUCCESS',
    GET_EMPLOYEE_BY_ID_LIST_LIST_FAILURE: 'GET_EMPLOYEE_BY_ID_LIST_LIST_FAILURE',

    GET_ALL_EMPLOYEE_LIST_REQUEST: 'GET_ALL_EMPLOYEE_LIST_REQUEST',
    GET_ALL_EMPLOYEE_LIST_SUCCESS: 'GET_ALL_EMPLOYEE_LIST_SUCCESS',
    GET_ALL_EMPLOYEE_LIST_FAILURE: 'GET_ALL_EMPLOYEE_LIST_FAILURE',

    SAVE_CUST_EMP_RELATION_LIST_REQUEST: 'SAVE_CUST_EMP_RELATION_LIST_REQUEST',
    SAVE_CUST_EMP_RELATION_LIST_SUCCESS: 'SAVE_CUST_EMP_RELATION_LIST_SUCCESS',
    SAVE_CUST_EMP_RELATION_LIST_FAILURE: 'SAVE_CUST_EMP_RELATION_LIST_FAILURE',

    GET_CUST_EMP_RELATION_LIST_REQUEST: 'GET_CUST_EMP_RELATION_LIST_REQUEST',
    GET_CUST_EMP_RELATION_LIST_SUCCESS: 'GET_CUST_EMP_RELATION_LIST_SUCCESS',
    GET_CUST_EMP_RELATION_LIST_FAILURE: 'GET_CUST_EMP_RELATION_LIST_FAILURE',

    HANDLE_360_FEEDBACK_DISABLE_REQUEST: 'HANDLE_360_FEEDBACK_DISABLE_REQUEST',
    HANDLE_360_FEEDBACK_DISABLE_SUCCESS: 'HANDLE_360_FEEDBACK_DISABLE_SUCCESS',
    HANDLE_360_FEEDBACK_DISABLE_FAILURE: 'HANDLE_360_FEEDBACK_DISABLE_FAILURE',

    SET_SURVEY_CAMPAIGN_TYPE_REQUEST: 'SET_SURVEY_CAMPAIGN_TYPE_REQUEST',
    SET_SURVEY_CAMPAIGN_TYPE_SUCCESS: 'SET_SURVEY_CAMPAIGN_TYPE_SUCCESS',
    SET_SURVEY_CAMPAIGN_TYPE_FAILURE: 'SET_SURVEY_CAMPAIGN_TYPE_FAILURE',
    
    GET_TRANSLATE_ELEMENT_REQUEST: 'GET_TRANSLATE_ELEMENT_REQUEST',
    GET_TRANSLATE_ELEMENT_SUCCESS: 'GET_TRANSLATE_ELEMENT_SUCCESS',
    GET_TRANSLATE_ELEMENT_FAILURE: 'GET_TRANSLATE_ELEMENT_FAILURE',

    GET_ASSESSMENT_BY_TOP_BOTTOM_REQUEST: 'GET_ASSESSMENT_BY_TOP_BOTTOM_REQUEST',
    GET_ASSESSMENT_BY_TOP_BOTTOM_SUCCESS: 'GET_ASSESSMENT_BY_TOP_BOTTOM_SUCCESS',
    GET_ASSESSMENT_BY_TOP_BOTTOM_FAILURE: 'GET_ASSESSMENT_BY_TOP_BOTTOM_FAILURE',

    GET_RESPONSE_ASSESSMENT_SHORT_CODE_REQUEST: 'GET_RESPONSE_ASSESSMENT_SHORT_CODE_REQUEST',
    GET_RESPONSE_ASSESSMENT_SHORT_CODE_SUCCESS: 'GET_RESPONSE_ASSESSMENT_SHORT_CODE_SUCCESS',
    GET_RESPONSE_ASSESSMENT_SHORT_CODE_FAILURE: 'GET_RESPONSE_ASSESSMENT_SHORT_CODE_FAILURE',

    RESEND_ASSESSMENT_CAMPAIGN_REQUEST: 'RESEND_ASSESSMENT_CAMPAIGN_REQUEST',
    RESEND_ASSESSMENT_CAMPAIGN_SUCCESS: 'RESEND_ASSESSMENT_CAMPAIGN_SUCCESS',
    RESEND_ASSESSMENT_CAMPAIGN_FAILURE: 'RESEND_ASSESSMENT_CAMPAIGN_FAILURE',

    SURVEY_COPY_SURVEY_REQUEST: 'SURVEY_COPY_SURVEY_REQUEST',
    SURVEY_COPY_SURVEY_SUCCESS: 'SURVEY_COPY_SURVEY_SUCCESS',
    SURVEY_COPY_SURVEY_FAILURE: 'SURVEY_COPY_SURVEY_FAILURE',

    UPDATE_EMPLOYEE_STATUS_REQUEST: 'UPDATE_EMPLOYEE_STATUS_REQUEST',
    UPDATE_EMPLOYEE_STATUS_SUCCESS: 'UPDATE_EMPLOYEE_STATUS_SUCCESS',
    UPDATE_EMPLOYEE_STATUS_FAILURE: 'UPDATE_EMPLOYEE_STATUS_FAILURE',
    
    GET_SURVEY_DMG_FILTER_REQUEST: 'GET_SURVEY_DMG_FILTER_REQUEST',
    GET_SURVEY_DMG_FILTER_SUCCESS: 'GET_SURVEY_DMG_FILTER_SUCCESS',
    GET_SURVEY_DMG_FILTER_FAILURE: 'GET_SURVEY_DMG_FILTER_FAILURE',

    REPONSE_ICEDOWNLOAD_BY_SURVEY_REQUEST: 'REPONSE_ICEDOWNLOAD_BY_SURVEY_REQUEST',
    REPONSE_ICEDOWNLOAD_BY_SURVEY_SUCCESS: 'REPONSE_ICEDOWNLOAD_BY_SURVEY_SUCCESS',
    REPONSE_ICEDOWNLOAD_BY_SURVEY_FAILURE: 'REPONSE_ICEDOWNLOAD_BY_SURVEY_FAILURE',

    GET_SURVEY_LAUNCH_BY_DMG_VALUE_REQUEST: 'GET_SURVEY_LAUNCH_BY_DMG_VALUE_REQUEST',
    GET_SURVEY_LAUNCH_BY_DMG_VALUE_SUCCESS: 'GET_SURVEY_LAUNCH_BY_DMG_VALUE_SUCCESS',
    GET_SURVEY_LAUNCH_BY_DMG_VALUE_FAILURE: 'GET_SURVEY_LAUNCH_BY_DMG_VALUE_FAILURE',

    GET_QUES_NAME_ID_ONLY_REQUEST: 'GET_QUES_NAME_ID_ONLY_REQUEST',
    GET_QUES_NAME_ID_ONLY_SUCCESS: 'GET_QUES_NAME_ID_ONLY_SUCCESS',
    GET_QUES_NAME_ID_ONLY_FAILURE: 'GET_QUES_NAME_ID_ONLY_FAILURE',

};

