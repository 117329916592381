import React from "react";
import Select2 from 'react-select';
export default function ConfirmMail(props) {
  let {

    changeISBacket, isDimension,

    handleSubmitCopyParaLibrary, handleCollectParaLib, getParaLibList, isBucket, handleClearParameterForm, handleNewParameterSubmit, handleConfirmMailClose, parameterFormData, handleNewParameterInput, getParameterTypeListData, handleNewParameterTypeInput, labelCollection, handleLabelCollectionSubmit, deleteLabelCollection, handleLabelNameInput, labelName, setCurrentIndex } = props;

  let [showParaLibScreen, handleParaLibScreen] = React.useState(false);


  // console.log('getParaLibList--->', getParaLibList)

  var options = [
    { value: 'one', label: 'One' },
    { value: 'two', label: 'Two' },
    { value: 'Three', label: 'Three' },
    { value: 'Four', label: 'Four' },
    { value: 'Five', label: 'Five' },
    { value: 'Six', label: 'Six' }
  ];

  return (

    <div className="fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center w-screen h-screen bg-black bg-opacity-40 md:px-0 px-6 ">
      <div className=" mx-auto bg-white rounded-md w-[48rem] ">

        <div className="flex items-center justify-between p-4 border-b md:px-8">

          <h1 className="text-lg font-semibold text-gray-800 ">{showParaLibScreen ? "Select Index" : (isBucket ? "Create New Index" : "Add New Dimension")}</h1>
          <span onClick={() => handleConfirmMailClose(false)} className="
      material-symbols-outlined bg-slate-100 hover:bg-slate-200 rounded-full p-1.5  cursor-pointer transition duration-150">close</span>

        </div>

        {showParaLibScreen ?
          <>
            <div className=" ">
              <div className="justify-between ">

                {/* <div className="flex py-2" >
                      <div onClick={()=>changeISBacket(true)} className={isBucket?"cursor-pointer bg-blue-500 px-2 py-1 text-white mx-1":"cursor-pointer bg-gray-500 px-2 py-1 text-white mx-1"}>Create Bucket</div>
                      <div onClick={()=>changeISBacket(false)} className={!isBucket?"cursor-pointer bg-blue-500 px-2 py-1 text-white mx-1":"cursor-pointer bg-gray-500 px-2 py-1 text-white mx-1"}>Create Dimension</div>
                    </div> */}

                <div className="grid grid-cols-2 md:grid-cols-3 px-2 py-4 lg:min-h-auto min-h-60 overflow-y-auto">
                  {getParaLibList && getParaLibList.length > 0 ?
                    getParaLibList.map((para, index) =>  
                      <label key={index} onClick={() => handleCollectParaLib(para)}
                        className="inline-flex items-center md:px-4 px-2 py-2 text-xs font-medium truncate cursor-pointer hover:bg-blue-100 rounded-md">
                        <input type="checkbox" value={true} className="m-2 " onChange={() => { }} />
                        <span >{para.name}</span>
                      </label>

                    )
                    : null}
                </div>
              </div>


              <div className="flex items-center justify-end p-4 space-x-4 border-t">
                <button onClick={() => handleParaLibScreen(false)} className="w-24 p-2 text-gray-500 border rounded-md">Cancel</button>
                <button onClick={() => { handleSubmitCopyParaLibrary(); handleParaLibScreen(false); }} className="w-24 p-2 text-white bg-[#2196F3] rounded-md cursor-pointer ">OK</button>

              </div>
            </div>
          </>
          :
          <div className="p-4 md:px-8">
            <div className="justify-between space-y-4">

              {/*<div className="flex py-2" >
                  <div onClick={()=>changeISBacket(true)} className={isBucket?"cursor-pointer bg-blue-500 px-2 py-1 text-white mx-1":"cursor-pointer bg-gray-500 px-2 py-1 text-white mx-1"}>Create Bucket</div>
                  <div onClick={()=>changeISBacket(false)} className={!isBucket?"cursor-pointer bg-blue-500 px-2 py-1 text-white mx-1":"cursor-pointer bg-gray-500 px-2 py-1 text-white mx-1"}>Create Dimension</div>
                </div> */}

              <div className="space-y-2">
                <label className="text-sm font-medium text-slate-800 ">{isBucket ? "Enter Index Name" : "Enter Dimension Name"}</label>
                <input type="text" name="name" value={parameterFormData.name} onChange={handleNewParameterInput}
                  className="w-full p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2" placeholder={isBucket ? "Index Name" : "Dimension Name"} />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium text-slate-800 ">{isBucket ? "Enter Index Label" : "Enter Dimension Label"}</label>
                <input type="text" name="label" value={parameterFormData.label} onChange={handleNewParameterInput}
                  className="w-full p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2" placeholder={isBucket ? "Index Label" : "Dimension Label"} />
              </div>

              {/* <div className="space-y-2">
                <label className="text-sm font-medium text-slate-800 ">Enter Dimensions</label>
                <div className="flex justify-between">
                  <div className="w-4/5">
                    <input type="text" name="labelName" value={labelName ? labelName : ""}
                      onChange={handleLabelNameInput}
                      className="w-full p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2" placeholder="Dimension Name" />
                  </div>


                  <div className="pl-4">
                    <button className="w-full px-8 py-1.5 text-lg font-medium text-white uppercase rounded-md cursor-pointer bg-blue-500" onClick={handleLabelCollectionSubmit}>Add</button>
                  </div>
                </div>


                <div className="space-x-2">
                  <div className="flex flex-wrap gap-2">
                    {
                      labelCollection && labelCollection.length > 0 ?
                        labelCollection.map((element, index) => (


                          <span className="px-2.5 py-1.5 bg-gray-100 text-sm text-slate-800 font-medium capitalize cursor-pointer flex items-center rounded-sm">
                            {element && element ? element : "-"}
                            <span onClick={deleteLabelCollection} class="material-icons text-[1rem] ml-1.5 hover:bg-red-300">close</span>
                          </span>


                        )) : null

                    }
                  </div>
                </div>

              </div> */}





              {/* <div className="mt-2 space-y-2">
                <label className="text-sm font-medium text-slate-800 ">Enter Dimension</label>
                <textarea type="text" name="label" value={parameterFormData.label} onChange={handleNewParameterInput}
                  className="w-full h-20 p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 " placeholder={isBucket ? "Index Label" : "Dimension Label"} />
              </div> */}



              {isBucket ?
                <div className="flex items-center my-10">
                  <div className="px-2 py-1 ">Select from </div>
                  <div onClick={() => handleParaLibScreen(true)} className="flex items-center p-2 text-sm border rounded-md cursor-pointer bg-blue-50">
                    <span className="mr-2 text-gray-500 material-symbols-outlined">upload_file</span> Index Library</div>
                </div>
                : null}

            </div>


            <div className="flex items-center justify-end pt-10 pb-4">

              <button onClick={() => handleNewParameterSubmit()} className="w-full p-3 text-lg font-medium text-white uppercase rounded-md cursor-pointer bg-[#2196F3]">Submit</button>

            </div>
          </div>
        }
      </div>

    </div>

  );
}
