import { dataTool } from 'echarts';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Actions from '../Actions/Actions';
// import Resource from '../TaskResouce/Resource/Resource';
// import Task from '../TaskResouce/Task/Task';
// import TaskResource from '../TaskResouce/TaskResource';
// import CompleteModal from './CompleteModal';

class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }

  render() {
    let { getManagerActionPlanData, CurrentDimensionId, onSelectAction } = this.props;

    let ActionList = getManagerActionPlanData;


    // getManagerActionPlanData,currentParameterId


    function taskStatus(action){
      let data = {
        count:0,
        total:0
      }
      if(action && action.TaskList && action.TaskList.length>0){
        data["count"] = action.TaskList.filter(x=>x.isComplete===true).length;
        data["total"] = action.TaskList.length;
      }
      return data
    }

    return (
      <> 
        <div className="w-full lg:pl-4  lg:mt-0 mt-4 mb-4 ">
          <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">Working on this action will help you to improve on:</h1>
          {/* boxes */}
          <div className="grid xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-3 gap-4 my-4">
            {/* box 1 */}

          {ActionList && ActionList.length>0?
            ActionList.map((action)=> 
            <>
              <div className="bg-white p-4  rounded-md shadow-xl">
                <div className="flex  pb-3 "> <div style={{background:action.colorCode?action.colorCode:"#3e7eed"}} className=" rounded-lg  w-14 h-14 bg-blue-0  flex justify-center items-center w-14 h-14 text-blue-500">
                  <span className="material-symbols-outlined text-white" style={{fontSize: '2rem'}}>group</span></div></div> 
                <h1 className="text-sm font-medium">{action.name}</h1>
                  <p className="text-xs text-gray-500 py-4 h-16">{action.desc}
                </p>
                {/* progess */}
                <div>
                  <div className="flex justify-between text-xs text-gray-500 font-medium"> <p>Tasks</p> <p>{taskStatus(action).count+"/"+taskStatus(action).total}</p></div>
                  <div className="h-1 w-full bg-gray-100 rounded-md overflow-hidden my-2"><div style={{background:action.colorCode, width:((taskStatus(action).count*100)/(taskStatus(action).total)).toString()+"%"}} className="h-1 w-6/12 bg-blue-500" /></div>
                </div>
                {/* btn */}
                <button onClick={()=>onSelectAction(action)} style={{background:action.colorCode}} className="uppercase bg-blue-500 text-white rounded-md w-full text-xs p-3 font-medium mt-4 hover:bg-blue-600">view tasks</button>
              </div>
            </>
            ):null}

          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dimension);
