import React, { useState } from "react";
import Header from "./Header/Header";
import { Button } from "@material-ui/core";
export default function HikeAppraiser() {
  const [open, close] = useState(0);
  return (
    <div className="h-screen overflow-hidden bg-gray-50">
      <Header />

      <div
        className="flex flex-row w-full"
        style={{ height: "calc(100% - 2rem)" }}
      >
        <div
          className="cursor-pointer transition-width w-64  lg:block lang lg:relative fixed top-0 left-0 z-20  h-screen     duration-200 transform bg-white border-r hidden"
          style={{ height: "calc(100% - 0rem)" }}
        >
          <div className="my-4 whitespace-nowrap ">
            <div
              title="Users"
              className="flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer   border-l-4 border-[#2196F3] bg-[#2196F3]/10"
            >
              <div className="flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center">
                <span className=" material-symbols-outlined">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-users"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth="1.8"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx={9} cy={7} r={4} />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                </span>
              </div>
              <span className=" text-sm text-[#2196F3]">Appraisal Cycle</span>
            </div>
          </div>
        </div>

        <main
          className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto  md:p-6 p-4 space-y-6 relative   "
          style={{ height: "calc(100% - 4rem)" }}
        >
        <div className="flex md:flex-row flex-col gap-4 items-start justify-between  cursor-pointer md:items-center">
            <div className="flex items-center space-x-4 ">
              <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">
                Salary Hike
              </h1>
            </div>

            <div className="flex items-center space-x-2 ">
            <div className="flex items-center justify-between overflow-hidden text-sm bg-white border rounded-md md:w-auto w-full">
            <span className="p-2 rounded-l-full material-symbols-outlined text-slate-600" style={{ fontSize: '1.4rem' }}>search</span>
            <input type="search" name="search" className="w-full py-2 md:w-48 focus:outline-none placeholder:text-slate-600" placeholder="Search" /></div>
              <button className="flex items-center justify-center p-2 bg-white border rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-filter" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z"></path>
                </svg></button></div>
          </div>

          <div className="w-full h-12 px-4 py-3 bg-white rounded border border-gray-200 justify-start items-center   inline-flex">
            <div className="grow shrink basis-0  justify-between  items-center  flex">
              <div><span className="text-[#3D405B] text-lg font-medium capitalize">Appraisal HR - Q3</span>
              </div>
              <div className="border-l flex pl-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down cursor-pointer" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M6 9l6 6l6 -6"></path>
                </svg>
              </div>
            </div>
          </div>

          <div className="bg-white p-6 space-y-6 border rounded-md">
            <h1 className="text-xl font-medium text-[#3D405B]">
              Employee List
            </h1>
            <div className="overflow-x-auto border border-gray-200  ">
              <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse xl:whitespace-normal whitespace-nowrap">
                <thead className="bg-white">
                  <tr className>
                    <th

                      className="px-6 py-3 font-semibold text-sm text-[#3D405B]  "
                    >
                   Hike Appraiser
                    </th>
                    <th

                      className="px-6 py-3 font-semibold text-sm text-[#3D405B]   text-center"
                    >
                      Employee
                    </th>
                    <th

                      className="px-6 py-3 font-semibold text-sm text-[#3D405B]   text-center"
                    >
                      Received On
                    </th>
                    <th
                      rowSpan={2}
                      className="px-6 py-3 font-semibold text-sm text-[#3D405B]   text-center"
                    >
                      Submitted On
                    </th>


                    <th

                      colSpan={2}
                      className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                    >
                      Action
                    </th>
                  </tr>

                </thead>
                <tbody className="border-t   divide-y  ">
                  <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                    <td className="px-6 py-3  ">
                    <div className="flex items-center space-x-2">
                       <div className="w-12">
                       <img
                          src="img/newimg/Rectangle 11116.png"
                          alt=""
                          className="rounded-full w-12 h-12  "
                        />
                       </div>
                        <div className="flex-1">
                          <p className="capitalize text-base font-medium block text-[#3D405B]">
                            Hari KC
                          </p>
                          <p className="capitalize text-sm  block text-[#3D405B] font-normal">
                            HR Manager - Dept of Human  
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3   text-center">
                      <div className="flex justify-center"><span className="cursor-pointer  px-6 bg-[#f9fafb] py-1 border rounded-md flex space-x-1 font-medium items-center"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg><span>9</span></span></div>
                    </td>
                    <td className="px-6 py-3   text-center">
                      01-Jul-2023
                    </td>
                    <td className="px-6 py-3   text-center">
                      01-Jul-2023
                    </td>

                    <td className="px-6 py-3   text-center">
                      <button className="  bg-[#FFA000] text-white w-32 px-8 py-2 rounded-md capitalize  text-sm font-medium ">
                        Pending
                      </button>
                    </td>
                  </tr>
                  <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                    <td className="px-6 py-3  ">
                    <div className="flex items-center space-x-2">
                       <div className="w-12">
                       <img
                          src="img/newimg/Rectangle 11116.png"
                          alt=""
                          className="rounded-full w-12 h-12  "
                        />
                       </div>
                        <div className="flex-1">
                          <p className="capitalize text-base font-medium block text-[#3D405B]">
                            Hari KC
                          </p>
                          <p className="capitalize text-sm  block text-[#3D405B] font-normal">
                            HR Manager - Dept of Human  
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-3   text-center">
                      <div className="flex justify-center"><span className="cursor-pointer  px-6 bg-[#f9fafb] py-1 border rounded-md flex space-x-1 font-medium items-center"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg><span>9</span></span></div>
                    </td>
                    <td className="px-6 py-3   text-center">
                      01-Jul-2023
                    </td>
                    <td className="px-6 py-3   text-center">
                      01-Jul-2023
                    </td>

                    <td className="px-6 py-3   text-center">
                      <button className="  bg-[#4CAF50] text-white w-32   py-2 rounded-md capitalize  text-sm font-medium ">
                        Completed
                      </button>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>

            <div className="flex md:flex-row gap-6 flex-col items-center justify-between border-t p-6 -m-6">
              <p className="text-[#3D405B] md:text-base text-sm font-semibold">
                Showing 1 to 5 of 150 entries
              </p>
              <div className="flex border divide-x rounded-md ">
                <button className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                  Previous
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">
                  1
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                  2
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                  3
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                  4
                </button>
                <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                  5
                </button>
                <button className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                  Next
                </button>
              </div>
            </div>
          </div>

       
 
        </main>
      </div>
    </div>
  );
}
