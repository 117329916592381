import {
  customerConstants
} from '../_constants';

export function customer(state = {}, action) {

  switch (action.type) {
      //----------------------------------------------------------------- 

      case customerConstants.GET_CUSTOMER_SURVEY_LIST_REQUEST:
        return {
          ...state,
          loading: true
        };
      case customerConstants.GET_CUSTOMER_SURVEY_LIST_SUCCESS:
        return {
          ...state,
          getSurveySuccess: true,
          loading: false,
          SurveyList:action.customer.SurveyData.list,
          SurveyTotal:action.customer.SurveyData.total

        };
      case customerConstants.GET_CUSTOMER_SURVEY_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };

        //----------------------------------------------------------------- 

        case customerConstants.GET_CUSTOMER_DASHBOARD_CARD_REQUEST:
          return {
            ...state,
            loading: true
          };
        case customerConstants.GET_CUSTOMER_DASHBOARD_CARD_SUCCESS:
          return {
            ...state,
            loading: false,
            getDashboardCardSuccess: true,
            getDashboardCardData:action.customer.getDashboardCardData
  
          };
        case customerConstants.GET_CUSTOMER_DASHBOARD_CARD_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  
          //----------------------------------------------------------------- 

        case customerConstants.GET_CUSTOMER_QUESTION_DIST_REQUEST:
          return {
            ...state,
            loading: true
          };
        case customerConstants.GET_CUSTOMER_QUESTION_DIST_SUCCESS:
          return {
            ...state,
            loading: false,
            getQuestionDistSuccess: true,
            getQuestionDistData:action.customer.getQuestionDistData
  
          };
        case customerConstants.GET_CUSTOMER_QUESTION_DIST_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  
          //----------------------------------------------------------------- 

          case customerConstants.GET_CUSTOMER_QUESTION_DIST_OVERALL_REQUEST:
            return {
              ...state,
              loading: true
            };
          case customerConstants.GET_CUSTOMER_QUESTION_DIST_OVERALL_SUCCESS:
            return {
              ...state,
              loading: false,
              getQuestionDistOverallSuccess: true,
              getQuestionDistOverallData:action.customer.getQuestionDistOverallData
    
            };
          case customerConstants.GET_CUSTOMER_QUESTION_DIST_OVERALL_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
    
            //----------------------------------------------------------------- 

          case customerConstants.GET_CUSTOMER_MEAN_SCORE_REQUEST:
            return {
              ...state,
              loading: true
            };
          case customerConstants.GET_CUSTOMER_MEAN_SCORE_SUCCESS:
            return {
              ...state,
              loading: false,
              getMeanScoreSuccess: true,
              getMeanScoreData:action.customer.getMeanScoreData
    
            };
          case customerConstants.GET_CUSTOMER_MEAN_SCORE_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
    
            //----------------------------------------------------------------- 

            case customerConstants.GET_CUSTOMER_MEAN_SCORE_QWISE_REQUEST:
              return {
                ...state,
                loading: true
              };
            case customerConstants.GET_CUSTOMER_MEAN_SCORE_QWISE_SUCCESS:
              return {
                ...state,
                loading: false,
                getMeanScoreQwiseSuccess: true,
                getMeanScoreQwiseData:action.customer.getMeanScoreQwiseData
      
              };
            case customerConstants.GET_CUSTOMER_MEAN_SCORE_QWISE_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };
      
              //----------------------------------------------------------------- 

                 
            case customerConstants.GET_CUSTOMER_QUES_NPS_RESULT_REQUEST:
              return {
                ...state,
                loading: true
              };
            case customerConstants.GET_CUSTOMER_QUES_NPS_RESULT_SUCCESS:
              return {
                ...state,
                loading: false,
                getQuestionResultNPSSuccess: true,
                getQuestionMatrixResultData:action.customer.getQuestionMatrixResultData
      
              };
            case customerConstants.GET_CUSTOMER_QUES_NPS_RESULT_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };


              case customerConstants.GET_CUSTOMER_QUES_OTHERS_RESULT_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case customerConstants.GET_CUSTOMER_QUES_OTHERS_RESULT_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  getQuestionResultOthersSuccess: true,
                  getQuestionOthersResultData:action.customer.getQuestionOthersResultData
        
                };
              case customerConstants.GET_CUSTOMER_QUES_OTHERS_RESULT_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };
      
              //----------------------------------------------------------------- 
 


              
              case customerConstants.GET_CUSTOMER_QUES_FILTER_OTHERS_RESULT_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case customerConstants.GET_CUSTOMER_QUES_FILTER_OTHERS_RESULT_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  getQuestionOthersResultFilterSuccess: true,
                  getQuestionOthersResultFilterData:action.customer.getQuestionOthersResultFilterData
        
                };
              case customerConstants.GET_CUSTOMER_QUES_FILTER_OTHERS_RESULT_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };




                case customerConstants.GET_CUSTOMER_QUES_2_FILTER_OTHERS_RESULT_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case customerConstants.GET_CUSTOMER_QUES_2_FILTER_OTHERS_RESULT_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getQuestion2OthersResultFilterSuccess: true,
                    getQuestion2OthersResultFilterData:action.customer.getQuestion2OthersResultFilterData
          
                  };
                case customerConstants.GET_CUSTOMER_QUES_2_FILTER_OTHERS_RESULT_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };
                




      case customerConstants.GET_CUSTOMER_COLUMN_NAME_REQUEST:
        return {
          ...state,
          loading: true
        };
      case customerConstants.GET_CUSTOMER_COLUMN_NAME_SUCCESS:
        return {
          ...state,
          loading: false,
          getCustomerColumnNameSuccess: true,
          getCustomerColumnNameData:action.customer.getCustomerColumnNameData

        };
      case customerConstants.GET_CUSTOMER_COLUMN_NAME_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };


        case customerConstants.GET_CUSTOMER_FILTER_LIST_REQUEST:
          return {
            ...state,
            loading: true
          };
        case customerConstants.GET_CUSTOMER_FILTER_LIST_SUCCESS:
          return {
            ...state,
            loading: false,
            getCustomerFilterListByCompanyIdSuccess: true,
            getCustomerFilterListByCompanyIdData:action.customer.getCustomerFilterListByCompanyIdData
  
          };
        case customerConstants.GET_CUSTOMER_FILTER_LIST_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };


          case customerConstants.CREATE_CUSTOMER_FILTER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case customerConstants.CREATE_CUSTOMER_FILTER_SUCCESS:
            return {
              ...state,
              loading: false,
              createCustomerFilterSuccess: true,
              createCustomerFilterData:action.customer.createCustomerFilterData
    
            };
          case customerConstants.CREATE_CUSTOMER_FILTER_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };


            case customerConstants.UPDATE_CUSTOMER_FILTER_RANK_REQUEST:
              return {
                ...state,
                loading: true
              };
            case customerConstants.UPDATE_CUSTOMER_FILTER_RANK_SUCCESS:
              return {
                ...state,
                loading: false,
                updateCustomerFilterRankSuccess: true,
                updateCustomerFilterRankData:action.customer.updateCustomerFilterRankData
      
              };
            case customerConstants.UPDATE_CUSTOMER_FILTER_RANK_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error
              };



              case customerConstants.UPDATE_CUSTOMER_FILTER_STATUS_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case customerConstants.UPDATE_CUSTOMER_FILTER_STATUS_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  updateCustomerFilterStatusSuccess: true,
                  updateCustomerFilterStatusData:action.customer.updateCustomerFilterStatusData
        
                };
              case customerConstants.UPDATE_CUSTOMER_FILTER_STATUS_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };



                case customerConstants.UPDATE_CUSTOMER_SHOW_REPORT_STATUS_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case customerConstants.UPDATE_CUSTOMER_SHOW_REPORT_STATUS_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    updateCustomerShowReportStatusSuccess: true,
                    updateCustomerShowReportStatusData:action.customer.updateCustomerShowReportStatusData
          
                  };
                case customerConstants.UPDATE_CUSTOMER_SHOW_REPORT_STATUS_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };

                  
                  case customerConstants.DELETE_CUSTOMER_FILTER_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case customerConstants.DELETE_CUSTOMER_FILTER_SUCCESS:
                    return {
                      ...state,
                      loading: false,
                      deleteCustomerFilterSuccess: true,
                      deleteCustomerFilterData:action.customer.deleteCustomerFilterData
            
                    };
                  case customerConstants.DELETE_CUSTOMER_FILTER_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };




                                      
                  case customerConstants.UPDATE_CUSTOMER_FILTER_LABEL_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case customerConstants.UPDATE_CUSTOMER_FILTER_LABEL_SUCCESS:
                    return {
                      ...state,
                      loading: false,
                      updateCustomerFilterLabelSuccess: true,
                      updateCustomerFilterLabelData:action.customer.updateCustomerFilterLabelData
            
                    };
                  case customerConstants.UPDATE_CUSTOMER_FILTER_LABEL_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };




                    case customerConstants.GET_CUSTOMER_LIST_REQUEST:
                      return {
                        ...state,
                        loading: true
                      };
                    case customerConstants.GET_CUSTOMER_LIST_SUCCESS:
                      return {
                        ...state,
                        loading: false,
                        getCustomerListSuccess: true,
                        getCustomerListData:action.customer.getCustomerListData.list,
                        getCustomerListTotal:action.customer.getCustomerListData.total
              
                      };
                    case customerConstants.GET_CUSTOMER_LIST_FAILURE:
                      return {
                        ...state,
                        loading: false,
                        error: action.error
                      };

                      



                      case customerConstants.GET_CUSTOMER_LEVEL_FILTER_REQUEST:
                        return {
                          ...state,
                          loading: true
                        };
                      case customerConstants.GET_CUSTOMER_LEVEL_FILTER_SUCCESS:
                        ////console.log("action.customer---",action.customer)
                        return {
                          ...state,
                          loading: false,
                          getCustFilterSuccess: true,
                          getCustFilterData:action.customer.getCustFilterData
                
                        };
                      case customerConstants.GET_CUSTOMER_LEVEL_FILTER_FAILURE:
                        return {
                          ...state,
                          loading: false,
                          error: action.error
                        };

  
                        case customerConstants.GET_CUSTOMER_DMG_FILTER_REQUEST:
                          return {
                            ...state,
                            loading: true
                          };
                        case customerConstants.GET_CUSTOMER_DMG_FILTER_SUCCESS:
                          ////console.log("action.customer---",action.customer)
                          return {
                            ...state,
                            loading: false,
                            getIndexByFilterDMG_CustomerSuccess: true,
                            getIndexByFilterDMG_CustomerData:action.customer.getIndexByFilterDMG_CustomerData
                  
                          };
                        case customerConstants.GET_CUSTOMER_DMG_FILTER_FAILURE:
                          return {
                            ...state,
                            loading: false,
                            error: action.error
                          };
  



                case customerConstants.GET_CUSTOMER_RESPONDANTS_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case customerConstants.GET_CUSTOMER_RESPONDANTS_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getRespondantDetails_CustomersSuccess: true,
                    getRespondantDetails_CustomersData:action.customer.getRespondantDetails_CustomersData
          
                  };
                case customerConstants.GET_CUSTOMER_RESPONDANTS_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };
    

                  case customerConstants.GET_CUSTOMER_RECORD_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case customerConstants.GET_CUSTOMER_RECORD_SUCCESS:
                    return {
                      ...state,
                      loading: false,
                      createCustomerRecordSuccess: true
            
                    };
                  case customerConstants.GET_CUSTOMER_RECORD_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };



                    

                    case customerConstants.GET_CAMPAIGN_CUSTOMER_BY_ID_REQUEST:
                      return {
                        ...state,
                        loading: true
                      };
                    case customerConstants.GET_CAMPAIGN_CUSTOMER_BY_ID_SUCCESS:
                      return {
                        ...state,
                        loading: false,
                        getCampaignCustomerssByIDSuccess: true,
                        getCampaignCustomerssByIDData:action.customer.getCampaignCustomerssByIDData
              
                      };
                    case customerConstants.GET_CAMPAIGN_CUSTOMER_BY_ID_FAILURE:
                      return {
                        ...state,
                        loading: false,
                        error: action.error
                      };



  
    default:
      return state
  }
}