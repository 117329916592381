import React, { Component } from "react";
import { connect } from "react-redux";
import { dashboardActions, userActions } from "../../../_actions";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileBox: false,
      showDropdown: false,
    };
    this.dropdownRef = React.createRef();
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getClientProfile());
  }

  // openProfile = (check) => {
  //   this.setState({ showProfileBox: !check });

  // };

  openProfile = () => {
    this.setState((prevState) => ({
      showProfileBox: !prevState.showProfileBox,
    }));
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ showProfileBox: false });
    }
  };

  handleLogout = () => {
    this.props.dispatch(userActions.logoutSession());
  };

  render() {
    const { showProfileBox } = this.state;
    let {
      dashboard,
      CurrentLanguageName,
      getLanguageData,
      handleLanguage,
      showLanguageModal,
      openLanguageModal,
      handleSidebar,
      sidebarToggle,
      showSidebar,
    } = this.props;
    // let { showProfileBox } = this.state;
    let { ClientProfileData } = dashboard;
    //console.log('ClientProfileData:', ClientProfileData)

    let ProfileName = "";

    let ProfileGender = "";
    if (ClientProfileData && ClientProfileData.name) {
      let nameGender = "";
      if (ClientProfileData && ClientProfileData.Gender) {
        nameGender = ClientProfileData.Gender;
      } else if (ClientProfileData && ClientProfileData.gender) {
        nameGender = ClientProfileData.gender;
      }

      ProfileGender = nameGender;
      ProfileName = ClientProfileData.name;
    }

    let { companyName } = this.props;
    ////console.log("companyName-ID--------->", companyName)

    return (
      <>
        <header className="md:px-6 p-4 h-[72px] border flex justify-between bg-white ">
          <div className="flex items-center space-x-4">
            <button onClick={() => sidebarToggle()} className="  cursor-pointer hover:bg-slate-100 p-2 rounded-md duration-200 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-menu-2 text-[#3D405B] "
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 6l16 0"></path>
                <path d="M4 12l16 0"></path>
                <path d="M4 18l16 0"></path>
              </svg>
            </button>
            <a  href="#/app/overview" className="text-2xl font-semibold text-[#3D405B]">
              <b className="text-[#18afb8] font-semibold">Insta</b>Survey
            </a>
          </div>

          <div className="flex items-center flex-row md:gap-4 gap-3 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-help-octagon text-[#3D405B]"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M9.103 2h5.794a3 3 0 0 1 2.122 .879l4.101 4.1a3 3 0 0 1 .88 2.125v5.794a3 3 0 0 1 -.879 2.122l-4.1 4.101a3 3 0 0 1 -2.123 .88h-5.795a3 3 0 0 1 -2.122 -.88l-4.101 -4.1a3 3 0 0 1 -.88 -2.124v-5.794a3 3 0 0 1 .879 -2.122l4.1 -4.101a3 3 0 0 1 2.125 -.88z"></path>
              <path d="M12 16v.01"></path>
              <path d="M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"></path>
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-settings text-[#3D405B]"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"></path>
              <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
            </svg>

            <div ref={this.dropdownRef} className="relative flex items-center ">
          <div   onClick={this.openProfile} className="h-10 w-10 bg-[#18afb8] rounded-full flex justify-center items-center text-base font-semibold text-white">
            {ProfileName ? ProfileName.toUpperCase().slice(0, 2) : "AS"}
            </div>

               <div
                onClick={this.openProfile}
                className="ml-2 lg:flex items-center hidden cursor-pointer relative"
              >
              

                {showProfileBox && (
                  <>
                    <div className="bg-white rounded-xl border shadow-2xl   py-6 absolute top-10 right-0 w-60  space-y-2  z-20">
                      <div className="flex items-center space-x-2 hover:bg-blue-100 transition-all duration-200 px-6 py-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-user "
                          width="26"
                          height="26"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="#2c3e50"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <circle cx="12" cy="7" r="4" />
                          <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                        </svg>
                        <p
                          onClick={() => this.openProfile(showProfileBox)}
                          className="text-base truncate font-medium"
                        >
                          {ProfileName}
                        </p>
                      </div>
                      <div
                        onClick={() => this.handleLogout()}
                        className="flex items-center space-x-2 hover:bg-blue-100 transition-all duration-200 px-6 py-2 text-red-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-logout"
                          width="26"
                          height="26"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="#e24a4f"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                          <path d="M7 12h14l-3 -3m0 6l3 -3" />
                        </svg>
                        <span> Logout</span>
                      </div>
                    </div>
                  </>
                )}
              </div>

          </div>

          </div>
        </header>
     
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { user, dashboard } = state;
  return {
    loggingIn,
    user,
    dashboard,
  };
}
export default connect(mapStateToProps)(Header);
