import React, { Component } from 'react';
import { connect } from 'react-redux';
import BoxPlot from './Charts/BoxPlot';
import Commentary from '../../../Components/Commentary/Commentary';
import Filter from '../../../../../CommonComponents/Filters/IndexFilter/Filter';

class DriverComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: ''
    }
  }
  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handleMultiFilterQuantile } = this.props;
    this.setState({ FilterValues: finalFilter });
    handleMultiFilterQuantile(finalFilter);

  }

  render() {
    let { ErrorIndexObj, getIndexQuantileError, selectedFilterValue, loading, CountRestrict, getIndexQuantileCount, getEMPFilterLabel,
      indexType, optionType, getCommentData,

      getQuestionData, getQuestionFilterData, handleQuantileFilter, EmpFilterData, getIndexFilterData, getIndexQuantileData, getIndexByFilterDMGData

    } = this.props;

    let { currentSelectValue, FilterValues } = this.state;


    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    //  let DemographicDataScore = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      //  DemographicDataScore=getDemographicIndexMultiFilterData;
      //  DemographicDataDistribution=getIndexDistributionData;

    }

    let { GetParameterLabel, XAxis, name } = this.props;

    //console.log('getIndexQuantileData----->',getIndexQuantileData)



    let IndexQuantile = CountRestrict(getIndexQuantileCount) ? "NaN" : getIndexQuantileData;



    return (
      <>
        <div className="items-center justify-between  md:flex">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />

          {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
          <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        </div>


        {true ?
          <>

            {/* <div className="items-center justify-start xl:flex lg:flex md:flex"> */}

            {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}



            {/* </div> */}

            {/* {FilterValues && FilterValues.length > 0 ?
              <div className="items-center h-10 px-4 py-3 bg-white border-b  xl:flex lg:flex md:flex justify-left">
                {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
                  <p className="flex mx-2 my-4 text-sm"><p className="mr-1 text-blue-500">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
                ) : null}</div>
              : null} */}



            <div onClick={() => this.closeFilter(false)} className="" >
              {/* <div className="flex w-full" >


                <div className="w-full px-4 m-4 bg-white rounded shadow-lg">
                  <div className="py-4 text-lg font-semibold">{name} Behaviour-Range</div> */}

              <div className="w-full" >

                <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">

                  <div className="p-6 space-y-6 transition duration-150 bg-white border rounded-lg cursor-pointer hover:shadow-lg">

                    <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">{name} Behaviour-Range</h1>


                    {ErrorIndexObj && ErrorIndexObj["getIndexQuantileError"] ?
                      <>
                        <div className='my-24 text-2xl font-medium text-center text-gray-400'>{ErrorIndexObj["getIndexQuantileError"]}</div>
                      </>
                      :
                      <>
                        {IndexQuantile ?
                          <BoxPlot getIndexQuantileData={IndexQuantile} GetParameterLabel={GetParameterLabel} XAxis={XAxis} name={name} />
                          :
                          <div className='p-2 text-lg text-center text-gray-500'>{getIndexQuantileError}</div>}
                      </>}


                  </div>
                </div>
              </div>

            </div>
          </>
          :
          <>
            {!loading ?
              <div className="text-sm text-[#3d405B]/70">We have not calculated this section!</div>
              : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DriverComparision);
