import React, { Component } from "react";
import { connect } from "react-redux";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // showParaFilter:false,
      // showParaValue:'',
      currFilterList: [],
      finalFilter: [],
      searchName: "",
    };
  }
  componentDidMount() { }

  handleParameterQuestion = () => { };

  handleSelectFilter = (name) => {
    // this.setState({showParaFilter:false});
    this.props.closeParaFilter(false);

    let { currFilterList, finalFilter } = this.state;
    let { handleMultifilterIndexScore } = this.props;
    let getIndex = currFilterList.findIndex((prev) => prev === name);
    if (getIndex === -1) {
      currFilterList.push(name);
      finalFilter.push({
        level: name,
        value: "All",
      });

      this.setState({ currFilterList, finalFilter }, () => {
        handleMultifilterIndexScore(finalFilter, name);
      });
    } else {
      currFilterList.splice(getIndex, 1);
      let getIndex2 = finalFilter.findIndex((prev) => prev.level === name);
      finalFilter.splice(getIndex2, 1);

      this.setState({ currFilterList, finalFilter }, () => {
        if (finalFilter && finalFilter.length > 0) {
          handleMultifilterIndexScore(
            finalFilter,
            finalFilter[finalFilter.length - 1].level
          );
        } else {
          handleMultifilterIndexScore([]);
          // this.setState({showParaFilter:false})
          this.props.closeParaFilter(false);
        }
      });
    }
  };

  handleOpenFilter = (check) => {
    // this.setState({showParaFilter:!check,showParaValue:''});
    this.props.closeParaFilter(!check, "");
  };

  handleOpenValue = (name) => {
    if (this.state.showParaValue === name) {
      // this.setState({showParaValue:'',showParaFilter:false})
      this.props.closeParaFilter(false, "");
    } else {
      // this.setState({showParaValue:name,showParaFilter:false})
      this.props.closeParaFilter(false, name);
    }
  };

  handleFilterClear = () => {
    let { handleMultifilterIndexScore } = this.props;
    this.setState({ finalFilter: [], currFilterList: [] }, () => {
      handleMultifilterIndexScore([]);
    });
  };

  handleSelectAllFilter = () => { };

  handleSearch = (e) => {
    this.setState({ searchName: e.target.value });
  };

  render() {
    const ref = React.createRef();
    let {
      isSelectAll,
      EmpFilterData2,
      getIndexFilterData,
      List,
      showParaValue,
      showParaFilter,
      GetParameterLabel,
    } = this.props;
    let { searchName, currFilterList, finalFilter } = this.state;

    let DemographicValueList = {};
    if (getIndexFilterData) {
      DemographicValueList = getIndexFilterData;
      // DemographicValueList.sort();
    }

    //console.log('EmpFilterData2: ',EmpFilterData2)
    //console.log('currFilterList: ',currFilterList)
    //console.log('DemographicValueList: ',DemographicValueList)
    //console.log('finalFilter: ',finalFilter)

    function selectedValue(item) {
      let Value = "";
      let getIndex = finalFilter.findIndex((prev) => prev.level === item);
      if (getIndex !== -1) {
        if (
          finalFilter &&
          finalFilter[getIndex] &&
          finalFilter[getIndex].value
        ) {
          Value = finalFilter[getIndex].value;
        }
      }
      return Value;
    }

    function SortList(list) {
      // let temp = [];
      // if(list && list.length>0){
      //   temp= list.sort()
      // }
      return list;
    }

    let {
      showTargetDropdown,
      handleTargetDropdown,
      selectedTargetValue,
      selectTargetDimension,
      validParameterList,
      handleCompareDropdown,
      showCompareDropdown,
      compareDimenList,
      selectMultiDimension,
      submitTargetCompareCorrelation,
    } = this.props;

    let InitTargetValue = "";
    if (selectedTargetValue) {
      InitTargetValue = selectedTargetValue;
    } else {
      InitTargetValue =
        validParameterList && validParameterList.length > 0
          ? validParameterList[0]
          : "Choose Target";
    }

    // if(selectedTargetValue){
    //   InitTargetValue = GetParameterLabel(selectedTargetValue);
    // }
    // else{
    //   InitTargetValue = validParameterList&&validParameterList.length>0?"Default: "+GetParameterLabel(validParameterList[0]):"Choose Target";
    // }

    return (
      <div className="flex md:flex-row flex-col gap-4">
        <div className="flex flex-row  gap-4 items-center   capitalize w-full">

          <div className="  bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center justify-between font-medium space-x-2 relative md:w-auto w-full">
            <p
              onClick={() =>
                handleTargetDropdown(!showTargetDropdown, InitTargetValue)
              }
            >
              {GetParameterLabel(InitTargetValue)}
            </p>{" "}
            <span
              onClick={() =>
                handleTargetDropdown(!showTargetDropdown, InitTargetValue)
              }
              className="material-symbols-outlined"
            >
              arrow_drop_down
            </span>
            {showTargetDropdown ? (
              <>
                <div className="absolute -left-2 z-10 md:w-auto w-full py-2 font-normal bg-white rounded-lg shadow-lg top-12 min-h-auto max-h-60 overflow-y-auto ">

                  {/* <input type="text" name="searchName" value={searchName} onChange={this.handleSearch} placeholder="Search" className="w-full border p-1.5 rounded-sm focus:outline-none text-gray-500 mb-2 focus:ring-blue-500 focus:ring-1" /> */}
                  {validParameterList && validParameterList.length > 0
                    ? validParameterList.map((value, index) => (
                      <div
                        className={
                          selectedTargetValue === value
                            ? "block px-4 p-2 text-sm  text-[#3D405B]  cursor-pointer hover:bg-gray-100"
                            : "block px-4 p-2 text-sm  text-[#3D405B]  cursor-pointer hover:bg-gray-100"
                        }
                        onClick={() => selectTargetDimension(value)}
                      >
                        {GetParameterLabel(value)}
                      </div>
                    ))
                    : null}
                </div>
              </>
            ) : null}
          </div>


          <p class="font-medium text-[#3D405B]/70 flex-shrink-0">VS</p>

          <div className="  bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center justify-between font-medium space-x-2 relative md:w-auto w-full">
            <span
              onClick={() =>
                handleCompareDropdown(!showCompareDropdown, InitTargetValue)
              }
            >
              Dimensions
            </span>
            <p
              onClick={() =>
                handleCompareDropdown(!showCompareDropdown, InitTargetValue)
              }
              className=" bg-slate-100  rounded-full text-[#2196F3] text-xs w-6 h-6 flex items-center justify-center font-semibold"
            >
              {compareDimenList && compareDimenList.length > 0
                ? compareDimenList.length
                : List && List.length > 0
                  ? List.length
                  : 0}
            </p>
            <span
              onClick={() =>
                handleCompareDropdown(!showCompareDropdown, InitTargetValue)
              }
              className="material-symbols-outlined"
            >
              arrow_drop_down
            </span>
            {showCompareDropdown ? (
              <div className="absolute -left-2 z-10 md:w-auto w-full py-2 font-normal bg-white rounded-lg shadow-lg top-12 min-h-auto max-h-60 overflow-y-auto ">

                <label
                  onClick={() => selectMultiDimension("all", validParameterList)}
                  className="block px-4 p-2 text-sm  text-[#3D405B]  cursor-pointer hover:bg-gray-100"
                >
                  <input
                    type="checkbox"
                    checked={isSelectAll}
                    onClick={() =>
                      selectMultiDimension("all", validParameterList)
                    }
                    onChange={() => { }}
                  />
                  <span className="ml-2">{"Select All"}</span>
                </label>

                {validParameterList && validParameterList.length > 0
                  ? validParameterList.map((value, index) =>
                    selectedTargetValue !== value ? (
                      <label
                        onClick={() => selectMultiDimension(value)}
                        key={index}
                        className="block px-4 p-2 text-sm  text-[#3D405B]  cursor-pointer hover:bg-gray-100"
                      >
                        <input
                          type="checkbox"
                          checked={
                            compareDimenList.some((prev) => prev === value)
                              ? true
                              : false
                          }
                          onClick={() => selectMultiDimension(value)}
                          onChange={() => { }}
                        />
                        <span className="ml-2">{GetParameterLabel(value)}</span>
                      </label>
                    ) : null
                  )
                  : null}
              </div>
            ) : null}
          </div>


        </div>
        <button
          onClick={() => submitTargetCompareCorrelation(false, [])}
          className="cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md border border-[#2196f3] md:w-auto w-full "
        >
          Submit
        </button>
      </div>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(Filter);
