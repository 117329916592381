import React, { Component } from 'react';
import { connect } from 'react-redux';
import DemographicFilter from './Filters/DemographicFilter';
import QuestionFilter from "../Filters/QuestionFilter";
import WordChart from './Content/WordChart';
import WordDetails from './Content/WordDetails';
import Comments from './Content/Comments';
import Commentary from '../../../Index/Components/Commentary/Commentary';

class TextAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuesId:'',
      DemographicFilterName:'',
      DemographicFilterValue:'',
      FilterList:[],
      showFilter:false,
      showValue:'',

      showViewBy:false
    }
  }


  componentDidMount() {
  }

  closeFilter=(check1,check2)=>{
    this.setState({showFilter:check1, showValue:check2});
  }

  closeFilterQues=(check1)=>{
    this.setState({showViewBy:check1});
  }

  handleChangeQuesIdSave=(Id)=>{
    this.setState({currentQuesId:Id})
  }

  FilterSave=(name, value)=>{
    this.setState({DemographicFilterName:name, DemographicFilterValue:value})
  }

  handleKeywordCall=(keyWord)=>{
    let { currentQuesId,  FilterList } = this.state;
    let { getTextQuestionData, callWordCloudText } = this.props;

    let initQuesId = '';
    if(currentQuesId){
      initQuesId = currentQuesId;
    }
    else{
      initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
    }



    callWordCloudText(initQuesId, keyWord, FilterList)
  }


  handleMultifilterIndexScore2=(finalFilter)=>{
    let { getTextQuestionData } = this.props;
    let { currentQuesId , graphType } = this.state;
    let initQuesId = '';
    if(currentQuesId){
      initQuesId = currentQuesId;
    }
    else{
      initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
    }

    let { handleWordCloudDemographicFilter }= this.props;
    
    this.setState({FilterList:finalFilter});

    handleWordCloudDemographicFilter(initQuesId,finalFilter, graphType, "advance");

  }

    render(){

        let { getIndexByFilterDMGData, indexType,optionType,getCommentData,loading,callQuestionWordCloud, wordTextKeyWord, wordCloudTextData, getTextQuestionData, handleChangeQuestion, wordCloudData,EmpFilterData } = this.props;

        let { FilterList } = this.state;

        //////console.log('getTextQuestionData: ',getTextQuestionData)

        // let initQuesId = '';


        // if(currentQuesId){
        //   initQuesId = currentQuesId;
        // }
        // else{
        //   initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
        // }


        let { CountRestrict } = this.props;
        let count = wordCloudData && wordCloudData.count?wordCloudData.count:"NaN";
        let CloudData = CountRestrict(count)?[]:wordCloudData;
        let CloudTextData = CountRestrict(count)?[]:wordCloudTextData;
        let wordTextKeyWordData = CountRestrict(count)?"":wordTextKeyWord;

        //////console.log('-----:wordCloudData ',wordCloudData)

        return(
            <>
            <div onClick={()=>this.closeFilter(false)}  className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">
              <QuestionFilter
                          showViewBy={this.state.showViewBy}
                          closeFilterQues={this.closeFilterQues}

                callQuestionWordCloud={callQuestionWordCloud} getTextQuestionData={getTextQuestionData} handleChangeQuestion={handleChangeQuestion} handleChangeQuesIdSave={this.handleChangeQuesIdSave}
              />
              <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />
            </div>


            <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">
            <DemographicFilter 
                                      showFilter={this.state.showFilter}
                                      showValue={this.state.showValue}
                                      closeFilter={this.closeFilter}

              EmpFilterData2={EmpFilterData}
              getIndexFilterData={getIndexByFilterDMGData}
              handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
              />
            </div>

              {/* {FilterList && FilterList.length>0?
              <div className=" px-4 xl:flex lg:flex md:flex  justify-left items-center bg-white border-b py-3 h-10 ">
                {FilterList && FilterList.length>0?FilterList.map((element)=>
                <p className="text-md mx-2 my-4 font-semibold flex"><p className="text-blue-500 mr-1">{element.level+": "}</p><p className="text-gray-500 ">{element.value}</p></p>
                ):null}</div>
            :null} */}
        {FilterList && FilterList.length > 0 ?
          <div className=" px-4 xl:flex lg:flex md:flex justify-left items-center bg-white border-b py-3 h-10 ">
            {FilterList && FilterList.length > 0 ? FilterList.map((element) =>
              <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}</div>
          : null}

            

            {getTextQuestionData && getTextQuestionData.length>0?
          
                <div>
                



                <div onClick={()=>{
                  this.closeFilter(false);
                  this.closeFilterQues(false);
                }}  className=" " >
              <div className="flex w-full" >
                
              
                    
                    
                    
                    <WordChart loading={loading} wordCloudData={CloudData} handleKeywordCall={this.handleKeywordCall}/>




                    </div>
                    
                    <div className=" md:flex">

                    <WordDetails loading={loading} wordCloudTextData={CloudTextData} wordTextKeyWord={wordTextKeyWordData}/>
                    
                    {/* <Comments/> */}


                    </div>
                </div>
                </div>

            :
          <>
          {!loading?
          <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>:null}
          </>
            }
            
            </>


        );
    }
}
function mapStateToProps(state) {
  // //////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(TextAnalysis);
