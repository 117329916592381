import React, { Component } from 'react';
import { connect } from 'react-redux';

class AssignManagerActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    return (
      <> 
        <div className="w-full  flex justify-center items-center -mt-30">
              {/*  text start*/}
              <div className="text-center">
                <img alt="item" src="img/empty_survey.png" className="mx-auto" />
                <h1 className="text-gray-800 text-lg  font-semibold cursor-default">This survey has no dimensions <br/> Please choose different survey</h1>
                {/* <p className="text-xs text-gray-500 cursor-default">Let's get started &amp; assign your actions!</p>    */}
              </div>
            </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AssignManagerActions);
