import React from 'react';
import Parameter from '../Parameter/Parameter';
import ParameterView from '../Parameter/ParameterView';

export default function TextBox(props) {
  let { SurveyTemplateId, question, quesindex, currQuesEditId, showTranslatePage, translShortName,
    translQuestion, handleTranslateQuestion, onTranslateQuestionSave,
    onCreateNewBucket,
    handleQuestionInput, handleOptionInput, onQuesMandatory, onSaveQues,
    handleParameterBucket, parameterList, handleParameterQuestion, paraBucketName,
    showBucketDropdown, showDimensionDropdown, handleParameterDimension, getParameterListbyParentData, paraDimensionName,
    innerEdit, handleQuesCancel, GetParameterLabel, handleNudgeModal
  } = props;
  // let BucketName = question && question.parameterBucketId && question.parameterBucketId.name?question.parameterBucketId.name:"";
  // let DimensionName = question && question.parameterDimensionId && question.parameterDimensionId.name?question.parameterDimensionId.name:"";

  let [showBotResEdit, handleBotRespEdit] = React.useState(false);

  return (
    <>
      {currQuesEditId === question.id ?
        <>
          {showTranslatePage ?
            <>
              <div className="flex justify-between items-center">
                <div className="text-sm font-medium text-[#212121]">Question</div>
                <div className="flex px-2">
                  <div onClick={() => onTranslateQuestionSave(true, translShortName)} className="py-1 px-3 rounded-sm border border-1 border-green-500 cursor-pointer text-sm text-green-500 mr-2">Save</div>
                  <div onClick={() => onTranslateQuestionSave(false, translShortName)} className="py-1 px-2 rounded-sm border border-1 border-red-500 cursor-pointer text-sm text-red-500">Cancel</div>
                </div>                    </div>
              <input
                type="text"
                value={question.name}
                onChange={(event) => handleQuestionInput(event.target.value, question.id)}
                placeholder={"Q." + (quesindex + 1) + " " + question.name}
                className="py-2 px-4 border outline-none w-full mt-2 rounded-md"
              />
              <input
                type="text"
                value={translQuestion["transl"][translShortName].name}
                onChange={(event) => handleTranslateQuestion(event.target.value, question.id, translShortName)}
                placeholder="Translate here..."
                className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2"
              />

            </>
            :
            <>

              <div className="flex justify-between items-center">
                <div className="text-sm font-medium text-[#212121]">Question</div>
                <div onClick={() => handleQuesCancel(false)} className="border-1 border-red-500 cursor-pointer text-sm font-semibold text-red-400 mr-2">Cancel</div>
              </div>
              <input
                type="text"
                value={question.name}
                onChange={(event) => handleQuestionInput(event.target.value, question.id)}
                placeholder={"Q." + (quesindex + 1) + " " + question.name}
                className="py-2 px-4 border outline-none w-full mt-2 rounded-md"
              />
              <ul className="pb-4">
                {/* <p className="text-xs text-gray-500 pb-2">Answer</p> */}
                <div className="mb-1.5 mt-2" >
                  <div className="py-1" >
                    <input
                      id="mandatory-check"
                      value={question.isMandatory}
                      checked={question.isMandatory}
                      type="checkbox"
                      onChange={() => { }}
                      className="cursor-pointer ml-1"
                      onClick={() => onQuesMandatory(question.isMandatory, question.id)}
                    />
                    <label htmlFor="mandatory-check" className="text-sm text-gray-500 pb-8 pl-2">Mandatory</label>
                    {/* <div className="ml-6 mt-1 cursor-pointer text-sm text-purple-500 uppercase font-semibold" onClick={()=>onParamaterModal(!showParameterModal)} ><i className={showParameterModal?"fa fa-times":"pr-2 fa fa-plus"} />{showParameterModal?"":"Add Paramaters"}</div> */}

                    <div className="py-1 flex space-x-2" >
                      <div onClick={() => handleNudgeModal(question, true)}
                        className='cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md '>
                        Add Nudge</div>
                      <div onClick={() => handleQuestionInput(" [variable] ", question.id, true)}
                        className='cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md '>
                        Add Variable</div>
                      {/* <div onClick={()=>handleBotRespEdit(!showBotResEdit)} className={showBotResEdit?
                            'cursor-pointer text-sm py-2 px-4 font-medium bg-yellow-500 text-white rounded-md ':
                            'cursor-pointer text-sm py-2 px-4 font-medium bg-purple-500 text-white rounded-md '
                            }>Bot Edit</div> */}
                    </div>

                  </div>



                  <Parameter
                    showBucketDropdown={showBucketDropdown}
                    parameterList={parameterList}
                    handleParameterBucket={handleParameterBucket}
                    handleParameterQuestion={handleParameterQuestion}
                    getParameterListbyParentData={getParameterListbyParentData}
                    paraBucketName={paraBucketName}
                    question={question}
                    showDimensionDropdown={showDimensionDropdown}
                    paraDimensionName={paraDimensionName}
                    handleParameterDimension={handleParameterDimension}
                    GetParameterLabel={GetParameterLabel}
                    onCreateNewBucket={onCreateNewBucket}
                    SurveyTemplateId={SurveyTemplateId}

                  />


                  <div className="py-4">
                    {question && question.optionsList && question.optionsList.length ?
                      question.optionsList.map((option, optionindex) =>
                        <>
                          <div className="flex items-center justify-between py-2">
                            <div className='mr-2'>Bot: </div>
                            <div className=" w-full">
                              <input
                                type="text"
                                value={option.bot}
                                onChange={(event) => handleOptionInput(event.target.value, question.id, option.id, "bot")}
                                placeholder={option.bot}
                                className="border border-purple-500 outline-none  px-4 text-xs py-1.5 w-full mr-4"
                              />

                            </div>
                          </div>

                        </>
                      ) : null}
                  </div>



                </div>



              </ul>


              <div className="flex justify-center py-2 mt-24">
                <span className="cursor-pointer text-sm rounded uppercase   bg-[#2196f3] py-2 px-4 text-white w-28  mx-1 text-center" onClick={() => onSaveQues(question)}> save</span>
              </div>


            </>}
        </>
        :
        <>
          <div title={(question && question.isNonEdit ? "" : "Double Click to Edit!")} onDoubleClick={() => innerEdit(question, SurveyTemplateId)} className="space-y-4">
            <ParameterView question={question} GetParameterLabel={GetParameterLabel} />
            <div className="text-slate-800 font-semibold text-sm inline-flex">{question.parentId ? <p className="pr-2">{" - "}</p> : <p className="pr-2">Q{quesindex + 1}.</p>}  <h1>{question.name}{(question.isMandatory) ? '*' : null}</h1> </div>


            <ul className=" pl-6">
              <input className="border-2 border-gray-200 w-11/12 h-10 rounded-md text-sm px-2" placeholder="Write something Here!" />
            </ul>
          </div>
        </>
      }
    </>
  );
}