import React from 'react';
// import { connect } from 'react-redux';



  export default function Filter(props) {

    let {getAnsweredEmployeesData, handleSelectEmployee } = props;

    let [showFilter, handleOpenFilter ] = React.useState(false);



    let {  crrSelectedEmp } = props;





    return (
      <>






          <div className="cursor-pointer bg-white md:w-auto w-full text-gray-500    border border-2 border-gray-500  flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5">
            

            <div>
                <h1 className='text-xs font-medium text-gray-800'>Employee: </h1>
            </div>
            <p  onClick={()=>handleOpenFilter(!showFilter)} className="text-xs px-2 text-gray-900">{crrSelectedEmp && crrSelectedEmp.name?crrSelectedEmp.name:"Select" }</p> 
            
            
            <span  onClick={()=>handleOpenFilter(!showFilter)} className="material-icons ">arrow_drop_down</span>
            
            {showFilter && getAnsweredEmployeesData && getAnsweredEmployeesData.length>0?
            <div className="z-10 absolute right-0 top-10 bg-white shadow-lg p-3 font-normal text-xs w-full h-60 overflow-x-auto customscroll2 rounded-md">

              {getAnsweredEmployeesData && getAnsweredEmployeesData.length>0?
                getAnsweredEmployeesData.map((emp,index)=>
                <div key={index}  className="py-2 m-1 flex items-center cursor-pointer" 
                  onClick={()=>{ handleSelectEmployee(emp); handleOpenFilter(false)}}
                  >
                    <input type="checkbox" checked={crrSelectedEmp.emp_id === emp.emp_id}  onChange={()=>{}}/>
                    <label  className="ml-2 font-medium">{emp.name}</label> 
                </div>
                ):null}
              </div>
             :null}
          </div>
          







        {/* </div> */}
        
      </>
    );
  }
