import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class QuestionStack extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { EmpFilterData, ParameterName, QuestionList, CompleteQuestionList  } = this.props;
    

    // //////console.log('ParameterName: ',ParameterName)

    
    


    let SelectedQuestionList = []

    if(QuestionList && QuestionList.length>0){
        QuestionList.forEach((ques)=>{
            if(ques.para === ParameterName){
                SelectedQuestionList.push(ques)
            }
        })
    }

    // //////console.log('ParameterName: ',ParameterName)
    // //////console.log('SelectedQuestionList: ',SelectedQuestionList)
    // //////console.log('NewQuesDynaObj: ',NewQuesDynaObj)

    let NewQuestionList = []
    if(SelectedQuestionList && SelectedQuestionList.length>0){
        SelectedQuestionList.forEach((Ques,index)=>{
            SelectedQuestionList[index]['Dimensions'] = []
            if(CompleteQuestionList && CompleteQuestionList.length>0){
                CompleteQuestionList.forEach((completeQues)=>{
                    if((completeQues.name === Ques.name)&&(completeQues.para === ParameterName)){
                        SelectedQuestionList[index]['Dimensions'].push({
                            "name":completeQues.filter,
                            "score":completeQues.score
                        })
                    }
                })
            }
        })
    }


    
    
    //////console.log('SelectedQuestionList: ',SelectedQuestionList)

            // if(CompleteQuestionList && CompleteQuestionList.length>0){
            //     CompleteQuestionList.forEach((CompleteQues)=>{

            //         if(Ques === CompleteQues){

            //         }


            //     })
            // }



    let total = 0
    let names = []
    let weightage1 = [];
    let weightage2 = [];
    let weightage3 = [];
    let weightage4 = [];
    let weightage5 = [];
    let weightage6 = [];


    let weightage = {};
    let colorList = ['#dab3ff','#ffdf91','#94ebcd','#ffb4b4']

    if(EmpFilterData && EmpFilterData.length>0){
        EmpFilterData.forEach((emp)=>{
            weightage[emp.name]=[]
        })
    }


    if(SelectedQuestionList && SelectedQuestionList.length>0){
        SelectedQuestionList.forEach((Ques)=>{


            if(true){
                names.push({
                    value:Ques.name,
                    textStyle:{fontWeight:'bold'}
                })

                if(Ques && Ques.Dimensions && Ques.Dimensions.length>0){
                    Ques.Dimensions.forEach((dimen,index)=>{
                        weightage[dimen.name].push({
                            value:dimen.score,
                            itemStyle: {color: colorList[index]}
                        });
                    })
                }
 

                // weightage1.push({
                //     value:(parseFloat("2")*100/total).toFixed(2),
                //     itemStyle: {color: '#e60000'}
                // });
                // weightage2.push({
                //     value:(parseFloat("3")*100/total).toFixed(2),
                //     itemStyle: {color: '#ff3333'}
        
                // });
                // weightage3.push({
                //     value:(parseFloat("4")*100/total).toFixed(2),
                //     itemStyle: {color: '#ff8080'}
        
                // });
                // weightage4.push({
                //     value:(parseFloat("4")*100/total).toFixed(2),
                //     itemStyle: {color: '#6ad4fb'}
        
                // });
                // weightage5.push({
                //     value:(parseFloat("2")*100/total).toFixed(2),
                //     itemStyle: {color: '#1fbff9'}
        
                // });
                // weightage6.push({
                //     value:(parseFloat("6")*100/total).toFixed(2),
                //     itemStyle: {color: '#06a6e0'}
        
                // });
            }


        })
    }


    //////console.log('weightage=>',weightage)


    let SeriesData = []
    if(EmpFilterData && EmpFilterData.length>0){
        EmpFilterData.forEach((emp)=>{
            let seriesName = {
                name: (emp.name==='ManagerPID')?"Manager":emp.name,
                type: 'bar',
                stack: '总量',
                barWidth:'20px',
                label: {
                    show: true,
                    position: 'insideRight',
                    formatter: function(d) {
                        if(parseInt(d.value) <5){
                            return '';
                        }
                        else{
                            return d.value;
                        }
                    }
                },
                data: weightage[emp.name],
                inverse:true
            }

            SeriesData.push(seriesName)
        })
    }



    // if(true){
    //     total = 2 + 3 + 4 + 4 + 2 + 6;
    //     names.push({
    //         value:QuestionName,
    //         textStyle:{fontWeight:'bold'}
    //     })
    //     weightage1.push({
    //         value:(parseFloat("2")*100/total).toFixed(2),
    //         itemStyle: {color: '#e60000'}
    //     });
    //     weightage2.push({
    //         value:(parseFloat("3")*100/total).toFixed(2),
    //         itemStyle: {color: '#ff3333'}

    //     });
    //     weightage3.push({
    //         value:(parseFloat("4")*100/total).toFixed(2),
    //         itemStyle: {color: '#ff8080'}

    //     });
    //     weightage4.push({
    //         value:(parseFloat("4")*100/total).toFixed(2),
    //         itemStyle: {color: '#6ad4fb'}

    //     });
    //     weightage5.push({
    //         value:(parseFloat("2")*100/total).toFixed(2),
    //         itemStyle: {color: '#1fbff9'}

    //     });
    //     weightage6.push({
    //         value:(parseFloat("6")*100/total).toFixed(2),
    //         itemStyle: {color: '#06a6e0'}

    //     });
    // }




    
                        

    return (
      <>
        <div >
                            <ReactEcharts
                            style={{height:'140px',marginTop:"-5.5%"}}
                            option={{
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {            
                                    type: 'shadow'        
                                }
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'value',
                                axisLine:'left',
                                position:'bottom',
                                splitLine: {
                                    lineStyle: {
                                        color: 'rgb(245, 245, 245)'
                                    }
                                },
                                axisLabel:{
                                    show:false
                                }
                                

                            },
                            yAxis: {
                                type: 'category',
                                data: names,
                                position:'left',
                                inverse:true,
                                axisLine: {
                                    lineStyle: {
                                      color:'#e6e6e6'
                                    }
                                  }
                            },
                            axisLabel: {
                                color: "#000",
                                interval: 0,
                                formatter: function(value) {
                                  if (value.length > 20) {
                                    return value.substring(0, 20) + ".. :";
                                  } else {
                                    return value + ":";
                                  }
                                }
                              },
                            series: SeriesData
                        }}
                          />
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
//   ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionStack);
