import React, { Component } from 'react';
import { connect } from 'react-redux';

import ScheduleModal from './Modal/ScheduleModal';
import ScheduleComplete from './Modal/ScheduleComplete';

import CreateActionModal from './Modal/CreateActionModal';
import ResolveModal from './Modal/ResolveModal';
import PluseScoreModal from './Modal/PluseScoreModal';
import ActionComplete from './Modal/ActionComplete';

import CreateNoteModal from './Modal/CreateNoteModal';
import CreateTalkingModal from './Modal/CreateTalkingModal';

import moment from 'moment';
import EmployeeRiskProfile from './Modal/EmployeeRiskProfile';

import Demographics from './Modal/Demographics';

import { CSVLink } from "react-csv";

class OneToOneConversation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      crrEmployeeId: "",
      showTab: "talking",
      active: false,

      showScheduleModal: false,
      showScheduleCompleteModal: false,

      showCreateActionModal: false,
      showActionCompleteModal: false,

      showResolveModal: false,
      showRiskProfileModal: false,

      showCreateNoteModal: false,
      showCreateTalkingModal: false,

      showActionText: false,

      showCatgory: false,

      showEmpPopUp: false,

      showMobileEMPDropdown: false,

      empEmployeeID: "",



      showFilterDMG: false,
      showValueDMG: '',
      FilterValuesDMG: [],


      selectedCategory: {
        "name": "All",
        "label": "Show All"
      },


      formSchedule: {
        "date": "",
        "time": ""
      },
      formAction: {
        "name": "",
        "desc": ""
      },
      formNotes: {
        "name": "",
        "desc": ""
      },
      talking: "",
      formScheduleComplete: {},
      formActionComplete: {},

      crrEmployeeData: {},

      crrPulseScoreList: [],

      onShowPTMemp: false,
      selectedPTMStatus: "Open",
      resolvedText: ""

    }
  }

  handleClick() {
    this.setState({
      active: !this.state.active
    })
  }

  componentDidMount() {

  }

  selectEmp = (id) => {
    this.setState({ crrEmployeeId: id, showMobileEMPDropdown: false })
  }

  handleTab = (value) => {
    this.setState({ showTab: value })
  }

  openScheduleModal = (value) => {
    this.setState({ showScheduleModal: value })
  }

  handleInputField = (e) => {
    let { name, value } = e.target;
    let { formSchedule } = this.state;
    formSchedule[name] = value;
    this.setState({ formSchedule })
  }

  handleSubmit = () => {
    let { formSchedule, crrEmployeeId } = this.state;
    let { handleOneToOneScheduleSubmit, getAllRiskEmpConversationData } = this.props;


    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId
    }
    else {
      if (getAllRiskEmpConversationData && getAllRiskEmpConversationData && getAllRiskEmpConversationData.list.length > 0) {
        initEmpId = getAllRiskEmpConversationData.list[0].id
      }
    }
    //////console.log("'''initEmpId__,", initEmpId)
    //////console.log("'''crrEmployeeId,", crrEmployeeId)
    //////console.log("'''getAllRiskEmpConversationData,", getAllRiskEmpConversationData)



    if (formSchedule && formSchedule.date && formSchedule.time) {
      let data = {
        "conversationId": initEmpId,
        "name": formSchedule.date,
        "desc": formSchedule.time
      }
      //////console.log("handleSubmitssssssssss-->", data)
      handleOneToOneScheduleSubmit(data, 1)

      this.onCloseScheduleModal()
    }
  }

  onCloseScheduleModal = () => {
    this.setState({ showScheduleModal: false })
  }

  onCloseCompleteScheduleModal = () => {
    this.setState({ showScheduleCompleteModal: false })
  }

  onCloseActionModal = () => {
    this.setState({ showActionText: false })
  }


  onCloseNoteModal = () => {
    this.setState({ showNoteText: false })
  }





  handleActionSubmit = () => {
    let { formAction, crrEmployeeId } = this.state;
    let { getAllRiskEmpConversationData } = this.props;




    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++
      }
      return count ? true : false
    }
    let { selectedPTMStatus } = this.state;
    let getAllRiskEmpConversationDataList = []
    if (getAllRiskEmpConversationData && getAllRiskEmpConversationData && getAllRiskEmpConversationData.list.length > 0) {
      getAllRiskEmpConversationData.list.forEach((item) => {
        if (selectedPTMStatus === "Resolved") {
          if (item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }
        else if (selectedPTMStatus === "InProgress") {
          if (getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }
        else if (selectedPTMStatus === "Open") {
          if (!getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }

      })
    }
    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId
    }
    else {
      if (getAllRiskEmpConversationDataList && getAllRiskEmpConversationDataList.length > 0) {
        initEmpId = getAllRiskEmpConversationDataList[0].id
      }
    }


    if (formAction && formAction.name) {
      let data = {
        "conversationId": initEmpId,
        "name": formAction.name,
        "desc": formAction.desc
      }
      //////console.log("handleActionSubmitssssssssss-->", data)
      // handleOneToOneActionSubmit(data)

      this.onCloseActionModal()
    }
  }


  onTakingField = (value) => {
    this.setState({ showTalkingField: value });
  }

  onActionField = (value) => {
    this.setState({ showActionText: value });
  }

  onNoteField = (value) => {
    this.setState({ showNoteText: value });
  }



  handleInputTalking = (e) => {
    let { value } = e.target;
    this.setState({ talking: value })
  }



  // SumbitTalking=()=>{
  //   let { talking } = this.state;
  //   let { handleTalkingSubmit } = this.props;
  //   if(talking){
  //     handleTalkingSubmit(talking)
  //   }
  //   this.setState({showTalkingField:false});
  // }
  SumbitTalking = () => {
    let { talking, crrEmployeeId } = this.state;
    let { handleTalkingSubmit, getAllRiskEmpConversationData } = this.props;




    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++
      }
      return count ? true : false
    }
    let { selectedPTMStatus } = this.state;
    let getAllRiskEmpConversationDataList = []
    if (getAllRiskEmpConversationData && getAllRiskEmpConversationData && getAllRiskEmpConversationData.list.length > 0) {
      getAllRiskEmpConversationData.list.forEach((item) => {
        if (selectedPTMStatus === "Resolved") {
          if (item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }
        else if (selectedPTMStatus === "InProgress") {
          if (getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }
        else if (selectedPTMStatus === "Open") {
          if (!getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }

      })
    }
    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId
    }
    else {
      if (getAllRiskEmpConversationDataList && getAllRiskEmpConversationDataList.length > 0) {
        initEmpId = getAllRiskEmpConversationDataList[0].id
      }
    }

    //////console.log("'''initEmpId__,", initEmpId)
    //////console.log("'''crrEmployeeId,", crrEmployeeId)
    //////console.log("'''getAllRiskEmpConversationData,", getAllRiskEmpConversationData)



    if (talking) {
      let data = {
        "conversationId": initEmpId,
        "name": talking,
        "desc": ""
      }
      //////console.log("handleSubmitssssssssss-->", data)
      handleTalkingSubmit(data, 11)

      this.onTakingField(false)
    }
  }








  openScheduleCompletedModal = (value) => {
    //////console.log("value--------->", value)
    this.setState({ showScheduleCompleteModal: true, formScheduleComplete: value })
  }
  closeCompleteField = (value) => {
    this.setState({ showScheduleCompleteModal: value });
  }
  handleCompleteScheduleSubmit = () => {
    let { crrEmployeeId, formScheduleComplete } = this.state;
    let { handleOneToOneScheduleSubmit, getAllRiskEmpConversationData } = this.props;

    //////console.log("formScheduleComplete-->", formScheduleComplete)



    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++
      }
      return count ? true : false
    }
    let { selectedPTMStatus } = this.state;
    let getAllRiskEmpConversationDataList = []
    if (getAllRiskEmpConversationData && getAllRiskEmpConversationData && getAllRiskEmpConversationData.list.length > 0) {
      getAllRiskEmpConversationData.list.forEach((item) => {
        if (selectedPTMStatus === "Resolved") {
          if (item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }
        else if (selectedPTMStatus === "InProgress") {
          if (getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }
        else if (selectedPTMStatus === "Open") {
          if (!getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }

      })
    }
    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId
    }
    else {
      if (getAllRiskEmpConversationDataList && getAllRiskEmpConversationDataList.length > 0) {
        initEmpId = getAllRiskEmpConversationDataList[0].id
      }
    }



    if (formScheduleComplete && formScheduleComplete._id) {
      let data = {
        "conversationId": initEmpId,
        "name": formScheduleComplete._id
      }
      //////console.log("handleActionSubmitssssssssss-->", data)
      handleOneToOneScheduleSubmit(data, 12)

      this.closeCompleteField(false)
    }
  }



  onCreateAction = (initId) => {
    this.setState({
      showCreateActionModal: true,
      crrEmployeeId: initId
    })

  }

  closeCreateActionModal = () => {
    this.setState({
      showCreateActionModal: false
    })

  }

  onCloseCompleteActionModal = () => {
    this.setState({ showActionCompleteModal: false })

  }

  handleInputActionField = (e) => {
    let { name, value } = e.target;
    let { formAction } = this.state;
    formAction[name] = value;
    this.setState({ formAction })
  }
  handleCreateActionSubmit = () => {
    let { formAction, crrEmployeeId } = this.state;
    let { handleOneToOneScheduleSubmit, getAllRiskEmpConversationData } = this.props;




    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++
      }
      return count ? true : false
    }
    let { selectedPTMStatus } = this.state;
    let getAllRiskEmpConversationDataList = []
    if (getAllRiskEmpConversationData && getAllRiskEmpConversationData && getAllRiskEmpConversationData.list.length > 0) {
      getAllRiskEmpConversationData.list.forEach((item) => {
        if (selectedPTMStatus === "Resolved") {
          if (item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }
        else if (selectedPTMStatus === "InProgress") {
          if (getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }
        else if (selectedPTMStatus === "Open") {
          if (!getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }

      })
    }
    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId
    }
    else {
      if (getAllRiskEmpConversationDataList && getAllRiskEmpConversationDataList.length > 0) {
        initEmpId = getAllRiskEmpConversationDataList[0].id
      }
    }



    if (formAction && formAction.name) {
      let data = {
        "conversationId": initEmpId,
        "name": formAction.name,
        "desc": formAction.desc
      }
      console.log("handleActionSubmitssssssssss------>", data)
      handleOneToOneScheduleSubmit(data, 2)

      this.closeCreateActionModal()
    }
  }



  openActionCompletedModal = (value) => {
    //////console.log("value--------->", value)
    this.setState({ showActionCompleteModal: true, formActionComplete: value })
  }
  closeActionCompleteField = () => {
    this.setState({ showActionCompleteModal: false });
  }
  handleCompleteActionSubmit = () => {
    let { crrEmployeeId, formActionComplete } = this.state;
    let { handleOneToOneScheduleSubmit, getAllRiskEmpConversationData } = this.props;

    //////console.log("formActionComplete-->", formActionComplete)



    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++
      }
      return count ? true : false
    }
    let { selectedPTMStatus } = this.state;
    let getAllRiskEmpConversationDataList = []
    if (getAllRiskEmpConversationData && getAllRiskEmpConversationData && getAllRiskEmpConversationData.list.length > 0) {
      getAllRiskEmpConversationData.list.forEach((item) => {
        if (selectedPTMStatus === "Resolved") {
          if (item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }
        else if (selectedPTMStatus === "InProgress") {
          if (getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }
        else if (selectedPTMStatus === "Open") {
          if (!getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }

      })
    }
    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId
    }
    else {
      if (getAllRiskEmpConversationDataList && getAllRiskEmpConversationDataList.length > 0) {
        initEmpId = getAllRiskEmpConversationDataList[0].id
      }
    }



    if (formActionComplete && formActionComplete._id) {
      let data = {
        "conversationId": initEmpId,
        "name": formActionComplete._id
      }
      //////console.log("handleActionSubmitssssssssss-->", data)
      handleOneToOneScheduleSubmit(data, 21)

      this.closeActionCompleteField(false)
    }
  }








  onCreateNote = (initId) => {
    this.setState({ showCreateNoteModal: true, crrEmployeeId: initId })
  }

  closeCreateNoteModal = () => {
    this.setState({ showCreateNoteModal: false })
  }


  handleInputNoteField = (e) => {
    let { name, value } = e.target;
    let { formNotes } = this.state;
    formNotes[name] = value;
    this.setState({ formNotes })
  }

  handleCreateNoteSubmit = () => {
    let { formNotes, crrEmployeeId } = this.state;
    let { handleOneToOneScheduleSubmit, getAllRiskEmpConversationData } = this.props;



    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++
      }
      return count ? true : false
    }
    let { selectedPTMStatus } = this.state;
    let getAllRiskEmpConversationDataList = []
    if (getAllRiskEmpConversationData && getAllRiskEmpConversationData && getAllRiskEmpConversationData.list.length > 0) {
      getAllRiskEmpConversationData.list.forEach((item) => {
        if (selectedPTMStatus === "Resolved") {
          if (item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }
        else if (selectedPTMStatus === "InProgress") {
          if (getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }
        else if (selectedPTMStatus === "Open") {
          if (!getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }

      })
    }
    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId
    }
    else {
      if (getAllRiskEmpConversationDataList && getAllRiskEmpConversationDataList.length > 0) {
        initEmpId = getAllRiskEmpConversationDataList[0].id
      }
    }


    if (formNotes && formNotes.name) {
      let data = {
        "conversationId": initEmpId,
        "name": formNotes.name,
        "desc": formNotes.desc
      }
      //////console.log("handleActionSubmitssssssssss-->", data)
      handleOneToOneScheduleSubmit(data, 3)

      this.closeCreateNoteModal()
    }
  }


  onShowCatgory = (check) => {
    this.setState({ showCatgory: check })
  }

  handleSelectEmpCategory = (value) => {
    this.setState({ selectedCategory: value, showCatgory: false })
  }


  handleDetailsPopUp = (value) => {


    this.setState({ empEmployeeID: value, showEmpPopUp: true }, () => {
      let { handleSelectEmpFilter } = this.props;
      handleSelectEmpFilter(value)
    })

  }

  closeEmpPopUpModal = () => {
    this.setState({ showEmpPopUp: false, empEmployeeID: "" })
  }


  handleMultifilterIndexScore2DMG = (finalFilter) => {
    this.setState({ FilterValuesDMG: finalFilter }, () => {
      let { emptyRiskQuesEmp } = this.props;
      emptyRiskQuesEmp()
    });
  }



  closeFilterDMG = (check1, check2) => {
    this.setState({ showFilterDMG: check1, showValueDMG: check2 });
  }


  onCreateTalking = () => {
    this.setState({ showCreateTalkingModal: true })
  }

  closeCreateTalkingModal = () => {
    this.setState({ showCreateTalkingModal: false })
  }

  onResolveModal = (item) => {
    this.setState({ showResolveModal: true, crrEmployeeData: item })
    //////console.log("item----------->",item)
  }

  onSubmitResolve = () => {
    let { crrEmployeeData, resolvedText } = this.state;
    let itemFinal = crrEmployeeData;

    if (itemFinal && itemFinal.id) {
      let data = {
        "id": itemFinal.id,
        "text": resolvedText
      }

      let { submitPeoptoMeetResolved } = this.props;
      submitPeoptoMeetResolved(data)
    }

    setTimeout(() => {
      this.setState({ showResolveModal: false, crrEmployeeData: {}, resolvedText: "" })
    }, 1000)

  }



  closeResolveModal = () => {
    this.setState({ showResolveModal: false, resolvedText: "" })
  }


  onRiskProfileModal = (initId) => {
    this.setState({ showRiskProfileModal: true, crrEmployeeId: initId }, () => {

    })
  }

  closePluseModal = () => {
    this.setState({ showRiskProfileModal: false })
  }



  handleInputTalkingField = (e) => {
    let { value } = e.target;
    this.setState({ talking: value })
  }

  handleCreateTalkingSubmit = () => {
    let { talking, crrEmployeeId } = this.state;
    let { handleTalkingSubmit, getAllRiskEmpConversationData } = this.props;


    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId
    }
    else {
      if (getAllRiskEmpConversationData && getAllRiskEmpConversationData && getAllRiskEmpConversationData.list.length > 0) {
        initEmpId = getAllRiskEmpConversationData.list[0].id
      }
    }


    if (talking) {
      let data = {
        "conversationId": initEmpId,
        "name": talking,
        "desc": ""
      }
      //////console.log("handleTalkingSubmitssssssssss-->", data)
      handleTalkingSubmit(data, 11)

      this.closeCreateTalkingModal()
    }
  }


  handleMobileEmpDropdown = (check) => {
    this.setState({ showMobileEMPDropdown: check })
  }

  onShowPTMempDrop = (check) => {
    this.setState({ onShowPTMemp: check })
  }
  handleSelectPTMStatus = (value) => {
    this.setState({ selectedPTMStatus: value, crrEmployeeId: "" })
  }

  handleResolvedText = (value) => {
    this.setState({ resolvedText: value })
  }

  render() {
    let { LifecyclePTMEmpQuesOpenEndedData, isOverallPTM, getAllRiskEmpConversationData, OnboardingEmpQuesResponsesData, RiskgetRiskCategoryData, EmpFilterData, getIndexFilterData } = this.props;
    let { crrEmployeeData, onShowPTMemp, crrEmployeeId, showTab, showCatgory, selectedCategory, FilterValuesDMG } = this.state;


    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++
      }
      return count ? true : false
    }
    let { selectedPTMStatus } = this.state;
    let getAllRiskEmpConversationDataList = []
    let getAllRiskEmpConList = []

    if (getAllRiskEmpConversationData && getAllRiskEmpConversationData.list && getAllRiskEmpConversationData.list.length > 0) {
      getAllRiskEmpConList = getAllRiskEmpConversationData.list;
      getAllRiskEmpConversationData.list.forEach((item) => {
        if (selectedPTMStatus === "Resolved") {
          if (item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }
        else if (selectedPTMStatus === "InProgress") {
          if (getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }
        else if (selectedPTMStatus === "Open") {
          if (!getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item)
          }
        }

      })
    }


    console.log('getAllRiskEmpConversationData--->', getAllRiskEmpConversationData)



    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split('.')[0];
        let second = str.split('.')[1];
        let second2 = second ? second.substring(0, 2) : ""
        let final = first + '.' + second2;
        if (second2) {
          return parseFloat(final).toFixed(2)
        } else {
          return no
        }
      } else {
        return 0
      }
    }


    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId
    }
    else {
      if (getAllRiskEmpConList && getAllRiskEmpConList.length > 0) {
        initEmpId = getAllRiskEmpConList[0].id
      }
    }

    function getCategoryPTMByScore(score1) {
      let scorez = score1 && score1 !== '-' ? Number(score1) : '-';
      if (scorez && scorez !== '-') {
        if (scorez < 3.5) {
          return 'Actively Disengaged'
        } else if (3.5 <= scorez && scorez < 4.5) {
          return 'Passive'
        } else if (4.5 <= scorez && scorez < 5.5) {
          return 'Moderately Engaged'
        } else if (5.5 <= scorez) {
          return 'Highly Engaged'
        }
      } else {
        return '-'
      }
    }

    let categoryList = isOverallPTM ? [
      {
        "name": "Actively Disengaged",
        "label": "Actively Disengaged",
        "color": "#b31717"
      },
      {
        "name": "Passive",
        "label": "Passive",
        "color": "#e65757"
      },
      {
        "name": "Moderately Engaged",
        "label": "Moderately Engaged",
        "color": "#f29083"
      },
      {
        "name": "Highly Engaged",
        "label": "Highly Engaged",
        "color": "#edb409"
      }
    ] : [
      {
        "name": "All",
        "label": "Show All",
        "color": "#000000"
      },
      {
        "name": "Extremely_High_Risk",
        "label": "Extremely High Risk",
        "color": "#b31717"
      },
      {
        "name": "High_Risk",
        "label": "High Risk",
        "color": "#e65757"
      },
      {
        "name": "Borderline",
        "label": "Borderline",
        "color": "#f29083"
      },
      {
        "name": "Moderate_Risk",
        "label": "Moderate Risk",
        "color": "#edb409"
      }, {
        "name": "No_Risk",
        "label": "No Risk",
        "color": "#1bbf36"
      }
    ]

    function getEmpDataById(crrId, type) {
      let Data = ""
      if (getAllRiskEmpConList && getAllRiskEmpConList.length > 0) {
        if (type === "name") {
          let getIndex = getAllRiskEmpConList.findIndex(prev => prev.id === crrId);
          if (getIndex !== -1) {
            Data = getAllRiskEmpConList[getIndex].name
          }
        }
        else if (type === "checkdata") {
          let getIndex = getAllRiskEmpConList.findIndex(prev => prev.id === crrId);
          if (getIndex !== -1) {
            Data = true
          }
        }
        else if (type === "UnResolved") {
          let getIndex = getAllRiskEmpConList.findIndex(prev => prev.id === crrId);
          if (getIndex !== -1) {
            Data = getAllRiskEmpConList && getAllRiskEmpConList[getIndex] && getAllRiskEmpConList[getIndex].isHistory ? false : true
          }
        }
        else if (type === "Schedule") {
          let getIndex = getAllRiskEmpConList.findIndex(prev => prev.id === crrId);
          if (getIndex !== -1) {
            let Temp = getAllRiskEmpConList && getAllRiskEmpConList[getIndex] && getAllRiskEmpConList[getIndex][type] && getAllRiskEmpConList[getIndex][type].length > 0 ? getAllRiskEmpConList[getIndex][type] : []
            Data = Temp;
          }
        }

        else if (type === "riskCategory") {
          let getIndex = getAllRiskEmpConList.findIndex(prev => prev.id === crrId);
          if (getIndex !== -1) {
            if (isOverallPTM) {
              let scoreData = getAllRiskEmpConList && getAllRiskEmpConList[getIndex] && getAllRiskEmpConList[getIndex].riskscore ? getAllRiskEmpConList[getIndex].riskscore : 0
              Data = getCategoryPTMByScore(scoreData)
            } else {
              let tempD = getAllRiskEmpConList && getAllRiskEmpConList[getIndex] && getAllRiskEmpConList[getIndex].riskCategory ? getAllRiskEmpConList[getIndex].riskCategory : "";
              if (categoryList && categoryList.length > 0) {
                let getIndex = categoryList.findIndex(prev => prev.name === tempD);
                if (getIndex !== -1) {
                  Data = categoryList[getIndex]["label"]
                }
              }
            }

          }
        }
        else if (type === "riskScore") {
          let getIndex = getAllRiskEmpConList.findIndex(prev => prev.id === crrId);
          if (getIndex !== -1) {
            Data = getAllRiskEmpConList && getAllRiskEmpConList[getIndex] && getAllRiskEmpConList[getIndex].riskscore && Number(getAllRiskEmpConList[getIndex].riskscore) > 0 ? getAllRiskEmpConList[getIndex].riskscore.toFixed(2) : 0
          }
        }
        else if (type === "pulseScore") {
          let getIndex = getAllRiskEmpConList.findIndex(prev => prev.id === crrId);
          if (getIndex !== -1) {
            let DataList = getAllRiskEmpConList && getAllRiskEmpConList[getIndex] && getAllRiskEmpConList[getIndex].pulseScoreList && getAllRiskEmpConList[getIndex].pulseScoreList.length > 0 ?
              getAllRiskEmpConList[getIndex].pulseScoreList : []

            if (DataList && DataList.length > 0) {

              Data = DataList && DataList[(DataList.length - 1)] && DataList[(DataList.length - 1)]["score"] ? DataList[(DataList.length - 1)]["score"] : 0

            }
          }
        }
        else if (type === "pulseScoreList") {
          let getIndex = getAllRiskEmpConList.findIndex(prev => prev.id === crrId);
          if (getIndex !== -1) {
            let DataList = getAllRiskEmpConList && getAllRiskEmpConList[getIndex] && getAllRiskEmpConList[getIndex].pulseScoreList && getAllRiskEmpConList[getIndex].pulseScoreList.length > 0 ?
              getAllRiskEmpConList[getIndex].pulseScoreList : []
            Data = DataList;
          }
        }
        else if (type === "data") {
          let getIndex = getAllRiskEmpConList.findIndex(prev => prev.id === crrId);
          if (getIndex !== -1) {
            Data = getAllRiskEmpConList && getAllRiskEmpConList[getIndex] ? getAllRiskEmpConList[getIndex] : 0
          }
        }

        else if (type === "riskScoreColor") {
          let getIndex = getAllRiskEmpConList.findIndex(prev => prev.id === crrId);
          if (getIndex !== -1) {
            let getRiskScoreX = getAllRiskEmpConList && getAllRiskEmpConList[getIndex] && getAllRiskEmpConList[getIndex].riskscore ? getAllRiskEmpConList[getIndex].riskscore : 0

            let getRiskScore = Number(getRiskScoreX);
            // if (getRiskScore >= 25 && getRiskScore < 50) {
            //   Data = "#b31717";
            // }
            // else if (getRiskScore >= 50 && getRiskScore < 75) {
            //   Data = "#e65757";
            // }
            // else if (getRiskScore >= 75 && getRiskScore < 100) {
            //   Data = "#f29083";
            // }
            // else if (getRiskScore >= 100 && getRiskScore < 125) {
            //   Data = "#edb409";
            // }
            // else if (getRiskScore >= 125 && getRiskScore <= 150) {
            //   Data = "#1bbf36";
            // }
            // else {
            //   Data = "#757575";
            // }

            Data = "#e65757";

          }
        }





        else {
          let getIndex = getAllRiskEmpConList.findIndex(prev => prev.id === crrId);
          if (getIndex !== -1) {
            Data = getAllRiskEmpConList && getAllRiskEmpConList[getIndex] && getAllRiskEmpConList[getIndex][type] && getAllRiskEmpConList[getIndex][type].length > 0 ? getAllRiskEmpConList[getIndex][type] : []
            console.log("getAllRiskEmpConList----.", getAllRiskEmpConList);
            console.log("Data----.", Data);
            console.log("crrId----.", crrId);

          }
        }
      }

      return Data
    }


    //////console.log("this.state.formSchedule----.", this.state.formSchedule);
    //////console.log("this.state.formAction----.", this.state.formAction);






    function getFilterEmp(scoreX, nameX) {
      let isContain = false
      let getRiskScore = Number(scoreX);

      //////console.log("getRiskScore------------>", getRiskScore);
      let name = nameX.toString();
      //////console.log("name------------>", name);

      if (getRiskScore >= 16 && getRiskScore < 100) {
        if (name === "Extremely_High_Risk") {
          isContain = true
        }
      }
      else if (getRiskScore >= 100 && getRiskScore < 110) {
        if (name === "High_Risk") {
          isContain = true
        }
      }
      else if (getRiskScore >= 110 && getRiskScore < 130) {
        if (name === "Borderline") {
          isContain = true
        }
      }
      else if (getRiskScore >= 130 && getRiskScore < 150) {
        if (name === "Moderate_Risk") {
          isContain = true
        }
      }
      else if (getRiskScore >= 150 && getRiskScore <= 180) {
        if (name === "No_Risk") {
          isContain = true
        }
      }
      //////console.log("isContain---->", isContain)

      return isContain
    }



    let FilterAllEmpResponses = [];



    if (getAllRiskEmpConversationData && getAllRiskEmpConversationDataList && getAllRiskEmpConversationDataList.length > 0) {

      if (FilterValuesDMG && FilterValuesDMG.length > 0) {

        getAllRiskEmpConversationDataList.forEach((item) => {

          let isCheck = 0;
          if (FilterValuesDMG && FilterValuesDMG.length > 0) {
            FilterValuesDMG.forEach((filter) => {
              if (filter.value !== "All") {
                let Emp = item;
                if (!(Emp[filter.level] === filter.value)) {
                  isCheck++
                }
              }


            });
          }
          // //console.log('x Emp: ',Emp);
          // //console.log('x isCheck: ',isCheck);

          if (isCheck === 0) {
            FilterAllEmpResponses.push(item)
          }




        });

      }
      else {
        FilterAllEmpResponses = getAllRiskEmpConversationDataList;
      }



    }





    let empList = []
    if (selectedCategory && selectedCategory.name === "All") {
      if (FilterAllEmpResponses && FilterAllEmpResponses.length > 0) {
        empList = FilterAllEmpResponses;
      }
    }
    else {

      if (FilterAllEmpResponses && FilterAllEmpResponses.length > 0) {
        empList = FilterAllEmpResponses.filter(prev => getFilterEmp(prev.riskscore, selectedCategory.name))


      }

    }

    //console.log('getAllRiskEmpConversationData1------------->', getAllRiskEmpConversationData)
    //console.log('FilterAllEmpResponses1------------->', FilterAllEmpResponses)
    //console.log('empList1------------->', empList)

    const isMobile = window.innerWidth <= 700;

    let newEmpList = empList;

    // function getInProgress (itemX){
    //   let count = 0;
    //   if(itemX && itemX.Schedule && itemX.Schedule.length>0){
    //     count++
    //   }
    //   if(itemX && itemX.actionList && itemX.actionList.length>0){
    //     count++
    //   }
    //   if(itemX && itemX.noteList && itemX.noteList.length>0){
    //     count++
    //   }
    //   if(itemX && itemX.talkList && itemX.talkList.length>0){
    //     count++
    //   }
    //   return count?true:false
    // }

    // let newEmpList = []
    // if(empList && empList.length>0){
    //   empList.forEach((item)=>{
    //     if(selectedPTMStatus === "Resolved"){
    //       if(item.isHistory){
    //         newEmpList.push(item)
    //       }
    //     }
    //     else if(selectedPTMStatus === "InProgress"){
    //       if(getInProgress(item) && !item.isHistory){
    //         newEmpList.push(item)
    //       }
    //     }
    //     else if(selectedPTMStatus === "Open"){
    //       if(!getInProgress(item) && !item.isHistory){
    //         newEmpList.push(item)
    //       }
    //     }

    //   })
    // }


    let PTMTypeList = ["Open", "InProgress", "Resolved"]



    let EmpFilterDataV2 = EmpFilterData && EmpFilterData.length > 0 ? EmpFilterData.map((ele) => (ele.name)) : []


    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split('.')[0];
        let second = str.split('.')[1];
        let second2 = second ? second.substring(0, 2) : ""
        let final = first + '.' + second2;
        if (second2) {
          return parseFloat(final).toFixed(2)
        } else {
          return no
        }
      } else {
        return 0
      }
    }




    let ScoreExcel = []
    if (getAllRiskEmpConversationDataList && getAllRiskEmpConversationDataList.length > 0) {
      getAllRiskEmpConversationDataList.forEach((item) => {


        let tempData = {
          "name": item["name"],
          "emp_id": item["emp_id"],
          "mobile": item["mobile"],
          "riskCategory": item["riskCategory"],
          "riskscore": spliceDecimal(item && item["riskscore"] ? item["riskscore"] : 0),
        }

        let keyList = Object.keys(item);
        if (keyList && keyList.length > 0) {
          keyList.forEach((key) => {
            if (EmpFilterDataV2.includes(key)) {
              tempData[key] = item[key]
            }
          })
        }
        console.log("tempData--->", tempData)
        ScoreExcel.push(tempData)
      })
    }

    console.log('ScoreExcel--->', ScoreExcel)

    return (
      <>

        <div className="space-y-4 flex-1 md:p-6 p-4 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto h-[calc(100vh-7rem)] ">

          <div className='flex items-center justify-between'>
            <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">People To Meet Cases</h1>
            <span class="material-symbols-outlined cursor-pointer lg:hidden">menu_open</span>
          </div>

          <div className='flex justify-between '>

            <div className='flex gap-2 '>

              <Demographics
                showFilter={this.state.showFilterDMG}
                showValue={this.state.showValueDMG}
                closeFilter={this.closeFilterDMG}

                EmpFilterData2={EmpFilterData}
                getIndexFilterData={getIndexFilterData}
                handleMultifilterIndexScore={this.handleMultifilterIndexScore2DMG}
              />

            </div>


            {/* <div>
              {ScoreExcel ?
                <CSVLink data={ScoreExcel} filename={"PeopleToMeetCases.csv"}>
                  <p className="flex items-center p-2 px-4 text-sm text-green-500 bg-green-100 rounded-full cursor-pointer ">
                    {"Download Excel"}
                  </p>
                </CSVLink>
                : '...'}
            </div> */}



            <>
              {ScoreExcel ? (
                <CSVLink
                  data={ScoreExcel}
                  filename={"PeopleToMeetCases.csv"}
                >
                  <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                      <path d="M10 12l4 4m0 -4l-4 4" />
                    </svg>

                    <span>Download Excel</span>
                  </button>
                </CSVLink>
              ) : (
                "..."
              )}
            </>




            {/* <h1 className="text-lg font-medium">People To Meet </h1> */}

            {/* {!isOverallPTM?
          <div className="cursor-pointer bg-white flex items-center text-xs font-medium relative border p-1 px-4 rounded-full space-x-1.5 ">

          <p onClick={() => this.onShowCatgory(!showCatgory)} className="px-2 text-gray-500">{selectedCategory && selectedCategory.name && (selectedCategory.name !== "All") ? selectedCategory.label : 'Select Category'}</p>
          <span onClick={() => this.onShowCatgory(!showCatgory)} className="text-gray-500 material-symbols-outlined">arrow_drop_down</span>

          {showCatgory ?
            <div className="absolute right-0 z-10 w-full p-3 overflow-x-auto font-normal bg-white rounded-md shadow-lg top-10 font-xs h-60 customscroll2">
              {categoryList && categoryList.length > 0 ?
                categoryList.map((category, index2) =>
                  <div key={index2} className="flex items-center py-2 m-1 cursor-pointer" onClick={() => this.handleSelectEmpCategory(category)}>
                    <input type="checkbox" checked={selectedCategory.name === category.name} onChange={() => { }} /><label style={{ color: category.color }} className="ml-2 font-medium">{category.label}</label>
                  </div>
                ) : null}
            </div>
            : null}
        </div>
          :null} */}


          </div>


          <div className="w-full space-y-4 xl:flex xl:space-x-4 xl:space-y-0 " >

            <div className="relative w-full h-auto bg-white border divide-y xl:w-72">
              <div className='items-center justify-between w-full p-4 cursor-pointer '>
                <div>

                  <div className='flex items-center justify-between' >
                    <h1 className="font-medium xl:text-xl pb-4 text-lg text-[#3D405B] whitespace-nowrap">People To Meet case </h1>

                    <span onClick={() => this.handleMobileEmpDropdown(!this.state.showMobileEMPDropdown)} className="block material-symbols-outlined lg:hidden">
                      expand_more
                    </span>
                  </div>
                </div>

                <div className='flex justify-between p-2 mt-4 border rounded-md' >
                  <p onClick={() => this.onShowPTMempDrop(!onShowPTMemp)} className='w-full text-sm capitalize' >{selectedPTMStatus}<b className='font-semibold ' > ({newEmpList && newEmpList.length > 0 ? newEmpList.length : 0})</b></p>
                  <span onClick={() => this.onShowPTMempDrop(!onShowPTMemp)} className="material-symbols-outlined">arrow_drop_down</span>

                  {onShowPTMemp ?
                    <div className="absolute right-0 w-full p-3 overflow-x-auto font-normal bg-white rounded-md shadow-lg z-24 top-24 font-xs h-60 customscroll2">
                      {PTMTypeList && PTMTypeList.length > 0 ?
                        PTMTypeList.map((item, index2) =>
                          <div key={index2} className="flex items-center py-2 m-1 cursor-pointer" onClick={() => { this.handleSelectPTMStatus(item); this.onShowPTMempDrop(false) }}>
                            <input type="checkbox" checked={selectedPTMStatus === item} onChange={() => { }} /><label className="ml-2 font-medium cursor-pointer">{item}</label>
                          </div>
                        ) : null}
                    </div>
                    : null}


                </div>

                {this.state.showMobileEMPDropdown && isMobile ?
                  <>
                    <div className="w-full space-y-4 overflow-y-auto bg-white border rounded-md lang " style={{ height: 'calc(100vh - 10rem)' }}  >
                      {!onShowPTMemp ?
                        <>
                          {newEmpList && newEmpList.length > 0 ?
                            newEmpList.map((item, index) =>
                              <div onClick={() => this.selectEmp(item.id)} key={index} className='flex px-4'>
                                <span className="text-gray-300 material-symbols-outlined" style={{ fontSize: '3rem' }}>account_circle </span>
                                <p className='pt-3 pl-2'>{item.name}</p>
                              </div>
                            ) : null}
                        </>
                        : null}


                    </div>
                  </>
                  : null}


              </div>

              <div className={this.state.active ?
                " lg:border-none border bg-white lg:shadow-none shadow-lg  customscroll4  overflow-y-auto lg:block lg:relative  absolute lg:top-0 top-16 lg:right-0 right-0 lg:w-auto w-full z-0" :
                'lg:border-none border bg-white lg:shadow-none shadow-lg  customscroll4  overflow-y-auto lg:block  hidden lg:relative  absolute lg:top-0 top-16 lg:right-0 right-0 lg:w-auto w-full z-0'} >

                {!onShowPTMemp ?
                  <div className="w-full pb-12 space-y-4 overflow-y-auto bg-white rounded-md lang" style={{ height: '350px' }}  >
                    {newEmpList && newEmpList.length > 0 ?
                      newEmpList.map((item) =>
                        <>


                          <div onClick={() => this.selectEmp(item.id)} className={initEmpId === item.id ?
                            "flex justify-between cursor-pointer items-center space-x-2 capitalize p-2 bg-blue-100 w-full"
                            :
                            "flex justify-between cursor-pointer items-center space-x-2 capitalize p-2 hover:bg-blue-100 w-full"}>

                            <div className='flex '>
                              <span className="text-gray-300 material-symbols-outlined" style={{ fontSize: '3rem' }}>account_circle </span>
                              <p className='pt-3 pl-2'>{item.name}</p>
                            </div>


                            <div className='flex'>
                              {/* <span className='px-2 font-medium '>Score: {item.riskscore}</span> */}
                              {/* <span onClick={()=>this.handleDetailsPopUp(item)} className='font-bold text-white bg-blue-400 rounded-md material-symbols-outlined hover:bg-blue-500'>add</span> */}
                            </div>

                          </div>


                        </>
                      ) : null}
                  </div>
                  : null}



              </div>


            </div>


            {getEmpDataById(initEmpId, "checkdata") ?
              <>
                <div className="w-full h-full space-y-4 overflow-hidden bg-white border xl:flex-1 ">
                  <div className='sticky top-0 space-y-4 bg-white '>
                    <div className="grid grid-cols-1 gap-6 p-4 capitalize border-b md:grid-cols-3 md:gap-0 md:divide-x ">
                      <div className='flex items-center space-x-2 xl:flex md:block'>

                        <span className="text-gray-200 material-symbols-outlined " style={{ fontSize: '6rem' }}>account_circle </span>
                        <div className='space-y-2'>
                          <p className="text-xl font-normal">{getEmpDataById(initEmpId, "name")}</p>



                          <div className='flex items-center space-x-2'>
                            {getEmpDataById(initEmpId, "UnResolved") ?
                              <button onClick={() => this.onResolveModal(getEmpDataById(initEmpId, "data"))} className='flex items-center px-4 py-2 text-sm text-white capitalize bg-blue-500 rounded-full cursor-pointer whitespace-nowrap xl:text-base '>
                                Move to Resolved
                                <span className="ml-2 material-symbols-outlined">
                                  moving
                                </span>
                              </button>
                              :
                              <button className='flex items-center px-4 py-2 text-sm text-white capitalize bg-green-500 rounded-full cursor-pointer whitespace-nowrap xl:text-base '>
                                Resolved
                                <span className="ml-2 material-symbols-outlined">
                                  check
                                </span>
                              </button>
                            }


                          </div>
                        </div>
                      </div>

                      <div className='flex items-center justify-start space-x-2 md:justify-center '>
                        <div className='space-y-2'>
                          <p className="text-xl font-normal">{isOverallPTM ? "Engagement Score" : "Response Score"}</p>
                          <div className='items-center xl:flex xl:space-x-2'>
                            <p className="text-lg font-semibold" >   {spliceDecimal(getEmpDataById(initEmpId, "riskScore"))}</p>
                            <button className="px-4 py-1 text-sm font-medium border border-red-400 rounded-full cursor-pointer " style={{ color: getEmpDataById(initEmpId, "riskScoreColor") }}>
                              {getEmpDataById(initEmpId, "riskCategory")}</button>
                          </div>   </div>
                      </div>

                      <div className='flex items-center justify-start space-x-2 md:justify-center '>
                        <div className='space-y-2'>
                          <p className="text-xl font-normal">Risk Profile</p>
                          <div className='items-center xl:flex xl:space-x-2'>
                            {/* <p className="text-lg font-semibold" >   {getEmpDataById(initEmpId, "pulseScore")}</p> */}
                            <button onClick={() => this.handleDetailsPopUp(getEmpDataById(initEmpId, "data"))} className="px-4 py-1 text-sm font-medium text-blue-500 border border-blue-500 rounded-full cursor-pointer "  >
                              view risk profile</button>
                          </div>   </div>
                      </div>


                      {/* <button onClick={() => this.handleDetailsPopUp(getEmpDataById(initEmpId, "data"))} className="flex items-center px-4 py-2 mx-auto text-sm font-medium text-blue-500 capitalize border border-blue-500 rounded-full md:mx-0">
                    View in Details</button> */}

                    </div>
                    <div className="flex items-center justify-between w-full px-4">
                      <h1 className="text-lg font-medium">Timeline </h1>
                      <button onClick={() => this.openScheduleModal(!this.state.showScheduleModal)} className="flex items-center px-6 py-2 text-sm font-medium text-white capitalize bg-blue-500 rounded-full">
                        <span className="mr-2 material-symbols-outlined">
                          schedule
                        </span> schedule</button>
                    </div>
                    <div className="flex w-full px-4 overflow-x-auto whitespace-nowrap">
                      <div className="flex space-x-4">

                        {getEmpDataById(initEmpId, "Schedule") && getEmpDataById(initEmpId, "Schedule").length > 0 ?
                          getEmpDataById(initEmpId, "Schedule").map((item) =>
                            <>
                              <div className="overflow-hidden border rounded ">
                                {item && item.isComplete ?
                                  <div className="flex items-center p-4 space-x-4 bg-white border-b-4 border-blue-500 ">
                                    <span className={"material-symbols-outlined text-green-500"}>{"check_circle"}</span>
                                    <div>
                                      <p className="text-sm font-medium">{item && item.date ? moment(item.date, "YYYY-MM-DD").format("DD MMM") + (item && item.time ? ", " + item.time : "") : "-"}</p>
                                      <p className="text-xs text-gray-500">Completed</p>


                                    </div>
                                  </div>
                                  :
                                  <div onClick={() => this.openScheduleCompletedModal(item)} className="flex items-center p-4 space-x-4 bg-white cursor-pointer ">
                                    <span className={"material-symbols-outlined text-blue-500"}>{"timer"}</span>
                                    <div>
                                      <p className="text-sm font-medium">{item && item.date ? moment(item.date, "YYYY-MM-DD").format("DD MMM") + (item && item.time ? ", " + item.time : "") : "-"}</p>
                                      <p className="text-xs text-gray-500">scheduled</p>


                                    </div>
                                  </div>
                                }
                              </div>
                            </>
                          ) : null}




                      </div>
                    </div>
                    {/* here is resorse nav */}
                    <nav className="static flex px-4 overflow-x-auto scroll justify-left overscroll-x-contain">
                      <div className="flex w-full capitalize border-b justify-left whitespace-nowrap ">

                        <span onClick={() => this.handleTab("talking")} className={showTab === "talking" ?
                          "cursor-pointer text-blue-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 px-4 mr-8"
                          :
                          "cursor-pointer text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 px-4 mr-8"
                        } href=" ">
                          Discussion Points
                        </span>
                        <span onClick={() => this.handleTab("action")} className={showTab === "action" ?
                          "cursor-pointer text-blue-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 px-4 mr-8"
                          :
                          "cursor-pointer text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 px-4 mr-8"
                        } href=" ">
                          Action items
                        </span>
                        <span onClick={() => this.handleTab("notes")} className={showTab === "notes" ?
                          "cursor-pointer text-blue-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 px-4 mr-8"
                          :
                          "cursor-pointer text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 px-4 mr-8"
                        } href=" ">
                          Notes
                        </span>
                      </div>
                    </nav>

                  </div>


                  {showTab === "talking" ?
                    <div className="w-full px-4 space-y-6 overflow-y-auto customscroll4 h-96 " >
                      <div className="flex items-center justify-between">
                        <h1 className="text-lg font-medium capitalize">
                          Discussion points
                        </h1>
                        <button onClick={() => this.onCreateTalking()} className="flex items-center px-4 py-2 text-sm font-medium text-blue-500 capitalize border border-blue-500 rounded-full">
                          <span className="material-symbols-outlined ">
                            add
                          </span> Add points</button>
                      </div>

                      {getEmpDataById(initEmpId, "talkList") && getEmpDataById(initEmpId, "talkList").length > 0 ?
                        getEmpDataById(initEmpId, "talkList").map((item, index) =>
                          <>
                            <div>
                              <div className='flex '>
                                <h1 className="p-2 px-6 font-medium xl:text-xl pb-4 text-lg text-[#3D405B] whitespace-nowrap border rounded">{item.text}</h1>

                              </div>


                              <p className="text-sm text-gray-500">{item.reply}</p>
                            </div>
                            {this.state.showTalkingField && (index === (getEmpDataById(initEmpId, "talkList").length - 1)) ?
                              <div className='flex'>
                                <input type="text" name="talking" placeholder="Type Here..." value={this.state.talking} onChange={(e) => this.handleInputTalking(e)} className="flex justify-between w-full p-2 text-blue-500 border rounded-lg cursor-pointer" />
                                <button onClick={() => this.SumbitTalking()} className='px-2 ml-2 text-white bg-blue-500 rounded-md cursor-pointer material-symbols-outlined'>arrow_forward</button>
                              </div>
                              :
                              null}

                          </>) : null}


                    </div>
                    : null}



                  {showTab === "action" ?


                    <>

                      <div className="px-4 space-y-6 overflow-y-auto customscroll4 h-96">
                        <div className="flex items-center justify-between">
                          <h1 className="text-lg font-medium capitalize">
                            Action items</h1>
                          <button onClick={() => this.onCreateAction(initEmpId)} className="flex items-center px-4 py-2 text-sm font-medium text-blue-500 capitalize border border-blue-500 rounded-full">
                            <span className="mr-2 material-symbols-outlined">
                              add
                            </span> Add action</button>
                        </div>
                        <div className="grid gap-6 2xl:grid-cols-6 xl:grid-cols-4 md:grid-cols-3">

                          {getEmpDataById(initEmpId, "actionList") && getEmpDataById(initEmpId, "actionList").length > 0 ?
                            getEmpDataById(initEmpId, "actionList").map((item, index) =>
                              <>
                                <div onClick={() => this.openActionCompletedModal(item)} className="p-4 space-y-4 bg-white border rounded-lg">
                                  <h2>{item.name}</h2>
                                  <p className="text-sm text-gray-500">{item.desc}</p>
                                  {item && item.isComplete ?
                                    <button className="px-4 py-1 text-xs font-medium text-white uppercase bg-green-500 rounded-full">completed</button>
                                    :
                                    <button className="px-4 py-1 text-xs font-medium text-white uppercase bg-yellow-500 rounded-full">pending</button>
                                  }

                                </div>
                              </>)
                            : null}

                        </div>
                      </div>

                    </>
                    : null}



                  {showTab === "notes" ?
                    <>

                      <div className="px-4 space-y-6 overflow-y-auto customscroll4 h-96">
                        <div className="flex items-center justify-between">
                          <h1 className="text-lg font-medium capitalize">
                            Notes</h1>
                          <button onClick={() => this.onCreateNote(initEmpId)} className="flex items-center px-4 py-2 text-sm font-medium text-blue-500 capitalize border border-blue-500 rounded-full">
                            <span className="mr-2 material-symbols-outlined">
                              add
                            </span> Add notes</button>
                        </div>
                        <div className="grid gap-6 2xl:grid-cols-6 xl:grid-cols-4 md:grid-cols-3">

                          {getEmpDataById(initEmpId, "noteList") && getEmpDataById(initEmpId, "noteList").length > 0 ?
                            getEmpDataById(initEmpId, "noteList").map((item, index) =>
                              <>
                                <div className="p-4 space-y-6 bg-blue-100 border rounded-lg ">
                                  <h2 className="text-sm text-gray-800">{item.desc}</h2>
                                  <p className="text-xs font-medium rounded-full ">{moment.unix(item.createdAt / 1000).format("DD MMM")}</p>
                                </div>
                              </>) : null}


                        </div>
                      </div>




                    </>
                    : null}






                </div>
              </>
              :
              <>
                <div className="w-full h-full space-y-4 overflow-hidden bg-white border xl:flex-1">
                  <div className='flex items-center justify-center h-screen' >
                    <div className='text-3xl font-bold text-center text-gray-500'>Select People To Meet Case </div>
                  </div>
                </div>
              </>
            }





          </div>
        </div>




        {/* Modal */}


        <ScheduleModal
          showModal={this.state.showScheduleModal}
          onCloseModal={this.onCloseScheduleModal}
          formSchedule={this.state.formSchedule}
          handleInputField={this.handleInputField}
          handleSubmit={this.handleSubmit}
        />

        <ScheduleComplete
          showModal={this.state.showScheduleCompleteModal}
          onCloseModal={this.onCloseCompleteScheduleModal}
          handleSubmit={this.handleCompleteScheduleSubmit}
        />



        <CreateActionModal
          showModal={this.state.showCreateActionModal}
          onCloseModal={this.closeCreateActionModal}
          formAction={this.state.formAction}
          handleInputField={this.handleInputActionField}
          handleSubmit={this.handleCreateActionSubmit}
        />

        <ActionComplete
          showModal={this.state.showActionCompleteModal}
          onCloseModal={this.onCloseCompleteActionModal}
          handleSubmit={this.handleCompleteActionSubmit}
        />

        <CreateNoteModal
          showModal={this.state.showCreateNoteModal}
          onCloseModal={this.closeCreateNoteModal}
          formNotes={this.state.formNotes}
          handleInputField={this.handleInputNoteField}
          handleSubmit={this.handleCreateNoteSubmit}
        />

        <ResolveModal
          showModal={this.state.showResolveModal}
          closeResolveModal={this.closeResolveModal}
          crrEmployeeData={crrEmployeeData}
          onSubmitResolve={this.onSubmitResolve}
          handleResolvedText={this.handleResolvedText}
          resolvedText={this.state.resolvedText}
        />

        {/* <PluseScoreModal
          showModal={this.state.showRiskProfileModal}
          closeResolveModal={this.closePluseModal}
          crrPulseScoreList={this.state.crrPulseScoreList}
        /> */}



        <CreateTalkingModal
          showModal={this.state.showCreateTalkingModal}
          onCloseModal={this.closeCreateTalkingModal}
          talking={this.state.talking}
          handleInputField={this.handleInputTalkingField}
          handleSubmit={this.handleCreateTalkingSubmit}
        />

        <EmployeeRiskProfile
          showModal={this.state.showEmpPopUp}
          closeModal={this.closeEmpPopUpModal}
          OnboardingEmpQuesResponsesData={OnboardingEmpQuesResponsesData}
          RiskgetRiskCategoryData={RiskgetRiskCategoryData}
          isOverallPTM={isOverallPTM}
          riskScore={getEmpDataById(initEmpId, "riskScore")}
          riskCategory={getEmpDataById(initEmpId, "riskCategory")}
          LifecyclePTMEmpQuesOpenEndedData={LifecyclePTMEmpQuesOpenEndedData}
          scoreType={isOverallPTM ? 1 : 2}
          emp_id={getEmpDataById(initEmpId, "emp_id")}
          name={getEmpDataById(initEmpId, "name")}
        />


      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(OneToOneConversation);
