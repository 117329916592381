import React, { Component } from 'react';
import { connect } from 'react-redux';
import { riskdashtype2Actions, campaignActions, dashboardActions, surveyActions } from '../../../_actions';
import { accessModule } from '../../../_helpers';
import moment from 'moment';

import CreateCampaignPulse from './Modal/CreateCampaignPulse';
import CampaignList from './Components/CampaignList';
import Header from './Header';
// import { Checkboard } from 'react-color';
import ReminderCampaign from './Components/ReminderCampaign/ReminderCampaign';
import ConfirmSubmit from './Components/ReminderCampaign/ConfirmSubmit';
import ConfirmCreateCampaign from './Modal/ConfirmCreateCampaign';

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateCampaign: false,
      showReminderModal: false,
      CurrCampaignID: '',
      showReminderEmployeeFilter: false,
      empSelectRemindFilter: '',
      reminderSubmitConfirm: false,
      confirmCreateCampaignAlert: false,

      showCampaignOptions: false,

      campaignValidationList: [],

      campaignFormData: {
        "title": "",
        "subject": "",
        "type": "EMAIL",
        "message": "",
        "emailName": "",
        "campaign_type": "NORMAL",
        "teamname": "",
        "surveyId": this.props.match.params.id,
        "employees": [],
        "isTest": false
      },
      reminderformData: {
        "action": 'Incomplete Survey',
        "actionId": 2,
        "title": '',
        "subject": '',
        "emailName": "",
        "message": '',
        "teamname": "",
        "createdAt": ""
      },

      formBatchRange: {
        "label": "1-1000",
        "rangeNo": 1,
        "size": 1000
      },

      storeBatchData: {
        "label": "1-1000",
        "rangeNo": 1,
        "size": 1000
      },

      showBatchRange: false,

      selectAll: false,
      employees: [],

      EmpIndexA: 0,
      EmpIndexB: 6,

      currentEmpPage: 1,

      selectAllEmp: false,

      showEmployeeFilter: false,

      empSelectFilter: {
        name: 'Show All',
        template: 1
      },

      showSidebar: false,
      CrrRoute: "survey",
      crrSurvey: "",

      CurrentLanguage: 'en',
      CurrentLanguageName: 'English',
      showLanguageModal: false,

      currFilterLevel: "Select Filter", showLevelFilter: false,
      currFilterValue: "Select Value", showValueFilter: false,

      employeeValueSearch: "",

      recursiveSettings: {
        "date": "",
        "frequency": 15,
        "isLaunchNow": true,
        "recursiveType": "RECURSIVE_NORMAL"
      },


      crrLaunchType: {
        "id": "A",
        "name": "Existing Survey"
      },

      crrSurveyCategory: {
        "id": null,
        "name": "Select Category"
      },
      crrSurveyData: {
        "id": null,
        "name": "Select Survey"
      },

      clusterCrrName: "",


      crrSenseSurvey: {},


    }
  }
  componentDidMount() {

    this.props.dispatch(dashboardActions.getCompanyNameOnly());

    this.props.dispatch(dashboardActions.getClientProfile());

    let data = {
      "surveyId": this.props.match.params.id
    }
    this.props.dispatch(surveyActions.getSurveyById(data));

    let data1 = {
      "surveyId": this.props.match.params.id,
    }
    this.props.dispatch(campaignActions.getCampaignListBySurveyID(data1));


    // this.props.dispatch(campaignActions.getAllEmployees(data2));




    let data3 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(surveyActions.getLanguageLabel(data3));
    this.props.dispatch(surveyActions.getLanguage(data3));













    this.props.dispatch(dashboardActions.getEmpFilter());
    this.props.dispatch(surveyActions.getIndexByFilterDMG({
      filters: []
    }));



    this.props.dispatch(surveyActions.allLifecycleListenList());






    let dataX2 = {
      "keyWord": "",
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "pageNo": 1,
      "size": 10000,
      "surveyModule": 2 //sense survey
    }
    this.props.dispatch(surveyActions.getSurveyList(dataX2));

  }

  handleEmpDropdown = (check) => {
    this.setState({ showEmployeeFilter: !check })
  }

  handleEmpFilterSelect = (data) => {
    this.setState({
      empSelectFilter: data,
      showEmployeeFilter: false
    })
  }

  prevEmpPage = () => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (EmpIndexA > 0) {
      this.setState({ EmpIndexA: EmpIndexA - 1, EmpIndexB: EmpIndexB - 1 });
    }
  }
  nextEmpPage = (IndexListIndex) => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (IndexListIndex && IndexListIndex.length && (EmpIndexB < IndexListIndex.length)) {
      this.setState({ EmpIndexA: EmpIndexA + 1, EmpIndexB: EmpIndexB + 1 });
    }
  }

  SelectAllEmployees = (check, EmployeesList) => {
    this.setState({ selectAllEmp: !check })

    let { employees, campaignFormData } = this.state;

    this.setState({ employees }, () => {
      if (EmployeesList && EmployeesList.length > 0) {
        if (!check) {
          EmployeesList.forEach((emp) => {
            if (employees.findIndex(prev => prev.id === emp.id) === -1) {
              employees.push({
                id: emp.id
              })
              this.setState({ employees }, () => {
                campaignFormData['employees'] = employees;
                this.setState({ campaignFormData })
              })
            }
          })
        }
        else {
          employees = []
          campaignFormData['employees'] = []
          this.setState({ employees, campaignFormData })
        }
      }
    })

  }

  SelectEmpPage = (val) => {
    this.setState({ currentEmpPage: val })
  }


  onCreateCampaign = (type) => {
    if (type === 1) {
      this.setState({
        showCreateCampaign: true,
        showReminderModal: false
      })
    }
    else if (2) {
      this.setState({
        showCreateCampaign: false,
        showReminderModal: false
      })
    }

  }

  handleCampaignInput = (e) => {
    let { campaignFormData } = this.state;

    let value = e.target.value;
    var newValue = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');

    campaignFormData[e.target.name] = newValue;
    this.setState({ campaignFormData })
  }

  handleMessageInput = (value) => {
    let { campaignFormData } = this.state;

    var newValue = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');

    campaignFormData['message'] = newValue;
    this.setState({ campaignFormData });
  }

  handleTestUserCheck = (value) => {
    let { campaignFormData } = this.state;
    campaignFormData['isTest'] = value;
    this.setState({ campaignFormData });
  }

  handleSendTypeCheck = (value) => {
    const { campaignFormData } = this.state;
    campaignFormData["type"] = value;
    this.setState({ campaignFormData });
  }

  handleCampaignEmployees = (emp) => {
    const { campaignFormData, employees, selectAll } = this.state;


    if (!selectAll) {



      if (employees.length > 0) {
        var matchFlag = null;
        employees.forEach((selectedEmp, empIndex) => {

          if (emp.id === selectedEmp.id) {
            matchFlag = 1
            var data = employees;
            data.splice(empIndex, 1);

            campaignFormData['employees'] = data;
            this.setState({ campaignFormData });

          }

        });

        if (matchFlag === 1) {
          //////////console.log("matchFlag = 1:---->", employees)
        }
        else {
          var data = employees;
          data.push({ id: emp.id })


          campaignFormData['employees'] = data;
          this.setState({ campaignFormData });

          //////////console.log("matchFlag = 0:---->", employees)
        }



      }
      else {

        var datax = employees;

        datax.push({ id: emp.id })

        //////////console.log("setZero:---->", employees)
        campaignFormData['employees'] = datax;
        this.setState({ campaignFormData });
      }


    }


  }

  handleCampaignSubmit = () => {
    let { campaignFormData } = this.state;
    let data = {
      "title": campaignFormData.title,
      "subject": campaignFormData.subject,
      "message": campaignFormData.message,
      "type": campaignFormData.type,
      "emailName": campaignFormData.emailName,
      "campaign_type": campaignFormData && campaignFormData.campaign_type ? campaignFormData.campaign_type : null,
      "teamname": campaignFormData.teamname,
      "surveyId": campaignFormData.surveyId,
      "employees": campaignFormData.employees,
      "isTest": (campaignFormData && campaignFormData.isTest) ? true : false
    }

    // if(campaignFormData.campaign_type==="RECURSIVE"){
    //   data["recursiveDate"] = recursiveSettings && recursiveSettings.date?recursiveSettings.date:"";
    //   data["recursiveFrequency"] = recursiveSettings && recursiveSettings.frequency?recursiveSettings.frequency:0;
    //   data["recursiveIsLaunch"] = recursiveSettings && recursiveSettings.isLaunchNow?true:false;
    // }


    let validateList = [];
    let fieldNames = Object.keys(data);
    if (fieldNames && fieldNames.length > 0) {
      fieldNames.forEach((item, index) => {
        //////////console.log("campaignFormData--->",campaignFormData)

        if (item === "employees") {

          if (campaignFormData.campaign_type === "NORMAL") {
            if (!(campaignFormData && campaignFormData.employees && campaignFormData.employees.length > 0)) {
              validateList.push('Select Minimum 1 Employee');
            }
          }

        }
        else {
          if (item !== "isTest") {

            if (!(data && data[item])) {

              if (item === "title") {
                validateList.push('Title');
              }
              else if (item === "campaign_type") {
                validateList.push("Campaign Type");
              }

              if (campaignFormData.type === "EMAIL") {

                if (item === "subject") {
                  validateList.push("Subject");
                }
                else if (item === "message") {
                  validateList.push("Message");
                }
                else if (item === "emailName") {
                  validateList.push("Email Name");
                }
                else if (item === "teamname") {
                  validateList.push("Team Name");
                }

              }


            }







          }
        }






      });
    }

    if (validateList && validateList.length > 0) {
      this.setState({ campaignValidationList: validateList });
    }
    else {
      this.setState({ confirmCreateCampaignAlert: true }, () => {
        this.setState({ campaignValidationList: [] });
      });

    }




  }

  handleConfirmCreateCampaignMailClose = () => {
    this.setState({ confirmCreateCampaignAlert: false });
    // let data = {
    //   "title": "",
    //   "subject": "",
    //   "message": "",
    //   "type":"EMAIL",
    //   "teamname": "",
    //   "emailName":"",
    //   "campaign_type":"",
    //   "surveyId": this.props.match.params.id,
    //   "employees": []
    // }
    // this.setState({campaignFormData:data})
  }


  onCampaignSubmit = () => {
    let { campaignFormData, recursiveSettings } = this.state;



    function scriptRemover(FormData, item) {
      let withJSdata = "";
      if (FormData && FormData[item]) {
        withJSdata = FormData[item].replace(/<script.*>.*<\/script>/ims, " ");
      }
      return withJSdata
    }

    let data = {
      "title": scriptRemover(campaignFormData, "title"),
      "subject": scriptRemover(campaignFormData, "subject"),
      "message": scriptRemover(campaignFormData, "message"),
      "type": campaignFormData.type && campaignFormData.type !== "NONE" ? campaignFormData.type : null,
      "emailName": scriptRemover(campaignFormData, "emailName"),
      "campaign_type": campaignFormData && campaignFormData.campaign_type ? campaignFormData.campaign_type : null,
      "teamname": scriptRemover(campaignFormData, "teamname"),
      "surveyId": campaignFormData.surveyId,
      "employees": campaignFormData.employees,
      "isTest": (campaignFormData && campaignFormData.isTest) ? true : false
    }

    if (campaignFormData.campaign_type === "RECURSIVE") {
      data["recursiveStartDate"] = recursiveSettings && recursiveSettings.date ? recursiveSettings.date : null; //2022-03-17 YYYY-MM-DD 
      data["recursiveFrequency"] = recursiveSettings && recursiveSettings.frequency ? recursiveSettings.frequency : 0;
      data["recursiveIsLaunchNow"] = recursiveSettings && recursiveSettings.isLaunchNow ? true : false;
      data["recursiveType"] = recursiveSettings && recursiveSettings.recursiveType ? recursiveSettings.recursiveType : "RECURSIVE_NORMAL";
    }



    //////////console.log("---------------------createNewCampaign---------------------->",data)

    this.props.dispatch(campaignActions.createNewCampaign(data))




    this.setState({ confirmCreateCampaignAlert: false, showCreateCampaign: false, showReminderModal: false })
    let data2 = {
      "title": "",
      "subject": "",
      "message": "",
      "type": "EMAIL",
      "teamname": "",
      "emailName": "",
      "campaign_type": "NORMAL",
      "surveyId": this.props.match.params.id,
      "employees": []
    }
    let data3 = {
      "date": "",
      "frequency": 15,
      "isLaunchNow": true
    }
    this.setState({ campaignFormData: data2, recursiveSettings: data3 })

  }


  onRecDayCampaignLaunch = () => {
    let { survey, campaign, dashboard } = this.props;
    let { getSurveyData } = survey;
    let { campaignList } = campaign;
    let { getCompanyNameOnlyData } = dashboard;

    if (getSurveyData && getSurveyData.name && getSurveyData.isRiskSurveyType === "DEFAULTDAYS" && getSurveyData.value && !(campaignList && campaignList.length > 0)) {
      let days = Number(getSurveyData.value)
      let data = {
        surveyId: getSurveyData && getSurveyData.id ? getSurveyData.id : null,
        campaign_type: "RECURSIVEDAYS",
        recursiveType: "RECURSIVE_EMP_LOGIC",
        type: "EMAIL",

        employees: [],
        isTest: false,

        recursiveFrequency: days,
        recursiveIsLaunchNow: true,
        recursiveStartDate: null,

        title: "New Joining Survey",
        subject: "New Joining Survey",
        emailName: "New Joining Survey",
        message: "<p>Welcome, please take this New Joining Survey</p>",
        teamname: getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : "HappyPlus"
      }
      //////////console.log("recursiveDayinput-------->",data)
      this.props.dispatch(campaignActions.createNewCampaign(data))
    }


  }


  onReportCampaign = (data) => {
    let surveyId = this.props.match.params.id;
    //////////console.log("onReportCampaign-------------->",data)
    if (data && data.campaign_type && data.campaign_type === "KIOSK") {
      this.props.history.push(`/app/campaignkioskreport/${data.id}/${surveyId}`);
    }
    else {
      this.props.history.push(`/app/campaignreport/${data.id}/${surveyId}`);
    }
  }

  onReminderCampaign = (data) => {
    let { reminderformData } = this.state;
    this.setState({ showReminderModal: true })
    reminderformData['title'] = data.title;
    reminderformData['subject'] = "Re: " + data.subject;
    reminderformData['emailName'] = data.emailName;
    reminderformData['message'] = data.message;
    reminderformData['teamname'] = data.teamname;
    reminderformData['createdAt'] = data.createdAt;

    this.setState({ CurrCampaignID: data.id, reminderformData })

    let campaignObj = {
      "surveyId": this.props.match.params.id,
      "campaignId": data.id
    }
    this.props.dispatch(campaignActions.getCampaignEmployeesByID(campaignObj));


  }



  handleReminderInput = (e) => {
    let { reminderformData } = this.state;

    let value = e.target.value;
    var newValue = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');

    reminderformData[e.target.name] = newValue;
    this.setState({ reminderformData })
  }


  handleReminderMessageInput = (value) => {
    let { reminderformData } = this.state;
    var newValue = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');

    reminderformData["message"] = newValue;
    this.setState({ reminderformData })
  }

  handleEmpReminderDropdown = (check) => {
    this.setState({ showReminderEmployeeFilter: !check })
  }

  handleEmpRemindFilterSelect = (value) => {
    //////console.log('handleEmpRemindFilterSelect',value)
    let { reminderformData } = this.state;
    reminderformData['action'] = value.type;
    reminderformData['actionId'] = value.id;
    this.setState({ reminderformData, showReminderEmployeeFilter: false })
  }

  handleReminderSubmit = () => {
    this.setState({ reminderSubmitConfirm: true })

  }

  handleConfirmReminderMailClose = () => {
    this.setState({ reminderSubmitConfirm: false })
  }


  onReminderSubmit = () => {
    let { reminderformData, CurrCampaignID } = this.state;


    function scriptRemover(FormData, item) {
      let withJSdata = "";
      if (FormData && FormData[item]) {
        withJSdata = FormData[item].replace(/<script.*>.*<\/script>/ims, " ");
      }
      return withJSdata
    }


    let data = {
      "campaignId": CurrCampaignID,
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "isMailDeliveryFailed": (reminderformData.actionId === 2) ? false : true,
      "subject": scriptRemover(reminderformData, "subject"),
      "emailName": scriptRemover(reminderformData, "emailName"),
      "message": scriptRemover(reminderformData, "message")
    }

    //////console.log('onReminderSubmit: ',data)
    this.props.dispatch(campaignActions.resendMailInBulk(data));
    this.setState({ reminderSubmitConfirm: false, showReminderModal: false, showCreateCampaign: false })

  }



  handleLanguage = (item) => {
    this.setState({ CurrentLanguage: item.shortName, CurrentLanguageName: item.name, showLanguageModal: false });
  }




  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  }
  routeLink = (link) => {
    this.props.history.push('/app/' + link);
    this.setState({ CrrRoute: link });
  }
  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  }

  handleCampaignOptions = (value) => {
    if (value === this.state.showCampaignOptions) {
      this.setState({ showCampaignOptions: '' });
    } else {
      this.setState({ showCampaignOptions: value });
    }
  }

  chooseCampaignOption = (item, optionType) => {
    if (optionType === 1) {
      this.onReportCampaign(item);
    }
    else if (optionType === 2) {
      this.onReminderCampaign(item);
    }
    this.setState({ showCampaignOptions: '' });
  }



  handleEmpLevelDropdown = (check) => {
    this.setState({ showLevelFilter: !check, showValueFilter: false });
  }

  handleEmpValueDropdown = (check) => {
    this.setState({ showValueFilter: !check, showLevelFilter: false });
  }


  handleLevelSelect = (level) => {

    if (level === "All") {
      this.setState({ currFilterLevel: "Select Filter", currFilterValue: "Select Value", showValueFilter: false, showLevelFilter: false });
    }
    else {
      this.setState({ currFilterLevel: level, currFilterValue: "Select Value", showLevelFilter: false });
    }

  }


  handleValueSelect = (value) => {
    this.setState({ currFilterValue: value, showValueFilter: false });
  }

  selectBatchSize = (item) => {
    let { formBatchRange } = this.state;
    formBatchRange["size"] = item;
    formBatchRange["label"] = "";
    formBatchRange["rangeNo"] = 1;
    this.setState({ formBatchRange });

    // let data5 = {
    //   "rangeNo": 1,
    //   "size":item
    // }
    // this.props.dispatch(surveyActions.getEmployeeBatchRange(data5));

  }

  selectBatchRange = (item) => {
    let { campaignFormData } = this.state;

    this.setState({ storeBatchData: item })

    campaignFormData["employees"] = [];
    this.setState({ selectAllEmp: false, campaignFormData, employees: [] });

    //////console.log('item; ',item.rangeNo);
    let { formBatchRange } = this.state;
    formBatchRange["label"] = item.label;
    formBatchRange["rangeNo"] = item.rangeNo;
    this.setState({ formBatchRange, showBatchRange: false }, () => {
      this.handleGetEmployees();
    });



    // campaignFormData["employees"]=[];
    // this.setState({selectAllEmp:false,campaignFormData,employees:[]});

    // //////console.log('item; ',item.rangeNo);
    // let { formBatchRange } = this.state;
    // formBatchRange["label"] = item.label;
    // formBatchRange["rangeNo"] = item.rangeNo;
    // this.setState({formBatchRange,showBatchRange:false});
    // let data5 = {
    //   "rangeNo": item.rangeNo,
    //   "size":formBatchRange.size
    // }
    // this.props.dispatch(surveyActions.getEmployeeBatchRange(data5));

  }

  handleBatchRangeDropdown = (check) => {
    this.setState({ showBatchRange: check });
  }


  handleCampaignType = (value) => {
    let { campaignFormData } = this.state;
    campaignFormData['campaign_type'] = value;
    this.setState({ campaignFormData });
  }


  handleType = (value) => {
    let { campaignFormData } = this.state;
    campaignFormData['type'] = value;
    this.setState({ campaignFormData });
  }


  handleEmployeeValueSearch = (e) => {
    this.setState({ employeeValueSearch: e.target.value }, () => {


      let data5 = {
        keyWord: this.state.employeeValueSearch,
        pageNo: 1,
        size: 10,
      }
      this.props.dispatch(surveyActions.getEmployeeSearch(data5));



    });

  }



  handleGetEmployees = (item) => {
    let { formBatchRange, currFilterLevel, currFilterValue } = this.state;


    let data5 = {
      "rangeNo": formBatchRange.rangeNo,
      "size": formBatchRange.size,

    }
    if (currFilterLevel !== "Select Filter" && currFilterValue !== "Select Value") {
      // data5["filterName"] = currFilterLevel;
      // data5["filterValue"] = currFilterValue;


      data5["filters"] = [{
        "level": currFilterLevel,
        "value": currFilterValue
      }]


    }




    //////////console.log("FilterName---->",data5)

    this.props.dispatch(surveyActions.getEmployeeBatchRange(data5));

  }


  onGenerateCompanySurveyCode = () => {
    let data5 = {
      "id": this.props.match.params.id
    }

    let temp = {
      "surveyId": this.props.match.params.id
    }

    this.props.dispatch(dashboardActions.generateCompanySurveyCode(data5, temp));
  }


  goBackFn = () => {
    let { showCreateCampaign } = this.state;
    if (showCreateCampaign) {
      this.onCreateCampaign(2);
    }
    else {
      this.props.history.goBack()
    }
  }



  handleRecursiveCampaignInput = (e) => {
    let { recursiveSettings } = this.state;
    recursiveSettings[e.target.name] = e.target.value;
    this.setState({ recursiveSettings });
  }

  handleCampaignRecursiveType = (check) => {
    let { recursiveSettings } = this.state;
    recursiveSettings["isLaunchNow"] = check;
    this.setState({ recursiveSettings })
  }

  handleRecursiveType = (check) => {
    let { recursiveSettings } = this.state;
    recursiveSettings["recursiveType"] = check;
    this.setState({ recursiveSettings })
  }

  gotoRecursiveDashboard = (item, type) => {
    let surveyId = this.props.match.params.id;
    let campaignId = item.id;
    if (campaignId && surveyId) {
      if (type === "rtype1") {
        this.props.history.push('/app/recursivedashboardt1/' + surveyId + "/" + campaignId);
      }
      else if (type === "rtype2") {
        this.props.history.push('/app/recursivedashboardt2/' + surveyId + "/" + campaignId);
      }
    }
  }


  handleExitSurveyAutofill = () => {
    let { survey } = this.props;
    let { getSurveyData } = survey;
    if (getSurveyData && getSurveyData.isRiskSurveyType && getSurveyData.isRiskSurveyType === "DEFAULTEXIT") {
      let { campaignFormData } = this.state;
      campaignFormData["title"] = "Exit Survey"
      campaignFormData["subject"] = "This is Exit Survey Subject"
      campaignFormData["message"] = "<p>This is Exit Survey Message</p>"
      campaignFormData["emailName"] = "Exit Survey emailName"
      campaignFormData["teamname"] = "Exit Survey teamname"

      this.setState({ campaignFormData })

    }

  }


  handleRecDaysActive = (value) => {
    let data5 = {
      "surveyId": this.props.match.params.id,
      "id": value
    }
    let data5temp2 = {
      "surveyId": this.props.match.params.id
    }
    this.props.dispatch(surveyActions.updateActiveStatusRecCampaign(data5, data5temp2));
  }


  handleLaunchType = (item) => {
    this.setState({ crrLaunchType: item }, () => {
      let { campaignFormData, employees } = this.state;
      campaignFormData["employees"] = [];
      employees = [];
      this.setState({ campaignFormData, employees })
    })
  }


  handleSelectCategory = (item) => {
    this.setState({ crrSurveyCategory: item }, () => {

      if (item && item.id && item.id !== "None") {
        let data5 = {
          "surveyCategory": item.id
        }
        this.props.dispatch(surveyActions.getSurveyListByCategory(data5));
      }

    })
  }

  handleSelectSurvey = (item) => {
    this.setState({ crrSurveyData: item }, () => {
      if (item && item.id && item.id !== "None") {
        let dataZ = {
          "surveyId": item.id,
          "filters": []
        }

        this.props.dispatch(dashboardActions.clusterAnalysis(dataZ));
      }

    })
  }


  handleSelectSenseSurvey = (item) => {

    if (item && item.id) {
      let data = {
        "id": item.id,
        "name": item.name
      }
      this.setState({ crrSenseSurvey: data }, () => {

        let data2 = {
          "surveyId": item.id
        }

        this.props.dispatch(riskdashtype2Actions.getAllRiskEmpConversation(data2));


      });
    }


  }




  handleCusterSelect = (ITEM) => {
  }


  // handleCusterSelect=(name,list)=>{
  //   let newList = list.map((emp_id)=>({"id":emp_id}))
  //   this.setState({clusterCrrName:name,clusterCrrList:newList},()=>{
  //     let { campaignFormData , employees} = this.state;
  //     campaignFormData["employees"] = newList;
  //     employees = newList;    
  //     this.setState({campaignFormData, employees})
  //   });
  // }


  render() {

    let { clusterCrrName, recursiveSettings, campaignValidationList, formBatchRange, showBatchRange } = this.state;



    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    //////console.log('moduleAccess: ',moduleAccess)



    //let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        //SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    // function accessAllow (item){
    //   return (moduleAccess.isAdmin?true:(moduleAccess && moduleAccess.access && moduleAccess.access[item]?true:false))
    // }

    //END -------------------------------------------------------






    let { campaign, survey, dashboard, riskdashtype2 } = this.props;
    let { EmpFilterData, getCompanyNameOnlyData, clusterAnalysisData } = dashboard;

    let { getAllRiskEmpConversationData } = riskdashtype2;

    let { campaignList, getCampaignEmployeesByIDData, getCampaignActiveHistoryData } = campaign;
    let { getSurveyListData, getIndexByFilterDMGData, getLanguageLabelData, getLanguageData, getSurveyData, getEmployeeSearchData, getEmployeeBatchRangeData, getCustomerBatchRangeData, allLifecycleListenListData, getSurveyListByCategoryData } = survey;
    let { employeeValueSearch, showCampaignOptions, showLanguageModal, CurrentLanguageName, CurrentLanguage, reminderSubmitConfirm, confirmCreateCampaignAlert, empSelectRemindFilter, showReminderEmployeeFilter, reminderformData, showReminderModal, showCreateCampaign, campaignFormData, employees, EmpIndexA, EmpIndexB, currentEmpPage, selectAllEmp, showEmployeeFilter, empSelectFilter } = this.state;
    // //////console.log('getCampaignEmployeesByIDData: ',getCampaignEmployeesByIDData)





    // let companySurveyCode = "";
    // if(getSurveyData && getSurveyData.code){
    //   companySurveyCode = getSurveyData.code;
    // }

    //////console.log('getCampaignActiveHistoryData--------------------------------->: ',getCampaignActiveHistoryData)

    let allEmployeesList = [];
    let AllEmpTotal = 0;

    if (employeeValueSearch) {
      allEmployeesList = getEmployeeSearchData && getEmployeeSearchData.list && getEmployeeSearchData.list.length > 0 ? getEmployeeSearchData.list : [];
      AllEmpTotal = getEmployeeSearchData && getEmployeeSearchData.total ? getEmployeeSearchData.total : 0;
    }
    else {
      allEmployeesList = getEmployeeBatchRangeData && getEmployeeBatchRangeData.list && getEmployeeBatchRangeData.list.length > 0 ? getEmployeeBatchRangeData.list : [];
      AllEmpTotal = getEmployeeBatchRangeData && getEmployeeBatchRangeData.total ? getEmployeeBatchRangeData.total : 0;
    }


    let allCustomerList = getCustomerBatchRangeData && getCustomerBatchRangeData.list && getCustomerBatchRangeData.list.length > 0 ? getCustomerBatchRangeData.list : [];
    // let AllCustTotal = getCustomerBatchRangeData && getCustomerBatchRangeData.total?getCustomerBatchRangeData.total:0;



    let loading = survey.loading || campaign.loading;

    let SurveyName = '';
    if (getSurveyData) {
      SurveyName = getSurveyData.name;
    }

    let EmployeeList = []
    if (allEmployeesList && allEmployeesList.length > 0) {

      if (empSelectFilter.template === 2) {
        allEmployeesList.forEach((item, index) => {
          if (!item.isDisable) {
            EmployeeList.push(item)
          }
        })
      }
      else if (empSelectFilter.template === 3) {
        allEmployeesList.forEach((item, index) => {
          if (item.isDisable) {
            EmployeeList.push(item)
          }
        })
      }
      else {
        EmployeeList = allEmployeesList;
      }



    }


    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }
    let EmployeFilterList = [
      {
        name: LanguageObj && LanguageObj["show_all_" + CurrentLanguage] ? LanguageObj["show_all_" + CurrentLanguage] : "Show All",
        template: 1
      },
      {
        name: LanguageObj && LanguageObj["active_" + CurrentLanguage] ? LanguageObj["active_" + CurrentLanguage] : "Active",
        template: 2
      },
      {
        name: LanguageObj && LanguageObj["inactive_" + CurrentLanguage] ? LanguageObj["inactive_" + CurrentLanguage] : "InActive",
        template: 3
      },
    ]

    let actionList = [
      {
        id: 2,
        type: LanguageObj && LanguageObj["reminder_to_incomplete_" + CurrentLanguage] ? LanguageObj["reminder_to_incomplete_" + CurrentLanguage] :
          'Incomplete Survey',
      },
      {
        id: 3,
        type: LanguageObj && LanguageObj["reminder_to_not_delivered_" + CurrentLanguage] ? LanguageObj["reminder_to_not_delivered_" + CurrentLanguage] :
          'Undelivered Survey',
      }
    ]

    let { ClientProfileData } = this.props.dashboard;
    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageractionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }



    let {
      currFilterLevel, showLevelFilter,
      currFilterValue, showValueFilter
    } = this.state;


    let campaignTypeList = ['NORMAL'];

    let TypeList = ['EMAIL', 'MOBILE', 'NONE']



    let campaignRecursiveFreqList = [
      {
        "label": "Launch Now",
        "value": true
      },
      {
        "label": "Select Launch Date",
        "value": false
      }
    ];


    let campaignRecursiveTypeList = [
      {
        "label": "RECURSIVE NORMAL",
        "value": "RECURSIVE_NORMAL"
      },
      {
        "label": "RECURSIVE LIFECYCLE",
        "value": "RECURSIVE_EVENT"
      }
    ];
    // {
    //   "label":"RECURSIVE EVENT",
    //   "value":"RECURSIVE_EVENT"
    // }

    //let SurveyisExpire = '';
    let SurveyexpireTime = '';

    if (getSurveyData) {
      SurveyName = getSurveyData.name;
      //SurveyisExpire = getSurveyData.isExpire ? true : (moment(moment.unix(getSurveyData.expireTime / 1000).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) ? true : false);
      SurveyexpireTime = (getSurveyData && getSurveyData.expireTime) ? moment.unix(getSurveyData.expireTime / 1000).format("DD MMM YYYY") : moment().format("DD MMM YYYY");
    }






    let isRecursiveDays = false;
    if (getSurveyData && getSurveyData.isRiskSurveyType === "DEFAULTDAYS") {
      isRecursiveDays = true;
    }

    let isValidSurvey = false;
    if (getSurveyData && getSurveyData.id) {
      isValidSurvey = true
    }






    let SenseSurveyCategoryList = [
      {
        "id": "A",
        "name": "Existing Survey"
      },
      {
        "id": "B",
        "name": "New Employee Launch"
      }
    ]





    return (
      <div className='bg-[#f9fafb] h-screen overflow-y-auto'>
        <div>
          <Header onLogout={this.onLogout}
            CurrentLanguageName={CurrentLanguageName}
            getLanguageData={getLanguageData}
            handleLanguage={this.handleLanguage}
            openLanguageModal={this.openLanguageModal}
            showLanguageModal={showLanguageModal}
            goBackFn={this.goBackFn}
            companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}

          />

          <div className="sticky z-20 flex items-center justify-between h-16 px-4 py-1 py-2 bg-white border-b top-14 ">
            <div className="flex space-x-2 text-gray-700">
              <span className="material-symbols-outlined -mt-0.5">description</span><h2 className="w-5/6 text-sm font-medium truncate"> {SurveyName}  </h2>
            </div>
            <div className="inline-flex items-center">
              <button className="py-2 text-sm font-medium text-gray-800 capitalize rounded-full "><b className="font-normal text-gray-500">Exp: </b>{SurveyexpireTime}</button>
            </div>
            {/* end */}
          </div>


          {isValidSurvey ?
            <>


              {isRecursiveDays ?
                <>


                  {campaignList && campaignList.length > 0 ?
                    <CampaignList
                      campaignList={campaignList}
                      onReportCampaign={this.onReportCampaign}
                      onReminderCampaign={this.onReminderCampaign}
                      LanguageObj={LanguageObj}
                      CurrentLanguage={CurrentLanguage}
                      handleCampaignOptions={this.handleCampaignOptions}
                      showCampaignOptions={showCampaignOptions}
                      chooseCampaignOption={this.chooseCampaignOption}

                      onCreateCampaign={this.onCreateCampaign}

                      gotoRecursiveDashboard={this.gotoRecursiveDashboard}

                      isRecursiveDays={true}
                      handleRecDaysActive={this.handleRecDaysActive}
                      getCampaignActiveHistoryData={getCampaignActiveHistoryData}

                    />
                    :
                    <>
                      {!loading ?
                        <div className="flex items-center justify-center w-full mt-24 bg-white">
                          {/*  text start*/}
                          <div className="text-center">
                            <h1 className="text-3xl font-semibold text-gray-800 cursor-default">Let's start the process of launching {SurveyName}'s Campaign</h1>

                            <div className="flex justify-center"><button onClick={() => this.onRecDayCampaignLaunch()} className="flex items-center px-6 py-3 my-6 text-sm text-white bg-blue-500 rounded-md focus:outline-none"> LAUNCH</button>    </div>
                          </div>
                        </div>
                        : null}
                    </>
                  }




                </>
                :
                <>


                  {/* <div className='items-center justify-between mx-4 my-2'>
      {companySurveyCode?
      <>
        <div className="items-center w-full py-1 space-y-4 overflow-x-auto md:flex whitespace-nowrap md:space-x-2 md:space-y-0">
          <div className="font-medium text-slate-800 ">Survey Unique URL</div>
          <div className='flex items-center justify-between w-full bg-white border border-gray-300 rounded-md'>
            <input value={(CONST.SURVEY_LOGIN +companySurveyCode).toString()} disabled={true} className="w-full p-2 select-all cursor-text hover:bg-blue-100" />
            <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10 px-2 text-gray-500 cursor-pointer hover:text-gray-800'  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
            </svg>
          </div>
        </div>
        <div className='text-xs text-blue-500 pl-44'>*This URL can be used by all who have no access to Survey Email.</div>
      </>
      :
      <div>
        <button 
        className="px-2 py-1 text-white bg-green-500 rounded-lg"
        onClick={()=>this.onGenerateCompanySurveyCode()}>
        Generate Survey Code
        </button>
      </div>}
    </div> */}




                  {showReminderModal ?
                    <ReminderCampaign
                      reminderformData={reminderformData}
                      handleReminderInput={this.handleReminderInput}
                      handleReminderMessageInput={this.handleReminderMessageInput}
                      campaignList={campaignList}
                      getCampaignEmployeesByIDData={getCampaignEmployeesByIDData}
                      actionList={actionList}
                      showReminderEmployeeFilter={showReminderEmployeeFilter}
                      handleEmpReminderDropdown={this.handleEmpReminderDropdown}
                      empSelectRemindFilter={empSelectRemindFilter}
                      handleEmpRemindFilterSelect={this.handleEmpRemindFilterSelect}
                      handleReminderSubmit={this.handleReminderSubmit}
                      LanguageObj={LanguageObj}
                      CurrentLanguage={CurrentLanguage}
                    />
                    :
                    <>
                      {showCreateCampaign ?
                        <CreateCampaignPulse
                          campaignFormData={campaignFormData}
                          handleCampaignInput={this.handleCampaignInput}
                          handleMessageInput={this.handleMessageInput}
                          handleCampaignSubmit={this.handleCampaignSubmit}
                          allEmployeesList={EmployeeList}
                          allCustomerList={allCustomerList}

                          handleCampaignEmployees={this.handleCampaignEmployees}

                          employees={employees}

                          EmpIndexA={EmpIndexA}
                          EmpIndexB={EmpIndexB}

                          prevEmpPage={this.prevEmpPage}
                          nextEmpPage={this.nextEmpPage}

                          SelectEmpPage={this.SelectEmpPage}
                          currentEmpPage={currentEmpPage}
                          SelectAllEmployees={this.SelectAllEmployees}
                          selectAllEmp={selectAllEmp}


                          showEmployeeFilter={showEmployeeFilter}

                          EmployeFilterList={EmployeFilterList}

                          empSelectFilter={empSelectFilter}

                          handleEmpFilterSelect={this.handleEmpFilterSelect}

                          handleEmpDropdown={this.handleEmpDropdown}

                          handleTestUserCheck={this.handleTestUserCheck}
                          LanguageObj={LanguageObj}
                          CurrentLanguage={CurrentLanguage}



                          currFilterLevel={currFilterLevel}
                          currFilterValue={currFilterValue}

                          showLevelFilter={showLevelFilter}
                          showValueFilter={showValueFilter}

                          handleEmpLevelDropdown={this.handleEmpLevelDropdown}
                          handleEmpValueDropdown={this.handleEmpValueDropdown}

                          handleLevelSelect={this.handleLevelSelect}
                          handleValueSelect={this.handleValueSelect}


                          handleSendTypeCheck={this.handleSendTypeCheck}

                          AllEmpTotal={AllEmpTotal}

                          formBatchRange={formBatchRange}

                          selectBatchRange={this.selectBatchRange}
                          selectBatchSize={this.selectBatchSize}

                          showBatchRange={showBatchRange}
                          handleBatchRangeDropdown={this.handleBatchRangeDropdown}
                          campaignTypeList={campaignTypeList}
                          handleCampaignType={this.handleCampaignType}

                          employeeValueSearch={employeeValueSearch}
                          handleEmployeeValueSearch={this.handleEmployeeValueSearch}
                          handleGetEmployees={this.handleGetEmployees}
                          campaignValidationList={campaignValidationList}

                          EmpFilterData={EmpFilterData}
                          getIndexByFilterDMGData={getIndexByFilterDMGData}

                          recursiveSettings={recursiveSettings}

                          handleRecursiveCampaignInput={this.handleRecursiveCampaignInput}

                          campaignRecursiveFreqList={campaignRecursiveFreqList}

                          campaignRecursiveTypeList={campaignRecursiveTypeList}

                          handleCampaignRecursiveType={this.handleCampaignRecursiveType}

                          handleRecursiveType={this.handleRecursiveType}

                          handleType={this.handleType}

                          TypeList={TypeList}

                          getSurveyData={getSurveyData}
                          handleExitSurveyAutofill={this.handleExitSurveyAutofill}


                          SenseSurveyCategoryList={SenseSurveyCategoryList}
                          crrLaunchType={this.state.crrLaunchType}
                          handleLaunchType={this.handleLaunchType}

                          crrSurveyCategory={this.state.crrSurveyCategory}
                          allLifecycleListenListData={allLifecycleListenListData}
                          handleSelectCategory={this.handleSelectCategory}

                          getSurveyListByCategoryData={getSurveyListByCategoryData}
                          crrSurveyData={this.state.crrSurveyData}
                          handleSelectSurvey={this.handleSelectSurvey}


                          clusterAnalysisData={clusterAnalysisData}

                          clusterCrrName={clusterCrrName}
                          handleCusterSelect={this.handleCusterSelect}


                          crrSenseSurvey={this.state.crrSenseSurvey}
                          handleSelectSenseSurvey={this.handleSelectSenseSurvey}
                          senseSurveyList={getSurveyListData && getSurveyListData.length > 0 ? getSurveyListData.filter(prev => prev.surveyModule === 2) : []}
                          getPeopleToMeetList={getAllRiskEmpConversationData && getAllRiskEmpConversationData.list && getAllRiskEmpConversationData.list.length > 0 ? getAllRiskEmpConversationData.list : []}

                        />
                        :
                        <>
                          {campaignList && campaignList.length > 0 ?
                            <CampaignList
                              campaignList={campaignList}
                              onReportCampaign={this.onReportCampaign}
                              onReminderCampaign={this.onReminderCampaign}
                              LanguageObj={LanguageObj}
                              CurrentLanguage={CurrentLanguage}
                              handleCampaignOptions={this.handleCampaignOptions}
                              showCampaignOptions={showCampaignOptions}
                              chooseCampaignOption={this.chooseCampaignOption}

                              onCreateCampaign={this.onCreateCampaign}

                              gotoRecursiveDashboard={this.gotoRecursiveDashboard}

                            />
                            :
                            <>
                              {!loading ?
                                <div className="flex items-center justify-center w-full bg-gray-100 " style={{ height: 'calc(100vh - 8rem)' }}>
                                  {/*  text start*/}
                                  <div className="text-center">
                                    <img alt="icon" src="img/empty_survey.png" className="mx-auto" />
                                    {/* <h1 className="text-lg font-semibold text-gray-800 cursor-default">No Campaign has been created yet.</h1> */}
                                    {/* <p className="text-xs text-gray-500 cursor-default">Let's get started &amp; create your first campaign!</p>    */}
                                    <h1 className="font-semibold text-gray-800 cursor-default text-md">Let's get started, create your first campaign!</h1>

                                    <div className="flex justify-center"><button onClick={() => this.onCreateCampaign(1)} className="flex items-center px-6 py-3 my-6 text-sm text-white bg-blue-500 rounded-md focus:outline-none"><span className="mr-2 material-symbols-outlined">add</span> CREATE CAMPAIGN</button>    </div>
                                  </div>
                                  {/* tex end */}
                                </div>
                                : null}
                            </>
                          }
                        </>
                      }
                    </>
                  }



                </>}

            </>
            : null}










          {reminderSubmitConfirm ?

            <ConfirmSubmit
              onReminderSubmit={this.onReminderSubmit}
              handleConfirmReminderMailClose={this.handleConfirmReminderMailClose}
            />

            : null}


          {confirmCreateCampaignAlert ?

            <ConfirmCreateCampaign
              handleSendSingleMail={this.onCampaignSubmit}
              handleConfirmMailClose={this.handleConfirmCreateCampaignMailClose}
            />



            : null}



        </div>


      </div>

    );
  }
}
function mapStateToProps(state) {
  //////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { campaign, survey, dashboard, riskdashtype2 } = state;
  return {
    loggingIn,
    campaign,
    survey,
    dashboard,
    riskdashtype2
  };
}
export default connect(mapStateToProps)(Campaign);
