import React, { Component } from 'react';
import { connect } from 'react-redux';

//api call
import { dashboardActions, userActions, surveyActions } from '../../_actions';

import LoadingOverlay from 'react-loading-overlay';

// import ManagerActionPlan from './ManagerActionPlan/ManagerActionPlan';

import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import Survey from '../SurveyMgmt/Survey/Survey';
import DimensionListComponent from './DimensionList/DimensionList';
import ActionListComponent from './ActionList/ActionList';
import TaskListComponent from './TaskList/TaskList';
import ResourceListComponent from './ResourceList/ResourceList';
import TaskDetailsComponent from './TaskList/TaskDetails';
import ResourceDetailsComponent from './ResourceList/ResourceDetails';
import CompleteModal from './CompleteModal';
import EmptyDimensions from './EmptyDimensions';
import StartModal from './StartModal';

import ReportModal from './ReportModal/ReportModal';

import { CONST } from '../../_config';

import CreateGoalModal from './GoalPlan/CreateGoalModal';
import CreateTaskModal from './GoalPlan/CreateTaskModal';

import UpdateTaskModal from './GoalPlan/UpdateTaskModal';
import UpdateGoalModal from './GoalPlan/UpdateGoalModal';
import TaskCompleteModal from './GoalPlan/TaskCompleteModal';
import TaskStartModal from './GoalPlan/TaskStartModal';

import TaskDeleteModal from './GoalPlan/TaskDeleteModal';
import TaskRemarkModal from './GoalPlan/TaskRemarkModal';

import GoalDeleteModal from './GoalPlan/GoalDeleteModal';



import moment from 'moment'

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
        CurrentSurveyId:"",
        CurrentSurveyName:"",
        showDimensionCards:false,
        CurrentDimensionId:"",
        CurrentDimensionData:"",
        CurrentActionId:"",
        ShowComponent:1,
        CurrentActionData:"",
        CurrentTaskId:"",
        CurrentTaskData:"",
        CurrentResourceId:"",
        CurrentResourceData:"",
        showCompleteModal:false,
        showStartModal:false,
        showStartModal:false,
        remarkTask:"",
        remarkResource:"",
        showTaskDeleteModal:false,

        showTaskRemarkModal:false,

        showSidebar:true,

        errorList:[],

        ShowScreens:"riskactionplan",

        DimensionList:[
          {
            index:1,
            name:'Happiness',
            cutOff:59
          },
          {
            index:2,
            name:'Engagement',
            cutOff:78
  
          },
          {
            index:4,
            name:'Stress',
            cutOff:28
  
          },
          {
            index:5,
            name:'Manager',
            cutOff:50
          }
        ],


        ManagerReportFConfig:{
          "managerIdType":CONST.MANAGER_ID_TYPE //Int or String
        },
        showCreateGoalModal:false,
        goalFormData:{
          "name":"",
          "desc":""
        },
        goalUpdateFormData:{
          "id":"",
          "name":"",
          "desc":""
        },
        crrGoalId:"",
        taskFormData:{
          "name": "",
          "desc": "",
          "timeline": "",
          "goalId":"",
          "employeeList":[],
          "startDate":"",
          "endDate":"",
          "remark":"",
          "deadline": ""

        },
        taskUpdateFormData:{
          "id": "",
          "name": "",
          "desc": "",
          "timeline": "",
          "goalId":"",
          "employeeList":[],
          "startDate":"",
          "endDate":"",
          "remark":"",
          "deadline": ""

        },
        formBatchRange:{
          "pageNo":1,
          "size":5
        },
        search:"",
        showUpdateGoalModal:false,
        showTaskCompleteModal:false,
        showTaskUpdateModal:false,
        taskCompleteFormData:{
          "id":"",
          "remark":""
        },
        taskStartFormData:{
          "id":""
        },
        goalDeleteFormData:{
          "id":""
        },
        showDeleteGoalModal:false,
        empSize:5,
        empOffset:0,
        empPage:1,
        crrMainGoalData:{},
        taskStatus:"all",
        ViewPlanDetailModal:false,
        crrMainPlanData:{}

    }
  }
  componentDidMount() {

    this.handleShowScreen("riskactionplan")



    this.props.dispatch(dashboardActions.emptyManagerActionPlan());
    this.props.dispatch(dashboardActions.getManagerProfile());

 

  }

  handleLogout=()=>{
    this.props.dispatch(userActions.logout());
  }

  


  ChooseSurvey=(data,check)=>{
    this.setState({CurrentSurveyId:data.id,CurrentSurveyName:data.name, showDimensionCards:check?true:false});
    this.props.dispatch(dashboardActions.getEmployeeActionPlan({surveyId:data.id}));
  }

  onSelectDimension=(parameter)=>{
    this.setState({CurrentDimensionId:parameter.id,CurrentDimensionData:parameter});
    this.setState({CurrentActionId:'',ShowComponent:1, CurrentResourceId:'',CurrentResourceData:"",CurrentTaskId:"",CurrentTaskData:""});

  }

  onSelectAction=(action)=>{
    if(action){
      this.setState({CurrentActionId:action.id,CurrentActionData:action, ShowComponent:1});
    }
    else{
      this.setState({CurrentActionId:"",CurrentActionData:"", ShowComponent:1});
    }
  }

  navigate=(page)=>{
    if(page==="tasklist"){
      this.setState({ShowComponent:1});
    }
    else if(page==="resourcelist"){
      this.setState({ShowComponent:2});
    }
    else if(page==="actionlist"){
      this.setState({CurrentActionId:"",CurrentActionData:"",ShowComponent:1});
    }
  }

  onSelectTask=(task,number)=>{
    this.setState({CurrentTaskId:task.id,CurrentTaskData:{...task,"number":number}, ShowComponent:3});
  }
  onSelectResource=(resource,number)=>{
    this.setState({CurrentResourceId:resource.id,CurrentResourceData:{...resource,"number":number}, ShowComponent:4});
  }


  handleManagerActionTaskComplete=(task, isStart)=>{
    let { dashboard } = this.props;
    let { SurveyList } = dashboard;
    let { CurrentSurveyId } = this.state;

    // let surveyId="";
    // if(CurrentSurveyId){
    //     surveyId = CurrentSurveyId;
    // }
    // else{
    //     surveyId=(SurveyList && SurveyList.length>0)?SurveyList[0].id:""
    // }

    let data = {
      "actionId":task.actionId,
      "taskId":task.taskId,
      "remark":task.remark,
      "emp_id":task.emp_id,
      "isStart":isStart
    }
    this.props.dispatch(dashboardActions.updateEmployeeTask(data,2));
    this.setState({showCompleteModal:false, showStartModal:false});

  }

  
  
  handleManagerActionResourceComplete=(resource)=>{
    let { dashboard } = this.props;
    let { SurveyList } = dashboard;
    let { CurrentSurveyId } = this.state;

    // let surveyId="";
    // if(CurrentSurveyId){
    //     surveyId = CurrentSurveyId;
    // }
    // else{
    //     surveyId=(SurveyList && SurveyList.length>0)?SurveyList[0].id:""
    // }

    let data = {
      // "surveyId": surveyId,
      "actionId":resource.actionId,
      "resourceId":resource.resourceId,
      "remark":resource.remark,
      "emp_id":resource.emp_id
    }
    console.log('emp_id: ',data)
    this.props.dispatch(dashboardActions.updateEmployeeResource(data));
    this.setState({showCompleteModal:false});

  }


  onComplete=()=>{
    this.setState({showCompleteModal:true});
  }

  onStartTask=()=>{
    this.setState({showStartModal:true});
  }


  closeCompleteModal=()=>{
    this.setState({showCompleteModal:false});
  }

  closeStartModal=()=>{
    this.setState({showStartModal:false});
  }


  handleRemark=(e)=>{
    console.log('e------------------------------->',e.target.value)
    let{ShowComponent}=this.state;
    if(ShowComponent===3){
      this.setState({remarkTask:e.target.value});
    }
    else if(ShowComponent===4){  
      this.setState({remarkResource:e.target.value});
    }
  }
  onRemarkCompleteSubmit=(data)=>{
    let{CurrentTaskId, CurrentResourceId, ShowComponent, CurrentActionId, remarkTask, remarkResource}=this.state;
    let { ManagerProfileData } = this.props.dashboard;
    let emp_id = '';
    if(ManagerProfileData && ManagerProfileData.ManagerPID){
      emp_id = ManagerProfileData.emp_id;
    }

    if(ShowComponent===3){
      let data1 = {
        "taskId":CurrentTaskId,
        "remark":remarkTask,
        "actionId":CurrentActionId,
        "emp_id":emp_id
      }
      let isStart =  false;
      this.handleManagerActionTaskComplete(data1, isStart)
      ////console.log("data1:",data1)
      this.setState({ShowComponent:1});

    }
    else if(ShowComponent===4){

      let data2 = {
        "resourceId":CurrentResourceId,
        "remark":remarkResource,
        "actionId":CurrentActionId,
        "emp_id":emp_id
      }
      this.handleManagerActionResourceComplete(data2)
      ////console.log("data2:",data2)

      this.setState({ShowComponent:2});
    }


  }


  onStartTaskEntry=(data)=>{
    let{CurrentTaskId, CurrentResourceId, ShowComponent, CurrentActionId, remarkTask, remarkResource}=this.state;
    let { ManagerProfileData } = this.props.dashboard;
    let emp_id = '';
    if(ManagerProfileData && ManagerProfileData.ManagerPID){
      emp_id = ManagerProfileData.emp_id;
    }

    if(ShowComponent===3){
      let data1 = {
        "taskId":CurrentTaskId,
        "actionId":CurrentActionId,
        "remark":"",
        "emp_id":emp_id
      }
      let isStart = true;
      this.handleManagerActionTaskComplete(data1,isStart)
      ////console.log("data1:",data1)
      this.setState({ShowComponent:1});

    }
    // else if(ShowComponent===4){

    //   let data2 = {
    //     "resourceId":CurrentResourceId,
    //     "actionId":CurrentActionId,
    //     "emp_id":emp_id
    //   }
    //   this.handleManagerActionResourceComplete(data2)
    //   ////console.log("data2:",data2)

    //   this.setState({ShowComponent:2});
    // }


  }

  onAssignManagerActionPlan=()=>{
    let { CurrentSurveyId  } = this.state;
    this.props.dispatch(dashboardActions.AssignManagerActionPlan({surveyId:CurrentSurveyId}));

  }


  callInitGoalPlan=()=>{
    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
  }
    this.props.dispatch(dashboardActions.getIDPLastDate({}))
    this.props.dispatch(dashboardActions.getGoalList(data1));
  }

  callInitManagerReport=()=>{
    let data6 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
   }
   this.props.dispatch(dashboardActions.getManagerProfile());
   this.props.dispatch(dashboardActions.getMNGRSurveyList(data6));
  }

  handleShowScreen=(value)=>{
    this.setState({ShowScreens:value})
    if(value==="riskactionplan"){
      this.props.dispatch(dashboardActions.getEmployeeActionPlan({}));
    }
    if(value==="mysurveys"){
      this.props.history.push('/app/survey');
    }


  }


  onCloseManagerReportModal=()=>{
    this.handleShowScreen("goalplan")
  }


  onLoadManagerReport=()=>{
    let { dashboard }= this.props;
    let { getIndexData, ManagerProfileData } = dashboard;

    let { ManagerReportFConfig  } = this.state;

    let IndexList = getIndexData? Object.keys(getIndexData) :[]

    let mngrId = ManagerProfileData && ManagerProfileData["emp_id"]?ManagerProfileData["emp_id"]:"";
    let mngrDMGValue = ManagerProfileData && ManagerProfileData["Department"]?ManagerProfileData["Department"]:"";

    if(mngrId){

      let data1 = {
        "surveyId":this.state.CurrentSurveyId
      }

      this.props.dispatch(dashboardActions.getOverallScoreBySurveyId(data1));

      this.props.dispatch(surveyActions.getSurveyById(data1));
      this.props.dispatch(dashboardActions.getIndex(data1));


      let ManagerIDNumber = mngrId;

      if(ManagerReportFConfig && ManagerReportFConfig.managerIdType){
        if(ManagerReportFConfig.managerIdType==="Int"){
          ManagerIDNumber = parseInt(mngrId)
        }
        else if(ManagerReportFConfig.managerIdType==="String"){
          ManagerIDNumber = (mngrId).toString()
        }
      }



      this.props.dispatch(dashboardActions.getIndexFilter({
        "surveyId": this.state.CurrentSurveyId,
        "filters": [
          {
            "level":"ManagerPID",
            "value":ManagerIDNumber
          }
        ]
      }));







      
      if(IndexList.some(prev=>(prev.toString())==="1") && getIndexData && getIndexData["1"] || true){
       
        this.props.dispatch(dashboardActions.getDemographicIndexHappiness({
          ...data1,"xAxis":"ManagerPID"
        }));
  
        //Happiness Manager
          this.props.dispatch(dashboardActions.getHappinessQuestion({
            ...data1,
            "template":1,
            "filters":[]
          }));
          this.props.dispatch(dashboardActions.getHappinessManagerQuestion({
            ...data1,
            "template":1,
            "filters":[
              {
                "level":"ManagerPID",
                "value":ManagerIDNumber
              }
            ]
          }));
          if(mngrDMGValue){
            this.props.dispatch(dashboardActions.getHappinessDMGQuestion({
              ...data1,
              "template":1,
              "filters":[
                {
                  "level":"Department",
                  "value":mngrDMGValue.toString()
                }
              ]
            }));
          }
      }






      if(IndexList.some(prev=>(prev.toString())==="2") && getIndexData && getIndexData["2"] || true){
     
        this.props.dispatch(dashboardActions.getDemographicIndexEngagement({
          ...data1,"xAxis":"ManagerPID"
        }));

        //Engagement Manager
      this.props.dispatch(dashboardActions.getEngagementQuestion({
        ...data1,
        "template":2,
        "filters":[]
      }));
      this.props.dispatch(dashboardActions.getEngagementManagerQuestion({
        ...data1,
        "template":2,
        "filters":[
          {
            "level":"ManagerPID",
            "value":ManagerIDNumber
          }
        ]
      }));
      if(mngrDMGValue){
        this.props.dispatch(dashboardActions.getEngagementDMGQuestion({
          ...data1,
          "template":2,
          "filters":[
            {
              "level":"Department",
              "value":mngrDMGValue.toString()
            }
          ]
        }));
      }
    }


    if(IndexList.some(prev=>(prev.toString())==="4") && getIndexData && getIndexData["4"] || true){
     
      this.props.dispatch(dashboardActions.getDemographicIndexStress({
        ...data1,"xAxis":"ManagerPID"
      }));

      //Stress Manager
      this.props.dispatch(dashboardActions.getStressQuestion({
        ...data1,
        "template":4,
        "filters":[]
      }));
      this.props.dispatch(dashboardActions.getStressManagerQuestion({
        ...data1,
        "template":4,
        "filters":[
          {
            "level":"ManagerPID",
            "value":ManagerIDNumber
          }
        ]
      }));
      if(mngrDMGValue){
        this.props.dispatch(dashboardActions.getStressDMGQuestion({
          ...data1,
          "template":4,
          "filters":[
            {
              "level":"Department",
              "value":mngrDMGValue.toString()
            }
          ]
        }));
      }
    }





    if(IndexList.some(prev=>(prev.toString())==="5") && getIndexData && getIndexData["5"] || true){

        this.props.dispatch(dashboardActions.getDemographicIndexManager({
          ...data1,"xAxis":"ManagerPID"
        }));


        //Manager Manager
        this.props.dispatch(dashboardActions.getManagerQuestion({
          ...data1,
          "template":5,
          "filters":[]
        }));
        this.props.dispatch(dashboardActions.getManagerManagerQuestion({
          ...data1,
          "template":5,
          "filters":[
            {
              "level":"ManagerPID",
              "value":ManagerIDNumber
            }
          ]
        }));
        if(mngrDMGValue){
          this.props.dispatch(dashboardActions.getManagerDMGQuestion({
            ...data1,
            "template":5,
            "filters":[
              {
                "level":"Department",
                "value":mngrDMGValue.toString()
              }
            ]
          }));
        }


    }

    if(IndexList.some(prev=>(prev.toString())==="6") && getIndexData && getIndexData["6"] || true){

      this.props.dispatch(dashboardActions.getDemographicIndexLeader({
        ...data1,"xAxis":"ManagerPID"
      }));

      //Leader Manager
      this.props.dispatch(dashboardActions.getLeaderQuestion({
        ...data1,
        "template":6,
        "filters":[]
      }));
      this.props.dispatch(dashboardActions.getLeaderManagerQuestion({
        ...data1,
        "template":6,
        "filters":[
          {
            "level":"ManagerPID",
            "value":ManagerIDNumber
          }
        ]
      }));
      if(mngrDMGValue){
        this.props.dispatch(dashboardActions.getLeaderDMGQuestion({
          ...data1,
          "template":6,
          "filters":[
            {
              "level":"Department",
              "value":mngrDMGValue.toString()
            }
          ]
        }));
      }



    }







  
    }

    
  }



  saveSurveyData=(surveyData)=>{
    let SurveyId = surveyData && surveyData.id?surveyData.id:"";
    this.setState({CurrentSurveyId:SurveyId})
  }

  openCreateGoalModal=(check)=>{
    this.setState({showCreateGoalModal:check})
  }

  openDeleteGoalModel=(goalId, check)=>{
    
    if(check){
      let data = {
        "id": goalId.id
      }
      this.setState({goalDeleteFormData:data, showDeleteGoalModal:check});
    }
    else{
      let clear = {
        "id":""
      }
      this.setState({goalDeleteFormData:clear,showDeleteGoalModal:check});
    }

}


deleteGoalSubmit=()=>{
  let data = {
    "id":this.state.goalDeleteFormData.id
  }
  let temp = {
    "keyWord": "",
    "pageNo": 1,
    "size": 500
  }
  ////console.log("data-------------->",data)
  this.props.dispatch(dashboardActions.deleteGoal(data, temp));
  this.setState({showDeleteGoalModal:false},()=>{
    let clear = {
      "id":""
    }
    this.setState({goalDeleteFormData:clear})
  })
}




  openUpdateGoalModel=(goalId, check)=>{
    
      if(check){
        let data = {
          "id": goalId.id,
          "name": goalId.name,
          "desc": goalId.desc
        }
        this.setState({goalUpdateFormData:data,showUpdateGoalModal:check});
      }
      else{
        this.setState({showUpdateGoalModal:check});
      }

  }

  handleGoalInput=(e)=>{
    let { value, name } = e.target;
    let { goalFormData } = this.state;
    goalFormData[name] = value;
    this.setState({goalFormData})
  }

  handleUpdateGoalInput=(e)=>{
    let { value, name } = e.target;
    let { goalUpdateFormData } = this.state;
    goalUpdateFormData[name] = value;
    this.setState({goalUpdateFormData})
  }

  handleTaskCompleteInput=(e)=>{
    let { value, name } = e.target;
    let { taskCompleteFormData } = this.state;
    taskCompleteFormData[name] = value;
    this.setState({taskCompleteFormData})
  }


  handleTaskInput=(e)=>{
    let { value, name } = e.target;
    let { taskFormData } = this.state;
    taskFormData[name] = value;
    this.setState({taskFormData})
  }


  handleUpdateTaskInput=(e)=>{
    let { value, name } = e.target;
    let { taskUpdateFormData } = this.state;
    taskUpdateFormData[name] = value;
    this.setState({taskUpdateFormData})
  }


  submitCreateGoal=()=>{
    let data = {
      "name":this.state.goalFormData.name,
      "desc":this.state.goalFormData.desc,
      "planList":[]
    }
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.createGoal(data, temp));
    this.setState({showCreateGoalModal:false},()=>{

      let clearData = {
        "name":"",
        "desc":""
      }
      this.setState({goalFormData:clearData})

    })


  }


  submitUpdateGoal=()=>{
    let data = {
      "id":this.state.goalUpdateFormData.id,
      "name":this.state.goalUpdateFormData.name,
      "desc":this.state.goalUpdateFormData.desc
    }
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.updateGoal(data, temp));
    this.setState({showUpdateGoalModal:false},()=>{
      let clearData = {
        "id":"",
        "name":"",
        "desc":""
      }
      this.setState({goalUpdateFormData:clearData})
    })


  }

  submitTaskStart=()=>{
    let data = {
      "id":this.state.taskStartFormData.id
    }
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.startPlan(data, temp));
    this.setState({showTaskStartModal:false},()=>{
      let clearData = {
        "id":""
      }
      this.setState({taskStartFormData:clearData})
    })
  }


  deleteTaskSubmit=()=>{
    let data = {
      "id":this.state.crrTaskId
    }
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    ////console.log("data-------------->",data)
    this.props.dispatch(dashboardActions.deletePlan(data, temp));
    this.setState({showTaskDeleteModal:false},()=>{
      this.setState({crrTaskId:''})
    })
  }




  submitTaskComplete=()=>{
    let data = {
      "id":this.state.taskCompleteFormData.id,
      "remark":this.state.taskCompleteFormData.remark
    }
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.closePlan(data, temp));
    this.setState({showTaskCompleteModal:false},()=>{
      let clearData = {
        "id":"",
        "remark":""
      }
      this.setState({taskCompleteFormData:clearData})
    })
  }


  openAddPlanModal=(goalId)=>{
    this.setState({crrGoalId:goalId},()=>{
      let { taskFormData } = this.state;
      taskFormData["goalId"] = goalId;
      this.setState({taskFormData});

      let data5 = {
        "keyWord":"",
        "pageNo": this.state.formBatchRange.pageNo,
        "size":this.state.formBatchRange.size
      }
      // this.props.dispatch(surveyActions.getEmployeeLimitedList(data5));
    })
  }


  submitCreateTask=()=>{
    this.setState({errorList:[]},()=>{



    if(this.state.taskFormData.name && this.state.taskFormData.deadline){
      let data = {
        "name":this.state.taskFormData.name,
        "desc":this.state.taskFormData.desc,
        "timeline": this.state.taskFormData.timeline,
        "deadline": this.state.taskFormData.deadline,
  
        "goalId":this.state.crrGoalId,
        "employeeList":this.state.taskFormData.employeeList
        // "startDate":this.state.taskFormData.startDate,
        // "endDate":this.state.taskFormData.endDate,
        // "remark":this.state.taskFormData.remark
      }
      let temp = {
        "keyWord": "",
        "pageNo": 1,
        "size": 500
      }
      this.props.dispatch(dashboardActions.createPlan(data, temp));
      setTimeout(()=>{
        this.setState({crrGoalId:""},()=>{
          let clearData = {
            "name": "",
            "desc": "",
            "timeline": "",
            "deadline":"",
            "goalId":"",
            "employeeList":[],
            "startDate":"",
            "endDate":"",
            "remark":""
          }
          this.setState({taskFormData:clearData, errorList:[]})
        })
      },1000)
    }

    else{
      let { errorList } = this.state;
      if(!this.state.taskFormData.name){
        errorList.push("task name")
      }
      if(!this.state.taskFormData.deadline){
        errorList.push("task deadline")

      }
      this.setState({errorList})

    }

  })


  }


  submitUpdateTask=()=>{

    this.setState({errorList:[]},()=>{



      if(this.state.taskUpdateFormData.name && this.state.taskUpdateFormData.deadline){
        let data = {
          "id":this.state.taskUpdateFormData.id,
          "name":this.state.taskUpdateFormData.name,
          "desc":this.state.taskUpdateFormData.desc,
          "timeline": this.state.taskUpdateFormData.timeline,
          "deadline": this.state.taskUpdateFormData.deadline,
          "employeeList":this.state.taskUpdateFormData.employeeList
          // "startDate":this.state.taskUpdateFormData.startDate,
          // "endDate":this.state.taskUpdateFormData.endDate,
          // "remark":this.state.taskUpdateFormData.remark
        }
        let temp = {
          "keyWord": "",
          "pageNo": 1,
          "size": 500
        }
        this.props.dispatch(dashboardActions.updatePlan(data, temp));
        setTimeout(()=>{
          this.setState({crrTaskId:"",showTaskUpdateModal:false},()=>{
            let clearData = {
              "id": "",
              "name": "",
              "desc": "",
              "timeline": "",
              "deadline":"",
              "goalId":"",
              "employeeList":[],
              "startDate":"",
              "endDate":"",
              "remark":""
            }
            this.setState({taskUpdateFormData:clearData, errorList:[]})
          })
        },1000)
      }
  
      else{
        let { errorList } = this.state;
        if(!this.state.taskUpdateFormData.name){
          errorList.push("task name")
        }
        if(!this.state.taskUpdateFormData.deadline){
          errorList.push("task deadline")
  
        }
        this.setState({errorList})
  
      }
  
    })

    






  }

  handleSelectEmployees=(empId)=>{
    let { taskFormData }= this.state;
    let getIndex = taskFormData["employeeList"].findIndex(prev=>prev===empId);
    if(getIndex===-1){
      taskFormData["employeeList"].push(empId)
    }
    else{
      taskFormData["employeeList"].splice(getIndex,1)
    }
    this.setState({taskFormData})

  }


  
  handleUpdateSelectEmployees=(empId)=>{
    let { taskUpdateFormData }= this.state;
    let getIndex = taskUpdateFormData["employeeList"].findIndex(prev=>prev===empId);
    if(getIndex===-1){
      taskUpdateFormData["employeeList"].push(empId)
    }
    else{
      taskUpdateFormData["employeeList"].splice(getIndex,1)
    }
    this.setState({taskUpdateFormData})

  }

  openTaskCompleteModel=(taskId, check)=>{

    this.setState({crrTaskId:taskId.id, showTaskCompleteModal:check},()=>{
      let data = {
        "id": taskId.id,
        "remark":taskId.remark
      }
      this.setState({taskCompleteFormData:data})
    })

    if(!check){
      let clearData = {
        "id":"",
        "remark":""
      }
      this.setState({taskCompleteFormData:clearData})
    }

  }

  openTaskStartModel=(taskId, check)=>{
    this.setState({crrTaskId:taskId.id, showTaskStartModal:check},()=>{
      let data = {
        "id": taskId.id
      }
      this.setState({taskStartFormData:data})
    })

    if(!check){
      let clearData = {
        "id":""
      }
      this.setState({taskStartFormData:clearData})
    }
  }


  closeDeleteModal=(check)=>{
    this.setState({crrTaskId:"", showTaskDeleteModal:check})
  }


  openDeleteTask=(taskId, check)=>{
    this.setState({crrTaskId:taskId.id, showTaskDeleteModal:check})
  }


  openUpdateTaskModel=(taskId, check)=>{
    this.setState({crrTaskId:taskId.id, showTaskUpdateModal:check},()=>{

      let data = {
        "id": taskId.id,
        "name": taskId.name,
        "desc": taskId.desc,
        "timeline": taskId.timeline,
        "deadline": taskId.deadline,
        "employeeList":[],
        "startDate":taskId.startDate,
        "endDate":taskId.endDate,
        "remark":taskId.remark
      }

      // ////console.log("taskId------------------>",taskId)

      // if(taskId && taskId.employeeList && taskId.employeeList.length>0){
      //   taskId.employeeList.forEach((emp)=>{
      //     data["employeeList"].push(emp.id)
      //   })
      // }
      
      ////console.log("data------------------>",data)

      this.setState({taskUpdateFormData:data},()=>{
        // if(check){
        //   let data5 = {
        //     "keyWord":"",
        //     "pageNo": this.state.formBatchRange.pageNo,
        //     "size":this.state.formBatchRange.size
        //   }
        //   this.props.dispatch(surveyActions.getEmployeeLimitedList(data5));
        // }

      });

    })

    if(!check){
      let clearData = {
        "id": "",
        "name": "",
        "desc": "",
        "timeline": "",
        "deadline": "",
        "goalId":"",
        "employeeList":[],
        "startDate":"",
        "endDate":"",
        "remark":""
      }
      this.setState({taskUpdateFormData:clearData})
    }
  }

  handleSearch=(e)=>{
    this.setState({search:e.target.value},()=>{
      let data5 = {
        "keyWord":e.target.value,
        "pageNo": this.state.formBatchRange.pageNo,
        "size":this.state.formBatchRange.size
      }
      // this.props.dispatch(surveyActions.getEmployeeLimitedList(data5));
    });
  }

  
  handleEmpPageClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ empOffset:offset, empPage:page });

    let data5 = {
      "keyWord":this.state.search,
      "pageNo": page,
      "size":this.state.empSize
    }
    // this.props.dispatch(surveyActions.getEmployeeLimitedList(data5));
  }


  handleViewTask=(goal)=>{
    this.setState({crrMainGoalData:goal})
  }

  handelTaskStatus=(check)=>{
    this.setState({taskStatus:check})
  }

  handleViewPlanDetails=(plan,check)=>{
    this.setState({ViewPlanDetailModal:check, crrMainPlanData:plan})
  }


  handleSidebar=(check)=>{
    this.setState({showSidebar:check})
  }

  // showRemarks=(remark)=>{
  //   this.setState({crrRemark:remark})
  // }




  showRemarks=(taskId, check)=>{
    this.setState({crrTaskId:taskId.id, showTaskRemarkModal:check},()=>{

      let data = {
        "id": taskId.id,
        "name": taskId.name,
        "desc": taskId.desc,
        "timeline": taskId.timeline,
        "deadline": taskId.deadline,
        "employeeList":[],
        "startDate":taskId.startDate,
        "endDate":taskId.endDate,
        "remark":taskId.remark
      }

      // ////console.log("taskId------------------>",taskId)

      // if(taskId && taskId.employeeList && taskId.employeeList.length>0){
      //   taskId.employeeList.forEach((emp)=>{
      //     data["employeeList"].push(emp.id)
      //   })
      // }
      
      ////console.log("data------------------>",data)

      this.setState({taskUpdateFormData:data},()=>{
      });

    })

    if(!check){
      let clearData = {
        "id": "",
        "name": "",
        "desc": "",
        "timeline": "",
        "deadline": "",
        "goalId":"",
        "employeeList":[],
        "startDate":"",
        "endDate":"",
        "remark":""
      }
      this.setState({taskUpdateFormData:clearData})
    }

  }

  managerDownloadAPI=()=>{
    let { CurrentSurveyId } = this.state;
    let data = {
      "surveyId":CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.managerDownloadStatus(data));
  }

  handleOverview=()=>{
    this.props.history.goBack()

  }

  render() {
    
    let { dashboard, survey } = this.props;
    let { getEmployeeLimitedListData, getEmployeeLimitedListTotal } = survey;
    let { 

    getIDPLastDateData,
    errorUpdateMsg,
    getGoalListData,
    getGoalListTotal,

    ManagerProfileData,loading,
    getEmployeeActionPlanData,
    SurveyList,

    getOverallScoreBySurveyIdData,

    getDemographicIndexForManagerData,
    getSurveyData,
    EmpFilterData,
    DynaObj,
    getIndexData,
    QuesDynaObj,
    getQuestionReportData,
    QuesMngrHappinessReportObject,
    QuesMngrEngagementReportObject,
    QuesMngrStressReportObject,
    QuesMngrManagerReportObject,

    DriverMngrHappinessReportObject,
    DriverMngrEngagementReportObject,
    DriverMngrStressReportObject,
    DriverMngrManagerReportObject,
    DemographicRestriction,
    getHappinessIndexDriverData,
    getEngagementIndexDriverData,
    getStressIndexDriverData,
    getManagerIndexDriverData,


    getDemographicIndexHappinessData,
    getDemographicIndexEngagementData,
    getDemographicIndexStressData,
    getDemographicIndexManagerData,
    getDemographicIndexLeaderData,
    
    getHappinessQuestionData,
    getHappinessManagerQuestionData,
    getHappinessDMGQuestionData,

    getEngagementQuestionData,
    getEngagementManagerQuestionData,
    getEngagementDMGQuestionData,

    getStressQuestionData,
    getStressManagerQuestionData,
    getStressDMGQuestionData,

    getManagerQuestionData,
    getManagerManagerQuestionData,
    getManagerDMGQuestionData,

    getLeaderQuestionData,
    getLeaderManagerQuestionData,
    getLeaderDMGQuestionData,


    getAllParameterListData,

    getIndexFilterData,

    getMNGRSurveyListData




    } = dashboard;

    let { goalDeleteFormData, showDeleteGoalModal, showTaskRemarkModal, showTaskDeleteModal, errorList, ViewPlanDetailModal, crrMainPlanData, taskStatus, crrMainGoalData, ManagerReportFConfig, ShowScreens, showStartModal, remarkTask, remarkResource, showCompleteModal,showDimensionCards, CurrentSurveyId, CurrentSurveyName, CurrentDimensionId,CurrentDimensionData,CurrentActionId, ShowComponent,
      CurrentActionData,CurrentTaskId,CurrentTaskData,  CurrentResourceId,CurrentResourceData, showCreateGoalModal, crrGoalId, crrTaskId, showUpdateGoalModal, showTaskCompleteModal,showTaskStartModal, showTaskUpdateModal, taskCompleteFormData, showSidebar }= this.state;

    let isAlreadyAssigned = false;
    if(ManagerProfileData && ManagerProfileData.actionPlanStage && ManagerProfileData.actionPlanStage===1){
        isAlreadyAssigned = true;
    }


    let showReport = false;
    if(ManagerProfileData && ManagerProfileData.showMNGRReport){
      showReport = true;
    }

    let isMNGRMoreThan5 = false;
    if(ManagerProfileData && ManagerProfileData.isMNGRMoreThan5){
      isMNGRMoreThan5 = true;
    }


    let IndexDimension = {
      "Happiness":['Time','Being','Relationship','WorkLife','Achivement','Meeningfull'],
      "Engagement":['Pride','Presence','Performance'],
      "Stress":['Recognition','WorkSatisfaction','Inclusion','Collaboration','Empowerment'],
      "Manager":['Connect','ACT','Respect','Empower'],
      "Leader":['Vision','Impact','Execute','Wisdom']
    }

    let { DimensionList} = this.state;


    let validateList = [];

    let validateIndex = getIndexData;
    let validObj = []
    if(validateIndex){
      validObj = Object.keys(validateIndex).map((key)=>[key,validateIndex[key]])
    }
    console.log('validObj: ',validObj);
    if(validObj && validObj.length>0){
      validObj.forEach((item)=>{
        if(item[1]){
          let getIndex = DimensionList.findIndex(prev=>prev.index===parseInt(item[0]));
          if(getIndex!==-1?DimensionList[getIndex].name:""){
            validateList.push({
              "name":getIndex!==-1?DimensionList[getIndex].name:"",
              "template":parseInt(item[0])
            });
          }
  
        }
      })
    }



    
    function GetParameterLabel(name){
      let AllParameterObj = {};
      if(getAllParameterListData && getAllParameterListData.length>0){
        getAllParameterListData.forEach((item)=>{
          AllParameterObj[item.name] = item&&item.label?item.label:item.name;
        });
      }
      return (AllParameterObj&&AllParameterObj[name]?AllParameterObj[name]:name);
    }




    let optionType = 2;
    // if(!loading){
    //   if(isAlreadyAssigned){
    //     optionType = 2;
    //   }
    //   else{
    //     optionType = 1;
    //   }
    // }



    // if(ManagerProfileData && ManagerProfileData.role && (ManagerProfileData.role==="ADMIN")){
    //     this.props.history.push(`/app/survey`)
    // }

    let isCrole=false
    if(ManagerProfileData && ManagerProfileData.role && (ManagerProfileData.role==="CROLE")){
      isCrole= true
    }


    let ParameterList = []
    if(getEmployeeActionPlanData && getEmployeeActionPlanData.length>0){
        getEmployeeActionPlanData.forEach((action)=>{
            if(ParameterList.findIndex(prev=>prev.id===action.parameterId.id)===-1){
                let temp = {
                  ...action.parameterId,
                  score:action.parameterScore
                }
                ParameterList.push(temp);
            }
        })
    }


    let DimensionId = '';
    let DimensionData = '';
    if(CurrentDimensionId){
      DimensionId = CurrentDimensionId;
      DimensionData = CurrentDimensionData;
    }
    else{
      if(ParameterList && ParameterList.length>0){
        DimensionId = ParameterList[0].id;
        DimensionData = ParameterList[0];
      }
    }




    console.log('getEmployeeActionPlanData------------',getEmployeeActionPlanData);
    console.log('errorUpdateMsg----///------------',errorUpdateMsg);


    
    let surveyLogo = "";

    let surveyId =  CurrentSurveyId?CurrentSurveyId:"";

    if(surveyId === "618b673c9503f41bad61667f"){
      surveyLogo = 'img/logo/kotaklifelogo.png';

      // surveyLogo = 'img/logo/tcillogo.jpeg';

    }
    else if(surveyId === "61541d6cf0f59b75a9b50d44"){
      surveyLogo = 'img/logo/tcillogo.jpeg';

      // surveyLogo = 'img/logo/kotaklogo.jpg';

    }
    else if(surveyId === "61b1850ada0e4ed403288e52"){
      surveyLogo = 'img/logo/tcellogo.jpg';
    }
    else if(surveyId === "61c06c871a708fbb5cf1c9a3"){
      surveyLogo = 'img/logo/bppllogo.png';
    }



    let PlanList = []
    if(crrMainGoalData){
      if(getGoalListData && getGoalListData.length>0){
        let getIndex = getGoalListData.findIndex(prev=>prev.id===crrMainGoalData.id)
        if(getIndex!==-1){
          let newPlanList = getGoalListData[getIndex].planList;

          if(taskStatus==="completed"){
            ////console.log("PlanList--------->",PlanList)
            PlanList  = (newPlanList.filter(prev=>prev.isComplete===true)).filter(prev=>(prev.isStarted===true))
            ////console.log("PlanList--------->",PlanList)

          }
          else if(taskStatus==="pending"){
            ////console.log("PlanList--------->",PlanList)

            PlanList  = (newPlanList.filter(prev=>(prev.isComplete===false))).filter(prev=>(prev.isStarted===true))
          }
          else{
            PlanList  = newPlanList
          }



        }
      }
    }





    ////console.log("PlanList---------->",PlanList)



    function getTaskStatus (listPlan){
      let allCount = 0;
      let completedCount = 0;
      if(listPlan && listPlan.length>0){
        allCount = listPlan.length
        completedCount  = (listPlan.filter(prev=>prev.isComplete===true)).filter(prev=>(prev.isStarted===true)).length
      }
      return {
        "completedCount":completedCount,
        "allCount":allCount
      }

    }


    return (
      <>
    
    <LoadingOverlay
      active={loading}
      // active={false}

      spinner
      text='Loading Data...'
      className="loader"
      >


    <div className='bg-gray-50 h-screen'>

    <Header
        ManagerProfileData={ManagerProfileData}
        handleLogout={this.handleLogout}
        handleOverview={this.handleOverview}
    />







      <div className="flex w-full">


        <Sidebar
        currSidebarValue={this.state.ShowScreens}
        handleShowScreen={this.handleShowScreen}
        handleSidebar={this.handleSidebar}
        showSidebar={this.state.showSidebar}
        isCrole={isCrole}
        />



      <main className="flex-1 w-full bg-gray-50  overflow-hidden overflow-y-auto ">




   {ShowScreens==="riskactionplan"?
    <div className="mx-auto  h-full overflow-hidden overflow-y-auto" style={{height: "calc(100% - 4rem)"}}>
    <div className="md:flex justify-between items-center py-4 px-4">
      <div className="flex items-center">
        
        {CurrentActionId?
        <>
          <div className='pl-3 pt-3'><span onClick={()=>this.onSelectAction()} className='cursor-pointer material-symbols-outlined'>arrow_back</span></div>
        </>
        :null}

        <h1 className="text-2xl font-semibold text-gray-500 px-2">{ManagerProfileData && ManagerProfileData.name?ManagerProfileData.name+" (Sense Action Plan)":"Manager Action Plan"}</h1>
      </div>
    
    </div>
{!(getEmployeeActionPlanData && getEmployeeActionPlanData.length>0)?
<>

  <div className='flex justify-center items-center  '>

    <div className="w-full  flex justify-center items-center mt-4">
      <div className="text-center">
        <img src="img/empty_survey.png" className="mb-4" />
        <h1 className="text-gray-800 text-md  font-semibold cursor-default">Action Plan Not Available!</h1>
      </div>
    </div>

  </div>



</>:

<>


{(ParameterList && ParameterList.length>0) || true?
    <>
    <div className="lg:flex p-4 block h-full">

    {CurrentActionId?
    <>
      
      {ShowComponent===1?
        <TaskListComponent
        getManagerActionPlanData={getEmployeeActionPlanData}
        CurrentDimensionData={DimensionData}
        CurrentActionId={CurrentActionId}
        CurrentActionData={CurrentActionData}
        navigate={this.navigate}
        onSelectTask={this.onSelectTask}
        
        />
      :null}

      {ShowComponent===2?
        <ResourceListComponent
        getManagerActionPlanData={getEmployeeActionPlanData}
        CurrentDimensionData={DimensionData}
        CurrentActionId={CurrentActionId}
        CurrentActionData={CurrentActionData}
        navigate={this.navigate}
        onSelectResource={this.onSelectResource}
        />
      :null}

      {ShowComponent===3?
        <TaskDetailsComponent
        getManagerActionPlanData={getEmployeeActionPlanData}
        CurrentDimensionData={DimensionData}
        CurrentActionId={CurrentActionId}
        CurrentActionData={CurrentActionData}
        navigate={this.navigate}
        onSelectTask={this.onSelectTask}
        CurrentTaskId={CurrentTaskId}
        CurrentTaskData={CurrentTaskData}
        onComplete={this.onComplete}
        onStartTask={this.onStartTask}
        />
      :null}

      {ShowComponent===4?
        <ResourceDetailsComponent
        getManagerActionPlanData={getEmployeeActionPlanData}
        CurrentDimensionData={DimensionData}
        CurrentActionId={CurrentActionId}
        CurrentActionData={CurrentActionData}
        navigate={this.navigate}
        onSelectResource={this.onSelectResource}
        CurrentResourceId={CurrentResourceId}
        CurrentResourceData={CurrentResourceData}
        onComplete={this.onComplete}
        />
      :null}

    </>
    :
    <ActionListComponent 
    getManagerActionPlanData={getEmployeeActionPlanData}
      CurrentDimensionId={DimensionId}
      onSelectAction={this.onSelectAction}
    />
    }

    </div>


    </>
    :
    <>
    {!loading?
    <EmptyDimensions/>
    :null}
    </>
    }



</>}


    {showCompleteModal?
      <CompleteModal closeCompleteModal={this.closeCompleteModal} 

      tempData={ShowComponent===3?CurrentTaskData:CurrentResourceData} 
      remarkContent={ShowComponent===3?remarkTask:remarkResource} 

      handleRemark={this.handleRemark} 
      onRemarkCompleteSubmit={this.onRemarkCompleteSubmit}/>
    :null}


    {showStartModal?
      <StartModal closeStartModal={this.closeStartModal} 
      onStartTaskEntry={this.onStartTaskEntry}/>
    :null}




 
    </div>
    :null}

    




  
  
  
  </main>
</div>
</div>
    </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, survey } = state;
  return {
    loggingIn,
    users,
    dashboard,
    survey
  };
}
export default connect(mapStateToProps)(Dashboard);
