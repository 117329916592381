// import { dataTool } from 'echarts';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { TbChevronRight } from 'react-icons/tb';


class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }

  render() {
    let { getManagerActionPlanData, CurrentActionId, navigate, CurrentActionData, onSelectResource, CurrentDimensionData } = this.props;

    let ResourceList = [];
    if (getManagerActionPlanData && getManagerActionPlanData.length > 0) {
      getManagerActionPlanData.forEach((action) => {
        if (action && action.id === CurrentActionId) {
          if (action && action.ResourceList && action.ResourceList.length > 0) {
            ResourceList = action.ResourceList;
          }
        }
      });
    }


    function taskStatusFn(task) {
      //1: start
      //2: pending
      //3: completed

      let taskStatus = 1
      if (task && task.isComplete) {
        taskStatus = 2;
      }
      return taskStatus
    }


    function colorCodeFn(task) {
      let color = "#757575";
      if (taskStatusFn(task) === 1) {
        color = "#0083db";
      }
      else if (taskStatusFn(task) === 2) {
        color = "#02bd5f";
      }
      return color
    }

    // getManagerActionPlanData,currentParameterId

    console.log('ResourceList: ', ResourceList);

    return (

      <div className="w-full  lg:mt-0 mt-4 mb-4  bg-white border p-6 lg:col-span-2 rounded-lg space-y-6">
        {/* indicators */}
        <div className="text-base text-[#3D405B] flex items-center space-x-2 ">
          <p className="cursor-pointer"
            onClick={() => navigate("actionlist")}>{CurrentDimensionData && CurrentDimensionData.name ? CurrentDimensionData.name : ""}</p>
          <TbChevronRight />
          <p className="text-blue-500 cursor-pointer">{CurrentActionData && CurrentActionData.name ? CurrentActionData.name : ""}</p>
        </div>

        {/* upperbox */}
        <div className="bg-gradient-to-l from-[#62cff4] to-[#2c67f2] flex p-6 rounded-lg border space-x-4">
          <div>
            <div className="bg-white/10 w-14 h-14 rounded-lg flex items-center justify-center" ><span className="w-8 text-white material-symbols-outlined" style={{ fontSize: '2rem' }}>group</span></div></div>
          {/* right */}
          <div className=" ">
            <h1 className="text-xl font-medium text-[#fff]">{CurrentActionData && CurrentActionData.name ? CurrentActionData.name : ""}</h1>
            <p className="text-sm text-[#fff]/80">
              {CurrentActionData && CurrentActionData.desc ? CurrentActionData.desc : ""}
              {/* <span>View More</span> */}
            </p>
          </div>
        </div>

        {/* tabs */}
        <div className="flex items-center text-xs text-gray-500 border-b space-x-2">

          <span onClick={() => navigate("tasklist")} className="hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border ">Tasks</span>
          <span onClick={() => navigate("resourcelist")} className="cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] ">Resources</span>
        </div>

        {/* a line */}
        <p className="text-base font-medium flex items-center text-[#3D405B]">To improve {CurrentActionData && CurrentActionData.name ? CurrentActionData.name : ""} follow the below resources.</p>
        {/* boxes */}
        <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 ">

          {ResourceList && ResourceList.length > 0 ?
            ResourceList.map((resource, index) =>

              <div style={{
                borderWidth: '0px',
                borderColor: colorCodeFn(resource)
              }} onClick={() => onSelectResource(resource, index + 1)} className="cursor-pointer bg-white p-4 rounded-lg border">
                <h1 className="text-base font-medium text-[#3D405B] ">{resource && resource.name ? (resource.name.length > 70) ? resource.name.slice(0, 70) + "..." : resource.name : ""}</h1>
                <div className=" text-sm text-[#3D405B]/70 items-center justify-between">

                  {taskStatusFn(resource) === 1 ?
                    <>
                      <p>Not started yet</p>
                    </>
                    : null}
                  {taskStatusFn(resource) === 2 ?
                    <>
                      <p>{(resource && resource.date) ? "Complete On " + moment.unix(resource.date).format("DD MMM, YYYY") : ""}</p>
                    </>
                    : null}
                </div>
                {/* loader */}
                <div className="h-1 bg-white rounded-full my-2 bg-opacity-50 w-full overflow-hidden">
                  {resource.isComplete ? <div className="bg-white w-full h-1" /> : <div className="bg-white w-0 h-1" />}
                </div>

                <span className="flex">
                  <span style={{
                    color: colorCodeFn(resource)
                  }} className="text-xs block mt-6 font-semibold">

                    {resource.isComplete ? "COMPLETED" :
                      "LET'S START"
                    }
                  </span>
                  {resource.isComplete ? <span className="material-symbols-outlined text-green-400 mx-2 mt-5">check_circle</span> : null}
                </span>


              </div>
            )
            : null}

        </div>
      </div>


    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dimension);
