import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList: [],
      EmpIndexA: 0,
      EmpIndexB: 4
    }
  }
  componentDidMount() {

  }

  render() {
    let { AnswerObj, name, templateId, size, questionName, index } = this.props;

    function ColorFn(key) {
      if (templateId === 11) {
        return { color: key === "Yes" ? '#00cc66' : '#ff5050' }
      }
      else {
        return {}
      }
    }

    let total = 0;
    let DataList = []
    if (AnswerObj) {
      Object.keys(AnswerObj).forEach(key => {
        let score = parseInt(AnswerObj[key]);
        total += score;
        DataList.push({
          name: key,
          value: score,
          itemStyle: ColorFn(key)
        });
      });
    }

    let NewDataList = [];
    if (DataList && DataList.length > 0) {
      DataList.forEach((item) => {
        NewDataList.push({
          name: item.name,
          number: item && item.name && (item.name === "Yes") ? 1 : 2,
          value: ((item.value * 100) / total).toFixed(2),
          itemStyle: item.itemStyle
        })
      })
    }


    NewDataList.sort((a, b) => a.number - b.number)

    // console.log('AnswerObj: ',AnswerObj)
    // console.log('DataList: ',DataList)
    // console.log('NewDataList: ',NewDataList)

    // [
    //   {value: 1, name: '7'},
    //   {value: 2, name: '2'},
    //   {value: 3, name: '9'},
    //   {value: 4, name: '10'},
    //   {value: 5, name: '3'}
    // ]
    let { stage } = this.props;

    return (
      <>
        <div className='relative flex flex-col justify-between h-full'>
          <div className="flex justify-between pb-2">
            <div className="relative inline-block dropdown">
              <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] line-clamp-2">
                {questionName && questionName.length > 30 ? (index === -1 ? "" : ((index + 1) + ") ")) + questionName.slice(0, 30) + "..." : (index === -1 ? "" : ((index + 1) + ") ")) + questionName}</h1>
              <div className="absolute top-0 z-30 hidden p-4 pt-1 text-sm font-semibold bg-white border rounded-md shadow-md tooltipcard dropdown-menu -left-4">
                {(index === -1 ? "" : ((index + 1) + ") ")) + questionName}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between mt-24 ">
            {/*  circle     */}
            <div className="w-7/12 -mt-8 " >
              <ReactEcharts
                style={{ height: size === 2 ? "250px" : "170px" }}
                option={{
                  tooltip: {
                    trigger: 'item'
                  },
                  // legend: {
                  //     top: '5%',
                  //     left: 'center'
                  // },
                  series: [
                    {
                      name: name ? name : "Responses",
                      type: 'pie',
                      radius: ['40%', '70%'],
                      avoidLabelOverlap: false,
                      label: {
                        show: false,
                        position: 'center'
                      },
                      // emphasis: {
                      //     label: {
                      //         show: true,
                      //         fontSize: '40',
                      //         fontWeight: 'bold'
                      //     }
                      // },
                      labelLine: {
                        show: false
                      },
                      data: NewDataList
                    }
                  ]
                }}

              />
            </div>


            <div className="mr-20 -mt-8">
              {NewDataList && NewDataList.length > 0 ?
                NewDataList.map((item, index) =>
                  <div key={index} className="flex items-center text-lg"> <div style={{ background: item.itemStyle.color }} className="bg-green-400 p-1.5 mr-2" />
                    <p className="w-12 text-gray-600">{item.name}</p> <p>{item.value + "%"}</p></div>
                ) : null}
            </div>



          </div>

          {stage !== 2 ?
            <>
              <div class="flex justify-center p-4 mt-auto">
                <button class=" text-[#2196f3]  cursor-pointer transition duration-150 flex items-center uppercase font-medium xl:text-base text-sm ">{stage == 2 ? "View Details" : (stage == 3 ? "" : "View More")}<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right ml-2" width="34" height="34" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2196f3" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="5" y1="12" x2="19" y2="12"></line><line x1="15" y1="16" x2="19" y2="12"></line><line x1="15" y1="8" x2="19" y2="12"></line></svg>
                </button>
              </div>
            </>
            : null}

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(PieChart);
