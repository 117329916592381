// import { dataTool } from "echarts";
import React from "react";
// import Pagination from "material-ui-flat-pagination";

export default function CompleteModal(props) {
  let { errorList,  submitCreateTask, closeCompleteModal,taskFormData, handleTaskInput} = props;

  ////console.log("errorList----------------------->",errorList)
  return (
   <>
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-xl mx-auto md:relative absolute left-0 right-0 overflow-hidden mt-20">
         
          <div className="  shadow w-full rounded-lg bg-white overflow-hidden block">
            
          <div className="flex justify-between items-center w-full border-b p-4 px-6">
              <h2 className="font-medium text-lg   text-gray-800 capitalize ">Create Task</h2>
              <div onClick={()=>closeCompleteModal("")} className=" rounded-full p-2  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>
           </div>
            <div className="w-full p-6 py-4">
            
            {/* <p className=" text-base mb-6 text-gray-700 border-b pb-2 text-center"></p> */}

            <div className="space-y-2" style={{height:'250px',overflowY:'auto'}}>

            <div className="text-sm space-y-2"> 
          <label>Task Name:</label>
            <div className="mb-2 text-xs">
              <input type="text" name="name" value={taskFormData && taskFormData.name?taskFormData.name:""} onChange={handleTaskInput} placeholder="Task Name.." className="p-2 w-full border border-gray-300 outline-none rounded-md" />
            </div>
          </div>

            <div className="text-sm space-y-2">
            <label>Task Description:</label>
            <div className="mb-2 text-xs">
              <input type="text" name="desc" value={taskFormData && taskFormData.desc?taskFormData.desc:""} onChange={handleTaskInput} placeholder="Task Description.." className="p-2 w-full border border-gray-300 outline-none rounded-md" />
            </div>
            </div>

           {/* <div className="text-sm space-y-2">
           <label>Task Timeline:-</label>
            <div className="mb-2 text-xs">
              <input type="text" name="timeline" value={taskFormData && taskFormData.timeline?taskFormData.timeline:""} onChange={handleTaskInput} placeholder="Task Timeline.." className="p-2 w-full border border-gray-300 outline-none rounded-md" />
            </div>
           </div> */}

            <div className="text-sm space-y-2">
            <label>Task Target Date:</label>
            <div className="mb-2 text-xs">
              <input type="date" name="deadline" value={taskFormData && taskFormData.deadline?taskFormData.deadline:""} onChange={handleTaskInput} placeholder="Task Deadline.." className="p-2 w-full border border-gray-300 outline-none rounded-md" />
            </div>
            </div>

            </div>
            <div className="text-red-500 text-xs pb-2 text-center">{errorList && errorList.length>0?"Please enter "+errorList.map((element)=>(element)).join(", "):null}</div>

            <div className=" text-center mb-4">
              <button onClick={()=>submitCreateTask()} type="button" className="w-full bg-blue-500 hover:bg-opacity-90 transition duration-150 text-white font-medium py-2 px-6  rounded-md  " >
                Create Task
              </button> 
            </div>

            </div>


        {/* <div className="w-full  rounded-md md:p-6 p-4 space-y-4 overflow-y-auto">
        
        <div className="flex justify-between">
            <h2 className=" text-xl ">Employees list</h2>
            <input type="text" value={search} onChange={handleSearch} className="px-2 py-1 border rounded-md text-sm" placeholder="search by name.."/>
          </div>

        <div className="flex flex-col  ">
          <div className=" overflow-x-auto     ">
            <div className="align-middle inline-block min-w-full overflow-hidden">
              <table className="min-w-full whitespace-nowrap">
                <thead>
                  <tr className="bg-white">
                  <th className="px-4 py-3 border-b border-gray-200  text-left text-xs leading-4 font-medium text-slate-800 tracking-wider">
                      <div className="ml-6">Name</div></th>
                    <th className="px-6 py-3 border-b border-gray-200  text-left text-xs leading-4 font-medium text-slate-800 tracking-wider">
                      Email Address</th>
                  </tr>
                </thead>
                <tbody className="bg-white">


                {EmployeesList && EmployeesList.length>0?
                  EmployeesList.map((item,index)=>

                  <tr>
                    <td className="px-4 py-4   border-b border-gray-200">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 ">
                          <input type="checkbox" value={item.id} checked={taskFormData["employeeList"].some(prev => prev === item.id)?true:false} onClick={() => handleSelectEmployees(item.id)} onChange={()=>{}} />
                        </div>
                        <div className="ml-4">
                          <div className="text-xs leading-5 text-gray-500">{(item && item.name?item.name:'Not Available').slice(0,15)}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4   border-b border-gray-200">
                      <div className="leading-5 text-gray-500 text-xs">{item && item.email?item.email:'Not Available'}</div>
                    </td>
                  </tr>

                  ):null}

                </tbody>
              </table>
            </div>
          </div>

          {EmployeesListTotal && EmployeesListTotal > 0 ?
          <div className='bg-white p-4 flex justify-center items-center'>
            <Pagination
              limit={empSize}
              offset={empOffset}
              total={EmployeesListTotal}
              onClick={(e, offset, empPage) => handleEmpPageClick(offset, empPage)}
            />
            </div>
          : null}



        </div>


           </div> */}



          </div>




        </div>
        </div>
   </>
  );
}
