import React from "react";
import moment from "moment";

export default function SurveyList(props) {
  let {
    cardImage,
    cardColor,
    categoryType,
    surveyModule,
    isLifecycle,
    crrLinkedSurveyId,
    selectLinkedSurvey,
    crrLinkedCluster,
    selectLinkedCluster,
    clusterAnalysisData,
    loadCluster,

    CLIENT_ID,
    CLIENT_ROLE,
    indexA,
    indexB,
    accessAllow,
    onOptionDot,
    crrSurvey,
    getSurveyListData,
    onDashboard,
    onEditSurvey,
    onReportSurvey,
    onPublishSurvey,
    onDeleteSurvey,
    LanguageObj,
    CurrentLanguage,
    onUpdateSurvey,
    onNoDashboardModal,
    isRiskSurvey,
    showGrid,
    onCopySurvey,
    onFilterSurvey
  } = props;

  //console.log('getSurveyListData CLIENT_ROLE:', CLIENT_ROLE);

  let [showFilter, handleOpenFilter] = React.useState(false);
  let [showFilter2, handleOpenFilter2] = React.useState(false);

  // console.log("isLifecycle----sss----->", isLifecycle);

  function AllowChange(survey) {
    if (CLIENT_ROLE === "ADMIN") {
      return true;
    }
    if (
      survey &&
      survey.roles &&
      survey.roles.length > 0 &&
      ((survey.roles[0] && survey.roles[0] === "ADMIN") ||
        (survey.roles[1] && survey.roles[1] === "ADMIN"))
    ) {
      return false;
    } else {
      return true;
    }
  }

  function ShowUserSurvey(survey) {
    if (CLIENT_ROLE === "ADMIN") {
      if (
        survey &&
        survey.roles &&
        survey.roles.length === 1 &&
        survey.roles[0] &&
        survey.roles[0] === "CROLE"
      ) {
        return false;
      } else if (
        survey &&
        survey.roles &&
        survey.roles.length > 0 &&
        ((survey.roles[0] && survey.roles[0] === "ADMIN") ||
          (survey.roles[1] && survey.roles[1] === "ADMIN"))
      ) {
        return true;
      } else {
        //can comment
        return true;
      }
    } else if (CLIENT_ROLE === "CROLE" && CLIENT_ID === survey.createdBy) {
      return true;
    } else if (
      survey &&
      survey.roles &&
      survey.roles.length > 0 &&
      ((survey.roles[0] && survey.roles[0] === "ADMIN") ||
        (survey.roles[1] && survey.roles[1] === "ADMIN"))
    ) {
      return true;
    } else {
      return false;
    }
  }

  //console.log('showFilter-------------------->', showFilter)

  let LabelList = ["Detached", "Low Attached", "Attached", "Highly Attached"];

  let newClusterList = [];
  if (clusterAnalysisData && clusterAnalysisData.length > 0) {
    clusterAnalysisData.forEach((item, indexZ) => {
      newClusterList.push({
        clusterName: LabelList[indexZ],
        clusterNumber: item[0],
        clusterSize: item[1],
        clusterCenter: item[2],
        clusterList: item[3],
      });
    });
  }

  // console.log("getSurveyListData--A-->", getSurveyListData);

  function getImageLink(isLifecycle, survey, cardImage) {

    let isClosed = false;
    if (survey.isExpire) {
      isClosed = true;
    } else if (moment(moment.unix(survey.expireTime / 1000).format("YYYY-MM-DD")
    ).isBefore(moment().format("YYYY-MM-DD"))) {
      isClosed = true;
    }


    if (isLifecycle) {  // Lifecycle img
      let imageLink = "/img/surveycard/" + (isClosed ? survey.name.toLowerCase() + "_grey.png" : survey.name.toLowerCase() + ".png");
      // console.log("imageLink--A-->", imageLink);
      return imageLink
    } else if (cardImage) {
      return cardImage
    } else {
      let imageLink = "/img/surveycard/" + (isClosed ? "30 days survey" + "_grey.png" : "30 days survey" + ".png");
      return imageLink
    }
  }



  function getColorCode(isLifecycle, survey, cardColor) {

    let isClosed = false;
    if (survey.isExpire) {
      isClosed = true;
    } else if (moment(moment.unix(survey.expireTime / 1000).format("YYYY-MM-DD")
    ).isBefore(moment().format("YYYY-MM-DD"))) {
      isClosed = true;
    }
    if (isLifecycle) {
      let imageLink = (isClosed ? "#F5F5F5" : "#E9F5FE");
      return imageLink
    } else if (cardColor) {
      return cardColor
    } else {
      let imageLink = (isClosed ? "#F5F5F5" : "#E9F5FE");
      return imageLink
    }
  }



  return (
    <>
      {getSurveyListData && getSurveyListData.length > 0
        ? getSurveyListData.map((survey, index) =>
          ShowUserSurvey(survey) && survey.surveyModule < 1 ? (
            <>
              {showGrid ? (
                <div className="capitalize transition-all duration-150 bg-white border cursor-pointer rounded-2xl group hover:shadow-sm ">
                  {/* grid */}
                  <div
                    className="relative overflow-hidden " >
                    <div className="relative flex justify-center rounded-b-none rounded-2xl"
                      style={{
                        background: getColorCode(isLifecycle, survey, cardColor)
                      }}
                    >
                      <div className="absolute top-0 left-0 flex items-center">
                        {survey.isExpire ? (
                          <b className="bg-[#F44336] text-white rounded-tl-2xl rounded-br-2xl  px-4 font-medium py-1.5 text-sm capitalize">
                            closed
                          </b>
                        ) : (
                          <>
                            {moment(moment.unix(survey.expireTime / 1000).format("YYYY-MM-DD")
                            ).isBefore(moment().format("YYYY-MM-DD")) ? (

                              <b className="bg-[#F44336] text-white rounded-tl-2xl rounded-br-2xl  px-4 font-medium py-1.5 text-sm capitalize">closed</b>

                            ) : (
                              <b className="bg-[#00CD7E] text-white rounded-tl-2xl rounded-br-2xl  px-4 font-medium py-1.5 text-sm capitalize">open</b>
                            )}
                          </>
                        )}
                      </div>
                      <img
                        alt="lifecycle_img"
                        src={getImageLink(isLifecycle, survey, cardImage)}
                        className="2xl:h-48 h-44"
                      />
                      <div className="absolute flex items-center justify-center invisible w-10 h-10 transition-all duration-200 bg-white rounded-full top-2 right-2 group-hover:visible ">

                        <div className="relative inline-block dropdown">
                          {AllowChange(survey) ? (
                            <span
                              onClick={() => onOptionDot(survey)}
                              className="cursor-pointer p-1 material-symbols-outlined text-gray-600 rounded-full text-[1.5rem]"
                            >
                              more_vert
                            </span>
                          ) : null}


                          {crrSurvey === survey.id && AllowChange(survey) ? (
                            <div className="absolute z-30 w-24 overflow-hidden text-xs text-gray-500 bg-white border shadow-lg dropdown-menu right-2 top-6">
                              {/* <span onClick={() => onPreviewSurvey(survey)}  className="block px-4 py-2 hover:bg-blue-50 hover:text-blue-500">Preview</span>  */}
                              {accessAllow("uploadUser") &&
                                AllowChange(survey) ? (
                                <span
                                  onClick={() => onUpdateSurvey(survey)}
                                  className="block px-4 py-2 hover:bg-blue-50 hover:text-blue-500"
                                >
                                  Access
                                </span>
                              ) : null}

                              {AllowChange(survey) ? (
                                <span
                                  onClick={() => onDeleteSurvey(survey)}
                                  className="block px-4 py-2 hover:bg-blue-50 hover:text-blue-500"
                                >
                                  Delete
                                </span>
                              ) : null}

                              {AllowChange(survey) ? (
                                <span
                                  onClick={() => onCopySurvey(survey)}
                                  className="block px-4 py-2 hover:bg-blue-50 hover:text-blue-500"
                                >
                                  Copy
                                </span>
                              ) : null}

                              {AllowChange(survey) ? (
                                <span
                                  onClick={() => onFilterSurvey(survey)}
                                  className="block px-4 py-2 hover:bg-blue-50 hover:text-blue-500"
                                >
                                  Filter
                                </span>
                              ) : null}



                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="p-6 space-y-6 ">
                    <div className="space-y-2 ">
                      <h1 className="flex flex-wrap gap-2 text-base font-semibold text-[#3D405B] line-clamp-2">{survey.name}</h1>
                    </div>

                    <div className="flex justify-between space-x-4">
                      <div className="space-y-1">
                        <p className="text-xs font-medium text-[#3D405B]/80 ">Created </p>
                        <p className="text-sm text-[#3D405B] font-medium ">
                          {moment(new Date(parseInt(survey.createdAt)))
                            .utcOffset("+05:30")
                            .format("DD MMM, YYYY")}
                        </p>
                      </div>

                      {!isLifecycle ? (
                        <>
                          <div className="space-y-1">
                            <p className="text-xs font-medium text-[#3D405B]/80 ">
                              {survey.closedDate ? "Closed" : "Expiry"}
                            </p>
                            <p className="text-sm text-[#3D405B] font-medium">
                              {survey.closedDate
                                ? moment
                                  .unix(survey.closedDate / 1000)
                                  .format("DD MMM, YYYY")
                                : moment
                                  .unix(survey.expireTime / 1000)
                                  .format("DD MMM, YYYY")}
                            </p>
                          </div>
                        </>
                      ) : null}
                    </div>

                    {survey.templateId === 6 ? (
                      <div className="my-3">
                        {survey &&
                          survey.linkedSurveyId &&
                          survey.linkedSurveyId.name ? (
                          <div className="flex justify-between py-1">
                            <div className="cursor-pointer bg-gray-100 border truncate flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5 w-full">
                              <span className="w-10/12 text-xs text-gray-800 ">
                                {survey &&
                                  survey.linkedSurveyId &&
                                  survey.linkedSurveyId.name
                                  ? survey.linkedSurveyId.name
                                  : "No Linked Survey"}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="cursor-pointer bg-gray-100 border flex justify-between px-4 items-center relative py-1 rounded-full space-x-1.5">
                            <span
                              onClick={() => handleOpenFilter(!showFilter)}
                              className="w-32 text-xs text-gray-500 truncate "
                            >
                              {crrLinkedSurveyId && crrLinkedSurveyId.name
                                ? crrLinkedSurveyId.name
                                : "Select Survey"}
                            </span>
                            <span
                              onClick={() => handleOpenFilter(!showFilter)}
                              className="text-gray-500 material-symbols-outlined "
                            >
                              arrow_drop_down
                            </span>

                            {showFilter ? (
                              <div
                                className="absolute w-48 px-3 py-1 font-normal bg-white shadow-lg z-96 -left-2 top-10 font-xs"
                                id="chatbot"
                                style={{
                                  overflowY: "auto",
                                  height: "90px",
                                  zIndex: 120,
                                }}
                              >
                                {getSurveyListData &&
                                  getSurveyListData.length > 0
                                  ? getSurveyListData.map(
                                    (
                                      innerSurveyDetails,
                                      innerSurveyIndex
                                    ) =>
                                      innerSurveyDetails.templateId ===
                                        1 ? (
                                        <div
                                          key={innerSurveyIndex}
                                          className="flex items-center py-1 m-1"
                                          onClick={() => {
                                            selectLinkedSurvey(
                                              innerSurveyDetails,
                                              survey,
                                              true
                                            );
                                            handleOpenFilter(!showFilter);
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            checked={
                                              crrLinkedSurveyId.id ===
                                                innerSurveyDetails.id
                                                ? true
                                                : false
                                            }
                                            onChange={() => { }}
                                          />
                                          <label className="ml-2 text-sm cursor-pointer text-[#3D405B] ">
                                            {innerSurveyDetails.name}
                                          </label>
                                        </div>
                                      ) : null
                                  )
                                  : null}
                              </div>
                            ) : null}
                          </div>
                        )}

                        {survey && survey.linkedClusterName ? (
                          <div className="flex justify-between py-1">
                            <div className="cursor-pointer bg-blue-50 border truncate flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5 w-full">
                              <span className="w-10/12 text-xs text-gray-500 ">
                                {survey && survey.linkedClusterName
                                  ? survey.linkedClusterName
                                  : "No Linked Cluster"}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="my-0">
                            <div className="flex justify-between py-1">
                              <div className="cursor-pointer bg-gray-100 border flex justify-between px-4 items-center relative py-1 rounded-full space-x-1.5">
                                <span
                                  onClick={() => {
                                    handleOpenFilter2(!showFilter2);
                                    loadCluster(survey);
                                  }}
                                  className="w-32 text-xs text-gray-500 truncate "
                                >
                                  {crrLinkedCluster &&
                                    crrLinkedCluster.clusterName
                                    ? crrLinkedCluster.clusterName
                                    : "Select Cluster"}
                                </span>
                                <span
                                  onClick={() => {
                                    handleOpenFilter2(!showFilter2);
                                    loadCluster(survey);
                                  }}
                                  className="text-gray-500 material-symbols-outlined "
                                >
                                  arrow_drop_down
                                </span>

                                {showFilter2 &&
                                  newClusterList &&
                                  newClusterList.length > 0 ? (
                                  <div
                                    className="absolute w-48 px-3 py-1 font-normal bg-white shadow-lg z-96 -left-2 top-10 font-xs"
                                    id="chatbot"
                                    style={{
                                      overflowY: "auto",
                                      height: "120px",
                                      zIndex: 120,
                                    }}
                                  >
                                    {newClusterList &&
                                      newClusterList.length > 0
                                      ? newClusterList.map(
                                        (item, innerSurveyIndex) => (
                                          <div
                                            key={innerSurveyIndex}
                                            className="flex items-center py-1 m-1"
                                            onClick={() => {
                                              selectLinkedCluster(
                                                item,
                                                survey,
                                                true
                                              );
                                              handleOpenFilter2(
                                                !showFilter2
                                              );
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              checked={
                                                crrLinkedCluster.clusterName ===
                                                  item.clusterName
                                                  ? true
                                                  : false
                                              }
                                              onChange={() => { }}
                                            />
                                            <label className="ml-2 text-sm cursor-pointer text-[#3D405B] ">
                                              {item && item.clusterName
                                                ? item.clusterName
                                                : "NaN"}
                                            </label>
                                          </div>
                                        )
                                      )
                                      : null}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className=""></div>
                    )}

                    <div className="flex items-center justify-between ">
                      {accessAllow("createSurvey") ? (
                        <>
                          <div
                            onClick={() => onEditSurvey(survey)}
                            className="cursor-pointer relative bg-[#F1F5F9] hover:bg-[#2196F3]/10 hover:text-[#2196f3]  rounded-full w-12 h-12 flex items-center justify-center transition duration-150 tooltipshow uppercase text-[#3D405B]/70"
                          >
                            <span className="material-symbols-rounded text-[1.5rem]">
                              edit_square
                            </span>
                            <span className="absolute p-2 px-4 text-sm bg-white border rounded-md tooltipcard -top-10 text-slate-800 whitespace-nowrap ">
                              {LanguageObj &&
                                LanguageObj[
                                "edit_survey_label_" + CurrentLanguage
                                ]
                                ? LanguageObj[
                                "edit_survey_label_" + CurrentLanguage
                                ]
                                : "View Question"}
                            </span>
                          </div>
                        </>
                      ) : null}

                      {accessAllow("responseAnalytics") ? (
                        <>
                          <div
                            onClick={() => onReportSurvey(survey)}
                            className="cursor-pointer relative bg-[#F1F5F9] hover:bg-[#2196F3]/10 hover:text-[#2196f3]  rounded-full w-12 h-12 flex items-center justify-center transition duration-150 tooltipshow uppercase text-[#3D405B]/70"
                          >
                            <span className="material-symbols-rounded text-[1.5rem]">
                              signal_cellular_alt
                            </span>
                            <span className="absolute p-2 px-4 text-sm bg-white border rounded-md tooltipcard -top-10 text-slate-800 whitespace-nowrap ">
                              {LanguageObj &&
                                LanguageObj[
                                "response_analytics_label_" + CurrentLanguage
                                ]
                                ? LanguageObj[
                                "response_analytics_label_" +
                                CurrentLanguage
                                ]
                                : "Response Analytics"}
                            </span>
                          </div>
                        </>
                      ) : null}

                      {survey && survey.showDashboard && !isLifecycle ? (
                        <>
                          {accessAllow("dashboard") ? (
                            <>
                              <div
                                onClick={() =>
                                  onDashboard(survey, categoryType)
                                }
                                className="cursor-pointer relative bg-[#F1F5F9] hover:bg-[#2196F3]/10 hover:text-[#2196f3]  rounded-full w-12 h-12 flex items-center justify-center transition duration-150 tooltipshow uppercase text-[#3D405B]/70"
                              >
                                <span className="material-symbols-rounded text-[1.5rem]">
                                  dashboard
                                </span>
                                <span className="absolute p-2 px-4 text-sm bg-white border rounded-md tooltipcard -top-10 text-slate-800 whitespace-nowrap ">
                                  {LanguageObj &&
                                    LanguageObj[
                                    "dashboard_label_" + CurrentLanguage
                                    ]
                                    ? LanguageObj[
                                    "dashboard_label_" + CurrentLanguage
                                    ]
                                    : "Dashboard"}
                                </span>
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div
                            onClick={() => onNoDashboardModal(true, survey)}
                            className="cursor-pointer relative bg-[#F1F5F9] hover:bg-[#2196F3]/10 hover:text-[#2196f3]  rounded-full w-12 h-12 flex items-center justify-center transition duration-150 tooltipshow uppercase text-[#3D405B]/70"
                          >
                            <span className="material-symbols-rounded text-[1.5rem]">
                              dashboard
                            </span>
                            <span className="absolute p-2 px-4 text-sm bg-white border rounded-md tooltipcard -top-10 text-slate-800 whitespace-nowrap ">
                              {LanguageObj &&
                                LanguageObj[
                                "dashboard_label_" + CurrentLanguage
                                ]
                                ? LanguageObj[
                                "dashboard_label_" + CurrentLanguage
                                ]
                                : "Dashboard"}
                            </span>
                          </div>
                        </>
                      )}

                      {accessAllow("publishSurvey") ? (
                        <>
                          {isRiskSurvey ? (
                            <div
                              onClick={() =>
                                onPublishSurvey(
                                  survey,
                                  6,
                                  survey &&
                                    survey.linkedSurveyId &&
                                    survey.linkedSurveyId.id
                                    ? survey.linkedSurveyId
                                    : ""
                                )
                              }
                              className="cursor-pointer relative bg-[#F1F5F9] hover:bg-[#2196F3]/10 hover:text-[#2196f3]  rounded-full w-12 h-12 flex items-center justify-center transition duration-150 tooltipshow uppercase text-[#3D405B]/70"
                            >
                              <span className="material-symbols-rounded text-[1.5rem]">
                                mail
                              </span>
                              <span className="absolute p-2 px-4 text-sm bg-white border rounded-md tooltipcard -top-10 text-slate-800 whitespace-nowrap ">
                                {LanguageObj &&
                                  LanguageObj[
                                  "campaign_label_" + CurrentLanguage
                                  ]
                                  ? LanguageObj[
                                  "campaign_label_" + CurrentLanguage
                                  ]
                                  : "Launch"}{" "}
                              </span>
                            </div>
                          ) : (
                            <div
                              onClick={() =>
                                onPublishSurvey(survey, "", "", surveyModule)
                              }
                              className="cursor-pointer relative bg-[#F1F5F9] hover:bg-[#2196F3]/10 hover:text-[#2196f3]  rounded-full w-12 h-12 flex items-center justify-center transition duration-150 tooltipshow uppercase text-[#3D405B]/70"
                            >
                              <span className="material-symbols-rounded text-[1.5rem]">
                                mail
                              </span>
                              <span className="absolute p-2 px-4 text-sm bg-white border rounded-md tooltipcard -top-10 text-slate-800 whitespace-nowrap ">
                                {LanguageObj &&
                                  LanguageObj[
                                  "campaign_label_" + CurrentLanguage
                                  ]
                                  ? LanguageObj[
                                  "campaign_label_" + CurrentLanguage
                                  ]
                                  : "Launch"}{" "}
                              </span>
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) :
                (
                  <div className="flex items-center justify-between w-full p-8 capitalize transition-all duration-200 bg-white border rounded-lg cursor-pointer group hover:shadow-sm">
                    {/* list view */}
                    <div className="flex items-center space-x-10 ">
                      <div className="flex justify-center bg-blue-500 rounded-lg w-28 " style={{ background: isLifecycle ? "#EFD2E0" : cardColor ? cardColor : "#D0F1F8", }}>
                        <img alt="bgimage" src={isLifecycle ? "/img/lifecycle_img.png" : cardImage ? cardImage : "/img/employee_listening_platform.png"} className="w-28 h-28" />
                      </div>
                      <div className="flex-1 w-full space-y-2">
                        <h1 className="flex flex-wrap gap-2 text-base font-semibold text-gray-800 line-clamp-2 w-60">
                          {survey.name}
                        </h1>
                        <div className="flex items-center ">
                          {survey.isExpire ? (
                            <b className="bg-red-100 text-red-500 rounded-sm px-2.5 font-normal py-1 text-sm capitalize  ">
                              closed
                            </b>
                          ) : (
                            <>
                              {moment(
                                moment
                                  .unix(survey.expireTime / 1000)
                                  .format("YYYY-MM-DD")
                              ).isBefore(moment().format("YYYY-MM-DD")) ? (
                                <>
                                  <b className="bg-red-100 text-red-500 rounded-sm px-2.5 font-normal py-1 text-sm capitalize  ">
                                    closed
                                  </b>
                                </>
                              ) : (
                                <b className="bg-green-100 text-green-500 rounded-sm px-2.5 font-normal py-1 text-sm capitalize  ">
                                  open
                                </b>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center justify-end space-x-10 ">
                      <div className="flex space-x-10 w-fit">

                        <div className="space-y-2">
                          <p className="text-sm font-medium text-gray-500 ">
                            Created:{" "}
                          </p>
                          <p className="text-base font-medium text-gray-800 ">
                            {moment(new Date(parseInt(survey.createdAt)))
                              .utcOffset("+05:30")
                              .format("DD MMM, YYYY")}
                          </p>
                        </div>

                        {!isLifecycle ? (

                          <div className="space-y-2">
                            <p className="text-sm font-medium text-gray-500 ">
                              {survey.closedDate ? "Closed" : "Expiry"}
                            </p>
                            <p className="text-base font-medium text-gray-800 ">
                              {survey.closedDate
                                ? moment
                                  .unix(survey.closedDate / 1000)
                                  .format("DD MMM, YYYY")
                                : moment
                                  .unix(survey.expireTime / 1000)
                                  .format("DD MMM, YYYY")}
                            </p>
                          </div>

                        ) : null}
                      </div>

                      {survey.templateId === 6 ? (
                        <div className="my-3">
                          {survey &&
                            survey.linkedSurveyId &&
                            survey.linkedSurveyId.name ? (
                            <div className="flex justify-between py-1">
                              <div className="cursor-pointer bg-gray-100 border truncate flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5 w-full">
                                <span className="w-10/12 text-xs text-gray-800 ">
                                  {survey &&
                                    survey.linkedSurveyId &&
                                    survey.linkedSurveyId.name
                                    ? survey.linkedSurveyId.name
                                    : "No Linked Survey"}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="cursor-pointer bg-gray-100 border flex justify-between px-4 items-center relative py-1 rounded-full space-x-1.5">
                              <span
                                onClick={() => handleOpenFilter(!showFilter)}
                                className="w-32 text-xs text-gray-500 truncate "
                              >
                                {crrLinkedSurveyId && crrLinkedSurveyId.name
                                  ? crrLinkedSurveyId.name
                                  : "Select Survey"}
                              </span>
                              <span
                                onClick={() => handleOpenFilter(!showFilter)}
                                className="text-gray-500 material-symbols-outlined "
                              >
                                arrow_drop_down
                              </span>

                              {showFilter ? (
                                <div
                                  className="absolute w-48 px-3 py-1 font-normal bg-white shadow-lg z-96 -left-2 top-10 font-xs"
                                  id="chatbot"
                                  style={{
                                    overflowY: "auto",
                                    height: "90px",
                                    zIndex: 120,
                                  }}
                                >
                                  {getSurveyListData &&
                                    getSurveyListData.length > 0
                                    ? getSurveyListData.map(
                                      (
                                        innerSurveyDetails,
                                        innerSurveyIndex
                                      ) =>
                                        innerSurveyDetails.templateId ===
                                          1 ? (
                                          <div
                                            key={innerSurveyIndex}
                                            className="flex items-center py-1 m-1"
                                            onClick={() => {
                                              selectLinkedSurvey(
                                                innerSurveyDetails,
                                                survey,
                                                true
                                              );
                                              handleOpenFilter(!showFilter);
                                            }}
                                          >
                                            <input
                                              type="checkbox"
                                              checked={
                                                crrLinkedSurveyId.id ===
                                                  innerSurveyDetails.id
                                                  ? true
                                                  : false
                                              }
                                              onChange={() => { }}
                                            />
                                            <label className="ml-2 text-sm cursor-pointer text-[#3D405B] ">
                                              {innerSurveyDetails.name}
                                            </label>
                                          </div>
                                        ) : null
                                    )
                                    : null}
                                </div>
                              ) : null}
                            </div>
                          )}

                          {survey && survey.linkedClusterName ? (
                            <div className="flex justify-between py-1">
                              <div className="cursor-pointer bg-blue-50 border truncate flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5 w-full">
                                <span className="w-10/12 text-xs text-gray-500 ">
                                  {survey && survey.linkedClusterName
                                    ? survey.linkedClusterName
                                    : "No Linked Cluster"}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="my-0">
                              <div className="flex justify-between py-1">
                                <div className="cursor-pointer bg-gray-100 border flex justify-between px-4 items-center relative py-1 rounded-full space-x-1.5">
                                  <span
                                    onClick={() => {
                                      handleOpenFilter2(!showFilter2);
                                      loadCluster(survey);
                                    }}
                                    className="w-32 text-xs text-gray-500 truncate "
                                  >
                                    {crrLinkedCluster &&
                                      crrLinkedCluster.clusterName
                                      ? crrLinkedCluster.clusterName
                                      : "Select Cluster"}
                                  </span>
                                  <span
                                    onClick={() => {
                                      handleOpenFilter2(!showFilter2);
                                      loadCluster(survey);
                                    }}
                                    className="text-gray-500 material-symbols-outlined "
                                  >
                                    arrow_drop_down
                                  </span>

                                  {showFilter2 &&
                                    newClusterList &&
                                    newClusterList.length > 0 ? (
                                    <div
                                      className="absolute w-48 px-3 py-1 font-normal bg-white shadow-lg z-96 -left-2 top-10 font-xs"
                                      id="chatbot"
                                      style={{
                                        overflowY: "auto",
                                        height: "120px",
                                        zIndex: 120,
                                      }}
                                    >
                                      {newClusterList &&
                                        newClusterList.length > 0
                                        ? newClusterList.map(
                                          (item, innerSurveyIndex) => (
                                            <div
                                              key={innerSurveyIndex}
                                              className="flex items-center py-1 m-1"
                                              onClick={() => {
                                                selectLinkedCluster(
                                                  item,
                                                  survey,
                                                  true
                                                );
                                                handleOpenFilter2(
                                                  !showFilter2
                                                );
                                              }}
                                            >
                                              <input
                                                type="checkbox"
                                                checked={
                                                  crrLinkedCluster.clusterName ===
                                                    item.clusterName
                                                    ? true
                                                    : false
                                                }
                                                onChange={() => { }}
                                              />
                                              <label className="ml-2 text-sm cursor-pointer text-[#3D405B] ">
                                                {item && item.clusterName
                                                  ? item.clusterName
                                                  : "NaN"}
                                              </label>
                                            </div>
                                          )
                                        )
                                        : null}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className=""></div>
                      )}

                      <div className="flex items-center justify-between space-x-4 text-gray-400 ">
                        {accessAllow("createSurvey") ? (
                          <>
                            <div
                              onClick={() => onEditSurvey(survey)}
                              className="cursor-pointer relative bg-[#F9FAFB] hover:bg-[#2196F3]/10 hover:text-[#2196F3] rounded-full w-12 h-12 flex items-center justify-center transition duration-150 tooltipshow uppercase text-[#3D405B]/70 tooltipshow   "
                            >
                              <span className="material-symbols-rounded text-[1.5rem]">
                                edit_square
                              </span>
                              <span className="absolute p-2 px-4 text-sm bg-white border rounded-md tooltipcard -top-10 text-slate-800 whitespace-nowrap ">
                                {LanguageObj &&
                                  LanguageObj[
                                  "edit_survey_label_" + CurrentLanguage
                                  ]
                                  ? LanguageObj[
                                  "edit_survey_label_" + CurrentLanguage
                                  ]
                                  : "View Question"}
                              </span>
                            </div>
                          </>
                        ) : null}

                        {accessAllow("responseAnalytics") ? (
                          <>
                            <div
                              onClick={() => onReportSurvey(survey)}
                              className="cursor-pointer relative bg-[#F9FAFB] hover:bg-[#2196F3]/10 hover:text-[#2196F3] rounded-full w-12 h-12 flex items-center justify-center transition duration-150 tooltipshow uppercase text-[#3D405B]/70 tooltipshow"
                            >
                              <span className="material-symbols-rounded text-[1.5rem]">
                                signal_cellular_alt
                              </span>
                              <span className="absolute p-2 px-4 text-sm bg-white border rounded-md tooltipcard -top-10 text-slate-800 whitespace-nowrap ">
                                {LanguageObj &&
                                  LanguageObj[
                                  "response_analytics_label_" + CurrentLanguage
                                  ]
                                  ? LanguageObj[
                                  "response_analytics_label_" +
                                  CurrentLanguage
                                  ]
                                  : "Response Analytics"}
                              </span>
                            </div>
                          </>
                        ) : null}

                        {survey && survey.showDashboard && !isLifecycle ? (
                          <>
                            {accessAllow("dashboard") ? (
                              <>
                                <div
                                  onClick={() =>
                                    onDashboard(survey, categoryType)
                                  }
                                  className="cursor-pointer relative bg-[#F9FAFB] hover:bg-[#2196F3]/10 hover:text-[#2196F3] rounded-full w-12 h-12 flex items-center justify-center transition duration-150 tooltipshow uppercase text-[#3D405B]/70 tooltipshow"
                                >
                                  <span className="material-symbols-rounded text-[1.5rem]">
                                    dashboard
                                  </span>
                                  <span className="absolute p-2 px-4 text-sm bg-white border rounded-md tooltipcard -top-10 text-slate-800 whitespace-nowrap ">
                                    {LanguageObj &&
                                      LanguageObj[
                                      "dashboard_label_" + CurrentLanguage
                                      ]
                                      ? LanguageObj[
                                      "dashboard_label_" + CurrentLanguage
                                      ]
                                      : "Dashboard"}
                                  </span>
                                </div>
                              </>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <div
                              onClick={() => onNoDashboardModal(true, survey)}
                              className="cursor-pointer relative bg-[#F9FAFB] hover:bg-[#2196F3]/10 hover:text-[#2196F3] rounded-full w-12 h-12 flex items-center justify-center transition duration-150 tooltipshow uppercase text-[#3D405B]/70 tooltipshow"
                            >
                              <span className="material-symbols-rounded text-[1.5rem]">
                                dashboard
                              </span>
                              <span className="absolute p-2 px-4 text-sm bg-white border rounded-md tooltipcard -top-10 text-slate-800 whitespace-nowrap ">
                                {LanguageObj &&
                                  LanguageObj[
                                  "dashboard_label_" + CurrentLanguage
                                  ]
                                  ? LanguageObj[
                                  "dashboard_label_" + CurrentLanguage
                                  ]
                                  : "Dashboard"}
                              </span>
                            </div>
                          </>
                        )}

                        {accessAllow("publishSurvey") ? (
                          <>
                            {isRiskSurvey ? (
                              <div
                                onClick={() =>
                                  onPublishSurvey(
                                    survey,
                                    6,
                                    survey &&
                                      survey.linkedSurveyId &&
                                      survey.linkedSurveyId.id
                                      ? survey.linkedSurveyId
                                      : ""
                                  )
                                }
                                className="cursor-pointer relative bg-[#F9FAFB] hover:bg-[#2196F3]/10 hover:text-[#2196F3] rounded-full w-12 h-12 flex items-center justify-center transition duration-150 tooltipshow uppercase text-[#3D405B]/70 tooltipshow"
                              >
                                <span className="material-symbols-rounded text-[1.5rem]">
                                  mail
                                </span>
                                <span className="absolute p-2 px-4 text-sm bg-white border rounded-md tooltipcard -top-10 text-slate-800 whitespace-nowrap ">
                                  {LanguageObj &&
                                    LanguageObj[
                                    "campaign_label_" + CurrentLanguage
                                    ]
                                    ? LanguageObj[
                                    "campaign_label_" + CurrentLanguage
                                    ]
                                    : "Launch"}{" "}
                                </span>
                              </div>
                            ) : (
                              <div
                                onClick={() =>
                                  onPublishSurvey(survey, "", "", surveyModule)
                                }
                                className="cursor-pointer relative bg-[#F9FAFB] hover:bg-[#2196F3]/10 hover:text-[#2196F3] rounded-full w-12 h-12 flex items-center justify-center transition duration-150 tooltipshow uppercase text-[#3D405B]/70 tooltipshow"
                              >
                                <span className="material-symbols-rounded text-[1.5rem]">
                                  mail
                                </span>
                                <span className="absolute p-2 px-4 text-sm bg-white border rounded-md tooltipcard -top-10 text-slate-800 whitespace-nowrap ">
                                  {LanguageObj &&
                                    LanguageObj[
                                    "campaign_label_" + CurrentLanguage
                                    ]
                                    ? LanguageObj[
                                    "campaign_label_" + CurrentLanguage
                                    ]
                                    : "Launch"}{" "}
                                </span>
                              </div>
                            )}
                          </>
                        ) : null}
                      </div>

                      <div className="flex items-center justify-center w-10 h-10 transition-all duration-150 bg-white rounded-full hover:bg-slate-100">
                        <div className="relative inline-block dropdown">
                          {AllowChange(survey) ? (
                            <span
                              onClick={() => onOptionDot(survey)}
                              className="cursor-pointer p-1 material-symbols-outlined text-gray-600 rounded-full text-[1.5rem]"
                            >
                              more_vert
                            </span>
                          ) : null}

                          {crrSurvey === survey.id && AllowChange(survey) ? (
                            <div className="absolute z-30 w-24 overflow-hidden text-xs text-gray-500 bg-white border shadow-lg dropdown-menu right-2 top-6">
                              {/* <span onClick={() => onPreviewSurvey(survey)}  className="block px-4 py-2 hover:bg-blue-50 hover:text-blue-500">Preview</span>  */}
                              {accessAllow("uploadUser") &&
                                AllowChange(survey) ? (
                                <span
                                  onClick={() => onUpdateSurvey(survey)}
                                  className="block px-4 py-2 hover:bg-blue-50 hover:text-blue-500"
                                >
                                  Access
                                </span>
                              ) : null}

                              {AllowChange(survey) ? (
                                <span
                                  onClick={() => onDeleteSurvey(survey)}
                                  className="block px-4 py-2 hover:bg-blue-50 hover:text-blue-500"
                                >
                                  Delete
                                </span>
                              ) : null}

                              {AllowChange(survey) ? (
                                <span
                                  onClick={() => onCopySurvey(survey)}
                                  className="block px-4 py-2 hover:bg-blue-50 hover:text-blue-500"
                                >
                                  Copy
                                </span>
                              ) : null}

                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </>
          ) : null
        )
        : null}
    </>
  );
}
