import React from "react";

export default function AddSurvey(props) {
  let { SelectOfQuesLibList, GetParameterLabel, selectedIndexFilterValue, handleSelectIndexFilter, SubmitOfQuesLibList, onSelectQLQue, handleQuestionLibraryModalClose, getQuestionLibraryListData, QuestionLibraryFinalList } = props;


  // console.log('getQuestionLibraryListData: ', getQuestionLibraryListData)


  let ParameterBucket = [];
  if (getQuestionLibraryListData && getQuestionLibraryListData.length > 0) {
    getQuestionLibraryListData.forEach((ques) => {
      if (ques && ques.parameterBucketId && ques.parameterBucketId.id) {
        let getIndex = ParameterBucket.findIndex(prev => prev.id === ques.parameterBucketId.id)
        if (getIndex === -1) {
          ParameterBucket.push({
            "name": ques.parameterBucketId.name,
            "id": ques.parameterBucketId.id
          });
        }

      }
    });
  }

  // console.log('ParameterBucket: ', ParameterBucket);




  let QuestionList = []
  if (getQuestionLibraryListData && getQuestionLibraryListData.length > 0) {
    if (selectedIndexFilterValue && selectedIndexFilterValue.id) {
      getQuestionLibraryListData.forEach((ques) => {
        if (ques && ques.parameterBucketId && ques.parameterBucketId.id && selectedIndexFilterValue && (selectedIndexFilterValue.id === ques.parameterBucketId.id)) {
          QuestionList.push(ques);
        }
      });
    }
    else {
      QuestionList = getQuestionLibraryListData;
    }

  }


  function replaceToQuestions(text) {
    return text ? text.replace('Index', 'Questions') : ''
  }


  function getIndexStatus(QuestionList) {
    if (QuestionList && QuestionList.length > 0) {
      let count = 0

      QuestionList.forEach((ques) => {
        if (QuestionLibraryFinalList.some(prev => prev === ques.id)) {
          count++
        }
      })

      if (count === QuestionList.length) {
        return true
      } else {
        return false
      }

    } else {
      return false
    }
  }



  return (
    <>
      <div className="fixed top-0 z-40 flex items-center justify-center w-full h-full xl:h-screen" style={{ background: '#0000005c' }}>
        <div className="w-11/12 bg-white rounded-lg shadow-sm xl:w-7/12 md:w-9/12">
          <div className="flex justify-between p-4 border-b"> <h2 className="flex items-center text-base font-semibold text-center capitalize test-gray-800">question library <span className="ml-2 material-symbols-outlined">help_outline</span> </h2> <span onClick={() => handleQuestionLibraryModalClose()} className="p-1 font-medium text-gray-500 rounded-full cursor-pointer material-symbols-outlined hover:bg-red-500 hover:text-white" style={{ fontSize: '16px' }}>close</span></div>
          <div className="flex">
            <div className="w-48 gap-2 overflow-hidden overflow-y-auto text-xs font-medium capitalize border-r md:text-sm md:w-56 h-96 quzlibrarysroll">

              <span onClick={() => handleSelectIndexFilter("", true)} className={
                !(selectedIndexFilterValue && selectedIndexFilterValue.id) ?
                  "px-4 block py-3 cursor-pointer  hover:bg-blue-500   hover:text-white bg-blue-500 text-white" :
                  "px-4 block py-3 cursor-pointer  hover:bg-blue-300 hover:text-white"
              }
              >
                {"All Questions"}
              </span>


              {ParameterBucket && ParameterBucket.length > 0 ?
                ParameterBucket.map((para, index) =>
                  <span onClick={() => handleSelectIndexFilter(para, false)} className={
                    selectedIndexFilterValue && selectedIndexFilterValue.id && selectedIndexFilterValue.id === para.id ?
                      "px-4 py-3 block cursor-pointer  hover:bg-blue-500   hover:text-white bg-blue-500 text-white" :
                      "px-4 py-3 block cursor-pointer  hover:bg-blue-300 hover:text-white"
                  }
                  >
                    {replaceToQuestions(GetParameterLabel(para.name))}
                  </span>
                ) : null}


            </div>
            {/*quz */}
            <div className="w-full p-4 overflow-y-auto text-xs h-96">

              {QuestionList && QuestionList.length > 0 ?
                QuestionList.map((ques) =>
                  <div className="py-4 mb-4 border-b ">   <p className="text-sm font-semibold">
                    {ques.name}
                  </p>
                    {/* options */}
                    <div className="relative my-2">
                      {ques && ques.optionsList && ques.optionsList.length > 0 ?
                        ques.optionsList.map((opt) =>
                          <div className="flex items-center py-2"><input type="checkbox" name
                            className="mr-2 rounded-full" /> <label>{opt.name}</label> </div>
                        ) : null}


                      {selectedIndexFilterValue && selectedIndexFilterValue.id ?
                        <>
                          {QuestionLibraryFinalList.some(prev => prev === ques.id) ?
                            <span className="cursor-pointer material-symbols-outlined absolute bottom-0 right-0 bg-green-100 text-gray-500 rounded-full p-0.5 hover:bg-blue-100"
                              title="Adready Added!">check</span>
                            :
                            <span className="cursor-pointer material-symbols-outlined absolute bottom-0 right-0 bg-gray-100 text-gray-500 rounded-full p-0.5 hover:bg-blue-100"
                              title="Add Question!">add</span>
                          }
                        </>
                        :
                        <>
                          {QuestionLibraryFinalList.some(prev => prev === ques.id) ?
                            <span onClick={() => onSelectQLQue(ques)} className="cursor-pointer material-symbols-outlined absolute bottom-0 right-0 bg-green-100 text-gray-500 rounded-full p-0.5 hover:bg-blue-100"
                              title="Adready Added!">check</span>
                            :
                            <span onClick={() => onSelectQLQue(ques)} className="cursor-pointer material-symbols-outlined absolute bottom-0 right-0 bg-gray-100 text-gray-500 rounded-full p-0.5 hover:bg-blue-100"
                              title="Add Question!">add</span>
                          }
                        </>
                      }


                    </div>
                  </div>
                ) : null}



            </div>
            {/*  */}
          </div>
          <div className="flex items-center justify-between p-8 text-xs capitalize border-t">
            <p className="text-xs text-gray-500 md:text-sm"> <b className="font-semibold ">{QuestionLibraryFinalList && QuestionLibraryFinalList.length > 0 ? " " + QuestionLibraryFinalList.length + " " : " 0 "}</b> Questions Added </p>

            {selectedIndexFilterValue && selectedIndexFilterValue.id ?
              <button onClick={() => SelectOfQuesLibList(QuestionList)}
                className={(getIndexStatus(QuestionList) ? "bg-green-500" : "bg-blue-500") + " cursor-pointer text-white rounded-lg px-4 py-3 mx-2  uppercase"}>
                {getIndexStatus(QuestionList) ? "Index Questions Added! " : "Add Index Questions"}</button>
              : null}

            <button onClick={() => SubmitOfQuesLibList()} className="px-4 py-3 mx-2 text-white uppercase bg-blue-500 rounded-lg cursor-pointer">Continue </button>
          </div>
        </div>
      </div>

    </>
  );
}
