import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionScore from './Charts/QuestionScore';
import Filter from '../../../../CommonComponents/Filters/IndexFilter/Filter';
import { CSVLink } from "react-csv";

class DimensionPriorities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: ''
    }
  }
  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handleEngQuesResponse } = this.props;
    this.setState({ FilterValues: finalFilter });
    handleEngQuesResponse(finalFilter, "", "", false);

  }

  render() {
    let { ErrorIndexObj, selectedFilterValue, loading, LifecycleEngQuesResponsesData,
      indexType, optionType, getCommentData, CountRestrict, getEMPFilterLabel,

      getQuestionData, getQuestionFilterData, handleQuestionFilter, EmpFilterData, getIndexFilterData, getIndexByFilterDMGData

    } = this.props;

    let { currentSelectValue, FilterValues } = this.state;



    let QuestionData = []

    if (this.state.currFilterValue === 'none') {
      QuestionData = getQuestionData
    }
    else {
      QuestionData = getQuestionFilterData
    }


    //console.log('QuestionData--->', QuestionData)
    //console.log('getQuestionData--->', getQuestionData)
    //console.log('getQuestionFilterData--->', getQuestionFilterData)


    let paraList = []

    let count = 0;
    let rowSpan = 0;
    let NewParaList = []

    if (QuestionData && QuestionData.length > 0) {

      QuestionData.forEach((ques) => {
        if (paraList.findIndex(prev => prev === ques.parameterDimensionName) === -1) {
          paraList.push(ques.parameterDimensionName);
          count += 1
        }
        else {
        }
      })

      let obj = {}
      paraList.forEach((para) => {
        obj[para] = 0
        QuestionData.forEach((ques) => {
          if (para === ques.parameterDimensionName) {
            obj[para] += 1
          }
        })
      })


      NewParaList = Object.keys(obj).map((key) => [key, obj[key]])
      //   //console.log('data NewParaList: ',NewParaList)

      rowSpan = (QuestionData.length / count)

      // //console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,QuestionData.length)

    }
    // //console.log('ORG ==== NewParaList ===== > : ',NewParaList)
    // //console.log('ORG ==== QuestionData ===== > : ',QuestionData)

    let NewQuesList = [];
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        let total = ques['1'] + ques['2'] + ques['3'] + ques['4'] + ques['5'] + ques['6'];
        NewQuesList.push({
          ...ques,
          score: parseFloat(((ques['5'] + ques['6']) * 100 / total).toFixed(2))
        })
      })
    }


    NewQuesList.sort((b, a) => a.score - b.score);


    // //console.log('ORG ==== NewQuesList ===== > : ',NewQuesList)





    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    //  let DemographicDataScore = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      //  DemographicDataScore=getDemographicIndexMultiFilterData;
      //  DemographicDataDistribution=getIndexDistributionData;

    }
    let { GetParameterLabel, name } = this.props;

    //Export Excel Logic
    // let ScoreExcel = []
    // let filter = FilterValues && FilterValues.length > 0 ? " (" + FilterValues.map((element) => { return element.level + ": " + element.value }).join(",") + ")" : " (Filter: All)"

    // if (NewQuesList && NewQuesList.length > 0) {
    //   NewQuesList.forEach((question) => {
    //     let para = question && question.parameterDimensionName ?
    //       GetParameterLabel(question.parameterDimensionName) :
    //       "";
    //     let Score = CountRestrict(question && question.count ? question.count : "NA") ? 0 : question.score
    //     let temp = {}
    //     temp["Dimension " + filter] = para;
    //     temp["Question"] = question.name
    //     temp["Score(%)"] = Score

    //     ScoreExcel.push(temp)
    //   });
    // }



    function getQuesCountScore(ques) {
      let obj = {
        "count": 1,
        "score": 0
      }

      let total = ques['1'] + ques['2'] + ques['3'] + ques['4'] + ques['5'] + ques['6'];
      obj["score"] = parseFloat(((ques['5'] + ques['6']) * 100 / total).toFixed(2))
      obj["count"] = ques && ques.count ? ques.count : 0;

      return obj
    }


    return (
      <>
        <div className="items-center justify-between md:flex">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />



          {/* <span className="hidden text-xs font-medium text-blue-500">Need Help?</span> */}
          <div className='flex space-x-4' >



            {/*   
          <div>
              {ScoreExcel ?
                <CSVLink data={ScoreExcel} filename={"EnagagementBehaviourItemSummary.csv"}>
                  <p className="flex items-center p-2 px-4 text-sm text-green-500 bg-green-100 rounded-full cursor-pointer ">
                    {"Download Excel"}
                  </p>
                </CSVLink>
                : '...'}
            </div> */}



            {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} /> */}
          </div>



        </div>


        {true ?
          <>



            <div onClick={() => this.closeFilter(false)} className="" >


              <div className="w-full" >

                {LifecycleEngQuesResponsesData && LifecycleEngQuesResponsesData.length > 0 ?
                  LifecycleEngQuesResponsesData.map((survey) =>
                    <>
                      <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-1">
                        <div className="p-6 space-y-6 transition duration-150 bg-white border rounded-lg cursor-pointer hover:shadow-lg">

                          <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">{survey && survey.surveyName ? "Engagement Behaviour Item - " + survey.surveyName : "Engagement Behaviour Item"}</h1>


                          <div className="w-full mb-4 overflow-hidden overflow-x-auto whitespace-nowrap">
                            <table className="w-full border ">
                              <thead>
                                <tr className="bg-gray-100 border-b text-sm  text-[#3D405B]">
                                  <th className="py-2 px-2 border-r font-bold  w-[20%]">
                                    <div className="flex items-center justify-left">
                                      Dimension
                                    </div>
                                  </th>
                                  <th className="py-2 px-2 border-r font-bold w-[50%]">
                                    <div className="flex items-center justify-left">
                                      Question
                                    </div>
                                  </th>
                                  <th className="py-2 px-4 border-r font-bold w-[30%]">
                                    <div className="flex items-center justify-left">
                                      Favorability Score
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {survey && survey.questions && survey.questions.length > 0 ?
                                  survey.questions.map((question, index) =>
                                    <tr
                                      key={index}
                                      className="bg-white  mb-10 lg:mb-0 text-sm text-[#3D405B]"
                                    >
                                      <td className="px-2 border border-b w-[20%]">
                                        <div style={{ width: '20%' }}>{question && question.parameterDimensionName ?
                                          GetParameterLabel(question.parameterDimensionName) :
                                          ""}</div>
                                      </td>
                                      <td className="tooltip-response-question1 px-2 border border-b w-[50%] ">
                                        <div className="whitespace-pre-line cursor-pointer">{question.name}</div>
                                      </td>
                                      <td className="border border-b w-[30%]">
                                        <QuestionScore question={getQuesCountScore(question)} CountRestrict={CountRestrict} />
                                      </td>
                                    </tr>
                                  )
                                  : null}

                              </tbody>
                            </table>
                          </div>


                          <div className="text-sm text-[#3d405B]/70">* We are not showing data where employee count is less than 5</div>
                        </div>
                      </div>
                    </>
                  ) : null}




              </div> 


            </div>

          </>
          :
          <>
            {!loading ?
              <div className="pt-40 text-3xl text-center text-gray-400">We have not calculated this section!</div>
              : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DimensionPriorities);
