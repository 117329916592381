import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage:1,
      EmpIndexA:0,
      EmpIndexB:8,
      pageCount:9
    }
  }
  componentDidMount() {

  }
  prevPage=()=>{
    let { EmpIndexA, EmpIndexB } = this.state;
    if(EmpIndexA>0){
        this.setState({EmpIndexA:EmpIndexA-1, EmpIndexB:EmpIndexB-1});
    }
  }
  nextPage=(IndexListIndex)=>{
    let { EmpIndexA, EmpIndexB } = this.state;
    if(IndexListIndex && IndexListIndex.length && (EmpIndexB<IndexListIndex.length)){
      this.setState({EmpIndexA:EmpIndexA+1, EmpIndexB:EmpIndexB+1});
    }
  }

  handlePage=(setPage)=>{
    this.setState({currentPage:setPage});
  }
  render() {

    let {MeanScoreQwiseList,cardType, QuesMeanScoreList, FilterList, ItemSaveData}= this.props;

    let valueList = FilterList;

    let indexName = '';
    if(ItemSaveData && ItemSaveData.name){
      indexName = ItemSaveData.name;
    }
    
    
    let { currentPage, EmpIndexA, EmpIndexB, pageCount } = this.state;

     //--------------------------------------------------------------------------------

    let IndexList = []

     if(QuesMeanScoreList && QuesMeanScoreList.length>0){
      QuesMeanScoreList.forEach((item,index)=>{
        if(index%pageCount===0){
          IndexList.push(index)
        }
        if(index===(QuesMeanScoreList.length-1)){
          IndexList.push(index)
        }
      })
     }
     
     //console.log('QuesMeanScoreList: ',QuesMeanScoreList)
     let IndexListIndex = []

     if(IndexList && IndexList.length>0){
      IndexList.forEach((item,index)=>{
        if(index!==(IndexList.length-1)){
          IndexListIndex.push(index+1)
        }
      })
     }
    //  //console.log('IndexListIndex: ',IndexListIndex)


    let indexA=0
    let indexB=0


     if(IndexListIndex && IndexListIndex.length>0){
      IndexListIndex.forEach((item,index)=>{
        if(item === currentPage){

          if(index<(IndexList.length-2)){
            indexA=IndexList[index];
            indexB=IndexList[index]+(pageCount-1)
          }
          else{
            indexA=IndexList[index];
            indexB=IndexList[IndexList.length-1]
          }
        }
      })
     }

     //--------------------------------------------------------------------------------


     let overallMean = 0;
     if(ItemSaveData && ItemSaveData.mean){
      overallMean = ItemSaveData.mean;
     }

    //  function getColor(mean, score) {
    //   let Color = '';

    //   if(score!=='NaN'){
    //     if(parseFloat(mean) <= parseFloat(score)){
    //       return Color = '#b2edc9'
    //     }
    //     else if(parseFloat(mean) === parseFloat(score)){
    //       return Color = 'yellow'
    //     }
    //     else{
    //       return Color = '#f2bdbd'
    //     }
      
    //   }
    //   else{
    //     return Color = '#f1f1f1'
    //   }



    // }

    function getColor(check,mean, score) {
      if(!check){
        return {Color:'#ffffff',diffTextColor:'#00000'}
      }
      else{
      if(score==='NaN'){
        return {Color:'#ffffff',diffTextColor:'#00000'}
      }
      else{
        let diffNo = parseInt(Math.abs(parseFloat(mean) - parseFloat(score)));
        //////console.log("diffNo:",diffNo)
        if(parseFloat(mean) <= parseFloat(score)){ //greater (blue)
          if(diffNo>=0 && diffNo<=0.1){
            return {Color:'#b8d7f2',diffTextColor:'#00000'}

          }
          else if(diffNo>=0.2 && diffNo<=0.9){
            return {Color:'#4b9ee7',diffTextColor:'#00000'}

          }
          else{
            return {Color:'#2a74d5',diffTextColor:'#ffffff'}

          }

        }
        else{ //lesse (red)
          if(diffNo>=0 && diffNo<=0.5){
            return {Color:'#f5babd',diffTextColor:'#000000'}

          }
          else if(diffNo>=0.6 && diffNo<=1){
            return {Color:'#ea95a1',diffTextColor:'#000000'}

          }
          else{
            return {Color:'#d03e4d',diffTextColor:'#ffffff'}

          }
        }
      }
    }
    }




    //console.log('valueList: ',valueList);
    function getWindowHeight() {
      const { innerHeight: height } = window;
      return height;
    }
    //console.log('QuesMeanScoreList: ',QuesMeanScoreList);

    return (
      <>    
      {QuesMeanScoreList && QuesMeanScoreList.length>0?
      <div className=" whitespace-nowrap w-full overflow-hidden  ">
      
      <div className="w-full border overflow-x-auto" style={{height:(getWindowHeight()-300).toString()+"px"}}>

      <table className="border-collapse " >
        <thead>
          <tr className="bg-gray-100 border-b text-xs   text-gray-800">
          <th className="py-2 px-4 border-r font-semibold"><div className="flex items-center justify-center">
          {cardType==="overall"?"Index":(indexName==="OrganizationCore"?"Driver":"Dimesions")}
          </div></th>
          <th className="py-2 px-4 border-r font-semibold"><div className="flex items-center justify-center">Questions</div></th>
          <th className="py-2 px-4 border-r font-semibold"><div className="flex items-center justify-center">Overall Mean</div></th>
          {valueList && valueList.length>0?
            valueList.map((filterValue)=>
              <th className="py-2 px-4 border-r font-semibold"><div className="flex items-center justify-center">{filterValue}</div></th>
            ):null}
          </tr>
        </thead>
        <tbody>
          {QuesMeanScoreList && QuesMeanScoreList.length>0?
            QuesMeanScoreList.map((question,index)=>
             
            (index >= indexA && index <=indexB)?
            

                  <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                  {cardType==="overall"?
                  <td className="p-3 border border-b" >    
                    {question && question.parameterBucketName?question.parameterBucketName:"-"}
                  </td>
                  :
                  <td className="p-3 border border-b" >    
                  {question && question.parameterDimensionName?question.parameterDimensionName:"-"}
                  </td>
                  }

                  <td className="p-3 border border-b" >    
                    {(valueList && valueList.length>5)?question && question.questionName && question.questionName.length>55?question.questionName.slice(0,55)+'...':question.questionName:question.questionName}
                  </td>
                  <td className="p-3 border border-b" style={{background:getColor(false,overallMean,(question && question.quesMean)?question.quesMean.toFixed(2):"NaN").Color,color:getColor(false,overallMean,(question && question.quesMean)?question.quesMean.toFixed(2):"NaN").diffTextColor}}>    
                    {(question && question.quesMean)?question.quesMean.toFixed(2):0}
                  </td>
                  {question && question.demographic.length>0?
                  question.demographic.map((filterValue)=>
                  // (question && question.dmgName && question.dmgName === filterValue)?
                  <td className="p-3 border border-b" style={{background:getColor(true,overallMean,(filterValue && filterValue.score)?filterValue.score.toFixed(2):"NaN").Color,color:getColor(true,overallMean,(filterValue && filterValue.score)?filterValue.score.toFixed(2):"NaN").diffTextColor}}>    
                    {(filterValue && filterValue.score)?filterValue.score.toFixed(2):0}
                  </td>
                  // :
                  // <td className="p-3 border border-b " style={{background:getColor(overallMean,"NaN")}} >    
                  //   {"-"}
                  // </td>
                  ):null}
                  </tr>



              :null

            ):null}

        </tbody>
      </table>
      </div>

      
      <div className="flex justify-center items-center text-center py-8">
            <span onClick={()=>this.prevPage()} className="select-none material-symbols-outlined w-8 h-8 rounded-full bg-gray-400 inline-flex border border-transparent items-center justify-center mx-1 text-sm text-white" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_left</span>
              {IndexListIndex && IndexListIndex.length>0 && IndexListIndex.length!==1?
              IndexListIndex.map((item,elemindex)=>
              <>
              {(elemindex >= EmpIndexA && elemindex <= EmpIndexB)?
              (elemindex < (IndexListIndex.length-1))?
              <span onClick={()=>this.handlePage(item)} className={(currentPage===item)?"cursor-pointer w-8 h-8 rounded-full bg-blue-500 text-white inline-flex border border-transparent items-center justify-center mx-1 text-sm":"cursor-pointer w-8 h-8 rounded-full bg-white border  inline-flex  items-center justify-center mx-1 text-sm"}>{item}</span>
              :
              <span onClick={()=>this.handlePage(item)} className={(currentPage===item)?"cursor-pointer w-8 h-8 rounded-full bg-blue-500 text-white inline-flex border border-transparent items-center justify-center mx-1 text-sm":"cursor-pointer w-8 h-8 rounded-full bg-white border  inline-flex  items-center justify-center mx-1 text-sm"}>{item}</span>
              :null}</>
              )
              :null}
            <span onClick={()=>this.nextPage(IndexListIndex)} className="select-none material-symbols-outlined w-8 h-8 rounded-full bg-white text-blue-500 border inline-flex  items-center justify-center mx-1 text-sm" style={{fontSize: '18px', display: 'inline-flex'}}>keyboard_arrow_right</span>
          </div>  

    </div>
      :null}

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
