import React, { useState } from 'react';


export default function SingleChoice(props) {
  let { showBucketDropdown, parameterList, handleParameterBucket, handleParameterQuestion,
    getParameterListbyParentData, paraBucketName, question, showDimensionDropdown, isPackage, surveyDetails,
    paraDimensionName, handleParameterDimension, GetParameterLabel, SurveyTemplateId, surveyModuleNo } = props;

  //////console.log("parameterList----------sss------>",question)


  function checkIndexTemplate(para) {
    let check = false;
    let templateId = para && para.template ? para.template : "";
    if (!([1, 2, 3, 4, 5, 6, 7, 8, 9].includes(templateId))) {
      check = true;
    }
    return check
  }


  // function getRestrictedPara(itemPara,surveyData){
  //   let check = false;

  //   if(surveyData && surveyData.surveyModule && (surveyData.surveyModule===6)){
  //     if(itemPara && itemPara.packageModule.length>0 && itemPara.packageModule.includes(9)){
  //       check = true;
  //     }
  //   }else{

  //       let template = itemPara.template;

  //       if(surveyData && surveyData.surveyModule && (surveyData.surveyModule===3)){
  //         if(!([1,2,3,4,5,6,7,8].includes(template))){
  //           check = true;
  //         }
  //       }else if(surveyData && surveyData.surveyModule && (surveyData.surveyModule===2)){
  //         if(!([1,2,3,4,5,6,7,8,9].includes(template))){
  //           check = true;
  //         }
  //       }else{
  //         if(surveyData && surveyData.value && (surveyData.value===7)){
  //           if(!([1,2,4,5,6,7,9].includes(template))){
  //             check = true;
  //           }
  //         }else if(surveyData && surveyData.value && (surveyData.value>7)){
  //           if(!([1,2,3,4,5,6,7,9].includes(template))){
  //             check = true;
  //           }
  //         }else{
  //           if(!([3,9].includes(template))){
  //             check = true;
  //           }
  //         }
  //       }

  //   }

  //   return check
  // }

  function getRestrictedPara(itemPara, surveyData) {
    let check = false;
    

    //console.log("itemPara, surveyData--->", itemPara, surveyData)

    //null: Employee Listening, 1: Exit Survey, 2: Sense Survey, 3: Feedback 360 Survey, 4: Pulse Survey, 5: Customer Survey, 6: Assessment 

    if(surveyData && surveyData.stageTemplate && surveyData.stageTemplate === 7 && !surveyData.isNonEdit){
      if(itemPara && itemPara.name && itemPara.name === "OrganizationCore"){
          check = true;
      }
    }else if(surveyData && surveyData.stageTemplate && surveyData.stageTemplate === 4 && !surveyData.isNonEdit){
      if(itemPara && itemPara.name && itemPara.name === "OrganizationCore"){
          check = true;
      }
    }
    else{
      if (surveyData && surveyData.surveyModule && (surveyData.surveyModule === 6)) { // Assessment
        if (itemPara && itemPara.moduleType.length > 0 && itemPara.moduleType.includes(6)) {
          check = true;
        }
      }
      if (surveyData && surveyData.surveyModule && (surveyData.surveyModule === 5)) { // Customer
        if (itemPara && itemPara.moduleType.length > 0 && itemPara.moduleType.includes(5)) {
          check = true;
        }
      }
      if (surveyData && surveyData.surveyModule && (surveyData.surveyModule === 4)) { // Pulse
        if (itemPara && itemPara.moduleType.length > 0 && itemPara.moduleType.includes(4)) {
          check = true;
        }
      }
      if (surveyData && surveyData.surveyModule && (surveyData.surveyModule === 3)) { // 360
        if (itemPara && itemPara.moduleType.length > 0 && itemPara.moduleType.includes(3)) {
          check = true;
        }
      }
      if (surveyData && surveyData.surveyModule && (surveyData.surveyModule === 2)) { // Sense
        if (itemPara && itemPara.moduleType.length > 0 && itemPara.moduleType.includes(2)) {
          check = true;
        }
      }
      if (surveyData && surveyData.surveyModule && (surveyData.surveyModule === 1)) { // Sense
        if (itemPara && itemPara.moduleType.length > 0 && itemPara.moduleType.includes(7)) {
          check = true;
        }
      }
      if (!(surveyData && surveyData.surveyModule)) {
        if (surveyData.value === 7) {
          if (itemPara && itemPara.moduleType.length > 0 && itemPara.moduleType.includes(1)) {
            check = true;
          }
        } else {
          if (itemPara && itemPara.moduleType.length > 0 && itemPara.moduleType.includes(0)) {
            check = true;
          }
        }
      }
    }
    


    return check
  }



  let customParameterList = []

  if (SurveyTemplateId === 1) {

    // if(parameterList && parameterList.length>0){
    //   parameterList.forEach((para)=>{
    //     if(checkIndexTemplate(para)){
    //       customParameterList.push(para)
    //     }
    //   });
    // }

    if (parameterList && parameterList.length > 0) {
      customParameterList = parameterList;
    }

  }
  else {

    if (parameterList && parameterList.length > 0) {
      customParameterList = parameterList;
    }

  }


  //console.log("parameterList----->",parameterList);
  //console.log("customParameterList----->",customParameterList);

  //console.log("surveyDetails----->",surveyDetails);



  let isShow = false;

  if (question && question.templateId && question.templateId === 1) {
    isShow = true
  }

  if (surveyDetails && surveyDetails.surveyModule && (surveyDetails.surveyModule === 6)) {
    isShow = true
  }



  let [searchName, handleSearchInput] = useState("")

  const handleSearch = (value) => {
    handleSearchInput(value)
  }

  function tryCatch(valueX, searchNameX) {
    let value1 = true;
    try {
      value1 = valueX && valueX.match(new RegExp(`${searchNameX}`, "gi"));
    } catch (e) {
      return true;
    }
    return value1;
  }

  function SortList(list) {
    let temp = [];
    if (list && list.length > 0) {
      temp = list.sort();
    }
    return temp;
  }
  let getParameterListbyParentDataV2 = [];
  if (getParameterListbyParentData && getParameterListbyParentData.length > 0) {
    getParameterListbyParentDataV2 = getParameterListbyParentData.map((ele => (ele)));
    getParameterListbyParentDataV2.sort((a, b) => (GetParameterLabel(a.name).charCodeAt(0) - GetParameterLabel(b.name).charCodeAt(0)))
  }



  //console.log("parameterList----------sss------>", question)



  return (
    <>
      {isShow ?

        <div className="flex justify-start w-full pb-4">


          <div className="relative flex items-center justify-between px-4 py-1 bg-white border rounded-lg lg:w-60 sm:w-full md:w-full text-slate-800">
            <span className="w-full pr-4 text-xs font-semibold cursor-pointer text-slate-800" onClick={() => { handleParameterBucket(showBucketDropdown); handleSearchInput("") }}>{(paraBucketName) ? paraBucketName : (question && question.parameterBucketId && question.parameterBucketId.name) ? GetParameterLabel(question.parameterBucketId.name) : 'Select Bucket'}</span>
            <span className="text-gray-500 cursor-pointer material-symbols-outlined" onClick={() => { handleParameterBucket(showBucketDropdown); handleSearchInput("") }}>expand_more</span>
            {showBucketDropdown ?
              <div className="absolute right-0 z-10 w-full overflow-hidden overflow-y-auto text-gray-500 bg-white rounded-md shadow-md top-10 h-60">
                <span onClick={() => handleParameterQuestion({ id: "", name: "" }, question.id, 1)}
                  className="inline-flex items-center w-full px-4 py-3 text-xs border-b cursor-pointer hover:bg-gray-100">{'None'}</span>

                {customParameterList && customParameterList.length > 0 ?
                  customParameterList.map((para, index) =>
                    (para.parameterTypeLevel === 1) ?
                      (getRestrictedPara(para, surveyDetails)) ?
                        <span key={index} onClick={() => handleParameterQuestion(para, question.id)} className="inline-flex items-center w-full px-4 py-3 text-xs border-b cursor-pointer hover:bg-gray-100">
                          {GetParameterLabel(para.name)}</span>
                        : null
                      : null
                  )
                  : null}
              </div>


              : null}

          </div>



          {(paraBucketName) || (question && question.parameterBucketId && question.parameterBucketId.name) ?


            <div className="bg-white mx-1.5 lg:w-60 sm:w-full rounded-lg md:w-full border text-slate-800 flex justify-between px-4 items-center relative py-1">
              <span className="w-full pr-4 text-xs font-semibold cursor-pointer text-slate-800" onClick={() => { handleParameterDimension(showDimensionDropdown); handleSearchInput("") }}>{(paraDimensionName) ? GetParameterLabel(paraDimensionName) : (question && question.parameterDimensionId && question.parameterDimensionId.name) ? GetParameterLabel(question.parameterDimensionId.name) : 'Select Dimension'}</span>
              <span className="text-gray-500 cursor-pointer material-symbols-outlined" onClick={() => { handleParameterDimension(showDimensionDropdown); handleSearchInput("") }} >expand_more</span>
              {showDimensionDropdown ?
                <div className="absolute left-0 z-10 w-full overflow-hidden overflow-y-auto text-gray-500 bg-white rounded-md shadow-md top-10 min-h-auto max-h-60" >

                  <div className="relative mb-4 overflow-hidden bg-white border rounded-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-search absolute  top-1.5 left-1.5"
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#3D405B"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="10" cy="10" r="7" />
                      <line x1="21" y1="21" x2="15" y2="15" />
                    </svg>
                    <input
                      type="text"
                      name="searchName"
                      value={searchName}
                      onChange={(e) => handleSearch(e.target.value)}
                      placeholder="Search"
                      className="text-sm w-full pl-8 p-2 rounded-sm focus:outline-none text-[#707070] placeholder:text-[#707070]  "
                    />
                  </div>

                  <span onClick={() => handleParameterQuestion({ id: "", name: "" }, question.id, 2)}
                    className="inline-flex items-center w-full px-4 py-3 text-xs border-b cursor-pointer hover:bg-gray-100">{'None'}</span>
                  {getParameterListbyParentDataV2 && getParameterListbyParentDataV2.length > 0 ?
                    getParameterListbyParentDataV2.map((para, index) =>
                      tryCatch(GetParameterLabel(para.name), searchName) ?

                        <span key={index} onClick={() => handleParameterQuestion(para, question.id)} className="inline-flex items-center w-full px-4 py-3 text-xs border-b cursor-pointer hover:bg-gray-100">
                          {GetParameterLabel(para.name)}</span>

                        : null)
                    : null}
                </div>

                : null}

            </div>


            : null}

        </div>

        : null}

    </>
  );
}