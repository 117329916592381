import { data } from "autoprefixer";
import React, { Component } from "react";
import { connect } from "react-redux";

class NudgesSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  componentDidMount() { }

  openHelpModal = (check) => {
    this.setState({ showModal: check });
  };

  render() {
    let { name, optionType, dataItem, DistList, CountRestrict, crrDmg, indexType } =
      this.props;
    let { showModal } = this.state;

    function showNaN(item, name) {
      let dataScore = CountRestrict(item.count)
        ? "NA"
        : item && item[name] && item[name]
          ? item[name].toFixed(2)
          : 0;
      return dataScore;
    }

    //-----------------------Nudeges-------------------------------------------------
    let textLabel = "";
    let dataListV2Asc = [];
    let dataListV2Dsc = [];



    let DistXList = []


    if (optionType === "summary") {
      textLabel = dataItem && dataItem.overallText ? dataItem.overallText : ""

      // let DistObj = {};
      // if (dataItem && dataItem.overallDist) {
      //   let indexDataV2 = dataItem.overallDist;
      //   if (dataItem && dataItem.distList && dataItem.distList.length > 0) {
      //     dataItem.distList.forEach((item, index) => {
      //       DistObj[item.value] = indexDataV2[item.value]
      //         ? indexDataV2[item.value].toFixed(2)
      //         : 0;
      //       textLabel +=
      //         (index > 0 ? ", " : " ") +
      //         DistObj[item.value] +
      //         "% " +
      //         item.label;
      //     });
      //   }
      // }

      // console.log("indexType",indexType);




      let DistObj = {};
      if (dataItem && dataItem.overallDist) {
        let indexDataV2 = dataItem.overallDist;
        if (dataItem && dataItem.distList && dataItem.distList.length > 0) {
          dataItem.distList.forEach((item, index) => {
            DistObj[item.value] = indexDataV2[item.value]
              ? indexDataV2[item.value].toFixed(2)
              : 0;
            textLabel +=
              (index > 0 ? ", " : " ") +
              DistObj[item.value] +
              "% " +
              item.label;

            // let tempStr = item.label + 
            // " ("+DistObj[item.value]+")" +
            // "% ";

            DistXList.push({
              "label": item.label,
              "score": DistObj[item.value],
              "text": item.text,
              "color1": item.color1,
              "color2": item.color2
            });


          });
        }
      }


      console.log("indexType", indexType);
      console.log("DistXList", DistXList);










    }


    if (optionType === "summarydetails") {
      textLabel = "Performing Engagement Score with  Disengaged Distribution";

      function getScoreData(dataItem, sortType) {
        let dataList = [];

        let tempSortData = [];
        if (
          dataItem &&
          dataItem.overallDistDetail &&
          dataItem.overallDistDetail.length > 0
        ) {
          dataItem.overallDistDetail.forEach((data) => {
            tempSortData.push(data);
          });
        }

        if (tempSortData && tempSortData.length > 0) {
          tempSortData.forEach((item) => {
            console.log("item---11-->", item);

            let scoreSum = 0;

            if (sortType === 1) {
              if (
                dataItem &&
                dataItem.distListSumLow &&
                dataItem.distListSumLow.length > 0
              ) {
                dataItem.distListSumLow.forEach((dist, index) => {
                  scoreSum += Number(item[dist.value]);
                });
              }
            } else {
              if (
                dataItem &&
                dataItem.distListSumHigh &&
                dataItem.distListSumHigh.length > 0
              ) {
                dataItem.distListSumHigh.forEach((dist, index) => {
                  scoreSum += Number(item[dist.value]);
                });
              }
            }

            dataList.push({
              name: item.name,
              score: scoreSum > 0 ? Number(scoreSum.toFixed(2)) : 0,
              count: item.count,
            });
          });
        }

        if (sortType === 1) {
          dataList.sort((a, b) => a.score - b.score);
        } else {
          dataList.sort((b, a) => a.score - b.score);
        }
        return dataList;
      }

      dataListV2Asc = getScoreData(dataItem, 1);
      dataListV2Dsc = getScoreData(dataItem, 2);
    }


    // const colors = ['#F44336', '#FF9800', '#8BC34A', '#4CAF50'];
    const colors = ['rgba(76, 175, 80, 0.1)', 'rgba(139, 195, 74, 0.1)', 'rgba(255, 152, 0, 0.1)', 'rgba(244, 67, 54, 0.1)'];
    // const colors = ['rgba(244, 67, 54, 0.1)', 'rgba(255, 152, 0, 0.1)', 'rgba(139, 195, 74, 0.1)', 'rgba(76, 175, 80, 0.1)'];


    return (
      <>
        {true ? (
          <button
            onClick={() => this.openHelpModal(true)}
            className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"
          >

            <svg className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B]" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 8C5.46957 8 4.96086 7.78929 4.58579 7.41421C4.21071 7.03914 4 6.53043 4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4C6.53043 4 7.03914 4.21071 7.41421 4.58579C7.78929 4.96086 8 5.46957 8 6C8 6.53043 7.78929 7.03914 7.41421 7.41421C7.03914 7.78929 6.53043 8 6 8ZM6 8V16C6 16.5304 6.21071 17.0391 6.58579 17.4142C6.96086 17.7893 7.46957 18 8 18H13M18 16C18.5304 16 19.0391 16.2107 19.4142 16.5858C19.7893 16.9609 20 17.4696 20 18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20C17.4696 20 16.9609 19.7893 16.5858 19.4142C16.2107 19.0391 16 18.5304 16 18C16 17.4696 16.2107 16.9609 16.5858 16.5858C16.9609 16.2107 17.4696 16 18 16ZM18 16V8C18 7.46957 17.7893 6.96086 17.4142 6.58579C17.0391 6.21071 16.5304 6 16 6H11M11 6L14 9M11 6L14 3M13 18L10 15M13 18L10 21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>Snapshot</span>
          </button>
        ) : null}

        {showModal ? (
          <div className="fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center w-full h-full p-4 bg-black bg-opacity-40 md:p-0">
            <div className="w-full mx-auto bg-white rounded-md xl:w-7/12 lg:w-9/12 ">
              <div className="flex items-center justify-between p-4 border-b lg:px-6">
                <h1 className="p-1 text-lg font-semibold capitalize">Snapshot</h1>
                <span onClick={() => this.openHelpModal(false)} className=" material-symbols-outlined bg-gray-50 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150">  close</span>
              </div>

              {/* nudges model code */}
              <div
                className="lg:px-6 p-4 h-[36rem] overflow-y-auto">
                {/* ---------------------- Summary ---------------------- */}

                {optionType === "summary" && dataItem && dataItem.show ? (
                  <>
                    <div className="flex justify-center ">
                      <div className="space-y-6 text-left">

                        <h1 className="p-1 text-lg font-medium capitalize">{name}</h1>

                        {textLabel ?
                          <h2 className="text-sm">{textLabel}</h2>
                          : null}

                        <div className="space-y-4 ">
                          {DistXList && DistXList.length > 0 ?
                            DistXList.map((ele, index) =>
                              <div className="" key={ele.label}>
                                <div className="flex items-center px-3 py-2" style={{ background: colors[index % colors.length] }}>
                                  <span style={{ background: ele.color1 }} className="w-4 h-4 rounded-md"></span>
                                  <span className="px-2 font-semibold">{ele.label}</span>
                                  <span className="font-semibold">({ele.score}%)</span>
                                </div>
                                <div className="bg-[#f5f5f5] p-4">
                                  <p className="text-sm italic">{ele.text}</p>
                                </div>
                              </div>
                            ) : null}
                        </div>

                      </div>
                    </div>
                  </>
                ) : null}

                {optionType === "summarydetails" &&
                  dataItem &&
                  dataItem.show ? (
                  <>
                    <div className="flex justify-center ">
                      <div className="w-full space-y-6 text-center">
                        <div className="flex justify-center text-lg font-medium text-center">
                          {textLabel ? <div>{"Low " + textLabel}</div> : null}
                        </div>

                        <div className="p-4">
                          <table className="p-2 border ">
                            <thead className="p-2 text-xs bg-red-100 border">
                              <th className="p-2 border ">
                                {dataItem && dataItem.crrDmg
                                  ? dataItem.crrDmg
                                  : "Demographics"}
                              </th>
                              <th className="p-2 border ">
                                Disengaged Score
                              </th>
                              <th className="p-2 border ">
                                No. Of Disengaged Employees
                              </th>
                            </thead>
                            <tbody>
                              {dataListV2Asc && dataListV2Asc.length > 0
                                ? dataListV2Asc.map((item, index) =>
                                  index < 5 ? (
                                    <tr className="text-xs text-gray-800 border-b">
                                      <td className="px-2 py-2 font-normal border-r">
                                        {item.name}
                                      </td>
                                      <td className="px-2 py-2 font-normal border-r">
                                        {item.score}
                                      </td>
                                      <td className="px-2 py-2 font-normal border-r">
                                        {item.count}
                                      </td>
                                    </tr>
                                  ) : null
                                )
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="w-full space-y-6 text-center">
                        <div className="flex justify-center text-lg font-medium text-center">
                          {textLabel ? (
                            <div className="text-left">
                              {"High " + textLabel}
                            </div>
                          ) : null}
                        </div>

                        <div className="p-4">
                          <table className="p-2 border ">
                            <thead className="p-2 text-xs bg-green-100 border">
                              <th className="p-2 border ">
                                {dataItem && dataItem.crrDmg
                                  ? dataItem.crrDmg
                                  : "Demographics"}
                              </th>
                              <th className="p-2 border ">Disengaged Score</th>
                              <th className="p-2 border ">
                                No. Of Disengaged Employees
                              </th>
                            </thead>
                            <tbody>
                              {dataListV2Dsc && dataListV2Dsc.length > 0
                                ? dataListV2Dsc.map((item, index) =>
                                  index < 5 ? (
                                    <tr className="text-xs text-gray-800 border-b">
                                      <td className="px-2 py-2 font-normal border-r">
                                        {item.name}
                                      </td>
                                      <td className="px-2 py-2 font-normal border-r">
                                        {item.score}
                                      </td>
                                      <td className="px-2 py-2 font-normal border-r">
                                        {item.count}
                                      </td>
                                    </tr>
                                  ) : null
                                )
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}










              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(NudgesSummary);
