import React, { Component } from 'react';
import { connect } from 'react-redux';
// import CoreValue from './Components/CoreValue/CoreValue';
import Overview from './Components/Overview/Overview';
import RWA from './Components/RWA/RWA';
import RWANPS from './Components/RWANPS/RWANPS';
import DimensionPriorities from '../../CommonComponents/DimensionPriorities/DimensionPriorities';
import DimensionPrioritiesMap from '../../CommonComponents/DimensionPrioritiesMap/DimensionPrioritiesMap';
import ResponseDistributionType2 from '../Index/Components/ResponseDistributionType2/ResponseDistribution';

import RWACalculator from './Components/RWACalculator/RWACalculator';

class OrgCore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 1,
      DimensionList: [
        {
          index: 1,
          name: 'Happiness',
          cutOff: 59
        },
        {
          index: 2,
          name: 'Engagement',
          cutOff: 78

        },
        {
          index: 4,
          name: 'Stress',
          cutOff: 28

        },
        {
          index: 5,
          name: 'Manager',
          cutOff: 50
        },
        {
          index: 6,
          name: 'Leader',
          cutOff: 50
        },
        // {
        //   index:8,
        //   name:'OrgCore',
        //   cutOff:59
        // },
      ],
    }
  }
  componentDidMount() {

  }


  onOptionType = (data, validateList) => {
    console.log("data no", data)
    this.setState({ optionType: data });
    let { onRWAnalysis, onOrgQuestionDist, onOrgQuestion, onRwaNpsInsight } = this.props;

    if (data === 2) {
      onRWAnalysis([], validateList && validateList.length ? validateList[0].template : 1, false);
    }
    else if (data === 7) {
      onRWAnalysis([], validateList && validateList.length ? validateList[0].template : 1, true);
    }
    else if (data === 3 || data === 6) {
      onOrgQuestion(8);
    }
    else if (data === 4) {
      onOrgQuestionDist(2);
    }
    else if (data === 8 || data === 9) {
      onRwaNpsInsight([], data === 9);
    }
  }

  onSwitchOptionScreen = (validateList) => {
    let { handleMultifilterOrgDriverSummary, indexType, getIndexByFilterDMGData, getCommentData, loading, GetParameterLabel, CountRestrict,
      EmpFilterData, getIndexFilterData, getEngagementRWAData, rwaIndexType, handleRWAFilter, getSurveyData, validateIndex, getEMPFilterLabel,

      handleMultiFilterGetResQuestion, getAllBenchMarkData, getIndexScore, handleSummaryFilter, handleSummaryCard, getDemographicIndexData, getIndexData, getIndexDistributionData,
      getQuestionData, getQuestionFilterData, handleQuestionFilter, handleMultiFilterGetQuestion,
      getDistributionIndexData, getEDAOrgdata, handleRwaNpsInsight, handleMultiFilterGetMapQuestion, handleMultiFilterGetDimenPrioQuestion,
      // getDistributionIndexData,handleDistributionFilter,getIndexDriverData, getIndexQuantileData
      onRWAnalysis, handleFilterRWA, getQuestionMapData, getdmgOrgManyFilterData, getEngagementRWAError,
      ErrorIndexObj, getDemographicIndexMultiAxisOrgData, driverVsNpsRWAData, getNameQuestionData, getQuesDistributionIndexOrgData,
      handleQuesDistributionIndexOrg, getQuestionDistributionData, getNudgeTextBySurveyIdData

    } = this.props;


    let { optionType } = this.state;

    switch (optionType) {

      case 1: return <Overview
        ErrorIndexObj={ErrorIndexObj} getDemographicIndexMultiAxisOrgData={getDemographicIndexMultiAxisOrgData}
        getEDAOrgdata={getEDAOrgdata} getdmgOrgManyFilterData={getdmgOrgManyFilterData}
        getIndexByFilterDMGData={getIndexByFilterDMGData} getEMPFilterLabel={getEMPFilterLabel}
        CountRestrict={CountRestrict} validateIndex={validateIndex} GetParameterLabel={GetParameterLabel} getDistributionIndexData={getDistributionIndexData} handleMultifilterOrgDriverSummary={handleMultifilterOrgDriverSummary} loading={loading} getSurveyData={getSurveyData}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getAllBenchMarkData={getAllBenchMarkData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard} getDemographicIndexData={getDemographicIndexData} getIndexData={getIndexData} />

      case 2: return <RWA
        ErrorIndexObj={ErrorIndexObj}
        getEngagementRWAError={getEngagementRWAError} getIndexByFilterDMGData={getIndexByFilterDMGData} getEMPFilterLabel={getEMPFilterLabel}
        CountRestrict={CountRestrict} validateIndex={validateIndex} GetParameterLabel={GetParameterLabel} validateList={validateList} loading={loading} handleFilterRWA={handleFilterRWA}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getEngagementRWAData={getEngagementRWAData}
        onRWAnalysis={onRWAnalysis} rwaIndexType={rwaIndexType} handleRWAFilter={handleRWAFilter}
      />

      case 3: return <DimensionPriorities
        IndexName={"Organisation"}
        getNudgeTextBySurveyIdData={getNudgeTextBySurveyIdData}
        ErrorIndexObj={ErrorIndexObj}
        handleMultiFilterGetDimenPrioQuestion={handleMultiFilterGetDimenPrioQuestion}
        getIndexByFilterDMGData={getIndexByFilterDMGData}

        CountRestrict={CountRestrict} getEMPFilterLabel={getEMPFilterLabel} name={"Organisation"} GetParameterLabel={GetParameterLabel} loading={loading} handleMultiFilterGetQuestion={handleMultiFilterGetQuestion}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}

        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getQuestionData={getQuestionData} getQuestionFilterData={getQuestionFilterData} handleQuestionFilter={handleQuestionFilter} />

      case 6: return <DimensionPrioritiesMap
        IndexName={"Organisation"}
        ErrorIndexObj={ErrorIndexObj}
        getIndexByFilterDMGData={getIndexByFilterDMGData}
        handleMultiFilterGetMapQuestion={handleMultiFilterGetMapQuestion}
        CountRestrict={CountRestrict} getEMPFilterLabel={getEMPFilterLabel} name={"Organisation"} GetParameterLabel={GetParameterLabel} loading={loading} handleMultiFilterGetQuestion={handleMultiFilterGetQuestion}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}

        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getQuestionData={getQuestionData} getQuestionMapData={getQuestionMapData} getQuestionFilterData={getQuestionFilterData} handleQuestionFilter={handleQuestionFilter} />



      case 4: return <ResponseDistributionType2
        ErrorIndexObj={ErrorIndexObj}
        getQuestionDistributionData={getQuestionDistributionData}
        handleMultiFilterGetResQuestion={handleMultiFilterGetResQuestion} getIndexByFilterDMGData={getIndexByFilterDMGData}
        CountRestrict={CountRestrict} GetParameterLabel={GetParameterLabel} loading={loading} handleMultiFilterGetQuestion={handleMultiFilterGetQuestion}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getQuestionData={getQuestionData} getQuestionFilterData={getQuestionFilterData} handleQuestionFilter={handleQuestionFilter}
        getQuesDistributionIndexOrgData={getQuesDistributionIndexOrgData} handleQuesDistributionIndexOrg={handleQuesDistributionIndexOrg} />

      case 7: return <RWACalculator

        getSurveyData={getSurveyData}
        getIndexData={getIndexData}


        ErrorIndexObj={ErrorIndexObj}
        getEngagementRWAError={getEngagementRWAError} getIndexByFilterDMGData={getIndexByFilterDMGData} getEMPFilterLabel={getEMPFilterLabel}
        CountRestrict={CountRestrict} validateIndex={validateIndex} GetParameterLabel={GetParameterLabel} validateList={validateList} loading={loading} handleFilterRWA={handleFilterRWA}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getEngagementRWAData={getEngagementRWAData}
        onRWAnalysis={onRWAnalysis} rwaIndexType={rwaIndexType} handleRWAFilter={handleRWAFilter}

      />


      case 8: return <RWANPS
        ErrorIndexObj={ErrorIndexObj}
        getEngagementRWAError={getEngagementRWAError} getIndexByFilterDMGData={getIndexByFilterDMGData} getEMPFilterLabel={getEMPFilterLabel}
        CountRestrict={CountRestrict} validateIndex={validateIndex} GetParameterLabel={GetParameterLabel} validateList={validateList} loading={loading} handleFilterRWA={handleFilterRWA}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getEngagementRWAData={getEngagementRWAData}
        onRWAnalysis={onRWAnalysis} rwaIndexType={rwaIndexType} handleRWAFilter={handleRWAFilter}
        driverVsNpsRWAData={driverVsNpsRWAData}
        handleRwaNpsInsight={handleRwaNpsInsight}
        getNameQuestionData={getNameQuestionData}

      />

      case 9: return <RWANPS
        ErrorIndexObj={ErrorIndexObj}
        getEngagementRWAError={getEngagementRWAError} getIndexByFilterDMGData={getIndexByFilterDMGData} getEMPFilterLabel={getEMPFilterLabel}
        CountRestrict={CountRestrict} validateIndex={validateIndex} GetParameterLabel={GetParameterLabel} validateList={validateList} loading={loading} handleFilterRWA={handleFilterRWA}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getEngagementRWAData={getEngagementRWAData}
        onRWAnalysis={onRWAnalysis} rwaIndexType={rwaIndexType} handleRWAFilter={handleRWAFilter}
        driverVsNpsRWAData={driverVsNpsRWAData}
        handleRwaNpsInsight={handleRwaNpsInsight}
        getNameQuestionData={getNameQuestionData}
        isHappinessLadder={true}

      />


      default: return <div>Default Option</div>
    }
  }

  render() {
    let { optionType, DimensionList } = this.state;

    let { validateIndex, isHide } = this.props;

    let validateList = [];
    let validObj = Object.keys(validateIndex).map((key) => [key, validateIndex[key]])
    console.log('validObj: ', validObj);
    if (validObj && validObj.length > 0) {
      validObj.forEach((item) => {
        if (item[1]) {
          let getIndex = DimensionList.findIndex(prev => prev.index === parseInt(item[0]));
          if (getIndex !== -1 ? DimensionList[getIndex].name : "") {
            validateList.push({
              "name": getIndex !== -1 ? DimensionList[getIndex].name : "",
              "template": parseInt(item[0])
            });
          }

        }
      })
    }
    console.log('org validateList: ', validateList);




    return (
      <>
        {/* <main className="flex-1 w-full overflow-hidden overflow-y-auto h-[calc(100vh-4rem)] bg-[#f9fafb]">
      <div className="items-center justify-between h-10 px-4 bg-white border-b xl:flex lg:flex md:flex">
        <div className="flex overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 cursor-pointer whitespace-nowrap">
          <span onClick={()=>this.onOptionType(1)} className={(optionType===1)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Overview</span>
          <span onClick={()=>this.onOptionType(2,validateList)} className={(optionType===2)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>RWA</span>
          <span onClick={()=>this.onOptionType(3)} className={(optionType===3)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Item Ranking</span>
          <span onClick={()=>this.onOptionType(4)} className={(optionType===4)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Detailed Item Distribution</span>
        </div>
      </div>
          {this.onSwitchOptionScreen(validateList)}
      </main>  */}



        {/* side bar end */}
        <div className="md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto bg-[#f9fafb]">
          <div className="flex items-center justify-between text-gray-800">
            <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]  ">Organization Core</h1>

            {/* <span className="flex cursor-pointer material-symbols-outlined lg:hidden">menu_open</span> */}
          </div>
          {/*sub nav bar 1 */}
          <div className="items-center justify-between border-b  md:flex">
            <div className="flex space-x-2 overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 whitespace-nowrap tab-scroll scollar-xs">
              <span onClick={() => this.onOptionType(1)} className={optionType === 1 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}> Overview </span>

              {!isHide ?
                <span onClick={() => this.onOptionType(2, validateList)} className={optionType === 2 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                  "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}> RWA </span>
                : null}
              <span onClick={() => this.onOptionType(3)} className={optionType === 3 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}> Item Ranking </span>
              <span onClick={() => this.onOptionType(6)} className={optionType === 6 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}> Item Ranking Detail</span>
              <span onClick={() => this.onOptionType(4)} className={optionType === 4 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}> Detailed Item Distribution </span>

              {!isHide ?
                <span onClick={() => this.onOptionType(7)} className={optionType === 7 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                  "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}> Engagement Calculator</span>
                : null}
              {!isHide ?
                <span onClick={() => this.onOptionType(8, validateList)} className={optionType === 8 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                  "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}> Driver Vs NPS</span>
                : null}
              {!isHide ?
                <span onClick={() => this.onOptionType(9, validateList)} className={optionType === 9 ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                  "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}> Driver Vs Happiness Ladder</span>
                : null}
            </div>
          </div>

          {this.onSwitchOptionScreen(validateList)}

        </div>




      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(OrgCore);
