import React, { Component } from 'react';
import { connect } from 'react-redux';
import Filter from '../../../CommonComponents/Filters/IndexFilter/Filter'
import BarChartThemes from './Charts/BarChartThemes';
import HeatMapBlock from './Charts/HeatMapBlock';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import moment from 'moment';
import PageTemplate from "./PageTemplate";


class Overview extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    this.state = {
      showMainPage: true,
      showFilter: false,
      showValue: '',
      showTabView: 1

    }
  }

  componentDidMount() {

  }


  exportPDFWithComponent = () => {
    if (this.pdfExportComponent.current) {
      this.pdfExportComponent.current.save();
    }
  };


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  openSurveyResponse = () => {
    this.setState({ showOverview: false });

  }

  closeResponseAnalytics = () => {
    this.setState({ showOverview: true })
  }

  onOptionType = (value) => {
    this.setState({ optionType: value })
  }


  handleStrength = (params) => {
    let { handleThemeStrenght } = this.props;
    let { FilterValues } = this.state;
    let name = ''
    if (params && params.name) {
      name = params.name;
    }
    handleThemeStrenght(name, FilterValues);
    this.setState({ showMainPage: false })
  }


  handleMultifilterIndexScore2 = (finalFilter, name) => {

    let { handlAssessmentDashboard } = this.props;
    this.setState({
      FilterValues: finalFilter,
      ListName: name,
      showMainPage: true
    });

    handlAssessmentDashboard(finalFilter, true);

  }

  handleGOTOTheme = () => {
    this.setState({ showMainPage: true })
  }

  handleTabView = (value) => {
    this.setState({ showTabView: value })
  }

  render() {

    let { cronBachAlpha, loading, getAssessmentDimensionsData, getAssessmentDashboardData, getAssessmentByThemeData, EmpFilterData, getIndexByFilterDMGData, getEMPFilterLabel } = this.props;
    let { showMainPage, showTabView } = this.state;


    function spliceDecimal(no) {
      if (no) {
        let str = no.toString();
        let first = str.split('.')[0];
        let second = str.split('.')[1];
        let second2 = second ? second.substring(0, 2) : ""
        let final = first + '.' + second2;
        if (second2) {
          return parseFloat(final).toFixed(2)
        } else {
          return no
        }
      } else {
        return 0
      }
    }





    let themetotal = 0;
    let themeScoreList = [];
    if (getAssessmentDashboardData && getAssessmentDashboardData.length > 0) {
      themetotal = getAssessmentDashboardData.reduce(
        (a, v) => a + v.count,
        0
      );
      getAssessmentDashboardData.forEach((element) => {
        if (element && element._id) {
          themeScoreList.push({
            "name": element._id,
            "score": spliceDecimal((Number(element["count"]) * 100 / themetotal))
          })

        }
      })
    }


    let strenghttotal = 0;
    let strenghtScoreList = [];
    if (getAssessmentByThemeData && getAssessmentByThemeData.length > 0) {
      strenghttotal = getAssessmentByThemeData.reduce(
        (a, v) => a + v.count,
        0
      );
      getAssessmentByThemeData.forEach((element) => {
        if (element && element._id) {
          strenghtScoreList.push({
            "name": element._id,
            "score": spliceDecimal((Number(element["count"]) * 100 / strenghttotal))
          })

        }
      })
    }

    console.log("getAssessmentDimensionsData----->", getAssessmentDimensionsData)

    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }





    let AssessmentList = []
    if (getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0) {
      getAssessmentDimensionsData.forEach((element) => {
        AssessmentList.push({
          ...element,
          finalSum: (Number(element.count) * Number(element.rankSum))
        })
      })
      // AssessmentList.sort((a, b) => (a.finalSum - b.finalSum))
      AssessmentList.sort((b, a) => (a.finalSum - b.finalSum))
    }

    console.log("AssessmentList--1->", AssessmentList)


    let newList = []
    if (AssessmentList && AssessmentList.length > 0) {
      AssessmentList.forEach((element, index) => {

        // let rank = (AssessmentList.length - index);
        let rank = index + 1;

        let check = 0;
        if (showTabView === 1) {
          check = 1
        } else if (showTabView === 2 && (rank <= 5)) {
          check = 2
        } else if (showTabView === 3 && (rank <= 7)) {
          check = 3
        } else if (showTabView === 4 && (rank <= 10)) {
          check = 4
        }
        console.log("check--1->", check)

        let getIndex = newList.findIndex(prev => prev.bucket === element.bucket)
        if (getIndex === -1) {
          let temp = {
            "showCount": 0,
            "bucket": element.bucket,
            "colorCode": element.colorCode,
            "dimensions": [{
              "rank": rank,
              "check": check,
              "name": element.dimension,
              "count": element.count,
              "finalSum": element.finalSum,
              "colorCode": element.colorCode,
            }],
          }
          newList.push(temp)
        } else {
          newList[getIndex]["showCount"] += 1

          newList[getIndex]["dimensions"].push({
            "rank": rank,
            "check": check,
            "name": element.dimension,
            "count": element.count,
            "finalSum": element.finalSum,
            "colorCode": element.colorCode,
          })
        }



      });
    }








    return (
      <>
        <div className="items-center justify-between md:flex">






          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />



        </div>


        <div className="w-full mt-4" >

          <div className="grid grid-cols-1 gap-4 md:grid-cols-1 ">

            <div className="p-4 bg-white border">

              <div className="flex items-center justify-between pb-2">
                <div className='flex'>
                  {!showMainPage ?
                    <div className="flex items-center mr-4 space-x-4 lg:space-x-8">
                      <span onClick={() => this.handleGOTOTheme()} className="p-2 text-blue-500 duration-150 rounded-full cursor-pointer material-symbols-outlined bg-blue-50 hover:bg-blue-100 trasition" style={{ fontSize: '18px' }}>arrow_back</span>
                    </div>
                    : null}
                  <h1 className="pb-2 mt-2 text-base font-medium">Overall Dominant Themes</h1>
                </div>
                <h1 className="bg-[#212121]/10 p-2 px-4 rounded-md border text-[#3D405B] text-sm font-medium space-x-2 flex items-center">
                Cronbach's alpha: <span className='text-lg font-semibold text-[#212121] pl-1'>{cronBachAlpha.score}</span>
                </h1>
              </div>

              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-2">

                  <button onClick={() => this.handleTabView(1)} className={(showTabView === 1 ? "border border-[#2196f3] " : "") + " bg-white px-3 border h-10 flex  items-center justify-center rounded-md text-sm font-medium text-slate-800 space-x-1"}>
                    <span className={showTabView === 1 ? "font-semibold text-[#2196f3]" : "text-slate-800"}>
                      {" "}
                      Overall
                    </span>{" "}
                  </button>

                  <button onClick={() => this.handleTabView(2)} className={(showTabView === 2 ? "border border-[#2196f3] " : "") + " bg-white px-3 border h-10 flex  items-center justify-center rounded-md text-sm font-medium text-slate-800 space-x-1"}>
                    <span className={showTabView === 2 ? "font-semibold  text-[#2196f3]" : "text-slate-800"}>
                      {" "}
                      Top 5
                    </span>{" "}
                  </button>

                  <button onClick={() => this.handleTabView(3)} className={(showTabView === 3 ? "border border-[#2196f3] " : "") + " bg-white px-3 border h-10 flex  items-center justify-center rounded-md text-sm font-medium text-slate-800 space-x-1"}>
                    <span className={showTabView === 3 ? "font-semibold text-[#2196f3]" : "text-slate-800"}>
                      {" "}
                      Top 7
                    </span>{" "}
                  </button>

                  <button onClick={() => this.handleTabView(4)} className={(showTabView === 4 ? "border border-[#2196f3] " : "") + " bg-white px-3 border h-10 flex  items-center justify-center rounded-md text-sm font-medium text-slate-800 space-x-1"}>
                    <span className={showTabView === 4 ? "font-semibold text-[#2196f3]" : "text-slate-800"}>
                      {" "}
                      Top 10
                    </span>{" "}
                  </button>

                </div>

                <div>
                  <button onClick={() => this.exportPDFWithComponent()} className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                    > <path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M14 3v4a1 1 0 0 0 1 1h4" /> <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /> <path d="M10 12l4 4m0 -4l-4 4" />
                    </svg>
                    <span>{"Download"}</span>
                  </button>
                </div>
              </div>


              <PDFExport ref={this.pdfExportComponent}
                pageTemplate={(pageObj) => <PageTemplate pageNum={pageObj.pageNum} />}
                scale={0.6}
                forcePageBreak=".page-break"
                paperSize="A4"
                margin={0}
                fileName={`Assessment Dominant Themes - ${moment(Date.now()).format("DD/MM/YYYY")}`}
                author="Happyplus Team"
                landscape={true}
              >

                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
                  {newList && newList.length > 0 ?
                    newList.map((item, index) =>
                      <>
                        <div className={''}>
                          <h1 className='text-lg text-center text-[#3D405B] font-semibold bg-[#F2F2F2] px-4 py-3 mb-2'>{item.bucket}</h1>
                          <HeatMapBlock item={item} />
                        </div>
                      </>
                    ) : null}
                </div>



                {/* <div className="grid grid-cols-1 gap-4 md:grid-cols-4 ">

                  {newList && newList.length > 0 ?
                    newList.map((item, index) =>
                      <>
                        <div className={'border p-4 '}>
                          <h1 className='text-base font-medium'>{item.bucket}</h1>
                          <HeatMapBlock
                            item={item}
                          />
                        </div>
                      </>
                    ) : null}
                </div> */}

                {!(newList && newList.length > 0) ?
                  <>
                    {!loading ?
                      <div className="pt-16 pb-24 text-center text-xl text-[#3d405B]/70">No Data Available</div>
                      : null}
                  </>
                  : null}



              </PDFExport>





            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
