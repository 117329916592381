import React, { Component } from 'react';
import { connect } from 'react-redux';

import ClusterAnalysis from './Components/ClusterAnalysis/ClusterAnalysis';


class AdvanceComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 1,
      DimensionList: [
        {
          index: 1,
          name: 'Happiness',
          cutOff: 59
        },
        {
          index: 2,
          name: 'Engagement',
          cutOff: 78

        },
        {
          index: 4,
          name: 'Stress',
          cutOff: 28
        },
        {
          index: 5,
          name: 'Manager',
          cutOff: 28

        },
        {
          index: 6,
          name: 'Leader',
          cutOff: 28

        }
      ],
    }
  }
  componentDidMount() {

  }

  onOptionType = (val, validateList) => {
    let { EmpFilterData, handleMultiFilterAdvancScatter, IndexDimension, handleCorelation, onCrossTab, handleQuadrantFilter, onClusterAnalysis } = this.props;


    this.setState({ optionType: val })

  }

  onSwitchOptionScreen = (validateList) => {
    let {
      clusterAnalysisData, clusterAnalysisCount,
      indexType, EmpFilterData, getIndexFilterData, getCommentData, loading, questionsList,

      getAllBenchMarkData, getIndexScore, handleSummaryFilter, handleQuadrantFilter, handleSummaryCard, getDemographicIndexData, getIndexData, getIndexDistributionData,
      getQuestionData, getQuestionFilterData, handleQuestionFilter, handleCorrelationFilter,
      getDistributionIndexData, handleDistributionFilter, getIndexDriverData,

      handleCorelation,

      getDemographicIndexScatterData,


      getHappinessCorrelationData,
      getEngagementCorrelationData,
      getStressCorrelationData,
      getManagerCorrelationData,
      getLeaderCorrelationData,

      handleMultiFilterAdvanc4Quadrant,
      handleMultiFilterAdvancScatter,

      crossTabAnalysisData,


      getRWAnalysisData,


      handleMultiFilterCorrelation,
      handleCrossTabApi,
      crossTabData,

      IndexDimension,

      GetParameterLabel,

      getCorrelationData,
      getCorrelationFilterData,
      getSurveyData,
      CountRestrict,
      getIndexByFilterDMGData,
      getClusterEmpData,
      getClusterEmpInfoListData,
      getEMPFilterLabel,

      onClusterAnalysis,

      clusterAnalysisMSG,
      clusterAnalysisError,
      ErrorOtherObj

    } = this.props;

    let { optionType } = this.state;

    switch (optionType) {

      // case 1: return <QuadrantData  getIndexByFilterDMGData={getIndexByFilterDMGData}
      // CountRestrict={CountRestrict} validateList={validateList}    loading={loading} handleMultiFilterAdvanc4Quadrant={handleMultiFilterAdvanc4Quadrant}

      // getEMPFilterLabel={getEMPFilterLabel}

      //    optionType={optionType} getCommentData={getCommentData}
      //   getDemographicIndexData={getDemographicIndexData} handleQuadrantFilter={handleQuadrantFilter}
      //   indexType={indexType} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} 
      //   getAllBenchMarkData={getAllBenchMarkData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard}  getIndexData={getIndexData} />


      // case 2: return <BestFitData getIndexByFilterDMGData={getIndexByFilterDMGData}
      // CountRestrict={CountRestrict}  validateList={validateList}     loading={loading} handleMultiFilterAdvancScatter={handleMultiFilterAdvancScatter}


      // optionType={optionType} getCommentData={getCommentData}

      // getDemographicIndexScatterData={getDemographicIndexScatterData}

      // getEMPFilterLabel={getEMPFilterLabel}


      // getDemographicIndexData={getDemographicIndexData} handleQuadrantFilter={handleQuadrantFilter}

      // indexType={indexType} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} 
      // getAllBenchMarkData={getAllBenchMarkData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard}  getIndexData={getIndexData} />

      // case 3: return <Corelation  getIndexByFilterDMGData={getIndexByFilterDMGData}
      // CountRestrict={CountRestrict}  GetParameterLabel={GetParameterLabel} IndexDimension={IndexDimension} validateList={validateList}  loading={loading} handleMultiFilterCorrelation={handleMultiFilterCorrelation}
      // optionType={optionType} getCommentData={getCommentData}
      // getSurveyData={getSurveyData}

      // getHappinessCorrelationData={getHappinessCorrelationData}
      // getEngagementCorrelationData={getEngagementCorrelationData}
      // getStressCorrelationData={getStressCorrelationData}
      // getManagerCorrelationData={getManagerCorrelationData}
      // getLeaderCorrelationData={getLeaderCorrelationData}

      // handleCorrelationFilter={handleCorrelationFilter}


      // getDemographicIndexData={getDemographicIndexData} handleQuadrantFilter={handleQuadrantFilter}


      // handleCorelation={handleCorelation}


      // getCorrelationData={getCorrelationData}
      // getCorrelationFilterData={getCorrelationFilterData}

      // getEMPFilterLabel={getEMPFilterLabel}


      // indexType={indexType} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} 
      // getAllBenchMarkData={getAllBenchMarkData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard} getIndexData={getIndexData} />

      // case 4: return <CrossTab getIndexByFilterDMGData={getIndexByFilterDMGData}

      // questionsList={questionsList}
      // handleCrossTabApi={handleCrossTabApi}
      // crossTabData={crossTabData}
      // EmpFilterData={EmpFilterData} 
      // getIndexFilterData={getIndexFilterData} 
      // crossTabAnalysisData={crossTabAnalysisData}

      // getEMPFilterLabel={getEMPFilterLabel}

      // />

      case 1: return <ClusterAnalysis getIndexByFilterDMGData={getIndexByFilterDMGData}
        CountRestrict={CountRestrict} validateList={validateList} loading={loading} handleMultiFilterAdvanc4Quadrant={handleMultiFilterAdvanc4Quadrant}

        optionType={optionType} getCommentData={getCommentData}
        getDemographicIndexData={getDemographicIndexData} handleQuadrantFilter={handleQuadrantFilter}
        indexType={indexType} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getAllBenchMarkData={getAllBenchMarkData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard} getIndexData={getIndexData}




        clusterAnalysisData={clusterAnalysisData}
        clusterAnalysisCount={clusterAnalysisCount}
        getClusterEmpData={getClusterEmpData}
        getClusterEmpInfoListData={getClusterEmpInfoListData}

        getEMPFilterLabel={getEMPFilterLabel}

        onClusterAnalysis={onClusterAnalysis}

        clusterAnalysisMSG={clusterAnalysisMSG}

        clusterAnalysisError={clusterAnalysisError}

        ErrorOtherObj={ErrorOtherObj}

      />



      default: return <div>Default Option</div>
    }
  }

  render() {
    let { CurrentSurveyId, indexType, getDemographicIndexData } = this.props;
    let { optionType } = this.state;




    let { DimensionList } = this.state;

    let { validateIndex } = this.props;

    let validateList = [];
    let validObj = Object.keys(validateIndex).map((key) => [key, validateIndex[key]])
    ////console.log('validObj: ',validObj);
    if (validObj && validObj.length > 0) {
      validObj.forEach((item) => {
        if (item[1]) {
          let getIndex = DimensionList.findIndex(prev => prev.index === parseInt(item[0]));
          if (getIndex !== -1 ? DimensionList[getIndex].name : "") {
            validateList.push({
              "name": getIndex !== -1 ? DimensionList[getIndex].name : "",
              "template": parseInt(item[0])
            });
          }

        }
      })
    }
    ////console.log('validateList: ',validateList);






    return (
      <>
        {/* <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto ">
        
        <nav className="flex px-6 overflow-x-auto bg-white scroll justify-left overscroll-x-contain">
          <div className="flex cursor-pointer justify-left">
            <a onClick={()=>this.onOptionType(1)} className={(optionType===1)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            4 Quadrant Matrix
            </a>
             <a onClick={()=>this.onOptionType(2)} className={(optionType===2)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            Scatter Plot 
            </a>
            <a onClick={()=>this.onOptionType(3,validateList)} className={(optionType===3)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            Correlation
            </a>
            <a onClick={()=>this.onOptionType(4)} className={(optionType===4)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            CrossTab
            </a>
            <a onClick={()=>this.onOptionType(5)} className={(optionType===5)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            Cluster Analysis
            </a>
          </div>
        </nav>

          {this.onSwitchOptionScreen(validateList)}
      </main>  */}


        <div className="md:p-6 p-4 space-y-6 w-full flex-1 overflow-y-auto bg-[#f9fafb]">

          {this.onSwitchOptionScreen(validateList)}

        </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AdvanceComparision);
