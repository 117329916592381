import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filter from './Filter';
import HeatMap from './Components/HeatMap';
import DonutChart from '../../../../Overview/Components/QuestionTypeCards/Charts/DonutChart';
import HorizontalBarChart from '../../../../Overview/Components/QuestionTypeCards/Charts/HorizontalBarChart';
import HeatMapNPS from './Components/HeatMapNPS';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      viewScore: true,
      showLeaderBoard: false,
      showFilter: false,
      showValue: '',
      XAxis: ""
    }
  }
  componentDidMount() {

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleFilterSave = (name) => {
    this.setState({ currFilterName: name })
  }

  handleMultifilterIndexScore2 = (finalFilter, XAxis) => {
    //console.log(':----------------------------------------------------------------------------------------------------------------XAxis:-------------------->',finalFilter, XAxis)

    let { handleOthersFilterCall, templateId } = this.props;
    this.setState({ FilterValues: finalFilter, XAxis: XAxis });
    handleOthersFilterCall(finalFilter, XAxis, templateId);

  }

  render() {
    let {
      getQuestionDMGResultData, getQuestionOthersResultData, currentQuestionId, EmpFilterData, getQuestionOthersResultFilterData,
      templateId, getIndexFilterData } = this.props;

    let { currFilterName, FilterValues, XAxis } = this.state;

    //-------------------------------------------------------------------------------------------------------------------------
    let nEwAxis = "";
    if (XAxis) {
      nEwAxis = XAxis;
    }
    else if (EmpFilterData && EmpFilterData.length > 0) {
      nEwAxis = EmpFilterData[0].name;
    }


    let quesData = getQuesDataFn(getQuestionDMGResultData, currentQuestionId);

    let QuestionName = quesData && quesData.question && quesData.question.name ? quesData.question.name : "";

    function getQuesDataFn(list, quesId) {
      if (list && list.length > 0) {
        let getIndex = list.findIndex(prev => prev.question.id.toString() === quesId.toString());
        if (getIndex !== -1) {
          return list[getIndex]
        }
      }
      return {}
    }


    console.log("templateId------------>",templateId)

    return (
      <>
        <div className="items-center justify-between h-10 xl:flex lg:flex md:flex">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData}
            getIndexFilterData={getIndexFilterData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />
        </div>

        <div onClick={() => this.closeFilter(false)} className="">
          <div className="flex w-full pt-6" >

            <div className="w-full p-5 bg-white border rounded mb-60">
              <div className="flex items-center justify-between px-1 text-base font-semibold text-gray-700">
                <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] mb-4">{QuestionName}
                  <p className="text-base font-semibold text-gray-700">
                    <span className="text-blue-500">{(currFilterName !== 'none') ? "( " + currFilterName + " wise )" : ""}</span></p>
                </h1>
              </div>
              {templateId === 21 ?
                <HeatMap
                  item={quesData}
                />
                : null}
              {templateId === 22 ?
                <HeatMapNPS
                  item={quesData}
                />
                : null}

            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
