import React from "react";
export default function ConfirmMail(props) {
  let { handleSubmitCopyParaLibrary, handleCollectParaLib, getParaLibList, isBucket,  handleNewParameterSubmit, handleConfirmMailClose , parameterFormData, handleNewParameterInput, } = props;

  let [ showParaLibScreen, handleParaLibScreen ] = React.useState(false);


  // console.log('getParaLibList--->',getParaLibList)

  return (
   <>
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full" >
        <div className="absolute left-0 right-0 max-w-xl p-4 mx-auto mt-24 overflow-hidden md:relative">
          <div onClick={()=>handleConfirmMailClose(false)} className="absolute top-0 right-0 inline-flex items-center justify-center w-10 h-10 text-gray-500 bg-white rounded-full shadow cursor-pointer hover:text-gray-800" >
            <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>
          
          
          
          <div className="block w-full overflow-hidden bg-white rounded-lg shadow ">
            <div className="p-3 text-white bg-blue-600 cursor-default">Create New Parameter</div>
            
            
        {showParaLibScreen?
        <>
        <div className="p-5 bg-white">
        <div className="justify-between pb-10">
          
        <div className="grid grid-cols-3">
            {getParaLibList && getParaLibList.length>0?
              getParaLibList.map((para,index)=>
                <div key={index} onClick={()=>handleCollectParaLib(para)} className="inline-flex items-center w-full px-4 py-3 text-xs border-b cursor-pointer "> 
                  <div><input type="checkbox" value={true} className="m-2 cursor-pointer"/></div>
                  <div className="font-semibold">{para.name}</div>
                </div>
              )
            :null}
        </div>
        </div>


        <div className="flex items-center justify-center pt-5">
            <button onClick={()=>{handleSubmitCopyParaLibrary(); handleParaLibScreen(false);}} className="w-3/12 px-2 py-1 mx-2 text-white bg-blue-600 rounded-md cursor-pointer">OK</button>                  
            <button  onClick={()=>handleParaLibScreen(false)} className="w-3/12 px-2 py-1 mx-2 text-white bg-blue-600 rounded-md">Cancel</button>
        </div>



        </div>
        </>
        : 
        <div className="p-5 bg-white">



          <div className="justify-between pb-24">


          <input type="text" name="name" value={parameterFormData.name} onChange={handleNewParameterInput} 
          className="w-full p-2 mt-2 text-sm border rounded-md focus:outline-none focus:ring-2" placeholder={isBucket?"Bucket Name":"Dimension Name"} />
          <br />
          <input type="text" name="label" value={parameterFormData.label} onChange={handleNewParameterInput} 
          className="w-full p-2 mt-2 text-sm border rounded-md focus:outline-none focus:ring-2" placeholder={isBucket?"Bucket Label":"Dimension Label"} />
          <br />

          {isBucket?
          <div className="flex my-10">
            <div className="px-2 py-1 font-bold">Or Choose from</div>
            <div onClick={()=>handleParaLibScreen(true)} className="px-2 py-1 text-white bg-gray-500 rounded-sm cursor-pointer hover:bg-blue-500">Parameter Library</div>
          </div>
          :null}

        

          </div>









              <div className="flex items-center justify-center pt-10">
                  <button onClick={()=>handleNewParameterSubmit()} className="w-3/12 px-2 py-1 mx-2 text-white bg-blue-600 rounded-md cursor-pointer">OK</button>                  
                  <button  onClick={()=>handleConfirmMailClose(false)} className="w-3/12 px-2 py-1 mx-2 text-white bg-blue-600 rounded-md">Cancel</button>
              </div>






            </div>

        }


          </div>
      



        </div>
        </div>
   </>
  );
}
