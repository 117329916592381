import React, { Component } from 'react'


export default class Header extends Component {
    render() {
        let { surveyLogo } = this.props;
        console.log("surveyLogo---------->",surveyLogo)
        return (
            <>
                <header className="bg-white   bg-white  h-20 flex items-center">
                    <div className=" capitalize w-full px-8 mx-auto flex justify-between  ">
                    <div className="flex items-center"> {surveyLogo?<img alt="item" src={surveyLogo} className="w-36" />:null}</div>
                    <div className="flex items-center"> <img alt="item" src="img/logo/happypluslogo.png" className="w-32" /></div>
                    </div>
                </header>
            </>
        )
    }
}
