import React, { Component } from 'react';
import { connect } from 'react-redux';
import DemographicFilter from '../TopicCloud/Filters/DemographicFilter';
import QuestionFilter from "../Filters/QuestionFilter";
import FeedbackFilter from '../Filters/FeedbackFilter';
import { MdCheck, MdOutlineAdd } from "react-icons/md";
import ReactEcharts from "echarts-for-react";
import { CSVLink } from "react-csv";


class TextAnalysis extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentQuesId: '',
			DemographicFilterName: '',
			DemographicFilterValue: '',
			FilterList: [],
			showFilter: false,
			showValue: '',

			showViewBy: false,

			isOpen: false,
			isModal: false,
			crrKeyword: "",
			sentimentType: "All",
			suggestion: "suggestion",



			currentEmpPage: 1,
			EmpIndexA: 0,
			EmpIndexB: 4,
			pageCount: 10,

			showViewDMGBy: false,
			currentDMGId:""

		}
	}



	componentDidMount() {
	}




	toggleDropdown = () => {
		this.setState((prevState) => ({
			isOpen: !prevState.isOpen,
		}));
	};
	modalhandle = () => {
		this.setState((prevState) => ({
			isModal: !prevState.isModal,
		}));
	};

	handleKeyword = (value) => {
		// console.log("value-crrKeywordcrrKeywordcrrKeyword--->", value)
		this.setState({ crrKeyword: value });
	}

	handleSentiment = (value) => {
		this.setState({ sentimentType: value, currentEmpPage: 1 });
	}

	closeFilter = (check1, check2) => {
		this.setState({ showFilter: check1, showValue: check2 });
	}

	closeFilterQues = (check1) => {
		this.setState({ showViewBy: check1 });
	}

	closeFilterDMG = (check1) => {
		this.setState({ showViewDMGBy: check1 });
	  }
	

	handleChangeQuesIdSave = (Id) => {
		this.setState({ currentQuesId: Id })
	}

	FilterSave = (name, value) => {
		this.setState({ DemographicFilterName: name, DemographicFilterValue: value })
	}

	handleKeywordCall = (keyWord) => {
		let { currentQuesId, DemographicFilterName, DemographicFilterValue, FilterList } = this.state;
		let { getTextQuestionData, callTopicCloudText } = this.props;

		let initQuesId = '';
		if (currentQuesId) {
			initQuesId = currentQuesId;
		}
		else {
			initQuesId = getTextQuestionData && getTextQuestionData.length > 0 ? getTextQuestionData[0].id : '';
		}
		callTopicCloudText(initQuesId, keyWord, FilterList)
	}


	handleMultifilterIndexScore2 = (finalFilter) => {
		let { getTextQuestionData } = this.props;
		let { currentQuesId, graphType } = this.state;
		let initQuesId = '';
		if (currentQuesId) {
			initQuesId = currentQuesId;
		}
		else {
			initQuesId = getTextQuestionData && getTextQuestionData.length > 0 ? getTextQuestionData[0].id : '';
		}
		let { handleTopicCloudDemographicFilter } = this.props;
		this.setState({ FilterList: finalFilter });
		handleTopicCloudDemographicFilter(initQuesId, finalFilter, graphType, "wordCloud");
	}

	callQuestionWordCloudQuesChange = (initQuesId) => {
		let { callTopicCloudText, handleTopicCloudDemographicFilter } = this.props;
		let { FilterList } = this.state;
		// callTopicCloudText(initQuesId, "", FilterList);
		handleTopicCloudDemographicFilter(initQuesId, FilterList, "", "wordCloud");
	}

	SelectEmpPage = (val) => {
		// console.log('currentEmpPagecurrentEmpPagecurrentEmpPagecurrentEmpPage==============>', val);
		this.setState({ currentEmpPage: val });
	};

	prevEmpPage = () => {
		let { EmpIndexA, EmpIndexB } = this.state;
		if (EmpIndexA > 0) {
			this.setState({ EmpIndexA: EmpIndexA - 1, EmpIndexB: EmpIndexB - 1 });
		}
	};

	nextEmpPage = (IndexListIndex) => {
		let { EmpIndexA, EmpIndexB } = this.state;
		if (
			IndexListIndex &&
			IndexListIndex.length &&
			EmpIndexB < IndexListIndex.length
		) {
			this.setState({ EmpIndexA: EmpIndexA + 1, EmpIndexB: EmpIndexB + 1 });
		}
		// console.log('EmpIndexAEmpIndexAEmpIndexAEmpIndexAEmpIndexA==============>', EmpIndexA);
		// console.log('EmpIndexBEmpIndexBEmpIndexB==============>', EmpIndexB);
	};


	callDMGWordCloudChange = (InitDMG) => {
		let { handleDMGCloudDemographicFilter, getTextQuestionData } = this.props;
		let { FilterList, currentQuesId } = this.state;
	
		let initQueId = "";
		if (getTextQuestionData && getTextQuestionData.length > 0) {
		  if (currentQuesId) {
			initQueId = currentQuesId;
		  } else {
			initQueId = getTextQuestionData[0]["id"];
		  }
		}
		// callWordCloudText(initQuesId, "", FilterList);
		handleDMGCloudDemographicFilter(initQueId, FilterList, InitDMG, "topicCloud");
	
	  }
	
	  handleChangeDMGIdSave = (Id) => {
		this.setState({ currentDMGId: Id })
	  }


	render() {

		const { isOpen, isModal, crrKeyword, sentimentType, suggestion } = this.state;

		const { topicCloudData } = this.props;

		// console.log('suggestionsuggestionsuggestionsuggestionsuggestion', suggestion);

		let { GetParameterLabel, ErrorOtherObj, wordCloudError, loading, wordTextKeyWord, wordCloudTextData, getTextQuestionData, handleChangeQuestion, wordCloudData, EmpFilterData,
			getIndexByFilterDMGData, isDMGText, getAllFeedbackWordsData } = this.props;

		let { currentQuesId } = this.state;

		//console.log('getTextQuestionData: ',getTextQuestionData)

		let initQuesId = '';


		if (currentQuesId) {
			initQuesId = currentQuesId;
		}
		else {
			initQuesId = getTextQuestionData && getTextQuestionData.length > 0 ? getTextQuestionData[0].id : '';
		}


		let { CountRestrict } = this.props;
		let count = wordCloudData && wordCloudData.count ? wordCloudData.count : "NaN";
		let CloudData = CountRestrict(count) ? [] : wordCloudData;
		let CloudTextData = CountRestrict(count) ? [] : wordCloudTextData;
		let wordTextKeyWordData = CountRestrict(count) ? "" : wordTextKeyWord;
		let newWordList = CountRestrict(count) ? [] : (wordCloudData && wordCloudData.list && wordCloudData.list.length > 0 ? wordCloudData.list : []);



		let maxCount = 0;
		if (newWordList && newWordList.length > 0) {
			newWordList.forEach((item) => {
				if (item[1] > maxCount) {
					maxCount = item[1];
				}
			});
		}
		let topicObjList = topicCloudData && topicCloudData.length > 0 ? topicCloudData.map((ele) => ({
			"sentence": ele["sentence"],
			"sentiment": ele["sentiment"],
			"suggestion": ele["suggestion"],
			"keyword": correctWord(ele["keyword"])
		})) : [];

		const groupTopics = topicObjList.reduce((groups, item) => {
			const group = (groups[item.keyword] || []);
			group.push(item);
			groups[item.keyword] = group;
			return groups;
		}, {});






		function circleChartFn(name, list) {

			// console.log("groupTopics-name1111111111111111111---->", name);

			let positive = 0;
			let negative = 0;
			let neutral = 0;

			if (list && list.length > 0) {
				list.forEach((ele) => {
					if (ele.sentiment === "POSITIVE") {
						positive++
					} else if (ele.sentiment === "NEGATIVE") {
						negative++
					} else if (ele.sentiment === "Neutral") {
						neutral++
					}
				})
			}

			return (<>
				<div className="relative flex justify-center percent" >
					<ReactEcharts
						className='!w-full !h-52'
						option={{
							tooltip: {
								trigger: 'item',
							},
							// legend: {
							//   top: '5%',
							//   left: 'center',
							// },
							series: [
								{
									name: name,
									type: 'pie',
									radius: ['60%', '70%'],
									avoidLabelOverlap: false,
									label: {
										show: false,
										position: 'center'
									},
									emphasis: {
										label: {
											show: false,
											fontSize: 40,
											fontWeight: 'bold'
										}
									},
									labelLine: {
										show: false
									},
									data: [
										{
											name: "Positive",
											value: positive,
											itemStyle: {
												color: '#33cc33'
											}
										},
										{
											name: "Negative",
											value: negative,
											itemStyle: {
												color: '#e60000'
											}
										},
										{
											name: "Neutral",
											value: neutral,
											itemStyle: {
												color: '#ffb31a'
											}
										}
									]
								}
							]
						}}
					/>
					<div
						style={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							textAlign: 'center'
						}}
					>
						{/* {sentimentList.map((ele) => */}

						<span className='cursor-pointer' style={{ fontSize: "15px" }}>{name} <br />
							<span className='font-semibold'>{name && groupTopics && groupTopics[name] && groupTopics[name].length > 0 ? groupTopics[name].length : 0}</span>
						</span>
						{/* )} */}
					</div>
				</div>
			</>)
		}


		let topic_list = Object.keys(groupTopics);
		console.log("groupTopics--->", groupTopics)
		if (topic_list && topic_list.length > 0) {
			topic_list.sort((b, a) => groupTopics[a].length - groupTopics[b].length)
		}



		let Keyword = "";
		if (crrKeyword) {
			Keyword = crrKeyword;
		} else {
			Keyword = topic_list && topic_list.length > 0 ? topic_list[0] : "";
		}

		let textList = [];

		let allList = {
			"All": [],
			"Positive": [],
			"Negative": [],
			"Neutral": []
		}

		if (Keyword && groupTopics[Keyword] && groupTopics[Keyword].length > 0) {
			allList["All"] = groupTopics[Keyword].sort((b, a) => a.sentence.length - b.sentence.length);
			allList["Positive"] = groupTopics[Keyword].filter(prev => prev.sentiment.toUpperCase() === "POSITIVE").sort((b, a) => a.sentence.length - b.sentence.length);
			allList["Negative"] = groupTopics[Keyword].filter(prev => prev.sentiment.toUpperCase() === "NEGATIVE").sort((b, a) => a.sentence.length - b.sentence.length);
			allList["Neutral"] = groupTopics[Keyword].filter(prev => prev.sentiment.toUpperCase() === "NEUTRAL").sort((b, a) => a.sentence.length - b.sentence.length);

			if (sentimentType === "All") {
				textList = allList["All"];
			}
			if (sentimentType === "Positive") {
				textList = allList["Positive"];
			}
			if (sentimentType === "Negative") {
				textList = allList["Negative"];
			}
			if (sentimentType === "Neutral") {
				textList = allList["Neutral"];
			}
		}

		let sentimentList = [
			{
				"name": "All",
				"color": '#212121'
			},
			{
				"name": "Positive",
				"color": '#33cc33'
			},
			{
				"name": "Negative",
				"color": '#e60000'
			},
			{
				"name": "Neutral",
				"color": '#ffb31a'
			}
		]

		// console.log('sentimentListsentimentListsentimentListsentimentListsentimentListsentimentList', sentimentList);

		function getColor(ele, value1, valueList) {
			let color = "#757575";
			let valueX = "";
			if (value1 === "All") {
				if (ele.sentiment === "POSITIVE") {
					valueX = "Positive";
				} else if (ele.sentiment === "NEGATIVE") {
					valueX = "Negative";
				} else if (ele.sentiment === "Neutral") {
					valueX = "Neutral";
				}
			} else {
				valueX = value1;
			}
			let getIndex = valueList.findIndex(prev => prev.name === valueX);
			if (getIndex !== -1) {
				color = valueList[getIndex]["color"]
			}
			return color
		}

		let { currentEmpPage, EmpIndexA, EmpIndexB, pageCount } = this.state;

		let IndexList = [];
		let IndexListIndex = [];
		let indexA = 0;
		let indexB = 0;

		if (textList && textList.length > 0) {
			if (textList && textList.length > 0) {
				textList.forEach((item, index) => {
					if (index % pageCount === 0) {
						IndexList.push(index);
					}
					if (index === textList.length - 1) {
						IndexList.push(index);
					}
				});
			}

			console.log('IndexList==================>: ', IndexList)

			if (IndexList && IndexList.length > 0) {
				IndexList.forEach((item, index) => {
					if (index !== IndexList.length - 1) {
						IndexListIndex.push(index + 1);
					}
				});
			}
			console.log('textList====================>: ', textList)

			if (IndexListIndex && IndexListIndex.length > 0) {
				IndexListIndex.forEach((item, index) => {
					if (item === currentEmpPage) {
						if (index < IndexList.length - 2) {
							indexA = IndexList[index];
							indexB = IndexList[index] + (pageCount - 1);
						} else {
							indexA = IndexList[index];
							indexB = IndexList[IndexList.length - 1];
						}
					}
				});
			}
		}


		function correctWord(text) {
			if (text === "Work_Life_Balance") {
				return "Work Life Balance"
			}
			else {
				return text
			}
		}






		return (
			<>

				{isDMGText ?
					<div onClick={() => this.closeFilter(false)} className='flex space-x-2' >

						<div className='flex flex-wrap items-start justify-between w-[70%] gap-4' >

							<QuestionFilter
								showViewBy={this.state.showViewBy}
								closeFilterQues={this.closeFilterQues}

								callQuestionWordCloud={this.callQuestionWordCloudQuesChange}
								getTextQuestionData={getTextQuestionData}
								handleChangeQuestion={handleChangeQuestion}
								handleChangeQuesIdSave={this.handleChangeQuesIdSave}
							/>

						</div>
						<div className='flex flex-wrap items-start justify-between w-[30%] gap-4' >

							<FeedbackFilter
								showViewBy={this.state.showViewDMGBy}
								closeFilterQues={this.closeFilterDMG}

								callQuestionWordCloud={this.callDMGWordCloudChange}

								getTextQuestionData={getAllFeedbackWordsData && getAllFeedbackWordsData.length > 0 ?
									getAllFeedbackWordsData.map((ele) => ({ "id": ele, "name": ele })) : []}

								handleChangeQuestion={handleChangeQuestion}
								handleChangeQuesIdSave={this.handleChangeDMGIdSave}
							/>

						</div>
					</div>

					:
					<div onClick={() => this.closeFilter(false)} >

						<div className='flex flex-wrap items-start justify-between w-full gap-4' >

							<QuestionFilter
								showViewBy={this.state.showViewBy}
								closeFilterQues={this.closeFilterQues}

								callQuestionWordCloud={this.callQuestionWordCloudQuesChange}
								getTextQuestionData={getTextQuestionData}
								handleChangeQuestion={handleChangeQuestion}
								handleChangeQuesIdSave={this.handleChangeQuesIdSave}
							/>

						</div>
					</div>}



				{/*sub nav bar 2 */}
				<div className="items-center justify-between space-y-4 md:flex lg:space-y-0">
					<DemographicFilter
						showFilter={this.state.showFilter}
						showValue={this.state.showValue}
						closeFilter={this.closeFilter}

						EmpFilterData2={EmpFilterData}
						getIndexFilterData={getIndexByFilterDMGData}
						handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
					/>


					{/* <div  className='flex space-x-2 '>
             <NudgesTextDist
                name={"Topic Cloud"}
                list = {CloudData && CloudData.list && CloudData.list.length>0?CloudData.list:[]}
              />
                <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />
             </div> */}

				</div>


				{/* {ErrorOtherObj && ErrorOtherObj["topicCloudTextError"]?
        <>
          <div className='my-24 text-2xl font-medium text-center text-gray-400'>{ErrorOtherObj["topicCloudTextError"]}</div>
        </>
        :
        <>
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-2 ">

          <Topics topicCloudError={topicCloudError} loading={loading} topicCloudData={CloudData} handleKeytopicCall={this.handleKeytopicCall} />


          </div>
      </>
      } */}



				<div className="grid grid-cols-1 gap-4 xl:grid-cols-2 ">
					{/* Left side design code */}
					<div className="transition duration-150 bg-white border rounded-lg hover:shadow-lg">
						<div className="flex items-center justify-between px-6 py-4">
							<h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap ">
								Topic Cloud
							</h1>
							<div className="flex items-center space-x-4">
								{/* <button className="cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white   rounded-full border border-[#2196f3]">
									{" "}
									Donut Chart{" "}
								</button> */}
								{/* <button className="cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-full border">
                                    {" "}
                                    Bar Chart
                                </button> */}
								{/* <button onClick={this.modalhandle} className="flex space-x-2 items-center cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white   rounded-md border border-[#2196f3]">
                                    <MdOutlineAdd className="text-white text-[1.5rem]" />
                                    <span>Add New</span>{" "}
                                </button> */}
							</div>
						</div>

						<div className="flex justify-start gap-4 text-sm text-[#3D405B] px-6  ">
							<div className="flex items-center ">
								<div className="p-1.5 rounded-full bg-green-500"></div>
								<p className="ml-2">Positive</p>
							</div>
							<div className="flex items-center ">
								<div className="p-1.5 rounded-full bg-red-500"></div>
								<p className="ml-2">Negative</p>
							</div>
							<div className="flex items-center ">
								<div className="p-1.5 rounded-full bg-yellow-500"></div>
								<p className="ml-2">Neutral</p>
							</div>
						</div>

						<div className="h-[600px] p-6 grid md:grid-cols-3 grid-cols-2 overflow-y-auto">
							{topic_list && topic_list.length > 0 ?
								topic_list.map((ele) => (
									<div onClick={() => this.handleKeyword(ele)}>
										{circleChartFn(ele, groupTopics[ele])}
									</div>
								)) : null}
						</div>

						<h1 className="text-sm text-[#3d405B]/70 p-6 border-t text-center">
							* We are not showing data where employee count is less than 5
						</h1>
					</div>
					{/* Right side design code */}
					<div className="transition duration-150 bg-white border rounded-lg hover:shadow-lg">
						<div className="flex items-center justify-between px-6 py-4 ">
							<h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap capitalize  ">
								{Keyword}
							</h1>

							{textList && textList.length >= 5 ?
								<CSVLink data={textList} filename={"TextData.csv"}>
									<button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-file-x"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											stroke-width="2"
											stroke="currentColor"
											fill="none"
											stroke-linecap="round"
											stroke-linejoin="round"
										>
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
											<path d="M7 11l5 5l5 -5"></path>
											<path d="M12 4l0 12"></path>
										</svg>
										<span> Download Report</span>
									</button>
								</CSVLink>
								: null}
						</div>
						{/* Tab list*/}
						<div className="items-center justify-start px-6 space-x-4 border-b md:flex">
							{sentimentList.map((ele) =>
								<span onClick={() => this.handleSentiment(ele.name)}
									className={
										sentimentType === ele.name
											? ` pb-2 text-sm font-medium`
											: 'text-[#3D405B]/70 pb-2 text-sm font-medium flex items-center space-x-1'}
									style={{
										color: sentimentType === ele.name ? ele.color : '#3D405BB2',
										borderBottom: sentimentType === ele.name ? `1px solid ${ele.color}` : '1px solid transparent ',
									}}>
									<p className="cursor-pointer">{ele.name} ({allList[ele.name] && allList[ele.name].length > 0 ? allList[ele.name].length : 0})</p>
								</span>
							)}
						</div>

						{/* List of Questions */}
						<div className="h-[550px] p-6 py-2 divide-y overflow-y-auto customscroll4">
							{textList && textList.length > 0 ? (
								textList.map((item, index) =>
									index >= indexA && index <= indexB ? (
										<div key={index} className="flex items-start py-4 space-x-4 capitalize">
											<div className=" items-center justify-between w-full gap-4">
												<div className='flex flex-row'>
													<p className='pr-2'>{index + 1}.</p>
													<h1 className="text-base font-normal text-[#3D405B]">{item.sentence}</h1>
												</div>
												<div className='flex flex-row space-x-2 m-4'>
													<button className={`w-24 flex-shrink-0 uppercase cursor-pointer text-sm py-1.5 px-2 font-medium rounded-md `}
														style={{
															color: getColor(item, sentimentType, sentimentList),
															border: `1px solid ${getColor(item, sentimentType, sentimentList)}`
														}}
													>
														{item.sentiment}
													</button>

													{/* Conditionally render the "Suggestion" button */}
													{item.suggestion === 'suggestion' && (
														<button className="w-28 border border-gray-500 text-gray-500 flex-shrink-0 uppercase cursor-pointer text-sm py-1.5 px-2 font-medium rounded-md">
															{item.suggestion}
														</button>
													)}
												</div>
											</div>
										</div>
									) : null
								)
							) : (
								<>
									{!loading ? (
										<div className="pt-40 text-2xl text-center text-gray-400">
											Data not available!
										</div>
									) : null}
								</>
							)}

							<h1 className="text-sm text-[#3d405B]/70 py-4">
								* We are not showing data where employee count is less than 5
							</h1>
						</div>

						{/*  Pagiantion*/}
						<div className="flex justify-center items-center space-x-6 text-[#3D405B] text-sm font-medium p-6 border-t">
							<p className="">Pages</p>
							<div className="flex border divide-x rounded-md ">
								<button
									onClick={() => this.prevEmpPage()}
									className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition"
								>
									Previous
								</button>

								{IndexListIndex && IndexListIndex.length > 0
									? IndexListIndex.map((item, index) =>
										index >= EmpIndexA && index <= EmpIndexB ? (
											item === currentEmpPage ? (
												<button
													onClick={() => this.SelectEmpPage(item)}
													className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] "
												>
													{item}
												</button>
											) : (
												<button
													onClick={() => this.SelectEmpPage(item)}
													className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition "
												>
													{item}
												</button>
											)
										) : null
									)
									: null}

								<button
									onClick={() => this.nextEmpPage(IndexListIndex)}
									className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition"
								>
									Next
								</button>
							</div>
							{IndexListIndex && IndexListIndex.length > 0 ?
								<p>{currentEmpPage} of {IndexListIndex && IndexListIndex.length > 0 ? IndexListIndex.length : 0}</p>
								: null}
						</div>





					</div>
				</div>

				{isModal && (<div className="fixed bottom-0 left-0 right-0 z-40 flex items-center justify-center w-full h-full p-4 bg-black bg-opacity-40 -top-4 md:p-0">
					<div className="w-5/6 mx-auto mb-20 bg-white rounded-md xl:w-4/12 lg:w-4/6 ">
						<div className="flex items-center justify-between p-4 border-b">
							{" "}
							<h1 className="text-lg p-1 font-semibold capitalize text-[#3D405B]">
								Add Topic
							</h1>
							<span onClick={this.modalhandle} className="material-symbols-outlined bg-gray-50 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150">
								close
							</span>
						</div>
						<div className="p-6 space-y-4 ">
							<div>
								<label className="text-sm font-medium text-[#3D405B]">
									Topic Name
								</label>
								<input
									name="label"
									placeholder="Enter Label"
									class="border bg-white w-full py-2.5 px-4 text-sm rounded-md focus:outline-none focus:ring-1 font-medium  text-[#3D405B]"
									value="Wellness"
								></input>
							</div>
							<div>
								<label className="text-sm font-medium text-[#3D405B]">
									Add Keywords
								</label>
								<div onClick={this.toggleDropdown} className="relative flex items-center justify-between w-full p-2 px-4 text-gray-700 border rounded-md cursor-pointer">
									<p className="w-full"> Select</p>
									<svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer icon icon-tabler icon-tabler-caret-down-filled text-[#3D405B]" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
										<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
										<path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" stroke-width="0" fill="currentColor"></path>
									</svg>

									{isOpen && (<div className="absolute right-0 z-10 w-full py-4 overflow-y-auto text-base font-medium bg-white border rounded-md shadow-lg top-10 min-h-auto max-h-60">
										<label className="items-center flex justify-between   space-x-2 text-[#3D405B] w-full hover:bg-[#2196F3]/10 transition-all duration-100 py-2 cursor-pointer px-6 ">
											<span>Number</span>
											<MdCheck className="text-[#2196F3] text-[1.5rem]" />
										</label>
										<label className="items-center flex space-x-2 text-[#3D405B] w-full hover:bg-[#2196F3]/10 transition-all duration-100 py-2 cursor-pointer px-6 ">
											<span>Star</span>
										</label>
									</div>)}
								</div>
							</div>

							<div className="items-center block px-0 space-x-4 ">
								<button type="button" className="border w-full border-[#2196F3] rounded-md  py-2.5 text-base bg-[#2196F3] text-white focus:outline-none flex items-center justify-center hover:bg-[#2196F3]/90 uppercase  text-center font-medium">Save </button>
							</div>
						</div>

					</div>
				</div>)}



			</>
		);
	}
}
function mapStateToProps(state) {
	// ////console.log("state  ", state);
	const { loggingIn } = state.authentication;
	// const { users, dashboard } = state;
	return {
		loggingIn
	};
}
export default connect(mapStateToProps)(TextAnalysis);
