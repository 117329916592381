import React from 'react';


export default function Happiness(props) {
    
    let { 

        item,

        DistributionData

     } = props;


    console.log('item:--------ssaa---ss------------------------> ',item)

    let score = 'NaN';
    let type = '';

    if(item['score']!=='NaN'){
        score = parseFloat(item['score']);
        type = item['type'];
    }

    let scoreOverall = 0;
    let countOverall = 0;
    if(item && item.DimenionList && item.DimenionList.length>0){
      item.DimenionList.forEach((item2)=>{
        console.log('item2:--------ssaa---------------------------> ',item2)

        if(item2 && item2.inner && item2.inner.length>0){
          item2.inner.forEach((item3)=>{
            console.log('item3:--------ssaa---------------------------> ',item3)
            countOverall++
            scoreOverall += (Number(item3.sum)/Number(item3.count))
          })
        }

        

      })
    }

    let newscoreOverall = scoreOverall/countOverall

    let finalScore = newscoreOverall?newscoreOverall.toFixed(2):0
    return (
      <>	
        <div className="bg-white cursor-pointer rounded w-full shadow-lg p-4 overflow-hidden bg-opacity-50 flex justify-between" style={{
            background:(item['color'])?item['color']:'#757575',
            height:'190px',
           
        }}>
           <div>
           <div className="text-white text-lg font-semibold capitalize">{item.index}</div>
            <div className="text-white pt-8 font-medium" style={{fontSize:'35px'}}>{(finalScore !== 'NaN')?(type==='Percentage')?finalScore+'%':finalScore : 
            <p style={{fontSize:'15px',position:'relative'}}>We have not calculated <br/> {item.index} for the organization</p>
            }</div>
            {(finalScore !== 'NaN')?<div className="text-white text-sm ">Total {type}</div>:null}
           </div>
        
            <div ><img src={item["image"]} className="w-40 mr-10 " 
            // style={{marginTop:(score !== 'NaN')?'-120px':'-50px', }}
            /></div>

        </div>
      </>
    );
  }
