import React from "react";
import StackEDAChart from './StackEDAChart';

export default function EmployeeProfileModal(props) {
  let {  closeModal, showModal, getdmgOrgManyFilterData, indexType, dmgLevel, dmgValue, GetParameterLabel } = props;



          // let OrgCoreDriverData = {};
        // if(getOrgIndexV2Data && getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length>0){
        //   getSurveyData.orgList.forEach((org)=>{
        //     OrgCoreDriverData[org.name] = getOrgIndexV2Data[org.name];
        //   })
        // }

    
        // if(OrgCoreDriverData){
        //   valueList = Object.keys(OrgCoreDriverData).map((key) => [ key, OrgCoreDriverData[key] ]);
        // }

    let DriverName = ''
    let FinalData = []
    if(getdmgOrgManyFilterData && getdmgOrgManyFilterData.length>0){
      getdmgOrgManyFilterData.forEach((item)=>{
        //console.log('item------>',item)

        DriverName = dmgValue;
          let tempData = {
            "xAxis":item.xAxis,
            "driver":dmgValue,
            "data":[]
        }
        let innerList = []
        if(item && item.Data && item.Data.length>0){
            item.Data.forEach((inner)=>{
              console.log('inner------>',inner)
              //console.log('innedmgValuer------>',dmgValue)
              let scoreZ = 0
                if(item && item.type===2){
                  scoreZ = inner && inner[dmgValue] && item && dmgValue?inner[dmgValue]:""
                }
                else{
                  scoreZ = inner && inner[dmgValue+"Avg"] && item && dmgValue?inner[dmgValue+"Avg"]:""
                }
                innerList.push({
                  'count':inner && inner.count?inner.count:"NA",
                  'name':inner && inner.name?inner.name:"",
                  'score':scoreZ
                })
            })
        }

        tempData["data"] = innerList
        FinalData.push(tempData)


      })
    }

    //console.log('showModal------>',showModal)
    //console.log('zzz getdmgOrgManyFilterData------>',getdmgOrgManyFilterData)
    console.log('List------>',FinalData)




  return (
   <>
   {showModal?
  <>



        <div  className="fixed z-40 top-0  left-0  h-screen w-full bg-black bg-opacity-50 " >
            <div className="p-4  max-w-12xl mx-auto overflow-hidden  flex h-screen justify-center items-center w-full">

              <div className="shadow rounded-lg bg-white overflow-hidden xl:w-4/6 lg:w-5/6 md:w-5/6 w-full ">

                <div className="p-4 flex justify-between items-center font-medium text-lg border-b ">
                <div className="px-2 py-1 font-medium text-lg border-b ">
              <span className="font-bold">{"Organization Core: "}</span>
              {/* <span className="text-blue-500">{" ("+GetParameterLabel(DriverName.replace('Avg',''))+") "}</span> */}
              <span className="text-blue-500">{" ("+GetParameterLabel(dmgValue)+") "}</span>

              
            </div>
                  {/* close btn */}
                  <div onClick={() => closeModal()} className="rounded-full p-2  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
                    <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                    </svg>
                  </div>

                </div>



            <div className=" px-2 h-96 overflow-y-auto" style={{height:'550px'}}>
                  {FinalData && FinalData.length>0?
                  FinalData.map((item)=>
                  <>
                  <div className="p-1 border-b" >
                      <div className="px-2 py-1 font-bold">{item.xAxis}</div>

                      <StackEDAChart
                        getDemographicIndexData={item.data}
                        indexType={indexType}
                        xAxis={item.xAxis}
                      />

                  </div>
                  </>
                  ):
                  <div className="text-cenetr pt-6 text-gray-500 font-bold ">Data not awailable!</div>}
            </div>
              


          
  
          </div>



        </div>
        </div>
  </> 
  :null}

   </>
  );
}
