import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionStack from './QuestionStack';

class QuestionStackView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OptimizedQuesDynaObj:{}
  
    }
  }

  componentDidMount() {

  }


  render() {
    let { getQuestionData, Name, QuesDynaObj, ManagerIndexQuestionData, EmpFilterData, ManagerDynaObj, CurrentManagerData } = this.props;
    let { OptimizedQuesDynaObj } = this.state;


    let ManagerParameterScore = ''
    if(ManagerDynaObj && ManagerDynaObj.length>0){
      ManagerDynaObj.forEach((obj)=>{
        if(obj.name===CurrentManagerData["ManagerPID"]){
          ManagerParameterScore=obj;
        }
      })
    }


    // ////console.log('QuesDynaObj; ----><------ ',QuesDynaObj)
    // ////console.log('ManagerIndexQuestionData; ----><------ ',ManagerIndexQuestionData)


    if(EmpFilterData && EmpFilterData.length>0){
      EmpFilterData.forEach((emp)=>{
        OptimizedQuesDynaObj[emp.name]=[]
      })
    }
    // ////console.log('OptimizedQuesDynaObj; ----><------ ',OptimizedQuesDynaObj)


    // OptimizedQuesDynaObj


    if(ManagerIndexQuestionData && ManagerIndexQuestionData.length>0){
                            
      ManagerIndexQuestionData.forEach((item)=>{
              if(OptimizedQuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                OptimizedQuesDynaObj[item.filters[0].level].push(item);
              }
              else{
                  let getIndex = OptimizedQuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                  OptimizedQuesDynaObj[item.filters[0].level][getIndex] = item;
              }
          }
      )

  }


  // ////console.log('QuesDynaObj; ----><------ ',QuesDynaObj)

  // ////console.log('OptimizedQuesDynaObj; ----><------ ',OptimizedQuesDynaObj)


  if(EmpFilterData && EmpFilterData.length>0){
    EmpFilterData.forEach((emp)=>{
      if(QuesDynaObj[emp.name].length===0){
        if(OptimizedQuesDynaObj[emp.name]!==0){
          QuesDynaObj[emp.name]=OptimizedQuesDynaObj[emp.name]
        }
      }
    })
  }



  // ////console.log('QuesDynaObj; ----><------ ',QuesDynaObj)

  // ////console.log('OptimizedQuesDynaObj; ----><------ ',OptimizedQuesDynaObj)


    let paraList = []

    let count = 0;
    let rowSpan = 0;
    let NewParaList=[]

    if(getQuestionData && getQuestionData.length>0){

      getQuestionData.forEach((ques)=>{
        if(paraList.findIndex(prev=>prev===ques.parameterDimensionName)===-1){
          paraList.push(ques.parameterDimensionName);
          count+=1
        }
        else{
        }
      })

      let obj = {}
      paraList.forEach((para)=>{
        obj[para]=0
        getQuestionData.forEach((ques)=>{
          if(para===ques.parameterDimensionName){
            obj[para]+=1
          }
        })
      })


      NewParaList = Object.keys(obj).map((key) => [key, obj[key]])
    //   ////console.log('data NewParaList: ',NewParaList)

      rowSpan=(getQuestionData.length/count)

      // ////console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,getQuestionData.length)

    }

    let QuestionList =[]
    let FilterQuestionList = []
    let ParameterNameList =[]



    if(NewParaList && NewParaList.length>0){
      NewParaList.forEach((para,index)=>{
        ParameterNameList.push(para[0]);

        if(getQuestionData && getQuestionData.length>0){
          getQuestionData.forEach((ques, queIndex)=>{

            // ////console.log(':=====> getQuestionData:=====> ',getQuestionData)
            if(ques.parameterDimensionName === para[0]){
              let total = ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];
              let sum = ques["5"] + ques["6"];

              let score = (  ( (sum*100)/total ).toFixed(2)  ).toString()
              
              QuestionList.push({
                "name":ques.name,
                "score":score,
                "para":ques.parameterDimensionName
              })
            }
          })
        }


      })
    }









    // ////console.log('QuesDynaObj===>',QuesDynaObj)

    let NewQuesDynaObj ={}


    if(EmpFilterData && EmpFilterData.length>0){
      EmpFilterData.forEach((emp)=>{
        NewQuesDynaObj[emp.name]=[]
      })
    }


      if(EmpFilterData && EmpFilterData.length>0){
        // ////console.log('Working===>')

        EmpFilterData.forEach((emp)=>{


          

          if(NewParaList && NewParaList.length>0){
                NewParaList.forEach((para,index)=>{

                      if(QuesDynaObj && QuesDynaObj[emp.name] && QuesDynaObj[emp.name].length>0){
                            QuesDynaObj[emp.name].forEach((NewQues)=>{
                              if(NewQues.parameterDimensionName === para[0]){

                                    let total = NewQues["1"] + NewQues["2"] + NewQues["3"] + NewQues["4"] + NewQues["5"] + NewQues["6"];
                                    let sum = NewQues["5"] + NewQues["6"];
                                    let score = (  ( (sum*100)/total ).toFixed(2)  ).toString()
                        
                                    NewQuesDynaObj[emp.name].push({
                                      "name":NewQues.name,
                                      "score":score,
                                      "para":NewQues.parameterDimensionName
                                    })

                              }
                            })
                      }
                  
              })
          }

        })
      }
    

    // ////console.log('NewQuesDynaObj===>',NewQuesDynaObj)
    
    // ////console.log('ParameterNameList===>',ParameterNameList)








      let CompleteQuestionList=[]
      if(EmpFilterData && EmpFilterData.length>0){
        EmpFilterData.forEach((emp)=>{
          NewQuesDynaObj[emp.name].forEach((ques)=>{
            CompleteQuestionList.push({
              "name":ques.name,
              "para":ques.para,
              "filter":emp.name,
              "score":ques.score

            })
          })
        })
      }

    //  ////console.log('CompleteQuestionList: ',CompleteQuestionList);












    // ////console.log('FilterQuestionList: ',FilterQuestionList)  








    // ////console.log('QuestionList: ',QuestionList)  
    let paraStore = []
    let previousPara = ''
    if(QuestionList && QuestionList.length>0){
      QuestionList.forEach((question)=>{
        let show = 1
        if(question.para === previousPara){
          show = 0 ;
        }
        else{
          show = 1
        }

        paraStore.push({
          "para":question.para,
          "show":show
        })

        show = 1;
        previousPara=question.para;


      })
    }
    // ////console.log('QuestionList: ',QuestionList)  



    return (
      <>
      <div style={{marginTop:'2%'}}>
      {ParameterNameList && ParameterNameList.length>0?
        ParameterNameList.map((para)=>


      <div className="stackview">

        <div className="stackview-stack" style={{height:'150px'}}>

            <div className="parameter" style={{float:'left',width:'14%'}}>
                            <div className="Stack-box1" style={{float:'left',width:'50%'}}>
                              <div className="percent ">
                              <svg>
                                  <circle cx={45} cy={45} r={45} />
                                  <circle cx={45} cy={45} r={45}
                                   style={{
                                        stroke:'#66b3ff',
                                        strokeDashoffset:290 - (290 * (((ManagerParameterScore[para+'Avg']?ManagerParameterScore[para+'Avg']:0)*100)/100))/ 100 
                                      }} 
                                        />
                                  </svg>
                                  <div className="number cursor-default">
                                      <h1 className="text-lg font-semibold" style={{fontSize:'12px',marginLeft:(para.length>8)?'-15px':(para.length>5)?'-7px':'3px'}}>{para}</h1>
                                    <p className="text-lg font-semibold text-center" style={{fontSize:'15px',marginLeft:'5px',marginTop:'-4%'}}>{(ManagerParameterScore[para+'Avg'])?ManagerParameterScore[para+'Avg'].toFixed(0)+'%':'NaN'}</p>
                                  </div>
                              </div>
                            </div>
                            {/* <div style={{float:'left',width:'50%'}}>
                                <div style={{marginLeft:'70%',marginTop:'20%'}}>
                                     <i style={{fontSize:'70px',color:'#4f90f7'}} className="fa fa-caret-right"></i>
                                </div>
                            </div> */}
            </div>
            <div className="stackview-question" style={{float:'left',width:'85%'}}>
                  <div>
                        <QuestionStack 
                          QuestionName={ "I have clear goals in my life" }
                          ParameterName={para}
                          QuestionList={QuestionList}
                          CompleteQuestionList={CompleteQuestionList}
                          EmpFilterData={EmpFilterData}
                          ManagerParameterScore={ManagerParameterScore}
                        />
                  </div>
            </div>

        </div>



      </div>
        )
      :null}
      </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionStackView);

