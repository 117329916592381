import React, { Component } from "react";
import { connect } from "react-redux";
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() { }

  render() {
    let { ParticipationObj } = this.props;

    let responseRate =
      ParticipationObj && ParticipationObj["participated"]
        ? ParticipationObj["participated"]
        : 0;


    let names = []
    let weightage1 = [];
    let weightage2 = [];
    if (true) {

      names.push({
        value: "Response",
        textStyle: { fontWeight: 'bold' }
      })
      weightage1.push({
        value: responseRate,
        itemStyle: {
          color: '#1E88E5',
        }
      });
      weightage2.push({
        value: 100 - responseRate,
        itemStyle: { color: '#f1f1f1f1' } 
      });

    }


    return (
      <>
        {true ? (
          <ReactEcharts
            style={{ height: '95px', marginTop: "-55px", width: '600px' }}
            option={{
              grid: {
                left: '0%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                type: 'value',
                axisLine: 'left',
                splitLine: {
                  show:false,
                  lineStyle: {
                    color: '#fff'
                  }
                },
                axisLabel: {
                  show: false
                }

              },
              yAxis: {
                type: 'category',
                data: names,
                position: 'left',
                inverse: true,
                axisLine: {
                  lineStyle: {
                    color: '#fff'
                  }
                },
                axisLabel: {
                  show: false
                }
              },
              axisLabel: {
                color: "#000",
                interval: 0,
                formatter: function (value) {
                  if (value.length > 20) {
                    return value.substring(0, 20) + ".. :";
                  } else {
                    return value + ":";
                  }
                }
              },
              series: [
                {
                  // name: 'Strongly Disagree',
                  type: 'bar',
                  stack: '总量',
                  barWidth: '20px',
                  label: {
                    show: true,
                    // position: 'insideRight',
                    color: "#ffffff",
                    position: 'insideRight',
                    formatter: function (d) {
                      if (parseInt(d.value) < 7) {
                        return '';
                      }
                      else {
                        return d.value + ' %';
                      }
                    }
                  },
                  data: weightage1,
                  inverse: true
                },
                {
                  // name: 'Disagree',
                  type: 'bar',
                  stack: '总量',
                  barWidth: '20px',
                  label: {
                    show: false,
                    position: 'insideRight',
                    formatter: function (d) {
                      if (parseInt(d.value) < 7) {
                        return '';
                      }
                      else {
                        return d.value;
                      }
                    }
                  },
                  data: weightage2,
                  inverse: true
                },

              ]
            }}
          />
        ) : null}
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(StackChart);
