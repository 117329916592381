import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";


export default function GaugeScore(props) {

  let { item, Benchmark } = props;





  return (
    <>
      <div className="pt-1 pl-4" style={{ height: Benchmark && Benchmark.score ? null : "140px" }}>

        {item && item.score?
          <ReactSpeedometer className="meter"
            height={250}
            value={item.score}
            minValue={0}
            maxValue={100}
            needleColor="#4db8ff"
            segments={1}
            segmentColors={[
              "#33cc33",
              "#e3e6e3"
            ]}
            ringWidth={35}
            customSegmentStops={[0, Number(item.score), 100]}
            // fluidWidth={true}
            needleTransitionDuration={3333}
            needleTransition="easeElastic"
          />
        :
        <div className='text-gray-400 text-lg text-center font-medium'>Data Not Available</div>}
        


        {Benchmark && Benchmark.score || true ?
          <div className="gauge-outer">
            <div className="gauge-inner" style={{ transform: 'rotate(calc(1deg * (-45 + ' + 0 + ' * 1.8)))' }}></div>
          </div>
          : null}


      </div>

    </>
  );
}
