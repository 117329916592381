import React, { Component } from 'react';
import { connect } from 'react-redux';
import Summary from './Components/Summary/Summary';
import ScoreVsBenchmark from './Components/ScoreVsBenchmark/ScoreVsBenchmark';

class HappinessIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 1
    }
  }
  componentDidMount() {

  }

  onOptionType = (type) => {
    this.setState({ optionType: type });

    // if(type===2 || type===5){
    //     let { handleQuestion } = this.props;
    //     handleQuestion();
    // }

    // if(type===3){
    //   let { handleQuestionMeanScore,EmpFilterData } = this.props;
    //   if(EmpFilterData && EmpFilterData.length>0){
    //     let level = EmpFilterData[0].name;
    //     handleQuestionMeanScore(level);
    //   }
    // }

  }


  onSwitchOptionScreen = () => {
    let { getQuestionOthersResultData, handleOthersFilterCall, getQuestionOthersResultFilterData,
      currentQuestionId, getIndexFilterData, EmpFilterData, getEMPFilterLabel } = this.props;

    let { optionType } = this.state;

    switch (optionType) {
      case 1: return <Summary
        getQuestionOthersResultData={getQuestionOthersResultData}
        getQuestionOthersResultFilterData={getQuestionOthersResultFilterData}
        getEMPFilterLabel={getEMPFilterLabel}
        handleOthersFilterCall={handleOthersFilterCall} currentQuestionId={currentQuestionId} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}

      />
      default: return <div>Default Option</div>
    }


  }

  render() {
    let { ItemSaveData, cardType } = this.props;
    let { optionType } = this.state;

    //console.log('--------->ItemSaveData:',ItemSaveData)
    let indexName = ''
    if (ItemSaveData && ItemSaveData.name) {
      indexName = ItemSaveData.name;
    }

    return (
      <>

          {this.onSwitchOptionScreen()}

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HappinessIndex);
