import * as React from 'react';

const PageTemplate = props => {
  return <div style={{
    position: "absolute",
    bottom: "10px",
    right:'20px'
  }}>
      Page {props.pageNum}
    </div>;
};

export default PageTemplate;