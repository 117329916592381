import React, { useState } from "react";
import Header from './Header/Header';
export default function SalaryHike() {

  const [open, close] = useState(0);


  return (

    <div className="h-screen overflow-hidden bg-gray-50">
      <Header
      />


      <div className="flex flex-row w-full" style={{ height: "calc(100% - 2rem)" }}>

        <div className="cursor-pointer transition-width w-64  lg:block lang lg:relative fixed top-0 left-0 z-20  h-screen     duration-200 transform bg-white border-r hidden" style={{ height: 'calc(100% - 0rem)' }}><div className="my-4 whitespace-nowrap "><div title="Users" className="flex items-center hover:bg-[#2196F3]/10 text-gray-500 cursor-pointer   border-l-4 border-[#2196F3] bg-[#2196F3]/10"><div className="flex items-center px-4 py-4 w-16  text-[#2196F3] h-12 justify-center"><span className=" material-symbols-outlined"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.8" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx={9} cy={7} r={4} /><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg></span></div><span className=" text-sm text-[#2196F3]">Appraisal Cycle</span></div></div></div>


        <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto  md:p-6 p-4 space-y-6  " style={{ height: 'calc(100% - 4rem)' }}>
          <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
            <div className="flex items-center space-x-4 ">
              <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">
                Manage Salary Hike
              </h1>
            </div>
            <div className="md:flex hidden items-center text-sm">
              <span className="mx-2 material-symbols-rounded">home</span>
              <span>/</span>
              <span className="px-2">PMS</span>
              <span>/</span>

              <span className="px-2 font-semibold">Salary Hike</span>
            </div>
          </div>

          <div className="bg-white md:p-6 p-4 space-y-6 rounded-lg border md:pb-0 pb-0">
            <h1 className="xl:text-2xl text-lg font-medium text-[#3D405B]">Salary Hike</h1>

            <div className="grid lg:grid-cols-3 gap-8">
              <div className="border p-6 py-4 flex flex-row gap-6">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users-group text-green-600" width="36" height="36" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                  <path d="M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1"></path>
                  <path d="M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                  <path d="M17 10h2a2 2 0 0 1 2 2v1"></path>
                  <path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                  <path d="M3 13v-1a2 2 0 0 1 2 -2h2"></path>
                </svg>

                <div className="space-y-1 ">
                  <p className="text-base">Total Employee</p>
                  <h1 className="text-2xl font-semibold">5</h1>
                </div>
              </div>

              <div className="border p-6 py-4 flex flex-row gap-6">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users-group text-green-600" width="36" height="36" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 14c0 1.657 2.686 3 6 3s6 -1.343 6 -3s-2.686 -3 -6 -3s-6 1.343 -6 3z"></path>
                  <path d="M9 14v4c0 1.656 2.686 3 6 3s6 -1.344 6 -3v-4"></path>
                  <path d="M3 6c0 1.072 1.144 2.062 3 2.598s4.144 .536 6 0c1.856 -.536 3 -1.526 3 -2.598c0 -1.072 -1.144 -2.062 -3 -2.598s-4.144 -.536 -6 0c-1.856 .536 -3 1.526 -3 2.598z"></path>
                  <path d="M3 6v10c0 .888 .772 1.45 2 2"></path>
                  <path d="M3 11c0 .888 .772 1.45 2 2"></path>
                </svg>

                <div className="space-y-1 ">
                  <p className="text-base">Total Salary before hike</p>
                  <h1 className="text-2xl font-semibold">₹ 120000</h1>
                </div>
              </div>

              <div className="border p-6 py-4 flex flex-row gap-6">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users-group text-green-600" width="36" height="36" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 14c0 1.657 2.686 3 6 3s6 -1.343 6 -3s-2.686 -3 -6 -3s-6 1.343 -6 3z"></path>
                  <path d="M9 14v4c0 1.656 2.686 3 6 3s6 -1.344 6 -3v-4"></path>
                  <path d="M3 6c0 1.072 1.144 2.062 3 2.598s4.144 .536 6 0c1.856 -.536 3 -1.526 3 -2.598c0 -1.072 -1.144 -2.062 -3 -2.598s-4.144 -.536 -6 0c-1.856 .536 -3 1.526 -3 2.598z"></path>
                  <path d="M3 6v10c0 .888 .772 1.45 2 2"></path>
                  <path d="M3 11c0 .888 .772 1.45 2 2"></path>
                </svg>

                <div className="space-y-1 ">
                  <p className="text-base">Estimated Salary After Hike</p>
                  <h1 className="text-2xl font-semibold">₹ 120000</h1>
                </div>
              </div>

            </div>

            <div className="md:w-3/6 space-y-2 ">
              <label className="text-lg">Hike Based on</label>
              <div onClick={() => close(!open)} className="text-gray-700  border rounded-md w-full p-2.5 px-4 relative flex justify-between items-center cursor-pointer" ><p>  Designation</p>
                <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer icon icon-tabler icon-tabler-caret-down-filled text-[#3D405B]/70" width={20} height={20} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" strokeWidth={0} fill="currentColor" /></svg>

                {open ? <div className="absolute top-10 right-0 bg-white shadow-lg rounded-md w-full py-4  border h-60 overflow-y-auto z-10 text-base space-y-2 ">
                  <div className="mx-6 flex items-center justify-between overflow-hidden text-base bg-white border rounded-md md:w-auto w-full my-4 ">
                    <span className="p-2 rounded-l-full material-symbols-outlined text-slate-600" style={{ fontSize: '1.4rem' }}>search</span>
                    <input type="search" name="search" className="w-full py-2   focus:outline-none placeholder:text-slate-600" placeholder="Search" /></div>
                  <p className="items-center flex space-x-2 text-[#3D405B] w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">
                    Allocated Hike Percentage All </p>
                  <p className="items-center flex space-x-2 text-[#3D405B] w-full  bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 font-medium ">
                    Designation </p>
                  <p className="items-center flex space-x-2 text-[#3D405B] w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">
                    Total Work Experience </p>

                  <p className="items-center flex space-x-2 text-[#3D405B] w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">
                    Date of Joining </p>
                  <p className="items-center flex space-x-2 text-[#3D405B] w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">
                    Ratings</p>
                </div> : null}
              </div>
            </div>

            <div className="bg-slate-50 grid md:grid-cols-2 gap-6 md:p-6 p-4">

              <div className="w-full space-y-2 ">
                <label className="text-lg">Designation</label>
                <div onClick={() => close(!open)} className="text-gray-700  border rounded-md w-full p-2.5 px-4 relative flex justify-between items-center cursor-pointer bg-white" ><p>  Manager</p>
                  <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer icon icon-tabler icon-tabler-caret-down-filled text-[#3D405B]/70" width={20} height={20} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" strokeWidth={0} fill="currentColor" /></svg>

                  {open ? <div className="absolute top-10 right-0 bg-white shadow-lg rounded-md w-full py-4  border h-60 overflow-y-auto z-10 text-base space-y-2 ">
                    <div className="mx-6 flex items-center justify-between overflow-hidden text-base bg-white border rounded-md md:w-auto w-full my-4 ">
                      <span className="p-2 rounded-l-full material-symbols-outlined text-slate-600" style={{ fontSize: '1.4rem' }}>search</span>
                      <input type="search" name="search" className="w-full py-2   focus:outline-none placeholder:text-slate-600" placeholder="Search" /></div>
                    <p className="items-center flex space-x-2 text-[#3D405B] w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">
                      Allocated Hike Percentage All </p>
                    <p className="items-center flex space-x-2 text-[#3D405B] w-full  bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 font-medium ">
                      Designation </p>
                    <p className="items-center flex space-x-2 text-[#3D405B] w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">
                      Total Work Experience </p>

                    <p className="items-center flex space-x-2 text-[#3D405B] w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">
                      Date of Joining </p>
                    <p className="items-center flex space-x-2 text-[#3D405B] w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">
                      Ratings</p>
                  </div> : null}
                </div>
                <div onClick={() => close(!open)} className="text-gray-700  border rounded-md w-full p-2.5 px-4 relative flex justify-between items-center cursor-pointer bg-white" ><p>  UI UX Designer</p>
                  <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer icon icon-tabler icon-tabler-caret-down-filled text-[#3D405B]/70" width={20} height={20} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" strokeWidth={0} fill="currentColor" /></svg>

                  {open ? <div className="absolute top-10 right-0 bg-white shadow-lg rounded-md w-full py-4  border h-60 overflow-y-auto z-10 text-base space-y-2 ">
                    <div className="mx-6 flex items-center justify-between overflow-hidden text-base bg-white border rounded-md md:w-auto w-full my-4 ">
                      <span className="p-2 rounded-l-full material-symbols-outlined text-slate-600" style={{ fontSize: '1.4rem' }}>search</span>
                      <input type="search" name="search" className="w-full py-2   focus:outline-none placeholder:text-slate-600" placeholder="Search" /></div>
                    <p className="items-center flex space-x-2 text-[#3D405B] w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">
                      Allocated Hike Percentage All </p>
                    <p className="items-center flex space-x-2 text-[#3D405B] w-full  bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 font-medium ">
                      Designation </p>
                    <p className="items-center flex space-x-2 text-[#3D405B] w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">
                      Total Work Experience </p>

                    <p className="items-center flex space-x-2 text-[#3D405B] w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">
                      Date of Joining </p>
                    <p className="items-center flex space-x-2 text-[#3D405B] w-full hover:bg-slate-100 transition-all duration-100 py-2 cursor-pointer px-6 ">
                      Ratings</p>
                  </div> : null}
                </div>
              </div>

              <div className="w-full space-y-2 ">
                <label className="text-lg">Hike %</label>
                <input name="name" value={25} placeholder="Enter Your Name" className="border bg-white w-full py-2.5 px-4 text-base rounded-md focus:outline-none focus:ring-1" />
                <input name="name" value={50} placeholder="Enter Your Name" className="border bg-white w-full py-2.5 px-4 text-base rounded-md focus:outline-none focus:ring-1" />
              </div>

              <div>
                <button className="p-1 bg-[#2196F3] text-white  rounded-md  flex items-center justify-center px-8 py-2.5 font-medium space-x-2 md:w-auto w-full ">

                  Add New </button>
              </div>



            </div>

            <div className="md:w-3/6 space-y-2 ">
              <label className="text-lg">Allocated Amount</label>
              <input name="name" value={0} placeholder="Enter Your Name" className="border bg-white w-full py-2.5 px-4 text-lg rounded-md focus:outline-none focus:ring-1" />
            </div>

            <div className="flex items-center justify-end  p-6 space-x-4 bg-white border-t md:-m-6 -m-4"><button className="w-32 px-4 py-3 uppercase border rounded-md text-[#3D405B]/70 font-medium">Reset</button><button className="border rounded-md w-32 py-3 px-4 uppercase bg-[#2196F3] text-white font-medium ">Submit</button></div>


          </div>



        </main>


      </div>
    </div>

  );
}