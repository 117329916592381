import { dataTool } from "echarts";
import React from "react";
import Pagination from "material-ui-flat-pagination";

export default function CompleteModal(props) {
  let { errorList, submitCreateTask, closeCompleteModal,  taskFormData, handleTaskInput } = props;

  ////console.log("errorList----------------------->",errorList)
  return (
   <>
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-xl mx-auto md:relative absolute left-0 right-0 overflow-hidden mt-20">
          
          <div className="  shadow w-full rounded-lg bg-white overflow-hidden block ">
            
          <div className="flex justify-between items-center w-full border-b p-4 px-6">
              <h2 className="font-medium text-xl   text-gray-800 ">UPDATE TASK</h2>
           <div onClick={()=>closeCompleteModal("")} className=" rounded-full p-2  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div> </div>

            <div className="w-full p-6 py-4 ">
          
            {/* <p className=" text-base mb-6 text-gray-700 border-b pb-2 text-center"></p> */}

            <div className="space-y-2" style={{height:'300px',overflowY:'auto'}}>
          <div className="text-sm space-y-2"> 
             <label className="">Task Name:</label>
            <div className=" text-xs">
              <input type="text" name="name" value={taskFormData && taskFormData.name?taskFormData.name:""} onChange={handleTaskInput} placeholder="Task Name.." className="p-2 w-full border border-gray-300 outline-none rounded-md" />
            </div>
</div>
<div className="text-sm space-y-2">  <label>Task Description:</label>
            <div className="mb-2 text-xs">
              <input type="text" name="desc" value={taskFormData && taskFormData.desc?taskFormData.desc:""} onChange={handleTaskInput} placeholder="Task Description.." className="p-2 w-full border border-gray-300 outline-none rounded-md" />
            </div></div>

           {/* <div className="text-sm space-y-2">
           <label>Task Timeline:-</label>
            <div className="mb-2 text-xs">
              <input type="text" name="timeline" value={taskFormData && taskFormData.timeline?taskFormData.timeline:""} onChange={handleTaskInput} placeholder="Task Timeline.." className="p-2 w-full border border-gray-300 outline-none rounded-md" />
            </div>
           </div> */}

           <div className="text-sm space-y-2">
           <label>Task Target Date:</label>
            <div className="mb-2 text-xs">
              <input type="date" name="deadline" value={taskFormData && taskFormData.deadline?taskFormData.deadline:""} onChange={handleTaskInput} placeholder="Task Deadline.." className="p-2 w-full border border-gray-300 outline-none rounded-md" />
            </div>
           </div>

            </div>

            <div className="text-red-500 font-normal h-12">{errorList && errorList.length>0?"Please enter "+errorList.map((element)=>(element)).join(", "):null}</div>

            <div className=" text-center mb-4">
              <button onClick={()=>submitCreateTask()} type="button" className="w-full bg-blue-500 hover:bg-opacity-90 transition duration-150 text-white font-medium py-2 px-6  rounded-md uppercase " >
                Update Task
              </button> 
            </div>

            </div>




          </div>




        </div>
        </div>
   </>
  );
}
