import React from 'react';
// import { connect } from 'react-redux';



  export default function LanguageDropdown(props) {

    let {getLanguageData, handleLanguageLabel } = props;

    let [showFilter, handleOpenFilter ] = React.useState(false);



    let {  languageShortName, languageName } = props;





    return (
      <>






          <div className="cursor-pointer bg-white md:w-auto w-full text-gray-500    border border-2 border-gray-500  flex justify-between px-4 items-center relative py-1.5 rounded-full space-x-1.5">
            
            <p  onClick={()=>handleOpenFilter(!showFilter)} className="text-xs px-2 text-gray-900">{languageName?languageName:"English" }</p> 
            
            
            <span  onClick={()=>handleOpenFilter(!showFilter)} className="material-icons ">arrow_drop_down</span>
            
            {showFilter && getLanguageData && getLanguageData.length>0?
            <div className="z-10 absolute right-0 top-10 bg-white shadow-lg p-3 font-normal text-xs w-full h-60 overflow-x-auto customscroll2 rounded-md">

              {getLanguageData && getLanguageData.length>0?
                getLanguageData.map((data,index)=>
                <div key={index}  className="py-2 m-1 flex items-center cursor-pointer" 
                  onClick={()=>{ handleLanguageLabel(data); handleOpenFilter(false)}}
                  >
                    <input type="checkbox" checked={languageShortName === data.shortName}  onChange={()=>{}}/>
                    <label  className="ml-2 font-medium">{data.name}</label> 
                </div>
                ):null}
              </div>
             :null}
          </div>
          







        {/* </div> */}
        
      </>
    );
  }
