import React from 'react';

export default function Component(props) {

const { question, answersList, ansTextBoxChange1} = props;

return(
    <>
            <input 
            type="number"
            value={(answersList && answersList.length > 0 && answersList
              .findIndex(item => item.questionId === question.id) !== -1) ?
              answersList[answersList.findIndex(item => item.questionId === question.id)].answer1
              : ''} 
            onChange={(event)=>ansTextBoxChange1(event,question)} 
            className="w-full bg-gray-100 rounded-md mt-4 p-3 text-sm  placeholder-gray-500" 
            placeholder={"Number.."}  />


          {/* <div className="md:px-6 px-4 ">

          <button 
          style={{
            background:(surveyScreenData && surveyScreenData.buttonColor1) ? surveyScreenData.buttonColor1 :  "#757575",
            color:(surveyScreenData && surveyScreenData.buttonColor2) ? surveyScreenData.buttonColor2 : "#757575",
          }} 
          onClick={() => clickOK()} className="mt-6 md:rounded-lg rounded-md p-2 px-4 mr-1 text-center text-xl">{SurveyAttributes("okButtonText")?SurveyAttributes("okButtonText"):"OK"}</button>

          </div> */}
    </>
  );}