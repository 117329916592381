import React, { Component } from 'react';
import { connect } from 'react-redux';


class ReportWaiseData extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }


  render() {

    let { item, index } = this.props;

    let { total } = item ? item : {};
    const customOrder = ['Yes', 'No'];
    total.sort((a, b) => customOrder.indexOf(a._id.answerText) - customOrder.indexOf(b._id.answerText));

    const chartData = total.map(item => ({
      name: item._id && item._id.answerText ? item._id.answerText : '',
      value: item && item.count ? Math.round((item.count / total.reduce((acc, curr) => acc + curr.count, 0) * 100)) : 0
    }));

    // Generate xAxis data dynamically
    const xAxisData = total
      .map(item => item._id && item._id.answerText)
      .sort((a, b) => customOrder.indexOf(a) - customOrder.indexOf(b));


    // Define color mapping function
    const getColor = answerText => {
      return answerText === 'Yes' ? '#81c784' : '#e57373';
    };

    // Define colors
    const colorList = xAxisData.map(answerText => getColor(answerText));


    return (

      <div className='p-6 space-y-4 bg-white'>
        {/* <div className='flex flex-row items-center justify-between w-full gap-4'>
          <div className='flex items-center text-[#212121] space-x-2'>
            <p className='p-3 bg-[#ededed] font-semibold'>Question {index + 1}</p>
          </div>
        </div>
        <div className=''>
          <h2 className='text-xl font-medium text-[#212121]'>{item && item.question.name ? item.question.name : 'null'}</h2>
          <p className='text-base text-[#212121]/80 flex space-x-2 items-center pt-3'>
            <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>Yes/No</p>
            <b className='font-medium text-[#212121] pr-1'>10</b> out of<b className='font-medium text-[#212121] pr-1'>10</b> answered
          </p>
        </div> */}

        <button class="flex w-full text-left space-x-4">
          <div class="text-primary flex h-10 items-center justify-center">
            <p className='bg-[#ededed] px-3 py-2 font-semibold whitespace-nowrap' style={{ fontWeight: "bold" }}>Q. {index + 1}</p>
          </div>
          <div class="w-full">
            <h4 class="text-xl font-medium text-[#212121]">{item && item.question.name ? item.question.name : 'null'}</h4>
            <p className='text-base text-[#212121]/80 flex space-x-2 items-center pt-3'>
              <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>Yes/No</p>
              {/* <b className='font-medium text-[#212121] pr-1'>10</b> out of<b className='font-medium text-[#212121] pr-1'>10</b> answered */}
            </p>
          </div>
        </button>

        <div className="relative w-full px-3 py-4">
          <div className='space-y-6 '>
            {chartData.map((data, index) => (
              <div key={index} className='flex items-center space-x-2 text-[#3D405B]'>
                <span className='w-24 text-right'>{data.name}</span>
                <span>-</span>
                <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                  <div className='flex items-center justify-start h-8' style={{ width: `${data.value}%`, backgroundColor: getColor(data.name) }}>
                    {/* <span className='text-center p-2 text-[#212121]'>{data.value}%</span> */}
                  </div>
                  <span className='flex items-center text-center p-2 text-[#212121]'>{data.value}%</span>
                </div>
              </div>
            ))}

          </div>
        </div>



        {/*  {stage !== 2 ?
          <>
            <button className='flex items-center space-x-2 font-medium text-[#2196F3] p-2 w-fit mx-auto' onClick={() => onOthersCardClick(item)}>
              <span>{stage == 2 ? "View More" : (stage == 3 ? "" : " View More")} </span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg>
            </button>
          </>
          : null} */}
      </div>


    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ReportWaiseData);
