export const customerConstants = {

    GET_CUSTOMER_SURVEY_LIST_REQUEST:'GET_CUSTOMER_SURVEY_LIST_REQUEST',
    GET_CUSTOMER_SURVEY_LIST_SUCCESS: 'GET_CUSTOMER_SURVEY_LIST_SUCCESS',
    GET_CUSTOMER_SURVEY_LIST_FAILURE: 'GET_CUSTOMER_SURVEY_LIST_FAILURE',

    GET_CUSTOMER_DASHBOARD_CARD_REQUEST:'GET_CUSTOMER_DASHBOARD_CARD_REQUEST',
    GET_CUSTOMER_DASHBOARD_CARD_SUCCESS: 'GET_CUSTOMER_DASHBOARD_CARD_SUCCESS',
    GET_CUSTOMER_DASHBOARD_CARD_FAILURE: 'GET_CUSTOMER_DASHBOARD_CARD_FAILURE',

    GET_CUSTOMER_QUESTION_DIST_REQUEST:'GET_CUSTOMER_QUESTION_DIST_REQUEST',
    GET_CUSTOMER_QUESTION_DIST_SUCCESS: 'GET_CUSTOMER_QUESTION_DIST_SUCCESS',
    GET_CUSTOMER_QUESTION_DIST_FAILURE: 'GET_QUESTION_DIST_FAILURE',

    GET_CUSTOMER_QUESTION_DIST_OVERALL_REQUEST:'GET_CUSTOMER_QUESTION_DIST_OVERALL_REQUEST',
    GET_CUSTOMER_QUESTION_DIST_OVERALL_SUCCESS: 'GET_CUSTOMER_QUESTION_DIST_OVERALL_SUCCESS',
    GET_CUSTOMER_QUESTION_DIST_OVERALL_FAILURE: 'GET_CUSTOMER_QUESTION_DIST_OVERALL_FAILURE',

    GET_CUSTOMER_MEAN_SCORE_REQUEST: 'GET_CUSTOMER_MEAN_SCORE_REQUEST',
    GET_CUSTOMER_MEAN_SCORE_SUCCESS: 'GET_CUSTOMER_MEAN_SCORE_SUCCESS',
    GET_CUSTOMER_MEAN_SCORE_FAILURE: 'GET_CUSTOMER_MEAN_SCORE_FAILURE',

    GET_CUSTOMER_MEAN_SCORE_QWISE_REQUEST: 'GET_CUSTOMER_MEAN_SCORE_QWISE_REQUEST',
    GET_CUSTOMER_MEAN_SCORE_QWISE_SUCCESS: 'GET_CUSTOMER_MEAN_SCORE_QWISE_SUCCESS',
    GET_CUSTOMER_MEAN_SCORE_QWISE_FAILURE: 'GET_CUSTOMER_MEAN_SCORE_QWISE_FAILURE',
    
    GET_CUSTOMER_QUES_NPS_RESULT_REQUEST: 'GET_CUSTOMER_QUES_NPS_RESULT_REQUEST',
    GET_CUSTOMER_QUES_NPS_RESULT_SUCCESS: 'GET_CUSTOMER_QUES_NPS_RESULT_SUCCESS',
    GET_CUSTOMER_QUES_NPS_RESULT_FAILURE: 'GET_CUSTOMER_QUES_NPS_RESULT_FAILURE',

    GET_CUSTOMER_QUES_OTHERS_RESULT_REQUEST: 'GET_CUSTOMER_QUES_OTHERS_RESULT_REQUEST',
    GET_CUSTOMER_QUES_OTHERS_RESULT_SUCCESS: 'GET_CUSTOMER_QUES_OTHERS_RESULT_SUCCESS',
    GET_CUSTOMER_QUES_OTHERS_RESULT_FAILURE: 'GET_CUSTOMER_QUES_OTHERS_RESULT_FAILURE',

    GET_CUSTOMER_QUES_FILTER_OTHERS_RESULT_REQUEST: 'GET_CUSTOMER_QUES_FILTER_OTHERS_RESULT_REQUEST',
    GET_CUSTOMER_QUES_FILTER_OTHERS_RESULT_SUCCESS: 'GET_CUSTOMER_QUES_FILTER_OTHERS_RESULT_SUCCESS',
    GET_CUSTOMER_QUES_FILTER_OTHERS_RESULT_FAILURE: 'GET_CUSTOMER_QUES_FILTER_OTHERS_RESULT_FAILURE',


    GET_CUSTOMER_QUES_2_FILTER_OTHERS_RESULT_REQUEST: 'GET_CUSTOMER_QUES_2_FILTER_OTHERS_RESULT_REQUEST',
    GET_CUSTOMER_QUES_2_FILTER_OTHERS_RESULT_SUCCESS: 'GET_CUSTOMER_QUES_2_FILTER_OTHERS_RESULT_SUCCESS',
    GET_CUSTOMER_QUES_2_FILTER_OTHERS_RESULT_FAILURE: 'GET_CUSTOMER_QUES_2_FILTER_OTHERS_RESULT_FAILURE',



    GET_CUSTOMER_COLUMN_NAME_REQUEST: 'GET_CUSTOMER_COLUMN_NAME_REQUEST',
    GET_CUSTOMER_COLUMN_NAME_SUCCESS: 'GET_CUSTOMER_COLUMN_NAME_SUCCESS',
    GET_CUSTOMER_COLUMN_NAME_FAILURE: 'GET_CUSTOMER_COLUMN_NAME_FAILURE',
    
    GET_CUSTOMER_FILTER_LIST_REQUEST: 'GET_CUSTOMER_FILTER_LIST_REQUEST',
    GET_CUSTOMER_FILTER_LIST_SUCCESS: 'GET_CUSTOMER_FILTER_LIST_SUCCESS',
    GET_CUSTOMER_FILTER_LIST_FAILURE: 'GET_CUSTOMER_FILTER_LIST_FAILURE',

    CREATE_CUSTOMER_FILTER_REQUEST: 'CREATE_CUSTOMER_FILTER_REQUEST',
    CREATE_CUSTOMER_FILTER_SUCCESS: 'CREATE_CUSTOMER_FILTER_SUCCESS',
    CREATE_CUSTOMER_FILTER_FAILURE: 'CREATE_CUSTOMER_FILTER_FAILURE',

    UPDATE_CUSTOMER_FILTER_RANK_REQUEST: 'UPDATE_CUSTOMER_FILTER_RANK_REQUEST',
    UPDATE_CUSTOMER_FILTER_RANK_SUCCESS: 'UPDATE_CUSTOMER_FILTER_RANK_SUCCESS',
    UPDATE_CUSTOMER_FILTER_RANK_FAILURE: 'UPDATE_CUSTOMER_FILTER_RANK_FAILURE',

    UPDATE_CUSTOMER_FILTER_STATUS_REQUEST: 'UPDATE_CUSTOMER_FILTER_STATUS_REQUEST',
    UPDATE_CUSTOMER_FILTER_STATUS_SUCCESS: 'UPDATE_CUSTOMER_FILTER_STATUS_SUCCESS',
    UPDATE_CUSTOMER_FILTER_STATUS_FAILURE: 'UPDATE_CUSTOMER_FILTER_STATUS_FAILURE',

    UPDATE_CUSTOMER_SHOW_REPORT_STATUS_REQUEST: 'UPDATE_CUSTOMER_SHOW_REPORT_STATUS_REQUEST',
    UPDATE_CUSTOMER_SHOW_REPORT_STATUS_SUCCESS: 'UPDATE_CUSTOMER_SHOW_REPORT_STATUS_SUCCESS',
    UPDATE_CUSTOMER_SHOW_REPORT_STATUS_FAILURE: 'UPDATE_CUSTOMER_SHOW_REPORT_STATUS_FAILURE',

    DELETE_CUSTOMER_FILTER_REQUEST: 'DELETE_CUSTOMER_FILTER_REQUEST',
    DELETE_CUSTOMER_FILTER_SUCCESS: 'DELETE_CUSTOMER_FILTER_SUCCESS',
    DELETE_CUSTOMER_FILTER_FAILURE: 'DELETE_CUSTOMER_FILTER_FAILURE',

    UPDATE_CUSTOMER_FILTER_LABEL_REQUEST: 'UPDATE_CUSTOMER_FILTER_LABEL_REQUEST',
    UPDATE_CUSTOMER_FILTER_LABEL_SUCCESS: 'UPDATE_CUSTOMER_FILTER_LABEL_SUCCESS',
    UPDATE_CUSTOMER_FILTER_LABEL_FAILURE: 'UPDATE_CUSTOMER_FILTER_LABEL_FAILURE',

    GET_CUSTOMER_LIST_REQUEST: 'GET_CUSTOMER_LIST_REQUEST',
    GET_CUSTOMER_LIST_SUCCESS: 'GET_CUSTOMER_LIST_SUCCESS',
    GET_CUSTOMER_LIST_FAILURE: 'GET_CUSTOMER_LIST_FAILURE',


    GET_CUSTOMER_LEVEL_FILTER_REQUEST: 'GET_CUSTOMER_LEVEL_FILTER_REQUEST',
    GET_CUSTOMER_LEVEL_FILTER_SUCCESS: 'GET_CUSTOMER_LEVEL_FILTER_SUCCESS',
    GET_CUSTOMER_LEVEL_FILTER_FAILURE: 'GET_CUSTOMER_LEVEL_FILTER_FAILURE',


    GET_CUSTOMER_DMG_FILTER_REQUEST: 'GET_CUSTOMER_DMG_FILTER_REQUEST',
    GET_CUSTOMER_DMG_FILTER_SUCCESS: 'GET_CUSTOMER_DMG_FILTER_SUCCESS',
    GET_CUSTOMER_DMG_FILTER_FAILURE: 'GET_CUSTOMER_DMG_FILTER_FAILURE',


    GET_CUSTOMER_RESPONDANTS_REQUEST: 'GET_CUSTOMER_RESPONDANTS_REQUEST',
    GET_CUSTOMER_RESPONDANTS_SUCCESS: 'GET_CUSTOMER_RESPONDANTS_SUCCESS',
    GET_CUSTOMER_RESPONDANTS_FAILURE: 'GET_CUSTOMER_RESPONDANTS_FAILURE',

    GET_CUSTOMER_RECORD_REQUEST: 'GET_CUSTOMER_RECORD_REQUEST',
    GET_CUSTOMER_RECORD_SUCCESS: 'GET_CUSTOMER_RECORD_SUCCESS',
    GET_CUSTOMER_RECORD_FAILURE: 'GET_CUSTOMER_RECORD_FAILURE',

    GET_CAMPAIGN_CUSTOMER_BY_ID_REQUEST: 'GET_CAMPAIGN_CUSTOMER_BY_ID_REQUEST',
    GET_CAMPAIGN_CUSTOMER_BY_ID_SUCCESS: 'GET_CAMPAIGN_CUSTOMER_BY_ID_SUCCESS',
    GET_CAMPAIGN_CUSTOMER_BY_ID_FAILURE: 'GET_CAMPAIGN_CUSTOMER_BY_ID_FAILURE',
};

