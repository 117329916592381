import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import html2canvas from 'html2canvas';

class ReportWaiseData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      selectedOption: 'Bar chart',

      dimensionValueList: [],
      EmpIndexA: 0,
      EmpIndexB: 4
    }
  }

  componentDidMount() {
  }

  handleDropdownToggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  handleOptionClick = (option) => {
    this.setState({
      selectedOption: option,
      dropdownOpen: false // Close dropdown after selection
    });
  };


  render() {

    let { item, stage, index, onOthersCardClick } = this.props;
    let { total } = item ? item : {};

    // console.log('DropDown Type _111111111111111111', item);
    // console.log('DropDown Type _total_222222222222222', total);

    // const customOrder = ['Yes', 'No'];
    // total.sort((a, b) => customOrder.indexOf(a._id.answerText) - customOrder.indexOf(b._id.answerText));

    const chartData = total.map(item => ({
      name: item._id && item._id.answerText ? item._id.answerText : '',
      value: item && item.count ? Math.round((item.count / total.reduce((acc, curr) => acc + curr.count, 0) * 100)) : 0
    }));

    // Generate xAxis data dynamically
    // const xAxisData = total
    //   .map(item => item._id && item._id.answerText)
    //   .sort((a, b) => customOrder.indexOf(a) - customOrder.indexOf(b));


    // Define color mapping function
    // const getColor = answerText => {
    //   return answerText === 'Yes' ? '#449E48' : '#DC3C31';
    // };

    // Define colors
    // const colorList = xAxisData.map(answerText => getColor(answerText));


    if (chartData && chartData.length > 0) {
      chartData.sort(
        (a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0)
      );
    }

    return (


      <div className={'w-full pb-6 space-y-4 bg-white report-section'}>

        <div className={'lg:p-6 p-4 space-y-4 report-section' + (index + 1).toString()}>
          <div className='flex lg:flex-row flex-col-reverse   items-start justify-between w-full gap-4'>
            <div className='flex items-start text-[#212121] space-x-3'>
              <p className='p-3 py-2 bg-[#ededed] font-semibold'>Q.{index + 1}</p>
              <div className=''>
                <h2 className='text-xl font-medium text-[#212121]'>{item && item.question.name ? item.question.name : 'null'}</h2>
                <p className='text-base text-[#212121]/80 flex space-x-2 items-center pt-3'>
                  <p className='px-2 py-1 text-sm bg-[#ededed] font-semibold text-[#212121]'>Dropdown</p>
                  {/* <b className='font-medium text-[#212121] pr-1'>10</b> out of<b className='font-medium text-[#212121] pr-1'>10</b> answered */}
                </p>
              </div>
            </div>
            <div className='flex items-center flex-shrink-0 space-x-2'>
              <button className='p-3 bg-[#ededed] rounded flex flex-row items-center gap-2 text-[#212121] relative'>
                <span className='flex items-center gap-2' onClick={this.handleDropdownToggle}>
                  {this.state.selectedOption === 'Bar chart' && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                      <path d="M12.1875 2.8125V25.3125H10.3125V6.5625H4.6875V25.3125H2.8125V27.1875H27.1875V25.3125H25.3125V13.125H19.6875V25.3125H17.8125V2.8125H12.1875ZM14.0625 4.6875H15.9375V25.3125H14.0625V4.6875Z" fill="#212121" />
                    </svg>
                  )}
                  {this.state.selectedOption === 'Table' && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                      <path d="M26.25 2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V26.25C2.5 26.5815 2.6317 26.8995 2.86612 27.1339C3.10054 27.3683 3.41848 27.5 3.75 27.5H26.25C26.5815 27.5 26.8995 27.3683 27.1339 27.1339C27.3683 26.8995 27.5 26.5815 27.5 26.25V3.75C27.5 3.41848 27.3683 3.10054 27.1339 2.86612C26.8995 2.6317 26.5815 2.5 26.25 2.5ZM10 25H5V20H10V25ZM10 17.5H5V12.5H10V17.5ZM10 10H5V5H10V10ZM17.5 25H12.5V20H17.5V25ZM17.5 17.5H12.5V12.5H17.5V17.5ZM17.5 10H12.5V5H17.5V10ZM25 25H20V20H25V25ZM25 17.5H20V12.5H25V17.5ZM25 10H20V5H25V10Z" fill="#212121" />
                    </svg>
                  )}
                  <span>{this.state.selectedOption}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor" class="icon icon-tabler icons-tabler-filled icon-tabler-caret-down"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" /></svg>
                </span>
                {this.state.dropdownOpen && (
                  <div className='absolute left-0 z-20 px-2 bg-white divide-y rounded shadow-xl top-12 w-fit whitespace-nowrap'>
                    <div className="flex items-center flex-shrink-0 space-x-2 text-[#212121] p-3 text-sm" onClick={() => this.handleOptionClick('Bar chart')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                        <path d="M12.1875 2.8125V25.3125H10.3125V6.5625H4.6875V25.3125H2.8125V27.1875H27.1875V25.3125H25.3125V13.125H19.6875V25.3125H17.8125V2.8125H12.1875ZM14.0625 4.6875H15.9375V25.3125H14.0625V4.6875Z" fill="#212121" />
                      </svg>
                      <span>Bar chart</span>
                    </div>
                    <div className="flex items-center flex-shrink-0 space-x-2 text-[#212121] p-3 text-sm" onClick={() => this.handleOptionClick('Table')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                        <path d="M26.25 2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V26.25C2.5 26.5815 2.6317 26.8995 2.86612 27.1339C3.10054 27.3683 3.41848 27.5 3.75 27.5H26.25C26.5815 27.5 26.8995 27.3683 27.1339 27.1339C27.3683 26.8995 27.5 26.5815 27.5 26.25V3.75C27.5 3.41848 27.3683 3.10054 27.1339 2.86612C26.8995 2.6317 26.5815 2.5 26.25 2.5ZM10 25H5V20H10V25ZM10 17.5H5V12.5H10V17.5ZM10 10H5V5H10V10ZM17.5 25H12.5V20H17.5V25ZM17.5 17.5H12.5V12.5H17.5V17.5ZM17.5 10H12.5V5H17.5V10ZM25 25H20V20H25V25ZM25 17.5H20V12.5H25V17.5ZM25 10H20V5H25V10Z" fill="#212121" />
                      </svg>
                      <span>Table</span>
                    </div>
                  </div>
                )}
              </button>
              {/* <button onClick={() => this.handleOptionClick('Table')} className='p-3 bg-[#ededed] rounded text-[#212121]'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-download"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" /><path d="M7 11l5 5l5 -5" /><path d="M12 4l0 12" /></svg></button> */}
            </div>
          </div>


          {this.state.selectedOption === 'Bar chart' && (

            <div className="relative w-full px-3 py-4">
              <div className='space-y-6 '>
                {chartData.map((data, index) => (
                  <div key={index} className='flex items-center flex-shrink-0 space-x-2 text-[#3D405B]'>
                    <span className='text-right w-44'>{data.name}</span>
                    <span>-</span>
                    <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                      <div className='flex items-center justify-start h-8' style={{ width: `${data.value}%`, backgroundColor: "#b388ff" }}>
                        {/* <span className='text-center p-2 text-[#212121]'>{data.value}%</span> */}
                      </div>
                      <span className='flex items-center text-center p-2 text-[#212121]'>{data.value}%</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {this.state.selectedOption === 'Table' && (
            <div className='bg-[#f9f9f9] p-6 rounded'>
              <table className='w-full'>
                <thead>
                  <tr className='border-b'>
                    <th className='p-4 text-lg font-medium text-left'>Answer</th>
                    <th className='p-4 text-lg font-medium text-left'>Responses</th>
                    <th className='p-4 text-lg font-medium text-left'>Responses (%)</th>
                  </tr>
                </thead>

                <tbody className='divide-y '>
                  {total && total.length > 0 ?
                    total.map((ele) =>
                      <tr className='text-[#212121]'>
                        <td className='p-4 text-left'>{ele && ele._id && ele._id && ele._id.answerText ? ele._id.answerText : ''}</td>
                        <td className='p-4 pl-6 text-left'>{ele && ele.count ? ele.count : ''}</td>
                        <td className='p-4 pl-6 text-left'>{ele && ele.count ? (ele.count / total.reduce((acc, curr) => acc + curr.count, 0) * 100).toFixed(0) : 0}%</td>
                      </tr>
                    ) :
                    <tr>
                      <td colSpan={3} className='pt-2 text-center'>Data not found</td>
                    </tr>
                  }
                </tbody>

              </table>
            </div>
          )}
        </div>
        {stage !== 2 ?
          <>
            <button className='flex items-center flex-shrink-0 space-x-2 font-medium text-[#2196F3] p-2 w-fit mx-auto'
              onClick={() => onOthersCardClick(item)}
            >
              <span>{stage == 2 ? "View More" : (stage == 3 ? "" : " View More")} </span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 6l6 6l-6 6" /></svg>
            </button>
          </>
          : null}
      </div>


    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ReportWaiseData);
