import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";


export default function GaugeScore(props) {
    
    let {  grandScore } = props;

    let score = grandScore && grandScore.toFixed(2)?grandScore.toFixed(2):"NaN"

    return (
      <>	
      <div className="pt-1 flex flex-col justify-center items-center "  >
      {score !== "NaN"?
      <>
     <div className='h-60'>
     <ReactSpeedometer className="meter "
          value={score}
          minValue={16}
          maxValue={180}
          needleColor="#4db8ff"
          segments={3}
          segmentColors={[
          "#b31717",
          "#e65757",
          "#f29083",
          "#edb409",
          "#1bbf36"
          ]}
          ringWidth={35}
          customSegmentStops={[16, 100, 110, 130, 150, 180]}
          // fluidWidth={true}
          needleTransitionDuration={3333}
          needleTransition="easeElastic"
        />
     </div>

        <div className="flex items-center justify-center">
          <div className=" grid xl:grid-cols-2 grid-cols-2 gap-6 text-xs text-gray-500  ">
            <div className="flex items-center "><div style={{background:'#b31717'}} className="p-1 bg-red-500 rounded-sm" /><p className="ml-2">Extremely High Risk (16-100)</p></div>
            <div className="flex items-center "><div style={{background:'#e65757'}} className="p-1 bg-red-300 rounded-sm" /><p className="ml-2">High Risk (100-110)</p></div>
            <div className="flex items-center "><div style={{background:'#f29083'}} className="p-1 bg-red-300 rounded-sm" /><p className="ml-2">Borderline (110-130)</p></div>
            <div className="flex items-center "><div style={{background:'#edb409'}} className="p-1 bg-yellow-400 rounded-sm" /><p className="ml-2">Moderate Risk (130-150)</p></div>
            <div className="flex items-center "><div style={{background:'#1bbf36'}} className="p-1 bg-green-400 rounded-sm" /><p className="ml-2">No Risk (150-180)</p></div>
          </div>
        </div>
      </>
      :<>
      <div className='text-gray-500 text-center mt-4'>Please select employee</div>
      </>}
         
      
      </div>

      </>
    );
  }
