import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionStack from './Charts/QuestionStack';
import Filter from './Filter';

class ResponseDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue:'none',
      currFilterName:'none'
    }
  }
  componentDidMount() {

  }

  handleFilterSave=(name)=>{
    this.setState({currFilterName:name});
  }

  handleFilterValueSave=(name,value)=>{
    this.setState({currFilterValue:value});
    let { handleResponseDistributionFilter } = this.props;
    if(name !=='none' && value !=='none'){
      handleResponseDistributionFilter(name,value)
    }
  }

  render() {
    let { getMeanScoreQwiseData } = this.props;

    let QuestionList = [];
    if(getMeanScoreQwiseData && getMeanScoreQwiseData.length>0){
      getMeanScoreQwiseData.forEach((item)=>{
        let getIndex = QuestionList.findIndex(prev=>prev.name===item.questionName);
        if(getIndex===-1){
          let tempData = {
            "name":item.questionName,
            "mean":item.score,
            "total":1,
            "parameter":item && item.parameterBucketName?item.parameterBucketName:"-"
          }
          QuestionList.push(tempData);
        }
        else{
          QuestionList[getIndex].mean+=item.score;
          QuestionList[getIndex].total++;
        }

      });
    }

    ////console.log('QuestionList: ',QuestionList)
    let maxMean = 0;
    if(QuestionList && QuestionList.length>0){
      QuestionList.forEach((item)=>{
        if(item.mean>maxMean){
          maxMean=item.mean
        }
      });
    }



    let tempObj = {
      mean: 0,
      name: "Overall Question Mean",
      total: 1,
      parameter:""
    }

    let QuestionListNew = []
    if(QuestionList && QuestionList.length>0){
      QuestionList.forEach((ques)=>{
        tempObj["mean"]+=parseFloat(ques.mean);
      });
      tempObj["mean"]/=QuestionList.length;
      tempObj["mean"]=parseFloat(tempObj["mean"].toFixed(2));

      QuestionList.forEach((ques)=>{
        QuestionListNew.push(ques);
      });

    }




    QuestionListNew.sort((a,b)=>b.mean-a.mean)

    ////console.log('QuestionListNew: ',QuestionListNew)


    return (
      <>

  
    <div className="container mx-auto px-2 mt-2">
      <div className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 xl:grid-cols-1  gap-2">   
      <div className="text-lg font-semibold py-2 px-4">Overall Question Mean</div>
        
      <div className=" px-2">

      {QuestionListNew && QuestionListNew.length>0?
          <div className="py-2  whitespace-nowrap  px-4">
                <table className="border-collapse w-full ">
                  <thead>
                    <tr className=" text-xs   text-gray-800">
                    <th className="py-2 px-2 font-bold"><div className="flex items-center justify-left">Index</div></th>
                    <th className="py-2 px-2 font-bold"><div className="flex items-left justify-left">Question</div></th>
                    <th className="py-2 px-2 font-bold"><div className="flex items-center justify-center">Mean (%)</div></th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr className="bg-white  mb-10 lg:mb-0 text-xs text-gray-800">
                          <td className="tooltip-response-question1 px-2  w-2/12">                             
                          <div className={tempObj.name==="Overall Question Mean"? "cursor-pointer font-bold":"cursor-pointer"} style={{width:'20%'}}>
                            {"Overall Index"}
                          </div>
                          </td>
                          <td className="tooltip-response-question1 px-2  w-2/12">                             
                          <div className={tempObj.name==="Overall Question Mean"? "cursor-pointer font-bold":"cursor-pointer"} style={{width:'20%'}}>
                            {(tempObj && tempObj.name && tempObj.name.length>65)?tempObj.name.slice(0,65)+'...':tempObj.name}
                          </div>
                            <div className="tooltip-response-question2">
                              {tempObj.name}
                            </div>
                          </td>
                          <td className="  w-10/12" >      
                            <div className="">
                              <QuestionStack question={tempObj} maxMean={maxMean} />

                            </div>
                          </td>
                        </tr>
                    {QuestionListNew && QuestionListNew.length>0?
                      QuestionListNew.map((question,index)=>
                        <tr key={index} className="bg-white  mb-10 lg:mb-0 text-xs text-gray-800">
                          <td className="tooltip-response-question1 px-2  w-2/12">                             
                          <div className={question.parameter==="overall"? "cursor-pointer font-bold":"cursor-pointer"} style={{width:'20%'}}>
                            {question && question.parameter?question.parameter:""}
                          </div>
                          </td>
                          <td className="tooltip-response-question1 px-2  w-2/12">                             
                          <div className={question.parameter==="overall"? "cursor-pointer font-bold":"cursor-pointer"} style={{width:'20%'}}>
                            {(question && question.name && question.name.length>65)?question.name.slice(0,65)+'...':question.name}
                          </div>
                            <div className="tooltip-response-question2">
                              {question.name}
                            </div>
                          </td>
                          <td className="  w-10/12" >      
                            <div className="">
                              <QuestionStack question={question} maxMean={maxMean} />

                            </div>
                          </td>
                        </tr>
                      )
                    :null}

                  </tbody>
                </table>
              </div>
              :
              <div className="py-10 text-center text-lg text-gray-500">No Data Available</div>
            }
      </div>
  </div>
</div>



      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ResponseDistribution);
