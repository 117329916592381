export const happinessConstants = {

    GET_HAPPINESS_EMP_RESPONDANTS_REQUEST:'GET_HAPPINESS_EMP_RESPONDANTS_REQUEST',
    GET_HAPPINESS_EMP_RESPONDANTS_SUCCESS: 'GET_HAPPINESS_EMP_RESPONDANTS_SUCCESS',
    GET_HAPPINESS_EMP_RESPONDANTS_FAILURE: 'GET_HAPPINESS_EMP_RESPONDANTS_FAILURE',

    GET_HAPPINESS_ORG_HAPPINESS_REQUEST:'GET_HAPPINESS_ORG_HAPPINESS_REQUEST',
    GET_HAPPINESS_ORG_HAPPINESS_SUCCESS: 'GET_HAPPINESS_ORG_HAPPINESS_SUCCESS',
    GET_HAPPINESS_ORG_HAPPINESS_FAILURE: 'GET_HAPPINESS_ORG_HAPPINESS_FAILURE',

    GET_HAPPINESS_ORG_HAPPINESS_DMG_WISE_REQUEST:'GET_HAPPINESS_ORG_HAPPINESS_DMG_WISE_REQUEST',
    GET_HAPPINESS_ORG_HAPPINESS_DMG_WISE_SUCCESS: 'GET_HAPPINESS_ORG_HAPPINESS_DMG_WISE_SUCCESS',
    GET_HAPPINESS_ORG_HAPPINESS_DMG_WISE_FAILURE: 'GET_HAPPINESS_ORG_HAPPINESS_DMG_WISE_FAILURE',

    GET_HAPPYPLUS_FILTER_DMG_REQUEST:'GET_HAPPYPLUS_FILTER_DMG_REQUEST',
    GET_HAPPYPLUS_FILTER_DMG_SUCCESS: 'GET_HAPPYPLUS_FILTER_DMG_SUCCESS',
    GET_HAPPYPLUS_FILTER_DMG_FAILURE: 'GET_HAPPYPLUS_FILTER_DMG_FAILURE',
};

