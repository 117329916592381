import React, { useState } from "react";
// import Sidebar from "./Components/Sidebar/Sidebar";
import Sidebar from "./sidebar/sidebar";
export default function Normalization(props, history) {
  // console.log('locationlocationlocationlocationlocation', props);

  let { item, name, stage } = props;
  const [isAddGoal, setIsAddGoal] = useState(false);
  const [isAddTask, setIsAddTask] = useState(false);
  // State to track the active tab
  const [activeTab, setActiveTab] = useState("goals");
  const [activeTabOne, setActiveTabOne] = useState("Review");

  const handleTabClick = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTab(tab);
  };

  const handleTabClickTop = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTabOne(tab);
  };

  const handleAddGoal = () => {
    setIsAddGoal(!isAddGoal);
  };
  const handleCloseAddGoal = () => {
    setIsAddGoal(false);
  };

  const handleAddTask = () => {
    setIsAddTask(!isAddTask);
  };
  const handleCloseAddTask = () => {
    setIsAddTask(false);
  };

  return (
    <>
      <div className="h-screen overflow-hidden bg-gray-50">
        <header
          className=" w-full flex justify-between items-center  md:px-4 px-4 bg-white  border-b sticky top-0 z-20"
          style={{ height: "4rem" }}
        >
          <div className="flex itmes-center  space-x-4">
            <button className="text-blue-500 bg-[#edf6fe] rounded-full cursor-pointer hover:bg-blue-100 trasition duration-150 p-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-arrow-left  "
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#2196f3"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1={5} y1={12} x2={19} y2={12} />
                <line x1={5} y1={12} x2={11} y2={18} />
                <line x1={5} y1={12} x2={11} y2={6} />
              </svg>
            </button>
            <div className="flex items-center space-x-4 ">
              <a href="#/app/overview">
                <img
                  src="/img/plus_ex_logo.svg"
                  className="w-24 object-cover"
                />
              </a>
              <span className="border py-1 px-2 text-gray-500 text-sm rounded-md">
                ABC Pvt Ltd
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between py-3 select-none">
            <div className="lg:flex hidden border-r pr-3 mx-2 items-center " />
            <div className="relative flex items-center ">
              <button className="relative block h-10 w-10 ml-2 rounded-full overflow-hidden  focus:outline-none bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] ">
                <span className="text-sm font-semibold text-white tracking-widest">
                  TE
                </span>
              </button>
              <div className="ml-2 lg:flex items-center hidden cursor-pointer relative">
                <h1 className="text-base text-slate-800 font-medium select-none">
                  Temp005
                </h1>
                <span className="material-symbols-outlined text-slate-600 text-[1.8rem]">
                  arrow_drop_down
                </span>
              </div>
            </div>
          </div>
        </header>
        <div className="px-4  flex  justify-between items-center bg-white border-b py-2.5">
          <div className="flex items-center space-x-4">
            <h2 className="xl:text-lg text-base text-[#3D405B] truncate font-medium">
              {" "}
              Appraisal HR - Q3
            </h2>

            <div class="flex items-center px-4 py-2 space-x-2 rounded-md w-fit bg-green-50 text-sm">
              {" "}
              <span class="font-medium text-green-500">Completed</span>
            </div>
          </div>
          <div>
            <span class="text-[#212121]/80">01-Jan-2023 - 01-Apr-2023</span>
          </div>
        </div>

        <div className="flex w-full" style={{ height: "calc(100% - 6.5rem)" }}>

          <Sidebar />

          <main className="flex-1 md:p-6 p-4 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto relative ">
            <div className="space-y-4">
              <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
                <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]  ">
                  Normalization
                </h1>
                <div className="md:flex  hidden items-center text-sm text-[#3D405B]">
                  <span className="mx-2 material-symbols-rounded">home</span>
                  <span>/</span>
                  <span className="px-2">Employee Engagement</span>
                  <span>/</span>
                  <span className="px-2">Dashboard</span>
                  <span>/</span>
                  <span className="px-2 font-semibold">Manager Score Card</span>
                </div>
              </div>


              <div className=" md:flex  justify-between items-center ">
                <div className="flex gap-2 flex-wrap">
                  <div className="cursor-pointer bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] flex justify-between px-4 items-center relative py-2 rounded-md w-40 ">
                    <div className="flex space-x-2 items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-filter"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#fff"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
                      </svg>
                      <span className=" text-sm text-white ">Filters</span>
                    </div>
                    <span className=" bg-white  rounded-full text-[#2196F3] text-xs w-6 h-6 flex items-center justify-center font-semibold">
                      2
                    </span>
                  </div>
                  <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                    <span className=" text-sm text-[#3D405B]/70 font-medium ">
                      Location
                    </span>
                    <span className="material-symbols-outlined ">
                      arrow_drop_down
                    </span>
                  </div>
                  <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5">
                    <span className=" text-sm text-[#3D405B]/70 font-medium ">
                      Department
                    </span>
                    <span className="material-symbols-outlined ">
                      arrow_drop_down
                    </span>
                  </div>
                </div>

              </div>


              <div className="bg-white p-6 space-y-6 border rounded-md">

                <div className="items-center justify-between  md:flex">
                  <h1 class="text-xl font-medium text-[#3D405B]">Expected vS Actual Rating</h1>
                  <div className="flex  text-sm font-medium text-gray-600 border rounded-full bg-[#F9FAFB]  ">
                    <span
                      onClick={() => handleTabClick("BeforeNormalized")}
                      className={
                        activeTab === "BeforeNormalized"
                          ? "cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white   rounded-full   border-[#2196f3]"
                          : "cursor-pointer text-sm py-2 px-4 font-medium   text-[#3D405B]/70   rounded-full  "
                      }
                    >
                      Before Normalized
                    </span>
                    <span
                      onClick={() => handleTabClick("AfterNormalized")}
                      className={
                        activeTab === "AfterNormalized"
                          ? "cursor-pointer text-sm py-2 px-4 font-medium bg-[#2196f3] text-white   rounded-full   border-[#2196f3]"
                          : "cursor-pointer text-sm py-2 px-4 font-medium   text-[#3D405B]/70   rounded-full  "
                      }
                    >
                      After Normalized
                    </span>

                  </div>
                </div>

                <div className="flex  text-sm font-medium text-gray-600 space-x-2     ">
                  <span
                    onClick={() => handleTabClick("TableView")}
                    className={
                      activeTab === "TableView"
                        ? "cursor-pointer text-sm py-2 px-4 font-medium text-[#2196f3] flex items-center space-x-2  border   rounded-md   border-[#2196f3]"
                        : "cursor-pointer text-sm py-2 px-4 font-medium   text-[#3D405B]/70 border  rounded-md flex items-center space-x-2  "
                    }
                  >

                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-table" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z"></path>
                      <path d="M3 10h18"></path>
                      <path d="M10 3v18"></path>
                    </svg>
                    <span>  Table View</span>
                  </span>
                  <span
                    onClick={() => handleTabClick("BellCurve")}
                    className={
                      activeTab === "BellCurve"
                        ? "cursor-pointer text-sm py-2 px-4 font-medium text-[#2196f3] flex items-center space-x-2   border   rounded-md   border-[#2196f3]"
                        : "cursor-pointer text-sm py-2 px-4 font-medium   text-[#3D405B]/70 border  rounded-md flex items-center space-x-2  "
                    }
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-sankey" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M3 3v18h18"></path>
                      <path d="M3 6h18"></path>
                      <path d="M3 8c10 0 8 9 18 9"></path>
                    </svg>
                    <span>  Bell Curve</span>
                  </span>

                </div>

                <div className="border p-6 space-y-6 rounded-md">
                  <h1 class="text-xl font-medium text-[#3D405B]">Expected VS Actual Rating</h1>

                  <div className="overflow-x-auto border ">
                    <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse xl:whitespace-normal whitespace-nowrap">
                      <thead className="bg-white">
                        <tr className="bg-[#F9FAFB] ">
                          <th
                            className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r"
                          >

                          </th>
                          <th
                            className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                          >
                            Outstanding

                          </th>

                          <th
                            className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                          >
                            Excellent
                          </th>
                          <th
                            className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                          >
                            Satisfactory
                          </th>
                          <th
                            className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                          >
                            Need Improvement
                          </th>
                          <th
                            className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                          >
                            Unsatisfactory
                          </th>
                          <th
                            className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                          >
                            Total
                          </th>
                        </tr>

                      </thead>
                      <tbody className="border-t   divide-y  ">
                        <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                          <td className="px-6 py-3   border-r font-semibold ">
                            Expected % (Numbers)
                          </td>
                          <td className="px-6 py-3 text-center border-r ">
                            5% (2)
                          </td>




                          <td className="px-6 py-3 text-center border-r">
                            5% (2)
                          </td>
                          <td className="px-6 py-3 text-center border-r">
                            5% (2)
                          </td>
                          <td className="px-6 py-3 text-center border-r">
                            5% (2)
                          </td>
                          <td className="px-6 py-3 text-center border-r">
                            5% (2)
                          </td>
                          <td className="px-6 py-3 text-center border-r">
                            100%(40)
                          </td>



                        </tr>

                        <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                          <td className="px-6 py-3   border-r font-semibold ">
                            Actual % (Numbers)
                          </td>
                          <td className="px-6 py-3 text-center border-r ">
                            5% (2)
                          </td>




                          <td className="px-6 py-3 text-center border-r">
                            5% (2)
                          </td>
                          <td className="px-6 py-3 text-center border-r">
                            5% (2)
                          </td>
                          <td className="px-6 py-3 text-center border-r">
                            5% (2)
                          </td>
                          <td className="px-6 py-3 text-center border-r">
                            5% (2)
                          </td>
                          <td className="px-6 py-3 text-center border-r">
                            100%(40)
                          </td>



                        </tr>



                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="bg-white p-6 space-y-6 border rounded-md">

                <div className="items-center justify-between  md:flex">
                  <h1 class="text-xl font-medium text-[#3D405B]">Manage Normalization</h1>
                  <div className="flex  text-sm font-medium text-gray-600 border rounded-full bg-[#F9FAFB]  ">


                  </div>
                </div>

                <div className="overflow-x-auto border ">
                  <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse xl:whitespace-normal whitespace-nowrap">
                    <thead className="bg-white">
                      <tr className="bg-[#F9FAFB] ">
                        <th
                          className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r"
                        >
                          Rating / Normalization

                        </th>
                        <th
                          className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                        >

                        </th>

                        <th
                          className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                        >

                        </th>
                        <th
                          className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                        >

                        </th>
                        <th
                          className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                        >

                        </th>

                      </tr>

                    </thead>
                    <tbody className="border-t   divide-y  ">
                      <tr className="bg-white  font-normal text-sm text-[#3D405B] ">
                        <td className="px-6 py-3   border-r  ">
                          <p>Outstanding</p>
                          <span className="font-semibold" >1/1</span>
                        </td>
                        <td className="px-6 py-3  border-r ">
                          <p>Excellent</p>
                          <span className="font-semibold" >1/1</span>
                        </td>

                        <td className="px-6 py-3  border-r">
                          <p>Satisfactory</p>
                          <span className="font-semibold" >1/1</span>
                        </td>
                        <td className="px-6 py-3  border-r">
                          <p>Need Improvement</p>
                          <span className="font-semibold" >1/1</span>
                        </td>
                        <td className="px-6 py-3  border-r">
                          <p>Unsatisfactory</p>
                          <span className="font-semibold" >1/1</span>
                        </td>




                      </tr>

                    </tbody>
                  </table>
                </div>


                <div className="overflow-x-auto border ">
                  <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse xl:whitespace-normal whitespace-nowrap">
                    <thead className="bg-white">
                      <tr className="bg-[#F9FAFB] ">
                        <th
                          className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r"
                        >
                          Employee

                        </th>
                        <th
                          className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                        >
                          Actual Rating

                        </th>

                        <th
                          className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r"
                        >
                          Normalized Rating
                        </th>


                      </tr>

                    </thead>
                    <tbody className="border-t   divide-y  ">
                      <tr className="bg-white  font-normal text-sm text-[#3D405B] ">
                        <td className="px-6 py-3   border-r  ">
                          <div className="flex items-center space-x-2"><img src="img/newimg/Rectangle 11116.png" alt="" className="rounded-full w-12 h-12" /><div className=" "><span className="capitalize text-base font-medium block text-[#3D405B]">Hari KC</span><p className="capitalize text-sm  block text-[#3D405B] font-normal">HR Manager - Dept of Human Resources</p></div></div>
                        </td>
                        <td className="px-6 py-3 text-center  border-r ">
                          Outstanding
                        </td>

                        <td className="px-6 py-3  border-r">
                          <div className="flex justify-center">
                            <div className="border flex items-center px-4 py-2 rounded-md justify-between w-56 text-[#3D405B]/70">
                              <span>Outstanding</span>
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-down-filled" width="14" height="14" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="0">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" stroke-width="0" fill="currentColor"></path>
                              </svg>
                            </div>
                          </div>
                        </td>

                      </tr>

                      <tr className="bg-white  font-normal text-sm text-[#3D405B] ">
                        <td className="px-6 py-3   border-r  ">
                          <div className="flex items-center space-x-2"><img src="img/newimg/Rectangle 11116.png" alt="" className="rounded-full w-12 h-12" /><div className=" "><span className="capitalize text-base font-medium block text-[#3D405B]">Hari KC</span><p className="capitalize text-sm  block text-[#3D405B] font-normal">HR Manager - Dept of Human Resources</p></div></div>
                        </td>
                        <td className="px-6 py-3 text-center  border-r ">
                          Need Improvement
                        </td>

                        <td className="px-6 py-3  border-r">
                          <div className="flex justify-center">
                            <div className="border flex items-center px-4 py-2 rounded-md justify-between w-56 text-[#3D405B]/70">
                              <span>Need Improvement</span>
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-down-filled" width="14" height="14" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="0">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" stroke-width="0" fill="currentColor"></path>
                              </svg>
                            </div>
                          </div>
                        </td>

                      </tr>

                    </tbody>
                  </table>
                </div>

                <div className="flex items-center justify-between" >

                  <p className="text-[#3D405B] text-sm">Showing 1 to 5 of 150 entries</p>

                  <div className="flex border divide-x rounded-md "><button className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">Previous</button><button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">1</button><button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">2</button><button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">3</button><button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">4</button><button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">5</button><button className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">Next</button></div>
                </div>

                <div className="flex items-center justify-end px-6 py-6 space-x-4 bg-white text-sm font-medium border-t -m-6"><button className="w-32 px-4 py-2 uppercase bg-[#F9FAFB] text-[#3D405B] border rounded-sm">save</button><button className="border rounded-sm w-32 py-2 px-4 uppercase bg-[#F9FAFB] text-[#3D405B]  ">Submit</button></div>



              </div>
            </div>

            <div className="fixed right-0 bottom-6 w-full flex  ">
              <div className="w-64 lg:block hidden">
              </div>
              <div className="w-full">
                <div className="bg-[#4CAF50] lg:p-6 p-4 flex justify-between items-center rounded-lg shadow-2xl  lg:w-8/12 w-11/12 mx-auto   ">
                  <div className="flex items-center space-x-4">
                    <div className="h-12 w-12 flex justify-center items-center bg-white text-[#4CAF50] rounded-full flex-shrink-0">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M5 12l5 5l10 -10"></path>
                      </svg>
                    </div>
                    <p className="text-white font-semibold lg:text-xl text-base">Changes has been successfully saved.</p>
                  </div>

                  <div className="h-10 w-10 flex justify-center items-center bg-black/10 text-white rounded-full flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M18 6l-12 12"></path>
                      <path d="M6 6l12 12"></path>
                    </svg>
                  </div>

                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
