import React, { Component } from 'react';
import { connect } from 'react-redux';
import IndexComponent from './DimensionIndex/IndexComponent/IndexComponent';
import Commentary from '../../../Index/Components/Commentary/Commentary';

// import IndexComponent from './DimensionIndex/IndexComponent';

// import Filter from './Filter';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      viewScore: true,
      showDropDown1: false,
      showDropDown2: false,

      dimensionTypeNo: 1,

      rwaIndexType: 2,



      selectedItem1: {
        index: 1,
        name: 'Happiness',
        cutOff: 59
      },
      selectedItem2: {
        index: 2,
        name: 'Engagement',
        cutOff: 78
      },
    }
  }
  componentDidMount() {

  }

  handleViewScore = (data) => {
    this.setState({ viewScore: data })
  }




  handleDropdown1 = (check) => {
    this.setState({ showDropDown1: !check, showDropDown2: false })
  }
  handleDropdown2 = (check) => {
    this.setState({ showDropDown2: !check, showDropDown1: false })
  }

  changeItem1 = (item) => {
    this.setState({ selectedItem1: item })
    this.setState({ showDropDown1: false, showDropDown2: false })

  }

  changeItem2 = (item) => {
    this.setState({ selectedItem2: item })
    this.setState({ showDropDown1: false, showDropDown2: false })

  }


  handleSummaryCard = (val) => {
    let { handleQuadrantFilter } = this.props;
    this.setState({ currFilterName: val })
    handleQuadrantFilter(val)
  }

  // handleFilternameSave=(name)=>{
  //   this.setState({currFilterName:name})
  // }

  onSwitchOptionScreen = (validateList) => {
    let { ErrorIndexObj, GetParameterLabel, loading, handleFilterRWA, CountRestrict, getEMPFilterLabel,
      indexType, optionType, getCommentData, EmpFilterData, getIndexFilterData,
      getEngagementRWAError, getEngagementRWAData, handleRWAFilter, validateIndex, getIndexByFilterDMGData,
      getSurveyData, getIndexData
    } = this.props;

    let { rwaIndexType } = this.state;


    let OptType = 0;
    if (rwaIndexType) {
      OptType = rwaIndexType;
    }
    else {
      OptType = validateList && validateList.length > 0 ? validateList[0].template : 0;
    }

    let NewOption = parseInt((optionType).toString() + (rwaIndexType).toString());

    let IndexName = "";
    if (rwaIndexType === 1) {
      IndexName = "Happiness";
    }
    else if (rwaIndexType === 2) {
      IndexName = "Engagement";
    }
    else if (rwaIndexType === 4) {
      IndexName = "Stress";
    }
    else if (rwaIndexType === 5) {
      IndexName = "Manager";
    }
    else if (rwaIndexType === 6) {
      IndexName = "Leader";
    }



    // return (
    //   <IndexComponent  loading={loading} handleFilterRWA={handleFilterRWA}
    //     indexType={indexType} optionType={NewOption} getCommentData={getCommentData}  
    //     Name ={IndexName}  
    //     getRWAData={getEngagementRWAData} handleRWAFilter={handleRWAFilter}
    //     rwaIndexType={OptType} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} />
    // )


    return <IndexComponent
      ErrorIndexObj={ErrorIndexObj}
      getEMPFilterLabel={getEMPFilterLabel} getIndexByFilterDMGData={getIndexByFilterDMGData} CountRestrict={CountRestrict}
      GetParameterLabel={GetParameterLabel} loading={loading} handleFilterRWA={handleFilterRWA}
      indexType={indexType} optionType={NewOption} getCommentData={getCommentData}
      Name={IndexName}
      getSurveyData={getSurveyData}
      getIndexData={getIndexData}
      getRWAError={getEngagementRWAError}
      getRWAData={getEngagementRWAData} handleRWAFilter={handleRWAFilter}
      rwaIndexType={OptType} EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
      validateIndex={validateIndex}
    />

  }


  onDimensionType = (val) => {
    let { onRWAnalysis } = this.props;

    onRWAnalysis([], val);
    this.setState({ rwaIndexType: val });

  }

  render() {
    let { rwaIndexType } = this.state;

    let { validateList, indexType, optionType, getCommentData } = this.props;
    // let valueList=[]
    // if(getIndexData){
    //   valueList = Object.keys(getIndexData).map((key) => [ key, getIndexData[key] ]);
    //   //console.log('valueList: ',valueList)
    // }


    let OptType = 0;
    if (rwaIndexType) {
      OptType = rwaIndexType;
    }
    else {
      OptType = validateList && validateList.length > 0 ? validateList[0].template : 0;
    }
    return (
      <>


        {/* 
        <nav className="flex px-6 overflow-x-auto scroll justify-left bg-gray overscroll-x-contain">
          <div className="flex cursor-pointer justify-left">
            <a onClick={()=>this.onDimensionType(1)} className={(rwaIndexType===1)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            Happiness
            </a>
             <a onClick={()=>this.onDimensionType(2)} className={(rwaIndexType===2)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            Engagement 
            </a>
            <a onClick={()=>this.onDimensionType(4)} className={(rwaIndexType===4)?"text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8":"text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8"} href>
            Stress
            </a>
          </div>
        </nav>

        {this.onSwitchOptionScreen()}
 */}



        {this.onSwitchOptionScreen(validateList)}





        {/* <div className="items-center justify-between h-10 px-4 bg-white border-b xl:flex lg:flex md:flex">
        <div className="flex overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 cursor-pointer whitespace-nowrap">

          {validateList && validateList.length>0?
          validateList.map((item)=>
          <span onClick={()=>this.onDimensionType(item.template)} className={(OptType===item.template)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>{item.name}</span>
          )
          :null}

        </div>
      </div>
          {this.onSwitchOptionScreen(validateList)} */}

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
