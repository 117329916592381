// import { dataTool } from "echarts";
import React from "react";
// import Pagination from "material-ui-flat-pagination";

export default function CompleteModal(props) {
  let { errorList, closeCompleteModal, taskFormData } = props;

  ////console.log("errorList----------------------->",errorList)
  return (
   <>
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-xl mx-auto md:relative absolute left-0 right-0 overflow-hidden mt-20">
          
          <div className="  shadow w-full rounded-lg bg-white overflow-hidden block ">
            
          <div className="flex justify-between items-center w-full border-b p-4 px-6">
              <h2 className="font-medium text-xl   text-gray-800 ">{taskFormData && taskFormData.name?taskFormData.name:""}</h2>
           <div onClick={()=>closeCompleteModal("")} className=" rounded-full p-2  bg-gray-100 text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div> </div>

            <div className="w-full p-6 py-4 ">
          
            {/* <p className=" text-base mb-6 text-gray-700 border-b pb-2 text-center"></p> */}

            <div className="space-y-2" style={{height:'300px',overflowY:'auto'}}>



            <label className="font-bold">Task Remark:</label>
            <div className="mb-2 text-xs overflow-y-auto" style={{height:'400px'}}>
              <div className="py-2 px-2 w-full text-lg " >
                {taskFormData && taskFormData.remark?taskFormData.remark:""}
              </div>
            </div>


            </div>



            </div>




          </div>




        </div>
        </div>
   </>
  );
}
