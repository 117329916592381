import { pmsConstants } from '../_constants';
import { pmsService } from '../_services';
import { dashboardActions } from './dashboard.actions';

export const pmsActions = {
    AddEmpPMSGoal,
    pmsGetEmpAppraisalList,
    GetEmpPMSGoalList,
    GetEmpPMSManagerGoalList,
    UpdateEmpPMSGoal,
    AddEmpPMSTask,
    GetEmpPMSTaskList,
    UpdateEmpPMSTask,
    getMyPeersList,
    pmsGetEmpDetails,
    getPeersGoalList,
    getPeersGoalStatus,
    handleGoalReviewInput,
    submitGoalReview,
    getPeersListNew,
    pmsGetPeerDetails,
    getPMSAppraisalStatusByEmpList,
    getPeersGoalStatusV2,
    getAppraisalNameDetais,
    submitGoalReview2,
    getPMSAppraisalListStatus,
    getPMSEmpScoreLabels,
    getEmployeeAppraisalStatus,
    PMSGetAppraisalDetailsById,
    PMSGetAppraisalName,

    getDashboardOverviewStatus,
    // getEmployeeAppraisalStatus,
    getPMSEmployeeWiseScore,
    PMSLaunchAppraisal,
    PMSGetDetailsByAppraisalCycleId,
    PMSUpdateAppraisalCycleById,

    PMSNormlStatusChange,
    GetAndUpdateEmpPMSStage,
    getPMSProcessSetting
};



function getPMSEmployeeWiseScore(data) {
    return dispatch => {
        dispatch(request());
        pmsService.getPMSEmployeeWiseScore(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_PMS_EMPLOYEE_WISE_STATUS_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_PMS_EMPLOYEE_WISE_STATUS_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_PMS_EMPLOYEE_WISE_STATUS_FAILURE, error } }

}


function PMSLaunchAppraisal(data, props) {
    return dispatch => {
        dispatch(request());
        pmsService.PMSLaunchAppraisal(data)
            .then(
                pms => {
                    dispatch(success(pms));
                    props.dispatch(dashboardActions.PMSAppraisalCycleList())
                    if (data && data.id) {
                        props.history.push(`/app/pmsemplist/${data.id}`);
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_PMS_LAUNCH_STATUS_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_PMS_LAUNCH_STATUS_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_PMS_LAUNCH_STATUS_FAILURE, error } }

}
// function getEmployeeAppraisalStatus(data) {
//     return dispatch => {
//         dispatch(request());
//         pmsService.getEmployeeAppraisalStatus(data)
//             .then(
//                 pms => dispatch(success(pms)),
//                 error => {
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: pmsConstants.GET_EMPLOYEE_APPRAISAL_STATUS_REQUEST } }
//     function success(pms) { return { type: pmsConstants.GET_EMPLOYEE_APPRAISAL_STATUS_SUCCESS, pms } }
//     function failure(error) { return { type: pmsConstants.GET_EMPLOYEE_APPRAISAL_STATUS_FAILURE, error } }

// }

function getDashboardOverviewStatus(data) {
    return dispatch => {
        dispatch(request());
        pmsService.getDashboardOverviewStatus(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_OVERVIEW_STATUS_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_OVERVIEW_STATUS_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_OVERVIEW_STATUS_FAILURE, error } }
}


function PMSGetAppraisalDetailsById(data) {


    return dispatch => {
        dispatch(request());
        pmsService.PMSGetAppraisalDetailsById(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.PMSGET_SCORE_NAME_REQUEST } }
    function success(pms) { return { type: pmsConstants.PMSGET_SCORE_NAME_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.PMSGET_SCORE_NAME_FAILURE, error } }

}
function PMSGetAppraisalName(data) {
    //console.log(data, "hgshsggshg");

    return dispatch => {
        dispatch(request());
        pmsService.PMSGetAppraisalName(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.PMSGETNANME_SCORE_NAME_REQUEST } }
    function success(pms) { return { type: pmsConstants.PMSGETNANME_SCORE_NAME_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.PMSGETNANME_SCORE_NAME_FAILURE, error } }

}

function getPMSEmpScoreLabels(data) {

    return dispatch => {
        dispatch(request());
        pmsService.getPMSEmpScoreLabels(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.EMP_SCORE_NAME_REQUEST } }
    function success(pms) { return { type: pmsConstants.EMP_SCORE_NAME_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.EMP_SCORE_NAME_FAILURE, error } }

}


function getEmployeeAppraisalStatus(data) {
    return dispatch => {
        dispatch(request());
        pmsService.getEmployeeAppraisalStatus(data)
            .then(
                pms => {
                    //console.log('ACTION___pmspms??', pms);
                    dispatch(success(pms))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_EMPLOYEE_APPRAISAL_STATUS_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_EMPLOYEE_APPRAISAL_STATUS_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_EMPLOYEE_APPRAISAL_STATUS_FAILURE, error } }
}


function getPMSAppraisalListStatus(data) {
    //console.log('data', data);
    return dispatch => {
        dispatch(request());
        pmsService.getPMSAppraisalListStatus(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_PMSLIST_NAME_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_PMSLIST_NAME_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_PMSLIST_NAME_FAILURE, error } }

}

function getAppraisalNameDetais(data) {
    return dispatch => {
        dispatch(request());
        pmsService.getAppraisalNameDetais(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_APPRAISAL_NAME_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_APPRAISAL_NAME_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_APPRAISAL_NAME_FAILURE, error } }

}

function pmsGetPeerDetails(data) {
    return dispatch => {
        dispatch(request());
        pmsService.pmsGetPeerDetails(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_PEER_DETAILS_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_PEER_DETAILS_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_PEER_DETAILS_FAILURE, error } }

}

function getPeersListNew(data) {
    let obj = {
        getPeersListNew: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        pmsService.getPeersListNew(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_PEERS_NEW_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_PEERS_NEW_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_PEERS_NEW_FAILURE, error } }

}

function submitGoalReview(data, props, useid, emp_id) {
    return dispatch => {
        dispatch(request());
        pmsService.submitGoalReview(data)
            .then(
                pms => {
                    dispatch(success(pms))
                    props.history.push(`/app/pmsemployeeteamgoals/${useid}/${emp_id}`);
                    // props.history.push(`/app/pmsemployeegoals/${useid}`);
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.SUBMIT_REVIEW_REQUEST } }
    function success(pms) { return { type: pmsConstants.SUBMIT_REVIEW_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.SUBMIT_REVIEW_FAILURE, error } }

}

function submitGoalReview2(data, props, useid) {
    return dispatch => {
        dispatch(request());
        pmsService.submitGoalReview2(data)
            .then(
                pms => {
                    dispatch(success(pms));
                    if (data && data.level && data.level === 1) {
                        dispatch(this.GetAndUpdateEmpPMSStage({
                            "appraisalCycleId": data.appraisalCycleId,
                            "reviewee_emp_id": data.emp_id,
                            "currentStage": 2,
                            "isUpdate": true
                        }));
                    } else if (data && data.level && data.level === 2) {
                        dispatch(this.GetAndUpdateEmpPMSStage({
                            "appraisalCycleId": data.appraisalCycleId,
                            "reviewee_emp_id": data.emp_id,
                            "currentStage": 3,
                            "isUpdate": true
                        }));
                    } else if (data && data.level && data.level === 3) {
                        dispatch(this.GetAndUpdateEmpPMSStage({
                            "appraisalCycleId": data.appraisalCycleId,
                            "reviewee_emp_id": data.emp_id,
                            "currentStage": 4,
                            "isUpdate": true
                        }));
                    }

                    props.history.push(`/app/pmsemployeegoals/${useid}`);
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.SUBMIT2_REVIEW_REQUEST } }
    function success(pms) { return { type: pmsConstants.SUBMIT2_REVIEW_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.SUBMIT2_REVIEW_FAILURE, error } }

}
function getPeersGoalList(data) {
    let obj = {
        getPeersGoalList: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        pmsService.getPeersGoalList(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_PEERS_GOAL_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_PEERS_GOAL_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_PEERS_GOAL_FAILURE, error } }

}
function getPeersGoalStatus(data) {
    let obj = {
        getPeersGoalStatus: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        pmsService.getPeersGoalStatus(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_PEERS_GOAL_STATUS_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_PEERS_GOAL_STATUS_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_PEERS_GOAL_STATUS_FAILURE, error } }

}
function getPeersGoalStatusV2(data) {
    let obj = {
        getPeersGoalStatusV2: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        pmsService.getPeersGoalStatus(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_PMS_STATUS_EMP_v2_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_PMS_STATUS_EMP_v2_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_PMS_STATUS_EMP_v2_FAILURE, error } }

}


function pmsGetEmpDetails() {
    let obj = {
        pmsGetEmpDetails: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        pmsService.pmsGetEmpDetails()
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_PMS_EMPDETAILS_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_PMS_EMPDETAILS_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_PMS_EMPDETAILS_FAILURE, error } }

}

function getMyPeersList(data, type, useid) {
    let obj = {
        getMyPeersList: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        pmsService.getMyPeersList(data)
            .then(
                pms => {
                    dispatch(success(pms));
                    if (type === 2) {
                        if (pms && pms.getMyPeersList && pms.getMyPeersList.length > 0) {
                            let emp_list = pms.getMyPeersList.map((ele) => (ele.emp_id));
                            let reqData = {
                                "emp_id": emp_list,
                                "appraisalCycleId": useid,
                                "reviewer_relation": "Self"
                            }
                            dispatch(this.getPMSAppraisalStatusByEmpList(reqData));
                            let reqDatax = {
                                "emp_id": emp_list,
                                "appraisalCycleId": useid,
                                "level": 3
                            }
                            dispatch(this.getPeersGoalStatusV2(reqDatax));
                        }
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_PMS_LIST_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_PMS_LIST_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_PMS_LIST_FAILURE, error } }

}
function GetAndUpdateEmpPMSStage(data,temp,useid, type) {
    return dispatch => {
        dispatch(request());
        pmsService.GetAndUpdateEmpPMSStage(data)
            .then(
                pms => {
                    dispatch(success(pms));

                    if(type==1){
                        let reqDatax = {
                            ...data
                        }
                        reqDatax["isUpdate"] = false;
                        dispatch(this.GetAndUpdateEmpPMSStage(reqDatax));
                    }
                    if(data.currentStage === 5){
                        dispatch(pmsActions.getMyPeersList(temp, (temp.reviewer_relation[0] === 'Manager')?2:0, useid))
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_AND_UPDATE_EMP_PMSSTAGE_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_AND_UPDATE_EMP_PMSSTAGE_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_AND_UPDATE_EMP_PMSSTAGE_FAILURE, error } }

}
function PMSNormlStatusChange(data) {
    return dispatch => {
        dispatch(request());
        pmsService.PMSNormlStatusChange(data)
            .then(
                pms => {
                    dispatch(success(pms))
                    dispatch(pmsActions.PMSGetDetailsByAppraisalCycleId(data))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_PMS_NORMAL_SCHANGE_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_PMS_NORMAL_SCHANGE_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_PMS_NORMAL_SCHANGE_FAILURE, error } }

}

function getPMSAppraisalStatusByEmpList(data, props, taskid) {
    return dispatch => {
        dispatch(request());
        pmsService.getPMSAppraisalStatusByEmpList(data)
            .then(
                pms => {
                    dispatch(success(pms))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GET_PMS_STATUS_EMP_REQUEST } }
    function success(pms) { return { type: pmsConstants.GET_PMS_STATUS_EMP_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GET_PMS_STATUS_EMP_FAILURE, error } }

}
function UpdateEmpPMSTask(data, props, taskid) {
    return dispatch => {
        dispatch(request());
        pmsService.UpdateEmpPMSTask(data)
            .then(
                pms => {
                    dispatch(success(pms))
                    props.dispatch(pmsActions.GetEmpPMSTaskList({ "goalId": taskid }))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.UPDATE_PMS_TASK_REQUEST } }
    function success(pms) { return { type: pmsConstants.UPDATE_PMS_TASK_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.UPDATE_PMS_TASK_FAILURE, error } }

}
function GetEmpPMSTaskList(data) {
    return dispatch => {
        dispatch(request());
        pmsService.GetEmpPMSTaskList(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.GETEMP_PMS_TASK_REQUEST } }
    function success(pms) { return { type: pmsConstants.GETEMP_PMS_TASK_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.GETEMP_PMS_TASK_FAILURE, error } }

}
function AddEmpPMSTask(data, props, taskid) {
    return dispatch => {
        dispatch(request());
        pmsService.AddEmpPMSTask(data)
            .then(
                pms => {
                    dispatch(success(pms))


                    props.dispatch(pmsActions.GetEmpPMSTaskList({ "goalId": taskid }))
                },

                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.ADDEMP_PMSTASK_REQUEST } }
    function success(pms) { return { type: pmsConstants.ADDEMP_PMSTASK_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.ADDEMP_PMSTASK_FAILURE, error } }

}

function UpdateEmpPMSGoal(data, useid, props, type) {
    return dispatch => {
        dispatch(request());
        pmsService.UpdateEmpPMSGoal(data)
            .then(
                pms => {
                    dispatch(success(pms));
                    if (type === 3) {
                        let data1 = {
                            "appraisalCycleId": useid,
                            "emp_id": data.emp_id
                        }
                        props.dispatch(pmsActions.GetEmpPMSManagerGoalList(data1))
                    } else {
                        let data2 = {
                            "appraisalCycleId": useid
                        }
                        props.dispatch(pmsActions.GetEmpPMSGoalList(data2))
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.UPDATE_EMPOMS_GOAL_REQUEST } }
    function success(pms) { return { type: pmsConstants.UPDATE_EMPOMS_GOAL_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.UPDATE_EMPOMS_GOAL_FAILURE, error } }

}
function GetEmpPMSGoalList(data) {
    return dispatch => {
        dispatch(request());
        pmsService.GetEmpPMSGoalList(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.EMP_PMS_GOAL_LIST_REQUEST } }
    function success(pms) { return { type: pmsConstants.EMP_PMS_GOAL_LIST_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.EMP_PMS_GOAL_LIST_FAILURE, error } }

}

function GetEmpPMSManagerGoalList(data) {
    return dispatch => {
        dispatch(request());
        pmsService.GetEmpPMSManagerGoalList(data)
            .then(
                pms => dispatch(success(pms)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.EMP_PMS_GOAL_LIST_REQUEST } }
    function success(pms) { return { type: pmsConstants.EMP_PMS_GOAL_LIST_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.EMP_PMS_GOAL_LIST_FAILURE, error } }

}
function AddEmpPMSGoal(data, props, taskid, type) {
    return dispatch => {
        dispatch(request());
        pmsService.AddEmpPMSGoal(data)
            .then(
                pms => {
                    dispatch(success(pms))
                    if (type === 3) {
                        let data1 = {
                            "appraisalCycleId": taskid,
                            "emp_id": data.emp_id
                        }
                        props.dispatch(pmsActions.GetEmpPMSManagerGoalList(data1))
                    } else {
                        let data2 = {
                            "appraisalCycleId": taskid
                        }
                        props.dispatch(pmsActions.GetEmpPMSGoalList(data2))
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.ADD_PMS_GETEMP_LIST_REQUEST } }
    function success(pms) { return { type: pmsConstants.ADD_PMS_GETEMP_LIST_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.ADD_PMS_GETEMP_LIST_FAILURE, error } }

}

function pmsGetEmpAppraisalList(props) {
    return dispatch => {
        dispatch(request());
        pmsService.pmsGetEmpAppraisalList()
            .then(
                pms => {
                    dispatch(success(pms))


                    let keys = [];
                    // //console.log(keys,"disdsss");
                    if (pms && pms.pmsGetEmpAppraisalList && pms.pmsGetEmpAppraisalList.length > 0) {
                        pms && pms.pmsGetEmpAppraisalList.forEach((item) =>
                            keys.push(item.id)

                        )
                    }

                    let data = { "appraisalCycleList": keys }
                    props.dispatch(pmsActions.getPMSAppraisalListStatus(data));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.PMS_GETEMP_LIST_REQUEST } }
    function success(pms) { return { type: pmsConstants.PMS_GETEMP_LIST_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.PMS_GETEMP_LIST_FAILURE, error } }

}


function handleGoalReviewInput(getPeersGoalList, goalId, value, field, level) {
    if (getPeersGoalList && getPeersGoalList.feedbacks && getPeersGoalList.feedbacks.length > 0) {
        let getIndex = getPeersGoalList.feedbacks.findIndex(prev => prev.goalId === goalId);
        if (getIndex !== -1) {
            getPeersGoalList["feedbacks"][getIndex][field] = value;
            return dispatch => {
                dispatch(success({
                    getPeersGoalList: getPeersGoalList
                }));
            };
        } else {
            let tempData = {
                goalId: goalId,
                level: level
            }
            tempData[field] = value;
            getPeersGoalList["feedbacks"].push(tempData)
            return dispatch => {
                dispatch(success({
                    getPeersGoalList: getPeersGoalList
                }));
            };
        }
    }
    function success(pms) { return { type: pmsConstants.GET_PEERS_GOAL_SUCCESS, pms } }
}


function PMSGetDetailsByAppraisalCycleId(data) {
    return dispatch => {
        dispatch(request());
        pmsService.PMSGetDetailsByAppraisalCycleId(data)
            .then(
                pms => {
                    dispatch(success(pms))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.PMS_GET_DETAILS_BY_APPRAISAL_CYCLE_REQUEST } }
    function success(pms) { return { type: pmsConstants.PMS_GET_DETAILS_BY_APPRAISAL_CYCLE_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.PMS_GET_DETAILS_BY_APPRAISAL_CYCLE_FAILURE, error } }

}

function PMSUpdateAppraisalCycleById(data) {
    return dispatch => {
        dispatch(request());
        pmsService.PMSUpdateAppraisalCycleById(data)
            .then(
                pms => {
                    dispatch(success(pms));
                    dispatch(dashboardActions.PMSAppraisalCycleList())

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.PMS_UPDATE_DETAILS_BY_APPRAISAL_CYCLE_ID_REQUEST } }
    function success(pms) { return { type: pmsConstants.PMS_UPDATE_DETAILS_BY_APPRAISAL_CYCLE_ID_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.PMS_UPDATE_DETAILS_BY_APPRAISAL_CYCLE_ID_FAILURE, error } }

}


function getPMSProcessSetting(data) {
    return dispatch => {
        dispatch(request());
        pmsService.getPMSProcessSetting(data)
            .then(
                pms => {
                    dispatch(success(pms));

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: pmsConstants.PMS_GET_PROCESS_SETTING_REQUEST } }
    function success(pms) { return { type: pmsConstants.PMS_GET_PROCESS_SETTING_SUCCESS, pms } }
    function failure(error) { return { type: pmsConstants.PMS_GET_PROCESS_SETTING_FAILURE, error } }
}



