import React from "react";

export default function SurveyList(props) {
  let {
    savePackage, isPackageSelected, handlePackageSelection,
    selectStage, crrLifecycleStageId, handleViewPackage, handleSuveyPackageSubmit, packageList, handleSurveyLibraryClose, getSurveyFromLibData, } = props;

  // console.log('getSurveyFromLibData:', getSurveyFromLibData);

  // let SurveyList = getSurveyFromLibData;
  let StageList = []
  if (getSurveyFromLibData && getSurveyFromLibData.length > 0) {
    getSurveyFromLibData.forEach((item) => {
      //console.log("item.stageId--->",item.stageId.id)
      let itemStageId = item && item.stageId && item.stageId.id ? item.stageId.id : "";
      let getINdex = StageList.findIndex(x => (x && x.id ? x.id : "") === itemStageId);
      if (getINdex === -1) {
        StageList.push(item.stageId)
      }
    })
  }

  let StageSurveyList = []
  if (StageList && StageList.length > 0) {
    StageList.forEach((item) => {
      let surveyList = getSurveyFromLibData.filter(prev => prev.stageId.id === item.id).filter(prev => prev.SenseTemplate > 0)
      if (!(surveyList && surveyList.length > 0)) {
        StageSurveyList.push({
          ...item,
          "surveyList": getSurveyFromLibData.filter(prev => prev.stageId.id === item.id)
        })
      }
    })
  }

  //console.log("StageSurveyList--->",StageSurveyList)


  return (


    <main className="p-6 bg-gray-50 h-[calc(100vh-4rem)] ">
      <div className="">
        <div className="items-center justify-between pb-8 xl:flex lg:flex md:flex"> 

          <div className="flex items-center justify-between pb-4 xl:pb-0 lg:pb-0 xl:flex-none">
            <span onClick={() => handleSurveyLibraryClose()}
              className="p-2 text-blue-500 bg-blue-100 rounded-full cursor-pointer material-symbols-outlined ">
              arrow_back
            </span>
            <h1 className="ml-4 text-xl cursor-pointer">PLUS Survey Template Library </h1>
            {packageList && packageList.length > 0 ?
              <h1 onClick={() => handleSuveyPackageSubmit(crrLifecycleStageId)} className="px-4 py-1 ml-10 text-white bg-blue-500 cursor-pointer">Continue</h1>
              : null}
          </div>

        </div>


        {StageSurveyList && StageSurveyList.length > 0 ?
          StageSurveyList.map((stage, index1) =>
            <div className="py-2 space-y-6">
              <div className="flex text-[#212121] text-lg font-medium  py-3 border-b">
                <span className="mr-2 tracking-widest uppercase ">{stage.label}</span> 
                {crrLifecycleStageId === stage.id ?
                  <span onClick={() => selectStage(stage)} className="cursor-pointer material-symbols-outlined">task_alt</span>
                  :
                  <span onClick={() => selectStage(stage)} className="cursor-pointer material-symbols-outlined">radio_button_unchecked</span>
                }

              </div>

              <div key={index1} className="grid justify-center grid-cols-2 gap-4 my-2 text-gray-600 lg:grid-cols-4 md:grid-cols-4 ">

                {stage && stage.surveyList && stage.surveyList.length > 0 ?
                  stage.surveyList.map((survey, index2) =>
                    (!(survey && survey.SenseTemplate > 0)) ?

                      <div
                        onClick={() => handleViewPackage(survey, crrLifecycleStageId === stage.id)} key={index2}
                        className="p-6 space-y-6 capitalize transition-all duration-150 bg-white border cursor-pointer rounded-xl group hover:shadow-sm">
                        <div
                          className="flex items-center space-x-4 " >
                          <div className="flex justify-center items-center rounded-full w-16 h-16 bg-[#f5f5f5] "
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-users-minus"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4c.948 0 1.818 .33 2.504 .88" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M16 19h6" /></svg>

                          </div>

                          <h1 className="flex flex-wrap gap-2 text-base font-semibold text-[#3D405B] line-clamp-2">{survey.name}</h1>

                        </div>
                        <div className="space-y-2 ">
                          <p>
                            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document.
                          </p>
                          <button className="uppercase text-[#2196f3] font-medium ">read more</button>
                        </div>
                        <p className="font-medium text-[#212121] uppercase ">66 Questions</p>
                        <div className="flex items-center pt-3 border-t divide-x">
                          <button className="uppercase text-[#2196f3] font-medium w-full ">preview</button>
                          <button className="uppercase text-[#2196f3] font-medium w-full ">Use this template</button>
                        </div>

                        {/* <div className="p-2 space-y-2 ">
                            <div>

                              <div className="flex" onClick={() => handlePackageSelection(isPackageSelected, savePackage)}>

                                {packageList.some(prev => prev === survey.id) ?
                                  <span
                                    style={{
                                      color: packageList.some(prev => prev === survey.id) ? '#0066ff' : '#000'
                                    }}
                                    class="material-symbols-outlined m-2">
                                    check_box
                                  </span>
                                  :
                                  <span
                                    style={{
                                      color: packageList.some(prev => prev === survey.id) ? '#0066ff' : '#000'
                                    }}
                                    class="material-symbols-outlined m-2">
                                    check_box_outline_blank
                                  </span>}



                              </div>


                            </div> 

                          </div> */}
                      </div>





                      : null
                  )
                  : null}


              </div>
            </div>
          )
          : null}




      </div></main>

  );
}
