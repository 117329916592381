import React from "react";
export default function UpdateSurvey(props) {
  let { handleCreateSurveyPopupClose, updateformSurveyData, updatehandleSurveyRole, handleUpdateSurveySubmit } = props;
  ////console.log("updateformSurveyData; ",updateformSurveyData)
  return (
    <>
      <div className="bg-black bg-opacity-40 fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full flex items-center justify-center md:p-0 p-4" >
        <div className="xl:w-4/12 lg:w-4/6 w-5/6  mx-auto bg-white rounded-md mb-20">
          <div className="flex items-center justify-between p-4 border-b"><h1 className="text-lg font-semibold text-center text-gray-800 ">Update Access</h1>
            <span onClick={() => handleCreateSurveyPopupClose()} className="
 material-symbols-outlined bg-gray-50 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150 ">close</span></div>

      
          <div className="p-6 space-y-4">
            <p className="text-sm font-medium text-gray-600" >Select Survey Access:</p>
            <div className="grid grid-cols-2 ">
              <label className="flex py-2 cursor-pointer items-center w-full hover:bg-blue-100 rounded-md p-4"><div>
                <input onClick={() => updatehandleSurveyRole("ADMIN")} checked={updateformSurveyData.roleTemp === "ADMIN" ? true : false}
                  className="pt-4 mr-2" type="radio" /></div><div className={updateformSurveyData.roleTemp === "ADMIN" ? "font-medium text-lg " : "text-lg"}>ADMIN</div></label>


              <label className="flex py-2 cursor-pointer items-center w-full hover:bg-blue-100 rounded-md p-4"><div><input onClick={() => updatehandleSurveyRole("CROLE")} checked={updateformSurveyData.roleTemp === "CROLE" ? true : false}
                className="pt-4 mr-2" type="radio" /></div><div className={updateformSurveyData.roleTemp === "CROLE" ? "font-medium text-lg " : "text-lg"}>CROLE</div></label>
            </div>
          </div>


          <div className="p-6 flex justify-end text-center space-x-4">
            <button onClick={() => handleUpdateSurveySubmit()} type="button" className="border   text-gray-600 font-medium py-2 px-6  rounded-md " >
              Cancel
            </button>
            <button onClick={() => handleUpdateSurveySubmit()} type="button" className="bg-blue-500 hover:bg-blue-500 text-white font-medium py-2 px-6  rounded-md " >
              {"Update"}
            </button>
          </div>
        </div>

      </div>
    </>
  );
}
