import React from "react";
import moment from "moment";
import AssessmentBottom5 from "./AssessmentBottom5";

export default function AssessmentDetails(props) {
  let {
    getAssessmentReportByEmpIDData,
    crrSelectedEmp,
    EditDetailsFn,
    EditButtonFn,
    createdAt
  } = props;

  function getModifiedContent(text) {
    let text2 = "";
    if (text) {
      text2 = text
        .replace(/<\/?li[^>]*>/g, "@")
        .replace(/<\/?ul[^>]*>/g, "")
        .replace(/<\/?span[^>]*>/g, "")
        .replace(/<\/?p[^>]*>/g, "")
        .replace(/&nbsp;/g, "")
        .replace(/<\/?br[^>]*>/g, "")
        .replace(/<\/?b[^>]*>/g, "");
    }
    return text2 ? text2 : text;
  }

  function getBulletPoints(element, field) {
    let descX =
      element && element.details && element.details[field]
        ? element.details[field]
        : "";
    let descNew = "";
    if (descX) {
      let temp = getModifiedContent(descX);
      //console.log("descX",descX)
      //console.log("temp",temp)
      descNew = temp.split("@").filter((prev) => prev !== "");
      // //console.log("descNew",descNew)
    }
    return descNew;
  }

  function getTitle(element, type) {
    let itemName =
      type === 1
        ? "How to use - talent every day?"
        : "How to deal with a person who has - talent?";
    let final = itemName.replace("-", element.dimension.trim().toLowerCase());
    //console.log("final--->", final.toString());
    return final.toString();
  }

  const cirleColor = (element) => {
    return (
      <span
        style={{
          background:
            element && element.colorCode ? element.colorCode : "#DDDDDD",
        }}
        className={"w-5 h-5 rounded-full "}
      />
    );
  };

  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }


  function chunkList15(array) {
    const chunkSize = 15;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    //console.log("list--->", list);
    return list;
  }


  function chunkList(array) {
    const chunkSize = 10;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    //console.log("list--->", list);
    return list;
  }

  function BoldText(text) {
    return text ? '<b>' + text + '</b>' : text
  }

  function checkForGeneralship(bucket, dimension) {
    let text = "";
    if (bucket && dimension) {
      if (dimension.toLowerCase() === "generalship" && bucket.toLowerCase() === "thinking talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "enhancer" && bucket.toLowerCase() === "relating talents") {
        text = "old";
      } else if (dimension.toLowerCase() === "coach" && bucket.toLowerCase() === "leading talents") {
        text = "old";
      }
    }
    return text
  }

  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }



  return (
    <>
      {getAssessmentReportByEmpIDData &&
        getAssessmentReportByEmpIDData.length > 0
        ? getAssessmentReportByEmpIDData.map((element, index1) => (
          <>
            
              
            {(index1 + 1) === getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 ?
              <AssessmentBottom5
                crrSelectedEmp={crrSelectedEmp}
                EditButtonFn={EditButtonFn}
                createdAt={createdAt}
              />
              : null}




{/* 
            {(!(getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1 <= index1 + 1 && index1 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2)) || false ? (

 */}


             {(index1 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1) || true ? (


              <>
                <main className="relative h-full p-10 px-6 space-y-6 bg-white bg-no-repeat page-break">
                  <div className="space-y-6 page-break ">

                    <div className="px-12 space-y-6">
                      <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
                        <h1 className="text-lg font-semibold">
                          {EditButtonFn(
                            "TalentAssessmentReport",
                            "TalentPlusStrengths Assessment"
                          )}
                        </h1>
                        <p className="text-lg font-semibold">
                          {crrSelectedEmp && crrSelectedEmp.name
                            ? crrSelectedEmp.name
                            : ""} | {createdAt}
                        </p>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="flex p-2 space-x-2 border rounded-full">
                          {getAssessmentReportByEmpIDData &&
                            getAssessmentReportByEmpIDData.length > 0
                            ? getAssessmentReportByEmpIDData.map(
                              (element2, index2) =>
                                index2 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1 ? (
                                  index1 === index2 ? (
                                    cirleColor(element2)
                                  ) : (
                                    <span className="w-5 h-5 rounded-full bg-[#DDDDDD]" />
                                  )
                                ) : null
                            )
                            : null}
                        </div>

                        <div className="flex p-2 space-x-2 border rounded-full">
                          {getAssessmentReportByEmpIDData &&
                            getAssessmentReportByEmpIDData.length > 0
                            ? getAssessmentReportByEmpIDData.map(
                              (element2, index2) =>
                                getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1 <= index2 + 1 && index2 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2 ? (
                                  index1 === index2 ? (
                                    cirleColor(element2)
                                  ) : (
                                    <span className="w-5 h-5 rounded-full bg-[#DDDDDD]" />
                                  )
                                ) : null
                            )
                            : null}
                        </div>

                        <div className="flex p-2 space-x-2 border rounded-full">
                          {getAssessmentReportByEmpIDData &&
                            getAssessmentReportByEmpIDData.length > 0
                            ? getAssessmentReportByEmpIDData.map(
                              (element2, index2) =>
                                getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 <= index2 + 1 ? (
                                  index1 === index2 ? (
                                    cirleColor(element2)
                                  ) : (
                                    <span className="w-5 h-5 rounded-full bg-[#DDDDDD]" />
                                  )
                                ) : null
                            )
                            : null}
                        </div>
                      </div>

                      <div
                        style={{
                          background: hexToRgbA(
                            element && element.colorCode
                              ? element.colorCode
                              : "#DDDDDD",
                            "0.20"
                          ),
                        }}
                        className={
                          "rounded-2xl p-6 py-4 flex justify-between  items-center  "
                        }
                      >
                        <div className="space-y-2">
                          <button
                            style={{
                              background:
                                element && element.colorCode
                                  ? element.colorCode
                                  : "#DDDDDD",
                            }}
                            className={
                              " px-6 py-2 text-base font-bold flex text-white uppercase tracking-wide rounded-full "
                            }
                          >
                            {EditDetailsFn(element, "bucket", element.bucket)}
                          </button>
                          <h1 className="text-4xl font-light text-[#212121] pl-4 ">
                            {(index1 + 1 > getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2
                              ? (index1 + 1 - getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2).toString()
                              : (index1 + 1).toString()) + ". "}

                            {EditDetailsFn(
                              element,
                              "dimension",
                              element.dimension
                            )}
                          </h1>
                        </div>

                        <div className="rounded-full" style={{
                          background: hexToRgbA(
                            element && element.colorCode
                              ? element.colorCode
                              : "#DDDDDD",
                            "0.20"
                          ),
                        }} >
                          <img
                            src={
                              "/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension)) +
                              element.dimension.toLowerCase() +
                              ".svg"
                            }
                            className="w-28 h-28"
                          />
                        </div>
                      </div>

                    </div>

                    <div className="w-full space-y-6 pl-14">
                      {/* <h1 className="text-xl font-medium text-[#212121]">
                        <b>  {EditButtonFn("SELFSNAPSHOT", "SELF SNAPSHOT")}</b>
                      </h1> */}

                      <h1 style={{ fontWeight: 'bold', color: '#212121' }} className="text-xl">
                        {EditButtonFn("SELFSNAPSHOT", "SELF SNAPSHOT")}
                      </h1>

                      <div className="w-full space-y-4">
                        <p className="text-base italic font-light">
                          {EditDetailsFn(
                            element,
                            "desc1",
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  BoldText(element &&
                                    element.details &&
                                    element.details.desc1
                                    ? element.details.desc1
                                    : ""),
                              }}
                            />
                          )}
                        </p>
                        {/* <p className="text-base">
                          <div>
                            {EditDetailsFn(
                              element,
                              "desc2",
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    element &&
                                      element.details &&
                                      element.details.desc2
                                      ? element.details.desc2
                                      : "",
                                }}
                              />
                            )}
                          </div>
                        </p> */}
                      </div>
                    </div>



                    <div className="w-full pl-2 space-y-4">

                      {getBulletPoints(element, "bulletpoints") &&
                        getBulletPoints(element, "bulletpoints").length > 0
                        ? chunkList15(getBulletPoints(element, "bulletpoints")).map(
                          (textList) => (
                            <>
                              <div className="px-12">
                                {element && element.details && element.details.bulletpoints ? (
                                  <>
                                    <div className="w-full pb-2 space-y-1 page-break">
                                      <h1 style={{ fontWeight: 'bold', color: '#212121' }} className="text-xl">
                                        {EditButtonFn("KNOWYOURSELF", "KNOW YOURSELF")}
                                      </h1>
                                    </div>
                                    {textList && textList.length > 0
                                      ? textList.map((text, index6) => (
                                        <>
                                          <div class="w-full justify-start items-start gap-1 inline-flex">
                                            <div class="grow shrink basis-0 mb-2 self-stretch px-3 py-[8.50px] justify-start items-center gap-2 flex italic" style={{ borderLeftWidth: '5px', borderLeftColor: element && element.colorCode ? element.colorCode : "#DDDDDD", background: hexToRgbA(element && element.colorCode ? element.colorCode : "#DDDDDD", "0.08"), }}>
                                              <div class="grow shrink basis-0"> {EditDetailsFn(element, "bulletpoints" + (index6 + 1), text)}</div>
                                            </div>
                                          </div>
                                        </>
                                      ))
                                      : null}
                                  </>
                                ) : null}

                                <img
                                  src={
                                    `/img/assessment/idea/lightwatermark/` + (checkForGeneralship(element.bucket, element.dimension)) +
                                    element.dimension.toLowerCase() +
                                    `_watermark.svg`
                                  }
                                  className="w-[300px] h-auto absolute bottom-0 right-0"
                                />

                              </div>
                            </>
                          )
                        )
                        : null}
                    </div>

                    <img
                      src={
                        `/img/assessment/idea/lightwatermark/` + (checkForGeneralship(element.bucket, element.dimension)) +
                        element.dimension.toLowerCase() +
                        `_watermark.svg`
                      }
                      className="w-[300px] h-auto absolute bottom-0 right-0"
                    />
                  </div></main>

                {getBulletPoints(element, "desc3") &&
                  getBulletPoints(element, "desc3").length > 0
                  ? chunkList(getBulletPoints(element, "desc3")).map(
                    (textList) => (
                      <>
                        <main className="relative p-10 px-6 space-y-6 bg-white page-break ">
                          <div className="px-12">
                            {element && element.details && element.details.desc3 ? (
                              <>
                                <div className="w-full pb-4 space-y-1 page-break">
                                  <h1 className="text-3xl font-light text-[#212121]">
                                    <b>  {EditButtonFn(getTitle(element, 1), getTitle(element, 1))}</b>
                                  </h1>
                                </div>
                                <div className="text-sm">
                                  {textList && textList.length > 0
                                    ? textList.map((text, index6) => (
                                      <>
                                        <div class="w-full justify-start items-start gap-1 inline-flex">
                                          <div class="self-stretch px-3 py-2 flex-col justify-center items-start gap-2.5 inline-flex mb-2" style={{ background: hexToRgbA(element && element.colorCode ? element.colorCode : "#DDDDDD", "0.14"), }}>
                                            <div class="relative">
                                              {/* <div class="w-6 h-6 left-0 top-0 absolute"></div> */}
                                              <img src={"/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension)) + element.dimension.toLowerCase() + ".svg"} className="w-20 h-20" />
                                              {/* <img class="w-[16.37px] h-6 left-[3.75px] top-0 absolute" src="https://via.placeholder.com/16x24" /> */}
                                            </div>
                                          </div>
                                          <div class="grow shrink basis-0 mb-2 self-stretch px-3 py-[8.50px] justify-start items-center gap-2 flex" style={{ background: hexToRgbA(element && element.colorCode ? element.colorCode : "#DDDDDD", "0.08"), }}>
                                            <div class="grow shrink basis-0"> {EditDetailsFn(element, "desc3" + (index6 + 1), text)}</div>
                                          </div>
                                        </div>
                                      </>
                                    ))
                                    : null}
                                </div>
                              </>
                            ) : null}

                            {/* <p>Copyright © 2024, HappyPlus, Inc. All rights reserved</p> */}
                            <img
                              src={
                                `/img/assessment/idea/lightwatermark/` + (checkForGeneralship(element.bucket, element.dimension)) +
                                element.dimension.toLowerCase() +
                                `_watermark.svg`
                              }
                              className="w-[300px] h-auto absolute bottom-0 right-0"
                            />

                          </div>
                        </main>
                      </>
                    )
                  )
                  : null}

                <main
                  className="relative p-10 px-6 space-y-6 bg-white page-break "
                >
                  {element && element.details && element.details.desc4 ? (
                    <>
                      <div className="px-12">

                        <div className={element.dimension === "Coach" ? "w-full space-y-1 " : "w-full space-y-1 page-break"}>
                          <h1 className="text-3xl font-light text-[#212121]">
                            {/* <b>What needs to be looked out for?</b> */}
                            <b>
                              {EditButtonFn(
                                "What needs to be looked out for_Possible Blind Spots?",
                                "Look out for Blind Spots"
                                // "What needs to be looked out for (Possible Blind Spots)?"
                              )}
                            </b>
                          </h1>
                        </div>

                        <div className="grid grid-cols-1 gap-2 py-2 pt-4 text-sm">
                          {getBulletPoints(element, "desc4") &&
                            getBulletPoints(element, "desc4").length > 0
                            ? getBulletPoints(element, "desc4").map(
                              (text, index) =>
                                <div class="w-full justify-start items-start gap-1 inline-flex">
                                  <div class="self-stretch px-3 py-2 flex-col justify-center items-start inline-flex" style={{ background: hexToRgbA(element && element.colorCode ? element.colorCode : "#DDDDDD", "0.14"), }}>
                                    <div class="relative">
                                      <img src={
                                        `/img/assessment/idea/blindspot/` + (checkForGeneralship(element.bucket, element.dimension)) +
                                        element.bucket.toLowerCase() +
                                        `.svg`
                                      } className="w-20 h-20" />
                                    </div>
                                  </div>
                                  <div class="grow shrink basis-0 self-stretch px-3 py-[8.50px] justify-start items-center flex" style={{ background: hexToRgbA(element && element.colorCode ? element.colorCode : "#DDDDDD", "0.08"), }}>
                                    <div class="grow shrink basis-0">{EditDetailsFn(element, "desc4" + (index + 1), text)}</div>
                                  </div>
                                </div>
                            )
                            : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* <p>Copyright © 2024, HappyPlus, Inc. All rights reserved</p> */}
                  <img
                    src={
                      `/img/assessment/idea/blindspot/` + (checkForGeneralship(element.bucket, element.dimension)) +
                      element.bucket.toLowerCase() +
                      `_watermark.svg`
                    }
                    className="w-[300px] h-auto absolute bottom-0 right-0"
                  />
                </main>

                {getBulletPoints(element, "desc5") &&
                  getBulletPoints(element, "desc5").length > 0
                  ? chunkList(getBulletPoints(element, "desc5")).map(
                    (textList, index5) => (
                      <>
                        <main
                          className="relative h-full p-10 px-6 space-y-6 bg-white"
                        >
                          <div className="px-12">

                            {element &&
                              element.details &&
                              element.details.desc5 ? (
                              <>
                                <div className="w-full space-y-1 ">
                                  <h1 className="text-3xl font-light text-[#212121] pb-4">
                                    {/* <b>{getTitle(element, 2)}</b> */}
                                    <b>
                                      {EditButtonFn(
                                        getTitle(element, 2),
                                        getTitle(element, 2)
                                      )}
                                    </b>
                                  </h1>
                                </div>

                                <div className="text-sm">
                                  {textList && textList.length > 0
                                    ? textList.map((text, index6) => (
                                      <>
                                        <div class="w-full justify-start items-start gap-1 inline-flex">
                                          <div class="self-stretch px-3 py-2 flex-col justify-center items-start gap-2.5 inline-flex mb-2" style={{ background: hexToRgbA(element && element.colorCode ? element.colorCode : "#DDDDDD", "0.14"), }}>
                                            <div class="relative">
                                              <img src={"/img/assessment/idea/howto/" + element.bucket.toLowerCase() + ".svg"} className="w-20 h-20" />
                                            </div>
                                          </div>
                                          <div class="grow shrink basis-0 mb-2 self-stretch px-3 py-[8.50px] justify-start items-center gap-2 flex" style={{ background: hexToRgbA(element && element.colorCode ? element.colorCode : "#DDDDDD", "0.08"), }}>
                                            <div class="grow shrink basis-0"> {EditDetailsFn(element, "desc3" + (index6 + 1), text)}</div>
                                          </div>
                                        </div>
                                      </>
                                    ))
                                    : null}
                                  {/* </>
                                    ))
                                    : null} */}
                                </div>
                              </>
                            ) : null}
                            {/* <p>Copyright © 2024, HappyPlus, Inc. All rights reserved</p> */}
                            <img
                              src={
                                `/img/assessment/idea/howto/` + (checkForGeneralship(element.bucket, element.dimension)) +
                                element.bucket.toLowerCase() +
                                `_watermark.svg`
                              }
                              className="w-[300px] h-auto absolute bottom-0 right-0"
                            />
                          </div>
                        </main>
                      </>
                    )
                  )
                  : null}
              </>
            ) : null}
          </>
        ))
        : null}
    </>
  );
}
