
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const pmsService = {

    logout,
    pmsGetEmpAppraisalList,
    AddEmpPMSGoal,
    GetEmpPMSGoalList,
    GetEmpPMSManagerGoalList,
    UpdateEmpPMSGoal,
    AddEmpPMSTask,
    GetEmpPMSTaskList,
    UpdateEmpPMSTask,
    getMyPeersList,
    pmsGetEmpDetails,
    getPeersGoalList,
    getPeersGoalStatus,
    submitGoalReview,
    getPeersListNew,
    pmsGetPeerDetails,
    getPMSAppraisalStatusByEmpList,
    getAppraisalNameDetais,
    submitGoalReview2,
    getPMSAppraisalListStatus,
    getPMSEmpScoreLabels,
    getEmployeeAppraisalStatus

    ,
    getAppraisalNameDetais,
    submitGoalReview2,
    getPMSAppraisalListStatus,
    getPMSEmpScoreLabels,
    PMSGetAppraisalDetailsById,
    PMSGetAppraisalName,
    getAppraisalNameDetais,
    submitGoalReview2,
    getPMSAppraisalListStatus,
    getPMSEmpScoreLabels,
    PMSGetAppraisalDetailsById,


    getDashboardOverviewStatus,
    getPMSEmployeeWiseScore,
    PMSLaunchAppraisal,
    PMSGetDetailsByAppraisalCycleId,
    PMSUpdateAppraisalCycleById,

    PMSNormlStatusChange,
    GetAndUpdateEmpPMSStage,
    getPMSProcessSetting


};








async function PMSGetAppraisalName(value) {
    //console.log("PMSGetAppraisalName", value);
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/PMSGetAppraisalDetailsById`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                PMSGetAppraisalName: data.data
            }
            return ProfileObj;
        });
}



async function getPMSEmployeeWiseScore(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getDashboardOverviewStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getPMSEWScore: data.data
            }
            return ProfileObj;
        });
}


async function getDashboardOverviewStatus(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getDashboardOverviewStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDashboardOverviewStatusData: data.data
            }
            // //console.log('Service_______________getDashboardOverviewStatusData', ProfileObj);
            return ProfileObj;
        });
}


async function PMSGetAppraisalDetailsById(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/PMSGetAppraisalDetailsById`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                PMSGetAppraisalDetailsById: data.data
            }
            return ProfileObj;
        });
}
async function getPMSEmpScoreLabels(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getPMSEmpScoreLabels`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getPMSEmpScoreLabels: data.data
            }
            return ProfileObj;
        });
}
async function getPMSAppraisalListStatus(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getPMSAppraisalListStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getPMSAppraisalListStatus: data.data
            }
            return ProfileObj;
        });
}
async function getAppraisalNameDetais(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getAppraisalNameDetais`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getAppraisalNameDetais: data.data
            }
            return ProfileObj;
        });
}

async function pmsGetPeerDetails(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/pmsGetPeerDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                pmsGetPeerDetails: data.data
            }
            return ProfileObj;
        });
}
async function submitGoalReview(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/submitGoalReview`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                submitGoalReview: data.data
            }
            return ProfileObj;
        });
}
async function submitGoalReview2(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/submitGoalReview`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                submitGoalReview2: data.data
            }
            return ProfileObj;
        });
}
async function getPeersListNew(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getPeersListNew`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getPeersListNew: data.data
            }
            return ProfileObj;
        });
}
async function getPeersGoalList(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getPeersGoalList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let obj = data.data;
            let feedbackList = [];


            if (obj && obj.goalList && obj.goalList.length > 0) {
                obj.goalList.map((ele) => {
                    let index = -1;
                    if (obj && obj.feedbacks && obj.feedbacks.length > 0) {
                        index = obj.feedbacks.findIndex(prev => prev.goalId === ele.id)
                    }
                    //console.log('index-obj-->', index)

                    if (index === -1) {
                        feedbackList.push({
                            rating: "",
                            comment: "",
                            level: value.level,
                            goalId: ele.id
                        })
                    } else {
                        let item = obj.feedbacks[index];
                        feedbackList.push({
                            rating: item && item.rating ? item.rating : "",
                            comment: item && item.comment ? item.comment : "",
                            level: item && item.level ? item.level : value.level,
                            goalId: ele.id
                        })
                    }
                })
            }




            let selffeedbackList = [];

            //console.log('value-obj-->', value)

            if (obj && obj.goalList && obj.goalList.length > 0) {
                obj.goalList.map((ele) => {
                    let index = -1;
                    if (obj && obj.selfFeedbacks && obj.selfFeedbacks.length > 0) {
                        index = obj.selfFeedbacks.findIndex(prev => prev.goalId === ele.id)
                    }
                    //console.log('index-obj-->', index)

                    if (index === -1) {
                        selffeedbackList.push({
                            rating: "",
                            comment: "",
                            goalId: ele.id
                        })
                    } else {
                        let item = obj.selfFeedbacks[index];
                        selffeedbackList.push({
                            rating: item && item.rating ? item.rating : "",
                            comment: item && item.comment ? item.comment : "",
                            goalId: ele.id
                        })
                    }
                })
            }



            let ProfileObj = {
                getPeersGoalList: {
                    "goalList": obj.goalList,
                    "relation": obj.relation,
                    "feedbacks": feedbackList,
                    "selfFeedbacks": selffeedbackList,
                    "settings": obj.settings
                }
            }

            //console.log('ProfileObj-ProfileObj-->', ProfileObj)
            return ProfileObj;
        });
}
async function getPeersGoalStatus(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getPeersGoalStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getPeersGoalStatus: data.data
            }
            return ProfileObj;
        });
}
async function pmsGetEmpDetails(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify({ "emp_id": "1" })

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/pmsGetEmpDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                pmsGetEmpDetails: data.data
            }
            return ProfileObj;
        });
}

async function getMyPeersList(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getMyPeersList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getMyPeersList: data.data
            }
            return ProfileObj;
        });
}

async function UpdateEmpPMSTask(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/UpdateEmpPMSTask`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                UpdateEmpPMSTask: data.data
            }
            return ProfileObj;
        });
}
async function GetEmpPMSTaskList(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/GetEmpPMSTaskList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                GetEmpPMSTaskList: data.data
            }
            return ProfileObj;
        });
}
async function AddEmpPMSTask(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/AddEmpPMSTask`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                AddEmpPMSTask: data.data
            }
            return ProfileObj;
        });
}
async function UpdateEmpPMSGoal(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/UpdateEmpPMSGoal`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                UpdateEmpPMSGoal: data.data
            }
            return ProfileObj;
        });
}
async function GetEmpPMSGoalList(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/GetEmpPMSGoalList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                GetEmpPMSGoalList: data.data
            }
            return ProfileObj;
        });
}
async function GetEmpPMSManagerGoalList(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/GetEmpPMSManagerGoalList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                GetEmpPMSGoalList: data.data
            }
            return ProfileObj;
        });
}
async function AddEmpPMSGoal(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/AddEmpPMSGoal`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                AddEmpPMSGoal: data.data
            }
            return ProfileObj;
        });
}
function logoutSession(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/userlogout`, requestOptions)
        .then(handleResponse2)
        .then(user => {
            let userObj = {
                userLogout: user
            }
            localStorage.removeItem('adminuser');
            window.location.href = "#/pmslogin";
            return userObj;
        });
}
function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/pmslogin";
}
function handleResponse2(response) {
    // ////////console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            // ////////console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //////////console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            // ////////console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}


function handleResponse(response) {
    // ////////console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logoutSession();
                //location.reload(true);
            }
            // ////////console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //////////console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            // ////////console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                //  logoutSession();
            }
            if (data && data.message && (data.message === "UnauthorizedError")) {
                logout()

            }
            //              else if (data.code===1) {
            //                 logoutSession();
            //             }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}


async function pmsGetEmpAppraisalList(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/pmsGetEmpAppraisalList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let ProfileObj = {
                pmsGetEmpAppraisalList: data.data
            }
            return ProfileObj;
        });
}

async function GetAndUpdateEmpPMSStage(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/GetAndUpdateEmpPMSStage`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let ProfileObj = {
                getAndUpdateEmpPMSStageData: data.data
            }
            return ProfileObj;
        });
}

async function PMSNormlStatusChange(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/PMSNormlStatusChange`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let ProfileObj = {
                PMSNormlStatusChange: data.data
            }
            return ProfileObj;
        });
}


async function getPMSAppraisalStatusByEmpList(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getPMSAppraisalStatusByEmpList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let ProfileObj = {
                getPMSAppraisalStatusByEmpList: data.data
            }
            return ProfileObj;
        });
}


async function PMSLaunchAppraisal(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/PMSLaunchAppraisal`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let ProfileObj = {
                PMSLaunchAppraisal: data.data
            }
            return ProfileObj;
        });
}





async function getEmployeeAppraisalStatus(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getDashboardOverviewStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let ProfileObj = {
                getEmployeeAppraisalStatus: data.data
            }
            //console.log('ProfileObjProfileObjProfileObjProfileObjProfileObjProfileObj______service', ProfileObj);
            return ProfileObj;
        });
}





async function PMSGetDetailsByAppraisalCycleId(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/PMSGetDetailsByAppraisalCycleId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let ProfileObj = {
                PMSGetDetailsByAppraisalCycleId: data.data
            }
            return ProfileObj;
        });
}





async function PMSUpdateAppraisalCycleById(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/PMSUpdateAppraisalCycleById`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let ProfileObj = {
                PMSUpdateAppraisalCycleById: data.data
            }
            return ProfileObj;
        });
}


async function getPMSProcessSetting(value) {

    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }

    return fetch((backendUrl ? backendUrl : CONST.BACKEND_URL_PMS) + `/getPMSProcessSetting`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let ProfileObj = {
                getPMSProcessSetting: data.data
            }
            return ProfileObj;
        });
}



