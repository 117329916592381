import React from "react";
export default function ConfirmMail(props) {
    let { showModal, closeResolveModal, crrEmployeeData, onSubmitResolve, handleResolvedText, resolvedText } = props;



    return (



        <>
            {showModal ?
                <div className="top-0 xl:h-screen h-full  w-full flex justify-center items-center z-40 bg-black fixed bg-opacity-40"  >
                    <div className="bg-white rounded-lg  shadow-sm  2xl:w-3/12 xl:w-4/12   lg:w-5/12 md:w-6/12 w-11/12">
                        <div className="flex justify-between items-center px-6 p-4 border-b"> <h2 className="text-base font-semibold text-center test-gray-800 capitalize">Alert</h2>
                            <span onClick={closeResolveModal} className="cursor-pointer material-symbols-outlined   font-medium text-gray-500 p-1 bg-gray-100 rounded-full hover:bg-sky-100 transtion duration-150">close</span></div>
                        <div className="p-4 space-y-4">

                         <p className="text-center text-lg text-gray-800 font-medium" > Are you sure you want to move this  user to resolved section ?</p>
                            <div className="flex justify-center" >
                                <div className="border flex  items-center space-x-4 p-2 rounded-md">
                                    <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" className="h-8 w-8 rounded-full object-cover" />
                                    <h1>{crrEmployeeData && crrEmployeeData.name?crrEmployeeData.name:""}</h1>
                                </div>
                            </div>

                        </div>

                        <div className='p-2 m-2'>
                        <textarea 
                            rows={4}
                            type="text" 
                            value={resolvedText} 
                            onChange={(e)=>handleResolvedText(e.target.value)}
                            placeholder="Comment Here..."
                            className='w-full border rounded-lg p-1'
                        ></textarea>

                        </div>
                      <div className="p-6" >
                      <button onClick={()=>onSubmitResolve()} className='bg-blue-500 text-white px-4 py-2 uppercase rounded-md flex items-center cursor-pointer justify-center font-medium  w-full' >yes move to resolved 
                        <span className="material-symbols-outlined ml-2">
                            moving
                          </span></button>
                      </div>

                    </div>
                </div>
                : null}
        </>

    );
}
