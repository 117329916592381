import React, { Component } from 'react';
import { connect } from 'react-redux';


class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',

      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      crrSelectedEmp: {}
    }
  }

  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { get360Overview } = this.props;
    this.setState({ FilterValues: finalFilter });
    get360Overview(finalFilter, "", "", false);

  }

  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { get360Overview } = this.props;

      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = value.emp_id;
      }
      let { FilterValues } = this.state;
      get360Overview(FilterValues, "", "", false, emp_id)

    })
  }


  render() {


    let { getFeedbackAndQuestionNameData, getTextAndQuestionNameData } = this.props;

    let questionList = []

    if (getTextAndQuestionNameData && getTextAndQuestionNameData.length > 0) {
      getTextAndQuestionNameData.forEach((element) => {
        let temp = {
          "name": element && element["_id"] && element["_id"]["questionName"] ? element["_id"]["questionName"] : "",
          "textList": element && element["answer"] && element["answer"].length > 0 ? element["answer"].map((ele) => (ele.answerText ? ele.answerText : "")) : []
        }
        questionList.push(temp)
      })
    }


    if (getFeedbackAndQuestionNameData && getFeedbackAndQuestionNameData.length > 0) {
      getFeedbackAndQuestionNameData.forEach((element) => {
        let temp = {
          "name": element && element["_id"] && element["_id"]["questionName"] ? element["_id"]["questionName"] : "",
          "textList": element && element["feedback"] && element["feedback"].length > 0 ? element["feedback"].map((ele) => (ele.feedback ? ele.feedback : "")) : []
        }
        questionList.push(temp)
      })
    }


    function getRandomColor() {
      let colorList = ["#E57373", "#F06292", "#BA68C8", "#BA68C8", "#9575CD", "#7986CB", "#64B5F6", "#4FC3F7", "#4DD0E1", "#4DB6AC", "#81C784", "#DCE775", "#FFB74D", "#FF8A65", "#90A4AE", "#757575"]
      var color = colorList[Math.floor(Math.random() * colorList.length)];
      return color
    }


    // console.log("questionList--->",questionList)
    var size = 7;

    let newQList = []
    if (questionList && questionList.length > 0) {
      questionList.forEach((item, index) => {

        var arList = item && item.textList && item.textList ? item.textList : [];
        let yList = arList.map((ele)=>({"text":ele,"color":getRandomColor()}))
        while (yList.length > 0) {
          newQList.push({
            "sno": (index + 1),
            "name": item.name,
            "textList": yList.splice(0, size)

          })
        }
      })
    }


    // console.log("newQList--->",newQList)
    let { userName, createdAt } = this.props;




    return (
      <>

        {newQList && newQList.length > 0 ?
          newQList.map((item, index) =>
            <section className='p-6 px-6 flex flex-col justify-between page-break'>
              {/* report header */}
              <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                <p className='font-medium italic text-base '>360 Feedback</p>
                <p className='font-medium italic text-base'>{userName} | {createdAt}</p>
              </div>
              <div className='space-y-4 flex flex-col  '>

                <h1 className='text-2xl text-[#212121] font-medium  capitalize'>{"Open Ended Feedback " + (index > 0 ? "(Continue)" : "")}</h1>

                <div className='text-[#212121] space-y-4 text-sm'>
                  <p className='text-[#212121] italic'> This section provides verbatim comments from all respondents. You will gain the most value if you pay attention to the frequently occurring topics and suggestions. Try to view the information objectively and reconcile it with the information in the previous rating sections.</p>

                  <div>
                    <h1 className='font-medium text-[#212121] text-lg'>{"Q." + (item.sno).toString() + " " + item.name}</h1>

                    {item && item.textList && item.textList.length > 0 ?
                      item.textList.map((text, index2) =>
                        <div class="space-x-4 flex items-end py-2">
                          <div>
                            <div className='bg-green-500 flex items-center justify-center h-8 w-8 rounded-full p-1' style={{ background: text.color }}>
                              <img src='/img/profile.svg' />
                            </div>
                          </div>
                          <div className=' rounded-tl-xl rounded-r-xl p-4 w-full '
                            style={{
                              background: `${text.color}1A`, // 1A represents 10% opacity in hexadecimal
                            }}
                          >
                            <p class="text-sm text-slate-600">{text && text.text}</p>
                          </div>
                        </div>
                      ) : null}

                  </div>



                </div>


              </div>

              {/* <div className='py-4 flex justify-between items-center'>
                  <img src="/img/logo/happypluslogo.png" class=" w-24 object-cover" alt="default_survey_logo" />
                  <p className='text-slate-600'>Page 1</p>
                  <div/>
                </div> */}

            </section>


          ) : null}

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
