import {
  lifecycledashConstants
} from '../_constants';

export function lifecycledash(state = {}, action) {

  switch (action.type) {

    case lifecycledashConstants.LIFECYCLE_GET_CLIENT_PROFILE_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_CLIENT_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        getClientProfileSuccess: true,
        loading: false,
        ClientProfileData:action.lifecycledash.ClientProfileData,
      };
    case lifecycledashConstants.LIFECYCLE_GET_CLIENT_PROFILE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



          case lifecycledashConstants.LIFECYCLE_TREE_MAP_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_TREE_MAP_SUCCESS:
      return {
      ...state,
      loading: false,
      TreeMapSuccess: true,
      TreeMapData:action.lifecycledash.TreeMapData.list
    };
  case lifecycledashConstants.LIFECYCLE_TREE_MAP_FAILURE:
      return {
      ...state,
      loading: false,      
      TreeMapError: action.error ,
      error: action.error
    };


    


    
    case lifecycledashConstants.LIFECYCLE_TREE_MAP_LEN_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_TREE_MAP_LEN_SUCCESS:
      return {
      ...state,
      loading: false,
      textDistTreeMapSuccess: true,
      textDistTreeMapData:action.lifecycledash.textDistTreeMapData.list
    };
  case lifecycledashConstants.LIFECYCLE_TREE_MAP_LEN_FAILURE:
      return {
      ...state,
      loading: false,      
      textDistError:action.error,    
      error: action.error
    };






        case lifecycledashConstants.LIFECYCLE_GENERATE_COMPANY_SURVEY_CODE_REQUEST:
          return {
            ...state,
            loading: true
          };
        case lifecycledashConstants.LIFECYCLE_GENERATE_COMPANY_SURVEY_CODE_SUCCESS:
          return {
            ...state,
            generateCompanySurveyCodeSuccess: true,
            loading: false,
            generateCompanySurveyCodeData:action.lifecycledash.generateCompanySurveyCodeData
          };
        case lifecycledashConstants.LIFECYCLE_GENERATE_COMPANY_SURVEY_CODE_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
  



      case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_ORG_REPORT_REQUEST:
        return {
          ...state,
          loading: true
        };
      case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_ORG_REPORT_SUCCESS:
        return {
          ...state,
          getDemographicOrgReportSuccess: true,
          loading: false,
          getDemographicOrgReportData:action.lifecycledash.getDemographicOrgReportData,
        };
      case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_ORG_REPORT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };
  




      //----------------------------------------------------------------- 

      case lifecycledashConstants.LIFECYCLE_GET_SURVEY_REQUEST:
        return {
          ...state,
          loading: true
        };
      case lifecycledashConstants.LIFECYCLE_GET_SURVEY_SUCCESS:
        return {
          ...state,
          getSurveySuccess: true,
          loading: false,
          SurveyList:action.lifecycledash.SurveyData.list,
          SurveyTotal:action.lifecycledash.SurveyData.total

        };
      case lifecycledashConstants.LIFECYCLE_GET_SURVEY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };

        //----------------------------------------------------------------- 


        case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_REQUEST:
          return {
            ...state,
            loading: true
          };
        case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_SUCCESS:
          return {
            ...state,
            getHappynessIndexSuccess: true,
            loading: false,
            HappynessIndexData:action.lifecycledash.HappynessIndexData
  
          };
        case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };

          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_SUCCESS:
            return {
              ...state,
              getEngagementIndexSuccess: true,
              loading: false,
              EngagementIndexData:action.lifecycledash.EngagementIndexData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };

          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_SUCCESS:
            return {
              ...state,
              getHopeIndexSuccess: true,
              loading: false,
              HopeIndexData:action.lifecycledash.HopeIndexData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
          
          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_SUCCESS:
            return {
              ...state,
              getStressIndexSuccess: true,
              loading: false,
              StressIndexData:action.lifecycledash.StressIndexData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };

          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_SUCCESS:
            return {
              ...state,
              getManagerIndexSuccess: true,
              loading: false,
              ManagerIndexData:action.lifecycledash.ManagerIndexData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };

          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_SUCCESS:
            return {
              ...state,
              getLeaderIndexSuccess: true,
              loading: false,

              LeaderIndexData:action.lifecycledash.LeaderIndexData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_FAILURE:
            return {
              ...state,
              loading: false,

              error: action.error
            };

          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_MNGR_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_MNGR_SUCCESS:
            return {
              ...state,
              getHappinessIndexMNGRSuccess: true,
              loading: false,
              HappinessIndexMNGRData:action.lifecycledash.HappinessIndexMNGRData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_MNGR_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };


          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_MNGR_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_MNGR_SUCCESS:
            return {
              ...state,
              getEngagementIndexMNGRSuccess: true,
              loading: false,
              EngagementIndexMNGRData:action.lifecycledash.EngagementIndexMNGRData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_MNGR_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };

          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_MNGR_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_MNGR_SUCCESS:
            return {
              ...state,
              getHopeIndexMNGRSuccess: true,
              loading: false,
              HopeIndexMNGRData:action.lifecycledash.HopeIndexMNGRData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_MNGR_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };


          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_MNGR_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_MNGR_SUCCESS:
            return {
              ...state,
              getStressIndexMNGRSuccess: true,
              loading: false,
              StressIndexMNGRData:action.lifecycledash.StressIndexMNGRData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_MNGR_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
        

          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_FILTER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_FILTER_SUCCESS:
            return {
              ...state,
              getHappinessIndexFilterSuccess: true,
              loading: false,
              HappinessIndexFilterData:action.lifecycledash.HappinessIndexFilterData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_FILTER_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };


          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_FILTER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_FILTER_SUCCESS:
            return {
              ...state,
              loading: false,
              getEngagementIndexFilterSuccess: true,
              EngagementIndexFilterData:action.lifecycledash.EngagementIndexFilterData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_FILTER_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };


          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_FILTER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_FILTER_SUCCESS:
            return {
              ...state,
              loading: false,
              getHopeIndexFilterSuccess: true,
              HopeIndexFilterData:action.lifecycledash.HopeIndexFilterData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_FILTER_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };


          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_FILTER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_FILTER_SUCCESS:
            return {
              ...state,
              loading: false,
              getStressIndexFilterSuccess: true,
              StressIndexFilterData:action.lifecycledash.StressIndexFilterData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_FILTER_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };

            
          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_FILTER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_FILTER_SUCCESS:
            return {
              ...state,
              loading: false,
              getManagerIndexFilterSuccess: true,
              ManagerIndexFilterData:action.lifecycledash.ManagerIndexFilterData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_FILTER_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };

            
          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_FILTER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_FILTER_SUCCESS:
            return {
              ...state,
              loading: false,
              getLeaderIndexFilterSuccess: true,
              LeaderIndexFilterData:action.lifecycledash.LeaderIndexFilterData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_FILTER_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };

          //----------------------------------------------------------------- 

          case lifecycledashConstants.LIFECYCLE_GET_EMP_FILTER_REQUEST:
            return {
              ...state,
              loading: false
            };
          case lifecycledashConstants.LIFECYCLE_GET_EMP_FILTER_SUCCESS:
            return {
              ...state,
              getEmpFilterSuccess: true,
              loading: false,

              EmpFilterData:action.lifecycledash.EmpFilterData
    
            };
          case lifecycledashConstants.LIFECYCLE_GET_EMP_FILTER_FAILURE:
            return {
              ...state,
              loading: false,

              error: action.error
            };


            case lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_REQUEST:
              return {
                ...state,
                loading: true
              };
            case lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_SUCCESS:
              return {
                ...state,
                getManagerListSuccess: true,
                loading: false,

                ManagerListData:action.lifecycledash.ManagerList.list,
              };
            case lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_FAILURE:
              return {
                ...state,
                loading: false,

                error: action.error
              };


              
              case lifecycledashConstants.LIFECYCLE_GET_QUESTION_ANALYSIS_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case lifecycledashConstants.LIFECYCLE_GET_QUESTION_ANALYSIS_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  getQuestionAnalysisSuccess: true,
                  QuestionAnalysisData:action.lifecycledash.QuestionAnalysisData.questionDetails,
                };
              case lifecycledashConstants.LIFECYCLE_GET_QUESTION_ANALYSIS_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };



                case lifecycledashConstants.LIFECYCLE_GET_QUESTION_ANALYSIS_NEW_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case lifecycledashConstants.LIFECYCLE_GET_QUESTION_ANALYSIS_NEW_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getQuestionAnalysisOverallSuccess: true,
                    QuestionAnalysisOverallData:action.lifecycledash.QuestionAnalysisOverallData.questionDetails,
                  };
                case lifecycledashConstants.LIFECYCLE_GET_QUESTION_ANALYSIS_NEW_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };


                

                case lifecycledashConstants.LIFECYCLE_GET_QUESTION_ANALYSIS_FILTER_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case lifecycledashConstants.LIFECYCLE_GET_QUESTION_ANALYSIS_FILTER_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getQuestionAnalysisFilterSuccess: true,
                    QuestionAnalysisFilterData:action.lifecycledash.QuestionAnalysisFilterData,
                  };
                case lifecycledashConstants.LIFECYCLE_GET_QUESTION_ANALYSIS_FILTER_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };




                case lifecycledashConstants.LIFECYCLE_GET_COMPANY_EMP_LIST_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case lifecycledashConstants.LIFECYCLE_GET_COMPANY_EMP_LIST_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getCompanyEmployeeListSuccess: true,
                    CompanyEmployeeList:action.lifecycledash.CompanyEmployeeList
                  };
                case lifecycledashConstants.LIFECYCLE_GET_COMPANY_EMP_LIST_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };


                  case lifecycledashConstants.LIFECYCLE_GET_ASSIGN_ROLE_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case lifecycledashConstants.LIFECYCLE_GET_ASSIGN_ROLE_SUCCESS:
                    return {
                      ...state,
                      loading: false,
                      getAssignRoleSuccess: true
                    };
                  case lifecycledashConstants.LIFECYCLE_GET_ASSIGN_ROLE_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };
    
                    case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_NEW_REQUEST:
                      return {
                        ...state,
                        loading: true
                      };
                    case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_NEW_SUCCESS:
                      return {
                        ...state,
                        getHappinessIndexNewSuccess: true,
                        loading: false,
                        HappinessIndexNewData:action.lifecycledash.HappinessIndexNewData
                      };
                    case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_NEW_FAILURE:
                      return {
                        ...state,
                        loading: false,
          
                        error: action.error
                      };

                      case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_NEW_REQUEST:
                        return {
                          ...state,
                          loading: true
                        };
                      case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_NEW_SUCCESS:
                        return {
                          ...state,
                          getEngagementIndexNewSuccess: true,
                          loading: false,
                          EngagementIndexNewData:action.lifecycledash.EngagementIndexNewData
                        };
                      case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_NEW_FAILURE:
                        return {
                          ...state,
                          loading: false,
            
                          error: action.error
                        };
                      
                        case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_NEW_REQUEST:
                          return {
                            ...state,
                            loading: true
                          };
                        case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_NEW_SUCCESS:
                          return {
                            ...state,
                            getHopeIndexNewSuccess: true,
                            loading: false,
                            HopeIndexNewData:action.lifecycledash.HopeIndexNewData
                          };
                        case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_NEW_FAILURE:
                          return {
                            ...state,
                            loading: false,
              
                            error: action.error
                          };

                          case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_NEW_REQUEST:
                            return {
                              ...state,
                              loading: true
                            };
                          case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_NEW_SUCCESS:
                            return {
                              ...state,
                              loading: false,
                              getStressIndexNewSuccess: true,
                              StressIndexNewData:action.lifecycledash.StressIndexNewData
                            };
                          case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_NEW_FAILURE:
                            return {
                              ...state,
                              loading: false,
                
                              error: action.error
                            };
                            

                            
                          case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_NEW_REQUEST:
                            return {
                              ...state,
                              loading: true
                            };
                          case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_NEW_SUCCESS:
                            return {
                              ...state,
                              loading: false,
                              getManagerIndexNewSuccess: true,
                              ManagerIndexNewData:action.lifecycledash.ManagerIndexNewData
                            };
                          case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_NEW_FAILURE:
                            return {
                              ...state,
                              loading: false,
                
                              error: action.error
                            };



                            
                            case lifecycledashConstants.LIFECYCLE_GET_ORG_CORE_INDEX_REQUEST:
                              return {
                                ...state,
                                loading: true
                              };
                            case lifecycledashConstants.LIFECYCLE_GET_ORG_CORE_INDEX_SUCCESS:
                              return {
                                ...state,
                                loading: false,
                                getOrgCoreIndexSuccess: true,
                                OrgCoreIndexData:action.lifecycledash.OrgCoreIndexData
                              };
                            case lifecycledashConstants.LIFECYCLE_GET_ORG_CORE_INDEX_FAILURE:
                              return {
                                ...state,
                                loading: false,
                  
                                error: action.error
                              };



                            case lifecycledashConstants.LIFECYCLE_GET_STACK_LIST_SUCCESS:
                              return {
                                ...state,
                                getStackListSuccess: true,
                                loading: false,
                                // StackListData:action.lifecycledash.StackListData
                              };





    // new api s------------------------------------------>


    case lifecycledashConstants.LIFECYCLE_GET_INDEX_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_INDEX_SUCCESS:
        return {
        ...state,
        loading: false,
        getIndexSuccess: true,
        getIndexData:action.lifecycledash.getIndexData
      };
    case lifecycledashConstants.LIFECYCLE_GET_INDEX_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




      case lifecycledashConstants.LIFECYCLE_GET_OVERALL_INDEX_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_OVERALL_INDEX_SUCCESS:
        return {
        ...state,
        loading: false,
        getOverallIndexSuccess: true,
        getOverallIndexData:action.lifecycledash.getOverallIndexData
      };
    case lifecycledashConstants.LIFECYCLE_GET_OVERALL_INDEX_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };





    case lifecycledashConstants.LIFECYCLE_GET_INDEX_FILTER_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_INDEX_FILTER_SUCCESS:
        return {
        ...state,
        loading: false,
        getIndexFilterSuccess: true,
        getIndexFilterData:action.lifecycledash.getIndexFilterData
      };
    case lifecycledashConstants.LIFECYCLE_GET_INDEX_FILTER_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



    case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SUCCESS:
        return {
        ...state,
        loading: false,
        getDemographicIndexSuccess: true,
        getDemographicIndexData:action.lifecycledash.getDemographicIndexData
      };
    case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




    case lifecycledashConstants.LIFECYCLE_GET_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getQuestionSuccess: true,
        getQuestionData:action.lifecycledash.getQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




      case lifecycledashConstants.LIFECYCLE_GET_QUESTION_ONLY_STRESS_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_QUESTION_ONLY_STRESS_SUCCESS:

        return {
        ...state,
        loading: false,
        getQuestionDistributionSuccess: true,
        getQuestionDistributionData:action.lifecycledash.getQuestionDistributionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_QUESTION_ONLY_STRESS_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



    case lifecycledashConstants.LIFECYCLE_GET_HAPINESS_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_HAPINESS_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getHappinessQuestionSuccess: true,
        getHappinessQuestionData:action.lifecycledash.getHappinessQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_HAPINESS_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




      case lifecycledashConstants.LIFECYCLE_GET_HAPINESS_MANAGER_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_HAPINESS_MANAGER_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getHappinessManagerQuestionSuccess: true,
        getHappinessManagerQuestionData:action.lifecycledash.getHappinessQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_HAPINESS_MANAGER_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };

      
      case lifecycledashConstants.LIFECYCLE_GET_HAPINESS_DMG_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_HAPINESS_DMG_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getHappinessDMGQuestionSuccess: true,
        getHappinessDMGQuestionData:action.lifecycledash.getHappinessQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_HAPINESS_DMG_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };






      case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getEngagementQuestionSuccess: true,
        getEngagementQuestionData:action.lifecycledash.getEngagementQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_MANAGER_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_MANAGER_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getEngagementManagerQuestionSuccess: true,
        getEngagementManagerQuestionData:action.lifecycledash.getEngagementQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_MANAGER_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_DMG_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_DMG_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getEngagementDMGQuestionSuccess: true,
        getEngagementDMGQuestionData:action.lifecycledash.getEngagementQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_DMG_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };







      case lifecycledashConstants.LIFECYCLE_GET_HOPE_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_HOPE_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getHopeQuestionSuccess: true,
        getHopeQuestionData:action.lifecycledash.getHopeQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_HOPE_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




      case lifecycledashConstants.LIFECYCLE_GET_STRESS_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_STRESS_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getStressQuestionSuccess: true,
        getStressQuestionData:action.lifecycledash.getStressQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_STRESS_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      case lifecycledashConstants.LIFECYCLE_GET_STRESS_MANAGER_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_STRESS_MANAGER_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getStressManagerQuestionSuccess: true,
        getStressManagerQuestionData:action.lifecycledash.getStressQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_STRESS_MANAGER_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      case lifecycledashConstants.LIFECYCLE_GET_STRESS_DMG_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_STRESS_DMG_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getStressDMGQuestionSuccess: true,
        getStressDMGQuestionData:action.lifecycledash.getStressQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_STRESS_DMG_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };





      case lifecycledashConstants.LIFECYCLE_GET_MANAGER_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_MANAGER_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getManagerQuestionSuccess: true,
        getManagerQuestionData:action.lifecycledash.getManagerQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_MANAGER_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      case lifecycledashConstants.LIFECYCLE_GET_MANAGER_MANAGER_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_MANAGER_MANAGER_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getManagerManagerQuestionSuccess: true,
        getManagerManagerQuestionData:action.lifecycledash.getManagerQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_MANAGER_MANAGER_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      case lifecycledashConstants.LIFECYCLE_GET_MANAGER_DMG_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_MANAGER_DMG_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getManagerDMGQuestionSuccess: true,
        getManagerDMGQuestionData:action.lifecycledash.getManagerQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_MANAGER_DMG_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      
      case lifecycledashConstants.LIFECYCLE_GET_LEADER_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_LEADER_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getLeaderQuestionSuccess: true,
        getLeaderQuestionData:action.lifecycledash.getLeaderQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_LEADER_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      case lifecycledashConstants.LIFECYCLE_GET_LEADER_MANAGER_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_LEADER_MANAGER_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getLeaderManagerQuestionSuccess: true,
        getLeaderManagerQuestionData:action.lifecycledash.getLeaderQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_LEADER_MANAGER_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      case lifecycledashConstants.LIFECYCLE_GET_LEADER_DMG_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_LEADER_DMG_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getLeaderDMGQuestionSuccess: true,
        getLeaderDMGQuestionData:action.lifecycledash.getLeaderQuestionData
      };
    case lifecycledashConstants.LIFECYCLE_GET_LEADER_DMG_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




      case lifecycledashConstants.LIFECYCLE_GET_QUESTION_REPORT_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_QUESTION_REPORT_SUCCESS:

        return {
        ...state,
        loading: false,
        getQuestionReportSuccess: true,
        getQuestionReportData:action.lifecycledash.getQuestionReportData
      };
    case lifecycledashConstants.LIFECYCLE_GET_QUESTION_REPORT_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



    case lifecycledashConstants.LIFECYCLE_GET_QUESTION_FILTER_REQUEST:
        return {
        ...state,
        loading: true
      };
    case lifecycledashConstants.LIFECYCLE_GET_QUESTION_FILTER_SUCCESS:
        return {
        ...state,
        loading: false,
        getQuestionFilterSuccess: true,
        getQuestionFilterData:action.lifecycledash.getQuestionFilterData
      };
    case lifecycledashConstants.LIFECYCLE_GET_QUESTION_FILTER_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };








      
  case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getHappinessIndexV2Success: true,
      getHappinessIndexV2Data:action.lifecycledash.getHappinessIndexV2Data
    };
  case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };





  case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getEngagementIndexV2Success: true,
      getEngagementIndexV2Data:action.lifecycledash.getEngagementIndexV2Data
    };
  case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




  case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getHopeIndexV2Success: true,
      getHopeIndexV2Data:action.lifecycledash.getHopeIndexV2Data
    };
  case lifecycledashConstants.LIFECYCLE_GET_HOPE_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };





  case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getStressIndexV2Success: true,
      getStressIndexV2Data:action.lifecycledash.getStressIndexV2Data
    };
  case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };





  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getHappinessIndexV2Success: true,
      getHappinessIndexV2Data:action.lifecycledash.getHappinessIndexV2Data
    };
  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };






  case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getHappinessIndexV2Success: true,
      getHappinessIndexV2Data:action.lifecycledash.getHappinessIndexV2Data
    };
  case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };





  case lifecycledashConstants.LIFECYCLE_GET_SENIORLEADERSHIP_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_SENIORLEADERSHIP_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getHappinessIndexV2Success: true,
      getHappinessIndexV2Data:action.lifecycledash.getHappinessIndexV2Data
    };
  case lifecycledashConstants.LIFECYCLE_GET_SENIORLEADERSHIP_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getOrgIndexV2Success: true,
      getOrgIndexV2Data:action.lifecycledash.getOrgIndexV2Data
    };
  case lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };










  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_HAPPINESS_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_HAPPINESS_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexHappinessSuccess: true,
      getDemographicIndexHappinessData:action.lifecycledash.getDemographicIndexHappinessData
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_HAPPINESS_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexEngagementSuccess: true,
      getDemographicIndexEngagementData:action.lifecycledash.getDemographicIndexEngagementData
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_STRESS_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_STRESS_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexStressSuccess: true,
      getDemographicIndexStressData:action.lifecycledash.getDemographicIndexStressData
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_STRESS_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };

  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANAGER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANAGER_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexManagerSuccess: true,
      getDemographicIndexManagerData:action.lifecycledash.getDemographicIndexManagerData
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANAGER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




    case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_LEADER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_LEADER_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexLeaderSuccess: true,
      getDemographicIndexLeaderData:action.lifecycledash.getDemographicIndexLeaderData
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_LEADER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };










  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ORG_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ORG_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexOrgSuccess: true,
      getDemographicIndexOrgData:action.lifecycledash.getDemographicIndexOrgData
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_ORG_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_SUCCESS:
      return {
      ...state,
      loading: false,
      getHappinessIndexDriverSuccess: true,
      getHappinessIndexDriverData:action.lifecycledash.getHappinessIndexDriverData
    };
  case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_DRIVER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_DRIVER_SUCCESS:
      return {
      ...state,
      loading: false,
      getEngagementIndexDriverSuccess: true,
      getEngagementIndexDriverData:action.lifecycledash.getEngagementIndexDriverData
    };
  case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_DRIVER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_DRIVER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_DRIVER_SUCCESS:
      return {
      ...state,
      loading: false,
      getStressIndexDriverSuccess: true,
      getStressIndexDriverData:action.lifecycledash.getStressIndexDriverData
    };
  case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_DRIVER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_DRIVER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_DRIVER_SUCCESS:
      return {
      ...state,
      loading: false,
      getManagerIndexDriverSuccess: true,
      getManagerIndexDriverData:action.lifecycledash.getManagerIndexDriverData
    };
  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_DRIVER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


    case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_DRIVER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_DRIVER_SUCCESS:
      return {
      ...state,
      loading: false,
      getLeaderIndexDriverSuccess: true,
      getLeaderIndexDriverData:action.lifecycledash.getLeaderIndexDriverData
    };
  case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_DRIVER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_DRIVER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_DRIVER_SUCCESS:
      return {
      ...state,
      loading: false,
      getOrgIndexDriverSuccess: true,
      getOrgIndexDriverData:action.lifecycledash.getOrgIndexDriverData
    };
  case lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_DRIVER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };

  




  case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_SUCCESS:
      return {
      ...state,
      loading: false,
      getHappinessIndexV2DemographicSuccess: true,
      getHappinessIndexV2DemographicData:action.lifecycledash.getHappinessIndexV2DemographicData
    };
  case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_SUCCESS:
      return {
      ...state,
      loading: false,
      getEngagementIndexV2DemographicSuccess: true,
      getEngagementIndexV2DemographicData:action.lifecycledash.getEngagementIndexV2DemographicData
    };
  case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_DEMOGRAPHIC_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_DEMOGRAPHIC_SUCCESS:
      return {
      ...state,
      loading: false,
      getStressIndexV2DemographicSuccess: true,
      getStressIndexV2DemographicData:action.lifecycledash.getStressIndexV2DemographicData
    };
  case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_DEMOGRAPHIC_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexForManagerSuccess: true,
      getDemographicIndexForManagerData:action.lifecycledash.getDemographicIndexForManagerData
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };





    case lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_SUCCESS:
      return {
      ...state,
      loading: false,
      getManagerListLifecycledashManagerIndexSuccess: true,
      getManagerListLifecycledashManagerIndexData:action.lifecycledash.getManagerListLifecycledashManagerIndexData
    };
  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



    case lifecycledashConstants.LIFECYCLE_GET_MULTIMANAGER_QUESTIONLIST_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_MULTIMANAGER_QUESTIONLIST_SUCCESS:
      return {
      ...state,
      loading: false,
      getMultiManagerQuestionListSuccess: true,
      getMultiManagerQuestionListData:action.lifecycledash.getMultiManagerQuestionListData
    };
  case lifecycledashConstants.LIFECYCLE_GET_MULTIMANAGER_QUESTIONLIST_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




  case lifecycledashConstants.LIFECYCLE_GET_ALL_BENCHMARK_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_ALL_BENCHMARK_SUCCESS:
      return {
      ...state,
      loading: false,
      getAllBenchMarkSuccess: true,
      getAllBenchMarkData:action.lifecycledash.getAllBenchMarkData
    };
  case lifecycledashConstants.LIFECYCLE_GET_ALL_BENCHMARK_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case lifecycledashConstants.LIFECYCLE_GET_RESPONDANT_DETAILS_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_RESPONDANT_DETAILS_SUCCESS:
      return {
      ...state,
      loading: false,
      getRespondantDetailsSuccess: true,
      getRespondantLifecycleDetailsData:action.lifecycledash.getRespondantLifecycleDetailsData
    };
  case lifecycledashConstants.LIFECYCLE_GET_RESPONDANT_DETAILS_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_DOMAIN_FILTER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_DOMAIN_FILTER_SUCCESS:
      return {
      ...state,
      loading: false,
      getManagerListDomainFilterSuccess: true,
      getManagerListDomainFilterData:action.lifecycledash.getManagerListDomainFilterData
    };
  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_LIST_DOMAIN_FILTER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_HAPPINESS_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_HAPPINESS_SUCCESS:
      return {
      ...state,
      loading: false,
      getQuestionManagerOfHappinessSuccess: true,
      getQuestionManagerOfHappinessData:action.lifecycledash.getQuestionManagerOfHappinessData
    };
  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_HAPPINESS_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_ENGAGEMENT_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_ENGAGEMENT_SUCCESS:
      return {
      ...state,
      loading: false,
      getQuestionManagerOfEngagementSuccess: true,
      getQuestionManagerOfEngagementData:action.lifecycledash.getQuestionManagerOfEngagementData
    };
  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_ENGAGEMENT_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };

  

  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_STRESS_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_STRESS_SUCCESS:
      return {
      ...state,
      loading: false,
      getQuestionManagerOfStressSuccess: true,
      getQuestionManagerOfStressData:action.lifecycledash.getQuestionManagerOfStressData
    };
  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_STRESS_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_MANAGER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_MANAGER_SUCCESS:
      return {
      ...state,
      loading: false,
      getQuestionManagerOfManagerSuccess: true,
      getQuestionManagerOfManagerData:action.lifecycledash.getQuestionManagerOfManagerData
    };
  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MANAGER_OF_MANAGER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


    case lifecycledashConstants.LIFECYCLE_GET_INDEX_QUANTILE_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_INDEX_QUANTILE_SUCCESS:
      return {
      ...state,
      loading: false,
      getIndexQuantileSuccess: true,
      getIndexQuantileData:action.lifecycledash.getIndexQuantileData.list,
      getIndexQuantileCount:action.lifecycledash.getIndexQuantileData.count
    };
  case lifecycledashConstants.LIFECYCLE_GET_INDEX_QUANTILE_FAILURE:
      return {
      ...state,
      loading: false,      
      getIndexQuantileError: action.error,
      error: action.error
    };



  case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_CORRELATION_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_CORRELATION_SUCCESS:
      return {
      ...state,
      loading: false,
      getHappinessCorrelationSuccess: true,
      getHappinessCorrelationData:action.lifecycledash.getHappinessCorrelationData
    };
  case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_CORRELATION_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_CORRELATION_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_CORRELATION_SUCCESS:
      return {
      ...state,
      loading: false,
      getEngagementCorrelationSuccess: true,
      getEngagementCorrelationData:action.lifecycledash.getEngagementCorrelationData
    };
  case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_CORRELATION_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };

  case lifecycledashConstants.LIFECYCLE_GET_STRESS_CORRELATION_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_STRESS_CORRELATION_SUCCESS:
      return {
      ...state,
      loading: false,
      getStressCorrelationSuccess: true,
      getStressCorrelationData:action.lifecycledash.getStressCorrelationData
    };
  case lifecycledashConstants.LIFECYCLE_GET_STRESS_CORRELATION_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_CORRELATION_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_CORRELATION_SUCCESS:
      return {
      ...state,
      loading: false,
      getManagerCorrelationSuccess: true,
      getManagerCorrelationData:action.lifecycledash.getManagerCorrelationData
    };
  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_CORRELATION_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case lifecycledashConstants.LIFECYCLE_GET_LEADER_CORRELATION_REQUEST:
    return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_LEADER_CORRELATION_SUCCESS:
      return {
      ...state,
      loading: false,
      getLeaderCorrelationSuccess: true,
      getLeaderCorrelationData:action.lifecycledash.getLeaderCorrelationData
    };
  case lifecycledashConstants.LIFECYCLE_GET_LEADER_CORRELATION_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SCATTER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SCATTER_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexScatterSuccess: true,
      getDemographicIndexScatterData:action.lifecycledash.getDemographicIndexScatterData
    };
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_SCATTER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


    case lifecycledashConstants.LIFECYCLE_GET_RWA_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_RWA_SUCCESS:
      return {
      ...state,
      loading: false,
      getRWAnalysisSuccess: true,
      getRWAnalysisData:action.lifecycledash.getRWAnalysisData
    };
  case lifecycledashConstants.LIFECYCLE_GET_RWA_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


    case lifecycledashConstants.LIFECYCLE_GET_TEXT_QUESTION_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_TEXT_QUESTION_SUCCESS:
      return {
      ...state,
      loading: false,
      getTextQuestionSuccess: true,
      getTextQuestionData:action.lifecycledash.getTextQuestionData
    };
  case lifecycledashConstants.LIFECYCLE_GET_TEXT_QUESTION_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };
    

    case lifecycledashConstants.LIFECYCLE_TEXT_DIST_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_TEXT_DIST_SUCCESS:
      return {
      ...state,
      loading: false,
      textDistSuccess: true,
      textDistData:action.lifecycledash.textDistData
    };
  case lifecycledashConstants.LIFECYCLE_TEXT_DIST_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



    

    case lifecycledashConstants.LIFECYCLE_WORD_CLOUD_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_WORD_CLOUD_SUCCESS:
      return {
      ...state,
      loading: false,
      wordCloudSuccess: true,
      wordCloudData:action.lifecycledash.wordCloudData
    };
  case lifecycledashConstants.LIFECYCLE_WORD_CLOUD_FAILURE:
      return {
      ...state,
      loading: false,      
      wordCloudError: action.error,     
      error: action.error
    };




    case lifecycledashConstants.LIFECYCLE_ADVANCE_WORD_CLOUD_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_ADVANCE_WORD_CLOUD_SUCCESS:
      return {
      ...state,
      loading: false,
      AdvanceWordCloudSuccess: true,
      AdvanceWordCloudData:action.lifecycledash.AdvanceWordCloudData
    };
  case lifecycledashConstants.LIFECYCLE_ADVANCE_WORD_CLOUD_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case lifecycledashConstants.LIFECYCLE_WORD_CLOUD_TEXT_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_WORD_CLOUD_TEXT_SUCCESS:
      return {
      ...state,
      loading: false,
      wordCloudTextSuccess: true,
      wordCloudTextData:action.lifecycledash.wordCloudTextData,
      wordTextKeyWord:action.lifecycledash.wordTextKeyWord

    };
  case lifecycledashConstants.LIFECYCLE_WORD_CLOUD_TEXT_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


    case lifecycledashConstants.LIFECYCLE_WORD_CLOUD_MULTI_TEXT_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_WORD_CLOUD_MULTI_TEXT_SUCCESS:
      return {
      ...state,
      loading: false,
      wordCloudTextSuccess: true,
      wordCloudMultiTextData:action.lifecycledash.wordCloudMultiTextData,
      wordMultiTextKeyWord:action.lifecycledash.wordMultiTextKeyWord

    };
  case lifecycledashConstants.LIFECYCLE_WORD_CLOUD_MULTI_TEXT_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case lifecycledashConstants.LIFECYCLE_COOCCUR_MATRIX_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_COOCCUR_MATRIX_SUCCESS:
      return {
      ...state,
      loading: false,
      cooccurmatrixSuccess: true,
      cooccurmatrixData:action.lifecycledash.cooccurmatrixData
    };
  case lifecycledashConstants.LIFECYCLE_COOCCUR_MATRIX_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case lifecycledashConstants.LIFECYCLE_NEIGHBOR_GRAPH_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_NEIGHBOR_GRAPH_SUCCESS:
      return {
      ...state,
      loading: false,
      neighborGraphSuccess: true,
      neighborGraphData:action.lifecycledash.neighborGraphData
    };
  case lifecycledashConstants.LIFECYCLE_NEIGHBOR_GRAPH_FAILURE:
      return {
      ...state,
      loading: false,    
      neighborGraphError: action.error,   
      error: action.error
    };




    case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_RWA_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_RWA_SUCCESS:
      return {
      ...state,
      loading: false,
      getEngagementRWASuccess: true,
      getEngagementRWAData:action.lifecycledash.getEngagementRWAData
    };
  case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_RWA_FAILURE:
      return {
      ...state,
      loading: false,      
      getEngagementRWAError: action.error,
      error: action.error
    };





    
    case lifecycledashConstants.LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_REQUEST:
      return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_SUCCESS:
      return {
      ...state,
      loading: false,
      cronbatchAlphaVarianceSuccess: true,
      cronbatchAlphaVarianceData:action.lifecycledash.cronbatchAlphaVarianceData
    };
    case lifecycledashConstants.LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_SUCCESS_TWO:
      return {
      ...state,
      loading: false,
      cronbatchAlphaVarianceSuccess: true,
      cronbatchAlphaVarianceData:action.lifecycledash.cronbatchAlphaVarianceData
    };
  case lifecycledashConstants.LIFECYCLE_CRONE_BATCH_ALPHA_VARIANCE_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_FAVOURABILITY_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_FAVOURABILITY_SUCCESS:
      return {
      ...state,
      loading: false,
      getQuestionFavorabilitySuccess: true,
      getQuestionFavorabilityData:action.lifecycledash.getQuestionFavorabilityData
    };

  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_FAVOURABILITY_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



    

    case lifecycledashConstants.LIFECYCLE_GET_QUESTION_FAVOURABILITY_2_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_FAVOURABILITY_2_SUCCESS:
      return {
      ...state,
      loading: false,
      getQuestionFavorability2Success: true,
      getQuestionFavorability2Data:action.lifecycledash.getQuestionFavorability2Data
    };

  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_FAVOURABILITY_2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };






    case lifecycledashConstants.LIFECYCLE_GET_HISTORY_INDEX_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case lifecycledashConstants.LIFECYCLE_GET_HISTORY_INDEX_SUCCESS:
      return {
      ...state,
      loading: false,
      getHistoryIndexSuccess: true,
      getHistoryIndexData:action.lifecycledash.getHistoryIndexData
    };

  case lifecycledashConstants.LIFECYCLE_GET_HISTORY_INDEX_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



    case lifecycledashConstants.LIFECYCLE_GET_RESPONSE_ANALYTIYCS_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case lifecycledashConstants.LIFECYCLE_GET_RESPONSE_ANALYTIYCS_SUCCESS:
      return {
      ...state,
      loading: false,
      getResponseAnalyticsSuccess: true,
      getResponseAnalyticsData:action.lifecycledash.getResponseAnalyticsData
    };

  case lifecycledashConstants.LIFECYCLE_GET_RESPONSE_ANALYTIYCS_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




    case lifecycledashConstants.LIFECYCLE_GET_COMMENT_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case lifecycledashConstants.LIFECYCLE_GET_COMMENT_SUCCESS:
      return {
      ...state,
      loading: false,
      getCommentSuccess: true,
      getCommentData:action.lifecycledash.getCommentData.list
    };

  case lifecycledashConstants.LIFECYCLE_GET_COMMENT_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };





    case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_MULTI_FILTER_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_MULTI_FILTER_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexMultiFilterSuccess: true,
      getDemographicIndexMultiFilterData:action.lifecycledash.getDemographicIndexMultiFilterData
    };

  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_MULTI_FILTER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




    case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_REPORT_FILTER_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_REPORT_FILTER_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexFilterReportSuccess: true,
      getDemographicIndexFilterReportData:action.lifecycledash.getDemographicIndexFilterReportData
    };

  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_REPORT_FILTER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



    case lifecycledashConstants.LIFECYCLE_GET_ORG_DEMOGRAPHIC_INDEX_MANY_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case lifecycledashConstants.LIFECYCLE_GET_ORG_DEMOGRAPHIC_INDEX_MANY_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexOrgManyFilterSuccess: true,
      getDemographicIndexOrgManyFilterData:action.lifecycledash.getDemographicIndexOrgManyFilterData
    };

  case lifecycledashConstants.LIFECYCLE_GET_ORG_DEMOGRAPHIC_INDEX_MANY_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



    
    case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANY_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANY_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexMultiManyFilterSuccess: true,
      getDemographicIndexMultiManyFilterData:action.lifecycledash.getDemographicIndexMultiManyFilterData
    };

  case lifecycledashConstants.LIFECYCLE_GET_DEMOGRAPHIC_INDEX_MANY_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };







    case lifecycledashConstants.LIFECYCLE_ASSIGN_MANAGER_ACTION_PLAN_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case lifecycledashConstants.LIFECYCLE_ASSIGN_MANAGER_ACTION_PLAN_SUCCESS:
      return {
      ...state,
      loading: false,
      AssignManagerActionPlanSuccess: true,
    };

  case lifecycledashConstants.LIFECYCLE_ASSIGN_MANAGER_ACTION_PLAN_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



    case lifecycledashConstants.LIFECYCLE_GET_MANAGER_ACTION_PLAN_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_ACTION_PLAN_SUCCESS:
      return {
      ...state,
      loading: false,
      getManagerActionPlanSuccess: true,
      getManagerActionPlanData:action.lifecycledash.getManagerActionPlanData
    };

  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_ACTION_PLAN_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




    
    case lifecycledashConstants.LIFECYCLE_GET_EMPLOYEE_ACTION_PLAN_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case lifecycledashConstants.LIFECYCLE_GET_EMPLOYEE_ACTION_PLAN_SUCCESS:
      return {
      ...state,
      loading: false,
      getEmployeeActionPlanSuccess: true,
      getEmployeeActionPlanData:action.lifecycledash.getEmployeeActionPlanData
    };

  case lifecycledashConstants.LIFECYCLE_GET_EMPLOYEE_ACTION_PLAN_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




    
  case lifecycledashConstants.LIFECYCLE_UPDATE_EMPLOYEE_TASK_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case lifecycledashConstants.LIFECYCLE_UPDATE_EMPLOYEE_TASK_SUCCESS:
      return {
      ...state,
      loading: false,
      updateEmployeeTaskSuccess: true,
      updateEmployeeTaskData:action.lifecycledash.updateEmployeeTaskData
    };

  case lifecycledashConstants.LIFECYCLE_UPDATE_EMPLOYEE_TASK_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


    

    
  case lifecycledashConstants.LIFECYCLE_UPDATE_EMPLOYEE_RESOURCE_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case lifecycledashConstants.LIFECYCLE_UPDATE_EMPLOYEE_RESOURCE_SUCCESS:
      return {
      ...state,
      loading: false,
      updateEmployeeResourceSuccess: true,
      updateEmployeeResourceData:action.lifecycledash.updateEmployeeResourceData
    };

  case lifecycledashConstants.LIFECYCLE_UPDATE_EMPLOYEE_RESOURCE_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



        
case lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_ACTION_PLAN_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_ACTION_PLAN_SUCCESS:
    return {
    ...state,
    loading: false,
    getAllManagerActionPlanSuccess: true,
    getAllManagerActionPlanData:action.lifecycledash.getAllManagerActionPlanData
  };

case lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_ACTION_PLAN_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
  };




  case lifecycledashConstants.LIFECYCLE_CROSS_TAB_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_CROSS_TAB_SUCCESS:
    return {
    ...state,
    loading: false,
    crossTabSuccess: true,
    crossTabData:action.lifecycledash.crossTabData
  };

case lifecycledashConstants.LIFECYCLE_CROSS_TAB_FAILURE:
    return {
    ...state,
    loading: false, 
    crossTabError:action.error,      
    error: action.error
  };



  case lifecycledashConstants.LIFECYCLE_CROSS_TAB_DMG_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_CROSS_TAB_DMG_SUCCESS:
    return {
    ...state,
    loading: false,
    crossTabDMGSuccess: true,
    crossTabDMGData:action.lifecycledash.crossTabDMGData
  };

case lifecycledashConstants.LIFECYCLE_CROSS_TAB_DMG_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
  };



  case lifecycledashConstants.LIFECYCLE_GET_QUESTION_OVERALL_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_GET_QUESTION_OVERALL_SUCCESS:
    return {
    ...state,
    loading: false,
    getQuestionOverallSuccess: true,
    getQuestionOverallData:action.lifecycledash.getQuestionOverallData
  };

case lifecycledashConstants.LIFECYCLE_GET_QUESTION_OVERALL_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
  };



case lifecycledashConstants.LIFECYCLE_CROSS_TAB_ANALYSIS_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_CROSS_TAB_ANALYSIS_SUCCESS:
    return {
    ...state,
    loading: false,
    crossTabAnalysisSuccess: true,
    crossTabAnalysisData:action.lifecycledash.crossTabAnalysisData
  };

case lifecycledashConstants.LIFECYCLE_CROSS_TAB_ANALYSIS_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
  };





  case lifecycledashConstants.LIFECYCLE_CORRELATION_TYPE2_ANALYSIS_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_CORRELATION_TYPE2_ANALYSIS_SUCCESS:
    return {
    ...state,
    loading: false,
    getCorrelationQuestionType2Success: true,
    getCorrelationQuestionType2Data:action.lifecycledash.getCorrelationQuestionType2Data
  };

case lifecycledashConstants.LIFECYCLE_CORRELATION_TYPE2_ANALYSIS_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
  };





case lifecycledashConstants.LIFECYCLE_GET_ALL_PARAMETER_LIST_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_GET_ALL_PARAMETER_LIST_SUCCESS:
    return {
    ...state,
    loading: false,
    getAllParameterListSuccess: true,
    getAllParameterListData:action.lifecycledash.getAllParameterListData.list
  };

case lifecycledashConstants.LIFECYCLE_GET_ALL_PARAMETER_LIST_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};




case lifecycledashConstants.LIFECYCLE_GET_CORRELATION_OVERALL_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_GET_CORRELATION_OVERALL_SUCCESS:
    return {
    ...state,
    loading: false,
    getCorrelationDimenionVsDimensionSuccess: true,
    getCorrelationDimenionVsDimensionData:action.lifecycledash.getCorrelationDimenionVsDimensionData
  };

case lifecycledashConstants.LIFECYCLE_GET_CORRELATION_OVERALL_FAILURE:
    return {
    ...state,
    loading: false,      
    getCorrelationDimenionVsDimensionError: action.error,
    error: action.error
};



case lifecycledashConstants.LIFECYCLE_GET_CORRELATION_FILTER_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_GET_CORRELATION_FILTER_SUCCESS:
    return {
    ...state,
    loading: false,
    getCorrelationDimensionVsDimensionFilterSuccess: true,
    getCorrelationDimensionVsDimensionFilterData:action.lifecycledash.getCorrelationDimensionVsDimensionFilterData
  };

case lifecycledashConstants.LIFECYCLE_GET_CORRELATION_FILTER_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};







case lifecycledashConstants.LIFECYCLE_GET_MANAGER_PROFILE_DATA_REQUEST:
  return {
    ...state,
    loading: true
  };
case lifecycledashConstants.LIFECYCLE_GET_MANAGER_PROFILE_DATA_SUCCESS:
  return {
    ...state,
    getManagerProfileSuccess: true,
    loading: false,
    ManagerProfileData:action.lifecycledash.ManagerProfileData,
  };
case lifecycledashConstants.LIFECYCLE_GET_MANAGER_PROFILE_DATA_FAILURE:
  return {
    ...state,
    loading: false,
    error: action.error
  };





  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_SURVEY_LIST_REQUEST:
    return {
      ...state,
      loading: true
    };
  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_SURVEY_LIST_SUCCESS:
    return {
      ...state,
      getMNGRSurveyListSuccess: true,
      loading: false,
      getMNGRSurveyListData:action.lifecycledash.getMNGRSurveyListData.list
    };
  case lifecycledashConstants.LIFECYCLE_GET_MANAGER_SURVEY_LIST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error
    };
  



    case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_BY_FILTER_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_BY_FILTER_SUCCESS:
    return {
    ...state,
    loading: false,
    getHappinessIndexByFilterSuccess: true,
    getHappinessIndexByFilterData:action.lifecycledash.getIndexFilterData
  };

case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_BY_FILTER_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};


case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_BY_FILTER_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_BY_FILTER_SUCCESS:
    return {
    ...state,
    loading: false,
    getEngagementIndexByFilterSuccess: true,
    getEngagementIndexByFilterData:action.lifecycledash.getIndexFilterData
  };

case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_BY_FILTER_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};


case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_BY_FILTER_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_BY_FILTER_SUCCESS:
    return {
    ...state,
    loading: false,
    getStressIndexByFilterSuccess: true,
    getStressIndexByFilterData:action.lifecycledash.getIndexFilterData
  };

case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_BY_FILTER_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};




case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_BY_FILTER_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_BY_FILTER_SUCCESS:
    return {
    ...state,
    loading: false,
    getManagerIndexByFilterSuccess: true,
    getManagerIndexByFilterData:action.lifecycledash.getIndexFilterData
  };

case lifecycledashConstants.LIFECYCLE_GET_MANAGER_INDEX_BY_FILTER_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};




case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_BY_FILTER_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_BY_FILTER_SUCCESS:
    return {
    ...state,
    loading: false,
    getLeaderIndexByFilterSuccess: true,
    getLeaderIndexByFilterData:action.lifecycledash.getIndexFilterData
  };

case lifecycledashConstants.LIFECYCLE_GET_LEADER_INDEX_BY_FILTER_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};





case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_COMPARISON_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_COMPARISON_SUCCESS:
    return {
    ...state,
    loading: false,
    getDemographicIndexMultiFilterEngCompSuccess: true,
    getDemographicIndexMultiFilterEngCompData:action.lifecycledash.getDemographicIndexMultiFilterEngCompData
  };

case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_COMPARISON_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};








case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_COMPARISON_OVERALL_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_COMPARISON_OVERALL_SUCCESS:
    return {
    ...state,
    loading: false,
    getDemographicIndexEngCompOverallSuccess: true,
    getDemographicIndexEngCompOverallData:action.lifecycledash.getDemographicIndexEngCompOverallData
  };

case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_COMPARISON_OVERALL_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};












case lifecycledashConstants.LIFECYCLE_GET_QUESTION_COMMENT_SUCCESS:
    return {
    ...state,
    loading: false,
    getQuestionCommentsDataSuccess: true,
    getQuestionCommentsData:action.lifecycledash.getQuestionCommentsData
  };








  
case lifecycledashConstants.LIFECYCLE_GET_BRANCODE_INDEX_SCORE_REQUEST:
  return {
  ...state,
  loading: true
};

case lifecycledashConstants.LIFECYCLE_GET_BRANCODE_INDEX_SCORE_SUCCESS:
  return {
  ...state,
  loading: false,
  getDemographicBranchCodeIndexMultiFilterSuccess: true,
  getDemographicBranchCodeIndexMultiFilterData:action.lifecycledash.getDemographicBranchCodeIndexMultiFilterData
};

case lifecycledashConstants.LIFECYCLE_GET_BRANCODE_INDEX_SCORE_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};







case lifecycledashConstants.LIFECYCLE_GET_PARAMETER_TYPE_LIST_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_GET_PARAMETER_TYPE_LIST_SUCCESS:
    return {
    ...state,
    loading: false,
    getParameterTypeListSuccess: true,
    getParameterTypeListData:action.lifecycledash.getParameterTypeListData.list
  };

case lifecycledashConstants.LIFECYCLE_GET_PARAMETER_TYPE_LIST_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};




case lifecycledashConstants.LIFECYCLE_CREATE_PARAMETER_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case lifecycledashConstants.LIFECYCLE_CREATE_PARAMETER_SUCCESS:
    return {
    ...state,
    loading: false,
    createParameterSuccess: true
  };

case lifecycledashConstants.LIFECYCLE_CREATE_PARAMETER_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};





      case lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_FILTER_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_FILTER_SUCCESS:
        return {
        ...state,
        loading: false,
        getOrgIndexV2FilterSuccess: true,
        getOrgIndexV2FilterData:action.lifecycledash.getOrgIndexV2FilterData
      };

      case lifecycledashConstants.LIFECYCLE_GET_ORG_INDEX_FILTER_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };






      case lifecycledashConstants.LIFECYCLE_GET_HAPINESS_INDEX_V2_FILTER_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_HAPINESS_INDEX_V2_FILTER_SUCCESS:
        return {
        ...state,
        loading: false,
        getHappinessIndexV2FilterSuccess: true,
        getHappinessIndexV2FilterData:action.lifecycledash.getHappinessIndexV2FilterData
      };

      case lifecycledashConstants.LIFECYCLE_GET_HAPINESS_INDEX_V2_FILTER_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FILTER_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FILTER_SUCCESS:
        return {
        ...state,
        loading: false,
        getEngagementIndexV2FilterSuccess: true,
        getEngagementIndexV2FilterData:action.lifecycledash.getEngagementIndexV2FilterData
      };

      case lifecycledashConstants.LIFECYCLE_GET_ENGAGEMENT_INDEX_V2_FILTER_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };

      case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_FILTER_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_FILTER_SUCCESS:
        return {
        ...state,
        loading: false,
        getStressIndexV2FilterSuccess: true,
        getStressIndexV2FilterData:action.lifecycledash.getStressIndexV2FilterData
      };

      case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_V2_FILTER_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };






      case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FILTER_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FILTER_SUCCESS:
        return {
        ...state,
        loading: false,
        getHappinessIndexDriverFilterSuccess: true,
        getHappinessIndexDriverFilterData:action.lifecycledash.getHappinessIndexDriverFilterData
      };

      case lifecycledashConstants.LIFECYCLE_GET_HAPPINESS_INDEX_DRIVER_FILTER_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      case lifecycledashConstants.LIFECYCLE_GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_SUCCESS:
        return {
        ...state,
        loading: false,
        getEngagementIndexDriverFilterSuccess: true,
        getEngagementIndexDriverFilterData:action.lifecycledash.getEngagementIndexDriverFilterData
      };

      case lifecycledashConstants.LIFECYCLE_GET_ENAGAGEMENT_INDEX_DRIVER_FILTER_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_DRIVER_FILTER_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_DRIVER_FILTER_SUCCESS:
        return {
        ...state,
        loading: false,
        getStressIndexDriverFilterSuccess: true,
        getStressIndexDriverFilterData:action.lifecycledash.getStressIndexDriverFilterData
      };

      case lifecycledashConstants.LIFECYCLE_GET_STRESS_INDEX_DRIVER_FILTER_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




      case lifecycledashConstants.LIFECYCLE_GET_PARAMETER_LIBRARY_ALL_LIST_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_PARAMETER_LIBRARY_ALL_LIST_SUCCESS:
        return {
        ...state,
        loading: false,
        getParameterLibraryListSuccess: true,
        getParameterLibraryListData:action.lifecycledash.getParameterLibraryListData.list
      };

      case lifecycledashConstants.LIFECYCLE_GET_PARAMETER_LIBRARY_ALL_LIST_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




      case lifecycledashConstants.LIFECYCLE_CLUSTER_ANALYSIS_REQUEST:
        return {
        ...state,
        loading: true,
        clusterAnalysisMSG: false
      };

      case lifecycledashConstants.LIFECYCLE_CLUSTER_ANALYSIS_SUCCESS:
        return {
        ...state,
        loading: false,
        clusterAnalysisMSG: false,
        clusterAnalysisData:action.lifecycledash.clusterAnalysisData.list,
        clusterAnalysisCount:action.lifecycledash.clusterAnalysisData.count
      };

      case lifecycledashConstants.LIFECYCLE_CLUSTER_ANALYSIS_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error,
        clusterAnalysisError: action.error,
        clusterAnalysisMSG: true

      };


      case lifecycledashConstants.LIFECYCLE_GET_CLUSTER_EMP_INFO_LIST_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_CLUSTER_EMP_INFO_LIST_SUCCESS:
        return {
        ...state,
        loading: false,
        getClusterEmpInfoListSuccess: true,
        getClusterEmpInfoListData:action.lifecycledash.getClusterEmpInfoListData
      };

      case lifecycledashConstants.LIFECYCLE_GET_CLUSTER_EMP_INFO_LIST_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      case lifecycledashConstants.LIFECYCLE_TOPIC_MODELLING_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_TOPIC_MODELLING_SUCCESS:
        return {
        ...state,
        loading: false,
        topicModellingSuccess: true,
        topicModellingData:action.lifecycledash.topicModellingData
      };

      case lifecycledashConstants.LIFECYCLE_TOPIC_MODELLING_FAILURE:
        return {
        ...state,
        loading: false,      
        topicModellingError: action.error,
        error: action.error
      };





      

      case lifecycledashConstants.LIFECYCLE_CREATE_TOPIC_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_CREATE_TOPIC_SUCCESS:
        return {
        ...state,
        loading: false,
        createTopicSuccess: true
      };

      case lifecycledashConstants.LIFECYCLE_CREATE_TOPIC_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      
      case lifecycledashConstants.LIFECYCLE_UPDATE_TOPIC_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_UPDATE_TOPIC_SUCCESS:
        return {
        ...state,
        loading: false,
        updateTopicSuccess: true
      };

      case lifecycledashConstants.LIFECYCLE_UPDATE_TOPIC_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




      case lifecycledashConstants.LIFECYCLE_DELETE_TOPIC_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_DELETE_TOPIC_SUCCESS:
        return {
        ...state,
        loading: false,
        deleteTopicSuccess: true
      };

      case lifecycledashConstants.LIFECYCLE_DELETE_TOPIC_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      case lifecycledashConstants.LIFECYCLE_GET_TOPIC_LIST_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_TOPIC_LIST_SUCCESS:
        return {
        ...state,
        loading: false,
        getTopicListSuccess: true,
        getTopicListData:action.lifecycledash.getTopicListData.list,
        getTopicListTotal:action.lifecycledash.getTopicListData.total

      };

      case lifecycledashConstants.LIFECYCLE_GET_TOPIC_LIST_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      case lifecycledashConstants.LIFECYCLE_GET_ALL_KEYWORDS_TEXT_LIST_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_ALL_KEYWORDS_TEXT_LIST_SUCCESS:
        return {
        ...state,
        loading: false,
        getAllWordKeywordsTextSuccess: true,
        wordCloudTextData:action.lifecycledash.getAllWordKeywordsTextData,
        wordTextKeyWord:action.lifecycledash.getAllWordKeywordsTopic

        
      };

      case lifecycledashConstants.LIFECYCLE_GET_ALL_KEYWORDS_TEXT_LIST_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };






      case lifecycledashConstants.LIFECYCLE_GET_OVERALL_SCORE_BY_SURVEY_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_OVERALL_SCORE_BY_SURVEY_SUCCESS:
        return {
        ...state,
        loading: false,
        getOverallScoreBySurveyIdSuccess: true,
        getOverallScoreBySurveyIdData:action.lifecycledash.getOverallScoreBySurveyIdData.data

        
      };

      case lifecycledashConstants.LIFECYCLE_GET_OVERALL_SCORE_BY_SURVEY_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




      case lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_SCORES_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_SCORES_SUCCESS:
        return {
        ...state,
        loading: false,
        getAllManagerScoresSuccess: true,
        getAllManagerScoresData:action.lifecycledash.getAllManagerScoresData

      };

      case lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_SCORES_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




      case lifecycledashConstants.LIFECYCLE_CREATE_GOAL_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_CREATE_GOAL_SUCCESS:
        return {
        ...state,
        loading: false,
        createGoalSuccess: true,
        createGoalData:action.lifecycledash.createGoalData

      };

      case lifecycledashConstants.LIFECYCLE_CREATE_GOAL_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      
      case lifecycledashConstants.LIFECYCLE_GET_GOAL_LIST_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_GOAL_LIST_SUCCESS:
        return {
        ...state,
        loading: false,
        getGoalListSuccess: true,
        getGoalListData:action.lifecycledash.getGoalListData.list,
        getGoalListTotal:action.lifecycledash.getGoalListData.total


      };

      case lifecycledashConstants.LIFECYCLE_GET_GOAL_LIST_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



            
      case lifecycledashConstants.LIFECYCLE_UPDATE_GOAL_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_UPDATE_GOAL_SUCCESS:
        return {
        ...state,
        loading: false,
        updateGoalSuccess: true,
        updateGoalData:action.lifecycledash.updateGoalData

      };

      case lifecycledashConstants.LIFECYCLE_UPDATE_GOAL_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      case lifecycledashConstants.LIFECYCLE_DELETE_GOAL_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_DELETE_GOAL_SUCCESS:
        return {
        ...state,
        loading: false,
        deleteGoalSuccess: true,
        deleteGoalData:action.lifecycledash.deleteGoalData

      };

      case lifecycledashConstants.LIFECYCLE_DELETE_GOAL_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      case lifecycledashConstants.LIFECYCLE_CREATE_PLAN_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_CREATE_PLAN_SUCCESS:
        return {
        ...state,
        loading: false,
        createPlanSuccess: true,
        createPlanData:action.lifecycledash.createPlanData

      };

      case lifecycledashConstants.LIFECYCLE_CREATE_PLAN_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




      case lifecycledashConstants.LIFECYCLE_UPDATE_PLAN_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_UPDATE_PLAN_SUCCESS:
        return {
        ...state,
        loading: false,
        updatePlanSuccess: true,
        updatePlanData:action.lifecycledash.updatePlanData

      };

      case lifecycledashConstants.LIFECYCLE_UPDATE_PLAN_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error,
        errorUpdateMsg: action.error

      };


      case lifecycledashConstants.LIFECYCLE_DELETE_PLAN_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_DELETE_PLAN_SUCCESS:
        return {
        ...state,
        loading: false,
        deletePlanSuccess: true,
        deletePlanData:action.lifecycledash.deletePlanData

      };

      case lifecycledashConstants.LIFECYCLE_DELETE_PLAN_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };

      
      case lifecycledashConstants.LIFECYCLE_START_PLAN_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.LIFECYCLE_START_PLAN_SUCCESS:
        return {
        ...state,
        loading: false,
        closePlanSuccess: true,
        closePlanData:action.lifecycledash.closePlanData

      };

      case lifecycledashConstants.LIFECYCLE_START_PLAN_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      
      case lifecycledashConstants.LIFECYCLE_CLOSE_PLAN_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.LIFECYCLE_CLOSE_PLAN_SUCCESS:
        return {
        ...state,
        loading: false,
        closePlanSuccess: true,
        closePlanData:action.lifecycledash.closePlanData

      };

      case lifecycledashConstants.LIFECYCLE_CLOSE_PLAN_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      case lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_GOAL_PLAN_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_GOAL_PLAN_SUCCESS:
        return {
        ...state,
        loading: false,
        getAllManagerGoalPlanSuccess: true,
        getAllManagerGoalPlanData:action.lifecycledash.getAllManagerGoalPlanData.list,
        getAllManagerGoalPlanTotal:action.lifecycledash.getAllManagerGoalPlanTotal

      };

      case lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_GOAL_PLAN_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      case lifecycledashConstants.LIFECYCLE_GET_IDP_LAST_DATE_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.LIFECYCLE_GET_IDP_LAST_DATE_SUCCESS:
        return {
        ...state,
        loading: false,
        getIDPLastDateSuccess: true,
        getIDPLastDateData:action.lifecycledash.getIDPLastDateData

      };
      case lifecycledashConstants.LIFECYCLE_GET_IDP_LAST_DATE_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };

      case lifecycledashConstants.LIFECYCLE_EDIT_IDP_LAST_DATE_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.LIFECYCLE_EDIT_IDP_LAST_DATE_SUCCESS:
        return {
        ...state,
        loading: false,
        editIDPLastDateSuccess: true,
        editIDPLastDateData:action.lifecycledash.editIDPLastDateData

      };
      case lifecycledashConstants.LIFECYCLE_EDIT_IDP_LAST_DATE_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      case lifecycledashConstants.LIFECYCLE_GET_COMPANY_NAME_ONLY_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.LIFECYCLE_GET_COMPANY_NAME_ONLY_SUCCESS:
        return {
        ...state,
        loading: false,
        getCompanyNameOnlySuccess: true,
        getCompanyNameOnlyData:action.lifecycledash.getCompanyNameOnlyData

      };
      case lifecycledashConstants.LIFECYCLE_GET_COMPANY_NAME_ONLY_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      case lifecycledashConstants.LIFECYCLE_GET_MANAGER_DOWNLOAD_STATUS_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.LIFECYCLE_GET_MANAGER_DOWNLOAD_STATUS_SUCCESS:
        return {
        ...state,
        loading: false,
        managerDownloadStatusSuccess: true,

      };
      case lifecycledashConstants.LIFECYCLE_GET_MANAGER_DOWNLOAD_STATUS_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      
      case lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_DOWNLOAD_STATUS_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_DOWNLOAD_STATUS_SUCCESS:
        return {
        ...state,
        loading: false,
        getMngrReportDownloadStatusSuccess: true,
        getMngrReportDownloadStatusData:action.lifecycledash.getMngrReportDownloadStatusData.list,
        getMngrReportDownloadStatusTotal:action.lifecycledash.getMngrReportDownloadStatusData.total

      };
      case lifecycledashConstants.LIFECYCLE_GET_ALL_MANAGER_DOWNLOAD_STATUS_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




      
      case lifecycledashConstants.LIFECYCLE_GET_INDEX_BY_FILTER_ALL_STAGES_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.LIFECYCLE_GET_INDEX_BY_FILTER_ALL_STAGES_SUCCESS:
        return {
        ...state,
        loading: false,
        getIndexByFilterAllStagesSuccess: true,
        getIndexByFilterAllStagesData:action.lifecycledash.getIndexByFilterAllStagesData

      };
      case lifecycledashConstants.LIFECYCLE_GET_INDEX_BY_FILTER_ALL_STAGES_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




      
      case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MAP_REQUEST:
        return {
        ...state,
        loading: true
      };

      case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MAP_SUCCESS:
        return {
        ...state,
        loading: false,
        getQuestionMapSuccess: true,
        getQuestionMapData:action.lifecycledash.getQuestionMapData

      };

      case lifecycledashConstants.LIFECYCLE_GET_QUESTION_MAP_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      case lifecycledashConstants.LIFECYCLE_GET_QUESTION_CUSTOM_TEMPLATE_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.LIFECYCLE_GET_QUESTION_CUSTOM_TEMPLATE_SUCCESS:
        return {
        ...state,
        loading: false,
        getQuestionCustomTemplateSuccess: true,
        getQuestionCustomTemplateData:action.lifecycledash.getQuestionCustomTemplateData

      };
      case lifecycledashConstants.LIFECYCLE_GET_QUESTION_CUSTOM_TEMPLATE_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      

      case lifecycledashConstants.LIFECYCLE_GET_CAT_OVERALL_INDEX_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.LIFECYCLE_GET_CAT_OVERALL_INDEX_SUCCESS:
        return {
        ...state,
        loading: false,
        getCategoryOverallIndexSuccess: true,
        getCategoryOverallIndexData:action.lifecycledash.getCategoryOverallIndexData

      };
      case lifecycledashConstants.LIFECYCLE_GET_CAT_OVERALL_INDEX_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      

      case lifecycledashConstants.LIFECYCLE_GET_LIFECYCLE_LISTEN_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.LIFECYCLE_GET_LIFECYCLE_LISTEN_SUCCESS:
        return {
        ...state,
        loading: false,
        getLifecycleListenByIdSuccess: true,
        getLifecycleListenByIdData:action.lifecycledash.getLifecycleListenByIdData

      };
      case lifecycledashConstants.LIFECYCLE_GET_LIFECYCLE_LISTEN_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      

      

      case lifecycledashConstants.GET_QUES_LIST_SURVEY_ID_LIFECYCLE_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.GET_QUES_LIST_SURVEY_ID_LIFECYCLE_SUCCESS:
        return {
        ...state,
        loading: false,
        getQuestionListBySurveyIDLifecycleSuccess: true,
        getQuestionListBySurveyIDLifecycleData:action.lifecycledash.getQuestionListBySurveyIDLifecycleData,
        questionsList: action.lifecycledash.questionsList
      };
      case lifecycledashConstants.GET_QUES_LIST_SURVEY_ID_LIFECYCLE_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




            

      case lifecycledashConstants.GET_INDEX_TREND_SCORE_FILTER_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.GET_INDEX_TREND_SCORE_FILTER_SUCCESS:
        return {
        ...state,
        loading: false,
        getIndexTrendScoreFilterSuccess: true,
        getIndexTrendScoreFilterData:action.lifecycledash.getIndexTrendScoreFilterData

      };
      case lifecycledashConstants.GET_INDEX_TREND_SCORE_FILTER_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };

           

      case lifecycledashConstants.GET_INDEX_TREND_SCORE_OVERALL_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.GET_INDEX_TREND_SCORE_OVERALL_SUCCESS:
        return {
        ...state,
        loading: false,
        getIndexTrendScoreSuccess: true,
        getIndexTrendScoreData:action.lifecycledash.getIndexTrendScoreData

      };
      case lifecycledashConstants.GET_INDEX_TREND_SCORE_OVERALL_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };


      case lifecycledashConstants.GET_CATEGORY_BY_ID_REQUEST:
        return {
        ...state,
        loading: true
      };
      case lifecycledashConstants.GET_CATEGORY_BY_ID_SUCCESS:
        return {
        ...state,
        loading: false,
        getCategoryByIdSuccess: true,
        getCategoryByIdData:action.lifecycledash.getCategoryByIdData

      };
      case lifecycledashConstants.GET_CATEGORY_BY_ID_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



    default:
      return state
  }
}