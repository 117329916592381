import React from 'react';
import ReactEcharts from "echarts-for-react";


export default function BarDistribution(props) {
  let { DistributionData } = props;

  let List = ['ActivelyDisengaged', 'Passive', 'ModeratelyEngaged', 'HighlyEngaged'];

  let labelObj = {
    'ActivelyDisengaged': `Actively
    Disengaged`,

    'Passive': `Passive`,

    'ModeratelyEngaged': `Moderately 
    Engaged`,

    'HighlyEngaged': `Highly 
    Engaged`
  }

  let colorObj = {
    'ActivelyDisengaged': '#d12e2e',
    'Passive': '#f4ae34',
    'ModeratelyEngaged': '#a2e37d',
    'HighlyEngaged': '#00cc66'
  }

  // let DistObj = {}

  let ScoreList = []

  if (DistributionData) {
    if (List && List.length > 0) {
      List.forEach((item) => {
        ScoreList.push({
          "name": item === "Happy" ? "Happier" : item,
          "score": DistributionData && DistributionData[item] && parseFloat(DistributionData[item]) ? parseFloat(DistributionData[item]) : 0
        })
        // DistObj[item] = DistributionData[item]? DistributionData[item].toFixed(2) :0;
      });
    }
  }

  // ScoreList.sort((a,b)=>a.score - b.score);

  let data = [];
  let names = []
  if (ScoreList && ScoreList.length > 0) {
    ScoreList.forEach((item) => {
      data.push({
        value: item.score && item.score.toFixed(2) ? item.score.toFixed(2) : 0,
        itemStyle: {
          color: colorObj && colorObj[item.name] ? colorObj[item.name] : '#757575'
        }
      })
      names.push(item.name === "Happy" ? "Happier" : labelObj[item.name])
    });
  }


  //console.log('x---names: ',names)


  // //console.log('DistObj: ',DistObj)


  return (
    <>
      <div className="pt-1" style={{ width: '100%' }}>
        <ReactEcharts
          style={{ width: '100%', height: '300px' }}
          option={{
            tooltip: {
              trigger: 'item',
              formatter: function (a) {
                //console.log(a)
                return a.name + ': <b>' + a.value + '%</b>'
              }
            },
            xAxis: {
              type: 'value',
              max: 100

            },
            yAxis: {
              type: 'category',
              data: names

            },
            series: [{
              data: data,
              type: 'bar',
              showBackground: true,
              barWidth: '40px',
              orientation: 'horizontal'
            }],
            grid: {
              left: '5%',
              right: '1%',
              bottom: '12%',
              top: '3%',
              // containLabel: true
            },
          }}

        />
      </div>
      <div className="  w-full py-4 md:hidden block">
        <div className="space-y-4 ">
          <div className="flex flex-col items-center flex-shrink-0 gap-1.5 text-[#212121]">
            <span className="text-left w-full text-sm">Happiest</span>

            <div className="w-full bg-[#f5f5f5] h-8 flex flex-row justify-start">
              <div
                className="flex items-center justify-start h-8"
                style={{ width: "57%", backgroundColor: "rgb(229, 115, 115)" }}
              />
              <span className="flex items-center text-center p-2 text-[#212121] text-sm font-medium">
                57%
              </span>
            </div>
          </div>
          <div className="flex flex-col items-center flex-shrink-0 gap-1.5 text-[#212121]">
            <span className="text-left w-full text-sm">Happy</span>

            <div className="w-full bg-[#f5f5f5] h-8 flex flex-row justify-start">
              <div
                className="flex items-center justify-start h-8"
                style={{ width: "57%", backgroundColor: "rgb(229, 115, 115)" }}
              />
              <span className="flex items-center text-center p-2 text-[#212121] text-sm font-medium">
                57%
              </span>
            </div>
          </div>
          <div className="flex flex-col items-center flex-shrink-0 gap-1.5 text-[#212121]">
            <span className="text-left w-full text-sm">Moderate</span>

            <div className="w-full bg-[#f5f5f5] h-8 flex flex-row justify-start">
              <div
                className="flex items-center justify-start h-8"
                style={{ width: "57%", backgroundColor: "rgb(229, 115, 115)" }}
              />
              <span className="flex items-center text-center p-2 text-[#212121] text-sm font-medium">
                57%
              </span>
            </div>
          </div>
          <div className="flex flex-col items-center flex-shrink-0 gap-1.5 text-[#212121]">
            <span className="text-left w-full text-sm">Unhappy</span>

            <div className="w-full bg-[#f5f5f5] h-8 flex flex-row justify-start">
              <div
                className="flex items-center justify-start h-8"
                style={{ width: "57%", backgroundColor: "rgb(229, 115, 115)" }}
              />
              <span className="flex items-center text-center p-2 text-[#212121] text-sm font-medium">
                57%
              </span>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
