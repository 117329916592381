import React, { Component } from 'react';
import { connect } from 'react-redux';

class IndexKPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { ResponseAction } = this.props;




    return (
      <>
        <div className="grid  md:grid-cols-3 grid-cols-1  gap-4 ">
        {/* box 1 */}
        <div className=" bg-white flex border ">
          <div className="bg-blue-500 flex items-center"><span className="material-symbols-outlined text-white px-6" style={{fontSize: '40px'}}>send</span></div>
          <div className="text-center w-full py-6"><h1 className="text-3xl text-gray-800">{ResponseAction['assigned']}</h1><p className="lg:text-sm text-xs text-gray-500">Assigned Tasks</p></div>
        </div>
        {/* box 2 */}
        <div className=" bg-white flex border ">
          <div className="bg-yellow-300 flex items-center"><span className="material-symbols-outlined text-white px-6" style={{fontSize: '40px'}}>pending_actions</span></div>
          <div className="text-center w-full py-6"><h1 className="text-3xl text-gray-800">{ResponseAction['pending']}</h1><p className="lg:text-sm text-xs text-gray-500">Pending Tasks</p></div>
        </div>
        {/* box 3 */}
        <div className=" bg-white flex border ">
          <div className="bg-green-400 flex items-center"><span className="material-symbols-outlined text-white px-6" style={{fontSize: '40px'}}>beenhere</span></div>
          <div className="text-center w-full py-6"><h1 className="text-3xl text-gray-800">{ResponseAction['completed']}</h1><p className="lg:text-sm text-xs text-gray-500">Completed Tasks</p></div>
        </div>
      </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexKPI);
