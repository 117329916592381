import React, { Component } from "react";
// import EmpChart from './Chart/EmpChart';
// import BarDistribution from './Chart/BarDistribution';
import Header from "../Components/Header";

class Employee extends Component {
  render() {
    let {
      setPDF,
      getOrgIndexV2Data,

      getQuestionFavorabilityData,
      getQuestionFavorabilityAPI2Data,

      GetParameterLabel,
      surveyLogo,
      CountRestrict,
      showYearData,
      yearValue1,
      yearValue2
    } = this.props;



    function Score(question) {
      let count1 = question && question.count ? question.count : 0;
      let total1 = question && question.total ? question.total : 0;
      let percentageValue = (count1 * 100) / total1;
      return percentageValue !== "NA"
        ? percentageValue
          ? percentageValue.toFixed(2)
          : 0
        : "NA";
    }


    let QuestionList = []
    if (getQuestionFavorabilityData && getQuestionFavorabilityData.length > 0) {
      getQuestionFavorabilityData.forEach((question) => {

        let dimension = question && question._id && question._id.dimension ? (question._id.dimension) : "Any"
        let name = question && question._id && question._id.question ? question._id.question : "NA"
        let score = Score(question);

        let temp = {
          "dimension": dimension,
          "name": name,
          "score": score,
          "score2": 0
        }
        QuestionList.push(temp)
      })
    }

    if (getQuestionFavorabilityAPI2Data && getQuestionFavorabilityAPI2Data.length > 0) {
      getQuestionFavorabilityAPI2Data.forEach((question) => {

        let dimension = question && question._id && question._id.dimension ? (question._id.dimension) : "Any"
        let name = question && question._id && question._id.question ? question._id.question : "NA"
        let score = Score(question);

        let getIndexMatch = QuestionList.findIndex(prev => prev.name === name);
        if (getIndexMatch !== -1) {
          QuestionList[getIndexMatch]["score2"] = score;
        } else {
          let temp = {
            "dimension": dimension,
            "name": name,
            "score": 0,
            "score2": score
          }
          QuestionList.push(temp)
        }

      })
    }


    console.log("444 QuestionList----->", QuestionList)

    return (
      <div>
        <div className="pt-4">
          <Header surveyLogo={surveyLogo} />
          <div className>
            <div className="w-full px-8 py-6 capitalize ">
              <h1 className="text-2xl text-[#3D405B] font-semibold border-b border-[#DDDDDD] pb-4 capitalize">
                Top 10 Areas Of Strength For Highest Scoring Experiences
              </h1>
            </div>
          </div>
        </div>

        <main className="w-full px-8 mt-4"  >
          {true ? (
            <>
              {setPDF ? (
                <>
                  <table className="w-full border border-white" id="Strength">
                    <tbody className="">
                      <tr className="text-left text-[#3D405B] capitalize border-b border-white bg-[#E8F5E9] text-sm border-l border-t">
                        <th className="w-[15%] font-medium p-2 text-left border-r border-white">
                          Dimension
                        </th>
                        <th className="w-[50%] font-medium p-2 text-left border-r border-white">
                          Question
                        </th>
                        <th className="w-[35%] font-medium p-2 text-left border-r border-white">
                          Top 10
                        </th>
                      </tr>

                      {QuestionList &&
                        QuestionList.length > 0
                        ? QuestionList.map((question, index) => (
                          <>
                            <tr className="border-b border-white bg-[#F1F5F9] text-[#3D405B] text-sm border-l">
                              <td className="p-2 capitalize border-r border-white">
                                {GetParameterLabel(question.dimension)}
                              </td>
                              <td className="p-2 text-sm border-r border-white">
                                {question.name}
                              </td>
                              <td className="p-2 text-left border-white">
                                <div className="w-full space-y-0 border-white">

                                  {showYearData(yearValue1, 101) ?
                                    <div className="flex items-center w-full space-y-0.5">
                                      <div className="w-16">{yearValue1}</div>
                                      <div className="h-3 w-full mr-2 bg-[#CFD8DC] ">
                                        <div
                                          className="w-full h-3 bg-[#2196F3]"
                                          style={{
                                            background: "#2196F3",
                                            width: question.score + "%"
                                          }}
                                        />
                                      </div>
                                      <div className="w-20 text-sm font-medium">
                                        {question.score}
                                        %
                                      </div>
                                    </div>
                                    : null}

                                  {showYearData(yearValue2, 101) ?
                                    <div className="flex items-center w-full space-y-0.5">
                                      <div className="w-16">{yearValue2}</div>
                                      <div className="h-3 w-full mr-2 bg-[#CFD8DC]">
                                        <div
                                          className="w-full h-3 bg-[#9576CD]"
                                          style={{
                                            background: "#9576CD",
                                            width: question.score2 + "%"
                                          }}
                                        />
                                      </div>
                                      <div className="w-20 text-sm font-medium">
                                        {question.score2}
                                        %
                                      </div>
                                    </div>
                                    : null}

                                </div>
                              </td>
                            </tr>
                          </>
                        ))
                        : null}
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <table id="Strength">
                    <thead>
                      <tr>
                        <th
                          data-pptx-min-width="2.6"
                          style={{
                            width: "15%",
                            fontSize: 10,
                            background: "#e6e6e6",
                          }}
                        >
                          Dimension
                        </th>

                        <th
                          data-pptx-min-width="3.6"
                          style={{
                            width: "50%",
                            fontSize: 10,
                            background: "#e6e6e6",
                          }}
                        >
                          Question
                        </th>
                        <th
                          data-pptx-min-width="0.8"
                          style={{
                            width: "10%",
                            fontSize: 10,
                            background: "#e6e6e6",
                          }}
                        >
                          Top 10
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getQuestionFavorabilityData &&
                        getQuestionFavorabilityData.length > 0
                        ? getQuestionFavorabilityData.map((question, index) => (
                          <tr>
                            <td
                              data-pptx-min-width="2.6"
                              style={{
                                width: "15%",
                                fontSize: 10,
                                background:
                                  index % 2 === 0 ? "#dbefff" : "#ffffff",
                              }}
                            >
                              {question &&
                                question._id &&
                                question._id.dimension
                                ? GetParameterLabel(question._id.dimension)
                                : "Any"}
                            </td>
                            <td
                              data-pptx-min-width="3.6"
                              style={{
                                width: "50%",
                                fontSize: 10,
                                background:
                                  index % 2 === 0 ? "#dbefff" : "#ffffff",
                              }}
                            >
                              {" "}
                              {question &&
                                question._id &&
                                question._id.question
                                ? question._id.question
                                : "NA"}
                            </td>

                            <td
                              data-pptx-min-width="0.8"
                              style={{
                                textAlign: "center",
                                width: "10%",
                                fontSize: 10,
                                background:
                                  index % 2 === 0 ? "#dbefff" : "#ffffff",
                              }}
                            >
                              {Score(question) + "%"}
                            </td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </table>
                </>
              )}
            </>
          ) : (
            <div className="p-4 text-3xl text-center text-gray-400">
              Data not available!
            </div>
          )}
        </main>
      </div>
    );
  }
}
export default Employee;
