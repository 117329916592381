import React from "react";
import moment from "moment";
import AssessmentBottom5 from "./AssessmentBottom5";

export default function AssessmentDetails(props) {
  let {
    getAssessmentReportByEmpIDData,
    crrSelectedEmp,
    EditDetailsFn,
    EditButtonFn,
    hindiPDFstatic
  } = props;

  function getModifiedContent(text) {
    let text2 = "";
    if (text) {
      text2 = text
        .replace(/<\/?li[^>]*>/g, "@")
        .replace(/<\/?ul[^>]*>/g, "")
        .replace(/<\/?span[^>]*>/g, "")
        .replace(/<\/?p[^>]*>/g, "")
        .replace(/&nbsp;/g, "")
        .replace(/<\/?br[^>]*>/g, "")
        .replace(/<\/?b[^>]*>/g, "");
    }
    return text2 ? text2 : text;
  }

  function getBulletPoints(element, field) {
    let descX =
      element && element.details && element.details[field]
        ? element.details[field]
        : "";
    let descNew = "";
    if (descX) {
      let temp = getModifiedContent(descX);
      //console.log("descX",descX)
      //console.log("temp",temp)
      descNew = temp.split("@").filter((prev) => prev !== "");
      // //console.log("descNew",descNew)
    }
    return descNew;
  }

  function getTitle(element, type) {
    let itemName =
      type === 1
        ? "How to use - Talent every day?"
        : "How to deal with a person who has - Talent?";
    let final = itemName.replace("-", element.dimension.trim());
    //console.log("final--->", final.toString());
    return final.toString();
  }

  const cirleColor = (element) => {
    return (
      <span
        style={{
          background:
            element && element.colorCode ? element.colorCode : "#DDDDDD",
        }}
        className={"w-5 h-5 rounded-full "}
      />
    );
  };

  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }

  function chunkList(array) {
    const chunkSize = 10;
    let list = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      list.push(chunk);
    }
    //console.log("list--->", list);
    return list;
  }

  function BoldText(text) {
    return text ? '<b>' + text + '</b>' : text
  }

  function checkForGeneralship() {
    return "school_"
  }


  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }



  console.log("getAssessmentReportByEmpIDData-XXXXXX--->", getAssessmentReportByEmpIDData);



  return (
    <>
      {getAssessmentReportByEmpIDData &&
        getAssessmentReportByEmpIDData.length > 0
        ? getAssessmentReportByEmpIDData.map((element, index1) => (
          <>
            {(index1 + 1) === getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 ?
              <div ref={hindiPDFstatic[(10) + (index1 * getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1)]}>
                <AssessmentBottom5
                  crrSelectedEmp={crrSelectedEmp}
                  EditButtonFn={EditButtonFn}
                />
              </div>
              : null}

            {/* {(!(getTopBottomIndex(getAssessmentReportByEmpIDData,"mid").range1 <= index1 + 1 && index1 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData,"mid").range2))?( */}

            {(index1 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1) || true ? (
              <>
                <main ref={hindiPDFstatic[(11) + (index1 * getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1)]} className="bg-white p-6 px-6 space-y-6 page-break bg-no-repeat relative h-full">
                  <div className="space-y-6 page-break ">

                    <div className="space-y-6">
                      <div className="flex items-center w-full justify-between border-b py-4 ">
                        <h1 className="text-3xl font-medium text-[#3D405B]">
                          {EditButtonFn(
                            "TalentAssessmentReport",
                            "Teen TalentPlusStrengths Assessment Report"
                          )}
                        </h1>
                        <p className="text-base font-medium">
                          {crrSelectedEmp && crrSelectedEmp.name
                            ? crrSelectedEmp.name
                            : ""}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex space-x-2 border p-2 rounded-full">
                          {getAssessmentReportByEmpIDData &&
                            getAssessmentReportByEmpIDData.length > 0
                            ? getAssessmentReportByEmpIDData.map(
                              (element2, index2) =>
                                index2 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1 ? (
                                  index1 === index2 ? (
                                    cirleColor(element2)
                                  ) : (
                                    <span className="w-5 h-5 rounded-full bg-[#DDDDDD]" />
                                  )
                                ) : null
                            )
                            : null}
                        </div>

                        <div className="flex space-x-2 border p-2 rounded-full">
                          {getAssessmentReportByEmpIDData &&
                            getAssessmentReportByEmpIDData.length > 0
                            ? getAssessmentReportByEmpIDData.map(
                              (element2, index2) =>
                                getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1 <= index2 + 1 && index2 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2 ? (
                                  index1 === index2 ? (
                                    cirleColor(element2)
                                  ) : (
                                    <span className="w-5 h-5 rounded-full bg-[#DDDDDD]" />
                                  )
                                ) : null
                            )
                            : null}
                        </div>

                        <div className="flex space-x-2 border p-2 rounded-full">
                          {getAssessmentReportByEmpIDData &&
                            getAssessmentReportByEmpIDData.length > 0
                            ? getAssessmentReportByEmpIDData.map(
                              (element2, index2) =>
                                getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 <= index2 + 1 ? (
                                  index1 === index2 ? (
                                    cirleColor(element2)
                                  ) : (
                                    <span className="w-5 h-5 rounded-full bg-[#DDDDDD]" />
                                  )
                                ) : null
                            )
                            : null}
                        </div>
                      </div>

                      <div
                        style={{
                          background: hexToRgbA(
                            element && element.colorCode
                              ? element.colorCode
                              : "#DDDDDD",
                            "0.20"
                          ),
                        }}
                        className={
                          "rounded-2xl p-6 py-2 flex justify-between  items-center  "
                        }
                      >
                        <div className="space-y-2">
                          <button
                            style={{
                              background:
                                element && element.colorCode
                                  ? element.colorCode
                                  : "#DDDDDD",
                            }}
                            className={
                              " px-6 py-2 text-base font-medium flex text-white uppercase tracking-wide rounded-full "
                            }
                          >
                            {/* {element.bucket} */}
                            {EditDetailsFn(element, "bucket", element.bucket)}
                          </button>
                          <h1 className="text-2xl font-medium text-[#3D405B] pl-4 ">
                            {(index1 + 1 > getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2
                              ? (index1 + 1 - getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2).toString()
                              : (index1 + 1).toString()) + ". "}
                            {EditDetailsFn(
                              element,
                              "dimension",
                              element.dimension
                            )}
                          </h1>
                        </div>

                        <img
                          src={
                            "/img/assessment/circleicons/" + checkForGeneralship() +
                            ((element.dimension.replace(' ','_')).toLowerCase()) +
                            ".svg"
                          }
                          className="w-28 h-28"
                        />
                      </div>

                    </div>

                    <div className="w-full space-y-6">
                      <div className="w-full space-y-4">
                        <p className="text-base font-medium">
                          {EditDetailsFn(
                            element,
                            "desc1",
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  BoldText(element &&
                                    element.details &&
                                    element.details.desc1
                                    ? element.details.desc1
                                    : ""),
                              }}
                            />
                          )}
                        </p>
                        <p className="text-base">
                          {/* <div
                        dangerouslySetInnerHTML={{
                          __html:
                            element && element.details && element.details.desc2
                              ? element.details.desc2
                              : "",
                        }}
                      /> */}
                          <div>
                            {EditDetailsFn(
                              element,
                              "desc2",
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    element &&
                                      element.details &&
                                      element.details.desc2
                                      ? element.details.desc2
                                      : "",
                                }}
                              />
                            )}
                          </div>
                        </p>
                      </div>
                    </div>

                    <img
                      src={
                        `/img/assessment/idea/lightwatermark/` + checkForGeneralship() +
                        ((element.dimension.replace(' ','_')).toLowerCase()) +
                        `_watermark.svg`
                      }
                      className="w-[300px] h-auto absolute bottom-0 right-0"
                    />
                  </div></main>

                {getBulletPoints(element, "desc3") &&
                  getBulletPoints(element, "desc3").length > 0
                  ? chunkList(getBulletPoints(element, "desc3")).map(
                    (textList, indexX) => (
                      <>
                        <main ref={hindiPDFstatic[(12 + indexX) + (index1 * getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1)]}
                          className="bg-white p-6 px-6 space-y-6 page-break relative  "
                        // style={{ backgroundImage: `url("/img/assessment/idea/watermark/`+element.bucket.toLowerCase()+`_watermark.svg")`, backgroundPosition: 'bottom right 100px' }}
                        >
                          <div className="">
                            {element &&
                              element.details &&
                              element.details.desc3 ? (
                              <>
                                <div className="w-full space-y-1 page-break">
                                  <h1 className="text-3xl font-light text-[#3D405B]">
                                    {/* <b>{getTitle(element, 1)}</b> */}
                                    <b>
                                      {EditButtonFn(
                                        getTitle(element, 1),
                                        getTitle(element, 1)
                                      )}
                                    </b>
                                  </h1>
                                </div>

                                <div className="text-sm space-y-2 py-4">
                                  {textList && textList.length > 0
                                    ? textList.map((text, index6) => (
                                      <>
                                        <div
                                          style={{
                                            background: hexToRgbA(
                                              element && element.colorCode
                                                ? element.colorCode
                                                : "#DDDDDD",
                                              "0.08"
                                            ),
                                          }}
                                          className={
                                            "rounded-[3rem] rounded-bl-none pl-6 py-4 flex space-x-4 justify-left  items-center"
                                          }
                                        >
                                          <img
                                            src={
                                              "/img/assessment/circleicons/" + checkForGeneralship() +
                                              ((element.dimension.replace(' ','_')).toLowerCase()) +
                                              ".svg"
                                            }
                                            className="w-20 h-20"
                                          />
                                          {/* <p className="mx-2 text-gray-900">
                                            {text}
                                          </p> */}
                                          <div className="pl-2">
                                            {EditDetailsFn(
                                              element,
                                              "desc3" + (index6 + 1),
                                              text
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ))
                                    : null}
                                </div>
                              </>
                            ) : null}
                            {/* <p>Copyright © 2024, HappyPlus, Inc. All rights reserved</p> */}
                            <img
                              src={
                                `/img/assessment/idea/lightwatermark/` + checkForGeneralship() +
                                ((element.dimension.replace(' ','_')).toLowerCase()) +
                                `_watermark.svg`
                              }
                              className="w-[300px] h-auto absolute bottom-0 right-0"
                            />

                          </div>
                        </main>
                      </>
                    )
                  )
                  : null}

                <div ref={hindiPDFstatic[(14) + (index1 * getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1)]}>
                  <main
                    className="bg-white p-6 px-6 space-y-6 page-break relative  "
                  // style={{ backgroundImage: `url("/img/assessment/idea/watermark/`+element.bucket.toLowerCase()+`_watermark.svg")`, backgroundPosition: 'bottom right 100px' }}
                  >
                    {element && element.details && element.details.desc4 ? (
                      <>
                        <div className="">

                          <div className={element.dimension === "Coach" ? "w-full space-y-1 " : "w-full space-y-1 page-break"}>
                            <h1 className="text-3xl font-light text-[#3D405B]">
                              {/* <b>What needs to be looked out for?</b> */}
                              <b>
                                {EditButtonFn(
                                  "What needs to be looked out for_Possible Blind Spots?",
                                  "What needs to be looked out for (Possible Blind Spots)?"
                                )}
                              </b>
                            </h1>
                          </div>

                          <div className="text-sm  grid grid-cols-1 gap-2 py-4">
                            {getBulletPoints(element, "desc4") &&
                              getBulletPoints(element, "desc4").length > 0
                              ? getBulletPoints(element, "desc4").map(
                                (text, index) =>
                                  index % 2 === 0 ? (
                                    <div
                                      style={{
                                        borderColor: "#3D405B",
                                        // element && element.colorCode
                                        //   ? element.colorCode
                                        //   : "#DDDDDD",
                                      }}
                                      className={
                                        "border-2 rounded-tl-3xl rounded-r-3xl  p-4 flex space-x-4 justify-start items-center  "
                                      }
                                    >
                                      <img
                                        src={"/img/right_arrow.svg"}
                                        className="w-14 h-14 flex-shrink-0"
                                      />
                                      <p
                                        style={{
                                          color: "#3D405B",
                                          // element && element.colorCode
                                          //   ? element.colorCode
                                          //   : "#DDDDDD",
                                        }}
                                        className={"mx-2 "}
                                      >
                                        {/* {text} */}
                                        {EditDetailsFn(
                                          element,
                                          "desc4" + (index + 1),
                                          text
                                        )}
                                      </p>
                                    </div>
                                  ) : (
                                    //odd
                                    <div
                                      style={{
                                        borderColor: "#3D405B",
                                        // element && element.colorCode
                                        //   ? element.colorCode
                                        //   : "#DDDDDD",
                                      }}
                                      className={
                                        "border-2 rounded-tl-3xl rounded-r-3xl  p-4 flex space-x-4 justify-start items-center  "
                                      }
                                    >
                                      <img
                                        src={"/img/right_arrow.svg"}
                                        className="w-14 h-14 flex-shrink-0"
                                      />
                                      <p
                                        style={{
                                          color: "#3D405B",
                                          // element && element.colorCode
                                          //   ? element.colorCode
                                          //   : "#DDDDDD",
                                        }}
                                        className={"mx-2 "}
                                      >
                                        {/* {text} */}
                                        {EditDetailsFn(
                                          element,
                                          "desc4" + (index + 1),
                                          text
                                        )}
                                      </p>
                                    </div>
                                  )
                              )
                              : null}
                          </div>
                        </div>
                      </>
                    ) : null}
                    {/* <p>Copyright © 2024, HappyPlus, Inc. All rights reserved</p> */}
                    <img
                      src={
                        `/img/assessment/idea/lightwatermark/` + checkForGeneralship() +
                        ((element.dimension.replace(' ','_')).toLowerCase()) +
                        `_watermark.svg`
                      }
                      className="w-[300px] h-auto absolute bottom-0 right-0"
                    />
                  </main>

                  {getBulletPoints(element, "desc5") &&
                    getBulletPoints(element, "desc5").length > 0
                    ? chunkList(getBulletPoints(element, "desc5")).map(
                      (textList, index5) => (
                        <>
                          <main
                            className="bg-white p-2 px-6 space-y-6 h-full relative"
                          // style={{ backgroundImage: `url("/img/assessment/idea/watermark/`+element.bucket.toLowerCase()+`_watermark.svg")`, backgroundPosition: 'bottom right 100px' }}
                          >
                            <div className="">

                              {element &&
                                element.details &&
                                element.details.desc5 ? (
                                <>
                                  <div className="w-full space-y-1 ">
                                    <h1 className="text-3xl font-light text-[#3D405B]">
                                      {/* <b>{getTitle(element, 2)}</b> */}
                                      <b>
                                        {EditButtonFn(
                                          getTitle(element, 2),
                                          getTitle(element, 2)
                                        )}
                                      </b>
                                    </h1>
                                  </div>

                                  <div className="text-sm space-y-4 py-4">
                                    {textList && textList.length > 0
                                      ? textList.map((text, index6) => (
                                        <>
                                          <div
                                            style={{
                                              background: hexToRgbA(
                                                element && element.colorCode
                                                  ? element.colorCode
                                                  : "#DDDDDD",
                                                "0.08"
                                              ),
                                            }}
                                            className={
                                              "rounded-[3rem] rounded-bl-none pl-6 py-4 flex space-x-4 justify-left  items-center"
                                            }
                                          >
                                            <img
                                              src={
                                                "/img/assessment/idea/" + checkForGeneralship() +
                                                element.bucket.toLowerCase() +
                                                ".svg"
                                              }
                                              className="w-14 h-14 flex-shrink-0"
                                            />
                                            {/* <p className="mx-2">{text}</p> */}
                                            <div>
                                              {EditDetailsFn(
                                                element,
                                                "desc5" + (index6 + 1),
                                                text
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ))
                                      : null}
                                  </div>
                                </>
                              ) : null}
                              {/* <p>Copyright © 2024, HappyPlus, Inc. All rights reserved</p> */}
                              <img
                                src={
                                  `/img/assessment/idea/watermark/` + checkForGeneralship() +
                                  element.bucket.toLowerCase() +
                                  `_watermark.svg`
                                }
                                className="w-[300px] h-auto absolute bottom-0 right-0"
                              />
                            </div>
                          </main>
                        </>
                      )
                    )
                    : null}

                </div>


              </>
            ) : null}
          </>
        ))
        : null}
    </>
  );
}
