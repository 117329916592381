import React from "react";
export default function ConfirmMail(props) {
  let { crrCategoryType, handleConfirmMailClose, surveyFormData, handleConfirmMailView } = props;
  return (
    <>
      <div className="bg-black bg-opacity-40 fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full flex items-center justify-center md:p-0 p-4" >



        <div className="xl:w-4/12 lg:w-4/6 w-5/6  mx-auto bg-white rounded-md mb-20  ">
          <div className="flex items-center justify-between p-4 border-b"> <h1 className="text-lg p-1 font-semibold capitalize">
            {surveyFormData && surveyFormData.name ? surveyFormData.name : ""}
          </h1>
            <span onClick={() => handleConfirmMailClose(false)} className="
              material-symbols-outlined bg-gray-50 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150">close</span>
          </div>
          <div className="p-4 space-y-4">
            <img alt="img" src="img/empty_survey.png" className="mx-auto w-36" />
            {crrCategoryType===2?
            <h2 className=" text-md text-gray-600  text-center">Please check Live Results for insights!</h2>
            :
            <h2 className=" text-md text-gray-600  text-center">Dashboard is not yet created, Please check Live Results for insights!</h2>
            }
          </div>

          <div className="flex justify-center space-x-4 items-center p-4 ">
            <button onClick={() => handleConfirmMailView(true)} className="cursor-pointer bg-blue-600 rounded-md text-white p-2 px-6 ">Live Results</button>
          </div>
        </div>        

      </div>
    </>
  );
}
