import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../_helpers';

//api call
import { userActions, dashboardActions, generalActions, questionsActions, happinessActions } from '../../_actions';

//libraries
import LoadingOverlay from 'react-loading-overlay';

//Layout

import Header from '../CommonComponents/DashboardHeader/Header';
import Sidebar from "../Overview/Sidebar/Sidebar";


import Overview from './Overview//Overview';


class HappinessDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IndexNo: 0,
      CurrentSurveyName: '',
      CurrentSurveyId: this.props.match.params.id,
      // "5fa04d316ede0f0336066cce"
      showAllQuestions: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      ItemSaveData: '',
      cardType: '',
      currentQuestionId: '',
      currentQuestionTemplate: '',

      itemSurveyReport: false,
      itemOverview: false,
      itemAllQuestions: false,

      showAllQues: false,


      showOverview: true,
      showDetailScore: false,

      levelList: [
        {
          "label": "Location",
          "name": "Location"
        },
        {
          "label": "Gender",
          "name": "gender"
        },
        {
          "label": "Department",
          "name": "Department"
        },
        {
          "label": "Country",
          "name": "country"
        }
      ],
      showSidebar: true,


    }
  }
  componentDidMount() {

    this.props.dispatch(dashboardActions.getClientProfile());
    this.props.dispatch(dashboardActions.getCompanyNameOnly());

    this.props.dispatch(happinessActions.getHappyPlusEmpRespondants());


    this.props.dispatch(happinessActions.getHappyPlusOrgHappiness());


    let data = {
      "levelList": this.state.levelList.map((element) => (element.name))
    }
    this.props.dispatch(happinessActions.getHappyPlusFilterDMG(data));


  }



  onOverview = () => {
    this.setState({
      showOverview: true,
      showAllQuestions: false,
      showDetailScore: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showQuesCard: false,
      CurrentSurveyId: this.state.CurrentSurveyId
    });
    this.props.dispatch(generalActions.getQuestionOthersResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [1, 5, 6, 11] }));

  }


  onCardClick = (cardType, item, index, check) => {

    if (check) {
      let { dashboard } = this.props;
      let { EmpFilterData } = dashboard;
      if (EmpFilterData && EmpFilterData.length > 0) {
        this.handleDemographicFilter(EmpFilterData[0].name);
      }
      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showDetailScore: true,
        showTextAnalysis: false,
        showAdvanceInsight: false,
        showQuesCard: false,
        IndexNo: index,
        cardType: cardType,
        ItemSaveData: item
      });
    }

  }

  handleSidebarClick = (isIndex, data, index, check) => {
    if (isIndex) {
      this.onCardClick('index', data, index, check);
    }
    else {
      this.onOverview()
    }
  }



  handleSidebarExtra = (type) => {
    if (type === 1) {
      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showDetailScore: false,
        showTextAnalysis: true,
        showAdvanceInsight: false,
        showQuesCard: false,
        IndexNo: 0
      });

      let data2 = {
        "surveyId": this.state.CurrentSurveyId
      }
      this.props.dispatch(dashboardActions.getTextQuestion(data2, 1));
    }
    else if (type == 2) {
      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showDetailScore: false,
        showTextAnalysis: false,
        showAdvanceInsight: true,
        showQuesCard: false,
        IndexNo: 0
      });

      let data2 = {
        "surveyId": this.state.CurrentSurveyId
      }



    }

  }


  //------------------------------------------------------------------------------------------------------------------------


  overviewAPI = () => {
    this.props.dispatch(generalActions.getQuestionOthersResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [1, 5, 6, 11] }));
  }

  handleLogout = () => {
    this.props.dispatch(userActions.logoutSession());
  }

  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });


  }

  routeLink = (link) => {
    this.props.history.push('/app/' + link);
    this.setState({ CrrRoute: link });
  }

  handleSidebar = (check) => {
    this.setState({ showSidebar: check })
  }
  handleMobileSidebar = (check) => {
    this.setState({ showMobileSidebar: check })
    this.setState({ showSidebar: check })
  }


  onDetailPage=()=>{
    this.props.dispatch(happinessActions.getHappyPlusOrgHappiness({}));
  }

  onOverviewFromHeader = () => {
    this.props.history.goBack()
  }


  render() {

    let { general, dashboard, survey, questions, happiness } = this.props;


    let { getHappyPlusEmpRespondantsData, getHappyPlusOrgHappinessData, getHappyPlusOrgHappinessDMGWiseData, getHappyPlusFilterDMGData } = happiness;

    let respondants = getHappyPlusEmpRespondantsData && getHappyPlusEmpRespondantsData[0] ? getHappyPlusEmpRespondantsData : {}


    let { getSurveyData } = survey;
    let { questionsList } = questions;


    let { ClientProfileData,
      getRespondantDetailsData,
      getCompanyNameOnlyData

    } = dashboard;
    let { getQuestionOthersResultData, getQuestionMatrixResultData, SurveyList, getMeanScoreQwiseData, getDashboardCardData, getQuestionDistOverallData } = general;

    let { CurrentSurveyId, showOverview, showSidebar, } = this.state;

    let loading = dashboard.loading || general.loading || happiness.loading;
    let OverviewList = [];
    let SumMean = 0;
    let OverallMean = 0;
    let total = 0;

    if (getDashboardCardData && getDashboardCardData.length > 0) {
      getDashboardCardData.forEach((item, index) => {
        if (item && item._id) {
          SumMean += parseFloat(item && item.mean ? item.mean : 0.0);
          total += 1;
          OverviewList.push({
            "id": item._id,
            "name": item.parameter && item.parameter.length > 0 && item.parameter[0] && item.parameter[0].name ? item.parameter[0].name : "NaN",
            "mean": (typeof item !== 'string' || item instanceof Number) && item && item.mean ? item.mean.toFixed(2) : 'NaN',
            "color": '#757575'
          });
        }
      });
      OverallMean = (SumMean / total);

    }
    //////console.log("OverallMean",OverallMean)
    //////console.log("SumMean",SumMean)
    //////console.log("total",total)
    //////console.log("getDashboardCardData",getDashboardCardData)


    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageactionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }

    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    //console.log('moduleAccess: ',moduleAccess)



    let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text='Loading Data...'
          className="loader"
        >
          <div className="h-screen overflow-hidden bg-gray-50">
            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              props={this.props}
              companyName={
                getCompanyNameOnlyData && getCompanyNameOnlyData.name
                  ? getCompanyNameOnlyData.name
                  : ""
              }
              sidebarToggle={this.sidebarToggle}
              onOverviewFromHeader={this.onOverviewFromHeader}
            />

            <div className="flex w-full" style={{ height: "calc(100% - 2rem)" }}>
              <Sidebar
                onOverview={this.onOverview}
                showSidebar={showSidebar}
                handleSidebar={this.handleSidebar}
                accessAllow={accessAllow}
                routeLink={this.routeLink}
                showMobileSidebar={this.state.showMobileSidebar}
                sidebarToggle={this.sidebarToggle}

              />
              <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto ">






                {accessAllow("dashboard") ?
                  <>


                    {showOverview ?
                      <Overview
                        CurrentSurveyId={CurrentSurveyId}
                        SurveyList={SurveyList}
                        EmpFilterData={this.state.levelList}
                        OverviewList={OverviewList}
                        OverallMean={OverallMean}
                        onCardClick={this.onCardClick}
                        getMeanScoreQwiseData={getMeanScoreQwiseData}
                        getRespondantDetailsData={getRespondantDetailsData}
                        getQuestionDistOverallData={getQuestionDistOverallData}

                        getQuestionMatrixResultData={getQuestionMatrixResultData}
                        questionsList={questionsList}

                        getQuestionOthersResultData={getQuestionOthersResultData}


                        onOthersCardClick={this.onOthersCardClick}

                        getSurveyData={getSurveyData}
                        overviewAPI={this.overviewAPI}
                        handleMatrixDetails={this.handleMatrixDetails}
                        handleOverViewRoute={this.handleOverViewRoute}

                        storeInnerRoute={this.storeInnerRoute}

                        onAllQuestions={this.onAllQuestions}
                        onAllMatrixQuestions={this.onAllMatrixQuestions}





                        getIndexFilterData={getHappyPlusFilterDMGData}
                        respondants={respondants}
                        getHappyPlusOrgHappinessData={getHappyPlusOrgHappinessData}
                        onDetailPage={this.onDetailPage}
                      />
                      : null}






                  </>
                  : null}
              </main>
            </div>

            <div id="sidebar-btn" className="fixed z-30 flex items-center justify-center w-10 h-10 text-gray-900 bg-white rounded-full shadow-md hover:bg-blue-500 hover:text-white bottom-8 right-8 lg:hidden">
              <span className="material-symbols-outlined">view_quilt</span>
            </div>
          </div>





        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, general, dashboard, survey, questions, happiness } = state;
  return {
    loggingIn,
    users,
    general,
    dashboard,
    survey,
    questions,
    happiness
  };
}
export default connect(mapStateToProps)(HappinessDashboard);
