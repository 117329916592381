import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let { item } = this.props;


    function getPercFn(row, details, index, feedback) {
      let score = 0;
      if (details && details.length > 0) {
        let getIndex3 = details.findIndex(prev => prev.answerText === row);
        if (getIndex3 !== -1) {
          let innerX = {
            count: details[getIndex3]["count"]
          }
          let total = 0;
          details.forEach(element => {
            total += element.count;
          });


          console.log("feedback---------->", feedback)
          console.log("row---------->", row)
          score = total > 0 ? (parseFloat((Number(innerX.count) * 100) / total)).toFixed(2) : 0;
          console.log("score---------->", score)
        }
      }
      return score
    }




    let rowList = [];

    if (item?.list?.length > 0) {
      item.list.forEach(inner2 => {
        if (inner2?.details?.length > 0) {
          inner2.details.forEach(inner3 => {
            if (inner3?.answerText) {
              rowList.push(inner3.answerText);
            }
          });
        }
      });
      // Remove duplicates using a Set and sort the list
      rowList = [...new Set(rowList)].sort((a, b) => a - b);
    }


    // const menuOrder = [
    //   'Option A','Option B','Option C','Option D',
    //   'Yes','No'
    // ];



    // let alphaAnswerTemplate = [9]
    // if(optionList && optionList.length>0){
    //   if(alphaAnswerTemplate.includes(templateId)){
    //     optionList.sort(
    //       (a, b) => a.charCodeAt(0) - b.charCodeAt(0)
    //     );
    //   }else{
    //     optionList.sort((a, b) => menuOrder.indexOf(a) - menuOrder.indexOf(b));
    //   }
    // }


    return (
      <>

        <div className='space-y-4 bg-white'>
          <div className='bg-[#f9f9f9] rounded'>
            <div class="space-y-4 text-sm text-slate-800">
              <table class="w-full border-collapse">
                <thead class="text-sm  capitalize bg-[#2196F3]/20">
                  <tr class="border-b-2 border-white">
                    <th class="text-[#212121] font-medium p-4 py-3 text-sm text-left border-r-2 border-white">{"Demographic"}</th>
                    {rowList && rowList.length > 0 ?
                      rowList.map((row, index) =>
                        <th key={index} class="text-[#212121] font-medium p-4 py-3 text-sm text-center border-r-2 border-white">{row + " (%)"}</th>
                      ) : null}
                  </tr>
                </thead>
                <tbody class="divide-y-2 divide-white ">
                  {item && item.list && item.list.length > 0 ?
                    item.list.map((ele, index) =>
                      <tr key={index} class="even:bg-gray-100 odd:bg-gray-50">
                        <td class="p-2 px-4 border-r-2 border-white"><p class="font-medium">{ele && ele.feedback ? ele.feedback : ""}</p></td>
                        {rowList && rowList.length > 0 ?
                          rowList.map((inner) =>
                            <td class="p-2 px-4 text-center border-r-2 border-white">{getPercFn(inner, ele.details, index, ele.feedback)}</td>
                          ) : null}
                      </tr>
                    ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
