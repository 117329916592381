import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionScore from './Charts/QuestionScore';
import QuestionStack from './Charts/QuestionStack';
import Filter from './Filter';

class ResponseDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue:'none',
      currFilterName:'none'
    }
  }
  componentDidMount() {

  }

  handleFilterSave=(name)=>{
    this.setState({currFilterName:name});
  }

  handleFilterValueSave=(name,value)=>{
    this.setState({currFilterValue:value});
    let { handleResponseDistributionFilter } = this.props;
    if(name !=='none' && value !=='none'){
      handleResponseDistributionFilter(name,value)
    }
  }

  render() {
    let { getIndexFilterData, cardType,ItemSaveData,getQuestionDistOverallData, getQuestionDistData,EmpFilterData,handleDemographicOverallQFilter, getMeanScoreQwiseData } = this.props;
    let { currFilterName, currFilterValue } = this.state;

    // //console.log('getQuestionDistData:',getQuestionDistData);
    // //console.log('getMeanScoreQwiseData:',getMeanScoreQwiseData);


      let QuestionList = []

      let NewData = []
      let totalGrand = 0;

      //---------------------------------------overall-------------------------------------------------------------


      if(getQuestionDistOverallData && getQuestionDistOverallData.length>0){
        getQuestionDistOverallData.forEach((Ques)=>{
              
                    if(Ques && Ques.total && Ques.total.length>0){
                      Ques.total.forEach((option)=>{
                        let ansCount = option.count;
                        let ansText = option&&option._id&&option._id.answerText?option._id.answerText:"";
                        if(ansText){
                          let getIndex = NewData.findIndex(prev=>prev.name===ansText);
                          if(getIndex===-1){
                            let temp = {
                              name: ansText, 
                              score: ansCount, 
                              total: 1
                            }
                            totalGrand+=ansCount;
                            temp["total"]=totalGrand;
                            NewData.push(temp);
                          }
                          else{
                            NewData[getIndex]["score"]+=ansCount;
                            totalGrand+=ansCount;
                            NewData[getIndex]["total"]=totalGrand;
                          }
                        }
                      });
                    }

       
     
          
        });
      }

      let AvgList = []
      if(NewData && NewData.length>0){
        NewData.forEach((item)=>{
          let temp = {
            name:item.name,
            score: parseFloat(item.score*100)/totalGrand,
            total: item.total
          }
          // temp["total"]=temp["total"]*100/totalGrand;
          AvgList.push(temp)
        });
      }


      //console.log('AvgList: ',AvgList)

      let avgData = {
        "name":"Overall Question Distributions",
        "answers":AvgList,
        "parameter":"overall"
      }

      QuestionList.push(avgData);




      //---------------------------------------Filter-------------------------------------------------------------







      if(getQuestionDistData && getQuestionDistData.length>0){
        getQuestionDistData.forEach((ques,index1)=>{
              
      let totalGrandMini = 0;
                  let para = ""
                  if(ques && ques.total && ques.total.length>0){
                    ques.total.forEach((item, index)=>{
                      // total += item.count;
                      if(index === 0){
                        para = (item && item._id && item._id.parameterBucketName)?item._id.parameterBucketName:"";
                      }
                    });
                  }
        
                  let data = {
                    "name":ques && ques.question && ques.question.length>0 && ques.question[0].name?ques.question[0].name:"NaN",
                    "answers":[],
                    "parameter":para
                  }

                  let NewData2 = [];

                  if(ques && ques.total && ques.total.length>0){
                    ques.total.forEach((item, index)=>{

                      let optionName = item&&item._id&&item._id.answerText?item._id.answerText:"";
                      let optionScore = item.count;

                      if(optionName){
                        let getIndex = NewData2.findIndex(prev=>prev.name===optionName);
                        if(getIndex===-1){
                          let temp = {
                            name: optionName, 
                            score: optionScore, 
                            total: 1
                          }
                          
                          totalGrandMini+=optionScore;
                          temp["total"]=totalGrandMini;
                          NewData2.push(temp);
                        }
                        else{
                          NewData2[getIndex]["score"]+=optionScore;
                          totalGrandMini+=optionScore;
                          NewData2[getIndex]["total"]=totalGrandMini;
                        }
                      }
                      //console.log('data NewAnswer: ',NewData2)

                      let NewAnswer = [];
                      if(NewData2 && NewData2.length>0){
                        NewData2.forEach((item)=>{
                          // LabelList.push(item.name);
                          let temp = {
                            ...item,
                          }
                          temp["score"] = parseFloat(temp["score"]*100)/totalGrandMini;

                          NewAnswer.push(temp);


                        });
                      }

                      data["answers"] = NewAnswer;

                      //console.log('NewAnswer: ',NewAnswer)


                      // data["answers"].push(
                      //   {
                      //     "name":optionName,
                      //     "score":optionScore,
                      //     "total":total
                      //   }
                      // )



                      
                    })
                  }
                  
                  
                  if(cardType==='overall'){
                    QuestionList.push(data);

                  }
                  else if(cardType==='index'){
                      if(ItemSaveData && ItemSaveData.name && (data.parameter === ItemSaveData.name)){
                        QuestionList.push(data);
                      }
                  }
          
        });
      }









      let MeanScoreQwiseList = []
      if(currFilterValue !== 'none'){
          if(getMeanScoreQwiseData && getMeanScoreQwiseData.length>0){
            getMeanScoreQwiseData.forEach((ques)=>{
              if(ques.dmgName === currFilterValue){
                if(cardType==='overall'){
                  MeanScoreQwiseList.push(ques);
                }
                else if(cardType==='index'){
                    if(ItemSaveData && ItemSaveData.name && (ques.parameter === ItemSaveData.name)){
                      MeanScoreQwiseList.push(ques);
                    }
                }
              }
            })
          }
      }
      else{
        MeanScoreQwiseList = getMeanScoreQwiseData;
      }


      let FilterValueList = []
      if(getMeanScoreQwiseData && getMeanScoreQwiseData.length>0){
        getMeanScoreQwiseData.forEach((ques)=>{
          if(ques.dmg === currFilterName){
            if(!FilterValueList.some(prev=>prev === ques.dmgName) && ques.dmgName){
              FilterValueList.push(ques.dmgName);
            }
          }
        })
      }
    
    //console.log('getQuestionDistData: ',getQuestionDistData);
    //console.log('QuestionList: ',QuestionList);

  

    return (
      <>
     <div className="xl:flex lg:flex md:flex justify-end items-center">


          <Filter 
                    EmpFilterData = {EmpFilterData}
                    
                    getIndexFilterData={getIndexFilterData}

                    handleDemographicFilter={handleDemographicOverallQFilter}

                    handleFilterSave={this.handleFilterSave}

                    handleFilterValueSave={this.handleFilterValueSave}

                    FilterValueList={FilterValueList}
        
                  />

        </div>  

  
    <div className="container mx-auto px-2 mt-2">
      <div className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 xl:grid-cols-1  gap-2">           
      <div className="bg-white rounded w-full shadow-lg px-2">

      {QuestionList && QuestionList.length>0?
          <div className="py-2  whitespace-nowrap  px-4">
                <table className="border-collapse w-full ">
                  <thead>
                    <tr className=" text-xs   text-gray-800">
                    <th className="py-2 px-4 font-bold"><div className="flex items-center justify-center">Question</div></th>
                    <th className="py-2 px-4 font-bold"><div className="flex items-center justify-center">Distribution (%)</div></th>
                    </tr>
                  </thead>
                  <tbody>
                    {QuestionList && QuestionList.length>0?
                      QuestionList.map((question,index)=>
                        <tr key={index} className="bg-white  mb-10 lg:mb-0 text-xs text-gray-800">
                          <td className="tooltip-response-question1 px-2  w-2/12">                             
                          <div className={question.parameter==="overall"? "cursor-pointer font-bold":"cursor-pointer"} style={{width:'20%'}}>
                            {(question && question.name && question.name.length>65)?question.name.slice(0,65)+'...':question.name}
                          </div>
                            <div className="tooltip-response-question2">
                              {question.name}
                            </div>
                          </td>
                          <td className="  w-10/12" >      
                            <div className="">
                              <QuestionStack question={question} />
                            </div>
                          </td>
                        </tr>
                      )
                    :null}

                  </tbody>
                </table>
              </div>
              :
              <div className="py-10 text-center text-lg text-gray-500">No Data Available</div>
            }
      </div>
  </div>
</div>



      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ResponseDistribution);
