import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../../_helpers';

import { dashboardActions, surveyActions } from '../../../_actions';

import Header from '../Header/Header';
import Sidebar from '../../Overview/Sidebar/Sidebar';

import * as XLSX from 'xlsx';
import { make_cols } from '../../Employees/MakeColumns';
import { SheetJSFT } from '../../Employees/types';

import FeedbackSurveyList from './Components/FeedSurveyList';
import AddSurvey from './Modal/AddSurvey/AddSurvey';
import UpdateSurvey from './Modal/AddSurvey/UpdateSurvey';
import { userActions } from '../../../_actions';
import DeleteSurveyConfirm from './Modal/DeleteSurveyConfirm';

import ChooseSurvey from './Modal/AddSurvey/ChooseSurvey';
import SurveyLibrary from './Components/SurveyLibrary';
import QuestionLibrary from './Components/QuestionLibrary';
import AddSurveyLibModal from './Modal/AddSurvey/AddSurveyLibModal';

import NoDashboardModal from './Modal/NoDashboardModal';

import UploadPreview from './Modal/UploadPreview';
import RelationshipTable from './Modal/RelationshipTable';
import { TbPlus } from 'react-icons/tb';


class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      surveysize: 10000,

      showSurveyList: true,
      showAddNewSurvey: false,
      showEditSurvey: false,
      confirmDeleteSurvey: false,
      CurrentSurveyId: '',
      CurrentSurveyName: '',
      showChooseSurveyModal: false,
      showSurveyLibrary: false,
      isPackageSelected: false,
      showStagesListModal: false,
      showRelationModal: false,
      CurrentSurveyData: '',
      showQuestionLibrary: '',
      savePackage: '',
      crrLifecycleStageId: '',
      crrLinkedCluster: '',
      crrLinkedSurveyId: '',
      EmpIndexA: 0,
      EmpIndexB: 6,

      currentSurveyPage: 1,

      packageList: [],
      surveyValidation: {
        "name": true,
        "desc": true
      },


      surveyFormData: {
        "name": '',
        "desc": '',
        "type": "risk",
        "roleTemp": "ADMIN"
      },

      showCreateSurveyPopup: false,


      showSidebar: true,

      showTab: 1,

      CrrRoute: "survey",

      crrSurvey: "",
      updateformSurveyData: {
        id: "",
        name: "",
        roleTemp: "",
        desc: "",
        templateId: "",
        companyId: "",
      },



      CurrentLanguage: 'en',
      CurrentLanguageName: 'English',


      showLanguageModal: false,
      showNODashboardModal: false,

      showSplash: true,
      crrSurveyId: "",

      loadedDataSheet: [],
      showUploadPreview: false,
      showRelationTable: false,
      showMobileSidebar: false,
      showGrid: true,
    }
  }


  componentDidMount() {


    this.props.dispatch(surveyActions.copy360Parameters({}));

    this.props.dispatch(dashboardActions.getCompanyNameOnly());


    let dataX = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.getAllParameterList(dataX));


    let data = {
      "keyWord": "",
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 3 //feedback survey
    }


    this.props.dispatch(surveyActions.getDefaultRiskSurvey({ "defaultType": "DEFAULT", "stageTemplate": 6, "surveyModule": 3 }, data));

    // let dataExit = {
    //   "SenseTemplate": 2,
    //   "isNonEdit": false
    // }
    // this.props.dispatch(surveyActions.getDefaultRiskSurvey(dataExit,data));


    let dataX2 = {
      "keyWord": "",
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 3 //feedback survey
    }
    this.props.dispatch(surveyActions.getSurveyList(dataX2));

    this.props.dispatch(dashboardActions.getClientProfile());


    let data2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(surveyActions.getLanguageLabel(data2));
    this.props.dispatch(surveyActions.getLanguage(data2));






    this.props.dispatch(surveyActions.getSurveyOneTemplate());


    let data2x2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100000,
      "isSense": false
    }

    this.props.dispatch(surveyActions.get360RelationList(data2x2));

  }



  prevEmpPage = () => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (EmpIndexA > 0) {
      this.setState({ EmpIndexA: EmpIndexA - 1, EmpIndexB: EmpIndexB - 1 });
    }
  }

  nextEmpPage = (IndexListIndex) => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (IndexListIndex && IndexListIndex.length && (EmpIndexB < IndexListIndex.length)) {
      this.setState({ EmpIndexA: EmpIndexA + 1, EmpIndexB: EmpIndexB + 1 });
    }
  }

  SelectEmpPage = (val) => {
    this.setState({ currentSurveyPage: val })
  }


  handleSearchSurvey = (e) => {
    let data = {
      "keyWord": e.target.value,
      "pageNo": 1,
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "size": this.state.size,
      "surveyModule": 3 // sense survey
    }
    this.props.dispatch(surveyActions.getSurveyList(data));
  }


  onAddNewSurvey = (check) => {
    if (check)
      this.setState({
        showSurveyList: true,
        showAddNewSurvey: false
      })
    else {
      this.setState({
        showSurveyList: false,
        showAddNewSurvey: true
      })
    }
  }

  handleAddSurveyInput = (e) => {
    let { surveyFormData } = this.state;
    surveyFormData[e.target.name] = e.target.value;
    this.setState({ surveyFormData })
  }

  handleUpdateSurveyInput = (e) => {
    let { updateformSurveyData } = this.state;
    updateformSurveyData[e.target.name] = e.target.value;
    this.setState({ updateformSurveyData })
  }

  handleAddSurveySubmit = () => {

    let isCustom = this.state.surveyFormData && this.state.surveyFormData.type === "custom";

    let reqData = {
      "name": this.state.surveyFormData.name,
      "desc": this.state.surveyFormData.desc,
      "type": this.state.surveyFormData.type,
      "roleTemp": this.state.surveyFormData.roleTemp,
      "templateId": (isCustom ? 3 : 1),
      "surveyModule": 3 // sense survey
    }

    // let datatempsurvey = {
    //   "keyWord": "",
    //   "pageNo": 1,
    //   "size": this.state.surveysize
    // }


    if (reqData && reqData.name) {
      this.setState({ SurveySubmitData: reqData }, () => {
        if (this.state.surveyFormData && this.state.surveyFormData.type === "custom") {
          // if(this.state.crrLifecycleStageId){
          //   this.handleCustomSurvey();
          // }
          // else{
          //   this.handleCustomSurveyStage();
          // }
          this.handleCustomSurvey();

        }
        else if (this.state.surveyFormData && this.state.surveyFormData.type === "package") {
          this.handleCreateSurveyFromLibrary();
          this.setState({ showCreateSurveyPopup: false });
        }
        else if (this.state.surveyFormData && this.state.surveyFormData.type === "risk") {
          this.handleCustomSurvey();
        }

      });

    }
    else {
      let { surveyValidation } = this.state;

      if (reqData && reqData.name) {
        surveyValidation["name"] = true
      }
      else {
        surveyValidation["name"] = false
      }

      if (reqData && reqData.desc) {
        surveyValidation["desc"] = true
      }
      else {
        surveyValidation["desc"] = false
      }
      this.setState({ surveyValidation })
    }







  }


  handleCustomSurveyStage = () => {


    let dataX = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(surveyActions.getLifecycleStageList(dataX));

    this.setState({ showStagesListModal: true });

    // let clientData = this.props.dashboard.ClientProfileData;


    // let { SurveySubmitData } = this.state;

    // let resData = {
    //   "name":SurveySubmitData.name,
    //   "desc":SurveySubmitData.desc,
    //   "templateId":SurveySubmitData && SurveySubmitData.templateId==="risk"?6:3,
    //   "roleTemp":clientData && clientData.role && clientData.role === "CROLE"?"CROLE":SurveySubmitData.roleTemp

    // }
    // let datatempsurvey = {
    //   "keyWord": "",
    //   "pageNo": 1,
    //   "size": this.state.surveysize
    // }

    // this.props.dispatch(surveyActions.createSurvey(resData, datatempsurvey, true,[],this.props));

    // let validate = {
    //    "name":true,
    //    "desc":true
    // }
    // let tempData={
    //    "name":'',
    //    "desc":'',
    //    "type":"custom",
    //    "roleTemp":"ADMIN"

    // }
    // this.setState({SurveySubmitData:"",showCreateSurveyPopup:false,showChooseSurveyModal:false, surveyValidation:validate, surveyFormData:tempData})

  }


  handleCustomSurvey = () => {
    let clientData = this.props.dashboard.ClientProfileData;


    let { SurveySubmitData, crrLifecycleStageId, crrLinkedSurveyId, crrLinkedCluster } = this.state;

    if (true) {

      let resData = {
        "name": SurveySubmitData.name,
        "desc": SurveySubmitData.desc,
        "templateId": 3,
        "roleTemp": clientData && clientData.role && clientData.role === "CROLE" ? "CROLE" : SurveySubmitData.roleTemp,
        "surveyModule": 3 //Feedback Survey
      }

      if (crrLifecycleStageId) {
        resData["stageId"] = crrLifecycleStageId;
      }
      if (crrLinkedSurveyId && crrLinkedSurveyId.id) {
        resData["linkedSurveyId"] = crrLinkedSurveyId.id;
      }
      if (crrLinkedCluster && crrLinkedCluster.clusterName) {
        resData["linkedClusterName"] = crrLinkedCluster.clusterName;
      }

      let datatempsurvey = {
        "keyWord": "",
        "pageNo": 1,
        "size": this.state.surveysize,
        "surveyModule": 3 //Feedback Survey
      }

      // console.log("111111 resData---->", resData)

      this.props.dispatch(surveyActions.createSurvey(resData, datatempsurvey, true, [], this.props));

      let validate = {
        "name": true,
        "desc": true
      }
      let tempData = {
        "name": '',
        "desc": '',
        "type": "custom",
        "roleTemp": "ADMIN"

      }
      this.setState({ crrLifecycleStageId: '', showStagesListModal: false, SurveySubmitData: "", showCreateSurveyPopup: false, showChooseSurveyModal: false, surveyValidation: validate, surveyFormData: tempData })

    }


  }

  onDeleteSurvey = (survey) => {
    this.setState({ confirmDeleteSurvey: true, CurrentSurveyId: survey.id, CurrentSurveyName: survey.name })
  }

  handleConfirmDeleteSurveyClose = () => {
    this.setState({ confirmDeleteSurvey: false, CurrentSurveyId: '', CurrentSurveyName: '' })
  }


  handleViewPackage = (survey, valid) => {
    if (valid) {
      let { packageList } = this.state;
      this.setState({ showQuestionLibrary: true, savePackage: survey });

      if (packageList.findIndex(prev => prev === survey.id) !== -1) {
        this.setState({ isPackageSelected: true })
      } else {
        this.setState({ isPackageSelected: false })
      }
    }
  }

  handlePackageSelection = (check, survey) => {
    let Id = survey.id;
    let StageId = survey && survey.stageId && survey.stageId.id ? survey.stageId.id : '';
    let { packageList, crrLifecycleStageId } = this.state;

    if (StageId === crrLifecycleStageId) {
      //////console.log("survey package------------->?", survey)
      this.setState({ showQuestionLibrary: false })

      if (!check) {
        packageList.push(Id);
      }
      else {
        let getIndex = packageList.findIndex(prev => prev === Id)
        packageList.splice(getIndex, 1);
      }
      this.setState({ packageList });


      // if(packageList.findIndex(prev=>prev === survey.id)===-1){
      //   packageList.push(survey.id);
      //   this.setState({isPackageSelected:false})
      // }
      // else{
      //   let getIndex = packageList.findIndex(prev=>prev === survey.id);
      //   packageList.splice(getIndex,1);
      //   this.setState({isPackageSelected:true})
      // }

    }


  }

  handleADDpackage = (survey) => {
    this.setState({ showQuestionLibrary: true })

    let { packageList } = this.state;
    if (packageList.findIndex(prev => prev === survey.id) === -1) {
      packageList.push(survey.id);
      this.setState({ isPackageSelected: false })
    }
    else {
      let getIndex = packageList.findIndex(prev => prev === survey.id);
      packageList.splice(getIndex, 1);
      this.setState({ isPackageSelected: true })
    }
    this.setState({ packageList });
  }


  onSurveyDelete = () => {
    let data = {
      id: this.state.CurrentSurveyId
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 3 //Feedback Survey
    }
    this.props.dispatch(surveyActions.deleteSurvey(data, datatempsurvey));
    this.setState({ confirmDeleteSurvey: false, CurrentSurveyId: '', CurrentSurveyName: '' })
  }

  onEditSurvey = (data) => {
    this.props.history.push(`/app/question/${data.id}`)
  }

  onReportSurvey = (data) => {
    this.props.history.push(`/app/survey360report/${data.id}`)
  }

  onPublishSurvey = (data, templateId, otherSurvey, type) => {
    if (type === 3) {
      this.props.history.push(`/app/publishfeedback/${data.id}/1`)
    }
    else if (type === 2) {
      this.props.history.push(`/app/publishsense/${data.id}`)
    }
    else {
      this.props.history.push(`/app/publish/${data.id}`)
    }


  }

  handleChooseSurveyPopup = () => {
    this.setState({ showCreateSurveyPopup: false, showChooseSurveyModal: true })
  }

  handleCreateSurveyPopup = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showCreateSurveyPopup: true })
  }

  handleCreateSurveyFromLibrary = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showSurveyLibrary: true });


    this.props.dispatch(surveyActions.getSurveyFromLib({
      "stageTemplate": 6,
      "surveyType": 2
    }));
  }

  handleSurveyLibraryClose = (check) => {
    this.setState({ showCreateSurveyPopup: true, showSurveyLibrary: false, packageList: [] });
  }


  QuestionLibraryClose = () => {
    this.setState({ CurrentSurveyData: false, savePackage: '', showQuestionLibrary: false });
  }



  handleCreateSurveyPopupClose = () => {
    this.handleChooseSurveyPopupClose();

    let validate = {
      "name": true,
      "desc": true
    }
    let tempData = {
      "name": '',
      "desc": '',
      "type": "custom"

    }
    this.setState({ crrLifecycleStageId: '', showStagesListModal: false, showCreateSurveyPopup: false, surveyValidation: validate, surveyFormData: tempData });
  }

  handleChooseSurveyPopupClose = () => {
    this.setState({ showChooseSurveyModal: false })
  }

  onLogout = () => {
    //console.log('logout')
    this.props.dispatch(userActions.logoutSession());
  }


  goToDashboard = () => {

    this.props.history.push('/app/dashboard');

  }


  gotoEmployeeScreen = () => {
    this.props.history.push('/app/employees/');
  }

  onDashboard = (survey) => {
    //console.log('survey: ', survey);

    if (survey && survey.surveyModule === 3) {
      this.props.history.push('/app/feebackdash/' + survey.id);
    }
    else {
      // let SurveyId = '5fa04d316ede0f0336066cce';
      if (survey && survey.templateId === 1) {
        this.props.history.push('/app/dashboard/' + survey.id);
      }
      else if (survey && survey.templateId === 2) {
        this.props.history.push('/app/dashboard/' + survey.id);
      }
      else if (survey && survey.templateId === 3) {
        this.props.history.push('/app/generaldashboard/' + survey.id);
      }
      else if (survey && survey.templateId === 4) {
        this.props.history.push('/app/generaldashboard/' + survey.id);
      }
      else if (survey && survey.templateId === 5) {
        this.props.history.push('/app/customerdashboard/' + survey.id);
      }
      else if (survey && survey.templateId === 6) {
        this.props.history.push('/app/sensedashboard/' + survey.id);
      }
    }

  }

  handleSurveyLibQuestions = (survey) => {
    this.setState({ CurrentSurveyData: survey });
  }

  handleUseSurveyPage = () => { }

  handleSuveyPackageSubmit = (crrLifecycleStageId) => {
    let { SurveySubmitData, packageList } = this.state;
    let { survey } = this.props;
    let { getSurveyFromLibData } = survey;

    //////console.log("packageList-------------------------->", packageList);

    let isNonEdit = false;
    let nonEditCount = 0
    if (getSurveyFromLibData && getSurveyFromLibData.length > 0 && packageList && packageList.length > 0) {
      packageList.forEach((item) => {
        let getIndex = getSurveyFromLibData.findIndex(prev => prev.id === item);
        if (getIndex !== -1) {
          if (getSurveyFromLibData[getIndex]["stageId"]["isNonEdit"]) {
            nonEditCount++
          }
        }
      })

      if (nonEditCount === packageList.length) {
        isNonEdit = true
      }

    }
    //////console.log("isNonEdit-------------------------->", isNonEdit);



    let resData = {
      "name": SurveySubmitData.name,
      "desc": SurveySubmitData.desc,
      "stageId": crrLifecycleStageId,
      "roleTemp": SurveySubmitData.roleTemp,
      "templateId": 1,
      "isNonEdit": isNonEdit,
      "surveyModule": 3 //Feedback Survey

    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 3 //Feedback Survey
    }

    //////console.log("resData-------->", resData)
    // this.props.history.push(`/app/question/${data.id}`)

    this.props.dispatch(surveyActions.createSurvey(resData, datatempsurvey, false, packageList, this.props));

    let validate = {
      "name": true,
      "desc": true
    }
    let tempData = {
      "name": '',
      "desc": '',
      "type": "custom",
      "roleTemp": "ADMIN"

    }
    this.setState({ crrLifecycleStageId: "", showSurveyLibrary: false, showCreateSurveyPopup: false, showChooseSurveyModal: false, surveyValidation: validate, surveyFormData: tempData, packageList: [] })

  }

  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  }
  routeLink = (link) => {
    this.props.history.push('/app/' + link);
    this.setState({ CrrRoute: link });
  }
  onOptionDot = (data) => {
    if (this.state.crrSurvey === data.id) {
      this.setState({ crrSurvey: "" });
    }
    else {
      this.setState({ crrSurvey: data.id });
    }

    setTimeout(() => {
      this.setState({ crrSurvey: "" });
    }, 7000);
  }


  handleLanguage = (item) => {
    this.setState({ CurrentLanguage: item.shortName, CurrentLanguageName: item.name, showLanguageModal: false });
  }


  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  }


  onPreviewSurvey = (data) => {
    this.props.history.push(`/app/preview/${data.id}`)
  }


  handleSurveyType = (type) => {
    let { surveyFormData } = this.state;
    surveyFormData["type"] = type;
    this.setState({ surveyFormData });
  }

  handleSurveyRole = (role) => {
    let { surveyFormData } = this.state;
    surveyFormData["roleTemp"] = role;
    this.setState({ surveyFormData });
  }

  updatehandleSurveyRole = (role) => {
    let { updateformSurveyData } = this.state;
    updateformSurveyData["roleTemp"] = role.trim();
    this.setState({ updateformSurveyData });
  }

  onUpdateSurvey = (survey) => {
    let SurveyRole = "ADMIN"
    if (survey && survey.roles && survey.roles && survey.roles.length > 0) {
      if (survey.roles.length === 1) {
        if (survey.roles[0] === "ADMIN") {
          SurveyRole = "ADMIN";
        }
        else if (survey.roles[0] === "CROLE") {
          SurveyRole = "CROLE";
        }
      }
      else if ((survey.roles[0] === "ADMIN" && survey.roles[1] === "CROLE") || (survey.roles[0] === "CROLE" && survey.roles[1] === "ADMIN")) {
        SurveyRole = "CROLE";
      }

    }
    //console.log('SurveyRole', SurveyRole)
    // survey.roles
    // roles
    let data = {
      id: survey.id,
      name: survey.name,
      roleTemp: SurveyRole,
      desc: survey.desc,
      templateId: survey.templateId,
      companyId: survey.companyId,
    }
    this.setState({ showUpdateSurveyModal: true, updateformSurveyData: data });
  }
  closeUpdateSurvey = () => {
    this.setState({ showUpdateSurveyModal: false, updateformSurveyData: "" });
  }

  handleUpdateSurveySubmit = () => {
    let { updateformSurveyData } = this.state;
    let resData = {
      "id": updateformSurveyData.id,
      "name": updateformSurveyData.name,
      "desc": updateformSurveyData.desc,
      "roleTemp": updateformSurveyData.roleTemp,
      "templateId": updateformSurveyData.templateId,
      "companyId": updateformSurveyData.companyId
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 3 //Feedback Survey
    }
    this.props.dispatch(surveyActions.updateSurvey(resData, datatempsurvey));
    let tempClear = {
      id: "",
      name: "",
      roleTemp: "",
      desc: "",
      templateId: "",
      companyId: "",
    }
    this.setState({ showUpdateSurveyModal: false, updateformSurveyData: tempClear });
  }


  onNoDashboardModal = (check, survey, categoryType) => {
    if (check) {
      this.setState({ showNODashboardModal: check, crrSurveyId: survey.id })
    } else {
      this.setState({ showNODashboardModal: check });
    }
  }

  selectStage = (item) => {
    let { crrLifecycleStageId } = this.state;
    if (crrLifecycleStageId === item.id) {
      this.setState({ crrLifecycleStageId: "", packageList: [] })
    }
    else {
      this.setState({ crrLifecycleStageId: item.id, packageList: [] })
    }
  }


  selectLinkedSurvey = (item, survey, isSave) => {
    this.setState({ crrLinkedSurveyId: item }, () => {


      if (item && item.id) {

        let dataX = {
          "surveyId": survey && survey.id ? survey.id : "",
          "linkedSurveyId": item.id
        };
        let datatempsurvey = {
          "keyWord": "",
          "pageNo": 1,
          "size": this.state.surveysize,
          "surveyModule": 3 //Feedback Survey
        }

        if (isSave) {
          this.props.dispatch(surveyActions.updateSurveyClusterSurveyId(dataX, datatempsurvey));
        }


        let dataZ = {
          "surveyId": item.id,
          "filters": []
        }
        this.props.dispatch(dashboardActions.clusterAnalysis(dataZ));
      }

    })
  }

  selectLinkedCluster = (item, survey, isSave) => {
    this.setState({ crrLinkedCluster: item }, () => {
      let dataX = {
        "surveyId": survey && survey.id ? survey.id : "",
        "linkedClusterName": item && item.clusterName ? item.clusterName : ""
      };
      let datatempsurvey = {
        "keyWord": "",
        "pageNo": 1,
        "size": this.state.surveysize,
        "surveyModule": 3 //Feedback Survey
      }

      if (isSave) {
        this.props.dispatch(surveyActions.updateSurveyClusterSurveyId(dataX, datatempsurvey));
      }
    })
  }



  loadCluster = (item) => {
    let dataZ = {
      "surveyId": item && item.linkedSurveyId && item.linkedSurveyId.id ? item.linkedSurveyId.id : "",
      "filters": []
    }
    this.props.dispatch(dashboardActions.clusterAnalysis(dataZ));
  }


  handleSplash = (value) => {
    this.setState({ showSplash: value })
  }


  handleConfirmMailView = () => {
    this.setState({ showNODashboardModal: false }, () => {
      if (this.state.crrSurveyId) {
        this.props.history.push('/app/livedashboard/' + this.state.crrSurveyId);
      }
    })
  }


  handleRelationshipUpload = () => {
    this.setState({ showRelationModal: true })
  }

  closeRelationshipModal = () => {
    this.setState({ showRelationModal: false })
  }



  downLoadSampleCSV = () => {

    let binary_univers = [
      { "reviewer_name": "NA", "reviewer_email": "NA", "reviewer_emp_id": "E002", "reviewee_emp_id": "E002", "reviewer_relation": "Self", "relation_type": "INTERNAL" },
      { "reviewer_name": "NA", "reviewer_email": "NA", "reviewer_emp_id": "E001", "reviewee_emp_id": "E002", "reviewer_relation": "Manager", "relation_type": "INTERNAL" },
      { "reviewer_name": "NA", "reviewer_email": "NA", "reviewer_emp_id": "E004", "reviewee_emp_id": "E002", "reviewer_relation": "Peer", "relation_type": "INTERNAL" },
      { "reviewer_name": "john", "reviewer_email": "empsample1@mailinator.com", "reviewer_emp_id": "NA", "reviewee_emp_id": "E002", "reviewer_relation": "Subordinate", "relation_type": "EXTERNAL" },
      { "reviewer_name": "john", "reviewer_email": "empsample1@mailinator.com", "reviewer_emp_id": "NA", "reviewee_emp_id": "E002", "reviewer_relation": "Subordinate", "relation_type": "EXTERNAL" },
      { "reviewer_name": "john", "reviewer_email": "empsample1@mailinator.com", "reviewer_emp_id": "NA", "reviewee_emp_id": "E002", "reviewer_relation": "Subordinate", "relation_type": "EXTERNAL" }
    ];



    //console.log('binary_univers: ', binary_univers)

    let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

    // Create a new Workbook
    var wb = XLSX.utils.book_new()

    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values')

    // export your excel
    XLSX.writeFile(wb, 'sample relationship.xlsx');
  }

  handleDataChange = (e) => {
    this.props.dispatch(surveyActions.empty360Save());

    this.setState({ loadedDataSheet: [] }, () => {

      const files = e.target.files;
      // //////console.log("files; ",files)

      if (files && files[0]) {
        let fileName = files && files[0] && files[0].name ? files[0].name : "";
        let extName1 = fileName && fileName.substr(fileName.length - 3) ? fileName.substr(fileName.length - 3) : "";
        let extName2 = fileName && fileName.substr(fileName.length - 4) ? fileName.substr(fileName.length - 4) : "";
        if ((extName1 === "csv") || (extName2 === "xlsx")) {

          this.setState({ file: files[0] }, () => {
            //////console.log("this.state.file", this.state.file)
            this.setState({ fileUploadError: "" });








            //----------------------------------------------------------------------------------------------------------------------------------------------------------
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;


            if (this.state.file) {
              if (rABS) {
                reader.readAsBinaryString(this.state.file);
              } else {
                reader.readAsArrayBuffer(this.state.file);
              };
            }

            // console.log('rABS--------------------------------------------->', rABS)


            reader.onload = (e) => {
              /* Parse data */
              const bstr = e.target.result;
              const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              // console.log('before ws--------------------------------------------->', ws)

              /* Convert array of arrays */
              let data = XLSX.utils.sheet_to_json(ws);
              data = data.map(v => ({ ...v }))

              // console.log('data-------------11111-------------------------------->', data)

              setTimeout(() => {
                this.setState({ loadedDataSheet: data, showUploadPreview: true, showRelationModal: false })
              }, 1000)

            };

            //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

          });

        }
        else {
          this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
          //////console.log("not csv or xlsx file")
        }

      }
      else {
        this.setState({ fileUploadError: "Please upload csv or xlsx file!" });
      }

    })

  };


  saveUploadRelation = () => {
    let { loadedDataSheet } = this.state;
    if (loadedDataSheet && loadedDataSheet.length > 0) {
      let Temp1 = {
        "keyWord": '',
        "pageNo": 1,
        "size": 6
      }

      let finalData = []
      if (loadedDataSheet && loadedDataSheet.length > 0) {
        finalData = loadedDataSheet.map((element) => ({ ...element, 'isSense': false }))
      }
      this.props.dispatch(surveyActions.save360RelationList(finalData, Temp1));
    }


  }

  handleDataFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws);
      // //////console.log("datadatadatadata ", data);
      data = data.map(v => ({ ...v }))
      //////console.log("datasssss-------->", data)
      let flag = 0;
      let locList = [];
      if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          flag = flag + 1;
          locList.push(i + 1);
        }
      }

      //////console.log("flag-------->", flag)

      if (flag && false) {
        this.setState({ isNotMail: true, isNotMailPos: locList })
      }
      else {

        let Temp1 = {
          "keyWord": '',
          "pageNo": 1,
          "size": 100000
        }
        this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
          // console.log("this.state.data-------->", this.state.data)

          let finalData = []
          if (this.state.data && this.state.data.length > 0) {
            finalData = this.state.data.map((element) => ({ ...element, 'isSense': false }))
          }

          this.props.dispatch(surveyActions.save360RelationList(finalData, Temp1));
          this.setState({ isNotMail: false, isNotMailPos: [] })

        });
      }



    };
    if (this.state.file) {
      if (rABS) {
        reader.readAsBinaryString(this.state.file);
      } else {
        reader.readAsArrayBuffer(this.state.file);
      };
    }
  }

  closeUploadPreview = () => {
    this.setState({ showUploadPreview: false, loadedDataSheet: [] })

  }

  closeRelationshipTable = () => {
    this.setState({ showRelationTable: false })
  }

  handleMyRelationshipTable = () => {
    this.setState({ showRelationTable: true })
  }

  handle360DisableStatue = (id) => {
    let data2x2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 1000
    }
    let data = {
      "id": id
    }
    this.props.dispatch(surveyActions.handle360FeedbackDisable(data, data2x2));
  }

  handleSidebar = (check) => {
    this.setState({ showSidebar: check })
  }
  handleMobileSidebar = (check) => {
    this.setState({ showMobileSidebar: check })
    this.setState({ showSidebar: check })
  }

  handleGridView = (check) => {
    this.setState({ showGrid: check })
  }


  render() {
    let { survey, dashboard } = this.props;
    let { save360RelationListData, get360RelationListData, getDefaultRiskSurveyData, getLifecycleStageListData, getLanguageLabelData, getLanguageData, getSurveyFromLibData, getSurveyListData, getSurveyListTotal, loading, getSurveyOneTemplateData } = survey;
    let { getCompanyNameOnlyData, clusterAnalysisData } = dashboard;
    let { showGrid, showRelationTable, loadedDataSheet, showUploadPreview, showRelationModal, crrLinkedCluster, crrLinkedSurveyId, crrLifecycleStageId, showNODashboardModal, showUpdateSurveyModal, updateformSurveyData, showLanguageModal, CurrentLanguageName, CurrentLanguage, crrSurvey, CrrRoute, showSidebar, savePackage, isPackageSelected, showQuestionLibrary, packageList, CurrentSurveyData, showSurveyLibrary, showChooseSurveyModal, showSurveyList, CurrentSurveyName, confirmDeleteSurvey, surveyFormData, showCreateSurveyPopup, surveyValidation, showTab } = this.state;

    // console.log("save360RelationListData-Data--------->", save360RelationListData);

    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    //console.log('updateformSurveyData: ', updateformSurveyData)

    //ROLE MANAGEMENT ----------------------------------------------

    let SHOW_ELEMENT = false;
    let CLIENT_ID = "";
    let CLIENT_ROLE = "";

    let clientData = this.props.dashboard.ClientProfileData;

    if (clientData && clientData.id) {
      CLIENT_ID = clientData.id;
    }


    if (clientData && clientData.role) {
      CLIENT_ROLE = clientData.role;

      if (CLIENT_ROLE === "ADMIN") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (CLIENT_ROLE === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (CLIENT_ROLE === "CROLE") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (CLIENT_ROLE === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }

    let isCrole = false;
    if (clientData && clientData.role && clientData.role === "CROLE") {
      isCrole = true;
    }

    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    //END -------------------------------------------------------
    let { ClientProfileData } = this.props.dashboard;
    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageractionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }
    //--------------------------------------------------------------------------------

    let IndexList = []
    let pageCount = 22
    let IndexListIndex = []
    let indexA = 0
    let indexB = 0

    if (getSurveyListData && getSurveyListData.length > 0) {
      if (getSurveyListData && getSurveyListData.length > 0) {
        getSurveyListData.forEach((item, index) => {
          if (index % pageCount === 0) {
            IndexList.push(index)
          }
          if (index === (getSurveyListData.length - 1)) {
            IndexList.push(index)
          }
        })
      }

      //console.log('IndexList: ', IndexList)

      if (IndexList && IndexList.length > 0) {
        IndexList.forEach((item, index) => {
          if (index !== (IndexList.length - 1)) {
            IndexListIndex.push(index + 1)
          }
        })
      }
      //console.log('IndexListIndex: ', IndexListIndex)





      if (IndexListIndex && IndexListIndex.length > 0) {
        IndexListIndex.forEach((item, index) => {
          if (item === this.state.currentSurveyPage) {

            if (index < (IndexList.length - 2)) {
              indexA = IndexList[index];
              indexB = IndexList[index] + (pageCount - 1)
            }
            else {
              indexA = IndexList[index];
              indexB = IndexList[IndexList.length - 1]
            }
          }
        })
      }

    }
    //--------------------------------------------------------------------------------

    //console.log('CLIENT_ROLE: ', CLIENT_ROLE)
    // //console.log('getLanguageLabelData: ',getLanguageLabelData,getLanguageData,);



    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }


    // createSurvey: false
    // dashboard: true
    // responseAnalytics: true
    // uploadUser: false
    // viewSurvey: true






    //////console.log("accessModule", accessModule());



    // accessAllow("createSurvey");
    // accessAllow("viewSurvey");
    // accessAllow("publishSurvey");
    let { getAllParameterListData } = this.props.dashboard;
    //console.log('getAllParameterListData V : ', getAllParameterListData);

    function GetParameterLabel(name) {
      let AllParameterObj = {};
      if (getAllParameterListData && getAllParameterListData.length > 0) {
        getAllParameterListData.forEach((item) => {
          AllParameterObj[item.name] = item && item.label ? item.label : item.name;
        });
      }
      return (AllParameterObj && AllParameterObj[name] ? AllParameterObj[name] : name);
    }


    return (
      <>
        <div className='h-screen overflow-hidden '>

          <Header
            ClientProfileData={ClientProfileData}
            handleLogout={this.handleLogout}
            props={this.props}
            companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}
            sidebarToggle={this.sidebarToggle}
          />

          <div className="flex w-full" style={{ height: 'calc(100% - 2rem)' }}>

            <Sidebar
              onOverview={this.onOverview}
              showSidebar={showSidebar}
              handleSidebar={this.handleSidebar}
              accessAllow={accessAllow}
              routeLink={this.routeLink}
              showMobileSidebar={this.state.showMobileSidebar}
              sidebarToggle={this.sidebarToggle}
            />
            <main className="flex-1 w-full overflow-hidden overflow-y-auto bg-[#f9fafb]   h-[calc(100vh-4rem)]">


              {showSurveyLibrary ?
                showQuestionLibrary ?
                  <QuestionLibrary
                    handleSurveyLibraryClose={this.handleSurveyLibraryClose}
                    getSurveyFromLibData={getSurveyFromLibData}
                    handleSurveyLibQuestions={this.handleSurveyLibQuestions}
                    CurrentSurveyData={CurrentSurveyData}
                    handleADDpackage={this.handleADDpackage}
                    packageList={packageList}
                    handleSuveyPackageSubmit={this.handleSuveyPackageSubmit}


                    isPackageSelected={isPackageSelected}
                    handlePackageSelection={this.handlePackageSelection}
                    savePackage={savePackage}
                    QuestionLibraryClose={this.QuestionLibraryClose}

                    GetParameterLabel={GetParameterLabel}

                  />
                  :
                  <SurveyLibrary
                    handleSurveyLibraryClose={this.handleSurveyLibraryClose}
                    getSurveyFromLibData={getSurveyFromLibData}
                    handleSurveyLibQuestions={this.handleSurveyLibQuestions}
                    CurrentSurveyData={CurrentSurveyData}
                    handleADDpackage={this.handleADDpackage}
                    packageList={packageList}
                    handleSuveyPackageSubmit={this.handleSuveyPackageSubmit}
                    crrLifecycleStageId={crrLifecycleStageId}
                    handleViewPackage={this.handleViewPackage}
                    selectStage={this.selectStage}
                  />
                :


                <>
                  {(accessAllow("viewSurvey")) ?
                    getSurveyListData && getSurveyListData.length > 0 ?
                      <div
                        className="p-4 mx-auto space-y-4 md:p-6 "

                      >
                        <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
                          <div className="flex items-center space-x-4 ">
                            <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">
                              {"360 Feeback Survey"}
                            </h1>
                          </div>

                          <div className="items-center hidden text-sm md:flex">
                            <span className="mx-2 material-symbols-rounded">home</span>
                            <span>/</span>
                            <span className="px-2">My survey</span>
                            <span>/</span>
                            <span className="px-2 font-semibold">{"360 Feeback Survey"}</span>
                          </div>
                        </div>



                        <div className="items-center justify-between space-x-6 md:flex">
                          {/* left side */}
                          <div className="flex items-center w-full space-x-2 overflow-x-auto border-b whitespace-nowrap">
                            <h1
                              onClick={() => this.setState({ showTab: 1 })}
                              className={
                                showTab === 1
                                  ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                                  : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-slate-600   rounded-t-md border"
                              }
                            >
                              {"Surveys"}
                            </h1>

                            <h1
                              onClick={() => this.setState({ showTab: 2 })}
                              className={
                                showTab === 2
                                  ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] "
                                  : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-slate-600   rounded-t-md border  "
                              }
                            >
                              {"360 Relationship"}
                            </h1>
                          </div>
                        </div>



                        {showTab === 1 ? (
                          <>
                            <div className="flex flex-col justify-center w-full gap-4 my-6 md:items-center md:flex-row md:justify-between">
                              <div className="flex items-center space-x-4 ">

                                <div className="flex items-center justify-between w-full overflow-hidden text-sm bg-white border rounded-md md:w-auto">
                                  <span className="p-2 rounded-l-full material-symbols-outlined text-slate-600" style={{ fontSize: "1.4rem" }}>  search</span>
                                  <input type="search" name="search" onChange={this.handleSearchSurvey} className="w-full py-2 md:w-48 focus:outline-none placeholder:text-slate-600" placeholder="Search" />
                                </div>

                              </div>

                              <div className="flex flex-row items-center gap-2">

                                <div className='items-stretch hidden space-x-2 md:flex'>
                                  {showGrid ?
                                    <button onClick={() => this.handleGridView(!showGrid)} className="flex items-center justify-center p-2 bg-white border rounded-md">
                                      <span className="material-symbols-rounded  text-[1.5rem] text-[#2196f3]">
                                        grid_view
                                      </span>
                                    </button>
                                    :
                                    <button onClick={() => this.handleGridView(!showGrid)} className="flex items-center justify-center p-2 bg-white border rounded-md">
                                      <span className="material-symbols-rounded  text-[1.5rem]">
                                        format_list_bulleted
                                      </span>
                                    </button>
                                  }

                                  {!showGrid ?
                                    <button onClick={() => this.handleGridView(!showGrid)} className="flex items-center justify-center p-2 bg-white border rounded-md">
                                      <span className="material-symbols-rounded  text-[1.5rem] text-[#2196f3]">
                                        grid_view
                                      </span>
                                    </button>
                                    :

                                    <button onClick={() => this.handleGridView(!showGrid)} className="flex items-center justify-center p-2 bg-white border rounded-md">
                                      <span className="material-symbols-rounded  text-[1.5rem] ">
                                        format_list_bulleted
                                      </span>
                                    </button>
                                  }
                                </div>
                                <button
                                  onClick={() =>
                                    this.handleCreateSurveyPopup()
                                  }
                                  className="bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] hover:bg-[#1E88E5] transition-all duration-200 md:w-auto w-full md:py-2 py-3 px-4 rounded-md text-center justify-center cursor-pointer transform flex items-center text-white space-x-1 md:text-sm text-md"
                                >

                                  <TbPlus className='text-[1.5rem]' />
                                  <span className="">Create New</span>
                                </button>


                              </div>
                            </div>

                            {/* <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">
                              {"Feedback Survey"}
                            </h1> */}

                            {/* end */}
                            {/* boxes work start */}
                            <div className={showGrid ? "grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 my-6" : "grid  grid-cols-1 gap-4 my-6"}>


                              {showSurveyList ?
                                <FeedbackSurveyList
                                  getSurveyListData={getSurveyListData}
                                  getSurveyOneTemplateData={getSurveyOneTemplateData}
                                  getSurveyListTotal={getSurveyListTotal}

                                  onDashboard={this.onDashboard}
                                  onEditSurvey={this.onEditSurvey}
                                  onPreviewSurvey={this.onPreviewSurvey}
                                  onReportSurvey={this.onReportSurvey}
                                  onPublishSurvey={this.onPublishSurvey}
                                  onDeleteSurvey={this.onDeleteSurvey}
                                  SHOW_ELEMENT={SHOW_ELEMENT}

                                  indexA={indexA}
                                  indexB={indexB}

                                  onOptionDot={this.onOptionDot}

                                  crrSurvey={crrSurvey}

                                  LanguageObj={LanguageObj}
                                  CurrentLanguage={CurrentLanguage}

                                  onUpdateSurvey={this.onUpdateSurvey}

                                  isCrole={isCrole}

                                  CLIENT_ID={CLIENT_ID}
                                  CLIENT_ROLE={CLIENT_ROLE}
                                  accessAllow={accessAllow}
                                  onNoDashboardModal={this.onNoDashboardModal}
                                  getDefaultRiskSurveyData={getDefaultRiskSurveyData}

                                  selectLinkedSurvey={this.selectLinkedSurvey}
                                  crrLinkedSurveyId={crrLinkedSurveyId}
                                  clusterAnalysisData={clusterAnalysisData}
                                  selectLinkedCluster={this.selectLinkedCluster}
                                  crrLinkedCluster={crrLinkedCluster}


                                  loadCluster={this.loadCluster}
                                  handleConfirmMailView={this.handleConfirmMailView}

                                  surveyModule={3}

                                  cardImage={"/img/360_feedback.png"}
                                  cardColor={"#DCEDC8"}

                                  showGrid={showGrid}

                                />
                                :
                                null}


                            </div>
                          </>
                        ) : null}

                        {showTab === 2 ? (
                          <>

                            <div className="w-full h-full xl:h-screen" >
                              <div className="bg-white rounded-lg shadow-sm ">
                                <div className="space-y-2">
                                  {true ?
                                    <div className='p-4 space-y-6 bg-white border rounded-lg md:p-6 '>
                                      <div className='flex flex-wrap items-center justify-between gap-4 '>
                                        <h1 className='font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap'>360 Relationship</h1>
                                        <div className='flex flex-row flex-wrap gap-2'>

                                          <div className="flex items-center justify-between w-full overflow-hidden text-sm bg-white border rounded-md md:w-auto ">
                                            <span
                                              className="p-2 rounded-l-full material-symbols-outlined text-slate-600"
                                              style={{ fontSize: "1.4rem" }}
                                            >
                                              search
                                            </span>
                                            <input
                                              type="search"
                                              name="search"
                                              onChange={this.handleSearchSurvey}
                                              className="w-full py-2 md:w-48 focus:outline-none placeholder:text-slate-600"
                                              placeholder="Search"
                                            />
                                          </div>

                                          <button
                                            onClick={() =>
                                              this.handleRelationshipUpload()
                                            }
                                            className="bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] transition-all duration-200 md:w-auto w-full md:py-2 py-3  px-4 rounded-md text-center justify-center cursor-pointer transform  flex items-center text-white space-x-1 md:text-sm  text-md whitespace-nowrap"
                                          >
                                            <span className="text-white material-symbols-outlined ">
                                              add
                                            </span>{" "}
                                            <h1 className="">Add 360 Relationship </h1>
                                          </button>

                                        </div>


                                      </div>

                                      <div className='overflow-x-auto'>
                                        <table className='w-full border border-collapse text-sm  text-[#3D405B] whitespace-nowrap ' >
                                          <tr className='bg-slate-100'>
                                            <th className='p-2 font-semibold border-r' >S.no.</th>
                                            <th className='p-2 font-semibold border-r' >Reviewer Name</th>
                                            <th className='p-2 font-semibold border-r' >Reviewer Email</th>
                                            <th className='p-2 font-semibold border-r' >{"Reviewer Emp Id"}</th>
                                            <th className='p-2 font-semibold border-r' >{"Reviewee Emp Id"}</th>
                                            <th className='p-2 font-semibold border-r' >Reviewer Relation</th>
                                            <th className='p-2 font-semibold border-r' >Relation Type</th>
                                            <th className='p-2 font-semibold border-r' >Active</th>
                                          </tr>
                                          {get360RelationListData && get360RelationListData.length > 0 ?
                                            get360RelationListData.map((item, index) =>

                                              <tr className='p-2 text-sm bg-white border-t '>
                                                <td className='p-2 text-center text-[#3D405B] border-r'>{(index + 1).toString() + ""}</td>
                                                <td className='p-2 text-center text-sm text-[#3D405B] border-r'>{item && item["reviewer_name"] ? item["reviewer_name"] : "-"}</td>
                                                <td className='p-2 text-center text-sm text-[#3D405B] border-r'>{item && item["reviewer_email"] ? item["reviewer_email"] : "-"}</td>
                                                <td className='p-2 text-center text-sm text-[#3D405B] border-r'>{item && item["reviewer_emp_id"] ? item["reviewer_emp_id"] : "-"}</td>
                                                <td className='p-2 text-center text-sm text-[#3D405B] border-r'>{item && item["reviewee_emp_id"] ? item["reviewee_emp_id"] : "-"}</td>
                                                <td className='p-2 text-center text-sm text-[#3D405B] border-r'>{item && item["reviewer_relation"] ? item["reviewer_relation"] : "-"}</td>
                                                <td className='p-2 text-center text-sm text-[#3D405B] border-r'>{item && item["relation_type"] ? item["relation_type"] : "-"}</td>

                                                <td className='text-center'>
                                                  <div className='flex justify-center cursor-pointer'>
                                                    {!item.isDisable ?
                                                      <>
                                                        <div onClick={() => this.handle360DisableStatue(item.id)} className="rounded-full w-11 h-5 p-0.5 bg-green-400">
                                                          <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform translate-x-3 bg-white rounded-full" />
                                                        </div>
                                                      </>
                                                      :
                                                      <>

                                                        <div onClick={() => this.handle360DisableStatue(item.id)} className="rounded-full w-11 h-5 p-0.5 bg-gray-200">
                                                          <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform -translate-x-3 bg-white rounded-full" />
                                                        </div>
                                                      </>
                                                    }
                                                  </div>
                                                </td>



                                              </tr>

                                            ) : null}
                                        </table>
                                      </div>

                                    </div>
                                    : null}

                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}


                        {IndexListIndex && IndexListIndex.length > 1 ?
                          <div className="flex items-center justify-center py-8 text-center">
                            <span onClick={() => this.prevEmpPage()} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-gray-400 border border-transparent rounded-full select-none material-symbols-outlined" style={{ fontSize: '18px', display: 'inline-flex' }}>keyboard_arrow_left</span>
                            {IndexListIndex && IndexListIndex.length > 0 ?
                              IndexListIndex.map((item, index) =>
                                (index >= this.state.EmpIndexA && index <= this.state.EmpIndexB) ?
                                  (item === this.state.currentSurveyPage) ?
                                    <span onClick={() => this.SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-white bg-blue-500 border border-transparent rounded-full cursor-pointer">{item}</span>
                                    :
                                    <span onClick={() => this.SelectEmpPage(item)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm bg-white border rounded-full cursor-pointer">{item}</span>
                                  : null
                              )

                              : null}
                            <span onClick={() => this.nextEmpPage(IndexListIndex)} className="inline-flex items-center justify-center w-8 h-8 mx-1 text-sm text-blue-500 bg-white border rounded-full select-none material-symbols-outlined" style={{ fontSize: '18px', display: 'inline-flex' }}>keyboard_arrow_right</span>
                          </div>
                          : null}
                      </div>

                      :
                      <>
                        {!loading && !showCreateSurveyPopup ?
                          <div className="container h-full px-4 py-12 mx-auto overflow-hidden overflow-y-auto" style={{ height: 'calc(100% - 4rem)' }}>
                            <div className="flex items-center justify-center w-full h-full">
                              {/*  text start*/}
                              <div className="text-center">
                                <img alt='empty_survey' src="img/empty_survey.png" className="mx-auto" />
                                <h1 className="text-lg font-semibold text-[#3D405B]cursor-default">No survey has been created yet.</h1>
                                <p className="text-xs text-gray-500 cursor-default">Let's get started &amp; create your first survey</p>
                                <div className="flex justify-center"><button onClick={() => this.handleCreateSurveyPopup()} className="flex items-center px-6 py-3 my-6 text-sm text-white bg-blue-500 rounded-md focus:outline-none"><span className="mr-2 material-symbols-outlined">add</span> CREATE SURVEY</button>    </div>
                              </div>
                            </div>
                          </div>
                          : null}
                      </>
                    :
                    <>
                      {!loading ?
                        <div className="container h-full px-4 py-12 mx-auto overflow-hidden overflow-y-auto" style={{ height: 'calc(100% - 4rem)' }}>
                          <div className="flex items-center justify-center w-full h-full">
                            {/*  text start*/}
                            <div className="text-center">
                              <img alt='empty_survey' src="img/empty_survey.png" className="mb-4" />
                              <h1 className="text-xl font-semibold text-gray-500 cursor-default">You have no access <br /> to this module!</h1>
                            </div>
                          </div>
                        </div>
                        : null}
                    </>

                  }



                </>}

            </main>




            {showCreateSurveyPopup ?
              (accessAllow("createSurvey")) ?
                <AddSurvey
                  handleCreateSurveyPopupClose={this.handleCreateSurveyPopupClose}
                  handleAddSurveyInput={this.handleAddSurveyInput}
                  handleAddSurveySubmit={this.handleAddSurveySubmit}
                  surveyFormData={surveyFormData}
                  surveyValidation={surveyValidation}
                  handleSurveyType={this.handleSurveyType}
                  handleSurveyRole={this.handleSurveyRole}
                  isCrole={isCrole}
                  showStagesListModal={this.state.showStagesListModal}
                  getLifecycleStageListData={getLifecycleStageListData}
                  selectStage={this.selectStage}
                  crrLifecycleStageId={crrLifecycleStageId}
                  getSurveyOneTemplateData={getSurveyOneTemplateData}
                  selectLinkedSurvey={this.selectLinkedSurvey}
                  crrLinkedSurveyId={crrLinkedSurveyId}
                  clusterAnalysisData={clusterAnalysisData}
                  selectLinkedCluster={this.selectLinkedCluster}
                  crrLinkedCluster={crrLinkedCluster}
                  is360Feedback={true}
                  moduleNumber={4}
                />
                : null
              : null}


            {showUpdateSurveyModal ?
              (accessAllow("uploadUser")) ?
                <UpdateSurvey
                  handleCreateSurveyPopupClose={this.closeUpdateSurvey}
                  updateformSurveyData={updateformSurveyData}
                  handleUpdateSurveyInput={this.handleUpdateSurveyInput}
                  updatehandleSurveyRole={this.updatehandleSurveyRole}
                  handleUpdateSurveySubmit={this.handleUpdateSurveySubmit}
                  isCrole={isCrole}
                />
                : null
              : null}


            {showChooseSurveyModal ?
              <ChooseSurvey
                handleCreateSurveyPopupClose={this.handleChooseSurveyPopupClose}
                handleCreateEditableSurvey={this.handleCustomSurvey}
                handleCreateSurveyFromLibrary={this.handleCreateSurveyFromLibrary}

              />
              : null}

            {CurrentSurveyData ?
              <AddSurveyLibModal
                CurrentSurveyData={CurrentSurveyData}
                handleUseSurveyPage={this.handleUseSurveyPage}

              />
              : null}


            {confirmDeleteSurvey ?
              <DeleteSurveyConfirm
                CurrentSurveyName={CurrentSurveyName}
                handleSendSingleMail={this.onSurveyDelete}
                handleConfirmMailClose={this.handleConfirmDeleteSurveyClose}
              />
              : null}


            {showNODashboardModal ?
              <NoDashboardModal
                handleConfirmMailClose={this.onNoDashboardModal}
              />
              : null}



            {showUploadPreview ?
              <>
                <UploadPreview
                  loadedDataSheet={loadedDataSheet}
                  closeUploadPreview={this.closeUploadPreview}
                  saveUploadRelation={this.saveUploadRelation}
                  handleDataChange={this.handleDataChange}
                  SheetJSFT={SheetJSFT}
                  save360RelationListData={save360RelationListData}


                />
              </>
              : null}


            {/* {showRelationModal ?
              <>
                <div className="fixed top-0 right-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-40 xl:h-screen" >
                  <div className="w-11/12 mx-auto bg-white rounded-lg shadow-sm xl:w-6/12 lg:w-9/12 ">
                 <div className="flex items-center justify-between p-4 border-b md:px-8">
                      <div className='space-y-2' >
                        <h2 className="text-lg font-semibold md:text-xl test-[#3D405B]">Upload 360 Partcipants Data</h2>
                        <p className='text-sm text-gray-500 md:text-base' > Please upload 360 Participants data according to sample and below guidelines.</p>
                      </div>

                      <div onClick={() => this.closeRelationshipModal(false)} className="p-2 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200 " >
                        <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path></svg>
                      </div>
                    </div>

                    <div className='' >
                      <div className='p-4 space-y-2 text-sm text-[#3D405B]' >
                        <h1 className='text-lg font-semibold text-[#3D405B]' > Guidelines for uploading the Participants Data</h1>
                        <ol className='pl-6 space-y-1 list-disc' >
                          <li className='' > <b className=''>Reviewer Name:</b> Name of the person who reviews or gives the feedback for an another person.
                          </li>
                          <li className='' > <b className=''>Reviewer Email:</b> Email Id of the reviewer.</li>
                          <li className='' > <b className=''>Reviewer Emp_id:</b> Employee Id of the reviewer.</li>
                          <li className='' > <b className=''>Reviewee Emp_id:</b> A Reviewee Empoyee Id represent a person who receives the feedback from the Reviewer.</li>
                          <li className='' > <b className=''>Reviewer Relation:</b> It defines the relationship between Reviewer and Reviewee.</li>
                          <li className='' > <b className=''>Relation Type:</b> It defines the Reviewer is from inside(INTERNAL) or outside(EXTERNAL) of the organization.</li>
                        </ol>

                        <p>{"1.  Reviewee Emp Id is mandatory"}</p>
                        <p>2. For relation type "INTERNAL", Reviewer Emp Id is mandatory </p>
                        <p>3. For relation type "EXTERNAL", Reviewer Email is mandatory</p>
                        <p>4. Reviewer Relation is mandatory.</p>
                        <p>5. Relation type is mandatory</p>
                        <p>6. Reviewer Relation 'Self' means reviewer and reviewee is same,giving feedback for self</p>

                      </div>
                    </div>



                    <div className="p-4 space-y-4 border-t">
                      <div className='grid grid-cols-1 gap-4 xl:grid-cols-2 md:grid-cols-2'>
                       <div onClick={() => this.downLoadSampleCSV()} className='flex items-start p-2 px-4 space-x-4 bg-blue-100 rounded shadow-md cursor-pointer 2xl:items-center' >
                          <span className="material-symbols-outlined">
                            cloud_download
                          </span>
                          <div>
                            <h1 className='text-sm font-medium md:text-base' >Download Sample Data</h1>
                            <p className='text-sm text-gray-500' >See the sample data</p>
                          </div>
                        </div>
                        <input type="file"
                          id="file" accept={SheetJSFT} onChange={this.handleDataChange} hidden />
                        <label for="file" className='bg-blue-100 rounded shadow-md cursor-pointer' >
                          <div className='flex items-start p-2 px-4 space-x-4 2xl:items-center ' >
                            <span className="material-symbols-outlined">
                              cloud_upload
                            </span>
                            <div>
                              <h1 className='text-sm font-medium md:text-base' >Master Data Upload</h1>
                              <p className='text-sm text-gray-500' >Upload the master data</p>
                            </div>
                          </div>
                        </label>


                        <span className="material-symbols-outlined">
                          cloud_upload
                        </span>
                        <div>
                          <h1 className='text-sm font-medium md:text-base' >Add </h1>
                          <p className='text-sm text-gray-500' >See the sample format our system</p>
                        </div>
                      </div> 

                      </div>



                      <div className="hidden" >
                        <button onClick={() => this.downLoadSampleCSV()} className=" border border-green-500 rounded-md px-6 py-1.5 text-xs text-green-500 focus:outline-none" >Sample CSV</button>

                        <button onClick={this.handleDataFile} className="ml-22 border border-blue-500 rounded-md px-6 py-1.5 text-xs text-blue-500 focus:outline-none">Continue</button>
                      </div>

                    </div>
                  </div>

                </div>

              </>
              :
              null} */}





            {showRelationModal ? (
              <div className="fixed top-0 right-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-40 xl:h-screen">
                <div className="w-11/12 mx-auto bg-white rounded-lg shadow-sm xl:w-6/12 lg:w-9/12 ">
                  {/* head */}
                  <div className="flex items-center justify-between p-4 border-b md:px-8">
                    <div className='space-y-2' >
                      <h2 className="text-lg font-semibold md:text-xl text-[#3D405B]">Upload 360 Partcipants Data</h2>
                      <p className='text-sm text-[#3D405B] opacity-70 md:text-base' > Please upload 360 Participants data according to sample and below guidelines.</p>
                    </div>
                    {/* <h2 className="text-lg font-semibold md:text-xl test-[#3D405B]">Upload 360 Partcipants Data</h2>
                    <p className='text-sm text-gray-500 md:text-base' >Please upload 360 Participants data according to sample and below guidelines.</p> */}
                    <div
                      onClick={() => this.closeRelationshipModal(false)}
                      className="p-2 rounded-full cursor-pointer bg-slate-100 hover:bg-slate-200 "
                    >
                      <svg
                        className="w-6 h-6 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path>
                      </svg>
                    </div>
                  </div>

                  <div className="m-4 space-y-8 md:m-8 ">
                    {/* data here */}
                    <div className="p-4 space-y-2 text-sm border rounded-md text-[#3D405B]">
                      <h1 className="pb-2 text-lg font-semibold border-b text-[#3D405B]">
                        {" "}  Guidelines for uploading the Participants Data</h1>
                      <ol className="list-disc pl-6 space-y-1.5 py-3 text-[#3D405B] ">
                        <li className=""><b className=''>Reviewer Name:</b> Name of the person who reviews or gives the feedback for an another person.</li>
                        <li className="capitalize"><b className=''>Reviewer Email:</b> Email Id of the reviewer.</li>
                        <li className="capitalize"><b className=''>Reviewer Emp_id:</b> Employee Id of the reviewer.</li>
                        <li className="capitalize"><b className=''>Reviewee Emp_id:</b> A Reviewee Empoyee Id represent a person who receives the feedback from the Reviewer.</li>
                        <li className="capitalize"><b className=''>Reviewer Relation:</b> It defines the relationship between Reviewer and Reviewee.</li>
                        <li className="capitalize"><b className=''>Relation Type:</b> It defines the Reviewer is from inside(INTERNAL) or outside(EXTERNAL) of the organization. </li>
                      </ol>
                      <p>{"1.  Reviewee Emp Id is mandatory"}</p>
                      <p>2. For relation type "INTERNAL", Reviewer Emp Id is mandatory </p>
                      <p>3. For relation type "EXTERNAL", Reviewer Email is mandatory</p>
                      <p>4. Reviewer Relation is mandatory.</p>
                      <p>5. Relation type is mandatory</p>
                      <p>6. Reviewer Relation 'Self' means reviewer and reviewee is same,giving feedback for self</p>
                    </div>

                    <div className="space-y-4">
                      <div className="grid grid-cols-1 gap-4 xl:grid-cols-4 md:grid-cols-2">
                        {/* 1 */}
                        <div
                          onClick={() => this.downLoadSampleCSV()}
                          className="flex items-start p-2 px-4 space-x-4 rounded shadow-sm cursor-pointer 2xl:items-center bg-sky-100 md:col-span-2"
                        >
                          <span class="material-symbols-outlined p-2 bg-white rounded-full text-blue-500 shadow-sm">
                            download
                          </span>
                          <div>
                            <h1 className="text-sm font-medium md:text-base text-[#3D405B] ">Download Sample Data</h1>
                            <p className="text-sm text-[#3D405B]">See the sample data</p>
                          </div>
                        </div>

                        {/* 2 */}
                        <input
                          type="file"
                          id="file"
                          accept={SheetJSFT}
                          onChange={this.handleDataChange}
                          hidden
                        />
                        <label
                          for="file"
                          className="rounded shadow-sm cursor-pointer bg-sky-100 md:col-span-2"
                        >
                          <div className="flex items-start p-2 px-4 space-x-4 2xl:items-center ">
                            <span class="material-symbols-outlined p-2 bg-white rounded-full text-blue-500 shadow-sm">
                              upload
                            </span>
                            <div>
                              <h1 className="text-sm font-medium md:text-base text-[#3D405B] ">
                                Upload Master Data
                              </h1>
                              <p className="text-sm text-[#3D405B]">
                                Upload master data as per the guidelines
                              </p>
                            </div>
                          </div>
                        </label>

                        {/* 3 */}
                        {/* <div className='flex items-start p-2 px-4 space-x-4 bg-blue-100 rounded shadow-md cursor-pointer 2xl:items-center md:col-span-4' >
                                <span class="material-symbols-outlined">
                                  person_off
                                </span>
                                <div>
                                  <h1 className='text-sm font-medium md:text-base' >Add/update/disable employee</h1>
                                  <p className='text-sm text-gray-500' >See the sample format our system</p>
                                </div>
                              </div> */}
                      </div>

                      <div className="hidden">
                        <button
                          onClick={() => this.downLoadSampleCSV()}
                          className=" border border-green-500 rounded-md px-6 py-1.5 text-xs text-green-500 focus:outline-none"
                        >
                          Sample CSV
                        </button>

                        <button
                          onClick={this.handleDataFile}
                          className="ml-22 border border-blue-500 rounded-md px-6 py-1.5 text-xs text-blue-500 focus:outline-none"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}






            {/* 
            {showRelationTable ?
              <>

                <RelationshipTable
                  closeRelationshipTable={this.closeRelationshipTable}
                  get360RelationListData={get360RelationListData}
                  handle360DisableStatue={this.handle360DisableStatue}
                />
              </>
              :
              null} */}




          </div>
        </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(Survey);
