import React, { Component } from 'react';
import { connect } from 'react-redux';
import Help from './Help/Help';

class Commentary extends Component {
  constructor(props) {
    super(props);
    this.state = {
        showModal:false
    }
  }
  componentDidMount() {

  }

  openHelpModal=()=>{
    this.setState({showModal:true})
  }

  render() {
    let { getCommentData, indexType, optionType } = this.props;
    let { showModal } = this.state;


    function getIndexName(key){
        let name = ''
          if     (key===1){ name='Happiness' }
          else if(key===2){ name='Engagement' }
          else if(key===3){ name='Hope' }
          else if(key===4){ name='Stress' }
          else if(key===5){ name='Manager' }
          else if(key===6){ name='Leadership' }
          else if(key===7){ name='Safety' }
          else if(key===9){ name='Diversity' }
          else { name='NaN' }
  
        return name;
      }


      function getOptionName(key,IndexName){
        let name = ''
          if     (key===1){ name=IndexName+' Summary' }
          else if(key===2){ name=IndexName+' Behaviour' }
          else if(key===3){ name='Dimension Priorities' }
          else if(key===4){ name='Response Distribution' }
          else if(key===5){ name='Driver Comparision' }
          else { name='NaN' }
  
        return name;
      }

    let IndexName = getIndexName(indexType)
    let OptionName = getOptionName(indexType,IndexName)

    let name = '';
    let desc = '';

    let flag = 0;
    if(getCommentData && getCommentData.length>0){
        getCommentData.forEach((item)=>{
          // ////console.log('item',item)

            if((item.indexType == indexType) && (item.optionType === optionType)){
                name = item.name;
                desc = item.desc;
                flag = 1 ;

                // ////console.log('item',item)
            }
        })
    }
    ////console.log('indexType, optionType',indexType, optionType)

    return (
      <>
        {flag?<span onClick={()=>this.openHelpModal()} className="cursor-pointer text-xs font-medium text-blue-500 bg-blue-100 px-4 py-2.5 rounded-full">Need Help</span>:null}
        {showModal? <Help IndexName={IndexName} OptionName={OptionName} name={name} desc={desc} closeModal={()=>this.setState({showModal:false})}/> :null}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Commentary);
  