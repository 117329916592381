import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filter from '../../../../../CommonComponents/Filters/IndexFilter/Filter';
import HeatMap from './Components/HeatMap';
import NPSChart from '../../../../Overview/Components/QuestionTypeCards/Charts/NPSChart';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName: 'none',
      viewScore: true,
      showLeaderBoard: false,
      showFilter: false,
      showValue: '',
      XAxis: ""
    }
  }
  componentDidMount() {

  }
  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleFilterSave = (name) => {
    this.setState({ currFilterName: name })
  }

  handleMultifilterIndexScore2 = (finalFilter, XAxis) => {
    let { handleOthersFilterCall } = this.props;
    this.setState({ FilterValues: finalFilter, XAxis: XAxis });
    handleOthersFilterCall(finalFilter, XAxis);
  }

  render() {
    let {
      getQuestionOthersResultData, getQuestionOthersResultFilterData, currentQuestionId, EmpFilterData, getEMPFilterLabel } = this.props;

    let { currFilterName, FilterValues, XAxis } = this.state;

    //-------------------------------------------------------------------------------------------------------------------------

    let nEwAxis = "";
    if (XAxis) {
      nEwAxis = XAxis;
    }
    else if (EmpFilterData && EmpFilterData.length > 0) {
      nEwAxis = EmpFilterData[0].name;
    }

    let QuestionName = "";

    function FormattFun(inputData) {
      let LIST = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {


          if (currentQuestionId.toString() === (ques.total[0]._id.questionId).toString()) {


            let temp = {
              name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
              answerList: []
            }
            QuestionName = temp["name"];

            if (ques && ques.total && ques.total.length > 0) {
              ques.total.forEach((item) => {
                let ans = {
                  count: item.count,
                  answerText: item["_id"]["answer"],
                  template: item["_id"]["qtemplate"],
                  dmgValue: item["_id"][nEwAxis]
                }
                temp["answerList"].push(ans);
              });
            }
            LIST.push(temp);
          }
        });
      }
      return LIST
    }

    let NPSQuesList = FormattFun(getQuestionOthersResultData);
    let NPSQuesListFilter = FormattFun(getQuestionOthersResultFilterData);
    let totalCount = 0;
    let Promoters = 0;
    let Passsives = 0;
    let Distractors = 0;
    let DemographicValues = [];
    let DemographicValuesObj = {};
    let item = NPSQuesList[0];
    let itemOthers = NPSQuesListFilter[0];

    let AnswerObjOverall = {};
    if (item && item.answerList && item.answerList.length > 0) {
      item.answerList.forEach((ans) => {
        ////console.log('ans (NPS)',ans)

        if (AnswerObjOverall && AnswerObjOverall[ans.answerText]) {
          AnswerObjOverall[ans.answerText] += (ans.count);
        }
        else {
          AnswerObjOverall[ans.answerText] = (ans.count)
        }

      });
    }

    let AnswerObj = {};
    let AnswerObjList = [];

    if (itemOthers && itemOthers.answerList && itemOthers.answerList.length > 0) {
      itemOthers.answerList.forEach((ans) => {

        let getIndex = DemographicValues.findIndex(prev => prev === ans.dmgValue);
        if (getIndex === -1) {
          DemographicValues.push(ans.dmgValue);
          DemographicValuesObj[ans.dmgValue] = {
            "Promoters": 0,
            "Passsives": 0,
            "Distractors": 0,
            "count": 0
          }
        }
        ////console.log('ans (NPS)',ans)
        ////console.log('dmgValue (NPS)',ans.dmgValue)

        let code = ans.answerText + ans.dmgValue;

        let getIndex1 = AnswerObjList.findIndex(prev => prev.code === code);
        if (getIndex1 === -1) {
          AnswerObjList.push({
            "text": ans.answerText,
            "name": ans.dmgValue,
            "value": ans.count,
            "code": ans.answerText + ans.dmgValue
          });
        }
        else {
          AnswerObjList[getIndex1].value += ans.count;
        }

      });
    }

    if (AnswerObjList) {

      //console.log('--->AnswerObjList:',AnswerObjList)
      //console.log('--->DemographicValues:',DemographicValues)


      AnswerObjList.forEach(item1 => {

        let key = item1.text;
        let name = item1.name;
        let score = item1.value;

        totalCount++;
        DemographicValuesObj[name]["count"] += score;

        if (parseInt(key) >= 9 && parseInt(key) <= 10) {

          DemographicValuesObj[name]["Promoters"] += score;
          //  Promoters+=score;

        }
        if (parseInt(key) >= 7 && parseInt(key) <= 8) {

          DemographicValuesObj[name]["Passsives"] += score;

          // Passsives+=score;

        }
        if (parseInt(key) >= 0 && parseInt(key) <= 6) {
          DemographicValuesObj[name]["Distractors"] += score;

          //  Distractors+=score;
        }

      });
    }

    let PromotersPer = Math.round(Promoters * 100 / totalCount);
    let PasssivesPer = Math.round(Promoters * 100 / totalCount);
    let DistractorsPer = Math.round(Distractors * 100 / totalCount);

    let npsScore = Math.round((PromotersPer) - (DistractorsPer));

    let PromotersColorRange = PromotersPer / 100;
    let PasssivesColorRange = (PasssivesPer / 100) + PromotersColorRange;
    let DistractorsColorRange = (DistractorsPer / 100) + PasssivesColorRange;

    let { getIndexFilterData } = this.props;

    return (
      <>
        <div className="items-center justify-between md:flex">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData}
            getIndexFilterData={getIndexFilterData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            getEMPFilterLabel={getEMPFilterLabel}
          />

          {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} /> */}

        </div>

        <div onClick={() => this.closeFilter(false)} className="">
          <div className="flex w-full" >

            {!(FilterValues && FilterValues.length > 0) && false ?
              <>
                <div className="w-full px-4 pb-10 bg-white border rounded">
                  <h1 className="font-medium xl:text-xl text-lg text-[#3D405B]">{QuestionName}
                    {/* <p className="text-sm font-semibold text-gray-500">NPS QUESTION</p> */}
                  </h1>
                  {item && item.answerList[0] && item.answerList[0].template === 6 ?
                    <NPSChart AnswerObj={AnswerObjOverall} name={"Overall"} templateId={6} stage={3} />
                    : null}
                </div>
              </>
              :
              <div className="w-full p-5 bg-white border rounded mb-60">
              <div className="flex items-center justify-between px-1 text-base font-semibold text-gray-700">
                <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap mb-4">{QuestionName}
                  {/* <p className="text-sm font-semibold text-gray-500">Single Choice Question</p> */}
                  <p className="text-base font-semibold text-gray-700">
                    <span className="text-blue-500">{(currFilterName !== 'none') ? "( " + currFilterName + " wise )" : ""}</span></p>
                </h1>
              </div>

                <HeatMap
                  AnswerObjOverall={AnswerObjOverall}
                  DemographicValues={DemographicValues}
                  DemographicValuesObj={DemographicValuesObj}
                  XAxis={XAxis}
                />
              </div>
            }

          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
