import { dataTool } from 'echarts';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Actions from '../Actions/Actions';
// import Resource from '../TaskResouce/Resource/Resource';
// import Task from '../TaskResouce/Task/Task';
// import TaskResource from '../TaskResouce/TaskResource';
// import CompleteModal from './CompleteModal';

class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { ParameterList , onSelectDimension, CurrentDimensionId} = this.props;


    // getManagerActionPlanData,currentParameterId
    return (
      <> 

            <div className=" xl:w-5/12 lg:w-9/12 w-full ">
              <h1 className="px-3  text-md font-medium flex items-center text-gray-900">Recommend Dimensions 
              {/* <p className="ml-2">({ParameterList && ParameterList.length>0?ParameterList.length:0})</p> */}
              </h1>  
              <div className="p-3 overflow-y-auto overflow-hidden customscroll4 h-full">
                
                
            {ParameterList && ParameterList.length>0?
              ParameterList.map((parameter)=>
                <div onClick={()=>onSelectDimension(parameter)} className={
                  CurrentDimensionId===parameter.id?
                  "flex p-4 bg-white  mb-2  rounded-md cursor-pointer items-center border-l-4 border-blue-500 border-transparent shadow-xl":
                  "flex p-4 bg-white  mb-2  rounded-md cursor-pointer items-center border-l-4  border-transparent shadow-sm"
                }>
                  <div className><h1 className="text-3xl font-light text-blue-500 rounded-full h-16 bg-blue-100 w-16  flex items-center justify-center ">{parameter&&parameter.score?parameter.score.toFixed(0):"0"}</h1></div>
                  <div className="pl-3"><h1 className="text-sm font-semibold">{parameter&&parameter.name?parameter.name:""}</h1>
                    <p className="text-xs text-gray-500 py-2">{parameter&&parameter.desc?parameter.desc:""}</p>
                    <p className="text-xs text-gray-500 ">Assigned!</p></div>
                </div>
              ):null}



              </div>
            </div>



{/* 
        <div className="bg-white xl:w-5/12 lg:w-9/12 w-full">
          <h1 className="p-3 border-b text-sm font-medium">Recommend Dimensions</h1>  
          <div className="p-3 overflow-y-auto overflow-hidden h-full customscroll4" style={{height: 'calc(100% - 4rem)'}}>

            {ParameterList && ParameterList.length>0?
            ParameterList.map((parameter)=>
            <div onClick={()=>onSelectDimension(parameter)} className={CurrentDimensionId===parameter.id?"flex p-4 border mb-2 border-blue-500 rounded-sm cursor-pointer":"flex p-4 border mb-2 hover:border-blue-500 rounded-sm cursor-pointer"}>
                <div className="bg-blue-500 w-26 h-20 text-white flex items-center justify-center"><h1 className="text-2xl mx-2 font-light">
                {parameter&&parameter.score?parameter.score.toFixed(2):"0"}</h1></div>
                <div className="pl-3"><p className="text-sm text-blue-500">{parameter&&parameter.name?parameter.name:""}</p><p className="text-xs text-gray-500">{
                    parameter&&parameter.desc?parameter.desc:""
                }</p>
                <p className="text-xs text-gray-500 pt-2">Assigned!</p>

                </div>
            </div>
            )
            :null}

          </div>
        </div> */}



      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dimension);
