import React, { Component } from 'react'
// import Sidebar from "../Sidebar/Sidebar";
// import Profile from "../Profile/Profile";
// import { dashboardActions } from "../../_actions";
import { connect } from "react-redux";

// import moment from "moment"

class GrowMonth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType1: true,
      showMusic: true,
      viewType2: false,
      width: 0, height: 0
    };
    // this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }
  componentDidMount() {


    // let id = this.props.match.params.id


    // this.props.dispatch(dashboardActions.getGrowWeekListbyMonthId({ monthId: id }, this.props));


    // console.log("dataMonth__________fsdfsd",dataMonth);

    // let data = {
    //   "keyWord": "",
    //   "pageNo": 1,
    //   "size": this.state.size
    // }
    // this.props.dispatch(dashboardActions.getGrowMonth());
    // this.props.dispatch(dashboardActions.getGrowParameterList(data));

  }


  // leading = (item) => {

  //   console.log("itemitemitemitemitem____",item);

  //       if (item.isStart === 0) {
  //         this.setState({ newid: item.id })
  //         let data = { monthId: item.id }
  //         this.props.dispatch(dashboardActions.startGrowMonth(data, this.props))
  //         let monthname = { item }
  //         this.props.history.push('/app/leadingself/'+item.id)
  //       }
  //       else {
  //         let monthname = { item }
  //         this.props.history.push('/app/leadingself/'+item.id)
  //       }

  //     }


  leadingInner = (item) => {

    console.log("item______________::", item);
    let alsoCall = "getGrowWeekListbyMonthId"
    let { leading_startGrowWeek_getGrowDayListbyWeekId, setLeaderSelfInnerName } = this.props;

    if (item.isStart === 0) {
      // this.setState({showtrue:true})
      this.setState({ newid: item.id })
      this.setState({ newids: item.monthId })
      let alldata = { item }
      // this.props.history.push('/app/leadingselfinner/' + item.monthId + "/" + item.id)
      let data = { weekId: item.id, monthId: item.monthId }
      // this.props.dispatch(dashboardActions.startGrowWeek(data, this.props))

      leading_startGrowWeek_getGrowDayListbyWeekId(true, data, alsoCall);

    }
    else {
      let alldata = { item }
      // this.props.history.push('/app/leadingselfinner/' + item.monthId + "/" + item.id)
      let data = { weekId: item.id, monthId: item.monthId }
      leading_startGrowWeek_getGrowDayListbyWeekId(false, data, alsoCall);

    }

    setLeaderSelfInnerName(item && item.dimensionId && item.dimensionId.name?item.dimensionId.name:"")
  }


  // leadingInner = (item) => {

  //   console.log("item______________::", item);

  //   if (item.isStart === 0) {
  //     // this.setState({showtrue:true})
  //     this.setState({ newid: item.id })
  //     this.setState({ newids: item.monthId })
  //     let alldata = { item }
  //     this.props.history.push('/app/leadingselfinner/' + item.monthId + "/" + item.id)
  //     let data = { weekId: item.id, monthId: item.monthId }
  //     // this.props.dispatch(dashboardActions.startGrowWeek(data, this.props))
  //   }
  //   else {
  //     let alldata = { item }
  //     this.props.history.push('/app/leadingselfinner/' + item.monthId + "/" + item.id)
  //   }

  //   callLeadingInner(item.isStart === 0,"startGrowWeek",item)
  // }


  render() {
    let { getWeekListbyMonthId, crrMonthName, goBackToScreen } = this.props;
    return (

      <div className="space-y-6 overflow-y-auto" style={{ height: 'calc(100% - 4rem)' }}>
        <div className='flex items-center space-x-4'>
          <span onClick={()=>goBackToScreen(1)}  class="cursor-pointer material-symbols-outlined bg-blue-100 p-2 rounded-full text-blue-500 ">arrow_back</span>
          <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">{crrMonthName?crrMonthName:"Grow App"}</h1>
        </div>



        {/* <Profile /> */}
        <main className="grid grid-cols-1 gap-6 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">

          {
            getWeekListbyMonthId && getWeekListbyMonthId.length > 0 ?
              getWeekListbyMonthId.map((item, index) => (


                <div className="h-auto overflow-hidden border rounded-lg shadow-lg ">
                  <img className="block object-cover object-top w-full h-52" src={item.dimensionId.imageLinkV2} />
                  <div className="flex flex-col justify-between p-4 bg-white ">
                    <h1 className="mb-2 text-lg font-semibold capitalize text-slate-800">{item.dimensionId.name}</h1>
                    <p className="text-sm font-medium text-gray-500 capitalize">{item.dimensionId.tagline}</p>
                    <button className="p-2 mt-4 text-base text-white bg-blue-500 rounded-full shadow-lg cursor-pointer text-grey-darker hover:bg-blue-600 hover:text-white" type='button'
                      onClick={() => this.leadingInner(item)}>Read more</button>
                  </div>
                </div>



              )) : null
          }
        </main>


      </div>

    )
  }
}

function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users, dashboard } = state;
  return {
    loggingIn,
    questions,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(GrowMonth)