import { dataTool } from 'echarts';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';

class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }

  render() {
    let { onComplete, onStartTask, navigate, CurrentActionData, CurrentTaskData, CurrentDimensionData } = this.props;
    // let TaskList = [];
    // if(getManagerActionPlanData && getManagerActionPlanData.length>0){
    //   getManagerActionPlanData.forEach((action)=>{
    //     if(action && action.id===CurrentActionId){
    //       if(action && action.TaskList && action.TaskList.length>0){
    //         TaskList = action.TaskList;
    //       }
    //     }
    //   });
    // }


    //1: start
    //2: pending
    //3: completed


    let taskStatus = 1
    if (CurrentTaskData && CurrentTaskData.isStarted) {
      if (CurrentTaskData && CurrentTaskData.isComplete) {
        taskStatus = 3;
      }
      else {
        taskStatus = 2;
      }
    }

    return (
      <div className="w-full p-6  bg-white capitalize lg:col-span-2 border rounded-lg space-y-4" >
        <div className="flex items-center   space-x-2">
          <button onClick={() => navigate("tasklist")} className='bg-sky-100 p-2 rounded-full'>
            <TbChevronLeft className='text-[1.5rem]' />
          </button>
          <h1 onClick={() => navigate("tasklist")} className="cursor-pointer text-lg font-medium ">{CurrentActionData && CurrentActionData.name ? CurrentActionData.name : ""}</h1>
          <p onClick={() => navigate("actionlist")} className="cursor-pointer text-sm pl-4 border-l text-[#3D405B]/70  font-medium">{CurrentDimensionData && CurrentDimensionData.name ? CurrentDimensionData.name : ""}</p>
          <TbChevronRight className='text-[#3D405B]/70' />
          <p className="text-sm text-sky-500 font-medium">{CurrentActionData && CurrentActionData.name ? CurrentActionData.name : ""}</p>
        </div>
        {/* prog */}
        {taskStatus === 1 ?
          <div className='border-b w-full flex items-end'>
            <div style={{ background: '#1ca8ff' }} className="h-1.5 w-1/12 "> </div>
          </div>
          : null}
        {taskStatus === 2 ?
          <div className='border-b w-full flex items-end'>
            <div style={{ background: '#ffd602' }} className="h-1.5 w-5/12"> </div>
          </div>
          : null}
        {taskStatus === 3 ?
          <div className='border-b w-full flex items-end'>
            <div className="h-1.5 bg-green-400 w-12/12"> </div>
          </div>
          : null}


        {/* boxes */}
        <div className="flex justify-center md:items-center h-full">
          {/* box 1 */}
          <div className="max-w-sm lg:-mt-20 my-6 relative ">
            <span className="bg-slate-100 py-2 px-4 rounded-md text-[#3D405B] border text-sm font-medium">{
              CurrentTaskData && CurrentTaskData.number ? "Task no. " + CurrentTaskData.number : "Task"}</span>
            <h1 className=" lg:text-2xl text-xl font-semibold py-8 normal">
              {CurrentTaskData && CurrentTaskData.name ? CurrentTaskData.name : ""}
            </h1>
            {/* btn */}
            {taskStatus === 1 ?
              <button onClick={() => onStartTask()} className="uppercase text-white font-semibold rounded-md w-full text-sm p-4  
          " style={{ background: '#1ca8ff' }}>start</button>
              : null}
            {taskStatus === 2 ?
              <button onClick={() => onComplete()} className="uppercase text-gray-800 font-semibold rounded-md w-full text-sm p-4  
          " style={{ background: '#ffd602' }}>pending</button>
              : null}
            {taskStatus === 3 ?
              // <button className="uppercase text-gray-800 font-semibold rounded-md w-full text-sm p-4  
              // " style={{background: '#ffd602'}}>completed</button>
              <button className="uppercase text-white font-semibold rounded-md w-full text-sm p-4 bg-green-400 
           flex items-center justify-center">completed
                <span className="material-symbols-outlined ml-2">check_circle</span></button>
              : null}
          </div>

          {/* bottom prog */}
          {/* <div className="lg:absolute  bottom-10 left-0 border-t w-full flex justify-between items-center py-2 mt-8">
          <span className="material-symbols-outlined bg-gray-200 p-1 rounded-md">
            chevron_left
          </span>
          <div className="font-medium text-blue-500"> 1 <b className="text-gray-800">/6</b></div><b className="text-gray-800">
            <span className="material-symbols-outlined bg-blue-500 text-white p-1 rounded-md">
              chevron_right
            </span>
          </b></div> */}


        </div>
      </div>


    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dimension);
