import * as React from 'react';

const PageTemplate = props => {

  let Number1 = props && props.pageNum ? props.pageNum : 0;
  let SurveyLogo = props && props.surveyLogo ? props.surveyLogo : "/img/logo/happypluslogo.png";

  let pageNo = 0
  if (Number1 > 1) {
    pageNo = Number1 - 1
  }

  return (
    <>

      <div className='absolute flex items-center justify-between bottom-10 left-16' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='pt-4 mr-20'>
          <img src={"/img/logo/TalentPlusStrengths_logo.svg"} className="object-cover w-32 mx-4" alt="default_survey_logo" />
        </div>


        <div className='pt-2'>
          {
            pageNo > 0 ?
              <p className='text-center text-[#212121]/85 font-bold pb-1'>{pageNo}</p> : null
          }
          <p className='text-sm text-[#212121]/70'>Copyright © 2024, HappyPlus, Inc. All rights reserved</p>
        </div>



        <div className='ml-14'>
          <img src={"/img/logo/happyplus-logo-with-powered-by.svg"} className="object-cover w-32 mx-4 " alt="default_survey_logo" />
        </div>
      </div>

      {/*   <div style={{ position: "absolute", bottom: "30px", left: '50px' }}>
        <div className='flex items-center justify-between' style={{ justifyContent: 'space-between' }}>
          <img src={"/img/logo/TalentPlusStrengths_logo.svg"} className="object-cover w-32 mx-4 " alt="default_survey_logo" />
        </div>
      </div>

      <div style={{ position: "absolute", bottom: "30px", left: '50px' }}>
        <div className='flex items-center justify-between' style={{ justifyContent: 'space-between' }}>
          <p>Copyright © 2024, HappyPlus, Inc. All rights reserved</p>
        </div>
      </div>


      <div className='py-4' style={{ position: "absolute", bottom: "10px", right: '20px' }}>
        <img src={"/img/logo/happyplus-logo-with-powered-by.svg"} className="object-cover w-32 mx-4 " alt="default_survey_logo" />
      </div> */}

    </>);
};

export default PageTemplate;