import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../_helpers';

//api call
import { surveyActions, dashboardActions, generalActions, questionsActions } from '../../_actions';

//libraries
import LoadingOverlay from 'react-loading-overlay';

//Layout

import Header from '../CommonComponents/DashboardHeader/Header';
import Sidebar from './Sidebar/Sidebar';
import Overview from './Overview//Overview';
import Index from './Index/Index';
import TextAnalysis from '../Dashboard/TextAnalysis/TextAnalysis';
import QuestionDetails from './QuestionDetails/QuestionDetails';

import CustomScoreIndex from '../Dashboard/CustomScoreIndex/CustomScoreIndex';
import AdvanceComparision from './AdvanceComparision/AdvanceComparision';
import OrgCore from '../Dashboard/OrgCore/OrgCore';



class GeneralDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IndexNo: 0,
      indexType: 0,
      optionType: 0,

      CurrentSurveyName: '',
      CurrentSurveyId: this.props.match.params.id,
      // "5fa04d316ede0f0336066cce"
      showOverview: true,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showOrgCore: false,
      ItemSaveData: '',
      cardType: '',
      currentQuestionId: '',
      currentTextQuesId: '',
      currentQuestionTemplate: '',

      itemSurveyReport: false,
      itemOverview: false,
      itemAllQuestions: false,

      showAllQues: false,
      customeScoreData: "",
      showCustomScoreCard: false,
      templateList: [1, 2, 5, 6, 8, 9, 11, 16]


    }
  }
  componentDidMount() {

    this.onOverview()

    this.props.dispatch(dashboardActions.GetTextDistHighlights({}, true));


    this.props.dispatch(dashboardActions.getComment(
      {
        "keyWord": "",
        "pageNo": 1,
        "size": 500
      }
    ));

  }




  ChooseSurvey = (val) => {

    // console.log('CurrentSurveyId: -------->',val)

    this.setState({ CurrentSurveyId: val })


  }

  onOverview = () => {
    this.setState({
      showOverview: true,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showOrgCore: false,
      showQuesCard: false,
      showCustomScoreCard: false
    });

    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }


    this.props.dispatch(dashboardActions.getClientProfile(data1));


    let data2 = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getEmpFilter(null, null, data2));

    this.props.dispatch(surveyActions.getSurveyById(data2));
    this.props.dispatch(dashboardActions.getRespondantDetails(data2));
    this.props.dispatch(generalActions.getQuestionOthersResult({
      surveyId: this.state.CurrentSurveyId,
      qtemplateList: this.state.templateList
    }));
    this.props.dispatch(generalActions.getQuestionDMGResult({
      surveyId: this.state.CurrentSurveyId,
      qtemplateList: [21, 22]
    }));
    this.props.dispatch(surveyActions.getIndexByFilterDMG());

  }

  onCardClick = (cardType, item, index, check) => {

    if (check) {
      let { dashboard } = this.props;
      let { EmpFilterData } = dashboard;
      if (EmpFilterData && EmpFilterData.length > 0) {
        this.handleDemographicFilter(EmpFilterData[0].name);
      }
      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showIndex: true,
        showTextAnalysis: false,
        showAdvanceInsight: false,
        showOrgCore: false,
        showQuesCard: false,
        showCustomScoreCard: false,
        IndexNo: index,
        cardType: cardType,
        ItemSaveData: item
      });
    }

  }

  handleSidebarClick = (isIndex, data, index, check) => {
    if (isIndex) {
      this.onCardClick('index', data, index, check);
    }
    else {
      this.onOverview()
      // this.setState({
      //   showOverview:true,
      //   showAllQuestions:false,
      //   showIndex:false,
      //   showTextAnalysis:false,
      //   showAdvanceInsight:false,
      //   showQuesCard:false,
      //   IndexNo:0,
      //   ItemSaveData:'',
      //   CurrentSurveyId:this.state.CurrentSurveyId
      // });
    }
  }



  handleSidebarExtra = (type) => {
    if (type === 1) {


      let data2 = {
        "surveyId": this.state.CurrentSurveyId
      }
      let data2x = {
        "surveyId": this.state.CurrentSurveyId,
        "otherType": [3, 4, 23]
      }
      this.props.dispatch(dashboardActions.getTextQuestion(data2x, 1));
      this.props.dispatch(dashboardActions.GetTextKeywords(data2));

      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showIndex: false,
        showTextAnalysis: true,
        showAdvanceInsight: false,
        showOrgCore: false,
        showQuesCard: false,
        showCustomScoreCard: false,
        IndexNo: 0
      });


    }
    else if (type == 2) {

      this.onCrossTab()


      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showIndex: false,
        showTextAnalysis: false,
        showAdvanceInsight: true,
        showOrgCore: false,
        showQuesCard: false,
        showCustomScoreCard: false,
        IndexNo: 0
      });


    } else if (type == 3) {
      let data10 = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10000
      }
      this.props.dispatch(surveyActions.getParameterList(data10));


      this.onOptionType(null, 8, 1);


      this.setState({
        showOverview: false,
        showAllQuestions: false,
        showIndex: false,
        showTextAnalysis: false,
        showAdvanceInsight: false,
        showOrgCore: true,
        showQuesCard: false,
        showCustomScoreCard: false,
        IndexNo: 8
      });

    }

  }


  //------------------------------------------------------------------------------------------------------------------------
  handleCrossTabApi = (QuesID1, QuesID2, FilterValues) => {

    let data = {
      // "xAxis": "Department",
      "surveyId": this.state.CurrentSurveyId,
      "questionId1": QuesID1.id,
      "questionId2": QuesID2.id,
      "filters": FilterValues
    }
    // this.props.dispatch(dashboardActions.crossTab(data));

    this.props.dispatch(dashboardActions.crossTabAnalysis(data));


  }



  handleDemographicFilter = (value) => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": value
    }
    // this.props.dispatch(generalActions.getMeanScore(data2));
    this.props.dispatch(generalActions.getMeanScoreQwise(data2));

  }
  handleQuestionMeanScore = (value) => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": value
    }
    this.props.dispatch(generalActions.getMeanScoreQwise(data2));
  }

  handleDemographicOverallQFilter = (value) => {
    // //console.log('value handleDemographicOverallQFilter',value)
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": value
    }
    this.props.dispatch(generalActions.getMeanScoreQwise(data2));

  }


  //------------------------------------------------------------------------------------------------------------------------

  handleQuestion = () => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": []
    }
    this.props.dispatch(generalActions.getQuestionDist(data2));
    this.props.dispatch(generalActions.getQuestionDistOverall(data2));

  }

  handleResponseDistributionFilter = (name, value) => {
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": [{
        "level": name,
        "value": value
      }]
    }
    this.props.dispatch(generalActions.getQuestionDist(data2));
  }
  //------------------------------------------------------------------------------------------------------------------------


  handleDMGCloudDemographicFilter = (QuesId, FilterValues, dmgValue, type, wordList) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters

    }

    let data2xy = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters,
      "dmgValue": dmgValue

    }


    if (dmgValue === "All") {
      data2xy = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "filters": Filters

      }
    }




    console.log("------------------------------type====", type)

    if (type === "advance") {
      this.props.dispatch(dashboardActions.AdvanceWordCloud(data2));
    }
    else if (type === "wordCloud") {
      this.props.dispatch(dashboardActions.wordCloud(data2xy));
    }
    else if (type === "topicCloud") {
      this.props.dispatch(dashboardActions.topicCloud(data2xy));
    }
    else if (type === "neighborGraph") {
      this.props.dispatch(dashboardActions.neighborGraph(data2xy));
    }
    else if (type === "topicModelling") {

      let data2x = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "wordList": wordList,
        // "wordwithKeywordList":wordList,
        "filters": Filters

      }
      ////////console.log("topic input ------------->",data2x)

      this.props.dispatch(dashboardActions.topicModelling(data2x));
    }
    else if (type === "topicModellingCustom") {

      let data2x = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "wordList": wordList,
        // "wordwithKeywordList":wordList,
        "filters": Filters,

      }
      ////////console.log("topic input ------------->",data2x)

      this.props.dispatch(dashboardActions.topicModelling(data2x));
    }
    else {
      let data3 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "xAxis": FilterValues && FilterValues.length > 0 ? FilterValues[0].level : "Department"
      }
      if (type === "textDistTreeMap") {
        this.props.dispatch(dashboardActions.textDistTreeMap(data3));

      }
      else if (type === "textDist") {
        this.props.dispatch(dashboardActions.textDist(data2xy));
        this.props.dispatch(dashboardActions.GetTextDistHighlights({}, true));
      }
      else {
        let data32 = {
          "surveyId": CurrentSurveyId,
          "questionId": QuesId,
          "xAxis": FilterValues && FilterValues.length > 0 ? FilterValues[0].level : "Department",
          "filters": Filters

        }
        this.props.dispatch(dashboardActions.TreeMap(data32));
      }

    }



  }



  handleWordCloudDemographicFilter = (QuesId, FilterValues, blank, type, wordList) => {
    let { CurrentSurveyId } = this.state;
    this.setState({ currentTextQuesId: QuesId });

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters

    }

    console.log("------------------------------type====", type)

    if (type === "advance") {
      this.props.dispatch(dashboardActions.AdvanceWordCloud(data2));
    }
    else if (type === "wordCloud") {
      let data2X21 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId
      }

      this.props.dispatch(dashboardActions.getAllFeedbackWords(data2X21));
      this.props.dispatch(dashboardActions.wordCloud(data2));

    }
    else if (type === "topicModelling") {

      let data2x = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "wordList": wordList,
        // "wordwithKeywordList":wordList,
        "filters": Filters

      }
      ////////console.log("topic input ------------->",data2x)

      this.props.dispatch(dashboardActions.topicModelling(data2x));
    }
    else if (type === "topicModellingCustom") {

      let data2x = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "wordList": wordList,
        // "wordwithKeywordList":wordList,
        "filters": Filters

      }
      ////////console.log("topic input ------------->",data2x)

      this.props.dispatch(dashboardActions.topicModelling(data2x));
    }
    else {
      let data3 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "xAxis": FilterValues && FilterValues.length > 0 ? FilterValues[0].level : "Department"
      }
      if (type === "textDistTreeMap") {
        this.props.dispatch(dashboardActions.textDistTreeMap(data3));

      }
      else if (type === "textDist") {
        this.props.dispatch(dashboardActions.textDist(data2));
        this.props.dispatch(dashboardActions.GetTextDistHighlights({}, true));
      }
      else {
        let data32 = {
          "surveyId": CurrentSurveyId,
          "questionId": QuesId,
          "xAxis": FilterValues && FilterValues.length > 0 ? FilterValues[0].level : "Department",
          "filters": Filters

        }
        this.props.dispatch(dashboardActions.TreeMap(data32));
      }

    }



  }



  callWordCloudText = (QuesId, keyWord, FilterList, FilterValue, isMultiKeyword) => {
    let { CurrentSurveyId } = this.state;

    let templateId = 0;
    let { dashboard } = this.props;
    let { getTextQuestionData, getAllFeedbackWordsData } = dashboard;
    if (getTextQuestionData && getTextQuestionData.length > 0) {
      let getIndex = getTextQuestionData.findIndex(prev => prev.id === QuesId);
      if (getIndex !== -1) {
        templateId = getTextQuestionData[getIndex]["templateId"]
      }
    }


    if (isMultiKeyword) {
      let data2 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "keyWord1": keyWord && keyWord[0] ? keyWord[0] : "",
        "keyWord2": keyWord && keyWord[1] ? keyWord[1] : "",
        "filters": FilterList && FilterList.length > 0 ? FilterList : []
      }

      if (templateId === 23 && getAllFeedbackWordsData && getAllFeedbackWordsData.length > 0) {
        data2["dmgValue"] = getAllFeedbackWordsData[0]
      }

      this.props.dispatch(dashboardActions.wordCloudMultiText(data2));
    }
    else {
      let data2 = {
        "surveyId": CurrentSurveyId,
        "questionId": QuesId,
        "keyWord": keyWord,
        "filters": FilterList && FilterList.length > 0 ? FilterList : []
      }

      if (templateId === 23 && getAllFeedbackWordsData && getAllFeedbackWordsData.length > 0) {
        data2["dmgValue"] = getAllFeedbackWordsData[0]
      }

      this.props.dispatch(dashboardActions.wordCloudText(data2));
    }
  }




  callQuestionWordCloud = (QuesId) => {
    let { CurrentSurveyId } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": []
    }
    this.props.dispatch(dashboardActions.wordCloud(data2));
  }


  onWorldCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "otherType": [3, 4, 23]
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 1));

  }




  onNetworkGraph = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "otherType": [3, 4, 23]
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 2));

  }

  handleNetworkDemographicFilter = (Qid, FilterValues, graphType) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": Qid,
      "filters": Filters
    }

    if (graphType === 1) {
      this.props.dispatch(dashboardActions.cooccurmatrix(data2));
    }
    else if (graphType === 2) {
      this.props.dispatch(dashboardActions.neighborGraph(data2));
    }

  }

  handleNeighborGraph = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "otherType": [3, 4, 23]
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 3));

  }

  handleOverview = () => {
    // this.overviewAPI();
    if (this.state.showOverview) {
      this.props.history.goBack()
    }
    else {
      this.onOverview();

      if (this.state.showQuesCard) {
        this.setState({
          showOverview: true,
          showAllQuestions: true,
          showIndex: false,
          showTextAnalysis: false,
          showAdvanceInsight: false,
          showOrgCore: false,
          showQuesCard: false,
          showCustomScoreCard: false,
        })
      }
      else {
        this.handleSidebarClick(false)
      }

    }
  }


  // onNPSCardClick=(item)=>{

  //   console.log('item:',item);
  //   console.log('item Qid:',item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"");


  //   this.setState({
  //     showOverview:false,
  //     showIndex:false,
  //     showTextAnalysis:false,
  //     showQuesCard:true,
  //     currentQuestionId:item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"",
  //     currentQuestionTemplate:item.answerList&&item.answerList[0]&&item.answerList[0].template?item.answerList[0].template:""

  //   });
  // }


  onOthersCardClick = (item) => {

    console.log('item:', item);
    // console.log('item Qid:',item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"");



    this.setState({
      showOverview: false,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showOrgCore: false,
      showQuesCard: true,
      showCustomScoreCard: false,
      currentQuestionId: item && item.question && item.question.id ? item.question.id : "",
      currentQuestionTemplate: item && item.templateId ? item.templateId : ""

    }, () => {


      let { currentQuestionTemplate } = this.state;

      let { dashboard } = this.props;
      let { EmpFilterData } = dashboard;

      let AxisInit = "Department";
      if (EmpFilterData && EmpFilterData.length > 0) {
        EmpFilterData.forEach((item, index) => {
          if (item.level && index === 0) {
            AxisInit = item.level;
          }
        });
      }

      if (currentQuestionTemplate !== 13) {
        let data7 = {
          "surveyId": this.state.CurrentSurveyId,
          "qtemplateList": [currentQuestionTemplate],
          "xAxis": AxisInit,
          "filters": []
        }
        this.props.dispatch(generalActions.getQuestionOthersResult(data7));
      }



    });



  }




  handleOverViewRoute = (item1, item2, item3) => {

    this.setState({
      itemSurveyReport: item1,
      itemOverview: item2,
      itemAllQuestions: item3
    });


  }




  // handleNPSFilterCall=(FilterValues,isValue)=>{
  //   let XAxis = "";
  //   let Filters = [];
  //   if(FilterValues && FilterValues.length>0){
  //     let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
  //     if(getIndex !== -1){
  //       XAxis = FilterValues[getIndex].level;
  //       FilterValues.forEach((item)=>{
  //         if(item.value !== "All"){
  //           Filters.push(item);
  //         }
  //       });
  //     }
  //     else{
  //       XAxis = FilterValues[FilterValues.length-1].level;
  //       Filters = FilterValues
  //     }

  //   }


  //   let data7 = {
  //     "surveyId": this.state.CurrentSurveyId,
  //     "qtemplateList":[6],
  //     "xAxis":isValue,
  //     "filters":Filters
  //   }

  //   this.props.dispatch(generalActions.getQuestionMatrixResult(data7));

  // }




  handleOthersFilterCall = (FilterValues, isValue, qTemplate, q2Template) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }


    ////console.log("--------------qTemplate-------.",qTemplate)

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplateList": [qTemplate],
      "xAxis": isValue,
      "filters": Filters
    }

    if (qTemplate === 13) {
      this.props.dispatch(generalActions.getQuestionMatrixResult(data7));
    }
    else if (qTemplate === 21 || qTemplate === 22) {
      let data7x = {
        "surveyId": this.state.CurrentSurveyId,
        "qtemplateList": [qTemplate],
        "filters": Filters
      }
      this.props.dispatch(generalActions.getQuestionDMGResult(data7x));
    }
    else {
      this.props.dispatch(generalActions.getQuestionOthersResultFilter(data7));
    }



    if (q2Template) {



      let data72 = {
        "surveyId": this.state.CurrentSurveyId,
        "qtemplateList": [q2Template],
        "xAxis": isValue,
        "filters": Filters
      }

      this.props.dispatch(generalActions.getQuestion2OthersResultFilter(data72));



    }

  }




  overviewAPI = () => {
    this.props.dispatch(generalActions.getQuestionOthersResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: this.state.templateList }));
  }





  onCrossTab = () => {
    this.props.dispatch(dashboardActions.emptycrossTabAnalysis());

    let data = {
      surveyId: this.state.CurrentSurveyId
    }

    this.props.dispatch(questionsActions.getQuestionListBySurveyID(data));


  }

  handleCrossTabApi = (QuesID1, QuesID2, FilterValues) => {

    let data = {
      // "xAxis": "Department",
      "surveyId": this.state.CurrentSurveyId,
      "questionId1": QuesID1.id,
      "questionId2": QuesID2.id,
      "filters": FilterValues
    }
    // this.props.dispatch(dashboardActions.crossTab(data));

    this.props.dispatch(dashboardActions.crossTabAnalysis(data));

  }


  handleQueCorrelation = (QuesID1, QuesList, FilterValues) => {
    ////console.log("asdasdasd")
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "questionId1": QuesID1.id,
      "questionList": QuesList,
      "filters": FilterValues
    }

    ////console.log("===========handleQueCorrelation============")

    this.props.dispatch(dashboardActions.getCorrelationQuestionType2(data2));

  }




  storeInnerRoute = (value) => {
    this.setState({ showAllQues: value });
  }



  onAllQuestions = (item) => {
    // this.setState({CurrentTemplateId:item.templateId})
    this.setState({
      showOverview: false,
      showAllQuestions: true,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showOrgCore: false,
      showQuesCard: false,
      showCustomScoreCard: false,
      currentQuestionTemplate: item.templateId
    })

  }


  onAdvanceWorldCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "otherType": [3, 4, 23]
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 4));

  }


  onTreeMap = () => {
    this.props.dispatch(dashboardActions.EmptyTreeMap());
  }



  onAllMatrixQuestions = (item) => {
    // this.setState({CurrentTemplateId:item.templateId})
    this.setState({
      showOverview: false,
      showAllQuestions: true,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showOrgCore: false,
      showQuesCard: false,
      showCustomScoreCard: false,
      currentQuestionTemplate: 13
    })

  }


  // oMatrixQuestionDetail=()=>{
  //   this.setState({
  //     showOverview:false,
  //     showAllQuestions:false,
  //     showIndex:false,
  //     showTextAnalysis:false,
  //     showAdvanceInsight:false,
  //     showQuesCard:true,
  //     currentQuestionId:ques && ques.id?ques.id:"",
  //     currentQuestionTemplate:13

  //   });
  //   this.props.dispatch(generalActions.getQuestionMatrixResult({surveyId:this.state.CurrentSurveyId,qtemplateList:[13]}));

  // }
  handleMatrixDetails = (ques) => {
    this.setState({
      showOverview: false,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showOrgCore: false,
      showQuesCard: true,
      showCustomScoreCard: false,
      currentQuestionId: ques && ques.id ? ques.id : "",
      currentQuestionTemplate: 13

    });
    this.props.dispatch(generalActions.getQuestionMatrixResult({ surveyId: this.state.CurrentSurveyId, qtemplateList: [13] }));

  }


  callQuestionNetworkCloud = (QuesId, graphType, FilterValues) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters && Filters.length > 0 ? Filters : []
    }

    if (graphType === 1) {
      this.props.dispatch(dashboardActions.cooccurmatrix(data2));

    }
    else if (graphType === 2) {
      this.props.dispatch(dashboardActions.neighborGraph(data2));
    }


  }



  onCustomScoreCard = (item) => {

    this.setState({
      showOverview: false,
      showAllQuestions: false,
      showIndex: false,
      showTextAnalysis: false,
      showAdvanceInsight: false,
      showOrgCore: false,
      showQuesCard: false,
      currentQuestionTemplate: "",

      showCustomScoreCard: true,
      customeScoreData: item

    })
  }



  handleMultiFilterGetCustomQuestion = (FilterValues, type, name) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "template": this.state.customeScoreData && this.state.customeScoreData.index ? this.state.customeScoreData.index : "",
      "filters": Filters
    }


    if (type === 2) {
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "template": this.state.customeScoreData && this.state.customeScoreData.index ? this.state.customeScoreData.index : "",
        "xAxis": name,
        "filters": Filters
      }
    }





    //////console.log("getQuestion-->1",data7)

    // //console.log('data7:::',data7);

    if (type === 2) {
      this.props.dispatch(dashboardActions.getQuestionCustomTemplate(data7));
    }
    else {
      this.props.dispatch(dashboardActions.getQuestionDistribution(data7));

      // this.props.dispatch(dashboardActions.getQuestion(data7));
    }
  }

  onCustomQuestion = () => {
    let data = {
      "template": this.state && this.state.customeScoreData && this.state.customeScoreData.index ? this.state.customeScoreData.index : "",
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(dashboardActions.getQuestionCustomTemplate(data))
  }


  handleMultifilterQuesAvgSummary = (FilterValues, isFilterTwice, name) => {

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": isFilterTwice ? name : XAxis,
      "filters": Filters,
      "qtemplateList": [1]

    }

    if (data7.xAxis) {
      this.props.dispatch(generalActions.getQuestionAvgScore(data7));
    }

    //console.log('data7:::',data7);
    // //console.log('data7:::',data7);

  }




  onTextDistSentence = (sentences, dmgValue) => {
    if (sentences && sentences.length > 0) {
      let data2 = {
        "surveyId": this.state.CurrentSurveyId,
        "sentences": sentences.map((ele) => (ele[0]))
      }
      this.props.dispatch(dashboardActions.GetTextDistHighlights(data2));
    } else {
      this.props.dispatch(dashboardActions.GetTextDistHighlights({}, true));
    }
  }



  onNeightboardGraph = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "otherType": [3, 4, 23]
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 3));

  }



  onTextWordDistribution = (EMPFieldFirstName) => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "otherType": [3, 4, 23]
    }
    console.log("EMPFieldFirstName---------->", EMPFieldFirstName)
    this.props.dispatch(dashboardActions.getTextQuestion(data2, 5, [], EMPFieldFirstName));

  }



  onTopicModel = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "otherType": [3, 4, 23]
    }

    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }

    this.props.dispatch(dashboardActions.getTopicList(data1));

    this.props.dispatch(dashboardActions.getTextQuestion(data2, 7));


  }

  onCreateTopicSubmit = (data) => {
    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }

    this.props.dispatch(dashboardActions.createTopic(data, temp))
  }
  onFeedbackCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId
    }

    this.props.dispatch(dashboardActions.getFeedbackTextQuestion(data2));


  }
  callAnswerFeedbackText = (QuesId, keyWord, FilterList, FilterValue, isMultiKeyword, type) => {
    let { CurrentSurveyId } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterList && FilterList.length > 0) {
      let getIndex = FilterList.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterList[getIndex].level;
        FilterList.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterList[FilterList.length - 1].level;
        Filters = FilterList
      }

    }

    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "keyWord": keyWord,
      "filters": Filters && Filters.length > 0 ? Filters : []
    }
    this.props.dispatch(dashboardActions.AnswerFeedbackText(data2));


  }


  handleAnswerFeedbackDemographicFilter = (QuesId, FilterValues, blank, type, wordList) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters

    }

    this.props.dispatch(dashboardActions.getOrgCoreDriverSentiment(data2));



  }


  onTextCluster = () => {
    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "otherType": [3, 4, 23]
    }
    this.props.dispatch(dashboardActions.getTextQuestion(data, 9));

  }

  getKeywordsByCluster = (QuesId, FilterValues, clusterNo) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters,
      "cluster": clusterNo

    }


    this.props.dispatch(dashboardActions.getClusterKeywords(data2));

  }


  getClusterSentences = (QuesId, FilterValues, clusterNo, keyword) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters,
      "cluster": clusterNo,
      "keyWord": keyword

    }

    this.props.dispatch(dashboardActions.getClusterSentences(data2));

  }

  getTextClusterFilter = (QuesId, FilterValues) => {
    let { CurrentSurveyId } = this.state;

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }
    }

    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters
    }

    this.props.dispatch(dashboardActions.getTextCluster(data2));

  }

  onTopicCloud = () => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "otherType": [3, 4, 23]
    }

    this.props.dispatch(dashboardActions.getTextQuestion(data2, 10));

  }

  handleTopicCloudDemographicFilter = (QuesId, FilterValues, blank, type, wordList) => {
    let { CurrentSurveyId } = this.state;


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": Filters

    }

    console.log("------------------------------type====", type)

    this.props.dispatch(dashboardActions.topicCloud(data2));


  }



  callTopicCloudText = (QuesId) => {
    let { CurrentSurveyId } = this.state;

    let data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "filters": []
    }
    this.props.dispatch(dashboardActions.topicCloud(data2));

  }



  addKeywordsAPI = (avoidList) => {
    let data = {
      surveyId: this.state.CurrentSurveyId,
      keywordList: avoidList,
    };
    let tempo = {
      surveyId: this.state.CurrentSurveyId
    };
    this.props.dispatch(dashboardActions.AddTextKeywords(data, tempo));
  }





  onOverviewFromHeader = () => {
    this.handleOverview()
  }





  onOrgQuestionDist = (empty, type) => {
    let { CurrentSurveyId, indexType } = this.state;
    let data2 = {
      surveyId: CurrentSurveyId,
      template: indexType,
    };
    this.props.dispatch(dashboardActions.getQuestionDistribution(data2));
  };
  handleQuesDistributionIndexOrg = () => {
    let data241 = {
      "surveyId": this.state.CurrentSurveyId,
      "templateList": [1, 2, 4, 5, 6, 8]
    }
    this.props.dispatch(dashboardActions.getQuesDistributionIndexOrg(data241));
  }
  onRwaNpsInsight = (Filter, isLadder) => {
    let { CurrentSurveyId } = this.state;

    let { getIndexData } = this.props.dashboard;
    // ////console.log('getIndexData Input----->: ',getIndexData)

    let data2 = {
      "surveyId": CurrentSurveyId,
      "filters": Filter,
      "index": 8,
      "addValueTemplate": []
    }

    if (getIndexData && getIndexData["5"]) {
      data2["addValueTemplate"].push(5)
    }

    if (getIndexData && getIndexData["6"]) {
      data2["addValueTemplate"].push(6)
    }


    let data11 = {
      "surveyId": CurrentSurveyId,
      "otherType": isLadder ? 16 : 6
    }
    // this.props.dispatch(dashboardActions.driverVsNpsRWA(data2));
    this.props.dispatch(dashboardActions.getNameQuestion(data11, 2, data2));

  }
  handleRwaNpsInsight = (initQueId, Filter) => {
    let { CurrentSurveyId } = this.state;

    let { getIndexData } = this.props.dashboard;
    // ////console.log('getIndexData Input----->: ',getIndexData)

    let data2 = {
      "surveyId": CurrentSurveyId,
      "filters": Filter,
      "index": 8,
      "addValueTemplate": []
    }

    if (getIndexData && getIndexData["5"]) {
      data2["addValueTemplate"].push(5)
    }

    if (getIndexData && getIndexData["6"]) {
      data2["addValueTemplate"].push(6)
    }

    data2["questionId"] = initQueId;
    this.props.dispatch(dashboardActions.driverVsNpsRWA(data2));

  }
  handleMultiFilterGetMapQuestion = (FilterValues, type, name, tabSummary) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "template": this.state.indexType,
      "filters": Filters
    }


    if (type === 2) {
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "template": this.state.indexType,
        "xAxis": name,
        "filters": Filters
      }
    }



    this.props.dispatch(dashboardActions.getQuestionMap(data7));


  }
  handleMultiFilterGetDimenPrioQuestion = (FilterValues, type, name, tabSummary) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "template": this.state.indexType,
      "filters": Filters
    }


    if (type === 2) {
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "template": this.state.indexType,
        "xAxis": name,
        "filters": Filters
      }
    }

    this.props.dispatch(dashboardActions.getQuestion(data7));


  }
  handleMultiFilterGetResQuestion = (FilterValues, type, name, tabSummary) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "template": this.state.indexType,
      "filters": Filters
    }


    if (type === 2) {
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "template": this.state.indexType,
        "xAxis": name,
        "filters": Filters
      }
    }





    ////////console.log("getQuestion-->1",data7)

    ////console.log('tabSummary---->',tabSummary);


    this.props.dispatch(dashboardActions.getQuestionDistribution(data7));


  }
  getEDAOrgdata = (name, scoreType, FilterList, xAxis, xAxisName) => {
    ////console.log('name:::',name);
    ////console.log('FilterList:::',FilterList);
    ////console.log('xAxis:::',xAxis);


    // let XAxis = "";
    let Filters = [];
    if (FilterList && FilterList.length > 0) {
      let getIndex = FilterList.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        // XAxis = FilterList[getIndex].level;
        FilterList.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        // XAxis = FilterList[FilterList.length-1].level;
        Filters = FilterList
      }

    }

    let NewFilter = [];
    let type = 1
    if (scoreType !== "overall") {
      NewFilter = [{
        "level": xAxis,
        "value": xAxisName
      }];
      if (Filters && Filters.length > 0) {
        Filters.forEach((item) => {
          let check = 0
          if (item.level === xAxis) {
            check++
          }
          if (check === 0) {
            NewFilter.push(item)
          }

        });
      }
      type = 2
    }




    let data71 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": NewFilter && NewFilter.length > 0 ? NewFilter : [],
      "xAxisList": [],
    }

    let data7 = []

    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((filter1) => {
        if (filter1 && filter1.isEDA && filter1.name) {

          data71["xAxisList"].push(filter1.name)

          // data7.push({
          //   "surveyId": this.state.CurrentSurveyId,
          //   "xAxis": filter1.name,
          //   "filters": NewFilter && NewFilter.length>0?NewFilter:[]
          // });




        }

      });
    }


    ////////console.log("data7---->",data7)


    // getDemographicIndexOrg
    // this.props.dispatch(dashboardActions.getDemographicIndexOrgManyFilter(data7,name, type));

    if (data71 && data71["xAxisList"] && data71["xAxisList"].length > 0) {
      this.props.dispatch(dashboardActions.getDemographicIndexMultiAxisOrg(data71));
    }


  }
  handleMultifilterOrgDriverSummary = (FilterValues, isFilterTwice, name) => {

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": isFilterTwice ? name : XAxis,
      "filters": Filters
    }

    if (data7.xAxis) {
      this.props.dispatch(dashboardActions.getDemographicIndexOrg(data7));
    }

    //////console.log('data7:::',data7);
    // //////console.log('data7:::',data7);

  }
  handleFilterRWA = (FilterValues, IndexType) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }


    let { getIndexData } = this.props.dashboard;



    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "index": IndexType,
      "filters": Filters,
      "addValueTemplate": []
    }

    if (getIndexData && getIndexData["5"]) {
      data7["addValueTemplate"].push(5)
    }

    if (getIndexData && getIndexData["6"]) {
      data7["addValueTemplate"].push(6)
    }
    // //////console.log('data7:::',data7);
    this.props.dispatch(dashboardActions.getEngagementRWA(data7));
  }
  handleMultiFilterQuantile = (FilterValues, clear) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data7 = {}
    if (clear) {
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "index": this.state.indexType,
        "filters": []
      }
    }
    else {
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "index": this.state.indexType,
        "filters": Filters
      }
    }

    // //////console.log('data7:::',data7);
    this.props.dispatch(dashboardActions.getIndexQuantile(data7));
  }
  handleMultiFilterGetQuestion = (FilterValues, type, name, tabSummary) => {
    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }



    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "template": this.state.indexType,
      "filters": Filters
    }


    if (type === 2) {
      data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "template": this.state.indexType,
        "xAxis": name,
        "filters": Filters
      }
    }





    ////////console.log("getQuestion-->1",data7)

    ////console.log('tabSummary---->',tabSummary);


    if (tabSummary) {
      if (type === 2) {
        this.props.dispatch(dashboardActions.getQuestionMap(data7));
      }
      else {
        this.props.dispatch(dashboardActions.getQuestionDistribution(data7));

      }
    } else {
      this.props.dispatch(dashboardActions.getQuestion(data7));
      this.props.dispatch(dashboardActions.getQuestionDistribution(data7));

    }

  }
  handleMultifilterIndexScore = (FilterValues, isAllTwice, name) => {

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }

    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": (isAllTwice) ? name : XAxis,
      "filters": Filters
    }

    ////console.log('data7:::',data7);

    this.props.dispatch(dashboardActions.getDemographicIndexMultiFilter(data7));

    if (this.state.indexType === 1) {
      this.props.dispatch(dashboardActions.getHappinessIndexV2Demographic(data7));
    }
    else if (this.state.indexType === 2) {
      this.props.dispatch(dashboardActions.getEngagementIndexV2Demographic(data7));
    }
    else if (this.state.indexType === 4) {
      this.props.dispatch(dashboardActions.getStressIndexV2Demographic(data7));
    }

  }
  onOptionType = (NoData, indexType, optionType) => {

    console.log("this.state.indexType", this.state.indexType)
    console.log("indexType------------------->", indexType)


    // this.handleSidebar(false);
    this.setState({ showMobileSidebar: false })

    if (indexType === "nps6") {

      this.setState({
        showOverview: false,
        showIndex: false,
        showManagerList: false,
        showNudgeReport: false,
        showOrgCore: false,
        showAdvanceComparision: false,
        showTextAnalysis: false, showExcelScreen: false,
        showStrengthAndWeaknesses: false,
        showManagerActionPlan: false,
        showManagerGoalPlan: false,
        showReport: false,
        showAllQuestions: false,
        showCustomScoreCard: false,
        showPeopleToMeet: false,

        currentQuestionTemplate: optionType,
        showOtherTypes: true
      })


    }
    else {



      let { dashboard } = this.props;
      let { EmpFilterData } = dashboard;

      let filterNames = ''
      if (EmpFilterData && EmpFilterData.length > 0) {
        filterNames = EmpFilterData && EmpFilterData[0] && EmpFilterData[0].name ? EmpFilterData[0].name : "";
      }




      // this.setState({CurrentSurveyId:surveyId})

      let surveyId = this.state.CurrentSurveyId;

      let {
        getHappinessIndexDriverData,
        getEngagementIndexDriverData,
        getStressIndexDriverData,
        getManagerIndexDriverData,
        getLeaderIndexDriverData,
        getOrgIndexDriverData
      } = dashboard;

      this.setState({
        showOverview: false,
        showIndex: true,
        showManagerList: false, showNudgeReport: false,
        showOrgCore: false,
        showAdvanceComparision: false,
        showTextAnalysis: false, showExcelScreen: false,
        showStrengthAndWeaknesses: false,
        showManagerActionPlan: false,
        showManagerGoalPlan: false,
        showReport: false,
        showOtherTypes: false,
        showCustomScoreCard: false,
        showPeopleToMeet: false

      })

      this.setState({ indexType: indexType, optionType: optionType })

      function callIndexapi(indexType, props, data) {
        if (indexType === 1) {
          props.dispatch(dashboardActions.getHappinessIndexV2(data));
        }
        else if (indexType === 2) {
          props.dispatch(dashboardActions.getEngagementIndexV2(data));



          let data1 = {
            dmgLabel: "IndexScore",
            index: 2
          }
          props.dispatch(dashboardActions.getBenchmarkQuantile(data1));


        }
        else if (indexType === 4) {
          props.dispatch(dashboardActions.getStressIndexV2(data));
        }
        else if (indexType === 5) {
          // props.dispatch(dashboardActions.getManagerIndexV2(data));
        }
        else if (indexType === 6) {
          // props.dispatch(dashboardActions.getLeaderIndexV2(data));
        }
        else if (indexType === 8) {
          props.dispatch(dashboardActions.getOrgIndexV2(data));
        }
      }

      function callDemographicapi(indexType, props, data,
        getHappinessIndexDriverData,
        getEngagementIndexDriverData,
        getStressIndexDriverData,
        getManagerIndexDriverData,
        getLeaderIndexDriverData,
        getOrgIndexDriverData,

      ) {
        if (indexType === 1) {
          props.dispatch(dashboardActions.getDemographicIndexHappiness(data));
          props.dispatch(dashboardActions.getHappinessIndexDriver({ surveyId: data.surveyId }));

        }
        else if (indexType === 2) {
          props.dispatch(dashboardActions.getDemographicIndexEngagement(data));
          props.dispatch(dashboardActions.getEngagementIndexDriver({ surveyId: data.surveyId }));

        }
        else if (indexType === 4) {
          props.dispatch(dashboardActions.getDemographicIndexStress(data));
          props.dispatch(dashboardActions.getStressIndexDriver({ surveyId: data.surveyId }));

        }
        else if (indexType === 5) {
          props.dispatch(dashboardActions.getDemographicIndexManager(data));
          props.dispatch(dashboardActions.getManagerIndexDriver({ surveyId: data.surveyId }));

        }
        else if (indexType === 6) {
          props.dispatch(dashboardActions.getDemographicIndexLeader(data));
          props.dispatch(dashboardActions.getLeaderIndexDriver({ surveyId: data.surveyId }));

        }
        else if (indexType === 8) {
          props.dispatch(dashboardActions.getDemographicIndexOrg(data));
          props.dispatch(dashboardActions.getOrgIndexDriver({ surveyId: data.surveyId }));

        }
      }


      if (indexType) {
        if (indexType === 102) { //MANAGER INSIGHT
          this.onManagerList(surveyId);

        }
        else if (indexType === 103) { // ADVANCE INSIGHT
          this.onAdvanceComparision(surveyId, filterNames);
        }
        else if (indexType === 104) { // TEXT ANALYSIS
          this.onTextAnalysis(surveyId);
        }
        else if (indexType === 105) { // STRENGHT & WEAKNESS
          this.onStrengthAndWeaknessess(surveyId);
        }
        else if (indexType === 106) { // MANAGER ACTION PLAN
          // this.managerActionPlan(surveyId);
          this.onManagerActionPlanSelect(surveyId);

        }
        else if (indexType === 107) { // REPORT
          // this.managerActionPlan(surveyId);
          this.onReport(surveyId);

        }
        else if (indexType === 109) { // REPORT
          // this.managerActionPlan(surveyId);
          this.onNudge(surveyId);

        }
        else if (indexType === 108) { // REPORT
          // this.managerActionPlan(surveyId);
          this.onManagerGoalPlan(surveyId);

        }
        else if (indexType === 121) { // REPORT
          // this.managerActionPlan(categoryId);
          this.onPeopleToMeetTab();

        } else if (indexType === 122) { // REPORT
          this.onExcelDownload();
        }
        else {

          if (optionType === 1) {
            let data1 = {
              "surveyId": surveyId
            }
            let data3 = {
              "surveyId": surveyId,
              "index": indexType

            }
            // this.props.dispatch(dashboardActions.cronbatchAlphaVariance(data3));
            // this.props.dispatch(dashboardActions.getRespondantDetails(data1));
            // this.props.dispatch(dashboardActions.getIndex(data1));

            if (indexType === 2) {
              let data6 = {
                "keyWord": "",
                "pageNo": 1,
                "size": 10
              }
              this.props.dispatch(dashboardActions.getHistoryIndex(data6));

            }

            callIndexapi(indexType, this.props, data1);







          }
          else if (optionType === 2) {
            let data3 = {
              "surveyId": surveyId,
              "xAxis": filterNames
            }
            callDemographicapi(indexType, this.props, data3,
              getHappinessIndexDriverData,
              getEngagementIndexDriverData,
              getStressIndexDriverData,
              getManagerIndexDriverData,
              getLeaderIndexDriverData,
              getOrgIndexDriverData,)
          }
          else if (optionType === 3) {
            let data2 = {
              "surveyId": surveyId,
              "template": indexType
            }
            this.props.dispatch(dashboardActions.getQuestion(data2));
          }
          else if (optionType === 4) {
            let data2 = {
              "surveyId": surveyId,
              "template": indexType
            }
            // this.props.dispatch(dashboardActions.getQuestion(data2));
            this.props.dispatch(dashboardActions.getQuestionDistribution(data2));

          }
          else if (optionType === 5) {
            let data2 = {
              "surveyId": surveyId,
              "index": indexType
            }
            this.props.dispatch(dashboardActions.getIndexQuantile(data2));
          }


          else if (optionType === 6) {
            let data2 = {
              "surveyId": surveyId,
              "template": indexType
            }
            this.props.dispatch(dashboardActions.getQuestionMap(data2));
            this.props.dispatch(dashboardActions.getQuestion(data2));

          }

          else if (optionType === 7) {
            let data11 = {
              "surveyId": surveyId,
              "otherType": 6
            }
            let data2222 = {
              "surveyId": surveyId,
              "npsRange": ["9", "10"],
              "template": indexType,
              "filters": []
            }
            this.props.dispatch(dashboardActions.getNameQuestion(data11, 1, data2222));
            // this.props.dispatch(dashboardActions.getIndexVsNps(data2222));

          }
          else if (optionType === 8) {
            let data11 = {
              "surveyId": surveyId,
              "otherType": 16
            }
            let data2222 = {
              "surveyId": surveyId,
              "npsRange": ["8", "9", "10"],
              "template": indexType,
              "filters": []
            }
            this.props.dispatch(dashboardActions.getNameQuestion(data11, 1, data2222));
            // this.props.dispatch(dashboardActions.getIndexVsNps(data2222));

          }


        }


      }
      else {

        this.setState({
          showOverview: true,
          showIndex: false,
          showManagerList: false, showNudgeReport: false,
          showOrgCore: false,
          showAdvanceComparision: false,
          showTextAnalysis: false, showExcelScreen: false,
          showStrengthAndWeaknesses: false,
          showManagerActionPlan: false,
          showManagerGoalPlan: false,
          showReport: false,
          showOtherTypes: false,
          showCustomScoreCard: false,
          showPeopleToMeet: false
        })


        this.LoadOverviewInitFn()



      }




    }





  }






  handleQuestionFilter = (name, value) => {
    let { indexType, CurrentSurveyId } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "template": indexType,
      "filters": [
        { "level": name, "value": value }
      ]
    }
    // //////console.log('data: ',data)
    this.props.dispatch(dashboardActions.getQuestionFilter(data));
  }

  handleSummaryFilter = (name, value) => {
    let { CurrentSurveyId } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "filters": [
        { "level": name, "value": value }
      ]
    }
    this.props.dispatch(dashboardActions.getIndexByFilter(data));
  }
  handleSummaryCard = (name) => {
    let { CurrentSurveyId } = this.state;

    let { indexType } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "xAxis": name
    }
    this.props.dispatch(dashboardActions.getDemographicIndex(data));

    if (indexType === 1) {
      this.props.dispatch(dashboardActions.getHappinessIndexV2Demographic(data));
    }
    else if (indexType === 2) {
      this.props.dispatch(dashboardActions.getEngagementIndexV2Demographic(data));
    }
    else if (indexType === 4) {
      this.props.dispatch(dashboardActions.getStressIndexV2Demographic(data));
    }
    else if (indexType === 5) {
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data));
    }
    else if (indexType === 6) {
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data));
    }
  }
  handleDistributionFilter = (name) => {
    let { indexType } = this.state;
    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": name
    }
    if (indexType === 1) {
      this.props.dispatch(dashboardActions.getDemographicIndexHappiness(data));
    }
    else if (indexType === 2) {
      this.props.dispatch(dashboardActions.getDemographicIndexEngagement(data));
    }
    else if (indexType === 4) {
      this.props.dispatch(dashboardActions.getDemographicIndexStress(data));
    }
    else if (indexType === 5) {
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data));
    }
    else if (indexType === 6) {
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data));
    }
    else if (indexType === 8) {
      this.props.dispatch(dashboardActions.getDemographicIndexOrg(data));
    }
  }
  handleQuantileFilter = (Name, Value) => {
    let { CurrentSurveyId, indexType } = this.state;
    let data2 = {
      "surveyId": CurrentSurveyId,
      "index": indexType,
      "filters": [
        { "level": Name, "value": Value }
      ]
    }
    this.props.dispatch(dashboardActions.getIndexQuantile(data2));
  }
  onRWAnalysis = (Filter, InnerIndexType, isOnlyRWA) => {
    let { CurrentSurveyId } = this.state;

    let { getIndexData } = this.props.dashboard;
    // ////console.log('getIndexData Input----->: ',getIndexData)

    let data2 = {
      "surveyId": CurrentSurveyId,
      "index": InnerIndexType,
      "filters": Filter,
      "addValueTemplate": []
    }

    if (getIndexData && getIndexData["5"]) {
      data2["addValueTemplate"].push(5)
    }

    if (getIndexData && getIndexData["6"]) {
      data2["addValueTemplate"].push(6)
    }


    this.setState({ rwaIndexType: InnerIndexType })

    let data3 = {
      "surveyId": CurrentSurveyId
    }

    if (isOnlyRWA) {
      this.props.dispatch(dashboardActions.getIndexFilter(data3))
      this.props.dispatch(surveyActions.getSurveyById(data3))
      this.props.dispatch(dashboardActions.getEngagementRWA(data2));
    } else {
      this.props.dispatch(dashboardActions.getEngagementRWA(data2));
    }


    // data2["questionId"] = "61b18a5c2392b3d406b32967"
    // this.props.dispatch(dashboardActions.driverVsNpsRWA(data2));

  }
  handleRWAFilter = (Filter, innerIndex, name, value) => {
    let { CurrentSurveyId } = this.state;

    let data2 = {}
    if (name === 'none') {
      data2 = {
        "surveyId": CurrentSurveyId,
        "index": innerIndex,
        "filters": []
      }
    }
    else {
      data2 = {
        "surveyId": CurrentSurveyId,
        "index": innerIndex,
        "filters": [
          { "level": name, "value": value }
        ]
      }
    }


    this.setState({ rwaIndexType: innerIndex })

    // ////console.log('onRWAnalysis Input----->: ',data2)


    this.props.dispatch(dashboardActions.getEngagementRWA(data2));

  }
  onOrgQuestion = (empty, type) => {
    let { CurrentSurveyId, indexType } = this.state;
    let data2 = {
      surveyId: CurrentSurveyId,
      template: indexType,
    };
    this.props.dispatch(dashboardActions.getQuestion(data2));

  };







  render() {

    let { general, dashboard, survey, questions } = this.props;

    let { getSurveyData, parameterList, getIndexByFilterDMGData,

    } = survey;
    let { questionsList } = questions;

    let { getQuestionAvgScoreData, getQuestionDMGResultData } = general;

    let getIndexFilterData = getIndexByFilterDMGData;


    let { getQuestionCustomTemplateData, neighborGraphData, cooccurmatrixData, wordCloudData, wordTextKeyWord, wordMultiTextKeyWord,
      wordCloudTextData, wordCloudMultiTextData, getTextQuestionData, ClientProfileData, EmpFilterData,
      getRespondantDetailsData,
      AdvanceWordCloudData,
      crossTabAnalysisData,
      crossTabData,
      TreeMapData,

      getCorrelationQuestionType2Data,

      getAllFeedbackWordsData,
      getQuesDistributionIndexOrgData,
      getNameQuestionData,
      driverVsNpsRWAData,
      getDemographicIndexMultiAxisOrgData,
      getEngagementRWAError,
      getQuestionDistributionData,
      getDemographicIndexOrgManyFilterData,
      getQuestionMapData,
      getOverallIndexData,
      getAllBenchMarkData,
      getOrgIndexV2Data,
      getMetaRespondantDetailsData,
      getDemographicIndexData,
      getHappinessIndexV2DemographicData,
      getQuestionFilterData,
      getDemographicIndexOrgData,
      getIndexQuantileData,
      getEngagementRWAData,
      rwaIndexType,
      getIndexDriverError,
      getDemographicIndexError,

      getQuestionError,
      getQuestionDistributionError,
      getQuestionMapError,
      getIndexQuantileError,
      getIndexV2Error,
      getDemographicIndexMultiFilterError,
      getOrgIndexV2Error,
      getDemographicIndexOrgError,

      getQuestionData,
      getOrgIndexDriverData,



    } = dashboard;
    let { showOrgCore,
      isCrole,
      newFilterObj,
      optionType, currentTextQuesId } = this.state;


    let ErrorIndexObj = {
      "getIndexDriverError": getIndexDriverError,
      "getDemographicIndexError": getDemographicIndexError,
      "getQuestionError": getQuestionError,
      "getQuestionDistributionError": getQuestionDistributionError,
      "getQuestionMapError": getQuestionMapError,
      "getIndexQuantileError": getIndexQuantileError,
      "getIndexV2Error": getIndexV2Error,
      "getDemographicIndexMultiFilterError": getDemographicIndexMultiFilterError,

      "getOrgIndexV2Error": getOrgIndexV2Error,
      "getDemographicIndexOrgError": getDemographicIndexOrgError,
      "getEngagementRWAError": getEngagementRWAError
    }




    let { getQuestion2OthersResultFilterData, getQuestionOthersResultData, getQuestionOthersResultFilterData, getQuestionMatrixResultData, SurveyList, getMeanScoreQwiseData, getDashboardCardData, getQuestionDistData, getQuestionDistOverallData, getMeanScoreData } = general;

    let { CurrentSurveyId, ItemSaveData, currentQuestionId, currentQuestionTemplate,

      showOverview, showAllQuestions, showCustomScoreCard, customeScoreData,
      showTextAnalysis, showAdvanceInsight, showQuesCard, showIndex, IndexNo, cardType

    } = this.state;

    let loading = dashboard.loading || general.loading;

    let OverviewList = [];
    let SumMean = 0;
    let OverallMean = 0;
    let total = 0;

    if (getDashboardCardData && getDashboardCardData.length > 0) {
      getDashboardCardData.forEach((item, index) => {
        if (item && item._id) {
          SumMean += parseFloat(item && item.mean ? item.mean : 0.0);
          total += 1;
          OverviewList.push({
            "id": item._id,
            "name": item.parameter && item.parameter.length > 0 && item.parameter[0] && item.parameter[0].name ? item.parameter[0].name : "NaN",
            "mean": (typeof item !== 'string' || item instanceof Number) && item && item.mean ? item.mean.toFixed(2) : 'NaN',
            "color": '#757575'
          });
        }
      });
      OverallMean = (SumMean / total);

    }

    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageactionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }

    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    //console.log('moduleAccess: ',moduleAccess)

    let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    //END -------------------------------------------------------

    function FormattFun(inputData, isSummary) {
      let RankObj = getSurveyData && getSurveyData.designObj && getSurveyData.designObj.componentRank ? getSurveyData.designObj.componentRank : {}

      let LIST = []
      let prevTemList = []
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {

          let templateId = ques.total[0]._id.qtemplate;

          let temp = {
            name: ques && ques.question[0] && ques.question[0].name ? ques.question[0].name : "",
            answerList: [],
            rank: RankObj && RankObj[templateId] ? RankObj[templateId] : 100,
            templateId: templateId
          }
          if (ques && ques.total && ques.total.length > 0) {
            ques.total.forEach((item) => {
              let ans = {
                count: item.count,
                answerText: item._id.answerText,
                heading: item && item._id && item._id.heading ? item._id.heading : "",
                parentId: item && item._id && item._id.parentId ? item._id.parentId : "",
                template: item._id.qtemplate,
                QuesId: item._id.questionId
              }
              temp["answerList"].push(ans);
            });
          }


          if (isSummary) {
            let getIndex = prevTemList.findIndex(prev => prev === templateId);
            if (getIndex === -1) {
              LIST.push(temp);
              prevTemList.push(templateId)
            }
          }
          else {
            LIST.push(temp);
          }


        });
      }
      return LIST
    }

    //  let NPSQuesList = FormattFun(getQuestionNPSResultData);
    let AllQuesList = FormattFun(getQuestionOthersResultData, false).filter(x => x.templateId === currentQuestionTemplate);




    function CountRestrict(count, isOverall, section) {
      let isCountLessThanFive = false;

      // //////console.log("count---",count)

      if (count !== "NaN") {
        if (isOverall) {
          isCountLessThanFive = false;
        }
        else {
          if (section === "rwa") {
            if (parseInt(count) < 30) {
              isCountLessThanFive = true;
            }
          }
          else {
            if (parseInt(count) < 5) {
              isCountLessThanFive = true;
            }
          }

        }
      }

      return isCountLessThanFive
    }



    function GetParameterLabel(name) {
      let AllParameterObj = {};
      if (parameterList && parameterList.length > 0) {
        parameterList.forEach((item) => {
          AllParameterObj[item.name] = item && item.label ? item.label : item.name;
        });
      }
      return (AllParameterObj && AllParameterObj[name] ? AllParameterObj[name] : name);
    }

    function getSurveyDetails(surveData, field) {
      return surveData && surveData[field] ? surveData[field] : "NaN";
    }

    function ObjectFieldAveragingFn(getTempIndexV2DemographicData, type) {
      let count = 0
      let keyList = Object.keys(getTempIndexV2DemographicData && getTempIndexV2DemographicData[0] ? getTempIndexV2DemographicData[0] : {});
      let obj = {}
      if (keyList && keyList.length > 0) {
        keyList.forEach((key) => {
          obj[key] = 0;
        });
      }
      if (getTempIndexV2DemographicData && getTempIndexV2DemographicData.length > 0) {
        getTempIndexV2DemographicData.forEach((item) => {
          if (keyList && keyList.length > 0) {
            keyList.forEach((key) => {
              obj[key] += item[key];
              count += item.count;
            });
          }
        });
        if (keyList && keyList.length > 0) {
          keyList.forEach((key) => {
            obj[key] /= getTempIndexV2DemographicData.length;
          });
        }
      }
      if (type === "count") {
        obj["count"] = count;
      }
      return obj
    }

    function getEMPFilterLabel(name) {

      let label = name;
      if (EmpFilterData && EmpFilterData.length > 0) {
        let getIndex = EmpFilterData.findIndex(prev => prev.name === name);
        if (getIndex !== 1) {
          label = EmpFilterData && EmpFilterData[getIndex] && EmpFilterData[getIndex].label ? EmpFilterData[getIndex].label : name
        }

      }
      return label
    }

    let templateList = this.state.templateList;

    let {
      crossTabError,
      getTextKeywordsData,
      getTextDistHighlightData,
      textDistError,
      TreeMapError,
      topicModellingError,
      neighborGraphError,
      wordCloudError,
      getCommentData,
      textDistTreeMapData,
      topicCloudData,
      getClusterSaveKeyWord,
      getClusterSentencesData,
      getClusterKeywordsData,
      getTextClusterSentimentCountData,
      getTextClusterData,
      getFeedbackTextQuestionData,
      AnswerFeedbackTextKeyWord,
      AnswerFeedbackTextData,
      getOrgCoreDriverSentimentData,
      getTopicListData,
      topicModellingData,
      ErrorOtherObj,
      getLeaderIndexDriverData,
      getManagerIndexDriverData,
      getStressIndexDriverData,
      getEngagementIndexDriverData,
      getHappinessIndexDriverData,
      getIndexData,
      getDemographicIndexHappinessData,
      getDemographicIndexEngagementData,
      getDemographicIndexStressData,
      getDemographicIndexManagerData,
      getDemographicIndexLeaderData,
      getDemographicIndexMultiFilterData,
      textDistData,
      indexType,



    } = dashboard;



    let surveyLogo = "";
    let surveyId = getSurveyData && getSurveyData.id ? (getSurveyData.id).toString() : "";

    if (surveyId === "660e64c4d31b9f08913b45a9") {
      surveyLogo = 'img/logo/tataprojectlogo.png';
    } else {
      surveyLogo = '/img/logo/happypluslogo.png';
    }
    console.log("getCommentData---", getCommentData)


    let avoidList = [3, 4]
    let questionsListV2 = [];
    if (questionsList && questionsList.length > 0) {
      questionsList.forEach((ques) => {
        if (!avoidList.includes(ques.templateId)) {
          questionsListV2.push(ques)
        }
      })
    }

    console.log("showOrgCore-----xxxxxxxxxxxxxxxxx--.", showOrgCore)

    let avoidSurveyList = ["660e64c4d31b9f08913b45a9"]


    let isDMGText = false;
    if (getTextQuestionData?.length && getAllFeedbackWordsData?.length) {
      const currentTextQues = currentTextQuesId
        ? getTextQuestionData.find(({ id }) => id === currentTextQuesId)
        : getTextQuestionData[0];

      if (currentTextQues?.templateId === 23) {
        isDMGText = true;
      }
    }



    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text='Loading Data...'
          className="loader"
        >

          <div className='h-screen overflow-hidden bg-gray-50'>

            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              SurveyList={SurveyList}
              ChooseSurvey={this.ChooseSurvey}
              CurrentSurveyId={CurrentSurveyId}
              onOverviewFromHeader={this.onOverviewFromHeader}
              props={this.props}
              handleOverview={this.handleOverview}
            />



            <div className="px-4  md:flex  justify-between items-center bg-white
              border-b py-2.5">
              <div className="flex justify-between w-full space-x-2 text-gray-700">

                <div className='flex space-x-2 lg:ml-16'>
                  <span className="material-symbols-outlined -mt-0.5">description</span>
                  <h2 className="text-sm font-medium truncate"> {getSurveyDetails(getSurveyData, "name")} </h2>
                </div>

                {/* <div onClick={()=>this.handleMobileSidebar(!this.state.showMobileSidebar)}>
                    <span className="cursor-pointer material-symbols-outlined lg:hidden">menu_open</span>
                  </div> */}

              </div>


            </div>




            <div className="fixed flex w-full h-screen bg-gray-100" >
              {accessAllow("dashboard") ?
                <>

                  <Sidebar
                    SurveyList={SurveyList}
                    OverviewList={OverviewList}
                    handleSidebarClick={this.handleSidebarClick}
                    IndexNo={IndexNo}
                    showIndex={showIndex}
                    showOverview={showOverview}
                    showTextAnalysis={showTextAnalysis}
                    showAdvanceInsight={showAdvanceInsight}
                    handleSidebarExtra={this.handleSidebarExtra}
                    showOrgCore={showOrgCore}
                  />


                  {showOverview ?
                    <Overview
                      surveyName={getSurveyDetails(getSurveyData, "name")}
                      surveyLogo={surveyLogo}


                      CurrentSurveyId={CurrentSurveyId}
                      SurveyList={SurveyList}
                      EmpFilterData={EmpFilterData}
                      OverviewList={OverviewList}
                      OverallMean={OverallMean}
                      onCardClick={this.onCardClick}
                      getMeanScoreQwiseData={getMeanScoreQwiseData}
                      getIndexFilterData={getIndexFilterData}
                      getRespondantDetailsData={getRespondantDetailsData}
                      getQuestionDistOverallData={getQuestionDistOverallData}

                      getQuestionMatrixResultData={getQuestionMatrixResultData}
                      questionsList={questionsList}

                      getQuestionOthersResultData={getQuestionOthersResultData}
                      getQuestionDMGResultData={getQuestionDMGResultData}


                      onOthersCardClick={this.onOthersCardClick}

                      getSurveyData={getSurveyData}
                      overviewAPI={this.overviewAPI}
                      handleMatrixDetails={this.handleMatrixDetails}
                      handleOverViewRoute={this.handleOverViewRoute}

                      storeInnerRoute={this.storeInnerRoute}

                      onAllQuestions={this.onAllQuestions}
                      onAllMatrixQuestions={this.onAllMatrixQuestions}

                      getQuestionAvgScoreData={getQuestionAvgScoreData}

                      onCustomScoreCard={this.onCustomScoreCard}

                      templateList={templateList}
                    />
                    : null}


                  {/* {showAllQuestions ?
                    <>
                      <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto md:p-6 p-4" style={{ height: 'calc(100% - 4rem)' }}>

                        <div className="">

                          <div className="h-full p-4 mx-auto overflow-hidden overflow-y-auto " >



                            {currentQuestionTemplate !== 13 ?
                              <>
                                <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 lg:gap-6">

                                  {AllQuesList && AllQuesList.length > 0 ? AllQuesList.map((item, index) =>
                                    <div onClick={() => this.onOthersCardClick(item)} className="p-4 bg-white border">
                                      <QuestionCard item={item} name={""} stage={2} index={index} />
                                    </div>
                                  ) : null}

                                </div>
                              </>
                              :
                              <>
                                {getQuestionMatrixResultData && getQuestionMatrixResultData.length > 0 ?

                                  <div className="p-4 border " >
                                    <QuestionCard
                                      questionType={"matrix"}
                                      getQuestionMatrixResultData={getQuestionMatrixResultData}
                                      questionsList={questionsList}
                                      stage={2}
                                      handleMatrixDetails={this.handleMatrixDetails}
                                    />
                                  </div>


                                  : null}
                              </>}









                          </div>
                        </div>

                      </main>
                    </>
                    : null} */}



                  {/* {showIndex ?
                    <Index
                      ItemSaveData={ItemSaveData}
                      cardType={cardType}

                      handleQuestion={this.handleQuestion}
                      getQuestionDistData={getQuestionDistData}
                      getQuestionDistOverallData={getQuestionDistOverallData}

                      getMeanScoreQwiseData={getMeanScoreQwiseData}
                      handleResponseDistributionFilter={this.handleResponseDistributionFilter}
                      handleQuestionMeanScore={this.handleQuestionMeanScore}

                      EmpFilterData={EmpFilterData}
                      getIndexFilterData={getIndexFilterData}
                      handleDemographicFilter={this.handleDemographicFilter}
                      getMeanScoreData={getMeanScoreData}
                      handleDemographicOverallQFilter={this.handleDemographicOverallQFilter}
                      OverviewList={OverviewList}

                    />
                    : null} */}


                  {showQuesCard ?

                    <QuestionDetails
                      ItemSaveData={ItemSaveData}
                      cardType={cardType}
                      handleQuestion={this.handleQuestion}
                      getQuestionDistData={getQuestionDistData}
                      getQuestionDistOverallData={getQuestionDistOverallData}
                      getMeanScoreQwiseData={getMeanScoreQwiseData}
                      handleResponseDistributionFilter={this.handleResponseDistributionFilter}
                      handleQuestionMeanScore={this.handleQuestionMeanScore}
                      EmpFilterData={EmpFilterData}
                      getIndexFilterData={getIndexFilterData}
                      handleDemographicFilter={this.handleDemographicFilter}
                      getMeanScoreData={getMeanScoreData}
                      handleDemographicOverallQFilter={this.handleDemographicOverallQFilter}
                      OverviewList={OverviewList}
                      handleOthersFilterCall={this.handleOthersFilterCall}
                      getQuestionMatrixResultData={getQuestionMatrixResultData}
                      getQuestionOthersResultFilterData={getQuestionOthersResultFilterData}
                      questionsList={questionsList}
                      getQuestion2OthersResultFilterData={getQuestion2OthersResultFilterData}

                      currentQuestionId={currentQuestionId}
                      currentQuestionTemplate={currentQuestionTemplate}


                      getQuestionOthersResultData={getQuestionOthersResultData}
                      getQuestionDMGResultData={getQuestionDMGResultData}


                    />

                    : null}


                  {showTextAnalysis ?
                    <TextAnalysis
                      isDMGText={isDMGText}
                      getAllFeedbackWordsData={getAllFeedbackWordsData}
                      handleDMGCloudDemographicFilter={this.handleDMGCloudDemographicFilter}

                      getTextKeywordsData={getTextKeywordsData}
                      getTextDistHighlightData={getTextDistHighlightData}
                      onTextDistSentence={this.onTextDistSentence}
                      textDistError={textDistError}
                      TreeMapError={TreeMapError}
                      topicModellingError={topicModellingError}
                      neighborGraphError={neighborGraphError}
                      wordCloudError={wordCloudError}
                      indexType={104}
                      getTextQuestionData={getTextQuestionData}

                      handleChangeQuestion={this.handleChangeQuestion}

                      wordCloudData={wordCloudData}

                      EmpFilterData={EmpFilterData}
                      getIndexFilterData={getIndexFilterData}

                      handleWordCloudDemographicFilter={this.handleWordCloudDemographicFilter}

                      callWordCloudText={this.callWordCloudText}

                      wordCloudTextData={wordCloudTextData}

                      wordTextKeyWord={wordTextKeyWord}

                      callQuestionWordCloud={this.callQuestionWordCloud}

                      cooccurmatrixData={cooccurmatrixData}

                      onWorldCloud={this.onWorldCloud}

                      onNetworkGraph={this.onNetworkGraph}
                      onNeightboardGraph={this.onNeightboardGraph}
                      onTextWordDistribution={this.onTextWordDistribution}

                      callQuestionNetworkCloud={this.callQuestionNetworkCloud}
                      handleNetworkDemographicFilter={this.handleNetworkDemographicFilter}


                      neighborGraphData={neighborGraphData}

                      handleNeighborGraph={this.handleNeighborGraph}

                      getCommentData={getCommentData}

                      loading={loading}

                      GetParameterLabel={GetParameterLabel}

                      CountRestrict={CountRestrict}

                      wordCloudMultiTextData={wordCloudMultiTextData}
                      wordMultiTextKeyWord={wordMultiTextKeyWord}

                      textDistData={textDistData}


                      onAdvanceWorldCloud={this.onAdvanceWorldCloud}
                      AdvanceWordCloudData={AdvanceWordCloudData}

                      onTreeMap={this.onTreeMap}
                      TreeMapData={TreeMapData}
                      textDistTreeMapData={textDistTreeMapData}

                      getEMPFilterLabel={getEMPFilterLabel}

                      getIndexByFilterDMGData={getIndexByFilterDMGData}




                      getDemographicIndexHappinessDataTreeMap={ObjectFieldAveragingFn(getDemographicIndexHappinessData)}
                      getDemographicIndexEngagementDataTreeMap={ObjectFieldAveragingFn(getDemographicIndexEngagementData)}
                      getDemographicIndexStressDataTreeMap={ObjectFieldAveragingFn(getDemographicIndexStressData)}
                      getDemographicIndexManagerDataTreeMap={ObjectFieldAveragingFn(getDemographicIndexManagerData)}
                      getDemographicIndexLeaderDataTreeMap={ObjectFieldAveragingFn(getDemographicIndexLeaderData)}


                      getDemographicIndexMultiFilterDataTreeMap={ObjectFieldAveragingFn(getDemographicIndexMultiFilterData)}


                      getIndexData={getIndexData}


                      getHappinessIndexDriverData={getHappinessIndexDriverData}
                      getEngagementIndexDriverData={getEngagementIndexDriverData}
                      getStressIndexDriverData={getStressIndexDriverData}
                      getManagerIndexDriverData={getManagerIndexDriverData}
                      getLeaderIndexDriverData={getLeaderIndexDriverData}


                      onTopicModel={this.onTopicModel}
                      topicModellingData={topicModellingData}

                      getTopicListData={getTopicListData}

                      onCreateTopicSubmit={this.onCreateTopicSubmit}

                      ErrorOtherObj={ErrorOtherObj}



                      //AnswerFeedback
                      getOrgCoreDriverSentimentData={getOrgCoreDriverSentimentData}
                      AnswerFeedbackTextData={AnswerFeedbackTextData}
                      AnswerFeedbackTextKeyWord={AnswerFeedbackTextKeyWord}
                      getFeedbackTextQuestionData={getFeedbackTextQuestionData}
                      onFeedbackCloud={this.onFeedbackCloud}
                      callAnswerFeedbackText={this.callAnswerFeedbackText}
                      handleAnswerFeedbackDemographicFilter={this.handleAnswerFeedbackDemographicFilter}

                      //onTextCluster
                      getTextClusterData={getTextClusterData}
                      getTextClusterSentimentCountData={getTextClusterSentimentCountData}
                      getClusterKeywordsData={getClusterKeywordsData}
                      getClusterSentencesData={getClusterSentencesData}
                      getClusterSaveKeyWord={getClusterSaveKeyWord}
                      onTextCluster={this.onTextCluster}
                      getKeywordsByCluster={this.getKeywordsByCluster}
                      getClusterSentences={this.getClusterSentences}
                      getTextClusterFilter={this.getTextClusterFilter}

                      onTopicCloud={this.onTopicCloud}
                      topicCloudData={topicCloudData}


                      handleTopicCloudDemographicFilter={this.handleTopicCloudDemographicFilter}
                      callTopicCloudText={this.callTopicCloudText}
                      addKeywordsAPI={this.addKeywordsAPI}
                    />
                    : null}

                  {showAdvanceInsight ?
                    <AdvanceComparision

                      indexType={indexType}
                      EmpFilterData={EmpFilterData}
                      getIndexFilterData={getIndexFilterData}
                      getCommentData={getCommentData}
                      loading={loading}
                      questionsList={questionsListV2}
                      crossTabAnalysisData={crossTabAnalysisData}
                      handleCrossTabApi={this.handleCrossTabApi}
                      crossTabData={crossTabData}
                      getIndexByFilterDMGData={getIndexByFilterDMGData}
                      getEMPFilterLabel={getEMPFilterLabel}
                      crossTabError={crossTabError}
                      ErrorOtherObj={ErrorOtherObj}
                      onCrossTab={this.onCrossTab}
                    />
                    : null}


                  {showOrgCore ?
                    <>

                      <OrgCore
                        onOrgQuestionDist={this.onOrgQuestionDist}
                        handleQuesDistributionIndexOrg={this.handleQuesDistributionIndexOrg}
                        getQuesDistributionIndexOrgData={getQuesDistributionIndexOrgData}
                        onRwaNpsInsight={this.onRwaNpsInsight} getNameQuestionData={getNameQuestionData}
                        driverVsNpsRWAData={driverVsNpsRWAData} handleRwaNpsInsight={this.handleRwaNpsInsight}
                        getDemographicIndexMultiAxisOrgData={getDemographicIndexMultiAxisOrgData}
                        handleMultiFilterGetMapQuestion={this.handleMultiFilterGetMapQuestion}
                        handleMultiFilterGetDimenPrioQuestion={this.handleMultiFilterGetDimenPrioQuestion}
                        ErrorIndexObj={ErrorIndexObj} handleMultiFilterGetResQuestion={this.handleMultiFilterGetResQuestion}
                        getEngagementRWAError={getEngagementRWAError} getQuestionDistributionData={getQuestionDistributionData}
                        getdmgOrgManyFilterData={getDemographicIndexOrgManyFilterData} getEDAOrgdata={this.getEDAOrgdata}
                        isCrole={isCrole} getQuestionMapData={getQuestionMapData} getOverallIndexData={getOverallIndexData}
                        getIndexByFilterDMGData={getIndexByFilterDMGData} getEMPFilterLabel={getEMPFilterLabel}
                        CountRestrict={CountRestrict} handleMultifilterOrgDriverSummary={this.handleMultifilterOrgDriverSummary}
                        GetParameterLabel={GetParameterLabel} validateIndex={{}} getSurveyData={getSurveyData}
                        handleFilterRWA={this.handleFilterRWA} handleMultiFilterQuantile={this.handleMultiFilterQuantile}
                        handleMultiFilterGetQuestion={this.handleMultiFilterGetQuestion}
                        handleMultifilterIndexScore={this.handleMultifilterIndexScore}
                        getDemographicIndexMultiFilterData={getDemographicIndexMultiFilterData}
                        loading={loading} getCommentData={getCommentData} CurrentSurveyId={CurrentSurveyId}
                        onOptionType={this.onOptionType} getAllBenchMarkData={getAllBenchMarkData}
                        getIndexScore={newFilterObj} getIndexData={getOrgIndexV2Data}
                        getRespondantDetailsData={getMetaRespondantDetailsData} optionType={optionType}
                        indexType={indexType} handleQuestionFilter={this.handleQuestionFilter}
                        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
                        handleSummaryFilter={this.handleSummaryFilter} handleSummaryCard={this.handleSummaryCard}
                        getDemographicIndexData={getDemographicIndexData} getIndexDistributionData={getHappinessIndexV2DemographicData}
                        getQuestionData={getQuestionData} getQuestionFilterData={getQuestionFilterData}
                        getIndexDriverData={getOrgIndexDriverData} getDistributionIndexData={getDemographicIndexOrgData}
                        handleDistributionFilter={this.handleDistributionFilter} getIndexQuantileData={getIndexQuantileData}
                        handleQuantileFilter={this.handleQuantileFilter} onRWAnalysis={this.onRWAnalysis}
                        getEngagementRWAData={getEngagementRWAData} rwaIndexType={rwaIndexType}
                        handleRWAFilter={this.handleRWAFilter} onOrgQuestion={this.onOrgQuestion} isHide={avoidSurveyList.includes(this.state.CurrentSurveyId) ? true : false}
                      />

                    </>
                    : null}


                </>
                : null}
            </div>

            <div id="sidebar-btn" className="fixed z-30 flex items-center justify-center w-10 h-10 text-gray-900 bg-white rounded-full shadow-md hover:bg-blue-500 hover:text-white bottom-8 right-8 lg:hidden">
              <span className="material-symbols-outlined">view_quilt</span>
            </div>
          </div>





        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, general, dashboard, survey, questions } = state;
  return {
    loggingIn,
    users,
    general,
    dashboard,
    survey,
    questions
  };
}
export default connect(mapStateToProps)(GeneralDashboard);
