import { growConstants } from '../_constants';
import { growService } from '../_services';


export const growActions = {
    getGrowMonth,
    startGrowMonth,
    getGrowWeekListbyMonthId,
    getGrowDayListbyWeekId,
    getGrowDayDatabyDayId,
    startGrowDay,
    startGrowWeek,
    startGrowDayAction,
    startGrowDayReflection,
    submitGrowWeek,

    getAllGrowNote,
    submitGrowDay,
    startGrowDayKnowledge,
    getGrowParameterList,
    
    updateEmployeeSubactivityId,
    getSubactivityActivityId,
    updatecomment

};

function startGrowDayAction(data1) {
    return dispatch => {
        dispatch(request());
        growService.startGrowDayAction(data1)
            .then(
                grow => dispatch(success(grow)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.START_GROW_GETALL_REQUEST } }
    function success(grow) { return { type: growConstants.START_GROW_GETALL_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.START_GROW_GETALL_FAILURE, error } }
}

function startGrowDay(data,alsoCall,data2) {
    
   
    return dispatch => {
 
        dispatch(request());
        growService.startGrowDay(data)
            .then(

                grow => {

                    dispatch(success(grow));
                    if(alsoCall==="getGrowDayDatabyDayId"){
                        dispatch(this.getGrowDayDatabyDayId(data2));
                    }
                   
                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_GROWDAY_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROWDAY_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROWDAY_FAILURE, error } }
}

function startGrowWeek(data) {
    
   
    return dispatch => {
 
        dispatch(request());
        growService.startGrowWeek(data)
            .then(

                grow => {

                    dispatch(success(grow));
                   
                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_GROWWEEK_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROWWEEK_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROWWEEK_FAILURE, error } }
}

function submitGrowWeek(data) {
    
   
    return dispatch => {
 
        dispatch(request());
        growService.submitGrowWeek(data)
            .then(

                grow => {

                    dispatch(success(grow));
                   
                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_GROWDAYWEEK_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROWDAYWEEK_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROWDAYWEEK_FAILURE, error } }
}

function getGrowDayDatabyDayId(data) {
   
    return dispatch => {
 
        dispatch(request());
        growService.getGrowDayDatabyDayId(data)
            .then(

                grow => {

                    dispatch(success(grow));

                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_GROWDAY_DAY_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROWDAY_DAY_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROWDAY_DAY_FAILURE, error } }
}




function startGrowDayReflection(data) {
   
    return dispatch => {
 
        dispatch(request());
        growService.startGrowDayReflection(data)
            .then(

                grow => {

                    dispatch(success(grow));

                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_REFLECTION_DAY_REQUEST } }
    function success(grow) { return { type: growConstants.GET_REFLECTION_DAY_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_REFLECTION_DAY_FAILURE, error } }
}


function getGrowDayListbyWeekId(data) {

    // console.log("ACTION_______data%%%%%%%%%%",data);
   
    return dispatch => {
 
        dispatch(request());
        growService.getGrowDayListbyWeekId(data)
            .then(

                grow => {

                    dispatch(success(grow));

                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_GROW_DAY_REQUEST } }
    function success(grow) { return { type: growConstants.GET_GROW_DAY_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_GROW_DAY_FAILURE, error } }
}

function getGrowWeekListbyMonthId(data) {
   
    return dispatch => {
 
        dispatch(request());
        growService.getGrowWeekListbyMonthId(data)
            .then(

                grow => {

                    dispatch(success(grow));

                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_WEEK_MONTH_REQUEST } }
    function success(grow) { return { type: growConstants.GET_WEEK_MONTH_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_WEEK_MONTH_FAILURE, error } }
}


function startGrowMonth(data,alsoCall) {
   
    return dispatch => {
 
        dispatch(request());
        growService.startGrowMonth(data)
            .then(

                grow => {

                    dispatch(success(grow));
                    if(alsoCall==="getGrowWeekListbyMonthId"){
                        dispatch(this.getGrowWeekListbyMonthId(grow));
                    }
              
                },

                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.START_MONTH_REQUEST } }
    function success(grow) { return { type: growConstants.START_MONTH_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.START_MONTH_FAILURE, error } }
}

function getGrowMonth(data) {
   
    return dispatch => {
 
        dispatch(request());
        growService.getGrowMonth(data)
            .then(
                grow => {
                    dispatch(success(grow));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_MONTH_REQUEST } }
    function success(grow) { return { type: growConstants.GET_MONTH_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_MONTH_FAILURE, error } }
}




function getAllGrowNote(data) {

    return dispatch => {
        dispatch(request());
        growService.getAllGrowNote(data)
            .then(
                grow => dispatch(success(grow)),
                
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.DASHBOARD_GROWNOTE_REQUEST } }
    function success(grow) { return { type: growConstants.DASHBOARD_GROWNOTE_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.DASHBOARD_GROWNOTE_FAILURE, error } }
}

function submitGrowDay(data,data1,props) {
    // console.log("dataaaa",data1)
    return dispatch => {
        dispatch(request());
        growService.submitGrowDay(data)
       
            .then(
            
            
                grow =>{
                 dispatch(success(grow))
                // console.log("dashboarddashboard",  dispatch(success(grow)))
                let newdata = grow &&grow.submitGrowDay&&grow.submitGrowDay.weekId
                // props.history.push('/app/Notetoself/' + newdata)

                let dat32 = { weekId: newdata }
                dispatch(this.getAllGrowNote(dat32))

                },
                // console.log("dashboarddashboard",grow),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.DASHBOARD_SUBMITGROW_REQUEST } }
    function success(grow) { return { type: growConstants.DASHBOARD_SUBMITGROW_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.DASHBOARD_SUBMITGROW_FAILURE, error } }
}

function startGrowDayKnowledge(data,datatemp) {
    return dispatch => {
        dispatch(request());
        growService.startGrowDayKnowledge(data)
            .then(
                grow => {
                    dispatch(success(grow));
                 


                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request() { return { type: growConstants.GET_KNOWLEDGE_REQUEST } }
    function success(grow) { return { type: growConstants.GET_KNOWLEDGE_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GET_KNOWLEDGE_FAILURE, error } }
}


function getGrowParameterList(data) {
    return dispatch => {
        dispatch(request());
        growService.getGrowParameterList(data)
            .then(
                grow => {
                    dispatch(success(grow));
                },
                error => {
                    
                    // dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: growConstants.GROWPARAMETERLIST_BYTYPE_GETALL_REQUEST } }
    function success(grow) { return { type: growConstants.GROWPARAMETERLIST_GETALL_SUCCESS, grow } }
    function failure(error) { return { type: growConstants.GROWPARAMETERLIST_GETALL_FAILURE, error } }
}


function updateEmployeeSubactivityId(data, datatemp) {
    // console.log("log",datatemp);
        return dispatch => {
            dispatch(request());
            growService.updateEmployeeSubactivityId(data)
    
                .then(
                    grow => {
                        dispatch(success(grow));
                    //  console.log("bbbbbbbbbb",datatemp)
                        // dispatch(growActions.getSubactivityActivityId({"activityId": [datatemp]}));
                        dispatch(this.getSubactivityActivityId({"activityId": [datatemp]}))
    
                    },
                    error => {
                        dispatch(failure(error))
                    }
                );
        };
        function request() { return { type: growConstants.UPDATE_EMPLOYEE_TRACKER_REQUEST } }
        function success(grow) { return { type: growConstants.UPDATE_EMPLOYEE_TRACKER_SUCCESS, grow } }
        function failure(error) { return { type: growConstants.UPDATE_EMPLOYEE_TRACKER_FAILURE, error } }
    }
    
    function getSubactivityActivityId(data) {
        return dispatch => {
            dispatch(request());
            growService.getSubactivityActivityId(data)
                .then(
                    grow => dispatch(success(grow)),
                    // dispatch(getMyRewardsProductsWithLevel()),
                    error => {
                        dispatch(failure(error))
                    }
                );
        };
        function request() { return { type: growConstants.SUB_ACT_REQUEST } }
        function success(grow) { return { type: growConstants.SUB_ACT_SUCCESS, grow } }
        function failure(error) { return { type: growConstants.SUB_ACT_FAILURE, error } }
    }


    

    function updatecomment(data) {
        return dispatch => {
            dispatch(request());
            growService.updatecomment(data)
                .then(
                    grow => dispatch(success(grow)),
                    // dispatch(getMyRewardsProductsWithLevel()),
                    error => {
                        dispatch(failure(error))
                    }
                );
        };
        function request() { return { type: growConstants.UPDATE_COMMENT_REQUEST } }
        function success(grow) { return { type: growConstants.UPDATE_COMMENT_SUCCESS, grow } }
        function failure(error) { return { type: growConstants.UPDATE_COMMENT_FAILURE, error } }
    }
