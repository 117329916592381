import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class DriverRadar extends Component {
    constructor(props) {
        super(props);
        this.state = {
    
      
        }
      }
    
      componentDidMount() {
    
      }
    
    
      render() {
        let { handleSelectSurvey, get360DimensionRelationScoreData,getRespondantAllLifecycleDetailsData, GetParameterLabel } = this.props;
        
        function getListSorted(list){
          if(list && list.length>0){
            let List1 = list.filter(prev=>prev.relation ==="Self")
            let List2 = list.filter(prev=>prev.relation !=="Self")
            let RelationListMainA = List1.concat(List2)
            return RelationListMainA
          }else{
            return []
          }
        }

        let get360DimensionRelationScoreList = []
        if(get360DimensionRelationScoreData && get360DimensionRelationScoreData.length>0){
          get360DimensionRelationScoreList = get360DimensionRelationScoreData.map((element)=>(element))
          get360DimensionRelationScoreList.sort((a,b)=>((a && a.item && a.item._id?a.item._id:"").charCodeAt(0)-(b && b.item && b.item._id?b.item._id:"").charCodeAt(0)))
        }

          let ValueList = [];
          let DimensionList = [];
          let RelationList = [];
          let RelationListMain = [];

          if(get360DimensionRelationScoreList && get360DimensionRelationScoreList.length>0){
            get360DimensionRelationScoreList.forEach((item)=>{
              let dimension1 = item && item._id?item._id:"";
  
              let dimension = dimension1;

  
              let temp = {
                "dimen_xyz":dimension
              }

              let miniList = []
              if(item && item.total && item.total.length>0){
                item.total.forEach((inner)=>{
                  if(inner && inner._id && inner._id.relation){
                    miniList.push({
                      "relation":inner._id.relation,
                      "mean":inner && inner.mean?inner.mean:0
                    })
                  }
                })
              }
              let miniListSorted = getListSorted(miniList)





              if(miniListSorted && miniListSorted.length>0){
                miniListSorted.forEach((inner)=>{
                  if(inner && inner.relation){

                    let scoreMean1 = inner && inner.mean?inner.mean:0;

                    let scoreMean = scoreMean1.toFixed(2);

                    temp[inner.relation] = scoreMean;
  
                    let getIndex2 = RelationList.findIndex(prev=>prev.name === inner.relation);
                    if(getIndex2 ===-1){
                      let tempx = { 
                        name: inner.relation, 
                        value: [scoreMean]
                      }
                      RelationList.push(tempx);
                      RelationListMain.push(tempx)

                    }else{
                      RelationList[getIndex2]["value"].push(scoreMean)
                    }
        
  
  
                  }
                })
              }


              DimensionList.push({ name: dimension, max: 6 })

  
  
              ValueList.push(temp)
            })
          }
  
          console.log('-------->>>>DimensionList; ',DimensionList)
          console.log('-------->>>>RelationList; ',RelationList)
          console.log('-------->>>>ValueList; ',ValueList)

          let listSurvey = []
          let sourceListV2 = [
            ['Survey', 'Sent', 'Responded']
          ]
          if(getRespondantAllLifecycleDetailsData && getRespondantAllLifecycleDetailsData.length>0){
            getRespondantAllLifecycleDetailsData.forEach((item)=>{
              
              let surveyId = item.surveyId;
              let SurveyName = item.surveyName;
              let Sent = item && item.Data && item.Data.total?item.Data.total:0
              let Respond = item && item.Data && item.Data.completed?item.Data.completed:0
              let ResponseRate = ((Respond*100)/Sent)
              console.log('///////////////////////////////////////-------->>>>ResponseRate; ',ResponseRate);

              if(Sent){
                let eachDimen = [
                  (SurveyName+(" ("+ResponseRate.toFixed(2)+"%)")),Sent,Respond
                ]
                sourceListV2.push(eachDimen)
              }
              
              listSurvey.push({
                "surveyLabel":(SurveyName+(" ("+ResponseRate.toFixed(2)+"%)")),
                "surveyName": SurveyName,
                "surveyId":surveyId
              })
            })
          }
          console.log('///////////////////////////////////////-------->>>>sourceListV2; ',sourceListV2)

          console.log('//////////-------->>>>getRespondantAllLifecycleDetailsData; ',getRespondantAllLifecycleDetailsData)


          
    
        return (
          <>     
    <div className="bg-white border  p-4 rounded-md   cursor-pointer">
        <div className='space-y-6' >
          <h1 className="font-medium text-lg ">Employee Life Cycle Response Rate</h1>

          {getRespondantAllLifecycleDetailsData && getRespondantAllLifecycleDetailsData.length>0?
          <>
          <div className="  w-full  mb-4 flex  justify-center">
          <ReactEcharts className='w-full'
            style={{ height: '500px' }}
            onEvents= {{
              'click': (params)=>handleSelectSurvey(params,listSurvey)
            }}
            option={
              {
                legend: {},
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {     
                      type: 'shadow'        
                  },
                  formatter: function(data){
                    console.log("a,data,d--->",data)
                    let sent = data[0]["value"][1];
                    let responded = data[0]["value"][2];
                    let htmlElement = ''
                    htmlElement+='<p>Sent:</p> <p><b>No. of Employees:</b> '+(sent)+'</p><br/>'
                    htmlElement+='<p>Responded:</p> <p><b>No. of Employees:</b> '+(responded)+'</p>'
                    return htmlElement
                  } 
      
      
                },

                dataset: {
                  source: sourceListV2
                },
                xAxis: { type: 'category'},
                yAxis: {},
                series: [{ type: 'bar',label:{show:true,position: 'top'}, barWidth:40 }, { type: 'bar',label:{show:true,position: 'top'}, barWidth:40 }]
              }}
                            
          />


          </div>

            <div className='flex justify-center items-center text-center p-2'>
            <p><b className='font-bold'>X-Axis: </b>Employee Life Cycle Surveys,</p>
            <p className='px-4'><b className='font-bold'>Y-Axis: </b>No. of Employees</p>
            </div>

            </>
          :
            <>
            <div className="text-center text-3xl text-gray-400 pt-40">Data Not Available!</div>
            </>}


        </div>
      </div>


          </>
        );
      }
    }
    function mapStateToProps(state) {
      // ////console.log("state  ", state);
      const { loggingIn } = state.authentication;
      const { users, dashboard } = state;
      return {
        loggingIn,
        users,
        dashboard
      };
    }
export default connect(mapStateToProps)(DriverRadar);
