import React, { Component } from 'react';
import { connect } from 'react-redux';
import DriverTable from './Tables/DriverTable';
import QuestionTable from './Tables/QuestionTable';

import DriverRadar from './Charts/DriverRadar';
import QuestionStackView from './Charts/QuestionStackView/QuestionStackView';
import Commentary from '../../../Index/Components/Commentary/Commentary';
import QuestionComparisionTable from './Tables/QuestionComparisionTable';
import DriverComparionTable from './Tables/DriverComparionTable';
import DriverComparisionRadar from './Charts/DriverComparisionRadar';
import { TbTable, TbTableColumn } from 'react-icons/tb'
import { PiTableDuotone } from 'react-icons/pi';

class IndexTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Tabtype: 1,

      showTable: true,
      showQStack: true

    }
  }

  componentDidMount() {

  }

  handleQuesViewType = (check) => {
    this.setState({ showQStack: !check })
  }

  handleViewType = (check) => {
    this.setState({ showTable: !check })
  }

  handleBehaviourType = (type) => {
    let { handleManagerDriver, handleManagerQuestion, optionTypeM } = this.props;
    this.setState({ Tabtype: type })

    handleManagerQuestion(optionTypeM);
    handleManagerDriver(optionTypeM);

    // if(type===1){
    //   handleManagerQuestion(optionTypeM);
    // }
    // else if(type===2){
    //   handleManagerDriver(optionTypeM);
    // }

  }


  handleOption = (data) => {
    let { handleInnerOption } = this.props;
    handleInnerOption(data)

  }

  render() {
    let { getIndexDriverManagerPIDWiseData, crrManagersList, getQuestionManagerPIDWiseData, IndexDriverData, GetParameterLabel, DemographicRestriction, getQuestionOverallData, ParaLabelList, showMultiple, optionTypeM, DemographicDriverManagerData, temShowMultiple, ManagerListQuestionDataObj, indexType, optionType, getCommentData, loading, getQuestionData, Name, CurrentManagerName, ManagerName, DefaultManagerName, ManagerIndexDriverData, ManagerDynaObj, EmpFilterData, CurrentManagerData, QuesDynaObj, ManagerIndexQuestionData } = this.props;
    let { Tabtype, showTable, showQStack } = this.state;



    let { optionTypeInner } = this.props;


    return (
      <>
        {true ?
          <>
            {/* <nav className="flex px-2 pb-2 overflow-x-auto scroll justify-left bg-gray overscroll-x-contain">
          <div className="flex justify-left">
            <a onClick={()=>this.handleOption(1)} className={(optionTypeInner===1)?"cursor-pointer text-gray-600 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-bold text-xs py-4 mr-8":"cursor-pointer text-gray-600 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-bold text-xs py-4 mr-8"} href>
              {Name} Questions
            </a>
            <a onClick={()=>this.handleOption(2)} className={(optionTypeInner===2)?"cursor-pointer text-gray-600 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-bold text-xs py-4 mr-8":"cursor-pointer text-gray-600 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-bold text-xs py-4 mr-8"} href>
              {Name} Drivers
            </a>
          </div>
          <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        </nav> */}

            <div className="flex flex-wrap items-center justify-between gap-4 mb-4">
              <div className="flex items-center justify-center hover:bg-[#F9FAFB] transition-all duration-150 cursor-pointer space-x-2 text-sm font-medium bg-white rounded-md text-[#3D405B]/70">
                <button onClick={() => this.handleOption(1)} className={(optionTypeInner === 1) ? " bg-white p-2 px-4 rounded-md border text-[#2196f3] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2" : " bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"}>
                  <TbTable className='text-[1.5rem]' />  <span>Items</span></button>
                <button onClick={() => this.handleOption(2)} className={(optionTypeInner === 2) ? "bg-white p-2 px-4 rounded-md border text-[#2196f3] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2" : " bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2"}><PiTableDuotone className='text-[1.5rem]' /> <span>Dimensions</span></button>
              </div>
              <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />
            </div>


            {/* 
            <div className='flex items-center space-x-2 overflow-x-auto'>
              {validateList && validateList.length > 0 ?
                validateList.map((item) =>
                  <div className="flex items-center justify-center hover:bg-[#F9FAFB] transition-all duration-150 cursor-pointer space-x-1 text-sm font-medium bg-white rounded-md text-[#3D405B]/70">
                    <span onClick={() => this.onDimensionType(item.template)}
                      className={OptType === item.template ? "text-[#2196f3] flex items-center justify-center h-10 px-3 text-sm font-medium bg-white border border-[#2196f3] rounded-md" : " bg-white px-3 border h-10 flex  items-center justify-center rounded-md text-sm font-medium text-[#3D405B]/70 space-x-1"}>
                      {item.name}
                    </span>
                  </div>
                )
                : null}
            </div> */}







            {optionTypeInner === 1 ?



              <>
                {true ?
                  <div className="p-6 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                  

                      <h2 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">{Name} Behaviour Items</h2>

                 
                    {/* {showQStack?
                                  <QuestionStackView
                                    getQuestionData={getQuestionData}
                                    QuesDynaObj={QuesDynaObj}
                                    EmpFilterData={EmpFilterData}
                                    ManagerIndexQuestionData={ManagerIndexQuestionData}
                                    Name={Name}
                                    ManagerDynaObj={ManagerDynaObj}
                                    CurrentManagerData={CurrentManagerData}
                                  />
                                : */}
                    {showMultiple || true ?
                      <QuestionComparisionTable
                        getQuestionData={getQuestionData}
                        QuesDynaObj={QuesDynaObj}
                        EmpFilterData={EmpFilterData}
                        ManagerIndexQuestionData={ManagerIndexQuestionData}
                        ManagerDynaObj={ManagerDynaObj}
                        CurrentManagerData={CurrentManagerData}
                        crrManagersList={crrManagersList}
                        Name={Name}
                        ManagerListQuestionDataObj={ManagerListQuestionDataObj}
                        optionTypeM={optionTypeM}
                        ParaLabelList={ParaLabelList}

                        getQuestionOverallData={getQuestionOverallData}
                        DemographicRestriction={DemographicRestriction}
                        GetParameterLabel={GetParameterLabel}

                        getQuestionManagerPIDWiseData={getQuestionManagerPIDWiseData}

                      />
                      :
                      <QuestionTable
                        getQuestionData={getQuestionData}
                        QuesDynaObj={QuesDynaObj}
                        EmpFilterData={EmpFilterData}
                        ManagerIndexQuestionData={ManagerIndexQuestionData}
                        ManagerDynaObj={ManagerDynaObj}
                        CurrentManagerData={CurrentManagerData}
                        Name={Name}
                        optionTypeM={optionTypeM}
                        ParaLabelList={ParaLabelList}

                        getQuestionOverallData={getQuestionOverallData}
                        DemographicRestriction={DemographicRestriction}
                        GetParameterLabel={GetParameterLabel}

                      />

                    }



                  </div>
                  : null}
              </>

              :


              <>
                {true ?
                  <div className="p-4 my-4 bg-white rounded shadow-md">
                    {/* <h1 className="px-2 py-4 text-base font-semibold text-gray-700 "> {Name} Drivers</h1>  */}
                    {/* <div className="flex items-center justify-between pb-1 text-base font-semibold text-gray-700">
                          <div className="text-lg">
                            <i className="show Table" onClick={()=>this.handleViewType(false)} style={{opacity:(showTable)?1:0.3}} className="mx-2 cursor-pointer fa fa-table"></i> 
                            <i className="show Radar" onClick={()=>this.handleViewType(true)} style={{opacity:(!showTable)?1:0.3}} className="mx-2 cursor-pointer fa fa-snowflake-o"></i> 
                          </div>


                        </div> */}

                    <div className="justify-between py-2 md:flex">
                      <div className="mb-4 text-lg font-semibold">{Name} Behaviour Dimensions</div>
                      {/* <div className="flex items-center text-xs">
                          <div onClick={()=>this.handleViewType(false)} className={showTable?"cursor-pointer border mx-1 bg-blue-50 ring-1 ring-blue-500 text-blue-500 py-1.5 px-4 rounded-full outline-none":"cursor-pointer border mx-1 py-1.5 px-4 rounded-full"}>Table view</div>
                          <div onClick={()=>this.handleViewType(true)} className={!showTable?"cursor-pointer border mx-1 bg-blue-50 ring-1 ring-blue-500 text-blue-500 py-1.5 px-4 rounded-full outline-none":"cursor-pointer border mx-1 py-1.5 px-4 rounded-full"}>Radar view</div>
                        </div> */}
                    </div>





                    {showTable ?
                      <>
                        {(!showMultiple || !temShowMultiple) && false ?
                          < DriverTable
                            getQuestionData={getQuestionData}
                            Name={Name}
                            ManagerDynaObj={ManagerDynaObj}
                            EmpFilterData={EmpFilterData}
                            CurrentManagerData={CurrentManagerData}
                            ManagerIndexDriverData={ManagerIndexDriverData}
                            optionTypeM={optionTypeM}
                            ParaLabelList={ParaLabelList}
                            DemographicRestriction={DemographicRestriction}
                            GetParameterLabel={GetParameterLabel}
                            IndexDriverData={IndexDriverData}
                          />
                          :
                          < DriverComparionTable
                            getQuestionData={getQuestionData}
                            Name={Name}
                            ManagerDynaObj={ManagerDynaObj}
                            EmpFilterData={EmpFilterData}
                            CurrentManagerData={CurrentManagerData}
                            ManagerIndexDriverData={ManagerIndexDriverData}
                            DemographicDriverManagerData={DemographicDriverManagerData}
                            optionTypeM={optionTypeM}
                            ParaLabelList={ParaLabelList}
                            DemographicRestriction={DemographicRestriction}
                            GetParameterLabel={GetParameterLabel}
                            IndexDriverData={IndexDriverData}

                            crrManagersList={crrManagersList}
                            getIndexDriverManagerPIDWiseData={getIndexDriverManagerPIDWiseData}

                          />
                        }
                      </>
                      :
                      <>
                        {!showMultiple || !temShowMultiple ?
                          < DriverRadar
                            getQuestionData={getQuestionData}
                            Name={Name}
                            ManagerDynaObj={ManagerDynaObj}
                            EmpFilterData={EmpFilterData}
                            CurrentManagerData={CurrentManagerData}
                            ManagerIndexDriverData={ManagerIndexDriverData}
                            ParaLabelList={ParaLabelList}
                            DemographicRestriction={DemographicRestriction}
                            GetParameterLabel={GetParameterLabel}
                            IndexDriverData={IndexDriverData}
                          />
                          :
                          < DriverComparisionRadar
                            getQuestionData={getQuestionData}
                            Name={Name}
                            ManagerDynaObj={ManagerDynaObj}
                            EmpFilterData={EmpFilterData}
                            CurrentManagerData={CurrentManagerData}
                            ManagerIndexDriverData={ManagerIndexDriverData}
                            DemographicDriverManagerData={DemographicDriverManagerData}
                            ParaLabelList={ParaLabelList}
                            DemographicRestriction={DemographicRestriction}
                            GetParameterLabel={GetParameterLabel}
                            IndexDriverData={IndexDriverData}
                          />
                        }
                      </>
                    }



                  </div>
                  : null}
              </>
            }


          </>
          :
          <>
            {!loading ?
              <div className="pt-40 text-3xl text-center text-gray-400">We have not calculated this section!</div>
              : null}
          </>
        }

      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexTables);
