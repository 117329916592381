import React, { Component } from "react";
import { connect } from "react-redux";

import ScheduleModal from "./Modal/ScheduleModal";
import ScheduleComplete from "./Modal/ScheduleComplete";

import CreateActionModal from "./Modal/CreateActionModal";
import ResolveModal from "./Modal/ResolveModal";
import PluseScoreModal from "./Modal/PluseScoreModal";
import ActionComplete from "./Modal/ActionComplete";

import CreateNoteModal from "./Modal/CreateNoteModal";
import CreateTalkingModal from "./Modal/CreateTalkingModal";

import moment from "moment";
import EmployeeProfileModal from "./Modal/EmployeeProfileModal";
import EmployeeMeter from "./EmployeeProfile/EmployeeMeter";
import EmployeeTable from "./EmployeeProfile/EmployeeTable";
import PluseScoreChart from "./EmployeeProfile/PluseScoreChart";

import Demographics from "./Modal/Demographics";

class OneToOneConversation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      crrEmployeeId: "",
      showTab: "talking",
      showSection: "SenseInsight",
      active: false,

      showScheduleModal: false,
      showScheduleCompleteModal: false,

      showCreateActionModal: false,
      showActionCompleteModal: false,

      showResolveModal: false,
      showPluseModal: false,

      showCreateNoteModal: false,
      showCreateTalkingModal: false,

      showActionText: false,

      showCatgory: false,

      showEmpPopUp: false,

      showMobileEMPDropdown: false,

      empEmployeeID: "",

      showFilterDMG: false,
      showValueDMG: "",
      FilterValuesDMG: [],

      selectedCategory: {
        name: "All",
        label: "Show All",
      },

      formSchedule: {
        date: "",
        time: "",
      },
      formAction: {
        name: "",
        desc: "",
      },
      formNotes: {
        name: "",
        desc: "",
      },
      talking: "",
      formScheduleComplete: {},
      formActionComplete: {},

      crrEmployeeData: {},

      crrPulseScoreList: [],

      onShowPTMemp: false,
      selectedPTMStatus: "InProgress",
      resolvedText: ""

    };
  }

  handleClick() {
    this.setState({
      active: !this.state.active,
    });
  }

  componentDidMount() { }

  selectEmp = (item) => {
    this.setState({ crrEmployeeId: item.id, showMobileEMPDropdown: false },()=>{
      this.props.callEmpQuestionResponses(item.emp_id)
    });
  };

  handleTab = (value) => {
    this.setState({ showTab: value });
  };

  openScheduleModal = (value) => {
    this.setState({ showScheduleModal: value });
  };

  handleInputField = (e) => {
    let { name, value } = e.target;
    let { formSchedule } = this.state;
    formSchedule[name] = value;
    this.setState({ formSchedule });
  };

  handleSubmit = () => {
    let { formSchedule, crrEmployeeId } = this.state;
    let { handleOneToOneScheduleSubmit, getAllRiskEmpConversationData } =
      this.props;

    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId;
    } else {
      if (
        getAllRiskEmpConversationData &&
        getAllRiskEmpConversationData &&
        getAllRiskEmpConversationData.list.length > 0
      ) {
        initEmpId = getAllRiskEmpConversationData.list[0].id;
      }
    }
    //////console.log("'''initEmpId__,", initEmpId)
    //////console.log("'''crrEmployeeId,", crrEmployeeId)
    //////console.log("'''getAllRiskEmpConversationData,", getAllRiskEmpConversationData)

    if (formSchedule && formSchedule.date && formSchedule.time) {
      let data = {
        conversationId: initEmpId,
        name: formSchedule.date,
        desc: formSchedule.time,
      };
      //////console.log("handleSubmitssssssssss-->", data)
      handleOneToOneScheduleSubmit(data, 1);

      this.onCloseScheduleModal();
    }
  };

  onCloseScheduleModal = () => {
    this.setState({ showScheduleModal: false });
  };

  onCloseCompleteScheduleModal = () => {
    this.setState({ showScheduleCompleteModal: false });
  };

  onCloseActionModal = () => {
    this.setState({ showActionText: false });
  };

  onCloseNoteModal = () => {
    this.setState({ showNoteText: false });
  };

  handleActionSubmit = () => {
    let { formAction, crrEmployeeId } = this.state;
    let { getAllRiskEmpConversationData } = this.props;

    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++;
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++;
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++;
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++;
      }
      return count ? true : false;
    }

    



    let { selectedPTMStatus } = this.state;
    let getAllRiskEmpConversationDataList = [];
    if (
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationData.list.length > 0
    ) {
      getAllRiskEmpConversationData.list.forEach((item) => {
        if (selectedPTMStatus === "Resolved") {
          if (item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        } else if (selectedPTMStatus === "InProgress") {
          if (getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        } else if (selectedPTMStatus === "Open") {
          if (!getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        }
      });
    }
    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId;
    } else {
      if (
        getAllRiskEmpConversationDataList &&
        getAllRiskEmpConversationDataList.length > 0
      ) {
        initEmpId = getAllRiskEmpConversationDataList[0].id;
      }
    }

    if (formAction && formAction.name) {
      let data = {
        conversationId: initEmpId,
        name: formAction.name,
        desc: formAction.desc,
      };
      //////console.log("handleActionSubmitssssssssss-->", data)
      // handleOneToOneActionSubmit(data)

      this.onCloseActionModal();
    }
  };

  onTakingField = (value) => {
    this.setState({ showTalkingField: value });
  };

  onActionField = (value) => {
    this.setState({ showActionText: value });
  };

  onNoteField = (value) => {
    this.setState({ showNoteText: value });
  };

  handleInputTalking = (e) => {
    let { value } = e.target;
    this.setState({ talking: value });
  };

  // SumbitTalking=()=>{
  //   let { talking } = this.state;
  //   let { handleTalkingSubmit } = this.props;
  //   if(talking){
  //     handleTalkingSubmit(talking)
  //   }
  //   this.setState({showTalkingField:false});
  // }
  SumbitTalking = () => {
    let { talking, crrEmployeeId } = this.state;
    let { handleTalkingSubmit, getAllRiskEmpConversationData } = this.props;

    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++;
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++;
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++;
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++;
      }
      return count ? true : false;
    }
    let { selectedPTMStatus } = this.state;
    let getAllRiskEmpConversationDataList = [];
    if (
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationData.list.length > 0
    ) {
      getAllRiskEmpConversationData.list.forEach((item) => {
        if (selectedPTMStatus === "Resolved") {
          if (item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        } else if (selectedPTMStatus === "InProgress") {
          if (getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        } else if (selectedPTMStatus === "Open") {
          if (!getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        }
      });
    }
    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId;
    } else {
      if (
        getAllRiskEmpConversationDataList &&
        getAllRiskEmpConversationDataList.length > 0
      ) {
        initEmpId = getAllRiskEmpConversationDataList[0].id;
      }
    }

    //////console.log("'''initEmpId__,", initEmpId)
    //////console.log("'''crrEmployeeId,", crrEmployeeId)
    //////console.log("'''getAllRiskEmpConversationData,", getAllRiskEmpConversationData)

    if (talking) {
      let data = {
        conversationId: initEmpId,
        name: talking,
        desc: "",
      };
      //////console.log("handleSubmitssssssssss-->", data)
      handleTalkingSubmit(data, 11);

      this.onTakingField(false);
    }
  };

  openScheduleCompletedModal = (value) => {
    //////console.log("value--------->", value)
    this.setState({
      showScheduleCompleteModal: true,
      formScheduleComplete: value,
    });
  };
  closeCompleteField = (value) => {
    this.setState({ showScheduleCompleteModal: value });
  };
  handleCompleteScheduleSubmit = () => {
    let { crrEmployeeId, formScheduleComplete } = this.state;
    let { handleOneToOneScheduleSubmit, getAllRiskEmpConversationData } =
      this.props;

    //////console.log("formScheduleComplete-->", formScheduleComplete)

    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++;
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++;
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++;
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++;
      }
      return count ? true : false;
    }
    let { selectedPTMStatus } = this.state;
    let getAllRiskEmpConversationDataList = [];
    if (
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationData.list.length > 0
    ) {
      getAllRiskEmpConversationData.list.forEach((item) => {
        if (selectedPTMStatus === "Resolved") {
          if (item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        } else if (selectedPTMStatus === "InProgress") {
          if (getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        } else if (selectedPTMStatus === "Open") {
          if (!getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        }
      });
    }
    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId;
    } else {
      if (
        getAllRiskEmpConversationDataList &&
        getAllRiskEmpConversationDataList.length > 0
      ) {
        initEmpId = getAllRiskEmpConversationDataList[0].id;
      }
    }

    if (formScheduleComplete && formScheduleComplete._id) {
      let data = {
        conversationId: initEmpId,
        name: formScheduleComplete._id,
      };
      //////console.log("handleActionSubmitssssssssss-->", data)
      handleOneToOneScheduleSubmit(data, 12);

      this.closeCompleteField(false);
    }
  };

  onCreateAction = () => {
    this.setState({
      showCreateActionModal: true,
    });
  };

  closeCreateActionModal = () => {
    this.setState({
      showCreateActionModal: false,
    });
  };

  onCloseCompleteActionModal = () => {
    this.setState({ showActionCompleteModal: false });
  };

  handleInputActionField = (e) => {
    let { name, value } = e.target;
    let { formAction } = this.state;
    formAction[name] = value;
    this.setState({ formAction });
  };
  handleCreateActionSubmit = () => {
    let { formAction, crrEmployeeId } = this.state;
    let { handleOneToOneScheduleSubmit, getAllRiskEmpConversationData } =
      this.props;

    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++;
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++;
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++;
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++;
      }
      return count ? true : false;
    }
    let { selectedPTMStatus } = this.state;
    let getAllRiskEmpConversationDataList = [];
    if (
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationData.list.length > 0
    ) {
      getAllRiskEmpConversationData.list.forEach((item) => {
        if (selectedPTMStatus === "Resolved") {
          if (item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        } else if (selectedPTMStatus === "InProgress") {
          if (getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        } else if (selectedPTMStatus === "Open") {
          if (!getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        }
      });
    }
    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId;
    } else {
      if (
        getAllRiskEmpConversationDataList &&
        getAllRiskEmpConversationDataList.length > 0
      ) {
        initEmpId = getAllRiskEmpConversationDataList[0].id;
      }
    }

    if (formAction && formAction.name) {
      let data = {
        conversationId: initEmpId,
        name: formAction.name,
        desc: formAction.desc,
      };
      //////console.log("handleActionSubmitssssssssss-->", data)
      handleOneToOneScheduleSubmit(data, 2);

      this.closeCreateActionModal();
    }
  };

  openActionCompletedModal = (value) => {
    //////console.log("value--------->", value)
    this.setState({ showActionCompleteModal: true, formActionComplete: value });
  };
  closeActionCompleteField = () => {
    this.setState({ showActionCompleteModal: false });
  };
  handleCompleteActionSubmit = () => {
    let { crrEmployeeId, formActionComplete } = this.state;
    let { handleOneToOneScheduleSubmit, getAllRiskEmpConversationData } =
      this.props;

    //////console.log("formActionComplete-->", formActionComplete)

    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++;
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++;
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++;
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++;
      }
      return count ? true : false;
    }
    let { selectedPTMStatus } = this.state;
    let getAllRiskEmpConversationDataList = [];
    if (
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationData.list.length > 0
    ) {
      getAllRiskEmpConversationData.list.forEach((item) => {
        if (selectedPTMStatus === "Resolved") {
          if (item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        } else if (selectedPTMStatus === "InProgress") {
          if (getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        } else if (selectedPTMStatus === "Open") {
          if (!getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        }
      });
    }
    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId;
    } else {
      if (
        getAllRiskEmpConversationDataList &&
        getAllRiskEmpConversationDataList.length > 0
      ) {
        initEmpId = getAllRiskEmpConversationDataList[0].id;
      }
    }

    if (formActionComplete && formActionComplete._id) {
      let data = {
        conversationId: initEmpId,
        name: formActionComplete._id,
      };
      //////console.log("handleActionSubmitssssssssss-->", data)
      handleOneToOneScheduleSubmit(data, 21);

      this.closeActionCompleteField(false);
    }
  };

  onCreateNote = () => {
    this.setState({ showCreateNoteModal: true });
  };

  closeCreateNoteModal = () => {
    this.setState({ showCreateNoteModal: false });
  };

  handleInputNoteField = (e) => {
    let { name, value } = e.target;
    let { formNotes } = this.state;
    formNotes[name] = value;
    this.setState({ formNotes });
  };

  handleCreateNoteSubmit = () => {
    let { formNotes, crrEmployeeId } = this.state;
    let { handleOneToOneScheduleSubmit, getAllRiskEmpConversationData } =
      this.props;

    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++;
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++;
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++;
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++;
      }
      return count ? true : false;
    }
    let { selectedPTMStatus } = this.state;
    let getAllRiskEmpConversationDataList = [];
    if (
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationData.list.length > 0
    ) {
      getAllRiskEmpConversationData.list.forEach((item) => {
        if (selectedPTMStatus === "Resolved") {
          if (item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        } else if (selectedPTMStatus === "InProgress") {
          if (getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        } else if (selectedPTMStatus === "Open") {
          if (!getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        }
      });
    }
    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId;
    } else {
      if (
        getAllRiskEmpConversationDataList &&
        getAllRiskEmpConversationDataList.length > 0
      ) {
        initEmpId = getAllRiskEmpConversationDataList[0].id;
      }
    }

    if (formNotes && formNotes.name) {
      let data = {
        conversationId: initEmpId,
        name: formNotes.name,
        desc: formNotes.desc,
      };
      //////console.log("handleActionSubmitssssssssss-->", data)
      handleOneToOneScheduleSubmit(data, 3);

      this.closeCreateNoteModal();
    }
  };

  onShowCatgory = (check) => {
    this.setState({ showCatgory: check });
  };

  handleSelectEmpCategory = (value) => {
    this.setState({ selectedCategory: value, showCatgory: false });
  };

  handleDetailsPopUp = (value) => {
    this.setState({ empEmployeeID: value, showEmpPopUp: true }, () => {
      let { handleSelectEmpFilter } = this.props;
      handleSelectEmpFilter(value);
    });
  };

  closeEmpPopUpModal = () => {
    this.setState({ showEmpPopUp: false, empEmployeeID: "" });
  };

  handleMultifilterIndexScore2DMG = (finalFilter) => {
    this.setState({ FilterValuesDMG: finalFilter }, () => {
      let { emptyRiskQuesEmp, handleAllRiskEmpConversation } = this.props;

      handleAllRiskEmpConversation(finalFilter);
      // emptyRiskQuesEmp()
    });
  };

  closeFilterDMG = (check1, check2) => {
    this.setState({ showFilterDMG: check1, showValueDMG: check2 });
  };

  onCreateTalking = () => {
    this.setState({ showCreateTalkingModal: true });
  };

  closeCreateTalkingModal = () => {
    this.setState({ showCreateTalkingModal: false });
  };

  onResolveModal = (item) => {
    this.setState({ showResolveModal: true, crrEmployeeData: item });
    //////console.log("item----------->",item)
  };


  onSubmitResolve = () => {
    let { crrEmployeeData, resolvedText } = this.state;
    let itemFinal = crrEmployeeData;

    if (itemFinal && itemFinal.id) {
      let data = {
        "id": itemFinal.id,
        "text": resolvedText
      }

      let { submitPeoptoMeetResolved } = this.props;
      submitPeoptoMeetResolved(data)
    }

    setTimeout(() => {
      this.setState({ showResolveModal: false, crrEmployeeData: {}, resolvedText: "" })
    }, 1000)

  }


  closeResolveModal = () => {
    this.setState({ showResolveModal: false });
  };

  onPluseModal = (pulseScoreList) => {
    this.setState({ showPluseModal: true, crrPulseScoreList: pulseScoreList });
  };

  closePluseModal = () => {
    this.setState({ showPluseModal: false });
  };

  handleInputTalkingField = (e) => {
    let { value } = e.target;
    this.setState({ talking: value });
  };

  handleCreateTalkingSubmit = () => {
    let { talking, crrEmployeeId } = this.state;
    let { handleTalkingSubmit, getAllRiskEmpConversationData } = this.props;

    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId;
    } else {
      if (
        getAllRiskEmpConversationData &&
        getAllRiskEmpConversationData &&
        getAllRiskEmpConversationData.list.length > 0
      ) {
        initEmpId = getAllRiskEmpConversationData.list[0].id;
      }
    }

    if (talking) {
      let data = {
        conversationId: initEmpId,
        name: talking,
        desc: "",
      };
      //////console.log("handleTalkingSubmitssssssssss-->", data)
      handleTalkingSubmit(data, 11);

      this.closeCreateTalkingModal();
    }
  };

  handleMobileEmpDropdown = (check) => {
    this.setState({ showMobileEMPDropdown: check });
  };

  onShowPTMempDrop = (check) => {
    this.setState({ onShowPTMemp: check });
  };
  handleSelectPTMStatus = (value) => {
    this.setState({ selectedPTMStatus: value, crrEmployeeId: "" },()=>{
      let { selectedPTMStatus } = this.state;

      
    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++;
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++;
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++;
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++;
      }
      return count ? true : false;
    }


    
      let { getAllRiskEmpConversationData } = this.props;
      let getAllRiskEmpConversationDataList = [];
      if (
        getAllRiskEmpConversationData &&
        getAllRiskEmpConversationData &&
        getAllRiskEmpConversationData.list.length > 0
      ) {
        getAllRiskEmpConversationData.list.forEach((item) => {
          if (selectedPTMStatus === "Resolved") {
            if (item.isHistory) {
              getAllRiskEmpConversationDataList.push(item);
            }
          } else if (selectedPTMStatus === "InProgress") {
            if (getInProgress(item) && !item.isHistory) {
              getAllRiskEmpConversationDataList.push(item);
            }
          } else if (selectedPTMStatus === "Open") {
            if (!getInProgress(item) && !item.isHistory) {
              getAllRiskEmpConversationDataList.push(item);
            }
          }
        });
      }
      let emp_id = getAllRiskEmpConversationDataList && getAllRiskEmpConversationDataList[0] && getAllRiskEmpConversationDataList[0].emp_id?getAllRiskEmpConversationDataList[0].emp_id:"";
      if(emp_id){
        this.props.callEmpQuestionResponses(emp_id)
      }else{
        let isEmpty = true;
        this.props.callEmpQuestionResponses(emp_id,isEmpty)
      }

    });
  };

  handleSetSection = (data, emp_id) => {
    this.setState(
      {
        showSection: data,
      },
      () => {
        if (data === "Assessment") {
          let { handlePTMAssessment } = this.props;
          // handlePTMAssessment(emp_id);
        }
      }
    );
  };


  handleResolvedText = (value) => {
    this.setState({ resolvedText: value })
  }


  render() {
    let {
      getAssessmentReportByEmpIDOnlyData,
      getAllRiskEmpConversationData,
      RiskgetEmpQuestionResponsesData,
      RiskgetRiskCategoryData,
      EmpFilterData,
      getIndexFilterData,
      questionsList,
      handleCrossTabApi,
      crossTabData,
      crossTabAnalysisData,
      RiskRespondantEmployeesData,
      crrSelectedEmp,
      handleSelectEmpFilter,
      CurrentSurveyId,
      RiskAllEmpQuesResponsesData,
      emptyRiskQuesEmp,
      loading
    } = this.props;
    let {
      crrEmployeeData,
      onShowPTMemp,
      crrEmployeeId,
      showTab,
      showCatgory,
      selectedCategory,
      FilterValuesDMG,
    } = this.state;

    console.log(
      "...........getAllRiskEmpConversationData",
      getAllRiskEmpConversationData
    );

    console.log('...........getAssessmentReportByEmpIDOnlyData', getAssessmentReportByEmpIDOnlyData);

    let getAssessmentReportByEmpIDData = []
    if (getAssessmentReportByEmpIDOnlyData && getAssessmentReportByEmpIDOnlyData.length > 0) {
      getAssessmentReportByEmpIDOnlyData.forEach((ele) => {
        if (ele && ele.dimension) {
          getAssessmentReportByEmpIDData.push(ele)
        }
      })
    }


    function getInProgress(itemX) {
      let count = 0;
      if (itemX && itemX.Schedule && itemX.Schedule.length > 0) {
        count++;
      }
      if (itemX && itemX.actionList && itemX.actionList.length > 0) {
        count++;
      }
      if (itemX && itemX.noteList && itemX.noteList.length > 0) {
        count++;
      }
      if (itemX && itemX.talkList && itemX.talkList.length > 0) {
        count++;
      }
      return count ? true : false;
    }
    let { selectedPTMStatus } = this.state;
    let getAllRiskEmpConversationDataList = [];
    if (
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationData.list.length > 0
    ) {
      getAllRiskEmpConversationData.list.forEach((item) => {
        if (selectedPTMStatus === "Resolved") {
          if (item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        } else if (selectedPTMStatus === "InProgress") {
          if (getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        } else if (selectedPTMStatus === "Open") {
          if (!getInProgress(item) && !item.isHistory) {
            getAllRiskEmpConversationDataList.push(item);
          }
        }
      });
    }

    let initEmpId = "";
    if (crrEmployeeId) {
      initEmpId = crrEmployeeId;
    } else {
      if (
        getAllRiskEmpConversationDataList &&
        getAllRiskEmpConversationDataList.length > 0
      ) {
        initEmpId = getAllRiskEmpConversationDataList[0].id;
      }
    }
    let categoryList = [
      {
        name: "All",
        label: "Show All",
        color: "#000000",
      },
      {
        name: "Extremely_High_Risk",
        label: "Extremely High Risk",
        color: "#b31717",
      },
      {
        name: "High_Risk",
        label: "High Risk",
        color: "#e65757",
      },
      {
        name: "Borderline",
        label: "Borderline",
        color: "#f29083",
      },
      {
        name: "Moderate_Risk",
        label: "Moderate Risk",
        color: "#edb409",
      },
      {
        name: "No_Risk",
        label: "No Risk",
        color: "#1bbf36",
      },
    ];

    console.log("getAllRiskEmpConversationDataList---->");
    function getEmpDataById(crrId, type) {
      let Data = "";
      if (
        getAllRiskEmpConversationDataList &&
        getAllRiskEmpConversationDataList.length > 0
      ) {
        if (type === "name") {
          let getIndex = getAllRiskEmpConversationDataList.findIndex(
            (prev) => prev.id === crrId
          );
          if (getIndex !== -1) {
            Data = getAllRiskEmpConversationDataList[getIndex].name;
          }
        } else if (type === "emp_id") {
          let getIndex = getAllRiskEmpConversationDataList.findIndex(
            (prev) => prev.id === crrId
          );
          if (getIndex !== -1) {
            Data = getAllRiskEmpConversationDataList[getIndex].emp_id;
          }
        } else if (type === "checkdata") {
          let getIndex = getAllRiskEmpConversationDataList.findIndex(
            (prev) => prev.id === crrId
          );
          if (getIndex !== -1) {
            Data = true;
          }
        } else if (type === "UnResolved") {
          let getIndex = getAllRiskEmpConversationDataList.findIndex(
            (prev) => prev.id === crrId
          );
          if (getIndex !== -1) {
            Data =
              getAllRiskEmpConversationData &&
                getAllRiskEmpConversationDataList &&
                getAllRiskEmpConversationDataList[getIndex] &&
                getAllRiskEmpConversationDataList[getIndex].isHistory
                ? false
                : true;
          }
        } else if (type === "Schedule") {
          let getIndex = getAllRiskEmpConversationDataList.findIndex(
            (prev) => prev.id === crrId
          );
          if (getIndex !== -1) {
            let Temp =
              getAllRiskEmpConversationData &&
                getAllRiskEmpConversationDataList &&
                getAllRiskEmpConversationDataList[getIndex] &&
                getAllRiskEmpConversationDataList[getIndex][type] &&
                getAllRiskEmpConversationDataList[getIndex][type].length > 0
                ? getAllRiskEmpConversationDataList[getIndex][type]
                : [];
            Data = Temp;
          }
        } else if (type === "riskCategory") {
          let getIndex = getAllRiskEmpConversationDataList.findIndex(
            (prev) => prev.id === crrId
          );
          if (getIndex !== -1) {
            let getRiskScoreX =
              getAllRiskEmpConversationData &&
                getAllRiskEmpConversationDataList &&
                getAllRiskEmpConversationDataList[getIndex] &&
                getAllRiskEmpConversationDataList[getIndex].riskscore
                ? getAllRiskEmpConversationDataList[getIndex].riskscore
                : 0;

            let getRiskScore = Number(getRiskScoreX);
            if (getRiskScore >= 25 && getRiskScore < 50) {
              Data = "Extremely High Risk";
            } else if (getRiskScore >= 50 && getRiskScore < 75) {
              Data = "High Risk";
            } else if (getRiskScore >= 75 && getRiskScore < 100) {
              Data = "Borderline";
            } else if (getRiskScore >= 100 && getRiskScore < 125) {
              Data = "Moderate Risk";
            } else if (getRiskScore >= 125 && getRiskScore <= 150) {
              Data = "No Risk";
            } else {
              Data = "Unavailable";
            }
          }
        } else if (type === "riskScore") {
          let getIndex = getAllRiskEmpConversationDataList.findIndex(
            (prev) => prev.id === crrId
          );
          if (getIndex !== -1) {
            Data =
              getAllRiskEmpConversationData &&
                getAllRiskEmpConversationDataList &&
                getAllRiskEmpConversationDataList[getIndex] &&
                getAllRiskEmpConversationDataList[getIndex].riskscore
                ? getAllRiskEmpConversationDataList[getIndex].riskscore
                : 0;
          }
        } else if (type === "pulseScore") {
          let getIndex = getAllRiskEmpConversationDataList.findIndex(
            (prev) => prev.id === crrId
          );
          if (getIndex !== -1) {
            let DataList =
              getAllRiskEmpConversationData &&
                getAllRiskEmpConversationDataList &&
                getAllRiskEmpConversationDataList[getIndex] &&
                getAllRiskEmpConversationDataList[getIndex].pulseScoreList &&
                getAllRiskEmpConversationDataList[getIndex].pulseScoreList
                  .length > 0
                ? getAllRiskEmpConversationDataList[getIndex].pulseScoreList
                : [];

            if (DataList && DataList.length > 0) {
              Data =
                DataList &&
                  DataList[DataList.length - 1] &&
                  DataList[DataList.length - 1]["score"]
                  ? DataList[DataList.length - 1]["score"]
                  : 0;
            }
          }
        } else if (type === "pulseScoreList") {
          let getIndex = getAllRiskEmpConversationDataList.findIndex(
            (prev) => prev.id === crrId
          );
          if (getIndex !== -1) {
            let DataList =
              getAllRiskEmpConversationData &&
                getAllRiskEmpConversationDataList &&
                getAllRiskEmpConversationDataList[getIndex] &&
                getAllRiskEmpConversationDataList[getIndex].pulseScoreList &&
                getAllRiskEmpConversationDataList[getIndex].pulseScoreList
                  .length > 0
                ? getAllRiskEmpConversationDataList[getIndex].pulseScoreList
                : [];
            Data = DataList;
          }
        } else if (type === "data") {
          let getIndex = getAllRiskEmpConversationDataList.findIndex(
            (prev) => prev.id === crrId
          );
          if (getIndex !== -1) {
            Data =
              getAllRiskEmpConversationData &&
                getAllRiskEmpConversationDataList &&
                getAllRiskEmpConversationDataList[getIndex]
                ? getAllRiskEmpConversationDataList[getIndex]
                : 0;
          }
        } else if (type === "riskScoreColor") {
          let getIndex = getAllRiskEmpConversationDataList.findIndex(
            (prev) => prev.id === crrId
          );
          if (getIndex !== -1) {
            let getRiskScoreX =
              getAllRiskEmpConversationData &&
                getAllRiskEmpConversationDataList &&
                getAllRiskEmpConversationDataList[getIndex] &&
                getAllRiskEmpConversationDataList[getIndex].riskscore
                ? getAllRiskEmpConversationDataList[getIndex].riskscore
                : 0;

            let getRiskScore = Number(getRiskScoreX);
            if (getRiskScore >= 25 && getRiskScore < 50) {
              Data = "#b31717";
            } else if (getRiskScore >= 50 && getRiskScore < 75) {
              Data = "#e65757";
            } else if (getRiskScore >= 75 && getRiskScore < 100) {
              Data = "#f29083";
            } else if (getRiskScore >= 100 && getRiskScore < 125) {
              Data = "#edb409";
            } else if (getRiskScore >= 125 && getRiskScore <= 150) {
              Data = "#1bbf36";
            } else {
              Data = "#757575";
            }
          }
        } else {
          let getIndex = getAllRiskEmpConversationDataList.findIndex(
            (prev) => prev.id === crrId
          );
          if (getIndex !== -1) {
            Data =
              getAllRiskEmpConversationData &&
                getAllRiskEmpConversationDataList &&
                getAllRiskEmpConversationDataList[getIndex] &&
                getAllRiskEmpConversationDataList[getIndex][type] &&
                getAllRiskEmpConversationDataList[getIndex][type].length > 0
                ? getAllRiskEmpConversationDataList[getIndex][type]
                : [];
          }
        }
      }

      return Data;
    }

    //////console.log("this.state.formSchedule----.", this.state.formSchedule);
    //////console.log("this.state.formAction----.", this.state.formAction);

    function getFilterEmp(scoreX, nameX) {
      let isContain = false;
      let getRiskScore = Number(scoreX);

      //////console.log("getRiskScore------------>", getRiskScore);
      let name = nameX.toString();
      //////console.log("name------------>", name);

      if (getRiskScore >= 25 && getRiskScore < 50) {
        if (name === "Extremely_High_Risk") {
          isContain = true;
        }
      } else if (getRiskScore >= 50 && getRiskScore < 75) {
        if (name === "High_Risk") {
          isContain = true;
        }
      } else if (getRiskScore >= 75 && getRiskScore < 100) {
        if (name === "Borderline") {
          isContain = true;
        }
      } else if (getRiskScore >= 100 && getRiskScore < 125) {
        if (name === "Moderate_Risk") {
          isContain = true;
        }
      } else if (getRiskScore >= 125 && getRiskScore <= 150) {
        if (name === "No_Risk") {
          isContain = true;
        }
      }
      //////console.log("isContain---->", isContain)

      return isContain;
    }

    let FilterAllEmpResponses = [];

    if (
      getAllRiskEmpConversationData &&
      getAllRiskEmpConversationDataList &&
      getAllRiskEmpConversationDataList.length > 0
    ) {
      if (FilterValuesDMG && FilterValuesDMG.length > 0) {
        getAllRiskEmpConversationDataList.forEach((item) => {
          let isCheck = 0;
          if (FilterValuesDMG && FilterValuesDMG.length > 0) {
            FilterValuesDMG.forEach((filter) => {
              if (filter.value !== "All") {
                let Emp = item;
                if (!(Emp[filter.level] === filter.value)) {
                  isCheck++;
                }
              }
            });
          }
          // //console.log('x Emp: ',Emp);
          // //console.log('x isCheck: ',isCheck);

          if (isCheck === 0) {
            FilterAllEmpResponses.push(item);
          }
        });
      } else {
        FilterAllEmpResponses = getAllRiskEmpConversationDataList;
      }
    }

    let empList = [];
    if (selectedCategory && selectedCategory.name === "All") {
      if (FilterAllEmpResponses && FilterAllEmpResponses.length > 0) {
        empList = FilterAllEmpResponses;
      }
    } else {
      if (FilterAllEmpResponses && FilterAllEmpResponses.length > 0) {
        empList = FilterAllEmpResponses.filter((prev) =>
          getFilterEmp(prev.riskscore, selectedCategory.name)
        );
      }
    }

    //console.log('getAllRiskEmpConversationData1------------->', getAllRiskEmpConversationData)
    //console.log('FilterAllEmpResponses1------------->', FilterAllEmpResponses)
    //console.log('empList1------------->', empList)

    const isMobile = window.innerWidth <= 700;

    let newEmpList = empList;

    // function getInProgress (itemX){
    //   let count = 0;
    //   if(itemX && itemX.Schedule && itemX.Schedule.length>0){
    //     count++
    //   }
    //   if(itemX && itemX.actionList && itemX.actionList.length>0){
    //     count++
    //   }
    //   if(itemX && itemX.noteList && itemX.noteList.length>0){
    //     count++
    //   }
    //   if(itemX && itemX.talkList && itemX.talkList.length>0){
    //     count++
    //   }
    //   return count?true:false
    // }

    // let newEmpList = []
    // if(empList && empList.length>0){
    //   empList.forEach((item)=>{
    //     if(selectedPTMStatus === "Resolved"){
    //       if(item.isHistory){
    //         newEmpList.push(item)
    //       }
    //     }
    //     else if(selectedPTMStatus === "InProgress"){
    //       if(getInProgress(item) && !item.isHistory){
    //         newEmpList.push(item)
    //       }
    //     }
    //     else if(selectedPTMStatus === "Open"){
    //       if(!getInProgress(item) && !item.isHistory){
    //         newEmpList.push(item)
    //       }
    //     }

    //   })
    // }

    let PTMTypeList = ["InProgress", "Open", "Resolved"];

    const cirleColor = (element) => {
      return (
        <div className="">
          <span
            style={{
              background:
                element && element.colorCode ? element.colorCode : "#DDDDDD",
            }}
            className={"w-5 h-5 rounded-full "}
          />
        </div>
      );
    };

    const barWidthColor = (element) => {
      return (

        <span
          style={{
            background:
              element && element.colorCode ? element.colorCode : "#DDDDDD",
          }}
          className={"lg:w-5 w-3 lg:h-5 h-3 rounded-full lg:mt-0 mt-1 "}
        />

      );
    };

    const shortSummary = (element, index) => {
      return (
        <div className="flex items-start w-full space-x-4 lg:items-center md:px-2">
          {barWidthColor(element)}

          <div className="flex flex-1 w-full">
            <p className="text-base font-normal">
              <b className="pr-2">
                {(index + 1).toString() + ". " + element.dimension + ":"}
              </b>
              {element && element.details && element.details.desc
                ? element.details.desc
                : ""}
            </p>
            {/* <p className="text-base font-normal">
            {element &&
              element.details &&
              element.details.desc
                ? element.details.desc
                : ""}
          </p> */}
          </div>
        </div>
      );
    };

    return (
      <>
        <div
          className="w-full p-4 space-y-4 overflow-x-hidden overflow-y-auto md:p-6 bg-[#F9FAFB]"style={{ height: "calc(100% - 4rem)" }} >

          <div className="flex items-center justify-between">
            <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">1:1 conversation</h1>
            <span className="cursor-pointer material-symbols-outlined lg:hidden">
              menu_open
            </span>
          </div>

          <div className="flex gap-2 ">
            <Demographics
              showFilter={this.state.showFilterDMG}
              showValue={this.state.showValueDMG}
              closeFilter={this.closeFilterDMG}
              EmpFilterData2={EmpFilterData}
              getIndexFilterData={getIndexFilterData}
              handleMultifilterIndexScore={this.handleMultifilterIndexScore2DMG}
            />
          </div>

          <div className="w-full space-y-6 xl:flex xl:space-x-6 xl:space-y-0 ">
            <div className="xl:w-[20%] w-full   divide-y bg-white border relative xl:h-[calc(100vh-3rem)] h-80">
              <div className="items-center justify-between w-full p-4 cursor-pointer ">
                <div>
                  <div className="flex items-center justify-between">
                    <h1 className="text-base font-medium text-gray-800 lg:text-lg lg:font-semibold">
                      People To Meet case
                    </h1>
                  </div>
                </div>

                <div className="flex justify-between p-2 mt-4 border rounded-md">
                  <p
                    onClick={() => this.onShowPTMempDrop(!onShowPTMemp)}
                    className="w-full text-sm capitalize"
                  >
                    {selectedPTMStatus}
                    <b className="font-semibold ">
                      {" "}
                      (
                      {newEmpList && newEmpList.length > 0
                        ? newEmpList.length
                        : 0}
                      )
                    </b>
                  </p>
                  <span
                    onClick={() => this.onShowPTMempDrop(!onShowPTMemp)}
                    className="material-symbols-outlined"
                  >
                    arrow_drop_down
                  </span>

                  {onShowPTMemp ? (
                    <div className="absolute right-0 w-full p-3 overflow-x-auto font-normal bg-white rounded-md shadow-lg z-24 top-24 font-xs h-60 customscroll2">
                      {PTMTypeList && PTMTypeList.length > 0
                        ? PTMTypeList.map((item, index2) => (
                          <div
                            key={index2}
                            className="flex items-center py-2 m-1 cursor-pointer"
                            onClick={() => {
                              this.handleSelectPTMStatus(item);
                              this.onShowPTMempDrop(false);
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedPTMStatus === item}
                              onChange={() => { }}
                            />
                            <label className="ml-2 font-medium cursor-pointer">
                              {item}
                            </label>
                          </div>
                        ))
                        : null}
                    </div>
                  ) : null}
                </div>

                {/* {this.state.showMobileEMPDropdown  ? (
                  <>
                    <div
                      className="w-full space-y-2 overflow-hidden overflow-y-auto font-normal font-xs h-80"
                      id="chatbot"
                    >
                      {!onShowPTMemp ? (
                        <>
                          {newEmpList && newEmpList.length > 0
                            ? newEmpList.map((item, index) => (
                                <div
                                  onClick={() => this.selectEmp(item.id)}
                                  key={index}
                                  className="flex px-4"
                                >
                                  <span
                                    className="text-gray-300 material-symbols-outlined"
                                    style={{ fontSize: "3rem" }}
                                  >
                                    account_circle{" "}
                                  </span>
                                  <p className="pt-3 pl-2">{item.name}</p>
                                </div>
                              ))
                            : null}
                        </>
                      ) : null}
                    </div>
                  </>
                ) : null} */}
              </div>

              <div
                className={
                  this.state.active
                    ? "  bg-white  customscroll4  overflow-y-auto lg:block    w-full z-0"
                    : " bg-white  customscroll4  overflow-y-auto lg:block   w-full z-0 "
                }
              >
                {!onShowPTMemp ? (
                  <>
                    {newEmpList && newEmpList.length > 0
                      ? newEmpList.map((item) => (
                        <>
                          <div
                            onClick={() => this.selectEmp(item)}
                            className={
                              initEmpId === item.id
                                ? "flex justify-between cursor-pointer items-center space-x-2 capitalize p-2 bg-blue-100 w-full"
                                : "flex justify-between cursor-pointer items-center space-x-2 capitalize p-2 hover:bg-blue-100 w-full"
                            }
                          >
                            <div className="flex ">
                              <span
                                className="text-gray-300 material-symbols-outlined"
                                style={{ fontSize: "3rem" }}
                              >
                                account_circle{" "}
                              </span>
                              <p className="pt-3 pl-2">{item.name}</p>
                            </div>

                            <div className="flex">
                              {/* <span className='px-2 font-medium '>Score: {item.riskscore}</span> */}
                              {/* <span onClick={()=>this.handleDetailsPopUp(item)} className='font-bold text-white bg-blue-400 rounded-md material-symbols-outlined hover:bg-blue-500'>add</span> */}
                            </div>
                          </div>
                        </>
                      ))
                      : null}
                  </>
                ) : null}
              </div>
            </div>

            <div className="xl:w-[80%]  space-y-4 xl:flex-1 h-[calc(100vh-14rem)]  ">
              <div className="">
                <div className="py-5 mb-4 bg-[#e3f2fd] px-8 rounded-md ">
                  <div className="grid justify-between grid-cols-1 gap-6 divide-y-2 divide-blue-200 lg:grid-cols-5 md:grid-cols-3 md:divide-x-2 md:divide-y-0">
                    <div className="flex items-center py-4 space-x-4 md:py-0 lg:col-span-2 md:col-span-3">
                      <img
                        src={"img/profile_place_holder_img.png"}

                        // src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80"
                        className="w-20 h-20 rounded-full lg:w-24 lg:h-24 ring-4 ring-white "
                      />
                      <div className="space-y-2">
                        <p className="pt-2 pl-2 text-xl font-medium text-slate-800">
                          {getEmpDataById(initEmpId, "name")}
                        </p>

                        {/* 

 <button  className='flex items-center px-4 py-2 text-sm text-white capitalize bg-blue-500 rounded-full cursor-pointer whitespace-nowrap xl:text-base '>
                            Move to Resolved
                            <span className="ml-2 material-symbols-outlined">
                              moving
                            </span>
                          </button> */}

                        {getEmpDataById(initEmpId, "UnResolved") ?
                          <div className="bg-[#2196f3] px-4 py-2 rounded-lg text-white whitespace-nowrap">
                            <button onClick={() => this.onResolveModal(getEmpDataById(initEmpId, "data"))} className="flex items-center justify-center capitalize cursor-pointer">
                              Move to Resolved
                              <span className="ml-2 material-symbols-outlined">
                                arrow_outward
                              </span>
                            </button>
                          </div>
                          :
                          <div className="px-4 py-2 text-white bg-green-500 rounded-lg">
                            <button className="flex items-center justify-center capitalize cursor-pointer">
                              Resolved
                              <span className="ml-2 material-symbols-outlined">
                                check
                              </span>
                            </button>
                          </div>
                        }



                        {/* 
<button className='flex items-center px-4 py-2 text-sm text-white capitalize bg-green-500 rounded-full cursor-pointer whitespace-nowrap xl:text-base '>
                            Resolved
                            <span className="ml-2 material-symbols-outlined">
                              check
                            </span>
                          </button>
                           */}


                        {/* <button className="flex items-center justify-center capitalize">
                            move to reload
                            <span className="ml-2 material-symbols-outlined">
                              arrow_outward
                            </span>
                          </button> */}

                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center py-4 text-left md:py-0">
                      <p className="text-slate-800">Emp ID</p>
                      <p className="text-xl font-bold text-slate-800">
                        {getEmpDataById(initEmpId, "emp_id")}
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center py-4 text-left md:py-0">
                      <p className="text-slate-800 ">Location</p>
                      <p className="text-xl font-bold text-slate-800">
                        {getEmpDataById(initEmpId, "Location")}
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center py-4 text-left md:py-0">
                      <p className="text-slate-800 ">Country</p>
                      <p className="text-xl font-bold text-slate-800">
                        {getEmpDataById(initEmpId, "Country")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-white border ">
                  <div className="text-sm font-medium text-center text-gray-500 border-b ">
                    <ul className="flex overflow-x-auto cursor-pointer whitespace-nowrap">
                      <li
                        className="mr-2"
                        onClick={() => this.handleSetSection("SenseInsight")}
                      >
                        <span
                          className={
                            this.state.showSection &&
                              this.state.showSection === "SenseInsight"
                              ? "inline-block p-4 text-[#2196f3] border-b-2 border-[#2196f3] rounded-t-lg active dark:text-[#2196f3] dark:border-[#2196f3]"
                              : "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                          }
                        >
                          Sense insight
                        </span>
                      </li>

                      <li
                        className="mr-2"
                        onClick={() =>
                          this.handleSetSection("EmployeeResponse")
                        }
                      >
                        <span
                          className={
                            this.state.showSection &&
                              this.state.showSection === "EmployeeResponse"
                              ? "inline-block p-4 text-[#2196f3] border-b-2 border-[#2196f3] rounded-t-lg active dark:text-[#2196f3] dark:border-[#2196f3]"
                              : "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                          }
                          aria-current="page"
                        >
                          Employee Feedback
                        </span>
                      </li>

                      <li
                        className="mr-2"
                        onClick={() => this.handleSetSection("ActionPoints")}
                      >
                        <span
                          className={
                            this.state.showSection &&
                              this.state.showSection === "ActionPoints"
                              ? "inline-block p-4 text-[#2196f3] border-b-2 border-[#2196f3] rounded-t-lg active dark:text-[#2196f3] dark:border-[#2196f3]"
                              : "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                          }
                        >
                          Action Points
                        </span>
                      </li>

                      <li
                        className="mr-2"
                        onClick={() =>
                          this.handleSetSection(
                            "Assessment",
                            getEmpDataById(initEmpId, "emp_id")
                          )
                        }
                      >
                        <span
                          className={
                            this.state.showSection &&
                              this.state.showSection === "Assessment"
                              ? "inline-block p-4 text-[#2196f3] border-b-2 border-[#2196f3] rounded-t-lg active dark:text-[#2196f3] dark:border-[#2196f3]"
                              : "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                          }
                        >
                          Assessment
                        </span>
                      </li>
                    </ul>
                  </div>

                  {getEmpDataById(initEmpId, "emp_id")?
                  <>
                    <div className="overflow-y-auto h-[calc(100vh-16em)] overflow-x-hidden w-full">

                      {this.state.showSection &&
                        this.state.showSection === "SenseInsight" ? (
                        <>
                          <div className="w-full p-6 space-y-10 ">
                            <h1 className="text-lg font-semibold">
                              Employee Score
                            </h1>

                            <div className="flex flex-col justify-center w-full space-y-8 lg:flex-row lg:divide-x lg:space-y-0">
                              <EmployeeMeter
                                questionsList={
                                  questionsList && questionsList.length > 0
                                    ? questionsList
                                      .filter((x) => x.templateId !== 13)
                                      .filter((x) => x.templateId !== 14)
                                      .filter((x) => x.templateId !== 3)
                                      .filter((x) => x.templateId !== 4)
                                    : []
                                }
                                handleCrossTabApi={handleCrossTabApi}
                                crossTabData={crossTabData}
                                EmpFilterData={EmpFilterData}
                                getIndexFilterData={getIndexFilterData}
                                crossTabAnalysisData={crossTabAnalysisData}
                                RiskRespondantEmployeesData={
                                  RiskRespondantEmployeesData
                                }
                                crrSelectedEmp={crrSelectedEmp}
                                handleSelectEmpFilter={handleSelectEmpFilter}
                                RiskgetEmpQuestionResponsesData={
                                  RiskgetEmpQuestionResponsesData
                                }
                                RiskgetRiskCategoryData={RiskgetRiskCategoryData}
                                CurrentSurveyId={CurrentSurveyId}
                                RiskAllEmpQuesResponsesData={
                                  RiskAllEmpQuesResponsesData
                                }
                                emptyRiskQuesEmp={emptyRiskQuesEmp}
                                check={1}
                              />
                            </div>

                            <PluseScoreChart
                              crrPulseScoreList={getEmpDataById(
                                initEmpId,
                                "pulseScoreList"
                              )}
                            />
                          </div>
                        </>
                      ) : null}

                      {this.state.showSection &&
                        this.state.showSection === "EmployeeResponse" ? (
                        <>
                          <EmployeeTable
                            questionsList={
                              questionsList && questionsList.length > 0
                                ? questionsList
                                  .filter((x) => x.templateId !== 13)
                                  .filter((x) => x.templateId !== 14)
                                  .filter((x) => x.templateId !== 3)
                                  .filter((x) => x.templateId !== 4)
                                : []
                            }
                            handleCrossTabApi={handleCrossTabApi}
                            crossTabData={crossTabData}
                            EmpFilterData={EmpFilterData}
                            getIndexFilterData={getIndexFilterData}
                            crossTabAnalysisData={crossTabAnalysisData}
                            RiskRespondantEmployeesData={
                              RiskRespondantEmployeesData
                            }
                            crrSelectedEmp={crrSelectedEmp}
                            handleSelectEmpFilter={handleSelectEmpFilter}
                            RiskgetEmpQuestionResponsesData={
                              RiskgetEmpQuestionResponsesData
                            }
                            RiskgetRiskCategoryData={RiskgetRiskCategoryData}
                            CurrentSurveyId={CurrentSurveyId}
                            RiskAllEmpQuesResponsesData={
                              RiskAllEmpQuesResponsesData
                            }
                            emptyRiskQuesEmp={emptyRiskQuesEmp}
                            check={2}
                          />
                        </>
                      ) : null}

                      {this.state.showSection &&
                        this.state.showSection === "ActionPoints" ? (
                        <>
                          <div className="w-full p-6 space-y-10 ">
                            {getEmpDataById(initEmpId, "checkdata") ? (
                              <>
                                <div className="w-full space-y-4 ">
                                  <div className="space-y-4 ">
                                    {/* <div className="grid grid-cols-1 gap-6 p-4 capitalize border-b md:grid-cols-3 md:gap-0 md:divide-x ">
                                      <div className='flex items-center space-x-2 xl:flex md:block'>

                                        <span className="text-gray-200 material-symbols-outlined " style={{ fontSize: '6rem' }}>account_circle </span>
                                        <div className='space-y-2'>
                                          <p className="text-xl font-normal">{getEmpDataById(initEmpId, "name")}</p>

                                          <div className='flex items-center space-x-2'>
                                            {getEmpDataById(initEmpId, "UnResolved") ?
                                              <button onClick={() => this.onResolveModal(getEmpDataById(initEmpId, "data"))} className='flex items-center px-4 py-2 text-sm text-white capitalize bg-blue-500 rounded-full cursor-pointer whitespace-nowrap xl:text-base '>
                                                Move to Resolved
                                                <span className="ml-2 material-symbols-outlined">
                                                  moving
                                                </span>
                                              </button>
                                              :
                                              <button className='flex items-center px-4 py-2 text-sm text-white capitalize bg-green-500 rounded-full cursor-pointer whitespace-nowrap xl:text-base '>
                                                Resolved
                                                <span className="ml-2 material-symbols-outlined">
                                                  check
                                                </span>
                                              </button>
                                            }


                                          </div>
                                        </div>
                                      </div>

                                      <div className='flex items-center justify-start space-x-2 md:justify-center '>
                                        <div className='space-y-2'>
                                          <p className="text-xl font-normal">Sense Score</p>
                                          <div className='items-center xl:flex xl:space-x-2'>
                                            <p className="text-lg font-semibold" >   {getEmpDataById(initEmpId, "riskScore")}</p>
                                            <button className="px-4 py-1 text-sm font-medium border border-red-400 rounded-full cursor-pointer " style={{ color: getEmpDataById(initEmpId, "riskScoreColor") }}>{getEmpDataById(initEmpId, "riskCategory")}</button>
                                          </div>   </div>
                                      </div>

                                      <div className='flex items-center justify-start space-x-2 md:justify-center '>
                                        <div className='space-y-2'>
                                          <p className="text-xl font-normal">Pulse Score</p>
                                          <div className='items-center xl:flex xl:space-x-2'>
                                            <p className="text-lg font-semibold" >   {getEmpDataById(initEmpId, "pulseScore")}</p>
                                            <button onClick={() => this.onPluseModal(getEmpDataById(initEmpId, "pulseScoreList"))} className="px-4 py-1 text-sm font-medium text-blue-500 border border-blue-500 rounded-full cursor-pointer "  > view trend chart</button>
                                          </div>   </div>
                                      </div>
                                    </div> */}
                                    <div className="flex items-center justify-between w-full ">
                                      <h1 className="text-lg font-semibold">
                                        Timeline
                                      </h1>
                                      <button
                                        onClick={() =>
                                          this.openScheduleModal(
                                            !this.state.showScheduleModal
                                          )
                                        }
                                        className="bg-[#2196f3] px-6 py-2 rounded-full text-white capitalize text-sm font-medium flex items-center"
                                      >
                                        <span className="mr-2 material-symbols-outlined">
                                          schedule
                                        </span>{" "}
                                        schedule
                                      </button>
                                    </div>

                                    <div className="w-full overflow-x-auto scollar-xs whitespace-nowrap ">
                                      <div className="flex space-x-4 ">
                                        {getEmpDataById(initEmpId, "Schedule") &&
                                          getEmpDataById(initEmpId, "Schedule")
                                            .length > 0
                                          ? getEmpDataById(
                                            initEmpId,
                                            "Schedule"
                                          ).map((item) => (
                                            <>
                                              <div className="border rounded whitespace-nowrap">
                                                {item && item.isComplete ? (
                                                  <div className="bg-white border-b-4 border-[#2196f3] p-4 flex space-x-4 items-center   ">
                                                    <span
                                                      className={
                                                        "material-symbols-outlined text-green-500"
                                                      }
                                                    >
                                                      {"check_circle"}
                                                    </span>
                                                    <div>
                                                      <p className="text-sm font-medium">
                                                        {item && item.date
                                                          ? moment(
                                                            item.date,
                                                            "YYYY-MM-DD"
                                                          ).format("DD MMM") +
                                                          (item && item.time
                                                            ? ", " + item.time
                                                            : "")
                                                          : "-"}
                                                      </p>
                                                      <p className="text-xs text-gray-500">
                                                        Completed
                                                      </p>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    onClick={() =>
                                                      this.openScheduleCompletedModal(
                                                        item
                                                      )
                                                    }
                                                    className="flex items-center p-4 space-x-4 bg-white cursor-pointer "
                                                  >
                                                    <span
                                                      className={
                                                        "material-symbols-outlined text-[#2196f3]"
                                                      }
                                                    >
                                                      {"timer"}
                                                    </span>
                                                    <div>
                                                      <p className="text-sm font-medium">
                                                        {item && item.date
                                                          ? moment(
                                                            item.date,
                                                            "YYYY-MM-DD"
                                                          ).format("DD MMM") +
                                                          (item && item.time
                                                            ? ", " + item.time
                                                            : "")
                                                          : "-"}
                                                      </p>
                                                      <p className="text-xs text-gray-500">
                                                        scheduled
                                                      </p>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </>
                                          ))
                                          : null}
                                      </div>
                                    </div>

                                    <nav className="flex overflow-x-auto scroll justify-left overscroll-x-contain ">
                                      <div className="flex w-full capitalize border-b justify-left whitespace-nowrap ">
                                        <span
                                          onClick={() =>
                                            this.handleTab("talking")
                                          }
                                          className={
                                            showTab === "talking"
                                              ? "cursor-pointer text-[#2196f3] border-b-2 hover:border-[#2196f3] border-[#2196f3]  tracking-wide font-medium  text-sm py-4 px-4 mr-8"
                                              : "cursor-pointer text-gray-500 border-b-2 hover:border-[#2196f3] border-transparent  tracking-wide font-medium  text-sm py-4 px-4 mr-8"
                                          }
                                          href=" "
                                        >
                                          Discussion Points
                                        </span>
                                        <span
                                          onClick={() => this.handleTab("action")}
                                          className={
                                            showTab === "action"
                                              ? "cursor-pointer text-[#2196f3] border-b-2 hover:border-[#2196f3] border-[#2196f3]  tracking-wide font-medium  text-sm py-4 px-4 mr-8"
                                              : "cursor-pointer text-gray-500 border-b-2 hover:border-[#2196f3] border-transparent  tracking-wide font-medium  text-sm py-4 px-4 mr-8"
                                          }
                                          href=" "
                                        >
                                          Action items
                                        </span>
                                        <span
                                          onClick={() => this.handleTab("notes")}
                                          className={
                                            showTab === "notes"
                                              ? "cursor-pointer text-[#2196f3] border-b-2 hover:border-[#2196f3] border-[#2196f3]  tracking-wide font-medium  text-sm py-4 px-4 mr-8"
                                              : "cursor-pointer text-gray-500 border-b-2 hover:border-[#2196f3] border-transparent  tracking-wide font-medium  text-sm py-4 px-4 mr-8"
                                          }
                                          href=" "
                                        >
                                          Notes
                                        </span>
                                      </div>
                                    </nav>
                                  </div>

                                  {showTab === "talking" ? (
                                    <div className="w-full space-y-6 overflow-y-auto customscroll4 h-96 ">
                                      <div className="flex items-center justify-between">
                                        <h1 className="text-lg font-medium capitalize">
                                          Discussion points
                                        </h1>
                                        <button
                                          onClick={() => this.onCreateTalking()}
                                          className="flex items-center px-4 py-2 text-sm font-medium text-blue-500 capitalize border border-blue-500 rounded-full"
                                        >
                                          <span className="material-symbols-outlined ">
                                            add
                                          </span>{" "}
                                          Add points
                                        </button>
                                      </div>

                                      {getEmpDataById(initEmpId, "talkList") &&
                                        getEmpDataById(initEmpId, "talkList")
                                          .length > 0
                                        ? getEmpDataById(
                                          initEmpId,
                                          "talkList"
                                        ).map((item, index) => (
                                          <>
                                            <div>
                                              <div className="flex ">
                                                <h1 className="p-2 px-6 text-base font-medium border rounded">
                                                  {item.text}
                                                </h1>
                                              </div>
                                              <p className="text-sm text-gray-500">
                                                {item.reply}
                                              </p>
                                            </div>
                                            {this.state.showTalkingField &&
                                              index ===
                                              getEmpDataById(
                                                initEmpId,
                                                "talkList"
                                              ).length -
                                              1 ? (
                                              <div className="flex">
                                                <input
                                                  type="text"
                                                  name="talking"
                                                  placeholder="Type Here..."
                                                  value={this.state.talking}
                                                  onChange={(e) =>
                                                    this.handleInputTalking(e)
                                                  }
                                                  className="flex justify-between w-full p-2 text-blue-500 border rounded-lg cursor-pointer"
                                                />
                                                <button
                                                  onClick={() =>
                                                    this.SumbitTalking()
                                                  }
                                                  className="px-2 ml-2 text-white bg-blue-500 rounded-md cursor-pointer material-symbols-outlined"
                                                >
                                                  arrow_forward
                                                </button>
                                              </div>
                                            ) : null}
                                          </>
                                        ))
                                        : null}
                                    </div>
                                  ) : null}
                                  {showTab === "action" ? (
                                    <>
                                      <div className="space-y-6 overflow-y-auto customscroll4 h-96">
                                        <div className="flex items-center justify-between">
                                          <h1 className="text-lg font-medium capitalize">
                                            Action items
                                          </h1>
                                          <button
                                            onClick={() => this.onCreateAction()}
                                            className="flex items-center px-4 py-2 text-sm font-medium text-blue-500 capitalize border border-blue-500 rounded-full"
                                          >
                                            <span className="mr-2 material-symbols-outlined">
                                              add
                                            </span>{" "}
                                            Add action
                                          </button>
                                        </div>
                                        <div className="grid gap-6 2xl:grid-cols-6 xl:grid-cols-4 md:grid-cols-3">
                                          {getEmpDataById(
                                            initEmpId,
                                            "actionList"
                                          ) &&
                                            getEmpDataById(initEmpId, "actionList")
                                              .length > 0
                                            ? getEmpDataById(
                                              initEmpId,
                                              "actionList"
                                            ).map((item, index) => (
                                              <>
                                                <div
                                                  onClick={() =>
                                                    this.openActionCompletedModal(
                                                      item
                                                    )
                                                  }
                                                  className="p-4 space-y-4 bg-white border rounded-lg"
                                                >
                                                  <h2>{item.name}</h2>
                                                  <p className="text-sm text-gray-500">
                                                    {item.desc}
                                                  </p>
                                                  {item && item.isComplete ? (
                                                    <button className="px-4 py-1 text-xs font-medium text-white uppercase bg-green-500 rounded-full">
                                                      completed
                                                    </button>
                                                  ) : (
                                                    <button className="px-4 py-1 text-xs font-medium text-white uppercase bg-yellow-500 rounded-full">
                                                      pending
                                                    </button>
                                                  )}
                                                </div>
                                              </>
                                            ))
                                            : null}
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                  {showTab === "notes" ? (
                                    <>
                                      <div className="space-y-6 overflow-y-auto customscroll4 h-96">
                                        <div className="flex items-center justify-between">
                                          <h1 className="text-lg font-medium capitalize">
                                            Notes
                                          </h1>
                                          <button
                                            onClick={() => this.onCreateNote()}
                                            className="flex items-center px-4 py-2 text-sm font-medium text-blue-500 capitalize border border-blue-500 rounded-full"
                                          >
                                            <span className="mr-2 material-symbols-outlined">
                                              add
                                            </span>{" "}
                                            Add notes
                                          </button>
                                        </div>
                                        <div className="grid gap-6 2xl:grid-cols-6 xl:grid-cols-4 md:grid-cols-3">
                                          {getEmpDataById(
                                            initEmpId,
                                            "noteList"
                                          ) &&
                                            getEmpDataById(initEmpId, "noteList")
                                              .length > 0
                                            ? getEmpDataById(
                                              initEmpId,
                                              "noteList"
                                            ).map((item, index) => (
                                              <>
                                                <div className=" border rounded-lg p-4 space-y-6 bg-[#e3f2fd]">
                                                  <h2 className="text-sm text-gray-800">
                                                    {item.desc}
                                                  </h2>
                                                  <p className="text-xs font-medium rounded-full ">
                                                    {moment
                                                      .unix(
                                                        item.createdAt / 1000
                                                      )
                                                      .format("DD MMM")}
                                                  </p>
                                                </div>
                                              </>
                                            ))
                                            : null}
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="w-full h-full space-y-4 overflow-hidden bg-white border xl:flex-1">
                                  <div className="h-screen">
                                    <div className="pt-12 text-3xl font-bold text-center text-gray-500">
                                      Select People To Meet Case{" "}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : null}

                      {this.state.showSection &&
                        this.state.showSection === "Assessment" ? (
                        <>
                          {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?
                            <div className="w-full p-6 space-y-10 divide-y divide-dashed">
                              <div>
                                <div className="py-2 text-base font-normal">
                                  <h1 className="text-lg font-semibold text-slate-900">
                                    {
                                      "Your Top 5 Talent Plus theme: (Broadly they define who you are what makes you successful, they need to be understood, nurtured and used regularly to experience a thriving life)"
                                    }
                                  </h1>
                                </div>
                                <div className="flex w-full py-4">
                                  <div className="w-full space-y-2">
                                    {getAssessmentReportByEmpIDData &&
                                      getAssessmentReportByEmpIDData.length > 0
                                      ? getAssessmentReportByEmpIDData.map(
                                        (element, index) =>
                                          index + 1 <= 5 ? (
                                            <>{shortSummary(element, index)}</>
                                          ) : null
                                      )
                                      : null}
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="py-2 text-base font-normal">
                                  <h1 className="text-lg font-semibold text-slate-900">
                                    {
                                      "Your Next 11 TalentPlus Themes (This defines, talents which are there and can be drawn when required. Once you have mastered the top 5, these talents too can be developed further)"
                                    }
                                  </h1>
                                </div>
                                <div className="flex w-full py-4">
                                  <div className="w-full space-y-2">
                                    {getAssessmentReportByEmpIDData &&
                                      getAssessmentReportByEmpIDData.length > 0
                                      ? getAssessmentReportByEmpIDData.map(
                                        (element, index) =>
                                          6 <= index + 1 && index + 1 <= 16 ? (
                                            <>{shortSummary(element, index)}</>
                                          ) : null
                                      )
                                      : null}
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="py-2 text-base font-normal">
                                  <h1 className="text-lg font-semibold text-slate-900">
                                    {
                                      "Your last 5 TalentPlus Themes (These are the talents, which are there but not much & you may need to learn how to manage it to achieve your goals in life)"
                                    }
                                  </h1>
                                </div>
                                <div className="flex w-full py-4">
                                  <div className="w-full space-y-2">
                                    {getAssessmentReportByEmpIDData &&
                                      getAssessmentReportByEmpIDData.length > 0
                                      ? getAssessmentReportByEmpIDData.map(
                                        (element, index) =>
                                          17 <= index + 1 ? (
                                            <>{shortSummary(element, index)}</>
                                          ) : null
                                      )
                                      : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            : 
                            <>
                              {!loading ? (
                                <div className="pt-40 text-3xl text-center text-gray-400">
                                  We have not calculated this section!
                                </div>
                              ) : null}
                            </>
                            
                            }

                        </>
                      ) : null}


                    </div>
                  </>
                  :
                   <>
                   {!loading ? (
                     <div className="pt-40 text-3xl text-center text-gray-400">
                       Data not available
                     </div>
                   ) : null}
                 </>
                  }
                 



                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal */}

        <ScheduleModal
          showModal={this.state.showScheduleModal}
          onCloseModal={this.onCloseScheduleModal}
          formSchedule={this.state.formSchedule}
          handleInputField={this.handleInputField}
          handleSubmit={this.handleSubmit}
        />

        <ScheduleComplete
          showModal={this.state.showScheduleCompleteModal}
          onCloseModal={this.onCloseCompleteScheduleModal}
          handleSubmit={this.handleCompleteScheduleSubmit}
        />

        <CreateActionModal
          showModal={this.state.showCreateActionModal}
          onCloseModal={this.closeCreateActionModal}
          formAction={this.state.formAction}
          handleInputField={this.handleInputActionField}
          handleSubmit={this.handleCreateActionSubmit}
        />

        <ActionComplete
          showModal={this.state.showActionCompleteModal}
          onCloseModal={this.onCloseCompleteActionModal}
          handleSubmit={this.handleCompleteActionSubmit}
        />

        <CreateNoteModal
          showModal={this.state.showCreateNoteModal}
          onCloseModal={this.closeCreateNoteModal}
          formNotes={this.state.formNotes}
          handleInputField={this.handleInputNoteField}
          handleSubmit={this.handleCreateNoteSubmit}
        />

        <ResolveModal
          showModal={this.state.showResolveModal}
          closeResolveModal={this.closeResolveModal}
          crrEmployeeData={crrEmployeeData}
          onSubmitResolve={this.onSubmitResolve}
          handleResolvedText={this.handleResolvedText}
          resolvedText={this.state.resolvedText}
        />

        <PluseScoreModal
          showModal={this.state.showPluseModal}
          closeResolveModal={this.closePluseModal}
          crrPulseScoreList={this.state.crrPulseScoreList}
        />

        <CreateTalkingModal
          showModal={this.state.showCreateTalkingModal}
          onCloseModal={this.closeCreateTalkingModal}
          talking={this.state.talking}
          handleInputField={this.handleInputTalkingField}
          handleSubmit={this.handleCreateTalkingSubmit}
        />

        <EmployeeProfileModal
          showModal={this.state.showEmpPopUp}
          closeModal={this.closeEmpPopUpModal}
          RiskgetEmpQuestionResponsesData={RiskgetEmpQuestionResponsesData}
          RiskgetRiskCategoryData={RiskgetRiskCategoryData}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(OneToOneConversation);
