import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "./Header/Header";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import { CiFilter } from "react-icons/ci";
import { MdChevronRight, MdEdit, MdKeyboardArrowDown, MdOutlineModeEdit, MdCheck } from "react-icons/md";
import { pmsActions } from "../../_actions";
import moment from "moment";
import { AiOutlineAim, AiOutlineSave } from "react-icons/ai";
import { TbExclamationMark } from "react-icons/tb";

function PMSFinalApproval(props) {
  useEffect(() => {
    props.dispatch(pmsActions.pmsGetEmpAppraisalList(props));
  }, []);

  let { pms } = props;

  let { pmsGetEmpAppraisalList, getPMSAppraisalListStatus } = pms;

  let gotoPage = (data) => {
    props.history.push(`/app/pmsemployeegoals/${data.id}`);

    props.dispatch(
      pmsActions.PMSGetAppraisalDetailsById({ id: data && data.id })
    );
  };

  function getCompleteValue(list, appraisalCycleId, fieldName) {
    if (list && list.length > 0) {
      let index = list.findIndex(
        (prev) => prev.appraisalCycleId === appraisalCycleId
      );
      if (index !== -1) {
        return list && list[index] && list[index][fieldName]
          ? list[index][fieldName]
          : "";
      }
    }
  }

  function spliceDecimal(no) {
    if (no) {
      let str = no.toString();
      let first = str.split(".")[0];
      let second = str.split(".")[1];
      let second2 = second ? second.substring(0, 2) : "";
      let final = first + "." + second2;
      if (second2) {
        return parseFloat(final).toFixed(2);
      } else {
        return no;
      }
    } else {
      return 0;
    }
  }

  function spliceDecimal(no) {
    if (no) {
      let str = no.toString();
      let first = str.split(".")[0];
      let second = str.split(".")[1];
      let second2 = second ? second.substring(0, 2) : "";
      let final = first + "." + second2;
      if (second2) {
        return parseFloat(final).toFixed(2);
      } else {
        return no;
      }
    } else {
      return 0;
    }
  }

  const [isModal, setModal] = useState(false);

  return (
    <>
      <div className="h-screen overflow-hidden bg-gray-50">
        <Header
        // sidebarToggle={sidebarToggle}
        />

        <div className="flex w-full" style={{ height: "calc(100% - 2rem)" }}>
          <Sidebar />

          <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto ">
            <div className="md:p-6 p-4 mx-auto  overflow-hidden overflow-y-auto space-y-4 h-[calc(100vh-4rem)] ">
              <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
                <h1 className="md:text-2xl text-xl text-[#3D405B] font-semibold">
                  Final Approval{" "}
                </h1>
                <div className="flex items-center space-x-3 ">
                  <div className="flex items-center justify-between w-full px-2 space-x-2 overflow-hidden text-sm bg-white border rounded-sm md:w-auto">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-search text-gray-600"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      {" "}
                      <path
                        stroke="none"
                        d="M0 0h24v24H0z"
                        fill="none"
                      ></path>{" "}
                      <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>{" "}
                      <path d="M21 21l-6 -6"></path>{" "}
                    </svg>
                    <input
                      type="search"
                      name="search"
                      className="w-full py-2 md:w-48 focus:outline-none placeholder:text-[#212121]/50"
                      placeholder="Search"
                    />
                  </div>

                  <div className="flex justify-center md:justify-start">
                    <button className="w-full px-2 py-2 text-center text-white transition-all duration-200 transform bg-white border rounded-sm cursor-pointer md:w-auto text-md">
                      <CiFilter className="w-5 h-5 text-gray-600" />
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex justify-between bg-white border px-4 p-2.5 rounded-md capitalize cursor-pointer items-center text-[#3D405B]">
                <h1 className="text-base truncate  font-medium border-r">Appraisal HR - Q3</h1>
                <div className="border-l pl-4">
                  <MdKeyboardArrowDown className="text-[1.5rem]  " />
                </div>
              </div>
              <div className="grid gap-4 my-4 lg:grid-cols-3 md:grid-cols-2 ">
                <div className="flex items-start h-32 p-6 bg-white border rounded-sm cursor-pointer space-x-4 ">
                  <div className="p-1.5 bg-green-500 rounded-full text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-check"
                      width={28}
                      height={28}
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 12l5 5l10 -10" />
                    </svg>
                  </div>
                  <div className="flex items-center w-full ">
                    <div className="space-y-2">
                      <p className="block text-base text-[#3d405b] w-full   font-semibold">
                        Total
                      </p>
                      <h1 className="text-3xl font-medium w-full text-[#fff]">
                        9
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="flex items-start h-32 p-6 bg-white border rounded-sm cursor-pointer space-x-4 ">
                  <div className="p-1.5 bg-green-500 rounded-full text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-check"
                      width={28}
                      height={28}
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 12l5 5l10 -10" />
                    </svg>
                  </div>
                  <div className="flex items-center w-full ">
                    <div className="space-y-2">
                      <p className="block text-base text-[#3d405b] w-full   font-semibold">
                        Total
                      </p>
                      <h1 className="text-3xl font-medium w-full text-[#fff]">
                        9
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="flex items-start h-32 p-6 bg-white border rounded-sm cursor-pointer space-x-4 ">
                  <div className="p-1.5 bg-amber-500 rounded-full text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-clock-hour-5"
                      width={28}
                      height={28}
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                      <path d="M12 12l2 3" />
                      <path d="M12 7v5" />
                    </svg>
                  </div>
                  <div className="flex items-center w-full ">
                    <div className="space-y-2">
                      <p className="block text-base text-[#3d405b] w-full   font-semibold">
                        Pending
                      </p>
                      <h1 className="text-3xl font-medium w-full text-[#fff]">
                        9
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white p-6 space-y-6 border rounded-md">
                <div className="flex items-center justify-between ">
                  <div className="flex items-center space-x-4">
                    <h1 className="text-xl font-medium text-[#3D405B]">
                      Employee List
                    </h1>

                    <button className="md:w-auto w-full text-center flex items-center justify-center text-base   relative cursor-pointer  rounded-md py-2 px-6   uppercase   text-[#4CAF50] font-medium  border border-[#4CAF50]  ">  Approve All </button>

                  </div>
                  <div className="cursor-pointer bg-white text-[#3D405B]  border  flex justify-between px-4 items-center relative py-1.5 rounded-lg space-x-1.5 w-40"><span className=" text-sm text-[#3D405B] font-medium ">All</span><span className="material-symbols-outlined text-[#3D405B]/70">arrow_drop_down</span></div>
                </div>

                <div className="overflow-x-auto border "><table className="w-full text-sm text-left text-gray-500 bg-white border-collapse xl:whitespace-normal whitespace-nowrap"><thead className="bg-white"><tr className="bg-[#F9FAFB] "><th className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r">Rating/Approved Rating</th><th className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r" /><th className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r" /><th className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r" /><th className="px-6 py-3 font-semibold text-sm text-[#3D405B] text-center border-r" /></tr></thead><tbody className="border-t   divide-y  "><tr className="bg-white  font-normal text-sm text-[#3D405B] "><td className="px-6 py-3   border-r  "><p>Outstanding</p><span className="font-semibold">1/1</span></td><td className="px-6 py-3  border-r "><p>Excellent</p><span className="font-semibold">1/1</span></td><td className="px-6 py-3  border-r"><p>Satisfactory</p><span className="font-semibold">1/1</span></td><td className="px-6 py-3  border-r"><p>Need Improvement</p><span className="font-semibold">1/1</span></td><td className="px-6 py-3  border-r"><p>Unsatisfactory</p><span className="font-semibold">1/1</span></td></tr></tbody></table></div>
                <div className="overflow-x-auto border border-gray-200  ">
                  <table className="w-full text-sm text-left text-gray-500 bg-white border-collapse xl:whitespace-normal whitespace-nowrap">
                    <thead className="bg-white">
                      <tr>
                        <th
                          rowSpan={2}
                          className="px-6 py-3 font-semibold text-sm text-[#3D405B]  border-r"
                        >
                          <div className="flex items-center space-x-2 ">
                            <input type="checkbox" className="scale-110 cursor-pointer first:mr-2" />
                            <span> Employees</span>
                          </div>
                        </th>
                        <th

                          className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                        >
                          Overall Score
                        </th>
                        <th

                          className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                        >
                          Rating
                        </th>
                        <th

                          className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                        >
                          Final Approver Score
                        </th>
                        <th

                          className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                        >
                          Approved Rating
                        </th>
                        <th

                          className="font-semibold text-sm text-[#3D405B] text-center border-r p-3 px-6"
                        >
                          Action
                        </th>
                      </tr>

                    </thead>
                    <tbody className="border-t   divide-y  ">
                      <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                        <td className="px-6 py-3   border-r">
                          <div className="flex items-center space-x-2  ">
                            <input type="checkbox" className="scale-110 cursor-pointer first:mr-2" />
                            <img
                              src="img/newimg/Rectangle 11116.png"
                              alt=""
                              className="rounded-full w-12 h-12"
                            />
                            <div className=" ">
                              <span className="capitalize text-base font-medium block text-[#3D405B]">
                                Hari KC
                              </span>
                              <p className="capitalize text-sm  block text-[#3D405B] font-normal">
                                HR Manager - Dept of Human Resources
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="p-3 px-6 border-r text-center">5.0</td>
                        <td className="p-3 px-6 border-r text-center">Unsatisfactory</td>
                        <td className="p-3 px-6 border-r text-center ">
                          <div className="flex justify-center space-x-4 items-center">
                            <span>5.0</span>
                            <button className="flex items-center justify-center p-2 bg-white border rounded-md">
                              <MdOutlineModeEdit className="text-[1.3rem]" />
                            </button>
                          </div>
                        </td>
                        <td className="px-6 py-3 text-center border-r">Outstanding</td>
                        <td className="px-6 py-3 text-center border-r ">
                          <div className="flex justify-center">
                            <button onClick={setModal} className="md:w-auto w-full text-center   relative cursor-pointer  rounded-md py-2 px-4   uppercase   text-[#4CAF50] font-medium  border border-[#4CAF50] text-sm  ">  Approve Now </button>
                          </div>
                        </td>

                      </tr>
                      <tr className="bg-white  font-medium text-sm text-[#3D405B] ">
                        <td className="px-6 py-3   border-r">
                          <div className="flex items-center space-x-2  ">
                            <input type="checkbox" className="scale-110 cursor-pointer first:mr-2" />
                            <img
                              src="img/newimg/Rectangle 11116.png"
                              alt=""
                              className="rounded-full w-12 h-12"
                            />
                            <div className=" ">
                              <span className="capitalize text-base font-medium block text-[#3D405B]">
                                Hari KC
                              </span>
                              <p className="capitalize text-sm  block text-[#3D405B] font-normal">
                                HR Manager - Dept of Human Resources
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="p-3 px-6 border-r text-center">5.0</td>
                        <td className="p-3 px-6 border-r text-center">Outstanding</td>
                        <td className="p-3 px-6 border-r text-center ">
                          <div className="flex justify-center space-x-2 items-center">
                            <input className="border p-2 w-28 rounded-md text-center outline-none" value={5.6}></input>
                            <button className="flex items-center justify-center p-2 bg-white border rounded-md">
                              <AiOutlineSave className="text-[1.3rem] hover:text-[#2196F3]" />
                            </button>
                          </div>
                        </td>
                        <td className="px-6 py-3 text-center border-r">Outstanding</td>
                        <td className="px-6 py-3 text-center border-r ">
                          <div className="flex justify-center">
                            <button className="md:w-auto w-full text-center   relative cursor-pointer  rounded-md py-2 px-4   uppercase  text-white bg-[#4CAF50] font-medium  border border-[#4CAF50] text-sm  ">  Approved </button>
                          </div>
                        </td>

                      </tr>

                    </tbody>
                  </table>
                </div>
                <div className="flex items-center justify-between border-t p-6 -m-6">
                  <p className="text-[#3D405B] text-base font-semibold">
                    Showing 1 to 5 of 150 entries
                  </p>
                  <div className="flex border divide-x rounded-md ">
                    <button className="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                      Previous
                    </button>
                    <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">
                      1
                    </button>
                    <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                      2
                    </button>
                    <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                      3
                    </button>
                    <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                      4
                    </button>
                    <button className="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition ">
                      5
                    </button>
                    <button className="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>

        {isModal && (<div className="bg-black bg-opacity-40 fixed  z-40 top-0 right-0 left-0 bottom-0 h-full w-full flex items-center justify-center md:p-0 p-4">
          <div className="xl:w-4/12 lg:w-4/6 w-5/6  mx-auto bg-white rounded-md mb-20  p-6 space-y-6">

            <div className="flex items-center justify-center  ">
              <div className=" p-4 bg-[#FFC107]/10 rounded-full">  <TbExclamationMark className="text-[#FFC107] text-[3rem]" /></div>
            </div>
            <div className="flex flex-col gap-2 items-center  text-center ">
              {" "}
              <h1 className="text-2xl p-1 font-semibold capitalize text-[#212121]  mx-auto">
                Are you sure you want to submit <br/> your final approval?
              </h1>
              <p className="text-[#212121]/70">Note: You won’t be able to edit once you submitted.</p>

            </div>
            <div className="  space-y-4   pt-4"> 
              <div className="  space-x-4 items-center px-0 flex justify-center  ">
                <button onClick={()=> setModal(!isModal)} type="button" className="border w-60   rounded-md  py-2.5 text-base text-[#212121]/70 focus:outline-none flex items-center justify-center hover:bg-slate-50 uppercase  text-center font-medium">NO, Cancel </button>
                <button onClick={()=> setModal(!isModal)} type="button" className="border w-60 border-[#2196F3] rounded-md  py-2.5 text-base bg-[#2196F3] text-white focus:outline-none flex items-center justify-center hover:bg-[#2196F3]/90 uppercase  text-center font-medium">YEs, COntinue </button>
              </div>
            </div>

          </div>
        </div>)}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard, pms } = state;
  return {
    loggingIn,
    survey,
    dashboard,
    pms,
  };
}
export default connect(mapStateToProps)(PMSFinalApproval);
