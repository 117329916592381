import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { pmsActions } from '../../../_actions';

import { CiFilter } from "react-icons/ci";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { GoGoal } from "react-icons/go";
import { VscFeedback } from "react-icons/vsc";
import { LiaUserCogSolid } from "react-icons/lia";
import { AiOutlineClose } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { TiDocumentText } from "react-icons/ti";
import { TbEdit } from "react-icons/tb";
import { Button } from '@material-ui/core';
import moment from 'moment';
function PeerAppraisal(props, history) {

  const [useid, setid] = useState('');
  const [empid, setempid] = useState('');

  useEffect(() => {
    let id = props.match.params.id;
    let idc = props.match.params.idc;

    if (id && idc) {
      setid(id);
      setempid(idc);
    }

    let data = {
      "emp_id": props.match.params.idc,
      "appraisalCycleId": props.match.params.id,
      "level": 3
    }
    props.dispatch(pmsActions.getPeersGoalList(data))


    let data1 = {
      "emp_id": props.match.params.idc
    }
    props.dispatch(pmsActions.pmsGetPeerDetails(data1))


  }, [])





  let { item, name, stage, pms } = props;
  let { getPeersGoalList, pmsGetPeerDetails, PMSGetAppraisalDetailsById } = pms;

  let initList = getPeersGoalList && getPeersGoalList.feedbacks && getPeersGoalList.feedbacks.length > 0 ?
    getPeersGoalList.feedbacks.map((ele) => ({
      goalId: ele.goalId,
      rating: ele.rating,
      comment: ele.comment,
      level: ele.level,
      emp_id: props.match.params.idc,
      appraisalCycleId: props.match.params.id
    })) : []


  let initPreviewList = getPeersGoalList && getPeersGoalList.selfFeedbacks && getPeersGoalList.selfFeedbacks.length > 0 ?
    getPeersGoalList.selfFeedbacks.map((ele) => ({
      goalId: ele.goalId,
      rating: ele.rating,
      comment: ele.comment,
      level: ele.level,
      emp_id: props.match.params.idc,
      appraisalCycleId: props.match.params.id
    })) : []



  const [isAddGoal, setIsAddGoal] = useState(false);
  const [isAddTask, setIsAddTask] = useState(false);
  // State to track the active tab
  const [activeTab, setActiveTab] = useState('goals');
  const [activeTabOne, setActiveTabOne] = useState('Review');
  // let initalvalue = {
  //   "rating": "7",
  //   "level": 1
  // }
  const [useform, setform] = useState(initList);




  const handleTabClick = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTab(tab);
  };

  const handleTabClickTop = (tab) => {
    // Set the active tab based on the clicked tab
    setActiveTabOne(tab);
  };


  const handleAddGoal = () => {
    setIsAddGoal(!isAddGoal);
  };
  const handleCloseAddGoal = () => {
    setIsAddGoal(false);
  };

  const handleAddTask = () => {
    setIsAddTask(!isAddTask);
  };
  const handleCloseAddTask = () => {
    setIsAddTask(false);
  };



  const peerpage = () => {
    // if (empid && useid) {
    //     props.history.push(`/app/pmsemployeegoalsreview/${useid}/${empid}`);
    // }
    setActiveTab('peergoals')

  }

  const savedataall = (item, e) => {

    setform({
      ...useform, "goalId": item.id, "emp_id": props.match.params.idc,
      "appraisalCycleId": props.match.params.id, [e.target.name]: e.target.value
    })
  }
  // const newsavedata=(e)=>{
  //   setform({...useform,)

  // }


  function getPrevValue(initList, item, field) {
    let value = "";
    if (initList && initList.length > 0) {
      let index = initList.findIndex(prev => prev.goalId === item.id);
      if (index !== -1) {
        value = initList && initList[index] && initList[index][field] ? initList[index][field] : ""
      }
    }
    return value
  }

  const goBack = () => {
    let emp_id = props.match.params.idc;
    props.history.push(`/app/pmsemployeeteamgoals/${useid}/${emp_id}`);
  };

  const handleGoalReviewInput = (a, b, c, d) => {
    props.dispatch(pmsActions.handleGoalReviewInput(a, b, c, d, 2))
  }

  const handleGoalReviewInputV2 = (a, b, c, d, e) => {
    if (rangeFn(e, "rating", c)) {
      props.dispatch(pmsActions.handleGoalReviewInput(a, b, c, d, 2))
    }
  }


  function validateErr(value, settings) {
    let err = ""
    if (settings && settings.rating && settings.rating.mandatory) {
      if(!value){
        err = "Rating is mandatory"
      }
    }
    return err
  }


  const saveapidata = (listX) => {

    let settings = {};
    if (listX && listX.settings) {
      settings = listX.settings;
    }
    let errCount = 0;



    if (listX && listX.feedbacks && listX.feedbacks.length > 0) {
      for (var i = 0; i < listX.feedbacks.length; i++) {
        if (settings && settings.rating && settings.rating.mandatory) {
          let details = listX.feedbacks[i];
          let rating = details && details["rating"] ? details["rating"] : "";

          if(validateErr(rating, settings)){
            errCount++
          }
        }
      }
    }



    if (errCount === 0) {



    let reviewList = listX && listX.feedbacks && listX.feedbacks.length > 0 ?
      listX.feedbacks.map((ele) => ({
        goalId: ele.goalId,
        rating: ele.rating,
        comment: ele.comment
      })) : []

    if (reviewList && reviewList.length > 0) {
      let data = {
        reviewList: reviewList,
        level: 3,
        emp_id: props.match.params.idc,
        appraisalCycleId: props.match.params.id
      }
      props.dispatch(pmsActions.submitGoalReview2(data, props, useid, props.match.params.idc))
    }


  }
  
  }

  function mycall(getPeersGoalList) {
    let sum = 0
    if (getPeersGoalList && getPeersGoalList.feedbacks && getPeersGoalList.feedbacks.length > 0)
      getPeersGoalList.feedbacks.map((item) =>
        sum = sum + (item.rating ? (parseFloat(item.rating) === "NaN" ? 0 : parseFloat(item.rating)) : 0)

      )
    return Math.floor(sum / (getPeersGoalList && getPeersGoalList.feedbacks && getPeersGoalList.feedbacks.length ? getPeersGoalList.feedbacks.length : 1))
  }


  let settings = getPeersGoalList && getPeersGoalList.settings ? getPeersGoalList.settings : {};
  console.log('settings---->', settings);

  var isNumber = function isNumber(value) {
    return typeof value === 'number' && isFinite(value);
  }

  function settingModule(ss, aa, bb, cc) {
    if (cc === 1) {
      return ss && ss[aa] && ss[aa][bb] ? true : false
    } else if (cc === 2) {
      return ss && ss[aa] && ss[aa][bb] ? ss[aa][bb] : 0
    } else if (cc === 3) {
      return ss && ss[aa] && ss[aa][bb] ? ss[aa][bb] : ""
    }
  }

  function rangeFn(ss, aa, vv) {
    let ar = settingModule(ss, aa, "rangeMin", 2);
    let br = settingModule(ss, aa, "rangeMax", 2)
    let vr = isNumber(parseInt(vv)) ? parseInt(vv) : 0;
    console.log('ar---->', ar);
    console.log('br---->', br);
    console.log('vr---->', vr);

    if (ar <= vr && br >= vr) {
      return true
    } else {
      return false
    }
  }

  function getScoreLabel(ss, value) {
    let final = ''
    let vv = value ? (parseFloat(value) !== "NaN" ? parseFloat(value) : 0) : 0;
    if (ss && ss.scoreLabels && ss.scoreLabels.length > 0) {
      ss.scoreLabels.forEach((ele) => {
        if ((parseFloat(ele.a) <= vv) && (vv < parseFloat(ele.b))) {
          final = ele.label
        }
      })
    }
    return final
  }


  return (
    <>
      <>
        <div className="h-screen overflow-y-auto bg-gray-50">
          {/* code here  */}
          <div className='space-y-4 '>
            <div className='flex md:items-center items-start space-x-4 text-[#212121] bg-white p-4'>
              <button onClick={goBack} className='p-2 duration-200 rounded-md cursor-pointer bg-slate-100 hover:bg-slate-100' >
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M15 6l-6 6l6 6"></path>
                </svg></button>
              <div className='flex flex-col gap-1 md:flex-row md:gap-2 md:items-center'>
                <h1 className='text-xl font-semibold md:text-2xl'>For {PMSGetAppraisalDetailsById && PMSGetAppraisalDetailsById.name ? PMSGetAppraisalDetailsById.name : ''}</h1>
                <p className='md:text-base text-sm text-[#212121]/70'>Submission End Date: {moment(PMSGetAppraisalDetailsById && PMSGetAppraisalDetailsById.createdAt).format("DD-MM-YYYY")}</p>
              </div>
            </div>



            <main className='p-6 space-y-2'>
              <div className='grid grid-cols-1 gap-4 p-6 py-2 bg-white border rounded-md md:grid-cols-3 ' >
                <div className='text-[#3D405B]   flex items-center space-x-4 '>
                  <img src="https://media.istockphoto.com/id/1391365592/photo/beautiful-afro-woman.jpg?s=612x612&amp;w=0&amp;k=20&amp;c=o4M2f5nPzpU3ipnjK6jZF6xRNCFem_kz51IcRFGZ6qs=" className="object-cover w-10 h-10 rounded-full" />
                  <h2 className='text-lg font-semibold'>{pmsGetPeerDetails && pmsGetPeerDetails[0] && pmsGetPeerDetails[0].name}</h2>
                </div>

                <div className="text-[#3D405B]  flex items-center space-x-4 w-full whitespace-nowrap"><p className="text-base">Final Score </p>
                  <h2 className="p-4 py-2 text-base font-semibold border rounded-md bg-gray-50">{mycall(getPeersGoalList)}</h2></div>

                <div className='text-[#3D405B]  flex items-center space-x-2 '>
                  <p className='text-base'>{getScoreLabel(settings, mycall(getPeersGoalList)) ? "Rating:" : ""} </p>
                  <h2 className='text-base font-semibold'>{getScoreLabel(settings, mycall(getPeersGoalList))}</h2>
                </div>

              </div>

              {getPeersGoalList && getPeersGoalList.goalList && getPeersGoalList.goalList.length > 0 ?
                getPeersGoalList.goalList.map((item) =>
                  <>
                    <div className='flex flex-col gap-10 p-6 bg-white border lg:flex-row '>
                      <div className='w-full space-y-4'>
                        <div className='flex items-center px-4 py-2 space-x-2 rounded-md w-fit bg-red-50'>
                          <span className='w-2 h-2 bg-red-500 rounded-full'></span>
                          <span className='font-medium text-red-500'>{item && item.priority}</span>
                        </div>
                        <h3 className='text-lg font-semibold text-[#3D405B]'>{item && item.goalName}</h3>

                        <p>{item && item.desc}</p>

                        <div className='flex items-center space-x-4'>
                          <span className='font-medium '>Progress</span>
                          <div className='h-2 overflow-hidden rounded-full bg-slate-100 w-60'><div className='w-3/6 h-2 bg-green-500'></div></div>
                          <span className='font-medium '>{item && item.progress}%</span>
                        </div>
                      </div>


                      <div className='w-full space-y-4'>

                        <div className='flex flex-col gap-4 '>
                          <input type='text' className='w-16 px-4 py-2 font-medium text-black border rounded-md focus:outline-none bg-gray-50 '
                            value={getPrevValue(initPreviewList, item, "rating")} />
                          <label className="flex items-center space-x-2"><span className='font-medium text-black'>
                            {getPrevValue(initPreviewList, item, "comment")}</span></label>
                        </div>

                      </div>



                      <div className='flex flex-row w-full gap-6'>

                        <div className='w-full space-y-4'>

                          <div className='flex space-x-4 '>
                            <input disabled={!settingModule(settings, "rating", "active", 1)} type='text'
                              onChange={(e) => { handleGoalReviewInputV2(getPeersGoalList, item.id, e.target.value, "rating", settings) }} className='w-16 px-4 py-2 font-medium text-black border rounded-md focus:outline-none '
                              value={getPrevValue(initList, item, "rating")} />
                              <span className='text-red-500 px-2 text-xs'>{validateErr(getPrevValue(initList, item, "rating"), settings)}</span>
                            <label className="flex items-center space-x-2"><input type="checkbox" style={{ zoom: 1.5 }} /><span className='font-medium text-black'>N/A</span></label>

                          </div>

                          <textarea disabled={!settingModule(settings, "comment", "active", 1)} value={getPrevValue(initList, item, "comment")} name='comment'
                            // onChange={(e) => savedataall(item, e)} 
                            onChange={(e) => handleGoalReviewInput(getPeersGoalList, item.id, e.target.value, "comment")}

                            className='w-full p-4 border rounded-md focus:outline-none ' placeholder='Write a comment...' rows={8}>

                          </textarea>


                        </div>


                      </div>

                    </div>
                  </>
                ) : null}







              <div className='flex items-center gap-4 p-6 py-2 bg-white border rounded-md' >
                <div className='text-[#3D405B]    w-full '>
                  <h2 className='text-lg font-semibold'> </h2>
                </div>

                <div className='flex flex-row w-full gap-6'>
                  <div className="text-[#3D405B]  flex items-center space-x-4 w-full whitespace-nowrap"><p className="text-base">Total Average </p>
                    <h2 className="p-4 py-2 text-base font-semibold border rounded-md bg-gray-50">{mycall(getPeersGoalList)}</h2></div>

                  <div className='text-[#3D405B]   flex items-center space-x-4 w-full '>

                  </div>

                </div>
              </div>


              <div className='flex items-center justify-end px-6 py-6 space-x-4 bg-white '>

                <button onClick={goBack} className='w-32 px-4 py-3 uppercase border rounded-md'>cancel</button>
                <button onClick={() => saveapidata(getPeersGoalList)} className='border rounded-md w-32 py-3 px-4 uppercase bg-[#3ab1b7] text-white '>Submit</button>
              </div>

              {/* <div className='fixed bottom-0 flex items-center justify-end px-6 py-6 -m-6 space-x-4 bg-white '>
                <button onClick={() => goBack()} className='w-32 px-4 py-3 uppercase border rounded-md'>cancel</button>
                <button onClick={() => saveapidata(getPeersGoalList)} className='border rounded-md w-32 py-3 px-4 uppercase bg-[#3ab1b7] text-white '>Submit</button>
              </div> */}

            </main>
          </div>



        </div>



      </>
    </>
  );
}

function mapStateToProps(state) {
  //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard, pms } = state;
  return {
    loggingIn,
    survey,
    dashboard,
    pms
  };
}
export default connect(mapStateToProps)(PeerAppraisal);
