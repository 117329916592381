import React from "react";
import ReactPaginate from 'react-paginate';

export default function AddUserEmployeeList(props) {
  let {
    getAssignedRolesData,
    LanguageObj,
    CurrentLanguage,
    openAccessUpdateTab,
    AccessList,
    SelectEmpPagev1,
    currentSurveyPageV1,
    IndexListIndexx1,
    startItem,
    endItem,
    getAssignedRolesTotal,
    size,
    offset
  } = props;

  // console.log('getAssignedRolesData: ', getAssignedRolesData)

  return (

    <div className="inline-block min-w-full overflow-hidden align-middle">
      <table className="min-w-full border">
        <thead>
          <tr className="apitalize text-[#3D405B] border-b ">
            <th className="w-10 p-3 text-sm font-medium text-center border-r">{"Sno"}</th>
            <th className="w-20 p-3 text-sm font-medium text-left border-r">{" Name"}</th>
            <th className="p-3 text-sm font-semibold text-left border-r w-40 ">{LanguageObj && LanguageObj["Employee id" + CurrentLanguage] ? LanguageObj["Employee id_" + CurrentLanguage] : "Employee Id"}</th>
            <th className="w-60 p-3 text-sm font-semibold text-left border-r ">{LanguageObj && LanguageObj["email_address_label_" + CurrentLanguage] ? LanguageObj["email_address_label_" + CurrentLanguage] : "Email Address"}</th>
            <th className="w-40 p-3 text-sm font-semibold text-left capitalize border-r"> {"Role"}</th>
            <th className="w-40 p-3 text-sm font-semibold capitalize border-r text-center"> {"Status"}</th>

            {/* {AccessList && AccessList.length > 0 ?
              AccessList.map((item) =>
                <th className="px-6 py-3 font-medium text-center bg-gray-50 "> {item.label}</th>
              ) : null} */}

            <th className="w-40 p-3 text-sm font-semibold text-center capitalize border-r">{LanguageObj && LanguageObj["action_label_" + CurrentLanguage] ? LanguageObj["action_label_" + CurrentLanguage] : "Action "}</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y">
          {getAssignedRolesData && getAssignedRolesData.length > 0 ?
            getAssignedRolesData.map((emp, index) =>
              (!(emp && emp.role === "ADMIN")) ?
                <tr className='odd:bg-[#F9FAFB] even:bg-white text-sm'>
                  <td className="p-3 whitespace-no-wrap border-r text-center">
                    <div className="leading-5 ">{offset + index + 1}</div>
                  </td>
                  <td className="p-3 whitespace-no-wrap border-r">
                    <div className="leading-5 "> {emp && emp.employeeId && emp.employeeId.name ? emp.employeeId.name : "-----"}</div>
                  </td>
                  <td className="p-3 whitespace-no-wrap border-r">
                    <div className="leading-5 ">{emp && emp.employeeId && emp.employeeId.emp_id ? emp.employeeId.emp_id : "-----"}</div>
                  </td>
                  <td className="p-3 whitespace-no-wrap border-r">{emp && emp.employeeId && emp.employeeId.email ? emp.employeeId.email : "-----"} </td>
                  <td className="p-3 whitespace-no-wrap border-r"> {emp && emp.employeeId && emp.employeeId.role ? emp.employeeId.role : "-----"}</td>
                  <td className="p-3 text-sm font-medium leading-5 text-center whitespace-no-wrap border-r"><span className="inline-flex px-2 text-sm text-green-800 bg-green-100 rounded-full">{"active"}</span></td>

                  <td className="p-3 text-sm font-medium leading-5 text-center whitespace-no-wrap border-r">

                    <div className="flex items-center justify-center space-x-4">
                      <span onClick={() => openAccessUpdateTab(emp)} class="material-symbols-outlined text-gray-600 hover:bg-blue-100 p-2 rounded-md hover:text-blue-500 cursor-pointer" style={{ fontSize: '1.4rem' }}>
                        edit_square
                      </span>

                      <span onClick={() => openAccessUpdateTab(emp)} className="p-2 text-gray-500 rounded-md cursor-pointer material-symbols-outlined hover:bg-blue-100 hover:text-blue-500" style={{ fontSize: '1.4rem' }}>delete</span>
                    </div>
                  </td>
                </tr>
                : null
            ) : null
          }
        </tbody>
      </table>


      {/* Pagination start */}
      <div class="sm:flex sm:flex-1 sm:items-center items-center justify-center sm:justify-between mt-4">
        <div className="text-center">
          <p class="text-sm text-gray-700 md:pb-0 pb-2">
            Showing
            <span class="font-medium"> {startItem} </span>
            to
            <span class="font-medium"> {endItem} </span>
            of
            <span class="font-medium"> {getAssignedRolesTotal} </span>
            results
          </p>
        </div>
        <div className="relative z-0 flex md:justify-end justify-center w-76">
          {
            getAssignedRolesTotal && getAssignedRolesTotal > 10 ?
              <ReactPaginate
                previousLabel={'Prev'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={getAssignedRolesTotal / this.state.size}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                pageClassName={'page-cls'}
                activeClassName={'active'}
              />
              : null}
        </div>
      </div>



      {/* page no 1 2 3  */}
      {/* <div className="flex items-center justify-center w-full h-16 bg-white ">
        <div className="flex flex-wrap border divide-x rounded-md ">

          <button onClick={() => SelectEmpPagev1(currentSurveyPageV1 - 1, IndexListIndexx1)}
            class="p-4 py-2 text-[#3D405B]/50 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">
            Prev
          </button>

          {IndexListIndexx1 && IndexListIndexx1.length > 0 ? (
            <>
              <button class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">
                {currentSurveyPageV1}
              </button>


              <button class="p-4 py-2 hover:bg-[#F9FAFB] hover:text-[#2196F3] transition text-[#2196f3] ">
                {IndexListIndexx1 &&
                  IndexListIndexx1.length > 0
                  ? IndexListIndexx1.length
                  : 0}
              </button>
            </>
          ) : (
            <p>{0}</p>
          )}

          <button onClick={() => SelectEmpPagev1(currentSurveyPageV1 + 1, IndexListIndexx1)} class="p-4 py-2 text-[#2196F3] hover:bg-[#F9FAFB] hover:text-[#2196F3] transition">  Next
          </button>

        </div>
      </div> */}
    </div>

  );
}
