import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import { graphic } from 'echarts';

class WordChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedText: ''
    }
  }



  componentDidMount() {
  }


  handleKeyword = (data) => {
    let { handleKeywordCall } = this.props;
    handleKeywordCall(data.text)
    this.setState({ SelectedText: data.text + ' (' + data.value + ')' });

  }

  handleKeywordTooltip = (data) => {
    this.setState({ SelectedText: data.text + ' (' + data.value + ')' });
  }


  render() {

    let { textDistTreeMapData, loading, handleSentencesList, xaXis } = this.props;
    // let { SelectedText }= this.state;

    let countList = [];

    let DriverName = [];
    let DriverValues = [];

    console.log("textDistTreeMapData---11--->", textDistTreeMapData)

    if (textDistTreeMapData && textDistTreeMapData.length > 0) {
      textDistTreeMapData.forEach((item) => {
        countList.push({
          "value": item[0],
          "count": item[1][0][0],
          "total": item[2],
        })
      });
    }


    if (countList && countList.length > 0) {
      countList.sort((a, b) => a.total - b.total);

      countList.forEach((item) => {
        DriverName.push(item["value"]);
        DriverValues.push(item["total"]);
      });
    }

    let XAxis = DriverName;
    let List = DriverValues;

    console.log("-------------------------textDistTreeMapData------------------------->", textDistTreeMapData)


    const onChartClick = (params) => {



      let DmgValue = params && params.name ? params.name : "";

      if (textDistTreeMapData && textDistTreeMapData.length > 0) {
        let getINdex = textDistTreeMapData.findIndex(prev => prev[0] === DmgValue);
        ////////console.log("getINdex--->",getINdex)

        if (getINdex !== -1) {
          let eleData = textDistTreeMapData[getINdex][1];

          console.log("eleData--->", eleData)
          ////////console.log("DmgLen--->",DmgLen)

          if (eleData && eleData.length > 0) {
            let sentenceList = eleData.map((element) => (element[1]))

            handleSentencesList(sentenceList, DmgValue)
          }


        }



      }

    }


    const onEvents = {
      click: onChartClick,
    };


    function getCommentCount(value) {
      let count = 0;
      if (textDistTreeMapData && textDistTreeMapData.length > 0) {
        let getINdex = textDistTreeMapData.findIndex(prev => prev[0] === value);
        if (getINdex !== -1) {
          count = textDistTreeMapData && textDistTreeMapData[getINdex] && textDistTreeMapData[getINdex][2] ? textDistTreeMapData[getINdex][2] : 0;
        }
      }
      return count
    }

    return (
      <div className='space-y-4 '> 
        <div style={{ height: '450px', overflowX: 'hidden', overflowY: 'auto' }}>
          {XAxis && XAxis.length > 0 ?
            <>
              <div className=" text-center"><b>X-Axis:</b> {'Number of Reviews'}{',  '}<b>Y-Axis:</b> {xaXis}</div>
              <div>
                <ReactEcharts
                  style={{
                    height: XAxis.length > 100 ? '2500px' : XAxis.length > 50 ? '2000px' : XAxis.length > 20 ? '1500px' : XAxis.length > 10 ? '1000px' : XAxis.length > 5 ? '700px' : '350px'
                  }}

                  onEvents={onEvents}
                  option={{
                    tooltip: {
                      trigger: 'item',
                      formatter: function (a) {
                        let level = xaXis;
                        let value = XAxis[a.dataIndex];
                        return '<b>' + level + ': </b> ' + value + '</br>' +
                          // '<b>Text maximum length: </b> ' + ((a.value) ? a.value : 0).toString() + '</br>'+
                          '<b>Total no. of comments: </b> ' + getCommentCount(value) + '</br>'
                      }
                    },
                    grid: {
                      left: "10px",
                      right: "10px",
                      bottom: "0%",
                      top: '10px',
                      containLabel: true
                    },
                    xAxis: {
                      type: 'value',
                      splitLine: {
                        lineStyle: {
                          color: '#f2f2f2'
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          color: '#e6e6e6'
                        }
                      }
                    },
                    yAxis: {
                      type: 'category',
                      data: XAxis,
                      axisLine: {
                        lineStyle: {
                          color: '#e6e6e6'
                        }
                      },
                      axisLabel: {
                        color: "#000",

                      },
                    },
                    axisLabel: {
                      color: "#000",
                      interval: 0,

                    },
                    series: [{
                      data: List,
                      type: 'bar',
                      // showBackground: true,
                      barWidth: '20px',
                      barCategoryGap: '10px',
                      label: {
                        show: true,
                        position: 'right',
                        formatter: function (value) {
                          return value.data > 0 ? value.data + '' : "0";
                        }
                      },
                      color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: '#62cff4'
                        },
                        {
                          offset: 1,
                          color: '#2c67f2'
                        }])
                    }]
                  }}

                />
              </div>

            </>
            :
            <>
              {!loading ?
                <div className="pt-40 text-3xl text-center text-gray-400 ">We have not calculated this section!</div>
                : null}
            </>
          }
        </div>
        <div className="text-sm text-[#3d405B]/70" >* We are not showing data where employee count is less than 5</div>

      </div>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(WordChart);
