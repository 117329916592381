import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../../_helpers';
import { dashboardActions, surveyActions, userActions } from '../../../_actions';

import Header from '../Header/Header';
import Sidebar from '../../Overview/Sidebar/Sidebar';

import AssessmentSurveyList from './Components/AssessmentSurveyList';
import AddSurvey from './Modal/AddSurvey/AddSurvey';
import UpdateSurvey from './Modal/AddSurvey/UpdateSurvey';
import DeleteSurveyConfirm from './Modal/DeleteSurveyConfirm';
import ChooseSurvey from './Modal/AddSurvey/ChooseSurvey';
import SurveyLibrary from './Components/SurveyLibrary';
import QuestionLibrary from './Components/QuestionLibrary';
import AddSurveyLibModal from './Modal/AddSurvey/AddSurveyLibModal';
import NoDashboardModal from './Modal/NoDashboardModal';
import VentLinkModal from './Modal/VentLinkModal';
import OverallSurveyCard from './Components/OverallSurveyCard';
import LoadingOverlay from 'react-loading-overlay';

class AssessmentSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveysize: 10000,
      showSurveyList: true,
      showAddNewSurvey: false,
      showEditSurvey: false,
      confirmDeleteSurvey: false,
      CurrentSurveyId: '',
      CurrentSurveyName: '',
      showChooseSurveyModal: false,
      showSurveyLibrary: false,
      isPackageSelected: false,
      showStagesListModal: false,
      CurrentSurveyData: '',
      showQuestionLibrary: '',
      savePackage: '',
      crrLifecycleStageId: '',

      EmpIndexA: 0,
      EmpIndexB: 6,

      currentSurveyPage: 1,

      packageList: [],
      surveyValidation: {
        "name": true,
        "desc": true
      },


      surveyFormData: {
        "name": '',
        "desc": '',
        "type": "custom",
        "roleTemp": "ADMIN"
      },

      showCreateSurveyPopup: false,


      showSidebar: true,

      CrrRoute: "survey",

      crrSurvey: "",
      updateformSurveyData: {
        id: "",
        name: "",
        roleTemp: "",
        desc: "",
        templateId: "",
        companyId: "",
      },



      CurrentLanguage: 'en',
      CurrentLanguageName: 'English',


      showLanguageModal: false,
      showNODashboardModal: false,
      crrSurveyId: "",
      selectedSurveyType: {
        "id": 0,
        "name": "Select Category"
      },
      showTab: 1,
      showVentLinkModal: false,
      crrCategoryType: 1,
      showMobileSidebar: false,
      showGrid: true,


    }
  }


  componentDidMount() {

    let dataX2 = {
      "keyWord": "",
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 6 // Employee Listening Survey
    }

    this.props.dispatch(surveyActions.getSurveyList(dataX2));




    let dataX = {
      "keyWord": "",
      "pageNo": 1,
      "size": 5000
    }
    this.props.dispatch(dashboardActions.getAllParameterList(dataX));




    this.props.dispatch(dashboardActions.getClientProfile());
    this.props.dispatch(dashboardActions.getCompanyNameOnly());



    let data2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(surveyActions.getLanguageLabel(data2));
    this.props.dispatch(surveyActions.getLanguage(data2));


    this.props.dispatch(surveyActions.copyPackageParameters({
      packageModule: [9]
    }));

    this.props.dispatch(surveyActions.getDefaultRiskSurvey({ "defaultType": "DEFAULTASSESSMENT", "stageTemplate": 9, "surveyModule": 6 }, dataX2));


  }



  prevEmpPage = () => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (EmpIndexA > 0) {
      this.setState({ EmpIndexA: EmpIndexA - 1, EmpIndexB: EmpIndexB - 1 });
    }
  }
  nextEmpPage = (IndexListIndex) => {
    let { EmpIndexA, EmpIndexB } = this.state;
    if (IndexListIndex && IndexListIndex.length && (EmpIndexB < IndexListIndex.length)) {
      this.setState({ EmpIndexA: EmpIndexA + 1, EmpIndexB: EmpIndexB + 1 });
    }
  }

  SelectEmpPage = (val) => {
    this.setState({ currentSurveyPage: val })
  }


  handleSearchSurvey = (e) => {
    let data = {
      "keyWord": e.target.value,
      "pageNo": 1,
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "size": this.state.size,
      "surveyModule": 6 // Employee Listening Survey
    }
    this.props.dispatch(surveyActions.getSurveyList(data));
  }


  onAddNewSurvey = (check) => {
    if (check)
      this.setState({
        showSurveyList: true,
        showAddNewSurvey: false
      })
    else {
      this.setState({
        showSurveyList: false,
        showAddNewSurvey: true
      })
    }
  }

  handleAddSurveyInput = (e) => {
    let { surveyFormData } = this.state;
    surveyFormData[e.target.name] = e.target.value;
    this.setState({ surveyFormData })
  }

  handleUpdateSurveyInput = (e) => {
    let { updateformSurveyData } = this.state;
    updateformSurveyData[e.target.name] = e.target.value;
    this.setState({ updateformSurveyData })
  }

  handleAddSurveySubmit = () => {

    let isCustom = this.state.surveyFormData && this.state.surveyFormData.type === "custom";
    let isRisk = this.state.surveyFormData && this.state.surveyFormData.type === "risk";

    let reqData = {
      "name": this.state.surveyFormData.name,
      "desc": this.state.surveyFormData.desc,
      "type": this.state.surveyFormData.type,
      "roleTemp": this.state.surveyFormData.roleTemp,
      "templateId": isRisk ? 6 : (isCustom ? 3 : 1)
    }

    // let datatempsurvey = {
    //   "keyWord": "",
    //   "pageNo": 1,
    //   "size": this.state.surveysize
    // }


    if (reqData && reqData.name) {
      this.setState({ SurveySubmitData: reqData }, () => {
        if (this.state.surveyFormData && this.state.surveyFormData.type === "custom") {
          // if(this.state.crrLifecycleStageId){
          //   this.handleCustomSurvey();
          // }
          // else{
          //   this.handleCustomSurveyStage();
          // }
          this.handleCustomSurvey();

        }
        else if (this.state.surveyFormData && this.state.surveyFormData.type === "package") {
          this.handleCreateSurveyFromLibrary();
          this.setState({ showCreateSurveyPopup: false });
        }
        else if (this.state.surveyFormData && this.state.surveyFormData.type === "risk") {
          this.handleCustomSurvey();
        }

      });

    }
    else {
      let { surveyValidation } = this.state;

      if (reqData && reqData.name) {
        surveyValidation["name"] = true
      }
      else {
        surveyValidation["name"] = false
      }

      if (reqData && reqData.desc) {
        surveyValidation["desc"] = true
      }
      else {
        surveyValidation["desc"] = false
      }
      this.setState({ surveyValidation })
    }







  }

  handleCustomSurveyStage = () => {


    let dataX = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(surveyActions.getLifecycleStageList(dataX));

    this.setState({ showStagesListModal: true });

  }


  handleCustomSurvey = () => {
    let clientData = this.props.dashboard.ClientProfileData;


    let { SurveySubmitData, crrLifecycleStageId, selectedSurveyType } = this.state;

    let resData = {
      "name": SurveySubmitData.name,
      "desc": SurveySubmitData.desc,
      "templateId": SurveySubmitData && SurveySubmitData.templateId === "risk" ? 6 : 3,
      "roleTemp": clientData && clientData.role && clientData.role === "CROLE" ? "CROLE" : SurveySubmitData.roleTemp,
      "stageId": crrLifecycleStageId ? crrLifecycleStageId : null,
      "surveyModule": 6,  //Employee Listening Survey
    }

    // if(selectedSurveyType && selectedSurveyType.id && selectedSurveyType.id === 1){
    //   resData["isRiskSurveyType"] = "CUSTOMDAYS"
    // }
    if (selectedSurveyType && selectedSurveyType.id) {
      resData["surveyCategory"] = selectedSurveyType.id
    }


    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 6 //Employee Listening Survey
    }

    this.props.dispatch(surveyActions.createSurvey(resData, datatempsurvey, true, [], this.props));

    let validate = {
      "name": true,
      "desc": true
    }
    let tempData = {
      "name": '',
      "desc": '',
      "type": "custom",
      "roleTemp": "ADMIN"

    }
    this.setState({ crrLifecycleStageId: '', showStagesListModal: false, SurveySubmitData: "", showCreateSurveyPopup: false, showChooseSurveyModal: false, surveyValidation: validate, surveyFormData: tempData })

  }

  onDeleteSurvey = (survey) => {
    this.setState({ confirmDeleteSurvey: true, CurrentSurveyId: survey.id, CurrentSurveyName: survey.name })
  }

  handleConfirmDeleteSurveyClose = () => {
    this.setState({ confirmDeleteSurvey: false, CurrentSurveyId: '', CurrentSurveyName: '' })
  }

  handleViewPackage = (survey, valid) => {
    if (valid) {
      let { packageList } = this.state;
      this.setState({ showQuestionLibrary: true, savePackage: survey });

      if (packageList.findIndex(prev => prev === survey.id) !== -1) {
        this.setState({ isPackageSelected: true })
      } else {
        this.setState({ isPackageSelected: false })
      }
    }
  }
  handlePackageSelection = (check, survey) => {
    let Id = survey.id;
    let StageId = survey && survey.stageId && survey.stageId.id ? survey.stageId.id : '';
    let { packageList, crrLifecycleStageId } = this.state;

    if (StageId === crrLifecycleStageId) {
      ////////console.log("survey package------------->?",survey)
      this.setState({ showQuestionLibrary: false })

      if (!check) {
        packageList.push(Id);
      }
      else {
        let getIndex = packageList.findIndex(prev => prev === Id)
        packageList.splice(getIndex, 1);
      }
      this.setState({ packageList });


      // if(packageList.findIndex(prev=>prev === survey.id)===-1){
      //   packageList.push(survey.id);
      //   this.setState({isPackageSelected:false})
      // }
      // else{
      //   let getIndex = packageList.findIndex(prev=>prev === survey.id);
      //   packageList.splice(getIndex,1);
      //   this.setState({isPackageSelected:true})
      // }

    }


  }





  handleADDpackage = (survey) => {
    this.setState({ showQuestionLibrary: true })

    let { packageList } = this.state;
    if (packageList.findIndex(prev => prev === survey.id) === -1) {
      packageList.push(survey.id);
      this.setState({ isPackageSelected: false })
    }
    else {
      let getIndex = packageList.findIndex(prev => prev === survey.id);
      packageList.splice(getIndex, 1);
      this.setState({ isPackageSelected: true })
    }
    this.setState({ packageList });
  }


  onSurveyDelete = () => {
    let data = {
      id: this.state.CurrentSurveyId
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 6 //Employee Listening Survey
    }
    this.props.dispatch(surveyActions.deleteSurvey(data, datatempsurvey));
    this.setState({ confirmDeleteSurvey: false, CurrentSurveyId: '', CurrentSurveyName: '' })
  }

  onEditSurvey = (data) => {
    this.props.history.push(`/app/question/${data.id}`)
  }

  onReportSurvey = (data) => {
    this.props.history.push(`/app/surveyassessmentreport/${data.id}`)
  }

  onPublishSurvey = (data) => {

    if (data && data.id) {
      this.props.history.push(`/app/publishassessment/${data.id}`)
    } else {
      // //console.log("data.id issue!")
    }

  }

  handleChooseSurveyPopup = () => {
    this.setState({ showCreateSurveyPopup: false, showChooseSurveyModal: true })
  }

  handleCreateSurveyPopup = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showCreateSurveyPopup: true })
  }

  handleCreateSurveyFromLibrary = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showSurveyLibrary: true });


    this.props.dispatch(surveyActions.getSurveyFromLib({
      "stageTemplate": 2,
      "surveyType": 2
    }));
  }


  closeCreateSurveyFromLibrary = () => {
    this.handleChooseSurveyPopupClose();
    this.setState({ showCreateSurveyPopup: true, showChooseSurveyModal: false, showSurveyLibrary: false });
  }

  handleSurveyLibraryClose = (check) => {
    this.setState({ showCreateSurveyPopup: true, showSurveyLibrary: false, packageList: [] });
  }


  QuestionLibraryClose = () => {
    this.setState({ CurrentSurveyData: false, savePackage: '', showQuestionLibrary: false });
  }



  handleCreateSurveyPopupClose = () => {
    this.handleChooseSurveyPopupClose();

    let validate = {
      "name": true,
      "desc": true
    }
    let tempData = {
      "name": '',
      "desc": '',
      "type": "custom"

    }
    this.setState({ crrLifecycleStageId: '', showStagesListModal: false, showCreateSurveyPopup: false, surveyValidation: validate, surveyFormData: tempData });
  }

  handleChooseSurveyPopupClose = () => {
    this.setState({ showChooseSurveyModal: false })
  }

  onLogout = () => {
    ////console.log('logout')
    this.props.dispatch(userActions.logoutSession());
  }


  goToDashboard = () => {

    this.props.history.push('/app/dashboard');

  }


  gotoEmployeeScreen = () => {
    this.props.history.push('/app/employees/');
  }



  // onDashboard = (survey) => {

  //   if(survey && survey.id){
  //     this.props.dispatch(dashboardActions.getAnsweredEmployees({"surveyId":survey.id,"filters":[],"isAssessment":true}));
  //     this.props.dispatch(surveyActions.getTranslateElementAll());

  //     this.setState({showReportModal:true, crrSurveyId:survey.id })
  //   }else{
  //     this.setState({showNODashboardModal:true})
  //   }

  // }
  onDashboard = (survey) => {
    //console.log("survey---->", survey);
    if (survey && survey.id) {
      if (survey && survey.isSchool) {
        this.props.history.push("/app/schooldashboard/" + survey.id);
      } else {
        this.props.history.push("/app/assessmentdashboard/" + survey.id);
      }
    }
  };

  onAssessmentByEmpID = (emp_id) => {
    if (emp_id) {
      this.props.dispatch(dashboardActions.getAssessmentReportByEmpID({
        "surveyId": this.state.crrSurveyId,
        "emp_id": emp_id
      }));
    }
  }




  closeReportModal = () => {
    this.setState({ showReportModal: false })
  }



  handleSurveyLibQuestions = (survey) => {
    this.setState({ CurrentSurveyData: survey });
  }

  handleUseSurveyPage = () => { }

  handleSuveyPackageSubmit = (crrLifecycleStageId) => {
    let { SurveySubmitData, packageList } = this.state;
    let { survey } = this.props;
    let { getSurveyFromLibData } = survey;

    ////////console.log("packageList-------------------------->",packageList);

    let isNonEdit = false;
    let nonEditCount = 0
    if (getSurveyFromLibData && getSurveyFromLibData.length > 0 && packageList && packageList.length > 0) {
      packageList.forEach((item) => {
        let getIndex = getSurveyFromLibData.findIndex(prev => prev.id === item);
        if (getIndex !== -1) {
          if (getSurveyFromLibData[getIndex]["stageId"]["isNonEdit"]) {
            nonEditCount++
          }
        }
      })

      if (nonEditCount === packageList.length) {
        isNonEdit = true
      }

    }
    ////////console.log("isNonEdit-------------------------->",isNonEdit);



    let resData = {
      "name": SurveySubmitData.name,
      "desc": SurveySubmitData.desc,
      "stageId": crrLifecycleStageId,
      "roleTemp": SurveySubmitData.roleTemp,
      "templateId": 1,
      "isNonEdit": isNonEdit,
      "isPackage": true
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 6 //Employee Listening Survey
    }

    ////////console.log("resData-------->",resData)
    // this.props.history.push(`/app/question/${data.id}`)

    this.props.dispatch(surveyActions.createSurvey(resData, datatempsurvey, false, packageList, this.props));

    let validate = {
      "name": true,
      "desc": true
    }
    let tempData = {
      "name": '',
      "desc": '',
      "type": "custom",
      "roleTemp": "ADMIN"

    }
    this.setState({ crrLifecycleStageId: "", showSurveyLibrary: false, showCreateSurveyPopup: false, showChooseSurveyModal: false, surveyValidation: validate, surveyFormData: tempData, packageList: [] })

  }



  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });
  }
  routeLink = (link) => {
    if (link === "happinessdashbaord") {
      this.props.history.push('/app/happinessdashbaord');
    }
    else {
      this.props.history.push('/app/' + link);
      this.setState({ CrrRoute: link });
    }

  }
  onOptionDot = (data) => {
    if (this.state.crrSurvey === data.id) {
      this.setState({ crrSurvey: "" });
    }
    else {
      this.setState({ crrSurvey: data.id });
    }

    setTimeout(() => {
      this.setState({ crrSurvey: "" });
    }, 7000);
  }


  handleLanguage = (item) => {
    this.setState({ CurrentLanguage: item.shortName, CurrentLanguageName: item.name, showLanguageModal: false });
  }


  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  }


  onPreviewSurvey = (data) => {
    this.props.history.push(`/app/preview/${data.id}`)
  }


  handleSurveyType = (type) => {
    let { surveyFormData } = this.state;
    surveyFormData["type"] = type;
    this.setState({ surveyFormData });
  }

  handleSurveyRole = (role) => {
    let { surveyFormData } = this.state;
    surveyFormData["roleTemp"] = role;
    this.setState({ surveyFormData });
  }

  updatehandleSurveyRole = (role) => {
    let { updateformSurveyData } = this.state;
    updateformSurveyData["roleTemp"] = role.trim();
    this.setState({ updateformSurveyData });
  }

  onUpdateSurvey = (survey) => {
    let SurveyRole = "ADMIN"
    if (survey && survey.roles && survey.roles && survey.roles.length > 0) {
      if (survey.roles.length === 1) {
        if (survey.roles[0] === "ADMIN") {
          SurveyRole = "ADMIN";
        }
        else if (survey.roles[0] === "CROLE") {
          SurveyRole = "CROLE";
        }
      }
      else if ((survey.roles[0] === "ADMIN" && survey.roles[1] === "CROLE") || (survey.roles[0] === "CROLE" && survey.roles[1] === "ADMIN")) {
        SurveyRole = "CROLE";
      }

    }
    ////console.log('SurveyRole',SurveyRole)
    // survey.roles
    // roles
    let data = {
      id: survey.id,
      name: survey.name,
      roleTemp: SurveyRole,
      desc: survey.desc,
      templateId: survey.templateId,
      companyId: survey.companyId,
    }
    this.setState({ showUpdateSurveyModal: true, updateformSurveyData: data });
  }
  closeUpdateSurvey = () => {
    this.setState({ showUpdateSurveyModal: false, updateformSurveyData: "" });
  }

  handleUpdateSurveySubmit = () => {
    let { updateformSurveyData } = this.state;
    let resData = {
      "id": updateformSurveyData.id,
      "name": updateformSurveyData.name,
      "desc": updateformSurveyData.desc,
      "roleTemp": updateformSurveyData.roleTemp,
      "templateId": updateformSurveyData.templateId,
      "companyId": updateformSurveyData.companyId
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize,
      "surveyModule": 6 //Employee Listening Survey
    }
    this.props.dispatch(surveyActions.updateSurvey(resData, datatempsurvey));
    let tempClear = {
      id: "",
      name: "",
      roleTemp: "",
      desc: "",
      templateId: "",
      companyId: "",
    }
    this.setState({ showUpdateSurveyModal: false, updateformSurveyData: tempClear });
  }


  onNoDashboardModal = (check, survey, categoryType) => {
    if (check) {
      this.setState({ showNODashboardModal: check, crrSurveyId: survey })
    } else {
      this.setState({ showNODashboardModal: check });
    }
    this.setState({ crrCategoryType: categoryType });
  }

  handleConfirmMailView = () => {
    this.setState({ showNODashboardModal: false }, () => {
      if (this.state.crrSurveyId && this.state.crrSurveyId.id) {
        this.props.history.push('/app/livedashboard/' + this.state.crrSurveyId.id);
      }
    })
  }

  selectStage = (item) => {
    let { crrLifecycleStageId } = this.state;
    if (crrLifecycleStageId === item.id) {
      this.setState({ crrLifecycleStageId: "", packageList: [] })
    }
    else {
      this.setState({ crrLifecycleStageId: item.id, packageList: [] })
    }
  }


  selectCreateType = (data) => {
    this.setState({ selectedSurveyType: data })
  }


  handleVentLink = () => {
    this.setState({ showVentLinkModal: true })
    this.props.dispatch(surveyActions.generateCompanyBotLink());
  }


  viewDashbaord = (categoryId) => {
    this.props.history.push('/app/lifecycledashboard/' + categoryId);
  }

  handleSidebar = (check) => {
    this.setState({ showSidebar: check })
  }
  handleMobileSidebar = (check) => {
    this.setState({ showMobileSidebar: check })
    this.setState({ showSidebar: check })
  }
  handleGridView = (check) => {
    this.setState({ showGrid: check })
  }


  render() {
    let { survey, dashboard } = this.props;
    let { getLifecycleStageListData, getLanguageLabelData, getLanguageData, getSurveyFromLibData, getSurveyListData, getSurveyListTotal, loading, allLifecycleListenListData, generateCompanyBotLinkData, getTranslateElementAllData } = survey;
    let { getCompanyNameOnlyData, getAssessmentReportByEmpIDData, getAnsweredEmployeesData } = dashboard;
    let { showGrid, crrSurveyId, showReportModal, showMobileSidebar, crrCategoryType, showVentLinkModal, showTab, crrLifecycleStageId, showNODashboardModal, showUpdateSurveyModal, updateformSurveyData, showLanguageModal, CurrentLanguageName, CurrentLanguage, crrSurvey, CrrRoute, showSidebar, savePackage, isPackageSelected, showQuestionLibrary, packageList, CurrentSurveyData, showSurveyLibrary, showChooseSurveyModal, showSurveyList, CurrentSurveyName, confirmDeleteSurvey, surveyFormData, showCreateSurveyPopup, surveyValidation } = this.state;

    //console.log("crrSurveyId--------->", crrSurveyId);
    //console.log("getAssessmentReportByEmpIDData--------->", getAssessmentReportByEmpIDData);


    let loading2 = dashboard.loading;


    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
      // //console.log('accessModule(): ', accessModule())
    }

    //ROLE MANAGEMENT ----------------------------------------------

    let SHOW_ELEMENT = false;
    let CLIENT_ID = "";
    let CLIENT_ROLE = "";

    let clientData = this.props.dashboard.ClientProfileData;

    if (clientData && clientData.id) {
      CLIENT_ID = clientData.id;
    }


    if (clientData && clientData.role) {
      CLIENT_ROLE = clientData.role;

      if (CLIENT_ROLE === "ADMIN") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (CLIENT_ROLE === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (CLIENT_ROLE === "CROLE") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (CLIENT_ROLE === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }

    let isCrole = false;
    if (clientData && clientData.role && clientData.role === "CROLE") {
      isCrole = true;
    }

    function accessAllow(item) {
      // ////////console.log("item-----......>>>",item)
      // ////////console.log("isCrole-----......>>>",isCrole)
      // if(item === "employeeactionplan"){
      //   return (isCrole?true:false)
      // }
      // else{
      //   return (moduleAccess.isAdmin?true:(moduleAccess && moduleAccess.access && moduleAccess.access[item]?true:false))
      // }

      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))

    }

    //END -------------------------------------------------------
    let { ClientProfileData } = this.props.dashboard;
    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageractionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }
    ////////console.log("ClientProfileData----------->",ClientProfileData)
    //--------------------------------------------------------------------------------

    let IndexList = []
    let pageCount = 22
    let IndexListIndex = []
    let indexA = 0
    let indexB = 0

    if (getSurveyListData && getSurveyListData.length > 0) {


      if (getSurveyListData && getSurveyListData.length > 0) {
        getSurveyListData.forEach((item, index) => {
          if (index % pageCount === 0) {
            IndexList.push(index)
          }
          if (index === (getSurveyListData.length - 1)) {
            IndexList.push(index)
          }
        })
      }

      ////console.log('IndexList: ',IndexList)

      if (IndexList && IndexList.length > 0) {
        IndexList.forEach((item, index) => {
          if (index !== (IndexList.length - 1)) {
            IndexListIndex.push(index + 1)
          }
        })
      }
      ////console.log('IndexListIndex: ',IndexListIndex)





      if (IndexListIndex && IndexListIndex.length > 0) {
        IndexListIndex.forEach((item, index) => {
          if (item === this.state.currentSurveyPage) {

            if (index < (IndexList.length - 2)) {
              indexA = IndexList[index];
              indexB = IndexList[index] + (pageCount - 1)
            }
            else {
              indexA = IndexList[index];
              indexB = IndexList[IndexList.length - 1]
            }
          }
        })
      }

    }
    //--------------------------------------------------------------------------------

    ////console.log('CLIENT_ROLE: ',CLIENT_ROLE)
    // ////console.log('getLanguageLabelData: ',getLanguageLabelData,getLanguageData,);



    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }


    // createSurvey: false
    // dashboard: true
    // responseAnalytics: true
    // uploadUser: false
    // viewSurvey: true






    ////////console.log("accessModule",accessModule());



    // accessAllow("createSurvey");
    // accessAllow("viewSurvey");
    // accessAllow("publishSurvey");
    let { getAllParameterListData } = this.props.dashboard;
    ////console.log('getAllParameterListData V : ',getAllParameterListData);

    function GetParameterLabel(name) {
      let AllParameterObj = {};
      if (getAllParameterListData && getAllParameterListData.length > 0) {
        getAllParameterListData.forEach((item) => {
          AllParameterObj[item.name] = item && item.label ? item.label : item.name;
        });
      }
      return (AllParameterObj && AllParameterObj[name] ? AllParameterObj[name] : name);
    }





    return (
      <>
        <LoadingOverlay
          active={loading2}
          // active={false}

          spinner
          text='Loading Data...'
          className="loader"
        >




          <div className='h-screen overflow-hidden bg-gray-50'>

            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              props={this.props}
              companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}
              sidebarToggle={this.sidebarToggle}
            />

            <div className="flex w-full" style={{ height: 'calc(100% - 2rem)' }}>

              <Sidebar
                onOverview={this.onOverview}
                showSidebar={showSidebar}
                handleSidebar={this.handleSidebar}
                accessAllow={accessAllow}
                routeLink={this.routeLink}
                showMobileSidebar={this.state.showMobileSidebar}
              />
              <main className="flex-1 w-full overflow-hidden overflow-y-auto h-[calc(100vh-4rem)] bg-[#f9fafb]">



                {showSurveyLibrary ?
                  showQuestionLibrary ?
                    <QuestionLibrary
                      handleSurveyLibraryClose={this.handleSurveyLibraryClose}
                      getSurveyFromLibData={getSurveyFromLibData}
                      handleSurveyLibQuestions={this.handleSurveyLibQuestions}
                      CurrentSurveyData={CurrentSurveyData}
                      handleADDpackage={this.handleADDpackage}
                      packageList={packageList}
                      handleSuveyPackageSubmit={this.handleSuveyPackageSubmit}


                      isPackageSelected={isPackageSelected}
                      handlePackageSelection={this.handlePackageSelection}
                      savePackage={savePackage}
                      QuestionLibraryClose={this.QuestionLibraryClose}

                      GetParameterLabel={GetParameterLabel}

                    />
                    :
                    <SurveyLibrary
                      handleSurveyLibraryClose={this.handleSurveyLibraryClose}
                      getSurveyFromLibData={getSurveyFromLibData}
                      handleSurveyLibQuestions={this.handleSurveyLibQuestions}
                      CurrentSurveyData={CurrentSurveyData}
                      handleADDpackage={this.handleADDpackage}
                      packageList={packageList}
                      handleSuveyPackageSubmit={this.handleSuveyPackageSubmit}
                      crrLifecycleStageId={crrLifecycleStageId}
                      handleViewPackage={this.handleViewPackage}
                      selectStage={this.selectStage}
                    />
                  :


                  <>
                    {(accessAllow("viewSurvey")) ?
                      getSurveyListData && getSurveyListData.length > 0 ?

                        <div
                          className="p-4 mx-auto md:p-6">
                          <div className="flex items-start justify-between space-x-4 cursor-pointer md:items-center">
                            <div className="flex items-center space-x-4 "> 
                              <h1 className="xl:text-2xl text-xl font-medium text-[#3D405B]">
                                {"Assessment"}
                              </h1>
                            </div>

                            <div className="items-center hidden text-sm md:flex">
                              <span className="mx-2 material-symbols-rounded">home</span>
                              <span>/</span>
                              <span className="px-2">My survey</span>
                              <span>/</span>
                              <span className="px-2 font-semibold">{"Assessment"}</span>
                            </div>
                          </div>


                          <div className="items-center justify-center w-full mt-4 md:flex md:justify-between"> 
                            <div className="flex items-center justify-between overflow-hidden text-sm bg-white border rounded-md ">
                              <span
                                className="p-2 rounded-l-full material-symbols-outlined text-slate-600"
                                style={{ fontSize: "1.4rem" }}
                              >
                                search
                              </span>
                              <input
                                type="search"
                                name="search"
                                onChange={this.handleSearchSurvey}
                                className="w-full py-2 md:w-48 focus:outline-none placeholder:text-slate-600"
                                placeholder="Search"
                              />
                            </div>

                            <div />


                            <div className="justify-between hidden space-x-2 md:flex">

                              {showGrid ?
                                <button onClick={() => this.handleGridView(!showGrid)} className="flex items-center justify-center p-2 bg-white border rounded-md">
                                  <span className="material-symbols-rounded  text-[1.5rem] text-[#2196f3]">
                                    grid_view
                                  </span>
                                </button>
                                :
                                <button onClick={() => this.handleGridView(!showGrid)} className="flex items-center justify-center p-2 bg-white border rounded-md">
                                  <span className="material-symbols-rounded  text-[1.5rem]">
                                    format_list_bulleted
                                  </span>
                                </button>
                              }

                              {!showGrid ?
                                <button onClick={() => this.handleGridView(!showGrid)} className="flex items-center justify-center p-2 bg-white border rounded-md">
                                  <span className="material-symbols-rounded  text-[1.5rem] text-[#2196f3]">
                                    grid_view
                                  </span>
                                </button>
                                :

                                <button onClick={() => this.handleGridView(!showGrid)} className="flex items-center justify-center p-2 bg-white border rounded-md">
                                  <span className="material-symbols-rounded  text-[1.5rem] ">
                                    format_list_bulleted
                                  </span>
                                </button>
                              }
                              {/* <div className="flex justify-center md:justify-start">
                                <button
                                  onClick={() =>
                                    this.handleCreateSurveyPopup()
                                  }
                                  className="bg-[#2196f3] hover:bg-[#1E88E5] transition-all duration-200 md:w-auto w-full md:py-2 py-3  px-4 rounded-md text-center justify-center cursor-pointer transform  flex items-center text-white space-x-1 md:text-sm  text-md"
                                >
                                  <span className="text-white material-symbols-outlined ">
                                    add_circle
                                  </span>{" "}
                                  <h1 className="">Create New</h1>
                                </button>
                              </div> */}



                            </div>
                          </div>


                          {/* <h1 className="text-xl font-medium text-gray-800 ">
                            {"Assessment Survey"}
                          </h1> */}

                          <div className={showGrid ? "grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 my-6" : "grid  grid-cols-1 gap-4 my-6"}>

                            {showSurveyList ?
                              <AssessmentSurveyList
                                getSurveyListData={getSurveyListData}
                                isLifecycle={false}
                                getSurveyListTotal={getSurveyListTotal}

                                onDashboard={this.onDashboard}
                                onEditSurvey={this.onEditSurvey}
                                onPreviewSurvey={this.onPreviewSurvey}
                                onReportSurvey={this.onReportSurvey}
                                onPublishSurvey={this.onPublishSurvey}
                                onDeleteSurvey={this.onDeleteSurvey}
                                SHOW_ELEMENT={SHOW_ELEMENT}

                                indexA={indexA}
                                indexB={indexB}

                                onOptionDot={this.onOptionDot}

                                crrSurvey={crrSurvey}

                                LanguageObj={LanguageObj}
                                CurrentLanguage={CurrentLanguage}

                                onUpdateSurvey={this.onUpdateSurvey}

                                isCrole={isCrole}

                                CLIENT_ID={CLIENT_ID}
                                CLIENT_ROLE={CLIENT_ROLE}
                                accessAllow={accessAllow}
                                onNoDashboardModal={this.onNoDashboardModal}
                                showGrid={showGrid}

                              />
                              :
                              null}
                          </div>






                        </div>

                        :
                        <>
                          {!loading && !showCreateSurveyPopup ?
                            <div className="container h-full px-4 py-12 mx-auto overflow-hidden overflow-y-auto" style={{ height: 'calc(100% - 4rem)' }}>
                              <div className="flex items-center justify-center w-full h-full">
                                <div className="text-center">
                                  <img alt='empty_survey' src="img/empty_survey.png" className="mx-auto" />
                                  <h1 className="text-lg font-semibold text-gray-800 cursor-default">No survey has been created yet.</h1>
                                  <p className="text-xs text-gray-500 cursor-default">Let's get started &amp; create your first survey</p>
                                  <div className="flex justify-center"><button onClick={() => this.handleCreateSurveyPopup()} className="flex items-center px-6 py-3 my-6 text-sm text-white bg-blue-500 rounded-md focus:outline-none"><span className="mr-2 material-symbols-outlined">add</span> CREATE SURVEY</button>    </div>
                                </div>
                              </div>
                            </div>
                            : null}
                        </>
                      :
                      <>
                        {!loading ?
                          <div className="container h-full px-4 py-12 mx-auto overflow-hidden overflow-y-auto" style={{ height: 'calc(100% - 4rem)' }}>
                            <div className="flex items-center justify-center w-full h-full">
                              <div className="text-center">
                                <img alt='empty_survey' src="img/empty_survey.png" className="mb-4" />
                                <h1 className="text-xl font-semibold text-gray-500 cursor-default">You have no access <br /> to this module!</h1>
                              </div>
                            </div>
                          </div>
                          : null}
                      </>

                    }



                  </>}

              </main>





              {showCreateSurveyPopup ?
                (accessAllow("createSurvey")) ?
                  <AddSurvey
                    handleCreateSurveyPopupClose={this.handleCreateSurveyPopupClose}
                    handleAddSurveyInput={this.handleAddSurveyInput}
                    handleAddSurveySubmit={this.handleAddSurveySubmit}
                    surveyFormData={surveyFormData}
                    surveyValidation={surveyValidation}
                    handleSurveyType={this.handleSurveyType}
                    handleSurveyRole={this.handleSurveyRole}
                    isCrole={isCrole}
                    showStagesListModal={this.state.showStagesListModal}
                    getLifecycleStageListData={getLifecycleStageListData}
                    selectStage={this.selectStage}
                    crrLifecycleStageId={crrLifecycleStageId}

                    isModule={1}
                    selectCreateType={this.selectCreateType}
                    selectedSurveyType={this.state.selectedSurveyType}

                    allLifecycleListenListData={allLifecycleListenListData}
                  />
                  : null
                : null}


              {showUpdateSurveyModal ?
                (accessAllow("uploadUser")) ?
                  <UpdateSurvey
                    handleCreateSurveyPopupClose={this.closeUpdateSurvey}
                    updateformSurveyData={updateformSurveyData}
                    handleUpdateSurveyInput={this.handleUpdateSurveyInput}
                    updatehandleSurveyRole={this.updatehandleSurveyRole}
                    handleUpdateSurveySubmit={this.handleUpdateSurveySubmit}
                    isCrole={isCrole}
                  />
                  : null
                : null}


              {showChooseSurveyModal ?
                <ChooseSurvey
                  handleCreateSurveyPopupClose={this.handleChooseSurveyPopupClose}
                  handleCreateEditableSurvey={this.handleCustomSurvey}
                  handleCreateSurveyFromLibrary={this.handleCreateSurveyFromLibrary}
                  closeCreateSurveyFromLibrary={this.closeCreateSurveyFromLibrary}
                />
                : null}

              {CurrentSurveyData ?
                <AddSurveyLibModal
                  CurrentSurveyData={CurrentSurveyData}
                  handleUseSurveyPage={this.handleUseSurveyPage}

                />
                : null}


              {confirmDeleteSurvey ?
                <DeleteSurveyConfirm
                  CurrentSurveyName={CurrentSurveyName}
                  handleSendSingleMail={this.onSurveyDelete}
                  handleConfirmMailClose={this.handleConfirmDeleteSurveyClose}
                />
                : null}


              {showNODashboardModal ?
                <NoDashboardModal
                  handleConfirmMailClose={this.onNoDashboardModal}
                  handleConfirmMailView={this.handleConfirmMailView}
                  surveyFormData={this.state.crrSurveyId}
                  crrCategoryType={crrCategoryType}
                />
                : null}


              {showVentLinkModal ?
                <VentLinkModal
                  handleClose={() => this.setState({ showVentLinkModal: false })}
                  generateCompanyBotLinkData={generateCompanyBotLinkData}
                />
                : null}


              {/* {showReportModal?
            <AssessmentReport
              closeReportModal={this.closeReportModal}
              getAnsweredEmployeesData={getAnsweredEmployeesData}
              getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
              onAssessmentByEmpID={this.onAssessmentByEmpID}
              getLanguageData={getLanguageData}
              getTranslateElementAllData={getTranslateElementAllData}
            />
            :null} */}



            </div>
          </div>
        </LoadingOverlay>

      </>
    );
  }
}
function mapStateToProps(state) {
  ////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(AssessmentSurvey);
