import React, { Component } from 'react';
import { connect } from 'react-redux';
import SummaryData from './Components/SummaryData/SummaryData';
import Behaviour from '../../../CommonComponents/Behaviour/Behaviour';
import ResponseDistributionType2 from '../Components/ResponseDistributionType2/ResponseDistribution';
import DimensionPriorities from '../../../CommonComponents/DimensionPriorities/DimensionPriorities';
import DimensionPrioritiesMap from '../../../CommonComponents/DimensionPrioritiesMap/DimensionPrioritiesMap';
import DriverComparision from './Components/DriverComparision/DriverComparision';

class IndexMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  onSwitchOptionScreen = () => {
    let { getIndexQuantileError, name, XAxis, GetParameterLabel, CountRestrict, getIndexQuantileCount, getEMPFilterLabel, getDMGIndexManyFilterData, getEDAdata,
      indexType, loading, optionType, EmpFilterData, getIndexFilterData, getIndexByFilterDMGData, cronbatchAlphaVarianceData, getCommentData, handleMultifilterIndexScore, getDemographicIndexMultiFilterData,

      getAllBenchMarkData, getRespondantDetailsData, getIndexScore, handleSummaryFilter, handleSummaryCard, getDemographicIndexData, getIndexData, getIndexDistributionData,
      getQuestionData, getQuestionFilterData, handleQuestionFilter,
      getDistributionIndexData, handleDistributionFilter, getIndexDriverData, getIndexQuantileData, handleQuantileFilter,

      getNudgeTextBySurveyIdData,

      handleMultiFilterGetResQuestion, handleMultifilterBehaviour, handleMultiFilterGetQuestion, handleMultiFilterQuantile,
      getOverallIndexData, isCrole, getQuestionMapData, getQuestionDistributionData, ErrorIndexObj, handleMultiFilterGetDimenPrioQuestion
      , handleMultiFilterGetMapQuestion, getDemographicIndexMultiAxisFilterData, getQuesDistributionIndexOrgData, handleQuesDistributionIndexOrg
    } = this.props;

    switch (optionType) {

      case 1: return <SummaryData
        ErrorIndexObj={ErrorIndexObj}
        getIndexByFilterDMGData={getIndexByFilterDMGData}
        getOverallIndexData={getOverallIndexData}
        getEDAdata={getEDAdata} getDMGIndexManyFilterData={getDMGIndexManyFilterData}
        getDemographicIndexMultiAxisFilterData={getDemographicIndexMultiAxisFilterData}

        isCrole={isCrole}
        CountRestrict={CountRestrict} getEMPFilterLabel={getEMPFilterLabel} GetParameterLabel={GetParameterLabel} name={name} loading={loading} handleMultifilterIndexScore={handleMultifilterIndexScore} getDemographicIndexMultiFilterData={getDemographicIndexMultiFilterData}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}

        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} cronbatchAlphaVarianceData={cronbatchAlphaVarianceData}
        getAllBenchMarkData={getAllBenchMarkData} getRespondantDetailsData={getRespondantDetailsData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard} getDemographicIndexData={getDemographicIndexData} getIndexData={getIndexData} />

      case 2: return <Behaviour
        IndexDimensions={["ACT", "Connect", "Empower", "Respect"]}
        ErrorIndexObj={ErrorIndexObj}
        getIndexByFilterDMGData={getIndexByFilterDMGData}
        CountRestrict={CountRestrict} getEMPFilterLabel={getEMPFilterLabel} GetParameterLabel={GetParameterLabel} name={name} loading={loading} handleMultifilterBehaviour={handleMultifilterBehaviour}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getIndexDriverData={getIndexDriverData} getDistributionIndexData={getDistributionIndexData} handleDistributionFilter={handleDistributionFilter}
      />

      case 3: return <DimensionPriorities
        IndexName={name}
        ErrorIndexObj={ErrorIndexObj}
        handleMultiFilterGetDimenPrioQuestion={handleMultiFilterGetDimenPrioQuestion}
        getIndexByFilterDMGData={getIndexByFilterDMGData}
        CountRestrict={CountRestrict} getEMPFilterLabel={getEMPFilterLabel} name={name} GetParameterLabel={GetParameterLabel} loading={loading} handleMultiFilterGetQuestion={handleMultiFilterGetQuestion}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        getNudgeTextBySurveyIdData={getNudgeTextBySurveyIdData}

        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getQuestionData={getQuestionData} getQuestionFilterData={getQuestionFilterData} handleQuestionFilter={handleQuestionFilter} />

      case 6: return <DimensionPrioritiesMap
        IndexName={name}
        ErrorIndexObj={ErrorIndexObj}
        getIndexByFilterDMGData={getIndexByFilterDMGData}
        handleMultiFilterGetMapQuestion={handleMultiFilterGetMapQuestion}
        name={name} getEMPFilterLabel={getEMPFilterLabel} CountRestrict={CountRestrict} GetParameterLabel={GetParameterLabel} loading={loading} handleMultiFilterGetQuestion={handleMultiFilterGetQuestion}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}

        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getQuestionData={getQuestionData} getQuestionMapData={getQuestionMapData} getQuestionFilterData={getQuestionFilterData} handleQuestionFilter={handleQuestionFilter} />



      case 4: return <ResponseDistributionType2
        ErrorIndexObj={ErrorIndexObj}
        handleMultiFilterGetResQuestion={handleMultiFilterGetResQuestion}
        getQuestionDistributionData={getQuestionDistributionData}
        getIndexByFilterDMGData={getIndexByFilterDMGData}
        CountRestrict={CountRestrict} getEMPFilterLabel={getEMPFilterLabel} XAxis={XAxis} GetParameterLabel={GetParameterLabel} loading={loading} handleMultiFilterGetQuestion={handleMultiFilterGetQuestion}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getQuestionData={getQuestionData} getQuestionFilterData={getQuestionFilterData} handleQuestionFilter={handleQuestionFilter}
        getQuesDistributionIndexOrgData={getQuesDistributionIndexOrgData}
        handleQuesDistributionIndexOrg={handleQuesDistributionIndexOrg}
      />

      case 5: return <DriverComparision
        ErrorIndexObj={ErrorIndexObj}
        getIndexByFilterDMGData={getIndexByFilterDMGData}
        CountRestrict={CountRestrict} getEMPFilterLabel={getEMPFilterLabel} name={name} XAxis={XAxis} GetParameterLabel={GetParameterLabel} loading={loading} handleMultiFilterQuantile={handleMultiFilterQuantile}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getQuestionData={getQuestionData} handleQuantileFilter={handleQuantileFilter} handleQuestionFilter={handleQuestionFilter}
        getIndexQuantileData={getIndexQuantileData}
        getIndexQuantileCount={getIndexQuantileCount}
        getIndexQuantileError={getIndexQuantileError}

      />



      default: return <div>Default Option</div>
    }
  }

  render() {
    let { onOptionType, optionType, CurrentSurveyId, indexType, name } = this.props;

    let OptionList = [
      {
        "name": name + " Summary",
        "template": 1
      },
      {
        "name": " Behaviour Summary",
        "template": 2
      },
      {
        "name": " Behaviour Item Summary",
        "template": 3
      },
      {
        "name": " Behavior Item Detail",
        "template": 6
      },
      {
        "name": "Detailed Distribution Summary",
        "template": 4
      },
      {
        "name": " Behaviour-Range Summary",
        "template": 5
      },
    ]


    return (
      <>
        {/* side bar end */}
        <div className="md:p-6 p-4 space-y-4 w-full flex-1 overflow-y-auto bg-[#f9fafb]">
          <div className="flex items-center justify-between text-gray-800">
            <h1 className=" xl:text-2xl text-xl font-medium text-[#3D405B]  ">{name} index</h1>
            {/* <span className="flex cursor-pointer material-symbols-outlined lg:hidden">menu_open</span> */}
          </div>
          {/*sub nav bar 1 */}
          <div className="items-center justify-between border-b md:flex">
            <div className="flex space-x-2 overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 whitespace-nowrap tab-scroll scollar-xs">
              {OptionList && OptionList.length > 0 ?
                OptionList.map((item) =>
                  <span onClick={() => onOptionType(CurrentSurveyId, indexType, item.template)}
                    className={optionType === item.template ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3] " :
                      "hover:bg-[#F9FAFB] transition-all duration-150  cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border "}>
                    {item.name}
                  </span>
                ) : null}

            </div>
          </div>

          {this.onSwitchOptionScreen()}

        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexMain);
